import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { TabBodyTitle } from '@/components/Navigation/Tabs';
import { TitleThree } from '@/components/Text/Title';
import Flex from '@/design-system/components/Flex';
import LanguageSelector from '@/i18n/components/LanguageSelector';

import Phone from './Phone';
import User from './User';

const Table = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 2.5rem 3rem;
  max-width: 500px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
  margin-bottom: 0.5rem;
`;

const LightText = styled.div`
  font-size: ${({ theme }) => theme.textCss.sizes.sm};
  color: ${({ theme }) => theme.cssColors.dark060};
`;

function PersonalInfo({ title }) {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);

  return (
    <Table>
      <TabBodyTitle noMargin>{title}</TabBodyTitle>

      <Row>
        <User user={user} />
      </Row>

      <Row>
        <Flex flex="1" flexDirection="column" gap="8px">
          <TitleThree noMargin weight="strong">
            {t('settings:profile.phone-number')}
          </TitleThree>
          <Phone user={user} />
        </Flex>
      </Row>

      <Row>
        <Flex flex="1" flexDirection="column" gap="8px">
          <TitleThree noMargin weight="strong">
            {t('settings:profile.language')}
          </TitleThree>
          <LightText>{t('settings:profile.language-description')}</LightText>
          <LanguageSelector />
        </Flex>
      </Row>
    </Table>
  );
}

export default PersonalInfo;
