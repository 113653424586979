import { nanoid } from 'nanoid';
import { useMemo } from 'react';

import SvgWrapper from './SvgWrapper';

function CupAltIcon(props) {
  const svgId = useMemo(() => nanoid(), []);

  return (
    <SvgWrapper {...props} fill="none" height="11" viewBox="0 0 11 11" width="11">
      <g clipPath={`url(#clip${svgId})`}>
        <mask
          height="11"
          id={`mask${svgId}`}
          maskUnits="userSpaceOnUse"
          style={{ maskType: 'alpha' }}
          width="11"
          x="0"
          y="0"
        >
          <path
            d="M10.6291 1.14728H9.2916C9.29219 1.04772 9.29634 0.95291 9.29634 0.850984V0.554688H1.88893V0.850984C1.88893 0.945799 1.8913 1.04832 1.8919 1.14728H0.556193L0.555601 1.44298C0.553823 2.08654 0.592342 2.7461 0.671156 3.40447C0.879749 5.14491 1.42434 7.1621 2.32745 7.60417C2.44242 7.66106 2.56153 7.6895 2.68064 7.6895C2.82168 7.6895 2.96449 7.64921 3.0996 7.56743L3.22345 7.48447C3.71708 8.24121 4.39086 8.75202 5.29634 8.84624V10.0362H3.54642V10.6288H7.69457V10.0362H5.88894V8.85276C6.72745 8.76447 7.42079 8.29869 7.95886 7.48269L8.09753 7.57454C8.22553 7.65158 8.36242 7.6895 8.50108 7.6895C8.6202 7.6895 8.74049 7.66106 8.85782 7.60358C9.76094 7.1615 10.3055 5.14432 10.5141 3.40387C10.5929 2.7461 10.6315 2.08595 10.6297 1.44239L10.6291 1.14728V1.14728ZM2.91886 6.97484L2.78316 7.0661C2.71679 7.10521 2.66227 7.10758 2.58879 7.07202C2.1236 6.84387 1.51738 5.48684 1.2596 3.33335C1.1956 2.80002 1.15945 2.2655 1.15056 1.73987H1.90493C1.96242 3.47261 2.20893 5.57276 2.96686 7.04713L2.91886 6.97484ZM5.59264 8.28269C2.90227 8.28269 2.51175 3.51232 2.4833 1.14728H8.70197C8.67294 3.94491 8.20538 8.28269 5.59264 8.28269ZM9.92568 3.33335C9.66731 5.48684 9.06168 6.84387 8.59649 7.07202C8.5236 7.10817 8.46849 7.10639 8.41397 7.0738L8.26582 6.97484L8.19708 7.07795C8.83649 5.87024 9.20864 4.07587 9.28212 1.73987H10.0353C10.0258 2.2655 9.98968 2.80002 9.92568 3.33335V3.33335Z"
            fill="black"
          />
        </mask>
        <g mask={`url(#mask${svgId})`}>
          <rect fill="currentColor" height="11.8519" width="11.8519" x="-0.629639" y="-0.0371094" />
        </g>
      </g>
      <defs>
        <clipPath id={`clip${svgId}`}>
          <rect
            fill="white"
            height="10.0741"
            transform="translate(0.555542 0.554688)"
            width="10.0741"
          />
        </clipPath>
      </defs>
    </SvgWrapper>
  );
}

export default CupAltIcon;
