import Divider from '@material-ui/core/Divider';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { FlexContainer } from '@/components/_common';
import { OutlinedButton, TextButton } from '@/components/Button/Button';
import Stepperinfo from '@/components/Stepper/StepperInfo';
import PrimaryButton from '@/design-system/components/Button/PrimaryButton';

const Wrapper = styled.div`
  height: 60px;
  margin: 1rem 0em;
`;

const DefaultWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export function OneActionStepperFooter({
  handleNext,
  infoOnError,
  info,
  isNextDisabled,
  nextLabel,
}) {
  const { t } = useTranslation();

  return (
    <>
      <Stepperinfo isError={infoOnError}>{info}</Stepperinfo>
      <PrimaryButton disabled={isNextDisabled} minWidth="180px" onClick={handleNext}>
        {nextLabel || t('components:stepper.next')}
      </PrimaryButton>
    </>
  );
}

export default function DefaultStepperFooter({
  handleCancel,
  handleNext,
  handlePrevious,
  info,
  infoOnError,
  isNextDisabled,
  nextLabel,
  previousDisabled,
}) {
  const { t } = useTranslation();

  return (
    <>
      <Divider />
      <DefaultWrapper>
        <FlexContainer>
          <OutlinedButton color="dark060" disabled={previousDisabled} onClick={handlePrevious}>
            {t('components:stepper.previous')}
          </OutlinedButton>
          {!!handleCancel && (
            <TextButton color="dark060" onClick={handleCancel}>
              {t('components:stepper.cancel')}
            </TextButton>
          )}
        </FlexContainer>
        <FlexContainer>
          {info && <Stepperinfo isError={infoOnError}>{info}</Stepperinfo>}
          <PrimaryButton disabled={isNextDisabled} minWidth="150px" onClick={handleNext}>
            {nextLabel || t('components:stepper.next')}
          </PrimaryButton>
        </FlexContainer>
      </DefaultWrapper>
    </>
  );
}
