import { nanoid } from 'nanoid';
import { useMemo } from 'react';

function WarningIconLarge(props) {
  const maskId = useMemo(() => nanoid(), []);

  return (
    <svg fill="none" height="17" viewBox="0 0 17 17" width="17" {...props}>
      <g>
        <mask
          height="18"
          id={maskId}
          maskUnits="userSpaceOnUse"
          style={{ maskType: 'alpha' }}
          width="17"
          x="0"
          y="0"
        >
          <path
            d="M16.5275 8.50531C16.5275 12.942 12.9332 16.5381 8.5 16.5381C4.06682 16.5381 0.472517 12.942 0.472517 8.50531C0.472517 4.06864 4.06682 0.472517 8.5 0.472517C12.9332 0.472517 16.5275 4.06864 16.5275 8.50531Z"
            stroke="#FF4D64"
            strokeWidth="0.945035"
          />
          <path
            d="M8.50427 4.09099H8.75204V10.3296H8.50427V4.09099ZM8.38365 12.0385H8.87266V12.5276H8.38365V12.0385Z"
            fill="#FF4D64"
            stroke="#FF4D64"
            strokeWidth="0.945035"
          />
        </mask>
        <g mask={`url(#${maskId})`}>
          <rect fill="#FF4D64" height="20" width="20" x="-2" y="-1" />
        </g>
      </g>
    </svg>
  );
}

export default WarningIconLarge;
