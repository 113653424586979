import React from 'react';

import SvgWrapper from './SvgWrapper';

const AnalyzeIcon = (props) => (
  <SvgWrapper {...props} viewBox="0 0 23 23">
    <circle cx="11.5" cy="11.5" fill="#FF4580" r="11.5" />
    <path
      clipRule="evenodd"
      d="M17.1123 11.4438C17.1123 11.3853 17.1114 11.3269 17.1096 11.2688L18.1088 11.2285C18.1111 11.3 18.1123 11.3718 18.1123 11.4438C18.1123 15.0585 15.1708 18 11.5562 18C10.8379 18 10.1462 17.8839 9.49896 17.6694L9.83194 16.7259C10.375 16.9038 10.9546 17 11.5562 17C14.6186 17 17.1123 14.5063 17.1123 11.4438ZM14.1781 6.54672L14.5835 5.62956C13.6773 5.15579 12.6474 4.8877 11.5562 4.8877C7.94146 4.8877 5 7.82915 5 11.4438C5 11.8906 5.04494 12.3272 5.13054 12.7491L6.12797 12.6318C6.04417 12.2489 6 11.8514 6 11.4438C6 8.38144 8.49374 5.8877 11.5562 5.88769C12.5036 5.88769 13.3966 6.12637 14.1781 6.54672ZM9 11.5C9 10.1226 10.1226 9 11.5 9C12.8774 9 14 10.1226 14 11.5C14 12.8774 12.8774 14 11.5 14C10.1226 14 9 12.8774 9 11.5ZM11.5 8C9.5703 8 8 9.57029 8 11.5C8 13.4297 9.5703 15 11.5 15C13.4297 15 15 13.4297 15 11.5C15 9.57029 13.4297 8 11.5 8Z"
      fill="white"
      fillRule="evenodd"
    />
  </SvgWrapper>
);

export default AnalyzeIcon;
