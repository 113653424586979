export const API_ORGANIZATION_ROLE_ADMIN = 'ADMIN';
export const API_ORGANIZATION_ROLE_MEMBER = 'MEMBER';

export const API_WORKSPACE_ROLE_OWNER = 'OWNER';
export const API_WORKSPACE_ROLE_MEMBER = 'MEMBER';
export const API_WORKSPACE_ROLE_GUEST_WRITER = 'GUEST_WRITER';
export const API_WORKSPACE_ROLE_LIMITED_GUEST_WRITER = 'LIMITED_GUEST_WRITER';

export const organizationUserRoles = [
  {
    description: 'components:settings.users-settings.admin-description',
    id: API_ORGANIZATION_ROLE_ADMIN,
    key: 'admin',
    label: 'components:settings.users-settings.admin-title',
  },
  {
    description: 'components:settings.users-settings.member-organization-description',
    id: API_ORGANIZATION_ROLE_MEMBER,
    key: 'member',
    label: 'components:settings.users-settings.member-organization-title',
  },
];

export const userRoles = [
  {
    description: 'components:settings.users-settings.owner-description',
    id: API_WORKSPACE_ROLE_OWNER,
    key: 'owner',
    label: 'components:settings.users-settings.owner-title',
  },
  {
    description: 'components:settings.users-settings.member-workspace-description',
    id: API_WORKSPACE_ROLE_MEMBER,
    key: 'member',
    label: 'components:settings.users-settings.member-workspace-title',
  },
  {
    description: 'components:settings.users-settings.guest-writer-description',
    id: API_WORKSPACE_ROLE_GUEST_WRITER,
    key: 'guest-writer',
    label: 'components:settings.users-settings.guest-writer-title',
  },
  {
    description: 'components:settings.users-settings.limited-member-description',
    id: API_WORKSPACE_ROLE_LIMITED_GUEST_WRITER,
    key: 'limited-guest-writer',
    label: 'components:settings.users-settings.limited-member-title',
  },
];

export const organizationRules = {
  [API_ORGANIZATION_ROLE_ADMIN]: {
    dynamic: {
      'create-workspace:exec': ({ limitReached }) => {
        return !limitReached;
      },
    },
    static: ['current-organization:visit', 'organization-admin-settings:visit'],
  },
  [API_ORGANIZATION_ROLE_MEMBER]: {
    dynamic: {},
    static: ['current-organization:visit'],
  },
};

export const workspaceRules = {
  [API_WORKSPACE_ROLE_GUEST_WRITER]: {
    dynamic: {
      'editor-page:visit': ({ accessGranted }) => accessGranted,
      'planning-page:visit': ({ accessGranted }) => accessGranted,
    },
    static: ['current-workspace:visit', 'create-or-update-folder:exec'],
  },
  [API_WORKSPACE_ROLE_LIMITED_GUEST_WRITER]: {
    dynamic: {
      'editor-page:visit': ({ accessGranted }) => accessGranted,
      'planning-page:visit': ({ accessGranted }) => accessGranted,
    },
    static: ['current-workspace:visit'],
  },
  [API_WORKSPACE_ROLE_MEMBER]: {
    dynamic: {
      'brand-voice-page:visit': ({ accessGranted }) => accessGranted,
      'editor-keyword-position:display': ({ hasSearchConsole }) => hasSearchConsole,
      'editor-keyword-traffic:display': ({ hasSearchConsole }) => hasSearchConsole,
      'editor-monthly-traffic:display': ({ hasSearchConsole }) => hasSearchConsole,
      'editor-page:visit': ({ accessGranted }) => accessGranted,
      'editor-performances-panel:visit': ({ accessGranted }) => accessGranted,
      'online-contents-page:visit': ({ accessGranted }) => accessGranted,
      'planning-page:visit': ({ accessGranted }) => accessGranted,
      'reports-page:visit': ({ accessGranted }) => accessGranted,
      'workspace-member-settings:visit': ({ accessGranted }) => accessGranted,
    },
    static: [
      'current-workspace:visit',
      'dashboard-page:visit',
      'app-menu:display',
      'onboarding-workspace:visit',
      'create-or-update-folder:exec',
    ],
  },
  [API_WORKSPACE_ROLE_OWNER]: {
    dynamic: {
      'brand-voice-page:visit': ({ accessGranted }) => accessGranted,
      'editor-keyword-position:display': ({ hasSearchConsole }) => hasSearchConsole,
      'editor-keyword-traffic:display': ({ hasSearchConsole }) => hasSearchConsole,
      'editor-monthly-traffic:display': ({ hasSearchConsole }) => hasSearchConsole,
      'editor-page:visit': ({ accessGranted }) => accessGranted,
      'editor-performances-panel:visit': ({ accessGranted }) => accessGranted,
      'online-contents-page:visit': ({ accessGranted }) => accessGranted,
      'planning-page:visit': ({ accessGranted }) => accessGranted,
      'reports-page:visit': ({ accessGranted }) => accessGranted,
      'workspace-member-settings:visit': ({ accessGranted }) => accessGranted,
      'workspace-owner-settings:visit': ({ accessGranted }) => accessGranted,
    },
    static: [
      'current-workspace:visit',
      'dashboard-page:visit',
      'app-menu:display',
      'onboarding-workspace:visit',
      'invite-users:exec',
      'create-or-update-folder:exec',
    ],
  },
};

export const SUPER_ADMIN_PERFORM_ACTION = 'only-super-admin-user:visit';
