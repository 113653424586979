import styled from 'styled-components/macro';

import Flex from '@/design-system/components/Flex';

export const ClusterRow = styled(Flex)`
  font-size: ${({ theme }) => theme.textCss.sizes.sm};
  font-weight: ${({ theme }) => theme.textCss.weights.medium};
  line-height: 135%;
  width: 100%;
  margin-bottom: 2px;
  background-color: ${({ theme, selected }) => selected && theme.colors.primaryPink5};
  border-radius: 0.5rem;
  box-sizing: border-box;
  :hover {
    background-color: ${({ theme }) => theme.colors.primaryPink5};
  }
`;
