import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Switch from '@material-ui/core/Switch';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { copyToClipboard } from 'semji-core/utils/clipboard';
import styled from 'styled-components/macro';

import FileIcon from '@/components/icons/FileIcon';
import { DarkerText } from '@/components/Text/Dark';
import { ExtraSmallGreyText } from '@/components/Text/Light';
import { showSuccessSnackbar, showWarningSnackbar } from '@/store/actions/ui';

const Title = styled(DarkerText)`
  display: block;
  margin-top: 3rem;
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
`;
const Wrapper = styled.div`
  padding: 1rem 0;
`;
const CopyIcon = styled(FileIcon)`
  cursor: pointer;
  margin: 0 0.5rem;
`;
const CustomInput = styled((props) => <OutlinedInput {...props} classes={{ input: 'input' }} />)`
  && {
    font-size: 0.8rem;
    padding: 0;
    .input {
      padding: 0.7rem;
      background-color: ${(props) =>
        props.disabled ? props.theme.colors.lightGreyOpaque : 'transparent'};
    }
  }
`;
const CustomFormHelperText = styled(FormHelperText)`
  && {
    font-size: 0.6rem;
  }
`;
const CustomLightText = styled(ExtraSmallGreyText)`
  display: block;
  padding-bottom: 0.5rem;
`;
const StyledInput = styled(
  ({
    id,
    label,
    helperText,
    placeholder,
    onChange,
    value,
    multiline,
    disabled,
    endAdornment,
    error,
    noPadding,
    ...props
  }) => (
    <div {...props}>
      <CustomLightText>{label}</CustomLightText>
      <FormControl fullWidth>
        <CustomInput
          aria-describedby={`${id}-helper`}
          disabled={disabled}
          endAdornment={endAdornment}
          error={error}
          fullWidth
          id={id}
          multiline={multiline}
          placeholder={placeholder}
          rows={multiline ? 3 : 1}
          rowsMax={3}
          value={value}
          variant="outlined"
          onChange={onChange}
        />
        <CustomFormHelperText id={`${id}-helper`}>{helperText}</CustomFormHelperText>
      </FormControl>
    </div>
  )
)`
  padding-top: ${(props) => (props.noPadding ? '0' : '1rem')};
`;
const StyledFormControlLabel = styled((props) => (
  <FormControlLabel {...props} classes={{ label: 'label' }} />
))`
  && {
    padding: 0.5em 1em 0em;
    height: 25px;
    .label {
      font-size: 0.8rem;
    }
  }
`;

const StyledSwitchFormControlLabel = styled((props) => (
  <FormControlLabel {...props} classes={{ label: 'label' }} />
))`
  && {
    padding-top: 0.5em;
    .label {
      font-size: 0.8rem;
    }
  }
`;

export default function SecuritySettings(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <Wrapper>
      <ExtraSmallGreyText>
        {t('components:security-settings.label-saml-options')}
      </ExtraSmallGreyText>
      <RadioGroup
        value={props.samlOption || 'OFF'}
        onChange={(e) => !props.configDisabled && props.onSamlOptionChange(e.target.value)}
      >
        <StyledFormControlLabel
          control={<Radio size="small" />}
          disabled={props.configDisabled}
          label={t('components:security-settings.label-off')}
          value="OFF"
        />
        <StyledFormControlLabel
          control={<Radio size="small" />}
          disabled={props.configDisabled}
          label={t('components:security-settings.label-optional')}
          value="OPTIONAL"
        />
        <StyledFormControlLabel
          control={<Radio size="small" />}
          disabled={props.configDisabled}
          label={t('components:security-settings.label-on')}
          value="ON"
        />
      </RadioGroup>

      <Title medium>{t('components:security-settings.title-service-provider')}</Title>

      <StyledInput
        disabled
        endAdornment={
          <CopyIcon
            onClick={() => {
              if (props.samlServiceProviderAcsUrl) {
                copyToClipboard(props.samlServiceProviderAcsUrl);
                dispatch(showSuccessSnackbar(t('components:security-settings.label-success-copy')));
              } else {
                dispatch(showWarningSnackbar(t('components:security-settings.label-error-copy')));
              }
            }}
          />
        }
        helperText=""
        id="samlServiceProviderAcsUrl"
        label={t('components:security-settings.input-semji-acs-label')}
        placeholder={t('components:security-settings.input-semji-acs-label')}
        value={props.samlServiceProviderAcsUrl}
      />
      <StyledInput
        disabled
        endAdornment={
          <CopyIcon
            onClick={() => {
              if (props.samlServiceProviderEntityId) {
                copyToClipboard(props.samlServiceProviderEntityId);
                dispatch(showSuccessSnackbar(t('components:security-settings.label-success-copy')));
              } else {
                dispatch(showWarningSnackbar(t('components:security-settings.label-error-copy')));
              }
            }}
          />
        }
        helperText=""
        id="samlServiceProviderEntityId"
        label={t('components:security-settings.input-semji-entity-identifier-label')}
        placeholder={t('components:security-settings.input-semji-entity-identifier-label')}
        value={props.samlServiceProviderEntityId}
      />

      <StyledSwitchFormControlLabel
        control={
          <Switch
            checked={props.signSpRequests ?? false}
            onChange={(e) => props.onSignSpRequestsChange(e.target.checked)}
          />
        }
        disabled={props.disabled}
        label={t('components:security-settings.switch-signature-label')}
      />

      {!props.disabled && props.signSpRequests ? (
        <StyledInput
          disabled
          endAdornment={
            <CopyIcon
              message={
                props.samlServiceProviderX509cert
                  ? t('components:security-settings.label-success-copy')
                  : t('components:security-settings.label-error-copy')
              }
              variant={props.samlServiceProviderX509cert ? 'success' : 'warning'}
              onClick={(e) => {
                props.samlServiceProviderX509cert &&
                  copyToClipboard(props.samlServiceProviderX509cert);
              }}
            />
          }
          helperText=""
          id="samlServiceProviderX509cert"
          label={t('components:security-settings.input-semji-certificate-label')}
          multiline
          noPadding
          placeholder={t('components:security-settings.input-semji-certificate-label')}
          value={props.samlServiceProviderX509cert}
        />
      ) : null}

      <Title medium>{t('components:security-settings.title-identity-provider')}</Title>
      <StyledInput
        disabled={props.disabled}
        error={props.inputsOnError.identityProviderUrl}
        helperText={t('components:security-settings.input-identity-provider-helper')}
        id="identityProviderUrl"
        label={t('components:security-settings.input-sign-in-page-url-label')}
        placeholder={t('components:security-settings.input-sign-in-page-url-placeholder')}
        value={props.identityProviderUrl}
        onChange={(e) => props.onIdentityProviderUrlChange(e.target.value)}
      />
      <StyledInput
        disabled={props.disabled}
        error={props.inputsOnError.identityProviderEntityId}
        helperText={t('components:security-settings.input-identity-provider-helper')}
        id="identityProviderEntityId"
        label={t('components:security-settings.input-entity-identifier-label')}
        placeholder={t('components:security-settings.input-entity-identifier-placeholder')}
        value={props.identityProviderEntityId}
        onChange={(e) => props.onIdentityProviderEntityIdChange(e.target.value)}
      />
      <StyledInput
        disabled={props.disabled}
        error={props.inputsOnError.certificate}
        helperText={t('components:security-settings.input-identity-provider-helper')}
        id="certificate"
        label={t('components:security-settings.input-certificate-label')}
        multiline
        placeholder={t('components:security-settings.input-certificate-placeholder')}
        value={props.certificate}
        onChange={(e) => props.onCertificateChange(e.target.value)}
      />
    </Wrapper>
  );
}
