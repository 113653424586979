// Use context values from Google
// https://developers.google.com/identity/gsi/web/reference/js-reference#context
export const SIGNIN = 'signin';
export const SIGNUP = 'signup';
export const USE = 'use';

export const ERROR_API_ACCOUNT_NOT_FOUND = 'Authentication Required';
export const ERROR_FRONT_EMAIL_NOT_CORRESPONDING = 'ERROR_FRONT_EMAIL_NOT_CORRESPONDING';
export const ERROR_FRONT_TOKEN_NOT_FOUND = 'ERROR_FRONT_TOKEN_NOT_FOUND';
export const ERROR_GOOGLE_POPUP_CLOSED = 'popup_closed_by_user';
export const ERROR_GOOGLE_SCRIPT_FAILURE = 'idpiframe_initialization_failed';
export const ERROR_EMAIL_CHECKER = 'This domain is not authorized';
