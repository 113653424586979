import { useMutation } from '@tanstack/react-query';

import apiClient from '@/apis/semji/apiClient';

export default function useDeleteThread({ ...props }) {
  const { delete: deleteRequest } = apiClient;

  return useMutation({
    mutationFn: ({ threadId }) => deleteRequest(`threads/${threadId}`),
    ...props,
  });
}
