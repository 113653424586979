import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import DefaultStepperFooter from '@/components/Stepper/StepperFooter';

const StepperWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem 6rem 0px;
  flex: 1;
`;
const StepTitle = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  color: ${({ theme }) => theme.cssColors.dark100};
  font-size: ${({ theme }) => theme.textCss.sizes.xxl};
  text-align: center;
`;
const StepSubTitle = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  color: ${({ theme }) => theme.cssColors.dark040};
  font-size: ${({ theme }) => theme.textCss.sizes.md};
  margin: 1em;
  text-align: center;
`;
const StepperBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export default function Stepper(props) {
  const { t } = useTranslation();

  return (
    <StepperWrapper>
      <div>
        <StepSubTitle>{t(props.header.step)}</StepSubTitle>
        <StepTitle>{t(props.header.title)}</StepTitle>
        {!!props.header.subTitle && <StepSubTitle>{props.header.subTitle}</StepSubTitle>}
      </div>
      <StepperBody>{props.children}</StepperBody>
      <DefaultStepperFooter
        handleCancel={props.handleCancel}
        handleNext={props.handleNext}
        handlePrevious={props.handlePrevious}
        info={props.info}
        infoOnError={props.infoOnError}
        isNextDisabled={props.isNextDisabled}
        nextLabel={props.nextLabel}
        previousDisabled={props.previousDisabled}
      />
    </StepperWrapper>
  );
}
