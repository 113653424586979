import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { FlexWrapContainer } from '@/components/_common';
import PortalButton from '@/components/Button/PortalButton';
import { TitleFour } from '@/components/Text/Title';
import BillingService from '@/services/Billing';
import { selectSubscriptionResourceVersion } from '@/store/selectors/selectSubscriptionResourceVersion';
import { Log } from '@/utils/log/Log';

import { Section } from '.';

const SECTIONS = ['PAYMENT_SOURCES', 'ADDRESS'];

function InvoiceInformation() {
  const { t } = useTranslation();
  const { organizationId } = useParams();
  const _BillingService = new BillingService();
  const [loadingSections, setLoadingSections] = useState(SECTIONS);
  const [information, setInformation] = useState({});
  const subscriptionResourceVersion = useSelector(selectSubscriptionResourceVersion);

  useEffect(() => {
    getInformation(SECTIONS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionResourceVersion]);

  const getInformation = async (sectionTypes) => {
    try {
      setLoadingSections(sectionTypes);
      const clientInformation = await _BillingService.information(organizationId);

      setInformation(clientInformation);
      setLoadingSections([]);
    } catch (e) {
      setLoadingSections([]);
    }
  };

  const openPortalSession = (sectionType) => {
    try {
      const cbInstance = window.Chargebee.getInstance();

      cbInstance.setPortalSession(async () => {
        const session = await _BillingService.session(
          organizationId,
          `http://localhost/o/${organizationId}/settings/billing`
        );
        return session.portalSession;
      });

      const cbPortal = cbInstance.createChargebeePortal();

      cbPortal.openSection(
        {
          // PAYMENT_SOURCES
          // ADDRESS
          sectionType: window.Chargebee.getPortalSections()[sectionType],
        },
        {
          close: () => getInformation([sectionType]),
        }
      );
    } catch (error) {
      // error getting chargebee instance
      Log.report({
        error,
      });
    }
  };

  function getCardData(paymentMethod = {}) {
    const data = [];

    if (null === paymentMethod) {
      return [t('settings:workspace.billing-information.no-payment-method-label')];
    }

    if (paymentMethod.brand) {
      data.push(
        t('settings:workspace.billing-information.payment-brand-label', {
          brand: paymentMethod.brand,
        })
      );
    }

    if (paymentMethod.firstName || paymentMethod.lastName) {
      data.push(`${paymentMethod.firstName ?? ''} ${paymentMethod.lastName ?? ''}`);
    }

    if (paymentMethod.maskedNumber) {
      data.push(`${paymentMethod.maskedNumber}`);
    }

    if (paymentMethod.expiryMonth && paymentMethod.expiryYear) {
      data.push(
        t('settings:workspace.billing-information.payment-expiration-label', {
          month: paymentMethod.expiryMonth,
          year: paymentMethod.expiryYear,
        })
      );
    }

    return data;
  }

  function getAddressData(billingAddress = {}) {
    const data = [];

    if (null === billingAddress) {
      return [t('settings:workspace.billing-information.no-billing-address-label')];
    }

    if (billingAddress.company) {
      data.push(billingAddress.company);
    }

    if (billingAddress.addressLine1) {
      data.push(billingAddress.addressLine1);
    }

    if (billingAddress.addressLine2) {
      data.push(billingAddress.addressLine2);
    }

    if (billingAddress.addressLine3) {
      data.push(billingAddress.addressLine3);
    }

    if (billingAddress.zip && billingAddress.city) {
      data.push(`${billingAddress.zip} ${billingAddress.city}`);
    }

    if (billingAddress.countryLabel) {
      data.push(billingAddress.countryLabel);
    }

    return data;
  }

  return (
    <Section>
      <TitleFour noMargin weight="strong">
        {t('settings:workspace.billing-information.title')}
      </TitleFour>
      <br />
      <FlexWrapContainer>
        <PortalButton
          data={getCardData(information.paymentMethod)}
          loading={loadingSections.includes(SECTIONS[0])}
          noMarginLeft
          title={t('settings:workspace.billing-information.button-payment')}
          onClick={() => openPortalSession(SECTIONS[0])}
        />
        <PortalButton
          data={getAddressData(information.billingAddress)}
          loading={loadingSections.includes(SECTIONS[1])}
          title={t('settings:workspace.billing-information.button-billing')}
          onClick={() => openPortalSession(SECTIONS[1])}
        />
      </FlexWrapContainer>
    </Section>
  );
}

export default InvoiceInformation;
