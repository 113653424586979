import React, { Component, ErrorInfo } from 'react';

import { DefaultErrorFallback } from './DefaultErrorFallback';
// import { Log } from '@/utils/log/Log';
import { ErrorBoundaryProps, ErrorBoundaryState } from './ErrorBoundary.types';

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    //Log.error('Error caught', {error, info})
    // Here we can log the error to an error reporting service
  }
  render() {
    const { fallback, children } = this.props;
    if (this.state.hasError) {
      return fallback || <DefaultErrorFallback />;
    }

    return children;
  }
}

export default ErrorBoundary;
