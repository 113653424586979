import { useQuery } from '@tanstack/react-query';

import apiClient from '@/apis/semji/apiClient';
import { SCOPE_CONTENTS } from '@/apis/semji/constants';

import { DEFAULT_CONTENT_PROPERTIES } from '../const';
import { UseGetContentByIdProps } from './useGetContentById.types';

export default function useGetContentById({ contentId, ...props }: UseGetContentByIdProps) {
  const { get } = apiClient;
  return useQuery({
    enabled: !!contentId,
    queryFn: async ({ signal }) => {
      const result = await get(`/contents/${contentId}`, {
        params: {
          properties: { ...DEFAULT_CONTENT_PROPERTIES },
        },
        signal,
      });

      return result.data;
    },
    queryKey: [SCOPE_CONTENTS.CONTENT, contentId],
    ...props,
  });
}
