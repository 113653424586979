import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import EmptyStateReportsAreaLg from '@/assets/images/empty_state_reports_area_chart_lg.svg';
import EmptyStateReportsAreaMd from '@/assets/images/empty_state_reports_area_chart_md.svg';
import EmptyStateReportsAreaSm from '@/assets/images/empty_state_reports_area_chart_sm.svg';
import EmptyStateReportsBarLg from '@/assets/images/empty_state_reports_bar_chart_lg.svg';
import EmptyStateReportsBarMd from '@/assets/images/empty_state_reports_bar_chart_md.svg';
import EmptyStateReportsBarSm from '@/assets/images/empty_state_reports_bar_chart_sm.svg';
import EmptyStateReportsLineLg from '@/assets/images/empty_state_reports_line_chart_lg.svg';
import EmptyStateReportsLineMd from '@/assets/images/empty_state_reports_line_chart_md.svg';
import EmptyStateReportsLineSm from '@/assets/images/empty_state_reports_line_chart_sm.svg';
import EmptyStateReportsLineFullLg from '@/assets/images/empty_state_reports_line_full_chart_lg.svg';
import EmptyStateReportsLineFullMd from '@/assets/images/empty_state_reports_line_full_chart_md.svg';
import EmptyStateReportsStackedBarLg from '@/assets/images/empty_state_reports_stacked_bar_lg.svg';
import EmptyStateReportsStackedBarMd from '@/assets/images/empty_state_reports_stacked_bar_md.svg';
import EmptyStateReportsStackedBarSm from '@/assets/images/empty_state_reports_stacked_bar_sm.svg';
import EmptyStateCourbe from '@/assets/images/empty-state-courbe.svg';
import EmptyStateCourbeLongue from '@/assets/images/empty-state-courbe-longue.svg';
import { SimpleBodyEmptyState, WrapperWithBackground } from '@/components/EmptyState/commons';
import { ReportMetricChartType } from '@/containers/Report/utils/types';

export const NO_ANALYTICS_DASHBOARD = 'noAnalyticsDashboard';
export const NO_SEARCH_CONSOLE_DASHBOARD = 'noSearchConsoleDashboard';
export const NO_ANALYTICS_REPORT = 'noAnalyticsReport';
export const NO_SEARCH_CONSOLE_REPORT = 'noSearchConsoleReport';

type versionType =
  | typeof NO_ANALYTICS_DASHBOARD
  | typeof NO_SEARCH_CONSOLE_DASHBOARD
  | typeof NO_ANALYTICS_REPORT
  | typeof NO_SEARCH_CONSOLE_REPORT;

const NoServiceIntegrationCurve = ({
  version,
  chartType,
  isOwner = true,
  isFullWidth = false,
}: {
  version: versionType;
  chartType?: ReportMetricChartType;
  isOwner: boolean;
  isFullWidth?: boolean;
}) => {
  const { t } = useTranslation();
  const { organizationId, workspaceId } = useParams();

  const NO_REPORT_BAR_IMAGES = {
    imageUrl: EmptyStateReportsBarLg,
    imageUrlDesktop: EmptyStateReportsBarMd,
    imageUrlLaptop: EmptyStateReportsBarSm,
  };

  const NO_REPORT_LINE_IMAGES = {
    imageUrl: EmptyStateReportsLineLg,
    imageUrlDesktop: EmptyStateReportsLineMd,
    imageUrlLaptop: EmptyStateReportsLineSm,
  };

  const NO_REPORT_LINE_FULL_IMAGES = {
    imageUrl: EmptyStateReportsLineFullLg,
    imageUrlDesktop: EmptyStateReportsLineFullMd,
    imageUrlLaptop: EmptyStateReportsLineSm,
  };

  const NO_REPORT_LINE_AREA_IMAGES = {
    imageUrl: EmptyStateReportsAreaLg,
    imageUrlDesktop: EmptyStateReportsAreaMd,
    imageUrlLaptop: EmptyStateReportsAreaSm,
  };

  const NO_REPORT_STACKED_BAR_IMAGES = {
    imageUrl: EmptyStateReportsStackedBarLg,
    imageUrlDesktop: EmptyStateReportsStackedBarMd,
    imageUrlLaptop: EmptyStateReportsStackedBarSm,
  };

  function getEmptyStateImage(): {
    imageUrl?: string;
    imageUrlDesktop?: string;
    imageUrlLaptop?: string;
  } {
    switch (chartType) {
      case ReportMetricChartType.Bar:
        return NO_REPORT_BAR_IMAGES;
      case ReportMetricChartType.Line:
        return isFullWidth ? NO_REPORT_LINE_FULL_IMAGES : NO_REPORT_LINE_IMAGES;
      case ReportMetricChartType.Area:
        return NO_REPORT_LINE_AREA_IMAGES;
      case ReportMetricChartType.Column:
        return NO_REPORT_STACKED_BAR_IMAGES;
      default:
        return {};
    }
  }

  const NO_SERVICE_INTEGRATION_VALUES = {
    [NO_ANALYTICS_DASHBOARD]: {
      buttonLabel: t(
        `empty-state:no-service-integration-curve.no-analytics-dashboard.${
          isOwner ? 'button' : 'no-text'
        }`
      ),
      href: `/o/${organizationId}/w/${workspaceId}/settings/integrations/`,
      imageUrl: EmptyStateCourbe,
      title: t('empty-state:no-service-integration-curve.no-analytics-dashboard.title'),
    },
    [NO_ANALYTICS_REPORT]: {
      buttonLabel: t(
        `empty-state:no-service-integration-curve.no-analytics-report.${
          isOwner ? 'button' : 'no-text'
        }`
      ),
      href: `/o/${organizationId}/w/${workspaceId}/settings/integrations/`,
      imageUrl: EmptyStateCourbeLongue,
      title: t('empty-state:no-service-integration-curve.no-analytics-report.title'),
    },
    [NO_SEARCH_CONSOLE_DASHBOARD]: {
      buttonLabel: t(
        `empty-state:no-service-integration-curve.no-search-console-dashboard.${
          isOwner ? 'button' : 'no-text'
        }`
      ),
      href: `/o/${organizationId}/w/${workspaceId}/settings/integrations/search_console`,
      imageUrl: EmptyStateCourbe,
      title: t('empty-state:no-service-integration-curve.no-search-console-dashboard.title'),
    },
    [NO_SEARCH_CONSOLE_REPORT]: {
      buttonLabel: t(
        `empty-state:no-service-integration-curve.no-search-console-report.${
          isOwner ? 'button' : 'no-text'
        }`
      ),
      href: `/o/${organizationId}/w/${workspaceId}/settings/integrations/search_console`,
      imageUrl: EmptyStateCourbeLongue,
      title: t('empty-state:no-service-integration-curve.no-search-console-report.title'),
    },
  };

  return (
    <WrapperWithBackground
      imageUrl={NO_SERVICE_INTEGRATION_VALUES[version].imageUrl}
      {...getEmptyStateImage()}
    >
      <SimpleBodyEmptyState
        buttonLabel={NO_SERVICE_INTEGRATION_VALUES[version].buttonLabel}
        title={NO_SERVICE_INTEGRATION_VALUES[version].title}
        url={isOwner && NO_SERVICE_INTEGRATION_VALUES[version].href}
      />
    </WrapperWithBackground>
  );
};

export default NoServiceIntegrationCurve;
