import { UserModel as User } from '@/types/user/user.types';
import { Workspace } from '@/types/workspace/Workspace.types';

export enum WorkspaceAccessRoles {
  OWNER = 'OWNER',
  MEMBER = 'MEMBER',
  ROLE_GUEST_WRITER = 'GUEST_WRITER',
  ROLE_LIMITED_GUEST_WRITER = 'LIMITED_GUEST_WRITER',
}

export interface WorkspaceAccess {
  id: string;
  user: User;
  workspace: Workspace;
  createdAt: string;
  accessToWebExtension: boolean;
  accessToSearchIntelligence: boolean;
  accessToAiFeatures: boolean;
  calendarExportToken: string;
  roles: WorkspaceAccessRoles[];
}
