import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { OutlinedButton } from '@/components/Button/Button';
import ConfirmChangePlanDialog from '@/components/Dialog/ConfirmChangePlanDialog';
import WaitChangePlanDialog from '@/components/Dialog/WaitChangePlanDialog';
import BillingService from '@/services/Billing';
import { showErrorSnackbar } from '@/store/actions/ui';
import {
  fetchCurrentOrganizationEditableData,
  fetchOrganization,
} from '@/store/actionsCreator/organization';
import { selectSubscriptionResourceVersion } from '@/store/selectors/selectSubscriptionResourceVersion';
import recursiveDelayedCallback from '@/utils/recursiveDelayedCallback';

function ReactivatePlan({ fullWidth }) {
  const _billingService = new BillingService();
  const { t } = useTranslation();
  const [modalsState, setModalsState] = useState({
    successModalShow: false,
    waitModalShow: false,
  });
  const dispatch = useDispatch();
  const organizationId = useSelector((state) => state.defaultOrganizationId);
  const subscriptionResourceVersion = useSelector(selectSubscriptionResourceVersion);

  const reactivatePlan = async () => {
    try {
      await _billingService.reactivateSubscription(organizationId);
      // wait for reactivate loading logic....
      onSuccessWaitForWebhookUpdate();
    } catch (e) {
      dispatch(showErrorSnackbar(t('billing:reactivate-plan.cant-be-reactivated')));
    }
  };

  const fetchOrganizationUntilVersionChange = () => {
    recursiveDelayedCallback(async () => {
      const dispatchActionResponse = await dispatch(
        fetchCurrentOrganizationEditableData(organizationId)
      );
      return dispatchActionResponse.subscriptionResourceVersion !== subscriptionResourceVersion;
    }, onVersionChange);
  };

  const onSuccessWaitForWebhookUpdate = () => {
    setModalsState({
      successModalShow: false,
      waitModalShow: true,
    });
    // wait for reactivate loading logic....
    fetchOrganizationUntilVersionChange();
  };

  const onVersionChange = async () => {
    await dispatch(fetchOrganization(organizationId));
    setModalsState({
      successModalShow: true,
      waitModalShow: false,
    });
  };

  const goToSemji = () => {
    // close everything...
    setModalsState({
      successModalShow: false,
      waitModalShow: false,
    });
  };

  return (
    <>
      <OutlinedButton fullWidth={fullWidth} weight="medium" onClick={reactivatePlan}>
        {t('billing:reactivate-plan.reactivate-plan')}
      </OutlinedButton>
      <WaitChangePlanDialog
        show={modalsState.waitModalShow}
        title={t('billing:reactivate-plan.reactivating-your-plan')}
      />
      <ConfirmChangePlanDialog
        show={modalsState.successModalShow}
        title={t('billing:reactivate-plan.subscription-cancelled')}
        onClose={goToSemji}
      />
    </>
  );
}

export default ReactivatePlan;
