import './StackedColTooltip.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'semji-core/utils/string';

import { Point } from '@/components/Chart/Chart.types';
import { LegendItemIconPlain } from '@/components/Chart/LegendItem';

export default function StackedColTooltip({ metric, points }: { metric: any; points: Point[] }) {
  const { t } = useTranslation();
  const total = points.reduce((acc, point) => acc + parseInt(point.value), 0);

  function getTitle() {
    if (points[1]?.period) {
      return `${capitalize(points[0].period)} vs ${capitalize(points[1]?.period)}`;
    }
    return points[0].period;
  }
  return (
    <div className="highchart-charts-stacked-col-tooltip">
      <div className="highchart-charts-stacked-col-tooltip__period">{getTitle()}</div>
      <div className="highchart-charts-stacked-col-tooltip__list">
        {points.map((point, index) => {
          return (
            <div key={index} className="highchart-charts-stacked-col-tooltip__point">
              <div className="highchart-charts-stacked-col-tooltip__point__label">
                <LegendItemIconPlain color={point.color} />
                <span>{t(metric.rankingKeywordsMetrics[index].legendLabel)}</span>
              </div>
              <div>{point.value}</div>
            </div>
          );
        })}
        <div className="highchart-charts-stacked-col-tooltip__point highchart-charts-stacked-col-tooltip__point--total">
          <div className="highchart-charts-stacked-col-tooltip__point__label">
            {t('chart:tooltip.total')}
          </div>
          <div className="highchart-charts-stacked-col-tooltip__point__value">{total}</div>
        </div>
      </div>
    </div>
  );
}
