import React, { useId } from 'react';
import { IconSquare } from 'semji-core/components/IconSquare';
import { DeleteIcon } from 'semji-core/icons/DeleteIcon';

import { LOCATION } from '@/apis/semji/constants';
import useGetCurrentWorkspaceFocusTopKeywordsWithSpecificProperties from '@/apis/semji/topKeywords/useGetCurrentWorkspaceFocusTopKeywordsWithSpecificProperties';
import { TOP_PAGE_FK } from '@/components/FocusKeyword/constants';
import FocusTopKeywordAssociation from '@/components/FocusTopKeyword/FocusTopKeywordAssociation';
import FlatLoader, { LoaderWrapper } from '@/components/Loader/FlatLoader';
import FocusKeywordSelectorCell from '@/components/Table/Cell/FocusKeywordSelectorCell';
import TitleDefaultCell from '@/components/Table/Cell/TitleDefaultCell';
import Table from '@/components/Table/Table';
import useApiConfigurations from '@/hooks/useApiConfigurations';
import { LISTING_REFETCH_FREQUENCY_MS } from '@/utils/configurations/constants';

export default function KeywordsSelection(props) {
  const REFETCH_INTERVAL_IN_MS = parseInt(useApiConfigurations(LISTING_REFETCH_FREQUENCY_MS), 10);
  const id = useId();

  function handleRemoveUrl(pageId) {
    props.setState((prevState) => ({
      ...prevState,
      pagesToImport: prevState.pagesToImport.filter((page) => page.id !== pageId),
    }));
    props.setPages((prevState) => prevState.filter((page) => page.id !== pageId));
  }

  const { data: focusTopKeywordsWithSpecificProperties } =
    useGetCurrentWorkspaceFocusTopKeywordsWithSpecificProperties({
      filters: { inStock: false },
      location: LOCATION.PLANNING,
      properties: ['id', 'keyword', 'associatedToPagesCount', 'associatedToDraftsCount'],
      refetchInterval: REFETCH_INTERVAL_IN_MS,
      refetchOnWindowFocus: 'always',
    });
  const columns = [
    {
      cellDataGetter: ({ rowData }) => ({
        isRedirectionDetected: !!rowData.redirectionUrl,
        lastStatusCode: rowData.lastStatusCode,
        loading: !rowData.titleRetrievedAt || rowData.loading,
        title: rowData.title,
        url: rowData.url,
      }),
      cellRenderer: ({ cellData }) => (
        <TitleDefaultCell
          isRedirectionDetected={cellData.isRedirectionDetected}
          lastStatusCode={cellData.lastStatusCode}
          loading={cellData.loading}
          title={cellData.title}
          url={cellData.url}
        />
      ),
      dataKey: 'title',
      flexGrow: 1,
      label: 'Page',
      sortable: false,
      width: 150,
    },
    {
      cellDataGetter: ({ rowData }) => ({
        focusTopKeyword: rowData.focusTopKeyword || null,
      }),
      cellRenderer: ({ cellData }) => {
        const existingFocusKeyword = focusTopKeywordsWithSpecificProperties?.find(
          (keyword) => keyword?.keyword === cellData?.focusTopKeyword?.keyword
        );
        return existingFocusKeyword ? (
          <FocusTopKeywordAssociation focusTopKeyword={existingFocusKeyword} isNew />
        ) : null;
      },
      width: 20,
    },
    {
      cellDataGetter: ({ rowData }) => ({
        focusTopKeyword: rowData.focusTopKeyword || null,
        isRedirectionDetected: rowData.redirectionUrl,
        loading: null === rowData.topKeywordsRetrievedAt || rowData.loading,
        pageId: rowData.id,
      }),
      cellRenderer: ({ cellData }) =>
        cellData.isRedirectionDetected ? null : cellData.loading ? (
          <LoaderWrapper height={40} width={400}>
            <FlatLoader />
          </LoaderWrapper>
        ) : (
          <FocusKeywordSelectorCell
            key={cellData.focusTopKeyword?.id ?? id}
            data-intercom-target="existing_draft_focus_keyword_selector"
            focusTopKeyword={cellData.focusTopKeyword}
            hasSearchConsole={props.hasSearchConsole}
            pageId={cellData.pageId}
            setIsFetching={props.setIsFetching}
            variant={TOP_PAGE_FK}
            workspaceCountryName={props.workspaceCountryName}
          />
        ),
      dataKey: 'focusKeyword',
      label: 'Focus Keyword',
      sortable: false,
      width: 400,
    },
    {
      cellDataGetter: ({ rowData }) => ({
        pageId: rowData.id,
      }),
      cellRenderer: ({ cellData }) => (
        <IconSquare
          size="normal"
          variant="tertiary"
          onClick={() => handleRemoveUrl(cellData.pageId)}
        >
          <DeleteIcon />
        </IconSquare>
      ),
      width: 40,
    },
  ];

  return (
    <Table
      columns={columns}
      isRowDisabled={(page) => !!page?.redirectionUrl}
      mode="transparent"
      rowHeight={65}
      rows={props.rows}
    />
  );
}
