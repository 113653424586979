export enum ENUM_SEMJI_THREAD_ATTRIBUTES {
  SEMJI_THREAD_ATTRIBUTE = 'semjiAnnotationCommentThreads', // this is useful when we want to manipulate the dataset
  SEMJI_THREAD_DATA_ATTRIBUTE = 'data-semji-annotation-comment-threads',
  SEMJI_THREAD_RESOLVED_ATTRIBUTE = 'data-semji-annotation-comment-threads-resolved',
  SEMJI_THREAD_DISABLED_ATTRIBUTE = 'data-semji-annotation-comment-threads-disabled',
  SEMJI_THREAD_FOCUSED_ATTRIBUTE = 'data-semji-annotation-comment-threads-focused',
}

export enum ENUM_COMMENT_SIDE_PANEL_OPEN_STATUS {
  COMMENTS_ALL_VALUE = 'all',
  COMMENTS_OPEN_VALUE = 'open',
  COMMENTS_RESOLVED_VALUE = 'resolved',
}

export interface CommentModel {
  id: string;
  content: string;
  createdAt: string;
  createdById: string;
  threadId: string;
  updatedAt: string | null;
}

export interface NewThreadModel {
  comments: CommentModel[];
  thread: {
    id: string;
  };
}

export interface UseCommentsReturn {
  buttons: {
    COMMENTS_BUTTON: string;
  };
  closeComments: () => void;
  deleteComment: any;
  deleteThread: any;
  focusOnThread: (threadId: string) => void;
  allThreads: any[];
  isCommentsEnabled: boolean;
  isFocusOnThread: (threadId: string) => boolean;
  isLoadingThreads: boolean;
  isPendingThread: (threadId: string) => boolean;
  newThread: NewThreadModel | null;
  nbUnreadComments: number;
  openComments: (type: string) => void;
  openStatus: string | null;
  postComment: any;
  postResolveThread: any;
  postThread: any;
  postUnResolveThread: any;
  putComment: any;
  setupToolbarButtons: (editor: any) => void;
  setCommentsUpdate: (value: number | boolean) => void;
  isCommentEnabled: boolean;
}

// because it is temporary
export const DATA_MCE_ANNOTATION_UID = 'data-mce-annotation-uid';
export const DATA_MCE_ANNOTATION = 'data-mce-annotation';
