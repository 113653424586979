import { MutableRefObject, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import useGetCurrentOrganization from '@/apis/semji/organization/useGetOrganization';
import { LastGenerationRef } from '@/containers/Content/TinyMceComponents/Editor/hooks/useAiWriting/useAiWriting.types';
import useAtomicContent from '@/containers/Content/TinyMceComponents/Editor/hooks/useAiWriting/useAtomicContent';
import useFactCheck from '@/containers/Content/TinyMceComponents/Editor/hooks/useAiWriting/useFactCheck/useFactCheck';
import useFlagContent from '@/containers/Content/TinyMceComponents/Editor/hooks/useAiWriting/useFlagContent/useFlagContent';
import { useStreamContent } from '@/containers/Content/TinyMceComponents/Editor/hooks/useAiWriting/useStreamContent';
import useApiConfigurations from '@/hooks/useApiConfigurations';
import useOrganizationFeatureSet, {
  AI_WRITING__DEPRECATED_BRAND_VOICE__IS_ENABLED,
} from '@/hooks/useOrganizationFeatureSet';
import useParamSelector from '@/store/hooks/useParamSelector';
import { getOrganizationById } from '@/store/reducers';
import { selectBrandVoiceByWorkspaceId } from '@/store/selectors/selectBrandVoiceByWorkspaceId';
import { BrandVoiceModel } from '@/types/brandVoice/brandVoice.types';
import { CONTENT_AI_WRITING_CREDITS_ENABLED } from '@/utils/configurations/constants';

export const AI_WRITING_NODE = 'semji-ai-writing';

const AI_WRITING_FLAG_CONTENT_ICON = 'ai-writing-flag-icon';
const AI_WRITING_ATOMIC_CONTENT_BUTTON = 'ai-writing-atomic-content';

export function isNodeInsideTable(currentNode: any) {
  if (!currentNode) return null;
  if (currentNode.tagName === 'TABLE') return currentNode;
  if (!currentNode.parentNode) return null;
  return isNodeInsideTable(currentNode.parentNode);
}

function useAiWriting({
  editorRef,
  inputTitleRef,
  focusTopKeywordRef,
  setEditorAiWritingPopperOpen,
  triggerSaveRef,
  textareaMetaDescriptionRef,
}: {
  editorRef: any;
  inputTitleRef: any;
  focusTopKeywordRef: any;
  setEditorAiWritingPopperOpen: any;
  triggerSaveRef: any;
  textareaMetaDescriptionRef: any;
}) {
  const [refetchAiUnit, setRefetchAiUnit] = useState(0);
  const { workspaceId, organizationId } = useParams();
  const currentBrandVoice: BrandVoiceModel | null | undefined = useSelector((state) =>
    selectBrandVoiceByWorkspaceId(state, workspaceId)
  );
  const lastGeneration: MutableRefObject<LastGenerationRef> = useRef<LastGenerationRef>({
    creditAmount: undefined,
    id: undefined,
    isRegenerate: false,
    nodes: [],
    type: undefined,
  });
  const { isFeatureEnabled: hasUnlimitedAIWriting } = useOrganizationFeatureSet(
    'credits:ai-writing:has-unlimited-amount'
  );
  const { isFeatureEnabled: brandVoiceEnabled } = useOrganizationFeatureSet(
    AI_WRITING__DEPRECATED_BRAND_VOICE__IS_ENABLED
  );

  const originCurrentOrganization = useSelector((state) =>
    getOrganizationById(state, organizationId)
  );

  const aiWritingCreditsEnabled = useApiConfigurations(CONTENT_AI_WRITING_CREDITS_ENABLED);
  const { data: currentOrganization } = useGetCurrentOrganization({
    queryKey: [refetchAiUnit],
  });

  const remainingAiWritingUnit =
    (currentOrganization as any)?.remainingAiWritingUnit ||
    (originCurrentOrganization as any)?.remainingAiWritingUnit;
  const disableAutoScrollRef = useRef(false);
  const [showLimitAiWriting, setShowLimitAiWriting] = useState(false);
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const [showStreamErrorModal, setShowStreamErrorModal] = useState(false);
  const [showWorkspaceUnitLimitationModal, setShowWorkspaceUnitLimitationModal] = useState(false);
  const flagContentHook = useFlagContent({ lastGeneration });
  const factCheckHook = useFactCheck();

  const {
    actions: {
      handleGenerateIntroduction,
      handleGenerateParagraph,
      handleGenerateOutlines,
      handleGenerateTitle,
      handleGenerateMetaDescription,
    },
    popperTooltip,
    isStreamContentGenerating,
  } = useStreamContent({
    aiWritingCreditsEnabled,
    currentBrandVoice: brandVoiceEnabled ? currentBrandVoice : null,
    disableAutoScrollRef,
    editorRef,
    factCheckHook,
    flagContentHook,
    focusTopKeywordRef,
    hasUnlimitedAIWriting,
    inputTitleRef,
    lastGeneration,
    remainingAiWritingUnit,
    setEditorAiWritingPopperOpen,
    setRefetchAiUnit,
    setShowLimitAiWriting,
    setShowWorkspaceUnitLimitationModal,
    textareaMetaDescriptionRef,
    triggerSaveRef,
  });

  const {
    handleAtomicContent,
    isAtomicContentGenerating,
    handleCancelAtomicContent,
    isAtomicContentFactCheckGenerating,
  } = useAtomicContent({
    askFeedBack: flagContentHook.askFeedback,
    currentBrandVoice: brandVoiceEnabled ? currentBrandVoice : null,
    editorRef,
    factCheckHook,
    focusTopKeywordRef,
    inputTitleRef,
    lastGeneration,
    setRefetchAiUnit,
    setShowLimitAiWriting,
    setShowStreamErrorModal,
    setShowWorkspaceUnitLimitationModal,
    textareaMetaDescriptionRef,
    triggerSaveRef,
  });

  function setupToolbarButtons(editor) {
    editor.ui.registry.addIcon(
      AI_WRITING_FLAG_CONTENT_ICON,
      '<svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="mask0_14422_69997" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="1" y="0" width="14" height="18"><path d="M13.0001 10.508V1.27L1.12109 5.889L13.0001 10.508ZM12.0001 9.047L3.87909 5.889L12.0001 2.73V9.047ZM15.0001 0.5V17.5H14.0001V0.5H15.0001Z" fill="black"/></mask><g mask="url(#mask0_14422_69997)"><rect x="-2" y="-0.5" width="20" height="20" fill="#7C7D86"/></g></svg>'
    );
  }

  const buttons = {
    AI_WRITING_ATOMIC_CONTENT_BUTTON,
  };

  return {
    actions: {
      handleAtomicContent,
      handleCancelAtomicContent,
      handleGenerateIntroduction,
      handleGenerateMetaDescription,
      handleGenerateOutlines,
      handleGenerateParagraph,
      handleGenerateTitle,
    },
    aiContentPermission: { setShowPermissionModal, showPermissionModal },
    aiContentStreamError: { setShowStreamErrorModal, showStreamErrorModal },
    aiContentWorkspaceUnitLimitation: {
      setShowWorkspaceUnitLimitationModal,
      showWorkspaceUnitLimitationModal,
    },
    brandVoiceEnabled,
    buttons,
    currentBrandVoiceId: brandVoiceEnabled ? currentBrandVoice?.id : null,
    disableAutoScrollRef,
    factCheckHook,
    factCheckPopperCardHook: factCheckHook.factCheckPopperCardHook,
    hasUnlimitedAIWriting,
    isAtomicContentFactCheckGenerating,
    isAtomicContentGenerating,
    isStreamContentGenerating,
    lastGeneration,
    limitAiWriting: {
      setRefetchAiUnit,
      setShowLimitAiWriting,
      showLimitAiWriting,
    },
    popperTooltip,
    remainingAiWritingUnit,
    renderFlagContentDialogs: flagContentHook.renderFlagContentDialogs,
    setupToolbarButtons,
  };
}

export default useAiWriting;
