import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DialogModal, ENUM_DIALOG_MODAL_SIZE } from 'semji-core/components/DialogModal';

import IllustrationCongratulations from '@/assets/images/illustration_congratulations.svg';
import { DefaultLink } from '@/components/Navigation/Link';
import { Content } from '@/design-system/components/Dialog';
import Text from '@/design-system/components/Text';
import { closeDialog } from '@/store/actions/dialogs';
import useParamSelector from '@/store/hooks/useParamSelector';
import { SUCCESS_MARK_AS_PUBLISHED_DIALOG } from '@/store/reducers/dialogs';
import selectDialogPropertyByName from '@/store/selectors/selectDialogPropertyByName';

export default function SuccessMarkAsPublishedDialog() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { organizationId, workspaceId } = useParams();

  const isSuccessMarkAsPublishedDialogOpen = useParamSelector(
    selectDialogPropertyByName,
    SUCCESS_MARK_AS_PUBLISHED_DIALOG,
    'isOpen'
  );
  const dratfCount =
    useParamSelector(selectDialogPropertyByName, SUCCESS_MARK_AS_PUBLISHED_DIALOG, 'dratfCount') ||
    0;

  function onClose() {
    dispatch(closeDialog(SUCCESS_MARK_AS_PUBLISHED_DIALOG));
  }

  return (
    <DialogModal
      ariaLabelledby="form-dialog-title"
      canClickOutsideToClose
      closable
      isOpen={isSuccessMarkAsPublishedDialogOpen}
      size={ENUM_DIALOG_MODAL_SIZE.Large}
      title={''}
      onClose={onClose}
    >
      <>
        <Content alignItems="center" id="dialog-description" textAlign="center">
          <img
            alt={t('components:dialog.success-mark-as-published.congratulations-label')}
            src={IllustrationCongratulations}
          />
          <Text
            color="dark100"
            fontSize="lg"
            fontWeight="strong"
            lineHeight="xxl"
            margin="26px 0 0 0"
          >
            {t('components:dialog.success-mark-as-published.congratulations-label')}
          </Text>
          <Text color="dark100" fontSize="lg" fontWeight="strong" lineHeight="xxl">
            {t('components:dialog.success-mark-as-published.draft-published-label_interval', {
              count: dratfCount,
              postProcess: 'interval',
            })}
          </Text>

          <Trans
            components={{
              br: <br />,
              extLinkPlanning: (
                <DefaultLink
                  color="red"
                  decoration
                  noDynamicFontSize
                  noPadding
                  to={`/o/${organizationId}/w/${workspaceId}/planning`}
                  weight="medium"
                />
              ),
              extLinkReport: (
                <DefaultLink
                  color="red"
                  decoration
                  noDynamicFontSize
                  noPadding
                  to={`/o/${organizationId}/w/${workspaceId}/reports/updated/overview`}
                  weight="medium"
                />
              ),
              text: (
                <Text
                  color="dark060"
                  fontSize="default"
                  fontWeight="medium"
                  lineHeight="lg"
                  margin="16px 0 0 0"
                  whiteSpace="normal"
                />
              ),
            }}
            i18nKey={'components:dialog.success-mark-as-published.explanation_interval'}
            values={{
              count: dratfCount,
              postProcess: 'interval',
            }}
          />
        </Content>
      </>
    </DialogModal>
  );
}
