import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import apiClient from '@/apis/semji/apiClient';
import { MutationOptions, MutationResult } from '@/apis/types/mutation.types';
import { showErrorSnackbar } from '@/store/actions/ui';
import { UsePostUserEventPayload, UserEvent } from '@/types/user/event.types';
import { COOKIE_IMPERSONATE_KEY_NAME, getCookie } from '@/utils/cookies/cookies';

export interface UsePostUserEventProps extends MutationOptions<UsePostUserEventPayload, UserEvent> {
  userId: string;
}

export default function usePostUserEvent({
  userId,
  ...props
}: UsePostUserEventProps): MutationResult<UsePostUserEventPayload, UserEvent> {
  const { post } = apiClient;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userImpersonate = getCookie(COOKIE_IMPERSONATE_KEY_NAME);

  return useMutation({
    mutationFn: async (payload) => {
      if (userImpersonate) {
        return {} as UserEvent;
      }
      return await post(`/users/${userId}/events`, payload);
    },
    onError: () => {
      dispatch(showErrorSnackbar(t('common:error.default')));
    },
    ...props,
  });
}
