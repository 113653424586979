import './ReportTitle.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Select,
  SELECT_VARIANT_BORDERLESS,
  SelectDropdownItem,
} from 'semji-core/components/Select';
import { ENUM_TOGGLE_BUTTON_TYPE, ToggleButton } from 'semji-core/components/ToggleButton';
import { Tooltip } from 'semji-core/components/Tooltip';
import { CheckIcon } from 'semji-core/icons/CheckIcon';
import { InfoIcon } from 'semji-core/icons/InfoIcon';
import { formatNumber } from 'semji-core/utils/numbers';
import {
  getRoundedNumberMetricWithSuffix,
  getRoundedPercentMetricObject,
} from 'semji-core/utils/numbers';
import {
  getRoundedEvolutionMetricObject,
  getRoundedNumberMetricObject,
} from 'semji-core/utils/numbers';

import { DiffPercentage } from '@/components/Metrics/DiffPercentage';
import { CHART_FORMAT_VALUES } from '@/containers/Report/utils/constants';
import { ReportMetricKey, ReportView } from '@/containers/Report/utils/types';
import { useMetricsConfig } from '@/hooks/useMetricsConfig';
import { DEFAULT_REPORT_METRIC_KEY } from '@/utils/constants';
import { twoDecimalPlacesIfNeeded } from '@/utils/helper';
import {
  METRIC_POSITION_KEY,
  METRIC_TYPE_PERCENT,
  RANKING_KEYWORDS_METRICS,
  TOP_100_TAB,
} from '@/utils/metrics/constants';

import { ENUM_REPORT_TITLE_VARIANT, ReportTitleProps } from './ReportTitle.types';

export default function ReportTitle({
  currentMetricKey,
  aggregatedPreviousMetrics,
  aggregatedMetrics,
  currentCount,
  comparisonPeriodLabel,
  isNoData,
  metricStackingType,
  handleMetricStackingTypeChange,
  currentMetricsState,
  activeSubTab,
  handleSelectItem,
  SelectList,
  variant = ENUM_REPORT_TITLE_VARIANT.Default,
}: ReportTitleProps) {
  const { reportView = ReportView.All } = useParams();
  const { t } = useTranslation();
  const metrics = useMetricsConfig({ isNew: true });
  const userLanguageCode = useSelector((state: any) => state.user?.languageCode);
  const isPositionMetric = currentMetricKey === METRIC_POSITION_KEY;

  function getSubtitle(
    currentValue: {
      initialValue: number;
      value: number;
      suffix: string;
    },
    previousValue: {
      initialValue: number;
      value: number;
      suffix: string;
    },
    value: number
  ) {
    const unit = metrics[currentMetricKey].suffix;

    const translateKey = `report:chart.title.${reportView}-content_interval`;
    const translatedContentInterval = `${t(translateKey, {
      count: currentCount,
      postProcess: 'interval',
    })}`;
    if (isNoData) {
      let labelKey = `report:chart.metric-title-chart.${metrics[currentMetricKey].key}`;
      if (isPositionMetric) {
        labelKey =
          activeSubTab === TOP_100_TAB
            ? 'report:chart.metric-title-chart.keywords'
            : 'report:chart.metric-title-chart.focus-keywords';
      }
      return {
        subTitle: null,
        title: t(labelKey, {
          separator: '',
          value: '',
        }),
      };
    }
    if (value === null || !currentCount) {
      return {
        subTitle: null,
        title: null,
      };
    }
    if (currentMetricKey === DEFAULT_REPORT_METRIC_KEY) {
      return {
        subTitle: null,
        title: `${currentCount} ${translatedContentInterval}`,
      };
    }
    if (isPositionMetric) {
      const lastMetricState = currentMetricsState[currentMetricsState.length - 1];
      const rankingKeywordsCount =
        (lastMetricState?.rankingKeywordsTop3 || 0) +
        (lastMetricState?.rankingKeywordsTop10 || 0) +
        (lastMetricState?.rankingKeywordsTop100 || 0);

      const count = activeSubTab === TOP_100_TAB ? rankingKeywordsCount : currentCount;
      const roundedCount = getRoundedNumberMetricWithSuffix({
        locale: userLanguageCode,
        number: count,
      });
      const labelKey =
        activeSubTab === TOP_100_TAB
          ? `report:chart.title.keyword_interval`
          : `report:chart.title.focus-keyword_interval`;

      return {
        subTitle: null,
        title: `${roundedCount} ${t(labelKey, {
          count: parseInt(roundedCount, 10),
          postProcess: 'interval',
        })}`,
      };
    }
    if (previousValue.initialValue === 0 && currentValue.initialValue === 0) {
      return {
        subTitle: reportView ? `${translatedContentInterval}` : '',
        title: t(metrics[currentMetricKey].name),
      };
    }
    return {
      subTitle: reportView ? `${currentCount} ${translatedContentInterval}` : '',
      title: t(`report:chart.metric-title-chart.${metrics[currentMetricKey].key}`, {
        separator: ':',
        value: `${currentValue.value}${currentValue.suffix}${unit}`,
      }),
    };
  }

  function getTooltipTitle(value: number, isPrevious = false) {
    const { suffix, name } = metrics[currentMetricKey];
    const formattedNumber = formatNumber({
      locale: userLanguageCode,
      number: twoDecimalPlacesIfNeeded(value),
    });

    const roundedValue = getRoundedNumberMetricWithSuffix({
      locale: userLanguageCode,
      number: value,
    });

    let formattedName = t(name);
    if (isPositionMetric) {
      return (
        <span>
          {activeSubTab === TOP_100_TAB
            ? t(
                'content:side-panel-components.performance.section.main-metrics-evolution.top100-tooltip'
              )
            : t(
                'content:side-panel-components.performance.section.main-metrics-evolution.focus-keyword-tooltip'
              )}
        </span>
      );
    }
    if (currentMetricKey === DEFAULT_REPORT_METRIC_KEY) {
      formattedName = t('report:chart.title.tooltip.publications_interval', {
        count: parseInt(formattedNumber),
        postProcess: 'interval',
      });
    }

    if (isPrevious) {
      return (
        <div>
          <span>
            {comparisonPeriodLabel}: {roundedValue}
          </span>
          <br />
          <span>
            ({formattedNumber}
            {suffix} {formattedName})
          </span>
        </div>
      );
    }

    return (
      <span>
        {formattedNumber}
        {suffix} {formattedName}
      </span>
    );
  }

  function getPercentDiff(value: number, previousValue: number) {
    if (previousValue === 0 && value !== 0) return Infinity;
    if (previousValue === 0) return 0;
    return ((value - previousValue) * 100) / previousValue;
  }

  function getValue() {
    if (isPositionMetric) {
      if (activeSubTab === TOP_100_TAB) {
        return aggregatedMetrics[RANKING_KEYWORDS_METRICS];
      }
      return currentCount;
    }
    return type === METRIC_TYPE_PERCENT
      ? (aggregatedMetrics[currentMetricKey] || 0) * 100
      : aggregatedMetrics[currentMetricKey] || 0;
  }

  function getPreviousValue() {
    if (isPositionMetric) {
      if (isPositionMetric && activeSubTab === TOP_100_TAB) {
        return aggregatedPreviousMetrics[RANKING_KEYWORDS_METRICS];
      }
      return aggregatedPreviousMetrics[ReportMetricKey.publicationsCount];
    }
    return type === METRIC_TYPE_PERCENT
      ? (aggregatedPreviousMetrics[currentMetricKey] || 0) * 100
      : aggregatedPreviousMetrics[currentMetricKey] || 0;
  }

  const type = metrics[currentMetricKey].type;
  const value = getValue() || 0;

  const previousValue = getPreviousValue() || 0;

  const percentDiff = getPercentDiff(value, previousValue);
  const valueDiff = value - previousValue;
  const percentDiffRounded = getRoundedEvolutionMetricObject({
    locale: userLanguageCode,
    number: percentDiff,
  });
  const valueDiffRounded = getRoundedEvolutionMetricObject({
    locale: userLanguageCode,
    number: valueDiff,
  });

  let roundedPreviousValueObject;
  let roundedCurrentValueObject;
  if (type === METRIC_TYPE_PERCENT) {
    roundedPreviousValueObject = getRoundedPercentMetricObject({
      locale: userLanguageCode,
      number: previousValue,
    });
    roundedCurrentValueObject = getRoundedPercentMetricObject({
      locale: userLanguageCode,
      number: value,
    });
  } else {
    roundedPreviousValueObject = getRoundedNumberMetricObject({
      locale: userLanguageCode,
      number: previousValue,
    });
    roundedCurrentValueObject = getRoundedNumberMetricObject({
      locale: userLanguageCode,
      number: value,
    });
  }

  const { title, subTitle } = getSubtitle(
    roundedCurrentValueObject,
    roundedPreviousValueObject,
    value
  );

  if (!title && !subTitle) {
    return null;
  }

  const showMetrics = !(previousValue === null || !currentCount || previousValue + value === 0);

  function SelectItem({
    optionValue,
    ...props
  }: {
    optionValue: { title: string; value: string };
  }) {
    return (
      <SelectDropdownItem {...props}>
        <span>{optionValue.title}</span>
        <CheckIcon />
      </SelectDropdownItem>
    );
  }

  function getSuffix() {
    if ([ReportMetricKey.publicationsCount, ReportMetricKey.Position].includes(currentMetricKey)) {
      return '';
    }
    if (type === METRIC_TYPE_PERCENT) {
      return `${valueDiffRounded.suffix} ${t('report:chart.title.point_interval', {
        count: Math.abs(valueDiffRounded.value),
        postProcess: 'interval',
      })}`;
    }
    return `${percentDiffRounded.suffix} %`;
  }

  return (
    <div className={`report-title report-title--${variant}`}>
      <div className="report-title__title-wrapper">
        <div className="report-title__title-wrapper__title">
          <Tooltip
            title={getTooltipTitle(value)}
            tooltipClassName="report-title__title-wrapper__tooltip"
          >
            <span>{title}</span>
          </Tooltip>
          {showMetrics && !isPositionMetric && (
            <span className="report-title__title-wrapper__metric">
              <DiffPercentage
                evolutionRating={metrics[currentMetricKey].evolutionRating}
                initialValue={
                  type === METRIC_TYPE_PERCENT ||
                  currentMetricKey === DEFAULT_REPORT_METRIC_KEY ||
                  isPositionMetric
                    ? valueDiffRounded.initialValue
                    : percentDiffRounded.initialValue
                }
                suffix={getSuffix()}
                value={
                  type === METRIC_TYPE_PERCENT ||
                  currentMetricKey === DEFAULT_REPORT_METRIC_KEY ||
                  isPositionMetric
                    ? valueDiffRounded.value
                    : percentDiffRounded.value
                }
              />
            </span>
          )}
          {!!previousValue && (
            <span className="report-title__title-wrapper__metric">
              <Tooltip
                description={getTooltipTitle(previousValue, true)}
                tooltipClassName="report-title__title-wrapper__tooltip"
              >
                <InfoIcon className="report-title__title-wrapper__icon" />
              </Tooltip>
            </span>
          )}
        </div>
        <div className="report-title__title-wrapper__actions">
          {SelectList && (
            <Select
              key={`report-title-select--${currentMetricKey}`}
              className="report-title__title-wrapper__actions__select"
              defaultValueIndex={SelectList.findIndex((p) => p.value === activeSubTab)}
              DropdownItem={SelectItem}
              dropdownPlacement="bottom-end"
              getValue={(item) => item?.title}
              isFrame={false}
              offsetValue={0}
              options={SelectList}
              variant={SELECT_VARIANT_BORDERLESS}
              width="200px"
              onChange={handleSelectItem}
            />
          )}
          {metricStackingType && (
            <ToggleButton
              actions={CHART_FORMAT_VALUES.map((item) => ({
                ...item,
                active: metricStackingType === item.value,
                onClick: () => handleMetricStackingTypeChange(item.value),
              }))}
              type={ENUM_TOGGLE_BUTTON_TYPE.Icon}
            />
          )}
        </div>
      </div>
    </div>
  );
}
