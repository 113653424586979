import { nanoid } from 'nanoid';
import { useMemo } from 'react';

import SvgWrapper from '@/components/icons/SvgWrapper';

function ArrowDown(props) {
  const maskId = useMemo(() => nanoid(), []);

  return (
    <SvgWrapper viewBox="0 0 17 17" {...props}>
      <mask
        height="18"
        id={maskId}
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="17"
        x="0"
        y="0"
      >
        <path
          d="M17 16.5V17.5H0V16.5H17ZM13.354 9.354L12.647 8.647L9.001 12.293V0.5H8.001V12.294L4.354 8.646L3.646 9.354L8.5 14.207L13.354 9.354Z"
          fill="black"
        />
      </mask>
      <g mask={`url(#${maskId})`}>
        <rect fill="#FF4D64" height="20" width="20" x="-2" y="-0.5" />
      </g>
    </SvgWrapper>
  );
}

export default ArrowDown;
