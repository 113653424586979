import { nanoid } from 'nanoid';
import React, { useMemo } from 'react';

import SvgWrapper from './SvgWrapper';

const SquareIcon = (props) => {
  const maskId = useMemo(() => nanoid(), []);
  return (
    <SvgWrapper {...props} fill="none" height="21" viewBox="0 0 20 20" width="20">
      <mask
        height="45"
        id={`mask${maskId}`}
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="45"
        x="3"
        y="3"
      >
        <path d="M3 3.5V14.5H14V3.5H3ZM13 13.5H4V4.5H13V13.5Z" fill="black" />
      </mask>
      <g mask={`url(#mask${maskId})`}>
        <rect fill="#7C7D86" height="20" width="20" x="-2" y="-0.5" />
      </g>
    </SvgWrapper>
  );
};

export default SquareIcon;
