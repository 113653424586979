import { unlockRecommendations } from '../apis/semji/api';

export default class Recommendation {
  constructor(keywordId) {
    this._keywordId = keywordId;
  }

  unlock(type, amount = 10) {
    return unlockRecommendations(this._keywordId, { amount, type });
  }
}
