import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';

import {
  ERROR_API_ACCOUNT_NOT_FOUND,
  ERROR_EMAIL_CHECKER,
  ERROR_FRONT_EMAIL_NOT_CORRESPONDING,
  ERROR_FRONT_TOKEN_NOT_FOUND,
  ERROR_GOOGLE_POPUP_CLOSED,
  ERROR_GOOGLE_SCRIPT_FAILURE,
  SIGNIN,
  SIGNUP,
  USE,
} from '@/components/GoogleLogin/constants';
import { Info } from '@/components/Text/Info';
import { Loader } from '@/design-system/components/Loader/Loader';
import { useGoogleAuth } from '@/hooks/useGoogleAuth';
import { googleOAuthLogin } from '@/store/actions/user';
import { pushAuthenticatedUserToGoogleAnalytics } from '@/utils/googleTagManager';

const ErrorMessage = styled(Info)`
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
`;

const GoogleDiv = styled.div`
  height: 38px;
  visibility: ${({ disabled }) => (disabled ? 'hidden' : 'visible')};
`;

function getParamsToSendToApi(context, tokenId) {
  let params = { googleToken: tokenId };

  switch (context) {
    case SIGNIN:
      return { ...params, autoCreate: false, updateProfile: false };
    case SIGNUP:
      return { ...params, autoCreate: true, updateProfile: false };
    case USE:
      return { ...params, autoCreate: true, updateProfile: true };
    default:
      return null;
  }
}

const getErrorMessage = (e, t) => {
  const { error, message } = e;

  if (error === ERROR_GOOGLE_SCRIPT_FAILURE) {
    return t('login:error-google-script-failure');
  } else if (error === ERROR_GOOGLE_POPUP_CLOSED) {
    return t('login:error-google-popup-closed');
  } else if (message === ERROR_FRONT_EMAIL_NOT_CORRESPONDING) {
    return t('login:error-front-email-not-corresponding');
  } else if (message === ERROR_FRONT_TOKEN_NOT_FOUND) {
    return t('login:error-front-token-not-found');
  } else if (message === ERROR_API_ACCOUNT_NOT_FOUND) {
    return t('login:error-api-account-not-found');
  } else if (message === ERROR_EMAIL_CHECKER) {
    return t('create-account:sign-up.email-error');
  } else {
    return t('login:error-unknown');
  }
};

function GoogleLoginButton({
  context = SIGNIN,
  onError,
  redirectTo,
  restrictEmailTo,
  withErrorMessage = true,
  options = {},
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorOAuthLogin, setErrorOAuthLogin] = useState('');
  const [isLogging, setIsLogging] = useState(false);
  const googleButtonRef = useRef(null);

  const onSuccess = async (response) => {
    try {
      setErrorOAuthLogin('');
      setIsLogging(true);
      const { credential, email } = response;
      if (restrictEmailTo && email !== restrictEmailTo) {
        throw new Error(ERROR_FRONT_EMAIL_NOT_CORRESPONDING);
      }

      if (!!credential) {
        const params = getParamsToSendToApi(context, credential);
        const user = await dispatch(googleOAuthLogin(params));

        pushAuthenticatedUserToGoogleAnalytics(user);
        navigate(redirectTo || '/', { replace: true });
      } else {
        throw new Error(ERROR_FRONT_TOKEN_NOT_FOUND);
      }
    } catch (e) {
      onFailure(e);
    } finally {
      setIsLogging(false);
    }
  };

  const onFailure = (response) => {
    const message = getErrorMessage(response, t);
    onError?.(message);
    setErrorOAuthLogin(message);
  };

  const { isLoaded } = useGoogleAuth({
    googleButtonRef,
    onFailure,
    onSuccess,
    options,
  });

  return (
    <>
      {/* Use default renderButton in Google oAuth */}
      {isLogging ? (
        <Loader fontSize="38px" />
      ) : (
        <GoogleDiv ref={googleButtonRef} disabled={!isLoaded} />
      )}
      {!!errorOAuthLogin && withErrorMessage && <ErrorMessage>{errorOAuthLogin}</ErrorMessage>}
    </>
  );
}

export default GoogleLoginButton;
