import { nanoid } from 'nanoid';

import SvgWrapper from '@/components/icons/SvgWrapper';

function StartOptimizing(props) {
  const id = nanoid();

  return (
    <SvgWrapper fill="none" viewBox="0 0 14 15" {...props}>
      <g clipPath={`url(#clip0_${id})`}>
        <mask
          height="15"
          id={`mask0_${id}`}
          maskUnits="userSpaceOnUse"
          style={{ maskType: 'alpha' }}
          width="14"
          x="0"
          y="0"
        >
          <path
            d="M13.5874 2.41809L12.432 1.26267C11.9659 0.796557 11.1514 0.796557 10.6845 1.26267L4.47835 7.46879L3.06024 11.8228L7.38294 10.3693L13.5874 4.16479C13.8205 3.93173 13.9489 3.62126 13.9489 3.29103C13.9489 2.96079 13.8205 2.65114 13.5874 2.41809ZM4.59859 9.75985L5.124 8.1482L6.70518 9.72856L5.10588 10.2663L4.59859 9.75985ZM5.62635 7.48526L8.90894 4.20267L10.6466 5.94032L7.364 9.22291L5.62635 7.48526ZM9.49118 3.62044L10.0915 3.02009L11.8292 4.75773L11.2288 5.35809L9.49118 3.62044ZM13.0052 3.58256L12.4122 4.17632L10.6746 2.43867L11.2675 1.84491C11.424 1.68926 11.6949 1.68926 11.8498 1.84491L13.0052 3.00032C13.0826 3.07856 13.1254 3.1815 13.1254 3.29185C13.1254 3.4022 13.0826 3.50432 13.0052 3.58256ZM12.3529 7.07679H13.1765V14.9003H0V2.54738H6.17647V3.37091H0.823529V14.0768H12.3529V7.07679Z"
            fill="black"
          />
        </mask>
        <g mask={`url(#mask0_${id})`}>
          <rect fill="#FF4D64" height="16.4706" width="16.4706" x="-1.64844" y="0.0771484" />
        </g>
      </g>
      <defs>
        <clipPath id={`clip0_${id}`}>
          <rect fill="white" height="14" transform="translate(0 0.900391)" width="14" />
        </clipPath>
      </defs>
    </SvgWrapper>
  );
}

export default StartOptimizing;
