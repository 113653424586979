import './SidePanel.scss';

import React, { useEffect } from 'react';

import { FactsSidePanel } from '@/containers/Content/SidePanel/Facts';
import Outline from '@/containers/Content/SidePanel/Outline';
import Report from '@/containers/Content/SidePanel/Report';
import { ENUM_SIDE_PANEL_STATUS } from '@/containers/Content/SidePanel/SidePanelContainer/SidePanelContainer.types';
import {
  ENUM_SIDE_PANEL_VARIANTS,
  ENUM_TAB_LABEL,
} from '@/containers/Content/SidePanel/SidePanelTabs';
import SidePanelComments from '@/containers/Content/TinyMceComponents/Comments/SidePanel';
import useApiConfigurations from '@/hooks/useApiConfigurations';
import { AI_WRITING_FACT_CHECKING_ENABLED } from '@/utils/configurations/constants';

import { SidePanelProps } from './SidePanel.types';

function SidePanel({
  sidePanelStatus,
  selectedTabHook,
  isReadOnly,
  loading,
  setTopKeywordUpdating,
  showMarkAsPublishedDialog,
  url,
  html,
  isSnapshotPage,
  variant = ENUM_SIDE_PANEL_VARIANTS.CONTENT_EDITOR,
}: SidePanelProps): React.JSX.Element {
  const aiWritingFactCheckingEnabled = useApiConfigurations(AI_WRITING_FACT_CHECKING_ENABLED);

  useEffect(() => {
    // Resize toolbar
    window.dispatchEvent(new Event('resize'));
  }, [sidePanelStatus]);

  return (
    <div
      className={`side-panel side-panel__${variant}__${
        sidePanelStatus === ENUM_SIDE_PANEL_STATUS.Normal ? 'normal' : 'huge'
      }`}
    >
      {selectedTabHook[0] === ENUM_TAB_LABEL.Seo && (
        <Report
          isReadOnly={isReadOnly}
          loading={loading}
          setTopKeywordUpdating={setTopKeywordUpdating}
          showMarkAsPublishedDialog={showMarkAsPublishedDialog}
          url={url}
        />
      )}
      {selectedTabHook[0] === ENUM_TAB_LABEL.Outline && (
        <Outline html={html} isSnapshotPage={isSnapshotPage} />
      )}
      {selectedTabHook[0] === ENUM_TAB_LABEL.Comments && <SidePanelComments />}
      {selectedTabHook[0] === ENUM_TAB_LABEL.FactChecking && aiWritingFactCheckingEnabled && (
        <FactsSidePanel />
      )}
    </div>
  );
}

export default SidePanel;
