import {
  deleteWorkspaceStatus,
  fetchWorkspaceStatus,
  postWorkspaceContentStatus,
  putStatus,
  putWorkspaceContentStatusPriorities,
} from '@/apis/semji/api';
import { SECTIONS } from '@/utils/log/constants';
import { Log } from '@/utils/log/Log';

export default class Status {
  constructor(workspaceId, statusId) {
    this._workspaceId = workspaceId;
    this._id = statusId;
    this._status = null;
  }

  get status() {
    return this._fetchStatus();
  }

  delete() {
    return deleteWorkspaceStatus(this._id);
  }

  updateStatus(values = {}) {
    return putStatus(this._id, values);
  }

  addStatus(values = {}) {
    return postWorkspaceContentStatus(this._workspaceId, values);
  }

  putStatusPriorities(ids = []) {
    return putWorkspaceContentStatusPriorities(this._workspaceId, ids);
  }

  async _fetchStatus() {
    if (this._status === null) {
      try {
        this._status = await fetchWorkspaceStatus(this._workspaceId);
        this._status = this._status['hydra:member'];
        // telling that the last value means a publish action
        this._status[this._status.length - 1].publish = true;
      } catch (error) {
        Log.report({
          context: '_fetchStatus',
          error,
          extra: 'fetch status',
          section: SECTIONS.organization.key,
        });

        throw error;
      }
    }

    return this._status;
  }
}
