import { nanoid } from 'nanoid';
import { useMemo } from 'react';

export default function MarkAsPublishedIcon() {
  const id = useMemo(() => nanoid(), []);

  return (
    <svg
      fill="currentColor"
      height="17"
      viewBox="0 0 17 17"
      width="17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        height="17"
        id={id}
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="17"
        x="0"
        y="0"
      >
        <path d="M6.69998 12.8C5.59998 11.8 5 10.3 5 8.79999C5 5.79999 7.5 3.29999 10.5 3.29999H13.4L10.8 0.699997L11.5 0L15.4 3.89999L11.5 7.79999L10.8 7.09999L13.4 4.5H10.5C8 4.5 6 6.5 6 9C6 10.3 6.49999 11.4 7.39999 12.3L6.69998 12.8ZM16 8.39999V14.9C16 15.2 15.8 15.4 15.5 15.4H1.5C1.2 15.4 1 15.2 1 14.9V8.39999H0V14.9C0 15.7 0.7 16.4 1.5 16.4H15.5C16.3 16.4 17 15.7 17 14.9V8.39999H16Z" />
      </mask>
      <g mask={`url(#${id})`}>
        <rect height="20" width="20" x="-2" y="-1" />
      </g>
    </svg>
  );
}
