import { HIGHLIGHTER_TAG_NAME } from '../constants';
import getElementClassNames from './getElementClassNames';

function isElementHighlighted({
  element,
  highlightClass,
}: {
  element: HTMLElement;
  highlightClass: string;
}): boolean {
  return (
    element.tagName?.toLowerCase() === HIGHLIGHTER_TAG_NAME &&
    getElementClassNames({ element }).includes(highlightClass)
  );
}

export default isElementHighlighted;
