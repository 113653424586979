import './Form.scss';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from 'semji-core/components/Button';
import { Input } from 'semji-core/components/Input';
import { Switch } from 'semji-core/components/Switch';
import { Textarea } from 'semji-core/components/Textarea';

import { BrandVoiceModel } from '@/types/brandVoice/brandVoice.types';

function Form({
  onSubmit,
  defaultValues,
}: {
  onSubmit: Function;
  defaultValues?: BrandVoiceModel;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [name, setName] = useState(defaultValues?.name || '');
  const [brandName, setBrandName] = useState(defaultValues?.brandName || '');
  const [isActive, setIsActive] = useState(defaultValues?.isActive || false);
  const [isSystemPromptEnabled, setIsSystemPromptEnabled] = useState(
    defaultValues?.isSystemPromptEnabled || false
  );
  const [promptHeader, setPromptHeader] = useState(defaultValues?.promptHeader || '');
  const [promptIntroduction, setPromptIntroduction] = useState(
    defaultValues?.promptIntroduction || ''
  );
  const [promptOutlines, setPromptOutlines] = useState(defaultValues?.promptOutlines || '');
  const [promptParagraph, setPromptParagraph] = useState(defaultValues?.promptParagraph || '');
  const [promptFooter, setPromptFooter] = useState(defaultValues?.promptFooter || '');
  const [isRefined, setIsRefined] = useState(defaultValues?.isRefined || false);
  const [refinedIntroCustomInstructions, setRefinedIntroCustomInstructions] = useState(
    defaultValues?.refinedIntroCustomInstructions || ''
  );
  const [refinedOutlinesCustomInstructions, setRefinedOutlinesCustomInstructions] = useState(
    defaultValues?.refinedOutlinesCustomInstructions || ''
  );
  const [refinedParagraphCustomInstructions, setRefinedParagraphCustomInstructions] = useState(
    defaultValues?.refinedParagraphCustomInstructions || ''
  );

  function handleToggle(setState: Function) {
    return () => setState((prev: any) => !prev);
  }

  function handleChange(setState: Function) {
    return (e: React.ChangeEvent<HTMLTextAreaElement>) => setState(e.target.value);
  }

  const brandNameValue = isRefined ? brandName : brandName === '' ? null : brandName;
  const isFormValid = isRefined ? brandName !== '' : true;

  function onSubmitForm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (!isFormValid) {
      return;
    }

    const payload = {
      brandName: brandNameValue,
      isActive,
      isRefined,
      isSystemPromptEnabled,
      name,
      promptFooter,
      promptHeader,
      promptIntroduction,
      promptOutlines,
      promptParagraph,
      refinedIntroCustomInstructions,
      refinedOutlinesCustomInstructions,
      refinedParagraphCustomInstructions,
    };

    onSubmit(payload);
  }

  function onCancelClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    navigate(-1);
  }

  return (
    <div className="brand-voice-page-form">
      <form className="brand-voice-page-form__form" onSubmit={onSubmitForm}>
        <div className="brand-voice-page-form__container">
          <div className="brand-voice-page-form__group">
            <div className="brand-voice-page-form__row">
              <Switch
                checked={isActive}
                className="brand-voice-page-form__switch"
                label={t('brand-voice:form.active-label')}
                onChange={handleToggle(setIsActive)}
              />
              <label className="brand-voice-page-form__label" onClick={handleToggle(setIsActive)}>
                {t('brand-voice:form.enabled-brand-voice')}
              </label>
            </div>
            <Input
              fullWidth
              label={t('brand-voice:form.name-label')}
              required={false}
              value={name}
              variant="borderless"
              onChange={handleChange(setName)}
            />
            <Input
              fullWidth
              label={t('brand-voice:form.brand-name-label')}
              // brandName is required if isRefined is true
              required={isRefined ? true : false}
              value={brandName}
              variant="borderless"
              onChange={handleChange(setBrandName)}
            />
          </div>

          <div className="brand-voice-page-form__group">
            <div className="brand-voice-page-form__row">
              <Switch
                checked={isSystemPromptEnabled}
                className="brand-voice-page-form__switch"
                label={t('brand-voice:form.active-label')}
                onChange={handleToggle(setIsSystemPromptEnabled)}
              />
              <label
                className="brand-voice-page-form__label"
                onClick={handleToggle(setIsSystemPromptEnabled)}
              >
                {t('brand-voice:form.enabled-system-prompt')}
              </label>
            </div>
            <Textarea
              label={t('brand-voice:form.prompt-header-label')}
              required={false}
              rows={10}
              value={promptHeader}
              onChange={handleChange(setPromptHeader)}
            />
            <Textarea
              label={t('brand-voice:form.prompt-introduction-label')}
              required={false}
              rows={10}
              value={promptIntroduction}
              onChange={handleChange(setPromptIntroduction)}
            />
            <Textarea
              label={t('brand-voice:form.prompt-outlines-label')}
              required={false}
              rows={10}
              value={promptOutlines}
              onChange={handleChange(setPromptOutlines)}
            />
            <Textarea
              label={t('brand-voice:form.prompt-paragraph-label')}
              required={false}
              rows={10}
              value={promptParagraph}
              onChange={handleChange(setPromptParagraph)}
            />
            <Textarea
              label={t('brand-voice:form.prompt-footer-label')}
              required={false}
              rows={10}
              value={promptFooter}
              onChange={handleChange(setPromptFooter)}
            />
          </div>

          <div className="brand-voice-page-form__group">
            <div className="brand-voice-page-form__row">
              <Switch
                checked={isRefined}
                className="brand-voice-page-form__switch"
                label={t('brand-voice:form.active-label')}
                onChange={handleToggle(setIsRefined)}
              />
              <label className="brand-voice-page-form__label" onClick={handleToggle(setIsRefined)}>
                {t('brand-voice:form.enabled-second-prompt')}
              </label>
            </div>
            <Textarea
              label={t('brand-voice:form.add-prompt-introduction-label')}
              required={false}
              rows={10}
              value={refinedIntroCustomInstructions}
              onChange={handleChange(setRefinedIntroCustomInstructions)}
            />
            <Textarea
              label={t('brand-voice:form.add-prompt-outlines-label')}
              required={false}
              rows={10}
              value={refinedOutlinesCustomInstructions}
              onChange={handleChange(setRefinedOutlinesCustomInstructions)}
            />
            <Textarea
              label={t('brand-voice:form.add-prompt-paragraph-label')}
              required={false}
              rows={10}
              value={refinedParagraphCustomInstructions}
              onChange={handleChange(setRefinedParagraphCustomInstructions)}
            />
          </div>
        </div>

        <div className="brand-voice-page-form__bottom">
          <Button
            className="brand-voice-page-form__outline-button"
            variant="tertiary"
            onClick={onCancelClick}
          >
            {t('brand-voice:form.cancel')}
          </Button>
          <Button
            className="brand-voice-page-form__submit-button"
            disabled={!isFormValid}
            variant="gradient-primary"
          >
            {t('brand-voice:form.save')}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default Form;
