import React from 'react';

const Logo = (props) => (
  <svg fill="none" height="36" viewBox="0 0 117 36" width="117" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M32.9087 27.9377C31.7283 27.3283 30.8312 26.6721 30.2174 25.9221C29.6036 25.1721 29.3203 24.5627 29.3203 24.1408C29.3203 23.9065 29.4147 23.719 29.6036 23.5783C29.7925 23.4377 29.9813 23.344 30.2174 23.344H30.8312C31.0201 23.344 31.1617 23.3908 31.3034 23.4377C31.445 23.4846 31.5867 23.6252 31.7283 23.8596C32.3421 24.7033 33.0503 25.3127 33.9002 25.7815C34.7501 26.2502 35.9305 26.4846 37.3942 26.4846C39.0467 26.4846 40.416 26.1565 41.4075 25.5471C42.4462 24.9377 42.9184 24.0002 42.9184 22.8283C42.9184 22.1252 42.7295 21.5158 42.3046 21.0471C41.8796 20.5783 41.2186 20.2033 40.2743 19.8752C39.33 19.5471 37.9608 19.1721 36.1193 18.7502C33.7114 18.2346 32.106 17.4846 31.2089 16.5471C30.3118 15.6096 29.8869 14.4377 29.8869 12.9846C29.8869 12.0002 30.1702 11.0627 30.7368 10.1721C31.3034 9.28145 32.106 8.57832 33.192 8.01582C34.2779 7.45332 35.6 7.17207 37.1109 7.17207C38.7162 7.17207 40.0855 7.45332 41.2186 8.01582C42.3518 8.57832 43.1545 9.1877 43.721 9.9377C44.2876 10.6408 44.5709 11.2502 44.5709 11.7189C44.5709 11.9533 44.4765 12.1408 44.2876 12.2814C44.0988 12.4221 43.9099 12.5158 43.6738 12.5158H43.06C42.6823 12.5158 42.399 12.3283 42.1629 12.0002C41.6436 11.2033 41.0298 10.5939 40.3215 10.1721C39.6133 9.7502 38.5746 9.5627 37.1109 9.5627C35.5528 9.5627 34.3724 9.89082 33.5697 10.5002C32.767 11.1096 32.3421 12.0002 32.3421 13.0314C32.3421 13.6877 32.4838 14.2033 32.8143 14.6252C33.1448 15.0471 33.7586 15.469 34.6085 15.7971C35.5055 16.1721 36.7804 16.5471 38.4801 16.8752C40.9825 17.4377 42.7295 18.1877 43.7683 19.1252C44.807 20.0627 45.2792 21.2815 45.2792 22.8283C45.2792 23.9065 44.9486 24.9377 44.3348 25.8283C43.721 26.719 42.8239 27.469 41.5963 27.9846C40.3687 28.5002 38.9523 28.7815 37.2525 28.7815C35.6 28.8283 34.0891 28.5002 32.9087 27.9377Z"
      fill="#354456"
    />
    <path
      d="M51.3221 26.2971C49.764 24.6096 48.8669 22.4064 48.6781 19.5939L48.6309 18.0002L48.6781 16.4064C48.9142 13.6408 49.764 11.3908 51.3221 9.70332C52.8802 8.01582 54.9105 7.17207 57.5074 7.17207C60.2931 7.17207 62.465 8.10957 64.0703 9.9377C65.6284 11.7658 66.4311 14.2971 66.4311 17.4846V18.0939C66.4311 18.3752 66.3367 18.5627 66.1478 18.7502C65.9589 18.8908 65.7701 18.9846 65.4868 18.9846H51.1333V19.4065C51.1805 20.6721 51.4638 21.844 51.9832 22.9221C52.5025 24.0002 53.258 24.8908 54.2023 25.5471C55.1466 26.2033 56.2326 26.5314 57.5074 26.5314C58.971 26.5314 60.1986 26.2502 61.0957 25.6877C62.04 25.1252 62.7011 24.5158 63.0788 23.9533C63.3149 23.6252 63.5037 23.4377 63.6454 23.344C63.787 23.2502 63.9759 23.2033 64.3064 23.2033H64.9674C65.2035 23.2033 65.3923 23.2502 65.5812 23.3908C65.7229 23.5315 65.8173 23.719 65.8173 23.9065C65.8173 24.469 65.4396 25.1252 64.7313 25.9221C64.0231 26.719 63.0316 27.3752 61.7568 27.9846C60.4819 28.5471 59.0655 28.8283 57.4601 28.8283C54.9105 28.8283 52.8802 27.9846 51.3221 26.2971ZM63.9287 16.7814V16.6408C63.9287 14.5783 63.3621 12.8908 62.1817 11.5783C61.0013 10.2658 59.4432 9.5627 57.5074 9.5627C55.5243 9.5627 54.0134 10.2189 52.833 11.5783C51.6999 12.8908 51.1333 14.6252 51.1333 16.6408V16.7814H63.9287Z"
      fill="#354456"
    />
    <path
      d="M71.1521 28.1721C71.0104 28.0314 70.916 27.7971 70.916 27.5158V8.48457C70.916 8.20332 71.0104 8.01582 71.1521 7.82832C71.2937 7.6877 71.5298 7.59395 71.8131 7.59395H72.5213C72.8046 7.59395 72.9935 7.6877 73.1824 7.82832C73.3712 7.96895 73.4184 8.20332 73.4184 8.48457V9.89082C74.1267 9.0002 74.9293 8.34395 75.8736 7.8752C76.818 7.40645 77.9039 7.17207 79.1787 7.17207C82.2005 7.17207 84.3252 8.4377 85.6473 10.9221C86.3083 9.79707 87.2054 8.85957 88.3858 8.20332C89.5661 7.5002 90.9354 7.17207 92.4935 7.17207C94.7126 7.17207 96.554 7.92207 97.9233 9.42207C99.3398 10.9221 100.001 13.0783 100.001 15.8439V27.4689C100.001 27.7502 99.9063 27.9377 99.7647 28.1252C99.6231 28.2658 99.387 28.3596 99.1037 28.3596H98.3954C98.1122 28.3596 97.9233 28.2658 97.7344 28.1252C97.5928 27.9846 97.4984 27.7502 97.4984 27.4689V16.2189C97.4984 13.7814 96.979 12.0471 95.9875 11.0158C94.9959 9.98457 93.6739 9.46895 92.0214 9.46895C90.5577 9.46895 89.3301 9.98457 88.2441 11.0627C87.2054 12.0939 86.6388 13.8283 86.6388 16.2658V27.5158C86.6388 27.7971 86.5444 27.9846 86.4027 28.1721C86.2611 28.3127 86.025 28.4064 85.7417 28.4064H85.0335C84.7502 28.4064 84.5613 28.3127 84.3724 28.1721C84.2308 28.0314 84.1364 27.7971 84.1364 27.5158V16.2658C84.1364 13.8752 83.617 12.1408 82.5782 11.0627C81.5395 10.0314 80.2175 9.46895 78.6594 9.46895C77.1957 9.46895 75.9681 9.98457 74.8821 11.0627C73.8434 12.0939 73.2768 13.8283 73.2768 16.2189V27.5158C73.2768 27.7971 73.1824 27.9846 73.0407 28.1721C72.8991 28.3127 72.663 28.4064 72.3797 28.4064H71.6715C71.5298 28.4064 71.2937 28.3127 71.1521 28.1721Z"
      fill="#354456"
    />
    <path
      d="M101.418 35.7656C101.276 35.625 101.182 35.3906 101.182 35.1094V34.5469C101.182 34.2656 101.276 34.0781 101.418 33.8906C101.559 33.75 101.795 33.6562 102.079 33.6562H102.22C103.684 33.6562 104.676 33.2812 105.148 32.5312C105.62 31.7812 105.856 30.7031 105.856 29.25V8.48438C105.856 8.20312 105.95 8.01562 106.092 7.82812C106.234 7.6875 106.47 7.59375 106.753 7.59375H107.461C107.745 7.59375 107.933 7.6875 108.122 7.82812C108.311 7.96875 108.358 8.20312 108.358 8.48438V29.2969C108.358 31.4062 107.933 33.0469 107.036 34.2187C106.139 35.3906 104.581 36 102.268 36H102.126C101.795 36 101.607 35.9531 101.418 35.7656ZM105.667 3.09375C105.525 2.95312 105.431 2.71875 105.431 2.4375V0.9375C105.431 0.65625 105.525 0.46875 105.667 0.28125C105.809 0.09375 106.045 0 106.328 0H107.839C108.122 0 108.311 0.09375 108.5 0.28125C108.689 0.46875 108.783 0.65625 108.783 0.9375V2.4375C108.783 2.71875 108.689 2.90625 108.5 3.09375C108.311 3.23437 108.122 3.32812 107.839 3.32812H106.328C106.045 3.32812 105.809 3.23437 105.667 3.09375Z"
      fill="#354456"
    />
    <path
      d="M113.883 3.09375C113.741 2.95312 113.646 2.71875 113.646 2.4375V0.9375C113.646 0.65625 113.741 0.46875 113.883 0.28125C114.024 0.09375 114.26 0 114.544 0H116.054C116.338 0 116.527 0.09375 116.715 0.28125C116.904 0.46875 116.999 0.65625 116.999 0.9375V2.4375C116.999 2.71875 116.904 2.90625 116.715 3.09375C116.527 3.23437 116.338 3.32812 116.054 3.32812H114.544C114.26 3.32812 114.024 3.23437 113.883 3.09375ZM114.308 28.1719C114.166 28.0312 114.071 27.7969 114.071 27.5156V8.48438C114.071 8.20312 114.166 8.01562 114.308 7.82812C114.449 7.6875 114.685 7.59375 114.969 7.59375H115.677C115.96 7.59375 116.149 7.6875 116.338 7.82812C116.479 7.96875 116.574 8.20312 116.574 8.48438V27.5156C116.574 27.7969 116.479 27.9844 116.338 28.1719C116.196 28.3125 115.96 28.4062 115.677 28.4062H114.969C114.685 28.4062 114.496 28.3125 114.308 28.1719Z"
      fill="#354456"
    />
    <path
      d="M14.5894 28.4062L11.6149 22.9687L8.6875 28.4062H14.5894Z"
      fill="url(#logo_paint0_linear)"
    />
    <path
      d="M4.39104 28.406L11.615 15.1872L18.839 28.406H23.2772L11.615 7.12474L0 28.406H4.39104Z"
      fill="url(#logo_paint1_linear)"
    />
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="logo_paint0_linear"
        x1="8.89955"
        x2="20.4002"
        y1="33.4443"
        y2="11.9711"
      >
        <stop stopColor="#E7477F" />
        <stop offset="1" stopColor="#E94D66" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="logo_paint1_linear"
        x1="8.89913"
        x2="20.3998"
        y1="33.4438"
        y2="11.9706"
      >
        <stop stopColor="#E7477F" />
        <stop offset="1" stopColor="#E94D66" />
      </linearGradient>
    </defs>
  </svg>
);

export default Logo;
