import { nanoid } from 'nanoid';
import React from 'react';
import { useMemo } from 'react';

import SvgWrapper from './SvgWrapper';

function BulletListIcon(props) {
  const maskId = useMemo(() => nanoid(), []);

  return (
    <SvgWrapper {...props} fill="none" height="17" viewBox="0 0 17 17" width="17">
      <mask
        height="11"
        id={`mask${maskId}`}
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="17"
        x="0"
        y="3"
      >
        <path
          d="M17 4V5H5V4H17ZM3 4.5C3 5.327 2.327 6 1.5 6C0.673 6 0 5.327 0 4.5C0 3.673 0.673 3 1.5 3C2.327 3 3 3.673 3 4.5ZM2 4.5C2 4.225 1.776 4 1.5 4C1.224 4 1 4.225 1 4.5C1 4.775 1.224 5 1.5 5C1.776 5 2 4.775 2 4.5ZM5 9H17V8H5V9ZM3 8.5C3 9.327 2.327 10 1.5 10C0.673 10 0 9.327 0 8.5C0 7.673 0.673 7 1.5 7C2.327 7 3 7.673 3 8.5ZM2 8.5C2 8.225 1.776 8 1.5 8C1.224 8 1 8.225 1 8.5C1 8.775 1.224 9 1.5 9C1.776 9 2 8.775 2 8.5ZM5 13H17V12H5V13ZM3 12.5C3 13.327 2.327 14 1.5 14C0.673 14 0 13.327 0 12.5C0 11.673 0.673 11 1.5 11C2.327 11 3 11.673 3 12.5ZM2 12.5C2 12.225 1.776 12 1.5 12C1.224 12 1 12.225 1 12.5C1 12.775 1.224 13 1.5 13C1.776 13 2 12.775 2 12.5Z"
          fill="currentColor"
        />
      </mask>
      <g mask={`url(#mask${maskId})`}>
        <rect fill="currentColor" height="20" width="20" x="-2" y="-1" />
      </g>
    </SvgWrapper>
  );
}

export default BulletListIcon;
