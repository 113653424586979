export interface CodeModalProps {
  html: string;
  close?: () => null;
  cleanHtml: (html: string, allowedAttributes: string[]) => string;
}

export enum ENUM_CODE_TYPE {
  HTML = 'HTML',
  MARKDOWN = 'Markdown',
}
