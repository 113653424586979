import React, { useId } from 'react';

function MetaDescIcon(props) {
  const id = useId();

  return (
    <svg {...props} fill="none" height="17" viewBox="0 0 17 17" width="17">
      <g clipPath={`url(#clip_${id})`}>
        <mask
          height="21"
          id={`mask_${id}`}
          maskUnits="userSpaceOnUse"
          style={{ maskType: 'alpha' }}
          width="21"
          x="-2"
          y="-2"
        >
          <path
            d="M4.55615 5.79297L1.30756 8.50013L4.55615 11.2073"
            stroke="#252736"
            strokeWidth="0.828074"
          />
          <path d="M10.4863 1.50293L6.89777 15.498" stroke="#252736" strokeWidth="0.828074" />
          <path
            d="M12.8281 5.79297L16.0767 8.50013L12.8281 11.2073"
            stroke="#252736"
            strokeWidth="0.828074"
          />
        </mask>
        <g mask={`url(#mask_${id})`}>
          <rect fill="#FF4D64" height="20" width="20" x="-1.30762" y="-1.30762" />
        </g>
      </g>
      <defs>
        <clipPath id={`clip_${id}`}>
          <rect fill="white" height="17" width="17" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default MetaDescIcon;
