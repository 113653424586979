import './Filter.scss';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'semji-core/components/Dropdown';
import { Checkbox } from 'semji-core/components/Input/Checkbox';
import { Separator } from 'semji-core/components/Separator';
import { FilterIcon } from 'semji-core/icons/FilterIcon';

import { FACT_FILTER_ENUM, FactFilter } from '../facts.types';

const DEFAULT_QUERY_SELECTOR_LISTENER = '#root';

function Filter({
  filters,
  setFilters,
}: {
  filters: FactFilter;
  setFilters: (filters: FactFilter) => void;
}): JSX.Element {
  const { t } = useTranslation();
  const dropDrownActionsRef = useRef({
    handleClose: () => null,
    handleOpen: () => null,
  });
  const querySelectorListener = DEFAULT_QUERY_SELECTOR_LISTENER;

  function handleFilterChange(filter: FACT_FILTER_ENUM, value: boolean): void {
    setFilters({ ...filters, [filter]: value });
  }

  function handleAll(value: boolean): void {
    setFilters({
      [FACT_FILTER_ENUM.CRITICALITY_HIGH]: value,
      [FACT_FILTER_ENUM.CRITICALITY_MEDIUM]: value,
      [FACT_FILTER_ENUM.CRITICALITY_VERIFIED]: value,
      [FACT_FILTER_ENUM.STATUS_VALIDATED]: value,
    });
  }

  return (
    <Dropdown
      actionsRef={dropDrownActionsRef}
      anchorElement={
        <div>
          <FilterIcon />
        </div>
      }
      containerClassName="facts_side_panel_filter__anchor"
      isFrame={false}
      offsetValue={10}
      placement={'bottom-end'}
      querySelectorListener={querySelectorListener}
      width="220px"
    >
      <div className="facts_side_panel_filter__content">
        <FormControlLabel
          className="facts_side_panel_filter__content__checkbox"
          control={
            <Checkbox
              checked={filters.VALIDATED && filters.high && filters.medium && filters.verified}
              className="facts_side_panel_filter__content__checkbox__all"
              indeterminate={
                !filters.VALIDATED || !filters.high || !filters.medium || !filters.verified
              }
              onChange={(event) => handleAll(event.target.checked)}
            />
          }
          label={t('fact:filters.all')}
        />
        <Separator />
        <FormControlLabel
          className="facts_side_panel_filter__content__checkbox"
          control={
            <Checkbox
              checked={filters.verified}
              onChange={(event) =>
                handleFilterChange(FACT_FILTER_ENUM.CRITICALITY_VERIFIED, event.target.checked)
              }
            />
          }
          label={t('fact:filters.verified')}
        />
        <FormControlLabel
          className="facts_side_panel_filter__content__checkbox"
          control={
            <Checkbox
              checked={filters.medium}
              onChange={(event) =>
                handleFilterChange(FACT_FILTER_ENUM.CRITICALITY_MEDIUM, event.target.checked)
              }
            />
          }
          label={t('fact:filters.criticality-medium')}
        />
        <FormControlLabel
          className="facts_side_panel_filter__content__checkbox"
          control={
            <Checkbox
              checked={filters.high}
              onChange={(event) =>
                handleFilterChange(FACT_FILTER_ENUM.CRITICALITY_HIGH, event.target.checked)
              }
            />
          }
          label={t('fact:filters.criticality-high')}
        />
        <FormControlLabel
          className="facts_side_panel_filter__content__checkbox"
          control={
            <Checkbox
              checked={filters.VALIDATED}
              onChange={(event) =>
                handleFilterChange(FACT_FILTER_ENUM.STATUS_VALIDATED, event.target.checked)
              }
            />
          }
          label={t('fact:filters.validated')}
        />
      </div>
    </Dropdown>
  );
}

export default Filter;
