import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useMatch } from 'react-router-dom';
import styled from 'styled-components/macro';

import BriefIcon from '@/components/icons/BriefIcon';
import PencilIcon from '@/components/icons/PencilIcon';
import WorldIcon from '@/components/icons/WorldIcon';
import useBreakpoints from '@/hooks/useBreakpoints';

const Wrapper = styled.div`
  display: flex;
`;

const Icon = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${({ isSmall }) => (isSmall ? '60px' : '100px')};
  height: 60px;
  padding: 0 10px;
  color: ${({ isActive, theme }) => (isActive ? 'white' : theme.cssColors.dark040)};
  background-color: ${({ isActive }) => isActive && 'rgba(26,27,38,1)'};
  border-bottom: 5px solid transparent;
  border-bottom: ${({ isActive, theme }) => isActive && `5px solid ${theme.cssColors.primary}`};
  font-size: ${({ theme }) => theme.textCss.sizes.default};
  cursor: ${({ isActive, isDisabled }) => (isActive || isDisabled ? 'default' : 'pointer')};

  & span {
    margin-left: 10px;
  }

  &:hover {
    border-bottom: ${({ isDisabled, theme }) =>
      !isDisabled && `5px solid ${theme.cssColors.primary}`};
  }

  &:hover,
  & > a:hover {
    color: ${({ isDisabled }) => !isDisabled && 'white'};
    background-color: ${({ isDisabled }) => !isDisabled && 'rgba(26, 27, 38, 1)'};
  }

  & > a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: ${({ isActive, theme }) => (isActive ? 'white' : theme.cssColors.dark040)};
  }
`;

function IconLink({ icon, isActive, isDisabled, isSmall, label, url, ...props }) {
  const child = (
    <>
      {icon}
      {!isSmall && <span>{label}</span>}
    </>
  );

  return (
    <Icon isActive={isActive} isDisabled={isDisabled} isSmall={isSmall} {...props}>
      {isActive || isDisabled ? child : <Link to={url}>{child}</Link>}
    </Icon>
  );
}

function ToggleEditor({ isLoading, toBrief, toEditor, toPage }) {
  const { t } = useTranslation();
  const { isMaxMd: isSmallScreen } = useBreakpoints();
  const match = useMatch('o/:organizationId/w/:workspaceId/p/:pageId/:tab/*');
  const { tab } = match?.params || {};

  return (
    <Wrapper>
      {toPage && (
        <IconLink
          data-intercom-target="editor-online-toggle"
          icon={<WorldIcon />}
          isActive={['online', 'text'].includes(tab)}
          isDisabled={isLoading}
          isSmall={isSmallScreen}
          label={t('content:header-components.page')}
          url={toPage}
        />
      )}
      {toBrief && (
        <IconLink
          data-intercom-target="editor-brief-toggle"
          icon={<BriefIcon />}
          isActive={tab === 'brief'}
          isDisabled={isLoading}
          isSmall={isSmallScreen}
          label={t('content:header-components.brief')}
          url={toBrief}
        />
      )}
      {toEditor && (
        <IconLink
          data-intercom-target="editor-content-toggle"
          icon={<PencilIcon />}
          isActive={['content', 'create'].includes(tab)}
          isDisabled={isLoading}
          isSmall={isSmallScreen}
          label={t('content:header-components.editor')}
          url={toEditor}
        />
      )}
    </Wrapper>
  );
}

ToggleEditor.propTypes = {
  isLoading: PropTypes.bool,
  toBrief: PropTypes.string,
  toEditor: PropTypes.string,
  toPage: PropTypes.string,
};

export default ToggleEditor;
