import { useTranslation } from 'react-i18next';
import { Tooltip } from 'semji-core/components/Tooltip';

import PlusIcon from '@/components/icons/PlusIcon';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import useDeepEqualSelector from '@/store/hooks/useDeepEqualSelector';
import { selectConversionsGoalsMetrics } from '@/store/selectors/selectConversionsGoalsMetrics';
import { OPPORTUNITIES_TAB } from '@/utils/filter/constants';

export const useViewsGoalsConfig = (counts = [0, 0, 0]) => {
  const goalsList = useDeepEqualSelector(selectConversionsGoalsMetrics);
  const { t } = useTranslation();

  const { isFeatureEnabled } = useOrganizationFeatureSet(
    'pages:priority-score:has-access-to-conversions'
  );

  const listCustomGoals = Object.values(goalsList).filter(
    (goal) => goal && goal.name !== t('listing:pages-list.view-panel.all-conversions')
  );
  const viewsGoals = new Array(3).fill(null);

  // We don't want to display goals if the feature is disabled but we want to display "All conversions" with lock badge.
  return viewsGoals.flatMap((view, index) =>
    isFeatureEnabled
      ? [
          {
            attribute: `goal${index + 1}Completions`,
            color: listCustomGoals[index] ? 'dark100' : null,
            count: listCustomGoals[index] ? counts[index] : null,
            endAdornment: listCustomGoals[index] ? null : (
              <Tooltip isFrame={false} title={t('common:labels.add')}>
                <PlusIcon cursor="pointer" hoverBackgroundColor="dark005" hoverColor="dark080" />
              </Tooltip>
            ),
            featureFlag: 'pages:priority-score:has-access-to-conversions',
            href: { priorityScore: `goal${index + 1}Completions`, tab: OPPORTUNITIES_TAB },
            icon: null,
            isConfigured: Boolean(listCustomGoals[index]),
            label:
              listCustomGoals[index]?.name ||
              `${t('listing:pages-list.view-panel.goal')} ${index + 1}`,
            weight: 'weight',
            withLeftPadding: true,
          },
        ]
      : []
  );
};
