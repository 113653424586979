import React from 'react';

import SvgWrapper from './SvgWrapper';

function AngleDoubleLeftIcon({ ...props }) {
  return (
    <SvgWrapper {...props}>
      <path d="M7.987 8.5l6.146 6.146-0.707 0.707-6.853-6.853 6.854-6.854 0.707 0.707-6.147 6.147zM9.29 2.353l-0.707-0.707-6.854 6.854 6.854 6.854 0.707-0.707-6.146-6.147 6.146-6.147z" />
    </SvgWrapper>
  );
}

export default AngleDoubleLeftIcon;
