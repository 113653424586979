import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import AICreditIcon from '@/components/icons/AICreditIcon';
import AnimatedReloadIcon from '@/components/icons/AnimatedReloadIcon';
import PlusIcon from '@/components/icons/PlusIcon';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import { useContentContainerContext } from '@/containers/Content/ContentContainerContext';
import TextButton from '@/design-system/components/Button/TextButton';
import useApiConfigurations from '@/hooks/useApiConfigurations';
import { CONTENT_AI_WRITING_CREDITS_ENABLED } from '@/utils/configurations/constants';

const TextBtn = styled(TextButton)`
  &&& {
    padding: 0;

    & > svg {
      font-size: ${({ theme }) => theme.textCss.sizes.sm} !important;
    }
  }
`;

function GenerateButton({ hasItems, onClick, creditAmount, creditIcon }) {
  const { t } = useTranslation();
  const {
    aiWriting: { hasUnlimitedAIWriting, remainingAiWritingUnit },
  } = useContentContainerContext();

  const aiWritingCreditsEnabled = useApiConfigurations(CONTENT_AI_WRITING_CREDITS_ENABLED);

  const [isLoading, setIsLoading] = useState(false);

  async function handleClick() {
    try {
      setIsLoading(true);
      await onClick?.();
    } finally {
      setIsLoading(false);
    }
  }

  const text = t(
    isLoading ? 'content:brief.generate-button-loading' : 'content:brief.generate-button-idle'
  );

  return (
    <TooltipComponent
      content={
        <div className="flex flex-col">
          <span className="flex items-center justify-center gap-1">
            {creditIcon ?? <AICreditIcon />}
            <span className="font-medium">
              {t('content:ai-writing.ai-writing-credits-needed', {
                count: creditAmount,
                postProcess: 'interval',
              })}
            </span>
          </span>
          <span className="text-center italic">
            (
            {t('content:ai-writing.ai-writing-credits-remaining.key_interval', {
              count: remainingAiWritingUnit,
              postProcess: 'interval',
            })}
            )
          </span>
        </div>
      }
      hide={hasUnlimitedAIWriting || !aiWritingCreditsEnabled}
    >
      <TextBtn disabled={isLoading} onClick={handleClick}>
        {hasItems ? <AnimatedReloadIcon $isLoading={isLoading} /> : <PlusIcon />}
        <span>&nbsp;{text}</span>
      </TextBtn>
    </TooltipComponent>
  );
}

export default GenerateButton;
