import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ComparisonSelectorMenu from '@/components/Filters/ComparisonSelectorMenu';
import FilterSelectorMenu from '@/components/Filters/FilterSelectorMenu';
import Flex from '@/design-system/components/Flex/Flex';
import { BOOL_TYPE_FILTER } from '@/utils/filter/constants';

import { FilterWrapper, GroupFilterWrapper } from './FilterGroups.styled';
import { useFilterGroups } from './useFilterGroups';

const FIRST_FILTER = 0;

function FilterGroups({
  applyFilters,
  defaultFilters = {},
  filtersList,
  dataIntercomTarget,
  folders = [],
  createNewFolder = () => {},
  isCreationDisabled = true,
}) {
  const { t } = useTranslation();
  const { INITIAL_FILTERS_STATE, OPERATORS, getOperatorLabel, AND_OPERATOR } = useFilterGroups();

  const [filters, setFilters] = useState(INITIAL_FILTERS_STATE);

  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...defaultFilters,
    }));
  }, [defaultFilters]);

  function addFilterGroup(filterGroup) {
    let defaultFilter = {
      comparison: 'eq',
      labelKey: t('components:filters.is'),
    };
    let defaultValues = [];

    if (filterGroup.type.type === 'string') {
      defaultFilter = {
        comparison: 'contains',
        labelKey: t('components:filters.contains'),
      };
    }
    if (filterGroup.type.type === 'number') {
      defaultFilter = {
        comparison: 'gt',
        labelKey: t('components:filters.greater-than'),
      };
    }
    if (filterGroup.type.type === 'date') {
      defaultFilter = {
        comparison: 'eq',
        labelKey: t('components:filters.on'),
      };
    }
    if (filterGroup.type.type === 'bool') {
      defaultValues = [{ labelKey: filterGroup.placeholder, value: true }];
    }
    if (filterGroup.type.type === 'folder') {
      // There is always at least one folder (General)
      defaultValues = [{ value: folders[0] }];
    }

    setFilters({
      ...filters,
      filterGroups: [
        ...filters.filterGroups,
        {
          filters: [
            {
              attribute: filterGroup.attribute,
              comparison: defaultFilter,
              getValueByAttribute: filterGroup.getValueByAttribute,
              icon: filterGroup.icon,
              labelKey: filterGroup.labelKey,
              mappingValues: filterGroup.mappingValues,
              placeholder: filterGroup.placeholder,
              selectList: filterGroup.selectList,
              type: filterGroup.type,
              uid: filterGroup.uid,
              values: defaultValues,
            },
          ],
          operator: AND_OPERATOR,
        },
      ],
    });
  }

  function deleteFilterGroup(index) {
    return () => {
      const filterGroups = filters.filterGroups;
      filterGroups.splice(index, 1);

      const newFiltersList = {
        ...filters,
        filterGroups,
      };

      setFilters(newFiltersList);
      applyFilters(newFiltersList);
    };
  }

  function changeOperator(operator) {
    const newFiltersList = { ...filters, operator: operator.type };
    setFilters(newFiltersList);
    applyFilters(newFiltersList);
  }

  function changeFilterComparison(comparison, groupIndex, filterIndex) {
    const filter = filters.filterGroups?.[groupIndex].filters?.[filterIndex];

    if (!filter) {
      return;
    }

    filter.comparison = comparison;

    setFilters({
      ...filters,
    });
  }

  function changeFilterValue(value, groupIndex, filterIndex) {
    const filter = filters.filterGroups?.[groupIndex].filters?.[filterIndex];

    if (!filter) {
      return;
    }

    filter.values = filter.mappingValues
      ? [filter.mappingValues(value)]
      : [{ labelKey: value, value }];

    setFilters({
      ...filters,
    });
  }

  function onApply() {
    applyFilters(filters);
  }

  function filterHasMissingValues(values) {
    return values.every((value) => value.value === '' || value.value === undefined);
  }

  return (
    <Flex alignItems="center" data-intercom-target={dataIntercomTarget} flexWrap="wrap" gap="5px">
      {filters.filterGroups.map((filterGroup, groupIndex) => (
        <Flex key={`filter_group_${groupIndex}`} alignItems="center" gap="5px" maxHeight="35px">
          {/* Group of Filters */}
          <GroupFilterWrapper
            active={filterGroup.filters.some((filter) => !filterHasMissingValues(filter.values))}
          >
            {filterGroup.filters.map((filter, index) => {
              const { comparison, icon, labelKey, placeholder, selectList, type, values } = filter;

              // 0, true, false are possible values
              // "", undefined are not accepted
              const hasMissingValues = filterHasMissingValues(values);

              if (
                type.type === 'folder' &&
                !values[FIRST_FILTER].labelKey &&
                !values[FIRST_FILTER].label
              ) {
                values[FIRST_FILTER] = {
                  labelKey: t('components:select.folder-select.label-folder-general'),
                };
              }
              const filterValues = hasMissingValues
                ? ''
                : `${t(comparison.labelKey)} ${values
                    .map((val) => t(val.labelKey || val.label))
                    .join('-')}`;
              return (
                <FilterWrapper key={`${groupIndex}_filter_${index}`}>
                  <ComparisonSelectorMenu
                    active={!hasMissingValues}
                    buttonLabel={t(labelKey)}
                    buttonValue={filterValues}
                    checkedValue={comparison}
                    createNewFolder={createNewFolder}
                    deleteGroup={deleteFilterGroup(groupIndex)}
                    error={hasMissingValues}
                    folders={folders}
                    icon={icon}
                    isCreationDisabled={isCreationDisabled}
                    menuItems={type.comparisonList?.map((el) => ({
                      ...el,
                      label: t(el.labelKey),
                    }))}
                    // default value of bool makes error not true
                    // the open prop forces the menu to be open by default in that case
                    open={type.type === BOOL_TYPE_FILTER.type}
                    placeholder={t(placeholder)}
                    selectList={selectList}
                    value={values[0] || {}}
                    onApply={onApply}
                    onChangeComparison={(comparison) => {
                      changeFilterComparison(comparison, groupIndex, index);

                      // when the type is bool, we set the value to true once the comparison method is selected
                      // because the render of the input revealer is null in this case
                      if (type.type === BOOL_TYPE_FILTER.type) {
                        changeFilterValue(true, groupIndex, index);
                      }
                    }}
                    onChangeValue={(value) => changeFilterValue(value, groupIndex, index)}
                  />
                  {
                    /* Operator between Filters inside a group */
                    filterGroup.filters.length - 1 !== index && <span>{filterGroup.operator}</span>
                  }
                </FilterWrapper>
              );
            })}
          </GroupFilterWrapper>

          {/* Operator between Groups */}
          {filters.filterGroups.length - 1 !== groupIndex && (
            <FilterSelectorMenu
              buttonLabel={getOperatorLabel(filters.operator)}
              menuItems={OPERATORS}
              size="sm"
              onClick={changeOperator}
            />
          )}
        </Flex>
      ))}

      {/* Fake Filter Group Adder :p */}
      <FilterSelectorMenu
        buttonLabel={t('components:filters.filter-groups.button-add-filter')}
        menuItems={filtersList}
        onClick={addFilterGroup}
      />
    </Flex>
  );
}

export default FilterGroups;
