import './SearchIntelligenceReportLayout.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useParams } from 'react-router-dom';

import useGetCompetitorReports from '@/apis/semji/competitors/useGetCompetitorReports';
import Loader from '@/components/Loader/Loader';
import { CompetitorEmptyState } from '@/containers/Competitors/components/EmptyState';
import { CompetitorRestrictionEmptyState } from '@/containers/Competitors/components/RestrictionEmptyState';
import { SEARCH_INTELLIGENCE_PAGES } from '@/containers/Competitors/utils/constants';
import useApiConfigurations from '@/hooks/useApiConfigurations';
import useOrganizationFeatureSet, {
  COMPETITORS__IS_ENABLED,
} from '@/hooks/useOrganizationFeatureSet';
import useParamSelector from '@/store/hooks/useParamSelector';
import { selectDefaultReportId } from '@/store/selectors/selectDefaultReportId';
import { selectHasAccessToCompetitorsAdmin } from '@/store/selectors/selectHasAccessToCompetitorsAdmin';
import { selectCurrentWorkspaceAccessByKey } from '@/store/selectors/selectWorkspaceAccessByKey';
import { ENUM_COMPETITOR_REPORT_STATUS } from '@/types/competitors';
import { COMPETITORS_ENABLED } from '@/utils/configurations/constants';

export default function SearchIntelligenceReportLayout() {
  const { t } = useTranslation();

  const { organizationId, workspaceId, reportId } = useParams();

  const isCompetitorAdmin = useSelector(selectHasAccessToCompetitorsAdmin);
  const defaultReportId = useSelector(selectDefaultReportId);
  const competitorsEnabled = useApiConfigurations(COMPETITORS_ENABLED);

  const { isFeatureEnabled } = useOrganizationFeatureSet(COMPETITORS__IS_ENABLED);
  const accessToSearchIntelligence = useParamSelector(selectCurrentWorkspaceAccessByKey, {
    key: 'accessToSearchIntelligence',
    workspaceId: workspaceId,
  });

  const canFetchCompetitorReports =
    isFeatureEnabled && competitorsEnabled && accessToSearchIntelligence;

  const isReportIdAPagePath = Object.values(SEARCH_INTELLIGENCE_PAGES).includes(reportId);

  const {
    isLoading: isLoadingReport,
    isPlaceholderData: isPlaceholderDataReport,
    data: competitorReports,
  } = useGetCompetitorReports({
    enabled: canFetchCompetitorReports,
    staleTime: 0,
  });

  const hasReports =
    competitorReports &&
    competitorReports.some((report) =>
      [
        ENUM_COMPETITOR_REPORT_STATUS.CURRENT_ACTIVE,
        ENUM_COMPETITOR_REPORT_STATUS.PENDING,
      ].includes(report.status)
    );

  if (canFetchCompetitorReports && (isLoadingReport || isPlaceholderDataReport)) {
    return (
      <div className="competitors-loader">
        <Loader fontSize="3em" opaque>
          <p className="competitors-loader__text">{t('common:labels.loading')}</p>
        </Loader>
      </div>
    );
  }

  const currentActiveReport = competitorReports?.find(
    (report) => report.status === ENUM_COMPETITOR_REPORT_STATUS.CURRENT_ACTIVE
  );
  const pendingReport = competitorReports?.find(
    (report) => report.status === ENUM_COMPETITOR_REPORT_STATUS.PENDING
  );

  // If the user does not have access to the search intelligence let's show the restricted access page
  if (!accessToSearchIntelligence) {
    return <CompetitorRestrictionEmptyState />;
  }

  // If there are no displayable reports and the user does not have access to the competitors admin
  // or the feature is not enabled
  // let's display the empty state
  if (!isFeatureEnabled || (!hasReports && !isCompetitorAdmin)) {
    return <CompetitorEmptyState />;
  }

  // If there are no displayable reports and the user has access to the competitors admin let's redirect to the configuration page
  if (!hasReports && isCompetitorAdmin) {
    return (
      <Navigate to={`/o/${organizationId}/w/${workspaceId}/search-intelligence/configuration`} />
    );
  }
  // If the reportId is a page path let's redirect to the correct page
  if (isReportIdAPagePath) {
    if (currentActiveReport) {
      return (
        <Navigate
          to={`/o/${organizationId}/w/${workspaceId}/search-intelligence/${currentActiveReport.id}/${reportId}`}
        />
      );
    }
    if (pendingReport) {
      return (
        <Navigate
          to={`/o/${organizationId}/w/${workspaceId}/search-intelligence/${pendingReport.id}/analyzing`}
        />
      );
    }
  }

  // If the reportId is not specified let's redirect to the active report or the pending report if there is no active report
  if (!reportId) {
    const isDefaultReportIdValid = competitorReports?.find(
      (competitorReport) =>
        defaultReportId === competitorReport.id &&
        [
          ENUM_COMPETITOR_REPORT_STATUS.CURRENT_ACTIVE,
          ENUM_COMPETITOR_REPORT_STATUS.DEPRECATED,
        ].includes(competitorReport.status)
    );
    const fallbackReportId = isDefaultReportIdValid ? defaultReportId : currentActiveReport?.id;
    if (currentActiveReport) {
      return (
        <Navigate
          to={`/o/${organizationId}/w/${workspaceId}/search-intelligence/${fallbackReportId}/reports`}
        />
      );
    }
    return (
      <Navigate
        to={`/o/${organizationId}/w/${workspaceId}/search-intelligence/${pendingReport?.id}/analyzing`}
      />
    );
  }

  // If the reportId is not valid let's redirect to the root page that will redirect to the correct reportId
  if (!competitorReports.some((report) => report.id === reportId)) {
    return <Navigate to={`/o/${organizationId}/w/${workspaceId}/search-intelligence`} />;
  }

  return <Outlet context={{ competitorReports }} />;
}
