import MuiTab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React from 'react';
import styled from 'styled-components/macro';

import {
  FlexCenteredAlignContainer,
  FlexJustifiedBetweenAndAlignCenterContainer,
} from '@/components/_common';
import { PrimaryTitle } from '@/components/Text/Title';

export const Tab = styled(
  ({
    smallHeight,
    smallWidth,
    labelLeft,
    minWidth,
    noGrow,
    orientation,
    noMaxWidth,
    // Take out component from props because MUI throw an error: "The `component` prop provided to ButtonBase is invalid."
    // TODO: find why it causes an error on Tabs in Settings page
    // component,
    ...props
  }) => {
    return (
      <MuiTab
        {...props}
        classes={{
          wrapper: 'wrapper',
        }}
      />
    );
  }
)`
  && {
    min-width: ${({ minWidth }) => (minWidth ? '100px' : '0px')};
    flex-grow: ${({ noGrow }) => !noGrow && '1'};
    max-width: ${({ noMaxWidth, smallWidth }) =>
      noMaxWidth ? 'none' : smallWidth ? '175px' : '264px'};
    height: ${({ smallHeight }) => (smallHeight ? '48px' : '60px')};
    padding: 0;
    .wrapper {
      font-size: 1rem;
      align-items: ${({ labelLeft }) => (labelLeft ? 'flex-start' : 'center')};
      color: ${({ orientation, selected, theme }) => {
        if (selected) {
          return orientation === 'vertical'
            ? theme.colors.secondary
            : theme.cssColors.secondaryBlue;
        }

        return orientation === 'vertical' ? theme.cssColors.dark100 : theme.cssColors.dark040;
      }};
    }
    opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
    &:hover {
      background: ${({ selected }) => !selected && `rgba(0, 0, 0, 0.01)`};
    }
  }
`;

export const SidePanelTabsWrapper = styled.div`
  width: 300px;
  min-width: 300px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  height: auto;
  overflow-y: auto;
`;

export const TabBodyWrapper = styled.div`
  overflow-y: auto;
  position: relative;
  color: ${(props) => props.theme.text.colors.default};
  font-size: 18px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;
export const TabBodyContent = styled.div`
  padding: 2.5rem 3rem;
  box-sizing: border-box;
  width: 100%;
`;
export const StretchTabBodyContent = styled.div`
  height: 85%;
  padding: 2.5rem 3rem 0 3rem;
  box-sizing: border-box;
  width: 100%;
`;
export const TabBodyTitle = styled(PrimaryTitle)`
  && {
    display: flex;
    align-items: center;
    height: 35px;
    font-weight: 400;
    font-size: 1.6rem;
    padding-bottom: 1rem;
  }
`;
const TabLabelTitle = styled.span`
  font-size: ${(props) => props.theme.textCss.sizes.sm};
  margin-left: ${(props) => props.margin && '.5rem'};
  margin-right: ${(props) => props.margin && '.5rem'};
  font-weight: ${(props) => props.theme.textCss.weights.medium};
  text-transform: none;
  &:first-letter {
    text-transform: capitalize;
  }
`;
const TabLabel = styled(FlexJustifiedBetweenAndAlignCenterContainer)`
  width: 100%;
`;
const TabContent = styled(FlexCenteredAlignContainer)`
  justify-content: ${(props) => props.align};
`;

export const Label = ({
  label,
  startAdornmentIcon,
  endAdornmentIcon,
  align = 'center',
  className,
}) => (
  <TabLabel {...className}>
    <TabContent align={align}>
      {startAdornmentIcon}
      <TabLabelTitle margin={!!startAdornmentIcon}>{label}</TabLabelTitle>
    </TabContent>
    {endAdornmentIcon}
  </TabLabel>
);

export default styled((props) => (
  <Tabs classes={{ indicator: 'indicator', scroller: 'scroller' }} indicatorColor="" {...props} />
))`
  && {
    flex-grow: 1;
    overflow: visible;
    .scroller {
      overflow: visible;
    }
    .indicator {
      border-bottom: 2px solid ${({ theme }) => theme.cssColors.secondaryBlue};
      width: ${(props) => props.orientation === 'vertical' && 0};
      height: ${(props) => props.orientation === 'vertical' && 0};
    }
  }
`;

export const VerticalTabs = styled((props) => (
  <Tabs
    classes={{ indicator: 'indicator', scroller: 'scroller' }}
    indicatorColor=""
    orientation="vertical"
    {...props}
  />
))`
  && {
    overflow: visible;
    .scroller {
      overflow: visible;
    }
    .indicator {
      border-bottom: 2px solid ${({ theme }) => theme.cssColors.secondaryBlue};
      width: 0;
      height: 0;
    }
  }
`;
