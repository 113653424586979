import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import apiClient from '@/apis/semji/apiClient';
import { showErrorSnackbar } from '@/store/actions/ui';

export default function usePostServiceIntegration(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { post } = apiClient;
  const { workspaceId } = useParams();

  return useMutation({
    mutationFn: async ({ data, name, type }) => {
      await post(`/workspaces/${workspaceId}/service_integrations`, {
        data,
        name,
        type,
      });
    },
    onError: () => {
      dispatch(showErrorSnackbar(t('common:error.default')));
    },
    ...props,
  });
}
