import styled from 'styled-components/macro';

import CalendarWithBackgroundIcon from '@/components/icons/CalendarWithBackgroundIcon';
import OptimizationIcon from '@/components/icons/OptimizationIcon';
import TrafficIcon from '@/components/icons/TrafficIcon';
import { DefaultLink } from '@/components/Navigation/Link';
import { ParagraphText } from '@/components/Text/Paragraph';
import { TitleTwo } from '@/components/Text/Title';
import { EditorEmptyBodyWrapper } from '@/containers/Content/Wrappers';
import PrimaryButton from '@/design-system/components/Button/PrimaryButton';
import { mediaQueries } from '@/themes/responsive';

const TextWrapper = styled.div`
  text-align: center;
  margin-bottom: 100px;
`;
export const StyledTrafficIcon = styled(TrafficIcon)`
  && {
    font-size: 150px;
  }
`;
export const StyledOptimizationIcon = styled(OptimizationIcon)`
  && {
    width: 150px;
  }
`;
export const StyledCalendarIcon = styled(CalendarWithBackgroundIcon)`
  && {
    font-size: 150px;
  }
`;
export const WrapperWithBackground = styled.div`
  background-image: ${({ imageUrl }) => "url('" + imageUrl + "')"};
  background-position: left bottom;
  background-size: 100%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  background-size: ${({ backgroundSize }) => backgroundSize};
  @media ${mediaQueries.range.laptop} {
    background-image: ${({ imageUrlDesktop, imageUrl }) =>
      "url('" + (imageUrlDesktop ?? imageUrl) + "')"};
  }
  @media ${mediaQueries.range.desktop} {
    background-image: ${({ imageUrlLaptop, imageUrl }) =>
      "url('" + (imageUrlLaptop ?? imageUrl) + "')"};
  }
`;
export const SimpleBodyEmptyState = ({ title, url, buttonLabel }) => (
  <TextWrapper>
    <ParagraphText noPadding>{title}</ParagraphText>
    {!!url ? (
      <DefaultLink decoration={!!url} to={url}>
        {buttonLabel}
      </DefaultLink>
    ) : (
      <ParagraphText align="center" color="primary">
        {buttonLabel}
      </ParagraphText>
    )}
  </TextWrapper>
);
export const EmptyStatePattern = ({ icon, title, text, url, buttonLabel, openModal }) => (
  <EditorEmptyBodyWrapper>
    {icon}
    <TitleTwo weight="strong">{title}</TitleTwo>
    <ParagraphText align="center" color="dark060" padding="1rem" weight="medium">
      {text}
    </ParagraphText>
    {buttonLabel &&
      (openModal ? (
        <PrimaryButton weight="strong" onClick={openModal}>
          {buttonLabel}
        </PrimaryButton>
      ) : (
        <DefaultLink to={url} weight="strong">
          <PrimaryButton weight="strong">{buttonLabel}</PrimaryButton>
        </DefaultLink>
      ))}
  </EditorEmptyBodyWrapper>
);
