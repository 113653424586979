import { DateTime } from 'luxon';

import {
  METRICS_CONFIG_PERIOD_LAST_3_MONTHS,
  METRICS_CONFIG_PERIOD_LAST_6_MONTHS,
  METRICS_CONFIG_PERIOD_LAST_12_MONTHS,
  METRICS_CONFIG_PERIOD_LAST_30_DAYS,
  METRICS_CONFIG_PERIOD_LAST_90_DAYS,
  METRICS_CONFIG_PERIOD_LAST_180_DAYS,
  METRICS_CONFIG_PERIOD_LAST_365_DAYS,
  METRICS_CONFIG_PERIOD_LAST_MONTH,
} from './constants';
import { getPreviousDateRangeGapDuration } from './getPreviousDateRangeGapDuration';

// returns two comparable range
// today date is passed throw the different methods for test purpose
export const getComparisonDates = ({
  period,
  comparisonPeriod,
  today,
}: {
  period: string;
  comparisonPeriod: string;
  today?: DateTime;
}) => {
  let periodInterpretation = period;

  // we are interpreting the rolling period and the complete period the same way
  // to compare always complete monthly data
  if (period === METRICS_CONFIG_PERIOD_LAST_365_DAYS) {
    periodInterpretation = METRICS_CONFIG_PERIOD_LAST_12_MONTHS;
  }
  if (period === METRICS_CONFIG_PERIOD_LAST_180_DAYS) {
    periodInterpretation = METRICS_CONFIG_PERIOD_LAST_6_MONTHS;
  }
  if (period === METRICS_CONFIG_PERIOD_LAST_90_DAYS) {
    periodInterpretation = METRICS_CONFIG_PERIOD_LAST_3_MONTHS;
  }
  if (period === METRICS_CONFIG_PERIOD_LAST_30_DAYS) {
    periodInterpretation = METRICS_CONFIG_PERIOD_LAST_MONTH;
  }

  const gapDuration = getPreviousDateRangeGapDuration(periodInterpretation, comparisonPeriod);
  // minus 1 month is to get a full/completed last month
  const DatetimeTo = (today ?? DateTime.local()).minus({ months: 1 });

  return {
    currentRange: {
      after: DatetimeTo.startOf('month').toSQLDate(),
      before: DatetimeTo.endOf('month').toSQLDate(),
    },
    lastMonth: DateTime.local().minus({ months: 1 }).startOf('month').toSQLDate(),
    previousRange: {
      after: DatetimeTo.minus(gapDuration).startOf('month').toSQLDate(),
      before: DatetimeTo.minus(gapDuration).endOf('month').toSQLDate(),
    },
    today: DateTime.local().toSQLDate(),
    yesterday: DateTime.local().minus({ days: 1 }).toSQLDate(),
  };
};
