import React from 'react';
import styled from 'styled-components/macro';

import {
  getAStyle,
  getBlockquoteStyle,
  getBoldStyle,
  getCodeStyle,
  getDivStyle,
  getEmStyle,
  getH1Style,
  getH2Style,
  getH3Style,
  getH4Style,
  getH5Style,
  getH6Style,
  getImgStyle,
  getLineStyle,
  getLiStyle,
  getOlStyle,
  getPStyle,
  getStrongStyle,
  getTableStyle,
  getUlStyle,
  getUStyle,
} from '@/containers/Content/EditorComponents/Nodes';
import { SECTIONS } from '@/utils/log/constants';
import { Log } from '@/utils/log/Log';

const IframeWrapper = styled.iframe`
  flex: 1;
  overflow: hidden;
`;

export default class Iframe extends React.Component {
  componentDidMount() {
    this.setIframeContent();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.html !== this.props.html) {
      this.setIframeContent();
    }
  }

  async setIframeContent() {
    const { id, html, url } = this.props;
    const iframe = document.getElementById(id);

    if (!!iframe && !!html && !!url) {
      try {
        const iframeContent = iframe.contentWindow || iframe.contentDocument;
        const iframeDocument = iframeContent.document ? iframeContent.document : iframeContent;
        // reset the content of the iframe
        // if we refresh using an iframe, the new html will append to the previous one
        iframeDocument.body.innerHTML = '';

        // We can use pointer-events:none; to disable all interactions with the iframe
        iframeDocument.write(`
          <base target="_blank" href="${url}" />
          ${html}
          <style>
            @font-face {
              font-family: Rubik;
              font-weight: 400;
              font-style: normal;
              src: url(https://fonts.gstatic.com/s/rubik/v7/iJWKBXyIfDnIV7nBrXyw023e.woff2);
            }
            @font-face {
              font-family: Rubik;
              font-weight: 500;
              font-style: normal;
              src: url(https://fonts.gstatic.com/s/rubik/v7/iJWHBXyIfDnIV7Eyjmmd8WD07oB-.woff2);
            }
            body {
              font-family : Rubik, sans-serif;
              color : rgba(0, 0, 0, 0.8);
              line-height : 1.6em;
              font-size : 16px;
              padding: 0 8%;
              margin: 0;
            }
            /* Customize website's scrollbar like Mac OS Not supports in Firefox and IE */
            /* total width */
            body::-webkit-scrollbar {
                background-color:#fff;
                width:22px;
            }
            /* background of the scrollbar except button or resizer */
            body::-webkit-scrollbar-track {
                background-color:#fff
            }
            /* scrollbar itself */
            body::-webkit-scrollbar-thumb {
                background-color:#babac0;
                border-radius:22px;
                height: 50px;
                border:6px solid #fff;
            }
            /* set button(top and bottom of the scrollbar) */
            body::-webkit-scrollbar-button {
              display:none;
            }
            blockquote {
              ${getBlockquoteStyle()}
            }
            code {
              ${getCodeStyle()}
            }
            ul {
              ${getUlStyle()}
            }
            h1 {
              ${getH1Style()}
            }
            h2 {
              ${getH2Style()}
            }
            h3 {
              ${getH3Style()}
            }
            h4 {
              ${getH4Style()}
            }
            h5 {
              ${getH5Style()}
            }
            h6 {
              ${getH6Style()}
            }
            li {
              ${getLiStyle()}
            }
            li h1,
            li h2,
            li h3,
            li h4,
            li h5,
            li h6 {
              display: inline-block;
            }
            ol {
              ${getOlStyle()}
            }
            line {
              ${getLineStyle()}
            }
            a {
              ${getAStyle()}
            }
            img {
              ${getImgStyle()}
            }
            strong {
              ${getStrongStyle()}
            }
            bold {
              ${getBoldStyle()}
            }
            em {
              ${getEmStyle()}
            }
            u {
              ${getUStyle()}
            }
            p {
              ${getPStyle()}
            }
            div {
              ${getDivStyle()}
            }
            table {
              ${getTableStyle()}
            }
          </style>
          `);
      } catch (error) {
        Log.report({
          context: 'setIframeContent',
          error,
          extra: 'Set the iframe content in the online editor',
          section: SECTIONS.content.key,
        });
      }
    }
  }

  render() {
    return <IframeWrapper id={this.props.id} src={'about:blank'} />;
  }
}
