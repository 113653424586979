import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import usePutRegistrant from '@/apis/semji/registrants/usePutRegistrant';
import InstructionsSent from '@/assets/images/instructions_sent.svg';
import {
  FlexColumnCenteredAlignContainer,
  FlexGrowColumnCenteredContainer,
} from '@/components/_common';
import { FormBox } from '@/components/Box/Box';
import { ONLY_LOGO_WHITE_BACKGROUND } from '@/components/Layout/Layout';
import { RelativeLoader } from '@/components/Loader/Loader';
import { SecondaryTitleTwo } from '@/components/Text/Title';
import OopsScreen from '@/containers/Errors/OopsScreen';
import LayoutWrapper from '@/containers/Layout/Layout';
import useUrlQuery from '@/hooks/useUrlQuery';
import { setSelectedPlanId } from '@/store/actions/signup';
import { fetchAuthenticatedUser } from '@/store/actionsCreator/user';

const StyledSecondaryTitleTwo = styled(SecondaryTitleTwo)`
  && {
    margin: 20px 0px;
  }
`;

function VerifyEmail() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { registrantToken } = useParams();
  const query = useUrlQuery();
  const planId = query.get('planId') ?? null;

  function getUserData() {
    dispatch(fetchAuthenticatedUser());
    navigate('/', { replace: true });
  }

  const { isError, mutate, error } = usePutRegistrant({
    onSuccess: getUserData,
    registrantToken,
  });

  useEffect(() => {
    mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(setSelectedPlanId(planId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planId]);

  function getError(status) {
    // eslint-disable-next-line no-console
    switch (status) {
      case 400:
      case 422:
        return t('create-account:verify-email.errors.400');
      case 404:
        return t('create-account:verify-email.errors.404');
      default:
        return t('create-account:verify-email.errors.an-error-occurred');
    }
  }

  if (isError) {
    return (
      <OopsScreen
        error={getError(error?.response?.status)}
        pageTitle={t('create-account:verify-email.errors.registration-error')}
      />
    );
  }

  return (
    <LayoutWrapper menu={ONLY_LOGO_WHITE_BACKGROUND}>
      <Helmet>
        <title>{t('create-account:verify-email.title')}</title>
      </Helmet>
      <FlexGrowColumnCenteredContainer>
        <FormBox>
          <FlexColumnCenteredAlignContainer>
            <img alt={t('create-account:verify-email.alt-image')} src={InstructionsSent} />
            <br />
            <StyledSecondaryTitleTwo noMargin weight="medium">
              {t('create-account:verify-email.verifying-your-email')}
            </StyledSecondaryTitleTwo>
            <RelativeLoader />
          </FlexColumnCenteredAlignContainer>
        </FormBox>
      </FlexGrowColumnCenteredContainer>
    </LayoutWrapper>
  );
}

export default VerifyEmail;
