import MenuItem from '@material-ui/core/MenuItem';
import { lighten } from 'polished';
import React from 'react';
import styled from 'styled-components/macro';

export const WorkspaceMenuItem = styled(({ selected, ...props }) => <MenuItem {...props} />)`
  && {
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    border-left: 3px solid;
    border-left-color: ${(props) =>
      props.selected ? props.theme.colors.secondary : 'transparent'};
    &&:hover {
      background-color: ${(props) => lighten(0.33, props.theme.colors.secondary)};
    }
    background-color: transparent;
  }
`;
