import { MutableRefObject, useRef } from 'react';

import { QUERY_PARAM_SORT } from '@/components/AGGrid/hooks/useGridSorts/const';
import {
  ISort,
  UseGridSortsProps,
  UseGridSortsResults,
} from '@/components/AGGrid/hooks/useGridSorts/useGridSorts.types';
import { UseGridSortsUtils } from '@/components/AGGrid/utils/useGridSorts.utils';
import { ObjectUtils } from '@/utils/object/Object.utils';

export function useGridSorts({ filterHook }: UseGridSortsProps): UseGridSortsResults {
  const [query, setQuery] = filterHook.searchParamsHook;
  const sortsRef: MutableRefObject<ISort | undefined> = useRef<ISort | undefined>(
    UseGridSortsUtils.getUnhashedSorts(query)
  );

  // Mutate sorts ref and query params
  function setSort(payload?: ISort) {
    // Do not mutate sorting if payload is equal to current sorting
    if (ObjectUtils.fastEqual(sortsRef.current ?? {}, payload ?? {})) return;
    sortsRef.current = payload;
    if (sortsRef.current) {
      query.set(QUERY_PARAM_SORT, UseGridSortsUtils.getStringifiedSort(sortsRef.current));
      setQuery(query);
    } else {
      removeSortQueryParam();
    }
  }

  // Remove sort from query params
  function removeSortQueryParam() {
    query.delete(QUERY_PARAM_SORT);
    setQuery(query);
  }

  // Will add or update a sort
  function addSort(sort: ISort) {
    setSort(sort);
  }

  // Will remove a sort by key
  function removeSort() {
    setSort();
    removeSortQueryParam();
  }

  return {
    addSort,
    removeSort,
    sort: UseGridSortsUtils.getUnhashedSorts(query),
  };
}
