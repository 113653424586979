import lowerFirst from 'lodash/lowerFirst';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { Instruction, InstructionProgressBar } from '@/components/Optimization/Instruction';
import { DarkText } from '@/components/Text/Dark';
import { BrightText } from '@/components/Text/Light';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import DetailsBase from '@/containers/Content/SidePanel/Components/Optimization/Details/DetailsBase';
import RecommendationDetails from '@/containers/Content/SidePanel/Components/Optimization/Details/RecommendationDetails';
import {
  disableRecommendationOptimization,
  enableRecommendationOptimization,
} from '@/store/actionsCreator/report';
import { getRelevanceOfRecommendation } from '@/utils/recommendationOccurrenceInterval';

const WrapperClusters = styled.div`
  padding: 0 1.5em;
`;
const RelatedTermWrapper = styled.div`
  &:hover ${WrapperClusters} {
    background-color: ${(props) => props.theme.colors.brightGrey};
  }
`;
const IndicatorText = styled(BrightText)`
  && {
    white-space: nowrap;
    width: 25px;
    text-align: left;
    font-style: italic;
  }
`;
const IndicatorTextDark = styled(DarkText)`
  && {
    white-space: nowrap;
    width: 25px;
    text-align: right;
  }
`;

const StyledTooltipComponent = styled(TooltipComponent)`
  display: flex;
`;

function RelatedTerm({
  competitors,
  focusTopKeywordId,
  id,
  isReadOnly,
  mainId,
  onAddRelatedTerm = () => {},
  onToggleRelatedTerm = () => {},
}) {
  const dispatch = useDispatch();
  const [showDetails, setShowDetails] = useState(false);
  const { t } = useTranslation();

  const html = useSelector((state) => state.content.html);
  const instruction = useSelector((state) => state.report.recommendations[id]);
  const score = useSelector((state) => state.report.recommendations[id].score);
  const title = useSelector((state) => state.content.title);

  const { interval, min, max } = getRelevanceOfRecommendation(instruction.data.medianOccurrences);
  const i18nNameSpace = 'content:side-panel-components.optimization.section.related-terms';

  const occurrenceLabel = t(`${i18nNameSpace}.recommendation-occurrences-label_interval`, {
    count: instruction.data.occurrencesCount,
    postProcess: 'interval',
  });

  function toggleRelatedTerm() {
    if (!instruction.disabled) {
      dispatch(disableRecommendationOptimization(id, mainId, focusTopKeywordId, html, title));
    } else {
      dispatch(enableRecommendationOptimization(id, mainId, focusTopKeywordId, html, title));
    }

    onToggleRelatedTerm(id, !instruction.disabled);
  }

  function addRelatedTerm() {
    onAddRelatedTerm(instruction.data.term);
  }

  function handleClose() {
    setShowDetails(false);
  }

  function handleDetailsShow() {
    if (instruction.data.samples.length > 0) {
      setShowDetails(true);
    }
  }

  return (
    <RelatedTermWrapper>
      <Instruction
        completed={1 === score}
        description={instruction.data.subTopics
          .sort((a, b) => a.priority - b.priority)
          .slice(0, 3)
          .map((subRelatedTerm, index) =>
            index !== 0 ? lowerFirst(subRelatedTerm.term) : subRelatedTerm.term
          )
          .join(', ')}
        disabled={instruction.disabled}
        isInRelatedTerms={true}
        isReadOnly={isReadOnly}
        label={instruction.data.term}
        scorer={
          <StyledTooltipComponent
            title={t(`${i18nNameSpace}.recommendation-relevance_interval`, {
              count: min,
              max,
              min,
              occurrenceLabel,
              postProcess: 'interval',
            })}
          >
            <IndicatorTextDark size="small">{instruction.data.occurrencesCount}</IndicatorTextDark>
            <InstructionProgressBar score={instruction.data.occurrencesCount / min} />
            <IndicatorText size="small">{interval}</IndicatorText>
          </StyledTooltipComponent>
        }
        onAdd={addRelatedTerm}
        onShowDetails={handleDetailsShow}
        onToggle={toggleRelatedTerm}
      />
      <DetailsBase
        show={showDetails}
        title={t(`${i18nNameSpace}.recommendation-relevance-details`, {
          term: instruction.data.term,
        })}
        onClose={handleClose}
      >
        <RecommendationDetails
          data={instruction.data.samples.map((competitor) => ({
            ...competitor,
            ...competitors[competitor.competitorPosition],
          }))}
        />
      </DetailsBase>
    </RelatedTermWrapper>
  );
}

export default RelatedTerm;
