import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import CloseIcon from '@/components/icons/CloseIcon';
import TooltipComponent from '@/components/Tooltip/Tooltip';

const Tooltip = styled(TooltipComponent)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.cssColors.dark040};
  cursor: pointer;
`;
const StyledCloseIcon = styled(CloseIcon)`
  && {
    font-size: 16px;
  }
`;

function RemoveContentButton({ onClick }) {
  const { t } = useTranslation();

  return (
    <Tooltip title={t('content:brief.remove-content-button-tooltip')}>
      <StyledCloseIcon onClick={onClick} />
    </Tooltip>
  );
}

export default RemoveContentButton;
