import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  ATOMIC_CONTENT_TYPE,
  GENERATION_INTRODUCTION_TYPE,
  GENERATION_PARAGRAPH_TYPE,
} from '@/containers/Content/EditorComponents/Ai/constants';
import useApiConfigurations from '@/hooks/useApiConfigurations';
import useOrganizationFeatureSet, {
  AI_WRITING__FACT_CHECKING__IS_ENABLED,
} from '@/hooks/useOrganizationFeatureSet';
import useParamSelector from '@/store/hooks/useParamSelector';
import { selectBrandVoiceByWorkspaceId } from '@/store/selectors/selectBrandVoiceByWorkspaceId';
import { selectUserConfiguration } from '@/store/selectors/selectUserConfiguration';
import {
  AI_WRITING_BRAND_VOICE_CREDITS_ENABLED,
  AI_WRITING_CREDITS_ATOMIC_CONTENT,
  AI_WRITING_CREDITS_INTRODUCTION,
  AI_WRITING_CREDITS_META_DESCRIPTION,
  AI_WRITING_CREDITS_OUTLINE,
  AI_WRITING_CREDITS_PARAGRAPH,
  AI_WRITING_CREDITS_TITLE,
  AI_WRITING_FACT_CHECKING_ENABLED,
} from '@/utils/configurations/constants';

// TODO: Maybe create a feature flag/set for the both multipliers
const FACT_CHECKING_MULTIPLIER = 1;
const BRAND_VOICE_MULTIPLIER = 0; // TODO: Update this value when the brand voice multiplier is defined

export default function useAiWritingCredit() {
  const { workspaceId } = useParams();
  const { factCheckingEnabled } = useSelector(selectUserConfiguration);
  const aiWritingFactCheckingEnabled = useApiConfigurations(AI_WRITING_FACT_CHECKING_ENABLED);
  const { isFeatureEnabled: factCheckingFeatureSetEnabled } = useOrganizationFeatureSet(
    AI_WRITING__FACT_CHECKING__IS_ENABLED
  );
  const titleCredits = useApiConfigurations(AI_WRITING_CREDITS_TITLE);
  const metaDescriptionCredits = useApiConfigurations(AI_WRITING_CREDITS_META_DESCRIPTION);
  const introductionCredits = useApiConfigurations(AI_WRITING_CREDITS_INTRODUCTION);
  const outlineCredits = useApiConfigurations(AI_WRITING_CREDITS_OUTLINE);
  const paragraphCredits = useApiConfigurations(AI_WRITING_CREDITS_PARAGRAPH);
  const atomicContentCredits = useApiConfigurations(AI_WRITING_CREDITS_ATOMIC_CONTENT);
  const aiWritingBrandVoiceCreditsEnabled = useApiConfigurations(
    AI_WRITING_BRAND_VOICE_CREDITS_ENABLED
  );
  const brandVoiceSelected = useParamSelector(selectBrandVoiceByWorkspaceId, workspaceId);

  const isBrandVoiceEnabled = aiWritingBrandVoiceCreditsEnabled && brandVoiceSelected;
  const isFactCheckingEnabled =
    aiWritingFactCheckingEnabled && factCheckingFeatureSetEnabled && factCheckingEnabled;

  function getAiWritingCredit(type: string): number {
    const credits = {
      ATOMIC_CONTENT_TYPE: atomicContentCredits,
      GENERATION_INTRODUCTION_TYPE: introductionCredits,
      GENERATION_METADESCRIPTION_TYPE: metaDescriptionCredits,
      GENERATION_OUTLINE_TYPE: outlineCredits,
      GENERATION_PARAGRAPH_TYPE: paragraphCredits,
      GENERATION_TITLE_TYPE: titleCredits,
    }[type];

    let multiplier = 1;

    if (
      [GENERATION_PARAGRAPH_TYPE, GENERATION_INTRODUCTION_TYPE, ATOMIC_CONTENT_TYPE].includes(type)
    ) {
      if (isFactCheckingEnabled) {
        multiplier += FACT_CHECKING_MULTIPLIER;
      }

      if (isBrandVoiceEnabled) {
        multiplier += BRAND_VOICE_MULTIPLIER;
      }
    }

    return credits * multiplier;
  }

  return { getAiWritingCredit };
}
