import { useTranslation } from 'react-i18next';
import { DialogModal, ENUM_DIALOG_MODAL_SIZE } from 'semji-core/components/DialogModal';
import styled from 'styled-components/macro';

import Flex from '@/design-system/components/Flex/Flex';

const Img = styled.img`
  min-width: ${(props) =>
    props.size === 'small' ? '30px' : props.size === 'huge' ? '100px' : '35px'};
  border-radius: 50%;
  border: ${(props) => props.border};
`;

export function RemoveImageDialog({
  handleCancel,
  handleConfirm,
  imageUrl,
  confirmLabel,
  title,
  text,
}) {
  const { t } = useTranslation();
  return (
    <DialogModal
      cancelAction={handleCancel}
      cancelLabel={t('settings:profile.cancel-dialog')}
      canClickOutsideToClose
      closable
      confirmAction={handleConfirm}
      confirmLabel={confirmLabel}
      isOpen
      size={ENUM_DIALOG_MODAL_SIZE.Small}
      title={title}
      onClose={handleCancel}
    >
      {text}
      <Flex justifyContent="center">
        <Img alt="avatar" height="240px" src={imageUrl} width="240px" />
      </Flex>
    </DialogModal>
  );
}
