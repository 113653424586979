import styled from 'styled-components/macro';

import { FlexContainer, FlexRowCenteredContainer } from '@/components/_common';
import { DefaultLink } from '@/components/Navigation/Link';
import { ParagraphText } from '@/components/Text/Paragraph';
import { TitleFive } from '@/components/Text/Title';

export const Wrapper = styled(FlexContainer)`
  padding: 0 30px 40px 84px;
  justify-content: space-between;
  flex: 1;
`;

export const NoPaddingParagraphText = styled(ParagraphText)`
  && {
    padding: 0;
    margin: ${({ margin }) => margin};
  }
`;

export const StyledParagraphText = styled(ParagraphText)`
  margin-left: ${({ margin }) => margin};
  line-height: 25px;
`;

export const VideoWrapper = styled(FlexRowCenteredContainer)`
  margin-left: 60px;
  padding: 10px;
  border: 2px solid;
  border-image-source: linear-gradient(180deg, #e9e9eb 0%, rgba(233, 233, 235, 0) 100%);
  box-shadow: 0px 3px 10px 0px rgba(37, 39, 54, 0.1);
  width: 468px;
  height: 300px;
  align-self: center;
  flex: unset;
`;

export const OptionalWrapper = styled.div`
  display: inline;
  justify-content: center;
  padding: 0px 10px;
  width: 75px;
  background: ${({ theme }) => theme.cssColors.dark005};
  border-radius: 25px;
`;

export const StyledImg = styled.img`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  margin-left: 60px;
  align-self: center;
  max-width: 468px;
  min-width: 468px;
`;

export const ItemFooter = ({ link, title, textExplanation }) => (
  <>
    <TitleFive noMargin>
      <DefaultLink color="dark100" href={link} isExternal noPadding weight="strong">
        {title}
      </DefaultLink>
    </TitleFive>
    <TitleFive color="dark060" noMargin weight="medium">
      {textExplanation}
    </TitleFive>
  </>
);
