import { nanoid } from 'nanoid';
import React, { useMemo } from 'react';

import SvgWrapper from './SvgWrapper';

function CopyIcon(props) {
  const maskId = useMemo(() => nanoid(), []);

  return (
    <SvgWrapper {...props} fill="none" viewBox="0 0 17 22">
      <path d="M11.667 0.5H4V17.5H17V5.808L11.667 0.5ZM12 2.242L15.273 5.5H12V2.242ZM5 16.5V1.5H11V6.5H16V16.5H5Z" />
      <mask height="18" id={maskId} maskUnits="userSpaceOnUse" width="13" x="0" y="4">
        <path
          d="M7.667 4.5H0V21.5H13V9.808L7.667 4.5ZM8 6.242L11.273 9.5H8V6.242ZM1 20.5V5.5H7V10.5H12V20.5H1Z"
          fill="#C4C4C4"
        />
      </mask>
      <g mask={`url(#${maskId})`}>
        <path clipRule="evenodd" d="M3 3.5H-4V22.5H14V18.5H3V3.5Z" fillRule="evenodd" />
      </g>
    </SvgWrapper>
  );
}

export default CopyIcon;
