import styled from 'styled-components/macro';

const Wrapper = styled.div`
  flex: 1;
  overflow: hidden;
`;

const Iframe = styled.iframe`
  --iframe-zoom: ${({ zoom }) => zoom};
  display: block;
  width: 100%;

  width: calc(100% * var(--iframe-zoom));
  min-height: calc(100% * var(--iframe-zoom));

  transform: scale(calc(1 / var(--iframe-zoom)), calc(1 / var(--iframe-zoom)));
  transform-origin: top left;
`;

function DefaultIframe({ id, html, zoom }) {
  return (
    <Wrapper>
      <Iframe
        id={id}
        referrerPolicy="no-referrer"
        sandbox="allow-same-origin allow-forms allow-popups allow-scripts"
        src="about:blank"
        srcDoc={html}
        zoom={zoom}
      />
    </Wrapper>
  );
}

export default DefaultIframe;
