import i18next from 'i18next';

import { CategoryHeader } from '@/containers/UniversalSearch/Ui/CategoryHeader';
import { PredefinedItem } from '@/containers/UniversalSearch/Ui/PredefinedItem';

export function predefinedItemsPlugin(organizationId, workspaceId, navigate) {
  const predefinedItems = [
    {
      isExternal: true,
      label: i18next.t('search:predefined-routes.help'),
      matches: ['aide', "centre d'aide"],
      url: 'https://help.semji.com',
    },
    {
      label: i18next.t('search:predefined-routes.settings.global'),
      matches: [
        'settings',
        'workspace settings',
        'organization settings',
        'paramètres',
        'espace',
        'organisation',
      ],
      url: `/o/${organizationId}/w/${workspaceId}/settings`,
    },
    {
      label: i18next.t('search:predefined-routes.settings.profile'),
      matches: ['profile', 'personal info', 'informations personnelles'],
      url: `/o/${organizationId}/w/${workspaceId}/settings/user-profile`,
    },
    {
      label: i18next.t('search:predefined-routes.settings.organization-users'),
      matches: [
        'users',
        'organization users',
        'users organization',
        'utilisateurs',
        'utilisateurs organisation',
        'organisation utilisateurs',
      ],
      url: `/o/${organizationId}/w/${workspaceId}/settings/organization-users`,
    },
    {
      label: i18next.t('search:predefined-routes.settings.workspace-users'),
      matches: ['users', 'workspace users', 'users workspace', 'utilisateurs', 'espace de travail'],
      url: `/o/${organizationId}/w/${workspaceId}/settings/workspace-users`,
    },
    {
      label: i18next.t('search:predefined-routes.settings.integrations'),
      matches: ['integrations', 'intégrations'],
      url: `/o/${organizationId}/w/${workspaceId}/settings/integrations`,
    },
    // TODO: add more routes from settings if it seems relevant
    {
      label: i18next.t('search:predefined-routes.dashboard'),
      matches: ['tableau de bord', 'dashboard'],
      url: `/o/${organizationId}/w/${workspaceId}/dashboard`,
    },
    {
      label: i18next.t('search:predefined-routes.content-ideas'),
      matches: ['content ideas', 'idées de contenu'],
      url: `/o/${organizationId}/w/${workspaceId}/content-ideas`,
    },
    {
      label: i18next.t('search:predefined-routes.pages'),
      matches: ['pages'],
      url: `/o/${organizationId}/w/${workspaceId}/contents`,
    },
    {
      label: i18next.t('search:predefined-routes.planning'),
      matches: ['planning'],
      url: `/o/${organizationId}/w/${workspaceId}/planning`,
    },
    {
      label: i18next.t('search:predefined-routes.reports'),
      matches: ['reports', 'rapports'],
      url: `/o/${organizationId}/w/${workspaceId}/reports`,
    },
  ];

  return {
    getSources() {
      return [
        {
          getItemUrl({ item }) {
            return item.url;
          },
          getItems({ query }) {
            if (!query) {
              return predefinedItems.slice(0, 2);
            }

            return predefinedItems.filter((item) => {
              const needle = query.toLowerCase();

              return (
                item.label.toLowerCase().includes(needle) ||
                item.matches.some((itemMatch) => {
                  return itemMatch.toLowerCase().includes(needle);
                })
              );
            });
          },
          onSelect({ item, event }) {
            event.stopPropagation();
            event.preventDefault();

            if (item.isExternal) {
              window.open(item.url, '_blank');
            } else {
              navigate(item.url);
            }
          },
          sourceId: 'predefinedItemsPlugin',
          templates: {
            header({ items }) {
              return <CategoryHeader items={items} label="Links" />;
            },
            item({ item }) {
              return <PredefinedItem item={item} />;
            },
          },
        },
      ];
    },
  };
}
