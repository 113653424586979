export interface Fact {
  criticality: string;
  factCheckedSequence: string;
  id: string;
  isFactChecked: boolean;
  searchEngineQuestion: string | null;
  searchEngineResultsContent: string;
  searchEngineResultsQuote: string;
  searchEngineResultsSourcedAt: string | null;
  searchEngineResultsTitle: string | null;
  searchEngineResultsUrl: string | null;
  status: string;
  enabledAt: string | null;
}

export const FACT_STATUS_KEY = 'status;';

export enum FACT_STATUS_ENUM {
  STATUS_DELETED = 'DELETED',
  STATUS_VALIDATED = 'VALIDATED',
}

export enum FACT_CRITICALITY_ENUM {
  CRITICALITY_VERIFIED = 'verified',
  CRITICALITY_HIGH = 'high',
  CRITICALITY_LOW = 'low',
  CRITICALITY_MEDIUM = 'medium',
}

export enum FACT_FILTER_ENUM {
  CRITICALITY_VERIFIED = 'verified',
  CRITICALITY_MEDIUM = 'medium',
  CRITICALITY_HIGH = 'high',
  STATUS_VALIDATED = 'VALIDATED',
}

export type FactFilter = {
  verified: boolean;
  medium: boolean;
  high: boolean;
  VALIDATED: boolean;
};

export const FACT_QUERY_PARAM = 'factId';
