import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useHover } from 'semji-core/hooks/useHover';
import styled from 'styled-components/macro';

import useGetCurrentWorkspaceContentIdeaSearches from '@/apis/semji/contentIdeas/useGetCurrentWorkspaceContentIdeaSearches';
import AlertAltIcon from '@/components/icons/AlertAltIcon';
import { Cell } from '@/components/Pages/ContentIdeas/common';
import {
  brandedTypeList,
  deprecatedKeywordsTypeList,
  FALSE_VALUE,
  keywordsTypeList,
  LISTING_VALUE,
  rankedTypeList,
  urlsClassificationTypeList,
} from '@/components/Pages/ContentIdeas/Listing/constant';
import {
  LoadingCellRender,
  StyledTooltipComponent,
} from '@/components/Pages/ContentIdeas/Menu/constant';

const FocusKeywordWrapper = styled(Link)`
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.cssColors.dark100};
`;

const KeywordCell = ({ cellData }) => {
  const [hoverRef, isHovered] = useHover();
  const { t } = useTranslation();
  const { organizationId, workspaceId } = useParams();
  const contentIdeaSearches = useGetCurrentWorkspaceContentIdeaSearches();

  function formatUrl(id) {
    const selectedContentIdeaSearch = contentIdeaSearches.data.find((search) => search.id === id);
    const isSearchDeprecated = selectedContentIdeaSearch?.isDeprecated;
    const keywordType = selectedContentIdeaSearch?.isDeprecated
      ? deprecatedKeywordsTypeList[0].value
      : keywordsTypeList[0].value;

    if (isSearchDeprecated) {
      let deprecatedSearchIdsList =
        JSON.parse(localStorage.getItem('deprecatedSearchIdsList')) || {};
      // Cleaning up storage to prevent it to become too big
      if (Object.keys(deprecatedSearchIdsList).length > 100) {
        deprecatedSearchIdsList = { id: true };
      } else {
        deprecatedSearchIdsList[id] = true;
      }

      localStorage.setItem('deprecatedSearchIdsList', JSON.stringify(deprecatedSearchIdsList));
    }

    return `/o/${organizationId}/w/${workspaceId}/content-ideas/${id}?keywordType=${keywordType}&urlClassificationType=${urlsClassificationTypeList[0].value}&brandedType=${brandedTypeList[0].value}&archivedType=${FALSE_VALUE}&viewType=${LISTING_VALUE}&rankedType=${rankedTypeList[0].value}`;
  }

  return (
    <Cell ref={hoverRef} color="dark100" fullWidth noPadding weight="strong">
      <LoadingCellRender
        fullWidth
        status={cellData.status}
        value={
          <FocusKeywordWrapper title={cellData.keyword} to={formatUrl(cellData.id)}>
            {cellData.keyword}
            {cellData.isDeprecated && isHovered && (
              <StyledTooltipComponent
                paddingTop="3px"
                placement="top"
                title={t(`content-ideas:menu.deprecated`)}
              >
                <AlertAltIcon />
              </StyledTooltipComponent>
            )}
          </FocusKeywordWrapper>
        }
      />
    </Cell>
  );
};

export default KeywordCell;
