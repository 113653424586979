import styled from 'styled-components/macro';

import AlignJustifyIcon from '@/components/icons/AlignJustifyIcon';
import BackspaceIcon from '@/components/icons/BackspaceIcon';
import BulletListIcon from '@/components/icons/BulletListIcon';
import IntroCreditIcon from '@/components/icons/IntroCreditIcon';
import LineDoubleIcon from '@/components/icons/LineDoubleIcon';
import OutlineCreditIcon from '@/components/icons/OutlineCreditIcon';
import ParagraphCreditIcon from '@/components/icons/ParagraphCreditIcon';
import TitleIcon from '@/components/icons/TitleIcon';
import {
  GENERATION_INTRODUCTION_TYPE,
  GENERATION_OUTLINE_TYPE,
  GENERATION_PARAGRAPH_TYPE,
} from '@/containers/Content/EditorComponents/Ai/constants';

const StyledLineDoubleIcon = styled(LineDoubleIcon)`
  && {
    color: ${({ theme }) => theme.cssColors.primary};
    font-size: 17px;
  }
`;
const StyledBulletListIcon = styled(BulletListIcon)`
  && {
    color: ${({ theme }) => theme.cssColors.primary};
    font-size: 17px;
  }
`;
const StyledAlignJustifyIcon = styled(AlignJustifyIcon)`
  && {
    color: ${({ theme }) => theme.cssColors.primary};
    font-size: 17px;
  }
`;

export const StyledTitleIcon = styled(TitleIcon)`
  && {
    color: ${({ theme }) => theme.cssColors.primary};
    font-size: 17px;
  }
`;

export const StyledBackspaceIcon = styled(BackspaceIcon)`
  && {
    color: ${({ theme }) => theme.cssColors.primary};
    font-size: 17px;
  }
`;

export const items = [
  {
    creditIcon: <IntroCreditIcon />,
    icon: <StyledLineDoubleIcon />,
    label: 'introduction',
    type: GENERATION_INTRODUCTION_TYPE,
  },
  {
    creditIcon: <OutlineCreditIcon />,
    icon: <StyledBulletListIcon />,
    label: 'outline',
    type: GENERATION_OUTLINE_TYPE,
  },
  {
    creditIcon: <ParagraphCreditIcon />,
    icon: <StyledAlignJustifyIcon />,
    label: 'paragraph',
    type: GENERATION_PARAGRAPH_TYPE,
  },
];
