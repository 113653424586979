import React from 'react';

import SvgWrapper from './SvgWrapper';

const CursorIcon = (props) => (
  <SvgWrapper {...props} fill="none" height="12" viewBox="0 0 7 12" width="7">
    <path d="M3.41064 11.3429C3.49658 11.5577 3.62549 11.7081 3.84033 11.7941C4.05518 11.88 4.24854 11.88 4.46338 11.7941L5.23682 11.4718C5.45166 11.3859 5.60205 11.2355 5.68799 11.0206C5.77393 10.8058 5.75244 10.6124 5.6665 10.3976L4.76416 8.14172H6.16064C6.37549 8.14172 6.56885 8.07727 6.71924 7.9054C6.86963 7.755 6.95557 7.56165 6.97705 7.3468C6.97705 7.13196 6.9126 6.91711 6.76221 6.74524L1.49854 1.11633C1.32666 0.944458 1.1333 0.858521 0.918457 0.858521C0.703613 0.858521 0.510254 0.944458 0.338379 1.09485C0.166504 1.26672 0.102051 1.46008 0.102051 1.69641V9.75305C0.102051 10.0109 0.166504 10.2042 0.338379 10.3546C0.510254 10.505 0.703613 10.5695 0.918457 10.5695C1.1333 10.5695 1.32666 10.4835 1.49854 10.2902L2.5083 9.17297L3.41064 11.3429ZM2.72314 7.88391L0.98291 9.83899C0.939941 9.88196 0.896973 9.90344 0.854004 9.88196C0.811035 9.86047 0.789551 9.8175 0.789551 9.73157V1.69641C0.789551 1.63196 0.811035 1.58899 0.854004 1.5675C0.896973 1.54602 0.939941 1.54602 0.98291 1.58899L6.24658 7.2179C6.28955 7.26086 6.28955 7.32532 6.26807 7.36829C6.24658 7.43274 6.20361 7.45422 6.16064 7.45422H3.73291L5.04346 10.6554C5.04346 10.6984 5.04346 10.7198 5.04346 10.7628C5.02197 10.8058 5.00049 10.8488 4.979 10.8488L4.20557 11.171C4.11963 11.214 4.07666 11.171 4.05518 11.0851L2.72314 7.88391Z" />
  </SvgWrapper>
);

export default CursorIcon;
