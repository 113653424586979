import i18next from 'i18next';
import { DateTime } from 'luxon';
import React from 'react';
import { InfoIcon } from 'semji-core/icons/InfoIcon';

import {
  METRICS_CONFIG_PERIOD_LAST_3_MONTHS,
  METRICS_CONFIG_PERIOD_LAST_6_MONTHS,
  METRICS_CONFIG_PERIOD_LAST_12_MONTHS,
  METRICS_CONFIG_PERIOD_LAST_MONTH,
} from '@/utils/metrics/constants';

export const FALSE_VALUE = 'false';
export const TRUE_VALUE = 'true';

export const getCompetitorDuelList = () => {
  const duelList = [
    {
      canToggle: false,
      key: 'organic-traffic',
      title: i18next.t('competitors:duel.organic-traffic.label'),
      tooltip: {
        children: <InfoIcon opacity={0.6} />,
        description: i18next.t('competitors:source-normalized-traffic'),
        isFrame: false,
        popoverOptions: {},
        title: (
          <span className="competitors-duel__tooltip">
            {i18next.t('competitors:duel.organic-traffic.tooltip')}
          </span>
        ),
      },
    },
    {
      canToggle: false,
      key: 'organic-page',
      title: i18next.t('competitors:duel.organic-page.label'),
      tooltip: {
        children: <InfoIcon opacity={0.6} />,
        description: i18next.t('competitors:source-normalized-traffic'),
        isFrame: false,
        popoverOptions: {},
        title: (
          <span className="competitors-duel__tooltip">
            {i18next.t('competitors:duel.organic-page.tooltip')}
          </span>
        ),
      },
    },
    {
      canToggle: true,
      key: 'unbranded-traffic',
      title: i18next.t('competitors:duel.unbranded-traffic.label'),
      tooltip: {
        children: <InfoIcon opacity={0.6} />,
        description: i18next.t('competitors:source-normalized-traffic'),
        isFrame: false,
        popoverOptions: {},
        title: (
          <span className="competitors-duel__tooltip">
            {i18next.t('competitors:duel.unbranded-traffic.tooltip')}
          </span>
        ),
      },
    },
    {
      canToggle: false,
      key: 'average-traffic',
      title: i18next.t('competitors:duel.average-traffic.label'),
      tooltip: {
        children: <InfoIcon opacity={0.6} />,
        description: i18next.t('competitors:source-normalized-traffic'),
        isFrame: false,
        popoverOptions: {},
        title: (
          <span className="competitors-duel__tooltip">
            {i18next.t('competitors:duel.average-traffic.tooltip')}
          </span>
        ),
      },
    },
  ];

  return duelList;
};

const today = DateTime.local();

export const AVAILABLE_PERIODS = {
  [METRICS_CONFIG_PERIOD_LAST_12_MONTHS]: {
    duration: { months: 12 },
    from: today.minus({ months: 12 }).startOf('month').toSQLDate(),
    name: 'report:chart.periodicity.last-12-months',
    size: { days: 365 },
    to: today.minus({ months: 1 }).endOf('month').toSQLDate(),
  },
  [METRICS_CONFIG_PERIOD_LAST_3_MONTHS]: {
    duration: { months: 3 },
    from: today.minus({ months: 3 }).startOf('month').toSQLDate(),
    name: 'report:chart.periodicity.last-3-months',
    size: { days: 90 },
    to: today.minus({ months: 1 }).endOf('month').toSQLDate(),
  },
  [METRICS_CONFIG_PERIOD_LAST_6_MONTHS]: {
    duration: { months: 6 },
    from: today.minus({ months: 6 }).startOf('month').toSQLDate(),
    name: 'report:chart.periodicity.last-6-months',
    size: { days: 180 },
    to: today.minus({ months: 1 }).endOf('month').toSQLDate(),
  },
  [METRICS_CONFIG_PERIOD_LAST_MONTH]: {
    duration: { months: 1 },
    from: today.minus({ months: 1 }).startOf('month').toSQLDate(),
    name: 'report:chart.periodicity.last-month',
    size: { days: 30 },
    to: today.minus({ months: 1 }).endOf('month').toSQLDate(),
  },
};

export type ReportPeriodType =
  | typeof METRICS_CONFIG_PERIOD_LAST_12_MONTHS
  | typeof METRICS_CONFIG_PERIOD_LAST_3_MONTHS
  | typeof METRICS_CONFIG_PERIOD_LAST_6_MONTHS
  | typeof METRICS_CONFIG_PERIOD_LAST_MONTH;

export const CHART_DATA_TYPE_PERCENTAGE = 'percentage';
export const CHART_DATA_TYPE_VALUE = 'value';

export const ENUM_CHART_DATA_TYPE = {
  PERCENTAGE: CHART_DATA_TYPE_PERCENTAGE,
  VALUE: CHART_DATA_TYPE_VALUE,
};

export const ENUM_SERIES_CURSOR_TYPE = {
  POINTER: 'pointer',
  UNDEFINED: undefined,
};

export type ChartDataType = typeof CHART_DATA_TYPE_PERCENTAGE | typeof CHART_DATA_TYPE_VALUE;

export const DEFAULT_LOADER_CARD_CONTENT_MIN_HEIGHT = '512px';
export const SMALL_LOADER_CARD_CONTENT_MIN_HEIGHT = '454px';
export const DEFAULT_LOADER_CARD_HEIGHT = '480px';
export const SMALL_LOADER_CARD_HEIGHT = '432px';
export const DEFAULT_CARD_CONTENT_MIN_HEIGHT = '476px';
export const SMALL_CARD_CONTENT_MIN_HEIGHT = '440px';
export const HORIZONTAL_MULTIPLIER_PIXEL = 40;
