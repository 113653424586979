import { DateTime } from 'luxon';

import { getPeriodRangeById } from './getPeriodById';

// today date is passed throw the different methods for test purpose
export const getCurrentDateRange = ({ period, today }) => {
  const { from, to } = getPeriodRangeById(period, today);

  let fromDate = DateTime.fromSQL(from).startOf('day');
  let toDate = DateTime.fromSQL(to).endOf('day');

  return {
    from: fromDate,
    to: toDate,
  };
};
