import MuiFormLabel from '@material-ui/core/FormLabel';
import styled from 'styled-components/macro';

const FormLabel = styled(MuiFormLabel)`
  flex: 1;
  display: flex;
  margin-left: 10px;

  & > :first-child {
    flex: 1;
  }
`;

export default FormLabel;
