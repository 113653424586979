import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import apiClient from '@/apis/semji/apiClient';
import { LOCATION, SCOPE_COMMENTS } from '@/apis/semji/constants';

export default function useGetThreads({ enabled, ...props }) {
  const { contentId } = useParams();
  const { get } = apiClient;

  return useQuery({
    enabled,
    placeholderData: [],
    queryFn: async ({ signal }) => {
      if (!contentId) {
        return [];
      }

      const [threads, comments] = await Promise.all([
        get(`/contents/${contentId}/threads`, {
          signal,
        }),
        get(`/contents/${contentId}/threads/comments`, {
          signal,
        }),
      ]);

      return threads.data['hydra:member'].map((thread) => ({
        comments: comments.data['hydra:member'].filter((comment) => comment.threadId === thread.id),
        thread,
      }));
    },
    queryKey: [SCOPE_COMMENTS.GET_THREAD_BY_CONTENT_ID, contentId, LOCATION.EDITOR],
    ...props,
  });
}
