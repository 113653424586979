import './VersionEditor.scss';

import React, { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getClassNames } from 'semji-core/utils/getClassNames';

import useGetContentById from '@/apis/semji/contents/useGetContentById';
import useGetContentVersionById from '@/apis/semji/contents/useGetVersionById/useGetVersionById';
import useGetPageById from '@/apis/semji/pages/useGetPageById';
import ContentContainerProvider from '@/containers/Content/ContentContainerContext';
import { SidePanelContainer } from '@/containers/Content/SidePanel/SidePanelContainer';
import { ENUM_SIDE_PANEL_STATUS } from '@/containers/Content/SidePanel/SidePanelContainer/SidePanelContainer.types';
import Editor from '@/containers/Content/TinyMceComponents/Editor';
import EditorSkeleton from '@/containers/Content/TinyMceComponents/Editor/EditorSkeleton';
import { EditorPageContainerWrapper, FlexWrapper } from '@/containers/Content/Wrappers';
import { useContentVersions } from '@/containers/ContentVersion/hooks/useContentVersions';
import useBreakpoints from '@/hooks/useBreakpoints';
import { FETCH_PAGE_SUCCESS, SET_FOCUS_TOP_KEYWORD } from '@/store/actions/actionTypes';
import { resetContent, setContent } from '@/store/actions/content';
import { resetEditor } from '@/store/actions/editor';
import { resetCurrentReportScore } from '@/store/actions/report';
import { showErrorSnackbar } from '@/store/actions/ui';

export default function EditorContent() {
  const { contentId, organizationId, workspaceId, pageId } = useParams();
  const navigate = useNavigate();
  const { isLoading, contentVersions } = useContentVersions();
  const contentVersionSelected = contentVersions.find(
    (contentVersion) => contentVersion.id === contentId
  );
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { isMaxLaptop } = useBreakpoints();

  const [sidePanelStatus, setSidePanelStatus] = useState(ENUM_SIDE_PANEL_STATUS.Normal);

  const { isDraftLoading, url } = useSelector((state: any) => state.editor);

  const { isLoading: isPageLoading } = useGetPageById({
    onError: () => {
      dispatch(showErrorSnackbar(t('common:error.default')));
      navigate(`/o/${organizationId}/w/${workspaceId}/p/${pageId}/versions`);
    },
    onSuccess: (data) => {
      const {
        contentRetrievedAt,
        draft,
        extra,
        extractedContentHtml,
        focusTopKeyword,
        imageUrl,
        lastStatusCode,
        redirectionUrl,
        source,
        staticHtml,
        staticHtmlRetrievedAt,
        title,
        url,
        urlPath,
        isTracked,
        metaDescription,
      } = data;
      const payload = {
        contentRetrievedAt,
        createdAt: draft?.createdAt,
        draftId: draft?.id,
        draftIsStarted: draft?.isStarted,
        extra,
        extractedContentHtml,
        imageUrl,
        isTracked,
        lastStatusCode,
        metaDescription,
        redirectionUrl,
        source,
        staticHtml,
        staticHtmlRetrievedAt,
        title,
        url,
        urlPath,
      };

      dispatch({
        payload,
        type: FETCH_PAGE_SUCCESS,
      });
      dispatch({
        focusTopKeyword: { ...focusTopKeyword },
        type: SET_FOCUS_TOP_KEYWORD,
      });
    },
    pageId,
  });

  function handleRetrieveDataError(error: any) {
    navigate(`/o/${organizationId}/w/${workspaceId}/p/${pageId}/versions`);
    dispatch(showErrorSnackbar(t('common:error.default')));
  }

  const {
    data: content,
    isLoading: isContentLoading,
    remove: removeContent,
  } = useGetContentById({
    contentId,
    enabled: !!contentId && !contentVersionSelected,
    onError: handleRetrieveDataError,
  });

  const {
    data: contentVersion,
    isLoading: isContentVersionLoading,
    remove: removeContentVersion,
  } = useGetContentVersionById({
    contentId: String(contentId),
    enabled: !!contentVersionSelected,
    onError: handleRetrieveDataError,
    versionId: String(contentId),
  });

  useEffect(() => {
    // First mount, reset redux content state to ensure no remaining ids from unclean data and fetch page.
    dispatch(resetEditor());
    dispatch(resetCurrentReportScore());

    return () => {
      // Cleanup redux state on unmount.
      dispatch(resetEditor());
      dispatch(resetContent());
      dispatch(resetCurrentReportScore());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let contentData = {
      html: '',
      metaDescription: '',
      title: '',
    };
    if (
      !isContentLoading &&
      content?.html &&
      content?.title !== null &&
      content?.metaDescription !== null
    ) {
      contentData = {
        html: content?.html,
        metaDescription: content?.metaDescription,
        title: content?.title,
      };
    }
    if (!isContentVersionLoading && contentVersion?.content) {
      contentData = {
        html: contentVersion?.content,
        metaDescription: contentVersion?.metaDescription ?? '',
        title: contentVersion?.title ?? '',
      };
    }
    if (!!contentData.html) {
      dispatch(setContent(contentData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isContentLoading, isContentVersionLoading]);

  useEffect(() => {
    return () => {
      removeContent();
      removeContentVersion();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentId]);

  useEffect(() => {
    // Close the side panel when the screen is small
    if (isMaxLaptop && sidePanelStatus !== ENUM_SIDE_PANEL_STATUS.Close) {
      setSidePanelStatus(ENUM_SIDE_PANEL_STATUS.Close);
    }
  }, [isMaxLaptop]);

  return (
    <ContentContainerProvider>
      <div className="version-history-content-container">
        <EditorPageContainerWrapper id="editor-page-container">
          <FlexWrapper>
            {isPageLoading && !contentId ? null : (
              // For the fallback, you can use EditorSkeleton for now, ContentLoader if others components are lazy loaded in the future.
              <Suspense fallback={<EditorSkeleton hideTopBar />}>
                <div
                  className={getClassNames(
                    'version-history-content-container__editor',
                    sidePanelStatus !== ENUM_SIDE_PANEL_STATUS.Close &&
                      'version-history-content-container__editor--open'
                  )}
                >
                  <Editor />
                </div>
                <SidePanelContainer
                  isReadOnly={true}
                  loading={isPageLoading || isDraftLoading || isContentLoading || isLoading}
                  setSidePanelStatus={setSidePanelStatus}
                  showMarkAsPublishedDialog={false}
                  sidePanelStatus={sidePanelStatus}
                  tab="content"
                  url={url}
                />
              </Suspense>
            )}
          </FlexWrapper>
        </EditorPageContainerWrapper>
      </div>
    </ContentContainerProvider>
  );
}
