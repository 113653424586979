import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { TabBodyTitle } from '@/components/Navigation/Tabs';
import { TitleFour } from '@/components/Text/Title';
import useCan from '@/hooks/useCan';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import { EDITOR_COMMENTS_ENABLED } from '@/utils/configurations/constants';
import { FEATURE_SET_COMMENT_IS_ENABLED } from '@/utils/organizationFeatureSet/constants';

import { CommentEmailNotifications, ContentEmailNotifications } from './EmailNotifications';

const Table = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 2.5rem 3rem;
  max-width: 500px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
  margin-bottom: 0.5rem;
`;

const LightText = styled.div`
  font-size: ${({ theme }) => theme.textCss.sizes.sm};
  color: ${({ theme }) => theme.cssColors.dark060};
`;

const TextBlock = styled.div`
  flex: 1;
`;

export function Notification({ title }) {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  const isEditorCommentEnabled = useCan({ perform: EDITOR_COMMENTS_ENABLED });
  const { isFeatureEnabled: isOrganizationCommentsEnabled } = useOrganizationFeatureSet(
    FEATURE_SET_COMMENT_IS_ENABLED
  );

  return (
    <Table>
      <TabBodyTitle noMargin>{title}</TabBodyTitle>
      <TitleFour noMargin weight="strong">
        {t('settings:email-notifications.title')}
      </TitleFour>

      <Row>
        <TextBlock>
          <TitleFour noMargin weight="strong">
            {t('settings:email-notifications.draft-title')}
          </TitleFour>
          <LightText> {t('settings:email-notifications.draft-label')}</LightText>
        </TextBlock>
        <ContentEmailNotifications user={user} />
      </Row>

      {isEditorCommentEnabled && isOrganizationCommentsEnabled && (
        <Row>
          <TextBlock aria-disabled={true}>
            <TitleFour noMargin weight="strong">
              {t('settings:email-notifications.comment-title')}
            </TitleFour>
            <LightText> {t('settings:email-notifications.comment-label')}</LightText>
          </TextBlock>
          <CommentEmailNotifications user={user} />
        </Row>
      )}
    </Table>
  );
}
