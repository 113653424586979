import React from 'react';
import { NotificationDotIcon } from 'semji-core/icons/NotificationDotIcon';

import LockRoundIcon from '@/components/icons/LockRoundIcon';
import SidePanelCommentaryIcon from '@/components/icons/SidePanelCommentaryIcon';
import SidePanelFactCheckingIcon from '@/components/icons/SidePanelFactCheckingIcon';
import SidePanelOutlineIcon from '@/components/icons/SidePanelOutlineIcon';
import SidePanelReduceIcon from '@/components/icons/SidePanelReduceIcon';
import SidePanelResizeIcon from '@/components/icons/SidePanelResizeIcon';
import SidePanelSEOIcon from '@/components/icons/SidePanelSEOIcon';
import SidePanelTourIcon from '@/components/icons/SidePanelTourIcon';

import { ISidePanelTab } from '../SidePanelTab';

export enum ENUM_TAB_LABEL {
  Seo = 'seo',
  Ai = 'ai',
  Outline = 'outline',
  FactChecking = 'fact-checking',
  Comments = 'comments',
  Tour = 'tour',
  Expand = 'expand',
  Reduce = 'reduce',
}

export enum ENUM_SIDE_PANEL_VARIANTS {
  CONTENT_EDITOR = 'content-editor',
  VERSION_EDITOR = 'version-editor',
}
export const EDITOR_ONLY_TABS = [
  ENUM_TAB_LABEL.Ai,
  ENUM_TAB_LABEL.FactChecking,
  ENUM_TAB_LABEL.Comments,
];

export const getSidePanelTopTabs = ({
  aiWritingFactCheckingEnabled,
  matchesEditor,
}: {
  aiWritingFactCheckingEnabled: boolean;
  matchesEditor: boolean;
}): ISidePanelTab[] => {
  const sidePanelTabs: ISidePanelTab[] = [
    {
      dataIntercomTarget: 'seo-sidepanel-tab',
      icon: <SidePanelSEOIcon />,
      label: ENUM_TAB_LABEL.Seo,
    },
    // { icon: <SidePanelIAIcon />, label: ENUM_TAB_LABEL.Ai },
    {
      dataIntercomTarget: 'outline-sidepanel-tab',
      icon: <SidePanelOutlineIcon />,
      label: ENUM_TAB_LABEL.Outline,
    },
  ];

  if (aiWritingFactCheckingEnabled && matchesEditor) {
    sidePanelTabs.push({
      dataIntercomTarget: 'fact-check-sidepanel-tab',
      icon: <SidePanelFactCheckingIcon />,
      label: ENUM_TAB_LABEL.FactChecking,
    });
  }

  return sidePanelTabs;
};

export const getSidePanelBottomTabs = (
  hasUnreadComments: boolean,
  matchesEditor: boolean,
  expanded: boolean,
  productTourEnabled: boolean,
  editorCommentEnabled: boolean,
  isOrganizationCommentsEnabled: boolean,
  expandDisabled?: boolean
): ISidePanelTab[] => {
  const sidePanbelTabs: ISidePanelTab[] = [];

  if (editorCommentEnabled && matchesEditor) {
    sidePanbelTabs.push({
      dataIntercomTarget: 'comments-sidepanel-tab',
      icon: <SidePanelCommentaryIcon />,
      label: ENUM_TAB_LABEL.Comments,
      sticker: !isOrganizationCommentsEnabled ? (
        <div className="side-panel-tab__icon__sticker side-panel-tab__icon__sticker--locked">
          <LockRoundIcon />
        </div>
      ) : (
        hasUnreadComments && (
          <div className="side-panel-tab__icon__sticker side-panel-tab__icon__sticker--notification">
            <NotificationDotIcon className="" />
          </div>
        )
      ),
    });
  }

  if (productTourEnabled) {
    sidePanbelTabs.push({ icon: <SidePanelTourIcon />, label: ENUM_TAB_LABEL.Tour });
  }

  if (expanded) {
    sidePanbelTabs.push({
      disabled: expandDisabled,
      icon: <SidePanelReduceIcon />,
      label: ENUM_TAB_LABEL.Reduce,
    });
  } else {
    sidePanbelTabs.push({
      disabled: expandDisabled,
      icon: <SidePanelResizeIcon />,
      label: ENUM_TAB_LABEL.Expand,
    });
  }

  return sidePanbelTabs;
};
