import { Checkbox } from 'semji-core/components/Input/Checkbox';

import { FlexCenteredContainer } from '@/components/_common';

export const SELECT_ALL = 'selectAll';
export const SELECT_ONCE = 'selectOne';

function CheckboxRender({ type, selectedIds, list, handleSelect, id, disabled }) {
  if (type === SELECT_ONCE) {
    return (
      <Checkbox checked={selectedIds.includes(id)} name={type} value={id} onChange={handleSelect} />
    );
  }
  if (type === SELECT_ALL) {
    return (
      <FlexCenteredContainer>
        <Checkbox
          checked={
            !!list.length &&
            !!selectedIds.length &&
            list.every((item) => selectedIds.includes(item.id))
          }
          name={SELECT_ALL}
          onChange={handleSelect}
        />
      </FlexCenteredContainer>
    );
  }
  return null;
}

export default CheckboxRender;
