import styled from 'styled-components/macro';

const Text = styled.p`
  line-height: ${({ lineHeight, theme }) =>
    lineHeight ? theme.textCss.sizes[lineHeight] : theme.textCss.sizes.lg};
  font-weight: ${({ fontWeight, theme }) =>
    fontWeight ? theme.textCss.weights[fontWeight] : theme.textCss.weights.strong};
  color: ${({ color, theme }) => (color ? theme.cssColors[color] : theme.cssColors.dark060)};
  font-size: ${({ fontSize, theme }) =>
    fontSize ? theme.textCss.sizes[fontSize] : theme.textCss.sizes.sm};
  margin: ${({ margin }) => margin};
  overflow: ${({ overflow }) => overflow || 'hidden'};
  text-overflow: ${({ textOverflow }) => textOverflow || 'ellipsis'};
  white-space: ${({ whiteSpace }) => whiteSpace || 'nowrap'};
`;

export default Text;
