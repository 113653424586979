export const CHART_WRAPPER_VARIANT_DEFAULT = 'default';
export const CHART_WRAPPER_VARIANT_SMALL = 'small';
export const CHART_WRAPPER_VARIANT_LARGE = 'large';

export type ChartWrapperVariant =
  | typeof CHART_WRAPPER_VARIANT_DEFAULT
  | typeof CHART_WRAPPER_VARIANT_SMALL
  | typeof CHART_WRAPPER_VARIANT_LARGE;

export interface ChartWrapperProps {
  isLoading?: boolean;
  children: React.ReactNode;
  variant?: ChartWrapperVariant;
}
