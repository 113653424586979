import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { LOCATION, SCOPE_SERVICE_INTEGRATIONS } from '@/apis/semji/constants';
import useDeleteServiceIntegration from '@/apis/semji/serviceIntegrations/useDeleteServiceIntegration';
import Stepper from '@/components/Stepper/Stepper';
import ConnectStep from '@/containers/ServiceIntegration/SearchConsole/Steps/Connect';
import NoMatchingProperties from '@/containers/ServiceIntegration/SearchConsole/Steps/NoMatchingProperties';
import NoProperties from '@/containers/ServiceIntegration/SearchConsole/Steps/NoProperties';
import Properties from '@/containers/ServiceIntegration/SearchConsole/Steps/Properties';
import ServiceIntegrationService from '@/services/ServiceIntegration';
import { getWorkspaceById } from '@/store/reducers';
import { SECTIONS } from '@/utils/log/constants';
import { Log } from '@/utils/log/Log';

function SearchConsoleServiceIntegration() {
  const { workspaceId, organizationId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [step, setStep] = useState(0);
  const [validating, setValidating] = useState(false);
  const [validProperties, setValidProperties] = useState([]);
  const [invalidProperties, setInvalidProperties] = useState([]);
  const [property, setProperty] = useState(null);
  const [googleCredentials, setGoogleCredentials] = useState(null);
  const [error, setError] = useState(null);

  const queryClient = useQueryClient();

  const serviceIntegration = useSelector((state) =>
    (state.serviceIntegrations.services || []).find((service) => service.type === 'SEARCH_CONSOLE')
  );
  const workspaceWebsiteUrl = useSelector(
    (state) => getWorkspaceById(state, workspaceId)?.websiteUrl
  );

  const deleteServiceIntegration = useDeleteServiceIntegration();

  function getStep(index) {
    const steps = [
      {
        step: t('service-integration:search-console.stepper.first-step'),
        subTitle: t('service-integration:search-console.stepper.first-step-subtitle'),
        title: t('service-integration:search-console.stepper.first-step-title'),
      },
      {
        step: t('service-integration:search-console.stepper.second-step'),
        subTitle: t('service-integration:search-console.stepper.second-step-subtitle'),
        title: t('service-integration:search-console.stepper.second-step-title'),
      },
    ];

    return steps[index] || {};
  }

  function handleCancel() {
    navigate(`/o/${organizationId}/w/${workspaceId}/settings/integrations`);
  }

  function handlePrevious() {
    setStep(step - 1);
  }

  function handleChange(siteUrl) {
    const selectedProperty = [...validProperties, ...invalidProperties].find(
      (propertyData) => siteUrl === propertyData.siteUrl
    );

    setProperty(selectedProperty);
  }

  function consent(consentData) {
    if (consentData) {
      const { searchConsoleProperties = {}, googleCredentialsValue = [] } = consentData;
      const { invalid, valid } = searchConsoleProperties;

      setValidProperties(valid);
      setInvalidProperties(invalid);

      // Set best choice based on url validation and number of pages
      if (!!valid?.[0]?.siteUrl) {
        setProperty(valid?.[0]);
      }

      setGoogleCredentials(googleCredentialsValue);
      setStep(step + 1);
    }
  }

  async function handleValidate() {
    if (!property?.siteUrl) {
      return;
    }

    setValidating(true);
    setError(null);

    try {
      const _ServiceIntegration = new ServiceIntegrationService();
      if (serviceIntegration?.disabledAt) {
        await deleteServiceIntegration.mutate(serviceIntegration.id);
      }

      await _ServiceIntegration.createSearchConsoleIntegration({
        googleCredentials,
        property,
        workspaceId: workspaceId,
      });
      await queryClient.refetchQueries({
        active: true,
        exact: true,
        fetching: false,
        queryKey: {
          queryKey: [
            SCOPE_SERVICE_INTEGRATIONS.GET_SERVICE_INTEGRATIONS,
            workspaceId,
            LOCATION.ROOT,
          ],
        },
      });

      setValidating(false);
      navigate(`/o/${organizationId}/w/${workspaceId}/settings/integrations`);
    } catch (e) {
      setValidating(false);
      setError(t('service-integration:search-console.stepper.error'));
      Log.report({
        context: 'handleValidate',
        error: e,
        extra: 'search console',
        section: SECTIONS.serviceIntegrations.key,
      });
    }
  }

  function isNextDisabled() {
    if (step === 1) {
      return !property?.siteUrl || validating || error;
    }

    return true;
  }

  function renderStep() {
    if (step === 0) {
      return (
        <ConnectStep
          consent={consent}
          setError={setError}
          workspaceWebsiteUrl={workspaceWebsiteUrl}
        />
      );
    }
    if (step === 1) {
      // No properties present in this account
      if (!validProperties.length && !invalidProperties.length) {
        return <NoProperties />;
      }

      // No matching properties
      if (!validProperties.length) {
        return (
          <NoMatchingProperties
            handleChange={handleChange}
            invalidProperties={invalidProperties}
            property={property}
            workspaceWebsiteUrl={workspaceWebsiteUrl}
          />
        );
      }

      return (
        <Properties
          handleChange={handleChange}
          invalidProperties={invalidProperties}
          property={property}
          validProperties={validProperties}
          workspaceWebsiteUrl={workspaceWebsiteUrl}
        />
      );
    }
    return null;
  }

  return (
    <Stepper
      handleCancel={handleCancel}
      handleNext={step === 1 ? handleValidate : undefined}
      handlePrevious={handlePrevious}
      header={getStep(step)}
      info={error}
      infoOnError={!!error}
      isNextDisabled={isNextDisabled()}
      nextLabel={t('service-integration:button-label.next')}
      previousDisabled={step === 0}
    >
      {renderStep()}
    </Stepper>
  );
}

export default SearchConsoleServiceIntegration;
