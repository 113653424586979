import styled from 'styled-components/macro';

export const TitleOne = styled.h1`
  font-family: ${(props) => props.theme.textCss.fonts[props.font || 'main']};
  margin: ${(props) => (props.noMargin ? '0px' : '0 5px')};
  color: ${(props) => props.theme.cssColors[props.color || 'dark100']};
  font-size: ${(props) => props.theme.textCss.sizes.xl};
  font-weight: ${(props) => props.theme.textCss.weights[props.weight || 'normal']};
  line-height: 2rem;
  &&::first-letter {
    text-transform: ${(props) => (props.noCapitalization ? 'none' : 'capitalize')};
  }
  ${(props) => props.theme.mediaQueries.largeDesktop} {
    font-size: ${(props) => props.theme.textCss.sizes.xxl};
  }
  text-align: ${(props) => props.align};
`;
export const TitleTwo = styled.h2`
  font-family: ${(props) => props.theme.textCss.fonts[props.font || 'main']};
  margin: ${(props) => (props.noMargin ? '0px' : '0 5px')};
  color: ${(props) => props.theme.cssColors[props.color || 'dark100']};
  font-size: ${(props) => props.theme.textCss.sizes.lg};
  font-weight: ${(props) => props.theme.textCss.weights[props.weight || 'normal']};
  line-height: 2rem;
  &&::first-letter {
    text-transform: ${(props) => (props.noCapitalization ? 'none' : 'capitalize')};
  }
  ${(props) => props.theme.mediaQueries.largeDesktop} {
    font-size: ${(props) => props.theme.textCss.sizes.xl};
  }
  text-align: ${(props) => props.align};
`;
export const TitleThree = styled.h3`
  font-family: ${(props) => props.theme.textCss.fonts[props.font || 'main']};
  margin: ${(props) => (props.noMargin ? '0px' : '0 5px')};
  color: ${(props) => props.theme.cssColors[props.color || 'dark100']};
  font-size: ${(props) => props.theme.textCss.sizes.md};
  font-weight: ${(props) => props.theme.textCss.weights[props.weight || 'normal']};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '2rem')};
  &&::first-letter {
    text-transform: ${(props) => (props.noCapitalization ? 'none' : 'capitalize')};
  }
  ${(props) => props.theme.mediaQueries.largeDesktop} {
    font-size: ${(props) => props.theme.textCss.sizes.lg};
  }
  text-align: ${(props) => props.align};
`;
export const TitleFour = styled.h4`
  font-family: ${(props) => props.theme.textCss.fonts[props.font || 'main']};
  margin: ${(props) => (props.noMargin ? '0px' : '0 5px')};
  color: ${(props) => props.theme.cssColors[props.color || 'dark100']};
  font-size: ${(props) => props.theme.textCss.sizes.default};
  font-weight: ${(props) => props.theme.textCss.weights[props.weight || 'normal']};
  line-height: 2rem;
  &&::first-letter {
    text-transform: ${(props) => (props.noCapitalization ? 'none' : 'capitalize')};
  }
  ${(props) => props.theme.mediaQueries.largeDesktop} {
    font-size: ${(props) => props.theme.textCss.sizes.md};
  }
  text-align: ${(props) => props.align};
`;
export const TitleFive = styled.h5`
  font-family: ${(props) => props.theme.textCss.fonts[props.font || 'main']};
  margin: ${(props) => (props.noMargin ? '0px' : '0 5px')};
  color: ${(props) => props.theme.cssColors[props.color || 'dark100']};
  font-size: ${(props) => props.theme.textCss.sizes.sm};
  font-weight: ${(props) => props.theme.textCss.weights[props.weight || 'normal']};
  line-height: 2rem;
  &&::first-letter {
    text-transform: ${(props) => (props.noCapitalization ? 'none' : 'capitalize')};
  }
  ${(props) => props.theme.mediaQueries.largeDesktop} {
    font-size: ${(props) => props.theme.textCss.sizes.default};
  }
  text-align: ${(props) => props.align};
`;
export const TitleSix = styled.h6`
  font-family: ${(props) => props.theme.textCss.fonts[props.font || 'main']};
  margin: ${(props) => (props.noMargin ? '0px' : '0 5px')};
  color: ${(props) => props.theme.cssColors[props.color || 'dark100']};
  font-size: ${(props) => props.theme.textCss.sizes.xs};
  font-weight: ${(props) => props.theme.textCss.weights[props.weight || 'normal']};
  line-height: 2rem;
  &&::first-letter {
    text-transform: ${(props) => (props.noCapitalization ? 'none' : 'capitalize')};
  }
  ${(props) => props.theme.mediaQueries.largeDesktop} {
    font-size: ${(props) => props.theme.textCss.sizes.sm};
  }
  text-align: ${(props) => props.align};
`;

export const SecondaryTitleOne = styled.h1`
  font-family: ${(props) => props.theme.textCss.fonts[props.font || 'secondary']};
  margin: ${(props) => (props.noMargin ? '0px' : '0 5px')};
  color: ${(props) => props.theme.cssColors[props.color || 'dark100']};
  font-size: ${(props) => props.theme.textCss.sizes.xxxl};
  font-weight: ${(props) => props.theme.textCss.weights[props.weight || 'normal']};
  line-height: 3rem;
  &&::first-letter {
    text-transform: ${(props) => (props.noCapitalization ? 'none' : 'capitalize')};
  }
  ${(props) => props.theme.mediaQueries.largeDesktop} {
    font-size: ${(props) => props.theme.textCss.sizes.xxxxl};
  }
  text-align: ${(props) => props.align};
`;

export const SecondaryTitleTwo = styled.h2`
  font-family: ${(props) => props.theme.textCss.fonts[props.font || 'secondary']};
  margin: ${(props) => (props.noMargin ? '0px' : '0 5px')};
  color: ${(props) => props.theme.cssColors[props.color || 'dark100']};
  font-size: ${(props) => props.theme.textCss.sizes.xl};
  font-weight: ${(props) => props.theme.textCss.weights[props.weight || 'normal']};
  line-height: 2rem;
  &&::first-letter {
    text-transform: ${(props) => (props.noCapitalization ? 'none' : 'capitalize')};
  }
  ${(props) => props.theme.mediaQueries.largeDesktop} {
    font-size: ${(props) => props.theme.textCss.sizes.xxl};
  }
  text-align: ${(props) => props.align};
`;

export const PrimaryTitle = styled.h1`
  font-family: ${(props) => props.theme.text.titleFont};
  margin: ${(props) => (props.noMargin ? '0px' : '0 5px')};
  color: ${(props) =>
    props.white ? props.theme.cssColors.white : props.theme.textCss.colors.default};
  font-size: ${(props) =>
    props.size === 'normal'
      ? props.theme.text.sizes.default
      : props.size === 'big'
        ? '3em'
        : '1.25em'};
  font-weight: 400;
  &&::first-letter {
    text-transform: ${(props) => (props.noCapitalization ? 'none' : 'capitalize')};
  }
`;

export const SecondaryTitle = styled.h2`
  font-family: ${(props) => props.theme.text.titleFont};
  margin: ${(props) => (props.noMargin ? '0px' : '0 5px')};
  font-size: ${(props) => (props.size === 'normal' ? props.theme.text.sizes.default : '1.2em')};
  font-weight: 300;
  color: ${(props) =>
    (props.color = 'default' ? props.theme.text.colors.default : props.theme.text.colors.darker)};
  &&::first-letter {
    text-transform: ${(props) => (props.noCapitalization ? 'none' : 'capitalize')};
  }
`;
export const TertiaryTitle = styled.h3`
  font-family: ${(props) => props.theme.text.titleFont};
  font-size: ${(props) =>
    props.size === 'small' ? props.theme.text.sizes.small : props.theme.text.sizes.default};
  color: ${(props) => props.theme.text.colors.default};
  font-weight: 300;
  &&::first-letter {
    text-transform: ${(props) => (props.noCapitalization ? 'none' : 'capitalize')};
  }
`;
