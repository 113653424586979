import {
  NO_CONTENTS,
  NO_REPORTS_AREA,
  NO_REPORTS_BAR,
  NO_REPORTS_LINE,
  NO_REPORTS_STACKED_BAR,
} from '@/components/EmptyState/NoContentsVerticalBars';
import {
  ReportMetric,
  ReportMetricCategory,
  ReportMetricChartType,
  ReportMetricKey,
  ReportView,
} from '@/containers/Report/utils/types';
import { ReportPageMetric } from '@/types/reports';

export const getMetricsFromCategory = (
  metrics: ReportMetric[],
  category: ReportMetricCategory,
  skipIndex = -1
) =>
  metrics.flatMap((metric, index) =>
    metric.category === category && index > skipIndex ? [metric] : []
  );

export function getNoDraftVersion(chartType: ReportMetricChartType, reportView: ReportView) {
  switch (chartType) {
    case ReportMetricChartType.Bar:
      return NO_REPORTS_BAR[reportView];
    case ReportMetricChartType.Area:
      return NO_REPORTS_AREA[reportView];
    case ReportMetricChartType.Line:
      return NO_REPORTS_LINE[reportView];
    case ReportMetricChartType.Column:
      return NO_REPORTS_STACKED_BAR[reportView];
    default:
      return NO_CONTENTS;
  }
}

export function combineNewAndUpdatedContentsMetric(
  newContents: ReportPageMetric[] = [],
  updatedContents: ReportPageMetric[] = [],
  key: ReportMetricKey
) {
  const size = Math.max(newContents.length, updatedContents.length);
  const combinedMetrics = [];

  for (let i = 0; i < size; i++) {
    combinedMetrics.push({
      date: newContents[i]?.date || updatedContents[i]?.date,
      [`${key}New`]: newContents[i]?.[key],
      [`${key}Updated`]: updatedContents[i]?.[key],
    });
  }

  return combinedMetrics;
}
