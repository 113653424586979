import styled from 'styled-components/macro';

import DotsLoaderIcon from '../icons/DotsLoaderIcon';

const LoaderWrapper = styled.div`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    text-align: center;
    padding: 0 20px;

    svg {
      color: ${({ theme }) => theme.cssColors.lightPink};
      font-size: ${({ theme }) => theme.textCss.sizes.xxxxl};
      margin-bottom: 10px;
    }

    span {
      color: ${({ theme }) => theme.cssColors.dark060};
      font-size: ${({ theme }) => theme.textCss.sizes.default};
      line-height: 135%;
      font-weight: normal;
    }
  }
`;

function RetrieveLoader({ children }) {
  return (
    <LoaderWrapper>
      <DotsLoaderIcon />
      <span>{children}</span>
    </LoaderWrapper>
  );
}

export default RetrieveLoader;
