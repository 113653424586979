import './CompetitorsLayout.scss';

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Outlet, useMatch, useParams } from 'react-router-dom';

import { useGetCompetitorClusters, useGetCompetitorPersonas } from '@/apis/semji/competitors';
import useGetCompetitorReportWebsites from '@/apis/semji/competitors/useGetCompetitorReportWebsites';
import Loader from '@/components/Loader/Loader';
import { HeaderFilters } from '@/containers/Competitors/components/HeaderFilters';
import HeaderShadow from '@/containers/Competitors/components/HeaderShadow';
import LayoutHeader from '@/containers/Competitors/components/LayoutHeader';
import { useCompetitorReports } from '@/containers/Competitors/hooks/useCompetitor';
import {
  COMPETITOR_FILTER_QUERY_PARAMS,
  SEARCH_INTELLIGENCE_PAGES,
} from '@/containers/Competitors/utils/constants';
import { getWebsiteReference } from '@/containers/Competitors/utils/utils';
import useFilters from '@/hooks/useFilters/useFilters';
import { changeDefaultReportId } from '@/store/actions/searchIntelligence';
import { CompetitorReport, CompetitorTopPagesFilters } from '@/types/competitors';
import { SEMJI_PAGE_BODY_ID } from '@/utils/constants';

export default function CompetitorLayout() {
  const { t } = useTranslation();

  const { reportId } = useParams();
  const dispatch = useDispatch();
  const match = useMatch('/o/:organizationId/w/:workspaceId/search-intelligence/:reportId/:page/*');
  const { page } = match?.params || {};
  const { competitorReports } = useCompetitorReports();

  const activeReportIndex = competitorReports.findIndex(
    (item: CompetitorReport) => item.id === reportId
  );

  const {
    data: competitorWebsites = [],
    isLoading: isLoadingWebsites,
    isPlaceholderData: isPlaceholderDataWebsites,
  } = useGetCompetitorReportWebsites({
    competitorReportId: String(reportId),
    staleTime: 0,
  });

  const { data: personas = [], isLoading: isPersonasLoading } = useGetCompetitorPersonas({
    competitorReportId: String(reportId),
    staleTime: 0,
  });

  const { data: clusters = [], isLoading: isClustersLoading } = useGetCompetitorClusters({
    competitorReportId: String(reportId),
    staleTime: 0,
  });

  const filterHook = useFilters<CompetitorTopPagesFilters>();

  useEffect(() => {
    dispatch(changeDefaultReportId(reportId));
  }, [reportId]);

  useEffect(() => {
    // scroll to top when changing page
    document.getElementById(SEMJI_PAGE_BODY_ID)?.scrollTo({
      behavior: 'auto',
      left: 0,
      top: 0,
    });
  }, [page]);

  const isLoading =
    isPersonasLoading || isClustersLoading || isLoadingWebsites || isPlaceholderDataWebsites;

  if (isLoading) {
    return (
      <div className="competitors-loader">
        <Loader fontSize="3em" opaque>
          <p className="competitors-loader__text">{t('common:labels.loading')}</p>
        </Loader>
      </div>
    );
  }

  const competitorContextData = {
    clusters,
    competitorComparativeDates: competitorReports[activeReportIndex]?.comparativeDates,
    competitorReportDate: competitorReports[activeReportIndex]?.reportDate,
    competitorReportDateStart: competitorReports[activeReportIndex]?.reportDateStart,
    competitorReportId: String(reportId),
    competitorReports,
    competitorWebsites,
    personas,
    retrievalDates: competitorReports[activeReportIndex]?.retrievalDates,
    trafficNormalizationStatus: competitorReports[activeReportIndex]?.trafficNormalizationStatus,
    websiteReference: getWebsiteReference(competitorWebsites ?? []),
  };

  return (
    <div className="main-competitor">
      <LayoutHeader
        competitorReportId={String(reportId)}
        reportDate={competitorContextData.competitorReportDate}
      />
      <div className="main-competitor__filters">
        <HeaderFilters
          clusters={clusters}
          competitorWebsites={competitorWebsites}
          disabledFilters={{
            [COMPETITOR_FILTER_QUERY_PARAMS.COMPETITOR_WEBSITE_ID]:
              page === SEARCH_INTELLIGENCE_PAGES.competition,
            [COMPETITOR_FILTER_QUERY_PARAMS.RETRIEVAL_DATE]: [
              SEARCH_INTELLIGENCE_PAGES.competition,
              SEARCH_INTELLIGENCE_PAGES.report,
            ].includes(page),
          }}
          disabledPagination={[
            SEARCH_INTELLIGENCE_PAGES.competition,
            SEARCH_INTELLIGENCE_PAGES.report,
          ].includes(page)}
          filterHook={filterHook}
          personas={personas}
          reportDate={competitorContextData.competitorReportDate}
          retrievalDates={competitorContextData.retrievalDates}
        />
      </div>
      <HeaderShadow />
      <Outlet context={competitorContextData} />
    </div>
  );
}
