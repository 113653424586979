import TextField from '@material-ui/core/TextField';
import { lazy, Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DialogModal, ENUM_DIALOG_MODAL_SIZE } from 'semji-core/components/DialogModal';

import DialogTemplate from '@/components/Dialog/DialogTemplate';
import PasswordFieldSkeleton from '@/components/Password/PasswordFieldSkeleton';
import { BlockText } from '@/components/Text/Inline';
import { ParagraphText } from '@/components/Text/Paragraph';
import UserService from '@/services/User';
import { showSuccessSnackbar } from '@/store/actions/ui';
import { setUserIsExternalLogin } from '@/store/actions/user';
import {
  DEFAULT_PASSWORD_MIN_STRENGTH,
  DEFAULT_PASSWORD_THRESHOLD_LENGTH,
} from '@/utils/constants';

const PasswordField = lazy(
  () => import(/* webpackChunkName: "PasswordField" */ '@/components/Password/PasswordField')
);

function SetPasswordDialog({ isOpen, onCancel }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user?.id);

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordStrength, setPasswordStrength] = useState(0);

  const [apiError, setApiError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const isValidPassword = (value, strength) => {
    return (
      strength >= DEFAULT_PASSWORD_MIN_STRENGTH && value.length >= DEFAULT_PASSWORD_THRESHOLD_LENGTH
    );
  };

  const isSubmitDisabled =
    !confirmPassword ||
    !newPassword ||
    !isValidPassword(newPassword, passwordStrength) ||
    isLoading ||
    confirmPassword !== newPassword;

  const onConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const onNewPasswordChange = ({ password, strength }) => {
    setNewPassword(password);
    setPasswordStrength(strength);
  };

  const onConfirm = async () => {
    setApiError('');
    setIsLoading(true);

    if (isSubmitDisabled) {
      return;
    }

    try {
      await new UserService().setNewPassword(userId, '', newPassword);

      dispatch(
        showSuccessSnackbar(
          t('settings:workspace.change-password.set-password-dialog.snackbar-change-success')
        )
      );
      dispatch(setUserIsExternalLogin(false));
      onClose();
    } catch (error) {
      setIsLoading(false);

      setApiError(t('settings:workspace.change-password.set-password-dialog.unknown-error'));
    }
  };

  const onClose = () => {
    setNewPassword('');
    setConfirmPassword('');
    setPasswordStrength(0);
    setApiError('');
    setIsLoading(false);

    onCancel?.();
  };

  return (
    <DialogModal
      cancelAction={onClose}
      cancelLabel={t('settings:workspace.change-password.set-password-dialog.cancel-label')}
      canClickOutsideToClose
      closable
      confirmAction={onConfirm}
      confirmLabel={t('settings:workspace.change-password.set-password-dialog.confirm-label')}
      error={!!apiError && apiError}
      isConfirmDisabled={isSubmitDisabled}
      isOpen={Boolean(isOpen)}
      title={t('settings:workspace.change-password.set-password-dialog.title')}
      onClose={onClose}
    >
      <BlockText weight="medium">
        {t('settings:workspace.change-password.set-password-dialog.input-new-password')}
      </BlockText>
      <Suspense fallback={<PasswordFieldSkeleton />}>
        <PasswordField
          error={!!apiError}
          label=""
          name="newPassword"
          placeholder=""
          thresholdLength={DEFAULT_PASSWORD_THRESHOLD_LENGTH}
          onPasswordChanged={onNewPasswordChange}
        />
      </Suspense>
      <BlockText weight="medium">
        {t('settings:workspace.change-password.set-password-dialog.input-confirm-password')}
      </BlockText>
      <TextField
        autoComplete="confirm-password"
        error={!!apiError}
        fullWidth
        name="confirmPassword"
        required
        type="password"
        value={confirmPassword}
        onChange={onConfirmPasswordChange}
      />
    </DialogModal>
  );
}

export default SetPasswordDialog;
