import Dialog from '@material-ui/core/Dialog';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import UnlockImage from '@/assets/images/unlock-image.svg';
import CloseIcon from '@/components/icons/CloseIcon';
import { PrimaryLink } from '@/components/Navigation/Link';
import PrimaryButton from '@/design-system/components/Button/PrimaryButton';
import Flex from '@/design-system/components/Flex';
import { SUPPORT_ADDRESS_MAIL } from '@/utils/constants';

const Title = styled.span`
  font-size: ${({ theme }) => theme.textCss.sizes.lg};
  font-weight: ${({ theme }) => theme.textCss.weights.strong};
  line-height: ${({ theme }) => theme.textCss.sizes.xxl};
  color: ${({ theme }) => theme.cssColors.dark100};
  text-align: center;
`;
const ExplanationText = styled.span`
  line-height: ${({ theme }) => theme.textCss.sizes.lg};
  text-align: center;
  color: ${({ theme }) => theme.cssColors.dark060};
`;
const Illustation = styled.img`
  height: 150px;
  width: 193px;
  margin-bottom: 20px;
`;
const Close = styled(CloseIcon)`
  cursor: pointer;
`;

function ServiceIntegrationAddOnDialog({ onClose, connector }) {
  const { t } = useTranslation();

  return (
    <Dialog open onClose={onClose}>
      <Flex flexDirection="column" padding="32px">
        <Flex justifyContent="end">
          <Close onClick={onClose} />
        </Flex>
        <Flex
          alignItems="center"
          flexDirection="column"
          gap="20px"
          height="500px"
          justifyContent="center"
        >
          <Illustation alt={t('service-integration:add-on.illustration-alt')} src={UnlockImage} />
          <Title>{t('service-integration:add-on.title', { connector })}</Title>
          <ExplanationText>
            {t('service-integration:add-on.explanation', { connector })}
          </ExplanationText>
          <PrimaryLink
            isExternal
            noDecoration
            target="_blank"
            to={`mailto:${SUPPORT_ADDRESS_MAIL}`}
          >
            <PrimaryButton noLetterSpacing>{t('service-integration:add-on.button')}</PrimaryButton>
          </PrimaryLink>
        </Flex>
      </Flex>
    </Dialog>
  );
}

export default ServiceIntegrationAddOnDialog;
