import React from 'react';

import SvgWrapper from './SvgWrapper';

const LinkIcon = (props) => (
  <SvgWrapper {...props} height="10" viewBox="0 0 17 10" width="17">
    <path d="M1.7112 5.00016C1.7112 3.57516 2.86954 2.41683 4.29454 2.41683H7.62787V0.833496H4.29454C1.99454 0.833496 0.127869 2.70016 0.127869 5.00016C0.127869 7.30016 1.99454 9.16683 4.29454 9.16683H7.62787V7.5835H4.29454C2.86954 7.5835 1.7112 6.42516 1.7112 5.00016ZM5.12787 5.8335H11.7945V4.16683H5.12787V5.8335ZM12.6279 0.833496H9.29453V2.41683H12.6279C14.0529 2.41683 15.2112 3.57516 15.2112 5.00016C15.2112 6.42516 14.0529 7.5835 12.6279 7.5835H9.29453V9.16683H12.6279C14.9279 9.16683 16.7945 7.30016 16.7945 5.00016C16.7945 2.70016 14.9279 0.833496 12.6279 0.833496Z" />
  </SvgWrapper>
);

export default LinkIcon;
