import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import i18next from 'i18next';
import memoize from 'lodash/memoize';
import { DateTime } from 'luxon';
import React, { PureComponent } from 'react';
import ReactDOMServer from 'react-dom/server';
import { getRoundedNumberMetricObject } from 'semji-core/utils/numbers';
import { withTheme } from 'styled-components/macro';
import styled from 'styled-components/macro';

import { ReportMetricKey } from '@/containers/Report/utils/types';
import { getChartZones } from '@/utils/charts/getChartZonesByMetric';
import { reverseChartPointValue } from '@/utils/charts/reverseChartPointValue';
import {
  METRIC_POSITION_KEY,
  METRICS_CONFIG_PERIODICITY_DAILY,
  periodicities,
} from '@/utils/metrics/constants';

import { SparklineTooltip } from './Tooltip';

const RANK_TRACKING_MIN_VALUE = 100;

function computeMaxReversed(serie) {
  const pointsY = serie.aggregateMetrics.map(({ y }) => y ?? 100);
  return RANK_TRACKING_MIN_VALUE - Math.round(Math.min(...pointsY));
}

function computeMinReversed(serie) {
  const pointsY = serie.aggregateMetrics.map(({ y }) => y ?? 100);
  return RANK_TRACKING_MIN_VALUE - Math.round(Math.max(...pointsY));
}

export default withTheme(styled(
  class HighChartsSparkline extends PureComponent {
    forgeSeriesFromMetricSeries = memoize((metricsSeries, isPositionMetric) => {
      const series = metricsSeries.map((metricsSerie) => {
        const zones = getChartZones({
          aggregateMetrics: metricsSerie.aggregateMetrics,
          dashStyle: 'dot',
        });
        const metricColor = metricsSerie.metric.isSubCategory
          ? this.props.theme.metricsColor[metricsSerie.metric.parentCategoryKey]
          : this.props.theme.metricsColor[metricsSerie.key];

        return {
          color: metricColor,
          connectNulls: this.props.metricKey === ReportMetricKey.AverageContentScore,
          data: metricsSerie.aggregateMetrics.map((aggregateMetric) => ({
            ...aggregateMetric,
            marker: {
              enabled: !!aggregateMetric.publicationsCount,
              fillColor: this.props.theme.colors.secondary,
              lineColor: this.props.theme.colors.secondary,
              radius: 2,
              states: {
                hover: {
                  fillColor: !!aggregateMetric.publicationsCount
                    ? this.props.theme.colors.secondary
                    : metricColor,
                  lineWidthPlus: 0,
                  radius: 2,
                  radiusPlus: 0,
                },
              },
            },
            // We do a fake reverse for rank tracking trend the min value will be at the bottom
            // of the chart to draw the chart background
            y: isPositionMetric
              ? reverseChartPointValue(RANK_TRACKING_MIN_VALUE, aggregateMetric.y, 0)
              : aggregateMetric.y,
          })),
          fillOpacity: 0.1,
          label: metricsSerie.metric.name,

          metric: metricsSerie.metric,

          name: metricsSerie.key + 'current',

          period: 'current',

          type: 'area',
          zoneAxis: 'x',
          zones,
        };
      });

      return { series };
    });

    render() {
      const { className, theme, metricsSeries, periodicity, movingAverageWindow, metricKey } =
        this.props;
      const isPositionMetric = metricKey === METRIC_POSITION_KEY;
      const { series } = this.forgeSeriesFromMetricSeries(metricsSeries, isPositionMetric);

      const options = {
        chart: {
          animation: false,
          backgroundColor: 'none',
          height: 70,
          skipClone: true,
          style: {
            fontFamily: theme.textCss.fonts.main,
            overflow: 'visible',
          },
          width: 150,
        },
        credits: {
          enabled: false,
        },
        exporting: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          series: {
            animation: false,
            fillOpacity: 0.25,
            lineWidth: 1,
            shadow: false,
            states: {
              hover: {
                lineWidth: 1,
              },
            },
          },
        },
        series,
        title: {
          text: '',
        },
        tooltip: {
          backgroundColor: 'white',
          borderWidth: 1,
          formatter: function () {
            let points = [];
            let date = null;
            this.points.forEach((point) => {
              const serie = metricsSeries[0];
              date = DateTime.fromMillis(point.x, { locale: i18next.language });
              // TODO: Add locale after switching to functional compoenents
              let roundedValueObject = getRoundedNumberMetricObject({
                number: point.y,
              });
              // We undo the fake reverse calculation for rank tracking trend to get the real value to display
              if (isPositionMetric) {
                roundedValueObject.value = reverseChartPointValue(
                  RANK_TRACKING_MIN_VALUE,
                  roundedValueObject.value
                );
              }

              points.push({
                color: point.series.color,
                label: i18next.t(serie.metric.name),
                suffix: roundedValueObject.suffix + serie.metric.suffix,
                value: roundedValueObject.value,
              });

              if (point.point.publicationsCount) {
                // TODO: Add locale after switching to functional compoenents
                const roundedValuePublicationsCount = getRoundedNumberMetricObject({
                  number: point.point.publicationsCount,
                });
                points.push({
                  color: theme.colors.secondary,
                  label: 'Publication count',
                  suffix: roundedValuePublicationsCount.suffix,
                  value: roundedValuePublicationsCount.value,
                });
              }
            });

            return ReactDOMServer.renderToStaticMarkup(
              <SparklineTooltip
                periodicity={i18next.t(`report:chart.periodicity.${periodicity}`)}
                points={points}
                subTitle={i18next.t('report:metric-label.scale_interval', {
                  count: movingAverageWindow,
                  postProcess: 'interval',
                  type: i18next.t('report:chart.metric-tabs.avg'),
                })}
                title={date?.toFormat(periodicities[METRICS_CONFIG_PERIODICITY_DAILY].humanFormat)}
              />
            );
          },
          hideDelay: 0,
          outside: true,
          shared: true,
          style: {
            color: theme.cssColors.dark080,
          },
          useHTML: true,
        },
        xAxis: {
          type: 'datetime',
          visible: false,
        },
        yAxis: {
          // We use the min and max value for the position metric to get a better view of positions
          max: isPositionMetric ? computeMaxReversed(metricsSeries[0]) : null,
          min: isPositionMetric ? computeMinReversed(metricsSeries[0]) : null,
          visible: false,
        },
      };

      return (
        <div className={className}>
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
      );
    }
  }
)`
  width: ${(props) => props.width + 'px'};
  height: ${(props) => props.height + 'px'};

  .highcharts-legend-item {
    & > span {
      display: flex;
      align-items: center;
      transition: opacity 300ms;

      > div {
        transition: color 300ms;
        font-weight: 400;
        color: ${({ theme }) => theme.text.colors.default};
      }

      svg {
        margin-right: ${({ theme }) => theme.text.sizes.micro};
        width: ${({ theme }) => theme.text.sizes.default};
        height: ${({ theme }) => theme.text.sizes.default};
      }
    }

    &:hover > span > div {
      color: ${({ theme }) => theme.text.colors.darker};
    }

    &-hidden {
      & > span {
        opacity: 0.3;
      }
      &:hover > span {
        opacity: 0.6;
      }
    }
  }
`);
