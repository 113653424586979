import styled from 'styled-components/macro';

import Flex from '@/design-system/components/Flex';

export const HeaderWrapper = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.cssColors.dark005};
`;

export const Title = styled.span`
  font-size: ${({ theme }) => theme.textCss.sizes.sm};
  font-weight: ${({ theme }) => theme.textCss.weights.strong};
  line-height: ${({ theme }) => theme.textCss.lineHeight};
`;

export const SelectorText = styled.span`
  font-size: ${({ theme }) => theme.textCss.sizes.xs};
  color: ${({ theme }) => theme.cssColors.dark060};
  line-height: ${({ theme }) => theme.textCss.lineHeight};
  font-weight: 400;
`;

export const SelectorDropdownWrapper = styled(Flex)`
  box-shadow: ${({ theme }) => theme.boxShadow.modal};
`;

export const SelectorDropdownItem = styled(Flex)`
  &:hover {
    background-color: ${({ theme }) => theme.cssColors.primary005};
  }
`;

export const SelectorDropdownText = styled.span`
  line-height: ${({ theme }) => theme.textCss.lineHeight};
  white-space: nowrap;
  font-size: ${({ theme }) => theme.textCss.sizes.sm};
  color: ${({ theme }) => theme.cssColors.dark080};
`;

export const EmptyStateTitle = styled.span`
  line-height: ${({ theme }) => theme.textCss.lineHeight};
  font-size: ${({ theme }) => theme.textCss.sizes.md};
  font-weight: ${({ theme }) => theme.textCss.weights.strong};
  text-align: center;
`;

export const EmptyStateExplanation = styled.span`
  line-height: ${({ theme }) => theme.textCss.lineHeight};
  text-align: center;
  font-size: ${({ theme }) => theme.textCss.sizes.sm};
  color: ${({ theme }) => theme.cssColors.dark060};
`;
