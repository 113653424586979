import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import apiClient from '@/apis/semji/apiClient';
import { showErrorSnackbar } from '@/store/actions/ui';

export default function usePutBrandVoice(props) {
  const { put } = apiClient;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: async ({ brandVoiceId = '', content = {} }) => {
      const editedBrandVoice = await put(`/deprecated_brand_voices/${brandVoiceId}`, {
        ...content,
      });
      return editedBrandVoice;
    },

    onError: () => {
      dispatch(showErrorSnackbar(t('common:error.default')));
    },
    ...props,
  });
}
