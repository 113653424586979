import { combineReducers } from 'redux';

import {
  comparisonPeriods,
  METRICS_CONFIG_COMPARISON_PERIOD_PREVIOUS_YEAR,
  METRICS_CONFIG_PERIOD_LAST_365_DAYS,
  METRICS_CONFIG_PERIODICITY_MONTHLY,
  periodicities,
} from '../../utils/metrics/constants';
import {
  CHANGE_METRICS_CONFIG_CURRENT_COMPARISON_PERIOD,
  CHANGE_METRICS_CONFIG_PERIOD,
  CHANGE_METRICS_CONFIG_PERIODICITY,
} from '../actions/actionTypes';

const periodicity = (state = METRICS_CONFIG_PERIODICITY_MONTHLY, action) => {
  switch (action.type) {
    case CHANGE_METRICS_CONFIG_PERIODICITY:
      if (Object.keys(periodicities).indexOf(action.periodicity) !== -1) {
        return action.periodicity;
      } else {
        return state;
      }
    default:
      return state;
  }
};

const period = (state = METRICS_CONFIG_PERIOD_LAST_365_DAYS, action) => {
  switch (action.type) {
    case CHANGE_METRICS_CONFIG_PERIOD:
      return action.period;
    default:
      return state;
  }
};

const comparisonPeriod = (state = METRICS_CONFIG_COMPARISON_PERIOD_PREVIOUS_YEAR, action) => {
  switch (action.type) {
    case CHANGE_METRICS_CONFIG_CURRENT_COMPARISON_PERIOD:
      if (Object.keys(comparisonPeriods).indexOf(action.comparisonPeriod) !== -1) {
        return action.comparisonPeriod;
      } else {
        return state;
      }
    default:
      return state;
  }
};

export default combineReducers({
  comparisonPeriod,
  period,
  periodicity,
});
