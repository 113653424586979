import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import apiClient from '@/apis/semji/apiClient';
import { SCOPE_COMPETITORS } from '@/apis/semji/constants';
import { QueryOptions } from '@/apis/types/query.types';
import useApiConfigurations from '@/hooks/useApiConfigurations';
import { showErrorSnackbar } from '@/store/actions/ui';
import { CompetitorReport } from '@/types/competitors';
import { LISTING_REFETCH_FREQUENCY_MS } from '@/utils/configurations/constants';

interface UseGetCompetitorReportByIdProps extends QueryOptions<{}, CompetitorReport> {
  competitorReportId: string;
}

export default function useGetCompetitorReportById({
  competitorReportId,
  ...props
}: UseGetCompetitorReportByIdProps) {
  const { get } = apiClient;
  const REFETCH_INTERVAL_IN_MS = parseInt(useApiConfigurations(LISTING_REFETCH_FREQUENCY_MS), 10);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { organizationId, workspaceId } = useParams();

  return useQuery({
    onError: () => {
      dispatch(showErrorSnackbar(t('common:error.default')));
      navigate(`/o/${organizationId}/w/${workspaceId}/competitors`, { replace: true });
    },
    queryFn: async ({ signal }) => {
      const result = await get(`/competitor_reports/${competitorReportId}`, {
        signal,
      });

      return result.data;
    },
    queryKey: [SCOPE_COMPETITORS.GET_COMPETITOR_REPORT_BY_ID, competitorReportId],
    refetchInterval: REFETCH_INTERVAL_IN_MS,
    refetchOnWindowFocus: 'always',
    ...props,
  });
}
