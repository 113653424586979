import PropTypes from 'prop-types';
import React, { Suspense, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useMatch, useNavigate, useParams } from 'react-router-dom';
import { LimitModal } from 'semji-core/components/LimitModal';
import { getClassNames } from 'semji-core/utils/getClassNames';

import useGetContentById from '@/apis/semji/contents/useGetContentById';
import apiSettings from '@/apis/semji/settings';
import UnlockImage from '@/assets/images/unlock-image.svg';
import MarkAsPublishedDialog from '@/components/Dialog/MarkAsPublishedDialog';
import PlanningTopbarButton from '@/components/Planning/PlanningTopbarButton';
import Brief from '@/containers/Content/Brief';
import { ONLINE_PAGE_TAB, TEXT_PAGE_TAB } from '@/containers/Content/constants';
import { useContentContainerContext } from '@/containers/Content/ContentContainerContext';
import EmptyEditorContainer from '@/containers/Content/EmptyEditorContainer';
import BackEditor from '@/containers/Content/HeaderComponents/Back';
import MetadataBar from '@/containers/Content/HeaderComponents/MetadataBar';
import ToggleEditor from '@/containers/Content/HeaderComponents/ToggleEditor';
import PageContainer from '@/containers/Content/PageContainer';
import { SidePanelContainer } from '@/containers/Content/SidePanel/SidePanelContainer';
import { ENUM_SIDE_PANEL_STATUS } from '@/containers/Content/SidePanel/SidePanelContainer/SidePanelContainer.types';
import Editor from '@/containers/Content/TinyMceComponents/Editor';
import EditorSkeleton from '@/containers/Content/TinyMceComponents/Editor/EditorSkeleton';
import { EditorPageContainerWrapper, FlexWrapper } from '@/containers/Content/Wrappers';
import AdministratorMenu from '@/containers/Layout/AdministratorMenu';
import NavBar from '@/design-system/components/NavBar/NavBar';
import NavBarGroup from '@/design-system/components/NavBar/NavBarGroup';
import { useMixpanelTrackEvent } from '@/hooks/useMixpanelTrackEvent';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import ContentService from '@/services/Content';
import { sendCustomEvent, updateWithCustomAttribute } from '@/services/Intercom';
import PageService from '@/services/Page';
import {
  ADD_DRAFT_TO_PLANNING_FAIL,
  ADD_DRAFT_TO_PLANNING_LOADING,
  ADD_DRAFT_TO_PLANNING_SUCCESS,
  CREATE_NEW_DRAFT_FAIL,
  CREATE_NEW_DRAFT_LOADING,
  CREATE_NEW_DRAFT_SUCCESS,
  DELETE_DRAFT_ID,
  FETCH_DRAFT_FAIL,
  FETCH_DRAFT_LOADING,
  FETCH_DRAFT_SUCCESS,
  FETCH_LIVE_CONTENT_DATA_FINISHED,
  FETCH_LIVE_CONTENT_DATA_LOADING,
  FETCH_LIVE_CONTENT_DATA_SUCCESS,
  FETCH_LIVE_PAGE_DATA_FINISHED,
  FETCH_LIVE_PAGE_DATA_LOADING,
  FETCH_PAGE_CONTENTS_FAIL,
  FETCH_PAGE_CONTENTS_LOADING,
  FETCH_PAGE_CONTENTS_SUCCESS,
  FETCH_PAGE_LOADING,
  FETCH_PAGE_SUCCESS,
  OPEN_PUBLISH_DIALOG,
  PUBLISH_CANCEL,
  PUBLISH_FAIL,
  PUBLISH_PENDING,
  PUBLISH_SUCCESS,
  SET_FOCUS_TOP_KEYWORD,
  UPDATE_PAGE,
} from '@/store/actions/actionTypes';
import { resetContent, setContent } from '@/store/actions/content';
import { resetEditor } from '@/store/actions/editor';
import { resetCurrentReportScore } from '@/store/actions/report';
import { showErrorSnackbar, showSuccessSnackbar } from '@/store/actions/ui';
import { selectCurrentWorkspaceIsStaticContentEnabled } from '@/store/selectors/selectCurrentWorkspaceIsStaticContentEnabled';
import { selectCurrentWorkspaceWebsiteUrl } from '@/store/selectors/selectCurrentWorkspaceWebsiteUrl';
import { CONTENT_PUBLISHED } from '@/utils/3rdParty/Mixpanel/constants';
import { SECTIONS } from '@/utils/log/constants';
import { Log } from '@/utils/log/Log';
import { isUrlValidWithWebSiteUrl } from '@/utils/url';

function ContentContainer({ isBrief = false, isContentBriefEnabled, isContent, isNew }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentWorkspaceWebsiteUrl = useSelector(selectCurrentWorkspaceWebsiteUrl);
  const currentDraftScore = useSelector((state) => state.report.score);
  const {
    aiWriting: {
      limitAiWriting: { setShowLimitAiWriting, showLimitAiWriting },
    },
  } = useContentContainerContext();

  const cancelFetchContent = useRef(null);
  const cancelFetchDraft = useRef(null);
  const cancelFetchPage = useRef(null);
  const cancelFetchPageContents = useRef(null);
  const cancelRefreshDraftData = useRef(null);
  const cancelRefreshPageData = useRef(null);

  const navigate = useNavigate();

  const location = useLocation();
  const match = useMatch('/o/:organizationId/w/:workspaceId/p/:pageId/:tab/*');
  const { contentId, organizationId, pageId, workspaceId } = useParams();

  const getContentById = useGetContentById({ contentId });
  const isPublishedContent = getContentById.data?.type === 'PUBLISHED';
  const isOriginalVersion = getContentById.data?.type === 'ORIGINAL';

  const pageService = useMemo(() => new PageService(pageId, workspaceId), [pageId, workspaceId]);

  const historyBackUrl = useSelector(
    (state) => state.workspaceRouteHistory || `/o/${organizationId}/w/${workspaceId}/dashboard`
  );
  const [topKeywordUpdating, setTopKeywordUpdating] = useState({ keyword: null, loading: false });

  const currentOrganizationTrackedPages = useSelector(
    (state) => state.organizations.byId[organizationId]?.trackedPages
  );
  const { isFeatureEnabled: organizationRankTrackingEnabled } = useOrganizationFeatureSet(
    'rank-tracking:is-enabled'
  );
  const { isFeatureEnabled: hasUnlimitedRankTrackingUrls } = useOrganizationFeatureSet(
    'rank-tracking:urls-tracked-number:has-unlimited-amount'
  );
  const { data: rankTrackingLimit } = useOrganizationFeatureSet(
    'rank-tracking:urls-tracked-number'
  );
  const {
    createdAt,
    draftId,
    draftIsStarted,
    isAddingDraftToPlanning,
    isContentLoading,
    isCreatingNewDraft,
    isDraftLoading,
    isPageContentsLoading,
    isPageLoading,
    isPublishDialogOpen,
    isPublishPending,
    page,
    pageContents,
    publishError,
    url,
    urlPath,
  } = useSelector((state) => state.editor);

  // Some data are located in redux store because they are used in the <Sidebar.
  const title = useSelector((state) => state.content?.title || '');
  const metaDescription = useSelector((state) => state.content?.metaDescription || '');
  const isStaticContentEnabled = useSelector(selectCurrentWorkspaceIsStaticContentEnabled);

  const trackMixpanelEvent = useMixpanelTrackEvent();

  const [sidePanelStatus, setSidePanelStatus] = useState(ENUM_SIDE_PANEL_STATUS.Normal);

  const isOnlinePage = isStaticContentEnabled && match?.params.tab === ONLINE_PAGE_TAB;
  const isTextPage = isStaticContentEnabled
    ? match?.params.tab === TEXT_PAGE_TAB
    : match?.params.tab === ONLINE_PAGE_TAB || match?.params.tab === TEXT_PAGE_TAB;
  // const isEditor = isPublishedVersion || isDraft;
  const isLoading = isPageLoading || isDraftLoading;

  /** API calls */

  async function fetchPage() {
    try {
      dispatch({ type: FETCH_PAGE_LOADING });
      /**** getting the page data ******/
      cancelFetchPage.current = pageService.fetchPage();

      const page = await cancelFetchPage.current;

      const {
        contentRetrievedAt,
        draft,
        extra,
        extractedContentHtml,
        focusTopKeyword,
        imageUrl,
        lastStatusCode,
        redirectionUrl,
        source,
        staticHtml,
        staticHtmlRetrievedAt,
        title,
        url,
        urlPath,
        isTracked,
        metaDescription,
      } = page;

      const payload = {
        contentRetrievedAt,
        createdAt: draft?.createdAt,
        draftId: draft?.id,
        draftIsStarted: draft?.isStarted,
        extra,
        extractedContentHtml,
        imageUrl,
        isTracked,
        lastStatusCode,
        metaDescription,
        redirectionUrl,
        source,
        staticHtml,
        staticHtmlRetrievedAt,
        title,
        url,
        urlPath,
      };

      dispatch({
        payload,
        type: FETCH_PAGE_SUCCESS,
      });

      /* We need to initialize report.topKeyword here when no analysis have been done.
      (useful for Brief screen) */
      dispatch({
        focusTopKeyword: { ...focusTopKeyword },
        type: SET_FOCUS_TOP_KEYWORD,
      });
    } catch (error) {
      Log.report({
        context: 'fetchPage',
        error,
        extra: 'Fetch the current page',
        section: SECTIONS.content.key,
      });
      navigate('/');
    }
  }

  async function fetchPageContents() {
    try {
      dispatch({
        type: FETCH_PAGE_CONTENTS_LOADING,
      });

      cancelFetchPageContents.current = pageService.fetchPageContents();
      const pageContents = await cancelFetchPageContents.current;

      dispatch({
        payload: {
          pageContents,
        },
        type: FETCH_PAGE_CONTENTS_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: FETCH_PAGE_CONTENTS_FAIL,
      });

      Log.report({
        context: 'fetchPageContents',
        error,
        extra: 'Fetch the current page contents',
        section: SECTIONS.content.key,
      });
    }
  }

  async function fetchDraft(id) {
    try {
      if (id) {
        dispatch({ type: FETCH_DRAFT_LOADING });

        const contentService = new ContentService(id);

        cancelFetchDraft.current = contentService.fetchContent();
        const content = await cancelFetchDraft.current;

        // if the html is null that means the draft has not been started yet
        if (isContent && content.type === 'DRAFT' && content.html === null) {
          navigate(`/o/${organizationId}/w/${workspaceId}/p/${pageId}/create`, { replace: true });
        }

        // Only pass data to store when in draft context (in editor and not on a published version)
        if (isContent) {
          dispatch(
            setContent({
              html: content.html,
              metaDescription: content.metaDescription,
              title: content.title,
            })
          );
        }

        dispatch({
          payload: {
            draft: content,
          },
          type: FETCH_DRAFT_SUCCESS,
        });
      }
    } catch (error) {
      dispatch({ type: FETCH_DRAFT_FAIL });
      Log.report({
        context: 'fetchDraft',
        error,
        extra: 'Fetch the current draft',
        section: SECTIONS.content.key,
      });
      // Still usefull?
      if (error.status >= 400) {
        navigate(`/o/${organizationId}/w/${workspaceId}/p/${pageId}/create`, { replace: true });
      }
    }
  }

  async function refreshDraftData(id) {
    const contentId = id ?? draftId;

    try {
      dispatch({ type: FETCH_LIVE_CONTENT_DATA_LOADING });

      const contentService = new ContentService(contentId);
      // the refresh data take time
      // we need to abort the call so the html won't be set with a wrong value

      cancelRefreshDraftData.current = contentService.refreshData();
      const { html, title, metaDescription, version } = await cancelRefreshDraftData.current;

      dispatch(setContent({ html, metaDescription, title }));

      dispatch({
        // this is needed because slate triggers a save with the wrong version number
        payload: { version },
        type: FETCH_LIVE_CONTENT_DATA_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: FETCH_LIVE_CONTENT_DATA_FINISHED,
      });

      Log.report({
        context: 'refreshLiveContentData',
        error,
        extra: 'Refresh content from live website data',
        section: SECTIONS.content.key,
      });
    } finally {
      cancelRefreshDraftData.current = null;
    }
  }

  async function refreshPageData() {
    try {
      dispatch({ type: FETCH_LIVE_PAGE_DATA_LOADING });

      // the refresh data take time
      // we need to abort the call so the html won't be set with a wrong value
      cancelRefreshPageData.current = pageService.refreshData();
      const {
        contentRetrievedAt,
        extractedContentHtml,
        staticHtml,
        staticHtmlRetrievedAt,
        redirectionUrl,
        title,
        metaDescription,
      } = await cancelRefreshPageData.current;

      dispatch({
        type: FETCH_LIVE_PAGE_DATA_FINISHED,
      });

      dispatch({
        payload: {
          contentRetrievedAt,
          html: extractedContentHtml,
          metaDescription,
          redirectionUrl,
          staticHtml,
          staticHtmlRetrievedAt,
          title,
        },
        type: UPDATE_PAGE,
      });
    } catch (error) {
      dispatch({
        type: FETCH_LIVE_PAGE_DATA_FINISHED,
      });

      Log.report({
        context: 'fetchLiveData',
        error,
        extra: 'Fetch page from live website data',
        section: SECTIONS.content.key,
      });
    } finally {
      cancelRefreshPageData.current = null;
    }
  }

  async function addDraftToPlanning() {
    try {
      dispatch({
        type: ADD_DRAFT_TO_PLANNING_LOADING,
      });
      const draft = await pageService.saveNewDraft({
        html: null,
        metaDescription,
        title,
      });
      dispatch({
        payload: {
          draft,
        },
        type: ADD_DRAFT_TO_PLANNING_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: ADD_DRAFT_TO_PLANNING_FAIL,
      });
      Log.report({
        context: 'addDraftToPlanning',
        error,
        extra: 'Add a new draft to planning',
        section: SECTIONS.content.key,
      });
      dispatch(showErrorSnackbar(t('common:error.default')));
    }
  }

  async function createNewDraft(assignedTo = null) {
    try {
      dispatch({
        type: CREATE_NEW_DRAFT_LOADING,
      });

      let localDraft = {
        id: draftId,
      };

      if (draftId) {
        // This condition is for Planned drafts that are not started yet
        // The refresh endpoint saves the html in the data base
        // we only need to refresh and after that fetch the draft
        await refreshDraftData();
        await fetchDraft(draftId);
      } else {
        // this condition is when we start optimizing a non planned draft
        // we need to create an empty draft, refresh the data so we can use the content extractor
        // and then notify our redux store that the draftId has changed (previously null)
        // this change of the id will trigger with a useEffect the fetchDraft the same way as the first part of this IF condition
        localDraft = await pageService.saveNewDraft({
          assignedTo,
          html: null,
          metaDescription: '',
          title: '',
        });

        await refreshDraftData(localDraft.id);

        localDraft = {
          id: localDraft.id,
        };
      }

      dispatch({
        payload: {
          draft: localDraft,
        },
        type: CREATE_NEW_DRAFT_SUCCESS,
      });

      navigate(`/o/${organizationId}/w/${workspaceId}/p/${pageId}/content/${localDraft.id}`);
    } catch (error) {
      dispatch({
        type: CREATE_NEW_DRAFT_FAIL,
      });
      Log.report({
        context: 'createNewDraft',
        error,
        extra: 'Create new draft from live page data',
        section: SECTIONS.content.key,
      });
    }
  }

  /** Event handlers */

  function showMarkAsPublishedDialog() {
    dispatch({
      type: OPEN_PUBLISH_DIALOG,
    });
  }

  async function handleConfirmPublish(url, publishDate) {
    if (isUrlValidWithWebSiteUrl(url, currentWorkspaceWebsiteUrl)) {
      try {
        dispatch({ type: PUBLISH_PENDING });

        const contentService = new ContentService(draftId);
        await contentService.publish(url, publishDate);

        // Trigger refresh call to get the last version of the site on the API side.
        refreshPageData();

        //Intercom publish event
        sendCustomEvent('content-published', {
          content_id: contentId,
          score: currentDraftScore,
          title,
          url,
          url_semji: `${apiSettings.rootUrl}/o/${organizationId}/w/${workspaceId}/p/${pageId}/content/${contentId}`,
        });
        // Send Mixpanel event
        trackMixpanelEvent(CONTENT_PUBLISHED, {
          content_id: contentId,
          score: currentDraftScore,
          title,
          url,
          url_semji: `${apiSettings.rootUrl}/o/${organizationId}/w/${workspaceId}/p/${pageId}/content/${contentId}`,
        });
        // Intercom last publication Data Attribute
        updateWithCustomAttribute({ last_publication_at: new Date().getTime() / 1000 });

        /**
         * after publishing a draft we need to set the draft to undefined
         * retrieve the new data of the page
         * launch the content online to refresh asynchronously the new content online
         *
         */
        dispatch({ type: PUBLISH_SUCCESS });

        await fetchPage();

        navigate(`/o/${organizationId}/w/${workspaceId}/p/${pageId}/online`);
        dispatch(showSuccessSnackbar(t('content:content-container.success.draft-published')));
      } catch (error) {
        Log.report({
          context: 'handleConfirm',
          error,
          extra: 'Publish the content',
          section: SECTIONS.content.key,
        });

        dispatch({ payload: { publishError: error }, type: PUBLISH_FAIL });
      }
    } else {
      dispatch({
        payload: { publishError: t('content:content-container.error.published-error') },
        type: PUBLISH_FAIL,
      });
    }
  }

  function handleCancelPublish() {
    dispatch({ type: PUBLISH_CANCEL });
  }

  function getPageUrl() {
    return url ? `/o/${organizationId}/w/${workspaceId}/p/${pageId}/online` : undefined;
  }

  function getPageTextUrl() {
    return url ? `/o/${organizationId}/w/${workspaceId}/p/${pageId}/text` : undefined;
  }

  const briefUrl = `/o/${organizationId}/w/${workspaceId}/p/${pageId}/brief`;

  function getEditorUrl() {
    return !!draftId
      ? `/o/${organizationId}/w/${workspaceId}/p/${pageId}/content/${draftId}`
      : `/o/${organizationId}/w/${workspaceId}/p/${pageId}/create`;
  }

  function onDeleteDraft() {
    dispatch({ type: DELETE_DRAFT_ID });

    if (isContent) {
      navigate(`/o/${organizationId}/w/${workspaceId}/p/${pageId}/create`, { replace: true });
    }
  }

  /** Effects */
  useEffect(() => {
    // First mount, reset redux content state to ensure no remaining ids from unclean data and fetch page.
    dispatch(resetEditor());
    dispatch(resetCurrentReportScore());
    fetchPage();

    return () => {
      // Cleanup redux state on unmount.
      dispatch(resetEditor());
      dispatch(resetContent());
      dispatch(resetCurrentReportScore());

      cancelFetchContent.current?.cancel?.();
      cancelFetchDraft.current?.cancel?.();
      cancelFetchPage.current?.cancel?.();
      cancelFetchPageContents.current?.cancel?.();
      cancelRefreshDraftData.current?.cancel?.();
      cancelRefreshPageData.current?.cancel?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isNew && !!getContentById.data?.html) {
      navigate(`/o/${organizationId}/w/${workspaceId}/p/${pageId}/content/${draftId}`, {
        replace: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNew, isDraftLoading, isPageLoading]);

  useEffect(() => {
    // if the html is null that means the draft has not been started yet
    // there is a case that set html to empty string, need to be investigated
    if (isContent && !isPageLoading && !getContentById.data?.html && !getContentById.isLoading) {
      navigate(`/o/${organizationId}/w/${workspaceId}/p/${pageId}/create`, { replace: true });
    }

    // Be careful here, title could be falsy with an empty string. Not the case for html, it's alaways replaced by an empty tag.
    if (
      isContent &&
      getContentById.data?.html &&
      getContentById.data?.title !== null &&
      getContentById.data?.metaDescription !== null
    ) {
      dispatch(
        setContent({
          html: getContentById.data?.html,
          metaDescription: getContentById.data?.metaDescription,
          title: getContentById.data?.title,
        })
      );
      // dispatch(setEditorNeedRefresh());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isContent, isPageLoading, isDraftLoading, getContentById.isLoading]);

  // Page contents
  useEffect(() => {
    (async () => {
      fetchPageContents();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Auto trigger refresh data if is new and auto trigger state is true
  useEffect(() => {
    if (isNew && location.state?.triggerRefreshDataAuto && !isDraftLoading && !isPageLoading) {
      // reset the route state to false
      navigate({
        pathname: `/o/${organizationId}/w/${workspaceId}/p/${pageId}/create`,
        replace: true,
        state: {
          triggerRefreshDataAuto: false,
        },
      });
      createNewDraft();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNew, location.state?.triggerRefreshDataAuto, isDraftLoading, isPageLoading]);

  useEffect(() => {
    return () => {
      getContentById.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentId]);

  return (
    <EditorPageContainerWrapper id="editor-page-container">
      <NavBar id="semji-navbar" variant="dark">
        <BackEditor goTo={historyBackUrl} path={urlPath} title={page?.title || ''} url={url} />
        <ToggleEditor
          isLoading={isContentLoading || isDraftLoading || isPageLoading}
          toBrief={briefUrl}
          toEditor={getEditorUrl()}
          toPage={getPageUrl()}
        />
        <NavBarGroup align="end" isMain>
          <MetadataBar />
          <PlanningTopbarButton
            draftId={draftId}
            isAddingDraftToPlanning={isAddingDraftToPlanning || isCreatingNewDraft}
            isDraftStarted={draftIsStarted}
            isLoading={isLoading}
            onAdd={addDraftToPlanning}
            onDelete={onDeleteDraft}
          />
          <AdministratorMenu variant="dark" />
        </NavBarGroup>
      </NavBar>
      <FlexWrapper>
        {isPageLoading && !contentId ? null : (
          // For the fallback, you can use EditorSkeleton for now, ContentLoader if others components are lazy loaded in the future.
          <Suspense fallback={<EditorSkeleton />}>
            <div
              className={getClassNames(
                'flex flex-1',
                sidePanelStatus !== ENUM_SIDE_PANEL_STATUS.Close && 'laptop:w-0'
              )}
            >
              {(isOnlinePage || isTextPage) && (
                <PageContainer
                  createNewDraft={createNewDraft}
                  isCreatingNewDraft={isCreatingNewDraft}
                  isDraftStarted={draftIsStarted}
                  refreshLivePageData={refreshPageData}
                  toEditor={getEditorUrl()}
                  toPageHtml={getPageUrl()}
                  toPageText={getPageTextUrl()}
                />
              )}
              {isNew && (
                <EmptyEditorContainer
                  createNewDraft={createNewDraft}
                  isCreatingNewDraft={isCreatingNewDraft}
                  isPageContentsLoading={isPageContentsLoading}
                  isUpdatingPlanning={isAddingDraftToPlanning}
                  pageContents={pageContents}
                />
              )}
              {isBrief && (
                <Brief
                  isContentBriefEnabled={isContentBriefEnabled}
                  topKeywordUpdating={topKeywordUpdating}
                />
              )}
              {isContent && <Editor />}
            </div>

            {!isNew && (
              <SidePanelContainer
                isReadOnly={
                  isTextPage || isOnlinePage || isPublishedContent || isBrief || isOriginalVersion
                }
                loading={isPageLoading || isDraftLoading || isContentLoading}
                setSidePanelStatus={setSidePanelStatus}
                setTopKeywordUpdating={setTopKeywordUpdating}
                showMarkAsPublishedDialog={showMarkAsPublishedDialog}
                sidePanelStatus={sidePanelStatus}
                tab={match?.params.tab}
                url={url}
              />
            )}
          </Suspense>
        )}
      </FlexWrapper>
      <MarkAsPublishedDialog
        key={`${url}_${isPublishDialogOpen}`}
        confirmPublishContent={handleConfirmPublish}
        createdAt={createdAt}
        currentWorkspaceWebsiteUrl={currentWorkspaceWebsiteUrl}
        error={publishError}
        handleCancel={handleCancelPublish}
        loading={isPublishPending}
        score={currentDraftScore}
        show={isPublishDialogOpen}
        title={title}
        trackedKeywordsLimit={rankTrackingLimit}
        trackedKeywordsLimitExceeded={
          organizationRankTrackingEnabled &&
          !hasUnlimitedRankTrackingUrls &&
          !page.isTracked &&
          currentOrganizationTrackedPages >= rankTrackingLimit
        }
        url={url}
      />
      {showLimitAiWriting && (
        <LimitModal
          buttonLabel={t('components:dialog.dialog-information-template.button-text')}
          description={t('content:ai-writing.limit-modal.ask-support')}
          illustration={<img alt="" src={UnlockImage} />}
          isOpen
          title={t('content:ai-writing.limit-modal.title')}
          onClose={() => setShowLimitAiWriting(false)}
        />
      )}
    </EditorPageContainerWrapper>
  );
}

ContentContainer.propTypes = {
  isContent: PropTypes.bool,
  isNew: PropTypes.bool,
};

export default ContentContainer;
