import { SemjiHub } from '@/types/common.types';

import { CHANGE_DEFAULT_HUB } from '../actions/actionTypes';

export default function defaultHub(
  state = SemjiHub.CONTENT,
  action: {
    type: string;
    hub: SemjiHub;
  }
) {
  switch (action.type) {
    case CHANGE_DEFAULT_HUB:
      return action.hub || null;
    default:
      return state;
  }
}
