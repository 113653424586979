import { useQuery, UseQueryResult } from '@tanstack/react-query';

import apiClient from '@/apis/semji/apiClient';
import { SCOPE_BRAND_VOICES } from '@/apis/semji/constants';
import { BrandVoiceModel } from '@/types/brandVoice/brandVoice.types';

export default function useGetAllBrandVoicesByWorkspaceId(
  workspaceId: string = '',
  props: {
    enabled: boolean;
  }
): UseQueryResult<BrandVoiceModel[]> {
  const { get } = apiClient;

  const brandVoices = useQuery({
    placeholderData: [],
    queryFn: async ({ signal }) => {
      const { data } = await get(`/workspaces/${workspaceId}/deprecated_brand_voices`, {
        signal,
      });

      return data?.['hydra:member'] || [];
    },
    ...props,
    queryKey: [SCOPE_BRAND_VOICES.GET_ALL_BY_WORKSPACE_ID, workspaceId],
  });

  return brandVoices;
}
