import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { getDecimalPlaces } from 'semji-core/utils/numbers';
import styled from 'styled-components/macro';

import { FlexContainer } from '@/components/_common';
import { RelativeLoader } from '@/components/Loader/Loader';
import NotAcknowledgedCell from '@/components/Table/Cell/NotAcknowledgedCell';
import NumberCell from '@/components/Table/Cell/NumberCell';
import { BrightText } from '@/components/Text/Light';
import { METRIC_TYPE_PERCENT } from '@/utils/metrics/constants';
import { formatDateByPeriodicity } from '@/utils/metrics/formatDateByPeriodicity';

const MetricName = styled.div`
  &:first-letter {
    text-transform: capitalize;
  }
`;

export function metricDataGetter({ rowData, date, metricKey, metricType }) {
  const value = rowData?.monthlyPageMetrics?.[date]?.[metricKey];
  if (value === undefined || value === null) {
    return { metricType, value: null };
  }

  if (metricType === METRIC_TYPE_PERCENT) {
    return { metricType, value: value * 100 };
  }

  return { metricType, value };
}

export function MetricLabel({ name, date, i18nextContext, count = 0 }) {
  const { t } = useTranslation();

  const nameKey =
    i18nextContext === 'female'
      ? 'report:metric-label.title-female_interval'
      : 'report:metric-label.title_interval';
  return (
    <div>
      <MetricName>{t(nameKey, { count, name, postProcess: 'interval' })}</MetricName>
      <BrightText capitalize size="small">
        {`(${formatDateByPeriodicity({
          date,
          locale: i18next.language,
        })})`}
      </BrightText>
    </div>
  );
}

export function MetricCell({ icon, isLoading, suffix, value, type }) {
  if (isLoading) {
    return <RelativeLoader fontSize="2rem" opaque />;
  }

  if (value !== null) {
    const decimalPlaces = getDecimalPlaces(value, type);
    return (
      <FlexContainer>
        {icon}&nbsp;
        <NumberCell decimalPlaces={decimalPlaces} number={value} suffix={suffix} />
      </FlexContainer>
    );
  }
  return <NotAcknowledgedCell align="center" />;
}
