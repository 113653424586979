import { nanoid } from 'nanoid';
import React, { useMemo } from 'react';

import SvgWrapper from '@/components/icons/SvgWrapper';

const LayoutMedia = (props) => {
  const svgId = useMemo(() => nanoid(), []);

  return (
    <SvgWrapper height="16" viewBox="0 0 15 16" width="15" {...props}>
      <mask
        height="15"
        id={`mask0_${svgId}`}
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="15"
        x="0"
        y="0"
      >
        <path
          d="M0 11.5707H13.2353V12.453H0V11.5707ZM0 14.2115H8.82353V13.3292H0V14.2115ZM15 0.982422V10.6883H0V0.982422H15ZM14.1176 1.86477H0.882353V9.80595H14.1176V1.86477Z"
          fill="black"
        />
      </mask>
      <g mask={`url(#mask0_${svgId})`}>
        <rect fill="#7C7D86" height="17.6471" width="17.6471" x="-1.76562" y="-0.783203" />
      </g>
    </SvgWrapper>
  );
};

export default LayoutMedia;
