import './ReportMetric.scss';

import { Duration } from 'luxon';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import UpgradePlan, { ENUM_UPGRADE_PLAN_VARIANT } from '@/components/EmptyState/UpgradePlan';
import { useReport } from '@/containers/Report/hooks';
import { GOAL_KEY_QUERY_PARAM } from '@/containers/Report/utils/constants';
import { ReportMetricKey, ReportView } from '@/containers/Report/utils/types';
import { useMetricsConfig } from '@/hooks/useMetricsConfig';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import { selectWorkspaceConfig } from '@/store/selectors/selectWorkspaceConfig';
import { getPeriods } from '@/utils/metrics/getPeriods';

import { ReportGraphContainer } from './ReportGraphContainer';
import { ReportPagesContainer } from './ReportPagesContainer';

function ReportMetric() {
  const { organizationId, workspaceId, metricKey, reportView = ReportView.All } = useParams();
  const [query, setQuery] = useSearchParams();
  const {
    filteredPagesIds,
    filteredPages,
    isLoading: isLayoutLoading,
    filters,
    currentCount,
  } = useReport();
  const navigate = useNavigate();
  const { period, periodicity, comparisonPeriod } = useSelector(selectWorkspaceConfig);
  const metrics = useMetricsConfig({ isNew: true });
  const { data: maxDataToDisplay } = useOrganizationFeatureSet(
    'url-metrics:max-data-to-display-in-months'
  );
  const [showTable, setShowTable] = useState(false);

  function selectMetricClick(metricKey: string, goalKey: string) {
    if (goalKey) {
      query.set(GOAL_KEY_QUERY_PARAM, goalKey);
      setQuery(query, { replace: true });
    } else {
      query.delete(GOAL_KEY_QUERY_PARAM);
      navigate(
        `/o/${organizationId}/w/${workspaceId}/reports/${reportView}/${metricKey}?${query.toString()}`,
        {
          replace: true,
        }
      );
    }
  }

  const getCustomGoal = () => {
    const goalKey = query.get(GOAL_KEY_QUERY_PARAM);
    if (!goalKey) {
      return undefined;
    }
    if (!!metrics[goalKey]) {
      return goalKey;
    }
    // if we refresh with a custom goal in query params we reset
    // the custom goals
    query.delete(GOAL_KEY_QUERY_PARAM);
    return undefined;
  };

  function getCurrentCount() {
    switch (reportView) {
      case ReportView.New:
        return currentCount.new;
      case ReportView.Existing:
        return currentCount.existing;
      default:
        return currentCount.new + currentCount.existing;
    }
  }

  function handleShowTable(value: boolean) {
    if (value !== showTable) {
      setShowTable(value);
    }
  }
  const isActivePeriodLimited = useMemo(() => {
    const periodObj = getPeriods()[period];
    if (!maxDataToDisplay || !periodObj.duration?.months) {
      return false;
    } else {
      const lockDate = Duration.fromISO(maxDataToDisplay).toObject();
      return lockDate.months > periodObj.months;
    }
  }, [maxDataToDisplay, period]);

  const goalKey = getCustomGoal();

  const isActiveFilter = filters?.filterGroups?.length > 0;

  return (
    <div className="report-page-metric">
      {isActivePeriodLimited && (
        <div className="report-page-metric__card--empty-state">
          <UpgradePlan variant={ENUM_UPGRADE_PLAN_VARIANT.SMALL} />
        </div>
      )}
      {!isActivePeriodLimited && (
        <div className="report-page-metric__card">
          <ReportGraphContainer
            comparisonPeriod={comparisonPeriod}
            currentCount={getCurrentCount()}
            customGoalKey={goalKey}
            filteredPagesIds={isActiveFilter ? filteredPagesIds : []}
            isFullWidth={true}
            isLoading={isLayoutLoading}
            metricKey={metricKey as ReportMetricKey}
            period={period}
            periodicity={periodicity}
            reportView={reportView as ReportView}
            selectMetricClick={selectMetricClick}
            setShowTable={handleShowTable}
          />
        </div>
      )}
      {!isActivePeriodLimited && showTable && filteredPagesIds.length > 0 && (
        <div className="report-page-metric__card--pages">
          <ReportPagesContainer
            key={`page_${reportView}`}
            comparisonPeriod={comparisonPeriod}
            filteredPages={filteredPages}
            isLoading={isLayoutLoading}
            metricKey={String(goalKey ?? metricKey)}
            period={period}
            periodicity={periodicity}
            reportView={reportView}
          />
        </div>
      )}
    </div>
  );
}

export default ReportMetric;
