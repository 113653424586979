import './StatusBar.css';

import React from 'react';

interface StatusBarProps {
  children: React.ReactNode;
}

function StatusBar(props: StatusBarProps): React.JSX.Element {
  return <div className="status-bar">{props.children}</div>;
}

export default StatusBar;
