import { useMutation } from '@tanstack/react-query';

import apiClient from '@/apis/semji/apiClient';
import { MutationOptions } from '@/apis/types/mutation.types';
import { CompetitorTopPage } from '@/types/competitors';

interface UsePostExportCompetitorTopPagesProps extends MutationOptions<{}, CompetitorTopPage> {
  reportId: string;
}

export default function usePostExportCompetitorTopPage({
  reportId,
  ...props
}: UsePostExportCompetitorTopPagesProps) {
  const { post } = apiClient;

  return useMutation({
    mutationFn: async (payload) => {
      const result = await post(
        `/competitor_reports/${reportId}/competitor_top_page_export`,
        payload
      );
      return result.data;
    },
    ...props,
  });
}
