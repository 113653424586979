import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import CheckIcon from '@/components/icons/CheckIcon';

const StatusBarWrapper = styled.div`
  && {
    font-size: ${(props) => props.theme.text.sizes.small};
    color: ${(props) => props.theme.text.colors.default};
    display: flex;
    flex: 1;
    background-color: transparent;
    align-items: center;
    padding: 0 5px;
    justify-content: flex-end;
    font-size: 0.8em;
    white-space: nowrap;
    min-height: 20px;

    animation: ${({ isSaving }) =>
      isSaving ? 'none' : '1s ease-in 2s 1 normal forwards running fadeOut '};

    @keyframes fadeOut {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }
`;

function StatusBar({ isSaving }) {
  const { t } = useTranslation();

  if (isSaving) {
    return (
      <StatusBarWrapper isSaving>
        {t('content:editor-components.status-bar.saving')}
      </StatusBarWrapper>
    );
  }
  return (
    <StatusBarWrapper>
      <CheckIcon />
      &nbsp;
      {t('content:editor-components.status-bar.saved')}
    </StatusBarWrapper>
  );
}

StatusBar.propTypes = {
  isSaving: PropTypes.bool,
};

export default StatusBar;
