import getElementDataByAttribute from './getElementDataByAttribute';

function isElementAlreadyHighlightedByDatum({
  element,
  datum,
  dataAttribute,
}: {
  element: HTMLElement;
  datum: string;
  dataAttribute: string;
}): boolean {
  return getElementDataByAttribute({
    dataAttribute,
    element,
  }).includes(datum);
}

export default isElementAlreadyHighlightedByDatum;
