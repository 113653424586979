import React, { useId } from 'react';

function TitleCreditIcon(props) {
  const id = useId();
  return (
    <svg fill="none" height="15" viewBox="0 0 15 15" width="15" {...props}>
      <path
        d="M15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0C11.6421 0 15 3.35786 15 7.5Z"
        fill={`url(#linear0_${id})`}
      />
      <circle cx="7.5" cy="7.5" r="7.19444" stroke={`url(#linear1_${id})`} strokeWidth="0.611111" />
      <g clipPath={`url(#clip_${id})`}>
        <path
          d="M6.14745 6.74236H6.61016V7.66778H6.14745V7.20507H5.22203V10.2771H5.68474V10.7398H4.29661V10.2771H4.75932V7.20507H3.8339V7.66778H3.37119V6.74236H6.14745ZM10.7746 4.2966H6.14745V5.489H6.61016V4.75931H8.46101V10.3118H7.9983V10.7746H9.38643V10.3118H8.92372V4.75931H10.7746V5.489H11.2373V4.2966H10.7746Z"
          fill="white"
          stroke="white"
          strokeWidth="0.561863"
        />
      </g>
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id={`linear0_${id}`}
          x1="3.02908e-05"
          x2="15.0027"
          y1="7.50423"
          y2="7.50423"
        >
          <stop stopColor="#FF4580" />
          <stop offset="1" stopColor="#FF4D64" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id={`linear1_${id}`}
          x1="13.4425"
          x2="1.98413"
          y1="2.57962"
          y2="12.5102"
        >
          <stop stopColor="#CA3B4E" />
          <stop offset="1" stopColor="#CA3B4E" stopOpacity="0" />
        </linearGradient>
        <clipPath id={`clip_${id}`}>
          <rect
            fill="white"
            height="7.86609"
            transform="translate(3.37119 3.37118)"
            width="7.86609"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default TitleCreditIcon;
