import { nanoid } from 'nanoid';
import { useMemo } from 'react';

import SvgWrapper from './SvgWrapper';

function NotificationIcon(props) {
  const svgId = useMemo(() => nanoid(), []);

  return (
    <SvgWrapper {...props}>
      <mask
        height="16"
        id={svgId}
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="14"
        x="1"
        y="0"
      >
        <path
          d="M15.3121 14.125C15.2981 14.112 14.0001 12.831 14.0001 10.915V7.623C14.0001 4.522 11.5321 2 8.50011 2C5.46811 2 3.00011 4.522 3.00011 7.623V10.915C3.00011 12.776 1.69411 14.118 1.68211 14.131L0.839111 14.984H16.1961L15.3121 14.125ZM3.05211 13.984C3.49611 13.292 4.00011 12.22 4.00011 10.915V7.623C4.00011 5.074 6.01911 3 8.50011 3C10.9811 3 13.0001 5.074 13.0001 7.623V10.915C13.0001 12.235 13.4951 13.298 13.9381 13.984H3.05211ZM9.00011 2H8.00011V1H9.00011V2ZM9.00011 15H10.0001C10.0001 15.827 9.32711 16.5 8.50011 16.5C7.67311 16.5 7.00011 15.827 7.00011 15H8.00011C8.00011 15.276 8.22511 15.5 8.50011 15.5C8.77511 15.5 9.00011 15.276 9.00011 15Z"
          fill="black"
        />
      </mask>
      <g mask={`url(#${svgId})`}>
        <rect height="20" width="20" x="-2" y="-1" />
      </g>
    </SvgWrapper>
  );
}

export default NotificationIcon;
