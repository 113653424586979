import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches';

import { CategoryHeader } from '@/containers/UniversalSearch/Ui/CategoryHeader';
import { SearchItem } from '@/containers/UniversalSearch/Ui/SearchItem';

export const recentSearchesPlugin = createLocalStorageRecentSearchesPlugin({
  key: 'RECENT_SEARCH',
  limit: 3,
  transformSource({ source }) {
    return {
      ...source,
      templates: {
        header({ items }) {
          return <CategoryHeader items={items} label="Recent" />;
        },
        item({ item }) {
          return <SearchItem item={item} />;
        },
      },
    };
  },
});
