import { ENUM_CONTENT_TYPE } from '@/types/contents';
import { UserModel as User } from '@/types/user/user.types';

export type VersionType = {
  id: string;
  date: string;
  type: ENUM_CONTENT_TYPE;
  author?: User;
  day: number;
  hour: number;
  month: number;
  year: number;
  name?: string;
  displayDate: string;
  longDisplayDate: string;
  score?: number;
  isCurrentDraft?: boolean;
  color: string;
};

export const TODAY = 'today';
export enum KEYBOARD_NAVIGATE_DIRECTION {
  UP = 'up',
  DOWN = 'down',
}

export interface VersionItemProps {
  version: VersionType;
  onSelectVersion: (id: string) => (e: any) => void;
  onOpen?: () => void;
  onRenameVersion?: (id: string, name: string) => void;
  onTabulate: (direction?: KEYBOARD_NAVIGATE_DIRECTION) => void;
  isSelected: boolean;
  isOpen: boolean;
  canBeOpen: boolean;
  isSubItem?: boolean;
  isPublished: boolean;
  withPadding: boolean;
  enableOptionsMenu?: boolean;
}

export interface DayRenderProps {
  handleSelectVersion: (id: string) => (e: any) => void;
  handleRenameVersion: (id: string, name: string) => void;
  onTabulate: (direction?: KEYBOARD_NAVIGATE_DIRECTION) => void;
  selectedVersionId: string;
  isPublished: boolean;
  versions: VersionType[];
  withPadding: boolean;
  enableOptionsMenu?: boolean;
}

export type GroupedVersionType = {
  [key: string]: VersionType[];
};

export interface GroupedVersionProps {
  date: string;
  versions: VersionType[];
  selectedVersionId: string;
  handleSelectVersion: (id: string) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  handleRenameVersion: (id: string, name: string) => void;
  onTabulate: (direction?: KEYBOARD_NAVIGATE_DIRECTION) => void;
  withPadding: boolean;
  isPublished: boolean;
  enableOptionsMenu?: boolean;
}
