import Highcharts from 'highcharts';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import HighchartsReact from 'highcharts-react-official';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';

import { ChartLegend } from '@/containers/Competitors/Duel/Report/ChartLegend';
import { ENUM_SERIES_CURSOR_TYPE } from '@/containers/Competitors/Duel/Report/constant';
import defaultTheme from '@/themes/defaultTheme';

import { ChartTooltip } from '../ChartTooltip/ChartTooltip';
import ChartUtils from './Chart.utils';
import { DEFAULT_CHART_OPTIONS } from './constants';

NoDataToDisplay(Highcharts);

export function HighChartsLine({
  metricsSeries,
  selectedMetrics,
  categories,
  options,
  onPointClick,
  serieKey = null,
  getCustomFilter,
}) {
  const { t } = useTranslation();

  const OPTIONS = {
    ...DEFAULT_CHART_OPTIONS,
    chart: {
      ...DEFAULT_CHART_OPTIONS.chart,
      type: 'line',
    },
    exporting: {
      enabled: false,
    },
    lang: {
      noData: t('competitors:duel.report.no-data'),
    },
    legend: ChartUtils.getLegendOptions({
      overrideOptions: {
        labelFormatter: function () {
          return ReactDOMServer.renderToStaticMarkup(
            getChartLegend(this.name, this.color, this.visible)
          );
        },
      },
    }),
    plotOptions: {
      series: {
        cursor: onPointClick ? ENUM_SERIES_CURSOR_TYPE.POINTER : ENUM_SERIES_CURSOR_TYPE.UNDEFINED,
        dataLabels: {
          useHTML: true,
        },
        events: {
          click: (e) => onPointClick?.(e, serieKey, getCustomFilter?.(e.point)),
        },
        pointPlacement: 'on',
      },
    },
    tooltip: {
      backgroundColor: 'transparent',
      borderWidth: '0px',
      className: 'competitors-duel-report__chart__tooltip',
      enabled: true,
      formatter: function () {
        return ReactDOMServer.renderToStaticMarkup(
          getChartTooltip({ graphPoints: this.points, selectedMetrics })
        );
      },
      hideDelay: 0,
      outside: true,
      shadow: false,
      shared: true,
      style: {
        color: defaultTheme.cssColors.dark080,
      },
      useHTML: true,
    },
    xAxis: ChartUtils.getXAxisOptions({
      overrideLabelOptions: {
        style: options.xAxisStyle,
      },
      overrideOptions: { categories: categories, type: 'datetime', ...options.xAxis },
    }),
    yAxis: ChartUtils.getYAxisOptions(),
  };

  const series = buildSeries(metricsSeries);

  // Used with ReactDOMServer to custom label
  function getChartLegend(name, color, isVisible) {
    const winner = ChartUtils.computeWinner(series).name;

    return (
      <ChartLegend color={color} isVisible={isVisible} isWinner={winner === name} name={name} />
    );
  }

  // Used with ReactDOMServer to custom chart tooltip
  function getChartTooltip({ graphPoints, selectedMetrics }) {
    return (
      <ChartTooltip
        graphPoints={graphPoints}
        isInHighChartsLine={true}
        selectedMetrics={selectedMetrics}
      />
    );
  }

  function buildSeries(series) {
    return series.map((serie) => {
      return {
        ...serie,
        marker: {
          enabled: false,
        },
      };
    });
  }

  function onClick(e) {
    e.stopPropagation();
  }

  return (
    <div className="competitors-duel-report__chart">
      <div aria-hidden="true" onClick={onClick}>
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            ...OPTIONS,
            series,
          }}
        />
      </div>
    </div>
  );
}
