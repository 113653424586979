import { useMutation } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import apiClient from '@/apis/semji/apiClient';
import { LOCATION, SCOPE_CONTENT_IDEAS } from '@/apis/semji/constants';
import { showErrorSnackbar, showSuccessSnackbar } from '@/store/actions/ui';

export default function useDeleteCurrentWorkspaceContentIdeaSearch() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const workspaceId = useSelector((state) => state.defaultWorkspaceId || '');
  const { delete: deleteRequest } = apiClient;

  return useMutation({
    mutationFn: async (contentIdeaSearchId) =>
      await deleteRequest(`/content_idea_searches/${contentIdeaSearchId}`),

    onError: () => {
      dispatch(showErrorSnackbar(t('content-ideas:menu.error.default')));
    },
    onSuccess: () => {
      queryClient.refetchQueries({
        active: true,
        exact: true,
        fetching: false,
        queryKey: [SCOPE_CONTENT_IDEAS.CONTENT_IDEAS_SEARCHES, workspaceId, LOCATION.CONTENT_IDEAS],
      });
      dispatch(showSuccessSnackbar(t('content-ideas:menu.delete-content-ideas-search')));
    },
  });
}
