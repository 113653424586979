import React from 'react';

import SvgWrapper from './SvgWrapper';

const DeleteAltIcon = (props) => (
  <SvgWrapper {...props} viewBox="0 0 27 27">
    <path
      d="M15.5603 7.33765C15.3679 6.57088 14.6788 6 13.8529 6C13.0306 6 12.3441 6.56735 12.1491 7.33765H9V8.22H18.7059V7.33765H15.5603ZM13.8529 6.88235C14.19 6.88235 14.475 7.07735 14.625 7.33765H13.0959C13.2476 7.07029 13.5238 6.88235 13.8529 6.88235ZM17.3824 9.08824H18.2647V18.3671C18.2647 19.0968 17.6709 19.6906 16.9412 19.6906H10.7647C10.035 19.6906 9.44118 19.0968 9.44118 18.3671V9.08824H10.3235V18.3671C10.3235 18.6097 10.5212 18.8082 10.7647 18.8082H16.9412C17.1847 18.8082 17.3824 18.6097 17.3824 18.3671V9.08824ZM12.9706 9.97059V17.0294H12.0882V9.97059H12.9706ZM15.6176 9.97059V17.0294H14.7353V9.97059H15.6176Z"
      fill="#51525E"
    />
  </SvgWrapper>
);

export default DeleteAltIcon;
