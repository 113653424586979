import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Loader from '@/components/Loader/Loader';
import WarningMessage from '@/components/Message/WarningMessage';
import { PrimaryLink } from '@/components/Navigation/Link';
import { DefaultLink } from '@/components/Navigation/Link';
import { DisableableWrapper, ShowMoreInstructions } from '@/components/Optimization/Instruction';
import Optimization from '@/components/Optimization/Optimization';
import DetailsBase from '@/containers/Content/SidePanel/Components/Optimization/Details/DetailsBase';
import HeatMapDetails from '@/containers/Content/SidePanel/Components/Optimization/Details/HeatMapDetails';
import RelatedTerm from '@/containers/Content/SidePanel/Components/Optimization/Section/RelatedTerms/RelatedTerm';
import { disableMainOptimization, enableMainOptimization } from '@/store/actionsCreator/report';
import { DISPLAY_LIMIT, MAX_DISPLAY_LIMIT } from '@/utils/constants';

class RelatedTerms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabledRelatedTerms: [],
      enabledRelatedTerms: [],
      isListLimited: true,
      showDetails: false,
    };

    this.openDetails = this.openDetails.bind(this);
    this.closeDetails = this.closeDetails.bind(this);
  }

  toggleLimit = () => {
    this.setState({ isListLimited: !this.state.isListLimited });
  };

  toggleRelatedTerm = (key, disabled) => {
    let { enabledRelatedTerms, disabledRelatedTerms } = this.state;

    if (disabled) {
      disabledRelatedTerms = [...disabledRelatedTerms, key];
      enabledRelatedTerms = enabledRelatedTerms.filter((enabledLink) => enabledLink !== key);
    } else {
      enabledRelatedTerms = [...enabledRelatedTerms, key];
      disabledRelatedTerms = disabledRelatedTerms.filter((enabledLink) => enabledLink !== key);
    }

    this.setState({ disabledRelatedTerms, enabledRelatedTerms });
  };

  componentDidMount() {
    this.setStateRecommendations();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.recommendationsIds !== this.props.recommendationsIds) {
      this.setStateRecommendations();
    }
  }

  setStateRecommendations() {
    const { allRecommendations, recommendationsIds } = this.props;
    let enabledRelatedTerms = [];
    let disabledRelatedTerms = [];

    recommendationsIds.forEach((recommendationsId) => {
      if (allRecommendations[recommendationsId].disabled) {
        disabledRelatedTerms.push(recommendationsId);
      } else {
        enabledRelatedTerms.push(recommendationsId);
      }
    });

    this.setState({ disabledRelatedTerms, enabledRelatedTerms });
  }

  disableRecommendation = (key) =>
    this.props.disableMainOptimization(
      key,
      this.props.focusTopKeywordId,
      this.props.html,
      this.props.contentTitle
    );

  enableRecommendation = (key) =>
    this.props.enableMainOptimization(
      key,
      this.props.focusTopKeywordId,
      this.props.html,
      this.props.contentTitle
    );

  onAddOptimization = (optimization) => {
    const { editorRef } = this.props;
    if (editorRef.current.isSemjiContentReadOnly) {
      return;
    }

    editorRef.current.focus();
    const currentNode = editorRef.current.selection.getEnd();

    if (['H1', 'H2', 'H3', 'H4', 'H5', 'H6'].includes(currentNode.tagName)) {
      const node = editorRef.current.dom.create('p', {}, optimization);
      editorRef.current.dom.insertAfter(node, currentNode);
      node.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      editorRef.current.selection.select(node, true);
    } else {
      const node = editorRef.current.dom.create('p', {}, optimization);
      const isEmptyParagraph =
        currentNode?.tagName === 'P' &&
        currentNode?.childNodes.length === 1 &&
        currentNode?.childNodes[0]?.tagName === 'BR';
      const isInsideEmptyParagraph =
        currentNode?.tagName === 'BR' && currentNode?.parentNode.tagName;

      // When selection is on a <br> with a parent, we insert after the parent
      if (isInsideEmptyParagraph) {
        editorRef.current.dom.insertAfter(node, currentNode.parentNode);
        editorRef.current.dom.remove(currentNode.parentNode);

        // When selection is on a <p><br></p>, we remove this empty paragraph
      } else if (isEmptyParagraph) {
        editorRef.current.dom.insertAfter(node, currentNode);
        currentNode.remove();
        editorRef.current.selection.select(node, true);
      } else {
        currentNode.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
        editorRef.current.dom.setHTML(
          currentNode,
          `${currentNode.innerHTML} ${optimization}`.trim()
        );
        if (currentNode?.tagName === 'TD' && currentNode?.childNodes[0]?.tagName === 'BR') {
          currentNode?.childNodes[0]?.remove();
        }
        editorRef.current.selection.select(currentNode, true);
      }
    }

    editorRef.current.selection.collapse(false);
    editorRef.current.setDirty(true);
    setTimeout(() => {
      editorRef.current?.undoManager?.add?.();
    });
  };

  renderDisplayedRelatedTerms(limit) {
    const instructions = [...this.state.enabledRelatedTerms, ...this.state.disabledRelatedTerms];

    return instructions
      .map((instructionId, index) => (
        <RelatedTerm
          key={`${instructionId}_${index}`}
          competitors={this.props.data.competitors}
          focusTopKeywordId={this.props.focusTopKeywordId}
          id={instructionId}
          isReadOnly={this.props.isReadOnly}
          mainId={this.props.id}
          onAddRelatedTerm={this.onAddOptimization}
          onToggleRelatedTerm={this.toggleRelatedTerm}
        />
      ))
      .slice(0, limit);
  }

  openDetails() {
    this.setState({ showDetails: true });
  }

  closeDetails() {
    this.setState({ showDetails: false });
  }

  render() {
    if (this.props.pageId === null) {
      return <Loader />;
    }

    const {
      score,
      weight,
      keyword,
      recommendationsIds,
      disabled,
      data,
      allRecommendations,
      t,
      html,
      pageId,
      focusTopKeywordId,
      disableMainOptimization,
      enableMainOptimization,
      contentTitle,
      ready,
      ...props
    } = this.props;
    const limitedInstructions = recommendationsIds.slice(0, DISPLAY_LIMIT);
    const displayableInstructions = recommendationsIds.slice(0, MAX_DISPLAY_LIMIT);
    const limit = this.state.isListLimited ? DISPLAY_LIMIT : MAX_DISPLAY_LIMIT;
    const leftInstructionsCount = displayableInstructions.length - limitedInstructions.length;
    const clusteringDetailLink = `${
      import.meta.env.VITE_REACT_APP_SEMJI_BACKOFFICE_URL
    }/clustering-details/p/${pageId}/tk/${focusTopKeywordId}/topics`;
    const isEmpty =
      ready && this.state.enabledRelatedTerms.length + this.state.disabledRelatedTerms.length === 0;

    return (
      <>
        <Optimization
          description={
            <Trans
              components={{
                extLink: (
                  <DefaultLink
                    color="dark040"
                    decoration
                    isExternal
                    noDynamicFontSize
                    noPadding
                    size="xsm"
                    to={t('common:links.help-related-terms')}
                    weight="medium"
                  />
                ),
              }}
              i18nKey="content:side-panel-components.optimization.section.related-terms.description"
            />
          }
          disabled={disabled}
          disableRecommendation={this.disableRecommendation}
          enableRecommendation={this.enableRecommendation}
          helpCenterLink={t('common:links.help-related-terms')}
          infoMessage={t('components:optimization.optimization.no-related-terms-message', {
            keyword,
          })}
          infoText={t('content:side-panel-components.optimization.section.related-terms.info-text')}
          instructions={[
            <DisableableWrapper key="related-terms" disabled={disabled}>
              {this.state.enabledRelatedTerms.length + this.state.disabledRelatedTerms.length <
                10 && (
                <Trans
                  components={{
                    pLink: (
                      <PrimaryLink
                        isExternal
                        noDecoration
                        size="sm"
                        to={t('common:links.help-no-recommendations')}
                        weight="strong"
                      />
                    ),
                    warning: <WarningMessage />,
                  }}
                  i18nKey="content:side-panel-components.optimization.section.related-terms.instruction-warning"
                />
              )}
              {this.renderDisplayedRelatedTerms(limit)}
            </DisableableWrapper>,
            <ShowMoreInstructions
              key="moreRelatedTerms"
              hide={recommendationsIds.length <= DISPLAY_LIMIT}
              toggle={this.toggleLimit}
            >
              {this.state.isListLimited
                ? t(
                    'content:side-panel-components.optimization.section.related-terms.toggle-more-text_interval',
                    {
                      count: leftInstructionsCount,
                      postProcess: 'interval',
                    }
                  )
                : t(
                    'content:side-panel-components.optimization.section.related-terms.toggle-less-text'
                  )}
            </ShowMoreInstructions>,
          ]}
          isEmpty={isEmpty}
          isReady={ready}
          loaderRows={1}
          points={Math.round((weight - weight * score) * 100)}
          score={score}
          scorer={`${data.foundTopicCount}/${data.topicGoalCount}`}
          scorerTooltipText={t(
            'content:side-panel-components.optimization.section.related-terms.title',
            {
              topicGoalCount: data.topicGoalCount,
            }
          )}
          showDetailsAction={this.openDetails}
          showExternalLinkToBackOffice={{
            title: t(
              'content:side-panel-components.optimization.section.related-terms.external-link-title'
            ),
            url: clusteringDetailLink,
          }}
          title={t('content:side-panel-components.optimization.section.related-terms.title')}
          {...props}
        />
        <DetailsBase
          description={t(
            'content:side-panel-components.optimization.section.related-terms.details-description',
            { keyword }
          )}
          show={this.state.showDetails}
          subTitle={t(
            'content:side-panel-components.optimization.section.related-terms.details-subtitle'
          )}
          title={t('content:side-panel-components.optimization.section.related-terms.title')}
          onClose={this.closeDetails}
        >
          <HeatMapDetails
            competitors={data.competitors}
            keyword={keyword}
            recommendations={(recommendationsIds || [])
              .filter((instructionId) => !allRecommendations[instructionId].disabled)
              .map((instructionId) => allRecommendations[instructionId])}
            termKey="relatedTerms"
            title={t('content:side-panel-components.optimization.section.related-terms.topics')}
          />
        </DetailsBase>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    allRecommendations: state.report.recommendations,
    contentTitle: state.content.title,
    data: state.report.recommendations[props.id]?.data,
    disabled: state.report.recommendations[props.id]?.disabled,
    html: state.content.html,
    ready: state.report.recommendations[props.id]?.ready,
    recommendationsIds: state.report.recommendations[props.id]?.recommendationsIds,
    score: state.report.recommendations[props.id]?.score,
    weight: state.report.recommendations[props.id]?.weight,
  };
};

export default withTranslation()(
  connect(mapStateToProps, {
    disableMainOptimization,
    enableMainOptimization,
  })(RelatedTerms)
);
