import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { FlexCenteredAlignContainer, FlexColumnContainer } from '@/components/_common';
import { SecondaryLink } from '@/components/Navigation/Link';
import Ellipsis from '@/components/Text/Ellipsis';
import { BrightText } from '@/components/Text/Light';

const Wrapper = styled.div`
  padding: 1em 0em 0em 0em;
`;
const StyledEllipsis = styled(Ellipsis)`
  && {
    padding: 0.3em 0;
  }
`;

function LinkDetails({ displayDescription = false, id }) {
  const link = useSelector((state) => state.report.recommendations[id]);

  return (
    <Wrapper>
      <FlexCenteredAlignContainer>
        <FlexColumnContainer>
          <div>
            <SecondaryLink isExternal small to={link.data.url}>
              <StyledEllipsis>{link.data.title}</StyledEllipsis>
            </SecondaryLink>
          </div>
          {displayDescription && (
            <BrightText dangerouslySetInnerHTML={{ __html: link.data.description }} size="micro" />
          )}
        </FlexColumnContainer>
      </FlexCenteredAlignContainer>
    </Wrapper>
  );
}

export default LinkDetails;
