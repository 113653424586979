import { METRICS_CONFIG_COMPARISON_PERIOD_PREVIOUS_YEAR } from './constants';
import { getPeriodRangeById } from './getPeriodById';

export const getPreviousDateRangeGapDuration = (period, comparisonPeriod) => {
  if (comparisonPeriod === METRICS_CONFIG_COMPARISON_PERIOD_PREVIOUS_YEAR) {
    return { years: 1 };
  }

  return getPeriodRangeById(period).duration;
};
