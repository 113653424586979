/* eslint-disable sort-keys-fix/sort-keys-fix */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Loader from '@/components/Loader/Loader';
import { StretchTabBodyContent } from '@/components/Navigation/Tabs';
import Credits from '@/components/Settings/CreditsSettings';
import useApiConfigurations from '@/hooks/useApiConfigurations';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import OrganizationService from '@/services/Organization';
import {
  CONTENT_AI_WRITING_CREDITS_ENABLED,
  CONTENT_IDEAS_CREDITS_ENABLED,
} from '@/utils/configurations/constants';
import {
  AI_WRITING_CREDITS_TYPE,
  ANALYSIS_CREDITS_TYPE,
  CONTENT_IDEAS_SEARCH_CREDITS_TYPE,
} from '@/utils/constants';

const AVAILABLE_TYPES = {
  analysis: {
    key: ANALYSIS_CREDITS_TYPE,
    label: 'Analyses',
    slug: 'analysis',
  },
  'ai-writing': {
    key: AI_WRITING_CREDITS_TYPE,
    label: 'AI+ Content',
    slug: 'ai-writing',
  },
  'content-ideas': {
    key: CONTENT_IDEAS_SEARCH_CREDITS_TYPE,
    label: 'Contents ideas',
    slug: 'content-ideas',
  },
};

function CreditsSettings({ title }) {
  const { creditType, organizationId } = useParams();
  const [creditsState, setCreditsState] = useState({
    credits: [],
    loading: true,
  });
  const { isFeatureEnabled: hasUnlimitedAnalysisCredits } = useOrganizationFeatureSet(
    'credits:analysis:has-unlimited-amount'
  );
  const { isFeatureEnabled: hasUnlimitedContentIdeas } = useOrganizationFeatureSet(
    'credits:content-ideas-searches:has-unlimited-amount'
  );
  const { isFeatureEnabled: hasUnlimitedAIWriting } = useOrganizationFeatureSet(
    'credits:ai-writing:has-unlimited-amount'
  );
  const contentIdeasCreditsEnabled = useApiConfigurations(CONTENT_IDEAS_CREDITS_ENABLED);
  const aiWritingCreditsEnabled = useApiConfigurations(CONTENT_AI_WRITING_CREDITS_ENABLED);

  const getCredits = async () => {
    const _OrganizationService = new OrganizationService(organizationId);
    const creditsLog = await _OrganizationService.creditsUsage;

    setCreditsState({ credits: creditsLog, loading: false });
  };

  useEffect(() => {
    getCredits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  if (creditsState.loading) {
    return (
      <StretchTabBodyContent>
        <Loader />
      </StretchTabBodyContent>
    );
  }

  if (false === contentIdeasCreditsEnabled) {
    delete AVAILABLE_TYPES['content-ideas'];
  }

  if (false === aiWritingCreditsEnabled) {
    delete AVAILABLE_TYPES['ai-writing'];
  }

  return (
    <StretchTabBodyContent>
      <Credits
        credits={creditsState.credits.filter(
          (credit) =>
            Object.keys(AVAILABLE_TYPES).includes(creditType) &&
            credit.type === AVAILABLE_TYPES[creditType].key
        )}
        defaultSort="createdAt"
        modeUnlimitedAIWriting={hasUnlimitedAIWriting}
        modeUnlimitedAnalysis={hasUnlimitedAnalysisCredits}
        modeUnlimitedContentIdeas={hasUnlimitedContentIdeas}
        title={title}
        types={AVAILABLE_TYPES}
      />
    </StretchTabBodyContent>
  );
}

export default CreditsSettings;
