import { useOutletContext } from 'react-router-dom';

import { PageWithMetrics } from '@/containers/Report/utils/types';
export type ReportCountType = {
  new: number;
  existing: number;
};

export enum ReportQueryFiltersOperator {
  AND = 'AND',
  OR = 'OR',
}

export type ReportQueryFilters = {
  operator: ReportQueryFiltersOperator;
  filterGroups: {
    filters: any[];
    operator: ReportQueryFiltersOperator;
  }[];
};

export type ReportContext = {
  filteredPagesIds: string[];
  filteredPages: PageWithMetrics[];
  currentCount: ReportCountType;
  setCurrentCount: (count: ReportCountType) => void;
  isLoading: boolean;
  filters: ReportQueryFilters;
};

export function useReport() {
  return useOutletContext<ReportContext>();
}
