import MenuItem from '@material-ui/core/MenuItem';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { DefaultMenu } from '@/components/_common';
import HelpAltIcon from '@/components/icons/HelpAltIcon';
import HeaderButton from '@/components/Layout/HeaderButton';
import { TertiaryLink } from '@/components/Navigation/Link';
import useCan from '@/hooks/useCan';
import { useMixpanelTrackEvent } from '@/hooks/useMixpanelTrackEvent';
import {
  CONTACT_US_TOP_NAV_CLICK,
  HELP_CENTER_TOP_NAV_CLICK,
  QUICKSTART_GUIDE_TOP_NAV_CLICK,
} from '@/utils/3rdParty/Mixpanel/constants';
import { isRouteAccessGranted } from '@/utils/billing';

function HelperMenu() {
  const { t } = useTranslation();
  const { organizationId, workspaceId } = useParams();
  const [helperMenuAnchorEl, setHelperMenuAnchorEl] = useState(null);
  const currentOrganization = useSelector((state) => state.organizations.byId[organizationId]);
  const canAccessToOnboarding = useCan({ perform: 'onboarding-workspace:visit' });
  const trackMixpanelEvent = useMixpanelTrackEvent();

  function handleCloseMenu() {
    setHelperMenuAnchorEl(null);
  }

  function handleClickMenu(e) {
    setHelperMenuAnchorEl(e.currentTarget);
  }

  return (
    <>
      <HeaderButton
        aria-haspopup="true"
        aria-label={t('layout:helper-menu.label-help')}
        onClick={handleClickMenu}
      >
        <HelpAltIcon />
        <span title={t('layout:helper-menu.label-help')}>{t('layout:helper-menu.label-help')}</span>
      </HeaderButton>
      <DefaultMenu
        anchorEl={helperMenuAnchorEl}
        open={!!helperMenuAnchorEl}
        onClose={handleCloseMenu}
      >
        {canAccessToOnboarding && isRouteAccessGranted(currentOrganization) && (
          <MenuItem onClick={handleCloseMenu}>
            <TertiaryLink
              to={`/o/${organizationId}/w/${workspaceId}/onboarding`}
              onClick={() => trackMixpanelEvent(QUICKSTART_GUIDE_TOP_NAV_CLICK)}
            >
              {t('layout:helper-menu.quick-start-guide')}
            </TertiaryLink>
          </MenuItem>
        )}
        <MenuItem onClick={handleCloseMenu}>
          <TertiaryLink
            isExternal
            to={t('common:links.help-home')}
            onClick={() => trackMixpanelEvent(HELP_CENTER_TOP_NAV_CLICK)}
          >
            {t('layout:helper-menu.help-center')}
          </TertiaryLink>
        </MenuItem>
        <MenuItem onClick={handleCloseMenu}>
          <TertiaryLink
            isExternal
            to={t('common:links.contact-us')}
            onClick={() => trackMixpanelEvent(CONTACT_US_TOP_NAV_CLICK)}
          >
            {t('layout:helper-menu.contact-us')}
          </TertiaryLink>
        </MenuItem>
      </DefaultMenu>
    </>
  );
}

export default HelperMenu;
