import React from 'react';
import styled from 'styled-components/macro';

import InfoIcon from '@/components/icons/InfoIcon';

const Wrapper = styled.div`
  display: flex;
  font-size: ${(props) => props.theme.textCss.sizes.sm};
  line-height: ${(props) => props.theme.textCss.sizes.default};
  font-weight: ${(props) => props.theme.textCss.weights.medium};
  color: ${(props) => props.theme.cssColors[props.color ?? 'dark060']};
  align-items: center;
  justify-content: ${({ halign }) =>
    halign === 'left' ? 'flex-start' : halign === 'right' ? 'flex-end' : 'center'};
  background-color: ${(props) => props.theme.cssColors[props.bgColor ?? 'primary010']};
  padding: ${({ padding }) => padding || '0.5rem 0'};
  white-space: pre-line;
`;
const StyledInfoIcon = styled(InfoIcon)`
  && {
    color: ${(props) => props.theme.cssColors.primary};
    margin: 0 1rem;
    font-size: 0.6rem;
    display: ${({ $hide }) => $hide && 'none'};
  }
`;

export default function FlashMessage({ bgColor, children, color, halign, hideIcon, padding }) {
  return (
    <Wrapper bgColor={bgColor} color={color} halign={halign} padding={padding}>
      <StyledInfoIcon $hide={hideIcon} bordered />
      {children}
    </Wrapper>
  );
}
