import React from 'react';
import styled, { css } from 'styled-components/macro';

export const getBlockquoteStyle = () => {
  return css`
    display: block;
    border-left: 6px solid rgba(211, 212, 215, 1);
    font-style: italic;
    padding: 0 34px;
    margin: 1em 0;
    line-height: 1.5em;
  `;
};

export const getCodeStyle = () => {
  return css`
    font-family: monospace;
    line-height: 1.5em;
  `;
};

export const getUlStyle = () => {
  return css`
    display: block;
    list-style-type: disc;
    margin-bottom: 1em;
    padding-left: 40px;
    line-height: 1.5em;
  `;
};

export const getH1Style = () => {
  return css`
    display: block;
    color: rgba(0, 0, 0, 0.8);
    font-size: 24px;
    font-weight: 500;
    margin: 1em 0 0;
    line-height: 1.5em;
  `;
};

export const getH2Style = () => {
  return css`
    display: block;
    color: rgba(0, 0, 0, 0.8);
    font-size: 19px;
    font-weight: 500;
    margin: 1em 0 0;
    line-height: 1.5em;
  `;
};

export const getH3Style = () => {
  return css`
    display: block;
    color: rgba(0, 0, 0, 0.8);
    font-size: 17px;
    font-weight: 500;
    margin: 1em 0 0;
    line-height: 1.5em;
  `;
};

export const getH4Style = () => {
  return css`
    display: block;
    color: rgba(0, 0, 0, 0.8);
    margin: 1em 0 0;
    font-weight: 500;
    line-height: 1.5em;
  `;
};

export const getH5Style = () => {
  return css`
    display: block;
    color: rgba(0, 0, 0, 0.8);
    margin: 1em 0 0;
    font-weight: 500;
    line-height: 1.5em;
  `;
};

export const getH6Style = () => {
  return css`
    display: block;
    color: rgba(0, 0, 0, 0.8);
    margin: 1em 0 0;
    font-weight: 500;
    line-height: 1.5em;
  `;
};

export const getLiStyle = () => {
  return css`
    display: list-item;
    text-align: match-parent;
    line-height: 1.5em;
    font-size: 15px;
  `;
};

export const getOlStyle = () => {
  return css`
    display: block;
    list-style-type: decimal;
    margin-bottom: 1em;
    padding-left: 40px;
    line-height: 1.5em;
  `;
};

export const getLineStyle = () => {
  return css`
    line-height: 1.5em;
  `;
};

export const getAStyle = () => {
  return css`
    color: #4387f4;
    line-height: 1.5em;
    text-decoration: underline;
    cursor: ${(props) => (props.ctrlPressed || props.readOnly ? 'pointer' : 'inherit')};
  `;
};

export const getImgStyle = () => {
  return css`
    max-width: 100%;
  `;
};

export const getSpanStyle = () => {
  return css`
    line-height: 1.5em;
  `;
};

export const getStrongStyle = () => {
  return css`
    font-weight: 500;
    line-height: 1.5em;
  `;
};

export const getBoldStyle = () => {
  return css`
    font-weight: 500;
    line-height: 1.5em;
  `;
};

export const getEmStyle = () => {
  return css`
    font-style: italic;
    line-height: 1.5em;
  `;
};

export const getUStyle = () => {
  return css`
    text-decoration: underline;
    line-height: 1.5em;
    font-size: 15px;
  `;
};

export const getPStyle = () => {
  return css`
    display: block;
    margin: 1em 0 0 0;
    line-height: 1.5em;
    font-size: 15px;
  `;
};

export const getDivStyle = () => {
  return css`
    line-height: 1.5em;
    font-size: 15px;
  `;
};

export const getTableStyle = () => {
  return css`
    th {
      background: #f7f6f3;
      font-weight: 500;
    }
  `;
};

export const Blockquote = styled.blockquote`
  ${() => getBlockquoteStyle()};
`;

export const Code = styled.code`
  ${() => getCodeStyle()};
`;

export const Ul = styled.ul`
  ${() => getUlStyle()};
`;

export const H1 = styled.h6`
  ${() => getH1Style()};
`;

export const H2 = styled.h2`
  ${() => getH2Style()};
`;

export const H3 = styled.h3`
  ${() => getH3Style()};
`;

export const H4 = styled.h4`
  ${() => getH4Style()};
`;

export const H5 = styled.h5`
  ${() => getH5Style()};
`;

export const H6 = styled.h6`
  ${() => getH6Style()};
`;

export const Li = styled.li`
  ${() => getLiStyle()};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    display: inline-block;
  }
`;

export const Ol = styled.ol`
  ${() => getOlStyle()};
`;

export const Line = styled.div`
  ${() => getLineStyle()};
`;

export const A = styled(({ ctrlPressed, readOnly, children, ...props }) => (
  <a {...props}>{children}</a>
))`
  ${() => getAStyle()};
`;

export const Img = styled.img`
  ${() => getImgStyle()};
`;

export const Span = styled.span`
  ${() => getSpanStyle()};
`;

export const Strong = styled.strong`
  ${() => getStrongStyle()};
`;

export const Bold = styled.strong`
  ${() => getBoldStyle()};
`;

export const Em = styled.em`
  ${() => getEmStyle()};
`;

export const U = styled.u`
  ${() => getUStyle()};
`;

export const P = styled.p`
  ${() => getPStyle()};
`;

export const Div = styled.div`
  ${() => getDivStyle()};
`;

export const Figure = styled.figure``;

export const FigCaption = styled.figcaption``;

export const Br = styled.br``;

const Collapsed = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  line-height: 1.5em;

  &::before {
    background: ${(props) => props.theme.colors.lightGrey};
    border-radius: 1em;
    padding: 0.4em 1em;
    color: ${(props) => props.theme.colors.grey};
    font-size: ${(props) => props.theme.text.sizes.micro};
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.1em;
  }

  &:after {
    content: '';
    display: flex;
    border-bottom: 1px dashed ${(props) => props.theme.colors.lightGrey};
    flex-grow: 1;
  }
`;

export const Script = styled(Collapsed)`
  &::before {
    content: '<script />';
  }
`;

export const NoScript = styled(Collapsed)`
  &::before {
    content: '<noscript />';
  }
`;

export const Style = styled(Collapsed)`
  &::before {
    content: '<style />';
  }
`;
