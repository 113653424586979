import { useMutation } from '@tanstack/react-query';

import apiClient from '@/apis/semji/apiClient';
import { setRefreshTokenCookie, setTokenCookie } from '@/utils/cookies/cookies';

export default function usePutRegistrant({
  email,
  firstName,
  languageCode,
  lastName,
  password,
  registrantToken,
  username,
  onSuccess,
  ...props
}) {
  const { put } = apiClient;

  return useMutation({
    mutationFn: () =>
      put(`/registrants/${registrantToken}`, {
        email,
        firstName,
        languageCode,
        lastName,
        password,
        username,
      }),

    ...props,
    onSuccess: async (res) => {
      const { data = {} } = res;
      const { token, refreshToken, refreshTokenExpiration } = data;
      setTokenCookie(token);
      setRefreshTokenCookie({
        refreshToken,
        refreshTokenExpiration,
      });

      await onSuccess?.(res);
    },
  });
}
