import {
  disableOptimization,
  enableOptimization,
  fetchTopKeywordReport,
  refreshInternalLinksToOptimization,
} from '@/apis/semji/api';
import Recommendations from '@/services/Recommendations';
import {
  openCreditLimitDialog,
  refreshCurrentReport,
  toggleCurrentReportMainRecommendations,
  toggleCurrentReportRecommendation,
} from '@/store/actions/report';
import { showErrorSnackbar } from '@/store/actions/ui';
import { SECTIONS } from '@/utils/log/constants';
import { Log } from '@/utils/log/Log';

import { fetchOrganization } from './organization';

/**
 * refreshing the report after disabling/enabling a Topic
 *
 * @param {object} topKeyword
 * @param {object} content
 */
const refreshReport =
  (topKeywordId, html, title, initialSet = false) =>
  async (dispatch) => {
    if (topKeywordId) {
      try {
        const report = await fetchTopKeywordReport(topKeywordId, { html, title });
        dispatch(refreshCurrentReport(report, initialSet));
      } catch (e) {}
    }
  };

/**
 * enable the  main recommendation and refresh report to get the new scores
 * revert the toggle on server error
 *
 * @param {string} optimizationKey
 * @param {string} topKeywordId
 * @param {string} html
 * @param {string} title
 */
export const enableMainOptimization =
  (optimizationKey, topKeywordId, html, title) => async (dispatch) => {
    try {
      dispatch(toggleCurrentReportMainRecommendations(optimizationKey));
      await enableOptimization(topKeywordId, optimizationKey);
      dispatch(refreshReport(topKeywordId, html, title));
    } catch (e) {
      // revert on error
      dispatch(toggleCurrentReportMainRecommendations(optimizationKey));
      Log.warn('error: ', JSON.stringify(e));
    }
  };

/**
 * disable the  main recommendation and refresh report to get the new scores
 * revert the toggle on server error
 *
 * @param {string} optimizationKey
 * @param {string} topKeywordId
 * @param {string} html
 * @param {string} title
 */
export const disableMainOptimization =
  (optimizationKey, topKeywordId, html, title) => async (dispatch) => {
    try {
      dispatch(toggleCurrentReportMainRecommendations(optimizationKey));
      await disableOptimization(topKeywordId, optimizationKey);
      dispatch(refreshReport(topKeywordId, html, title));
    } catch (e) {
      // revert on server error
      dispatch(toggleCurrentReportMainRecommendations(optimizationKey));
      Log.warn('error: ', JSON.stringify(e));
    }
  };

export const refreshLinksToOptimization = (topKeywordId, html, title) => async (dispatch) => {
  try {
    await refreshInternalLinksToOptimization(topKeywordId);
    const report = await fetchTopKeywordReport(topKeywordId, { html, title });
    await dispatch(refreshCurrentReport(report));
    return Promise.resolve();
  } catch (e) {
    return Promise.reject(e);
  }
};

/**
 * enable the recommendations and refresh the report on success
 *
 * @param {*} optimizationKey
 * @param {*} mainKey
 * @param {*} topKeywordId
 * @param {*} html
 * @param {*} title
 */
export const enableRecommendationOptimization =
  (optimizationKey, mainKey, topKeywordId, html, title) => async (dispatch) => {
    try {
      dispatch(toggleCurrentReportRecommendation(mainKey, optimizationKey));
      await enableOptimization(topKeywordId, optimizationKey);
      // after enabling a locked recommendation, we need to reset the tree so the enabled recommendation disappear from the list
      dispatch(refreshReport(topKeywordId, html, title, true));
    } catch (e) {
      // revert on error except 404, that means the optimization had already been enabled
      if (e.status !== 404) {
        dispatch(toggleCurrentReportRecommendation(mainKey, optimizationKey));
      }
      Log.warn('error: ', JSON.stringify(e));
    }
  };

/**
 * disable the recommendations and refresh the report on success
 *
 * @param {*} optimizationKey
 * @param {*} mainKey
 * @param {*} topKeywordId
 * @param {*} html
 * @param {*} title
 *
 */
export const disableRecommendationOptimization =
  (optimizationKey, mainKey, topKeywordId, html, title) => async (dispatch) => {
    try {
      dispatch(toggleCurrentReportRecommendation(mainKey, optimizationKey));
      await disableOptimization(topKeywordId, optimizationKey);
      dispatch(refreshReport(topKeywordId, html, title));
    } catch (e) {
      // revert on error except 409, that means the optimization had already been disabled
      if (e.status !== 409) {
        dispatch(toggleCurrentReportRecommendation(mainKey, optimizationKey));
      }
      Log.warn('error: ', JSON.stringify(e));
    }
  };

export const unlockRecommendations = (keywordId, type, amount) => async (dispatch, getState) => {
  const { html, title } = getState().content || {};

  try {
    const recommendationsService = new Recommendations(keywordId);
    await recommendationsService.unlock(type, amount);
  } catch (e) {
    if (e.status === 403) {
      dispatch(openCreditLimitDialog(type));
    } else {
      Log.report({
        context: 'unlockRecommendations',
        error: e,
        extra: 'Action to unlock new Recommendations',
        section: SECTIONS.report.key,
      });
      dispatch(showErrorSnackbar('Something went wrong. Please retry later.'));
    }
  } finally {
    await dispatch(refreshReport(keywordId, html, title, true));

    const { defaultOrganizationId } = getState();

    // Update credits
    await dispatch(fetchOrganization(defaultOrganizationId));
    return Promise.resolve();
  }
};
