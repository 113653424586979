import { COMPETITORS_METRIC_TYPES } from '@/containers/Competitors/utils/constants';

export const EVOLUTION_REPORT_COLORS = {
  [COMPETITORS_METRIC_TYPES.page]: ['#FFC3CB', '#F97D7D', '#CA3B4E'],
  [COMPETITORS_METRIC_TYPES.traffic]: ['#ABC0ED', '#5682DC', '#1F4594'],
  [COMPETITORS_METRIC_TYPES.unbranded]: ['#FFDAA5', '#FFAC34', '#C5762E'],
};

export const BRAND_REPORT_COLORS = {
  'branded-traffic': '#1F4594',
  'unbranded-traffic': '#FFAC34',
};

export const TAIL_REPORT_COLORS = {
  'long-tail-traffic': '#FFAC34',
  'short-tail-traffic': '#1F4594',
};

export const PAGE_TYPE_AND_PERSONA_REPORT_COLORS = [
  '#1F4594',
  '#FFAC34',
  '#2FD3A2',
  '#F97D7D',
  '#CB4897',
  '#ACD731',
  '#4D90F4',
  '#33EEDD',
  '#ABAEED',
  '#EAD302',
  '#6DC1F0',
];

export const KEYWORD_POSITION_REPORT_COLORS = {
  top10: '#FFAC34',
  top100: '#2FD3A2',
  top3: '#1F4594',
};

// clusters
export const CLUSTERS_REPORT_COLORS = [
  '#1F4594',
  '#19AFCC',
  '#2FD3A2',
  '#EAD302',
  '#FFAC34',
  '#F97D7D',
  '#CB4897',
];

export const FUNNEL_REPORT_COLORS = {
  awareness: '#1F4594',
  consideration: '#FFAC34',
  conversion: '#2FD3A2',
  loyalty: '#F97D7D',
  misc: '#CB4897',
};
