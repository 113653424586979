import {
  CHANGE_UI_MODE,
  SNACKBAR_CLEAR,
  SNACKBAR_DEFAULT,
  SNACKBAR_ERROR,
  SNACKBAR_HINT,
  SNACKBAR_INFO,
  SNACKBAR_SUCCESS,
  SNACKBAR_WARNING,
} from './actionTypes';

export const toggleUiMode = (isUserSuperAdmin) => ({
  isUserSuperAdmin,
  type: CHANGE_UI_MODE,
});

export const showSuccessSnackbar = (message, actions = []) => ({
  actions,
  message,
  type: SNACKBAR_SUCCESS,
});

export const showInfoSnackbar = (message, actions = []) => ({
  actions,
  message,
  type: SNACKBAR_INFO,
});

export const showWarningSnackbar = (message, actions = []) => ({
  actions,
  message,
  type: SNACKBAR_WARNING,
});

export const showErrorSnackbar = (message, actions = []) => ({
  actions,
  message,
  type: SNACKBAR_ERROR,
});

export const showHintSnackbar = (message, actions = []) => ({
  actions,
  message,
  type: SNACKBAR_HINT,
});

export const showSnackbar = (message, actions = []) => ({
  actions,
  message,
  type: SNACKBAR_DEFAULT,
});

export const clearSnackbar = () => ({ type: SNACKBAR_CLEAR });
