import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getRoundedNumberObject } from 'semji-core/utils/numbers';
import styled, { withTheme } from 'styled-components/macro';

import { FlexCenteredAlignContainer } from '@/components/_common';
import FileIcon from '@/components/icons/FileIcon';
import LoopIcon from '@/components/icons/LoopIcon';
import SearchIcon from '@/components/icons/SearchIcon';
import { TertiaryLink } from '@/components/Navigation/Link';
import NumberCell from '@/components/Table/Cell/NumberCell';
import { Title, TitleWrapper } from '@/components/Table/Cell/TitleCell';
import TooltipComponent from '@/components/Tooltip/Tooltip';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 80%;
  color: ${(props) => props.theme.text.colors.light};
  padding-bottom: 0.25em;
  padding-top: 0.25em;
  font-size: 0.85em;
  & > * {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
const FocusKeywordWrapper = styled.span`
  padding-right: 7px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80%;
  padding-bottom: 3px;
`;

const TitleWithKeywordCell = ({
  title,
  workspaceId,
  organizationId,
  pageId,
  contentId,
  theme,
  focusKeyword,
  searchVolume,
  isNewContent,
  isUpdatedContent,
  ...props
}) => {
  const userLanguageCode = useSelector((state) => state.user?.languageCode);

  const path = `/o/${organizationId}/w/${workspaceId}/p/${pageId}/content/${contentId}`;
  const numberObject = getRoundedNumberObject({
    locale: userLanguageCode,
    number: searchVolume,
  });
  const { t } = useTranslation();

  return (
    <TitleWrapper>
      <FlexCenteredAlignContainer>
        {isNewContent && (
          <span title="New Content">
            <FileIcon style={{ fontSize: '0.8em', padding: '0 5px 0 0' }} />
          </span>
        )}
        {isUpdatedContent && (
          <span title="Updated Content">
            <LoopIcon style={{ fontSize: '0.8em', padding: '0 5px 0 0' }} />
          </span>
        )}
        <Title title={title || t('common:labels.unknown-title')}>
          <TertiaryLink size="default" to={path}>
            {title || t('common:labels.unknown-title')}
          </TertiaryLink>
        </Title>
      </FlexCenteredAlignContainer>
      <FlexWrapper>
        {!!focusKeyword && (
          <TooltipComponent
            description={`Search Volume: ${
              searchVolume ? numberObject.value + numberObject.suffix : '-'
            }`}
            style={{ alignItems: 'center', display: 'flex' }}
            title={`Focus Keyword: ${focusKeyword}`}
          >
            <FocusKeywordWrapper>{focusKeyword}</FocusKeywordWrapper>
            <SearchIcon viewBox="0 0 20 20" />
            &nbsp;
            <NumberCell number={searchVolume} />
          </TooltipComponent>
        )}
      </FlexWrapper>
    </TitleWrapper>
  );
};

export default withTheme(TitleWithKeywordCell);
