import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Tooltip } from 'semji-core/components/Tooltip';
import styled from 'styled-components/macro';

import {
  MIND_MAP_DETAILED_VIEW,
  MIND_MAP_SIMPLIFIED_VIEW,
  MIND_MAP_VIEW_LIST,
} from '@/containers/ContentIdeas/Listing/MindMap/constant';
import Flex from '@/design-system/components/Flex';

const ViewRender = styled.span`
  line-height: 135%;
  font-size: ${({ theme }) => theme.textCss.sizes.xs};
  color: ${({ active, theme }) => (active ? theme.cssColors.primary : theme.cssColors.dark060)};
`;

const Tab = styled.button`
  cursor: pointer;
  border-radius: 0px 3px 3px 0px;
  border: 1px solid
    ${({ active, theme }) => (active ? theme.cssColors.primary010 : theme.cssColors.dark010)};
  background: ${({ active, theme }) => (active ? '#FFEBED' : theme.cssColors.white)};
  padding: 8px;
`;

function MindMapViewSelector() {
  const { t } = useTranslation();
  const [query, setQuery] = useSearchParams();

  const mindMapView = query.get('mindMapView');

  function handleChangeView(view) {
    query.set('mindMapView', view);
    setQuery(query);
  }

  function handleSelectView(view) {
    return () => handleChangeView(view);
  }

  useEffect(() => {
    if (![MIND_MAP_SIMPLIFIED_VIEW, MIND_MAP_DETAILED_VIEW].includes(mindMapView)) {
      handleChangeView(MIND_MAP_SIMPLIFIED_VIEW);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex position="absolute" right="32px" top="32px">
      {MIND_MAP_VIEW_LIST.map(({ labelKey, value, tooltipKey }) => {
        const isActive = value === mindMapView;
        return (
          <Tooltip
            popoverOptions={{
              placement: 'top',
            }}
            title={t(tooltipKey)}
          >
            <Tab
              key={value}
              active={isActive}
              aria-selected={isActive}
              role="tab"
              tabIndex={isActive || -1}
              type="button"
              onClick={handleSelectView(value)}
            >
              <ViewRender active={value === mindMapView}>{t(labelKey)}</ViewRender>
            </Tab>
          </Tooltip>
        );
      })}
    </Flex>
  );
}

export default MindMapViewSelector;
