import styled from 'styled-components/macro';

import { FlexCenteredAlignContainer } from '@/components/_common';
import SearchIcon from '@/components/icons/SearchIcon';
import SyncCalendarIcon from '@/components/icons/SyncCalendarIcon';
import { LayoutContent } from '@/components/Layout/Layout';
import StatusSelect from '@/components/Select/StatusSelect';
import Flex from '@/design-system/components/Flex';

export const TitleWrapper = styled(FlexCenteredAlignContainer)`
  flex: 0;
  min-width: 200px;
`;

export const StyledStatusSelect = styled(StatusSelect)`
  && {
    min-width: 30px;
    width: 30px;
    height: 30px;
    max-width: 50px;
    padding: 0;
    box-sizing: border-box;
  }
`;

export const StatusBulk = styled.div`
  width: 13px;
  height: 13px;
  background-color: ${({ theme }) => theme.cssColors.dark080};
  border-radius: 100%;
`;

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  margin-bottom: 48px;
`;

export const TableContainer = styled.div`
  flex: 1;
`;

export const StyledSearchIcon = styled(SearchIcon)`
  color: ${({ theme }) => theme.cssColors.dark060};
`;

export const CalendarIcon = styled(SyncCalendarIcon)`
  && {
    cursor: pointer;
    font-size: ${({ theme }) => theme.textCss.sizes.md};
    color: ${({ theme }) => theme.cssColors.dark060};
  }
`;

export const MainContent = styled(LayoutContent)`
  padding: ${({ isSidebarOpen }) => (isSidebarOpen ? '0 32px 24px 32px' : '24px 32px')};

  ${({ theme }) => theme.mediaQueries.tablet} {
    && {
      padding: ${({ isSidebarOpen }) => (isSidebarOpen ? '0 32px 24px 32px' : '24px 32px')};
    }
  }
`;

export const Wrapper = styled(LayoutContent)`
  margin: ${({ isSidebarOpen }) => isSidebarOpen && '24px 0 0 24px'};

  ${({ theme }) => theme.mediaQueries.phone} {
    && {
      margin-top: 0;
    }
  }
`;

export const Container = styled(Flex)`
  margin-left: ${({ isSidebarOpen }) => !isSidebarOpen && '32px'};
`;

export const FlexWithMargin = styled(Flex)`
  margin-left: 20px;
  ${({ theme }) => theme.mediaQueries.phone} {
    && {
      margin-left: 10px;
    }
  }
`;
