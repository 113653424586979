import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import useDeleteCurrentWorkspaceContentIdeaSearch from '@/apis/semji/contentIdeas/useDeleteCurrentWorkspaceContentIdeaSearch';
import DeleteConfirmDialog from '@/components/Dialog/DeleteConfirmDialog';
import { Bold } from '@/containers/Content/EditorComponents/Nodes';
import Header from '@/containers/ContentIdeas/Menu/Header';
import History from '@/containers/ContentIdeas/Menu/History';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const ContentIdeasSummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 33px;
  max-width: 750px;
  width: 100%;
  min-width: 0;
`;

function ContentIdeasMenu() {
  const { t } = useTranslation();
  const [contentIdeaSearchToDelete, setContentIdeaSearchToDelete] = useState(null);
  const deleteContentIdeaSearch = useDeleteCurrentWorkspaceContentIdeaSearch();

  function handleCloseDeleteModal() {
    setContentIdeaSearchToDelete(null);
  }

  function handleDeleteContentIdeaSearch() {
    deleteContentIdeaSearch.mutate(contentIdeaSearchToDelete);
  }

  return (
    <>
      <Wrapper>
        <ContentIdeasSummaryWrapper>
          <Header />
          <History setContentIdeaSearchToDelete={setContentIdeaSearchToDelete} />
        </ContentIdeasSummaryWrapper>
      </Wrapper>
      <DeleteConfirmDialog
        cancelLabel={t(`content-ideas:menu.modal.cancel-label`)}
        confirmationText={
          <Trans
            components={{
              bold: <Bold />,
            }}
            i18nKey={`content-ideas:menu.modal.confirmation-text`}
            values={{ delete: t(`content-ideas:menu.modal.delete-placeholder`) }}
          />
        }
        deleteLabel={t(`content-ideas:menu.modal.delete-label`)}
        deletePlaceholder={t(`content-ideas:menu.modal.delete-placeholder`)}
        explanationText={
          <Trans
            components={{
              bold: <Bold />,
            }}
            i18nKey={`content-ideas:menu.modal.explanation-text`}
          />
        }
        open={!!contentIdeaSearchToDelete}
        title={t(`content-ideas:menu.modal.title`)}
        onClose={handleCloseDeleteModal}
        onConfirm={handleDeleteContentIdeaSearch}
      />
    </>
  );
}

export default ContentIdeasMenu;
