import { nanoid } from 'nanoid';
import { useMemo } from 'react';

import SvgWrapper from '@/components/icons/SvgWrapper';

const InfoAltIcon = (props) => {
  const svgId = useMemo(() => nanoid(), []);

  return (
    <SvgWrapper fill="none" height="17" viewBox="0 0 17 17" width="17" {...props}>
      <mask
        height="17"
        id={`mask0_${svgId}`}
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="17"
        x="0"
        y="0"
      >
        <path
          d="M8.5 0C3.813 0 0 3.813 0 8.5C0 13.187 3.813 17 8.5 17C13.187 17 17 13.187 17 8.5C17 3.813 13.187 0 8.5 0ZM8.5 16C4.364 16 1 12.636 1 8.5C1 4.364 4.364 1 8.5 1C12.636 1 16 4.364 16 8.5C16 12.636 12.636 16 8.5 16ZM9 12.369H9.979V13.369H7.021V12.369H8V7.949H7.054V6.949H9V12.369ZM7.185 4.986C7.185 4.441 7.626 4 8.171 4C8.716 4 9.156 4.441 9.156 4.986C9.156 5.529 8.716 5.97 8.171 5.97C7.626 5.97 7.185 5.529 7.185 4.986Z"
          fill="black"
        />
      </mask>
      <g mask={`url(#mask0_${svgId})`}>
        <rect fill="currentColor" height="20" width="20" x="-2" y="-1" />
      </g>
    </SvgWrapper>
  );
};

export default InfoAltIcon;
