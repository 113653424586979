import React from 'react';

import SvgWrapper from './SvgWrapper';

const CreateUpdatedContentIcon = (props) => (
  <SvgWrapper fill="none" height="34" viewBox="0 0 62 34" width="62" {...props}>
    <path
      d="M53.4618 31.1935C53.4618 32.6728 43.2502 33.8626 30.6699 33.8626C18.0896 33.8626 7.87793 32.6728 7.87793 31.1935C7.87793 29.7142 18.0896 29.875 30.6699 29.875C43.2502 29.875 53.4618 29.7142 53.4618 31.1935Z"
      fill="#FFEBED"
    />
    <path
      d="M39.7719 7.07387C43.4925 7.07387 47.6505 10.8774 47.6505 15.9034C47.6505 19.8658 44.3903 24.4613 39.7719 24.4613L29.3397 24.4613L32.9998 20.728L32.5723 20.2503L27.818 25.0046L32.5723 29.7117L33.1661 29.2126L29.1734 25.2198L39.7719 25.2198C44.3429 25.2198 48.4717 20.7226 48.4717 15.9034C48.4717 11.0842 44.5262 6.53051 39.7719 6.53051L39.7719 7.07387Z"
      fill="#FFCCD3"
    />
    <path
      d="M21.5699 24.733C17.8493 24.733 13.6913 20.9295 13.6913 15.9035C13.6913 11.941 16.9515 7.34562 21.5699 7.34562L32.002 7.34562L28.342 11.0789L28.7695 11.5566L33.5238 6.80226L28.7695 2.09521L28.1757 2.59431L32.1684 6.58706L21.5699 6.58706C16.9989 6.58706 12.8701 11.0843 12.8701 15.9035C12.8701 20.7227 16.8156 25.2764 21.5699 25.2764V24.733Z"
      fill="#FFCCD3"
    />
    <path
      d="M22.3546 24.0746V23.7418H22.0218C17.9298 23.7418 14.6272 20.2221 14.6272 15.9648C14.6272 11.588 17.9266 8.18778 22.0218 8.18778H29.8055L27.0605 10.9376L26.8258 11.1727L27.0605 11.4078L28.5324 12.8823L28.7679 13.1182L29.0034 12.8823L34.7681 7.10713L35.0028 6.87207L34.7681 6.637L29.0034 0.861861L28.7679 0.625957L28.5324 0.861853L27.0605 2.33637L26.8258 2.57144L27.0605 2.80651L29.8055 5.55635H22.0218C16.5527 5.55635 11.9993 10.2631 11.9993 15.9648C11.9993 21.6638 16.4274 26.3733 22.0218 26.3733H22.3546V26.0406V24.0746ZM39.3162 5.43347H38.9835V5.7662V7.73221V8.06494H39.3162C43.4083 8.06494 46.7107 11.5846 46.7107 15.8419C46.7107 20.0992 43.4083 23.619 39.3162 23.619H31.5326L34.2775 20.8692L34.5121 20.6341L34.2775 20.399L32.8057 18.9245L32.5702 18.6886L32.3347 18.9245L26.5698 24.6997L26.3352 24.9347L26.5698 25.1698L32.3347 30.9449L32.5702 31.1808L32.8057 30.9449L34.2775 29.4704L34.5121 29.2353L34.2775 29.0003L31.5326 26.2504H39.3162C44.9095 26.2504 49.3368 21.543 49.3388 15.8457C49.4635 10.1357 44.9029 5.43347 39.3162 5.43347Z"
      stroke="#51525E"
      strokeWidth="0.665459"
    />
    <rect fill="#FFEBED" height="2.3291" rx="1.16455" width="4.99094" x="5.2168" y="13.7407" />
    <rect fill="#FFEBED" height="2.3291" rx="1.16455" width="4.32548" x="9.875" y="4.58228" />
    <rect fill="#FFEBED" height="2.3291" rx="1.16455" width="2.66183" x="0.558594" y="8.74976" />
    <rect fill="#FFEBED" height="2.3291" rx="1.16455" width="6.65458" x="4.55176" y="8.74976" />
    <rect
      fill="#FFEBED"
      height="2.3291"
      rx="1.16455"
      transform="rotate(-180 55.792 18.3989)"
      width="4.99094"
      x="55.792"
      y="18.3989"
    />
    <rect
      fill="#FFEBED"
      height="2.3291"
      rx="1.16455"
      transform="rotate(-180 51.1338 26.3843)"
      width="4.32548"
      x="51.1338"
      y="26.3843"
    />
    <rect
      fill="#FFEBED"
      height="2.3291"
      rx="1.16455"
      transform="rotate(-180 60.7822 22.7241)"
      width="2.66183"
      x="60.7822"
      y="22.7241"
    />
    <rect
      fill="#FFEBED"
      height="2.3291"
      rx="1.16455"
      transform="rotate(-180 56.7891 22.7241)"
      width="6.65458"
      x="56.7891"
      y="22.7241"
    />
  </SvgWrapper>
);

export default CreateUpdatedContentIcon;
