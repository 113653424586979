import { combineReducers } from 'redux';

import {
  CHANGE_WORKSPACE_NAME,
  FETCH_WORKSPACE_SUCCESS,
  UPDATE_WORKSPACE_ACHIEVEMENTS,
  UPDATE_WORKSPACE_USERS,
} from '@/store/actions/actionTypes';

const byId = (state = {}, action) => {
  let newState = { ...state };

  switch (action.type) {
    case FETCH_WORKSPACE_SUCCESS:
      newState[action.workspace.id] = {
        ...action.workspace,
      };
      return newState;
    case CHANGE_WORKSPACE_NAME:
      newState[action.id] = {
        ...newState[action.id],
        name: action.name,
      };
      return newState;
    case UPDATE_WORKSPACE_ACHIEVEMENTS:
      newState[action.workspaceId].workspaceAchievements = action.achievements;
      return newState;
    case UPDATE_WORKSPACE_USERS:
      newState[action.workspaceId] = {
        ...newState[action.workspaceId],
        users: [...action.users],
      };
      return newState;
    default:
      return state;
  }
};

const allIds = (state = [], action) => {
  switch (action.type) {
    case FETCH_WORKSPACE_SUCCESS:
      return state.indexOf(action.workspace.id) === -1 ? [...state, action.workspace.id] : state;
    default:
      return state;
  }
};

export default combineReducers({
  allIds,
  byId,
});

export const getById = (state, id) => state.byId[id] || null;
export const getAllIds = (state) => state.allIds;
export const getDictionary = (state) => state.byId;
export const isLoading = (state) => state.loading;
