import React from 'react';

import SvgWrapper from './SvgWrapper';

const HelpAltIcon = (props) => (
  <SvgWrapper {...props}>
    <path
      d="M8.5 0c-4.687 0-8.5 3.813-8.5 8.5s3.813 8.5 8.5 8.5 8.5-3.813 8.5-8.5-3.813-8.5-8.5-8.5zM8.5 16c-4.136 0-7.5-3.364-7.5-7.5s3.364-7.5 7.5-7.5 7.5 3.364 7.5 7.5-3.364 7.5-7.5 7.5zM9.658 12.219c0 0.568-0.462 1.031-1.031 1.031-0.571 0-1.033-0.463-1.033-1.031 0-0.57 0.462-1.032 1.033-1.032 0.569 0 1.031 0.461 1.031 1.032zM10.662 4.215c0.448 0.565 0.674 1.328 0.55 1.855-0.243 1.027-0.842 1.567-1.371 2.043-0.543 0.489-0.934 0.84-0.934 1.647h-1c0-1.251 0.671-1.856 1.264-2.39 0.461-0.415 0.896-0.807 1.066-1.529 0.034-0.143-0.039-0.6-0.36-1.005-0.307-0.389-0.728-0.586-1.248-0.586-1.779 0-1.869 1.444-1.873 1.609l-1-0.027c0.024-0.893 0.655-2.582 2.873-2.582 0.818 0 1.539 0.343 2.033 0.965z"
      fill="currentColor"
    />
  </SvgWrapper>
);
export default HelpAltIcon;
