import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import HelperMessage from '@/components/Message/HelperMessage';
import ScrollBar from '@/components/Scrollbar';
import EmptyState from '@/containers/Content/SidePanel/Components/Outline/EmptyState';
import HeadingElement from '@/containers/Content/SidePanel/Components/Outline/HeadingElement';
import SidePanelHeader from '@/containers/Content/SidePanel/SidePanelHeader/SidePanelHeader';
import Flex from '@/design-system/components/Flex';

const Container = styled.div`
  overflow: hidden;
  padding: 0px 16px 10px 16px;
`;

export function getMissingHeadings(from, to) {
  let missingHeadings = [];
  for (let i = from; i < to; i++) {
    missingHeadings.push({
      heading: `<H${i + 1}>`,
      isMissing: true,
      level: i,
    });
  }
  return missingHeadings;
}

export function getHeadings(elements, defaultLabel) {
  if (!elements || elements?.length === 0) return [];

  let headings = [];
  // Content outline does not contain H1 element
  if (!Array.from(elements).find((element) => element.nodeName === 'H1')) {
    headings.push({ heading: '<H1>', isMissing: true, level: 0 });
  }
  elements.forEach((element) => {
    const heading = `<${element.nodeName}>`;
    const level = element.nodeName.split('H')[1] - 1;
    const text = element.innerText || defaultLabel;

    // Add missing headers
    const index = headings.length;
    if (headings[index - 1]) {
      const prev = headings[index - 1];
      if (prev?.level + 1 < level) {
        const missingHeadings = getMissingHeadings(prev.level + 1, level);
        headings = headings.concat(missingHeadings);
      }
    }

    headings.push({ heading, level, text });
  });
  return headings;
}

const Outline = ({ isSnapshotPage, html }) => {
  const { t } = useTranslation();

  const htmlDocument = new DOMParser().parseFromString(html, 'text/html');
  const elements = htmlDocument.querySelectorAll('h1,h2,h3,h4,h5,h6');
  const headings = getHeadings(elements, t('components:outline.empty.heading'));
  const hasHeadings = elements.length > 0;

  if (!hasHeadings) {
    return (
      <>
        <SidePanelHeader>{t('components:outline.title')}</SidePanelHeader>
        <EmptyState />
      </>
    );
  }

  return (
    <Flex flexDirection="column" height="100%">
      <SidePanelHeader>{t('components:outline.title')}</SidePanelHeader>
      <ScrollBar>
        <Container>
          {isSnapshotPage && (
            <HelperMessage margin="0 0 16px 0" withCloseBtn>
              {t('components:outline.helper-message')}
            </HelperMessage>
          )}
          {headings.map((heading, index) => (
            <HeadingElement key={index} {...heading} />
          ))}
        </Container>
      </ScrollBar>
    </Flex>
  );
};

export default Outline;
