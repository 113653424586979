import { useTranslation } from 'react-i18next';
import { getRoundedNumberMetricWithSuffix } from 'semji-core/utils/numbers';

import { FlexTooltipComponent } from '@/components/Tooltip/Tooltip';
import {
  KeywordDetailed,
  SearchIconDetailed,
  VolumeDetailed,
} from '@/containers/ContentIdeas/Listing/MindMap/Nodes/Keyword/KeywordNode.styled';
import Box from '@/design-system/components/Box';
import Flex from '@/design-system/components/Flex';

function KeywordTitle({ data, userLanguageCode }) {
  const { t } = useTranslation();

  return (
    <Flex
      alignItems="center"
      backgroundColorWithoutTheme={data.cluster.backgroundColor}
      borderRadius="20px"
      color={data.cluster.color}
      gap="10px"
      maxWidth="-webkit-fill-available"
      overflow="hidden"
      padding="6px 12px"
      whiteSpace="nowrap"
      width="fit-content"
    >
      <KeywordDetailed
        color={data.cluster.color}
        dangerouslySetInnerHTML={{ __html: data.keywordLabel }}
      />
      <Box
        backgroundColorWithoutTheme={data.cluster.color}
        height="16px"
        opacity="0.3"
        width="1px"
      />
      <FlexTooltipComponent
        cursor="help"
        description={data.searchVolume}
        title={t('content-ideas:mind-map.volume')}
      >
        <VolumeDetailed alignItems="center" color="dark060" gap="2px">
          <SearchIconDetailed />
          {getRoundedNumberMetricWithSuffix({
            locale: userLanguageCode,
            number: data.searchVolume,
          })}
        </VolumeDetailed>
      </FlexTooltipComponent>
    </Flex>
  );
}

export default KeywordTitle;
