import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import FlashMessage from '@/components/FlashMessage/FlashMessage';
import { DefaultLink } from '@/components/Navigation/Link';
import Can from '@/hoc/Can';
import { SERVICES_LIST } from '@/utils/constants';

export default function MissingServiceIntegrationFlashMessage({ organizationId, workspaceId }) {
  const { t } = useTranslation();
  const disabledApis = useSelector((state) => state.serviceIntegrations.disabledApis);

  if ((disabledApis ?? []).length === 0) {
    return null;
  }

  const serviceIntegrationTitle = SERVICES_LIST[disabledApis[0]]?.title;
  return (
    <FlashMessage>
      <span>
        {t('layout:flash-message.cannot-conntect-service-integration', {
          serviceIntegrationTitle,
        })}
        &nbsp;
        <Can
          data={{
            accessGranted: true,
          }}
          no={() => t('layout:flash-message.please-contact-your-workspace-owner')}
          perform="workspace-owner-settings:visit"
          yes={() => (
            <DefaultLink
              size="sm"
              to={`/o/${organizationId}/w/${workspaceId}/settings/integrations`}
              weight="medium"
            >
              {t('layout:flash-message.please-reconnect')}
            </DefaultLink>
          )}
        />
      </span>
    </FlashMessage>
  );
}
