import './Menu.scss';

import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { BookIcon } from 'semji-core/icons/BookIcon';
import { DownIcon, ENUM_DOWN_ICON_DIRECTION } from 'semji-core/icons/DownIcon';

import PenIcon from '@/components/icons/PenIcon';
import StarIcon from '@/components/icons/StarIcon';
import Selector, { SelectorBox } from '@/components/Selector';
import useApiConfigurations from '@/hooks/useApiConfigurations';
import useCan from '@/hooks/useCan';
import { useMixpanelTrackEvent } from '@/hooks/useMixpanelTrackEvent';
import useOrganizationFeatureSet, {
  AI_WRITING__BRAND_VOICE__IS_ENABLED,
  AI_WRITING__DEPRECATED_BRAND_VOICE_CONFIGURATOR__IS_ENABLED,
  AI_WRITING__KNOWLEDGE_BASE__IS_ENABLED,
  COMPETITORS__IS_ENABLED,
} from '@/hooks/useOrganizationFeatureSet';
import { selectCurrentHub } from '@/store/selectors/selectCurrentHub';
import { selectDefaultReportId } from '@/store/selectors/selectDefaultReportId';
import { selectDefaultWebsiteId } from '@/store/selectors/selectDefaultWebsiteId';
import { SemjiHub } from '@/types/common.types';
import {
  BRANDVOICE_TOP_NAV_CLICK,
  COMPETITORS_TOP_NAV_CLICK,
  CONTENT_IDEAS_TOP_NAV_CLICK,
  DASHBOARD_TOP_NAV_CLICK,
  PAGES_TOP_NAV_CLICK,
  PLANNING_TOP_NAV_CLICK,
  REPORTS_TOP_NAV_CLICK,
} from '@/utils/3rdParty/Mixpanel/constants';
import { getNumberOfFiltersDisplayable } from '@/utils/filter/getNumberOfFiltersDisplayable';

import { MenuItemType } from './Menu.types';
import { MenuItem, SubMenuItem } from './MenuItem';

function MenuContainer() {
  const prefix = 'layout:menu-item:';
  const { t } = useTranslation();
  const { organizationId, workspaceId } = useParams();
  const canDisplayMenu = useCan({ perform: 'app-menu:display' });
  const trackMixpanelEvent = useMixpanelTrackEvent();
  const currentHub = useSelector(selectCurrentHub);
  const defaultReportId = useSelector(selectDefaultReportId);
  const defaultWebsiteId = useSelector(selectDefaultWebsiteId);

  const { isFeatureEnabled: hasAccessToCompetitors } =
    useOrganizationFeatureSet(COMPETITORS__IS_ENABLED);
  const { isFeatureEnabled: hasAccessToDeprecatedBrandVoice } = useOrganizationFeatureSet(
    AI_WRITING__DEPRECATED_BRAND_VOICE_CONFIGURATOR__IS_ENABLED
  );
  const { isFeatureEnabled: hasAccessToBrandVoice } = useOrganizationFeatureSet(
    AI_WRITING__BRAND_VOICE__IS_ENABLED
  );
  const { isFeatureEnabled: hasAccessToKnowledgeBase } = useOrganizationFeatureSet(
    AI_WRITING__KNOWLEDGE_BASE__IS_ENABLED
  );

  const [displayedItemCount, setDisplayedItemCount] = useState(0);

  const { search } = useLocation();

  const layoutMenuRef = useRef<HTMLDivElement>(null);

  const canAccessToDashboard = useCan({
    data: {
      accessGranted: true,
    },
    hubs: [SemjiHub.CONTENT],
    perform: 'dashboard-page:visit',
  });
  const canAccessToContentIdeas = useCan({
    data: {
      accessGranted: true,
    },
    hubs: [SemjiHub.CONTENT],
    perform: 'content-ideas:visit',
  });

  const canAccessToCompetitors = useCan({
    data: {
      accessGranted: hasAccessToCompetitors,
    },
    hubs: [SemjiHub.EXECUTIVE],
    perform: 'competitors:visit',
  });

  const canAccessToPages = useCan({
    data: {
      accessGranted: true,
    },
    hubs: [SemjiHub.CONTENT],
    perform: 'online-contents-page:visit',
  });

  const canAccessToPlanning = useCan({
    data: {
      accessGranted: true,
    },
    hubs: [SemjiHub.CONTENT],
    perform: 'planning-page:visit',
  });
  const canAccessToReport = useCan({
    data: {
      accessGranted: true,
    },
    hubs: [SemjiHub.CONTENT],
    perform: 'reports-page:visit',
  });
  const canAccessToDeprecatedBrandVoice = useCan({
    data: {
      accessGranted: hasAccessToDeprecatedBrandVoice,
    },
    hubs: [SemjiHub.CONTENT],
    perform: 'brand-voice-page:visit',
  });
  const canAccessToBrandVoice = useCan({
    data: {
      accessGranted: hasAccessToBrandVoice,
    },
    hubs: [SemjiHub.CONTENT],
    perform: 'brand-voice-page:visit',
  });
  const canAccessToAiKnowledge = useCan({
    data: {
      accessGranted: hasAccessToKnowledgeBase,
    },
    hubs: [SemjiHub.CONTENT],
    perform: 'knowledge-base-page:visit',
  });

  const menuItems: MenuItemType[] = [
    canAccessToCompetitors && {
      disabled: !canAccessToCompetitors,
      feature: 'competitors:is-enabled',
      handleClick: () => trackMixpanelEvent(COMPETITORS_TOP_NAV_CLICK),
      hubs: [SemjiHub.EXECUTIVE],
      id: `${prefix}competitors_report`,
      intercomTarget: 'menu_competitors_entry',
      label: t('layout:menu.search-intelligence.sub-menu.report'),
      to: `/o/${organizationId}/w/${workspaceId}/search-intelligence${defaultReportId ? '/' + defaultReportId : ''}/reports${search}`,
      type: 'link',
    },
    canAccessToCompetitors && {
      disabled: !canAccessToCompetitors,
      feature: 'competitors:is-enabled',
      handleClick: () => trackMixpanelEvent(COMPETITORS_TOP_NAV_CLICK),
      hubs: [SemjiHub.EXECUTIVE],
      id: `${prefix}competitors_content_duel`,
      intercomTarget: 'menu_competitors_entry',
      label: t('layout:menu.search-intelligence.sub-menu.competition'),
      to: `/o/${organizationId}/w/${workspaceId}/search-intelligence${defaultReportId ? '/' + defaultReportId : ''}/contents-duel${defaultWebsiteId ? '/' + defaultWebsiteId : ''}${search}`,
      type: 'link',
    },
    canAccessToCompetitors && {
      disabled: !canAccessToCompetitors,
      feature: 'competitors:is-enabled',
      handleClick: () => trackMixpanelEvent(COMPETITORS_TOP_NAV_CLICK),
      hubs: [SemjiHub.EXECUTIVE],
      id: `${prefix}competitors_data_explorer`,
      intercomTarget: 'menu_competitors_entry',
      label: t('layout:menu.search-intelligence.sub-menu.data-explorer'),
      to: `/o/${organizationId}/w/${workspaceId}/search-intelligence${defaultReportId ? '/' + defaultReportId : ''}/data-explorer${search}`,
      type: 'link',
    },
    canAccessToDashboard && {
      exact: 'true',
      handleClick: () => trackMixpanelEvent(DASHBOARD_TOP_NAV_CLICK),
      hubs: [SemjiHub.CONTENT],
      id: `${prefix}dashboard`,
      intercomTarget: 'menu_dashboard_entry',
      label: t('layout:menu.dashboard'),
      to: `/o/${organizationId}/w/${workspaceId}/dashboard`,
      type: 'link',
    },
    canAccessToContentIdeas && {
      handleClick: () => trackMixpanelEvent(CONTENT_IDEAS_TOP_NAV_CLICK),
      hubs: [SemjiHub.CONTENT],
      id: `${prefix}content-ideas`,
      intercomTarget: 'menu_content_ideas_entry',
      label: t('layout:menu.content-ideas'),
      to: `/o/${organizationId}/w/${workspaceId}/content-ideas`,
      type: 'link',
    },
    canAccessToPages && {
      handleClick: () => trackMixpanelEvent(PAGES_TOP_NAV_CLICK),
      hubs: [SemjiHub.CONTENT],
      id: `${prefix}pages`,
      intercomTarget: 'menu_online_content_entry',
      label: t('layout:menu.pages'),
      to: `/o/${organizationId}/w/${workspaceId}/contents?tab=opportunities&priorityScore=clicks`,
      type: 'link',
    },
    canAccessToPlanning && {
      handleClick: () => trackMixpanelEvent(PLANNING_TOP_NAV_CLICK),
      hubs: [SemjiHub.CONTENT],
      id: `${prefix}planning`,
      intercomTarget: 'menu_planning_entry',
      label: t('layout:menu.planning'),
      to: `/o/${organizationId}/w/${workspaceId}/planning`,
      type: 'link',
    },
    canAccessToReport && {
      handleClick: () => trackMixpanelEvent(REPORTS_TOP_NAV_CLICK),
      hubs: [SemjiHub.CONTENT],
      id: `${prefix}reports`,
      intercomTarget: 'menu_report_entry',
      label: t('layout:menu.reports'),
      to: `/o/${organizationId}/w/${workspaceId}/reports`,
      type: 'link',
    },
    canAccessToDeprecatedBrandVoice && {
      handleClick: () => trackMixpanelEvent(BRANDVOICE_TOP_NAV_CLICK),
      hubs: [SemjiHub.CONTENT],
      id: `${prefix}brand-voice`,
      label: t('layout:menu.brand-voice'),
      to: `/o/${organizationId}/w/${workspaceId}/brand-voice`,
      type: 'link',
    },
    (canAccessToBrandVoice || canAccessToAiKnowledge) && {
      handleClick: () => trackMixpanelEvent(REPORTS_TOP_NAV_CLICK),
      hubs: [SemjiHub.CONTENT],
      id: `${prefix}old-reports`,
      intercomTarget: 'menu_old-report_entry',
      label: <AIMenuRender />,
      subLabel: <AIMenuRender isSubMenu />,
      to: `/o/${organizationId}/w/${workspaceId}/old-reports`,
      type: 'selector',
    },
  ].filter((menuItem: MenuItemType | false) => {
    return canAccessToCompetitors ? menuItem && menuItem.hubs.includes(currentHub) : !!menuItem;
  });

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      // La taille de la div a changé, met à jour l'état
      for (let entry of entries) {
        const { width } = entry.contentRect;

        const elements = document.querySelectorAll(`[id^="${prefix}"]`);
        const elementWidths = Array.from(elements).map((element) => element.offsetWidth);
        setDisplayedItemCount(
          getNumberOfFiltersDisplayable({
            elementWidths,
            options: {
              addMoreButtonWidth: 80,
              gap: 0,
              marginLeft: 0,
              marginRight: 0,
            },
            screenWidth: width,
          })
        );
      }
    });

    if (layoutMenuRef.current) {
      observer.observe(layoutMenuRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  function MoreButtonRender({ openMenu }: any) {
    function handleOpenMore(e: any) {
      if (workspaceId) {
        openMenu(e);
      }
    }

    return (
      <span
        aria-hidden="true"
        className={displayedItemCount < menuItems.length ? '' : 'layout-menu__hidden'}
        onClick={handleOpenMore}
      >
        <span
          className={`layout-menu__item ${!workspaceId && 'layout-menu__item--disabled'}`}
          data-intercom-target="menu_more_entry"
        >
          <span className="layout-menu__item__label">{t('layout:menu.more')}</span>
          <DownIcon className="layout-menu__item__label__arrow" />
        </span>
      </span>
    );
  }

  function AIButtonRender({ openMenu }: any) {
    function handleOpenAIContent(e: any) {
      if (workspaceId) {
        openMenu(e);
      }
    }

    return (
      <span aria-hidden="true" id={`${prefix}ai_content`} onClick={handleOpenAIContent}>
        <NavLink
          className={`layout-menu__item ${!workspaceId && 'layout-menu__item--disabled'}`}
          data-intercom-target="menu_ai_content_entry"
          to={`/o/${organizationId}/w/${workspaceId}/ai-content`}
          onClick={(e) => e.preventDefault()}
        >
          <span className="layout-menu__item__label">
            {t('layout:menu.ai')}
            <StarIcon className="layout-menu__item__label__star-icon" />
          </span>
          <DownIcon className="layout-menu__item__label__arrow" />
        </NavLink>
      </span>
    );
  }

  function AIButtonRenderAsSubMenu({ openMenu, open }: any) {
    function handleOpenAIContent(e: any) {
      e.preventDefault();
      if (workspaceId) {
        openMenu(e);
      }
    }

    return (
      <NavLink
        className="layout-menu__sub-menu__item"
        to={`/o/${organizationId}/w/${workspaceId}/ai-content`}
        onClick={handleOpenAIContent}
      >
        <span className="layout-menu__sub-menu__item__label">
          {t('layout:menu.ai')}
          <StarIcon className="layout-menu__item__label__star-icon" />
        </span>
        <DownIcon direction={ENUM_DOWN_ICON_DIRECTION.RIGHT} />
      </NavLink>
    );
  }

  function MoreMenuRender() {
    return (
      <Selector button={MoreButtonRender}>
        {(props: any) => (
          <SelectorBox {...props} classes={{ paper: 'layout-menu__sub-menu' }}>
            {menuItems.slice(displayedItemCount).map((item) => (
              <SubMenuItem
                {...item}
                key={item.id}
                handleClick={() => {
                  props.onClose();
                  item?.handleClick?.();
                }}
              />
            ))}
          </SelectorBox>
        )}
      </Selector>
    );
  }
  function AIMenuRender({ isSubMenu = false }) {
    if (canAccessToAiKnowledge || canAccessToBrandVoice) {
      return (
        <Selector button={isSubMenu ? AIButtonRenderAsSubMenu : AIButtonRender}>
          {(props: any) => (
            <SelectorBox
              {...props}
              anchorOrigin={{
                horizontal: isSubMenu ? 'right' : 'left',
                vertical: isSubMenu ? 'top' : 'bottom',
              }}
              classes={{ paper: 'layout-menu__sub-menu' }}
              transformOrigin={{ horizontal: 'left', vertical: 'top' }}
            >
              {canAccessToBrandVoice && (
                <SubMenuItem
                  key={`brand-voice`}
                  hubs={[SemjiHub.CONTENT]}
                  icon={<PenIcon />}
                  id="brand-voice"
                  label={t('layout:menu.brand-voice')}
                  to={`/o/${organizationId}/w/${workspaceId}/ai-content/brand-voice`}
                  type="link"
                />
              )}
              {canAccessToAiKnowledge && (
                <SubMenuItem
                  key={`knowledge-base`}
                  hubs={[SemjiHub.CONTENT]}
                  icon={<BookIcon />}
                  id="knowledge-base"
                  label={t('layout:menu.knowledge-base')}
                  to={`/o/${organizationId}/w/${workspaceId}/ai-content/knowledge-base`}
                  type="link"
                />
              )}
            </SelectorBox>
          )}
        </Selector>
      );
    }
    return null;
  }

  if (canDisplayMenu) {
    return (
      <div ref={layoutMenuRef} className="layout-menu" id="layout-menu">
        {menuItems.map((menuItem, index) => (
          <React.Fragment key={menuItem.id}>
            {index === displayedItemCount && <MoreMenuRender />}
            <MenuItem
              {...menuItem}
              key={menuItem.id}
              disabled={!workspaceId || menuItem.disabled}
              handleClick={menuItem?.handleClick}
            />
          </React.Fragment>
        ))}
      </div>
    );
  }

  return <div className="layout-menu" />;
}

export default MenuContainer;
