import Highcharts from 'highcharts';
import HighchartsExporting from 'highcharts/modules/exporting';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import HighchartsReact from 'highcharts-react-official';
import React, { useId } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getRoundedNumberMetricWithSuffix } from 'semji-core/utils/numbers';

import {
  AxisChartLabel,
  ENUM_AXIS_CHART_LABEL_ORIENTATION,
} from '@/containers/Competitors/Duel/Report/AxisChartLabel/AxisChartLabel';
import { ChartLegend } from '@/containers/Competitors/Duel/Report/ChartLegend';
import {
  ChartDataType,
  ENUM_CHART_DATA_TYPE,
  ENUM_SERIES_CURSOR_TYPE,
} from '@/containers/Competitors/Duel/Report/constant';
import { CompetitorReportChartCategory } from '@/containers/Competitors/utils/constants';
import { selectUserConfiguration } from '@/store/selectors/selectUserConfiguration';
import defaultTheme from '@/themes/defaultTheme';

import { ChartTooltipSingle } from '../ChartTooltipSingle';
import ChartUtils from './Chart.utils';
import { DEFAULT_CHART_OPTIONS, getContainerId } from './constants';

NoDataToDisplay(Highcharts);
HighchartsExporting(Highcharts);

interface HighChartsColumnProps {
  metricsSeries: any;
  getTooltipDataByPoint: any;
  renderType: ChartDataType;
  reference: string;
  categories: CompetitorReportChartCategory[];
  options?: any;
  chartRef: any;
  title?: string;
  onPointClick?: (e: any, serieKey: string) => void;
  serieKey: string;
  exportImage: boolean;
}

export function HighChartsColumn({
  metricsSeries,
  getTooltipDataByPoint,
  renderType,
  reference,
  categories,
  options = {},
  chartRef,
  serieKey,
  title,
  exportImage,
  onPointClick,
}: HighChartsColumnProps) {
  const id = useId();
  const chartContainerId = getContainerId(id);
  const { t } = useTranslation();
  const apiRootUrl = import.meta.env.VITE_REACT_APP_SEMJI_API_ROOT_URL;

  const { showCompetitorDataLabels } = useSelector(selectUserConfiguration);

  const series = metricsSeries;
  const OPTIONS = {
    ...DEFAULT_CHART_OPTIONS,
    chart: {
      ...DEFAULT_CHART_OPTIONS.chart,
      events: {
        render: function () {
          const series = this.series;
          series.forEach((serie) => {
            serie.data.forEach((point) => {
              !showCompetitorDataLabels && point?.dataLabel?.hide();
            });
          });
        },
      },
      height: options.height || 400,
      type: 'bar',
    },
    exporting: ChartUtils.getExportingOptions({
      overrideOptions: {
        title: {
          align: 'left',
          text: title,
        },
      },
    }),
    lang: {
      noData: t('competitors:duel.report.no-data'),
    },
    legend: ChartUtils.getLegendOptions({
      overrideOptions: {
        labelFormatter: function () {
          return ReactDOMServer.renderToStaticMarkup(
            getChartLegend(this.name, this.color, this.visible)
          );
        },
        ...options.legend,
      },
    }),
    plotOptions: {
      series: {
        cursor: onPointClick ? ENUM_SERIES_CURSOR_TYPE.POINTER : ENUM_SERIES_CURSOR_TYPE.UNDEFINED,
        maxPointWidth: 80,
        ...options.plotOptions?.series,
        dataLabels: {
          color: defaultTheme.cssColors.white,
          enabled: true,
          formatter: function () {
            //format the number with suffix like 'K' or 'M'
            const value = getRoundedNumberMetricWithSuffix({ number: this.y });
            if (renderType === ENUM_CHART_DATA_TYPE.PERCENTAGE) {
              return `${value}%`;
            }
            return value;
          },
          inside: true,
          style: {
            fontSize: defaultTheme.textCss.sizes.xs,
            fontWeight: defaultTheme.textCss.weights.bold,
          },
        },
        events: {
          click: (e: any) => {
            onPointClick?.(e, serieKey);
          },
          mouseOut: function () {
            this.data.forEach((point) => {
              !showCompetitorDataLabels && point.dataLabel.hide();
            });
          },
          mouseOver: function () {
            this.data.forEach((point) => {
              point.dataLabel.show();
            });
          },
        },
      },
    },
    tooltip: {
      backgroundColor: 'transparent',
      borderWidth: '0px',
      className: 'competitors-duel-report__chart__tooltip',
      enabled: true,
      formatter: function () {
        return ReactDOMServer.renderToStaticMarkup(getChartTooltip({ graphPoints: this.points }));
      },
      hideDelay: 0,
      outside: true,
      shadow: false,
      shared: true,
      style: {
        color: defaultTheme.cssColors.dark080,
      },
      useHTML: true,
    },
    xAxis: ChartUtils.getXAxisOptions({
      overrideLabelOptions: {
        align: 'left',
        formatter: function () {
          const imgId = `chart-${id}-xaxis-${this.value?.name}`;
          ChartUtils.addChartFaviconEventListeners(imgId);

          return ReactDOMServer.renderToStaticMarkup(
            <AxisChartLabel
              apiRootUrl={apiRootUrl}
              domain={this.value?.name}
              exportImage={exportImage}
              id={imgId}
              isReference={this.value?.name === reference}
              orientation={ENUM_AXIS_CHART_LABEL_ORIENTATION.HORIZONTAL}
              url={this.value?.url}
              urlType={this.value?.urlType}
            />
          );
        },
        reserveSpace: true,
        style: options.xAxisStyle,
      },
      overrideOptions: { categories: categories, type: 'datetime', ...options.xAxis },
    }),
    yAxis: ChartUtils.getYAxisOptions({
      overrideLabelOptions: {
        formatter: function () {
          return renderType === ENUM_CHART_DATA_TYPE.PERCENTAGE
            ? `${this.value}%`
            : getRoundedNumberMetricWithSuffix({ number: this.value });
        },
      },
      overrideOptions: {
        endOnTick: true,
        softMax: ChartUtils.getChartMaxValue(series, {
          roundPrecision: renderType === ENUM_CHART_DATA_TYPE.PERCENTAGE ? 1 : 2,
        }),
        softMin: ChartUtils.getChartMinValue(series, {
          roundPrecision: renderType === ENUM_CHART_DATA_TYPE.PERCENTAGE ? 1 : 2,
        }),
        startOnTick: true,
      },
    }),
  };

  function getChartLegend(name: string, color: string, isVisible: boolean) {
    return <ChartLegend color={color} isVisible={isVisible} name={name} />;
  }

  function getChartTooltip({
    graphPoints,
  }: {
    graphPoints: {
      x: CompetitorReportChartCategory;
      y: number;
      custom: { [key: string]: number };
    }[];
  }) {
    const imgId = `chart-${id}-tooltip-${graphPoints[0].x}`;
    ChartUtils.addChartFaviconEventListeners(imgId);
    return (
      <ChartTooltipSingle
        id={imgId}
        point={graphPoints[0]}
        renderType={renderType}
        {...getTooltipDataByPoint({ x: graphPoints[0].x.name, y: graphPoints[0].y })}
      />
    );
  }

  function onClick(e: Event) {
    e.stopPropagation();
  }

  return (
    <div className="competitors-duel-report__chart">
      <div aria-hidden="true" id={chartContainerId} onClick={onClick}>
        <HighchartsReact
          ref={chartRef}
          highcharts={Highcharts}
          options={{
            ...OPTIONS,
            series: ChartUtils.buildSeries(series),
          }}
        />
      </div>
    </div>
  );
}
