import styled from 'styled-components/macro';

import { FlexRowWrapContainer } from '@/components/_common';
import Flex from '@/design-system/components/Flex';

export const Alert = styled(Flex)`
  padding: 16px;
  background: ${({ theme }) => theme.cssColors.primary005};
  border-radius: 4px;
`;

export const AlertDescription = styled.p`
  font-size: ${({ theme }) => theme.textCss.sizes.sm};
  line-height: ${({ theme }) => theme.textCss.sizes.lg};
  color: ${({ theme }) => theme.cssColors.dark080};
  font-weight: ${({ theme, fontWeight }) => theme.textCss.weights[fontWeight]};
`;

export const FlatLoaderWrapper = styled.div`
  height: 32px;
  width: 200px;
  margin-left: 10px;
`;

export const Title = styled.div`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  align-self: center;
  font-size: 20px;
  line-height: ${({ theme }) => theme.textCss.sizes.xxl};
  font-weight: ${({ theme }) => theme.textCss.weights.normal};
`;

export const StyledFlexRowWrapContainer = styled(FlexRowWrapContainer)`
  margin-left: ${({ isSidebarOpen }) => (isSidebarOpen ? '20px' : '52px')};
  ${({ theme }) => theme.mediaQueries.phone} {
    && {
      margin-left: 10px;
    }
  }
`;

export const Message = styled(Flex)`
  background: #ecf5ff;
  padding: 16px 14px;
  border-radius: 4px;
  font-size: ${({ theme }) => theme.textCss.sizes.sm};
  font-weight: ${({ theme }) => theme.textCss.weights.medium};
  line-height: ${({ theme }) => theme.textCss.sizes.md};
  color: ${({ theme }) => theme.cssColors.dark080};
`;

export const ClusterizationMessage = styled(Message)`
  && {
    background: ${(props) => props.theme.colors.primaryPink5};
  }
`;

export const Wrapper = styled(Flex)`
  && {
    display: ${({ isHeaderOpen }) => (isHeaderOpen ? 'flex' : 'none')};
  }
`;
