import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import apiClient from '@/apis/semji/apiClient';
import { SCOPE_UNIT_LIMITATIONS } from '@/apis/semji/constants';
import { QueryResult } from '@/apis/types/query.types';
import { showErrorSnackbar } from '@/store/actions/ui';
import { Page } from '@/types/pages';

export default function useGetWorkspaceUnitLimitationsByOrganizationId(): QueryResult<Page[]> {
  const { t } = useTranslation();
  const { get } = apiClient;
  const dispatch = useDispatch();
  const { organizationId } = useParams();

  return useQuery({
    onError: () => {
      dispatch(showErrorSnackbar(t('common:error.default')));
    },
    placeholderData: [],

    queryFn: async ({ signal }) => {
      const result = await get(`/organizations/${organizationId}/workspace_unit_limitations`, {
        signal,
      });

      return result.data['hydra:member'] || [];
    },

    queryKey: [
      SCOPE_UNIT_LIMITATIONS.GET_WORKSPACE_UNIT_LIMITATIONS_BY_ORGANIZATION_ID,
      organizationId,
    ],
  });
}
