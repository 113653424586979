import './Listing.scss';

import React from 'react';
export default function Listing() {
  return (
    <div className="ai-knowledge-listing">
      <h1>AI Knowledge</h1>
    </div>
  );
}
