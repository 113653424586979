import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Text } from '@/components/Message/common';
import WarningMessage from '@/components/Message/WarningMessage';
import {
  getApiCreditType,
  getLabel,
} from '@/containers/Content/SidePanel/Components/Optimization/Section/Credits/utils';
import { showUpgradePlanModal } from '@/store/actions/billing';
import useParamSelector from '@/store/hooks/useParamSelector';
import { selectCredits } from '@/store/selectors/selectCredits';

const getMessage = (type, creditLeft, amountToUnlock, onClick) => {
  const label = getLabel(type, creditLeft);

  return (
    <Trans
      components={{
        text: <Text isPointer primary weight="strong" onClick={onClick} />,
        textUnlock: <Text weight="strong" />,
        textUpgrade: <Text isPointer primary weight="strong" onClick={onClick} />,
      }}
      i18nKey={
        'content:side-panel-components.optimization.section.credits.warning-message_interval'
      }
      values={{ amountToUnlock, count: creditLeft, postProcess: 'interval', type: label }}
    />
  );
};

function CreditWarningMessage({ amountToUnlock = 10, type }) {
  const dispatch = useDispatch();

  const remainingCredits = useParamSelector(selectCredits, getApiCreditType(type), 'remaining');
  const maxAmountToUnlock = amountToUnlock > 10 ? 10 : amountToUnlock;

  const onMessageClick = () => {
    dispatch(showUpgradePlanModal());
  };

  return maxAmountToUnlock > remainingCredits && maxAmountToUnlock > 0 ? (
    <WarningMessage>
      {getMessage(type, remainingCredits, maxAmountToUnlock, onMessageClick)}
    </WarningMessage>
  ) : null;
}

CreditWarningMessage.propTypes = {
  amountToUnlock: PropTypes.number,
  type: PropTypes.string.isRequired,
};

export default CreditWarningMessage;
