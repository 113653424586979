import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { RedirectionBadge } from '@/components/Badge/Badge';
import InfoBadge, { useBadge } from '@/components/Badge/InfoBadge';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import Flex from '@/design-system/components/Flex';

const Wrapper = styled.div`
  font-weight: 500;
  font-size: ${(props) => props.theme.text.sizes.small};
  color: ${(props) => props.theme.text.colors.light};
  justify-content: space-between;
  background-color: ${(props) => props.theme.colors.lightGrey};
  padding: 10px 3em 10px 3em;
`;
const TooltipNode = styled.a`
  color: inherit;
  text-decoration: underline;
  text-decoration-style: dotted;
  cursor: pointer;
`;

const ERROR_MOVED_PERMANENTLY = 301;

export default function RedirectionHeader({ redirectionUrl, lastStatusCode }) {
  const { t } = useTranslation();
  // TODO TO REMOVE LATER
  const temporaryFallback = lastStatusCode ?? (redirectionUrl && ERROR_MOVED_PERMANENTLY);
  const [{ badgeTitle, color }] = useBadge(temporaryFallback);

  if (!badgeTitle) return null;

  return (
    <Wrapper>
      {temporaryFallback >= 300 && temporaryFallback < 400 ? (
        <Trans
          components={{
            badge: <RedirectionBadge statusCode={temporaryFallback} />,
            container: <Flex alignItems="center" gap="5px" maxHeight="35px" />,
            tooltip: (
              <TooltipComponent
                description={t('components:content.redirection-header.tooltip-description')}
                title={t('components:content.redirection-header.tooltip-title', {
                  statusCode: temporaryFallback,
                })}
              />
            ),
            tooltipNode: (
              <TooltipNode href={redirectionUrl} rel="noopener noreferrer" target="_blank" />
            ),
          }}
          i18nKey={'components:content.redirection-header.content'}
        />
      ) : (
        <Flex alignItems="center" gap="5px" maxHeight="35px">
          <TooltipComponent title={badgeTitle}>
            <InfoBadge color={color}>{temporaryFallback}</InfoBadge>
          </TooltipComponent>
          <span>{t('components:badge.not-found-badge.description')}</span>
        </Flex>
      )}
    </Wrapper>
  );
}
