import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Avatar } from 'semji-core/components/Avatar';
import { useHover } from 'semji-core/hooks/useHover';
import styled from 'styled-components/macro';

import { useContentContainerContext } from '@/containers/Content/ContentContainerContext';
import Comment from '@/containers/Content/TinyMceComponents/Comments/SidePanel/Comment';
import Delete from '@/containers/Content/TinyMceComponents/Comments/SidePanel/Delete';
import TextArea from '@/containers/Content/TinyMceComponents/Comments/SidePanel/TextArea';
import {
  ThreadQuote,
  ThreadWrapper,
} from '@/containers/Content/TinyMceComponents/Comments/SidePanel/Thread/Thread.styled';
import { CommentDOMUtils } from '@/containers/Content/TinyMceComponents/Editor/hooks/useComment/commentDom.utils';
import { THREAD_CARD_PREFIX_ID } from '@/containers/Content/TinyMceComponents/Editor/hooks/useComment/constants';
import Flex from '@/design-system/components/Flex';

function Input({ threadId, threadAnchor }) {
  const apiRootUrl = import.meta.env.VITE_REACT_APP_SEMJI_API_ROOT_URL;
  const user = useSelector((state) => state.user);
  const { t } = useTranslation();

  const {
    comments: { isPendingThread, postThread, postComment },
  } = useContentContainerContext();
  const isNewThread = isPendingThread(threadId);

  function handleSubmit(content) {
    if (!isNewThread) {
      postComment.mutate({ content, threadId });
    } else {
      postThread.mutate({ anchor: threadAnchor, content });
    }
  }

  return (
    <Flex gap="4px" width="100%">
      <Avatar
        apiRootUrl={apiRootUrl}
        className="side-panel-comments__avatar"
        email={user.email}
        firstName={user.firstName}
        lastName={user.lastName}
        profileImageHash={user.profileImageHash}
        size="22px"
        translations={{ unassigned: t('common:user-picker.unassigned') }}
        uploadedAvatarUrl={user.uploadedAvatarUrl}
      />
      <TextArea
        className="side-panel-comments__click-away-listener"
        threadId={threadId}
        onSubmit={handleSubmit}
      />
    </Flex>
  );
}

const EllepsisDot = styled.div`
  background-color: #d9d9d9;
  height: 4px;
  width: 4px;
  border-radius: 50%;
`;

const UnattachedLabel = styled.div`
  font-style: italic;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 13px;
  margin-left: 2px;
  line-height: ${({ theme }) => theme.textCss.lineHeight};
  color: ${({ theme }) => theme.cssColors.dark040};
`;

const ThreadQuoteWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  gap: 10px;
`;

function Thread({ thread, comments, users }) {
  const { t } = useTranslation();
  const [hoverRef, isHovered] = useHover();

  const {
    comments: { deleteThread, isFocusOnThread, focusOnThread },
  } = useContentContainerContext();

  const [showDeleteThreadRender, setShowDeleteThreadRender] = useState(false);

  const elements = CommentDOMUtils.getElementsByDataAttribute(thread.id);
  const isThreadUnattached = elements.length === 0;
  const isCurrentThread = isFocusOnThread(thread.id);
  const isThreadResolved = !!thread.resolvedAt;

  const threadAnchor = getThreadAnchorText();

  function getThreadAnchorText() {
    // the fallback on api value if the span is deleted from content
    if (elements.length === 0) {
      return thread.anchor;
    }

    let threadAnchorText = elements[0].innerText;
    let previousParentNode = elements[0].parentNode;

    for (let i = 1; i < elements.length; i++) {
      const currentParentNode = elements[i].parentNode;
      const elementInnerText = elements[i].innerText;

      if (!previousParentNode.isSameNode(currentParentNode)) {
        threadAnchorText += ' ' + elementInnerText;
        previousParentNode = currentParentNode;
      } else {
        threadAnchorText += elementInnerText;
      }
    }

    return threadAnchorText.trim();
  }

  function handleCloseDeleteThreadRender() {
    setShowDeleteThreadRender(false);
  }

  function handleDeleteThread() {
    deleteThread.mutate({ threadId: thread.id });
  }

  function handleClick(e) {
    e.stopPropagation();
    if (!isCurrentThread) {
      focusOnThread(thread.id);
    }
  }

  if (!thread) {
    return null;
  }

  return (
    <ThreadWrapper
      ref={hoverRef}
      flexDirection="column"
      gap="16px"
      id={`${THREAD_CARD_PREFIX_ID}${thread.id}`}
      isFocus={isCurrentThread}
      isHovered={isHovered}
      isUnattached={isThreadUnattached}
      padding="20px"
      position="relative"
      onClick={handleClick}
    >
      <ThreadQuoteWrapper>
        <ThreadQuote isThreadResolved={isThreadResolved}>{threadAnchor}</ThreadQuote>
        {isThreadUnattached && (
          <UnattachedLabel>
            <EllepsisDot />
            {t('comments:unattached')}
          </UnattachedLabel>
        )}
      </ThreadQuoteWrapper>

      <Flex flexDirection="column" gap="8px">
        <Flex flexDirection="column" gap="12px">
          {comments
            ?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
            ?.map((comment, index) => (
              <Comment
                key={comment.id}
                comment={comment}
                isFirstComment={index === 0}
                isFocus={isCurrentThread}
                isThreadHovered={isHovered}
                isThreadResolved={isThreadResolved}
                setShowDeleteThreadRender={setShowDeleteThreadRender}
                user={users.find((user) => user.id === comment.createdById)}
                users={users}
              />
            ))}
        </Flex>
        {!isThreadResolved && <Input threadAnchor={threadAnchor} threadId={thread.id} />}
      </Flex>
      {showDeleteThreadRender && (
        <Delete
          title={t('comments:delete.delete-thread')}
          onClose={handleCloseDeleteThreadRender}
          onConfirm={handleDeleteThread}
        />
      )}
    </ThreadWrapper>
  );
}

export default Thread;
