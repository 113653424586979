import styled from 'styled-components/macro';

export const RecommendationCell = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 200px;
  max-width: 200px;
  font-size: 0.9em;
`;
