import React from 'react';

import SvgWrapper from './SvgWrapper';

const StarIcon = (props) => (
  <SvgWrapper {...props} viewBox="0 0 11 11">
    <path
      d="M5.10672 0.306682C5.20947 -0.102228 5.79053 -0.102227 5.89328 0.306682L6.79808 3.90746C6.83449 4.05236 6.94764 4.16551 7.09254 4.20192L10.6933 5.10672C11.1022 5.20947 11.1022 5.79053 10.6933 5.89328L7.09254 6.79808C6.94764 6.83449 6.83449 6.94764 6.79808 7.09254L5.89328 10.6933C5.79053 11.1022 5.20947 11.1022 5.10672 10.6933L4.20192 7.09254C4.16551 6.94764 4.05236 6.83449 3.90746 6.79808L0.306682 5.89328C-0.102228 5.79053 -0.102227 5.20947 0.306682 5.10672L3.90746 4.20192C4.05236 4.16551 4.16551 4.05236 4.20192 3.90746L5.10672 0.306682Z"
      fill="currentColor"
    />
  </SvgWrapper>
);

export default StarIcon;
