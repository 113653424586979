import './FocusKeywordsSelection.scss';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { LOCATION } from '@/apis/semji/constants';
import useGetCurrentWorkspacePagesWithSpecificProperties from '@/apis/semji/pages/useGetCurrentWorkspacePagesWithSpecificProperties';
import EmptyStatePagesImport from '@/components/EmptyState/PagesImport';
import MessageSearchConsole from '@/components/Message/MessageSearchConsole';
import KeywordsSelection from '@/components/Pages/KeywordsSelection';
import { showErrorSnackbar } from '@/store/actions/ui';
import { sortPagesByURLOrderSelection } from '@/utils/sorter';

const LOADING_ROWS_PLACEHOLDER = Array(5).fill({
  loading: true,
});

function FocusKeywordsSelection({
  hasSearchConsole,
  importing,
  pagesToImport,
  setCanPagesBeImported,
  setFocusKeywordsAreLoading,
  setIsPagesListEmpty,
  setIsPagesListLoading,
  workspaceCountryName,
  setIsFetching,
  setState,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [pages, setPages] = useState([]);

  const pagesWithSpecificProperties = useGetCurrentWorkspacePagesWithSpecificProperties({
    filters: { 'exists[url]': true, inStock: true },
    location: LOCATION.IMPORT_PAGES,
    onError: () => dispatch(showErrorSnackbar(t('common:error.default'))),
    onSuccess: (data) => {
      const sortedPages = sortPagesByURLOrderSelection(
        pagesToImport.map((elem) => elem.url),
        data
      );
      const result = sortedPages.filter(
        (page) => !!pagesToImport.find((pageToImport) => pageToImport.id === page.id)
      );

      setCanPagesBeImported(
        result
          .filter((page) => !page.redirectionUrl)
          .every(
            (page) =>
              page.focusTopKeyword !== null &&
              page.focusTopKeyword?.lastSearchConsoleUpdatedAt !== null
          )
      );
      setFocusKeywordsAreLoading(
        result
          .filter((page) => !page.redirectionUrl)
          .some((page) => page.topKeywordsRetrievedAt === null)
      );
      setIsPagesListEmpty(result.length === 0);
      setPages(result);
      setIsPagesListLoading(false);
    },
    properties: [
      'id',
      'url',
      'title',
      'contentScore',
      'draft',
      'extra',
      'optimizedImages',
      'imageUrl',
      'source',
      'new',
      'redirectionUrl',
      'focusTopKeyword',
      'titleRetrievedAt',
      'topKeywordsRetrievedAt',
      'lastStatusCode',
    ],
    refetchInterval: importing ? false : 5000,
  });

  const isLoading =
    pagesWithSpecificProperties.isPlaceholderData && !pagesWithSpecificProperties.isFetched;

  useEffect(() => {
    return () => {
      pagesWithSpecificProperties.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoading) {
      setIsPagesListLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  if (pages.length === 0 && !isLoading) {
    return (
      <div className="focus-keyword-selection">
        {!hasSearchConsole && (
          <MessageSearchConsole message={t('pages:step.urls.warning-message')} />
        )}
        <div className="focus-keyword-selection__card">
          <EmptyStatePagesImport />
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="focus-keyword-selection">
        {!hasSearchConsole && (
          <MessageSearchConsole message={t('pages:step.urls.warning-message')} />
        )}
        <div className="focus-keyword-selection__card">
          <KeywordsSelection
            hasSearchConsole={hasSearchConsole}
            rows={isLoading ? LOADING_ROWS_PLACEHOLDER : pages}
            setIsFetching={setIsFetching}
            setPages={setPages}
            setState={setState}
            workspaceCountryName={workspaceCountryName}
          />
        </div>
      </div>
    </>
  );
}

export default FocusKeywordsSelection;
