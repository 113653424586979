import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import { StyledListItem } from '@/components/Select/SelectComponent';
import ContentScoreCss from '@/components/Table/Cell/ContentScoreCss';
import { DEFAULT_VERSION } from '@/utils/constants';

const ORDER_WITH_CONTENT_TYPE = {
  DEFAULT: 0,
  DRAFT: 1,
  ORIGINAL: 3,
  PUBLISHED: 2,
};

const MenuProps = {
  PaperProps: {
    style: {
      marginTop: '32px',
      maxHeight: 350,
      width: 275,
    },
  },
};

const StyledSelect = styled(({ children, hideInput, ...props }) => (
  <Select classes={{ root: 'root', selectMenu: 'selectMenu' }} {...props}>
    {children}
  </Select>
))`
  .no-input {
    display: none;
  }
  .root {
    color: ${(props) => props.theme.colors.greyOpaque};
    margin: 0;
  }

  && {
    .selectMenu {
      display: flex;
      align-items: center;
      background-color: transparent;
    }
  }
`;

const ListTitle = styled.div`
  margin: 17px 0 12px 17px;
`;

const StyledListItemText = styled((props) => (
  <ListItemText {...props} classes={{ primary: 'primary' }} />
))`
  .primary {
    font-size: ${(props) => props.theme.text.sizes.small};
    color: inherit;
  }
  .name {
    width: 60px;
  }
  .date {
    width: 50px;
  }
  .score {
    width: 40px;
  }
`;

function VersionSelect() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { organizationId, workspaceId, pageId, contentId } = useParams();
  const [localValue, setLocalValue] = useState(null);

  const currentDraftScore = useSelector((state) => state.report.score);
  const { pageContents } = useSelector((state) => state.editor);

  const versions = buildVersionsList(pageContents);
  const match = useMatch('/o/:organizationId/w/:workspaceId/p/:pageId/:content/:contentId/*');

  function handleChange(event) {
    if (event.target.value) {
      if (DEFAULT_VERSION.id === event.target.value) {
        navigate(`/o/${organizationId}/w/${workspaceId}/p/${pageId}/create`);
      } else {
        navigate(`/o/${organizationId}/w/${workspaceId}/p/${pageId}/content/${event.target.value}`);
      }
      if (match?.params?.contentId === event.target.value) {
        setLocalValue(event.target.value);
      }
    }
  }

  function buildVersionsList(editorPageContents) {
    const draftIndex = editorPageContents.findIndex((o) => o.type === 'DRAFT');

    if (draftIndex !== -1 && contentId && editorPageContents[draftIndex].id === contentId) {
      editorPageContents[draftIndex].contentScore = currentDraftScore;
    }

    if (draftIndex === -1) {
      return [...editorPageContents, DEFAULT_VERSION];
    }

    return [...editorPageContents];
  }

  function getDefaultVersionId() {
    const version = versions.find((version) => version.id === contentId);

    return version?.id || DEFAULT_VERSION.id;
  }

  const sortOptions = (options) => {
    return options.sort(function (a, b) {
      if (ORDER_WITH_CONTENT_TYPE[a.type] < ORDER_WITH_CONTENT_TYPE[b.type]) return -1;
      if (
        ORDER_WITH_CONTENT_TYPE[a.type] === ORDER_WITH_CONTENT_TYPE[b.type] &&
        ORDER_WITH_CONTENT_TYPE[b.type] === ORDER_WITH_CONTENT_TYPE.PUBLISHED
      ) {
        return new Date(a.publishedAt).getTime() > new Date(b.publishedAt).getTime() ? -1 : 1;
      }
      return 0;
    });
  };

  const renderValue = (option) => {
    const { id, publishedAt, type } = option || {};

    if (id) {
      let label;

      switch (type) {
        case 'DEFAULT':
        case 'DRAFT':
          label = t('content:version-history-panel.current-draft');
          break;
        case 'ORIGINAL':
          label = t('content:version-history-panel.original-version');
          break;
        default:
          label = `${DateTime.fromISO(publishedAt).toFormat('dd MMM yy')}`;
      }

      return <StyledListItemText className="name" primary={label} />;
    }

    return null;
  };

  function handleRenderValue(value) {
    if (!contentId) {
      return null;
    }
    return renderValue(versions.find((option) => option?.id === value));
  }

  function renderOptions(options) {
    const sortedOptions = sortOptions(options);
    const listVersions = [];
    const currentDraft = sortedOptions.find(
      (option) => option.type === 'DEFAULT' || option.type === 'DRAFT'
    );
    const otherVersions = sortedOptions.filter(
      (option) => option.type !== 'DEFAULT' && option.type !== 'DRAFT'
    );

    if (currentDraft.type === 'DEFAULT') {
      listVersions.push(
        <StyledListItem key={currentDraft.id} alignItems="center" value={currentDraft.id}>
          <StyledListItemText className="name" primary={t('select:version-select.current-draft')} />
        </StyledListItem>
      );
    }

    if (currentDraft.type === 'DRAFT') {
      listVersions.push(
        <StyledListItem key={currentDraft.id} alignItems="center" value={currentDraft.id}>
          <StyledListItemText className="name" primary={t('select:version-select.current-draft')} />
          <ContentScoreCss
            extraSmallMode={true}
            score={Math.floor(currentDraft.contentScore * 100)}
          />
        </StyledListItem>
      );
    }

    if (otherVersions.length) {
      listVersions.push(
        <Divider key="divider" />,
        <ListTitle key="version_title">{t('select:version-select.published-version')}</ListTitle>
      );
      otherVersions.forEach((content) => {
        listVersions.push(
          <StyledListItem key={content.id} alignItems="center" value={content.id}>
            <StyledListItemText
              className="name"
              primary={
                content.type === 'ORIGINAL'
                  ? t('content:version-history-panel.original-version')
                  : `${DateTime.fromISO(content.publishedAt).toFormat('dd MMM yy')}`
              }
            />
            <ContentScoreCss
              extraSmallMode={true}
              score={content.contentScore ? Math.floor(content.contentScore * 100) : null}
            />
          </StyledListItem>
        );
      });
    }

    return listVersions;
  }

  if (!versions.length) {
    return null;
  }
  return (
    <FormControl>
      <StyledSelect
        input={<Input disableUnderline id="version-select" />}
        MenuProps={MenuProps}
        renderValue={handleRenderValue}
        value={localValue || getDefaultVersionId()}
        onChange={handleChange}
      >
        {renderOptions(versions)}
      </StyledSelect>
    </FormControl>
  );
}

export default VersionSelect;
