import { nanoid } from 'nanoid';
import { useMemo } from 'react';

import SvgWrapper from './SvgWrapper';

function BriefIcon(props) {
  const clipId = useMemo(() => nanoid(), []);
  const maskId = useMemo(() => nanoid(), []);

  return (
    <SvgWrapper {...props} height="17" viewBox="0 0 18 17" width="18">
      <g clipPath={`url(#${clipId})`}>
        <mask
          height="18"
          id={maskId}
          maskUnits="userSpaceOnUse"
          style={{ maskType: 'alpha' }}
          width="17"
          x="1"
          y="0"
        >
          <path d="M12.167 0H3.516V1.984H3C2.173 1.984 1.5 2.657 1.5 3.484V12.072L4.021 17.028L6.485 12.069V3.484C6.485 2.657 5.812 1.984 4.985 1.984H4.516V1H11.5V6H16.5V16H5V17H17.5V5.308L12.167 0ZM4.408 14.002H3.604L2.5 11.832V5.984H3.5V12.011H4.5V5.984H5.484V11.835L4.408 14.002ZM5.484 3.484V4.984H2.5V3.484C2.5 3.209 2.725 2.984 3 2.984H4.984C5.26 2.984 5.484 3.209 5.484 3.484ZM12.5 1.742L15.773 5H12.5V1.742Z" />
        </mask>
        <g mask={`url(#${maskId})`}>
          <rect height="20" width="20" x="-1.5" y="-1" />
        </g>
      </g>
      <defs>
        <clipPath id={clipId}>
          <rect height="17" transform="translate(0.5)" width="17" />
        </clipPath>
      </defs>
    </SvgWrapper>
  );
}

export default BriefIcon;
