import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';

import { DEFAULT_BACKGROUND, NO_MENU_LAYOUT } from '@/components/Layout/Layout';
import { LayoutLoader } from '@/components/Loader/Loader';
import LayoutWrapper from '@/containers/Layout/Layout';
import PageService from '@/services/Page';
import { showErrorSnackbar } from '@/store/actions/ui';
import { SECTIONS } from '@/utils/log/constants';
import { Log } from '@/utils/log/Log';

function DefaultContentContainer() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pageId, organizationId, workspaceId } = useParams();
  const [isReady, setIsReady] = useState(false);
  const [draftId, setDraftId] = useState(null);
  const [isStarted, setIsStarted] = useState(false);

  const pageService = new PageService(pageId, workspaceId);

  useEffect(() => {
    const getPageData = async () => {
      try {
        const { draft } = await pageService.page;
        setDraftId(draft?.id);
        setIsStarted(draft?.isStarted);
        setIsReady(true);
      } catch (error) {
        Log.report({
          context: 'getPageData',
          error,
          extra: 'Get the page data',
          section: SECTIONS.content.key,
        });
        dispatch(showErrorSnackbar(t('common:error.default')));
      }
    };
    getPageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isReady) {
    return (
      <LayoutWrapper menu={NO_MENU_LAYOUT} variant={DEFAULT_BACKGROUND}>
        <LayoutLoader />
      </LayoutWrapper>
    );
  }

  if (!!draftId && isStarted) {
    return <Navigate to={`/o/${organizationId}/w/${workspaceId}/p/${pageId}/content/${draftId}`} />;
  }

  return <Navigate to={`/o/${organizationId}/w/${workspaceId}/p/${pageId}/create`} />;
}

export default DefaultContentContainer;
