import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ClickableTooltip } from 'semji-core/components/ClickableTooltip';
import { Placements } from 'semji-core/hooks/usePopover';
import styled from 'styled-components/macro';

import WarningIcon from '@/components/icons/WarningIcon';

const StyledWarningIcon = styled(WarningIcon)`
  && {
    font-size: 20px;

    :hover {
      cursor: pointer;
      circle {
        fill: ${({ theme }) => theme.cssColors.dark010};
      }
    }

    circle {
      fill: transparent;
    }

    g > rect {
      fill: ${({ theme }) => theme.cssColors.primary};
    }
  }
`;

const DropdownWrapper = styled.div`
  font-size: 13px;
  line-height: 17px;
  padding: 12px 16px;
  width: 225px;
  background-color: ${({ theme }) => theme.cssColors.white};
  color: ${({ theme }) => theme.cssColors.dark100};
  box-shadow: ${({ theme }) => theme.boxShadow.modal};
  border-radius: 3px;

  div {
    display: inline;
    color: ${({ theme }) => theme.cssColors.primary};
    cursor: pointer;
  }
`;

function FocusTopKeywordAssociation({
  focusTopKeyword,
  isNew = false,
  placement = Placements.Left,
}) {
  const { t } = useTranslation();
  const { organizationId, workspaceId } = useParams();
  const { associatedToPagesCount, associatedToDraftsCount } = focusTopKeyword;
  const minimumCannibalizedPages = isNew ? 0 : 1;

  const hasMultipleAssociatedPages =
    associatedToPagesCount > minimumCannibalizedPages ||
    (associatedToDraftsCount > minimumCannibalizedPages && associatedToPagesCount > 0);
  const hasMultipleAssociatedDrafts =
    associatedToDraftsCount > minimumCannibalizedPages ||
    (associatedToPagesCount > minimumCannibalizedPages && associatedToDraftsCount > 0);

  function handleRedirect({ toPages }) {
    return () => {
      const path = toPages ? 'contents' : 'planning';
      const tab = toPages ? 'all' : 'open';
      const filter = JSON.stringify([
        { comparison: 'eq', uid: 'keyword', value: focusTopKeyword.keyword },
      ]);

      window.open(
        `/o/${organizationId}/w/${workspaceId}/${path}?tab=${tab}&filter=${filter}`,
        '_blank'
      );
    };
  }

  return (
    (hasMultipleAssociatedPages || hasMultipleAssociatedDrafts) && (
      <ClickableTooltip
        anchorElement={
          <StyledWarningIcon data-intercom-target="focus-top-keyword-association-warning" />
        }
        isFrame={false}
        popoverOptions={{
          placement,
        }}
      >
        <DropdownWrapper>
          <Trans
            components={{
              draftsWrapper: hasMultipleAssociatedDrafts ? (
                // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
                <div onClick={handleRedirect({ toPages: false })} />
              ) : (
                <></>
              ),
              pagesWrapper: hasMultipleAssociatedPages ? (
                // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
                <div onClick={handleRedirect({ toPages: true })} />
              ) : (
                <></>
              ),
            }}
            i18nKey={'components:focus-top-keyword-association.text'}
            values={{
              drafts: hasMultipleAssociatedDrafts
                ? t('components:focus-top-keyword-association.drafts_interval', {
                    count: focusTopKeyword.associatedToDraftsCount,
                    postProcess: 'interval',
                  })
                : '',
              hasDraftsAndPages:
                hasMultipleAssociatedDrafts && hasMultipleAssociatedPages
                  ? t('components:focus-top-keyword-association.and')
                  : '',
              pages: hasMultipleAssociatedPages
                ? t('components:focus-top-keyword-association.pages_interval', {
                    count: focusTopKeyword.associatedToPagesCount,
                    postProcess: 'interval',
                  })
                : '',
            }}
          />
        </DropdownWrapper>
      </ClickableTooltip>
    )
  );
}

export default FocusTopKeywordAssociation;
