import './AiContentStreamErrorModal.scss';

import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { PrimaryButton } from 'semji-core/components/Button';
import { WrapperModal } from 'semji-core/components/WrapperModal';

import ErrorImage from '@/assets/images/empty-state-probleme.svg';
import { DefaultLink } from '@/components/Navigation/Link';
import { SUPPORT_ADDRESS_MAIL } from '@/utils/constants';

export function AiContentStreamErrorModal({
  showStreamErrorModal,
  setShowStreamErrorModal,
  handleAtomicContent,
}: {
  showStreamErrorModal: boolean;
  setShowStreamErrorModal: (show: boolean) => void;
  handleAtomicContent: () => void;
}) {
  const { t } = useTranslation();

  function onClose() {
    setShowStreamErrorModal(false);
  }

  function handleRegenerateAtomicContent() {
    setShowStreamErrorModal(false);
    handleAtomicContent();
  }

  return (
    <WrapperModal
      description={
        <Trans
          components={{
            extLink: (
              <DefaultLink
                color="red"
                decoration
                isExternal
                noDynamicFontSize
                noPadding
                to={`mailto:${SUPPORT_ADDRESS_MAIL}`}
                weight="medium"
              />
            ),
          }}
          i18nKey="errors:ai-content-stream-error.description"
        />
      }
      illustration={<img alt="" src={ErrorImage} />}
      isOpen={showStreamErrorModal}
      title={t('errors:ai-content-stream-error.title')}
      onClose={onClose}
    >
      <div className="ai-content-stream-error-modal">
        <PrimaryButton
          className="ai-content-stream-error-modal__button"
          onClick={handleRegenerateAtomicContent}
        >
          {t('content:ai-writing.generate-again')}
        </PrimaryButton>
      </div>
    </WrapperModal>
  );
}
