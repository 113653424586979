import { useQuery } from '@tanstack/react-query';

import apiClient from '@/apis/semji/apiClient';
import { SCOPE_COMPETITORS } from '@/apis/semji/constants';
import { QueryOptions } from '@/apis/types/query.types';
import { CompetitorPersona } from '@/types/competitors';

interface UseGetCompetitorPersonasProps extends QueryOptions<{}, CompetitorPersona[]> {
  competitorReportId: string;
}

export default function useGetCompetitorPersonas({
  competitorReportId,
  ...props
}: UseGetCompetitorPersonasProps) {
  const { get } = apiClient;

  return useQuery({
    placeholderData: [],
    queryFn: async ({ signal }) => {
      const result = await get(`/competitor_reports/${competitorReportId}/competitor_personas`, {
        signal,
      });

      return result.data['hydra:member'] || [];
    },
    queryKey: [SCOPE_COMPETITORS.GET_COMPETITOR_PERSONAS, competitorReportId],
    ...props,
  });
}
