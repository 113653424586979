import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import apiClient from '@/apis/semji/apiClient';
import { showErrorSnackbar } from '@/store/actions/ui';

export default function useDeleteContentById(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const workspaceId = useSelector((state) => state.defaultWorkspaceId || '');
  const { delete: deleteRequest } = apiClient;

  return useMutation({
    mutationFn: async (contentsIds = []) =>
      await deleteRequest(`/workspaces/${workspaceId}/contents/bulk_delete`, {
        data: { contentsIds },
      }),

    onError: () => {
      dispatch(showErrorSnackbar(t('common:error.default')));
    },
    ...props,
  });
}
