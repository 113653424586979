import React from 'react';

import SvgWrapper from './SvgWrapper';

const ClusterIcon = ({ color, ...props }) => (
  <SvgWrapper {...props} viewBox="0 0 8 14">
    <g>
      <path d="M0 0.5V13.6036L3.97203 10.8318L8 13.5951V0.5H0Z" fill={color ?? 'currentColor'} />
    </g>
  </SvgWrapper>
);

export default ClusterIcon;
