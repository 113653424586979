import LuxonUtils from '@date-io/luxon';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import i18next from 'i18next';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import AgendaIcon from '@/components/icons/Agenda';
import defaultTheme from '@/themes/defaultTheme';

const DatePickerWrapper = styled(({ shortVersion, disabled, small, ...props }) => (
  <div {...props} />
))`
  width: ${(props) => (props.small ? '75px' : '115px')};
  height: 32px;
  align-items: center;
  justify-content: ${(props) => props.shortVersion && 'center'};
  border-radius: 18px;
  display: inline-flex;
  flex: 1 1 auto;
  min-width: 1px;
  padding: 3px;
  position: relative;
  transition:
    0.2s box-shadow,
    0.2s color;
  border-style: dashed;
  & > div {
    gap: 10px;
  }
  &&&:hover {
    background-color: ${(props) =>
      !props.shortVersion && !props.disabled && props.theme.colors.brightGrey};
    border-style: ${(props) => !props.disabled && 'solid'};
  }
`;

const MyAgendaIcon = styled(AgendaIcon)`
  && {
    fill: ${(props) => props.theme.cssColors.dark040};
    font-size: 1.3em;
  }
`;

const AgendaWrapper = styled.div`
  cursor: ${(props) => (props.disabled ? 'inherit' : 'pointer')};
  border-width: 1px;
  border-color: ${(props) => props.theme.cssColors.dark040};
  border-style: inherit;
  background: transparent;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ size }) => size || '30px'};
  width: ${({ size }) => size || '30px'};
`;

const SmartDayText = ({ isCurrentYear, currentDate, isToday, isYesterday, isTomorrow }) => {
  if (isToday) {
    return i18next.t('common:date-picker.today');
  }
  if (isYesterday) {
    return i18next.t('common:date-picker.yesterday');
  }
  if (isTomorrow) {
    return i18next.t('common:date-picker.tomorrow');
  }
  if (isCurrentYear) {
    return currentDate.toFormat('MMM dd');
  }

  return currentDate.toFormat('MMM dd, yy');
};

const DateColorCalculator = styled.span`
  color: ${(props) =>
    props.defaultColor
      ? props.theme.colors.greyOpaque
      : props.isPreviousDate
        ? props.theme.colors.red
        : props.isToday || props.isTomorrow
          ? props.theme.colors.green
          : props.theme.colors.greyOpaque};
`;

const Text = ({ children, defaultColor, locale, ...props }) => {
  const currentDate = DateTime.fromFormat(children, 'dd LLLL yyyy', { locale });
  if (currentDate.isValid) {
    const today = DateTime.fromISO(new Date().toISOString());
    const yesterday = DateTime.fromISO(new Date().toISOString()).minus({ days: 1 });
    const tomorrow = DateTime.fromISO(new Date().toISOString()).plus({ days: 1 });
    const isCurrentYear = currentDate.hasSame(today, 'year');
    const isToday =
      today.hasSame(currentDate, 'day') &&
      today.hasSame(currentDate, 'month') &&
      today.hasSame(currentDate, 'year');
    const isYesterday =
      yesterday.hasSame(currentDate, 'day') &&
      yesterday.hasSame(currentDate, 'month') &&
      yesterday.hasSame(currentDate, 'year');
    const isTomorrow =
      tomorrow.hasSame(currentDate, 'day') &&
      tomorrow.hasSame(currentDate, 'month') &&
      tomorrow.hasSame(currentDate, 'year');
    const isPreviousDate = currentDate.toSeconds() <= yesterday.toSeconds();

    return (
      <DateColorCalculator
        defaultColor={defaultColor}
        isPreviousDate={isPreviousDate}
        isToday={isToday}
        isTomorrow={isTomorrow}
        {...props}
      >
        <SmartDayText
          currentDate={currentDate}
          isCurrentYear={isCurrentYear}
          isToday={isToday}
          isTomorrow={isTomorrow}
          isYesterday={isYesterday}
        />
      </DateColorCalculator>
    );
  }
  return <span {...props}>{children}</span>;
};

const TextWrapper = styled.div`
  width: ${({ $isFullWidth }) => $isFullWidth && '100%'};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  display: flex;
  border-style: inherit;
  font-weight: ${({ theme }) => theme.textCss.weights.normal};
  align-items: center;
  font-size: ${({ theme }) => theme.textCss.sizes.sm};
  color: ${({ theme }) => theme.cssColors.dark080};
  padding: 0.1em 0 0 0;
`;

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersDay: {
      daySelected: {
        backgroundColor: defaultTheme.colors.secondary,
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        background: defaultTheme.colors.primaryGradient,
      },
    },
  },
});

function DatePickerComponent({
  disabled,
  handleChange,
  dueDate,
  shortVersion,
  defaultColor,
  small,
  inputMode,
  label,
  isInputFullWidth,
  size,
  margin = 'normal',
}) {
  const { t } = useTranslation();
  const [dueDateValue, setDueDateValue] = useState();
  const userLanguageCode = useSelector((state) => state.user.languageCode);

  function handleChangeDate(date) {
    if (!disabled) {
      setDueDateValue(date);
      handleChange(date);
    }
  }

  if (inputMode) {
    return (
      <MuiPickersUtilsProvider locale={userLanguageCode} utils={LuxonUtils}>
        <ThemeProvider theme={materialTheme}>
          <KeyboardDatePicker
            cancelLabel={t('common:date-picker.clear')}
            format="dd LLLL yyyy"
            label={label}
            margin={margin}
            // eslint-disable-next-line react/jsx-no-bind
            TextFieldComponent={({ onChange, ...textFieldProps }) => (
              <TextField {...textFieldProps} />
            )}
            value={dueDateValue || dueDate || null}
            onChange={handleChangeDate}
          />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    );
  }

  return (
    <DatePickerWrapper disabled={disabled} shortVersion={shortVersion} small={small}>
      <MuiPickersUtilsProvider locale={userLanguageCode} utils={LuxonUtils}>
        <ThemeProvider theme={materialTheme}>
          <DatePicker
            cancelLabel={t('common:date-picker.cancel')}
            clearable
            clearLabel={t('common:date-picker.clear')}
            disabled={disabled}
            format="dd LLLL yyyy"
            // eslint-disable-next-line react/jsx-no-bind
            TextFieldComponent={(props) => (
              <TextWrapper $isFullWidth={isInputFullWidth} disabled={disabled} {...props}>
                {shortVersion ? (
                  props.value && dueDateValue !== null ? (
                    <Text defaultColor={defaultColor} locale={userLanguageCode}>
                      {props.value}
                    </Text>
                  ) : (
                    <AgendaWrapper disabled={disabled} size={size}>
                      <MyAgendaIcon />
                    </AgendaWrapper>
                  )
                ) : (
                  <>
                    <AgendaWrapper disabled={disabled} size={size}>
                      <MyAgendaIcon />
                    </AgendaWrapper>
                    <Text defaultColor={defaultColor} locale={userLanguageCode}>
                      {props.value || t('common:date-picker.label')}
                    </Text>
                  </>
                )}
              </TextWrapper>
            )}
            value={dueDateValue || dueDate || null}
            onChange={handleChangeDate}
          />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </DatePickerWrapper>
  );
}

export default DatePickerComponent;
