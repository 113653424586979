import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import apiClient from '@/apis/semji/apiClient';
import { SCOPE_USERS } from '@/apis/semji/constants';
import { QueryOptions } from '@/apis/types/query.types';
import { UserModel as User } from '@/types/user/user.types';

interface UseGetWorkspaceUsersProps extends QueryOptions<{}, User[]> {}

export default function useGetWorkspaceUsers(props: UseGetWorkspaceUsersProps) {
  const { get } = apiClient;
  const { workspaceId } = useParams();

  return useQuery({
    placeholderData: [],
    queryFn: async ({ signal }) => {
      const { data } = await get(`/workspaces/${workspaceId}/users`, { signal });

      return (data?.['hydra:member'] || []).sort((a, b) =>
        `${a.firstName || ''}${a.lastName || ''}${a.email}`
          .trim()
          .localeCompare(`${b.firstName || ''}${b.lastName || ''}${b.email}`.trim())
      );
    },
    queryKey: [SCOPE_USERS.GET_ALL_BY_WORKSPACE_ID, workspaceId],
    ...props,
  });
}
