import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import MessageIcon from '@/components/icons/MessageIcon';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import { useContentContainerContext } from '@/containers/Content/ContentContainerContext';
import { ENUM_COMMENT_SIDE_PANEL_OPEN_STATUS } from '@/containers/Content/TinyMceComponents/Editor/hooks/useComment/useComments.types';

import { ActionIconWrapper } from './OpenCommentsButton.styled';

function OpenCommentsButton() {
  const { t } = useTranslation();
  const { contentId } = useParams();
  const {
    comments: { openComments },
  } = useContentContainerContext();

  function handleOpenComments() {
    openComments(ENUM_COMMENT_SIDE_PANEL_OPEN_STATUS.COMMENTS_OPEN_VALUE);
  }

  if (!contentId) {
    return null;
  }

  return (
    <TooltipComponent cursor="pointer" title={t('comments:open-comment-button')}>
      <ActionIconWrapper
        backgroundColor="white"
        borderRadius="100%"
        color="primary"
        data-intercom-target="side_panel_comments_history"
        padding="8px"
        onClick={handleOpenComments}
      >
        <MessageIcon />
      </ActionIconWrapper>
    </TooltipComponent>
  );
}

export default OpenCommentsButton;
