import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import apiClient from '@/apis/semji/apiClient';
import { SCOPE_BRAND_VOICES } from '@/apis/semji/constants';
import { BrandVoiceModel } from '@/types/brandVoice/brandVoice.types';

export default function useGetBrandVoiceById({
  brandVoiceId,
  ...props
}: {
  brandVoiceId: string;
  props?: UseQueryOptions;
}): UseQueryResult<BrandVoiceModel> {
  const { get } = apiClient;

  return useQuery({
    enabled: !!brandVoiceId,
    queryFn: async ({ signal }) => {
      const result = await get(`/deprecated_brand_voices/${brandVoiceId}`, {
        signal,
      });

      return result.data;
    },
    queryKey: [SCOPE_BRAND_VOICES.GET_BRAND_VOICE_BY_ID, brandVoiceId],
    ...props,
  });
}
