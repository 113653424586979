import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import { LOCATION } from '@/apis/semji/constants';
import useGetWorkspaceById from '@/apis/semji/workspace/useGetWorkspaceById';
import usePutWorkspaceBlacklistedTerms from '@/apis/semji/workspace/usePutWorkspaceBlacklistedTerms';
import { FlexAlignedStartContainer } from '@/components/_common';
import { PrimaryButton } from '@/components/Button/Button';
import InputSmartList from '@/components/Input/InputSmartList';
import Loader from '@/components/Loader/Loader';
import { TabBodyContent, TabBodyTitle } from '@/components/Navigation/Tabs';
import { SmallBrightText } from '@/components/Text/Light';
import { noop } from '@/utils/noop';

const Wrapper = styled.div`
  max-width: 700px;
`;
const StyledFlexAlignedStartContainer = styled(FlexAlignedStartContainer)`
  margin: 1rem 5px;
`;

function KeywordsRelevanceSettingsContainer({ title }) {
  const { t } = useTranslation();
  const { workspaceId } = useParams();
  const [blacklistedTerms, setBlacklistedTerms] = useState(null);
  const [saved, setSaved] = useState(true);

  const workspace = useGetWorkspaceById({
    location: LOCATION.SETTINGS,
    onSuccess: (workspace) => {
      setBlacklistedTerms(workspace.blacklistedTerms);
    },
    workspaceId,
  });

  const putWorkspaceBlacklistedTerms = usePutWorkspaceBlacklistedTerms({ workspaceId });

  useEffect(() => {
    return () => {
      workspace.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function setBlackTerms() {
    setSaved(true);
    putWorkspaceBlacklistedTerms.mutate(blacklistedTerms.filter((term) => term));
  }

  function handleGetList(blacklistedTerms) {
    setBlacklistedTerms(blacklistedTerms);
    setSaved(false);
  }

  if (
    workspace.isFetched &&
    blacklistedTerms !== null &&
    !workspace.isPlaceholderData &&
    !workspace.isFetching
  ) {
    return (
      <TabBodyContent>
        <TabBodyTitle noMargin>{title}</TabBodyTitle>
        <Wrapper>
          <SmallBrightText>
            {t('settings:workspace.keywords-relevance-settings.text')}
          </SmallBrightText>
          <InputSmartList
            getList={handleGetList}
            helperText={noop}
            inputs={[...blacklistedTerms, '']}
            isError={noop}
            placeholder={t('settings:workspace.keywords-relevance-settings.input-placeholder')}
          />
          <StyledFlexAlignedStartContainer>
            <PrimaryButton disabled={saved} onClick={setBlackTerms}>
              {t(
                saved
                  ? 'settings:workspace.keywords-relevance-settings.button-saved'
                  : 'settings:workspace.keywords-relevance-settings.button-save'
              )}
            </PrimaryButton>
          </StyledFlexAlignedStartContainer>
        </Wrapper>
      </TabBodyContent>
    );
  }
  return (
    <TabBodyContent>
      <Loader />
    </TabBodyContent>
  );
}

export default KeywordsRelevanceSettingsContainer;
