export class ObjectUtils {
  // Will stringify object and check strict string equality
  // Even if objects are equals, will return false if properties aren't sorted
  static fastEqual(a: { [key: string]: any }, b: { [key: string]: any }): boolean {
    return JSON.stringify(a) === JSON.stringify(b);
  }

  // Check every property and value to check properties strict equality
  // Slow performance on huge objects
  static deepEqual(a: { [key: string]: any }, b: { [key: string]: any }): boolean {
    if (a === b) return true;
    if (Array.isArray(a) && Array.isArray(b)) {
      if (a.length !== b.length) return false;

      return a.every((elem, index) => {
        return ObjectUtils.deepEqual(elem, b[index]);
      });
    }
    if (typeof a === 'object' && typeof b === 'object' && a !== null && b !== null) {
      if (Array.isArray(a) || Array.isArray(b)) return false;

      const keys1 = Object.keys(a);
      const keys2 = Object.keys(b);

      if (keys1.length !== keys2.length || !keys1.every((key) => keys2.includes(key))) return false;

      for (let key in a) {
        let isEqual = ObjectUtils.deepEqual(a[key], b[key]);
        if (!isEqual) {
          return false;
        }
      }
      return true;
    }
    return false;
  }
}
