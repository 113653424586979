import './SidePanelHeader.scss';

import React from 'react';

import { SidePanelHeaderProps } from './SidePanelHeader.types';

function SidePanelHeader({ children }: SidePanelHeaderProps): React.JSX.Element {
  return <div className="side-panel-header">{children}</div>;
}

export default SidePanelHeader;
