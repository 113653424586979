import TextField from '@material-ui/core/TextField';
import trimEnd from 'lodash/trimEnd';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { DialogModal } from 'semji-core/components/DialogModal';
import styled from 'styled-components/macro';

import usePostPublishContent from '@/apis/semji/contents/usePostPublishContent';
import DatePicker from '@/components/DatePicker';
import HelperMessage from '@/components/Message/HelperMessage';
import { DefaultLink, SecondaryLink } from '@/components/Navigation/Link';
import { SmallFlexTooltipComponent as Tooltip } from '@/components/Tooltip/Tooltip';
import { Close, Content, Title } from '@/design-system/components/Dialog';
import Text from '@/design-system/components/Text';
import { setDialogState } from '@/store/actions/dialogs';
import { SUCCESS_MARK_AS_PUBLISHED_DIALOG } from '@/store/reducers/dialogs';
import { isUrlValidWithWebSiteUrl } from '@/utils/url';

const ContentList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 25px;
  border: 1px solid ${({ theme }) => theme.cssColors.dark010};
  border-radius: 10px;
`;

const ContentListItem = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  align-items: ${({ alignItems }) => alignItems};

  & ${Tooltip} {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    padding: 6px;
    width: 30px;
    margin: 0;
  }

  & ${Close} {
    display: none;
  }

  &:hover ${Close} {
    display: flex;
  }

  & ${SecondaryLink} {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  flex: 1;
  overflow: hidden;
`;

function BulkMarkAsPublishedDialog({
  contents,
  currentWorkspaceWebsiteUrl,
  deleteElementInSelectedList,
  selectedList,
  onCancel,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [publishDate, setPublishDate] = useState(DateTime.fromJSDate(new Date()).toISODate());
  const [newContentsMap, setNewContentsMap] = useState({});
  const [loading, setLoading] = useState(false);
  const newContentsList = selectedList.filter((elem) => elem.page.url === null);
  const updatedContentsList = selectedList.filter((elem) => elem.page.url !== null);

  const publishContent = usePostPublishContent({});

  function getTextError(urlToCheck, id) {
    if (!isUrlValidWithWebSiteUrl(urlToCheck, currentWorkspaceWebsiteUrl)) {
      return t('components:dialog.bulk-mark-as-published.error-invalid-url');
    }
    const newObject = { ...newContentsMap };
    delete newObject[id];
    if (
      Object.values(newObject).some((value) => value.url === urlToCheck) ||
      updatedContentsList.some((value) => value.page.url === urlToCheck)
    ) {
      return t('components:dialog.bulk-mark-as-published.error-duplicated-published-content');
    }
    return null;
  }

  function onBlurCheck(urlToCheck, id) {
    return () => {
      let newContentsMapTmp = {
        ...newContentsMap,
        [id]: { error: getTextError(urlToCheck, id), url: urlToCheck },
      };

      for (const elem in newContentsMapTmp) {
        if (
          newContentsMapTmp[elem].error ===
          t('components:dialog.bulk-mark-as-published.error-duplicated-published-content')
        ) {
          const newObject = { ...newContentsMapTmp };
          delete newObject[elem];
          if (
            !Object.values(newObject).some((value) => value.url === newContentsMapTmp[elem].url)
          ) {
            newContentsMapTmp[elem].error = null;
          }
        }
      }
      setNewContentsMap(newContentsMapTmp);
    };
  }

  function onUrlChange(elemId) {
    return (e) =>
      setNewContentsMap({
        ...newContentsMap,
        [elemId]: { url: e.target.value },
      });
  }

  function publishedAll() {
    let isError = null;
    let newContentsMapTmp = {};
    let readyToPublish = true;

    newContentsList.forEach((elem) => {
      const url = newContentsMap[elem.id]?.url || '';
      const error = getTextError(newContentsMap[elem.id]?.url || '', elem.id);

      readyToPublish = !!error ? false : readyToPublish;
      newContentsMapTmp = {
        ...newContentsMapTmp,
        [elem.id]: {
          error,
          url,
        },
      };
    });
    setNewContentsMap(newContentsMapTmp);
    if (!readyToPublish) {
      return;
    }
    setLoading(true);
    Promise.all(
      selectedList.map((elem) =>
        publishContent.mutateAsync({
          contentId: elem.id,
          date: publishDate,
          url: elem.page.url || newContentsMap[elem.id]?.url,
        })
      )
    )
      .catch((error) => {
        isError = t(
          error.response.status === 409
            ? 'components:dialog.bulk-mark-as-published.error-duplicated-url'
            : 'components:dialog.bulk-mark-as-published.error-unknown'
        );
      })
      .finally(async () => {
        const freshContents = (await contents.refetch()).data;
        const errorSelectedList = freshContents.filter(
          (elem) =>
            elem.type === 'DRAFT' &&
            !!selectedList.find((selectedElem) => selectedElem.id === elem.id)
        );
        if (Boolean(isError)) {
          errorSelectedList.forEach((elem) => {
            const url = newContentsMap[elem.id]?.url || '';
            newContentsMapTmp = { ...newContentsMapTmp, [elem.id]: { error: isError, url } };
          });
          setNewContentsMap(newContentsMapTmp);
        } else {
          dispatch(
            setDialogState(SUCCESS_MARK_AS_PUBLISHED_DIALOG, {
              dratfCount: selectedList.length || 0,
              isOpen: true,
            })
          );
        }
        setLoading(false);
      });
  }

  function removeFromSelection(id, url) {
    return () => {
      let newContentsMapTmp = {
        ...newContentsMap,
        [id]: { error: null, url: url },
      };
      setNewContentsMap(newContentsMapTmp);
      deleteElementInSelectedList(id);
    };
  }

  function handleDateChange(id) {
    setPublishDate(id);
  }

  if (selectedList.length === 0) {
    onCancel();
  }

  return (
    <DialogModal
      cancelAction={onCancel}
      cancelLabel={t('components:dialog.bulk-mark-as-published.cancel')}
      canClickOutsideToClose
      closable
      confirmAction={publishedAll}
      confirmLabel={
        loading
          ? t('components:dialog.bulk-mark-as-published.confirm-loading')
          : t('components:dialog.bulk-mark-as-published.confirm')
      }
      id="form-dialog-title"
      isCancelDisabled={loading}
      isConfirmDisabled={loading}
      isOpen={selectedList.length !== 0}
      title={
        <Title id="form-dialog-title">
          {t('components:dialog.bulk-mark-as-published.title_interval', {
            count: selectedList.length,
            postProcess: 'interval',
          })}
        </Title>
      }
      onClose={onCancel}
    >
      <Content gap="25px" id="dialog-description">
        <HelperMessage>
          <Trans
            components={{
              bold: <strong />,
              primaryLink: (
                <DefaultLink
                  color="dark080"
                  decoration
                  isExternal
                  size="sm"
                  to={t('components:dialog.mark-as-published-dialog.help-center-url')}
                  weight="medium"
                />
              ),
            }}
            i18nKey={'components:dialog.mark-as-published-dialog.helper-message'}
          />
        </HelperMessage>

        <div>
          <Text margin="0 0 10px 0">
            {t('components:dialog.bulk-mark-as-published.publication-date')}
          </Text>
          <DatePicker
            dueDate={publishDate}
            handleChange={handleDateChange}
            inputMode
            margin="none"
          />
        </div>

        {newContentsList.length !== 0 ? (
          <ContentList>
            <div>
              <Text>
                {t('components:dialog.bulk-mark-as-published.new-content-label_interval', {
                  count: newContentsList.length,
                  postProcess: 'interval',
                })}
              </Text>
              <Text fontWeight="medium" whiteSpace="normal">
                {t('components:dialog.bulk-mark-as-published.description')}
              </Text>
            </div>

            {newContentsList.map((elem) => (
              <ContentListItem key={elem.id} flexDirection="column">
                <Text color="dark100" title={elem.title || t('common:labels.unknown-title')}>
                  {elem.title || t('common:labels.unknown-title')}
                </Text>
                <Row>
                  <TextField
                    error={Boolean(newContentsMap[elem.id]?.error)}
                    fullWidth
                    helperText={newContentsMap[elem.id]?.error}
                    placeholder={`${trimEnd(currentWorkspaceWebsiteUrl, '/')}/page`}
                    type="url"
                    value={newContentsMap[elem.id]?.url || ''}
                    onBlur={onBlurCheck(newContentsMap[elem.id]?.url || '', elem.id)}
                    onChange={onUrlChange(elem.id)}
                  />
                  <Tooltip
                    title={t('components:dialog.bulk-mark-as-published.remove-from-selection')}
                  >
                    <Close onClick={removeFromSelection(elem.id, elem.url)} />
                  </Tooltip>
                </Row>
              </ContentListItem>
            ))}
          </ContentList>
        ) : null}

        {updatedContentsList.length !== 0 ? (
          <ContentList>
            <Text>
              {t('components:dialog.bulk-mark-as-published.updated-content-label_interval', {
                count: updatedContentsList.length,
                postProcess: 'interval',
              })}
            </Text>

            {updatedContentsList.map((elem) => (
              <ContentListItem key={elem.id} alignItems="center" flexDirection="row">
                <Row flexDirection="column">
                  <Text color="dark100" title={elem.title || t('common:labels.unknown-title')}>
                    {elem.title || t('common:labels.unknown-title')}
                  </Text>
                  <SecondaryLink isExternal to={elem.page.url}>
                    {elem.page.url}
                  </SecondaryLink>
                </Row>
                <Tooltip
                  title={t('components:dialog.bulk-mark-as-published.remove-from-selection')}
                >
                  <Close onClick={removeFromSelection(elem.id, elem.url)} />
                </Tooltip>
              </ContentListItem>
            ))}
          </ContentList>
        ) : null}
      </Content>
    </DialogModal>
  );
}

export default BulkMarkAsPublishedDialog;
