import React from 'react';
import styled from 'styled-components/macro';

import AngleLeft from '@/components/icons/AngleLeft';
import Link from '@/components/Navigation/Link';
import { BackPathWrapper, BackTitleWrapper, BackWrapper } from '@/containers/Content/Wrappers';

const TextWrapper = styled.div`
  max-width: 75%;
`;

function Back({ goTo, title, path, url }) {
  return (
    <BackWrapper>
      <Link to={goTo}>
        <AngleLeft />
      </Link>
      <TextWrapper>
        <BackTitleWrapper>{title}</BackTitleWrapper>
        <BackPathWrapper href={url} target="_blank">
          {path}
        </BackPathWrapper>
      </TextWrapper>
    </BackWrapper>
  );
}

export default Back;
