import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import useGetConfigurations from '@/apis/semji/configuration/useGetConfigurations';
import useApiConfigurations from '@/hooks/useApiConfigurations';
import { notifyUrlUpdate } from '@/services/Intercom';
import { setConfigurations } from '@/store/actions/configurations';
import { RECURSIVE_CALLS_REFETCH_FREQUENCY_MS } from '@/utils/configurations/constants';

const FALLBACK_DELAY = 30000; // 30s

function RecursiveCalls() {
  const DELAY =
    parseInt(useApiConfigurations(RECURSIVE_CALLS_REFETCH_FREQUENCY_MS), 10) || FALLBACK_DELAY;
  const location = useLocation();
  const dispatch = useDispatch();

  useGetConfigurations({
    onSuccess: ({ data: configurations }) => {
      dispatch(setConfigurations(configurations));
    },
    refetchInterval: DELAY,
    refetchOnWindowFocus: 'always',
  });

  useEffect(() => {
    notifyUrlUpdate();
  }, [location.pathname]);

  return null;
}

export default RecursiveCalls;
