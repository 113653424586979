import { useSelector } from 'react-redux';

import useParamSelector from '@/store/hooks/useParamSelector';
import selectFeatureSet from '@/store/selectors/selectFeatureSet';
import selectPlanFeature from '@/store/selectors/selectPlanFeature';

const ADD_ON_FEATURE = 'add-on';

/** ALL feature keys here ?? */
export const AI_WRITING__FACT_CHECKING__IS_ENABLED = 'ai-writing:fact-checking:is-enabled';
export const AI_WRITING__BRAND_VOICE__IS_ENABLED = 'ai-writing:brand-voice:is-enabled';
export const AI_WRITING__KNOWLEDGE_BASE__IS_ENABLED = 'ai-writing:knowledge-base:is-enabled';
export const AI_WRITING__DEPRECATED_BRAND_VOICE__IS_ENABLED =
  'ai-writing:deprecated-brand-voice:is-enabled';
export const AI_WRITING__DEPRECATED_BRAND_VOICE_CONFIGURATOR__IS_ENABLED =
  'ai-writing:deprecated-brand-voice-configurator:is-enabled';
export const COMPETITORS__IS_ENABLED = 'competitors:is-enabled';

export default function useOrganizationFeatureSet(feature) {
  const user = useSelector((state) => state.user);
  const uiMode = useSelector((state) => state.uiMode);
  const data = useParamSelector(selectFeatureSet, feature);
  const planFeature = useParamSelector(selectPlanFeature, feature);

  const isSuperAdminAndAdvancedMode = user?.superAdmin && uiMode === 'advanced';
  const isAddOn = planFeature === ADD_ON_FEATURE;
  const isFeatureSetAddOn = data === ADD_ON_FEATURE;

  if (isSuperAdminAndAdvancedMode) {
    return {
      addOn: {
        isAddOn: false,
        value: true,
      },
      data: data || true,
      isFeatureEnabled: true,
    };
  }
  return {
    addOn: {
      isAddOn,
      value: data,
    },
    data,
    isFeatureEnabled: isFeatureSetAddOn ? false : data,
  };
}
