import DialogContentText from '@material-ui/core/DialogContentText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogModal, ENUM_DIALOG_MODAL_SIZE } from 'semji-core/components/DialogModal';
import styled from 'styled-components/macro';

import Loader from '@/components/Loader/Loader';
import { CSV_EXPORT_TYPE, XLSX_EXPORT_TYPE } from '@/types/export/export.types';

const RadioGroupWrapper = styled.div`
  margin: 20px 0;
`;

export default function ExportDialog({
  handleConfirm,
  handleCancel,
  isAsync,
  open,
  title,
  loading,
  error,
}) {
  const { t } = useTranslation();

  const [selectedValue, setSelectedValue] = useState(XLSX_EXPORT_TYPE.ext);

  function handleChange(event) {
    setSelectedValue(event.target.value);
  }

  function onConfirm() {
    handleConfirm(selectedValue);
  }

  return (
    <DialogModal
      children={
        <>
          {loading ? (
            <Loader opaque style={{ fontSize: '1.5rem' }} />
          ) : (
            <>
              <DialogContentText id="alert-dialog-description">
                {t('components:dialog.export.content')} <br />
              </DialogContentText>
              <RadioGroupWrapper>
                <RadioGroup value={selectedValue} onChange={handleChange}>
                  <FormControlLabel
                    control={<Radio color="secondary" />}
                    label={XLSX_EXPORT_TYPE.label}
                    value={XLSX_EXPORT_TYPE.ext}
                  />
                  <FormControlLabel
                    control={<Radio color="secondary" />}
                    label={CSV_EXPORT_TYPE.label}
                    value={CSV_EXPORT_TYPE.ext}
                  />
                </RadioGroup>
              </RadioGroupWrapper>
            </>
          )}
          {error && (
            <DialogContentText id="alert-dialog-description">
              {t('components:dialog.export.error')}
            </DialogContentText>
          )}
          {isAsync && !loading && (
            <DialogContentText>
              {t('components:dialog.export.export-async-limit')}
            </DialogContentText>
          )}
        </>
      }
      cancelAction={handleCancel}
      cancelLabel={t('components:dialog.export.cancel')}
      canClickOutsideToClose
      closable
      confirmAction={onConfirm}
      confirmLabel={t('components:dialog.export.export')}
      id="form-dialog-title"
      isCancelDisabled={loading}
      isConfirmDisabled={loading}
      isOpen={open}
      size={ENUM_DIALOG_MODAL_SIZE.Large}
      title={title}
      onClose={handleCancel}
    ></DialogModal>
  );
}
