import React from 'react';

import SvgWrapper from './SvgWrapper';

const LockIcon = (props) => (
  <SvgWrapper {...props} viewBox="0 0 8 12">
    <path d="M7 4.40681H6.5V3.39995C6.5 2.01049 5.38 0.882812 4 0.882812C2.62 0.882812 1.5 2.01049 1.5 3.39995V4.40681H1C0.45 4.40681 0 4.85989 0 5.41366V10.4479C0 11.0017 0.45 11.4548 1 11.4548H7C7.55 11.4548 8 11.0017 8 10.4479V5.41366C8 4.85989 7.55 4.40681 7 4.40681ZM4 8.93766C3.45 8.93766 3 8.48457 3 7.9308C3 7.37703 3.45 6.92395 4 6.92395C4.55 6.92395 5 7.37703 5 7.9308C5 8.48457 4.55 8.93766 4 8.93766ZM5.55 4.40681H2.45V3.39995C2.45 2.53909 3.145 1.83933 4 1.83933C4.855 1.83933 5.55 2.53909 5.55 3.39995V4.40681Z" />
  </SvgWrapper>
);

export default LockIcon;
