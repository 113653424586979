import { fetchPageMetrics } from '@/apis/semji/api';

// this object is storing the metrics by page, range and periodicity
// this will cache the response for the report page that calls too many times this endpoint to retrieve the trends for each page
// cache must be cleared with clearLocalCache when we don't need data anymore
let pageMetricsCache = {};

export default class PageMetrics {
  constructor(pageId, range, periodicity) {
    this._pageId = pageId;
    this._range = range;
    this._periodicity = periodicity;
  }

  get pageMetrics() {
    return this._fetchMetrics();
  }

  async _fetchMetrics() {
    const cacheKey = `${this._pageId}_${this._periodicity}_${this._range.from}_${this._range.to}`;

    if (undefined === pageMetricsCache[cacheKey]) {
      try {
        pageMetricsCache[cacheKey] = await fetchPageMetrics(
          this._pageId,
          this._range,
          this._periodicity
        );
      } catch (e) {
        throw e;
      }
    }

    return pageMetricsCache[cacheKey]['hydra:member'];
  }

  clearLocalCache() {
    pageMetricsCache = {};
  }
}
