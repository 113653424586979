import React from 'react';
import styled from 'styled-components/macro';

const CreateDraftWrapper = styled.div`
  width: 200px;
  margin-bottom: 1em;
`;

const CreateDraft = () => (
  <CreateDraftWrapper
    dangerouslySetInnerHTML={{
      __html: `
      <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="svg2"
      width="100%"
      viewBox="0 0 299.99933 166.55519"
    >
      <defs id="defs6">
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath20">
          <path d="m 1716.874,207.973 h 203.125 V 0 h -203.125 z" id="path18" />
        </clipPath>
        <mask
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="1"
          height="1"
          id="mask24"
        ></mask>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath36">
          <path d="M 0,1149.396 H 1920 V 0 H 0 Z" id="path34" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath44">
          <path d="M 1716.87,207.973 H 2929.8 V -16.1344 H 1716.87 Z" id="path42" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath58">
          <path d="m 1812.04,147.898 h 977 V 30.8979 h -977 z" id="path56" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath62">
          <path d="m 1812.04,147.898 h 977 V 30.8979 h -977 z" id="path60" />
        </clipPath>
        <mask
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="1"
          height="1"
          id="mask64"
        >
          <g id="g74">
            <g clip-path="url(#clipPath62)" id="g72">
              <g id="g70">
                <g transform="matrix(977,0,0,117,1812.0435,30.897949)" id="g68"></g>
              </g>
            </g>
          </g>
        </mask>
        <mask
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="1"
          height="1"
          id="mask76"
        ></mask>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath108">
          <path d="m 1875.36,142.324 h 101.61 v -15.848 h -101.61 z" id="path106" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath126">
          <path d="m 1716.874,207.973 h 203.125 V 0 h -203.125 z" id="path124" />
        </clipPath>
        <mask
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="1"
          height="1"
          id="mask130"
        ></mask>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath142">
          <path d="M 0,1149.396 H 1920 V 0 H 0 Z" id="path140" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath150">
          <path
            d="M 1716.87,207.973 H 2929.8 V -16.1344 H 1716.87 Z"
            id="path148"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath164">
          <path d="m 1812.04,147.898 h 977 V 30.8979 h -977 z" id="path162" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath168">
          <path d="m 1812.04,147.898 h 977 V 30.8979 h -977 z" id="path166" />
        </clipPath>
        <mask
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="1"
          height="1"
          id="mask170"
        >
          <g id="g180">
            <g clip-path="url(#clipPath168)" id="g178">
              <g id="g176">
                <g
                  transform="matrix(977,0,0,117,1812.0435,30.897949)"
                  id="g174"
                ></g>
              </g>
            </g>
          </g>
        </mask>
        <mask
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="1"
          height="1"
          id="mask182"
        ></mask>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath214">
          <path d="m 1875.36,142.324 h 101.61 v -15.848 h -101.61 z" id="path212" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath230">
          <path d="M 0,1149.396 H 1920 V 0 H 0 Z" id="path228" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath238">
          <path d="m 1812.04,77.898 h 909 v -260 h -909 z" id="path236" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath242">
          <path d="m 1812.04,77.898 h 909 v -260 h -909 z" id="path240" />
        </clipPath>
        <mask
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="1"
          height="1"
          id="mask244"
        >
          <g id="g254">
            <g clip-path="url(#clipPath242)" id="g252">
              <g id="g250">
                <g
                  transform="matrix(909,0,0,260,1812.0435,-182.10204)"
                  id="g248"
                ></g>
              </g>
            </g>
          </g>
        </mask>
        <mask
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="1"
          height="1"
          id="mask256"
        ></mask>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath288">
          <path d="M 0,1149.396 H 1920 V 0 H 0 Z" id="path286" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath296">
          <path
            d="m 1924.092,-368.99 h -712.729 v 227.587 h 712.729 z"
            id="path294"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath304">
          <path
            d="m 1206.102,-587.217 h 753.283 V 80.598 h -753.283 z"
            id="path302"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath316">
          <path d="M 0,1149.396 H 1920 V 0 H 0 Z" id="path314" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath324">
          <path d="M -0.666672,1149.17 H 1214.66 V 0 H -0.666672 Z" id="path322" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath338">
          <path d="m 1181.88,1119.06 h 768 V -29.9371 h -768 z" id="path336" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath342">
          <path d="m 1181.88,1119.06 h 768 V -29.9371 h -768 z" id="path340" />
        </clipPath>
        <mask
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="1"
          height="1"
          id="mask344"
        >
          <g id="g354">
            <g clip-path="url(#clipPath342)" id="g352">
              <g id="g350">
                <g
                  transform="matrix(768,0,0,1149,1181.8785,-29.937134)"
                  id="g348"
                ></g>
              </g>
            </g>
          </g>
        </mask>
        <mask
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="1"
          height="1"
          id="mask356"
        ></mask>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath382">
          <path
            d="M 1211.364,1088.888 H 1920 V 861.301 h -708.636 z"
            id="path380"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath394">
          <path d="M 0,1149.396 H 1920 V 0 H 0 Z" id="path392" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath402">
          <path d="M 1211.36,1088.89 H 1924.7 V 861.299 h -713.34 z" id="path400" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath416">
          <path d="m 1222.04,1033.9 h 645 V 916.898 h -645 z" id="path414" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath420">
          <path d="m 1222.04,1033.9 h 645 V 916.898 h -645 z" id="path418" />
        </clipPath>
        <mask
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="1"
          height="1"
          id="mask422"
        >
          <g id="g432">
            <g clip-path="url(#clipPath420)" id="g430">
              <g id="g428">
                <g
                  transform="matrix(645,0,0,117,1222.0435,916.89795)"
                  id="g426"
                ></g>
              </g>
            </g>
          </g>
        </mask>
        <mask
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="1"
          height="1"
          id="mask434"
        ></mask>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath466">
          <path d="M 0,1149.396 H 1920 V 0 H 0 Z" id="path464" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath474">
          <path d="m 1271.28,1027.52 h 102.2 v -15.85 h -102.2 z" id="path472" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath494">
          <path d="M 0,1149.396 H 1920 V 0 H 0 Z" id="path492" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath502">
          <path d="m 1585.38,1027.52 h 55.41 v -15.85 h -55.41 z" id="path500" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath522">
          <path d="M 0,1149.396 H 1920 V 0 H 0 Z" id="path520" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath530">
          <path d="m 1513.49,1027.52 h 42.59 v -15.85 h -42.59 z" id="path528" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath550">
          <path d="M 0,1149.396 H 1920 V 0 H 0 Z" id="path548" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath558">
          <path d="m 1670.07,1027.52 h 50.17 v -15.85 h -50.17 z" id="path556" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath578">
          <path d="m 1748.21,1039.68 h 123 V 915.978 h -123 z" id="path576" />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(123,0,0,123,1748.21,171.572)"
          spreadMethod="pad"
          id="linearGradient596"
        >
          <stop style="stop-opacity:1;stop-color:#54ca95" offset="0" id="stop590" />
          <stop
            style="stop-opacity:1;stop-color:#63d6ce"
            offset="0.58139535"
            id="stop592"
          />
          <stop style="stop-opacity:1;stop-color:#63d6ce" offset="1" id="stop594" />
        </linearGradient>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(26.0497,0,0,26.0497,1830.14,219.643)"
          spreadMethod="pad"
          id="linearGradient608"
        >
          <stop style="stop-opacity:1;stop-color:#54ca95" offset="0" id="stop602" />
          <stop
            style="stop-opacity:1;stop-color:#63d6ce"
            offset="0.58139535"
            id="stop604"
          />
          <stop style="stop-opacity:1;stop-color:#63d6ce" offset="1" id="stop606" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath622">
          <path d="M 0,1149.396 H 1920 V 0 H 0 Z" id="path620" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath630">
          <path d="m 1776.08,971.651 h 56.62 v -28.089 h -56.62 z" id="path628" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath650">
          <path d="M 0,1149.396 H 1920 V 0 H 0 Z" id="path648" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath666">
          <path d="m 1214.96,780.613 h 331.32 v -1.5 h -331.32 z" id="path664" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath686">
          <path d="M 0,1149.396 H 1920 V 0 H 0 Z" id="path684" />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(280.154,0,0,280.154,-71.9038,1149.3995)"
          spreadMethod="pad"
          id="linearGradient700"
        >
          <stop style="stop-opacity:1;stop-color:#fa6a62" offset="0" id="stop694" />
          <stop
            style="stop-opacity:1;stop-color:#f39b6e"
            offset="0.58139535"
            id="stop696"
          />
          <stop style="stop-opacity:1;stop-color:#f39b6e" offset="1" id="stop698" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath714">
          <path d="M 0,1149.396 H 1920 V 0 H 0 Z" id="path712" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath734">
          <path d="M 0,1149.396 H 1920 V 0 H 0 Z" id="path732" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath754">
          <path d="M 0,1149.396 H 1920 V 0 H 0 Z" id="path752" />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(314.383,0,0,314.383,-71.9038,1149.3999)"
          spreadMethod="pad"
          id="linearGradient768"
        >
          <stop style="stop-opacity:1;stop-color:#fa6a62" offset="0" id="stop762" />
          <stop
            style="stop-opacity:1;stop-color:#f39b6e"
            offset="0.58139535"
            id="stop764"
          />
          <stop style="stop-opacity:1;stop-color:#f39b6e" offset="1" id="stop766" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath782">
          <path d="M 0,1149.396 H 1920 V 0 H 0 Z" id="path780" />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(541.667,0,0,541.667,-2.0003,1149.3999)"
          spreadMethod="pad"
          id="linearGradient792"
        >
          <stop style="stop-opacity:1;stop-color:#54ca95" offset="0" id="stop786" />
          <stop
            style="stop-opacity:1;stop-color:#63d6ce"
            offset="0.58139535"
            id="stop788"
          />
          <stop style="stop-opacity:1;stop-color:#63d6ce" offset="1" id="stop790" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath806">
          <path d="M 0,1149.396 H 1920 V 0 H 0 Z" id="path804" />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(542.717,0,0,542.717,-1.9995,1149.3999)"
          spreadMethod="pad"
          id="linearGradient816"
        >
          <stop style="stop-opacity:1;stop-color:#54c994" offset="0" id="stop810" />
          <stop
            style="stop-opacity:1;stop-color:#63d5cd"
            offset="0.58139535"
            id="stop812"
          />
          <stop style="stop-opacity:1;stop-color:#63d5cd" offset="1" id="stop814" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath830">
          <path d="M 0,1149.396 H 1920 V 0 H 0 Z" id="path828" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath842">
          <path d="M 133.043,1045.9 H 1109.04 V 92.898 H 133.043 Z" id="path840" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath846">
          <path d="M 133.043,1045.9 H 1109.04 V 92.8979 H 133.043 Z" id="path844" />
        </clipPath>
        <mask
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="1"
          height="1"
          id="mask848"
        >
          <g id="g858">
            <g clip-path="url(#clipPath846)" id="g856">
              <g id="g854">
                <g
                  transform="matrix(976,0,0,953,133.04347,92.897949)"
                  id="g852"
                ></g>
              </g>
            </g>
          </g>
        </mask>
        <mask
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="1"
          height="1"
          id="mask860"
        ></mask>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath896">
          <path d="M 0,1149.396 H 1920 V 0 H 0 Z" id="path894" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath908">
          <path
            d="M 0.0236053,1149.3 H 1918.99 v -61.61 H 0.0236053 Z"
            id="path906"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath912">
          <path
            d="M 1918.986,1087.688 H 0.024 v 61.613 h 1918.962 z"
            id="path910"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(1882.4331,0,0,-1882.4331,13.654001,1118.4945)"
          spreadMethod="pad"
          id="linearGradient920"
        >
          <stop style="stop-opacity:1;stop-color:#00bef1" offset="0" id="stop914" />
          <stop
            style="stop-opacity:1;stop-color:#5f5bff"
            offset="0.58139535"
            id="stop916"
          />
          <stop style="stop-opacity:1;stop-color:#5f5bff" offset="1" id="stop918" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath946">
          <path d="M 0,1149.396 H 1920 V 0 H 0 Z" id="path944" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath954">
          <path d="M 85.6089,1116.4 H 258.259 v -14.57 H 85.6089 Z" id="path952" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath970">
          <path d="m 50.0525,1130.73 h 13.3421 v -24.47 H 50.0525 Z" id="path968" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath986">
          <path d="M 1024.01,1135.74 H 1115 v -34.5 h -90.99 z" id="path984" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1002">
          <path
            d="m 844.432,1125.23 h 122.162 v -15.84 H 844.432 Z"
            id="path1000"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1022">
          <path d="M 0,1149.396 H 1920 V 0 H 0 Z" id="path1020" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1030">
          <path d="m 532.433,486.509 h 180 v -53 h -180 z" id="path1028" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1034">
          <path d="m 532.433,486.509 h 180 v -53 h -180 z" id="path1032" />
        </clipPath>
        <mask
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="1"
          height="1"
          id="mask1036"
        >
          <g id="g1046">
            <g clip-path="url(#clipPath1034)" id="g1044">
              <g id="g1042">
                <g
                  transform="matrix(180,0,0,53,532.43262,433.50882)"
                  id="g1040"
                ></g>
              </g>
            </g>
          </g>
        </mask>
        <mask
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="1"
          height="1"
          id="mask1048"
        ></mask>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1072">
          <path
            d="m 541.037,481.504 c -1.658,0 -3.001,-1.342 -3.001,-2.999 v 0 -36 c 0,-1.657 1.343,-3 3.001,-3 v 0 h 162.908 c 1.656,0 3,1.343 3,3 v 0 36 c 0,1.657 -1.344,2.999 -3,2.999 v 0 z"
            id="path1070"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(168.90764,0,0,-168.90764,538.03674,460.50467)"
          spreadMethod="pad"
          id="linearGradient1082"
        >
          <stop
            style="stop-opacity:1;stop-color:#ff4580"
            offset="0"
            id="stop1078"
          />
          <stop
            style="stop-opacity:1;stop-color:#ff4d64"
            offset="1"
            id="stop1080"
          />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1116">
          <path d="M 0,1149.396 H 1920 V 0 H 0 Z" id="path1114" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1128">
          <path d="m 1222.04,957.898 h 550 v -304 h -550 z" id="path1126" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1132">
          <path d="m 1222.04,957.898 h 550 v -304 h -550 z" id="path1130" />
        </clipPath>
        <mask
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="1"
          height="1"
          id="mask1134"
        >
          <g id="g1144">
            <g clip-path="url(#clipPath1132)" id="g1142">
              <g id="g1140">
                <g
                  transform="matrix(550,0,0,304,1222.0435,653.89795)"
                  id="g1138"
                ></g>
              </g>
            </g>
          </g>
        </mask>
        <mask
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="1"
          height="1"
          id="mask1146"
        ></mask>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1212">
          <path d="M 0,1149.396 H 1920 V 0 H 0 Z" id="path1210" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1220">
          <path d="m 1252.72,734.92 h 489.3 v -0.54 h -489.3 z" id="path1218" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1240">
          <path
            d="m 1283.571,718.764 v -6.373 h -6.337 v -2.179 h 6.337 v -6.34 h 2.167 v 6.34 h 6.304 v 2.179 h -6.304 v 6.373 z"
            id="path1238"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(14.807538,0,0,-14.807538,1277.2341,711.31799)"
          spreadMethod="pad"
          id="linearGradient1250"
        >
          <stop
            style="stop-opacity:1;stop-color:#ff4580"
            offset="0"
            id="stop1246"
          />
          <stop
            style="stop-opacity:1;stop-color:#ff4d64"
            offset="1"
            id="stop1248"
          />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1260">
          <path d="M 0,1149.396 H 1920 V 0 H 0 Z" id="path1258" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1300">
          <path
            d="m 542.794,761.647 -30.751,-115.999 10.25,-34.25 116.5,-5.001 h 111.5 l 7.501,10.251 2.25,17 -26.834,16 -31.333,35.084 -7.005,-6.986 -17.829,-6.431 4.406,23.77 20.344,20.313 13.5,23 -10.499,23 -120.751,2.75 z"
            id="path1298"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1324">
          <path d="M 0,1149.396 H 1920 V 0 H 0 Z" id="path1322" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1384">
          <path d="m 514.877,631.743 h 225 v -7.762 h -225 z" id="path1382" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1428">
          <path d="m 1886.98,1066.87 h 18.28 v -18.28 h -18.28 z" id="path1426" />
        </clipPath>
      </defs>
      <g id="g10" transform="matrix(1.3333333,0,0,-1.3333333,-686.50265,998.53051)">
        <g id="g1254">
          <g id="g1256" clip-path="url(#clipPath1260)">
            <g id="g1262" transform="translate(550.4302,686.1602)">
              <path
                d="m 0,0 h -8.023 c -1.232,0 -2.239,1.007 -2.239,2.238 0,1.231 1.007,2.238 2.239,2.238 H 0 C 1.231,4.476 2.238,3.469 2.238,2.238 2.238,1.007 1.231,0 0,0"
                style="fill:#e1e4e7;fill-opacity:1;fill-rule:nonzero;stroke:none"
                id="path1264"
              />
            </g>
            <g id="g1266" transform="translate(557.5679,711.9224)">
              <path
                d="m 0,0 h -8.024 c -1.231,0 -2.239,1.007 -2.239,2.238 0,1.231 1.008,2.238 2.239,2.238 H 0 C 1.23,4.476 2.237,3.469 2.237,2.238 2.237,1.007 1.23,0 0,0"
                style="fill:#e1e4e7;fill-opacity:1;fill-rule:nonzero;stroke:none"
                id="path1268"
              />
            </g>
            <g id="g1270" transform="translate(556.1392,700.3267)">
              <path
                d="m 0,0 h -11.857 c -1.232,0 -2.239,1.007 -2.239,2.238 0,1.231 1.007,2.238 2.239,2.238 H 0 C 1.23,4.476 2.237,3.469 2.237,2.238 2.237,1.007 1.23,0 0,0"
                style="fill:#e1e4e7;fill-opacity:1;fill-rule:nonzero;stroke:none"
                id="path1272"
              />
            </g>
            <g id="g1274" transform="translate(534.8892,700.4102)">
              <path
                d="m 0,0 h -2.107 c -1.232,0 -2.239,1.007 -2.239,2.238 0,1.231 1.007,2.238 2.239,2.238 H 0 C 1.23,4.476 2.237,3.469 2.237,2.238 2.237,1.007 1.23,0 0,0"
                style="fill:#e1e4e7;fill-opacity:1;fill-rule:nonzero;stroke:none"
                id="path1276"
              />
            </g>
            <g id="g1278" transform="translate(717.6177,661.4111)">
              <path
                d="m 0,0 h -11.648 c -1.232,0 -2.239,1.007 -2.239,2.238 0,1.231 1.007,2.238 2.239,2.238 H 0 C 1.231,4.476 2.238,3.469 2.238,2.238 2.238,1.007 1.231,0 0,0"
                style="fill:#e1e4e7;fill-opacity:1;fill-rule:nonzero;stroke:none"
                id="path1280"
              />
            </g>
            <g id="g1282" transform="translate(697.8052,661.3257)">
              <path
                d="m 0,0 h -3.44 c -1.231,0 -2.239,1.007 -2.239,2.238 0,1.231 1.008,2.238 2.239,2.238 H 0 C 1.231,4.476 2.238,3.469 2.238,2.238 2.238,1.007 1.231,0 0,0"
                style="fill:#e1e4e7;fill-opacity:1;fill-rule:nonzero;stroke:none"
                id="path1284"
              />
            </g>
            <g id="g1286" transform="translate(699.6802,713.0054)">
              <path
                d="m 0,0 h -6.315 c -1.231,0 -2.239,1.007 -2.239,2.238 0,1.231 1.008,2.238 2.239,2.238 H 0 C 1.231,4.476 2.238,3.469 2.238,2.238 2.238,1.007 1.231,0 0,0"
                style="fill:#e1e4e7;fill-opacity:1;fill-rule:nonzero;stroke:none"
                id="path1288"
              />
            </g>
            <g id="g1290" transform="translate(706.3892,673.8394)">
              <path
                d="m 0,0 h -2.316 c -1.231,0 -2.238,1.007 -2.238,2.238 0,1.231 1.007,2.238 2.238,2.238 H 0 C 1.23,4.476 2.237,3.469 2.237,2.238 2.237,1.007 1.23,0 0,0"
                style="fill:#e1e4e7;fill-opacity:1;fill-rule:nonzero;stroke:none"
                id="path1292"
              />
            </g>
          </g>
        </g>
        <g id="g1294">
          <g id="g1296" clip-path="url(#clipPath1300)">
            <g id="g1302" transform="translate(691.5435,746.7314)">
              <path
                d="m 0,0 h -132 c -2.209,0 -4,-1.791 -4,-4 V -105.75 H 4 V -4 C 4,-1.791 2.209,0 0,0 M -1,-11.167 V -100 c 0,-1.657 -1.343,-3 -3,-3 h -124 c -1.657,0 -3,1.343 -3,3 v 92 c 0,1.657 1.343,3 3,3 H -4 c 1.657,0 3,-1.343 3,-3 z"
                style="fill:#d9dcde;fill-opacity:1;fill-rule:nonzero;stroke:none"
                id="path1304"
              />
            </g>
            <g id="g1306" transform="translate(687.0435,742.1479)">
              <path
                d="m 0,0 h -123 c -1.104,0 -2,-0.896 -2,-2 v -91.736 c 0,-1.104 0.896,-2 2,-2 H 0 c 1.104,0 2,0.896 2,2 V -2 C 2,-0.896 1.104,0 0,0"
                style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
                id="path1308"
              />
            </g>
            <g id="g1310" transform="translate(687.0435,742.1479)">
              <path
                d="m 0,0 h -123 c -1.104,0 -2,-0.896 -2,-2 v -91.736 c 0,-1.104 0.896,-2 2,-2 H 0 c 1.104,0 2,0.896 2,2 V -2 C 2,-0.896 1.104,0 0,0 Z"
                style="fill:none;stroke:#7b838a;stroke-width:1;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
                id="path1312"
              />
            </g>
            <g id="g1314" transform="translate(691.5435,748.3979)">
              <path
                d="m 0,0 h -132 c -2.209,0 -4,-1.791 -4,-4 V -108 H 4 V -4 C 4,-1.791 2.209,0 0,0 Z"
                style="fill:none;stroke:#7b838a;stroke-width:1;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
                id="path1316"
              />
            </g>
          </g>
        </g>
        <g id="g1318">
          <g id="g1320" clip-path="url(#clipPath1324)">
            <g id="g1326" transform="translate(584.5015,696.8145)">
              <path
                d="M 0,0 H 82.625"
                style="fill:none;stroke:#7b838a;stroke-width:1.5;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
                id="path1328"
              />
            </g>
            <g id="g1330" transform="translate(584.5015,691.5767)">
              <path
                d="M 0,0 H 82.625"
                style="fill:none;stroke:#7b838a;stroke-width:1.5;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
                id="path1332"
              />
            </g>
            <g id="g1334" transform="translate(584.5015,686.3384)">
              <path
                d="M 0,0 H 82.625"
                style="fill:none;stroke:#7b838a;stroke-width:1.5;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
                id="path1336"
              />
            </g>
            <g id="g1338" transform="translate(584.5015,681.1001)">
              <path
                d="M 0,0 H 82.625"
                style="fill:none;stroke:#7b838a;stroke-width:1.5;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
                id="path1340"
              />
            </g>
            <g id="g1342" transform="translate(584.5015,675.8623)">
              <path
                d="M 0,0 H 82.625"
                style="fill:none;stroke:#7b838a;stroke-width:1.5;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
                id="path1344"
              />
            </g>
            <g id="g1346" transform="translate(584.5015,670.624)">
              <path
                d="M 0,0 H 82.625"
                style="fill:none;stroke:#7b838a;stroke-width:1.5;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
                id="path1348"
              />
            </g>
            <g id="g1350" transform="translate(584.5015,665.3862)">
              <path
                d="M 0,0 H 82.625"
                style="fill:none;stroke:#7b838a;stroke-width:1.5;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
                id="path1352"
              />
            </g>
            <g id="g1354" transform="translate(584.5015,660.1479)">
              <path
                d="M 0,0 H 82.625"
                style="fill:none;stroke:#7b838a;stroke-width:1.5;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
                id="path1356"
              />
            </g>
            <g id="g1358" transform="translate(666.1265,705.5645)">
              <path
                d="m 0,0 h -80.625 c -0.552,0 -1,0.448 -1,1 v 21 c 0,0.552 0.448,1 1,1 H 0 c 0.553,0 1,-0.448 1,-1 V 1 C 1,0.448 0.553,0 0,0"
                style="fill:#c5cacc;fill-opacity:1;fill-rule:nonzero;stroke:none"
                id="path1360"
              />
            </g>
            <g id="g1362" transform="translate(693.7808,681.6177)">
              <path
                d="M 0,0 -9.092,9.343 7.13,25.499 16.346,16.364 Z"
                style="fill:#d9dcde;fill-opacity:1;fill-rule:nonzero;stroke:none"
                id="path1364"
              />
            </g>
            <g id="g1366" transform="translate(711.7759,699.9824)">
              <path
                d="M 0,0 -9.063,9.032 -5.17,12.988 3.893,3.957 Z"
                style="fill:#d9dcde;fill-opacity:1;fill-rule:nonzero;stroke:none"
                id="path1368"
              />
            </g>
            <g id="g1370" transform="translate(720.4575,707.9658)">
              <path
                d="m 0,0 -0.002,-0.003 -3.36,-3.459 -9.092,9.344 3.359,3.457 c 1.074,1.099 3.07,1.095 4.131,-0.002 L -0.003,4.238 C 0.548,3.666 0.85,2.914 0.85,2.116 0.85,1.319 0.548,0.568 0,0"
                style="fill:#d9dcde;fill-opacity:1;fill-rule:nonzero;stroke:none"
                id="path1372"
              />
            </g>
            <g id="g1374" transform="translate(720.8628,709.2305)">
              <path
                d="m 0,0 -0.003,-0.003 -3.653,-3.66 -9.887,9.886 3.653,3.658 c 1.168,1.162 3.337,1.159 4.492,-0.002 L -0.004,4.484 C 0.596,3.879 0.924,3.083 0.924,2.238 0.924,1.396 0.596,0.601 0,0 m -9.187,-9.183 -9.884,9.886 4.572,4.572 9.885,-9.885 z m -18.054,-18.055 -9.886,9.885 17.103,17.103 9.885,-9.885 z m -10.885,-4.538 -3.462,3.455 3.325,10.195 10.254,-10.249 z M 2.271,2.242 c 0,1.207 -0.469,2.341 -1.322,3.194 v 0 l -5.398,5.398 c -1.702,1.702 -4.692,1.701 -6.398,-10e-4 l -28.78,-28.78 -5.762,-17.692 17.559,5.905 28.779,28.777 c 0.853,0.853 1.322,1.989 1.322,3.199"
                style="fill:#7b838a;fill-opacity:1;fill-rule:nonzero;stroke:none"
                id="path1376"
              />
            </g>
            <g id="g1378">
              <g id="g1380" />
              <g id="g1392">
                <g clip-path="url(#clipPath1384)" id="g1390" style="opacity:0.5">
                  <g transform="translate(739.8765,629.1895)" id="g1388">
                    <path
                      d="m 0,0 c 0,-2.876 -50.367,-5.208 -112.5,-5.208 -62.132,0 -112.5,2.332 -112.5,5.208 0,2.876 50.368,2.542 112.5,2.542 C -50.367,2.542 0,2.876 0,0"
                      style="fill:#b3b9bc;fill-opacity:1;fill-rule:nonzero;stroke:none"
                      id="path1386"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="g1394" transform="translate(529.377,636.5645)">
              <path
                d="m 0,0 h 194.5 c 1.657,0 3,-0.766 3,-1.711 v -1.437 c 0,-0.945 -1.343,-1.711 -3,-1.711 H 0 c -1.657,0 -3,0.766 -3,1.711 v 1.437 C -3,-0.766 -1.657,0 0,0"
                style="fill:#d9dcde;fill-opacity:1;fill-rule:nonzero;stroke:none"
                id="path1396"
              />
            </g>
            <g id="g1398" transform="translate(529.377,640.2251)">
              <path
                d="m 0,0 h 194.5 c 1.657,0 3,-1.343 3,-3 v -2.52 c 0,-1.657 -1.343,-3 -3,-3 H 0 c -1.657,0 -3,1.343 -3,3 V -3 c 0,1.657 1.343,3 3,3 z"
                style="fill:none;stroke:#7b838a;stroke-width:1;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
                id="path1400"
              />
            </g>
            <g id="g1402" transform="translate(666.1265,705.5645)">
              <path
                d="m 0,0 h -80.625 c -0.552,0 -1,0.448 -1,1 v 21 c 0,0.552 0.448,1 1,1 H 0 c 0.553,0 1,-0.448 1,-1 V 1 C 1,0.448 0.553,0 0,0"
                style="fill:#c5cacc;fill-opacity:1;fill-rule:nonzero;stroke:none"
                id="path1404"
              />
            </g>
            <g id="g1406" transform="translate(551.5854,679.3271)">
              <path
                d="m 0,0 c -5.2,0 -10.146,-1.985 -13.928,-5.589 -3.919,-3.744 -6.152,-8.794 -6.29,-14.22 -0.141,-5.418 1.829,-10.574 5.55,-14.516 3.724,-3.947 8.747,-6.198 14.145,-6.339 0.175,-0.004 0.352,-0.006 0.528,-0.006 4.616,0 9.144,1.624 12.751,4.574 l 0.075,0.061 0.069,0.067 1.979,1.892 0.068,0.065 0.064,0.071 c 3.24,3.606 5.094,8.25 5.22,13.077 0.142,5.42 -1.83,10.575 -5.549,14.518 C 10.957,-2.399 5.934,-0.148 0.537,-0.007 0.358,-0.002 0.179,0 0,0 m 0,-2.5 c 0.157,0 0.314,-0.002 0.472,-0.006 9.773,-0.255 17.516,-8.461 17.26,-18.291 -0.115,-4.422 -1.84,-8.423 -4.581,-11.473 l -1.978,-1.891 c -3.059,-2.503 -6.95,-4.009 -11.168,-4.009 -0.154,0 -0.309,0.002 -0.463,0.005 -9.773,0.255 -17.518,8.461 -17.261,18.292 0.121,4.762 2.081,9.193 5.516,12.474 C -8.878,-4.23 -4.561,-2.5 0,-2.5"
                style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
                id="path1408"
              />
            </g>
            <g id="g1410" transform="translate(551.1821,643.876)">
              <path
                d="m 0,0 c -8.291,0.216 -14.863,7.178 -14.646,15.52 0.103,4.043 1.766,7.801 4.682,10.582 2.913,2.783 6.734,4.258 10.753,4.153 C 9.08,30.039 15.651,23.078 15.434,14.735 15.217,6.396 8.291,-0.216 0,0 m 23.059,-6.979 -9.484,10.14 c 2.748,3.058 4.478,7.069 4.594,11.503 C 18.426,24.521 10.661,32.75 0.86,33.005 -3.887,33.129 -8.4,31.387 -11.85,28.1 -15.293,24.81 -17.258,20.366 -17.38,15.591 -17.637,5.733 -9.872,-2.495 -0.072,-2.75 4.336,-2.865 8.412,-1.337 11.591,1.264 l 9.471,-10.126 c 0.523,-0.554 1.397,-0.576 1.947,-0.051 0,0 1.047,0.847 0.05,1.934"
                style="fill:#8b9298;fill-opacity:1;fill-rule:nonzero;stroke:none"
                id="path1412"
              />
            </g>
            <g id="g1414" transform="translate(566.897,658.6709)">
              <path
                d="m 0,0 c -0.221,-8.452 -7.251,-15.125 -15.703,-14.905 -8.451,0.221 -15.125,7.251 -14.904,15.703 0.22,8.452 7.251,15.125 15.703,14.905 C -6.452,15.482 0.221,8.452 0,0"
                style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
                id="path1416"
              />
            </g>
            <g id="g1418" transform="translate(564.1841,658.7417)">
              <path
                d="m 0,0 c -0.181,-6.954 -5.965,-12.444 -12.919,-12.263 -6.954,0.181 -12.444,5.966 -12.263,12.92 C -25,7.61 -19.216,13.101 -12.263,12.919 -5.309,12.738 0.182,6.954 0,0"
                style="fill:#d9dcde;fill-opacity:1;fill-rule:nonzero;stroke:none"
                id="path1420"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>`,
    }}
  />
);

export default CreateDraft;
