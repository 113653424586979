import './ReportTabs.scss';

import React, { useEffect, useId, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ENUM_TABS_VARIANTS, Tabs } from 'semji-core/components/Tabs';

import CaretIcon from '@/components/icons/CaretIcon';
import { buildMetricFeatureSetFlag } from '@/components/Metrics/helpers';
import MetricsSelectorBox from '@/components/Metrics/MetricsSelectorBox';
import Selector from '@/components/Selector';
import { getMetricsFromCategory } from '@/containers/Report/utils/helpers';
import {
  ReportMetric,
  ReportMetricCategory,
  ReportMetricKey,
} from '@/containers/Report/utils/types';
import { getNumberOfFiltersDisplayable } from '@/utils/filter/getNumberOfFiltersDisplayable';

import ReportTab from './ReportTab';
import { ENUM_REPORT_TAB_VARIANT, ReportTabsProps } from './ReportTabs.types';

export default function ReportTabs({
  currentMetricKey,
  metricsList = [],
  maxInlineMetrics = 7,
  selectMetricClick,
  variant = ENUM_REPORT_TAB_VARIANT.Report,
  showMoreButton = true,
}: ReportTabsProps) {
  const { t } = useTranslation();
  const id = useId();
  const MORE_BUTTON_ID = 'report-tab_more_metric--more-button';
  const MORE_TAB_KEY = 'more';
  const REPORT_TAB_PADDING = 8;
  const REPORT_TAB_GAP = 8;
  const [displayedItemCount, setDisplayedItemCount] = useState(maxInlineMetrics);

  const productionMetrics = getMetricsFromCategory(
    metricsList,
    ReportMetricCategory.Production,
    displayedItemCount - 1
  );
  const acquisitionMetrics = getMetricsFromCategory(
    metricsList,
    ReportMetricCategory.Acquisition,
    displayedItemCount - 1
  );
  const conversionMetrics = getMetricsFromCategory(
    metricsList,
    ReportMetricCategory.Conversion,
    displayedItemCount - 1
  );
  const eCommerceMetrics = getMetricsFromCategory(
    metricsList,
    ReportMetricCategory.ECommerce,
    displayedItemCount - 1
  );
  const userBehaviorMetrics = getMetricsFromCategory(
    metricsList,
    ReportMetricCategory.UserBehavior,
    displayedItemCount - 1
  );
  const layoutMenuRef = useRef<HTMLDivElement>(null);
  const prefix = `report-tab_${id}_metric__`;

  const selectMetric = (metric: ReportMetric) => selectMetricClick(metric.key);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const { width } = entry.contentRect;

        const elements = document.querySelectorAll(`[id^="${prefix}"]`);
        const elementWidths = Array.from(elements).map(
          (element) => element.offsetWidth + REPORT_TAB_PADDING
        );
        const MoreButtonWidth = document.getElementById(MORE_BUTTON_ID)?.offsetWidth;

        setDisplayedItemCount(
          Math.min(
            getNumberOfFiltersDisplayable({
              elementWidths,
              options: {
                addMoreButtonWidth: 0,
                gap: REPORT_TAB_GAP,
                marginLeft: 0,
                marginRight: MoreButtonWidth
                  ? MoreButtonWidth + REPORT_TAB_PADDING + REPORT_TAB_GAP
                  : 65,
              },
              screenWidth: width,
            }),
            maxInlineMetrics
          )
        );
      }
    });

    if (layoutMenuRef.current) {
      observer.observe(layoutMenuRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  function MoreButton({ openMenu }: { openMenu: () => void }) {
    return (
      <div
        className="page-report-tabs__item"
        data-intercom-target="report_tab_drop_down_more_metrics"
        id="report-tab_more_metric--more-button"
        onClick={openMenu}
      >
        <span className="page-report-tabs__item__label">{t('report:table.more')}</span>
        <CaretIcon />
      </div>
    );
  }
  const inlineMetricsList = useMemo(
    () =>
      [
        ...metricsList.slice(0, displayedItemCount),
        ...(showMoreButton
          ? [
              {
                key: MORE_TAB_KEY,
                name: t('report:table.more'),
              },
            ]
          : []),
        ...metricsList.slice(displayedItemCount),
      ].slice(0, maxInlineMetrics + 1),
    [displayedItemCount]
  );

  function isSelectedMetric(metricKey: ReportMetricKey | typeof MORE_TAB_KEY) {
    const currentMetricIndex = metricsList.findIndex((metric) => metric.key === currentMetricKey);

    if (currentMetricIndex >= displayedItemCount && metricKey === MORE_TAB_KEY) {
      return true;
    }
    return metricKey === currentMetricKey;
  }

  function handleTabClick(metricKey: string) {
    if (metricKey === MORE_TAB_KEY) {
      return;
    }
    return () => selectMetricClick(metricKey);
  }

  return (
    <div className="page-report-tabs">
      <Tabs
        ref={layoutMenuRef}
        overrideClassName="page-report-tabs__container"
        tabs={inlineMetricsList.map((metricItem) => ({
          content:
            metricItem.key === MORE_TAB_KEY ? (
              <Selector button={MoreButton}>
                {(props: any) => (
                  <MetricsSelectorBox
                    activeItemKey={currentMetricKey}
                    anchorEl={props.anchorEl}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    direction="column"
                    elements={[
                      productionMetrics.length > 0 && {
                        metricsList: productionMetrics,
                        selectMetric,
                        title: t('report:metric-categories.production'),
                      },
                      acquisitionMetrics.length > 0 && {
                        metricsList: acquisitionMetrics,
                        selectMetric,
                        title: t('report:metric-categories.acquisition'),
                      },
                      userBehaviorMetrics.length > 0 && {
                        metricsList: userBehaviorMetrics,
                        selectMetric,
                        title: t('report:metric-categories.user-behaviour'),
                      },
                      conversionMetrics.length > 0 && {
                        metricsList: conversionMetrics,
                        selectMetric,
                        title: t('report:metric-categories.conversion'),
                      },
                      eCommerceMetrics.length > 0 && {
                        metricsList: eCommerceMetrics,
                        selectMetric,
                        title: t('report:metric-categories.e-commerce'),
                      },
                    ].filter(Boolean)}
                    open={Boolean(props.open)}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    onClose={props.onClose}
                  />
                )}
              </Selector>
            ) : (
              <ReportTab
                key={metricItem.key}
                className={`page-report-tabs__item page-report-tabs__item--${variant}`}
                data-intercom-target={`report_tab_${metricItem.key}_metric`}
                endAdornmentIcon={metricItem.badge}
                feature={buildMetricFeatureSetFlag({
                  category: metricItem.category,
                  metricKey: metricItem.key,
                })}
                id={`${prefix}${metricItem.key}`}
                label={t(`${metricItem.name}`)}
                startAdornmentIcon={metricItem.icon}
              />
            ),
          onClick: handleTabClick(metricItem.key),
          preventDefaultSelection: metricItem.key === MORE_TAB_KEY,
          selected: isSelectedMetric(metricItem.key),
        }))}
        variant={ENUM_TABS_VARIANTS.Huge}
      />
    </div>
  );
}
