import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getRoundedNumberWithSuffix } from 'semji-core/utils/numbers';
import styled from 'styled-components/macro';

import FavIcon from '@/components/FavIcon/FavIcon';
import OutlineCreditIcon from '@/components/icons/OutlineCreditIcon';
import SearchIcon from '@/components/icons/SearchIcon';
import { SEARCH_INTENT_REPORT_TYPE } from '@/containers/Content/BriefComponents/constants';
import CopyContentButton from '@/containers/Content/BriefComponents/CopyContentButton';
import FlagContentButton from '@/containers/Content/BriefComponents/FlagContentButton';
import GenerateButton from '@/containers/Content/BriefComponents/GenerateButton';
import RedInfoIcon from '@/containers/Content/BriefComponents/RedInfoIcon';
import RemoveContentButton from '@/containers/Content/BriefComponents/RemoveContentButton';
import ActionsColumn from '@/containers/Content/BriefComponents/Table/Columns/ActionsColumn';
import Column from '@/containers/Content/BriefComponents/Table/Columns/Column';
import MetadataColumn from '@/containers/Content/BriefComponents/Table/Columns/MetadataColumn';
import OneLineColumn from '@/containers/Content/BriefComponents/Table/Columns/OneLineColumn';
import PrimaryColumn from '@/containers/Content/BriefComponents/Table/Columns/PrimaryColumn';
import Header from '@/containers/Content/BriefComponents/Table/Rows/Header';
import LoadingRow from '@/containers/Content/BriefComponents/Table/Rows/LoadingRow';
import MultiLineRow from '@/containers/Content/BriefComponents/Table/Rows/MultiLineRow';
import Row from '@/containers/Content/BriefComponents/Table/Rows/Row';
import UnlockSearchIntentRow from '@/containers/Content/BriefComponents/Table/Rows/UnlockSearchIntentRow';
import WarningRow from '@/containers/Content/BriefComponents/Table/Rows/WarningRow';
import Table from '@/containers/Content/BriefComponents/Table/Table';
import WaitLabel from '@/containers/Content/BriefComponents/WaitLabel';
import useApiConfigurations from '@/hooks/useApiConfigurations';
import { AI_WRITING_CREDITS_BRIEF_TITLE } from '@/utils/configurations/constants';
import { getFragmentDirective } from '@/utils/url';

const TitleVolumeWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
`;
const SmallFavIcon = styled(FavIcon)`
  &&& {
    img {
      box-sizing: border-box;
      padding: 0;
    }
  }
`;

function SearchIntentSample({ samples = [] }) {
  const sample = samples.find((sample) => sample.text.length && sample.competitorUrl.length);

  if (sample) {
    return (
      <OneLineColumn color="light" gap="15px">
        <PrimaryColumn color="light">
          <div dangerouslySetInnerHTML={{ __html: sample.text }} title={sample.text} />
        </PrimaryColumn>
        <SmallFavIcon url={`${sample.competitorUrl}#${getFragmentDirective(sample.text)}`} />
      </OneLineColumn>
    );
  }
  return null;
}

function SearchIntents({
  areGeneratedSearchIntentsSafe,
  generatedItems,
  isLimited,
  isLoading,
  onDeleteContent,
  onFlagContent,
  onGenerate,
  searchIntents,
}) {
  const { t } = useTranslation();
  const userLanguageCode = useSelector((state) => state.user?.languageCode);

  const cantGenerateMessage =
    false === areGeneratedSearchIntentsSafe && searchIntents.length > 0
      ? t('content:brief.generated-removed-due-to-sensitive-content-warning')
      : false === areGeneratedSearchIntentsSafe
        ? t('content:brief.generated-unable-due-to-sensitive-content-warning')
        : null;

  const briefSearchIntentCredits = useApiConfigurations(AI_WRITING_CREDITS_BRIEF_TITLE);

  const renderSafeMessage = () =>
    null !== cantGenerateMessage && (
      <Table>
        <WarningRow key={`error`}>
          <PrimaryColumn>
            <RedInfoIcon bordered /> {cantGenerateMessage}
          </PrimaryColumn>
        </WarningRow>
      </Table>
    );

  if (isLoading) {
    return (
      <Table>
        <LoadingRow />
      </Table>
    );
  }

  if (searchIntents?.length > 0) {
    return (
      <>
        {renderSafeMessage()}

        {searchIntents.map(({ id: searchIntentId, label, searchVolume, term, samples }) => (
          <Table key={term}>
            <MultiLineRow key={label} gap="8px">
              <TitleVolumeWrapper>
                <span dangerouslySetInnerHTML={{ __html: label }} title={term} />
                <MetadataColumn>
                  <SearchIcon />
                  &nbsp;
                  {getRoundedNumberWithSuffix({
                    locale: userLanguageCode,
                    number: searchVolume,
                  }) ?? '-'}
                </MetadataColumn>
              </TitleVolumeWrapper>
              <SearchIntentSample samples={samples} />
            </MultiLineRow>
            <Header>
              <PrimaryColumn>
                {t('content:brief.section-article-table-headings-header')}
              </PrimaryColumn>
              <Column>
                {isLimited ? (
                  <WaitLabel />
                ) : (
                  <GenerateButton
                    creditAmount={briefSearchIntentCredits}
                    creditIcon={<OutlineCreditIcon />}
                    hasItems={
                      generatedItems.filter(
                        ({ searchIntentId: generatedItemSearchIntentId }) =>
                          generatedItemSearchIntentId === searchIntentId
                      ).length > 0
                    }
                    onClick={() => onGenerate?.(searchIntentId)}
                  />
                )}
              </Column>
            </Header>
            {generatedItems.map(
              ({ searchIntentId: generatedItemSearchIntentId, id: genId, term }) =>
                generatedItemSearchIntentId === searchIntentId && (
                  <Row key={genId}>
                    <PrimaryColumn>{term}</PrimaryColumn>
                    <ActionsColumn>
                      <RemoveContentButton onClick={() => onDeleteContent?.(genId)} />
                      <FlagContentButton
                        onClick={() =>
                          onFlagContent?.({
                            id: genId,
                            text: term,
                            type: SEARCH_INTENT_REPORT_TYPE,
                          })
                        }
                      />
                      <CopyContentButton contentToCopy={term} />
                    </ActionsColumn>
                  </Row>
                )
            )}
          </Table>
        ))}

        <Table>
          <UnlockSearchIntentRow searchIntentsCount={searchIntents.length} />
        </Table>
      </>
    );
  }

  return (
    <>
      {renderSafeMessage()}

      <Table>
        <UnlockSearchIntentRow searchIntentsCount={searchIntents.length} />
      </Table>
    </>
  );
}

export default SearchIntents;
