import styled from 'styled-components/macro';

import { TitleTwo } from '@/components/Text/Title';

import { Box, BoxHeader, Content } from './Box';

const MessageBoxWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: ${(props) => props.theme.text.colors.default};
`;

const MessageBox = (props) => (
  <MessageBoxWrapper {...props}>
    <Box>
      <BoxHeader>
        <TitleTwo weight="medium">{props.title}</TitleTwo>
      </BoxHeader>
      <Content>{props.message}</Content>
    </Box>
  </MessageBoxWrapper>
);

export default MessageBox;
