import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getRoundedNumberObject } from 'semji-core/utils/numbers';
import styled from 'styled-components/macro';

import { FlexJustifiedBetweenContainer } from '@/components/_common';
import { TertiaryTitle } from '@/components/Text/Title';
import TooltipComponent from '@/components/Tooltip/Tooltip';

const Wrapper = styled(FlexJustifiedBetweenContainer)`
  padding: 1em 0 0 0;
`;
const SourceWrapper = styled.div`
  display: ${(props) => (props.isPAA ? 'flex' : 'none')};
  width: 45px;
  height: 25px;
  color: #39aaf3;
  background-color: #cceafc;
  font-weight: 500;
  font-size: 0.7em;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
`;
const StyledTertiaryTitle = styled(TertiaryTitle)`
  display: flex;
  flex-grow: 1;
  flex: 1;
  align-items: center;
`;
const VolumeWrapper = styled(TertiaryTitle)`
  width: 125px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 0.8em;
`;

function QuestionDetails({ id }) {
  const userLanguageCode = useSelector((state) => state.user?.languageCode);

  const { t } = useTranslation();
  const i18nNameSpace = 'content:side-panel-components.optimization.details.question-details';
  const data = useSelector((state) => state.report.recommendations[id].data);
  const searchVolume = getRoundedNumberObject({
    locale: userLanguageCode,
    number: data.searchVolume,
  });
  const volume = searchVolume.value && searchVolume.value >= 10 ? searchVolume.value : '< 10';

  return (
    <Wrapper>
      <StyledTertiaryTitle size="small">{data.title}</StyledTertiaryTitle>
      <SourceWrapper isPAA={data.isPAA}>
        <TooltipComponent title={t(`${i18nNameSpace}.people-also-ask`)}>
          {t(`${i18nNameSpace}.paa`)}
        </TooltipComponent>
      </SourceWrapper>
      <VolumeWrapper size="small">{volume + searchVolume.suffix}</VolumeWrapper>
    </Wrapper>
  );
}

export default QuestionDetails;
