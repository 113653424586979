import { useEffect, useState } from 'react';

import CountriesService from '@/services/Countries';
import { Log } from '@/utils/log/Log';
import { getDefaultLocation } from '@/utils/onboarding/getDefaultLocation';

const DEFAULT_COUNTRY_CODE = 'fr';

export function useDefaultUserLocation() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [countryCode, setCountryCode] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const countriesService = new CountriesService();
        const countriesList = await countriesService.countries;
        const { countryCode = DEFAULT_COUNTRY_CODE } = getDefaultLocation(countriesList) || {};
        setCountryCode(countryCode.toLowerCase());
      } catch (e) {
        Log.error(e);
        setCountryCode(DEFAULT_COUNTRY_CODE);
      } finally {
        setIsLoaded(true);
      }
    })();
  }, []);

  return { countryCode, isLoaded };
}
