import React from 'react';

import SvgWrapper from './SvgWrapper';

const DiagramIcon = (props) => (
  <SvgWrapper viewBox="0 0 15 15" {...props}>
    <path d="M12.814 8.5101L13.714 8.61011C13.514 12.0101 10.614 14.7101 7.21399 14.7101C3.61399 14.7101 0.614014 11.8101 0.614014 8.11011C0.614014 4.71011 3.31399 1.81011 6.71399 1.61011L6.81396 2.5101C3.91396 2.7101 1.51392 5.21011 1.51392 8.21011C1.51392 11.3101 4.11399 13.9101 7.21399 13.9101C10.114 13.8101 12.614 11.5101 12.814 8.5101ZM14.614 7.21011V7.61011H14.214H7.71399V0.610107H8.11401C11.714 0.710107 14.614 3.61011 14.614 7.21011ZM13.714 6.81012C13.514 4.01012 11.3139 1.81011 8.51392 1.61011V6.81012H13.714Z" />
  </SvgWrapper>
);

export default DiagramIcon;
