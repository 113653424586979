import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { memo } from 'react';

const OPTIONS = {
  chart: {
    animation: false,
    backgroundColor: 'none',
    height: 60,
    skipClone: true,
    style: {
      overflow: 'visible',
    },
    width: 100,
  },
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    series: {
      animation: false,
    },
  },
  title: {
    text: '',
  },
  tooltip: {
    enabled: false,
  },
  xAxis: {
    visible: false,
  },
  yAxis: {
    visible: false,
  },
};

function HighChartsTrend({ series }) {
  function onClick(e) {
    e.stopPropagation();
  }

  return (
    <div aria-hidden="true" onClick={onClick}>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          ...OPTIONS,
          series: [
            {
              color: 'rgba(39, 88, 188, 0.7)',
              data: series,
              fillOpacity: 0.05,
              marker: { enabled: false },
              states: {
                hover: {
                  enabled: false,
                },
              },
              type: 'area',
            },
          ],
        }}
      />
    </div>
  );
}

export default memo(HighChartsTrend);
