import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'semji-core/components/Input/Checkbox';
import { StatusSelect } from 'semji-core/components/StatusSelect';
import { DeleteIcon } from 'semji-core/icons/DeleteIcon';
import styled from 'styled-components/macro';

import KeywordSuggest from '@/components/Autocomplete/KeywordSuggest';
import DatePicker from '@/components/DatePicker';
import FlatLoader, { CircularLoaderWrapper, LoaderWrapper } from '@/components/Loader/FlatLoader';
import FolderSelect from '@/components/Select/FolderSelect';
import UserSelect from '@/components/Select/UserSelect';

const FlexContainer = styled.div`
  display: flex;
  gap: 5px;
  padding-top: 5px;
`;

const PaddedWrapper = styled(LoaderWrapper)`
  margin-right: 1.5rem;
`;
const PaddedCircularWrapper = styled(CircularLoaderWrapper)`
  margin-right: 1.5rem;
`;
const DeleteButton = styled(({ display, ...props }) => (
  <IconButton aria-label="Delete" mode="inverted" {...props}>
    <DeleteIcon viewBox="0 0 17 17" />
  </IconButton>
))`
  && {
    opacity: ${(props) => (props.display ? 1 : 0)};
    padding: 0.5rem;
    font-size: 1rem;
  }
`;
const InputField = styled(({ pxSize, ...props }) => <TextField {...props} />)`
  && {
    width: ${(props) => props.pxSize}px;
    max-width: 700px;
    margin-bottom: ${({ error }) => (error ? '-24px' : '0px')};
    margin-right: 25px;
    & textarea {
      white-space: nowrap;
    }
  }
`;

function FocusKeywordRender({
  loading,
  index,
  isKeywordFocused,
  getSuggestionForKeyword,
  lastKnownSearch,
  openedTab,
  handleChange,
  onLastKnowSearchChange,
  focusKeyword,
  rowLastKnownSearch,
  questions,
  suggestions,
  filterSuggestions,
  filterQuestions,
  setOpenedTab,
  workspaceCountryName,
  handleKeyDown,
  onKeywordFocus,
  inputValidation,
  setInputValidation,
}) {
  const { t } = useTranslation();

  function handleOnChange(event, disableDefaultFocus) {
    handleChange({
      changingKey: 'focusKeyword',
      currentColumnIndex: 0,
      disableDefaultFocus,
      event,
      index,
      unchangingKey: 'title',
    });
    // Handle input validation on change
    const newErros = [...inputValidation.errors];
    if (!event?.target?.value?.length) {
      newErros[index] = t('drafts:new-contents.creation.error-empty-keyword');
    } else {
      newErros[index] = undefined;
    }
    setInputValidation({ ...inputValidation, errors: newErros, showError: false });
  }

  function setLastKnownSearch(s) {
    onLastKnowSearchChange(s, index);
  }

  function handleKeywordFocus(e) {
    onKeywordFocus(e, index);
  }

  function handleOnKeyDown(e) {
    handleKeyDown(index, 'focusKeyword', 'title', e);
  }

  useEffect(() => {
    const newErros = [...inputValidation.errors];
    if (focusKeyword?.length === 0) {
      newErros[index] = t('drafts:new-contents.creation.error-empty-keyword');
    } else {
      newErros[index] = undefined;
    }
    setInputValidation({ ...inputValidation, errors: newErros, showError: false });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusKeyword, index]);

  if (loading) {
    return (
      <PaddedWrapper height={30} width={360}>
        <FlatLoader />
      </PaddedWrapper>
    );
  }
  return (
    <KeywordSuggest
      key={`keyword_${index}_${isKeywordFocused}`}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={isKeywordFocused}
      data-intercom-target={index === 0 ? 'new_draft_keyword_suggest_input' : undefined}
      error={inputValidation.showError && !!inputValidation.errors[index]}
      getSuggestionForKeyword={getSuggestionForKeyword}
      helperText={inputValidation.showError ? inputValidation.errors[index] : ''}
      lastKnownSearch={lastKnownSearch}
      margin="dense"
      multiline
      openedTab={openedTab}
      placeholder={t('components:create-new-draft.add-focus-keyword')}
      pxSize={360}
      questions={filterQuestions(focusKeyword, questions)}
      rowLastKnownSearch={rowLastKnownSearch}
      rowsMax="1"
      setLastKnownSearch={setLastKnownSearch}
      setOpenedTab={setOpenedTab}
      suggestions={filterSuggestions(focusKeyword, suggestions)}
      value={focusKeyword}
      workspaceCountryName={workspaceCountryName}
      onChange={handleOnChange}
      onFocus={handleKeywordFocus}
      onKeyDown={handleOnKeyDown}
    />
  );
}

function TitleRender({
  loading,
  index,
  isTitleFocused,
  title,
  handleChange,
  handleKeyDown,
  onTitleFocus,
}) {
  const { t } = useTranslation();

  function handleOnChange(event, disableFocus) {
    handleChange({
      changingKey: 'title',
      currentColumnIndex: 1,
      disableFocus,
      event,
      index,
      unchangingKey: 'focusKeyword',
    });
  }

  function handleFocus(e) {
    onTitleFocus(e, index);
  }

  function handleOnKeyDown(e) {
    handleKeyDown(index, 'focusKeyword', 'title', e);
  }

  if (loading) {
    return (
      <PaddedWrapper height={30} width={300}>
        <FlatLoader />
      </PaddedWrapper>
    );
  }
  return (
    <InputField
      key={`title_${index}_${isTitleFocused}`}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={isTitleFocused}
      data-intercom-target={index === 0 ? 'new_draft_title_input' : undefined}
      margin="dense"
      multiline
      placeholder={t('components:create-new-draft.add-title')}
      pxSize={300}
      rowsMax="1"
      value={title}
      onChange={handleOnChange}
      onFocus={handleFocus}
      onKeyDown={handleOnKeyDown}
    />
  );
}

function UsersSelectRender({
  loading,
  index,
  handleUserChange,
  users,
  assignationReadOnly,
  userId,
}) {
  function handleChange(id) {
    handleUserChange(index, id);
  }

  if (loading) {
    return (
      <PaddedCircularWrapper height={32} width={32}>
        <FlatLoader />
      </PaddedCircularWrapper>
    );
  }
  return (
    <UserSelect
      key={`user_select_${userId}`}
      disabled={assignationReadOnly}
      handleChange={handleChange}
      hideInput
      options={users}
      shortVersion
      small
      value={userId}
      variant="column"
      width={300}
    />
  );
}

function DateSelectRender({ loading, dueDate, handleDueDateChange, index }) {
  function handleChange(date) {
    handleDueDateChange(index, date);
  }

  if (loading) {
    return (
      <PaddedCircularWrapper height={32} width={32}>
        <FlatLoader />
      </PaddedCircularWrapper>
    );
  }
  return (
    <DatePicker
      key={`date_picker_${dueDate || 'default'}`}
      dueDate={dueDate}
      handleChange={handleChange}
      shortVersion
      small
    />
  );
}

function StatusSelectRender({ loading, statusId, statuses, handleStatusChange, index }) {
  function handleChange({ id }) {
    handleStatusChange(index, id);
  }

  if (loading) {
    return (
      <PaddedWrapper height={30} width={110}>
        <FlatLoader />
      </PaddedWrapper>
    );
  }
  return (
    <StatusSelect
      key={`status_select_${statusId}`}
      handleChange={handleChange}
      options={statuses}
      value={statusId}
    />
  );
}

function FolderSelectRender({
  loading,
  folderId,
  folders,
  createNewFolder,
  handleFolderChange,
  index,
  isCreationDisabled,
}) {
  const { t } = useTranslation();

  function handleChange(id) {
    handleFolderChange(index, id);
  }

  if (loading) {
    return (
      <PaddedCircularWrapper height={32} width={32}>
        <FlatLoader />
      </PaddedCircularWrapper>
    );
  }
  return (
    <FolderSelect
      createNewFolder={createNewFolder}
      dropdownPillShape
      folders={folders}
      isCreationDisabled={isCreationDisabled}
      isFrame={false}
      setValue={handleChange}
      translations={{
        button: t('components:select.folder-select.create-folder.label-button'),
        cancel: t('components:select.folder-select.create-folder.cancel'),
        create_folder: t('components:select.folder-select.folder-select.label-create-folder'),
        folder_general: t('components:select.folder-select.label-folder-general'),
        label_no_parent: t('components:filters.folder-filters-panel.label-no-parent'),
        loading: t('components:select.folder-select.create-folder.label-loading'),
        placeHolder: t('components:select.folder-select.create-folder.input-placeholder'),
        search_placeholder: t('components:select.folder-select.folder-select.search-placeholder'),
        title: t('components:select.folder-select.create-folder.title'),
        tooltip_disabled: t('components:select.folder-select.create-folder.tooltip-disabled'),
      }}
      value={folderId}
      width="280px"
      withFolderCreation
      withSearchInput
    />
  );
}

function AssignationRender({
  index,
  loading,
  assignationReadOnly,
  createNewFolder,
  isCreationDisabled,
  handleUserChange,
  userId,
  users,
  dueDate,
  handleDueDateChange,
  handleStatusChange,
  handleFolderChange,
  statuses,
  statusId,
  folders,
  folderId,
}) {
  return (
    <FlexContainer data-intercom-target={index === 0 ? 'new_draft_assignation_actions' : undefined}>
      <UsersSelectRender
        assignationReadOnly={assignationReadOnly}
        handleUserChange={handleUserChange}
        index={index}
        loading={loading}
        userId={userId}
        users={users}
      />
      <DateSelectRender
        dueDate={dueDate}
        handleDueDateChange={handleDueDateChange}
        index={index}
        loading={loading}
      />
      <StatusSelectRender
        handleStatusChange={handleStatusChange}
        index={index}
        loading={loading}
        statuses={statuses}
        statusId={statusId}
      />
      <FolderSelectRender
        createNewFolder={createNewFolder}
        folderId={folderId}
        folders={folders}
        handleFolderChange={handleFolderChange}
        index={index}
        isCreationDisabled={isCreationDisabled}
        loading={loading}
        shortVersion
      />
    </FlexContainer>
  );
}

function NewDraft({
  row,
  index,
  isTitleFocused,
  isKeywordFocused,
  lastKnownSearch,
  workspaceCountryName,
  openedTab,
  handleChange,
  handleKeyDown,
  setOpenedTab,
  onKeywordFocus,
  handleUserChange,
  users,
  assignationReadOnly,
  statuses,
  handleStatusChange,
  handleDueDateChange,
  deleteRow,
  getSuggestionForKeyword,
  onLastKnowSearchChange,
  suggestions,
  questions,
  filterSuggestions,
  filterQuestions,
  onTitleFocus,
  handleFolderChange,
  folders,
  createNewFolder,
  isCreationDisabled,
  filteredDrafts,
  setFilteredDrafts,
  inputValidation,
  setInputValidation,
}) {
  const [draftsChecked, setDraftsChecked] = useState([]);
  function handleRemoveRow() {
    deleteRow(index);
    const newErros = [...inputValidation.errors].slice(0, index);
    setInputValidation({ ...inputValidation, errors: newErros, showError: false });
  }

  function isNewDraftChecked() {
    return draftsChecked.filter((draft) => draft.id === index).length > 0;
  }

  function handleCheckboxChange() {
    if (isNewDraftChecked()) {
      setFilteredDrafts(draftsChecked.filter((draft) => draft.id !== index));
    } else {
      setFilteredDrafts([...draftsChecked, { ...row, id: index }]);
    }
  }

  useEffect(() => {
    setDraftsChecked(filteredDrafts);
  }, [filteredDrafts]);

  return (
    <div className="flex items-center">
      {row.loading ? (
        <PaddedWrapper height={30} width={30}>
          <FlatLoader />
        </PaddedWrapper>
      ) : (
        <Checkbox
          checked={isNewDraftChecked()}
          name={row.focusKeyword}
          onChange={handleCheckboxChange}
        />
      )}
      <FocusKeywordRender
        filterQuestions={filterQuestions}
        filterSuggestions={filterSuggestions}
        focusKeyword={row.focusKeyword}
        getSuggestionForKeyword={getSuggestionForKeyword}
        handleChange={handleChange}
        handleKeyDown={handleKeyDown}
        index={index}
        inputValidation={inputValidation}
        isKeywordFocused={isKeywordFocused}
        lastKnownSearch={lastKnownSearch}
        loading={row.loading}
        openedTab={openedTab}
        questions={questions}
        rowLastKnownSearch={row.rowLastKnownSearch}
        setInputValidation={setInputValidation}
        setOpenedTab={setOpenedTab}
        suggestions={suggestions}
        workspaceCountryName={workspaceCountryName}
        onKeywordFocus={onKeywordFocus}
        onLastKnowSearchChange={onLastKnowSearchChange}
      />
      <TitleRender
        handleChange={handleChange}
        handleKeyDown={handleKeyDown}
        index={index}
        isTitleFocused={isTitleFocused}
        loading={row.loading}
        title={row.title}
        onTitleFocus={onTitleFocus}
      />
      <AssignationRender
        assignationReadOnly={assignationReadOnly}
        createNewFolder={createNewFolder}
        dueDate={row.dueDate}
        folderId={row.folderId}
        folders={folders}
        handleDueDateChange={handleDueDateChange}
        handleFolderChange={handleFolderChange}
        handleStatusChange={handleStatusChange}
        handleUserChange={handleUserChange}
        index={index}
        isCreationDisabled={isCreationDisabled}
        loading={row.loading}
        statuses={statuses}
        statusId={row.statusId}
        userId={row.userId}
        users={users}
      />
      {row.loading ? (
        <PaddedWrapper height={30} width={30}>
          <FlatLoader />
        </PaddedWrapper>
      ) : (
        <DeleteButton display={true} onClick={handleRemoveRow} />
      )}
    </div>
  );
}

export default NewDraft;
