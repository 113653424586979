import MuiRadio from '@material-ui/core/Radio';
import styled from 'styled-components/macro';

const Radio = styled(MuiRadio)`
  && {
    padding: 0;
  }
`;

export default Radio;
