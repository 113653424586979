import styled from 'styled-components/macro';

import Flex from '@/design-system/components/Flex';

export const ChildrenWrapper = styled(Flex)`
  box-shadow: ${({ theme }) => theme.boxShadow.modal};
  width: 260px;
  box-sizing: border-box;
`;

export const AnchorWrapper = styled(Flex)`
  border-radius: 20px;
`;
