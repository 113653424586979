import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { removeDefaultReportId, removeDefaultWebsiteId } from '@/store/actions/searchIntelligence';
import { logoutUser } from '@/store/actions/user';

export default function Logout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function logout() {
    dispatch(logoutUser());
    dispatch(removeDefaultReportId());
    dispatch(removeDefaultWebsiteId());
    navigate('/', { replace: true });
  }

  useEffect(() => {
    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
