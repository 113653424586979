import React from 'react';
import { useTranslation } from 'react-i18next';
import SortDirection from 'react-virtualized/dist/es/Table/SortDirection';
import { Checkbox } from 'semji-core/components/Input/Checkbox';
import { getDecimalPlaces } from 'semji-core/utils/numbers';
import styled from 'styled-components/macro';

import { FlexCenteredContainer, FlexGrowContainer } from '@/components/_common';
import FlatLoader, { LoaderWrapper } from '@/components/Loader/FlatLoader';
import NotAcknowledgedCell from '@/components/Table/Cell/NotAcknowledgedCell';
import NumberCell from '@/components/Table/Cell/NumberCell';
import TitleDefaultCell from '@/components/Table/Cell/TitleDefaultCell';
import Table from '@/components/Table/Table';
import Ellipsis from '@/components/Text/Ellipsis';
import { METRIC_TYPE_NUMBER } from '@/utils/metrics/constants';

const FocusKeywordCell = styled(Ellipsis)`
  flex: ${(props) => `0 1 ${props.width}px`};
  padding: 5px 0;
`;

function TopPages(props) {
  const { t } = useTranslation();

  const columns = [
    {
      align: 'center',
      cellDataGetter: ({ rowData }) => ({
        id: rowData.id,
        loading: rowData.loading,
      }),
      cellRenderer: ({ cellData }) =>
        cellData.loading ? (
          <LoaderWrapper height={20} width={20}>
            <FlatLoader />
          </LoaderWrapper>
        ) : (
          <Checkbox
            checked={!!props.selectedPages.find((page) => page.id === cellData.id)}
            name={cellData.id}
            onChange={handleSelectChange}
          />
        ),
      dataKey: 'select',
      headerCellRenderer: () => (
        <FlexCenteredContainer>
          <Checkbox
            checked={props.selectedPages.length === props.rows.length}
            onChange={handleSelectAllChange}
          />
        </FlexCenteredContainer>
      ),
      label: t('pages:top-pages.headers.select'),
      sortable: false,
      width: 50,
    },
    {
      cellDataGetter: ({ rowData }) => ({
        isRedirectionDetected: !!rowData.redirectionUrl,
        lastStatusCode: rowData.lastStatusCode,
        loading: (!rowData.titleRetrievedAt && !rowData.title) || rowData.loading,
        title: rowData.title,
        url: rowData.url,
      }),
      cellRenderer: ({ cellData }) => (
        <TitleDefaultCell
          isRedirectionDetected={cellData.isRedirectionDetected}
          lastStatusCode={cellData.lastStatusCode}
          loading={cellData.loading}
          title={cellData.title}
          url={cellData.url}
        />
      ),
      dataKey: 'title',
      flexGrow: 1,
      label: t('pages:top-pages.headers.pages'),
      sortable: false,
      width: 150,
    },
    {
      align: 'center',
      cellDataGetter: ({ rowData }) => ({
        countryMonthlyClicks: rowData.countryMonthlyClicks,
        loading: rowData.loading,
      }),
      cellRenderer: ({ cellData }) => {
        if (cellData.loading)
          return (
            <LoaderWrapper height={15} width={70}>
              <FlatLoader />
            </LoaderWrapper>
          );

        if (cellData.countryMonthlyClicks) {
          const decimalPlaces = getDecimalPlaces(cellData.countryMonthlyClicks, METRIC_TYPE_NUMBER);
          return (
            <NumberCell
              decimalPlaces={decimalPlaces}
              number={
                cellData.countryMonthlyClicks ? Math.floor(cellData.countryMonthlyClicks) : null
              }
            />
          );
        }

        return <NotAcknowledgedCell />;
      },
      dataKey: 'countrySeoTraffic',
      label: t('pages:top-pages.headers.monthly-clicks', {
        wkCountryCode: props.workspaceCountryCode,
      }),
      sortFunction: (value1, value2, sortDirection) =>
        Table.naturalSort(value1.countryMonthlyClicks, value2.countryMonthlyClicks, sortDirection),
      sortable: true,
      width: 100,
    },
    {
      cellDataGetter: ({ rowData }) => ({
        keyword: rowData.focusTopKeyword?.keyword || null,
        loading:
          (!rowData.topKeywordsRetrievedAt && !rowData.focusTopKeyword?.keyword) || rowData.loading,
      }),
      cellRenderer: ({ cellData }) => {
        if (cellData.loading) {
          return (
            <LoaderWrapper height={15} width={150}>
              <FlatLoader />
            </LoaderWrapper>
          );
        }

        if (cellData.keyword !== null) {
          return (
            <FocusKeywordCell title={cellData.keyword} width={180}>
              {cellData.keyword}
            </FocusKeywordCell>
          );
        }

        return <NotAcknowledgedCell />;
      },
      dataKey: 'focusKeyword',
      label: t('pages:top-pages.headers.focus-keyword'),
      sortFunction: (value1, value2, sortDirection) =>
        Table.stringSort(value1.keyword, value2.keyword, sortDirection),
      sortable: true,
      width: 180,
    },
    {
      align: 'center',
      cellDataGetter: ({ rowData }) => ({
        loading:
          (!rowData.topKeywordsRetrievedAt && rowData.focusTopKeyword?.position === null) ||
          rowData.loading,
        position: rowData.focusTopKeyword?.position || null,
      }),
      cellRenderer: ({ cellData }) => {
        if (cellData.loading) {
          return (
            <LoaderWrapper height={15} width={40}>
              <FlatLoader />
            </LoaderWrapper>
          );
        }
        if (cellData.position !== null) {
          return <NumberCell number={cellData.position} />;
        }
        return <NotAcknowledgedCell />;
      },
      dataKey: 'focusKeywordPosition',
      label: t('pages:top-pages.headers.position'),
      sortFunction: (value1, value2, sortDirection) =>
        Table.naturalSort(value1.position, value2.position, sortDirection),
      sortable: true,
      width: 60,
    },
    {
      align: 'center',
      cellDataGetter: ({ rowData }) => ({
        loading:
          (!rowData.topKeywordsRetrievedAt &&
            !rowData.focusTopKeyword?.keywordDataUpdatedAt &&
            rowData.focusTopKeyword?.searchVolume === null) ||
          rowData.loading,
        searchVolume: rowData.focusTopKeyword?.searchVolume || null,
      }),
      cellRenderer: ({ cellData }) => {
        if (cellData.loading) {
          return (
            <LoaderWrapper height={15} width={40}>
              <FlatLoader />
            </LoaderWrapper>
          );
        }
        if (cellData.searchVolume !== null) {
          const decimalPlaces = getDecimalPlaces(cellData.searchVolume, METRIC_TYPE_NUMBER);
          return (
            <NumberCell
              decimalPlaces={decimalPlaces}
              number={cellData.searchVolume ? Math.floor(cellData.searchVolume) : null}
            />
          );
        }
        return <NotAcknowledgedCell />;
      },
      dataKey: 'focusKeywordVolume',
      label: t('pages:top-pages.headers.volume'),
      sortFunction: (value1, value2, sortDirection) =>
        Table.naturalSort(value1.searchVolume, value2.searchVolume, sortDirection),
      sortable: true,
      width: 60,
    },
  ];

  const handleSelectAllChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    let pages = [];

    if (value) {
      pages = props.rows;
    } else {
      pages = [];
    }

    props.setSelectedPages(pages);
  };

  const handleSelectChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const id = target.name;
    let pages = [];

    if (value) {
      pages = [...props.selectedPages, props.rows.find((row) => row.id === id)];
    } else {
      pages = props.selectedPages.filter((page) => page.id !== id);
    }

    props.setSelectedPages(pages);
  };

  return (
    <FlexGrowContainer>
      <Table
        columns={columns}
        defaultSort="countrySeoTraffic"
        defaultSortDirection={SortDirection.DESC}
        mode="transparent"
        rows={props.rows}
      />
    </FlexGrowContainer>
  );
}

export default TopPages;
