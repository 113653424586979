import i18next from 'i18next';
import { DateTime } from 'luxon';
import React from 'react';
import { DashboardIcon } from 'semji-core/icons/DashboardIcon';
import { LayoutGridIcon } from 'semji-core/icons/LayoutGridIcon';
import { PercentIcon } from 'semji-core/icons/PercentIcon';
import { PositionIcon } from 'semji-core/icons/PositionIcon';

import Agenda from '@/components/icons/Agenda';
import BarChartIcon from '@/components/icons/BarChartIcon';
import ExchangeVerticalIcon from '@/components/icons/ExchangeVerticalIcon';
import EyeIcon from '@/components/icons/EyeIcon';
import FolderIcon from '@/components/icons/FolderIcon';
import LinkIcon from '@/components/icons/LinkIcon';
import PlanIcon from '@/components/icons/PlanIcon';
import SearchIcon from '@/components/icons/SearchIcon';
import SmallCapIcon from '@/components/icons/SmallCapIcon';
import TextIcon from '@/components/icons/TextIcon';
import UnfilledUserIcon from '@/components/icons/UnfilledUserIcon';
import {
  ReportMetricCategory,
  ReportMetricChartType,
  ReportMetricEvolutionRating,
  ReportMetricKey,
  ReportMetricSparklineMovingType,
  ReportMetricType,
} from '@/containers/Report/utils/types';
import { UserModel as User } from '@/types/user/user.types';
import {
  BOOL_TYPE_FILTER,
  DATE_TYPE_FILTER,
  NUMBER_TYPE_FILTER,
  SELECT_TYPE_FILTER,
  STRING_TYPE_FILTER,
} from '@/utils/filter/constants';
import {
  FOCUS_KEYWORDS,
  METRICS_CONFIG as DEFAULT_METRICS_CONFIG,
  NO_DEPENDENCY,
  reducers,
  TOP_100_TAB,
} from '@/utils/metrics/constants';

const { publicationsCount, ...COMMON_METRICS_CONFIG } = DEFAULT_METRICS_CONFIG;

export const GOAL_KEY_QUERY_PARAM = 'goalKey';

export const METRICS_CONFIG = {
  [ReportMetricKey.overview]: {
    allowDecimals: false,
    category: ReportMetricCategory.Acquisition,
    chartType: ReportMetricChartType.Line,
    default: true,
    dependency: NO_DEPENDENCY,
    evolutionRating: ReportMetricEvolutionRating.Positive,
    icon: <LayoutGridIcon />,
    key: ReportMetricKey.overview,
    name: 'report:chart.metric-tabs.overview',
    reducer: reducers.sum,
    sparklineMovingType: ReportMetricSparklineMovingType.Sum,
    suffix: '',
    type: ReportMetricType.Number,
  },
  [ReportMetricKey.publicationsCount]: {
    ...publicationsCount,
    category: ReportMetricCategory.Production,
    name: 'report:chart.metric-tabs.publications',
  },
  [ReportMetricKey.AverageContentScore]: {
    allowDecimals: false,
    category: ReportMetricCategory.Production,
    chartType: ReportMetricChartType.Line,
    default: true,
    dependency: NO_DEPENDENCY,
    evolutionRating: ReportMetricEvolutionRating.Positive,
    icon: <DashboardIcon />,
    key: ReportMetricKey.AverageContentScore,
    name: 'report:chart.metric-tabs.publicationsContentScore',
    reducer: reducers.avg,
    sparklineMovingType: ReportMetricSparklineMovingType.Avg,
    suffix: '',
    tooltip: { context: 'male', count: 2 },
    type: ReportMetricType.Percent,
  },

  ...COMMON_METRICS_CONFIG,
};

const { position, ...REST_METRICS_CONFIG } = COMMON_METRICS_CONFIG;

export const DASHBOARD_METRICS_CONFIG = {
  [ReportMetricKey.publicationsCount]: {
    ...publicationsCount,
    name: 'report:chart.metric-tabs.publications',
  },
  [FOCUS_KEYWORDS]: {
    allowDecimals: false,
    category: ReportMetricKey.Position,
    chartType: ReportMetricChartType.Bar,
    default: true,
    dependency: NO_DEPENDENCY,
    evolutionRating: ReportMetricEvolutionRating.Positive,
    icon: <DashboardIcon />,
    key: FOCUS_KEYWORDS,
    name: 'report:chart.metric-subtabs.focus-keywords',
    reducer: reducers.sum,
    sparklineMovingType: ReportMetricSparklineMovingType.Avg,
    suffix: '',
    tooltip: { context: 'male', count: 2 },
  },
  [TOP_100_TAB]: {
    allowDecimals: false,
    category: ReportMetricKey.Position,
    chartType: ReportMetricChartType.Line,
    default: true,
    dependency: NO_DEPENDENCY,
    evolutionRating: ReportMetricEvolutionRating.Positive,
    icon: <DashboardIcon />,
    key: TOP_100_TAB,
    name: 'report:chart.metric-subtabs.top100-keywords',
    reducer: reducers.sum,
    sparklineMovingType: ReportMetricSparklineMovingType.Avg,
    suffix: '',
    tooltip: { context: 'male', count: 2 },
  },
  [ReportMetricKey.AverageContentScore]: {
    allowDecimals: false,
    averageScoreIndex: 1,
    category: ReportMetricCategory.Performance,
    chartType: ReportMetricChartType.Line,
    default: true,
    dependency: NO_DEPENDENCY,
    evolutionRating: ReportMetricEvolutionRating.Positive,
    icon: <DashboardIcon />,
    key: ReportMetricKey.AverageContentScore,
    name: 'report:chart.metric-tabs.publicationsContentScore',
    reducer: reducers.avg,
    sparklineMovingType: ReportMetricSparklineMovingType.Avg,
    suffix: '',
    tooltip: { context: 'male', count: 2 },
    type: ReportMetricType.Percent,
  },
  ...REST_METRICS_CONFIG,
};

export const CHART_FORMAT_VALUES = [
  {
    title: <PositionIcon />,
    tooltip: i18next.t('competitors:toggle.value'),
    value: 'normal',
  },
  {
    title: <PercentIcon />,
    tooltip: i18next.t('competitors:toggle.percentage'),
    value: 'percent',
  },
];

export const PAGES_TABLE_PROPERTIES = [
  'id',
  'url',
  'title',
  'contentScore',
  'draft',
  'draftId',
  'extra',
  'optimizedImages',
  'imageUrl',
  'source',
  'new',
  'redirectionUrl',
  'focusTopKeyword',
  'isTracked',
  'titleRetrievedAt',
  'topKeywordsRetrievedAt',
  'lastStatusCode',
];

export function getFiltersList({
  nullUser,
  users,
  folders,
  isRankTrackingEnabled,
}: {
  nullUser: {
    id: string;
    lastName: string;
  };
  users: User[];
  folders: {
    id: string;
    name: string;
    parentFolderId?: string;
  }[];
  isRankTrackingEnabled: boolean;
}) {
  const filters = [
    {
      attribute: 'title',
      icon: <SmallCapIcon opacity={0.6} />,
      labelKey: 'report:filters-panel.title',
      type: STRING_TYPE_FILTER,
      uid: 'title',
    },
    {
      attribute: 'url',
      icon: <LinkIcon opacity={0.6} />,
      labelKey: 'report:filters-panel.url',
      type: STRING_TYPE_FILTER,
      uid: 'url',
    },
    {
      attribute: 'keyword',
      icon: <TextIcon opacity={0.6} />,
      labelKey: 'report:filters-panel.keyword',
      type: STRING_TYPE_FILTER,
      uid: 'keyword',
    },
    {
      attribute: 'lastPublishedAt',
      getValueByAttribute: (list: any, attribute: string) =>
        list[attribute] ? DateTime.fromISO(list[attribute]).toFormat('yyyy-MM-dd') : null,
      icon: <Agenda opacity={0.6} />,
      labelKey: 'report:filters-panel.publicationDate',
      mappingValues: (value: any) => ({
        labelKey: value?.isValid ? value.toFormat('yyyy-MM-dd') : null,
        value: value?.isValid ? value.toFormat('yyyy-MM-dd') : null,
      }),
      type: DATE_TYPE_FILTER,
      uid: 'lastPublishedAt',
    },
    {
      attribute: 'position',
      icon: <BarChartIcon opacity={0.6} />,
      labelKey: 'report:filters-panel.average-position',
      mappingValues: (value: any) => ({
        labelKey: value === true ? '' : value,
        value,
      }),
      type: NUMBER_TYPE_FILTER,
      uid: 'position',
    },
    {
      attribute: 'currentPosition',
      icon: <PositionIcon />,
      labelKey: 'report:filters-panel.position',
      mappingValues: (value: any) => ({
        labelKey: value === true ? '' : value,
        value,
      }),
      type: NUMBER_TYPE_FILTER,
      uid: 'currentPosition',
    },
    {
      attribute: 'searchVolume',
      icon: <SearchIcon opacity={0.6} />,
      labelKey: 'report:filters-panel.search-volume',
      mappingValues: (value: any) => ({
        labelKey: value === true ? '' : value,
        value,
      }),
      type: NUMBER_TYPE_FILTER,
      uid: 'searchVolume',
    },
    {
      attribute: 'contentScore',
      getValueByAttribute: (list: any) =>
        list.contentScore === null ? null : list.contentScore * 100,
      icon: <EyeIcon opacity={0.6} />,
      labelKey: 'report:filters-panel.content-score',
      mappingValues: (value: any) => ({
        labelKey: value === true ? '' : value,
        value,
      }),
      type: NUMBER_TYPE_FILTER,
      uid: 'contentScore',
    },
    {
      attribute: 'draftId',
      getValueByAttribute: (list: any) => !!list.draftId,
      icon: <PlanIcon opacity={0.6} />,
      labelKey: 'report:filters-panel.planning.title',
      mappingValues: (value: any) => ({
        labelKey: 'report:filters-panel.planning.label',
        value,
      }),
      placeholder: 'report:filters-panel.planning.placeholder',
      type: BOOL_TYPE_FILTER,
      uid: 'draftId',
    },
    {
      attribute: 'assignedToIds',
      group: 'report:filters-panel.group.associated-contents',
      icon: <UnfilledUserIcon opacity={0.6} />,
      labelKey: 'report:filters-panel.assigned-to',
      mappingValues: (value: any) => ({
        labelKey: users.find((user) => user.id === value)?.email || nullUser.lastName,
        value,
      }),
      selectList: [
        { labelKey: nullUser.lastName, value: nullUser.id },
        ...users.map((user) => ({
          labelKey: user.email,
          value: user.id,
        })),
      ],
      type: SELECT_TYPE_FILTER,
      uid: 'assignedToIds',
    },
    {
      attribute: 'folderIds',
      group: 'report:filters-panel.group.associated-contents',
      icon: <FolderIcon opacity={0.6} />,
      labelKey: 'report:filters-panel.folder',
      mappingValues: (value: any) => ({
        labelKey: folders.find((folder) => folder.id === value)?.name,
        value,
      }),
      selectList: folders.map((folder) => ({
        htmlLabel: `${folder.parentFolderId ? '&emsp;' : ''}${folder?.name}`,
        labelKey: folder?.name,
        value: folder.id,
      })),
      type: SELECT_TYPE_FILTER,
      uid: 'folderIds',
    },
  ];

  if (isRankTrackingEnabled) {
    filters.push({
      attribute: 'evolution',
      getValueByAttribute: (list: any) => list.evolution,
      icon: <ExchangeVerticalIcon opacity={0.6} />,
      labelKey: 'report:filters-panel.evolution',
      mappingValues: (value) => ({
        labelKey: value === true ? '' : value,
        value,
      }),
      type: NUMBER_TYPE_FILTER,
      uid: 'evolution',
    });
  }

  return filters;
}
