import {
  CHANGE_METRICS_CONFIG_CURRENT_METRIC,
  CHANGE_SELECTED_METRIC,
  SET_COMPETITOR_DATA_LABELS_ENABLED,
  SET_FACT_CHECKING_ENABLED,
  SET_HIGHLIGHT_COMMENT_ENABLED,
  SET_HIGHLIGHT_FACT_CHECKING_ENABLED,
  SET_MARK_AS_PUBLISHED_HELPER_MESSAGE_ENABLED,
  SET_REPORT_ACCORDION_STATE,
} from './actionTypes';

export const changeCurrentMetric = (currentMetricKey) => ({
  currentMetricKey,
  type: CHANGE_METRICS_CONFIG_CURRENT_METRIC,
});

export const changeSelectedMetric = (index, metricKey) => ({
  index,
  metricKey,
  type: CHANGE_SELECTED_METRIC,
});

export const setFactCheckingEnabled = (payload) => ({
  payload,
  type: SET_FACT_CHECKING_ENABLED,
});

export const setHighlightCommentEnabled = (payload) => ({
  payload,
  type: SET_HIGHLIGHT_COMMENT_ENABLED,
});

export const setHighlightFactCheckingEnabled = (payload) => ({
  payload,
  type: SET_HIGHLIGHT_FACT_CHECKING_ENABLED,
});
export const setMarkAsPublishedHelperMessageEnabled = (payload) => ({
  payload,
  type: SET_MARK_AS_PUBLISHED_HELPER_MESSAGE_ENABLED,
});

export const setCompetitorDataLabelsEnabled = (payload) => ({
  payload,
  type: SET_COMPETITOR_DATA_LABELS_ENABLED,
});

export const setReportAccordionState = (payload) => ({
  payload,
  type: SET_REPORT_ACCORDION_STATE,
});
