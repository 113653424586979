import IconButton from '@material-ui/core/IconButton';
import React, { useState } from 'react';

import { DefaultMenu } from '../../components/_common';

export default function MoreMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (event) => {
    if (!props.disabled) {
      setAnchorEl(event.currentTarget);
    }
  };

  return (
    <>
      <IconButton
        aria-haspopup="true"
        aria-label="More"
        aria-owns={!!anchorEl ? 'long-menu' : undefined}
        disabled={props.disabled}
        onClick={handleOpen}
      >
        {props.triggerIcon}
      </IconButton>
      <DefaultMenu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
        {(props.children || []).filter(Boolean).map((child, index) =>
          React.cloneElement(child, {
            key: `menu_items_${index}`,
            onClick: () => {
              child.props.onClick();
              handleClose();
            },
          })
        )}
      </DefaultMenu>
    </>
  );
}
