import { useSelector } from 'react-redux';

import { checkCanDebug } from '@/utils/log/checkCanDebug';

function useDebug(property) {
  const value = useSelector((state) => state.debug?.[property]);
  if (checkCanDebug()) {
    return value;
  } else {
    return null;
  }
}

export default useDebug;
