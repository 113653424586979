/* eslint-disable no-console */
import { checkCanDebug } from '@/utils/log/checkCanDebug';
import { ReportOptions } from '@/utils/log/Log.types';
import { noop } from '@/utils/noop';

// https://developer.chrome.com/docs/devtools/console/api/
export class Log {
  constructor() {
    if (!checkCanDebug()) {
      // Clear console on prod envs to hide ugly GTM erros
      console.clear();
    }

    this.printSemjiLogo();
  }

  // @deprecated: Please use browserAgent context
  static async report(options: ReportOptions) {
    const { error = '', section = 'default', context = '', extra = '' } = options;

    newrelic?.noticeError(error, { contexts: context, extra, section });
    Log.error(error);
  }

  static error(...args: any[]) {
    console.trace(...args);
  }

  static warn(...args: any[]) {
    if (checkCanDebug()) {
      console.warn(...args);
    }
  }

  static info(...args: any[]) {
    console.info(...args);
  }

  static log(...args: any[]) {
    if (checkCanDebug()) {
      console.log(...args);
    }
  }
  printSemjiLogo() {
    // https://www.bennadel.com/blog/3941-styling-console-log-output-formatting-with-css.htm
    const styles = `
    background-color: white;
    background-image: url('${window.location.origin}/images/logo-semji.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    padding: 50px 49%;
    border: 20px solid white;
    `;
    console.log('%c ', styles);
  }

  async timer(func = noop) {
    if (checkCanDebug()) {
      // Get original function name
      const name = func.name;
      console.time(name);
      await func();
      console.timeEnd(name);
    }
  }
}
