import { useMutation } from '@tanstack/react-query';

import apiClient from '@/apis/semji/apiClient';

export default function usePostUserAvatar(props) {
  const { post } = apiClient;

  return useMutation({
    mutationFn: ({ userId, data }) =>
      post(`/users/${userId}/profile_image`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),

    ...props,
  });
}
