import './OrganizationSelector.scss';

import React from 'react';

import { OrganizationMenu, OrganizationMenuItem } from '@/components/Profile/OrganizationsList';
import Selector, { SelectorBox } from '@/components/Selector';

function OrganizationSelector({
  organizationId,
  onClose,
  organizationAccesses,
  organizationName,
  handleClick,
}: {
  organizationId: string;
  onClose: () => void;
  organizationAccesses: any[];
  organizationName: string;
  handleClick: () => void;
}) {
  const organizations = organizationAccesses.map(
    (organizationAccess) => organizationAccess.organization
  );

  return (
    <Selector
      button={({ openMenu }: any) => (
        <OrganizationMenu
          className="organization-selector__anchor"
          label={organizationName}
          noDropDown={organizations.length <= 1}
          onClick={(e) => {
            if (organizations.length > 1) openMenu(e);
            handleClick();
          }}
          onClose={onClose}
        />
      )}
    >
      {(selectorBoxProps: any) => (
        <SelectorBox
          anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          {...selectorBoxProps}
        >
          <div className="organization-selector__list">
            {organizations
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((organization) => (
                <OrganizationMenuItem
                  key={organization.id}
                  className="organization-selector__item"
                  label={organization.name}
                  selected={organization.id.includes(organizationId)}
                  url={`/o/${organization.id}/`}
                />
              ))}
          </div>
        </SelectorBox>
      )}
    </Selector>
  );
}

export default OrganizationSelector;
