import './Loader.scss';

import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';
import { VersusCard } from 'semji-core/components/VersusCard';

import useGetCompetitorReportById from '@/apis/semji/competitors/useGetCompetitorReportById';
import useGetCompetitorReportWebsites from '@/apis/semji/competitors/useGetCompetitorReportWebsites';
import FavIcon from '@/components/FavIcon/FavIcon';
import FileIcon from '@/components/icons/FileIcon';
import PieChart from '@/components/icons/PieChart';
import StatsUpIcon from '@/components/icons/StatsUpIcon';
import { RelativeLoader } from '@/components/Loader/Loader';
import {
  getCompetitorDomains,
  getEmptyStateImage,
  getHostnamefromUrl,
  getWebsiteReference,
} from '@/containers/Competitors/utils/utils';
import { COMPETITOR_REPORT_STATUS_CURRENT_ACTIVE } from '@/types/competitors';
import { formatUrl } from '@/utils/url';

export default function Loader() {
  const { t, i18n } = useTranslation();

  const { organizationId, workspaceId, reportId: competitorReportId } = useParams();

  //Do not show error message if competitorReportId is not present
  // Because user will be redirected to the appropriate page
  const handleError = !!competitorReportId ? {} : { onError: () => {} };

  const {
    data: competitorReport,
    isFetching: isCompetitorReportFetching,
    isPlaceholderData: isCompetitorPlaceholder,
  } = useGetCompetitorReportById({
    competitorReportId,
    ...handleError,
  });

  const {
    data: websites = [],
    isFetching,
    isPlaceholderData,
  } = useGetCompetitorReportWebsites({
    competitorReportId: competitorReportId,
    ...handleError,
  });

  if ((isCompetitorReportFetching && isCompetitorPlaceholder) || isFetching || isPlaceholderData) {
    return (
      <div className="flex flex-col align-center justify-center h-full">
        <RelativeLoader fontSize="3em" opaque />
      </div>
    );
  }

  const websiteReference = getWebsiteReference(websites ?? []);
  const domainReference = getHostnamefromUrl(websiteReference.url);
  const competitorWebsites = websites.filter((w) => !w.isSelfReference);
  const competitorDomains = getCompetitorDomains(competitorWebsites).map((domain) => ({
    ...domain,
    favicon: <FavIcon disableClick displayLink={false} url={formatUrl(domain.url)} />,
  }));

  if (
    !isCompetitorReportFetching &&
    competitorReport.status === COMPETITOR_REPORT_STATUS_CURRENT_ACTIVE
  ) {
    return (
      <Navigate
        replace
        state={{ competitorReportId, competitorWebsites, websiteReference }}
        to={`/o/${organizationId}/w/${workspaceId}/search-intelligence/${competitorReportId}/reports`}
      />
    );
  }

  return (
    <>
      <div className="competitor-loader-page">
        <img
          alt=""
          className="competitor-loader-page__image"
          src={getEmptyStateImage(i18n.language)}
        />
        <RelativeLoader fontSize="4em" />
        <div className="competitor-loader-page__title">
          <h4>{t('competitors:loading.title')}</h4>
        </div>
        <div className="competitor-loader-page__description">
          <p>{t('competitors:loading.description')}</p>
        </div>
        <VersusCard
          domains={competitorDomains}
          reference={domainReference}
          referenceFavicon={
            <FavIcon disableClick displayLink={false} url={formatUrl(domainReference)} />
          }
        />

        <div className="competitor-loader-page__cardgrids">
          <div className="competitor-loader-page__cardgrids__card">
            <div className="competitor-loader-page__cardgrids__card__title">
              <FileIcon />
              {t('competitors:loading.card-0.title')}
            </div>
            <div className="competitor-loader-page__cardgrids__card__content">
              {t('competitors:loading.card-0.content')}
            </div>
          </div>

          <div className="competitor-loader-page__cardgrids__card">
            <div className="competitor-loader-page__cardgrids__card__title">
              <PieChart /> {t('competitors:loading.card-1.title')}
            </div>
            <div className="competitor-loader-page__cardgrids__card__content">
              {t('competitors:loading.card-1.content')}
            </div>
          </div>

          <div className="competitor-loader-page__cardgrids__card">
            <div className="competitor-loader-page__cardgrids__card__title">
              <StatsUpIcon /> {t('competitors:loading.card-2.title')}
            </div>
            <div className="competitor-loader-page__cardgrids__card__content">
              {t('competitors:loading.card-2.content')}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
