import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { StyledCard } from '@/components/_common';
import { PlanRestrictionBadge } from '@/components/Badge/PlanRestrictionBadge';
import { LinkLikeButton } from '@/components/Button/Button';
import WarningMessage from '@/components/Message/Message';
import { ContainedLinkButton } from '@/components/Navigation/Link';
import { TitleFour, TitleThree } from '@/components/Text/Title';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import { CreditLimitationSettingsModal } from '@/containers/Settings/Organization/CreditLimitationSettingsModal/CreditLimitationSettingsModal';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import { selectUserCurrentOrganizationRoles } from '@/store/selectors/selectUserCurrentOrganizationRoles';
import { API_ORGANIZATION_ROLE_ADMIN } from '@/utils/can/constants';
import { SUPPORT_ADDRESS_MAIL } from '@/utils/constants';

import { Section } from '.';
import Credits from './Credits';

const CustomStyledCard = styled(StyledCard)`
  padding: 24px 32px 32px 32px;
  min-width: 100%;
  box-sizing: border-box;
  flex-direction: column;
`;

const StyledWarningMessage = styled(WarningMessage)`
  margin-top: 16px;
  margin-bottom: 32px;
  p:first-of-type {
    padding: 1rem;
    font-weight: 400;
  }
`;

const FlexAlignCenter = styled.div`
  display: flex;
  align-items: center;
  margin: ${({ margin }) => margin};
  min-height: ${({ minHeight }) => minHeight};
  min-width: ${({ minWidth }) => minWidth};
  justify-content: ${({ justifyContent }) => justifyContent};
`;

function CustomPlan({ currentOrganization }) {
  const { t } = useTranslation();
  const [showUnitLimitationModal, setShowUnitLimitationModal] = useState(false);

  const userRole = useSelector(selectUserCurrentOrganizationRoles);
  const isOrganizationAdmin = userRole.includes(API_ORGANIZATION_ROLE_ADMIN);
  const { isFeatureEnabled: isWorkspaceUnitLimitationEnabled } = useOrganizationFeatureSet(
    'organization:workspace-unit-limitation:is-enabled'
  );

  const planName = currentOrganization.planName;

  function renderWorkspaceUnitLimitationButton() {
    if (isWorkspaceUnitLimitationEnabled && isOrganizationAdmin) {
      return (
        <LinkLikeButton
          data-intercom-target="product_tour_manage_workspaces_ai_unit"
          onClick={() => setShowUnitLimitationModal(true)}
        >
          {t('billing:credit-limitation.manage-unit')}
        </LinkLikeButton>
      );
    }
    if (isWorkspaceUnitLimitationEnabled) {
      return (
        <TooltipComponent
          className="w-56"
          placement="bottom"
          title={t(`billing:credit-limitation.not-admin-tooltip`)}
        >
          <LinkLikeButton
            data-intercom-target="product_tour_manage_workspaces_ai_unit"
            isDisabled={true}
          >
            {t('billing:credit-limitation.manage-unit')}
          </LinkLikeButton>
        </TooltipComponent>
      );
    }

    return (
      <TooltipComponent
        className="w-fit"
        placement="bottom"
        title={t(`components:badge.plan.available-on-higher-plan`)}
      >
        <LinkLikeButton
          className="flex items-center gap-1"
          data-intercom-target="product_tour_manage_workspaces_ai_unit"
          isDisabled={true}
        >
          <PlanRestrictionBadge hasTooltip={false} isCondensed />
          {t('billing:credit-limitation.manage-unit')}
        </LinkLikeButton>
      </TooltipComponent>
    );
  }

  return (
    <Section>
      <TitleFour weight="strong">{t('settings:workspace.billing-plan.title-custom')}</TitleFour>
      <br />
      <CustomStyledCard>
        <TitleThree weight="strong">
          <Trans
            components={{
              medium: <span />,
            }}
            i18nKey={'settings:workspace.billing-plan.subtitle'}
            values={{
              planName,
            }}
          />
        </TitleThree>
        <StyledWarningMessage type="warning">
          <FlexAlignCenter justifyContent="space-between">
            <p>{t('settings:workspace.billing-plan.warning-custom-message')}</p>
            <ContainedLinkButton
              minWidth="120px"
              OutlinedButton
              to={`mailto:${SUPPORT_ADDRESS_MAIL}`}
              weight="normal"
            >
              {t('settings:workspace.billing-plan.button-contact-us')}
            </ContainedLinkButton>
          </FlexAlignCenter>
        </StyledWarningMessage>
        <Credits organization={currentOrganization} />
        {renderWorkspaceUnitLimitationButton()}
        {isOrganizationAdmin && isWorkspaceUnitLimitationEnabled && (
          <CreditLimitationSettingsModal
            setShowUnitLimitationModal={setShowUnitLimitationModal}
            showUnitLimitationModal={showUnitLimitationModal}
          />
        )}
      </CustomStyledCard>
    </Section>
  );
}

export default CustomPlan;
