import { useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { LOCATION, SCOPE_SERVICE_INTEGRATIONS } from '@/apis/semji/constants';
import usePostMatomoSites from '@/apis/semji/serviceIntegrations/Matomo/usePostMatomoSites';
import useDeleteServiceIntegration from '@/apis/semji/serviceIntegrations/useDeleteServiceIntegration';
import usePostServiceIntegration from '@/apis/semji/serviceIntegrations/usePostServiceIntegration';
import Stepper from '@/components/Stepper/Stepper';
import ApiInformations from '@/containers/ServiceIntegration/common/ApiInformations';
import SelectService from '@/containers/ServiceIntegration/common/SelectService';
import { MATOMO_VALUE, SERVICES_LIST, TYPE_ANALYTICS } from '@/utils/constants';

function MatomoServiceIntegration() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { organizationId, workspaceId } = useParams();
  const queryClient = useQueryClient();

  const [step, setStep] = useState(0);
  const [validating, setValidating] = useState(false);
  const [apiUrl, setApiUrl] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [profile, setProfile] = useState(null);

  const currentAnalyticsServiceIntegration = queryClient
    .getQueryData([SCOPE_SERVICE_INTEGRATIONS.GET_SERVICE_INTEGRATIONS, workspaceId, LOCATION.ROOT])
    ?.find((service) => service.type === TYPE_ANALYTICS);

  const currentService = Object.values(SERVICES_LIST).find((service) =>
    service.values.includes(currentAnalyticsServiceIntegration?.name)
  );

  const deleteServiceIntegration = useDeleteServiceIntegration();
  const postServiceIntegration = usePostServiceIntegration({
    onError: () => {
      setValidating(false);
    },
    onMutate: async () => {
      setValidating(true);

      if (currentAnalyticsServiceIntegration) {
        await deleteServiceIntegration.mutateAsync(currentAnalyticsServiceIntegration.id);
      }
    },
    onSuccess: async () => {
      await queryClient.refetchQueries({
        active: true,
        exact: true,
        fetching: false,
        queryKey: [SCOPE_SERVICE_INTEGRATIONS.GET_SERVICE_INTEGRATIONS, workspaceId, LOCATION.ROOT],
      });
      setValidating(false);
      handleGoToServiceIntegration();
    },
  });
  const postMatomoSites = usePostMatomoSites({ onError: () => null });

  function handleGoToServiceIntegration() {
    navigate(`/o/${organizationId}/w/${workspaceId}/settings/integrations`);
  }

  function handlePrevious() {
    setStep(step - 1);
    setProfile(null);
  }

  async function handleNext() {
    setStep(step + 1);
  }

  async function handleValidate() {
    await postServiceIntegration.mutateAsync({
      data: {
        api_key: apiKey,
        api_url: apiUrl,
        id_site: profile.value,
        web_property_name: profile.name,
      },
      name: MATOMO_VALUE,
      type: SERVICES_LIST.MATOMO.type,
    });
  }

  function getStep(index) {
    const steps = [
      {
        step: t('service-integration:matomo.steps.step1.step'),
        subTitle: t('service-integration:matomo.steps.step1.subTitle'),
        title: t('service-integration:matomo.steps.step1.title'),
      },
      {
        step: t('service-integration:matomo.steps.step2.step'),
        subTitle: t('service-integration:matomo.steps.step2.subTitle'),
        title: t('service-integration:matomo.steps.step2.title'),
      },
    ];

    return steps[index] || {};
  }

  function isNextDisabled() {
    if (step === 0) {
      return !apiUrl || !apiKey;
    }

    if (step === 1) {
      return !profile?.value || validating;
    }

    return true;
  }

  async function handleGetFormatedData() {
    try {
      const { data } = await postMatomoSites.mutateAsync({ apiKey, apiUrl });

      return data.sites
        .filter((site) => site.name && site.idsite)
        .map((site) => ({ name: site.name, url: site.main_url, value: site.idsite }));
    } catch {
      return [];
    }
  }

  function renderStep() {
    if (step === 0) {
      return (
        <ApiInformations
          access={apiUrl}
          accessPlaceholder={t('service-integration:matomo.api-informations.placeholder-api-url')}
          accessTitle={t('service-integration:matomo.api-informations.sub-title-api-url')}
          handleConfirm={handleNext}
          keyPlaceholder={t('service-integration:matomo.api-informations.placeholder-api-key')}
          keyTitle={t('service-integration:matomo.api-informations.sub-title-api-key')}
          keyValue={apiKey}
          linkHref={t('common:links.help-connect-matomo')}
          linkText={t('service-integration:matomo.api-informations.help-center-text')}
          logo={
            <img
              alt={t('service-integration:matomo.alt-logo')}
              src={SERVICES_LIST.MATOMO.logo}
              style={SERVICES_LIST.MATOMO.logoStyle}
            />
          }
          setAccess={setApiUrl}
          setKey={setApiKey}
          title={t('service-integration:matomo.api-informations.title')}
          warningMessage={
            currentAnalyticsServiceIntegration &&
            t('service-integration:matomo.api-informations.warning', {
              analytic: currentService?.title,
            })
          }
        />
      );
    }
    if (step === 1) {
      return (
        <SelectService
          currentValue={profile}
          errorMessage={
            <Trans
              i18nKey={'service-integration:matomo.select-service.error-message'}
              values={{ url: apiUrl }}
            />
          }
          handleGetFormatedData={handleGetFormatedData}
          logo={
            <img
              alt={t('service-integration:matomo.alt-logo')}
              src={SERVICES_LIST.MATOMO.logo}
              style={SERVICES_LIST.MATOMO.logoStyle}
            />
          }
          setCurrentValue={setProfile}
          title={t('service-integration:matomo.select-service.title')}
        />
      );
    }
    return null;
  }

  return (
    <Stepper
      handleCancel={handleGoToServiceIntegration}
      handleNext={step === 0 ? handleNext : handleValidate}
      handlePrevious={handlePrevious}
      header={getStep(step)}
      isNextDisabled={isNextDisabled()}
      nextLabel={
        step === 0
          ? t('service-integration:button-label.next')
          : t('service-integration:button-label.activate')
      }
      previousDisabled={step === 0}
    >
      {renderStep()}
    </Stepper>
  );
}

export default MatomoServiceIntegration;
