import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import BrokenLaptop from '@/assets/images/broken-laptop.svg';
import { FlexGrowColumnCenteredContainer } from '@/components/_common';
import { LinkLikeButton } from '@/components/Button/Button';
import { ParagraphText } from '@/components/Text/Paragraph';
import { TitleTwo } from '@/components/Text/Title';
import { STATIC_HTML_CRAWL_ERROR } from '@/containers/Content/constants';
import PrimaryButton from '@/design-system/components/Button/PrimaryButton';
import { noop } from '@/utils/noop';

const LinkLikeBtn = styled(LinkLikeButton)`
  text-decoration: underline;
  padding: 0;
`;

const StyledTitleTwo = styled(TitleTwo)`
  margin-top: 30px;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  margin: 30px 0;
  font-weight: 500;
`;

function CrawlError({ secondaryAction = noop, type }) {
  const { t } = useTranslation();

  return (
    <Trans
      components={{
        container: <FlexGrowColumnCenteredContainer />,
        image: <img alt={t('components:content.crawl-error.image-alt')} src={BrokenLaptop} />,
        linkBtn: <LinkLikeBtn onClick={secondaryAction} />,
        pButton: (
          <StyledPrimaryButton
            as="a"
            href={t('common:links.help-semji-bot')}
            rel="noopener noreferrer"
            target="_blank"
            width="180px"
          />
        ),
        paragraph1: (
          <ParagraphText align="center" color="dark080" noPadding size="md" weight="medium" />
        ),
        paragraph2: (
          <ParagraphText align="center" color="dark080" noPadding size="default" weight="medium" />
        ),
        title: <StyledTitleTwo noMargin weight="400" />,
      }}
      i18nKey={'components:content.crawl-error.content'}
      values={{
        type: t(
          STATIC_HTML_CRAWL_ERROR === type
            ? 'components:content.crawl-error.type-html'
            : 'components:content.crawl-error.type-content'
        ),
      }}
    />
  );
}

export default CrawlError;
