import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { putUserData } from '@/apis/semji/api';
import InfoIcon from '@/components/icons/InfoIcon';
import { TabBodyContent, TabBodyTitle } from '@/components/Navigation/Tabs';
import { ParagraphText } from '@/components/Text/Paragraph';
import { TitleFour } from '@/components/Text/Title';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import useFaceIo from '@/hooks/useFaceIo';
import { setUserFacialId } from '@/store/actions/user';

import ChangePassword from './ChangePassword/ChangePassword';
import DeleteAccount from './DeleteAccount';
import ResetEmail from './ResetEmail';

export const Title = styled(TitleFour)`
  margin-top: 3rem;
  margin-bottom: 0.5rem;
`;

const InfoButton = styled(InfoIcon)`
  && {
    cursor: pointer;
    font-size: 0.7em;
    transition:
      opacity 400ms,
      color 400ms;
    color: ${(props) => props.theme.cssColors.dark060};
  }
  &:hover {
    color: ${(props) => props.theme.cssColors.dark080};
  }
`;

export const Tooltip = styled(TooltipComponent)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Flex = styled.div`
  display: flex;
  align-items: ${({ alignItems }) => alignItems};
  flex: ${({ flex }) => flex};
  gap: ${({ gap }) => gap};
  justify-self: ${({ justifySelf }) => justifySelf};
`;

function Security({ title }) {
  const { t } = useTranslation();
  const { isEnabled: isFaceIoEnabled, enrollNewUser, deleteFacialId } = useFaceIo();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { facialId } = user || {};

  async function handleChangeSwitchFaceIo(event) {
    if (event.target.checked) {
      enrollNewUser({
        onSuccess: async (data = {}) => {
          const { facialId } = data;

          if (facialId) {
            await putUserData(user.id, { facialId });
            dispatch(setUserFacialId(facialId));
          }
        },
        user,
      });
    } else {
      // Remove facial id from user entity.
      if (facialId) {
        deleteFacialId(facialId);
        await putUserData(user.id, { facialId: null });
        dispatch(setUserFacialId(null));
      }
    }
  }

  return (
    <TabBodyContent>
      <TabBodyTitle noMargin>{title}</TabBodyTitle>
      {isFaceIoEnabled && (
        <div>
          <Title noMargin weight="strong">
            {t('settings:workspace.facial-recognition.title')}
          </Title>
          <ParagraphText color="dark080" noPadding weight="medium">
            {t('settings:workspace.facial-recognition.infos')}
          </ParagraphText>
          <Flex alignItems="center">
            <FormControlLabel
              control={
                <Switch
                  checked={!!facialId}
                  inputProps={{
                    'aria-label': t('settings:workspace.facial-recognition.btn-enable'),
                  }}
                  name="checkboxFaceIo"
                  onChange={handleChangeSwitchFaceIo}
                />
              }
              label={t('settings:workspace.facial-recognition.btn-enable')}
            />
            <Tooltip title={t('settings:workspace.facial-recognition.infos')}>
              <InfoButton bordered />
            </Tooltip>
          </Flex>
        </div>
      )}
      <ResetEmail />
      <ChangePassword />
      <DeleteAccount />
    </TabBodyContent>
  );
}

export default Security;
