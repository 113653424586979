import ListItem from '@material-ui/core/ListItem';
import React from 'react';
import styled from 'styled-components/macro';

export function StyledListItem(props) {
  return <List {...props} />;
}

export const List = styled((props) => <ListItem classes={{ selected: 'selected' }} {...props} />)`
  color: ${(props) => props.theme.colors.greyOpaque};
  && {
    cursor: pointer;
    color: ${(props) => props.theme.colors.greyOpaque};
  }
  &&.selected {
    background-color: transparent;
    color: ${(props) => props.theme.colors.pink};
  }
  &&:hover {
    background-color: #848f991f;
  }
`;
