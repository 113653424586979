import React from 'react';

const NotAnalyzedIcon = (props) => (
  <svg
    {...props}
    fill="none"
    height="122"
    viewBox="0 0 173 122"
    width="173"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34.6176 63.8865H23.9371C22.3351 63.8865 21 62.5514 21 60.9494C21 59.3473 22.3351 58.0122 23.9371 58.0122H34.6176C36.2197 58.0122 37.5547 59.3473 37.5547 60.9494C37.6882 62.5514 36.3532 63.8865 34.6176 63.8865Z"
      fill="#E1E4E6"
    />
    <path
      d="M44.6175 29.5754H33.9371C32.335 29.5754 31 28.2403 31 26.6383C31 25.0362 32.335 23.7012 33.9371 23.7012H44.6175C46.2196 23.7012 47.5547 25.0362 47.5547 26.6383C47.5547 28.2403 46.2196 29.5754 44.6175 29.5754Z"
      fill="#E1E4E6"
    />
    <path
      d="M34.9288 45.0627H19.0416C17.4396 45.0627 16.1045 43.7277 16.1045 42.1256C16.1045 40.5235 17.4396 39.1885 19.0416 39.1885H34.9288C36.5309 39.1885 37.8659 40.5235 37.8659 42.1256C37.9994 43.7277 36.6644 45.0627 34.9288 45.0627Z"
      fill="#E1E4E6"
    />
    <path
      d="M6.62656 44.9285H3.82289C2.22082 44.9285 0.885742 43.5934 0.885742 41.9913C0.885742 40.3892 2.22082 39.0542 3.82289 39.0542H6.62656C8.22863 39.0542 9.56363 40.3892 9.56363 41.9913C9.56363 43.5934 8.22863 44.9285 6.62656 44.9285Z"
      fill="#E1E4E6"
    />
    <path
      d="M170.508 78.4384H158.897C157.696 78.4384 156.695 77.2247 156.695 75.7682C156.695 74.3118 157.696 73.0981 158.897 73.0981H170.508C171.709 73.0981 172.71 74.3118 172.71 75.7682C172.81 77.2247 171.709 78.4384 170.508 78.4384Z"
      fill="#E1E4E6"
    />
    <path
      d="M145.585 95.3402H139.766C137.712 95.3402 136 94.1265 136 92.6701C136 91.2137 137.712 90 139.766 90H145.585C147.639 90 149.351 91.2137 149.351 92.6701C149.351 94.1265 147.639 95.3402 145.585 95.3402Z"
      fill="#E1E4E6"
    />
    <path
      d="M158.348 58.9465H149.937C148.335 58.9465 147 57.6115 147 56.0094C147 54.4073 148.335 53.0723 149.937 53.0723H158.348C159.95 53.0723 161.285 54.4073 161.285 56.0094C161.285 57.6115 159.95 58.9465 158.348 58.9465Z"
      fill="#E1E4E6"
    />
    <path
      d="M148.726 78.9724H145.976C144.541 78.9724 143.346 77.6373 143.346 76.0352C143.346 74.4332 144.541 73.0981 145.976 73.0981H148.726C150.161 73.0981 151.356 74.4332 151.356 76.0352C151.356 77.5038 150.161 78.9724 148.726 78.9724Z"
      fill="#E1E4E6"
    />
    <path
      d="M143.066 116.411C143.066 119.081 119.97 121.35 91.5332 121.35C63.0965 121.35 40 119.214 40 116.411C40 113.74 63.0965 114.007 91.5332 114.007C119.97 114.007 143.066 113.74 143.066 116.411Z"
      fill="#E9E9EB"
    />
    <g clip-path="url(#clip0)">
      <path
        d="M148.014 57.3213C148.014 88.5094 122.881 113.783 91.8903 113.783C60.8995 113.783 35.767 88.5094 35.767 57.3213C35.767 26.1331 60.8995 0.859737 91.8903 0.859737C122.881 0.859737 148.014 26.1331 148.014 57.3213Z"
        fill="white"
        stroke="#51525E"
        strokeWidth="1.90307"
      />
      <path
        d="M91.8916 106.448C64.5729 106.448 42.4267 84.3016 42.4267 56.9829C42.4267 29.6642 64.5729 7.51806 91.8916 7.51806C119.21 7.51807 141.356 29.6642 141.356 56.9829C141.356 84.3016 119.21 106.448 91.8916 106.448ZM91.8916 9.99131C65.9388 9.99131 44.8999 31.0302 44.8999 56.9829C44.8999 82.9357 65.9388 103.975 91.8916 103.975C117.844 103.975 138.883 82.9357 138.883 56.9829C138.883 31.0302 117.844 9.99131 91.8916 9.99131Z"
        fill="#E9E9EB"
      />
    </g>
    <circle cx="90.9273" cy="56.3292" fill="#FFCCD3" r="32.2847" />
    <path
      clip-rule="evenodd"
      d="M109.195 55.4112C109.195 55.2225 109.192 55.0345 109.186 54.8473L111.334 54.7608C111.341 54.9767 111.345 55.1936 111.345 55.4112C111.345 66.3404 102.451 75.234 91.522 75.234C89.3505 75.234 87.2592 74.8829 85.3022 74.2345L86.0179 72.2065C87.751 72.7762 89.6013 73.0845 91.522 73.0845C101.264 73.0845 109.195 65.1533 109.195 55.4112ZM74.2375 59.1057L72.0939 59.3579C71.8351 58.0821 71.6992 56.7622 71.6992 55.4112C71.6992 44.482 80.5928 35.5884 91.522 35.5884C94.8217 35.5884 97.9357 36.399 100.676 37.8316L99.8044 39.8028C97.3322 38.4852 94.5124 37.7379 91.522 37.7379C81.78 37.7379 73.8488 45.6692 73.8488 55.4112C73.8488 56.6778 73.9828 57.9138 74.2375 59.1057ZM82.0198 55.3347C82.0198 50.1398 86.2508 45.9088 91.4457 45.9088C96.6406 45.9088 100.872 50.1398 100.872 55.3347C100.872 60.5296 96.6406 64.7606 91.4457 64.7606C86.2508 64.7606 82.0198 60.5296 82.0198 55.3347ZM91.4457 43.7592C85.0637 43.7592 79.8703 48.9526 79.8703 55.3347C79.8703 61.7167 85.0637 66.9101 91.4457 66.9101C97.8278 66.9101 103.021 61.7167 103.021 55.3347C103.021 48.9526 97.8278 43.7592 91.4457 43.7592Z"
      fill="#FF4D64"
      fill-rule="evenodd"
    />
    <defs>
      <clipPath id="clip0">
        <rect fill="white" height="115" transform="translate(34.9082)" width="114.184" />
      </clipPath>
    </defs>
  </svg>
);

export default NotAnalyzedIcon;
