import { DOMMatcherUtils } from './helpers/domMatcher.utils';

function selectRange({
  startNode,
  endNode,
  startOffset,
  endOffset,
}: {
  startNode: HTMLElement;
  startOffset: number;
  endNode: HTMLElement;
  endOffset: number;
}): void {
  const selection = window.getSelection();
  const range = document.createRange();

  const startRangeOffset = DOMMatcherUtils.calculateRangeOffset(startNode, startOffset);
  range.setStart(startRangeOffset.node, startRangeOffset.offset);

  const endRangeOffset = DOMMatcherUtils.calculateRangeOffset(endNode, endOffset);
  range.setEnd(endRangeOffset.node, endRangeOffset.offset);

  selection?.removeAllRanges();
  selection?.addRange(range);
}

export default selectRange;
