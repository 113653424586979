import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';

import {
  ENUM_QUEUE_ITEM_STATUS,
  QueueItem,
  UseQueueParams,
} from '@/containers/Content/TinyMceComponents/Editor/hooks/useAiWriting/useQueue/useQueue.types';

export default function useQueue({ debug, cleanQueue, afterQueue, beforeQueue }: UseQueueParams) {
  const [active, setActive] = useState<boolean>(false);
  const [running, setRunning] = useState<boolean>(false);
  const [stop, setStop] = useState<boolean>(false);
  const [queue, setQueue] = useState<QueueItem[]>([]);

  function addItemToQueue(item: QueueItem) {
    setQueue((prevState) => [
      ...prevState,
      {
        ...item,
        id: nanoid(),
        isAwaited: item.isAwaited ?? true,
        status: item.status ?? ENUM_QUEUE_ITEM_STATUS.Pending,
      },
    ]);
  }

  function changeItemStatus(targetId: string, status: ENUM_QUEUE_ITEM_STATUS) {
    setQueue((prevState) => {
      const targetItem = prevState.find(({ id }) => id === targetId);
      if (targetItem) targetItem.status = status;
      return [...prevState];
    });
  }

  async function executeQueueItem(item: QueueItem): Promise<void> {
    if (item.id) {
      changeItemStatus(item.id, ENUM_QUEUE_ITEM_STATUS.Resolving);
      if (item?.beforeFn) item.beforeFn();
      return await item
        .fn()
        .then(() => {
          if (item.afterFn) item.afterFn();
          changeItemStatus(item.id as string, ENUM_QUEUE_ITEM_STATUS.Success);
        })
        .catch((e: any) => {
          changeItemStatus(item.id as string, ENUM_QUEUE_ITEM_STATUS.Failed);
          // eslint-disable-next-line no-console
          console.error(e);
        });
    }
  }

  async function runQueue(): Promise<void> {
    const filteredQueue = queue.filter(({ status }) => status === ENUM_QUEUE_ITEM_STATUS.Pending);
    const item = filteredQueue?.[0];
    if (item) {
      await executeQueueItem(item);
    }
  }

  function startQueue() {
    setActive(true);
  }

  function stopQueue() {
    setStop(true);
  }

  function resetQueue() {
    setQueue([]);
  }

  useEffect(() => {
    // Debug
    if (debug) {
      // eslint-disable-next-line no-console
      console.log({ queue });
    }

    if (stop) {
      resetQueue();
      setRunning(false);
      setStop(false);
      return;
    }
    // Run queue
    if (queue.length > 0 && active) {
      setRunning(true);
      setActive(false);
      if (beforeQueue) beforeQueue();
      runQueue();
    }

    if (
      running &&
      !stop &&
      queue.length > 0 &&
      queue.find((queueItem) => queueItem.status === ENUM_QUEUE_ITEM_STATUS.Pending) &&
      !queue.find((queueItem) => queueItem.status === ENUM_QUEUE_ITEM_STATUS.Resolving)
    ) {
      runQueue();
    }

    if (
      running &&
      queue.every((queueItem) => queueItem.status === ENUM_QUEUE_ITEM_STATUS.Success)
    ) {
      setRunning(false);
      if (afterQueue) afterQueue();
      if (cleanQueue) resetQueue();
    }
  }, [queue, active, stop, running]);

  return {
    queue,
    addItemToQueue,
    loading: running || active,
    resetQueue,
    startQueue,
    stopQueue,
  };
}
