import './MultiSelect.scss';

import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ENUM_BUTTON_VARIANTS } from 'semji-core/components/Button';
import { Dropdown } from 'semji-core/components/Dropdown';

import { ENUM_FILTER_TYPE, FilterAnchor } from '@/components/DynamicFilters';
import SelectItem from '@/components/DynamicFilters/MultiSelect/MultiSelectItem';
import ScrollBar from '@/components/Scrollbar';

import { MultiSelectProps, MultiSelectsType } from './MultiSelect.types';

export default function MultiSelect({
  icon,
  options,
  onValidate,
  name,
  id,
  disabled = false,
}: MultiSelectProps) {
  const { t } = useTranslation();

  const defaultFilers = options.reduce(
    (acc, option) => ({ ...acc, [option.value]: option.selected }),
    {}
  );

  const [selects, setSelects] = useState<MultiSelectsType>(defaultFilers);

  const isCurrentAllSelected = options.every((option) => selects[option.value]);
  const hasCurrentUnselected = options.some((option) => !selects[option.value]);
  const hasCurrentSelected = options.some((option) => selects[option.value]);

  const dropDrownActionsRef = useRef({
    handleClose: () => null,
    handleOpen: () => null,
  });

  function handleDropDrownInteract() {
    if (disabled) return;

    setSelects(defaultFilers);
  }

  function handleItemChange(select: string, value: boolean): void {
    if (disabled) return;

    setSelects((prevSelect) => ({ ...prevSelect, [select]: value }));
  }

  function handleValidate(): void {
    if (disabled) return;

    const selectedSelects = Object.keys(selects).filter((key) => selects[key]);
    onValidate(selectedSelects);
    dropDrownActionsRef.current.handleClose();
  }

  function handleSelectAll() {
    if (disabled) return;

    if (isCurrentAllSelected) {
      setSelects(options.reduce((acc, option) => ({ ...acc, [option.value]: false }), {}));
      return;
    }
    setSelects(options.reduce((acc, option) => ({ ...acc, [option.value]: true }), {}));
  }

  return (
    <Dropdown
      actionsRef={dropDrownActionsRef}
      anchorElement={
        <FilterAnchor
          disabled={disabled}
          icon={icon}
          id={id}
          name={name}
          options={options}
          type={ENUM_FILTER_TYPE.MULTI_SELECT}
          onValidate={onValidate}
        />
      }
      containerClassName="competitors-multi-select"
      disabled={disabled}
      isFrame={false}
      offsetValue={10}
      placement={'bottom-start'}
      querySelectorListener="#root"
      width="240px"
      onClose={handleDropDrownInteract}
      onOpen={handleDropDrownInteract}
    >
      <div className="competitors-multi-select__content">
        <SelectItem
          checked={isCurrentAllSelected}
          hasCurrentSelected={hasCurrentSelected}
          indeterminate={hasCurrentUnselected && hasCurrentSelected}
          label={t('competitors:filters.all')}
          onClick={handleSelectAll}
        />
        <div className="competitors-multi-select__content__list__divider" />
        <div
          className="competitors-multi-select__content__list"
          style={{ height: Math.min(options.length * 40, 280) }}
        >
          <ScrollBar autoHide={false} weight="4px">
            {options.map((option, index) => (
              <SelectItem
                key={`select-${option.value}-options-${index}`}
                checked={selects[option.value]}
                label={option.label}
                onClick={() => handleItemChange(option.value, !selects[option.value])}
              />
            ))}
          </ScrollBar>
        </div>
        <div className="competitors-multi-select__content__footer">
          <Button
            className="competitors-multi-select__content__footer__validate"
            variant={ENUM_BUTTON_VARIANTS.Text}
            onClick={handleValidate}
          >
            {t('competitors:filters.validate')}
          </Button>
        </div>
      </div>
    </Dropdown>
  );
}
