import styled from 'styled-components/macro';

import FavIcon from './FavIcon';

const Label = styled.div`
  && {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-start;
    padding-left: 1em;
  }
`;

const LabelName = styled.span`
  && {
    max-width: 175px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
  }
`;

function LabelledFavIcon({ url, label, disableClick, withoutFavIcon }) {
  return (
    <Label>
      {!withoutFavIcon && <FavIcon disableClick={disableClick} url={url} />}
      <LabelName title={label}>{label}</LabelName>
    </Label>
  );
}

export default LabelledFavIcon;
