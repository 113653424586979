import i18next from 'i18next';

import { FilterOption } from '@/components/DynamicFilters';

export const FUNNEL_MISC = 'misc';
export const FUNNEL_LOYALTY = 'loyalty';
export const FUNNEL_CONVERSION = 'conversion';
export const FUNNEL_CONSIDERATION = 'consideration';
export const FUNNEL_AWARENESS = 'awareness';

export const FUNNELS_TYPES = [
  FUNNEL_MISC,
  FUNNEL_LOYALTY,
  FUNNEL_CONVERSION,
  FUNNEL_CONSIDERATION,
  FUNNEL_AWARENESS,
];

export const getFunnelOptions = (filter?: string[]): FilterOption[] =>
  FUNNELS_TYPES.map((option) => ({
    id: option,
    label: i18next.t(`competitors:reports.labels.${option}`),
    selected: filter?.includes(option),
    value: option,
  }));
