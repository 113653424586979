import { Input } from '@material-ui/core';
import { useState } from 'react';
import { DialogModal } from 'semji-core/components/DialogModal';
import styled from 'styled-components/macro';

import WarningMessage from '@/components/Message/WarningMessage';
import { ParagraphText } from '@/components/Text/Paragraph';
import { TitleTwo } from '@/components/Text/Title';

const StyledInput = styled(Input)`
  && {
    padding: 5px;
  }
`;
const Wrapper = styled.div`
  gap: 20px;
  display: flex;
  flex-direction: column;
`;
function DeleteConfirmDialog({
  open,
  onClose,
  onConfirm,
  deleteLabel,
  title,
  cancelLabel,
  warningCondition,
  explanationText,
  confirmationText,
  warningMessage,
  deletePlaceholder,
}) {
  const [confirmText, setConfirmText] = useState('');

  const isButtonDisabled = confirmText !== deletePlaceholder;

  function handleClose() {
    onClose();
    setConfirmText('');
  }

  function handleConfirm() {
    onConfirm();
    handleClose();
  }

  function handleKeyDown(e) {
    if (e.keyCode === 13 && !isButtonDisabled) {
      e.preventDefault();
      handleConfirm();
    }
  }

  function handleChange(e) {
    setConfirmText(e.target.value);
  }

  return (
    <DialogModal
      cancelAction={handleClose}
      cancelLabel={cancelLabel}
      canClickOutsideToClose
      closable
      confirmAction={handleConfirm}
      confirmLabel={deleteLabel}
      isConfirmDisabled={isButtonDisabled}
      isOpen={open}
      title={<span>{title}</span>}
      onClose={onClose}
    >
      <Wrapper>
        {explanationText && <p>{explanationText}</p>}
        {warningCondition && warningMessage && (
          <WarningMessage size="default">{warningMessage}</WarningMessage>
        )}
        {confirmationText && <p>{confirmationText}</p>}
        <StyledInput
          fullWidth
          placeholder={deletePlaceholder}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
        />
      </Wrapper>
    </DialogModal>
  );
}

export default DeleteConfirmDialog;
