import { getUpdatedAccordionList } from '@/containers/Onboarding/constants';

export const selectWorkspaceOnboardingTasks = (state) => {
  const workspaceAchievements =
    state.workspaces.byId[state.defaultWorkspaceId]?.workspaceAchievements || [];

  const tasks = getUpdatedAccordionList(workspaceAchievements);
  const numberOfTask = tasks.reduce((prev, step) => prev + (step.children.length || 1), 0);
  const numberOfValidatedTask = tasks.reduce((prev, step) => {
    if (step.children.length) {
      return prev + step.children.filter((elem) => elem.validated).length;
    }

    return prev + step.validated;
  }, 0);

  return {
    percentage: (numberOfValidatedTask * 100) / numberOfTask,
    tasks,
  };
};
