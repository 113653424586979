import './PageDataLayout.scss';

import React, { useState } from 'react';
import { IconSquare } from 'semji-core/components/IconSquare';

import AngleDoubleLeftIcon from '@/components/icons/AngleDoubleLeftIcon';
import { PageDataLayoutContext } from '@/components/PageDataLayout/PageDataLayout.context';
import { PageDataLayoutProps } from '@/components/PageDataLayout/PageDataLayout.types';

import PageDataSideBar from './PageDataSideBar/PageDataSideBar';

function PageDataLayout({ children, sideBar, isContentPadded }: PageDataLayoutProps) {
  const [sideBarOpen, setSideBarOpen] = useState<boolean>(true);

  const toggleIcon = (
    <IconSquare
      className="page-data-icon-container__flip"
      size="md"
      variant="tertiary"
      onClick={handleToggleSideBar}
    >
      <AngleDoubleLeftIcon />
    </IconSquare>
  );

  function handleToggleSideBar() {
    setSideBarOpen(!sideBarOpen);
  }

  return (
    <PageDataLayoutContext.Provider
      value={{
        ToggleComponent: toggleIcon,
        isSidebarOpen: sideBarOpen,
        toggleSideBar: handleToggleSideBar,
      }}
    >
      <div className="page-data-layout">
        <div className="page-data-layout__container">
          {sideBarOpen && <PageDataSideBar {...sideBar} />}
          <div
            className={`page-data-layout__content ${isContentPadded && 'page-data-layout__content--padded'}`}
          >
            {children}
          </div>
        </div>
      </div>
    </PageDataLayoutContext.Provider>
  );
}

export default PageDataLayout;
