export const FACT_CHECK_DATA_ATTRIBUTE = 'data-semji-fact-check';
export const FACT_CHECK_DATA_ATTRIBUTE_CC = 'semjiFactCheck';
export const FACT_CHECK_CRITICALITY_DATA_ATTRIBUTE = 'data-semji-fact-check-criticality';
export const FACT_CHECK_HOVER_DATA_ATTRIBUTE = 'data-semji-fact-check-hovered';
export const FACT_CHECK_VALIDATED_DATA_ATTRIBUTE = 'data-semji-fact-check-validated';
export const FACT_CHECK_SELECTED_DATA_ATTRIBUTE = 'data-semji-fact-check-selected';

export const FACT_CHECK_HIGHLIGHT_CLASS = 'semji_annotation_fact_check_highlight';

export const FACT_CHECK_GENERATED_CONTENT_SELECTOR = 'generated-content';

export const FACT_CHECK_CARD_PREFIX_ID = 'fact-check-card-';
export const FACT_CHECK_POPOVER_CARD_PREFIX_ID = 'fact-check-popover-card-';
