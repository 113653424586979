import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import AnalyzeIcon from '@/components/icons/AnalyzeIcon';
import { SmallFlexTooltipComponent } from '@/components/Tooltip/Tooltip';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import { selectRemainingAnalysisUnit } from '@/store/selectors/selectRemainingAnalysisUnit';

const StartAnalysisIcon = styled(AnalyzeIcon)`
  && {
    cursor: pointer;
    font-size: ${(props) => props.theme.textCss.sizes.xl};
  }
`;

function StartAnalysisCell({
  focusTopKeyword,
  triggerUpdateAnalysis,
  organizationId,
  showInsufficientCreditsModalShow,
  setFocusKeywordAnalyzingName,
  ...props
}) {
  const { t } = useTranslation();
  const remainingAnalysisUnit = useSelector(selectRemainingAnalysisUnit);
  const [isClicked, setIsClicked] = useState(false);
  const { isFeatureEnabled: hasUnlimitedAnalysisCredits } = useOrganizationFeatureSet(
    'credits:analysis:has-unlimited-amount'
  );

  function startAnalysis() {
    if (isClicked) {
      return;
    }

    if (hasUnlimitedAnalysisCredits || remainingAnalysisUnit > 0) {
      triggerUpdateAnalysis(focusTopKeyword);
      setIsClicked(true);
    } else {
      showInsufficientCreditsModalShow();
    }
  }

  return (
    <SmallFlexTooltipComponent
      delay={50}
      placement="top"
      title={t('components:table.start-analysis-cell.tooltip-title')}
      {...props}
    >
      <StartAnalysisIcon disabled={isClicked} onClick={startAnalysis} />
    </SmallFlexTooltipComponent>
  );
}

export default styled(StartAnalysisCell)`
  display: none;
`;
