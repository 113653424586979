import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import LaunchTour from '@/components/Badge/LaunchTour';
import StepCheckbox from '@/components/Checkbox/StepCheckBox';
import TopPagesIcon from '@/components/icons/TopPagesIcon';
import UrlIcon from '@/components/icons/UrlIcon';
import MessageSearchConsole from '@/components/Message/MessageSearchConsole';
import FocusKeywordsSelection from '@/containers/Pages/steps/FocusKeywordsSelection';
import TopPagesList from '@/containers/Pages/steps/TopPagesList';
import UrlsList from '@/containers/Pages/steps/UrlsList';

const Container = styled.div`
  display: flex;
  gap: 50px;
`;

export const IMPORT_FROM_URLS = '/urls';
export const IMPORT_FROM_TOP_PAGES = '/top-pages';

export function getSteps(index = 0, importType = IMPORT_FROM_TOP_PAGES) {
  const steps = [
    {
      step: 'pages:step.step0.step',
      title: 'pages:step.step0.title',
      urlFragment: '',
    },
    {
      step: 'Step 1/2',
      subTitle: (
        <LaunchTour
          data-intercom-target={`product_tour_import_${
            importType === IMPORT_FROM_TOP_PAGES ? 'top_pages' : 'urls'
          }`}
          withoutTooltip
        />
      ),
      title:
        importType === IMPORT_FROM_TOP_PAGES
          ? `pages:step.step1.title_top_pages`
          : `pages:step.step1.title`,
      urlFragment: importType,
    },
    {
      step: 'pages:step.step2.step',
      title: 'pages:step.step2.title',
      urlFragment: `${importType}#keywords-selection`,
    },
  ];

  return steps[index] || {};
}

export function Step({
  activeStep,
  setUrls,
  urls,
  setSelectedTopPages,
  selectedTopPages,
  pagesToImport,
  setCanPagesBeImported,
  setFocusKeywordsAreLoading,
  setIsPagesListEmpty,
  setIsPagesListLoading,
  websiteUrl,
  workspaceCountryCode,
  workspaceCountryName,
  appliedFilters,
  setAppliedFilters,
  setTotalPagesNumber,
  setState,
  importing,
  importType,
  setImportType,
  hasSearchConsole,
  pages,
  setIsFetching,
}) {
  const { t } = useTranslation();

  function handleClickTopPages() {
    if (hasSearchConsole) {
      setImportType(IMPORT_FROM_TOP_PAGES);
    }
  }

  function handleClickUrls() {
    setImportType(IMPORT_FROM_URLS);
  }

  if (importType === IMPORT_FROM_TOP_PAGES && activeStep === 1) {
    return (
      <TopPagesList
        appliedFilters={appliedFilters}
        selectedPages={selectedTopPages}
        setAppliedFilters={setAppliedFilters}
        setSelectedPages={setSelectedTopPages}
        setTotalPagesNumber={setTotalPagesNumber}
        workspaceCountryCode={workspaceCountryCode}
      />
    );
  }
  if (importType === IMPORT_FROM_URLS && activeStep === 1) {
    return <UrlsList pages={pages} setUrls={setUrls} urls={urls} websiteUrl={websiteUrl} />;
  }
  if (activeStep === 2) {
    return (
      <FocusKeywordsSelection
        hasSearchConsole={hasSearchConsole}
        importing={importing}
        pagesToImport={pagesToImport}
        setCanPagesBeImported={setCanPagesBeImported}
        setFocusKeywordsAreLoading={setFocusKeywordsAreLoading}
        setIsFetching={setIsFetching}
        setIsPagesListEmpty={setIsPagesListEmpty}
        setIsPagesListLoading={setIsPagesListLoading}
        setState={setState}
        workspaceCountryName={workspaceCountryName}
      />
    );
  }

  return (
    <Container>
      <StepCheckbox
        checked={importType === IMPORT_FROM_TOP_PAGES}
        description={t('pages:step.top-pages.description')}
        disabled={!hasSearchConsole}
        disabledWarningMessage={
          <MessageSearchConsole message={t('pages:step.top-pages.warning-message')} />
        }
        title={t('pages:step.top-pages.title')}
        onClick={handleClickTopPages}
      >
        <TopPagesIcon />
      </StepCheckbox>
      <StepCheckbox
        checked={importType === IMPORT_FROM_URLS}
        description={t('pages:step.urls.description')}
        title={t('pages:step.urls.title')}
        onClick={handleClickUrls}
      >
        <UrlIcon />
      </StepCheckbox>
    </Container>
  );
}
