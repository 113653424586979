import SvgWrapper from './SvgWrapper';

const EmptyStateOutline = (props) => {
  return (
    <SvgWrapper {...props}>
      <rect
        fill="#E9E9EB"
        height="4.62651"
        rx="2.31325"
        transform="matrix(-1 0 0 1 173.747 33.1566)"
        width="6.16867"
      />
      <rect
        fill="#E9E9EB"
        height="4.62651"
        rx="2.31325"
        transform="matrix(-1 0 0 1 163.723 33.1566)"
        width="15.4217"
      />
      <rect
        fill="#E9E9EB"
        height="4.62651"
        rx="2.31325"
        transform="matrix(-1 0 0 1 157.554 21.5904)"
        width="15.4217"
      />
      <rect
        fill="#E9E9EB"
        height="4.62651"
        rx="2.31325"
        transform="matrix(-1 0 0 1 162.181 45.494)"
        width="12.3373"
      />
      <rect fill="#E9E9EB" height="4.62651" rx="2.31325" width="6.16867" x="1.02393" y="20.0482" />
      <rect fill="#E9E9EB" height="4.62651" rx="2.31325" width="14.6506" x="11.0483" y="20.0482" />
      <rect fill="#E9E9EB" height="4.62651" rx="2.31325" width="11.5663" x="16.4458" y="7.71085" />
      <path
        d="M173.747 59.5276C173.747 61.7637 134.884 63.5372 86.9999 63.5372C39.1156 63.5372 0.25293 61.7637 0.25293 59.5276C0.25293 57.2914 39.1156 57.5999 86.9999 57.5999C134.884 57.5999 173.747 57.2914 173.747 59.5276Z"
        fill="#BEBFC3"
        opacity="0.5"
      />
      <rect
        fill="white"
        height="15.2151"
        rx="1.67986"
        stroke="#7C7D86"
        strokeWidth="1.15663"
        width="109.494"
        x="23.9636"
        y="0.578313"
      />
      <rect fill="#E9E9EB" height="4.62651" rx="1.71062" width="84.0482" x="41.1206" y="6.16867" />
      <rect fill="#BEBFC3" height="4.62651" rx="1.71062" width="6.93976" x="30.3252" y="6.16867" />
      <rect
        fill="white"
        height="15.2151"
        rx="1.67986"
        stroke="#7C7D86"
        strokeWidth="1.15663"
        width="109.494"
        x="31.8674"
        y="21.4664"
      />
      <rect fill="#E9E9EB" height="4.62651" rx="1.71062" width="70.9398" x="48.8311" y="26.9879" />
      <rect fill="#BEBFC3" height="4.62651" rx="1.71062" width="6.93976" x="38.0361" y="26.9879" />
      <rect
        fill="white"
        height="15.2151"
        rx="1.67986"
        stroke="#7C7D86"
        strokeWidth="1.15663"
        width="110.059"
        x="39.2053"
        y="42.3546"
      />
      <rect fill="#E9E9EB" height="4.62651" rx="1.71062" width="79.4217" x="56.5425" y="47.8071" />
      <rect fill="#BEBFC3" height="4.62651" rx="1.71062" width="6.93976" x="45.7471" y="47.8071" />
      <rect fill="#E9E9EB" height="4.62651" rx="2.31325" width="11.5663" x="16.4458" y="31.6144" />
    </SvgWrapper>
  );
};
export default EmptyStateOutline;
