import startCase from 'lodash/startCase';
import React from 'react';
import styled from 'styled-components/macro';

const Badge = styled.span`
  font-weight: ${(props) => props.theme.textCss.weights.normal};
  font-size: ${(props) => props.theme.textCss.sizes.xs};
  border-radius: 25px;
  padding: 5px 15px;
`;

const SuccessBadge = styled(Badge)`
  color: ${(props) => props.theme.cssColors.green};
  background-color: ${(props) => props.theme.cssColors.green020};
`;
const ErrorBadge = styled(Badge)`
  color: ${(props) => props.theme.cssColors.red};
  background-color: ${(props) => props.theme.cssColors.red020};
`;
const WarningBadge = styled(Badge)`
  color: ${(props) => props.theme.cssColors.yellow};
  background-color: ${(props) => props.theme.cssColors.yellow020};
`;

const InvoiceBadge = ({ status = '' }) => {
  switch (status) {
    case 'not_paid':
    case 'voided':
      return <ErrorBadge>{startCase(status)}</ErrorBadge>;
    case 'payment_due':
    case 'pending':
    case 'posted':
      return <WarningBadge>{startCase(status)}</WarningBadge>;
    case 'paid':
    default:
      return <SuccessBadge>{startCase(status)}</SuccessBadge>;
  }
};

export default InvoiceBadge;
