import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import React, { lazy, Suspense } from 'react';
import { ThemeProvider } from 'styled-components/macro';

import AlgoliaStyle from '@/themes/AlgoliaStyle';
import GlobalStyle from '@/themes/globalStyle';
const PhoneInputStyle = lazy(
  () => import(/* webpackChunkName: "PhoneInputStyle" */ `@/themes/phoneInputStyle`)
);

function ThemeLoader({ theme, children }) {
  const muiTheme = createMuiTheme({
    palette: {
      action: {
        hoverOpacity: 0.06,
      },
      primary: { dark: theme.colors.primary, main: theme.colors.primary },
      secondary: { main: theme.colors.secondary },
      text: {
        primary: theme.text.colors.darker,
      },
    },
    typography: {
      fontFamily: theme.text.font,
    },
  });

  return (
    <>
      <GlobalStyle />
      <AlgoliaStyle />
      <MuiThemeProvider theme={muiTheme}>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </MuiThemeProvider>
      <Suspense fallback={<></>}>
        <PhoneInputStyle />
      </Suspense>
    </>
  );
}

export default ThemeLoader;
