export const URL_TYPE_DOMAIN = 'DOMAIN';
export const URL_TYPE_SUBDOMAIN = 'SUBDOMAIN';
export const URL_TYPE_SUBFOLDER = 'FOLDER';

export type UrlType =
  | typeof URL_TYPE_DOMAIN
  | typeof URL_TYPE_SUBDOMAIN
  | typeof URL_TYPE_SUBFOLDER;

export enum CompetitorWebsiteUrlType {
  DOMAIN = URL_TYPE_DOMAIN,
  SUBDOMAIN = URL_TYPE_SUBDOMAIN,
  SUBFOLDER = URL_TYPE_SUBFOLDER,
}
export interface CompetitorWebsite {
  id: string;
  url: string;
  urlType: UrlType;
  isSelfReference?: boolean;
}

export interface CompetitorWebsiteInfo {
  id: string;
  url: string;
  urlType: UrlType;
  nbPages: number;
  retrievalDate: string;
}

export interface CompetitorWebsiteInfoDto {
  url: string;
  urlType: UrlType;
  retrievalDate: string;
}

export interface CompetitorWebsiteMetric {
  id: string;
  traffic: number;
  unbrandedTraffic: number;
  nbPages: number;
  keyword?: string;
}
