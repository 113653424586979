import { useQuery } from '@tanstack/react-query';

import apiClient from '@/apis/semji/apiClient';
import { SCOPE_CONTENTS } from '@/apis/semji/constants';

import { UseGetContentVersionByIdProps } from './useGetVersionById.types';

export default function useGetContentVersionById({
  contentId,
  versionId,
  ...props
}: UseGetContentVersionByIdProps) {
  const { get } = apiClient;
  return useQuery({
    enabled: !!contentId || !!versionId,
    queryFn: async ({ signal }) => {
      const result = await get(`/contents/${contentId}/versions/${versionId}`, {
        signal,
      });

      return result.data;
    },
    queryKey: [SCOPE_CONTENTS.GET_CONTENT_VERSION_BY_ID, contentId, versionId],
    ...props,
  });
}
