import './DeleteDialog.scss';

import Input from '@material-ui/core/Input';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogModal, ENUM_DIALOG_MODAL_SIZE } from 'semji-core/components/DialogModal';

import { isEnterHotkey } from '@/utils/keyboard';

export function DeleteDialog({
  show,
  title,
  text,
  onCancel,
  onConfirm,
  confirmLabel,
  cancelLabel,
  loading,
  error,
  matchConfirmMessage,
}) {
  const { t } = useTranslation();

  const [confirmMessage, setConfirmMessage] = useState('');

  function onChange(event) {
    const value = event.target.value;
    setConfirmMessage(value);
  }

  function resetAndCloseModal() {
    setConfirmMessage('');
    onCancel();
  }

  function handleConfirm() {
    if (loading || matchConfirmMessage !== confirmMessage) return;

    setConfirmMessage('');
    onConfirm();
  }

  function onKeyDown(event) {
    if (isEnterHotkey(event)) {
      handleConfirm();
    }
  }

  return (
    <DialogModal
      ariaDescribedby="delete-dialog-description"
      ariaLabelledby="delete-dialog-title"
      cancelAction={resetAndCloseModal}
      cancelLabel={cancelLabel}
      canClickOutsideToClose
      closable
      confirmAction={handleConfirm}
      confirmLabel={confirmLabel}
      error={!!error && t('components:delete.error')}
      id="form-dialog-title"
      isConfirmDisabled={loading || matchConfirmMessage !== confirmMessage}
      isOpen={Boolean(show)}
      size={ENUM_DIALOG_MODAL_SIZE.Large}
      title={<span id="delete-dialog-title">{title}</span>}
      onClose={resetAndCloseModal}
    >
      <>
        {text}
        <Input
          className="delete-dialog-input"
          fullWidth
          inputProps={{
            onKeyDown,
          }}
          margin="dense"
          placeholder={matchConfirmMessage}
          value={confirmMessage}
          onChange={onChange}
        />
      </>
    </DialogModal>
  );
}

DeleteDialog.propTypes = {
  cancelLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  matchConfirmMessage: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  show: PropTypes.bool.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  title: PropTypes.string,
};

DeleteDialog.defaultProps = {
  cancelLabel: 'Cancel',
  confirmLabel: 'Confirm',
};
