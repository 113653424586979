import './EditForm.scss';

import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import useGetBrandVoiceById from '@/apis/semji/brandVoices/useGetBrandVoiceById';
import usePutBrandVoice from '@/apis/semji/brandVoices/usePutBrandVoice';
import Form from '@/containers/BrandVoiceDeprecated/Form';
import SkeletonForm from '@/containers/BrandVoiceDeprecated/Form/SkeletonForm';

function CreateForm() {
  const { brandVoiceId } = useParams();

  const navigate = useNavigate();
  const { t } = useTranslation();

  function onSuccess() {
    navigate(-1);
  }

  const mutation = usePutBrandVoice({ onSuccess });
  const { data: brandVoice, isFetching } = useGetBrandVoiceById({ brandVoiceId });

  function onSubmit(data) {
    mutation.mutate({ brandVoiceId, content: data });
  }

  return (
    <div className="brand-voice-page-edit">
      <div className="brand-voice-page-edit__top">
        <h1 className="brand-voice-page-edit__title">{t('brand-voice:form.edit-brand-voice')}</h1>
        <p className="brand-voice-page-edit__desc">{t('brand-voice:form.desc')}</p>
      </div>
      {isFetching ? <SkeletonForm /> : <Form defaultValues={brandVoice} onSubmit={onSubmit} />}
    </div>
  );
}

export default CreateForm;
