import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import React, { Component } from 'react';
import styled, { css } from 'styled-components/macro';

export const VARIANT_FLOATING_BUTTON = 'floating-button';
export const VARIANT_CONTAINED_BUTTON = 'contained-button';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

class PukingSelect extends Component {
  componentDidMount() {
    const caretIcon = this.x.querySelectorAll('svg')[0];
    caretIcon.setAttribute('viewBox', '0 0 17 17');
    caretIcon.innerHTML =
      '<path d="M16.354 5.075l-7.855 7.854-7.853-7.854 0.707-0.707 7.145 7.146 7.148-7.147 0.708 0.708z" fill="#000000" />';
  }

  render() {
    const { variant, children, ...otherProps } = this.props;

    return (
      <div ref={(x) => (this.x = x)} style={{ display: 'flex' }}>
        <Select input={<Input />} MenuProps={MenuProps} {...otherProps}>
          {children}
        </Select>
      </div>
    );
  }
}

export default styled((props) => (
  <PukingSelect
    classes={{
      icon: 'icon',
      select: 'select',
    }}
    {...props}
  />
))`
  && {
    text-transform: capitalize;
    .icon {
      font-size: ${({ theme }) => theme.image.sizes.small};
      top: calc(50% - 6px);
      right: 9px;
      opacity: 0.6;
    }
    .select {
      color: ${({ theme }) => theme.text.colors.default};
      padding: 3px 30px 3px 9px;
      font-size: ${(props) => (props.size === 'huge' ? '1.3em' : props?.size ?? '0.7em')};
      &:focus {
        background: transparent;
      }
    }

    ${(props) => props.theme.mediaQueries.phone} {
      ${(props) =>
        [VARIANT_FLOATING_BUTTON].includes(props.variant) &&
        css`
          & {
            padding: 0.3em 0.4em;
          }
        `};
    }
    ${(props) => props.theme.mediaQueries.tablet} {
      ${(props) =>
        [VARIANT_FLOATING_BUTTON].includes(props.variant) &&
        css`
          & {
            padding: 0.3em 0.4em;
          }
        `};
    }
    ${(props) => props.theme.mediaQueries.desktop} {
      ${(props) =>
        [VARIANT_FLOATING_BUTTON].includes(props.variant) &&
        css`
          & {
            padding: 0.3em 0.4em;
          }
        `};
    }

    &:before {
      display: none;
    }
    &:after {
      display: none;
    }
    border-radius: ${(props) =>
      [VARIANT_CONTAINED_BUTTON, VARIANT_FLOATING_BUTTON].includes(props.variant) ? '3px' : null};
    box-shadow: ${(props) =>
      [VARIANT_FLOATING_BUTTON].includes(props.variant)
        ? `0 0 10px ${props.theme.colors.lightGrey}`
        : null};
  }
`;
