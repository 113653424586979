import './CreateNewDraft.scss';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Checkbox } from 'semji-core/components/Input/Checkbox';
import { Separator } from 'semji-core/components/Separator';
import styled from 'styled-components/macro';

import { postKeywordQuestions, postKeywordSuggestions } from '@/apis/semji/api';
import useGetCurrentWorkspaceAllFolders from '@/apis/semji/folders/useGetCurrentWorkspaceAllFolders';
import usePostFolder from '@/apis/semji/folders/usePostFolder';
import { TextButton } from '@/components/Button/Button';
import NewDraft from '@/components/CreateNewDraft/NewDraft';
import { HeaderBulkActions } from '@/components/Input/DraftsBuilder';
import { DEFAULT_FOLDER_GENERAL } from '@/components/Select/FolderSelect/constants';
import { DarkerText, DarkText } from '@/components/Text/Dark';
import useNullUser from '@/hooks/useNullUser';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import { getOrganizationById } from '@/store/reducers';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${(props) => props.height || '56px'};
  margin-left: ${(props) => props.marginLeft || 0};
`;

const StyledDarkerText = styled(({ pxSize, ...props }) => <DarkerText {...props} />)`
  && {
    width: ${(props) => (props.pxSize ? `${props.pxSize}px` : 'auto')};
    max-width: 700px;
    margin-right: ${({ marginRight }) => marginRight || 0};
    margin-left: ${({ marginLeft }) => marginLeft || 0};
    color: ${({ theme, color }) => theme.cssColors[color || 'dark060']};
    font-weight: ${({ weight }) => weight || 'normal'};
  }
`;

function Header({
  rows,
  filteredDrafts,
  setFilteredDrafts,
  getList,
  folders,
  users,
  statuses,
  handleNewFolderCreation,
  isFolderCreationDisabled,
}) {
  const { t } = useTranslation();

  function handleAllDraftFiltered() {
    if (filteredDrafts.length === rows.length) {
      setFilteredDrafts([]);
    } else {
      setFilteredDrafts(rows.map((row, index) => ({ id: index, ...row })));
    }
  }

  return (
    <div className="flex flex-col gap-5">
      <Row height="39px" marginLeft="10px">
        {filteredDrafts.length > 0 ? (
          <HeaderBulkActions
            filteredDrafts={filteredDrafts}
            folders={folders}
            getList={getList}
            handleNewFolderCreation={handleNewFolderCreation}
            isFolderCreationDisabled={isFolderCreationDisabled}
            rows={rows}
            setFilteredDrafts={setFilteredDrafts}
            statuses={statuses}
            users={users}
          />
        ) : (
          <DarkText>
            {t('components:dialog.bulk-analysis.drafts-text_interval', {
              count: rows.length,
              postProcess: 'interval',
            })}
          </DarkText>
        )}
      </Row>
      <div>
        <Separator />
        <Row>
          <Checkbox
            checked={filteredDrafts.length === rows.length}
            indeterminate={filteredDrafts.length > 0 && filteredDrafts.length < rows.length}
            name="all"
            onChange={handleAllDraftFiltered}
          />
          <StyledDarkerText medium pxSize={440} size="small">
            {t('components:create-new-draft.focus-keyword')}
          </StyledDarkerText>
          <div className="flex items-center gap-1 w-[325px]">
            <StyledDarkerText medium size="small">
              {`${t('components:create-new-draft.title')} `}
            </StyledDarkerText>
            <StyledDarkerText color="dark040" medium size="small" weight="400px">
              {`(${t('drafts:create-drafts.label-optional')})`}
            </StyledDarkerText>
          </div>
          <div className="flex items-center gap-1 w-[400px]">
            <StyledDarkerText medium size="small">
              {t('components:create-new-draft.assignation')}
            </StyledDarkerText>
            <StyledDarkerText color="dark040" medium size="small" weight="400px">
              {`(${t('drafts:create-drafts.label-optional')})`}
            </StyledDarkerText>
          </div>
        </Row>
        <Separator />
      </div>
    </div>
  );
}

function CreateNewDraft({
  assignationReadOnly,
  defaultUserId,
  getList,
  isLimitAchieved,
  automaticallyAddRow,
  loading,
  rows,
  statuses,
  users,
  workspaceCountryName,
  workspaceId,
  folderIdFromQuery,
  inputValidation,
  setInputValidation,
}) {
  const { t } = useTranslation();
  const nullUser = useNullUser();

  const [focusColumn, setFocusColumn] = useState(0);
  const [focusIndex, setFocusIndex] = useState(0);
  const [lastKnownSearch, setLastKnownSearch] = useState('');
  const [openedTab, setOpenedTab] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [filteredDrafts, setFilteredDrafts] = useState([]);

  const {
    data: folders,
    remove: removeFolderCache,
    refetch: refecthFolders,
  } = useGetCurrentWorkspaceAllFolders();

  const createNewFolder = usePostFolder({
    onSuccess: async () => {
      refecthFolders();
    },
  });

  const { organizationId } = useParams();

  const currentOrganization = useSelector((state) => getOrganizationById(state, organizationId));
  const { isFeatureEnabled: unlimitedFoldersFeatureSet } = useOrganizationFeatureSet(
    'organization:folders:has-unlimited-amount'
  );
  const { data: foldersLimit = 0 } = useOrganizationFeatureSet('organization:folders:amount');
  const isOrganizationFoldersLimit =
    currentOrganization.foldersCount >= foldersLimit && !unlimitedFoldersFeatureSet;

  // Clean up react-query folders cache.
  useEffect(() => {
    return () => {
      removeFolderCache();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getDefaultRow() {
    return {
      dueDate: null,
      focusKeyword: '',
      folderId: folderIdFromQuery || DEFAULT_FOLDER_GENERAL.id,
      rowLastKnownSearch: lastKnownSearch,
      statusId: statuses[0]?.id,
      title: '',
      userId: assignationReadOnly ? defaultUserId : nullUser.id,
    };
  }

  async function getKeywords(keyword) {
    const defaultSuggestion = { keyword, loading: true, relatedKeywordsSuggested: [] };
    const newSuggestionsList = [...suggestions, defaultSuggestion];

    try {
      setSuggestions(newSuggestionsList);
      const keywordSuggestions = await postKeywordSuggestions(workspaceId, keyword);
      newSuggestionsList.splice(
        newSuggestionsList.findIndex((v) => v.keyword === keyword),
        1,
        keywordSuggestions
      );
      setSuggestions([...newSuggestionsList]);
      return Promise.resolve();
    } catch (e) {
      newSuggestionsList.splice(
        newSuggestionsList.findIndex((v) => v.keyword === keyword),
        1,
        { ...defaultSuggestion, loading: false }
      );
      setSuggestions([...newSuggestionsList]);
      return Promise.reject(e);
    }
  }

  async function getQuestions(keyword) {
    const defaultSuggestion = { keyword, loading: true, relatedKeywordsSuggested: [] };
    const newQuestionsList = [...questions, defaultSuggestion];

    try {
      setQuestions(newQuestionsList);
      const keywordQuestions = await postKeywordQuestions(workspaceId, keyword);
      newQuestionsList.splice(
        newQuestionsList.findIndex((v) => v.keyword === keyword),
        1,
        keywordQuestions
      );
      setQuestions([...newQuestionsList]);
      return Promise.resolve();
    } catch (e) {
      newQuestionsList.splice(
        newQuestionsList.findIndex((v) => v.keyword === keyword),
        1,
        { ...defaultSuggestion, loading: false }
      );
      setQuestions([...newQuestionsList]);
      return Promise.reject(e);
    }
  }

  async function getSuggestionForKeyword(keyword) {
    if (
      keyword &&
      !suggestions.find((suggestion) => suggestion.keyword === keyword) &&
      !questions.find((question) => question.keyword === keyword)
    ) {
      await Promise.all([getKeywords(keyword), getQuestions(keyword)]);
    }
  }

  function handleChange({
    index,
    changingKey,
    unchangingKey,
    currentColumnIndex,
    event,
    disableDefaultFocus,
  }) {
    const newValue = event.target.value;
    let currentFocusIndex = index;
    const DEFAULT_ROW = getDefaultRow();
    const newRows = [...rows];

    if (newValue.indexOf('\n') > 0) {
      const rowsList = newValue.split('\n');
      const newRowsList = rowsList.map((newVal, localIndex) => {
        const rowElements = newVal.split('\t');

        return {
          [changingKey]: rowElements[0],
          dueDate: newRows[index + localIndex]?.dueDate || DEFAULT_ROW.dueDate,
          folderId: newRows[0]?.folderId || DEFAULT_FOLDER_GENERAL.id,
          rowLastKnownSearch: DEFAULT_ROW.rowLastKnownSearch,
          statusId: newRows[index + localIndex]?.statusId || DEFAULT_ROW.statusId,
          [unchangingKey]:
            (currentColumnIndex === 0 && rowElements[1]) ||
            newRows[index + localIndex]?.[unchangingKey] ||
            DEFAULT_ROW[unchangingKey],
          userId: newRows[index + localIndex]?.userId || DEFAULT_ROW.userId,
        };
      });
      newRows.splice(index, rowsList.length, ...newRowsList);
      currentFocusIndex = index + rowsList.length;
    } else {
      const rowElements = newValue.split('\t');
      newRows[index] = {
        [changingKey]: rowElements[0],
        dueDate: newRows[index]?.dueDate || DEFAULT_ROW.dueDate,
        folderId: newRows[0].folderId,
        rowLastKnownSearch: DEFAULT_ROW.rowLastKnownSearch,
        statusId: newRows[index]?.statusId || DEFAULT_ROW.statusId,
        [unchangingKey]:
          (currentColumnIndex === 0 && rowElements[1]) ||
          newRows[index][unchangingKey] ||
          DEFAULT_ROW[unchangingKey],
        userId: newRows[index]?.userId || DEFAULT_ROW.userId,
      };
    }

    if (
      automaticallyAddRow &&
      (newRows[newRows.length - 1][changingKey] !== DEFAULT_ROW[changingKey] ||
        newRows[newRows.length - 1][unchangingKey] !== DEFAULT_ROW[unchangingKey]) &&
      !isLimitAchieved
    ) {
      newRows.push(DEFAULT_ROW);
    }

    // Disable auto focus on select
    // Enable Autofocus on the next row
    // so the suggestion list will be opened on the next row after selection
    if (!disableDefaultFocus) {
      setFocusColumn(currentColumnIndex);
      setFocusIndex(currentFocusIndex);
    } else {
      setFocusColumn(currentColumnIndex);
      setFocusIndex(currentFocusIndex + 1);
    }

    // return the new rows list
    getList(newRows);
  }

  function handleKeyDown(index, firstKey, secondKey, event) {
    const DEFAULT_ROW = getDefaultRow();
    const newRows = [...rows];

    if (
      event.key === 'Backspace' &&
      newRows[index][firstKey] === DEFAULT_ROW[firstKey] &&
      newRows[index][secondKey] === DEFAULT_ROW[secondKey]
    ) {
      newRows.splice(index, 1);
      if (
        newRows.length === 0 ||
        newRows[newRows.length - 1][firstKey] !== DEFAULT_ROW[firstKey] ||
        newRows[newRows.length - 1][secondKey] !== DEFAULT_ROW[secondKey] ||
        newRows[newRows.length - 1].userId !== DEFAULT_ROW.userId ||
        newRows[newRows.length - 1].statusId !== DEFAULT_ROW.statusId ||
        !!newRows[newRows.length - 1].dueDate
      ) {
        newRows.push(DEFAULT_ROW);
      }
      event.stopPropagation();
      event.preventDefault();
      setFocusIndex(Math.max(0, index - 1));
      // return the new rows list
      getList(newRows);
    }
    if (event.key === 'Enter') {
      event.stopPropagation();
      event.preventDefault();
      if (
        automaticallyAddRow &&
        newRows[index][firstKey] !== DEFAULT_ROW[firstKey] &&
        newRows[index][secondKey] !== DEFAULT_ROW[secondKey] &&
        newRows[index + 1][firstKey] !== DEFAULT_ROW[firstKey] &&
        newRows[index + 1][secondKey] !== DEFAULT_ROW[secondKey] &&
        !isLimitAchieved
      ) {
        newRows.splice(index + 1, 0, DEFAULT_ROW);
      }
      setFocusIndex(Math.min(newRows.length - 1, index + 1));
      // return the new props.rows list
      getList(newRows);
    }
    if (event.key === 'ArrowUp') {
      event.stopPropagation();
      event.preventDefault();
      setFocusIndex(Math.max(0, index - 1));
    }
    if (event.key === 'ArrowDown') {
      event.stopPropagation();
      event.preventDefault();
      setFocusIndex(Math.min(newRows.length - 1, index + 1));
    }
  }

  function handleUserChange(index, id) {
    const newRows = [...rows];
    newRows[index] = {
      ...newRows[index],
      userId: id,
    };

    getList(newRows);
  }

  function handleFolderChange(index, id) {
    const newRows = [...rows];
    newRows[index] = {
      ...newRows[index],
      folderId: id,
    };

    getList(newRows);
  }

  function handleNewFolderCreation(newFolder) {
    createNewFolder.mutate(newFolder);
  }

  function handleStatusChange(index, id) {
    const newRows = [...rows];
    newRows[index] = {
      ...newRows[index],
      statusId: id,
    };

    getList(newRows);
  }

  function handleDueDateChange(index, chosenDate) {
    const newRows = [...rows];
    newRows[index] = {
      ...newRows[index],
      dueDate: chosenDate,
    };

    getList(newRows);
  }

  function deleteRow(index) {
    if (rows.length > 1) {
      const newRows = [...rows];
      newRows.splice(index, 1);
      setFocusIndex(-1); // this is for not set auto focus on delet row
      getList(newRows);
    } else {
      const newRows = [{ ...getDefaultRow() }];
      getList(newRows);
      setFocusIndex(index - 1);
    }
  }

  function filterOutPreviousSelectedKeywordsFromList(suggestionsList, currentKeyword) {
    const focusKeywordsExceptCurrent = rows
      .map((keyword) => keyword.focusKeyword)
      .filter((keyword) => keyword !== currentKeyword);

    return suggestionsList.map((suggest) => ({
      ...suggest,
      relatedKeywordsSuggested: suggest.relatedKeywordsSuggested.filter(
        (relatedKeyword) => !focusKeywordsExceptCurrent.includes(relatedKeyword.keyword)
      ),
    }));
  }

  function filterSuggestionsKeywords(currentKeyword, suggestions) {
    return filterOutPreviousSelectedKeywordsFromList(suggestions, currentKeyword);
  }

  function filterQuestionsKeywords(currentKeyword, questions) {
    return filterOutPreviousSelectedKeywordsFromList(questions, currentKeyword);
  }

  function onKeywordFocus(e, index) {
    setFocusColumn(0);
    setFocusIndex(index);
    // so the cursor is set at the end of the text
    const val = e.target.value;
    e.target.value = '';
    e.target.value = val;
  }

  function onLastKnowSearchChange(s, index) {
    setLastKnownSearch(s);
    rows[index] = {
      ...rows[index],
      rowLastKnownSearch: s,
    };
    // return the new rows list
    getList([...rows]);
  }

  function onTitleFocus(e, index) {
    setFocusColumn(1);
    setFocusIndex(index);
    // so the cursor is set at the end of the text
    const val = e.target.value;
    e.target.value = '';
    e.target.value = val;
  }

  function handleOpenedTab(tabIndex) {
    setOpenedTab(tabIndex);
  }

  function addNewRow() {
    if (!loading) {
      getList([...rows, getDefaultRow()]);
    }
  }

  return (
    <>
      <div className="create-new-draft">
        <Header
          filteredDrafts={filteredDrafts}
          folders={folders}
          getList={getList}
          handleNewFolderCreation={handleNewFolderCreation}
          isFolderCreationDisabled={isOrganizationFoldersLimit}
          rows={rows}
          setFilteredDrafts={setFilteredDrafts}
          statuses={statuses}
          users={users}
        />
        <div className="create-new-draft__content">
          {rows.map((row, index) => (
            <NewDraft
              key={`smart_row_text_list_${index}`}
              assignationReadOnly={assignationReadOnly}
              createNewFolder={handleNewFolderCreation}
              deleteRow={deleteRow}
              filteredDrafts={filteredDrafts}
              filterQuestions={filterQuestionsKeywords}
              filterSuggestions={filterSuggestionsKeywords}
              folders={folders}
              getSuggestionForKeyword={getSuggestionForKeyword}
              handleChange={handleChange}
              handleDueDateChange={handleDueDateChange}
              handleFolderChange={handleFolderChange}
              handleKeyDown={handleKeyDown}
              handleStatusChange={handleStatusChange}
              handleUserChange={handleUserChange}
              index={index}
              inputValidation={inputValidation}
              isCreationDisabled={isOrganizationFoldersLimit}
              isKeywordFocused={focusIndex === index && focusColumn === 0}
              isTitleFocused={focusIndex === index && focusColumn === 1}
              lastKnownSearch={lastKnownSearch}
              openedTab={openedTab}
              questions={questions}
              row={row}
              setFilteredDrafts={setFilteredDrafts}
              setInputValidation={setInputValidation}
              setOpenedTab={handleOpenedTab}
              statuses={statuses}
              suggestions={suggestions}
              users={users}
              workspaceCountryName={workspaceCountryName}
              onKeywordFocus={onKeywordFocus}
              onLastKnowSearchChange={onLastKnowSearchChange}
              onTitleFocus={onTitleFocus}
            />
          ))}
        </div>
      </div>
      <TextButton color="primary" padding="0 0.25rem" weight="medium" onClick={addNewRow}>
        {t('components:create-new-draft.add-draft')}
      </TextButton>
    </>
  );
}

export default CreateNewDraft;
