import React from 'react';
import { useTranslation } from 'react-i18next';
import { OptionButton } from 'semji-core/components/OptionButton';
import { CodeIcon } from 'semji-core/icons/CodeIcon';
import { CommentIcon } from 'semji-core/icons/CommentIcon';
import { CompareIcon } from 'semji-core/icons/CompareIcon';
import { DashboardIcon } from 'semji-core/icons/DashboardIcon';
import { EyeIcon } from 'semji-core/icons/EyeIcon';
import { HighlighterIcon } from 'semji-core/icons/HighlighterIcon';
import { ReloadIcon } from 'semji-core/icons/ReloadIcon';
import { SettingsIcon } from 'semji-core/icons/SettingsIcon';
import { VersionHistoryIcon } from 'semji-core/icons/VersionHistoryIcon';

import LockRoundIcon from '@/components/icons/LockRoundIcon';
import { useDiffContent } from '@/containers/Content/TinyMceComponents/Editor/hooks/useEditorPopper/useDiffContent';
import useApiConfigurations from '@/hooks/useApiConfigurations';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import { DIFF_CONTENT_ENABLED } from '@/utils/configurations/constants';

export default function ScreenOptionButton({
  disabled,
  canShowVersionHistory = false,
  canSyncToWebsite = true,
  canAccessToFactChecking = false,
  handleClickSyncWithWebsite,
  handleClickShowSourceCode,
  handleClickShowVersionHistory,
  handleChangeIsFactCheckingEnabled,
  handleChangeIsContentHighlightEnabled,
  handleChangeIsOptimizationHighlightEnabled,
  handleChangeIsCommentEnabled,
  isFactCheckingEnabled,
  isContentHighlightEnabled,
  isOptimizationHighlightEnabled,
  isCommentEnabled,
}: {
  disabled?: boolean;
  canShowVersionHistory: boolean;
  canSyncToWebsite: boolean;
  canAccessToFactChecking: boolean;
  handleClickSyncWithWebsite: () => void;
  handleClickShowSourceCode: () => void;
  handleClickShowVersionHistory: () => void;
  handleChangeIsFactCheckingEnabled: () => void;
  handleChangeIsContentHighlightEnabled: () => void;
  handleChangeIsOptimizationHighlightEnabled: () => void;
  handleChangeIsCommentEnabled: () => void;
  isFactCheckingEnabled: boolean;
  isContentHighlightEnabled: boolean;
  isOptimizationHighlightEnabled: boolean;
  isCommentEnabled: boolean;
}) {
  const { t } = useTranslation();
  const { handleCompareClick } = useDiffContent();

  // TODO: replace with real value from hook use
  const canAccessToOptimizationHighLight = false;
  const canAccessToContentHighLight = false;

  const { isFeatureEnabled: hasAccessToContentVersionHistory } = useOrganizationFeatureSet(
    'contents:draft-versioning:is-enabled'
  );
  const diffContentEnabled = useApiConfigurations(DIFF_CONTENT_ENABLED);
  const isDiffContentEnabled = hasAccessToContentVersionHistory && diffContentEnabled;

  return (
    <OptionButton
      data-intercom-target="editor_screen_options_button"
      enabled={!disabled}
      icon={<SettingsIcon stroke="1" />}
      optionGroups={[
        {
          items: [
            canAccessToOptimizationHighLight && {
              checkable: true,
              checked: isOptimizationHighlightEnabled,
              description: t(
                'content:editor-components.toolbar.screen-options.optimization-highlight-desc'
              ),
              icon: <DashboardIcon />,
              onClick: handleChangeIsOptimizationHighlightEnabled,
              switchPosition: 'end',
              title: t('content:editor-components.toolbar.screen-options.optimization-highlight'),
            },
            canAccessToFactChecking && {
              checkable: true,
              checked: isFactCheckingEnabled,
              icon: <EyeIcon />,
              onClick: handleChangeIsFactCheckingEnabled,
              switchPosition: 'end',
              title: t('content:editor-components.toolbar.screen-options.ai-fact-checking'),
            },
            {
              checkable: true,
              checked: isCommentEnabled,
              icon: <CommentIcon />,
              onClick: handleChangeIsCommentEnabled,
              switchPosition: 'end',
              title: t('content:editor-components.toolbar.screen-options.comments'),
            },
            canAccessToContentHighLight && {
              checkable: true,
              checked: isContentHighlightEnabled,
              icon: <HighlighterIcon />,
              onClick: handleChangeIsContentHighlightEnabled,
              switchPosition: 'end',
              title: t('content:editor-components.toolbar.screen-options.content-highlight'),
            },
          ].filter(Boolean),
          separatorBetween: true,
          title: t('content:editor-components.toolbar.screen-options.screen-options'),
        },
        {
          items: [
            {
              closeOnClick: true,
              'data-intercom-target': 'editor_show_source_code',
              icon: <CodeIcon className="svg-no-fill" />,
              onClick: handleClickShowSourceCode,
              title: t('content:editor-components.toolbar.screen-options.show-source-code'),
            },
            canSyncToWebsite && {
              closeOnClick: true,
              icon: <ReloadIcon />,
              onClick: handleClickSyncWithWebsite,
              title: t('content:editor-components.toolbar.screen-options.sync-with-website'),
            },
            canShowVersionHistory && {
              closeOnClick: true,
              'data-intercom-target': 'editor_version_history_button',
              icon: <VersionHistoryIcon />,
              onClick: handleClickShowVersionHistory,
              title: t('content:editor-components.toolbar.screen-options.show-version-history'),
            },
            diffContentEnabled && {
              badgeIcon: !isDiffContentEnabled && <LockRoundIcon className="icon" />,
              closeOnClick: true,
              'data-intercom-target': 'editor_compare_button',
              disabled: !isDiffContentEnabled,
              icon: <CompareIcon />,
              onClick: handleCompareClick,
              title: t(`content:editor-components.toolbar.compare`),
              tooltip: !isDiffContentEnabled && t('components:badge.plan.available-on-higher-plan'),
            },
          ].filter(Boolean),
          title: t('content:editor-components.toolbar.screen-options.other-options'),
        },
      ]}
      width="310px"
    />
  );
}
