import { useQuery } from '@tanstack/react-query';

import apiClient from '@/apis/semji/apiClient';
import { SCOPE_CONFIGURATION } from '@/apis/semji/constants';

export default function useGetConfigurations(props: any): any {
  const { get } = apiClient;

  return useQuery({
    queryFn: async ({ signal }: { signal: any }) =>
      get(`/configurations`, {
        signal,
      }),
    queryKey: [SCOPE_CONFIGURATION.GET_CONFIGURATIONS],
    ...props,
  });
}
