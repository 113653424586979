import {
  deleteContent,
  exportContents,
  fetchContent,
  publishPageContent,
  refreshContentData,
} from '../apis/semji/api';

export default class Content {
  constructor(id, workspaceId) {
    this._id = id;
    this._workspaceId = workspaceId;
    this._content = null;
  }

  /** Cached call to API */
  get content() {
    return this._fetchContent();
  }

  fetchContent() {
    return fetchContent(this._id);
  }

  publish(url, date = new Date()) {
    return publishPageContent(this._id, url, date);
  }

  export(contentsIds, fileType) {
    return exportContents(contentsIds, fileType);
  }

  delete() {
    return deleteContent(this._id);
  }

  refreshData() {
    return refreshContentData(this._id);
  }

  /** Cached call to API */
  async _fetchContent() {
    if (this._content === null) {
      try {
        this._content = await fetchContent(this._id);
      } catch (e) {
        throw e;
      }
    }

    return this._content;
  }
}
