export default function UrlIcon() {
  return (
    <svg
      fill="none"
      height="101"
      viewBox="0 0 138 101"
      width="138"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M137.912 92.9781C137.912 97.424 107.221 101 69.4116 101C31.6021 101 0.911621 97.424 0.911621 92.9781C0.911621 88.5322 31.6021 89.0154 69.4116 89.0154C107.221 89.0154 137.912 88.5322 137.912 92.9781Z"
        fill="#FFEBED"
      />
      <path
        d="M95.5257 33.9783L91.0279 39L89.5278 37.5L94.0278 32.5C101.53 23.5217 99.2228 16.2145 94.0279 11C85.5279 4.1871 77.1127 5.49676 71.5278 11.5L55.5278 27.5C50.3329 32.7198 49.5279 43 56.693 49.5C61.8879 54.7145 72.8383 54.7198 78.0279 49.5L79.0278 51C75.0278 54 71.8072 55.5 67.0278 55.5C63.0278 55.5 58.2589 53.734 55.0279 51C48.5279 45.5 46.7604 33.3024 54.028 26L70.0279 10C77.3008 2.69226 88.8004 2.17305 96.0733 9.48081C103.346 16.7779 102.799 26.6758 95.5257 33.9783Z"
        fill="#FFCCD3"
      />
      <path
        d="M42.7002 60.4567L48.0278 55L49.5278 56.5L44.0278 62C36.7096 71.1289 39.3649 78.292 44.6648 83.3998C53.3015 90.0385 61.6883 88.558 67.15 82.4425L82.8215 66.1206C87.9092 60.7963 89.3236 50.853 82.0278 44.5C76.728 39.3922 66.1102 40.1758 61.0278 45.5L59.5278 44.1489C63.466 41.0682 66.7495 39.0971 71.5278 39C75.527 38.9187 79.5577 39.9476 82.8437 42.6153C89.4541 47.9821 91.4691 60.1412 84.3515 67.5898L68.6801 83.9117C61.5573 91.3658 50.0706 92.1186 42.6507 84.9602C35.231 77.8125 35.5773 67.9054 42.7002 60.4567Z"
        fill="#FFCCD3"
      />
      <path
        d="M92.2557 40.9511L97.6079 35.599C105.396 27.8162 105.396 15.1434 97.6079 7.3663C89.8193 -0.42223 77.158 -0.42223 69.3694 7.3663L52.326 24.4098C44.5432 32.1926 44.5432 44.8654 52.326 52.6482C56.2174 56.5396 61.3241 58.4853 66.4423 58.4853C71.5605 58.4853 76.6673 56.5453 80.5644 52.6482M57.2616 42.5773L58.0033 41.8412C65.7804 34.0698 78.4475 34.0527 86.2417 41.8527C94.0246 49.6355 94.0246 62.3082 86.2417 70.0911L68.9929 87.3457C65.0957 91.2371 59.989 93.1828 54.8708 93.1828C49.7526 93.1828 44.6459 91.2428 40.7487 87.3457C32.9659 79.5628 32.9659 66.8901 40.7487 59.1072L46.7856 53.0647"
        stroke="#51525E"
        strokeWidth="2"
      />
      <path
        d="M60.5965 49.4702L61.1544 48.9295C67.0042 43.2207 75.4636 42.2379 81.3262 47.9676C87.1803 53.6847 84.8563 62.1078 79.0022 67.8249L66.0279 80.4999C63.0966 83.3585 59.2554 84.7877 55.4056 84.7877C51.5558 84.7877 48.8498 84.5748 45.9185 81.712C40.0644 75.9949 41.133 67.6559 46.9871 61.9388L51.5278 57.5"
        stroke="#51525E"
        strokeWidth="2"
      />
      <path
        d="M87.5278 35.5L91.2863 31.8355C96.7558 26.5067 97.9974 18.3249 92.5279 13.0001C87.0585 7.66736 78.9973 7.66727 73.5278 13L58.4571 27.6635C52.9917 32.9922 52.1616 41.6692 57.627 46.998C60.3597 49.6624 63.9459 50.9946 67.5401 50.9946C71.1343 50.9946 74.7205 49.6663 77.4572 46.998"
        stroke="#51525E"
        strokeWidth="2"
      />
      <rect fill="#FFEBED" height="7" rx="3.5" width="15" x="26.9116" y="41" />
      <rect fill="#FFEBED" height="7" rx="3.5" width="13" x="34.9116" y="13" />
      <rect fill="#FFEBED" height="7" rx="3.5" width="8" x="10.9116" y="26" />
      <rect fill="#FFEBED" height="7" rx="3.5" width="20" x="22.9116" y="26" />
      <rect
        fill="#FFEBED"
        height="7"
        rx="3.5"
        transform="rotate(-180 112.912 59)"
        width="15"
        x="112.912"
        y="59"
      />
      <rect
        fill="#FFEBED"
        height="7"
        rx="3.5"
        transform="rotate(-180 95.9116 83)"
        width="13"
        x="95.9116"
        y="83"
      />
      <rect
        fill="#FFEBED"
        height="7"
        rx="3.5"
        transform="rotate(-180 127.912 72)"
        width="8"
        x="127.912"
        y="72"
      />
      <rect
        fill="#FFEBED"
        height="7"
        rx="3.5"
        transform="rotate(-180 115.912 72)"
        width="20"
        x="115.912"
        y="72"
      />
    </svg>
  );
}
