import './ChartStackedBarTooltip.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { ColoredDot } from 'semji-core/components/ColoredDot';
import {
  getRoundedNumberMetricWithSuffix,
  getRoundedPercentMetricObject,
} from 'semji-core/utils/numbers';

import { COMPETITORS_METRIC_TYPES } from '@/containers/Competitors/utils/constants';
import { DEFAULT_FAVICON } from '@/utils/constants';
import { getFaviconFromUrl } from '@/utils/favicon';

export function ChartStackedBarTooltip({
  title,
  name,
  metrics,
  percentValue,
  color = '#000',
  point,
  id,
}: {
  title: string;
  name: string;
  metrics: COMPETITORS_METRIC_TYPES[];
  percentValue: string;
  color: string;
  point: {
    y: number;
    custom: {
      [key in COMPETITORS_METRIC_TYPES]: number;
    };
  };
  id: string;
}) {
  const apiRootUrl = import.meta.env.VITE_REACT_APP_SEMJI_API_ROOT_URL;

  const { t } = useTranslation();

  const { value: percentageValue, suffix } = getRoundedPercentMetricObject({
    number: percentValue,
  });
  return (
    <div className="chart-stacked-bar-tooltip">
      <div className="chart-stacked-bar-tooltip__label">
        <img
          alt=""
          className="chart-stacked-bar-tooltip__label__icon"
          id={id}
          src={getFaviconFromUrl(title, apiRootUrl)}
          style={{
            backgroundImage: `url(${DEFAULT_FAVICON})`,
          }}
        />
        <span>{title}</span>
      </div>
      <div className="chart-stacked-bar-tooltip__point">
        <div className="chart-stacked-bar-tooltip__point__left">
          <div className="chart-stacked-bar-tooltip__point__left__top">
            {metrics.map((metric, key) => (
              <div key={key}>
                <span>{t(`competitors:reports.labels.${metric}`)}</span>
              </div>
            ))}
          </div>

          <div className="chart-stacked-bar-tooltip__point__left__bottom">
            <span className="chart-stacked-bar-tooltip__point__left__value">
              <ColoredDot color={color} size="xxs" />
              <span>{name}</span>
            </span>

            {metrics.map((metric, index) => (
              <div key={index} className="chart-stacked-bar-tooltip__point__left__bottom__metrics">
                {percentageValue && index === 0 && (
                  <span>
                    {percentageValue}% {suffix}&nbsp;
                  </span>
                )}
                <div
                  className={`chart-stacked-bar-tooltip__point__right ${percentageValue && index === 0 && 'chart-stacked-bar-tooltip__point__right--light'}`}
                >
                  <span>
                    {getRoundedNumberMetricWithSuffix({ number: point.custom[metric] ?? point.y })}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
