import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import CheckIcon from '@/components/icons/CheckIcon';
import CloseIcon from '@/components/icons/CloseIcon';
import PlusIcon from '@/components/icons/PlusIcon';
import RemoveDraftFromPlanningDialog from '@/components/Planning/RemoveDraftFromPlanningDialog';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import Button from '@/design-system/components/Button/Button';
import ContentService from '@/services/Content';
import { SECTIONS } from '@/utils/log/constants';
import { Log } from '@/utils/log/Log';

const MIN_WIDTH_BUTTON = '200px';

const Tooltip = styled(TooltipComponent)`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const CustomPrimaryButton = styled(Button).attrs(() => ({ minWidth: MIN_WIDTH_BUTTON }))`
  color: ${({ theme }) => theme.cssColors.primary};
  background: transparent;
  border: 1px solid ${({ theme }) => theme.cssColors.primary};
`;

const CustomSecondaryButton = styled(Button).attrs(() => ({ minWidth: MIN_WIDTH_BUTTON }))`
  color: ${({ theme }) => theme.cssColors.dark010};
  background: transparent;
  border: 1px solid ${({ theme }) => theme.cssColors.dark010};
`;

function PlanningTopbarButton({
  draftId,
  isAddingDraftToPlanning,
  isDraftStarted,
  isLoading,
  onAdd,
  onDelete,
}) {
  const { t } = useTranslation();

  const [isHovered, setIsHovered] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDeletingDraft, setIsDeletingDraft] = useState(false);

  const isPlanned = Boolean(draftId);

  async function onConfirmDeleteDraft() {
    const content = new ContentService(draftId);
    try {
      setIsDeletingDraft(true);
      await content.delete();
      onDelete();
    } catch (error) {
      Log.report({
        context: 'onConfirmDeleteDraft',
        error,
        extra: 'Draft delete action',
        section: SECTIONS.content.key,
      });
    } finally {
      setIsDeletingDraft(false);
      setIsDeleteDialogOpen(false);
    }
  }

  if (isLoading) {
    return null;
  }

  return (
    <>
      {isPlanned ? (
        isDeletingDraft ? (
          <CustomSecondaryButton disabled>
            {t('components:planning.planning-topbar-button.removing')}
          </CustomSecondaryButton>
        ) : (
          <CustomSecondaryButton
            disabled={isAddingDraftToPlanning}
            onClick={() => (isDraftStarted ? setIsDeleteDialogOpen(true) : onConfirmDeleteDraft())}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <Tooltip title={t('components:planning.planning-topbar-button.remove-from-planning')}>
              {isHovered ? <CloseIcon /> : <CheckIcon />}
              <span>{t('components:planning.planning-topbar-button.planned')}</span>
            </Tooltip>
          </CustomSecondaryButton>
        )
      ) : isAddingDraftToPlanning ? (
        <CustomSecondaryButton disabled>
          {t('components:planning.planning-topbar-button.adding')}
        </CustomSecondaryButton>
      ) : (
        <CustomPrimaryButton onClick={onAdd}>
          <PlusIcon />
          &nbsp;
          {t('components:planning.planning-topbar-button.add-to-planning')}
        </CustomPrimaryButton>
      )}
      <RemoveDraftFromPlanningDialog
        isLoading={isDeletingDraft}
        isOpen={isDeleteDialogOpen}
        onCancel={() => setIsDeleteDialogOpen(false)}
        onConfirm={onConfirmDeleteDraft}
      />
    </>
  );
}

PlanningTopbarButton.propTypes = {
  draftId: PropTypes.string,
  isDraftStarted: PropTypes.bool,
  isLoading: PropTypes.bool,
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
};

export default PlanningTopbarButton;
