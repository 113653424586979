import styled from 'styled-components/macro';

const BottomBar = styled.div`
  box-sizing: border-box;
  height: 62px;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.cssColors.white};
  margin-top: 10px;
  padding: 0 50px;
  box-shadow: 0px 3px 10px ${({ theme }) => theme.cssColors.dark060};
`;

export default BottomBar;
