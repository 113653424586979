import { nanoid } from 'nanoid';
import { useMemo } from 'react';
import styled from 'styled-components/macro';

import SvgWrapper from '@/components/icons/SvgWrapper';

const Svg = styled(SvgWrapper)`
  &&& {
    font-size: ${({ $fontSize }) => $fontSize};
  }
`;

function ArrowLeft({ fontSize, ...props }) {
  const maskId = useMemo(() => nanoid(), []);

  return (
    <Svg $fontSize={fontSize} viewBox="0 0 17 17" {...props}>
      <mask
        height="16"
        id={maskId}
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="15"
        x="1"
        y="1"
      >
        <path
          d="M16 9.47216H3.20697L9.35297 15.6182L8.64597 16.3252L1.29297 8.97216L8.64697 1.61816L9.35397 2.32516L3.20697 8.47216H16V9.47216Z"
          fill="black"
        />
      </mask>
      <g mask={`url(#${maskId})`}>
        <rect fill="#252736" height="20" width="20" x="-2" y="-0.5" />
      </g>
    </Svg>
  );
}

export default ArrowLeft;
