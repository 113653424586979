import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import SortDirection from 'react-virtualized/dist/es/Table/SortDirection';

import EmptyStateContentIdea from '@/assets/images/empty-state-content-idea.png';
import EmptyStateContentIdeaArchive from '@/assets/images/empty-state-content-idea-archive.png';
import EmptyStateContentIdeaFailed from '@/assets/images/empty-state-content-idea-failed.png';
import { DarkText, VerticalSeparator } from '@/components/ActionsBar/BulkActionsBar';
import BackRightIcon from '@/components/icons/BackRightIcon';
import BoxIcon from '@/components/icons/BoxIcon';
import FileExportOutline from '@/components/icons/FileExportOutline';
import PlusIcon from '@/components/icons/PlusIcon';
import {
  FAILED_STATUS,
  PENDING_STATUS,
  SUCCESS_STATUS,
} from '@/components/Pages/ContentIdeas/constant';
import {
  FALSE_VALUE,
  getColumns,
  RELATED_VALUE,
  TRUE_VALUE,
} from '@/components/Pages/ContentIdeas/Listing/constant';
import Table from '@/components/Table/Table';
import BulkActionButton from '@/components/TableBulkActions/BulkActionButton';
import BulkActionIcon from '@/components/TableBulkActions/BulkActionIcon';
import TableActionBar from '@/components/TableBulkActions/TableActionBar';
import { SmallFlexTooltipComponent } from '@/components/Tooltip/Tooltip';
import EmptyState from '@/containers/ContentIdeas/Listing/EmptyState';

function getContentIdeasPanelListFiltered({ contentIdeasList, archivedType, clusterId }) {
  let filteredRows = contentIdeasList;

  if (archivedType === TRUE_VALUE) {
    return filteredRows.filter((contentIdea) => contentIdea.isArchived);
  }

  if (clusterId !== FALSE_VALUE) {
    filteredRows = filteredRows.filter((contentIdea) =>
      contentIdea.contentIdeaClusterIds?.find((id) => id === clusterId)
    );
  }
  return filteredRows.filter((contentIdea) => !contentIdea.isArchived);
}

function List({
  contentIdeasClusters,
  contentIdeasList,
  contentIdeasLoading,
  handleSelectContentIdeas,
  selectedIds,
  handleOpenAddToPlanningModal,
  handleOpenStartOptimizingModal,
  status,
  contentIdeasKeyword,
  contentIdeaSearch,
  handleRemoveFromPlanningDialog,
  handleOpenEditDraftModal,
  handleSelectContentIdeasInline,
  pages,
  nbContentIdeasForCurrentKeywordType,
  handleOpenExportModal,
  archiveContentIdeas,
}) {
  const { workspaceId, organizationId } = useParams();
  const navigate = useNavigate();
  const [query, setQuery] = useSearchParams();
  const { t } = useTranslation();
  const userLanguageCode = useSelector((state) => state.user?.languageCode);

  const keywordType = query.get('keywordType');
  const archivedType = query.get('archivedType');
  const clusterId = query.get('clusterId');
  // Default table sort to apply on page refresh
  const sortType = query.get('sortType');
  const sortDirection = query.get('sortDirection');

  const [sortParams, setSortParams] = useState({ sortDirection, sortType });

  const contentIdeasListFiltered = getContentIdeasPanelListFiltered({
    archivedType,
    clusterId,
    contentIdeasList,
  });
  const filteredSelectedId = selectedIds.filter((id) =>
    contentIdeasListFiltered.find((contentIdea) => contentIdea.id === id)
  );

  function handleSelectedIdsAddToPlanning() {
    handleOpenAddToPlanningModal(filteredSelectedId);
  }

  function handleSelectedIdsExport() {
    handleOpenExportModal(filteredSelectedId);
  }

  function handleRedirect() {
    navigate(`/o/${organizationId}/w/${workspaceId}/content-ideas/`);
  }

  function handleArchive({ list, isArchived }) {
    archiveContentIdeas.mutate({
      contentIdeasIds: list?.length ? list : filteredSelectedId,
      isArchived:
        isArchived === true || isArchived === false ? isArchived : !(archivedType === TRUE_VALUE),
    });
  }

  if (status === FAILED_STATUS) {
    return (
      <EmptyState
        altImage={t(`content-ideas:listing.table.empty-state-failed-alt`)}
        buttonText={t(`content-ideas:listing.table.empty-state-failed-button`)}
        image={EmptyStateContentIdeaFailed}
        text={t(`content-ideas:listing.table.empty-state-failed-text`)}
        title={t(`content-ideas:listing.table.empty-state-failed-title`, {
          keyword: contentIdeasKeyword,
        })}
        onClick={handleRedirect}
      />
    );
  }

  if (
    status === SUCCESS_STATUS &&
    0 !== nbContentIdeasForCurrentKeywordType &&
    0 === contentIdeasListFiltered.length &&
    !contentIdeasLoading
  ) {
    if (archivedType === TRUE_VALUE) {
      return (
        <EmptyState
          altImage={t(`content-ideas:listing.table.empty-state-alt`)}
          image={EmptyStateContentIdeaArchive}
          text={t(`content-ideas:listing.table.empty-state-text-archive`)}
          title={t(`content-ideas:listing.table.empty-state-title-archive`, {
            keyword: contentIdeasKeyword,
          })}
        />
      );
    }
    return (
      <EmptyState
        altImage={t(`content-ideas:listing.table.empty-state-alt`)}
        image={EmptyStateContentIdea}
        text={t(`content-ideas:listing.table.empty-state-text-filtered-rows`)}
        title={t(`content-ideas:listing.table.empty-state-title-filtered-rows`, {
          keyword: contentIdeasKeyword,
        })}
      />
    );
  }

  if (
    status === SUCCESS_STATUS &&
    0 === nbContentIdeasForCurrentKeywordType &&
    !contentIdeasLoading
  ) {
    return (
      <EmptyState
        altImage={t(`content-ideas:listing.table.empty-state-alt`)}
        buttonText={t(`content-ideas:listing.table.empty-state-button`)}
        image={EmptyStateContentIdea}
        text={t(`content-ideas:listing.table.empty-state-text`)}
        title={t(`content-ideas:listing.table.empty-state-title`, { keyword: contentIdeasKeyword })}
        onClick={handleRedirect}
      />
    );
  }

  const isAddToPlanningButtonDisabled =
    !!filteredSelectedId.length &&
    !filteredSelectedId.some((id) =>
      contentIdeasListFiltered.find(
        (contentIdea) => contentIdea.id === id && !contentIdea.plannedContentIds.length
      )
    );

  const defaultSort = contentIdeaSearch?.isDeprecated
    ? keywordType === RELATED_VALUE
      ? 'title'
      : 'urlEstimatedTraffic'
    : 'similarityScore';

  const defaultSortDirection =
    keywordType === RELATED_VALUE && contentIdeaSearch?.isDeprecated
      ? SortDirection.ASC
      : SortDirection.DESC;

  const columns = getColumns(
    handleSelectContentIdeas,
    contentIdeasClusters,
    contentIdeasListFiltered,
    filteredSelectedId,
    handleOpenAddToPlanningModal,
    handleOpenStartOptimizingModal,
    handleRemoveFromPlanningDialog,
    handleOpenEditDraftModal,
    keywordType,
    pages,
    archiveContentIdeas,
    userLanguageCode,
    contentIdeaSearch?.isDeprecated,
    status,
    contentIdeasKeyword,
    setQueryParams
  );

  function setQueryParams(params) {
    // Do not update the query if the params are the same
    // TODO find a better way to avoid rerendering loop
    if (JSON.stringify(params) === JSON.stringify(sortParams)) return;
    for (const [key, value] of Object.entries(params)) {
      query.set(key, value);
    }
    setQuery(query);
    setSortParams(params);
  }

  return (
    <Table
      columns={columns}
      customHeader={
        <TableActionBar>
          <DarkText>
            {t('listing:bulk-actions.pages-selected_interval', {
              count: filteredSelectedId.length,
              postProcess: 'interval',
            })}
          </DarkText>

          <BulkActionButton
            disabled={isAddToPlanningButtonDisabled}
            onClick={handleSelectedIdsAddToPlanning}
          >
            <SmallFlexTooltipComponent
              title={t(
                isAddToPlanningButtonDisabled
                  ? t('content-ideas:listing.table.bulk-actions.already-in-planning')
                  : t('content-ideas:listing.table.bulk-actions.add-to-planning')
              )}
            >
              <PlusIcon /> {t('content-ideas:listing.table.bulk-actions.add-to-planning')}
            </SmallFlexTooltipComponent>
          </BulkActionButton>

          <BulkActionButton onClick={handleSelectedIdsExport}>
            <SmallFlexTooltipComponent title={t('content-ideas:listing.table.bulk-actions.export')}>
              <FileExportOutline /> {t('content-ideas:listing.table.bulk-actions.export')}
            </SmallFlexTooltipComponent>
          </BulkActionButton>

          <VerticalSeparator />

          <BulkActionIcon
            key={'bulk-actions-archive'}
            disabled={!filteredSelectedId.length}
            title={
              archivedType === TRUE_VALUE
                ? t('content-ideas:listing.archive.restore')
                : t('content-ideas:listing.archive.archive')
            }
            onClick={handleArchive}
          >
            {archivedType === TRUE_VALUE ? (
              <BackRightIcon style={{ fontSize: '1rem' }} />
            ) : (
              <BoxIcon style={{ fontSize: '1rem' }} />
            )}
          </BulkActionIcon>
        </TableActionBar>
      }
      defaultSort={sortType || defaultSort}
      defaultSortDirection={sortDirection || defaultSortDirection}
      disablePadding
      enableCustomHeader={filteredSelectedId.length > 0}
      mode="transparent"
      rowHeight={80}
      rows={
        contentIdeaSearch?.status === PENDING_STATUS
          ? [...contentIdeasListFiltered, ...Array(5).fill({ isSkeletonData: true })]
          : contentIdeasListFiltered
      }
      onRowClick={handleSelectContentIdeasInline}
    />
  );
}

export default memo(List);
