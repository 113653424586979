import Highcharts from 'highcharts';
import HighchartsExporting from 'highcharts/modules/exporting';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import HighchartsReact from 'highcharts-react-official';
import { useId, useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  getRoundedNumberMetricWithSuffix,
  getRoundedPercentMetricObject,
} from 'semji-core/utils/numbers';

import {
  AxisChartLabel,
  ENUM_AXIS_CHART_LABEL_ORIENTATION,
} from '@/containers/Competitors/Duel/Report/AxisChartLabel/AxisChartLabel';
import ChartUtils from '@/containers/Competitors/Duel/Report/Chart/Chart.utils';
import { ChartLegend } from '@/containers/Competitors/Duel/Report/ChartLegend';
import { ChartStackedBarTooltip } from '@/containers/Competitors/Duel/Report/ChartStackedBarTooltip';
import {
  ENUM_CHART_DATA_TYPE,
  ENUM_SERIES_CURSOR_TYPE,
} from '@/containers/Competitors/Duel/Report/constant';
import { sortBarChart } from '@/containers/Competitors/utils/utils';
import { selectUserConfiguration } from '@/store/selectors/selectUserConfiguration';
import defaultTheme from '@/themes/defaultTheme';

import { DEFAULT_CHART_OPTIONS, getContainerId } from './constants';

NoDataToDisplay(Highcharts);
HighchartsExporting(Highcharts);

export function HighChartsStackedBar({
  metricsSeries,
  selectedMetrics,
  categories,
  options = {},
  onPointClick = () => null,
  renderType = ENUM_CHART_DATA_TYPE.VALUE,
  isHorizontalChart = false,
  serieKey,
  chartRef,
  title,
  reference,
  customFilter = {},
  exportImage,
}) {
  const id = useId();
  const chartContainerId = getContainerId(id);
  const { t } = useTranslation();
  const apiRootUrl = import.meta.env.VITE_REACT_APP_SEMJI_API_ROOT_URL;
  const { showCompetitorDataLabels } = useSelector(selectUserConfiguration);

  // Handle the visible series
  const hiddenSeriesRef = useRef([]);
  const [hiddenSeries, setHiddenSeries] = useState(hiddenSeriesRef.current);
  const categoriesNames = categories.map((category) => category.name);

  // Sort series with categories in descending order
  const { categoriesData, seriesData } = sortBarChart(
    metricsSeries,
    categoriesNames,
    null,
    hiddenSeries
  );

  const OPTIONS = {
    ...DEFAULT_CHART_OPTIONS,
    chart: {
      ...DEFAULT_CHART_OPTIONS.chart,
      events: {
        render: function () {
          const series = this.series;
          series.forEach((serie) => {
            serie.data.forEach((point) => {
              !showCompetitorDataLabels && point?.dataLabel?.hide();
            });
          });
        },
      },
      height: options.height || 400,
      type: isHorizontalChart ? 'bar' : 'column',
    },
    exporting: ChartUtils.getExportingOptions({
      overrideOptions: {
        title: {
          align: 'left',
          text: title,
        },
      },
    }),
    lang: {
      noData: t('competitors:duel.report.no-data'),
    },
    legend: ChartUtils.getLegendOptions({
      overrideOptions: {
        labelFormatter: function () {
          return ReactDOMServer.renderToStaticMarkup(
            getChartLegend(this.name, this.color, this.visible)
          );
        },
        reversed: true,
      },
    }),
    plotOptions: {
      series: {
        cursor: onPointClick ? ENUM_SERIES_CURSOR_TYPE.POINTER : ENUM_SERIES_CURSOR_TYPE.UNDEFINED,
        dataLabels: {
          enabled: true,
          formatter: function () {
            if (renderType === ENUM_CHART_DATA_TYPE.PERCENTAGE) {
              const { value, suffix } = getRoundedPercentMetricObject({
                number: this.percentage ?? 0,
              });
              return `${value}${suffix}%`;
            }
            //format the number with suffix like 'K' or 'M'
            return getRoundedNumberMetricWithSuffix({ number: this.y });
          },
          style: {
            fontSize: defaultTheme.textCss.sizes.xs,
            fontWeight: defaultTheme.textCss.weights.bold,
          },
        },
        events: {
          click: (e) => onPointClick(e, serieKey, customFilter),
          legendItemClick: function () {
            const { name, visible } = this;
            handleLegendItemClick({ name, visible: !visible });
          },
          mouseOut: function () {
            this.data.forEach((point) => {
              !showCompetitorDataLabels && point?.dataLabel?.hide();
            });
          },
          mouseOver: function () {
            this.data.forEach((point) => {
              point?.dataLabel?.show();
            });
          },
        },
        maxPointWidth: 40,
        stacking: renderType === ENUM_CHART_DATA_TYPE.VALUE ? 'normal' : 'percent',
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            opacity: 0.3,
          },
        },
        ...options?.plotOptions?.series,
      },
    },
    tooltip: {
      backgroundColor: 'transparent',
      borderWidth: '0px',
      className: 'competitors-duel-report__chart__tooltip',
      distance: isHorizontalChart ? 25 : 16, // Waiting for the design to be validated
      formatter: function () {
        return ReactDOMServer.renderToStaticMarkup(getChartTooltip(this));
      },
      hideDelay: 0,
      outside: true,
      shadow: false,
      style: {
        color: defaultTheme.cssColors.dark080,
      },
      useHTML: true,
    },
    xAxis: ChartUtils.getXAxisOptions({
      overrideLabelOptions: {
        align: isHorizontalChart ? 'left' : 'center',
        autoRotation: [0], // Waiting for the design to be validated
        formatter: function () {
          const imgId = `chart-${id}-xaxis-${this.value}`;
          ChartUtils.addChartFaviconEventListeners(imgId);
          const retrievedValues = categories?.find((category) => category.name === this.value);

          return ReactDOMServer.renderToStaticMarkup(
            <AxisChartLabel
              apiRootUrl={apiRootUrl}
              domain={this.value}
              exportImage={exportImage}
              id={imgId}
              isReference={this.value === reference}
              orientation={
                isHorizontalChart
                  ? ENUM_AXIS_CHART_LABEL_ORIENTATION.HORIZONTAL
                  : ENUM_AXIS_CHART_LABEL_ORIENTATION.VERTICAL
              }
              url={retrievedValues?.url}
              urlType={retrievedValues?.urlType}
            />
          );
        },
        reserveSpace: true,
      },
      overrideOptions: {
        categories: categoriesData,

        ...options.xAxis,
      },
    }),
    yAxis: ChartUtils.getYAxisOptions({
      overrideLabelOptions: {
        formatter: function () {
          return renderType === ENUM_CHART_DATA_TYPE.PERCENTAGE
            ? `${this.value}%`
            : getRoundedNumberMetricWithSuffix({ number: this.value });
        },
      },
      overrideOptions: {
        max: renderType === ENUM_CHART_DATA_TYPE.PERCENTAGE ? 100 : null,
      },
    }),
  };

  // Used with ReactDOMServer to custom label
  function getChartLegend(name, color, isVisible) {
    return <ChartLegend color={color} isVisible={isVisible} name={name} />;
  }

  // Used with ReactDOMServer to custom chart tooltip
  function getChartTooltip({ series, color, x, y, percentage, point }) {
    const imgId = `chart-${id}-tooltip-${series.name}`;
    ChartUtils.addChartFaviconEventListeners(imgId);
    return (
      <ChartStackedBarTooltip
        color={color}
        id={imgId}
        metrics={selectedMetrics}
        name={series.name}
        percentValue={percentage}
        point={point}
        title={x}
      />
    );
  }

  function onClick(e) {
    e.stopPropagation();
  }

  function handleLegendItemClick({ name, visible }) {
    hiddenSeriesRef.current = visible
      ? hiddenSeriesRef.current.filter((item) => item !== name)
      : [...hiddenSeriesRef.current, name];

    setHiddenSeries(hiddenSeriesRef.current);
  }

  return (
    <div className="competitors-duel-report__chart">
      <div aria-hidden="true" id={chartContainerId} onClick={onClick}>
        <HighchartsReact
          ref={chartRef}
          highcharts={Highcharts}
          options={{
            ...OPTIONS,
            series: seriesData,
          }}
        />
      </div>
    </div>
  );
}
