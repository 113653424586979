import './DiffPercentage.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHover } from 'semji-core/hooks/useHover';
import { SortOrders } from 'semji-core/utils/sort';

import InfoAltIcon from '@/components/icons/InfoAltIcon';
import TriangleArrow from '@/components/icons/TriangleArrow';
import Tooltip from '@/components/Tooltip/Tooltip';
import { ReportMetricType } from '@/containers/Report/utils/types';
import { noop } from '@/utils/noop';

export const EVOLUTION = {
  NEGATIVE: 'negative',
  NEUTRAL: 'neutral',
  POSITIVE: 'positive',
};

const computeDiffPercentageColor = ({
  initialValue,
  evolutionRating,
}: {
  initialValue: number;
  evolutionRating: string;
}) => {
  const isPositive = Math.floor(initialValue) >= 0;
  if (
    (isPositive && 'positive' === evolutionRating) ||
    (!isPositive && 'negative' === evolutionRating)
  ) {
    return 'positive';
  }

  return 'negative';
};

export function computeDiffNoData(
  value: string | number | null | undefined,
  isValueUnranked: boolean,
  isInitialValueUnranked: boolean
) {
  return !value || (isInitialValueUnranked && isValueUnranked);
}

export function computeDiffIsNew(initialValue: string, isInitialValueUnranked: boolean) {
  return !initialValue || isInitialValueUnranked;
}

export function DiffPercentage({
  initialValue,
  value,
  suffix,
  evolutionRating,
  ...props
}: {
  initialValue: number;
  value: number;
  suffix?: string;
  evolutionRating: string;
  type?: string;
}) {
  const evolutionSign = Math.floor(initialValue) >= 0 ? '+' : '';

  if (value === Infinity) {
    return (
      <span
        {...props}
        className={`diff-percentage diff-percentage--${computeDiffPercentageColor({ evolutionRating, initialValue })} ${props.type === 'editor' && 'diff-percentage--editor'}`}
      >
        {evolutionSign}&infin;
      </span>
    );
  }

  if (value !== null) {
    return (
      <span
        {...props}
        className={`diff-percentage diff-percentage--${computeDiffPercentageColor({ evolutionRating, initialValue })} ${props.type === 'editor' && 'diff-percentage--editor'}`}
      >
        {evolutionSign + value + suffix}
      </span>
    );
  }

  return '-';
}

export function Diff({
  initialValue,
  value,
  suffix = '',
  isInitialValueUnranked,
  isValueUnranked,
  sortOrder = SortOrders.DESC,
  hoverElement = noop,
  ...props
}: {
  initialValue: number;
  value: number;
  suffix?: string;
  isInitialValueUnranked: boolean;
  isValueUnranked: boolean;
  sortOrder?: SortOrders;
  hoverElement?: () => JSX.Element;
}) {
  const [hoverRef, hovered] = useHover();
  const { t } = useTranslation();
  const firstValue = sortOrder === SortOrders.ASC ? value : initialValue;
  const secondValue = sortOrder === SortOrders.ASC ? initialValue : value;

  if (
    computeDiffNoData(secondValue, isValueUnranked, isInitialValueUnranked) ||
    computeDiffIsNew(firstValue, isInitialValueUnranked)
  ) {
    return '-';
  }

  const evolutionRating =
    secondValue > firstValue
      ? EVOLUTION.NEGATIVE
      : secondValue < firstValue
        ? EVOLUTION.POSITIVE
        : EVOLUTION.NEUTRAL;

  const diffValue = firstValue - secondValue;

  return (
    <div ref={hoverRef} className="diff-component">
      <Tooltip title={t('report:table.tooltips.starting-position', { count: initialValue })}>
        {evolutionRating !== 'neutral' && (
          <>
            <TriangleArrow
              className={`diff-component__triangle-arrow diff-component__triangle-arrow--${evolutionRating}`}
              evolutionRating={evolutionRating}
            />
          </>
        )}
        <span
          {...props}
          className={`diff-component__text diff-component__text--${evolutionRating}`}
        >
          {diffValue === 0 ? '-' : Math.abs(diffValue) + suffix}
        </span>
      </Tooltip>
      {hovered && hoverElement()}
    </div>
  );
}

export function EvolutionInfoTooltip() {
  const { t } = useTranslation();
  return (
    <Tooltip title={t('report:table.tooltips.evolution-info')}>
      <InfoAltIcon className="evolution-info-tooltip" />
    </Tooltip>
  );
}
