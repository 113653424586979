import './ReadTime.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { readTimePerMinute } from 'semji-core/utils/word';

import useCan from '@/hooks/useCan';

function ReadTime({
  count,
  selectionCount,
}: {
  count: number;
  selectionCount?: number;
}): JSX.Element | null {
  const { t } = useTranslation();
  const isAvailable = useCan({ perform: 'content:editor:read-time' });

  if (!isAvailable) {
    return null;
  }

  return (
    <div className="read-time">
      |{' '}
      {t('content:editor-components.read-time.total-read-time', {
        time: readTimePerMinute(count),
      })}
      {!!selectionCount && (
        <>
          &nbsp;
          {t('content:editor-components.read-time.selection-read-time', {
            time: readTimePerMinute(selectionCount),
          })}
        </>
      )}
    </div>
  );
}

export default ReadTime;
