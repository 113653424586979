import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { lazy, Suspense, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import useGetRegistrant from '@/apis/semji/registrants/useGetRegistrant';
import usePutRegistrant from '@/apis/semji/registrants/usePutRegistrant';
import { FlexGrowColumnCenteredContainer } from '@/components/_common';
import { Form, FormBox, Input } from '@/components/Box/Box';
import { ContainedButton } from '@/components/Button/Button';
import GoogleBlock from '@/components/GoogleLogin/Block';
import { USE } from '@/components/GoogleLogin/constants';
import GoogleLoginButton from '@/components/GoogleLogin/GoogleLoginButton';
import Separator from '@/components/GoogleLogin/Separator';
import { ONLY_LOGO_WHITE_BACKGROUND } from '@/components/Layout/Layout';
import { RelativeLoader } from '@/components/Loader/Loader';
import PasswordFieldSkeleton from '@/components/Password/PasswordFieldSkeleton';
import { SecondaryTitleOne } from '@/components/Text/Title';
import OopsScreen from '@/containers/Errors/OopsScreen';
import LayoutWrapper from '@/containers/Layout/Layout';
import useCan from '@/hooks/useCan';
import { fetchAuthenticatedUser } from '@/store/actionsCreator/user';
import {
  DEFAULT_PASSWORD_MIN_STRENGTH,
  DEFAULT_PASSWORD_THRESHOLD_LENGTH,
} from '@/utils/constants';

const PasswordField = lazy(
  () => import(/* webpackChunkName: "PasswordField" */ '@/components/Password/PasswordField')
);

const Container = styled(FlexGrowColumnCenteredContainer)`
  box-sizing: content-box;
  gap: 30px;

  ${({ theme }) => `@media ${theme.responsive.max.xxs}`} {
    padding: 15px;
    height: auto;
  }
`;

const Content = styled.div`
  width: 100%;
  max-width: 500px;
`;

const FormBoxStyled = styled(FormBox)`
  && {
    min-width: unset;
  }
`;

function Register() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { registrantToken } = useParams();
  const registrant = useGetRegistrant(registrantToken);

  const isSigninWithGoogleEnabled = useCan({ perform: 'sign-in:with-google' });

  const [state, setState] = useState({ firstName: '', lastName: '', password: '' });
  const [passwordStrength, setPasswordStrength] = useState(0);

  function onSuccess() {
    dispatch(fetchAuthenticatedUser());

    navigate('/', { replace: true });
  }

  const { isLoading, isError, mutate, error } = usePutRegistrant({
    email: registrant?.data?.email,
    firstName: state.firstName || registrant?.data?.firstName,
    languageCode: i18n.languages?.[0] || 'en',
    lastName: state.lastName || registrant?.data?.lastName,
    onSuccess,
    password: state.password,
    registrantToken,
    username: registrant?.data?.email,
  });

  function getError(status) {
    // eslint-disable-next-line no-console
    switch (status) {
      case 400:
      case 422:
        return t('create-account:register.errors.400');
      case 404:
        return t('create-account:register.errors.404');
      default:
        return t('create-account:register.errors.an-error-occurred');
    }
  }

  const isValidPassword = (value, strength) => {
    return (
      strength >= DEFAULT_PASSWORD_MIN_STRENGTH && value.length >= DEFAULT_PASSWORD_THRESHOLD_LENGTH
    );
  };

  function handleSubmit(event) {
    event.preventDefault();

    if (!isValidPassword(state.password, passwordStrength)) {
      return;
    }

    mutate();
  }

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  function onPasswordChanged(pwd) {
    setPasswordStrength(pwd.strength);
  }

  if (registrant.isLoading) {
    return (
      <LayoutWrapper menu={ONLY_LOGO_WHITE_BACKGROUND}>
        <Helmet>
          <title>{t('create-account:register.title')}</title>
        </Helmet>
        <FlexGrowColumnCenteredContainer>
          <RelativeLoader />
        </FlexGrowColumnCenteredContainer>
      </LayoutWrapper>
    );
  }

  if (registrant.isError) {
    return (
      <OopsScreen
        error={getError(registrant.error?.response?.status)}
        pageTitle={t('create-account:register.title-error')}
      />
    );
  }

  return (
    <LayoutWrapper menu={ONLY_LOGO_WHITE_BACKGROUND}>
      <Helmet>
        <title>{t('create-account:register.title')}</title>
      </Helmet>
      <Container>
        <SecondaryTitleOne weight="medium">
          {t('create-account:register.lets-create-your-account')}
        </SecondaryTitleOne>

        <Content>
          {isSigninWithGoogleEnabled && (
            <GoogleBlock>
              <GoogleLoginButton context={USE} restrictEmailTo={registrant?.data?.email} />
              <Separator>{t('login:label-or')}</Separator>
            </GoogleBlock>
          )}

          <FormBoxStyled>
            <Form onChange={handleChange} onSubmit={handleSubmit}>
              <FormControl aria-describedby="registration-error" error={isError} fullWidth>
                <Input
                  autoComplete="username"
                  disabled
                  fullWidth
                  label={t('create-account:register.labels.email')}
                  name="email"
                  value={registrant?.data?.email}
                />
                <Input
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus
                  defaultValue={registrant?.data?.firstName}
                  fullWidth
                  label={t('create-account:register.labels.first-name')}
                  name="firstName"
                  required
                />
                <Input
                  defaultValue={registrant?.data?.lastName}
                  fullWidth
                  label={t('create-account:register.labels.last-name')}
                  name="lastName"
                  required
                />
                <Suspense fallback={<PasswordFieldSkeleton />}>
                  <PasswordField
                    error={isError}
                    thresholdLength={DEFAULT_PASSWORD_THRESHOLD_LENGTH}
                    onPasswordChanged={onPasswordChanged}
                  />
                </Suspense>
                {isError && <FormHelperText>{getError(error.response.status)}</FormHelperText>}
              </FormControl>
              <ContainedButton
                disabled={isLoading || !state.password || !registrant?.data?.email}
                fullWidth
                height="big"
                type="submit"
                withMargin
              >
                {isLoading ? <RelativeLoader /> : t('create-account:register.sign-up')}
              </ContainedButton>
            </Form>
          </FormBoxStyled>
        </Content>
      </Container>
    </LayoutWrapper>
  );
}

export default Register;
