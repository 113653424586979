import React from 'react';
import { Checkbox } from 'semji-core/components/Input/Checkbox';

export default function MultiSelectItem({
  checked,
  onClick,
  indeterminate,
  hasCurrentSelected,
  label,
}: {
  checked: boolean;
  onClick: () => void;
  indeterminate?: boolean;
  label: string | React.ReactNode;
  hasCurrentSelected?: boolean;
}) {
  return (
    <div className="competitors-multi-select__content__list__item" onClick={onClick}>
      <span>
        <Checkbox
          checked={checked}
          className={`competitors-multi-select__content__list__item__checkbox ${hasCurrentSelected && 'competitors-multi-select__content__list__item__checkbox--indeterminate'}`}
          indeterminate={indeterminate}
          onChange={onClick}
        />
      </span>
      <span className="competitors-multi-select__content__list__item__label">{label}</span>
    </div>
  );
}
