import React from 'react';

function AICreditIcon(props) {
  return (
    <svg fill="none" height="14" viewBox="0 0 11 12" width="14">
      <path
        d="M11 6C11 9.03756 8.53756 11.5 5.5 11.5C2.46243 11.5 0 9.03756 0 6C0 2.96243 2.46243 0.5 5.5 0.5C8.53756 0.5 11 2.96243 11 6Z"
        fill="url(#paint0_linear_563_6158)"
      />
      <circle
        cx="5.5"
        cy="6"
        r="5.27592"
        stroke="url(#paint1_linear_563_6158)"
        strokeWidth="0.448148"
      />
      <mask
        height="8"
        id="mask0_563_6158"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="8"
        x="2"
        y="2"
      >
        <path
          clipRule="evenodd"
          d="M4.74113 2.56859C4.69427 2.38209 4.42925 2.38209 4.38238 2.56859L3.96971 4.21089C3.9531 4.27698 3.90149 4.32859 3.83541 4.34519L2.1931 4.75787C2.0066 4.80474 2.0066 5.06976 2.1931 5.11662L3.83541 5.5293C3.90149 5.54591 3.9531 5.59751 3.96971 5.6636L4.38238 7.3059C4.42925 7.49241 4.69427 7.49241 4.74113 7.3059L5.15381 5.6636C5.17042 5.59751 5.22202 5.54591 5.28811 5.5293L6.93042 5.11662C7.11692 5.06976 7.11692 4.80474 6.93042 4.75787L5.28811 4.34519C5.22202 4.32859 5.17042 4.27698 5.15381 4.21089L4.74113 2.56859ZM7.62521 6.12681C7.59367 6.00128 7.41529 6.00128 7.38375 6.12681L7.10598 7.23221C7.0948 7.27669 7.06007 7.31143 7.01559 7.3226L5.91019 7.60037C5.78466 7.63191 5.78466 7.81029 5.91019 7.84183L7.01559 8.1196C7.06007 8.13077 7.0948 8.16551 7.10598 8.20999L7.38375 9.31539C7.41529 9.44092 7.59367 9.44092 7.62521 9.31539L7.90298 8.20999C7.91415 8.16551 7.94889 8.13077 7.99337 8.1196L9.09877 7.84183C9.2243 7.81029 9.2243 7.63191 9.09877 7.60037L7.99337 7.3226C7.94889 7.31143 7.91415 7.27669 7.90298 7.23221L7.62521 6.12681Z"
          fill="#252736"
          fillRule="evenodd"
        />
      </mask>
      <g mask="url(#mask0_563_6158)">
        <rect fill="white" height="9.93834" width="9.93834" x="0.276367" y="0.815186" />
      </g>
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_563_6158"
          x1="2.22132e-05"
          x2="11.002"
          y1="6.0031"
          y2="6.0031"
        >
          <stop stopColor="#FF4580" />
          <stop offset="1" stopColor="#FF4D64" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_563_6158"
          x1="9.8578"
          x2="1.45503"
          y1="2.39172"
          y2="9.67412"
        >
          <stop stopColor="#CA3B4E" />
          <stop offset="1" stopColor="#CA3B4E" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default AICreditIcon;
