import { useEffect, useState } from 'react';
import { noop } from 'semji-core/utils/noop';

import useUrlQuery from '@/hooks/useUrlQuery';
import mapQueryParameterToFilters from '@/utils/filter/mapQueryParameterToFilters';

const defaultFilters = {
  filterGroups: [],
  operator: 'AND',
};
function useMapUrlQueryToFilter({ filterList, onError = noop() }) {
  const [filters, setFilters] = useState(defaultFilters);
  const query = useUrlQuery();
  const operator = query?.get('operator') || 'AND';
  const filter = query?.get('filter');

  useEffect(() => {
    try {
      setFilters(mapQueryParameterToFilters({ filter, filterList, operator }));
    } catch {
      onError();
      setFilters(defaultFilters);
    }
  }, [operator, filter, filterList]);

  return filters;
}

export default useMapUrlQueryToFilter;
