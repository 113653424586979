import './LayoutHeader.scss';

import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import { FlexContainer, FlexGrowContainer } from '@/components/_common';
import NumberBadge from '@/components/Badge/NumberBadge';
import ColoredLogo from '@/components/icons/Logo';
import WhiteLogo from '@/components/icons/LogoWhite';
import ValidatedIcon from '@/components/icons/ValidatedIcon';
import Header from '@/components/Layout/Header';
import {
  GRADIENT_LOGO_AND_PROFILE_MENU,
  NO_MENU_LAYOUT,
  ONLY_LOGO_WHITE_BACKGROUND,
  ONLY_LOGO_WHITE_BACKGROUND_AND_ONBOARDING,
  TRANSPARENT_LOGO_AND_PROFILE_MENU,
} from '@/components/Layout/Layout';
import SelectWorkspace from '@/components/Select/SelectWorkspace';
import { TitleFive } from '@/components/Text/Title';
import AdministratorMenu from '@/containers/Layout/AdministratorMenu';
import HelperMenu from '@/containers/Layout/HelperMenu';
import HubMenuSelect from '@/containers/Layout/HubMenuSelect/HubMenuSelect';
import LayoutFlashMessages from '@/containers/Layout/LayoutFlashMessages';
import Menu from '@/containers/Layout/Menu';
import Profile from '@/containers/Layout/Profile';
import QuickAccessMenu from '@/containers/Layout/QuickAccessMenu';
import WhatsNewButton from '@/containers/Layout/WhatsNewButton';
import UniversalSearch from '@/containers/UniversalSearch/UniversalSearch';
import Flex from '@/design-system/components/Flex/Flex';
import useApiConfigurations from '@/hooks/useApiConfigurations';
import useOrganizationFeatureSet, {
  COMPETITORS__IS_ENABLED,
} from '@/hooks/useOrganizationFeatureSet';
import useParamSelector from '@/store/hooks/useParamSelector';
import { selectWorkspaceIsOnboarding } from '@/store/selectors/selectWorkspaceIsOnboarding';
import defaultTheme from '@/themes/defaultTheme';
import { COMPETITORS_ENABLED } from '@/utils/configurations/constants';
import { COOKIE_IMPERSONATE_KEY_NAME, getCookie } from '@/utils/cookies/cookies';

import { LayoutHeaderProps } from './LayoutHeader.styles';

const StepWrapper = styled(FlexContainer)`
  && {
    align-items: center;
    padding: 0 1rem;
  }
`;
const StyledValidatedIcon = styled(ValidatedIcon)`
  && {
    font-size: 2em;
    margin-right: 4px;
  }
`;
const StyledUniversalSearch = styled(UniversalSearch)`
  && {
    display: flex;
    align-items: center;
  }
  & > div {
    width: 150px;
  }
`;

const SeparatorDiv = styled.div`
  border-right: 0.25px solid #777777;
  height: 25px;
`;

const StyledImage = styled.img`
  max-height: 48px;
  max-width: 135px;
`;

function isFlashMessageDisplay(path: string) {
  if (
    path === '/o/:organizationId/workspace-access-blocked' ||
    path === '/o/:organizationId/add-workspace-not-authorized' ||
    path === '/o/:organizationId/create-workspace'
  ) {
    return false;
  }
  return true;
}

function LayoutHeader({ variant, steps }: LayoutHeaderProps) {
  const location = useLocation();
  const { organizationId, workspaceId } = useParams();
  const { isFeatureEnabled: hasAccessToCompetitor } =
    useOrganizationFeatureSet(COMPETITORS__IS_ENABLED);
  const userImpersonate = getCookie(COOKIE_IMPERSONATE_KEY_NAME);
  const displayFlashMessage = isFlashMessageDisplay(location.pathname);
  const isWorkspaceOnboarding = useParamSelector(
    selectWorkspaceIsOnboarding,
    organizationId,
    workspaceId
  );
  const brandImageUrl = useSelector(
    (state: any) => state.organizations?.byId?.[String(organizationId)]?.brandImageUrl
  );
  const isCompetitorEnabled = useApiConfigurations(COMPETITORS_ENABLED);

  const getLogoLink = () => {
    if (workspaceId && organizationId && isWorkspaceOnboarding) {
      return `/o/${organizationId}/w/${workspaceId}/onboarding`;
    }
    if (workspaceId && organizationId && !isWorkspaceOnboarding) {
      return `/o/${organizationId}/w/${workspaceId}/dashboard`;
    }
    if (organizationId) {
      return `/o/${organizationId}`;
    }

    return '/';
  };

  if (variant === NO_MENU_LAYOUT) {
    return null;
  }

  if (variant === ONLY_LOGO_WHITE_BACKGROUND_AND_ONBOARDING) {
    return (
      <Header className="layout-header" userImpersonate={userImpersonate} variant={variant}>
        <div className="layout-header__container">
          <Link className="layout-header__centered-link" to={getLogoLink()}>
            <ColoredLogo />
          </Link>
          {steps.map((step, index) => (
            <StepWrapper key={step.id}>
              {step.isValidated ? (
                <StyledValidatedIcon color={defaultTheme.cssColors.green} size="default" />
              ) : (
                <NumberBadge color={step.isCurrent ? 'dark080' : 'dark040'}>
                  {index + 1}
                </NumberBadge>
              )}
              <TitleFive
                key={step.id}
                color={step.isCurrent ? 'dark080' : 'dark040'}
                noMargin
                weight="strong"
              >
                {step.title}
              </TitleFive>
            </StepWrapper>
          ))}
        </div>
        <SelectWorkspace />
      </Header>
    );
  }

  if (variant === ONLY_LOGO_WHITE_BACKGROUND) {
    return (
      <Header className="layout-header" userImpersonate={userImpersonate} variant={variant}>
        <div className="layout-header__container">
          <Link
            className="layout-header__centered-link layout-header__centered-link--full"
            to={getLogoLink()}
          >
            <ColoredLogo />
          </Link>
          {steps.map((step, index) => (
            <StepWrapper key={step.id}>
              {step.isValidated ? (
                <StyledValidatedIcon color={defaultTheme.cssColors.green} size="default" />
              ) : (
                <NumberBadge color={step.isCurrent ? 'dark080' : 'dark040'}>
                  {index + 1}
                </NumberBadge>
              )}
              <TitleFive
                key={step.id}
                color={step.isCurrent ? 'dark080' : 'dark040'}
                noMargin
                weight="strong"
              >
                {step.title}
              </TitleFive>
            </StepWrapper>
          ))}
        </div>
      </Header>
    );
  }

  if (variant === TRANSPARENT_LOGO_AND_PROFILE_MENU || variant === GRADIENT_LOGO_AND_PROFILE_MENU) {
    return (
      <>
        <Header className="layout-header" userImpersonate={userImpersonate} variant={variant}>
          <Link className="layout-header__centered-link" to={getLogoLink()}>
            <WhiteLogo />
          </Link>
          {!!brandImageUrl && (
            <Flex alignItems="center" gap="12px" marginLeft="12px">
              <SeparatorDiv />
              <StyledImage alt="brand image" src={brandImageUrl} />
            </Flex>
          )}
          <FlexGrowContainer />
          <AdministratorMenu />
          <Profile />
        </Header>
        {displayFlashMessage && (
          <LayoutFlashMessages organizationId={organizationId} workspaceId={workspaceId} />
        )}
      </>
    );
  }

  return (
    <>
      <Header className="layout-header" userImpersonate={userImpersonate} variant={variant}>
        <Link className="layout-header__centered-link" to={getLogoLink()}>
          <WhiteLogo />
        </Link>
        {!!brandImageUrl && (
          <Flex alignItems="center" gap="12px" marginLeft="12px">
            <SeparatorDiv />
            <StyledImage alt="brand image" src={brandImageUrl} />
          </Flex>
        )}
        {isCompetitorEnabled && hasAccessToCompetitor && <HubMenuSelect />}
        <Menu />
        <StyledUniversalSearch />
        <HelperMenu />
        <WhatsNewButton />
        <AdministratorMenu />
        <QuickAccessMenu data-intercom-target="quick_access_menu_create_drafts" />
        <Profile data-intercom-target="profile_dropdown_menu" />
      </Header>
      {displayFlashMessage && (
        <LayoutFlashMessages organizationId={organizationId} workspaceId={workspaceId} />
      )}
    </>
  );
}

export default LayoutHeader;
