import { UseFiltersResults } from '@/hooks/useFilters/useFilters.types';

export interface SingleFilterProps {
  filter: string | undefined | number[];
  updateFilter: UpdateFilter;
}

export interface MultiFilterProps {
  filter: string[];
  updateFilter: UpdateFilter;
}

export interface FilterRangeProps {
  filter?: number[];
  updateFilter: UpdateFilter;
  customRange?: number[];
  onClose?: (key: string) => void;
}

type UpdateFilter = (filterTarget: string, filter: any) => void;

export interface SingleFilterType {
  icon: React.ReactNode;
  key: string;
  options: FilterOption[] | FilterRadioRangeOption[];
  onValidate: (value: string) => void;
  name: string;
  type: ENUM_FILTER_TYPE;
  customRange?: number[];
  onClose?: (key: string) => void;
  disabled?: boolean;
}

export interface MultipleFilterType {
  icon: React.ReactNode;
  key: string;
  options: FilterOption[];
  onValidate: (value: string[]) => void;
  name: string;
  type: ENUM_FILTER_TYPE.MULTI_SELECT;
  disabled?: boolean;
}

export type FilterType = SingleFilterType | MultipleFilterType;

export type ToggleButtonOptionItemType = {
  active: boolean;
  title: string;
  tooltip: string;
  value: string;
};

export type FilterOption = {
  id?: string;
  value: string;
  label: any;
  selected?: boolean;
};

export type FilterRadioRangeOption = {
  id?: string;
  value: number[];
  label: string;
  selected?: boolean;
};

export interface FilterProps {
  AddFilterElement: any;
  filters?: FilterType[];
  filterHook: UseFiltersResults<any>;
  before?: (id: string) => any;
  after?: (id: string) => any;
}

export enum ENUM_FILTER_TYPE {
  SELECT = 'select',
  MULTI_SELECT = 'multi-select',
  RADIO = 'radio',
  RADIO_RANGE = 'radio_range',
  TOGGLE = 'toggle',
}
