/* eslint-disable sort-keys-fix/sort-keys-fix */
import useMediaQuery from '@/hooks/useMediaQuery';
import { mediaQueries } from '@/themes/responsive';

const { range, min, max } = mediaQueries;

// Credits: https://gist.github.com/gragland/ed8cac563f5df71d78f4a1fefa8c5633/c769cdc6a658b3925e9e2e204d228400d132965f#gistcomment-3474308
function useBreakpoints() {
  return {
    // Range: (min-width: ${breakpoint}) and (max-width: ${maxBreakpoint})
    isMobile: useMediaQuery(range.mobile),
    isXxs: useMediaQuery(range.xxs),
    isXs: useMediaQuery(range.xs),
    isSm: useMediaQuery(range.sm),
    isMd: useMediaQuery(range.md),
    isLg: useMediaQuery(range.lg),
    isXl: useMediaQuery(range.xl),
    isXxl: useMediaQuery(range.xxl),
    isLaptop: useMediaQuery(range.laptop),
    isDesktop: useMediaQuery(range.desktop),
    isLargeDesktop: useMediaQuery(range.largeDesktop),

    // Min: (min-width: ${breakpoint})
    isMinMobile: useMediaQuery(min.mobile),
    isMinXs: useMediaQuery(min.xs),
    isMinSm: useMediaQuery(min.sm),
    isMinMd: useMediaQuery(min.md),
    isMinLg: useMediaQuery(min.lg),
    isMinXl: useMediaQuery(min.xl),
    isMinXxl: useMediaQuery(min.xxl),
    isMinLaptop: useMediaQuery(min.laptop),
    isMinDesktop: useMediaQuery(min.desktop),
    isMinLargeDesktop: useMediaQuery(min.largeDesktop),

    // Max: (max-width: ${maxBreakpoint})
    isMaxMobile: useMediaQuery(max.mobile),
    isMaxXxs: useMediaQuery(max.xxs),
    isMaxXs: useMediaQuery(max.xs),
    isMaxSm: useMediaQuery(max.sm),
    isMaxMd: useMediaQuery(max.md),
    isMaxLg: useMediaQuery(max.lg),
    isMaxXl: useMediaQuery(max.xl),
    isMaxLaptop: useMediaQuery(max.laptop),
    isMaxDesktop: useMediaQuery(max.desktop),
  };
}

export default useBreakpoints;
