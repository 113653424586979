import { GridApi } from 'ag-grid-community';

import { ENUM_GRID_PIN, IGridColumnPersister } from '@/components/AGGrid/GridBody/GridBody.types';

export class GridUtils {
  // Will detect if the grid is smaller than default column width
  public static isGridOverflowing(gridColumnsPersisted: IGridColumnPersister[]): boolean {
    const gridWidth = GridUtils.getGridWidth();
    const columnsWidth = GridUtils.getColumnsWidth(gridColumnsPersisted);

    return gridWidth < columnsWidth;
  }

  // Will init the grid columns persister
  public static initGridColumnsPersister(gridApi: GridApi): IGridColumnPersister[] {
    return gridApi
      .getAllDisplayedColumns()
      .map((column, index) => ({ columnId: column.getUniqueId(), width: column.getActualWidth() }));
  }

  // Update grid columns persister width
  public static updateGridColumnsPersister(
    gridApi: GridApi,
    gridColumnPersister: IGridColumnPersister[]
  ): IGridColumnPersister[] {
    return gridApi.getAllDisplayedColumns().map((column, index) => ({
      ...gridColumnPersister[index],
      columnId: column.getUniqueId(),
      width: column.getActualWidth(),
    }));
  }

  // Update grid columns persister pinning
  public static updateGridColumnPinning(
    gridApi: GridApi,
    gridColumnPersister: IGridColumnPersister[],
    pinned: boolean
  ): IGridColumnPersister[] {
    function getColumnPinning(index: number) {
      if (index === 0) return pinned ? ENUM_GRID_PIN.left : undefined;
      if (index === gridColumnPersister.length - 1) return pinned ? ENUM_GRID_PIN.right : undefined;
      return undefined;
    }
    return gridApi.getAllDisplayedColumns().map((column, index) => ({
      ...gridColumnPersister[index],
      columnId: column.getUniqueId(),
      pinned: getColumnPinning(index),
      width: column.getActualWidth(),
    }));
  }

  // Return the width of the grid in pixels
  private static getGridWidth() {
    return document.querySelector('div.ag-root')?.clientWidth ?? 0;
  }

  // Return the total column widths in pixels
  private static getColumnsWidth(gridColumnPersisted: IGridColumnPersister[]) {
    return gridColumnPersisted.reduce((acc, colDef) => {
      return acc + colDef.width;
    }, 0);
  }
}
