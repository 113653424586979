import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { StyledCard } from '@/components/_common';
import { ContainedButton } from '@/components/Button/Button';
import WarningMessage from '@/components/FlashMessage/WarningMessage';
import { RelativeLoader } from '@/components/Loader/Loader';
import useCheckAdBlocker from '@/hooks/useCheckAdBlocker';
import ServiceIntegrationService from '@/services/ServiceIntegration';
import { SERVICES_LIST } from '@/utils/constants';
import { SECTIONS } from '@/utils/log/constants';
import { Log } from '@/utils/log/Log';

const Wrapper = styled.div``;
const CustomCard = styled(StyledCard)`
  flex-direction: column;
  padding: 2em;
`;
const Img = styled.img`
  width: 200px;
  object-fit: contain;
  margin-bottom: 1em;
`;

function ConnectStep(props) {
  const { t } = useTranslation();

  const onAdBlockError = useCallback(
    () => props.setError(t('service-integration:google-analytics.connect-step.error-adblock')),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const isBlocked = useCheckAdBlocker('/analytics/v3/management/accounts', onAdBlockError);

  const [consenting, setConsenting] = useState(false);

  useEffect(() => {
    if (props.autoTrigger) {
      giveConsent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.autoTrigger]);

  async function giveConsent() {
    setConsenting(true);
    props.setError(null);

    try {
      const _ServiceIntegration = new ServiceIntegrationService();
      const { googleCredentialsValue, accounts } =
        await _ServiceIntegration.fetchGoogleAnalyticsData();
      setConsenting(false);

      return props.consent({
        accounts,
        googleCredentialsValue,
      });
    } catch (e) {
      setConsenting(false);
      props.setError(t('service-integration:google-analytics.connect-step.error'));

      Log.report({
        context: 'fetchAnalyticsProfiles',
        error: e,
        extra: 'Analytics',
        section: SECTIONS.serviceIntegrations.key,
      });

      props.consent(null);
    }
  }

  return (
    <Wrapper>
      <CustomCard>
        <Img
          alt={t('service-integration:google-analytics.connect-step.alt-google-analytics-logo')}
          src={SERVICES_LIST.GOOGLE_ANALYTICS.logo}
        />
        {!!props.analytic && (
          <WarningMessage
            align="center"
            message={t('service-integration:google-analytics.connect-step.warning', {
              analytic: props.analytic,
            })}
          />
        )}
        <ContainedButton
          disabled={isBlocked || consenting}
          fullWidth
          minWidth="400px"
          onClick={giveConsent}
        >
          {consenting ? (
            <RelativeLoader />
          ) : (
            t('service-integration:google-analytics.connect-step.button-connect')
          )}
        </ContainedButton>
      </CustomCard>
    </Wrapper>
  );
}

export default ConnectStep;
