import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { GOOGLE_ANALYTICS_API_ROOT_URL } from '@/apis/googleApi/constants';
import { showErrorSnackbar } from '@/store/actions/ui';

export default function useGetGA4Properties(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: async ({ accountId, accessToken }) =>
      (
        await axios({
          headers: {
            authorization: 'Bearer ' + accessToken,
          },
          method: 'GET',
          params: {
            filter: `parent:accounts/${accountId}`,
          },
          url: `${GOOGLE_ANALYTICS_API_ROOT_URL}/v1beta/properties`,
        })
      )?.data,
    onError: () => {
      dispatch(showErrorSnackbar(t('common:error.default')));
    },
    ...props,
  });
}
