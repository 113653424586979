import { FACT_CHECK_VARIANT_ENUM } from 'semji-core/components/FactCheckCard';

import { Fact, FACT_CRITICALITY_ENUM } from './facts.types';

const apiRootUrl = import.meta.env.VITE_REACT_APP_SEMJI_API_ROOT_URL;

export function transformerCriticalityToVariant(criticality: string) {
  switch (criticality) {
    case FACT_CRITICALITY_ENUM.CRITICALITY_LOW:
      return FACT_CHECK_VARIANT_ENUM.FACT_CHECK_VARIANT_INFO;
    case FACT_CRITICALITY_ENUM.CRITICALITY_MEDIUM:
      return FACT_CHECK_VARIANT_ENUM.FACT_CHECK_VARIANT_WARNING;
    case FACT_CRITICALITY_ENUM.CRITICALITY_HIGH:
      return FACT_CHECK_VARIANT_ENUM.FACT_CHECK_VARIANT_ERROR;
    case FACT_CRITICALITY_ENUM.CRITICALITY_VERIFIED:
    default:
      return FACT_CHECK_VARIANT_ENUM.FACT_CHECK_VARIANT_SUCCESS;
  }
}

export function getSourceTitle(fact: Fact) {
  return fact.isFactChecked ? fact.searchEngineResultsTitle : fact.searchEngineQuestion;
}

export function getSourceUrl(fact: Fact, languageCode: string, countryCode: string) {
  return fact.isFactChecked
    ? fact.searchEngineResultsUrl
    : `https://google.com/search?q=${fact.searchEngineQuestion}&hl=${languageCode}&gl=${countryCode}`;
}

export function getSourceFavicon(fact: Fact) {
  // TO DO: replace with real favicons
  // helper or generic component?
  return fact.isFactChecked
    ? `${apiRootUrl}/domains/clubic.com/favicon`
    : `${apiRootUrl}/domains/google.com/favicon`;
}
