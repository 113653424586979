import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getDefaultOptionParentFolders } from '@/components/Filters/FolderFiltersPanel/constants';
import FolderIcon from '@/components/Filters/FolderFiltersPanel/FolderIcon';
import Select from '@/components/Select/FolderSelect/Select';
import TextField from '@/components/Select/FolderSelect/TextField';
import Dialog from '@/design-system/components/Dialog';
import { isEnterHotkey } from '@/utils/keyboard';

import DialogBody from './DialogBody';

function CreateFolderDialog({
  isLoading,
  onConfirm,
  onCancel,
  parentFoldersOptions,
  defaultSelectedParentFolderId,
}) {
  const { t } = useTranslation();
  const DEFAULT_OPTION_PARENT_FOLDERS = getDefaultOptionParentFolders();

  const [name, setName] = useState('');
  const [parentFolderId, setParentFolderId] = useState(defaultSelectedParentFolderId ?? null);

  const isConfirmDisabled = !name.trim();

  const defaultSelectedParentFolder =
    parentFoldersOptions.find((item) => item.id === defaultSelectedParentFolderId) ||
    DEFAULT_OPTION_PARENT_FOLDERS;

  const options = [
    DEFAULT_OPTION_PARENT_FOLDERS,
    ...parentFoldersOptions.map((item) => ({ name: item.name, value: item.id })),
  ];

  function handleChangeFolderName(value) {
    setName(value);
  }

  function handleChangeParentFolderId(id) {
    setParentFolderId(id);
  }

  function handleKeyDown(event) {
    if (isEnterHotkey(event)) {
      handleConfirm();
    }
  }

  function handleConfirm() {
    onConfirm?.({ name, parentFolderId });
  }

  return (
    <Dialog
      canContentOverflow
      confirmLabel={t('components:filters.folder-filters-panel.create-dialog.label-confirm')}
      isConfirmDisabled={isConfirmDisabled}
      isLoading={isLoading}
      isOpen={true}
      tertiaryButton
      title={t('components:filters.folder-filters-panel.create-dialog.title')}
      onCancel={onCancel}
      onConfirm={handleConfirm}
    >
      <DialogBody>
        <TextField
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          isClearable
          placeholder={t('components:filters.folder-filters-panel.create-dialog.input-placeholder')}
          value={name}
          onChange={handleChangeFolderName}
          onKeyDown={handleKeyDown}
        />
        <Select
          defaultValue={{
            name: defaultSelectedParentFolder.name,
            value: defaultSelectedParentFolder.id,
          }}
          iconPlaceHolder={<FolderIcon $hasMargin />}
          options={options}
          onChange={handleChangeParentFolderId}
        />
      </DialogBody>
    </Dialog>
  );
}

export default CreateFolderDialog;
