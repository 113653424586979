import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components/macro';

import { TertiaryLink } from '@/components/Navigation/Link';
import { LightText } from '@/components/Text/Light';

export const Box = styled(Paper)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: white;
    padding: 50px;
    border-radius: 0;
    min-width: 350px;
    max-width: ${(props) => props.small && '350px'};
    gap: 10px;

    ${({ theme }) => `@media ${theme.responsive.max.xs}`} {
      border-radius: 0;
      height: 100%;
      width: 100%;
      box-sizing: border-box;
      padding: 20px;
    }
  }
`;

export const FormBox = styled.div`
  && {
    min-width: 500px;

    ${({ theme }) => `@media ${theme.responsive.max.xs}`} {
      width: 100%;
    }
  }
`;

export const BoxHeader = styled.div`
  margin-bottom: 20px;
  text-align: ${(props) => props.align ?? 'center'};
  width: ${(props) => props.fullWidth && '100%'};
`;

export const Footer = styled.footer`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 40px;
    width: 100%;
  }
`;

export const Message = styled.div`
  && {
    margin: 10px 0;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 2px;
    background: ${({ theme }) => theme.colors.yellow};
    color: ${({ theme }) => theme.text.colors.default};

    strong {
      font-weight: 700;
    }
  }
`;

export const FooterLink = styled(TertiaryLink)`
  && {
    margin-top: 1em;
    font-size: ${({ theme }) => theme.text.sizes.default};
  }
`;

export const Form = styled.form`
  && {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;

export const Fieldset = styled.fieldset`
  && {
    &:not(:last-of-type) {
      margin-bottom: 40px;
    }
    width: 100%;
  }
`;

export const Label = styled.label`
  && {
    display: flex;
    text-transform: uppercase;
    font-weight: 500;
    align-items: center;
    margin-bottom: ${({ theme }) => theme.text.sizes.default};

    & > svg {
      font-size: ${({ theme }) => theme.image.sizes.big};
      margin-right: ${({ theme }) => theme.text.sizes.small};
    }
  }
`;

export const Content = styled.div`
  && {
    display: flex;
    flex-grow: 1;
    align-self: stretch;
    justify-self: stretch;
    align-items: center;
    justify-content: center;
  }
`;

export const Input = styled(TextField)`
  && {
    margin-bottom: 20px;
  }
`;

export const Link = styled(TertiaryLink)`
  && {
    text-decoration: underline;
    margin: auto;
  }
`;

export const Info = styled(LightText)`
  && {
    font-size: 0.9em;
  }
`;

export const StyledInfo = styled(Info)`
  && {
    display: block;
    line-height: 1.25rem;
    font-size: 1rem;
    margin-bottom: 1rem;
  }
`;
