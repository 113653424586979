import './WarningMessage.scss';

import WarningIconLarge from '@/components/icons/WarningIconLarge';

const WarningMessage = ({ children, size, margin, display, weight, ...props }) => (
  <div
    className="warning-message"
    style={{
      display: display ? display : 'grid',
      margin: margin ? margin : '0px',
    }}
    {...props}
  >
    <WarningIconLarge />
    <div
      className="warning-message__text"
      style={{ fontSize: size ? size : '14px', fontWeight: weight ? weight : 400 }}
    >
      {children}
    </div>
  </div>
);

export default WarningMessage;
