import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import styled from 'styled-components/macro';

import ScrollBar from '@/components/Scrollbar';

const ScrollbarWrapper = styled.div`
  flex: 1;
`;

const StyledPopper = styled(Popper)`
  && {
    position: absolute;
    box-sizing: border-box;
    width: ${({ width }) => (width ?? 500) + 'px'};
    min-height: ${({ $minHeight }) => ($minHeight ?? 50) + 'px'};
    height: ${({ height }) => (height ?? 200) + 'px'};
    overflow: hidden;
    border-radius: 3px;
    box-shadow: 0px 0px 20px rgba(37, 39, 54, 0.1);
    display: flex;
    flex-direction: column;
    background: ${({ background }) => background ?? 'white'};
    background-color: ${({ background }) => background ?? 'white'};
    z-index: 1400;
  }
`;

function DropDown({ anchorEl, setAnchorEl, children, background, width, height, minHeight }) {
  function handleClose() {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  }

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <StyledPopper
        $minHeight={minHeight}
        anchorEl={anchorEl}
        background={background}
        height={height}
        open={!!anchorEl}
        placement="bottom-end"
        width={width}
      >
        <ScrollbarWrapper>
          <ScrollBar>{children}</ScrollBar>
        </ScrollbarWrapper>
      </StyledPopper>
    </ClickAwayListener>
  );
}

export default DropDown;
