import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  GRADIENT_LOGO_AND_PROFILE_MENU,
  ONLY_LOGO_WHITE_BACKGROUND_AND_ONBOARDING,
} from '@/components/Layout/Layout';
import Stepper from '@/containers/CreateWorkspace/Stepper/Stepper';
import LayoutWrapper from '@/containers/Layout/Layout';
import useBreakpoints from '@/hooks/useBreakpoints';
import { CREATE_WORKSPACE_STEP, SERVICE_INTEGRATION } from '@/utils/constants';
import { getOnboardingSteps } from '@/utils/helper';

import { CREATE_WORKSPACE_STEPS } from '.';

function WorkspaceCreator({ isFirstWorkspace }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [currentStepKey, setCurrentStepKey] = useState(CREATE_WORKSPACE_STEPS.general.stepKey);
  const { isMobile } = useBreakpoints();

  useEffect(() => {
    const { urlFragment } = CREATE_WORKSPACE_STEPS[currentStepKey];
    navigate(pathname + urlFragment, { replace: true });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStepKey]);

  const getNextStepKey = (key) => {
    const nextPosition = Math.min(CREATE_WORKSPACE_STEPS[key].position + 1, 2);
    const nextStep = Object.values(CREATE_WORKSPACE_STEPS).find(
      (step) => step.position === nextPosition
    );

    return nextStep.stepKey;
  };

  const getPreviousStepKey = (key) => {
    const previousPosition = Math.max(CREATE_WORKSPACE_STEPS[key].position - 1, 0);
    const previousStep = Object.values(CREATE_WORKSPACE_STEPS).find(
      (step) => step.position === previousPosition
    );

    return previousStep.stepKey;
  };

  function nextStep() {
    const newStepKey = getNextStepKey(currentStepKey);
    setCurrentStepKey(newStepKey);
  }

  function previousStep() {
    const newStepKey = getPreviousStepKey(currentStepKey);
    setCurrentStepKey(newStepKey);
  }

  return (
    <LayoutWrapper
      menu={
        isFirstWorkspace
          ? ONLY_LOGO_WHITE_BACKGROUND_AND_ONBOARDING
          : GRADIENT_LOGO_AND_PROFILE_MENU
      }
      steps={
        isFirstWorkspace && !isMobile
          ? getOnboardingSteps(
              currentStepKey === 'dataSources' ? SERVICE_INTEGRATION : CREATE_WORKSPACE_STEP
            )
          : []
      }
    >
      <Helmet>
        <title>{t('create-workspace:title')}</title>
      </Helmet>
      <Stepper
        cancelable={!isFirstWorkspace}
        currentStepKey={currentStepKey}
        isMobileVersion={isMobile}
        nextStep={nextStep}
        previousStep={previousStep}
      />
    </LayoutWrapper>
  );
}

export default WorkspaceCreator;
