import styled from 'styled-components/macro';

import { InlineText } from '../../../../../components/Text/Inline';

const UppercaseText = styled(InlineText)`
  /* 20px on the right due to Scrollbar overlapping text */
  padding: 0.3rem 20px 0.3rem 0;
  text-transform: uppercase;
`;

export default UppercaseText;
