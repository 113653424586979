import styled from 'styled-components/macro';

import Button from './Button';

const SecondaryButton = styled(Button)`
  color: ${({ disabled, theme }) =>
    disabled ? theme.cssColors.primary040 : theme.cssColors.primary};
  background: ${({ theme }) => theme.cssColors.white};
  border: 1px solid
    ${({ disabled, theme }) => (disabled ? theme.cssColors.primary040 : theme.cssColors.primary)};

  &:hover,
  &.active {
    color: ${({ disabled, theme }) => !disabled && theme.cssColors.secondaryDark};
    border: 1px solid ${({ disabled, theme }) => !disabled && theme.cssColors.secondaryDark};
  }
`;

export default SecondaryButton;
