import { useTranslation } from 'react-i18next';

import {
  CancelButton,
  ConfirmButton,
  StyledDeleteIcon,
  Title,
  Wrapper,
} from '@/containers/Content/TinyMceComponents/Comments/SidePanel/Delete/Delete.styled';
import Flex from '@/design-system/components/Flex';

function Delete({ onClose, onConfirm, title }) {
  const { t } = useTranslation();

  function handleClose(e) {
    e?.stopPropagation();
    onClose();
  }

  function handleConfirm(e) {
    e?.stopPropagation();
    onConfirm();
    handleClose();
  }

  return (
    <Wrapper backgroundColor="white" height="100%" position="absolute" width="100%">
      <Flex
        alignItems="center"
        flexDirection="column"
        gap="2px"
        justifyContent="center"
        width="100%"
      >
        <Title>{title}</Title>
        <Flex gap="16px">
          <CancelButton onClick={handleClose}>{t('comments:delete.cancel')}</CancelButton>
          <ConfirmButton onClick={handleConfirm}>
            <StyledDeleteIcon />
            {t('comments:delete.delete')}
          </ConfirmButton>
        </Flex>
      </Flex>
    </Wrapper>
  );
}

export default Delete;
