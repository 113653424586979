import './ChartTooltipSingle.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { ChartDataType, ENUM_CHART_DATA_TYPE } from '@/containers/Competitors/Duel/Report/constant';
import { COMPETITORS_METRIC_TYPES } from '@/containers/Competitors/utils/constants';
import { DEFAULT_FAVICON } from '@/utils/constants';
import { getFaviconFromUrl } from '@/utils/favicon';

export function ChartTooltipSingle({
  title,
  name,
  value,
  metrics,
  percentValue,
  renderType,
  custom,
  id,
}: {
  title: string;
  name: string;
  value: string;
  metrics: COMPETITORS_METRIC_TYPES[];
  percentValue: string;
  renderType: ChartDataType;
  custom: {
    [key in COMPETITORS_METRIC_TYPES]: number;
  };
  id: string;
}) {
  const apiRootUrl = import.meta.env.VITE_REACT_APP_SEMJI_API_ROOT_URL;
  const isPercentageDisplay = renderType === ENUM_CHART_DATA_TYPE.PERCENTAGE;
  const { t } = useTranslation();
  return (
    <div className="chart-tooltip-single">
      <div className="chart-tooltip-single__label">{title}</div>
      <div className="chart-tooltip-single__point__left">
        <div className="chart-tooltip-single__point__top">
          {metrics.map((metric, key) => (
            <div key={key}>
              <span>{t(`competitors:duel.report.${metric}`)}</span>
            </div>
          ))}
        </div>
        <div className="chart-tooltip-single__point__bottom">
          <div className="chart-tooltip-single__point__bottom__start">
            <img
              alt=""
              className="chart-tooltip-single__point__icon"
              id={id}
              src={getFaviconFromUrl(name, apiRootUrl)}
              style={{
                backgroundImage: `url(${DEFAULT_FAVICON})`,
              }}
            />
            <span>{name}</span>
          </div>
          <div className="chart-tooltip-single__point__bottom__end">
            {metrics.map((metric, index) => (
              <div key={index} className="chart-tooltip-single__point__bottom__end__metric">
                {index === 0 && isPercentageDisplay && <span>{percentValue}</span>}
                <div
                  className={`${isPercentageDisplay && index === 0 && 'chart-tooltip-single__point__bottom__end__metric--light'}`}
                >
                  <span>{custom[metric] ?? value}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
