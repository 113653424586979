import { parseJwtToken } from '@/utils/jwt/parseJwtToken';
import { envDetector } from '@/utils/log/envDetector';

export const COOKIE_TOKEN_KEY_NAME = `token_${envDetector()}`;
export const COOKIE_REFRESH_TOKEN_KEY_NAME = `refresh_token_${envDetector()}`;
export const COOKIE_IMPERSONATE_KEY_NAME = `impersonate_${envDetector()}`;
const COOKIE_DOMAIN =
  window.location.hostname === 'localhost'
    ? `${window.location.hostname}`
    : import.meta.env.VITE_REACT_APP_COOKIE_DOMAIN;

// Credits: https://www.tabnine.com/academy/javascript/how-to-get-cookies/
export function getCookie(name) {
  const str = name + '=';
  const decoded = decodeURIComponent(document.cookie);
  const arr = decoded.split('; ');
  let res;
  arr.forEach((val) => {
    if (val.indexOf(str) === 0) {
      res = val.substring(str.length);
    }
  });
  return res;
}

export function setTokenCookie(token) {
  const { exp = 0 } = parseJwtToken(token);

  setCookieByKey({ expiresTimestampinS: exp, key: COOKIE_TOKEN_KEY_NAME, value: token });
}

export function setRefreshTokenCookie({ refreshTokenExpiration, refreshToken }) {
  setCookieByKey({
    expiresTimestampinS: refreshTokenExpiration,
    key: COOKIE_REFRESH_TOKEN_KEY_NAME,
    value: refreshToken,
  });
}

export function setImpersonateCookie(email) {
  setCookieByKey({
    expiresTimestampinS: 0,
    key: COOKIE_IMPERSONATE_KEY_NAME,
    value: email,
  });
}

export function setCookieByKey({ key, value, expiresTimestampinS }) {
  document.cookie = `${key}=${value};${
    expiresTimestampinS
      ? `expires=${new Date(expiresTimestampinS * 1000).toUTCString()};max-age=${
          expiresTimestampinS - new Date().getTime() / 1000
        };`
      : ``
  }path=/;domain=${COOKIE_DOMAIN};secure`;
}

export function removeCookieByKey(key) {
  document.cookie = `${key}=;path=/;expires=Thu Jan 01 1970 00:00:00 GMT;max-age=-1;domain=${COOKIE_DOMAIN}`;
  // Remove old cookie values (only with hostname).
  document.cookie = `${key}=;path=/;expires=Thu Jan 01 1970 00:00:00 GMT;max-age=-1;`;
}
