import './Report.scss';

import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'semji-core/components/Tooltip';
import { InfoIcon } from 'semji-core/icons/InfoIcon';

import { useGetCompetitorPersonas, useGetCompetitorReportMetrics } from '@/apis/semji/competitors';
import { GROUP_BY_PERSONA } from '@/apis/semji/competitors/constants';
import Card from '@/components/Card/Card';
import { NO_COMPETITOR_STACK_BAR_FULL } from '@/components/EmptyState/NoContentsVerticalBars';
import ChartWrapper, {
  CHART_WRAPPER_VARIANT_SMALL,
} from '@/containers/Competitors/components/ChartWrapper';
import EmptyStateChart from '@/containers/Competitors/components/EmptyStateChart';
import { ExportOptionButton } from '@/containers/Competitors/components/ExportOptionButton/ExportOptionButton';
import { HighChartsStackedBar } from '@/containers/Competitors/Duel/Report/Chart/HighChartsStackedBar';
import {
  ENUM_CHART_DATA_TYPE,
  SMALL_CARD_CONTENT_MIN_HEIGHT,
} from '@/containers/Competitors/Duel/Report/constant';
import { ToggleSelector } from '@/containers/Competitors/Duel/Selector/Selector';
import { useCompetitor } from '@/containers/Competitors/hooks/useCompetitor';
import { PAGE_TYPE_AND_PERSONA_REPORT_COLORS } from '@/containers/Competitors/utils/colors';
import {
  COMPETITORS_METRIC_TYPES,
  getReportFormatValues,
  PERSONA_FILTER_QUERY_PARAM,
} from '@/containers/Competitors/utils/constants';
import {
  filterCompetitorWebsites,
  getCompetitorValueMetricByType,
  getHostnamefromUrl,
  sortMetricByVolume,
} from '@/containers/Competitors/utils/utils';

export function PersonaStackedBarReport({
  competitorsFilter,
  competitorReportId,
  filters,
  handleOpenDataExplorer,
}) {
  const { t } = useTranslation();
  const [renderType, setRenderType] = useState(ENUM_CHART_DATA_TYPE.VALUE);
  const [exportImage, setExportImage] = useState(false);

  const {
    data: personas,
    isLoading: isPersonaLoading,
    isPlaceholderData: isPersonaPlaceholderData,
  } = useGetCompetitorPersonas({
    competitorReportId: competitorReportId,
    staleTime: 0,
  });
  const {
    data: metrics = [],
    isLoading: isPersonaMetricsLoading,
    isPlaceholderData,
  } = useGetCompetitorReportMetrics({
    competitorReportId: competitorReportId,
    filters: {
      ...filters,
      groupBy: GROUP_BY_PERSONA,
    },
    staleTime: 0,
  });
  const { competitorWebsites, websiteReference } = useCompetitor();

  const chartRef = useRef(null);

  const formatValues = getReportFormatValues();
  const reference = getHostnamefromUrl(websiteReference?.url);
  const { series, categories } = buildSeries();
  const isLoading =
    isPersonaMetricsLoading || isPlaceholderData || isPersonaPlaceholderData || isPersonaLoading;

  function buildSeries() {
    const websites = filterCompetitorWebsites(competitorWebsites, competitorsFilter);
    const sortedSeries = sortMetricByVolume(metrics, personas);
    const seriesLength = sortedSeries.length;

    const series = sortedSeries.map(({ id: idPersona, name }, index) => {
      return {
        color: PAGE_TYPE_AND_PERSONA_REPORT_COLORS[seriesLength - index - 1],
        data: websites.map(({ id }) => getCompetitorValueMetricByType(metrics, id, idPersona)),
        name: name,
      };
    });

    const categories = websites.map((website) => {
      return { name: website.name, url: website.url, urlType: website.urlType };
    });
    return { categories, series };
  }

  const isNoData = series?.length === 0 || categories?.length === 0 || metrics?.length === 0;

  function handleSelectThemeType(value) {
    return () => {
      setRenderType(value);
    };
  }

  return (
    <ChartWrapper isLoading={isLoading} variant={CHART_WRAPPER_VARIANT_SMALL}>
      <Card
        className="competitors-duel-report__card"
        contentMinHeight={SMALL_CARD_CONTENT_MIN_HEIGHT}
        title={
          <div className="competitors-duel-report__card__header">
            <div className="competitors-duel-report__card__header__title">
              <span>{t('competitors:reports.report.persona-traffic.title')}</span>
              <Tooltip
                description={t('competitors:source-normalized-traffic')}
                isFrame={false}
                title={t('competitors:reports.report.persona-traffic.tooltip')}
                tooltipClassName="competitors-duel-report__card__header__title-tooltip competitors-duel-report__card__header__title-tooltip--extra-large"
              >
                <InfoIcon />
              </Tooltip>
            </div>
            <div className="competitors-duel-report__card__header__actions">
              <div className="competitors-duel-report__card__header__filter">
                <ToggleSelector
                  list={formatValues.map(({ title, tooltip, value }) => ({
                    active: value === renderType,
                    onClick: handleSelectThemeType(value),
                    title: title,
                    tooltip: tooltip,
                    value: value,
                  }))}
                />
              </div>
              <ExportOptionButton
                chartRef={chartRef}
                exportImage={exportImage}
                setExportImage={setExportImage}
              />
            </div>
          </div>
        }
      >
        {isNoData ? (
          <EmptyStateChart version={NO_COMPETITOR_STACK_BAR_FULL} />
        ) : (
          <HighChartsStackedBar
            categories={categories}
            chartRef={chartRef}
            metricsSeries={series}
            reference={reference}
            renderType={renderType}
            selectedMetrics={[COMPETITORS_METRIC_TYPES.traffic, COMPETITORS_METRIC_TYPES.page]}
            serieKey={PERSONA_FILTER_QUERY_PARAM}
            showLegendWinner={false}
            title={t('competitors:reports.report.persona-traffic.title')}
            withPositioner={true}
            onPointClick={handleOpenDataExplorer}
          />
        )}
      </Card>
    </ChartWrapper>
  );
}
