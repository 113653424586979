import Input from '@material-ui/core/Input';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FlexAlignEndContainer } from '@/components/_common';
import { PrimaryButton } from '@/components/Button/Button';
import { PortalInputBox } from '@/components/Selector';
import { isEnterHotkey } from '@/utils/keyboard';

export function PopoverKeywordAdd({ isOpen, onClose, onAdd, anchor }) {
  const { t } = useTranslation();
  const [newValue, setAddNewValue] = useState('');

  function handleChange(e) {
    setAddNewValue(e.target.value);
  }

  function handleKeydown(e) {
    if (isEnterHotkey(e) && newValue) {
      onAdd(newValue);
      setAddNewValue('');
      onClose();
    }
  }

  function handleOnClick() {
    if (newValue) {
      onAdd(newValue);
      setAddNewValue('');
      onClose();
    }
  }

  return (
    <PortalInputBox anchorEl={anchor} open={isOpen} onClose={onClose}>
      <FlexAlignEndContainer>
        <Input
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          placeholder={t('components:focus-keyword.popover-keyword-add.keyword-label')}
          value={newValue}
          onChange={handleChange}
          onKeyDown={handleKeydown}
        />
        &nbsp;
        <PrimaryButton disabled={!newValue} onClick={handleOnClick}>
          {t('components:focus-keyword.popover-keyword-add.add-keyword')}
        </PrimaryButton>
      </FlexAlignEndContainer>
    </PortalInputBox>
  );
}
