import styled from 'styled-components/macro';

import { SmallFlexTooltipComponent } from '@/components/Tooltip/Tooltip';

const BulkActionIcon = styled(SmallFlexTooltipComponent)`
  cursor: pointer;
  box-sizing: border-box;
  min-width: 30px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  color: ${({ theme }) => theme.cssColors.dark080};

  &:hover {
    background-color: ${({ theme }) => theme.cssColors.dark005};
  }

  svg {
    width: 17px;
    height: 17px;
  }
`;

export default BulkActionIcon;
