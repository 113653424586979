import { useState } from 'react';
import CountUp from 'react-countup';

function CountUpComponent(props) {
  const [previousValue, setPreviousValue] = useState(0);

  const { value, children, ...otherProps } = props;

  function handlePreviousValue() {
    setPreviousValue(value);
  }

  return (
    <>
      <CountUp
        duration={1}
        end={value ? value : 0}
        start={previousValue}
        style={{ display: 'none' }}
        useEasing={false}
        onComplete={handlePreviousValue}
        {...otherProps}
      />
      {children({
        targetValue: value,
        value,
      })}
    </>
  );
}

export default CountUpComponent;
