import {
  ADD_DRAFT_TO_PLANNING_FAIL,
  ADD_DRAFT_TO_PLANNING_LOADING,
  ADD_DRAFT_TO_PLANNING_SUCCESS,
  CREATE_NEW_DRAFT_FAIL,
  CREATE_NEW_DRAFT_LOADING,
  CREATE_NEW_DRAFT_SUCCESS,
  DELETE_DRAFT_ID,
  DELETE_DRAFT_LOADING,
  FETCH_CONTENT_FAIL,
  FETCH_CONTENT_LOADING,
  FETCH_CONTENT_SUCCESS,
  FETCH_DRAFT_FAIL,
  FETCH_DRAFT_LOADING,
  FETCH_DRAFT_SUCCESS,
  FETCH_LIVE_CONTENT_DATA_FINISHED,
  FETCH_LIVE_CONTENT_DATA_LOADING,
  FETCH_LIVE_CONTENT_DATA_SUCCESS,
  FETCH_LIVE_PAGE_DATA_FINISHED,
  FETCH_LIVE_PAGE_DATA_LOADING,
  FETCH_PAGE_CONTENTS_FAIL,
  FETCH_PAGE_CONTENTS_LOADING,
  FETCH_PAGE_CONTENTS_SUCCESS,
  FETCH_PAGE_LOADING,
  FETCH_PAGE_SUCCESS,
  OPEN_PUBLISH_DIALOG,
  PUBLISH_CANCEL,
  PUBLISH_FAIL,
  PUBLISH_PENDING,
  PUBLISH_SUCCESS,
  RESET_DRAFT,
  RESET_EDITOR_STATE,
  SET_AI_LAST_GENERATION_TYPE,
  SET_AI_THINKING_FINISHED,
  SET_AI_THINKING_STARTED,
  SET_AI_WRITING_FINISHED,
  SET_AI_WRITING_STARTED,
  SET_SELECTION_TEXT,
  UPDATE_DRAFT,
  UPDATE_PAGE,
} from '@/store/actions/actionTypes';

const INITIAL_STATE = {
  content: {},
  createdAt: undefined,
  draft: {},
  draftId: undefined,
  draftIsStarted: false,
  focusTopKeyword: null,
  imageUrl: undefined,
  isAddingDraftToPlanning: false,
  isAiThinking: false,
  isAiWriting: false,
  isContentLoading: false,
  isCreatingNewDraft: false,
  isDraftLoading: false,
  isPageContentsLoading: false,
  isPageLoading: true,
  isPublishDialogOpen: false,
  isPublishPending: false,
  isRefreshLoading: false,
  lastGenerationType: null,
  page: {},
  pageContents: [],
  publishError: null,
  selectionText: '',
  url: '',
  urlPath: '',
  diffContent: {},
};

export default function editor(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case SET_AI_THINKING_STARTED:
      return {
        ...state,
        isAiThinking: true,
      };
    case SET_AI_THINKING_FINISHED:
      return {
        ...state,
        isAiThinking: false,
      };
    case SET_AI_WRITING_STARTED:
      return {
        ...state,
        isAiWriting: true,
      };
    case SET_AI_WRITING_FINISHED:
      return {
        ...state,
        isAiWriting: false,
      };
    case SET_AI_LAST_GENERATION_TYPE:
      return {
        ...state,
        lastGenerationType: payload,
      };
    case FETCH_PAGE_LOADING:
      return {
        ...state,
        isPageLoading: true,
      };
    case FETCH_PAGE_SUCCESS: {
      const {
        contentRetrievedAt,
        createdAt,
        draftId,
        draftIsStarted,
        extractedContentHtml,
        lastStatusCode,
        staticHtml,
        staticHtmlRetrievedAt,
        imageUrl,
        redirectionUrl,
        title,
        url,
        isTracked,
        extra,
        urlPath,
        source,
        metaDescription,
      } = payload;
      return {
        ...state,
        createdAt,
        draftId,
        draftIsStarted,
        imageUrl,
        isPageLoading: false,
        page: {
          ...state.page,
          contentRetrievedAt,
          extra,
          html: extractedContentHtml,
          isTracked,
          lastStatusCode,
          metaDescription,
          redirectionUrl,
          staticHtml,
          staticHtmlRetrievedAt,
          title,
        },
        source,
        url,
        urlPath,
      };
    }
    case FETCH_PAGE_CONTENTS_LOADING:
      return {
        ...state,
        isPageContentsLoading: true,
      };
    case FETCH_PAGE_CONTENTS_SUCCESS: {
      const { pageContents = [] } = payload;
      return {
        ...state,
        isPageContentsLoading: false,
        pageContents,
      };
    }
    case FETCH_PAGE_CONTENTS_FAIL:
      return {
        ...state,
        isPageContentsLoading: false,
      };
    case FETCH_LIVE_PAGE_DATA_LOADING:
      return {
        ...state,
        isRefreshLoading: true,
      };
    case FETCH_LIVE_PAGE_DATA_FINISHED: {
      return {
        ...state,
        isRefreshLoading: false,
      };
    }
    case FETCH_LIVE_CONTENT_DATA_LOADING:
      return {
        ...state,
        isRefreshDraftLoading: true,
      };
    case FETCH_LIVE_CONTENT_DATA_FINISHED: {
      return {
        ...state,
        isRefreshDraftLoading: false,
      };
    }
    case FETCH_LIVE_CONTENT_DATA_SUCCESS: {
      return {
        ...state,
        draft: {
          ...state.draft,
          version: payload.version,
        },
        isRefreshDraftLoading: false,
      };
    }
    case FETCH_DRAFT_LOADING:
      return {
        ...state,
        isDraftLoading: true,
      };
    case FETCH_DRAFT_SUCCESS:
      return {
        ...state,
        draft: payload.draft,
        isDraftLoading: false,
      };
    case FETCH_DRAFT_FAIL:
      return {
        ...state,
        draft: {},
        isDraftLoading: false,
      };
    case FETCH_CONTENT_LOADING:
      return {
        ...state,
        isContentLoading: true,
      };
    case FETCH_CONTENT_SUCCESS:
      return {
        ...state,
        content: payload.content,
        isContentLoading: false,
      };
    case FETCH_CONTENT_FAIL:
      return {
        ...state,
        content: {},
        isContentLoading: false,
      };

    case PUBLISH_PENDING:
      return {
        ...state,
        isPublishPending: true,
        publishError: null,
      };
    case PUBLISH_SUCCESS:
      return {
        ...state,
        draft: {},
        draftId: undefined,
        draftIsStarted: false,
        isPublishDialogOpen: false,
        isPublishPending: false,
      };
    case PUBLISH_FAIL:
      return {
        ...state,
        isPublishDialogOpen: true,
        isPublishPending: false,
        publishError: payload.publishError,
      };
    case PUBLISH_CANCEL:
      return {
        ...state,
        isPublishDialogOpen: false,
        publishError: null,
      };
    case CREATE_NEW_DRAFT_LOADING:
      return {
        ...state,
        isCreatingNewDraft: true,
      };
    case CREATE_NEW_DRAFT_SUCCESS:
      return {
        ...state,
        draft: payload.draft,
        draftId: payload.draft?.id,
        draftIsStarted: true,
        isCreatingNewDraft: false,
      };
    case CREATE_NEW_DRAFT_FAIL:
      return {
        ...state,
        draftId: undefined,
        isCreatingNewDraft: false,
      };
    case DELETE_DRAFT_LOADING:
      return {
        ...state,
        isDeleteDraftLoading: true,
      };
    case ADD_DRAFT_TO_PLANNING_LOADING:
      return {
        ...state,
        isAddingDraftToPlanning: true,
      };
    case ADD_DRAFT_TO_PLANNING_SUCCESS:
      return {
        ...state,
        draft: { ...payload.draft },
        draftId: payload.draft.id,
        draftIsStarted: false,
        isAddingDraftToPlanning: false,
      };
    case ADD_DRAFT_TO_PLANNING_FAIL:
      return {
        ...state,
        isAddingDraftToPlanning: false,
      };
    case DELETE_DRAFT_ID:
      return {
        ...state,
        draft: {},
        draftId: undefined,
        draftIsStarted: false,
      };
    case UPDATE_DRAFT:
      return {
        ...state,
        draft: { ...payload.draft },
      };
    case OPEN_PUBLISH_DIALOG:
      return {
        ...state,
        isPublishDialogOpen: true,
      };
    case UPDATE_PAGE:
      return {
        ...state,
        page: {
          ...state.page,
          contentRetrievedAt: payload.contentRetrievedAt,
          html: payload.html,
          metaDescription: payload.metaDescription,
          redirectionUrl: payload.redirectionUrl,
          staticHtml: payload.staticHtml,
          staticHtmlRetrievedAt: payload.staticHtmlRetrievedAt,
          title: payload.title,
        },
      };
    case SET_SELECTION_TEXT:
      return {
        ...state,
        selectionText: payload,
      };
    case RESET_DRAFT:
      return {
        ...state,
        draft: {},
      };
    case RESET_EDITOR_STATE:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}
