export const SELECT_LIST = 'selectList';
export const DATE_PICKER = 'datePicker';
export const INPUT_TEXT = 'inputText';
export const BOOL_BUTTON = 'boolButton';
export const UNKNOWN_BUTTON = 'unknownButton';
export const KNOWN_BUTTON = 'knownButton';
export const FOLDER = 'folder';

export const NUMBER_TYPE_FILTER = {
  comparisonList: [
    {
      comparison: 'gt',
      labelKey: 'components:filters.greater-than',
      type: INPUT_TEXT,
    },
    {
      comparison: 'lt',
      labelKey: 'components:filters.lower-than',
      type: INPUT_TEXT,
    },
    {
      comparison: 'gte',
      labelKey: 'components:filters.greater-or-equals',
      type: INPUT_TEXT,
    },
    {
      comparison: 'lte',
      labelKey: 'components:filters.lower-or-equals',
      type: INPUT_TEXT,
    },
    {
      comparison: 'eq',
      labelKey: 'components:filters.equals',
      type: INPUT_TEXT,
    },
    {
      comparison: 'not_eq',
      labelKey: 'components:filters.not-equals',
      type: INPUT_TEXT,
    },
    {
      comparison: 'not_exist',
      labelKey: 'components:filters.unknown',
      type: UNKNOWN_BUTTON,
    },
    {
      comparison: 'exist',
      labelKey: 'components:filters.known',
      type: KNOWN_BUTTON,
    },
  ],
  type: 'number',
};

export const STRING_TYPE_FILTER = {
  comparisonList: [
    {
      comparison: 'contains',
      labelKey: 'components:filters.contains',
      type: INPUT_TEXT,
    },
    {
      comparison: 'not_contains',
      labelKey: 'components:filters.not-contains',
      type: INPUT_TEXT,
    },
    {
      comparison: 'eq',
      labelKey: 'components:filters.equals',
      type: INPUT_TEXT,
    },
    {
      comparison: 'not_eq',
      labelKey: 'components:filters.not-equals',
      type: INPUT_TEXT,
    },
  ],
  type: 'string',
};

export const SELECT_TYPE_FILTER = {
  comparisonList: [
    {
      comparison: 'eq',
      labelKey: 'components:filters.is',
      type: SELECT_LIST,
    },
    {
      comparison: 'not_eq',
      labelKey: 'components:filters.is-not',
      type: SELECT_LIST,
    },
  ],
  type: 'select',
};

export const DATE_TYPE_FILTER = {
  comparisonList: [
    {
      comparison: 'eq',
      labelKey: 'components:filters.on',
      type: DATE_PICKER,
    },
    {
      comparison: 'not_eq',
      labelKey: 'components:filters.not-on',
      type: DATE_PICKER,
    },
    {
      comparison: 'after',
      labelKey: 'components:filters.after',
      type: DATE_PICKER,
    },
    {
      comparison: 'before',
      labelKey: 'components:filters.before',
      type: DATE_PICKER,
    },
    {
      comparison: 'eq',
      labelKey: 'components:filters.unknown',
      type: UNKNOWN_BUTTON,
    },
  ],
  type: 'date',
};

export const BOOL_TYPE_FILTER = {
  comparisonList: [
    {
      comparison: 'eq',
      type: BOOL_BUTTON,
    },
    {
      comparison: 'not_eq',
      labelKey: 'components:filters.not',
      type: BOOL_BUTTON,
    },
  ],
  type: 'bool',
};

export const FOLDER_TYPE_FILTER = {
  comparisonList: [
    {
      comparison: 'eq',
      labelKey: 'components:filters.is',
      type: FOLDER,
    },
    {
      comparison: 'not_eq',
      labelKey: 'components:filters.is-not',
      type: FOLDER,
    },
  ],
  type: 'folder',
};

export const OPPORTUNITIES_TAB = 'opportunities';
export const ALL_TAB = 'all';
export const DEFAULT_PRIORITY_SCORE = 'clicks';
