import { AlertAltIcon } from 'semji-core/icons/AlertAltIcon';

import BarChartIcon from '@/components/icons/BarChartIcon';
import CardIcon from '@/components/icons/CardIcon';
import CursorIcon from '@/components/icons/CursorIcon';
import EyeIcon from '@/components/icons/EyeIcon';
import FunnelIcon from '@/components/icons/FunnelIcon';
import LinkIcon from '@/components/icons/LinkIcon';
import MoneyIcon from '@/components/icons/MoneyIcon';
import PlanIcon from '@/components/icons/PlanIcon';
import SearchIcon from '@/components/icons/SearchIcon';
import SmallCapIcon from '@/components/icons/SmallCapIcon';
import TextIcon from '@/components/icons/TextIcon';
import UpdatedIcon from '@/components/icons/UpdatedIcon';
import WarningTriangleIcon from '@/components/icons/WarningTriangleIcon';
import { BOOL_TYPE_FILTER, NUMBER_TYPE_FILTER, STRING_TYPE_FILTER } from '@/utils/filter/constants';

export const FILTERS_LIST = [
  {
    attribute: 'title',
    icon: <SmallCapIcon opacity={0.6} />,
    labelKey: 'listing:pages-list.filters-panel.title',
    type: STRING_TYPE_FILTER,
    uid: 'title',
  },
  {
    attribute: 'url',
    icon: <LinkIcon opacity={0.6} />,
    labelKey: 'listing:pages-list.filters-panel.url',
    type: STRING_TYPE_FILTER,
    uid: 'url',
  },
  {
    attribute: 'monthlyClicks',
    icon: <CursorIcon opacity={0.6} />,
    labelKey: 'listing:pages-list.filters-panel.clicks',
    mappingValues: (value) => ({
      labelKey: value === true ? '' : value,
      value,
    }),
    type: NUMBER_TYPE_FILTER,
    uid: 'monthlyClicks',
  },
  {
    attribute: 'monthlyConversions',
    icon: <FunnelIcon opacity={0.6} />,
    labelKey: 'listing:pages-list.filters-panel.conversions',
    mappingValues: (value) => ({
      labelKey: value === true ? '' : value,
      value,
    }),
    type: NUMBER_TYPE_FILTER,
    uid: 'monthlyConversions',
  },
  {
    attribute: 'monthlyTransactions',
    icon: <CardIcon opacity={0.6} />,
    labelKey: 'listing:pages-list.filters-panel.transactions',
    mappingValues: (value) => ({
      labelKey: value === true ? '' : value,
      value,
    }),
    type: NUMBER_TYPE_FILTER,
    uid: 'monthlyTransactions',
  },
  {
    attribute: 'monthlyRevenue',
    icon: <MoneyIcon opacity={0.6} />,
    labelKey: 'listing:pages-list.filters-panel.revenue',
    mappingValues: (value) => ({
      labelKey: value === true ? '' : value,
      value,
    }),
    type: NUMBER_TYPE_FILTER,
    uid: 'monthlyRevenue',
  },
  {
    attribute: 'keyword',
    icon: <TextIcon opacity={0.6} />,
    labelKey: 'listing:pages-list.filters-panel.keyword',
    type: STRING_TYPE_FILTER,
    uid: 'keyword',
  },
  {
    attribute: 'position',
    icon: <BarChartIcon opacity={0.6} />,
    labelKey: 'listing:pages-list.filters-panel.average-position',
    mappingValues: (value) => ({
      labelKey: value === true ? '' : value,
      value,
    }),
    type: NUMBER_TYPE_FILTER,
    uid: 'position',
  },
  {
    attribute: 'searchVolume',
    icon: <SearchIcon opacity={0.6} />,
    labelKey: 'listing:pages-list.filters-panel.search-volume',
    mappingValues: (value) => ({
      labelKey: value === true ? '' : value,
      value,
    }),
    type: NUMBER_TYPE_FILTER,
    uid: 'searchVolume',
  },
  {
    attribute: 'contentScore',
    getValueByAttribute: (list) => (list.contentScore === null ? null : list.contentScore * 100),
    icon: <EyeIcon opacity={0.6} />,
    labelKey: 'listing:pages-list.filters-panel.content-score',
    mappingValues: (value) => ({
      labelKey: value === true ? '' : value,
      value,
    }),
    type: NUMBER_TYPE_FILTER,
    uid: 'contentScore',
  },
  {
    attribute: 'draftId',
    getValueByAttribute: (list) => !!list.draftId,
    icon: <PlanIcon opacity={0.6} />,
    labelKey: 'listing:pages-list.filters-panel.planning.title',
    mappingValues: (value) => ({
      labelKey: 'listing:pages-list.filters-panel.planning.label',
      value,
    }),
    placeholder: 'listing:pages-list.filters-panel.planning.placeholder',
    type: BOOL_TYPE_FILTER,
    uid: 'draftId',
  },
  {
    attribute: 'isUpdated',
    getValueByAttribute: (list) => list?.extra?.publicationsCount > 0,
    icon: <UpdatedIcon opacity={0.6} />,
    labelKey: 'listing:pages-list.filters-panel.update.title',
    mappingValues: (value) => ({
      labelKey: 'listing:pages-list.filters-panel.update.label',
      value,
    }),
    placeholder: 'listing:pages-list.filters-panel.update.placeholder',
    type: BOOL_TYPE_FILTER,
    uid: 'isUpdated',
  },
  {
    attribute: 'lastStatusCode',
    icon: <AlertAltIcon opacity={0.6} />,
    labelKey: 'listing:pages-list.filters-panel.http-code',
    mappingValues: (value) => ({
      labelKey: value === true ? '' : value,
      value,
    }),
    type: NUMBER_TYPE_FILTER,
    uid: 'lastStatusCode',
  },
  {
    attribute: 'isCannibalized',
    getValueByAttribute: (list) => {
      const hasMultipleAssociatedPages =
        list?.focusTopKeyword?.associatedToPagesCount > 1 ||
        (list?.focusTopKeyword?.associatedToDraftsCount > 1 &&
          list?.focusTopKeyword?.associatedToPagesCount > 0);
      const hasMultipleAssociatedDrafts =
        list?.focusTopKeyword?.associatedToDraftsCount > 1 ||
        (list?.focusTopKeyword?.associatedToPagesCount > 1 &&
          list?.focusTopKeyword?.associatedToDraftsCount > 0);
      return hasMultipleAssociatedDrafts || hasMultipleAssociatedPages;
    },
    icon: <WarningTriangleIcon opacity={0.6} />,
    labelKey: 'listing:pages-list.filters-panel.cannibalization.title',
    mappingValues: (value) => ({
      labelKey: 'listing:pages-list.filters-panel.cannibalization.label',
      value,
    }),
    placeholder: 'listing:pages-list.filters-panel.cannibalization.placeholder',
    type: BOOL_TYPE_FILTER,
    uid: 'isCannibalized',
  },
];
