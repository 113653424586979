import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import FilesIcon from '@/components/icons/FilesIcon';

const Wrapper = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 13px;
  color: ${({ theme }) => theme.cssColors.primary};
  margin-left: 10px;
  cursor: pointer;

  svg {
    margin-right: 5px;
  }
`;

function CopyContent({ copyToClipboard }) {
  const { t } = useTranslation();

  return (
    <Wrapper onClick={copyToClipboard}>
      <FilesIcon /> {t('content:editor-container.copy-content')}
    </Wrapper>
  );
}

export default CopyContent;
