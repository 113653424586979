import { DateTime } from 'luxon';

import {
  FOCUS_TOP_KEYWORDS_31_PLUS,
  METRIC_TYPE_PERCENT,
  periodicities,
} from '@/utils/metrics/constants';

function buildSortedSeries({
  metrics,
  selectedMetric,
  selectedMetricKey,
  gapDuration,
  periodicity,
  keepNullValues = false,
}) {
  function safeMultiply(value, multiplier, index) {
    if (keepNullValues && index > 0 && index < metrics.length - 1) {
      if (value === null || value === undefined) return null;
      return value * multiplier;
    }
    // For the first and last values, we don't want to keep null values
    return value * multiplier || 0;
  }
  return metrics
    .map((metric, index) => {
      // Specific case for top 11+ which is sum of 2 fields
      const metricValue =
        selectedMetricKey === FOCUS_TOP_KEYWORDS_31_PLUS
          ? metric.focusTopKeywordsPositionTop100 + metric.focusTopKeywordsPositionBeyondTop100
          : metric[selectedMetricKey];

      return {
        publicationsCount: metric['publicationsCount'],
        realX: DateTime.fromISO(metric.date, { zone: 'utc' })
          .startOf(periodicity ? periodicities[periodicity].luxonReference : 'day')
          .toMillis(),
        x: DateTime.fromISO(metric.date, { zone: 'utc' })
          .plus(gapDuration ?? { days: 0 }) // the gapDuration is needed when we need to compare data and make a horizontal translation
          .startOf(periodicity ? periodicities[periodicity].luxonReference : 'day') // switch to start of periodicity after translation else we stay in the same day
          .toMillis(),
        y:
          selectedMetric.type === METRIC_TYPE_PERCENT
            ? safeMultiply(metricValue, 100, index)
            : metricValue,
      };
    })
    .sort((a, b) => a.x - b.x);
}

export function forgeSparklinesMetricsSeries({
  forceSelectedPositionKey,
  selectedMetric,
  metrics,
  keepNullValues,
}) {
  return [
    {
      aggregateMetrics: buildSortedSeries({
        keepNullValues,
        metrics,
        selectedMetric,
        selectedMetricKey: forceSelectedPositionKey ?? selectedMetric.key,
      }),
      key: forceSelectedPositionKey ?? selectedMetric.key,
      metric: selectedMetric,
    },
  ];
}

export function forgeMetricsSeries({
  displayedMetrics,
  metrics,
  previousMetrics = [],
  gapDuration,
  periodicity,
  forceSelectedPositionKey,
  keepNullValues = false,
}) {
  return Object.keys(displayedMetrics).map((selectedMetricKey) => {
    const selectedMetric = displayedMetrics[selectedMetricKey];

    const aggregatePositionMetrics = selectedMetric[forceSelectedPositionKey]?.map((metric) =>
      buildSortedSeries({ metrics, selectedMetric, selectedMetricKey: metric.key })
    );

    return {
      aggregateMetrics: buildSortedSeries({
        keepNullValues,
        metrics,
        selectedMetric,
        selectedMetricKey: forceSelectedPositionKey ?? selectedMetricKey,
      }),
      aggregatePositionMetrics,
      aggregatePreviousMetrics: buildSortedSeries({
        gapDuration,
        keepNullValues,
        metrics: previousMetrics,
        periodicity,
        selectedMetric,
        selectedMetricKey: forceSelectedPositionKey ?? selectedMetricKey,
      }),
      key: forceSelectedPositionKey ?? selectedMetricKey,
      metric: selectedMetric,
    };
  });
}
