import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { FlexContainer } from '@/components/_common';

function DraggableComponent({ onChange, items }) {
  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }
    onChange(reorder(items, result.source.index, result.destination.index));
  }

  function reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }

  // Inspiration https://github.com/atlassian/react-beautiful-dnd/issues/410
  //https://github.com/atlassian/react-beautiful-dnd/issues/1872
  function onMouseDown(event) {
    event.currentTarget.focus();
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={`droppable`}>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <FlexContainer
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    isDragging={snapshot.isDragging}
                    style={{ ...provided.draggableProps.style }}
                    onMouseDown={onMouseDown}
                  >
                    <item.Content key={`${item.id}_item_content`} />
                  </FlexContainer>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default DraggableComponent;
