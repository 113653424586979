import { useTranslation } from 'react-i18next';

import TitleCreditIcon from '@/components/icons/TitleCreditIcon';
import { TITLE_REPORT_TYPE } from '@/containers/Content/BriefComponents/constants';
import CopyContentButton from '@/containers/Content/BriefComponents/CopyContentButton';
import FlagContentButton from '@/containers/Content/BriefComponents/FlagContentButton';
import GenerateButton from '@/containers/Content/BriefComponents/GenerateButton';
import RedInfoIcon from '@/containers/Content/BriefComponents/RedInfoIcon';
import RemoveContentButton from '@/containers/Content/BriefComponents/RemoveContentButton';
import ActionsColumn from '@/containers/Content/BriefComponents/Table/Columns/ActionsColumn';
import Column from '@/containers/Content/BriefComponents/Table/Columns/Column';
import PrimaryColumn from '@/containers/Content/BriefComponents/Table/Columns/PrimaryColumn';
import Header from '@/containers/Content/BriefComponents/Table/Rows/Header';
import Row from '@/containers/Content/BriefComponents/Table/Rows/Row';
import WarningRow from '@/containers/Content/BriefComponents/Table/Rows/WarningRow';
import Table from '@/containers/Content/BriefComponents/Table/Table';
import WaitLabel from '@/containers/Content/BriefComponents/WaitLabel';
import useApiConfigurations from '@/hooks/useApiConfigurations';
import { AI_WRITING_CREDITS_BRIEF_TITLE } from '@/utils/configurations/constants';

function TitleIdeas({
  isLimited,
  titles,
  onFlagContent,
  onDeleteContent,
  onGenerate,
  areGeneratedTitlesSafe,
  safeForGeneratingTitles,
}) {
  const { t } = useTranslation();

  const cantGenerateTitleMessage =
    false === areGeneratedTitlesSafe && titles.length > 0
      ? t('content:brief.generated-removed-due-to-sensitive-content-warning')
      : false === safeForGeneratingTitles || false === areGeneratedTitlesSafe
        ? t('content:brief.generated-unable-due-to-sensitive-content-warning')
        : null;

  const briefTitleCredits = useApiConfigurations(AI_WRITING_CREDITS_BRIEF_TITLE);

  return (
    <Table>
      <Header>
        <PrimaryColumn>{t('content:brief.section-title-table-suggestions-header')}</PrimaryColumn>
        <Column>
          {isLimited ? (
            <WaitLabel />
          ) : (
            <GenerateButton
              creditAmount={briefTitleCredits}
              creditIcon={<TitleCreditIcon />}
              hasItems={titles.length > 0}
              onClick={onGenerate}
            />
          )}
        </Column>
      </Header>

      {null !== cantGenerateTitleMessage && (
        <WarningRow key={`error`}>
          <PrimaryColumn>
            <RedInfoIcon bordered /> {cantGenerateTitleMessage}
          </PrimaryColumn>
        </WarningRow>
      )}

      {titles.map(({ title, id }) => (
        <Row key={id}>
          <PrimaryColumn>{title}</PrimaryColumn>
          <ActionsColumn>
            <RemoveContentButton onClick={() => onDeleteContent(id)} />
            <FlagContentButton
              onClick={() => onFlagContent({ id, text: title, type: TITLE_REPORT_TYPE })}
            />
            <CopyContentButton contentToCopy={title} />
          </ActionsColumn>
        </Row>
      ))}
    </Table>
  );
}

export default TitleIdeas;
