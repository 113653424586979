export function sortFoldersByParents(options = []) {
  const parentReducer = options.reduce((acc, item) => {
    if (!item.parentFolderId) {
      return {
        ...acc,
        [item.id]: !!acc[item.id] ? [item, ...acc[item.id]] : [item],
      };
    }

    return acc;
  }, {});

  const reducer = options.reduce((acc, item) => {
    if (item.parentFolderId) {
      return {
        ...acc,
        [item.parentFolderId]: !!acc[item.parentFolderId]
          ? [...acc[item.parentFolderId], item]
          : [item],
      };
    }

    return acc;
  }, parentReducer);

  return Object.values(reducer).flat(2);
}
