import { enUS as en, es, fr, it } from 'date-fns/locale';
import i18next from 'i18next';
import { Settings } from 'luxon';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { DEFAULT_BACKGROUND, NO_MENU_LAYOUT } from '@/components/Layout/Layout';
import { LayoutLoader } from '@/components/Loader/Loader';
import { ReloadPrompt } from '@/components/ReloadPrompt';
import CancelPlan from '@/containers/Billing/CancelPlan';
import UpgradePlanModal from '@/containers/Billing/UpgradePlanModal';
import Register from '@/containers/CreateAccount/Register';
import SignUp from '@/containers/CreateAccount/SignUp';
import VerifyEmail from '@/containers/CreateAccount/VerifyEmail';
import CreateOrganization from '@/containers/CreateOrganization';
import LayoutWrapper from '@/containers/Layout/Layout';
import ForgotPassword from '@/containers/Login/ForgotPassword';
import Login from '@/containers/Login/Login';
import LoginSso from '@/containers/Login/LoginSso';
import Logout from '@/containers/Login/Logout';
import ResetPassword from '@/containers/Login/ResetPassword';
import DefaultOrganization from '@/containers/Organization/DefaultOrganization';
import Organization from '@/containers/Organization/Organization';
import PostMessage from '@/containers/PostMessage';
import ProfileSettings from '@/containers/Settings/Profile';
import UserImpersonate from '@/containers/User/UserImpersonate';
import Can from '@/hoc/Can';
import { fetchAuthenticatedUser, logoutUser } from '@/store/actions/user';
import { initChargebee } from '@/utils/chargebeeScript';
import {
  COOKIE_IMPERSONATE_KEY_NAME,
  COOKIE_TOKEN_KEY_NAME,
  getCookie,
} from '@/utils/cookies/cookies';
import { pushAuthenticatedUserToGoogleAnalytics } from '@/utils/googleTagManager';
import { SECTIONS } from '@/utils/log/constants';
import { Log } from '@/utils/log/Log';

function Authentication() {
  const dispatch = useDispatch();
  const userLoggedIn = useSelector((state) => state.user.userLoggedIn);
  const [state, setState] = useState({
    isReady: false,
    redirect: null,
  });
  const { pathname } = useLocation();

  const locales = [en, fr, es, it];
  const locale = locales.find((locale) => locale.code === i18next.language) || en;

  useEffect(() => {
    Settings.defaultLocale = locale.code;
  }, [locale]);

  const authenticateUser = async () => {
    try {
      const user = await dispatch(fetchAuthenticatedUser());
      pushAuthenticatedUserToGoogleAnalytics(user);
      setState({ isReady: true, redirect: pathname });
    } catch (error) {
      dispatch(logoutUser());
      setState({ isReady: true });
      Log.report({
        context: 'componentDidMount',
        error,
        extra: 'Get the current connected user',
        section: SECTIONS.authentication.key,
      });
    }
  };

  useEffect(() => {
    // Initialize chargebee.js
    initChargebee();

    const token = getCookie(COOKIE_TOKEN_KEY_NAME);

    if (token) {
      authenticateUser();
    } else {
      setState({
        isReady: true,
        redirect: pathname,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const userImpersonate = getCookie(COOKIE_IMPERSONATE_KEY_NAME);
    if (userImpersonate && state.redirect !== '/login') {
      setState({ isReady: true, redirect: '/login' });
    }
  }, [pathname, state.redirect]);

  if (!state.isReady) {
    return (
      <LayoutWrapper menu={NO_MENU_LAYOUT} variant={DEFAULT_BACKGROUND}>
        <LayoutLoader />
      </LayoutWrapper>
    );
  }

  if (userLoggedIn) {
    return (
      <>
        <ReloadPrompt />
        <Routes>
          <Route element={<Navigate to="/" />} path="/login" />
          <Route element={<Logout />} path="/logout" />
          <Route
            // THIS IS AN URL DECLARED IN THE GOOGLE OAUTH
            element={<PostMessage />}
            path="/onboarding/notify-google-consent"
          />
          <Route element={<UserImpersonate />} path="/impersonate/:email/*" />
          <Route
            element={
              <Can
                no={() => <Navigate to="/" />}
                perform={'create-organization:self-serve'}
                yes={() => <CreateOrganization />}
              />
            }
            path="/create-organization"
          />
          <Route element={<ProfileSettings />} path="/settings/:tab" />
          <Route element={<Navigate to="/settings/user-profile" />} path="/settings" />
          <Route element={<Organization />} path="/o/:organizationId/*" />
          <Route element={<DefaultOrganization />} path="*" />
        </Routes>
        <UpgradePlanModal />
        <CancelPlan />
      </>
    );
  }

  return (
    <Routes>
      <Route element={<Register />} path="/register/:registrantToken" />
      <Route element={<SignUp />} path="/register" />
      <Route element={<VerifyEmail />} path="/verify-email/:registrantToken" />
      <Route
        element={<Login redirectTo={state.redirect !== '/logout' ? state.redirect : null} />}
        path="/login"
      />
      <Route element={<LoginSso />} path="/sso" />
      <Route element={<ForgotPassword />} path="/forgot-password" />
      <Route element={<ResetPassword />} path="/reset-password/:resetToken" />
      <Route element={<PostMessage />} path="/notify-sso-login" />
      <Route element={<Navigate to="/login" />} path="*" />
    </Routes>
  );
}

export default memo(Authentication);
