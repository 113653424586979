import { BRANDED_TRAFFIC } from '@/containers/Competitors/components/HeaderFilters/Filters/BrandTrafficFilter/const';
import { LONG_TAIL_TRAFFIC } from '@/containers/Competitors/components/HeaderFilters/Filters/TailTrafficFilter/const';
import {
  BRAND_TRAFFIC_TYPE_FILTER_QUERY_PARAM,
  CATEGORY_FILTER_QUERY_PARAM,
  CLUSTER_FILTER_QUERY_PARAM,
  FIRST_SEEN_DATE_FILTER_QUERY_PARAM,
  FUNNEL_STAGE_FILTER_QUERY_PARAM,
  PERSONA_FILTER_QUERY_PARAM,
  RETRIEVAL_DATE_FILTER_QUERY_PARAM,
  TAIL_TRAFFIC_TYPE_FILTER_QUERY_PARAM,
  URL_POSITION_FILTER_QUERY_PARAM,
} from '@/containers/Competitors/utils/constants';
import { IFilter, UseFiltersResults } from '@/hooks/useFilters/useFilters.types';
import { CompetitorTopPagesFilters } from '@/types/competitors';

export class CompetitorDrillUtils {
  static openDrilledDataExplorer({
    baseUrl,
    filterHook,
  }: {
    baseUrl: string;
    filterHook: UseFiltersResults<CompetitorTopPagesFilters>;
  }) {
    return function (
      e: any,
      serieKey: string,
      customFilter: IFilter<CompetitorTopPagesFilters> = {}
    ) {
      const competitorId = e?.point?.custom?.competitorId;
      const value = e?.point?.custom?.type;
      const filterCompetitor: IFilter<CompetitorTopPagesFilters> = competitorId
        ? { competitorWebsiteId: { multi_search: [competitorId] } }
        : {};
      const filterDeep: IFilter<CompetitorTopPagesFilters> = CompetitorDrillUtils.getDrillFilter(
        serieKey,
        value
      );
      window.open(
        `${baseUrl}?${filterHook.buildStringifiedFilters({ ...filterHook.filters, ...filterCompetitor, ...filterDeep, ...customFilter }, { encoded: true })}`,
        '_blank'
      );
    };
  }
  static getDrillFilter(serieKey: string, value: any): IFilter<CompetitorTopPagesFilters> {
    if (!serieKey || !value) return {};
    switch (serieKey) {
      case BRAND_TRAFFIC_TYPE_FILTER_QUERY_PARAM: {
        return {
          isBranded: {
            boolean: value === BRANDED_TRAFFIC,
          },
        };
      }
      case CATEGORY_FILTER_QUERY_PARAM: {
        return {
          category: {
            multi_search: [value],
          },
        };
      }
      case CLUSTER_FILTER_QUERY_PARAM: {
        return {
          cluster: {
            multi_search: [value],
          },
        };
      }
      case TAIL_TRAFFIC_TYPE_FILTER_QUERY_PARAM: {
        return {
          isLongTail: {
            boolean: value === LONG_TAIL_TRAFFIC,
          },
        };
      }
      case PERSONA_FILTER_QUERY_PARAM: {
        return {
          persona: {
            multi_search: [value],
          },
        };
      }
      case FUNNEL_STAGE_FILTER_QUERY_PARAM: {
        return {
          funnel: {
            multi_search: [value],
          },
        };
      }
      case RETRIEVAL_DATE_FILTER_QUERY_PARAM: {
        return {
          date: {
            equals: value,
          },
        };
      }
      case URL_POSITION_FILTER_QUERY_PARAM: {
        const ranges = {
          top10: [4, 10],
          top100: [11, 100],
          top3: [1, 3],
        };
        const targetRange = ranges[value as keyof typeof ranges];
        return {
          urlPosition: {
            gte: targetRange[0],
            lte: targetRange[1],
          },
        };
      }
      case FIRST_SEEN_DATE_FILTER_QUERY_PARAM: {
        return {
          firstSeenAt: {
            equals: value,
          },
        };
      }
      default:
        return {};
    }
  }
}
