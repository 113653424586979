import { useTranslation } from 'react-i18next';

import BellIcon from '@/components/icons/BellIcon';
import HeaderButton from '@/components/Layout/HeaderButton';
import { useMixpanelTrackEvent } from '@/hooks/useMixpanelTrackEvent';
import { WHATS_NEW_TOP_NAV_CLICK } from '@/utils/3rdParty/Mixpanel/constants';

const BEAMER_ID = 'what-is-new-button';

function WhatsNewButton() {
  const { t } = useTranslation();
  const trackMixpanelEvent = useMixpanelTrackEvent();

  return (
    <HeaderButton
      aria-haspopup="true"
      aria-label={t('layout:whats-new-button.label')}
      onClick={() => trackMixpanelEvent(WHATS_NEW_TOP_NAV_CLICK)}
      id={BEAMER_ID}
      // Display will be handled by GTM: https://gitlab.rvip.fr/semji/semji/-/issues/4654
      style={{ display: window[`is_beamer_enabled`] ? 'flex' : 'none' }}
    >
      <BellIcon />
      <span title={t('layout:whats-new-button.label')}>{t('layout:whats-new-button.label')}</span>
    </HeaderButton>
  );
}

export default WhatsNewButton;
