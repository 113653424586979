import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OptionButton } from 'semji-core/components/OptionButton';
import { MoreIcon } from 'semji-core/icons/MoreIcon';

import {
  EXPORT_CHART_PNG,
  EXPORT_CHART_SVG,
  ExportOptionKeyType,
} from '@/containers/Competitors/components/ExportOptionButton/ExportOptionButton.types';
import useAuth from '@/pages/Content/Hooks/useAuth';
import { LogContext } from '@/providers/LogProvider';

export function ExportOptionButton({
  chartRef,
  setExportImage,
  exportImage,
}: {
  chartRef: any;
  setExportImage: any;
  exportImage: boolean;
}) {
  const { t } = useTranslation();
  const { report } = useContext(LogContext);
  const [exportParams, setExportParams] = useState({ e: null, key: '' });
  const { e, key } = exportParams;
  useEffect(() => {
    async function exportChart() {
      if (!exportImage) return;

      if (e && key && chartRef) {
        try {
          switch (key) {
            case EXPORT_CHART_PNG:
              await chartRef.current.chart.exportChart();
              break;
            case EXPORT_CHART_SVG:
              await chartRef.current.chart.exportChart({ type: 'image/svg+xml' });
              break;
            default:
              break;
          }
        } catch (error) {
          report(error, {
            chart: chartRef.current.chart,
            errorName: 'EXPORT_IMAGE_ERROR',
            key,
          });
        } finally {
          setExportImage(false);
        }
      }
    }

    exportChart();
  }, [exportImage, key, chartRef]);

  async function handleExport(e: any, key: ExportOptionKeyType) {
    setExportImage(true);
    setExportParams({ e, key });
  }
  return (
    <OptionButton
      dropdownWidth="210px"
      enabled={true}
      icon={<MoreIcon />}
      optionGroups={[
        {
          items: [
            {
              checkable: false,
              checked: false,
              dropDrownActionsRef: null,
              onClick: (event: any) => handleExport(event, EXPORT_CHART_PNG),
              switchPosition: 'start',
              title: t('competitors:export-options.download-png'),
            },
            {
              checkable: false,
              checked: false,
              dropDrownActionsRef: null,
              onClick: (event: any) => handleExport(event, EXPORT_CHART_SVG),
              switchPosition: 'start',
              title: t('competitors:export-options.download-svg'),
            },
          ],
        },
      ]}
    />
  );
}
