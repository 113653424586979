import './WorkspaceSelector.scss';

import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ENUM_INPUT_VARIANT, SearchInput } from 'semji-core/components/Search';
import { Tooltip } from 'semji-core/components/Tooltip';

import SearchIcon from '@/components/icons/SearchIcon';
import { Divider } from '@/components/Profile/ProfileBox';
import { AddWorkspace, WorkspaceItem } from '@/components/Profile/WorkspacesList';
import useCan from '@/hooks/useCan';
import { useMixpanelTrackEvent } from '@/hooks/useMixpanelTrackEvent';
import { Workspace } from '@/types/workspace';
import {
  ADD_WORKSPACE_TOP_NAV_CLICK,
  SELECT_WORKSPACE_TOP_NAV_CLICK,
} from '@/utils/3rdParty/Mixpanel/constants';

export default function WorkspaceSelector({
  maxAllowedWorkspacesReached,
  onClose,
  workspaceId,
  organizationId,
  workspaceAccesses,
  setMaxWorkspacesAchievedShow,
  organizationWorkspaces,
}: {
  maxAllowedWorkspacesReached: boolean;
  onClose: () => void;
  workspaceId: string;
  organizationId: string;
  workspaceAccesses: any;
  setMaxWorkspacesAchievedShow: any;
  organizationWorkspaces: any;
}) {
  const [searchValue, setSearchValue] = useState('');
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const { t } = useTranslation();
  const ITEM_HEIGHT = 42;
  const CONTAINER_BORDER_HEIGHT = 1;
  const MIN_CONTAINER_HEIGHT = ITEM_HEIGHT * 6;
  const workspaceRouteHistory = useSelector((state) => state.workspaceRouteHistory);
  const canCreateWorkspace = useCan({ perform: 'organization-admin-settings:visit' });
  const currentUserOrganizationWorkspaces: Workspace[] = useMemo(
    () =>
      organizationWorkspaces.filter((workspace) => {
        return !!workspaceAccesses.find((wa) => wa.workspace.id === workspace.id);
      }),
    [organizationWorkspaces, workspaceAccesses]
  );
  const trackMixpanelEvent = useMixpanelTrackEvent();

  function getWorkspaceUrl(workspace: Workspace) {
    return (
      workspaceRouteHistory
        ?.replace(/\/o\/\w+\//, `/o/${organizationId}/`)
        ?.replace(/\/w\/\w+\//, `/w/${workspace?.id}/`) || `/o/${organizationId}/w/${workspace?.id}`
    );
  }

  const filteredWorkspaces = currentUserOrganizationWorkspaces.filter(
    (wa) =>
      wa.websiteUrl.includes(searchValue.toLowerCase().trim()) ||
      wa.name.toLowerCase().includes(searchValue.toLowerCase().trim())
  );

  const containerMaxHeight = Math.min(
    MIN_CONTAINER_HEIGHT + CONTAINER_BORDER_HEIGHT,
    (canCreateWorkspace ? 2 : 1) * ITEM_HEIGHT +
      CONTAINER_BORDER_HEIGHT +
      Math.min(4, currentUserOrganizationWorkspaces.length) * ITEM_HEIGHT
  );

  function handleSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchValue(e.target.value);
  }

  function handleSearchOpen() {
    setIsSearchOpen(true);
  }

  return (
    <div
      className="workspace-selector"
      style={{
        height: `${containerMaxHeight}px`,
      }}
    >
      <Divider />
      <div
        className={`workspace-selector__header ${isSearchOpen && 'workspace-selector__header--searching'}`}
      >
        <div className="workspace-selector__header__left">
          {isSearchOpen ? (
            <SearchInput
              autoFocus
              className="workspace-selector__header__left__search-input"
              closable={false}
              data-intercom-target="workspace-selector-search-input"
              handleSearchChange={handleSearchChange}
              searchValue={searchValue}
              titleTooltipCloseIcon={t('layout:profile.search-delete-tooltip')}
              translations={{
                search_placeholder: t('layout:profile.search-workspace'),
              }}
              variant={ENUM_INPUT_VARIANT.Borderless}
              onReset={() => setSearchValue('')}
            />
          ) : (
            <div className="workspace-selector__header__left__title">
              {t('layout:profile.workspaces')}
            </div>
          )}
        </div>
        <div className="workspace-selector__header__right">
          <Tooltip hide={isSearchOpen} title={t(`layout:profile.search`)}>
            {!isSearchOpen && (
              <SearchIcon
                className="workspace-selector__header__icon"
                data-intercom-target="workspace-selector-search-icon"
                onClick={handleSearchOpen}
              />
            )}
          </Tooltip>
        </div>
      </div>
      <div className="workspace-selector__list">
        {filteredWorkspaces.map((workspace) => (
          <WorkspaceItem
            key={workspace.id}
            className="workspace-selector__item"
            selected={workspaceId === workspace?.id}
            settingsUrl={`/o/${organizationId}/w/${workspace?.id}/settings`}
            url={getWorkspaceUrl(workspace)}
            workspace={workspace}
            onClick={() => {
              trackMixpanelEvent(SELECT_WORKSPACE_TOP_NAV_CLICK);
              onClose();
            }}
          />
        ))}
      </div>
      <AddWorkspace
        className="workspace-selector__item"
        url={maxAllowedWorkspacesReached ? '' : `/o/${organizationId}/create-workspace`}
        onClick={(e) => {
          if (maxAllowedWorkspacesReached) {
            setMaxWorkspacesAchievedShow(true);
          }
          trackMixpanelEvent(ADD_WORKSPACE_TOP_NAV_CLICK);
          onClose();
        }}
      />
    </div>
  );
}
