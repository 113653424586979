export class ContentUtils {
  private static emptyHTML = '<p><br></p>';

  // Function that will return a valid empty HTML string <p><br></p>
  // Must be the only func used to init editor
  static createEmptyHTML(): string {
    return this.emptyHTML;
  }

  // Check if html is equals to <p><br></p>
  static isEmptyHTML(html: string): boolean {
    return html === this.emptyHTML;
  }
}
