import { withTheme } from 'styled-components/macro';

import defaultTheme from '@/themes/defaultTheme';

export default withTheme(({ value, successMonoColor, errorMonoColor, children }) => {
  const { color, secondaryColor } = getColorForPercentageValue({
    errorMonoColor,
    successMonoColor,
    value,
  });

  return children({ color, secondaryColor });
});

export function getColorForPercentageValue({ value, successMonoColor, errorMonoColor }) {
  let color;
  let secondaryColor;

  if (successMonoColor) {
    color = defaultTheme.cssColors.lightGreen;
    secondaryColor = defaultTheme.cssColors.lightGreen;

    return {
      color,
      secondaryColor,
    };
  }

  if (errorMonoColor) {
    color = defaultTheme.cssColors.primary;
    secondaryColor = defaultTheme.cssColors.primary;

    return {
      color,
      secondaryColor,
    };
  }

  if (value < 25) {
    color = defaultTheme.cssColors.red;
    secondaryColor = defaultTheme.cssColors.red;
  } else if (value < 50) {
    color = defaultTheme.cssColors.salmon;
    secondaryColor = defaultTheme.cssColors.salmon;
  } else if (value < 75) {
    color = defaultTheme.cssColors.yellow;
    secondaryColor = defaultTheme.cssColors.yellow;
  } else {
    color = defaultTheme.cssColors.lightGreen;
    secondaryColor = defaultTheme.cssColors.lightGreen;
  }

  return {
    color,
    secondaryColor,
  };
}
