import { useCallback, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';

function ScrollBar({
  autoHide = true,
  customViewStyle = {},
  disabled = false,
  customTrackStyle = {},
  weight = '10px',
  ...props
}) {
  const [isHovered, setIsHovered] = useState(false);

  const hoverStyle = isHovered ? { opacity: 1 } : { opacity: 0 };

  const renderView = useCallback(
    ({ style, ...props }) => <div {...props} style={{ ...style, ...customViewStyle }} />,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const renderTrackHorizontal = useCallback(
    ({ style, ...props }) => (
      <div
        {...props}
        className="scrollbarTrackHorizontal"
        style={{
          ...style,
          ...customTrackStyle,
          height: weight,
          ...hoverStyle,
        }}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isHovered]
  );

  const renderTrackVertical = useCallback(
    ({ style, ...props }) => (
      <div
        {...props}
        className="scrollbarTrackVertical"
        style={{ ...style, ...customTrackStyle, width: weight, ...hoverStyle }}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isHovered]
  );

  function handleMouseEnter() {
    setIsHovered(true);
  }

  function handleMouseLeave() {
    setIsHovered(false);
  }

  return (
    <Scrollbars
      {...props}
      autoHide={autoHide}
      autoHideDuration={200}
      autoHideTimeout={800}
      renderTrackHorizontal={renderTrackHorizontal}
      renderTrackVertical={renderTrackVertical}
      renderView={renderView}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    />
  );
}

export default ScrollBar;
