import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { FlexGrowContainer, StyledCard } from '@/components/_common';
import CreateDraft from '@/components/icons/CreateDraft';
import { ButtonLikeLink, PrimaryLink } from '@/components/Navigation/Link';
import { ParagraphText } from '@/components/Text/Paragraph';
import { TitleTwo } from '@/components/Text/Title';
import { SUPPORT_ADDRESS_MAIL } from '@/utils/constants';

const BodyWrapper = styled(StyledCard)`
  flex-direction: column;
  align-items: center;
  padding: 5em 3em;
  background: ${(props) => props.theme.colors.brightestGreyOpaque};
  max-width: 700px;
  margin: auto;
`;

const WorkspaceLimitReached = () => {
  const { t } = useTranslation();

  return (
    <FlexGrowContainer>
      <BodyWrapper>
        <CreateDraft />
        <TitleTwo align="center" weight="strong">
          {t('empty-state:workspace-limit-reached.maximum-number-workspace')}
        </TitleTwo>
        <ParagraphText align="center" color="dark060" weight="medium">
          <Trans
            components={{
              primaryLink: (
                <PrimaryLink href={`mailto:${SUPPORT_ADDRESS_MAIL}`} isExternal noDecoration />
              ),
            }}
            i18nKey={'empty-state:workspace-limit-reached.to-use-this-feature'}
            values={{ email: SUPPORT_ADDRESS_MAIL }}
          />
        </ParagraphText>
        <ButtonLikeLink href={`mailto:${SUPPORT_ADDRESS_MAIL}`} target="_blank">
          {t('empty-state:workspace-limit-reached.contact-us')}
        </ButtonLikeLink>
      </BodyWrapper>
    </FlexGrowContainer>
  );
};

export default WorkspaceLimitReached;
