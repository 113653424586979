import { useState } from 'react';
import Cropper from 'react-easy-crop';
import { useTranslation } from 'react-i18next';
import { DialogModal, ENUM_DIALOG_MODAL_SIZE } from 'semji-core/components/DialogModal';
import styled from 'styled-components/macro';

import Box from '@/design-system/components/Box';
import SecondaryButton from '@/design-system/components/Button/SecondaryButton';
import Flex from '@/design-system/components/Flex';

const StyledSlider = styled.input`
  appearance: none;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background: ${({ theme }) => theme.cssColors.dark100};
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
  margin: 0 16px;
  &:hover {
    opacity: 1;
  }
  &::-webkit-slider-thumb {
    appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: ${({ theme }) => theme.cssColors.primary};
    cursor: pointer;
  }
  &::-moz-range-thumb {
    appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border-color: transparent;
    background: ${({ theme }) => theme.cssColors.primary};
    cursor: pointer;
  }
`;

export function ConfirmAvatarDialog({ onCancel, handleConfirm, image, onCropComplete }) {
  const { t } = useTranslation();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  function handleZoomChange(e) {
    setZoom(e.target.value);
  }

  function zoomOut() {
    setZoom(zoom > 1 ? zoom - 0.1 : zoom);
  }

  function zoomIn() {
    setZoom(zoom < 3 ? zoom + 0.1 : zoom);
  }

  return (
    <DialogModal
      cancelAction={onCancel}
      cancelLabel={t('settings:profile.cancel-dialog')}
      canClickOutsideToClose
      closable
      confirmAction={handleConfirm}
      confirmLabel={t('settings:profile.confirm-dialog')}
      isOpen
      size={ENUM_DIALOG_MODAL_SIZE.Small}
      title={t('settings:profile.confirm-dialog-title')}
      onClose={onCancel}
    >
      <Flex flexDirection="column" gap="16px">
        <Box height="240px" position="relative" width="100%">
          <Cropper
            aspect={1}
            crop={crop}
            cropShape="round"
            image={image}
            showGrid={false}
            zoom={zoom}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </Box>
        <Flex
          alignItems="center"
          display="flex"
          gap="16px"
          justifyContent="space-between"
          width="100%"
        >
          <SecondaryButton onClick={zoomOut}>-</SecondaryButton>
          <StyledSlider
            max={3}
            min={1}
            step={0.1}
            type="range"
            value={zoom}
            onChange={handleZoomChange}
          />
          <SecondaryButton onClick={zoomIn}>+</SecondaryButton>
        </Flex>
      </Flex>
    </DialogModal>
  );
}
