import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import debounce from 'lodash/debounce';
import { lazy, Suspense, useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';

import usePostEmailChecker from '@/apis/semji/emails/usePostEmailChecker';
import CustomPlan from '@/assets/images/custom_plan.svg';
import InstructionsSent from '@/assets/images/instructions_sent.svg';
import Partenaires from '@/assets/images/partenaires.svg';
import {
  FlexColumnCenteredAlignContainer,
  FlexContainer,
  FlexGrowColumnCenteredContainer,
} from '@/components/_common';
import { Form, Input } from '@/components/Box/Box';
import { ContainedButton } from '@/components/Button/Button';
import GoogleBlock from '@/components/GoogleLogin/Block';
import { SIGNUP } from '@/components/GoogleLogin/constants';
import GoogleLoginButton from '@/components/GoogleLogin/GoogleLoginButton';
import Separator from '@/components/GoogleLogin/Separator';
import { ONLY_LOGO_WHITE_BACKGROUND } from '@/components/Layout/Layout';
import { RelativeLoader } from '@/components/Loader/Loader';
import { DefaultLink } from '@/components/Navigation/Link';
import PasswordFieldSkeleton from '@/components/Password/PasswordFieldSkeleton';
import { BlockText } from '@/components/Text/Inline';
import Medium from '@/components/Text/Medium';
import { ParagraphText } from '@/components/Text/Paragraph';
import { SecondaryTitleOne, SecondaryTitleTwo, TitleThree } from '@/components/Text/Title';
import LayoutWrapper from '@/containers/Layout/Layout';
import PrimaryButton from '@/design-system/components/Button/PrimaryButton';
import useBreakpoints from '@/hooks/useBreakpoints';
import useCan from '@/hooks/useCan';
import useDebug from '@/hooks/useDebug';
import { useWindowDimensions } from '@/hooks/useWindowsDimensions';
import UserService from '@/services/User';
import {
  DEFAULT_PASSWORD_MIN_STRENGTH,
  DEFAULT_PASSWORD_THRESHOLD_LENGTH,
} from '@/utils/constants';
import { isValidEmail } from '@/utils/email';
import { checkIsDevEnv } from '@/utils/log/checkIsDevEnv';

const PasswordField = lazy(
  () => import(/* webpackChunkName: "PasswordField" */ '@/components/Password/PasswordField')
);

const FirstNameInput = styled(Input)`
  && {
    margin-right: 1rem;
  }
`;
const LastNameInput = styled(Input)`
  && {
    margin-left: ${({ $isMobileVersion }) => ($isMobileVersion ? '0' : '1rem')};
  }
`;
const StyledTitleThree = styled(TitleThree)`
  && {
    ${(props) => props.theme.mediaQueries.largeDesktop} {
      font-size: ${({ theme }) => theme.textCss.sizes.md};
    }
    margin: 8px 0 38px 0;
  }
`;
const StyledSecondaryTitleTwo = styled(SecondaryTitleTwo)`
  && {
    margin: 20px 0px;
    max-width: ${({ $isMobileVersion }) => ($isMobileVersion ? '300px' : 'none')};
    text-align: ${({ $isMobileVersion }) => ($isMobileVersion ? 'center' : 'unset')};
  }
`;
const StyledFlexContainer = styled(FlexContainer)`
  && {
    flex-direction: ${({ $isMobileVersion }) => ($isMobileVersion ? 'column' : 'row')};
  }
`;
const StyledBlockText = styled(BlockText)`
  && {
    text-align: ${({ $isMobileVersion }) => ($isMobileVersion ? 'center' : 'unset')};
    white-space: ${({ $isMobileVersion }) => ($isMobileVersion ? 'break-spaces' : 'nowrap')};
    margin: ${({ margin }) => margin};
    font-size: ${({ theme }) => theme.textCss.sizes.xs};

    ${(props) => props.theme.mediaQueries.largeDesktop} {
      font-size: ${({ theme }) => theme.textCss.sizes.xs};
    }
  }
`;
const StyledParagraphText = styled(ParagraphText)`
  && {
    max-width: ${({ $isMobileVersion }) => ($isMobileVersion ? '300px' : 'none')};
    text-align: ${({ $isMobileVersion }) => ($isMobileVersion ? 'center' : 'unset')};
  }
`;
const ButtonWrapper = styled.div`
  margin-top: 20px;
`;
const RegisterWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  align-items: center;
  padding: ${({ isMobile }) => `0 ${isMobile ? '5px' : '15px'} 0 15px`};
`;
const ImgWrapper = styled.div`
  display: none;
  padding: 0 85px 0 174px;
  flex-direction: column;
  ${({ theme }) => `@media ${theme.responsive.min.md}`} {
    display: flex;
  }
`;
const FlexWrapper = styled.div`
  flex-direction: column;
  max-width: 400px;
  margin: ${({ isMinMd }) => `0 ${isMinMd ? '75px' : 0} 0 ${isMinMd ? '85px' : 0}`};
  width: 100%;
  box-sizing: border-box;
`;
const StyledGoogleBlock = styled(GoogleBlock)`
  && {
    box-sizing: border-box;
    margin: 16px 0;
  }
`;
const StyledSecondaryTitleOne = styled(SecondaryTitleOne)`
  font-size: 26px;
`;
const ErrorBoxMessage = styled.div`
  background-color: ${({ theme }) => theme.cssColors.primary005};
  padding: 16px;
  border-radius: 3px;
  font-size: ${({ theme }) => theme.textCss.sizes.sm};
  line-height: 20px;
  color: ${({ theme }) => theme.cssColors.dark080};
`;
const StyledForm = styled(Form)`
  margin-top: 18px;
`;
const Text = styled.span`
  font-size: ${(props) => props.theme.textCss.sizes.sm};
  line-height: ${(props) => props.theme.textCss.sizes.lg};
  font-weight: ${(props) => props.theme.textCss.weights.strong};
  color: ${({ theme }) => theme.cssColors.dark040};
  text-align: center;
  margin: 40px 0;
  text-transform: uppercase;
`;

const FlexCentered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  flex: 1;
`;

function SignUp() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const mockProxyEnv = useDebug('mockProxyEnv');

  const isSigninWithGoogleEnabled = useCan({ perform: 'sign-in:with-google' });

  const [state, setState] = useState({ email: '', firstName: '', lastName: '', password: '' });
  const [uiState, setUiState] = useState({
    displayEmail: true,
    displayName: false,
    displayPassword: false,
  });
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [signUpState, setSignUpState] = useState({
    isSignedUp: false,
    isSigningUp: false,
    isSigningUpOnError: false,
  });
  const [isGoogleEmail, setIsGoogleEmail] = useState(false);
  const [googleErrorMessage, setGoogleErrorMessage] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const { isMobile, isMinMd } = useBreakpoints();
  const { width } = useWindowDimensions();
  const isDevEnv = checkIsDevEnv();

  const emailChecker = usePostEmailChecker({
    onSuccess: ({ data }) => {
      if (data.isBlacklistedDomain) {
        setEmailError(
          t('create-account:sign-up.blacklisted-domain', { domain: data.email.split('@')[1] })
        );
      } else if (!data.isWorkEmail) {
        setEmailError(t('create-account:sign-up.email-error'));
      } else {
        setEmailError(null);
        setUiState({ ...uiState, displayName: true });
      }
    },
  });

  useEffect(() => {
    const routerSearchParams = new URLSearchParams(location.search);
    const planId = routerSearchParams.getAll('planId')[0];
    const email = routerSearchParams.getAll('email')[0] ?? state.email;
    const firstName = routerSearchParams.getAll('firstName')[0] ?? state.firstName;
    const lastName = routerSearchParams.getAll('lastName')[0] ?? state.lastName;

    if (planId) {
      setState({
        ...state,
        email,
        firstName,
        lastName,
        plan: `/plans/${planId}`,
      });
    } else {
      setState({
        ...state,
        email,
        firstName,
        lastName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    fetchGoogleEmail(state.email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signUpState.isSignedUp]);

  useEffect(() => {
    if (state.firstName && state.lastName) {
      setUiState({ ...uiState, displayPassword: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.firstName, state.lastName]);

  const isValidPassword = (value, strength) => {
    return (
      strength >= DEFAULT_PASSWORD_MIN_STRENGTH && value.length >= DEFAULT_PASSWORD_THRESHOLD_LENGTH
    );
  };

  async function handleSubmit(event) {
    event.preventDefault();

    if (!isValidPassword(state.password, passwordStrength)) {
      return;
    }

    setSignUpState({
      ...signUpState,
      isSigningUp: true,
      isSigningUpOnError: null,
    });

    try {
      const languageCode = i18n.languages?.[0] || 'en';
      const _UserService = new UserService();
      await _UserService.createUser({ ...state, languageCode });

      setSignUpState({
        ...signUpState,
        isSignedUp: true,
        isSigningUp: false,
      });
    } catch (error) {
      setSignUpState({
        ...signUpState,
        isSignedUp: false,
        isSigningUp: false,
        isSigningUpOnError: t('create-account:sign-up.email-verification.unknown-error'),
      });
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceCheckEmail = useCallback(
    debounce(() => {
      if (state.email && isValidEmail(state.email)) {
        setGoogleErrorMessage(null);
        emailChecker.mutate({ email: state.email });
      }
    }, 250),
    [state.email]
  );

  useEffect(() => {
    debounceCheckEmail();

    return debounceCheckEmail.cancel;
  }, [state.email, debounceCheckEmail]);

  function handleGoogleError(e) {
    setGoogleErrorMessage(e);
  }

  function handleChange(event) {
    setState({ ...state, [event.target.name]: event.target.value });
  }

  function onPasswordChanged(pwd) {
    setPasswordStrength(pwd.strength);
  }

  function fetchGoogleEmail(email) {
    if (typeof email !== 'string') {
      return;
    }

    const domain = email?.split('@')[1];

    if (!domain?.length) {
      return;
    }
    if (domain === 'gmail.com') {
      setIsGoogleEmail(true);
      return;
    }
    fetch(`https://dns.google.com/resolve?name=${domain}&type=MX`)
      .then((response) => response.text())
      .then((response) => setIsGoogleEmail(response.includes('google.com')));
  }

  function getInboxUrl(email) {
    if (isDevEnv && !mockProxyEnv) {
      return `http://localhost:8025/`;
    }
    const formattedEmail = email.replace(/\+[\dA-z]*/g, '');

    return `https://mail.google.com/mail/u/${formattedEmail}/#search/no-reply%40semji.com`;
  }

  if (signUpState.isSignedUp) {
    return (
      <LayoutWrapper menu={ONLY_LOGO_WHITE_BACKGROUND}>
        <Helmet>
          <title>{t('create-account:sign-up.email-verification.title')}</title>
        </Helmet>
        <FlexGrowColumnCenteredContainer>
          <div>
            <FlexColumnCenteredAlignContainer>
              <img
                alt={t('create-account:sign-up.email-verification.alt-image')}
                src={InstructionsSent}
              />
              <br />
              <StyledSecondaryTitleTwo $isMobileVersion={isMobile} noMargin weight="medium">
                {t('create-account:sign-up.email-verification.please-verify-email')}
              </StyledSecondaryTitleTwo>
              <StyledParagraphText $isMobileVersion={isMobile} color="dark060" weight="medium">
                {t('create-account:sign-up.email-verification.we-have-sent-link')}{' '}
                <Medium>{state.email}</Medium>
              </StyledParagraphText>
              <StyledParagraphText $isMobileVersion={isMobile} color="dark060" weight="medium">
                {t('create-account:sign-up.email-verification.if-cant-find-email')}
              </StyledParagraphText>
            </FlexColumnCenteredAlignContainer>
          </div>
          {(isGoogleEmail || isDevEnv) && (
            <ButtonWrapper>
              <DefaultLink isExternal to={getInboxUrl(state.email)}>
                <PrimaryButton weight="strong">
                  {t('create-account:sign-up.email-verification.check-your-inbox')}
                </PrimaryButton>
              </DefaultLink>
            </ButtonWrapper>
          )}
        </FlexGrowColumnCenteredContainer>
      </LayoutWrapper>
    );
  }

  return (
    <LayoutWrapper menu={ONLY_LOGO_WHITE_BACKGROUND}>
      <Helmet>
        <title>{t('create-account:sign-up.title')}</title>
      </Helmet>
      <FlexCentered>
        <RegisterWrapper isMobile={isMobile}>
          <FlexWrapper isMinMd={isMinMd}>
            <StyledSecondaryTitleOne align={!isMinMd ? 'center' : 'initial'} noMargin weight="bold">
              {t('create-account:sign-up.try-semji-for-free')}
            </StyledSecondaryTitleOne>
            <StyledTitleThree
              align={!isMinMd ? 'center' : 'initial'}
              color="dark060"
              noMargin
              weight="medium"
            >
              <Trans
                components={{
                  br: <br />,
                }}
                i18nKey={'create-account:sign-up.get-more-traffic'}
              />
            </StyledTitleThree>
            {googleErrorMessage && <ErrorBoxMessage>{googleErrorMessage}</ErrorBoxMessage>}
            <StyledForm onChange={handleChange} onSubmit={handleSubmit}>
              <FormControl
                aria-describedby="registration-error"
                error={!!signUpState.isSigningUpOnError}
                fullWidth
              >
                {uiState.displayEmail && (
                  <Input
                    autoComplete="username"
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                    error={!!signUpState.isSigningUpOnError || !!emailError}
                    fullWidth
                    helperText={emailError}
                    label={t('create-account:sign-up.labels.email')}
                    name="email"
                    required
                    type="email"
                    value={state.email}
                  />
                )}
                {uiState.displayName && (
                  <StyledFlexContainer $isMobileVersion={isMobile}>
                    <FirstNameInput
                      fullWidth
                      label={t('create-account:sign-up.labels.first-name')}
                      name="firstName"
                      required
                      value={state.firstName}
                    />
                    <LastNameInput
                      $isMobileVersion={isMobile}
                      fullWidth
                      label={t('create-account:sign-up.labels.last-name')}
                      name="lastName"
                      required
                      value={state.lastName}
                    />
                  </StyledFlexContainer>
                )}
                {uiState.displayPassword && (
                  <Suspense fallback={<PasswordFieldSkeleton />}>
                    <PasswordField
                      error={!!signUpState.isSigningUpOnError}
                      thresholdLength={DEFAULT_PASSWORD_THRESHOLD_LENGTH}
                      onPasswordChanged={onPasswordChanged}
                    />
                  </Suspense>
                )}
                {signUpState.isSigningUpOnError && (
                  <FormHelperText>{signUpState.isSigningUpOnError}</FormHelperText>
                )}
              </FormControl>
              <ContainedButton
                disabled={
                  !state.email ||
                  !state.firstName ||
                  !state.lastName ||
                  !state.password ||
                  emailError ||
                  signUpState.isSigningUp ||
                  !isValidPassword(state.password, passwordStrength)
                }
                fullWidth
                height="big"
                type="submit"
                weight="strong"
                withMargin
              >
                {signUpState.isSigningUp ? (
                  <RelativeLoader />
                ) : (
                  t('create-account:sign-up.try-semji-for-free')
                )}
              </ContainedButton>
            </StyledForm>
            <Separator transform="none" weight="medium">
              {t('create-account:sign-up.label-or')}
            </Separator>
            {isSigninWithGoogleEnabled && (
              <StyledGoogleBlock>
                <GoogleLoginButton
                  context={SIGNUP}
                  options={{ text: 'signup_with', width: `${Math.min(width - 30, 400)}px` }}
                  withErrorMessage={false}
                  onError={handleGoogleError}
                />
              </StyledGoogleBlock>
            )}
            <StyledBlockText $isMobileVersion={!isMinMd} margin="0 0 38px 0" weight="medium">
              <Trans
                components={{
                  defaultLink: <DefaultLink size="xs" to="/login" weight="medium" />,
                }}
                i18nKey={'create-account:sign-up.already-have-account'}
              />
            </StyledBlockText>
            <StyledBlockText $isMobileVersion={!isMinMd} color="dark060" weight="medium">
              <Trans
                components={{
                  defaultLink: (
                    <DefaultLink
                      color="dark060"
                      decoration
                      isExternal
                      size="xs"
                      to={t('common:links.terms-and-conditions')}
                      weight="medium"
                    />
                  ),
                }}
                i18nKey={'create-account:sign-up.agree-to-semji'}
              />
            </StyledBlockText>
          </FlexWrapper>
          <ImgWrapper>
            <img alt={t('create-account:sign-up.alt-image-custom')} src={CustomPlan} />
            <Text>{t('create-account:sign-up.illustration-text')}</Text>
            <img alt={t('create-account:sign-up.alt-image-partenaires')} src={Partenaires} />
          </ImgWrapper>
        </RegisterWrapper>
      </FlexCentered>
    </LayoutWrapper>
  );
}

export default SignUp;
