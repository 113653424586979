import { SemjiHub } from '@/types/common.types';

export const hubRoutes = [
  {
    hubs: [SemjiHub.EXECUTIVE, SemjiHub.CONTENT],
    path: '/in_queue',
    pathRex: /\/o\/[a-zA-Z0-9]{12}\/w\/[a-zA-Z0-9]{12}\/in_queue\/?/,
  },
  {
    hubs: [SemjiHub.EXECUTIVE, SemjiHub.CONTENT],
    path: '/settings',
    pathRex: /\/o\/[a-zA-Z0-9]{12}\/w\/[a-zA-Z0-9]{12}\/settings(\/\d*)?/,
  },
  {
    hubs: [SemjiHub.CONTENT],
    path: '/dashboard',
    pathRex: /\/o\/[a-zA-Z0-9]{12}\/w\/[a-zA-Z0-9]{12}\/dashboard\/?/,
  },
  {
    hubs: [SemjiHub.CONTENT],
    path: '/content-idea',
    pathRex: /\/o\/[a-zA-Z0-9]{12}\/w\/[a-zA-Z0-9]{12}\/content-idea(\/\d*)?/,
  },
  {
    hubs: [SemjiHub.CONTENT],
    path: '/onboarding',
    pathRex: /\/o\/[a-zA-Z0-9]{12}\/w\/[a-zA-Z0-9]{12}\/onboarding\/?/,
  },
  {
    hubs: [SemjiHub.CONTENT],
    path: '/contents',
    pathRex: /\/o\/[a-zA-Z0-9]{12}\/w\/[a-zA-Z0-9]{12}\/contents(\/\d*)?/,
  },
  {
    hubs: [SemjiHub.CONTENT],
    path: '/planning',
    pathRex: /\/o\/[a-zA-Z0-9]{12}\/w\/[a-zA-Z0-9]{12}\/planning(\/\d*)?/,
  },
  {
    hubs: [SemjiHub.CONTENT],
    path: '/p/',
    pathRex: /\/o\/[a-zA-Z0-9]{12}\/w\/[a-zA-Z0-9]{12}\/p\/\d+/,
  },
  {
    hubs: [SemjiHub.CONTENT],
    path: '/reports/',
    pathRex: /\/o\/[a-zA-Z0-9]{12}\/w\/[a-zA-Z0-9]{12}\/reports(\/\d*)?/,
  },
  {
    hubs: [SemjiHub.CONTENT],
    path: '/brand-voice/',
    pathRex: /\/o\/[a-zA-Z0-9]{12}\/w\/[a-zA-Z0-9]{12}\/brand-voice(\/\d*)?/,
  },
  {
    hubs: [SemjiHub.EXECUTIVE],
    path: '/search-intelligence',
    pathRex: /\/o\/[a-zA-Z0-9]{12}\/w\/[a-zA-Z0-9]{12}\/search-intelligence(\/\d*)?/,
  },
  {
    hubs: [SemjiHub.EXECUTIVE, SemjiHub.CONTENT],
    path: '/',
    pathRex: /\/o\/[a-zA-Z0-9]{12}\/w\/[a-zA-Z0-9]{12}\/?/,
  },
];
