import styled from 'styled-components/macro';

import FlatLoader from '../Loader/FlatLoader';

const PasswordFieldSkeleton = styled(FlatLoader)`
  && {
    width: ${({ width }) => width ?? '100%'};
    height: ${({ height }) => height ?? '42px'};
  }
`;

export default PasswordFieldSkeleton;
