import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import apiClient from '@/apis/semji/apiClient';
import { SCOPE_CONTENTS } from '@/apis/semji/constants';
import { showErrorSnackbar } from '@/store/actions/ui';

import { UseGetCurrentWorkspaceContentsStatusesProps } from './useGetCurrentWorkspaceContentsStatuses.types';

export default function useGetCurrentWorkspaceContentsStatuses({
  location,
  ...props
}: UseGetCurrentWorkspaceContentsStatusesProps) {
  const { t } = useTranslation();
  const { get } = apiClient;
  const dispatch = useDispatch();
  const { workspaceId } = useParams();

  return useQuery({
    onError: () => {
      dispatch(showErrorSnackbar(t('common:error.default')));
    },
    placeholderData: [],

    queryFn: async ({ signal }) => {
      let result = (await get(`/workspaces/${workspaceId}/content_statuses`, { signal })).data[
        'hydra:member'
      ];
      result[result.length - 1].publish = true;
      return result || [];
    },
    queryKey: [SCOPE_CONTENTS.STATUSES, workspaceId, location],
    ...props,
  });
}
