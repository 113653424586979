import styled from 'styled-components/macro';

import ThreeDotsIcon from '@/components/icons/ThreeDotsIcon';
import Flex from '@/design-system/components/Flex';

const LoaderWrapper = styled(Flex)`
  font-size: ${({ fontSize }) => fontSize};
`;

export const LoaderText = styled.span`
  color: ${({ theme }) => theme.cssColors.dark060};
  font-size: ${({ theme }) => theme.textCss.sizes.md};
  font-weight: ${({ theme }) => theme.textCss.weights.medium};
  line-height: ${({ theme }) => theme.textCss.sizes.xl};
`;

export const Loader = ({ fontSize = '48px', children, ...rest }) => {
  return (
    <LoaderWrapper
      alignItems="center"
      color="dark020"
      flex={1}
      flexDirection="column"
      fontSize={fontSize}
      justifyContent="center"
      {...rest}
    >
      <ThreeDotsIcon />
      {children}
    </LoaderWrapper>
  );
};
