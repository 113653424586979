import i18next from 'i18next';

import {
  addCurrentPageDraft,
  addTopKeywordOnPage,
  bulkImportPagesFromStock,
  bulkImportPagesFromUrl,
  deletePage,
  fetchPage,
  getPageContents,
  pageRefreshData,
  savePageDraft,
  setPageFocusTopKeyword,
} from '@/apis/semji/api';
import { SECTIONS } from '@/utils/log/constants';
import { Log } from '@/utils/log/Log';

export const SOURCE_TOP_PAGES = 'top_pages';
export const SOURCE_URL_IMPORTED = 'url_imported';
export const SOURCE_SEMJI_CONTENT = 'semji_content';
export const SOURCE_CHROME_EXTENSION_TOP_KEYWORD = 'chrome_extension_create_top_keyword';

export const getSources = () => [
  {
    id: SOURCE_TOP_PAGES,
    label: i18next.t(`components:table.title-cell.tooltip-existing-content`),
  },
  {
    id: SOURCE_SEMJI_CONTENT,
    label: i18next.t(`components:table.title-cell.tooltip-new-content`),
  },
];

export default class Page {
  constructor(id, workspaceId) {
    this._id = id;
    this._workspaceId = workspaceId;
    this._page = null;
    this._pageContents = null;
  }

  /** Cached call to API */
  get page() {
    return this._fetchPage();
  }

  /** Cached call to API */
  get pageContents() {
    return this._fetchPageContents();
  }

  delete() {
    return deletePage(this._id);
  }

  fetchPage() {
    return fetchPage(this._id);
  }

  async fetchPageContents() {
    return (await getPageContents(this._id))['hydra:member'];
  }

  importFromUrls(urls) {
    return bulkImportPagesFromUrl(this._workspaceId, urls);
  }

  importFromStock(ids) {
    return bulkImportPagesFromStock(this._workspaceId, ids);
  }

  setDraftUser(assignedTo, id) {
    return savePageDraft({
      assignedTo: assignedTo?.['@id'] || assignedTo,
      id,
    });
  }

  setDraftStatus(contentStatus, id) {
    return savePageDraft({
      contentStatus: contentStatus?.['@id'] || contentStatus,
      id,
    });
  }

  setDraftDueDate(dueDate, id) {
    return savePageDraft({ dueDate, id });
  }

  addTopKeyword(topKeyword) {
    return addTopKeywordOnPage(this._id, topKeyword);
  }

  setFocusTopKeyword(topKeywordIri) {
    return setPageFocusTopKeyword(this._id, topKeywordIri);
  }

  refreshData() {
    return pageRefreshData(this._id);
  }

  saveNewDraft({
    html,
    title,
    metaDescription = '', // default to empty string, the comparison to handle autoSave is with a ref to a textarea which is always an empty string if no value
    assignedTo = null,
    contentStatus = null,
    dueDate = null,
    folderId = null,
  }) {
    let page = null;

    if (this._id) {
      page = `/pages/${this._id}`;
    }

    return addCurrentPageDraft(this._workspaceId, {
      assignedTo: assignedTo?.['@id'] || assignedTo,
      contentStatus: contentStatus?.['@id'] || contentStatus,
      dueDate,
      folderId,
      html,
      metaDescription,
      page,
      title,
    });
  }

  /** Cached call to API */
  async _fetchPage() {
    if (this._page === null) {
      try {
        this._page = await fetchPage(this._id);
      } catch (error) {
        Log.report({
          context: '_fetchPage',
          error,
          extra: 'get Page Error',
          section: SECTIONS.organization.key,
        });
      }
    }

    return this._page;
  }

  /** Cached call to API */
  async _fetchPageContents() {
    if (this._pageContents === null) {
      try {
        this._pageContents = await getPageContents(this._id);
      } catch (e) {
        return Promise.reject(new Error(e));
      }
    }

    return Promise.resolve(this._pageContents['hydra:member']);
  }
}
