import { getRoundedNumberMetricWithSuffix } from 'semji-core/utils/numbers';

export const sortByClickListTopKeywords = (topKeywords) =>
  topKeywords.sort((topKeyword1, topKeyword2) => topKeyword2.clicks - topKeyword1.clicks);

export const formatListTopKeywords = (topKeywords, userLanguageCode) =>
  topKeywords.map((topKeyword) => {
    const position = topKeyword.position;
    const positionNumber = position >= 1 && position < 100 ? position : '> 100';

    return {
      analysisStatus: topKeyword.analysisStatus,
      analyzed: topKeyword.analyzed,
      id: topKeyword.id,
      keyword: topKeyword.keyword,
      loadingKeywordVolume: !topKeyword.keywordDataUpdatedAt,
      loadingSearchConsoleData: !topKeyword.lastSearchConsoleUpdatedAt,
      positionValue: positionNumber,
      searchVolumeValue: getRoundedNumberMetricWithSuffix({
        locale: userLanguageCode,
        number: topKeyword.searchVolume,
      }),
      topKeywordData: {
        '@id': topKeyword['@id'],
      },
      trafficValue: topKeyword.clicks,
    };
  });
