export const FAVICON_SIZE_SM = 'sm';
export const FAVICON_SIZE_MD = 'md';
export const FAVICON_SIZE_LG = 'lg';

type FaviconSize = typeof FAVICON_SIZE_SM | typeof FAVICON_SIZE_MD | typeof FAVICON_SIZE_LG;

export interface ContainedFavIconProps {
  url: string;
  size: FaviconSize;
  className?: string;
}
