import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import Sablier from '@/assets/images/sablier.svg';
import { Subtitle, Title } from '@/components/Optimization/FocusKeywordAnalysisPending';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  justify-self: stretch;
  flex-grow: 1;
`;

function FocusKeywordAnalysisQueued() {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <img alt="pending" src={Sablier} />
      <Title>{t('components:optimization.focus-keyword-analysis-queued.title-label')}</Title>
      <Subtitle>
        {t('components:optimization.focus-keyword-analysis-queued.subtitle-label')}
      </Subtitle>
    </Wrapper>
  );
}

export default FocusKeywordAnalysisQueued;
