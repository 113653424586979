import { matchRoutes } from 'react-router-dom';

import useForceDesktopViewport from '@/hooks/useForceDesktopViewport';

const ROUTES_DESKTOP_LAYOUT = [
  { path: '/o/:organizationId/settings/*' },
  { path: '/o/:organizationId/w/:workspaceId/*' },
];

// Routes exceptions for previous array.
const ROUTES_EXCEPTION_RESPONSIVE_LAYOUT = [];

function ForceDesktopLayout(location) {
  const isDesktopRoute = Boolean(matchRoutes(ROUTES_DESKTOP_LAYOUT, location));

  const isResponsiveRoute = Boolean(matchRoutes(ROUTES_EXCEPTION_RESPONSIVE_LAYOUT, location));

  useForceDesktopViewport(isDesktopRoute && !isResponsiveRoute);

  return null;
}

export default ForceDesktopLayout;
