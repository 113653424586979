import { transparentize } from 'polished';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { PrimaryButton } from '@/components/Button/Button';
import Agenda from '@/components/icons/Agenda';
import PenBoxIcon from '@/components/icons/PenBoxIcon';
import PencilIcon from '@/components/icons/PencilIcon';
import StatsUpIcon from '@/components/icons/StatsUpIcon';
import TimerIcon from '@/components/icons/TimerIcon';
import WorldIcon from '@/components/icons/WorldIcon';
import { TertiaryLink } from '@/components/Navigation/Link';

const StyledPrimaryButton = styled(PrimaryButton)`
  && {
    font-size: ${({ theme }) => theme.textCss.sizes.sm};
  }
`;

const Badge = styled.span`
  box-sizing: border-box;
  display: block;
  max-width: 100px;
  padding: 4px 16px;
  border-radius: 16px;
  font-size: ${({ theme }) => theme.textCss.sizes.sm};
  line-height: ${({ theme }) => theme.textCss.sizes.lg};
  font-weight: ${({ theme }) => theme.textCss.weights.strong};
  color: ${({ theme, color }) => theme.cssColors[color || 'primary']};
  background-color: ${({ theme, color }) =>
    transparentize(0.9, theme.cssColors[color || 'primary'])};
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Title = styled.h3`
  font-family: ${({ theme }) => theme.textCss.fonts.secondary};
  color: ${({ theme }) => theme.cssColors.dark100};
  font-size: ${({ theme }) => theme.textCss.sizes.xl};
  line-height: ${({ theme }) => theme.textCss.sizes.xxxl};
  font-weight: ${({ theme }) => theme.textCss.weights.bold};
  text-align: center;
`;

const ColoredText = styled.span`
  color: ${({ theme }) => theme.cssColors.primary};
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 70px;
  padding: 55px 45px;
  background-color: linear-gradient(180deg, rgba(246, 245, 248, 0) 12.59%, #f6f5f8 100%), #ffffff;
  font-family: ${({ theme }) => theme.textCss.fonts.main};

  > div {
    width: 100%;
    max-width: 1090px;
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
`;

const CardsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
  gap: inherit;
  justify-items: center;
`;

const CardTitle = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  color: ${({ theme }) => theme.cssColors.dark100};
  font-size: ${({ theme }) => theme.textCss.sizes.md};
  line-height: ${({ theme }) => theme.textCss.sizes.xl};
  font-weight: ${({ theme }) => theme.textCss.weights.strong};
  margin-bottom: 12px;
`;

const CardContent = styled.div`
  color: ${({ theme }) => theme.cssColors.dark100};
  font-size: ${({ theme }) => theme.textCss.sizes.default};
  line-height: ${({ theme }) => theme.textCss.sizes.lg};
  font-weight: ${({ theme }) => theme.textCss.weights.medium};
`;

const Card = styled.div`
  &&& {
    box-sizing: border-box;
    padding: 24px;
    background-color: ${({ theme }) => theme.cssColors.white};
    box-shadow: 0px 2px 50px rgba(113, 113, 113, 0.1);
    border-radius: 8px;
    width: 310px;
    overflow: hidden;

    > ${Badge} {
      color: ${({ theme, color }) => theme.cssColors[color || 'primary']};
      background-color: ${({ theme, color }) =>
        transparentize(0.9, theme.cssColors[color || 'primary'])};
      margin-bottom: 28px;
    }

    > ${CardTitle} > svg {
      color: ${({ theme, color }) => theme.cssColors[color || 'primary']};
      width: 30px;
      height: 30px;
    }
  }
`;

const CustomersGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
  gap: inherit;
`;

const CustomerTitle = styled.h3`
  box-sizing: border-box;
  color: ${({ theme }) => theme.cssColors.dark040};
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.textCss.sizes.default};
  line-height: ${({ theme }) => theme.textCss.sizes.lg};
  font-weight: ${({ theme }) => theme.textCss.weights.strong};
  height: 45px;
  padding-bottom: 20px;
  border-bottom: 2px solid ${({ theme }) => theme.cssColors.dark020};
`;

const CustomerFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;

const CustomerLogos = styled.div`
  display: grid;
  grid-template-columns: repeat(3, max-content);
  grid-template-rows: repeat(1, 50px);
  align-items: center;
  justify-content: space-between;
`;

const StyledImg = styled.img`
  max-width: 130px;
  object-fit: contain;
`;

function CustomerBlock({ index }) {
  const namespaceTotest = 'upgrade-plan.customer-block-';
  const { t } = useTranslation();

  const title = t(`billing:upgrade-plan.customer-block-${index}.title`);
  const logo0 = t(`billing:upgrade-plan.customer-block-${index}.logo-0`);
  const logo1 = t(`billing:upgrade-plan.customer-block-${index}.logo-1`);
  const logo2 = t(`billing:upgrade-plan.customer-block-${index}.logo-2`);

  if (title.includes(namespaceTotest)) {
    return null;
  }

  return (
    <CustomerFlex>
      <CustomerTitle>{title}</CustomerTitle>
      <CustomerLogos>
        {!logo0.includes(namespaceTotest) && (
          <StyledImg alt={logo0} src={`/images/customers/${logo0}.png`} />
        )}
        {!logo1.includes(namespaceTotest) && (
          <StyledImg alt={logo1} src={`/images/customers/${logo1}.png`} />
        )}
        {!logo2.includes(namespaceTotest) && (
          <StyledImg alt={logo2} src={`/images/customers/${logo2}.png`} />
        )}
      </CustomerLogos>
    </CustomerFlex>
  );
}

function UpgradePlan() {
  const { t } = useTranslation();

  return (
    <Flex>
      <Box>
        <Title>
          <Trans components={{ color: <ColoredText /> }} i18nKey={'billing:upgrade-plan.title'} />
        </Title>
        <TertiaryLink isExternal to={t('common:links.contact-us')}>
          <StyledPrimaryButton title={t('billing:upgrade-plan.contact-us')}>
            {t('billing:upgrade-plan.contact-us')}
          </StyledPrimaryButton>
        </TertiaryLink>
      </Box>
      <CardsGrid>
        <Card color="green">
          <Badge>{t('billing:upgrade-plan.card-0.badge-label')}</Badge>
          <CardTitle>
            <PenBoxIcon /> {t('billing:upgrade-plan.card-0.title')}
          </CardTitle>
          <CardContent>{t('billing:upgrade-plan.card-0.content')}</CardContent>
        </Card>

        <Card color="primary">
          <Badge>{t('billing:upgrade-plan.card-1.badge-label')}</Badge>
          <CardTitle>
            <TimerIcon /> {t('billing:upgrade-plan.card-1.title')}
          </CardTitle>
          <CardContent>{t('billing:upgrade-plan.card-1.content')}</CardContent>
        </Card>

        <Card color="orange">
          <Badge>{t('billing:upgrade-plan.card-2.badge-label')}</Badge>
          <CardTitle>
            <StatsUpIcon /> {t('billing:upgrade-plan.card-2.title')}
          </CardTitle>
          <CardContent>{t('billing:upgrade-plan.card-2.content')}</CardContent>
        </Card>

        <Card color="purple">
          <Badge>{t('billing:upgrade-plan.card-3.badge-label')}</Badge>
          <CardTitle>
            <Agenda /> {t('billing:upgrade-plan.card-3.title')}
          </CardTitle>
          <CardContent>{t('billing:upgrade-plan.card-3.content')}</CardContent>
        </Card>

        <Card color="lightBlue">
          <Badge>{t('billing:upgrade-plan.card-4.badge-label')}</Badge>
          <CardTitle>
            <PencilIcon /> {t('billing:upgrade-plan.card-4.title')}
          </CardTitle>
          <CardContent>{t('billing:upgrade-plan.card-4.content')}</CardContent>
        </Card>

        <Card color="green">
          <Badge>{t('billing:upgrade-plan.card-5.badge-label')}</Badge>
          <CardTitle>
            <WorldIcon /> {t('billing:upgrade-plan.card-5.title')}
          </CardTitle>
          <CardContent>{t('billing:upgrade-plan.card-5.content')}</CardContent>
        </Card>
      </CardsGrid>
      <CustomersGrid>
        {Array(5)
          .fill(null)
          .map((_value, index) => (
            <CustomerBlock key={index} index={index} />
          ))}
      </CustomersGrid>
      <Box>
        <TertiaryLink isExternal to={t('common:links.contact-us')}>
          <StyledPrimaryButton title={t('billing:upgrade-plan.contact-us')}>
            {t('billing:upgrade-plan.contact-us')}
          </StyledPrimaryButton>
        </TertiaryLink>
      </Box>
    </Flex>
  );
}

export default UpgradePlan;
