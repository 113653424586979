import React from 'react';

import SvgWrapper from './SvgWrapper';

const UpdatedIcon = (props) => (
  <SvgWrapper {...props} height="13" viewBox="0 0 11 13" width="11">
    <path d="M4.10764 2.47619H8.80208C9.26898 2.47619 9.71674 2.67185 10.0469 3.02014C10.377 3.36842 10.5625 3.84079 10.5625 4.33333V11.1429C10.5625 11.6354 10.377 12.1078 10.0469 12.4561C9.71674 12.8043 9.26898 13 8.80208 13H4.10764C3.64075 13 3.19298 12.8043 2.86284 12.4561C2.53269 12.1078 2.34722 11.6354 2.34722 11.1429V10.5238H2.93403V11.1429C2.93403 11.4712 3.05768 11.7861 3.27777 12.0183C3.49787 12.2505 3.79638 12.381 4.10764 12.381H8.80208C9.11335 12.381 9.41186 12.2505 9.63195 12.0183C9.85205 11.7861 9.97569 11.4712 9.97569 11.1429V4.33333C9.97569 4.00497 9.85205 3.69006 9.63195 3.45787C9.41186 3.22568 9.11335 3.09524 8.80208 3.09524H4.10764C3.79638 3.09524 3.49787 3.22568 3.27777 3.45787C3.05768 3.69006 2.93403 4.00497 2.93403 4.33333V7.42857H2.34722V4.33333C2.34722 3.84079 2.53269 3.36842 2.86284 3.02014C3.19298 2.67185 3.64075 2.47619 4.10764 2.47619ZM1.76042 0H7.62847V0.619048H1.76042C1.44916 0.619048 1.15064 0.749489 0.930548 0.981677C0.710454 1.21387 0.586806 1.52878 0.586806 1.85714V7.42857C0.586806 7.75694 0.710454 8.07185 0.930548 8.30404C1.15064 8.53623 1.44916 8.66667 1.76042 8.66667H6.01476L4.69444 7.27381L5.08408 6.80952L7.1379 8.97619L5.08408 11.1429L4.69444 10.6786L6.01476 9.28572H1.76042C1.29353 9.28571 0.845756 9.09005 0.515614 8.74177C0.185472 8.39349 0 7.92112 0 7.42857V1.85714C0 1.3646 0.185472 0.892227 0.515614 0.543945C0.845756 0.195663 1.29353 0 1.76042 0Z" />
  </SvgWrapper>
);

export default UpdatedIcon;
