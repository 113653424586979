import Divider from '@material-ui/core/Divider';
import Popper from '@material-ui/core/Popper';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import {
  ADD_KEYWORD_OPTION_ID,
  EDITOR_FK,
  MAX_KEYWORD_LENGTH,
  PAGES_FK,
  STYLE,
  TOP_PAGE_FK,
} from '@/components/FocusKeyword/constants';
import { FocusKeywordOption } from '@/components/FocusKeyword/FocusKeywordOption';
import { FocusKeywordOptionAdd } from '@/components/FocusKeyword/FocusKeywordOptionAdd';
import { FocusKeywordOptionsHeader } from '@/components/FocusKeyword/FocusKeywordOptionsHeader';
import { FocusKeywordPlaceHolder } from '@/components/FocusKeyword/FocusKeywordPlaceHolder';
import { PopoverKeywordAdd } from '@/components/FocusKeyword/PopoverKeywordAdd';
import { formatListTopKeywords, sortByClickListTopKeywords } from '@/components/FocusKeyword/utils';
import CaretIcon from '@/components/icons/CaretIcon';
import { sortKeywordsByTrafficAndSearchVolumes } from '@/utils/keywords';
import { noop } from '@/utils/noop';

const Label = styled.label`
  && {
    color: white;
  }
`;
const TextFieldWrapper = styled.div`
  && {
    border-radius: 5px;
    padding: 0 10px;
    display: flex;
    background-color: ${({ isOpen, theme }) => (isOpen ? theme.cssColors.dark010 : 'transparent')};
    :hover {
      background-color: ${({ theme }) => theme.cssColors.dark005};
    }
    && textarea {
      padding: 0;
      max-height: 30px;
      white-space: initial;
      display: -webkit-box;
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      cursor: pointer;
    }
  }
`;
const Wrapper = styled.div`
  && {
    display: contents;
    flex: 1;
    button {
      background-color: transparent;
      & :hover {
        background-color: transparent;
      }
    }
  }
`;
const StyledTextField = styled(TextField)`
  && {
    transition: all 0.3s ease-in-out;
    & > * {
      transition: all 0.3s ease-in-out;
    }
    & > div {
      border-color: ${({ focus, theme }) =>
        focus ? theme.cssColors.secondaryBlue : theme.cssColors.dark005};
      background-color: ${({ focus, theme }) =>
        focus ? theme.cssColors.white : theme.cssColors.dark005};
      &:hover {
        border-color: ${({ theme, focus }) => !focus && theme.cssColors.dark060};
        background-color: ${({ theme, focus }) => !focus && theme.cssColors.white};
      }
    }
  }
`;

const useStyles = (variant) => makeStyles(STYLE[variant])();

function FocusKeywordSelector({
  workspaceCountryName,
  hasSearchConsole,
  topKeywords = [],
  focusTopKeywordId = null,
  variant = EDITOR_FK,
  getKeywordsByPage = noop,
  onKeywordAdd = noop,
  onKeywordChange = noop,
  onFocus = noop,
  onBlur = noop,
  ...props
}) {
  const { t } = useTranslation();
  const classes = useStyles(variant);
  const autoCompleteInput = useRef(null);
  const autoCompleteRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [currentValue, setCurrentValue] = useState(null);
  const [showNewFocusKeywordPopover, setShowNewFocusKeywordPopover] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [closeOption, setCloseOption] = useState(null);
  const userLanguageCode = useSelector((state) => state.user?.languageCode);

  useEffect(() => {
    function checkKeywordOnClose() {
      const existingKeyword = topKeywords?.find(
        (topKeyword) => topKeyword?.keyword === currentValue
      );
      if (
        existingKeyword?.id !== focusTopKeywordId &&
        currentValue?.length > 0 &&
        closeOption === 'blur'
      ) {
        if (existingKeyword) {
          onUpdate(
            sortedList?.find((elem) => elem?.id === existingKeyword?.id),
            'select-option'
          );
        } else {
          onUpdate({ keyword: currentValue }, 'add-option');
        }
      }
      setCurrentValue(null);
    }

    if (isOpen) {
      setIsFocused(true);
      getKeywordsByPage();
    } else {
      checkKeywordOnClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const topKeywordsList = formatListTopKeywords(
    sortByClickListTopKeywords(topKeywords),
    userLanguageCode
  );

  const focusTopKeyword = useMemo(
    () => topKeywordsList.find((o) => o.id === focusTopKeywordId) || {},
    [topKeywordsList, focusTopKeywordId]
  );

  const sortedList = [
    ...sortKeywordsByTrafficAndSearchVolumes(topKeywordsList),
    { id: ADD_KEYWORD_OPTION_ID },
  ];

  const currentValueInTheList = topKeywordsList.find(
    (el) => el.keyword?.toLowerCase() === currentValue?.toLowerCase()
  );

  function onAdd(newValue) {
    const existingKeyword = topKeywordsList.find(
      (el) => el.keyword?.toLowerCase() === newValue?.toLowerCase()
    );

    if (!!existingKeyword) {
      onUpdate(existingKeyword, 'select-option');
    } else {
      onUpdate({ keyword: newValue }, 'add-option');
    }
  }

  function onUpdate(option, meta) {
    if (meta === 'select-option') {
      onKeywordChange(option);
    }
    if (meta === 'add-option') {
      onKeywordAdd({
        createdByUser: true,
        // using an uuid locally, so we can add multiple focus keywords
        // previously if we add 2 keywords it fails
        // because we delete the temp focus keyword based on the id
        id: `pending_top_keyword_id_${nanoid()}`,
        keyword: option.keyword.toLowerCase(),
        keywordDataUpdatedAt: null,
        lastSearchConsoleUpdatedAt: null,
      });
    }
  }

  function handleOpenInput() {
    setIsOpen(!isOpen);
  }

  function renderInput(params) {
    if (PAGES_FK === variant) {
      return (
        <TextFieldWrapper isOpen={isOpen} onClick={handleOpenInput}>
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              readOnly: true,
            }}
            inputRef={autoCompleteInput}
            maxRows={2}
            multiline
            // rowsMax will be deprecated, migrating to MUI v5 will be replaced by maxRows
            rowsMax={2}
          />
        </TextFieldWrapper>
      );
    }
    return (
      <StyledTextField
        {...params}
        focus={isOpen}
        fullWidth
        InputLabelProps={{
          component: (props) => <Label {...props} />,
          disableAnimation: true,
          focused: false,
          shrink: true,
        }}
        inputProps={{
          ...params.inputProps,
          // disable autocomplete and autofill
          autoComplete: 'disabled',
          maxLength: MAX_KEYWORD_LENGTH,
          onChange: (e) => setCurrentValue(e.target.value),
          title: focusTopKeyword.keyword,
          value: currentValue === null ? focusTopKeyword.keyword : currentValue,
        }}
        InputProps={{
          ...params.InputProps,
          disableUnderline: true,
          endAdornment:
            (!isFocused && variant === EDITOR_FK) || variant === TOP_PAGE_FK ? (
              <FocusKeywordPlaceHolder
                hasSearchConsole={hasSearchConsole}
                value={focusTopKeyword}
                variant={variant}
                workspaceCountryName={workspaceCountryName}
              />
            ) : null,
        }}
        inputRef={autoCompleteInput}
        placeholder={
          focusTopKeyword.keyword || t('components:focus-keyword.enter-keyword-placeholder')
        }
        variant="standard"
        onBlur={onBlur}
        onClick={handleOpenInput}
        onFocus={onFocus}
      />
    );
  }

  function renderGroup({ children, group }) {
    if (group === 'SUGGESTED KEYWORDS') {
      return (
        <React.Fragment key={group}>
          <FocusKeywordOptionsHeader
            hasSearchConsole={hasSearchConsole}
            title={t('components:focus-keyword.suggested-keyword')}
            workspaceCountryName={workspaceCountryName}
          />
          {children}
          <Divider />
        </React.Fragment>
      );
    }
    if (group === 'ANALYZED KEYWORDS') {
      return (
        <React.Fragment key={group}>
          <FocusKeywordOptionsHeader
            hasSearchConsole={hasSearchConsole}
            title={t('components:focus-keyword.analyzed-keyword')}
            workspaceCountryName={workspaceCountryName}
          />
          {children}
          <Divider />
        </React.Fragment>
      );
    }
    return <React.Fragment key={group}>{children} </React.Fragment>;
  }

  function renderOption(option) {
    if (option.id === ADD_KEYWORD_OPTION_ID) {
      return <FocusKeywordOptionAdd key={option.id} value={currentValue} />;
    }
    return (
      <FocusKeywordOption
        key={focusTopKeyword.id}
        analyzed={option.analyzed}
        hasSearchConsole={hasSearchConsole}
        keyword={option.keyword}
        loadingKeywordVolume={option.loadingKeywordVolume}
        loadingSearchConsoleData={option.loadingSearchConsoleData}
        positionValue={option.positionValue}
        searchVolumeValue={option.searchVolumeValue}
        selected={focusTopKeyword.id === option.id}
        trafficValue={option.trafficValue}
        variant={variant}
      />
    );
  }

  function filterOptions(options) {
    if (!currentValue) {
      return options;
    }
    return options.filter(
      (option) =>
        option.id === ADD_KEYWORD_OPTION_ID ||
        (option.keyword || '').toLowerCase().includes(currentValue.toLowerCase())
    );
  }

  function getOptionLabel(option) {
    return option.keyword || '';
  }

  function handleChange(e, option) {
    if (option.id === ADD_KEYWORD_OPTION_ID && currentValue && !!currentValueInTheList) {
      onUpdate(currentValueInTheList, 'select-option');
    } else if (option.id === ADD_KEYWORD_OPTION_ID && currentValue) {
      onUpdate({ keyword: currentValue }, 'add-option');
    } else if (option.id === ADD_KEYWORD_OPTION_ID && !currentValue) {
      setShowNewFocusKeywordPopover(true);
    } else if (option.id && option.id !== focusTopKeyword.id) {
      onUpdate(option, 'select-option');
    }
  }

  function handleClose(e, option) {
    setCloseOption(option);
    setIsFocused(false);
    setIsOpen(false);
  }

  function handleClosePopover() {
    setShowNewFocusKeywordPopover(false);
  }

  function groupBy(option) {
    if (option.id === ADD_KEYWORD_OPTION_ID) {
      return 'ADD ACTION';
    }
    if (option.analyzed) {
      return 'ANALYZED KEYWORDS';
    }

    return 'SUGGESTED KEYWORDS';
  }

  return (
    <>
      <Wrapper>
        <Autocomplete
          {...props}
          autoHighlight
          classes={{
            ...classes,
          }}
          disableClearable
          filterOptions={filterOptions}
          getOptionLabel={getOptionLabel}
          groupBy={groupBy}
          {...([EDITOR_FK, TOP_PAGE_FK].includes(variant) ? { freeSolo: true } : {})}
          key={props.key}
          options={sortedList}
          onChange={handleChange}
          onClose={handleClose}
          {...(variant === PAGES_FK
            ? {
                PopperComponent: (props) => (
                  <Popper
                    {...props}
                    placement="bottom-start"
                    style={{ ...STYLE[PAGES_FK].popper }}
                  />
                ),
                popupIcon: <CaretIcon />,
              }
            : {})}
          ref={autoCompleteRef}
          open={isOpen}
          openOnFocus
          renderGroup={renderGroup}
          renderInput={renderInput}
          renderOption={renderOption}
          value={focusTopKeyword}
        />
      </Wrapper>
      {showNewFocusKeywordPopover ? (
        <PopoverKeywordAdd
          anchor={autoCompleteRef.current}
          isOpen={showNewFocusKeywordPopover}
          onAdd={onAdd}
          onClose={handleClosePopover}
        />
      ) : null}
    </>
  );
}

FocusKeywordSelector.propTypes = {
  focusTopKeywordId: PropTypes.string,
  getKeywordsByPage: PropTypes.func.isRequired,
  hasSearchConsole: PropTypes.bool.isRequired,
  onKeywordChange: PropTypes.func.isRequired,
  topKeywords: PropTypes.array.isRequired,
  variant: PropTypes.string,
  workspaceCountryName: PropTypes.string.isRequired,
};

export default FocusKeywordSelector;
