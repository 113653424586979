import './ReportLayout.scss';

import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { FilesIcon } from 'semji-core/icons/FilesIcon';
import { UpdateIcon } from 'semji-core/icons/UpdateIcon';
import { capitalize } from 'semji-core/utils/string';

import { exportPagesReport } from '@/apis/semji/api';
import useWorkspaceFolders from '@/apis/semji/folders/useWorkspaceFolders';
import useGetWorkspaceUsers from '@/apis/semji/users/useGetWorkspaceUsers';
import ExportDialog from '@/components/Dialog/ExportDialog';
import FilterGroups from '@/components/Filters/FilterGroups';
import DotsLoaderIcon from '@/components/icons/DotsLoaderIcon';
import FileIcon from '@/components/icons/FileIcon';
import PageDataLayout from '@/components/PageDataLayout/PageDataLayout';
import PageDataNavigation from '@/components/PageDataLayout/PageDataNavigation/PageDataNavigation';
import { Header } from '@/containers/Report/components/Header';
import { ReportTabs } from '@/containers/Report/components/ReportTabs';
import { ReportContext, ReportCountType } from '@/containers/Report/hooks';
import usePagesWithMetrics from '@/containers/Report/hooks/usePagesWithMetrics';
import { getFiltersList } from '@/containers/Report/utils/constants';
import { GOAL_KEY_QUERY_PARAM } from '@/containers/Report/utils/constants';
import {
  PageWithMetrics,
  ReportMetric,
  ReportMetricKey,
  ReportView,
} from '@/containers/Report/utils/types';
import useApiConfigurations from '@/hooks/useApiConfigurations';
import useCan from '@/hooks/useCan';
import useMapUrlQueryToFilter from '@/hooks/useMapUrlQueryToFilter';
import { useMetricsConfig } from '@/hooks/useMetricsConfig';
import { useMixpanelTrackEvent } from '@/hooks/useMixpanelTrackEvent';
import useNullUser from '@/hooks/useNullUser';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import { showSuccessSnackbar } from '@/store/actions/ui';
import { selectWorkspaceConfig } from '@/store/selectors/selectWorkspaceConfig';
import { Page } from '@/types/pages';
import { VIEW_REPORTS } from '@/utils/3rdParty/Mixpanel/constants';
import { PAGE_EXPORT_SYNC_LIMITATION } from '@/utils/configurations/constants';
import { REPORT_FILTERS } from '@/utils/constants';
import filterPages from '@/utils/filter/filterPages';
import mapFiltersToQueryParameter from '@/utils/filter/mapFiltersToQueryParameter';
import { SECTIONS } from '@/utils/log/constants';
import { Log } from '@/utils/log/Log';
import { comparisonPeriods } from '@/utils/metrics/constants';
import { getComparableDayWithToday } from '@/utils/metrics/getComparableDayWithToday';
import { getCurrentDateRangeSQLFormat } from '@/utils/metrics/getCurrentDateRangeSQLFormat';
import { getPreviousDateRangeSQLFormat } from '@/utils/metrics/getPreviousDateRangeSQLFormat';
import { downloadFileFromBlob } from '@/utils/url';

function ReportLayout() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const {
    organizationId,
    workspaceId,
    reportView = ReportView.All,
    metricKey = ReportMetricKey.overview,
  } = useParams();
  const location = useLocation();
  const [query, setQuery] = useSearchParams();
  const { t } = useTranslation();
  const urlPrefix = `/o/${organizationId}/w/${workspaceId}/reports`;
  const sidebarDataIntercomTargetPrefix = 'report_sidebar_link_';
  const { period, comparisonPeriod } = useSelector(selectWorkspaceConfig);

  const dispatch = useDispatch();
  const [exportError, setExportError] = useState(false);
  const [isExportDialogOpen, setIsExportDialogOpen] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const pageExportSyncLimitation = parseInt(useApiConfigurations(PAGE_EXPORT_SYNC_LIMITATION), 10);

  const [currentCount, setCurrentCount] = useState<ReportCountType>();

  const trackMixpanelEvent = useMixpanelTrackEvent();

  const nullUser = useNullUser();
  const rankTrackingFeatFlag = useCan({ perform: 'rank-tracking:enabled' });
  const { isFeatureEnabled: rankTrackingFeatSet } = useOrganizationFeatureSet(
    'rank-tracking:is-enabled'
  );
  const {
    data: users = [],
    isPlaceholderData: isUsersPlaceholderData,
    isFetching: isUsersFetching,
  } = useGetWorkspaceUsers();
  const {
    data: folders = [],
    isPlaceholderData: isFoldersPlaceholderData,
    isFetching: isFoldersFetching,
  } = useWorkspaceFolders();

  const isFilterDataLoading =
    (isFoldersPlaceholderData && isFoldersFetching) || (isUsersPlaceholderData && isUsersFetching);

  const filtersList = useMemo(
    () =>
      getFiltersList({
        folders,
        isRankTrackingEnabled: rankTrackingFeatSet && rankTrackingFeatFlag,
        nullUser,
        users,
      }),
    [nullUser, rankTrackingFeatFlag, rankTrackingFeatSet, users]
  );

  function onError() {
    setQuery('');
  }

  const filters = useMapUrlQueryToFilter({
    filterList: filtersList,
    onError,
  });

  const { pages: allPagesData = [], isLoading } = usePagesWithMetrics({
    comparisonPeriod,
    period,
  });

  const AllFilteredPages: PageWithMetrics[] = filterPages(allPagesData, filters);

  function getCurrentFilteredPages() {
    if (reportView === ReportView.New) {
      return AllFilteredPages.filter((page) => page.new && page?.extra?.lastPublishedAt);
    }
    if (reportView === ReportView.Existing) {
      return AllFilteredPages.filter((page) => !page.new && page?.extra?.lastPublishedAt);
    }
    return AllFilteredPages;
  }

  const filteredPages = getCurrentFilteredPages();

  const filteredPagesIds: string[] = filteredPages.map((page: any) => page.id);

  useEffect(() => {
    trackMixpanelEvent(VIEW_REPORTS, {
      metric: metricKey,
      type: reportView,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportView]);

  useEffect(() => {
    if (!isLoading) {
      const filteredAllPagesData: Page[] = filterPages(allPagesData, filters);
      const newPages = filteredAllPagesData.filter(
        (page) => page.new && page?.extra?.lastPublishedAt
      );
      const existingPages = filteredAllPagesData.filter(
        (page) => !page.new && page?.extra?.lastPublishedAt
      );
      setCurrentCount({
        existing: existingPages.length,
        new: newPages.length,
      });
    }
  }, [isLoading, reportView, filters]);

  function applyFiltersToUrl(filters = {}) {
    mapFiltersToQueryParameter({ filters, location, navigate, query, setQuery });
  }

  const menuItems = [
    {
      active: pathname.includes(`${urlPrefix}/${ReportView.All}`),
      count: (currentCount?.new || 0) + (currentCount?.existing || 0),
      icon: <FilesIcon />,
      intercomTarget: `${sidebarDataIntercomTargetPrefix}${ReportView.All}`,
      title: t('report:titles.all-contents'),
      to: {
        pathname: `${urlPrefix}/${ReportView.All}/${metricKey}`,
        search: location.search,
      },
    },
    {
      active: pathname.includes(`${urlPrefix}/${ReportView.New}`),
      count: currentCount?.new || 0,
      icon: <FileIcon />,
      intercomTarget: `${sidebarDataIntercomTargetPrefix}${ReportView.New}`,
      title: t('report:titles.new-contents'),
      to: {
        pathname: `${urlPrefix}/${ReportView.New}/${metricKey}`,
        search: location.search,
      },
    },
    {
      active: pathname.includes(`${urlPrefix}/${ReportView.Existing}`),
      count: currentCount?.existing || 0,
      icon: <UpdateIcon />,
      intercomTarget: `${sidebarDataIntercomTargetPrefix}${ReportView.Existing}`,
      title: t('report:titles.existing-contents'),
      to: {
        pathname: `${urlPrefix}/${ReportView.Existing}/${metricKey}`,
        search: location.search,
      },
    },
  ];

  const metrics = useMetricsConfig({ isNew: true });
  const metricsList = Object.values(metrics);
  const selectedTab = REPORT_FILTERS.find((o) => o.id === reportView);

  const reportContextData: ReportContext = {
    currentCount: currentCount || { existing: 0, new: 0 },
    filteredPages,
    filteredPagesIds,
    filters,
    isLoading: isLoading || !currentCount,
    setCurrentCount: (count: ReportCountType) => setCurrentCount(count),
  };

  function handleMetricClick(metricKey: ReportMetricKey) {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete(GOAL_KEY_QUERY_PARAM);
    const searchParamsWithoutGoalKey = searchParams.toString();
    navigate({
      pathname: `${urlPrefix}/${reportView}/${metricKey}`,
      search: searchParamsWithoutGoalKey,
    });
  }

  const isContentExportAsync = filteredPagesIds?.length >= pageExportSyncLimitation;

  const positionComparisonDates = useMemo(
    () => getComparableDayWithToday({ comparisonPeriod, period, today: DateTime.local() }),
    [period, comparisonPeriod]
  );

  function openExportDialog() {
    setIsExportDialogOpen(true);
  }

  function closeExportDialog() {
    setIsExportDialogOpen(false);
  }

  function handleConfirmExport(fileType: string) {
    doExportPagesReport(filteredPagesIds, fileType, reportView);
  }

  async function doExportPagesReport(pagesIds: string[], fileType: string, reportView: string) {
    try {
      setExportError(false);
      setIsExporting(true);

      if (pagesIds.length === 0) {
        return;
      }

      const currentPeriod = getCurrentDateRangeSQLFormat({ period, today: DateTime.local() });
      const previousPeriod = getPreviousDateRangeSQLFormat({
        comparisonPeriod,
        period,
        today: DateTime.local(),
      });
      const positionPreviousDate = positionComparisonDates.after;
      const positionCurrentDate = positionComparisonDates.before;
      const reportType = `${capitalize(reportView)} Content`;

      const response = await exportPagesReport({
        comparisonPeriodLabel: t(comparisonPeriods[comparisonPeriod].label),
        currentPeriod,
        fileType,
        pagesIds,
        periodLabel: period,
        positionCurrentDate,
        positionPreviousDate,
        previousPeriod,
        reportType,
        workspaceId,
      });
      if (response.isAsync) {
        dispatch(
          showSuccessSnackbar(t('report:report-container.notification.async-export-success'))
        );
      } else {
        downloadFileFromBlob({ fileBlob: response, filePrefix: 'export', fileType });
      }
    } catch (error) {
      Log.report({
        context: 'exportPagesReport',
        error: String(error),
        extra: 'Export the selected pages in report',
        section: SECTIONS.report.key,
      });
      setExportError(true);
    } finally {
      setIsExporting(false);
      setIsExportDialogOpen(false);
    }
  }

  return (
    <PageDataLayout
      sideBar={{
        content: (
          <PageDataNavigation
            menuItems={menuItems.map((menuItem) => ({
              ...menuItem,
              end: reportContextData.isLoading ? <DotsLoaderIcon /> : <span>{menuItem.count}</span>,
            }))}
            title={t('report:titles.published-contents')}
          />
        ),
        dataIntercomTarget: 'product_tour_report',
        titleContent: <span>{t('report:titles.reports')}</span>,
      }}
    >
      <div className="reports-layout__content">
        <div className="reports-layout__content__header">
          <Header
            title={menuItems.find((item) => item.active)?.title || ''}
            view={reportView as ReportView}
            onExportClick={openExportDialog}
          />

          {!isFilterDataLoading && (
            <div className="reports-layout__content__header__filters">
              <FilterGroups
                applyFilters={applyFiltersToUrl}
                // Note: remove reportView from dataIntercomTarget to avoid multiple rerenders
                dataIntercomTarget="filter_groups_reports"
                // dataIntercomTarget={`filter_groups_reports_${reportView}`}
                defaultFilters={filters}
                filtersList={filtersList}
              />
            </div>
          )}
          <ReportTabs
            currentMetricKey={metricKey as ReportMetricKey}
            metricsList={metricsList as ReportMetric[]}
            selectMetricClick={handleMetricClick}
          />
        </div>

        <Outlet context={reportContextData} />
      </div>
      <ExportDialog
        error={exportError}
        handleCancel={closeExportDialog}
        handleConfirm={handleConfirmExport}
        isAsync={isContentExportAsync}
        loading={isExporting}
        open={isExportDialogOpen}
        title={t(`report:report-container.export-modal-title-${reportView}`)}
      />
    </PageDataLayout>
  );
}

export default ReportLayout;
