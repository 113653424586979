import i18next from 'i18next';

import { HELP_CENTER_URL, ONBOARDING_STEPS, REPORT_FILTERS } from './constants';

export const PRIMARY_COUNTRIES = ['FR', 'GB', 'US', 'ES', 'DE', 'IT', 'NL'];

export function getDefaultOrganizationId(defaultOrganizationId = null, organizationAccesses = []) {
  let defaultId = defaultOrganizationId;
  if (!organizationAccesses.some((oa) => oa.organization.id === defaultId)) {
    defaultId = null;
  }
  defaultId = defaultId ?? organizationAccesses[0]?.organization?.id ?? null;

  return defaultId;
}

export function getDefaultWorkspaceId(
  defaultWorkspaceId = null,
  workspaceAccesses = [],
  currentOrganization = {},
  organizationId
) {
  let defaultId = defaultWorkspaceId;
  let userOrganizationWorkspaces = (currentOrganization.workspaces || []).filter((workspace) => {
    return !!workspaceAccesses.find((wa) => wa.workspace.id === workspace.id);
  });
  if (!userOrganizationWorkspaces.length) {
    userOrganizationWorkspaces = workspaceAccesses
      .filter((wa) => wa.workspace.organizationId === organizationId)
      .map((wa) => wa.workspace);
  }

  if (
    !userOrganizationWorkspaces.some(
      (userOrganizationWorkspace) => userOrganizationWorkspace.id === defaultWorkspaceId
    )
  ) {
    defaultId = null;
  }

  if (defaultId === null) {
    defaultId = userOrganizationWorkspaces[0]?.id || null;
  }
  return defaultId;
}

export function getOnboardingSteps(stepId) {
  const indexOfCurrent = ONBOARDING_STEPS.findIndex((step) => step.id === stepId);

  return ONBOARDING_STEPS.map((step, index) => ({
    ...step,
    isCurrent: step.id === stepId,
    isValidated: index < indexOfCurrent,
  }));
}

export function getFibonacciNthNumber(n) {
  if (n <= 3) {
    return n;
  }

  return getFibonacciNthNumber(n - 1) + getFibonacciNthNumber(n - 2);
}

export function getViewFilter(reportView) {
  return (REPORT_FILTERS.filter((o) => o.id === reportView)[0] ?? {}).filter ?? {};
}

export function twoDecimalPlacesIfNeeded(amount) {
  return amount % 1 !== 0 ? amount.toFixed(2) : amount;
}

export function generateColorBasedOnString(colors, str, length) {
  const colorLength = length || colors.length;
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const index = Math.abs(hash) % colorLength;
  const colorIndex = index >= colors.length ? colors.length - 1 : index;

  return colors[colorIndex];
}

export function getHelpCenterUrl() {
  const langCode = i18next.language.split('-')[0];
  switch (langCode) {
    case 'fr':
      return `${HELP_CENTER_URL}/fr`;
    default:
      return `${HELP_CENTER_URL}/en`;
  }
}
