import './ChartWrappers.scss';

import React from 'react';
import styled from 'styled-components/macro';

import { LegendItemIconPlain } from '../LegendItem';

export const ChartWrapper = styled.div`
  display: flex;
  flex: 1;
  min-height: 400px;
  height: 400px;
  position: relative;
  padding-left: 30px;
  padding-right: 30px;
`;

export function BadgeWrapper({ count, title, color }) {
  return (
    <div className="dashboard-badge-wrapper">
      <span className="dashboard-badge-wrapper__value">{count}</span>
      <div className="dashboard-badge-wrapper__legend">
        <LegendItemIconPlain color={color} width="10" /> {title}
      </div>
    </div>
  );
}
