const INFERIOR_VALUE_HIGH_RELEVANCE_INTERVAL = 11;
const INFERIOR_VALUE_MEDIUM_RELEVANCE_INTERVAL = 3;
const INFERIOR_VALUE_LOW_RELEVANCE_INTERVAL = 1;
const SUPERIOR_VALUE_HIGH_RELEVANCE_INTERVAL = Infinity;
const SUPERIOR_VALUE_MEDIUM_RELEVANCE_INTERVAL = 10;
const SUPERIOR_VALUE_LOW_RELEVANCE_INTERVAL = 2;

export function getRelevanceOfRecommendation(count = 0) {
  if (count >= INFERIOR_VALUE_HIGH_RELEVANCE_INTERVAL) {
    return {
      interval: `${INFERIOR_VALUE_HIGH_RELEVANCE_INTERVAL - 1}+`,
      max: SUPERIOR_VALUE_HIGH_RELEVANCE_INTERVAL,
      min: INFERIOR_VALUE_HIGH_RELEVANCE_INTERVAL,
    };
  }
  if (count >= INFERIOR_VALUE_MEDIUM_RELEVANCE_INTERVAL) {
    return {
      interval: `${INFERIOR_VALUE_MEDIUM_RELEVANCE_INTERVAL}-${SUPERIOR_VALUE_MEDIUM_RELEVANCE_INTERVAL}`,
      max: SUPERIOR_VALUE_MEDIUM_RELEVANCE_INTERVAL,
      min: INFERIOR_VALUE_MEDIUM_RELEVANCE_INTERVAL,
    };
  }

  return {
    interval: `${INFERIOR_VALUE_LOW_RELEVANCE_INTERVAL}-${SUPERIOR_VALUE_LOW_RELEVANCE_INTERVAL}`,
    max: SUPERIOR_VALUE_LOW_RELEVANCE_INTERVAL,
    min: INFERIOR_VALUE_LOW_RELEVANCE_INTERVAL,
  };
}
