import React from 'react';

import SvgWrapper from './SvgWrapper';

const PenBoxIcon = (props) => (
  <SvgWrapper {...props} height="22" viewBox="0 0 20 22" width="20">
    <path d="M18.9209 9.82815V21.0468H0V3.29792H8.87442V4.47001H1.17209V19.8747H17.7488V9.82815H18.9209ZM17.9163 1.45606C17.7488 1.28862 17.5814 1.12117 17.2465 1.12117C16.9116 1.12117 16.7442 0.953732 16.5767 0.953732C16.4093 0.953732 16.0744 0.953732 15.907 1.12117C15.7395 1.28862 15.5721 1.28862 15.4047 1.45606L6.36279 10.3305L4.35349 16.5258L10.5488 14.5165L19.4233 5.6421L19.5907 5.47466C19.5907 5.47466 19.7581 5.30722 19.7581 5.13978C19.7581 4.97234 19.7581 4.97234 19.9256 4.80489C20.093 4.63745 19.9256 4.63745 19.9256 4.47001C19.9256 4.30257 19.9256 4.30257 19.9256 4.13513C19.9256 3.96769 19.9256 3.96769 19.7581 3.80024C19.5907 3.6328 19.5907 3.6328 19.5907 3.46536C19.5907 3.29792 19.4233 3.29792 19.4233 3.13048L17.9163 1.45606ZM7.36744 11.3351L9.71163 13.6793L7.36744 14.3491L6.53023 13.6793L7.36744 11.3351ZM12.7256 5.6421L15.2372 8.15373L10.5488 12.8421L8.03721 10.3305L12.7256 5.6421ZM14.4 3.96769L16.9116 6.47931L16.0744 7.31652L13.5628 4.80489L14.4 3.96769ZM18.7535 4.80489L17.9163 5.6421L15.2372 3.13048L16.0744 2.29327C16.0744 2.12582 16.2419 2.12582 16.2419 2.12582H16.4093H16.5767L16.7442 2.29327L18.4186 3.96769C18.4186 3.96769 18.4186 3.96769 18.4186 4.13513C18.4186 4.30257 18.4186 4.30257 18.4186 4.30257V4.47001C18.4186 4.47001 18.7535 4.63745 18.7535 4.80489C18.7535 4.63745 18.7535 4.63745 18.7535 4.80489Z" />
  </SvgWrapper>
);
export default PenBoxIcon;
