import React from 'react';

import { ENUM_FILTER_TYPE, FilterType } from './index';

export class FiltersUtils {
  static hasUnselectedOptions(select: FilterType): boolean {
    return !!select.options.some((option) => !option.selected);
  }

  static removeShowMoreButton(): string | undefined {
    const elementToRemove = document.getElementById('competitors-filters__more-button');
    const className = elementToRemove?.className;
    if (elementToRemove) {
      elementToRemove.remove();
    }
    return className;
  }

  static addShowMoreButton(
    position: number,
    containerRef: React.RefObject<HTMLDivElement>,
    count = 0,
    filterCount = 0,
    previousClassName = 'competitors-filters__more-button'
  ) {
    const showMoreButton = document.createElement('div');
    showMoreButton.className = previousClassName;
    showMoreButton.id = 'competitors-filters__more-button';
    showMoreButton.addEventListener('click', () => FiltersUtils.toggleShowMore(containerRef));
    // Création de l'élément interne (CaretIcon ou +X)
    const internalElement = document.createElement('span');
    internalElement.textContent = `+${filterCount - count}`;
    showMoreButton.appendChild(internalElement);

    const lastDisplayableElement = containerRef.current?.children[position];
    if (lastDisplayableElement) {
      containerRef.current?.insertBefore(showMoreButton, lastDisplayableElement.nextSibling);
    }
  }

  static toggleShowMore(containerRef: React.RefObject<HTMLDivElement>) {
    containerRef.current?.classList.add('competitors-filters--show-all');
    document
      .getElementById('competitors-filters__more-button')
      ?.classList.add('competitors-filters__more-button--hidden');
    document
      .getElementById('competitors-filters__less-button')
      ?.classList.remove('competitors-filters__less-button--hidden');
  }

  static toggleShowLess(containerRef: React.RefObject<HTMLDivElement>) {
    containerRef.current?.classList.remove('competitors-filters--show-all');
    document
      .getElementById('competitors-filters__more-button')
      ?.classList.remove('competitors-filters__more-button--hidden');
    document
      .getElementById('competitors-filters__less-button')
      ?.classList.add('competitors-filters__less-button--hidden');
  }

  static getElementWidths(cssSelector: string): number[] {
    const elements = document.querySelectorAll(cssSelector);
    const elementWidths = Array.from(elements).map((element) => element.offsetWidth);

    return elementWidths;
  }

  static getSortSelects(selects: FilterType[]) {
    const shallowCopy = [...selects];

    const newObj = shallowCopy.map((filter) => {
      if (filter.type === ENUM_FILTER_TYPE.RADIO_RANGE) {
        return {
          ...filter,
          isActive:
            !!filter.customRange || !!filter.options.find((option) => option.selected === true),
        };
      } else {
        return {
          ...filter,
          isActive: FiltersUtils.hasUnselectedOptions(filter),
        };
      }
    });

    return newObj.sort((a, b) => b.isActive - a.isActive);
  }
}
