import styled from 'styled-components/macro';

export const ParagraphText = styled.p`
  font-family: ${({ theme }) => theme.textCss.fonts.main};
  color: ${({ theme, color }) => theme.cssColors[color || 'dark100']};
  font-size: ${({ theme, size }) => theme.textCss.sizes[size || 'default']};
  font-weight: ${({ theme, weight }) => theme.textCss.weights[weight || 'normal']};
  text-align: ${({ align }) => align};
  padding: ${({ noPadding, padding }) => (padding ? padding : noPadding ? '0.5rem 0' : '0.5rem')};
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : 'inherit')};
`;
