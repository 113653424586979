import { MutableRefObject, useRef } from 'react';

import {
  DEFAULT_PAGE,
  QUERY_PARAM_PAGE,
  QUERY_PARAM_PER_PAGE,
} from '@/components/AGGrid/hooks/useGridPagination/const';
import {
  IPagination,
  UseGridPaginationProps,
  UseGridPaginationResults,
} from '@/components/AGGrid/hooks/useGridPagination/useGridPagination.types';
import { UseGridPaginationUtils } from '@/components/AGGrid/utils/UseGridPaginationUtils';
import { ObjectUtils } from '@/utils/object/Object.utils';

export function useGridPagination({
  filterHook,
}: UseGridPaginationProps): UseGridPaginationResults {
  const [query, setQuery] = filterHook.searchParamsHook;
  const paginationRef: MutableRefObject<IPagination> = useRef<IPagination>(
    UseGridPaginationUtils.getPaginationState(query)
  );

  // Will init query params when grid is ready
  function initPagination() {
    query.set(QUERY_PARAM_PAGE, (paginationRef.current?.page as number).toString());
    query.set(QUERY_PARAM_PER_PAGE, (paginationRef.current?.itemsPerPage as number).toString());
    setQuery(query, { replace: true });
  }

  const isFirstPage = paginationRef.current.page === DEFAULT_PAGE;
  const isLastPage =
    paginationRef.current.page ===
    Math.max(paginationRef.current.pageCount ?? DEFAULT_PAGE, DEFAULT_PAGE);

  // Mutate pagination ref and query params
  function setPagination(payload: IPagination) {
    // Mutate pageCount and totalItems
    paginationRef.current = {
      ...paginationRef.current,
      pageCount: payload.pageCount,
      totalItems: payload.totalItems,
    };
    // Do not mutate pagination if payload is equal to current pagination
    if (
      ObjectUtils.fastEqual(
        { itemsPerPage: paginationRef.current.itemsPerPage, page: paginationRef.current.page },
        { itemsPerPage: payload.itemsPerPage, page: payload.page }
      )
    ) {
      return;
    }
    paginationRef.current = payload;
    query.set(QUERY_PARAM_PAGE, (paginationRef.current?.page as number).toString());
    query.set(QUERY_PARAM_PER_PAGE, (paginationRef.current?.itemsPerPage as number).toString());
    setQuery(query);
  }

  // Will change the number of items per page and set page to 1
  function setItemsPerPage(itemsPerPage: number) {
    setPagination({
      itemsPerPage,
      page: DEFAULT_PAGE,
    });
  }

  return {
    initPagination,
    isFirstPage,
    isLastPage,
    pagination: { ...paginationRef.current, ...UseGridPaginationUtils.getPaginationState(query) },
    setItemsPerPage,
    setPagination,
  };
}
