import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Ellipsis from '@/components/Text/Ellipsis';
import { CompetitorCell } from '@/containers/Content/SidePanel/Components/Optimization/Details/CompetitorCell';
import { Heat } from '@/containers/Content/SidePanel/Components/Optimization/Details/Heat';
import { RecommendationCell } from '@/containers/Content/SidePanel/Components/Optimization/Details/RecommendationCell';
import { VolumeCell } from '@/containers/Content/SidePanel/Components/Optimization/Details/VolumeCell';

const Row = styled.div`
  display: flex;
  user-select: none;
  filter: blur(3px);
`;

const HeatRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 1em 0 2em;
`;

const StyledEllipsis = styled(Ellipsis)`
  && strong {
    font-weight: 500;
  }
`;

const SENTENCES = [
  <>
    Lorem <strong>ipsum</strong> dolor sit amet
  </>,
  <>
    Ut velit <strong>mauris</strong> egestas sed
  </>,
  <>
    Aliquam convallis <strong>sollicitudin</strong> purus
  </>,
  <>
    <strong>Praesent</strong> aliquam enim at fermentum mollis
  </>,
  <>
    Aenean ut orci vel <strong>massa</strong>
  </>,
  <>
    Morbi vel erat non <strong>mauris</strong> convallis
  </>,
  <>
    <strong>Curabitur</strong> aliquet pellentesque diam
  </>,
  <>
    Nulla et <strong>sapien</strong>
  </>,
];

function BlurredHeatMapDetail({ columns }) {
  const volume = Math.floor(Math.random() * 1000);
  const sentence = SENTENCES[(Math.random() * SENTENCES.length) | 0];

  return (
    <Row>
      <RecommendationCell>
        <StyledEllipsis>{sentence}</StyledEllipsis>
      </RecommendationCell>
      <VolumeCell>{volume}</VolumeCell>
      <HeatRow>
        {Array(columns)
          .fill(null)
          .map(() => {
            const uuid = nanoid();
            return (
              <CompetitorCell key={uuid}>
                <Heat count={Math.floor(Math.random() * 4)} />
              </CompetitorCell>
            );
          })}
      </HeatRow>
    </Row>
  );
}

BlurredHeatMapDetail.propTypes = {
  columns: PropTypes.number.isRequired,
};

export default BlurredHeatMapDetail;
