import './BrandVoiceButton.scss';

import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { PlacementEnum } from 'semji-core/components/Dropdown';
import { InfoLabel } from 'semji-core/components/InfoLabel';
import {
  ENUM_MENU_SELECT_POSITION,
  ENUM_MENU_SELECT_TYPE,
  MenuSelectDropdown,
} from 'semji-core/components/MenuSelect';
import { Tooltip } from 'semji-core/components/Tooltip';
import { getClassNames } from 'semji-core/utils/getClassNames';

import useGetAllBrandVoicesByWorkspaceId from '@/apis/semji/brandVoices/useGetAllBrandVoicesByWorkspaceId';
import PenIcon from '@/components/icons/PenIcon';
import useOrganizationFeatureSet, {
  AI_WRITING__DEPRECATED_BRAND_VOICE_CONFIGURATOR__IS_ENABLED,
} from '@/hooks/useOrganizationFeatureSet';
import { setBrandVoice } from '@/store/actions/brandVoiceByWorkspaceId';
import useParamSelector from '@/store/hooks/useParamSelector';
import { selectBrandVoiceByWorkspaceId } from '@/store/selectors/selectBrandVoiceByWorkspaceId';
import { BrandVoiceModel } from '@/types/brandVoice/brandVoice.types';

export default function BrandVoiceButton() {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const dropDrownActionsRef = useRef({
    handleClose: () => null,
    handleOpen: () => null,
  });
  const { workspaceId, organizationId } = useParams();
  const { data: brandVoices } = useGetAllBrandVoicesByWorkspaceId(workspaceId, { enabled: true });

  const brandVoiceSelected = useParamSelector(selectBrandVoiceByWorkspaceId, workspaceId);
  const { isFeatureEnabled: brandVoiceConfigurationEnabled } = useOrganizationFeatureSet(
    AI_WRITING__DEPRECATED_BRAND_VOICE_CONFIGURATOR__IS_ENABLED
  );
  const navigate = useNavigate();

  function handleClickChosenBrandVoice(value: BrandVoiceModel | null) {
    dispatch(setBrandVoice({ value, workspaceId }));
  }

  function createNewBrandVoice() {
    navigate(`/o/${organizationId}/w/${workspaceId}/brand-voice/create`);
  }

  const items = [
    {
      id: null,
      label: t('brand-voice:listing.no-brand-voice'),
      onSelect: () => {
        handleClickChosenBrandVoice(null);
        dropDrownActionsRef.current.handleClose();
      },
      position: ENUM_MENU_SELECT_POSITION.TOP,
      type: ENUM_MENU_SELECT_TYPE.DEFAULT,
      value: null,
    },
    ...(brandVoices?.map((brandVoice) => ({
      id: brandVoice.id,
      label: brandVoice.name,
      onSelect: () => {
        handleClickChosenBrandVoice(brandVoice);
        dropDrownActionsRef.current.handleClose();
      },
      position: ENUM_MENU_SELECT_POSITION.DEFAULT,
      type: ENUM_MENU_SELECT_TYPE.DEFAULT,
      value: brandVoice.id,
    })) ?? []),
    brandVoiceConfigurationEnabled && {
      id: 'create-new-brand-voice',
      label: t('brand-voice:badge.tooltip.create-new-brand-voice'),
      onSelect: createNewBrandVoice,
      position: ENUM_MENU_SELECT_POSITION.BOTTOM,
      type: ENUM_MENU_SELECT_TYPE.BUTTON,
      value: null,
    },
  ];

  return (
    <MenuSelectDropdown
      actionsRef={dropDrownActionsRef}
      anchorElement={
        <div
          className={getClassNames(
            'brand-voice-button',
            brandVoiceSelected?.id && 'brand-voice-button--selected'
          )}
          data-intercom-target="brand-voice-toggle"
        >
          <Tooltip
            description={t(`brand-voice:badge.tooltip.activate-brand-voice`)}
            isFrame={false}
            popoverOptions={{ placement: 'bottom' }}
            title={
              <div className="flex gap-2">
                <span>{t('brand-voice:badge.tooltip.ai-brand-voice')}</span>
                <InfoLabel variant="beta">{t(`common:labels.beta`)}</InfoLabel>
              </div>
            }
            tooltipClassName="brand-voice-button__tooltip"
          >
            <PenIcon />
          </Tooltip>
        </div>
      }
      dropdownPlacement={PlacementEnum.DROPDOWN_PLACEMENT_BOTTOM_START}
      isFrame={false}
      items={items}
      itemSelected={brandVoiceSelected?.id ?? null}
      width="306px"
    />
  );
}
