import { nanoid } from 'nanoid';
import React, { useMemo } from 'react';

import SvgWrapper from './SvgWrapper';

const PercentIcon = (props) => {
  const maskId = useMemo(() => nanoid(), []);
  return (
    <SvgWrapper {...props}>
      <mask
        height="22"
        id={maskId}
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="19"
        x="1"
        y="1"
      >
        <path
          d="M13.442 2.558a.625.625 0 0 1 0 .884l-10 10a.625.625 0 1 1-.884-.884l10-10a.625.625 0 0 1 .884 0zM4.5 6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zm7 6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
          fill="#737373"
          id="mainIconPathAttribute"
        ></path>
      </mask>
      <g mask={`url(#${maskId})`}>
        <rect fill="currentColor" height="22" width="19" x="-2" y="-1" />
      </g>
    </SvgWrapper>
  );
};

export default PercentIcon;
