import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import { TabBodyContent, TabBodyTitle } from '@/components/Navigation/Tabs';
import UpgradePlan from '@/containers/Billing/UpgradePlan';
import CustomPlan from '@/containers/Settings/Organization/Billing/CustomPlan';
import Documents from '@/containers/Settings/Organization/Billing/Documents';
import Information from '@/containers/Settings/Organization/Billing/Information';
import Invoice from '@/containers/Settings/Organization/Billing/Invoice';
import Plan from '@/containers/Settings/Organization/Billing/Plan';
import useCan from '@/hooks/useCan';
import { SUBSCRIPTION_STATUS } from '@/utils/billing';

const SUBSCRIPTION_TYPES = {
  MANAGED: 'MANAGED',
  SELF_SERVE: 'SELF_SERVE',
  TRIAL: 'TRIAL',
};

export const Section = styled.div`
  padding: 2rem 0;
`;

function BillingSettings(props) {
  const { organizationId } = useParams();
  const currentOrganization = useSelector((state) => state.organizations.byId[organizationId]);
  const isSelfServeEnabled = useCan({ perform: 'self-serve:enabled' });

  if (currentOrganization.subscriptionType === SUBSCRIPTION_TYPES.MANAGED) {
    return (
      <TabBodyContent>
        <TabBodyTitle noMargin>{props.title}</TabBodyTitle>
        <CustomPlan currentOrganization={currentOrganization} />
      </TabBodyContent>
    );
  }

  if (
    currentOrganization.subscriptionType === SUBSCRIPTION_TYPES.TRIAL ||
    currentOrganization.subscriptionStatus === SUBSCRIPTION_STATUS.in_trial ||
    (!isSelfServeEnabled &&
      currentOrganization.subscriptionStatus === SUBSCRIPTION_STATUS.cancelled_trial)
  ) {
    return (
      <TabBodyContent>
        <TabBodyTitle noMargin>{props.title}</TabBodyTitle>
        <Plan currentOrganization={currentOrganization} disableCta />
        <Section>
          <UpgradePlan organizationId={currentOrganization.id} />
        </Section>
      </TabBodyContent>
    );
  }

  return (
    <TabBodyContent>
      <TabBodyTitle noMargin>{props.title}</TabBodyTitle>
      <Plan currentOrganization={currentOrganization} />
      <Invoice />
      {!isSelfServeEnabled &&
      currentOrganization.subscriptionStatus ===
        SUBSCRIPTION_STATUS.cancelled_subscription ? null : (
        <Information />
      )}
      <Documents />
    </TabBodyContent>
  );
}

export default BillingSettings;
