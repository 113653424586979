import styled from 'styled-components/macro';

const Wrapper = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 1fr auto 1fr;
  width: 100%;
`;

const LineThrough = styled.hr`
  border: none;
  border-top: 0.5px solid ${({ theme }) => theme.cssColors.dark040};
  width: 100%;
  margin: 0;
`;

const Text = styled.span`
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.cssColors.white};
  line-height: ${({ theme }) => theme.textCss.sizes.lg};
  font-size: ${({ theme }) => theme.textCss.sizes.sm};
  font-weight: ${({ theme, weight }) => theme.textCss.weights[weight || 'strong']};
  font-family: ${(props) => props.theme.textCss.fonts.main};
  color: ${({ theme }) => theme.cssColors.dark040};
  text-align: center;
  text-transform: ${({ transform }) => transform || 'uppercase'};
  margin: 0 10px;
`;

function Separator({ children, className, ...props }) {
  return (
    <Wrapper>
      <LineThrough className={className} />
      <Text {...props}>{children}</Text>
      <LineThrough className={className} />
    </Wrapper>
  );
}

export default Separator;
