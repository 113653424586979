import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { checkUrl, fetchCompanyInformation } from '@/apis/semji/api';
import {
  OnboardingBodyWrapper,
  OnboardingFooterWrapper,
  OnboardingTitleWrapper,
  OnboardingWrapper,
} from '@/components/Layout/Layout';
import WithActionStepperFooter from '@/components/Stepper/WithActionStepperFooter';
import { SecondaryTitleOne, TitleThree } from '@/components/Text/Title';
import { CREATE_WORKSPACE_STEPS } from '@/containers/CreateWorkspace';
import ConnectWorkspace from '@/containers/CreateWorkspace/Connect/ConnectWorkspace';
import CreateWorkspace, {
  ACCESSIBLE_STATUS,
  ERROR_DOMAIN_STATUS,
  LOADING_URL_STATUS,
  REDIRECTION_DETECTED_STATUS,
  UNABLE_TO_ACCESS_STATUS,
} from '@/containers/CreateWorkspace/Create/CreateWorkspace';
import InviteWorkspace from '@/containers/CreateWorkspace/Invite/InviteWorkspace';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import OrganizationService from '@/services/Organization';
import OrganizationAccessesService from '@/services/OrganizationAccesses';
import ServiceIntegrationService from '@/services/ServiceIntegration';
import WorkspaceAccessesService from '@/services/WorkspaceAccesses';
import {
  resetWorkspaceData,
  setLocation,
  setOrganizationId,
  setServiceIntegration,
  setUsers,
  setWebsiteUrl,
  setWorkspaceName,
} from '@/store/actions/createWorkspace';
import { fetchAuthenticatedUser } from '@/store/actions/user';
import { changeDefaultWorkspaceId } from '@/store/actions/workspace';
import useParamSelector from '@/store/hooks/useParamSelector';
import { selectCreateWorkspaceData } from '@/store/selectors/selectCreateWorkspaceData';
import { selectOrganizationAvailableSeats } from '@/store/selectors/selectOrganizationAvailableSeats';
import { SEARCH_CONSOLE_VALUE } from '@/utils/constants';
import { isValidEmail } from '@/utils/email';
import { getOriginFromUrl, hasGoodUrlPattern } from '@/utils/url';

function getNewUsersFromEmails(emails, alreadyInvitedUsers) {
  const uniqEmails = [...new Set(emails)];
  return uniqEmails.filter((email) => isValidEmail(email) && !alreadyInvitedUsers.includes(email));
}

function getAlreadyUsersFromEmails(emails, alreadyInvitedUsers) {
  const uniqEmails = [...new Set(emails)];
  return uniqEmails.filter((email) => isValidEmail(email) && alreadyInvitedUsers.includes(email));
}

function Stepper({ currentStepKey, previousStep, nextStep, cancelable, isMobileVersion }) {
  const navigate = useNavigate();
  const { organizationId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  if (!cancelable) {
    delete CREATE_WORKSPACE_STEPS['users'];
  }

  const currentObjectLength = Object.keys(CREATE_WORKSPACE_STEPS).length;
  const { stepKey, title, title_cancelable_step, subTitle, position } =
    CREATE_WORKSPACE_STEPS[currentStepKey];
  const [creatingWorkspace, setCreatingWorkspace] = useState(false);
  const [organizationUsersEmails, setOrganizationUsersEmails] = useState([]);
  const [error, setError] = useState(null);
  const configuration = useParamSelector(selectCreateWorkspaceData, organizationId);
  const [urlError, setUrlError] = useState(false);
  const [isUrlLoading, setIsUrlLoading] = useState(false);
  const [websiteUrlStatus, setWebsiteUrlStatus] = useState(null);
  const [redirectedUrl, setRedirectedUrl] = useState(null);
  const [previousWebsiteUrl, setPreviousWebsiteUrl] = useState(null);

  const { isFeatureEnabled } = useOrganizationFeatureSet('users:invitations:has-unlimited-amount');
  const availableSeats = useSelector(selectOrganizationAvailableSeats);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    fetchAccesses();
    if (configuration?.organizationId !== organizationId) {
      dispatch(resetWorkspaceData(organizationId)); // the data is reset but not for the website url state
    }
    dispatch(setOrganizationId(organizationId));
    if (configuration?.workspace?.websiteUrl?.length) {
      checkUrlValue(configuration?.workspace?.websiteUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!configuration?.workspace?.websiteUrl) {
      setDefaultWebsiteUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    dispatch(resetWorkspaceData(organizationId));
    navigate(`/`);
  };

  const fetchAccesses = async () => {
    const _OrganizationAccessesService = new OrganizationAccessesService(null, organizationId);
    const organizationAccesses = await _OrganizationAccessesService.organizationAccesses;

    setOrganizationUsersEmails(organizationAccesses.map((oa) => oa.user.email));
  };

  function onChangeWorkspaceName(event) {
    dispatch(setWorkspaceName(event.target.value, organizationId, true));
  }

  const setOnErrorWebsiteUrl = () => {
    setWebsiteUrlStatus(ERROR_DOMAIN_STATUS);
    setUrlError(true);
    setIsUrlLoading(false);
  };

  const checkUrlValue = async (url) => {
    if (!hasGoodUrlPattern(url)) {
      setOnErrorWebsiteUrl();
      return;
    }

    setIsUrlLoading(true);
    setWebsiteUrlStatus(LOADING_URL_STATUS);
    setRedirectedUrl(null);
    setUrlError(false);

    try {
      const urlData = new URL(url).origin;
      const checkUrlValue = await checkUrl(urlData + '/');

      if (!checkUrlValue.isDomainValid) {
        setOnErrorWebsiteUrl();
        return;
      } else if (checkUrlValue.isWebsiteResponding) {
        if (checkUrlValue.url === checkUrlValue.finalUrl) {
          setWebsiteUrlStatus(ACCESSIBLE_STATUS);
        } else {
          setWebsiteUrlStatus(REDIRECTION_DETECTED_STATUS);
          setRedirectedUrl(checkUrlValue.finalUrl);
        }
      } else {
        setWebsiteUrlStatus(UNABLE_TO_ACCESS_STATUS);
      }

      if (!configuration.workspace.isNameDirty && checkUrlValue.workspaceName) {
        dispatch(setWorkspaceName(checkUrlValue.workspaceName, organizationId, false));
      }

      dispatch(setWebsiteUrl(url, organizationId));
    } catch {
      setWebsiteUrlStatus(UNABLE_TO_ACCESS_STATUS);
    } finally {
      setIsUrlLoading(false);
    }
  };

  const setDefaultWebsiteUrl = async () => {
    try {
      const { websiteUrl = '' } = await fetchCompanyInformation(user.id);
      setPreviousWebsiteUrl(websiteUrl);
      dispatch(setWebsiteUrl(websiteUrl, organizationId));
      onChangeWebSiteUrl(websiteUrl);
    } catch (err) {}
  };

  function onChangeWebSiteUrl(url) {
    if (!url) {
      dispatch(setWebsiteUrl(url, organizationId));
      setPreviousWebsiteUrl(url);
      setWebsiteUrlStatus(null);
      return;
    }
    if (
      (url !== configuration?.workspace?.websiteUrl && websiteUrlStatus !== ERROR_DOMAIN_STATUS) ||
      (websiteUrlStatus === ERROR_DOMAIN_STATUS && previousWebsiteUrl !== url)
    ) {
      dispatch(
        setServiceIntegration(
          {
            ...configuration.dataSources,
            searchConsole: null,
          },
          organizationId
        )
      );
      checkUrlValue(url);
    }
    setPreviousWebsiteUrl(url);
  }

  function onChangeLocation(location, isLocationDirty = true) {
    dispatch(setLocation(location, organizationId, isLocationDirty));
  }

  function onChangeDataSource(dataSources) {
    dispatch(setServiceIntegration(dataSources, organizationId));
  }

  function onChangeUsers(users) {
    dispatch(setUsers(users, organizationId));
  }

  const isNextDisabled = (stepKey) => {
    switch (stepKey) {
      case CREATE_WORKSPACE_STEPS.general.stepKey:
        return generalStepIsNotValid(configuration.workspace, configuration.location);
      case CREATE_WORKSPACE_STEPS.dataSources.stepKey:
        return dataSourcesStepIsNotValid(configuration.dataSources) || creatingWorkspace;
      case CREATE_WORKSPACE_STEPS.users.stepKey:
        return usersStepIsNotValid(configuration.users) || creatingWorkspace;
      default:
        return true;
    }
  };

  const dataSourcesStepIsNotValid = (dataSources) => {
    return dataSources.searchConsole === null;
  };

  const usersStepIsNotValid = (listUsers) => {
    return listUsers.filter((email) => !!email).some((email) => !isValidEmail(email));
  };

  const generalStepIsNotValid = (workspace, location) =>
    !workspace.name || !workspace.websiteUrl || !location.languageCode || !location.countryCode;

  const infoHelper = () => {
    if (!cancelable) {
      return;
    }
    if (
      usersStepIsNotValid(configuration.users) &&
      stepKey === CREATE_WORKSPACE_STEPS.users.stepKey
    ) {
      return t('create-workspace:errors.emails');
    }

    if (error) {
      return error;
    }

    return '';
  };

  const isOnError = () => {
    if (!cancelable) {
      return;
    }
    if (
      usersStepIsNotValid(configuration.users) &&
      stepKey === CREATE_WORKSPACE_STEPS.users.stepKey
    ) {
      return true;
    }

    return error;
  };

  function handlePrevious() {
    previousStep();
    // manually handle reset Error on previous click
    setError(false);
  }

  function handleNext() {
    if (position !== currentObjectLength - 1) {
      nextStep?.();
    } else {
      if (
        (configuration.dataSources.searchConsole &&
          Object.keys(configuration.dataSources.searchConsole).length > 1) ||
        cancelable
      ) {
        createWorkspace();
      } else {
        activateLater();
      }
    }
  }

  const getWorkspaceWithSimpleURL = () => {
    return {
      ...configuration.workspace,
      websiteUrl: getOriginFromUrl(configuration.workspace.websiteUrl),
    };
  };

  const activateLater = async () => {
    setCreatingWorkspace(true);
    setError(false);

    try {
      const _OrganizationService = new OrganizationService(organizationId);

      const workspace = await _OrganizationService.createWorkspace({
        ...getWorkspaceWithSimpleURL(),
        ...configuration.location,
      });

      // after create we fetch the authenticated user to fetch his accesses
      // and then we set the default workspace id to the created workspace
      await dispatch(fetchAuthenticatedUser());
      await dispatch(changeDefaultWorkspaceId(workspace.id));

      setCreatingWorkspace(false);
      dispatch(resetWorkspaceData());
      navigate(`/`, { replace: true });
    } catch (e) {
      setError(t('create-workspace:errors.something-went-wrong'));
      setCreatingWorkspace(false);
    }
  };

  const createWorkspace = async () => {
    setCreatingWorkspace(true);
    setError(false);

    try {
      const _OrganizationService = new OrganizationService(organizationId);
      const _ServiceIntegration = new ServiceIntegrationService();

      const workspace = await _OrganizationService.createWorkspace({
        ...getWorkspaceWithSimpleURL(),
        ...configuration.location,
      });

      //On success, post services integrations && Add users to current workspaces (max to allowed users)
      const _WorkspaceAccess = new WorkspaceAccessesService(null, workspace.id);
      const newUsers = getNewUsersFromEmails(configuration.users, organizationUsersEmails);
      const alreadyUsers = getAlreadyUsersFromEmails(configuration.users, organizationUsersEmails);

      if (
        !isFeatureEnabled &&
        newUsers.length + alreadyUsers.length > 0 &&
        availableSeats < newUsers.length
      ) {
        newUsers.splice(availableSeats, newUsers.length - 1);
      }
      const listUsers = [...newUsers, ...alreadyUsers].map((email) => ({ email }));

      await Promise.all(
        [
          configuration.dataSources.searchConsole?.name === SEARCH_CONSOLE_VALUE &&
            _ServiceIntegration.createSearchConsoleIntegration({
              ...configuration.dataSources.searchConsole,
              workspaceId: workspace.id,
            }),
          listUsers.length > 0 && _WorkspaceAccess.inviteUsers(listUsers),
        ].filter(Boolean)
      );

      // after create we fetch the authenticated user to fetch his accesses
      // and then we set the default workspace id to the created workspace
      await dispatch(fetchAuthenticatedUser());
      await dispatch(changeDefaultWorkspaceId(workspace.id));

      setCreatingWorkspace(false);
      dispatch(resetWorkspaceData(organizationId));
      navigate(`/`, { replace: true });
    } catch (e) {
      setError(t('create-workspace:errors.something-went-wrong'));
      setCreatingWorkspace(false);
    }
  };

  const renderBody = (stepKey) => {
    switch (stepKey) {
      case CREATE_WORKSPACE_STEPS.general.stepKey:
        return (
          <CreateWorkspace
            isUrlLoading={isUrlLoading}
            location={configuration.location}
            redirectedUrl={redirectedUrl}
            user={user}
            websiteUrlStatus={websiteUrlStatus}
            workspace={configuration.workspace}
            onChangeLocation={onChangeLocation}
            onChangeWebSiteUrl={onChangeWebSiteUrl}
            onChangeWorkspaceName={onChangeWorkspaceName}
          />
        );
      case CREATE_WORKSPACE_STEPS.dataSources.stepKey:
        return (
          <ConnectWorkspace
            countryCode={configuration.location?.countryCode}
            dataSources={configuration.dataSources}
            handlePrevious={handlePrevious}
            isObvious={configuration.location?.isObvious}
            languageCode={configuration.location?.languageCode}
            workspaceName={configuration.workspace.name}
            workspaceWebSiteUrl={configuration.workspace.websiteUrl}
            onChangeDataSource={onChangeDataSource}
          />
        );
      case CREATE_WORKSPACE_STEPS.users.stepKey:
        return (
          <InviteWorkspace
            availableSeats={availableSeats}
            seatsWillBeConsumed={
              getNewUsersFromEmails(configuration.users, organizationUsersEmails).length
            }
            users={configuration.users}
            onChangeUsers={onChangeUsers}
          />
        );
      default:
        return null;
    }
  };

  return (
    <OnboardingWrapper $isMobileVersion={isMobileVersion}>
      <OnboardingTitleWrapper>
        <SecondaryTitleOne noMargin weight="medium">
          {t(cancelable ? title_cancelable_step : title)}
        </SecondaryTitleOne>
        <br />
        <TitleThree color="dark060" noMargin>
          {t(subTitle)}
        </TitleThree>
      </OnboardingTitleWrapper>
      <OnboardingBodyWrapper>{renderBody(stepKey)}</OnboardingBodyWrapper>
      <OnboardingFooterWrapper>
        <WithActionStepperFooter
          handleCancel={cancelable ? handleCancel : null}
          handleNext={handleNext}
          handlePrevious={position !== 0 ? handlePrevious : null}
          info={infoHelper()}
          infoOnError={isOnError()}
          isLoading={isUrlLoading}
          isNextDisabled={isNextDisabled(stepKey) || urlError}
          nextLabel={
            position !== currentObjectLength - 1
              ? t('create-workspace:actions.next')
              : creatingWorkspace
                ? t('create-workspace:actions.validating')
                : t('create-workspace:actions.go-to-semji')
          }
        />
      </OnboardingFooterWrapper>
    </OnboardingWrapper>
  );
}

export default Stepper;
