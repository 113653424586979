export const makeCancelable = (promise) => {
  let hasBeenCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise
      .then((val) => (hasBeenCanceled_ ? reject({ isCanceled: true }) : resolve(val)))
      .catch((error) => (hasBeenCanceled_ ? reject({ isCanceled: true }) : reject(error)));
  });

  return {
    cancel() {
      hasBeenCanceled_ = true;
    },
    promise: wrappedPromise,
  };
};
