import './CompetitorReport.scss';

import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getClassNames } from 'semji-core/utils/getClassNames';
import { capitalize } from 'semji-core/utils/string';

import {
  useGetCompetitorReport3YearMetrics,
  useGetCompetitorReportMetrics,
} from '@/apis/semji/competitors';
import { BrandTrafficStackedBarReport } from '@/containers/Competitors/Duel/Report/BrandTrafficStackedBarReport';
import { EvolutionReport } from '@/containers/Competitors/Duel/Report/EvolutionReport';
import { HEvolutionReport } from '@/containers/Competitors/Duel/Report/HEvolutionReport';
import { MarketingStackedBarReport } from '@/containers/Competitors/Duel/Report/MarketingStackedBarReport';
import { PageStackedBarReport } from '@/containers/Competitors/Duel/Report/PageStackedBarReport';
import { PersonaStackedBarReport } from '@/containers/Competitors/Duel/Report/PersonaStackedBarReport';
import { PositionStackedBarReport } from '@/containers/Competitors/Duel/Report/PositionStackedBarReport';
import { TailKeywordStackedBarReport } from '@/containers/Competitors/Duel/Report/TailKeywordStackedBarReport';
import { ThemeStackedBarReport } from '@/containers/Competitors/Duel/Report/ThemeStackedBarReport';
import { useCompetitor } from '@/containers/Competitors/hooks/useCompetitor';
import { CompetitorDrillUtils } from '@/containers/Competitors/utils/CompetitorDrill.utils';
import { COMPETITORS_METRIC_TYPES } from '@/containers/Competitors/utils/constants';
import useFilters from '@/hooks/useFilters/useFilters';
import { MULTI_SEARCH_OPERATOR } from '@/hooks/useFilters/useFilters.types';
import { CompetitorTopPagesFilters } from '@/types/competitors';

import { Header } from './Header';

export default function CompetitorReport() {
  const { t } = useTranslation();

  const { workspaceId, organizationId, reportId: competitorReportId } = useParams();
  const baseUrl = `/o/${organizationId}/w/${workspaceId}/search-intelligence/${competitorReportId}/data-explorer`;

  const { competitorWebsites, competitorReportDateStart, competitorComparativeDates, clusters } =
    useCompetitor();

  const filterHook = useFilters<CompetitorTopPagesFilters>();

  const competitorsFilter =
    filterHook.findFilterByKey('competitorWebsiteId', {
      targetOperator: MULTI_SEARCH_OPERATOR.multi_search,
    }) ?? competitorWebsites.map(({ id }) => id);

  const filters = useMemo(
    () => filterHook.buildTransformedFilters(filterHook.filters),
    [filterHook.filters]
  );

  const handleOpenDataExplorer = CompetitorDrillUtils.openDrilledDataExplorer({
    baseUrl,
    filterHook,
  });

  // For horizontal charts
  const {
    data: metrics12MonthsEvolution = [],
    isLoading: metrics12MonthsEvolutionLoading,
    isPlaceholderData: isMetrics12MonthsEvolutionPlaceholder,
  } = useGetCompetitorReportMetrics({
    competitorReportId: String(competitorReportId),
    filters: {
      ...filters,
      ...filterHook.buildTransformedFilters<any>({
        date: {
          equals: competitorReportDateStart,
        },
      }),
    },
    staleTime: 0,
  });
  const isMetrics12MonthsEvolutionLoading =
    metrics12MonthsEvolutionLoading || isMetrics12MonthsEvolutionPlaceholder;

  // For 3 years metrics
  const {
    data: competitor3YearsMetrics,
    isLoading: competitor3YearsMetricsLoading,
    isPlaceholderData: isCompetitor3YearsMetricsPlaceholder,
  } = useGetCompetitorReport3YearMetrics({
    compareDates: competitorComparativeDates ?? [],
    competitorReportId: String(competitorReportId),
    enabled: Boolean(competitorReportId && competitorComparativeDates),
    filters,
    staleTime: 0,
  });
  const isCompetitor3YearsMetricsLoading =
    competitor3YearsMetricsLoading || isCompetitor3YearsMetricsPlaceholder;

  // Current year metrics
  const {
    data: currentYearMetrics = [],
    isLoading: isCurrentYearMetricsLoad,
    isPlaceholderData: isCurrentYearMetricsPlaceholder,
  } = useGetCompetitorReportMetrics({
    competitorReportId: String(competitorReportId),
    filters,
    staleTime: 0,
  });
  const isCurrentYearMetricsLoading = isCurrentYearMetricsLoad || isCurrentYearMetricsPlaceholder;

  const lower11Competitors = competitorWebsites.length < 11;

  return (
    <div className="competitor-report">
      <Header />
      <div className="competitor-report__content">
        <EvolutionReport
          competitorsFilter={competitorsFilter}
          handleOpenDataExplorer={handleOpenDataExplorer}
          isLoading={isCompetitor3YearsMetricsLoading}
          isMinimizable={false}
          metrics={competitor3YearsMetrics}
          title={t('competitors:reports.report.evolution-organic-traffic.title')}
          tooltip={{
            description: t('competitors:source-normalized-traffic'),
            title: t('competitors:reports.report.evolution-organic-traffic.tooltip', {
              date1: capitalize(
                DateTime.fromISO(competitorComparativeDates?.[0])
                  .minus({ years: 2 })
                  .set({ month: 1 })
                  .toFormat('LLLL yyyy')
              ),
              date2: capitalize(
                DateTime.fromISO(competitorComparativeDates?.[0])
                  .minus({ years: 1 })
                  .set({ month: 1 })
                  .toFormat('LLLL yyyy')
              ),
              date3: capitalize(
                DateTime.fromISO(competitorComparativeDates?.[0]).toFormat('LLLL yyyy')
              ),
            }),
          }}
          type={COMPETITORS_METRIC_TYPES.traffic}
        />
        <div
          className={getClassNames(
            lower11Competitors
              ? 'competitor-report__content__group-chart'
              : 'competitor-report__content__group-chart__full'
          )}
        >
          <div
            className={getClassNames(
              lower11Competitors
                ? 'competitor-report__content__group-chart__item'
                : 'competitor-report__content__group-chart__full__item'
            )}
          >
            <EvolutionReport
              competitorsFilter={competitorsFilter}
              handleOpenDataExplorer={handleOpenDataExplorer}
              isLoading={isCompetitor3YearsMetricsLoading}
              isMinimizable
              metrics={competitor3YearsMetrics}
              title={t('competitors:reports.report.evolution-unbranded-traffic.title')}
              tooltip={{
                description: t('competitors:source-normalized-traffic'),
                title: t('competitors:reports.report.evolution-unbranded-traffic.tooltip', {
                  date1: capitalize(
                    DateTime.fromISO(competitorComparativeDates?.[0])
                      .minus({ years: 2 })
                      .set({ month: 1 })
                      .toFormat('LLLL yyyy')
                  ),
                  date2: capitalize(
                    DateTime.fromISO(competitorComparativeDates?.[0])
                      .minus({ years: 1 })
                      .set({ month: 1 })
                      .toFormat('LLLL yyyy')
                  ),
                  date3: capitalize(
                    DateTime.fromISO(competitorComparativeDates?.[0]).toFormat('LLLL yyyy')
                  ),
                }),
              }}
              type={COMPETITORS_METRIC_TYPES.unbranded}
            />
          </div>
          <div
            className={getClassNames(
              lower11Competitors
                ? 'competitor-report__content__group-chart__item'
                : 'competitor-report__content__group-chart__full__item'
            )}
          >
            <EvolutionReport
              competitorsFilter={competitorsFilter}
              handleOpenDataExplorer={handleOpenDataExplorer}
              isLoading={isCompetitor3YearsMetricsLoading}
              isMinimizable
              metrics={competitor3YearsMetrics}
              title={t('competitors:reports.report.evolution-page-traffic.title')}
              tooltip={{
                title: t('competitors:reports.report.evolution-page-traffic.tooltip', {
                  date1: capitalize(
                    DateTime.fromISO(competitorComparativeDates?.[0])
                      .minus({ years: 2 })
                      .set({ month: 1 })
                      .toFormat('LLLL yyyy')
                  ),
                  date2: capitalize(
                    DateTime.fromISO(competitorComparativeDates?.[0])
                      .minus({ years: 1 })
                      .set({ month: 1 })
                      .toFormat('LLLL yyyy')
                  ),
                  date3: capitalize(
                    DateTime.fromISO(competitorComparativeDates?.[0]).toFormat('LLLL yyyy')
                  ),
                }),
              }}
              type={COMPETITORS_METRIC_TYPES.page}
            />
          </div>
        </div>

        <div className="competitor-report__content__group-chart competitor-report__content__group-chart--desktop">
          <div className="competitor-report__content__group-chart__item">
            <HEvolutionReport
              color="#1F4594"
              competitorsFilter={competitorsFilter}
              handleOpenDataExplorer={handleOpenDataExplorer}
              isLoading={isMetrics12MonthsEvolutionLoading && isCurrentYearMetricsLoading}
              metrics={{
                currentMetrics: currentYearMetrics,
                previousMetrics: metrics12MonthsEvolution ?? [],
              }}
              title={t('competitors:reports.report.traffic-evolution-12.title')}
              tooltip={{
                description: t('competitors:source-normalized-traffic'),
                title: t('competitors:reports.report.traffic-evolution-12.tooltip', {
                  dateEnd: capitalize(
                    DateTime.fromISO(competitorReportDateStart)
                      .plus({ years: 1 })
                      .toFormat('MMMM yyyy')
                  ),
                  dateStart: capitalize(
                    DateTime.fromISO(competitorReportDateStart).toFormat('MMMM yyyy')
                  ),
                }),
              }}
              type={COMPETITORS_METRIC_TYPES.traffic}
            />
          </div>
          <div className="competitor-report__content__group-chart__item">
            <HEvolutionReport
              color="#F97D7D"
              competitorsFilter={competitorsFilter}
              handleOpenDataExplorer={handleOpenDataExplorer}
              isLoading={isMetrics12MonthsEvolutionLoading && isCurrentYearMetricsLoading}
              metrics={{
                currentMetrics: currentYearMetrics,
                previousMetrics: metrics12MonthsEvolution,
              }}
              title={t('competitors:reports.report.page-number-evolution-12.title')}
              tooltip={{
                title: t('competitors:reports.report.page-number-evolution-12.tooltip', {
                  dateEnd: capitalize(
                    DateTime.fromISO(competitorReportDateStart)
                      .plus({ years: 1 })
                      .toFormat('MMMM yyyy')
                  ),
                  dateStart: capitalize(
                    DateTime.fromISO(competitorReportDateStart).toFormat('MMMM yyyy')
                  ),
                }),
              }}
              type={COMPETITORS_METRIC_TYPES.page}
            />
          </div>
        </div>

        <div
          className={getClassNames(
            lower11Competitors
              ? 'competitor-report__content__group-chart'
              : 'competitor-report__content__group-chart__full'
          )}
        >
          <div
            className={getClassNames(
              lower11Competitors
                ? 'competitor-report__content__group-chart__item'
                : 'competitor-report__content__group-chart__full__item'
            )}
          >
            <BrandTrafficStackedBarReport
              competitorReportId={competitorReportId}
              competitorsFilter={competitorsFilter}
              filters={filters}
              handleOpenDataExplorer={handleOpenDataExplorer}
              isLoading={isCurrentYearMetricsLoading}
              metrics={currentYearMetrics}
            />
          </div>
          <div
            className={getClassNames(
              lower11Competitors
                ? 'competitor-report__content__group-chart__item'
                : 'competitor-report__content__group-chart__full__item'
            )}
          >
            <PageStackedBarReport
              competitorReportId={competitorReportId}
              competitorsFilter={competitorsFilter}
              filters={filters}
              handleOpenDataExplorer={handleOpenDataExplorer}
            />
          </div>
        </div>

        <ThemeStackedBarReport
          clusters={clusters}
          competitorReportId={competitorReportId}
          competitorsFilter={competitorsFilter}
          filters={filters}
          handleOpenDataExplorer={handleOpenDataExplorer}
          isClustersLoading={false}
        />

        <div
          className={getClassNames(
            lower11Competitors
              ? 'competitor-report__content__group-chart'
              : 'competitor-report__content__group-chart__full'
          )}
        >
          <div
            className={getClassNames(
              lower11Competitors
                ? 'competitor-report__content__group-chart__item'
                : 'competitor-report__content__group-chart__full__item'
            )}
          >
            <PersonaStackedBarReport
              competitorReportId={competitorReportId}
              competitorsFilter={competitorsFilter}
              filters={filters}
              handleOpenDataExplorer={handleOpenDataExplorer}
            />
          </div>
          <div
            className={getClassNames(
              lower11Competitors
                ? 'competitor-report__content__group-chart__item'
                : 'competitor-report__content__group-chart__full__item'
            )}
          >
            <MarketingStackedBarReport
              competitorReportId={competitorReportId}
              competitorsFilter={competitorsFilter}
              filters={filters}
              handleOpenDataExplorer={handleOpenDataExplorer}
            />
          </div>
        </div>

        <div
          className={getClassNames(
            lower11Competitors
              ? 'competitor-report__content__group-chart'
              : 'competitor-report__content__group-chart__full'
          )}
        >
          <div
            className={getClassNames(
              lower11Competitors
                ? 'competitor-report__content__group-chart__item'
                : 'competitor-report__content__group-chart__full__item'
            )}
          >
            <TailKeywordStackedBarReport
              competitorReportId={competitorReportId}
              competitorsFilter={competitorsFilter}
              filters={filters}
              handleOpenDataExplorer={handleOpenDataExplorer}
              isLoading={isCurrentYearMetricsLoading}
              metrics={currentYearMetrics}
            />
          </div>

          <div
            className={getClassNames(
              lower11Competitors
                ? 'competitor-report__content__group-chart__item'
                : 'competitor-report__content__group-chart__full__item'
            )}
          >
            <PositionStackedBarReport
              competitorReportId={competitorReportId}
              competitorsFilter={competitorsFilter}
              filters={filters}
              handleOpenDataExplorer={handleOpenDataExplorer}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
