import React from 'react';

import SvgWrapper from './SvgWrapper';

const SuccessIcon = (props) => (
  <SvgWrapper height="19" viewBox="0 0 19 19" width="19" {...props}>
    <circle cx="9.5" cy="9.5" fill="#54CA95" r="9.5" />
    <mask
      height="12"
      id="mask0"
      maskUnits="userSpaceOnUse"
      style={{ maskType: 'alpha' }}
      width="11"
      x="4"
      y="3"
    >
      <path
        d="M14.7909 4.35656L8.03621 14.6686L4.27539 11.3146L4.78468 10.7441L7.88251 13.5078L14.1516 3.9375L14.7909 4.35656Z"
        fill="black"
      />
    </mask>
    <g mask="url(#mask0)">
      <rect fill="white" height="15.2941" width="15.2941" x="1.4707" y="2.23535" />
    </g>
  </SvgWrapper>
);

export default SuccessIcon;
