export const selectReportRecommendationData = (id: string) => (state: any) => {
  return {
    actualLinksCount: state.report.recommendations[id]?.data?.actualLinksCount,
    contentTitle: state.content.title,
    disabled: state.report.recommendations[id]?.disabled,
    expectedLinksCount: state.report.recommendations[id]?.data?.expectedLinksCount,
    html: state.content.html,
    links: state.report.recommendations[id]?.underlyingData.links,
    maximumLinks: state.report.recommendations[id]?.underlyingData.maximumLinks,
    ready: state.report.recommendations[id].ready,
    recommendations: state.report.recommendations,
    recommendationsIds: state.report.recommendations[id].recommendationsIds,
    score: state.report.recommendations[id]?.score,
    weight: state.report.recommendations[id]?.weight,
  };
};
