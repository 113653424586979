import Input from '@material-ui/core/Input';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { fetchCompanyInformation } from '@/apis/semji/api';
import FlatLoader, { LoaderWrapper } from '@/components/Loader/FlatLoader';

function OrganizationInputAccordion({
  id,
  dataValue = '',
  onDataChange,
  onKeyDownPress,
  placeholder,
  isDirty,
  setIsDirty,
}) {
  const userId = useSelector((state) => state.user?.id);
  const [isLoading, setIsLoading] = useState(true);
  const [organizationNameError, setOrganizationNameError] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        if (userId && !isDirty && !dataValue.length) {
          setIsLoading(true);
          const { name = '' } = await fetchCompanyInformation(userId);

          onDataChange?.(id, name);
        }
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, userId]);

  const onChange = (event) => {
    onDataChange?.(id, event.target.value);
  };

  return isLoading ? (
    <LoaderWrapper height={32} isFlex>
      <FlatLoader />
    </LoaderWrapper>
  ) : (
    <Input
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus
      error={organizationNameError}
      fullWidth
      placeholder={placeholder}
      value={dataValue}
      onBlur={(e) => setOrganizationNameError(Boolean(e?.target?.value?.length < 3))}
      onChange={onChange}
      onFocus={() => setOrganizationNameError(false)}
      onKeyDown={onKeyDownPress}
      onKeyUp={() => setIsDirty(true)}
    />
  );
}

export default OrganizationInputAccordion;
