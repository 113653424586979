import styled from 'styled-components/macro';

import AtIcon from '@/components/icons/AtIcon';
import CancelIcon from '@/components/icons/CancelIcon';
import SubmitIcon from '@/components/icons/SubmitIcon';
import ValidatedGradientIcon from '@/components/icons/ValidatedGradientIcon';
import Flex from '@/design-system/components/Flex';
import defaultTheme from '@/themes/defaultTheme';

export const TextAreaWrapper = styled(Flex)`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.cssColors.dark010};
`;

export const FakeTextAreaText = styled(Flex)`
  font-size: 13px;
  line-height: ${({ theme }) => theme.textCss.lineHeight};
  color: ${({ theme }) => theme.cssColors.dark040};
`;

export const TextAreaFooter = styled(Flex)`
  border-top: 1px solid ${({ theme }) => theme.cssColors.dark010};
`;

export const StyledAtIcon = styled(AtIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.cssColors.dark060};
  && {
    &:hover {
      color: ${({ theme }) => theme.cssColors.dark080};
      background-color: ${({ theme }) => theme.cssColors.dark005};
      border-radius: 4px;
    }
    padding: 4px;
    font-size: 15px;
  }
`;

export const StyledSubmitIcon = styled(SubmitIcon)`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};

  && {
    display: ${({ displayOnHover }) => (displayOnHover ? 'none' : 'unset')};
    font-size: 20px;
    &:hover {
      stop:first-child {
        stop-color: ${({ theme }) => theme.cssColors.primaryHoverGradientStart};
      }
      stop:last-child {
        stop-color: ${({ theme }) => theme.cssColors.primaryHoverGradientEnd};
      }
    }
  }
`;

export const StyledValidatedIcon = styled(ValidatedGradientIcon)`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};

  && {
    font-size: 20px;
    &:hover {
      stop:first-child {
        stop-color: ${({ theme }) => theme.cssColors.primaryHoverGradientStart};
      }
      stop:last-child {
        stop-color: ${({ theme }) => theme.cssColors.primaryHoverGradientEnd};
      }
    }
  }
`;

export const StyledCancelIcon = styled(CancelIcon)`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  color: ${({ theme }) => theme.cssColors.dark040};
  && {
    font-size: 20px;
    &:hover {
      color: ${({ theme }) => theme.cssColors.dark050};
    }
  }
`;

export const FakeTextArea = styled(Flex)`
  border-radius: 4px;
  mix-blend-mode: multiply;

  &:hover {
    background-color: ${({ theme }) => theme.cssColors.dark005};

    ${StyledSubmitIcon} {
      display: unset;
    }
  }
`;

export const UserItem = styled(Flex)`
  background: inherit;
  padding: 10px;
  z-index: 10;
`;

export const UserInfo = styled(Flex)`
  min-width: 0;
`;

export const UserName = styled.span`
  font-size: ${(props) => props.theme.textCss.sizes.sm};
  line-height: ${({ theme }) => theme.textCss.lineHeight};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const UserEmail = styled.span`
  color: ${({ theme }) => theme.cssColors.dark040};
  font-size: ${(props) => props.theme.textCss.sizes.sm};
  line-height: ${({ theme }) => theme.textCss.lineHeight};
  letter-spacing: 0.004em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Separator = styled.div`
  border-left: 1px solid ${({ theme }) => theme.cssColors.dark010};
  height: 20px;
`;

export const stylesMentionsInput = {
  '&multiLine': {
    control: {
      fontFamily: defaultTheme.textCss.fonts.main,
      overflow: 'auto',
      paddingBottom: 8,
    },
    highlighter: {
      border: 'none',
      boxSizing: 'border-box',
      fontFamily: defaultTheme.textCss.fonts.main,
      fontSize: '13px',
      lineHeight: '135%',
      maxHeight: '60vh',
      overflow: 'hidden',
      padding: 8,
      paddingBottom: 20,
    },
    input: {
      border: 'none',
      fontFamily: defaultTheme.textCss.fonts.main,
      fontSize: '13px',
      lineHeight: '135%',
      maxHeight: '60vh',
      overflow: 'auto',
      padding: 8,
      paddingBottom: 8,
    },
  },

  '&singleLine': {
    display: 'inline-block',
    highlighter: {
      border: 'none',
      boxSizing: 'border-box',
      fontFamily: defaultTheme.textCss.fonts.main,
      fontSize: '13px',
      lineHeight: '135%',
      maxHeight: '60vh',
      overflow: 'hidden',
      padding: 8,
      paddingBottom: 15,
    },

    input: {
      border: 'none',
      boxSizing: 'border-box',
      fontFamily: defaultTheme.textCss.fonts.main,
      fontSize: '13px',
      lineHeight: '135%',
      maxHeight: '60vh',
      overflow: 'auto',
      padding: 8,
      paddingBottom: 15,
    },
    width: 300,
  },

  control: {
    backgroundColor: defaultTheme.colors.white,
    fontSize: 13,
    fontWeight: 'normal',
  },

  suggestions: {
    item: {
      '&focused': {
        backgroundColor: '#fff8f9',
      },
    },
    list: {
      backgroundColor: defaultTheme.colors.white,
      boxShadow: defaultTheme.boxShadow.modal,
      fontFamily: defaultTheme.textCss.fonts.main,
      maxHeight: 210,
      overflowY: 'scroll',
      width: 310,
    },
  },
};

export const stylesMentions = {
  color: '#2758BC',
  pointerEvents: 'none',
  position: 'relative',
  textShadow: '1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white, -1px -1px 1px white',
  zIndex: 10,
};
