import {
  deleteUser,
  fetchWorkspaceUsers,
  postRegistrant,
  putUserData,
  resetPassword,
  setUserEmail,
  setUserPassword,
} from '@/apis/semji/api';
import apiClient from '@/apis/semji/apiClient';
import { NULL_USER } from '@/hooks/useNullUser';

export default class User {
  constructor(workspaceId) {
    this._workspaceId = workspaceId;
    this._users = null;
  }

  get users() {
    return this._fetchUsers();
  }

  delete(userId) {
    return deleteUser(userId);
  }

  setNewEmail(userId, newEmail) {
    return setUserEmail(userId, {
      email: newEmail,
    });
  }

  setNewPassword(userId, currentPassword, newPassword) {
    return setUserPassword(userId, {
      currentPassword,
      newPassword,
    });
  }

  setUserInfo(userId, data) {
    return putUserData(userId, data);
  }

  resetPasswordForEmail(email) {
    return resetPassword(email);
  }

  createUser(payload) {
    return postRegistrant(payload);
  }

  async _fetchUsers() {
    if (this._users === null) {
      try {
        this._users = await fetchWorkspaceUsers(this._workspaceId);
        this._users = [
          NULL_USER,
          ...this._users['hydra:member'].sort((a, b) =>
            ('' + a.firstName + a.lastName).localeCompare(b.firstName + b.lastName)
          ),
        ];
      } catch (e) {
        throw e;
      }
    }

    return this._users;
  }
}

const findMe = async () => {
  const response = await apiClient.get(`/me`, {}, {});

  return response;
};

export { findMe };
