import Tooltip from '@material-ui/core/Tooltip';
import MoreVert from '@material-ui/icons/MoreVert';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  getRoundedEvolutionMetricObject,
  getRoundedNumberMetricObject,
  getRoundedPercentMetricObject,
} from 'semji-core/utils/numbers';
import { SortOrders } from 'semji-core/utils/sort';
import styled, { css } from 'styled-components/macro';

import Avatar from '@/components/icons/Avatar';
import FlatLoader, { LoaderWrapper } from '@/components/Loader/FlatLoader';
import { Diff, DiffPercentage } from '@/components/Metrics/DiffPercentage';
import MetricsSelectorBox from '@/components/Metrics/MetricsSelectorBox';
import { Number } from '@/components/Metrics/Number';
import { ReportMetricCategory, ReportMetricKey } from '@/containers/Report/utils/types';
import { useMetricsConfig } from '@/hooks/useMetricsConfig';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import {
  METRIC_POSITION_KEY,
  METRIC_TYPE_PERCENT,
  RANKING_KEYWORDS_METRICS,
} from '@/utils/metrics/constants';

const MAX_POSITION_VALUE = 100;

const Cartridge = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  position: relative;
  min-height: ${(props) => (props.size === 'small' ? '50px' : '120px')};
  justify-content: center;
  ${(props) => (props.direction === 'column' ? 'height' : 'width')}: 33%;
  flex-grow: 1;
  overflow: hidden;

  border: 1px solid #e9e9eb50;
  border-bottom-width: 0;

  &::before {
    left: 0;
    top: 0;
    width: ${(props) => (props.direction === 'column' ? '3px' : '0')};
    height: ${(props) => (props.direction === 'column' ? '0' : '2px')};
    transition: all 300ms ease-out;
    opacity: 0;
    display: flex;
    align-items: center;
    content: '';
    background: ${(props) => props.metricColor};
    position: absolute;
  }

  color: ${(props) => props.theme.text.colors.default};
  background: #e9e9eb50;
  transition:
    color 300ms ease-out,
    background 300ms ease-out;

  &:hover {
    color: ${(props) => props.theme.text.colors.darker};
    background: ${(props) => props.theme.colors.brightestGrey};
  }

  ${(props) => props.active && cartridgeStyleWhenActive};
`;

const cartridgeStyleWhenActive = css`
  &,
  &:hover {
    color: ${(props) => props.theme.text.colors.black};
    background: white;
  }

  &::before {
    opacity: 1;
  }

  border-right-color: transparent;
  border-left-color: transparent;

  &:first-child {
    border-top-color: transparent;
  }

  &::before {
    height: ${(props) => (props.direction === 'column' ? 'calc(100% + 3px)' : '2px')};
    width: ${(props) => (props.direction === 'column' ? '3px' : 'calc(100% + 3px)')};
  }
`;

const CartridgeContent = styled.div`
  box-sizing: border-box;
  display: flex;
  padding: ${(props) => (props.size === 'small' ? '0.8em' : '1em 3em 1em 1.5em')};
  width: 100%;
  flex-grow: 1;
  align-items: ${(props) => (props.size === 'small' ? 'flex-start' : 'center')};
`;

const CartridgeText = styled.div`
  font-size: 14px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.size === 'small' ? 'flex-start' : 'stretch')};
  flex-grow: 1;
  width: auto;
  max-width: 80%;
  margin-left: ${(props) => (props.size === 'small' ? '0.2em' : '1.1em')};
`;

const CartridgeNumbersRow = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: ${(props) => (props.direction === 'column' ? 'row' : 'column')};
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;
  width: 100%;
`;

const CartridgeTitle = styled.h3`
  font-size: ${(props) => props.theme.text.sizes.small};
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  line-height: 1.2em;
`;

const MoreButton = styled.div`
  align-self: flex-end;
  right: 0.6em;
  font-size: 0.7em;
  font-weight: 400;
  position: absolute;
`;

const CartridgeAvatar = styled(({ maxWidth, ...otherProps }) => <Avatar {...otherProps} />)`
  margin-top: ${(props) => ('mini' === props.size ? '0.5em' : 'initial')};
  @media (max-width: ${(props) => props.maxWidth || '0px'}) {
    && {
      display: none;
    }
  }
`;

const StyledLoaderWrapper = styled(LoaderWrapper)`
  && {
    width: 80%;
  }
`;

const CartridgeData = ({
  metricKey,
  isPublished,
  value,
  previousValue,
  evolutionRating,
  size,
  suffix,
  selectedPositionKey,
  percentDiffRounded,
  roundedCurrentValueObject,
  active,
}) => {
  const { isFeatureEnabled: rankTrackingFeatSetFlag } = useOrganizationFeatureSet(
    'rank-tracking:is-enabled'
  );
  if (metricKey === METRIC_POSITION_KEY && (!isPublished || !rankTrackingFeatSetFlag)) {
    return <span>-</span>;
  }

  return (
    <>
      <Number size={size === 'small' ? 'default' : size}>
        {`${roundedCurrentValueObject.value}${roundedCurrentValueObject.suffix}${suffix}`}
      </Number>
      {metricKey === METRIC_POSITION_KEY && isPublished && active && (
        <Diff
          initialValue={Math.round(previousValue)}
          isInitialValueUnranked={!!previousValue && previousValue === MAX_POSITION_VALUE}
          isValueUnranked={!!value && value === MAX_POSITION_VALUE}
          sortOrder={
            selectedPositionKey === RANKING_KEYWORDS_METRICS ? SortOrders.ASC : SortOrders.DESC
          }
          value={Math.round(value)}
        />
      )}
      {previousValue !== null && metricKey !== METRIC_POSITION_KEY ? (
        <DiffPercentage
          evolutionRating={evolutionRating}
          initialValue={percentDiffRounded.initialValue}
          suffix={`${percentDiffRounded.suffix}%`}
          type="editor"
          value={percentDiffRounded.value}
        />
      ) : null}
    </>
  );
};

export function MetricCartridge({
  name,
  value,
  icon,
  isPublished,
  color,
  size,
  active = false,
  metricKey = null,
  type = null,
  previousValue = 0,
  handleMenuItemClick = () => {},
  options = [],
  direction = 'column',
  evolutionRating = 'positive',
  suffix = '',
  isRetrieving,
  selectedPositionKey,
  ...props
}) {
  const { t } = useTranslation();
  const userLanguageCode = useSelector((state) => state.user?.languageCode);

  const [anchorEl, setAnchorEl] = useState(null);
  const metrics = useMetricsConfig({ isNew: true });
  const metricsList = Object.values(metrics).filter(
    (metric) => ![ReportMetricKey.overview, ReportMetricKey.publicationsCount].includes(metric.key)
  );

  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const selectMetric = (metric) => handleMenuItemClick(metric);

  const percentDiff =
    previousValue === 0 && value !== 0
      ? Infinity
      : previousValue === 0
        ? 0
        : ((value - previousValue) * 100) / previousValue;
  const percentDiffRounded = getRoundedEvolutionMetricObject({
    locale: userLanguageCode,
    number: percentDiff,
  });

  let roundedCurrentValueObject;
  if (type === METRIC_TYPE_PERCENT) {
    roundedCurrentValueObject = getRoundedPercentMetricObject({
      locale: userLanguageCode,
      number: value * 100,
    });
  } else {
    roundedCurrentValueObject = getRoundedNumberMetricObject({
      locale: userLanguageCode,
      number: value,
    });
  }

  return (
    <Tooltip placement="top" title={t(name)}>
      {/* ERROR Forward Ref not working on functionnal components */}
      {/*  MATERIAL UI v4 https://material-ui.com/fr/guides/migration-v3/#tooltip */}
      {/* The child needs to be able to hold a ref. The composition guide explains the migration strategy. */}
      <Cartridge
        active={active}
        data-active={active}
        direction={direction}
        metricColor={color}
        size={size}
        {...props}
      >
        <CartridgeContent size={size}>
          <CartridgeAvatar color={color} size="mini" title={name}>
            {icon}
          </CartridgeAvatar>
          <CartridgeText size={size}>
            {size !== 'small' && <CartridgeTitle title={name}>{name}</CartridgeTitle>}
            {isRetrieving ? (
              <StyledLoaderWrapper height={30}>
                <FlatLoader />
              </StyledLoaderWrapper>
            ) : (
              <CartridgeNumbersRow direction={direction} size={size}>
                <CartridgeData
                  active={active}
                  evolutionRating={evolutionRating}
                  isPublished={isPublished}
                  metricKey={metricKey}
                  percentDiffRounded={percentDiffRounded}
                  previousValue={previousValue}
                  roundedCurrentValueObject={roundedCurrentValueObject}
                  selectedPositionKey={selectedPositionKey}
                  size={size}
                  suffix={suffix}
                  value={value}
                />
              </CartridgeNumbersRow>
            )}
          </CartridgeText>
        </CartridgeContent>
        <>
          <MoreButton
            onClick={(e) => {
              e.stopPropagation();
              setAnchorEl(e.currentTarget);
            }}
          >
            <MoreVert fontSize="small" htmlColor="#A8A9AF" />
          </MoreButton>
          <MetricsSelectorBox
            anchorEl={anchorEl}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            direction="column"
            elements={[
              {
                metricsList: metricsList.filter(
                  (metric) => metric.category === ReportMetricCategory.Production
                ),
                selectMetric,
                title: t('report:metric-categories.production'),
              },
              {
                metricsList: metricsList.filter(
                  (metric) => metric.category === ReportMetricCategory.Acquisition
                ),
                selectMetric,
                title: t('report:metric-categories.acquisition'),
              },
              {
                metricsList: metricsList.filter(
                  (metric) => metric.category === ReportMetricCategory.UserBehavior
                ),
                selectMetric,
                title: t('report:metric-categories.user-behaviour'),
              },
              {
                metricsList: metricsList.filter(
                  (metric) => metric.category === ReportMetricCategory.Conversion
                ),
                selectMetric,
                title: t('report:metric-categories.conversion'),
              },
              {
                metricsList: metricsList.filter(
                  (metric) => metric.category === ReportMetricCategory.ECommerce
                ),
                selectMetric,
                title: t('report:metric-categories.e-commerce'),
              },
            ]}
            open={Boolean(anchorEl)}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
            onClose={handleClose}
          />
        </>
      </Cartridge>
    </Tooltip>
  );
}
