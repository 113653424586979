import Highcharts from 'highcharts';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import HighchartsReact from 'highcharts-react-official';
import memoize from 'lodash/memoize';
import { DateTime } from 'luxon';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  getRoundedNumberMetricObject,
  getRoundedPercentMetricObject,
} from 'semji-core/utils/numbers';
import styled from 'styled-components/macro';

import { ReportMetricKey, ReportMetricType } from '@/containers/Report/utils/types';
import defaultTheme from '@/themes/defaultTheme';
import { getChartZonesByMetric } from '@/utils/charts/getChartZonesByMetric';
import { timeLabelFormats } from '@/utils/charts/timeLabelFormats';
import { lighten } from '@/utils/color';
import {
  FOCUS_TOP_KEYWORD_POSITION,
  METRIC_CHART_BAR,
  METRIC_CHART_LINE,
  METRIC_POSITION_KEY,
  METRIC_STACKED_CHART_BAR,
  METRIC_STACKING_TYPE_PERCENT,
  METRICS_CONFIG_PERIODICITY_DAILY,
  periodicities,
  PLOT_LINE_LOCK_TYPE,
  PLOT_LINE_PUBLICATION_TYPE,
  RANKING_KEYWORDS_METRICS,
} from '@/utils/metrics/constants';
import { getCurrentDateRange } from '@/utils/metrics/getCurrentDateRange';

import LegendItem from './LegendItem';
import PlotLineLock from './PlotLineLock';
import PlotLineTag from './PlotLineTag';
import PositionTooltip from './PositionTooltip';
import StackedColTooltip from './StackedColTooltip';
import Tooltip, { StackedTooltip } from './Tooltip';

NoDataToDisplay(Highcharts);

export default styled(HighChartsChart)`
  // This is to avoid the tooltip to be hidden by the overflow hidden of the parent
  // Highchart v11.x does not autorize to set the overflow to visible on the tooltip (https://api.highcharts.com/class-reference/Highcharts.CSSObject)
  // for plotlines (https://api.highcharts.com/highcharts/xAxis.plotLines.label.style)
  .highcharts-plot-line-label {
    overflow: visible !important;
    font-size: 1rem !important;
  }

  width: ${(props) => props.width + 'px'};
  height: ${(props) => props.height + 'px'};
`;

function HighChartsChart({
  commonAxis,
  comparisonPeriod,
  currentMetricKey,
  disableLegend,
  height,
  markers,
  metricsSeries,
  period,
  periodicity,
  stacked,
  legendLeft,
  width,
  className,
  multipleMetricsKey,
  yAxisReversed,
  noMargin,
}) {
  const userLanguageCode = useSelector((state) => state.user?.languageCode);
  const { t } = useTranslation();

  const highChartsTypes = {
    [METRIC_CHART_BAR]: 'column',
    [METRIC_CHART_LINE]: 'line',
    [METRIC_STACKED_CHART_BAR]: 'column',
  };

  function getLegendItem(serieName, stacked, isVisible) {
    const { series } = forgeSeriesFromMetricSeries(metricsSeries);

    return (
      <LegendItem
        comparisonPeriod={comparisonPeriod}
        isVisible={isVisible}
        locale={userLanguageCode}
        period={period}
        serie={series.find((serie) => serie.name === serieName)}
        stacked={stacked}
      />
    );
  }

  function getSerieOptions({ metricsSerie, after, before, metricPeriod, withZones = true }) {
    const aggregatedMetrics =
      metricPeriod === 'current'
        ? metricsSerie.aggregateMetrics
        : metricsSerie.aggregatePreviousMetrics;

    return {
      metric: metricsSerie.metric,
      period: metricPeriod,
      // this is to enable 3 years of data (1096 points) to be displayed https://assets.highcharts.com/errors/12/
      // default value is 1000 points https://api.highcharts.com/highcharts/series.line.turboThreshold
      turboThreshold: 1200,
      zIndex: 1,
      // dotted series for data that can potentially change (incomplete data)
      zoneAxis: 'x',
      zones:
        withZones && aggregatedMetrics.length && after && before
          ? [
              {
                dashStyle: 'dot',
                value: before,
              },
              {
                value: after,
              },
              {
                dashStyle: 'dot',
              },
            ]
          : undefined,
    };
  }

  function getTooltipItem(graphPoints, stacked, metric, metricSerieKey) {
    const points = [];
    const isStackedChartBar = metric.chartType === METRIC_STACKED_CHART_BAR;
    const isPositionMetric = currentMetricKey === METRIC_POSITION_KEY;
    const metricType =
      currentMetricKey === ReportMetricKey.publicationsCount
        ? ReportMetricType.Percent
        : metricsSeries[0].metric.type;

    const { series } = forgeSeriesFromMetricSeries(metricsSeries);
    graphPoints.forEach((graphPoint) => {
      const period = DateTime.fromMillis(graphPoint?.point?.realX ?? graphPoint.x).toFormat(
        periodicities[periodicity].humanFormat,
        { locale: userLanguageCode }
      );

      const serie = series.find((serie) => serie.name === graphPoint.series.name);
      const roundedValueObject = getRoundedNumberMetricObject({
        locale: userLanguageCode,
        number: graphPoint.y,
      });

      points.push({
        color: graphPoint.series.color,
        evolutionRating: serie.metric?.evolutionRating,
        label: serie.label,
        metricTitle: t(serie.metric?.tooptipTitleKey, {
          count: parseInt(roundedValueObject.value, 10),
          postProcess: 'interval',
        }),
        millisDate: graphPoint?.point?.realX ?? graphPoint.x,
        period,
        realValue: graphPoint.y,
        suffix:
          stacked === METRIC_STACKING_TYPE_PERCENT
            ? `${roundedValueObject.suffix + serie.metric?.suffix} (${Math.round(
                graphPoint.percentage
              )}%)`
            : roundedValueObject.suffix + serie.metric?.suffix,
        value: roundedValueObject.value,
      });
    });

    // Add the other point if we have only one point (average content score case)
    if (metricSerieKey === ReportMetricKey.AverageContentScore && points.length === 1) {
      const otherSerie = series.find((serie) => serie.name !== graphPoints[0].series.name);
      const otherPoint = otherSerie.data.find((point) => point.x === graphPoints[0].x);
      const millisDate = otherPoint?.realX ?? otherPoint?.x;
      if (millisDate) {
        const period = DateTime.fromMillis(millisDate).toFormat(
          periodicities[periodicity].humanFormat,
          { locale: userLanguageCode }
        );

        points.push({
          color: otherSerie.color,
          evolutionRating: points[0].evolutionRating,
          label: otherSerie.label,
          metricTitle: points[0].metricTitle,
          millisDate: otherPoint?.realX,
          period,
          realValue: null,
          suffix: null,
          value: null,
        });
      }
    }
    const sortedPoints = points.sort((a, b) => b.millisDate - a.millisDate);

    if (isPositionMetric && metricSerieKey !== FOCUS_TOP_KEYWORD_POSITION) {
      return <PositionTooltip locale={userLanguageCode} points={sortedPoints} />;
    }

    if (isStackedChartBar) {
      return <StackedColTooltip metric={metricsSeries[0].metric} points={sortedPoints} />;
    }

    if (stacked) {
      const previousMetrics = metricsSeries
        .map((metricSerie) =>
          metricSerie.aggregatePreviousMetrics.find(
            (point) => point.x === sortedPoints[0].millisDate
          )
        )
        .filter(Boolean)
        .map((point) => ({
          ...point,
          y: point.y || point[currentMetricKey] || 0,
        }));
      return (
        <StackedTooltip
          comparisonPeriod={comparisonPeriod}
          metricKey={currentMetricKey}
          points={sortedPoints}
          previousPoints={previousMetrics}
          type={metricType}
        />
      );
    }
    return (
      <Tooltip
        comparisonPeriod={comparisonPeriod}
        metricKey={currentMetricKey}
        points={sortedPoints}
        type={metricType}
      />
    );
  }

  const forgeSeriesFromMetricSeries = memoize((metricsSeries, commonAxis) => {
    let series = [];
    let yAxis = [];

    metricsSeries.forEach((metricsSerie) => {
      if (commonAxis) {
        yAxis = [
          {
            allowDecimals: metricsSerie.metric.allowDecimals,
            gridLineDashStyle: 'dash',
            gridLineWidth: 1,
            labels: {
              formatter: function () {
                const roundedValueObject = getRoundedNumberMetricObject({
                  locale: userLanguageCode,
                  number: this.value,
                });
                return (
                  roundedValueObject.value +
                  roundedValueObject.suffix +
                  metricsSerie.metric.suffix +
                  `${stacked === METRIC_STACKING_TYPE_PERCENT ? '%' : ''}`
                );
              },
              style: {
                color: defaultTheme.cssColors.dark040,
                fontSize: defaultTheme.textCss.sizes.xsm,
                fontWeight: defaultTheme.textCss.weights.normal,
              },
            },
            min: 0,
            minorGridLineWidth: 0,
            softMax: 1, // to avoid vertical alignment of the line if the value is 0
            title: {
              text: null,
            },
          },
        ];
      } else {
        // y axis
        yAxis.push({
          allowDecimals: yAxisReversed ? false : metricsSerie.metric.allowDecimals,
          gridLineDashStyle: 'dash',
          gridLineWidth: 1,
          labels: {
            formatter: function () {
              const roundedValueObject =
                metricsSerie.metric.type === METRIC_STACKING_TYPE_PERCENT ||
                metricsSerie.metric.allowDecimals
                  ? getRoundedPercentMetricObject({
                      locale: userLanguageCode,
                      number: this.value,
                    })
                  : getRoundedNumberMetricObject({
                      locale: userLanguageCode,
                      number: this.value,
                    });
              return (
                roundedValueObject.value +
                roundedValueObject.suffix +
                metricsSerie.metric.suffix +
                `${stacked === METRIC_STACKING_TYPE_PERCENT ? '%' : ''}`
              );
            },
            style: {
              color: defaultTheme.cssColors.dark040,
              fontSize: defaultTheme.textCss.sizes.xsm,
              fontWeight: defaultTheme.textCss.weights.normal,
            },
          },

          min: yAxisReversed ? 1 : 0,
          // to avoid vertical alignment of the line if the value is 0
          minorGridLineWidth: 0,
          opposite: !!(yAxis.length % 2),
          reversed: yAxisReversed,
          reversedStacks: false,
          softMax: 1,
          title: {
            text: null,
          },
        });
      }
      const metricColor = metricsSerie.metric.isSubCategory
        ? defaultTheme.metricsColor[metricsSerie.metric.parentCategoryKey]
        : defaultTheme.metricsColor[metricsSerie.key];

      const { after, before } = getChartZonesByMetric({
        period: period,
        periodicity: periodicity,
      });

      //  current data serie
      if (!multipleMetricsKey) {
        series.push({
          ...getSerieOptions({ after, before, metricPeriod: 'current', metricsSerie }),
          animation: false,
          color: metricColor,
          connectNulls: currentMetricKey === ReportMetricKey.AverageContentScore,
          data: metricsSerie.aggregateMetrics,
          label: metricsSerie.metric.name,
          marker: {
            enabled: metricsSerie.aggregateMetrics.length === 1,
            states: {
              hover: {
                fillColor: metricColor,
              },
            },
          },
          maxPointWidth: metricsSerie.metric.chartType === METRIC_CHART_BAR ? 25 : undefined,
          name: metricsSerie.key,
          type: highChartsTypes[metricsSerie.metric.chartType],
        });

        // previous
        series.push({
          ...getSerieOptions({ after, before, metricPeriod: 'previous', metricsSerie }),
          animation: false,
          color: lighten(metricColor, 0.3),
          connectNulls: currentMetricKey === ReportMetricKey.AverageContentScore,
          data: metricsSerie.aggregatePreviousMetrics,
          label: metricsSerie.metric.name,
          marker: {
            enabled: metricsSerie.aggregatePreviousMetrics.length === 1,
            states: {
              hover: {
                fillColor: lighten(metricColor, 0.3),
              },
            },
          },
          maxPointWidth: metricsSerie.metric.chartType === METRIC_CHART_BAR ? 25 : undefined,
          metric: metricsSerie.metric,
          name: metricsSerie.key + 'previous',
          stacking: stacked,
          type: highChartsTypes[metricsSerie.metric.chartType],
          visible: !!metricsSerie.metric.previousVisible,
          yAxis: yAxis.length - 1,
        });
      }

      if (multipleMetricsKey) {
        metricsSerie.metric[multipleMetricsKey].forEach((positionMetric, index) => {
          series.push({
            ...getSerieOptions({
              after,
              before,
              metricPeriod: 'current',
              metricsSerie,
              withZones: false,
            }),
            animation: false,
            color: defaultTheme.metricsColor[positionMetric.key],
            connectNulls: currentMetricKey === ReportMetricKey.AverageContentScore,
            data: metricsSerie.aggregatePositionMetrics[index],
            // Used for line charts only
            fillOpacity: 0.6,

            label: t(positionMetric.legendLabel),

            // Used for line charts only
            lineWidth: 2.2,

            marker: {
              enabled: false,
              states: {
                hover: {
                  fillColor: defaultTheme.metricsColor[positionMetric.key],
                  lineWidthPlus: 0,
                  radius: 6,
                  radiusPlus: 0,
                },
              },
            },

            maxPointWidth: periodicity === METRICS_CONFIG_PERIODICITY_DAILY ? 3 : 25,

            name: positionMetric.key,

            stacking: stacked,
            type:
              multipleMetricsKey === RANKING_KEYWORDS_METRICS
                ? 'area'
                : highChartsTypes[METRIC_CHART_BAR], // Used for line charts only
          });
        });
      }
    });

    return { series, yAxis };
  });

  function setChartOptions({
    stacked,
    markers,
    disableLegend,
    width,
    height,
    period,
    periodicity,
    metricsSeries,
    commonAxis,
    legendLeft,
    noMargin,
  }) {
    const { yAxis, series } = forgeSeriesFromMetricSeries(metricsSeries, commonAxis);
    const { from: accurateDate } = getCurrentDateRange({ period });
    const timeLabel = timeLabelFormats({ periodicity });
    const from = accurateDate.startOf(periodicities[periodicity].luxonReference);

    return {
      chart: {
        // Disable animation on series update
        animation: false,
        height,
        scrollablePlotArea: {
          scrollPositionX: 1,
        },
        spacing: [5, 5, 15, 5],
        style: {
          fontFamily: defaultTheme.text.font,
        },
        width,
        zoomType: 'x',
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      lang: {
        noData: t('competitors:duel.report.no-data'),
      },
      legend: {
        align: 'left',
        enabled: !disableLegend,
        itemHiddenStyle: {
          textDecoration: 'none',
        },
        itemMarginBottom: legendLeft ? 12 : 0,
        labelFormatter: function () {
          return ReactDOMServer.renderToStaticMarkup(
            getLegendItem(this.name, stacked, this.visible)
          );
        },
        symbolHeight: 0.1,
        symbolPadding: 0,
        symbolRadius: 0,
        symbolWidth: 0,
        useHTML: true,
        x: noMargin
          ? -10
          : metricsSeries[0].metric.chartType === METRIC_STACKED_CHART_BAR || legendLeft
            ? 0
            : 50,
        y: legendLeft ? 30 : 0,
      },
      noData: {
        style: {
          color: defaultTheme.cssColors.dark060,
          fontSize: defaultTheme.textCss.sizes.default,
          fontWeight: defaultTheme.textCss.weights.normal,
        },
      },
      plotOptions: {
        column: {
          borderWidth: 0,
          stacking: stacked,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
        line: {
          lineWidth: 2,
          marker: {
            enabled: false,
          },
          states: {
            hover: {
              lineWidth: 3,
            },
          },
        },
        series: {
          groupPadding: series[0].metric.key === METRIC_POSITION_KEY ? 0.02 : 0.2,
          point: {
            events: {
              mouseOut: function () {
                this.series.chart.series.forEach((series) => {
                  series?.points?.forEach((point) => {
                    point?.graphic?.attr({
                      opacity: 1,
                    });
                  });
                });
              },
              mouseOver: function () {
                this.series.chart.series.forEach((series) => {
                  series?.points?.forEach((point) => {
                    if (point.x !== this.x) {
                      point?.graphic?.attr({
                        opacity: 0.3,
                      });
                    }
                  });
                });
              },
            },
          },
          pointPadding: 0,
        },
      },
      series,
      title: {
        text: null,
      },
      tooltip: {
        backgroundColor: 'white',
        borderColor: 'transparent',
        borderWidth: 0,
        formatter: function () {
          return ReactDOMServer.renderToStaticMarkup(
            getTooltipItem(this.points, stacked, metricsSeries[0].metric, metricsSeries[0].key)
          );
        },
        padding: 0,
        shadow: false,
        shared: true,
        style: {
          color: defaultTheme.cssColors.dark060,
        },
        useHTML: true,
      },
      xAxis: {
        // endOnTick: periodicity !== METRICS_CONFIG_PERIODICITY_DAILY,
        dateTimeLabelFormats: {
          day: timeLabel,
          hour: timeLabel,
          millisecond: timeLabel,
          minute: timeLabel,
          month: timeLabel,
          second: timeLabel,
          week: timeLabel,
          year: timeLabel,
        },

        labels: {
          overflow: 'justify',
          style: {
            color: defaultTheme.cssColors.dark040,
            fontSize: defaultTheme.textCss.sizes.xsm,
            fontWeight: defaultTheme.textCss.weights.normal,
          },
          y: 30,
        },

        lineWidth: 0,

        // The min is to set the bounds of the xAxis charts
        // this is needed to display always all the selected interval even if we do not have data
        min: from.toMillis(),
        plotLines: markers.map((marker) => {
          // transform plotline date to the current periodicity
          const date = DateTime.fromMillis(marker.x).toFormat(periodicities[periodicity].format, {
            locale: userLanguageCode,
          });

          switch (marker.type) {
            case PLOT_LINE_LOCK_TYPE:
              return {
                color: defaultTheme.cssColors.salmonOrange,
                dashStyle: 'dash',
                label: {
                  align: 'center',
                  rotation: 0,
                  text: ReactDOMServer.renderToStaticMarkup(
                    <PlotLineLock marker={marker} theme={defaultTheme} />
                  ),
                  useHTML: true,
                  verticalAlign: 'bottom',
                },
                value: DateTime.fromFormat(date, periodicities[periodicity].format).toMillis(),
                width: 1,
                zIndex: 3,
              };
            case PLOT_LINE_PUBLICATION_TYPE:
            default:
              return {
                color: defaultTheme.colors.secondary,
                dashStyle: 'dash',
                label: {
                  align: 'center',
                  rotation: 0,
                  text: ReactDOMServer.renderToStaticMarkup(
                    <PlotLineTag marker={marker} theme={defaultTheme} />
                  ),
                  useHTML: true,
                  verticalAlign: 'bottom',
                },
                value: DateTime.fromFormat(date, periodicities[periodicity].format).toMillis(),
                width: 1,
                zIndex: 3,
              };
          }
        }),
        tickWidth: 0,
        type: 'datetime',
      },
      yAxis,
    };
  }

  const options = setChartOptions({
    commonAxis,
    defaultTheme,
    disableLegend,
    height,
    legendLeft,
    markers,
    metricsSeries,
    noMargin,
    period,
    periodicity,
    stacked,
    width,
  });

  // Doesnt work when its set directly in setChartOptions for unkown reason
  // https://github.com/highcharts/highcharts-react/issues/316
  Highcharts.setOptions({
    lang: {
      months: [
        t('chart:lang.months.january'),
        t('chart:lang.months.february'),
        t('chart:lang.months.march'),
        t('chart:lang.months.april'),
        t('chart:lang.months.may'),
        t('chart:lang.months.june'),
        t('chart:lang.months.july'),
        t('chart:lang.months.august'),
        t('chart:lang.months.september'),
        t('chart:lang.months.october'),
        t('chart:lang.months.november'),
        t('chart:lang.months.december'),
      ],
      shortMonths: [
        t('chart:lang.shortMonths.jan'),
        t('chart:lang.shortMonths.feb'),
        t('chart:lang.shortMonths.march'),
        t('chart:lang.shortMonths.april'),
        t('chart:lang.shortMonths.may'),
        t('chart:lang.shortMonths.jun'),
        t('chart:lang.shortMonths.jul'),
        t('chart:lang.shortMonths.aug'),
        t('chart:lang.shortMonths.sep'),
        t('chart:lang.shortMonths.oct'),
        t('chart:lang.shortMonths.nov'),
        t('chart:lang.shortMonths.dec'),
      ],
    },
  });

  return (
    <div className={className}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}
