import React from 'react';

import SvgWrapper from './SvgWrapper';

const ErrorIcon = (props) => (
  <SvgWrapper height="19" viewBox="0 0 19 19" width="19" {...props}>
    <circle cx="9.5" cy="9.5" fill="#FF5451" r="9.5" />
    <mask
      height="11"
      id="mask0"
      maskUnits="userSpaceOnUse"
      style={{ maskType: 'alpha' }}
      width="11"
      x="4"
      y="4"
    >
      <path
        d="M9.95813 9.50001L14.2585 13.8004L13.801 14.2578L9.50066 9.95748L5.20031 14.2578L4.74283 13.8004L9.04319 9.50001L4.74219 5.19966L5.19966 4.74219L9.50066 9.04254L13.801 4.74219L14.2585 5.19966L9.95813 9.50001Z"
        fill="black"
      />
    </mask>
    <g mask="url(#mask0)">
      <rect fill="white" height="12.9412" width="12.9412" x="2.70508" y="3.35291" />
    </g>
  </SvgWrapper>
);

export default ErrorIcon;
