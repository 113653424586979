import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import apiClient from '@/apis/semji/apiClient';
import { LOCATION, SCOPE_COMPETITORS } from '@/apis/semji/constants';
import { QueryOptions } from '@/apis/types/query.types';
import { showErrorSnackbar } from '@/store/actions/ui';
import { CompetitorConfiguration } from '@/types/competitors';

interface UseGetCompetitorConfigurationsProps extends QueryOptions<{}, CompetitorConfiguration[]> {}

export default function useGetCompetitorConfigurations({
  ...props
}: UseGetCompetitorConfigurationsProps) {
  const { get } = apiClient;
  const { workspaceId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return useQuery({
    onError: () => {
      dispatch(showErrorSnackbar(t('common:error.default')));
    },
    queryFn: async ({ signal }) => {
      const result = await get(`/workspaces/${workspaceId}/competitor_configurations`, {
        signal,
      });

      return result.data['hydra:member'] ?? [];
    },
    queryKey: [SCOPE_COMPETITORS.GET_COMPETITOR_CONFIGURATIONS, workspaceId, LOCATION.COMPETITORS],
    ...props,
  });
}
