import { useEffect, useState } from 'react';

import { LOGIN_BUTTON_CLIENT_ID } from '@/apis/googleApi/constants';
import { parseJwtToken } from '@/utils/jwt/parseJwtToken';
import { loadScript, removeScript } from '@/utils/script';

const idScript = 'google-login';
const googleOauthScript = 'https://accounts.google.com/gsi/client';

export const useGoogleAuth = ({ googleButtonRef, onFailure, onSuccess, options }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const handleGoogleResponse = (response) => {
    onSuccess({
      credential: response.credential,
      ...parseJwtToken(response.credential),
    });
  };

  useEffect(() => {
    loadScript(googleOauthScript, idScript)
      .then(() => {
        setIsLoaded(true);
        /*global google*/
        google.accounts.id.initialize({
          callback: handleGoogleResponse,
          client_id: LOGIN_BUTTON_CLIENT_ID,
        });

        google.accounts.id.renderButton(googleButtonRef.current, {
          size: 'large',
          theme: 'outline',
          ...options,
        });
      })
      .catch(onFailure);

    return () => {
      if (isLoaded) {
        removeScript(document, idScript);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isLoaded, setIsLoaded };
};
