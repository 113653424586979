import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import apiClient from '@/apis/semji/apiClient';
import { MutationOptions } from '@/apis/types/mutation.types';
import { showErrorSnackbar } from '@/store/actions/ui';
import { CompetitorReport } from '@/types/competitors';

interface UsePostCompetitorReportProps extends MutationOptions<{}, CompetitorReport> {}

export default function usePostCompetitorReport({ ...props }: UsePostCompetitorReportProps) {
  const { t } = useTranslation();
  const { post } = apiClient;
  const dispatch = useDispatch();
  const { workspaceId } = useParams();

  return useMutation({
    mutationFn: async (payload) => {
      const result = await post(`/workspaces/${workspaceId}/competitor_reports`, payload);
      return result.data;
    },
    onError: () => {
      dispatch(showErrorSnackbar(t('common:error.default')));
    },
    ...props,
  });
}
