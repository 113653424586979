import Avatar from '@material-ui/core/Avatar';
import styled from 'styled-components/macro';

export default styled(Avatar)`
  && {
    background: ${(props) => props.color};
    width: ${(props) => {
      switch (props.size) {
        case 'mini':
          return props.theme.image.sizes.big;
        case 'small':
          return props.theme.image.sizes.immense;
        case 'default':
        default:
          return props.theme.image.sizes.humongous;
      }
    }};
    height: ${(props) => {
      switch (props.size) {
        case 'mini':
          return props.theme.image.sizes.big;
        case 'small':
          return props.theme.image.sizes.immense;
        case 'default':
        default:
          return props.theme.image.sizes.humongous;
      }
    }};
    font-size: 0.8em;
  }
`;
