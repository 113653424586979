import './Association.scss';

import { Trans, useTranslation } from 'react-i18next';
import { Tooltip } from 'semji-core/components/Tooltip';

import HelpAltIcon from '@/components/icons/HelpAltIcon';
import { CONTENT_STATUS } from '@/containers/AddToPlanning/AddToPlanning.types';
import KeywordAssociation from '@/containers/AddToPlanning/Association/KeywordAssociation';

function Association({
  type,
  handleTitleChange,
  handleUrlChange,
  handleAddToPlanning,
  isButtonDisabled,
  handleRemoveFromContentToAdd,
  contents,
  pages,
  handleFocusKeywordChange,
}) {
  const { t } = useTranslation();

  return (
    <div className="add-to-planning-dialog-association">
      <div className="add-to-planning-dialog-association__header">
        <div className="add-to-planning-dialog-association__header__cell">
          {t('add-to-planning:associate.focus-keyword')}
          <Tooltip
            className="add-to-planning-dialog-association__header__cell__tooltip"
            isFrame={false}
            noDivider
            title={t('add-to-planning:associate.focus-keyword-tooltip')}
          >
            <HelpAltIcon />
          </Tooltip>
        </div>
        <div className="add-to-planning-dialog-association__header__cell">
          <Trans
            components={{
              optional: <span className="add-to-planning-dialog-association__optional" />,
            }}
            i18nKey={
              type === CONTENT_STATUS.NEW_CONTENT
                ? t('add-to-planning:associate.draft-title')
                : t('add-to-planning:associate.url')
            }
          />
          {type === CONTENT_STATUS.NEW_CONTENT && (
            <Tooltip
              className="add-to-planning-dialog-association__header__cell__tooltip"
              isFrame={false}
              noDivider
              title={t('add-to-planning:associate.draft-title-tooltip')}
            >
              <HelpAltIcon />
            </Tooltip>
          )}
        </div>
      </div>
      {contents.map((content, index) => (
        <KeywordAssociation
          key={content.id}
          contentsLength={contents.length}
          draftTitle={content.draftTitle}
          draftUrl={content.draftUrl}
          focusKeywordError={content.focusKeywordError}
          handleAddToPlanning={handleAddToPlanning}
          handleFocusKeywordChange={handleFocusKeywordChange}
          handleRemoveFromContentToAdd={handleRemoveFromContentToAdd}
          handleTitleChange={handleTitleChange}
          handleUrlChange={handleUrlChange}
          id={content.id}
          index={index}
          isButtonDisabled={isButtonDisabled}
          keyword={content.keyword}
          nextContentId={contents[index + 1]?.id}
          pages={pages}
          previousContentId={contents[index - 1]?.id}
          type={type}
          urlError={content.urlError}
        />
      ))}
    </div>
  );
}

export default Association;
