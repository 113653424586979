import { useMemo } from 'react';

import useDeepEqualSelector from '@/store/hooks/useDeepEqualSelector';
import { selectConversionRateGoalsMetrics } from '@/store/selectors/selectConversionRateGoalsMetrics';
import { selectConversionsGoalsMetrics } from '@/store/selectors/selectConversionsGoalsMetrics';
import { GOAL_METRIC_TEMPLATE, GOAL_RATE_METRIC_TEMPLATE } from '@/utils/metrics/constants';

export const useMetricGoalsConfig = (metricKey) => {
  const goalsList = useDeepEqualSelector(selectConversionsGoalsMetrics);
  const goalsRateList = useDeepEqualSelector(selectConversionRateGoalsMetrics);

  return useMemo(() => {
    if (GOAL_METRIC_TEMPLATE.parentCategoryKey === metricKey) {
      return goalsList;
    }
    if (GOAL_RATE_METRIC_TEMPLATE.parentCategoryKey === metricKey) {
      return goalsRateList;
    }

    return {};
  }, [goalsList, goalsRateList, metricKey]);
};
