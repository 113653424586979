import { UseGridSelectionResults } from '@/components/AGGrid/hooks/useGridSelection/useGridSelection.types';
import { IFilterTransformed } from '@/hooks/useFilters/useFilters.types';
import {
  CompetitorCluster,
  CompetitorPersona,
  CompetitorTopPage,
  CompetitorTopPagesFilters,
} from '@/types/competitors';

export interface ModifyProps {
  isOpen: boolean;
  cancel: () => void;
  filters: IFilterTransformed<CompetitorTopPagesFilters>;
  personas: CompetitorPersona[];
  clusters: CompetitorCluster[];
  competitorReportId: string;
  refetchTopPages: () => void;
  nbPages: number;
  gridSelectionHook: UseGridSelectionResults<CompetitorTopPage>;
}

export const enum TOP_PAGE_UPDATABLE_FIELDS {
  CLUSTER = 'clusterId',
  FUNNEL = 'funnel',
  PERSONA = 'personaId',
  CATEGORY = 'urlCategory',
  BRANDED = 'isKeywordBranded',
}

export const TOP_PAGE_PROPERTIES = [
  { label: 'competitors:filters.theme', value: TOP_PAGE_UPDATABLE_FIELDS.CLUSTER },
  { label: 'competitors:filters.persona', value: TOP_PAGE_UPDATABLE_FIELDS.PERSONA },
  { label: 'competitors:filters.page-type', value: TOP_PAGE_UPDATABLE_FIELDS.CATEGORY },
  { label: 'competitors:filters.funnel-stage', value: TOP_PAGE_UPDATABLE_FIELDS.FUNNEL },
  { label: 'competitors:filters.tail-traffic-type', value: TOP_PAGE_UPDATABLE_FIELDS.BRANDED },
];
