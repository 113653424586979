import { CLOSE_OUTLINE, TOGGLE_OUTLINE } from '@/store/actions/actionTypes';

const INITIAL_STATE = {
  isOutlineOpen: false,
};

export default function outline(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CLOSE_OUTLINE:
      return {
        ...state,
        isOutlineOpen: false,
      };
    case TOGGLE_OUTLINE:
      return {
        ...state,
        isOutlineOpen: !state.isOutlineOpen,
      };
    default:
      return state;
  }
}
