import { ENUM_TEXT_RANGE_TYPE } from '../constants';

function getTextRangeType({
  element,
  range,
}: {
  element: HTMLElement;
  range: Range;
}): ENUM_TEXT_RANGE_TYPE {
  const elementRange: Range = document.createRange();
  elementRange.selectNodeContents(element);

  const startToEndPoints: number = range.compareBoundaryPoints(Range.START_TO_END, elementRange);
  const endToStartPoints: number = range.compareBoundaryPoints(Range.END_TO_START, elementRange);
  const startToStartPoints: number = range.compareBoundaryPoints(
    Range.START_TO_START,
    elementRange
  );
  const endToEndPoints: number = range.compareBoundaryPoints(Range.END_TO_END, elementRange);

  if (startToEndPoints <= 0 || endToStartPoints >= 0) {
    return ENUM_TEXT_RANGE_TYPE.NOT_IN_RANGE;
  }

  if (startToStartPoints < 0) {
    if (endToEndPoints < 0) {
      return ENUM_TEXT_RANGE_TYPE.BEFORE_PARTIALLY_RANGE;
    }

    return ENUM_TEXT_RANGE_TYPE.IN_RANGE;
  }

  if (endToEndPoints > 0) {
    return ENUM_TEXT_RANGE_TYPE.AFTER_PARTIALLY_RANGE;
  }

  if (startToStartPoints === 0 && endToEndPoints === 0) {
    return ENUM_TEXT_RANGE_TYPE.IN_RANGE;
  }

  return ENUM_TEXT_RANGE_TYPE.PARTIALLY_RANGE;
}

export default getTextRangeType;
