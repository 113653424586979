import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const AND_OPERATOR = 'AND';
const OR_OPERATOR = 'OR';

const INITIAL_FILTERS_STATE = {
  filterGroups: [],
  // Example
  // operator: 'AND',
  // filterGroups: [
  //   {
  //     operator: 'AND',
  //     filters: [
  //       {
  //         comparison: {label: 'equals', comparison: 'eq'}, => the applied comparison (cf. list of available comparison methods)
  //         attribute: 'url', => the attribute to filter on
  //         type: STRING_TYPE_FILTER, => the type of filter (cf. list of available types {type: 'bool', comparisonList: [...]}, {type: 'string', comparisonList: [...]}, {type: 'select', comparisonList: [...]}, {type: 'date', comparisonList: [...]}, {type: 'number', comparisonList: [...]})
  //         icon: <EyeIcon />, => the icon of the filter
  //         values: [{value: '', label:''}], => the value of the filter (currently we support only one value)
  //         getValueByAttribute: (list) => list.contentScore * 100, => filter formatter for cases that the value is not the same as we want to compare to (percentage for instance)
  //         placeholder: ' Planned', => a placeholder that will be concatenated to the comparison label for bool filters
  //         mappingValues: (value) => ({ value, label: ' Planned' }), => mapping value for cases when we want to customize the displayed filter value
  //         selectList: [{ value, labelKey: '' })], => Array of values for filter type SELECT_TYPE_FILTER
  //       }
  //     ]
  //   }
  // ],
  operator: AND_OPERATOR,
};

export function useFilterGroups() {
  const { t } = useTranslation();

  const OPERATORS = useMemo(
    () => [
      {
        labelKey: t('components:filters.and'),
        type: AND_OPERATOR,
      },
      {
        labelKey: t('components:filters.or'),
        type: OR_OPERATOR,
      },
    ],
    [t]
  );

  function getOperatorLabel(operatorType) {
    return OPERATORS.find((ope) => ope.type === operatorType)?.labelKey;
  }

  return { AND_OPERATOR, INITIAL_FILTERS_STATE, OPERATORS, OR_OPERATOR, getOperatorLabel };
}
