import styled from 'styled-components/macro';

const Stepperinfo = styled.div`
  display: flex;
  align-items: center;
  margin: 0 1rem;
  color: ${({ isError, theme }) => (isError ? theme.cssColors.red : theme.cssColors.dark040)};
  font-size: ${({ theme }) => theme.text.sizes.small};
`;

export default Stepperinfo;
