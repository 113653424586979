import styled from 'styled-components/macro';

export const Text = styled.span`
  color: ${({ primary, theme }) => (primary ? theme.cssColors.primary : theme.cssColors.dark080)};
  font-size: ${({ size, theme }) => theme.textCss.sizes[size || 'sm']};
  font-weight: ${({ theme, weight }) => theme.textCss.weights[weight || 'normal']};
  line-height: ${({ theme }) => theme.textCss.sizes.md};
  cursor: ${({ isPointer }) => (isPointer ? 'pointer' : 'auto')};
  strong {
    font-weight: 500;
  }
`;
export const Wrapper = styled.div`
  box-sizing: border-box;
  min-height: 50px;
  padding: ${({ padding }) => (padding ? padding : '10px 10px 10px 8px')};
  margin: ${({ margin }) => margin};
  background-color: ${({ color }) => color};
  display: ${({ display }) => (display ? display : 'flex')};
  align-items: center;
  border-radius: 4px;
  position: ${({ position }) => position};
`;
