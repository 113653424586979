import { SPLIT_DATA_SYMBOL } from '@/utils/highlight/constants';

import {
  PENDING_THREAD_ID,
  SEMJI_THREAD_HIGHLIGHT_CLASS_NAME,
  THREAD_CARD_PREFIX_ID,
} from './constants';
import { ENUM_SEMJI_THREAD_ATTRIBUTES } from './useComments.types';

export class CommentDOMUtils {
  private static getElementsByFocusAttribute(): NodeListOf<HTMLElement> {
    return document.querySelectorAll(
      `[${ENUM_SEMJI_THREAD_ATTRIBUTES.SEMJI_THREAD_FOCUSED_ATTRIBUTE}]`
    );
  }

  private static getFocusedElements(): NodeListOf<HTMLElement> {
    return document.querySelectorAll(
      `[${ENUM_SEMJI_THREAD_ATTRIBUTES.SEMJI_THREAD_FOCUSED_ATTRIBUTE}="true"]`
    );
  }

  private static getElementsByThreadAttribute(): NodeListOf<HTMLElement> {
    return document.querySelectorAll(
      `[${ENUM_SEMJI_THREAD_ATTRIBUTES.SEMJI_THREAD_DATA_ATTRIBUTE}]`
    );
  }

  private static getElementsBySelector(selector: string): HTMLElement | null {
    return document.querySelector(selector);
  }

  private static getThreadsByElement(element: HTMLElement): string {
    return element.getAttribute(ENUM_SEMJI_THREAD_ATTRIBUTES.SEMJI_THREAD_DATA_ATTRIBUTE) ?? '';
  }

  private static getThreadsIdsByElement(element: HTMLElement): string[] {
    return CommentDOMUtils.getThreadsByElement(element).split(SPLIT_DATA_SYMBOL).filter(Boolean);
  }

  private static setCommentClassFormElement(element: HTMLElement): void {
    element.classList.add(SEMJI_THREAD_HIGHLIGHT_CLASS_NAME);
  }

  private static setFocusForElement({
    element,
    isFocused,
  }: {
    element: HTMLElement;
    isFocused: boolean;
  }): void {
    element.setAttribute(
      ENUM_SEMJI_THREAD_ATTRIBUTES.SEMJI_THREAD_FOCUSED_ATTRIBUTE,
      isFocused.toString()
    );
  }

  private static setResolveForElement({
    element,
    isResolved,
  }: {
    element: HTMLElement;
    isResolved: boolean;
  }): void {
    element.setAttribute(
      ENUM_SEMJI_THREAD_ATTRIBUTES.SEMJI_THREAD_RESOLVED_ATTRIBUTE,
      isResolved.toString()
    );
  }

  private static setDisableForElement({
    element,
    isDisabled,
  }: {
    element: HTMLElement;
    isDisabled: boolean;
  }): void {
    element.setAttribute(
      ENUM_SEMJI_THREAD_ATTRIBUTES.SEMJI_THREAD_DISABLED_ATTRIBUTE,
      isDisabled.toString()
    );
  }

  private static shouldElementBeFocused({
    element,
    focusedThreadId,
  }: {
    element: HTMLElement;
    focusedThreadId: string | null;
  }): boolean {
    if (!focusedThreadId) {
      return false;
    }

    return CommentDOMUtils.getThreadsIdsByElement(element).includes(focusedThreadId);
  }

  private static shouldElementBeResolved({
    element,
    unresolvedThreads,
  }: {
    element: HTMLElement;
    unresolvedThreads: { id: string; resolved: boolean }[];
  }): boolean {
    const elementThreadsIds = CommentDOMUtils.getThreadsIdsByElement(element);
    return false === unresolvedThreads.some(({ id }) => elementThreadsIds.includes(id));
  }

  static replacePendingCommentThread(threadId: string): void {
    const elements = CommentDOMUtils.getElementsByDataAttribute(PENDING_THREAD_ID);

    elements.forEach((element) => {
      const newData = CommentDOMUtils.getThreadsByElement(element).replace(
        PENDING_THREAD_ID,
        threadId
      );

      element.setAttribute(ENUM_SEMJI_THREAD_ATTRIBUTES.SEMJI_THREAD_DATA_ATTRIBUTE, newData);
    });
  }

  static getElementsByDataAttribute(threadId: string): NodeListOf<HTMLElement> {
    return document.querySelectorAll(
      `[${ENUM_SEMJI_THREAD_ATTRIBUTES.SEMJI_THREAD_DATA_ATTRIBUTE}*='${threadId}']`
    );
  }

  static handleThreadElementAttributes({
    threads,
    isCommentsEnabled,
    focusedThreadId,
  }: {
    threads: { id: string; resolved: boolean }[] | undefined;
    isCommentsEnabled: boolean;
    focusedThreadId: string | null;
  }): void {
    if (!threads) {
      return;
    }

    const unresolvedThreads = threads.filter((thread) => false === thread.resolved);
    const elements = CommentDOMUtils.getElementsByThreadAttribute();

    elements.forEach((element) => {
      // if comments are disabled, we set the attribute to true
      //we do not need to set the other attributes, they are not necessary
      if (!isCommentsEnabled) {
        CommentDOMUtils.setDisableForElement({ element, isDisabled: true });
        return;
      }

      // we need to set the attribute to resolved=true only if it is not set yet or it is true by another thread
      // if another thread is saying that the thread is not resolved (false), we want to keep it that way
      // https://gitlab.rvip.fr/semji/semji/-/issues/6470#note_183960
      const isResolved = CommentDOMUtils.shouldElementBeResolved({ element, unresolvedThreads });
      const isFocused = CommentDOMUtils.shouldElementBeFocused({ element, focusedThreadId });

      CommentDOMUtils.setResolveForElement({ element, isResolved });
      CommentDOMUtils.setFocusForElement({ element, isFocused });
      CommentDOMUtils.setCommentClassFormElement(element);
    });
  }

  static handleFocusByThreadId(focusedThreadId: string | null): void {
    if (!focusedThreadId) {
      const elements = CommentDOMUtils.getFocusedElements();

      // on query param threadId removed, remove all focus
      elements.forEach((element) => {
        CommentDOMUtils.setFocusForElement({ element, isFocused: false });
      });

      return;
    }

    const elements = CommentDOMUtils.getElementsByFocusAttribute();

    // on query param threadId change, set all threads to false except the focused one
    elements.forEach((element) => {
      const isFocused = CommentDOMUtils.shouldElementBeFocused({ element, focusedThreadId });

      CommentDOMUtils.setFocusForElement({ element, isFocused });
    });
  }

  static isPendingThreadHaveAComment(): boolean {
    const onGoingComment = CommentDOMUtils.getElementsBySelector(
      `#${THREAD_CARD_PREFIX_ID}${PENDING_THREAD_ID} textarea`
    ) as HTMLTextAreaElement | null;

    return !!onGoingComment?.value;
  }
}
