import stripTagsAndAttributes from '@/apis/semji/utils/stripTagsAndAttributes';
import { ContentUtils } from '@/containers/Content/TinyMceComponents/Editor/hooks/useContent/content.utils';
import { setRefreshTokenCookie, setTokenCookie } from '@/utils/cookies/cookies';

import { client } from './client';
import apiSettings from './settings';

// ======================  ANALYTICS CREDENTIALS  ====================== //

export const fetchGoogleCredentials = (authCode) =>
  client.post(`${apiSettings.rootUrl}/google_credentials`, {
    code: authCode,
  });

// ======================  CONTENT  ====================== //
export const fetchContent = (contentId) =>
  client.get(`${apiSettings.rootUrl}/contents/${contentId}`).then((d) => {
    const html = stripTagsAndAttributes(d.html);

    return {
      ...d,
      html,
    };
  });

export const fetchWorkspaceContents = ({
  workspaceId,
  filters = {
    inStock: false,
  },
  properties,
}) =>
  client.get(`${apiSettings.rootUrl}/workspaces/${workspaceId}/contents`, {
    ...filters,
    properties: properties || [
      'id',
      'createdAt',
      'updatedAt',
      'title',
      'type',
      'publishedAt',
      'page',
      'assignedTo',
      'dueDate',
      'contentStatus',
      'contentScore',
      'redirectionUrl',
      'lastStatusCode',
      'workspace',
      'pageFocusTopKeyword',
      'folderId',
    ],
  });

export const publishPageContent = (contentId, url, date) =>
  client.post(`${apiSettings.rootUrl}/contents/${contentId}/publication`, {
    date,
    url,
  });
// ======================  TOP KEYWORD  ====================== //
export const addTopKeywordOnPage = (pageId, keyword) =>
  client.post(`${apiSettings.rootUrl}/pages/${pageId}/top_keywords`, {
    keyword,
  });
export const getTopKeywordOnPage = (pageId) =>
  client.get(`${apiSettings.rootUrl}/pages/${pageId}/top_keywords`);

export const getWorkspaceFocusTopKeywords = (workspaceId, { properties, filters }) => {
  let data = {
    ...(filters ?? {}),
  };
  if (null !== properties) {
    data = {
      ...data,
      properties,
    };
  }
  return client.get(`${apiSettings.rootUrl}/workspaces/${workspaceId}/focus_top_keywords`, data);
};

export const setPageFocusTopKeyword = (pageId, topKeywordId) =>
  client.put(`${apiSettings.rootUrl}/pages/${pageId}`, {
    focusTopKeyword: topKeywordId,
  });

export const fetchTopKeywordReport = (topKeywordId, data) =>
  client.post(`${apiSettings.rootUrl}/top_keywords/${topKeywordId}/report`, data);

export const unlockRecommendations = (topKeywordId, { type, amount }) =>
  client.post(`${apiSettings.rootUrl}/top_keywords/${topKeywordId}/unlock`, { amount, type });

export const bulkImportPagesFromStock = (workspaceId, pageIds) =>
  client.post(`${apiSettings.rootUrl}/workspaces/${workspaceId}/bulk_page_import_from_stock`, {
    pageIds,
  });

export const bulkImportPagesFromUrl = (workspaceId, pageUrls, isInStock = true) =>
  client.post(`${apiSettings.rootUrl}/workspaces/${workspaceId}/bulk_page_import`, {
    isInStock,
    urls: pageUrls,
  });

export const addCurrentPageDraft = (workspaceId, content) =>
  client.post(`${apiSettings.rootUrl}/workspaces/${workspaceId}/contents`, {
    assignedTo: null,
    contentStatus: null,
    dueDate: null,
    folderId: null,
    html: ContentUtils.createEmptyHTML(),
    page: null,
    title: '',
    type: 'DRAFT',
    version: 0,
    ...content,
  });

export const getPageContents = (pageId) =>
  client.get(`${apiSettings.rootUrl}/pages/${pageId}/contents`, {
    properties: ['id', 'contentScore', 'publishedAt', 'type'],
  });

export const pageRefreshData = (pageId) =>
  client.post(`${apiSettings.rootUrl}/pages/${pageId}/refresh_data`);

export const pageRefreshDataAsync = (pageId) =>
  client.post(`${apiSettings.rootUrl}/pages/${pageId}/refresh_data_async`);

export const savePageDraft = (content) =>
  client.put(`${apiSettings.rootUrl}/contents/${content.id}`, { ...content });

export const refreshInternalLinksToOptimization = (topKeywordId) =>
  client.post(`${apiSettings.rootUrl}/top_keywords/${topKeywordId}/refresh_internal_backlinks`);

export const disableOptimization = (topKeywordId, optimizationKey) =>
  client.post(
    `${apiSettings.rootUrl}/top_keywords/${topKeywordId}/disabled_optimizations/${optimizationKey}`
  );

export const enableOptimization = (topKeywordId, optimizationKey) =>
  client.delete(
    `${apiSettings.rootUrl}/top_keywords/${topKeywordId}/disabled_optimizations/${optimizationKey}`
  );

// ====================== BRIEF ====================== //

export const fetchBrief = (topKeywordId) =>
  client.get(`${apiSettings.rootUrl}/top_keywords/${topKeywordId}/brief`);

export const downloadBrief = (topKeywordId) =>
  client.post(`${apiSettings.rootUrl}/top_keywords/${topKeywordId}/brief_download`);

export const generateBriefTitles = (topKeywordId) =>
  client.post(`${apiSettings.rootUrl}/top_keywords/${topKeywordId}/brief/titles`);

export const reportGeneratedTitle = (generatedTitleId, report) =>
  client.put(`${apiSettings.rootUrl}/generated_titles/${generatedTitleId}/report`, {
    report,
  });

export const deleteGeneratedTitle = (generatedTitleId) =>
  client.delete(`${apiSettings.rootUrl}/generated_titles/${generatedTitleId}`);

export const generateBriefSearchIntents = (topKeywordId, searchIntentId) =>
  client.post(
    `${apiSettings.rootUrl}/top_keywords/${topKeywordId}/brief/search_intents/${searchIntentId}`
  );

export const reportGeneratedSearchIntent = (generatedSearchIntentId, report) =>
  client.put(`${apiSettings.rootUrl}/generated_search_intents/${generatedSearchIntentId}/report`, {
    report,
  });

export const deleteGeneratedSearchIntent = (generatedSearchIntentId) =>
  client.delete(`${apiSettings.rootUrl}/generated_search_intents/${generatedSearchIntentId}`);

// ====================== USER ====================== //

export const fetchAuthenticatedUser = () => client.get(`${apiSettings.rootUrl}/me`);

export const postUserHubspotVisitorToken = () =>
  client.post(`${apiSettings.rootUrl}/me/hubspot_visitor_token`);

export const fetchSamlUrl = (email, returnUrl) =>
  client.post(`${apiSettings.rootUrl}/user/saml_url`, {
    email,
    returnUrl,
  });

export const fetchRegistrant = (token) => client.get(`${apiSettings.rootUrl}/registrants/${token}`);

// TODO: investigate why it looks like it's not used anymore but webpack still see it in use somewhere.
export const registerUser = (registrantToken, values) =>
  client.put(`${apiSettings.rootUrl}/registrants/${registrantToken}`, values).then((res) => {
    const { token, refreshToken, refreshTokenExpiration } = res;
    setTokenCookie(token);
    setRefreshTokenCookie({
      refreshToken,
      refreshTokenExpiration,
    });

    return res;
  });

export const fetchWorkspace = (workspaceId) =>
  client.get(`${apiSettings.rootUrl}/workspaces/${workspaceId}`);

export const fetchOrganizationWorkspaces = (organizationId) =>
  client.get(`${apiSettings.rootUrl}/organizations/${organizationId}/workspaces`);

export const fetchOrganization = (organizationId) =>
  client.get(`${apiSettings.rootUrl}/organizations/${organizationId}`);

export const fetchUserOrganizations = (userId) =>
  client.get(`${apiSettings.rootUrl}/users/${userId}/organizations`);

export const fetchCompanyInformation = (userId) =>
  client.get(`${apiSettings.rootUrl}/users/${userId}/company`);

export const deleteContent = (contentId) =>
  client.delete(`${apiSettings.rootUrl}/contents/${contentId}`);

export const refreshContentData = (contentId) =>
  client.post(`${apiSettings.rootUrl}/contents/${contentId}/refresh_data`).then((d) => {
    const html = stripTagsAndAttributes(d.html);

    return {
      ...d,
      html,
    };
  });

export const bulkDeleteContent = (workspaceId, contentsIds) =>
  client.delete(`${apiSettings.rootUrl}/workspaces/${workspaceId}/contents/bulk_delete`, {
    contentsIds,
  });

export const exportContents = (contentsIds, fileType) =>
  client.post(`${apiSettings.rootUrl}/contents_export`, {
    contentsIds,
    fileType,
  });

export const exportPagesReport = ({
  workspaceId,
  pagesIds,
  fileType,
  currentPeriod,
  previousPeriod,
  comparisonPeriodLabel,
  periodLabel,
  positionCurrentDate,
  positionPreviousDate,
  reportType,
}) =>
  client.post(`${apiSettings.rootUrl}/workspaces/${workspaceId}/pages_report_export`, {
    comparisonPeriodLabel,
    currentPeriod,
    fileType,
    pagesIds,
    periodLabel,
    positionCurrentDate,
    positionPreviousDate,
    previousPeriod,
    reportType,
  });

export const exportCompetitorTopPages = ({ competitorReportId, fileType, filters }) =>
  client.post(
    `${apiSettings.rootUrl}/competitor_reports/${competitorReportId}/competitor_top_page_export`,
    {
      fileType,
      filters,
    }
  );

export const exportPages = ({ workspaceId, pagesIds, fileType, filters }) =>
  client.post(`${apiSettings.rootUrl}/workspaces/${workspaceId}/pages_export`, {
    fileType,
    filters,
    pagesIds,
  });

export const exportContentIdeas = ({ contentIdeaSearchId, contentIdeasIds, fileType }) =>
  client.post(
    `${apiSettings.rootUrl}/content_idea_searches/${contentIdeaSearchId}/content_ideas_export`,
    {
      contentIdeasIds,
      fileType,
    }
  );

// ======================  Organization SETTINGS  ====================== //

export const postInviteUsersOnOrganization = (organizationId, users, workspaces, action) =>
  client.post(`${apiSettings.rootUrl}/organizations/${organizationId}/invite_users`, {
    action,
    users,
    workspaces,
  });

export const postOrganization = (userId, payload) =>
  client.post(`${apiSettings.rootUrl}/users/${userId}/organizations`, payload);

// ======================  WORKSPACE SETTINGS  ====================== //
export const fetchWorkspaceUsers = (workspaceId) =>
  client.get(`${apiSettings.rootUrl}/workspaces/${workspaceId}/users`);
export const fetchWorkspaceWorkspaceAccesses = (workspaceId) =>
  client.get(`${apiSettings.rootUrl}/workspaces/${workspaceId}/workspace_accesses`);

export const postWorkspaceContentStatus = (workspaceId, values) =>
  client.post(`${apiSettings.rootUrl}/workspaces/${workspaceId}/content_statuses`, { ...values });
export const putWorkspaceContentStatusPriorities = (workspaceId, content_status_ids) =>
  client.put(`${apiSettings.rootUrl}/workspaces/${workspaceId}/content_statuses_priorities`, {
    content_status_ids,
  });
export const fetchWorkspaceStatus = (workspaceId) =>
  client.get(`${apiSettings.rootUrl}/workspaces/${workspaceId}/content_statuses`);
export const putStatus = (statusId, values) =>
  client.put(`${apiSettings.rootUrl}/content_statuses/${statusId}`, { ...values });
export const deleteWorkspaceStatus = (statusId) =>
  client.delete(`${apiSettings.rootUrl}/content_statuses/${statusId}`);

export const postInviteUsersOnWorkspace = (workspaceId, users) =>
  client.post(`${apiSettings.rootUrl}/workspaces/${workspaceId}/invite_users`, {
    users,
  });

export const postWorkspace = (organizationId, workspace) =>
  client.post(`${apiSettings.rootUrl}/organizations/${organizationId}/workspaces`, {
    ...workspace,
  });

export const deleteWorkspace = (workspaceId) =>
  client.delete(`${apiSettings.rootUrl}/workspaces/${workspaceId}`);

export const disableWorkspace = (workspaceId) =>
  client.post(`${apiSettings.rootUrl}/workspaces/${workspaceId}/disable`);

export const putWorkspace = (workspaceId, values) =>
  client.put(`${apiSettings.rootUrl}/workspaces/${workspaceId}`, {
    ...values,
  });

// ======================  PAGE  ====================== //
export const fetchPageCompetitorPages = (pageId) =>
  client.get(`${apiSettings.rootUrl}/pages/${pageId}/competitors_pages`);

export const fetchAggregatedPageMetrics = (workspaceId, filters = {}) =>
  client.get(`${apiSettings.rootUrl}/workspaces/${workspaceId}/page_metrics`, filters);

export const postAggregatedPageMetrics = (workspaceId, payload = {}, filters = {}) =>
  client.post(`${apiSettings.rootUrl}/workspaces/${workspaceId}/page_metrics`, payload, filters);

export const deletePage = (id) => client.delete(`${apiSettings.rootUrl}/pages/${id}`);

export const fetchPageMetrics = (pageId, dateRange, periodicity = 'daily') =>
  client.get(`${apiSettings.rootUrl}/pages/${pageId}/page_metrics`, {
    'date[after]': dateRange.from,
    'date[before]': dateRange.to,
    itemsPerPage: 1000000,
    'order[date]': 'asc',
    periodicity,
  });

export const fetchPagesForWorkspace = (
  workspaceId,
  filters,
  additionalProperties = [],
  mainProperties = null
) => {
  mainProperties = mainProperties || [
    'id',
    'url',
    'title',
    'contentScore',
    'draft',
    'draftId',
    'extra',
    'optimizedImages',
    'imageUrl',
    'source',
    'new',
    'redirectionUrl',
    'focusTopKeyword',
    'isTracked',
    'titleRetrievedAt',
    'topKeywordsRetrievedAt',
    'lastStatusCode',
  ];

  return client.get(`${apiSettings.rootUrl}/workspaces/${workspaceId}/pages`, {
    ...filters,
    properties: [...mainProperties, ...additionalProperties],
  });
};

export const fetchPage = (pageId) => client.get(`${apiSettings.rootUrl}/pages/${pageId}`);
// ======================  CREDITS ====================== //

export const fetchOrganizationCreditsUsage = (organizationId) =>
  client.get(
    `${apiSettings.rootUrl}/organizations/${organizationId}/organization_unit_usages.json`
  );

// ======================  WORKSPACE ACCESSES ====================== //

export const setWorkspaceAccesses = (workspaceAccessesId, workspaceAccesses) =>
  client.put(`${apiSettings.rootUrl}/workspace_accesses/${workspaceAccessesId}`, {
    ...workspaceAccesses,
  });
export const deleteWorkspaceAccesses = (workspaceAccessesId) =>
  client.delete(`${apiSettings.rootUrl}/workspace_accesses/${workspaceAccessesId}`);

export const getUserWorkspaceAccesses = (userId) =>
  client.get(`${apiSettings.rootUrl}/users/${userId}/workspace_accesses`);

// ======================  ORGANIZATION ACCESSES ====================== //

export const setOrganizationAccesses = (organizationAccessesId, organizationAccesses) =>
  client.put(`${apiSettings.rootUrl}/organization_accesses/${organizationAccessesId}`, {
    ...organizationAccesses,
  });
export const deleteOrganizationAccesses = (organizationAccessesId) =>
  client.delete(`${apiSettings.rootUrl}/organization_accesses/${organizationAccessesId}`);

export const getUserOrganizationAccesses = (userId) =>
  client.get(`${apiSettings.rootUrl}/users/${userId}/organization_accesses`);

export const fetchOrganizationOrganizationAccesses = (organizationId) =>
  client.get(`${apiSettings.rootUrl}/organizations/${organizationId}/organization_accesses`);

// ======================  SERVICE INTEGRATIONS ====================== //

export const createServiceIntegration = ({ type, workspaceId, data, name }) =>
  client.post(`${apiSettings.rootUrl}/workspaces/${workspaceId}/service_integrations`, {
    data,
    name,
    type,
  });

// ======================  SSO ====================== //
export const getSamlConfiguration = (organizationId) =>
  client.get(
    `${apiSettings.rootUrl}/organizations/${organizationId}/organization_saml_configurations`
  );
export const getSamlDomains = (organizationId) =>
  client.get(`${apiSettings.rootUrl}/organizations/${organizationId}/organization_domains`);
export const postSamlConfiguration = ({ organizationId, ...props }) =>
  client.post(
    `${apiSettings.rootUrl}/organizations/${organizationId}/organization_saml_configurations`,
    {
      ...props,
    }
  );
export const putSamlConfiguration = ({ samlConfigurationId, ...props }) =>
  client.put(`${apiSettings.rootUrl}/organization_saml_configurations/${samlConfigurationId}`, {
    ...props,
  });

// ======================  LOGIN/PWD ====================== //
export const login = ({ username, password }) =>
  client
    .post(
      `${apiSettings.rootUrl}/login_check`,
      {
        password,
        username: username?.toLowerCase()?.trim(),
      },
      { disableImpersonation: true }
    )
    .then((res) => {
      const { token, refreshToken, refreshTokenExpiration } = res;

      setTokenCookie(token);
      setRefreshTokenCookie({
        refreshToken,
        refreshTokenExpiration,
      });

      return res;
    });

export const googleOAuthLogin = ({ googleToken, autoCreate, updateProfile }) =>
  client
    .post(
      `${apiSettings.rootUrl}/login_token_check`,
      {
        autoCreate,
        googleToken,
        updateProfile,
      },
      { disableImpersonation: true }
    )
    .then((res) => {
      const { token, refreshToken, refreshTokenExpiration } = res;
      setTokenCookie(token);
      setRefreshTokenCookie({
        refreshToken,
        refreshTokenExpiration,
      });

      return res;
    });

export const faceIoLogin = ({ facialId, username }) =>
  client
    .post(
      `${apiSettings.rootUrl}/facial_login_check`,
      {
        facialId,
        username: username?.toLowerCase()?.trim(),
      },
      { disableImpersonation: true }
    )
    .then((res) => {
      const { token, refreshToken, refreshTokenExpiration } = res;
      setTokenCookie(token);
      setRefreshTokenCookie({
        refreshToken,
        refreshTokenExpiration,
      });

      return res;
    });

export const refreshToken = (refreshToken) =>
  client
    .post(`${apiSettings.rootUrl}/token_refresh`, {
      refreshToken,
    })
    .then((res) => {
      const { token, refreshToken, refreshTokenExpiration } = res;

      setTokenCookie(token);
      setRefreshTokenCookie({
        refreshToken,
        refreshTokenExpiration,
      });

      return res;
    });

export const resetPassword = (email) =>
  client.post(`${apiSettings.rootUrl}/user/reset_password`, {
    email,
  });

export const setUserPassword = (id, payload) =>
  client.post(`${apiSettings.rootUrl}/users/${id}/password`, payload);

export const putUserData = (id, data) => client.put(`${apiSettings.rootUrl}/users/${id}`, data);

export const deleteUser = (id) => client.delete(`${apiSettings.rootUrl}/users/${id}`);

export const setUserEmail = (id, payload) =>
  client.post(`${apiSettings.rootUrl}/users/${id}/email`, payload).then((res) => {
    const { token } = res;
    setTokenCookie(token);
    return res;
  });

export const postRegistrant = (payload) =>
  client.post(`${apiSettings.rootUrl}/users/register`, payload);

export const getResetUserPassword = (token) =>
  client.get(`${apiSettings.rootUrl}/password_resets/${token}`);

export const resetUserPassword = (token, values) =>
  client.put(`${apiSettings.rootUrl}/password_resets/${token}`, values).then((res) => {
    const { token, refreshToken, refreshTokenExpiration } = res;

    setTokenCookie(token);
    setRefreshTokenCookie({
      refreshToken,
      refreshTokenExpiration,
    });

    return res;
  });

// ======================  KEYWORDS SUGGEST ====================== //
export const postKeywordSuggestions = (workspaceId, keyword) =>
  client.post(`${apiSettings.rootUrl}/workspaces/${workspaceId}/related_keywords_suggestions`, {
    keyword,
    type: 'KEYWORDS',
  });
export const postKeywordQuestions = (workspaceId, keyword) =>
  client.post(`${apiSettings.rootUrl}/workspaces/${workspaceId}/related_keywords_suggestions`, {
    keyword,
    type: 'QUESTION_KEYWORDS',
  });

// ======================  COUNTRIES ====================== //
export const fetchCountries = () => client.get(`${apiSettings.rootUrl}/countries`);

// ======================  BILLING ====================== //

export const cancelSubscription = (organizationId, payload) =>
  client.post(
    `${apiSettings.rootUrl}/organizations/${organizationId}/subscription_cancellation`,
    payload
  );

export const getSession = (organizationId, redirectUrl) =>
  client.post(`${apiSettings.rootUrl}/organizations/${organizationId}/billing/session`, {
    redirectUrl,
  });

export const downloadInvoice = (organizationId, payload) =>
  client.post(`${apiSettings.rootUrl}/organizations/${organizationId}/invoices/download`, payload);

export const getInvoices = (organizationId) =>
  client.get(`${apiSettings.rootUrl}/organizations/${organizationId}/invoices`);

export const getClientInformation = (organizationId) =>
  client.get(`${apiSettings.rootUrl}/organizations/${organizationId}/billing`);

export const deleteSubscriptionCancellation = (organizationId) =>
  client.delete(`${apiSettings.rootUrl}/organizations/${organizationId}/subscription_cancellation`);

// ====================== CONFIGURATIONS ====================== //
export const fetchConfigurations = () => client.get(`${apiSettings.rootUrl}/configurations`);

// ====================== URL CHECKER ====================== //
export const checkUrl = (url) => client.post(`${apiSettings.rootUrl}/url_checker`, { url });

// ====================== AI WRITING ====================== //
export const generateTitle = (focusKeyword) =>
  client.post(`${apiSettings.rootUrl}/top_keywords/${focusKeyword}/completions/titles`);

export const generateOutlines = ({ focusKeyword, html, title }) =>
  client.post(`${apiSettings.rootUrl}/top_keywords/${focusKeyword}/completions/outlines`, {
    html,
    title,
  });

export const generateIntroduction = ({ focusKeyword, title, heading, html }) =>
  client.post(`${apiSettings.rootUrl}/top_keywords/${focusKeyword}/completions/introduction`, {
    heading,
    html,
    title,
  });

export const reportGeneratedAiContent = (id, report) =>
  client.put(`${apiSettings.rootUrl}/generated_contents/${id}/report`, {
    report,
  });
