import { useMutation } from '@tanstack/react-query';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import apiClient from '@/apis/semji/apiClient';
import { showErrorSnackbar } from '@/store/actions/ui';

export default function usePostGenerateTitle(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { post } = apiClient;
  const abortControllerRef = useRef(null);

  const mutation = useMutation({
    mutationFn: async ({ focusTopKeywordId, generatedContentToken = null }) => {
      abortControllerRef.current = new AbortController();
      const response = await post(
        `/top_keywords/${focusTopKeywordId}/completions/titles`,
        { generatedContentToken },
        {
          signal: abortControllerRef.current.signal,
        }
      );

      if (response.status === 429) {
        throw new Error('rate-limit');
      }
      return response;
    },
    onError: () => {
      dispatch(showErrorSnackbar(t('common:error.default')));
    },
    ...props,
  });

  const abort = useCallback(() => {
    abortControllerRef.current?.abort();
  }, [mutation.abort]);

  return {
    ...mutation,
    abort,
  };
}
