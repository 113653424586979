import React from 'react';

import SvgWrapper from './SvgWrapper';

const TextIcon = (props) => (
  <SvgWrapper {...props}>
    <path d="M14 2v3h-1v-2h-4v12h1.643v1h-4.286v-1h1.643v-12h-4v2h-1v-3h11z" />
  </SvgWrapper>
);

export default TextIcon;
