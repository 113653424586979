import { useTranslation } from 'react-i18next';

import Tabs, { Label, Tab } from '@/components/Navigation/Tabs';
import { PanelHeader } from '@/components/Panel/Panel';

const HIDDEN_TABS = 'hidden_tabs';

export function ReportTabs({ currentMetricKey, metricsList, selectMetricClick, className }) {
  const { t } = useTranslation();

  const defaultMetrics = metricsList.filter((metric) => metric.default);
  const selectMetric = (metric) => selectMetricClick(metric.key);

  return (
    <PanelHeader className={className} smallHeight>
      <Tabs
        value={
          defaultMetrics.find((elem) => elem.key === currentMetricKey && elem.default)
            ? currentMetricKey
            : HIDDEN_TABS
        }
      >
        {defaultMetrics.flatMap((metric) =>
          !metric.isSubCategory
            ? [
                <Tab
                  key={metric.key}
                  data-intercom-target={`report_tab_${metric.key}_metric`}
                  label={
                    <div>
                      <Label
                        endAdornmentIcon={metric.badge}
                        label={t(`${metric.name}`)}
                        startAdornmentIcon={metric.icon}
                      />
                    </div>
                  }
                  smallHeight
                  smallWidth
                  value={metric.key}
                  onClick={() => selectMetric(metric)}
                />,
              ]
            : []
        )}
      </Tabs>
    </PanelHeader>
  );
}
