import './DashboardChartMetric.scss';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import NoContentsVerticalBars from '@/components/EmptyState/NoContentsVerticalBars';
import NoServiceIntegrationCurve, {
  NO_ANALYTICS_REPORT,
  NO_SEARCH_CONSOLE_REPORT,
} from '@/components/EmptyState/NoServiceIntegrationCurve';
import UpgradePlan, { ENUM_UPGRADE_PLAN_VARIANT } from '@/components/EmptyState/UpgradePlan';
import Loader from '@/components/Loader/Loader';
import Link from '@/components/Navigation/Link';
import MetricsChart from '@/containers/Chart/MetricsChart';
import { ENUM_REPORT_TITLE_VARIANT, ReportTitle } from '@/containers/Report/components/ReportTitle';
import { getNoDraftVersion } from '@/containers/Report/utils/helpers';
import { AggregatedMetrics, ReportMetricKey } from '@/containers/Report/utils/types';
import { aggregateMetrics } from '@/utils/metrics/aggregateMetrics';
import { comparisonPeriods } from '@/utils/metrics/constants';

import { DashboardChartMetricProps } from './DashboardChartMetric.types';
export default function DashboardChartMetric({
  seeDetailsLink,
  isOwner,
  isRestricted,
  isAnalyticsEmptyState,
  isSearchConsoleEmptyState,
  isLoading,
  isFullWidth,
  className,
  optionList,
  metricKey,
  metrics,
  currentMetrics = [],
  previousMetrics = [],
  comparisonPeriod,
  period,
  periodicity,
  defaultMetricStackingType,
  overrideChartProps,
  defaultActiveSubTab,
  currentCount,
  reportView,
  chartType,
}: DashboardChartMetricProps) {
  const { t } = useTranslation();
  const location = useLocation();

  const [activeSubTab, setActiveSubTab] = useState(defaultActiveSubTab);
  const [metricStackingType, setMetricStackingType] = useState(defaultMetricStackingType);

  const aggregatedMetrics: AggregatedMetrics = aggregateMetrics(currentMetrics, metrics);
  const aggregatedPreviousMetrics: AggregatedMetrics = aggregateMetrics(previousMetrics, metrics);
  const currentMetricKey: ReportMetricKey = metricKey ?? activeSubTab;

  const isMetricData = Boolean(
    aggregatedMetrics[currentMetricKey] || aggregatedPreviousMetrics[currentMetricKey]
  );
  function handleSelectItem(selected: { title: string; value: string }) {
    setActiveSubTab(selected.value);
  }

  function handleStackingChange(value: string) {
    setMetricStackingType(value);
  }

  return (
    <div
      className={`report-page-chart-wrapper ${!!optionList && 'report-page-chart-wrapper--selectable'} ${className}`}
      data-intercom-target={`report_overview_chart_${currentMetricKey}`}
    >
      <ReportTitle
        activeSubTab={activeSubTab}
        aggregatedMetrics={isRestricted ? {} : aggregatedMetrics}
        aggregatedPreviousMetrics={isRestricted ? {} : aggregatedPreviousMetrics}
        comparisonPeriodLabel={t(comparisonPeriods[comparisonPeriod].label)}
        currentCount={currentCount}
        currentMetricKey={currentMetricKey}
        currentMetricsState={currentMetrics}
        handleMetricStackingTypeChange={handleStackingChange}
        handleSelectItem={handleSelectItem}
        isNoData={
          isLoading ||
          !currentCount ||
          !isMetricData ||
          isAnalyticsEmptyState ||
          isSearchConsoleEmptyState ||
          isRestricted
        }
        metricStackingType={metricStackingType}
        SelectList={!!optionList ? optionList : undefined}
        variant={ENUM_REPORT_TITLE_VARIANT.Small}
      />
      <div className="report-page-chart-wrapper__body">
        {isRestricted ? (
          <UpgradePlan variant={ENUM_UPGRADE_PLAN_VARIANT.SMALL} />
        ) : isAnalyticsEmptyState ? (
          <NoServiceIntegrationCurve
            chartType={chartType}
            isFullWidth={isFullWidth}
            isOwner={isOwner ?? false}
            version={NO_ANALYTICS_REPORT}
          />
        ) : isSearchConsoleEmptyState ? (
          <NoServiceIntegrationCurve
            chartType={chartType}
            isFullWidth={isFullWidth}
            isOwner={isOwner ?? false}
            version={NO_SEARCH_CONSOLE_REPORT}
          />
        ) : isLoading ? (
          <Loader />
        ) : currentCount ? (
          <MetricsChart
            comparisonPeriod={comparisonPeriod}
            currentMetricKey={currentMetricKey}
            displayedMetrics={{
              [currentMetricKey]: {
                ...metrics[currentMetricKey],
                previousVisible: true,
              },
            }}
            identifier={`report_chart_${currentMetricKey}`}
            legendLeft
            metrics={currentMetrics}
            noMargin
            period={period}
            periodicity={periodicity}
            previousMetrics={previousMetrics}
            stacked={metricStackingType}
            {...overrideChartProps}
          />
        ) : (
          <NoContentsVerticalBars
            isFull={isFullWidth}
            version={getNoDraftVersion(chartType, reportView)}
          />
        )}
      </div>
      <div className="report-page-chart-wrapper__footer">
        {!isRestricted && !isLoading && (
          <Link
            className="report-page-dashboard__chart-footer-button"
            to={{
              pathname: seeDetailsLink,
              search: location.search,
            }}
          >
            {t('report:chart.see-detail')}
          </Link>
        )}
      </div>
    </div>
  );
}
