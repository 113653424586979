import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components/macro';

import ErrorIcon from '../icons/ErrorIcon';
import SuccessIcon from '../icons/SuccessIcon';
import WarningIcon from '../icons/WarningIcon';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.cssColors.dark005};
  height: 45px;
  display: flex;
  align-items: center;
  flex: 1;
`;

export const LOADING_STATUS = 'LOADING_STATUS';
export const SUCCESS_STATUS = 'SUCCESS_STATUS';
export const WARNING_STATUS = 'WARNING_STATUS';
export const ERROR__STATUS = 'ERROR_STATUS';

const IconStatus = styled(({ status, ...props }) => {
  switch (status) {
    case LOADING_STATUS:
      return <CircularProgress color="secondary" size={20} {...props} />;
    case SUCCESS_STATUS:
      return <SuccessIcon {...props} />;
    case WARNING_STATUS:
      return <WarningIcon {...props} />;
    case ERROR__STATUS:
      return <ErrorIcon {...props} />;
    default:
      return null;
  }
})`
  && {
    font-size: 1.4em;
    margin: 0 10px;
  }
`;

const InformationMessage = ({ status, text }) => (
  <Wrapper>
    <IconStatus status={status} />
    {text}
  </Wrapper>
);

export default InformationMessage;
