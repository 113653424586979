import i18next from 'i18next';
import React from 'react';

import {
  ENUM_FILTER_TYPE,
  MultiFilterProps,
  MultipleFilterType,
} from '@/components/DynamicFilters';
import BookMarkIcon from '@/components/icons/BookMarkIcon';
import { getTailTrafficOptions } from '@/containers/Competitors/components/HeaderFilters/Filters/TailTrafficFilter/const';
import { TAIL_TRAFFIC_TYPE_FILTER_QUERY_PARAM } from '@/containers/Competitors/utils/constants';

export function getTailTrafficFilter({
  updateFilter,
  filter,
}: MultiFilterProps): MultipleFilterType {
  return {
    icon: <BookMarkIcon />,
    key: TAIL_TRAFFIC_TYPE_FILTER_QUERY_PARAM,
    name: i18next.t('competitors:filters.keyword-type'),
    onValidate: (value) => updateFilter(TAIL_TRAFFIC_TYPE_FILTER_QUERY_PARAM, value),
    options: getTailTrafficOptions(filter),
    type: ENUM_FILTER_TYPE.MULTI_SELECT,
  };
}
