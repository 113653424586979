import { useMutation } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import apiClient from '@/apis/semji/apiClient';
import { SCOPE_FOLDERS } from '@/apis/semji/constants';
import { showErrorSnackbar, showSuccessSnackbar } from '@/store/actions/ui';

export default function usePutFolder({ onError, onSuccess, ...props }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const workspaceId = useSelector((state) => state.defaultWorkspaceId || '');
  const { put } = apiClient;

  return useMutation({
    mutationFn: ({ id, name, parentFolderId = null }) => {
      return put(`folders/${id}`, {
        name,
        parentFolderId,
      });
    },

    onError: () => {
      dispatch(showErrorSnackbar(t('common:error.default')));
      onError?.();
    },
    onSuccess: (data) => {
      queryClient.refetchQueries({
        active: true,
        exact: true,
        fetching: false,
        queryKey: [SCOPE_FOLDERS.GET_ALL_BY_WORKSPACE_ID, workspaceId],
      });
      dispatch(
        showSuccessSnackbar(
          t('components:filters.folder-filters-panel.put-success', { name: data?.name || '' })
        )
      );
      onSuccess?.(data);
    },
    ...props,
  });
}
