import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import SemjiCreditIcon from '@/assets/images/semji-credit-icon.svg';
import SemjiLogo from '@/assets/images/semji-logo.svg';
import { FlexContainer } from '@/components/_common';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import PrimaryButton from '@/design-system/components/Button/PrimaryButton';

const MargedImg = styled.img`
  margin-right: 10px;
`;

function SemjiButton({
  costTitleTooltip,
  titleButton,
  onClick,
  disabled,
  withoutTooltip,
  withoutIcon,
  ...props
}) {
  const { t } = useTranslation();

  if (withoutTooltip) {
    return (
      <FlexContainer {...props}>
        <PrimaryButton disabled={disabled} onClick={onClick}>
          {!withoutIcon && (
            <MargedImg alt={t('components:button.semji-button.alt')} src={SemjiLogo} />
          )}
          {titleButton}
        </PrimaryButton>
      </FlexContainer>
    );
  }
  return (
    <FlexContainer {...props}>
      <TooltipComponent
        icon={<img alt={t('components:button.semji-button.tooltip-alt')} src={SemjiCreditIcon} />}
        title={`${costTitleTooltip} credit${costTitleTooltip > 1 ? 's' : ''}`}
      >
        <PrimaryButton disabled={disabled} onClick={onClick}>
          {!withoutIcon && (
            <MargedImg alt={t('components:button.semji-button.alt')} src={SemjiLogo} />
          )}
          {titleButton}
        </PrimaryButton>
      </TooltipComponent>
    </FlexContainer>
  );
}

SemjiButton.propTypes = {
  costTitleTooltip: PropTypes.number,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  titleButton: PropTypes.string.isRequired,
  withoutTooltip: PropTypes.bool,
};

export default SemjiButton;
