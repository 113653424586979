import { useEffect } from 'react';

const RESPONSIVE_SETTINGS = {
  initialScale: '1.0',
  shrinkToFit: false,
};

const FORCE_DESKTOP_SETTINGS = {
  initialScale: '0.5',
  maximumScale: '2.0',
  minimumScale: '0.1',
  userScalable: true,
};

function updateViewportMeta({
  initialScale,
  maximumScale,
  minimumScale,
  shrinkToFit,
  userScalable,
}) {
  let viewport = document.querySelector('meta[name=viewport]');

  if (!viewport) {
    // in case there is no view port meta tag creates one and add it to the head
    viewport = document.createElement('meta');
    viewport.name = 'viewport';
    document.getElementsByTagName('head')[0].appendChild(viewport);
  }

  const userScalableAttr =
    typeof userScalable === 'boolean' ? `user-scalable=${userScalable ? 'yes' : 'no'}` : '';
  const shrinkToFitAttr =
    typeof shrinkToFit === 'boolean' ? `shrink-to-fit=${shrinkToFit ? 'yes' : 'no'}` : '';

  const content = `width=device-width,${!!initialScale ? `initial-scale=${initialScale},` : ''}${
    !!maximumScale ? `maximum-scale=${maximumScale},` : ''
  }${!!minimumScale ? `minimum-scale=${minimumScale},` : ''}${userScalableAttr}${shrinkToFitAttr}`;

  viewport.setAttribute('content', content);
}

// Use viewport scale instead of responsive layout to display content.
function useForceDesktopViewport(isEnabled) {
  useEffect(() => {
    if (isEnabled) {
      updateViewportMeta(FORCE_DESKTOP_SETTINGS);
    } else {
      updateViewportMeta(RESPONSIVE_SETTINGS);
    }
  }, [isEnabled]);
}

export default useForceDesktopViewport;
