import styled from 'styled-components/macro';

import ReloadIcon from './ReloadIcon';

const AnimatedReloadIcon = styled(ReloadIcon)`
  padding: 7px 0;
  @keyframes rotation {
    from {
      transform: rotate(359deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
  stroke: currentColor;
  stroke-width: 0.02em;
  transform-origin: center center;
  animation: ${({ $isLoading }) => ($isLoading ? 'rotation 2s infinite ease' : 'none')};
  cursor: ${({ $isClickable }) => ($isClickable ? 'pointer' : 'initial')};
`;

export default AnimatedReloadIcon;
