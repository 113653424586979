import React, { useId } from 'react';

function FactCheckingCreditIcon(props) {
  const id = useId();
  return (
    <svg fill="none" height="16" viewBox="0 0 15 16" width="15" {...props}>
      <path
        d="M15 8C15 12.1421 11.6421 15.5 7.5 15.5C3.35786 15.5 0 12.1421 0 8C0 3.85786 3.35786 0.5 7.5 0.5C11.6421 0.5 15 3.85786 15 8Z"
        fill={`url(#linear0_${id})`}
      />
      <circle cx="7.5" cy="8" r="7.19444" stroke={`url(#linear1_${id})`} strokeWidth="0.611111" />
      <g clipPath={`url(#clip_${id})`}>
        <mask
          height="10"
          id={`mask_${id}`}
          maskUnits="userSpaceOnUse"
          style={{ maskType: 'alpha' }}
          width="10"
          x="3"
          y="4"
        >
          <path
            clipRule="evenodd"
            d="M7.49506 11.9697C7.85355 11.8241 8.57 11.4994 9.26971 10.9845C10.2463 10.2657 11.0031 9.32173 11.0031 8.12534V6.45929C10.5914 6.69801 10.1135 6.83475 9.604 6.83475C8.7692 6.83475 8.01903 6.46761 7.50638 5.88621C6.99373 6.46761 6.24356 6.83475 5.40876 6.83475C4.8993 6.83475 4.42136 6.69801 4.00969 6.45929V8.14212C4.00969 9.29306 4.75419 10.2327 5.73012 10.9629C6.42681 11.4842 7.14004 11.8193 7.49506 11.9697ZM3.01147 5.47729V8.14212C3.01147 11.3815 7.20792 12.9367 7.38712 13.0008L7.4866 13.0374L7.58669 13.0026C7.76649 12.9409 12.0013 11.4522 12.0013 8.12534V5.47729V5.03679V4.03857H11.402C11.402 4.46533 11.2525 4.85787 11.0031 5.16656C10.6732 5.57491 10.1685 5.83654 9.604 5.83654C8.9442 5.83654 8.36617 5.47914 8.05338 4.9478C7.89624 4.68087 7.80604 4.37004 7.80604 4.03857H7.20672C7.20672 4.37004 7.11652 4.68087 6.95938 4.9478C6.64659 5.47914 6.06857 5.83654 5.40876 5.83654C4.84423 5.83654 4.33958 5.57491 4.00969 5.16656C3.7603 4.85787 3.6108 4.46533 3.6108 4.03857H3.01147V5.03679V5.47729Z"
            fill="black"
            fillRule="evenodd"
          />
          <path
            d="M5.87915 8.99714L6.97413 9.83805L9.00449 7.48193"
            stroke="black"
            strokeWidth="1.12373"
          />
        </mask>
        <g mask={`url(#mask_${id})`}>
          <rect fill="white" height="10.2353" width="10.2353" x="3.10083" y="3.39111" />
        </g>
      </g>
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id={`linear0_${id}`}
          x1="3.02908e-05"
          x2="15.0027"
          y1="8.00423"
          y2="8.00423"
        >
          <stop stopColor="#FF4580" />
          <stop offset="1" stopColor="#FF4D64" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id={`linear1_${id}`}
          x1="13.4425"
          x2="1.98413"
          y1="3.07962"
          y2="13.0102"
        >
          <stop stopColor="#CA3B4E" />
          <stop offset="1" stopColor="#CA3B4E" stopOpacity="0" />
        </linearGradient>
        <clipPath id={`clip_${id}`}>
          <rect
            fill="white"
            height="10.1135"
            transform="translate(2.44971 3.30933)"
            width="10.1135"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default FactCheckingCreditIcon;
