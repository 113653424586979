import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import { initReactI18next } from 'react-i18next';

import { SUPPORTED_LANGUAGES } from '@/i18n/constants';

const HASH = import.meta.env.VITE_REACT_APP_COMMIT_SHA;

function loadI18n(options = {}) {
  i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(intervalPlural) // to use interval
    .init({
      // Back-end config
      backend: {
        loadPath: `/locales/{{lng}}/{{ns}}.json`,
        queryStringParams: { h: HASH },
      },

      defaultNS: 'common',

      // Eagerly loaded languages
      // preload: ["en", "fr"],

      // lng: 'en',
      fallbackLng: 'en',

      interpolation: {
        escapeValue: false, // react already safes from xss
      },

      /*
      If you need the primary used language depending on your configuration (supportedLngs, load) you will prefer using i18next.languages?.[0].
      So we have to set load to languageOnly to get the language with i18next.languages?.[0] (the detected one could include the country code).
      https://github.com/i18next/i18next/issues/1502#issuecomment-680969692 */
      load: 'languageOnly',

      nonExplicitSupportedLngs: true,

      ns: [
        'add-to-planning',
        'ag-grid',
        'billing',
        'brand-voice',
        'comments',
        'common',
        'components',
        'chart',
        'content',
        'content-ideas',
        'competitors',
        'create-account',
        'create-organization',
        'create-workspace',
        'dashboard',
        'drafts',
        'empty-state',
        'errors',
        'fact',
        'grid',
        'inqueue',
        'layout',
        'listing',
        'login',
        'organization',
        'pages',
        'quick-start-guide',
        'remove-from-planning',
        'report',
        'search',
        'select',
        'service-integration',
        'settings',
        'user',
        'workspace',
      ],

      // react-i18next config
      react: {
        useSuspense: true,
      },

      // https://www.i18next.com/overview/configuration-options
      // Remove this array to allow use of debug values during creation of new languages (ex: frdebug)
      supportedLngs: SUPPORTED_LANGUAGES,

      ...options,
    });

  return i18n;
}

export default loadI18n;
