import TextField from '@material-ui/core/TextField';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import { LOCATION, SCOPE_WORKSPACE } from '@/apis/semji/constants';
import usePostNewContent from '@/apis/semji/contents/usePostNewContent';
import CreateNewContentSvg from '@/assets/images/create-new-content.svg';
import SemjiButton from '@/components/Button/SemjiButton';
import { StyledImg } from '@/containers/Onboarding/common';
import DescribeStepsComponents from '@/containers/Onboarding/DescribeSteps';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import { showErrorSnackbar } from '@/store/actions/ui';
import { selectRemainingAnalysisUnit } from '@/store/selectors/selectRemainingAnalysisUnit';
import { isEnterHotkey } from '@/utils/keyboard';

const Wrapper = styled.div`
  margin-top: 20px;
`;
const StyledSemjiButton = styled(SemjiButton)`
  && {
    margin: 30px 0;
    button {
      font-weight: 500;
    }
  }
`;
const HelperText = styled.p`
  color: ${({ theme }) => theme.cssColors.dark060};
  font-size: ${({ theme }) => theme.textCss.sizes.xs};
  font-weight: ${({ theme }) => theme.textCss.weights.medium};
`;

function CreateNewContent() {
  const dispatch = useDispatch();
  const i18nNameSpace = 'quick-start-guide:body.accordion.create-new-content.create-new-content';
  const { t } = useTranslation();
  const [keyword, setKeyword] = useState('');
  const { organizationId, workspaceId } = useParams();
  const { isFeatureEnabled: hasUnlimitedAnalysisCredits } = useOrganizationFeatureSet(
    'credits:analysis:has-unlimited-amount'
  );
  const remainingAnalysisUnit = useSelector(selectRemainingAnalysisUnit);
  const queryClient = useQueryClient();

  const newContent = usePostNewContent({
    onError: () => dispatch(showErrorSnackbar(t('common:error.default'))),
    onSuccess: (content) => {
      const appBaseUrl = import.meta.env.VITE_REACT_APP_SEMJI_APP_FRONTEND_URL;

      queryClient.refetchQueries({
        active: true,
        exact: true,
        fetching: false,
        queryKey: [SCOPE_WORKSPACE.GET_CURRENT_WORKSPACE, workspaceId, LOCATION.QUICK_START_GUIDE],
      });

      window
        .open(
          `${appBaseUrl}/o/${organizationId}/w/${workspaceId}/p/${content.data.page.id}/content/${content.data.id}`,
          '_blank'
        )
        .focus();
      setKeyword('');
    },
  });

  function handleChange(e) {
    setKeyword(e.target.value);
  }

  function handleConfirm(e) {
    if (isEnterHotkey(e) || e.type === 'click') {
      newContent.mutate({ keyword, startAnalysis: true });
    }
  }

  return (
    <DescribeStepsComponents
      button={
        <StyledSemjiButton
          costTitleTooltip={1}
          disabled={
            !keyword.length ||
            newContent.isLoading ||
            (!hasUnlimitedAnalysisCredits && remainingAnalysisUnit < 1)
          }
          titleButton={t(`${i18nNameSpace}.button-label`)}
          withoutIcon={hasUnlimitedAnalysisCredits}
          withoutTooltip={hasUnlimitedAnalysisCredits}
          onClick={handleConfirm}
        />
      }
      explanatoryText={<Trans i18nKey={`${i18nNameSpace}.explanatory-text`} />}
      helperRender={
        !hasUnlimitedAnalysisCredits && (
          <HelperText>
            {t(`${i18nNameSpace}.credits-left_interval`, {
              count: remainingAnalysisUnit,
              postProcess: 'interval',
            })}
          </HelperText>
        )
      }
      render={
        <Wrapper>
          <TextField
            disabled={newContent.isLoading}
            fullWidth
            placeholder={t(`${i18nNameSpace}.placeholder`)}
            value={keyword}
            onChange={handleChange}
            onKeyDown={handleConfirm}
          />
        </Wrapper>
      }
      visualRendering={
        <StyledImg alt={t(`${i18nNameSpace}.alt-image`)} src={CreateNewContentSvg} />
      }
    />
  );
}

export default CreateNewContent;
