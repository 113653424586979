import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';
import React, { useState } from 'react';

import { LogContext } from '@/providers/LogProvider/LogProvider.context';
import { LogProviderTraceAttributes } from '@/providers/LogProvider/LogProvider.types';

export default function LogProvider({
  browserAgent,
  children,
}: {
  browserAgent: BrowserAgent;
  children: any;
}) {
  const [newRelicBrowserAgent] = useState<BrowserAgent>(browserAgent);

  function report(error: Error | string, customAttributes?: object | undefined) {
    // eslint-disable-next-line no-console
    if (!newRelicBrowserAgent) console.error('No browser agent');
    newRelicBrowserAgent.noticeError(error, customAttributes);
  }

  function trace(customAttributes: LogProviderTraceAttributes) {
    // eslint-disable-next-line no-console
    if (!newRelicBrowserAgent) console.error('No browser agent');
    newRelicBrowserAgent.addToTrace({ start: Date.now(), ...customAttributes });
  }

  return <LogContext.Provider value={{ report, trace }}>{children}</LogContext.Provider>;
}
