import React, { ReactNode } from 'react';
import { FavIcon } from 'semji-core/components/FavIcon';

import { getHostnamefromUrl } from '@/containers/Competitors/utils/utils';
import { CompetitorWebsite } from '@/types/competitors';
import { DEFAULT_FAVICON } from '@/utils/constants';
import { getFaviconFromUrl } from '@/utils/favicon';

export default function CompetitorLabel({
  competitorId,
  competitorWebsites,
}: {
  competitorId: string;
  competitorWebsites: CompetitorWebsite[];
}): ReactNode {
  const apiRootUrl = import.meta.env.VITE_REACT_APP_SEMJI_API_ROOT_URL;

  const competitor = competitorWebsites?.find((competitor) => competitor.id === competitorId);

  if (competitor) {
    return (
      <span className="flex gap-1 w-full">
        <FavIcon
          defaultFavicon={DEFAULT_FAVICON}
          disableClick
          src={getFaviconFromUrl(competitor.url, apiRootUrl)}
        />
        <span>{getHostnamefromUrl(competitor.url)}</span>
      </span>
    );
  }
  return '';
}
