import { fetchCountries } from '@/apis/semji/api';
import { PRIMARY_COUNTRIES } from '@/utils/helper';

export default class Countries {
  constructor() {
    this._countries = null;
  }

  get countries() {
    return this._fetchCountries();
  }

  async _fetchCountries() {
    const listCountries = [];
    if (this._countries === null) {
      try {
        this._countries = await fetchCountries();
      } catch (e) {
        throw new Error(e);
      }
    }

    this._countries['hydra:member'].forEach((country) => {
      country.officialLanguages.forEach((officialLanguage) => {
        listCountries.push({
          country: country.name,
          countryCode: country.iso3166_2 ?? country.alpha2Code,
          isObvious: country.officialLanguages.length === 1,
          languageCode: officialLanguage.code,
          primary: PRIMARY_COUNTRIES.includes(country.alpha2Code) ? 1 : 0,
        });
      });
    });

    return listCountries
      .sort((a, b) => a.country.localeCompare(b.country))
      .sort((a, b) => b.primary - a.primary);
  }
}
