import { Fragment, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import { FlexCenteredAlignContainer, FlexJustifyStartContainer } from '@/components/_common';
import { PlanRestrictionBadge } from '@/components/Badge/PlanRestrictionBadge';
import { PrimaryButton } from '@/components/Button/Button';
import { ListingStyledDivider } from '@/components/Layout/Header';
import Loader from '@/components/Loader/Loader';
import { SecondaryLink } from '@/components/Navigation/Link';
import { TabBodyContent, TabBodyTitle } from '@/components/Navigation/Tabs';
import SecuritySettings from '@/components/Settings/SecuritySettings';
import { Info } from '@/components/Text/Info';
import { ExtraSmallGreyText } from '@/components/Text/Light';
import { SecondaryTitle } from '@/components/Text/Title';
import { OrganizationFeatureSet } from '@/hoc/OrganizationFeatureSet';
import OrganizationService from '@/services/Organization';
import { selectCurrentOrganization } from '@/store/selectors/selectCurrentOrganization';
import { SUPPORT_ADDRESS_MAIL } from '@/utils/constants';
import { SECTIONS } from '@/utils/log/constants';
import { Log } from '@/utils/log/Log';

const Body = styled.div`
  padding: 0 1em;
`;
const StyledInfo = styled(Info)`
  && {
    margin: auto 0.7em;
  }
`;
const StyledSecondaryLink = styled(SecondaryLink)`
  && {
    font-size: 1em;
  }
`;
const StyledPlanRestrictionBadge = styled(PlanRestrictionBadge)`
  && {
    margin-left: 20px;
  }
`;

const AUTHORIZED_VALUES_FOR_POST = ['ON', 'OPTIONAL'];
const AUTHORIZED_VALUES_FOR_PUT = ['OFF'];

function SecurityContainer(props) {
  const { t } = useTranslation();
  const { organizationId } = useParams();
  const [samlConfig, setSamlConfig] = useState({});
  const [domains, setDomains] = useState([]);
  const [loading, setLoading] = useState(true);
  const [saved, setSaved] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [inputsOnError, setInputsOnError] = useState({});
  const _OrganizationService = new OrganizationService(organizationId);
  const currentOrganization = useSelector(selectCurrentOrganization);

  useEffect(() => {
    async function getConfig() {
      try {
        const promiseResult = await Promise.all([
          _OrganizationService.samlConfiguration,
          _OrganizationService.samlDomains,
        ]);
        const samlConfiguration = promiseResult[0];
        const samlDomains = promiseResult[1];
        const {
          id,
          mandatory,
          identityProviderEntityId,
          identityProviderUrl,
          certificate,
          disabled,
          signSpRequests,
        } = samlConfiguration[0] || { disabled: true };
        const samlOptionValue = disabled ? 'OFF' : mandatory ? 'ON' : 'OPTIONAL';

        setSamlConfig({
          certificate,
          id,
          identityProviderEntityId,
          identityProviderUrl,
          samlOption: samlOptionValue,
          signSpRequests,
        });
        setDomains(samlDomains.map((d) => d.domainName));
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setError(true);
        Log.report({
          context: 'getConfig',
          error: e,
          extra: 'Security Saml config',
          section: SECTIONS.settings.key,
        });
      }
    }

    getConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInputsState = () => {
    return (
      !!samlConfig.identityProviderEntityId &&
      !!samlConfig.identityProviderUrl &&
      !!samlConfig.certificate
    );
  };

  const saveChanges = async () => {
    try {
      setError(false);
      setSaving(true);
      setInputsOnError({});

      if (!samlConfig.id && AUTHORIZED_VALUES_FOR_POST.includes(samlConfig.samlOption)) {
        if (getInputsState()) {
          const samlConfiguration = await _OrganizationService.postSamlConfiguration({
            certificate: samlConfig.certificate,
            disabled: false,
            identityProviderEntityId: samlConfig.identityProviderEntityId,
            identityProviderUrl: samlConfig.identityProviderUrl,
            mandatory: samlConfig.samlOption === 'ON' ? true : false,
            signSpRequests: samlConfig.signSpRequests,
          });
          const { id } = samlConfiguration;

          setSamlConfig({
            ...samlConfig.samlOption,
            id,
          });
        } else {
          setInputsOnError({
            certificate: !samlConfig.certificate,
            identityProviderEntityId: !samlConfig.identityProviderEntityId,
            identityProviderUrl: !samlConfig.identityProviderUrl,
            signSpRequests: !samlConfig.signSpRequests,
          });
        }
      }

      if (!samlConfig.id && AUTHORIZED_VALUES_FOR_PUT.includes(samlConfig.samlOption)) {
        const samlConfiguration = await _OrganizationService.postSamlConfiguration({
          certificate: samlConfig.certificate || '',
          disabled: true,
          identityProviderEntityId: samlConfig.identityProviderEntityId || '',
          identityProviderUrl: samlConfig.identityProviderUrl || '',
          mandatory: false,
          signSpRequests: false,
        });

        const { id } = samlConfiguration;
        setSamlConfig({
          ...samlConfig.samlOption,
          id,
        });
      }

      if (samlConfig.id && AUTHORIZED_VALUES_FOR_POST.includes(samlConfig.samlOption)) {
        if (getInputsState()) {
          await _OrganizationService.putSamlConfiguration({
            certificate: samlConfig.certificate,
            disabled: false,
            identityProviderEntityId: samlConfig.identityProviderEntityId,
            identityProviderUrl: samlConfig.identityProviderUrl,
            mandatory: samlConfig.samlOption === 'ON' ? true : false,
            samlConfigurationId: samlConfig.id,
            signSpRequests: samlConfig.signSpRequests,
          });
        } else {
          setInputsOnError({
            certificate: !samlConfig.certificate,
            identityProviderEntityId: !samlConfig.identityProviderEntityId,
            identityProviderUrl: !samlConfig.identityProviderUrl,
            signSpRequests: !samlConfig.signSpRequests,
          });
        }
      }

      if (samlConfig.id && AUTHORIZED_VALUES_FOR_PUT.includes(samlConfig.samlOption)) {
        await _OrganizationService.putSamlConfiguration({
          disabled: true,
          samlConfigurationId: samlConfig.id,
        });
      }
    } catch (e) {
      setSaved(false);
      setError(true);
      Log.report({
        context: 'saveChanges',
        error: e,
        extra: 'Security Saml config save',
        section: SECTIONS.settings.key,
      });
    } finally {
      setSaving(false);
      if (Object.values(inputsOnError).every((inputState) => !inputState)) {
        setSaved(true);
      }
    }
  };

  const isSaveDisabled = () => {
    return saving || saved || domains.length === 0;
  };

  if (loading) {
    return (
      <TabBodyContent>
        <Loader />
      </TabBodyContent>
    );
  }

  return (
    <TabBodyContent className="max-h-full overflow-y-auto">
      <TabBodyTitle noMargin>{props.title}</TabBodyTitle>
      <br />
      <OrganizationFeatureSet
        feature="organization-setting:has-access-to-security"
        no={() => (
          <FlexCenteredAlignContainer>
            <SecondaryTitle noMargin>
              {t('settings:workspace.security-settings.title')}
            </SecondaryTitle>
            <StyledPlanRestrictionBadge />
          </FlexCenteredAlignContainer>
        )}
        yes={() => (
          <SecondaryTitle noMargin>
            {t('settings:workspace.security-settings.title')}
          </SecondaryTitle>
        )}
      />
      <ListingStyledDivider />
      <Body>
        <div>
          {domains.length === 0 ? (
            <Trans
              components={{
                br: <br />,
                email: <StyledSecondaryLink isExternal to={`mailto:${SUPPORT_ADDRESS_MAIL}`} />,
                small: <ExtraSmallGreyText />,
              }}
              i18nKey={'settings:workspace.security-settings.text-no-domain'}
            />
          ) : (
            <Trans
              components={{
                br: <br />,
                email: <StyledSecondaryLink isExternal to={`mailto:${SUPPORT_ADDRESS_MAIL}`} />,
                list: (
                  <>
                    {domains.reduce(
                      (acc, name, index) => [
                        ...acc,
                        ...(index > 0
                          ? [
                              <Fragment key={`or-${index}`}>
                                {t('settings:workspace.security-settings.label-or')}
                              </Fragment>,
                            ]
                          : []),
                        <strong key={name}>{name}</strong>,
                      ],
                      []
                    )}
                  </>
                ),
                small: <ExtraSmallGreyText />,
                strong: <strong />,
              }}
              i18nKey={'settings:workspace.security-settings.text-with-domains'}
            />
          )}
        </div>
        <OrganizationFeatureSet
          feature="organization-setting:has-access-to-security"
          no={() => (
            <SecuritySettings
              certificate={samlConfig.certificate}
              configDisabled
              disabled
              identityProviderEntityId={samlConfig.identityProviderEntityId}
              identityProviderUrl={samlConfig.identityProviderUrl}
              inputsOnError={inputsOnError}
              samlOption={samlConfig.samlOption}
              samlServiceProviderAcsUrl={currentOrganization.samlServiceProviderAcsUrl}
              samlServiceProviderEntityId={currentOrganization.samlServiceProviderEntityId}
              samlServiceProviderX509cert={currentOrganization.samlServiceProviderX509cert}
              signSpRequests={samlConfig.signSpRequests}
              onCertificateChange={(val) => {
                setSaved(false);
                setInputsOnError({
                  ...inputsOnError,
                  certificate: false,
                });
                setSamlConfig({ ...samlConfig, certificate: val });
              }}
              onIdentityProviderEntityIdChange={(val) => {
                setSaved(false);
                setInputsOnError({
                  ...inputsOnError,
                  identityProviderEntityId: false,
                });
                setSamlConfig({ ...samlConfig, identityProviderEntityId: val });
              }}
              onIdentityProviderUrlChange={(val) => {
                setSaved(false);
                setInputsOnError({
                  ...inputsOnError,
                  identityProviderUrl: false,
                });
                setSamlConfig({ ...samlConfig, identityProviderUrl: val });
              }}
              onSamlOptionChange={(val) => {
                setSaved(false);
                setSamlConfig({ ...samlConfig, samlOption: val });
              }}
              onSignSpRequestsChange={(val) => {
                setSaved(false);
                setInputsOnError({
                  ...inputsOnError,
                  signSpRequests: false,
                });
                setSamlConfig({ ...samlConfig, signSpRequests: val });
              }}
            />
          )}
          yes={() => (
            <SecuritySettings
              certificate={samlConfig.certificate}
              configDisabled={domains.length === 0}
              disabled={
                domains.length === 0 || AUTHORIZED_VALUES_FOR_PUT.includes(samlConfig.samlOption)
              }
              identityProviderEntityId={samlConfig.identityProviderEntityId}
              identityProviderUrl={samlConfig.identityProviderUrl}
              inputsOnError={inputsOnError}
              samlOption={samlConfig.samlOption}
              samlServiceProviderAcsUrl={currentOrganization.samlServiceProviderAcsUrl}
              samlServiceProviderEntityId={currentOrganization.samlServiceProviderEntityId}
              samlServiceProviderX509cert={currentOrganization.samlServiceProviderX509cert}
              signSpRequests={samlConfig.signSpRequests}
              onCertificateChange={(val) => {
                setSaved(false);
                setInputsOnError({
                  ...inputsOnError,
                  certificate: false,
                });
                setSamlConfig({ ...samlConfig, certificate: val });
              }}
              onIdentityProviderEntityIdChange={(val) => {
                setSaved(false);
                setInputsOnError({
                  ...inputsOnError,
                  identityProviderEntityId: false,
                });
                setSamlConfig({ ...samlConfig, identityProviderEntityId: val });
              }}
              onIdentityProviderUrlChange={(val) => {
                setSaved(false);
                setInputsOnError({
                  ...inputsOnError,
                  identityProviderUrl: false,
                });
                setSamlConfig({ ...samlConfig, identityProviderUrl: val });
              }}
              onSamlOptionChange={(val) => {
                setSaved(false);
                setSamlConfig({ ...samlConfig, samlOption: val });
              }}
              onSignSpRequestsChange={(val) => {
                setSaved(false);
                setInputsOnError({
                  ...inputsOnError,
                  signSpRequests: false,
                });
                setSamlConfig({ ...samlConfig, signSpRequests: val });
              }}
            />
          )}
        />
        <FlexJustifyStartContainer>
          <PrimaryButton disabled={isSaveDisabled()} onClick={saveChanges}>
            {saved
              ? t('settings:workspace.security-settings.button-saved')
              : saving
                ? t('settings:workspace.security-settings.button-saving')
                : t('settings:workspace.security-settings.button-save')}
          </PrimaryButton>
          {error && (
            <StyledInfo>{t('settings:workspace.security-settings.error-saving')}</StyledInfo>
          )}
        </FlexJustifyStartContainer>
      </Body>
    </TabBodyContent>
  );
}

export default SecurityContainer;
