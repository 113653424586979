import { useQuery } from '@tanstack/react-query';
import { sortFoldersByName } from 'semji-core/utils/sort';

import apiClient from '@/apis/semji/apiClient';
import { SCOPE_FOLDERS } from '@/apis/semji/constants';

export default function useGetAllFoldersByWorkspaceId(workspaceId = '') {
  const { get } = apiClient;

  const { data: folders = [], ...rest } = useQuery({
    placeholderData: [],
    queryFn: async ({ signal }) => {
      const { data } = await get(`/workspaces/${workspaceId}/folders`, {
        signal,
      });

      return data?.['hydra:member'] || [];
    },
    queryKey: [SCOPE_FOLDERS.GET_ALL_BY_WORKSPACE_ID, workspaceId],
  });

  //sort Parents folders only
  folders.sort(sortFoldersByName);
  //sort children folders
  folders.forEach((folder) => {
    folder?.childrens?.sort(sortFoldersByName);
  });

  return { data: folders, ...rest };
}
