import React from 'react';
import { FloatLoader } from 'semji-core/components/FloatLoader';

export default function VersionLoader() {
  return (
    <div className="version-history-panel-loading-state__group__item">
      <div className="version-history-panel-loading-state__group__item__spacer" />
      <div className="version-history-panel-loading-state__group__item__dot">
        <FloatLoader />
      </div>
      <div className="version-history-panel-loading-state__group__item__text">
        <div className="version-history-panel-loading-state__group__item__date">
          <FloatLoader />
        </div>
        <div className="version-history-panel-loading-state__group__item__author">
          <FloatLoader />
        </div>
      </div>
    </div>
  );
}
