import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import TooltipComponent from '@/components/Tooltip/Tooltip';
import { FOCUS_TOP_KEYWORD_POSITION, TOP_100_TAB } from '@/utils/metrics/constants';

const Tabs = styled.ul`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  gap: 16px;
  z-index: 1;
  font-weight: ${({ theme }) => theme.textCss.weights.strong};
  font-size: ${({ theme }) => theme.textCss.sizes.xs};
  line-height: 135%;
  padding: 15px 0;
`;

const Item = styled.li`
  cursor: pointer;
  color: ${({ theme, active }) =>
    active ? theme.cssColors.secondaryBlue : theme.cssColors.dark040};
`;

const MetricPositionTabs = ({ activeTab, setActiveSubTab }) => {
  const { t } = useTranslation();
  return (
    <Tabs>
      <TooltipComponent
        placement="top"
        title={t(
          'content:side-panel-components.performance.section.main-metrics-evolution.focus-keyword-tooltip'
        )}
      >
        <Item
          active={activeTab === FOCUS_TOP_KEYWORD_POSITION}
          onClick={() => setActiveSubTab(FOCUS_TOP_KEYWORD_POSITION)}
        >
          {t(
            'content:side-panel-components.performance.section.main-metrics-evolution.focus-keyword'
          )}
        </Item>
      </TooltipComponent>
      <TooltipComponent
        placement="top"
        title={t(
          'content:side-panel-components.performance.section.main-metrics-evolution.top100-tooltip'
        )}
      >
        <Item active={activeTab === TOP_100_TAB} onClick={() => setActiveSubTab(TOP_100_TAB)}>
          {t('content:side-panel-components.performance.section.main-metrics-evolution.top100')}
        </Item>
      </TooltipComponent>
    </Tabs>
  );
};

export default MetricPositionTabs;
