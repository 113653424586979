import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';

const StrengthMeterWrapper = styled.div`
  && {
    position: relative;
    height: 3px;
    background: #ddd;
    margin: 7px 0;
    border-radius: 2px;
    visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};

    &:before,
    &:after {
      content: '';
      height: inherit;
      background: transparent;
      display: block;
      border-color: #fff;
      border-style: solid;
      border-width: 0 6px 0;
      position: absolute;
      width: calc(20% + 6px);
      z-index: 10;
      box-sizing: border-box;
    }

    &:before {
      left: calc(20% - 3px);
    }

    &:after {
      right: calc(20% - 3px);
    }
  }
`;

const StrengthMeterFill = styled.div`
  && {
    background: transparent;
    height: inherit;
    position: absolute;
    width: 0;
    border-radius: inherit;
    transition:
      width 0.5s ease-in-out,
      background 0.25s;
    &[data-strength='0'] {
      width: 20%;
      background: ${(props) => props.theme.cssColors.red};
    }

    &[data-strength='1'] {
      width: 40%;
      background: ${(props) => props.theme.cssColors.salmonOrange};
    }

    &[data-strength='2'] {
      width: 60%;
      background: ${(props) => props.theme.cssColors.yellow};
    }

    &[data-strength='3'] {
      width: 80%;
      background: ${(props) => props.theme.cssColors.yellowGreen};
    }

    &[data-strength='4'] {
      width: 100%;
      background: ${(props) => props.theme.cssColors.green};
    }
  }
`;

function StrengthMeter(props) {
  return (
    <StrengthMeterWrapper isVisible={props.isVisible}>
      <StrengthMeterFill data-strength={props.strength} />
    </StrengthMeterWrapper>
  );
}

StrengthMeter.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  strength: PropTypes.number.isRequired,
};

export default StrengthMeter;
