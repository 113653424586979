import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { Cell } from '@/components/Pages/ContentIdeas/common';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import defaultTheme from '@/themes/defaultTheme';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
`;
const ColoredDot = styled.div`
  background-color: ${({ color }) => color};
  height: 8px;
  width: 8px;
  min-width: 8px;
  margin: 8px;
  border-radius: 50%;
`;
const StyledToolTip = styled(TooltipComponent)`
  && {
    min-width: 0;
  }
`;

const KEYWORD_DIFFICULTY_REFERENCE = [
  {
    color: defaultTheme.cssColors.lightGreen,
    label: 'content-ideas:listing.table.keyword-difficulty.very-low',
    maxValue: 14,
  },
  {
    color: defaultTheme.cssColors.yellowGreen,
    label: 'content-ideas:listing.table.keyword-difficulty.low',
    maxValue: 29,
  },
  {
    color: defaultTheme.cssColors.yellow,
    label: 'content-ideas:listing.table.keyword-difficulty.medium',
    maxValue: 49,
  },
  {
    color: defaultTheme.cssColors.salmonOrange,
    label: 'content-ideas:listing.table.keyword-difficulty.high',
    maxValue: 69,
  },
  {
    color: defaultTheme.cssColors.red,
    label: 'content-ideas:listing.table.keyword-difficulty.very-high',
    maxValue: 100,
  },
];

export function findKeywordDifficultyToolkit(value) {
  return getKeywordDifficultyToolkits().find(
    (keywordDifficulty) => value <= keywordDifficulty.maxValue
  );
}

export function getKeywordDifficultyToolkits() {
  return KEYWORD_DIFFICULTY_REFERENCE.sort((a, b) => a.maxValue - b.maxValue);
}

function KeywordDifficultyRender({ keywordDifficulty }) {
  const { t } = useTranslation();

  function onClick(e) {
    e.stopPropagation();
  }

  if (keywordDifficulty === null) {
    return (
      <StyledToolTip
        cursor="help"
        description={`n/a`}
        title={t(`content-ideas:listing.table.header.keyword-difficulty.label`)}
      >
        <Wrapper onClick={onClick}>-</Wrapper>
      </StyledToolTip>
    );
  }

  const { color, label } = findKeywordDifficultyToolkit(keywordDifficulty);

  return (
    <StyledToolTip
      cursor="help"
      description={`${keywordDifficulty} / 100`}
      title={t(`content-ideas:listing.table.header.keyword-difficulty.label`)}
    >
      <Wrapper onClick={onClick}>
        <ColoredDot color={color} />
        <Cell color="dark060" noPadding size="sm" weight="medium">
          {t(label)}
        </Cell>
      </Wrapper>
    </StyledToolTip>
  );
}

export default KeywordDifficultyRender;
