import React from 'react';
import styled from 'styled-components/macro';

import { TertiaryLink } from '../Navigation/Link';

const CardFooter = styled.div`
  font-size: 1em;
  margin: 0;
  display: flex;
  justify-content: flex-end;
  padding: 0px 30px;
`;

export const CardHeader = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0;
  font-family: Rubik;
  padding: 20px 30px 20px 30px;
  color: ${(props) => props.theme.cssColors.dark100};
`;

export const CardSeparator = styled.div`
  height: 30px;
  min-height: 30px;

  ${(props) => props.theme.mediaQueries.phone} {
    height: 20px;
    background: white;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${(props) => (props.size === 'big' ? '1.2em' : '1em')};
  min-height: ${(props) => props.minHeight};
  position: relative;
`;

const Card = (props) => (
  <div
    className={props.className}
    style={{ flex: 1, height: props.height, padding: props?.padding }}
  >
    <CardHeader>{props.title}</CardHeader>
    <Wrapper minHeight={props.contentMinHeight} size={props.contentSize}>
      {props.children}
    </Wrapper>
    {!!props.displayFooter && (
      <CardFooter>
        {<TertiaryLink to={props.footerLink}>{props.footerLabel}</TertiaryLink>}
      </CardFooter>
    )}
  </div>
);

export default Card;
