import { nanoid } from 'nanoid';
import React, { useMemo } from 'react';

import SvgWrapper from './SvgWrapper';

const SidePanelCommentaryIcon = (props) => {
  const maskId = useMemo(() => nanoid(), []);
  const clipId = useMemo(() => nanoid(), []);
  return (
    <SvgWrapper {...props} fill="none" height="18" viewBox="0 0 18 18" width="18">
      <g clipPath={`url(#clip${clipId})`}>
        <mask
          height="17"
          id={`mask${maskId}`}
          maskUnits="userSpaceOnUse"
          style={{ maskType: 'alpha' }}
          width="18"
          x="0"
          y="0"
        >
          <path
            d="M16.4118 0.5H1.58824C0.712588 0.5 0 1.13341 0 1.91176V11.3235C0 12.1019 0.712588 12.7353 1.58824 12.7353H2.11765V16.596L7.08141 12.7353H16.4118C17.2874 12.7353 18 12.1019 18 11.3235V1.91176C18 1.13341 17.2874 0.5 16.4118 0.5ZM16.9412 11.3235C16.9412 11.5824 16.704 11.7941 16.4118 11.7941H6.68329L3.17647 14.5216V11.7941H1.58824C1.296 11.7941 1.05882 11.5824 1.05882 11.3235V1.91176C1.05882 1.65294 1.296 1.44118 1.58824 1.44118H16.4118C16.704 1.44118 16.9412 1.65294 16.9412 1.91176V11.3235ZM3.17647 3.32353H14.8235V4.26471H3.17647V3.32353ZM3.17647 5.20588H14.8235V6.14706H3.17647V5.20588ZM3.17647 7.08823H9.52941V8.02941H3.17647V7.08823Z"
            fill="black"
          />
        </mask>
        <g mask={`url(#mask${maskId})`}>
          <rect fill="currentColor" height="18.8235" width="21.1765" x="-2.11719" y="-0.441162" />
        </g>
      </g>
      <defs>
        <clipPath id={`clip${clipId}`}>
          <rect fill="white" height="16" transform="translate(0 0.5)" width="18" />
        </clipPath>
      </defs>
    </SvgWrapper>
  );
};

export default SidePanelCommentaryIcon;
