import styled from 'styled-components/macro';

import Alert1Icon from '@/components/icons/Alert1Icon';
import DeleteIcon from '@/components/icons/DeleteIcon';
import PencilIcon from '@/components/icons/PencilIcon';
import Flex from '@/design-system/components/Flex';

export const CommentName = styled.span`
  width: auto;
  white-space: nowrap;
  font-size: 13px;
  line-height: ${({ theme }) => theme.textCss.lineHeight};
  font-weight: ${({ theme }) => theme.textCss.weights.strong};
`;

export const CommentDate = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${({ theme }) => theme.textCss.sizes.xs};
  line-height: ${({ theme }) => theme.textCss.lineHeight};
  color: ${({ theme }) => theme.cssColors.dark040};
`;

export const CommentText = styled.span`
  font-size: 13px;
  line-height: ${({ theme }) => theme.textCss.lineHeight};
  opacity: ${({ isThreadResolved }) => (isThreadResolved ? 0.5 : 1)};
`;

export const CommentMoreDropdownWrapper = styled(Flex)`
  box-shadow: ${({ theme }) => theme.boxShadow.modal};
`;

export const CommentMoreDropdownItem = styled(Flex)`
  &:hover {
    background-color: ${({ theme }) => theme.cssColors.primary005};
  }
`;

export const CommentMoreDropdownText = styled.span`
  line-height: ${({ theme }) => theme.textCss.lineHeight};
  white-space: nowrap;
  font-size: ${({ theme }) => theme.textCss.sizes.sm};
`;

export const StyledDeleteIcon = styled(DeleteIcon)`
  font-size: 17px;
  color: ${({ theme }) => theme.cssColors.dark060};
`;

export const StyledEditIcon = styled(PencilIcon)`
  font-size: 17px;
  color: ${({ theme }) => theme.cssColors.dark060};
`;

export const StyledAlertIcon = styled(Alert1Icon)`
  position: absolute;
  top: 1px;
  left: 15px;
  z-index: 5;
  width: 10px;
  height: 10px;
`;
