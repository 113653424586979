import { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';

const AdminMenu = lazy(
  () =>
    import(
      /* webpackChunkName: "AdministratorMenu" */ '@/containers/Layout/AdministratorMenu/AdministratorMenu'
    )
);

function AdministratorMenu(props) {
  const isUserSuperAdmin = useSelector((state) => state.user.superAdmin);

  return isUserSuperAdmin ? (
    <Suspense fallback={<></>}>
      <AdminMenu {...props} isUserSuperAdmin={isUserSuperAdmin} />
    </Suspense>
  ) : null;
}

export default AdministratorMenu;
