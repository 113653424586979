import styled from 'styled-components/macro';

import Input from '@/components/Input/Input';
import WarningMessage from '@/components/Message/WarningMessage';
import { DefaultLink } from '@/components/Navigation/Link';
import Flex from '@/design-system/components/Flex';

const Wrapper = styled(Flex)`
  width: 100%;
  max-width: 700px;
  padding: 32px 40px 48px 40px;
  box-shadow: ${({ theme }) => theme.boxShadow.modal};
`;
const Title = styled.span`
  color: ${({ theme }) => theme.cssColors.dark060};
  line-height: ${({ theme }) => theme.textCss.sizes.md};
  margin: 20px 0 ${({ warningMessage }) => (warningMessage ? '20px' : '32px')} 0;
`;
const SubTitle = styled.span`
  color: ${({ theme }) => theme.cssColors.dark060};
  font-size: ${({ theme }) => theme.textCss.sizes.sm};
  font-weight: ${({ theme }) => theme.textCss.weights.strong};
  line-height: ${({ theme }) => theme.textCss.sizes.lg};
`;
const Message = styled.span`
  color: ${({ theme }) => theme.cssColors.dark080};
  font-size: ${({ theme }) => theme.textCss.sizes.sm};
  line-height: ${({ theme }) => theme.textCss.sizes.lg};
`;
const Link = styled(DefaultLink)`
  && {
    font-weight: ${({ theme }) => theme.textCss.weights.medium};
    line-height: ${({ theme }) => theme.textCss.sizes.md};
    color: ${({ theme }) => theme.cssColors.secondaryBlue};
    margin-top: 20px;

    :hover {
      color: ${({ theme }) => theme.cssColors.secondaryBlue};
    }
  }
`;

function ApiInformations({
  logo,
  title,
  access,
  setAccess,
  apiUrlTitle = null,
  apiUrlPlaceholder = null,
  apiUrl = null,
  setApiUrl = () => null,
  accessTitle,
  accessPlaceholder,
  keyValue,
  setKey,
  keyTitle,
  keyPlaceholder,
  warningMessage = null,
  linkHref = null,
  linkText = null,
  handleConfirm = () => null,
}) {
  const isApiUrlDisplayed =
    apiUrl !== null && setApiUrl !== null && apiUrlTitle !== null && apiUrlPlaceholder !== null;

  function handleChangeAccess(e) {
    setAccess(e.target.value);
  }

  function handleChangeKey(e) {
    setKey(e.target.value);
  }

  function handleChangeApiUrl(e) {
    setApiUrl(e.target.value);
  }

  function handleOnKeyDown(e) {
    if (e.key === 'Enter') {
      handleConfirm();
    }
  }

  return (
    <Wrapper flexDirection="column">
      {logo}
      <Title warningMessage={warningMessage}>{title}</Title>
      <Flex flexDirection="column" gap={warningMessage ? '32px' : '0'}>
        {warningMessage && (
          <WarningMessage>
            <Message>{warningMessage}</Message>
          </WarningMessage>
        )}
        <Flex flexDirection="column" gap="24px">
          {isApiUrlDisplayed && (
            <Flex flexDirection="column">
              <SubTitle>{apiUrlTitle}</SubTitle>
              <Input
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                placeholder={apiUrlPlaceholder}
                value={apiUrl}
                onChange={handleChangeApiUrl}
                onKeyDown={handleOnKeyDown}
              />
            </Flex>
          )}
          <Flex flexDirection="column">
            <SubTitle>{accessTitle}</SubTitle>
            <Input
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              placeholder={accessPlaceholder}
              value={access}
              onChange={handleChangeAccess}
              onKeyDown={handleOnKeyDown}
            />
          </Flex>
          <Flex flexDirection="column">
            <SubTitle>{keyTitle}</SubTitle>
            <Input
              placeholder={keyPlaceholder}
              value={keyValue}
              onChange={handleChangeKey}
              onKeyDown={handleOnKeyDown}
            />
          </Flex>
        </Flex>
      </Flex>
      {linkHref && linkText && (
        <Link isExternal to={linkHref}>
          {linkText}
        </Link>
      )}
    </Wrapper>
  );
}

export default ApiInformations;
