import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getRoundedNumberWithSuffix } from 'semji-core/utils/numbers';
import styled from 'styled-components/macro';

import FavIcon from '@/components/FavIcon/FavIcon';
import SearchIcon from '@/components/icons/SearchIcon';
import Column from '@/containers/Content/BriefComponents/Table/Columns/Column';
import MetadataColumn from '@/containers/Content/BriefComponents/Table/Columns/MetadataColumn';
import PrimaryColumn from '@/containers/Content/BriefComponents/Table/Columns/PrimaryColumn';
import EmptyRow from '@/containers/Content/BriefComponents/Table/Rows/EmptyRow';
import Header from '@/containers/Content/BriefComponents/Table/Rows/Header';
import LoadingRow from '@/containers/Content/BriefComponents/Table/Rows/LoadingRow';
import MultiLineRow from '@/containers/Content/BriefComponents/Table/Rows/MultiLineRow';
import Table from '@/containers/Content/BriefComponents/Table/Table';
import { SourceWrapper } from '@/containers/Content/SidePanel/Components/Optimization/Section/Questions/Question';
import { getFragmentDirective } from '@/utils/url';

const SmallFavIcon = styled(FavIcon)`
  &&& {
    img {
      box-sizing: border-box;
      padding: 0;
    }
  }
`;

function Questions({ isLoading, questions }) {
  const { t } = useTranslation();
  const userLanguageCode = useSelector((state) => state.user?.languageCode);

  return (
    <Table>
      <Header>
        <PrimaryColumn>
          {t('content:brief.section-article-table-suggested-questions-header')}
        </PrimaryColumn>
      </Header>

      {isLoading ? (
        <LoadingRow />
      ) : questions?.length > 0 ? (
        questions.map(({ term, label, searchVolume, isPAA, answer }) => (
          <MultiLineRow key={label} gap="8px">
            <Column gap="8px">
              <PrimaryColumn>
                <span dangerouslySetInnerHTML={{ __html: label }} title={term} />
              </PrimaryColumn>
              <MetadataColumn>
                {isPAA ? (
                  <SourceWrapper>
                    {t('content:brief.section-article-questions-label-paa')}
                  </SourceWrapper>
                ) : (
                  <>
                    <SearchIcon />
                    &nbsp;
                    {getRoundedNumberWithSuffix({
                      locale: userLanguageCode,
                      number: searchVolume,
                    }) ?? '-'}
                  </>
                )}
              </MetadataColumn>
            </Column>
            {!!answer.label && !!answer.url && !!answer.term && (
              <Column color="light" gap="8px">
                <PrimaryColumn color="light">
                  <div dangerouslySetInnerHTML={{ __html: answer.label }} title={answer.label} />
                </PrimaryColumn>
                <MetadataColumn>
                  <SmallFavIcon url={`${answer.url}#${getFragmentDirective(answer.term)}`} />
                </MetadataColumn>
              </Column>
            )}
          </MultiLineRow>
        ))
      ) : (
        <EmptyRow />
      )}
    </Table>
  );
}

export default Questions;
