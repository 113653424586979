import './CreateForm.scss';

import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import usePostNewBrandVoice from '@/apis/semji/brandVoices/usePostBrandVoice';
import Form from '@/containers/BrandVoiceDeprecated/Form';

function CreateForm() {
  const { workspaceId } = useParams();

  const navigate = useNavigate();
  const { t } = useTranslation();

  function onSuccess() {
    navigate(-1);
  }

  const mutation = usePostNewBrandVoice({ onSuccess, workspaceId });

  function onSubmit(payload) {
    mutation.mutate(payload);
  }

  return (
    <div className="brand-voice-page-create">
      <div className="brand-voice-page-create__top">
        <h1 className="brand-voice-page-create__title">
          {t('brand-voice:form.create-brand-voice')}
        </h1>
        <p className="brand-voice-page-create__desc">{t('brand-voice:form.desc')} </p>
      </div>
      <Form onSubmit={onSubmit} />
    </div>
  );
}

export default CreateForm;
