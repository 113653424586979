import {
  getRoundedNumberMetricObject,
  getRoundedPercentMetricObject,
} from 'semji-core/utils/numbers';

import { METRIC_TYPE_PERCENT } from '@/utils/metrics/constants';

export function changeDataGetter({ rowData, date1, date2, metricKey, metricType, locale }) {
  let metric1 = null;
  let metric2 = null;

  if (rowData?.monthlyPageMetrics?.[date1] !== undefined) {
    metric1 = rowData.monthlyPageMetrics[date1][metricKey];
  }

  if (rowData?.monthlyPageMetrics?.[date2] !== undefined) {
    metric2 = rowData.monthlyPageMetrics[date2][metricKey];
  }

  if (null === metric1 || null === metric2 || metric1 + metric2 === 0) {
    return null;
  }

  if (metricType === METRIC_TYPE_PERCENT) {
    const val1 = metric1 * 100;
    const val2 = metric2 * 100;
    return getRoundedPercentMetricObject({ locale, number: val1 - val2 });
  }
  return getRoundedNumberMetricObject({ locale, number: metric1 - metric2 });
}
