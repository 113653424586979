import { nanoid } from 'nanoid';
import { useMemo } from 'react';

import SvgWrapper from './SvgWrapper';

const CalendarIcon = (props) => {
  const svgId = useMemo(() => nanoid(), []);

  return (
    <SvgWrapper fill="none" height="16" viewBox="0 0 16 16" width="16" {...props}>
      <g clipPath={`url(#clip${svgId})`}>
        <mask
          height="15"
          id={`mask${svgId}`}
          maskUnits="userSpaceOnUse"
          style={{ maskType: 'alpha' }}
          width="16"
          x="0"
          y="1"
        >
          <path
            d="M12.84 2.28002V1.40002H10.2V2.28002H5.8V1.40002H3.16V2.28002H0.52V15.48H15.48V2.28002H12.84ZM11.08 2.28002H11.96V4.04002H11.08V2.28002ZM4.04 2.28002H4.92V4.04002H4.04V2.28002ZM14.6 14.6H1.4V6.74955H14.6V14.6ZM1.4 5.86954V3.16002H3.16V4.92002H5.8V3.16002H10.2V4.92002H12.84V3.16002H14.6V5.86954H1.4Z"
            fill="black"
          />
        </mask>
        <g mask={`url(#mask${svgId})`}>
          <rect fill="#7C7D86" height="17.6" width="17.6" x="-1.24" y="-0.360107" />
        </g>
      </g>
      <defs>
        <clipPath id={`clip${svgId}`}>
          <rect fill="white" height="14.96" transform="translate(0.52 0.519897)" width="14.96" />
        </clipPath>
      </defs>
    </SvgWrapper>
  );
};

export default CalendarIcon;
