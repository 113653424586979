import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import apiClient from '@/apis/semji/apiClient';
import { DEFAULT_PAGE } from '@/components/AGGrid/hooks/useGridPagination/const';
import { QUERY_PARAM_SORT } from '@/components/AGGrid/hooks/useGridSorts/const';
import { GRID_STATE_DEFAULT_STALLING_TIME } from '@/components/AGGrid/hooks/useGridState/const';
import {
  PrefetchPagesArgs,
  UseGridStateProps,
  UseGridStateResults,
} from '@/components/AGGrid/hooks/useGridState/useGridState.types';
import { QUERY_PARAM_FILTER } from '@/hooks/useFilters/const';
import { UseFiltersUtils } from '@/hooks/useFilters/useFilters.utils';
import { showErrorSnackbar } from '@/store/actions/ui';

export function useGridState({
  url,
  paginationHook,
  masterQueryKeys,
  cacheStalingTime,
  filterHook,
}: UseGridStateProps): UseGridStateResults {
  const { get } = apiClient;
  const { t } = useTranslation();
  const [query] = useSearchParams();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const filtersFromQueryParams = query.get(QUERY_PARAM_FILTER);
  const sortsFromQueryParams = query.get(QUERY_PARAM_SORT);

  const unHashedFilters: string = useMemo(
    () => UseFiltersUtils.unHashQueryParams(filtersFromQueryParams),
    [filtersFromQueryParams]
  );

  const queryKey = [
    ...masterQueryKeys,
    { page: paginationHook.pagination.page, perPage: paginationHook.pagination.itemsPerPage },
    unHashedFilters,
    sortsFromQueryParams,
  ];

  function getQueryKeyPrefetch(page: number) {
    return [
      ...masterQueryKeys,
      {
        page,
        perPage: paginationHook.pagination.itemsPerPage,
      },
      unHashedFilters,
      sortsFromQueryParams,
    ];
  }

  function setPaginationData(totalItems: number) {
    paginationHook.setPagination({
      ...paginationHook.pagination,
      pageCount: Math.ceil(totalItems / paginationHook.pagination.itemsPerPage),
      totalItems,
    });
  }

  function getQueryFn({ page, itemsPerPage }: { page: number; itemsPerPage: number }) {
    return async () => {
      const result = await get(
        `${url}?${UseFiltersUtils.chainParamsProperties([`page=${page}`, `itemsPerPage=${itemsPerPage}`, unHashedFilters, sortsFromQueryParams])}`
      );

      return result.data;
    };
  }

  // Prefetch function that will cache result based on query key
  async function prefetchData(page: number) {
    await queryClient.prefetchQuery({
      queryFn: getQueryFn({
        itemsPerPage: paginationHook.pagination.itemsPerPage,
        page,
      }),
      queryKey: getQueryKeyPrefetch(page),
      retry: false,
      staleTime: (cacheStalingTime ?? GRID_STATE_DEFAULT_STALLING_TIME) * 1000, // Default 60 seconds
    });
  }

  // Classic fetch, only called if data has not been cached by prefetch
  const { data, isPlaceholderData } = useQuery({
    enabled: true,
    onError: () => {
      dispatch(showErrorSnackbar(t('common:error.default')));
    },
    placeholderData: { 'hydra:member': [...Array(6)].map(() => ({ isLoading: true })) },
    queryFn: getQueryFn({
      itemsPerPage: paginationHook.pagination.itemsPerPage as number,
      page: paginationHook.pagination.page,
    }),
    queryKey,
    retry: false,
    staleTime: (cacheStalingTime ?? GRID_STATE_DEFAULT_STALLING_TIME) * 1000, // Default 60 seconds
  });

  // Update pagination totalItems, even on cache serving
  if (
    data?.['hydra:totalItems'] > -1 &&
    data['hydra:totalItems'] !== paginationHook.pagination?.totalItems
  ) {
    const pageCount = data['hydra:totalItems'];
    setPaginationData(pageCount);
    prefetchPages({ page: paginationHook.pagination.page, pageCount });
  }

  // Prefetch n+1 and n-1 only if data is not available in cache
  function prefetchPages({ page, pageCount }: PrefetchPagesArgs) {
    prefetchData(
      Math.min(
        page + 1,
        Math.max(pageCount ?? paginationHook.pagination.pageCount ?? 1, 1) ?? DEFAULT_PAGE
      )
    );
    prefetchData(Math.max(page - 1, DEFAULT_PAGE));
  }

  return {
    data: data?.['hydra:member'] ?? [],
    loading: isPlaceholderData,
    pageCount: data?.['hydra:totalItems']
      ? Math.ceil(data?.['hydra:totalItems'] / paginationHook.pagination.itemsPerPage)
      : undefined,
    prefetchPages,
    totalItems: data?.['hydra:totalItems'] ?? undefined,
  };
}
