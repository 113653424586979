import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import SemjiCreditIcon from '@/components/icons/SemjiCreditIcon';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import EmptyRow from '@/containers/Content/BriefComponents/Table/Rows/EmptyRow';
import Row from '@/containers/Content/BriefComponents/Table/Rows/Row';
import { SEARCH_INTENT } from '@/containers/Content/SidePanel/Components/Optimization/Section/Credits/constants';
import { Loader } from '@/containers/Content/SidePanel/Components/Optimization/Section/Credits/UnlockRecommendationsOverlay';
import {
  getMessage,
  getTooltipMessage,
} from '@/containers/Content/SidePanel/Components/Optimization/Section/Credits/utils';
import { openCreditLimitDialog } from '@/store/actions/report';
import { unlockRecommendations } from '@/store/actionsCreator/report';
import useParamSelector from '@/store/hooks/useParamSelector';
import { selectCredits } from '@/store/selectors/selectCredits';
import { SEARCH_INTENTS_CREDITS_TYPE } from '@/utils/constants';

const StyledToolTip = styled(TooltipComponent)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: initial;
    cursor: pointer;
  }
`;
const TextWrapper = styled.div`
  color: ${({ theme }) => theme.cssColors.primary};
  font-weight: 500;
`;
const Wrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;
const StyledSemjiCreditIcon = styled(SemjiCreditIcon)`
  margin-right: 5px;
`;

const UnlockSearchIntentRow = ({ searchIntentsCount }) => {
  const dispatch = useDispatch();
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isLoadingSearchIntents, setIsLoadingSearchIntents] = useState(false);
  const keywordId = useSelector((state) => state.report?.focusTopKeyword?.id);
  const lockedSearchIntentCount = useSelector(
    (state) =>
      state.report.recommendations?.searchIntentsSuggestion?.data?.lockedSearchIntentCount || 0
  );

  const maxAmountToUnlock = lockedSearchIntentCount > 10 ? 10 : lockedSearchIntentCount;
  const apiCreditType = SEARCH_INTENTS_CREDITS_TYPE;
  const remainingCredits = useParamSelector(selectCredits, apiCreditType, 'remaining');
  const totalCredits = useParamSelector(selectCredits, apiCreditType, 'total');
  const message = getMessage(SEARCH_INTENT, maxAmountToUnlock, remainingCredits, isFirstLoad);
  const tooltipMessage = getTooltipMessage(SEARCH_INTENT, remainingCredits, totalCredits);

  async function handleClick() {
    if (remainingCredits > 0) {
      const numberOfItemsToUnlock =
        maxAmountToUnlock > remainingCredits ? remainingCredits : maxAmountToUnlock;

      setIsLoadingSearchIntents(true);
      await dispatch(unlockRecommendations(keywordId, apiCreditType, numberOfItemsToUnlock));
      setIsFirstLoad(false);
      setIsLoadingSearchIntents(false);
    } else {
      dispatch(openCreditLimitDialog(apiCreditType));
    }
  }

  if (lockedSearchIntentCount === 0) {
    if (searchIntentsCount > 0) {
      return null;
    }
    return <EmptyRow />;
  }

  return (
    <Row>
      <Wrapper>
        {isLoadingSearchIntents ? (
          <Loader />
        ) : (
          <StyledToolTip icon={<SemjiCreditIcon />} title={tooltipMessage} onClick={handleClick}>
            <StyledSemjiCreditIcon /> <TextWrapper>{message}</TextWrapper>
          </StyledToolTip>
        )}
      </Wrapper>
    </Row>
  );
};

export default UnlockSearchIntentRow;
