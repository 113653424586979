import { useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import apiClient from '@/apis/semji/apiClient';
import { MutationOptions } from '@/apis/types/mutation.types';
import { CompetitorWebsiteInfo, CompetitorWebsiteInfoDto } from '@/types/competitors';

interface UsePostCompetitorWebsiteInfoProps
  extends MutationOptions<CompetitorWebsiteInfoDto, CompetitorWebsiteInfo> {}

export default function usePostCompetitorWebsiteInfo({
  ...props
}: UsePostCompetitorWebsiteInfoProps) {
  const { post } = apiClient;
  const { workspaceId } = useParams();

  return useMutation({
    mutationFn: async (payload: CompetitorWebsiteInfoDto) => {
      const result = await post(`/workspaces/${workspaceId}/competitor_websites_info`, {
        ...payload,
      });

      return result.data;
    },
    ...props,
  });
}
