import styled from 'styled-components/macro';

import { TextButton as TextBn } from '../../../../../components/Button/Button';

const TextButton = styled(TextBn)`
  &&& {
    justify-content: center;
  }
`;

export default TextButton;
