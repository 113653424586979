import { useQueryClient } from '@tanstack/react-query';
import cloneDeep from 'lodash/cloneDeep';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { Placements } from 'semji-core/hooks/usePopover';

import { SCOPE_FACTS } from '@/apis/semji/constants';
import useGetFactsByContentId from '@/apis/semji/facts/useGetFactsByContentId';
import FactCheckCardWrapper from '@/containers/Content/SidePanel/Facts/FactCheckCardWrapper';
import {
  FACT_QUERY_PARAM,
  FACT_STATUS_ENUM,
  FACT_STATUS_KEY,
} from '@/containers/Content/SidePanel/Facts/facts.types';
import { FactDOMUtils } from '@/containers/Content/TinyMceComponents/Editor/hooks/useAiWriting/useFactCheck/factDom.utils';
import {
  FactCheckHookResults,
  FactCheckPopperCard,
} from '@/containers/Content/TinyMceComponents/Editor/hooks/useAiWriting/useFactCheck/useFactCheck.types';
import useApiConfigurations from '@/hooks/useApiConfigurations';
import useOrganizationFeatureSet, {
  AI_WRITING__FACT_CHECKING__IS_ENABLED,
} from '@/hooks/useOrganizationFeatureSet';
import { selectUserConfiguration } from '@/store/selectors/selectUserConfiguration';
import { Fact } from '@/types/fact/fact.types';
import { AI_WRITING_FACT_CHECKING_ENABLED } from '@/utils/configurations/constants';
import { MatchResult } from '@/utils/domMatcher/domMatcher.types';
import findTextAcrossHtmlNodes from '@/utils/domMatcher/findTextAcrossHtmlNodes';
import selectRange from '@/utils/domMatcher/selectRange';
import { highlight } from '@/utils/highlight';

import {
  FACT_CHECK_DATA_ATTRIBUTE_CC,
  FACT_CHECK_HIGHLIGHT_CLASS,
  FACT_CHECK_HOVER_DATA_ATTRIBUTE,
  FACT_CHECK_POPOVER_CARD_PREFIX_ID,
} from './const';

function useFactCheck(): FactCheckHookResults {
  const { contentId } = useParams();
  const [query, _] = useSearchParams();
  const queryClient = useQueryClient();

  const focusedFactCheckId = query.get(FACT_QUERY_PARAM);
  const { isFeatureEnabled: factCheckingFeatureSetEnabled } = useOrganizationFeatureSet(
    AI_WRITING__FACT_CHECKING__IS_ENABLED
  );

  const { highlightFactCheckingEnabled } = useSelector(selectUserConfiguration);
  const aiWritingFactCheckingEnabled = useApiConfigurations(AI_WRITING_FACT_CHECKING_ENABLED);

  const initRef = useRef<boolean>(false);
  const [factCheckPopperCard, setFactCheckPopperCard] = useState<FactCheckPopperCard>({
    content: undefined,
    position: undefined,
  });
  const [updateFacts, setFactsUpdate] = useState<number | boolean>(false);
  const queryKey = [SCOPE_FACTS.GET_FACTS_BY_CONTENT_ID, contentId];

  const { data: fetchedFacts } = useGetFactsByContentId(
    contentId,
    factCheckingFeatureSetEnabled && aiWritingFactCheckingEnabled,
    {
      onSuccess: (newData: Fact[]) => {
        function isSameFactArrays(newData: Fact[], oldData: Fact[]) {
          if (newData.length !== oldData.length) return false;
          const newFactsIds = newData.map((fact) => fact.id);
          const oldFactsIds = oldData.map((fact) => fact.id);

          return newFactsIds.every((id) => oldFactsIds.includes(id));
        }

        if (!isSameFactArrays(newData, fetchedFacts)) {
          initRef.current = true;
        }
      },
    }
  );

  function removeFactCheckPopperCard() {
    setFactCheckPopperCard((prevState) => ({
      ...prevState,
      textHovered: false,
    }));
  }

  function addFactCheckPopperCard({
    event,
    fact,
  }: {
    event: React.MouseEvent<HTMLElement> & { layerY: number };
    fact: Fact;
  }) {
    if (fact) {
      const nearestElement = FactDOMUtils.getNearestElementToCursorByAttribute(event, fact.id);

      setFactCheckPopperCard({
        content: (
          <FactCheckCardWrapper
            contentId={contentId}
            fact={fact}
            prefixId={FACT_CHECK_POPOVER_CARD_PREFIX_ID}
          />
        ),
        fact,
        placement: Placements.BottomStart,
        popperContext: {
          event,
          nearestElement,
        },
        textHovered: true,
      });
    }
  }

  function removeFactEventListeners(elements: NodeListOf<HTMLElement>) {
    elements.forEach((element: HTMLElement) => {
      element.onmouseenter = () => {};
      element.onclick = () => {};
      element.onmouseleave = () => {};
    });

    handleForceClosePopperFactCheckCard();
  }

  function addFactEventListeners(elements: NodeListOf<HTMLElement>, fact: Fact) {
    Array.from(elements).forEach((element: HTMLElement) => {
      // @ts-ignore
      element.onmouseenter = (
        event: React.MouseEvent<HTMLElement, MouseEvent> & { layerY: number }
      ) => {
        FactDOMUtils.setAttributeForElements({
          attribute: FACT_CHECK_HOVER_DATA_ATTRIBUTE,
          elements,
          value: 'true',
        });

        if (event?.buttons === 0 && highlightFactCheckingEnabled) {
          addFactCheckPopperCard({ event, fact });
        }
      };

      element.onmouseleave = () => {
        FactDOMUtils.setAttributeForElements({
          attribute: FACT_CHECK_HOVER_DATA_ATTRIBUTE,
          elements,
          value: 'false',
        });

        removeFactCheckPopperCard();
      };
    });
  }

  function handleHoverHighlightFactCheck(highlightedSpans: NodeListOf<HTMLElement>, fact: Fact) {
    if (
      fact.status === FACT_STATUS_ENUM.STATUS_VALIDATED ||
      fact.status === FACT_STATUS_ENUM.STATUS_DELETED ||
      !highlightFactCheckingEnabled
    ) {
      removeFactEventListeners(highlightedSpans);
      return;
    }

    addFactEventListeners(highlightedSpans, fact);
  }

  function handleForceClosePopperFactCheckCard() {
    setFactCheckPopperCard((prevState) => ({
      ...prevState,
      cardHovered: false,
      textHovered: false,
    }));
  }

  function handleFactCheck({
    fact,
    targetNode,
  }: {
    // Fact that will be highlighted
    fact: Fact;
    // Node in which fact is located
    targetNode: HTMLElement;
  }) {
    const textInNode: MatchResult | null = findTextAcrossHtmlNodes({
      caseSenstivity: false,
      domWrapper: targetNode,
      includePunctuation: false,
      textToMatch: fact.factCheckedSequence,
    });

    if (!textInNode) {
      return;
    }

    selectRange({
      endNode: textInNode.nodes.at(-1) as HTMLElement,
      endOffset: textInNode.endOffset,
      startNode: textInNode.nodes[0] as HTMLElement,
      startOffset: textInNode.startOffset,
    });

    highlight({
      dataAttribute: FACT_CHECK_DATA_ATTRIBUTE_CC,
      datum: fact.id,
      highlightClass: FACT_CHECK_HIGHLIGHT_CLASS,
      removeInternalSelection: true,
    });

    const highlightedSpans = FactDOMUtils.initFactCheckDataAttributes(fact);

    handleHoverHighlightFactCheck(highlightedSpans, fact);
  }

  async function addNewFactsOptimistically(newFacts: Fact[]) {
    await queryClient.cancelQueries({ queryKey });

    const previousFacts: Fact[] | undefined = queryClient.getQueryData(queryKey);
    queryClient.setQueryData(queryKey, (oldFacts: Fact[]) => [...oldFacts, ...newFacts]);

    return { previousFacts };
  }

  async function updateKeyFactsOptimistically(fact: Fact, key: string, newValue: string) {
    await queryClient.cancelQueries({ queryKey });

    const previousFacts: Fact[] | undefined = queryClient.getQueryData(queryKey);

    if (!previousFacts) {
      return;
    }

    const targetFactIndex = previousFacts.findIndex(
      (previousFact: Fact) => previousFact.id === fact.id
    );

    if (targetFactIndex !== -1) {
      const newFacts = cloneDeep(previousFacts);
      newFacts[targetFactIndex] = {
        ...newFacts[targetFactIndex],
        [key]: newValue,
      };

      queryClient.setQueryData(queryKey, () => newFacts);

      if (key === FACT_STATUS_KEY) {
        handleHoverHighlightFactCheck(
          FactDOMUtils.getAllElementsByFactId(fact.id),
          newFacts[targetFactIndex]
        );
      }

      return { previousFacts };
    }
  }

  useEffect(() => {
    // Init highlight when fetch is completed
    if (fetchedFacts && fetchedFacts?.length > 0 && false !== updateFacts) {
      const targetIds = Array.from(FactDOMUtils.getFactsIds());

      const targetFacts = fetchedFacts?.filter((fact: Fact) => targetIds.includes(fact.id));

      targetFacts?.forEach((fact: Fact) => {
        if (FACT_STATUS_ENUM.STATUS_DELETED !== fact.status) {
          const highlightedSpans = FactDOMUtils.initFactCheckDataAttributes(fact);

          handleHoverHighlightFactCheck(highlightedSpans, fact);
        }
      });
    }
  }, [fetchedFacts, updateFacts, highlightFactCheckingEnabled]);

  useEffect(() => {
    if (fetchedFacts && focusedFactCheckId && fetchedFacts?.length > 0) {
      FactDOMUtils.handleHighlightFactCheck(focusedFactCheckId, fetchedFacts);
    } else if (fetchedFacts && fetchedFacts?.length > 0) {
      FactDOMUtils.removePreviousFactCheckHighlights();
    }
  }, [focusedFactCheckId, fetchedFacts]);

  return {
    addNewFactsOptimistically,
    handleForceClosePopperFactCheckCard,
    factCheckPopperCardHook: [factCheckPopperCard, setFactCheckPopperCard],
    facts: fetchedFacts ?? [],
    handleFactCheck,
    handleInitFactCheckHighlight: setFactsUpdate,
    updateKeyFactsOptimistically,
  };
}

export default useFactCheck;
