import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import PlusIcon from '../icons/PlusIcon';

const OptionCreationSuggestion = styled.div`
  padding: 0.9em;
  font-size: 0.9em;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.secondary};
`;

export const FocusKeywordOptionAdd = ({ value, ...props }) => {
  const { t } = useTranslation();
  return (
    <OptionCreationSuggestion {...props}>
      <PlusIcon />
      &nbsp;{' '}
      {value
        ? t('components:focus-keyword.add-keyword', { keyword: value })
        : t('components:focus-keyword.add-focus-keyword')}
    </OptionCreationSuggestion>
  );
};
