import styled from 'styled-components/macro';

import Row from './Row';

const TitleRow = styled(Row)`
  & > :first-child {
    flex: 1;
  }
`;

export default TitleRow;
