import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { FlexCenteredAlignContainer } from '@/components/_common';
import { Position } from '@/components/CompetitorPage/CompetitorPage';
import FavIcon from '@/components/FavIcon/FavIcon';
import { DefaultDarkText } from '@/components/Text/Dark';
import TooltipComponent from '@/components/Tooltip/Tooltip';

const Wrapper = styled.div`
  padding: 0.5em 0;
`;
const SampleWrapper = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.brightGrey};
  padding: 0.5em;
`;
const StyledFavIcon = styled(FavIcon)`
  font-size: 0.9em;
`;
const StyledDarkText = styled(DefaultDarkText)`
  && {
    font-size: 0.75em;
  }
  && em {
    font-style: initial;
    font-weight: 700;
  }
`;
const HtmlSampleWrapper = styled.div`
  padding-top: 0.5em;
`;
const Li = styled.li`
  list-style-type: circle;
`;
const Ul = styled.ul`
  padding: 0 1em;
`;
const StyledPosition = styled(Position)`
  && {
    margin-left: 10px;
  }
`;
const WrapperCompetitor = styled(FlexCenteredAlignContainer)`
  padding-top: 0.5em;
`;

function RecommendationDetails({ data = [] }) {
  const { t } = useTranslation();
  const competitors = recommendationsGroupedByCompetitor(data);

  function recommendationsGroupedByCompetitor(data) {
    const dataGroupedByCompetitor = {};

    data.forEach((recommendation) => {
      if (dataGroupedByCompetitor[recommendation.url]) {
        dataGroupedByCompetitor[recommendation.url].samples.push(recommendation.html);
      } else {
        dataGroupedByCompetitor[recommendation.url] = {
          position: recommendation.position,
          samples: [recommendation.html],
          title: recommendation.title,
          url: recommendation.url,
        };
      }
    });

    return dataGroupedByCompetitor;
  }

  return (
    <Wrapper>
      {Object.values(competitors)
        .sort((competitorA, competitorB) => (competitorA.position > competitorB ? 1 : -1))
        .map((competitor) => (
          <SampleWrapper key={competitor.url}>
            <HtmlSampleWrapper>
              <Ul>
                {competitor.samples.map((sample) => (
                  <Li key={nanoid()}>
                    <StyledDarkText dangerouslySetInnerHTML={{ __html: sample }} />
                  </Li>
                ))}
              </Ul>
              <WrapperCompetitor>
                <TooltipComponent
                  title={t(
                    'content:side-panel-components.optimization.details.recommendation-details.rank'
                  )}
                >
                  <StyledPosition>{competitor.position}</StyledPosition>
                </TooltipComponent>
                <StyledFavIcon displayLink url={competitor.url} />
              </WrapperCompetitor>
            </HtmlSampleWrapper>
          </SampleWrapper>
        ))}
    </Wrapper>
  );
}

export default RecommendationDetails;
