import { nanoid } from 'nanoid';
import React, { useMemo } from 'react';

import SvgWrapper from './SvgWrapper';

const PositionIcon = (props) => {
  const svgId = useMemo(() => nanoid(), []);
  return (
    <SvgWrapper {...props}>
      <mask
        height="16"
        id={svgId}
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="13"
        x="2"
        y="1"
      >
        <path
          clipRule="evenodd"
          d="M7.16242 2.08698C7.21041 1.81504 7.02887 1.55568 6.75693 1.50769C6.48498 1.4597 6.22563 1.64125 6.17764 1.91319L5.68842 4.68545H2.5C2.22386 4.68545 2 4.90931 2 5.18545C2 5.46159 2.22386 5.68545 2.5 5.68545H5.51195L4.63477 10.6562H2.5C2.22386 10.6562 2 10.88 2 11.1562C2 11.4323 2.22386 11.6562 2.5 11.6562H4.4583L3.7926 15.4284C3.74461 15.7003 3.92616 15.9597 4.1981 16.0077C4.47004 16.0557 4.7294 15.8741 4.77739 15.6022L5.47375 11.6562H9.57594L8.91025 15.4284C8.86226 15.7003 9.04381 15.9597 9.31575 16.0077C9.58769 16.0557 9.84704 15.8741 9.89503 15.6022L10.5914 11.6562H14.2941C14.5703 11.6562 14.7941 11.4323 14.7941 11.1562C14.7941 10.88 14.5703 10.6562 14.2941 10.6562H10.7679L11.645 5.68545H14.2941C14.5703 5.68545 14.7941 5.46159 14.7941 5.18545C14.7941 4.90931 14.5703 4.68545 14.2941 4.68545H11.8215L12.2801 2.08698C12.3281 1.81504 12.1465 1.55568 11.8746 1.50769C11.6026 1.4597 11.3433 1.64125 11.2953 1.91319L10.8061 4.68545H6.70387L7.16242 2.08698ZM9.75241 10.6562L10.6296 5.68545H6.5274L5.65022 10.6562H9.75241Z"
          fill="#A8A9AF"
          fillRule="evenodd"
        />
      </mask>
      <g mask={`url(#${svgId})`}>
        <rect fill="currentColor" height="20" width="20" x="-2" y="-1" />
      </g>
    </SvgWrapper>
  );
};

export default PositionIcon;
