import { useQuery } from '@tanstack/react-query';

import apiClient from '@/apis/semji/apiClient';
import { SCOPE_COMPETITORS } from '@/apis/semji/constants';
import { createQueryKeyFromFiltersAndProperties } from '@/apis/semji/helper';
import { QueryOptions } from '@/apis/types/query.types';
import { IFilterTransformed } from '@/hooks/useFilters/useFilters.types';
import { CompetitorReportMetric, CompetitorTopPagesFilters } from '@/types/competitors';

import { MetricsGroupByType } from './constants';

interface UseGetCompetitorReportMetricsProps extends QueryOptions<{}, CompetitorReportMetric[]> {
  competitorReportId: string;
  filters: IFilterTransformed<CompetitorTopPagesFilters> & {
    groupBy?: MetricsGroupByType;
  };
}

export default function useGetCompetitorReportMetrics({
  competitorReportId,
  filters = {},
  ...props
}: UseGetCompetitorReportMetricsProps) {
  const { get } = apiClient;

  return useQuery({
    placeholderData: [],
    queryFn: async ({ signal }) => {
      const result = await get(`/competitor_reports/${competitorReportId}/metrics`, {
        params: {
          ...filters,
        },
        signal,
      });

      return result.data['hydra:member'] || [];
    },
    queryKey: [
      SCOPE_COMPETITORS.GET_COMPETITOR_REPORT_METRICS,
      competitorReportId,
      ...createQueryKeyFromFiltersAndProperties({ filters }),
    ],
    ...props,
  });
}
