import { useQuery } from '@tanstack/react-query';

import apiClient from '@/apis/semji/apiClient';
import { MetricsGroupByType } from '@/apis/semji/competitors/constants';
import { SCOPE_COMPETITORS } from '@/apis/semji/constants';
import { createQueryKeyFromFiltersAndProperties } from '@/apis/semji/helper';
import { QueryOptions } from '@/apis/types/query.types';
import { IFilterTransformed } from '@/hooks/useFilters/useFilters.types';
import { CompetitorTopPagesFilters, CompetitorWebsiteMetric } from '@/types/competitors';

interface UseGetCompetitorWebsiteMetricsProps
  extends QueryOptions<
    {},
    { competitorMetrics: CompetitorWebsiteMetric[]; referenceMetrics: CompetitorWebsiteMetric[] }
  > {
  websiteId: string;
  referenceId: string;
  reportId?: string;
  filters: IFilterTransformed<CompetitorTopPagesFilters> & { groupBy?: MetricsGroupByType };
}

export default function useGetCompetitorWebsiteMetrics({
  websiteId,
  referenceId,
  filters = {},
  reportId,
  ...props
}: UseGetCompetitorWebsiteMetricsProps) {
  const { get } = apiClient;

  return useQuery({
    placeholderData: { competitorMetrics: null, referenceMetrics: null },
    queryFn: async ({ signal }) => {
      const [referenceMetrics, competitorMetrics] = await Promise.all([
        get(`/competitor_reports/${reportId}/metrics`, {
          params: {
            competitorWebsiteId: [referenceId],
            ...filters,
          },
          signal,
        }),
        get(`/competitor_reports/${reportId}/metrics`, {
          params: {
            competitorWebsiteId: [websiteId],
            ...filters,
          },
          signal,
        }),
      ]);

      return {
        competitorMetrics: competitorMetrics.data['hydra:member'] || [],
        referenceMetrics: referenceMetrics.data['hydra:member'] || [],
      };
    },

    queryKey: [
      SCOPE_COMPETITORS.GET_COMPETITOR_WEBSITE_METRICS,
      reportId,
      referenceId,
      websiteId,
      ...createQueryKeyFromFiltersAndProperties({ filters }),
    ],
    ...props,
  });
}
