import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { DefaultLink } from '@/components/Navigation/Link';
import { Instruction } from '@/components/Optimization/Instruction';
import Optimization from '@/components/Optimization/Optimization';
import DetailsBase from '@/containers/Content/SidePanel/Components/Optimization/Details/DetailsBase';
import TitleDetails from '@/containers/Content/SidePanel/Components/Optimization/Details/TitleDetails';
import { disableMainOptimization, enableMainOptimization } from '@/store/actionsCreator/report';

function Title({
  score,
  weight,
  ready,
  keyword,
  disabled,
  data,
  focusTopKeywordId,
  html,
  contentTitle,
  disableMainOptimization,
  enableMainOptimization,
  ...props
}) {
  const { t } = useTranslation();
  const [showDetails, setShowDetails] = useState(false);
  const competitors = Object.values(data?.competitors || {});

  function openDetails() {
    setShowDetails(true);
  }

  function closeDetails() {
    setShowDetails(false);
  }

  function disableRecommendation(key) {
    return disableMainOptimization(key, focusTopKeywordId, html, contentTitle);
  }

  function enableRecommendation(key) {
    return enableMainOptimization(key, focusTopKeywordId, html, contentTitle);
  }

  return (
    <>
      <Optimization
        description={
          <span>
            {t('content:side-panel-components.optimization.section.title.description')}
            <Trans
              components={{
                extLink: (
                  <DefaultLink
                    color="dark060"
                    decoration
                    isExternal
                    noDynamicFontSize
                    noPadding
                    size="xsm"
                    to={t('common:links.help-title')}
                    weight="medium"
                  />
                ),
              }}
              i18nKey="content:side-panel-components.optimization.section.title.description-learn-more"
            />
          </span>
        }
        disabled={disabled}
        disableRecommendation={disableRecommendation}
        enableRecommendation={enableRecommendation}
        helpCenterLink={t('common:links.help-title')}
        infoText={t('content:side-panel-components.optimization.section.title.info-text')}
        instructions={[
          <Instruction
            key="title"
            allowActions={false}
            completed={1 === score}
            defaultLabelFontWeight="normal"
            disabled={disabled}
            label={t('content:side-panel-components.optimization.section.title.instruction-label', {
              keyword,
            })}
            title={t('content:side-panel-components.optimization.section.title.instruction-title', {
              keyword,
            })}
          />,
        ]}
        isEmpty={false}
        isReady={ready}
        loaderRows={1}
        points={Math.round((weight - weight * score) * 100)}
        score={score}
        showDetailsAction={openDetails}
        title={t('content:side-panel-components.optimization.section.title.title')}
        {...props}
      />
      <DetailsBase
        description={t(
          'content:side-panel-components.optimization.section.title.details-description',
          { keyword }
        )}
        show={showDetails}
        subTitle={t('content:side-panel-components.optimization.section.title.details-subtitle', {
          keyword,
        })}
        title={t('content:side-panel-components.optimization.section.title.title')}
        onClose={closeDetails}
      >
        <TitleDetails data={competitors} />
      </DetailsBase>
    </>
  );
}

const mapStateToProps = (state, props) => {
  return {
    contentTitle: state.content.title,
    data: state.report.recommendations[props.id]?.data,
    disabled: state.report.recommendations[props.id]?.disabled,
    html: state.content.html,
    ready: state.report.recommendations[props.id]?.ready,
    score: state.report.recommendations[props.id]?.score,
    weight: state.report.recommendations[props.id]?.weight,
  };
};

export default connect(mapStateToProps, {
  disableMainOptimization,
  enableMainOptimization,
})(Title);
