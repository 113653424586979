import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getDefaultOptionParentFolders } from '@/components/Filters/FolderFiltersPanel/constants';
import FolderIcon from '@/components/Filters/FolderFiltersPanel/FolderIcon';
import Select from '@/components/Select/FolderSelect/Select';
import TextField from '@/components/Select/FolderSelect/TextField';
import Dialog from '@/design-system/components/Dialog';
import { isEnterHotkey } from '@/utils/keyboard';

import DialogBody from './DialogBody';

function EditFolderDialog({
  isLoading,
  onConfirm,
  onCancel,
  currentId,
  currentName,
  currentParentFolderId,
  folders,
}) {
  const { t } = useTranslation();
  const DEFAULT_OPTION_PARENT_FOLDERS = getDefaultOptionParentFolders();

  const [name, setName] = useState(currentName);
  const [parentFolderId, setParentFolderId] = useState(currentParentFolderId);

  const isConfirmDisabled = !name.trim();

  // We don't allow a depth above 2 so if it has children to hide the selector.
  const { hasChildren, parentFolders, currentParentFolder } = useMemo(
    () =>
      folders.reduce(
        (acc, item) => {
          if (item.id === currentId) {
            return acc;
          }
          if (item.id === currentParentFolderId) {
            acc.currentParentFolder = item;
          }
          if (!!item.parentFolderId) {
            if (item.parentFolderId === currentId) {
              acc.hasChildren = true;
            }
          } else {
            acc.parentFolders.push({ name: item.name, value: item.id });
          }

          return acc;
        },
        {
          currentParentFolder: null,
          hasChildren: false,
          parentFolders: [DEFAULT_OPTION_PARENT_FOLDERS],
        }
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentId, currentParentFolderId, folders]
  );

  function handleChangeFolderName(value) {
    setName(value);
  }

  function handleKeyDown(event) {
    if (isEnterHotkey(event)) {
      handleConfirm();
    }
  }

  function handleChangeParentFolderId(id) {
    setParentFolderId(id);
  }

  function handleConfirm() {
    onConfirm?.({ id: currentId, name, parentFolderId });
  }

  return (
    <Dialog
      canContentOverflow
      confirmLabel={t('components:filters.folder-filters-panel.edit-dialog.label-confirm')}
      isConfirmDisabled={isConfirmDisabled}
      isLoading={isLoading}
      isOpen={true}
      title={t('components:filters.folder-filters-panel.edit-dialog.title')}
      onCancel={onCancel}
      onConfirm={handleConfirm}
    >
      <DialogBody>
        <TextField
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          isClearable
          placeholder={t('components:filters.folder-filters-panel.edit-dialog.input-placeholder')}
          value={name}
          onChange={handleChangeFolderName}
          onKeyDown={handleKeyDown}
        />
        {!hasChildren && (
          <Select
            defaultValue={currentParentFolder}
            iconPlaceHolder={<FolderIcon $hasMargin />}
            options={parentFolders}
            onChange={handleChangeParentFolderId}
          />
        )}
      </DialogBody>
    </Dialog>
  );
}

export default EditFolderDialog;
