import './QuickStartGuide.scss';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import { LOCATION } from '@/apis/semji/constants';
import useGetWorkspaceById from '@/apis/semji/workspace/useGetWorkspaceById';
import WorkingImage from '@/assets/images/working-image.svg';
import { FlexColumnContainer, FlexRowCenteredContainer } from '@/components/_common';
import ProgressBar from '@/components/Rating/LinearProgressBar';
import { ParagraphText } from '@/components/Text/Paragraph';
import { TitleFour, TitleOne, TitleThree } from '@/components/Text/Title';
import Accordion from '@/containers/Onboarding/Accordion';
import { ItemFooter, StyledParagraphText } from '@/containers/Onboarding/common';
import { showErrorSnackbar } from '@/store/actions/ui';
import { updateWorkspaceAchievements } from '@/store/actions/workspace';
import { selectWorkspaceOnboardingTasks } from '@/store/selectors/selectWorkspaceOnboardingTasks';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
`;
const ProgressBarWrapper = styled.div`
  width: 200px;
`;
const NoFlexRowCenteredContainer = styled(FlexRowCenteredContainer)`
  && {
    flex: none;
    margin-left: 20px;
  }
`;
const Body = styled.div`
  display: flex;
  flex: 1;
  background-color: ${({ theme }) => theme.cssColors.dark002};
  justify-content: center;
  align-items: baseline;
  padding-bottom: 77px;
`;
const StepWrapper = styled(FlexColumnContainer)`
  margin-top: 45px;
`;
const HeaderWrapper = styled.div`
  display: flex;
  width: 1200px;
  padding: 0 50px;
  justify-content: space-between;
`;
const BodyWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1200px;
  padding: 0 50px;
`;
const FooterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  box-shadow: 0px 3px 10px 0px rgba(37, 39, 54, 0.1);
`;
const FooterBody = styled.div`
  width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px;
`;
const AlignSelfEndDiv = styled.div`
  align-self: end;
`;
const MargedDiv = styled.div`
  margin-top: ${({ marginTop }) => (marginTop ? '30px' : '0')};
`;

const HeaderRender = ({ user, percentage }) => {
  const { t } = useTranslation();
  const { organizationId } = useParams();
  const brandName = useSelector((state) => state.organizations?.byId?.[organizationId]?.brandName);

  const explanationMessage = t(
    `quick-start-guide:header.${brandName ? 'explanation-custom-brand' : 'explanation'}`,
    { brandName: brandName }
  );

  return (
    <div className="quick_start_guide__header">
      <HeaderWrapper>
        <div>
          <TitleOne noMargin weight="strong">
            {t('quick-start-guide:header.title', { user })}
          </TitleOne>
          <ParagraphText color="dark060" noPadding size="md" weight="medium">
            {explanationMessage}
          </ParagraphText>
        </div>
        <NoFlexRowCenteredContainer>
          <ProgressBarWrapper>
            <ProgressBar height="8px" value={percentage / 100} />
          </ProgressBarWrapper>
          <StyledParagraphText color="dark100" margin="20px" noPadding weight="medium">
            {t('quick-start-guide:header.percentage-completed', {
              percentage: percentage.toFixed(0),
            })}
          </StyledParagraphText>
        </NoFlexRowCenteredContainer>
      </HeaderWrapper>
    </div>
  );
};

const BodyRender = ({ tasks }) => {
  const { t } = useTranslation();
  const [expandedId, setExpandedId] = useState(null);
  const [expandedChildrenId, setExpandedChildrenId] = useState(null);

  return (
    <Body>
      <BodyWrapper>
        <StepWrapper>
          <TitleThree noMargin weight="strong">
            {t('quick-start-guide:body.stepper.title-stepper')}
          </TitleThree>
          {tasks.map((accordion, index) => (
            <Accordion
              key={accordion.id}
              accordion={accordion}
              expandedChildrenId={expandedChildrenId}
              expandedId={expandedId}
              index={index}
              setExpandedChildrenId={setExpandedChildrenId}
              setExpandedId={setExpandedId}
              stepsListLength={tasks.length}
            />
          ))}
        </StepWrapper>
      </BodyWrapper>
    </Body>
  );
};

const FooterRender = () => {
  const { t } = useTranslation();
  const i18nNameSpace = 'quick-start-guide:footer';

  return (
    <FooterWrapper>
      <FooterBody>
        <div>
          <TitleFour noMargin weight="strong">
            {t(`${i18nNameSpace}.title`)}
          </TitleFour>
          <MargedDiv marginTop>
            <ItemFooter
              link={t(`${i18nNameSpace}.items.video-tutorial.link`)}
              textExplanation={t(`${i18nNameSpace}.items.video-tutorial.text-explanation`)}
              title={t(`${i18nNameSpace}.items.video-tutorial.title`)}
            />
          </MargedDiv>
          <MargedDiv marginTop>
            <ItemFooter
              link={t(`${i18nNameSpace}.items.help-center.link`)}
              textExplanation={t(`${i18nNameSpace}.items.help-center.text-explanation`)}
              title={t(`${i18nNameSpace}.items.help-center.title`)}
            />
          </MargedDiv>
        </div>
        <img alt={t(`${i18nNameSpace}.alt-image`)} src={WorkingImage} />
        <AlignSelfEndDiv>
          <div>
            <ItemFooter
              link={t(`${i18nNameSpace}.items.customer-success.link`)}
              textExplanation={t(`${i18nNameSpace}.items.customer-success.text-explanation`)}
              title={t(`${i18nNameSpace}.items.customer-success.title`)}
            />
          </div>
          <MargedDiv marginTop>
            <ItemFooter
              link={t(`${i18nNameSpace}.items.blog.link`)}
              textExplanation={t(`${i18nNameSpace}.items.blog.text-explanation`)}
              title={t(`${i18nNameSpace}.items.blog.title`)}
            />
          </MargedDiv>
        </AlignSelfEndDiv>
      </FooterBody>
    </FooterWrapper>
  );
};

function QuickStartGuide() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { workspaceId } = useParams();
  const { percentage, tasks } = useSelector(selectWorkspaceOnboardingTasks);
  const firstName = useSelector((state) => state.user.firstName);

  const workspace = useGetWorkspaceById({
    location: LOCATION.QUICK_START_GUIDE,
    onError: () => dispatch(showErrorSnackbar(t('common:error.default'))),
    onSuccess: (data) =>
      dispatch(updateWorkspaceAchievements(workspaceId, [...data.workspaceAchievements])),
    refetchOnWindowFocus: 'always',
    workspaceId,
  });

  useEffect(() => {
    return () => {
      workspace.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <HeaderRender percentage={percentage} user={firstName} />
      <BodyRender tasks={tasks} />
      <FooterRender />
    </Wrapper>
  );
}

export default QuickStartGuide;
