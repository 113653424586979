import styled from 'styled-components/macro';

export default styled.span`
  box-sizing: border-box;
  height: 15px;
  width: 15px;
  border: 0.08em solid currentColor;
  background-color: currentColor;
  box-shadow: 0px 0px 0px 0.13em white inset;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  overflow: hidden;
  ::after {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    left: calc(50% + 0.05em);
    background: white;
  }
`;
