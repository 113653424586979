import styled from 'styled-components/macro';

import CloseIcon from '@/components/icons/CloseIcon';
import PencilIcon from '@/components/icons/PencilIcon';
import PlusRoundIcon from '@/components/icons/PlusRoundIcon';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import Rotate from '@/design-system/components/Button/Rotate';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 400px;
  flex: 1;
  min-width: 200px;
`;

export const Header = styled.div`
  box-sizing: border-box;
  display: flex;
  height: 35px;
  line-height: 35px;
  align-items: center;
  padding: 8px 18px 8px 22px;
`;

export const Title = styled.span`
  flex: 1;
  font-weight: ${({ theme }) => theme.textCss.weights.strong};
  font-size: ${({ theme }) => theme.textCss.sizes.sm};
  color: ${({ theme }) => theme.cssColors.dark040};
  text-transform: uppercase;
`;

export const PlusIcon = styled(PlusRoundIcon)`
  && {
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    padding: 4px;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    :hover {
      color: ${({ theme }) => theme.cssColors.dark080};
      border-radius: 4px;
      backgroundcolor: ${({ theme }) => theme.cssColors.dark005};
    }
  }
`;

export const StyledPencilIcon = styled(PencilIcon)`
  && {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;

export const StyledDeleteIcon = styled(CloseIcon)`
  && {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;

export const Tooltip = styled(TooltipComponent)`
  display: flex;
  color: ${({ theme }) => theme.cssColors.dark080};

  :hover {
    color: ${({ theme }) => theme.cssColors.primary};
  }
`;

export const Items = styled.ul`
  display: flex;
  flex-direction: column;
  max-height: ${({ isOpen }) => (isOpen ? '100000px' : '0')};
  overflow: hidden;
  transition: max-height 0.25s linear;
`;

export const ItemAction = styled.div`
  display: none;
  gap: 10px;
`;

export const ItemContent = styled(TooltipComponent)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ItemNumber = styled.div`
  display: flex;
`;

export const ScrollbarWrapper = styled.div`
  flex: 1;
`;

export const Item = styled.li`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 7px;
  border-radius: 0.5rem;
  width: min(100%, 246px);
  margin-bottom: 4px;
  padding: 4px 22px 4px ${({ indentation = 1 }) => 6 + 18 * indentation}px;
  cursor: pointer;
  font-size: ${({ theme }) => theme.textCss.sizes.sm};
  line-height: ${({ theme }) => theme.textCss.sizes.lg};
  color: ${({ isActive, theme }) => (isActive ? theme.cssColors.primary : theme.cssColors.dark100)};
  background-color: ${({ theme, isActive }) => isActive && theme.colors.primaryPink5};

  & ${Rotate} {
    color: ${({ theme }) => theme.cssColors.dark080};
    font-size: ${({ theme }) => theme.textCss.sizes.xs};
    position: relative;

    :after {
      content: '';
      position: absolute;
      top: -10px;
      bottom: -10px;
      left: -10px;
      right: -10px;
    }

    :hover {
      color: ${({ theme }) => theme.cssColors.primary};
    }
  }

  :hover {
    background-color: ${({ theme }) => theme.colors.primaryPink5};

    ${ItemAction} {
      display: ${({ isEditable }) => isEditable && 'flex'};
    }

    ${ItemNumber} {
      display: ${({ isEditable }) => isEditable && 'none'};
    }
  }

  & > ${ItemContent} {
    flex: 1;
  }

  & svg {
    color: ${({ isActive, theme }) => isActive && theme.cssColors.primary100};
  }
`;
