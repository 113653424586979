import React from 'react';

import SvgWrapper from './SvgWrapper';

const StatsIcon = (props) => (
  <SvgWrapper viewBox="0 0 12 11" {...props}>
    <path d="M12 10.6H0V9.9H12V10.6ZM2.8 5.6V9.1H3.5V4.9H0.7V9.1H1.4V5.6H2.8ZM6.4 3.5V9.1H7.1V2.8H4.2V9.2H4.9V3.5H6.4ZM9.9 0.7V9.2H10.6V0H7.8V9.2H8.5V0.7H9.9Z" />
  </SvgWrapper>
);

export default StatsIcon;
