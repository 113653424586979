import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player/youtube';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import {
  FlexCenteredAlignContainer,
  FlexCenteredAlignJustifyEndContainer,
  FlexColumnContainer,
  FlexColumnEndAlignContainer,
  FlexColumnGrowContainer,
  FlexGrowContainer,
  FlexJustifiedSpaceBetweenContainer,
  FlexRowContainer,
} from '@/components/_common/index';
import DashboardIcon from '@/components/icons/DashboardIcon';
import Logo from '@/components/icons/Logo';
import NotValidatedIcon from '@/components/icons/NotValidatedIcon';
import PlainBulletListIcon from '@/components/icons/PlainBulletListIcon';
import ScreenIcon from '@/components/icons/ScreenIcon';
import StatsUpIcon from '@/components/icons/StatsUpIcon';
import ValidatedIcon from '@/components/icons/ValidatedIcon';
import { ContainedLinkButton } from '@/components/Navigation/Link';
import { Divider } from '@/components/Profile/ProfileBox';
import ProgressBar from '@/components/Rating/LinearProgressBar';
import SelectWorkspace from '@/components/Select/SelectWorkspace';
import { ParagraphText } from '@/components/Text/Paragraph';
import { PrimaryTitle } from '@/components/Text/Title';
import { Bold } from '@/containers/Content/EditorComponents/Nodes';
import AdministratorMenu from '@/containers/Layout/AdministratorMenu';
import { breakpoints } from '@/themes/responsive';

const VIDEO_IS_WATCHED_AT = 60; // If 60% of the video is watched, the video is considered viewed

const AdministratorMenuWrapper = styled(FlexGrowContainer)`
  margin-right: 10px;
`;
const StyledFlexCenteredAlignContainer = styled(FlexCenteredAlignContainer)`
  && {
    flex: none;
  }
`;

const ViewedIndicatorIcon = styled(({ type, ...props }) => {
  switch (type) {
    case 'validated':
      return <ValidatedIcon withCircle {...props} />;
    case 'not-validated':
      return <NotValidatedIcon {...props} />;
    default:
      return null;
  }
})`
  && {
    height: 25px;
    width: 25px;
    right: -18px;
    top: 2px;
    position: absolute;
  }
`;

const VideoIcon = styled(({ type, ...props }) => {
  switch (type) {
    case 'screen':
      return <ScreenIcon {...props} />;
    case 'dashboard':
      return <DashboardIcon {...props} />;
    case 'stats-up':
      return <StatsUpIcon {...props} />;
    case 'bullet-list':
      return <PlainBulletListIcon {...props} />;
    default:
      return null;
  }
})`
  && {
    height: 30px;
    width: 30px;
    color: ${(props) => props.theme.colors.grey};
  }
`;

const IconWrapper = styled.div`
  margin-right: 30px;
  position: relative;
`;

const MarginBottomDiv = styled.div`
  margin-bottom: ${(props) => props.marginBottom};
`;

const SelectVideoWrapper = styled(FlexRowContainer)`
  padding: 15px 25px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.08);
  align-items: center;
  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.brightGrey};
  }
`;

const LogoWrapper = styled.div`
  margin: 15px 25px;
`;

const StyledFlexColumnContainer = styled(FlexColumnContainer)`
  padding-left: 200px;
  @media (max-width: ${breakpoints.xl}) {
    padding-right: 200px;
  }
`;

const VideoWrapper = styled.div`
  padding: 20px 0px 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.08);
  @media (max-width: ${breakpoints.xl}) {
    margin: 0px 0px 50px 0px;
    padding: 20px;
  }
`;

const MargedParagraphText = styled(ParagraphText)`
  margin: 10px 0px;
`;

const StyledFlexColumnEndAlignContainer = styled(FlexColumnEndAlignContainer)`
  @media (max-width: ${breakpoints.xl}) {
    align-items: center;
  }
`;

const ButtonWrapper = styled.div`
  max-width: 150px;
  @media (max-width: ${breakpoints.xl}) {
    margin-bottom: 50px;
  }
`;

const VideoTitle = styled.div`
  color: ${(props) =>
    props.selected ? props.theme.colors.secondary : props.theme.textCss.colors.default};
  font-weight: 500;
`;

const RowContainer = styled(FlexRowContainer)`
  flex: none;
  @media (max-width: ${breakpoints.xl}) {
    flex-direction: column-reverse;
  }
`;

const initialValueVideoState = [
  {
    duration: 158,
    iconType: 'screen',
    id: 0,
    title: 'discover-semji-platform',
    videoUrl: 'https://youtu.be/6yteJpLB2Vw',
  },
  {
    duration: 197,
    iconType: 'stats-up',
    id: 1,
    title: 'how-to-create-content',
    videoUrl: 'https://youtu.be/FMdjbYemzl4',
  },
];

const getLocalStorageTimersValue = (workspaceId) => {
  let value = null;
  try {
    value = JSON.parse(localStorage.getItem(`workspace:${workspaceId}:in_queue:videoState`));
  } catch (error) {
    return null;
  }
  return value;
};

const VideoPlayerComponent = ({ currentVideo, updateTimerCurrentVideo }) => (
  <StyledFlexColumnEndAlignContainer>
    <VideoWrapper>
      <ReactPlayer
        config={{
          playerVars: {
            start: currentVideo.timerStart,
          },
        }}
        controls
        url={currentVideo.videoUrl}
        onProgress={(currentVideoState) =>
          updateTimerCurrentVideo(Math.floor(currentVideoState.playedSeconds))
        }
      />
    </VideoWrapper>
  </StyledFlexColumnEndAlignContainer>
);

function InQueue(props) {
  const { t } = useTranslation();
  const [videoState, setVideoState] = useState({});
  const [currentVideoId, setCurrentVideoId] = useState(0);
  const { workspaceId } = useParams();
  const currentVideo = videoState[currentVideoId];

  const updateTimerCurrentVideo = (timerInSeconds) => {
    if (!timerInSeconds || timerInSeconds === 0) {
      return;
    }
    videoState[currentVideoId].timer = timerInSeconds;
    setVideoState({ ...videoState });
    localStorage.setItem(
      `workspace:${workspaceId}:in_queue:videoState`,
      JSON.stringify(
        Object.keys(videoState).reduce(
          (acc, key) => ({
            ...acc,
            [key]: {
              id: videoState[key].id,
              timer: videoState[key].timer || 0,
            },
          }),
          {}
        )
      )
    );
  };

  useEffect(() => {
    const localStorageTimersValue = getLocalStorageTimersValue(workspaceId);
    const state = initialValueVideoState.reduce((acc, item) => {
      const timer = localStorageTimersValue?.[item.id]?.timer || 0;
      return {
        ...acc,
        [`${item.id}`]: {
          ...item,
          timer,
          timerStart: timer,
        },
      };
    }, {});

    setVideoState(state);
  }, [workspaceId]);

  return (
    <FlexColumnGrowContainer>
      <MarginBottomDiv marginBottom="60px">
        <FlexJustifiedSpaceBetweenContainer>
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
          <StyledFlexCenteredAlignContainer>
            <AdministratorMenuWrapper>
              <AdministratorMenu variant="dark" />
            </AdministratorMenuWrapper>
            <SelectWorkspace />
          </StyledFlexCenteredAlignContainer>
        </FlexJustifiedSpaceBetweenContainer>
        <Divider />
      </MarginBottomDiv>
      <StyledFlexColumnContainer>
        <MarginBottomDiv marginBottom="20px">
          <PrimaryTitle noMargin size="big">
            {t('inqueue:title')}
          </PrimaryTitle>
          <MargedParagraphText color="dark060" noPadding weight="500">
            <Trans components={{ bold: <Bold /> }} i18nKey={'inqueue:text-explaination'} />
            <br />
            {t('inqueue:email-will-be-send')}
          </MargedParagraphText>
        </MarginBottomDiv>
        <RowContainer>
          <FlexColumnContainer>
            <MarginBottomDiv marginBottom="40px">
              <Bold>
                {t('inqueue:videos-title_interval', {
                  count: Object.keys(videoState).length,
                  postProcess: 'interval',
                })}
              </Bold>
            </MarginBottomDiv>
            <MarginBottomDiv marginBottom="20px">
              {Object.keys(videoState).map((key) => {
                const video = videoState[key];
                return (
                  <MarginBottomDiv key={video.id} marginBottom="25px">
                    <SelectVideoWrapper key={video.id} onClick={() => setCurrentVideoId(video.id)}>
                      <IconWrapper>
                        <VideoIcon type={video.iconType} />
                        <ViewedIndicatorIcon
                          type={
                            Math.floor((video.timer * 100) / video.duration) >= VIDEO_IS_WATCHED_AT
                              ? 'validated'
                              : 'not-validated'
                          }
                        />
                      </IconWrapper>
                      <VideoTitle selected={video.id === currentVideo.id}>
                        {t(`inqueue:videos.${video.title}`)}
                      </VideoTitle>
                      <FlexCenteredAlignJustifyEndContainer>
                        <ParagraphText color="dark060" noPadding weight="500">
                          {Math.floor(video.duration / 60)} {t('inqueue:timer-video')}
                        </ParagraphText>
                      </FlexCenteredAlignJustifyEndContainer>
                    </SelectVideoWrapper>
                    <ProgressBar errorMonoColor value={video.timer / video.duration} />
                  </MarginBottomDiv>
                );
              })}
            </MarginBottomDiv>
            <ButtonWrapper>
              <ContainedLinkButton to={t('common:links.contact-us')} weight="strong">
                {t('inqueue:talk-to-sales')}
              </ContainedLinkButton>
            </ButtonWrapper>
          </FlexColumnContainer>
          {currentVideo && (
            <VideoPlayerComponent
              currentVideo={currentVideo}
              updateTimerCurrentVideo={updateTimerCurrentVideo}
            />
          )}
        </RowContainer>
      </StyledFlexColumnContainer>
    </FlexColumnGrowContainer>
  );
}

export default InQueue;
