import LinearProgress from '@material-ui/core/LinearProgress';
import { lighten } from 'polished';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';

import { FlexContainer } from '@/components/_common';
import ColorComputer from '@/hoc/ColorComputer';

const LinearProgressDynamic = styled(
  ({
    progressBarColor,
    progressBarSecondaryColor,
    backgroundColor,
    isInfinity,
    small,
    ...props
  }) => <LinearProgress {...props} classes={{ bar: 'bar' }} />
)`
  && {
    width: 100%;
    border-radius: 15px;
    height: ${(props) => (props.height ? props.height : props.small ? '2px' : '3px')};
    background-color: ${(props) => props.theme.cssColors[props.backgroundColor ?? 'dark010']};
    .bar {
      border-radius: 15px;
      background: ${(props) => props.progressBarColor};
      background: linear-gradient(
        90deg,
        ${(props) => props.progressBarColor} 50%,
        ${(props) => props.progressBarSecondaryColor} 100%
      );
      background: ${(props) =>
        props.isInfinity &&
        `repeating-linear-gradient(-45deg, ${lighten(0.2, props.progressBarColor)},
        ${lighten(0.2, props.progressBarColor)} 2px,
        ${props.theme.cssColors[props.backgroundColor ?? 'dark010']} 2px,
        ${props.theme.cssColors[props.backgroundColor ?? 'dark010']} 4px
      )`};
    }
  }
`;

function ProgressBar(props) {
  let value = 0;

  if (props.value >= 1) {
    value = 100;
  } else if (props.value > 0) {
    value = Math.round(props.value * 100);
  }

  return (
    <ColorComputer
      errorMonoColor={props.errorMonoColor}
      successMonoColor={props.successMonoColor}
      value={value}
    >
      {({ color, secondaryColor }) => (
        <FlexContainer>
          <LinearProgressDynamic
            backgroundColor={props.backgroundColor}
            height={props.height}
            isInfinity={props.value === Infinity}
            progressBarColor={color}
            progressBarSecondaryColor={secondaryColor}
            small={props.small}
            value={value}
            variant="determinate"
          />
        </FlexContainer>
      )}
    </ColorComputer>
  );
}

ProgressBar.propTypes = {
  small: PropTypes.bool,
  value: PropTypes.number,
};

ProgressBar.defaultProps = {
  small: false,
  value: 0,
};

export default ProgressBar;
