import { createContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Tooltip } from 'semji-core/components/Tooltip';
import styled from 'styled-components/macro';

import { LOCATION } from '@/apis/semji/constants';
import useGetCurrentWorkspacePagesWithSpecificProperties from '@/apis/semji/pages/useGetCurrentWorkspacePagesWithSpecificProperties';
import usePostTopKeywordAnalysis from '@/apis/semji/topKeywords/usePostTopKeywordAnalysis';
import LaunchTour from '@/components/Badge/LaunchTour';
import StepCheckbox from '@/components/Checkbox/StepCheckBox';
import CreditLimitReachedDialog from '@/components/Dialog/CreditLimitReachedDialog';
import AICreditIcon from '@/components/icons/AICreditIcon';
import NewContentIcon from '@/components/icons/NewContentIcon';
import SemjiCreditIcon from '@/components/icons/SemjiCreditIcon';
import SemjiIcon from '@/components/icons/SemjiIcon';
import UpdatedContentIcon from '@/components/icons/UpdatedContentIcon';
import Stepper from '@/components/Stepper/Stepper';
import { ContentUtils } from '@/containers/Content/TinyMceComponents/Editor/hooks/useContent/content.utils';
import NewContentsCreation from '@/containers/Drafts/NewContents/Creation';
import UpdatedContentCreation from '@/containers/Drafts/UpdatedContents/Creation';
import UpdatedContentsIdentification from '@/containers/Drafts/UpdatedContents/Identification';
import useNullUser from '@/hooks/useNullUser';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import PageService from '@/services/Page';
import { showErrorSnackbar } from '@/store/actions/ui';
import { selectCurrentWorkspaceCountry } from '@/store/selectors/selectCurrentWorkspaceCountry';
import { selectCurrentWorkspaceWebsiteUrl } from '@/store/selectors/selectCurrentWorkspaceWebsiteUrl';
import { selectHasSearchConsole } from '@/store/selectors/selectHasSearchConsole';
import { selectRemainingAnalysisUnit } from '@/store/selectors/selectRemainingAnalysisUnit';
import { selectTotalAnalysisUnit } from '@/store/selectors/selectTotalAnalysisUnit';
import { SOURCE_DRAFT_CREATE } from '@/utils/analysis';
import {
  ANALYSIS_CREDITS_TYPE,
  NEW_DRAFTS_LIMIT_CREATION,
  URL_LIMIT_CREATION,
} from '@/utils/constants';
import { Log } from '@/utils/log/Log';
import { sortPagesByURLOrderSelection } from '@/utils/sorter';
import { isUrlAlreadyInDraftList, isUrlValidWithWebSiteUrl, isValidPageUrl } from '@/utils/url';

const FlexBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  height: 35px;
`;

const FlexItem = styled.div`
  display: flex;
`;

const StepBody = styled.div`
  display: flex;
  gap: 50px;
`;

const CREATION_MODE_NEW = '/new';
const CREATION_MODE_UPDATED = '/updated';
const NUMBER_OF_STEPS = {
  [CREATION_MODE_NEW]: 2,
  [CREATION_MODE_UPDATED]: 3,
};
export const NewDraftContext = createContext();

function CreateDrafts() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();
  const { organizationId, workspaceId } = useParams();
  const nullUser = useNullUser();

  const [isFetching, setIsFetching] = useState(false);

  const historyBackUrl = useSelector(
    (state) => state.workspaceRouteHistory || `/o/${organizationId}/w/${workspaceId}/planning`
  );
  const hasSearchConsole = useSelector(selectHasSearchConsole);
  const currentWorkspaceWebsiteUrl = useSelector(selectCurrentWorkspaceWebsiteUrl);
  const { countryName } = useSelector(selectCurrentWorkspaceCountry);
  const remainingAnalysisUnit = useSelector(selectRemainingAnalysisUnit);
  const totalAnalysisUnit = useSelector(selectTotalAnalysisUnit);
  const [activeStep, setActiveStep] = useState(0);
  const [creationMode, setCreationMode] = useState(null);
  const [existingDrafts, setExistingDrafts] = useState([{ loading: true }]);
  const [newDrafts, setNewDrafts] = useState([{ loading: true }]);
  const [draftsPages, setDraftsPages] = useState([]);
  const [creatingDrafts, setCreatingDrafts] = useState(false);
  const [ctaDisabledStatus, setCtaDisabledStatus] = useState(false);
  const [onError, setOnError] = useState(null);
  const [inputValidation, setInputValidation] = useState({
    errors: [],
    showError: false,
  });
  const [defaultStatusId, setDefaultStatusId] = useState(null);
  const [isCreditReachedDialogOpen, setIsCreditReachedDialogOpen] = useState(false);

  const { isFeatureEnabled: hasUnlimitedAnalysisCredits } = useOrganizationFeatureSet(
    'credits:analysis:has-unlimited-amount'
  );

  const [query] = useSearchParams();

  const pagesWithSpecificProperties = useGetCurrentWorkspacePagesWithSpecificProperties({
    filters: { 'url[contains]': '' },
    location: LOCATION.CREATE_DRAFTS,
    properties: ['id', 'url', 'draft', 'extra', 'new', 'inStock'],
  });
  const pages = pagesWithSpecificProperties.data.filter((p) =>
    isUrlValidWithWebSiteUrl(p.url, currentWorkspaceWebsiteUrl)
  );

  const topKeywordAnalysis = usePostTopKeywordAnalysis({
    onError: () => dispatch(showErrorSnackbar(t('common:error.default'))),
  });

  useEffect(() => {
    return () => pagesWithSpecificProperties.remove();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ERRORS = {
    API_ERROR: {
      error: t('drafts:create-drafts.error-unknwon'),
      id: 'API_ERROR',
    },
    EMPTY_LIST_ERROR: {
      error: t('drafts:create-drafts.error-no-url-invalid'),
      id: 'EMPTY_LIST_ERROR',
    },
    LIMIT_ERROR: {
      error: t('drafts:create-drafts.draft-creation-limited-to', { limit: URL_LIMIT_CREATION }),
      id: 'LIMIT_ERROR',
    },
    URL_ERROR: {
      error: t('drafts:create-drafts.error-url-invalid'),
      id: 'URL_ERROR',
    },
  };

  function getStep(index, mode) {
    if (index === 0) {
      return {
        step: '',
        subTitle: '',
        title: t('drafts:create-drafts.start-new-draft'),
        urlFragment: '',
      };
    }

    const steps = {
      [CREATION_MODE_NEW]: [
        {
          step: '',
          subTitle: (
            <LaunchTour data-intercom-target="product_tour_create_draft_new" withoutTooltip />
          ),
          title: t('drafts:create-drafts.start-creating-new-content'),
          urlFragment: `${CREATION_MODE_NEW}`,
        },
      ],
      [CREATION_MODE_UPDATED]: [
        {
          step: t('drafts:create-drafts.step-1'),
          subTitle: (
            <LaunchTour data-intercom-target="product_tour_create_draft_updated" withoutTooltip />
          ),
          title: t('drafts:create-drafts.start-optimizing-existing-content'),
          urlFragment: `${CREATION_MODE_UPDATED}`,
        },
        {
          step: t('drafts:create-drafts.step-2'),
          subTitle: '',
          title: t('drafts:create-drafts.select-a-focus-keyword'),
          urlFragment: `${CREATION_MODE_UPDATED}#keywords-selection`,
        },
      ],
    };

    return steps[mode]?.[index - 1] || {};
  }

  useEffect(() => {
    const { urlFragment } = getStep(activeStep, creationMode);
    navigate(`${location.pathname}${urlFragment}?${query.toString()}`, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  const _pageService = new PageService(null, workspaceId);

  async function createPageDraft(
    { pageId, assignedTo, dueDate, contentStatus, title, folderId },
    isUpdatedContent
  ) {
    const _specificPageService = new PageService(pageId, workspaceId);

    // https://gitlab.rvip.fr/semji/semji/-/issues/2786
    // New content => started draft & planned | Updated content => not started draft & planned
    const html = isUpdatedContent ? null : ContentUtils.createEmptyHTML();

    return await _specificPageService.saveNewDraft({
      assignedTo,
      contentStatus,
      dueDate,
      folderId,
      html,
      title,
    });
  }

  async function createPageTopKeyword(pageId, topKeyword) {
    const createdTopKeyword = await new PageService(pageId, workspaceId).addTopKeyword(topKeyword);
    return createdTopKeyword;
  }

  async function setPageTopKeyword(pageId, topKeyword) {
    const createdTopKeyword = await new PageService(pageId, workspaceId).setFocusTopKeyword(
      topKeyword
    );
    return createdTopKeyword;
  }

  async function startAnalysis(focusTopKeywordId) {
    if (!hasUnlimitedAnalysisCredits && remainingAnalysisUnit < 1) {
      return;
    }

    await topKeywordAnalysis.mutateAsync({
      data: {
        source: SOURCE_DRAFT_CREATE,
      },
      topKeywordIdList: [focusTopKeywordId],
    });
  }

  function areUrlsListValid(listDrafts) {
    return listDrafts.every(
      ({ url }) =>
        url === '' ||
        !isUrlValidWithWebSiteUrl(url, currentWorkspaceWebsiteUrl) ||
        !isValidPageUrl(url).value ||
        !isUrlAlreadyInDraftList(url, listDrafts)
    );
  }

  async function importContentsFromUrls() {
    const filteredExistingDrafts = existingDrafts.filter(
      (draft) =>
        isUrlValidWithWebSiteUrl(draft.url, currentWorkspaceWebsiteUrl) &&
        isValidPageUrl(draft.url).value &&
        !pages?.find((p) => p.url === draft.url)?.draft
    );

    if (filteredExistingDrafts.length >= 1) {
      const response = await _pageService.importFromUrls(
        filteredExistingDrafts.map((draft) => draft.url)
      );
      const sortedResponse = sortPagesByURLOrderSelection(
        filteredExistingDrafts.map((elem) => elem.url),
        response.pages
      );
      // pages should be filtered on pages without drafts ??
      return sortedResponse;
    }

    return [];
  }

  async function validateDraftsCreationFromUrls() {
    const ids = draftsPages.filter((page) => !page.redirectionUrl).map((page) => page['@id']);

    if (!hasUnlimitedAnalysisCredits && remainingAnalysisUnit < ids.length) {
      return setIsCreditReachedDialogOpen(true);
    }

    try {
      setCreatingDrafts(true);
      setOnError(null);

      if (ids.length > 0) {
        await _pageService.importFromStock(ids);
        const contents = await Promise.all(
          draftsPages.map((draftPage) => {
            const d = existingDrafts.find((existingDraft) => existingDraft.url === draftPage.url);
            const assignedTo = d.userId === nullUser.id ? null : `/users/${d.userId}`;
            const contentStatus = `/content_statuses/${d.statusId}`;
            const folderId = d.folderId;
            return createPageDraft(
              {
                assignedTo,
                contentStatus,
                dueDate: d.dueDate,
                folderId,
                pageId: draftPage.id,
                title: draftPage.title,
              },
              true
            );
          })
        );

        setCreatingDrafts(false);

        if (contents.length === 1 && contents[0]?.id && contents[0]?.page?.id) {
          navigate(`/o/${organizationId}/w/${workspaceId}/p/${contents[0]?.page?.id}/create`, {
            state: {
              triggerRefreshDataAuto: true,
            },
          });
        } else {
          goBackToPlanning();
        }
      } else {
        setCreatingDrafts(false);
        setOnError(ERRORS.EMPTY_LIST_ERROR.id);
      }
    } catch (error) {
      setCreatingDrafts(false);
      setOnError(ERRORS.API_ERROR.id);
      Log.error(error);
    }
  }

  async function validateDraftsCreationFocusKeywordAndTitle() {
    const draftList = filterEmptyDrafts(newDrafts);

    if (!hasUnlimitedAnalysisCredits && remainingAnalysisUnit < draftList.length) {
      return setIsCreditReachedDialogOpen(true);
    }

    try {
      setCreatingDrafts(true);
      setOnError(null);

      const contents = await Promise.all(
        draftList.map((d) => {
          const assignedTo = d.userId === nullUser.id ? null : `/users/${d.userId}`;
          const contentStatus = `/content_statuses/${d.statusId}`;
          const folderId = d.folderId;
          return createPageDraft({
            assignedTo,
            contentStatus,
            dueDate: d.dueDate,
            folderId,
            pageId: null,
            title: d.title,
          });
        })
      );
      // and in // we create the list of top keywords !== ''
      const topKeywordsPromises = contents.map((content, index) => {
        if (draftList[index].focusKeyword) {
          return createPageTopKeyword(content.page.id, draftList[index].focusKeyword);
        }
        return Promise.resolve();
      });
      const topKeywords = await Promise.all(topKeywordsPromises);
      const pageFocusKeywords = contents.map((content, index) => {
        if (draftList[index].focusKeyword) {
          return setPageTopKeyword(content.page.id, topKeywords[index]['@id']);
        }
        return Promise.resolve();
      });

      await Promise.all(pageFocusKeywords);

      for (const keyword of topKeywords) {
        if (keyword) {
          if (contents.length === 1) {
            await startAnalysis(keyword.id);
          } else {
            startAnalysis(keyword.id);
          }
        }
      }

      setCreatingDrafts(false);

      if (contents.length === 1 && contents[0]?.id && contents[0]?.page?.id) {
        navigate(
          `/o/${organizationId}/w/${workspaceId}/p/${contents[0]?.page?.id}/content/${contents[0]?.id}`
        );
      } else {
        goBackToPlanning();
      }
    } catch (error) {
      setOnError(ERRORS.API_ERROR.id);
      Log.error(error);
    }
  }

  function handleCancel() {
    navigate(historyBackUrl, { replace: true });
  }

  function goBackToPlanning() {
    navigate(`/o/${organizationId}/w/${workspaceId}/planning`, { replace: true });
  }

  async function handleValidate() {
    if (inputValidation.errors.filter((e) => e !== undefined).length > 0) {
      setInputValidation({ ...inputValidation, showError: true });
      return;
    }

    setCtaDisabledStatus(true);

    if (creationMode === CREATION_MODE_UPDATED) {
      await validateDraftsCreationFromUrls();
    } else if (creationMode === CREATION_MODE_NEW) {
      await validateDraftsCreationFocusKeywordAndTitle();
    } else {
      handleCancel();
    }

    setCtaDisabledStatus(false);
  }

  async function handleNext() {
    setCtaDisabledStatus(true);
    setOnError(null);

    if (creationMode === CREATION_MODE_UPDATED && activeStep === 1) {
      const pages = await importContentsFromUrls();

      if (pages.length === 0) {
        setCtaDisabledStatus(false);
        setOnError(ERRORS.URL_ERROR.id);
      } else {
        setDraftsPages(pages);
        setCtaDisabledStatus(false);
        setActiveStep(activeStep + 1);
      }
    } else {
      setCtaDisabledStatus(false);
      setActiveStep(activeStep + 1);
    }
  }

  function handlePrevious() {
    if (1 === activeStep) {
      navigate(`/o/${organizationId}/w/${workspaceId}/planning/create-drafts?${query.toString()}`);
    }

    if (activeStep !== 2) {
      setExistingDrafts([{ loading: true }]);
    }
    setActiveStep(activeStep - 1);
    setNewDrafts([{ loading: true }]);
    setDraftsPages([]);
    setCreatingDrafts(false);
    setCtaDisabledStatus(false);
    setOnError(null);
  }

  function filterEmptyDrafts(drafts = []) {
    return drafts.filter(
      ({ focusKeyword, title, dueDate, statusId, url }) =>
        !!focusKeyword || !!title || !!dueDate || statusId !== defaultStatusId || !!url
    );
  }

  function renderStep() {
    if (activeStep === 0) {
      return (
        <StepBody>
          <StepCheckbox
            checked={creationMode === CREATION_MODE_NEW}
            data-intercom-target="new_content_card_checkbox"
            description={t('drafts:create-drafts.new-content-description')}
            title={t('drafts:create-drafts.new-content-title')}
            onClick={() => setCreationMode(CREATION_MODE_NEW)}
          >
            <NewContentIcon />
          </StepCheckbox>
          <StepCheckbox
            checked={creationMode === CREATION_MODE_UPDATED}
            data-intercom-target="existing_content_card_checkbox"
            description={t('drafts:create-drafts.update-content-description')}
            title={t('drafts:create-drafts.update-content-title')}
            onClick={() => setCreationMode(CREATION_MODE_UPDATED)}
          >
            <UpdatedContentIcon />
          </StepCheckbox>
        </StepBody>
      );
    }

    if (activeStep === 1 && creationMode === CREATION_MODE_UPDATED) {
      return (
        <UpdatedContentsIdentification
          automaticallyAddRow={false}
          currentWorkspaceWebsiteUrl={currentWorkspaceWebsiteUrl}
          existingDrafts={existingDrafts}
          isLimitAchieved={filterEmptyDrafts(existingDrafts).length > URL_LIMIT_CREATION}
          setDefaultStatusId={setDefaultStatusId}
          setExistingDrafts={(d) => {
            if (!areUrlsListValid(d)) {
              setCtaDisabledStatus(true);
              setOnError(ERRORS.URL_ERROR.id);
              // 100 drafts maximum
            } else if (filterEmptyDrafts(d).length > URL_LIMIT_CREATION) {
              setCtaDisabledStatus(true);
              setOnError(ERRORS.LIMIT_ERROR.id);
            } else {
              setCtaDisabledStatus(false);
              setOnError(null);
            }
            setExistingDrafts(d);
          }}
        />
      );
    }

    if (activeStep === 1 && creationMode === CREATION_MODE_NEW) {
      return (
        <NewDraftContext.Provider value={newDrafts}>
          <NewContentsCreation
            // 50 drafts maximum
            automaticallyAddRow={false}
            inputValidation={inputValidation}
            isLimitAchieved={filterEmptyDrafts(newDrafts).length > NEW_DRAFTS_LIMIT_CREATION}
            loading={newDrafts[0].loading}
            setDefaultStatusId={setDefaultStatusId}
            setInputValidation={setInputValidation}
            setNewDrafts={setNewDrafts}
            workspaceCountryName={countryName}
            workspaceId={workspaceId}
          />
        </NewDraftContext.Provider>
      );
    }

    if (activeStep === 2 && creationMode === CREATION_MODE_UPDATED) {
      return (
        <UpdatedContentCreation
          creatingDrafts={creatingDrafts}
          draftsPages={draftsPages}
          hasSearchConsole={hasSearchConsole}
          setCtaDisabledStatus={setCtaDisabledStatus}
          setIsFetching={setIsFetching}
          updateDraftPagesTitles={(pagesetIsFetchings = []) => {
            const updatedDraftPages = draftsPages.map((draftPage) => ({
              ...draftPage,
              title: pages.find((page) => page.id === draftPage.id)?.title,
            }));
            setDraftsPages(updatedDraftPages);
          }}
          workspaceCountryName={countryName}
        />
      );
    }

    return null;
  }

  function isNextDisabled() {
    if (!creationMode || isFetching) {
      return true;
    }

    if (activeStep === 0) {
      return false;
    }

    return ctaDisabledStatus || creatingDrafts;
  }

  function getNextLabel() {
    if (activeStep !== NUMBER_OF_STEPS[creationMode] - 1) {
      return t('drafts:create-drafts.next');
    }

    if (creatingDrafts) {
      return t('drafts:create-drafts.creating');
    }

    if (creationMode === CREATION_MODE_UPDATED) {
      const filteredDrafts = existingDrafts?.filter(
        (draft) =>
          draft?.url?.length &&
          isValidPageUrl(draft?.url).value &&
          !pages?.find((p) => p.url === draft?.url)?.draft
      ).length;

      const analysisInterval = t('drafts:create-drafts.analysis_interval', {
        count: filteredDrafts,
        postProcess: 'interval',
      });

      const draftsInterval = t('drafts:create-drafts.drafts_interval', {
        count: filteredDrafts,
        postProcess: 'interval',
      });

      const focusKeywordInterval = t('drafts:create-drafts.focus-keywords_interval', {
        count: filteredDrafts,
        postProcess: 'interval',
      });

      return (
        <Tooltip
          data-intercom-target="create_drafts_primary_action_btn"
          title={t('drafts:create-drafts.analysis-of-fk-will-be-performed', {
            analysisInterval,
            count: filteredDrafts,
            focusKeywordInterval,
          })}
        >
          <div className="flex items-center gap-2">
            <SemjiIcon />
            <span>
              {t('drafts:create-drafts.create', {
                count: filteredDrafts,
                draftsInterval,
              })}
            </span>
          </div>
        </Tooltip>
      );
    }

    const numberOfDrafts = filterEmptyDrafts(newDrafts).length;

    if (creationMode === CREATION_MODE_NEW) {
      const numberOfFocusKeyword = newDrafts.filter((draft) => !!draft.focusKeyword).length;
      const analysisInterval = t('drafts:create-drafts.analysis_interval', {
        count: numberOfFocusKeyword,
        postProcess: 'interval',
      });

      const focusKeywordInterval = t('drafts:create-drafts.focus-keywords_interval', {
        count: numberOfFocusKeyword,
        postProcess: 'interval',
      });

      const draftsInterval = t('drafts:create-drafts.drafts_interval', {
        count: newDrafts.length,
        postProcess: 'interval',
      });

      return (
        <Tooltip
          className="p-2"
          description={
            !hasUnlimitedAnalysisCredits && (
              <div className="flex items-center gap-1">
                <AICreditIcon />
                <span className="font-bold">
                  {t('drafts:credits.analysis-credits-needed', {
                    count: numberOfDrafts,
                    postProcess: 'interval',
                  })}
                </span>{' '}
                <span className="text-dark-040 italic">
                  (
                  {t('drafts:credits.key_interval', {
                    count: remainingAnalysisUnit,
                    postProcess: 'interval',
                  })}
                  )
                </span>
              </div>
            )
          }
          isFrame={false}
          placement="top"
          title={t('drafts:create-drafts.analysis-of-fk-will-be-performed', {
            analysisInterval,
            count: numberOfFocusKeyword,
            focusKeywordInterval,
          })}
        >
          <div className="flex items-center gap-2">
            {!hasUnlimitedAnalysisCredits && <SemjiIcon />}
            <span>
              {t('drafts:create-drafts.create-number-of-draft', {
                count: newDrafts.length,
                draftsInterval,
              })}
            </span>
          </div>
        </Tooltip>
      );
    }

    return t('drafts:create-drafts.create');
  }

  function getInfoMessage() {
    if (activeStep === 0 || hasUnlimitedAnalysisCredits) {
      return '';
    }

    let numberOfFocusKeyword = 0;

    if (creationMode === CREATION_MODE_UPDATED) {
      numberOfFocusKeyword = existingDrafts.filter((draft) =>
        isUrlValidWithWebSiteUrl(draft.url, currentWorkspaceWebsiteUrl)
      ).length;
    }

    if (creationMode === CREATION_MODE_NEW) {
      numberOfFocusKeyword = newDrafts.filter((draft) => !!draft.focusKeyword).length;
    }

    if (numberOfFocusKeyword > 0) {
      const analysisInterval = t('drafts:create-drafts.analysis_interval', {
        count: totalAnalysisUnit,
        postProcess: 'interval',
      });

      return (
        <FlexItem>
          <SemjiCreditIcon />
          {t('drafts:create-drafts.amount-credits-left', {
            analysisInterval,
            remainingAnalysisUnit,
            totalAnalysisUnit,
          })}
        </FlexItem>
      );
    }

    return '';
  }

  function getErrorMessage() {
    if (onError) {
      return <FlexItem>{ERRORS[onError]?.error || ''}</FlexItem>;
    }
  }

  return (
    <>
      <Stepper
        handleCancel={handleCancel}
        handleNext={activeStep !== NUMBER_OF_STEPS[creationMode] - 1 ? handleNext : handleValidate}
        handlePrevious={handlePrevious}
        header={getStep(activeStep, creationMode)}
        info={
          <FlexBox>
            {getInfoMessage()}
            {getErrorMessage()}
          </FlexBox>
        }
        infoOnError={onError}
        isNextDisabled={isNextDisabled()}
        nextLabel={getNextLabel()}
        previousDisabled={activeStep === 0}
      >
        {renderStep()}
      </Stepper>
      {isCreditReachedDialogOpen && (
        <CreditLimitReachedDialog
          creditType={ANALYSIS_CREDITS_TYPE}
          isOpen
          onClose={() => setIsCreditReachedDialogOpen(false)}
        />
      )}
    </>
  );
}

export default CreateDrafts;
