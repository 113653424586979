import './ContainedFavIcon.scss';

import React from 'react';
import { FavIcon } from 'semji-core/components/FavIcon';

import { DEFAULT_FAVICON } from '@/utils/constants';
import { getFaviconFromUrl } from '@/utils/favicon';

import { ContainedFavIconProps } from './ContainedFavIcon.types';

export default function ContainedFavIcon({ url, size, className }: ContainedFavIconProps) {
  const apiRootUrl = import.meta.env.VITE_REACT_APP_SEMJI_API_ROOT_URL;

  return (
    <div
      className={`competitors-contained-favicon competitors-contained-favicon--${size} ${className}`}
    >
      <FavIcon
        defaultFavicon={DEFAULT_FAVICON}
        disableClick
        displayLink={false}
        src={getFaviconFromUrl(url, apiRootUrl)}
      />
    </div>
  );
}
