import Menu from '@material-ui/core/Menu';
import Popover from '@material-ui/core/Popover';
import React from 'react';
import styled from 'styled-components/macro';

import { STYLED_CARD_PADDING } from '@/utils/constants';

export const FlexContainer = styled.div`
  display: flex;
`;
export const FlexWrapContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const FlexAlignEndContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;
export const FlexVerticalAlignedContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ gap }) => gap};
  max-height: 35px;
`;
export const FlexGrowContainer = styled(FlexContainer)`
  flex: 1;
  height: 100%;
`;
export const FlexGrowMaxWidthContainer = styled(FlexGrowContainer)`
  max-width: ${(props) => props.maxWidth ?? '100%'};
`;
export const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
export const FlexRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;
export const FlexRowWrapContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-wrap: wrap;
`;
export const FlexColumnGrowContainer = styled(FlexColumnContainer)`
  height: 100%;
`;
export const FlexColumnCenteredAlignContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
`;
export const FlexGrowColumnCenteredContainer = styled(FlexColumnGrowContainer)`
  align-items: center;
  justify-content: center;
`;
export const FlexRowCenteredContainer = styled(FlexRowContainer)`
  align-items: center;
  justify-content: center;
`;
export const FlexColumnStartAlignContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex: 1;
`;
export const FlexColumnEndAlignContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  flex: 1;
`;
export const FlexCenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  max-height: ${({ maxHeight }) => maxHeight};
  overflow: ${({ overflow }) => overflow};
`;
export const FlexCenteredNoGrowContainer = styled.div`
  display: flex;
  justify-content: center;
`;
export const FlexJustifiedBetweenContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`;
export const FlexJustifiedSpaceBetweenContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const FlexCenteredAlignContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;
export const FlexCenteredAlignJustifyEndContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  gap: ${({ gap }) => gap};
  margin-top: ${({ marginTop }) => marginTop};
`;
export const FlexJustifiedBetweenAndAlignCenterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`;
export const FlexAlignedStartContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;
export const FlexJustifyStartContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;
export const StyledCard = styled.div`
  display: flex;
  flex: 1;
  max-height: 100%;
  overflow: hidden;
  font-size: 0.9em;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  background: white;
`;
export const TableStyledCard = styled.div`
  overflow: hidden;
  padding: ${(props) => (props.disablePadding ? '0' : `0  ${STYLED_CARD_PADDING}px`)};
  font-size: 0.85rem;
  box-shadow: ${(props) =>
    props.mode === 'transparent' ? 'none' : '0 0 30px rgba(0, 0, 0, 0.08)'};
  border-radius: 4px;
  background: ${(props) => (props.mode === 'transparent' ? 'transparent' : 'white')};
`;
export const TableFlexStyledCard = styled(TableStyledCard)`
  display: flex;
  flex: 1;
  font-size: 1.08em;
  box-shadow: ${(props) =>
    props.mode === 'transparent' ? 'none' : '0 0 30px rgba(0, 0, 0, 0.08)'};
`;
export const CaretUp = styled.span`
  width: 0;
  height: 0;
  display: inline-block;
  border-left: 0.4em solid transparent;
  border-right: 0.4em solid transparent;
  border-top: 0px solid transparent;
  border-bottom: 0.5em solid transparent;
  border-bottom-color: ${(props) => props.theme.colors.green};
`;
export const CaretDown = styled.span`
  width: 0;
  height: 0;
  display: inline-block;
  border-left: 0.4em solid transparent;
  border-right: 0.4em solid transparent;
  border-top: 0.5em solid transparent;
  border-bottom: 0px solid transparent;
  border-top-color: ${(props) => props.theme.colors.red};
`;
export const DefaultMenu = (props) => (
  <Menu
    anchorOrigin={{
      horizontal: 'center',
      vertical: 'bottom',
    }}
    elevation={2}
    getContentAnchorEl={null}
    style={{ marginTop: '5px' }}
    transformOrigin={{
      horizontal: 'center',
      vertical: 'top',
    }}
    {...props}
  />
);
export const DefaultPopover = (props) => (
  <Popover
    anchorOrigin={{
      horizontal: 'center',
      vertical: 'bottom',
    }}
    elevation={2}
    getContentAnchorEl={null}
    style={{ marginTop: '5px' }}
    transformOrigin={{
      horizontal: 'center',
      vertical: 'top',
    }}
    {...props}
  />
);
export const ColoredDot = styled.div`
  background-color: ${(props) => props.color};
  height: ${(props) => props.height || '13px'};
  width: ${(props) => props.width || '13px'};
  min-width: ${(props) => props.minWidth || '13px'};
  margin: 8px;
  border-radius: 50%;
`;
