import styled from 'styled-components/macro';

export const Cell = styled.div`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: ${({ noWhiteSpace }) => !noWhiteSpace && 'nowrap'};
  font-family: ${(props) => props.theme.textCss.fonts.main};
  color: ${(props) =>
    props.color === 'currentColor' ? props.color : props.theme.cssColors[props.color || 'dark100']};
  font-size: ${(props) => props.theme.textCss.sizes[props.size || 'default']};
  font-weight: ${(props) => props.theme.textCss.weights[props.weight || 'normal']};
  text-align: ${(props) => props.align};
  margin: ${({ margin }) => margin};
  padding: ${({ noPadding }) => (noPadding ? '0' : '0 30px')};
  width: ${({ width }) => width};
  max-width: ${({ maxWidth }) => maxWidth};
  flex: ${({ fullWidth }) => fullWidth && 1};
  align-self: ${({ alignSelf }) => alignSelf};
  cursor: ${({ cursor }) => cursor};
  line-height: ${({ lineHeight }) => lineHeight || '24px'};
  strong {
    font-weight: 500;
  }
`;

export const EllipsisCell = styled(Cell)`
  && {
    white-space: initial;
    display: -webkit-box;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: ${({ lineClamp = 2 }) => lineClamp};
    -webkit-box-orient: vertical;
  }
`;

export const NoEllipsisCell = styled(Cell)`
  && {
    display: ${({ noFlex }) => (noFlex ? 'initial' : 'flex')};
    overflow: unset;
    white-space: initial;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ color, theme }) => color || theme.colors.lightGrey};
`;
