import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'semji-core/components/Dropdown';
import { FavIcon } from 'semji-core/components/FavIcon';

import CustomInput from '@/containers/Competitors/Configuration/Listing/CustomInput';
import { commonDomainSuffixes } from '@/containers/Competitors/utils/constants';
import { getCompetitorColor } from '@/containers/Competitors/utils/utils';
import { DEFAULT_FAVICON } from '@/utils/constants';
import { getFaviconFromUrl } from '@/utils/favicon';
import {
  formatUrl,
  formatUrlForDisplay,
  getOriginFromUrl,
  hasGoodUrlOrDomainPattern,
} from '@/utils/url';

import { CompetitorAutoCompleteProps } from './AutoComplete.types';

export default function CompetitorAutoComplete({
  autoFocus = false,
  competitor,
  competitorSuggestions,
  index,
  onChange: handleChange,
  onBlur: handleBlur,
}: CompetitorAutoCompleteProps) {
  const { t } = useTranslation();
  const apiRootUrl = import.meta.env.VITE_REACT_APP_SEMJI_API_ROOT_URL;
  const dropDrownActionsRef = useRef({
    handleClose: () => null,
    handleOpen: () => null,
  });

  function handleFocusInput() {
    // dropDrownActionsRef.current.handleOpen();
  }

  function handleKeyDownInput(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      handleChange(
        getSuggestedCompetitors(competitor.url.toLocaleLowerCase())[0].url || competitor.url,
        index
      );
      dropDrownActionsRef.current.handleClose();
    }
  }

  useEffect(() => {
    if (autoFocus && !competitor.url && competitorSuggestions.length > 0) {
      dropDrownActionsRef.current.handleOpen();
    }
  }, [competitorSuggestions, competitor.url, autoFocus]);

  function getSuggestedCompetitors(typing: string) {
    const suggestedCompetitors = competitorSuggestions
      .filter((competitorSuggestion) =>
        competitorSuggestion.url.includes(formatUrlForDisplay(getOriginFromUrl(typing)))
      )
      .sort((a, b) => a.rank - b.rank);
    if (suggestedCompetitors.length < 1) {
      if (!typing) {
        return [];
      }
      // remove the last character of the typing if it's a dot
      if (typing[typing.length - 1] === '.') {
        typing = typing.slice(0, -1);
      }
      if (hasGoodUrlOrDomainPattern(typing)) {
        return [
          {
            '@id': 'suggested-competitor-0',
            url: typing,
          },
        ];
      }
      return commonDomainSuffixes.map((suffix, index) => ({
        '@id': 'suggested-competitor-' + index,
        url: `${typing}${suffix}`,
      }));
    }
    // If exact typing is not in the list, add it to the top
    if (
      hasGoodUrlOrDomainPattern(typing) &&
      !suggestedCompetitors.find((competitor) => competitor.url === typing)
    ) {
      suggestedCompetitors.unshift({
        '@id': 'suggested-competitor-0',
        rank: 100,
        url: typing,
      });
    }
    return suggestedCompetitors;
  }

  return (
    <Dropdown
      actionsRef={dropDrownActionsRef}
      anchorElement={
        <CustomInput
          autoFocus={autoFocus}
          className="competitors-configuration-listing__input"
          color={getCompetitorColor(competitor.url)?.color}
          error={competitor.error}
          fullWidth
          placeholder={t('competitors:configuration.add-domain-or-url')}
          value={competitor.url}
          onBlur={(e) => handleBlur(e.target.value, index)}
          onChange={(e) => handleChange(e.target.value, index)}
          onFocus={handleFocusInput}
          onKeyDown={handleKeyDownInput}
        />
      }
      isFrame={false}
      offsetValue={0}
      querySelectorListener="#root"
    >
      <div className="competitors-configuration-listing__url-dropdown-wrapper">
        <span className="competitors-configuration-listing__url-dropdown-wrapper__title">
          {t('competitors:configuration.suggested-competitors')}
        </span>
        <div className="competitors-configuration-listing__url-dropdown-wrapper__listing">
          {getSuggestedCompetitors(competitor.url.toLocaleLowerCase()).map((competitor) => (
            <div
              key={competitor['@id']}
              className="competitors-configuration-listing__url-dropdown-wrapper__listing__row"
              onClick={() => {
                handleBlur(competitor.url, index);
                dropDrownActionsRef.current.handleClose();
              }}
            >
              <FavIcon
                className="competitors-configuration-listing__url-dropdown-wrapper__listing__row__favico"
                defaultFavicon={DEFAULT_FAVICON}
                disableClick
                displayLink={false}
                src={getFaviconFromUrl(formatUrl(competitor.url), apiRootUrl)}
              />
              {competitor.url}
            </div>
          ))}
        </div>
      </div>
    </Dropdown>
  );
}
