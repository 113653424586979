import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SortDirection from 'react-virtualized/dist/es/Table/SortDirection';
import styled from 'styled-components/macro';

import useGetCurrentWorkspaceContentIdeaSearches from '@/apis/semji/contentIdeas/useGetCurrentWorkspaceContentIdeaSearches';
import EmptyStateContentIdea from '@/assets/images/empty-state-content-idea.png';
import { Cell } from '@/components/Pages/ContentIdeas/common';
import { getColumns } from '@/components/Pages/ContentIdeas/Menu/constant';
import Table from '@/components/Table/Table';

const SIZE_OF_A_ROW = 50.12;

const HistoryWrapper = styled.div`
  display: flex;
  background-color: white;
  flex-direction: column;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
  min-height: 415px;
  height: fit-content;
  width: 100%;
  flex: 1;
`;
const TableWrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 0 23px 30px 45px;
  min-height: ${({ size }) => size};
`;
const EmptyStateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding: 0 50px;
`;
const StyledTable = styled(Table)`
  && {
    margin-bottom: 0;
  }
`;

function History({ setContentIdeaSearchToDelete }) {
  const { t } = useTranslation();
  const columns = getColumns(setContentIdeaSearchToDelete);
  const contentIdeaSearches = useGetCurrentWorkspaceContentIdeaSearches();

  useEffect(() => {
    return () => contentIdeaSearches.remove();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HistoryWrapper>
      <Cell color="dark060" margin="30px 40px 15px 55px" noPadding weight="strong">
        {t(`content-ideas:menu.history.history`)}
      </Cell>
      {!!contentIdeaSearches.data?.length ? (
        <TableWrapper size={`${(contentIdeaSearches.data?.length + 1) * SIZE_OF_A_ROW}px`}>
          <StyledTable
            columns={columns}
            defaultSort="createdAt"
            defaultSortDirection={SortDirection.DESC}
            disablePadding
            headerHeight={50}
            mode="transparent"
            rowHeight={50}
            rows={contentIdeaSearches.data || []}
          />
        </TableWrapper>
      ) : (
        <EmptyStateWrapper>
          <img alt={t(`content-ideas:menu.history.empty-state-alt`)} src={EmptyStateContentIdea} />
          <Cell
            align="center"
            color="dark080"
            margin="40px 0 0 0"
            noPadding
            noWhiteSpace
            weight="medium"
          >
            {t(`content-ideas:menu.history.empty-state-text`)}
          </Cell>
        </EmptyStateWrapper>
      )}
    </HistoryWrapper>
  );
}

export default History;
