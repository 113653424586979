import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { RelativeLoader } from '@/components/Loader/Loader';
import WarningMessage from '@/components/Message/WarningMessage';
import { DefaultLink } from '@/components/Navigation/Link';
import { DisableableWrapper } from '@/components/Optimization/Instruction';
import Optimization from '@/components/Optimization/Optimization';
import { Bold } from '@/containers/Content/EditorComponents/Nodes';
import DetailsBase from '@/containers/Content/SidePanel/Components/Optimization/Details/DetailsBase';
import LinkDetails from '@/containers/Content/SidePanel/Components/Optimization/Details/LinkDetails';
import Link from '@/containers/Content/SidePanel/Components/Optimization/Section/IncomingLinks/Link';
import {
  disableMainOptimization,
  enableMainOptimization,
  refreshLinksToOptimization,
} from '@/store/actionsCreator/report';

const DraftNotMarkAsPublished = () => (
  <Trans
    components={{
      bold: <Bold />,
      warningMessage: <WarningMessage />,
    }}
    i18nKey={`content:side-panel-components.optimization.section.incoming-links.draft-not-mark-as-published`}
  />
);

class IncomingLinks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabledLinks: [],
      enabledLinks: [],
      showDetails: false,
    };

    this.closeDetails = this.closeDetails.bind(this);
  }

  componentDidMount() {
    this.setStateRecommendations();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.recommendationsIds !== this.props.recommendationsIds) {
      this.setStateRecommendations();
    }
  }

  setStateRecommendations() {
    const { allRecommendations, recommendationsIds } = this.props;
    let enabledLinks = [];
    let disabledLinks = [];

    recommendationsIds.forEach((recommendationsId) => {
      if (allRecommendations[recommendationsId].disabled) {
        disabledLinks.push(recommendationsId);
      } else {
        enabledLinks.push(recommendationsId);
      }
    });

    this.setState({ disabledLinks, enabledLinks });
  }

  disableRecommendation = (key) =>
    this.props.disableMainOptimization(
      key,
      this.props.focusTopKeywordId,
      this.props.html,
      this.props.contentTitle
    );

  enableRecommendation = (key) =>
    this.props.enableMainOptimization(
      key,
      this.props.focusTopKeywordId,
      this.props.html,
      this.props.contentTitle
    );

  toggleLink = (key, disabled) => {
    let { enabledLinks, disabledLinks } = this.state;

    if (disabled) {
      disabledLinks = [...disabledLinks, key];
      enabledLinks = enabledLinks.filter((enabledLink) => enabledLink !== key);
    } else {
      enabledLinks = [...enabledLinks, key];
      disabledLinks = disabledLinks.filter((enabledLink) => enabledLink !== key);
    }

    this.setState({ disabledLinks, enabledLinks });
  };

  renderDisplayedLinks(loading) {
    if (loading) {
      return <RelativeLoader />;
    }

    const links = [...this.state.enabledLinks, ...this.state.disabledLinks];

    return links.map((linkId, index) => (
      <Link
        key={`${linkId}_${index}`}
        focusTopKeywordId={this.props.focusTopKeywordId}
        id={linkId}
        mainId={this.props.id}
        onToggleLink={this.toggleLink}
      />
    ));
  }

  refreshAction = async () => {
    if (this.state.loading) {
      return;
    }

    this.setState({ loading: true });

    try {
      await this.props.refreshLinksToOptimization(
        this.props.focusTopKeywordId,
        this.props.html,
        this.props.contentTitle
      );
      this.setState({ loading: false });
    } catch (error) {}
  };

  closeDetails() {
    this.setState({ showDetails: false });
  }

  render() {
    const {
      actualLinksCount,
      allRecommendations,
      contentTitle,
      disabled,
      disableMainOptimization,
      enableMainOptimization,
      expectedLinksCount,
      focusTopKeywordId,
      html,
      keyword,
      lockedLinksCount,
      newContentIsNotPublished,
      pageId,
      recommendationsIds,
      refreshLinksToOptimization,
      score,
      ready,
      t,
      weight,
      ...props
    } = this.props;
    const completed = 1 === score;
    const isEmpty =
      ready &&
      !this.state.loading &&
      [...this.state.enabledLinks, ...this.state.disabledLinks].length === 0;

    return (
      <>
        <Optimization
          completed={completed} // TODO: find where it's used (doesn't exist in props 🤷‍♂️)
          description={
            <Trans
              components={{
                extLink: (
                  <DefaultLink
                    color="dark040"
                    decoration
                    isExternal
                    noDynamicFontSize
                    noPadding
                    size="xsm"
                    to={t('common:links.help-incoming-links')}
                    weight="medium"
                  />
                ),
              }}
              i18nKey="content:side-panel-components.optimization.section.incoming-links.description"
            />
          }
          disabled={disabled}
          disableRecommendation={this.disableRecommendation}
          enableRecommendation={this.enableRecommendation}
          helpCenterLink={t('common:links.help-incoming-links')}
          infoMessage={t('components:optimization.optimization.no-incoming-links-message', {
            keyword,
          })}
          infoText={t(
            'content:side-panel-components.optimization.section.incoming-links.info-text'
          )}
          // set the state of showDetails to display the modal of details
          // temporary disabled
          instructions={[
            <DisableableWrapper key="comingLink" disabled={disabled}>
              {newContentIsNotPublished && !isEmpty && <DraftNotMarkAsPublished />}
              {this.renderDisplayedLinks(this.state.loading)}
            </DisableableWrapper>,
          ]}
          isEmpty={isEmpty}
          isReady={ready}
          loaderRows={3}
          points={Math.round((weight - weight * score) * 100)}
          refreshAction={this.refreshAction}
          score={score}
          scorer={`${actualLinksCount}/${expectedLinksCount}`}
          scorerTooltipText={t(
            'content:side-panel-components.optimization.section.incoming-links.scorer-tooltip-text',
            { expectedLinksCount }
          )}
          // Disabled, see this comment: https://gitlab.rvip.fr/semji/semji/-/issues/2158#note_84560
          showDetailsAction={undefined}
          title={t('content:side-panel-components.optimization.section.incoming-links.title')}
          {...props}
        />
        <DetailsBase
          description={t(
            'content:side-panel-components.optimization.section.incoming-links.details-description',
            { keyword }
          )}
          show={this.state.showDetails}
          subTitle={t(
            'content:side-panel-components.optimization.section.incoming-links.details-subtitle'
          )}
          title={t('content:side-panel-components.optimization.section.incoming-links.title')}
          onClose={this.closeDetails}
        >
          {[...this.state.enabledLinks, ...this.state.disabledLinks].map((linkId) => (
            <LinkDetails key={linkId} displayDescription id={linkId} />
          ))}
        </DetailsBase>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    actualLinksCount: state.report.recommendations[props.id]?.data?.actualLinksCount,
    allRecommendations: state.report.recommendations,
    contentTitle: state.content.title,
    disabled: state.report.recommendations[props.id]?.disabled,
    expectedLinksCount: state.report.recommendations[props.id]?.data?.expectedLinksCount,
    html: state.content.html,
    lockedLinksCount: state.report.recommendations[props.id]?.data?.lockedLinksCount,
    ready: state.report.recommendations[props.id]?.ready,
    recommendationsIds: state.report.recommendations[props.id]?.recommendationsIds,
    score: state.report.recommendations[props.id]?.score,
    weight: state.report.recommendations[props.id]?.weight,
  };
};

export default withTranslation()(
  connect(mapStateToProps, {
    disableMainOptimization,
    enableMainOptimization,
    refreshLinksToOptimization,
  })(IncomingLinks)
);
