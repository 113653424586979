import React from 'react';

import SvgWrapper from './SvgWrapper';

const UserIcon = (props) => (
  <SvgWrapper {...props} viewBox="0 0 17 17">
    <path d="M17 16.488C16.937 13.801 14.222 11.489 10.479 10.879V9.505C10.971 9.032 11.321 8.298 11.55 7.672C11.882 7.506 12.174 7.136 12.344 6.639C12.582 5.951 12.49 5.316 12.138 5.01C12.166 4.772 12.184 4.529 12.153 4.287C12.074 3.624 12.218 3.249 12.347 2.919C12.453 2.642 12.576 2.328 12.453 1.974C12.011 0.701 10.726 0 8.83504 0L8.57104 0.005C7.25804 0.052 6.86404 0.605 6.60004 1.12C6.56704 1.182 6.52304 1.266 6.52304 1.271C4.81104 1.424 4.82604 2.84 4.83904 3.978L4.84204 4.347C4.84204 4.552 4.85104 4.766 4.86804 4.986C4.44304 5.286 4.36404 5.991 4.68904 6.723C4.87404 7.138 5.14104 7.452 5.43804 7.615C5.68104 8.289 6.06304 9.085 6.61704 9.58V10.863C2.81904 11.452 0.0630371 13.77 3.71095e-05 16.488L-0.0119629 17H17.011L17 16.488ZM1.05404 16C1.44604 13.906 3.91304 12.179 7.17604 11.796L7.61704 11.744V9.078L7.40104 8.928C7.00804 8.656 6.61004 7.981 6.31104 7.077L6.22804 6.796L5.93404 6.745C5.88104 6.726 5.72604 6.592 5.60404 6.317C5.52904 6.149 5.50004 6.005 5.49204 5.902L6.00204 6.045L5.90604 5.296C5.86404 4.966 5.84204 4.645 5.84204 4.346L5.83904 3.966C5.82404 2.625 5.89004 2.332 6.61204 2.267C7.15704 2.219 7.36404 1.818 7.48804 1.578C7.63804 1.286 7.76804 1.035 8.60804 1.004L8.83504 1C9.66404 1 11.114 1.169 11.504 2.282C11.504 2.325 11.452 2.459 11.414 2.557C11.269 2.931 11.05 3.496 11.16 4.41C11.184 4.598 11.153 4.834 11.12 5.085L11.031 5.89L11.472 5.842C11.48 5.946 11.468 6.111 11.397 6.314C11.3 6.603 11.155 6.752 11.16 6.768H10.8L10.686 7.11C10.403 7.963 10.036 8.607 9.67704 8.878L9.47904 9.028V11.754L9.91704 11.809C13.128 12.21 15.558 13.932 15.947 16.001H1.05404V16Z" />
  </SvgWrapper>
);
export default UserIcon;
