import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import TableFilters from '@/components/Filters/TableFilters';
import CardIcon from '@/components/icons/CardIcon';
import CursorIcon from '@/components/icons/CursorIcon';
import FunnelIcon from '@/components/icons/FunnelIcon';
import MoneyIcon from '@/components/icons/MoneyIcon';
import { useViewsGoalsConfig } from '@/hooks/useViewsGoalsConfig';
import { selectHasAnalytics } from '@/store/selectors/selectHasAnalytics';
import { selectHasSearchConsole } from '@/store/selectors/selectHasSearchConsole';
import { DEFAULT_PRIORITY_SCORE, OPPORTUNITIES_TAB } from '@/utils/filter/constants';

function getPagesCount(items) {
  const pageCountByAlgorithm = items.reduce(
    (acc, item) => {
      const { extra = {} } = item || {};
      const {
        monthlyClicks = 0,
        monthlyConversions = 0,
        monthlyTransactions = 0,
        monthlyRevenue = 0,
        monthlyGoal1Completions = 0,
        monthlyGoal2Completions = 0,
        monthlyGoal3Completions = 0,
      } = extra;

      if (monthlyClicks > 0) {
        ++acc.numberOfOpportunitiesBasedOnClicks;
      }
      if (monthlyConversions > 0) {
        ++acc.numberOfOpportunitiesBasedOnConversions;
      }
      if (monthlyTransactions > 0) {
        ++acc.numberOfOpportunitiesBasedOnTransactions;
      }
      if (monthlyRevenue > 0) {
        ++acc.numberOfOpportunitiesBasedOnRevenue;
      }
      if (monthlyGoal1Completions > 0) {
        ++acc.numberOfOpportunitiesBasedOnGoal1Completions;
      }
      if (monthlyGoal2Completions > 0) {
        ++acc.numberOfOpportunitiesBasedOnGoal2Completions;
      }
      if (monthlyGoal3Completions > 0) {
        ++acc.numberOfOpportunitiesBasedOnGoal3Completions;
      }

      return acc;
    },
    {
      numberOfOpportunitiesBasedOnClicks: 0,
      numberOfOpportunitiesBasedOnConversions: 0,
      numberOfOpportunitiesBasedOnGoal1Completions: 0,
      numberOfOpportunitiesBasedOnGoal2Completions: 0,
      numberOfOpportunitiesBasedOnGoal3Completions: 0,
      numberOfOpportunitiesBasedOnRevenue: 0,
      numberOfOpportunitiesBasedOnTransactions: 0,
    }
  );

  return {
    ...pageCountByAlgorithm,
    numberOfPages: items.length,
  };
}

function ViewPanel({ resetContentsIds, pages }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [query, setQuery] = useSearchParams();
  const [view, setView] = useState();
  const [priorityScore, setPriorityScore] = useState();
  const hasSearchConsole = useSelector(selectHasSearchConsole);
  const hasAnalytics = useSelector(selectHasAnalytics);

  useEffect(() => {
    const tab = query.getAll('tab') ?? OPPORTUNITIES_TAB;
    const priorityScore = query.getAll('priorityScore') ?? DEFAULT_PRIORITY_SCORE;

    setView(tab[0]);
    setPriorityScore(priorityScore[0]);
  }, [query]);

  const handleFilterChange = (href) => {
    if (typeof href === 'string') {
      navigate(href);
    }

    if (typeof href === 'object') {
      for (const [key, value] of Object.entries(href)) {
        if (!!value) {
          query.set(key, value);
        } else {
          query.delete(key);
        }
      }

      setQuery(query);
    }

    resetContentsIds();
  };

  const {
    numberOfOpportunitiesBasedOnClicks,
    numberOfOpportunitiesBasedOnConversions,
    numberOfOpportunitiesBasedOnTransactions,
    numberOfOpportunitiesBasedOnRevenue,
    numberOfOpportunitiesBasedOnGoal1Completions,
    numberOfOpportunitiesBasedOnGoal2Completions,
    numberOfOpportunitiesBasedOnGoal3Completions,
    numberOfPages,
  } = useMemo(() => getPagesCount(pages), [pages]);

  const conversionsGoals = useViewsGoalsConfig([
    numberOfOpportunitiesBasedOnGoal1Completions,
    numberOfOpportunitiesBasedOnGoal2Completions,
    numberOfOpportunitiesBasedOnGoal3Completions,
  ]);

  return (
    <TableFilters
      selectedFilter={priorityScore ?? view}
      tableFilters={[
        {
          attribute: OPPORTUNITIES_TAB,
          collapsible: true,
          filters: [
            {
              attribute: 'clicks',
              count: hasSearchConsole ? numberOfOpportunitiesBasedOnClicks : 0,
              featureFlag: 'pages:priority-score:has-access-to-clicks',
              href: { priorityScore: 'clicks', tab: OPPORTUNITIES_TAB },
              icon: <CursorIcon opacity={0.6} />,
              label: t('listing:pages-list.view-panel.clicks'),
            },
            {
              attribute: 'conversions',
              count: hasAnalytics ? numberOfOpportunitiesBasedOnConversions : 0,
              featureFlag: 'pages:priority-score:has-access-to-conversions',
              href: { priorityScore: 'conversions', tab: OPPORTUNITIES_TAB },
              icon: <FunnelIcon opacity={0.6} />,
              label: t('listing:pages-list.view-panel.all-conversions'),
            },
            ...conversionsGoals,
            {
              attribute: 'transactions',
              count: hasAnalytics ? numberOfOpportunitiesBasedOnTransactions : 0,
              featureFlag: 'pages:priority-score:has-access-to-transactions',
              href: { priorityScore: 'transactions', tab: OPPORTUNITIES_TAB },
              icon: <CardIcon opacity={0.6} />,
              label: t('listing:pages-list.view-panel.transactions'),
            },
            {
              attribute: 'revenue',
              count: hasAnalytics ? numberOfOpportunitiesBasedOnRevenue : 0,
              featureFlag: 'pages:priority-score:has-access-to-revenue',
              href: { priorityScore: 'revenue', tab: OPPORTUNITIES_TAB },
              icon: <MoneyIcon opacity={0.6} />,
              label: t('listing:pages-list.view-panel.revenues'),
            },
          ],
          label: t('listing:pages-list.view-panel.opportunities'),
        },
        {
          attribute: 'all',
          collapsible: false,
          count: numberOfPages,
          href: { priorityScore: undefined, tab: 'all' },
          label: t('listing:pages-list.view-panel.all-pages'),
        },
      ]}
      onClick={handleFilterChange}
    />
  );
}

ViewPanel.propTypes = {
  pages: PropTypes.array,
  resetContentsIds: PropTypes.func,
};

export default ViewPanel;
