import styled from 'styled-components/macro';

const Column = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: ${({ halign }) =>
    halign === 'middle' ? 'center' : halign === 'right' ? 'flex-end' : 'flex-start'};
  min-width: 0;
  line-height: initial;
  gap: ${({ gap }) => gap};

  & > * {
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${({ color, theme }) =>
      'light' === color ? theme.cssColors.dark060 : theme.cssColors.dark100};
  }

  &&& em {
    font-style: initial;
    font-weight: 500;
    color: ${({ color, theme }) =>
      'light' === color ? theme.cssColors.dark060 : theme.cssColors.dark100};
  }
`;

export default Column;
