import './PlanRestriction.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavigationButton } from 'semji-core/components/Button';

import UnlockImage from '@/assets/images/unlock-image.svg';
import { SUPPORT_ADDRESS_MAIL } from '@/utils/constants';

function PlanRestriction({
  title,
  description,
}: {
  title: string;
  description: string;
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="plan_restriction">
      <img alt="plan restriction" src={UnlockImage} />
      <div className="plan_restriction__body">
        <div className="plan_restriction__body__title">{title}</div>
        <div className="plan_restriction__body__description">{description}</div>
      </div>
      <NavigationButton href={`mailto:${SUPPORT_ADDRESS_MAIL}`}>
        {t('components:dialog.dialog-information-template.button-text')}
      </NavigationButton>
    </div>
  );
}

export default PlanRestriction;
