import { useEffect, useState } from 'react';

export default function useBasicStateDialog({
  initialState = false,
  onOpen,
  onClose,
  onToggle,
} = {}) {
  const [isOpen, setIsOpen] = useState(initialState);

  useEffect(() => {
    if (isOpen) {
      onOpen?.();
    } else {
      onClose?.();
    }

    onToggle?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  function open() {
    setIsOpen(true);
  }

  function close() {
    setIsOpen(false);
  }

  function toggle() {
    setIsOpen((state) => !state);
  }

  return {
    close,
    isOpen,
    open,
    toggle,
  };
}
