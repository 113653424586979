import './Modify.scss';

import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { DialogModal } from 'semji-core/components/DialogModal';
import { PlacementEnum } from 'semji-core/components/Dropdown';
import {
  ENUM_MENU_SELECT_POSITION,
  ENUM_MENU_SELECT_TYPE,
  MenuSelectDropdown,
} from 'semji-core/components/MenuSelect';
import { DownIcon } from 'semji-core/icons/DownIcon';

import usePostCompetitorTopPage from '@/apis/semji/competitors/usePostCompetitorTopPages';
import { FilterOption } from '@/components/DynamicFilters';
import {
  BRANDED_TRAFFIC,
  getBrandTrafficOptions,
} from '@/containers/Competitors/components/HeaderFilters/Filters/BrandTrafficFilter/const';
import { getCategoriesOptions } from '@/containers/Competitors/components/HeaderFilters/Filters/CategoryFilter/const';
import { getFunnelOptions } from '@/containers/Competitors/components/HeaderFilters/Filters/FunnelFilter/const';
import {
  ModifyProps,
  TOP_PAGE_PROPERTIES,
  TOP_PAGE_UPDATABLE_FIELDS,
} from '@/containers/Competitors/DataExplorer/BulkActions/Modify/Modify.types';
import { showSuccessSnackbar } from '@/store/actions/ui';

const MODIFY_DIALOG_MODAL_ID = 'modify-bulk-dialog-modal-id';
const MODIFY_DIALOG_MODAL_SELECTOR = `#${MODIFY_DIALOG_MODAL_ID}`;

export default function Modify({
  isOpen,
  cancel,
  filters,
  personas,
  clusters,
  competitorReportId,
  refetchTopPages,
  gridSelectionHook,
  nbPages,
}: ModifyProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const categories: FilterOption[] = getCategoriesOptions();
  const funnels: FilterOption[] = getFunnelOptions();
  const brandTrafficTypes: FilterOption[] = getBrandTrafficOptions();

  const dropDrownActionsPropertyRef = useRef({
    handleClose: () => null,
    handleOpen: () => null,
  });
  const dropDrownActionsValueRef = useRef({
    handleClose: () => null,
    handleOpen: () => null,
  });

  const [propertyToUpdate, setPropertyToUpdate] = React.useState<{
    label: string;
    value: TOP_PAGE_UPDATABLE_FIELDS;
  } | null>(null);
  const [propertyNewValue, setPropertyNewValue] = React.useState<{
    label: string;
    value: string;
  } | null>(null);

  const postCompetitorTopPage = usePostCompetitorTopPage({
    onSuccess: () => {
      refetchTopPages();
      dispatch(showSuccessSnackbar(t('common:labels.successfully-updated')));
      cancel();
    },
    reportId: competitorReportId,
  });

  useEffect(() => {
    if (!propertyToUpdate) return;

    const newValue = getValues(propertyToUpdate.value)[0];

    if (!newValue) return;

    setPropertyNewValue({
      label: newValue.label,
      value: newValue.value,
    });
  }, [propertyToUpdate]);

  function confirm() {
    if (!propertyToUpdate || !propertyNewValue) return;

    postCompetitorTopPage.mutate({
      filters,
      [propertyToUpdate.value]: propertyNewValue.value,
    });
  }

  if (!isOpen) return null;

  function getValues(field: TOP_PAGE_UPDATABLE_FIELDS) {
    switch (field) {
      case TOP_PAGE_UPDATABLE_FIELDS.CLUSTER:
        return clusters.map((cluster) => ({ label: cluster.name, value: cluster.id }));
      case TOP_PAGE_UPDATABLE_FIELDS.PERSONA:
        return personas.map((persona) => ({ label: persona.name, value: persona.id }));
      case TOP_PAGE_UPDATABLE_FIELDS.CATEGORY:
        return categories.map((category) => ({ label: category.label, value: category.id }));
      case TOP_PAGE_UPDATABLE_FIELDS.FUNNEL:
        return funnels.map((funnel) => ({ label: funnel.label, value: funnel.id }));
      case TOP_PAGE_UPDATABLE_FIELDS.BRANDED:
        return brandTrafficTypes.map((brandTrafficType) => ({
          label: brandTrafficType.label,
          value: brandTrafficType.id === BRANDED_TRAFFIC,
        }));
      default:
        return [];
    }
  }

  return (
    <DialogModal
      cancelAction={cancel}
      cancelLabel={t('common:labels.cancel')}
      canClickOutsideToClose={true}
      closable={true}
      confirmAction={confirm}
      confirmLabel={t('common:labels.update')}
      description=""
      error={postCompetitorTopPage.isError ? t('common:error.default') : ''}
      id={MODIFY_DIALOG_MODAL_ID}
      isConfirmDisabled={!propertyNewValue || postCompetitorTopPage.isLoading}
      isOpen={true}
      title={t(`competitors:data-explorer.modify-modal.title`, {
        count: gridSelectionHook?.isAllSelected ? nbPages : gridSelectionHook.selection?.length,
      })} // todo
      onClose={cancel}
    >
      <div className="data-explorer-bulk-actions-modify">
        <div className="data-explorer-bulk-actions-modify__property">
          <div className="data-explorer-bulk-actions-modify__property__label">
            {t(`competitors:data-explorer.modify-modal.property-to-update`)}
          </div>
          <MenuSelectDropdown
            actionsRef={dropDrownActionsPropertyRef}
            anchorElement={
              <div className="data-explorer-bulk-actions-modify__property__placeholder">
                {propertyToUpdate?.label
                  ? t(propertyToUpdate.label)
                  : t(`competitors:data-explorer.modify-modal.select-property-to-update`)}
                <DownIcon />
              </div>
            }
            dropdownPlacement={PlacementEnum.DROPDOWN_PLACEMENT_BOTTOM_END}
            isFrame={false}
            items={TOP_PAGE_PROPERTIES.map((item) => ({
              id: item.value,
              label: t(item.label),
              onSelect: () => {
                setPropertyToUpdate(item);
                dropDrownActionsPropertyRef.current.handleClose();
              },
              position: ENUM_MENU_SELECT_POSITION.DEFAULT,
              type: ENUM_MENU_SELECT_TYPE.DEFAULT,
              value: item.value,
            })).sort((a, b) => a.label.localeCompare(b.label))}
            itemSelected={propertyToUpdate?.value ?? ''}
            querySelectorListener={MODIFY_DIALOG_MODAL_SELECTOR}
            width="650px"
          />
        </div>
        {null !== propertyToUpdate && (
          <div className="data-explorer-bulk-actions-modify__property">
            <div className="data-explorer-bulk-actions-modify__property__label">
              {t(propertyToUpdate.label)}
            </div>
            <MenuSelectDropdown
              actionsRef={dropDrownActionsValueRef}
              anchorElement={
                <div className="data-explorer-bulk-actions-modify__property__placeholder">
                  {propertyNewValue?.label ??
                    t(`competitors:data-explorer.modify-modal.property-not-yet-available`)}
                  <DownIcon />
                </div>
              }
              dropdownPlacement={PlacementEnum.DROPDOWN_PLACEMENT_BOTTOM_END}
              isFrame={false}
              items={getValues(propertyToUpdate?.value)
                .map((item) => ({
                  id: item.value,
                  label: item.label,
                  onSelect: () => {
                    setPropertyNewValue(item);
                    dropDrownActionsValueRef.current.handleClose();
                  },
                  position: ENUM_MENU_SELECT_POSITION.DEFAULT,
                  type: ENUM_MENU_SELECT_TYPE.DEFAULT,
                  value: item.value,
                }))
                .sort((a, b) => a.label.localeCompare(b.label))}
              itemSelected={propertyNewValue?.value ?? ''}
              querySelectorListener={MODIFY_DIALOG_MODAL_SELECTOR}
              titleTooltipCloseIcon={t('components:input-search.tooltip.title')}
              width="650px"
            />
          </div>
        )}
      </div>
    </DialogModal>
  );
}
