import React from 'react';

import SvgWrapper from './SvgWrapper';

function ValidatedIcon({ withCircle, color, fill, ...props }) {
  return (
    <SvgWrapper height="28" viewBox="0 0 28 28" width="28" {...props}>
      {withCircle ? <circle cx="14" cy="14" fill={fill || 'white'} r="14" /> : null}
      <path
        d="M14 25C20.0751 25 25 20.0751 25 14C25 7.92487 20.0751 3 14 3C7.92487 3 3 7.92487 3 14C3 20.0751 7.92487 25 14 25Z"
        fill={color || '#FF4D64'}
      />
      <path
        d="M18.8125 9.18739L12.625 18.6748L9.1875 15.6499L9.60007 15.0999L12.4875 17.5749L18.2626 8.7749L18.8125 9.18739Z"
        fill={fill || 'white'}
      />
    </SvgWrapper>
  );
}

export default ValidatedIcon;
