import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import EmptyStateOutline from '@/components/icons/EmptyStateOutline';
import { DefaultLink } from '@/components/Navigation/Link';
import { TitleFive, TitleThree } from '@/components/Text/Title';
import Flex from '@/design-system/components/Flex';

const Container = styled.div`
  height: 70%;
  padding: 0 15px;
`;

const StyledTitleFive = styled(TitleFive)`
  line-height: 20px;
`;

const StyledTitleThree = styled(TitleThree)`
  line-height: 26px;
  margin-bottom: 16px;
`;

const EmptyState = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Flex
        alignItems="center"
        flex={1}
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <EmptyStateOutline height="64" size="11em" viewBox="0 0 174 64" width="174" />
        <StyledTitleThree align="center" weight="strong">
          {t('components:outline.empty.subtitle')}
        </StyledTitleThree>
        <StyledTitleFive align="center" color="dark060" weight="medium">
          {t('components:outline.empty.description')}
        </StyledTitleFive>
        <DefaultLink
          color="dark060"
          decoration
          isExternal
          to={t('components:outline.empty.help-center-link')}
          weight="medium"
        >
          {t('components:outline.empty.learn-more')}
        </DefaultLink>
      </Flex>
    </Container>
  );
};

export default EmptyState;
