import { useCallback } from 'react';

import { PAGES_FK } from '@/components/FocusKeyword/constants';
import FlatLoader, { DEFAULT_LOADER_HEIGHT, LoaderWrapper } from '@/components/Loader/FlatLoader';
import FocusKeywordSelectorCell from '@/components/Table/Cell/FocusKeywordSelectorCell';
import NotAcknowledgedCell from '@/components/Table/Cell/NotAcknowledgedCell';
import { SOURCE_PLANNING_LIST } from '@/utils/analysis';

export function FocusKeywordCell({
  loading,
  pageFocusTopKeyword,
  pageId,
  contentScore,
  id,
  hasSearchConsole,
  workspaceCountryName,
  triggerAnalysis,
  dataIntercomTarget,
}) {
  const onFKUpdate = useCallback(
    (focusKeyword) => {
      if (!focusKeyword.analyzed) {
        triggerAnalysis(
          [
            {
              contentScore,
              id,
              pageFocusTopKeyword: {
                id: focusKeyword.id,
              },
            },
          ],
          SOURCE_PLANNING_LIST
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id]
  );

  if (loading) {
    return (
      <LoaderWrapper height={DEFAULT_LOADER_HEIGHT} width={150}>
        <FlatLoader />
      </LoaderWrapper>
    );
  }

  if (pageFocusTopKeyword) {
    return (
      <FocusKeywordSelectorCell
        key={pageFocusTopKeyword.id}
        data-intercom-target={dataIntercomTarget}
        focusTopKeyword={pageFocusTopKeyword}
        hasSearchConsole={hasSearchConsole}
        pageId={pageId}
        variant={PAGES_FK}
        workspaceCountryName={workspaceCountryName}
        onUpdate={onFKUpdate}
      />
    );
  }

  return <NotAcknowledgedCell />;
}
