import { findByUser as findOrganizationAccessByUser } from '@/services/OrganizationAccesses';
import { findMe } from '@/services/User';
import { findByUser as findWorkspaceAccessByUser } from '@/services/WorkspaceAccesses';
import { FETCH_USER_SUCCESS } from '@/store/actions/actionTypes';

export const fetchAuthenticatedUser = () => async (dispatch) => {
  const response = await findMe();

  if (200 === response.status) {
    const user = response.data;
    const [organizationAccesses, workspaceAccesses] = await Promise.all([
      findOrganizationAccessByUser(user.id),
      findWorkspaceAccessByUser(user.id),
    ]);

    dispatch({
      type: FETCH_USER_SUCCESS,
      user: {
        ...user,
        organizationAccesses: organizationAccesses.data['hydra:member'],
        userLoggedIn: true,
        workspaceAccesses: workspaceAccesses.data['hydra:member'],
      },
    });
  }
};
