import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FolderSelect } from 'semji-core/components/FolderSelect';
import styled from 'styled-components/macro';

import DatePicker from '@/components/DatePicker';
import { DEFAULT_FOLDER_GENERAL } from '@/components/Select/FolderSelect/constants';
import {
  BOOL_BUTTON,
  DATE_PICKER,
  FOLDER,
  KNOWN_BUTTON,
  SELECT_LIST,
  UNKNOWN_BUTTON,
} from '@/utils/filter/constants';

const StyledSelect = styled(Select)`
  && {
    min-width: 196px;
  }
`;

function FolderSelectInitial({
  handleFolderChange,
  folderId,
  folders,
  createNewFolder,
  isCreationDisabled,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    handleFolderChange(folderId);
  }, []);
  return (
    <FolderSelect
      createNewFolder={createNewFolder}
      folders={folders?.data}
      isCreationDisabled={isCreationDisabled}
      isFrame={false}
      setValue={handleFolderChange}
      translations={{
        button: t('components:select.folder-select.create-folder.label-button'),
        cancel: t('components:select.folder-select.create-folder.cancel'),
        create_folder: t('components:select.folder-select.folder-select.label-create-folder'),
        folder_general: t('components:select.folder-select.label-folder-general'),
        label_no_parent: t('components:filters.folder-filters-panel.label-no-parent'),
        loading: t('components:select.folder-select.create-folder.label-loading'),
        placeHolder: t('components:select.folder-select.create-folder.input-placeholder'),
        search_placeholder: t('components:select.folder-select.folder-select.search-placeholder'),
        title: t('components:select.folder-select.create-folder.title'),
        tooltip_disabled: t('components:select.folder-select.create-folder.tooltip-disabled'),
      }}
      value={folderId}
      withFolderCreation
    />
  );
}

function FilterInputRevealer(props) {
  const { t } = useTranslation();
  const [date, setDate] = useState(props.value.value);
  const [folderId, setFolderId] = useState(props.value.value || DEFAULT_FOLDER_GENERAL.id);

  const type = props.menuItems.find(
    (elem) =>
      props.checkedValue.comparison === elem.comparison &&
      (props.checkedValue.labelKey === elem.label || props.checkedValue.labelKey === elem.labelKey)
  ).type;

  function renderValue(selected) {
    const valueKey = selected ? props.selectList.find((s) => s.value === selected)?.labelKey : '';
    return t(valueKey);
  }

  function onChange(event) {
    props.onChangeValue(event.target.value);
  }

  function handleDateChange(date) {
    setDate(date);
    props.onChangeValue(date);
  }

  function handleFolderChange(value) {
    setFolderId(value);
    props.onChangeValue(value);
  }

  function onKeyDown(event) {
    if (event.keyCode === 13 && !props.error) {
      props.handleDone();
      event.preventDefault();
    }
  }

  switch (type) {
    case SELECT_LIST:
      return (
        <div>
          <StyledSelect
            renderValue={renderValue}
            value={props.value.value || ''}
            onChange={onChange}
          >
            {props.selectList.map(({ label, value, htmlLabel }) => {
              return (
                <MenuItem key={value} value={value}>
                  <span dangerouslySetInnerHTML={{ __html: htmlLabel ?? label }} />
                </MenuItem>
              );
            })}
          </StyledSelect>
        </div>
      );
    case DATE_PICKER:
      return (
        <div>
          <DatePicker dueDate={date} handleChange={handleDateChange} inputMode />
        </div>
      );
    case BOOL_BUTTON:
      // the BOOL_BUTTON Type is a null button that set the value to true once the comparison method is checked
      return null;
    case KNOWN_BUTTON:
    case UNKNOWN_BUTTON:
      return null;
    case FOLDER:
      return (
        <FolderSelectInitial
          createNewFolder={props?.createNewFolder}
          folderId={folderId}
          folders={props?.folders}
          handleFolderChange={handleFolderChange}
          isCreationDisabled={props?.isCreationDisabled}
        />
      );
    default:
      return (
        <div>
          <Input
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            value={props.value.value || ''}
            onChange={onChange}
            onKeyDown={onKeyDown}
          />
        </div>
      );
  }
}

export default FilterInputRevealer;
