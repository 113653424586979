export const sortKeywordsByTrafficAndSearchVolumes = (keywords) => {
  const analyzedKeyword = keywords
    .filter((keyword) => keyword.analyzed)
    .sort((a, b) => {
      if (a.trafficValue - b.trafficValue < 0) return 1;
      if (a.trafficValue - b.trafficValue > 0) return -1;
      return b.searchVolumeValue - a.searchVolumeValue;
    });

  const suggestedKeyword = keywords
    .filter((keyword) => !keyword.analyzed)
    .sort((a, b) => {
      if (a.trafficValue - b.trafficValue < 0) return 1;
      if (a.trafficValue - b.trafficValue > 0) return -1;
      return b.searchVolumeValue - a.searchVolumeValue;
    });
  return [...analyzedKeyword, ...suggestedKeyword];
};
