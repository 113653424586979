import { getExtraKeyFromAlgorithm } from '@/utils/page';

import { OPPORTUNITIES_TAB } from './constants';

export default function filterPagesByView(pages, tab, algorithm) {
  if (OPPORTUNITIES_TAB === tab) {
    const extraKey = getExtraKeyFromAlgorithm(algorithm);

    return pages.filter((page) => page.extra[extraKey] > 0);
  }

  return [...pages];
}
