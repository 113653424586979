import styled from 'styled-components/macro';

import CloseIcon from '@/components/icons/CloseIcon';
import CopyIcon from '@/components/icons/CopyIcon';
import Input from '@/components/Input/Input';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import Flex from '@/design-system/components/Flex';

export const Wrapper = styled.div`
  padding: 32px;
`;
export const VersionWrapper = styled(Flex)`
  margin-top: 20px;
  color: ${({ theme }) => theme.cssColors.dark060};
`;
export const Title = styled.h1`
  font-family: ${({ theme }) => theme.textCss.fonts.main};
  color: ${({ theme }) => theme.cssColors.dark100};
  font-size: ${({ theme }) => theme.textCss.sizes.lg};
  line-height: ${({ theme }) => theme.textCss.sizes.xxl};
  margin-bottom: ${({ isOwnerOrMember }) => !isOwnerOrMember && '15px'};
`;
export const Text = styled.div`
  margin-top: 10px;
  line-height: ${({ theme }) => theme.textCss.sizes.lg};
  color: ${({ theme }) => theme.cssColors.dark060};
`;
export const StyledInput = styled(Input)`
  && {
    margin: 10px 15px 0 0;
    && input {
      padding: 10px 0 7px;
      text-overflow: ellipsis;
    }
  }
`;
export const StyledCopyIcon = styled(CopyIcon)`
  && {
    cursor: pointer;
    font-size: ${({ theme }) => theme.textCss.sizes.md};
    fill: ${({ theme }) => theme.cssColors.dark060};
  }
`;
export const Tooltip = styled(TooltipComponent)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.cssColors.dark080};
  cursor: pointer;
`;
export const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.cssColors.dark060};
`;
