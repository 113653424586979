import { AggregatedMetrics, ReportMetricKey } from '@/containers/Report/utils/types';

export enum ENUM_REPORT_TITLE_VARIANT {
  Default = 'default',
  Medium = 'medium',
  Small = 'small',
}

export interface ReportTitleProps {
  currentMetricKey: ReportMetricKey;
  aggregatedPreviousMetrics: AggregatedMetrics;
  aggregatedMetrics: AggregatedMetrics;
  currentCount: number;
  comparisonPeriodLabel: string;
  isNoData?: boolean;
  metricStackingType?: string;
  handleMetricStackingTypeChange?: (value: string) => void;
  currentMetricsState: any[];
  activeSubTab: string;
  handleSelectItem?: (selected: { title: string; value: string }) => void;
  SelectList?: { title: string; value: string }[];
  variant: ENUM_REPORT_TITLE_VARIANT;
}
