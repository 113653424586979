import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';

import { LOCATION, SCOPE_ORGANIZATION } from '@/apis/semji/constants';
import useGetCurrentOrganization from '@/apis/semji/organization/useGetOrganization';
import { DEFAULT_BACKGROUND, NO_MENU_LAYOUT } from '@/components/Layout/Layout';
import { LayoutLoader } from '@/components/Loader/Loader';
import CreateWorkspace from '@/containers/CreateWorkspace';
import MaxAllowedWorkspacesReached from '@/containers/Errors/MaxAllowedWorkspacesReached';
import WorkspaceAccessBlocked from '@/containers/Errors/WorkspaceAccessBlocked';
import LayoutWrapper from '@/containers/Layout/Layout';
import Settings from '@/containers/Settings';
import DefaultWorkspace from '@/containers/Workspace/DefaultWorkspace';
import HubWorkspace from '@/containers/Workspace/HubWorkspace';
import Workspace from '@/containers/Workspace/Workspace';
import Can from '@/hoc/Can';
import useApiConfigurations from '@/hooks/useApiConfigurations';
import { useMixpanelTrackEvent } from '@/hooks/useMixpanelTrackEvent';
import useOrganizationFeatureSet, {
  COMPETITORS__IS_ENABLED,
} from '@/hooks/useOrganizationFeatureSet';
import { init as initIntercom } from '@/services/Intercom';
import {
  changeDefaultOrganizationId,
  removeDefaultOrganizationId,
  setCurrentOrganizationEditableData,
} from '@/store/actions/organization';
import { fetchWorkspaces } from '@/store/actions/workspace';
import { fetchOrganization } from '@/store/actionsCreator/organization';
import selectOrganizationPlan from '@/store/selectors/selectOrganizationPlan';
import { SESSION_START } from '@/utils/3rdParty/Mixpanel/constants';
import { register } from '@/utils/3rdParty/Mixpanel/MixPanel';
import {
  COMPETITORS_ENABLED,
  RECURSIVE_CALLS_REFETCH_FREQUENCY_MS,
} from '@/utils/configurations/constants';
import { SECTIONS } from '@/utils/log/constants';
import { Log } from '@/utils/log/Log';

function Organization() {
  const DELAY = parseInt(useApiConfigurations(RECURSIVE_CALLS_REFETCH_FREQUENCY_MS), 10);
  const dispatch = useDispatch();
  const [isReady, setIsReady] = useState(false);
  const user = useSelector((state) => state.user);
  const currentOrganizationPlan = useSelector(selectOrganizationPlan);
  const { organizationId } = useParams();
  const navigate = useNavigate();
  const { isFeatureEnabled: hasAccessToCompetitor } =
    useOrganizationFeatureSet(COMPETITORS__IS_ENABLED);
  const isCompetitorEnabled = useApiConfigurations(COMPETITORS_ENABLED);
  const trackMixpanelEvent = useMixpanelTrackEvent();

  const currentOrganization = useGetCurrentOrganization({
    onSuccess: (data) => {
      dispatch(
        setCurrentOrganizationEditableData({
          organizationId,
          ...data,
        })
      );
    },
    queryKey: [SCOPE_ORGANIZATION.GET_CURRENT_ORGANIZATION, organizationId, LOCATION.ORGANIZATION],
    refetchInterval: organizationId && user?.id ? DELAY : false,
    refetchOnWindowFocus: 'always',
  });

  const fetchAccountData = async () => {
    try {
      const response = await Promise.all([
        dispatch(fetchOrganization(organizationId)),
        dispatch(fetchWorkspaces(organizationId)),
      ]);
      dispatch(changeDefaultOrganizationId(organizationId));

      const organizationRoles = user?.organizationAccesses?.filter(
        (oa) => oa.organization.id === organizationId
      )[0]?.roles;
      const organization = response[0];

      initIntercom(
        user,
        {
          company_id: organizationId,
          name: organization.name,
          plan: organization.planId,
        },
        {
          organizationRoles,
        }
      );

      setIsReady(true);
    } catch (error) {
      if (error.status >= 400 && error.status < 500) {
        dispatch(removeDefaultOrganizationId());
        navigate('/', { replace: true });
      }

      Log.report({
        context: 'componentDidMount',
        error,
        extra: 'Fetch organization and workspaces',
        section: SECTIONS.organization.key,
      });
    }
  };

  useEffect(() => {
    if (organizationId && !isReady && currentOrganizationPlan) {
      trackMixpanelEvent(SESSION_START);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, isReady, currentOrganizationPlan]);

  useEffect(() => {
    fetchAccountData();
    register({
      distinct_id: user.id,
      organization_id: organizationId,
    });
    return () => currentOrganization.remove();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  if (!isReady) {
    return (
      <LayoutWrapper menu={NO_MENU_LAYOUT} variant={DEFAULT_BACKGROUND}>
        <LayoutLoader />
      </LayoutWrapper>
    );
  }

  return (
    <Can
      no={() => {
        dispatch(removeDefaultOrganizationId());
        return <Navigate to="/" />;
      }}
      perform="current-organization:visit"
      yes={() => (
        <Routes>
          <Route
            element={
              isCompetitorEnabled && hasAccessToCompetitor ? <HubWorkspace /> : <Workspace />
            }
            path="/w/:workspaceId/*"
          />
          <Route
            element={
              <Can
                no={() => <Navigate to="/" />}
                perform="organization-admin-settings:visit"
                yes={() => <CreateWorkspace />}
              />
            }
            path="/create-workspace"
          />
          <Route element={<MaxAllowedWorkspacesReached />} path="/add-workspace-not-authorized" />
          <Route element={<WorkspaceAccessBlocked />} path={`/workspace-access-blocked`} />
          <Route element={<Settings />} path={`/settings/:tab`} />
          <Route
            element={<Navigate to={`/o/${organizationId}/settings/user-profile`} />}
            path={`/settings`}
          />
          <Route element={<DefaultWorkspace />} path="*" />
        </Routes>
      )}
    />
  );
}

export default Organization;
