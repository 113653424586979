import { lazy, Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import AlarmClock from '@/assets/images/alarm-clock.png';
import ServiceIntegrationCheckbox from '@/components/Checkbox/ServiceIntegrationCheckbox';
import SearchConsoleServiceIntegration from '@/containers/CreateWorkspace/Connect/SearchConsole';
import LinkLikeButton from '@/containers/ServiceIntegration/SearchConsole/Steps/Dialog/LinkLikeButton';
import { SERVICES_LIST } from '@/utils/constants';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const ServiceIntegrationIllustration = lazy(
  () =>
    import(
      /* webpackChunkName: "ServiceIntegrationIllustration" */ '@/components/DynamicIllustration/ServiceIntegration'
    )
); // Lazy-loaded

// Lazy load this component because it's a heavy svg
const StyledServiceIntegrationIllustration = styled((props) => (
  <Suspense fallback={<></>}>
    <ServiceIntegrationIllustration {...props} />
  </Suspense>
))`
  position: fixed;
  bottom: 0;
  right: 0;
  // resize on medium screen
  ${({ theme }) => theme.mediaQueries.desktop} {
    max-width: 50%;
    transform: scale(0.8);
    transform-origin: right bottom;
  }
  // hide illustration for small screens
  ${({ theme }) => theme.mediaQueries.phone} {
    display: none;
  }
  ${({ theme }) => theme.mediaQueries.tablet} {
    display: none;
  }
`;

const Img = styled.img`
  object-fit: contain;
  height: 100%;
  vertical-align: middle;
`;

const Text = styled.span`
  font-family: ${(props) => props.theme.textCss.fonts.main};
  color: ${(props) => props.theme.cssColors.dark100};
  font-size: ${(props) => props.theme.textCss.sizes.sm};
  font-weight: ${(props) => props.theme.textCss.weights.strong};
  overflow: hidden;

  ${(props) => props.theme.mediaQueries.largeDesktop} {
    font-size: ${(props) => props.theme.textCss.sizes.default};
  }
`;

export default function ConnectWorkspace({
  countryCode,
  dataSources,
  handlePrevious,
  isObvious,
  languageCode,
  onChangeDataSource,
  workspaceName,
  workspaceWebSiteUrl,
}) {
  const { t } = useTranslation();
  const [isSearchConsoleDialogDisplayed, setIsSearchConsoleDialogDisplayed] = useState(false);

  const isSearchConsoleFilled =
    dataSources.searchConsole && Object.keys(dataSources.searchConsole).length > 1;

  const isActivatedLater =
    dataSources.searchConsole && Object.keys(dataSources.searchConsole).length === 0;

  const { icon, title } = SERVICES_LIST.SEARCH_CONSOLE;

  function onChangeSearchConsoleDataSource(searchConsole = {}) {
    onChangeDataSource({
      searchConsole,
    });
  }

  function handleCloseModal() {
    setIsSearchConsoleDialogDisplayed(false);
  }

  function onDeleteDataSource() {
    onChangeDataSource({
      searchConsole: null,
    });
  }

  function handleOpenModal() {
    if (!isSearchConsoleFilled) {
      setIsSearchConsoleDialogDisplayed(true);
    }
  }

  function handleSkip() {
    if (!isActivatedLater) {
      onChangeSearchConsoleDataSource();
    }
  }

  const searchConsoleLabel = t(
    isSearchConsoleFilled
      ? 'create-workspace:connect-workspace.search-console-connected-label'
      : 'create-workspace:connect-workspace.connect-search-console-label'
  );

  const activateLaterLabel = t('create-workspace:connect-workspace.activate-later-label');

  return (
    <>
      <Wrapper>
        <ServiceIntegrationCheckbox
          deleteLabel={t(
            'create-workspace:connect-workspace.search-console-connected-delete-label'
          )}
          isChecked={isSearchConsoleFilled}
          onClick={handleOpenModal}
          onDelete={onDeleteDataSource}
        >
          <Img alt={title} src={icon} />
          <Text alt={searchConsoleLabel}>{searchConsoleLabel}</Text>
        </ServiceIntegrationCheckbox>
        <ServiceIntegrationCheckbox
          deleteLabel={t('create-workspace:connect-workspace.activate-later-delete-label')}
          isChecked={isActivatedLater}
          onClick={handleSkip}
          onDelete={onDeleteDataSource}
        >
          <Img alt={t('create-workspace:connect-workspace.activate-later-alt')} src={AlarmClock} />
          <Text alt={activateLaterLabel}>{activateLaterLabel}</Text>
        </ServiceIntegrationCheckbox>
        <LinkLikeButton
          as="a"
          href={t('common:links.help-connect-google-search-console')}
          rel="noopener noreferrer"
          target="_blank"
          title={t('service-integration:search-console.help.connect-search-console')}
        >
          {t('service-integration:search-console.help.connect-search-console')}
        </LinkLikeButton>
      </Wrapper>
      {isSearchConsoleDialogDisplayed && (
        <SearchConsoleServiceIntegration
          handleClose={handleCloseModal}
          handlePrevious={handlePrevious}
          workspaceWebsiteUrl={workspaceWebSiteUrl}
          onChangeSearchConsoleDataSource={onChangeSearchConsoleDataSource}
        />
      )}
      <StyledServiceIntegrationIllustration
        countryCode={countryCode}
        isObvious={isObvious}
        languageCode={languageCode}
        workspaceName={workspaceName}
      />
    </>
  );
}
