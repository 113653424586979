import {
  CHANGE_DEFAULT_ORGANIZATION_ID,
  REMOVE_DEFAULT_ORGANIZATION_ID,
} from '../actions/actionTypes';

export default function defaultOrganizationId(state = null, action) {
  switch (action.type) {
    case CHANGE_DEFAULT_ORGANIZATION_ID:
      return action.organizationId;
    case REMOVE_DEFAULT_ORGANIZATION_ID:
      return null;
    default:
      return state;
  }
}
