import { useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import apiClient from '@/apis/semji/apiClient';

export default function usePostThread({ ...props }) {
  const { contentId } = useParams();
  const { post } = apiClient;

  return useMutation({
    mutationFn: ({ anchor }) => post(`contents/${contentId}/threads`, { anchor }),
    ...props,
  });
}
