import apiSettings from '@/apis/semji/settings';
import { GeneratedContentType } from '@/containers/Content/TinyMceComponents/Editor/hooks/useAiWriting/useAiWriting.types';

import { PostStreamParameters, UseStreamModelParameters } from './useStreamModel.types';

export const STREAM_EVENT_ERROR = '___STREAM_ERROR___';

export const ERROR_MESSAGE_STREAM_FAIL = 'stream-error';
export const ERROR_MESSAGE_RATE_LIMIT = 'rate-limit';
export const ERROR_MESSAGE_CREDIT_LIMIT = 'credit-limit';

export const POST_STREAM_PARAMETERS_INTRODUCTION: string[] = [
  'brandVoiceId',
  'editorRef',
  'focusTopKeywordId',
  'generatedContentToken',
  'handleStreamWrite',
  'heading',
  'html',
  'htmlAboveCursor',
  'isFullDraft',
  'lastGenerationRef',
  'title',
];

export const POST_STREAM_PARAMETERS_PARAGRAPH: string[] = POST_STREAM_PARAMETERS_INTRODUCTION;

export const POST_STREAM_PARAMETERS_TITLE: string[] = [
  'focusTopKeywordId',
  'inputTitleRef',
  'handleStreamWriteTitle',
  'generatedContentToken',
];

export const POST_STREAM_PARAMETERS_METADESCRIPTION: string[] = [
  'focusTopKeywordId',
  'generatedContentToken',
  'handleStreamWriteMetaDescription',
  'inputTitleRef',
  'textareaMetaDescriptionRef',
];

export const POST_STREAM_PARAMETERS_OUTLINE: string[] = [
  'brandVoiceId',
  'editorRef',
  'focusTopKeywordId',
  'generatedContentToken',
  'handleStreamWrite',
  'html',
  'isFullDraft',
  'lastGenerationRef',
  'title',
];

export const POST_STREAM_PARAMETERS_REFINED_BRAND_VOICE: string[] = [
  'brandVoiceId',
  'editorRef',
  'focusTopKeywordId',
  'generatedContentToken',
  'generatedContentId',
  'handleStreamWrite',
  'html',
  'isFullDraft',
  'lastGenerationRef',
  'title',
];

export function getPostStreamParameters(
  mutationProps: UseStreamModelParameters
): PostStreamParameters {
  const baseUrl = apiSettings.rootUrl;
  const mapping = {
    GENERATION_INTRODUCTION_TYPE: POST_STREAM_PARAMETERS_INTRODUCTION,
    GENERATION_METADESCRIPTION_TYPE: POST_STREAM_PARAMETERS_METADESCRIPTION,
    GENERATION_OUTLINE_TYPE: POST_STREAM_PARAMETERS_OUTLINE,
    GENERATION_PARAGRAPH_TYPE: POST_STREAM_PARAMETERS_PARAGRAPH,
    GENERATION_REFINED_BRAND_VOICE_TYPE: POST_STREAM_PARAMETERS_REFINED_BRAND_VOICE,
    GENERATION_TITLE_TYPE: POST_STREAM_PARAMETERS_TITLE,
  }[mutationProps.lastGenerationRef.current?.type as string];

  const postStreamParameters: PostStreamParameters = {
    lastGenerationRef: mutationProps.lastGeneration,
    payload: {},
    type: mutationProps.lastGenerationRef.current?.type as GeneratedContentType,
    ...(mutationProps?.textareaMetaDescriptionRef && {
      textareaMetaDescriptionRef: mutationProps.textareaMetaDescriptionRef,
    }),
    ...(mutationProps?.inputTitleRef && {
      inputTitleRef: mutationProps.inputTitleRef,
    }),
    ...(mutationProps?.isFullDraft && {
      isFullDraft: mutationProps.isFullDraft,
    }),
    ...(mutationProps?.editorRef && {
      editorRef: mutationProps.editorRef,
    }),
    url: '',
  };

  mapping?.forEach(
    (key: string) =>
      (postStreamParameters[key as keyof PostStreamParameters] =
        mutationProps[key as keyof PostStreamParameters])
  );

  postStreamParameters.url = {
    GENERATION_INTRODUCTION_TYPE: `${baseUrl}/top_keywords/${mutationProps.focusTopKeywordId}/completions/introduction`,
    GENERATION_METADESCRIPTION_TYPE: `${baseUrl}/top_keywords/${mutationProps.focusTopKeywordId}/completions/meta_descriptions`,
    GENERATION_OUTLINE_TYPE: `${baseUrl}/top_keywords/${mutationProps.focusTopKeywordId}/completions/outlines`,
    GENERATION_PARAGRAPH_TYPE: `${baseUrl}/top_keywords/${mutationProps.focusTopKeywordId}/completions/paragraph`,
    GENERATION_REFINED_BRAND_VOICE_TYPE: `${baseUrl}/top_keywords/${mutationProps.focusTopKeywordId}/completions/deprecated_brand_voice`,
    GENERATION_TITLE_TYPE: `${baseUrl}/top_keywords/${mutationProps.focusTopKeywordId}/completions/titles`,
  }[mutationProps.lastGenerationRef.current?.type as string] as string;

  postStreamParameters.payload = {
    GENERATION_INTRODUCTION_TYPE: {
      deprecatedBrandVoiceId: mutationProps.brandVoiceId,
      generatedContentToken: mutationProps.generatedContentToken,
      heading: mutationProps.heading,
      ...(mutationProps?.title && { title: mutationProps.title }),
      ...(mutationProps?.html && { html: mutationProps.html }),
      ...(mutationProps?.htmlAboveCursor && { htmlAboveCursor: mutationProps.htmlAboveCursor }),
    },
    GENERATION_METADESCRIPTION_TYPE: {
      generatedContentToken: mutationProps?.generatedContentToken,
      title: mutationProps?.inputTitleRef?.current.value,
    },
    GENERATION_OUTLINE_TYPE: {
      deprecatedBrandVoiceId: mutationProps?.brandVoiceId,
      generatedContentToken: mutationProps?.generatedContentToken,
      ...(mutationProps?.html && { html: mutationProps.html }),
      title: mutationProps?.title,
    },
    GENERATION_PARAGRAPH_TYPE: {
      deprecatedBrandVoiceId: mutationProps?.brandVoiceId,
      generatedContentToken: mutationProps?.generatedContentToken,
      heading: mutationProps?.heading,
      title: mutationProps?.title,
      ...(mutationProps?.html && { html: mutationProps.html }),
      ...(mutationProps?.htmlAboveCursor && { htmlAboveCursor: mutationProps.htmlAboveCursor }),
    },
    GENERATION_REFINED_BRAND_VOICE_TYPE: {
      deprecatedBrandVoiceId: mutationProps?.brandVoiceId,
      generatedContentId: mutationProps?.generatedContentId,
      generatedContentToken: mutationProps?.generatedContentToken,
      html: mutationProps?.html,
      title: mutationProps?.title,
    },
    GENERATION_TITLE_TYPE: {
      generatedContentToken: mutationProps?.generatedContentToken,
    },
  }[mutationProps.lastGenerationRef.current?.type as string] as { [key: string]: string };

  return postStreamParameters;
}
