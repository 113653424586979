const SORTED_MAIN_RECOMMENDATIONS_IDS = [
  'focusKeywordInTitle',
  'articleWordsCount',
  'searchIntentsSuggestion',
  'questionsSuggestion',
  'topicsSuggestion',
  'incomingLinks',
  'outgoingLinks',
  'outgoingLinksLegacy',
];

export function selectSortedRecommendationsIds(state): string[] {
  const recommendations = state.report.recommendations;

  return SORTED_MAIN_RECOMMENDATIONS_IDS.filter((recommendationId) => {
    return !!recommendations[recommendationId];
  });
}
