import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import usePostTopKeywordAnalysis from '@/apis/semji/topKeywords/usePostTopKeywordAnalysis';
import UnlockImage from '@/assets/images/unlock-image.svg';
import NoAnalyze, {
  AN_ERROR_OCCURRED,
  KEYWORD_NOT_ANALYZED,
} from '@/components/EmptyState/NoAnalyze';
import FocusKeywordAnalysisPending from '@/components/Optimization/FocusKeywordAnalysisPending';
import FocusKeywordAnalysisQueued from '@/components/Optimization/FocusKeywordAnalysisQueued';
import ScrollBar from '@/components/Scrollbar';
import { useContentContainerContext } from '@/containers/Content/ContentContainerContext';
import IncomingLinks from '@/containers/Content/SidePanel/Components/Optimization/Section/IncomingLinks/IncomingLinks';
import ArticlesLength from '@/containers/Content/SidePanel/Components/Optimization/Section/Length/ArticlesLength';
import OutgoingLinks from '@/containers/Content/SidePanel/Components/Optimization/Section/OutgoingLinks/OutgoingLinks';
import OutgoingLinksLegacy from '@/containers/Content/SidePanel/Components/Optimization/Section/OutgoingLinksLegacy/OutgoingLinksLegacy';
import Questions from '@/containers/Content/SidePanel/Components/Optimization/Section/Questions/Questions';
import RelatedTerms from '@/containers/Content/SidePanel/Components/Optimization/Section/RelatedTerms/RelatedTerms';
import SearchIntents from '@/containers/Content/SidePanel/Components/Optimization/Section/SearchIntents/SearchIntents';
import Title from '@/containers/Content/SidePanel/Components/Optimization/Section/Title/Title';
import SidePanelErrorMessage from '@/containers/Content/SidePanel/Components/SidePanelErrorMessage';
import { Loader, LoaderText } from '@/design-system/components/Loader';
import { openCreditLimitDialog } from '@/store/actions/report';
import { showErrorSnackbar } from '@/store/actions/ui';
import { selectIsNotPublishedNewContent } from '@/store/selectors/selectIsNotPublishedNewContent';
import { selectRemainingAnalysisUnit } from '@/store/selectors/selectRemainingAnalysisUnit';
import { selectSortedRecommendationsIds } from '@/store/selectors/selectSortedRecommendationsIds';
import { SOURCE_EDITOR, STATUS_FAILED, STATUS_PENDING, STATUS_QUEUED } from '@/utils/analysis';
import { ANALYSIS_CREDITS_TYPE } from '@/utils/constants';

const OptimizationList = styled.div`
  margin-top: 18px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 10px 16px 75px 16px;
`;

function OptimizationContainer({
  areManyAnalysisInProgress,
  focusTopKeyword,
  isReadOnly,
  loading,
  refreshing,
  refreshReport,
  mandatoryRecommendationsReady,
}) {
  const { editorRef } = useContentContainerContext();
  const { t } = useTranslation();
  const { pageId } = useParams();
  const dispatch = useDispatch();

  const remainingAnalysisUnit = useSelector(selectRemainingAnalysisUnit);
  const newContentIsNotPublished = useSelector(selectIsNotPublishedNewContent);
  const [isInProgress, setIsInProgress] = useState(false);
  const [expandedPanelKeys, setexpandedPanelKeys] = useState([]);
  const sortedMainRecommendationsIds = useSelector(selectSortedRecommendationsIds);

  const topKeywordAnalysis = usePostTopKeywordAnalysis({
    onError: (error) => {
      if (error.response.status === 403) {
        dispatch(openCreditLimitDialog(ANALYSIS_CREDITS_TYPE));
      } else {
        dispatch(showErrorSnackbar(t('common:error.default')));
      }
    },
    onMutate: () => setIsInProgress(true),
    onSettled: () => setIsInProgress(false),
    onSuccess: () => refreshReport(),
  });

  function handleOptimizationClick(key) {
    if (expandedPanelKeys.includes(key)) {
      setexpandedPanelKeys([...expandedPanelKeys.filter((k) => k !== key)]);
    } else {
      setexpandedPanelKeys([...expandedPanelKeys, key]);
    }
  }

  function renderOptimizationsList(focusTopKeyword, pageId) {
    return sortedMainRecommendationsIds.map((id) => {
      const props = {
        editorRef: editorRef,
        expanded: expandedPanelKeys.includes(id),
        focusTopKeywordId: focusTopKeyword.id,
        id,
        isReadOnly: isReadOnly,
        key: id,
        keyword: focusTopKeyword?.keyword,
        newContentIsNotPublished,
        onClick: handleOptimizationClick,
        pageId,
      };

      switch (id) {
        case 'focusKeywordInTitle':
          return <Title data-intercom-target="focus_keyword_in_title_optimization" {...props} />;
        case 'articleWordsCount':
          return (
            <ArticlesLength data-intercom-target="article_words_count_optimization" {...props} />
          );
        case 'searchIntentsSuggestion':
          return (
            <SearchIntents
              data-intercom-target="search_intents_suggestion_optimization"
              {...props}
            />
          );
        case 'questionsSuggestion':
          return <Questions data-intercom-target="questions_suggestion_optimization" {...props} />;
        case 'topicsSuggestion':
          return <RelatedTerms data-intercom-target="topics_suggestion_optimization" {...props} />;
        case 'incomingLinks':
          return <IncomingLinks data-intercom-target="incoming_links_optimization" {...props} />;
        case 'outgoingLinks':
          return <OutgoingLinks data-intercom-target="outgoing_links_optimization" {...props} />;
        case 'outgoingLinksLegacy':
          return (
            <OutgoingLinksLegacy
              data-intercom-target="outgoing_links_legacy_optimization"
              {...props}
            />
          );
        default:
          return null;
      }
    });
  }

  async function retry() {
    await topKeywordAnalysis.mutateAsync({
      data: {
        source: SOURCE_EDITOR,
      },
      topKeywordIdList: [focusTopKeyword.id],
    });
  }

  if (loading) {
    return (
      <Loader>
        {refreshing && (
          <LoaderText>
            {t('content:side-panel-components.optimization.updating-optimizations')}
          </LoaderText>
        )}
      </Loader>
    );
  }

  if (focusTopKeyword.analysisStatus === STATUS_FAILED) {
    return <NoAnalyze isInProgress={isInProgress} version={AN_ERROR_OCCURRED} onClick={retry} />;
  }

  if (focusTopKeyword.analysisStatus === STATUS_QUEUED) {
    return <FocusKeywordAnalysisQueued />;
  }

  if (focusTopKeyword.analysisStatus === STATUS_PENDING && !mandatoryRecommendationsReady) {
    return (
      <FocusKeywordAnalysisPending
        analysisIsAutoUpdating={focusTopKeyword.previousAnalysisExpired}
        areManyAnalysisInProgress={areManyAnalysisInProgress}
      />
    );
  }

  if (focusTopKeyword.analyzed === false && remainingAnalysisUnit <= 0) {
    return (
      <SidePanelErrorMessage
        imageAlt={t('content:side-panel-components.optimization-container.error-message.image-alt')}
        imagePath={UnlockImage}
        informationText={
          <Trans
            components={{
              br: <br />,
            }}
            i18nKey="content:side-panel-components.optimization-container.error-message.text"
          />
        }
        title={t('content:side-panel-components.optimization-container.error-message.title')}
        upgradePlanButton
      />
    );
  }

  if (focusTopKeyword.analyzed === false) {
    return <NoAnalyze isInProgress={isInProgress} version={KEYWORD_NOT_ANALYZED} onClick={retry} />;
  }

  return (
    <ScrollBar>
      <OptimizationList>{renderOptimizationsList(focusTopKeyword, pageId)}</OptimizationList>
    </ScrollBar>
  );
}

export default OptimizationContainer;
