import { useMutation } from '@tanstack/react-query';

import apiClient from '@/apis/semji/apiClient';

export default function usePutGeneratedTitleReport(props) {
  const { put } = apiClient;

  return useMutation({
    mutationFn: ({ ids = [], report = {} }) =>
      Promise.all(ids.map(async (id) => put(`/generated_titles/${id}/report`, { ...report }))),

    ...props,
  });
}
