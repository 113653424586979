import { nanoid } from 'nanoid';
import { useMemo } from 'react';

import SvgWrapper from './SvgWrapper';

const PencilIcon = (props) => {
  const maskId = useMemo(() => nanoid(), []);

  return (
    <SvgWrapper fill="none" height="18" viewBox="0 0 18 18" width="18" {...props}>
      <mask
        height="15"
        id={maskId}
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="16"
        x="1"
        y="1"
      >
        <path
          d="M15.529 2.857L14.126 1.453C13.561 0.887003 12.571 0.887003 12.004 1.453L2.94701 10.511L1.22501 15.799L6.47301 14.034L15.528 4.978C16.114 4.394 16.114 3.442 15.529 2.857ZM3.09401 13.294L3.73901 11.315L5.67301 13.25L3.71001 13.91L3.09401 13.294ZM4.35501 10.518L9.84801 5.025L11.959 7.135L6.46501 12.629L4.35501 10.518ZM10.555 4.317L11.284 3.588L13.395 5.698L12.666 6.427L10.555 4.317ZM14.822 4.271L14.102 4.991L11.991 2.881L12.711 2.16C12.9 1.971 13.229 1.971 13.418 2.16L14.821 3.564C15.017 3.76 15.017 4.076 14.822 4.271Z"
          fill="black"
        />
      </mask>
      <g mask={`url(#${maskId})`}>
        <rect fill="currentColor" height="20" width="20" x="-2" y="-1" />
      </g>
    </SvgWrapper>
  );
};

export default PencilIcon;
