import styled from 'styled-components/macro';

const Table = styled.div`
  margin: 10px 0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);

  & > div:last-child {
    border: none;
  }
`;

export default Table;
