export function formatCurrency(currency, defaultValue = 'EUR') {
  const lowerCaseCurrency = (currency ?? '').toLowerCase();

  switch (lowerCaseCurrency) {
    case 'dollar':
    case '$':
    case 'usd':
      return 'USD';
    default:
      return defaultValue;
  }
}

export function formatAmount(amountInCents, currency, maximumFractionDigits = 2) {
  if (isNaN(amountInCents) || !currency) {
    return '';
  }

  if ('USD' === currency) {
    return new Intl.NumberFormat('en-US', {
      currency,
      maximumFractionDigits,
      style: 'currency',
    }).format(amountInCents / 100);
  }

  return new Intl.NumberFormat('fr-FR', {
    currency,
    maximumFractionDigits,
    style: 'currency',
  }).format(amountInCents / 100);
}
