import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import DropDown from '@/components/DropDown';
import EditStartOptimizing from '@/components/icons/EditStartOptimizing';
import { NEW_CONTENT, UPDATED_CONTENT } from '@/components/Pages/ContentIdeas/constant';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import RowRender from '@/containers/ContentIdeas/Dialog/RowRender';

const ROW_SIZE_IN_PX = 60;

const StyledEditStartOptimizing = styled(EditStartOptimizing)`
  && {
    font-size: 1rem;
    padding: 0.3rem;
  }
  &:hover {
    background-color: ${({ theme }) => theme.cssColors.dark010};
    border-radius: 50%;
  }
`;

function OptimizedCell({ handleEditDraftClick }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { workspaceId, organizationId } = useParams();

  const [anchorEl, setAnchorEl] = useState(null);
  const [contents, setContents] = useState([]);

  function click(e) {
    if (anchorEl) {
      setAnchorEl(null);
      e.stopPropagation();
      return;
    }

    const contents = handleEditDraftClick();

    if (contents) {
      setContents(contents);
      setAnchorEl(e.currentTarget);
    }

    e.stopPropagation();
  }

  function onRowClick(pageId, contentId) {
    return () => {
      navigate(`/o/${organizationId}/w/${workspaceId}/p/${pageId}/content/${contentId}`, {
        replace: true,
      });
    };
  }

  return (
    <>
      <TooltipComponent
        cursor="pointer"
        delay={50}
        placement="top"
        title={t('components:add-to-planning-cell.edit-draft')}
        onClick={click}
      >
        <StyledEditStartOptimizing />
      </TooltipComponent>
      <DropDown
        anchorEl={anchorEl}
        height={ROW_SIZE_IN_PX * contents.length + 1}
        setAnchorEl={setAnchorEl}
      >
        {contents.map((content) => (
          <RowRender
            key={content.id}
            assignedTo={content.assignedTo}
            contentScore={content.contentScore}
            contentStatus={content.contentStatus}
            dueDate={content.dueDate}
            rowSize={ROW_SIZE_IN_PX}
            title={content.title}
            type={content.page.new ? NEW_CONTENT : UPDATED_CONTENT}
            onClick={onRowClick(content.page.id, content.id)}
          />
        ))}
      </DropDown>
    </>
  );
}

OptimizedCell.propTypes = {
  handleEditDraftClick: PropTypes.func,
};

export default OptimizedCell;
