import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import PlusAltIcon from '@/components/icons/PlusAltIcon';
import { SmallFlexTooltipComponent } from '@/components/Tooltip/Tooltip';
import { useMixpanelTrackEvent } from '@/hooks/useMixpanelTrackEvent';
import { CREATE_DRAFT_TOP_NAV_CLICK } from '@/utils/3rdParty/Mixpanel/constants';

const Tooltip = styled(SmallFlexTooltipComponent)`
  align-items: center;
  justify-content: center;
`;

const PlusIcon = styled(PlusAltIcon)`
  && {
    cursor: pointer;
    font-size: 1.5rem;
  }
`;

function QuickAccessMenu(props) {
  const { t } = useTranslation();
  const { organizationId, workspaceId } = useParams();
  const [query] = useSearchParams();
  const navigate = useNavigate();
  const trackMixpanelEvent = useMixpanelTrackEvent();

  function handleClick() {
    navigate(`/o/${organizationId}/w/${workspaceId}/planning/create-drafts?${query.toString()}`);
    trackMixpanelEvent(CREATE_DRAFT_TOP_NAV_CLICK);
  }

  return (
    <Tooltip title={t('layout:quick-access-menu.new-draft')} {...props}>
      <PlusIcon onClick={handleClick} />
    </Tooltip>
  );
}

export default QuickAccessMenu;
