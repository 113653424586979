import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { DROPDOWN_SIZES } from 'semji-core/components/Dropdown';
import { FolderSelect } from 'semji-core/components/FolderSelect';

import { fetchContent } from '@/apis/semji/api';
import {
  LOCATION,
  SCOPE_CONTENTS,
  SCOPE_FOCUS_TOP_KEYWORDS,
  SCOPE_FOLDERS,
} from '@/apis/semji/constants';
import useDeleteContentById from '@/apis/semji/contents/useDeleteContentById';
import useGetCurrentWorkspaceContentsStatuses from '@/apis/semji/contents/useGetCurrentWorkspaceContentsStatuses';
import useGetCurrentWorkspaceContentsWithSpecificProperties from '@/apis/semji/contents/useGetCurrentWorkspaceContentsWithSpecificProperties';
import usePostPublishContent from '@/apis/semji/contents/usePostPublishContent';
import usePostRefreshContentData from '@/apis/semji/contents/usePostRefreshContentData';
import usePutContents from '@/apis/semji/contents/usePutContents';
import useGetCurrentWorkspaceAllFolders from '@/apis/semji/folders/useGetCurrentWorkspaceAllFolders';
import usePostFolder from '@/apis/semji/folders/usePostFolder';
import useGetCurrentWorkspacePagesWithSpecificProperties from '@/apis/semji/pages/useGetCurrentWorkspacePagesWithSpecificProperties';
import useGetCurrentWorkspaceFocusTopKeywordsWithSpecificProperties from '@/apis/semji/topKeywords/useGetCurrentWorkspaceFocusTopKeywordsWithSpecificProperties';
import usePostTopKeywordAnalysis from '@/apis/semji/topKeywords/usePostTopKeywordAnalysis';
import { TableFlexStyledCard } from '@/components/_common';
import { DarkText, VerticalSeparator } from '@/components/ActionsBar/BulkActionsBar';
import { NavButton } from '@/components/Button/Button';
import { AlertDialog } from '@/components/Dialog/AlertDialog';
import BulkAnalysisDialog from '@/components/Dialog/BulkAnalysisDialog';
import BulkMarkAsPublishedDialog from '@/components/Dialog/BulkMarkAsPublishedDialog';
import CreditLimitReachedDialog from '@/components/Dialog/CreditLimitReachedDialog';
import ExportCalendar from '@/components/Dialog/ExportCalendar';
import ExportDialog from '@/components/Dialog/ExportDialog';
import MarkAsPublishedDialog from '@/components/Dialog/MarkAsPublishedDialog';
import SuccessMarkAsPublishedDialog from '@/components/Dialog/SuccessMarkAsPublishedDialog';
import NoDraft, {
  NO_DRAFT_ASSIGNED,
  NO_DRAFT_FOLDER,
  NO_DRAFT_PUBLISHED,
} from '@/components/EmptyState/NoDraft';
import FilterGroups from '@/components/Filters/FilterGroups';
import FolderFiltersPanel from '@/components/Filters/FolderFiltersPanel';
import TableFilters from '@/components/Filters/TableFilters';
import Analyze2Icon from '@/components/icons/Analyze2Icon';
import CheckboxIcon from '@/components/icons/CheckboxIcon';
import DeleteIcon from '@/components/icons/DeleteIcon';
import DoubleFolderIcon from '@/components/icons/DoubleFolderIcon';
import FileExportOutline from '@/components/icons/FileExportOutline';
import MarkAsPublishedIcon from '@/components/icons/MarkAsPublishedIcon';
import PenBoxIcon from '@/components/icons/PenBoxIcon';
import PencilIcon from '@/components/icons/PencilIcon';
import User2Icon from '@/components/icons/User2Icon';
import InputRevealer from '@/components/Input/InputRevealer';
import { ListingHeaderContainer } from '@/components/Layout/Header';
import { PageDataLayout } from '@/components/PageDataLayout';
import { usePageDataLayout } from '@/components/PageDataLayout/PageDataLayout.context';
import { getDefaultFolderGeneral } from '@/components/Select/FolderSelect/constants';
import UserSelect from '@/components/Select/UserSelect';
import { SidebarToggle } from '@/components/Sidebar';
import Table from '@/components/Table/Table';
import BulkActionButton from '@/components/TableBulkActions/BulkActionButton';
import BulkActionIcon from '@/components/TableBulkActions/BulkActionIcon';
import DatePicker from '@/components/TableBulkActions/DatePicker';
import TableActionBar from '@/components/TableBulkActions/TableActionBar';
import TooltipComponent, { SmallFlexTooltipComponent } from '@/components/Tooltip/Tooltip';
import { getColumns, getFiltersList } from '@/containers/Planning/utils';
import Flex from '@/design-system/components/Flex';
import useApiConfigurations from '@/hooks/useApiConfigurations';
import useCan from '@/hooks/useCan';
import useIsUserLimitedGuestWriter from '@/hooks/useIsUserLimitedGuestWriter';
import useMapUrlQueryToFilter from '@/hooks/useMapUrlQueryToFilter';
import { useMixpanelTrackEvent } from '@/hooks/useMixpanelTrackEvent';
import useNullUser from '@/hooks/useNullUser';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import useWorkspaceUsers from '@/hooks/useWorkspaceUsers';
import ContentCollection from '@/services/ContentCollection';
import { setDialogState } from '@/store/actions/dialogs';
import { showErrorSnackbar, showSuccessSnackbar } from '@/store/actions/ui';
import useParamSelector from '@/store/hooks/useParamSelector';
import { getOrganizationById } from '@/store/reducers';
import { SUCCESS_MARK_AS_PUBLISHED_DIALOG } from '@/store/reducers/dialogs';
import selectCurrentWorkspaceCountryName from '@/store/selectors/selectCurrentWorkspaceCountryName';
import { selectCurrentWorkspaceWebsiteUrl } from '@/store/selectors/selectCurrentWorkspaceWebsiteUrl';
import selectDialogPropertyByName from '@/store/selectors/selectDialogPropertyByName';
import { selectHasSearchConsole } from '@/store/selectors/selectHasSearchConsole';
import { selectRemainingAnalysisUnit } from '@/store/selectors/selectRemainingAnalysisUnit';
import { FileType } from '@/types/export/export.types';
import {
  CONTENT_PUBLISHED,
  PLANNING_NEW_DRAFT_CLICK,
  PLANNING_SYNCHRONIZE_AGENDA_CLICK,
} from '@/utils/3rdParty/Mixpanel/constants';
import { SOURCE_PLANNING_BULK, STATUS_PENDING } from '@/utils/analysis';
import {
  CONTENT_EXPORT_SYNC_LIMITATION,
  CONTENT_VERSION_HISTORY_ENABLED,
  LISTING_REFETCH_FREQUENCY_MS,
} from '@/utils/configurations/constants';
import { ANALYSIS_CREDITS_TYPE } from '@/utils/constants';
import applyFilters from '@/utils/filter/applyFilters';
import mapFiltersToQueryParameter from '@/utils/filter/mapFiltersToQueryParameter';
import getCreditsCost from '@/utils/getCreditsCost';
import listByPropertyReducer from '@/utils/listByPropertyReducer';
import { SECTIONS } from '@/utils/log/constants';
import { Log } from '@/utils/log/Log';
import removeDiacritics from '@/utils/removeDiacritics';
import { downloadFileFromBlob, isUrlValidWithWebSiteUrl } from '@/utils/url';

import {
  CalendarIcon,
  Container,
  FiltersContainer,
  FlexWithMargin,
  MainContent,
  StatusBulk,
  StyledSearchIcon,
  StyledStatusSelect,
  Wrapper,
} from './Planning.styled';

const LOADING_ROWS_PLACEHOLDER = Array(5).fill({
  contentStatus: {},
  loading: true,
  page: {},
  workspace: {},
});

const DEFAULT_TAB = 'open';
const DRAFT_TYPE = 'DRAFT';
const PUBLISHED_TYPE = 'PUBLISHED';

const searchFilter = (searchTerm, contents) => {
  if (searchTerm) {
    return contents.filter((content) => {
      const clearedSearchTerm = removeDiacritics(searchTerm.toLowerCase());
      const clearedFocusKeyword = removeDiacritics(
        ('' + content.pageFocusTopKeyword?.keyword).toLowerCase()
      );
      const clearedUrl = removeDiacritics(('' + content.page?.url).toLowerCase());
      const clearedTitle = removeDiacritics(('' + content.title).toLowerCase());

      return (
        clearedFocusKeyword.includes(clearedSearchTerm) ||
        clearedUrl.includes(clearedSearchTerm) ||
        clearedTitle.includes(clearedSearchTerm)
      );
    });
  }

  return contents;
};

const applyAllFilters = (pagesSearchFiltered, filters, history, organizationId, workspaceId) => {
  try {
    return applyFilters(pagesSearchFiltered, filters);
  } catch {
    history.replace(`/o/${organizationId}/w/${workspaceId}/planning?tab=open`);
    return pagesSearchFiltered;
  }
};

const filterContents = (contents, filter, userId, normalizedFolders, defaultFolder) => {
  if (filter === DEFAULT_TAB) {
    return contents
      .filter((content) => content.type === DRAFT_TYPE)
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  } else if (filter === 'published') {
    return contents
      .filter((content) => content.type === PUBLISHED_TYPE)
      .sort((a, b) => b.publishedAt - a.publishedAt);
  } else if (filter === 'my-drafts') {
    return contents
      .filter((content) => content.assignedTo?.id === userId && content.type === DRAFT_TYPE)
      .sort((a, b) => new Date(b.publishedAt) - new Date(a.publishedAt));
  } else if (filter.startsWith('folder-')) {
    const folderId = filter.split('-')[1];

    return contents
      .filter((content) => {
        if (folderId === defaultFolder?.id) {
          return content.folderId === defaultFolder?.id;
        }
        return content.folderId === folderId;
      })
      .sort((a, b) => {
        const aName = normalizedFolders.entities?.[a.folderId]?.name || defaultFolder?.name;
        const bName = normalizedFolders.entities?.[b.folderId]?.name || defaultFolder?.name;
        return aName.localeCompare(bName);
      });
  } else {
    return contents.slice().sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  }
};

const incrementPropertyValue = (obj, id) => (obj[id] = ++obj[id] || 1);

const getCount = (contents, userId, defaultFolder) =>
  contents.reduce((acc, content) => {
    // Open drafts: DEFAULT_TAB
    if (content.type === DRAFT_TYPE) {
      incrementPropertyValue(acc, DEFAULT_TAB);
    }

    // Published drafts: 'published'
    if (content.type === PUBLISHED_TYPE) {
      incrementPropertyValue(acc, 'published');
    }

    // My drafts: 'my-drafts'
    if (content.assignedTo?.id === userId && content.type === DRAFT_TYPE) {
      incrementPropertyValue(acc, 'my-drafts');
    }

    // Folders
    if (content.folderId) {
      incrementPropertyValue(acc, content.folderId);
    } else {
      incrementPropertyValue(acc, defaultFolder?.id);
    }

    // All drafts: 'all'
    incrementPropertyValue(acc, 'all');

    return acc;
  }, {});

const getSelectedListIds = (visibleRows, contentsIds) => {
  return contentsIds.filter((id) => visibleRows.findIndex((row) => row.id === id) !== -1);
};

const getSelectedList = (visibleRows, contentsIds) => {
  return visibleRows.filter((elem) => contentsIds.includes(elem.id));
};

function getSelectedListWithFocusKeyword(visibleRows, contentsIds) {
  return visibleRows.filter(
    (elem) => !!elem.pageFocusTopKeyword?.keyword && contentsIds.includes(elem.id)
  );
}

const INITIAL_STATE_CONTENT = {
  contentId: null,
  createdAt: null,
  errorPublish: null,
  publishPending: false,
  score: null,
  title: null,
  url: null,
};

function Planning() {
  const REFETCH_INTERVAL_IN_MS = parseInt(useApiConfigurations(LISTING_REFETCH_FREQUENCY_MS), 10);
  const { t } = useTranslation();
  const DEFAULT_FOLDER_GENERAL = getDefaultFolderGeneral();
  const nullUser = useNullUser();
  const queryClient = useQueryClient();
  const trackMixpanelEvent = useMixpanelTrackEvent();

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [query, setQuery] = useSearchParams();
  const { organizationId, workspaceId } = useParams();
  const contentVersionHistoryEnabled = useApiConfigurations(CONTENT_VERSION_HISTORY_ENABLED);

  const tab = query.get('tab') || DEFAULT_TAB;
  const isInFolderTab = tab.startsWith('folder-');

  const currentWorkspaceWebsiteUrl = useSelector(selectCurrentWorkspaceWebsiteUrl);
  const userId = useSelector((state) => state.user?.id);
  const users = useWorkspaceUsers();
  // Tricks to prevent stale closure in onChangeAssignedTo function.
  const latestUsersValues = useRef(users);
  latestUsersValues.current = [...users];

  const [contentsIds, setContentsIds] = useState([]);
  const [exportError, setExportError] = useState(null);
  const [bulkAnalysisModal, setBulkAnalysisModal] = useState({ loading: false, show: false });
  const [isInsufficientCreditsModalOpen, setIsInsufficientCreditsModalOpen] = useState(false);
  const [isBulkDeleteDialogOpen, setIsBulkDeleteDialogOpen] = useState(false);
  const [isBulkMarkAsPublishedDialogOpen, setIsBulkMarkAsPublishedDialogOpen] = useState(false);
  const [isExportDialogOpen, setIsExportDialogOpen] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [pageToDelete, setPageToDelete] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);
  const [contentToMarkAsPublished, setContentToMarkAsPublished] = useState(INITIAL_STATE_CONTENT);
  const [isExportCalendarOpen, setIsExportCalendarOpen] = useState(false);
  const { isSidebarOpen } = usePageDataLayout();

  const isPlanningSyncCalendarEnabled = useCan({ perform: 'planning:sync_calendar' });

  const isUserLimitedGuestWriter = useIsUserLimitedGuestWriter();

  const currentOrganization = useSelector((state) => getOrganizationById(state, organizationId));
  const currentOrganizationTrackedPages = useSelector(
    (state) => state.organizations.byId[organizationId]?.trackedPages
  );
  const { data: rankTrackingLimit } = useOrganizationFeatureSet(
    'rank-tracking:urls-tracked-number'
  );
  const { isFeatureEnabled: hasUnlimitedRankTrackingUrls } = useOrganizationFeatureSet(
    'rank-tracking:urls-tracked-number:has-unlimited-amount'
  );
  const { isFeatureEnabled: organizationRankTrackingEnabled } = useOrganizationFeatureSet(
    'rank-tracking:is-enabled'
  );

  const { isFeatureEnabled: hasUnlimitedAnalysisCredits } = useOrganizationFeatureSet(
    'credits:analysis:has-unlimited-amount'
  );

  const remainingAnalysisUnit = useSelector(selectRemainingAnalysisUnit);
  const isSuccessMarkAsPublishedDialogOpen = useParamSelector(
    selectDialogPropertyByName,
    SUCCESS_MARK_AS_PUBLISHED_DIALOG,
    'isOpen'
  );

  const { isFeatureEnabled: unlimitedFoldersFeatureSet } = useOrganizationFeatureSet(
    'organization:folders:has-unlimited-amount'
  );
  const { data: foldersLimit = 0 } = useOrganizationFeatureSet('organization:folders:amount');
  const isOrganizationFoldersLimit =
    currentOrganization.foldersCount >= foldersLimit && !unlimitedFoldersFeatureSet;

  const contentsWithSpecificProperties = useGetCurrentWorkspaceContentsWithSpecificProperties({
    filters: {
      inStock: false,
      type: { not_equals: 'ORIGINAL' },
    },
    location: LOCATION.PLANNING,
    properties: [
      'id',
      'createdAt',
      'updatedAt',
      'title',
      'type',
      'publishedAt',
      'pageId',
      'assignedToId',
      'dueDate',
      'contentStatusId',
      'contentScore',
      'redirectionUrl',
      'workspaceId',
      'pageFocusTopKeywordId',
      'folderId',
    ],
    refetchInterval: REFETCH_INTERVAL_IN_MS,
    refetchOnWindowFocus: 'always',
  });

  const pagesWithSpecificProperties = useGetCurrentWorkspacePagesWithSpecificProperties({
    location: LOCATION.PLANNING,
    properties: [
      'id',
      'redirectionUrl',
      'titleRetrievedAt',
      'source',
      'new',
      'url',
      'onlineContent',
      'lastStatusCode',
    ],
    refetchInterval: REFETCH_INTERVAL_IN_MS,
    refetchOnWindowFocus: 'always',
  });

  const focusTopKeywordsWithSpecificProperties =
    useGetCurrentWorkspaceFocusTopKeywordsWithSpecificProperties({
      filters: { inStock: false },
      location: LOCATION.PLANNING,
      properties: [
        'id',
        'keyword',
        'keywordDataUpdatedAt',
        'searchVolume',
        'analysisStatus',
        'associatedToPagesCount',
        'associatedToDraftsCount',
      ],
      refetchInterval: REFETCH_INTERVAL_IN_MS,
      refetchOnWindowFocus: 'always',
    });

  const contentsStatuses = useGetCurrentWorkspaceContentsStatuses({
    location: LOCATION.PLANNING,
    refetchInterval: REFETCH_INTERVAL_IN_MS,
    refetchOnWindowFocus: 'always',
  });

  const updateContent = usePutContents({
    onMutate: async ({ contentIds, optimisticUIChanges }) => {
      const queryKey = [SCOPE_CONTENTS.WITH_SPECIFIC_PROPERTIES, workspaceId, LOCATION.PLANNING];
      await queryClient.cancelQueries({ queryKey });
      const previousData = queryClient.getQueryData(queryKey);
      const newData = [
        ...previousData.map((currContent) => {
          const data = { ...currContent, ...optimisticUIChanges };

          return contentIds.includes(currContent.id)
            ? isUserLimitedGuestWriter && data.assignedToId !== userId
              ? null
              : data
            : currContent;
        }),
      ];
      queryClient.setQueryData(queryKey, newData);
      return { previousData };
    },
    onSettled: refetchListing,
  });

  const deleteContent = useDeleteContentById({
    onMutate: async (contentIds) => {
      const queryKey = [SCOPE_CONTENTS.WITH_SPECIFIC_PROPERTIES, workspaceId, LOCATION.PLANNING];
      await queryClient.cancelQueries({ queryKey });
      const previousData = queryClient.getQueryData(queryKey);
      const newData = [
        ...previousData.filter((currContent) => !contentIds.includes(currContent.id)),
      ];
      queryClient.setQueryData(queryKey, newData);
      return { previousData };
    },
    onSettled: refetchListing,
  });

  const publishContent = usePostPublishContent({
    onError: (error) => {
      setContentToMarkAsPublished({
        ...contentToMarkAsPublished,
        errorPublish: { status: error.response.status },
        publishPending: false,
      });
    },
    onSuccess: () => {
      refetchListing();
      dispatch(setDialogState(SUCCESS_MARK_AS_PUBLISHED_DIALOG, { dratfCount: 1, isOpen: true }));
      setContentToMarkAsPublished({
        ...contentToMarkAsPublished,
        contentId: null,
        publishPending: false,
      });
      trackMixpanelEvent(CONTENT_PUBLISHED, { ...contentToMarkAsPublished });
    },
  });

  const topKeywordAnalysis = usePostTopKeywordAnalysis({
    onSettled: () => {
      refetchListing();
    },
  });

  const refreshContentData = usePostRefreshContentData();

  function refetchListing() {
    refetchFolderIfLimitedGuestWriter();
    contentsWithSpecificProperties.refetch();
    focusTopKeywordsWithSpecificProperties.refetch();
    pagesWithSpecificProperties.refetch();
  }

  const isLoading =
    (contentsWithSpecificProperties.isPlaceholderData &&
      contentsWithSpecificProperties.isFetching) ||
    (focusTopKeywordsWithSpecificProperties.isPlaceholderData &&
      focusTopKeywordsWithSpecificProperties.isFetching) ||
    (pagesWithSpecificProperties.isPlaceholderData && pagesWithSpecificProperties.isFetching) ||
    (contentsStatuses.isPlaceholderData && contentsStatuses.isFetching);

  const folders = useGetCurrentWorkspaceAllFolders();
  const normalizedFolders = useMemo(
    () =>
      folders.data?.reduce(
        (acc, folder) => ({
          entities: { ...acc.entities, [`${folder.id}`]: folder },
          ids: [...acc.ids, folder.id],
        }),
        { entities: {}, ids: [] }
      ),
    [folders.data]
  );

  const filtersList = useMemo(
    () =>
      getFiltersList({
        folders: folders.data,
        nullUser,
        status: contentsStatuses.data,
        users,
      }),
    [contentsStatuses.data, folders.data, nullUser, users]
  );

  const filters = useMapUrlQueryToFilter({
    filterList: filtersList,
    onError,
  });

  const focusTopKeywordsById = focusTopKeywordsWithSpecificProperties.data.reduce(
    listByPropertyReducer('id'),
    {}
  );
  const contentStatusesById = contentsStatuses.data.reduce(listByPropertyReducer('id'), {});
  const usersById = users.reduce(listByPropertyReducer('id'), {});
  const pagesById = pagesWithSpecificProperties.data.reduce(listByPropertyReducer('id'), {});
  const foldersById = folders.data.reduce(listByPropertyReducer('id'), {});

  const preparedContents = contentsWithSpecificProperties.data.flatMap((item) =>
    item?.type !== 'ORIGINAL'
      ? [
          {
            ...item,
            assignedTo: usersById[item?.assignedToId] ?? null,
            contentStatus: contentStatusesById[item?.contentStatusId] ?? null,
            folderId: foldersById[item?.folderId]?.id || DEFAULT_FOLDER_GENERAL.id,
            page: pagesById[item?.pageId] ?? null,
            pageFocusTopKeyword: focusTopKeywordsById[item?.pageFocusTopKeywordId] ?? null,
          },
        ]
      : []
  );

  const pagesSearchFiltered = searchFilter(searchTerm, preparedContents);
  const pagesFilterFiltered = applyAllFilters(
    pagesSearchFiltered,
    filters,
    navigate,
    organizationId,
    workspaceId
  );
  const pagesFiltered = filterContents(
    pagesFilterFiltered,
    tab,
    userId,
    normalizedFolders,
    DEFAULT_FOLDER_GENERAL
  );
  const count = getCount(pagesFilterFiltered, userId, DEFAULT_FOLDER_GENERAL);
  const selectedList = getSelectedList(pagesFiltered, contentsIds);
  const selectedListIds = getSelectedListIds(pagesFiltered, contentsIds);
  const selectedListWithFocusKeyword = getSelectedListWithFocusKeyword(pagesFiltered, contentsIds);
  const drafts = selectedList.filter((elem) => elem.type === DRAFT_TYPE);
  const contentExportSyncLimitation = parseInt(
    useApiConfigurations(CONTENT_EXPORT_SYNC_LIMITATION),
    10
  );

  const isContentExportAsync = selectedListIds.length >= contentExportSyncLimitation;
  const isEverySelectedItemPublished = selectedList.every((item) => !!item.contentStatus?.publish);
  const isAllCheckBoxChecked = useMemo(
    () =>
      pagesFiltered.every((page) => contentsIds.includes(page.id)) &&
      pagesFiltered.length &&
      !isLoading,
    [pagesFiltered, contentsIds, isLoading]
  );
  const hasSelectedListSomeKeyword = selectedListWithFocusKeyword.length > 0;
  const canOpenBulkAnalysisDialog = bulkAnalysisModal.show && hasSelectedListSomeKeyword;
  const hasSearchConsole = useSelector(selectHasSearchConsole);
  const workspaceCountryName = useSelector(selectCurrentWorkspaceCountryName);

  function onError() {
    navigate(`/o/${organizationId}/w/${workspaceId}/planning?tab=open`, { replace: true });
  }

  function onChangeContentStatus({ id, pageUrl, contentId, score, createdAt, title }) {
    const contentStatus = contentsStatuses.data.find((status) => status.id === id);

    if (contentStatus.publish) {
      setContentToMarkAsPublished({
        ...contentToMarkAsPublished,
        contentId,
        createdAt,
        score,
        title,
        url: pageUrl,
      });
    } else {
      updateContent.mutate({
        content: {
          contentStatus: contentStatus?.['@id'],
        },
        contentIds: [contentId],
        optimisticUIChanges: {
          contentStatusId: contentStatus.id,
        },
      });
    }
  }

  function handleSelectChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setContentsIds([...(value ? [...contentsIds, name] : contentsIds.filter((id) => id !== name))]);
  }

  function handleSelectAllChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    setContentsIds([
      ...(value
        ? [
            ...contentsIds,
            ...pagesFiltered
              .map((content) => content.id)
              .filter((content) => !contentsIds.find((id) => content === id)),
          ]
        : contentsIds.filter((id) => !pagesFiltered.find((page) => page.id === id))),
    ]);
  }

  function handleSearchChange(e) {
    const searchTerm = e.currentTarget.value.toLowerCase();

    setSearchTerm(searchTerm);
  }

  function handleTabChange({ tab }) {
    query.set('tab', tab);
    setQuery(query);
    setContentsIds([]);
  }

  function handleDelete(id, onlineContentId, pageId) {
    if (id !== onlineContentId) {
      setPageToDelete({ id, onlineContentId, pageId });
    }
  }

  function confirmDeleteDraft() {
    if (pageToDelete.id !== pageToDelete.onlineContentId) {
      deleteContent.mutate([pageToDelete.id]);
      setPageToDelete(null);
    }
  }

  function onCancelDeleteDraft() {
    setPageToDelete(null);
  }

  function handleExportCalendarDialog() {
    trackMixpanelEvent(PLANNING_SYNCHRONIZE_AGENDA_CLICK);
    setIsExportCalendarOpen(!isExportCalendarOpen);
  }

  async function refetchFolderIfLimitedGuestWriter() {
    if (isUserLimitedGuestWriter) {
      folders.refetch();
    }
  }

  function handleAssignedToBulk(userId) {
    let assignedTo = latestUsersValues?.current?.find((user) => user.id === userId);

    updateContent.mutate({
      content: {
        assignedTo: assignedTo?.id === nullUser.id ? null : assignedTo?.['@id'],
      },
      contentIds: selectedListIds,
      optimisticUIChanges: {
        assignedToId: assignedTo?.id === nullUser.id ? null : assignedTo?.id,
      },
    });
  }

  async function handleStatusBulk(statusId) {
    const contentStatus = contentsStatuses.data.find((status) => status.id === statusId);

    if (contentStatus.publish) {
      openBulkMarkAsPublishedDialog();
    } else {
      updateContent.mutate({
        content: {
          contentStatus: contentStatus?.['@id'],
        },
        contentIds: drafts.map((d) => d.id), //we can only change drafts status
        optimisticUIChanges: {
          contentStatusId: contentStatus.id,
        },
      });
    }
  }

  function confirmBulkDeleteDraft(selectedListIds) {
    deleteContent.mutate(selectedListIds);
    setIsBulkDeleteDialogOpen(false);
  }

  async function exportContents(contentsIds = [], fileType = FileType.XLSX) {
    let isAsync = false;

    try {
      const contentCollectionService = new ContentCollection(workspaceId, contentsIds);
      const response = await contentCollectionService.export(fileType);
      if (true === response?.isAsync) {
        isAsync = true;
      } else {
        downloadFileFromBlob({ fileBlob: response, filePrefix: 'export', fileType });
      }
    } catch (error) {
      Log.report({
        context: 'exportContents',
        error,
        extra: 'Export list of contents',
        section: SECTIONS.listing.key,
      });
      dispatch(showErrorSnackbar(t('common:error.default')));
    }

    return { isAsync };
  }

  async function exportSelectedList(selectedListIds, fileType) {
    try {
      setIsExporting(true);

      if (selectedListIds.length === 0) {
        return;
      }

      const response = await exportContents(selectedListIds, fileType);
      if (response.isAsync) {
        dispatch(showSuccessSnackbar(t('listing:pages-list.notification.async-export-success')));
      }
    } catch (error) {
      Log.report({
        context: 'exportSelectedList',
        error,
        extra: 'Export the selected drafts',
        section: SECTIONS.pages.key,
      });
      setExportError(error);
    } finally {
      setIsExporting(false);
      setIsExportDialogOpen(false);
    }
  }

  function deleteElementInSelectedList(idToDelete) {
    setContentsIds([...contentsIds.filter((id) => id !== idToDelete)]);
  }

  function applyFiltersToUrl(filters = {}) {
    mapFiltersToQueryParameter({ filters, location, navigate, query, setQuery });
  }

  function openExportDialog() {
    setIsExportDialogOpen(true);
  }

  function closeExportDialog() {
    setIsExportDialogOpen(false);
  }

  function openBulkMarkAsPublishedDialog() {
    if (!isEverySelectedItemPublished) {
      if (drafts.length === 1) {
        const content = drafts[0];
        setContentToMarkAsPublished({
          ...contentToMarkAsPublished,
          contentId: content.id,
          createdAt: content.createdAt,
          pageId: content.page?.id,
          score: content.contentScore,
          title: content.title,
          url: content.page?.url,
        });
      } else {
        setIsBulkMarkAsPublishedDialogOpen(true);
      }
    }
  }

  function closeBulkMarkAsPublishedDialog() {
    setIsBulkMarkAsPublishedDialogOpen(false);
  }

  function openBulkDeleteDialog() {
    setIsBulkDeleteDialogOpen(true);
  }

  function closeBulkDeleteDialog() {
    setIsBulkDeleteDialogOpen(false);
  }

  function onConfirmBulkDeleteDraft() {
    confirmBulkDeleteDraft(selectedListIds);
  }

  function displayInsufficientCreditsModal() {
    setIsInsufficientCreditsModalOpen(true);
  }

  function onCloseInsufficientCreditModal() {
    setIsInsufficientCreditsModalOpen(false);
  }

  function openBulkAnalysisDialog() {
    setBulkAnalysisModal({ ...bulkAnalysisModal, show: true });
  }

  function closeBulkAnalysis() {
    setBulkAnalysisModal({ loading: false, show: false });
  }

  async function triggerAnalysis(contents = [], source) {
    if (
      !hasUnlimitedAnalysisCredits &&
      remainingAnalysisUnit - getCreditsCost(contents.length) < 0
    ) {
      displayInsufficientCreditsModal();
      return;
    }

    contents.forEach(async (content) => {
      const fkArr = [content.pageFocusTopKeyword?.id];
      const originalContent = pagesFiltered.filter((pc) => pc.id === content.id);
      fkArr.push(originalContent?.[0]?.pageFocusTopKeyword?.id);

      // Optimistic Update of Focus keyword
      const queryKeyFks = [
        SCOPE_FOCUS_TOP_KEYWORDS.WITH_SPECIFIC_PROPERTIES,
        workspaceId,
        LOCATION.PLANNING,
      ];
      const previousFks = queryClient.getQueryData(queryKeyFks);
      const newFks = [
        ...previousFks.map((fk) => {
          if (fkArr.includes(fk.id)) {
            fk.analysisStatus = STATUS_PENDING;
            fk.searchVolume = null;
          }
          return fk;
        }),
      ];
      queryClient.setQueryData(queryKeyFks, newFks);

      if (!content.contentScore) {
        const {
          html,
          page: { url },
        } = await fetchContent(content.id);

        if (!html && url) {
          refreshContentData.mutate(content.id);
        }
      }
    });

    return topKeywordAnalysis.mutate({
      data: {
        source,
      },
      topKeywordIdList: contents.map((content) => content.pageFocusTopKeyword.id),
    });
  }

  async function onConfirmBulkAnalysis() {
    setBulkAnalysisModal({ ...bulkAnalysisModal, loading: true });
    await triggerAnalysis(selectedListWithFocusKeyword, SOURCE_PLANNING_BULK);
    closeBulkAnalysis();
  }

  function onChangeFolder(contentId) {
    return (folderId) => {
      updateContent.mutate({
        content: {
          folderId: folderId === DEFAULT_FOLDER_GENERAL.id ? null : folderId,
        },
        contentIds: [contentId],
        optimisticUIChanges: {
          folderId: folderId === DEFAULT_FOLDER_GENERAL.id ? null : folderId,
        },
      });
    };
  }

  function onChangeDueDate(dueDate, contentId) {
    updateContent.mutate({
      content: {
        dueDate,
      },
      contentIds: [contentId],
      optimisticUIChanges: {
        dueDate,
      },
    });
  }

  function handleConfirmPublish(url, publishDate) {
    if (isUrlValidWithWebSiteUrl(url, currentWorkspaceWebsiteUrl)) {
      setContentToMarkAsPublished({ ...contentToMarkAsPublished, publishPending: true });
      publishContent.mutate({
        contentId: contentToMarkAsPublished.contentId,
        date: publishDate,
        url,
      });
    } else {
      setContentToMarkAsPublished({
        ...contentToMarkAsPublished,
        errorPublish: 'InvalidUrl',
        publishPending: false,
      });
    }
  }

  function handleCancelPublish() {
    setContentToMarkAsPublished(INITIAL_STATE_CONTENT);
  }

  function onChangeAssignedTo(id, contentId) {
    let assignedTo = latestUsersValues?.current?.find((user) => user.id === id);

    updateContent.mutate({
      content: {
        assignedTo: assignedTo?.id === nullUser.id ? null : assignedTo?.['@id'],
      },
      contentIds: [contentId],
      optimisticUIChanges: {
        assignedToId: assignedTo?.id === nullUser.id ? null : assignedTo?.id,
      },
    });
  }

  function handleChangeFolderIdBulk(folderId) {
    updateContent.mutate({
      content: {
        folderId: folderId === DEFAULT_FOLDER_GENERAL.id ? null : folderId,
      },
      contentIds: selectedListIds,
      optimisticUIChanges: {
        folderId: folderId === DEFAULT_FOLDER_GENERAL.id ? null : folderId,
      },
    });
  }

  function handleChangeDueDateBulk(dueDate) {
    updateContent.mutate({
      content: {
        dueDate,
      },
      contentIds: selectedListIds,
      optimisticUIChanges: {
        dueDate,
      },
    });
  }

  function handleConfirmExport(fileType) {
    exportSelectedList(selectedListIds, fileType);
  }

  const createNewFolder = usePostFolder({
    onSuccess: ({ data }) => {
      onChangeFolder(data);
    },
  });

  function handleNewFolderCreation(newFolder) {
    createNewFolder.mutate(newFolder);
  }

  const columns = getColumns({
    DEFAULT_FOLDER_GENERAL,
    canAccessToVersionHistory: contentVersionHistoryEnabled,
    contentsIds,
    createNewFolder: handleNewFolderCreation,
    folders,
    handleDelete,
    handleSelectAllChange,
    handleSelectChange,
    hasSearchConsole,
    isAllCheckBoxChecked,
    isLoading,
    isOrganizationFoldersLimit,
    normalizedFolders,
    onChangeAssignedTo,
    onChangeContentStatus,
    onChangeDueDate,
    onChangeFolder,
    organizationId,
    setContentToMarkAsPublished,
    status: contentsStatuses.data,
    t,
    tab,
    triggerAnalysis,
    users,
    workspaceCountryName,
  });

  useEffect(() => {
    if (
      isUserLimitedGuestWriter &&
      Object.keys(count).length &&
      !folders.isRefetching &&
      !folders.isPlaceholderData
    ) {
      folders.data.forEach(async (folder) => {
        const usedAsParent = folders.data.find((f) => f.parentFolderId === folder.id);
        if (!count[folder.id] && !usedAsParent) {
          const queryKey = [SCOPE_FOLDERS.GET_ALL_BY_WORKSPACE_ID, workspaceId];
          await queryClient.cancelQueries({ queryKey });
          const previousData = queryClient.getQueryData(queryKey);
          queryClient.setQueryData(
            queryKey,
            previousData.filter((f) => f.id !== folder.id)
          );
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  useEffect(() => {
    if (isInFolderTab && !folders.isRefetching && !folders.isPlaceholderData) {
      const folderId = tab.split('-')[1];
      if (
        folderId !== DEFAULT_FOLDER_GENERAL.id &&
        !folders.data.find((folder) => folder.id === folderId)
      ) {
        handleTabChange({ tab: `folder-${DEFAULT_FOLDER_GENERAL.id}` });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInFolderTab, folders.data]);

  useEffect(() => {
    return () => {
      folders.remove();
      contentsWithSpecificProperties.remove();
      pagesWithSpecificProperties.remove();
      contentsStatuses.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageDataLayout
      sideBar={{
        content: (
          <div className="flex flex-col h-full">
            <FiltersContainer>
              <TableFilters
                data-intercom-target="planning_side_bar_table_views"
                selectedFilter={tab}
                tableFilters={[
                  {
                    attribute: '',
                    collapsible: false,
                    filters: [
                      {
                        attribute: 'open',
                        count: count[DEFAULT_TAB],
                        href: { tab: DEFAULT_TAB },
                        icon: <PencilIcon opacity={0.75} />,
                        label: t('listing:planning-list:view-panel.open-drafts'),
                      },
                      {
                        attribute: 'my-drafts',
                        color: 'dark100',
                        count: count['my-drafts'],
                        href: { tab: 'my-drafts' },
                        icon: <PenBoxIcon opacity={0.75} />,
                        label: t('listing:planning-list:view-panel.my-open-drafts'),
                      },
                      {
                        attribute: 'published',
                        color: 'dark100',
                        count: count['published'],
                        href: { tab: 'published' },
                        icon: <CheckboxIcon opacity={0.75} />,
                        label: t('listing:planning-list:view-panel.published'),
                      },
                      {
                        attribute: 'all',
                        color: 'dark100',
                        count: count['all'],
                        href: { tab: 'all' },
                        icon: <DoubleFolderIcon opacity={0.75} />,
                        label: t('listing:planning-list:view-panel.all'),
                      },
                    ],
                  },
                ]}
                onClick={handleTabChange}
              />
            </FiltersContainer>
            <FolderFiltersPanel count={count} selectedFilter={tab} onClick={handleTabChange} />
          </div>
        ),
        dataIntercomTarget: 'product_tour_planning',
        titleContent: <span>{t('listing:planning-list:planning')}</span>,
      }}
    >
      <MainContent flexDirection="row" isSidebarOpen={isSidebarOpen}>
        <Wrapper flex={3} flexDirection="column" isSidebarOpen={isSidebarOpen}>
          <ListingHeaderContainer margin="0 0 50px 0">
            <SidebarToggle />
            <FlexWithMargin flex={1} height="100%">
              {!isLoading && (
                <FilterGroups
                  applyFilters={applyFiltersToUrl}
                  createNewFolder={handleNewFolderCreation}
                  defaultFilters={filters}
                  filtersList={filtersList}
                  folders={folders}
                  isCreationDisabled={isOrganizationFoldersLimit}
                />
              )}
            </FlexWithMargin>
            <Flex alignItems="center" gap="15px" maxHeight="35px">
              <InputRevealer context="Planning" handleChange={handleSearchChange}>
                <StyledSearchIcon />
              </InputRevealer>
              {isPlanningSyncCalendarEnabled && (
                <TooltipComponent title={t('listing:planning-list.export-calendar.tooltip')}>
                  <Flex data-intercom-target="ical-sync">
                    <CalendarIcon onClick={handleExportCalendarDialog} />
                  </Flex>
                </TooltipComponent>
              )}
              <NavButton
                data-intercom-target="planning_create_drafts_button"
                to={`create-drafts?tab=${tab}`}
                onClick={() => trackMixpanelEvent(PLANNING_NEW_DRAFT_CLICK)}
              >
                {t('listing:planning-list:create-draft')}
              </NavButton>
            </Flex>
          </ListingHeaderContainer>
          <Container flex={1} isSidebarOpen={isSidebarOpen}>
            {!pagesFiltered.length && !isLoading ? (
              <TableFlexStyledCard mode="transparent">
                <NoDraft
                  folderName={
                    tab.split('-')[1] === DEFAULT_FOLDER_GENERAL.id
                      ? DEFAULT_FOLDER_GENERAL.name
                      : folders.data.find(({ id }) => id === tab.split('-')[1])?.name
                  }
                  version={
                    tab === 'my-drafts'
                      ? NO_DRAFT_ASSIGNED
                      : tab === 'published'
                        ? NO_DRAFT_PUBLISHED
                        : tab.includes('folder-')
                          ? NO_DRAFT_FOLDER
                          : null
                  }
                />
              </TableFlexStyledCard>
            ) : (
              <Table
                columns={columns}
                customHeader={
                  <TableActionBar>
                    <DarkText>
                      {t('listing:bulk-actions.pages-selected_interval', {
                        count: selectedListIds.length,
                        postProcess: 'interval',
                      })}
                    </DarkText>

                    <BulkActionButton
                      disabled={!hasSelectedListSomeKeyword}
                      onClick={openBulkAnalysisDialog}
                    >
                      <SmallFlexTooltipComponent
                        title={t(
                          hasSelectedListSomeKeyword
                            ? 'listing:planning-list.bulk-actions.analyze'
                            : 'listing:planning-list.bulk-actions.no-fk-defined'
                        )}
                      >
                        <Analyze2Icon /> {t('listing:planning-list.bulk-actions.analyze')}
                      </SmallFlexTooltipComponent>
                    </BulkActionButton>

                    <BulkActionButton
                      disabled={isEverySelectedItemPublished}
                      onClick={openBulkMarkAsPublishedDialog}
                    >
                      <SmallFlexTooltipComponent
                        title={t(
                          isEverySelectedItemPublished
                            ? 'listing:planning-list.bulk-actions.already-published'
                            : 'listing:planning-list.bulk-actions.mark-as-published'
                        )}
                      >
                        <MarkAsPublishedIcon />
                        <span>{t('listing:planning-list.bulk-actions.mark-as-published')}</span>
                      </SmallFlexTooltipComponent>
                    </BulkActionButton>

                    <BulkActionButton onClick={openExportDialog}>
                      <SmallFlexTooltipComponent
                        title={t('listing:planning-list.bulk-actions.export')}
                      >
                        <FileExportOutline /> {t('listing:planning-list.bulk-actions.export')}
                      </SmallFlexTooltipComponent>
                    </BulkActionButton>

                    <VerticalSeparator />

                    <UserSelect
                      centered
                      displayEmpty
                      handleChange={handleAssignedToBulk}
                      options={users}
                      renderValue={
                        <BulkActionIcon title={t('listing:planning-list.bulk-actions.assignation')}>
                          <User2Icon />
                        </BulkActionIcon>
                      }
                      shortVersion
                      small
                      variant="column"
                      width={300}
                    />

                    <BulkActionIcon title={t('listing:planning-list.bulk-actions.due-date')}>
                      <DatePicker key="bulk-action-due-date" onChange={handleChangeDueDateBulk} />
                    </BulkActionIcon>

                    <StyledStatusSelect
                      disabled={drafts.length === 0}
                      displayEmpty
                      handleChange={handleStatusBulk}
                      options={contentsStatuses.data}
                      renderValue={
                        <BulkActionIcon
                          title={
                            drafts.length === 0
                              ? t('listing:planning-list.bulk-actions.status-disabled')
                              : t('listing:planning-list.bulk-actions.status')
                          }
                        >
                          <StatusBulk />
                        </BulkActionIcon>
                      }
                      withLoading
                    />

                    <BulkActionIcon title={t('listing:planning-list.bulk-actions.add-to-folder')}>
                      <FolderSelect
                        createNewFolder={handleNewFolderCreation}
                        dropdownIconOnly
                        dropDownSize={DROPDOWN_SIZES.FULL_WIDTH}
                        folders={folders.data}
                        isCreationDisabled={isOrganizationFoldersLimit}
                        isFrame={false}
                        setValue={handleChangeFolderIdBulk}
                        translations={{
                          button: t('components:select.folder-select.create-folder.label-button'),
                          cancel: t('components:select.folder-select.create-folder.cancel'),
                          create_folder: t(
                            'components:select.folder-select.folder-select.label-create-folder'
                          ),
                          folder_general: t('components:select.folder-select.label-folder-general'),
                          label_no_parent: t(
                            'components:filters.folder-filters-panel.label-no-parent'
                          ),
                          loading: t('components:select.folder-select.create-folder.label-loading'),
                          placeHolder: t(
                            'components:select.folder-select.create-folder.input-placeholder'
                          ),
                          search_placeholder: t(
                            'components:select.folder-select.folder-select.search-placeholder'
                          ),
                          title: t('components:select.folder-select.create-folder.title'),
                          tooltip_disabled: t(
                            'components:select.folder-select.create-folder.tooltip-disabled'
                          ),
                        }}
                        value={DEFAULT_FOLDER_GENERAL.id}
                        width="280px"
                        withFolderCreation
                        withSearchInput
                      />
                    </BulkActionIcon>

                    <VerticalSeparator />

                    <BulkActionIcon
                      key={'bulk-actions-delete'}
                      title={t('listing:planning-list:bulk-actions.delete')}
                    >
                      <DeleteIcon size="small" onClick={openBulkDeleteDialog} />
                    </BulkActionIcon>
                  </TableActionBar>
                }
                defaultSort={tab === 'published' ? 'publicationDate' : 'dueDate'}
                defaultSortDirection={tab === 'published' ? 'DESC' : 'ASC'}
                disablePadding
                enableCustomHeader={selectedListIds.length > 0}
                mode="transparent"
                rows={isLoading ? LOADING_ROWS_PLACEHOLDER : pagesFiltered}
              />
            )}
          </Container>
        </Wrapper>
        {isExportCalendarOpen && <ExportCalendar onClose={handleExportCalendarDialog} />}
        {isBulkDeleteDialogOpen && (
          <AlertDialog
            confirmLabel={t('listing:planning-list:modal.delete')}
            show={true}
            text={t('listing:planning-list:modal.bulk-delete-drafts-text_interval', {
              count: selectedListIds.length,
              postProcess: 'interval',
            })}
            title={t('listing:planning-list:modal.bulk-delete-drafts-title_interval', {
              count: selectedListIds.length,
              postProcess: 'interval',
            })}
            onCancel={closeBulkDeleteDialog}
            onConfirm={onConfirmBulkDeleteDraft}
          />
        )}
        {!!pageToDelete && (
          <AlertDialog
            confirmLabel={t('listing:planning-list:modal.delete')}
            show={true}
            text={t('listing:planning-list:modal.delete-page-text')}
            title={t('listing:planning-list:modal.delete-page-title')}
            onCancel={onCancelDeleteDraft}
            onConfirm={confirmDeleteDraft}
          />
        )}
        {isExportDialogOpen && (
          <ExportDialog
            error={exportError}
            handleCancel={closeExportDialog}
            handleConfirm={handleConfirmExport}
            isAsync={isContentExportAsync}
            loading={isExporting}
            open={true}
            title={t('components:dialog.export.title_interval', {
              count: selectedListIds.length,
              postProcess: 'interval',
            })}
          />
        )}

        {isInsufficientCreditsModalOpen && (
          <CreditLimitReachedDialog
            creditType={ANALYSIS_CREDITS_TYPE}
            isOpen
            onClose={onCloseInsufficientCreditModal}
          />
        )}
        {canOpenBulkAnalysisDialog && (
          <BulkAnalysisDialog
            creditsCost={getCreditsCost(selectedListWithFocusKeyword.length)}
            isLoading={bulkAnalysisModal.loading}
            open
            pagesWithFkCount={selectedListWithFocusKeyword.length}
            selectedPageCount={selectedListIds.length}
            variant="planning"
            onClose={closeBulkAnalysis}
            onConfirm={onConfirmBulkAnalysis}
          />
        )}
        {isBulkMarkAsPublishedDialogOpen && (
          <BulkMarkAsPublishedDialog
            contents={contentsWithSpecificProperties}
            currentWorkspaceWebsiteUrl={currentWorkspaceWebsiteUrl}
            deleteElementInSelectedList={deleteElementInSelectedList}
            selectedList={drafts}
            onCancel={closeBulkMarkAsPublishedDialog}
          />
        )}
        {contentToMarkAsPublished.contentId && (
          <MarkAsPublishedDialog
            key={`${contentToMarkAsPublished.url}_${contentToMarkAsPublished.contentId}`}
            confirmPublishContent={handleConfirmPublish}
            createdAt={contentToMarkAsPublished.createdAt}
            currentWorkspaceWebsiteUrl={currentWorkspaceWebsiteUrl}
            error={contentToMarkAsPublished.errorPublish}
            handleCancel={handleCancelPublish}
            loading={contentToMarkAsPublished.publishPending}
            score={contentToMarkAsPublished.score}
            show={true}
            title={contentToMarkAsPublished.title}
            trackedKeywordsLimit={rankTrackingLimit}
            trackedKeywordsLimitExceeded={
              organizationRankTrackingEnabled &&
              !hasUnlimitedRankTrackingUrls &&
              contentToMarkAsPublished.isTracked !== true &&
              currentOrganizationTrackedPages >= rankTrackingLimit
            }
            url={contentToMarkAsPublished.url}
          />
        )}
        {isSuccessMarkAsPublishedDialogOpen && <SuccessMarkAsPublishedDialog />}
      </MainContent>
    </PageDataLayout>
  );
}

export default Planning;
