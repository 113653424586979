import styled from 'styled-components/macro';

import DsSelect, { Item, Items, Label, PlaceHolder } from '@/design-system/components/Select';

const Select = styled(DsSelect)`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.cssColors.dark020};
  border-radius: 0;
  background-color: transparent;
  color: ${({ theme }) => theme.cssColors.dark080};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;

  ${Label} {
    justify-content: flex-start;
    padding: 0;
  }

  ${PlaceHolder} {
    padding: 0;
  }

  ${Items} {
    border-radius: 0;
  }

  ${Item} {
    background-color: white;
    border-left: 2px solid transparent;

    &.selected {
      color: ${({ theme }) => theme.cssColors.dark020};
      background-color: ${({ theme }) => theme.cssColors.primary010};
      cursor: not-allowed;
    }

    &.hovered {
      background-color: ${({ theme }) => theme.cssColors.primary010};
      border-left: 2px solid ${({ theme }) => theme.cssColors.primary};
    }
  }
`;

export default Select;
