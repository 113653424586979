import createHighlight from './createHighlight';

function handleCreateElement({
  isHighlight,
  textContent,
  datum,
  highlightClass,
  dataAttribute,
}: {
  isHighlight: boolean;
  textContent: string;
  datum: string;
  highlightClass: string;
  dataAttribute: string;
}): Element | Text {
  if (isHighlight) {
    return createHighlight({ dataAttribute, datum, highlightClass, textContent });
  }

  return document.createTextNode(textContent);
}

export default handleCreateElement;
