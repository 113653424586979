import { nanoid } from 'nanoid';
import React, { useMemo } from 'react';

import SvgWrapper from './SvgWrapper';

function ThumbUpIcon(props) {
  const maskId = useMemo(() => nanoid(), []);

  return (
    <SvgWrapper {...props} fill="none" height="18" viewBox="0 0 17 18" width="17">
      <g clipPath={`url(#clip${maskId})`}>
        <mask
          height="17"
          id={`mask${maskId}`}
          maskUnits="userSpaceOnUse"
          style={{ maskType: 'alpha' }}
          width="18"
          x="0"
          y="0"
        >
          <path
            clipRule="evenodd"
            d="M13.1447 16.6152C13.7687 16.6152 14.3275 16.2289 14.548 15.6452L16.9064 9.40051C16.9704 9.23113 17.0032 9.05158 17.0032 8.87053L17.0032 7.40477C17.0032 6.57634 16.3316 5.90477 15.5032 5.90477L10.0859 5.90477L11.2002 2.58553C11.5265 1.61365 10.8034 0.608146 9.77819 0.608146L8.96778 0.608146C8.52445 0.608146 8.10383 0.80426 7.81882 1.14384L3.82307 5.90476L1.49991 5.90476C0.671481 5.90476 -9.25825e-05 6.57634 -9.26549e-05 7.40476L-9.33289e-05 15.1152C-9.34014e-05 15.9437 0.671478 16.6152 1.49991 16.6152L13.1447 16.6152ZM13.6124 15.2919C13.539 15.4865 13.3527 15.6152 13.1447 15.6152L4 15.6152L4 6.90476L4.05619 6.90476C4.20397 6.90476 4.34418 6.83939 4.43918 6.7262L8.5848 1.78671C8.6798 1.67352 8.82001 1.60815 8.96778 1.60815L9.77819 1.60815C10.1199 1.60815 10.361 1.94331 10.2522 2.26728L8.91661 6.24564C8.8654 6.39818 8.89073 6.56605 8.98468 6.69669C9.07862 6.82732 9.2297 6.90477 9.39061 6.90477L15.5032 6.90477C15.7793 6.90477 16.0032 7.12862 16.0032 7.40477L16.0032 8.87053C16.0032 8.93088 15.9922 8.99073 15.9709 9.04719L13.6124 15.2919ZM3 6.90476L1.49991 6.90476C1.22376 6.90476 0.999907 7.12862 0.999907 7.40476L0.999907 15.1152C0.999907 15.3914 1.22376 15.6152 1.49991 15.6152L3 15.6152L3 6.90476Z"
            fill="#252736"
            fillRule="evenodd"
          />
        </mask>
        <g mask={`url(#mask${maskId})`}>
          <rect fill="currentColor" height="20" width="20" x="-2" y="-0.5" />
        </g>
      </g>
      <defs>
        <clipPath id={`clip${maskId}`}>
          <rect fill="currentColor" height="17" transform="translate(0 0.5)" width="17" />
        </clipPath>
      </defs>
    </SvgWrapper>
  );
}

export default ThumbUpIcon;
