import i18next from 'i18next';

import { FilterOption } from '@/components/DynamicFilters';

export const BRANDED_TRAFFIC = 'branded-traffic';
export const UNBRANDED_TRAFFIC = 'unbranded-traffic';

export const BRAND_TRAFFIC_TYPES = [UNBRANDED_TRAFFIC, BRANDED_TRAFFIC];

// Options available for urlCategory
export const getBrandTrafficOptions = (filter?: string[]): FilterOption[] =>
  BRAND_TRAFFIC_TYPES.map((option) => ({
    id: option,
    label: i18next.t(`competitors:reports.labels.${option}`),
    selected: filter?.includes(option),
    value: option,
  }));
