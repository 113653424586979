import React from 'react';
import styled from 'styled-components/macro';

export const DEFAULT_LOADER_HEIGHT = 15;

export const LoaderWrapper = styled.div`
  flex: ${({ isFlex }) => isFlex && '1'};
  width: ${({ width }) => width && `${width}${String(width).includes('%') ? '' : 'px'}`};
  height: ${({ height }) => height && `${height}px`};
`;

export const CircularLoaderWrapper = styled.div`
  width: ${({ width }) => width && `${width}px`};
  height: ${({ height }) => height && `${height}px`};

  && > div {
    border-radius: 50%;
  }
`;

const Loader = styled.div`
  @keyframes placeHolderShimmer {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }
  && {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f9;
    background-image: linear-gradient(to right, #f6f7f9 0%, #e9ebee 20%, #f6f7f9 40%, #f6f7f9 100%);
    ${({ variant, theme }) =>
      variant === 'dark' &&
      `
      background: ${theme.cssColors.dark100};
      background-image: linear-gradient(to right, ${theme.cssColors.dark100} 0%, ${theme.cssColors.dark060} 20%, ${theme.cssColors.dark100} 40%, ${theme.cssColors.dark100} 100%);
    `}
    background-repeat: no-repeat;
    background-size: 800px 104px;
    height: 100%;
    width: 100%;
    position: relative;
    border-radius: 3px;
  }
`;

const FlatLoader = (props) => {
  return <Loader {...props} />;
};

export default FlatLoader;
