import React from 'react';

import SvgWrapper from './SvgWrapper';

const CircleIcon = ({ innerFill = 'none', radius = 7, stroke = 'currentColor', ...props }) => (
  <SvgWrapper {...props}>
    <circle cx="8" cy="8" fill={innerFill} r={radius} stroke={stroke} />
  </SvgWrapper>
);
export default CircleIcon;
