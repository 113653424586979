import './ReportTabs.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { matchRoutes, useLocation, useNavigate } from 'react-router-dom';
import { ENUM_TABS_VARIANTS, Tabs } from 'semji-core/components/Tabs';

import useCan from '@/hooks/useCan';
import { STATUS_FAILED, STATUS_PENDING, STATUS_QUEUED } from '@/utils/analysis';

interface ReportTabsProps {
  focusTopKeyword: any;
  disabled: boolean;
  mandatoryRecommendationsReady: boolean;
}

function ReportTabs({ disabled, focusTopKeyword, mandatoryRecommendationsReady }: ReportTabsProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const canVisitPerformancePanel = useCan({
    data: { accessGranted: true },
    perform: 'editor-performances-panel:visit',
  });

  const matchesCurrentSubTab = matchRoutes(
    [
      { path: '/o/:organizationId/w/:workspaceId/p/:pageId/:tab/:subtab/*' },
      { path: '/o/:organizationId/w/:workspaceId/p/:pageId/content/:contentId/:subtab/*' },
      {
        path: '/o/:organizationId/w/:workspaceId/p/:pageId/versions/:contentId/content-version/:versionId/:subtab/*',
      },
      { path: '/o/:organizationId/w/:workspaceId/p/:pageId/versions/:contentId/:subtab/*' },
    ],
    location
  );

  function handleClickTab(url: string) {
    return function () {
      navigate(url, { replace: true });
    };
  }

  return (
    <div className="report-tabs-container">
      <Tabs
        disabled={disabled}
        tabs={[
          {
            content: t('content:side-panel-components.tabs.optimizations'),
            'data-intercom-target': 'side_panel_tab_optimizations',
            onClick: handleClickTab(`optimizations`),
            selected: matchesCurrentSubTab?.[0].params?.subtab === 'optimizations',
          },
          {
            content: t('content:side-panel-components.tabs.competitors'),
            'data-intercom-target': 'side_panel_tab_competitors',
            disabled:
              disabled ||
              !focusTopKeyword.analyzed ||
              focusTopKeyword.analysisStatus === STATUS_FAILED ||
              focusTopKeyword.analysisStatus === STATUS_QUEUED ||
              (focusTopKeyword.analysisStatus === STATUS_PENDING && !mandatoryRecommendationsReady),
            onClick: handleClickTab('competitors'),
            selected: matchesCurrentSubTab?.[0].params?.subtab === 'competitors',
          },
          canVisitPerformancePanel && {
            content: t('content:side-panel-components.tabs.performances'),
            'data-intercom-target': 'side_panel_tab_performances',
            onClick: handleClickTab('performances'),
            selected: matchesCurrentSubTab?.[0].params?.subtab === 'performances',
          },
        ]}
        variant={ENUM_TABS_VARIANTS.Huge}
      />
    </div>
  );
}

export default ReportTabs;
