import React from 'react';

const WelcomeIllustration = ({ users, ...props }) => {
  return (
    <div {...props}>
      <svg
        fill="none"
        height="723"
        viewBox="0 0 918 723"
        width="918"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="595.5"
          cy="595.5"
          r="594.5"
          stroke="#E9E9EB"
          strokeDasharray="5 5"
          strokeWidth="2"
        />
        <circle cx="596" cy="604" r="441" stroke="#E9E9EB" strokeDasharray="5 5" strokeWidth="2" />
        <circle
          cx="595.5"
          cy="595.5"
          r="277.5"
          stroke="#E9E9EB"
          strokeDasharray="5 5"
          strokeWidth="2"
        />
        <rect fill="#F4F4F5" height="118" rx="20" width="118" x="258" y="487" />
        <mask
          height="118"
          id="mask0"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          width="118"
          x="258"
          y="487"
        >
          <rect fill="white" height="118" rx="20" width="118" x="258" y="487" />
        </mask>
        <g mask="url(#mask0)">
          <path
            clipRule="evenodd"
            d="M359.926 591.311C356.448 586.279 351.319 582.622 345.429 580.972L331.749 577.139V567.306C337.532 562.962 341.33 556.125 341.563 548.392C342.372 548.748 343.264 548.95 344.205 548.95C347.825 548.95 350.76 546.015 350.76 542.395C350.76 538.774 347.825 535.839 344.205 535.839C343.271 535.839 342.386 536.037 341.582 536.388V534.528C341.582 520.951 330.576 509.945 316.999 509.945H316.999C303.422 509.945 292.416 520.951 292.416 534.528V536.388C291.612 536.037 290.726 535.839 289.794 535.839C286.173 535.839 283.238 538.774 283.238 542.395C283.238 546.015 286.173 548.95 289.794 548.95C290.734 548.95 291.626 548.749 292.435 548.392C292.668 556.125 296.466 562.962 302.249 567.306V577.139L288.569 580.972C282.679 582.622 277.55 586.279 274.072 591.311L264.555 605.079V614.834H369.444V605.079L359.926 591.311Z"
            fill="#B5683C"
            fillRule="evenodd"
          />
          <path
            clipRule="evenodd"
            d="M316.999 572.222C313.967 572.222 311.066 571.67 308.385 570.667C312.198 573.103 316.727 574.517 321.588 574.517C325.213 574.517 328.651 573.726 331.749 572.317V567.306C327.64 570.392 322.533 572.222 316.999 572.222Z"
            fill="#A3420B"
            fillRule="evenodd"
          />
          <path
            d="M293.271 543.351C293.355 543.047 293.4 542.726 293.4 542.395C293.4 540.403 291.785 538.789 289.794 538.789C289.296 538.789 288.822 538.89 288.391 539.072"
            stroke="#252736"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.5"
          />
          <path
            d="M340.728 543.351C340.644 543.047 340.6 542.726 340.6 542.395C340.6 540.403 342.214 538.789 344.205 538.789C344.703 538.789 345.177 538.89 345.609 539.072"
            stroke="#252736"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.5"
          />
          <path
            clipRule="evenodd"
            d="M323.554 557.147C323.554 560.768 320.62 563.703 316.999 563.703C313.378 563.703 310.443 560.768 310.443 557.147H323.554Z"
            fill="white"
            fillRule="evenodd"
          />
          <path
            clipRule="evenodd"
            d="M330.44 543.378C330.44 544.826 329.266 546 327.818 546C326.369 546 325.195 544.826 325.195 543.378C325.195 541.93 326.369 540.755 327.818 540.755C329.266 540.755 330.44 541.93 330.44 543.378Z"
            fill="#252736"
            fillRule="evenodd"
          />
          <path
            clipRule="evenodd"
            d="M308.805 543.378C308.805 544.826 307.631 546 306.183 546C304.735 546 303.561 544.826 303.561 543.378C303.561 541.93 304.735 540.755 306.183 540.755C307.631 540.755 308.805 541.93 308.805 543.378Z"
            fill="#252736"
            fillRule="evenodd"
          />
          <path
            d="M317.001 540.755L320.278 551.9L315.689 552.555"
            stroke="#252736"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.5"
          />
          <path
            clipRule="evenodd"
            d="M344.206 535.839C344.899 535.839 345.566 535.948 346.193 536.147C346.469 535.612 346.63 535.007 346.63 534.362C346.63 532.899 345.829 531.625 344.644 530.948C346.395 529.924 347.575 528.029 347.575 525.855C347.575 522.897 345.396 520.455 342.558 520.028C343.008 519.224 343.268 518.297 343.268 517.31C343.268 514.232 340.773 511.738 337.696 511.738C336.549 511.738 335.484 512.085 334.598 512.678C334.599 512.639 334.601 512.6 334.601 512.561C334.601 508.578 331.373 505.35 327.39 505.35C325.663 505.35 324.079 505.958 322.837 506.97C321.752 504.846 319.549 503.389 317 503.389C314.452 503.389 312.248 504.846 311.163 506.97C309.922 505.958 308.337 505.35 306.61 505.35C302.628 505.35 299.399 508.578 299.399 512.561C299.399 512.6 299.402 512.639 299.402 512.678C298.516 512.085 297.451 511.738 296.305 511.738C293.227 511.738 290.732 514.232 290.732 517.31C290.732 518.297 290.992 519.224 291.443 520.028C288.605 520.455 286.426 522.897 286.426 525.855C286.426 528.029 287.605 529.924 289.357 530.948C288.172 531.625 287.371 532.899 287.371 534.362C287.371 535.007 287.531 535.612 287.806 536.148C288.433 535.948 289.101 535.839 289.795 535.839C292.039 535.839 294.018 536.968 295.2 538.688C298.556 537.419 301.036 534.367 301.484 530.687C302.544 531.255 303.754 531.578 305.04 531.578C308.115 531.578 310.757 529.737 311.93 527.098C313.252 528.35 315.036 529.119 317 529.119C318.965 529.119 320.748 528.35 322.07 527.098C323.243 529.737 325.886 531.578 328.96 531.578C330.246 531.578 331.457 531.255 332.517 530.687C332.964 534.367 335.445 537.419 338.801 538.688C339.982 536.968 341.961 535.839 344.206 535.839Z"
            fill="#252736"
            fillRule="evenodd"
          />
          <path
            clipRule="evenodd"
            d="M369.444 605.079L359.926 591.31C356.448 586.279 351.32 582.621 345.429 580.971L331.749 577.139C331.749 585.285 325.145 591.889 316.999 591.889C308.853 591.889 302.249 585.285 302.249 577.139L288.569 580.971C282.679 582.621 277.55 586.279 274.072 591.31L264.555 605.079V614.833H369.444V605.079Z"
            fill="#54CA95"
            fillRule="evenodd"
          />
        </g>
        <rect fill="#F4F4F5" height="87" rx="20" width="87" x="683" y="297" />
        <mask
          height="87"
          id="mask1"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          width="87"
          x="683"
          y="297"
        >
          <rect fill="white" height="87" rx="20" width="87" x="683" y="297" />
        </mask>
        <g mask="url(#mask1)">
          <path
            clipRule="evenodd"
            d="M758.15 373.907C755.585 370.197 751.805 367.501 747.462 366.284L737.375 363.459V356.209C741.639 353.006 744.44 347.965 744.612 342.264C745.208 342.526 745.865 342.675 746.559 342.675C749.228 342.675 751.392 340.511 751.392 337.842C751.392 335.172 749.228 333.009 746.559 333.009C745.871 333.009 745.218 333.154 744.626 333.413V332.042C744.626 322.032 736.511 313.917 726.501 313.917H726.5C716.49 313.917 708.376 322.032 708.376 332.042V333.413C707.783 333.154 707.13 333.009 706.442 333.009C703.773 333.009 701.609 335.172 701.609 337.842C701.609 340.511 703.773 342.675 706.442 342.675C707.136 342.675 707.793 342.527 708.39 342.264C708.561 347.965 711.362 353.006 715.625 356.209V363.459L705.539 366.284C701.196 367.501 697.416 370.197 694.851 373.907L687.834 384.058V391.25H765.167V384.058L758.15 373.907Z"
            fill="#E9ADA1"
            fillRule="evenodd"
          />
          <path
            clipRule="evenodd"
            d="M726.501 359.833C724.266 359.833 722.127 359.426 720.15 358.687C722.962 360.483 726.301 361.525 729.885 361.525C732.558 361.525 735.092 360.942 737.376 359.903V356.209C734.347 358.484 730.582 359.833 726.501 359.833Z"
            fill="#DB8B79"
            fillRule="evenodd"
          />
          <path
            d="M709.007 338.547C709.068 338.322 709.101 338.086 709.101 337.842C709.101 336.374 707.911 335.183 706.443 335.183C706.076 335.183 705.726 335.258 705.408 335.392"
            stroke="#252736"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.6"
          />
          <path
            d="M743.995 338.547C743.933 338.322 743.9 338.086 743.9 337.842C743.9 336.374 745.091 335.183 746.559 335.183C746.926 335.183 747.275 335.258 747.594 335.392"
            stroke="#252736"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.6"
          />
          <path
            clipRule="evenodd"
            d="M731.335 348.718C731.335 351.388 729.171 353.552 726.501 353.552C723.832 353.552 721.668 351.388 721.668 348.718H731.335Z"
            fill="white"
            fillRule="evenodd"
          />
          <path
            clipRule="evenodd"
            d="M736.408 338.567C736.408 339.634 735.542 340.5 734.474 340.5C733.407 340.5 732.541 339.634 732.541 338.567C732.541 337.499 733.407 336.633 734.474 336.633C735.542 336.633 736.408 337.499 736.408 338.567Z"
            fill="#252736"
            fillRule="evenodd"
          />
          <path
            clipRule="evenodd"
            d="M720.458 338.567C720.458 339.634 719.593 340.5 718.525 340.5C717.457 340.5 716.592 339.634 716.592 338.567C716.592 337.499 717.457 336.633 718.525 336.633C719.593 336.633 720.458 337.499 720.458 338.567Z"
            fill="#252736"
            fillRule="evenodd"
          />
          <path
            d="M726.5 336.633L728.917 344.85L725.533 345.333"
            stroke="#252736"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.6"
          />
          <path
            clipRule="evenodd"
            d="M705.959 332.041C705.959 320.697 715.156 311.5 726.5 311.5C737.845 311.5 747.042 320.697 747.042 332.041C747.042 332.374 747.031 332.703 747.015 333.031C746.864 333.017 746.713 333.008 746.559 333.008C745.201 333.008 743.976 333.57 743.098 334.471C738.536 332.397 734.729 328.953 732.213 324.656C726.346 330.266 718.597 333.922 710.009 334.588C709.125 333.619 707.857 333.008 706.442 333.008C706.288 333.008 706.137 333.017 705.987 333.031C705.97 332.703 705.959 332.374 705.959 332.041ZM706.442 342.675C707.133 342.675 707.789 342.527 708.384 342.266C708.552 347.833 711.235 352.766 715.331 355.977C713.249 359.618 709.331 362.073 704.837 362.073C698.164 362.073 692.754 356.663 692.754 349.99C692.754 344.426 696.516 339.743 701.634 338.339C701.883 340.775 703.94 342.675 706.442 342.675Z"
            fill="#252736"
            fillRule="evenodd"
          />
          <path
            clipRule="evenodd"
            d="M765.167 384.058L758.15 373.907C755.586 370.197 751.805 367.5 747.462 366.284L737.376 363.458C737.376 369.464 732.507 374.333 726.501 374.333C720.495 374.333 715.626 369.464 715.626 363.458L705.539 366.284C701.196 367.5 697.416 370.197 694.851 373.907L687.834 384.058V391.25H765.167V384.058Z"
            fill="#FF4D64"
            fillRule="evenodd"
          />
        </g>
        <rect fill="#F4F4F5" height="118" rx="20" width="118" x="421" y="99" />
        <mask
          height="118"
          id="mask2"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          width="118"
          x="421"
          y="99"
        >
          <rect fill="white" height="118" rx="20" width="118" x="421" y="99" />
        </mask>
        <g mask="url(#mask2)">
          <path
            clipRule="evenodd"
            d="M522.926 203.311C519.448 198.279 514.319 194.622 508.429 192.972L494.749 189.139V179.306C500.532 174.962 504.33 168.125 504.563 160.392C505.372 160.748 506.264 160.95 507.205 160.95C510.825 160.95 513.76 158.015 513.76 154.395C513.76 150.774 510.825 147.839 507.205 147.839C506.271 147.839 505.386 148.037 504.582 148.388V146.528C504.582 132.951 493.576 121.945 479.999 121.945H479.999C466.422 121.945 455.416 132.951 455.416 146.528V148.388C454.612 148.037 453.726 147.839 452.794 147.839C449.173 147.839 446.238 150.774 446.238 154.395C446.238 158.015 449.173 160.95 452.794 160.95C453.734 160.95 454.626 160.749 455.435 160.392C455.668 168.125 459.466 174.962 465.249 179.306V189.139L451.569 192.972C445.679 194.622 440.55 198.279 437.072 203.311L427.555 217.079V226.834H532.444V217.079L522.926 203.311Z"
            fill="#E9ADA1"
            fillRule="evenodd"
          />
          <path
            clipRule="evenodd"
            d="M479.999 184.222C476.967 184.222 474.066 183.67 471.385 182.667C475.198 185.103 479.727 186.517 484.588 186.517C488.213 186.517 491.651 185.726 494.749 184.317V179.306C490.64 182.392 485.533 184.222 479.999 184.222Z"
            fill="#DB8B79"
            fillRule="evenodd"
          />
          <path
            d="M456.271 155.351C456.355 155.047 456.4 154.726 456.4 154.395C456.4 152.403 454.785 150.789 452.794 150.789C452.296 150.789 451.822 150.89 451.391 151.072"
            stroke="#252736"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M503.728 155.351C503.644 155.047 503.6 154.726 503.6 154.395C503.6 152.403 505.214 150.789 507.205 150.789C507.703 150.789 508.177 150.89 508.609 151.072"
            stroke="#252736"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            clipRule="evenodd"
            d="M486.554 169.147C486.554 172.768 483.62 175.703 479.999 175.703C476.378 175.703 473.443 172.768 473.443 169.147H486.554Z"
            fill="white"
            fillRule="evenodd"
          />
          <path
            clipRule="evenodd"
            d="M493.44 155.378C493.44 156.826 492.266 158 490.818 158C489.369 158 488.195 156.826 488.195 155.378C488.195 153.93 489.369 152.756 490.818 152.756C492.266 152.756 493.44 153.93 493.44 155.378Z"
            fill="#252736"
            fillRule="evenodd"
          />
          <path
            clipRule="evenodd"
            d="M471.805 155.378C471.805 156.826 470.631 158 469.183 158C467.735 158 466.561 156.826 466.561 155.378C466.561 153.93 467.735 152.756 469.183 152.756C470.631 152.756 471.805 153.93 471.805 155.378Z"
            fill="#252736"
            fillRule="evenodd"
          />
          <path
            d="M480.001 152.756L483.278 163.9L478.689 164.556"
            stroke="#252736"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            clipRule="evenodd"
            d="M506.222 147.92V146.528V123.583C506.222 121.773 504.754 120.306 502.944 120.306H473.444C462.582 120.306 453.777 129.111 453.777 139.972V146.528V147.92C455.646 148.202 457.256 149.266 458.258 150.775L460.478 143.33C461.307 140.552 463.862 138.648 466.76 138.648H493.239C496.137 138.648 498.692 140.552 499.521 143.33L501.741 150.775C502.743 149.266 504.353 148.202 506.222 147.92Z"
            fill="#252736"
            fillRule="evenodd"
          />
          <path
            clipRule="evenodd"
            d="M522.926 203.311C519.448 198.279 514.32 194.622 508.429 192.972L499.684 190.521L496.06 184.55H494.749V187.005L479.999 200.611L479.999 200.611L465.249 187.005V184.55H463.938L460.314 190.521L451.569 192.972C445.679 194.622 440.551 198.279 437.072 203.311L427.555 217.079V226.834H532.444V217.079L522.926 203.311Z"
            fill="#3D9CFF"
            fillRule="evenodd"
          />
          <path
            d="M460.314 190.522L468.937 208.396L480 200.611"
            stroke="#252736"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M480 200.611L491.062 208.396L496.814 196.473"
            stroke="#252736"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <rect fill="#F4F4F5" height="157" rx="30" width="157" x="41" y="139" />
        <mask
          height="157"
          id="mask3"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          width="157"
          x="41"
          y="139"
        >
          <rect fill="white" height="157" rx="30" width="157" x="41" y="139" />
        </mask>
        <g mask="url(#mask3)">
          <path
            clipRule="evenodd"
            d="M176.615 277.786C171.987 271.091 165.164 266.225 157.327 264.03L139.125 258.931V245.848C146.82 240.068 151.873 230.972 152.183 220.683C153.259 221.157 154.446 221.425 155.698 221.425C160.514 221.425 164.42 217.52 164.42 212.703C164.42 207.886 160.514 203.981 155.698 203.981C154.456 203.981 153.278 204.244 152.209 204.711V202.236C152.209 184.172 137.565 169.528 119.5 169.528H119.5C101.436 169.528 86.7921 184.172 86.7921 202.236V204.711C85.7228 204.244 84.5444 203.981 83.3032 203.981C78.4859 203.981 74.581 207.886 74.581 212.703C74.581 217.52 78.4859 221.425 83.3032 221.425C84.5544 221.425 85.7415 221.157 86.8174 220.683C87.1275 230.972 92.1807 240.068 99.875 245.848V258.931L81.6735 264.03C73.8365 266.225 67.0136 271.091 62.3856 277.786L49.7227 296.105V309.084H189.278V296.105L176.615 277.786Z"
            fill="#E9ADA1"
            fillRule="evenodd"
          />
          <path
            clipRule="evenodd"
            d="M119.5 252.389C115.467 252.389 111.606 251.654 108.039 250.319C113.113 253.561 119.138 255.442 125.606 255.442C130.429 255.442 135.003 254.389 139.125 252.515V245.848C133.658 249.954 126.864 252.389 119.5 252.389Z"
            fill="#DB8B79"
            fillRule="evenodd"
          />
          <path
            d="M87.9292 213.976C88.0405 213.57 88.1002 213.143 88.1002 212.703C88.1002 210.054 85.9524 207.906 83.303 207.906C82.6405 207.906 82.0095 208.04 81.4355 208.283"
            stroke="#252736"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M151.071 213.976C150.96 213.57 150.9 213.143 150.9 212.703C150.9 210.054 153.048 207.906 155.698 207.906C156.36 207.906 156.991 208.04 157.565 208.283"
            stroke="#252736"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            clipRule="evenodd"
            d="M128.222 232.331C128.222 237.149 124.317 241.054 119.5 241.054C114.682 241.054 110.777 237.149 110.777 232.331H128.222Z"
            fill="white"
            fillRule="evenodd"
          />
          <path
            clipRule="evenodd"
            d="M137.382 214.011C137.382 215.938 135.82 217.5 133.893 217.5C131.966 217.5 130.404 215.938 130.404 214.011C130.404 212.084 131.966 210.522 133.893 210.522C135.82 210.522 137.382 212.084 137.382 214.011Z"
            fill="#252736"
            fillRule="evenodd"
          />
          <path
            clipRule="evenodd"
            d="M108.599 214.011C108.599 215.938 107.037 217.5 105.11 217.5C103.183 217.5 101.621 215.938 101.621 214.011C101.621 212.084 103.183 210.522 105.11 210.522C107.037 210.522 108.599 212.084 108.599 214.011Z"
            fill="#252736"
            fillRule="evenodd"
          />
          <path
            d="M119.5 210.522L123.861 225.35L117.756 226.222"
            stroke="#252736"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            clipRule="evenodd"
            d="M109.191 188.909C104.652 196.663 97.7815 202.878 89.5478 206.62C88.0925 205.126 86.106 204.16 83.8936 204.011L85.0392 196.758V196.758C85.7693 192.135 87.4086 187.814 89.7658 183.982C91.9429 180.445 94.7366 177.331 97.9931 174.779C99.2661 173.781 100.608 172.867 102.014 172.05C105.962 169.759 110.398 168.217 115.13 167.623C116.561 167.444 118.019 167.347 119.5 167.347C125.874 167.347 131.844 169.065 136.986 172.05C141.989 174.955 146.206 179.061 149.234 183.982C151.591 187.814 153.23 192.135 153.961 196.758L155.105 204.002C152.792 204.159 150.731 205.22 149.261 206.831C133.763 205.63 119.779 199.032 109.191 188.909ZM99.8748 245.836C92.1875 240.055 87.1268 230.968 86.8167 220.682C85.7409 221.156 84.5538 221.424 83.3026 221.424C82.5708 221.424 81.8647 221.325 81.1857 221.155L75.8887 254.69H99.8748V245.836ZM155.697 221.425C154.446 221.425 153.258 221.157 152.182 220.682C151.872 230.968 146.812 240.055 139.125 245.836V254.69H163.111L157.814 221.155C157.135 221.325 156.429 221.425 155.697 221.425Z"
            fill="#252736"
            fillRule="evenodd"
          />
          <path
            clipRule="evenodd"
            d="M189.278 296.105L176.615 277.786C171.987 271.092 165.164 266.225 157.327 264.03L139.125 258.931C139.125 269.769 130.339 278.556 119.5 278.556C108.662 278.556 99.8754 269.769 99.8754 258.931L81.6735 264.03C73.8365 266.225 67.0136 271.092 62.3856 277.786L49.7227 296.105V309.083H189.278V296.105Z"
            fill="#FFA6B1"
            fillRule="evenodd"
          />
        </g>
      </svg>
    </div>
  );
};

export default WelcomeIllustration;
