import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { Duration } from 'luxon';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { FlexCenteredAlignContainer } from '@/components/_common';
import { PlanRestrictionBadge } from '@/components/Badge/PlanRestrictionBadge';
import Select, { VARIANT_CONTAINED_BUTTON } from '@/components/Select/Select';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import {
  changeComparisonPeriod,
  changePeriod,
  changePeriodicity,
} from '@/store/actions/workspaceConfig';
import { selectWorkspaceConfig } from '@/store/selectors/selectWorkspaceConfig';
import defaultTheme from '@/themes/defaultTheme';
import { comparisonPeriods, periodicities } from '@/utils/metrics/constants';
import { getPeriods } from '@/utils/metrics/getPeriods';

const StyledMenuItem = withStyles({
  root: {
    display: 'flex',
    fontSize: defaultTheme.textCss.sizes.sm,
    justifyContent: 'space-between',
    lineHeight: '135%',
  },
})(MenuItem);

const Selector = styled(Select)`
  margin-right: 8px;
  && {
    .select {
      color: #7c7d86;
      padding: 0 20px 3px 0;
      display: flex;
      align-items: center;
    }
    .icon {
      color: #7c7d86;
      font-size: 8px;
      margin-top: 1px;
    }
  }
`;

export default function MetricsDateConfigSelector({ hideComparisonPeriods = false, ...props }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { period, periodicity, comparisonPeriod } = useSelector(selectWorkspaceConfig);
  const { data: maxDataToDisplay } = useOrganizationFeatureSet(
    'url-metrics:max-data-to-display-in-months'
  );

  function onChangeComparisonPeriod(event) {
    dispatch(changeComparisonPeriod(event.target.value));
  }

  function onChangePeriodicity(event) {
    dispatch(changePeriodicity(event.target.value));
  }

  function onChangePeriod(event) {
    dispatch(changePeriod(event.target.value));
  }

  function isPeriodRestricted(periodObj) {
    if (!maxDataToDisplay || !periodObj.duration?.months) return false;

    const lockDate = Duration.fromISO(maxDataToDisplay).toObject();
    return periodObj.duration.months > lockDate.months;
  }

  const getListComparisonPeriods = useMemo(
    () =>
      Object.values(comparisonPeriods).map((comparisonPeriod) => (
        <StyledMenuItem key={comparisonPeriod.key} value={comparisonPeriod.key}>
          {t(comparisonPeriod.label)}
        </StyledMenuItem>
      )),
    []
  );

  const getListPeriodicities = useMemo(
    () =>
      Object.entries(periodicities).map(([periodicityKey, periodicityObj]) => (
        <StyledMenuItem key={periodicityKey} value={periodicityKey}>
          {t(periodicityObj.label)}
        </StyledMenuItem>
      )),
    []
  );

  const getListPeriods = useMemo(
    () =>
      Object.entries(getPeriods()).map(([periodKey, periodObj]) => {
        const isRestricted = isPeriodRestricted(periodObj);
        return (
          <StyledMenuItem key={periodKey} value={periodKey}>
            {periodObj.name}
            {isRestricted && <PlanRestrictionBadge isCondensed />}
          </StyledMenuItem>
        );
      }),
    [isPeriodRestricted]
  );

  return (
    <FlexCenteredAlignContainer {...props}>
      {!hideComparisonPeriods && (
        <Selector
          size={defaultTheme.textCss.sizes.sm}
          value={comparisonPeriod}
          variant={VARIANT_CONTAINED_BUTTON}
          onChange={onChangeComparisonPeriod}
        >
          {getListComparisonPeriods}
        </Selector>
      )}
      <Selector
        size={defaultTheme.textCss.sizes.sm}
        value={periodicity}
        variant={VARIANT_CONTAINED_BUTTON}
        onChange={onChangePeriodicity}
      >
        {getListPeriodicities}
      </Selector>
      <Selector
        size={defaultTheme.textCss.sizes.sm}
        value={period}
        variant={VARIANT_CONTAINED_BUTTON}
        onChange={onChangePeriod}
      >
        {getListPeriods}
      </Selector>
    </FlexCenteredAlignContainer>
  );
}
