import './Header.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ENUM_BUTTON_VARIANTS } from 'semji-core/components/Button';

import HeaderTitle from '@/containers/Competitors/components/HeaderTitle';

export default function Header({ onExportClick }: { onExportClick: () => void }) {
  const { t } = useTranslation();

  return (
    <HeaderTitle
      actionsContent={
        <Button variant={ENUM_BUTTON_VARIANTS.Secondary} onClick={onExportClick}>
          {t('competitors:data-explorer.actions.export')}
        </Button>
      }
      description={t('competitors:data-explorer.description')}
      title={t('competitors:data-explorer.title')}
    />
  );
}
