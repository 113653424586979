import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import apiClient from '@/apis/semji/apiClient';
import { showErrorSnackbar } from '@/store/actions/ui';

export default function usePostPiwikProApps(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { post } = apiClient;

  return useMutation({
    mutationFn: async ({ apiUrl, clientId, clientSecret }) =>
      await post(`/services_integrations/piwik_pro_apps`, { apiUrl, clientId, clientSecret }),

    onError: () => {
      dispatch(showErrorSnackbar(t('common:error.default')));
    },
    ...props,
  });
}
