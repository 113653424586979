import { nanoid } from 'nanoid';
import React from 'react';
import styled from 'styled-components/macro';

import CountUp from '@/components/CountUp/CountUp';

const Ring = styled((props) => <circle cx="21" cy="21" r="16" {...props} />)`
  fill: none;
  stroke-width: 0.1rem;
  stroke: ${({ color }) => color};
  stroke-linecap: round;
`;

const Background = styled((props) => <Ring r="18.5" {...props} />)`
  fill: white;
  stroke-width: 0;
`;

const Bar = styled(({ loading, ...props }) => <Ring {...props} />)`
  transition:
    stroke 800ms,
    opacity 400ms;
  transform: rotate(90deg);
  transform-origin: center center;
  stroke: yellow;
  opacity: ${(props) => (props.loading ? 0 : 1)};
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const Circle = ({ score, color, backgroundColor, loading }) => {
  const svgId = nanoid();

  return (
    <Wrapper>
      <svg height="40px" viewBox="0 0 42 42" width="40px">
        <Background />
        <Ring color={backgroundColor} />
        <defs>
          <mask id={`content-score-mask-${svgId}`}>
            <Bar color={color} loading={loading} strokeDasharray={`${score} 100`} />
          </mask>
          <linearGradient id={`content-score-gradient-${svgId}`} x1="0%" x2="0%" y1="0%" y2="100%">
            <stop
              offset="50%"
              style={{
                stopColor: color,
                stopOpacity: 1,
                transition: '800ms ease',
              }}
            />

            <stop
              offset="100%"
              style={{
                stopColor: color,
                stopOpacity: 1,
                transition: '800ms ease',
              }}
            />
          </linearGradient>
        </defs>
        <rect
          fill={`url(#content-score-gradient-${svgId})`}
          height="36"
          mask={`url(#content-score-mask-${svgId})`}
          width="36"
          x="3"
          y="3"
        />
      </svg>
    </Wrapper>
  );
};

export default function CircularProgressBar(props) {
  return (
    <CountUp value={Math.floor(props.percentage || 0)}>
      {({ value }) => (
        <Circle backgroundColor={props.backgroundColor} color={props.color} score={value} />
      )}
    </CountUp>
  );
}
