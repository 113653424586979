import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { TitleSix } from '@/components/Text/Title';

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 16px;
  margin-left: ${({ level }) => `calc(${level} * 12px)`};
`;

const Title = styled(TitleSix)`
  color: ${({ theme, isMissing }) =>
    isMissing ? theme.cssColors.dark040 : theme.cssColors.secondaryBlue};
  text-transform: lowercase;
  padding: 2px 6px;
  margin: 0;
  border: ${({ theme, isMissing }) =>
    `1px solid ${isMissing ? theme.cssColors.dark040 : theme.cssColors.secondaryBlue}`};
  border-radius: 8px;
  line-height: ${({ theme }) => theme.textCss.sizes.default};
  font-weight: ${({ theme }) => theme.textCss.weights.medium};

  ${(props) => props.theme.mediaQueries.largeDesktop} {
    font-size: ${(props) => props.theme.textCss.sizes.xs};
  }
`;

const Description = styled.p`
  font-size: 13px;
  align-self: center;
  overflow-wrap: anywhere;
  line-height: ${({ theme }) => theme.textCss.sizes.md};
  color: ${(props) => props.theme.cssColors[props.color]};
  font-weight: medium;
`;

const HeadingElement = ({ heading, text, level, isMissing }) => {
  const { t } = useTranslation();
  return (
    <Container level={level}>
      <Title isMissing={isMissing}>{heading}</Title>
      <Description color={isMissing ? 'dark040' : 'dark100'}>
        {text ?? t('components:outline.empty.missing-heading')}
      </Description>
    </Container>
  );
};

export default HeadingElement;
