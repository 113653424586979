import React from 'react';
import { IconSquare } from 'semji-core/components/IconSquare';
import styled from 'styled-components/macro';

import DeleteIcon from '@/components/icons/DeleteIcon';
import Input from '@/components/Input/Input';

// DO NOT FORGET TO REFACTOR RELEVANT KEYWORDS
// THE COMPONENT IS SIMILAR TO THIS ONE
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`;
const InputText = styled(Input)`
  && {
    margin-bottom: 15px;
    width: 95%;
  }
`;
const Row = styled.div`
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
`;
const AddInputActionWrapper = styled.div`
  && {
    display: flex;
    padding: 1rem 0;
  }
`;

export default function InputSmartList({ AddInput, ...props }) {
  const [inputs, setInputs] = React.useState(props.inputs);
  const [focusIndex, setFocusIndex] = React.useState(0);

  const handleChange = (index) => (event) => {
    let newValue = event.target.value;
    let focusIndex = index;

    if (event.target.value.indexOf('\n') > 0) {
      let inputsList = event.target.value.split('\n');
      newValue = inputsList.shift();
      inputsList = inputsList.filter((term) => term !== '');
      inputs.splice(index + 1, 0, ...inputsList);
      focusIndex = index + inputsList.length;
    }

    inputs[index] = newValue;

    if (inputs[inputs.length - 1] !== '' && !props.isLimitAchieved) {
      inputs.push('');
    }

    setInputs([...inputs]);
    setFocusIndex(focusIndex);

    // return the new inputs list
    props.getList(inputs);
  };

  const handleKeyDown = (index) => (event) => {
    if (event.key === 'Backspace' && inputs[index] === '') {
      inputs.splice(index, 1);
      if (inputs[inputs.length - 1] !== '') {
        inputs.push('');
      }
      event.stopPropagation();
      event.preventDefault();
      setFocusIndex(Math.max(0, index - 1));
      setInputs([...inputs]);
      // return the new inputs list
      props.getList(inputs);
    }
    if (event.key === 'Enter') {
      event.stopPropagation();
      event.preventDefault();
      if (inputs[index] !== '' && inputs[index + 1] !== '' && !props.isLimitAchieved) {
        inputs.splice(index + 1, 0, '');
      }
      setFocusIndex(Math.min(inputs.length - 1, index + 1));
      setInputs([...inputs]);
      // return the new inputs list
      props.getList(inputs);
    }
    if (event.key === 'ArrowUp') {
      event.stopPropagation();
      event.preventDefault();
      setFocusIndex(Math.max(0, index - 1));
    }
    if (event.key === 'ArrowDown') {
      event.stopPropagation();
      event.preventDefault();
      setFocusIndex(Math.min(inputs.length - 1, index + 1));
    }
  };

  const deleteInput = (inputs, index) => {
    const newInputs = [...inputs];
    newInputs.splice(index, 1);
    setInputs(newInputs);
    setFocusIndex(Math.max(0, index - 1));
    // return the new inputs list
    props.getList(newInputs);
  };

  const addInput = () => {
    if (!props.isLimitAchieved) {
      const newInputs = [...inputs, ''];
      setInputs(newInputs);
      setFocusIndex(Math.max(0, newInputs.length - 1));
      props.getList(newInputs);
    }
  };

  function onFocus(e) {
    // so the cursor is set at the end of the text
    const val = e.target.value;
    e.target.value = '';
    e.target.value = val;
  }

  return (
    <Wrapper key={`smart_input_text_list_${focusIndex}`}>
      {inputs.map((input, index) => (
        <Row key={`smart_input_text_list_${index}`}>
          <InputText
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={focusIndex === index}
            error={props.isError(input)}
            fullWidth
            helperText={props.helperText(input)}
            InputProps={props.InputProps}
            margin="dense"
            multiline
            placeholder={props.placeholder}
            rowsMax="1"
            value={input}
            onChange={handleChange(index)}
            onFocus={onFocus}
            onKeyDown={handleKeyDown(index)}
          />
          {index + 1 !== inputs.length && (
            <IconSquare
              size="normal"
              variant="tertiary"
              onClick={() => {
                if (index + 1 !== inputs.length) {
                  deleteInput(inputs, index);
                }
              }}
            >
              <DeleteIcon />
            </IconSquare>
          )}
        </Row>
      ))}
      {!!AddInput && (
        <AddInputActionWrapper>
          <AddInput onClick={addInput} />
        </AddInputActionWrapper>
      )}
      {props.limitAchievedMessage}
    </Wrapper>
  );
}
