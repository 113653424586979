import Switch from '@material-ui/core/Switch';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import UserService from '@/services/User';
import { showSuccessSnackbar } from '@/store/actions/ui';
import {
  setUserCommentEmailNotifications,
  setUserContentEmailNotifications,
} from '@/store/actions/user';

export function ContentEmailNotifications({ user }) {
  const dispatch = useDispatch();
  const _userService = new UserService();
  const [contentEmailNotificationsEnabled, setContentEmailNotificationsEnabled] = useState(
    user.contentEmailNotificationsEnabled
  );

  const saveChanges = async () => {
    const notifications = !contentEmailNotificationsEnabled;
    setContentEmailNotificationsEnabled(notifications);
    await _userService.setUserInfo(user.id, { contentEmailNotificationsEnabled: notifications });
    dispatch(setUserContentEmailNotifications(notifications));
    dispatch(
      showSuccessSnackbar(
        `Your notifications have been ${notifications ? 'enabled' : 'disabled'} successfully.`
      )
    );
  };

  return <Switch checked={contentEmailNotificationsEnabled} onChange={saveChanges} />;
}

export function CommentEmailNotifications({ user }) {
  const dispatch = useDispatch();
  const _userService = new UserService();
  const [commentEmailNotificationsEnabled, setCommentEmailNotificationsEnabled] = useState(
    user.commentEmailNotificationsEnabled
  );

  const saveChanges = async () => {
    const notifications = !commentEmailNotificationsEnabled;

    setCommentEmailNotificationsEnabled(notifications);
    await _userService.setUserInfo(user.id, { commentEmailNotificationsEnabled: notifications });
    dispatch(setUserCommentEmailNotifications(notifications));
    dispatch(
      showSuccessSnackbar(
        `Your notifications have been ${notifications ? 'enabled' : 'disabled'} successfully.`
      )
    );
  };

  return <Switch checked={commentEmailNotificationsEnabled} onChange={saveChanges} />;
}
