import './Report.scss';

import { capitalize } from 'lodash';
import { DateTime } from 'luxon';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'semji-core/components/Tooltip';
import { InfoIcon } from 'semji-core/icons/InfoIcon';

import { useGetCompetitorReportMetrics } from '@/apis/semji/competitors';
import Card from '@/components/Card/Card';
import { NO_COMPETITOR_STACK_BAR } from '@/components/EmptyState/NoContentsVerticalBars';
import ChartWrapper, {
  CHART_WRAPPER_VARIANT_SMALL,
} from '@/containers/Competitors/components/ChartWrapper';
import EmptyStateChart from '@/containers/Competitors/components/EmptyStateChart';
import { ExportOptionButton } from '@/containers/Competitors/components/ExportOptionButton/ExportOptionButton';
import { BRAND_TRAFFIC_TYPES } from '@/containers/Competitors/components/HeaderFilters/Filters/BrandTrafficFilter/const';
import { HighChartsStackedBar } from '@/containers/Competitors/Duel/Report/Chart/HighChartsStackedBar';
import {
  ENUM_CHART_DATA_TYPE,
  SMALL_CARD_CONTENT_MIN_HEIGHT,
} from '@/containers/Competitors/Duel/Report/constant';
import { ToggleSelector } from '@/containers/Competitors/Duel/Selector/Selector';
import { useCompetitor } from '@/containers/Competitors/hooks/useCompetitor';
import { BRAND_REPORT_COLORS } from '@/containers/Competitors/utils/colors';
import {
  BRAND_TRAFFIC_TYPE_FILTER_QUERY_PARAM,
  COMPETITORS_METRIC_TYPES,
  getReportFormatValues,
} from '@/containers/Competitors/utils/constants';
import { filterCompetitorWebsites, getHostnamefromUrl } from '@/containers/Competitors/utils/utils';

export function BrandTrafficStackedBarReport({
  metrics,
  isLoading,
  competitorsFilter,
  handleOpenDataExplorer,
  competitorReportId,
  filters,
}) {
  const { t } = useTranslation();
  const [renderType, setRenderType] = useState(ENUM_CHART_DATA_TYPE.VALUE);
  const [exportImage, setExportImage] = useState(false);

  const chartRef = useRef(null);

  const { competitorWebsites, websiteReference, competitorComparativeDates } = useCompetitor();

  const reference = getHostnamefromUrl(websiteReference?.url);

  const {
    data: metricsBranded = [],
    isLoading: isMetricsBrandedLoading,
    isPlaceholderDataBranded,
  } = useGetCompetitorReportMetrics({
    competitorReportId: competitorReportId,
    filters: {
      ...filters,
      isBranded: true,
    },
    staleTime: 0,
  });

  const {
    data: metricsUnBranded = [],
    isLoading: isMetricsUnBrandedLoading,
    isPlaceholderDataUnBranded,
  } = useGetCompetitorReportMetrics({
    competitorReportId: competitorReportId,
    filters: {
      ...filters,
      isBranded: false,
    },
    staleTime: 0,
  });

  const { series, categories } = buildSeries();

  const loading =
    isLoading ||
    isPlaceholderDataUnBranded ||
    isPlaceholderDataBranded ||
    isMetricsBrandedLoading ||
    isMetricsUnBrandedLoading;

  function getCompetitorMetricByType(competitorId, type) {
    let value = 0;
    let nbPages = 0;
    const competitorMetric = metrics.find((metric) => metric.competitorWebsiteId === competitorId);
    const competitorMetricBranded = metricsBranded?.find(
      (metric) => metric.competitorWebsiteId === competitorId
    );
    const competitorMetricUnBranded = metricsUnBranded?.find(
      (metric) => metric.competitorWebsiteId === competitorId
    );

    if (type === BRAND_TRAFFIC_TYPES[0]) {
      value = competitorMetric?.unbrandedTraffic ?? 0;
      nbPages = competitorMetricUnBranded?.nbPages ?? 0;
    } else if (type === BRAND_TRAFFIC_TYPES[1]) {
      value = competitorMetric?.brandedTraffic;
      nbPages = competitorMetricBranded?.nbPages ?? 0;
    }

    return {
      custom: {
        competitorId,
        nbPages,
        percentage: (value * 100) / competitorMetric?.traffic,
        type,
        value,
      },
      y:
        renderType === ENUM_CHART_DATA_TYPE.PERCENTAGE
          ? (value * 100) / competitorMetric?.traffic
          : value,
    };
  }

  function buildSeries() {
    const websites = filterCompetitorWebsites(competitorWebsites, competitorsFilter);

    const series = BRAND_TRAFFIC_TYPES.map((type) => {
      return {
        color: BRAND_REPORT_COLORS[type],
        data: websites.map(({ id }) => getCompetitorMetricByType(id, type)),
        name: t(`competitors:reports.labels.${type}`),
      };
    });

    const categories = websites.map((website) => {
      return { name: website.name, url: website.url, urlType: website.urlType };
    });
    return { categories, series };
  }

  const formatValues = getReportFormatValues();

  const isNoData = series?.length === 0 || categories?.length === 0 || metrics?.length === 0;

  function handleSelectThemeType(value) {
    return () => {
      setRenderType(value);
    };
  }

  return (
    <ChartWrapper isLoading={loading} variant={CHART_WRAPPER_VARIANT_SMALL}>
      <Card
        className="competitors-duel-report__card"
        contentMinHeight={SMALL_CARD_CONTENT_MIN_HEIGHT}
        title={
          <div className="competitors-duel-report__card__header">
            <div className="competitors-duel-report__card__header__title">
              <span>{t('competitors:reports.report.brand-status-traffic.title')}</span>
              <Tooltip
                description={t('competitors:source-normalized-traffic')}
                isFrame={false}
                title={t('competitors:reports.report.brand-status-traffic.tooltip', {
                  date: capitalize(
                    DateTime.fromISO(competitorComparativeDates?.[0]).toFormat('LLLL yyyy')
                  ),
                })}
                tooltipClassName="competitors-duel-report__card__header__title-tooltip competitors-duel-report__card__header__title-tooltip--extra-large"
              >
                <InfoIcon />
              </Tooltip>
            </div>
            <div className="competitors-duel-report__card__header__actions">
              <div className="competitors-duel-report__card__header__filter">
                <ToggleSelector
                  list={formatValues.map(({ title, tooltip, value }) => ({
                    active: value === renderType,
                    onClick: handleSelectThemeType(value),
                    title: title,
                    tooltip: tooltip,
                    value: value,
                  }))}
                />
              </div>
              <ExportOptionButton
                chartRef={chartRef}
                exportImage={exportImage}
                setExportImage={setExportImage}
              />
            </div>
          </div>
        }
      >
        {isNoData ? (
          <EmptyStateChart version={NO_COMPETITOR_STACK_BAR} />
        ) : (
          <HighChartsStackedBar
            categories={categories}
            chartRef={chartRef}
            metricsSeries={series}
            reference={reference}
            renderType={renderType}
            selectedMetrics={[COMPETITORS_METRIC_TYPES.traffic, COMPETITORS_METRIC_TYPES.page]}
            serieKey={BRAND_TRAFFIC_TYPE_FILTER_QUERY_PARAM}
            showLegendWinner={false}
            title={t('competitors:reports.report.brand-status-traffic.title')}
            withPositioner={true}
            onPointClick={handleOpenDataExplorer}
          />
        )}
      </Card>
    </ChartWrapper>
  );
}
