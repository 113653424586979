import { Trans } from 'react-i18next';
import styled from 'styled-components/macro';

const CharacterCounterWrapper = styled.div`
  && {
    font-size: ${(props) => props.theme.text.sizes.small};
    color: ${(props) => props.theme.text.colors.default};
    display: flex;
    flex: 1;
    background-color: transparent;
    align-items: center;
    padding: 0 5px;
    justify-content: flex-start;
    font-size: 0.8em;
    white-space: nowrap;
    min-height: 20px;
  }
`;
const Bold = styled.strong`
  padding-right: 5px;
`;

function CharacterCounter({ count, selectionCount }) {
  return (
    <CharacterCounterWrapper>
      <Trans
        components={{
          Bold: <Bold />,
        }}
        i18nKey={'content:editor-components.character-counter.total-count_interval'}
        values={{
          count,
          postProcess: 'interval',
        }}
      />
      {!!selectionCount && (
        <>
          &nbsp;
          <Trans
            components={{
              Bold: <Bold />,
            }}
            i18nKey={'content:editor-components.character-counter.selection-count_interval'}
            values={{
              count: selectionCount,
              postProcess: 'interval',
            }}
          />
        </>
      )}
    </CharacterCounterWrapper>
  );
}

export default CharacterCounter;
