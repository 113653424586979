import './EmptyState.scss';

import React, { useId } from 'react';
import { FloatLoader } from 'semji-core/components/FloatLoader';

import { EmptyStateProps } from './EmptyState.types';

export default function EmptyState({ defaultRows = 10, canDisplayPageNumber }: EmptyStateProps) {
  const id = useId();

  return (
    <div className="competitors-configuration-empty-sate">
      {Array(defaultRows)
        .fill('')
        .map((item, index) => (
          <div
            key={`competitor-empty-state-${id}-item-${index}`}
            className="competitors-configuration-empty-sate__loader-wrapper h-8"
          >
            <div>
              <FloatLoader />
            </div>
            <div>
              <FloatLoader />
            </div>
            {canDisplayPageNumber && (
              <div>
                <FloatLoader />
              </div>
            )}
            <div>
              <FloatLoader />
            </div>
          </div>
        ))}
    </div>
  );
}
