import { autocomplete } from '@algolia/autocomplete-js';
import React, { createElement, Fragment, useEffect, useRef } from 'react';
import { render } from 'react-dom';

export function Autocomplete(props) {
  const containerRef = useRef(null);

  useEffect(() => {
    if (!containerRef.current) {
      return undefined;
    }

    const search = autocomplete({
      container: containerRef.current,
      render({ children }, root) {
        render(children, root);
      },
      renderer: { Fragment, createElement },
      ...props,
    });

    return () => {
      search.destroy();
    };
  }, [props]);

  useEffect(() => {
    window.addEventListener('keydown', (event) => {
      if (event.metaKey && event.code === 'KeyK') {
        triggerClick();
      }
    });

    return () => {
      window.removeEventListener('keydown');
    };
  }, []);

  function triggerClick() {
    document.querySelector('.aa-Autocomplete > button').click();
  }

  window.addEventListener('keydown', (event) => {
    if (event.metaKey && event.code === 'KeyK') {
      triggerClick();
    }
  });

  return <div ref={containerRef} />;
}
