import i18next from 'i18next';
import React from 'react';

import { ENUM_FILTER_TYPE, FilterRangeProps, SingleFilterType } from '@/components/DynamicFilters';
import PositionIcon from '@/components/icons/PositionIcon';
import { getPositionOptions } from '@/containers/Competitors/components/HeaderFilters/Filters/PositionFilter/const';
import { URL_POSITION_FILTER_QUERY_PARAM } from '@/containers/Competitors/utils/constants';

export function getPositionFilter({
  updateFilter,
  filter,
  customRange,
  onClose,
}: FilterRangeProps): SingleFilterType {
  return {
    customRange,
    icon: <PositionIcon />,
    key: URL_POSITION_FILTER_QUERY_PARAM,
    name: i18next.t('competitors:data-explorer.metrics.position'),
    onClose,
    onValidate: (value) => updateFilter(URL_POSITION_FILTER_QUERY_PARAM, value),
    options: getPositionOptions(filter),
    type: ENUM_FILTER_TYPE.RADIO_RANGE,
  };
}
