import Button from '@material-ui/core/Button';
import styled from 'styled-components/macro';

const HeaderButton = styled(Button)`
  && {
    background: transparent;
    &:hover {
      background: ${({ theme }) => theme.colors.lightGrey};
      box-shadow: none;
    }
    box-shadow: none;
    min-width: 3em;
    height: auto;
    color: white;
    border-radius: 0;
    padding: 10px;
    text-transform: initial;
    font-weight: ${(props) => props.theme.textCss.weights.medium};
    font-size: ${(props) => props.theme.textCss.sizes.sm};

    .MuiButton-label {
      gap: 5px;
      align-items: center;
      justify-content: center;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

export default HeaderButton;
