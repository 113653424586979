import {
  cancelSubscription,
  deleteSubscriptionCancellation,
  downloadInvoice,
  getClientInformation,
  getInvoices,
  getSession,
} from '@/apis/semji/api';

export default class Billing {
  invoices(organizationId) {
    return getInvoices(organizationId);
  }

  information(organizationId) {
    return getClientInformation(organizationId);
  }

  download(organizationId, payload) {
    return downloadInvoice(organizationId, payload);
  }

  session(organizationId, redirectUrl) {
    return getSession(organizationId, redirectUrl);
  }

  unsubscribe(organizationId, payload) {
    return cancelSubscription(organizationId, payload);
  }

  reactivateSubscription(organizationId) {
    return deleteSubscriptionCancellation(organizationId);
  }
}
