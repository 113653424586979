import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import LockRoundIcon from '@/components/icons/LockRoundIcon';

const PlotLineLockLabel = styled.div`
  position: absolute;
  top: -3px;
  right: ${({ position }) => position === 'left' && '1.7rem'};
  left: ${({ position }) => position === 'right' && '1.7rem'};
  background: ${(props) => props.theme.cssColors.dark080};
  color: ${(props) => props.theme.cssColors.white};
  font-size: ${(props) => props.theme.textCss.sizes.xs};
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  padding: 5px;
  &:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: ${({ position, theme }) =>
      position === 'left' && `8px solid ${theme.cssColors.dark080}`};
    border-right: ${({ position, theme }) =>
      position === 'right' && `8px solid ${theme.cssColors.dark080}`};
    top: calc(50% - 8px);
    right: ${({ position }) => position === 'left' && '-6px'};
    left: ${({ position }) => position === 'right' && '-6px'};
  }
`;

function PlotLineLock({ marker, theme, ...props }) {
  const { t } = useTranslation();
  return (
    <div {...props}>
      <PlotLineLockLabel className="label" position={marker.tooltipPosition} theme={theme}>
        <span>{t('components:badge.plan.available-on-higher-plan')}</span>
      </PlotLineLockLabel>
      <LockRoundIcon className="icon" />
    </div>
  );
}

export default styled(PlotLineLock)`
  display: inline-flex;
  justify-content: center;
  right: 4px;
  top: 0;
  position: relative;
  color: ${(props) => props.theme.cssColors.orangeSalmon};
  font-size: 1rem;

  .icon {
    cursor: help;
  }

  .label {
    display: none;
  }

  &:hover {
    .label {
      display: flex;
    }
  }
`;
