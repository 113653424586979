import React, { useEffect } from 'react';

import { DayRenderProps } from './VersionGroup.types';
import VersionItem from './VersionItem';

export default function DayRender({
  handleSelectVersion,
  handleRenameVersion,
  onTabulate: handleTabulate,
  selectedVersionId,
  versions,
  withPadding,
  isPublished,
  enableOptionsMenu,
}: DayRenderProps) {
  const anchor = versions[0];
  const restOfVersions = versions.slice(1);
  const [isOpen, setIsOpen] = React.useState(
    versions.some((version) => version.id === selectedVersionId)
  );
  const canBeOpen = versions.length > 1;
  const forceDropdownOpen = restOfVersions.some((version) => version.id === selectedVersionId);
  useEffect(() => {
    if (forceDropdownOpen) {
      setIsOpen(true);
    }
  }, [forceDropdownOpen]);
  return (
    <>
      <VersionItem
        canBeOpen={canBeOpen}
        isOpen={isOpen}
        isPublished={isPublished}
        isSelected={selectedVersionId === anchor.id}
        version={anchor}
        withPadding={withPadding}
        enableOptionsMenu={enableOptionsMenu}
        onOpen={() => setIsOpen((prev) => !prev)}
        onRenameVersion={handleRenameVersion}
        onSelectVersion={handleSelectVersion}
        onTabulate={handleTabulate}
      />
      <div
        className={`version-history-panel__content__listing__group__submenu ${
          isOpen && 'version-history-panel__content__listing__group__submenu--open'
        }`}
      >
        {canBeOpen &&
          restOfVersions.map((version) => (
            <VersionItem
              key={version.id}
              canBeOpen={false}
              isOpen={false}
              isPublished={isPublished}
              isSelected={selectedVersionId === version.id}
              isSubItem={true}
              version={version}
              withPadding={withPadding}
              onRenameVersion={handleRenameVersion}
              onSelectVersion={handleSelectVersion}
              onTabulate={handleTabulate}
              onOpen={() => {}}
              enableOptionsMenu={enableOptionsMenu}
            />
          ))}
      </div>
    </>
  );
}
