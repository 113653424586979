import i18next from 'i18next';
import React from 'react';

import {
  ENUM_FILTER_TYPE,
  MultiFilterProps,
  MultipleFilterType,
} from '@/components/DynamicFilters';
import PaperIcon from '@/components/icons/Papericon';
import { getCategoriesOptions } from '@/containers/Competitors/components/HeaderFilters/Filters/CategoryFilter/const';
import { CATEGORY_FILTER_QUERY_PARAM } from '@/containers/Competitors/utils/constants';

export function getCategoryFilter({ updateFilter, filter }: MultiFilterProps): MultipleFilterType {
  return {
    icon: <PaperIcon />,
    key: CATEGORY_FILTER_QUERY_PARAM,
    name: i18next.t('competitors:filters.page-type'),
    onValidate: (value) => updateFilter(CATEGORY_FILTER_QUERY_PARAM, value),
    options: getCategoriesOptions(filter),
    type: ENUM_FILTER_TYPE.MULTI_SELECT,
  };
}
