import {
  deleteWorkspaceAccesses,
  fetchWorkspaceWorkspaceAccesses,
  postInviteUsersOnWorkspace,
  setWorkspaceAccesses,
} from '@/apis/semji/api';
import apiClient from '@/apis/semji/apiClient';
import { sortUsers } from '@/utils/user';

export default class WorkspaceAccesses {
  constructor(id, workspaceId) {
    this._id = id;
    this._workspaceId = workspaceId;
  }

  get workspaceAccesses() {
    return this._fetchWorkspaceWorkspaceAccesses();
  }

  async setWorkspaceAccesses(role) {
    return await setWorkspaceAccesses(this._id, { roles: [role] });
  }

  async deleteWorkspaceAccesses() {
    return await deleteWorkspaceAccesses(this._id);
  }

  async inviteUsers(users) {
    return await postInviteUsersOnWorkspace(this._workspaceId, users);
  }

  async setWorkspaceAccessesAiSuite(accessToAiFeatures) {
    try {
      await setWorkspaceAccesses(this._id, { accessToAiFeatures });
    } catch (error) {
      throw error;
    }
  }

  async setWorkspaceAccessesSearchIntelligence(accessToSearchIntelligence) {
    try {
      await setWorkspaceAccesses(this._id, { accessToSearchIntelligence });
    } catch (error) {
      throw error;
    }
  }

  async _fetchWorkspaceWorkspaceAccesses() {
    try {
      const response = await fetchWorkspaceWorkspaceAccesses(this._workspaceId);
      return sortUsers(response['hydra:member']);
    } catch (e) {
      throw e;
    }
  }
}

const findByUser = async (userId) => {
  const response = await apiClient.get(`/users/${userId}/workspace_accesses`, {}, {});

  return response;
};

export { findByUser };
