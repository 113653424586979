import './DuelCards.scss';

import React from 'react';
import { useParams } from 'react-router-dom';
import { DuelCard } from 'semji-core/components/DuelCard';
import { FavIcon } from 'semji-core/components/FavIcon';

import { useGetCompetitorWebsiteMetrics } from '@/apis/semji/competitors';
import { getCompetitorDuelList } from '@/containers/Competitors/Duel/Report/constant';
import { useCompetitor } from '@/containers/Competitors/hooks/useCompetitor';
import { DEFAULT_COLOR } from '@/containers/Competitors/utils/constants';
import { getCompetitorColor, getHostnamefromUrl } from '@/containers/Competitors/utils/utils';
import { IFilterTransformed } from '@/hooks/useFilters/useFilters.types';
import {
  CompetitorCluster,
  CompetitorTopPagesFilters,
  CompetitorWebsite,
  CompetitorWebsiteMetric,
} from '@/types/competitors';
import { DEFAULT_FAVICON } from '@/utils/constants';
import { getFaviconFromUrl } from '@/utils/favicon';

interface DuelCardsProps {
  clusters: CompetitorCluster[];
  filters: IFilterTransformed<CompetitorTopPagesFilters>;
}

export default function DuelCards({ clusters, filters }: DuelCardsProps) {
  const { websiteId, reportId: competitorReportId } = useParams();
  const { websiteReference, competitorWebsites } = useCompetitor();
  const apiRootUrl = import.meta.env.VITE_REACT_APP_SEMJI_API_ROOT_URL;
  const competitor = competitorWebsites.find((c) => c.id === websiteId) as CompetitorWebsite;

  const {
    data,
    isLoading: isCompetitorMetricsLoading,
    isPlaceholderData: isCompetitorMetricsPlaceholder,
  } = useGetCompetitorWebsiteMetrics({
    filters: { ...filters },
    referenceId: websiteReference?.id,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    reportId: competitorReportId,
    websiteId: String(websiteId),
  });

  const referenceMetrics = data?.referenceMetrics ?? [];
  const competitorMetrics = data?.competitorMetrics ?? [];

  const domainReference = websiteReference ? getHostnamefromUrl(websiteReference.url) : '';

  const competitorDuelList = getCompetitorDuelList();

  function getContentMetricValue(index: number, metric: CompetitorWebsiteMetric[]) {
    switch (index) {
      case 0:
        return metric?.[0]?.traffic ?? 0;
      case 1:
        return metric?.[0]?.nbPages ?? 0;

      case 2:
        return metric?.[0]?.unbrandedTraffic ?? 0;

      case 3:
        return metric?.[0]?.traffic / (metric?.[0]?.nbPages ?? 1);

      default:
        return 0;
    }
  }
  return (
    <div className="competitors-duel__duel-cards">
      {competitorDuelList.map((item, index) => (
        <DuelCard
          key={item.key}
          canToggle={item.canToggle}
          isLoading={isCompetitorMetricsLoading || isCompetitorMetricsPlaceholder}
          items={[
            {
              color: DEFAULT_COLOR,
              favicon: (
                <FavIcon
                  defaultFavicon={DEFAULT_FAVICON}
                  disableClick
                  displayLink={false}
                  src={getFaviconFromUrl(domainReference, apiRootUrl)}
                />
              ),
              name: domainReference,
              // get total traffic value
              totalValue: getContentMetricValue(0, referenceMetrics),
              value: getContentMetricValue(index, referenceMetrics),
            },
            {
              color: getCompetitorColor(competitor?.url ?? '')?.color,
              favicon: (
                <FavIcon
                  defaultFavicon={DEFAULT_FAVICON}
                  disableClick
                  displayLink={false}
                  src={getFaviconFromUrl(competitor?.url ?? '', apiRootUrl)}
                />
              ),
              name: competitor ? getHostnamefromUrl(competitor.url) : '-',
              // get total traffic value
              totalValue: getContentMetricValue(0, competitorMetrics),
              value: getContentMetricValue(index, competitorMetrics),
            },
          ]}
          title={item.title}
          tooltip={{ ...item.tooltip, tooltipClassName: 'competitors-duel__duel-cards__tooltip' }}
        />
      ))}
    </div>
  );
}
