import TextField from '@material-ui/core/TextField';
import { lazy, Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import DialogTemplate from '@/components/Dialog/DialogTemplate';
import PasswordFieldSkeleton from '@/components/Password/PasswordFieldSkeleton';
import { BlockText } from '@/components/Text/Inline';
import { ParagraphText } from '@/components/Text/Paragraph';
import UserService from '@/services/User';
import { showSuccessSnackbar } from '@/store/actions/ui';
import {
  DEFAULT_PASSWORD_MIN_STRENGTH,
  DEFAULT_PASSWORD_THRESHOLD_LENGTH,
} from '@/utils/constants';

const PasswordField = lazy(
  () => import(/* webpackChunkName: "PasswordField" */ '@/components/Password/PasswordField')
);

function ChangePasswordDialog({ isOpen, onCancel }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const _userService = new UserService();
  const userId = useSelector((state) => state.user.id);
  const [state, setState] = useState({
    currentPassword: '',
    newPassword: '',
    passwordStrength: 0,
  });
  const [resetState, setResetState] = useState({
    isResetting: false,
    resetError: false,
  });

  const isValidPassword = (value, strength) => {
    return (
      strength >= DEFAULT_PASSWORD_MIN_STRENGTH && value.length >= DEFAULT_PASSWORD_THRESHOLD_LENGTH
    );
  };

  const isSubmitDisabled = () => {
    return (
      // !state.currentPassword ||
      !state.newPassword ||
      !isValidPassword(state.newPassword, state.passwordStrength) ||
      resetState.isResetting
    );
  };

  const confirmChange = async () => {
    setResetState({
      isResetting: true,
      resetError: false,
    });

    if (isSubmitDisabled()) {
      return;
    }

    try {
      await _userService.setNewPassword(userId, state.currentPassword, state.newPassword);

      dispatch(
        showSuccessSnackbar(
          t('settings:workspace.change-password.change-password-dialog.snackbar-change-success')
        )
      );
      cancel();
    } catch (error) {
      if (error.status === 403) {
        setResetState({
          isResetting: false,
          resetError: t(
            'settings:workspace.change-password.change-password-dialog.reset-error-incorrect-password'
          ),
        });
      } else {
        setResetState({
          isResetting: false,
          resetError: t(
            'settings:workspace.change-password.change-password-dialog.reset-unknown-error'
          ),
        });
      }
    }
  };

  const onCurrentPasswordChange = (event) => {
    setState({ ...state, currentPassword: event.target.value });
  };

  const onNewPasswordChanged = (pwd) => {
    setState({ ...state, newPassword: pwd.password, passwordStrength: pwd.strength });
  };

  const cancel = () => {
    setState({ currentPassword: '', newPassword: '', passwordStrength: 0 });
    setResetState({
      isResetting: false,
      resetError: false,
    });
    onCancel();
  };

  return (
    <DialogTemplate
      confirmAction={confirmChange}
      confirmDisabled={isSubmitDisabled()}
      confirmLabel={t('settings:workspace.change-password.change-password-dialog.confirm-label')}
      fullWidth={false}
      open={Boolean(isOpen)}
      title={t('settings:workspace.change-password.change-password-dialog.title')}
      onClose={cancel}
    >
      <BlockText weight="medium">
        {t('settings:workspace.change-password.change-password-dialog.input-current-password')}
      </BlockText>
      <TextField
        autoComplete="current-password"
        error={!!resetState.resetError}
        fullWidth
        name="currentPassword"
        required
        type="password"
        value={state.currentPassword}
        onChange={onCurrentPasswordChange}
      />
      <br />
      <br />
      <BlockText weight="medium">
        {t('settings:workspace.change-password.change-password-dialog.input-new-password')}
      </BlockText>
      <Suspense fallback={<PasswordFieldSkeleton />}>
        <PasswordField
          error={!!resetState.resetError}
          label=""
          name="newPassword"
          placeholder=""
          thresholdLength={DEFAULT_PASSWORD_THRESHOLD_LENGTH}
          onPasswordChanged={onNewPasswordChanged}
        />
      </Suspense>
      <br />
      <br />
      {!!resetState.resetError && (
        <ParagraphText color="red" noPadding size="sm">
          {resetState.resetError}
        </ParagraphText>
      )}
    </DialogTemplate>
  );
}

export default ChangePasswordDialog;
