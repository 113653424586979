function removeElementAttribute({
  element,
  attribute,
}: {
  element: HTMLElement;
  attribute: string;
}): HTMLElement {
  element.removeAttribute(attribute);

  return element;
}

export default removeElementAttribute;
