import { grey } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import ErrorIcon from '@material-ui/icons/Error';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';
import { InfoIcon } from 'semji-core/icons/InfoIcon';

import { clearSnackbar } from '@/store/actions/ui';
import clsx from '@/utils/clsx';

const variantIcon = {
  default: null,
  error: ErrorIcon,
  hint: EmojiObjectsIcon,
  info: InfoIcon,
  success: CheckCircleIcon,
  warning: InfoIcon,
};

const useStyles = makeStyles((theme) => ({
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  hint: {
    backgroundColor: grey[800],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    marginRight: theme.spacing(1),
    opacity: 0.9,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  message: {
    alignItems: 'center',
    display: 'flex',
  },
  success: {
    backgroundColor: grey[900],
  },
  warning: {
    backgroundColor: '#FFF4F6',
    color: '#000000',
  },
}));

function SnackbarContentWrapper(props) {
  const classes = useStyles();
  const { className, message, variant, onClose, actions, ...other } = props;
  const Icon = variantIcon[variant];
  const dispatch = useDispatch();

  const snackbarActions = useSelector((state) => state?.ui?.snackbarActions);
  const matchEditor = useMatch('o/:organizationId/w/:workspaceId/p/:pageId/content/*');
  const matchNewdraft = useMatch('/o/:organizationId/w/:workspaceId/planning/create-drafts/new/*');
  useEffect(() => {
    if (snackbarActions?.length > 0 && !matchEditor && !matchNewdraft) {
      dispatch(clearSnackbar());
    }
  }, [matchEditor, matchNewdraft, snackbarActions]);

  if (!variant) return <SnackbarContent />;

  return (
    <SnackbarContent
      action={[
        ...actions,
        variant !== 'warning' && (
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            style={{ padding: '0 5px' }}
            onClick={onClose}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>
        ),
      ]}
      aria-describedby="client-snackbar"
      className={clsx(classes[variant], className)}
      message={
        <span className={classes.message} id="client-snackbar">
          {Icon && <Icon className={clsx(classes.icon, classes.iconVariant)} theme="info" />}
          {message}
        </span>
      }
      {...other}
    />
  );
}

export default function NotificationSnackbar() {
  const dispatch = useDispatch();
  const { snackbarMessage, snackbarOpen, snackbarActions } = useSelector((state) => state.ui);

  function handleClose() {
    dispatch(clearSnackbar());
  }

  function handleTimeout(event, reason) {
    if (reason === 'timeout') handleClose();
  }

  return (
    <Snackbar
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom',
      }}
      autoHideDuration={snackbarOpen === 'default' ? null : 4000}
      open={!!snackbarOpen}
      onClose={handleTimeout}
    >
      <SnackbarContentWrapper
        actions={snackbarActions}
        message={snackbarMessage}
        variant={snackbarOpen}
        onClose={handleClose}
      />
    </Snackbar>
  );
}
