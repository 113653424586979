import { useTranslation } from 'react-i18next';

import PrimaryColumn from '@/containers/Content/BriefComponents/Table/Columns/PrimaryColumn';

import Row from './Row';

function EmptyRow() {
  const { t } = useTranslation();

  return (
    <Row>
      <PrimaryColumn halign="middle">{t('common:labels.no-results-found')}</PrimaryColumn>
    </Row>
  );
}

export default EmptyRow;
