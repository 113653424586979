import i18next from 'i18next';
import { DateTime } from 'luxon';

export const isRelativeUrl = (urlString) => {
  return !/^https?:\/\/|^\/\//i.test(urlString);
};

function getUrlErrorMessage(hasHash, hasPath, hasTld, hasValidProtocol, urlObject) {
  if (hasHash) {
    return i18next.t('pages:urls-list.helper-text.incorrect-url-fragment');
  }
  if (!hasPath) {
    return i18next.t('pages:urls-list.helper-text.incorrect-url-no-path');
  }
  if (!hasValidProtocol) {
    return i18next.t('pages:urls-list.helper-text.incorrect-url-invalid-protocol');
  }
  if (!hasTld) {
    return i18next.t('pages:urls-list.helper-text.incorrect-url-no-tld');
  }
  if (!urlObject) {
    return i18next.t('pages:urls-list.helper-text.incorrect-url');
  }
  return null;
}

export const isValidPageUrl = (url) => {
  try {
    const urlObject = new URL(url); // valid url
    const hasValidProtocol = ['https:', 'http:'].includes(urlObject.protocol); //only http or https protocols are authorized
    const domainSplittedByDots = urlObject.hostname.split('.');
    const hasTld =
      domainSplittedByDots.length > 1 && !!domainSplittedByDots[domainSplittedByDots.length - 1]; // has a dot (to verify that the url has potentially a tld)
    const hasPath = urlObject.pathname.length > 1 || url[url.length - 1] === '/';
    const hasHash = urlObject.hash && urlObject.hash.includes('#'); // does not contains fragments
    return {
      error: getUrlErrorMessage(hasHash, hasPath, hasTld, hasValidProtocol, !!urlObject),
      value: !!urlObject && hasTld && hasValidProtocol && hasPath && !hasHash,
    };
  } catch (e) {
    return { error: i18next.t('pages:urls-list.helper-text.incorrect-url'), value: false };
  }
};

export const getFragmentDirective = (fragmentString) => {
  if (!fragmentString) {
    return '';
  }

  const cleanString = fragmentString.trim();
  const fragmentPrefix = ':~:text=';

  return `${fragmentPrefix}${encodeURIComponent(cleanString)
    .replaceAll('.', '%2E')
    .replaceAll('-', '%2D')}`;
};

export const hasGoodUrlOrDomainPattern = (url) => {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    url = 'http://' + url;
  }
  return hasGoodUrlPattern(url);
};

export const hasGoodUrlPattern = (url) => {
  try {
    const urlObject = new URL(url); // valid url
    const hasValidProtocol =
      ['https:', 'http:'].includes(urlObject.protocol) &&
      0 === url.indexOf(urlObject.protocol + '//'); //only http or https protocols are authorized
    const domainSplittedByDots = urlObject.hostname.split('.');
    const hasTld =
      domainSplittedByDots.length > 1 && !!domainSplittedByDots[domainSplittedByDots.length - 1]; // has a dot (to verify that the url has potentially a tld)
    const hasHash = urlObject.hash && urlObject.hash.includes('#'); // does not contains fragments
    // Semji does not handle websites without a TLD
    return !!urlObject && hasValidProtocol && hasTld && !hasHash;
  } catch (e) {
    return false;
  }
};

export const formatUrl = (url, preferHttps) => {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    url = (preferHttps ? 'https://' : 'http://') + url;
  }
  return url;
};

export const isUrlValidWithWebSiteUrl = (url, websiteUrl) => {
  if (websiteUrl.indexOf('http') !== 0) {
    websiteUrl = 'http://' + websiteUrl;
  }

  try {
    return new URL(url).host === new URL(websiteUrl).host;
  } catch (e) {
    return false;
  }
};

// /!\ Warning: Some servers are case sensitive for path name.
export const isUrlAlreadyInDraftList = (item, list = []) =>
  list.filter((draft) => draft.url.toLocaleLowerCase() === item.toLocaleLowerCase()).length > 1;

// /!\ Warning: Some servers are case sensitive for path name.
export const isUrlAlreadyInList = (item, list = []) =>
  list.filter((url) => url.toLocaleLowerCase() === item.toLocaleLowerCase()).length > 1;

export const formatUrlForDisplay = (url) => String(url).replace(/^(https?:\/\/)?(www\.)?/, '');

export const downloadFileFromBlob = async ({
  fileBlob,
  filePrefix = 'download',
  fileType = 'pdf',
}) => {
  const windowUrl = window.URL || window.webkitURL;
  const url = windowUrl.createObjectURL(await fileBlob.blob());
  const date = DateTime.fromJSDate(new Date());
  const anchor = document.createElement('a');

  anchor.href = url;
  anchor.download = `${filePrefix}_${date}.${fileType}`;
  anchor.click();
  anchor.remove();
  windowUrl.revokeObjectURL(url);

  return undefined;
};

export const downloadFileFromUrl = (fileUrl) => {
  const anchor = document.createElement('a');
  anchor.href = fileUrl;
  anchor.click();
  anchor.remove();
};

export function downloadFileWithHtml({ filePrefix = 'html', html, fileType = 'txt' }) {
  const date = DateTime.fromJSDate(new Date());
  const element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(html));
  element.setAttribute('download', `${filePrefix}_${date}.${fileType}`);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export const getOriginFromUrl = (websiteUrl) => {
  try {
    const urlObject = new URL(websiteUrl);
    return urlObject.origin;
  } catch {
    return websiteUrl;
  }
};

export function getDomainFromUrl(url) {
  const subdomain = getOriginFromUrl(formatUrl(url)).replace(/https?:\/\//i, '');
  const domain = subdomain.split('.');
  return domain[domain.length - 2] + '.' + domain[domain.length - 1];
}
