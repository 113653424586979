import i18next from 'i18next';
import { Tooltip } from 'semji-core/components/Tooltip';
import { Placements } from 'semji-core/hooks/usePopover';
import styled from 'styled-components/macro';

import Flex from '@/design-system/components/Flex/Flex';

const SelectorWrapper = styled(Flex)``;
const SelectorCell = styled(Flex)`
  font-weight: ${({ theme }) => theme.textCss.weights.medium};
  font-size: ${({ theme }) => theme.textCss.sizes.sm};
  background-color: ${({ theme, selected }) =>
    selected ? theme.cssColors.primary005 : 'transparent'};
  color: ${({ theme, selected }) => (selected ? theme.cssColors.primary : theme.cssColors.dark060)};
  cursor: ${({ selected }) => (selected ? 'help' : 'pointer')};
  border: 1px solid
    ${({ theme, selected }) => (selected ? theme.cssColors.primary010 : theme.cssColors.dark010)};

  &:hover {
    color: ${({ theme, selected }) =>
      selected ? theme.cssColors.secondaryDark : theme.cssColors.dark080};
  }
`;

function Selector({ handleSelect, list, ...props }) {
  return (
    <SelectorWrapper {...props}>
      {list.map((elem) => {
        return (
          <Tooltip
            className={elem?.tooltipProps?.className}
            hide={elem?.tooltipProps == null}
            isFrame={false}
            popoverOptions={{ placement: Placements.Bottom }}
            title={i18next.t(elem?.tooltipProps?.tooltipTitle)}
            tooltipClassName="content-ideas-listing-header__tooltip"
          >
            <SelectorCell
              key={elem?.value}
              alignItems="center"
              data-intercom-target={elem?.['data-intercom-target']}
              padding="8px"
              selected={elem?.isSelected}
              whiteSpace="nowrap"
              onClick={handleSelect(elem?.value)}
            >
              {elem?.tooltipProps?.tooltipIcon && elem?.tooltipProps?.tooltipIcon}
              {i18next.t(elem?.tooltipProps?.label)}
            </SelectorCell>
          </Tooltip>
        );
      })}
    </SelectorWrapper>
  );
}

export default Selector;
