export enum ENUM_CONTENT_TYPE {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  ORIGINAL = 'ORIGINAL',
}

export interface Content {
  id: string;
  assignedTo: object; // TODO: define type
  assignedToId: string;
  dueDate: string;
  contentStatus: any; // TODO: define type
  contentStatusId: string;
  version: number;
  createdAt: string;
  html: string;
  text: string;
  title: string;
  metaDescription: string;
  page: object; // TODO: define type
  pageId: string;
  contentScore: number;
  wordsCount: number;
  publishedAt: string;
  type: ENUM_CONTENT_TYPE;
  workspace: object; // TODO: define type
  workspaceId: string;
  pageFocusTopKeyword: object; // TODO: define type
  pageFocusTopKeywordId: string;
  isStarted: boolean;
  folderId: string;
  nbUnreadComments: number;
  publishedById: string;
}
