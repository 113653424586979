import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import FocusKeywordSelector from '@/components/FocusKeyword';
import KeywordService from '@/services/Keyword';
import PageService from '@/services/Page';
import { showErrorSnackbar } from '@/store/actions/ui';
import { SECTIONS } from '@/utils/log/constants';
import { Log } from '@/utils/log/Log';
import { noop } from '@/utils/noop';

function FocusKeywordSelectorCell({
  workspaceCountryName,
  focusKeyword,
  focusTopKeyword,
  hasSearchConsole,
  pageId,
  variant,
  onUpdate = noop,
  setIsFetching,
  ...props
}) {
  const _KeywordService = new KeywordService(null, pageId);
  const _PageService = new PageService(pageId);
  const [focusTopKeywordId, setFocusTopKeywordId] = React.useState(focusTopKeyword?.id);
  const [focusKeywords, setFocusKeywords] = React.useState(
    focusTopKeyword ? [focusTopKeyword] : []
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();

  async function onKeywordAdd(option) {
    setIsFetching?.(true);
    // add the temporary focus keyword to the list
    setFocusKeywords([...focusKeywords, option]);
    setFocusTopKeywordId(option.id);

    try {
      const newTopKeyword = await _PageService.addTopKeyword(option.keyword);
      await _PageService.setFocusTopKeyword(newTopKeyword['@id']);

      // removing the added temp keyword from the list
      const newTopKeywords = focusKeywords.filter((o) => o.keyword !== newTopKeyword.keyword);

      //setting the focus top keyword with the right metrics
      setFocusKeywords([...newTopKeywords, newTopKeyword]);
      setFocusTopKeywordId(newTopKeyword.id);
      onUpdate(newTopKeyword);
      setIsFetching?.(false);
    } catch (error) {
      Log.report({
        context: 'onKeywordAdd',
        error,
        extra: 'Add top keyword on page',
        section: SECTIONS.pages.key,
      });
      dispatch(showErrorSnackbar(t('common:error.default')));
    }
  }

  async function onKeywordChange(option) {
    setIsFetching?.(true);
    setFocusTopKeywordId(option.id);

    try {
      await _PageService.setFocusTopKeyword(option.topKeywordData['@id']);
      onUpdate(option);
      setIsFetching?.(false);
    } catch (error) {
      Log.report({
        context: 'onKeywordChange',
        error,
        extra: 'Set top keyword on page',
        section: SECTIONS.pages.key,
      });
      dispatch(showErrorSnackbar(t('common:error.default')));
    }
  }

  async function getKeywordsByPage() {
    try {
      const keywords = await _KeywordService.keywords;
      setFocusKeywords(keywords);
    } catch (error) {
      Log.report({
        context: 'getKeywordsByPage',
        error,
        extra: 'Get list of keywords on page',
        section: SECTIONS.pages.key,
      });
      setFocusKeywords(focusKeyword ? [focusKeyword] : []);
      throw error;
    }
  }

  return (
    <FocusKeywordSelector
      focusTopKeywordId={focusTopKeywordId}
      getKeywordsByPage={getKeywordsByPage}
      hasSearchConsole={Boolean(hasSearchConsole)}
      topKeywords={focusKeywords}
      variant={variant}
      workspaceCountryName={workspaceCountryName}
      onKeywordAdd={onKeywordAdd}
      onKeywordChange={onKeywordChange}
      {...props}
    />
  );
}

export default memo(FocusKeywordSelectorCell);
