import { ReportMetricCategoryWithPosition } from '@/containers/Report/utils/types';
import { METRICS_CONFIG } from '@/utils/metrics/constants';

import {
  CHANGE_METRICS_CONFIG_CURRENT_METRIC,
  CHANGE_SELECTED_METRIC,
  SET_COMPETITOR_DATA_LABELS_ENABLED,
  SET_FACT_CHECKING_ENABLED,
  SET_HIGHLIGHT_COMMENT_ENABLED,
  SET_HIGHLIGHT_FACT_CHECKING_ENABLED,
  SET_MARK_AS_PUBLISHED_HELPER_MESSAGE_ENABLED,
  SET_REPORT_ACCORDION_STATE,
} from '../actions/actionTypes';

// state interface
export interface IUserConfigurationState {
  currentMetricKey: string;
  factCheckingEnabled: boolean;
  highlightCommentEnabled: boolean;
  highlightFactCheckingEnabled: boolean;
  selectedMetrics: string[];
  markAsPublishedHelperMessageEnabled: boolean;
  showCompetitorDataLabels: boolean;
  isReportAccordionOpen: {
    [key in ReportMetricCategoryWithPosition]: boolean;
  };
}

const initialState: IUserConfigurationState = {
  currentMetricKey: METRICS_CONFIG.clicks.key,
  factCheckingEnabled: false,
  highlightCommentEnabled: true,
  highlightFactCheckingEnabled: true,
  isReportAccordionOpen: Object.values(ReportMetricCategoryWithPosition).reduce(
    (acc: any, category) => {
      acc[category] = true;
      return acc;
    },
    {}
  ),
  markAsPublishedHelperMessageEnabled: true,
  selectedMetrics: [
    METRICS_CONFIG.clicks.key,
    METRICS_CONFIG.conversions.key,
    METRICS_CONFIG.conversionRate.key,
  ],
  showCompetitorDataLabels: false,
};

function userConfiguration(state = initialState, action: any): IUserConfigurationState {
  switch (action.type) {
    case CHANGE_METRICS_CONFIG_CURRENT_METRIC:
      return { ...state, currentMetricKey: action.currentMetricKey };
    case CHANGE_SELECTED_METRIC:
      return {
        ...state,
        selectedMetrics: [
          ...state.selectedMetrics.slice(0, action.index),
          action.metricKey,
          ...state.selectedMetrics.slice(action.index + 1),
        ],
      };
    case SET_FACT_CHECKING_ENABLED:
      return {
        ...state,
        factCheckingEnabled: action.payload,
      };
    case SET_HIGHLIGHT_COMMENT_ENABLED:
      return {
        ...state,
        highlightCommentEnabled: action.payload,
      };
    case SET_HIGHLIGHT_FACT_CHECKING_ENABLED:
      return {
        ...state,
        highlightFactCheckingEnabled: action.payload,
      };
    case SET_MARK_AS_PUBLISHED_HELPER_MESSAGE_ENABLED:
      return {
        ...state,
        markAsPublishedHelperMessageEnabled: action.payload,
      };
    case SET_COMPETITOR_DATA_LABELS_ENABLED:
      return {
        ...state,
        showCompetitorDataLabels: action.payload,
      };
    case SET_REPORT_ACCORDION_STATE:
      return {
        ...state,
        isReportAccordionOpen: {
          ...state.isReportAccordionOpen,
          [action.payload.category]: action.payload.isOpen,
        },
      };

    default:
      return state;
  }
}

export default userConfiguration;
