import styled from 'styled-components/macro';

import { LoaderWrapper } from '../Loader/FlatLoader';

export const StyledLoaderWrapper = styled((props) => (
  <LoaderWrapper {...props} height={props.height ?? 10} width={props.width ?? 35} />
))`
  display: flex;
  align-items: center;
  margin: auto 0;
`;
