import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import apiClient from '@/apis/semji/apiClient';
import { showErrorSnackbar } from '@/store/actions/ui';

export default function usePostPublishContent(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { post } = apiClient;

  return useMutation({
    mutationFn: async ({ contentId, date = new Date(), url }) =>
      await post(`/contents/${contentId}/publication`, { date, url }),

    onError: () => {
      dispatch(showErrorSnackbar(t('common:error.default')));
    },
    ...props,
  });
}
