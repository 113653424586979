import './BriefEmptyState.scss';

import { PrimaryButton } from 'semji-core/components/Button';

function BriefEmptyState({ Icon, title, body, button, onClick }) {
  return (
    <div className="brief-empty-state">
      <div className="brief-empty-state__wrapper">
        <Icon className="brief-empty-state__icon" />
        <h2 className="brief-empty-state__title">{title}</h2>
        <p className="brief-empty-state__body">{body}</p>
        {button && (
          <PrimaryButton className="brief-empty-state__button" onClick={onClick}>
            {button}
          </PrimaryButton>
        )}
      </div>
    </div>
  );
}

export default BriefEmptyState;
