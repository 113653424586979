import { useOutletContext } from 'react-router-dom';

import { Content } from '@/types/contents';
import { ContentVersion } from '@/types/contents/ContentVersion.types';

export type ContentVersionsContext = {
  contents: Content[];
  contentVersions: ContentVersion[];
  isLoading: boolean;
  createNewDraft: () => void;
  isCreating: boolean;
};

export function useContentVersions() {
  return useOutletContext<ContentVersionsContext>();
}
