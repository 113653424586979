import Beautify from 'js-beautify';

export function decodeHtml(html: string) {
  let textArea = document.createElement('textarea');
  textArea.innerHTML = html;
  return textArea.value;
}

export function getInnerText(html: string) {
  let textArea = document.createElement('div');
  textArea.innerHTML = html;
  return textArea.innerText;
}

export function formatTags(html: string) {
  // TODO: Improve this function to text highlighting
  return decodeHtml(html)
    .replace(/<del>(.*?)<\/del>/g, '$1')
    .replace(/<ins>(.*?)<\/ins>/g, '$1');
}

export function beautifyHtml(html: string) {
  return Beautify.html(cleanHtml(html));
}

export function cleanHtml(html: string) {
  return html
    .replace(/data-semji-fact-check="(.+?)"/gim, '')
    .replace(/data-semji-annotation-comment-threads="(.+?)"/gim, '')
    .replace(/data-mce-href="(.+?)"/gim, '')
    .replace(/data-mce-annotation="(.+?)"/gim, '')
    .replace(/data-mce-annotation-uid"(.+?)"/gim, '')
    .replace(/<img[^>]*>/gim, '')
    .replace(/<map[^>]*>/gim, '');
}

export function handleDisplayMode(
  title: string | null,
  metaDescription: string | null,
  content: string | null,
  type: string | null,
  displayMode: string
) {
  return displayMode === 'Html'
    ? displayHtml(title, metaDescription, content, type)
    : displayText(title, metaDescription, content, type);
}

function displayHtml(
  title: string | null,
  metaDescription: string | null,
  content: string | null,
  type: string | null
) {
  return `
    <html>
      <head>
        <title>${title ?? ''}</title>
        <meta name="description" content="${metaDescription ?? ''}" />
      </head>
      <body>
        ${content ?? ''}
      </body>
    </html>
    ${type}
    `;
}

function displayText(
  title: string | null,
  metaDescription: string | null,
  content: string | null,
  type: string | null
) {
  return `
      <head>
        <h6 class="title">${title ?? ''}</h6>
        <p class="meta-description">${metaDescription ?? ''}</p>
      </head>
      <body>
        ${content ?? ''}
      </body>
      ${type}
    `;
}
