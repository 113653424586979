import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { DialogModal, ENUM_DIALOG_MODAL_SIZE } from 'semji-core/components/DialogModal';
import styled from 'styled-components/macro';

import { BlockText, InlineText } from '@/components/Text/Inline';
import { ParagraphText } from '@/components/Text/Paragraph';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';

const ParagraphTextMarginBottom = styled(ParagraphText)`
  margin-bottom: 30px;
  padding: 0px;
`;
function BulkAnalysisDialog({
  pagesWithFkCount,
  selectedPageCount,
  open,
  onClose,
  onConfirm,
  isLoading,
  creditsCost,
  variant = 'pages', // Could be "pages" or "planning" depending on the screen.
}) {
  const { isFeatureEnabled: hasUnlimitedAnalysisCredits } = useOrganizationFeatureSet(
    'credits:analysis:has-unlimited-amount'
  );

  const { t } = useTranslation();

  const hasSomePagesWithoutFk = pagesWithFkCount !== selectedPageCount;
  const pagesText = t(
    variant === 'planning'
      ? 'components:dialog.bulk-analysis.drafts-text_interval'
      : 'components:dialog.bulk-analysis.pages-text_interval',
    {
      count: pagesWithFkCount,
      postProcess: 'interval',
    }
  );
  const selectedPageText = t(
    variant === 'planning'
      ? 'components:dialog.bulk-analysis.selected-drafts-text_interval'
      : 'components:dialog.bulk-analysis.selected-pages-text_interval',
    {
      count: selectedPageCount,
      postProcess: 'interval',
    }
  );
  const creditsText = t('components:dialog.bulk-analysis.credits-text_interval', {
    count: creditsCost,
    postProcess: 'interval',
  });

  return (
    <DialogModal
      cancelAction={onClose}
      cancelLabel={t('components:dialog.bulk-analysis.cancel')}
      canClickOutsideToClose
      closable
      confirmAction={onConfirm}
      confirmLabel={t('components:dialog.bulk-analysis.analyze')}
      isConfirmDisabled={isLoading}
      isOpen={open}
      size={ENUM_DIALOG_MODAL_SIZE.Small}
      title={t('components:dialog.bulk-analysis.title', { pagesText })}
      onClose={onClose}
    >
      <>
        <ParagraphTextMarginBottom color="dark080" weight="400">
          {hasUnlimitedAnalysisCredits && (
            <Trans
              components={{
                text: <InlineText color="dark080" size="default" weight="strong" />,
              }}
              i18nKey={'components:dialog.bulk-analysis.content-unlimited-credits'}
              values={{ pagesText }}
            />
          )}
          {!hasUnlimitedAnalysisCredits && (
            <Trans
              components={{
                text: <InlineText color="dark080" size="default" weight="strong" />,
              }}
              i18nKey={'components:dialog.bulk-analysis.content'}
              values={{ creditsText, pagesText }}
            />
          )}
          {hasSomePagesWithoutFk && (
            <BlockText color="dark060" size="xs" weight="medium">
              {t('components:dialog.bulk-analysis.info-pages-without-fk_interval', {
                count: pagesWithFkCount,
                postProcess: 'interval',
                selectedPageText,
              })}
            </BlockText>
          )}
        </ParagraphTextMarginBottom>
      </>
    </DialogModal>
  );
}

BulkAnalysisDialog.propTypes = {
  creditsCost: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  pagesWithFkCount: PropTypes.number.isRequired,
  selectedPageCount: PropTypes.number.isRequired,
};

export default BulkAnalysisDialog;
