import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import { exportPages } from '@/apis/semji/api';
import { LOCATION } from '@/apis/semji/constants';
import useGetCurrentWorkspaceContentsWithSpecificProperties from '@/apis/semji/contents/useGetCurrentWorkspaceContentsWithSpecificProperties';
import useGetCurrentWorkspacePagesWithSpecificProperties from '@/apis/semji/pages/useGetCurrentWorkspacePagesWithSpecificProperties';
import useGetCurrentWorkspaceFocusTopKeywordsWithSpecificProperties from '@/apis/semji/topKeywords/useGetCurrentWorkspaceFocusTopKeywordsWithSpecificProperties';
import usePostTopKeywordAnalysis from '@/apis/semji/topKeywords/usePostTopKeywordAnalysis';
import Pages from '@/components/Pages/Pages';
import useApiConfigurations from '@/hooks/useApiConfigurations';
import { useViewsGoalsConfig } from '@/hooks/useViewsGoalsConfig';
import PageService from '@/services/Page';
import { showErrorSnackbar, showSuccessSnackbar } from '@/store/actions/ui';
import { selectHasAnalytics } from '@/store/selectors/selectHasAnalytics';
import { selectHasSearchConsole } from '@/store/selectors/selectHasSearchConsole';
import { SOURCE_PAGE_BULK, SOURCE_PAGE_LIST } from '@/utils/analysis';
import { LISTING_REFETCH_FREQUENCY_MS } from '@/utils/configurations/constants';
import { ALL_TAB, OPPORTUNITIES_TAB } from '@/utils/filter/constants';
import listByPropertyReducer from '@/utils/listByPropertyReducer';
import { SECTIONS } from '@/utils/log/constants';
import { Log } from '@/utils/log/Log';
import {
  ANALYTICS_DEPENDENCY,
  NO_DEPENDENCY,
  SEARCH_CONSOLE_DEPENDENCY,
} from '@/utils/metrics/constants';
import { downloadFileFromBlob } from '@/utils/url';

export const FILTERS_DEPENDENCY = [
  {
    attribute: 'clicks',
    dependency: SEARCH_CONSOLE_DEPENDENCY,
  },
  {
    attribute: 'conversions',
    dependency: ANALYTICS_DEPENDENCY,
  },
  {
    attribute: 'goal1Completions',
    dependency: ANALYTICS_DEPENDENCY,
  },
  {
    attribute: 'goal2Completions',
    dependency: ANALYTICS_DEPENDENCY,
  },
  {
    attribute: 'goal3Completions',
    dependency: ANALYTICS_DEPENDENCY,
  },
  {
    attribute: 'transactions',
    dependency: ANALYTICS_DEPENDENCY,
  },
  {
    attribute: 'revenue',
    dependency: ANALYTICS_DEPENDENCY,
  },
  {
    all: 'all',
    dependency: NO_DEPENDENCY,
  },
];

const OPPORTUNITIES_FILTERS = FILTERS_DEPENDENCY.map((filter) => filter.attribute);

function PagesList() {
  const REFETCH_INTERVAL_IN_MS = parseInt(useApiConfigurations(LISTING_REFETCH_FREQUENCY_MS), 10);
  const dispatch = useDispatch();
  const location = useLocation();
  const [query, setQuery] = useSearchParams();
  const { workspaceId } = useParams();
  const { t } = useTranslation();

  const hasSearchConsole = useSelector(selectHasSearchConsole);
  const hasAnalytics = useSelector(selectHasAnalytics);
  const viewsGoals = useViewsGoalsConfig();

  const topKeywordAnalysis = usePostTopKeywordAnalysis({
    onError: () => dispatch(showErrorSnackbar(t('common:error.default'))),
    onSettled: () => {
      refreshPagesByPotentialScore();
    },
  });

  const tabValue = query.get('tab') || ALL_TAB;
  const priorityScoreValue = query.get('priorityScore');

  function isDependencyConfigured(dependency) {
    switch (dependency) {
      case ANALYTICS_DEPENDENCY:
        return hasAnalytics;
      case SEARCH_CONSOLE_DEPENDENCY:
        return hasSearchConsole;
      default:
        return true;
    }
  }

  function isCallBlocked(potentialScore) {
    const currentViewsGoal = viewsGoals.find((goal) => goal.attribute === potentialScore);
    const currentOpportunitiesFilter = FILTERS_DEPENDENCY.find(
      (filter) => filter?.attribute === potentialScore
    );
    if (
      !isDependencyConfigured(currentOpportunitiesFilter.dependency) ||
      (currentViewsGoal && !currentViewsGoal?.isConfigured)
    ) {
      return true;
    }
    return false;
  }

  const propertiesPages = [
    'id',
    'url',
    'title',
    'contentScore',
    'draftId',
    'extra',
    'optimizedImages',
    'imageUrl',
    'source',
    'new',
    'redirectionUrl',
    'focusTopKeywordId',
    'titleRetrievedAt',
    'topKeywordsRetrievedAt',
    'lastStatusCode',
    ...(priorityScoreValue && OPPORTUNITIES_FILTERS.includes(priorityScoreValue)
      ? ['potentialScore']
      : []),
  ];

  const filtersPages = {
    'exists[url]': true,
    inStock: false,
    ...(priorityScoreValue && OPPORTUNITIES_FILTERS.includes(priorityScoreValue)
      ? { potentialScore: priorityScoreValue }
      : {}),
  };

  const pagesWithoutFkRes = useGetCurrentWorkspacePagesWithSpecificProperties({
    enabled: !isCallBlocked(filtersPages?.potentialScore),
    filters: filtersPages,
    location: LOCATION.PAGES_PAGES,
    onError: () => {
      dispatch(showErrorSnackbar(t('listing:pages-list.notification.something-went-wrong')));
    },
    properties: propertiesPages,
    refetchInterval: REFETCH_INTERVAL_IN_MS,
  });

  const propertiesFocusTopKeyword = [
    'id',
    'analysisStatus',
    'keyword',
    'position',
    'lastSearchConsoleUpdatedAt',
    'searchVolume',
    'keywordDataUpdatedAt',
    'analyzed',
    'associatedToPagesCount',
    'associatedToDraftsCount',
  ];

  const focusTopKeywordsRes = useGetCurrentWorkspaceFocusTopKeywordsWithSpecificProperties({
    enabled: !isCallBlocked(filtersPages?.potentialScore),
    filters: filtersPages,
    location: LOCATION.PAGES,
    onError: () => {
      dispatch(showErrorSnackbar(t('listing:pages-list.notification.something-went-wrong')));
    },
    properties: propertiesFocusTopKeyword,
    refetchInterval: REFETCH_INTERVAL_IN_MS,
    refetchOnWindowFocus: 'always',
  });

  const isLoadingFk = focusTopKeywordsRes.isFetching && focusTopKeywordsRes.isPlaceholderData;
  const isLoadingPagesWithoutFk =
    pagesWithoutFkRes.isFetching && pagesWithoutFkRes.isPlaceholderData;

  const isLoadingPages = isLoadingFk || isLoadingPagesWithoutFk;

  const focusTopKeywordsById = focusTopKeywordsRes.data.reduce(listByPropertyReducer('id'), {});
  const pages = pagesWithoutFkRes.data.map((page) => {
    page.focusTopKeyword = focusTopKeywordsById[page?.focusTopKeywordId] ?? null;
    return page;
  });

  function refreshPagesByPotentialScore() {
    focusTopKeywordsRes.refetch();
    pagesWithoutFkRes.refetch();
  }

  const allPagesRes = useGetCurrentWorkspacePagesWithSpecificProperties({
    filters: {
      'exists[url]': true,
      inStock: false,
    },
    location: LOCATION.PAGES_ALL_PAGES,
    onError: () => {
      dispatch(showErrorSnackbar(t('listing:pages-list.notification.something-went-wrong')));
    },
    properties: ['id', 'extra'],
    refetchInterval: REFETCH_INTERVAL_IN_MS,
    refetchOnWindowFocus: 'always',
  });

  const isLoadingAllPages = allPagesRes.isFetching && allPagesRes.isPlaceholderData;

  const contentsRes = useGetCurrentWorkspaceContentsWithSpecificProperties({
    filters: {
      inStock: false,
      type: { not_equals: 'ORIGINAL' },
    },
    location: LOCATION.PAGES,
    properties: ['id', 'pageId', 'type'],
    refetchInterval: REFETCH_INTERVAL_IN_MS,
    refetchOnWindowFocus: 'always',
  });

  useEffect(() => {
    // We use history.replace here because we are in the context of an invalid filter.
    if (![ALL_TAB, OPPORTUNITIES_TAB].includes(tabValue)) {
      query.set('tab', OPPORTUNITIES_TAB);
      query.set('priorityScore', 'clicks');
      setQuery(query, { replace: true });
    } else if (ALL_TAB === tabValue && !!priorityScoreValue) {
      query.delete('priorityScore');
      setQuery(query, { replace: true });
    } else if (
      OPPORTUNITIES_TAB === tabValue &&
      !OPPORTUNITIES_FILTERS.includes(priorityScoreValue)
    ) {
      query.set('priorityScore', 'clicks');
      setQuery(query, { replace: true });
    } else {
      refreshPagesByPotentialScore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    return () => {
      allPagesRes.remove();
      contentsRes.remove();
      pagesWithoutFkRes.remove();
      focusTopKeywordsRes.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function triggerUpdateAnalysis(focusTopKeyword) {
    if (!focusTopKeyword.analyzed) {
      topKeywordAnalysis.mutate({
        data: {
          source: SOURCE_PAGE_LIST,
        },
        topKeywordIdList: [focusTopKeyword.id],
      });
    }
  }

  async function triggerBulkUpdateAnalysis(pages) {
    return topKeywordAnalysis.mutateAsync({
      data: {
        source: SOURCE_PAGE_BULK,
      },
      topKeywordIdList: pages.map((page) => page.focusTopKeyword.id),
    });
  }

  async function triggerBulkAddToPlanning({
    pagesList,
    assignedTo,
    dueDate,
    contentStatus,
    folderId,
  }) {
    try {
      await Promise.all(
        pagesList.map((page) => {
          const pageService = new PageService(page.id, workspaceId);
          return pageService.saveNewDraft({
            assignedTo,
            contentStatus,
            dueDate,
            folderId,
            html: null,
            title: page.title,
          });
        })
      );
      return Promise.resolve();
    } catch (error) {
      Log.error({ error });
      dispatch(showErrorSnackbar(t('common:error.default')));
    } finally {
      // Refresh data after
      refreshPagesByPotentialScore();
    }
  }

  async function triggerBulkExportPages(selectedPagesId, fileType) {
    try {
      const response = await exportPages({
        fileType,
        filters: filtersPages,
        pagesIds: selectedPagesId,
        workspaceId,
      });
      if (response.isAsync) {
        dispatch(
          showSuccessSnackbar(t('listing:pages-list.export.notification.async-export-success'))
        );
      } else {
        downloadFileFromBlob({ fileBlob: response, filePrefix: 'export', fileType });
      }
    } catch (error) {
      Log.report({
        context: 'exportPages',
        error,
        extra: 'Bulk export pages',
        section: SECTIONS.content.key,
      });
      dispatch(showErrorSnackbar(t('common:error.default')));
      throw error;
    }
  }

  async function triggerDeletePages(pagesIds = []) {
    const i18nNameSpace = 'components:dialog.delete-page-dialog';

    return Promise.all(
      pagesIds.map((id) => {
        const _PageService = new PageService(id);
        return _PageService.delete();
      })
    )
      .then(() => {
        refreshPagesByPotentialScore();
        dispatch(
          showSuccessSnackbar(
            t(`${i18nNameSpace}.success-delete-notification`, {
              count: pagesIds.length,
              postProcess: 'interval',
            })
          )
        );
      })
      .catch(() => dispatch(showErrorSnackbar(t(`${i18nNameSpace}.error-delete-notification`))));
  }

  return (
    <Pages
      allPages={allPagesRes.data}
      contents={contentsRes.data}
      loading={isLoadingAllPages || isLoadingPages}
      pages={pages}
      refreshData={refreshPagesByPotentialScore}
      triggerBulkAddToPlanning={triggerBulkAddToPlanning}
      triggerBulkExportPages={triggerBulkExportPages}
      triggerBulkUpdateAnalysis={triggerBulkUpdateAnalysis}
      triggerDeletePages={triggerDeletePages}
      triggerUpdateAnalysis={triggerUpdateAnalysis}
    />
  );
}

export default PagesList;
