import { UserModel } from '@/types/user/user.types';

export const selectCurrentWorkspaceAccessByKey = (
  state?: { user: UserModel; [key: string]: any },
  params?: {
    key: string;
    workspaceId: string;
  }
): string | undefined => {
  return state && params?.key
    ? // @ts-ignore
      state.user?.workspaceAccesses?.find((wa: any) => wa.workspace.id === params?.workspaceId)?.[
        params?.key
      ] ?? undefined
    : undefined;
};
