import MuiDialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import CloseIcon from '@/components/icons/CloseIcon';
import ThreeDotsIcon from '@/components/icons/ThreeDotsIcon';
import PrimaryButton from '@/design-system/components/Button/PrimaryButton';
import SecondaryButton from '@/design-system/components/Button/SecondaryButton';
import TertiaryButton from '@/design-system/components/Button/TertiaryButton';
import Flex from '@/design-system/components/Flex/Flex';
import { noop } from '@/utils/noop';

const StyledDialog = styled(MuiDialog)`
  && {
    .MuiDialog-paper {
      overflow-y: ${({ $canContentOverflow }) => $canContentOverflow && 'unset'};
    }
  }
`;

export const Container = styled.div`
  padding: 35px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.h2`
  flex: 1;
  margin: 0;
  color: ${({ theme }) => theme.cssColors.dark100};
  font-size: ${({ theme }) => theme.textCss.sizes.lg};
  font-weight: 400;
  line-height: ${({ theme }) => theme.textCss.sizes.xl};

  &&::first-letter {
    text-transform: 'capitalize';
  }
`;

export const Close = styled(CloseIcon)`
  && {
    cursor: pointer;
    font-size: ${({ theme }) => theme.textCss.sizes.default};
    color: ${({ theme }) => theme.icon.colors.default};
  }
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: ${({ textAlign }) => textAlign};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: center;
  min-height: 60px;
  margin: 25px 0 45px 0;
  font-size: ${({ theme }) => theme.textCss.sizes.default};
  font-weight: 400;
  line-height: ${({ theme }) => theme.textCss.sizes.md};
  gap: ${({ gap }) => gap};
`;

const Loader = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.textCss.sizes.xl};
`;

export const Footer = styled(Flex)`
  & > button {
    margin-left: 20px;
  }
`;

function Dialog({
  // Useful for dropdown in dialog's body.
  canContentOverflow,
  cancelLabel,
  confirmLabel,
  children,
  isConfirmDisabled,
  isLoading = false,
  isOpen = false,
  title,
  hasCloseIcon = true,
  tertiaryButton,
  canCancelOnOutsideClick = true,
  onCancel = noop,
  onConfirm = noop,
  maxWidth = 'sm',
  justifyFooter = 'flex-end',
}) {
  const buttonWidth = maxWidth !== 'xs' ? '200px' : '140px';
  const { t } = useTranslation();

  return (
    <StyledDialog
      $canContentOverflow={canContentOverflow}
      aria-describedby="dialog-description"
      aria-labelledby="dialog-title"
      fullWidth
      maxWidth={maxWidth}
      open={isOpen}
      onClose={canCancelOnOutsideClick && onCancel}
    >
      <Container>
        <Header>
          <Title id="dialog-title">{title}</Title>
          {hasCloseIcon !== false && <Close onClick={onCancel} />}
        </Header>
        <Content id="dialog-description">
          {isLoading && (
            <Loader>
              <ThreeDotsIcon />
            </Loader>
          )}
          {children}
        </Content>
        {(onConfirm !== noop || onCancel !== noop) && (
          <Footer alignItems="center" display="flex" justifyContent={justifyFooter}>
            {tertiaryButton ? (
              <TertiaryButton minWidth={buttonWidth} onClick={onCancel}>
                {cancelLabel ?? t('common:labels.cancel')}
              </TertiaryButton>
            ) : (
              <SecondaryButton minWidth={buttonWidth} onClick={onCancel}>
                {cancelLabel ?? t('common:labels.cancel')}
              </SecondaryButton>
            )}
            <PrimaryButton
              disabled={isLoading || isConfirmDisabled}
              minWidth={buttonWidth}
              onClick={onConfirm}
            >
              {confirmLabel ?? t('common:labels.confirm')}
            </PrimaryButton>
          </Footer>
        )}
      </Container>
    </StyledDialog>
  );
}

Dialog.propTypes = {
  cancelLabel: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  confirmLabel: PropTypes.string,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  title: PropTypes.string,
};

export default Dialog;
