import { useSearchParams } from 'react-router-dom';

import { FALSE_VALUE } from '@/components/Pages/ContentIdeas/Listing/constant';
import {
  StyledExternalIcon,
  TitleDetailed,
  TitleDetailedWrapper,
} from '@/containers/ContentIdeas/Listing/MindMap/Nodes/Keyword/KeywordNode.styled';
import Flex from '@/design-system/components/Flex';
import defaultTheme from '@/themes/defaultTheme';

import KeywordFooter from './KeywordFooter';

function HoverCard({ data }) {
  const [query] = useSearchParams();
  const clusterId = query.get('clusterId');

  function handleNavigate() {
    window.open(data.url, '_blank');
  }

  const cardMargin = clusterId === FALSE_VALUE ? '0 20px' : '0 80px 0 90px';

  return (
    <Flex
      backgroundColor="white"
      borderRadius="8px"
      boxShadow={defaultTheme.boxShadow.modal}
      cursor="pointer"
      flexDirection="column"
      gap="6px"
      margin={cardMargin}
      maxWidth="450px"
      opacity={data.blurred ? 0.3 : 1}
      padding="12px 20px"
    >
      <TitleDetailedWrapper alignItems="center" gap="8px" onClick={handleNavigate}>
        <TitleDetailed
          dangerouslySetInnerHTML={{ __html: data.titleLabel }}
          fontWeight={defaultTheme.textCss.weights.strong}
        />
        <StyledExternalIcon />
      </TitleDetailedWrapper>
      <KeywordFooter data={data} />
    </Flex>
  );
}

export default HoverCard;
