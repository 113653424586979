// CRAWL ERROR TYPES
export const STATIC_HTML_CRAWL_ERROR = 'STATIC_HTML_CRAWL_ERROR';
export const CONTENT_HTML_CRAWL_ERROR = 'CONTENT_HTML_CRAWL_ERROR';

export const MIN_ZOOM_LEVEL = 1;
export const DEFAULT_ZOOM_LEVEL = 1.5;
export const MAX_ZOOM_LEVEL = 2;
export const STEP_ZOOM_LEVEL = 0.25;

export const ONLINE_PAGE_TAB = 'online';
export const TEXT_PAGE_TAB = 'text';
