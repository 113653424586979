import React from 'react';
import styled from 'styled-components/macro';

import Loader from '@/components/Loader/Loader';
import { getColorForPercentageValue } from '@/hoc/ColorComputer';
interface PieComponentProps {
  value: number;
  bgColor: string;
  secondaryBgColor: string;
  path: string;
  smallMode?: boolean;
  extraSmallMode?: boolean;
  extraLargeMode?: boolean;
  loading?: boolean;
  [key: string]: any;
}
const PieComponent = styled(
  ({
    value,
    bgColor,
    secondaryBgColor,
    path,
    smallMode,
    extraSmallMode,
    extraLargeMode,
    loading,
    ...props
  }: PieComponentProps) => (
    <div {...props}>
      <svg className="progress" viewBox="0 0 100 100">
        <path d={path} />
      </svg>
      <div className="center">
        {loading ? (
          <Loader fontSize="1em" />
        ) : (
          <>
            <span className="score">{value}</span>
            {smallMode || extraSmallMode || extraLargeMode ? null : (
              <span className="total">/100</span>
            )}
          </>
        )}
      </div>
    </div>
  )
)`
  height: ${(props: PieComponentProps) =>
    props.extraSmallMode ? '36px' : props.smallMode ? '40px' : '50px'};
  width: ${(props: PieComponentProps) =>
    props.extraSmallMode ? '36px' : props.smallMode ? '40px' : '50px'};
  display: flex;
  position: relative;
  border-radius: 50%;
  margin: 10px auto;
  transition: all 0.3s ease-in-out;
  background: linear-gradient(
    to top,
    ${(props: PieComponentProps) => props.bgColor},
    ${(props: PieComponentProps) => props.secondaryBgColor}
  );

  .progress {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-shadow: 0px 0px 0px 4px white;
    fill: #ececec;
  }

  .center {
    top: 2px;
    left: 2px;
    position: absolute;
    height: calc(100% - 4px);
    width: calc(100% - 4px);
    background: white;
    border-radius: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .score {
      font-size: ${(props: PieComponentProps) =>
        props.extraSmallMode ? '90%' : props.smallMode ? '90%' : '120%'};
      font-weight: 500;
      color: ${(props: PieComponentProps) => props.bgColor};
    }

    .total {
      font-size: 60%;
      font-weight: 500;
      color: #ececec;
    }
  }
`;

function PureContentScoreComponent({
  score,
  smallMode,
  extraSmallMode,
  extraLargeMode,
  loading,
  dataIntercomTarget,
  ...props
}: {
  score: number;
  smallMode?: boolean;
  extraSmallMode?: boolean;
  extraLargeMode?: boolean;
  loading?: boolean;
  dataIntercomTarget?: string;
  [key: string]: any;
}) {
  const { color, secondaryColor } = getColorForPercentageValue({ value: score });

  let path = 'M 50 100, 50 50';

  if (score >= 87.5) {
    path += ', ' + (50 + (100 - score) * 4) + ' 100';
  } else if (score >= 75) {
    path += ', 100 ' + (100 - (87.5 - score) * 4) + ', 100 100';
  } else if (score >= 62.5) {
    path += ', 100 ' + (50 - (75 - score) * 4) + ', 100 100';
  } else if (score >= 50) {
    path += ', ' + (100 - (62.5 - score) * 4) + ' 0, 100 0, 100 100';
  } else if (score >= 37.5) {
    path += ', ' + (50 - (50 - score) * 4) + ' 0, 100 0, 100 100';
  } else if (score >= 25) {
    path += ', 0 ' + (37.5 - score) * 4 + ', 0 0, 100 0, 100 100';
  } else if (score >= 12.5) {
    path += ', 0 ' + (50 + (25 - score) * 4) + ', 0 0, 100 0, 100 100';
  } else {
    path += ',' + (12.5 - score) * 4 + ' 100, 0 100 , 0 0, 100 0, 100 100';
  }

  path += ' Z';

  return (
    <div {...props}>
      <PieComponent
        bgColor={color}
        data-intercom-target={dataIntercomTarget}
        extraLargeMode={extraLargeMode}
        extraSmallMode={extraSmallMode}
        loading={loading}
        path={path}
        secondaryBgColor={secondaryColor}
        smallMode={smallMode}
        value={score}
      />
    </div>
  );
}

export default PureContentScoreComponent;
