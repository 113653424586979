import { useQueryClient } from '@tanstack/react-query';
import { lazy, Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import { LOCATION, SCOPE_CONTENT_IDEAS } from '@/apis/semji/constants';
import usePutArchiveContentIdeas from '@/apis/semji/contentIdeas/usePutArchiveContentIdeas';
import { FlexGrowContainer } from '@/components/_common';
import { LayoutContent } from '@/components/Layout/Layout';
import { PageDataLayout } from '@/components/PageDataLayout';
import { FAILED_STATUS, PENDING_STATUS } from '@/components/Pages/ContentIdeas/constant';
import { MIND_MAP_VALUE } from '@/components/Pages/ContentIdeas/Listing/constant';
import { SidebarToggle } from '@/components/Sidebar';
import Header from '@/containers/ContentIdeas/Listing/Header';
import List from '@/containers/ContentIdeas/Listing/List';
import Panel from '@/containers/ContentIdeas/Listing/Panel';
import Flex from '@/design-system/components/Flex';
import useCan from '@/hooks/useCan';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import { showErrorSnackbar, showSuccessSnackbar } from '@/store/actions/ui';
import defaultTheme from '@/themes/defaultTheme';

const MindMap = lazy(
  () => import(/* webpackChunkName: "MindMap" */ '@/containers/ContentIdeas/Listing/MindMap')
);

const UndoWrapper = styled.div`
  color: ${({ theme }) => theme.cssColors.primary};
  margin-left: 10px;
  font-weight: 500;
  line-height: 21px;
  cursor: pointer;
`;
const Wrapper = styled(LayoutContent)`
  ${({ theme }) => theme.mediaQueries.phone} {
    && {
      margin-top: 0;
    }
  }
`;

function ContentIdeasListing({
  contentIdeasClusters,
  contentIdeaSearch,
  contentIdeasList,
  contentIdeasLoading,
  handleSelectContentIdeas,
  isAddToPlanningDialogOpen,
  isRemoveFromPlanningDialogOpen,
  contentsToAddToPlanning,
  contentsToRemoveFromPlanning,
  selectedIds,
  handleOpenAddToPlanningModal,
  handleOpenStartOptimizingModal,
  handleRemoveFromPlanningDialog,
  handleOpenEditDraftModal,
  handleSelectContentIdeasInline,
  handleOpenExportModal,
  nbContentIdeasForCurrentKeywordType,
  pages,
  isSearchDeprecated,
  isClusterizationStatusSuccess,
}) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isHeaderOpen, setIsHeaderOpen] = useState(true);

  const { t } = useTranslation();
  const { contentIdeaSearchId } = useParams();

  const isClusteringDisplayedFeatureFlag = useCan({
    perform: 'content_ideas.clustering.displayed',
  });
  const { isFeatureEnabled: isClusteringFeayureSet } = useOrganizationFeatureSet(
    'content-ideas:has-access-to-clusters'
  );
  const isClusteringEnabled = isClusteringDisplayedFeatureFlag && isClusteringFeayureSet;

  const isSearchPending = contentIdeaSearch?.status === PENDING_STATUS || false;

  const clusterizationStatus = contentIdeaSearch?.clusterizationStatus;
  const isClusterizationFailed = clusterizationStatus === FAILED_STATUS;

  const [query, setQuery] = useSearchParams();

  const viewType = query.get('viewType');
  const keywordType = query.get('keywordType');

  const archiveContentIdeas = usePutArchiveContentIdeas({
    onError: () => {
      dispatch(showErrorSnackbar(t('common:error.default')));
    },
    onMutate: async (data) => {
      const queryKey = [
        SCOPE_CONTENT_IDEAS.CONTENT_IDEAS,
        contentIdeaSearchId,
        LOCATION.CONTENT_IDEAS,
        keywordType,
        isClusteringEnabled,
        isClusterizationStatusSuccess,
      ];
      await queryClient.cancelQueries({ queryKey });
      const previousData = queryClient.getQueryData(queryKey);
      const newData = {
        ...previousData,
        contentIdeasList: previousData.contentIdeasList.reduce(
          (prev, curr) =>
            data.contentIdeasIds.includes(curr.id)
              ? [...prev, { ...curr, isArchived: data.isArchived }]
              : [...prev, curr],
          []
        ),
      };
      queryClient.setQueryData(queryKey, newData);
    },
    onSettled: () =>
      queryClient.refetchQueries({
        active: true,
        exact: true,
        queryKey: [
          SCOPE_CONTENT_IDEAS.CONTENT_IDEAS,
          contentIdeaSearchId,
          LOCATION.CONTENT_IDEAS,
          keywordType,
          isClusteringEnabled,
          isClusterizationStatusSuccess,
        ],
      }),
    onSuccess: (response) => {
      response[0].data.isArchived
        ? dispatch(
            showSuccessSnackbar(
              t('content-ideas:listing.archive.archive-success-message_interval', {
                count: response.length,
                postProcess: 'interval',
              }),
              [
                <UndoWrapper key="undo" onClick={handleUndo(response)}>
                  {t('content-ideas:listing.archive.undo')}
                </UndoWrapper>,
              ]
            )
          )
        : dispatch(
            showSuccessSnackbar(
              t('content-ideas:listing.archive.restore-success-message_interval', {
                count: response.length,
                postProcess: 'interval',
              })
            )
          );
    },
  });

  function handleUndo(response) {
    return () => {
      dispatch(showSuccessSnackbar(t('content-ideas:listing.archive.undo-success')));
      archiveContentIdeas.mutate({
        contentIdeasIds: response.map((elem) => elem.data.id),
        isArchived: !response[0].data.isArchived,
      });
    };
  }

  useEffect(() => {
    query.set(
      'viewType',
      !isClusteringEnabled || viewType !== MIND_MAP_VALUE ? 'listing' : 'mind-map'
    );
    setQuery(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClusteringEnabled, viewType, isClusterizationStatusSuccess]);

  return (
    <PageDataLayout
      sideBar={{
        content: (
          <Panel
            contentIdeasClusters={contentIdeasClusters}
            contentIdeasList={contentIdeasList}
            contentIdeasLoading={contentIdeasLoading}
            status={clusterizationStatus}
          />
        ),
        titleContent: <span>{t('content-ideas:menu.title')}</span>,
      }}
    >
      <FlexGrowContainer>
        <SidebarToggle className="pl-8 pt-8" />
        <Wrapper flex={1} flexDirection="column" isSidebarOpen={isSidebarOpen}>
          <Header
            contentIdeasKeyword={contentIdeaSearch?.keyword}
            contentIdeasLoading={contentIdeasLoading}
            isClusterizationFailed={isClusterizationFailed}
            isHeaderOpen={isHeaderOpen}
            isSearchDeprecated={isSearchDeprecated}
            isSearchPending={isSearchPending}
          />
          <Flex
            borderTop={viewType === MIND_MAP_VALUE && `1px solid ${defaultTheme.cssColors.dark010}`}
            flex={1}
            padding={!viewType === MIND_MAP_VALUE && '0 32px 32px 32px'}
          >
            {viewType === MIND_MAP_VALUE ? (
              <Flex position="relative" width="100%">
                <Suspense fallback={<></>}>
                  <MindMap
                    archiveContentIdeas={archiveContentIdeas}
                    clusterizationStatus={clusterizationStatus}
                    contentIdeasClusters={contentIdeasClusters}
                    contentIdeaSearch={contentIdeaSearch}
                    contentIdeasList={contentIdeasList}
                    contentIdeasLoading={contentIdeasLoading}
                    contentsToAddToPlanning={contentsToAddToPlanning}
                    contentsToRemoveFromPlanning={contentsToRemoveFromPlanning}
                    handleOpenAddToPlanningModal={handleOpenAddToPlanningModal}
                    handleOpenEditDraftModal={handleOpenEditDraftModal}
                    handleRemoveFromPlanningDialog={handleRemoveFromPlanningDialog}
                    isAddToPlanningDialogOpen={isAddToPlanningDialogOpen}
                    isRemoveFromPlanningDialogOpen={isRemoveFromPlanningDialogOpen}
                    setIsHeaderOpen={setIsHeaderOpen}
                    setIsSidebarOpen={setIsSidebarOpen}
                  />
                </Suspense>
              </Flex>
            ) : (
              <List
                archiveContentIdeas={archiveContentIdeas}
                contentIdeasClusters={contentIdeasClusters}
                contentIdeaSearch={contentIdeaSearch}
                contentIdeasKeyword={contentIdeaSearch?.keyword}
                contentIdeasList={contentIdeasList}
                contentIdeasLoading={contentIdeasLoading}
                handleOpenAddToPlanningModal={handleOpenAddToPlanningModal}
                handleOpenEditDraftModal={handleOpenEditDraftModal}
                handleOpenExportModal={handleOpenExportModal}
                handleOpenStartOptimizingModal={handleOpenStartOptimizingModal}
                handleRemoveFromPlanningDialog={handleRemoveFromPlanningDialog}
                handleSelectContentIdeas={handleSelectContentIdeas}
                handleSelectContentIdeasInline={handleSelectContentIdeasInline}
                nbContentIdeasForCurrentKeywordType={nbContentIdeasForCurrentKeywordType}
                pages={pages}
                selectedIds={selectedIds}
                status={contentIdeaSearch?.status}
              />
            )}
          </Flex>
        </Wrapper>
      </FlexGrowContainer>
    </PageDataLayout>
  );
}

export default ContentIdeasListing;
