import styled from 'styled-components/macro';

import { FlexCenteredAlignContainer } from '@/components/_common';
import { DarkText, VerticalSeparator } from '@/components/ActionsBar/BulkActionsBar';
import { SmallFlexTooltipComponent } from '@/components/Tooltip/Tooltip';

const TableActionBar = styled(FlexCenteredAlignContainer)`
  && {
    position: absolute;
    top: 0;
    box-sizing: border-box;
    height: 50px;
    gap: 20px;

    ${DarkText}, ${SmallFlexTooltipComponent}, ${VerticalSeparator} {
      margin: 0;
      padding: 0;
    }
  }
`;

export default TableActionBar;
