import './Header.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ENUM_BUTTON_VARIANTS } from 'semji-core/components/Button';

import { usePageDataLayout } from '@/components/PageDataLayout/PageDataLayout.context';
import MetricsDateConfigSelector from '@/containers/Chart/MetricsDateConfigSelector';
import { ReportView } from '@/containers/Report/utils/types';

export default function Header({
  view,
  title,
  onExportClick,
}: {
  view: ReportView;
  title: string;
  onExportClick: () => void;
}) {
  const { t } = useTranslation();
  const { isSidebarOpen, ToggleComponent } = usePageDataLayout();
  return (
    <div className="page-report-header">
      {!isSidebarOpen && ToggleComponent}
      <div className="page-report-header__title">{title}</div>
      <div className="page-report-header__actions">
        <MetricsDateConfigSelector data-intercom-target="report_metrics_date_config_selectors" />
        <div data-intercom-target={`reports-${view}-export`}>
          <Button variant={ENUM_BUTTON_VARIANTS.Secondary} onClick={onExportClick}>
            {t('report:report-container.export-label')}
          </Button>
        </div>
      </div>
    </div>
  );
}
