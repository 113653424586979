import {
  METRICS_CONFIG_COMPARISON_PERIOD_PREVIOUS_YEAR,
  METRICS_CONFIG_PERIOD_LAST_3_MONTHS,
  METRICS_CONFIG_PERIOD_LAST_6_MONTHS,
  METRICS_CONFIG_PERIOD_LAST_12_MONTHS,
  METRICS_CONFIG_PERIOD_LAST_24_MONTHS,
  METRICS_CONFIG_PERIOD_LAST_36_MONTHS,
  METRICS_CONFIG_PERIOD_LAST_MONTH,
  METRICS_CONFIG_PERIODICITY_DAILY,
  METRICS_CONFIG_PERIODICITY_MONTHLY,
  METRICS_CONFIG_PERIODICITY_QUARTERLY,
  METRICS_CONFIG_PERIODICITY_WEEKLY,
} from './constants';
import { getPreviousDateRangeGapDuration } from './getPreviousDateRangeGapDuration';

// these transformations are needed because of the durations with monthly/weekly/quarterly
// in days are never correct
export const translateDate = ({ period, periodicity, comparisonPeriod }) => {
  const duration = getPreviousDateRangeGapDuration(period, comparisonPeriod);

  if (
    comparisonPeriod === METRICS_CONFIG_COMPARISON_PERIOD_PREVIOUS_YEAR ||
    periodicity === METRICS_CONFIG_PERIODICITY_DAILY ||
    [
      METRICS_CONFIG_PERIOD_LAST_MONTH,
      METRICS_CONFIG_PERIOD_LAST_3_MONTHS,
      METRICS_CONFIG_PERIOD_LAST_6_MONTHS,
      METRICS_CONFIG_PERIOD_LAST_12_MONTHS,
      METRICS_CONFIG_PERIOD_LAST_24_MONTHS,
      METRICS_CONFIG_PERIOD_LAST_36_MONTHS,
    ].includes(period)
  ) {
    return duration;
  }

  if (periodicity === METRICS_CONFIG_PERIODICITY_WEEKLY) {
    return { weeks: Math.round(duration.days / 7) }; //transform period from Rolling days to weeks
  }

  if (periodicity === METRICS_CONFIG_PERIODICITY_MONTHLY) {
    return { months: Math.round(duration.days / 30) }; //transform period from Rolling days to months
  }

  if (periodicity === METRICS_CONFIG_PERIODICITY_QUARTERLY) {
    return { quarters: Math.round(duration.days / 90) }; //transform period from Rolling days to quarters
  }

  return { days: 0 };
};
