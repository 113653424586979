import { nanoid } from 'nanoid';
import React, { useMemo } from 'react';

import SvgWrapper from './SvgWrapper';

const SidePanelFactCheckingIcon = (props) => {
  const maskId = useMemo(() => nanoid(), []);
  return (
    <SvgWrapper {...props} fill="none" height="19" viewBox="0 0 18 19" width="18">
      <svg xmlns="http://www.w3.org/2000/svg">
        <mask
          height="17"
          id={`mask${maskId}`}
          maskUnits="userSpaceOnUse"
          style={{ maskType: 'alpha' }}
          width="16"
          x="1"
          y="1"
        >
          <path
            d="M12.7057 4.7524C14.4569 4.7524 15.8821 3.32722 15.8821 1.57593H16.9409V8.79605C16.9409 14.6736 9.4593 17.3037 9.14165 17.4128L8.96483 17.4742L8.78906 17.4096C8.47248 17.2963 1.05859 14.5486 1.05859 8.82569V1.57593H2.11742C2.11742 3.32722 3.54259 4.7524 5.29389 4.7524C7.04518 4.7524 8.47036 3.32722 8.47036 1.57593H9.52918C9.52918 3.32722 10.9544 4.7524 12.7057 4.7524ZM2.11742 4.37334V8.82463C2.11742 12.882 6.71377 15.3385 8.47036 16.1295V16.1177L8.99977 16.3824L9.58088 16.0918C11.4217 15.2763 15.8821 12.8814 15.8821 8.79605V4.37334C15.106 5.25428 13.9688 5.81016 12.7057 5.81016C11.4393 5.81016 10.3064 5.24581 9.52918 4.36063V4.3824L8.99977 3.67651L8.32842 4.5157C7.55837 5.31181 6.48516 5.81122 5.29389 5.81122C4.03071 5.81122 2.89354 5.25428 2.11742 4.37334Z"
            fill="black"
            style={{ clipRule: 'evenodd', fillRule: 'evenodd' }}
          />
          <path d="M6.28711 10.2749L8.05963 11.8244L11.6467 7.66187" stroke="black" />
        </mask>
        <g mask={`url(#mask${maskId})`}>
          <rect fill="currentColor" height="21.1765" width="21.1765" x="-2.11719" y="-0.558838" />
        </g>
      </svg>
    </SvgWrapper>
  );
};

export default SidePanelFactCheckingIcon;
