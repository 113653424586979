import React from 'react';

export function SearchItem({ item }) {
  return (
    <div className="aa-ItemLink">
      <div className="aa-ItemIcon aa-ItemIcon--noBorder">
        <svg
          fill="none"
          height="17"
          viewBox="0 0 18 17"
          width="18"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            height="17"
            id="mask0_11451_256458"
            maskUnits="userSpaceOnUse"
            style={{ maskType: 'alpha' }}
            width="16"
            x="1"
            y="0"
          >
            <path
              d="M9.5 2.025V1H11V0H7V1H8.5V2.025C4.598 2.285 1.5 5.533 1.5 9.5C1.5 13.636 4.864 17 9 17C13.136 17 16.5 13.636 16.5 9.5C16.5 5.533 13.402 2.285 9.5 2.025V2.025ZM9 16C5.416 16 2.5 13.084 2.5 9.5C2.5 5.916 5.416 3 9 3C12.584 3 15.5 5.916 15.5 9.5C15.5 13.084 12.584 16 9 16ZM9.5 9H13.5V10H8.5V6H9.5V9Z"
              fill="black"
            />
          </mask>
          <g mask="url(#mask0_11451_256458)">
            <rect fill="#A8A9AF" height="20" width="20" x="-1.5" y="-1" />
          </g>
        </svg>
      </div>
      <div className="aa-ItemWrapper">
        <div className="aa-ItemContentTitle">{item.label}</div>
      </div>
    </div>
  );
}
