import cloneDeep from 'lodash/cloneDeep';
import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { AlertDialog } from '@/components/Dialog/AlertDialog';
import { RelativeLoader } from '@/components/Loader/Loader';
import ContentStatusSettings from '@/components/Settings/ContentStatusSettings';
import StatusService from '@/services/Status';
import { showErrorSnackbar, showSuccessSnackbar } from '@/store/actions/ui';
import { CONTENT_STATUS_TO_ADD } from '@/utils/constants';

let asyncActionsQueue = new Promise((resolve, reject) => {
  return resolve();
});

const idsMap = {};

function ContentStatusContainer() {
  const { t } = useTranslation();
  const { workspaceId } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [firstStatus, setFirstStatus] = useState({});
  const [lastStatus, setLastStatus] = useState({});
  const [contentStatuses, setContentStatuses] = useState([]);
  const [deleteStatusState, setDeleteStatusState] = useState({});

  useEffect(() => {
    getListContentStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId]);

  const getListContentStatus = async () => {
    let contentStatusesList = [];

    try {
      setLoading(true);
      const _ContentStatusesService = new StatusService(workspaceId);
      contentStatusesList = (await _ContentStatusesService.status).sort(
        (a, b) => a.position - b.position
      );
      const alterableContentStatuses = contentStatusesList.filter((cs) => !cs.readOnly);
      const maxPosition = contentStatusesList.reduce((max, cs) => Math.max(max, cs.position), 0);

      // set private ids map
      alterableContentStatuses.forEach((acs) => {
        idsMap[acs.id] = acs.id;
      });

      setFirstStatus(contentStatusesList[0]);
      setLastStatus(contentStatusesList[contentStatusesList.length - 1]);

      // set/Reset default position of temporary status after the last known position
      setContentStatuses([
        ...alterableContentStatuses,
        {
          ...CONTENT_STATUS_TO_ADD,
          position: maxPosition + 1,
        },
      ]);
    } catch (e) {
      setContentStatuses(contentStatusesList);
    } finally {
      setLoading(false);
    }
  };

  const verifyStatus = ({ status, label }) => {
    const currentContentStatusIndex = contentStatuses.findIndex(
      (cs) => cs.id === status.id && cs.position === status.position
    );
    const contentStatusWithSameLabelIndex = contentStatuses.findIndex(
      (cs) => cs.label.localeCompare(label, 'en', { sensitivity: 'base' }) === 0
    );
    const isDuplicateLabel =
      (contentStatusWithSameLabelIndex !== -1 &&
        currentContentStatusIndex !== contentStatusWithSameLabelIndex) ||
      firstStatus.label === label ||
      lastStatus.label === label;

    if (!label || label.length < 3) {
      return t('settings:workspace.content-status-settings.status-message-label-too-short');
    }

    if (isDuplicateLabel) {
      return t('settings:workspace.content-status-settings.status-message-label-duplicated');
    }

    return '';
  };

  const addStatus = ({ status, label }) => {
    const newContentStatuses = cloneDeep(contentStatuses);
    const temporaryContentStatusIndex = contentStatuses.findIndex(
      (cs) => status.id && cs.position === status.position
    );
    const privateId = nanoid();
    idsMap[privateId] = null;

    newContentStatuses[temporaryContentStatusIndex] = {
      ...newContentStatuses[temporaryContentStatusIndex],
      id: privateId,
      label,
    };

    // Temporary add the added element to the list
    // set the temporary status to default
    // and set default position of the new temporary status after the last known position
    newContentStatuses.push({
      ...CONTENT_STATUS_TO_ADD,
      position: newContentStatuses[temporaryContentStatusIndex].position + 1,
    });

    setContentStatuses([...newContentStatuses]);

    // ADD STATUS ACTION
    asyncActionsQueue = asyncActionsQueue.then(async () => {
      const _StatusService = new StatusService(workspaceId);
      try {
        const newStatus = await _StatusService.addStatus({
          color: newContentStatuses[temporaryContentStatusIndex].color,
          label,
          position: newContentStatuses[temporaryContentStatusIndex].position,
        });

        idsMap[privateId] = newStatus.id;

        dispatch(
          showSuccessSnackbar(
            t('settings:workspace.content-status-settings.snackbar-add-label-success', {
              label,
            })
          )
        );

        return newStatus;
      } catch (e) {
        dispatch(
          showErrorSnackbar(
            t('settings:workspace.content-status-settings.snackbar-add-label-error', {
              label,
            })
          )
        );
      }
    });
  };

  const updateLabel = ({ status, label }) => {
    if (status.id === CONTENT_STATUS_TO_ADD.id) {
      addStatus({ label, status });
    } else {
      const newContentStatuses = cloneDeep(contentStatuses);
      const newStatus = newContentStatuses.find((cs) => cs.id === status.id);
      newStatus.label = label;
      setContentStatuses([...newContentStatuses]);

      // UPDATE LABEL ACTION
      asyncActionsQueue = asyncActionsQueue.then(async () => {
        const _StatusService = new StatusService(null, idsMap[status.id]);

        try {
          const updatedStatus = await _StatusService.updateStatus({ label });
          dispatch(
            showSuccessSnackbar(
              t('settings:workspace.content-status-settings.snackbar-update-label-success', {
                label,
              })
            )
          );
          return updatedStatus;
        } catch (e) {
          dispatch(
            showErrorSnackbar(
              t('settings:workspace.content-status-settings.snackbar-update-label-error', {
                label,
              })
            )
          );
        }
      });
    }
  };

  const updateColor = ({ status, color }) => {
    const newContentStatuses = cloneDeep(contentStatuses);
    const newStatus = newContentStatuses.find((cs) => cs.id === status.id);
    newStatus.color = color;
    setContentStatuses([...newContentStatuses]);

    if (status.id !== CONTENT_STATUS_TO_ADD.id) {
      // UPDATE COLOR ACTION
      asyncActionsQueue = asyncActionsQueue.then(async () => {
        const _StatusService = new StatusService(null, idsMap[status.id]);
        try {
          const updatedStatus = await _StatusService.updateStatus({ color });
          dispatch(
            showSuccessSnackbar(
              t('settings:workspace.content-status-settings.snackbar-update-label-success', {
                label: status.label,
              })
            )
          );
          return updatedStatus;
        } catch (e) {
          dispatch(
            showErrorSnackbar(
              t('settings:workspace.content-status-settings.snackbar-update-label-error', {
                label: status.label,
              })
            )
          );
        }
      });
    }
  };

  const updatePositions = (newContentStatuses) => {
    const orderedIds = newContentStatuses
      .filter((cs) => cs.id !== CONTENT_STATUS_TO_ADD.id && !cs.readOnly)
      .map((cs) => cs.id);

    // UPDATE POSITIONS ACTION
    asyncActionsQueue = asyncActionsQueue.then(async () => {
      const _StatusService = new StatusService(workspaceId);
      try {
        const newOrder = await _StatusService.putStatusPriorities(
          orderedIds.map((id) => idsMap[id])
        );
        dispatch(
          showSuccessSnackbar(
            t('settings:workspace.content-status-settings.snackbar-update-position-success')
          )
        );
        return newOrder;
      } catch (e) {
        dispatch(
          showErrorSnackbar(
            t('settings:workspace.content-status-settings.snackbar-update-position-error')
          )
        );
      }
    });
  };

  const confirmDeleteStatus = async (statusId) => {
    setContentStatuses(contentStatuses.filter((cs) => cs.id !== statusId));
    setDeleteStatusState({
      ...deleteStatusState,
      id: null,
    });

    // DELETE ACTION
    asyncActionsQueue = asyncActionsQueue.then(async () => {
      const _StatusService = new StatusService(null, idsMap[statusId]);
      try {
        const deletedStatus = await _StatusService.delete();
        dispatch(
          showSuccessSnackbar(
            t('settings:workspace.content-status-settings.snackbar-delete-success')
          )
        );
        return deletedStatus;
      } catch (e) {
        dispatch(
          showErrorSnackbar(t('settings:workspace.content-status-settings.snackbar-delete-error'))
        );
      }
    });
  };

  const deleteStatus = async ({ id, label }) => {
    if (id !== CONTENT_STATUS_TO_ADD.id) {
      setDeleteStatusState({
        id,
        label,
      });
    }
  };

  if (loading) {
    return <RelativeLoader />;
  }

  return (
    <>
      <ContentStatusSettings
        contentStatuses={contentStatuses}
        contentStatusesChange={setContentStatuses}
        deleteStatus={deleteStatus}
        firstStatus={firstStatus}
        lastStatus={lastStatus}
        updateColor={updateColor}
        updateLabel={updateLabel}
        updatePositions={updatePositions}
        verifyStatus={verifyStatus}
      />
      <AlertDialog
        confirmLabel={t('settings:workspace.content-status-settings.dialog-confirm-label', {
          label: deleteStatusState.label,
        })}
        show={!!deleteStatusState.id}
        text={
          <Trans
            components={{
              span: <span />,
              strong: <strong />,
            }}
            i18nKey={'settings:workspace.content-status-settings.dialog-content'}
            values={{
              label: deleteStatusState.label,
            }}
          />
        }
        title={t('settings:workspace.content-status-settings.dialog-title', {
          label: deleteStatusState.label,
        })}
        onCancel={() =>
          setDeleteStatusState({
            ...deleteStatusState,
            id: null,
          })
        }
        onConfirm={() => confirmDeleteStatus(deleteStatusState.id)}
      />
    </>
  );
}

export default ContentStatusContainer;
