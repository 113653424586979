import './OutgoingLinksLegacy.scss';

import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { DefaultLink } from '@/components/Navigation/Link';
import { Instruction } from '@/components/Optimization/Instruction';
import Optimization from '@/components/Optimization/Optimization';
import DetailsBase from '@/containers/Content/SidePanel/Components/Optimization/Details/DetailsBase';
import useApiConfigurations from '@/hooks/useApiConfigurations';
import { disableMainOptimization, enableMainOptimization } from '@/store/actionsCreator/report';
import { SUPPORT_ADDRESS_MAIL } from '@/utils/constants';

function OutgoingLinksLegacy({
  actualLinksCount,
  expectedLinksCount,
  score,
  weight,
  links,
  disabled,
  focusTopKeywordId,
  html,
  contentTitle,
  disableMainOptimization,
  enableMainOptimization,
  keyword,
  pageId,
  ready,
  ...props
}) {
  const { t } = useTranslation();

  const [showDetails, setShowDetails] = useState(false);
  const linkWithUrlCount = links?.filter((link) => !!link.anchor).length || 0;
  const isFeatureEnabled = useApiConfigurations('analysis.outgoing-links.retrieval.enabled');

  function disableRecommendation(key) {
    return disableMainOptimization(key, focusTopKeywordId, html, contentTitle);
  }

  function enableRecommendation(key) {
    return enableMainOptimization(key, focusTopKeywordId, html, contentTitle);
  }

  function closeDetails() {
    setShowDetails(false);
  }
  const AlertLegacyMessage = ({ message }) => {
    return (
      <div className="outgoing-links-legacy__message--legacy">
        <div>{message}</div>
      </div>
    );
  };

  return (
    <>
      <Optimization
        alertMessage={
          isFeatureEnabled && (
            <AlertLegacyMessage
              key="key"
              message={
                <Trans
                  components={{
                    br: <br />,
                    contact: (
                      <DefaultLink
                        backgroundColor="primary"
                        color="white"
                        isExternal
                        padding="6px"
                        rounded="2px"
                        size="xs"
                        to={`mailto:${SUPPORT_ADDRESS_MAIL}`}
                        weight="medium"
                      >
                        {t('components:alert-legacy-message.contact-us')}
                      </DefaultLink>
                    ),

                    p: <p></p>,
                    strong: <strong></strong>,
                  }}
                  i18nKey={t(
                    'content:side-panel-components.optimization.section.outgoing-links-legacy.message'
                  )}
                />
              }
            />
          )
        }
        description={
          <Trans
            components={{
              extLink: (
                <DefaultLink
                  color="dark040"
                  decoration
                  isExternal
                  noDynamicFontSize
                  noPadding
                  size={'xsm'}
                  to={t('common:links.help-outgoing-links')}
                  weight="medium"
                />
              ),
            }}
            i18nKey="content:side-panel-components.optimization.section.outgoing-links.description"
          />
        }
        disabled={disabled}
        disableRecommendation={disableRecommendation}
        enableRecommendation={enableRecommendation}
        helpCenterLink={t('common:links.help-outgoing-links')}
        infoText={t('content:side-panel-components.optimization.section.outgoing-links.info-text')}
        instructions={[
          <Instruction
            key="outgoingLinks"
            allowActions={false}
            completed={1 === score}
            defaultLabelFontWeight="normal"
            disabled={disabled}
            label={t(
              'content:side-panel-components.optimization.section.outgoing-links.instruction-label_interval',
              {
                count: expectedLinksCount,
                postProcess: 'interval',
              }
            )}
          />,
        ]}
        isEmpty={false} // we always have a recommendations of at least one link for outgoing links legacy
        isOutgoingLinksLegacy={true}
        isReady={ready}
        loaderRows={1}
        score={score}
        scorerTooltipText={t(
          'content:side-panel-components.optimization.section.outgoing-links.scorer-tooltip-text',
          { expectedLinksCount }
        )}
        title={t('content:side-panel-components.optimization.section.outgoing-links.title')}
        infoMessage={''} // there is no info message for outgoing links legacy
        // set the state of showDetails to display the modal of details
        // temporary disabled
        points={Math.round((weight - weight * score) * 100)}
        scorer={`${actualLinksCount}/${expectedLinksCount}`}
        // Disabled, see this comment: https://gitlab.rvip.fr/semji/semji/-/issues/2158#note_84560
        showDetailsAction={undefined}
        {...props}
      />
      <DetailsBase
        description={t(
          'content:side-panel-components.optimization.section.outgoing-links.details-description_interval',
          {
            count: linkWithUrlCount,
            postProcess: 'interval',
          }
        )}
        show={showDetails}
        subTitle={t(
          'content:side-panel-components.optimization.section.outgoing-links.details-subtitle_interval',
          {
            count: expectedLinksCount,
            postProcess: 'interval',
          }
        )}
        title={t('content:side-panel-components.optimization.section.outgoing-links.title')}
        onClose={closeDetails}
      />
    </>
  );
}

const mapStateToProps = (state, props) => {
  return {
    actualLinksCount: state.report.recommendations[props.id]?.data?.actualLinksCount,
    contentTitle: state.content.title,
    disabled: state.report.recommendations[props.id]?.disabled,
    expectedLinksCount: state.report.recommendations[props.id]?.data?.expectedLinksCount,
    html: state.content.html,
    links: state.report.recommendations[props.id]?.underlyingData?.links,
    ready: state.report.recommendations[props.id]?.ready,
    score: state.report.recommendations[props.id]?.score,
    weight: state.report.recommendations[props.id]?.weight,
  };
};

export default connect(mapStateToProps, {
  disableMainOptimization,
  enableMainOptimization,
})(OutgoingLinksLegacy);
