import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { FlexCenteredAlignContainer } from '@/components/_common';
import { Position } from '@/components/CompetitorPage/CompetitorPage';
import FavIcon from '@/components/FavIcon/FavIcon';
import TooltipComponent from '@/components/Tooltip/Tooltip';

const CompetitorWrapper = styled(FlexCenteredAlignContainer)`
  height: 55px;
`;
const CompetitorWordCount = styled(FlexCenteredAlignContainer)`
  padding-right: 1em;
  height: 100%;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  flex: 2;
`;
const CompetitorEmptyWordCount = styled.div`
  height: 60%;
  width: ${(props) => `calc(100% - ${props.width * 0.8}%)`};
  background: #fff;
  display: flex;
  align-items: center;
`;
const CompetitorPlainWordCount = styled.div`
  height: 60%;
  width: ${(props) => `${props.width * 0.8}%`};
  background: ${(props) =>
    props.isCurrentPage ? props.theme.colors.secondary : props.theme.colors.grey};
`;
const Count = styled.span`
  color: ${(props) =>
    props.isCurrentPage
      ? `${props.theme.text.colors.secondary}`
      : `${props.theme.text.colors.darker}`};
  font-size: 0.7em;
  width: 100px;
  text-align: right;
  font-weight: 500;
`;
const FavIconWrapper = styled.div`
  max-width: 200px;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;
const StyledPosition = styled(Position)`
  font-size: 0.7em;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;
const StyledFlexCenteredAlignContainer = styled(FlexCenteredAlignContainer)`
  border-right: 1px solid ${(props) => props.theme.colors.grey};
  height: 100%;
`;

function LengthDetails({ targetWordsCount, id }) {
  const { t } = useTranslation();
  const i18nNameSpace = 'content:side-panel-components.optimization.details.length-details';
  const competitor = useSelector((state) => state.report.recommendations[id] || {});
  const width = targetWordsCount
    ? Math.min(100, parseInt((competitor.data.wordCount / targetWordsCount) * 100, 10))
    : 0;

  return (
    <div>
      <CompetitorWrapper>
        <StyledFlexCenteredAlignContainer>
          <TooltipComponent placement="top" title={t(`${i18nNameSpace}.rank`)}>
            <StyledPosition
              disabled={competitor.disabled}
              isCurrentPage={competitor.data.isConsideredSameAsPage}
            >
              {competitor.data.position}
            </StyledPosition>
          </TooltipComponent>
          <FavIconWrapper disabled={competitor.disabled}>
            <FavIcon defaultColor disableClick displayLink url={competitor.data.url} />
          </FavIconWrapper>
        </StyledFlexCenteredAlignContainer>
        <CompetitorWordCount disabled={competitor.disabled}>
          <CompetitorPlainWordCount
            isCurrentPage={competitor.data.isConsideredSameAsPage}
            width={width}
          />
          <CompetitorEmptyWordCount
            isCurrentPage={competitor.data.isConsideredSameAsPage}
            width={width}
          >
            <Count isCurrentPage={competitor.data.isConsideredSameAsPage}>
              {t(`${i18nNameSpace}.words-number`, {
                count: competitor.data.wordCount,
                postProcess: 'interval',
              })}
            </Count>
          </CompetitorEmptyWordCount>
        </CompetitorWordCount>
      </CompetitorWrapper>
    </div>
  );
}

export default LengthDetails;
