import styled from 'styled-components/macro';

const NumberBadge = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: ${(props) => props.theme.textCss.weights[props.weight || 'normal']};
  font-size: ${(props) => props.theme.textCss.sizes[props.size || 'default']};

  border-radius: 50%;

  padding: 0.2rem;
  margin: 0 0.5rem;

  color: ${(props) => props.theme.cssColors[props.color ?? 'dark080']};
  background-color: ${(props) => props.theme.cssColors[props.bgColor ?? 'dark010']};
  width: 20px;
  height: 20px;
`;

export default NumberBadge;
