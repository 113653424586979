import {
  DEFAULT_PAGE,
  DEFAULT_PER_PAGE,
  QUERY_PARAM_PAGE,
  QUERY_PARAM_PER_PAGE,
} from '@/components/AGGrid/hooks/useGridPagination/const';
import { IPagination } from '@/components/AGGrid/hooks/useGridPagination/useGridPagination.types';

export class UseGridPaginationUtils {
  static getPaginationState(query: any): IPagination {
    const queryPage = query.get(QUERY_PARAM_PAGE);
    const queryPerPage = query.get(QUERY_PARAM_PER_PAGE);
    return {
      itemsPerPage: queryPerPage ? parseInt(queryPerPage) : DEFAULT_PER_PAGE,
      page: queryPage ? parseInt(queryPage) : DEFAULT_PAGE,
    };
  }
}
