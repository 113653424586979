import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import apiClient from '@/apis/semji/apiClient';
import { showErrorSnackbar } from '@/store/actions/ui';

import { UsePostRefreshContentData } from './usePostRefreshContentData.types';

export default function usePostRefreshContentData({
  contentId,
  ...props
}: UsePostRefreshContentData = {}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { post } = apiClient;

  return useMutation({
    mutationFn: async (id) => await post(`/contents/${id || contentId}/refresh_data`, {}),

    onError: () => {
      dispatch(showErrorSnackbar(t('common:error.default')));
    },
    ...props,
  });
}
