import { useSearchParams } from 'react-router-dom';

import { SEPARATOR_QUERY_PARAM } from '@/containers/Competitors/utils/constants';

export default function useValidateFilter() {
  const [query, setQuery] = useSearchParams();

  function handleValidateFilter(filterKey: string, options: { id: string }[]) {
    return (selectedValues: string[] | string) => {
      const isArray = Array.isArray(selectedValues);
      const numberOfSelectedValues = isArray ? selectedValues.length : 0;
      const numberOfOptions = options.length;

      if (isArray && numberOfSelectedValues > 0) {
        if (numberOfSelectedValues === numberOfOptions) {
          query.delete(filterKey);
        } else {
          query.set(filterKey, selectedValues.join(SEPARATOR_QUERY_PARAM));
        }

        setQuery(query, { replace: true });

        return;
      }

      if (!isArray && !selectedValues) {
        query.delete(filterKey);
      } else {
        query.set(filterKey, selectedValues.toString());
      }

      setQuery(query, { replace: true });
    };
  }

  function getSelectedFilters(filterKey: string, options: { id: string }[]): string[] {
    return (
      query.get(filterKey)?.split(SEPARATOR_QUERY_PARAM) ?? options.map((option) => option.id) ?? []
    );
  }

  function getAllSelectedFilters(): Record<string, string[]> {
    const filters: Record<string, string[]> = {};

    for (const [key, value] of query.entries()) {
      filters[key] = value.split(SEPARATOR_QUERY_PARAM);
    }

    return filters;
  }

  return { getAllSelectedFilters, getSelectedFilters, handleValidateFilter };
}
