import { DateTime } from 'luxon';

import { GOOGLE_API_ROOT_URL } from './constants';

// https://developers.google.com/webmaster-tools/search-console-api-original/v3/searchanalytics/query
const fetchSearchConsoleAnalytics = (accessToken, siteUrl, websiteUrl) => {
  const url = new URL(
    `${GOOGLE_API_ROOT_URL}/webmasters/v3/sites/${encodeURIComponent(
      siteUrl
    )}/searchAnalytics/query`
  );

  const date = DateTime.now().toFormat('yyyy-MM-dd');

  return fetch(url, {
    body: JSON.stringify({
      dataState: 'all',
      dimensionFilterGroups: [
        {
          filters: [
            {
              dimension: 'page',
              expression: websiteUrl,
              operator: 'contains',
            },
          ],
        },
      ],
      dimensions: ['page'],
      endDate: date,
      rowLimit: 25000,
      startDate: date,
    }),
    headers: new Headers({
      authorization: `Bearer ${accessToken}`,
    }),
    method: 'POST',
  })
    .then((response) => response.json())
    .then((data) => ({ ...data, siteUrl }));
};

export default fetchSearchConsoleAnalytics;
