import React from 'react';

import SvgWrapper from './SvgWrapper';

const BarChartIcon = (props) => (
  <SvgWrapper {...props}>
    <path d="M17 16v1h-17v-1h17zM2 10h2v5h1v-6h-4v6h1v-5zM7 7h2v8h1v-9h-4v9h1v-8zM12 3h2v12h1v-13h-4v13h1v-12z" />
  </SvgWrapper>
);

export default BarChartIcon;
