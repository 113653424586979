import './UsersSettings.scss';

import Switch from '@material-ui/core/Switch';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { LimitModal } from 'semji-core/components/LimitModal';

import UnlockImage from '@/assets/images/unlock-image.svg';
import { FlexRowContainer } from '@/components/_common';
import { PlanRestrictionBadge } from '@/components/Badge/PlanRestrictionBadge';
import { AlertDialog } from '@/components/Dialog/AlertDialog';
import InviteUsers from '@/components/Dialog/InviteUsers';
import WarningAvailableSeatsLeft from '@/components/FlashMessage/WarningAvailableSeatsLeft';
import MoreMenu from '@/components/MoreMenu/MoreMenu';
import { TabBodyTitle } from '@/components/Navigation/Tabs';
import AccessSelect from '@/components/Select/AccessSelect';
import UserSelect from '@/components/Select/UserSelect';
import Table from '@/components/Table/Table';
import Flex from '@/design-system/components/Flex';
import useOrganizationFeatureSet, {
  COMPETITORS__IS_ENABLED,
} from '@/hooks/useOrganizationFeatureSet';
import { showUpgradePlanModal } from '@/store/actions/billing';
import { showErrorSnackbar, showSuccessSnackbar } from '@/store/actions/ui';
import { SUBSCRIPTION_STATUS } from '@/utils/billing';
import { SECTIONS } from '@/utils/log/constants';
import { Log } from '@/utils/log/Log';

import {
  CustomTableHeaderSpan,
  InfoBadge,
  StyledFlexJustifiedSpaceBetweenContainer,
  StyledMenuItem,
  StyledMoreHoriz,
  StyledPrimaryButton,
} from './UserSettings.styled';

const currentAccessInitialState = {
  access: {},
  error: '',
  loading: false,
  type: null,
};
function UsersSettings({
  setUserRole,
  handleResetPassword,
  sendInvites,
  accessName,
  accesses,
  organizationId,
  organizationName,
  deleteUserAccess,
  currentUser,
  roles,
  keyName,
  alreadyInvitedUsers,
  availableUsers,
  emailDomain,
  availableWorkspaces,
  title,
  withWebextensionSettings,
  withAiSuiteSettings,
  withSearchIntelligenceSettings,
  setUserAiSuiteAccess,
  setUserSearchIntelligenceAccess,
  setUserWebextensionAccess,
  organizationAccesses,
  deleteOrganizationUserAccess,
}) {
  const { t } = useTranslation();
  const { data: webextensionMaxAmount = 0 } = useOrganizationFeatureSet(
    'users:invitations:webextension:allowed-amount'
  );
  const { isFeatureEnabled: unlimitedWebextensionFeatureSet } = useOrganizationFeatureSet(
    'users:invitations:webextension:has-unlimited-amount'
  );
  const { isFeatureEnabled: isSearchIntelligenceEnabled } =
    useOrganizationFeatureSet(COMPETITORS__IS_ENABLED);
  const { isFeatureEnabled: isAccessToAiSuiteManagementEnabled } = useOrganizationFeatureSet(
    'ai-writing:user-access-management:is-enabled'
  );

  const {
    isFeatureEnabled: isFeatureWebExtensionEnabled,
    addOn: { isAddOn: isFeatureWebExtensionAddOn },
  } = useOrganizationFeatureSet('web-extension:is-enabled');

  const webExtensionDisabled = isFeatureWebExtensionEnabled !== true;

  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [inviting, setInviting] = useState(false);
  const [currentAccess, setCurrentAccess] = useState(currentAccessInitialState);
  const [alertTitle, setAlertTitle] = useState('');
  const [isLimitModalOpen, setIsLimitModalOpen] = useState(false);
  const { subscriptionStatus } = useSelector((state) => state.organizations.byId[organizationId]);
  const dispatch = useDispatch();

  const countWebextension = accesses.filter((access) => access.accessToWebExtension).length;

  async function onConfirm() {
    try {
      setCurrentAccess({
        ...currentAccess,
        error: '',
        loading: true,
      });
      await deleteUserAccess(currentAccess.access?.id);
      setCurrentAccess(currentAccessInitialState);
    } catch (error) {
      Log.report({
        context: 'onConfirm',
        error,
        extra: 'Confirm set access',
        section: SECTIONS.settings.key,
      });
      setCurrentAccess({
        ...currentAccess,
        error: error,
        loading: false,
      });
    }
  }

  async function onConfirmDeleteOrganization() {
    const organizationAccess = organizationAccesses.find(
      (org) => org.user?.id === currentAccess.access?.user?.id
    );

    try {
      setCurrentAccess({
        ...currentAccess,
        error: '',
        loading: true,
      });
      await deleteOrganizationUserAccess(organizationAccess.id, currentAccess.access.id);
      setCurrentAccess(currentAccessInitialState);
    } catch (error) {
      setCurrentAccess({
        ...currentAccess,
        error: error,
        loading: false,
      });
    }
  }

  function onCancel() {
    setCurrentAccess(currentAccessInitialState);
    setAlertTitle('');
  }

  async function handleSendInvites(emails, selectedWorkspaces) {
    try {
      const usersInvitedInterval = t('components:settings.users-settings.users-invited_interval', {
        count: emails.length,
        postProcess: 'interval',
      });

      let successMessage;
      successMessage = t('components:settings.users-settings.success-invited-to-workspace', {
        accessName,
        usersInvitedInterval,
      });

      if (selectedWorkspaces.length === 1) {
        successMessage = t(
          'components:settings.users-settings.success-invited-to-workspace-from-organization',
          {
            accessName,
            usersInvitedInterval,
            workspaceName: selectedWorkspaces[0].name,
          }
        );
      } else if (selectedWorkspaces.length > 1) {
        successMessage = t(
          'components:settings.users-settings.success-invited-to-multiple-workspace-from-organization',
          {
            accessName,
            usersInvitedInterval,
            workspaceCount: selectedWorkspaces.length,
          }
        );
      }

      setInviting(true);
      await sendInvites(emails, selectedWorkspaces);
      setOpenInviteModal(false);
      setInviting(false);
      dispatch(showSuccessSnackbar(successMessage));
      return Promise.resolve();
    } catch (e) {
      setInviting(false);
      return Promise.reject(e);
    }
  }

  async function handleSendInvite(email) {
    try {
      await sendInvites([email]);
      dispatch(
        showSuccessSnackbar(
          t('components:settings.users-settings.new-invitation-sent-to-email', {
            email,
          })
        )
      );
    } catch (error) {
      Log.report({
        context: 'handleSendInvite',
        error,
        extra: 'Send invite to user',
        section: SECTIONS.settings.key,
      });
      dispatch(
        showErrorSnackbar(
          t('components:settings.users-settings.error-cannot-send-invitation', {
            email,
          })
        )
      );
    }
  }

  async function _handleResetPassword(email) {
    if (email) {
      try {
        await handleResetPassword(email);
        dispatch(
          showSuccessSnackbar(
            t('components:settings.users-settings.email-password-has-been-reset-successfully', {
              email,
            })
          )
        );
      } catch (error) {
        Log.report({
          context: 'handleResetPassword',
          error,
          extra: 'Reset user password',
          section: SECTIONS.settings.key,
        });
        dispatch(showErrorSnackbar(t('components:settings.users-settings.error')));
      }
    } else {
      dispatch(showErrorSnackbar(t('components:settings.users-settings.error')));
    }
  }

  async function _setUserRole(accessesId, role) {
    try {
      await setUserRole(accessesId, role);
      dispatch(
        showSuccessSnackbar(
          t('components:settings.users-settings.the-new-role-has-been-set-successfully')
        )
      );
    } catch (error) {
      Log.report({
        context: 'setUserRole',
        error,
        extra: 'set User Role',
        section: SECTIONS.settings.key,
      });
      dispatch(showErrorSnackbar(t('components:settings.users-settings.error')));
    }
  }

  async function _setUserWebextensionAccess(organizationAccessesId, newValue) {
    if (
      !unlimitedWebextensionFeatureSet &&
      countWebextension >= webextensionMaxAmount &&
      newValue
    ) {
      setIsLimitModalOpen(true);
      return;
    }

    try {
      await setUserWebextensionAccess(organizationAccessesId, newValue);
      dispatch(
        showSuccessSnackbar(
          t('components:settings.users-settings.webextension-access-successful-message')
        )
      );
    } catch (error) {
      dispatch(showErrorSnackbar(t('components:settings.users-settings.error')));
    }
  }

  async function _setUserAiSuiteAccess(workspaceAccessesId, newValue) {
    try {
      await setUserAiSuiteAccess(workspaceAccessesId, newValue);
      dispatch(
        showSuccessSnackbar(
          t('components:settings.users-settings.ai-suite-access-successful-message')
        )
      );
    } catch (error) {
      dispatch(showErrorSnackbar(t('components:settings.users-settings.error')));
    }
  }

  async function _setUserSearchIntelligenceAccess(workspaceAccessesId, newValue) {
    try {
      await setUserSearchIntelligenceAccess(workspaceAccessesId, newValue);
      dispatch(
        showSuccessSnackbar(
          t('components:settings.users-settings.search-intelligence-access-successful-message')
        )
      );
    } catch (error) {
      dispatch(showErrorSnackbar(t('components:settings.users-settings.error')));
    }
  }

  function getColumns({ countWebextension }) {
    return [
      {
        cellDataGetter: ({ rowData }) => ({
          id: rowData.id,
          user: rowData.user,
        }),
        cellRenderer: ({ cellData }) => (
          <>
            <FlexRowContainer>
              <UserSelect
                disabled
                longVersion
                options={[cellData.user]}
                value={cellData.user.id}
                variant="column"
              />
            </FlexRowContainer>
            {!cellData.user.lastLoginAt && (
              <InfoBadge>{t('components:settings.users-settings.pending-invite')}</InfoBadge>
            )}
          </>
        ),
        dataKey: 'name',
        flexGrow: 10,
        height: 50,

        label: t('components:settings.users-settings.table-headers.name'),
        sortable: true,
        width: 150,
      },
      {
        cellDataGetter: ({ rowData }) => ({
          currentAccess: rowData,
          id: rowData.id,
          roles: rowData.roles,
          user: rowData.user,
        }),
        cellRenderer: ({ cellData }) => (
          <AccessSelect
            key={cellData.roles[0]}
            currentAccess={cellData.currentAccess}
            disabled={cellData.user.id === currentUser?.id}
            roles={roles}
            setUserRole={_setUserRole}
            userId={cellData.user.id}
            withNoPadding
          />
        ),
        dataKey: 'roles',
        flexGrow: 2,
        height: 50,

        label: t('components:settings.users-settings.table-headers.role'),
        sortable: false,
        width: 150,
      },
      withWebextensionSettings
        ? {
            cellDataGetter: ({ rowData }) => ({
              accessToWebExtension: rowData.accessToWebExtension,
              currentAccess: rowData,
            }),
            cellRenderer: ({ cellData }) => (
              <Switch
                checked={cellData.accessToWebExtension || false}
                classes={{
                  root: 'webextension-switch-root',
                }}
                disabled={webExtensionDisabled}
                edge="start"
                onChange={(e) =>
                  _setUserWebextensionAccess(cellData.currentAccess.id, e.target.checked)
                }
              />
            ),
            dataKey: 'accessToWebExtension',
            flexGrow: 1,
            headerCellRenderer: () => (
              <Flex alignItems="center" gap="2px">
                <CustomTableHeaderSpan>
                  {t('components:settings.users-settings.table-headers.webextension')}
                </CustomTableHeaderSpan>
                {webExtensionDisabled && (
                  <>
                    <PlanRestrictionBadge isAddOn={isFeatureWebExtensionAddOn} isCondensed />
                  </>
                )}
                {!webExtensionDisabled && !unlimitedWebextensionFeatureSet && (
                  <>
                    <CustomTableHeaderSpan lightWeight>
                      {t('components:settings.users-settings.table-headers.webextension-count', {
                        count: countWebextension,
                        maxCount: webextensionMaxAmount,
                      })}
                    </CustomTableHeaderSpan>
                  </>
                )}
              </Flex>
            ),

            height: 50,

            sortable: false,
            width: 150,
          }
        : {},
      withAiSuiteSettings
        ? {
            cellDataGetter: ({ rowData }) => ({
              accessToAiFeatures: rowData.accessToAiFeatures ?? true,
              currentAccess: rowData,
            }),
            cellRenderer: ({ cellData }) => (
              <Switch
                checked={cellData.accessToAiFeatures || false}
                classes={{
                  root: 'ai-writing-switch-root',
                }}
                disabled={!isAccessToAiSuiteManagementEnabled}
                edge="start"
                onChange={(e) => _setUserAiSuiteAccess(cellData.currentAccess.id, e.target.checked)}
              />
            ),
            dataKey: 'accessToAiFeatures',
            flexGrow: 1,
            headerCellRenderer: () => (
              <Flex alignItems="center" gap="2px">
                <CustomTableHeaderSpan>
                  {t('components:settings.users-settings.table-headers.ai-suite')}
                </CustomTableHeaderSpan>
                {!isAccessToAiSuiteManagementEnabled && (
                  <>
                    <PlanRestrictionBadge isCondensed />
                  </>
                )}
              </Flex>
            ),

            height: 50,

            sortable: false,
            width: 150,
          }
        : {},
      withSearchIntelligenceSettings && isSearchIntelligenceEnabled
        ? {
            cellDataGetter: ({ rowData }) => ({
              accessToSearchIntelligence: rowData.accessToSearchIntelligence,
              currentAccess: rowData,
            }),
            cellRenderer: ({ cellData }) => (
              <Switch
                checked={cellData.accessToSearchIntelligence || false}
                classes={{
                  root: 'ai-writing-switch-root',
                }}
                edge="start"
                onChange={(e) =>
                  _setUserSearchIntelligenceAccess(cellData.currentAccess.id, e.target.checked)
                }
              />
            ),
            dataKey: 'accessToSearchIntelligence',
            flexGrow: 1,
            headerCellRenderer: () => (
              <Flex alignItems="center" gap="2px">
                <CustomTableHeaderSpan>
                  {t('components:settings.users-settings.table-headers.search-intelligence')}
                </CustomTableHeaderSpan>
              </Flex>
            ),

            height: 50,

            sortable: false,
            width: 150,
          }
        : {},
      {
        cellDataGetter: ({ rowData }) => ({
          currentAccess: rowData,
          user: rowData.user,
        }),
        cellRenderer: ({ cellData }) =>
          !cellData.user.lastLoginAt ? (
            <MoreMenu
              disabled={cellData.user.id === currentUser?.id}
              triggerIcon={<StyledMoreHoriz disabled={cellData.user.id === currentUser?.id} />}
            >
              <StyledMenuItem
                value={t('components:settings.users-settings.resend-invite')}
                onClick={() => handleSendInvite(cellData.user.email)}
              />
              <StyledMenuItem
                color="secondary"
                value={t('components:settings.users-settings.cancel-invite')}
                onClick={() => {
                  setCurrentAccess({
                    access: cellData.currentAccess,
                    error: '',
                    loading: false,
                    type: keyName,
                  });
                  setAlertTitle(t('components:settings.users-settings.cancel-invite'));
                }}
              />
            </MoreMenu>
          ) : (
            <MoreMenu
              disabled={cellData.user.id === currentUser?.id}
              triggerIcon={<StyledMoreHoriz disabled={cellData.user.id === currentUser?.id} />}
            >
              <StyledMenuItem
                value={t('components:settings.users-settings.reset-password')}
                onClick={() => _handleResetPassword(cellData.user.email)}
              />
              <StyledMenuItem
                color="secondary"
                value={t(`components:settings.users-settings.remove-from-${keyName}`, {
                  accessName: accessName,
                })}
                onClick={() => {
                  setCurrentAccess({
                    access: cellData.currentAccess,
                    error: '',
                    loading: false,
                    type: keyName,
                  });
                  setAlertTitle(t('components:settings.users-settings.remove-user'));
                }}
              />
              {keyName === 'workspace' && (
                <StyledMenuItem
                  color="secondary"
                  value={t('components:settings.users-settings.remove-from-organization', {
                    accessName: organizationName,
                  })}
                  onClick={() => {
                    setCurrentAccess({
                      access: cellData.currentAccess,
                      error: '',
                      loading: false,
                      type: 'organization',
                    });
                    setAlertTitle(t('components:settings.users-settings.remove-user'));
                  }}
                />
              )}
            </MoreMenu>
          ),
        dataKey: 'moreMenu',
        height: 50,
        sortable: false,
        width: 50,
      },
    ];
  }

  const columns = getColumns({ countWebextension });
  return (
    <Flex flex={1} flexDirection="column">
      <StyledFlexJustifiedSpaceBetweenContainer>
        <TabBodyTitle noMargin>{title}</TabBodyTitle>
        <StyledPrimaryButton
          onClick={() =>
            SUBSCRIPTION_STATUS.cancelled_subscription === subscriptionStatus
              ? dispatch(showUpgradePlanModal())
              : setOpenInviteModal(true)
          }
        >
          {t('components:settings.users-settings.invite-users')}
        </StyledPrimaryButton>
      </StyledFlexJustifiedSpaceBetweenContainer>
      <WarningAvailableSeatsLeft organizationId={organizationId} />
      <Flex flex={1}>
        <Table columns={columns} rows={accesses} />
      </Flex>
      <AlertDialog
        confirmLabel={t('components:settings.users-settings.remove')}
        error={currentAccess.error}
        loading={currentAccess.loading}
        show={!!currentAccess.access.id}
        text={
          <span>
            <strong>
              {!!currentAccess.access.user?.firstName || !!currentAccess.access.user?.lastName
                ? `${currentAccess.access.user?.firstName || ''} ${
                    currentAccess.access.user?.lastName || ''
                  }`
                : currentAccess.access.user?.email ||
                  t('components:settings.users-settings.this-user')}
            </strong>
            <Trans
              components={{ bold: <strong /> }}
              i18nKey={'components:settings.users-settings.will-no-longer-be-able-to-access'}
              values={{
                access: t(`settings:category.${currentAccess.type}`),
                accessName:
                  currentAccess.type === 'organization' && keyName === 'workspace'
                    ? organizationName
                    : accessName,
              }}
            />
          </span>
        }
        title={alertTitle}
        onCancel={onCancel}
        onConfirm={
          currentAccess.type === 'organization' && keyName === 'workspace'
            ? onConfirmDeleteOrganization
            : onConfirm
        }
      />
      <InviteUsers
        accessName={accessName}
        alreadyInvitedUsers={alreadyInvitedUsers}
        availableUsers={availableUsers}
        availableWorkspaces={availableWorkspaces}
        emailDomain={emailDomain}
        handleCancel={() => setOpenInviteModal(false)}
        handleSendInvites={handleSendInvites}
        inviting={inviting}
        keyName={keyName}
        open={openInviteModal}
        organizationId={organizationId}
      />
      <LimitModal
        buttonLabel={t('components:dialog.dialog-information-template.button-text')}
        description={t('components:settings.users-settings.limitation-dialog.description')}
        illustration={<img alt="" src={UnlockImage} />}
        isOpen={isLimitModalOpen}
        title={t('components:settings.users-settings.limitation-dialog.title_interval', {
          count: webextensionMaxAmount,
          maxAmount: webextensionMaxAmount,
          postProcess: 'interval',
        })}
        onClose={() => setIsLimitModalOpen(false)}
      />
    </Flex>
  );
}

export default UsersSettings;
