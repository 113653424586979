import React from 'react';

import SvgWrapper from './SvgWrapper';

const ErrorAltIcon = (props) => (
  <SvgWrapper height="20px" viewBox="0 0 24 24" width="20px" {...props}>
    <path
      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"
      fill="rgba(255, 84, 81, 1)"
    ></path>
  </SvgWrapper>
);

export default ErrorAltIcon;
