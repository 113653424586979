import './Tooltip.scss';

import { capitalize } from 'lodash';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getRoundedEvolutionMetricObject } from 'semji-core/utils/numbers';

import { Point } from '@/components/Chart/Chart.types';
import { LegendItemIconPlain } from '@/components/Chart/LegendItem';
import { DiffPercentage } from '@/components/Metrics/DiffPercentage';
import { ReportMetricKey, ReportMetricType } from '@/containers/Report/utils/types';
import { comparisonPeriods } from '@/utils/metrics/constants';

export function SparklineTooltip({
  minWidth,
  points,
  title,
  subTitle,
  periodicity,
  withSpacing,
}: {
  minWidth: string;
  points: Point[];
  title: string;
  subTitle: string;
  periodicity?: boolean;
  withSpacing?: boolean;
}) {
  const { t } = useTranslation();

  const metricLabelTitle = t('report:metric-label.title_interval', {
    count: points[0].value,
    name: points[0].period,
    postProcess: 'interval',
  });

  // because it is rendered as ReactDomServer Static Markup, this component has no access to the theme
  return (
    <div
      className={`highchart-charts-tooltip--sparkline ${withSpacing && 'justify-between'}`}
      style={{ minWidth }}
    >
      <span className="highchart-charts-tooltip--sparkline__period">{title}</span>
      <div className="highchart-charts-tooltip--sparkline__value">
        <span className="highchart-charts-tooltip--sparkline__number">
          {points[0].value + points[0].suffix}
        </span>
        {periodicity && (
          <span className="highchart-charts-tooltip--sparkline__title">{metricLabelTitle}</span>
        )}
        <span className="highchart-charts-tooltip--sparkline__subtitle">{subTitle}</span>
      </div>
      {points.slice(1, points.length).map((point) => (
        <span key={point.value} className="highchart-charts-tooltip--sparkline__point">
          {t('components:chart.tooltip.sparkline-tooltip-text_interval', {
            count: point.value,
            postProcess: 'interval',
          })}
        </span>
      ))}
    </div>
  );
}

export function StackedTooltip({
  points,
  previousPoints,
  type,
  metricKey,
}: {
  points: Point[];
  previousPoints: { x: number; y: number; realX: number }[];
  type: ReportMetricType;
  metricKey: ReportMetricKey;
}) {
  const { t } = useTranslation();
  // because it is rendered as ReactDomServer Static Markup, this component has no access to the theme

  function getTotalValue(points: Point[]) {
    return points.reduce((acc, point) => acc + parseFloat(point.value), 0);
  }

  function getTitle() {
    if (previousPoints[0]?.realX) {
      const comparativeDate = DateTime.fromMillis(previousPoints[0].realX);
      const comparativePeriod = comparativeDate.toFormat('LLL yyyy');

      return `${capitalize(points[0].period)} vs ${capitalize(comparativePeriod)}`;
    }
    return points[0].period;
  }

  return (
    <div className="highchart-charts-tooltip">
      <div className="highchart-charts-tooltip__period">{getTitle()}</div>
      <div className="highchart-charts-tooltip__list">
        {points.map((point, index) => (
          <div key={point.value} className="highchart-charts-tooltip__point">
            <div className="highchart-charts-tooltip__point__label">
              <LegendItemIconPlain color={point.color} />
              <span>{point.metricTitle}</span>
            </div>
            <div className="highchart-charts-tooltip__point__value">
              <span>{point.value + point.suffix}</span>
              {previousPoints.length > 0 && (
                <PointDiffComponent
                  index={index}
                  isStacked
                  metricKey={metricKey}
                  points={points}
                  previousPoints={previousPoints.map((point) => ({
                    realValue: point.y,
                  }))}
                  type={type}
                />
              )}
            </div>
          </div>
        ))}

        <div className="highchart-charts-tooltip__point highchart-charts-tooltip__point--total">
          <div className="highchart-charts-tooltip__point__label">
            <span>{t('chart:tooltip.total')}</span>
          </div>
          <div className="highchart-charts-tooltip__point__value">
            <span>{getTotalValue(points) + points[0].suffix}</span>
            {previousPoints.length > 0 && (
              <PointDiffComponent
                index={-1}
                isStacked
                metricKey={metricKey}
                points={points}
                previousPoints={previousPoints.map((point) => ({
                  realValue: point.y,
                }))}
                type={type}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function PointDiffComponent({
  points,
  previousPoints,
  index,
  isStacked,
  type,
  metricKey,
}: {
  points: Point[];
  previousPoints?: Point[];
  index: number;
  isStacked?: boolean;
  type: ReportMetricType;
  metricKey: ReportMetricKey;
}) {
  const { t } = useTranslation();
  const pointValue =
    index >= 0
      ? points[index].realValue
      : points.reduce((acc, point) => acc + parseFloat(point.realValue), 0);
  const previousPointValue =
    index >= 0
      ? previousPoints?.[index].realValue
      : previousPoints?.reduce((acc, point) => acc + parseFloat(point.realValue), 0);
  const restValue =
    points.reduce((acc, point) => acc + parseFloat(point.realValue), 0) - pointValue;

  function getPercentDiff() {
    const compareValue = isStacked ? previousPointValue : restValue;

    if (type === ReportMetricType.Percent) {
      return pointValue - compareValue;
    }

    if (compareValue === 0 && pointValue !== 0) {
      return Infinity;
    }

    if (compareValue === 0 || isNaN(compareValue)) {
      return null;
    }

    return ((pointValue - compareValue) * 100) / compareValue;
  }

  function getSuffix(value: number) {
    if (ReportMetricKey.publicationsCount === metricKey) {
      return '';
    }
    if (type === ReportMetricType.Percent) {
      return t('report:chart.title.point_interval', {
        count: Math.abs(value),
        postProcess: 'interval',
      });
    }
    return `%`;
  }

  if ((!pointValue || !previousPointValue) && !getPercentDiff()) {
    return null;
  }

  if (points.length === 2) {
    const percentDiff = getPercentDiff();
    const percentDiffRounded = getRoundedEvolutionMetricObject({
      number: percentDiff,
    });

    return (
      <DiffPercentage
        evolutionRating={points[0].evolutionRating}
        initialValue={percentDiff === null ? null : percentDiffRounded.initialValue}
        suffix={`${percentDiffRounded.suffix}${getSuffix(percentDiffRounded.value)}`}
        value={percentDiff === null ? null : percentDiffRounded.value}
      />
    );
  }

  return null;
}

function Tooltip({
  points,
  comparisonPeriod,
  type = ReportMetricType.Number,
  metricKey,
}: {
  points: Point[];
  comparisonPeriod?: string;
  type: ReportMetricType;
  metricKey: ReportMetricKey;
}) {
  function getName(index: number) {
    if (index === 0) {
      return t(points[0].label);
    }
    return t(comparisonPeriods[comparisonPeriod].label);
  }

  function getTitle() {
    if (points[1]?.period) {
      return `${capitalize(points[0].period)} vs ${capitalize(points[1]?.period)}`;
    }
    return points[0].period;
  }

  const { t } = useTranslation();
  // because it is rendered as ReactDomServer Static Markup, this component has no access to the theme
  return (
    <div className="highchart-charts-tooltip">
      <div className="highchart-charts-tooltip__period">{getTitle()}</div>
      <div className="highchart-charts-tooltip__list">
        {points.map((point, index) => (
          <div key={point.value} className="highchart-charts-tooltip__point">
            <div className="highchart-charts-tooltip__point__label">
              <LegendItemIconPlain color={point.color} />
              <span>{getName(index)}</span>
            </div>
            <div className="highchart-charts-tooltip__point__value">
              <span>{point.value === null ? '-' : point.value + point.suffix}</span>
              {index === 0 && (
                <PointDiffComponent
                  index={index}
                  metricKey={metricKey}
                  points={points}
                  type={type}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Tooltip;
