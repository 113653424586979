import styled from 'styled-components/macro';

import CheckboxOff from '@/components/icons/CheckboxOffIcon';
import CheckboxOn from '@/components/icons/CheckboxOnIcon';
import Close from '@/components/icons/CloseIcon';
import TooltipComponent from '@/components/Tooltip/Tooltip';

const CloseIcon = styled(Close)`
  && {
    font-size: ${(props) => props.theme.icon.sizes.xl};
    color: ${(props) => props.theme.icon.colors.default};
  }
`;

const CheckboxOffIcon = styled(CheckboxOff)`
  && {
    font-size: ${(props) => props.theme.textCss.sizes.xl};
  }
`;

const CheckboxOnIcon = styled(CheckboxOn)`
  && {
    font-size: ${(props) => props.theme.textCss.sizes.xl};
  }
`;

const Container = styled.div`
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 3px 3px 0px 0px;
  background-color: ${({ isChecked, theme }) => (isChecked ? theme.cssColors.primary005 : 'white')};
  box-sizing: border-box;
  width: 100%;
  height: 80px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-top: 4px solid transparent;
  border-bottom: ${({ isChecked, theme }) =>
    isChecked ? `4px solid ${theme.cssColors.primary}` : '4px solid transparent'};
  cursor: pointer;
  overflow: hidden;

  ${CheckboxOffIcon} {
    opacity: 0;
  }

  ${CloseIcon} {
    display: none;
  }

  &:hover {
    border-bottom: ${({ theme }) => `4px solid ${theme.cssColors.primary}`};

    ${CloseIcon}, ${CheckboxOffIcon} {
      display: block;
      opacity: 1;
    }

    ${CheckboxOnIcon} {
      display: ${({ isChecked }) => isChecked && 'none'};
    }
  }
`;

const Content = styled.div`
  height: 100%;
  width: calc(100% - 60px);
  flex: 1;
  display: flex;
  align-items: center;
  gap: 20px;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
`;

function ServiceIntegrationCheckbox({
  children,
  isChecked,
  onDelete,
  deleteLabel,
  onClick,
  service,
  ...props
}) {
  return (
    <Container {...props} isChecked={isChecked} onClick={isChecked ? onDelete : onClick}>
      <Content>{children}</Content>
      <Actions>
        {isChecked ? (
          <>
            <TooltipComponent title={deleteLabel}>
              <CloseIcon />
            </TooltipComponent>
            <CheckboxOnIcon />
          </>
        ) : (
          <CheckboxOffIcon />
        )}
      </Actions>
    </Container>
  );
}

export default ServiceIntegrationCheckbox;
