import { MatchResult } from '../domMatcher.types';
import { INLINE_NODE_NAMES, SPACE_CHARACTER_LENGTH } from './constantes';

export class DOMMatcherUtils {
  private static normalizeText({
    text,
    caseSenstivity,
    trailingSpace,
  }: {
    text: string;
    caseSenstivity: boolean;
    trailingSpace: boolean;
  }): string {
    let normalizedText = text;

    if (false === caseSenstivity) {
      normalizedText = text.toLowerCase();
    }

    if (false === trailingSpace) {
      normalizedText = normalizedText.trim();
    }

    return normalizedText;
  }

  private static tokenizeText({ text }: { text: string }): string[] {
    // Split the text into words, a word is a sequence of non-whitespace characters
    return text.split(/\s+/);
  }

  static calculateRangeOffset(
    parentNode: HTMLElement,
    offset: number
  ): { node: Node; offset: number } {
    let calculatedOffset = offset;
    const textNodes: Set<Node> = DOMMatcherUtils.getTextNodes(parentNode);

    for (const node of textNodes) {
      const nodeLength = node.textContent?.length ?? 0;

      if (calculatedOffset <= nodeLength) {
        return { node, offset: calculatedOffset };
      }

      calculatedOffset -= nodeLength;
    }

    return { node: parentNode, offset: calculatedOffset };
  }

  static createElementTreeWalker(node: HTMLElement | null): TreeWalker | null {
    if (!node) {
      return null;
    }

    const treeWalker = document.createTreeWalker(node, NodeFilter.SHOW_TEXT, {
      acceptNode: (node: Text) => {
        // Logic to remove new line nodes
        // do not remove all whitespaces as it will break the text selection
        if (!/^\n*$/.test(node.data)) {
          return NodeFilter.FILTER_ACCEPT;
        }

        return NodeFilter.FILTER_REJECT;
      },
    });

    return treeWalker;
  }

  static getParentNodesOfTextNodes(node: HTMLElement | null): Set<Node> {
    let allTextNodes = new Set<Node>();
    const treeWalker = DOMMatcherUtils.createElementTreeWalker(node);

    if (null === treeWalker) {
      return allTextNodes;
    }

    let currentNode: Node | null;
    while ((currentNode = treeWalker.nextNode())) {
      let parent = currentNode.parentNode;

      while (!!parent && DOMMatcherUtils.isInlineNode(parent)) {
        parent = parent?.parentNode ?? null;
      }

      allTextNodes.add(parent ?? currentNode);
    }

    return allTextNodes;
  }

  static getTextNodes(node: HTMLElement | null): Set<Node> {
    let allTextNodes = new Set<Node>();
    const treeWalker = DOMMatcherUtils.createElementTreeWalker(node);

    if (null === treeWalker) {
      return allTextNodes;
    }

    let currentNode: Node | null;
    while ((currentNode = treeWalker.nextNode())) {
      allTextNodes.add(currentNode);
    }

    return allTextNodes;
  }

  static getTokenizedNormalizedWords({
    text,
    caseSenstivity,
    trailingSpace,
  }: {
    text: string;
    caseSenstivity: boolean;
    trailingSpace: boolean;
  }): string[] {
    const normalizedTextToMatch = DOMMatcherUtils.normalizeText({
      caseSenstivity,
      text,
      trailingSpace,
    });

    return DOMMatcherUtils.tokenizeText({ text: normalizedTextToMatch });
  }

  static isInlineNode(node: Node | null): boolean {
    return node !== null && INLINE_NODE_NAMES.has(node.nodeName);
  }

  static isSameWord(word1: string, word2: string, includePunctuation?: boolean): boolean {
    if (true === includePunctuation) {
      return word1 === word2;
    }

    return DOMMatcherUtils.stripPunctuation(word1) === DOMMatcherUtils.stripPunctuation(word2);
  }

  static matchTextAcrossNodes({
    blockNodes,
    textToMatch,
    caseSenstivity,
    includePunctuation,
  }: {
    blockNodes: Set<Node>;
    textToMatch: string;
    caseSenstivity: boolean;
    includePunctuation: boolean;
  }): MatchResult | null {
    let currentWordIndex: number = 0;
    let startOffset: number = 0;
    let endOffset: number = 0;
    let matchingNodes: Set<Node> = new Set();
    let previousWordLength: number = 0;

    const normalizedWordsToMatch = DOMMatcherUtils.getTokenizedNormalizedWords({
      caseSenstivity,
      text: textToMatch,
      trailingSpace: false,
    });

    for (const node of blockNodes) {
      // stop at the first match
      if (currentWordIndex >= normalizedWordsToMatch.length) {
        break;
      }

      // If we are at the beginning of the text, startOffset is 0
      if (currentWordIndex === 0) {
        startOffset = 0;
      }
      endOffset = 0;

      const normalizedWordsInNode = DOMMatcherUtils.getTokenizedNormalizedWords({
        caseSenstivity,
        text: node.textContent ?? '',
        trailingSpace: true,
      });

      for (const normalizedWord of normalizedWordsInNode) {
        if (
          DOMMatcherUtils.isSameWord(
            normalizedWord,
            normalizedWordsToMatch[currentWordIndex],
            includePunctuation
          )
        ) {
          endOffset += normalizedWord.length;
          previousWordLength = normalizedWord.length + SPACE_CHARACTER_LENGTH;

          matchingNodes.add(node);

          if (currentWordIndex === normalizedWordsToMatch.length - 1) {
            return { endOffset, nodes: [...matchingNodes], startOffset };
          }

          endOffset += SPACE_CHARACTER_LENGTH; // we need to add the space character only if it is not the last word
          currentWordIndex++;
        } else {
          // we add the previous word length to the start in case in the previous iteration we matched a word
          startOffset += normalizedWord.length + SPACE_CHARACTER_LENGTH + previousWordLength;
          endOffset += normalizedWord.length + SPACE_CHARACTER_LENGTH;
          currentWordIndex = 0;
          matchingNodes = new Set();
          previousWordLength = 0;
        }
      }
    }

    return null;
  }

  static stripPunctuation(text: string): string {
    // remove all punctuation except for underscores
    // maybe we need to consider other characters as well ? '-' ?
    return text.replace(/[^\w\s]/g, '');
  }
}
