import './Listing.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { FloatLoader } from 'semji-core/components/FloatLoader';
import { Select } from 'semji-core/components/Select';
import { SelectDropdownItem } from 'semji-core/components/Select';
import { Tooltip } from 'semji-core/components/Tooltip';
import { DeleteIcon } from 'semji-core/icons/DeleteIcon';

import {
  CURRENT_NB_PAGES,
  PREVIOUS_NB_PAGES,
} from '@/containers/Competitors/Configuration/Configuration.types';
import useCan from '@/hooks/useCan';
import { URL_TYPE_DOMAIN, URL_TYPE_SUBDOMAIN, URL_TYPE_SUBFOLDER } from '@/types/competitors';

import { CompetitorAutoComplete } from './AutoComplete';
import { ENUM_COMPETITOR_CHANGE_KEY } from './Listing.types';
import { ListingProps, OptionType } from './Listing.types';

export default function Listing({
  competitors,
  handleRemoveElement,
  handleChangeElement,
  competitorSuggestions,
  shouldFocusIndexZero = false,
  isCalculatingPagesNumber = false,
}: ListingProps) {
  const { t } = useTranslation();
  const canDisplayCompetitorWebsiteNumberOfPages = useCan({
    perform: 'can-display-competitor-website-number-of-pages',
  });

  const DOMAIN_OPTIONS: OptionType[] = [
    {
      subTitle: t('competitors:configuration.domain.subtitle'),
      title: t('competitors:configuration.domain.title'),
      value: URL_TYPE_DOMAIN,
    },
    {
      subTitle: t('competitors:configuration.subdomain.subtitle'),
      title: t('competitors:configuration.subdomain.title'),
      value: URL_TYPE_SUBDOMAIN,
    },
    {
      subTitle: t('competitors:configuration.folder.subtitle'),
      title: t('competitors:configuration.folder.title'),
      value: URL_TYPE_SUBFOLDER,
    },
  ];

  const DropdownItem = ({ optionValue, ...props }: any) => (
    <SelectDropdownItem {...props}>
      <span>{optionValue.title}</span>
      <span>{optionValue.subTitle}</span>
    </SelectDropdownItem>
  );

  function getSafeIndex(index: any): number {
    if (isNaN(index) || index < 0) {
      return 0;
    }
    return index;
  }

  return (
    <div className="competitors-configuration-listing">
      <div className="competitors-configuration-listing__container">
        {competitors.map((competitor, index) => (
          <div
            key={competitor.id}
            className={`competitors-configuration-listing__item`}
            id={competitor.id}
          >
            <CompetitorAutoComplete
              autoFocus={shouldFocusIndexZero && index === 0}
              competitor={competitor}
              competitorSuggestions={competitorSuggestions}
              index={index}
              onBlur={handleChangeElement(ENUM_COMPETITOR_CHANGE_KEY.Url, true)}
              onChange={handleChangeElement(ENUM_COMPETITOR_CHANGE_KEY.Url, false)}
            />
            <Select
              className="competitors-configuration-listing__item__select"
              defaultValueIndex={getSafeIndex(
                DOMAIN_OPTIONS.findIndex((option) => option.value === competitor.urlType)
              )}
              DropdownItem={DropdownItem}
              dropdownPlacement="bottom-start"
              getValue={(item: any) => item?.title}
              isFrame={false}
              offsetValue={0}
              options={DOMAIN_OPTIONS}
              variant="domain"
              width="232px"
              onChange={(value: any) =>
                handleChangeElement(ENUM_COMPETITOR_CHANGE_KEY.UrlType)(value.value, index)
              }
            />

            {canDisplayCompetitorWebsiteNumberOfPages && (
              <div className="competitors-configuration-listing__item__number-of-pages">
                {isCalculatingPagesNumber ? (
                  <FloatLoader />
                ) : (
                  <span>
                    {`${competitor?.[CURRENT_NB_PAGES] ?? '-'} ${t('competitors:configuration.pages')} / ${competitor?.[PREVIOUS_NB_PAGES] ?? '-'} ${t('competitors:configuration.pages')}`}
                  </span>
                )}
              </div>
            )}

            <Tooltip
              className={`competitors-configuration-listing__item__delete-icon ${
                competitor.url && 'competitors-configuration-listing__item__delete-icon--visible'
              }`}
              description={t('competitors:configuration.delete')}
              isFrame={false}
            >
              <DeleteIcon
                className="competitors-configuration-listing__item__icon"
                onClick={() => handleRemoveElement(index)}
              />
            </Tooltip>
          </div>
        ))}
      </div>
    </div>
  );
}
