import './EditorAlert.scss';

import React from 'react';
import { Alert } from 'semji-core/components/Alert';

import { EditorAlertProps } from '@/containers/Content/TinyMceComponents/Editor/EditorAlert/EditorAlert.types';

function EditorAlert({ variant, children }: EditorAlertProps): React.JSX.Element {
  return (
    <div className="editor-alert-container">
      <div className="editor-alert">
        <div className="editor-alert__wrapper">
          <Alert variant={variant}>
            <div className="editor-alert__content">{children}</div>
          </Alert>
        </div>
      </div>
    </div>
  );
}

export default EditorAlert;
