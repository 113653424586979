import { useQueryClient } from '@tanstack/react-query';
import uniq from 'lodash/uniq';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import { LOCATION, SCOPE_WORKSPACE } from '@/apis/semji/constants';
import usePostInviteUsersOnWorkspace from '@/apis/semji/users/usePostInviteUsersOnWorkspace';
import InviteUser from '@/assets/images/invite-user.svg';
import { InviteUsersInput } from '@/components/Dialog/InviteUsers';
import { StyledImg } from '@/containers/Onboarding/common';
import DescribeStepsComponents from '@/containers/Onboarding/DescribeSteps';
import PrimaryButton from '@/design-system/components/Button/PrimaryButton';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import { showErrorSnackbar, showSuccessSnackbar } from '@/store/actions/ui';
import { selectCurrentWorkspaceWebsiteUrl } from '@/store/selectors/selectCurrentWorkspaceWebsiteUrl';
import { selectOrganizationAvailableSeats } from '@/store/selectors/selectOrganizationAvailableSeats';
import { getEmailDomainFromUrl, isValidEmail } from '@/utils/email';

const Wrapper = styled.div`
  margin-top: 20px;
  input {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'text')};
  }
`;

function InviteYourTeam() {
  const dispatch = useDispatch();
  const i18nNameSpace = 'quick-start-guide:body.accordion.invite-your-team';
  const { t } = useTranslation();
  const { workspaceId } = useParams();

  const isWorkspaceOwner = useSelector((state) =>
    state.user.workspaceAccesses
      .find((workspaceAccess) => workspaceAccess.workspace.id === state.defaultWorkspaceId)
      .roles.includes('OWNER')
  );
  const { isFeatureEnabled } = useOrganizationFeatureSet('users:invitations:has-unlimited-amount');

  const [emails, setEmails] = useState([]);
  const [currentInputValue, setCurrentInputValue] = useState('');

  const alreadyInvitedUsers = useSelector(
    (state) =>
      state.user.workspaceAccesses.map((workspaceAccesses) => workspaceAccesses.user.email) || []
  );
  const availableUsers = useSelector((state) =>
    state.user.organizationAccesses
      .map((organizationAccesses) => organizationAccesses.user.email)
      .filter(
        (email) =>
          !state.user.workspaceAccesses.find(
            (workspaceAccesses) => workspaceAccesses.user.email === email
          )
      )
  );
  const websiteUrl = useSelector(selectCurrentWorkspaceWebsiteUrl);
  const availableSeats = useSelector(selectOrganizationAvailableSeats);
  const workspaceName = useSelector((state) => state.user.workspaceAccesses[0]?.workspace?.name);

  const queryClient = useQueryClient();

  const emailDomain = getEmailDomainFromUrl(websiteUrl);

  const inviteUsersOnWorkspace = usePostInviteUsersOnWorkspace({
    onError: () => {
      dispatch(showErrorSnackbar(t('common:error.default')));
    },
    onSuccess: () => {
      const usersInvitedInterval = t('components:settings.users-settings.users-invited_interval', {
        count: emails.length,
        postProcess: 'interval',
      });

      dispatch(
        showSuccessSnackbar(
          t('components:settings.users-settings.success-invited-to-workspace', {
            accessName: workspaceName,
            emailCount: getListOfCanBeInvitedUsers().length,
            usersInvitedInterval,
          })
        )
      );
      setEmails([]);
      setCurrentInputValue('');

      queryClient.refetchQueries({
        active: true,
        exact: true,
        fetching: false,
        queryKey: [SCOPE_WORKSPACE.GET_CURRENT_WORKSPACE, workspaceId, LOCATION.QUICK_START_GUIDE],
      });
    },
  });

  function getValidEmails() {
    return emails.filter((email) => isValidEmail(email) && !alreadyInvitedUsers.includes(email));
  }

  function onInputBlur() {
    if (currentInputValue) {
      setEmails(uniq([...emails, currentInputValue].map((email) => email.toLowerCase())));
      setCurrentInputValue('');
    }
  }

  function handleInputChange(event, value) {
    const splittedInputValue = value.split(/[,;\n\t ]+/);

    if (splittedInputValue.length > 1) {
      setEmails(
        uniq(
          [...emails, ...splittedInputValue.filter((val) => !!val)].map((email) =>
            email.toLowerCase()
          )
        )
      );
      setCurrentInputValue('');
    } else {
      setCurrentInputValue(value.toLowerCase());
    }
  }

  function getListOfCanBeInvitedUsers() {
    const validUsers = getValidEmails();
    const newUsers = validUsers.filter((validUser) => !availableUsers.includes(validUser));
    const alreadyUsers = validUsers.filter((validUser) => availableUsers.includes(validUser));

    if (isFeatureEnabled) {
      return [...newUsers, ...alreadyUsers];
    } else {
      return [...newUsers.slice(0, availableSeats), ...alreadyUsers];
    }
  }

  function canBeInvited(email) {
    return (
      getListOfCanBeInvitedUsers().findIndex((invitableUser) => invitableUser === email) !== -1
    );
  }

  function handleChange(event, values) {
    setEmails(uniq(values.map((email) => email.toLowerCase())));
  }

  function handleInviteUsers() {
    inviteUsersOnWorkspace.mutate(getListOfCanBeInvitedUsers().map((email) => ({ email })));
  }

  return (
    <DescribeStepsComponents
      button={
        <PrimaryButton
          disabled={
            !isWorkspaceOwner ||
            inviteUsersOnWorkspace.isLoading ||
            getListOfCanBeInvitedUsers().length === 0
          }
          margin="20px 0"
          noFlex
          weight="strong"
          onClick={handleInviteUsers}
        >
          {t(`${i18nNameSpace}.button-label`)}
        </PrimaryButton>
      }
      explanatoryText={<Trans i18nKey={`${i18nNameSpace}.explanatory-text`} />}
      render={
        <Wrapper disabled={!isWorkspaceOwner}>
          <InviteUsersInput
            alreadyInvitedUsers={alreadyInvitedUsers}
            availableUsers={availableUsers}
            canBeInvited={canBeInvited}
            emailDomain={emailDomain}
            emails={emails}
            handleChange={handleChange}
            handleInputChange={handleInputChange}
            inputValue={currentInputValue}
            isDisabled={!isWorkspaceOwner || inviteUsersOnWorkspace.isLoading}
            onInputBlur={onInputBlur}
          />
        </Wrapper>
      }
      visualRendering={<StyledImg alt={t(`${i18nNameSpace}.alt-image`)} src={InviteUser} />}
    />
  );
}

export default InviteYourTeam;
