import { computeLocationLabel, getFullNameFromUser, getInitialsFromUser } from '@/utils/user';

function WorkspaceIllustration({
  countryCode,
  isObvious,
  languageCode,
  organizationName,
  remainingAnalysisUnit,
  user = {},
  workspaceName,
  ...props
}) {
  const locationLabel = computeLocationLabel({ countryCode, isObvious, languageCode });

  return (
    <div {...props}>
      <svg
        fill="none"
        height="514"
        viewBox="0 0 424 514"
        width="424"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M423.001 337.211V465.493L307.16 465.559L423.001 337.211Z"
          fill="#585965"
          opacity="0.1"
        />
        <path d="M423.001 370.002V514H293L423.001 370.002Z" fill="url(#workspace_paint0_linear)" />
        <g filter="url(#filter0_d)">
          <path
            d="M387.291 481.979H13.669C11.5899 481.979 10 480.463 10 478.48V10.4994C10 8.51639 11.5899 7 13.669 7H387.291C389.37 7 390.96 8.51639 390.96 10.4994V478.597C390.96 480.463 389.37 481.979 387.291 481.979Z"
            fill="white"
          />
        </g>
        <path
          d="M391 97H10V10.2609C10 8.41304 11.59 7 13.6693 7H387.331C389.41 7 391 8.41304 391 10.2609V97Z"
          fill="url(#workspace_paint1_linear)"
          opacity="0.9"
        />
        <path d="M390.96 176.243H10V248.162H390.96V176.243Z" fill="#FF4D64" opacity="0.05" />
        <path
          d="M12 248.529V176"
          stroke="url(#workspace_paint0_linear)"
          strokeMiterlimit="10"
          strokeWidth="4.1439"
        />
        <path
          d="M10 176H390"
          opacity="0.4"
          stroke="#889CA8"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <ellipse cx="58" cy="212.5" fill="#E9E9EB" rx="17" ry="16.5" />
        <circle cx="58" cy="280" fill="#E9E9EB" r="17" />
        <ellipse cx="58" cy="346.5" fill="#E9E9EB" rx="17" ry="16.5" />
        <ellipse cx="58" cy="414.5" fill="#E9E9EB" rx="17" ry="16.5" />
        {organizationName ? (
          <text
            fill="#7C7D86"
            fontFamily="Rubik"
            fontSize="20px"
            height="12"
            rx="6"
            width="119"
            x="45"
            y="131"
          >
            {organizationName.substring(0, 30)}
          </text>
        ) : (
          <rect fill="#E9E9EB" height="12" rx="6" width="119" x="45" y="121" />
        )}
        {!isNaN(remainingAnalysisUnit) ? (
          <text
            fill="#D3D4D7"
            fontFamily="Rubik"
            fontSize="12px"
            height="9"
            rx="4.5"
            width="91"
            x="45"
            y="155"
          >
            {`${remainingAnalysisUnit} Credits`}
          </text>
        ) : (
          <rect fill="#F4F4F5" height="9" rx="4.5" width="91" x="45" y="145" />
        )}
        <rect fill="#E9E9EB" height="11" rx="5.5" width="134" x="95" y="275" />
        {workspaceName ? (
          <text fill="#7C7D86" fontFamily="Rubik" height="11" rx="5.5" width="172" x="95" y="217">
            {workspaceName.substring(0, 30)} {locationLabel}
          </text>
        ) : (
          <rect fill="#E9E9EB" height="11" rx="5.5" width="172" x="95" y="207" />
        )}
        <rect fill="#E9E9EB" height="10" rx="5" width="157" x="95" y="341" />
        <rect fill="#E9E9EB" height="10" rx="5" width="97" x="95" y="409" />
        {getFullNameFromUser(user) ? (
          <text fill="white" width="174" x="113" y="47">
            {getFullNameFromUser(user).substring(0, 30)}
          </text>
        ) : (
          <rect fill="white" height="15" opacity="0.3" rx="7.5" width="174" x="113" y="34" />
        )}
        {user.email ? (
          <text fill="#D3D4D7" width="89" x="113" y="70">
            {user.email.substring(0, 30)}
          </text>
        ) : (
          <rect fill="white" height="10" opacity="0.2" rx="5" width="89" x="113" y="62" />
        )}
        <circle cx="67.389" cy="52.178" fill="#95C8FD" r="23.1995" />
        <text fill="white" x="57" y="57">
          {getInitialsFromUser(user) || 'NJ'}
        </text>
        <defs>
          <filter
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
            height="494.979"
            id="filter0_d"
            width="400.96"
            x="0"
            y="0"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dy="3" />
            <feGaussianBlur stdDeviation="5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.145098 0 0 0 0 0.152941 0 0 0 0 0.211765 0 0 0 0.1 0"
            />
            <feBlend in2="BackgroundImageFix" mode="normal" result="effect1_dropShadow" />
            <feBlend in="SourceGraphic" in2="effect1_dropShadow" mode="normal" result="shape" />
          </filter>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="workspace_paint0_linear"
            x1="371.391"
            x2="227.349"
            y1="1341.85"
            y2="1341.85"
          >
            <stop stopColor="#FF4580" />
            <stop offset="1" stopColor="#FF4D64" />
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="workspace_paint1_linear"
            x1="-199.162"
            x2="538.373"
            y1="51.9912"
            y2="51.9912"
          >
            <stop stopColor="#00BEF1" />
            <stop offset="0.1311" stopColor="#14A9F4" />
            <stop offset="0.3754" stopColor="#3587F9" />
            <stop offset="0.6081" stopColor="#4C6FFC" />
            <stop offset="0.8221" stopColor="#5A60FE" />
            <stop offset="1" stopColor="#5F5BFF" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}

export default WorkspaceIllustration;
