import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { MutableRefObject, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { getPostStreamParameters } from '@/apis/semji/aiWriting/useStreamModel/const';
import { postStreamModel } from '@/apis/semji/aiWriting/useStreamModel/postStreamModel';
import {
  PostStreamParameters,
  UseStreamModelParameters,
} from '@/apis/semji/aiWriting/useStreamModel/useStreamModel.types';
import { showErrorSnackbar } from '@/store/actions/ui';

export default function useStreamModel(props: any) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const abortControllerRef: MutableRefObject<AbortController | undefined> = useRef<
    AbortController | undefined
  >();

  const mutation: UseMutationResult<any> & { abort?: () => void } = useMutation({
    mutationFn: async (mutationProps: UseStreamModelParameters) => {
      abortControllerRef.current = new AbortController();

      const postStreamParameters: PostStreamParameters = getPostStreamParameters(mutationProps);

      return await postStreamModel(postStreamParameters, {
        signal: abortControllerRef.current?.signal,
      });
    },
    onError: (e: any) => {
      dispatch(showErrorSnackbar(t('common:error.default')));
    },
    ...props,
  });

  const abort = useCallback(() => {
    abortControllerRef.current?.abort();
  }, [mutation.abort]);

  return {
    ...mutation,
    abort,
  };
}
