const AlertAltIcon = (props) => {
  return (
    <svg fill="none" height="20" viewBox="0 0 20 20" width="20" {...props}>
      <g clipPath="url(#clip0_14464_301493)">
        <mask
          height="18"
          id="mask0_14464_301493"
          maskUnits="userSpaceOnUse"
          style={{ maskType: 'alpha' }}
          width="18"
          x="0"
          y="0"
        >
          <mask fill="white" id="path-1-inside-1_14464_301493">
            <path d="M8.5 17.0106C13.1944 17.0106 17 13.2027 17 8.50531C17 3.80796 13.1944 0 8.5 0C3.80558 0 0 3.80796 0 8.50531C0 13.2027 3.80558 17.0106 8.5 17.0106Z" />
          </mask>
          <path
            d="M8.5 17.0106C13.1944 17.0106 17 13.2027 17 8.50531C17 3.80796 13.1944 0 8.5 0C3.80558 0 0 3.80796 0 8.50531C0 13.2027 3.80558 17.0106 8.5 17.0106Z"
            mask="url(#path-1-inside-1_14464_301493)"
            stroke="#FF4D64"
            strokeWidth="1.89007"
          />
          <mask fill="white" id="path-2-inside-2_14464_301493">
            <path d="M8.34001 10.8028C8.25066 10.8028 8.17471 10.776 8.11217 10.7224C8.05856 10.6598 8.03175 10.5839 8.03175 10.4945V3.92739C8.03175 3.82911 8.05856 3.75316 8.11217 3.69955C8.17471 3.64595 8.25066 3.61914 8.34001 3.61914H8.9163C9.00565 3.61914 9.07713 3.65041 9.13074 3.71296C9.19329 3.76657 9.22456 3.83804 9.22456 3.92739V10.4945C9.22456 10.5839 9.19329 10.6598 9.13074 10.7224C9.07713 10.776 9.00565 10.8028 8.9163 10.8028H8.34001ZM8.21939 13.0007C8.13004 13.0007 8.05409 12.9739 7.99155 12.9203C7.93794 12.8578 7.91113 12.7818 7.91113 12.6925V11.875C7.91113 11.7856 7.93794 11.7141 7.99155 11.6605C8.05409 11.598 8.13004 11.5667 8.21939 11.5667H9.02352C9.11287 11.5667 9.18882 11.598 9.25136 11.6605C9.31391 11.7141 9.34518 11.7856 9.34518 11.875V12.6925C9.34518 12.7818 9.31391 12.8578 9.25136 12.9203C9.18882 12.9739 9.11287 13.0007 9.02352 13.0007H8.21939Z" />
          </mask>
          <path
            d="M8.34001 10.8028C8.25066 10.8028 8.17471 10.776 8.11217 10.7224C8.05856 10.6598 8.03175 10.5839 8.03175 10.4945V3.92739C8.03175 3.82911 8.05856 3.75316 8.11217 3.69955C8.17471 3.64595 8.25066 3.61914 8.34001 3.61914H8.9163C9.00565 3.61914 9.07713 3.65041 9.13074 3.71296C9.19329 3.76657 9.22456 3.83804 9.22456 3.92739V10.4945C9.22456 10.5839 9.19329 10.6598 9.13074 10.7224C9.07713 10.776 9.00565 10.8028 8.9163 10.8028H8.34001ZM8.21939 13.0007C8.13004 13.0007 8.05409 12.9739 7.99155 12.9203C7.93794 12.8578 7.91113 12.7818 7.91113 12.6925V11.875C7.91113 11.7856 7.93794 11.7141 7.99155 11.6605C8.05409 11.598 8.13004 11.5667 8.21939 11.5667H9.02352C9.11287 11.5667 9.18882 11.598 9.25136 11.6605C9.31391 11.7141 9.34518 11.7856 9.34518 11.875V12.6925C9.34518 12.7818 9.31391 12.8578 9.25136 12.9203C9.18882 12.9739 9.11287 13.0007 9.02352 13.0007H8.21939Z"
            fill="#FF4D64"
            mask="url(#path-2-inside-2_14464_301493)"
            stroke="#FF4D64"
            strokeWidth="1.89007"
          />
        </mask>
        <g mask="url(#mask0_14464_301493)">
          <rect fill="#FF4D64" height="20" width="20" x="-2" y="-1" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_14464_301493">
          <rect fill="white" height="20" width="20" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AlertAltIcon;
