import './PersonalInfo.scss';

import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Avatar } from 'semji-core/components/Avatar';
import styled from 'styled-components/macro';

import apiSettings from '@/apis/semji/settings';
import usePostUserAvatar from '@/apis/semji/user/usePostUserAvatar';
import { FlexContainer } from '@/components/_common';
import CircleIcon from '@/components/icons/CircleIcon';
import Input from '@/components/Input/Input';
import { TitleThree } from '@/components/Text/Title';
import { ConfirmAvatarDialog } from '@/containers/Settings/Profile/PersonalInfo/ConfirmAvatarDialog';
import { getCroppedImg } from '@/containers/Settings/Profile/PersonalInfo/imageUtils';
import { RemoveImageDialog } from '@/containers/Settings/Profile/PersonalInfo/RemoveImageDialog';
import Flex from '@/design-system/components/Flex';
import UserService from '@/services/User';
import { showErrorSnackbar, showSuccessSnackbar } from '@/store/actions/ui';
import {
  setProfileImageHash,
  setUploadedAvatarUrl,
  setUserFirstName,
  setUserLastName,
} from '@/store/actions/user';

const ONE_MEGABYTE = 1048576;
const AVATAR_MAX_SIZE = 10 * ONE_MEGABYTE; // 3MiB
const ACCEPTED_FILE_TYPES = ['image/png', 'image/jpeg', 'image/jpg'];
const IMAGE_QUALITY = 0.66;
const DEFAULT_SENT_AVATAR_NAME = 'avatar.jpeg';
const DEFAULT_SENT_AVATAR_EXTENSION = 'jpeg';

const LightText = styled.p`
  font-size: ${({ theme }) => theme.textCss.sizes.default};
  color: ${({ theme }) => theme.cssColors.dark040};
  line-height: ${({ theme }) => theme.textCss.lineHeight};
  margin-bottom: ${({ marginBottom }) => marginBottom};
`;

const ErrorText = styled.p`
  font-size: ${({ theme }) => theme.textCss.sizes.sm};
  color: ${({ theme }) => theme.text.colors.error};
`;

const RemoveButton = styled.label`
  color: ${({ theme }) => theme.cssColors.primary};
  background: none;
  font-size: ${({ theme }) => theme.textCss.sizes.default};
  border: none;
  cursor: pointer;
`;

const StyledImport = styled.label`
  cursor: pointer;
  font-size: ${({ theme }) => theme.textCss.sizes.default};
  color: ${({ theme }) => theme.cssColors.primary};
`;

function User({ user }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const _userService = new UserService();
  const [name, setName] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
  });
  const fileInputRef = useRef(null);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [croppedInfo, setCroppedInfo] = useState(null);

  const apiRootUrl = import.meta.env.VITE_REACT_APP_SEMJI_API_ROOT_URL;

  const onNameChange = (e, id) => {
    setName({
      ...name,
      [id]: e.target.value,
    });
  };

  async function saveFirstNameChange() {
    if (user.firstName !== name.firstName) {
      try {
        await _userService.setUserInfo(user.id, { firstName: name.firstName });
        dispatch(setUserFirstName(name.firstName));
        dispatch(
          showSuccessSnackbar(
            t('settings:workspace.personal-info-user.snackbar-first-name-change-success')
          )
        );
      } catch (e) {
        dispatch(
          showErrorSnackbar(
            t('settings:workspace.personal-info-user.snackbar-first-name-change-error')
          )
        );
      }
    }
  }

  async function saveLastNameChange() {
    if (user.lastName !== name.lastName) {
      try {
        await _userService.setUserInfo(user.id, { lastName: name.lastName });
        dispatch(setUserLastName(name.lastName));
        dispatch(
          showSuccessSnackbar(
            t('settings:workspace.personal-info-user.snackbar-last-name-change-success')
          )
        );
      } catch (e) {
        dispatch(
          showErrorSnackbar(
            t('settings:workspace.personal-info-user.snackbar-last-name-change-error')
          )
        );
      }
    }
  }

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedInfo(croppedAreaPixels);
  }, []);

  function onCancel() {
    fileInputRef.current.value = null;
    setErrorMessage(null);
    setAvatar(null);
    setIsConfirmDialogOpen(false);
  }

  const postUserAvatar = usePostUserAvatar({
    onError: () => {
      dispatch(showErrorSnackbar(t('settings:profile.snackbar-profile-picture-change-error')));
    },
    onSuccess: ({ data: updatedUser }) => {
      dispatch(setUploadedAvatarUrl(updatedUser.uploadedAvatarUrl));
      dispatch(showSuccessSnackbar(t('settings:profile.snackbar-profile-picture-change-success')));
    },
  });

  async function sendAvatarRequest(fullAvatarFile) {
    const formData = new FormData();
    formData.append('avatar', fullAvatarFile);
    postUserAvatar.mutate({ data: formData, userId: user.id });
  }

  async function handleConfirm() {
    if (avatar) {
      const canvas = await getCroppedImg(avatar, croppedInfo);
      canvas.toBlob(
        (blob) => {
          const fullAvatarFile = new File([blob], DEFAULT_SENT_AVATAR_NAME, {
            type: DEFAULT_SENT_AVATAR_EXTENSION,
          });
          sendAvatarRequest(fullAvatarFile);
        },
        'image/jpeg',
        IMAGE_QUALITY
      );
    }
    setIsConfirmDialogOpen(false);
    fileInputRef.current.value = null;
  }

  function handleAvatarUpload(e) {
    const file = e.target.files[0];
    if (file) {
      if (file.size > AVATAR_MAX_SIZE) {
        setErrorMessage(
          `${file.name} ${t('settings:profile.upload-error-size')} ${
            AVATAR_MAX_SIZE / ONE_MEGABYTE
          }MiB`
        );
        return;
      }
      if (!ACCEPTED_FILE_TYPES.includes(file.type)) {
        setErrorMessage(`${file.name}${t('settings:profile.upload-error-type')}`);
        return;
      }
      setAvatar(URL.createObjectURL(file));
      setErrorMessage(null);
      setIsConfirmDialogOpen(true);
    }
  }

  function handleRemoveProfilePicture() {
    setRemoveDialogOpen(true);
  }

  async function handleRemoveProfilePictureConfirm() {
    try {
      if (user.uploadedAvatarUrl) {
        await _userService.setUserInfo(user.id, { uploadedAvatarUrl: null });
        setAvatar(null);
      } else {
        await _userService.setUserInfo(user.id, { profileImageUrl: null });
        dispatch(setProfileImageHash(null));
      }
      dispatch(setUploadedAvatarUrl(null));
      dispatch(showSuccessSnackbar(t('settings:profile.snackbar-profile-picture-remove-success')));
    } catch (e) {
      dispatch(showErrorSnackbar(t('settings:profile.snackbar-profile-picture-remove-error')));
    }
    setRemoveDialogOpen(false);
  }

  function handleRemoveProfilePictureCancel() {
    setRemoveDialogOpen(false);
  }

  function handleFirstNameChange(e) {
    onNameChange(e, 'firstName');
  }

  function handleLastNameChange(e) {
    onNameChange(e, 'lastName');
  }

  return (
    <>
      <Flex className="personal-info-user" flexDirection="column" gap="8px">
        {isConfirmDialogOpen && (
          <ConfirmAvatarDialog
            handleConfirm={handleConfirm}
            image={avatar}
            onCancel={onCancel}
            onCropComplete={onCropComplete}
          />
        )}
        <TitleThree noMargin weight="strong">
          {t('settings:profile.profile-picture')}
        </TitleThree>
        <FlexContainer>
          <Avatar
            apiRootUrl={apiRootUrl}
            className="personal-info-user__avatar"
            email={user.email}
            firstName={user.firstName}
            id={user.id}
            lastName={user.lastName}
            profileImageHash={user.profileImageHash}
            size="75px"
            translations={{ unassigned: t('common:user-picker.unassigned') }}
            uploadedAvatarUrl={user.uploadedAvatarUrl}
          />
          <Flex flexDirection="column" gap="4px" paddingLeft="1rem">
            <Flex alignItems="center" gap="8px" width="max-content">
              <StyledImport htmlFor="avatar-upload">
                <input
                  ref={fileInputRef}
                  accept={ACCEPTED_FILE_TYPES.join(', ')}
                  hidden
                  id="avatar-upload"
                  type="file"
                  onChange={handleAvatarUpload}
                />
                {t('settings:profile.import-avatar')}
              </StyledImport>
              {(user.uploadedAvatarUrl || user.profileImageHash) && (
                <>
                  <CircleIcon innerFill="currentColor" opacity={0.4} radius={2} stroke="none" />
                  <RemoveButton onClick={handleRemoveProfilePicture}>
                    {t('settings:profile.remove-profile-picture')}
                  </RemoveButton>
                </>
              )}
            </Flex>
            {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
            <LightText>{t('settings:profile.import-avatar-description')}</LightText>
            {removeDialogOpen && (
              <RemoveImageDialog
                confirmLabel={t('settings:profile.remove-avatar-confirm')}
                handleCancel={handleRemoveProfilePictureCancel}
                handleConfirm={handleRemoveProfilePictureConfirm}
                imageUrl={
                  user.uploadedAvatarUrl ??
                  `${apiSettings.rootUrl}/profile_images/${user.profileImageHash}`
                }
                text={
                  <LightText marginBottom="20px">
                    {t('settings:profile.remove-profile-picture-text')}
                  </LightText>
                }
                title={t('settings:profile.remove-profile-picture-title')}
              />
            )}
          </Flex>
        </FlexContainer>
        <Flex flexDirection="column" gap="8px" paddingTop="1rem">
          <TitleThree noMargin weight="strong">
            {t('settings:workspace.personal-info-user.input-first-name-placeholder')}
          </TitleThree>
          <Input
            placeholder={t('settings:workspace.personal-info-user.input-first-name-placeholder')}
            value={name.firstName}
            width="100%"
            onBlur={saveFirstNameChange}
            onChange={handleFirstNameChange}
          />
        </Flex>
        <Flex flexDirection="column" gap="8px">
          <TitleThree noMargin weight="strong">
            {t('settings:workspace.personal-info-user.input-last-name-placeholder')}
          </TitleThree>
          <Input
            placeholder={t('settings:workspace.personal-info-user.input-last-name-placeholder')}
            value={name.lastName}
            width="100%"
            onBlur={saveLastNameChange}
            onChange={handleLastNameChange}
          />
        </Flex>
      </Flex>
    </>
  );
}

export default User;
