import { DateTime } from 'luxon';

import SearchIntelligenceEmptyStateEn from '@/assets/images/search-intelligence-empty-state-en.svg';
import SearchIntelligenceEmptyStateFr from '@/assets/images/search-intelligence-empty-state-fr.svg';
import { COMPETITOR_COLORS } from '@/containers/Competitors/utils/constants';
import {
  CompetitorReportMetric,
  CompetitorWebsite,
  CompetitorWebsiteUrlType,
} from '@/types/competitors';
import { generateColorBasedOnString } from '@/utils/helper';
import { formatUrl, getDomainFromUrl, getOriginFromUrl } from '@/utils/url';

export function getWebsiteReference(competitorWebsites: CompetitorWebsite[]) {
  return competitorWebsites.find((website) => true === website?.isSelfReference);
}

export function getHostnamefromUrl(url = '') {
  try {
    return new URL(formatUrl(url))?.hostname.replace(/https?:\/\//i, '').replace('www.', '');
  } catch (error) {
    return url;
  }
}

export function getCompetitorColor(url: string): { color: string; backgroundColor: string } | null {
  if (!url) {
    return null;
  }
  return generateColorBasedOnString(COMPETITOR_COLORS, getOriginFromUrl(url));
}

export function getCompetitorDomains(competitorWebsites: CompetitorWebsite[]) {
  const domains = [
    ...competitorWebsites.map((competitor) => ({
      color: getCompetitorColor(competitor.url)?.color,
      domain: getHostnamefromUrl(competitor.url),
      url: competitor.url,
    })),
  ];

  return domains;
}

export function getHighlightedUrl(competitor: CompetitorWebsite) {
  let textToHighlight = competitor.url;
  const url: string = competitor.url;
  if (competitor.urlType === CompetitorWebsiteUrlType.DOMAIN) {
    // highlight the domain
    textToHighlight = getDomainFromUrl(url);
  } else if (competitor.urlType === CompetitorWebsiteUrlType.SUBDOMAIN) {
    // highlight the subdomain
    textToHighlight = getHostnamefromUrl(url);
  } else if (competitor.urlType === CompetitorWebsiteUrlType.SUBFOLDER) {
    // highlight the folder
    textToHighlight = url.replace(/https?:\/\//i, '').replace('www.', '');
  }
  const regex = new RegExp(`(${textToHighlight})`);
  const parts = url.split(regex);

  return parts.map((part) => ({
    isHighlighted: part === textToHighlight,
    text: part,
  }));
}

//Sort the stacked bar chart based on the total value of each stack (descending order)
export const sortBarChart = (
  series: any,
  names: string[],
  sortkey: string | null = null,
  hiddenSeries: string[] = []
) => {
  const stacks: any[] = [],
    seriesData: any[] = [],
    categoriesData: string[] = [];
  let sortedMetrics = series;
  if (sortkey) {
    sortedMetrics = sortedMetrics.filter((serie: any) => serie.name === sortkey);
  }
  sortedMetrics?.forEach((serie: any) => {
    serie.data.forEach((d: any, index: number) => {
      if (!stacks[index]) {
        stacks[index] = {};
        stacks[index].index = index;
        stacks[index].total = 0;
      }
      if (hiddenSeries.includes(serie.name)) {
        stacks[index].total += 0;
      } else {
        stacks[index].total += d?.custom?.value ?? d;
      }
    });
  });

  stacks.sort((a, b) => b.total - a.total);

  stacks.forEach(({ index }) => {
    series?.forEach((serie: any, dIndex: number) => {
      if (!seriesData[dIndex]) {
        seriesData[dIndex] = { ...serie };
        seriesData[dIndex].data = [];
      }

      if (hiddenSeries.includes(serie.name)) {
        seriesData[dIndex].data.push(0);
      } else seriesData[dIndex].data.push(serie.data[index] || 0);
    });

    categoriesData.push(names[index]);
  });

  return { categoriesData, seriesData };
};

export function getCompetitorValueMetricByType(
  metrics: CompetitorReportMetric[],
  competitorId: string,
  type: string,
  metrickey: string = 'traffic'
) {
  const competitorMetric: CompetitorReportMetric | undefined = metrics?.find(
    (metric) => metric.competitorWebsiteId === competitorId && metric.id === type
  );

  const traffic = (competitorMetric?.[metrickey as keyof CompetitorReportMetric] as number) ?? 0;

  return {
    custom: {
      competitorId,
      nbPages: competitorMetric?.nbPages,
      percentage: (traffic * 100) / getCompetitorTotalMetrics(competitorId, metrics, metrickey),
      type,
      value: traffic,
    },
    y: traffic,
  };
}

export function getCompetitorTotalMetrics(
  competitorId: string,
  metrics: CompetitorReportMetric[],
  metrickey: string = 'traffic'
) {
  const competitorMetrics = metrics?.filter(
    (metric) => metric.competitorWebsiteId === competitorId
  );

  return competitorMetrics?.reduce((acc, metric) => acc + metric?.[metrickey], 0);
}

export function getSumMetricByKey(metrics: any[], metricKey: string, metric: string) {
  const data = metrics?.filter((metric) => metric.id === metricKey);

  return data?.reduce((acc, datum) => acc + datum[metric], 0);
}

function competitorWithHighestTraffic(
  metrics: { competitorWebsiteId: string; traffic: number }[]
): string {
  const competitorsTraffics = metrics.reduce((acc: { [key: string]: number }, metric) => {
    acc[metric.competitorWebsiteId] = (acc[metric.competitorWebsiteId] ?? 0) + metric.traffic;

    return acc;
  }, {});

  return Object.keys(competitorsTraffics).reduce(
    (a, b) => (competitorsTraffics[a] > competitorsTraffics[b] ? a : b),
    ''
  );
}

export function sortMetricByVolume(metrics: any[], data: { id: string }[]) {
  const competitorIdWithHighestTraffic = competitorWithHighestTraffic(metrics);
  const metricsOfCompetitorId = metrics.filter(
    (metric) => metric.competitorWebsiteId === competitorIdWithHighestTraffic
  );

  return data
    .map((datum) => ({
      ...datum,
      globalVolume: getSumMetricByKey(metrics, datum.id, 'traffic'),
      volume: getSumMetricByKey(metricsOfCompetitorId, datum.id, 'traffic'),
    }))
    .filter((datum) => datum.globalVolume > 0) // we do not want to display legends of empty series
    .sort((a, b) => a.volume - b.volume);
}

export function filterEmptySeries(metrics: any[], data: string[]) {
  return data
    .map((datum) => ({
      globalVolume: getSumMetricByKey(metrics, datum, 'traffic'),
      id: datum,
    }))
    .filter((datum) => datum.globalVolume > 0); // we do not want to display legends of empty series
}

export function filterCompetitorWebsites(
  competitorWebsites: any[],
  competitorsFilter: string[]
): { id: string; name: string }[] {
  return competitorWebsites
    .filter(({ id }) => competitorsFilter.includes(id))
    .map((competitor) => ({
      id: competitor.id,
      name: getHostnamefromUrl(competitor.url),
      url: competitor.url,
      urlType: competitor.urlType,
    }));
}

export function getFirstDayOfTheLastCompletedMonth() {
  const now = DateTime.now();
  const isLastDayOfMonth = now.day === now.daysInMonth;

  if (isLastDayOfMonth) {
    return DateTime.fromObject({
      day: 1,
      month: now.month,
      year: now.year,
    });
  } else {
    const lastMonth = now.minus({ months: 1 });
    return DateTime.fromObject({
      day: 1,
      month: lastMonth.month,
      year: lastMonth.year,
    });
  }
}

export const EmptyStateImage = {
  en: SearchIntelligenceEmptyStateEn,
  fr: SearchIntelligenceEmptyStateFr,
};

export function getEmptyStateImage(locale: string = 'en') {
  switch (locale) {
    case 'fr':
      return EmptyStateImage.fr;
    default:
      return EmptyStateImage.en;
  }
}
