import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Handle, Position } from 'reactflow';

import {
  MAX_EXPANDED_NODES,
  MIND_MAP_DETAILED_VIEW,
} from '@/containers/ContentIdeas/Listing/MindMap/constant';
import { ORIENTATION_VERTICAL } from '@/containers/ContentIdeas/Listing/MindMap/constant';
import Flex from '@/design-system/components/Flex/Flex';
import defaultTheme from '@/themes/defaultTheme';

import { StyledPlusIcon, Text } from './ShowMoreNode.styled';

const SHOW_MORE_TYPE = 'showMore';
const SHOW_LESS_TYPE = 'showLess';

function ShowMoreNode({ data }) {
  const { t } = useTranslation();
  const [query, setQuery] = useSearchParams();
  const clusterId = query.get('clusterId');

  const { nodes, setNodes } = data;
  const parentNode = nodes.find((node) => node.id === data.cluster.id);
  const parentOccurences = parentNode.data.occurrences;

  const type = parentNode.data.expanded !== parentOccurences ? SHOW_MORE_TYPE : SHOW_LESS_TYPE;

  const text =
    type === SHOW_MORE_TYPE
      ? t('content-ideas:mind-map.show-more-content-ideas_interval', {
          count: parentOccurences - parentNode.data.expanded,
          postProcess: 'interval',
        })
      : t('content-ideas:mind-map.show-less-content-ideas');

  function handleShow() {
    if (data.cluster.id !== clusterId) {
      query.set('clusterId', data.cluster.id);
      setQuery(query);
    }

    const parentIndex = nodes.findIndex((node) => node.id === data.cluster.id);
    if (type === SHOW_MORE_TYPE) {
      nodes[parentIndex].data.expanded = parentOccurences;
    } else {
      nodes[parentIndex].data.expanded = MAX_EXPANDED_NODES;
    }

    setNodes([...nodes]);
  }

  return data.mindMapView === MIND_MAP_DETAILED_VIEW ? (
    <DetailedRender data={data} handleShow={handleShow} text={text} />
  ) : (
    <SimplifiedRender data={data} handleShow={handleShow} text={text} />
  );
}

function DetailedRender({ data, handleShow, text }) {
  return (
    <Flex
      alignItems="center"
      backgroundColor="white"
      borderRadius="8px"
      boxShadow={defaultTheme.boxShadow.modal}
      gap="12px"
      justifyContent="center"
      opacity={data.blurred ? 0.3 : 1}
      padding="12px 16px"
      width="450px"
      onClick={handleShow}
    >
      <Handle
        position={data.orientation === ORIENTATION_VERTICAL ? Position.Top : Position.Left}
        style={{ visibility: 'hidden' }}
        type="target"
      />
      <StyledPlusIcon />
      <Text>{text}</Text>
    </Flex>
  );
}

function SimplifiedRender({ data, handleShow, text }) {
  return (
    <Flex
      alignItems="center"
      gap="2px"
      marginLeft="-6px"
      opacity={data.blurred ? 0.3 : 1}
      onClick={handleShow}
    >
      <Handle
        position={data.orientation === ORIENTATION_VERTICAL ? Position.Top : Position.Left}
        style={{ visibility: 'hidden' }}
        type="target"
      />
      <StyledPlusIcon />
      <Text>{text}</Text>
    </Flex>
  );
}

export default ShowMoreNode;
