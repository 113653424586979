export default function listByPropertyReducer(propertyName) {
  return (values, current) => {
    values[current[propertyName]] = current;
    return values;
  };
}

export function listOfListByPropertyReducer(propertyName) {
  return (values, current) => {
    values[current[propertyName]] = [...(values[current[propertyName]] ?? []), current];

    return values;
  };
}
