import './SidePanelTab.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { SidePanelTabProps } from './SidePanelTab.types';

function SidePanelTab({
  icon,
  label,
  onClick,
  selected,
  sticker,
  disabled,
  ...props
}: SidePanelTabProps): React.JSX.Element {
  const { t } = useTranslation();
  return (
    <div
      className={`side-panel-tab ${selected ? 'side-panel-tab__selected' : ''} ${disabled ? 'side-panel-tab__disabled' : ''}`}
      onClick={onClick}
      {...props}
    >
      <div className="side-panel-tab__icon">
        {icon}
        {sticker}
      </div>
      <div className="side-panel-tab__label">{t(`content:side-bar.${label}`)}</div>
    </div>
  );
}

export default SidePanelTab;
