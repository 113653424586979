import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { DateTime } from 'luxon';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getRoundedNumberMetricObject } from 'semji-core/utils/numbers';

import defaultTheme from '@/themes/defaultTheme';
import { METRICS_CONFIG_PERIODICITY_DAILY, periodicities } from '@/utils/metrics/constants';

import { SparklineTooltip } from './Tooltip';

function HighChartsColumn({ metricsSeries, selectedMetric, periodicity }) {
  const { t } = useTranslation();
  const userLanguageCode = useSelector((state) => state.user?.languageCode);
  const OPTIONS = {
    chart: {
      animation: false,
      backgroundColor: 'none',
      height: 70,
      skipClone: true,
      style: {
        fontFamily: defaultTheme.textCss.fonts.main,
        overflow: 'visible',
      },
      width: 125,
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
        borderRadius: 3,
        borderWidth: 0,
        pointPadding: 0.3,
      },
      series: {
        animation: false,
        groupPadding: 0,
        pointPadding: 0,
      },
    },
    title: {
      text: '',
    },
    tooltip: {
      backgroundColor: 'white',
      formatter: function () {
        return ReactDOMServer.renderToStaticMarkup(getTooltipItem({ graphPoints: this.points }));
      },
      hideDelay: 0,
      outside: true,
      shared: true,
      style: {
        color: defaultTheme.cssColors.dark080,
      },
      useHTML: true,
    },
    xAxis: {
      type: 'datetime',
      visible: false,
    },
    yAxis: {
      min: 0,
      visible: false,
    },
  };

  const series = forgeSeriesFromMetricSeries();

  function getTooltipItem({ graphPoints }) {
    const points = [];

    graphPoints.forEach((graphPoint) => {
      const date = DateTime.fromMillis(graphPoint.x, { locale: userLanguageCode });
      const label = date?.toFormat(periodicities[METRICS_CONFIG_PERIODICITY_DAILY].humanFormat);
      const roundedValueObject = getRoundedNumberMetricObject({
        number: graphPoint.y,
      });

      points.push({
        color: graphPoint.color,
        label: label,
        suffix: roundedValueObject.suffix,
        value: roundedValueObject.value,
      });

      if (graphPoint.point.publicationsCount) {
        const roundedValuePublicationsCount = getRoundedNumberMetricObject({
          number: graphPoint.point.publicationsCount,
        });
        points.push({
          color: defaultTheme.colors.secondary,
          label: 'Publication count',
          suffix: roundedValuePublicationsCount.suffix,
          value: roundedValuePublicationsCount.value,
        });
      }
    });

    if (points.length === 0) return;

    const sortedPoints = points.sort((a, b) => b.millisDate - a.millisDate);
    return (
      <SparklineTooltip
        minWidth="90px"
        points={sortedPoints}
        subTitle={t('report:table.keywords')}
        title={sortedPoints[0].label}
      />
    );
  }

  function forgeSeriesFromMetricSeries() {
    return metricsSeries.map((metricSerie) => {
      const pointsY = metricSerie.aggregateMetrics.map(({ y }) => y ?? 100);
      const maxY = Math.round(Math.max(...pointsY));
      return {
        dashStyle: 'dot',
        data: metricSerie.aggregateMetrics.map((aggregateMetric) => ({
          ...aggregateMetric,
          color: aggregateMetric.publicationsCount ? defaultTheme.colors.secondary : '#2758BC66',
          // Force y values to display something for stack bars
          y: aggregateMetric.y || maxY * 0.05,
        })),
        metric: selectedMetric,
        name: selectedMetric.key,

        period: 'current',
        // this is to enable 3 years of data (1096 points) to be displayed https://assets.highcharts.com/errors/12/
        // default value is 1000 points https://api.highcharts.com/highcharts/series.line.turboThreshold
        turboThreshold: 1200,
        type: 'column',
        zIndex: 1,
      };
    });
  }

  function onClick(e) {
    e.stopPropagation();
  }

  return (
    <div aria-hidden="true" onClick={onClick}>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          ...OPTIONS,
          series,
        }}
      />
    </div>
  );
}

export default HighChartsColumn;
