import { createContext, useContext } from 'react';

import { IPageDataLayoutContext } from './PageDataLayout.types';

export const PageDataLayoutContext = createContext<IPageDataLayoutContext>({
  ToggleComponent: null,
  isSidebarOpen: false,
  toggleSideBar: () => null,
});

export const usePageDataLayout = () => useContext(PageDataLayoutContext);
