import React from 'react';
import { useTranslation } from 'react-i18next';
import { getDecimalPlaces } from 'semji-core/utils/numbers';
import styled from 'styled-components/macro';

import { FlexCenteredAlignContainer, FlexJustifiedBetweenContainer } from '@/components/_common';
import { EDITOR_FK, TOP_PAGE_FK } from '@/components/FocusKeyword/constants';
import { StyledLoaderWrapper } from '@/components/FocusKeyword/StyledLoaderWrapper';
import CaretIcon from '@/components/icons/CaretIcon';
import CursorIcon from '@/components/icons/CursorIcon';
import HashIcon from '@/components/icons/HashIcon';
import SearchIcon from '@/components/icons/SearchIcon';
import FlatLoader from '@/components/Loader/FlatLoader';
import NotAcknowledgedCell from '@/components/Table/Cell/NotAcknowledgedCell';
import NumberCell from '@/components/Table/Cell/NumberCell';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import Can from '@/hoc/Can';
import { METRIC_TYPE_NUMBER } from '@/utils/metrics/constants';

const SelectorWrapper = styled(FlexJustifiedBetweenContainer)`
  border-radius: 3px;
  cursor: text;
  font-size: ${(props) => (props.variant === EDITOR_FK ? '13px' : 'inherit')};
  justify-content: end;
`;

const DetailsWrapper = styled.div`
  display: flex;
  gap: 8px;
  justify-content: end;
  align-items: center;
  color: ${({ theme }) => theme.text.colors.light};
  font-size: 0.9em;
`;

const IconTableModeWrapper = styled.div`
  margin: auto 0 auto 10px;
  display: flex;
  cursor: pointer;
`;

const ItemContainer = styled(FlexCenteredAlignContainer)`
  display: flex;
  gap: 2px;
  justify-content: center;
  align-items: center;
  cursor: help;
  font-size: 13px;
  color: #7c7d86;
  & > svg {
    width: 10px;
  }
`;

export function FocusKeywordPlaceHolder({
  workspaceCountryName,
  hasSearchConsole,
  variant,
  value,
}) {
  const { t } = useTranslation();
  const decimalPlaces = getDecimalPlaces(value?.trafficValue, METRIC_TYPE_NUMBER);

  return (
    <SelectorWrapper variant={variant}>
      <DetailsWrapper>
        <Can
          data={{
            hasSearchConsole,
          }}
          perform="editor-keyword-traffic:display"
          yes={() => (
            <div>
              <TooltipComponent
                description={t(
                  'components:focus-keyword.focus-keyword-options-header.traffic-description',
                  { workspaceCountryName }
                )}
                title={t('components:focus-keyword.focus-keyword-options-header.traffic-title')}
              >
                <ItemContainer>
                  <CursorIcon />
                  <StyledLoaderWrapper width={'auto'}>
                    {value.loadingSearchConsoleData ? (
                      <FlatLoader />
                    ) : value.trafficValue ? (
                      <NumberCell decimalPlaces={decimalPlaces} number={value.trafficValue} />
                    ) : (
                      <NotAcknowledgedCell />
                    )}
                  </StyledLoaderWrapper>
                </ItemContainer>
              </TooltipComponent>
            </div>
          )}
        />
        <Can
          data={{
            hasSearchConsole,
          }}
          perform="editor-keyword-position:display"
          yes={() => (
            <div>
              <TooltipComponent
                description={t(
                  'components:focus-keyword.focus-keyword-options-header.position-description',
                  { workspaceCountryName }
                )}
                title={t('components:focus-keyword.focus-keyword-options-header.position-title')}
              >
                <ItemContainer>
                  <HashIcon />
                  <StyledLoaderWrapper width={'auto'}>
                    {value.loadingSearchConsoleData ? (
                      <FlatLoader />
                    ) : value.positionValue ? (
                      value.positionValue
                    ) : (
                      <NotAcknowledgedCell />
                    )}
                  </StyledLoaderWrapper>
                </ItemContainer>
              </TooltipComponent>
            </div>
          )}
        />
        <div>
          <TooltipComponent
            description={t(
              'components:focus-keyword.focus-keyword-options-header.volume-description',
              { workspaceCountryName }
            )}
            title={t('components:focus-keyword.focus-keyword-options-header.volume-title')}
          >
            <ItemContainer>
              <SearchIcon />
              <StyledLoaderWrapper width={'auto'}>
                {value.loadingKeywordVolume ? (
                  <FlatLoader />
                ) : value.searchVolumeValue ? (
                  <NumberCell
                    decimalPlaces={getDecimalPlaces(value.searchVolumeValue, METRIC_TYPE_NUMBER)}
                    number={value.searchVolumeValue}
                  />
                ) : (
                  <NotAcknowledgedCell />
                )}
              </StyledLoaderWrapper>
            </ItemContainer>
          </TooltipComponent>
        </div>
        {variant === TOP_PAGE_FK ? (
          <IconTableModeWrapper>
            <CaretIcon />
          </IconTableModeWrapper>
        ) : null}
      </DetailsWrapper>
    </SelectorWrapper>
  );
}
