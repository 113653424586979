import './AlertDialog.scss';

import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogModal, ENUM_DIALOG_MODAL_SIZE } from 'semji-core/components/DialogModal';
import styled from 'styled-components/macro';

import CloseIcon from '@/components/icons/CloseIcon';
import Loader from '@/components/Loader/Loader';
import { SecondaryLink } from '@/components/Navigation/Link';
import { ParagraphText } from '@/components/Text/Paragraph';
export const Close = styled(CloseIcon)`
  && {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 30px;
    font-size: 1.2rem;
    color: ${(props) => props.theme.icon.colors.default};
  }
`;
export function AlertDialog({
  show,
  title,
  text,
  onCancel,
  onConfirm,
  confirmLabel,
  cancelLabel,
  loading,
  url,
  error,
}) {
  const { t } = useTranslation();

  return (
    <DialogModal
      ariaDescribedby="alert-dialog-description"
      ariaLabelledby="alert-dialog-title"
      cancelAction={onCancel}
      cancelLabel={cancelLabel || t('common:labels.cancel')}
      canClickOutsideToClose
      closable
      confirmAction={onConfirm}
      confirmLabel={confirmLabel}
      isConfirmDisabled={loading}
      isOpen={Boolean(show)}
      size={ENUM_DIALOG_MODAL_SIZE.Large}
      title={<span id="alert-dialog-title">{title}</span>}
      onClose={onCancel}
    >
      {loading ? (
        <Loader opaque style={{ fontSize: '1.5rem' }} />
      ) : (
        <>
          <div className="dialog-alert-dialog-description" id="alert-dialog-description">
            {text}
            {Boolean(url) && (
              <SecondaryLink isExternal to={url}>
                {url}
              </SecondaryLink>
            )}
            {!!error && (
              <ParagraphText color="red" size="sm">
                {t('components:dialog.alert.error')}
              </ParagraphText>
            )}
          </div>
        </>
      )}
    </DialogModal>
  );
}

AlertDialog.propTypes = {
  cancelLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  show: PropTypes.bool.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  title: PropTypes.string,
};

AlertDialog.defaultProps = {
  confirmLabel: 'Confirm',
  onCancel: () => {},
  onConfirm: () => {},
  title: '',
};
