import './EmptyStateChart.scss';

import React from 'react';

import NoContentsVerticalBars, {
  NO_COMPETITOR_CONTENT_DATA,
  NO_COMPETITOR_EVOLUTION_3YEARS_DATA,
  NO_COMPETITOR_EVOLUTION_3YEARS_MID_DATA,
  NO_COMPETITOR_EVOLUTION_12MONTHS_DATA,
  NO_COMPETITOR_HORIZONTAL_STACK_BAR_FULL,
  NO_COMPETITOR_STACK_BAR,
  NO_COMPETITOR_STACK_BAR_FULL,
  NO_COMPETITOR_THEME_DATA,
  NO_COMPETITOR_TRAFFIC_BAR_DATA,
  NO_COMPETITOR_TRAFFIC_LINE_DATA,
} from '@/components/EmptyState/NoContentsVerticalBars';

type EmptyStateVersion =
  | typeof NO_COMPETITOR_THEME_DATA
  | typeof NO_COMPETITOR_CONTENT_DATA
  | typeof NO_COMPETITOR_TRAFFIC_LINE_DATA
  | typeof NO_COMPETITOR_TRAFFIC_BAR_DATA
  | typeof NO_COMPETITOR_EVOLUTION_12MONTHS_DATA
  | typeof NO_COMPETITOR_EVOLUTION_3YEARS_DATA
  | typeof NO_COMPETITOR_EVOLUTION_3YEARS_MID_DATA
  | typeof NO_COMPETITOR_STACK_BAR
  | typeof NO_COMPETITOR_HORIZONTAL_STACK_BAR_FULL
  | typeof NO_COMPETITOR_STACK_BAR_FULL;

export default function EmptyStateChart({ version }: { version: EmptyStateVersion }) {
  return (
    <div className="competitors-empty-chart">
      <NoContentsVerticalBars version={version} />
    </div>
  );
}
