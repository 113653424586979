import { useQuery, UseQueryResult } from '@tanstack/react-query';

import apiClient from '@/apis/semji/apiClient';
import { SCOPE_FACTS } from '@/apis/semji/constants';
import useApiConfigurations from '@/hooks/useApiConfigurations';
import { Fact } from '@/types/fact/fact.types';
import { RECURSIVE_CALLS_REFETCH_FREQUENCY_MS } from '@/utils/configurations/constants';

export default function useGetFactsByContentId(
  contentId: string | undefined,
  isEnabled: boolean,
  props: {
    onSuccess: (...args: any) => void;
  }
): UseQueryResult<Fact[]> {
  const { get } = apiClient;
  const DELAY = parseInt(useApiConfigurations(RECURSIVE_CALLS_REFETCH_FREQUENCY_MS), 10);

  return useQuery({
    enabled: !!contentId && isEnabled,
    placeholderData: [],
    queryFn: async ({ signal }) => {
      if (!contentId) {
        return [];
      }

      const { data } = await get(`/contents/${contentId}/facts`, {
        signal,
      });

      return data?.['hydra:member'] || [];
    },
    queryKey: [SCOPE_FACTS.GET_FACTS_BY_CONTENT_ID, contentId],
    refetchInterval: DELAY,
    refetchOnWindowFocus: 'always',
    ...props,
  });
}
