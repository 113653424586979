import i18next from 'i18next';

import { TYPE_ANALYTICS } from '@/utils/constants';

export const selectConversionsGoalsMetrics = (state) => {
  const savedGoals =
    state.serviceIntegrations?.services?.find((service) => service.type === TYPE_ANALYTICS)?.data
      ?.custom_goals || {};
  return {
    conversions: {
      id: 0,
      name: i18next.t('listing:pages-list.view-panel.all-conversions'),
    },
    ...savedGoals,
  };
};
