import './Creation.scss';

import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ExistingUrlsCreation from '@/components/EmptyState/ExistingUrlsCreation';
import MessageSearchConsole from '@/components/Message/MessageSearchConsole';
import KeywordsSelection from '@/components/Pages/KeywordsSelection';
import useRecursiveTimeout from '@/hooks/useRecursiveTimeout';
import WorkspaceService from '@/services/Workspace';
import { makeCancelable } from '@/utils/cancelabePromise';
import { Log } from '@/utils/log/Log';
import { sortPagesByURLOrderSelection } from '@/utils/sorter';

const LOADING_ROWS_PLACEHOLDER = Array(5).fill({
  loading: true,
});

function Creation({
  creatingDrafts,
  draftsPages,
  hasSearchConsole,
  setCtaDisabledStatus,
  updateDraftPagesTitles,
  workspaceCountryName,
  setIsFetching,
}) {
  const { workspaceId } = useParams();
  const { t } = useTranslation();
  const cancelPageLoad = useRef(null);
  const [pages, setPages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  async function fetchPagesToImport() {
    try {
      if (!creatingDrafts) {
        const workspaceService = new WorkspaceService(workspaceId);
        const { promise, cancel } = makeCancelable(
          workspaceService.pages({
            'exists[draft]': false,
            'exists[url]': true,
          })
        );
        cancelPageLoad.current = cancel;

        const data = await promise;
        const sortedResponse = sortPagesByURLOrderSelection(
          draftsPages.map((elem) => elem.url),
          data
        );
        const pages = sortedResponse.filter(
          (page) => !!draftsPages.find((draftsPages) => draftsPages.id === page.id)
        );

        setCtaDisabledStatus(
          !pages
            .filter((page) => !page.redirectionUrl)
            .every(
              (page) =>
                page.focusTopKeyword !== null &&
                page.focusTopKeyword?.lastSearchConsoleUpdatedAt !== null
            )
        );

        updateDraftPagesTitles?.(pages);

        setPages(pages);
        setIsLoading(false);
        cancelPageLoad.current = null;
      }
    } catch (error) {
      cancelPageLoad.current = null;
      Log.error(error);
    }
  }

  useRecursiveTimeout(
    () => {
      fetchPagesToImport();
    },
    5000,
    true
  );

  useEffect(() => {
    return () => {
      cancelPageLoad.current?.();
    };
  }, []);

  if (pages.length === 0 && !isLoading) {
    return (
      <>
        <div className="updated-contents-creation">
          {!hasSearchConsole && (
            <MessageSearchConsole message={t('drafts:step.updated-contents.warning-message')} />
          )}
          <div className="updated-contents-creation__card">
            <ExistingUrlsCreation />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="updated-contents-creation">
        {!hasSearchConsole && (
          <MessageSearchConsole message={t('drafts:step.updated-contents.warning-message')} />
        )}
        <div className="updated-contents-creation__card">
          <KeywordsSelection
            hasSearchConsole={hasSearchConsole}
            rows={isLoading ? LOADING_ROWS_PLACEHOLDER : pages}
            setIsFetching={setIsFetching}
            workspaceCountryName={workspaceCountryName}
          />
        </div>
      </div>
    </>
  );
}

Creation.propTypes = {
  creatingDrafts: PropTypes.bool,
  draftsPages: PropTypes.array,
  hasSearchConsole: PropTypes.bool,
  setCtaDisabledStatus: PropTypes.func,
  workspaceCountryName: PropTypes.string,
};

export default Creation;
