import { PORTAL_FACT_CHECK_CARD_WIDTH } from '@/components/Editor/PortalFactCheckCard/const';
import {
  FACT_CRITICALITY_ENUM,
  FACT_STATUS_ENUM,
} from '@/containers/Content/SidePanel/Facts/facts.types';
import { ID_CONTENT } from '@/containers/Content/TinyMceComponents/Editor/constants';
import {
  FACT_CHECK_CRITICALITY_DATA_ATTRIBUTE,
  FACT_CHECK_DATA_ATTRIBUTE,
  FACT_CHECK_HIGHLIGHT_CLASS,
  FACT_CHECK_HOVER_DATA_ATTRIBUTE,
  FACT_CHECK_SELECTED_DATA_ATTRIBUTE,
  FACT_CHECK_VALIDATED_DATA_ATTRIBUTE,
} from '@/containers/Content/TinyMceComponents/Editor/hooks/useAiWriting/useFactCheck/const';
import { Fact } from '@/types/fact/fact.types';

export class FactDOMUtils {
  static getAllElementsByFactId(factId: string): NodeListOf<HTMLElement> {
    return document.querySelectorAll(`span[${FACT_CHECK_DATA_ATTRIBUTE}="${factId}"]`);
  }

  static getAllFactsElements(): NodeListOf<HTMLElement> {
    return document.querySelectorAll(`span[${FACT_CHECK_DATA_ATTRIBUTE}]`);
  }

  static getTopLineClientRect(node: HTMLElement | undefined): DOMRect | undefined {
    // Only works with #text
    if (!node || !node.parentNode || node.nodeType !== 3) {
      return undefined;
    }
    const range = document.createRange();
    range.setStart(node, 0);
    let prevBottom = range.getBoundingClientRect().bottom;
    let str = node.textContent;
    let current = 1;
    let bottom = 0;
    while (current <= str.length) {
      range.setStart(node, current);
      if (current < str.length - 1) {
        range.setEnd(node, current + 1);
      }
      bottom = range.getBoundingClientRect().bottom;
      if (bottom > prevBottom) {
        range.setStart(node, 0);
        range.setEnd(node, current);
        return range.getBoundingClientRect();
      }
      current++;
    }
    range.setStart(node, 0);
    range.setEnd(node, current - 1);
    return range.getBoundingClientRect();
  }

  static getBottomLineClientRect(node: HTMLElement | undefined): DOMRect | undefined {
    // Only works with #text
    if (!node || !node.parentNode || node.nodeType !== 3 || !node.textContent) {
      return undefined;
    }
    const range = document.createRange();
    range.setEnd(node, node.textContent.length);
    let prevBottom = range.getBoundingClientRect().bottom;
    let str = node.textContent;
    let current = 1;
    let bottom = 0;
    while (current <= str.length) {
      range.setStart(node, node.textContent.length - current);
      if (current < str.length - 1) {
        range.setStart(node, current - 1);
      }
      bottom = range.getBoundingClientRect().bottom;
      if (bottom > prevBottom) {
        range.setStart(node, 0);
        range.setEnd(node, current);
        return range.getBoundingClientRect();
      }
      current++;
    }
    range.setStart(node, 0);
    range.setEnd(node, node.textContent.length);
    return range.getBoundingClientRect();
  }

  static removePreviousFactCheckHighlights() {
    const previousHighlightedFactChecks = FactDOMUtils.getElementsBySelectedAttribute();

    Array.from(previousHighlightedFactChecks).forEach((element: Element) => {
      FactDOMUtils.setAttributeForElement({
        attribute: FACT_CHECK_SELECTED_DATA_ATTRIBUTE,
        element,
        value: 'false',
      });
    });
  }

  static initFactCheckDataAttributes(fact: Fact): NodeListOf<HTMLElement> {
    const highlightedSpans = FactDOMUtils.getAllElementsByFactId(fact.id);

    Array.from(highlightedSpans).forEach((element) => {
      element.setAttribute(
        FACT_CHECK_CRITICALITY_DATA_ATTRIBUTE,
        FactDOMUtils.getFactCriticality(fact)
      );
      FactDOMUtils.setAttributeForElement({
        attribute: FACT_CHECK_HOVER_DATA_ATTRIBUTE,
        element,
        value: 'false',
      });
      FactDOMUtils.setAttributeForElement({
        attribute: FACT_CHECK_VALIDATED_DATA_ATTRIBUTE,
        element,
        value: fact.status === FACT_STATUS_ENUM.STATUS_VALIDATED ? 'true' : 'false',
      });

      if (!element.classList.contains(FACT_CHECK_HIGHLIGHT_CLASS)) {
        element.classList.add(FACT_CHECK_HIGHLIGHT_CLASS);
      }
    });

    return highlightedSpans;
  }

  static getFactCriticality(fact: Fact): FACT_CRITICALITY_ENUM | 'undefined' {
    if (fact.isFactChecked) return FACT_CRITICALITY_ENUM.CRITICALITY_VERIFIED;
    if (fact.criticality === FACT_CRITICALITY_ENUM.CRITICALITY_MEDIUM)
      return FACT_CRITICALITY_ENUM.CRITICALITY_MEDIUM;
    if (fact.criticality === FACT_CRITICALITY_ENUM.CRITICALITY_HIGH)
      return FACT_CRITICALITY_ENUM.CRITICALITY_HIGH;
    return 'undefined';
  }

  static handleHighlightFactCheck(factCheckId: string, newFacts: Fact[]) {
    const fact = newFacts?.find((fact: Fact) => fact.id === factCheckId);

    if (!fact) return;

    const factSpans = FactDOMUtils.getAllElementsByFactId(fact.id);
    if (factSpans.length < 0) return;

    FactDOMUtils.removePreviousFactCheckHighlights();

    Array.from(factSpans).forEach((element) => {
      FactDOMUtils.setAttributeForElement({
        attribute: FACT_CHECK_SELECTED_DATA_ATTRIBUTE,
        element,
        value: 'true',
      });
    });

    factSpans.item(0)?.scrollIntoView({ block: 'nearest' });
  }

  static getTopFactCardPosition({
    event,
    targetElement,
  }: {
    event: React.MouseEvent;
    targetElement: Element;
  }) {
    // Use first highlighted entity as anchor
    const domRect = targetElement.getBoundingClientRect();
    const textTag = FactDOMUtils.findTextTag(targetElement as HTMLElement);
    const topLineRect: DOMRect | undefined = FactDOMUtils.getTopLineClientRect(textTag);
    const editorElement = document.getElementById(ID_CONTENT);
    const factCheckPosition = {
      getBoundingClientRect: () => ({
        height: domRect?.height,
        left: topLineRect
          ? Math.min(
              PORTAL_FACT_CHECK_CARD_WIDTH + (editorElement?.offsetLeft || 0),
              topLineRect.left
            )
          : event.clientX - PORTAL_FACT_CHECK_CARD_WIDTH / 2,
        right: domRect?.right,
        top: topLineRect ? topLineRect?.top : event.clientY,
        width: domRect?.width,
      }),
    };

    return factCheckPosition;
  }

  static getBottomFactCardPosition({
    event,
    targetElement,
  }: {
    event: React.MouseEvent;
    targetElement: Element;
  }) {
    const domRect = targetElement.getBoundingClientRect();
    const textTag = FactDOMUtils.findTextTag(targetElement as HTMLElement);
    const bottomLineRect: DOMRect | undefined = FactDOMUtils.getBottomLineClientRect(textTag);
    const editorElement = document.getElementById(ID_CONTENT);
    const factCheckPosition = {
      getBoundingClientRect: () => ({
        height: domRect?.height,
        left: bottomLineRect
          ? Math.min(
              PORTAL_FACT_CHECK_CARD_WIDTH + (editorElement?.offsetLeft || 0),
              bottomLineRect.left
            )
          : event.clientX - PORTAL_FACT_CHECK_CARD_WIDTH / 2,
        right: bottomLineRect?.right,
        top: bottomLineRect ? bottomLineRect?.top : event.clientY,
        width: domRect?.width,
      }),
    };

    return factCheckPosition;
  }

  static getFactsIds(): Set<string> {
    const highlightedElement = FactDOMUtils.getAllFactsElements();

    const targetIds = Array.from(highlightedElement).map(
      (element) => element.getAttribute(FACT_CHECK_DATA_ATTRIBUTE) ?? ''
    );

    return new Set(targetIds);
  }

  static setAttributeForElements({
    elements,
    attribute,
    value,
  }: {
    elements: NodeListOf<HTMLElement>;
    attribute: string;
    value: string;
  }): void {
    Array.from(elements).forEach((element) => {
      FactDOMUtils.setAttributeForElement({ attribute, element, value });
    });
  }

  static getNearestElementToCursorByAttribute(
    event: React.MouseEvent<HTMLElement> & { layerY: number },
    factId: string
  ): HTMLElement {
    const duplicatedElements = FactDOMUtils.getAllElementsByFactId(factId);

    const sortedElementsbyDistance = Array.from(duplicatedElements).sort(
      (a, b) => Math.abs(event?.layerY - a.offsetTop) - Math.abs(event?.layerY - b.offsetTop)
    );

    return sortedElementsbyDistance[0];
  }

  private static setAttributeForElement({
    element,
    attribute,
    value,
  }: {
    element: Element;
    attribute: string;
    value: string;
  }): void {
    element.setAttribute(attribute, value);
  }

  private static getElementsBySelectedAttribute(): NodeListOf<HTMLElement> {
    return document.querySelectorAll(`span[${FACT_CHECK_SELECTED_DATA_ATTRIBUTE}="true"]`);
  }

  private static findTextTag(node: HTMLElement): HTMLElement | undefined {
    if (!node?.childNodes) return undefined;
    if (node.nodeType !== 3) {
      return FactDOMUtils.findTextTag(node.childNodes?.[0] as HTMLElement);
    } else {
      return node;
    }
  }
}
