import Switch from '@material-ui/core/Switch';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DialogModal } from 'semji-core/components/DialogModal';
import { copyToClipboard } from 'semji-core/utils/clipboard';

import {
  StyledCopyIcon,
  StyledInput,
  Text,
  Title,
  Tooltip,
  VersionWrapper,
} from '@/components/Dialog/ExportCalendar.styles';
import Flex from '@/design-system/components/Flex';
import { showSuccessSnackbar } from '@/store/actions/ui';
import { selectCurrentWorkspaceAccess } from '@/store/selectors/selectCurrentWorkspaceAccess';
import { API_WORKSPACE_ROLE_MEMBER, API_WORKSPACE_ROLE_OWNER } from '@/utils/can/constants';

function ExportCalendar({ onClose }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { roles, calendarExportToken } = useSelector(selectCurrentWorkspaceAccess);
  const isOwnerOrMember =
    roles.includes(API_WORKSPACE_ROLE_OWNER) || roles.includes(API_WORKSPACE_ROLE_MEMBER);
  const [isAllView, setIsAllView] = useState(isOwnerOrMember);

  const calendarUrl = `${
    import.meta.env.VITE_REACT_APP_SEMJI_API_ROOT_URL
  }/ical/${calendarExportToken}/${isAllView ? 'all' : 'mine'}`;

  function handleSwitchView() {
    setIsAllView(!isAllView);
  }

  function handleCopy() {
    copyToClipboard(calendarUrl);
    dispatch(showSuccessSnackbar(t('listing:planning-list.export-calendar.copy-message')));
  }

  return (
    <DialogModal
      canClickOutsideToClose
      closable
      isOpen={true}
      title={
        <Title isOwnerOrMember={isOwnerOrMember}>
          {t('listing:planning-list.export-calendar.title')}
        </Title>
      }
      onClose={onClose}
    >
      <>
        {isOwnerOrMember && (
          <VersionWrapper alignItems="center">
            {t('listing:planning-list.export-calendar.switch')}
            <Switch checked={!isAllView} onChange={handleSwitchView} />
          </VersionWrapper>
        )}
        <Flex alignItems="center">
          <StyledInput disabled fullWidth value={calendarUrl} />
          <Tooltip title={t('listing:planning-list.export-calendar.copy-content-button-tooltip')}>
            <StyledCopyIcon onClick={handleCopy} />
          </Tooltip>
        </Flex>
        <Text>{t('listing:planning-list.export-calendar.warning')}</Text>
      </>
    </DialogModal>
  );
}

export default ExportCalendar;
