function mapFilters(filterGroups = []) {
  return JSON.stringify(
    filterGroups.flatMap((group) => {
      const { filters = [] } = group;

      return filters.map((filter) => {
        const { uid, comparison = {}, values = [] } = filter;
        const { value = null } = values?.[0] || {};

        return {
          comparison: comparison.comparison,
          uid,
          value,
        };
      });
    })
  );
}

export default function mapFiltersToQueryParameter({
  filters = {},
  navigate,
  query,
  location,
  setQuery,
}) {
  const { filterGroups = [], operator = 'AND' } = filters;

  query.set('operator', operator);
  query.set('filter', mapFilters(filterGroups));

  navigate(location.pathname);
  setQuery(query, { replace: true });
}
