import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import AlignJustifyIcon from '@/components/icons/AlignJustifyIcon';
import FlatLoader, { LoaderWrapper } from '@/components/Loader/FlatLoader';
import { Cell } from '@/components/Pages/ContentIdeas/common';
import Tooltip from '@/components/Tooltip/Tooltip';
import Flex from '@/design-system/components/Flex';

const StyledAlignJustifyIcon = styled(AlignJustifyIcon)`
  && {
    height: 11px;
    width: 11px;
  }
`;

function UrlWordsCount({ urlWordsCount, urlWordsCountRetrievedAt }) {
  const { t } = useTranslation();

  if (!urlWordsCountRetrievedAt) {
    return (
      <LoaderWrapper height={15} width={50}>
        <FlatLoader />
      </LoaderWrapper>
    );
  }

  return (
    <Tooltip title={t('content-ideas:listing.table.number-of-words')}>
      <Flex alignItems="center" cursor="pointer" gap="4px">
        <StyledAlignJustifyIcon />
        <Cell color="dark060" lineHeight="14px" noPadding size="xs" weight="medium">
          {urlWordsCount ? urlWordsCount : 'N/A'}
        </Cell>
      </Flex>
    </Tooltip>
  );
}

export default UrlWordsCount;
