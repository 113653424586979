import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { SUPPORT_ADDRESS_MAIL } from '@/components/../utils/constants';
import { FlexColumnCenteredAlignContainer } from '@/components/_common';
import { Close } from '@/components/Dialog/AlertDialog';
import { ContainedLinkButton } from '@/components/Navigation/Link';
import { ParagraphText } from '@/components/Text/Paragraph';
import { TitleThree } from '@/components/Text/Title';

const MarginTopBottomTitle = styled(TitleThree)`
  && {
    margin-top: 60px;
    margin-bottom: 15px;
    text-align: center;
  }
`;

const CenterParagraphText = styled(ParagraphText)`
  && {
    text-align: center;
    margin-bottom: 15px;
  }
`;

const MarginFlexColumnCenteredAlignContainer = styled(FlexColumnCenteredAlignContainer)`
  && {
    margin: 80px 40px 80px 40px;
    justify-content: center;
  }
`;

const WideButton = styled(ContainedLinkButton)`
  && {
    width: 200px;
  }
`;

const StyledClose = styled(Close)`
  && {
    top: 20px;
  }
`;

export const BodyMessage = ({ imagePath, imageAlt, title, children }) => {
  return (
    <>
      <img alt={imageAlt} src={imagePath} />
      <MarginTopBottomTitle weight="strong">{title}</MarginTopBottomTitle>
      <CenterParagraphText color="dark080" weight="medium">
        {children}
      </CenterParagraphText>
    </>
  );
};

function DialogInformationTemplate(props) {
  const { t } = useTranslation();

  return (
    <Dialog fullWidth maxWidth="sm" open={props.show} onClose={props.onClose}>
      <MarginFlexColumnCenteredAlignContainer>
        <StyledClose onClick={props.onClose} />
        <BodyMessage imageAlt={props.imageAlt} imagePath={props.imagePath} title={props.title}>
          {props.children}
        </BodyMessage>
        {props.withoutButton ? null : props.customButton ? (
          props.customButton
        ) : (
          <WideButton to={`mailto:${SUPPORT_ADDRESS_MAIL}`}>
            {t('components:dialog.dialog-information-template.button-text')}
          </WideButton>
        )}
      </MarginFlexColumnCenteredAlignContainer>
    </Dialog>
  );
}

DialogInformationTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  customButton: PropTypes.node,
  imageAlt: PropTypes.string.isRequired,
  imagePath: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default DialogInformationTemplate;
