import './Header.scss';

import { Trans, useTranslation } from 'react-i18next';

import HelperMessage from '@/components/Message/HelperMessage';
import Selector from '@/components/Select/Selector';

import { CONTENT_STATUS } from '../AddToPlanning.types';

function Header({
  handleSelectType,
  contentsLength,
  type,
  contents,
}: {
  handleSelectType: (type: string) => void;
  contentsLength: number;
  type: string | null;
  contents: any[];
}): JSX.Element {
  const { t } = useTranslation();
  const recommendedList = contents.filter((content) => !!content.recommendedUrl);

  return (
    <>
      {!!recommendedList.length && (
        <HelperMessage margin="0 0 20px 0">
          <Trans
            i18nKey={`add-to-planning:header.recommended-message_interval`}
            values={{
              count: recommendedList.length,
              postProcess: 'interval',
              url: recommendedList[0].recommendedUrl,
            }}
          />
        </HelperMessage>
      )}
      <div className="add-to-planning-dialog-header">
        <Selector
          handleSelectType={handleSelectType}
          isRecommendedText={t('add-to-planning:header.recommended')}
          margin="0 20px 0 0"
          text={t(`add-to-planning:header.create-new-content`, {
            count: contentsLength,
            postProcess: 'interval',
          })}
          type={type}
          value={CONTENT_STATUS.NEW_CONTENT}
        />
        <Selector
          handleSelectType={handleSelectType}
          isRecommended={!!recommendedList.length}
          isRecommendedText={t('add-to-planning:header.recommended')}
          text={t(`add-to-planning:header.optimize-existing-content`, {
            count: contentsLength,
            postProcess: 'interval',
          })}
          type={type}
          value={CONTENT_STATUS.UPDATED_CONTENT}
        />
      </div>
    </>
  );
}

export default Header;
