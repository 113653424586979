import React from 'react';

import SvgWrapper from './SvgWrapper';

const TimeIcon = (props) => (
  <SvgWrapper {...props}>
    <path d="M8.666 0c-4.687 0-8.5 3.813-8.5 8.5s3.813 8.5 8.5 8.5 8.5-3.813 8.5-8.5c0-4.687-3.813-8.5-8.5-8.5zM8.666 16c-4.136 0-7.5-3.364-7.5-7.5s3.364-7.5 7.5-7.5 7.5 3.364 7.5 7.5-3.364 7.5-7.5 7.5zM14 9v1h-6v-5h1v4h5z" />
  </SvgWrapper>
);

export default TimeIcon;
