import axios from 'axios';
import { useEffect, useRef } from 'react';

import useCan from '@/hooks/useCan';
import useScript from '@/hooks/useScript';

export default function useFaceIo() {
  const faceIoPublicId = import.meta.env.VITE_REACT_APP_FACEIO_PUBLIC_ID;
  const faceIoApiKey = import.meta.env.VITE_REACT_APP_FACEIO_API_KEY;
  const isSigninWithFIOEnabled = useCan({ perform: 'sign-in:with-faceio' });
  const isEnabled = isSigninWithFIOEnabled && !!faceIoPublicId;
  const status = useScript('https://cdn.faceio.net/fio.js', isEnabled);
  const faceIoRef = useRef(null);

  // Instantiate faceIo when lib is loaded.
  useEffect(() => {
    if (status === 'ready') {
      // eslint-disable-next-line no-undef
      faceIoRef.current = new faceIO(faceIoPublicId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  function enrollNewUser({ user = {}, onSuccess, onError }) {
    if (!faceIoRef.current) {
      return;
    }

    const { email, id, languageCode } = user;

    // Start the facial enrollment process
    faceIoRef.current
      .enroll({
        enrollIntroTimeout: 5,
        locale: languageCode || 'auto', // Default user locale
        payload: {
          email,
          userId: id,
        },
      })
      .then((userInfo) => {
        onSuccess?.(userInfo);
      })
      .catch((errCode) => {
        // https://faceio.net/integration-guide#error-codes
        onError?.(errCode);
      });
  }

  function authenticateUser({ onSuccess, onError } = {}) {
    if (!faceIoRef.current) {
      return;
    }

    // Authenticate a previously enrolled user
    faceIoRef.current
      .authenticate({
        locale: 'auto', // Default user locale
      })
      .then((userData) => {
        onSuccess?.(userData);
      })
      .catch((errCode) => {
        // https://faceio.net/integration-guide#error-codes
        onError?.(errCode);
      });
  }

  function deleteFacialId(facialId) {
    return axios(`https://api.faceio.net/deletefacialid?key=${faceIoApiKey}&fid=${facialId}`);
  }

  return { authenticateUser, deleteFacialId, enrollNewUser, isEnabled };
}
