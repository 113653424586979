import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from 'semji-core/components/Button';
import { ColoredDot, ColoredDotSize } from 'semji-core/components/ColoredDot';
import { ClickableTextArea } from 'semji-core/components/Textarea';
import { DownIcon, ENUM_DOWN_ICON_DIRECTION } from 'semji-core/icons/DownIcon';

import ContentScoreCss from '@/components/Table/Cell/ContentScoreCss';
import VersionOptionsMenu from '@/containers/ContentVersion/components/VersionHistoryPanel/VersionGroup/VersionOptionsMenu';
import { ENUM_CONTENT_TYPE } from '@/types/contents';

import { KEYBOARD_NAVIGATE_DIRECTION, VersionItemProps } from './VersionGroup.types';
import { VersionGroupUtils } from './VersionGroup.utils';

function VersionItem({
  version,
  canBeOpen,
  isSelected,
  isOpen,
  onSelectVersion: handleSelectVersion,
  onRenameVersion,
  onTabulate,
  onOpen: handleClickOpen,
  isSubItem,
  withPadding,
  isPublished,
  enableOptionsMenu = false,
}: VersionItemProps) {
  const { t } = useTranslation();
  const textAreaRef = React.createRef<HTMLTextAreaElement>();
  const { id, displayDate, score, type, isCurrentDraft } = version;
  const defaultVersionName = isCurrentDraft
    ? t('content:version-history-panel.current-draft')
    : version.name || displayDate;
  const [name, setName] = useState(defaultVersionName);

  function handleRenameVersion(name: string) {
    onRenameVersion?.(id, name);
  }

  function handleChangeName(e: React.ChangeEvent<HTMLTextAreaElement>) {
    setName(e.currentTarget.value);
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
    if (['Tab', 'ArrowDown'].includes(e.key) && onTabulate) {
      onTabulate();
    }
    // handle up and down arrow keys
    if (e.key === 'ArrowUp' && onTabulate) {
      onTabulate(KEYBOARD_NAVIGATE_DIRECTION.UP);
    }
  }

  function handleRenameClick() {
    const event = new MouseEvent('dblclick', {
      bubbles: true,
      cancelable: true,
      view: window,
    });

    textAreaRef.current?.dispatchEvent(event);
  }

  useEffect(() => {
    setName(defaultVersionName);
  }, [defaultVersionName]);

  const canEditName = onRenameVersion && !isPublished && !isCurrentDraft;

  return (
    <>
      <div
        className={`version-history-panel__content__listing__group__item ${
          isSelected ? 'version-history-panel__content__listing__group__item--selected' : ''
        } ${isSubItem ? 'version-history-panel__content__listing__group__item--sub' : ''}`}
        id={VersionGroupUtils.getVersionComponentId(id)}
        role="button"
        tabIndex={0}
        onClick={handleSelectVersion(id)}
        onKeyDown={handleKeyDown}
      >
        {canBeOpen && (
          <IconButton
            className="version-history-panel__content__listing__group__item__button"
            onClick={handleClickOpen}
          >
            <DownIcon
              className="version-history-panel__content__listing__group__item__icon"
              direction={isOpen ? ENUM_DOWN_ICON_DIRECTION.DOWN : ENUM_DOWN_ICON_DIRECTION.RIGHT}
            />
          </IconButton>
        )}
        {withPadding && !canBeOpen && (
          <div className="version-history-panel__content__listing__group__item__padding" />
        )}
        <ColoredDot
          className="version-history-panel__content__listing__group__item__dot"
          color={version.color}
          size={ColoredDotSize.XXXS}
        />

        <div className="version-history-panel__content__listing__group__item__label">
          <ClickableTextArea
            ref={textAreaRef}
            className="version-history-panel__content__listing__group__item__label__name"
            label={t(
              `content:version-history-panel.${version.name ? 'rename-version' : 'name-version'}`
            )}
            maxLength={70}
            readOnly={!canEditName}
            rows={VersionGroupUtils.getRowNum(name)}
            value={name}
            withTooltip={canEditName && isSelected}
            onChange={handleChangeName}
            onValidate={handleRenameVersion}
          />
          <span className="version-history-panel__content__listing__group__item__label__author">
            {VersionGroupUtils.getAuthorName(version)}
          </span>
          {type === ENUM_CONTENT_TYPE.ORIGINAL && (
            <span className="version-history-panel__content__listing__group__item__label__type">
              {t('content:version-history-panel.original-version')}
            </span>
          )}
        </div>
        {score ? (
          <ContentScoreCss
            className="version-history-panel__content__listing__group__item__score"
            extraLargeMode={false}
            extraSmallMode={true}
            loading={false}
            score={Math.floor(score * 100)}
            smallMode={false}
          />
        ) : null}

        {enableOptionsMenu && (
          <div className="version-history-panel__content__listing__group__item__menu">
            <VersionOptionsMenu
              hasAlreadyBeenNamed={!!version.name}
              isRenameDisabled={!canEditName}
              version={version}
              onRemoveNameClick={() => handleRenameVersion('')}
              onRenameClick={handleRenameClick}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default memo(VersionItem);
