import MuiMenuItem from '@material-ui/core/MenuItem';
import MuiSelect from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { copyToClipboard } from 'semji-core/utils/clipboard';
import styled from 'styled-components/macro';

import useGetInfo from '@/apis/semji/configuration/useGetInfo';
import { DefaultMenu } from '@/components/_common';
import SettingsIcon from '@/components/icons/SettingsIcon';
import HeaderButton from '@/components/Layout/HeaderButton';
import { TertiaryLink } from '@/components/Navigation/Link';
import useApiConfigurations from '@/hooks/useApiConfigurations';
import useDebug from '@/hooks/useDebug';
import {
  RESET_DEBUG_STATE,
  SET_COMPETITOR_MOCK,
  SET_DEBUG_I18N,
  SET_DEBUG_LANGUAGE,
  SET_MOCK,
  SET_MOCK_PROXY_ENV,
  SET_RECURSIVE_CALLS,
} from '@/store/actions/actionTypes';
import { showSuccessSnackbar, toggleUiMode } from '@/store/actions/ui';
import { checkCanDebug } from '@/utils/log/checkCanDebug';
import { checkIsDevEnv } from '@/utils/log/checkIsDevEnv';
import { PROXY_HOSTS } from '@/utils/log/constants';
import { envDetector } from '@/utils/log/envDetector';

const MenuItem = styled(MuiMenuItem)`
  height: 50px;
`;

const StyledSettingsIcon = styled(SettingsIcon)`
  color: ${({ variant, theme }) => variant === 'dark' && theme.cssColors.dark040};
`;

const TextItem = styled.span`
  flex: 1;
`;

const Divider = styled.li`
  border-top: solid 0.5px ${({ theme }) => theme.cssColors.dark010};
  color: ${({ variant, theme }) => variant === 'dark' && theme.cssColors.dark040};
  font-size: ${(props) => props.theme.textCss.sizes.xs};
  padding: 16px 16px 0 16px;
`;

function AdministratorMenu({ isUserSuperAdmin, variant }) {
  const { t } = useTranslation();
  const userEmail = useSelector((state) => state.user.email);
  const location = useLocation();
  const dispatch = useDispatch();
  const [settingsMenuAnchorEl, setSettingsMenuAnchorEl] = useState(null);
  const uiMode = useSelector((state) => state.uiMode);
  const userLanguageCode = useSelector((state) => state.user?.languageCode);

  const canDebug = checkCanDebug();
  const isDevEnv = checkIsDevEnv();
  const debugLng = useDebug('lng');
  const isDebugI18nEnabled = useDebug('i18n');
  const isRecursiveCallDisabled = useDebug('isRecursiveCallDisabled');
  const isMockEnabled = useDebug('isMockEnabled');
  const isCompetitorsfakeData = false;
  const mockProxyEnv = useDebug('mockProxyEnv');

  const getInfo = useGetInfo({
    enabled: isUserSuperAdmin,
  });

  // AI Writing
  const aiPromptCodeOutline = useApiConfigurations('completion.outline.prompt_code');
  const aiPromptCodeIntroduction = useApiConfigurations('completion.introduction.prompt_code');
  const aiPromptCodeParagraph = useApiConfigurations('completion.paragraph.prompt_code');
  const aiPromptCodeTitle = useApiConfigurations('completion.title.prompt_code');

  const appVersion = import.meta.env.VITE_REACT_APP_VERSION;
  const appType = import.meta.env.VITE_REACT_APP_CLIENT_TYPE;
  const appEnv = envDetector();
  const apiVersion = getInfo?.data?.data?.version;
  const appBuildDate = getInfo?.data?.data?.buildDate
    ? new Date(getInfo.data.data.buildDate)
    : null;

  function handleSettingsMenuClose() {
    setSettingsMenuAnchorEl(null);
  }

  function handleClickSettingsMenu(e) {
    setSettingsMenuAnchorEl(e.currentTarget);
  }

  function toggleUiModeClick() {
    dispatch(toggleUiMode(isUserSuperAdmin));
  }

  function resetDebugState() {
    dispatch({
      type: RESET_DEBUG_STATE,
    });
  }

  function toggleDebugI18n() {
    dispatch({
      payload: { value: !isDebugI18nEnabled },
      type: SET_DEBUG_I18N,
    });
  }

  function toggleRecursiveCalls() {
    dispatch({
      payload: { value: !isRecursiveCallDisabled },
      type: SET_RECURSIVE_CALLS,
    });
  }

  function toggleMocks() {
    dispatch({
      payload: { value: !isMockEnabled },
      type: SET_MOCK,
    });
  }

  function toggleCompetitorFakeMocks() {
    dispatch({
      payload: { value: false },
      type: SET_COMPETITOR_MOCK,
    });
  }

  function setMockProxyEnv(event) {
    dispatch({
      payload: { value: event.target.value },
      type: SET_MOCK_PROXY_ENV,
    });
  }

  function setDebugLanguage(event) {
    dispatch({
      payload: { value: event.target.value },
      type: SET_DEBUG_LANGUAGE,
    });
  }

  function handleCopyToClipboard() {
    copyToClipboard(
      `${import.meta.env.VITE_REACT_APP_SEMJI_APP_FRONTEND_URL}/impersonate/${userEmail}${
        location.pathname
      }${location.search}`
    );
    dispatch(showSuccessSnackbar(t('layout:flash-message.copy-impersonate-link-message')));
    handleSettingsMenuClose();
  }

  return (
    <>
      <HeaderButton aria-haspopup="true" aria-label="Settings" onClick={handleClickSettingsMenu}>
        <StyledSettingsIcon variant={variant} />
      </HeaderButton>
      <DefaultMenu
        anchorEl={settingsMenuAnchorEl}
        open={!!settingsMenuAnchorEl}
        onClose={handleSettingsMenuClose}
      >
        <MenuItem onClick={handleSettingsMenuClose}>
          <TertiaryLink isExternal to={import.meta.env.VITE_REACT_APP_SEMJI_BACKOFFICE_URL}>
            {t('layout:administrator-menu.go-to-backoffice')}
          </TertiaryLink>
        </MenuItem>
        <MenuItem onClick={toggleUiModeClick}>
          <TextItem>{t('layout:administrator-menu.advanced-ui')}</TextItem>
          <Switch checked={uiMode === 'advanced'} />
        </MenuItem>
        <MenuItem onClick={handleCopyToClipboard}>
          <TextItem>{t('layout:administrator-menu.copy-to-clipboard')}</TextItem>
        </MenuItem>

        {canDebug && [
          <Divider key="dividerDebug">Debug</Divider>,

          <MenuItem key="toggleDebugI18n" onClick={toggleDebugI18n}>
            <TextItem>I18n debug</TextItem>
            <Switch checked={Boolean(isDebugI18nEnabled)} />
          </MenuItem>,

          <MenuItem key="debugLng">
            <TextItem>Lang</TextItem>
            <MuiSelect value={debugLng || userLanguageCode} onChange={setDebugLanguage}>
              {['en', 'es', 'fr', 'it', 'cimode'].map((lng) => (
                <MuiMenuItem key={lng} value={lng}>
                  {lng}
                </MuiMenuItem>
              ))}
            </MuiSelect>
          </MenuItem>,

          <MenuItem key="isRecursiveCallDisabled" onClick={toggleRecursiveCalls}>
            <TextItem>Recursive calls</TextItem>
            <Switch checked={Boolean(!isRecursiveCallDisabled)} />
          </MenuItem>,

          <MenuItem key="isMockEnabled" onClick={toggleMocks}>
            <TextItem>Use mock</TextItem>
            <Switch checked={Boolean(isMockEnabled)} />
          </MenuItem>,

          <MenuItem key="isCompetitorsfakeData" onClick={toggleCompetitorFakeMocks}>
            <TextItem>Use Competitors fake data</TextItem>
            <Switch checked={Boolean(isCompetitorsfakeData)} />
          </MenuItem>,

          ...(isDevEnv
            ? [
                <MenuItem key="mockProxyEnv">
                  <TextItem>Use proxy</TextItem>
                  <MuiSelect value={mockProxyEnv} onChange={setMockProxyEnv}>
                    {Object.entries({ Local: '', ...PROXY_HOSTS }).map(([label, host]) => (
                      <MuiMenuItem key={label} value={host}>
                        {label}
                      </MuiMenuItem>
                    ))}
                  </MuiSelect>
                </MenuItem>,
              ]
            : []),

          <MenuItem key="resetDebugState" onClick={resetDebugState}>
            <TextItem>Reset debug state</TextItem>
          </MenuItem>,
        ]}

        {(aiPromptCodeOutline ||
          aiPromptCodeParagraph ||
          aiPromptCodeIntroduction ||
          aiPromptCodeTitle) && [
          <Divider key="dividerAi">AI+ Content</Divider>,
          ...(aiPromptCodeTitle
            ? [
                <MenuItem key="aiPromptCodeTitle">
                  <TextItem>Title: {aiPromptCodeTitle}</TextItem>
                </MenuItem>,
              ]
            : []),
          ...(aiPromptCodeIntroduction
            ? [
                <MenuItem key="aiPromptCodeIntroduction">
                  <TextItem>Introduction: {aiPromptCodeIntroduction}</TextItem>
                </MenuItem>,
              ]
            : []),
          ...(aiPromptCodeParagraph
            ? [
                <MenuItem key="aiPromptCodeIntroduction">
                  <TextItem>Paragraph: {aiPromptCodeParagraph}</TextItem>
                </MenuItem>,
              ]
            : []),
          ...(aiPromptCodeOutline
            ? [
                <MenuItem key="aiPromptCodeOutline">
                  <TextItem>Outline: {aiPromptCodeOutline}</TextItem>
                </MenuItem>,
              ]
            : []),
        ]}

        {(appEnv || appVersion || appType || apiVersion || appBuildDate) && [
          <Divider key="dividerInfos">Env. infos</Divider>,
          ...(appEnv
            ? [
                <MenuItem key="appEnv">
                  <TextItem>Env: {appEnv}</TextItem>
                </MenuItem>,
              ]
            : []),
          ...(appVersion
            ? [
                <MenuItem key="appVersion">
                  <TextItem>App Version: {appVersion}</TextItem>
                </MenuItem>,
              ]
            : []),
          ...(appType
            ? [
                <MenuItem key="appType">
                  <TextItem>App Type: {appType}</TextItem>
                </MenuItem>,
              ]
            : []),
          ...(apiVersion
            ? [
                <MenuItem key="apiVersion">
                  <TextItem>API Version: {apiVersion}</TextItem>
                </MenuItem>,
              ]
            : []),
          ...(appBuildDate
            ? [
                <MenuItem key="appBuildDate">
                  <TextItem>Build: {appBuildDate.toLocaleString()}</TextItem>
                </MenuItem>,
              ]
            : []),
        ]}
      </DefaultMenu>
    </>
  );
}

export default AdministratorMenu;
