import {
  CLOSE_CREDIT_LIMIT_DIALOG,
  CLOSE_SIDE_PANEL,
  OPEN_CREDIT_LIMIT_DIALOG,
  OPEN_SIDE_PANEL,
  RESET_REPORT,
  SET_FOCUS_TOP_KEYWORD,
  SET_REPORT,
  TOGGLE_REPORT_MAIN_RECOMMENDATION,
  TOGGLE_REPORT_RECOMMENDATION,
  TOGGLE_SIDE_PANEL,
  UPDATE_FOCUS_TOP_KEYWORD,
} from '@/store/actions/actionTypes';

const INITIAL_STATE = {
  creditLimitDialogType: null,
  focusTopKeyword: {},
  isCreditLimitDialogOpen: false,
  isSidePanelOpen: true,
  recommendations: {},
  reportLastUpdate: null,
  score: 0,
};

/**
 * sort the object of each recommendation
 *
 * @param {object} object
 *
 * @returns {object}
 */
function setRecommendation(object) {
  return {
    data: object.data,
    disabled: object.disabled,
    ready: object.ready,
    recommendationsIds: [],
    score: object.score,
    underlyingData: object.underlyingData,
    weight: object.weight,
  };
}

/**
 * build the recommendations tree
 *
 * @param {array} recommendationsList
 * @param {object} state
 *
 * @returns {object}
 */
function setRecommendationsTree(recommendationsList, recommendations) {
  recommendationsList.forEach((e) => {
    recommendations[e.key] = setRecommendation(e);

    e.recommendations.forEach((o) => {
      recommendations[o.key] = setRecommendation(o);
      recommendations[e.key].recommendationsIds.push(o.key);
    });
  });

  return {
    recommendations,
  };
}

/**
 * refresh scorers scores
 *
 * @param {array} recommendationsList
 * @param {object} recommendations
 *
 * @returns {array}
 */
function setRecommendationsTreeScores(recommendationsList, recommendations) {
  recommendationsList.forEach((e) => {
    recommendations[e.key].score = e.score;
    recommendations[e.key].weight = e.weight;
    recommendations[e.key].data = e.data;
    recommendations[e.key].disabled = e.disabled;

    e.recommendations.forEach((o) => {
      if (recommendations[o.key]) {
        recommendations[o.key].score = o.score;
        recommendations[o.key].weight = o.weight;
      } else {
        // Report after Disable or enable an Article Length
        // Sends a new article length recommendation
        recommendations[e.key].recommendationsIds = [
          ...recommendations[e.key].recommendationsIds,
          o.key,
        ];
        recommendations[o.key] = setRecommendation(o);
      }
    });
  });

  return recommendations;
}

export default function report(state = INITIAL_STATE, action) {
  let disabled = null;

  switch (action.type) {
    case SET_REPORT:
      const isInitialSet = action.initialSet;
      const newState = {
        id: state.id,
        recommendations: state.recommendations,
        score: state.score,
      };

      const { topKeyword: focusTopKeyword = {} } = action?.report || {};

      if (isInitialSet) {
        const { recommendations } = setRecommendationsTree(
          action.report.recommendations,
          state.recommendations
        );
        newState.id = action.report.id;
        newState.score = action.report.score;
        newState.recommendations = recommendations;
      } else {
        newState.score = action.report.score;
        newState.recommendations = setRecommendationsTreeScores(
          action.report.recommendations,
          state.recommendations
        );
      }

      return {
        ...state,
        ...newState,
        focusTopKeyword: { ...focusTopKeyword },
        reportLastUpdate: Date.now(),
      };
    case SET_FOCUS_TOP_KEYWORD: {
      const { focusTopKeyword = {} } = action || {};
      return {
        ...state,
        focusTopKeyword: { ...focusTopKeyword },
      };
    }
    case UPDATE_FOCUS_TOP_KEYWORD: {
      const { analyzed } = action || {};
      return {
        ...state,
        focusTopKeyword: { ...state.focusTopKeyword, analyzed },
      };
    }
    case RESET_REPORT:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    case TOGGLE_REPORT_MAIN_RECOMMENDATION:
      disabled = !state.recommendations[action.key].disabled;

      const newRecommendations = {
        recommendations: {
          ...state.recommendations,
          [action.key]: { ...state.recommendations[action.key], disabled },
        },
      };

      return {
        ...state,
        ...newRecommendations,
        reportLastUpdate: Date.now(),
      };
    case TOGGLE_REPORT_RECOMMENDATION:
      disabled = !state.recommendations[action.key].disabled;

      return {
        ...state,
        recommendations: {
          ...state.recommendations,
          [action.mainKey]: {
            ...state.recommendations[action.mainKey],
            recommendationsIds: state.recommendations[action.mainKey].recommendationsIds,
          },
          [action.key]: {
            ...state.recommendations[action.key],
            disabled,
          },
        },
        reportLastUpdate: Date.now(),
      };
    case CLOSE_SIDE_PANEL:
      return {
        ...state,
        isSidePanelOpen: false,
      };
    case OPEN_SIDE_PANEL:
      return {
        ...state,
        isSidePanelOpen: true,
      };
    case TOGGLE_SIDE_PANEL:
      return {
        ...state,
        isSidePanelOpen: !state.isSidePanelOpen,
      };
    case OPEN_CREDIT_LIMIT_DIALOG:
      return {
        ...state,
        creditLimitDialogType: action.payload?.creditType,
        isCreditLimitDialogOpen: true,
      };
    case CLOSE_CREDIT_LIMIT_DIALOG:
      return {
        ...state,
        creditLimitDialogType: null,
        isCreditLimitDialogOpen: false,
      };
    default:
      return state;
  }
}
