import { marked } from 'marked';

import { ERROR_CODE_INSUFFICIENT_CREDITS } from '@/apis/semji/aiWriting/const';
import {
  ERROR_MESSAGE_CREDIT_LIMIT,
  ERROR_MESSAGE_RATE_LIMIT,
  ERROR_MESSAGE_STREAM_FAIL,
  STREAM_EVENT_ERROR,
} from '@/apis/semji/aiWriting/useStreamModel/const';
import { PostStreamParameters } from '@/apis/semji/aiWriting/useStreamModel/useStreamModel.types';
import {
  GENERATION_METADESCRIPTION_TYPE,
  GENERATION_OUTLINE_TYPE,
  GENERATION_TITLE_TYPE,
} from '@/containers/Content/EditorComponents/Ai/constants';
import { AI_WRAPPER_SELECTOR } from '@/containers/Content/TinyMceComponents/Editor/hooks/useAiWriting/useAIWrappper/useAiWrapper';
import { AI_WRITING_NODE } from '@/containers/Content/TinyMceComponents/Editor/hooks/useAiWriting/useAiWriting';
import { FACT_CHECK_GENERATED_CONTENT_SELECTOR } from '@/containers/Content/TinyMceComponents/Editor/hooks/useAiWriting/useFactCheck/const';
import {
  COOKIE_IMPERSONATE_KEY_NAME,
  COOKIE_TOKEN_KEY_NAME,
  getCookie,
} from '@/utils/cookies/cookies';

export const postStreamModel = async (props: PostStreamParameters, options: any) => {
  const token = getCookie(COOKIE_TOKEN_KEY_NAME);
  const userImpersonate = getCookie(COOKIE_IMPERSONATE_KEY_NAME);

  function inputWriting(html: string) {
    let callback;

    switch (props.type) {
      case GENERATION_METADESCRIPTION_TYPE:
        callback = props.handleStreamWriteMetaDescription;
        break;
      case GENERATION_TITLE_TYPE:
        callback = props.handleStreamWriteTitle;
        break;
      default:
        break;
    }

    callback?.({
      contents: { attrs: {}, html },
      streamingEnd: false,
    });
  }

  function inputWritingStop(html: string, streamId: string) {
    let callback;

    switch (props.type) {
      case GENERATION_METADESCRIPTION_TYPE:
        callback = props.handleStreamWriteMetaDescription;
        break;
      case GENERATION_TITLE_TYPE:
        callback = props.handleStreamWriteTitle;
        break;
      default:
        break;
    }

    callback?.({
      contents: { attrs: {}, html },
      streamId,
      streamingEnd: true,
    });
  }

  function editorWriting({
    html,
    node,
    streamId,
    streamingEnd,
  }: {
    html: string;
    node?: Element;
    streamingEnd?: boolean;
    streamId?: string;
  }) {
    if (props.handleStreamWrite) {
      props.handleStreamWrite({
        contents: { attrs: {}, html: html, name: 'p' },
        ...(node && { node }),
        ...(streamingEnd && { streamingEnd }),
        ...(streamId && { streamId }),
        type: props.type,
      });
    }
  }

  async function streamModel(): Promise<Response> {
    return await fetch(props.url, {
      body: JSON.stringify(props.payload),
      headers: {
        'Content-Type': 'application/ld+json; charset=utf-8',
        ...(!!token ? { Authorization: `Bearer ${token}` } : {}),
        ...(!!userImpersonate
          ? {
              'X-Switch-User': userImpersonate,
            }
          : {}),
      },
      method: 'POST',
      ...options,
    });
  }

  try {
    // We use default fetch because body.getReader() does not work with axios api
    const response: Response & { error_code?: string } = await streamModel();

    if (response.status === 429) {
      throw new Error(ERROR_MESSAGE_RATE_LIMIT);
    }
    if (response.status === 403 && response?.error_code === ERROR_CODE_INSUFFICIENT_CREDITS) {
      throw new Error(ERROR_MESSAGE_CREDIT_LIMIT);
    }
    if (response.status !== 200) {
      throw new Error(JSON.stringify(response));
    }

    // Manual node target de AI Wrapper
    const manualNode = props.editorRef?.current?.dom?.select('div.' + AI_WRAPPER_SELECTOR);

    // Auto node target the beginning of the editor
    let autoNode: Element | undefined = undefined;
    if (props.isFullDraft) {
      autoNode = props.editorRef.current.dom.create('p', { class: AI_WRITING_NODE }, '');
      props.editorRef.current.dom.insertAfter(
        autoNode,
        props.editorRef.current.dom.select('div.ai-loader-wrapper')[0]
      );
    }
    const targetNode = props.isFullDraft ? autoNode : manualNode;

    const reader = response.body?.getReader();
    const streamId = response.headers.get('X-Semji-Generated-Content-Id');
    let generatedContent = '';

    let truncatedData = '';
    let formattedHtml = '';

    if ([GENERATION_METADESCRIPTION_TYPE, GENERATION_TITLE_TYPE].includes(props.type)) {
      props.textareaMetaDescriptionRef.current.setAttribute('style', '');
      props.inputTitleRef.current.setAttribute('style', '');
    }

    while (true) {
      const readableStream = await reader?.read();
      if (readableStream?.done) {
        if ([GENERATION_METADESCRIPTION_TYPE, GENERATION_TITLE_TYPE].includes(props.type)) {
          if (props.isFullDraft) {
            if (props.type === GENERATION_METADESCRIPTION_TYPE) {
              props.textareaMetaDescriptionRef.current.dispatchEvent(
                new Event('input', { bubbles: true })
              );
            }
            if (props.type === GENERATION_TITLE_TYPE) {
              props.inputTitleRef.current.dispatchEvent(new Event('input', { bubbles: true }));
            }
          } else {
            inputWritingStop(formattedHtml, streamId as string);
          }
        } else {
          if (props.isFullDraft) {
            const arrayChildNodes = Array.from(targetNode.childNodes);
            arrayChildNodes.forEach((childNode: any) => {
              if (childNode.nodeName !== '#text') {
                if (props.lastGenerationRef?.current?.nodes) {
                  props.lastGenerationRef.current.nodes.push(childNode);
                }
              }
            });
            if (props.lastGenerationRef.current.type !== GENERATION_OUTLINE_TYPE) {
              const div = props.editorRef.current.dom.create(
                'div',
                {
                  class: `${FACT_CHECK_GENERATED_CONTENT_SELECTOR}-${streamId} ${FACT_CHECK_GENERATED_CONTENT_SELECTOR}`,
                },
                ''
              );
              if (props.lastGenerationRef?.current?.nodes) {
                props.lastGenerationRef.current.nodes.forEach((node) => {
                  div.append(node);
                });
              }
              const generatedContent = props.editorRef.current.dom.select('p.semji-ai-writing')[0];
              props.editorRef.current.dom.insertAfter(div, generatedContent);
              props.editorRef.current.dom.remove(targetNode);
            } else {
              if (props.lastGenerationRef?.current?.nodes) {
                let previousNode: Element | undefined = undefined;
                props.lastGenerationRef.current.nodes.forEach((node) => {
                  previousNode = props.editorRef.current.dom.insertAfter(
                    node,
                    previousNode ?? targetNode
                  );
                });
              }
              props.editorRef.current.dom.remove(targetNode);
            }
          } else {
            const loaderElement = document.querySelector('div.ai-loader-wrapper');
            const arrayChildNodes = Array.from((loaderElement as Element).childNodes);
            arrayChildNodes.forEach((childNode) => {
              if (childNode.nodeName !== '#text') {
                if (props.lastGenerationRef?.current?.nodes) {
                  props.lastGenerationRef.current.nodes.push(childNode);
                }
              }
            });
          }
          editorWriting({
            html: formattedHtml,
            node: targetNode,
            streamId: streamId || '',
            streamingEnd: true,
          });
        }
        break;
      }

      if (readableStream?.value) {
        let cleanedValue = readableStream.value.filter((v) => v !== 32);
        let streamChunk = new TextDecoder().decode(cleanedValue);
        streamChunk = truncatedData + streamChunk;
        try {
          streamChunk = decodeURIComponent(streamChunk);
        } catch (e) {
          truncatedData = streamChunk;
          continue;
        }
        truncatedData = '';

        if (streamChunk === '') {
          continue;
        }
        generatedContent += streamChunk;

        // Used to detect stream fail when already started
        if (generatedContent.includes(STREAM_EVENT_ERROR)) {
          throw new Error(ERROR_MESSAGE_STREAM_FAIL);
        }

        if ([GENERATION_METADESCRIPTION_TYPE, GENERATION_TITLE_TYPE].includes(props.type)) {
          if (props.isFullDraft) {
            if (props.type === GENERATION_METADESCRIPTION_TYPE) {
              if (props.textareaMetaDescriptionRef.current.value === ' ') {
                props.textareaMetaDescriptionRef.current.value = '';
              }
              props.textareaMetaDescriptionRef.current.value =
                props.textareaMetaDescriptionRef.current.value + streamChunk;
            }
            if (props.type === GENERATION_TITLE_TYPE) {
              if (props.inputTitleRef.current.value === ' ') {
                props.inputTitleRef.current.value = '';
              }
              props.inputTitleRef.current.value = props.inputTitleRef.current.value + streamChunk;
            }
            generatedContent += streamChunk;
          } else {
            formattedHtml = generatedContent;
            inputWriting(formattedHtml);
          }
        } else {
          formattedHtml = marked.parse(generatedContent, { headerIds: false, mangle: false });
          editorWriting({ html: formattedHtml, node: targetNode });
        }
      }
    }

    return { generatedContent, streamId };
  } catch (e) {
    throw e;
  }
};
