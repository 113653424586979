import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import billing from '@/store/reducers/billing';
import brandVoiceByWorkspaceId from '@/store/reducers/brandVoiceByWorkspaceId';
import configurations from '@/store/reducers/configurations';
import content from '@/store/reducers/content';
import createWorkspace from '@/store/reducers/createWorkspace';
import debug from '@/store/reducers/debug';
import defaultHub from '@/store/reducers/defaultHub';
import defaultOrganizationId from '@/store/reducers/defaultOrganizationId';
import defaultWorkspaceId from '@/store/reducers/defaultWorkspaceId';
import devReducer from '@/store/reducers/dev';
import dialogs from '@/store/reducers/dialogs';
import editor from '@/store/reducers/editor';
import organizations, * as fromOrganizations from '@/store/reducers/organizations';
import outline from '@/store/reducers/outline';
import report from '@/store/reducers/report';
import searchIntelligence from '@/store/reducers/searchIntelligence';
import { serviceIntegrations } from '@/store/reducers/serviceIntegrations';
import signup from '@/store/reducers/signup';
import { ui, uiMode } from '@/store/reducers/ui';
import user from '@/store/reducers/user';
import userConfiguration from '@/store/reducers/userConfiguration';
import workspaceConfig from '@/store/reducers/workspaceConfig';
import workspaceRouteHistory from '@/store/reducers/workspaceRouteHistory';
import workspaces, * as fromWorkspaces from '@/store/reducers/workspaces';
import {
  COOKIE_IMPERSONATE_KEY_NAME,
  COOKIE_REFRESH_TOKEN_KEY_NAME,
  COOKIE_TOKEN_KEY_NAME,
  removeCookieByKey,
} from '@/utils/cookies/cookies';

export const reducers = {
  billing,
  brandVoiceByWorkspaceId,
  configurations,
  content,
  debug,
  defaultHub,
  defaultOrganizationId,
  defaultWorkspaceId,
  dialogs,
  editor,
  organizations,
  outline,
  report,
  searchIntelligence,
  serviceIntegrations,
  signup,
  ui,
  uiMode,
  user,
  userConfiguration,
  workspaceConfig,
  workspaceRouteHistory,
  workspaces,
};

export const getReducers = (reducers) => {
  const combinedReducers = combineReducers({ ...reducers });

  return (state, action) => {
    let output = {};

    if (action.type === 'LOGOUT_USER') {
      removeCookieByKey(COOKIE_TOKEN_KEY_NAME);
      removeCookieByKey(COOKIE_REFRESH_TOKEN_KEY_NAME);
      removeCookieByKey(COOKIE_IMPERSONATE_KEY_NAME);

      // for all keys defined in your persistConfig(s)
      storage.removeItem('persist:root');
      localStorage.removeItem('i18nextLng');

      // Reset all states except createWorkspace and configurations;
      const {
        createWorkspace,
        debug,
        configurations,
        defaultOrganizationId,
        defaultWorkspaceId,
        defaultHub,
        searchIntelligence,
      } = state;

      output = combinedReducers(
        {
          configurations,
          createWorkspace,
          debug,
          defaultHub,
          defaultOrganizationId,
          defaultWorkspaceId,
          searchIntelligence,
        },
        action
      );
    } else {
      output = combinedReducers(state, action);
    }

    // Handle special actions only available in dev (removed from code during build)
    output = devReducer(output, action);
    return output;
  };
};

const dataPersistConfig = {
  blacklist: ['createWorkspace'],
  key: 'data',
  migrate: (state) => {
    let persistedWorkspaceConfig = { ...state };
    delete persistedWorkspaceConfig?.dataSources;
    delete persistedWorkspaceConfig?.location;
    delete persistedWorkspaceConfig?.organizationId;
    delete persistedWorkspaceConfig?.users;
    delete persistedWorkspaceConfig?.workspace;
    return Promise.resolve(persistedWorkspaceConfig);
  },
  storage: storage,
  version: 1,
};

const PERSIST_VERSION = 3;
export default persistReducer(
  {
    key: 'root',
    migrate: (state) => {
      // migrate old key metricsConfig to new key userConfiguration
      if (state._persist.version === PERSIST_VERSION) {
        return Promise.resolve(state);
      }

      let migratedState = { ...state };
      if (migratedState?.metricsConfig) {
        migratedState = {
          ...migratedState,
          userConfiguration: {
            ...migratedState.metricsConfig,
            factCheckingEnabled: false,
            highlightCommentEnabled: true,
            highlightFactCheckingEnabled: true,
          },
        };
      }

      if (migratedState?.userConfiguration) {
        migratedState = {
          ...migratedState,
          userConfiguration: {
            ...migratedState.userConfiguration,
            factCheckingEnabled: false,
            highlightCommentEnabled: true,
            highlightFactCheckingEnabled: true,
          },
        };
      }
      return Promise.resolve(migratedState);
    },
    storage: storage,
    version: PERSIST_VERSION,
    whitelist: [
      'brandVoiceByWorkspaceId',
      'configurations',
      'debug',
      'defaultHub',
      'defaultOrganizationId',
      'defaultWorkspaceId',
      'userConfiguration',
      'signup',
      'uiMode',
      'user',
      'workspaceRouteHistory',
    ],
  },
  getReducers({ ...reducers, createWorkspace: persistReducer(dataPersistConfig, createWorkspace) })
);

export const getOrganizationById = (state, id) =>
  fromOrganizations.getById(state.organizations, id);

export const getOrganizationsDictionary = (state) =>
  fromOrganizations.getDictionary(state.organizations);

export const getWorkspaceById = (state, id) => fromWorkspaces.getById(state.workspaces, id);
