import './Header.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import HeaderTitle from '@/containers/Competitors/components/HeaderTitle';
export default function Header() {
  const { t } = useTranslation();

  return (
    <HeaderTitle
      description={t('competitors:reports.header.description')}
      title={t('competitors:reports.header.title')}
    />
  );
}
