import styled from 'styled-components/macro';

const Block = styled.div`
  width: ${({ width }) => width || '100%'};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin: 0 0 20px 0;
`;

export default Block;
