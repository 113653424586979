import './Grid.scss';

import React from 'react';

import { GridProps } from '@/components/AGGrid/Grid.types';
import GridBody from '@/components/AGGrid/GridBody/GridBody';
import GridFooter from '@/components/AGGrid/GridFooter/GridFooter';
import { useGridPagination } from '@/components/AGGrid/hooks/useGridPagination';
import { useGridSorts } from '@/components/AGGrid/hooks/useGridSorts';
import { useGridState } from '@/components/AGGrid/hooks/useGridState';

// Documentation: https://www.notion.so/semji/Tableau-et-filtrage-5d442697113c4ce194d9aa79e6e8354d
function Grid({
  url,
  colDefs,
  gridSelection,
  masterCacheKey,
  cacheStalingTime,
  filterHook,
  gridSelectionHook,
}: GridProps) {
  const paginationHook = useGridPagination({ filterHook });
  const sortingHook = useGridSorts({ filterHook });
  const gridStateHook = useGridState({
    cacheStalingTime,
    filterHook,
    masterQueryKeys: masterCacheKey,
    paginationHook,
    url,
  });

  return (
    <div className="grid-container">
      <GridBody
        colDefs={colDefs}
        filterHook={filterHook}
        gridSelection={gridSelection}
        gridSelectionHook={gridSelectionHook}
        gridStateHook={gridStateHook}
        paginationHook={paginationHook}
        sortingHook={sortingHook}
      />
      <GridFooter gridStateHook={gridStateHook} paginationHook={paginationHook} />
    </div>
  );
}

export default Grid;
