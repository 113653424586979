import { nanoid } from 'nanoid';
import { useMemo } from 'react';

export default function User2Icon() {
  const clipId = useMemo(() => nanoid(), []);
  const maskId = useMemo(() => nanoid(), []);

  return (
    <svg
      fill="currentColor"
      height="17"
      viewBox="0 0 17 17"
      width="17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#${clipId})`}>
        <mask
          height="17"
          id={maskId}
          maskUnits="userSpaceOnUse"
          style={{ maskType: 'alpha' }}
          width="19"
          x="-1"
          y="0"
        >
          <path
            d="M17 16.488C16.937 13.801 14.222 11.489 10.479 10.879V9.505C10.971 9.032 11.321 8.298 11.55 7.672C11.882 7.506 12.174 7.136 12.344 6.639C12.582 5.951 12.49 5.316 12.138 5.01C12.166 4.772 12.184 4.529 12.153 4.287C12.074 3.624 12.218 3.249 12.347 2.919C12.453 2.642 12.576 2.328 12.453 1.974C12.011 0.701 10.726 0 8.83501 0L8.57101 0.005C7.25801 0.052 6.86401 0.605 6.60001 1.12C6.56701 1.182 6.52301 1.266 6.52301 1.271C4.81101 1.424 4.82601 2.84 4.83901 3.978L4.84201 4.347C4.84201 4.552 4.85101 4.766 4.86801 4.986C4.44301 5.286 4.36401 5.991 4.68901 6.723C4.87401 7.138 5.14101 7.452 5.43801 7.615C5.68101 8.289 6.06301 9.085 6.61701 9.58V10.863C2.81901 11.452 0.0630066 13.77 6.5919e-06 16.488L-0.0119934 17H17.011L17 16.488ZM1.05401 16C1.44601 13.906 3.91301 12.179 7.17601 11.796L7.61701 11.744V9.078L7.40101 8.928C7.00801 8.656 6.61001 7.981 6.31101 7.077L6.22801 6.796L5.93401 6.745C5.88101 6.726 5.72601 6.592 5.60401 6.317C5.52901 6.149 5.50001 6.005 5.49201 5.902L6.00201 6.045L5.90601 5.296C5.86401 4.966 5.84201 4.645 5.84201 4.346L5.83901 3.966C5.82401 2.625 5.89001 2.332 6.61201 2.267C7.15701 2.219 7.36401 1.818 7.48801 1.578C7.63801 1.286 7.76801 1.035 8.60801 1.004L8.83501 1C9.66401 1 11.114 1.169 11.504 2.282C11.504 2.325 11.452 2.459 11.414 2.557C11.269 2.931 11.05 3.496 11.16 4.41C11.184 4.598 11.153 4.834 11.12 5.085L11.031 5.89L11.472 5.842C11.48 5.946 11.468 6.111 11.397 6.314C11.3 6.603 11.155 6.752 11.16 6.768H10.8L10.686 7.11C10.403 7.963 10.036 8.607 9.67701 8.878L9.47901 9.028V11.754L9.91701 11.809C13.128 12.21 15.558 13.932 15.947 16.001H1.05401V16Z"
            fill="black"
          />
        </mask>
        <g mask={`url(#${maskId})`}>
          <rect height="20" width="20" x="-2" y="-1" />
        </g>
      </g>
      <defs>
        <clipPath id={clipId}>
          <rect height="17" width="17" />
        </clipPath>
      </defs>
    </svg>
  );
}
