import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { capitalize } from 'semji-core/utils/string';
import styled from 'styled-components/macro';

import { ColoredDot } from '@/components/_common';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import { noop } from '@/utils/noop';

import { StyledListItem } from './SelectComponent';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      marginTop: '2em',
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 182,
    },
  },
};

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  border-style: inherit;
  min-width: 0;
  justify-content: ${(props) => props.shortVersion && 'center'};
`;

const StyledColorDot = styled(ColoredDot)`
  margin: 8px 12px 8px 8px;
`;

const StyledSelect = styled(({ children, autoCursor, hideInput, ...props }) => (
  <Select
    classes={{ disabled: 'disabled', root: 'root', selectMenu: 'selectMenu' }}
    IconComponent={noop}
    {...props}
  >
    {children}
  </Select>
))`
  .root {
    color: ${(props) => props.theme.colors.greyOpaque};
    margin: 0;
    z-index: 999;
  }
  && {
    align-items: center;
    border-radius: 18px;
    display: inline-flex;
    flex: 1 1 auto;
    min-width: 0;
    padding: 3px;
    position: relative;
    transition:
      0.2s box-shadow,
      0.2s color;
    .selectMenu {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0;
      background-color: transparent;
    }
    .disabled {
      cursor: ${(props) => (props.autoCursor ? 'auto' : 'not-allowed')};
    }
  }
  &&&:hover {
    background-color: ${(props) =>
      !props.$isCustomRenderValue &&
      !props.$shortVersion &&
      !props.disabled &&
      props.theme.cssColors.dark005};
    border-style: solid;
    cursor: ${(props) => (props.autoCursor ? 'auto' : props.disabled ? 'not-allowed' : 'pointer')};
  }
`;

const StyledListItemText = styled((props) => (
  <ListItemText {...props} classes={{ primary: 'primary' }} />
))`
  && {
    .primary {
      color: inherit;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-weight: ${({ theme }) => theme.textCss.weights.normal};
      font-size: ${({ theme }) => theme.textCss.sizes.sm};
      color: ${({ theme }) => theme.cssColors.dark080};
    }
  }
`;

/*
 * DEPRECATED : use component from semji-core
 */
function StatusSelect({
  renderValue,
  handleChange,
  options,
  value,
  displayEmpty,
  enableChangeMode,
  shortVersion,
  ...props
}) {
  const [localValue, setLocalValue] = useState();

  function handleClick(event) {
    const id = event.target.value;
    const publishStatus = options.filter((o) => o.publish)[0];

    if (publishStatus?.id !== id || enableChangeMode) {
      setLocalValue(displayEmpty ? null : id);
    }

    handleChange(id);
  }

  function handleRenderValue(value) {
    if (renderValue) {
      return renderValue;
    }
    if (options.length) {
      const status = options.find((o) => o.id === value);
      return !!status ? (
        <Wrapper shortVersion={shortVersion}>
          <StyledColorDot color={status.color} />
          {!shortVersion && (
            <TooltipComponent
              placement="top"
              style={{ minWidth: 0 }}
              title={capitalize(status.label)}
            >
              <StyledListItemText primary={capitalize(status.label)} />
            </TooltipComponent>
          )}
        </Wrapper>
      ) : null;
    }
    return null;
  }

  function renderOptions() {
    return options.map((status) => (
      <StyledListItem key={status.id} alignItems="center" value={status.id}>
        <StyledColorDot color={status.color} />
        <TooltipComponent style={{ minWidth: 0 }} title={capitalize(status.label)}>
          <StyledListItemText primary={capitalize(status.label)} />
        </TooltipComponent>
      </StyledListItem>
    ));
  }

  return (
    <StyledSelect
      $isCustomRenderValue={!!renderValue}
      $shortVersion={shortVersion}
      input={<Input disableUnderline />}
      MenuProps={MenuProps}
      renderValue={handleRenderValue}
      value={localValue || value || !!renderValue}
      onChange={handleClick}
      {...props}
    >
      {renderOptions()}
    </StyledSelect>
  );
}

StatusSelect.propTypes = {
  defaultSelect: PropTypes.shape({
    color: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default StatusSelect;
