import React from 'react';

const LogoWhite = (props) => (
  <svg
    fill="none"
    height={props.height ?? '25'}
    viewBox="0 0 79 25"
    width={props.width ?? '79'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.7008 19.2001C21.9008 18.8001 21.3008 18.4001 20.9008 17.9001C20.5008 17.4001 20.3008 17.0001 20.3008 16.7001C20.3008 16.5001 20.4008 16.4001 20.5008 16.3001C20.6008 16.2001 20.8008 16.2001 20.9008 16.2001H21.3008C21.4008 16.2001 21.5008 16.2001 21.6008 16.3001C21.7008 16.3001 21.8008 16.4001 21.9008 16.6001C22.3008 17.2001 22.8008 17.6001 23.3008 17.9001C23.9008 18.2001 24.6008 18.4001 25.6008 18.4001C26.7008 18.4001 27.6008 18.2001 28.3008 17.8001C29.0008 17.4001 29.3008 16.8001 29.3008 16.0001C29.3008 15.5001 29.2008 15.1001 28.9008 14.8001C28.6008 14.5001 28.2008 14.2001 27.6008 14.0001C27.0008 13.8001 26.1008 13.5001 24.9008 13.2001C23.3008 12.8001 22.2008 12.4001 21.7008 11.7001C21.1008 11.1001 20.8008 10.3001 20.8008 9.3001C20.8008 8.6001 21.0008 8.0001 21.3008 7.4001C21.7008 6.8001 22.2008 6.3001 22.9008 6.0001C23.6008 5.6001 24.5008 5.4001 25.5008 5.4001C26.6008 5.4001 27.5008 5.6001 28.2008 5.9001C28.9008 6.3001 29.5008 6.7001 29.9008 7.2001C30.3008 7.7001 30.4008 8.1001 30.4008 8.4001C30.4008 8.6001 30.3008 8.7001 30.2008 8.8001C30.1008 8.9001 29.9008 8.9001 29.8008 8.9001H29.4008C29.2008 8.9001 29.0008 8.8001 28.8008 8.6001C28.4008 8.1001 28.0008 7.7001 27.6008 7.4001C27.1008 7.1001 26.4008 7.0001 25.5008 7.0001C24.5008 7.0001 23.7008 7.2001 23.2008 7.6001C22.7008 8.0001 22.4008 8.6001 22.4008 9.3001C22.4008 9.7001 22.5008 10.1001 22.7008 10.4001C22.9008 10.7001 23.3008 10.9001 23.9008 11.2001C24.5008 11.4001 25.3008 11.7001 26.5008 11.9001C28.2008 12.3001 29.3008 12.8001 30.0008 13.4001C30.7008 14.0001 31.0008 14.9001 31.0008 15.9001C31.0008 16.6001 30.8008 17.3001 30.4008 17.9001C30.0008 18.5001 29.4008 19.0001 28.6008 19.3001C27.8008 19.7001 26.8008 19.8001 25.7008 19.8001C24.5008 19.8001 23.5008 19.6001 22.7008 19.2001Z"
      fill="white"
    />
    <path
      d="M34.9006 18.1001C33.9006 17.0001 33.3006 15.5001 33.1006 13.6001V12.5001V11.4001C33.2006 9.50009 33.8006 8.1001 34.8006 6.90009C35.8006 5.80009 37.2006 5.2001 38.9006 5.2001C40.8006 5.2001 42.2006 5.8001 43.2006 7.0001C44.2006 8.2001 44.8006 9.9001 44.8006 12.0001V12.4001C44.8006 12.6001 44.7006 12.7001 44.6006 12.8001C44.5006 12.9001 44.3006 13.0001 44.2006 13.0001H34.7006V13.3001C34.7006 14.1001 34.9006 14.9001 35.3006 15.6001C35.6006 16.3001 36.1006 16.9001 36.8006 17.3001C37.4006 17.7001 38.2006 18.0001 39.0006 18.0001C40.0006 18.0001 40.8006 17.8001 41.4006 17.4001C42.0006 17.0001 42.5006 16.6001 42.7006 16.2001C42.9006 16.0001 43.0006 15.9001 43.1006 15.8001C43.2006 15.7001 43.3006 15.7001 43.5006 15.7001H43.9006C44.1006 15.7001 44.2006 15.7001 44.3006 15.8001C44.4006 15.9001 44.5006 16.0001 44.5006 16.1001C44.5006 16.5001 44.3006 16.9001 43.8006 17.4001C43.3006 17.9001 42.7006 18.4001 41.8006 18.8001C41.0006 19.6001 40.0006 19.8001 39.0006 19.8001C37.3006 19.8001 35.9006 19.2001 34.9006 18.1001ZM43.3006 11.7001V11.6001C43.3006 10.2001 42.9006 9.1001 42.1006 8.2001C41.3006 7.3001 40.3006 6.90009 39.0006 6.90009C37.7006 6.90009 36.7006 7.3001 35.9006 8.2001C35.1006 9.1001 34.8006 10.2001 34.8006 11.6001V11.7001H43.3006Z"
      fill="white"
    />
    <path
      d="M48.1004 19.3001C48.0004 19.2001 47.9004 19.1001 47.9004 18.9001V6.20009C47.9004 6.00009 48.0004 5.90009 48.1004 5.80009C48.2004 5.70009 48.3004 5.60009 48.5004 5.60009H49.0004C49.2004 5.60009 49.3004 5.70009 49.4004 5.80009C49.5004 5.90009 49.6004 6.00009 49.6004 6.20009V7.10009C50.1004 6.50009 50.6004 6.10009 51.2004 5.80009C51.8004 5.50009 52.5004 5.30009 53.4004 5.30009C55.4004 5.30009 56.8004 6.10009 57.7004 7.80009C58.1004 7.00009 58.7004 6.40009 59.5004 6.00009C60.3004 5.50009 61.2004 5.30009 62.2004 5.30009C63.7004 5.30009 64.9004 5.80009 65.8004 6.80009C66.7004 7.80009 67.2004 9.20009 67.2004 11.1001V18.9001C67.2004 19.1001 67.1004 19.2001 67.0004 19.3001C66.9004 19.4001 66.8004 19.5001 66.6004 19.5001H66.1004C65.9004 19.5001 65.8004 19.4001 65.7004 19.3001C65.6004 19.2001 65.5004 19.1001 65.5004 18.9001V11.4001C65.5004 9.80009 65.2004 8.60009 64.5004 7.90009C63.8004 7.20009 63.0004 6.90009 61.9004 6.90009C60.9004 6.90009 60.1004 7.30009 59.4004 8.00009C58.7004 8.70009 58.3004 9.9001 58.3004 11.5001V19.0001C58.3004 19.2001 58.2004 19.3001 58.1004 19.4001C58.0004 19.5001 57.9004 19.6001 57.7004 19.6001H57.2004C57.0004 19.6001 56.9004 19.5001 56.8004 19.4001C56.7004 19.3001 56.6004 19.2001 56.6004 19.0001V11.5001C56.6004 9.9001 56.3004 8.70009 55.6004 8.00009C54.9004 7.30009 54.0004 6.90009 53.0004 6.90009C52.0004 6.90009 51.2004 7.30009 50.5004 8.00009C49.8004 8.70009 49.4004 9.80009 49.4004 11.4001V18.9001C49.4004 19.1001 49.3004 19.2001 49.2004 19.3001C49.1004 19.4001 49.0004 19.5001 48.8004 19.5001H48.3004C48.3004 19.5001 48.2004 19.5001 48.1004 19.3001Z"
      fill="white"
    />
    <path
      d="M68.1004 24.4001C68.0004 24.3001 67.9004 24.2001 67.9004 24.0001V23.6001C67.9004 23.4001 68.0004 23.3001 68.1004 23.2001C68.2004 23.1001 68.3004 23.0001 68.5004 23.0001H68.6004C69.6004 23.0001 70.2004 22.8001 70.5004 22.3001C70.8004 21.8001 71.0004 21.1001 71.0004 20.1001V6.2001C71.0004 6.0001 71.1004 5.9001 71.2004 5.8001C71.3004 5.7001 71.4004 5.6001 71.6004 5.6001H72.1004C72.3004 5.6001 72.4004 5.7001 72.5004 5.8001C72.6004 5.9001 72.7004 6.0001 72.7004 6.2001V20.1001C72.7004 21.5001 72.4004 22.6001 71.8004 23.4001C71.2004 24.2001 70.2004 24.6001 68.6004 24.6001H68.5004C68.4004 24.6001 68.2004 24.5001 68.1004 24.4001ZM70.9004 2.6001C70.8004 2.5001 70.7004 2.4001 70.7004 2.2001V1.2001C70.7004 1.0001 70.8004 0.900098 70.9004 0.800098C71.0004 0.700098 71.1004 0.600098 71.3004 0.600098H72.3004C72.5004 0.600098 72.6004 0.700098 72.7004 0.800098C72.8004 0.900098 72.9004 1.1001 72.9004 1.2001V2.2001C72.9004 2.4001 72.8004 2.5001 72.7004 2.6001C72.6004 2.7001 72.4004 2.8001 72.3004 2.8001H71.3004C71.2004 2.8001 71.0004 2.7001 70.9004 2.6001Z"
      fill="white"
    />
    <path
      d="M76.4012 2.6001C76.3012 2.5001 76.2012 2.4001 76.2012 2.2001V1.2001C76.2012 1.0001 76.3012 0.900098 76.4012 0.800098C76.5012 0.700098 76.6012 0.600098 76.8012 0.600098H77.8012C78.0012 0.600098 78.1012 0.700098 78.2012 0.800098C78.3012 0.900098 78.4012 1.1001 78.4012 1.2001V2.2001C78.4012 2.4001 78.3012 2.5001 78.2012 2.6001C78.1012 2.7001 77.9012 2.8001 77.8012 2.8001H76.8012C76.6012 2.8001 76.5012 2.7001 76.4012 2.6001ZM76.7012 19.3001C76.6012 19.2001 76.5012 19.1001 76.5012 18.9001V6.2001C76.5012 6.0001 76.6012 5.9001 76.7012 5.8001C76.8012 5.7001 76.9012 5.6001 77.1012 5.6001H77.6012C77.8012 5.6001 77.9012 5.7001 78.0012 5.8001C78.1012 5.9001 78.2012 6.0001 78.2012 6.2001V18.9001C78.2012 19.1001 78.1012 19.2001 78.0012 19.3001C77.9012 19.4001 77.8012 19.5001 77.6012 19.5001H77.1012C76.9012 19.5001 76.8012 19.5001 76.7012 19.3001Z"
      fill="white"
    />
    <path d="M10.6002 19.5001L8.60019 15.9001L6.7002 19.5001H10.6002Z" fill="white" />
    <path
      d="M3.80039 19.5001L8.60039 10.7001L13.4004 19.5001H16.3004L8.60039 5.30009L0.900391 19.5001H3.80039Z"
      fill="white"
    />
  </svg>
);

export default LogoWhite;
