import './EditorPopper.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ENUM_MENU_ENTITY_TYPE, MenuDropdown } from 'semji-core/components/Menu';
import { useKeyPress } from 'semji-core/hooks/useKeyPress';

import useGetAllBrandVoicesByWorkspaceId from '@/apis/semji/brandVoices/useGetAllBrandVoicesByWorkspaceId';
import { BetaBadge } from '@/components/Badge/BetaBadge';
import {
  GENERATION_INTRODUCTION_TYPE,
  GENERATION_METADESCRIPTION_TYPE,
  GENERATION_OUTLINE_TYPE,
  GENERATION_PARAGRAPH_TYPE,
  GENERATION_TITLE_TYPE,
} from '@/containers/Content/EditorComponents/Ai/constants';
import useAiWritingCredit from '@/containers/Content/TinyMceComponents/Editor/hooks/useAiWritingCredit';
import {
  getBrandVoiceItems,
  getEditorAiWritingItems,
  getMetaDescriptionItem,
  getTitleItem,
} from '@/containers/Content/TinyMceComponents/Editor/hooks/useEditorPopper/EditorPopper/const';
import useApiConfigurations from '@/hooks/useApiConfigurations';
import { deleteBrandVoice, setBrandVoice } from '@/store/actions/brandVoiceByWorkspaceId';
import { showWarningSnackbar } from '@/store/actions/ui';
import useParamSelector from '@/store/hooks/useParamSelector';
import { selectBrandVoiceByWorkspaceId } from '@/store/selectors/selectBrandVoiceByWorkspaceId';
import { selectUserConfiguration } from '@/store/selectors/selectUserConfiguration';
import { selectCurrentWorkspaceAccessByKey } from '@/store/selectors/selectWorkspaceAccessByKey';
import { BrandVoiceModel } from '@/types/brandVoice/brandVoice.types';
import { STATUS_PENDING, STATUS_QUEUED, STATUS_SUCCESS } from '@/utils/analysis';
import {
  AI_WRITING_BRAND_VOICE_CREDITS_ENABLED,
  CONTENT_AI_WRITING_CREDITS_ENABLED,
} from '@/utils/configurations/constants';

import { MenuContentItem } from './EditorPopper.types';

function EditorPopper({
  position,
  editorRef,
  handleCloseEditorPopper,
  handleEscapeCloseEditorPopper,
  aiWriting,
  isEmpty,
  isTitle,
  isMetaDescription,
  setOpenAIWritingPopper,
  focusTopKeywordRef,
  handleBackspaceCloseEditorPopper,
  hasTitle = false,
}: any) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { factCheckingEnabled } = useSelector(selectUserConfiguration);
  const { workspaceId } = useParams();

  const aiWritingBrandVoiceCreditsEnabled = useApiConfigurations(
    AI_WRITING_BRAND_VOICE_CREDITS_ENABLED
  );
  const brandVoiceSelected = useParamSelector(selectBrandVoiceByWorkspaceId, workspaceId);

  useKeyPress('Backspace', handleBackspaceCloseEditorPopper);
  useKeyPress('Escape', handleEscapeCloseEditorPopper);
  useKeyPress(new RegExp('^.$'), handleSearch);

  const { getAiWritingCredit } = useAiWritingCredit();

  function handleSearch(e: React.KeyboardEvent) {
    handleCloseEditorPopper();
  }

  const accessToAiFeatures = useParamSelector(selectCurrentWorkspaceAccessByKey, {
    key: 'accessToAiFeatures',
    workspaceId: workspaceId,
  });

  const { data: brandVoices } = useGetAllBrandVoicesByWorkspaceId(workspaceId, {
    enabled: aiWriting.brandVoiceEnabled,
  });

  function isGenerationEnabled() {
    if (
      focusTopKeywordRef.current.id &&
      [STATUS_PENDING, STATUS_QUEUED].includes(focusTopKeywordRef.current.analysisStatus)
    ) {
      dispatch(showWarningSnackbar(t('content:ai-writing.error-wait-analysis')));
      return false;
    }
    if (
      !focusTopKeywordRef.current.id ||
      focusTopKeywordRef.current.analysisStatus !== STATUS_SUCCESS
    ) {
      dispatch(showWarningSnackbar(t('content:ai-writing.error-not-analyzed')));
      return false;
    }
    return true;
  }

  const aiWritingCreditsEnabled = useApiConfigurations(CONTENT_AI_WRITING_CREDITS_ENABLED);

  function handleGenerateAi(type: string) {
    if (!accessToAiFeatures) {
      aiWriting.aiContentPermission.setShowPermissionModal(true);
      handleCloseEditorPopper();
      return;
    }
    const creditAmount = getAiWritingCredit(type);
    if (isGenerationEnabled()) {
      if (
        aiWritingCreditsEnabled &&
        !aiWriting.hasUnlimitedAIWriting &&
        aiWriting.remainingAiWritingUnit < creditAmount
      ) {
        aiWriting.limitAiWriting.setShowLimitAiWriting(true);
        handleCloseEditorPopper();
      } else {
        aiWriting.lastGeneration.current.creditAmount = creditAmount;
        if (type === GENERATION_INTRODUCTION_TYPE) aiWriting.actions.handleGenerateIntroduction();
        if (type === GENERATION_OUTLINE_TYPE) aiWriting.actions.handleGenerateOutlines();
        if (type === GENERATION_PARAGRAPH_TYPE) aiWriting.actions.handleGenerateParagraph();
        if (type === GENERATION_TITLE_TYPE) handleOpenAiPopper();
        if (type === GENERATION_METADESCRIPTION_TYPE) handleOpenAiPopper();
        handleCloseEditorPopper();
      }
    }
  }

  function handleOpenAiPopper() {
    setOpenAIWritingPopper(true);
    handleCloseEditorPopper();
  }

  function saveBrandVoice(value: BrandVoiceModel) {
    dispatch(setBrandVoice({ value, workspaceId }));
  }

  function removeBrandVoice() {
    dispatch(deleteBrandVoice({ workspaceId }));
  }

  const menuContent: MenuContentItem[] = [];
  const brandVoiceContent = [];

  if (isTitle) {
    menuContent.push(
      getTitleItem(
        getAiWritingCredit,
        aiWriting.remainingAiWritingUnit,
        handleGenerateAi,
        aiWriting.hasUnlimitedAIWriting || !aiWritingCreditsEnabled,
        t,
        factCheckingEnabled
      )
    );
  }
  if (isMetaDescription) {
    menuContent.push(
      getMetaDescriptionItem(
        getAiWritingCredit,
        aiWriting.remainingAiWritingUnit,
        handleGenerateAi,
        aiWriting.hasUnlimitedAIWriting || !aiWritingCreditsEnabled,
        t,
        factCheckingEnabled
      )
    );
  }

  if (!isMetaDescription && !isTitle) {
    menuContent.push(
      ...getEditorAiWritingItems(
        getAiWritingCredit,
        aiWriting.remainingAiWritingUnit,
        handleGenerateAi,
        aiWriting.hasUnlimitedAIWriting || !aiWritingCreditsEnabled,
        '',
        t,
        factCheckingEnabled,
        brandVoiceSelected && aiWritingBrandVoiceCreditsEnabled
      )
    );
  }

  if (!isTitle && !isMetaDescription && aiWriting.brandVoiceEnabled) {
    const activeBrandVoices = brandVoices?.filter((brandVoice) => brandVoice.isActive) ?? [];

    brandVoiceContent.push(
      ...getBrandVoiceItems({
        brandVoices: activeBrandVoices,
        currentBrandVoiceId: aiWriting.currentBrandVoiceId,
        removeBrandVoice,
        saveBrandVoice,
        t,
      })
    );
  }

  const entities: MenuContentItem[] = [
    {
      content: (
        <div className="flex items-center gap-2">
          <span>{t('content:ai-writing.dropdown-menu.ai-model-writing')}</span>
          <BetaBadge
            fontSize="10px"
            fontWeight="500"
            height="35px"
            lineHeight="16px"
            margin="0 0 0 0"
            padding="2px 6px"
          >
            {t(`common:labels.beta`)}
          </BetaBadge>
        </div>
      ),
      type: ENUM_MENU_ENTITY_TYPE.section,
    },
    ...menuContent,
    ...brandVoiceContent,
  ];

  function getDefaultSelectedIndex() {
    if (isEmpty) {
      return Math.max(
        entities
          .filter((entity) => entity.type === ENUM_MENU_ENTITY_TYPE.item)
          .findIndex((entity) => entity.aiType === GENERATION_INTRODUCTION_TYPE),
        0
      );
    } else if (!hasTitle) {
      return Math.max(
        entities
          .filter((entity) => entity.type === ENUM_MENU_ENTITY_TYPE.item)
          .findIndex((entity) => entity.aiType === GENERATION_OUTLINE_TYPE),
        0
      );
    }
    return Math.max(
      entities
        .filter((entity) => entity.type === ENUM_MENU_ENTITY_TYPE.item)
        .findIndex((entity) => entity.aiType === GENERATION_PARAGRAPH_TYPE),
      0
    );
  }

  return (
    <MenuDropdown
      defaultSelectedIndex={getDefaultSelectedIndex()}
      entities={entities}
      popperProps={{
        anchorEl: position,
        placement: 'bottom-start',
      }}
      onClickAway={handleCloseEditorPopper}
    />
  );
}

export default EditorPopper;
