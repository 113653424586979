import { nanoid } from 'nanoid';
import React from 'react';
import { useMemo } from 'react';

import SvgWrapper from '@/components/icons/SvgWrapper';

const BoxIcon = (props) => {
  const maskId = useMemo(() => nanoid(), []);

  return (
    <SvgWrapper {...props} fill="none" height="18" viewBox="0 0 18 18" width="18">
      <mask
        height="16"
        id={`mask${maskId}`}
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="18"
        x="0"
        y="2"
      >
        <path
          d="M17.5 2.5H0.5V6.5H1.5V17.5H16.5V6.5H17.5V2.5ZM15.5 16.5H2.5V6.5H15.5V16.5ZM16.5 5.5H1.5V3.5H16.5V5.5ZM6.5 11.5H11.516C12.619 11.5 13.516 10.603 13.516 9.5C13.516 8.397 12.619 7.5 11.516 7.5H6.5C5.397 7.5 4.5 8.397 4.5 9.5C4.5 10.603 5.397 11.5 6.5 11.5ZM6.5 8.5H11.516C12.068 8.5 12.516 8.948 12.516 9.5C12.516 10.052 12.068 10.5 11.516 10.5H6.5C5.948 10.5 5.5 10.052 5.5 9.5C5.5 8.948 5.948 8.5 6.5 8.5Z"
          fill="black"
        />
      </mask>
      <g mask={`url(#mask${maskId})`}>
        <rect fill={props.fill || '#7C7D86'} height="20" width="20" x="-1.5" y="-0.5" />
      </g>
    </SvgWrapper>
  );
};

export default BoxIcon;
