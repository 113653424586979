import i18next from 'i18next';
import React from 'react';

import { ENUM_FILTER_TYPE, FilterRangeProps, SingleFilterType } from '@/components/DynamicFilters';
import BarChartIcon from '@/components/icons/BarChartIcon';
import { getMyPositionOptions } from '@/containers/Competitors/components/HeaderFilters/Filters/MyPositionFilter/const';
import { RANKED_WORKSPACE_URL_POSITION_QUERY_PARAM } from '@/containers/Competitors/utils/constants';

export function getMyPositionFilter({
  updateFilter,
  filter,
  customRange,
  onClose,
}: FilterRangeProps): SingleFilterType {
  return {
    customRange,
    icon: <BarChartIcon />,
    key: RANKED_WORKSPACE_URL_POSITION_QUERY_PARAM,
    name: i18next.t('competitors:data-explorer.metrics.my-position'),
    onClose,
    onValidate: (value) => updateFilter(RANKED_WORKSPACE_URL_POSITION_QUERY_PARAM, value),
    options: getMyPositionOptions(filter),
    type: ENUM_FILTER_TYPE.RADIO_RANGE,
  };
}
