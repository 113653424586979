import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const ItemIcon = styled.span`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${({ theme, selected }) => (selected ? theme.cssColors.primary : theme.cssColors.dark080)};
`;

const EndItemIcon = styled.span`
  display: none;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  color: ${({ theme, selected, color }) =>
    selected ? theme.cssColors.primary : theme.cssColors[color ?? 'dark080']};
`;

const Count = styled.span``;

const Item = styled.li`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  gap: 10px;
  font-size: 14px;
  font-weight: ${({ theme, weight }) => theme.textCss.weights[weight ?? 'medium']};
  line-height: ${({ theme }) => theme.textCss.sizes.lg};
  color: ${({ theme, selected, color }) =>
    selected ? theme.cssColors.primary : theme.cssColors[color ?? 'dark100']};
  padding: 4px 22px 4px ${({ withLeftPadding }) => (withLeftPadding ? '48px' : '22px')};
  margin-bottom: 4px;
  cursor: pointer;
  width: 100%;
  border-radius: 0.5rem;
  background-color: ${({ theme, selected }) => selected && theme.colors.primaryPink5};

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryPink5};

    ${EndItemIcon} {
      display: flex;
    }
  }

  & svg {
    color: ${({ theme, selected }) => selected && theme.cssColors.primary};
    font-weight: ${({ theme }) => theme.textCss.weights.medium};
  }
`;

const Label = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  & > :first-child {
    flex: 1;
    user-select: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

function ListItem({
  endAdornment,
  icon,
  label,
  selected,
  count,
  color,
  weight,
  withLeftPadding,
  onClick,
}) {
  return (
    <Item
      color={color}
      selected={selected}
      weight={weight}
      withLeftPadding={withLeftPadding}
      onClick={onClick}
    >
      {icon && <ItemIcon selected={selected}>{icon}</ItemIcon>}
      <Label title={label}>
        <span>{label}</span>
      </Label>
      <Count>{0 === count ? '-' : count}</Count>
      {endAdornment && (
        <EndItemIcon color={color} selected={selected}>
          {endAdornment}
        </EndItemIcon>
      )}
    </Item>
  );
}

ListItem.propTypes = {
  count: PropTypes.number,
  icon: PropTypes.element,
  label: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
};

export default ListItem;
