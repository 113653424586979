import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import CreditLimitReachedDialog from '@/components/Dialog/CreditLimitReachedDialog';
import { useFilterGroups } from '@/components/Filters/FilterGroups/useFilterGroups';
import SemjiCreditIcon from '@/components/icons/SemjiCreditIcon';
import SemjiIcon from '@/components/icons/SemjiIcon';
import Stepper from '@/components/Stepper/Stepper';
import { Warning } from '@/components/Text/Warning';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import { getSteps, IMPORT_FROM_TOP_PAGES, IMPORT_FROM_URLS, Step } from '@/containers/Pages/Step';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import { sendCustomEvent } from '@/services/Intercom';
import PageService from '@/services/Page';
import Workspace from '@/services/Workspace';
import { showSuccessSnackbar } from '@/store/actions/ui';
import { selectCurrentWorkspaceCountry } from '@/store/selectors/selectCurrentWorkspaceCountry';
import { selectCurrentWorkspaceWebsiteUrl } from '@/store/selectors/selectCurrentWorkspaceWebsiteUrl';
import { selectHasSearchConsole } from '@/store/selectors/selectHasSearchConsole';
import { selectRemainingAnalysisUnit } from '@/store/selectors/selectRemainingAnalysisUnit';
import { selectTotalAnalysisUnit } from '@/store/selectors/selectTotalAnalysisUnit';
import { ANALYSIS_CREDITS_TYPE, URL_LIMIT_IMPORT } from '@/utils/constants';
import { DEFAULT_PRIORITY_SCORE } from '@/utils/filter/constants';
import filterPages from '@/utils/filter/filterPages';
import { SECTIONS } from '@/utils/log/constants';
import { Log } from '@/utils/log/Log';
import { sortPagesByURLOrderSelection } from '@/utils/sorter';
import { isUrlAlreadyInList, isUrlValidWithWebSiteUrl, isValidPageUrl } from '@/utils/url';

const FlexBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  height: 35px;
`;

const FlexItem = styled.div`
  display: flex;
`;

function Info({ mainInfo, warnings }) {
  return (
    <FlexBox>
      {mainInfo}
      {warnings.map((warning) => (
        <FlexItem>
          <Warning key={warning}>{warning}</Warning>
        </FlexItem>
      ))}
    </FlexBox>
  );
}

function PagesImport() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const { organizationId, workspaceId } = useParams();
  const historyBackUrl = useSelector(
    (state) =>
      state.workspaceRouteHistory ||
      `/o/${organizationId}/w/${workspaceId}/contents?tab=opportunities&priorityScore=${DEFAULT_PRIORITY_SCORE}`
  );
  const hasSearchConsole = useSelector(selectHasSearchConsole);
  const currentWorkspaceWebsiteUrl = useSelector(selectCurrentWorkspaceWebsiteUrl);
  const { countryName, countryCode } = useSelector(selectCurrentWorkspaceCountry);
  const remainingAnalysisUnit = useSelector(selectRemainingAnalysisUnit);
  const totalAnalysisUnit = useSelector(selectTotalAnalysisUnit);
  const { INITIAL_FILTERS_STATE } = useFilterGroups();

  const [state, setState] = useState({
    activeStep: 0,
    appliedFilters: INITIAL_FILTERS_STATE,
    canBeImported: true,
    error: null,
    focusKeywordsAreLoading: false,
    importType: null,
    importing: false,
    isCreditReachedDialogOpen: false,
    isPagesListEmpty: false,
    isPagesListLoading: true,
    loading: false,
    pagesToImport: [],
    selectedTopPages: [],
    totalPagesNumber: 0,
    urls: [''],
  });
  const [pages, setPages] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const { isFeatureEnabled: hasUnlimitedAnalysisCredits } = useOrganizationFeatureSet(
    'credits:analysis:has-unlimited-amount'
  );

  const _pageService = new PageService(null, workspaceId);
  const _numberOfSteps = 3;
  const _workspace = new Workspace(workspaceId);

  useEffect(() => {
    const { urlFragment } = getSteps(state.activeStep, state.importType);
    const url = location.pathname.replaceAll(IMPORT_FROM_URLS, '');
    navigate(url + urlFragment, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.activeStep]);

  useEffect(() => {
    const fetchPages = async () => {
      try {
        setPages(
          await _workspace.pages({
            'exists[url]': true,
            inStock: false,
          })
        );
      } catch (error) {
        Log.report({
          context: 'useEffect',
          error,
          extra: 'Fetch pages',
          section: SECTIONS.pages.key,
        });
      }
    };
    fetchPages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNext = async () => {
    if (
      state.importType === IMPORT_FROM_TOP_PAGES &&
      state.activeStep === 1 &&
      state.selectedTopPages.length !== 0
    ) {
      setState({
        ...state,
        activeStep: state.activeStep + 1,
        error: null,
        pagesToImport: state.selectedTopPages,
      });
    } else if (
      state.importType === IMPORT_FROM_URLS &&
      state.activeStep === 1 &&
      state.urls.length !== 0
    ) {
      setState({
        ...state,
        error: null,
        loading: true,
      });
      try {
        const urlsToImport = state.urls
          .filter(
            (url) =>
              !pages.find((page) => page.url === url.toLowerCase()) &&
              isUrlValidWithWebSiteUrl(url, currentWorkspaceWebsiteUrl) &&
              isValidPageUrl(url).value &&
              !isUrlAlreadyInList(url, state.urls)
          )
          .map((url) => url.trimStart().trimEnd());

        const response = await _pageService.importFromUrls(urlsToImport);
        const sortedResponse = sortPagesByURLOrderSelection(urlsToImport, response.pages);

        setState({
          ...state,
          activeStep: state.activeStep + 1,
          loading: false,
          pagesToImport: sortedResponse.filter((page) => page.inStock),
        });
      } catch (error) {
        setState({
          ...state,
          error: error.statusText,
          loading: false,
        });
        Log.report({
          context: 'handleNext',
          error,
          extra: 'Import from urls',
          section: SECTIONS.pages.key,
        });
      }
    } else {
      setState({
        ...state,
        activeStep: state.activeStep + 1,
        error: null,
      });
    }
  };

  const handlePrevious = () => {
    if (state.activeStep === 1) {
      navigate(`/o/${organizationId}/w/${workspaceId}/contents/import-pages`);

      setState({
        ...state,
        activeStep: 0,
        canBeImported: true,
        error: null,
        focusKeywordsAreLoading: false,
        isPagesListLoading: true,
        pagesToImport: [],
        selectedTopPages: [],
        urls: [''],
      });
    } else {
      setState({
        ...state,
        activeStep: state.activeStep - 1,
        canBeImported: true,
        error: null,
        focusKeywordsAreLoading: false,
        isPagesListLoading: true,
      });
    }
  };

  const handleCancel = () => {
    navigate(historyBackUrl);
  };

  const handleValidate = async () => {
    setState({ ...state, importing: true });

    try {
      const ids = state.pagesToImport
        .filter((page) => !page.redirectionUrl)
        .map((page) => page['@id']);
      await _pageService.importFromStock(ids);
      setState({ ...state, importing: false });
      dispatch(
        showSuccessSnackbar(
          t('pages:index.notification.pages-imported_interval', {
            count: state.pagesToImport.length,
            postProcess: 'interval',
          })
        )
      );
      sendCustomEvent('pages-import', {
        import_date: new Date(),
        workspace_website_url: currentWorkspaceWebsiteUrl,
      });
      navigate(`/o/${organizationId}/w/${workspaceId}/contents?tab=all`);
    } catch (error) {
      setState({ ...state, error: t('pages:index.errors.validation-error'), importing: false });
      Log.report({
        context: 'handleValidate',
        error,
        extra: 'Validate import pages',
        section: SECTIONS.pages.key,
      });
    }
  };

  const isNextDisabled = () => {
    if (!state.importType || isFetching) return true;

    if (state.importing) return true;

    const filteredUrls = state.urls.filter(
      (url) =>
        !pages.find((page) => page.url === url.toLowerCase()) &&
        isUrlValidWithWebSiteUrl(url, currentWorkspaceWebsiteUrl) &&
        isValidPageUrl(url).value &&
        !isUrlAlreadyInList(url, state.urls)
    );

    if (
      state.importType === IMPORT_FROM_URLS &&
      state.activeStep === 1 &&
      (!filteredUrls.length ||
        !!filteredUrls.find((url) => isUrlAlreadyInList(url, state.urls)) ||
        filteredUrls.length > URL_LIMIT_IMPORT ||
        state.loading)
    ) {
      return true;
    }

    if (
      state.importType === IMPORT_FROM_TOP_PAGES &&
      (state.selectedTopPages.length === 0 || state.selectedTopPages.length > URL_LIMIT_IMPORT) &&
      state.activeStep === 1
    ) {
      return true;
    }

    if (
      state.activeStep === 2 &&
      (state.pagesToImport.filter((page) => !page.redirectionUrl).length === 0 ||
        !state.canBeImported ||
        state.focusKeywordsAreLoading ||
        state.isPagesListLoading ||
        state.isPagesListEmpty)
    ) {
      return true;
    }

    return false;
  };

  const isInfoOnError = () => {
    const validUrls = state.urls.filter((url) =>
      isUrlValidWithWebSiteUrl(url, currentWorkspaceWebsiteUrl)
    );

    if (
      state.activeStep === 1 &&
      state.importType === IMPORT_FROM_URLS &&
      validUrls.length > remainingAnalysisUnit
    ) {
      return true;
    }

    if (
      state.activeStep === 2 &&
      (state.isPagesListEmpty ||
        !!state.error ||
        !state.canBeImported ||
        state.focusKeywordsAreLoading)
    ) {
      return true;
    }

    if (state.activeStep === 2) {
      return state.pagesToImport.length > remainingAnalysisUnit;
    }

    return !!state.error;
  };

  const getInfoMessage = () => {
    const selectedTopPages = state.selectedTopPages.length;
    const pagesToImport = state.pagesToImport.filter((page) => !page.redirectionUrl).length;

    if (state.loading) return '';

    if (
      state.activeStep === 1 &&
      state.importType === IMPORT_FROM_TOP_PAGES &&
      selectedTopPages > 0
    ) {
      return (
        <FlexItem>
          {t('pages:index.pages-to-import_interval', {
            count: selectedTopPages,
            postProcess: 'interval',
            total: state.totalPagesNumber,
          })}
        </FlexItem>
      );
    }

    if (!!state.error) {
      return (
        <FlexItem>
          {t('pages:index.errors.an-unexpected-error')} {state.error}
        </FlexItem>
      );
    }

    if (state.activeStep !== 2) return '';

    if (state.isPagesListEmpty) {
      return <FlexItem>{t('pages:index.errors.no-page-to-import')}</FlexItem>;
    }

    if (true === state.focusKeywordsAreLoading) {
      return <FlexItem>{t('pages:index.errors.some-focus-keywords-still-retrieved')}</FlexItem>;
    }

    if (false === state.canBeImported) {
      return (
        <FlexItem>
          {t('pages:index.errors.add-missing-focus-keyword_interval', {
            count: pagesToImport,
            postProcess: 'interval',
          })}
        </FlexItem>
      );
    }

    if (hasUnlimitedAnalysisCredits) {
      return '';
    }

    return (
      <FlexItem>
        <SemjiCreditIcon /> &nbsp;{' '}
        {t('pages:index.analysis-credit-count_interval', {
          count: remainingAnalysisUnit,
          postProcess: 'interval',
          total: totalAnalysisUnit,
        })}
      </FlexItem>
    );
  };

  const getExtraInfoMessage = () => {
    const topPagesCount = state.selectedTopPages.length;
    const pagesToImportCount = state.pagesToImport.length;
    const filteredPagesToImportCount = state.pagesToImport.filter(
      (page) => !page.redirectionUrl
    ).length;

    const topPagesWithRedirectionCount = topPagesCount - filteredPagesToImportCount;
    const urlsWithRedirection = pagesToImportCount - filteredPagesToImportCount;
    const urlsAlreadyInStockCount = state.urls.filter((url) =>
      pages.find((page) => page.url === url.toLowerCase())
    ).length;
    const warnings = [];

    if (state.activeStep === 2 && (topPagesWithRedirectionCount > 0 || urlsWithRedirection > 0)) {
      warnings.push(
        t('pages:index.warnings.page-redirection_interval', {
          count: Math.max(topPagesWithRedirectionCount, urlsWithRedirection),
          postProcess: 'interval',
        })
      );
    }

    if (state.activeStep === 2 && urlsAlreadyInStockCount > 0) {
      warnings.push(
        t('pages:index.warnings.page-already-imported_interval', {
          count: urlsAlreadyInStockCount,
          postProcess: 'interval',
        })
      );
    }

    return warnings;
  };

  const setAppliedFilters = (filters) => {
    // filters list of pages to import after applying a filter on a selection
    const selectedTopPages = filterPages(state.selectedTopPages, filters);

    setState({ ...state, appliedFilters: filters, selectedTopPages });
  };

  const getNextLabel = () => {
    const numberOfPages = state.pagesToImport.filter((page) => !page.redirectionUrl).length;

    if (state.loading) {
      return t('pages:index.next-label.importing');
    }
    if (state.activeStep !== _numberOfSteps - 1) {
      return t('pages:index.next-label.next');
    }
    if (state.importing) {
      return t('pages:index.next-label.importing-page_interval', {
        count: numberOfPages,
        postProcess: 'interval',
      });
    }
    if (state.isPagesListEmpty) {
      return t('pages:index.next-label.import');
    }

    return (
      <TooltipComponent
        title={t('pages:index.tooltips.focus-keyword-will-performed_interval', {
          count: numberOfPages,
          postProcess: 'interval',
        })}
      >
        {!hasUnlimitedAnalysisCredits && <SemjiIcon />}
        &nbsp;
        {t('pages:index.next-label.import-page_interval', {
          count: numberOfPages,
          postProcess: 'interval',
        })}
      </TooltipComponent>
    );
  };

  return (
    <>
      <Stepper
        consumedCredits={state.pagesToImport.filter((page) => !page.redirectionUrl).length}
        handleCancel={handleCancel}
        handleNext={state.activeStep !== _numberOfSteps - 1 ? handleNext : handleValidate}
        handlePrevious={handlePrevious}
        header={getSteps(state.activeStep, state.importType)}
        info={<Info mainInfo={getInfoMessage()} warnings={getExtraInfoMessage()} />}
        infoOnError={isInfoOnError()}
        isNextDisabled={isNextDisabled()}
        nextLabel={getNextLabel()}
        previousDisabled={state.activeStep === 0}
        totalCredits={remainingAnalysisUnit}
      >
        <Step
          activeStep={state.activeStep}
          appliedFilters={state.appliedFilters}
          hasSearchConsole={hasSearchConsole}
          importing={state.importing}
          importType={state.importType}
          pages={pages}
          pagesToImport={state.pagesToImport}
          selectedTopPages={state.selectedTopPages}
          setAppliedFilters={setAppliedFilters}
          setCanPagesBeImported={(canBeImported) => setState({ ...state, canBeImported })}
          setFocusKeywordsAreLoading={(focusKeywordsAreLoading) =>
            setState({ ...state, focusKeywordsAreLoading })
          }
          setImportType={(importType) => setState({ ...state, importType })}
          setIsFetching={setIsFetching}
          setIsPagesListEmpty={(isPagesListEmpty) => setState({ ...state, isPagesListEmpty })}
          setIsPagesListLoading={(isPagesListLoading) => setState({ ...state, isPagesListLoading })}
          setSelectedTopPages={(selectedTopPages) => setState({ ...state, selectedTopPages })}
          setState={setState}
          setTotalPagesNumber={(totalPagesNumber) => setState({ ...state, totalPagesNumber })}
          setUrls={(urls) => setState({ ...state, urls })}
          urls={state.urls}
          websiteUrl={currentWorkspaceWebsiteUrl}
          workspaceCountryCode={countryCode}
          workspaceCountryName={countryName}
        />
      </Stepper>
      {state.isCreditReachedDialogOpen && (
        <CreditLimitReachedDialog
          creditType={ANALYSIS_CREDITS_TYPE}
          isOpen
          onClose={() => setState({ ...state, isCreditReachedDialogOpen: false })}
        />
      )}
    </>
  );
}

export default PagesImport;
