import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import apiClient from '@/apis/semji/apiClient';
import { SCOPE_CONTENTS } from '@/apis/semji/constants';
import { createQueryKeyFromFiltersAndProperties } from '@/apis/semji/helper';

import { DEFAULT_CONTENT_PROPERTIES } from '../const';
import { UseGetContentsProps } from './useGetContents.types';

export default function useGetContents({ properties, filters, ...props }: UseGetContentsProps) {
  const { workspaceId } = useParams();
  const { get } = apiClient;
  return useQuery({
    queryFn: async ({ signal }) => {
      const { data } = await get(`/workspaces/${workspaceId}/contents`, {
        params: {
          properties: properties ?? DEFAULT_CONTENT_PROPERTIES,
        },
        signal,
      });

      return data['hydra:member'] || [];
    },
    queryKey: [
      SCOPE_CONTENTS.CONTENT,
      workspaceId,
      ...createQueryKeyFromFiltersAndProperties({ filters, properties }),
    ],
    ...props,
  });
}
