import { useId } from 'react';

import SvgWrapper from './SvgWrapper';

function PaperIcon(props) {
  const svgId = useId();

  return (
    <SvgWrapper {...props} fill="none" height="11" viewBox="0 0 12 11" width="12">
      <mask
        height="11"
        id={`mask${svgId}`}
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="10"
        x="1"
        y="0"
      >
        <path
          d="M6.60455 0H1.64355V11H10.0553V3.43459L6.60455 0ZM6.82002 1.12718L8.93785 3.23529H6.82002V1.12718ZM2.29061 10.3529V0.647059H6.17297V3.88235H9.40826V10.3529H2.29061Z"
          fill="black"
        />
      </mask>
      <g mask={`url(#mask${svgId})`}>
        <rect fill="currentColor" height="12.9412" width="12.9412" x="-0.944336" y="-0.646973" />
      </g>
    </SvgWrapper>
  );
}

export default PaperIcon;
