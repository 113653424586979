import './ChartWrapper.scss';

import React from 'react';

import Loader from '@/components/Loader/Loader';

export default function ChartWrapper({
  isLoading,
  children,
  variant = 'default',
}: {
  isLoading?: boolean;
  children: React.ReactNode;
  variant?: 'default' | 'small';
}) {
  return (
    <div className="competitors-chart-wrapper">
      <div className="competitors-chart-wrapper__container">
        {isLoading ? (
          <div
            className={`competitors-chart-wrapper__loader competitors-chart-wrapper__loader--${variant}`}
          >
            <Loader children={''} fontSize="50px" />
          </div>
        ) : (
          children
        )}
      </div>
    </div>
  );
}
