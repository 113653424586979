import MuiBadge from '@material-ui/core/Badge';
import MuiSvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';
import styled, { css } from 'styled-components/macro';

const Badge = styled((props) => (
  <MuiBadge
    {...props}
    classes={{
      badge: 'badge',
    }}
  />
))`
  .badge {
    font-size: 0.45em;
    width: 2em;
    height: 2em;
    font-weight: 600;
    min-width: 0;
  }
`;

const SvgIcon = styled(MuiSvgIcon)`
  && {
    font-size: 1em;
  }
`;

const SvgWrapper = ({ notification, color, ...otherProps }) => {
  if (notification) {
    return (
      <Badge badgeContent={notification} color="secondary">
        <SvgIcon {...otherProps} />
      </Badge>
    );
  }

  return <SvgIcon {...otherProps} />;
};

const getStyleAccordingToSize = (props) => {
  let size = null;

  switch (props.size) {
    case 'micro':
      size = props.theme.image.sizes.small;
      break;
    case 'small':
      size = props.theme.image.sizes.default;
      break;
    case 'default':
      size = props.theme.image.sizes.big;
      break;
    case 'huge':
      size = props.theme.image.sizes.huge;
      break;
    default:
      size = props.size;
      break;
  }

  return css`
    font-size: ${size};
  `;
};

const getStyleWhenBordered = () => {
  return css`
    border-radius: 50%;
    border: 0.07em solid currentColor;
    padding: 0.2em;
  `;
};

const getStyleWhenBorderedHovered = () => {
  return css`
    border: 0.07em solid currentColor;
  `;
};

export default styled(({ bordered, ...props }) => <SvgWrapper {...props} />).attrs((props) => ({
  htmlColor: 'currentColor',
  viewBox: props.viewBox || '0 0 17 17',
}))`
  && {
    ${(props) => getStyleAccordingToSize(props)};
    ${(props) => props.bordered && getStyleWhenBordered(props)};
    padding: ${(props) => props.padding};
    border-radius: ${(props) => props.borderRadius};
  }
  &:hover {
    ${(props) => props.bordered && getStyleWhenBorderedHovered(props)};
    background-color: ${({ hoverBackgroundColor, theme }) =>
      hoverBackgroundColor && theme.cssColors[hoverBackgroundColor]};
    color: ${({ hoverColor, theme }) => hoverColor && theme.cssColors[hoverColor]};
  }
`;
