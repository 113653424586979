import styled from 'styled-components/macro';

import { FlexContainer } from '@/components/_common';
import DotsLoaderIcon from '@/components/icons/DotsLoaderIcon';
import Flex from '@/design-system/components/Flex/Flex';

const LabelWrapper = styled(Flex)`
  font-size: ${({ theme }) => theme.textCss.sizes.sm};
  font-weight: ${({ theme }) => theme.textCss.weights.medium};
  line-height: 135%;
  background-color: ${({ active, theme }) => (active ? theme.colors.primaryPink5 : 'transparent')};
  border-radius: 0.5rem;

  :hover {
    background-color: ${({ theme }) => theme.colors.primaryPink5};
  }
`;

const Loader = styled(DotsLoaderIcon)`
  && {
    align-self: center;
    font-size: 24px;
  }
`;

function PanelElementRender({
  label,
  startAdornment,
  endAdornment,
  active,
  isLoading,
  isMainElement,
  onClick,
}) {
  function handleClick() {
    if (!active) {
      onClick();
    }
  }

  return (
    <LabelWrapper
      active={active}
      alignItems="center"
      boxSizing="border-box"
      color={active ? 'primary' : 'currentColor'}
      cursor="pointer"
      isMainElement={isMainElement}
      justifyContent="space-between"
      maxWidth="220px"
      padding="6px 22px"
      width="100%"
      onClick={handleClick}
    >
      <FlexContainer>
        {startAdornment}
        {label}
      </FlexContainer>
      {isLoading ? <Loader /> : endAdornment}
    </LabelWrapper>
  );
}

export default PanelElementRender;
