import memoize from 'lodash/memoize';
import PropTypes from 'prop-types';
import React from 'react';
import AutoSizer from 'react-virtualized/dist/es/AutoSizer';
import Table from 'react-virtualized/dist/es/Table';
import Column from 'react-virtualized/dist/es/Table/Column';
import SortDirection from 'react-virtualized/dist/es/Table/SortDirection';
import SortIndicator from 'react-virtualized/dist/es/Table/SortIndicator';
import styled from 'styled-components/macro';

import { FlexColumnContainer, TableStyledCard } from '@/components/_common';
import { OutlinedButton } from '@/components/Button/Button';
import { IconExternalLink } from '@/components/Navigation/Link';
import { ContentIdeaTitleWrapper } from '@/components/Pages/ContentIdeas/Listing/CellRender/Title/Title.styled';
import { StyledCloseIcon } from '@/components/Pages/ContentIdeas/Menu/constant';
import { ArchiveWrapper } from '@/components/Planning/ArchiveCell';
import { ArchivedWrapper } from '@/components/Planning/ArchivedCell';
import StartAnalysisPlanningCell from '@/components/Planning/StartAnalysisCell';
import StartAnalysisCell from '@/components/Table/Cell/StartAnalysisCell';
import { STYLED_CARD_PADDING } from '@/utils/constants';
import { noop } from '@/utils/noop';

const ROW_HEADER_HEIGHT = 70;
const ROW_HEIGHT = 70;

const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => {
    switch (props.align) {
      case 'right':
        return 'flex-end';
      case 'center':
        return 'center';
      case 'left':
      default:
        return 'flex-start';
    }
  }};
`;

export const HeaderCell = styled(
  ({
    label,
    dataKey,
    sortBy,
    sortDirection,
    sortable = false,
    align,
    columnData,
    disableSort,
    workspaceId,
    ...props
  }) => (
    <div {...props}>
      {label}
      {sortable && sortBy === dataKey && <SortIndicator sortDirection={sortDirection} />}
    </div>
  )
)`
  font-weight: 500;
  text-transform: initial;
  font-size: 0.9em;
  display: flex;
  color: #8a8a8a;
  word-break: initial;
  white-space: normal;
  cursor: ${(props) => (props.sortable ? 'pointer' : 'inherit')};
  align-items: center;
  text-align: ${(props) => props.align};
  justify-content: ${(props) => {
    switch (props.align) {
      case 'right':
        return 'flex-end';
      case 'center':
        return 'center';
      case 'left':
      default:
        return 'flex-start';
    }
  }};

  :hover {
    color: ${(props) => (props.sortable ? '#777777' : '#8a8a8a')};
  }
`;

class VirtualizedTable extends React.PureComponent {
  generateColumns = memoize(
    (columns, enableCustomHeader, customHeader) => {
      return columns.map(
        (column) =>
          !column.hide && (
            <Column
              key={column.label}
              cellDataGetter={column.cellDataGetter}
              cellRenderer={
                column.cellRenderer &&
                ((props) => (
                  <Cell align={column.align || 'left'}>{column.cellRenderer(props)}</Cell>
                ))
              }
              dataKey={column.dataKey}
              flexGrow={column.flexGrow}
              headerRenderer={(props) => {
                if (column.headerCellRenderer) {
                  return column.headerCellRenderer(props);
                } else if (enableCustomHeader) {
                  if (column.customizableHeader) {
                    return customHeader;
                  }
                  return null;
                } else {
                  return (
                    <HeaderCell
                      align={column.align || 'left'}
                      sortable={column.sortable}
                      {...props}
                    />
                  );
                }
              }}
              label={column.label}
              maxWidth={column.maxWidth}
              minWidth={column.minWidth}
              width={column.width}
            />
          )
      );
    },
    (...args) => args
  );

  render() {
    const {
      classes,
      columns,
      rowGetter,
      rowCount,
      disableHeader,
      mode,
      overscanRowCount,
      customHeader,
      enableCustomHeader,
      disablePadding,
      noRowsRenderer,
      rowHeight = ROW_HEIGHT,
      headerHeight = ROW_HEADER_HEIGHT,
      ...tableProps
    } = this.props;

    return (
      <AutoSizer>
        {({ height, width }) => {
          const isHeader = disableHeader ? 0 : 1;
          const padding = disablePadding ? 0 : STYLED_CARD_PADDING;
          const maxHeight = Math.min(height, rowCount * rowHeight + headerHeight * isHeader);
          const maxWidth = width - 2 * padding;

          return (
            <TableStyledCard
              disablePadding={disablePadding}
              mode={mode}
              style={{ height: height - 2, width: maxWidth - 2 }}
            >
              <Table
                disableHeader={disableHeader}
                headerHeight={headerHeight}
                height={rowCount === 0 && noRowsRenderer ? height : maxHeight}
                noRowsRenderer={noRowsRenderer}
                rowCount={rowCount}
                rowGetter={rowGetter}
                rowHeight={rowHeight}
                width={maxWidth}
                {...tableProps}
              >
                {this.generateColumns(columns, enableCustomHeader, customHeader)}
              </Table>
            </TableStyledCard>
          );
        }}
      </AutoSizer>
    );
  }
}

VirtualizedTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      cellContentRenderer: PropTypes.func,
      dataKey: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired,
    })
  ).isRequired,
  headerHeight: PropTypes.number.isRequired,
  onRowClick: PropTypes.func,
  rowHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.func]).isRequired,
  sort: PropTypes.func,
};

const stringSort = (value1, value2, sortDirection) => {
  if (sortDirection === SortDirection.ASC) {
    return ('' + value1).localeCompare('' + value2);
  }
  return ('' + value2).localeCompare('' + value1);
};

stringSort.NullLast = (value1, value2, sortDirection) => {
  if (value1 === undefined || value1 === null) {
    return 1;
  }

  if (value2 === undefined || value2 === null) {
    return -1;
  }

  return stringSort(value1, value2, sortDirection);
};

const naturalSort = (value1, value2, sortDirection) => {
  if (value1 === value2) return 0;
  if (sortDirection === SortDirection.ASC) {
    return value1 < value2 ? -1 : 1;
  }
  return value1 < value2 ? 1 : -1;
};

naturalSort.NullLast = (value1, value2, sortDirection) => {
  if (value1 === undefined || value1 === null) {
    return 1;
  }

  if (value2 === undefined || value2 === null) {
    return -1;
  }
  return naturalSort(value1, value2, sortDirection);
};

class ReactVirtualizedTable extends React.PureComponent {
  state = {
    sortBy: undefined,
    sortDirection: this.props.defaultSortDirection || SortDirection.ASC,
  };

  _sort =
    (columns) =>
    ({ sortBy, sortDirection }) => {
      const column = columns.find((cell) => cell.dataKey === sortBy);

      if (!column || column.sortable !== true || this.props.enableCustomHeader) {
        // if enableCustomHeader disable sort
        return;
      }

      this.setState({ sortBy, sortDirection });
    };

  static stringSort = stringSort.NullLast;
  static naturalSort = naturalSort.NullLast;

  _sortList = memoize(({ sortBy, sortDirection, rows, columns }) => {
    const column = columns.find((cell) => cell.dataKey === sortBy);

    if (!column || column.sortable !== true) {
      return rows;
    }

    const sortFunction = column.sortFunction || ReactVirtualizedTable.naturalSort;

    return rows.slice().sort((a, b) => {
      const value1 = column.cellDataGetter({ rowData: a });
      const value2 = column.cellDataGetter({ rowData: b });
      return sortFunction(value1, value2, sortDirection);
    });
  });

  _defaultSortKey = memoize(({ sortBy, defaultSort, columns }) => {
    const column = columns.find((cell) => cell.dataKey === sortBy);
    if (!!column) return sortBy;
    return defaultSort;
  });

  componentDidUpdate(prevProps) {
    // Update state to the new defaultSortDirection
    if (prevProps.defaultSortDirection !== this.props.defaultSortDirection) {
      this.setState({
        ...this.state,
        sortDirection: this.props.defaultSortDirection,
      });
    }
  }

  render() {
    const {
      columns,
      rows,
      height,
      width,
      handleDelete,
      isRowDisabled,
      workspaceId,
      defaultSort,
      defaultSortDirection,
      disableHeader,
      headerHeight,
      rowHeight,
      mode,
      overscanRowCount,
      enableCustomHeader,
      disablePadding,
      customHeader,
      noRowsRenderer,
      onRowClick = noop,
      ...props
    } = this.props;
    const { sortBy = defaultSort, sortDirection } = this.state;
    const defaultSortKey = this._defaultSortKey({ columns, defaultSort, sortBy });
    const sortedList = this._sortList({ columns, rows, sortBy: defaultSortKey, sortDirection });

    return (
      <FlexColumnContainer style={{ width }}>
        <div style={{ height }} {...props}>
          <VirtualizedTable
            columns={columns}
            customHeader={customHeader}
            disableHeader={disableHeader}
            disablePadding={disablePadding}
            enableCustomHeader={enableCustomHeader}
            headerHeight={headerHeight}
            mode={mode}
            noRowsRenderer={noRowsRenderer}
            overscanRowCount={overscanRowCount}
            rowCount={sortedList.length}
            rowGetter={({ index }) => sortedList[index]}
            rowHeight={rowHeight}
            rowStyle={({ index }) =>
              isRowDisabled(sortedList[index]) && index !== -1
                ? { background: '#EFF3F6', opacity: 0.5 }
                : null
            }
            sort={this._sort(columns)}
            sortBy={defaultSortKey}
            sortDirection={sortDirection}
            onRowClick={onRowClick}
          />
        </div>
      </FlexColumnContainer>
    );
  }
}

ReactVirtualizedTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataKey: PropTypes.string.isRequired,
      label: PropTypes.node.isRequired,
      width: PropTypes.number.isRequired,
    })
  ).isRequired,
  customHeader: PropTypes.node,
  disablePadding: PropTypes.bool,
  enableCustomHeader: PropTypes.bool,
  headerHeight: PropTypes.number,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isRowDisabled: PropTypes.func,
  rowHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
  rows: PropTypes.array,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

ReactVirtualizedTable.defaultProps = {
  headerHeight: ROW_HEADER_HEIGHT,
  height: '100%',
  isRowDisabled: () => {},
  rowHeight: ROW_HEIGHT,
  rows: [],
  width: '100%',
};

export default styled(ReactVirtualizedTable)`
  color: ${(props) => props.theme.textCss.colors.darkGrey};
  box-shadow: none;
  margin-bottom: 10px;
  overflow-x: auto;
  flex: 1;

  .ReactVirtualized__Table__headerRow  {
    height: 50px !important;
  }

  .ReactVirtualized__Table__headerColumn {
    box-sizing: border-box;
  }

  .ReactVirtualized__Table__headerRow,
  .ReactVirtualized__Table__row {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    box-sizing: border-box;
  }

  .ReactVirtualized__Grid.ReactVirtualized__Table__Grid {
    &::-webkit-scrollbar {
      background-color: #fff;
      width: 22px;
    }
    /* background of the scrollbar except button or resizer */
    &::-webkit-scrollbar-track {
      background-color: #fff;
    }
    /* scrollbar itself */
    &::-webkit-scrollbar-thumb {
      background-color: #babac0;
      border-radius: 22px;
      height: 50px;
      border: 6px solid #fff;
    }
    /* set button(top and bottom of the scrollbar) */
    &::-webkit-scrollbar-button {
      display: none;
    }
  }

  .ReactVirtualized__Table__row:hover {
    background-color: #fbfbfb;
    ${IconExternalLink}, ${StyledCloseIcon} {
      display: inline;
      opacity: 1;
    }
    ${OutlinedButton} {
      display: block;
    }
    ${StartAnalysisPlanningCell}, ${StartAnalysisCell}, ${ArchiveWrapper}, ${ArchivedWrapper} {
      display: flex;
    }
    ${ContentIdeaTitleWrapper} {
      color: ${({ theme }) => theme.cssColors.secondaryBlue};
    }
  }

  .ReactVirtualized__Table__headerColumn,
  .ReactVirtualized__Table__rowColumn {
    margin-right: 5px;
    padding: 5px 0;
    min-width: 0px;
  }
  .ReactVirtualized__Table__rowColumn {
    text-overflow: ellipsis;
  }

  .ReactVirtualized__Table__headerColumn:first-of-type,
  .ReactVirtualized__Table__rowColumn:first-of-type {
    margin-left: 10px;
  }

  .ReactVirtualized__Table__sortableHeaderIcon {
    height: 20px;
    width: 20px;
    fill: currentColor;
    flex-shrink: 0;
  }
`;
