import { useTranslation } from 'react-i18next';

import FavIcon from '@/components/FavIcon/FavIcon';
import PrimaryColumn from '@/containers/Content/BriefComponents/Table/Columns/PrimaryColumn';
import SecondaryColumn from '@/containers/Content/BriefComponents/Table/Columns/SecondaryColumn';
import EmptyRow from '@/containers/Content/BriefComponents/Table/Rows/EmptyRow';
import Header from '@/containers/Content/BriefComponents/Table/Rows/Header';
import LoadingRow from '@/containers/Content/BriefComponents/Table/Rows/LoadingRow';
import Row from '@/containers/Content/BriefComponents/Table/Rows/Row';
import Table from '@/containers/Content/BriefComponents/Table/Table';

function CompetitorsArticles({ isLoading, competitorsArticles }) {
  const { t } = useTranslation();

  return (
    <Table>
      <Header>
        <PrimaryColumn>{t('content:brief.length-primary-column-title')}</PrimaryColumn>
        <SecondaryColumn halign="right">
          {t('content:brief.length-secondary-column-title')}
        </SecondaryColumn>
      </Header>

      {isLoading ? (
        <LoadingRow />
      ) : competitorsArticles?.length > 0 ? (
        competitorsArticles.map(({ data }) => (
          <Row key={data.title}>
            <PrimaryColumn>
              <FavIcon displayLink url={data.url} />
            </PrimaryColumn>
            <SecondaryColumn halign="right">
              <span>{data.wordCount || '-'}</span>
            </SecondaryColumn>
          </Row>
        ))
      ) : (
        <EmptyRow />
      )}
    </Table>
  );
}

export default CompetitorsArticles;
