import './OrganizationMenuItem.scss';

import React from 'react';
import { NavLink } from 'react-router-dom';

interface OrganizationMenuProps extends React.HTMLProps<HTMLDivElement> {
  selected?: boolean;
  label: string;
  url: string;
}
export default function OrganizationMenu({
  id,
  selected,
  label,
  url,
  className,
  ...props
}: OrganizationMenuProps) {
  return (
    <div
      className={`organization-menu-item ${selected && 'organization-menu-item--selected'} ${className}`}
      {...props}
    >
      <NavLink className="organization-menu-item__link" title={label} to={url}>
        <span>{label}</span>
      </NavLink>
    </div>
  );
}
