import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

export const useBadge = (lastStatusCode) => {
  const { t } = useTranslation();

  switch (lastStatusCode) {
    case 404:
      return [{ badgeTitle: t('components:badge.not-found-badge.title'), color: 'red' }];
    case 301:
    case 302:
    case 303:
    case 307:
    case 308:
      return [
        {
          badgeTitle: t('components:badge.redirection-badge.title', { statusCode: lastStatusCode }),
          color: 'orange',
        },
      ];
    default:
      return [{}];
  }
};

const InfoBadge = styled.span`
  display: inline-grid;
  width: ${({ size }) => (size === 'micro' ? '20px' : '30px')};
  height: ${({ size }) => (size === 'micro' ? '11px' : '18px')};
  text-align: center;
  align-items: center;
  font-weight: 500;
  font-size: ${({ theme, size }) =>
    size === 'micro' ? theme.text.sizes.micro : theme.text.sizes.small};
  color: ${(props) => props.theme.cssColors.white};
  background-color: ${({ theme, color }) =>
    theme.cssColors[color || theme.text.colors.lightGreyOpaque]};
  margin-bottom: ${(props) => (props.size === 'micro' ? '3px' : '1px')};
  border-radius: 5px;
`;

export default InfoBadge;
