export const SOURCE_UNKNOWN = 'unknown';
export const SOURCE_EDITOR = 'editor';
export const SOURCE_PAGE_LIST = 'page_list';
export const SOURCE_PAGE_BULK = 'page_bulk';
export const SOURCE_PLANNING_LIST = 'planning_list';
export const SOURCE_PLANNING_BULK = 'planning_bulk';
export const SOURCE_DRAFT_CREATE = 'draft_create';

export const STATUS_QUEUED = 'queued';
export const STATUS_PENDING = 'pending';
export const STATUS_SUCCESS = 'success';
export const STATUS_FAILED = 'failed';
