import Input from '@material-ui/core/Input';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import styled from 'styled-components/macro';

import Flex from '@/design-system/components/Flex';
import { useMixpanelTrackEvent } from '@/hooks/useMixpanelTrackEvent';
import { QUICK_SEARCH_CLICK, QUICK_SEARCH_INPUT } from '@/utils/3rdParty/Mixpanel/constants';

const FoldableInput = styled(({ isOpen, ...props }) => <Input {...props} />)`
  && {
    font-size: 0.8rem;
    transition: width 400ms;
    width: ${({ isOpen }) => (isOpen ? '200px' : 0)};
    margin-left: ${({ isOpen, theme }) => isOpen && theme.text.sizes.micro};
  }
`;

const ClickableIcon = styled(({ children, ...otherProps }) => <a {...otherProps}>{children}</a>)`
  color: ${(props) => props.theme.text.colors.darker};
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export default function InputRevealer(props) {
  const trackMixpanelEvent = useMixpanelTrackEvent();

  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState('');
  const textInputRef = useRef(null);

  function handleIconClick() {
    trackMixpanelEvent(`${props.context} - ${QUICK_SEARCH_CLICK}`);
    setIsOpen(true);
    textInputRef.current.focus();
    props.onOpen();
  }

  function handleInputBlur(e) {
    if (value.length === 0) {
      setIsOpen(false);
      props.onClose();
    }
  }

  function handleChange(e) {
    trackMixpanelEvent(`${props.context} - ${QUICK_SEARCH_INPUT}`);

    setValue(e.target.value);
    props.handleChange(e);
  }

  return (
    <Flex alignItems="center" flex="1">
      <ClickableIcon onClick={handleIconClick}>{props.children}</ClickableIcon>
      <FoldableInput
        inputRef={textInputRef}
        isOpen={isOpen}
        value={value}
        onBlur={handleInputBlur}
        onChange={handleChange}
      />
    </Flex>
  );
}

InputRevealer.propTypes = {
  handleChange: PropTypes.func,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
};

InputRevealer.defaultProps = {
  handleChange: () => {},
  onClose: () => {},
  onOpen: () => {},
};
