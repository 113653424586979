import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { GOOGLE_API_ROOT_URL } from '@/apis/googleApi/constants';
import { showErrorSnackbar } from '@/store/actions/ui';

export default function useGetUniversalAnalyticsProperties(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: async ({ accountId, accessToken }) =>
      (
        await axios({
          headers: {
            authorization: 'Bearer ' + accessToken,
          },
          method: 'GET',
          url: `${GOOGLE_API_ROOT_URL}/analytics/v3/management/accounts/${accountId}/webproperties`,
        })
      )?.data,

    onError: () => {
      dispatch(showErrorSnackbar(t('common:error.default')));
    },
    ...props,
  });
}
