import { getCategoriesOptions } from '@/containers/Competitors/components/HeaderFilters/Filters/CategoryFilter/const';
import { getFunnelOptions } from '@/containers/Competitors/components/HeaderFilters/Filters/FunnelFilter/const';
import useValidateFilter from '@/containers/Competitors/hooks/useValidateFilter';
import {
  CATEGORY_FILTER_QUERY_PARAM,
  CLUSTER_FILTER_QUERY_PARAM,
  COMPETITOR_FILTER_QUERY_PARAM,
  FUNNEL_STAGE_FILTER_QUERY_PARAM,
  PERSONA_FILTER_QUERY_PARAM,
} from '@/containers/Competitors/utils/constants';
import { CompetitorFilters } from '@/types/competitors';

export type CompetitorFilterType = {
  id: string;
  [key: string]: any;
};

export default function useCompetitorsFilters({
  clusters,
  personas,
  competitors,
}: {
  clusters: CompetitorFilterType[];
  personas?: CompetitorFilterType[];
  competitors?: CompetitorFilterType[];
}): {
  filters: CompetitorFilters;
} {
  const { getSelectedFilters } = useValidateFilter();

  // add here all hard coded filters
  const categories: CompetitorFilterType[] = getCategoriesOptions();
  const funnels: CompetitorFilterType[] = getFunnelOptions();

  function getFilters(key: string, options?: CompetitorFilterType[]) {
    const filters = getSelectedFilters(key, options || []);

    if (options && filters.length > 0 && filters.length < options.length) {
      return { [key]: filters };
    }

    return {};
  }

  return {
    filters: {
      ...getFilters(CATEGORY_FILTER_QUERY_PARAM, categories),
      ...getFilters(CLUSTER_FILTER_QUERY_PARAM, clusters),
      ...getFilters(PERSONA_FILTER_QUERY_PARAM, personas),
      ...getFilters(FUNNEL_STAGE_FILTER_QUERY_PARAM, funnels),
      ...getFilters(COMPETITOR_FILTER_QUERY_PARAM, competitors),
    },
  };
}
