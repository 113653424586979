/* eslint-disable sort-keys-fix/sort-keys-fix */
import i18next from 'i18next';
import { DateTime } from 'luxon';

import {
  METRICS_CONFIG_PERIOD_LAST_3_MONTHS,
  METRICS_CONFIG_PERIOD_LAST_6_MONTHS,
  METRICS_CONFIG_PERIOD_LAST_12_MONTHS,
  METRICS_CONFIG_PERIOD_LAST_24_MONTHS,
  METRICS_CONFIG_PERIOD_LAST_30_DAYS,
  METRICS_CONFIG_PERIOD_LAST_36_MONTHS,
  METRICS_CONFIG_PERIOD_LAST_90_DAYS,
  METRICS_CONFIG_PERIOD_LAST_180_DAYS,
  METRICS_CONFIG_PERIOD_LAST_365_DAYS,
  METRICS_CONFIG_PERIOD_LAST_MONTH,
} from './constants';

// the last complete rolling period is today - 1 day
const COMPLETE_ROLLING_PERIOD_GAP = 1;

export function getPeriods(todayDate) {
  const today = todayDate ?? DateTime.local();

  const availablePeriods = {
    [METRICS_CONFIG_PERIOD_LAST_30_DAYS]: {
      duration: { days: 30 },
      from: today.minus({ days: 30 }).minus({ days: COMPLETE_ROLLING_PERIOD_GAP }).toSQLDate(),
      name: i18next.t('report:chart.periodicity.last-30-days'),
      size: { days: 30 },
      to: today.minus({ days: COMPLETE_ROLLING_PERIOD_GAP }).toSQLDate(),
    },
    [METRICS_CONFIG_PERIOD_LAST_90_DAYS]: {
      duration: { days: 90 },
      from: today.minus({ days: 90 }).minus({ days: COMPLETE_ROLLING_PERIOD_GAP }).toSQLDate(),
      name: i18next.t('report:chart.periodicity.last-90-days'),
      size: { days: 90 },
      to: today.minus({ days: COMPLETE_ROLLING_PERIOD_GAP }).toSQLDate(),
    },
    [METRICS_CONFIG_PERIOD_LAST_180_DAYS]: {
      duration: { days: 180 },
      from: today.minus({ days: 180 }).minus({ days: COMPLETE_ROLLING_PERIOD_GAP }).toSQLDate(),
      name: i18next.t('report:chart.periodicity.last-180-days'),
      size: { days: 180 },
      to: today.minus({ days: COMPLETE_ROLLING_PERIOD_GAP }).toSQLDate(),
    },
    [METRICS_CONFIG_PERIOD_LAST_365_DAYS]: {
      duration: { days: 365 },
      from: today.minus({ days: 365 }).minus({ days: COMPLETE_ROLLING_PERIOD_GAP }).toSQLDate(),
      name: i18next.t('report:chart.periodicity.last-365-days'),
      size: { days: 365 },
      to: today.minus({ days: COMPLETE_ROLLING_PERIOD_GAP }).toSQLDate(),
    },
    [METRICS_CONFIG_PERIOD_LAST_MONTH]: {
      duration: { months: 1 },
      from: today.minus({ months: 1 }).startOf('month').toSQLDate(),
      name: i18next.t('report:chart.periodicity.last-month'),
      size: { days: 30 },
      to: today.minus({ months: 1 }).endOf('month').toSQLDate(),
    },
    [METRICS_CONFIG_PERIOD_LAST_3_MONTHS]: {
      duration: { months: 3 },
      from: today.minus({ months: 3 }).startOf('month').toSQLDate(),
      name: i18next.t('report:chart.periodicity.last-3-months'),
      size: { days: 90 },
      to: today.minus({ months: 1 }).endOf('month').toSQLDate(),
    },
    [METRICS_CONFIG_PERIOD_LAST_6_MONTHS]: {
      duration: { months: 6 },
      from: today.minus({ months: 6 }).startOf('month').toSQLDate(),
      name: i18next.t('report:chart.periodicity.last-6-months'),
      size: { days: 180 },
      to: today.minus({ months: 1 }).endOf('month').toSQLDate(),
    },
    [METRICS_CONFIG_PERIOD_LAST_12_MONTHS]: {
      duration: { months: 12 },
      from: today.minus({ months: 12 }).startOf('month').toSQLDate(),
      name: i18next.t('report:chart.periodicity.last-12-months'),
      size: { days: 365 },
      to: today.minus({ months: 1 }).endOf('month').toSQLDate(),
    },
    [METRICS_CONFIG_PERIOD_LAST_24_MONTHS]: {
      duration: { months: 24 },
      from: today.minus({ months: 24 }).startOf('month').toSQLDate(),
      name: i18next.t('report:chart.periodicity.last-24-months'),
      size: { days: 730 },
      to: today.minus({ months: 1 }).endOf('month').toSQLDate(),
    },
    [METRICS_CONFIG_PERIOD_LAST_36_MONTHS]: {
      duration: { months: 36 },
      from: today.minus({ months: 36 }).startOf('month').toSQLDate(),
      name: i18next.t('report:chart.periodicity.last-36-months'),
      size: { days: 1095 },
      to: today.minus({ months: 1 }).endOf('month').toSQLDate(),
    },
  };

  return availablePeriods;
}
