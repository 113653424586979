import React from 'react';
interface LegendItemIconPlainProps extends React.SVGProps<SVGSVGElement> {
  color: string;
}

export default function LegendItemIconPlain({
  color,
  children,
  ...otherProps
}: LegendItemIconPlainProps) {
  return (
    <svg viewBox="0 0 12 12" {...otherProps}>
      <circle cx="6" cy="6" fill={color} r="6" />
    </svg>
  );
}
