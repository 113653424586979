import { Trans, useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player/youtube';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import { PrimaryLink } from '@/components/Navigation/Link';
import AIWritingButton from '@/containers/Content/EditorComponents/Ai/WritingButton';
import { VideoWrapper } from '@/containers/Onboarding/common';
import DescribeStepsComponents from '@/containers/Onboarding/DescribeSteps';

const AIWritingWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const StyledAIWritingButton = styled(AIWritingButton)`
  &&& {
    margin: 0 5px;
    height: 20px;
    span {
      height: 15px;
      width: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

function AIWriting() {
  const i18nNameSpace = 'quick-start-guide:body.accordion.create-new-content.ai-writing';
  const { t } = useTranslation();
  const { organizationId, workspaceId } = useParams();

  return (
    <DescribeStepsComponents
      explanatoryText={<Trans i18nKey={`${i18nNameSpace}.explanatory-text`} />}
      steps={[
        <Trans
          key={`${i18nNameSpace}.steps.select-draft`}
          components={{
            planningLink: (
              <PrimaryLink
                href={`/o/${organizationId}/w/${workspaceId}/planning`}
                noDecoration
                size="sm"
                weight="medium"
              />
            ),
          }}
          i18nKey={`${i18nNameSpace}.steps.select-draft`}
        />,
        <AIWritingWrapper key={`${i18nNameSpace}.steps.ai-writing`}>
          <Trans
            components={{
              aiWritingRender: <StyledAIWritingButton active disabled noIntercom />,
            }}
            i18nKey={`${i18nNameSpace}.steps.ai-writing`}
          />
        </AIWritingWrapper>,
        <Trans
          key={`${i18nNameSpace}.steps.generate`}
          i18nKey={`${i18nNameSpace}.steps.generate`}
        />,
      ]}
      visualRendering={
        <VideoWrapper>
          <ReactPlayer
            controls
            height="300px"
            url={t(`${i18nNameSpace}.video-link`)}
            width="468px"
          />
        </VideoWrapper>
      }
    />
  );
}

export default AIWriting;
