function PlusRoundIcon({ className, ...props }) {
  return (
    <svg
      className={className}
      fill="none"
      viewBox="0 0 22 23"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.8">
        <path
          d="M11 22.2C16.799 22.2 21.5 17.4542 21.5 11.6C21.5 5.74579 16.799 1 11 1C5.20101 1 0.5 5.74579 0.5 11.6C0.5 17.4542 5.20101 22.2 11 22.2Z"
          opacity="0.8"
          stroke="#A8A9AF"
          strokeMiterlimit="10"
        />
      </g>
      <path
        d="M10.8001 10.8999V7.19995H11.2001V10.8999V11.3999H11.7001H15.4001V11.8H11.7001H11.2001V12.3V15.9999H10.8001V12.3V11.8H10.3001H6.6001V11.3999H10.3001H10.8001V10.8999Z"
        fill="#A8A9AF"
        opacity="0.8"
        stroke="#A8A9AF"
      />
    </svg>
  );
}

export default PlusRoundIcon;
