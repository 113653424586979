import styled from 'styled-components/macro';

export const GroupFilterWrapper = styled.div`
  font-size: 0.9em;
  border-radius: 3px;
  background-color: ${(props) =>
    props.active ? props.theme.cssColors.primary005 : props.theme.colors.transparent};
  border: 1px solid
    ${(props) => (props.active ? props.theme.cssColors.primary010 : props.theme.cssColors.dark010)};
  cursor: pointer;
  max-width: 350px;
`;

export const FilterWrapper = styled.div`
  border-radius: 3px;
  background-color: ${(props) => (props.error ? props.theme.colors.lightPink : 'transparent')};
  max-width: 100%;
`;
