import Zoom from '@material-ui/core/Zoom';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { matchRoutes, useLocation, useNavigate } from 'react-router-dom';

import { Box, BoxHeader } from '@/components/Box/Box';
import WhiteLogo from '@/components/icons/LogoWhite';
import {
  DEFAULT_BACKGROUND,
  LayoutCenteredContent,
  NO_MENU_LAYOUT,
} from '@/components/Layout/Layout';
import { DefaultLink } from '@/components/Navigation/Link';
import { BlockText } from '@/components/Text/Inline';
import { TitleTwo } from '@/components/Text/Title';
import LayoutWrapper from '@/containers/Layout/Layout';
import { toggleUiMode } from '@/store/actions/ui';
import { fetchAuthenticatedUser } from '@/store/actions/user';
import {
  COOKIE_IMPERSONATE_KEY_NAME,
  removeCookieByKey,
  setImpersonateCookie,
} from '@/utils/cookies/cookies';

function UserImpersonate() {
  const location = useLocation();
  const navigate = useNavigate();
  const matches = matchRoutes(
    [
      { path: '/impersonate/:email/o/:organizationId/w/:workspaceId/*' },
      { path: '/impersonate/:email/o/:organizationId/w/:workspaceId' },
      { path: '/impersonate/:email/o/:organizationId/*' },
      { path: '/impersonate/:email' },
    ],
    location
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [impersonateError, setImpersonateError] = useState(null);
  const uiMode = useSelector((state) => state.uiMode);
  const isUserSuperAdmin = useSelector((state) => state.user?.superAdmin);

  const { params } = matches?.[0] || {};
  const { email, organizationId, workspaceId, '*': route } = params;
  const urlToRedirect = organizationId
    ? workspaceId
      ? route
        ? `/o/${organizationId}/w/${workspaceId}/${route}`
        : `/o/${organizationId}/w/${workspaceId}`
      : route
        ? `/o/${organizationId}/${route}`
        : `/o/${organizationId}`
    : '/';

  useEffect(() => {
    async function impersonate() {
      try {
        setImpersonateCookie(email);
        await dispatch(fetchAuthenticatedUser(true));
        if (uiMode === 'advanced') {
          dispatch(toggleUiMode(isUserSuperAdmin));
        }
        navigate(`${urlToRedirect}${location.search}`, { replace: true });
      } catch (error) {
        removeCookieByKey(COOKIE_IMPERSONATE_KEY_NAME);
        setImpersonateError(true);
      }
    }

    impersonate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LayoutWrapper menu={NO_MENU_LAYOUT} variant={DEFAULT_BACKGROUND}>
      <LayoutCenteredContent>
        <Helmet>
          <title>{t('user:impersonate.title')}</title>
        </Helmet>
        <WhiteLogo height="75" width="150" />
        <Zoom in transition={600}>
          <Box>
            {impersonateError ? (
              <BoxHeader>
                <TitleTwo color="primary" weight="medium">
                  {t('user:impersonate.error.title')}
                </TitleTwo>
                <BlockText color="dark080" weight="medium">
                  {t('user:impersonate.error.message', { email })}
                </BlockText>
                <br />
                <DefaultLink color="dark060" decoration to="/" weight="medium">
                  {t('user:impersonate.error.button-back-dashboard')}
                </DefaultLink>
              </BoxHeader>
            ) : (
              <BoxHeader>
                <TitleTwo color="primary" weight="medium">
                  {t('user:impersonate.content.title')}
                </TitleTwo>
                <BlockText color="dark080" weight="medium">
                  {t('user:impersonate.content.message', { email })}
                </BlockText>
              </BoxHeader>
            )}
          </Box>
        </Zoom>
      </LayoutCenteredContent>
    </LayoutWrapper>
  );
}

export default UserImpersonate;
