import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { DialogModal } from 'semji-core/components/DialogModal';

import usePostOrganizationUnitUsages from '@/apis/semji/organization/usePostOrganizationUnitUsages';
import { showErrorSnackbar } from '@/store/actions/ui';
import { CSV_EXPORT_TYPE, FileType, XLSX_EXPORT_TYPE } from '@/types/export/export.types';

function ExportModal({
  handleCloseExportDialog,
}: {
  handleCloseExportDialog: () => void;
}): React.JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [fileType, setFileType] = React.useState<FileType>(FileType.XLSX);

  const { isLoading, mutate } = usePostOrganizationUnitUsages({
    onError: () => {
      handleCloseExportDialog();
      dispatch(showErrorSnackbar(t('common:error')));
    },
    onSuccess: () => {
      handleCloseExportDialog();
    },
  });

  function handleConfirmExport() {
    mutate({ fileType });
  }

  function handleFileTypeChange(event: React.ChangeEvent<HTMLInputElement>) {
    setFileType(event.target.value as FileType);
  }

  return (
    <DialogModal
      cancelAction={handleCloseExportDialog}
      cancelLabel={t('common:labels.cancel')}
      canClickOutsideToClose
      closable
      confirmAction={handleConfirmExport}
      confirmLabel={t('common:labels.confirm')}
      isCancelDisabled={isLoading}
      isConfirmDisabled={isLoading}
      isOpen
      title={t('settings:credits.export.modal-header')}
      onClose={handleCloseExportDialog}
    >
      <p>{t('settings:credits.export.modal-title')}</p>
      <p className="p-4">
        <RadioGroup value={fileType} onChange={handleFileTypeChange}>
          <FormControlLabel
            control={<Radio color="secondary" />}
            label={XLSX_EXPORT_TYPE.label}
            value={XLSX_EXPORT_TYPE.ext}
          />
          <FormControlLabel
            control={<Radio color="secondary" />}
            label={CSV_EXPORT_TYPE.label}
            value={CSV_EXPORT_TYPE.ext}
          />
        </RadioGroup>
      </p>
      <p>{t('settings:credits.export.modal-footer')}</p>
    </DialogModal>
  );
}

export default ExportModal;
