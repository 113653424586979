import axios from 'axios';
import qs from 'qs';

import apiSettings from '@/apis/semji/settings';

const apiClient = axios.create({
  baseURL: apiSettings.rootUrl,
  headers: {
    Accept: 'application/ld+json,application/json',
    'Content-Type': 'application/json; charset=utf-8',
    'x-semji-client-route': '/', // Will be filled later with axios request interceptors.
    'x-semji-client-type': import.meta.env.VITE_REACT_APP_CLIENT_TYPE,
    'x-semji-client-version': import.meta.env.VITE_REACT_APP_VERSION,
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: 'brackets' }); // arrayFormat: 'brackets' | { a: ['b', 'c'] } => 'a[]=b&a[]=c' (default value will output 'a[0]=b&a[1]=c')
  },
});

export default apiClient;
