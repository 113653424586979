import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation } from 'react-router-dom';

import CompetitorPage, { Headers } from '@/components/CompetitorPage/CompetitorPage';
import ScrollBar from '@/components/Scrollbar';
import { Loader, LoaderText } from '@/design-system/components/Loader';
import CompetitorService from '@/services/Competitor';
import { STATUS_FAILED, STATUS_PENDING, STATUS_QUEUED } from '@/utils/analysis';
import { SECTIONS } from '@/utils/log/constants';
import { Log } from '@/utils/log/Log';

function Competitors({ refreshing, mandatoryRecommendationsReady, ...props }) {
  const { t } = useTranslation();
  const location = useLocation();
  const [state, setState] = useState({
    competitorPages: [],
    expandedPosition: null,
    wait: true,
  });

  useEffect(() => {
    if (!props.loading) {
      getCompetitorsPages(props.pageId);
    }
  }, [props.pageId, props.focusTopKeyword.id, props.loading]);

  async function getCompetitorsPages(pageId) {
    setState({ competitorPages: [], expandedPosition: null, wait: true });
    const _CompetitorService = new CompetitorService(pageId);

    try {
      const competitorPages = await _CompetitorService.competitors;
      setState({ competitorPages, expandedPosition: null, wait: false });
    } catch (error) {
      setState({ competitorPages: [], expandedPosition: null, wait: false });
      Log.report({
        context: 'componentDidMount',
        error,
        extra: 'Competitors',
        section: SECTIONS.content.key,
      });
    }
  }

  function onChange(position) {
    setState({
      ...state,
      expandedPosition: state.expandedPosition === position ? null : position,
    });
  }

  const isNotAllowed =
    !props.loading &&
    !state.wait &&
    (!props.focusTopKeyword.analyzed ||
      [STATUS_FAILED, STATUS_QUEUED].includes(props.focusTopKeyword.analysisStatus) ||
      (props.focusTopKeyword.analysisStatus === STATUS_PENDING && !mandatoryRecommendationsReady));

  if (isNotAllowed) {
    return <Navigate to={location.pathname.split('/').slice(0, -1).join('/')} />;
  }

  if (!props.loading && !state.wait) {
    return (
      <ScrollBar>
        <div className={'px-4 pb-8'}>
          <Headers date={props.focusTopKeyword.analyzedAt} />
          {state.competitorPages.map((competitorPage) => (
            <CompetitorPage
              key={`competitor_position_${competitorPage.position}`}
              expanded={state.expandedPosition === competitorPage.position}
              onChange={onChange}
              {...competitorPage}
            />
          ))}
        </div>
      </ScrollBar>
    );
  }

  return (
    <Loader>
      {refreshing && (
        <LoaderText>
          {t('content:side-panel-components.competitors.updating-competitors')}
        </LoaderText>
      )}
    </Loader>
  );
}

export default memo(Competitors);
