import { useMutation } from '@tanstack/react-query';

import apiClient from '@/apis/semji/apiClient';

export default function usePostStatusFact(props = {}) {
  const { put } = apiClient;

  return useMutation({
    mutationFn: ({ id, status }: { id: string; status: string | null }) =>
      put(`facts/${id}`, {
        status,
      }),
    ...props,
  });
}
