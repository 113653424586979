import i18next from 'i18next';

import { ENUM_CONTENT_TYPE } from '@/types/contents';

import { GroupedVersionType, VersionType } from './VersionGroup.types';

export abstract class VersionGroupUtils {
  static maxDay = 32;
  static maxHour = 24;
  static minDay = 0;
  static maxKey = 10000;

  static groupVersionsByDay(versions: VersionType[]): GroupedVersionType {
    const groupedVersions: { [key: string]: VersionType[] } = {};
    versions.forEach((version) => {
      const day = version.isCurrentDraft ? VersionGroupUtils.minDay : version.day;
      if (!groupedVersions[day]) {
        groupedVersions[day] = [];
      }
      groupedVersions[day].push(version);
    });
    return groupedVersions;
  }

  static groupVersionsByHour(versions: VersionType[]): GroupedVersionType {
    const groupedVersions: { [key: string]: VersionType[] } = {};
    versions.forEach((version) => {
      const day = version.isCurrentDraft ? VersionGroupUtils.maxDay : version.day;
      const hour = version.isCurrentDraft ? VersionGroupUtils.maxHour : version.hour;
      const groupKey = VersionGroupUtils.maxKey - parseInt(`${day}${hour}`); // Reverse order
      if (!groupedVersions[groupKey]) {
        groupedVersions[groupKey] = [];
      }
      groupedVersions[groupKey].push(version);
    });
    return groupedVersions;
  }

  static getAuthorName(item: VersionType) {
    if (item.author) {
      return `${item.author.firstName} ${item.author.lastName}`;
    }
    return i18next.t('content:version-history-panel.unknown-user');
  }

  static getVersionComponentId(versionId: string) {
    return `version-history-panel__item-${versionId}`;
  }

  static getRowNum(name: string) {
    const MAX_LINE_CHARACTERS = 28;
    const rows = Math.min(Math.ceil(name.length / MAX_LINE_CHARACTERS), 4);
    return rows === 0 ? 1 : rows;
  }
}
