import { SET_CONFIGURATIONS } from '@/store/actions/actionTypes';
import {
  ANALYSIS_INCOMING_LINKS_CREDITS_ENABLED,
  PAGE_SNAPSHOT_ENABLED,
  TOP_KEYWORD_SEARCH_CONSOLE_DATA_RETRIEVAL_FOR_ORGANIZATION_IN_TRIAL_ENABLED,
  TOP_PAGES_RETRIEVAL_SCHEDULING_FOR_ORGANIZATION_IN_TRIAL_ENABLED,
  USER_REGISTER_ENABLED,
  USER_REGISTER_GOOGLE_ENABLED,
  WORKSPACE_DELAY_ONBOARDING_ENABLED,
} from '@/utils/configurations/constants';

const INITIAL_STATE = {
  flags: {
    [ANALYSIS_INCOMING_LINKS_CREDITS_ENABLED]: false,
    [PAGE_SNAPSHOT_ENABLED]: false,
    [TOP_KEYWORD_SEARCH_CONSOLE_DATA_RETRIEVAL_FOR_ORGANIZATION_IN_TRIAL_ENABLED]: false,
    [TOP_PAGES_RETRIEVAL_SCHEDULING_FOR_ORGANIZATION_IN_TRIAL_ENABLED]: false,
    [USER_REGISTER_ENABLED]: false,
    [USER_REGISTER_GOOGLE_ENABLED]: false,
    [WORKSPACE_DELAY_ONBOARDING_ENABLED]: false,
  },
  isFirstRun: true,
};

export default function configurations(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case SET_CONFIGURATIONS: {
      const { configurations } = payload || {};

      return {
        ...state,
        flags: {
          ...state.flags,
          ...configurations,
        },
        isFirstRun: false,
        isNewVersionAvailable: false,
      };
    }
    default:
      return state;
  }
}
