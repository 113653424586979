import './LegendItem.scss';

import i18next from 'i18next';
import React from 'react';

import {
  comparisonPeriods,
  METRIC_POSITION_KEY,
  METRICS_CONFIG_PERIODICITY_DAILY,
} from '@/utils/metrics/constants';
import { formatDateByPeriodicity } from '@/utils/metrics/formatDateByPeriodicity';
import { getPreviousDateRangeSQLFormat } from '@/utils/metrics/getPreviousDateRangeSQLFormat';

import LegendItemIconPlain from './LegendItemIconPlain';

interface LegendItemProps {
  serie: any;
  period: string;
  comparisonPeriod: string;
  children?: React.ReactNode;
  stacked: boolean;
  points: any[];
  locale: string;
  isVisible?: boolean;
}
export default function LegendItem({
  serie,
  period,
  comparisonPeriod,
  children,
  stacked,
  points,
  locale,
  isVisible = true,
  ...otherProps
}: LegendItemProps) {
  if (!stacked && serie.period === 'current') {
    return (
      <div
        className={`chart-legend-item ${!isVisible && 'chart-legend-item--hidden'}`}
        {...otherProps}
      >
        <LegendItemIconPlain color={serie.color} />
        <div>{i18next.t(serie.label) ?? serie.name}</div>
      </div>
    );
  }

  if (stacked && serie.period === 'current') {
    return (
      <div
        className={`chart-legend-item ${!isVisible && 'chart-legend-item--hidden'}`}
        {...otherProps}
      >
        <LegendItemIconPlain color={serie.color} />
        <div>{serie.metric.title ?? serie.label}</div>
      </div>
    );
  }

  if (!stacked && serie.period === 'previous') {
    // No previous legend for rank tracking
    if (serie.metric.key === METRIC_POSITION_KEY) {
      return null;
    }

    const previousRange = getPreviousDateRangeSQLFormat({ comparisonPeriod, period });
    const fromDate = formatDateByPeriodicity({
      date: previousRange.from,
      locale,
      periodicity: METRICS_CONFIG_PERIODICITY_DAILY,
    });
    const toDate = formatDateByPeriodicity({
      date: previousRange.to,
      locale,
      periodicity: METRICS_CONFIG_PERIODICITY_DAILY,
    });

    return (
      <div
        className={`chart-legend-item ${!isVisible && 'chart-legend-item--hidden'}`}
        {...otherProps}
      >
        <LegendItemIconPlain color={serie.color} />
        <div>{`${i18next.t(
          comparisonPeriods[comparisonPeriod].label
        )} (${fromDate} - ${toDate})`}</div>
      </div>
    );
  }

  return null;
}
