import { useState } from 'react';
import { GithubPicker } from 'react-color';
import styled from 'styled-components/macro';

import { ColoredDot } from '@/components/_common';

const Picker = styled(ColoredDot)`
  width: 15px;
  height: 15px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;
const Popover = styled.div`
  position: absolute;
  z-index: 2;
`;
const Cover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;
const COLORS = [
  '#FFA6B1',
  '#FF7183',
  '#FFAC34',
  '#FF8F54',
  '#FA7063',
  '#95C8FD',
  '#3587F9',
  '#5257D8',
  '#99A689',
  '#B8DF63',
];

function DotColorPicker({ value, onChange, disabled }) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  function handleClick() {
    if (!disabled) {
      setDisplayColorPicker(!displayColorPicker);
    }
  }

  function handleClose() {
    setDisplayColorPicker(false);
  }

  function handleChange(color) {
    onChange(color.hex);
  }

  return (
    <div>
      <Picker color={value} disabled={disabled} onClick={handleClick} />
      {displayColorPicker ? (
        <Popover>
          <Cover onClick={handleClose} />
          <GithubPicker
            color={value}
            colors={COLORS}
            triangle="hide"
            width="125px"
            onChange={handleChange}
          />
        </Popover>
      ) : null}
    </div>
  );
}

export default DotColorPicker;
