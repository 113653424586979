import styled from 'styled-components/macro';

import Button from './Button';

const TertiaryButton = styled(Button)`
  color: ${({ disabled, theme }) => (disabled ? theme.cssColors.dark020 : theme.cssColors.dark060)};
  background: ${({ theme }) => theme.cssColors.white};
  border: 1px solid ${({ theme }) => theme.cssColors.dark010};

  &:hover,
  &:active,
  &.active {
    color: ${({ disabled, theme }) =>
      disabled ? theme.cssColors.dark020 : theme.cssColors.dark080};
    background: ${({ disabled, theme }) =>
      disabled ? theme.cssColors.white : theme.cssColors.dark010};
  }
`;

export default TertiaryButton;
