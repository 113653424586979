export const MIN_ZOOM_REACT_FLOW = '0.2';

export const MAX_EXPANDED_NODES = 5;

export const CLUSTER_NODE_TYPE = 'clusterNode';
export const KEYWORD_NODE_TYPE = 'keywordNode';
export const MAIN_KEYWORD_NODE_TYPE = 'mainKeywordNode';
export const SHOW_MORE_NODE_TYPE = 'showMoreNode';

export const ORIENTATION_HORIZONTAL = 'horizontal';
export const ORIENTATION_VERTICAL = 'vertical';

export const MIND_MAP_DETAILED_VIEW = 'detailed';
export const MIND_MAP_SIMPLIFIED_VIEW = 'simplified';

export const MIND_MAP_DETAILED_VIEW_LABEL = 'content-ideas:mind-map.controls.detailed-view';
export const MIND_MAP_DETAILED_VIEW_TOOLTIP =
  'content-ideas:mind-map.controls.detailed-view-tooltip';
export const MIND_MAP_SIMPLIFIED_VIEW_LABEL = 'content-ideas:mind-map.controls.simplified-view';
export const MIND_MAP_SIMPLIFIED_VIEW_TOOLTIP =
  'content-ideas:mind-map.controls.simplified-view-tooltip';

export const MIND_MAP_VIEW_LIST = [
  {
    labelKey: MIND_MAP_SIMPLIFIED_VIEW_LABEL,
    tooltipKey: MIND_MAP_SIMPLIFIED_VIEW_TOOLTIP,
    value: MIND_MAP_SIMPLIFIED_VIEW,
  },
  {
    labelKey: MIND_MAP_DETAILED_VIEW_LABEL,
    tooltipKey: MIND_MAP_DETAILED_VIEW_TOOLTIP,
    value: MIND_MAP_DETAILED_VIEW,
  },
];

export const DETAILED_TREE_NODE_HEIGHT = 125;
export const DETAILED_TREE_NODE_WIDTH = 500;

export const SIMPLIFIED_TREE_NODE_HEIGHT = 60;
export const SIMPLIFIED_TREE_NODE_WIDTH = 300;

export const ANIMATION_DURATION = 200;
