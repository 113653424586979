import { useMutation } from '@tanstack/react-query';

import apiClient from '@/apis/semji/apiClient';
import { AI_WRITING_CREDITS_TYPE } from '@/utils/constants';

export default function usePutWorkspaceUnitLimitation(props) {
  const { put } = apiClient;

  return useMutation({
    mutationFn: async ({
      id,
      amount,
      limitationDuration = 365,
      type = AI_WRITING_CREDITS_TYPE,
    }) => {
      const result = await put(`/workspace_unit_limitations/${id}`, {
        amount,
        limitationDuration,
        type,
      });

      return result;
    },

    ...props,
  });
}
