import React from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import InfoIcon from '@/components/icons/InfoIcon';
import { DefaultLink } from '@/components/Navigation/Link';
import { InlineText } from '@/components/Text/Inline';
import { ParagraphText } from '@/components/Text/Paragraph';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import { selectOrganizationAvailableSeats } from '@/store/selectors/selectOrganizationAvailableSeats';
import { selectOrganizationMaxAllowedUsers } from '@/store/selectors/selectOrganizationMaxAllowedUsers';

const AVAILABLE_SEATS_ERROR_THRESHOLD = 0;
const StyledInfoIcon = styled(InfoIcon)`
  && {
    font-size: 0.5rem;
    margin-right: 0.5rem;
  }
`;

function ErrorAvailableSeatsLeftMessage({ seatsWillBeConsumed, organizationId }) {
  const availableSeats = useSelector(selectOrganizationAvailableSeats);
  const maxAllowedUsers = useSelector(selectOrganizationMaxAllowedUsers);
  const { isFeatureEnabled } = useOrganizationFeatureSet('users:invitations:has-unlimited-amount');

  if (!isFeatureEnabled && availableSeats - seatsWillBeConsumed < AVAILABLE_SEATS_ERROR_THRESHOLD) {
    return (
      <Trans
        components={{
          dLink: (
            <DefaultLink
              color="red"
              decoration
              isExternal
              to={`/o/${organizationId}/billing/settings`}
            />
          ),
          icon: <StyledInfoIcon bordered />,
          paragraph: <ParagraphText color="red" noPadding />,
          strongText: <InlineText color="red" weight="strong" />,
          text: <InlineText color="red" />,
        }}
        i18nKey={'components:message.error-available-seats-left.content'}
        values={{ maxAllowedUsers }}
      />
    );
  }

  return null;
}

export default ErrorAvailableSeatsLeftMessage;
