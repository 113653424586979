import React from 'react';

import SvgWrapper from './SvgWrapper';

const LayoutGrid2AltIcon = (props) => (
  <SvgWrapper {...props}>
    <path d="M0 0h7v7h-7v-7zM9 0v7h7v-7h-7zM0 16h7v-7h-7v7zM9 16h7v-7h-7v7z" />
  </SvgWrapper>
);

export default LayoutGrid2AltIcon;
