import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import { PlanRestrictionBadge } from '@/components/Badge/PlanRestrictionBadge';
import CountryLanguageFlag from '@/components/Flag/CountryLanguage';
import CaretIcon from '@/components/icons/CaretIcon';
import SearchIcon from '@/components/icons/SearchIcon';
import { Divider } from '@/components/Profile/ProfileBox';
import { DarkText } from '@/components/Text/Dark';
import Flex from '@/design-system/components/Flex';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';

const Caret = styled(({ dark, ...props }) => <CaretIcon {...props} />)`
  margin-left: 10px;
  color: ${(props) => props.dark && props.theme.colors.grey};
`;

const CountryName = styled(DarkText)`
  margin-left: 10px;
  font-size: 0.8em;
  color: ${({ theme, isDisabled }) =>
    isDisabled ? theme.cssColors.dark060 : theme.cssColors.dark100};
`;

const CountryWrapper = styled.div`
  display: flex;
  flex: 1;
  margin-left: 15px;
`;
const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 1em;
  padding: 0.4em ${(props) => (props.inlineVersion ? '0' : '1em')};
  min-width: 3em;
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
  justify-content: ${(props) => (props.inlineVersion ? 'space-between' : 'initial')};
`;
const StyledPopper = styled(Popper)`
  && {
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    width: 250px;
    z-index: 1;
    background-color: white;
  }
`;
const StyledAutoComplete = withStyles({
  listbox: {
    maxHeight: '250px',
    padding: 0,
  },
  option: {
    '&&[aria-disabled="true"]': {
      backgroundColor: 'white',
      opacity: '1 !important',
      pointerEvents: 'inherit',
    },
    alignItems: 'center',
    minHeight: 'auto',
    padding: '10px 0 10px 20px',
  },
  paper: {
    backgroundColor: 'white',
    boxShadow: 'none',
    margin: '0',
    maxHeight: '250px',
    padding: '0 0 .1em 0',
  },

  popperDisablePortal: {
    position: 'relative',
    width: '100% !important',
  },
  root: {
    padding: '10px 10px 10px 20px',
  },
})(Autocomplete);

function SearchInput({ params }) {
  const [value, setValue] = useState('');

  return (
    <TextField
      ref={params.InputProps.ref}
      inputProps={{
        ...params.inputProps,
        onChange: (e, newValue) => {
          params.inputProps.onChange(e, newValue);
          setValue(newValue);
        },
        placeholder: 'Search countries',
        value,
      }}
      InputProps={{
        endAdornment: <SearchIcon />,
      }}
      size="small"
      variant="outlined"
    />
  );
}

export default function CountryLanguageSuggest(props) {
  const { data: allowedCountries = [] } = useOrganizationFeatureSet(
    'organization:workspaces:allowed-country-language'
  );
  const [selectedCountryLanguage, setSelectedCountryLanguage] = useState(props.defaultValue);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setSelectedCountryLanguage(props.defaultValue);
  }, [props.defaultValue]);

  const options = props.options || [];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const handleCountryLanguageChange = (e, newValue) => {
    if (newValue.disabled) {
      return;
    }

    setSelectedCountryLanguage(newValue);
    props.onChange(newValue);
  };

  const groupBy = (option) => {
    if (option.primary) return 'PRIMARY';

    return 'OTHERS';
  };

  const renderGroup = (params) => {
    if (params.group === 'PRIMARY') return params.children;

    return (
      <div key={params.key}>
        <Divider />
        {params.children}
      </div>
    );
  };

  const open = Boolean(anchorEl);
  const id = open ? 'country-language' : undefined;

  function getIsOptionDisabled(option) {
    if (!Array.isArray(allowedCountries)) return false;
    return !allowedCountries.includes(option.languageCode + '-' + option.countryCode);
  }

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <Wrapper aria-describedby={id} inlineVersion={props.inlineVersion} onClick={handleClick}>
          <CountryLanguageFlag
            countryCode={selectedCountryLanguage.countryCode}
            isObvious={selectedCountryLanguage.isObvious}
            languageCode={selectedCountryLanguage.languageCode}
          />
          {props.inlineVersion && (
            <CountryWrapper>{selectedCountryLanguage.country}</CountryWrapper>
          )}
          <Caret dark />
        </Wrapper>
        <StyledPopper anchorEl={anchorEl} id={id} open={open} placement="bottom-end">
          <StyledAutoComplete
            disableClearable
            disablePortal
            getOptionDisabled={(option) => option.disabled}
            getOptionLabel={(option) => option.country}
            getOptionSelected={(option, value) =>
              option.countryCode === value.countryCode && option.languageCode === value.languageCode
            }
            groupBy={groupBy}
            open
            options={options.map((option) => ({
              ...option,
              disabled: getIsOptionDisabled(option),
            }))}
            renderGroup={renderGroup}
            renderInput={(params) => <SearchInput params={params} />}
            renderOption={(option) => {
              return (
                <Flex display="flex" marginRight="16px" width="100%">
                  <Flex display="flex" width="100%">
                    <CountryLanguageFlag
                      key={option.countryCode}
                      countryCode={option.countryCode}
                      isDisabled={option.disabled}
                      isObvious={option.isObvious}
                      languageCode={option.languageCode}
                    />
                    <CountryName isDisabled={option.disabled}>{option.country}</CountryName>
                  </Flex>
                  {option.disabled && <PlanRestrictionBadge isCondensed />}
                </Flex>
              );
            }}
            value={selectedCountryLanguage}
            onChange={handleCountryLanguageChange}
            onClose={handleClose}
          />
        </StyledPopper>
      </div>
    </ClickAwayListener>
  );
}
