import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import { downloadBrief, fetchBrief } from '@/apis/semji/api';
import {
  deleteGeneratedSearchIntent,
  deleteGeneratedTitle,
  generateBriefSearchIntents,
  generateBriefTitles,
  reportGeneratedSearchIntent,
  reportGeneratedTitle,
} from '@/apis/semji/api';
import usePutGeneratedSearchIntentsReport from '@/apis/semji/generated/usePutGeneratedSearchIntentsReport';
import usePutGeneratedTitleReport from '@/apis/semji/generated/usePutGeneratedTitleReport';
import { BetaBadge } from '@/components/Badge/BetaBadge';
import BriefEmptyState from '@/components/EmptyState/BriefEmptyState';
import ColoredReportIcon from '@/components/icons/ColoredReportIcon';
import NotAnalyzedIcon from '@/components/icons/NotAnalyzedIcon';
import RetrieveLoader from '@/components/Loader/RetrieveLoader';
import { AiContentPermissionModal } from '@/components/modal/AiContentPermissionModal';
import { WorkspaceUnitLimitationModal } from '@/components/modal/WorkspaceUnitLimitationModal/WorkspaceUnitLimitationModal';
import ScrollBar from '@/components/Scrollbar';
import { BlockText } from '@/components/Text/Inline';
import { TitleFour, TitleTwo } from '@/components/Text/Title';
import {
  SEARCH_INTENT_REPORT_TYPE,
  TITLE_REPORT_TYPE,
} from '@/containers/Content/BriefComponents/constants';
import FlagContentDialog from '@/containers/Content/BriefComponents/FlagContentDialog';
import Body from '@/containers/Content/BriefComponents/Layout/Body';
import ContentSection from '@/containers/Content/BriefComponents/Layout/ContentSection';
import ScrollWrapper from '@/containers/Content/BriefComponents/Layout/ScrollWrapper';
import Section from '@/containers/Content/BriefComponents/Layout/Section';
import TopBar from '@/containers/Content/BriefComponents/Layout/TopBar';
import CompetitorsArticles from '@/containers/Content/BriefComponents/Tables/CompetitorsLength';
import CompetitorsTitles from '@/containers/Content/BriefComponents/Tables/CompetitorsTitles';
import IncomingLinks from '@/containers/Content/BriefComponents/Tables/IncomingLinks';
import Questions from '@/containers/Content/BriefComponents/Tables/Questions';
import RelatedTerms from '@/containers/Content/BriefComponents/Tables/RelatedTerms';
import SearchIntents from '@/containers/Content/BriefComponents/Tables/SearchIntents';
import TitleIdeas from '@/containers/Content/BriefComponents/Tables/TitleIdeas';
import { useContentContainerContext } from '@/containers/Content/ContentContainerContext';
import useApiConfigurations from '@/hooks/useApiConfigurations';
import useCan from '@/hooks/useCan';
import { useMixpanelTrackEvent } from '@/hooks/useMixpanelTrackEvent';
import { showUpgradePlanModal } from '@/store/actions/billing';
import { closeSidePanel, openSidePanel } from '@/store/actions/report';
import { showErrorSnackbar, showSuccessSnackbar } from '@/store/actions/ui';
import useParamSelector from '@/store/hooks/useParamSelector';
import { selectCurrentWorkspaceAccessByKey } from '@/store/selectors/selectWorkspaceAccessByKey';
import { VIEW_BRIEF } from '@/utils/3rdParty/Mixpanel/constants';
import { STATUS_PENDING, STATUS_QUEUED, STATUS_SUCCESS } from '@/utils/analysis';
import {
  AI_WRITING_CREDITS_BRIEF_TITLE,
  CONTENT_AI_WRITING_CREDITS_ENABLED,
} from '@/utils/configurations/constants';
import { downloadFileFromBlob } from '@/utils/url';

const StyledTitleTwo = styled(TitleTwo)`
  && {
    ${(props) => props.theme.mediaQueries.largeDesktop} {
      font-size: ${(props) => props.theme.textCss.sizes.lg};
    }
  }
`;
const StyledTitleFour = styled(TitleFour)`
  && {
    ${(props) => props.theme.mediaQueries.largeDesktop} {
      font-size: ${(props) => props.theme.textCss.sizes.default};
    }
  }
`;

const INITIAL_STATE = {
  competitorTitles: [],
  generatedQuestions: [],
  generatedSearchIntents: [],
  generatedTitles: [],
  questions: [],
  searchIntents: [],
};

const KEYWORD_LOADING = 'KEYWORD_LOADING';
const KEYWORD_NOT_ANALYZED = 'KEYWORD_NOT_ANALYZED';
const KEYWORD_ANALYZED = 'KEYWORD_ANALYZED';

const TITLE_GENERATIONS_LIMIT_PER_USER = 'TITLE_GENERATIONS_LIMIT_PER_USER';
const TITLE_GENERATIONS_LIMIT_PER_ORGANIZATION = 'TITLE_GENERATIONS_LIMIT_PER_ORGANIZATION';

function Brief({ isContentBriefEnabled, topKeywordUpdating }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { workspaceId } = useParams();
  const {
    aiWriting: {
      hasUnlimitedAIWriting,
      remainingAiWritingUnit,
      limitAiWriting: { setRefetchAiUnit, setShowLimitAiWriting },
    },
  } = useContentContainerContext();

  const briefTitleCredits = useApiConfigurations(AI_WRITING_CREDITS_BRIEF_TITLE);
  const briefSearchIntentCredits = useApiConfigurations(AI_WRITING_CREDITS_BRIEF_TITLE);

  const aiWritingCreditsEnabled = useApiConfigurations(CONTENT_AI_WRITING_CREDITS_ENABLED);

  const accessToAiFeatures = useParamSelector(selectCurrentWorkspaceAccessByKey, {
    key: 'accessToAiFeatures',
    workspaceId: workspaceId,
  });
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const [showWorkspaceUnitLimitationModal, setShowWorkspaceUnitLimitationModal] = useState(false);

  const isSidePanelOpen = useSelector((state) => state.report?.isSidePanelOpen);
  const reportLastUpdate = useSelector((state) => state.report?.reportLastUpdate);

  const analysisStatus = useSelector((state) => state.report?.focusTopKeyword?.analysisStatus);
  const analyzed = useSelector((state) => state.report?.focusTopKeyword?.analyzed);
  const keywordId = useSelector((state) => state.report?.focusTopKeyword?.id);
  const keyword = useSelector((state) => state.report?.focusTopKeyword?.keyword);
  const keywordState = useMemo(() => {
    if (
      topKeywordUpdating.loading ||
      (analyzed && [STATUS_PENDING, STATUS_QUEUED].includes(analysisStatus))
    ) {
      return KEYWORD_LOADING;
    }

    if (!analyzed && !analysisStatus) {
      return KEYWORD_NOT_ANALYZED;
    }

    if (analyzed && analysisStatus === STATUS_SUCCESS) {
      return KEYWORD_ANALYZED;
    }
  }, [analysisStatus, analyzed, topKeywordUpdating.loading]);

  const isBuildPdfEnabled = useCan({ perform: 'pdf:build' });

  const fetchRef = useRef(null);
  const [isBriefDataLoading, setIsBriefDataLoading] = useState(false);
  const [isBriefPdfDownloading, setIsBriefPdfDownloading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDialogLoading, setIsDialogLoading] = useState(false);
  const [isLimited, setIsLimited] = useState(false);
  const [results, setResults] = useState({
    ...INITIAL_STATE,
  });

  const putGeneratedTitleReport = usePutGeneratedTitleReport();
  const putGeneratedSearchIntentsReport = usePutGeneratedSearchIntentsReport();

  const trackMixpanelEvent = useMixpanelTrackEvent();

  const fetchBriefData = (isUpdate) => {
    if (keywordId && keywordState === KEYWORD_ANALYZED && isContentBriefEnabled) {
      if (!isUpdate) {
        setIsBriefDataLoading(true);
      }

      // Extract the query to return it and give ability to cancel the request.
      const query = fetchBrief(keywordId);

      query
        .then(
          ({
            areGeneratedSearchIntentsSafe,
            areGeneratedTitlesSafe,
            competitorTitles,
            generatedSearchIntents,
            generatedTitles,
            incomingLinks,
            questions,
            searchIntents,
            topics,
            topKeyword,
            competitorsArticles,
          }) => {
            setResults({
              ...results,
              areGeneratedSearchIntentsSafe,
              areGeneratedTitlesSafe,
              competitorTitles,
              competitorsArticles,
              generatedSearchIntents,
              generatedTitles,
              incomingLinks,
              questions,
              safeForGeneratingTitles: topKeyword?.safeForGeneratingTitles,
              searchIntents,
              topKeyword,
              topics,
            });
          }
        )
        .catch(() => {
          dispatch(showErrorSnackbar(t('content:brief.fetch-brief-error')));
        })
        .finally(() => {
          setIsBriefDataLoading(false);
          fetchRef.current = null;
        });

      return query;
    }
  };

  const handleBriefExportPdfClick = async () => {
    if (isBriefPdfDownloading) {
      return;
    }

    try {
      setIsBriefPdfDownloading(true);
      const fileBlob = await downloadBrief(keywordId);
      await downloadFileFromBlob({ fileBlob, filePrefix: 'brief', fileType: 'pdf' });
    } catch (error) {
      dispatch(showErrorSnackbar(t('content:brief.build-pdf-error-snackbar')));
    } finally {
      setIsBriefPdfDownloading(false);
    }
  };

  const handleGenerateApiError = (status, type, retryAfter) => {
    setRefetchAiUnit((prevState) => prevState + 1);
    if (status === 429) {
      setShowWorkspaceUnitLimitationModal(true);
      setIsLimited(true);
      const currentTime = Math.round(new Date() / 1000);
      const unlockLimiterIn = Math.max(0, retryAfter - currentTime) * 1000;
      if (type === TITLE_GENERATIONS_LIMIT_PER_USER) {
        dispatch(showErrorSnackbar(t('content:brief.rate-limiter-user-error-message')));
      }
      if (type === TITLE_GENERATIONS_LIMIT_PER_ORGANIZATION) {
        dispatch(
          showErrorSnackbar(
            showErrorSnackbar(t('content:brief.rate-limiter-organization-error-message'))
          )
        );
      }
      setTimeout(() => {
        setIsLimited(false);
      }, unlockLimiterIn);
    } else {
      dispatch(showErrorSnackbar(t('content:brief.fetch-brief-error')));
    }
  };

  const handleFlagContent = (item) => {
    setFlaggedContent(item);
    if (item.type === TITLE_REPORT_TYPE) {
      putGeneratedTitleReport.mutate({
        ids: [item.id],
        report: { rating: 'negative' },
      });
    }
    if (item.type === SEARCH_INTENT_REPORT_TYPE) {
      putGeneratedSearchIntentsReport.mutate({
        ids: [item.id],
        report: { rating: 'negative' },
      });
    }
    setIsDialogOpen(true);
  };

  const handleReportConfirmClick = async ({ id, report, type }) => {
    try {
      setIsDialogLoading(true);

      const func =
        type === TITLE_REPORT_TYPE
          ? reportGeneratedTitle
          : type === SEARCH_INTENT_REPORT_TYPE
            ? reportGeneratedSearchIntent
            : undefined;

      await func?.(id, report);
      dispatch(showSuccessSnackbar(t('content:brief.flag-content-success-snackbar')));
    } catch {
      dispatch(showErrorSnackbar(t('content:brief.flag-content-error-snackbar')));
    } finally {
      setIsDialogLoading(false);
      setIsDialogOpen(false);
      setFlaggedContent({});
    }
  };

  /* ===== Titles ===== */

  const [flaggedContent, setFlaggedContent] = useState({});

  const generateTitles = useCallback(
    () => generateBriefTitles(keywordId),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [keywordId]
  );

  const handleGenerateTitlesClick = () => {
    if (!accessToAiFeatures) {
      setShowPermissionModal(true);
      return;
    }

    if (
      aiWritingCreditsEnabled &&
      !hasUnlimitedAIWriting &&
      remainingAiWritingUnit < briefTitleCredits
    ) {
      setShowLimitAiWriting(true);
    } else {
      return generateTitles()
        .then(({ competitorTitles, generatedTitles, areGeneratedTitlesSafe, topKeyword }) => {
          setRefetchAiUnit((prevState) => prevState + 1);
          setResults({
            ...results,
            areGeneratedTitlesSafe,
            competitorTitles,
            generatedTitles,
            safeForGeneratingTitles: topKeyword.safeForGeneratingTitles,
          });
        })
        .catch((error) => handleGenerateApiError(error.status, error.type, error?.retryAfter));
    }
  };

  const handleRemoveGenerateTitlesClick = (id) => {
    const originalGeneratedTitles = [...generatedTitles];
    const cleanedGeneratedTitles = generatedTitles.filter(
      (generatedTitle) => generatedTitle.id !== id
    );
    setResults({
      ...results,
      generatedTitles: cleanedGeneratedTitles,
    });

    deleteGeneratedTitle(id)
      .then(() => {
        dispatch(showSuccessSnackbar(t('content:brief.generated-title-removal-success-snackbar')));
      })
      .catch(() => {
        setResults({
          ...results,
          generatedTitles: originalGeneratedTitles,
        });
        dispatch(showErrorSnackbar(t('content:brief.generated-title-removal-error-snackbar')));
      });
  };

  /* ===== Search Intents ===== */

  const generateSearchIntent = useCallback(
    (searchIntentId) => generateBriefSearchIntents(keywordId, searchIntentId),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [keywordId]
  );

  const handleGenerateSearchIntentsClick = (searchIntentId) => {
    if (!accessToAiFeatures) {
      setShowPermissionModal(true);
      return;
    }

    if (
      aiWritingCreditsEnabled &&
      !hasUnlimitedAIWriting &&
      remainingAiWritingUnit < briefSearchIntentCredits
    ) {
      setShowLimitAiWriting(true);
    } else {
      return generateSearchIntent(searchIntentId)
        .then(({ areGeneratedSearchIntentsSafe, generatedSearchIntents }) => {
          setRefetchAiUnit((prevState) => prevState + 1);
          setResults({
            ...results,
            areGeneratedSearchIntentsSafe,
            generatedSearchIntents,
          });
        })
        .catch((error) => handleGenerateApiError(error.status, error.type, error?.retryAfter));
    }
  };

  const handleRemoveGeneratedSearchIntentClick = (id) => {
    const originalGeneratedSearchIntents = [...generatedSearchIntents];
    const cleanedGeneratedSearchIntents = generatedSearchIntents.filter(
      ({ id: genId }) => genId !== id
    );
    setResults({
      ...results,
      generatedSearchIntents: cleanedGeneratedSearchIntents,
    });

    deleteGeneratedSearchIntent(id)
      .then(() => {
        dispatch(
          showSuccessSnackbar(t('content:brief.generated-search-intent-removal-success-snackbar'))
        );
      })
      .catch(() => {
        setResults({
          ...results,
          generatedSearchIntents: originalGeneratedSearchIntents,
        });
        dispatch(
          showErrorSnackbar(t('content:brief.generated-search-intent-removal-error-snackbar'))
        );
      });
  };

  /* ===== Effects ===== */

  useEffect(() => {
    const originalSidePanelState = isSidePanelOpen;
    dispatch(closeSidePanel());

    // Tracking
    trackMixpanelEvent(VIEW_BRIEF);

    return () => {
      if (originalSidePanelState) {
        dispatch(openSidePanel());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchRef.current = fetchBriefData();

    return () => fetchRef.current?.cancel?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isContentBriefEnabled, keywordState, keywordId]);

  /* Relaunch api request when an item is updated in the side panel component.
  The flag `isUpdate` prevent loaders to appear again. */
  useEffect(() => {
    if (!fetchRef.current) {
      fetchRef.current = fetchBriefData(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportLastUpdate]);

  const {
    areGeneratedSearchIntentsSafe = true,
    areGeneratedTitlesSafe = true,
    competitorTitles = [],
    generatedSearchIntents = [],
    generatedTitles = [],
    incomingLinks = [],
    questions = [],
    safeForGeneratingTitles = true,
    searchIntents = [],
    topics = [],
    competitorsArticles = [],
  } = results;

  return (
    <Body>
      <TopBar
        data-intercom-target="editor_taskbar_wrapper"
        handleBriefExportPdfClick={handleBriefExportPdfClick}
        isBriefPdfDownloadable={
          isBuildPdfEnabled &&
          keywordId &&
          keywordState === KEYWORD_ANALYZED &&
          isContentBriefEnabled &&
          !isBriefDataLoading
        }
        isBriefPdfDownloading={isBriefPdfDownloading}
        isSidePanelOpen={isSidePanelOpen}
      >
        {topKeywordUpdating.loading ? topKeywordUpdating.keyword : keyword}{' '}
        <BetaBadge>{t('content:brief.beta')}</BetaBadge>
      </TopBar>
      {isContentBriefEnabled ? (
        <ScrollWrapper>
          <ScrollBar
            customTrackStyle={{
              zIndex: 1,
            }}
          >
            <ContentSection>
              {keywordState === KEYWORD_LOADING && (
                <RetrieveLoader>{t('content:brief.loader-message')}</RetrieveLoader>
              )}

              {keywordState === KEYWORD_NOT_ANALYZED && (
                <BriefEmptyState
                  body={t('content:brief.empty-state.not-analyzed-yet.text')}
                  Icon={NotAnalyzedIcon}
                  title={t('content:brief.empty-state.not-analyzed-yet.title')}
                />
              )}

              {keywordState === KEYWORD_ANALYZED && (
                <>
                  {/* Title */}
                  <Section>
                    <StyledTitleTwo noMargin weight="strong">
                      {t('content:brief.section-title')}
                    </StyledTitleTwo>
                    <BlockText color="dark060" size="sm" weight="normal">
                      {t('content:brief.section-title-description')}
                    </BlockText>
                    <br />

                    <CompetitorsTitles isLoading={isBriefDataLoading} titles={competitorTitles} />

                    <TitleIdeas
                      areGeneratedTitlesSafe={areGeneratedTitlesSafe}
                      isLimited={isLimited}
                      safeForGeneratingTitles={safeForGeneratingTitles}
                      titles={generatedTitles}
                      onDeleteContent={handleRemoveGenerateTitlesClick}
                      onFlagContent={handleFlagContent}
                      onGenerate={handleGenerateTitlesClick}
                    />
                  </Section>

                  <Section>
                    {/* Content Writing */}
                    <StyledTitleTwo noMargin weight="strong">
                      {t('content:brief.section-article-length')}
                    </StyledTitleTwo>
                    <br />

                    {/* Length */}
                    <BlockText color="dark060" size="sm" weight="normal">
                      {t('content:brief.length-description')}
                    </BlockText>
                    <br />
                    <CompetitorsArticles
                      competitorsArticles={competitorsArticles}
                      isLoading={isBriefDataLoading}
                    />
                  </Section>

                  <Section>
                    {/* Content Outline */}
                    <StyledTitleTwo noMargin weight="strong">
                      {t('content:brief.section-article')}
                    </StyledTitleTwo>
                    <br />

                    {/* Search Intents */}
                    <StyledTitleFour noMargin weight="strong">
                      {t('content:brief.section-article-search-intents')}
                    </StyledTitleFour>
                    <BlockText color="dark060" size="sm" weight="normal">
                      {t('content:brief.section-article-search-intents-description')}
                    </BlockText>
                    <br />
                    <SearchIntents
                      areGeneratedSearchIntentsSafe={areGeneratedSearchIntentsSafe}
                      generatedItems={generatedSearchIntents}
                      isLimited={isLimited}
                      isLoading={isBriefDataLoading}
                      searchIntents={searchIntents}
                      onDeleteContent={handleRemoveGeneratedSearchIntentClick}
                      onFlagContent={handleFlagContent}
                      onGenerate={handleGenerateSearchIntentsClick}
                    />
                    <br />

                    {/* Questions */}
                    <StyledTitleFour noMargin weight="strong">
                      {t('content:brief.section-article-questions')}
                    </StyledTitleFour>
                    <BlockText color="dark060" size="sm" weight="normal">
                      {t('content:brief.section-article-questions-description')}
                    </BlockText>
                    <br />
                    <Questions isLoading={isBriefDataLoading} questions={questions} />
                  </Section>

                  <Section>
                    {/* Content Writing */}
                    <StyledTitleTwo noMargin weight="strong">
                      {t('content:brief.section-article-writing')}
                    </StyledTitleTwo>
                    <br />

                    {/* Related Terms */}
                    <StyledTitleFour noMargin weight="strong">
                      {t('content:brief.section-article-related-terms')}
                    </StyledTitleFour>
                    <BlockText color="dark060" size="sm" weight="normal">
                      {t('content:brief.section-article-related-terms-description')}
                    </BlockText>
                    <br />
                    <RelatedTerms isLoading={isBriefDataLoading} relatedTerms={topics} />
                  </Section>

                  <Section>
                    {/* Internal Linking */}
                    <StyledTitleTwo noMargin weight="strong">
                      {t('content:brief.section-article-linking')}
                    </StyledTitleTwo>
                    <br />

                    {/* Incoming Links */}
                    <StyledTitleFour noMargin weight="strong">
                      {t('content:brief.section-article-incoming-links')}
                    </StyledTitleFour>
                    <BlockText color="dark060" size="sm" weight="normal">
                      {t('content:brief.section-article-incoming-links-description')}
                    </BlockText>
                    <br />
                    <IncomingLinks incomingLinks={incomingLinks} isLoading={isBriefDataLoading} />

                    <br />
                    <br />
                  </Section>
                </>
              )}

              <FlagContentDialog
                flaggedContent={flaggedContent}
                isLoading={isDialogLoading}
                isOpen={isDialogOpen}
                onCancel={() => {
                  setIsDialogOpen(false);
                  setFlaggedContent({});
                }}
                onConfirm={handleReportConfirmClick}
              />
            </ContentSection>
          </ScrollBar>
        </ScrollWrapper>
      ) : (
        <BriefEmptyState
          body={
            <>
              {t('content:brief.empty-state.only-custom.text-part-1')}
              <br />
              {t('content:brief.empty-state.only-custom.text-part-2')}
            </>
          }
          button={t('content:brief.empty-state.only-custom.button')}
          Icon={ColoredReportIcon}
          title={t('content:brief.empty-state.only-custom.title')}
          onClick={() => dispatch(showUpgradePlanModal())}
        />
      )}
      <AiContentPermissionModal
        setShowPermissionModal={setShowPermissionModal}
        showPermissionModal={showPermissionModal}
      />
      <WorkspaceUnitLimitationModal
        setShowWorkspaceUnitLimitationModal={setShowWorkspaceUnitLimitationModal}
        showWorkspaceUnitLimitationModal={showWorkspaceUnitLimitationModal}
      />
    </Body>
  );
}

export default Brief;
