import {
  CHANGE_DEFAULT_ORGANIZATION_ID,
  FETCH_ORGANIZATION_SUCCESS,
  FETCH_USER_ORGANIZATION_EDITABLE_DATA,
  REMOVE_DEFAULT_ORGANIZATION_ID,
} from './actionTypes';

export const setOrganization = (organization) => ({
  organization,
  type: FETCH_ORGANIZATION_SUCCESS,
});

export const setCurrentOrganizationEditableData = ({ organizationId, ...organizationData }) => ({
  organizationData,
  organizationId,
  type: FETCH_USER_ORGANIZATION_EDITABLE_DATA,
});

export const changeDefaultOrganizationId = (organizationId) => ({
  organizationId,
  type: CHANGE_DEFAULT_ORGANIZATION_ID,
});

export const removeDefaultOrganizationId = () => ({
  type: REMOVE_DEFAULT_ORGANIZATION_ID,
});
