import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import SortDirection from 'react-virtualized/dist/es/Table/SortDirection';
import styled from 'styled-components/macro';

import { FlexGrowContainer } from '@/components/_common';
import InvoiceBadge from '@/components/Badge/InvoiceBadge';
import { OutlinedButton } from '@/components/Button/Button';
import FlatLoader, { LoaderWrapper } from '@/components/Loader/FlatLoader';
import Table from '@/components/Table/Table';
import { ParagraphText } from '@/components/Text/Paragraph';
import { TitleFour } from '@/components/Text/Title';
import BillingService from '@/services/Billing';
import { selectSubscriptionResourceVersion } from '@/store/selectors/selectSubscriptionResourceVersion';
import { formatAmount, formatCurrency } from '@/utils/currency';
import { downloadFileFromUrl } from '@/utils/url';

import { Section } from '.';

const DEFAULT_ROWS_IN_LOADING = 3;
const LOADING_ROWS_PLACEHOLDER = Array(DEFAULT_ROWS_IN_LOADING).fill({
  loading: true,
});

const ListInvoicesWrapper = styled.div`
  height: ${(props) => Math.min(Math.max(props.size * 60, 60), 600) + 80}px;
  width: 100%;
  flex: 1;
`;

function Invoice() {
  const { organizationId } = useParams();
  const { t } = useTranslation();
  const _BillingService = new BillingService();
  const [state, setState] = useState({
    error: null,
    invoices: LOADING_ROWS_PLACEHOLDER,
  });
  const subscriptionResourceVersion = useSelector(selectSubscriptionResourceVersion);

  useEffect(() => {
    getInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionResourceVersion]);

  const getInvoices = async () => {
    try {
      setState({
        ...state,
        error: null,
      });
      const clientInvoices = await _BillingService.invoices(organizationId);

      setState({
        ...state,
        error: null,
        invoices: clientInvoices['hydra:member'],
      });
    } catch (e) {
      setState({
        ...state,
        error: t('settings:workspace.billing-invoice.error'),
        invoices: [],
      });
    }
  };

  const downloadInvoice = async (invoiceId) => {
    const { downloadUrl } = await _BillingService.download(organizationId, {
      invoiceId,
    });

    downloadFileFromUrl(downloadUrl);
  };

  const columns = [
    {
      cellDataGetter: ({ rowData }) => ({
        id: rowData.id,
        loading: rowData.loading,
      }),
      cellRenderer: ({ cellData }) =>
        cellData.loading ? (
          <LoaderWrapper height={20} width={100}>
            <FlatLoader />
          </LoaderWrapper>
        ) : (
          cellData.id
        ),
      dataKey: 'id',
      flexGrow: 1,
      label: t('settings:workspace.billing-invoice.column-id-label'),
      sortable: false,
      width: 100,
    },
    {
      cellDataGetter: ({ rowData }) => ({
        date: rowData.date,
        loading: rowData.loading,
      }),
      cellRenderer: ({ cellData }) =>
        cellData.loading ? (
          <LoaderWrapper height={20} width={100}>
            <FlatLoader />
          </LoaderWrapper>
        ) : (
          DateTime.fromISO(cellData.date).toFormat('LLL dd, yyyy')
        ),
      dataKey: 'date',
      label: t('settings:workspace.billing-invoice.column-date-label'),
      sortFunction: (value1, value2, sortDirection) => {
        const a = value1 ? DateTime.fromISO(value1.date) : null;
        const b = value2 ? DateTime.fromISO(value2.date) : null;
        let sortResult = 0;

        if (a === b) {
          sortResult = value1.id > value2.id ? 1 : -1;
        } else {
          sortResult = (a && a < b) || (a && !b) ? -1 : 1;
        }

        return sortDirection === SortDirection.ASC ? sortResult : -1 * sortResult;
      },
      sortable: true,
      width: 175,
    },
    {
      align: 'center',
      cellDataGetter: ({ rowData }) => ({
        loading: rowData.loading,
        status: rowData.status,
      }),
      cellRenderer: ({ cellData }) =>
        cellData.loading ? (
          <LoaderWrapper height={20} width={150}>
            <FlatLoader />
          </LoaderWrapper>
        ) : (
          <InvoiceBadge status={cellData.status} />
        ),
      dataKey: 'status',
      label: t('settings:workspace.billing-invoice.column-status-label'),
      width: 100,
    },
    {
      align: 'right',
      cellDataGetter: ({ rowData }) => ({
        currencyCode: formatCurrency(rowData.currencyCode),
        loading: rowData.loading,
        totalAmount: rowData.totalAmount,
      }),
      cellRenderer: ({ cellData }) =>
        cellData.loading ? (
          <LoaderWrapper height={20} width={150}>
            <FlatLoader />
          </LoaderWrapper>
        ) : (
          formatAmount(cellData.totalAmount, cellData.currencyCode, 2)
        ),
      dataKey: 'amount',
      label: t('settings:workspace.billing-invoice.column-amount-label'),
      width: 150,
    },
    {
      align: 'right',
      cellDataGetter: ({ rowData }) => ({
        id: rowData.id,
        loading: rowData.loading,
      }),
      cellRenderer: ({ cellData }) =>
        cellData.loading ? (
          <LoaderWrapper height={35} width={125}>
            <FlatLoader />
          </LoaderWrapper>
        ) : (
          <OutlinedButton
            defaultDisplay="none"
            weight="normal"
            onClick={() => downloadInvoice(cellData.id)}
          >
            {t('settings:workspace.billing-invoice.button-download')}
          </OutlinedButton>
        ),
      dataKey: '',
      flexGrow: 1,
      label: '',
      width: 150,
    },
  ];

  return (
    <Section>
      <TitleFour noMargin weight="strong">
        {t('settings:workspace.billing-invoice.title')}
      </TitleFour>
      {0 === state.invoices.length ? (
        <ParagraphText color="dark080">
          {t('settings:workspace.billing-invoice.no-invoice-label')}
        </ParagraphText>
      ) : (
        <ListInvoicesWrapper size={state.invoices.length}>
          <FlexGrowContainer>
            <Table
              columns={columns}
              defaultSort="date"
              defaultSortDirection={SortDirection.DESC}
              disablePadding
              mode="transparent"
              rows={state.invoices}
            />
          </FlexGrowContainer>
        </ListInvoicesWrapper>
      )}
    </Section>
  );
}

export default Invoice;
