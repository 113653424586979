import { useState } from 'react';
import styled from 'styled-components/macro';

import CloseIcon from '@/components/icons/CloseIcon';
import InfoAltIcon from '@/components/icons/InfoAltIcon';
import { Text, Wrapper } from '@/components/Message/common';

const HelpIcon = styled(InfoAltIcon)`
  && {
    margin: 0 8px 1px 0;
    color: #51525e;
    font-size: 18px;
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
  color: ${({ theme }) => theme.cssColors.dark080};
  & {
    height: 12px;
    width: 12px;
  }
`;

const StyledText = styled(Text)`
  color: #51525e;
  ${({ theme }) => theme.mediaQueries.desktop} {
    margin-right: 30px;
  }

  ${({ theme }) => theme.mediaQueries.largeDesktop} {
    margin-right: 30px;
  }
`;

const HelperMessage = ({ children, size, margin, withCloseBtn, handleClick }) => {
  const [isHidden, setIsHidden] = useState(false);
  const onClickCondition = () => {
    if (handleClick != null && typeof handleClick === 'function') return handleClick();
    else setIsHidden(true);
  };
  if (isHidden) return null;
  return (
    <Wrapper color={'#ecf5ff'} margin={margin} padding={'16px'} position="relative">
      <div className={'-mt-[15px]'}>
        <HelpIcon />
      </div>
      <StyledText size={size} weight="medium">
        {children}
      </StyledText>
      {withCloseBtn && <StyledCloseIcon onClick={onClickCondition} />}
    </Wrapper>
  );
};

export default HelperMessage;
