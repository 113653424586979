import './Assignation.scss';

import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FolderSelect } from 'semji-core/components/FolderSelect';
import { StatusSelect } from 'semji-core/components/StatusSelect';

import DatePicker from '@/components/DatePicker';
import { DEFAULT_FOLDER_GENERAL } from '@/components/Select/FolderSelect/constants';
import UserSelect from '@/components/Select/UserSelect';
import useNullUser from '@/hooks/useNullUser';

import { ADD_TO_PLANNING_MODAL_ID } from '../AddToPlanning.types';

function Assignation({
  contentStatusAssignation,
  userAssignation,
  setDueDateAssignation,
  statuses,
  users,
  setContentStatusAssignation,
  setUserAssignation,
  dueDateAssignation,
  folders,
  folderId = DEFAULT_FOLDER_GENERAL.id,
  handleChangeFolderId,
  isLoading,
  isCreationDisabled,
  createNewFolder,
}: {
  contentStatusAssignation: string | null;
  userAssignation: string | null;
  setDueDateAssignation: (date: string) => void;
  statuses: any[];
  users: any[];
  setContentStatusAssignation: (status: string) => void;
  setUserAssignation: (user: string) => void;
  dueDateAssignation: string | null;
  folders: any[];
  folderId: string | null;
  handleChangeFolderId: (folderId: string) => void;
  isLoading: boolean;
  isCreationDisabled: boolean;
  createNewFolder: (folderName: string) => void;
}): JSX.Element {
  const nullUser = useNullUser();
  const { t } = useTranslation();
  const contentStatuses = statuses.filter((status) => !status.publish);

  return (
    <div className="add-to-planning-dialog-assignation">
      <div className="add-to-planning-dialog-assignation__cell">
        <Trans
          components={{
            optional: <span className="add-to-planning-dialog-assignation__optional" />,
          }}
          i18nKey={`add-to-planning:draft-planning.title`}
        />
      </div>
      <div className="add-to-planning-dialog-assignation__wrapper">
        <div className="add-to-planning-dialog-assignation__wrapper__user_select">
          <UserSelect
            key={userAssignation || nullUser.id}
            disabled={isLoading}
            handleChange={setUserAssignation}
            hovered
            options={users}
            querySelectorListener={`#${ADD_TO_PLANNING_MODAL_ID}`}
            shortName
            small
            value={userAssignation || nullUser.id}
            variant="column"
            width={300}
          />
        </div>
        <div className="add-to-planning-dialog-assignation__wrapper__date_picker">
          <DatePicker
            key={dueDateAssignation}
            disabled={isLoading}
            dueDate={dueDateAssignation}
            handleChange={setDueDateAssignation}
            isInputFullWidth
          />
        </div>
        <div className="add-to-planning-dialog-assignation__wrapper__status_select">
          <StatusSelect
            key={contentStatusAssignation || contentStatuses?.[0]?.id}
            disabled={isLoading}
            handleChange={({ id }) => setContentStatusAssignation(id)}
            options={contentStatuses}
            value={contentStatusAssignation || contentStatuses?.[0]?.id}
          />
        </div>
        <div className="add-to-planning-dialog-assignation__wrapper__folder_select">
          <FolderSelect
            createNewFolder={createNewFolder}
            dropdownPillShape
            folders={folders}
            isCreationDisabled={isCreationDisabled}
            isFrame={false}
            querySelectorListener={`#${ADD_TO_PLANNING_MODAL_ID}`}
            setValue={handleChangeFolderId}
            translations={{
              button: t('components:select.folder-select.create-folder.label-button'),
              cancel: t('components:select.folder-select.create-folder.cancel'),
              create_folder: t('components:select.folder-select.folder-select.label-create-folder'),
              folder_general: t('components:select.folder-select.label-folder-general'),
              label_no_parent: t('components:filters.folder-filters-panel.label-no-parent'),
              loading: t('components:select.folder-select.create-folder.label-loading'),
              placeHolder: t('components:select.folder-select.create-folder.input-placeholder'),
              search_placeholder: t(
                'components:select.folder-select.folder-select.search-placeholder'
              ),
              title: t('components:select.folder-select.create-folder.title'),
              tooltip_disabled: t('components:select.folder-select.create-folder.tooltip-disabled'),
            }}
            value={folderId}
            width={300}
            withFolderCreation
            withSearchInput
          />
        </div>
      </div>
    </div>
  );
}

export default Assignation;
