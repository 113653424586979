import './Listing.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Cell, Divider } from '@/components/Pages/ContentIdeas/common';
import { NEW_CONTENT, UPDATED_CONTENT } from '@/components/Pages/ContentIdeas/constant';
import CheckboxRender, {
  SELECT_ALL,
} from '@/components/Pages/ContentIdeas/Listing/CellRender/Checkbox';
import ScrollBar from '@/components/Scrollbar';
import ScrollWrapper from '@/containers/Content/BriefComponents/Layout/ScrollWrapper';
import RowRender from '@/containers/ContentIdeas/Dialog/RowRender';

const MAX_ROW = 3;
const ROW_SIZE_IN_PX = 60;

function Listing({
  contentsToRemoveFromPlanning,
  handleSelect,
  selectedContents,
  handleSelectInline,
}: {
  contentsToRemoveFromPlanning: any;
  handleSelect: (id: string) => void;
  selectedContents: string[];
  handleSelectInline: (id: string) => void;
}) {
  const { t } = useTranslation();

  const length = contentsToRemoveFromPlanning.length;
  const keyword = contentsToRemoveFromPlanning[0]?.pageFocusTopKeyword?.keyword;

  function handleClick(id) {
    return () => {
      handleSelectInline(id);
    };
  }

  return (
    <div className="remove-from-planning-listing">
      <div className="remove-from-planning-listing__keyword">
        {length > 1 && (
          <div>
            <CheckboxRender
              handleSelect={handleSelect}
              list={contentsToRemoveFromPlanning.map((content) => ({ id: content.id }))}
              selectedIds={selectedContents}
              type={SELECT_ALL}
            />
          </div>
        )}
        <Cell
          color="dark080"
          lineHeight={`42px`}
          margin="0 0 0 15px"
          noPadding
          size="sm"
          weight="strong"
        >
          {keyword}
        </Cell>
        {length > 1 && (
          <Cell
            color="dark040"
            lineHeight={`42px`}
            margin="0 0 0 5px"
            noPadding
            size="sm"
            weight="medium"
          >
            {t('remove-from-planning:selected-draft', {
              count: length,
            })}
          </Cell>
        )}
      </div>
      <div className="remove-from-planning-listing__header">
        <Cell color="dark060" fullWidth margin="0 0 0 57px" noPadding size="xs" weight="strong">
          {t('remove-from-planning:header.draft')}
        </Cell>
        <Cell align="center" color="dark060" noPadding size="xs" weight="strong" width="90px">
          {t('remove-from-planning:header.content-score')}
        </Cell>
        <Cell align="center" color="dark060" noPadding size="xs" weight="strong" width="90px">
          {t('remove-from-planning:header.assignation')}
        </Cell>
        <Cell align="center" color="dark060" noPadding size="xs" weight="strong" width="90px">
          {t('remove-from-planning:header.due-date')}
        </Cell>
      </div>
      <Divider />
      <ScrollWrapper
        style={{
          height: `${contentsToRemoveFromPlanning.length * (ROW_SIZE_IN_PX + 1)}px`,
          maxHeight: `${MAX_ROW * ROW_SIZE_IN_PX + ROW_SIZE_IN_PX * 0.5}px`,
        }}
      >
        <ScrollBar>
          {contentsToRemoveFromPlanning.map((contentToRemove) => (
            <RowRender
              key={contentToRemove.id}
              assignedTo={contentToRemove.assignedTo}
              contentScore={contentToRemove.contentScore}
              contentStatus={contentToRemove.contentStatus}
              dueDate={contentToRemove.dueDate}
              handleSelect={handleSelect}
              id={contentToRemove.id}
              length={contentsToRemoveFromPlanning.length}
              rowSize={ROW_SIZE_IN_PX}
              selectedContents={selectedContents}
              title={contentToRemove.title}
              type={contentToRemove.page.new ? NEW_CONTENT : UPDATED_CONTENT}
              onClick={handleClick(contentToRemove.id)}
            />
          ))}
        </ScrollBar>
      </ScrollWrapper>
    </div>
  );
}

export default Listing;
