import filterPages from './filterPages';

export default function applyFilters(rows, filters) {
  if (filters === null) return rows;
  try {
    return filterPages(rows, filters);
  } catch {
    return rows;
  }
}
