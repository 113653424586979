import { lazy, Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import { FlexCenteredAlignContainer } from '@/components/_common';
import CountryLanguageSuggest from '@/components/Autocomplete/CountryLanguageSuggest';
import { InfoButton, LinkLikeButton } from '@/components/Button/Button';
import Input from '@/components/Input/Input';
import FlatLoader, { LoaderWrapper } from '@/components/Loader/FlatLoader';
import InformationMessage, {
  ERROR__STATUS,
  LOADING_STATUS,
  SUCCESS_STATUS,
  WARNING_STATUS,
} from '@/components/Message/InformationMessage';
import { PrimaryLink } from '@/components/Navigation/Link';
import { Divider } from '@/components/Profile/ProfileBox';
import { DarkerText } from '@/components/Text/Dark';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import CountriesService from '@/services/Countries';
import { selectOrganizationName } from '@/store/selectors/selectOrganizationName';
import { selectRemainingAnalysisUnit } from '@/store/selectors/selectRemainingAnalysisUnit';
import { SECTIONS } from '@/utils/log/constants';
import { Log } from '@/utils/log/Log';
import { getDefaultLocation } from '@/utils/onboarding/getDefaultLocation';

const CountryLanguageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 50px;
  padding-bottom: 3rem;
`;
const StyledDarkerText = styled(DarkerText)`
  && {
    display: block;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: ${({ marged }) => (marged ? '10px' : '0')};
  }
`;
const StyledFlexCenteredAlignContainer = styled(FlexCenteredAlignContainer)`
  margin: ${({ marged }) => (marged ? '10px 0' : '0')};
`;
const PaddedDiv = styled.div`
  padding-bottom: 3rem;
`;
const StyledLinkLikeButton = styled(LinkLikeButton)`
  && {
    color: ${({ theme }) => theme.cssColors.primary};
    font-size: ${({ theme }) => theme.textCss.sizes.default};
  }
`;
const ErrorWrapper = styled.div`
  color: ${({ theme }) => theme.cssColors.primary};
`;

const WorkspaceIllustration = lazy(
  () =>
    import(
      /* webpackChunkName: "WorkspaceIllustration" */ '@/components/DynamicIllustration/Workspace'
    )
); // Lazy-loaded

const InputText = styled(Input)`
  && {
    display: block;
    padding-bottom: ${({ $noPadding }) => ($noPadding ? '0' : '3rem')};
    & > div {
      margin-top: 0;
    }
  }
`;

export const LOADING_URL_STATUS = 'LOADING_URL_STATUS';
export const ACCESSIBLE_STATUS = 'ACCESSIBLE_STATUS';
export const UNABLE_TO_ACCESS_STATUS = 'UNABLE_TO_ACCESS_STATUS';
export const ERROR_DOMAIN_STATUS = 'ERROR_DOMAIN_STATUS';
export const REDIRECTION_DETECTED_STATUS = 'REDIRECTION_DETECTED_STATUS';

const getStatusValue = (status) => {
  switch (status) {
    case LOADING_URL_STATUS:
      return LOADING_STATUS;
    case ACCESSIBLE_STATUS:
      return SUCCESS_STATUS;
    case REDIRECTION_DETECTED_STATUS:
    case UNABLE_TO_ACCESS_STATUS:
      return WARNING_STATUS;
    case ERROR_DOMAIN_STATUS:
      return ERROR__STATUS;
    default:
      return null;
  }
};

// Lazy load this component because it's a heavy svg
const StyledWorkspaceIllustration = styled((props) => (
  <Suspense fallback={<></>}>
    <WorkspaceIllustration {...props} />
  </Suspense>
))`
  position: fixed;
  bottom: calc((100vh - 514px) / 2);
  right: 10vh;
  // reset right on medium screen
  ${({ theme }) => theme.mediaQueries.desktop} {
    right: 5vh;
  }
  // hide illustration for small screens
  ${({ theme }) => theme.mediaQueries.phone} {
    display: none;
  }
  ${({ theme }) => theme.mediaQueries.tablet} {
    display: none;
  }
`;

const countriesService = new CountriesService();

function WorkspaceNameInput({ workspace, onChangeWorkspaceName, isDisabled }) {
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  function onBlur() {
    if (!workspace.name) {
      setError(t('create-workspace:errors.workspace-name'));
    } else {
      setError(null);
    }
  }

  function onFocus() {
    setError(null);
  }

  return (
    <>
      <StyledDarkerText>{t('create-workspace:workspace-name.title')}</StyledDarkerText>
      <InputText
        $noPadding
        disabled={isDisabled}
        error={!!error}
        fullWidth
        helperText={error}
        InputLabelProps={{
          shrink: true,
        }}
        placeholder={t('create-workspace:workspace-name.placeholder-input')}
        type="text"
        value={workspace.name}
        onBlur={onBlur}
        onChange={onChangeWorkspaceName}
        onFocus={onFocus}
      />
    </>
  );
}

function WebsiteUrlInput({ isLoading, url, onBlur, websiteUrlStatus, redirectedUrl }) {
  const { t } = useTranslation();
  const [value, setValue] = useState(url);

  function handleChange(event) {
    setValue(event.target?.value?.trim());
  }

  const getMessageByStatus = (status) => {
    if (status === REDIRECTION_DETECTED_STATUS && !redirectedUrl) {
      status = UNABLE_TO_ACCESS_STATUS;
    }
    switch (status) {
      case LOADING_URL_STATUS:
        return t('create-workspace:website-url.loading-status');
      case ACCESSIBLE_STATUS:
        return t('create-workspace:website-url.accessible-status');
      case UNABLE_TO_ACCESS_STATUS:
        return (
          <div>
            {t('create-workspace:website-url.unable-to-access-status')}{' '}
            {
              <PrimaryLink isExternal noDecoration to={t('common:links.help-semji-bot')}>
                {t('create-workspace:website-url.how-to-fix')}
              </PrimaryLink>
            }
          </div>
        );
      case REDIRECTION_DETECTED_STATUS:
        return (
          <div>
            {t('create-workspace:website-url.redirection-detected-status')}{' '}
            {
              <PrimaryLink isExternal noDecoration to={redirectedUrl}>
                {redirectedUrl}
              </PrimaryLink>
            }{' '}
            {t('create-workspace:website-url.is-it-rather-url')}{' '}
            <StyledLinkLikeButton
              noPadding
              onClick={() => {
                setValue(redirectedUrl);
                onBlur(redirectedUrl);
              }}
            >
              {t('create-workspace:website-url.use-it-instead')}
            </StyledLinkLikeButton>
          </div>
        );
      case ERROR_DOMAIN_STATUS:
        return <ErrorWrapper>{t('create-workspace:errors.workspace-url')}</ErrorWrapper>;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (url !== value) {
      setValue(url); // reset value with url after each blur
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return (
    <PaddedDiv>
      <StyledFlexCenteredAlignContainer>
        <StyledDarkerText>{t('create-workspace:website-url.title')}</StyledDarkerText>
        <TooltipComponent title={t('create-workspace:website-url.tooltip-text')}>
          <InfoButton $isMargin bordered />
        </TooltipComponent>
      </StyledFlexCenteredAlignContainer>
      <InputText
        $noPadding
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        disabled={isLoading}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        placeholder={t('create-workspace:website-url.placeholder-input')}
        type="text"
        value={value}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        onBlur={(event) => onBlur(event.target.value)}
        onChange={handleChange}
      />
      {websiteUrlStatus && (
        <InformationMessage
          status={getStatusValue(websiteUrlStatus)}
          text={getMessageByStatus(websiteUrlStatus)}
        />
      )}
    </PaddedDiv>
  );
}

function CountryLanguageSelector({ location, onChangeLocation }) {
  const { t } = useTranslation();
  const [locations, setLocations] = useState([]);
  const countryCode = useSelector((state) => state.user?.countryCode);
  const languageCode = useSelector((state) => state.user?.languageCode);
  const { data: allowedCountries } = useOrganizationFeatureSet(
    'organization:workspaces:allowed-country-language'
  );

  const inferCountryFromUserState = (countriesList) => {
    const hasLocation = location.countryCode && location.languageCode;
    const isLocationAllowed =
      !allowedCountries ||
      allowedCountries.includes(location.languageCode + '-' + location.countryCode);

    if (hasLocation && isLocationAllowed) return location;

    if (allowedCountries) {
      return countriesList.find((c) =>
        allowedCountries.includes(c.languageCode + '-' + c.countryCode)
      );
    }

    if (countryCode && languageCode) {
      const country = countriesList.find(
        (c) =>
          c.countryCode.toLowerCase() === countryCode.toLowerCase() &&
          c.languageCode.toLowerCase() === languageCode.toLowerCase()
      );

      if (!!country) {
        return country;
      }
    }

    if (countryCode) {
      const country = countriesList.find(
        (c) => c.countryCode.toLowerCase() === countryCode.toLowerCase()
      );

      if (!!country) {
        return country;
      }
    }

    return null;
  };

  const retrieveLocations = async () => {
    try {
      const countriesList = await countriesService.countries;
      setLocations(countriesList);

      const userCountry = inferCountryFromUserState(countriesList);

      if (userCountry) {
        onChangeLocation({ ...userCountry }, false);
        return;
      }

      if (
        !location.isLocationDirty &&
        !location.countryCode &&
        !location.languageCode &&
        !location.country
      ) {
        const defaultLocation = getDefaultLocation(countriesList);
        onChangeLocation(defaultLocation, false);
      }
    } catch (error) {
      Log.report({
        context: 'retrieveLocations',
        error,
        extra: 'Retrieve countries',
        section: SECTIONS.onboarding.key,
      });
    }
  };

  useEffect(() => {
    retrieveLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <StyledFlexCenteredAlignContainer marged>
        <StyledDarkerText>{t('create-workspace:location.title')}</StyledDarkerText>
        <TooltipComponent title={t('create-workspace:location.tooltip-text')}>
          <InfoButton $isMargin bordered />
        </TooltipComponent>
      </StyledFlexCenteredAlignContainer>
      <CountryLanguageWrapper>
        {!!location.countryCode && !!location.languageCode && !!location.country ? (
          <>
            <CountryLanguageSuggest
              defaultValue={location}
              inlineVersion
              options={locations}
              onChange={onChangeLocation}
            />
            <Divider color="grey" />
          </>
        ) : (
          <LoaderWrapper isFlex>
            <FlatLoader />
          </LoaderWrapper>
        )}
      </CountryLanguageWrapper>
    </>
  );
}

export default function CreateWorkspace({
  workspace,
  location,
  onChangeWorkspaceName,
  onChangeWebSiteUrl,
  onChangeLocation,
  isUrlLoading,
  user,
  websiteUrlStatus,
  redirectedUrl,
}) {
  const { organizationId } = useParams();

  const remainingAnalysisUnit = useSelector(selectRemainingAnalysisUnit);
  const organizationName = useSelector(selectOrganizationName);
  const workspacesCount = useSelector(
    (state) => state.organizations.byId[organizationId]?.workspacesCount || 0
  );
  const isFirstWorkspace = workspacesCount === 0;

  return (
    <>
      <WebsiteUrlInput
        isLoading={isUrlLoading}
        redirectedUrl={redirectedUrl}
        url={workspace.websiteUrl}
        websiteUrlStatus={websiteUrlStatus}
        onBlur={onChangeWebSiteUrl}
      />
      <CountryLanguageSelector location={location} onChangeLocation={onChangeLocation} />
      {!isFirstWorkspace && (
        <WorkspaceNameInput
          isDisabled={isUrlLoading}
          workspace={workspace}
          onChangeWorkspaceName={onChangeWorkspaceName}
        />
      )}
      <StyledWorkspaceIllustration
        countryCode={location?.countryCode}
        isObvious={location?.isObvious}
        languageCode={location?.languageCode}
        organizationName={organizationName}
        remainingAnalysisUnit={remainingAnalysisUnit}
        user={user}
        workspaceName={workspace.name}
      />
    </>
  );
}
