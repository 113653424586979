import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';
import { ReactNode } from 'react';
import styled from 'styled-components/macro';

import { FlexVerticalAlignedContainer } from '@/components/_common';

const HtmlTooltip = withStyles({
  arrow: {
    color: '#252736',
  },
  tooltip: {
    '&&': {
      background: '#252736',
      backgroundColor: '#252736',
      color: 'white',
      fontSize: '13px',
      fontWeight: 300,
      maxWidth: 220,
      padding: '10px',
      position: 'relative',
    },
  },
})(Tooltip);

const TooltipTitle = styled.span`
  color: ${(props: any) => props.theme.text.colors.white};
  && strong {
    font-weight: 500;
  }
`;

const MargedTooltipTitle = styled(TooltipTitle)`
  margin-left: 5px;
  font-weight: 400;
`;

const HtmlIconTitle = ({ icon, title }: { icon: ReactNode; title: string }) => (
  <FlexVerticalAlignedContainer>
    {icon}
    <MargedTooltipTitle dangerouslySetInnerHTML={{ __html: title }} />
  </FlexVerticalAlignedContainer>
);

const TooltipDescription = styled.div`
  color: ${(props: any) => props.theme.text.colors.white};
  border-top: 1px solid white;
  padding-top: 7px;
  margin-top: 7px;
  font-size: 11px;
  && strong {
    font-weight: 500;
  }
`;

const TooltipContent = styled.div`
  font-size: ${(props: any) => props.fontSize};
  cursor: ${(props: any) => props.cursor};
`;

const HtmlTitle = ({ title, description }: { title: string; description: string }) => (
  <>
    <TooltipTitle dangerouslySetInnerHTML={{ __html: title }} />
    {description ? <TooltipDescription dangerouslySetInnerHTML={{ __html: description }} /> : null}
  </>
);

interface TooltipComponentProps {
  children?: React.ReactNode;
  delay?: number;
  description?: string;
  icon?: React.ReactNode;
  hide?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  open?: boolean;
  placement?:
    | 'bottom'
    | 'left'
    | 'right'
    | 'top'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start'
    | undefined;
  content?: React.ReactNode;
  title?: string;
  cursor?: any;

  [key: string]: any;
}

export default function TooltipComponent({
  children,
  delay = 400,
  description,
  icon,
  hide,
  onClose,
  onOpen,
  open,
  placement = 'bottom',
  content,
  title,
  ...props
}: TooltipComponentProps) {
  if (hide) {
    return <div {...props}>{children}</div>;
  }

  return (
    <HtmlTooltip
      arrow
      enterDelay={delay}
      enterNextDelay={delay}
      open={open || undefined}
      placement={placement}
      title={
        content ? (
          content
        ) : icon ? (
          <HtmlIconTitle icon={icon} title={title || ''} />
        ) : (
          <HtmlTitle description={description} title={title} />
        )
      }
      onClose={onClose}
      onOpen={onOpen}
    >
      <TooltipContent {...props}>{children}</TooltipContent>
    </HtmlTooltip>
  );
}

export const FlexTooltipComponent = styled(TooltipComponent)`
  display: flex;
`;

export const SmallFlexTooltipComponent = styled(FlexTooltipComponent)`
  margin: ${(props: any) => props.theme.textCss.sizes.xs};
`;
