import SvgWrapper from './SvgWrapper';

const SemjiCreditIcon = (props) => (
  <SvgWrapper {...props} fill="none" height="16" viewBox="0 0 17 16" width="17">
    <circle cx="8.50002" cy="8.00002" fill="#FF4D64" r="8.00002" />
    <path d="M9.76792 11.7876L8.49003 9.45166L7.23242 11.7876H9.76792Z" fill="white" />
    <path
      d="M5.38641 11.7861L8.48986 6.10724L11.5933 11.7861H13.5L8.48986 2.64355L3.5 11.7861H5.38641Z"
      fill="white"
    />
  </SvgWrapper>
);

export default SemjiCreditIcon;
