import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import usePostExportCompetitorTopPage from '@/apis/semji/competitors/usePostExportCompetitorTopPages';
import ExportDialog from '@/components/Dialog/ExportDialog';
import { IFilterTransformed } from '@/hooks/useFilters/useFilters.types';
import { showSuccessSnackbar } from '@/store/actions/ui';
import { CompetitorTopPagesFilters } from '@/types/competitors';
import { FileType } from '@/types/export/export.types';

export default function Export({
  isOpen,
  filters,
  cancel,
  competitorReportId,
}: {
  isOpen: boolean;
  filters: IFilterTransformed<CompetitorTopPagesFilters>;
  cancel: () => void;
  competitorReportId: string;
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const postExportCompetitorTopPages = usePostExportCompetitorTopPage({
    onSuccess: () => {
      dispatch(
        showSuccessSnackbar(t('competitors:data-explorer.export-modal.async-export-success'))
      );
      cancel();
    },
    reportId: competitorReportId,
  });

  function handleConfirmExport(fileType: FileType) {
    doExportCompetitorTopPages(fileType, filters);
  }

  async function doExportCompetitorTopPages(fileType: FileType, filters: any) {
    postExportCompetitorTopPages.mutate({ fileType, filters });
  }

  if (!isOpen) {
    return null;
  }

  return (
    <ExportDialog
      error={postExportCompetitorTopPages.isError ? t('common:error.default') : ''}
      handleCancel={cancel}
      handleConfirm={handleConfirmExport}
      isAsync={true}
      loading={postExportCompetitorTopPages.isLoading}
      open={true}
      title={t(`competitors:data-explorer.export-modal.title`)}
    />
  );
}
