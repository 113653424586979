import styled from 'styled-components/macro';

const HiddenElement = styled.div`
  visibility: hidden;
  width: 200px;
`;

function FakeNode() {
  return <HiddenElement>Hidden element</HiddenElement>;
}

export default FakeNode;
