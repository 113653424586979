import {
  CHANGE_DEFAULT_REPORT_ID,
  CHANGE_DEFAULT_WEBSITE_ID,
  REMOVE_DEFAULT_REPORT_ID,
  REMOVE_DEFAULT_WEBSITE_ID,
} from '@/store/actions/actionTypes';

export const changeDefaultReportId = (reportId?: string) => ({
  payload: { defaultReportId: reportId },
  type: CHANGE_DEFAULT_REPORT_ID,
});

export const removeDefaultReportId = () => ({
  type: REMOVE_DEFAULT_REPORT_ID,
});

export const changeDefaultWebsiteId = (websiteId?: string) => ({
  payload: { defaultWebsiteId: websiteId },
  type: CHANGE_DEFAULT_WEBSITE_ID,
});

export const removeDefaultWebsiteId = () => ({
  type: REMOVE_DEFAULT_WEBSITE_ID,
});
