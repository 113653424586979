import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Input from '@material-ui/core/Input';
import { withStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { FlexColumnContainer } from '@/components/_common/index';
import OrganizationInputAccordion from '@/components/Accordion/OrganizationInputAccordion';
import { TextCheckbox } from '@/components/Checkbox/StepCheckBox';
import CheckIcon from '@/components/icons/CheckIcon';
import FlatLoader, { LoaderWrapper } from '@/components/Loader/FlatLoader';
import PhoneNumberInput from '@/components/PhoneNumber';
import { LightText } from '@/components/Text/Light';
import { TitleThree } from '@/components/Text/Title';
import { useDefaultUserLocation } from '@/hooks/useDefaultUserLocation';

const AccordionStatus = styled(CheckIcon)`
  && {
    color: ${(props) =>
      props.checked ? props.theme.cssColors.white : props.theme.cssColors.dark080};
    background-color: ${(props) => (props.checked ? props.theme.cssColors.green : 'transparent')};
    font-size: 1rem;
    padding: 0.2rem;
    margin: 4px 1rem 0 0;
    border: 1px solid
      ${(props) => (props.checked ? props.theme.cssColors.green : props.theme.cssColors.dark080)};
  }
`;
const StyledAccordion = withStyles({
  expanded: {},
  root: {
    '&$expanded': {
      margin: '0px',
    },
    '&:before': {
      height: '0',
    },
    background: 'transparent',
    border: '0',
    boxShadow: 'none',
  },
})(Accordion);
const StyledAccordionSummary = styled(AccordionSummary)`
  && {
    border: 0;
    padding: 0;
    background-color: transparent;
    & > div {
      display: flex;
      margin: 10px 0;
    }
  }
`;
const StyledAccordionDetails = styled(AccordionDetails)`
  && {
    padding-left: 2.5rem;
    flex-wrap: wrap;
  }
`;

const StyledLightText = styled(LightText)`
  && {
    margin-top: 10px;
  }
`;

function InputAccordion(props) {
  const onDataChange = (event) => {
    props.onDataChange(props.id, event.target.value);
  };

  return (
    <Input
      autoFocus
      fullWidth
      placeholder={props.placeholder}
      value={props.dataValue || ''}
      onChange={onDataChange}
      onKeyDown={props.onKeyDownPress}
    />
  );
}

function PhoneAccordion(props) {
  const countryCode = useSelector((state) => state.user?.countryCode);
  const onDataChange = (value, country, e, formattedValue) => {
    props.onDataChange(props.id, {
      format: country.format,
      formattedValue,
      value,
    });
  };

  const { countryCode: detectedCountryCode, isLoaded } = useDefaultUserLocation();

  return isLoaded ? (
    <PhoneNumberInput
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus
      defaultCountry={countryCode || detectedCountryCode}
      isValid={props.checked}
      phoneObject={props.dataValue || {}}
      placeholder={props.placeholder}
      onChange={onDataChange}
    />
  ) : (
    <LoaderWrapper height={32} isFlex>
      <FlatLoader />
    </LoaderWrapper>
  );
}

function SelectAccordion(props) {
  const onDataChange = (value) => {
    props.onDataChange(props.id, value);
  };

  return props.values.map((value) => (
    <TextCheckbox
      key={value}
      checked={value === props.dataValue}
      text={value}
      onClick={() => onDataChange(value)}
    />
  ));
}

function DefaultAccordion(props) {
  return 'N/A';
}

function Details({ accordion, expanded, onKeyDownPress, isDirty, setIsDirty }) {
  switch (accordion.type) {
    case 'organization-input':
      return (
        <OrganizationInputAccordion
          key={accordion.id}
          {...accordion}
          expanded={expanded === accordion.id}
          isDirty={isDirty}
          setIsDirty={setIsDirty}
          onKeyDownPress={onKeyDownPress}
        />
      );
    case 'input':
      return (
        <InputAccordion
          key={accordion.id}
          {...accordion}
          expanded={expanded === accordion.id}
          onKeyDownPress={onKeyDownPress}
        />
      );
    case 'select':
      return (
        <SelectAccordion key={accordion.id} {...accordion} expanded={expanded === accordion.id} />
      );
    case 'phone':
      return (
        <PhoneAccordion key={accordion.id} {...accordion} expanded={expanded === accordion.id} />
      );
    default:
      return (
        <DefaultAccordion key={accordion.id} {...accordion} expanded={expanded === accordion.id} />
      );
  }
}

export default function ControlledAccordions(props) {
  const [isDirty, setIsDirty] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    props.setExpanded(isExpanded ? panel : false);
  };

  return props?.accordions?.map((accordion) => {
    return (
      <StyledAccordion
        key={accordion.id}
        expanded={props.expanded === accordion.id}
        TransitionProps={{ unmountOnExit: true }}
        onChange={handleChange(accordion.id)}
      >
        <StyledAccordionSummary expandIcon={null}>
          <AccordionStatus bordered checked={accordion.checked} />
          <FlexColumnContainer>
            <TitleThree noMargin weight="strong">
              {accordion.summary}
            </TitleThree>
            {props.expanded === accordion.id ? (
              <StyledLightText size="small">{accordion.explanationText}</StyledLightText>
            ) : null}
          </FlexColumnContainer>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <Details
            accordion={accordion}
            expanded={props.expanded}
            isDirty={isDirty}
            setIsDirty={setIsDirty}
            onKeyDownPress={props.onKeyDownPress}
          />
        </StyledAccordionDetails>
      </StyledAccordion>
    );
  });
}
