export function isHexColor(str: string) {
  return /^#[0-9A-F]{6}$/i.test(str);
}

export function isHexAColor(str: string) {
  return /^#[0-9A-F]{8}$/i.test(str);
}

export function isRGBColor(str: string) {
  return /^rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)$/i.test(str);
}

export function isRGBAColor(str: string) {
  return /^rgba\((\d{1,3}), (\d{1,3}), (\d{1,3}), ((?:\d+\.)?\d+)\)$/i.test(str);
}

export function lighten(color: string, percent = 1) {
  if (percent > 1) {
    return color;
  }
  if (isHexColor(color)) {
    return `${color}${Math.round(percent * 255).toString(16)}`.toLocaleUpperCase();
  }
  if (isHexAColor(color)) {
    const match = color.match(/^#([0-9A-F]{6})([0-9A-F]{2})$/i);
    const hex = match[1];
    const a = parseInt(match[2], 16);
    const newA = Math.round(percent * a);

    return `#${hex}${newA.toString(16)}`.toLocaleUpperCase();
  }
  if (isRGBColor(color)) {
    const match = color.match(/^rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)$/i);
    const r = parseInt(match[1]);
    const g = parseInt(match[2]);
    const b = parseInt(match[3]);

    return `rgba(${r}, ${g}, ${b}, ${percent})`;
  }
  if (isRGBAColor(color)) {
    const match = color.match(/^rgba\((\d{1,3}), (\d{1,3}), (\d{1,3}), ((?:\d+\.)?\d+)\)$/i);
    const r = parseInt(match[1]);
    const g = parseInt(match[2]);
    const b = parseInt(match[3]);
    const a = parseFloat(match[4]);

    return `rgba(${r}, ${g}, ${b}, ${percent * a})`;
  }

  return color;
}
