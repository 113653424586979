import { nanoid } from 'nanoid';
import React, { useMemo } from 'react';

import SvgWrapper from './SvgWrapper';

function HashIcon({ ...props }) {
  const maskId = useMemo(() => nanoid(), []);

  return (
    <SvgWrapper {...props} fill="none" height="17" viewBox="0 0 17 17" width="17">
      <mask
        height="15"
        id={`mask${maskId}`}
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="13"
        x="2"
        y="1"
      >
        <path
          clipRule="evenodd"
          d="M7.16242 1.58698C7.21041 1.31504 7.02887 1.05568 6.75693 1.00769C6.48498 0.959702 6.22563 1.14125 6.17764 1.41319L5.68842 4.18545H2.5C2.22386 4.18545 2 4.40931 2 4.68545C2 4.96159 2.22386 5.18545 2.5 5.18545H5.51195L4.63477 10.1562H2.5C2.22386 10.1562 2 10.38 2 10.6562C2 10.9323 2.22386 11.1562 2.5 11.1562H4.4583L3.7926 14.9284C3.74461 15.2003 3.92616 15.4597 4.1981 15.5077C4.47004 15.5557 4.7294 15.3741 4.77739 15.1022L5.47375 11.1562H9.57594L8.91025 14.9284C8.86226 15.2003 9.04381 15.4597 9.31575 15.5077C9.58769 15.5557 9.84704 15.3741 9.89503 15.1022L10.5914 11.1562H14.2941C14.5703 11.1562 14.7941 10.9323 14.7941 10.6562C14.7941 10.38 14.5703 10.1562 14.2941 10.1562H10.7679L11.645 5.18545H14.2941C14.5703 5.18545 14.7941 4.96159 14.7941 4.68545C14.7941 4.40931 14.5703 4.18545 14.2941 4.18545H11.8215L12.2801 1.58698C12.3281 1.31504 12.1465 1.05568 11.8746 1.00769C11.6026 0.959702 11.3433 1.14125 11.2953 1.41319L10.8061 4.18545H6.70387L7.16242 1.58698ZM9.75241 10.1562L10.6296 5.18545H6.5274L5.65022 10.1562H9.75241Z"
          fill="currentColor"
          fillRule="evenodd"
        />
      </mask>
      <g mask={`url(#mask${maskId})`}>
        <rect fill="currentColor" height="20" width="20" x="-2" y="-1" />
      </g>
    </SvgWrapper>
  );
}

export default HashIcon;
