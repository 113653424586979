import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { withTheme } from 'styled-components/macro';

import { FlexCenteredAlignContainer } from '@/components/_common';
import { RedirectionBadge } from '@/components/Badge/Badge';
import InfoBadge, { useBadge } from '@/components/Badge/InfoBadge';
import AgendaIcon from '@/components/icons/Agenda';
import Link2Icon from '@/components/icons/Link2Icon';
import UpdatedIcon from '@/components/icons/UpdatedIcon';
import FlatLoader, { DEFAULT_LOADER_HEIGHT } from '@/components/Loader/FlatLoader';
import { DefaultLink, IconExternalLink } from '@/components/Navigation/Link';
import { InlineText } from '@/components/Text/Inline';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import Flex from '@/design-system/components/Flex';
import { FROM_ORIGIN, PLANNING_TAB } from '@/utils/constants';
import { getFormattedTitle } from '@/utils/title';

const ExternalIcon = styled(IconExternalLink)`
  && {
    margin-left: 5px;
  }
`;

const TooltipEllipsis = styled(TooltipComponent)`
  align-items: center;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const LinkIcon = styled(Link2Icon)`
  min-width: 10px;
  height: 10px;
  fill: currentColor;
  color: ${(props) => props.theme.cssColors.dark040};
`;

export const Pill = styled.span`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  height: 20px;
  overflow: hidden;
  font-size: ${({ theme }) => theme.textCss.sizes.xxs};
  font-weight: ${(props) => props.theme.textCss.weights.strong};
  line-height: ${({ theme }) => theme.textCss.sizes.sm};
  color: ${(props) => props.theme.cssColors.dark060};
  background: ${(props) => props.theme.cssColors.dark005};
  border-radius: 25px;
  padding: 2px 8px;
`;

export const Title = styled.h4`
  font-weight: 400;
  overflow: hidden;
  width: 100%;
  font-size: ${({ theme }) => theme.text.sizes.big};
  color: ${({ theme }) => theme.text.colors.darker};
  padding-bottom: 0.1em;
  padding-top: 0.1em;
  text-overflow: ellipsis;
  && > a {
    padding-bottom: 0.25em;
    padding-top: 0.25em;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
  }
`;

export const Url = styled.div`
  overflow: hidden;
  font-size: 0.85em;
  display: flex;
  align-items: center;
  && > a {
    padding-bottom: 0.25em;
    padding-top: 0.25em;
    display: inline;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const MyAgendaIcon = styled(AgendaIcon)`
  && {
    fill: ${(props) => props.theme.cssColors.dark040};
    font-size: ${({ theme }) => theme.textCss.sizes.xs};
    margin: 0 0.5em 0.1em 0;
  }
`;

export const TitleWrapper = styled.div`
  min-width: 0;
  width: 100%;
  white-space: nowrap;
`;

export const LoaderWrapper = styled.div`
  width: 90%;
  height: ${DEFAULT_LOADER_HEIGHT}px;
  padding: 5px 0px;
`;

const DateDisplayer = styled.div`
  display: flex;
  font-size: ${({ theme }) => theme.textCss.sizes.xs};
  align-items: center;
  color: ${(props) => props.theme.cssColors.dark040};
`;

const FlexCenteredAlignContainerMarged = styled(FlexCenteredAlignContainer)`
  && {
    margin-left: 5px;
  }
`;
const MargedDiv = styled.div`
  margin-left: 3px;
`;

const StyledDefaultLink = styled(DefaultLink)`
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: initial;
  line-height: 20px;
`;

const UpdatesInfo = ({ updateInfos }) => {
  return (
    <TooltipComponent
      title={`Last update: ${DateTime.fromISO(updateInfos.lastPublishedAt).toFormat(
        'dd MMM yyyy'
      )}`}
    >
      <FlexCenteredAlignContainerMarged>
        <UpdatedIcon />
        <MargedDiv>{updateInfos.publicationsCount}</MargedDiv>
      </FlexCenteredAlignContainerMarged>
    </TooltipComponent>
  );
};

function TitleCell({
  canAccessToVersionHistory,
  title,
  url,
  workspaceId,
  organizationId,
  pageId,
  type,
  contentId,
  publishedAt,
  isRedirectionDetected,
  tab,
  isNewContent,
  isUpdatedContent,
  loading,
  updateInfos,
  lastStatusCode,
  ...props
}) {
  const { t } = useTranslation();
  const [{ badgeTitle, color }] = useBadge(lastStatusCode);

  function getPath() {
    switch (type) {
      case FROM_ORIGIN.ONLINE:
        if (canAccessToVersionHistory) {
          return `/o/${organizationId}/w/${workspaceId}/p/${pageId}/versions`;
        }
        return `/o/${organizationId}/w/${workspaceId}/p/${pageId}/online`;
      case FROM_ORIGIN.REPORT:
        return `/o/${organizationId}/w/${workspaceId}/p/${pageId}/online/performances`;
      case FROM_ORIGIN.PLANNING:
        if (
          (tab === PLANNING_TAB.PUBLISHED || (PLANNING_TAB.FOLDER && publishedAt)) &&
          canAccessToVersionHistory
        ) {
          return `/o/${organizationId}/w/${workspaceId}/p/${pageId}/versions/${contentId}`;
        }
        return `/o/${organizationId}/w/${workspaceId}/p/${pageId}/content/${contentId}`;
      default:
        return `/o/${organizationId}/w/${workspaceId}/p/${pageId}/content/${contentId}`;
    }
  }

  const formattedTitle = getFormattedTitle(title);

  return (
    <TitleWrapper>
      <FlexCenteredAlignContainer>
        {loading ? (
          <LoaderWrapper>
            <FlatLoader />
          </LoaderWrapper>
        ) : (
          <>
            <StyledDefaultLink
              color="dark100"
              title={formattedTitle}
              to={getPath()}
              weight="medium"
            >
              {formattedTitle}
            </StyledDefaultLink>
            {updateInfos?.publicationsCount > 0 && updateInfos?.lastPublishedAt && (
              <UpdatesInfo updateInfos={updateInfos} />
            )}
          </>
        )}
      </FlexCenteredAlignContainer>
      <Flex alignItems="center" gap="4px" maxHeight="35px">
        {isNewContent && !loading && (
          <TooltipComponent title={t('components:table.title-cell.tooltip-new-content')}>
            <Pill>{t('components:table.title-cell.tooltip-new')}</Pill>
          </TooltipComponent>
        )}
        {isUpdatedContent && !loading && (
          <TooltipComponent title={t('components:table.title-cell.tooltip-existing-content')}>
            <Pill>{t('components:table.title-cell.tooltip-existing')}</Pill>
          </TooltipComponent>
        )}
        {badgeTitle && (
          <TooltipComponent title={badgeTitle}>
            <InfoBadge color={color} size="micro">
              {lastStatusCode}
            </InfoBadge>
          </TooltipComponent>
        )}
        {Boolean(publishedAt) && (
          <TooltipComponent title={t(t('listing:planning-list:list.publication-date'))}>
            <DateDisplayer>
              <MyAgendaIcon />
              {DateTime.fromISO(publishedAt).toFormat('dd MMM yy')}
            </DateDisplayer>
          </TooltipComponent>
        )}

        <Flex alignItems="center" overflow="hidden">
          {!lastStatusCode && Boolean(isRedirectionDetected) && <RedirectionBadge size="micro" />}
          {Boolean(url) && (
            <Url>
              <TooltipEllipsis title={url}>
                <LinkIcon />
                <InlineText color="dark040" size="xs" weight="medium">
                  {url}
                </InlineText>
              </TooltipEllipsis>
              <ExternalIcon href={url} title={t('components:table.title-cell.link-title')} />
            </Url>
          )}
        </Flex>
      </Flex>
    </TitleWrapper>
  );
}

export default withTheme(TitleCell);
