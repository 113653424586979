import { Handle } from 'reactflow';
import styled from 'styled-components/macro';

import MinusIcon from '@/components/icons/MinusIcon';
import PlusIcon from '@/components/icons/PlusIcon';
import Box from '@/design-system/components/Box/Box';

export const Cluster = styled.span`
  line-height: 135%;
  color: ${({ theme }) => theme.textCss.colors.white};
`;
export const StyledHandle = styled(Handle)`
  && {
    cursor: pointer;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ color }) => color};
    display: flex;
    font-size: ${({ theme }) => theme.textCss.sizes.xs};
    height: 18px;
    right: -8px;
    justify-content: center;
    width: 18px;
    visibility: hidden;
  }
`;
export const ClusterWrapper = styled(Box)`
  :hover ${StyledHandle} {
    visibility: visible;
  }
`;
export const StyledMinusIcon = styled(MinusIcon)`
  font-size: ${({ theme }) => theme.textCss.sizes.xs};
  color: ${({ color }) => color};
  cursor: pointer;
`;
export const StyledPlusIcon = styled(PlusIcon)`
  font-size: ${({ theme }) => theme.textCss.sizes.xs};
  color: ${({ color }) => color};
  cursor: pointer;
`;
