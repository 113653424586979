import React from 'react';

import SvgWrapper from './SvgWrapper';

const BellIcon = (props) => (
  <SvgWrapper {...props}>
    <path d="M15.312 14.125c-0.014-0.013-1.312-1.294-1.312-3.21v-3.292c0-3.101-2.468-5.623-5.5-5.623s-5.5 2.522-5.5 5.623v3.292c0 1.861-1.306 3.203-1.318 3.216l-0.843 0.853h15.357l-0.884-0.859zM3.052 13.984c0.444-0.692 0.948-1.764 0.948-3.069v-3.292c0-2.549 2.019-4.623 4.5-4.623s4.5 2.074 4.5 4.623v3.292c0 1.32 0.495 2.383 0.938 3.069h-10.886zM9 2h-1v-1h1v1zM9 15h1c0 0.827-0.673 1.5-1.5 1.5s-1.5-0.673-1.5-1.5h1c0 0.276 0.225 0.5 0.5 0.5s0.5-0.224 0.5-0.5z" />
  </SvgWrapper>
);

export default BellIcon;
