import { useTranslation } from 'react-i18next';
import { DialogModal } from 'semji-core/components/DialogModal';
import styled from 'styled-components/macro';

const SubTitle = styled.span`
  line-height: ${({ theme }) => theme.textCss.sizes.lg};
  color: ${({ theme }) => theme.cssColors.dark060};
`;
function DisconnectServiceIntegrationDialog({ onClose, onConfirm, serviceName, isLoading }) {
  const { t } = useTranslation();

  return (
    <DialogModal
      cancelAction={onClose}
      cancelLabel={t('service-integration:disconnect-service-integration.cancel')}
      canClickOutsideToClose
      closable
      confirmAction={onConfirm}
      confirmLabel={t('service-integration:disconnect-service-integration.disconnect')}
      isCancelDisabled={isLoading}
      isConfirmDisabled={isLoading}
      isOpen={true}
      title={t('service-integration:disconnect-service-integration.title', { serviceName })}
      onClose={onClose}
    >
      <SubTitle>
        {t('service-integration:disconnect-service-integration.explanation-text', {
          serviceName,
        })}
      </SubTitle>
    </DialogModal>
  );
}

export default DisconnectServiceIntegrationDialog;
