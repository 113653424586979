import MuiRadio from '@material-ui/core/Radio';
import styled from 'styled-components/macro';

import RequiredBadge from '@/components/Badge/Required';
import CreateNewContentIcon from '@/components/icons/CreateNewContentIcon';
import CreateUpdatedContentIcon from '@/components/icons/CreateUpdatedContentIcon';
import { Cell } from '@/components/Pages/ContentIdeas/common';
import { NEW_CONTENT } from '@/components/Pages/ContentIdeas/constant';
import TooltipComponent from '@/components/Tooltip/Tooltip';

const StyledMuiRadio = styled(MuiRadio)`
  && {
    padding: 0px;
  }
`;
const SelectorWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: ${({ margin }) => margin};
  min-height: ${({ minHeight }) => minHeight};
  min-width: ${({ minWidth }) => minWidth};
  justify-content: ${({ justifyContent }) => justifyContent};
  width: 360px;
  min-width: 0px;
  padding: 10px 20px;
  justify-content: space-between;
  border: ${({ checked, theme }) =>
    checked
      ? `0.5px solid ${theme.cssColors.primary060}`
      : `0.5px solid ${theme.cssColors.dark020}`};
  box-sizing: border-box;
  border-radius: 3px;
  margin: 0;
  background-color: ${({ checked, theme }) => (checked ? theme.cssColors.primary005 : 'white')};
  cursor: pointer;
  height: 85px;
  :hover {
    box-shadow: 0px 3px 15px 0px #ff4d6433;
    border: ${({ theme }) => `0.5px solid ${theme.cssColors.primary060}`};
  }
`;
const BadgeWrapper = styled.div`
  span {
    font-weight: 400;
  }
  margin: 5px 0 0 5px;
`;
const StyledRequiredBadge = styled(RequiredBadge)`
  && {
    padding: 4px 8px;
  }
`;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 0;
`;
const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: ${({ margin }) => margin};
  min-height: ${({ minHeight }) => minHeight};
  min-width: ${({ minWidth }) => minWidth};
  justify-content: ${({ justifyContent }) => justifyContent};
  && {
    & > svg {
      color: transparent;
      font-size: 40px;
    }
  }
`;

function Selector({
  value,
  type,
  handleSelectType,
  text,
  margin,
  isRecommended,
  isRecommendedText,
}) {
  function handleClick() {
    handleSelectType(value);
  }

  return (
    <SelectorWrapper checked={type === value} margin={margin} onClick={handleClick}>
      <FlexWrapper minWidth="0px">
        {value === NEW_CONTENT ? <CreateNewContentIcon /> : <CreateUpdatedContentIcon />}
        <TextWrapper>
          <TooltipComponent style={{ minWidth: '0px' }} title={text}>
            <Cell margin="0 0 0 5px" noPadding weight="medium">
              {text}
            </Cell>
          </TooltipComponent>
          {isRecommended && (
            <BadgeWrapper>
              <StyledRequiredBadge>{isRecommendedText}</StyledRequiredBadge>
            </BadgeWrapper>
          )}
        </TextWrapper>
      </FlexWrapper>
      {type === value && <StyledMuiRadio checked={type === value} value={value} />}
    </SelectorWrapper>
  );
}

export default Selector;
