import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import apiClient from '@/apis/semji/apiClient';
import { showErrorSnackbar } from '@/store/actions/ui';

export default function usePostCurrentWorkspaceContentIdeaSearch() {
  const { t } = useTranslation();
  const { post } = apiClient;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const organizationId = useSelector((state) => state.defaultOrganizationId || '');
  const workspaceId = useSelector((state) => state.defaultWorkspaceId || '');

  return useMutation({
    mutationFn: async (keyword) =>
      await post(`/workspaces/${workspaceId}/content_idea_searches`, {
        keyword: keyword.trim(),
      }),

    onError: (error) => {
      if (error.response.status === 429) {
        dispatch(showErrorSnackbar(t(`content-ideas:menu.error.fair-use-limit`)));
      } else {
        dispatch(showErrorSnackbar(t(`content-ideas:menu.error.default`)));
      }
    },
    onSuccess: (contentIdeaSearch) => {
      navigate(`/o/${organizationId}/w/${workspaceId}/content-ideas/${contentIdeaSearch.data.id}`);
    },
  });
}
