/* eslint-disable sort-keys-fix/sort-keys-fix */
import './index.scss';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, Navigate, useParams } from 'react-router-dom';

import { FlexGrowContainer } from '@/components/_common';
import NotificationIcon from '@/components/icons/NotificationIcon';
import OutlinedCardIcon from '@/components/icons/OutlinedCardIcon';
import OutlinedCreditIcon from '@/components/icons/OutlinedCreditIcon';
import OutlinedDoubleUserIcon from '@/components/icons/OutlinedDoubleUserIcon';
import OutlinedLockIcon from '@/components/icons/OutlinedLockIcon';
import OutlinedUserIcon from '@/components/icons/OutlinedUserIcon';
import SettingsIcon from '@/components/icons/SettingsIcon';
import SmallCapIcon from '@/components/icons/SmallCapIcon';
import TargetIcon from '@/components/icons/TargetIcon';
import { SettingsSidePanelCategoryTitle } from '@/components/Layout/Header';
import { GRADIENT_LOGO_AND_PROFILE_MENU } from '@/components/Layout/Layout';
import { Tab, VerticalTabs } from '@/components/Navigation/Tabs';
import TabSettings from '@/components/Navigation/TabSettings';
import { PageDataLayout } from '@/components/PageDataLayout';
import { SidebarToggle } from '@/components/Sidebar';
import LayoutWrapper from '@/containers/Layout/Layout';
import BillingContainer from '@/containers/Settings/Organization/Billing';
import CreditsRoutes from '@/containers/Settings/Organization/CreditsRoutes';
import SecurityContainer from '@/containers/Settings/Organization/SecuritySettings';
import UsersContainer from '@/containers/Settings/Organization/UsersSettings';
import { Notification } from '@/containers/Settings/Profile/Notification';
import PersonalInfo from '@/containers/Settings/Profile/PersonalInfo';
import ProfileSecurityContainer from '@/containers/Settings/Profile/Security';
import GeneralSettingsContainer from '@/containers/Settings/Workspace/GeneralSettings';
import IntegrationsContainer from '@/containers/Settings/Workspace/IntegrationsSettings';
import KeywordsRelevanceSettingsContainer from '@/containers/Settings/Workspace/KeywordsRelevanceSettings';
import UsersSettingsContainer from '@/containers/Settings/Workspace/UsersRolesSettings';
import Can from '@/hoc/Can';
import useCan from '@/hooks/useCan';
import { selectCurrentOrganization } from '@/store/selectors/selectCurrentOrganization';
import { isRouteAccessGranted } from '@/utils/billing';

const ACCESS_ALL_USERS = 'ACCESS_ALL_USERS';
const ACCESS_ADMIN_USERS = 'ACCESS_ADMIN_USERS';

const PROFILE_SETTINGS_CATEGORY = 'profile';
const ORGANIZATION_SETTINGS_CATEGORY = 'organization';
const WORKSPACE_SETTINGS_CATEGORY = 'workspace';

const TABS_LIST = {
  'user-profile': {
    Component: PersonalInfo,
    access: ACCESS_ALL_USERS,
    category: PROFILE_SETTINGS_CATEGORY,
    featureFlagKey: 'profile',
    icon: <OutlinedUserIcon />,
    value: 'user-profile',
  },
  'user-notifications': {
    Component: Notification,
    access: ACCESS_ALL_USERS,
    category: PROFILE_SETTINGS_CATEGORY,
    featureFlagKey: 'profile-notifications',
    icon: <NotificationIcon />,
    value: 'user-notifications',
  },
  'user-security': {
    Component: ProfileSecurityContainer,
    access: ACCESS_ALL_USERS,
    category: PROFILE_SETTINGS_CATEGORY,
    featureFlagKey: 'profile-security',
    icon: <OutlinedLockIcon />,
    value: 'user-security',
  },

  'organization-users': {
    Component: UsersContainer,
    access: ACCESS_ADMIN_USERS,
    category: ORGANIZATION_SETTINGS_CATEGORY,
    featureFlagKey: 'users',
    icon: <OutlinedDoubleUserIcon />,
    label: 'users',
    value: 'organization-users',
  },
  credits: {
    Component: CreditsRoutes,
    access: ACCESS_ADMIN_USERS,
    category: ORGANIZATION_SETTINGS_CATEGORY,
    featureFlagKey: 'credits-logs',
    icon: <OutlinedCreditIcon />,
    value: 'credits',
  },
  security: {
    Component: SecurityContainer,
    access: ACCESS_ADMIN_USERS,
    category: ORGANIZATION_SETTINGS_CATEGORY,
    featureFlagKey: 'security',
    icon: <OutlinedLockIcon />,
    value: 'security',
  },
  billing: {
    Component: BillingContainer,
    access: ACCESS_ALL_USERS,
    category: ORGANIZATION_SETTINGS_CATEGORY,
    featureFlagKey: 'billing',
    icon: <OutlinedCardIcon />,
    value: 'billing',
  },

  'workspace-users': {
    Component: UsersSettingsContainer,
    access: ACCESS_ADMIN_USERS,
    category: WORKSPACE_SETTINGS_CATEGORY,
    featureFlagKey: 'users',
    icon: <OutlinedDoubleUserIcon />,
    value: 'workspace-users',
    label: 'users',
  },
  general: {
    Component: GeneralSettingsContainer,
    access: ACCESS_ADMIN_USERS,
    category: WORKSPACE_SETTINGS_CATEGORY,
    featureFlagKey: 'general',
    icon: <SettingsIcon />,
    value: 'general',
  },
  'excluded-keywords': {
    Component: KeywordsRelevanceSettingsContainer,
    access: ACCESS_ALL_USERS,
    category: WORKSPACE_SETTINGS_CATEGORY,
    featureFlagKey: 'keywords',
    icon: <SmallCapIcon />,
    value: 'excluded-keywords',
  },
  integrations: {
    Component: IntegrationsContainer,
    access: ACCESS_ADMIN_USERS,
    category: WORKSPACE_SETTINGS_CATEGORY,

    featureFlagKey: 'integrations',
    icon: <TargetIcon />,
    value: 'integrations',
  },
};

function Settings() {
  const { t } = useTranslation();
  const { organizationId, tab, workspaceId } = useParams();
  const currentOrganization = useSelector(selectCurrentOrganization);
  const isWorkspaceMember = useCan({
    data: { accessGranted: true },
    perform: 'workspace-member-settings:visit',
  });

  const renderTab = () => {
    const currentTab = TABS_LIST?.[tab];

    if (!currentTab) {
      return (
        <Navigate
          to={
            workspaceId
              ? `/o/${organizationId}/w/${workspaceId}/settings/user-profile`
              : `/o/${organizationId}/settings/user-profile`
          }
        />
      );
    }

    const canPerform =
      currentTab.category === WORKSPACE_SETTINGS_CATEGORY
        ? `workspace-${
            currentTab.access === ACCESS_ADMIN_USERS ? 'owner' : 'member'
          }-settings:visit`
        : currentTab.access === ACCESS_ADMIN_USERS
          ? 'organization-admin-settings:visit'
          : 'current-organization:visit';

    return (
      <Can
        data={{
          accessGranted: isRouteAccessGranted(currentOrganization),
        }}
        no={() => (
          <Navigate
            to={
              workspaceId
                ? `/o/${organizationId}/w/${workspaceId}/settings/user-profile`
                : `/o/${organizationId}/settings/user-profile`
            }
          />
        )}
        perform={canPerform}
        yes={() => <currentTab.Component title={t(`settings:tabs.${currentTab.value}`)} />}
      />
    );
  };

  const getSelectedTab = (tabId, category) => {
    const isTabNotInList =
      undefined ===
      Object.values(TABS_LIST).find(
        (tabElement) => tabElement.category === category && tabId === tabElement.value
      );
    return isTabNotInList ? false : tabId;
  };

  return (
    <LayoutWrapper flex={1} menu={GRADIENT_LOGO_AND_PROFILE_MENU}>
      <PageDataLayout
        sideBar={{
          content: (
            <div className="settings-index__tabs">
              <SettingsSidePanelCategoryTitle fontWeight="bold">
                {t(`settings:category.${PROFILE_SETTINGS_CATEGORY}`)}
              </SettingsSidePanelCategoryTitle>
              <VerticalTabs value={getSelectedTab(tab, PROFILE_SETTINGS_CATEGORY)}>
                {Object.values(TABS_LIST)
                  .filter((tabElement) => tabElement.category === PROFILE_SETTINGS_CATEGORY)
                  .map((tabElement) => (
                    <Tab
                      key={tabElement.value}
                      component={Link}
                      data-intercom-target={`organization_settings-${tabElement.value}`}
                      id={tabElement.value}
                      label={<TabSettings settingScope="profile-setting" tab={tabElement} />}
                      labelLeft
                      orientation="vertical"
                      smallHeight
                      to={
                        workspaceId
                          ? `/o/${organizationId}/w/${workspaceId}/settings/${tabElement.value}`
                          : `/o/${organizationId}/settings/${tabElement.value}`
                      }
                      value={tabElement.value}
                    />
                  ))}
              </VerticalTabs>
              <SettingsSidePanelCategoryTitle>
                {t(`settings:category.${ORGANIZATION_SETTINGS_CATEGORY}`)}
              </SettingsSidePanelCategoryTitle>
              <Can
                no={() => (
                  <VerticalTabs value={getSelectedTab(tab, ORGANIZATION_SETTINGS_CATEGORY)}>
                    {Object.values(TABS_LIST)
                      .filter(
                        (tabElement) =>
                          tabElement.access === ACCESS_ALL_USERS &&
                          tabElement.category === ORGANIZATION_SETTINGS_CATEGORY
                      )
                      .map((tabElement) => (
                        <Tab
                          key={tabElement.value}
                          component={Link}
                          data-intercom-target={`organization_settings-${tabElement.value}`}
                          id={tabElement.value}
                          label={
                            <TabSettings settingScope="organization-setting" tab={tabElement} />
                          }
                          labelLeft
                          orientation="vertical"
                          smallHeight
                          to={
                            workspaceId
                              ? `/o/${organizationId}/w/${workspaceId}/settings/${tabElement.value}`
                              : `/o/${organizationId}/settings/${tabElement.value}`
                          }
                          value={tabElement.value}
                        />
                      ))}
                  </VerticalTabs>
                )}
                perform="organization-admin-settings:visit"
                yes={() => (
                  <VerticalTabs value={getSelectedTab(tab, ORGANIZATION_SETTINGS_CATEGORY)}>
                    {Object.values(TABS_LIST)
                      .filter(
                        (tabElement) => tabElement.category === ORGANIZATION_SETTINGS_CATEGORY
                      )
                      .map((tabElement) => (
                        <Tab
                          key={tabElement.value}
                          component={Link}
                          data-intercom-target={`organization_settings-${tabElement.value}`}
                          id={tabElement.value}
                          label={
                            <TabSettings settingScope="organization-setting" tab={tabElement} />
                          }
                          labelLeft
                          orientation="vertical"
                          smallHeight
                          to={
                            workspaceId
                              ? `/o/${organizationId}/w/${workspaceId}/settings/${tabElement.value}`
                              : `/o/${organizationId}/settings/${tabElement.value}`
                          }
                          value={tabElement.value}
                        />
                      ))}
                  </VerticalTabs>
                )}
              />
              {workspaceId && isWorkspaceMember && isRouteAccessGranted(currentOrganization) && (
                <>
                  <SettingsSidePanelCategoryTitle>
                    {t(`settings:category.${WORKSPACE_SETTINGS_CATEGORY}`)}
                  </SettingsSidePanelCategoryTitle>
                  <Can
                    data={{
                      accessGranted: true,
                    }}
                    no={() => (
                      <VerticalTabs value={tab}>
                        {Object.values(TABS_LIST)
                          .filter(
                            (tab) =>
                              tab.category === WORKSPACE_SETTINGS_CATEGORY &&
                              tab.access === ACCESS_ALL_USERS
                          )
                          .map((tab) => (
                            <Tab
                              key={tab.value}
                              component={Link}
                              data-intercom-target={`workspace_settings_${tab.value}`}
                              id={tab.value}
                              label={<TabSettings settingScope="workspace-setting" tab={tab} />}
                              labelLeft
                              orientation="vertical"
                              smallHeight
                              to={`/o/${organizationId}/w/${workspaceId}/settings/${tab.value}`}
                              value={tab.value}
                            />
                          ))}
                      </VerticalTabs>
                    )}
                    perform="workspace-owner-settings:visit"
                    yes={() => (
                      <VerticalTabs value={tab}>
                        {Object.values(TABS_LIST)
                          .filter((tab) => tab.category === WORKSPACE_SETTINGS_CATEGORY)
                          .map((tab) => (
                            <Tab
                              key={tab.value}
                              component={Link}
                              data-intercom-target={`workspace_settings_${tab.value}`}
                              id={tab.value}
                              label={<TabSettings settingScope="workspace-setting" tab={tab} />}
                              labelLeft
                              orientation="vertical"
                              smallHeight
                              to={`/o/${organizationId}/w/${workspaceId}/settings/${tab.value}`}
                              value={tab.value}
                            />
                          ))}
                      </VerticalTabs>
                    )}
                  />
                </>
              )}
            </div>
          ),
          dataIntercomTarget: 'product_tour_organizations_settings',
          titleContent: <span>{t(`settings:category.settings`)}</span>,
        }}
      >
        <FlexGrowContainer>
          <SidebarToggle className="pl-8 pt-8" />
          {renderTab()}
        </FlexGrowContainer>
      </PageDataLayout>
    </LayoutWrapper>
  );
}

export default Settings;
