import { useMutation } from '@tanstack/react-query';

import apiClient from '@/apis/semji/apiClient';
import { MutationOptions } from '@/apis/types/mutation.types';
import { CompetitorTopPage } from '@/types/competitors';

interface UsePostCompetitorTopPagesProps extends MutationOptions<{}, CompetitorTopPage> {
  reportId: string;
}

export default function usePostCompetitorTopPage({
  reportId,
  ...props
}: UsePostCompetitorTopPagesProps) {
  const { post } = apiClient;

  return useMutation({
    mutationFn: async (payload) => {
      const result = await post(`/competitor_reports/${reportId}/top_pages`, payload);
      return result.data;
    },
    ...props,
  });
}
