import { CLIENT_ID, GOOGLE_API_ROOT_URL, GOOGLE_AUTH_ROOT_URL } from '@/apis/googleApi/constants';
import { ACCESS_DENIED_ERROR } from '@/utils/constants';

export const authorize = (
  scope,
  // THIS IS AN URL DECLARED IN THE GOOGLE OAUTH (/onboarding/notify-google-consent)
  redirectUri = window.location.origin + '/onboarding/notify-google-consent'
) => {
  return new Promise((resolve, reject) => {
    const url = new URL(GOOGLE_AUTH_ROOT_URL + '/o/oauth2/v2/auth');
    url.searchParams.set('scope', scope);
    url.searchParams.set('include_granted_scopes', 'true');
    url.searchParams.set('response_type', 'code');
    url.searchParams.set('access_type', 'offline');
    url.searchParams.set('prompt', 'select_account consent');
    url.searchParams.set('client_id', CLIENT_ID);
    url.searchParams.set('redirect_uri', redirectUri);
    const consentWindow = openCenteredWindow(url.toString(), 'User Consent', 600, 600);

    const interval = setInterval(() => {
      if (consentWindow.closed) {
        clearInterval(interval);
        reject(ACCESS_DENIED_ERROR);
      }
    }, 400);

    window.onmessage = function (e) {
      if (e.data?.sender === 'semji') {
        clearInterval(interval);
        consentWindow.close();
        if (e.data.error) {
          reject(e.data.error);
        } else {
          resolve(e.data.code);
        }
      }
    };
  });
};

export const fetchAnalyticsAccounts = (accessToken) => {
  const url = new URL(`${GOOGLE_API_ROOT_URL}/analytics/v3/management/accounts`);

  return fetch(url, {
    headers: new Headers({
      authorization: 'Bearer ' + accessToken,
    }),
  }).then(function (response) {
    if (response.ok) {
      return response.json().then((json) => Promise.resolve(json.items));
    }
    return response.json().then((json) => Promise.reject(json));
  });
};

export const fetchAnalyticsProfiles = (accessToken, websiteUrl) => {
  const url = new URL(
    GOOGLE_API_ROOT_URL + '/analytics/v3/management/accounts/~all/webproperties/~all/profiles'
  );

  return fetch(url, {
    headers: new Headers({
      authorization: 'Bearer ' + accessToken,
    }),
  })
    .then(function (response) {
      if (response.ok) {
        return response.json().then((json) => Promise.resolve(json.items));
      }
      return response.json().then((json) => Promise.reject(json));
    })
    .then((profiles) =>
      profiles.filter(
        (profile) =>
          profile.websiteUrl && profile.websiteUrl.toLowerCase().includes(websiteUrl.toLowerCase())
      )
    );
};

// https://developers.google.com/webmaster-tools/search-console-api-original/v3/sites/list
export const fetchSearchConsoleSitesList = (accessToken) => {
  const url = new URL(`${GOOGLE_API_ROOT_URL}/webmasters/v3/sites`);

  return fetch(url, {
    headers: new Headers({
      authorization: `Bearer ${accessToken}`,
    }),
  })
    .then(function (response) {
      if (response.ok) {
        return response
          .json()
          .then((json) =>
            Promise.resolve(
              json.siteEntry
                ? json.siteEntry.filter((site) => site.permissionLevel !== 'siteUnverifiedUser')
                : []
            )
          );
      }
      return response.json().then((json) => Promise.reject(json));
    })
    .then((properties) => properties);
};

export const openCenteredWindow = (url, title, width, height) => {
  let dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  let dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

  let windowWidth = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : window.screen.width;
  let windowHeight = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : window.screen.height;

  let left = windowWidth / 2 - width / 2 + dualScreenLeft;
  let top = windowHeight / 2 - height / 2 + dualScreenTop;
  return window.open(
    url,
    title,
    'status=yes, toolbar=no, menubar=no, location=no, addressbar=no, scrollbars=yes, resizable=yes, width=' +
      width +
      ', height=' +
      height +
      ', top=' +
      top +
      ', left=' +
      left
  );
};
