import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import CancelSubscription from '@/components/Dialog/CancelSubscription';
import ConfirmChangePlanDialog from '@/components/Dialog/ConfirmChangePlanDialog';
import WaitChangePlanDialog from '@/components/Dialog/WaitChangePlanDialog';
import { PrimaryLink } from '@/components/Navigation/Link';
import BillingService from '@/services/Billing';
import { hideCancelPlanModal } from '@/store/actions/billing';
import { showErrorSnackbar } from '@/store/actions/ui';
import {
  fetchCurrentOrganizationEditableData,
  fetchOrganization,
} from '@/store/actionsCreator/organization';
import { selectSubscriptionResourceVersion } from '@/store/selectors/selectSubscriptionResourceVersion';
import { SUPPORT_ADDRESS_MAIL } from '@/utils/constants';
import recursiveDelayedCallback from '@/utils/recursiveDelayedCallback';

function CancelPlan() {
  const { t } = useTranslation();

  const _billingService = new BillingService();
  const [modalsState, setModalsState] = useState({
    successModalShow: false,
    waitModalShow: false,
  });
  const dispatch = useDispatch();
  const show = useSelector((state) => state.billing.showCancelModal);
  const organizationId = useSelector((state) => state.defaultOrganizationId);
  const subscriptionResourceVersion = useSelector(selectSubscriptionResourceVersion);

  function closeCancelPlanModal() {
    dispatch(hideCancelPlanModal());
  }

  async function unsubscribePlan(reason) {
    try {
      await _billingService.unsubscribe(organizationId, reason);
      // wait for cancel loading logic....
      onSuccessWaitForWebhookUpdate();
    } catch (e) {
      dispatch(showErrorSnackbar(t('billing:cancel-plan.unsubscribe-error')));
    }
  }

  function fetchOrganizationUntilVersionChange() {
    recursiveDelayedCallback(async () => {
      const dispatchActionResponse = await dispatch(
        fetchCurrentOrganizationEditableData(organizationId)
      );
      return dispatchActionResponse.subscriptionResourceVersion !== subscriptionResourceVersion;
    }, onVersionChange);
  }

  function onSuccessWaitForWebhookUpdate() {
    setModalsState({
      successModalShow: false,
      waitModalShow: true,
    });
    closeCancelPlanModal();
    // wait for cancel loading logic....
    fetchOrganizationUntilVersionChange();
  }

  async function onVersionChange() {
    await dispatch(fetchOrganization(organizationId));
    setModalsState({
      successModalShow: true,
      waitModalShow: false,
    });
    closeCancelPlanModal();
  }

  function goToSemji() {
    // close everything...
    setModalsState({
      successModalShow: false,
      waitModalShow: false,
    });
    closeCancelPlanModal();
  }

  return (
    <>
      <CancelSubscription
        closeCancelSubscription={closeCancelPlanModal}
        confirmCancelSubscription={unsubscribePlan}
        show={show}
      />
      <WaitChangePlanDialog
        show={modalsState.waitModalShow}
        title={t('billing:cancel-plan.wait-change-plan-dialog.title')}
      />
      <ConfirmChangePlanDialog
        show={modalsState.successModalShow}
        title={t('billing:cancel-plan.confirm-change-plan-dialog.title')}
        onClose={goToSemji}
      >
        <Trans
          components={{
            primaryLink: (
              <PrimaryLink isExternal noDecoration to={`mailto:${SUPPORT_ADDRESS_MAIL}`} />
            ),
          }}
          i18nKey={'billing:cancel-plan.confirm-change-plan-dialog.contact-us'}
        />
      </ConfirmChangePlanDialog>
    </>
  );
}

export default CancelPlan;
