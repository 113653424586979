import './Header.scss';

import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PlacementEnum } from 'semji-core/components/Dropdown';
import {
  ENUM_MENU_SELECT_POSITION,
  ENUM_MENU_SELECT_TYPE,
  MenuSelectDropdown,
} from 'semji-core/components/MenuSelect';
import { DownIcon } from 'semji-core/icons/DownIcon';

import ContainedFavIcon, {
  FAVICON_SIZE_MD,
} from '@/containers/Competitors/components/ContainedFavIcon';
import HeaderTitle from '@/containers/Competitors/components/HeaderTitle';
import { getHighlightedUrl, getHostnamefromUrl } from '@/containers/Competitors/utils/utils';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import { CompetitorWebsite } from '@/types/competitors';

export default function Header({
  competitorWebsites,
}: {
  competitorWebsites: CompetitorWebsite[];
}) {
  const { t } = useTranslation();
  const { organizationId, workspaceId, reportId, websiteId } = useParams();
  const { search } = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const dropDrownActionsRef = useRef({
    handleClose: () => null,
    handleOpen: () => null,
  });

  const { data: maxCompetitors = 14 } = useOrganizationFeatureSet(
    'competitors:report:websites-allowance'
  );
  const selectedWebsite = competitorWebsites.find((website) => website.id === websiteId);

  const handleSelectWebsite = (item: CompetitorWebsite) => {
    dropDrownActionsRef.current.handleClose();
    return navigate(
      `/o/${organizationId}/w/${workspaceId}/search-intelligence/${reportId}/contents-duel/${item.id}${search}`
    );
  };

  function ItemTooltipContent({ website }: { website: CompetitorWebsite }) {
    return (
      <div className="competitors-duel-header__menu__item__tooltip">
        <div className="competitors-duel-header__menu__item__tooltip__url">
          {getHighlightedUrl(website).map((urlPart, index) => (
            <span
              key={`${website.id}-${urlPart.text}-${index}`}
              className={
                urlPart.isHighlighted
                  ? 'competitors-duel-header__menu__item__tooltip__url__highlight'
                  : ''
              }
            >
              {urlPart.text}
            </span>
          ))}
        </div>
        <div>{t(`competitors:configuration.${website.urlType.toLowerCase()}.title`)}</div>
      </div>
    );
  }
  return (
    <HeaderTitle
      className="competitors-duel-header__title"
      description={t('competitors:duel.header.description')}
      title={
        <>
          {t('competitors:duel.header.title')}
          <MenuSelectDropdown
            actionsRef={dropDrownActionsRef}
            anchorElement={
              <div
                className={`competitors-duel-header__menu__anchor ${isOpen && 'competitors-duel-header__menu__anchor--open'}`}
              >
                <ContainedFavIcon size={FAVICON_SIZE_MD} url={selectedWebsite?.url ?? ''} />
                <span>{getHostnamefromUrl(selectedWebsite?.url)}</span>
                <DownIcon />
              </div>
            }
            dropdownPlacement={PlacementEnum.DROPDOWN_PLACEMENT_BOTTOM_START}
            isFrame={false}
            items={[
              {
                id: 'competitors',
                label: (
                  <span className="competitors-duel-header__menu__item__label">
                    {t('competitors:header.competitors')}
                    <span className="competitors-duel-header__menu__item__label--light">
                      &nbsp;({competitorWebsites.length}/{maxCompetitors})
                    </span>
                  </span>
                ),
                onSelect: () => null,
                position: ENUM_MENU_SELECT_POSITION.TOP,
                type: ENUM_MENU_SELECT_TYPE.TITLE,
                value: 'competitors',
              },
              ...competitorWebsites
                .filter((website) => !website.isSelfReference)
                .map((website) => ({
                  icon: <ContainedFavIcon size={FAVICON_SIZE_MD} url={website.url} />,
                  id: website.id,
                  label: getHostnamefromUrl(website.url),
                  onSelect: (e: any) => handleSelectWebsite(website),
                  position: ENUM_MENU_SELECT_POSITION.DEFAULT,
                  tooltipContent: <ItemTooltipContent website={website} />,
                  type: ENUM_MENU_SELECT_TYPE.DEFAULT,
                  value: website.id,
                })),
            ]}
            itemSelected={websiteId}
            searchPlaceholder={t('components:input-search.placeholder')}
            titleTooltipCloseIcon={t('components:input-search.tooltip.title')}
            width="220px"
            onClose={() => setIsOpen(false)}
            onOpen={() => setIsOpen(true)}
          />
        </>
      }
    />
  );
}
