import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { FlexCenteredAlignContainer, FlexVerticalAlignedContainer } from '@/components/_common';
import { RedirectionBadge } from '@/components/Badge/Badge';
import InfoBadge, { useBadge } from '@/components/Badge/InfoBadge';
import FlatLoader from '@/components/Loader/FlatLoader';
import { IconExternalLink } from '@/components/Navigation/Link';
import { LoaderWrapper, TitleWrapper, Url } from '@/components/Table/Cell/TitleCell';
import { BlockText, InlineText } from '@/components/Text/Inline';
import Tooltip from '@/components/Tooltip/Tooltip';

const StyledInfoBadge = styled(InfoBadge)`
  && {
    margin-right: 3px;
  }
`;

const StyledRedirectionBadge = styled(RedirectionBadge)`
  && {
    margin-right: 3px;
  }
`;

function TitleDefaultCell({ title, url, loading, lastStatusCode, isRedirectionDetected }) {
  const { t } = useTranslation();
  const [{ badgeTitle, color }] = useBadge(lastStatusCode);

  return (
    <TitleWrapper>
      <FlexCenteredAlignContainer>
        {loading ? (
          <LoaderWrapper>
            <FlatLoader />
          </LoaderWrapper>
        ) : (
          <BlockText title={title ?? t('common:labels.unknown-title')} weight="medium">
            {title ?? t('common:labels.unknown-title')}
          </BlockText>
        )}
      </FlexCenteredAlignContainer>
      <FlexVerticalAlignedContainer>
        {Boolean(isRedirectionDetected) && !badgeTitle && lastStatusCode === 301 && (
          <StyledRedirectionBadge size="micro" />
        )}
        {badgeTitle && (
          <Tooltip title={badgeTitle}>
            <StyledInfoBadge color={color} size="micro">
              {lastStatusCode}
            </StyledInfoBadge>
          </Tooltip>
        )}
        {Boolean(url) && (
          <Url>
            <InlineText color="dark060" size="xs">
              {url}
            </InlineText>
            <IconExternalLink href={url} title={t('components:table.title-cell.link-title')} />
          </Url>
        )}
      </FlexVerticalAlignedContainer>
    </TitleWrapper>
  );
}

export default TitleDefaultCell;
