import './GridFooter.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { ENUM_ICON_SQUARE_VARIANTS, IconSquare } from 'semji-core/components/IconSquare';
import { Select, SelectDropdownItem } from 'semji-core/components/Select';
import { AngleRightEndIcon } from 'semji-core/icons/AngleRightEndIcon';

import { GridFooterProps } from '@/components/AGGrid/GridFooter/GridFooter.types';
import {
  DEFAULT_PAGE,
  QUERY_PARAM_PAGE,
  QUERY_PARAM_PER_PAGE,
} from '@/components/AGGrid/hooks/useGridPagination/const';
import AngleLeft from '@/components/icons/AngleLeft';
import AngleRight from '@/components/icons/AngleRight';

function GridFooter({ paginationHook, gridStateHook }: GridFooterProps) {
  const [query] = useSearchParams();
  const { prefetchPages, totalItems: totalItemsFetched, pageCount } = gridStateHook;
  const { t } = useTranslation();
  const pageQueryParam = query.get(QUERY_PARAM_PAGE);
  const itemsPerPageQueryParam = query.get(QUERY_PARAM_PER_PAGE);
  const page = pageQueryParam ? parseInt(pageQueryParam) : DEFAULT_PAGE;
  const itemsPerPage = itemsPerPageQueryParam ? parseInt(itemsPerPageQueryParam) : DEFAULT_PAGE;

  const isFirstPage = page === DEFAULT_PAGE;
  const isLastPage = page === Math.max(pageCount ?? DEFAULT_PAGE, DEFAULT_PAGE);
  const totalItems = totalItemsFetched ?? paginationHook.pagination.totalItems;

  function handleChangePerPage(perPage: string) {
    paginationHook.setItemsPerPage(parseInt(perPage));
  }

  const DropdownItem = ({ optionValue, ...props }) => (
    <SelectDropdownItem {...props}>
      <span>{optionValue}</span>
    </SelectDropdownItem>
  );

  function goNextPage() {
    if (isLastPage) return;
    const targetPage = page + 1;
    paginationHook.setPagination({ ...paginationHook.pagination, page: targetPage });
    return targetPage;
  }

  function goPreviousPage() {
    if (isFirstPage) return;
    const targetPage = Math.max(page - 1, DEFAULT_PAGE);
    paginationHook.setPagination({ ...paginationHook.pagination, page: targetPage });
    return targetPage;
  }

  function goFirstPage() {
    if (isFirstPage) return;
    paginationHook.setPagination({ ...paginationHook.pagination, page: DEFAULT_PAGE });
    return DEFAULT_PAGE;
  }

  function goLastPage() {
    if (isLastPage) return;
    const targetPage = pageCount || DEFAULT_PAGE;
    paginationHook.setPagination({
      ...paginationHook.pagination,
      page: targetPage,
    });
    return targetPage;
  }

  function handleFirstPage() {
    const page = goFirstPage();
    if (page) prefetchPages({ page });
  }

  function handlePreviousPage() {
    const page = goPreviousPage();
    if (page) prefetchPages({ page });
  }

  function handleNextPage() {
    const page = goNextPage();
    if (page) prefetchPages({ page });
  }

  function handleLastPage() {
    const page = goLastPage();
    if (page) prefetchPages({ page });
  }
  const options = ['20', '50', '100'];

  return (
    <div className="grid-footer">
      <div className="flex items-center gap-2">
        <div>{t('grid:footer.rows-per-page')}</div>
        <div className="w-[88px]">
          <Select
            defaultValueIndex={options.findIndex(
              (option) => option === paginationHook.pagination.itemsPerPage.toString()
            )}
            DropdownItem={DropdownItem}
            getValue={(option) => option}
            options={options}
            querySelectorListener="body"
            variant="number"
            width="88px"
            onChange={handleChangePerPage}
            onCloseDropdown={() => null}
          />
        </div>
      </div>
      <div>
        {itemsPerPage * page - itemsPerPage + 1}-
        {Math.min(itemsPerPage * page, totalItems || 999999999)} {t('grid:footer.of')}{' '}
        {totalItems ?? '-'}
      </div>
      <div className="flex items-center gap-4">
        <IconSquare
          disabled={isFirstPage}
          variant={ENUM_ICON_SQUARE_VARIANTS.Tertiary}
          onClick={handleFirstPage}
        >
          <AngleRightEndIcon className={'rotate-180'} />
        </IconSquare>
        <IconSquare
          disabled={isFirstPage}
          variant={ENUM_ICON_SQUARE_VARIANTS.Tertiary}
          onClick={handlePreviousPage}
        >
          <AngleLeft />
        </IconSquare>
        <IconSquare
          disabled={isLastPage}
          variant={ENUM_ICON_SQUARE_VARIANTS.Tertiary}
          onClick={handleNextPage}
        >
          <AngleRight />
        </IconSquare>
        <IconSquare
          disabled={isLastPage}
          variant={ENUM_ICON_SQUARE_VARIANTS.Tertiary}
          onClick={handleLastPage}
        >
          <AngleRightEndIcon />
        </IconSquare>
      </div>
    </div>
  );
}

export default GridFooter;
