import { Trans } from 'react-i18next';
import styled from 'styled-components/macro';

const AIBadge = styled.span`
  font-weight: 800;
  background-color: ${(props) => props.theme.cssColors.primary};
  padding: 2px;
  border-radius: 3px;
  margin: 0px 5px;
  font-size: ${(props) => props.theme.textCss.sizes.xs};
  color: ${(props) => props.theme.cssColors.white};
`;
const AIWritingButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  color: ${({ theme }) => theme.cssColors.primary};
  font-size: ${(props) => props.theme.textCss.sizes.sm};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  padding: 7px 6px 7px 2px;
  border-radius: 3px;
  background-color: ${(props) => props.active && props.theme.cssColors.primary010};

  &:hover {
    color: ${(props) => props.theme.cssColors.primary};
    background-color: ${(props) => props.theme.cssColors.primary010};
  }
`;

export default function AIWritingButton({
  active,
  disabled,
  onClick,
  noIntercom = false,
  ...props
}) {
  return (
    <AIWritingButtonWrapper
      active={active}
      data-intercom-target={noIntercom ? null : 'editor_content_ai_writing'}
      disabled={disabled}
      onClick={disabled ? undefined : onClick}
      {...props}
    >
      <Trans
        components={{
          AIBadge: <AIBadge />,
        }}
        i18nKey={'content:ai-writing.ai-writing'}
      />
    </AIWritingButtonWrapper>
  );
}
