import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { ContainedButton, OutlinedButton } from '@/components/Button/Button';
import { Error } from '@/components/Text/Error';
import AccessDenied from '@/containers/ServiceIntegration/SearchConsole/Steps/AccessDenied';
import ConnectStep from '@/containers/ServiceIntegration/SearchConsole/Steps/Connect';
import Dialog from '@/containers/ServiceIntegration/SearchConsole/Steps/Dialog/Dialog';
import TextButton from '@/containers/ServiceIntegration/SearchConsole/Steps/Dialog/TextButton';
import NoMatchingProperties from '@/containers/ServiceIntegration/SearchConsole/Steps/NoMatchingProperties';
import NoProperties from '@/containers/ServiceIntegration/SearchConsole/Steps/NoProperties';
import Properties from '@/containers/ServiceIntegration/SearchConsole/Steps/Properties';
import { SEARCH_CONSOLE_VALUE } from '@/utils/constants';
import { ACCESS_DENIED_ERROR } from '@/utils/constants';
import { getOriginFromUrl } from '@/utils/url';

const FISRT_STEP = 'FISRT_STEP';
const SECOND_STEP = 'SECOND_STEP';
const NO_PROPERTIES_FOUND = 'NO_PROPERTIES_FOUND';
const NO_PROPERTIES_MATCH = 'NO_PROPERTIES_MATCH';
const ACCESS_DENIED = 'ACCESS_DENIED';
const UNREACHABLE_STATE = 'UNREACHABLE_STATE';

const OutlinedTextButton = styled(TextButton)`
  &&&& {
    border: 1px solid ${({ theme }) => theme.cssColors.dark040};
    margin-right: ${({ withMargin }) => withMargin && '20px'};
  }
`;

function SearchConsoleServiceIntegration({
  handleClose,
  handlePrevious,
  onChangeSearchConsoleDataSource,
  workspaceWebsiteUrl,
}) {
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const [validProperties, setValidProperties] = useState([]);
  const [invalidProperties, setInvalidProperties] = useState([]);
  const [property, setProperty] = useState(null);
  const [googleCredentials, setGoogleCredentials] = useState(null);
  const [error, setError] = useState(null);

  const originWebsiteUrl = getOriginFromUrl(workspaceWebsiteUrl);

  const SCREEN_STATE = useMemo(() => {
    if (error === ACCESS_DENIED_ERROR) {
      return ACCESS_DENIED;
    } else if (step === 0) {
      return FISRT_STEP;
    } else if (step === 1) {
      // No properties present in this account
      if (!validProperties.length && !invalidProperties.length) {
        return NO_PROPERTIES_FOUND;
        // No matching properties
      } else if (!validProperties.length) {
        return NO_PROPERTIES_MATCH;
      } else {
        return SECOND_STEP;
      }
    } else {
      return UNREACHABLE_STATE;
    }
  }, [error, invalidProperties.length, step, validProperties.length]);

  function handleChange(siteUrl) {
    const selectedProperty = [...validProperties, ...invalidProperties].find(
      (propertyData) => siteUrl === propertyData.siteUrl
    );

    setProperty(selectedProperty);
  }

  function consent(consentData) {
    if (consentData) {
      const { searchConsoleProperties = {}, googleCredentialsValue = [] } = consentData;
      const { invalid, valid } = searchConsoleProperties;

      setValidProperties(valid);
      setInvalidProperties(invalid);

      // Set best choice based on url validation and number of pages
      if (!!valid?.[0]?.siteUrl) {
        setProperty(valid?.[0]);
      }

      setGoogleCredentials(googleCredentialsValue);
      setStep(step + 1);
    }
  }

  function handleValidate() {
    if (!property?.siteUrl) {
      return;
    }

    onChangeSearchConsoleDataSource({
      googleCredentials,
      name: SEARCH_CONSOLE_VALUE,
      property,
    });

    handleClose();
  }

  function isNextDisabled() {
    return !property?.siteUrl || error;
  }

  function updateSearchConsoleProperty() {
    setStep(0);
    setError(null);
  }

  function updateWebsiteUrl() {
    handleClose();
    handlePrevious();
  }

  return (
    <>
      {SCREEN_STATE === ACCESS_DENIED && (
        <Dialog
          actions={
            <>
              <OutlinedTextButton onClick={handleClose}>
                {t('service-integration:button-label.cancel')}
              </OutlinedTextButton>
              <ContainedButton onClick={updateSearchConsoleProperty}>
                {t('service-integration:button-label.select-another-account')}
              </ContainedButton>
            </>
          }
          maxWidth="510px"
          onClose={handleClose}
        >
          <AccessDenied />
        </Dialog>
      )}

      {SCREEN_STATE === FISRT_STEP && (
        <Dialog
          actions={
            <>
              {!!error && <Error>{error}</Error>}
              <OutlinedTextButton withMargin onClick={handleClose}>
                {t('service-integration:button-label.cancel')}
              </OutlinedTextButton>
              <ContainedButton disabled>
                {t('service-integration:button-label.activate')}
              </ContainedButton>
            </>
          }
          onClose={handleClose}
        >
          <ConnectStep
            autoTrigger
            consent={consent}
            setError={setError}
            workspaceWebsiteUrl={originWebsiteUrl}
          />
        </Dialog>
      )}

      {SCREEN_STATE === SECOND_STEP && (
        <Dialog
          actions={
            <>
              {!!error && <Error>{error}</Error>}
              <OutlinedTextButton onClick={handleClose}>
                {t('service-integration:button-label.cancel')}
              </OutlinedTextButton>
              <ContainedButton disabled={isNextDisabled()} onClick={handleValidate}>
                {t('service-integration:button-label.activate')}
              </ContainedButton>
            </>
          }
          fullWidth
          onClose={handleClose}
        >
          <Properties
            handleChange={handleChange}
            invalidProperties={invalidProperties}
            property={property}
            validProperties={validProperties}
            workspaceWebsiteUrl={originWebsiteUrl}
          />
        </Dialog>
      )}

      {SCREEN_STATE === NO_PROPERTIES_FOUND && (
        <Dialog
          actions={
            <>
              {!!error && <Error>{error}</Error>}
              <OutlinedTextButton onClick={handleClose}>
                {t('service-integration:button-label.cancel')}
              </OutlinedTextButton>
              <ContainedButton onClick={updateSearchConsoleProperty}>
                {t('service-integration:button-label.select-another-account')}
              </ContainedButton>
            </>
          }
          maxWidth="510px"
          onClose={handleClose}
        >
          <NoProperties />
        </Dialog>
      )}

      {SCREEN_STATE === NO_PROPERTIES_MATCH && (
        <Dialog
          actions={
            <>
              {!!error && <Error>{error}</Error>}
              <OutlinedButton color="primary" width="205px" onClick={updateWebsiteUrl}>
                {t('service-integration:button-label.edit-website-url')}
              </OutlinedButton>
              <ContainedButton width="205px" onClick={updateSearchConsoleProperty}>
                {t('service-integration:button-label.select-another-account')}
              </ContainedButton>
            </>
          }
          fullWidth
          onClose={handleClose}
        >
          <NoMatchingProperties
            handleChange={handleChange}
            invalidProperties={invalidProperties}
            property={property}
            workspaceWebsiteUrl={originWebsiteUrl}
          />
        </Dialog>
      )}

      {SCREEN_STATE === UNREACHABLE_STATE && null}
    </>
  );
}

export default SearchConsoleServiceIntegration;
