import { TFunction } from 'i18next';

export function getAgGridTranslations(t: TFunction) {
  return {
    AreaColumnCombo: t('ag-grid:table.AreaColumnCombo'),
    addCurrentSelectionToFilter: t('ag-grid:table.addCurrentSelectionToFilter'),
    addToLabels: t('ag-grid:table.addToLabels'),
    addToValues: t('ag-grid:table.addToValues'),
    advancedFilterAnd: t('ag-grid:table.advancedFilterAnd'),
    advancedFilterApply: t('ag-grid:table.advancedFilterApply'),
    advancedFilterBlank: t('ag-grid:table.advancedFilterBlank'),
    advancedFilterBuilder: t('ag-grid:table.advancedFilterBuilder'),
    advancedFilterBuilderAddButtonTooltip: t('ag-grid:table.advancedFilterBuilderAddButtonTooltip'),
    advancedFilterBuilderAddCondition: t('ag-grid:table.advancedFilterBuilderAddCondition'),
    advancedFilterBuilderAddJoin: t('ag-grid:table.advancedFilterBuilderAddJoin'),
    advancedFilterBuilderApply: t('ag-grid:table.advancedFilterBuilderApply'),
    advancedFilterBuilderCancel: t('ag-grid:table.advancedFilterBuilderCancel'),
    advancedFilterBuilderEnterValue: t('ag-grid:table.advancedFilterBuilderEnterValue'),
    advancedFilterBuilderMoveDownButtonTooltip: t(
      'ag-grid:table.advancedFilterBuilderMoveDownButtonTooltip'
    ),
    advancedFilterBuilderMoveUpButtonTooltip: t(
      'ag-grid:table.advancedFilterBuilderMoveUpButtonTooltip'
    ),
    advancedFilterBuilderRemoveButtonTooltip: t(
      'ag-grid:table.advancedFilterBuilderRemoveButtonTooltip'
    ),
    advancedFilterBuilderSelectColumn: t('ag-grid:table.advancedFilterBuilderSelectColumn'),
    advancedFilterBuilderSelectOption: t('ag-grid:table.advancedFilterBuilderSelectOption'),
    advancedFilterBuilderTitle: t('ag-grid:table.advancedFilterBuilderTitle'),
    advancedFilterBuilderValidationAlreadyApplied: t(
      'ag-grid:table.advancedFilterBuilderValidationAlreadyApplied'
    ),
    advancedFilterBuilderValidationEnterValue: t(
      'ag-grid:table.advancedFilterBuilderValidationEnterValue'
    ),
    advancedFilterBuilderValidationIncomplete: t(
      'ag-grid:table.advancedFilterBuilderValidationIncomplete'
    ),
    advancedFilterBuilderValidationSelectColumn: t(
      'ag-grid:table.advancedFilterBuilderValidationSelectColumn'
    ),
    advancedFilterBuilderValidationSelectOption: t(
      'ag-grid:table.advancedFilterBuilderValidationSelectOption'
    ),
    advancedFilterContains: t('ag-grid:table.advancedFilterContains'),
    advancedFilterEndsWith: t('ag-grid:table.advancedFilterEndsWith'),
    advancedFilterEquals: t('ag-grid:table.advancedFilterEquals'),
    advancedFilterFalse: t('ag-grid:table.advancedFilterFalse'),
    advancedFilterGreaterThan: t('ag-grid:table.advancedFilterGreaterThan'),
    advancedFilterGreaterThanOrEqual: t('ag-grid:table.advancedFilterGreaterThanOrEqual'),
    advancedFilterLessThan: t('ag-grid:table.advancedFilterLessThan'),
    advancedFilterLessThanOrEqual: t('ag-grid:table.advancedFilterLessThanOrEqual'),
    advancedFilterNotBlank: t('ag-grid:table.advancedFilterNotBlank'),
    advancedFilterNotContains: t('ag-grid:table.advancedFilterNotContains'),
    advancedFilterNotEqual: t('ag-grid:table.advancedFilterNotEqual'),
    advancedFilterOr: t('ag-grid:table.advancedFilterOr'),
    advancedFilterStartsWith: t('ag-grid:table.advancedFilterStartsWith'),
    advancedFilterTextEquals: t('ag-grid:table.advancedFilterTextEquals'),
    advancedFilterTextNotEqual: t('ag-grid:table.advancedFilterTextNotEqual'),
    advancedFilterTrue: t('ag-grid:table.advancedFilterTrue'),
    advancedFilterValidationExtraEndBracket: t(
      'ag-grid:table.advancedFilterValidationExtraEndBracket'
    ),
    advancedFilterValidationInvalidColumn: t('ag-grid:table.advancedFilterValidationInvalidColumn'),
    advancedFilterValidationInvalidDate: t('ag-grid:table.advancedFilterValidationInvalidDate'),
    advancedFilterValidationInvalidJoinOperator: t(
      'ag-grid:table.advancedFilterValidationInvalidJoinOperator'
    ),
    advancedFilterValidationInvalidOption: t('ag-grid:table.advancedFilterValidationInvalidOption'),
    advancedFilterValidationJoinOperatorMismatch: t(
      'ag-grid:table.advancedFilterValidationJoinOperatorMismatch'
    ),
    advancedFilterValidationMessage: t('ag-grid:table.advancedFilterValidationMessage'),
    advancedFilterValidationMessageAtEnd: t('ag-grid:table.advancedFilterValidationMessageAtEnd'),
    advancedFilterValidationMissingColumn: t('ag-grid:table.advancedFilterValidationMissingColumn'),
    advancedFilterValidationMissingCondition: t(
      'ag-grid:table.advancedFilterValidationMissingCondition'
    ),
    advancedFilterValidationMissingEndBracket: t(
      'ag-grid:table.advancedFilterValidationMissingEndBracket'
    ),
    advancedFilterValidationMissingOption: t('ag-grid:table.advancedFilterValidationMissingOption'),
    advancedFilterValidationMissingQuote: t('ag-grid:table.advancedFilterValidationMissingQuote'),
    advancedFilterValidationMissingValue: t('ag-grid:table.advancedFilterValidationMissingValue'),
    advancedFilterValidationNotANumber: t('ag-grid:table.advancedFilterValidationNotANumber'),
    advancedSettings: t('ag-grid:table.advancedSettings'),
    after: t('ag-grid:table.after'),
    aggregate: t('ag-grid:table.aggregate'),
    andCondition: t('ag-grid:table.andCondition'),
    animation: t('ag-grid:table.animation'),
    applyFilter: t('ag-grid:table.applyFilter'),
    april: t('ag-grid:table.april'),
    area: t('ag-grid:table.area'),
    areaChart: t('ag-grid:table.areaChart'),
    areaColumnComboTooltip: t('ag-grid:table.areaColumnComboTooltip'),
    areaGroup: t('ag-grid:table.areaGroup'),
    ariaAdvancedFilterBuilderColumn: t('ag-grid:table.ariaAdvancedFilterBuilderColumn'),
    ariaAdvancedFilterBuilderFilterItem: t('ag-grid:table.ariaAdvancedFilterBuilderFilterItem'),
    ariaAdvancedFilterBuilderGroupItem: t('ag-grid:table.ariaAdvancedFilterBuilderGroupItem'),
    ariaAdvancedFilterBuilderItem: t('ag-grid:table.ariaAdvancedFilterBuilderItem'),
    ariaAdvancedFilterBuilderItemValidation: t(
      'ag-grid:table.ariaAdvancedFilterBuilderItemValidation'
    ),
    ariaAdvancedFilterBuilderJoinOperator: t('ag-grid:table.ariaAdvancedFilterBuilderJoinOperator'),
    ariaAdvancedFilterBuilderList: t('ag-grid:table.ariaAdvancedFilterBuilderList'),
    ariaAdvancedFilterBuilderOption: t('ag-grid:table.ariaAdvancedFilterBuilderOption'),
    ariaAdvancedFilterBuilderValue: t('ag-grid:table.ariaAdvancedFilterBuilderValue'),
    ariaAdvancedFilterInput: t('ag-grid:table.ariaAdvancedFilterInput'),
    ariaChartMenuClose: t('ag-grid:table.ariaChartMenuClose'),
    ariaChecked: t('ag-grid:table.ariaChecked'),
    ariaColumn: t('ag-grid:table.ariaColumn'),
    ariaColumnFiltered: t('ag-grid:table.ariaColumnFiltered'),
    ariaColumnGroup: t('ag-grid:table.ariaColumnGroup'),
    ariaColumnPanelList: t('ag-grid:table.ariaColumnPanelList'),
    ariaColumnSelectAll: t('ag-grid:table.ariaColumnSelectAll'),
    ariaDateFilterInput: t('ag-grid:table.ariaDateFilterInput'),
    ariaDefaultListName: t('ag-grid:table.ariaDefaultListName'),
    ariaDropZoneColumnComponentAggFuncSeparator: t(
      'ag-grid:table.ariaDropZoneColumnComponentAggFuncSeparator'
    ),
    ariaDropZoneColumnComponentDescription: t(
      'ag-grid:table.ariaDropZoneColumnComponentDescription'
    ),
    ariaDropZoneColumnComponentSortAscending: t(
      'ag-grid:table.ariaDropZoneColumnComponentSortAscending'
    ),
    ariaDropZoneColumnComponentSortDescending: t(
      'ag-grid:table.ariaDropZoneColumnComponentSortDescending'
    ),
    ariaDropZoneColumnGroupItemDescription: t(
      'ag-grid:table.ariaDropZoneColumnGroupItemDescription'
    ),
    ariaDropZoneColumnValueItemDescription: t(
      'ag-grid:table.ariaDropZoneColumnValueItemDescription'
    ),
    ariaFilterColumn: t('ag-grid:table.ariaFilterColumn'),
    ariaFilterColumnsInput: t('ag-grid:table.ariaFilterColumnsInput'),
    ariaFilterFromValue: t('ag-grid:table.ariaFilterFromValue'),
    ariaFilterInput: t('ag-grid:table.ariaFilterInput'),
    ariaFilterList: t('ag-grid:table.ariaFilterList'),
    ariaFilterMenuOpen: t('ag-grid:table.ariaFilterMenuOpen'),
    ariaFilterPanelList: t('ag-grid:table.ariaFilterPanelList'),
    ariaFilterToValue: t('ag-grid:table.ariaFilterToValue'),
    ariaFilterValue: t('ag-grid:table.ariaFilterValue'),
    ariaFilteringOperator: t('ag-grid:table.ariaFilteringOperator'),
    ariaHidden: t('ag-grid:table.ariaHidden'),
    ariaIndeterminate: t('ag-grid:table.ariaIndeterminate'),
    ariaInputEditor: t('ag-grid:table.ariaInputEditor'),
    ariaLabelAdvancedFilterAutocomplete: t('ag-grid:table.ariaLabelAdvancedFilterAutocomplete'),
    ariaLabelAdvancedFilterBuilderAddField: t(
      'ag-grid:table.ariaLabelAdvancedFilterBuilderAddField'
    ),
    ariaLabelAdvancedFilterBuilderColumnSelectField: t(
      'ag-grid:table.ariaLabelAdvancedFilterBuilderColumnSelectField'
    ),
    ariaLabelAdvancedFilterBuilderJoinSelectField: t(
      'ag-grid:table.ariaLabelAdvancedFilterBuilderJoinSelectField'
    ),
    ariaLabelAdvancedFilterBuilderOptionSelectField: t(
      'ag-grid:table.ariaLabelAdvancedFilterBuilderOptionSelectField'
    ),
    ariaLabelAggregationFunction: t('ag-grid:table.ariaLabelAggregationFunction'),
    ariaLabelCellEditor: t('ag-grid:table.ariaLabelCellEditor'),
    ariaLabelColumnFilter: t('ag-grid:table.ariaLabelColumnFilter'),
    ariaLabelColumnMenu: t('ag-grid:table.ariaLabelColumnMenu'),
    ariaLabelContextMenu: t('ag-grid:table.ariaLabelContextMenu'),
    ariaLabelDialog: t('ag-grid:table.ariaLabelDialog'),
    ariaLabelRichSelectField: t('ag-grid:table.ariaLabelRichSelectField'),
    ariaLabelSelectField: t('ag-grid:table.ariaLabelSelectField'),
    ariaLabelSubMenu: t('ag-grid:table.ariaLabelSubMenu'),
    ariaLabelTooltip: t('ag-grid:table.ariaLabelTooltip'),
    ariaMenuColumn: t('ag-grid:table.ariaMenuColumn'),
    ariaPageSizeSelectorLabel: t('ag-grid:table.ariaPageSizeSelectorLabel'),
    ariaPivotDropZonePanelLabel: t('ag-grid:table.ariaPivotDropZonePanelLabel'),
    ariaRowDeselect: t('ag-grid:table.ariaRowDeselect'),
    ariaRowGroupDropZonePanelLabel: t('ag-grid:table.ariaRowGroupDropZonePanelLabel'),
    ariaRowSelect: t('ag-grid:table.ariaRowSelect'),
    ariaRowSelectAll: t('ag-grid:table.ariaRowSelectAll'),
    ariaRowSelectionDisabled: t('ag-grid:table.ariaRowSelectionDisabled'),
    ariaRowToggleSelection: t('ag-grid:table.ariaRowToggleSelection'),
    ariaSearch: t('ag-grid:table.ariaSearch'),
    ariaSearchFilterValues: t('ag-grid:table.ariaSearchFilterValues'),
    ariaSortableColumn: t('ag-grid:table.ariaSortableColumn'),
    ariaToggleCellValue: t('ag-grid:table.ariaToggleCellValue'),
    ariaToggleVisibility: t('ag-grid:table.ariaToggleVisibility'),
    ariaUnchecked: t('ag-grid:table.ariaUnchecked'),
    ariaValuesDropZonePanelLabel: t('ag-grid:table.ariaValuesDropZonePanelLabel'),
    ariaVisible: t('ag-grid:table.ariaVisible'),
    august: t('ag-grid:table.august'),
    autoRotate: t('ag-grid:table.autoRotate'),
    automatic: t('ag-grid:table.automatic'),
    autosizeAllColumns: t('ag-grid:table.autosizeAllColumns'),
    autosizeThiscolumn: t('ag-grid:table.autosizeThiscolumn'),
    avg: t('ag-grid:table.avg'),
    axis: t('ag-grid:table.axis'),
    axisType: t('ag-grid:table.axisType'),
    background: t('ag-grid:table.background'),
    barChart: t('ag-grid:table.barChart'),
    barGroup: t('ag-grid:table.barGroup'),
    before: t('ag-grid:table.before'),
    blank: t('ag-grid:table.blank'),
    blanks: t('ag-grid:table.blanks'),
    blur: t('ag-grid:table.blur'),
    bold: t('ag-grid:table.bold'),
    boldItalic: t('ag-grid:table.boldItalic'),
    bottom: t('ag-grid:table.bottom'),
    boxPlot: t('ag-grid:table.boxPlot'),
    boxPlotTooltip: t('ag-grid:table.boxPlotTooltip'),
    bubble: t('ag-grid:table.bubble'),
    bubbleTooltip: t('ag-grid:table.bubbleTooltip'),
    callout: t('ag-grid:table.callout'),
    calloutLabels: t('ag-grid:table.calloutLabels'),
    cancelFilter: t('ag-grid:table.cancelFilter'),
    cap: t('ag-grid:table.cap'),
    capLengthRatio: t('ag-grid:table.capLengthRatio'),
    categories: t('ag-grid:table.categories'),
    category: t('ag-grid:table.category'),
    categoryAdd: t('ag-grid:table.categoryAdd'),
    categoryValues: t('ag-grid:table.categoryValues'),
    chart: t('ag-grid:table.chart'),
    chartAdvancedSettings: t('ag-grid:table.chartAdvancedSettings'),
    chartDownload: t('ag-grid:table.chartDownload'),
    chartDownloadToolbarTooltip: t('ag-grid:table.chartDownloadToolbarTooltip'),
    chartEdit: t('ag-grid:table.chartEdit'),
    chartLink: t('ag-grid:table.chartLink'),
    chartLinkToolbarTooltip: t('ag-grid:table.chartLinkToolbarTooltip'),
    chartRange: t('ag-grid:table.chartRange'),
    chartSettingsToolbarTooltip: t('ag-grid:table.chartSettingsToolbarTooltip'),
    chartUnlink: t('ag-grid:table.chartUnlink'),
    chartUnlinkToolbarTooltip: t('ag-grid:table.chartUnlinkToolbarTooltip'),
    circle: t('ag-grid:table.circle'),
    clearFilter: t('ag-grid:table.clearFilter'),
    collapseAll: t('ag-grid:table.collapseAll'),
    color: t('ag-grid:table.color'),
    columnChart: t('ag-grid:table.columnChart'),
    columnChooser: t('ag-grid:table.columnChooser'),
    columnFilter: t('ag-grid:table.columnFilter'),
    columnGroup: t('ag-grid:table.columnGroup'),
    columnLineCombo: t('ag-grid:table.columnLineCombo'),
    columnLineComboTooltip: t('ag-grid:table.columnLineComboTooltip'),
    columns: t('ag-grid:table.columns'),
    combinationChart: t('ag-grid:table.combinationChart'),
    combinationGroup: t('ag-grid:table.combinationGroup'),
    connectorLine: t('ag-grid:table.connectorLine'),
    contains: t('ag-grid:table.contains'),
    copy: t('ag-grid:table.copy'),
    copyWithGroupHeaders: t('ag-grid:table.copyWithGroupHeaders'),
    copyWithHeaders: t('ag-grid:table.copyWithHeaders'),
    count: t('ag-grid:table.count'),
    crosshair: t('ag-grid:table.crosshair'),
    crosshairLabel: t('ag-grid:table.crosshairLabel'),
    crosshairSnap: t('ag-grid:table.crosshairSnap'),
    csvExport: t('ag-grid:table.csvExport'),
    ctrlC: t('ag-grid:table.ctrlC'),
    ctrlV: t('ag-grid:table.ctrlV'),
    ctrlX: t('ag-grid:table.ctrlX'),
    customComboTooltip: t('ag-grid:table.customComboTooltip'),
    cut: t('ag-grid:table.cut'),
    data: t('ag-grid:table.data'),
    dateFilter: t('ag-grid:table.dateFilter'),
    dateFormatOoo: t('ag-grid:table.dateFormatOoo'),
    december: t('ag-grid:table.december'),
    decimalSeparator: t('ag-grid:table.decimalSeparator'),
    defaultCategory: t('ag-grid:table.defaultCategory'),
    donut: t('ag-grid:table.donut'),
    donutTooltip: t('ag-grid:table.donutTooltip'),
    durationMillis: t('ag-grid:table.durationMillis'),
    empty: t('ag-grid:table.empty'),
    enabled: t('ag-grid:table.enabled'),
    endAngle: t('ag-grid:table.endAngle'),
    endsWith: t('ag-grid:table.endsWith'),
    equals: t('ag-grid:table.equals'),
    excelExport: t('ag-grid:table.excelExport'),
    expandAll: t('ag-grid:table.expandAll'),
    export: t('ag-grid:table.export'),
    false: t('ag-grid:table.false'),
    february: t('ag-grid:table.february'),
    fillOpacity: t('ag-grid:table.fillOpacity'),
    filterOoo: t('ag-grid:table.filterOoo'),
    filteredRows: t('ag-grid:table.filteredRows'),
    filters: t('ag-grid:table.filters'),
    first: t('ag-grid:table.first'),
    firstPage: t('ag-grid:table.firstPage'),
    fixed: t('ag-grid:table.fixed'),
    font: t('ag-grid:table.font'),
    footerTotal: t('ag-grid:table.footerTotal'),
    format: t('ag-grid:table.format'),
    greaterThan: t('ag-grid:table.greaterThan'),
    greaterThanOrEqual: t('ag-grid:table.greaterThanOrEqual'),
    gridLines: t('ag-grid:table.gridLines'),
    group: t('ag-grid:table.group'),
    groupBy: t('ag-grid:table.groupBy'),
    groupFilterSelect: t('ag-grid:table.groupFilterSelect'),
    groupPadding: t('ag-grid:table.groupPadding'),
    groupedAreaTooltip: t('ag-grid:table.groupedAreaTooltip'),
    groupedBar: t('ag-grid:table.groupedBar'),
    groupedBarTooltip: t('ag-grid:table.groupedBarTooltip'),
    groupedColumn: t('ag-grid:table.groupedColumn'),
    groupedColumnTooltip: t('ag-grid:table.groupedColumnTooltip'),
    groups: t('ag-grid:table.groups'),
    heatmap: t('ag-grid:table.heatmap'),
    heatmapTooltip: t('ag-grid:table.heatmapTooltip'),
    height: t('ag-grid:table.height'),
    hierarchicalChart: t('ag-grid:table.hierarchicalChart'),
    hierarchicalGroup: t('ag-grid:table.hierarchicalGroup'),
    histogramBinCount: t('ag-grid:table.histogramBinCount'),
    histogramChart: t('ag-grid:table.histogramChart'),
    histogramFrequency: t('ag-grid:table.histogramFrequency'),
    histogramTooltip: t('ag-grid:table.histogramTooltip'),
    inRange: t('ag-grid:table.inRange'),
    inRangeEnd: t('ag-grid:table.inRangeEnd'),
    inRangeStart: t('ag-grid:table.inRangeStart'),
    innerRadius: t('ag-grid:table.innerRadius'),
    inside: t('ag-grid:table.inside'),
    invalidDate: t('ag-grid:table.invalidDate'),
    invalidNumber: t('ag-grid:table.invalidNumber'),
    italic: t('ag-grid:table.italic'),
    itemPaddingX: t('ag-grid:table.itemPaddingX'),
    itemPaddingY: t('ag-grid:table.itemPaddingY'),
    itemSpacing: t('ag-grid:table.itemSpacing'),
    january: t('ag-grid:table.january'),
    july: t('ag-grid:table.july'),
    june: t('ag-grid:table.june'),
    labelPlacement: t('ag-grid:table.labelPlacement'),
    labelRotation: t('ag-grid:table.labelRotation'),
    labels: t('ag-grid:table.labels'),
    last: t('ag-grid:table.last'),
    lastPage: t('ag-grid:table.lastPage'),
    layoutHorizontalSpacing: t('ag-grid:table.layoutHorizontalSpacing'),
    layoutVerticalSpacing: t('ag-grid:table.layoutVerticalSpacing'),
    left: t('ag-grid:table.left'),
    legend: t('ag-grid:table.legend'),
    length: t('ag-grid:table.length'),
    lessThan: t('ag-grid:table.lessThan'),
    lessThanOrEqual: t('ag-grid:table.lessThanOrEqual'),
    line: t('ag-grid:table.line'),
    lineDash: t('ag-grid:table.lineDash'),
    lineDashOffset: t('ag-grid:table.lineDashOffset'),
    lineGroup: t('ag-grid:table.lineGroup'),
    lineTooltip: t('ag-grid:table.lineTooltip'),
    lineWidth: t('ag-grid:table.lineWidth'),
    loadingError: t('ag-grid:table.loadingError'),
    loadingOoo: t('ag-grid:table.loadingOoo'),
    march: t('ag-grid:table.march'),
    markerPadding: t('ag-grid:table.markerPadding'),
    markerSize: t('ag-grid:table.markerSize'),
    markerStroke: t('ag-grid:table.markerStroke'),
    markers: t('ag-grid:table.markers'),
    max: t('ag-grid:table.max'),
    maxSize: t('ag-grid:table.maxSize'),
    may: t('ag-grid:table.may'),
    min: t('ag-grid:table.min'),
    minSize: t('ag-grid:table.minSize'),
    miniChart: t('ag-grid:table.miniChart'),
    more: t('ag-grid:table.more'),
    navigator: t('ag-grid:table.navigator'),
    nextPage: t('ag-grid:table.nextPage'),
    nightingale: t('ag-grid:table.nightingale'),
    nightingaleTooltip: t('ag-grid:table.nightingaleTooltip'),
    noAggregation: t('ag-grid:table.noAggregation'),
    noDataToChart: t('ag-grid:table.noDataToChart'),
    noMatches: t('ag-grid:table.noMatches'),
    noPin: t('ag-grid:table.noPin'),
    noRowsToShow: t('ag-grid:table.noRowsToShow'),
    none: t('ag-grid:table.none'),
    normal: t('ag-grid:table.normal'),
    normalizedArea: t('ag-grid:table.normalizedArea'),
    normalizedAreaTooltip: t('ag-grid:table.normalizedAreaTooltip'),
    normalizedBar: t('ag-grid:table.normalizedBar'),
    normalizedBarTooltip: t('ag-grid:table.normalizedBarTooltip'),
    normalizedColumn: t('ag-grid:table.normalizedColumn'),
    normalizedColumnTooltip: t('ag-grid:table.normalizedColumnTooltip'),
    notBlank: t('ag-grid:table.notBlank'),
    notContains: t('ag-grid:table.notContains'),
    notEqual: t('ag-grid:table.notEqual'),
    november: t('ag-grid:table.november'),
    number: t('ag-grid:table.number'),
    numberFilter: t('ag-grid:table.numberFilter'),
    october: t('ag-grid:table.october'),
    of: t('ag-grid:table.of'),
    offset: t('ag-grid:table.offset'),
    offsets: t('ag-grid:table.offsets'),
    orCondition: t('ag-grid:table.orCondition'),
    orientation: t('ag-grid:table.orientation'),
    outside: t('ag-grid:table.outside'),
    padding: t('ag-grid:table.padding'),
    page: t('ag-grid:table.page'),
    pageLastRowUnknown: t('ag-grid:table.pageLastRowUnknown'),
    pageSizeSelectorLabel: t('ag-grid:table.pageSizeSelectorLabel'),
    paired: t('ag-grid:table.paired'),
    parallel: t('ag-grid:table.parallel'),
    paste: t('ag-grid:table.paste'),
    perpendicular: t('ag-grid:table.perpendicular'),
    pie: t('ag-grid:table.pie'),
    pieChart: t('ag-grid:table.pieChart'),
    pieGroup: t('ag-grid:table.pieGroup'),
    pieTooltip: t('ag-grid:table.pieTooltip'),
    pinColumn: t('ag-grid:table.pinColumn'),
    pinLeft: t('ag-grid:table.pinLeft'),
    pinRight: t('ag-grid:table.pinRight'),
    pivotChart: t('ag-grid:table.pivotChart'),
    pivotChartAndPivotMode: t('ag-grid:table.pivotChartAndPivotMode'),
    pivotChartRequiresPivotMode: t('ag-grid:table.pivotChartRequiresPivotMode'),
    pivotChartTitle: t('ag-grid:table.pivotChartTitle'),
    pivotColumnGroupTotals: t('ag-grid:table.pivotColumnGroupTotals'),
    pivotColumnsEmptyMessage: t('ag-grid:table.pivotColumnsEmptyMessage'),
    pivotMode: t('ag-grid:table.pivotMode'),
    pivots: t('ag-grid:table.pivots'),
    polarAxis: t('ag-grid:table.polarAxis'),
    polarChart: t('ag-grid:table.polarChart'),
    polarGroup: t('ag-grid:table.polarGroup'),
    polygon: t('ag-grid:table.polygon'),
    position: t('ag-grid:table.position'),
    positionRatio: t('ag-grid:table.positionRatio'),
    predefined: t('ag-grid:table.predefined'),
    preferredLength: t('ag-grid:table.preferredLength'),
    previousPage: t('ag-grid:table.previousPage'),
    radarArea: t('ag-grid:table.radarArea'),
    radarAreaTooltip: t('ag-grid:table.radarAreaTooltip'),
    radarLine: t('ag-grid:table.radarLine'),
    radarLineTooltip: t('ag-grid:table.radarLineTooltip'),
    radialBar: t('ag-grid:table.radialBar'),
    radialBarTooltip: t('ag-grid:table.radialBarTooltip'),
    radialColumn: t('ag-grid:table.radialColumn'),
    radialColumnTooltip: t('ag-grid:table.radialColumnTooltip'),
    radiusAxis: t('ag-grid:table.radiusAxis'),
    radiusAxisPosition: t('ag-grid:table.radiusAxisPosition'),
    rangeArea: t('ag-grid:table.rangeArea'),
    rangeAreaTooltip: t('ag-grid:table.rangeAreaTooltip'),
    rangeBar: t('ag-grid:table.rangeBar'),
    rangeBarTooltip: t('ag-grid:table.rangeBarTooltip'),
    rangeChartTitle: t('ag-grid:table.rangeChartTitle'),
    removeFromLabels: t('ag-grid:table.removeFromLabels'),
    removeFromValues: t('ag-grid:table.removeFromValues'),
    resetColumns: t('ag-grid:table.resetColumns'),
    resetFilter: t('ag-grid:table.resetFilter'),
    reverseDirection: t('ag-grid:table.reverseDirection'),
    right: t('ag-grid:table.right'),
    rowDragRow: t('ag-grid:table.rowDragRow'),
    rowDragRows: t('ag-grid:table.rowDragRows'),
    rowGroupColumnsEmptyMessage: t('ag-grid:table.rowGroupColumnsEmptyMessage'),
    scatter: t('ag-grid:table.scatter'),
    scatterGroup: t('ag-grid:table.scatterGroup'),
    scatterTooltip: t('ag-grid:table.scatterTooltip'),
    scrollingStep: t('ag-grid:table.scrollingStep'),
    scrollingZoom: t('ag-grid:table.scrollingZoom'),
    searchOoo: t('ag-grid:table.searchOoo'),
    secondaryAxis: t('ag-grid:table.secondaryAxis'),
    sectorLabels: t('ag-grid:table.sectorLabels'),
    selectAll: t('ag-grid:table.selectAll'),
    selectAllSearchResults: t('ag-grid:table.selectAllSearchResults'),
    selectedRows: t('ag-grid:table.selectedRows'),
    selectingZoom: t('ag-grid:table.selectingZoom'),
    september: t('ag-grid:table.september'),
    series: t('ag-grid:table.series'),
    seriesAdd: t('ag-grid:table.seriesAdd'),
    seriesChartType: t('ag-grid:table.seriesChartType'),
    seriesItemLabels: t('ag-grid:table.seriesItemLabels'),
    seriesItemNegative: t('ag-grid:table.seriesItemNegative'),
    seriesItemPositive: t('ag-grid:table.seriesItemPositive'),
    seriesItemType: t('ag-grid:table.seriesItemType'),
    seriesItems: t('ag-grid:table.seriesItems'),
    seriesLabels: t('ag-grid:table.seriesLabels'),
    seriesPadding: t('ag-grid:table.seriesPadding'),
    seriesType: t('ag-grid:table.seriesType'),
    setFilter: t('ag-grid:table.setFilter'),
    settings: t('ag-grid:table.settings'),
    shadow: t('ag-grid:table.shadow'),
    shape: t('ag-grid:table.shape'),
    size: t('ag-grid:table.size'),
    sortAscending: t('ag-grid:table.sortAscending'),
    sortDescending: t('ag-grid:table.sortDescending'),
    sortUnSort: t('ag-grid:table.sortUnSort'),
    spacing: t('ag-grid:table.spacing'),
    specializedChart: t('ag-grid:table.specializedChart'),
    specializedGroup: t('ag-grid:table.specializedGroup'),
    stackedArea: t('ag-grid:table.stackedArea'),
    stackedAreaTooltip: t('ag-grid:table.stackedAreaTooltip'),
    stackedBar: t('ag-grid:table.stackedBar'),
    stackedBarTooltip: t('ag-grid:table.stackedBarTooltip'),
    stackedColumn: t('ag-grid:table.stackedColumn'),
    stackedColumnTooltip: t('ag-grid:table.stackedColumnTooltip'),
    startAngle: t('ag-grid:table.startAngle'),
    startsWith: t('ag-grid:table.startsWith'),
    statisticalChart: t('ag-grid:table.statisticalChart'),
    statisticalGroup: t('ag-grid:table.statisticalGroup'),
    strokeColor: t('ag-grid:table.strokeColor'),
    strokeOpacity: t('ag-grid:table.strokeOpacity'),
    strokeWidth: t('ag-grid:table.strokeWidth'),
    sum: t('ag-grid:table.sum'),
    sunburst: t('ag-grid:table.sunburst'),
    sunburstTooltip: t('ag-grid:table.sunburstTooltip'),
    switchCategorySeries: t('ag-grid:table.switchCategorySeries'),
    textFilter: t('ag-grid:table.textFilter'),
    thickness: t('ag-grid:table.thickness'),
    thousandSeparator: t('ag-grid:table.thousandSeparator'),
    ticks: t('ag-grid:table.ticks'),
    tile: t('ag-grid:table.tile'),
    time: t('ag-grid:table.time'),
    timeFormat: t('ag-grid:table.timeFormat'),
    timeFormatDashesYYYYMMDD: t('ag-grid:table.timeFormatDashesYYYYMMDD'),
    timeFormatDotsDDMYY: t('ag-grid:table.timeFormatDotsDDMYY'),
    timeFormatDotsMDDYY: t('ag-grid:table.timeFormatDotsMDDYY'),
    timeFormatHHMMSS: t('ag-grid:table.timeFormatHHMMSS'),
    timeFormatHHMMSSAmPm: t('ag-grid:table.timeFormatHHMMSSAmPm'),
    timeFormatSlashesDDMMYY: t('ag-grid:table.timeFormatSlashesDDMMYY'),
    timeFormatSlashesDDMMYYYY: t('ag-grid:table.timeFormatSlashesDDMMYYYY'),
    timeFormatSlashesMMDDYY: t('ag-grid:table.timeFormatSlashesMMDDYY'),
    timeFormatSlashesMMDDYYYY: t('ag-grid:table.timeFormatSlashesMMDDYYYY'),
    timeFormatSpacesDDMMMMYYYY: t('ag-grid:table.timeFormatSpacesDDMMMMYYYY'),
    title: t('ag-grid:table.title'),
    titlePlaceholder: t('ag-grid:table.titlePlaceholder'),
    to: t('ag-grid:table.to'),
    tooltips: t('ag-grid:table.tooltips'),
    top: t('ag-grid:table.top'),
    totalAndFilteredRows: t('ag-grid:table.totalAndFilteredRows'),
    totalRows: t('ag-grid:table.totalRows'),
    treemap: t('ag-grid:table.treemap'),
    treemapTooltip: t('ag-grid:table.treemapTooltip'),
    true: t('ag-grid:table.true'),
    ungroupAll: t('ag-grid:table.ungroupAll'),
    ungroupBy: t('ag-grid:table.ungroupBy'),
    valueAggregation: t('ag-grid:table.valueAggregation'),
    valueColumnsEmptyMessage: t('ag-grid:table.valueColumnsEmptyMessage'),
    values: t('ag-grid:table.values'),
    waterfall: t('ag-grid:table.waterfall'),
    waterfallTooltip: t('ag-grid:table.waterfallTooltip'),
    whisker: t('ag-grid:table.whisker'),
    width: t('ag-grid:table.width'),
    xAxis: t('ag-grid:table.xAxis'),
    xOffset: t('ag-grid:table.xOffset'),
    xType: t('ag-grid:table.xType'),
    xyChart: t('ag-grid:table.xyChart'),
    xyValues: t('ag-grid:table.xyValues'),
    yAxis: t('ag-grid:table.yAxis'),
    yOffset: t('ag-grid:table.yOffset'),
    zoom: t('ag-grid:table.zoom'),
  };
}
