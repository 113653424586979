import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { Close } from '@/components/Dialog/AlertDialog';
import { TitleOne } from '@/components/Text/Title';
import UpgradePlan from '@/containers/Billing/UpgradePlan';
import { hideUpgradePlanModal } from '@/store/actions/billing';

const DialogTitleWrapper = styled.div`
  padding: 25px 75px;
  border-bottom: 0.4px solid ${(props) => props.theme.cssColors.dark020};
`;
const DialogBodyWrapper = styled.div`
  border-bottom: 0.4px solid ${(props) => props.theme.cssColors.dark020};
  overflow: auto;
`;

function UpgradePlanModal() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const showUpgradePlanModal = useSelector((state) => state.billing.showUpgradeModal);

  function closeUpgradePlanModal() {
    dispatch(hideUpgradePlanModal());
  }

  if (!showUpgradePlanModal) {
    return null;
  }

  return (
    <Dialog fullWidth keepMounted maxWidth="xl" open onClose={closeUpgradePlanModal}>
      <Close onClick={closeUpgradePlanModal} />
      <DialogTitleWrapper>
        <TitleOne weight="strong">{t('billing:upgrade-plan.modal-title')}</TitleOne>
      </DialogTitleWrapper>
      <DialogBodyWrapper>
        <UpgradePlan />
      </DialogBodyWrapper>
    </Dialog>
  );
}

export default UpgradePlanModal;
