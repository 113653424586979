import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import zxcvbn from 'zxcvbn';

import StrengthMeter from '@/components/Password/StrengthMeter';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import {
  DEFAULT_PASSWORD_MIN_STRENGTH,
  DEFAULT_PASSWORD_THRESHOLD_LENGTH,
} from '@/utils/constants';

const Tooltip = styled(TooltipComponent)`
  display: flex;
`;

function getMinStrength(minStrength) {
  // set default minStrength to DEFAULT_PASSWORD_MIN_STRENGTH if not a number or not specified
  // minStrength must be a a number between 0 - 4
  return typeof minStrength === 'number'
    ? Math.max(Math.min(minStrength, 4), 0)
    : DEFAULT_PASSWORD_MIN_STRENGTH;
}

function getThresholdLength(thresholdLength) {
  // set default thresholdLength to DEFAULT_PASSWORD_THRESHOLD_LENGTH if not a number or not specified
  // thresholdLength must be a minimum value of DEFAULT_PASSWORD_THRESHOLD_LENGTH

  return typeof thresholdLength === 'number'
    ? Math.max(thresholdLength, DEFAULT_PASSWORD_THRESHOLD_LENGTH)
    : DEFAULT_PASSWORD_THRESHOLD_LENGTH;
}

function PasswordField(props) {
  const { t } = useTranslation();
  const [state, setState] = useState({ password: '', strength: 0 });
  const [showPassword, setShowPassword] = useState(false);

  const label = props.label || t('login:password-field.placeholder');
  const placeholder = props.placeholder || t('login:password-field.placeholder');

  const minStrength = getMinStrength(props.minStrength);
  const thresholdLength = getThresholdLength(props.thresholdLength);

  function onChange(e) {
    const value = e.target.value;
    // update the internal state using the updated state from the form field
    const newState = {
      password: value,
      strength: zxcvbn(value).score,
    };

    setState(newState);
    props.onPasswordChanged(newState);
  }

  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }

  function handleMouseDownPassword(event) {
    event.preventDefault();
  }

  function validatePasswordStrong(value) {
    // ensure password is long enough
    if (value.length < thresholdLength) {
      return t('login:password-field.error-short-password');
    }

    // ensure password is strong enough using the zxcvbn library
    if (zxcvbn(value).score < minStrength) {
      return t('login:password-field.error-weak');
    }

    return '';
  }

  const error = state.password.length > 0 ? validatePasswordStrong(state.password) : '';

  useEffect(() => {
    props?.onErrorStatusChange?.(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const labelVisibilityPassword = t(
    showPassword
      ? 'login:password-field.label-hide-password'
      : 'login:password-field.label-show-password'
  );

  return (
    <div>
      <FormControl fullWidth>
        <InputLabel htmlFor="password-field">{label}</InputLabel>
        <Input
          aria-describedby="password-helper-text"
          autoComplete="new-password"
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label={labelVisibilityPassword}
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                <Tooltip title={labelVisibilityPassword}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </Tooltip>
              </IconButton>
            </InputAdornment>
          }
          error={!!error || props.error}
          fullWidth
          id="password-field"
          label={label}
          name={props.name}
          placeholder={placeholder}
          required
          type={showPassword ? 'text' : 'password'}
          value={state.password}
          onChange={onChange}
        />
        {error && <FormHelperText id="password-helper-text">{error}</FormHelperText>}
      </FormControl>

      <StrengthMeter isVisible={state.password.length > 0} strength={state.strength} />
    </div>
  );
}

PasswordField.propTypes = {
  label: PropTypes.string,
  minStrength: PropTypes.number,
  name: PropTypes.string,
  onPasswordChanged: PropTypes.func,
  placeholder: PropTypes.string,
  thresholdLength: PropTypes.number,
};

PasswordField.defaultProps = {
  minStrength: DEFAULT_PASSWORD_MIN_STRENGTH,
  name: 'password',
  onErrorStatusChange: () => {},
  onPasswordChanged: (f) => f,
  thresholdLength: DEFAULT_PASSWORD_THRESHOLD_LENGTH,
};

export default PasswordField;
