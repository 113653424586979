import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { OutlinedButton } from '@/components/Button/Button';
import ArrowDown from '@/components/icons/ArrowDown';
import ThreeDotsIcon from '@/components/icons/ThreeDotsIcon';

const TopBarWrapper = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 180px 1fr 180px;
  grid-template-rows: auto;
  align-items: center;
  justify-content: center;
  border-bottom: 0.5px solid ${({ theme }) => theme.cssColors.dark020};
  padding: 0.5rem 46px;
  padding-right: ${({ isSidePanelOpen }) => isSidePanelOpen && '15px'};
  grid-template-areas: 'left main right';

  && {
    ${(props) => props.theme.mediaQueries.phone},
    ${(props) => props.theme.mediaQueries.tablet} {
      grid-template-columns: 0 1fr 180px;
    }
  }
`;

const TopBarMain = styled.div`
  grid-area: main;
  display: flex;
  align-items: center;
  justify-content: center;

  color: ${({ theme }) => theme.cssColors.dark100};
  font-size: ${({ theme }) => theme.textCss.sizes.lg};
  font-weight: ${({ theme }) => theme.textCss.weights.strong};
`;
const TopBarActionsRight = styled.div`
  grid-area: right;
`;

const DownloadButton = styled(OutlinedButton)`
  && {
    .icon {
      margin-right: 5px;
    }
  }
`;

function TopBar({
  children,
  isBriefPdfDownloadable,
  isBriefPdfDownloading,
  handleBriefExportPdfClick,
}) {
  const { t } = useTranslation();

  return (
    <TopBarWrapper>
      <TopBarMain>{children}</TopBarMain>
      <TopBarActionsRight>
        {isBriefPdfDownloadable && (
          <DownloadButton onClick={handleBriefExportPdfClick}>
            {isBriefPdfDownloading ? (
              <ThreeDotsIcon className="icon" />
            ) : (
              <>
                <ArrowDown className="icon" />
                &nbsp;{t('content:brief.topbar-download-pdf')}
              </>
            )}
          </DownloadButton>
        )}
      </TopBarActionsRight>
    </TopBarWrapper>
  );
}

export default TopBar;
