import styled from 'styled-components/macro';

import UserIcon from '@/components/icons/UserIcon';

const UnfilledUserIcon = styled(UserIcon)`
  && {
    fill: none;
    stroke: black;
  }
`;

export default UnfilledUserIcon;
