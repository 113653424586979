export const ENV_DEV = 'development';
export const ENV_STAGING = 'staging';
export const ENV_NEXT = 'next';
export const ENV_DEMO = 'demo';
export const ENV_PRODUCTION = 'production';

// To make it easier to locate the errors, we will be using the name of containers
// as the main section
export const SECTIONS = {
  application: {
    key: 'application',
    label: 'application',
  },
  authentication: {
    key: 'authentication',
    label: 'authentication',
  },
  chart: {
    key: 'chart',
    label: 'charts',
  },
  content: {
    key: 'content',
    label: 'content',
  },
  dashboard: {
    key: 'dashboard',
    label: 'dashboard',
  },
  default: {
    key: 'default',
    label: 'N/A',
  },
  draft: {
    key: 'draft',
    label: 'draft',
  },
  layout: {
    key: 'layout',
    label: 'layout',
  },
  listing: {
    key: 'listing',
    label: 'listing',
  },
  login: {
    key: 'login',
    label: 'login',
  },
  onboarding: {
    key: 'onboarding',
    label: 'Onboarding',
  },
  organization: {
    key: 'organization',
    label: 'organization',
  },
  pages: {
    key: 'pages',
    label: 'pages',
  },
  report: {
    key: 'report',
    label: 'report',
  },
  serviceIntegrations: {
    key: 'serviceIntegrations',
    label: 'serviceIntegrations',
  },
  AI: {
    key: 'AI Stream',
    label: 'AI Stream',
  },
  settings: {
    key: 'settings',
    label: 'settings',
  },
  workspace: {
    key: 'workspace',
    label: 'workspace',
  },
};

export const PROXY_HOSTS = {
  Demo: 'api-demo2.semji.com:443',
  Next: 'api-next.internal.semji.io:443',
  Production: 'api.semji.com:443',
  Staging: 'api-sg.internal.semji.io:443',
};
