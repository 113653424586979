import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { Instruction } from '@/components/Optimization/Instruction';
import { LightText } from '@/components/Text/Light';
import {
  disableRecommendationOptimization,
  enableRecommendationOptimization,
} from '@/store/actionsCreator/report';

const IndicatorWrapText = styled(LightText)`
  && {
    white-space: nowrap;
    width: 75px;
    text-align: right;
  }
`;

function Article({ articleWordsCount, focusTopKeywordId, id, mainId, onToggleArticle = () => {} }) {
  const dispatch = useDispatch();
  const article = useSelector((state) => state.report.recommendations[id]);
  const html = useSelector((state) => state.content.html);
  const title = useSelector((state) => state.content.title);

  function toggleArticle() {
    if (!article.disabled) {
      dispatch(disableRecommendationOptimization(id, mainId, focusTopKeywordId, html, title));
    } else {
      dispatch(enableRecommendationOptimization(id, mainId, focusTopKeywordId, html, title));
    }

    onToggleArticle(id, !article.disabled);
  }

  const pluralMark = article.data.wordCount > 1 ? 's' : '';
  const scorer = article.data.wordCount ? `${article.data.wordCount} word${pluralMark}` : '-';
  const score = article.data.wordCount ? articleWordsCount / article.data.wordCount : 0;

  return (
    <Instruction
      completed={1 <= score}
      disabled={article.disabled}
      isConsideredSameAsPage={article.data.isConsideredSameAsPage}
      position={article.data.position}
      scorer={<IndicatorWrapText size={'micro'}>{scorer}</IndicatorWrapText>}
      url={article.data.url}
      onToggle={toggleArticle}
    />
  );
}

export default Article;
