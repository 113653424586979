import { nanoid } from 'nanoid';
import React, { useMemo } from 'react';

import SvgWrapper from './SvgWrapper';

function PlusIcon({ ...props }) {
  const maskId = useMemo(() => nanoid(), []);

  return (
    <SvgWrapper {...props} fill="none" height="14" viewBox="0 0 14 14" width="14">
      <mask
        height="12"
        id={`mask${maskId}`}
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="12"
        x="1"
        y="1"
      >
        <path
          d="M12.4521 7.36362H7.36115V12.4545H6.63388V7.36362H1.54297V6.63635H6.63388V1.54544H7.36115V6.63635H12.4521V7.36362Z"
          fill="currentColor"
        />
      </mask>
      <g mask={`url(#mask${maskId})`}>
        <rect fill="currentColor" height="14" width="14" x="-0.636719" y="0.0909119" />
      </g>
    </SvgWrapper>
  );
}

export default PlusIcon;
