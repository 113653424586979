import { nanoid } from 'nanoid';
import React, { useMemo } from 'react';

import SvgWrapper from '@/components/icons/SvgWrapper';

const EmptyShoppingCart = (props) => {
  const svgId = useMemo(() => nanoid(), []);

  return (
    <SvgWrapper fill="none" height="16" viewBox="0 0 15 16" width="15" {...props}>
      <g clipPath={`url(#clip0_${svgId})`}>
        <mask
          height="14"
          id={`mask0_${svgId}`}
          maskUnits="userSpaceOnUse"
          style={{ maskType: 'alpha' }}
          width="16"
          x="-1"
          y="1"
        >
          <path
            d="M2.42536 11.129C1.57389 11.129 0.881241 11.8216 0.881241 12.6731C0.881241 13.5246 1.57389 14.2172 2.42536 14.2172C3.27683 14.2172 3.96948 13.5246 3.96948 12.6731C3.96948 11.8216 3.27683 11.129 2.42536 11.129ZM2.42536 13.3348C2.06006 13.3348 1.76359 13.0375 1.76359 12.6731C1.76359 12.3087 2.06006 12.0113 2.42536 12.0113C2.79065 12.0113 3.08712 12.3087 3.08712 12.6731C3.08712 13.0375 2.79065 13.3348 2.42536 13.3348ZM9.92536 11.129C9.07389 11.129 8.38124 11.8216 8.38124 12.6731C8.38124 13.5246 9.07389 14.2172 9.92536 14.2172C10.7768 14.2172 11.4695 13.5246 11.4695 12.6731C11.4695 11.8216 10.7768 11.129 9.92536 11.129ZM9.92536 13.3348C9.56007 13.3348 9.26359 13.0375 9.26359 12.6731C9.26359 12.3087 9.56007 12.0113 9.92536 12.0113C10.2907 12.0113 10.5871 12.3087 10.5871 12.6731C10.5871 13.0375 10.2907 13.3348 9.92536 13.3348ZM11.7968 1.86426L11.5304 3.62896H-0.0664062L0.920064 10.7196H11.4315L12.3474 4.13455L12.5557 2.74661H14.9989V1.86426H11.7968ZM11.4042 4.51132L11.2815 5.39367H1.06918L0.946535 4.51132H11.4042ZM1.68771 9.8372L1.19271 6.27602H11.1589L10.6639 9.8372H1.68771Z"
            fill="black"
          />
        </mask>
        <g mask={`url(#mask0_${svgId})`}>
          <rect fill="#7C7D86" height="17.6471" width="17.6471" x="-1.76562" y="-0.783203" />
        </g>
      </g>
      <defs>
        <clipPath id={`clip0_${svgId}`}>
          <rect fill="white" height="15" transform="translate(0 0.0996094)" width="15" />
        </clipPath>
      </defs>
    </SvgWrapper>
  );
};

export default EmptyShoppingCart;
