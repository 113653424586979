import './Configuration.scss';

import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert } from 'semji-core/components/Alert';
import { Button, ENUM_BUTTON_VARIANTS } from 'semji-core/components/Button';
import { FloatLoader } from 'semji-core/components/FloatLoader';
import { Checkbox } from 'semji-core/components/Input/Checkbox';
import { LimitModal } from 'semji-core/components/LimitModal';
import { Switch } from 'semji-core/components/Switch';
import { Textarea } from 'semji-core/components/Textarea';
import { HelpIcon } from 'semji-core/icons/HelpIcon';
import { PlusIcon } from 'semji-core/icons/PlusIcon';
import { getRoundedEvolutionMetricObject } from 'semji-core/utils/numbers';

import {
  useGetCompetitorConfigurationWebsites,
  useGetCompetitorReports,
  useGetCompetitorSuggestions,
  usePostCompetitorConfiguration,
  usePostCompetitorReport,
  usePostCompetitorWebsiteInfo,
} from '@/apis/semji/competitors';
import useGetCompetitorConfigurations from '@/apis/semji/competitors/useGetCompetitorConfigurations';
import UnlockImage from '@/assets/images/unlock-image.svg';
import WorkspaceDomain from '@/containers/Competitors/Configuration/WorkspaceDomain/WorkspaceDomain';
import { getFirstDayOfTheLastCompletedMonth } from '@/containers/Competitors/utils/utils';
import withErrorBoundary from '@/hoc/withErrorBoundary';
import useCan from '@/hooks/useCan';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import { showSuccessSnackbar } from '@/store/actions/ui';
import { selectCurrentWorkspaceWebsiteUrl } from '@/store/selectors/selectCurrentWorkspaceWebsiteUrl';
import { selectHasAccessToCompetitorsAdmin } from '@/store/selectors/selectHasAccessToCompetitorsAdmin';
import { selectIsSuperAdminUser } from '@/store/selectors/selectIsSuperAdminUser';
import {
  CompetitorPeriodicity,
  CompetitorReport,
  CompetitorWebsiteUrlType,
  ENUM_COMPETITOR_REPORT_STATUS,
  URL_TYPE_DOMAIN,
  UrlType,
} from '@/types/competitors';
import { SUPPORT_ADDRESS_MAIL } from '@/utils/constants';
import { getHelpCenterUrl } from '@/utils/helper';
import { formatUrl, formatUrlForDisplay, hasGoodUrlPattern } from '@/utils/url';

import { Competitor, CURRENT_NB_PAGES, PREVIOUS_NB_PAGES } from './Configuration.types';
import EmptyState from './EmptyState';
import Listing from './Listing';
import { ENUM_COMPETITOR_CHANGE_KEY } from './Listing/Listing.types';

function Configure() {
  const DEFAULT_DISPLAYED_COMPETITORS = 5;
  const NEW_COMPETITOR_ID_PREFIX = 'new_competitor_';
  const currentYear = new Date().getFullYear();

  const isCompetitorAdmin = useSelector(selectHasAccessToCompetitorsAdmin);
  const { organizationId, workspaceId } = useParams();
  const dispatch = useDispatch();
  const { data: maxCompetitors = 14 } = useOrganizationFeatureSet(
    'competitors:report:websites-allowance'
  );
  const { data: maxCompetitorsTotalUrls = 10000 } = useOrganizationFeatureSet(
    'competitors:report:top-pages-allowance'
  );
  const navigate = useNavigate();

  const currentWorkspaceWebsiteUrl = useSelector(selectCurrentWorkspaceWebsiteUrl);

  const { data: competitorSuggestions } = useGetCompetitorSuggestions({});
  const {
    data: competitorConfigurations = [],
    isFetched: isCompetitorConfigurationFetched,
    isLoading: isCompetitorConfigurationLoading,
    isPlaceholderData: isPlaceholderDataCompetitorConfiguration,
    refetch: refetchConfigurations,
  } = useGetCompetitorConfigurations({});
  const activeConfiguration =
    competitorConfigurations.length > 0 ? competitorConfigurations[0] : null;

  const { data: competitorReports = [], isFetched: isCompetitorReportsFetched } =
    useGetCompetitorReports({});
  const getCompetitorWebsites = useGetCompetitorConfigurationWebsites({
    competitorConfigurationId: String(activeConfiguration?.id),
    enabled: !!activeConfiguration?.id,
  });
  const canDisplayCompetitorWebsiteNumberOfPages = useCan({
    perform: 'can-display-competitor-website-number-of-pages',
  });

  const { t, i18n } = useTranslation();
  const [isOpenLimitModal, setIsOpenLimitModal] = useState(false);
  const [competitorReference, setCompetitorReference] = useState<{
    [CURRENT_NB_PAGES]: number | null;
    [PREVIOUS_NB_PAGES]: number | null;
    url: string;
    urlType: CompetitorWebsiteUrlType;
  }>({
    [CURRENT_NB_PAGES]: null,
    [PREVIOUS_NB_PAGES]: null,
    url: `${currentWorkspaceWebsiteUrl}/`,
    urlType: CompetitorWebsiteUrlType.SUBDOMAIN,
  });
  const [competitors, setCompetitors] = useState<Competitor[]>(
    Array(DEFAULT_DISPLAYED_COMPETITORS).fill({ nbPages: null, url: '', urlType: URL_TYPE_DOMAIN })
  );
  const [isWebsiteInfoLoading, setIsWebsiteInfoLoading] = useState(false);
  const [funnelPrompt, setFunnelPrompt] = useState('');
  const [clusterPrompt, setClusterPrompt] = useState('');
  const [personaPrompt, setPersonaPrompt] = useState('');
  const [isAutoUpdate, setIsAutoUpdate] = useState(false);
  const [isClusterClassificationEnabled, setIsClusterClassificationEnabled] = useState(false);
  const [isTrafficNormalized, setIsTrafficNormalizedEnabled] = useState(true);
  const [isReplicatingClusterClassification, setIsReplicatingClusterClassification] =
    useState(false);
  const [isFunnelClassificationEnabled, setIsFunnelClassificationEnabled] = useState(false);
  const [isReplicatingFunnelClassification, setIsReplicatingFunnelClassification] = useState(false);
  const [isPersonaClassificationEnabled, setIsPersonaClassificationEnabled] = useState(false);
  const [isReplicatingPersonaClassification, setIsReplicatingPersonaClassification] =
    useState(false);
  const competitorReportMutation = usePostCompetitorReport({
    onSuccess: (data) => {
      // Todo: remove when new report will be automatically set as pending
      dispatch(showSuccessSnackbar(t('competitors:configuration.success.compute-pending')));

      navigate(`/o/${organizationId}/w/${workspaceId}/search-intelligence/${data.id}/analyzing`);
    },
  });
  const competitorConfigurationMutation = usePostCompetitorConfiguration({
    onSuccess: () => {
      refetchConfigurations();
      dispatch(showSuccessSnackbar(t('competitors:configuration.success.configuration-saved')));
    },
  });
  const competitorWebsiteInfoMutation = usePostCompetitorWebsiteInfo({
    onSuccess: ({ url, nbPages, urlType, retrievalDate }) => {
      const keyNbPage =
        DateTime.fromISO(retrievalDate).year === currentYear ? CURRENT_NB_PAGES : PREVIOUS_NB_PAGES;
      if (url === competitorReference.url && urlType === competitorReference.urlType) {
        setCompetitorReference((prev) => ({
          ...prev,
          [keyNbPage]: nbPages,
        }));
      } else {
        setCompetitors((prevCompetitors) =>
          prevCompetitors.map((competitor) =>
            competitor.url === url && competitor.urlType === urlType
              ? {
                  ...competitor,
                  [keyNbPage]: nbPages,
                }
              : competitor
          )
        );
      }
    },
  });

  const isLoading =
    isCompetitorConfigurationLoading ||
    isPlaceholderDataCompetitorConfiguration ||
    (!!activeConfiguration?.id &&
      (getCompetitorWebsites.isLoading || getCompetitorWebsites.isPlaceholderData));
  const competitorsCount = competitors.filter((competitor) => competitor.url).length;
  const noValidCompetitor =
    isLoading || competitorsCount === 0 || competitors.some((competitor) => competitor.error);
  const totalCurrentNbPages =
    competitors.reduce((acc, currentValue) => acc + (currentValue[CURRENT_NB_PAGES] ?? 0), 0) +
    (competitorReference[CURRENT_NB_PAGES] ?? 0);
  const totalPreviousNbPages =
    competitors.reduce((acc, currentValue) => acc + (currentValue[PREVIOUS_NB_PAGES] ?? 0), 0) +
    (competitorReference[PREVIOUS_NB_PAGES] ?? 0);
  const evolution = getRoundedEvolutionMetricObject({
    locale: i18n.language,
    number: ((totalCurrentNbPages - totalPreviousNbPages) / totalPreviousNbPages) * 100,
  });

  const isSaveActionDisabled =
    !isCompetitorAdmin || competitorConfigurationMutation.isLoading || noValidCompetitor;

  const isCompareActionDisabled =
    !isCompetitorAdmin ||
    competitorConfigurations.length === 0 ||
    competitorReportMutation.isLoading;

  useEffect(() => {
    const pendingCompetitorReport = competitorReports?.find(
      (report: CompetitorReport) => report.status === ENUM_COMPETITOR_REPORT_STATUS.PENDING
    );
    const activeReport = competitorReports?.find(
      (report: CompetitorReport) => report.status === ENUM_COMPETITOR_REPORT_STATUS.CURRENT_ACTIVE
    );
    if (!activeReport && !!pendingCompetitorReport && isCompetitorReportsFetched) {
      // redirect to the analyzing screen if there is a pending report and no active report
      navigate(
        `/o/${organizationId}/w/${workspaceId}/search-intelligence/${pendingCompetitorReport.id}/analyzing`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCompetitorReportsFetched, workspaceId]);

  useEffect(() => {
    if (competitorConfigurations.length > 0) {
      const activeConfiguration = competitorConfigurations[0];
      setPersonaPrompt(activeConfiguration.personaPrompt ?? '');
      setClusterPrompt(activeConfiguration.clusterPrompt ?? '');
      setFunnelPrompt(activeConfiguration.funnelPrompt ?? '');
      setIsAutoUpdate(activeConfiguration.isAutoUpdate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCompetitorConfigurationFetched, workspaceId]);

  useEffect(() => {
    if (getCompetitorWebsites.data && getCompetitorWebsites.data.length > 0) {
      const competitorWebsites = [...getCompetitorWebsites.data];
      setCompetitors([
        ...competitorWebsites.filter((competitor) => !competitor.isSelfReference),
        {
          id: NEW_COMPETITOR_ID_PREFIX + (competitorWebsites.length + 1),
          nbPages: null,
          url: '',
          urlType: URL_TYPE_DOMAIN,
        },
      ]);
    }
  }, [getCompetitorWebsites.isFetched, workspaceId]);

  useEffect(() => {
    setCompetitorReference({
      [CURRENT_NB_PAGES]: null,
      [PREVIOUS_NB_PAGES]: null,
      url: `${currentWorkspaceWebsiteUrl}/`,
      urlType: CompetitorWebsiteUrlType.SUBDOMAIN,
    });
  }, [currentWorkspaceWebsiteUrl, workspaceId]);

  function handleAddElement() {
    if (competitors.length < maxCompetitors) {
      setCompetitors([
        ...competitors,
        {
          id: NEW_COMPETITOR_ID_PREFIX + (competitors.length + 1),
          nbPages: null,
          url: '',
          urlType: URL_TYPE_DOMAIN,
        },
      ]);
    } else {
      if (!isOpenLimitModal) {
        setIsOpenLimitModal(true);
      }
    }
  }

  useEffect(() => {
    if (
      competitors.length > 5 &&
      competitors[competitors.length - 1].id.includes(NEW_COMPETITOR_ID_PREFIX)
    ) {
      const element = document.getElementById(competitors[competitors.length - 1].id);
      element?.scrollIntoView({ block: 'nearest' });
    }
  }, [competitors]);

  function handleRemoveElement(competitorIndex: number) {
    if (competitors.length > 1) {
      setCompetitors((prevCompetitors) =>
        prevCompetitors.filter((competitor, index) => competitorIndex !== index)
      );
    }
  }

  function handleChangeElement(key: ENUM_COMPETITOR_CHANGE_KEY, validate = false) {
    return (value: string, competitorIndex: number) => {
      const getErrorValue = (url: string, urlType: UrlType) => {
        if (url && !hasGoodUrlPattern(url)) {
          return t('competitors:configuration.error.invalid-url');
        }
        if (
          formatUrlForDisplay(url) === formatUrlForDisplay(currentWorkspaceWebsiteUrl) &&
          urlType === URL_TYPE_DOMAIN
        ) {
          return t('competitors:configuration.error.reserved-domain');
        }
        return null;
      };

      const newCompetitors = [
        ...competitors.map((competitor, index) =>
          index === competitorIndex
            ? {
                ...competitor,
                ...(validate
                  ? {
                      error: getErrorValue(
                        key === ENUM_COMPETITOR_CHANGE_KEY.Url
                          ? value
                          : competitors[competitorIndex].url,
                        key === ENUM_COMPETITOR_CHANGE_KEY.UrlType
                          ? (value as UrlType)
                          : competitors[competitorIndex].urlType
                      ),
                    }
                  : {}),
                [key]: value,
              }
            : competitor
        ),
      ];
      if (!newCompetitors.some((item) => !item.url) && newCompetitors.length < maxCompetitors) {
        newCompetitors.push({
          id: NEW_COMPETITOR_ID_PREFIX + (competitors.length + 1),
          nbPages: null,
          url: '',
          urlType: URL_TYPE_DOMAIN,
        });
      }
      setCompetitors(newCompetitors);
    };
  }

  function handleSaveConfiguration() {
    if (!isSaveActionDisabled) {
      competitorConfigurationMutation.mutate({
        clusterPrompt: clusterPrompt === '' ? null : clusterPrompt,
        competitorWebsites: cleanCompetitors(competitors),
        funnelPrompt: funnelPrompt === '' ? null : funnelPrompt,
        isAutoUpdate,
        periodicity: CompetitorPeriodicity.Monthly,
        personaPrompt: personaPrompt === '' ? null : personaPrompt,
      });
    }
  }

  function handleComputeReport() {
    if (!isCompareActionDisabled) {
      competitorReportMutation.mutate({
        isClusterClassificationEnabled,
        isFunnelClassificationEnabled,
        isPersonaClassificationEnabled,
        isReplicatingExistingClusterClassification: isReplicatingClusterClassification,
        isReplicatingExistingFunnelClassification: isReplicatingFunnelClassification,
        isReplicatingExistingPersonaClassification: isReplicatingPersonaClassification,
        isTrafficNormalized,
      });
    }
  }

  function handleCalculatePagesNumber() {
    setIsWebsiteInfoLoading(true);
    const currentDate = getFirstDayOfTheLastCompletedMonth();
    const mutations = [...cleanCompetitors(competitors), competitorReference].map((competitor) =>
      competitorWebsiteInfoMutation.mutateAsync({
        retrievalDate: currentDate.toISO() ?? '',
        url: competitor.url,
        urlType: competitor.urlType,
      })
    );
    const datePreviousYear = currentDate.minus({ years: 1 });
    const mutationsPreviousYear = [...cleanCompetitors(competitors), competitorReference].map(
      (competitor) =>
        competitorWebsiteInfoMutation.mutateAsync({
          retrievalDate: datePreviousYear.toISO() ?? '',
          url: competitor.url,
          urlType: competitor.urlType,
        })
    );

    Promise.all([...mutations, ...mutationsPreviousYear]).finally(() => {
      setIsWebsiteInfoLoading(false);
    });
  }

  function handleCompetitorReferenceChange(value: string) {
    setCompetitorReference((prev) => ({
      ...prev,
      url: `${currentWorkspaceWebsiteUrl}/${value}`,
      urlType:
        value !== currentWorkspaceWebsiteUrl
          ? CompetitorWebsiteUrlType.SUBFOLDER
          : CompetitorWebsiteUrlType.SUBDOMAIN,
    }));
  }

  function handleChange(setState: Function) {
    return (e: React.ChangeEvent<HTMLTextAreaElement>) => setState(e.target.value);
  }

  function handleToggle(setState: Function) {
    return () => setState((prev: any) => !prev);
  }

  function cleanCompetitors(reportCompetitors: Competitor[]) {
    return reportCompetitors
      .filter((competitor) => competitor.url !== '')
      .map((competitor) => ({
        url: competitor.url,
        urlType: competitor.urlType,
      }));
  }

  return (
    <>
      <div className="competitors-configuration-screen">
        <div className="competitors-configuration-screen__top-bar">
          <a
            className="competitors-configuration-screen__top-bar__help-button"
            href={getHelpCenterUrl()}
            rel="noreferrer"
            target="_blank"
          >
            <HelpIcon /> {t('competitors:configuration.help')}
          </a>
        </div>
        <div className="competitors-configuration-screen__container">
          <div className="competitors-configuration-screen__header">
            <h1 className="competitors-configuration-screen__title">
              {t('competitors:configuration.search-intelligence')}
            </h1>
            <p className="competitors-configuration-screen__desc">
              {t('competitors:configuration.desc')}
            </p>
          </div>
          <WorkspaceDomain
            currentNbPages={competitorReference[CURRENT_NB_PAGES]}
            isCalculatingPagesNumber={isWebsiteInfoLoading}
            prefixUrl={currentWorkspaceWebsiteUrl}
            previousNbPages={competitorReference[PREVIOUS_NB_PAGES]}
            value={competitorReference.url.replace(`${currentWorkspaceWebsiteUrl}/`, '')}
            onChange={handleCompetitorReferenceChange}
          />
          <div className="competitors-configuration-screen__card">
            <div className="competitors-configuration-screen__card-heading">
              <span className="competitors-configuration-screen__card-title">
                {t('competitors:configuration.competitors')}
              </span>
              <span className="competitors-configuration-screen__card-counter">
                {competitorsCount}/{maxCompetitors}
              </span>
            </div>
            <div className="competitors-configuration-screen__card-body">
              {competitors.length === maxCompetitors && (
                <Alert
                  className="competitors-configuration-screen__card-body__alert"
                  variant="primary"
                >
                  <Trans
                    components={{
                      a: <a href={`mailto:${SUPPORT_ADDRESS_MAIL}`} />,
                      strong: <strong />,
                    }}
                    i18nKey="competitors:configuration.alert-limit-competitors"
                    values={{ maxCompetitors }}
                  />
                </Alert>
              )}
              {Math.max(totalCurrentNbPages, totalPreviousNbPages) > maxCompetitorsTotalUrls && (
                <Alert
                  className="competitors-configuration-screen__card-body__alert"
                  variant="primary"
                >
                  <Trans
                    components={{
                      a: <a href={`mailto:${SUPPORT_ADDRESS_MAIL}`} />,
                      strong: <strong />,
                    }}
                    i18nKey="competitors:configuration.alert-limit-urls"
                    values={{ maxTotalUrls: maxCompetitorsTotalUrls }}
                  />
                </Alert>
              )}
              {isLoading ? (
                <EmptyState
                  canDisplayPageNumber={canDisplayCompetitorWebsiteNumberOfPages}
                  defaultRows={DEFAULT_DISPLAYED_COMPETITORS}
                />
              ) : (
                <Listing
                  competitors={competitors}
                  competitorSuggestions={
                    competitorSuggestions?.map((competitorSuggestion) => ({
                      ...competitorSuggestion,
                      url: formatUrl(competitorSuggestion.url, true),
                    })) ?? []
                  }
                  handleChangeElement={handleChangeElement}
                  handleRemoveElement={handleRemoveElement}
                  isCalculatingPagesNumber={isWebsiteInfoLoading}
                  shouldFocusIndexZero={false}
                />
              )}
              <div className="competitors-configuration-screen__card-actions">
                <button
                  className={`competitors-configuration-screen__card-button ${
                    competitors.length === maxCompetitors &&
                    'competitors-configuration-screen__card-button--disabled'
                  }`}
                  onClick={handleAddElement}
                >
                  <PlusIcon className="text-primary" />{' '}
                  {t('competitors:configuration.add-competitor')}
                </button>
              </div>
            </div>
            {canDisplayCompetitorWebsiteNumberOfPages && (
              <div className="competitors-configuration-screen__card-footer">
                <div className="competitors-configuration-screen__card-footer__total">
                  {isWebsiteInfoLoading ? (
                    <FloatLoader />
                  ) : (
                    <>
                      {t('competitors:configuration.total-pages', {
                        value: totalPreviousNbPages ?? '-',
                      })}
                      {evolution.value &&
                        ` (${evolution.value >= 0 ? '+' : ''}${evolution.value} ${evolution.suffix}%)`}
                    </>
                  )}
                </div>
                <Button
                  disabled={isWebsiteInfoLoading || noValidCompetitor}
                  variant={ENUM_BUTTON_VARIANTS.GradientPrimary}
                  onClick={handleCalculatePagesNumber}
                >
                  {t('competitors:configuration.calculatePagesNumber')}
                </Button>
              </div>
            )}

            <div className="competitors-configuration-screen__card-body">
              <div className="competitors-configuration-screen__card__row">
                <Switch
                  checked={isAutoUpdate}
                  variant="small"
                  onChange={handleToggle(setIsAutoUpdate)}
                />
                <label
                  className="competitors-configuration-screen__card__row__label"
                  onClick={handleToggle(setIsAutoUpdate)}
                >
                  {t('competitors:configuration.auto-update')}
                </label>
              </div>
              <Textarea
                label={t('competitors:configuration.prompt-funnel')}
                required={false}
                rows={10}
                value={funnelPrompt}
                onChange={handleChange(setFunnelPrompt)}
              />

              <Textarea
                label={t('competitors:configuration.prompt-cluster')}
                required={false}
                rows={10}
                value={clusterPrompt}
                onChange={handleChange(setClusterPrompt)}
              />
              <Textarea
                label={t('competitors:configuration.prompt-persona')}
                required={false}
                rows={10}
                value={personaPrompt}
                onChange={handleChange(setPersonaPrompt)}
              />
            </div>

            <div className="competitors-configuration-screen__card-footer">
              <Button
                disabled={isSaveActionDisabled}
                variant={ENUM_BUTTON_VARIANTS.GradientPrimary}
                onClick={handleSaveConfiguration}
              >
                {t('competitors:configuration.save-configuration')}
              </Button>
            </div>
          </div>

          <div className="competitors-configuration-screen__card">
            <div className="competitors-configuration-screen__card-heading">
              <span className="competitors-configuration-screen__card-title">
                {t('competitors:configuration.report-settings')}
              </span>
            </div>
            <div className="competitors-configuration-screen__card-body">
              <div className="competitors-configuration-screen__card__row">
                <Checkbox
                  checked={isFunnelClassificationEnabled}
                  id="isFunnelClassificationEnabled"
                  onChange={handleToggle(setIsFunnelClassificationEnabled)}
                />
                <label
                  className="competitors-configuration-screen__card__row__label"
                  htmlFor="isFunnelClassificationEnabled"
                >
                  {t('competitors:configuration.is-funnel-classification-enabled')}
                </label>
              </div>
              <div className="competitors-configuration-screen__card__row">
                <Checkbox
                  checked={isReplicatingFunnelClassification}
                  id="isReplicatingFunnelClassification"
                  onChange={handleToggle(setIsReplicatingFunnelClassification)}
                />
                <label
                  className="competitors-configuration-screen__card__row__label"
                  htmlFor="isReplicatingFunnelClassification"
                >
                  {t('competitors:configuration.is-replicating-funnel-classification')}
                </label>
              </div>
              <div className="competitors-configuration-screen__card__row">
                <Checkbox
                  checked={isPersonaClassificationEnabled}
                  id="isPersonaClassificationEnabled"
                  onChange={handleToggle(setIsPersonaClassificationEnabled)}
                />
                <label
                  className="competitors-configuration-screen__card__row__label"
                  htmlFor="isPersonaClassificationEnabled"
                >
                  {t('competitors:configuration.is-persona-classification-enabled')}
                </label>
              </div>
              <div className="competitors-configuration-screen__card__row">
                <Checkbox
                  checked={isReplicatingPersonaClassification}
                  id="isReplicatingPersonaClassification"
                  onChange={handleToggle(setIsReplicatingPersonaClassification)}
                />
                <label
                  className="competitors-configuration-screen__card__row__label"
                  htmlFor="isReplicatingPersonaClassification"
                >
                  {t('competitors:configuration.is-replicating-persona-classification')}
                </label>
              </div>
              <div className="competitors-configuration-screen__card__row">
                <Checkbox
                  checked={isClusterClassificationEnabled}
                  id="isClusterClassificationEnabled"
                  onChange={handleToggle(setIsClusterClassificationEnabled)}
                />
                <label
                  className="competitors-configuration-screen__card__row__label"
                  htmlFor="isClusterClassificationEnabled"
                >
                  {t('competitors:configuration.is-cluster-classification-enabled')}
                </label>
              </div>
              <div className="competitors-configuration-screen__card__row">
                <Checkbox
                  checked={isReplicatingClusterClassification}
                  id="isReplicatingClusterClassification"
                  onChange={handleToggle(setIsReplicatingClusterClassification)}
                />
                <label
                  className="competitors-configuration-screen__card__row__label"
                  htmlFor="isReplicatingClusterClassification"
                >
                  {t('competitors:configuration.is-replicating-cluster-classification')}
                </label>
              </div>
              <div className="competitors-configuration-screen__card__row">
                <Checkbox
                  checked={isTrafficNormalized}
                  id="isTrafficNormalized"
                  onChange={handleToggle(setIsTrafficNormalizedEnabled)}
                />
                <label
                  className="competitors-configuration-screen__card__row__label"
                  htmlFor="isTrafficNormalized"
                >
                  {t('competitors:configuration.is-normalizing-traffic')}
                </label>
              </div>
            </div>
            <div className="competitors-configuration-screen__card-footer">
              <Button
                disabled={isCompareActionDisabled}
                variant={ENUM_BUTTON_VARIANTS.GradientPrimary}
                onClick={handleComputeReport}
              >
                {t('competitors:configuration.compute-report')}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <LimitModal
        buttonLabel={t('competitors:configuration.modal.contact-us')}
        description={t('competitors:configuration.modal.description')}
        illustration={<img alt="" src={UnlockImage} />}
        isOpen={isOpenLimitModal}
        title={t('competitors:configuration.modal.title', {
          count: maxCompetitors,
        })}
        onClose={() => setIsOpenLimitModal(false)}
      />
    </>
  );
}

export default withErrorBoundary(Configure);
