import { timer } from 'd3-timer';
import { useEffect, useState } from 'react';
import { useReactFlow } from 'reactflow';

import { ANIMATION_DURATION } from '@/containers/ContentIdeas/Listing/MindMap/constant';

function useAnimatedNodes({ nodes = [], animationDuration = ANIMATION_DURATION }) {
  const [tmpNodes, setTmpNodes] = useState(nodes);
  const { getNode } = useReactFlow();

  useEffect(() => {
    const transitions = nodes.map((node) => ({
      from: getNode(node.id)?.position ?? node.position,
      id: node.id,
      node,
      to: node.position,
    }));

    const time = timer((elapsed) => {
      const sleep = elapsed / animationDuration;

      const currNodes = transitions.map(({ node, from, to }) => {
        return {
          ...node,
          position: { x: from.x + (to.x - from.x) * sleep, y: from.y + (to.y - from.y) * sleep },
        };
      });

      setTmpNodes(currNodes);

      if (elapsed > animationDuration) {
        // it's important to set the final nodes here to avoid glitches
        setTmpNodes(nodes);
        time.stop();
      }
    });

    return () => time.stop();
  }, [nodes, getNode, animationDuration]);

  return { nodes: tmpNodes };
}

export default useAnimatedNodes;
