import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

import MessageBox from '@/components/Box/MessageBox';
import { DEFAULT_BACKGROUND, TRANSPARENT_LOGO_AND_PROFILE_MENU } from '@/components/Layout/Layout';
import LayoutWrapper from '@/containers/Layout/Layout';
import Can from '@/hoc/Can';
import { getDefaultOrganizationId } from '@/utils/helper';

function DefaultOrganization() {
  const { t } = useTranslation();
  const location = useLocation();
  const organizationAccesses = useSelector((state) => state.user.organizationAccesses);
  const defaultOrganizationId = useSelector((state) => state.defaultOrganizationId);
  const defaultId = getDefaultOrganizationId(defaultOrganizationId, organizationAccesses);

  if (defaultId) {
    return <Navigate to={location.state?.redirectTo ?? `/o/${defaultId}`} />;
  }

  return (
    <Can
      no={() => (
        <LayoutWrapper menu={TRANSPARENT_LOGO_AND_PROFILE_MENU} variant={DEFAULT_BACKGROUND}>
          <MessageBox
            message={t('organization:default-organization.error-message')}
            title={t('organization:default-organization.error-title')}
          />
        </LayoutWrapper>
      )}
      perform={'create-organization:self-serve'}
      yes={() => <Navigate to="/create-organization" />}
    />
  );
}

export default DefaultOrganization;
