import React from 'react';
import styled from 'styled-components/macro';

export default styled(({ loading, href, children, ...props }) => (
  <div {...props}>
    <a href={href} rel="noreferrer noopener" target="_blank">
      {children}
    </a>
  </div>
))`
  overflow: hidden;
  padding: 5px 0 20px;
  position: relative;
  a {
    width: 100%;
    position: absolute;
    font-weight: 400;
    font-size: 1em;
    text-decoration: none;
    color: ${(props) => (props.loading ? 'transparent' : props.theme.colors.halfGrey)};
    transition:
      color 400ms,
      opacity 400ms,
      font-size 200ms,
      padding-top 200ms;
    text-overflow: ellipsis;
    white-space: nowrap;
    align-self: flex-start;
    min-height: 20px;
    box-sizing: border-box;
    &:hover {
      color: ${(props) => (props.loading ? 'none' : props.theme.colors.greyBlue)};
    }
  }
`;
