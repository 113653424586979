import React from 'react';
import styled from 'styled-components/macro';

export const MenuButton = styled(({ darkColor, onClick, ...props }) => (
  <span
    onClick={(e) => {
      if (props.disabled) {
        e.preventDefault();
      } else {
        onClick(e);
      }
    }}
    {...props}
  />
))`
  padding: ${({ theme, padding }) => padding || theme.textCss.sizes.sm};
  opacity: ${(props) => (props.disabled ? 0.3 : 0.6)};
  text-decoration: none;
  display: flex;
  color: ${(props) =>
    props.darkColor ? props.theme.cssColors.dark080 : props.theme.cssColors.white};
  font-size: ${(props) => props.theme.textCss.sizes.xs};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;
