import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the grid
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Optional Theme applied to the grid
import './GridBody.scss';

import {
  ColDef,
  ColumnResizedEvent,
  GridApi,
  GridReadyEvent,
  GridSizeChangedEvent,
  ICellRendererParams,
  RowSelectedEvent,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import React, { LegacyRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getClassNames } from 'semji-core/utils/getClassNames';

import CellLoader from '@/components/AGGrid/CellLoader/CellLoader';
import {
  ENUM_GRID_DISPLAY,
  GridBodyProps,
  IGridColumnPersister,
} from '@/components/AGGrid/GridBody/GridBody.types';
import { getAgGridTranslations } from '@/components/AGGrid/locale/ag-trans';
import { NoSearchFound } from '@/components/AGGrid/NoSearchFound';
import { GridUtils } from '@/components/AGGrid/utils/Grid.utils';

function GridBody({
  colDefs,
  gridStateHook,
  paginationHook,
  sortingHook,
  gridSelection,
  filterHook,
  gridSelectionHook,
}: GridBodyProps<any>): React.JSX.Element {
  const { t } = useTranslation();
  const gridApiRef: React.MutableRefObject<GridApi> = useRef<any | GridApi>(null);
  const gridColumnsPersister = useRef<IGridColumnPersister[]>([]);
  const [gridDisplay, setGridDisplay] = useState<ENUM_GRID_DISPLAY>();
  const { data: rowData } = gridStateHook;

  function onGridReady(params: GridReadyEvent) {
    // Init gridApiRef
    gridApiRef.current = params.api;
    // Init gridColumnsPersister
    gridColumnsPersister.current = GridUtils.initGridColumnsPersister(gridApiRef.current);
    // Init pagination
    paginationHook.initPagination();
    // Init selection
    gridSelectionHook?.initGridSelection(gridApiRef.current);
    // Auto size first column
    gridApiRef.current.autoSizeColumns([gridColumnsPersister.current[0].columnId]);
    // Init grid size
    updateGridDisplay();
  }

  // Will update the grid display with pinned or unpinned first and last column
  function updateGridDisplay() {
    if (GridUtils.isGridOverflowing(gridColumnsPersister.current)) {
      gridColumnsPersister.current = GridUtils.updateGridColumnPinning(
        gridApiRef.current,
        gridColumnsPersister.current,
        true
      );
      setGridDisplay(ENUM_GRID_DISPLAY.pinned);
    }

    if (!GridUtils.isGridOverflowing(gridColumnsPersister.current)) {
      gridColumnsPersister.current = GridUtils.updateGridColumnPinning(
        gridApiRef.current,
        gridColumnsPersister.current,
        false
      );
      setGridDisplay(ENUM_GRID_DISPLAY.normal);
    }
  }

  function onGridSizeChanged(e: GridSizeChangedEvent) {
    updateGridDisplay();
  }

  // Will save the current width of the columns and store it in the gridColumnsPersister
  // And update the grid display
  function onColumnResized(e: ColumnResizedEvent) {
    gridColumnsPersister.current = GridUtils.updateGridColumnsPersister(
      gridApiRef.current,
      gridColumnsPersister.current
    );
    updateGridDisplay();
  }

  function onRowSelected(e: RowSelectedEvent) {
    gridSelectionHook?.onSelect(gridApiRef.current.getSelectedRows());
  }

  // If column is sortable, will use IColDef.field as ordering property
  function onSortChanged(e: any) {
    // Target the last selected sort
    const targetColumn = e.columns[e.columns.length - 1];
    if (!targetColumn.sort) {
      sortingHook.removeSort();
    } else {
      sortingHook.addSort({
        order: {
          // Field is defined in colDefs
          // Sort is asc | desc
          [targetColumn.colDef.field]: targetColumn.sort,
        },
      });
    }
  }

  useEffect(() => {
    gridSelectionHook?.onSelect([]);
  }, [paginationHook.pagination.page, paginationHook.pagination.itemsPerPage]);

  return (
    <div className="ag-theme-quartz ag-grid-container">
      <AgGridReact
        ref={gridApiRef as unknown as LegacyRef<AgGridReact<any>>}
        columnDefs={colDefs.map(
          (colDef, index): ColDef => ({
            ...colDef,
            // Display cell class
            cellClass: getClassNames(
              colDef.align && `ag-grid-container__col--align-${colDef.align}`,
              colDef.cellClass && colDef.cellClass
            ),
            // Used to display skeleton loader when no data is available
            cellRenderer: (params: ICellRendererParams) => {
              if (params.data.isLoading) return <CellLoader />;
              return colDef.cellRenderer?.(params) ?? null;
            },
            // Show checkbox selection on first cell
            checkboxSelection: !rowData?.[0]?.isLoading && gridSelection && index === 0,
            // Disable default table sorting
            comparator: () => 0,
            // Disable default table filters
            filter: false,
            // If data is loading, do not show check box selection on first column
            headerCheckboxSelection: !rowData?.[0]?.isLoading && gridSelection && index === 0,
            // Default col header class

            headerClass: getClassNames(
              'ag-grid-container__header',
              colDef.align && `ag-grid-container__col--align-${colDef.align}`,
              colDef.headerClass && colDef.headerClass
            ),
            // Set first or last column pinned based on grid width
            pinned: gridColumnsPersister.current?.[index]?.pinned ?? undefined,
            // Set all columns resizable
            resizable: true,
            // Only allow sorting on explicit definition of property sortable
            sortable: colDef.sortable === true,
            // Use persisted width to keep actualWidth on pagination change
            width: gridColumnsPersister.current?.[index]?.width ?? colDef.width,
            // Wrap text in column headers
            wrapHeaderText: false,
          })
        )}
        noRowsOverlayComponent={NoSearchFound}
        noRowsOverlayComponentParams={{
          onClearFilters: filterHook.removeAllFilters,
        }}
        rowData={rowData}
        rowHeight={80}
        rowSelection={gridSelection ? 'multiple' : undefined}
        suppressCellFocus={true}
        suppressMovableColumns={true}
        suppressRowClickSelection={true}
        suppressRowHoverHighlight={true}
        onColumnResized={onColumnResized}
        onGridReady={onGridReady}
        onGridSizeChanged={onGridSizeChanged}
        onRowSelected={onRowSelected}
        onSortChanged={onSortChanged}
        enableCellTextSelection={true}
        // Get ag grid translations
        localeText={getAgGridTranslations(t)}
      />
    </div>
  );
}

export default GridBody;
