export const USER_COLORS = [
  { backgroundColor: '#d64854', color: '#d23744', fontColor: '#FFFFFF' },
  { backgroundColor: '#eb6b3e', color: '#e85521', fontColor: '#FFFFFF' },
  { backgroundColor: '#ef9f39', color: '#b36b0e', fontColor: '#FFFFFF' },
  { backgroundColor: '#e7c542', color: '#957a13', fontColor: '#FFFFFF' },
  { backgroundColor: '#adce4f', color: '#6b8425', fontColor: '#FFFFFF' },
  { backgroundColor: '#80cf7a', color: '#35832f', fontColor: '#FFFFFF' },
  { backgroundColor: '#65c2ac', color: '#307967', fontColor: '#FFFFFF' },
  { backgroundColor: '#51a8e4', color: '#1d79b9', fontColor: '#FFFFFF' },
  { backgroundColor: '#5384d9', color: '#2759af', fontColor: '#FFFFFF' },
  { backgroundColor: '#786fe8', color: '#5246e1', fontColor: '#FFFFFF' },
  { backgroundColor: '#a065dc', color: '#8c44d5', fontColor: '#FFFFFF' },
  { backgroundColor: '#d36add', color: '#c844d4', fontColor: '#FFFFFF' },
  { backgroundColor: '#d8599b', color: '#ce3183', fontColor: '#FFFFFF' },
  { backgroundColor: '#ee96ad', color: '#e03e68', fontColor: '#FFFFFF' },
  { backgroundColor: '#91a2a5', color: '#5e7073', fontColor: '#FFFFFF' },
];

export function getInitialsFromEmail(email = '') {
  let initials = '';
  const emailParts = email.split('@');
  const username = emailParts[0];
  const usernameParts = username.split('.');

  if (emailParts.length > 0 && usernameParts.length >= 2) {
    initials = usernameParts[0].charAt(0) + usernameParts[1].charAt(0);
    initials = initials.toUpperCase();
  } else {
    initials = email.charAt(0).toUpperCase() + email.charAt(1);
  }

  return initials;
}

export function getFullNameFromEmail(email) {
  let fullName = '';

  if (!email) {
    return fullName;
  }

  const username = email.split('@')[0];
  const usernameParts = username.split('.');

  if (usernameParts.length >= 2) {
    const firstName = usernameParts[0].charAt(0).toUpperCase() + usernameParts[0].slice(1);
    const lastName = usernameParts[1].toUpperCase();
    fullName = `${firstName} ${lastName}`;
  }

  return fullName;
}

export function getFullNameFromUser(user) {
  let fullName = '';

  if (user.firstName) {
    fullName = user.firstName;
  }

  if (user.lastName) {
    fullName = `${fullName} ${user.lastName}`;
  }

  if (fullName) {
    return fullName;
  }

  return getFullNameFromEmail(user.email);
}

export function getInitialsFromUser(user = { email: '', firstName: '', lastName: '' }) {
  let initials = '';
  const { firstName, lastName, email } = user;

  if (firstName && lastName && firstName.length >= 1 && lastName.length >= 1) {
    initials = firstName.substr(0, 1) + lastName.substr(0, 1);
  } else if (firstName && firstName.length >= 2) {
    initials = firstName.charAt(0).toUpperCase() + firstName.slice(1, 2).toLowerCase();
  } else if (lastName && lastName.length >= 2) {
    initials = lastName.charAt(0).toUpperCase() + lastName.slice(1, 2).toLowerCase();
  } else {
    initials = getInitialsFromEmail(email);
  }

  return initials;
}

export function computeLocationLabel({ countryCode, languageCode, isObvious }) {
  const lang = !!languageCode ? `${languageCode.toLowerCase()}` : '';
  const country = !!countryCode ? `${countryCode.toUpperCase()}` : '';

  if (!lang && !country) {
    return '';
  }

  if (isObvious || !lang) {
    return ` (${country})`;
  }

  if (!country) {
    return ` (${lang})`;
  }

  return ` (${country}/${lang})`;
}

export function sortUsers(users = []) {
  return [...users].sort((a, b) => {
    const aFullName = (a.user?.firstName || '') + (a.user?.lastName || '');
    const bFullName = (b.user?.firstName || '') + (b.user?.lastName || '');
    const aEmail = a.user?.email || '';
    const bEmail = b.user?.email || '';

    if (!aFullName && !bFullName) {
      return aEmail.localeCompare(bEmail);
    }

    if (!aFullName) {
      return 1;
    }

    if (!bFullName) {
      return -1;
    }

    return aFullName.localeCompare(bFullName);
  });
}
