import useCan from '@/hooks/useCan';
import { noop } from '@/utils/noop';

function Can({
  yes = noop,
  no = noop,
  perform,
  data,
}: {
  yes?: () => JSX.Element | null;
  no?: () => JSX.Element | null;
  perform: string;
  data?: any;
}) {
  const check = useCan({ data, perform });

  return check ? yes() : no();
}

export default Can;
