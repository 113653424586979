import i18next from 'i18next';
import React from 'react';

import {
  ENUM_FILTER_TYPE,
  MultiFilterProps,
  MultipleFilterType,
} from '@/components/DynamicFilters';
import BookMarkIcon from '@/components/icons/BookMarkIcon';
import { CLUSTER_FILTER_QUERY_PARAM } from '@/containers/Competitors/utils/constants';
import { CompetitorCluster } from '@/types/competitors';

export function getClusterFilter({
  updateFilter,
  filter,
  clusters,
}: MultiFilterProps & { clusters: CompetitorCluster[] }): MultipleFilterType {
  return {
    icon: <BookMarkIcon />,
    key: CLUSTER_FILTER_QUERY_PARAM,
    name: i18next.t('competitors:filters.theme'),
    onValidate: (value) => updateFilter(CLUSTER_FILTER_QUERY_PARAM, value),
    options: clusters.map((cluster) => ({
      label: cluster.name,
      selected: filter.includes(cluster.id),
      value: cluster.id,
    })),
    type: ENUM_FILTER_TYPE.MULTI_SELECT,
  };
}
