import { getTopKeywordOnPage } from '@/apis/semji/api';

export default class Keyword {
  constructor(id, pageId) {
    this._id = id;
    this._pageId = pageId;
    this._keywords = null;
  }

  get keywords() {
    return this._fetchKeywords();
  }

  async _fetchKeywords() {
    if (this._keywords === null) {
      try {
        this._keywords = await getTopKeywordOnPage(this._pageId);
      } catch (e) {
        return Promise.reject(new Error(e));
      }
    }

    return Promise.resolve(this._keywords['hydra:member']);
  }
}
