import i18next from 'i18next';
import { DateTime } from 'luxon';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Avatar } from 'semji-core/components/Avatar';
import { Dropdown } from 'semji-core/components/Dropdown';
import { CheckIcon } from 'semji-core/icons/CheckIcon';
import { MoreIcon } from 'semji-core/icons/MoreIcon';
import { isCurrentYearDate, isTodayDate, isYesterdayDate } from 'semji-core/utils/date';

import BackRightIcon from '@/components/icons/BackRightIcon';
import Tooltip from '@/components/Tooltip/Tooltip';
import { useContentContainerContext } from '@/containers/Content/ContentContainerContext';
import Delete from '@/containers/Content/TinyMceComponents/Comments/SidePanel/Delete';
import TextArea from '@/containers/Content/TinyMceComponents/Comments/SidePanel/TextArea';
import Flex from '@/design-system/components/Flex';

import {
  CommentDate,
  CommentMoreDropdownItem,
  CommentMoreDropdownText,
  CommentMoreDropdownWrapper,
  CommentName,
  StyledAlertIcon,
  StyledDeleteIcon,
  StyledEditIcon,
} from './Comment.styled';
import CommentText from './CommentText';

function Actions({ actions }) {
  return (
    <CommentMoreDropdownWrapper backgroundColor="white" flexDirection="column" padding="8px 0">
      {actions.map(({ text, icon, action }, index) => (
        <CommentMoreDropdownItem
          key={`action-${index}`}
          alignItems="center"
          cursor="pointer"
          gap="10px"
          padding="8px 16px"
          width="-webkit-fill-available"
          onClick={action}
        >
          {icon}
          <CommentMoreDropdownText>{text}</CommentMoreDropdownText>
        </CommentMoreDropdownItem>
      ))}
    </CommentMoreDropdownWrapper>
  );
}

function getDateRender(date) {
  const currentDate = DateTime.fromISO(date);
  const relativeDate = currentDate.toRelativeCalendar({ locale: i18next.language });
  const relativeDateFirstLetterUppercase =
    relativeDate && relativeDate.charAt(0).toUpperCase() + relativeDate.slice(1);

  if (isTodayDate(currentDate) || isYesterdayDate(currentDate)) {
    return `${currentDate.toFormat('HH:mm')} ${relativeDateFirstLetterUppercase}`;
  } else if (isCurrentYearDate(currentDate)) {
    return currentDate.toFormat('HH:mm MMM dd');
  }
  return currentDate.toFormat('HH:mm MMM dd yyyy');
}

function Comment({
  comment,
  isThreadResolved,
  isFirstComment,
  isFocus,
  isThreadHovered,
  setShowDeleteThreadRender,
  user,
  users,
}) {
  const { t } = useTranslation();
  const {
    comments: { putComment, postUnResolveThread, postResolveThread, deleteComment, focusOnThread },
  } = useContentContainerContext();
  const currentUser = useSelector((state) => state.user);

  const [showDeleteCommentRender, setShowDeleteCommentRender] = useState(false);
  const [showEditCommentRender, setShowEditCommentRender] = useState(false);
  const [actionsOpened, setActionsOpened] = useState(false);

  const dropDrownActionsRef = useRef({
    handleClose: () => null,
    handleOpen: () => null,
  });

  const actions = [
    {
      action: handleEditCommentRender,
      icon: <StyledEditIcon />,
      text: t('comments:actions:edit-comment'),
    },
    {
      action: handleDeleteCommentRender,
      icon: <StyledDeleteIcon />,
      text: isFirstComment
        ? t('comments:actions:delete-thread')
        : t('comments:actions:delete-comment'),
    },
  ];

  const apiRootUrl = import.meta.env.VITE_REACT_APP_SEMJI_API_ROOT_URL;

  function handleEditCommentRender() {
    focusOnThread(comment.threadId);
    setShowEditCommentRender(true);
    setShowDeleteCommentRender(false);
    dropDrownActionsRef.current.handleClose();
  }

  function handleDeleteCommentRender() {
    if (isFirstComment) {
      setShowDeleteThreadRender(true);
    } else {
      setShowDeleteCommentRender(true);
      handleCloseEditCommentRender();
    }
    dropDrownActionsRef.current.handleClose();
  }

  function handleCloseDeleteCommentRender() {
    setShowDeleteCommentRender(false);
  }

  function handleDeleteComment() {
    deleteComment.mutate({ commentId: comment.id, threadId: comment.threadId });
  }

  function handlePutComment(content) {
    putComment.mutate({ commentId: comment.id, content, threadId: comment.threadId });
    handleCloseEditCommentRender();
  }

  function handleResolveThread(e) {
    e.stopPropagation();
    postResolveThread.mutate({ threadId: comment.threadId });
  }

  function handleUnResolveThread(e) {
    e.stopPropagation();
    postUnResolveThread.mutate({ threadId: comment.threadId });
  }

  function handleCloseEditCommentRender() {
    setShowEditCommentRender(false);
  }

  function getName() {
    if (user?.email) {
      return user?.firstName ? `${user?.firstName} ${user?.lastName}`.trim() : user.email;
    } else {
      return t('comments:deleted-user.anonymous');
    }
  }

  function renderCommentAvatar() {
    if (!user) {
      return (
        <Tooltip placement="top" title={t('comments:tooltip.user-deleted')}>
          <Avatar
            apiRootUrl={apiRootUrl}
            email="?"
            opacity={isThreadResolved ? '0.5' : undefined}
            size="22px"
            translations={{ unassigned: t('common:user-picker.unassigned') }}
          />
          <StyledAlertIcon />
        </Tooltip>
      );
    }

    const userHasWorkspaceAccess = !!users.find((workspaceUser) => workspaceUser.id === user.id);

    if (userHasWorkspaceAccess) {
      return (
        <>
          <Avatar
            apiRootUrl={apiRootUrl}
            email={user.email}
            firstName={user.firstName}
            lastName={user.lastName}
            opacity={isThreadResolved ? '0.5' : undefined}
            profileImageHash={user.profileImageHash}
            size="22px"
            translations={{ unassigned: t('common:user-picker.unassigned') }}
            uploadedAvatarUrl={user.uploadedAvatarUrl}
          />
        </>
      );
    } else {
      return (
        <Tooltip placement="top" title={t('comments:tooltip.user-removed-workspace')}>
          <Avatar
            apiRootUrl={apiRootUrl}
            email={user.email}
            firstName={user.firstName}
            lastName={user.lastName}
            opacity={isThreadResolved ? '0.5' : undefined}
            profileImageHash={user.profileImageHash}
            size="22px"
            translations={{ unassigned: t('common:user-picker.unassigned') }}
            uploadedAvatarUrl={user.uploadedAvatarUrl}
          />

          <StyledAlertIcon />
        </Tooltip>
      );
    }
  }

  return (
    <Flex flexDirection="column" gap="4px" padding="2px 0" position="relative" width="100%">
      <Flex key={comment.id} alignItems="center" gap="12px" height="24px" width="100%">
        {renderCommentAvatar()}
        <Flex justifyContent="space-between" width="100%">
          <Flex
            alignItems="center"
            gap="4px"
            maxWidth="75%"
            opacity={isThreadResolved ? '0.5' : undefined}
            overflow="hidden"
          >
            <CommentName>{getName()}</CommentName>
            <CommentDate>
              {getDateRender(comment.createdAt)}
              {comment.updatedAt && ' (modifié)'}
            </CommentDate>
          </Flex>
          {(isThreadHovered || isFocus || actionsOpened) && (
            <Flex gap="16px" justifyContent="flex-end">
              {isFirstComment &&
                (isThreadResolved ? (
                  <Tooltip cursor="pointer" title={t('comments:unresolve-thread')}>
                    <BackRightIcon
                      className="side-panel-comments__back-right-icon"
                      onClick={handleUnResolveThread}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip cursor="pointer" title={t('comments:resolve-thread')}>
                    <CheckIcon
                      className="side-panel-comments__check-icon"
                      onClick={handleResolveThread}
                    />
                  </Tooltip>
                ))}
              {currentUser.id === comment.createdById && (
                <Dropdown
                  actionsRef={dropDrownActionsRef}
                  anchorElement={
                    <Tooltip cursor="pointer" title={t('comments:more-actions')}>
                      <MoreIcon className="side-panel-comments__more-icon" />
                    </Tooltip>
                  }
                  offsetValue={10}
                  querySelectorListener="#root"
                  width="150"
                  isFrame={false}
                  onClose={() => setActionsOpened(false)}
                  onOpen={() => setActionsOpened(true)}
                >
                  <Actions actions={actions} />
                </Dropdown>
              )}
            </Flex>
          )}
        </Flex>
      </Flex>
      <Flex padding={`0 6px 0 ${showEditCommentRender ? '26px' : '34px'}`}>
        {showEditCommentRender ? (
          <TextArea
            defaultValue={comment.content}
            threadId={comment.threadId}
            onClose={handleCloseEditCommentRender}
            onSubmit={handlePutComment}
          />
        ) : (
          <CommentText comment={comment} isThreadResolved={isThreadResolved} users={users} />
        )}
      </Flex>
      {showDeleteCommentRender && (
        <Delete
          title={t('comments:delete.delete-comment')}
          onClose={handleCloseDeleteCommentRender}
          onConfirm={handleDeleteComment}
        />
      )}
    </Flex>
  );
}

export default Comment;
