import React from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar } from 'semji-core/components/Avatar';
import styled, { withTheme } from 'styled-components/macro';

import { ColoredDot } from '@/components/_common';
import CaretIcon from '@/components/icons/CaretIcon';

const Caret = styled(({ dark, ...props }) => <CaretIcon {...props} />)`
  width: 20px;
  height: 20px;
  margin-left: 10px;
  color: ${(props) => props.dark && props.theme.colors.grey};
`;
const Label = styled.div`
  color: ${(props) => props.theme.text.colors.white};
  font-size: 0.9em;
  padding: 0 0 5px 5px;
  font-weight: 300;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const SubLabel = styled.div`
  color: ${(props) => props.theme.text.colors.brightWhite};
  font-size: 0.65em;
  padding-left: 5px;
  font-weight: 300;
  &&::first-letter {
    text-transform: capitalize;
  }
`;
const Wrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 1em;
  padding: 0.4em 0em;
  min-width: 3em;
  max-width: 300px;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
`;
const StyledDot = styled(ColoredDot)`
  && {
    top: 0;
    left: 15px;
    position: absolute;
  }
`;

const Flex = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

function ProfileButton({
  user,
  userImpersonate,
  currentWorkspace,
  onClick,
  isAdminMode,
  theme,
  ...props
}) {
  const { t } = useTranslation();
  const apiRootUrl = import.meta.env.VITE_REACT_APP_SEMJI_API_ROOT_URL;

  const isObvious = currentWorkspace?.officialLanguages
    ? currentWorkspace.officialLanguages.length === 1
    : true;
  const language = isObvious ? '' : `(${currentWorkspace?.languageCode ?? ''})`;
  const label = currentWorkspace?.name ?? '';
  const subLabel = `${currentWorkspace?.countryCode ?? ''} ${language}`;

  return (
    <Wrapper onClick={onClick} {...props}>
      <Avatar
        apiRootUrl={apiRootUrl}
        border={userImpersonate ? `3px solid ${theme.colors.red}` : 'none'}
        email={user?.email}
        firstName={user?.firstName}
        lastName={user?.lastName}
        profileImageHash={user?.profileImageHash}
        translations={{ unassigned: t('common:user-picker.unassigned') }}
        uploadedAvatarUrl={user?.uploadedAvatarUrl}
      />
      {isAdminMode && <StyledDot color="#f76767" />}
      <Flex>
        <Label>
          <span title={label}>{label}</span>
        </Label>
        <SubLabel>
          <span title={subLabel}>{subLabel}</span>
        </SubLabel>
      </Flex>
      <Caret />
    </Wrapper>
  );
}

export default withTheme(ProfileButton);
