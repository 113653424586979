import { useQueryClient } from '@tanstack/react-query';
import debounce from 'lodash/debounce';
import { lazy, Suspense, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useMatch, useNavigate, useParams } from 'react-router-dom';

import { SCOPE_CONTENTS } from '@/apis/semji/constants';
import useGetContentById from '@/apis/semji/contents/useGetContentById';
import useGetContentVersionById from '@/apis/semji/contents/useGetVersionById/useGetVersionById';
import usePostRefreshContentData from '@/apis/semji/contents/usePostRefreshContentData';
import usePutContent from '@/apis/semji/contents/usePutContent';
import EditorSynchronizeDialog from '@/components/Dialog/EditorSynchronizeDialog';
import { useContentContainerContext } from '@/containers/Content/ContentContainerContext';
import {
  CONTENT_SELECTOR,
  METADESCRIPTION_SELECTOR,
  TITLE_SELECTOR,
} from '@/containers/Content/TinyMceComponents/Editor/constants';
import { ContentUtils } from '@/containers/Content/TinyMceComponents/Editor/hooks/useContent/content.utils';
import { migrateOldHtmlToNewCommentAttributes } from '@/containers/Content/TinyMceComponents/Utils';
import Dialog from '@/design-system/components/Dialog';
import useBasicStateDialog from '@/design-system/hooks/useBasicStateDialog';
import useApiConfigurations from '@/hooks/useApiConfigurations';
import useForceRender from '@/hooks/useForceRender';
import usePrompt from '@/hooks/usePrompt';
import {
  resetContent,
  setContent as setContentAction,
  setContentHtml,
  setContentMetaDescription,
  setContentTitle,
} from '@/store/actions/content';
import { setFocusTopKeyword } from '@/store/actions/report';
import { showErrorSnackbar } from '@/store/actions/ui';
import { ENUM_CONTENT_TYPE } from '@/types/contents';
import { STATUS_PENDING } from '@/utils/analysis';
import {
  EDITOR_CONTENT_REFETCH_FREQUENCY_MS,
  EDITOR_DEBOUNCE_SAVE_FREQUENCY_MS,
} from '@/utils/configurations/constants';

const CodeModal = lazy(
  () =>
    import(
      /* webpackChunkName: "CodeModal" */ '@/containers/Content/TinyMceComponents/Code/CodeModal'
    )
);

const HTTP_SAVE_ERROR_STATUS = 403;
const HTTP_GET_ERROR_STATUS = 404;
const HTTP_CONFLICT_STATUS = 409;

export default function useContent({
  cleanHtml,
  handleSetTitle,
  handleSetMetaDescription,
  isAtomicContentGenerating,
  handleCancelAtomicContent,
  hasAccessToMetaDescription,
}) {
  const { workspaceId, organizationId, pageId, contentId, versionId } = useParams();
  const matchVersionScreens = useMatch(
    '/o/:organizationId/w/:workspaceId/p/:pageId/versions/:contentId/*'
  );
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { editorRef, inputTitleRef, textareaMetaDescriptionRef } = useContentContainerContext();

  const DEBOUNCE_SAVE_DELAY = parseInt(useApiConfigurations(EDITOR_DEBOUNCE_SAVE_FREQUENCY_MS), 10);
  const CONTENT_REFETCH_FREQUENCY =
    parseInt(useApiConfigurations(EDITOR_CONTENT_REFETCH_FREQUENCY_MS), 10) || false;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [initialHtml, setInitialHtml] = useState({ lastSetAt: Date.now(), value: '' });
  const [initialTitle, setInitialTitle] = useState({ lastSetAt: Date.now(), value: '' });
  const [hasSavingConflict, setHasSavingConflict] = useState(false);
  const [isResolvingConflict, setIsResolvingConflict] = useState(false);
  const refreshDialog = useBasicStateDialog();
  const sourceCodeDialog = useBasicStateDialog();
  const isPutContentLoading = useRef(false);
  const atomicContentForceSaveRef = useRef(false);

  const { forceRender } = useForceRender();
  const contentRef = useRef({});

  const content = contentRef.current;
  const isReadOnly = content?.type !== ENUM_CONTENT_TYPE.DRAFT || !!matchVersionScreens;

  const editorInnerHtml = document.querySelector(CONTENT_SELECTOR)?.innerHTML;
  const cleanedHtml = editorInnerHtml ? cleanHtml(editorInnerHtml) : null;

  const isContentDirty =
    !isReadOnly &&
    null !== cleanedHtml &&
    (cleanedHtml !== contentRef.current?.html ||
      contentRef.current?.title !== inputTitleRef.current?.value ||
      (contentRef.current?.metaDescription !== textareaMetaDescriptionRef.current?.value &&
        hasAccessToMetaDescription));

  function setContent(content) {
    contentRef.current = { ...content };
    editorRef.current?.setDirty(true);
    forceRender();
  }

  function setContentWithoutForceRender(content) {
    contentRef.current = { ...content };
  }

  function handleSetContentHtml(html) {
    if (html !== null) {
      setInitialHtml({ lastSetAt: Date.now(), value: html });
      editorRef.current?.setContent(html);
      editorRef.current?.setDirty(true);
    }
  }

  function handleSetContentTitle(title) {
    if (title !== null) {
      setInitialTitle({ lastSetAt: Date.now(), value: title });
      handleSetTitle(title);
    }
  }

  function handleSetContentMetaDescription(metaDescription) {
    if (metaDescription !== null) {
      handleSetMetaDescription(metaDescription);
    }
  }

  const getContentReq = useGetContentById({
    contentId,
    enabled: !versionId,
    onError: (error) => {
      if (error?.response?.status === HTTP_GET_ERROR_STATUS) {
        navigate(`/o/${workspaceId}/w/${organizationId}/planning`, { replace: true });
        dispatch(showErrorSnackbar(t('common:error.default')));
      }
    },
    onSuccess: (content) => {
      if (!!versionId) {
        // prevent to update the content with content's data if we are in the version screen and it's a contentVersion selected
        return;
      }
      const { html, title, pageFocusTopKeyword, metaDescription } = content;

      if (!editorRef.current?.mode?.isReadOnly()) {
        // fix: https://gitlab.rvip.fr/semji/semji/-/issues/7553
        if (pageFocusTopKeyword.analysisStatus !== STATUS_PENDING) {
          dispatch(setFocusTopKeyword(pageFocusTopKeyword));
        }

        if (!initialHtml.value || html !== contentRef.current?.html) {
          // this migration is to handle the new architecure of comments
          const migratedHtml = migrateOldHtmlToNewCommentAttributes(html);

          const validHtml = migratedHtml === '' ? ContentUtils.createEmptyHTML() : migratedHtml;

          handleSetContentHtml(validHtml);
          dispatch(setContentHtml(validHtml));
        }

        if (title !== contentRef.current?.title) {
          handleSetContentTitle(title);
          dispatch(setContentTitle(title));
        }

        if (metaDescription !== contentRef.current?.metaDescription) {
          handleSetContentMetaDescription(metaDescription);
          dispatch(setContentMetaDescription(metaDescription));
        }

        setContent(content);
        atomicContentForceSaveRef.current = false;
      }
    },
    refetchInterval: (data, query) => {
      if (
        !query.state.isFetching &&
        !isPutContentLoading.current &&
        !hasSavingConflict &&
        !isContentDirty
      ) {
        return CONTENT_REFETCH_FREQUENCY;
      }
      return false;
    },
    refetchOnWindowFocus: 'always',
  });

  // try to get the version of the content instead of the content itself if we are in the version screen and it's a draft content selected

  const getVersionReq = useGetContentVersionById({
    contentId,
    enabled: !!versionId,
    onError: (error) => {
      if (error?.response?.status === HTTP_GET_ERROR_STATUS) {
        navigate(`/o/${organizationId}/w/${workspaceId}/p/${pageId}/versions/${contentId}`, {
          replace: true,
        });
        dispatch(showErrorSnackbar(t('common:error.default')));
      }
    },
    onSuccess: (contentVersion) => {
      const { content: html, title, metaDescription } = contentVersion;

      if (!editorRef.current?.mode?.isReadOnly()) {
        if (!initialHtml.value || html !== contentRef.current?.html) {
          // this migration is to handle the new architecure of comments
          const migratedHtml = migrateOldHtmlToNewCommentAttributes(html);

          const validHtml = migratedHtml === '' ? ContentUtils.createEmptyHTML() : migratedHtml;

          handleSetContentHtml(validHtml);
          dispatch(setContentHtml(validHtml));
        }

        if (title !== contentRef.current?.title) {
          handleSetContentTitle(title);
          dispatch(setContentTitle(title));
        }

        if (metaDescription !== contentRef.current?.metaDescription) {
          handleSetContentMetaDescription(metaDescription);
          dispatch(setContentMetaDescription(metaDescription));
        }

        setContent({ html, metaDescription, title });
        atomicContentForceSaveRef.current = false;
      }
    },
    versionId,
  });

  const { isLoading: isRefreshingData, mutateAsync: refreshDataAsync } = usePostRefreshContentData({
    contentId,
    onSuccess: ({ data }) => {
      debouncedSave.cancel?.();

      const { html, title, pageFocusTopKeyword, metaDescription } = data;
      dispatch(setContentAction({ html, metaDescription, title }));
      dispatch(setFocusTopKeyword(pageFocusTopKeyword));
      handleSetContentHtml(html);
      handleSetContentTitle(title);
      handleSetContentMetaDescription(metaDescription);

      setContent(data);
    },
  });

  const putContentReq = usePutContent({
    contentId,
    onError: (error) => {
      if (error?.response?.status === HTTP_SAVE_ERROR_STATUS) {
        dispatch(showErrorSnackbar(t('content:editor-container.saving-error')));
      } else if (error?.response?.status === HTTP_CONFLICT_STATUS) {
        if (atomicContentForceSaveRef.current) {
          // Force save if atomic content generating and has been override one time
          handleOverrideContent();
        } else {
          setHasSavingConflict(true);
          setTimeout(() => debouncedSave.cancel());
          // we set timeout because we want to cancel the debounced save
          // after the debouncedSave has been recreated due to the re-render
        }
      } else {
        dispatch(showErrorSnackbar(t('common:error.default')));
      }
    },
    onMutate: () => {
      queryClient.cancelQueries({ queryKey: [SCOPE_CONTENTS.CONTENT, contentId] });
      isPutContentLoading.current = true;
    },
    onSettled: () => {
      isPutContentLoading.current = false;
    },
    onSuccess: ({ data }) => {
      const { html, title, pageFocusTopKeyword, metaDescription } = data;
      dispatch(setContentAction({ html, metaDescription, title }));
      dispatch(setFocusTopKeyword(pageFocusTopKeyword));
      setContent(data);
      if (!isAtomicContentGenerating) {
        atomicContentForceSaveRef.current = false;
      }
    },
    retry: false,
  });

  async function save({ content }) {
    if (
      isReadOnly ||
      (content.title === contentRef.current?.title &&
        content.html === contentRef.current?.html &&
        content.metaDescription === contentRef.current?.metaDescription)
    ) {
      return;
    }
    if (isPutContentLoading.current || hasSavingConflict) {
      return debouncedSave({ content });
    }
    putContentReq.mutate({
      content: { ...content, version: contentRef.current?.version },
    });
  }

  // Debounced save used in editor events.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(
    debounce(save, DEBOUNCE_SAVE_DELAY, { leading: false, trailing: true }),
    [isReadOnly]
  );

  // Override content from API with the local one (forced save).
  const { mutateAsync: overrideContent } = usePutContent({
    contentId,
    onError: () => {
      setHasSavingConflict(false);
      dispatch(showErrorSnackbar(t('common:error.default')));
    },
    onMutate: () => {
      isPutContentLoading.current = true;
      setIsResolvingConflict(true);
    },
    onSettled: () => {
      isPutContentLoading.current = false;
    },
    onSuccess: ({ data }) => {
      const { html, title, pageFocusTopKeyword, metaDescription } = data;
      if (!isAtomicContentGenerating) {
        dispatch(setContentAction({ html, metaDescription, title }));
        setInitialHtml({ lastSetAt: Date.now(), value: html });
        dispatch(setFocusTopKeyword(pageFocusTopKeyword));
        setContent(data);
      } else {
        setContentWithoutForceRender(data);
      }
      setIsResolvingConflict(false);
      setHasSavingConflict(false);
    },
  });

  async function reloadContentFromApi() {
    try {
      handleCancelAtomicContent();
      setIsResolvingConflict(true);
      debouncedSave.cancel?.();
      getContentReq.remove?.();
      const {
        data: { html, title, pageFocusTopKeyword, metaDescription },
      } = await getContentReq.refetch();
      dispatch(setContentAction({ html, metaDescription, title }));
      dispatch(setFocusTopKeyword(pageFocusTopKeyword));

      if (html != null) {
        setInitialHtml({ lastSetAt: Date.now(), value: html });
      }
      if (title != null) {
        setInitialTitle({ lastSetAt: Date.now(), value: title });
        handleSetTitle(title);
      }
      if (metaDescription != null) {
        handleSetMetaDescription(metaDescription);
      }
    } catch (e) {
      dispatch(showErrorSnackbar(t('common:error.default')));
    } finally {
      setIsResolvingConflict(false);
      setHasSavingConflict(false);
    }
  }

  async function handleOverrideContent() {
    const content = document.querySelector(CONTENT_SELECTOR).innerHTML;
    const title = document.querySelector(TITLE_SELECTOR).value;
    const metaDescription = document.querySelector(METADESCRIPTION_SELECTOR).value;
    if (isAtomicContentGenerating) {
      atomicContentForceSaveRef.current = true;
    }

    await overrideContent({
      content: {
        html: cleanHtml(content),
        metaDescription,
        title,
      },
    });

    getContentReq.refetch?.();
  }

  async function onConfirmRefresh() {
    await refreshDataAsync();
    refreshDialog.close();
  }

  function renderDialogs() {
    return (
      <>
        {hasSavingConflict && (
          <Dialog
            canCancelOnOutsideClick={false}
            cancelLabel={t('content:editor-container.conflict-dialog.cancel-label')}
            confirmLabel={t('content:editor-container.conflict-dialog.confirm-label')}
            hasCloseIcon={false}
            isLoading={isResolvingConflict}
            isOpen={true}
            title={t('content:editor-container.conflict-dialog.title')}
            onCancel={handleOverrideContent}
            onConfirm={reloadContentFromApi}
          >
            {t('content:editor-container.conflict-dialog.body')}
          </Dialog>
        )}
        {refreshDialog.isOpen && (
          <EditorSynchronizeDialog
            isLoading={isRefreshingData}
            isOpen={true}
            url={getContentReq?.data?.page?.url}
            onCancel={refreshDialog.close}
            onConfirm={onConfirmRefresh}
          />
        )}
        {sourceCodeDialog.isOpen && (
          <Suspense fallback={null}>
            <CodeModal
              cleanHtml={cleanHtml}
              close={sourceCodeDialog.close}
              html={contentRef.current?.html}
            />
          </Suspense>
        )}
      </>
    );
  }

  useEffect(() => {
    debouncedSave.cancel?.();
    getContentReq.remove?.();
    getVersionReq.remove?.();
    putContentReq.reset?.();
    content.current = null;
    getContentReq.refetch?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentId, versionId]);

  useEffect(() => {
    return () => {
      getContentReq.remove?.();
      getVersionReq.remove?.();
      putContentReq.reset?.();
      dispatch(resetContent());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // In getContentReq success, we set the local state of initialHtml
  // But because its async, getContentReq.isLoading is false before initialHtml has been updated
  // So we force value, to keep the loading state up
  useEffect(() => {
    if ((getContentReq.isLoading && !versionId) || (!!versionId && getVersionReq.isLoading)) {
      setInitialHtml({ lastSetAt: Date.now(), value: '' });
    }
  }, [getContentReq.isLoading, versionId, getVersionReq.isLoading]);

  usePrompt({
    bypassNavigationRegex:
      /.*\/o\/[a-fA-F0-9]{12}\/w\/[a-fA-F0-9]{12}\/p\/[a-fA-F0-9]{12}\/content\/[a-fA-F0-9]{12}\/.*/gm,
    message: t('content:editor-container.content-not-saved-message'),
    when: !getContentReq.isLoading && isContentDirty,
  });

  return {
    atomicContentForceSaveRef,
    content,
    debouncedSave,
    initialHtml,
    initialTitle,
    isLoading:
      (getContentReq.isLoading && !versionId) ||
      (!!versionId && getVersionReq.isLoading) ||
      !initialHtml.value,
    isReadOnly,
    isResolvingConflict,
    isSaveIdle: putContentReq.isIdle,
    isSaving: putContentReq.isLoading,
    openRefreshDialog: refreshDialog.open,
    openSourceCodeDialog: sourceCodeDialog.open,
    renderDialogs,
    save,
  };
}
