import React from 'react';

import SvgWrapper from './SvgWrapper';

const FileIcon = (props) => (
  <SvgWrapper {...props}>
    <path d="M9.667 0h-7.667v17h13v-11.692l-5.333-5.308zM10 1.742l3.273 3.258h-3.273v-3.258zM3 16v-15h6v5h5v10h-11z" />
  </SvgWrapper>
);

export default FileIcon;
