import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { UserSelect } from 'semji-core/components/UserSelect';
import { noop } from 'semji-core/utils/noop';

import { postInviteUsersOnWorkspace } from '@/apis/semji/api';
import useCan from '@/hooks/useCan';
import useNullUser from '@/hooks/useNullUser';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import { showSuccessSnackbar } from '@/store/actions/ui';
import { updateWorkspaceUsers } from '@/store/actions/workspace';
import { fetchCurrentOrganizationEditableData } from '@/store/actionsCreator/organization';
import { selectOrganizationMaxAllowedUsers } from '@/store/selectors/selectOrganizationMaxAllowedUsers';
import { selectOrganizationUsersCount } from '@/store/selectors/selectOrganizationUsersCount';
import { Log } from '@/utils/log/Log';

// maybe we can refactor this component to have a local state inside
function UserSelectRender({
  addDefaultUser = false,
  disabled = false,
  dropDownPlacement = 'bottom',
  dropDownSize,
  handleChange = noop,
  longVersion,
  options = [],
  isDropdownOpen = false,
  paddingValue = 50,
  querySelectorListener = '#root',
  shortVersion,
  small,
  value,
  variant = 'row',
  renderValue,
  centered = false,
  displayEmpty,
  width = 450,
  shortName = false,
  hovered = false,
}: {
  addDefaultUser?: boolean;
  disabled?: boolean;
  dropDownPlacement: 'top' | 'bottom' | 'left' | 'right';
  dropDownSize: string;
  isDropdownOpen: boolean;
  handleChange?: (...args: any) => any;
  longVersion?: boolean;
  options: any[];
  shortVersion?: boolean;
  small?: boolean;
  value: any;
  variant: 'row' | 'column';
  renderValue: () => any;
  centered?: boolean;
  displayEmpty?: boolean;
  width: number | string;
  shortName?: boolean;
  hovered?: boolean;
  paddingValue?: number;
  querySelectorListener?: string;
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const nullUser = useNullUser();
  const { organizationId, workspaceId } = useParams();
  const maxAllowedUsers = useSelector(selectOrganizationMaxAllowedUsers);
  const usersCountOrganization = useSelector(selectOrganizationUsersCount);
  const currentWorkspaceName = useSelector(
    (state: any) => state.workspaces.byId[workspaceId]?.name
  );
  const { isFeatureEnabled: isUnlimitedInvitationsEnabled } = useOrganizationFeatureSet(
    'users:invitations:has-unlimited-amount'
  );
  const canInviteUsersAsOwner = useCan({
    perform: 'invite-users:exec',
  });

  const apiRootUrl = import.meta.env.VITE_REACT_APP_SEMJI_API_ROOT_URL;

  const isOrganizationUsersLimitAchieved =
    !isUnlimitedInvitationsEnabled && usersCountOrganization >= maxAllowedUsers;
  const isWorkspaceUsersLimitAchieved =
    !isUnlimitedInvitationsEnabled && options.length >= maxAllowedUsers;

  const selectedItem = options.find((o) => o.id === value) || (displayEmpty ? null : nullUser);

  async function handleSelectedItem(value: any) {
    handleChange(value?.id);
  }

  async function handleInvite(email: string) {
    try {
      const existingUser = options.find((user) => user.email === email);

      if (!!existingUser) {
        await handleSelectedItem(existingUser);
      } else {
        await postInviteUsersOnWorkspace(workspaceId, [{ email }]);
        const user = (await dispatch(updateWorkspaceUsers(workspaceId))).find(
          (user: any) => user.email === email
        );
        await handleSelectedItem(user);
        dispatch(
          showSuccessSnackbar(
            t('components:select.user-select.invite-success-snackbar', { currentWorkspaceName })
          )
        );
      }
    } catch (e) {
      Log.error({ e });
    } finally {
      dispatch(fetchCurrentOrganizationEditableData(organizationId));
    }
  }

  return (
    <UserSelect
      addDefaultUser={addDefaultUser}
      apiRootUrl={apiRootUrl}
      centered={centered}
      disabled={disabled}
      displayEmpty={displayEmpty}
      dropDownPlacement={dropDownPlacement}
      dropDownSize={dropDownSize}
      hovered={hovered}
      inviteUser={handleInvite}
      isDropdownOpen={isDropdownOpen}
      isFrame={false}
      isInvitationDisabled={
        isOrganizationUsersLimitAchieved || isWorkspaceUsersLimitAchieved || !canInviteUsersAsOwner
      }
      longVersion={longVersion}
      paddingValue={paddingValue}
      querySelectorListener={querySelectorListener}
      setValue={handleSelectedItem}
      shortName={shortName}
      shortVersion={shortVersion}
      small={small}
      translations={{
        button: t('components:dialog.invite-users.button-send-invitations'),
        invalid_email: t('components:select.user-select.invalid-email'),
        invite_user: t('components:select.user-select.button-invite-users-via-email'),
        invite_user_custom: t('components:select.user-select.button-invite-users-via-email-custom'),
        loading: t('components:dialog.invite-users.button-inviting'),
        pending_invite: t('components:settings.users-settings.pending-invite'),
        placeHolder: t('components:dialog.invite-users.emails'),
        search_placeholder: t('components:select.user-select.search-placeholder'),
        tooltip_disabled: !canInviteUsersAsOwner
          ? t('components:select.user-select.tootlip-only-workspace-owner')
          : t('components:select.user-select.tooltip-limit-user'),
        unassigned: t('common:user-picker.unassigned'),
      }}
      users={options}
      value={selectedItem}
      variant={variant}
      width={width}
      withInvite={true}
    />
  );
}

export default UserSelectRender;
