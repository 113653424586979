import './Radio.scss';

import Radio from '@material-ui/core/Radio';
import React from 'react';

function RadioItem({
  checked,
  onClick,
  label,
}: {
  checked: boolean;
  onClick: () => void;
  label: string | React.ReactNode;
}) {
  return (
    <div className="competitors-radio__content__list__item" onClick={onClick}>
      <span>
        <Radio
          checked={checked}
          className="competitors-radio__content__list__item__radio"
          onChange={onClick}
        />
      </span>
      <span className="competitors-radio__content__list__item__label">{label}</span>
    </div>
  );
}

export default RadioItem;
