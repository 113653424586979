import i18next from 'i18next';
import React from 'react';
import { PercentIcon } from 'semji-core/icons/PercentIcon';
import { PositionIcon } from 'semji-core/icons/PositionIcon';

import { CompetitorReportMetric, CompetitorWebsite } from '@/types/competitors';

export const COMPETITOR_COLORS = [
  { backgroundColor: '#6DC1F01A', color: '#6DC1F0' },
  { backgroundColor: '#ACD7311A', color: '#ACD731' },
  { backgroundColor: '#1F45941A', color: '#1F4594' },
  { backgroundColor: '#2FD3A21A', color: '#29C8BA' },
  { backgroundColor: '#4D90F41A', color: '#4D90F4' },
  { backgroundColor: '#FFAC341A', color: '#FFAC34' },
  { backgroundColor: '#43BC651A', color: '#43BC65' },
  { backgroundColor: '#ABAEED1A', color: '#ABAEED' },
  { backgroundColor: '#EAD3021A', color: '#EAD302' },
  { backgroundColor: '#33EEDD1A', color: '#33EEDD' },
];

export const commonDomainSuffixes = [
  '.fr',
  '.com',
  '.net',
  '.org',
  '.info',
  '.co.uk',
  '.co',
  '.de',
  '.es',
  '.ca',
];

export const DEFAULT_COLOR = '#FF7183';

export const getReportFormatValues = () => {
  return [
    {
      title: <PositionIcon />,
      tooltip: i18next.t('competitors:toggle.value'),
      value: 'value',
    },
    {
      title: <PercentIcon />,
      tooltip: i18next.t('competitors:toggle.percentage'),
      value: 'percentage',
    },
  ];
};

export type CompetitorReportMetricFiltered = Omit<CompetitorReportMetric, 'id'>;
export type CompetitorReportChartCategory = Omit<CompetitorWebsite, 'id'> & { name: string };

export const KEYWORD_POSITION_LIST = [
  {
    title: 'Top 11-100',
    value: 'top100',
  },
  {
    title: 'Top 4-10',
    value: 'top10',
  },
  {
    title: 'Top 1-3',
    value: 'top3',
  },
];

export const ENUM_TAIL_KEYWORD = {
  LONG: 'long-tail',
  SHORT: 'short-tail',
};

export const COMPETITOR_FILTER_QUERY_PARAM = 'competitorWebsiteId';
export const CATEGORY_FILTER_QUERY_PARAM = 'category';
export const CLUSTER_FILTER_QUERY_PARAM = 'cluster';
export const ANALYZED_DATA_DATE_FILTER_QUERY_PARAM = 'report-date';
export const TAIL_TRAFFIC_TYPE_FILTER_QUERY_PARAM = 'isLongTail';
export const FUNNEL_STAGE_FILTER_QUERY_PARAM = 'funnel';
export const PERSONA_FILTER_QUERY_PARAM = 'persona';
export const FIRST_SEEN_DATE_FILTER_QUERY_PARAM = 'firstSeenAt';
export const RETRIEVAL_DATE_FILTER_QUERY_PARAM = 'date';
export const BRAND_TRAFFIC_TYPE_FILTER_QUERY_PARAM = 'isBranded';
export const URL_POSITION_FILTER_QUERY_PARAM = 'urlPosition';
export const RANKED_WORKSPACE_URL_POSITION_QUERY_PARAM = 'rankedWorkspaceUrlPosition';

export enum COMPETITOR_FILTER_QUERY_PARAMS {
  COMPETITOR_WEBSITE_ID = COMPETITOR_FILTER_QUERY_PARAM,
  CATEGORY = CATEGORY_FILTER_QUERY_PARAM,
  CLUSTER = CLUSTER_FILTER_QUERY_PARAM,
  REPORT_DATE = ANALYZED_DATA_DATE_FILTER_QUERY_PARAM,
  IS_LONG_TAIL = TAIL_TRAFFIC_TYPE_FILTER_QUERY_PARAM,
  FUNNEL = FUNNEL_STAGE_FILTER_QUERY_PARAM,
  PERSONA = PERSONA_FILTER_QUERY_PARAM,
  FIRST_SEEN_AT = FIRST_SEEN_DATE_FILTER_QUERY_PARAM,
  RETRIEVAL_DATE = RETRIEVAL_DATE_FILTER_QUERY_PARAM,
  IS_BRANDED = BRAND_TRAFFIC_TYPE_FILTER_QUERY_PARAM,
}

export const SEPARATOR_QUERY_PARAM = ',';

export enum COMPETITORS_METRIC_TYPES {
  keyword = 'keyword',
  page = 'nbPages',
  traffic = 'traffic',
  unbranded = 'unbrandedTraffic',
}

export enum SEARCH_INTELLIGENCE_PAGES {
  report = 'reports',
  competition = 'contents-duel',
  dataExplorer = 'data-explorer',
}

export const FIRST_MONTH_OF_YEAR = 1;

export const COMPETITOR_NORMALIZATION_SUCCESS_STATUS = 'SUCCESS';
