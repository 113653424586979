import { CLASS_ATTRIBUTE_NAME, HIGHLIGHTER_TAG_NAME } from '../constants';

function createHighlight({
  textContent,
  datum,
  highlightClass,
  dataAttribute,
}: {
  textContent: string;
  datum: string;
  highlightClass: string;
  dataAttribute: string;
}): Element {
  const highlight = document.createElement(HIGHLIGHTER_TAG_NAME);

  highlight.setAttribute(CLASS_ATTRIBUTE_NAME, highlightClass);
  highlight.dataset[dataAttribute] = datum;
  highlight.textContent = textContent;

  return highlight;
}

export default createHighlight;
