import styled from 'styled-components/macro';

const RequiredBadge = styled.span`
  font-weight: ${(props) => props.theme.textCss.weights.normal};
  font-size: ${(props) => props.theme.textCss.sizes.xs};
  color: ${(props) => props.theme.cssColors.primary};
  background-color: ${(props) => props.theme.cssColors.primary010};
  border-radius: 25px;
  padding: 5px 15px;
`;

export default RequiredBadge;
