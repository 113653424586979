import React from 'react';

import Placeholder from '@/assets/images/image-placeholder.svg';

export function PageItem({ hit, components }) {
  return (
    <div className="aa-ItemContent">
      <div className="aa-ItemIcon--picture">
        <img alt="page" src={hit.image_url ?? Placeholder} />
      </div>
      <div className="aa-ItemContentBody">
        <div className="aa-ItemContentTitle">
          <components.Highlight attribute="title" hit={hit} />
          <span className="aa-dotSeparator">•</span>
          <components.Highlight attribute="focus_keyword" hit={hit} />
        </div>
        <div className="aa-ItemContentDescription">
          <components.Snippet attribute="url" hit={hit} />
        </div>
      </div>
    </div>
  );
}
