import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { fetchWorkspaceStatus } from '@/apis/semji/api';
import { SECTIONS } from '@/utils/log/constants';
import { Log } from '@/utils/log/Log';

/**
 * Custom hook to fetch users and content statuses.
 *
 * @returns {{users: Object, statuses: Object}} Object containing users and statuses
 */
function useWorkspaceStatuses() {
  const { workspaceId } = useParams();
  const [statuses, setStatuses] = useState([]);
  const cancelFetchWorkspaceStatus = useRef(null);

  const fetchStatus = async () => {
    let status = [];

    try {
      cancelFetchWorkspaceStatus.current = fetchWorkspaceStatus(workspaceId);
      status = await cancelFetchWorkspaceStatus.current;
      status = status['hydra:member'];
      // telling that the last value means a publish action
      status[status.length - 1].publish = true;

      setStatuses(status);
    } catch (error) {
      Log.report({
        context: 'fetchStatus',
        error,
        extra: 'fetch status',
        section: SECTIONS.organization.key,
      });
    }

    return status;
  };

  /** Effects */

  useEffect(() => {
    fetchStatus();

    return () => {
      cancelFetchWorkspaceStatus.current?.cancel?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId]);

  return statuses;
}

export default useWorkspaceStatuses;
