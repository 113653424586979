import SvgWrapper from '@/components/icons/SvgWrapper';

function LockRoundIcon(props) {
  return (
    <SvgWrapper {...props}>
      <circle cx="8" cy="8.5" fill="#FFEAE3" r="8" />
      <path
        d="M10.6667 6.87037H10.2222V5.93916C10.2222 4.65408 9.22668 3.61111 8.00001 3.61111C6.77335 3.61111 5.77779 4.65408 5.77779 5.93916V6.87037H5.33335C4.84446 6.87037 4.44446 7.28942 4.44446 7.80159V12.4577C4.44446 12.9698 4.84446 13.3889 5.33335 13.3889H10.6667C11.1556 13.3889 11.5556 12.9698 11.5556 12.4577V7.80159C11.5556 7.28942 11.1556 6.87037 10.6667 6.87037ZM8.00001 11.0608C7.51112 11.0608 7.11112 10.6418 7.11112 10.1296C7.11112 9.61746 7.51112 9.19842 8.00001 9.19842C8.4889 9.19842 8.8889 9.61746 8.8889 10.1296C8.8889 10.6418 8.4889 11.0608 8.00001 11.0608ZM9.37779 6.87037H6.62224V5.93916C6.62224 5.14297 7.24001 4.49577 8.00001 4.49577C8.76001 4.49577 9.37779 5.14297 9.37779 5.93916V6.87037Z"
        fill="#FF8F68"
      />
    </SvgWrapper>
  );
}

export default LockRoundIcon;
