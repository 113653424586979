import {
  CLOSE_CREDIT_LIMIT_DIALOG,
  CLOSE_SIDE_PANEL,
  OPEN_CREDIT_LIMIT_DIALOG,
  OPEN_SIDE_PANEL,
  RESET_REPORT,
  SET_FOCUS_TOP_KEYWORD,
  SET_REPORT,
  TOGGLE_REPORT_MAIN_RECOMMENDATION,
  TOGGLE_REPORT_RECOMMENDATION,
  TOGGLE_SIDE_PANEL,
  UPDATE_FOCUS_TOP_KEYWORD,
} from '@/store/actions/actionTypes';

export const refreshCurrentReport =
  (report, initialSet = false) =>
  (dispatch) =>
    dispatch({
      initialSet,
      report,
      type: SET_REPORT,
    });

export const resetCurrentReportScore = () => (dispatch) => {
  dispatch({
    type: RESET_REPORT,
  });
};

export const toggleCurrentReportMainRecommendations = (key) => (dispatch) => {
  dispatch({
    key,
    type: TOGGLE_REPORT_MAIN_RECOMMENDATION,
  });
};

export const toggleCurrentReportRecommendation = (mainKey, key) => (dispatch) => {
  dispatch({
    key,
    mainKey,
    type: TOGGLE_REPORT_RECOMMENDATION,
  });
};

export const closeSidePanel = () => (dispatch) => {
  dispatch({
    type: CLOSE_SIDE_PANEL,
  });
};

export const openSidePanel = () => (dispatch) => {
  dispatch({
    type: OPEN_SIDE_PANEL,
  });
};

export const toggleSidePanel = () => (dispatch) => {
  dispatch({
    type: TOGGLE_SIDE_PANEL,
  });
};

export const openCreditLimitDialog = (creditType) => (dispatch) => {
  dispatch({
    payload: {
      creditType,
    },
    type: OPEN_CREDIT_LIMIT_DIALOG,
  });
};

export const closeCreditLimitDialog = () => (dispatch) => {
  dispatch({
    type: CLOSE_CREDIT_LIMIT_DIALOG,
  });
};

export const setFocusTopKeyword = (focusTopKeyword) => (dispatch) => {
  dispatch({
    focusTopKeyword,
    type: SET_FOCUS_TOP_KEYWORD,
  });
};

export const updateFocusTopKeyword = (analyzed) => (dispatch) => {
  dispatch({
    analyzed,
    type: UPDATE_FOCUS_TOP_KEYWORD,
  });
};
