import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogModal } from 'semji-core/components/DialogModal';
import styled from 'styled-components/macro';

import FlashMessage from '@/components/FlashMessage/FlashMessage';
import { InlineText, TextAuto } from '@/components/Text/Inline';

const TextArea = styled.textarea`
  height: auto;
  width: 100%;
  font-size: ${(props) => props.theme.textCss.sizes.sm};
  color: ${(props) =>
    props.haveText ? props.theme.cssColors.dark100 : props.theme.cssColors.dark040};
  border: solid 1px ${(props) => props.theme.cssColors.dark040};
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
`;
const StyledBlockText = styled(TextAuto)`
  && {
    margin-top: ${({ marginTop }) => (marginTop ? marginTop : '0px')};
    margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : '0px')};
  }
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ul {
    line-height: 11px;
    list-style-type: disc;
    padding-left: 10px;
  }

  h2,
  h3 {
    font-size: 16px;
  }
`;

function FlagContentDialog({ flaggedContent = {}, isOpen, onCancel, onConfirm, isLoading }) {
  const { t } = useTranslation();
  const { id, text, type } = flaggedContent;
  const [report, setReport] = useState('');

  function handleCancel(e) {
    onCancel(e);
    setReport('');
  }

  function handleConfirm() {
    onConfirm({ id, report, type });
    setReport('');
  }

  function handleChangeReportText(e) {
    setReport(e.target.value);
  }

  return (
    <DialogModal
      cancelAction={handleCancel}
      cancelLabel={t('content:brief.flag-content-dialog-cancel-label')}
      canClickOutsideToClose
      closable
      confirmAction={handleConfirm}
      confirmLabel={t('content:brief.flag-content-dialog-confirm-label')}
      isCancelDisabled={isLoading}
      isConfirmDisabled={isLoading}
      isOpen={isOpen}
      title={t('content:brief.flag-content-dialog-title')}
      onClose={handleCancel}
    >
      <StyledBlockText color="dark080" marginBottom="20px" size="default" weight="medium">
        {t('content:brief.flag-content-dialog-description')}
      </StyledBlockText>
      <FlashMessage halign="left" padding="10px">
        <StyledContent
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
      </FlashMessage>

      <StyledBlockText
        color="dark080"
        marginBottom="10px"
        marginTop="20px"
        size="md"
        weight="medium"
      >
        {t('content:brief.flag-content-dialog-message')}&nbsp;
        <InlineText color="dark040" weight="medium">
          {t('content:brief.flag-content-dialog-message-recommended')}
        </InlineText>
      </StyledBlockText>

      <TextArea
        haveText={report.length > 0}
        placeholder={t('content:brief.flag-content-dialog-textarea-placeholder')}
        rows="5"
        value={report}
        onChange={handleChangeReportText}
      ></TextArea>
    </DialogModal>
  );
}

export default FlagContentDialog;
