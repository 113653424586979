import { MutationResult } from '@/apis/types/mutation.types';

export enum ENUM_COMPLETION_TOKEN_TYPE {
  TYPE_ATOMIC_CONTENT = 'TYPE_ATOMIC_CONTENT',
  TYPE_TITLE = 'TYPE_TITLE',
  TYPE_OUTLINES = 'TYPE_OUTLINES',
  TYPE_META_DESCRIPTION = 'TYPE_META_DESCRIPTION',
  TYPE_INTRODUCTION = 'TYPE_INTRODUCTION',
  TYPE_PARAGRAPH = 'TYPE_PARAGRAPH',
}

export interface CompletionTokenParams {
  topKeywordId: string;
  type: ENUM_COMPLETION_TOKEN_TYPE;
  aiFact?: boolean;
  aiBrandVoice?: boolean;
}

export type UseGenerateCompletionTokenResults = MutationResult<CompletionTokenParams>;
