import './OutgoingLink.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Anchor } from 'semji-core/components/AnchorLink';
import { Placements } from 'semji-core/hooks/usePopover';
import { copyToClipboard } from 'semji-core/utils/clipboard';

import { SerpInstruction } from '@/components/Optimization/Instruction';
import { OutgoingLinkItemProps } from '@/containers/Content/SidePanel/Components/Optimization/Section/OutgoingLinks/OutgoingLinks.types';
import { showSuccessSnackbar } from '@/store/actions/ui';
import {
  disableRecommendationOptimization,
  enableRecommendationOptimization,
} from '@/store/actionsCreator/report';

function OutgoingLink({
  recommendationId,
  onToggle,
  focusTopKeywordId,
  mainId,
  disabled,
}: OutgoingLinkItemProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const html = useSelector((state) => state.content.html);
  const outgoingLink = useSelector((state) => state.report.recommendations[recommendationId]);
  const title = useSelector((state) => state.content.title);
  const score = useSelector((state) => state.report.recommendations[recommendationId].score);

  function toggleOutgoingLink() {
    if (!outgoingLink.disabled) {
      dispatch(
        disableRecommendationOptimization(recommendationId, mainId, focusTopKeywordId, html, title)
      );
    } else {
      dispatch(
        enableRecommendationOptimization(recommendationId, mainId, focusTopKeywordId, html, title)
      );
    }
    onToggle(recommendationId, outgoingLink.disabled);
  }

  function handleCopy() {
    copyToClipboard(outgoingLink.data.url);
    dispatch(showSuccessSnackbar(t('common:notifications.copy-to-clipboard')));
  }

  if (!outgoingLink) {
    return null;
  }

  return (
    <SerpInstruction
      completed={score === 1}
      description={
        <Anchor
          anchor={outgoingLink.data.anchor}
          tooltipPlacement={Placements.Bottom}
          tooltipTitle={t('components:optimization.instruction.outgoing-links.tooltip')}
        />
      }
      disabled={disabled || outgoingLink.disabled}
      title={outgoingLink.data.title}
      url={outgoingLink.data.url}
      onCopy={handleCopy}
      onToggle={toggleOutgoingLink}
    />
  );
}

export default OutgoingLink;
