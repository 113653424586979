import './ReloadPrompt.scss';

import MuiDialog from '@material-ui/core/Dialog';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'semji-core/components/Button';
import semver from 'semver/preload';

import useApiConfigurations from '@/hooks/useApiConfigurations';
import { SW_VERSION_MANDATORY, SW_VERSION_RECOMMENDED } from '@/utils/configurations/constants';

function ReloadPrompt() {
  const { t } = useTranslation();
  const currentVersion = import.meta.env.VITE_REACT_APP_VERSION;
  const recommendedVersion: string = useApiConfigurations(SW_VERSION_RECOMMENDED);
  const mandatoryVersion: string = useApiConfigurations(SW_VERSION_MANDATORY);

  function isVersionObsolete(version: string, configVersion: string) {
    const currentV = semver.valid(version);
    const configV = semver.valid(configVersion);

    // Hard refresh is disabled when current version isn't a release version
    // Avoid trigger block modal on branch deployment
    if (!configV || !currentV) {
      // eslint-disable-next-line no-console
      console.warn('Mandatory or recommended configuration is not formatted correctly');
      return false;
    }

    return semver.lt(currentV, configV);
  }

  function forceReload() {
    window.location.reload(true);
  }

  if (isVersionObsolete(currentVersion, mandatoryVersion)) {
    return (
      <MuiDialog fullWidth open={true}>
        <div className="reload-modal">
          <div className="reload-modal__text">
            <span>{t('components:reload-prompt.title')}</span>
          </div>
          <div className="reload-modal__actions">
            <Button onClick={forceReload}>{t('components:reload-prompt.reload')}</Button>
          </div>
        </div>
      </MuiDialog>
    );
  }

  return (
    <div className="reload-prompt">
      {isVersionObsolete(currentVersion, recommendedVersion) && (
        <div className="reload-prompt__body">
          <div className="reload-prompt__content">
            <div>
              <span>{t('components:reload-prompt.title')}</span>
            </div>
            <div>
              <Button onClick={forceReload}>{t('components:reload-prompt.reload')}</Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ReloadPrompt;
