import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import InstructionsError from '@/assets/images/instructions_error.svg';
import {
  FlexColumnCenteredAlignContainer,
  FlexGrowColumnCenteredContainer,
} from '@/components/_common';
import { FormBox, Link } from '@/components/Box/Box';
import { ONLY_LOGO_WHITE_BACKGROUND } from '@/components/Layout/Layout';
import { ParagraphText } from '@/components/Text/Paragraph';
import { SecondaryTitleTwo } from '@/components/Text/Title';
import LayoutWrapper from '@/containers/Layout/Layout';

const StyledSecondaryTitleTwo = styled(SecondaryTitleTwo)`
  && {
    margin: 10px 0px;
  }
`;
const StyledParagraphText = styled(ParagraphText)`
  && {
    max-width: 'none';
    text-align: 'unset';
    margin-bottom: 10px;
  }
`;

function OopsScreen({ pageTitle, error }) {
  const { t } = useTranslation();

  return (
    <LayoutWrapper menu={ONLY_LOGO_WHITE_BACKGROUND}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <FlexGrowColumnCenteredContainer>
        <FormBox>
          <FlexColumnCenteredAlignContainer>
            <img alt={t('create-account:verify-email.errors.image-alt')} src={InstructionsError} />
            <br />
            <StyledSecondaryTitleTwo color="red" noMargin weight="medium">
              {t('create-account:verify-email.errors.oops')}
            </StyledSecondaryTitleTwo>
            <StyledParagraphText color="dark060" weight="medium">
              {error}
            </StyledParagraphText>
            <Link to="/login">{t('create-account:verify-email.errors.return-to-sign-in')}</Link>
          </FlexColumnCenteredAlignContainer>
        </FormBox>
      </FlexGrowColumnCenteredContainer>
    </LayoutWrapper>
  );
}

export default OopsScreen;
