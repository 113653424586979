/* eslint-disable sort-keys-fix/sort-keys-fix */
import removeDiacritics from '@/utils/removeDiacritics';

const FILTERS_FUNCTIONS = {
  // Numbers Filters
  gt: (a, b) => parseFloat(a) > parseFloat(b),
  gte: (a, b) => parseFloat(a) >= parseFloat(b),
  lt: (a, b) => parseFloat(a) < parseFloat(b),
  lte: (a, b) => parseFloat(a) <= parseFloat(b),
  btw: (a, b, c) => parseFloat(a) >= parseFloat(b) && parseFloat(a) <= parseFloat(c),
  // String, numbers, date
  eq: (a, b) => {
    if (a === null && b === null) return true;
    if ((!a || !b) && a !== 0 && b !== 0) return false;
    const value1 = removeDiacritics(a.toString());
    const value2 = removeDiacritics(b.toString());
    return value1.toLowerCase() === value2.toLowerCase();
  },
  not_eq: (a, b) => {
    if (a === null && b === null) return false;
    if (!a || !b) return true;
    const value1 = removeDiacritics(a.toString());
    const value2 = removeDiacritics(b.toString());
    return value1.toLowerCase() !== value2.toLowerCase();
  },
  // Strings
  contains: (a, b) => {
    if (!a || !b) return false;
    const value1 = removeDiacritics(a.toString());
    const value2 = removeDiacritics(b.toString());
    return value1.toLowerCase().includes(value2.toLowerCase());
  },
  not_contains: (a, b) => {
    if (!a || !b) return true;
    const value1 = removeDiacritics(a.toString());
    const value2 = removeDiacritics(b.toString());
    return !value1.toLowerCase().includes(value2.toLowerCase());
  },
  // Date
  after: (a, b) => {
    if (!a || !b) return false;
    if (new Date(a).getTime() > new Date(b).getTime()) return true;
    return false;
  },
  before: (a, b) => {
    if (!a || !b) return false;
    if (new Date(a).getTime() < new Date(b).getTime()) return true;
    return false;
  },
  exist: (a, b) => {
    return ![null, undefined].includes(a) && ![null, undefined].includes(b);
  },
  not_exist: (a, b) => {
    return [null, undefined].includes(a) && [null, undefined].includes(b);
  },
};

export default function filterPages(pages, filters) {
  return pages.filter((row) => {
    const truthTable = filters.filterGroups.map((group) => {
      // no sub groups for the moment
      const filter = group.filters[0];
      const filterFunction = FILTERS_FUNCTIONS[filter.comparison.comparison];
      if (filter.values.length === 0) return true;
      const flattenRow = {
        ...row.page,
        ...row.pageFocusTopKeyword,
        ...row.contentStatus,
        ...row.focusTopKeyword,
        ...row.extra,
        ...row.assignedTo,
        ...row.draft,
        ...row,
      };
      const valueToCompareWith =
        filter.getValueByAttribute?.(flattenRow, filter.attribute) ?? flattenRow[filter.attribute];
      // when the value to compare with is an array, and it is a negative comparison, we need to select all that matches the condition
      if (
        Array.isArray(valueToCompareWith) &&
        ['not_eq', 'not_exist', 'not_contains'].includes(filter.comparison.comparison)
      ) {
        return valueToCompareWith.every((value) =>
          filterFunction(
            value === null ? null : value || '',
            filter.values[0]?.value,
            filter.values[1]?.value
          )
        );
      }

      // when the value to compare with is an array, we try to find one that matches
      if (Array.isArray(valueToCompareWith)) {
        return valueToCompareWith.some((value) =>
          filterFunction(
            value === null ? null : value || '',
            filter.values[0]?.value,
            filter.values[1]?.value
          )
        );
      }

      return filterFunction(
        valueToCompareWith === undefined ? '' : valueToCompareWith,
        filter.values[0]?.value,
        filter.values[1]?.value
      );
    });

    if (truthTable.length === 0) return true;
    if (filters.operator === 'AND') {
      return truthTable.every((truth) => truth);
    }
    return truthTable.some((truth) => truth);
  });
}
