import { Accordion as MUIAccordion } from '@material-ui/core/';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import {
  FlexColumnContainer,
  FlexContainer,
  FlexJustifiedBetweenAndAlignCenterContainer,
  FlexVerticalAlignedContainer,
} from '@/components/_common';
import CaretIcon from '@/components/icons/CaretIcon';
import ValidatedIcon from '@/components/icons/ValidatedIcon';
import { Divider } from '@/components/Profile/ProfileBox';
import CircularProgressBar from '@/components/Rating/CircularProgressBar';
import { Bold } from '@/containers/Content/EditorComponents/Nodes';
import { StyledParagraphText } from '@/containers/Onboarding/common';
import ColorComputer from '@/hoc/ColorComputer';
import defaultTheme from '@/themes/defaultTheme';

const AccordionWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? '20px' : '0')};
`;
const StyledValidatedIcon = styled(ValidatedIcon)`
  && {
    font-size: ${({ size }) => size};
  }
`;
const BorderedNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.8px solid ${({ theme }) => theme.cssColors.dark080};
  border-radius: 100%;
  height: 20px;
  width: 20px;
`;
const StyledCaretIcon = styled(CaretIcon)`
  && {
    transform: ${({ open }) => (open ? 'rotate(0deg)' : 'rotate(-90deg)')};
  }
`;
const FirstAccordionSummary = styled(AccordionSummary)`
  && {
    height: 78px;
    box-sizing: border-box;
    background-color: white;
    border-radius: 3px;
    padding: 20px 30px;
    & > div {
      display: flex;
      margin: 10px 0;
    }
  }
`;
const SecondAccordionSummary = styled(AccordionSummary)`
  && {
    height: 68px;
    box-sizing: border-box;
    background-color: white;
    padding: 20px 30px 20px 35px;
    & > div {
      display: flex;
      margin: 10px 0;
    }
  }
`;
const StyledAccordionDetails = styled(AccordionDetails)`
  && {
    padding: 0;
    flex-wrap: wrap;
    background-color: white;
  }
`;
const BorderedNumberWrapper = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const HeaderWrapper = styled(FlexJustifiedBetweenAndAlignCenterContainer)`
  && {
    opacity: ${({ isOpac }) => (isOpac ? '0.4' : '1')};
  }
`;

const useStylesMUIAccordion = ({ isChildren }) =>
  makeStyles({
    expanded: {},
    root: {
      '&$expanded': {
        margin: '0',
      },
      '&:before': {
        height: '0',
      },
      background: 'transparent',
      border: '0',
      boxShadow: isChildren ? 'none' : '0px 3px 10px rgba(37, 39, 54, 0.1)',
    },
  })();

const StepChildrenHeader = ({ title, validated, number, isExpanded }) => {
  const { t } = useTranslation();

  return (
    <HeaderWrapper isOpac={validated && !isExpanded}>
      <FlexVerticalAlignedContainer>
        {validated ? (
          <StyledValidatedIcon color={defaultTheme.cssColors.lightGreen} size="28px" />
        ) : (
          <BorderedNumberWrapper>
            <BorderedNumber>{number}</BorderedNumber>
          </BorderedNumberWrapper>
        )}
        <StyledParagraphText color="dark100" margin="21px" noPadding weight="strong">
          {t(title)}
        </StyledParagraphText>
      </FlexVerticalAlignedContainer>
      <StyledCaretIcon open={isExpanded} />
    </HeaderWrapper>
  );
};

const ChildrenAccordion = ({
  children,
  index,
  accordionLength,
  expandedChildrenId,
  setExpandedChildrenId,
}) => {
  const classes = useStylesMUIAccordion({ isChildren: true });

  return (
    <>
      <MUIAccordion
        key={children.id}
        classes={{ ...classes }}
        expanded={children.id === expandedChildrenId}
        TransitionProps={{ unmountOnExit: true }}
        onChange={() =>
          setExpandedChildrenId(children.id === expandedChildrenId ? null : children.id)
        }
      >
        <SecondAccordionSummary expandIcon={null}>
          <StepChildrenHeader
            isExpanded={children.id === expandedChildrenId}
            number={index + 1}
            title={children.title}
            validated={children.validated}
          />
        </SecondAccordionSummary>
        <StyledAccordionDetails>{children.childrenRender}</StyledAccordionDetails>
      </MUIAccordion>
      {accordionLength - 1 !== index && <Divider color={defaultTheme.cssColors.dark005} />}
    </>
  );
};

const StepHeader = ({ numberOfTask, numberOfValidatedTask, title, isExpanded }) => {
  const { t } = useTranslation();
  const percentage = (numberOfValidatedTask * 100) / numberOfTask;

  return (
    <HeaderWrapper isOpac={percentage === 100 && !isExpanded}>
      <FlexContainer>
        {percentage === 100 ? (
          <StyledValidatedIcon color={defaultTheme.cssColors.lightGreen} size="40px" />
        ) : (
          <ColorComputer value={percentage}>
            {({ color }) => (
              <CircularProgressBar
                backgroundColor={defaultTheme.cssColors.dark005}
                color={color}
                percentage={percentage}
              />
            )}
          </ColorComputer>
        )}
        <StyledParagraphText color="dark100" margin="13px" noPadding size="md" weight="strong">
          {t(title)}
        </StyledParagraphText>
      </FlexContainer>
      {percentage === 100 ? (
        <StyledParagraphText color="lightGreen" noPadding weight="strong">
          {t('quick-start-guide:body.stepper.steps.completed')}
        </StyledParagraphText>
      ) : (
        <StyledParagraphText color="dar060" noPadding weight="medium">
          <Bold>
            {t('quick-start-guide:body.stepper.steps.advancement_interval', {
              count: numberOfTask - numberOfValidatedTask,
              postProcess: 'interval',
            })}
          </Bold>
        </StyledParagraphText>
      )}
    </HeaderWrapper>
  );
};

const Accordion = ({
  accordion,
  index,
  stepsListLength,
  expandedId,
  expandedChildrenId,
  setExpandedId,
  setExpandedChildrenId,
}) => {
  const classes = useStylesMUIAccordion({ isChildren: false });

  return (
    <AccordionWrapper marginBottom={index === stepsListLength - 1}>
      <MUIAccordion
        key={accordion.id}
        classes={{ ...classes }}
        expanded={accordion.id === expandedId}
        TransitionProps={{ unmountOnExit: true }}
        onChange={() => {
          setExpandedChildrenId(null);
          setExpandedId(accordion.id === expandedId ? null : accordion.id);
        }}
      >
        <FirstAccordionSummary expandIcon={null}>
          <StepHeader
            isExpanded={accordion.id === expandedId}
            numberOfTask={accordion.children.length || 1}
            numberOfValidatedTask={
              accordion.children.length
                ? accordion.children.filter((elem) => elem.validated).length
                : +accordion.validated
            }
            title={accordion.title}
          />
        </FirstAccordionSummary>
        <StyledAccordionDetails>
          {accordion.children.length ? (
            <FlexColumnContainer>
              {accordion.children.map((children, index) => (
                <ChildrenAccordion
                  children={children}
                  key={children.id}
                  accordionLength={accordion.children.length}
                  expandedChildrenId={expandedChildrenId}
                  index={index}
                  setExpandedChildrenId={setExpandedChildrenId}
                />
              ))}
            </FlexColumnContainer>
          ) : (
            <FlexColumnContainer>{accordion.render}</FlexColumnContainer>
          )}
        </StyledAccordionDetails>
      </MUIAccordion>
    </AccordionWrapper>
  );
};

export default Accordion;
