import { useMutation } from '@tanstack/react-query';

import apiClient from '@/apis/semji/apiClient';
import { AI_WRITING_CREDITS_TYPE } from '@/utils/constants';

export default function usePostWorkspaceUnitLimitation(props) {
  const { post } = apiClient;

  return useMutation({
    mutationFn: async ({
      workspaceId,
      amount,
      limitationDuration = 365,
      type = AI_WRITING_CREDITS_TYPE,
    }) => {
      const result = await post(`/workspaces/${workspaceId}/workspace_unit_limitations`, {
        amount,
        limitationDuration,
        type,
      });

      return result;
    },

    ...props,
  });
}
