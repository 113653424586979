import './FactsSidePanel.scss';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import FactShieldGray from '@/assets/images/empty-state-fact-checking-gray.svg';
import FactShieldPink from '@/assets/images/ill-fact-checking.svg';
import ScrollBar from '@/components/Scrollbar';
import { useContentContainerContext } from '@/containers/Content/ContentContainerContext';
import { FactEmptyState } from '@/containers/Content/SidePanel/Facts/EmptyState';
import FactCheckCardWrapper from '@/containers/Content/SidePanel/Facts/FactCheckCardWrapper';
import { Filter } from '@/containers/Content/SidePanel/Facts/Filter';
import { PlanRestriction } from '@/containers/Content/SidePanel/PlanRestriction';
import { SidePanelHeader } from '@/containers/Content/SidePanel/SidePanelHeader';
import {
  FACT_CHECK_CARD_PREFIX_ID,
  FACT_CHECK_DATA_ATTRIBUTE,
} from '@/containers/Content/TinyMceComponents/Editor/hooks/useAiWriting/useFactCheck/const';
import useApiConfigurations from '@/hooks/useApiConfigurations';
import { useMixpanelTrackEvent } from '@/hooks/useMixpanelTrackEvent';
import useOrganizationFeatureSet, {
  AI_WRITING__FACT_CHECKING__IS_ENABLED,
} from '@/hooks/useOrganizationFeatureSet';
import { setFactCheckingEnabled } from '@/store/actions/userConfiguration';
import useParamSelector from '@/store/hooks/useParamSelector';
import { selectBrandVoiceByWorkspaceId } from '@/store/selectors/selectBrandVoiceByWorkspaceId';
import { selectUserConfiguration } from '@/store/selectors/selectUserConfiguration';
import { FACT_CHECKING_ACTIVATION_CLICK } from '@/utils/3rdParty/Mixpanel/constants';
import { AI_WRITING_FACT_CHECKING_ENABLED } from '@/utils/configurations/constants';

import {
  Fact,
  FACT_CRITICALITY_ENUM,
  FACT_FILTER_ENUM,
  FACT_STATUS_ENUM,
  FactFilter,
} from './facts.types';

export default function FactsSidePanel(): JSX.Element | null {
  const { contentId, workspaceId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const brandVoiceSelected = useParamSelector(selectBrandVoiceByWorkspaceId, workspaceId);

  const { isFeatureEnabled: factCheckingFeatureSetEnabled } = useOrganizationFeatureSet(
    AI_WRITING__FACT_CHECKING__IS_ENABLED
  );
  const aiWritingFactCheckingEnabled = useApiConfigurations(AI_WRITING_FACT_CHECKING_ENABLED);
  const { factCheckingEnabled } = useSelector(selectUserConfiguration);

  const {
    editorRef,
    aiWriting: {
      isAtomicContentFactCheckGenerating,
      factCheckHook: { facts },
    },
  } = useContentContainerContext();
  const trackMixpanelEvent = useMixpanelTrackEvent();

  const factCheckedElements = editorRef?.current?.targetElm
    ? (editorRef?.current?.targetElm as HTMLElement).querySelectorAll(
        `span[${FACT_CHECK_DATA_ATTRIBUTE}]`
      )
    : [];
  const ids = Array.from(factCheckedElements).map((elem) =>
    elem.getAttribute(FACT_CHECK_DATA_ATTRIBUTE)
  );

  // maybe move this logix to query params
  const [factFilters, setFactFilters] = useState<FactFilter>({
    [FACT_CRITICALITY_ENUM.CRITICALITY_VERIFIED]: true,
    [FACT_CRITICALITY_ENUM.CRITICALITY_MEDIUM]: true,
    [FACT_CRITICALITY_ENUM.CRITICALITY_HIGH]: true,
    [FACT_STATUS_ENUM.STATUS_VALIDATED]: false,
  });

  function activateFactChecking() {
    trackMixpanelEvent(FACT_CHECKING_ACTIVATION_CLICK, {
      brand_voice_id: brandVoiceSelected?.id ?? '',
      brand_voice_name: brandVoiceSelected?.name ?? '',
      in_editor: false,
      in_sidepanel: true,
      is_brand_voice: brandVoiceSelected != null,
      is_fact_checking: true,
    });

    // The editor is in read-only mode, involve we're generating or in atomic content.
    if (!isAtomicContentFactCheckGenerating && !editorRef.current?.mode?.isReadOnly()) {
      dispatch(setFactCheckingEnabled(true));
    }
  }

  function filterFacts(fact: Fact) {
    if (FACT_STATUS_ENUM.STATUS_DELETED === fact.status || fact.enabledAt === null) {
      return false;
    }

    if (
      factFilters[FACT_STATUS_ENUM.STATUS_VALIDATED] &&
      fact.status === FACT_STATUS_ENUM.STATUS_VALIDATED
    ) {
      return true;
    }

    if (
      !factFilters[FACT_STATUS_ENUM.STATUS_VALIDATED] &&
      fact.status === FACT_STATUS_ENUM.STATUS_VALIDATED
    ) {
      return false;
    }

    if (
      factFilters[FACT_CRITICALITY_ENUM.CRITICALITY_HIGH] &&
      fact.criticality === FACT_FILTER_ENUM.CRITICALITY_HIGH
    ) {
      return true;
    }

    if (
      factFilters[FACT_CRITICALITY_ENUM.CRITICALITY_MEDIUM] &&
      fact.criticality === FACT_FILTER_ENUM.CRITICALITY_MEDIUM
    ) {
      return true;
    }

    if (factFilters[FACT_CRITICALITY_ENUM.CRITICALITY_VERIFIED] && fact.criticality === null) {
      return true;
    }

    return false;
  }

  function getEmptyStateTranslationBaseKey(facts: Fact[] | null) {
    if (facts && facts.length === 0) {
      if (
        factFilters[FACT_CRITICALITY_ENUM.CRITICALITY_HIGH] &&
        !factFilters[FACT_CRITICALITY_ENUM.CRITICALITY_MEDIUM] &&
        !factFilters[FACT_CRITICALITY_ENUM.CRITICALITY_VERIFIED] &&
        !factFilters[FACT_STATUS_ENUM.STATUS_VALIDATED]
      ) {
        return 'fact:empty-state.high-criticality';
      }

      if (
        factFilters[FACT_CRITICALITY_ENUM.CRITICALITY_MEDIUM] &&
        !factFilters[FACT_CRITICALITY_ENUM.CRITICALITY_HIGH] &&
        !factFilters[FACT_CRITICALITY_ENUM.CRITICALITY_VERIFIED] &&
        !factFilters[FACT_STATUS_ENUM.STATUS_VALIDATED]
      ) {
        return 'fact:empty-state.medium-criticality';
      }

      if (
        factFilters[FACT_CRITICALITY_ENUM.CRITICALITY_VERIFIED] &&
        !factFilters[FACT_CRITICALITY_ENUM.CRITICALITY_MEDIUM] &&
        !factFilters[FACT_CRITICALITY_ENUM.CRITICALITY_HIGH] &&
        !factFilters[FACT_STATUS_ENUM.STATUS_VALIDATED]
      ) {
        return 'fact:empty-state.verified-criticality';
      }

      if (
        factFilters[FACT_STATUS_ENUM.STATUS_VALIDATED] &&
        !factFilters[FACT_CRITICALITY_ENUM.CRITICALITY_VERIFIED] &&
        !factFilters[FACT_CRITICALITY_ENUM.CRITICALITY_MEDIUM] &&
        !factFilters[FACT_CRITICALITY_ENUM.CRITICALITY_HIGH]
      ) {
        return 'fact:empty-state.validated';
      }
    }

    return 'fact:empty-state.default';
  }

  if (!aiWritingFactCheckingEnabled) {
    return null;
  }

  if (!factCheckingFeatureSetEnabled) {
    return (
      <div className="facts_side_panel">
        <SidePanelHeader>
          <div>{t('fact:sidepanel-title', { value: 0 })}</div>
          <Filter filters={factFilters} setFilters={setFactFilters} />
        </SidePanelHeader>
        <PlanRestriction
          description={t('fact:restriction-plan.description')}
          title={t('fact:restriction-plan.title')}
        />
      </div>
    );
  }

  const filteredFacts: Fact[] = facts?.filter(filterFacts) ?? [];
  const sortedFacts: Fact[] = filteredFacts
    .sort((a, b) => ids.indexOf(b.id) - ids.indexOf(a.id))
    .sort((a) => (ids.includes(a.id) ? -1 : 1));

  if (!factCheckingEnabled && facts.length === 0) {
    return (
      <div className="facts_side_panel">
        <SidePanelHeader>
          <div>{t('fact:sidepanel-title', { value: 0 })}</div>
          <Filter filters={factFilters} setFilters={setFactFilters} />
        </SidePanelHeader>
        <FactEmptyState
          callToActionFn={activateFactChecking}
          callToActionText={t(`fact:empty-state.disabled.activate`)}
          description={t(`fact:empty-state.disabled.description`)}
          illustration={FactShieldPink}
          title={t(`fact:empty-state.disabled.title`)}
        />
      </div>
    );
  }

  if (filteredFacts.length === 0) {
    const baseKey = getEmptyStateTranslationBaseKey(filteredFacts);

    return (
      <div className="facts_side_panel">
        <SidePanelHeader>
          <div>{t('fact:sidepanel-title', { value: 0 })}</div>
          <Filter filters={factFilters} setFilters={setFactFilters} />
        </SidePanelHeader>
        {!factCheckingEnabled && (
          <div className="facts_side_panel__badge">
            {t(`fact:badge.text`)}{' '}
            <span
              className="facts_side_panel__badge__call_to_action"
              onClick={activateFactChecking}
            >
              {t(`fact:badge.activate`)}
            </span>
          </div>
        )}
        <FactEmptyState
          description={t(`${baseKey}.description`)}
          illustration={FactShieldGray}
          title={t(`${baseKey}.title`)}
        />
      </div>
    );
  }

  return (
    <div className="facts_side_panel">
      <SidePanelHeader>
        <div>{t('fact:sidepanel-title', { value: filteredFacts.length })}</div>
        <Filter filters={factFilters} setFilters={setFactFilters} />
      </SidePanelHeader>
      {!factCheckingEnabled && (
        <div className="facts_side_panel__badge">
          {t(`fact:badge.text`)}{' '}
          <span className="facts_side_panel__badge__call_to_action" onClick={activateFactChecking}>
            {t(`fact:badge.activate`)}
          </span>
        </div>
      )}
      <div className="facts_side_panel__body">
        <ScrollBar>
          <div className="facts_side_panel__body__elements">
            {sortedFacts.map((fact: Fact) => (
              <FactCheckCardWrapper
                key={fact.id}
                contentId={contentId}
                fact={fact}
                prefixId={FACT_CHECK_CARD_PREFIX_ID}
              />
            ))}
          </div>
        </ScrollBar>
      </div>
    </div>
  );
}
