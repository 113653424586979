import './UpgradePlan.scss';

import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Button } from 'semji-core/components/Button';

import ColoredReportIcon from '@/components/icons/ColoredReportIcon';

export enum ENUM_UPGRADE_PLAN_VARIANT {
  DEFAULT = 'default',
  SMALL = 'small',
}

const UpgradePlan = ({
  variant = ENUM_UPGRADE_PLAN_VARIANT.DEFAULT,
}: {
  variant: ENUM_UPGRADE_PLAN_VARIANT;
}) => {
  const { t } = useTranslation();
  const organizationId = useSelector((state) => state.defaultOrganizationId);
  const title = t('empty-state:upgrade-plan.metric-not-available-in-your-plan');

  return (
    <div className={`empty-state-upgrade-plan empty-state-upgrade-plan--${variant}`}>
      <ColoredReportIcon className="empty-state-upgrade-plan__image" />
      <div className="empty-state-upgrade-plan__content">
        <h2 className="empty-state-upgrade-plan__title">{title}</h2>
        <span className="empty-state-upgrade-plan__description">
          <Trans i18nKey={'empty-state:upgrade-plan.need-to-track-metric-upgrade'} />
        </span>
      </div>
      <NavLink
        className="empty-state-upgrade-plan__button"
        to={`/o/${organizationId}/settings/billing`}
      >
        <Button>{t('empty-state:upgrade-plan.upgrade-plan')}</Button>
      </NavLink>
    </div>
  );
};

export default UpgradePlan;
