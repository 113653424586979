import { Page } from '@/types/pages';

export enum ReportView {
  New = 'new',
  Existing = 'existing',
  All = 'all',
}

export enum ReportMetricCategory {
  Performance = 'performance',
  Production = 'production',
  Acquisition = 'acquisition',
  UserBehavior = 'user-behaviour',
  Conversion = 'conversion',
  ECommerce = 'e-commerce',
}

export enum ReportMetricCategoryWithPosition {
  Performance = 'performance',
  Position = 'position',
  Acquisition = 'acquisition',
  UserBehavior = 'user-behaviour',
  Conversion = 'conversion',
  ECommerce = 'e-commerce',
}

export enum ReportMetricType {
  Percent = 'percent',
  Number = 'number',
}

export enum ReportMetricChartType {
  Line = 'line',
  Bar = 'bar',
  Column = 'column',
  Area = 'area',
}

export enum ReportMetricKey {
  overview = 'overview',
  publicationsCount = 'publicationsCount',
  Clicks = 'clicks',
  Position = 'position',
  Sessions = 'sessions',
  Conversions = 'conversions',
  ConversionRate = 'conversionRate',
  BounceRate = 'bounceRate',
  CTR = 'ctr',
  Impressions = 'impressions',
  PagesPerSession = 'pagesPerSession',
  AverageSessionDuration = 'averageSessionDuration',
  Transactions = 'transactions',
  TransactionRate = 'transactionRate',
  Revenue = 'revenue',
  AverageContentScore = 'publicationsAverageContentScore',
}

export enum ReportMetricEvolutionRating {
  Positive = 'positive',
  Negative = 'negative',
}

export enum ReportMetricSparklineMovingType {
  Avg = 'avg',
  Sum = 'sum',
}

export type ReportMetric = {
  category: ReportMetricCategory;
  chartType: ReportMetricChartType;
  dashboardFullWidth?: boolean;
  default: boolean;
  dependency: string;
  evolutionRating: ReportMetricEvolutionRating;
  icon: React.ReactNode;
  key: ReportMetricKey;
  name: string;
  suffix: string;
  type: ReportMetricType;
  sparklineMovingType: ReportMetricSparklineMovingType;
};

export type AggregatedMetrics = {
  [key in ReportMetricKey]?: number;
};

export type PageWithMetrics = Page & {
  assignedToIds: string[];
  currentPosition: number;
  dailyPageMetrics: any;
  evolution: number;
  folderIds: string[];
  monthlyPageMetrics: any;
};
