import React from 'react';

import SvgWrapper from './SvgWrapper';

const CheckIcon = (props) => (
  <SvgWrapper {...props}>
    <path d="M15.418 1.774l-8.833 13.485-4.918-4.386 0.666-0.746 4.051 3.614 8.198-12.515 0.836 0.548z" />
  </SvgWrapper>
);

export default CheckIcon;
