import './AddToPlanningDialog.scss';

import { useCallback, useEffect, useRef, useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { DialogModal, ENUM_DIALOG_MODAL_SIZE } from 'semji-core/components/DialogModal';

import {
  addCurrentPageDraft,
  addTopKeywordOnPage,
  bulkImportPagesFromUrl,
  getTopKeywordOnPage,
  setPageFocusTopKeyword,
} from '@/apis/semji/api';
import useGetCurrentWorkspaceAllFolders from '@/apis/semji/folders/useGetCurrentWorkspaceAllFolders';
import usePostFolder from '@/apis/semji/folders/usePostFolder';
import { DEFAULT_FOLDER_GENERAL } from '@/components/Select/FolderSelect/constants';
import {
  ADD_CONTENT_VERSION,
  ADD_TO_PLANNING_MODAL_ID,
  CONTENT_STATUS,
  ContentToAddToPlanning,
} from '@/containers/AddToPlanning/AddToPlanning.types';
import { Assignation } from '@/containers/AddToPlanning/Assignation';
import Association from '@/containers/AddToPlanning/Association/Association';
import { Header } from '@/containers/AddToPlanning/Header';
import useNullUser from '@/hooks/useNullUser';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import useWorkspaceStatuses from '@/hooks/useWorkspaceStatuses';
import useWorkspaceUsers from '@/hooks/useWorkspaceUsers';
import { showErrorSnackbar, showSuccessSnackbar } from '@/store/actions/ui';
import { getOrganizationById } from '@/store/reducers';
import { selectCurrentWorkspaceWebsiteUrl } from '@/store/selectors/selectCurrentWorkspaceWebsiteUrl';
import { Log } from '@/utils/log/Log';
import { isUrlValidWithWebSiteUrl, isValidPageUrl } from '@/utils/url';

function AddToPlanningDialog({
  onClose,
  contentsToAddToPlanning,
  addToPlanningDialogVersion,
  handleRefetch,
  handleRefetchContent,
  pages,
}: {
  onClose: () => void;
  contentsToAddToPlanning: ContentToAddToPlanning[];
  addToPlanningDialogVersion: string;
  handleRefetch: () => void;
  handleRefetchContent: () => void;
  pages: any[];
}): JSX.Element {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const statuses = useWorkspaceStatuses();
  const users = useWorkspaceUsers();
  const navigate = useNavigate();
  const { organizationId, workspaceId } = useParams();

  const workspaceUrl = useSelector(selectCurrentWorkspaceWebsiteUrl);
  const nullUser = useNullUser();

  const [addToPlanningLoading, setAddToPlanningLoading] = useState(false);
  const [userAssignation, setUserAssignation] = useState(nullUser.id);
  const [dueDateAssignation, setDueDateAssignation] = useState<string | null>(null);
  const [contentStatusAssignation, setContentStatusAssignation] = useState<string | null>(null);
  const [type, setType] = useState<string | null>(null);
  const [contents, setContents] = useState(contentsToAddToPlanning);
  const [folderId, setFolderId] = useState(DEFAULT_FOLDER_GENERAL.id);

  const isAssociationRenderActive =
    (addToPlanningDialogVersion === ADD_CONTENT_VERSION.ADD_TO_PLANNING_VERSION ||
      (addToPlanningDialogVersion === ADD_CONTENT_VERSION.START_OPTIMIZING_VERSION &&
        type === CONTENT_STATUS.UPDATED_CONTENT)) &&
    type;
  const contentsRef = useRef(contents);
  contentsRef.current = contents;

  const { data: folders, refetch: refetchFolders } = useGetCurrentWorkspaceAllFolders();
  const currentOrganization = useSelector((state) => getOrganizationById(state, organizationId));
  const { isFeatureEnabled: unlimitedFoldersFeatureSet } = useOrganizationFeatureSet(
    'organization:folders:has-unlimited-amount'
  );
  const { data: foldersLimit = 0 } = useOrganizationFeatureSet('organization:folders:amount');
  const isOrganizationFoldersLimit =
    currentOrganization.foldersCount >= foldersLimit && !unlimitedFoldersFeatureSet;
  const createNewFolder = usePostFolder({
    onSuccess: async () => {
      refetchFolders();
    },
  });

  function handleNewFolderCreation(newFolder) {
    createNewFolder.mutate(newFolder);
  }

  function handleChangeFolderId(id) {
    setFolderId(id);
  }

  function handleClose() {
    if (addToPlanningLoading) {
      return;
    }

    onClose();
    setAddToPlanningLoading(false);
    setType('');
    setUserAssignation(nullUser.id);
    setContentStatusAssignation(statuses[0]?.id);
    setDueDateAssignation('');
    setContents([]);
  }

  function isUrlOnError(url, contents = contentsRef.current) {
    if (!url || !url.length) {
      return '';
    }
    const { value, error } = isValidPageUrl(url);
    if (!value) {
      return error;
    }
    if (!isUrlValidWithWebSiteUrl(url, workspaceUrl)) {
      return t(`add-to-planning:error.incorrect-url-workspace`, {
        workspaceUrl,
      });
    }
    if (contents.filter((content) => content.draftUrl === url).length > 1) {
      return t(`add-to-planning:error.url-already-in-list`);
    }
    if (!!pages.find((p) => p.url === url)?.draft) {
      return t(`add-to-planning:error.url-already-in-planning`);
    }
    return '';
  }

  function isFocusKeywordOnError(focusKeyword) {
    if (!focusKeyword || !focusKeyword.length) {
      return t(`add-to-planning:error.focus-keyword-empty`);
    }
    return '';
  }

  async function handleAddToPlanningNewContent() {
    return await Promise.all(
      contentsRef.current.map(async (content) => {
        const createdContent = await addCurrentPageDraft(workspaceId, {
          assignedTo: userAssignation === nullUser.id ? null : `/users/${userAssignation}`,
          contentStatus: `/content_statuses/${contentStatusAssignation}`,
          dueDate: dueDateAssignation,
          folderId: folderId,
          pageId: null,
          title: content.draftTitle,
        });
        const topKeyword = await addTopKeywordOnPage(createdContent.page.id, content.keyword);
        await setPageFocusTopKeyword(createdContent.page.id, topKeyword['@id']);
        return createdContent;
      })
    );
  }

  async function handleAddToPlanningUpdatedContent() {
    const urls = contentsRef.current.map((content) => content.draftUrl);
    const pages = (await bulkImportPagesFromUrl(workspaceId, urls, false))['pages'];

    return await Promise.all(
      pages.map(async (page) => {
        const createdContent = await addCurrentPageDraft(workspaceId, {
          assignedTo: userAssignation === nullUser.id ? null : `/users/${userAssignation}`,
          contentStatus: `/content_statuses/${contentStatusAssignation}`,
          dueDate: dueDateAssignation,
          folderId: folderId,
          html: null,
          page: `/pages/${page.id}`,
        });
        const keyword = contentsRef.current.find(
          (content) => content.draftUrl === page.url
        ).keyword;
        const topKeywordsAssociated = (await getTopKeywordOnPage(createdContent.page.id))[
          'hydra:member'
        ];
        let topKeyword = topKeywordsAssociated.find((topKeyword) => topKeyword.keyword === keyword);
        if (!topKeyword) {
          topKeyword = await addTopKeywordOnPage(createdContent.page.id, keyword);
        }
        await setPageFocusTopKeyword(createdContent.page.id, topKeyword['@id']);
        return createdContent;
      })
    );
  }

  const handleRemoveFromContentToAdd = useCallback((id) => {
    setContents((prevContents) => prevContents.filter((content) => content.id !== id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTitleChange = useCallback((id, value) => {
    setContents((prevContents) =>
      prevContents.map((content) => ({
        ...content,
        ...(content.id === id && { draftTitle: value }),
      }))
    );
  }, []);

  const handleFocusKeywordChange = useCallback((id, value) => {
    setContents((prevContents) =>
      prevContents.map((content) => ({
        ...content,
        ...(content.id === id && { keyword: value }),
        focusKeywordError: isFocusKeywordOnError(content.id === id ? value : content.keyword),
      }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUrlChange = useCallback((id, value) => {
    setContents((prevContents) => {
      const newContents = prevContents.map((content) =>
        content.id !== id ? { ...content } : { ...content, draftUrl: value }
      );

      return newContents.map((content) => ({
        ...content,
        focusKeywordError: '',
        urlError: isUrlOnError(content.draftUrl, newContents),
      }));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddToPlanning = useCallback(async () => {
    let createdContents = [];

    setAddToPlanningLoading(true);
    try {
      if (type === CONTENT_STATUS.NEW_CONTENT) {
        createdContents = await handleAddToPlanningNewContent();
      } else {
        createdContents = await handleAddToPlanningUpdatedContent();
      }
    } catch (e) {
      Log.error({ e });
      dispatch(showErrorSnackbar(t(`common:error.default`)));
    } finally {
      if (
        addToPlanningDialogVersion === ADD_CONTENT_VERSION.START_OPTIMIZING_VERSION &&
        createdContents[0]?.page?.id &&
        createdContents[0]?.id
      ) {
        navigate(
          type === CONTENT_STATUS.UPDATED_CONTENT
            ? `/o/${organizationId}/w/${workspaceId}/p/${createdContents[0]?.page?.id}/create`
            : `/o/${organizationId}/w/${workspaceId}/p/${createdContents[0]?.page?.id}/content/${createdContents[0]?.id}`,
          {
            state: {
              triggerRefreshDataAuto: type === CONTENT_STATUS.UPDATED_CONTENT ? true : false,
            },
          }
        );
      } else {
        handleRefetch();
        handleRefetchContent();
      }
      onClose();
      dispatch(showSuccessSnackbar(t(`add-to-planning:added-successfully`)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, userAssignation, dueDateAssignation, contentStatusAssignation, folderId]);

  useEffect(() => {
    if (statuses[0]?.id) {
      setContentStatusAssignation(statuses[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statuses[0]]);

  useEffect(() => {
    setContents(
      contentsToAddToPlanning.map((content) => ({
        ...content,
        urlError: isUrlOnError(content.draftUrl, contentsToAddToPlanning),
      }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentsToAddToPlanning]);

  function getLabel() {
    if (
      addToPlanningLoading &&
      addToPlanningDialogVersion === ADD_CONTENT_VERSION.ADD_TO_PLANNING_VERSION
    ) {
      return t(`add-to-planning:adding-to-planning`);
    }

    if (
      addToPlanningLoading &&
      addToPlanningDialogVersion === ADD_CONTENT_VERSION.START_OPTIMIZING_VERSION
    ) {
      return t(`add-to-planning:starting-optimizing`);
    }

    if (addToPlanningDialogVersion === ADD_CONTENT_VERSION.ADD_TO_PLANNING_VERSION) {
      return t(`add-to-planning:add-to-planning`);
    }

    if (addToPlanningDialogVersion === ADD_CONTENT_VERSION.START_OPTIMIZING_VERSION) {
      return t(`add-to-planning:start-optimizing`);
    }

    return '';
  }

  const isButtonDisabled =
    !type ||
    !folderId ||
    (type === CONTENT_STATUS.NEW_CONTENT
      ? contents.some((content) => !!content.focusKeywordError)
      : contents.some(
          (content) => !content.draftUrl || !!content.urlError || !!content.focusKeywordError
        ));

  const title =
    addToPlanningDialogVersion === ADD_CONTENT_VERSION.ADD_TO_PLANNING_VERSION
      ? t(`add-to-planning:add-to-planning`)
      : t(`add-to-planning:start-optimizing`);

  return (
    <DialogModal
      cancelAction={handleClose}
      cancelLabel={t('common:labels.cancel')}
      canClickOutsideToClose
      closable
      confirmAction={handleAddToPlanning}
      confirmLabel={getLabel()}
      id={ADD_TO_PLANNING_MODAL_ID}
      isCancelDisabled={false}
      isConfirmDisabled={addToPlanningLoading || !type}
      isOpen={true}
      size={ENUM_DIALOG_MODAL_SIZE.Large}
      title={title}
      onClose={handleClose}
    >
      <Header
        contents={contents}
        contentsLength={contents.length}
        handleSelectType={setType}
        type={type}
      />

      {type && addToPlanningDialogVersion === ADD_CONTENT_VERSION.ADD_TO_PLANNING_VERSION && (
        <Assignation
          contentStatusAssignation={contentStatusAssignation}
          createNewFolder={handleNewFolderCreation}
          dueDateAssignation={dueDateAssignation}
          folderId={folderId}
          folders={folders}
          handleChangeFolderId={handleChangeFolderId}
          isCreationDisabled={isOrganizationFoldersLimit}
          isLoading={addToPlanningLoading}
          setContentStatusAssignation={setContentStatusAssignation}
          setDueDateAssignation={setDueDateAssignation}
          setUserAssignation={setUserAssignation}
          statuses={statuses}
          userAssignation={userAssignation}
          users={users}
        />
      )}
      {isAssociationRenderActive && (
        <Association
          contents={contents}
          handleAddToPlanning={handleAddToPlanning}
          handleFocusKeywordChange={handleFocusKeywordChange}
          handleRemoveFromContentToAdd={handleRemoveFromContentToAdd}
          handleTitleChange={handleTitleChange}
          handleUrlChange={handleUrlChange}
          isButtonDisabled={isButtonDisabled}
          pages={pages}
          type={type}
        />
      )}
    </DialogModal>
  );
}

export default AddToPlanningDialog;
