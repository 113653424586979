import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import apiClient from '@/apis/semji/apiClient';
import { SCOPE_PAGES } from '@/apis/semji/constants';
import { QueryOptions } from '@/apis/types/query.types';
import { showErrorSnackbar } from '@/store/actions/ui';
import { Page } from '@/types/pages';

interface UseGetPageByIdProps extends QueryOptions<{}, Page> {
  pageId: string;
}

export default function useGetPageById({ pageId, ...props }: UseGetPageByIdProps) {
  const { get } = apiClient;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return useQuery({
    enabled: !!pageId,
    onError: () => {
      dispatch(showErrorSnackbar(t('common:error.default')));
    },
    queryFn: async ({ signal }) => {
      const result = await get(`/pages/${pageId}`, {
        signal,
      });

      return result.data;
    },
    queryKey: [SCOPE_PAGES.GET_PAGE_BY_ID, pageId],
    ...props,
  });
}
