import { useMutation } from '@tanstack/react-query';

import apiClient from '@/apis/semji/apiClient';

export default function usePostResolveThread({ ...props }) {
  const { post } = apiClient;

  return useMutation({
    mutationFn: ({ threadId }) => post(`threads/${threadId}/resolve`, {}),
    ...props,
  });
}
