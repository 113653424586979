import styled from 'styled-components/macro';

export const Cell = styled.span`
  width: ${(props) => props.width};
  min-width: ${(props) => props.minWidth};
  max-width: ${(props) => props.maxWidth};
  cursor: ${(props) => props.cursor};
  display: flex;
  justify-content: ${(props) => (props.alignCenter ? 'center' : 'flex-start')};
`;
