import { createContext, useContext, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import useGetWorkspaceUsers from '@/apis/semji/users/useGetWorkspaceUsers';
import useAiWriting from '@/containers/Content/TinyMceComponents/Editor/hooks/useAiWriting/useAiWriting';
import useComments from '@/containers/Content/TinyMceComponents/Editor/hooks/useComment/useComments';
import useEditorPopper from '@/containers/Content/TinyMceComponents/Editor/hooks/useEditorPopper/useEditorPopper';
import useForceRender from '@/hooks/useForceRender';

const ContentContainerContext = createContext(null);

export function useContentContainerContext() {
  return useContext(ContentContainerContext);
}

function ContentContainerProvider({ children }) {
  const editorRef = useRef(null);
  const [lockEditorScroll, setLockEditorScroll] = useState(false);
  const inputTitleRef = useRef(null);
  const textareaMetaDescriptionRef = useRef(null);
  const [editorAiWritingPopperOpen, setEditorAiWritingPopperOpen] = useState(false);
  const triggerSaveRef = useRef(false);
  const focusTopKeywordRef = useRef();
  focusTopKeywordRef.current = useSelector((state) => state.report.focusTopKeyword);

  const { forceRender } = useForceRender();

  // TODO Refactoring, redux store, specific context for these values
  const getWorkspaceUsers = useGetWorkspaceUsers();

  const comments = useComments({ editorRef, workspaceUsers: getWorkspaceUsers.data });

  const aiWriting = useAiWriting({
    editorRef,
    focusTopKeywordRef,
    inputTitleRef,
    setEditorAiWritingPopperOpen,
    textareaMetaDescriptionRef,
    triggerSaveRef,
  });

  const editorPopper = useEditorPopper({
    aiWriting,
    editorAiWritingPopperOpen,
    editorRef,
    focusTopKeywordRef,
    inputTitleRef,
    setEditorAiWritingPopperOpen,
    setLockEditorScroll,
    textareaMetaDescriptionRef,
  });

  // we will set all the data that we need to share in content container here
  // like the current content, comments, ...
  return (
    <ContentContainerContext.Provider
      value={{
        aiWriting,
        comments,
        editorPopper,
        editorRef,
        forceRender,
        getWorkspaceUsers,
        inputTitleRef,
        lockEditorScroll,
        textareaMetaDescriptionRef,
        triggerSaveRef,
      }}
    >
      {children}
    </ContentContainerContext.Provider>
  );
}

export default ContentContainerProvider;
