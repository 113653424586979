import { getCurrentDateRange } from './getCurrentDateRange';
import { getPreviousDateRangeGapDuration } from './getPreviousDateRangeGapDuration';

// today date is passed throw the different methods for test purpose
export const getPreviousDateRange = ({ period, comparisonPeriod, today }) => {
  const dateRange = getCurrentDateRange({ period, today });
  const duration = getPreviousDateRangeGapDuration(period, comparisonPeriod);

  return {
    from: dateRange.from.minus(duration),
    to: dateRange.to.minus(duration),
  };
};
