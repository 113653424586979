import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import PrimaryButton from '../../design-system/components/Button/PrimaryButton';
import { OutlinedButton, TextButton as TextBtn } from '../Button/Button';
import { RelativeLoader } from '../Loader/Loader';
import StepperInfo from './StepperInfo';

const TextButton = styled(TextBtn)`
  &&& {
    justify-content: center;
  }
`;

const FlexSide = styled.div`
  display: flex;
  gap: 15px;
`;

const FlexMiddle = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

export function WithActionStepperFooter({
  handleCancel,
  handleNext,
  handlePrevious,
  info,
  infoOnError,
  isLoading,
  isNextDisabled,
  nextLabel,
  previousDisabled,
}) {
  const { t } = useTranslation();

  return (
    <>
      {handlePrevious && (
        <FlexSide>
          <OutlinedButton color="dark040" disabled={previousDisabled} onClick={handlePrevious}>
            {t('common:labels.previous')}
          </OutlinedButton>
        </FlexSide>
      )}
      {/* FlexMiddle must be present in DOM to align buttons on the right or the left (flex: 1). */}
      <FlexMiddle>{info && <StepperInfo isError={infoOnError}>{info}</StepperInfo>}</FlexMiddle>
      <FlexSide>
        {handleCancel && (
          <TextButton color="dark040" onClick={handleCancel}>
            {t('common:labels.cancel')}
          </TextButton>
        )}
        <PrimaryButton disabled={isNextDisabled || isLoading} width="180px" onClick={handleNext}>
          {isLoading ? <RelativeLoader /> : nextLabel || 'Next'}
        </PrimaryButton>
      </FlexSide>
    </>
  );
}

export default WithActionStepperFooter;
