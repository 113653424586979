import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';

import SelectFocusKeywordIcon from '@/assets/images/select-focus-keyword.svg';
import { FlexGrowColumnCenteredContainer } from '@/components/_common';
import SemjiButton from '@/components/Button/SemjiButton';
import FocusKeywordSelector from '@/components/FocusKeyword';
import { TOP_PAGE_FK } from '@/components/FocusKeyword/constants';
import CloseIcon from '@/components/icons/CloseIcon';
import { ParagraphText } from '@/components/Text/Paragraph';
import { TitleThree } from '@/components/Text/Title';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import OpenCommentsButton from '@/containers/Content/TinyMceComponents/Comments/SidePanel/OpenCommentsButton';
import Flex from '@/design-system/components/Flex';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import { openCreditLimitDialog } from '@/store/actions/report';
import { toggleSidePanel } from '@/store/actions/report';
import {
  ANALYSIS_CREDITS_TYPE,
  ESTIMATED_AMOUNT_OF_ANALYSIS_UNIT_TO_START_ANALYSIS,
} from '@/utils/constants';

const TooltipWrapper = styled(Flex)`
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
`;
const StyledFlexGrowColumnCenteredContainer = styled(FlexGrowColumnCenteredContainer)`
  padding: 0 50px;
`;
const StyledFocusKeywordSelector = styled(FocusKeywordSelector)`
  margin: 40px 0;
`;
const StyledImage = styled.img`
  margin: 0 0 40px 0;
`;

function SidePanelNoKeyword({
  hasSearchConsole,
  focusTopKeyword,
  topKeywords,
  loading,
  onKeywordChange,
  getKeywordsByPage,
  onKeywordAdd,
  workspaceCountryName,
  startAnalysis,
  remainingAnalysisUnit,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isAnalyzable, setIsAnalyzable] = useState(true);
  const { isFeatureEnabled: hasUnlimitedAnalysisCredits } = useOrganizationFeatureSet(
    'credits:analysis:has-unlimited-amount'
  );

  function onStartAnalyze() {
    if (isAnalyzable && focusTopKeyword?.id) {
      if (
        !hasUnlimitedAnalysisCredits &&
        remainingAnalysisUnit < ESTIMATED_AMOUNT_OF_ANALYSIS_UNIT_TO_START_ANALYSIS
      ) {
        dispatch(openCreditLimitDialog(ANALYSIS_CREDITS_TYPE));
      } else {
        setIsAnalyzable(false);
        startAnalysis();
      }
    }
  }

  function toggle() {
    dispatch(toggleSidePanel());
  }

  return (
    <>
      <TooltipWrapper alignItems="center" gap="10px">
        <OpenCommentsButton />
        <TooltipComponent
          title={t('content:side-panel-components.side-panel-no-keyword.close-sidebar')}
        >
          <CloseIcon onClick={toggle} />
        </TooltipComponent>
      </TooltipWrapper>
      <StyledFlexGrowColumnCenteredContainer>
        <StyledImage
          alt={t('content:side-panel-components.side-panel-no-keyword.alt-image')}
          height="120px"
          src={SelectFocusKeywordIcon}
        />
        <TitleThree align="center" lineHeight={'24.3px'} weight="strong">
          {t('content:side-panel-components.side-panel-no-keyword.title')}
        </TitleThree>
        <ParagraphText align="center" color="dark060" lineHeight="24px" weight="medium">
          {t('content:side-panel-components.side-panel-no-keyword.explanation-text')}
        </ParagraphText>
        <StyledFocusKeywordSelector
          focusTopKeywordId={
            loading && !focusTopKeyword.id ? 'loading_keyword' : focusTopKeyword.id
          }
          getKeywordsByPage={getKeywordsByPage}
          hasSearchConsole={Boolean(hasSearchConsole)}
          topKeywords={
            loading && !focusTopKeyword.id
              ? [
                  {
                    id: 'loading_keyword',
                    keywordDataUpdatedAt: null,
                    lastSearchConsoleUpdatedAt: null,
                  },
                ]
              : topKeywords
          }
          variant={TOP_PAGE_FK}
          workspaceCountryName={workspaceCountryName}
          onKeywordAdd={onKeywordAdd}
          onKeywordChange={onKeywordChange}
        />
        <SemjiButton
          costTitleTooltip={ESTIMATED_AMOUNT_OF_ANALYSIS_UNIT_TO_START_ANALYSIS}
          disabled={!isAnalyzable || !focusTopKeyword.id || loading}
          titleButton={t('content:side-panel-components.side-panel-no-keyword.button')}
          withoutIcon={hasUnlimitedAnalysisCredits}
          withoutTooltip={hasUnlimitedAnalysisCredits}
          onClick={onStartAnalyze}
        />
      </StyledFlexGrowColumnCenteredContainer>
    </>
  );
}

export default SidePanelNoKeyword;
