import {
  RESET_CONTENT,
  SET_CONTENT,
  SET_CONTENT_HTML,
  SET_CONTENT_METADESCRIPTION,
  SET_CONTENT_TITLE,
} from '../actions/actionTypes';

const INITIAL_STATE = {
  html: undefined,
  metaDescription: undefined,
  title: undefined,
};

export default function content(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_CONTENT:
      return {
        ...state,
        html: action.html,
        metaDescription: action.metaDescription,
        title: action.title,
      };
    case SET_CONTENT_HTML:
      return {
        ...state,
        html: action.html,
      };
    case SET_CONTENT_TITLE:
      return {
        ...state,
        title: action.title,
      };
    case SET_CONTENT_METADESCRIPTION:
      return {
        ...state,
        meyaDescription: action.meyaDescription,
      };
    case RESET_CONTENT:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
}
