import { nanoid } from 'nanoid';
import { useMemo } from 'react';

import SvgWrapper from './SvgWrapper';

function EditStartOptimizing(props) {
  const svgId = useMemo(() => nanoid(), []);

  return (
    <SvgWrapper fill="none" viewBox="0 0 14 15" {...props}>
      <mask
        height="13"
        id={svgId}
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="13"
        x="1"
        y="1"
      >
        <path
          d="M12.7915 3.25289L11.6361 2.09666C11.1708 1.63054 10.3555 1.63054 9.88854 2.09666L2.42984 9.55619L1.01172 13.911L5.3336 12.4575L12.7907 4.9996C13.2732 4.51866 13.2732 3.73466 12.7915 3.25289ZM2.5509 11.8481L3.08207 10.2183L4.67478 11.8118L3.05819 12.3554L2.5509 11.8481ZM3.58937 9.56195L8.11301 5.03831L9.85148 6.77595L5.32701 11.3004L3.58937 9.56195ZM8.69525 4.45525L9.2956 3.85489L11.0341 5.59254L10.4337 6.19289L8.69525 4.45525ZM12.2092 4.41736L11.6163 5.01031L9.87784 3.27266L10.4708 2.67889C10.6264 2.52325 10.8974 2.52325 11.053 2.67889L12.2084 3.83513C12.3698 3.99654 12.3698 4.25678 12.2092 4.41736Z"
          fill="black"
        />
      </mask>
      <g mask={`url(#${svgId})`}>
        <rect fill="#7C7D86" height="16.4706" width="16.4706" x="-1.64844" y="0.0771484" />
      </g>
    </SvgWrapper>
  );
}

export default EditStartOptimizing;
