import React from 'react';

import SvgWrapper from './SvgWrapper';

const FileExportOutline = (props) => (
  <SvgWrapper {...props} fill="none" height="18" viewBox="0 0 17 18" width="17">
    <mask
      height="18"
      id="mask0_10859_19942"
      maskUnits="userSpaceOnUse"
      style={{ maskType: 'alpha' }}
      width="15"
      x="1"
      y="0"
    >
      <path
        clipRule="evenodd"
        d="M14 0.0498047H6.333L1 5.3578V17.0498H14V16.0498H13H10H2V6.0498H7V1.0498H13V5.0498H14V0.0498047ZM2.727 5.0498L6 1.7918V5.0498H2.727Z"
        fill="black"
        fillRule="evenodd"
      />
      <path d="M8 10.5498H14.5M11.5 7.0498L15 10.5498L11.5 14.0498" stroke="black" />
    </mask>
    <g mask="url(#mask0_10859_19942)">
      <rect fill="#FF4D64" height="20" transform="matrix(-1 0 0 1 19 -0.950195)" width="20" />
    </g>
  </SvgWrapper>
);
export default FileExportOutline;
