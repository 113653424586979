/* eslint-disable react-hooks/rules-of-hooks */
import useGetContentById from '@/apis/semji/contents/useGetContentById';
import useGetContentVersionById from '@/apis/semji/contents/useGetVersionById/useGetVersionById';
import { ENUM_CONTENT_TYPE } from '@/types/contents';

import { UseGetContentByVersionProps } from './useGetContentByVersion.types';

export default function useGetContentByVersion({
  contentId,
  versionId,
  isCurrentDraft,
  type,
  ...props
}: UseGetContentByVersionProps) {
  return isCurrentDraft || type !== ENUM_CONTENT_TYPE.DRAFT
    ? useGetContentById({ contentId: versionId })
    : useGetContentVersionById({ contentId, versionId });
}
