import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import Img from '@/containers/ServiceIntegration/SearchConsole/Steps/Dialog/Img';
import LinkLikeButton from '@/containers/ServiceIntegration/SearchConsole/Steps/Dialog/LinkLikeButton';
import SubTitle from '@/containers/ServiceIntegration/SearchConsole/Steps/Dialog/SubTitle';
import Title from '@/containers/ServiceIntegration/SearchConsole/Steps/Dialog/Title';
import { SERVICES_LIST } from '@/utils/constants';

const Container = styled.div`
  padding: 2rem;
`;

function AccessDenied() {
  const { t } = useTranslation();

  return (
    <Container>
      <Img alt={SERVICES_LIST.SEARCH_CONSOLE.title} src={SERVICES_LIST.SEARCH_CONSOLE.logo} />
      <Title noMargin weight="medium">
        {t('service-integration:search-console.access-denied-modal.title')}
      </Title>
      <SubTitle color="dark060" padding="0" size="sm">
        <Trans i18nKey="service-integration:search-console.access-denied-modal.subtitle" t={t}>
          Semji was unable to connect to the Search Console data for this account.
          <br />
          Please, connect a Google account with Search Console properties.
        </Trans>
        <br />
        <LinkLikeButton
          as="a"
          href={t('common:links.help-connect-google-search-console')}
          margin="25px 0 0 0"
          rel="noopener noreferrer"
          target="_blank"
        >
          {t('service-integration:search-console.help.connect-search-console')}
        </LinkLikeButton>
      </SubTitle>
    </Container>
  );
}

export default AccessDenied;
