import React from 'react';
import styled from 'styled-components/macro';

const ContentProductionWrapper = styled.div`
  width: 100px;
`;

const OptimizationIcon = (props) => (
  <ContentProductionWrapper
    dangerouslySetInnerHTML={{
      __html: `
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<style type="text/css">
	.optim_icon_style_st0{fill:#E8EAEB;}
	.optim_icon_style_st1{fill:#D9DCDD;}
	.optim_icon_style_st2{fill:#989EA4;}
</style>
<g>
	<path class="optim_icon_style_st0" d="M456.4,401.6c0,10.7-91.3,19.3-203.9,19.3s-203.9-8.6-203.9-19.3c0-10.7,91.3-9.4,203.9-9.4
		S456.4,391,456.4,401.6z"/>
	<g>
		<path class="optim_icon_style_st0" d="M79.1,272.4H49.4c-4.6,0-8.3-3.7-8.3-8.3l0,0c0-4.6,3.7-8.3,8.3-8.3h29.7c4.6,0,8.3,3.7,8.3,8.3l0,0
			C87.4,268.7,83.6,272.4,79.1,272.4z"/>
		<path class="optim_icon_style_st0" d="M110.8,177H81.1c-4.6,0-8.3-3.7-8.3-8.3v0c0-4.6,3.7-8.3,8.3-8.3h29.7c4.6,0,8.3,3.7,8.3,8.3v0
			C119.1,173.3,115.3,177,110.8,177z"/>
		<path class="optim_icon_style_st0" d="M100.2,220H56.3c-4.6,0-8.3-3.7-8.3-8.3v0c0-4.6,3.7-8.3,8.3-8.3h43.9c4.6,0,8.3,3.7,8.3,8.3v0
			C108.5,216.2,104.8,220,100.2,220z"/>
		<path class="optim_icon_style_st0" d="M21.5,219.6h-7.8c-4.6,0-8.3-3.7-8.3-8.3v0c0-4.6,3.7-8.3,8.3-8.3h7.8c4.6,0,8.3,3.7,8.3,8.3v0
			C29.8,215.9,26.1,219.6,21.5,219.6z"/>
	</g>
	<path class="optim_icon_style_st0" d="M452.5,317.7H403c-4.6,0-8.3-3.7-8.3-8.3v0c0-4.6,3.7-8.3,8.3-8.3h49.5c4.6,0,8.3,3.7,8.3,8.3v0
		C460.8,313.9,457,317.7,452.5,317.7z"/>
	<path class="optim_icon_style_st0" d="M497.1,275.7h-29.6c-4.6,0-8.3-3.7-8.3-8.3l0,0c0-4.6,3.7-8.3,8.3-8.3h29.6c4.6,0,8.3,3.7,8.3,8.3l0,0
		C505.4,271.9,501.7,275.7,497.1,275.7z"/>
	<g>
		<path class="optim_icon_style_st0" d="M450,236.6h-23.4c-4.6,0-8.3-3.7-8.3-8.3v0c0-4.6,3.7-8.3,8.3-8.3H450c4.6,0,8.3,3.7,8.3,8.3v0
			C458.2,232.8,454.5,236.6,450,236.6z"/>
	</g>
	<g>
		<path class="optim_icon_style_st0" d="M438.7,275.8h-8.6c-4.6,0-8.3-3.7-8.3-8.3v0c0-4.6,3.7-8.3,8.3-8.3h8.6c4.6,0,8.3,3.7,8.3,8.3v0
			C447,272.1,443.3,275.8,438.7,275.8z"/>
	</g>
	<rect x="150.2" y="156" class="optim_icon_style_st0" width="211.1" height="195.6"/>
	<path class="optim_icon_style_st1" d="M260.5,119.8v-10.1c0-1.5-1.2-2.6-2.6-2.6H112.9c-1.5,0-2.6,1.2-2.6,2.6v277.7c0,1.5,1.2,2.6,2.6,2.6h296.8
		c1.5,0,2.6-1.2,2.6-2.6V204.2c0-1.5-1.2-2.6-2.6-2.6h-10.1c-1.5,0-2.6,1.2-2.6,2.6V372c0,1.5-1.2,2.6-2.6,2.6h-266
		c-1.5,0-2.6-1.2-2.6-2.6V125.1c0-1.5,1.2-2.6,2.6-2.6h129.5C259.3,122.5,260.5,121.3,260.5,119.8z"/>
	<g>
		<polygon class="optim_icon_style_st1" points="274.8,272.5 233.3,229.8 307.3,156.1 349.4,197.8 		"/>
		<polygon class="optim_icon_style_st1" points="356.9,188.6 315.6,147.4 333.3,129.3 374.7,170.6 		"/>
		<path class="optim_icon_style_st1" d="M396.6,152.2L396.6,152.2L381.2,168l-41.5-42.7l15.3-15.8c4.9-5,14-5,18.9,0l22.7,23.3c2.5,2.6,3.9,6,3.9,9.7
			C400.5,146.2,399.1,149.6,396.6,152.2z"/>
		<path class="optim_icon_style_st2" d="M408.8,136.2c0-5.5-2.1-10.7-6-14.6v0l-24.7-24.6c-7.8-7.8-21.4-7.8-29.2,0L217.5,228.4l-26.3,80.8l80.2-27
			l131.4-131.4C406.7,146.9,408.8,141.7,408.8,136.2z M224.3,291.5l-15.8-15.8l15.2-46.6l46.8,46.8L224.3,291.5z M274,270.8
			l-45.1-45.1l78.1-78.1l45.1,45.1L274,270.8z M356.5,188.3l-45.1-45.1l20.9-20.9l45.1,45.1L356.5,188.3z M398.4,146.4L398.4,146.4
			l-16.7,16.7L336.6,118l16.7-16.7c5.3-5.3,15.2-5.3,20.5,0l24.6,24.6c2.7,2.8,4.2,6.4,4.2,10.3C402.7,140,401.2,143.7,398.4,146.4z
			"/>
	</g>
	<g>
		<path class="optim_icon_style_st2" d="M252.1,112.4c1.5,0,2.6,1.2,2.6,2.6v10.1c0,1.5-1.2,2.6-2.6,2.6H122.6c-1.5,0-2.6,1.2-2.6,2.6v246.8
			c0,1.5,1.2,2.6,2.6,2.6h266c1.5,0,2.6-1.2,2.6-2.6V209.6c0-1.5,1.2-2.6,2.6-2.6H404c1.5,0,2.6,1.2,2.6,2.6v183.1
			c0,1.5-1.2,2.6-2.6,2.6H107.2c-1.5,0-2.6-1.2-2.6-2.6V115.1c0-1.5,1.2-2.6,2.6-2.6H252.1 M252.1,107.2H107.2
			c-4.4,0-7.9,3.6-7.9,7.9v277.7c0,4.4,3.6,7.9,7.9,7.9H404c4.4,0,7.9-3.6,7.9-7.9V209.6c0-4.4-3.6-7.9-7.9-7.9h-10.1
			c-4.4,0-7.9,3.6-7.9,7.9v165.1H125.3V133.1h126.9c4.4,0,7.9-3.6,7.9-7.9v-10.1C260,110.7,256.5,107.2,252.1,107.2L252.1,107.2z"/>
	</g>
</g>
</svg>
`,
    }}
    {...props}
  />
);

export default OptimizationIcon;
