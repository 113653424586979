import './CellLoader.scss';

import React from 'react';
import { FloatLoader } from 'semji-core/components/FloatLoader';

function CellLoader() {
  return (
    <div className="cell-loader">
      <div className="cell-loader__wrapper">
        <FloatLoader />
      </div>
    </div>
  );
}

export default CellLoader;
