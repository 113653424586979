import { nanoid } from 'nanoid';
import React, { useMemo } from 'react';

import SvgWrapper from './SvgWrapper';

function CancelIcon({ ...props }) {
  const id = useMemo(() => nanoid(), []);

  return (
    <SvgWrapper fill="none" height="20" viewBox="0 0 20 20" width="20" {...props}>
      <circle cx="10.0004" cy="10" fill="currentColor" r="9.77778" />
      <mask
        height="10"
        id={`mask0_${id}`}
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="10"
        x="5"
        y="5"
      >
        <path
          d="M10.7875 9.6496L14.8973 13.7594L14.4601 14.1966L10.3503 10.0868L6.24055 14.1966L5.80335 13.7594L9.91315 9.6496L5.80273 5.5398L6.23993 5.1026L10.3503 9.2124L14.4601 5.1026L14.8973 5.5398L10.7875 9.6496Z"
          fill="black"
        />
      </mask>
      <g mask={`url(#mask0_${id})`}>
        <rect fill="white" height="12.3677" width="12.3677" x="3.85742" y="3.7749" />
      </g>
    </SvgWrapper>
  );
}

export default CancelIcon;
