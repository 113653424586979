import React from 'react';

import SvgWrapper from './SvgWrapper';

const UserIcon = (props) => (
  <SvgWrapper {...props} viewBox="0 0 17 17">
    <path
      d="M10.448 16.3002L8.49994 12.6963L6.64929 16.3002H10.448Z"
      fill="none"
      stroke="currentColor"
      strokeWidth="0.9"
    />
    <path
      d="M3.82467 16.3002L8.5 7.63132L13.1753 16.3002H16L8.5 2.37158L1 16.3002H3.82467Z"
      fill="none"
      stroke="currentColor"
      strokeWidth="0.9"
    />
  </SvgWrapper>
);
export default UserIcon;
