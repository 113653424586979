import { StyledBlockText } from '@/components/Dialog/AddToPlanningDialog';
import { BlockText } from '@/components/Text/Inline';
import { getFormattedTitle } from '@/utils/title';

function AddToPlanningHeader({ data }) {
  const { title = '', url } = data || {};
  const formattedTitle = getFormattedTitle(title);

  return (
    <>
      <BlockText color="dark100" size="default" title={formattedTitle} weight={400}>
        {formattedTitle}
      </BlockText>
      {url && (
        <StyledBlockText color="dark040" size="xs" title={url} weight={300}>
          {url}
        </StyledBlockText>
      )}
    </>
  );
}

export default AddToPlanningHeader;
