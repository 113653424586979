import React from 'react';

import SvgWrapper from './SvgWrapper';

const Agenda = (props) => (
  <SvgWrapper {...props}>
    <path d="M14 2v-1h-3v1h-5v-1h-3v1h-3v15h17v-15h-3zM12 2h1v2h-1v-2zM4 2h1v2h-1v-2zM16 16h-15v-8.921h15v8.921zM1 6.079v-3.079h2v2h3v-2h5v2h3v-2h2v3.079h-15z" />
  </SvgWrapper>
);
export default Agenda;
