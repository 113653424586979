import i18next from 'i18next';
import SortDirection from 'react-virtualized/dist/es/Table/SortDirection';
import { getDecimalPlaces } from 'semji-core/utils/numbers';
import styled from 'styled-components/macro';

import DatePicker from '@/components/DatePicker';
import { NO_ANALYTICS, NO_SEARCH_CONSOLE } from '@/components/EmptyState/NoServiceIntegration';
import WarningTriangleIcon from '@/components/icons/WarningTriangleIcon';
import {
  computeDiffIsNew,
  computeDiffNoData,
  Diff,
  EvolutionInfoTooltip,
} from '@/components/Metrics/DiffPercentage';
import ContentScoreCell from '@/components/Table/Cell/ContentScoreCell';
import NotAcknowledgedCell from '@/components/Table/Cell/NotAcknowledgedCell';
import NotTrackedCell from '@/components/Table/Cell/NotTrackedCell';
import NumberCell from '@/components/Table/Cell/NumberCell';
import PictureCell from '@/components/Table/Cell/PictureCell';
import TitleCell from '@/components/Table/Cell/TitleCell';
import Table from '@/components/Table/Table';
import { LineClampEllipsis } from '@/components/Text/Ellipsis';
import { BrightText } from '@/components/Text/Light';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import { ChangeCell, ChangeLabel } from '@/containers/Report/components/PagesColumns/Change';
import { GainCell } from '@/containers/Report/components/PagesColumns/Gain';
import {
  MetricCell,
  metricDataGetter,
  MetricLabel,
} from '@/containers/Report/components/PagesColumns/Metric';
import PositionCell from '@/containers/Report/components/PagesColumns/Position';
import { TrendCell } from '@/containers/Report/components/PagesColumns/Trend';
import { DEFAULT_REPORT_METRIC_KEY, FROM_ORIGIN } from '@/utils/constants';
import { changeDataGetter } from '@/utils/dataFormatters/change';
import { gainDataGetter } from '@/utils/dataFormatters/gain';
import { ANALYTICS_DEPENDENCY, SEARCH_CONSOLE_DEPENDENCY } from '@/utils/metrics/constants';
import { METRIC_TYPE_NUMBER } from '@/utils/metrics/constants';
import { METRIC_POSITION_KEY } from '@/utils/metrics/constants';
import { METRICS_CONFIG_PERIODICITY_DAILY } from '@/utils/metrics/constants';
import { formatDateByPeriodicity } from '@/utils/metrics/formatDateByPeriodicity';

function getNoServiceIntegrationVersion(metric, hasSearchConsole, hasAnalytics) {
  if (metric?.dependency === SEARCH_CONSOLE_DEPENDENCY && !hasSearchConsole) {
    return NO_SEARCH_CONSOLE;
  }
  if (metric?.dependency === ANALYTICS_DEPENDENCY && !hasAnalytics) {
    return NO_ANALYTICS;
  }

  return null;
}

function getTooltipTitle(noServiceIntegrationVersion) {
  if (NO_SEARCH_CONSOLE === noServiceIntegrationVersion) {
    return 'No Search Console data source connected';
  }

  if (NO_ANALYTICS === noServiceIntegrationVersion) {
    return 'No Analytics data source connected';
  }

  return null;
}

const NoSearchConsoleApiAvailable = styled(TooltipComponent)`
  font-size: 1rem;
`;

function reportPagesColumnsBuilder({
  metrics,
  metricKey,
  periodicity,
  organizationId,
  workspaceId,
  period,
  positionComparisonDates,
  monthlyPageMetricsLoading,
  hasSearchConsole,
  hasAnalytics,
  userLanguageCode,
  countryName,
  comparisonDates,
}) {
  const noServiceIntegrationVersion = getNoServiceIntegrationVersion(
    metrics[metricKey],
    hasSearchConsole,
    hasAnalytics
  );
  let selectedMetricColumns = [];

  if (metricKey === METRIC_POSITION_KEY) {
    selectedMetricColumns.push(
      {
        align: 'center',
        cellDataGetter: ({ rowData }) => {
          return {
            focusTopKeyword: rowData.focusTopKeyword,
            isTracked: rowData.isTracked,
            isUnranked:
              !!rowData.dailyPageMetrics?.[positionComparisonDates.after]
                ?.focusTopKeywordsPositionBeyondTop100 &&
              rowData.dailyPageMetrics?.[positionComparisonDates.after]?.focusTopKeywordPosition ===
                100,

            previousPosition:
              rowData.dailyPageMetrics?.[positionComparisonDates.after]?.focusTopKeywordPosition,

            previousPositionComputed:
              rowData.dailyPageMetrics?.[positionComparisonDates.after]
                ?.focusTopKeywordPositionComputed,
          };
        },

        cellRenderer: ({ cellData }) => {
          const { suffix } = metrics['position'];
          if (!cellData.isTracked) {
            return <NotTrackedCell label={i18next.t('report:table.tooltips.not-tracked')} />;
          }
          return (
            <>
              {cellData.isUnranked && cellData.previousPositionComputed && <span>{`>`}</span>}
              <MetricCell
                isLoading={
                  cellData.previousPositionComputed === undefined && cellData.focusTopKeyword
                }
                suffix={suffix}
                type={metrics['position'].type}
                value={cellData.previousPosition}
              />
            </>
          );
        },
        dataKey: 'position-previous',
        label: (
          <TooltipComponent
            description={i18next.t('report:table.header.tooltip.previous-position')}
            placement="top"
            title={i18next.t('report:chart.position')}
          >
            <div>{i18next.t('report:chart.position')}</div>
            <BrightText capitalize size="small">
              {`(${formatDateByPeriodicity({
                date: positionComparisonDates.after,
                locale: i18next.language,
                periodicity: METRICS_CONFIG_PERIODICITY_DAILY,
              })})`}
            </BrightText>
          </TooltipComponent>
        ),
        sortFunction: (value1, value2, sortDirection) =>
          Table.naturalSort(value1.previousPosition, value2.previousPosition, sortDirection),
        sortable: true,
        width: 100,
      },
      {
        align: 'center',
        cellDataGetter: ({ rowData }) => {
          return {
            // Take daily position if possible (not null and ranked), else fallback on yersterday position
            currentPosition:
              (!rowData.dailyPageMetrics?.last2days?.[1]?.focusTopKeywordsPositionBeyondTop100
                ? rowData.dailyPageMetrics?.last2days?.[1]?.focusTopKeywordPosition
                : null) ??
              rowData.dailyPageMetrics?.last2days?.[0]?.focusTopKeywordPosition ??
              null,
            focusTopKeyword: rowData.focusTopKeyword,
            isTodayUnranked:
              !!rowData.dailyPageMetrics?.last2days?.[1]?.focusTopKeywordsPositionBeyondTop100 &&
              rowData.dailyPageMetrics?.last2days?.[1]?.focusTopKeywordPosition === 100,
            isTracked: rowData.isTracked,
            isYesterdayUnranked:
              !!rowData.dailyPageMetrics?.last2days?.[0]?.focusTopKeywordsPositionBeyondTop100 &&
              rowData.dailyPageMetrics?.last2days?.[0]?.focusTopKeywordPosition === 100,
            todayMetric: rowData.dailyPageMetrics?.last2days?.[1],
            yesterdayMetric: rowData.dailyPageMetrics?.last2days?.[0],
          };
        },
        cellRenderer: ({ cellData }) => {
          const { suffix } = metrics.position;
          if (!cellData.isTracked) {
            return <NotTrackedCell label={i18next.t('report:table.tooltips.not-tracked')} />;
          }

          const {
            currentPosition,
            todayMetric,
            yesterdayMetric,
            isTodayUnranked,
            isYesterdayUnranked,
            focusTopKeyword,
          } = cellData;
          const computed =
            todayMetric?.focusTopKeywordPositionComputed &&
            yesterdayMetric?.focusTopKeywordPositionComputed;
          const isUnranked = isTodayUnranked && isYesterdayUnranked;

          return (
            <>
              {isUnranked && computed && <span>{`>`}</span>}
              <MetricCell
                isLoading={!computed && focusTopKeyword}
                suffix={suffix}
                type={metrics.position.type}
                value={currentPosition}
              />
            </>
          );
        },
        dataKey: 'position-after',
        label: (
          <TooltipComponent
            description={i18next.t('report:table.header.tooltip.current-position')}
            placement="top"
            title={i18next.t('report:chart.position')}
          >
            <div>{i18next.t('report:chart.position')}</div>
            <BrightText capitalize size="small">
              {`(${formatDateByPeriodicity({
                date: positionComparisonDates.before,
                locale: i18next.language,
                periodicity: METRICS_CONFIG_PERIODICITY_DAILY,
              })})`}
            </BrightText>
          </TooltipComponent>
        ),
        sortFunction: (value1, value2, sortDirection) =>
          Table.naturalSort(value1.currentPosition, value2.currentPosition, sortDirection),
        sortable: true,
        width: 100,
      },
      {
        align: 'center',
        cellDataGetter: ({ rowData }) => {
          return {
            // Take daily position if possible (not null and ranked), else fallback on yersterday position
            currentPosition:
              (!rowData.dailyPageMetrics?.last2days?.[1]?.focusTopKeywordsPositionBeyondTop100
                ? rowData.dailyPageMetrics?.last2days?.[1]?.focusTopKeywordPosition
                : null) ??
              rowData.dailyPageMetrics?.last2days?.[0]?.focusTopKeywordPosition ??
              null,
            isPreviousPositionUnranked:
              !!rowData.dailyPageMetrics?.[positionComparisonDates.after]
                ?.focusTopKeywordsPositionBeyondTop100 &&
              rowData.dailyPageMetrics?.last2days?.[0]?.focusTopKeywordPosition === 100,
            isTodayUnranked:
              !!rowData.dailyPageMetrics?.last2days?.[1]?.focusTopKeywordsPositionBeyondTop100 &&
              rowData.dailyPageMetrics?.last2days?.[1]?.focusTopKeywordPosition === 100,
            isTracked: rowData.isTracked,
            isYesterdayUnranked:
              !!rowData.dailyPageMetrics?.last2days?.[0]?.focusTopKeywordsPositionBeyondTop100 &&
              rowData.dailyPageMetrics?.last2days?.[0]?.focusTopKeywordPosition === 100,
            previousPosition:
              rowData.dailyPageMetrics?.[positionComparisonDates.after]?.focusTopKeywordPosition,
          };
        },
        cellRenderer: ({ cellData }) => {
          if (!cellData.isTracked) {
            return <NotTrackedCell label={i18next.t('report:table.tooltips.not-tracked')} />;
          }
          return !cellData?.currentPosition ? (
            <MetricCell isLoading={true} />
          ) : (
            <Diff
              hoverElement={() => <EvolutionInfoTooltip />}
              initialValue={cellData.previousPosition}
              isInitialValueUnranked={cellData.isPreviousPositionUnranked}
              isValueUnranked={cellData.isTodayUnranked && cellData.isYesterdayUnranked}
              suffix=""
              value={cellData.currentPosition}
            />
          );
        },
        dataKey: 'change',
        label: (
          <TooltipComponent
            description={i18next.t('report:table.header.tooltip.evolution')}
            placement="top"
            title={i18next.t('report:table.header.evolution')}
          >
            {i18next.t('report:table.header.evolution')}
          </TooltipComponent>
        ),
        sortFunction: (a, b, sortDirection) =>
          computeDiffIsNew(a.previousPosition, a.isPreviousPositionUnranked) -
            computeDiffIsNew(b.previousPosition, b.isPreviousPositionUnranked) ||
          computeDiffNoData(
            a.currentPosition,
            a.isTodayUnranked && a.isYesterdayUnranked,
            a.isPreviousPositionUnranked
          ) -
            computeDiffNoData(
              b.currentPosition,
              b.isTodayUnranked && b.isYesterdayUnranked,
              b.isPreviousPositionUnranked
            ) ||
          (sortDirection === SortDirection.ASC
            ? a.previousPosition - a.currentPosition - (b.previousPosition - b.currentPosition)
            : b.previousPosition - b.currentPosition - (a.previousPosition - a.currentPosition)),
        sortable: true,
        width: 100,
      },
      {
        align: 'center',
        cellDataGetter: ({ rowData }) => ({ id: rowData.id, isTracked: rowData.isTracked }),
        cellRenderer: ({ cellData }) => {
          if (!cellData.isTracked) {
            return <NotTrackedCell label={i18next.t('report:table.tooltips.not-tracked')} />;
          }
          return noServiceIntegrationVersion ? (
            <NoSearchConsoleApiAvailable title={getTooltipTitle(noServiceIntegrationVersion)}>
              <WarningTriangleIcon />
            </NoSearchConsoleApiAvailable>
          ) : (
            <TrendCell
              metric={metrics[metricKey]}
              pageId={cellData.id}
              period={period}
              periodicity={periodicity}
              yAxisReversed
            />
          );
        },
        dataKey: 'pageMetrics',
        label: (
          <TooltipComponent
            description={i18next.t('report:table.header.tooltip.trend-position')}
            placement="top"
            title={i18next.t('report:table.header.trend-position')}
          >
            {i18next.t('report:table.header.trend-position')}
          </TooltipComponent>
        ),
        sortable: false,
        width: 150,
      },
      {
        align: 'center',
        cellDataGetter: ({ rowData }) => ({ id: rowData.id, isTracked: rowData.isTracked }),
        cellRenderer: ({ cellData }) => {
          if (!cellData.isTracked) {
            return <NotTrackedCell label={i18next.t('report:table.tooltips.not-tracked')} />;
          }
          return noServiceIntegrationVersion ? (
            <NoSearchConsoleApiAvailable title={getTooltipTitle(noServiceIntegrationVersion)}>
              <WarningTriangleIcon />
            </NoSearchConsoleApiAvailable>
          ) : (
            <PositionCell
              metric={metrics[metricKey]}
              pageId={cellData.id}
              period={period}
              periodicity={periodicity}
            />
          );
        },
        dataKey: 'top-100',
        label: (
          <TooltipComponent
            description={i18next.t('report:table.header.tooltip.top-100')}
            placement="top"
            title={i18next.t('report:table.header.top-100')}
          >
            {i18next.t('report:table.header.top-100')}
          </TooltipComponent>
        ),
        sortable: false,
        width: 125,
      }
    );
  }

  // The overview has specific columns
  if (metricKey === DEFAULT_REPORT_METRIC_KEY) {
    selectedMetricColumns.push(
      {
        align: 'center',
        cellDataGetter: ({ rowData }) =>
          metricDataGetter({
            date: comparisonDates.currentRange.after,
            metricKey: 'sessions',
            metricType: metrics['sessions'].type,
            rowData,
          }).value,
        cellRenderer: ({ cellData }) => {
          const { suffix } = metrics['sessions'];
          return (
            <MetricCell
              isLoading={monthlyPageMetricsLoading}
              suffix={suffix}
              type={metrics['sessions'].type}
              value={cellData}
            />
          );
        },
        dataKey: 'traffic',
        label: (
          <TooltipComponent
            description={i18next.t('report:table.header.tooltip.sessions')}
            placement="top"
            title={i18next.t('report:metric-label.title-female_interval', {
              count: 2,
              name: i18next.t(metrics['sessions'].name),
              postProcess: 'interval',
            })}
          >
            <MetricLabel
              count={2}
              date={comparisonDates.currentRange.after}
              i18nextContext="female"
              name={i18next.t(metrics['sessions'].name)}
            />
          </TooltipComponent>
        ),
        sortable: true,
        width: 100,
      },
      {
        align: 'center',
        cellDataGetter: ({ rowData }) =>
          metricDataGetter({
            date: comparisonDates.currentRange.after,
            metricKey: 'conversions',
            metricType: metrics['conversions'].type,
            rowData,
          }),
        cellRenderer: ({ cellData }) => {
          const { suffix } = metrics['conversions'];
          return (
            <MetricCell
              isLoading={monthlyPageMetricsLoading}
              suffix={suffix}
              type={cellData.metricType}
              value={cellData.value}
            />
          );
        },
        dataKey: 'conversion',
        label: (
          <TooltipComponent
            description={i18next.t('report:table.header.tooltip.conversions')}
            placement="top"
            title={i18next.t('report:metric-label.title-female_interval', {
              context: 'female',
              count: 2,
              name: i18next.t(metrics['conversions'].name),
              postProcess: 'interval',
            })}
          >
            <MetricLabel
              count={2}
              date={comparisonDates.currentRange.after}
              i18nextContext="female"
              name={i18next.t(metrics['conversions'].name)}
            />
          </TooltipComponent>
        ),
        sortable: true,
        width: 100,
      }
    );
  } else if (metricKey !== DEFAULT_REPORT_METRIC_KEY && metricKey !== METRIC_POSITION_KEY) {
    selectedMetricColumns.push(
      {
        align: 'center',
        cellDataGetter: ({ rowData }) => rowData.id,
        cellRenderer: ({ cellData }) =>
          noServiceIntegrationVersion ? (
            <NoSearchConsoleApiAvailable title={getTooltipTitle(noServiceIntegrationVersion)}>
              <WarningTriangleIcon />
            </NoSearchConsoleApiAvailable>
          ) : (
            <TrendCell
              metric={metrics[metricKey]}
              pageId={cellData}
              period={period}
              periodicity={periodicity}
            />
          ),
        dataKey: 'pageMetrics',
        label: (
          <TooltipComponent
            description={i18next.t('report:table.header.tooltip.trend')}
            placement="top"
            title={i18next.t('report:table.header.trend')}
          >
            {i18next.t('report:table.header.trend')}
          </TooltipComponent>
        ),
        sortable: false,
        width: 150,
      },
      {
        align: 'center',
        cellDataGetter: ({ rowData }) =>
          metricDataGetter({
            date: comparisonDates.currentRange.after,
            metricKey,
            metricType: metrics[metricKey].type,
            rowData,
          }).value,
        cellRenderer: ({ cellData }) => {
          const { suffix } = metrics[metricKey];
          if (noServiceIntegrationVersion) {
            return (
              <NoSearchConsoleApiAvailable title={getTooltipTitle(noServiceIntegrationVersion)}>
                <WarningTriangleIcon />
              </NoSearchConsoleApiAvailable>
            );
          }

          return (
            <MetricCell
              isLoading={monthlyPageMetricsLoading}
              suffix={suffix}
              type={metrics[metricKey].type}
              value={cellData}
            />
          );
        },
        dataKey: `current_metric_key_${metricKey}`,
        label: (
          <TooltipComponent
            description={i18next.t(`report:table.header.tooltip.${metricKey}`, {
              context: metrics[metricKey].tooltip?.context || 'male',
              count: metrics[metricKey].tooltip?.count || 1,
              goal: i18next.t(metrics[metricKey].name),
              metric: i18next.t(metrics[metricKey].name),
            })}
            placement="top"
            title={i18next.t('report:metric-label.title_interval', {
              context: metrics[metricKey].tooltip?.context || 'male',
              count: metrics[metricKey].tooltip?.count || 1,
              name: i18next.t(metrics[metricKey].name),
              postProcess: 'interval',
            })}
          >
            <MetricLabel
              count={metrics[metricKey].tooltip?.count || 1}
              date={comparisonDates.currentRange.after}
              i18nextContext={metrics[metricKey].tootip?.context || 'male'}
              name={i18next.t(metrics[metricKey].name)}
            />
          </TooltipComponent>
        ),
        sortable: true,
        width: 100,
      },
      {
        align: 'center',
        cellDataGetter: ({ rowData }) =>
          changeDataGetter({
            date1: comparisonDates.currentRange.after,
            date2: comparisonDates.previousRange.after,
            locale: userLanguageCode,
            metricKey,
            metricType: metrics[metricKey].type,
            rowData,
          }),
        cellRenderer: ({ cellData }) => {
          const { evolutionRating, suffix } = metrics[metricKey];
          if (noServiceIntegrationVersion) {
            return (
              <NoSearchConsoleApiAvailable title={getTooltipTitle(noServiceIntegrationVersion)}>
                <WarningTriangleIcon />
              </NoSearchConsoleApiAvailable>
            );
          }
          return (
            <ChangeCell
              evolutionRating={evolutionRating}
              isLoading={monthlyPageMetricsLoading}
              metricValue={cellData}
              suffix={suffix}
            />
          );
        },
        dataKey: 'change',
        label: (
          <TooltipComponent
            description={i18next.t('report:table.header.tooltip.change')}
            placement="top"
            title={i18next.t('report:change-label.title')}
          >
            <ChangeLabel date={comparisonDates.previousRange.after} />
          </TooltipComponent>
        ),
        sortFunction: (value1, value2, sortDirection) =>
          Table.naturalSort(value1?.initialValue, value2?.initialValue, sortDirection),
        sortable: true,
        width: 100,
      },
      {
        align: 'center',
        cellDataGetter: ({ rowData }) =>
          gainDataGetter({
            date1: comparisonDates.currentRange.after,
            date2: comparisonDates.previousRange.after,
            locale: userLanguageCode,
            metricKey,
            rowData,
          }),
        cellRenderer: ({ cellData }) =>
          noServiceIntegrationVersion ? (
            <NoSearchConsoleApiAvailable title={getTooltipTitle(noServiceIntegrationVersion)}>
              <WarningTriangleIcon />
            </NoSearchConsoleApiAvailable>
          ) : (
            <GainCell
              evolutionRating={metrics[metricKey].evolutionRating}
              gainValue={cellData}
              isLoading={monthlyPageMetricsLoading}
              metricKey={metricKey}
              periodicity={periodicity}
            />
          ),
        dataKey: metricKey + '_percentage_gain',
        label: (
          <TooltipComponent
            description={i18next.t('report:table.header.tooltip.pourcent')}
            placement="top"
            title="%"
          >
            %
          </TooltipComponent>
        ),
        sortFunction: (value1, value2, sortDirection) =>
          Table.naturalSort(value1?.initialValue, value2?.initialValue, sortDirection),
        sortable: true,
        width: 75,
      }
    );
  }

  const positionMetricColumn =
    metricKey === METRIC_POSITION_KEY
      ? []
      : [
          {
            align: 'center',
            cellDataGetter: ({ rowData }) => rowData.focusTopKeyword?.position,
            cellRenderer: ({ cellData }) =>
              !isNaN(cellData) && null !== cellData && 0 !== cellData ? (
                <NumberCell number={cellData} />
              ) : (
                <NotAcknowledgedCell />
              ),
            dataKey: 'position',
            label: (
              <TooltipComponent
                description={i18next.t('report:table.header.tooltip.position', {
                  country: countryName,
                })}
                placement="top"
                title={i18next.t('report:table.header.position')}
              >
                {i18next.t('report:table.header.position')}
              </TooltipComponent>
            ),
            sortable: true,
            width: 60,
          },
        ];

  return [
    {
      cellDataGetter: ({ rowData }) => rowData.optimizedImages.small,
      cellRenderer: ({ cellData }) => <PictureCell pictureUrl={cellData} />,
      dataKey: 'imageUrl',
      label: ' ',
      width: 60,
    },
    {
      cellDataGetter: ({ rowData }) => ({
        isRedirectionDetected: !!rowData.redirectionUrl,
        lastStatusCode: rowData.lastStatusCode,
        pageId: rowData.id,
        title: rowData.title,
        url: rowData.url,
        workspaceId: workspaceId,
      }),
      cellRenderer: ({ cellData }) => (
        <TitleCell
          isRedirectionDetected={cellData.isRedirectionDetected}
          lastStatusCode={cellData.lastStatusCode}
          organizationId={organizationId}
          pageId={cellData.pageId}
          title={cellData.title}
          type={FROM_ORIGIN.REPORT}
          url={cellData.url}
          workspaceId={cellData.workspaceId}
        />
      ),
      dataKey: 'title',
      flexGrow: 1,
      label: (
        <TooltipComponent
          description={i18next.t('report:table.header.tooltip.page')}
          placement="top"
          title={i18next.t('report:table.header.page')}
        >
          <>{i18next.t('report:table.header.page')}</>
        </TooltipComponent>
      ),
      sortFunction: (value1, value2, sortDirection) =>
        Table.stringSort(value1.title, value2.title, sortDirection),
      sortable: true,
      width: 150,
    },
    {
      align: 'center',
      cellDataGetter: ({ rowData }) => rowData.contentScore,
      cellRenderer: ({ cellData }) =>
        cellData !== null ? (
          <ContentScoreCell contentScore={cellData} />
        ) : (
          <NotAcknowledgedCell align="center" />
        ),
      dataKey: 'contentScore',
      label: (
        <TooltipComponent
          description={i18next.t('report:table.header.tooltip.content-score')}
          placement="top"
          title={i18next.t('report:table.header.content-score')}
        >
          <>{i18next.t('report:table.header.content-score')}</>
        </TooltipComponent>
      ),
      sortable: true,
      width: 100,
    },
    {
      cellDataGetter: ({ rowData }) => rowData.focusTopKeyword?.keyword,
      cellRenderer: ({ cellData }) => (
        <LineClampEllipsis title={cellData}>{cellData}</LineClampEllipsis>
      ),
      dataKey: '"focusKeyword"',
      label: (
        <TooltipComponent
          description={i18next.t('report:table.header.tooltip.focus-keyword')}
          placement="top"
          title={i18next.t('report:table.header.focus-keyword')}
        >
          <>{i18next.t('report:table.header.focus-keyword')}</>
        </TooltipComponent>
      ),
      sortFunction: Table.stringSort,
      sortable: true,
      width: 125,
    },

    {
      align: 'center',
      cellDataGetter: ({ rowData }) => rowData.focusTopKeyword?.searchVolume,

      cellRenderer: ({ cellData }) => {
        if (!isNaN(cellData) && null !== cellData && 0 !== Math.round(cellData)) {
          const decimalPlaces = getDecimalPlaces(cellData, METRIC_TYPE_NUMBER);
          return <NumberCell decimalPlaces={decimalPlaces} number={Math.round(cellData)} />;
        } else {
          return <NotAcknowledgedCell />;
        }
      },
      dataKey: 'volume',
      label: (
        <TooltipComponent
          description={i18next.t('report:table.header.tooltip.volume')}
          placement="top"
          title={i18next.t('report:table.header.volume')}
        >
          <>{i18next.t('report:table.header.volume')}</>
        </TooltipComponent>
      ),
      sortable: true,
      width: 60,
    },
    ...positionMetricColumn,
    ...selectedMetricColumns,
    {
      align: 'center',
      cellDataGetter: ({ rowData }) => rowData.extra.lastPublishedAt,
      cellRenderer: ({ cellData }) => (
        <DatePicker defaultColor disabled dueDate={cellData} shortVersion />
      ),
      dataKey: 'lastPublicationDate',
      label: (
        <TooltipComponent
          description={i18next.t('report:table.header.tooltip.last-publication')}
          placement="top"
          title={i18next.t('report:table.header.last-publication')}
        >
          <>{i18next.t('report:table.header.last-publication')}</>
        </TooltipComponent>
      ),
      sortable: true,
      width: 100,
    },
  ];
}

export default reportPagesColumnsBuilder;
