import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import ThreeDotsIcon from '@/components/icons/ThreeDotsIcon';
import TooltipComponent from '@/components/Tooltip/Tooltip';

const Tooltip = styled(TooltipComponent)`
  cursor: not-allowed;
`;

function WaitLabel() {
  const { t } = useTranslation();

  return (
    <Tooltip title={t('content:brief.waiting-limit-ai-tooltip')}>
      <ThreeDotsIcon />
      &nbsp;&nbsp;{t('content:brief.waiting-limit-ai-label')}
    </Tooltip>
  );
}

export default WaitLabel;
