import './DashboardGraphs.scss';

import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import DashboardReportCard from '@/containers/Dashboard/DashboardReportCard';
import { ReportGraphContainer } from '@/containers/Report/ReportMetric/ReportGraphContainer';
import { ReportMetricKey, ReportView } from '@/containers/Report/utils/types';
import { useMetricsConfig } from '@/hooks/useMetricsConfig';
import { selectHasAnalytics } from '@/store/selectors/selectHasAnalytics';
import { selectHasSearchConsole } from '@/store/selectors/selectHasSearchConsole';
import { ReportPeriod, ReportPeriodicity } from '@/types/reports';
import { ANALYTICS_DEPENDENCY, SEARCH_CONSOLE_DEPENDENCY } from '@/utils/metrics/constants';

export default function DashboardGraph({
  period,
  periodicity,
  comparisonPeriod,
  reportView,
  count,
  loading,
}: {
  period: ReportPeriod;
  periodicity: ReportPeriodicity;
  comparisonPeriod: string;
  reportView: ReportView;
  count: number;
  loading: boolean;
}) {
  const [metricKey, setMetricKey] = useState<ReportMetricKey>(ReportMetricKey.Clicks);
  const [goalKey, setGoalKey] = useState<string>();
  const { t } = useTranslation();
  const metrics = useMetricsConfig({ isNew: true });

  const hasAnalytics = useSelector(selectHasAnalytics);
  const hasSearchConsole = useSelector(selectHasSearchConsole);
  const isAnalyticsEmptyState =
    !hasAnalytics && metrics[metricKey]?.dependency === ANALYTICS_DEPENDENCY;
  const isSearchConsoleEmptyState =
    !hasSearchConsole && metrics[metricKey]?.dependency === SEARCH_CONSOLE_DEPENDENCY;
  const noServiceIntegration = isAnalyticsEmptyState || isSearchConsoleEmptyState;

  function selectMetricClick(metricKey: ReportMetricKey) {
    if (goalKey) {
      setGoalKey(undefined);
    }
    setMetricKey(metricKey);
  }

  function handleGraphSelectMetric(newMetricKey: ReportMetricKey, newGoalKey: string) {
    if (newGoalKey) {
      setGoalKey(newGoalKey);
    } else {
      setGoalKey(undefined);
      selectMetricClick(newMetricKey);
    }
  }

  return (
    <DashboardReportCard
      currentMetricKey={metricKey}
      reportView={reportView}
      setCurrentMetricKey={selectMetricClick}
      showFooter={!loading && count > 0 && !noServiceIntegration}
      title={
        reportView === 'new'
          ? t('dashboard:graphs.new-content-title_interval', {
              count: loading ? '-' : count,
              postProcess: 'interval',
            })
          : t('dashboard:graphs.updated-content-title_interval', {
              count: loading ? '-' : count,
              postProcess: 'interval',
            })
      }
    >
      <ReportGraphContainer
        comparisonPeriod={comparisonPeriod}
        currentCount={count}
        customGoalKey={goalKey}
        filteredPagesIds={[]}
        isLoading={loading}
        metricKey={metricKey}
        period={period}
        periodicity={periodicity}
        reportView={reportView}
        selectMetricClick={handleGraphSelectMetric}
      />
    </DashboardReportCard>
  );
}
