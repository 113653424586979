let mixpanel;
const MIXPANEL_TOKEN = import.meta.env.VITE_REACT_APP_MIXPANEL_TOKEN;

if (MIXPANEL_TOKEN) {
  import(/* webpackChunkName: "mixpanel" */ 'mixpanel-browser').then((myModule) => {
    mixpanel = myModule.default;
    initMixpanel(MIXPANEL_TOKEN);
  });
}

function loadMixpanel() {
  return new Promise((resolve) => {
    if (MIXPANEL_TOKEN) {
      import(/* webpackChunkName: "mixpanel" */ 'mixpanel-browser').then((myModule) => {
        mixpanel = myModule.default;
        initMixpanel(MIXPANEL_TOKEN);
        resolve();
      });
    }
  });
}

function initMixpanel() {
  if (MIXPANEL_TOKEN) {
    mixpanel.init(MIXPANEL_TOKEN);
  }
}

export const trackEvent = (event, data = {}) => {
  if (MIXPANEL_TOKEN) {
    if (mixpanel && mixpanel.track) {
      mixpanel.track(event, { ...data });
    } else {
      loadMixpanel().then(() => mixpanel.track(event, { ...data }));
    }
  }
};

export const register = (data) => {
  if (MIXPANEL_TOKEN) {
    if (mixpanel && mixpanel.register) {
      mixpanel.register({ ...data });
    } else {
      loadMixpanel().then(() => mixpanel.register({ ...data }));
    }
  }
};
