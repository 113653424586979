import './GridHeader.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { FloatLoader } from 'semji-core/components/FloatLoader';
import { Tooltip } from 'semji-core/components/Tooltip';
import { ExportIcon } from 'semji-core/icons/ExportIcon';
import { PencilIcon } from 'semji-core/icons/PencilIcon';
import { PlusIcon } from 'semji-core/icons/PlusIcon';
import { getRoundedNumberMetricWithSuffix } from 'semji-core/utils/numbers';

import { GridHeaderProps } from '@/containers/Competitors/DataExplorer/GridHeader/GridHeader.types';

function GridHeader({
  nbPages,
  traffic,
  onExportClick,
  onAddToPlanningClick,
  onModifyClick,
  isLoading,
  gridSelectionHook,
}: GridHeaderProps) {
  const { t } = useTranslation();

  const { selection, isAllSelected, selectAll, deselectAll } = gridSelectionHook;

  if (selection.length === 0) {
    return (
      <div className="data-explorer-grid-header">
        <div className="data-explorer-grid-header__data data-explorer-grid-header__divider_left_small">
          {isLoading ? (
            <FloatLoader />
          ) : (
            t('competitors:data-explorer.total.pages', {
              count: getRoundedNumberMetricWithSuffix({
                number: nbPages,
              }) as any,
            })
          )}
        </div>
        <div className="data-explorer-grid-header__data data-explorer-grid-header__divider_right_small">
          {isLoading ? (
            <FloatLoader />
          ) : (
            t('competitors:data-explorer.total.traffic', {
              count: getRoundedNumberMetricWithSuffix({
                number: traffic,
              }) as any,
            })
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="data-explorer-grid-header">
      <div className="data-explorer-grid-header__selection data-explorer-grid-header__divider_left">
        <div className="data-explorer-grid-header__data">{`${isAllSelected ? nbPages : selection.length} ${t('competitors:data-explorer.grid-header.selected')}`}</div>
        <div
          className="data-explorer-grid-header__data data-explorer-grid-header__actions__button"
          onClick={isAllSelected ? deselectAll : selectAll}
        >
          {isAllSelected ? (
            <>{t('competitors:data-explorer.grid-header.unselect-all')}</>
          ) : (
            <>
              {t('competitors:data-explorer.grid-header.select-all')} ({nbPages})
            </>
          )}
        </div>
      </div>

      <div className="data-explorer-grid-header__actions data-explorer-grid-header__divider_right">
        {isAllSelected || selection.length > 100 ? (
          <Tooltip
            className="data-explorer-grid-header__actions__button data-explorer-grid-header__actions__button--disabled"
            isFrame={false}
            noDivider={true}
            popoverOptions={{ placement: 'bottom' }}
            title={t('add-to-planning:disable-add-tooltip')}
          >
            <PlusIcon />
            <span>{t('add-to-planning:add-to-planning')}</span>
          </Tooltip>
        ) : (
          <div
            className="data-explorer-grid-header__actions__button"
            onClick={onAddToPlanningClick}
          >
            <PlusIcon />
            <span>{t('add-to-planning:add-to-planning')}</span>
          </div>
        )}

        <div className="data-explorer-grid-header__actions__button" onClick={onModifyClick}>
          <PencilIcon />
          <div>{t('competitors:data-explorer.grid-header.modify')}</div>
        </div>

        <div className="data-explorer-grid-header__actions__button" onClick={onExportClick}>
          <ExportIcon />
          {t('competitors:data-explorer.actions.export')}
        </div>
      </div>
    </div>
  );
}

export default GridHeader;
