import './ExpandableUserList.scss';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { PureAvatar } from 'semji-core/components/Avatar';
import { Button, ENUM_BUTTON_VARIANTS } from 'semji-core/components/Button';
import { Tooltip } from 'semji-core/components/Tooltip';
import { DownIcon, ENUM_DOWN_ICON_DIRECTION } from 'semji-core/icons/DownIcon';

import { getWorkspaceById } from '@/store/reducers';
import { UserModel as User } from '@/types/user/user.types';
import { Workspace } from '@/types/workspace';
import { getFullNameFromUser } from '@/utils/user';

import { ExpandableUserListProps } from './ExpandableUserList.types';

export default function ExpandableUserList({
  users,
  emailBodyKey,
  emailSubjectKey,
}: ExpandableUserListProps) {
  const apiRootUrl = import.meta.env.VITE_REACT_APP_SEMJI_API_ROOT_URL;
  const { workspaceId } = useParams();
  const [displayAllUsers, setDisplayAllUsers] = useState(false);
  function toggleDisplayAllUsers() {
    setDisplayAllUsers(!displayAllUsers);
  }
  const { t } = useTranslation();

  const currentWorkspace: Workspace = useSelector((state: any) =>
    getWorkspaceById(state, workspaceId)
  );
  const currentUser = useSelector((state: any) => state.user);

  const currentWorkspaceName = currentWorkspace?.name;
  function getItemHref(user: User) {
    const adminName = getFullNameFromUser(user);
    const userName = getFullNameFromUser(currentUser);
    const emailSubject = t(emailSubjectKey, {
      user: userName,
    });
    const emailBody = t(emailBodyKey, {
      admin: adminName || '',
      user: userName,
      workspace: currentWorkspaceName,
    });
    return `mailto:${user.email}?subject=${emailSubject}&body=${encodeURI(emailBody)}`;
  }

  const usersToDisplay = displayAllUsers ? users : users?.slice(0, 3);
  return (
    <div className="expandable-user-list">
      <div className="expandable-user-list__flexbox">
        {usersToDisplay?.map((user) => {
          return (
            user.lastLoginAt && (
              <Tooltip key={user.id} title={user.email}>
                <a className="expandable-user-list__flexbox__item" href={getItemHref(user)}>
                  <PureAvatar
                    apiRootUrl={apiRootUrl}
                    email={user.email ?? ''}
                    firstName={user.firstName || ''}
                    id={user.id}
                    lastName={user.lastName || ''}
                    profileImageHash={user.profileImageHash || null}
                    translations={{ unassigned: t('common:user-picker.unassigned') }}
                  />
                  <span className="expandable-user-list__flexbox__item__name">
                    {getFullNameFromUser(user) || user.email}
                  </span>
                </a>
              </Tooltip>
            )
          );
        })}
      </div>
      {users.length > 3 && !displayAllUsers && (
        <div className="expandable-user-list__show-more">
          <Button variant={ENUM_BUTTON_VARIANTS.Text} onClick={toggleDisplayAllUsers}>
            {displayAllUsers
              ? t('competitors:restriction-empty-state.show-less')
              : t('competitors:restriction-empty-state.show-more')}
            <DownIcon
              direction={
                displayAllUsers ? ENUM_DOWN_ICON_DIRECTION.UP : ENUM_DOWN_ICON_DIRECTION.DOWN
              }
            />
          </Button>
        </div>
      )}
    </div>
  );
}
