import './PositionTooltip.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { getRoundedNumberMetricObject } from 'semji-core/utils/numbers';
import { capitalize } from 'semji-core/utils/string';

import { Point } from '@/components/Chart/Chart.types';
import { LegendItemIconPlain } from '@/components/Chart/LegendItem';

export default function PositionTooltip({
  points,
  locale = 'fr',
}: {
  points: Point[];
  locale: string;
}) {
  const { t } = useTranslation();
  const total = points.reduce((acc, point) => acc + parseInt(point.realValue), 0);
  const totalRoundedObj = getRoundedNumberMetricObject({ locale, number: total });

  return (
    <div className="highchart-charts-position-tooltip">
      <div className="highchart-charts-position-tooltip__period">
        {capitalize(points[0].period)}
      </div>
      <div className="highchart-charts-position-tooltip__list">
        {points.map((point, index) => {
          return (
            <div key={index} className="highchart-charts-position-tooltip__point">
              <div className="highchart-charts-position-tooltip__point__label">
                <LegendItemIconPlain color={point.color} />
                <span>{t(point.label)}</span>
              </div>
              <div className="highchart-charts-position-tooltip__point__value">
                {point.value}
                {point.suffix}
              </div>
            </div>
          );
        })}
        <div className="highchart-charts-position-tooltip__point highchart-charts-position-tooltip__point--total">
          <div className="highchart-charts-position-tooltip__point__label">
            {t('chart:tooltip.total')}
          </div>
          <div className="highchart-charts-position-tooltip__point__value">
            {totalRoundedObj.value}
            {totalRoundedObj.suffix}
          </div>
        </div>
      </div>
    </div>
  );
}
