export const ID_TITLE = 'title-editor';
export const TITLE_SELECTOR = `#${ID_TITLE}`;

export const ID_METADESCRIPTION = 'metadescription-editor';
export const ID_METADESCRIPTION_CONTAINER = 'metadescription-container';
export const ID_METADESCRIPTION_WRAPPER = 'metadescription-wrapper';
export const METADESCRIPTION_SELECTOR = `#${ID_METADESCRIPTION}`;
export const METADESCRIPTION_WRAPPER_SELECTOR = `#${ID_METADESCRIPTION_WRAPPER}`;

export const ID_CONTENT = 'content-editor';
export const CONTENT_SELECTOR = `#${ID_CONTENT}`;
export const ID_CONTENT_CONTAINER = 'content-container';

export const ID_TOOLBAR = 'toolbar-editor';
export const TOOLBAR_SELECTOR = `#${ID_TOOLBAR}`;

export const ID_TITLE_CONTENT_CONTAINER = 'title-content-container';
export const TITLE_CONTENT_CONTAINER_SELECTOR = `#${ID_TITLE_CONTENT_CONTAINER}`;

export const ALLOWED_ATTRIBUTES_FOR_SAVE = ['href', 'target', 'src', 'title'];
