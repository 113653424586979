import AIWriting from '@/containers/Onboarding/CreateNewContent/AIWriting';
import NewContentContentScore from '@/containers/Onboarding/CreateNewContent/ContentScore';
import CreateNewContent from '@/containers/Onboarding/CreateNewContent/CreateNewContent';
import NewContentPublishContent from '@/containers/Onboarding/CreateNewContent/PublishContent';
import DiscoverSemjiVideo from '@/containers/Onboarding/DiscoverSemjiVideo/DiscoverSemjiVideo';
import InviteYourTeam from '@/containers/Onboarding/InviteYourTeam/InviteYourTeam';
import OptimizeContentContentScore from '@/containers/Onboarding/OptimizeContent/ContentScore';
import LaunchAnalysis from '@/containers/Onboarding/OptimizeContent/LaunchAnalysis';
import OpitmizeContentPublishContent from '@/containers/Onboarding/OptimizeContent/PublishContent';
import SearchConsole from '@/containers/Onboarding/OptimizeContent/SearchConsole';
import TrackPerformances from '@/containers/Onboarding/TrackPerformances/TrackPerformances';

export const VIDEO_VALIDATION_TIME = 0.8;

export const DISCOVER_SEMJI_VIDEO = 'discover-semji:see-video';
const LAUNCH_ANALYSIS_EXISTING_CONTENT = 'existing-content:launch-analysis';
const OBTAIN_75_CONTENT_SCORE_EXISTING_CONTENT = 'existing-content:obtain-75-content-score';
const PUBLISH_EXISTING_CONTENT = 'existing-content:publish-content';
const CREATE_DRAFT_NEW_CONTENT = 'new-content:create-draft';
const CREATE_DRAFT_AI_WRITING = 'new-content:ai-writing';
const OBTAIN_75_CONTENT_SCORE_NEW_CONTENT = 'new-content:obtain-75-content-score';
const PUBLISH_NEW_CONTENT = 'new-content:publish-content';
const INVITE_USER = 'users:invite-user';
const CONNECT_SEARCH_CONSOLE = 'service-integration:connect-search-console';
export const TRACK_PERFORMANCE_VIDEO = 'track-performance:see-video';

export const getUpdatedAccordionList = (workspaceAchievements) => {
  const isAchievementsValidated = (achievementsToValidate) =>
    achievementsToValidate.every((achievementToValidate) =>
      workspaceAchievements.some((achievement) => achievement.code === achievementToValidate)
    );

  return ACCORDION_LIST.map((step) =>
    step.children.length
      ? {
          ...step,
          children: step.children.map((children) => ({
            ...children,
            validated: isAchievementsValidated(children.achievementsToValidate),
          })),
        }
      : { ...step, validated: isAchievementsValidated(step.achievementsToValidate) }
  );
};

const ACCORDION_LIST = [
  {
    achievementsToValidate: [DISCOVER_SEMJI_VIDEO],
    children: [],
    id: 'get-to-know-semji',
    render: <DiscoverSemjiVideo />,
    title: 'quick-start-guide:body.accordion.discover-semji.title',
    validated: false,
  },
  {
    achievementsToValidate: [INVITE_USER],
    children: [],
    id: 'invite-your-team',
    render: <InviteYourTeam />,
    title: 'quick-start-guide:body.accordion.invite-your-team.title',
    validated: false,
  },
  {
    children: [
      {
        achievementsToValidate: [CREATE_DRAFT_NEW_CONTENT],
        childrenRender: <CreateNewContent />,
        id: 'create-new-content-draft',
        title: 'quick-start-guide:body.accordion.create-new-content.create-new-content.title',
        validated: false,
      },
      {
        achievementsToValidate: [CREATE_DRAFT_AI_WRITING],
        childrenRender: <AIWriting />,
        id: 'create-new-content-ai-writing',
        title: 'quick-start-guide:body.accordion.create-new-content.ai-writing.title',
        validated: false,
      },
      {
        achievementsToValidate: [OBTAIN_75_CONTENT_SCORE_NEW_CONTENT],
        childrenRender: <NewContentContentScore />,
        id: 'create-new-content-obtain-75-content-score',
        title: 'quick-start-guide:body.accordion.create-new-content.content-score.title',
        validated: false,
      },
      {
        achievementsToValidate: [PUBLISH_NEW_CONTENT],
        childrenRender: <NewContentPublishContent />,
        id: 'create-new-content-publish-content',
        title: 'quick-start-guide:body.accordion.create-new-content.publish-content.title',
        validated: false,
      },
    ],
    id: 'create-new-content',
    title: 'quick-start-guide:body.accordion.create-new-content.title',
  },
  {
    children: [
      {
        achievementsToValidate: [CONNECT_SEARCH_CONSOLE],
        childrenRender: <SearchConsole />,
        id: 'optimize-existing-content-connect-search-console',
        title: 'quick-start-guide:body.accordion.optimise-existing-content.search-console.title',
        validated: false,
      },
      {
        achievementsToValidate: [LAUNCH_ANALYSIS_EXISTING_CONTENT],
        childrenRender: <LaunchAnalysis />,
        id: 'optimize-existing-content-launch-analysis',
        title: 'quick-start-guide:body.accordion.optimise-existing-content.launch-analysis.title',
        validated: false,
      },
      {
        achievementsToValidate: [OBTAIN_75_CONTENT_SCORE_EXISTING_CONTENT],
        childrenRender: <OptimizeContentContentScore />,
        id: 'optimize-existing-content-obtain-75-content-score',
        title: 'quick-start-guide:body.accordion.optimise-existing-content.content-score.title',
        validated: false,
      },
      {
        achievementsToValidate: [PUBLISH_EXISTING_CONTENT],
        childrenRender: <OpitmizeContentPublishContent />,
        id: 'optimize-existing-content-publish-content',
        title: 'quick-start-guide:body.accordion.optimise-existing-content.publish-content.title',
        validated: false,
      },
    ],
    id: 'optimize-existing-content',
    title: 'quick-start-guide:body.accordion.optimise-existing-content.title',
  },
  {
    achievementsToValidate: [TRACK_PERFORMANCE_VIDEO],
    children: [],
    id: 'track-performances',
    render: <TrackPerformances />,
    title: 'quick-start-guide:body.accordion.track-performances.title',
    validated: false,
  },
];
