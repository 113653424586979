import { useMutation } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import apiClient from '@/apis/semji/apiClient';
import { UsePostNewContentProps } from '@/apis/semji/contents/usePostNewContent/usePostNewContent.types';
import { ContentUtils } from '@/containers/Content/TinyMceComponents/Editor/hooks/useContent/content.utils';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import { selectRemainingAnalysisUnit } from '@/store/selectors/selectRemainingAnalysisUnit';
import { ENUM_CONTENT_TYPE } from '@/types/contents';
import { SOURCE_DRAFT_CREATE } from '@/utils/analysis';

export default function usePostNewContent(props: UsePostNewContentProps) {
  const { post, put } = apiClient;
  const { workspaceId } = useParams();
  const remainingAnalysisUnit = useSelector(selectRemainingAnalysisUnit);
  const { isFeatureEnabled: hasUnlimitedAnalysisCredits } = useOrganizationFeatureSet(
    'credits:analysis:has-unlimited-amount'
  );

  return useMutation({
    mutationFn: async ({ data = {}, startAnalysis = false, keyword = null }) => {
      const createdContent = await post(`/workspaces/${workspaceId}/contents`, {
        assignedTo: null,
        contentStatus: null,
        dueDate: null,
        folderId: null,
        html: ContentUtils.createEmptyHTML(),
        metaDescription: '',
        page: null,
        title: '',
        type: ENUM_CONTENT_TYPE.DRAFT,
        version: 0,
        ...data,
      });

      if (keyword) {
        const topKeyword = await post(`/pages/${createdContent.data.page.id}/top_keywords`, {
          keyword,
        });
        await put(`/pages/${createdContent.data.page.id}`, {
          focusTopKeyword: topKeyword.data['@id'],
        });
        if (startAnalysis && (hasUnlimitedAnalysisCredits || remainingAnalysisUnit > 0)) {
          await post(`/top_keywords/${topKeyword.data.id}/analysis`, {
            source: SOURCE_DRAFT_CREATE,
          });
        }
      }
      return createdContent;
    },

    ...props,
  });
}
