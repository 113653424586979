import { Settings } from 'luxon';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import useDebug from '@/hooks/useDebug';
import loadI18n from '@/i18n/utils/loadI18n';

Settings.defaultLocale = 'en';

export default function useI18n() {
  const i18n = useRef(null);
  const userLanguageCode = useSelector((state) => state.user?.languageCode);
  const debugLng = useDebug('lng');
  const isDebugI18nEnabled = useDebug('i18n');

  useEffect(() => {
    i18n.current = loadI18n({
      debug: isDebugI18nEnabled,
      lng: isDebugI18nEnabled ? debugLng : userLanguageCode,
    });

    i18n.current?.on('languageChanged', function (lng) {
      /*
      If you need the primary used language depending on your configuration (supportedLngs, load) you will prefer using i18next.languages?.[0].
      So we have to set load to languageOnly to get the language with i18next.languages?.[0] (the detected one could include the country code).
      https://github.com/i18next/i18next/issues/1502#issuecomment-680969692 */
      const currentLng = i18n.current?.languages?.[0] || lng || 'en';

      // Change luxon locale with the detected language when FF is enabled.
      // TODO: find a way to change it properly because it's currently broken :'(
      // Settings.defaultLocale = currentLng;

      // Change html lang attribute
      window.document.documentElement.lang = currentLng;

      // Change intercom lang attribute
      window.document.body.dataset.userLanguage = currentLng;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDebugI18nEnabled]);

  useEffect(() => {
    // Launch user language detection when FF is enabled.
    if (debugLng && isDebugI18nEnabled) {
      i18n.current?.changeLanguage?.(debugLng);
    } else if (userLanguageCode) {
      i18n.current?.changeLanguage?.(userLanguageCode);
    }
  }, [debugLng, userLanguageCode]);

  return i18n.current;
}
