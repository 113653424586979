import { memo, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import apiSettings from '@/apis/semji/settings';
import { FlexCenteredAlignContainer } from '@/components/_common';
import { IconExternalLink } from '@/components/Navigation/Link';
import { InlineText } from '@/components/Text/Inline';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import { formatUrlForDisplay } from '@/utils/url';

const Img = styled.img`
  width: ${(props) => (props.small ? '12px' : '16px')};
  height: ${(props) => (props.small ? '12px' : '16px')};
  padding: ${(props) => (props.noPadding ? '0 4px 0 0' : '0.5em 0.3em 0.5em 0')};
`;
const StyledTooltipComponent = styled(TooltipComponent)`
  display: flex;
  min-width: 0;
`;
const Wrapper = styled(FlexCenteredAlignContainer)`
  min-width: 0;
`;

const FlexLink = styled.a`
  display: flex;
`;

const FlexSpan = styled.span`
  display: flex;
`;

function FavIconImage({ host, small, noPadding }) {
  function addDefaultSrc(event) {
    event.target.src = `/images/favicon/default-favicon.svg`; // replace by a default favicon
  }

  return (
    <Img
      noPadding={noPadding}
      small={small}
      src={`${apiSettings.rootUrl}/domains/${host}/favicon`}
      onError={addDefaultSrc}
    />
  );
}

function getHost(url) {
  try {
    const { host } = new URL(url);
    return host;
  } catch (e) {
    return url;
  }
}

function FavIcon({
  className,
  url,
  displayLink,
  disableClick,
  isEditor = false,
  small,
  noPadding,
}) {
  const { t } = useTranslation();

  const host = getHost(url);

  return (
    <StyledTooltipComponent
      className={className}
      hide={displayLink}
      title={formatUrlForDisplay(host)}
    >
      <Wrapper>
        {disableClick ? (
          <FlexSpan>
            <Suspense fallback={<></>}>
              <FavIconImage host={host} noPadding={noPadding} small={small} />
            </Suspense>
          </FlexSpan>
        ) : (
          <FlexLink href={url} rel="noopener noreferrer" target="_blank">
            <Suspense fallback={<></>}>
              <FavIconImage host={host} noPadding={noPadding} small={small} />
            </Suspense>
          </FlexLink>
        )}
        {displayLink && (
          <>
            <FlexSpan>
              <InlineText color="dark060" size={isEditor ? 'xsm' : 'sm'}>
                {formatUrlForDisplay(host)}
              </InlineText>
            </FlexSpan>
            <IconExternalLink href={url} title={t('components:fav-icon.icon-title')} />
          </>
        )}
      </Wrapper>
    </StyledTooltipComponent>
  );
}

export default memo(FavIcon);
