import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DialogModal } from 'semji-core/components/DialogModal';
import styled from 'styled-components/macro';

import usePostEmailChecker from '@/apis/semji/emails/usePostEmailChecker';
import { LinkLikeButton } from '@/components/Button/Button';
import Input from '@/components/Input/Input';
import { BlockText } from '@/components/Text/Inline';
import { ParagraphText } from '@/components/Text/Paragraph';
import UserService from '@/services/User';
import { showSuccessSnackbar } from '@/store/actions/ui';
import { setUserEmail } from '@/store/actions/user';
import { isValidEmail } from '@/utils/email';

import { Title } from '.';

const StyledInput = styled(Input)`
  && {
    margin-bottom: 25px;
  }
`;

function ResetEmailDialog({ show, onCancel }) {
  const { t } = useTranslation();
  const _userService = new UserService();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [newEmail, setNewEmail] = useState({
    error: '',
    value: '',
  });
  const [confirmNewEmail, setConfirmNewEmail] = useState({
    error: '',
    value: '',
  });
  const [state, setState] = useState({
    changing: false,
    errorChanging: false,
  });

  const emailChecker = usePostEmailChecker({
    onSuccess: ({ data }) => {
      if (data.isBlacklistedDomain) {
        setNewEmail({
          ...newEmail,
          error: t('create-account:sign-up.blacklisted-domain', {
            domain: data.email.split('@')[1],
          }),
        });
      } else if (!data.isWorkEmail) {
        setNewEmail({
          ...newEmail,
          error: t('create-account:sign-up.email-error'),
        });
      }
    },
  });

  function onNewEmailBlur() {
    if (!isValidEmail(newEmail.value)) {
      setNewEmail({
        ...newEmail,
        error: t('settings:workspace.reset-email.change-error-new-email-not-valid'),
      });
    } else if (newEmail.value === user.email) {
      setNewEmail({
        ...newEmail,
        error: t('settings:workspace.reset-email.same-email'),
      });
    } else {
      emailChecker.mutate({ email: newEmail.value });
    }
  }

  function onConfirmNewEmailBlur() {
    if (confirmNewEmail.value !== newEmail.value) {
      setConfirmNewEmail({
        ...confirmNewEmail,
        error: t('settings:workspace.reset-email.change-error-new-email-dont-match'),
      });
    }
  }

  function onChangeNewEmail(event) {
    const value = event.target.value;
    setNewEmail({
      error: '',
      value,
    });
  }

  function onChangeConfirmNewEmail(event) {
    const value = event.target.value;
    setConfirmNewEmail({
      error: '',
      value,
    });
  }

  function cancel() {
    setNewEmail({
      error: '',
      value: '',
    });
    setConfirmNewEmail({
      error: '',
      value: '',
    });
    onCancel();
  }

  function isSubmitDisabled() {
    return (
      !newEmail.value ||
      !!newEmail.error ||
      state.changing ||
      newEmail.value !== confirmNewEmail.value ||
      user.email === newEmail.value
    );
  }

  async function confirmChange() {
    if (isSubmitDisabled()) {
      return;
    }

    setState({
      changing: true,
      errorChanging: false,
    });

    try {
      const newUser = await _userService.setNewEmail(user?.id, newEmail.value);
      // set the new email
      dispatch(setUserEmail(newUser.email));
      dispatch(showSuccessSnackbar(t('settings:workspace.reset-email.snackbar-change-success')));

      setState({
        changing: false,
        errorChanging: false,
      });
      cancel();
    } catch (e) {
      setState({
        changing: false,
        errorChanging: true,
      });
    }
  }

  return (
    <DialogModal
      cancelAction={cancel}
      cancelLabel={t('settings:workspace.reset-email.dialog-cancel-label')}
      canClickOutsideToClose
      closable
      confirmAction={confirmChange}
      confirmLabel={t('settings:workspace.reset-email.dialog-confirm-label')}
      error={!!state.errorChanging && t('settings:workspace.reset-email.form-error')}
      isConfirmDisabled={isSubmitDisabled()}
      isOpen={Boolean(show)}
      title={t('settings:workspace.reset-email.dialog-title')}
      onClose={cancel}
    >
      <BlockText weight="medium">
        {t('settings:workspace.reset-email.dialog-input-new-email')}
      </BlockText>
      <StyledInput
        autoComplete="username"
        error={!!newEmail.error || state.errorChanging}
        fullWidth
        helperText={newEmail.error}
        type="email"
        value={newEmail.value}
        onBlur={onNewEmailBlur}
        onChange={onChangeNewEmail}
      />
      <br />
      <br />
      <BlockText weight="medium">
        {t('settings:workspace.reset-email.dialog-input-confirm-new-email')}
      </BlockText>
      <StyledInput
        error={!!confirmNewEmail.error || state.errorChanging}
        fullWidth
        helperText={confirmNewEmail.error}
        type="email"
        value={confirmNewEmail.value}
        onBlur={onConfirmNewEmailBlur}
        onChange={onChangeConfirmNewEmail}
      />
    </DialogModal>
  );
}

function ResetEmail() {
  const { t } = useTranslation();
  const userEmail = useSelector((state) => state.user.email);
  const [showEmailModal, setShowEmailModal] = useState(false);

  function handleOpenModal() {
    setShowEmailModal(true);
  }

  function handleCloseModal() {
    setShowEmailModal(false);
  }

  return (
    <>
      <Title noMargin weight="strong">
        {t('settings:workspace.reset-email.title')}
      </Title>
      <ParagraphText color="dark080" noPadding weight="medium">
        {userEmail}
      </ParagraphText>
      <LinkLikeButton noPadding onClick={handleOpenModal}>
        {t('settings:workspace.reset-email.button-change-email')}
      </LinkLikeButton>
      <ResetEmailDialog show={showEmailModal} onCancel={handleCloseModal} />
    </>
  );
}

export default ResetEmail;
