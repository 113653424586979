import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Zoom from '@material-ui/core/Zoom';
import { lazy, Suspense, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import { getResetUserPassword } from '@/apis/semji/api';
import { Box, BoxHeader, Form, Input } from '@/components/Box/Box';
import { ContainedButton } from '@/components/Button/Button';
import WhiteLogo from '@/components/icons/LogoWhite';
import {
  DEFAULT_BACKGROUND,
  LayoutCenteredContent,
  NO_MENU_LAYOUT,
} from '@/components/Layout/Layout';
import { LayoutLoader, RelativeLoader } from '@/components/Loader/Loader';
import { DefaultLink } from '@/components/Navigation/Link';
import PasswordFieldSkeleton from '@/components/Password/PasswordFieldSkeleton';
import { BlockText } from '@/components/Text/Inline';
import { TitleTwo } from '@/components/Text/Title';
import LayoutWrapper from '@/containers/Layout/Layout';
import { showSuccessSnackbar } from '@/store/actions/ui';
import { resetUserPassword } from '@/store/actions/user';
import {
  DEFAULT_PASSWORD_MIN_STRENGTH,
  DEFAULT_PASSWORD_THRESHOLD_LENGTH,
} from '@/utils/constants';
import { SECTIONS } from '@/utils/log/constants';
import { Log } from '@/utils/log/Log';

const PasswordField = lazy(
  () => import(/* webpackChunkName: "PasswordField" */ '@/components/Password/PasswordField')
);

const InputHidden = styled(Input)`
  && {
    display: none;
  }
`;

function ResetPassword(props) {
  const { resetToken } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isReady, setIsReady] = useState(false);
  const [fetchError, setFetchError] = useState(null);
  const [state, setState] = useState({ confirmNewPassword: '', email: '', newPassword: '' });
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [isResetting, setIsResetting] = useState(false);
  const [resetError, setResetError] = useState(false);
  const [hasPasswordFieldError, setHasPasswordFieldError] = useState(false);

  useEffect(() => {
    async function getResetPassword() {
      if (!resetToken || isResetting) {
        return;
      }

      try {
        const { email } = await getResetUserPassword(resetToken);

        setState({
          ...state,
          email,
        });
      } catch (error) {
        Log.report({
          context: 'getResetPassword',
          error,
          extra: 'Get Reset registrant',
          section: SECTIONS.login.key,
        });
        switch (error.status) {
          case 400:
          case 422:
            setFetchError(t('login:reset-password.errors.400'));
            break;
          case 404:
            setFetchError(t('login:reset-password.errors.404'));
            break;
          default:
            setFetchError(t('login:reset-password.errors.default'));
            break;
        }
      }

      setIsReady(true);
    }

    getResetPassword();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, isResetting]);

  function isValidPassword(value, strength) {
    return (
      strength >= DEFAULT_PASSWORD_MIN_STRENGTH && value.length >= DEFAULT_PASSWORD_THRESHOLD_LENGTH
    );
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setIsResetting(true);
    setResetError(false);

    if (!isValidPassword(state.newPassword, passwordStrength)) {
      return;
    }

    if (state.newPassword !== state.confirmNewPassword) {
      setIsResetting(false);
      setResetError(t('login:reset-password.errors.password-does-not-match'));

      return;
    }

    try {
      await props.resetUserPassword({
        password: state.newPassword,
        token: resetToken,
      });

      dispatch(showSuccessSnackbar(t('login:reset-password.password-successfully-updated')));
      setIsResetting(false);
      navigate('/', { replace: true });
    } catch (error) {
      Log.report({
        context: 'handleSubmit',
        error,
        extra: 'Form submit reset password',
        section: SECTIONS.login.key,
      });
      setIsResetting(false);
      switch (error.status) {
        case 400:
        case 422:
          setResetError(t('login:reset-password.errors.400'));
          break;
        case 404:
          setResetError(t('login:reset-password.errors.404'));
          break;
        default:
          setResetError(t('login:reset-password.errors.default'));
          break;
      }
    }
  }

  function handleChange(event) {
    setState({ ...state, [event.target.name]: event.target.value });
  }

  function onPasswordChanged(pwd) {
    setPasswordStrength(pwd.strength);
  }

  function onPasswordErrorChange(error) {
    setHasPasswordFieldError(!!error);
  }

  if (!isReady) {
    return (
      <LayoutWrapper menu={NO_MENU_LAYOUT} variant={DEFAULT_BACKGROUND}>
        <LayoutLoader />
      </LayoutWrapper>
    );
  }

  return (
    <LayoutWrapper menu={NO_MENU_LAYOUT} variant={DEFAULT_BACKGROUND}>
      <LayoutCenteredContent>
        <Helmet>
          <title>{t('login:reset-password.title')}</title>
        </Helmet>
        <WhiteLogo height="75" width="150" />
        <Zoom in transition={600}>
          <Box>
            {fetchError ? (
              <BoxHeader>
                <TitleTwo color="primary" weight="medium">
                  {t('login:reset-password.oops')}
                </TitleTwo>
                <BlockText color="dark080" weight="medium">
                  {fetchError}
                </BlockText>
                <br />
                <DefaultLink color="dark080" decoration to="/login">
                  {t('login:reset-password.return-to-sign-in')}
                </DefaultLink>
              </BoxHeader>
            ) : (
              <BoxHeader>
                <TitleTwo weight="medium">{t('login:reset-password.reset-password')}</TitleTwo>
              </BoxHeader>
            )}
            {!fetchError && (
              <Form onChange={handleChange} onSubmit={handleSubmit}>
                <FormControl aria-describedby="resetting-error" error={resetError} fullWidth>
                  <InputHidden autoComplete="username" disabled type="email" value={state.email} />
                  <Suspense fallback={<PasswordFieldSkeleton />}>
                    <PasswordField
                      error={resetError}
                      label={t('login:reset-password.password')}
                      name="newPassword"
                      thresholdLength={DEFAULT_PASSWORD_THRESHOLD_LENGTH}
                      onErrorStatusChange={onPasswordErrorChange}
                      onPasswordChanged={onPasswordChanged}
                    />
                  </Suspense>
                  <Input
                    autoComplete="new-password"
                    fullWidth
                    inputProps={{
                      minLength: DEFAULT_PASSWORD_THRESHOLD_LENGTH,
                    }}
                    label={t('login:reset-password.confirm-new-password')}
                    name="confirmNewPassword"
                    required
                    type="password"
                  />
                  {resetError && <FormHelperText>{resetError}</FormHelperText>}
                </FormControl>
                <ContainedButton
                  disabled={
                    isResetting ||
                    !state.newPassword ||
                    !state.confirmNewPassword ||
                    hasPasswordFieldError
                  }
                  fullWidth
                  height="big"
                  type="submit"
                  withMargin
                >
                  {isResetting ? <RelativeLoader /> : t('login:reset-password.change-password')}
                </ContainedButton>
              </Form>
            )}
          </Box>
        </Zoom>
      </LayoutCenteredContent>
    </LayoutWrapper>
  );
}

export default connect(null, {
  resetUserPassword,
})(ResetPassword);
