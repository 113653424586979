import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Avatar } from 'semji-core/components/Avatar';
import styled from 'styled-components/macro';

import PowerOffIcon from '@/components/icons/PowerOffIcon';
import SettingsIcon from '@/components/icons/SettingsIcon';
import UserIcon from '@/components/icons/UserIcon';
import { useMixpanelTrackEvent } from '@/hooks/useMixpanelTrackEvent';
import { SETTINGS_TOP_NAV_CLICK } from '@/utils/3rdParty/Mixpanel/constants';
import { getFullNameFromUser } from '@/utils/user';

export const MyNavLink = styled(NavLink)`
  color: ${(props) => props.theme.cssColors.dark080};
  text-decoration: none !important;
  display: flex;
  flex-grow: 1;
  align-items: center;
  height: 100%;
  font-size: 0.9rem;

  &&:hover {
    color: ${(props) => props.theme.cssColors.dark100};
  }
`;

const StyledMenuItem = styled(({ noHover, ...props }) => <MenuItem {...props} />)`
  && {
    padding: 0 1.2rem;
    margin: 0.5rem 0;
    color: ${(props) => props.theme.cssColors.dark080};
    font-size: 0.9rem;
    text-decoration: none;
    display: flex;
    height: 40px;
  }

  &&:hover {
    background-color: ${(props) => props.noHover && 'transparent'};
    color: ${(props) => props.theme.cssColors.dark100};
  }
`;

const SignOutIcon = styled(PowerOffIcon)`
  margin-right: 8px;
`;

const StyledSettingsIcon = styled(SettingsIcon)`
  margin-right: 8px;
`;

const StyledUserIcon = styled(UserIcon)`
  margin-right: 8px;
  && {
    fill: none;
    stroke: black;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${(props) => props.color || props.theme.colors.lightGrey};
`;

export const SignOutItem = (props) => {
  const { t } = useTranslation();

  return (
    <StyledMenuItem {...props}>
      <SignOutIcon />
      {t('layout:profile.sign-out')}
    </StyledMenuItem>
  );
};

export const SettingsItem = ({ organizationId, workspaceId, ...props }) => {
  const { t } = useTranslation();
  const trackMixpanelEvent = useMixpanelTrackEvent();

  return (
    <StyledMenuItem {...props}>
      <MyNavLink
        to={
          organizationId
            ? workspaceId
              ? `/o/${organizationId}/w/${workspaceId}/settings/user-profile`
              : `/o/${organizationId}/settings/user-profile`
            : '/settings/user-profile'
        }
        onClick={() => trackMixpanelEvent(SETTINGS_TOP_NAV_CLICK)}
      >
        <StyledSettingsIcon />
        {t('layout:profile.settings')}
      </MyNavLink>
    </StyledMenuItem>
  );
};

export const StopImpersonatingItem = (props) => {
  const { t } = useTranslation();

  return (
    <StyledMenuItem {...props}>
      <StyledUserIcon />
      {t('layout:profile.stop-impersonating')}
    </StyledMenuItem>
  );
};

const PopoverHeader = styled.div`
  color: white;
  padding: 15px 50px 15px 15px;
  display: flex;
  position: relative;
  min-width: 240px;

  &:after {
    content: '';
    width: 15px;
    height: 15px;
    position: absolute;
    top: -5px;
    right: 11px;
    transform: rotate(45deg);
    border-radius: 3px;
  }
`;

const UserInfos = styled.div`
  display: flex;
  color: ${(props) => props.theme.text.colors.white};
  flex-direction: column;
  justify-content: center;
`;

const UserName = styled.div`
  margin-bottom: 5px;
  font-size: 1.1em;
  color: ${({ theme }) => theme.cssColors.dark100};
`;

const UserEmail = styled.div`
  font-size: 0.8em;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.cssColors.dark060};
`;

const UserAvatarWrapper = styled.div`
  margin-right: 15px;
`;

export const ProfileBoxHeader = ({ user = {}, ...otherProps }) => {
  const { t } = useTranslation();
  const apiRootUrl = import.meta.env.VITE_REACT_APP_SEMJI_API_ROOT_URL;

  return (
    <PopoverHeader {...otherProps}>
      <UserAvatarWrapper>
        <Avatar
          apiRootUrl={apiRootUrl}
          email={user.email}
          firstName={user.firstName}
          id={user.id}
          lastName={user.lastName}
          profileImageHash={user.profileImageHash}
          translations={{ unassigned: t('common:user-picker.unassigned') }}
          uploadedAvatarUrl={user.uploadedAvatarUrl}
        />
      </UserAvatarWrapper>
      <UserInfos>
        <UserName>{getFullNameFromUser(user)}</UserName>
        <UserEmail title={user.email}>{user.email}</UserEmail>
      </UserInfos>
    </PopoverHeader>
  );
};
