import { fromJS, mergeDeep } from 'immutable';
import set from 'lodash/set';

import { checkCanDebug } from '@/utils/log/checkCanDebug';

// Credits: https://github.com/zalmoxisus/redux-devtools-extension/issues/502#issuecomment-425604203
function devReducer(state, action) {
  if (checkCanDebug()) {
    switch (action.type) {
      case 'DEV': {
        const mergeValue = action.state || action.value;
        const mergeHack = action.path ? set({}, action.path, mergeValue) : mergeValue;

        return mergeDeep(state, fromJS(mergeHack));
      }
      default:
        return state;
    }
  } else {
    return state;
  }
}

export default devReducer;
