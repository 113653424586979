import React from 'react';

import SvgWrapper from './SvgWrapper';

const MoneyIcon = (props) => (
  <SvgWrapper {...props}>
    <path d="M10.719 9.577v0l-0.010-0.010c-0.013-0.011-0.022-0.024-0.036-0.034l-0.003 0.004-1.67-1.282v-5.707c1.14 0.218 2 1.164 2 2.296l0.499 0.060 0.501-0.060c0-1.68-1.309-3.062-3-3.296v-1.548h-1v1.548c-1.691 0.234-3 1.616-3 3.296 0 0.942 0.421 1.838 1.151 2.473l-0.005 0.005 0.040 0.031c0 0 0 0 0 0v0l1.814 1.394v5.705c-1.14-0.218-2-1.164-2-2.296h-1c0 1.68 1.309 3.062 3 3.296v1.549h1v-1.549c1.691-0.234 3-1.616 3-3.296 0-1.006-0.469-1.939-1.281-2.579zM6.822 6.581c-0.522-0.446-0.822-1.077-0.822-1.737 0-1.132 0.86-2.078 2-2.296v4.938l-1.178-0.905zM9 14.452v-4.936l1.104 0.849c0.567 0.447 0.896 1.096 0.896 1.791 0 1.132-0.86 2.078-2 2.296z" />
  </SvgWrapper>
);

export default MoneyIcon;
