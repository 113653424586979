import React from 'react';

import SvgWrapper from './SvgWrapper';

const SpinnerIcon = (props) => (
  <SvgWrapper {...props}>
    <path
      d="M-2.40413e-07 5.5C-2.9408e-07 6.72776 0.4108 7.92021 1.16698 8.88747C1.92316 9.85472 2.98123 10.5411 4.1727 10.8374C5.36417 11.1337 6.62052 11.0228 7.74167 10.5224C8.86282 10.022 9.78431 9.16089 10.3594 8.07614L9.14453 7.4321C8.71323 8.24567 8.02212 8.89153 7.18125 9.26683C6.34039 9.64213 5.39813 9.7253 4.50453 9.50308C3.61092 9.28086 2.81737 8.76604 2.25023 8.0406C1.6831 7.31516 1.375 6.42082 1.375 5.5L-2.40413e-07 5.5Z"
      fill="url(#paint0_linear_2259_38334)"
    />
    <path
      d="M10.3703 2.94463C9.79901 1.85574 8.87927 0.989725 7.75802 0.484889C6.63677 -0.0199478 5.37868 -0.134479 4.1847 0.159589C2.99072 0.453656 1.92972 1.13936 1.17117 2.10717C0.412624 3.07499 0.00027924 4.26909 -2.40358e-07 5.49875L1.375 5.49906C1.37521 4.57682 1.68447 3.68124 2.25338 2.95538C2.82229 2.22952 3.61804 1.71524 4.51353 1.49469C5.40901 1.27414 6.35258 1.36004 7.19352 1.73867C8.03445 2.11729 8.72426 2.76681 9.15274 3.58347L10.3703 2.94463Z"
      fill="url(#paint1_linear_2259_38334)"
    />
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_2259_38334"
        x1="7.85714"
        x2="0.5"
        y1="8.64286"
        y2="7"
      >
        <stop offset="0.146451" stopColor="#FF4D64" stopOpacity="0" />
        <stop offset="0.673078" stopColor="#FF4D64" />
        <stop offset="1" stopColor="#FF4D64" stopOpacity="0.9" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint1_linear_2259_38334"
        x1="7.5"
        x2="1.5"
        y1="6.5"
        y2="2.5"
      >
        <stop stopColor="#FF4D64" stopOpacity="0" />
        <stop offset="0.69837" stopColor="#FF4D64" />
        <stop offset="1" stopColor="#FF4D64" stopOpacity="0.9" />
      </linearGradient>
    </defs>
  </SvgWrapper>
);

export default SpinnerIcon;
