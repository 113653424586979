import useGetCurrentWorkspaceAllFolders from '@/apis/semji/folders/useGetCurrentWorkspaceAllFolders';
import { getDefaultFolderGeneral } from '@/components/Select/FolderSelect/constants';
import { sortFoldersByParents } from '@/components/Select/FolderSelect/utils/sortFoldersByParents';

export default function useWorkspaceFolders() {
  const DEFAULT_FOLDER_GENERAL = getDefaultFolderGeneral();
  const { data, ...rest } = useGetCurrentWorkspaceAllFolders();

  return {
    data: [DEFAULT_FOLDER_GENERAL, ...sortFoldersByParents(data)],
    ...rest,
  };
}
