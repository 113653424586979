import styled from 'styled-components/macro';

import { IconButton as Icon } from '@/components/Button/Button';
import { DarkText as Text } from '@/components/Text/Dark';
import { SmallFlexTooltipComponent } from '@/components/Tooltip/Tooltip';

export const DarkText = styled(Text)`
  && {
    font-weight: 400;
    white-space: nowrap;
    margin-right: 15px;
  }
`;
export const VerticalSeparator = styled.div`
  border-left: 0.5px solid ${(props) => props.theme.textCss.colors.default};
  height: 30px;
  opacity: 25%;
  margin-right: 3px;
`;

export const IconButton = styled(Icon)`
  && {
    opacity: ${({ disabled }) => (disabled ? '0.7' : '1')};
    &:hover {
      cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    }
  }
`;

export const ItemSmallFlexTooltipComponent = styled(SmallFlexTooltipComponent)`
  color: ${({ theme }) => theme.cssColors.primary};
  font-size: 13px;
  font-weight: 400;
  cursor: pointer;
  align-items: center;
  padding: ${(props) => props.theme.textCss.sizes.xs};
  margin: 0;

  svg {
    width: 17px;
    height: 17px;
    margin-right: 5px;
  }
`;
