import React from 'react';

import SvgWrapper from './SvgWrapper';

const CreateNewContentIcon = (props) => (
  <SvgWrapper fill="none" height="34" viewBox="0 0 46 34" width="46" {...props}>
    <path
      d="M45.5839 31.3002C45.5839 32.7795 35.3723 33.9693 22.792 33.9693C10.2116 33.9693 0 32.7795 0 31.3002C0 29.8209 10.2116 29.9817 22.792 29.9817C35.3723 29.9817 45.5839 29.8209 45.5839 31.3002Z"
      fill="#FFEBED"
    />
    <rect fill="#FFEBED" height="2.3291" rx="1.16455" width="4.99094" x="5.6582" y="14.0059" />
    <rect fill="#FFEBED" height="2.3291" rx="1.16455" width="4.99094" x="6.32227" y="4.68896" />
    <rect fill="#FFEBED" height="2.3291" rx="1.16455" width="2.66183" x="0.333984" y="9.01465" />
    <rect fill="#FFEBED" height="2.3291" rx="1.16455" width="6.65458" x="4.32715" y="9.01465" />
    <rect
      fill="#FFEBED"
      height="2.3291"
      rx="1.16455"
      transform="rotate(-180 38.9307 25.6514)"
      width="4.32548"
      x="38.9307"
      y="25.6514"
    />
    <rect
      fill="#FFEBED"
      height="2.3291"
      rx="1.16455"
      transform="rotate(-180 38.9307 16.6675)"
      width="4.99094"
      x="38.9307"
      y="16.6675"
    />
    <rect
      fill="#FFEBED"
      height="2.3291"
      rx="1.16455"
      transform="rotate(-180 44.9199 21.6587)"
      width="2.66183"
      x="44.9199"
      y="21.6587"
    />
    <rect
      fill="#FFEBED"
      height="2.3291"
      rx="1.16455"
      transform="rotate(-180 40.9258 21.6587)"
      width="6.65458"
      x="40.9258"
      y="21.6587"
    />
    <path
      d="M11.3132 30.9751V0.363979H23.161L34.2715 10.6575V30.9751H11.3132Z"
      stroke="#51525E"
      strokeWidth="0.665459"
    />
    <line
      stroke="#51525E"
      strokeWidth="0.665459"
      x1="23.2913"
      x2="23.2913"
      y1="11.0112"
      y2="0.696623"
    />
    <path d="M23.29 10.6782L34.6028 10.6782" stroke="#51525E" strokeWidth="0.665459" />
    <rect fill="#FFCCD3" height="9.31642" rx="1.66365" width="9.31642" x="17.9678" y="14.3384" />
    <line
      stroke="#51525E"
      strokeWidth="0.665459"
      x1="22.6247"
      x2="22.6247"
      y1="16.3345"
      y2="21.3254"
    />
    <line
      stroke="#51525E"
      strokeWidth="0.665459"
      x1="19.9629"
      x2="25.2866"
      y1="18.6639"
      y2="18.6639"
    />
  </SvgWrapper>
);

export default CreateNewContentIcon;
