import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import apiClient from '@/apis/semji/apiClient';
import { showErrorSnackbar } from '@/store/actions/ui';

export default function useDeleteBrandVoiceById(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { delete: deleteRequest } = apiClient;

  return useMutation({
    mutationFn: async (brandVoiceId) =>
      await deleteRequest(`/deprecated_brand_voices/${brandVoiceId}`),

    onError: () => {
      dispatch(showErrorSnackbar(t('common:error.default')));
    },
    ...props,
  });
}
