import MuiDialog from '@material-ui/core/Dialog';

import DialogActions from './DialogActions';
import DialogContent from './DialogContent';

function Dialog({ actions, children, fullWidth, handleClose }) {
  return (
    <MuiDialog
      aria-describedby="dialog-service-integration-search-console"
      aria-labelledby="dialog-service-integration-search-console"
      fullWidth={fullWidth}
      maxWidth="sm"
      open
      onClose={handleClose}
    >
      <DialogContent>{children}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </MuiDialog>
  );
}

export default Dialog;
