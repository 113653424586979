import React from 'react';

export function PredefinedItem({ item }) {
  return (
    <div className="aa-ItemLink">
      <div className="aa-ItemIcon aa-ItemIcon--noBorder">
        <svg
          fill="none"
          height="17"
          viewBox="0 0 18 17"
          width="18"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.0094 15.8774V8.33962H14.1321V17H0.5V3.36792H9V4.49057H1.62264V15.8774H13.0094ZM17.5 0H9V1.12264H15.7358L4.99057 11.8679L5.79245 12.6698L16.3774 2.08491V8.5H17.5V0Z"
            fill="#A8A9AF"
          />
        </svg>
      </div>
      <div className="aa-ItemWrapper">
        <div className="aa-ItemContentTitle">{item.label}</div>
      </div>
    </div>
  );
}
