import './Header.scss';

import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { LimitModal } from 'semji-core/components/LimitModal';

import UnlockImage from '@/assets/images/unlock-image.svg';
import AlertAltIcon from '@/components/icons/AlertAltIcon';
import ArrowLeft from '@/components/icons/ArrowLeft';
import InfoAltIcon from '@/components/icons/InfoAltIcon';
import { DefaultLink } from '@/components/Navigation/Link';
import {
  brandedTypeList,
  deprecatedKeywordsTypeList,
  keywordsTypeList,
  rankedTypeList,
  urlsClassificationTypeList,
  viewTypeList,
} from '@/components/Pages/ContentIdeas/Listing/constant';
import Selector from '@/components/Pages/ContentIdeas/Listing/Selector';
import Flex from '@/design-system/components/Flex/Flex';
import useCan from '@/hooks/useCan';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';

import Filters from './Filters';
import { Alert, AlertDescription, ClusterizationMessage, Message, Wrapper } from './Header.styled';
import Title from './Title';

function Header({
  contentIdeasKeyword,
  contentIdeasLoading,
  children,
  isSearchDeprecated,
  isSearchPending,
  isClusterizationFailed,
  isHeaderOpen,
}: {
  contentIdeasKeyword: string;
  contentIdeasLoading: boolean;
  children: ReactNode;
  isSearchDeprecated: boolean;
  isSearchPending: boolean;
  isClusterizationFailed: boolean;
  isHeaderOpen: boolean;
}) {
  const [query, setQuery] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { organizationId, workspaceId } = useParams();

  const keywordType = query.get('keywordType');
  const urlClassificationType = query.get('urlClassificationType');
  const brandedType = query.get('brandedType');
  const rankedType = query.get('rankedType');
  const viewType = query.get('viewType');

  const [isLimitModalOpen, setIsLimitModalOpen] = useState(false);

  const isClusteringDisplayedFeatureFlag = useCan({
    perform: 'content_ideas.clustering.displayed',
  });
  const { isFeatureEnabled: isMindMapFeatureSet } = useOrganizationFeatureSet(
    'content-ideas:has-access-to-mind-map'
  );

  const { isFeatureEnabled: isClusteringFeatureSet } = useOrganizationFeatureSet(
    'content-ideas:has-access-to-clusters'
  );

  const isMindMapEnabled = isClusteringDisplayedFeatureFlag && isMindMapFeatureSet;

  function handleGoToContentIdeasMenu() {
    navigate(`/o/${organizationId}/w/${workspaceId}/content-ideas`);
  }

  function handleSelectKeywordsType(newKeywordsType: string) {
    return () => {
      if (newKeywordsType !== keywordType) {
        query.set('keywordType', newKeywordsType);
        setQuery(query);
      }
    };
  }

  function handleSelectUrlsClassificationType(newUrlsClassificationType: string) {
    return () => {
      if (newUrlsClassificationType !== urlClassificationType) {
        query.set('urlClassificationType', newUrlsClassificationType);
        setQuery(query);
      }
    };
  }

  function handleSelectBrandedType(newBrandedType: string) {
    return () => {
      if (newBrandedType !== brandedType) {
        query.set('brandedType', newBrandedType);
        setQuery(query);
      }
    };
  }

  function handleSelectRankedType(newRankedType: string) {
    return () => {
      if (newRankedType !== rankedType) {
        query.set('rankedType', newRankedType);
        setQuery(query);
      }
    };
  }

  function handleSelectViewType(newViewType: string) {
    if (!isMindMapEnabled || !isClusteringFeatureSet) {
      return () => setIsLimitModalOpen(true);
    }
    return () => {
      if (newViewType !== viewType) {
        query.set('viewType', newViewType);
        setQuery(query);
      }
    };
  }

  const availableKeywordsTypeList = isSearchDeprecated
    ? deprecatedKeywordsTypeList
    : keywordsTypeList;

  return (
    <Wrapper flexDirection="column" gap="24px" isHeaderOpen={isHeaderOpen} padding="22px 32px">
      <Flex alignItems="center" justifyContent="space-between">
        <Flex alignItems="center" gap="16px">
          {children}
          <ArrowLeft cursor="pointer" onClick={handleGoToContentIdeasMenu} />
          <Title
            contentIdeasKeyword={contentIdeasKeyword}
            contentIdeasLoading={contentIdeasLoading}
          />
        </Flex>
        {isClusteringDisplayedFeatureFlag && (
          <Selector
            data-intercom-target="content_ideas_mindmap_and_list_toggle_wrapper"
            handleSelect={handleSelectViewType}
            list={viewTypeList.map((type) => ({
              ...type,
              isSelected: type.value === viewType,
            }))}
          />
        )}
      </Flex>
      {isSearchPending && (
        <Message alignItems="center" gap="10px">
          <InfoAltIcon height="20px" width="20px" />
          <p>{t('content-ideas:menu.info-search-message')}</p>
        </Message>
      )}
      {isClusterizationFailed && (
        <ClusterizationMessage alignItems="center" gap="10px">
          <InfoAltIcon height="20px" width="20px" />
          <p>
            {t('content-ideas:listing.panel.cluster-info-search-message', {
              focusKeyword: contentIdeasKeyword,
            })}
          </p>
        </ClusterizationMessage>
      )}
      {isSearchDeprecated && (
        <Alert gap="10px">
          <div>
            <AlertAltIcon />
          </div>

          <div>
            <AlertDescription fontWeight="strong">
              <Trans
                components={{
                  extLink: (
                    <DefaultLink
                      color="dark080"
                      decoration
                      isExternal
                      noDynamicFontSize
                      noPadding
                      to={t('common:links.help-current-idea')}
                      weight="strong"
                    />
                  ),
                }}
                i18nKey="content-ideas:listing.header.alert.line1"
              />
            </AlertDescription>
            <AlertDescription fontWeight="normal">
              {t('content-ideas:listing.header.alert.line2', { keyword: contentIdeasKeyword })}
            </AlertDescription>
          </div>
        </Alert>
      )}
      <Flex alignItems="center" justifyContent="space-between">
        <Flex flexWrap="wrap" gap="16px">
          <Selector
            handleSelect={handleSelectUrlsClassificationType}
            list={urlsClassificationTypeList.map((type) => ({
              ...type,
              isSelected: type.value === urlClassificationType,
            }))}
          />
          <Selector
            handleSelect={handleSelectKeywordsType}
            list={availableKeywordsTypeList.map((type) => ({
              ...type,
              isSelected: type.value === keywordType,
            }))}
          />
          <Selector
            handleSelect={handleSelectBrandedType}
            list={brandedTypeList.map((type) => ({
              ...type,
              isSelected: type.value === brandedType,
            }))}
          />
          <Selector
            handleSelect={handleSelectRankedType}
            list={rankedTypeList.map((type) => ({
              ...type,
              isSelected: type.value === rankedType,
            }))}
          />
          <Filters />
        </Flex>
      </Flex>
      <LimitModal
        buttonLabel={t('components:dialog.dialog-information-template.button-text')}
        description={t('content-ideas:upgrade-plan.mindmap.description')}
        illustration={<img alt="" src={UnlockImage} />}
        isOpen={isLimitModalOpen}
        title={t('content-ideas:upgrade-plan.mindmap.title')}
        onClose={() => setIsLimitModalOpen(false)}
      />
    </Wrapper>
  );
}

export default Header;
