import styled from 'styled-components/macro';

import Column from '@/containers/Content/BriefComponents/Table/Columns/Column';

const ActionsColumn = styled(Column)`
  visibility: hidden;
  > *:not(:first-child) {
    margin-left: 8px;
  }
`;

export default ActionsColumn;
