import { DateTime } from 'luxon';

import { getPreviousDateRangeGapDuration } from './getPreviousDateRangeGapDuration';

// returns two comparable days
// today date is passed throw the different methods for test purpose
export const getComparableDayWithToday = ({ period, comparisonPeriod, today }) => {
  const gapDuration = getPreviousDateRangeGapDuration(period, comparisonPeriod);

  let DatetimeTo = today ?? DateTime.local();
  let DatetimeFrom = DatetimeTo.minus(gapDuration);

  return {
    after: DatetimeFrom.toSQLDate(),
    before: DatetimeTo.toSQLDate(),
  };
};
