import { isValidEmail } from 'semji-core/utils/email';

import { formatUrlForDisplay } from './url';

function getEmailDomainFromUrl(url) {
  try {
    const { host } = new URL(url);
    return formatUrlForDisplay(host);
  } catch (e) {
    return 'example.com';
  }
}

export { getEmailDomainFromUrl, isValidEmail };
