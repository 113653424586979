import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LimitModal } from 'semji-core/components/LimitModal';

import UnlockImage from '@/assets/images/unlock-image.svg';
import { PlanRestrictionBadge } from '@/components/Badge/PlanRestrictionBadge';
import ClusterIcon from '@/components/icons/ClusterIcon';
import FlatLoader, { DEFAULT_LOADER_HEIGHT } from '@/components/Loader/FlatLoader';
import Box from '@/design-system/components/Box/Box';
import Flex from '@/design-system/components/Flex';
import { OrganizationFeatureSet } from '@/hoc/OrganizationFeatureSet';
import useCan from '@/hooks/useCan';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import useUrlQuery from '@/hooks/useUrlQuery';

import { ClusterRow } from './Clusters.styled';

function Clusters({ contentIdeasClusters = {}, onClick, isLoading }) {
  const query = useUrlQuery();
  const { t } = useTranslation();
  const activeClusterId = query.get('clusterId');

  const [isLimitModalOpen, setIsLimitModalOpen] = useState(false);

  const isClusteringDisplayedFeatureFlag = useCan({
    perform: 'content_ideas.clustering.displayed',
  });
  const { isFeatureEnabled: isClusteringFeatureSet } = useOrganizationFeatureSet(
    'content-ideas:has-access-to-clusters'
  );
  const isClusteringEnabled = isClusteringDisplayedFeatureFlag;

  const clustersArray = Object.values(contentIdeasClusters).sort((a, b) => {
    const diff = b.occurrences - a.occurrences;
    if (diff === 0) {
      return a.name ? a.name.localeCompare(b.name) : 1;
    }
    return diff;
  });

  if (!isClusteringEnabled) {
    return null;
  }

  if (isLoading) {
    return (
      <Flex flexDirection="column" height="240px">
        {[19, 50, 11, 80, 20, 40, 60, 20].map((value, index) => (
          <Flex key={index} padding="6px 0">
            <Box height={`${DEFAULT_LOADER_HEIGHT}px`} width={`${100 - value}%`}>
              <FlatLoader />
            </Box>
          </Flex>
        ))}
      </Flex>
    );
  }

  if (clustersArray.length === 0) {
    return null;
  }

  const clusters = clustersArray.filter((cluster) => cluster.id !== null);

  function handleClick(clusterId) {
    if (!isClusteringFeatureSet && clusterId) {
      return () => setIsLimitModalOpen(true);
    }
    return () => onClick(clusterId);
  }

  return (
    <>
      <div>
        {clusters.map(({ id, name, color, occurrences }) => (
          <ClusterRow
            key={id}
            alignItems="center"
            color={activeClusterId === id && 'primary'}
            cursor="pointer"
            justifyContent="space-between"
            padding="6px 22px"
            selected={activeClusterId === id}
            onClick={handleClick(id)}
          >
            <Flex alignItems="center" gap="12px">
              <ClusterIcon color={color} />
              <span>{name}</span>
            </Flex>
            <OrganizationFeatureSet
              feature={`content-ideas:has-access-to-clusters`}
              no={() => <PlanRestrictionBadge isCondensed />}
              yes={() => <span>{occurrences}</span>}
            />
          </ClusterRow>
        ))}
      </div>
      <LimitModal
        buttonLabel={t('components:dialog.dialog-information-template.button-text')}
        description={t('content-ideas:upgrade-plan.clusters.description')}
        illustration={<img alt="" src={UnlockImage} />}
        isOpen={isLimitModalOpen}
        title={t('content-ideas:upgrade-plan.clusters.title')}
        onClose={() => setIsLimitModalOpen(false)}
      />
    </>
  );
}

export default Clusters;
