import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { keyframes } from 'styled-components/macro';

import CaretIcon from '@/components/icons/CaretIcon';
import Clear from '@/components/icons/CloseIcon';
import Rotate from '@/design-system/components/Button/Rotate';
import useOnClickOutside from '@/design-system/hooks/useClickOutside';
import { isDownArrowHotkey, isEnterHotkey, isUpArrowHotkey } from '@/utils/keyboard';

const Container = styled.div`
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: ${({ width }) => width ?? '200px'};
  height: 35px;
  background-color: ${({ theme }) => theme.cssColors.secondary010};
  color: ${({ theme }) => theme.cssColors.secondary};
  border-radius: 25px;
  font-size: ${({ theme }) => theme.textCss.sizes.default};
`;

export const PlaceHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 10px;
  cursor: pointer;
  width: 100%;
`;

export const Label = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 10px;
  font-weight: 300;
  width: 100%;
  overflow: hidden;

  span {
    user-select: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: normal;
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  && > * {
    border-radius: 30px;
    font-size: ${({ theme }) => theme.textCss.sizes.xs};
    padding: 3px;

    &:hover {
      background-color: ${({ theme }) => theme.cssColors.dark020};
    }
  }
`;

const hideScroll = keyframes`
  from {
      overflow: hidden;
  }
   to{
      overflow: auto;
  }
`;

export const Items = styled.ul`
  display: flex;
  flex-direction: column;
  z-index: 1000;
  position: absolute;
  top: 35px;
  max-height: ${({ $isOpen }) => ($isOpen ? '200px' : 0)};
  padding: 0;
  margin: 0;
  background-color: white;
  width: 100%;
  list-style-type: none;
  border-radius: 10px;
  visibility: ${({ $isOpen }) => ($isOpen ? 'visible' : 'hidden')};
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.08);
  transition: max-height 0.25s linear;
  animation: ${hideScroll} 0.25s linear forwards;
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  flex: 1 1 35px;
  min-height: 35px;
  line-height: 35px;
  padding: 0 15px;
  background-color: white;
  cursor: pointer;

  &.selected {
    color: ${({ theme }) => theme.cssColors.dark020};
    background-color: ${({ theme }) => theme.cssColors.secondary010};
    cursor: not-allowed;
  }

  &.hovered {
    background-color: ${({ theme }) => theme.cssColors.secondary010};
  }

  span {
    user-select: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

function Select({
  className = '',
  defaultValue,
  iconPlaceHolder,
  isClearable,
  onChange,
  options = [],
  placeholder = '',
  ...props
}) {
  const selectUid = useMemo(() => nanoid(), []);
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(defaultValue || options[0]);
  const [hoveredItemIndex, setHoveredItemIndex] = useState(0);
  const ref = useRef(null);

  const hasValue = !!selectedValue;
  const labelValue = selectedValue?.name ?? placeholder;

  function toggleOpen() {
    setIsOpen(!isOpen);
  }

  const closeDropdown = useCallback(() => setIsOpen(false), []);

  function onClear(event) {
    setSelectedValue(null);
    onChange?.(null);
    event.stopPropagation();
  }

  function onItemClick(selection, index) {
    return () => {
      if (selectedValue?.value !== selection.value) {
        setSelectedValue(selection);
        onChange?.(selection?.value);
        if (typeof index !== 'undefined') {
          setHoveredItemIndex(index);
        }
        setIsOpen(false);
      }
    };
  }

  // TabIndex on the container div is mandatory to be able to capture event.
  function handleKeyDown(event) {
    const minIndex = -1;
    const maxIndex = options.length;
    let newhoveredItemIndex;

    if (isDownArrowHotkey(event)) {
      if (!isOpen) {
        setIsOpen(true);
      } else {
        newhoveredItemIndex = hoveredItemIndex + 1;
      }
    }

    if (isUpArrowHotkey(event)) {
      if (!isOpen) {
        setIsOpen(true);
      } else {
        newhoveredItemIndex = hoveredItemIndex - 1;
      }
    }

    if (
      ![minIndex, maxIndex, hoveredItemIndex].includes(newhoveredItemIndex) &&
      !isNaN(newhoveredItemIndex)
    ) {
      document
        .getElementById(`${selectUid}-item-${newhoveredItemIndex}`)
        ?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
      setHoveredItemIndex(newhoveredItemIndex);
    }

    if (isEnterHotkey(event)) {
      if (isOpen) {
        onItemClick(options[hoveredItemIndex], hoveredItemIndex)();
      }
    }

    event.stopPropagation();
  }

  function handleItemMouseEnter(index) {
    return () => setHoveredItemIndex(index);
  }

  useOnClickOutside(ref, closeDropdown);

  return (
    <Container ref={ref} className={className} {...props} tabIndex="0" onKeyDown={handleKeyDown}>
      <PlaceHolder onClick={toggleOpen}>
        {iconPlaceHolder && <>{iconPlaceHolder}</>}
        <Label>
          <span title={labelValue}>{labelValue}</span>
        </Label>
        <Actions>
          {isClearable && hasValue && (
            <Clear alt={t('components:design-system.select.button-clear-alt')} onClick={onClear} />
          )}
          <Rotate hasRotated={isOpen}>
            <CaretIcon />
          </Rotate>
        </Actions>
      </PlaceHolder>
      <Items $isOpen={isOpen}>
        {options.map(({ name, value }, index) => {
          const isSelected = value === selectedValue?.value;
          const isHovered = index === hoveredItemIndex;

          const classes = [
            ...(isSelected ? ['selected'] : []),
            ...(isHovered ? ['hovered'] : []),
          ].join(' ');

          return (
            <Item
              key={`${name}-${value}`}
              className={classes}
              id={`${selectUid}-item-${index}`}
              onClick={onItemClick({ name, value }, index)}
              onMouseEnter={handleItemMouseEnter(index)}
            >
              <span title={name}>{name}</span>
            </Item>
          );
        })}
      </Items>
    </Container>
  );
}

Select.propTypes = {
  defaultValue: PropTypes.object,
  isClearable: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.string,
};

export default Select;
