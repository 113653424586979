import { rest } from 'msw';

import apiSettings from '@/apis/semji/settings';

const HTTP_NO_CONTENT = 204;

function getProxyHandlers(host) {
  async function patchRequest(req, res, ctx) {
    const { url } = req;
    url.host = host;
    const originalResponse = await ctx.fetch({
      ...req,
      url,
    });

    const body = await originalResponse.text();
    const { status, statusText, headers } = originalResponse;

    if (status === HTTP_NO_CONTENT) {
      return res(ctx.status(HTTP_NO_CONTENT));
    } else {
      return res(
        ctx.set(Object.fromEntries(headers.entries())),
        ctx.status(status || 200, statusText),
        ctx.body(body)
      );
    }
  }

  return [
    rest.get(`${apiSettings.rootUrl}/*`, patchRequest),
    rest.post(`${apiSettings.rootUrl}/*`, patchRequest),
    rest.put(`${apiSettings.rootUrl}/*`, patchRequest),
    rest.patch(`${apiSettings.rootUrl}/*`, patchRequest),
    rest.delete(`${apiSettings.rootUrl}/*`, patchRequest),
    rest.options(`${apiSettings.rootUrl}/*`, patchRequest),
  ];
}

export default getProxyHandlers;
