import React from 'react';
import styled from 'styled-components/macro';

import Flex from '@/design-system/components/Flex';

const StyledMenuItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.textCss.sizes.sm};
  color: ${({ theme, color, isDisabled }) =>
    isDisabled
      ? theme.cssColors.dark060
      : color === 'secondary'
        ? theme.cssColors.primary
        : theme.cssColors.dark100};
  font-size: ${({ theme }) => theme.textCss.sizes.sm};
  cursor: inherit;
  max-width: 250px;
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};

  &:not([aria-disabled='true'])&:hover {
    background-color: ${({ theme }) => theme.cssColors.dark005};
    cursor: pointer;
  }
`;

const Description = styled.div`
  padding-top: 5px;
  font-size: ${({ theme }) => theme.textCss.sizes.xs};
  color: ${({ theme }) => theme.cssColors.dark060};
`;

const MenuItem = ({
  value,
  description,
  onClick,
  selected,
  color,
  isDisabled,
  badge,
  ...others
}) => (
  <StyledMenuItem
    aria-disabled={isDisabled}
    color={color}
    isDisabled={isDisabled}
    onClick={isDisabled ? undefined : onClick}
    {...others}
  >
    <div>
      {value}
      <Description>{description}</Description>
    </div>
    {!!badge && isDisabled && <Flex alignSelf="center">{badge}</Flex>}
  </StyledMenuItem>
);

export default MenuItem;
