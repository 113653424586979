import { DateTime } from 'luxon';
import SortDirection from 'react-virtualized/dist/es/Table/SortDirection';
import { DROPDOWN_SIZES } from 'semji-core/components/Dropdown';
import { FolderSelect } from 'semji-core/components/FolderSelect';
import { Checkbox } from 'semji-core/components/Input/Checkbox';
import { StatusSelect } from 'semji-core/components/StatusSelect';
import { AlertAltIcon } from 'semji-core/icons/AlertAltIcon';
import { getDecimalPlaces } from 'semji-core/utils/numbers';

import { FlexCenteredContainer } from '@/components/_common';
import DatePicker from '@/components/DatePicker';
import FocusTopKeywordAssociation from '@/components/FocusTopKeyword/FocusTopKeywordAssociation';
import Agenda from '@/components/icons/Agenda';
import CircleIcon from '@/components/icons/CircleIcon';
import EyeIcon from '@/components/icons/EyeIcon';
import FileIcon from '@/components/icons/FileIcon';
import FolderIcon from '@/components/icons/FolderIcon';
import LinkIcon from '@/components/icons/LinkIcon';
import SearchIcon from '@/components/icons/SearchIcon';
import SmallCapIcon from '@/components/icons/SmallCapIcon';
import TextIcon from '@/components/icons/TextIcon';
import UnfilledUserIcon from '@/components/icons/UnfilledUserIcon';
import WarningTriangleIcon from '@/components/icons/WarningTriangleIcon';
import FlatLoader, {
  CircularLoaderWrapper,
  DEFAULT_LOADER_HEIGHT,
  LoaderWrapper,
} from '@/components/Loader/FlatLoader';
import StartAnalysisCell from '@/components/Planning/StartAnalysisCell';
import { getDefaultFolderGeneral } from '@/components/Select/FolderSelect/constants';
import UserSelect from '@/components/Select/UserSelect';
import DotsMenu from '@/components/Table/Cell/ActionsCell';
import ContentScoreCell from '@/components/Table/Cell/ContentScoreCell';
import NotAcknowledgedCell from '@/components/Table/Cell/NotAcknowledgedCell';
import NumberCell from '@/components/Table/Cell/NumberCell';
import TitleCell from '@/components/Table/Cell/TitleCell';
import Table from '@/components/Table/Table';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import { FocusKeywordCell } from '@/containers/Planning/FocusKeywordCell';
import { getSources, SOURCE_SEMJI_CONTENT, SOURCE_TOP_PAGES } from '@/services/Page';
import { SOURCE_PLANNING_LIST, STATUS_PENDING, STATUS_QUEUED } from '@/utils/analysis';
import { FROM_ORIGIN } from '@/utils/constants';
import {
  BOOL_TYPE_FILTER,
  DATE_TYPE_FILTER,
  FOLDER_TYPE_FILTER,
  NUMBER_TYPE_FILTER,
  SELECT_TYPE_FILTER,
  STRING_TYPE_FILTER,
} from '@/utils/filter/constants';
import { METRIC_TYPE_NUMBER } from '@/utils/metrics/constants';

export function getFiltersList({ status, users, nullUser, folders }) {
  const sources = getSources();
  const defaultFolder = getDefaultFolderGeneral();

  return [
    {
      attribute: 'title',
      icon: <SmallCapIcon opacity={0.6} />,
      labelKey: 'listing:planning-list:filters-panel.title',
      type: STRING_TYPE_FILTER,
      uid: 'title',
    },
    {
      attribute: 'id',
      getValueByAttribute: (list, attribute) => list.contentStatus?.[attribute] ?? null,
      icon: <CircleIcon opacity={0.6} />,
      labelKey: 'listing:planning-list:filters-panel.status',
      mappingValues: (value) => ({
        labelKey: status.find((status) => status.id === value)?.label,
        value,
      }),
      selectList: status.map((status) => ({
        labelKey: status.label,
        value: status.id,
      })),
      type: SELECT_TYPE_FILTER,
      uid: 'status',
    },
    {
      attribute: 'source',
      getValueByAttribute: (list) =>
        // we consider SOURCE_CHROME_EXTENSION_TOP_KEYWORD | SOURCE_URL_IMPORTED as SOURCE_TOP_PAGES for this filter because the three are considered as Existing content and not new content
        list.source === SOURCE_SEMJI_CONTENT ? SOURCE_SEMJI_CONTENT : SOURCE_TOP_PAGES,
      icon: <FileIcon opacity={0.6} />,
      labelKey: 'listing:planning-list:filters-panel.type',
      mappingValues: (value) => ({
        labelKey: sources.find((source) => source.id === value)?.label,
        value: sources.find((source) => source.id === value)?.id,
      }),
      selectList: sources.map((source) => ({
        labelKey: source.label,
        value: source.id,
      })),
      type: SELECT_TYPE_FILTER,
      uid: 'source',
    },
    {
      attribute: 'dueDate',
      getValueByAttribute: (list, attribute) =>
        list[attribute] ? DateTime.fromISO(list[attribute]).toFormat('yyyy-MM-dd') : null,
      icon: <Agenda opacity={0.6} />,
      labelKey: 'listing:planning-list:filters-panel.due-date',
      mappingValues: (value) => ({
        labelKey: value?.isValid ? value.toFormat('yyyy-MM-dd') : null,
        value: value?.isValid ? value.toFormat('yyyy-MM-dd') : null,
      }),
      type: DATE_TYPE_FILTER,
      uid: 'dueDate',
    },
    {
      attribute: 'id',
      // for unassigned user the id is a frontend id
      // the api is returning a null user
      // so we should match the frontend id with a null
      getValueByAttribute: (list, attribute) =>
        list.assignedTo ? list.assignedTo[attribute] : nullUser.id,

      icon: <UnfilledUserIcon opacity={0.6} />,
      labelKey: 'listing:planning-list:filters-panel.assigned-to',
      mappingValues: (value) => ({
        labelKey: users.find((user) => user.id === value)?.email || nullUser.lastName,
        value,
      }),
      selectList: users.map((user) => ({
        labelKey: user.id === nullUser.id ? nullUser.lastName : user.email,
        value: user.id,
      })),
      type: SELECT_TYPE_FILTER,
      uid: 'assignedTo',
    },
    {
      attribute: 'folderId',
      icon: <FolderIcon opacity={0.6} />,
      labelKey: 'listing:planning-list:filters-panel.folder',
      mappingValues: (value) => ({
        labelKey: folders.find((folder) => folder.id === value)?.name || defaultFolder.name,
        value,
      }),
      type: FOLDER_TYPE_FILTER,
      uid: 'folderId',
    },
    {
      attribute: 'keyword',
      icon: <TextIcon opacity={0.6} />,
      labelKey: 'listing:planning-list:filters-panel.keyword',
      type: STRING_TYPE_FILTER,
      uid: 'keyword',
    },
    {
      attribute: 'searchVolume',
      icon: <SearchIcon opacity={0.6} />,
      labelKey: 'listing:planning-list:filters-panel.search-volume',
      mappingValues: (value) => ({
        labelKey: value === true ? '' : value,
        value,
      }),
      type: NUMBER_TYPE_FILTER,
      uid: 'searchVolume',
    },
    {
      attribute: 'contentScore',
      getValueByAttribute: (list) => list.contentScore * 100,
      icon: <EyeIcon opacity={0.6} />,
      labelKey: 'listing:planning-list:filters-panel.content-score',
      mappingValues: (value) => ({
        labelKey: value === true ? '' : value,
        value,
      }),
      type: NUMBER_TYPE_FILTER,
      uid: 'contentScore',
    },
    {
      attribute: 'lastStatusCode',
      icon: <AlertAltIcon opacity={0.6} />,
      labelKey: 'listing:pages-list.filters-panel.http-code',
      mappingValues: (value) => ({
        labelKey: value === true ? '' : value,
        value,
      }),
      type: NUMBER_TYPE_FILTER,
      uid: 'lastStatusCode',
    },
    {
      attribute: 'isCannibalized',
      getValueByAttribute: (list) => {
        const hasMultipleAssociatedPages =
          list?.pageFocusTopKeyword?.associatedToPagesCount > 1 ||
          (list?.pageFocusTopKeyword?.associatedToDraftsCount > 1 &&
            list?.pageFocusTopKeyword?.associatedToPagesCount > 0);
        const hasMultipleAssociatedDrafts =
          list?.pageFocusTopKeyword?.associatedToDraftsCount > 1 ||
          (list?.pageFocusTopKeyword?.associatedToPagesCount > 1 &&
            list?.pageFocusTopKeyword?.associatedToDraftsCount > 0);
        return (
          (hasMultipleAssociatedDrafts || hasMultipleAssociatedPages) &&
          list?.contentStatus?.label !== 'Published'
        );
      },
      icon: <WarningTriangleIcon opacity={0.6} />,
      labelKey: 'listing:pages-list.filters-panel.cannibalization.title',
      mappingValues: (value) => ({
        labelKey: 'listing:pages-list.filters-panel.cannibalization.label',
        value,
      }),
      placeholder: 'listing:pages-list.filters-panel.cannibalization.placeholder',
      type: BOOL_TYPE_FILTER,
      uid: 'isCannibalized',
    },
    {
      attribute: 'url',
      icon: <LinkIcon opacity={0.6} />,
      labelKey: 'report:filters-panel.url',
      type: STRING_TYPE_FILTER,
      uid: 'url',
    },
  ];
}

export function getColumns({
  contentsIds,
  DEFAULT_FOLDER_GENERAL,
  folders,
  handleDelete,
  handleSelectAllChange,
  handleSelectChange,
  isAllCheckBoxChecked,
  isLoading,
  normalizedFolders,
  triggerAnalysis,
  onChangeAssignedTo,
  onChangeContentStatus,
  onChangeDueDate,
  onChangeFolder,
  createNewFolder,
  organizationId,
  setContentToMarkAsPublished,
  status,
  t,
  tab,
  users,
  hasSearchConsole,
  workspaceCountryName,
  isOrganizationFoldersLimit,
  canAccessToVersionHistory,
}) {
  return [
    {
      align: 'center',
      cellDataGetter: ({ rowData }) => ({
        id: rowData.id,
        loading: rowData.loading,
      }),
      cellRenderer: ({ cellData }) =>
        cellData.loading ? (
          <LoaderWrapper height={20} width={20}>
            <FlatLoader />
          </LoaderWrapper>
        ) : (
          <Checkbox
            checked={contentsIds.includes(cellData.id)}
            name={cellData.id}
            onChange={handleSelectChange}
          />
        ),
      dataKey: 'select',
      headerCellRenderer: () => (
        <FlexCenteredContainer>
          <Checkbox
            checked={isAllCheckBoxChecked}
            disabled={isLoading}
            onChange={handleSelectAllChange}
          />
        </FlexCenteredContainer>
      ),
      label: t('listing:planning-list:list.select'),
      sortable: false,
      width: 50,
    },
    {
      cellDataGetter: ({ rowData }) => ({
        contentId: rowData.id,
        isRedirectionDetected: !!rowData.page?.redirectionUrl,
        lastStatusCode: rowData.lastStatusCode,
        loading:
          (!rowData.page?.titleRetrievedAt &&
            !rowData.title &&
            rowData.page?.source !== SOURCE_SEMJI_CONTENT) ||
          rowData.loading,
        new: rowData.page?.new,
        pageId: rowData.page?.id,
        publishedAt: rowData.publishedAt,
        title: rowData.title,
        url: rowData.page?.url,
        workspaceId: rowData.workspaceId,
      }),
      cellRenderer: ({ cellData }) => (
        <TitleCell
          canAccessToVersionHistory={canAccessToVersionHistory}
          contentId={cellData.contentId}
          isNewContent={cellData.new}
          isRedirectionDetected={cellData.isRedirectionDetected}
          isUpdatedContent={!cellData.new}
          lastStatusCode={cellData.lastStatusCode}
          loading={cellData.loading}
          organizationId={organizationId}
          pageId={cellData.pageId}
          publishedAt={cellData.publishedAt}
          tab={tab}
          title={cellData.title}
          type={FROM_ORIGIN.PLANNING}
          url={cellData.url}
          workspaceId={cellData.workspaceId}
        />
      ),
      customizableHeader: true,
      dataKey: 'title',
      flexGrow: 1,
      label: (
        <TooltipComponent
          description={t('listing:planning-list:list.tooltip.draft')}
          placement="top"
          title={t('listing:planning-list:list.draft')}
        >
          {t('listing:planning-list:list.draft')}
        </TooltipComponent>
      ),
      sortFunction: (value1, value2, sortDirection) =>
        Table.stringSort(value1.title, value2.title, sortDirection),
      sortable: true,
      width: 150,
    },
    {
      align: 'center',
      cellDataGetter: ({ rowData }) => ({
        contentId: rowData.id,
        createdAt: rowData.createdAt,
        loading: rowData.loading,
        pageId: rowData.page?.id,
        readOnly: rowData.type !== 'DRAFT',
        score: rowData.contentScore,
        status: rowData.contentStatus,
        title: rowData.title,
        url: rowData.page?.url,
      }),
      cellRenderer: ({ cellData }) => {
        function handleChange({ id }) {
          if (!cellData.readOnly) {
            onChangeContentStatus({
              contentId: cellData.contentId,
              createdAt: cellData.createdAt,
              id,
              pageId: cellData.pageId,
              pageUrl: cellData.url,
              score: cellData.score,
              title: cellData.title,
            });
          }
        }

        if (cellData.loading) {
          return (
            <LoaderWrapper height={DEFAULT_LOADER_HEIGHT} width={100}>
              <FlatLoader />
            </LoaderWrapper>
          );
        }
        return (
          <StatusSelect
            key={`${cellData.pageId}_${cellData.status.id}_content_status`}
            disabled={cellData.readOnly}
            handleChange={handleChange}
            options={status}
            value={cellData.status.id}
          />
        );
      },
      dataKey: 'status',
      label: (
        <TooltipComponent
          description={t('listing:planning-list:list.tooltip.status')}
          placement="top"
          title={t('listing:planning-list:list.status')}
        >
          {t('listing:planning-list:list.status')}
        </TooltipComponent>
      ),
      minWidth: 110,
      sortFunction: (value1, value2, sortDirection) =>
        Table.naturalSort(
          value1.readOnly ? Infinity : value1.status.position,
          value2.readOnly ? Infinity : value2.status.position,
          sortDirection
        ),
      sortable: true,
      width: 110,
    },
    {
      cellDataGetter: ({ rowData }) => ({
        loading:
          (!rowData.topKeywordsRetrievedAt &&
            !rowData.pageFocusTopKeyword &&
            rowData.page?.source !== SOURCE_SEMJI_CONTENT) ||
          rowData.loading,
        pageFocusTopKeyword: rowData.pageFocusTopKeyword,
        type: rowData.type,
      }),
      cellRenderer: ({ cellData }) =>
        !cellData.loading &&
        cellData.pageFocusTopKeyword &&
        cellData.type !== 'PUBLISHED' && (
          <FocusTopKeywordAssociation focusTopKeyword={cellData.pageFocusTopKeyword} />
        ),
      dataKey: 'focusKeywordAssociation',

      width: 25,
    },
    {
      cellDataGetter: ({ rowData }) => ({
        contentScore: rowData.contentScore,
        id: rowData.id,
        loading:
          (!rowData.topKeywordsRetrievedAt &&
            !rowData.pageFocusTopKeyword &&
            rowData.page?.source !== SOURCE_SEMJI_CONTENT) ||
          rowData.loading,
        pageFocusTopKeyword: rowData.pageFocusTopKeyword,
        pageId: rowData.pageId,
        type: rowData.type,
      }),
      cellRenderer: ({ cellData }) => (
        <FocusKeywordCell
          {...cellData}
          dataIntercomTarget="draft_focus_keyword_selector"
          hasSearchConsole={hasSearchConsole}
          triggerAnalysis={triggerAnalysis}
          workspaceCountryName={workspaceCountryName}
        />
      ),
      dataKey: 'focusKeyword',
      label: (
        <TooltipComponent
          description={t('listing:planning-list:list.tooltip.focus-keyword')}
          placement="top"
          title={t('listing:planning-list:list.focus-keyword')}
        >
          {t('listing:planning-list:list.focus-keyword')}
        </TooltipComponent>
      ),
      sortFunction: (value1, value2, sortDirection) =>
        Table.stringSort(
          value1.pageFocusTopKeyword?.keyword,
          value2.pageFocusTopKeyword?.keyword,
          sortDirection
        ),
      sortable: true,
      width: 150,
    },
    {
      align: 'center',
      cellDataGetter: ({ rowData }) => ({
        loading:
          (rowData.pageFocusTopKeyword &&
            !rowData.pageFocusTopKeyword.keywordDataUpdatedAt &&
            rowData.pageFocusTopKeyword.searchVolume === null) ||
          rowData.loading,
        searchVolume: rowData.pageFocusTopKeyword?.searchVolume || null,
      }),
      cellRenderer: ({ cellData }) => {
        if (cellData.loading) {
          return (
            <LoaderWrapper height={DEFAULT_LOADER_HEIGHT} width={40}>
              <FlatLoader />
            </LoaderWrapper>
          );
        }
        if (cellData.searchVolume !== null) {
          const decimalPlaces = getDecimalPlaces(cellData.searchVolume, METRIC_TYPE_NUMBER);
          return (
            <NumberCell
              decimalPlaces={decimalPlaces}
              number={cellData.searchVolume ? cellData.searchVolume : null}
            />
          );
        }
        return <NotAcknowledgedCell />;
      },
      dataKey: 'focusKeywordVolume',
      label: (
        <TooltipComponent
          description={t('listing:planning-list:list.tooltip.volume')}
          placement="top"
          title={t('listing:planning-list:list.volume')}
        >
          {t('listing:planning-list:list.volume')}
        </TooltipComponent>
      ),
      sortFunction: (value1, value2, sortDirection) =>
        Table.naturalSort(value1.searchVolume, value2.searchVolume, sortDirection),
      sortable: true,
      width: 80,
    },
    {
      align: 'center',
      cellDataGetter: ({ rowData }) => ({
        analysisStatus: rowData.pageFocusTopKeyword?.analysisStatus,
        content: rowData,
        loading:
          (rowData.pageFocusTopKeyword &&
            [STATUS_PENDING, STATUS_QUEUED].includes(rowData.pageFocusTopKeyword.analysisStatus)) ||
          rowData.loading,
        score: rowData.contentScore,
      }),
      cellRenderer: ({ cellData }) => {
        if (cellData.loading) {
          return (
            <CircularLoaderWrapper height={40} width={40}>
              <FlatLoader />
            </CircularLoaderWrapper>
          );
        }
        if (cellData.score === null) {
          if (cellData?.content?.pageFocusTopKeyword && cellData?.content?.type === 'DRAFT') {
            return (
              <StartAnalysisCell
                onClick={() => triggerAnalysis([cellData.content], SOURCE_PLANNING_LIST)}
              />
            );
          }
          return <NotAcknowledgedCell align="center" />;
        }
        return <ContentScoreCell contentScore={cellData.score} />;
      },
      dataKey: 'contentScore',
      label: (
        <TooltipComponent
          description={t('listing:planning-list:list.tooltip.content-score')}
          placement="top"
          title={t('listing:planning-list:list.content-score')}
        >
          {t('listing:planning-list:list.content-score')}
        </TooltipComponent>
      ),
      sortFunction: (value1, value2, sortDirection) =>
        Table.naturalSort(
          [STATUS_PENDING, STATUS_QUEUED].includes(value1.analysisStatus) ? null : value1.score,
          [STATUS_PENDING, STATUS_QUEUED].includes(value2.analysisStatus) ? null : value2.score,
          sortDirection
        ),
      sortable: true,
      width: 120,
    },
    {
      align: 'center',
      cellDataGetter: ({ rowData }) => ({
        assignedTo: rowData.assignedTo ? rowData.assignedTo : users[0],
        contentId: rowData.id,
        loading: rowData.loading,
        pageId: rowData.page?.id,
      }),
      cellRenderer: ({ cellData }) => {
        function handleChange(id) {
          return onChangeAssignedTo(id, cellData.contentId);
        }

        if (cellData.loading) {
          return (
            <CircularLoaderWrapper height={30} width={30}>
              <FlatLoader />
            </CircularLoaderWrapper>
          );
        }
        return (
          <UserSelect
            key={`${cellData.pageId}_assigned_user`}
            centered
            handleChange={handleChange}
            options={users}
            shortVersion
            small
            value={cellData.assignedTo?.id || null}
            variant="column"
            width={300}
          />
        );
      },
      dataKey: 'assignedTo',
      label: (
        <TooltipComponent
          description={t('listing:planning-list:list.tooltip.assignation')}
          placement="top"
          title={t('listing:planning-list:list.assignation')}
        >
          {t('listing:planning-list:list.assignation')}
        </TooltipComponent>
      ),
      minWidth: 80,
      sortFunction: (value1, value2, sortDirection) =>
        Table.stringSort(value1.assignedTo?.firstName, value2.assignedTo?.firstName, sortDirection),
      sortable: true,
      width: 80,
    },
    {
      align: 'center',
      cellDataGetter: ({ rowData }) => ({
        contentId: rowData.id,
        dueDate: rowData.dueDate,
        loading: rowData.loading,
        pageId: rowData.page?.id,
      }),
      cellRenderer: ({ cellData }) => {
        function handleChange(id) {
          onChangeDueDate(id, cellData.contentId);
        }

        if (cellData.loading) {
          return (
            <LoaderWrapper height={DEFAULT_LOADER_HEIGHT} width={50}>
              <FlatLoader />
            </LoaderWrapper>
          );
        }
        return (
          <DatePicker
            key={`${cellData.pageId}_due_date`}
            dueDate={cellData.dueDate}
            handleChange={handleChange}
            shortVersion
          />
        );
      },
      dataKey: 'dueDate',
      hide: tab === 'published',
      label: (
        <TooltipComponent
          description={t('listing:planning-list:list.tooltip.due-date')}
          placement="top"
          title={t('listing:planning-list:list.due-date')}
        >
          {t('listing:planning-list:list.due-date')}
        </TooltipComponent>
      ),
      minWidth: 80,
      sortFunction: (value1, value2, sortDirection) => {
        const a = value1.dueDate ? DateTime.fromISO(value1.dueDate) : null;
        const b = value2.dueDate ? DateTime.fromISO(value2.dueDate) : null;

        if (+a === +b) {
          return 0;
        }

        if (sortDirection === SortDirection.ASC) {
          return (a && a < b) || (a && !b) ? -1 : 1;
        }

        return a < b ? 1 : -1;
      },
      sortable: true,
      width: 80,
    },

    {
      align: 'center',
      cellDataGetter: ({ rowData }) => ({
        contentId: rowData.id,
        dueDate: rowData.dueDate,
        loading: rowData.loading,
        pageId: rowData.page?.id,
        publicationDate: rowData.publishedAt,
        readOnly: rowData.type !== 'DRAFT',
      }),
      cellRenderer: ({ cellData }) =>
        cellData.loading ? (
          <LoaderWrapper height={DEFAULT_LOADER_HEIGHT} width={50}>
            <FlatLoader />
          </LoaderWrapper>
        ) : (
          <DatePicker
            key={`${cellData.pageId}_publication_date`}
            defaultColor
            disabled={true}
            dueDate={cellData.publicationDate}
            shortVersion
          />
        ),
      dataKey: 'publicationDate',
      hide: tab !== 'published',
      label: (
        <TooltipComponent
          description={t('listing:planning-list:list.tooltip.publication-date')}
          placement="top"
          title={t('listing:planning-list:list.publication-date')}
        >
          {t('listing:planning-list:list.publication-date')}
        </TooltipComponent>
      ),
      minWidth: 80,
      sortFunction: (value1, value2, sortDirection) => {
        const a = value1.publicationDate ? DateTime.fromISO(value1.publicationDate) : null;
        const b = value2.publicationDate ? DateTime.fromISO(value2.publicationDate) : null;

        if (+a === +b) {
          return 0;
        }

        if (sortDirection === SortDirection.ASC) {
          return (a && a < b) || (a && !b) ? -1 : 1;
        }

        return a < b ? 1 : -1;
      },
      sortable: true,
      width: 80,
    },

    {
      align: 'center',
      cellDataGetter: ({ rowData }) => ({
        contentId: rowData.id,
        folderId: rowData.folderId,
        loading: rowData.loading,
      }),
      cellRenderer: ({ cellData }) => {
        if (cellData.loading) {
          return (
            <LoaderWrapper height={DEFAULT_LOADER_HEIGHT} width={150}>
              <FlatLoader />
            </LoaderWrapper>
          );
        }

        return (
          <FolderSelect
            createNewFolder={createNewFolder}
            dropdownIcon={false}
            dropdownPillShape
            dropDownSize={DROPDOWN_SIZES.TABLE_WIDTH}
            folders={folders.data}
            isCreationDisabled={isOrganizationFoldersLimit}
            isFrame={false}
            menuSize="wide"
            setValue={onChangeFolder(cellData.contentId)}
            translations={{
              button: t('components:select.folder-select.create-folder.label-button'),
              cancel: t('components:select.folder-select.create-folder.cancel'),
              create_folder: t('components:select.folder-select.folder-select.label-create-folder'),
              folder_general: t('components:select.folder-select.label-folder-general'),
              label_no_parent: t('components:filters.folder-filters-panel.label-no-parent'),
              loading: t('components:select.folder-select.create-folder.label-loading'),
              placeHolder: t('components:select.folder-select.create-folder.input-placeholder'),
              search_placeholder: t(
                'components:select.folder-select.folder-select.search-placeholder'
              ),
              title: t('components:select.folder-select.create-folder.title'),
              tooltip_disabled: t('components:select.folder-select.create-folder.tooltip-disabled'),
            }}
            value={cellData.folderId}
            width="460px"
            withFolderCreation
            withSearchInput
          />
        );
      },
      dataKey: 'folder',
      label: (
        <TooltipComponent
          description={t('listing:planning-list:list.tooltip.folder')}
          placement="top"
          title={t('listing:planning-list.list.folder')}
        >
          {t('listing:planning-list.list.folder')}
        </TooltipComponent>
      ),
      minWidth: 120,
      sortFunction: (value1, value2, sortDirection) =>
        Table.stringSort(
          normalizedFolders.entities?.[value1.folderId]?.name || DEFAULT_FOLDER_GENERAL.name,
          normalizedFolders.entities?.[value2.folderId]?.name || DEFAULT_FOLDER_GENERAL.name,
          sortDirection
        ),
      sortable: true,
      width: 120,
    },

    {
      cellDataGetter: ({ rowData }) => rowData,
      cellRenderer: ({ cellData }) => {
        function handleRemovePage() {
          handleDelete(cellData.id, cellData.page?.onlineContent, cellData.page?.id);
        }

        function handleMarkAsPublish() {
          setContentToMarkAsPublished({
            contentId: cellData.id,
            createdAt: cellData.createdAt,
            score: cellData.contentScore,
            title: cellData.title,
            url: cellData.page?.url,
          });
        }

        if (!cellData.loading) {
          return (
            <DotsMenu
              disabledAnalysisAction={
                !cellData?.pageFocusTopKeyword ||
                cellData?.type !== 'DRAFT' ||
                (cellData.pageFocusTopKeyword &&
                  [STATUS_PENDING, STATUS_QUEUED].includes(
                    cellData.pageFocusTopKeyword.analysisStatus
                  ) &&
                  cellData.contentScore === null) ||
                cellData.loading
              }
              disabledPublishAction={cellData.type !== 'DRAFT'}
              disabledRemoveAction={cellData.id === cellData.page?.onlineContent}
              handleAnalysis={() => triggerAnalysis([cellData], SOURCE_PLANNING_LIST)}
              handlePublishAction={handleMarkAsPublish}
              handleRemovePage={handleRemovePage}
            />
          );
        }
      },
      dataKey: 'actions',
      label: ' ',
      width: 50,
    },
  ];
}
