import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { FlexContainer } from '@/components/_common';
import { RedirectionBadge } from '@/components/Badge/Badge';
import { PanelContent } from '@/components/Panel/Panel';
import ScrollBar from '@/components/Scrollbar';
import { BrightText } from '@/components/Text/Light';
import MainMetricsEvolution from '@/containers/Content/SidePanel/Components/Performance/Section/MainMetricsEvolution';
import { PageNotPublishedStatsPanelBody } from '@/containers/Content/SidePanel/Components/Performance/Section/PageNotPublishedStatsPanelBody';
import { Loader, LoaderText } from '@/design-system/components/Loader';
import PageService from '@/services/Page';
import { SECTIONS } from '@/utils/log/constants';
import { Log } from '@/utils/log/Log';

function Performance({ loading, refreshing, showMarkAsPublishedDialog, url }) {
  const { t } = useTranslation();
  const [isWaiting, setIsWaiting] = useState(true);
  const [page, setPage] = useState(null);
  const { pageId, workspaceId } = useParams();

  const isLoading = loading || isWaiting;

  const getPage = async () => {
    try {
      setIsWaiting(true);
      const pageService = new PageService(pageId, workspaceId);
      const page = await pageService.page;
      setIsWaiting(false);
      setPage(page);
    } catch (error) {
      setIsWaiting(false);
      setPage(null);
      Log.report({
        context: 'componentDidMount',
        error,
        extra: 'get Pages in Performance panel',
        section: SECTIONS.content.key,
      });
    }
  };

  useEffect(() => {
    getPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <Loader>
        {refreshing && (
          <LoaderText>
            {t('content:side-panel-components.performance.updating-performances')}
          </LoaderText>
        )}
      </Loader>
    );
  }

  if (url && !isLoading && !!page) {
    return (
      <ScrollBar>
        <PanelContent>
          <MainMetricsEvolution
            page={page}
            pageId={pageId}
            showMarkAsPublishedDialog={showMarkAsPublishedDialog}
          />
          {!!page.redirectionUrl && (
            <FlexContainer style={{ fontSize: '.8em' }}>
              <RedirectionBadge />
              <BrightText>
                {t('content:side-panel-components.performance.the-redirect-URL-is-tracked')}
              </BrightText>
            </FlexContainer>
          )}
        </PanelContent>
      </ScrollBar>
    );
  }

  return <PageNotPublishedStatsPanelBody showMarkAsPublishedDialog={showMarkAsPublishedDialog} />;
}

Performance.propTypes = {
  loading: PropTypes.bool.isRequired,
  showMarkAsPublishedDialog: PropTypes.func.isRequired,
  url: PropTypes.string,
};

export default Performance;
