import React from 'react';

import SvgWrapper from './SvgWrapper';

const DeleteIcon = (props) => (
  <SvgWrapper {...props} viewBox="0 0 13 17">
    <path d="M8.57824 1.68428C8.34741 0.76416 7.52047 0.0791016 6.52941 0.0791016C5.54259 0.0791016 4.71882 0.759925 4.48482 1.68428H0.705883V2.7431H12.3529V1.68428H8.57824ZM6.52941 1.13793C6.93388 1.13793 7.27588 1.37193 7.45588 1.68428H5.62094C5.80306 1.36345 6.13447 1.13793 6.52941 1.13793ZM10.7647 3.78498H11.8235V14.9196C11.8235 15.7952 11.1109 16.5078 10.2353 16.5078H2.82353C1.94788 16.5078 1.23529 15.7952 1.23529 14.9196V3.78498H2.29412V14.9196C2.29412 15.2107 2.53129 15.449 2.82353 15.449H10.2353C10.5275 15.449 10.7647 15.2107 10.7647 14.9196V3.78498ZM5.47059 4.84381V13.3144H4.41177V4.84381H5.47059ZM8.64706 4.84381V13.3144H7.58824V4.84381H8.64706Z" />
  </SvgWrapper>
);

export default DeleteIcon;
