import {
  deleteOrganizationAccesses,
  fetchOrganizationOrganizationAccesses,
  postInviteUsersOnOrganization,
  setOrganizationAccesses,
} from '@/apis/semji/api';
import apiClient from '@/apis/semji/apiClient';
import { SECTIONS } from '@/utils/log/constants';
import { Log } from '@/utils/log/Log';
import { sortUsers } from '@/utils/user';

export const TYPE_INVITE_ACTION = 'invite';
export const TYPE_RESEND_ACTION = 'resend';

export default class OrganizationAccesses {
  constructor(id, organizationId) {
    this._id = id;
    this._organizationId = organizationId;
  }

  get organizationAccesses() {
    return this._fetchOrganizationOrganizationAccesses();
  }

  async setOrganizationAccesses(role) {
    try {
      await setOrganizationAccesses(this._id, { roles: [role] });
    } catch (error) {
      Log.report({
        context: 'setOrganizationAccesses',
        error,
        extra: 'Organization Accesses Service',
        section: SECTIONS.organization.key,
      });
      throw error;
    }
  }

  async setOrganizationAccessesWebextension(accessToWebExtension) {
    try {
      await setOrganizationAccesses(this._id, { accessToWebExtension });
    } catch (error) {
      Log.report({
        context: 'setOrganizationAccessesWebextension',
        error,
        extra: 'Organization Accesses Service',
        section: SECTIONS.organization.key,
      });
      throw error;
    }
  }

  async deleteOrganizationAccesses() {
    return await deleteOrganizationAccesses(this._id);
  }

  async inviteUsers(users, workspaces, action) {
    return await postInviteUsersOnOrganization(this._organizationId, users, workspaces, action);
  }

  async _fetchOrganizationOrganizationAccesses() {
    try {
      const response = await fetchOrganizationOrganizationAccesses(this._organizationId);
      return sortUsers(response['hydra:member']);
    } catch (e) {
      throw e;
    }
  }
}

const findByUser = async (userId) => {
  const response = await apiClient.get(`/users/${userId}/organization_accesses`, {}, {});

  return response;
};

export { findByUser };
