import i18next from 'i18next';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import {
  getRoundedNumberMetricWithSuffix,
  getRoundedNumberWithSuffix,
} from 'semji-core/utils/numbers';
import styled from 'styled-components/macro';

import CloseIcon from '@/components/icons/CloseIcon';
import ErrorAltIcon from '@/components/icons/ErrorAltIcon';
import FlatLoader from '@/components/Loader/FlatLoader';
import { Cell } from '@/components/Pages/ContentIdeas/common';
import {
  FAILED_STATUS,
  PENDING_STATUS,
  SUCCESS_STATUS,
} from '@/components/Pages/ContentIdeas/constant';
import KeywordCell from '@/components/Pages/ContentIdeas/Menu/KeywordCell';
import Table from '@/components/Table/Table';
import TooltipComponent from '@/components/Tooltip/Tooltip';

export const StyledCloseIcon = styled(CloseIcon)`
  && {
    color: ${({ theme }) => theme.cssColors.dark060};
    display: none;
  }
`;

const CellLoaderWrapper = styled.div`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '50px')};
  height: 16px;
  margin: auto;
`;
export const StyledTooltipComponent = styled(TooltipComponent)`
  && {
    display: flex;
    justify-content: center;
    padding-top: ${({ paddingTop }) => paddingTop};
  }
`;

export function LoadingCellRender({
  status,
  value,
  isRounder = false,
  isMetric = false,
  fullWidth = false,
}) {
  const userLanguageCode = useSelector((state) => state.user?.languageCode);

  if (status === SUCCESS_STATUS) {
    if (isRounder) {
      if (isMetric) {
        return getRoundedNumberMetricWithSuffix({ locale: userLanguageCode, number: value });
      } else {
        return getRoundedNumberWithSuffix({ locale: userLanguageCode, number: value });
      }
    }
    return value;
  }
  if (status === PENDING_STATUS) {
    return (
      <CellLoaderWrapper fullWidth={fullWidth}>
        <FlatLoader />
      </CellLoaderWrapper>
    );
  }
  if (status === FAILED_STATUS) {
    return (
      <StyledTooltipComponent title={i18next.t(`content-ideas:menu.error.default`)}>
        <ErrorAltIcon />
      </StyledTooltipComponent>
    );
  }
}

export function getColumns(handleDelete) {
  const i18nNameSpace = 'content-ideas:menu.history';

  function handleDeleteFromHistory(id) {
    return () => {
      handleDelete(id);
    };
  }

  return [
    {
      cellDataGetter: ({ rowData }) => ({
        id: rowData.id,
        isDeprecated: rowData.isDeprecated,
        keyword: rowData.keyword,
        status: rowData.isSkeletonData ? PENDING_STATUS : SUCCESS_STATUS,
      }),
      cellRenderer: ({ cellData }) => <KeywordCell cellData={cellData} />,
      dataKey: 'keyword',
      flexGrow: 1,
      height: 50,
      label: (
        <Cell
          color="dark060"
          noPadding
          size="sm"
          title={i18next.t(`${i18nNameSpace}.focus-keyword`)}
          weight="strong"
        >
          {i18next.t(`${i18nNameSpace}.focus-keyword`)}
        </Cell>
      ),
      sortFunction: (value1, value2, sortDirection) =>
        Table.stringSort(value1.keyword, value2.keyword, sortDirection),
      sortable: true,
      width: 150,
    },
    {
      align: 'center',
      cellDataGetter: ({ rowData }) => ({
        nbContentIdeas:
          rowData.isSkeletonData || rowData.status === PENDING_STATUS
            ? null
            : rowData.nbContentIdeas || 0,
        status: rowData.isSkeletonData ? PENDING_STATUS : rowData.status,
      }),
      cellRenderer: ({ cellData }) => (
        <Cell align="center" color="dark060" noPadding size="sm" weight="medium" width="85px">
          <LoadingCellRender isRounder status={cellData.status} value={cellData.nbContentIdeas} />
        </Cell>
      ),
      dataKey: 'nbContentIdeas',
      label: (
        <Cell
          color="dark060"
          noPadding
          size="sm"
          title={i18next.t(`${i18nNameSpace}.contents`)}
          weight="strong"
        >
          {i18next.t(`${i18nNameSpace}.contents`)}
        </Cell>
      ),
      sortFunction: (value1, value2, sortDirection) =>
        Table.naturalSort(value1.nbContentIdeas, value2.nbContentIdeas, sortDirection),
      sortable: true,
      width: 85,
    },
    {
      align: 'center',
      cellDataGetter: ({ rowData }) => ({
        searchVolume:
          rowData.isSkeletonData || rowData.status === PENDING_STATUS
            ? null
            : rowData.searchVolume || 0,
        status: rowData.isSkeletonData ? PENDING_STATUS : rowData.status,
      }),
      cellRenderer: ({ cellData }) => (
        <Cell align="center" color="dark060" noPadding size="sm" weight="medium" width="75px">
          <LoadingCellRender
            isMetric
            isRounder
            status={cellData.status}
            value={cellData.searchVolume}
          />
        </Cell>
      ),
      dataKey: 'searchVolume',
      label: (
        <Cell
          color="dark060"
          noPadding
          size="sm"
          title={i18next.t(`${i18nNameSpace}.searchVolume`)}
          weight="strong"
          width="75px"
        >
          {i18next.t(`${i18nNameSpace}.searchVolume`)}
        </Cell>
      ),
      sortFunction: (value1, value2, sortDirection) =>
        Table.naturalSort(value1.searchVolume, value2.searchVolume, sortDirection),
      sortable: true,
      width: 75,
    },
    {
      align: 'center',
      cellDataGetter: ({ rowData }) => ({
        createdAt: rowData.createdAt,
        status: rowData.isSkeletonData ? PENDING_STATUS : SUCCESS_STATUS,
      }),
      cellRenderer: ({ cellData }) => (
        <Cell align="center" color="dark060" noPadding size="sm" weight="medium" width="85px">
          <LoadingCellRender
            status={cellData.status}
            value={DateTime.fromISO(cellData.createdAt).toFormat('dd LLL yy').toLocaleLowerCase()}
          />
        </Cell>
      ),
      dataKey: 'createdAt',
      label: (
        <Cell
          color="dark060"
          noPadding
          size="sm"
          title={i18next.t(`${i18nNameSpace}.date`)}
          weight="strong"
          width="85px"
        >
          {i18next.t(`${i18nNameSpace}.date`)}
        </Cell>
      ),
      sortFunction: (value1, value2, sortDirection) =>
        Table.naturalSort(value1.createdAt, value2.createdAt, sortDirection),
      sortable: true,
      width: 85,
    },
    {
      align: 'center',
      cellDataGetter: ({ rowData }) => ({
        id: rowData.id,
        isSkeletonData: rowData.isSkeletonData,
      }),
      cellRenderer: ({ cellData }) =>
        !cellData.isSkeletonData && (
          <Cell cursor={`pointer`} lineHeight="0" noPadding size="sm">
            <TooltipComponent title={i18next.t(`${i18nNameSpace}.delete`)}>
              <StyledCloseIcon onClick={handleDeleteFromHistory(cellData.id)} />
            </TooltipComponent>
          </Cell>
        ),
      dataKey: 'deleteFromHistory',
      label: '',
      sortable: false,
      width: 40,
    },
  ];
}
