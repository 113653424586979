import {
  RESET_EDITOR_STATE,
  SET_AI_LAST_GENERATION_TYPE,
  SET_AI_THINKING_FINISHED,
  SET_AI_THINKING_STARTED,
  SET_AI_WRITING_FINISHED,
  SET_AI_WRITING_STARTED,
  SET_SELECTION_TEXT,
} from './actionTypes';

export const resetEditor = () => (dispatch) => {
  dispatch({
    type: RESET_EDITOR_STATE,
  });
};

export const setAiThinkingStarted = () => (dispatch) => {
  dispatch({
    type: SET_AI_THINKING_STARTED,
  });
};

export const setAiThinkingFinished = () => (dispatch) => {
  dispatch({
    type: SET_AI_THINKING_FINISHED,
  });
};

export const setAiWritingStarted = () => (dispatch) => {
  dispatch({
    type: SET_AI_WRITING_STARTED,
  });
};

export const setAiWritingFinished = () => (dispatch) => {
  dispatch({
    type: SET_AI_WRITING_FINISHED,
  });
};

export const setAiLastGenerationType = (payload) => (dispatch) => {
  dispatch({
    payload,
    type: SET_AI_LAST_GENERATION_TYPE,
  });
};

export const setSelectionText = (payload) => (dispatch) => {
  dispatch({
    payload,
    type: SET_SELECTION_TEXT,
  });
};
