import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { LinkLikeButton } from '@/components/Button/Button';
import { ParagraphText } from '@/components/Text/Paragraph';
import { Title } from '@/containers/Settings/Profile/Security';
import ChangePasswordDialog from '@/containers/Settings/Profile/Security/ChangePassword/ChangePasswordDialog';
import SetPasswordDialog from '@/containers/Settings/Profile/Security/ChangePassword/SetPasswordDialog';

function ChangePassword() {
  const { t } = useTranslation();
  const isExternalLogin = useSelector((state) => state.user?.isExternalLogin);
  const Dialog = isExternalLogin ? SetPasswordDialog : ChangePasswordDialog;

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <Title noMargin weight="strong">
        {t('settings:workspace.change-password.title')}
      </Title>
      <ParagraphText color="dark080" noPadding weight="medium">
        {t(
          isExternalLogin
            ? 'settings:workspace.change-password.text-set-password'
            : 'settings:workspace.change-password.text-change-password'
        )}
      </ParagraphText>
      <LinkLikeButton noPadding onClick={() => setIsDialogOpen(true)}>
        {t(
          isExternalLogin
            ? 'settings:workspace.change-password.button-set-password'
            : 'settings:workspace.change-password.button-change-password'
        )}
      </LinkLikeButton>
      <Dialog isOpen={isDialogOpen} onCancel={() => setIsDialogOpen(false)} />
    </>
  );
}

export default ChangePassword;
