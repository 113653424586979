import { DateTime, Duration } from 'luxon';

import { getDiffInDaysFromDate } from '@/utils/date';

import {
  METRICS_CONFIG_PERIOD_LAST_30_DAYS,
  METRICS_CONFIG_PERIOD_LAST_90_DAYS,
  METRICS_CONFIG_PERIOD_LAST_180_DAYS,
  METRICS_CONFIG_PERIOD_LAST_365_DAYS,
  METRICS_CONFIG_PERIODICITY_MONTHLY,
  METRICS_CONFIG_PERIODICITY_WEEKLY,
  PLOT_LINE_LOCK_TYPE,
} from './constants';
import { getPreviousDateRange } from './getPreviousDateRange';

const DISPLAY_POSITION_THRESHOLD = -60; // in days
const MOVING_PERIODS = [
  METRICS_CONFIG_PERIOD_LAST_30_DAYS,
  METRICS_CONFIG_PERIOD_LAST_90_DAYS,
  METRICS_CONFIG_PERIOD_LAST_180_DAYS,
  METRICS_CONFIG_PERIOD_LAST_365_DAYS,
];

export function buildLockMarker({ period, comparisonPeriod, featureFlagData, periodicity, today }) {
  if (!featureFlagData.data) {
    return [];
  }

  const range = getPreviousDateRange({ comparisonPeriod, period, today });
  const todayDate = today ?? DateTime.local();
  let lockDate = todayDate.minus(Duration.fromISO(featureFlagData.data).toObject());

  if (periodicity === METRICS_CONFIG_PERIODICITY_WEEKLY && !MOVING_PERIODS.includes(period)) {
    lockDate = lockDate.startOf('week');
  }

  if (periodicity === METRICS_CONFIG_PERIODICITY_MONTHLY && !MOVING_PERIODS.includes(period)) {
    lockDate = lockDate.startOf('month');
  }

  if (range.from <= lockDate && range.to >= lockDate) {
    const diff = getDiffInDaysFromDate(lockDate.plus({ years: 1 }).toISODate(), todayDate);

    return [
      {
        label: `chart:plotline.label-available-in-upper-plan`,
        tooltipPosition: diff < DISPLAY_POSITION_THRESHOLD ? 'right' : 'left',
        type: PLOT_LINE_LOCK_TYPE,
        upperPlan: featureFlagData.upperPlan,
        x: lockDate.plus({ years: 1 }).toMillis(),
      },
    ];
  }

  return [];
}
