import './InviteUsers.scss';

import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import uniq from 'lodash/uniq';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DialogModal } from 'semji-core/components/DialogModal';
import { Checkbox } from 'semji-core/components/Input/Checkbox';

import LabelledFavIcon from '@/components/FavIcon/LabelledFavIcon';
import CountryLanguageFlag from '@/components/Flag/CountryLanguage';
import ErrorAvailableSeatsLeftMessage from '@/components/Message/ErrorAvailableSeatsLeftMessage';
import Medium from '@/components/Text/Medium';
import { ParagraphText } from '@/components/Text/Paragraph';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import { selectOrganizationAvailableSeats } from '@/store/selectors/selectOrganizationAvailableSeats';
import { isValidEmail } from '@/utils/email';
import { getInitialsFromEmail } from '@/utils/user';

export function InviteUsersInput({
  isDisabled,
  inputValue,
  availableUsers,
  emailDomain,
  onInputBlur,
  emails,
  handleChange,
  handleInputChange,
  canBeInvited,
  alreadyInvitedUsers,
  className,
}) {
  function InputRender(params) {
    const { t } = useTranslation();
    function handleOnMouseDownCapture(e) {
      e.stopPropagation();
    }

    return (
      <TextField
        {...params}
        className={className}
        // There is a bug in material ui which cause to open the list all the time
        // https://github.com/mui-org/material-ui/issues/20286
        multiline
        placeholder={`${t('components:dialog.invite-users.name')}@${emailDomain}, ...`}
        rows={5}
        variant="outlined"
        onBlur={onInputBlur}
        onMouseDownCapture={handleOnMouseDownCapture}
      />
    );
  }

  function TagsRender(value, getTagProps) {
    function TagRender({ option, index }) {
      return (
        <Chip
          avatar={<Avatar>{getInitialsFromEmail(option)}</Avatar>}
          color={
            isValidEmail(option) && canBeInvited(option) && !alreadyInvitedUsers.includes(option)
              ? undefined
              : 'secondary'
          }
          label={option}
          variant="outlined"
          {...getTagProps({ index })}
        />
      );
    }

    return value.map((option, index) => <TagRender key={index} index={index} option={option} />);
  }

  return (
    <Autocomplete
      disableClearable
      disabled={isDisabled}
      filterSelectedOptions
      freeSolo
      id="multiple-emails-tags"
      inputValue={inputValue}
      multiple
      options={availableUsers}
      renderInput={InputRender}
      renderTags={TagsRender}
      value={emails}
      onChange={handleChange}
      onInputChange={handleInputChange}
    />
  );
}

export default function InviteUsers({
  availableUsers,
  alreadyInvitedUsers,
  availableWorkspaces = [],
  handleSendInvites,
  handleCancel,
  open,
  accessName,
  inviting,
  keyName,
  emailDomain,
  organizationId,
}) {
  const { t } = useTranslation();

  const [emails, setEmails] = useState([]);
  const [currentInputValue, setCurrentInputValue] = useState('');
  const [selectedWorkspaces, setSelectedWorkspaces] = useState([]);
  const [error, setError] = useState(null);
  const { isFeatureEnabled } = useOrganizationFeatureSet('users:invitations:has-unlimited-amount');
  const availableSeats = useSelector(selectOrganizationAvailableSeats);

  function getValidEmails() {
    return emails.filter((email) => isValidEmail(email) && !alreadyInvitedUsers.includes(email));
  }

  function getListOfCanBeInvitedUsers() {
    const validUsers = getValidEmails();
    const newUsers = validUsers.filter((validUser) => !availableUsers.includes(validUser));
    const alreadyUsers = validUsers.filter((validUser) => availableUsers.includes(validUser));

    if (isFeatureEnabled) {
      return [...newUsers, ...alreadyUsers];
    } else {
      return [...newUsers.slice(0, availableSeats), ...alreadyUsers];
    }
  }

  function seatsWillBeConsumed() {
    return getValidEmails().filter((email) => !availableUsers.includes(email)).length;
  }

  async function handleSubmit() {
    try {
      setError(null);
      const invitableUsers = getListOfCanBeInvitedUsers();
      await handleSendInvites(invitableUsers, selectedWorkspaces);
      setEmails([]);
      setSelectedWorkspaces([]);
      setCurrentInputValue('');
    } catch (e) {
      setError(t('components:dialog.invite-users.error'));
    }
  }

  function handleChange(event, values) {
    setEmails(uniq(values.map((email) => email.toLowerCase())));
  }

  function onInputBlur(event) {
    if (currentInputValue) {
      setEmails(uniq([...emails, currentInputValue].map((email) => email.toLowerCase())));
      setCurrentInputValue('');
    }
  }

  function handleWorkspacesChange(event, values, reason) {
    setSelectedWorkspaces(values);
  }

  function handleInputChange(event, value) {
    //  we split on ";" " " "\t" "\n"
    const splittedInputValue = value.split(/[,;\n\t ]+/);

    if (splittedInputValue.length > 1) {
      setEmails(
        uniq(
          [...emails, ...splittedInputValue.filter((val) => !!val)].map((email) =>
            email.toLowerCase()
          )
        )
      );
      setCurrentInputValue('');
    } else {
      setCurrentInputValue(value.toLowerCase());
    }
  }

  function canBeInvited(email) {
    return (
      getListOfCanBeInvitedUsers().findIndex((invitableUser) => invitableUser === email) !== -1
    );
  }

  function onCancel() {
    setEmails([]);
    setCurrentInputValue('');
    setSelectedWorkspaces([]);
    setError(null);
    handleCancel();
  }

  return (
    <DialogModal
      canClickOutsideToClose
      closable
      confirmAction={handleSubmit}
      confirmLabel={t(
        inviting
          ? 'components:dialog.invite-users.button-inviting'
          : 'components:dialog.invite-users.button-send-invitations'
      )}
      error={!!error && { error }}
      isConfirmDisabled={
        inviting ||
        getListOfCanBeInvitedUsers().length === 0 ||
        (availableWorkspaces.length > 0 && selectedWorkspaces.length === 0)
      }
      isOpen={open}
      title={
        <span>
          <Trans
            components={{ medium: <Medium /> }}
            i18nKey="components:dialog.invite-users.title"
            values={{ accessName }}
          />
        </span>
      }
      onClose={onCancel}
    >
      {availableWorkspaces.length > 0 && (
        <>
          <ParagraphText color="dark060" noPadding weight="strong">
            {t('components:dialog.invite-users.add-to-workspaces')}
          </ParagraphText>
          <Autocomplete
            disableClearable
            disableCloseOnSelect
            disabled={inviting}
            getOptionLabel={(option) => option.name}
            groupBy={() => 'SUGGESTED WORKSPACES'}
            id="multiple-workspaces-tags"
            multiple
            options={availableWorkspaces}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={
                  selectedWorkspaces.length > 0
                    ? ''
                    : `${availableWorkspaces
                        .map((aw) => aw.name)
                        .slice(0, 2)
                        .join(', ')}...`
                }
                variant="outlined"
              />
            )}
            renderOption={(option, { selected }) => (
              <>
                <Checkbox checked={selected} />
                <CountryLanguageFlag
                  countryCode={option.countryCode}
                  isObvious={option.isObvious}
                  languageCode={option.languageCode}
                />
                <LabelledFavIcon disableClick label={option.name} url={option.websiteUrl} />
              </>
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  avatar={
                    <CountryLanguageFlag
                      countryCode={option.countryCode}
                      height={22}
                      isObvious={option.isObvious}
                      languageCode={option.languageCode}
                      style={{ marginLeft: 3 }}
                      width={22}
                    />
                  }
                  label={option.name}
                  variant="outlined"
                  {...getTagProps({ index })}
                />
              ))
            }
            value={selectedWorkspaces}
            onChange={handleWorkspacesChange}
          />
          <br />
          <br />
        </>
      )}
      <ParagraphText color="dark060" noPadding weight="strong">
        {t('components:dialog.invite-users.emails')}
      </ParagraphText>
      <InviteUsersInput
        alreadyInvitedUsers={alreadyInvitedUsers}
        availableUsers={availableUsers}
        canBeInvited={canBeInvited}
        className="dialog-invite-users"
        emailDomain={emailDomain}
        emails={emails}
        fullWidth
        handleChange={handleChange}
        handleInputChange={handleInputChange}
        inputValue={currentInputValue}
        isDisabled={inviting}
        onInputBlur={onInputBlur}
      />
      <div className="dialog-invite-users-message">
        {t('components:dialog.invite-users.info-email-users', { accessName })}
      </div>
      <ErrorAvailableSeatsLeftMessage
        organizationId={organizationId}
        seatsWillBeConsumed={seatsWillBeConsumed()}
      />
    </DialogModal>
  );
}
