import { useTranslation } from 'react-i18next';

import FlatLoader, { LoaderWrapper } from '@/components/Loader/FlatLoader';
import { Cell } from '@/components/Pages/ContentIdeas/common';
import { urlsClassificationTypeList } from '@/components/Pages/ContentIdeas/Listing/constant';
import Flex from '@/design-system/components/Flex';

function UrlClassification({ urlCategory, urlClassifiedAt, ...others }) {
  const { t } = useTranslation();
  const urlCategoryInfo = urlsClassificationTypeList.find(
    (urlClassificationType) => urlClassificationType.value === urlCategory
  );

  if (!urlClassifiedAt) {
    return (
      <LoaderWrapper height={15} width={75}>
        <FlatLoader />
      </LoaderWrapper>
    );
  }
  return (
    <Flex alignItems="center" {...others}>
      {urlCategoryInfo && urlCategoryInfo.icon}
      <Cell
        color="dark060"
        lineHeight={`15px`}
        margin="0 0 0 3px"
        noPadding
        size="xs"
        weight="medium"
      >
        {urlCategoryInfo && t(urlCategoryInfo.label)}
      </Cell>
    </Flex>
  );
}

export default UrlClassification;
