import React, { useMemo, useState } from 'react';

import FlatLoader, { LoaderWrapper } from '@/components/Loader/FlatLoader';
import MetricsSparkline from '@/containers/Chart/MetricsSparkline';
import { useDebounce } from '@/hooks/useDebounce';
import { useMetricsConfig } from '@/hooks/useMetricsConfig';
import PageMetricsService from '@/services/PageMetrics';
import { METRICS_CONFIG_PERIODICITY_DAILY } from '@/utils/metrics/constants';
import { getPeriodSizeInDays } from '@/utils/metrics/getPeriodSizeInDays';
import { getTrendCurrentDateRangeSQLFormat } from '@/utils/metrics/getTrendCurrentDateRangeSQLFormat';
import {
  getSimpleMovingAverageStep,
  getSimpleMovingAverageWindow,
  simpleMovingAverage,
} from '@/utils/simpleMovingAverage';

export function TrendCell({ metric, periodicity, period, pageId }) {
  const range = useMemo(
    () => getTrendCurrentDateRangeSQLFormat({ period, periodicity }),
    [period, periodicity]
  );
  const size = useMemo(() => getPeriodSizeInDays({ period }), [period]);
  const movingAverageWindow = useMemo(
    () => getSimpleMovingAverageWindow(periodicity),
    [periodicity]
  );
  const metricsConfig = useMetricsConfig({ isNew: true });
  const [state, setState] = useState({
    error: false,
    loading: true,
    metrics: [],
  });

  const getPageMetrics = async () => {
    const _pageMetrics = new PageMetricsService(pageId, range, METRICS_CONFIG_PERIODICITY_DAILY);

    try {
      const pageMetrics = await _pageMetrics.pageMetrics;
      const movingAverageStep = getSimpleMovingAverageStep(size);
      const { publicationsCount, ...otherMetrics } = metricsConfig;
      const pageMetricsMovingAverage = simpleMovingAverage({
        data: pageMetrics,
        excludedMetricsConfig: { publicationsCount },
        metricsConfig: otherMetrics,
        movingAverageStep,
        movingAverageWindow,
        periodicity,
      });

      setState({
        error: false,
        loading: false,
        metrics: pageMetricsMovingAverage,
      });
    } catch (error) {
      setState({
        error: true,
        loading: false,
        metrics: [],
      });
    }
  };

  useDebounce(() => getPageMetrics(), 100, [periodicity, period, pageId]); // pageId is useful when we change the order

  if (state.loading) {
    return (
      <LoaderWrapper height={15} width={40}>
        <FlatLoader />
      </LoaderWrapper>
    );
  }

  if (state.error) {
    return null;
  }

  return (
    <MetricsSparkline
      metrics={state.metrics}
      movingAverageWindow={movingAverageWindow}
      periodicity={periodicity}
      selectedMetric={metric}
    />
  );
}
