import styled from 'styled-components/macro';

const Button = styled.button`
  align-items: center;
  background: ${({ theme }) => theme.cssColors.dark010};
  border-radius: 3px;
  border: none;
  box-sizing: border-box;
  color: ${({ disabled, theme }) => (disabled ? theme.cssColors.dark020 : theme.cssColors.dark080)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: ${({ noFlex }) => (noFlex ? 'initial' : 'flex')};
  height: 35px;
  justify-content: center;
  letter-spacing: ${({ noLetterSpacing }) => (noLetterSpacing ? '0' : '0.75px')};
  max-height: 35px;
  max-width: ${({ maxWidth }) => maxWidth};
  min-width: ${({ minWidth }) => minWidth ?? 0};
  padding: ${({ padding, theme }) => padding ?? `0 ${theme.textCss.sizes.default}`};
  visibility: ${({ visibility }) => visibility};
  width: ${({ width }) => width};

  &::first-letter {
    text-transform: uppercase;
  }

  &,
  & > * {
    font-family: ${({ theme }) => theme.textCss.fonts.main};
    font-size: ${({ size, theme }) => theme.textCss.sizes[size ?? 'sm']};
    font-weight: ${({ theme, weight }) => theme.textCss.weights[weight ?? 'strong']};
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export default Button;
