const SC_PROTOCOL = 'sc-domain:';

function isSearchConsoleUrlValidWorkspaceUrl(url, workspaceUrl) {
  try {
    const parsedUrl = new URL(url);
    const parsedWorkspaceUrl = new URL(workspaceUrl);

    // When parsing an url starting with "sc-domain:", we have to use pathname to get the equivalent of the hostname
    return (
      (parsedUrl.protocol === SC_PROTOCOL &&
        (parsedUrl.pathname === parsedWorkspaceUrl.hostname ||
          parsedWorkspaceUrl.hostname.includes(parsedUrl.pathname))) ||
      (parsedUrl.protocol === parsedWorkspaceUrl.protocol &&
        parsedUrl.hostname === parsedWorkspaceUrl.hostname)
    );
  } catch {
    return false;
  }
}

export default isSearchConsoleUrlValidWorkspaceUrl;
