import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled(({ mode, width, height, ...props }) => <div {...props} />)`
  background: ${(props) => props.theme.colors.lightGreyBlue};
  color: ${(props) => props.theme.text.colors.black};
  border-radius: ${(props) => (props.mode === 'circ' ? '50%' : '3px')};
  border: 1px solid white;
  width: ${(props) => props.width + 'px'};
  height: ${(props) => props.height + 'px'};
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 0.6em;
  font-weight: 700;
  padding: 0.1em;
`;

function Language({ languageCode, mode, width, height, ...props }) {
  return (
    <Wrapper height={height} mode={mode} width={width} {...props}>
      {languageCode}
    </Wrapper>
  );
}

Language.propTypes = {
  height: PropTypes.number,
  languageCode: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(['circ', 'rect']),
  width: PropTypes.number,
};

Language.defaultProps = {
  height: 50,
  mode: 'circ',
  width: 50,
};

export default Language;
