import i18next from 'i18next';
import React from 'react';
import { UserIcon } from 'semji-core/icons/UserIcon';

import {
  ENUM_FILTER_TYPE,
  MultiFilterProps,
  MultipleFilterType,
} from '@/components/DynamicFilters';
import { PERSONA_FILTER_QUERY_PARAM } from '@/containers/Competitors/utils/constants';
import { CompetitorPersona } from '@/types/competitors';

export function getPersonaFilter({
  updateFilter,
  filter,
  personas,
}: MultiFilterProps & { personas: CompetitorPersona[] }): MultipleFilterType {
  return {
    icon: <UserIcon borderless className="w-4 h-4" />,
    key: PERSONA_FILTER_QUERY_PARAM,
    name: i18next.t('competitors:filters.persona'),
    onValidate: (value) => updateFilter(PERSONA_FILTER_QUERY_PARAM, value),
    options: personas.map((persona) => ({
      label: persona.name,
      selected: filter.includes(persona.id),
      value: persona.id,
    })),
    type: ENUM_FILTER_TYPE.MULTI_SELECT,
  };
}
