import { useMutation } from '@tanstack/react-query';

import apiClient from '@/apis/semji/apiClient';

import { usePutContentVersionProps } from './usePutContentVersion.types';

export default function usePutContentVersion({
  contentId,
  versionId,
  ...props
}: usePutContentVersionProps) {
  const { put } = apiClient;

  return useMutation({
    mutationFn: async ({ version = {} }) => {
      const result = await put(
        `contents/${contentId || version.contentId}/versions/${versionId || version.id}`,
        {
          ...version,
        }
      );

      return result;
    },
    ...props,
  });
}
