import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';

import ControlledAccordion from '@/components/Accordion/ControlledAccordion';
import WelcomeIllustration from '@/components/DynamicIllustration/Welcome';
import {
  OnboardingBodyWrapper,
  OnboardingFooterWrapper,
  OnboardingTitleWrapper,
  OnboardingWrapper,
  ONLY_LOGO_WHITE_BACKGROUND_AND_ONBOARDING,
} from '@/components/Layout/Layout';
import { OneActionStepperFooter } from '@/components/Stepper/StepperFooter';
import { SecondaryTitleOne, TitleThree } from '@/components/Text/Title';
import LayoutWrapper from '@/containers/Layout/Layout';
import useBreakpoints from '@/hooks/useBreakpoints';
import { useCookies } from '@/hooks/useCookies';
import OrganizationService from '@/services/Organization';
import UserService from '@/services/User';
import { fetchAuthenticatedUser } from '@/store/actions/user';
import {
  ABOUT_WORK_STEP,
  ONBOARDING_CONTENT_PRODUCTION_INTERVALS_PER_MONTH,
  ONBOARDING_USERS_JOB_TITLES,
} from '@/utils/constants';
import { getDefaultOrganizationId, getOnboardingSteps } from '@/utils/helper';

// https://stackoverflow.com/questions/14894899/what-is-the-minimum-length-of-a-valid-international-phone-number
// We can use a google service later to validation and parse phone numbers
// https://github.com/google/libphonenumber
const MINIMUM_PHONE_NUMBER_LENGTH = 4;
const MINIMUM_PHONE_NUMBER_LENGTH_WITH_COUNTRY_CODE = MINIMUM_PHONE_NUMBER_LENGTH + 3;

const StyledWelcomeIllustration = styled(WelcomeIllustration)`
  position: fixed;
  bottom: 0;
  right: 0;
  max-width: 40%;
  // hide illustration for small screens
  ${({ theme }) => theme.mediaQueries.tablet} {
    display: none;
  }
  ${({ theme }) => theme.mediaQueries.phone} {
    display: none;
  }
`;

function CreateOrganization() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [organizationData, setOrganizationData] = useState({});
  const [creationState, setCreationState] = useState({
    error: null,
    pending: false,
  });
  const [currentAccordionId, setCurrentAccordionId] = useState('name'); // 'name' is the first element of the list returned by generateAccordionsList
  const [nextIsDisabled, setNextIsDisabled] = useState(true);

  const organizationAccesses = useSelector((state) => state.user.organizationAccesses);
  const defaultOrganizationId = useSelector((state) => state.defaultOrganizationId);
  const currentUserId = useSelector((state) => state.user.id);
  const selectedPlanId = useSelector((state) => state.signup.selectedPlanId);
  const defaultId = getDefaultOrganizationId(defaultOrganizationId, organizationAccesses);
  const { isMobile } = useBreakpoints();
  const referralCode = useCookies('_fprom_tid');

  useEffect(() => {
    const accordionList = generateAccordionsList();
    const currentAccordionIndex = accordionList.findIndex(
      (accordion) => accordion.id === currentAccordionId
    );
    const isLastOfArray = currentAccordionIndex === accordionList.length - 1;

    if (accordionList[currentAccordionIndex]?.checked && !isLastOfArray) {
      setNextIsDisabled(false);
    } else {
      setNextIsDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationData, currentAccordionId]);

  const createOrganization = async () => {
    try {
      setCreationState({
        error: null,
        pending: true,
      });
      const _OrganizationService = new OrganizationService();
      const _userService = new UserService();
      const payload = {
        monthlyProducedContents: organizationData.monthly_produced_contents,
        name: organizationData.name,
        referralCode,
      };

      if (selectedPlanId) {
        payload.plan = `/plans/${selectedPlanId}`;
      }

      // we need to wait for the user to have the new values before creating the organization
      await _userService.setUserInfo(currentUserId, {
        jobTitle: organizationData.job_title,
        phoneNumber: organizationData.company_phone_number.value,
      });
      const organization = await _OrganizationService.createOrganization(currentUserId, payload);
      await dispatch(fetchAuthenticatedUser());
      navigate(`/o/${organization.id}/create-workspace`);
    } catch (e) {
      setCreationState({
        error: t('create-organization:errors.something-went-wrong'),
        pending: false,
      });
    }
  };

  const onKeyDownPress = (event) => {
    const isNextDisabled = getOrgaValue(true, nextIsDisabled, isCreateOrganizationDisable());

    if (event.keyCode === 13 && !isNextDisabled) {
      nextForm();
    }
  };

  const nextForm = () => {
    const accordionsList = generateAccordionsList();
    const currentAccordionIndex = accordionsList.findIndex(
      (accordion) => accordion.id === currentAccordionId
    );

    if (currentAccordionIndex !== accordionsList.length - 1) {
      setCurrentAccordionId(accordionsList[currentAccordionIndex + 1].id);
    }
  };

  const onChangeOrganizationDataSelect = (id, value) => {
    onChangeOrganizationData(id, value);
    nextForm();
  };

  const isCreateOrganizationDisable = () => {
    return (
      generateAccordionsList().some((accordion) => !accordion?.checked) || creationState.pending
    );
  };

  const onChangeOrganizationData = (id, value) => {
    setOrganizationData({
      ...organizationData,
      [id]: value,
    });
  };

  const isLastToCheck = () => {
    const accordionsList = generateAccordionsList();
    const currentAccordion = accordionsList.find(
      (accordion) => accordion.id === currentAccordionId
    );
    const numberOfChecked = accordionsList.reduce(
      (acc, currentValue) => (currentValue?.checked ? (acc += 1) : acc),
      0
    );

    return numberOfChecked === accordionsList.length - 1 && !currentAccordion?.checked;
  };

  const getOrgaValue = (pendingValue, disabledOrgaValue, enabledOrgaValue) => {
    if (creationState.pending) {
      return pendingValue;
    }
    if (isCreateOrganizationDisable() && !isLastToCheck()) {
      return disabledOrgaValue;
    }
    return enabledOrgaValue;
  };

  const generateAccordionsList = () => {
    return [
      {
        checked: organizationData.name?.length >= 3,
        dataValue: organizationData.name,
        id: 'name',
        onDataChange: onChangeOrganizationData,
        placeholder: t('create-organization:accordions.name-placeholder'),
        summary: t('create-organization:accordions.name-summary'),
        type: 'organization-input',
      },
      {
        checked: ONBOARDING_CONTENT_PRODUCTION_INTERVALS_PER_MONTH.includes(
          organizationData.monthly_produced_contents
        ),
        dataValue: organizationData.monthly_produced_contents,
        id: 'monthly_produced_contents',
        onDataChange: onChangeOrganizationDataSelect,
        summary: t('create-organization:accordions.monthly-content-summary'),
        type: 'select',
        values: ONBOARDING_CONTENT_PRODUCTION_INTERVALS_PER_MONTH,
      },
      {
        checked: ONBOARDING_USERS_JOB_TITLES.includes(organizationData.job_title),
        dataValue: organizationData.job_title,
        id: 'job_title',
        onDataChange: onChangeOrganizationDataSelect,
        summary: t('create-organization:accordions.job-summary'),
        type: 'select',
        values: ONBOARDING_USERS_JOB_TITLES,
      },
      {
        checked:
          organizationData.company_phone_number?.value.length >=
          MINIMUM_PHONE_NUMBER_LENGTH_WITH_COUNTRY_CODE,
        dataValue: organizationData.company_phone_number,
        explanationText: t('create-organization:accordions.phone-explanation-text'),
        id: 'company_phone_number',
        onDataChange: onChangeOrganizationData,
        placeholder: t('create-organization:accordions.phone-placeholder'),
        summary: t('create-organization:accordions.phone-summary'),
        type: 'phone',
      },
    ];
  };

  if (defaultId) {
    // redirect to default organization if the user has already an organization access
    return <Navigate to={`/o/${defaultId}`} />;
  }

  return (
    <LayoutWrapper
      menu={ONLY_LOGO_WHITE_BACKGROUND_AND_ONBOARDING}
      steps={isMobile ? [] : getOnboardingSteps(ABOUT_WORK_STEP)}
    >
      <Helmet>
        <title>{t('create-organization:title')}</title>
      </Helmet>
      <OnboardingWrapper $isMobileVersion={isMobile} maxWidth="500px">
        <OnboardingTitleWrapper>
          <SecondaryTitleOne noMargin weight="medium">
            {t('create-organization:welcome-to-semji')}
          </SecondaryTitleOne>
          <br />
          <TitleThree color="dark060" noMargin>
            {t('create-organization:tell-us-more')}
          </TitleThree>
        </OnboardingTitleWrapper>
        <OnboardingBodyWrapper>
          <ControlledAccordion
            accordions={generateAccordionsList()}
            expanded={currentAccordionId}
            setExpanded={(accordion) => setCurrentAccordionId(accordion)}
            onKeyDownPress={onKeyDownPress}
          />
        </OnboardingBodyWrapper>
        <OnboardingFooterWrapper width={isMobile ? 'unset' : '500px'}>
          <OneActionStepperFooter
            handleNext={getOrgaValue(null, nextForm, createOrganization)}
            info={creationState.error}
            infoOnError={!!creationState.error}
            isNextDisabled={getOrgaValue(true, nextIsDisabled, isCreateOrganizationDisable())}
            nextLabel={getOrgaValue(
              t('create-organization:actions.creating'),
              t('create-organization:actions.next'),
              t('create-organization:actions.create-organization')
            )}
          />
        </OnboardingFooterWrapper>
        <StyledWelcomeIllustration />
      </OnboardingWrapper>
    </LayoutWrapper>
  );
}

export default CreateOrganization;
