import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { PureAvatar } from 'semji-core/components/Avatar';
import { WrapperModal } from 'semji-core/components/WrapperModal';
import styled from 'styled-components/macro';

import UnlockImage from '@/assets/images/unlock-image.svg';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import WorkspaceAccessesService from '@/services/WorkspaceAccesses';
import { API_WORKSPACE_ROLE_OWNER } from '@/utils/can/constants';

const BoxList = styled.div`
  padding-top: 1rem;
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 1.5rem;
`;

const Item = styled.a`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${(props) => props.theme.cssColors.dark100};
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: ${(props) => props.theme.cssColors.secondary};
  }
`;
const MoreButton = styled.span`
  display: flex;
  justify-content: center;
  cursor: pointer;
  padding: 20px;
  color: ${(props) => props.theme.cssColors.dark060};
`;

export function AiContentPermissionModal({ showPermissionModal, setShowPermissionModal }) {
  const { t } = useTranslation();

  const { organizationId, workspaceId } = useParams();
  const [accesses, setAccesses] = useState([]);

  useEffect(() => {
    const fetchAccesses = async () => {
      const _workspaceAccessesService = new WorkspaceAccessesService(null, workspaceId);
      const [workspaceAccesses] = await Promise.all([_workspaceAccessesService.workspaceAccesses]);

      setAccesses(workspaceAccesses);
    };

    fetchAccesses();
  }, [workspaceId]);

  const currentOrganization = useSelector((state) => state.organizations.byId[organizationId]);
  const organizationName = currentOrganization?.name;
  const currentUser = useSelector((state) => state.user);

  const apiRootUrl = import.meta.env.VITE_REACT_APP_SEMJI_API_ROOT_URL;
  const title = t('components:settings.users-settings.ai-suite-no-permission.title');
  const description = t('components:settings.users-settings.ai-suite-no-permission.description');

  const [displayAllUsers, setDisplayAllUsers] = useState(false);
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    const owners = filterWorkspaceOwners(accesses);
    if (displayAllUsers) {
      setUserList(owners);
    } else {
      setUserList(owners.slice(0, 3));
    }
  }, [accesses, displayAllUsers]);

  function filterWorkspaceOwners(accesses) {
    return accesses.filter((item) => item.roles.includes(API_WORKSPACE_ROLE_OWNER)) ?? [];
  }

  const hasMoreThreeOwners = filterWorkspaceOwners(accesses).length > 3;

  function onClose() {
    setShowPermissionModal(false);
    setDisplayAllUsers(false);
  }

  return (
    <WrapperModal
      buttonLabel={t('components:dialog.dialog-information-template.button-text')}
      description={description}
      illustration={<img alt="" src={UnlockImage} />}
      isOpen={showPermissionModal}
      title={title}
      onClose={onClose}
    >
      <BoxList>
        {userList?.map(({ user }) => {
          const adminName = user.firstName && user.lastName && user.firstName + ' ' + user.lastName;
          const userName = currentUser.firstName + ' ' + currentUser.lastName;
          const emailSubject = t(
            'workspace:default-workspace.no-permissions-access-workspaces.email.subject',
            { user: userName }
          );
          const emailBody = t(
            'workspace:default-workspace.no-permissions-access-workspaces.email.body',
            { admin: adminName || '', organizationName, user: userName }
          );

          return (
            user.lastLoginAt && (
              <TooltipComponent key={user.id} title={user.email}>
                <Item
                  href={`mailto:${user.email}?subject=${emailSubject}&body=${encodeURI(emailBody)}`}
                >
                  <PureAvatar
                    apiRootUrl={apiRootUrl}
                    email={user.email ?? ''}
                    firstName={user.firstName || ''}
                    id={user.id}
                    lastName={user.lastName || ''}
                    profileImageHash={user.profileImageHash || null}
                    translations={{ unassigned: t('common:user-picker.unassigned') }}
                  />
                  {adminName || user.email}
                </Item>
              </TooltipComponent>
            )
          );
        })}
      </BoxList>
      {!displayAllUsers && hasMoreThreeOwners && (
        <MoreButton onClick={() => setDisplayAllUsers(true)}>
          {t('components:settings.users-settings.show-more')}
        </MoreButton>
      )}
    </WrapperModal>
  );
}
