import styled from 'styled-components/macro';

import { LinkLikeButton as LinkLikeBtn } from '@/components/Button/Button';

const LinkLikeButton = styled(LinkLikeBtn)`
  display: block;
  text-decoration: none;
  margin: ${({ margin }) => margin || '10px 0'};
  padding: ${({ padding }) => padding || '0'};
  font-weight: ${({ theme, weight }) => theme.textCss.weights[weight || 'medium']};
`;

export default LinkLikeButton;
