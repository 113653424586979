import styled from 'styled-components/macro';

import DeleteIcon from '@/components/icons/DeleteIcon';
import TextButton from '@/design-system/components/Button/TextButton';
import Flex from '@/design-system/components/Flex';

export const Wrapper = styled(Flex)`
  right: 0;
  top: 0;
  opacity: 0.93;
`;

export const Title = styled.span`
  line-height: ${({ theme }) => theme.textCss.lineHeight};
  font-size: ${({ theme }) => theme.textCss.sizes.sm};
  font-weight: ${({ theme }) => theme.textCss.weights.strong};
`;

export const CancelButton = styled(TextButton)`
  font-size: 13px;
  line-height: ${({ theme }) => theme.textCss.lineHeight};
  font-weight: ${({ theme }) => theme.textCss.weights.medium};
  color: ${({ theme }) => theme.cssColors.dark060};
  padding: 0;
  max-height: 18px;
`;

export const ConfirmButton = styled(TextButton)`
  font-size: 13px;
  line-height: ${({ theme }) => theme.textCss.lineHeight};
  font-weight: ${({ theme }) => theme.textCss.weights.medium};
  gap: 4px;
  padding: 0;
  max-height: 18px;
`;

export const StyledDeleteIcon = styled(DeleteIcon)`
  && {
    font-size: 12px;
  }
  color: ${({ theme }) => theme.cssColors.primary};
`;
