import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getRoundedNumberMetricObject } from 'semji-core/utils/numbers';
import styled from 'styled-components/macro';

import SearchIcon from '@/components/icons/SearchIcon';
import { Instruction } from '@/components/Optimization/Instruction';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import { IndicatorTextDark } from '@/containers/Content/SidePanel/Components/Optimization/Section/SearchIntents/SearchIntent';
import {
  disableRecommendationOptimization,
  enableRecommendationOptimization,
} from '@/store/actionsCreator/report';

export const SourceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 20px;
  color: #39aaf3;
  background-color: #cceafc;
  font-weight: 500;
  font-size: 0.8em;
  border-radius: 3px;
`;
const ScorerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 20px;
`;
const StyledTooltipComponent = styled(TooltipComponent)`
  font-size: 0.8em;
`;

function Question({
  focusTopKeywordId,
  id,
  isReadOnly,
  mainId,
  onAddQuestion = () => {},
  onToggleQuestion = () => {},
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userLanguageCode = useSelector((state) => state.user?.languageCode);
  const html = useSelector((state) => state.content.html);
  const question = useSelector((state) => state.report.recommendations[id]);
  const score = useSelector((state) => state.report.recommendations[id].score);
  const title = useSelector((state) => state.content.title);

  const { value, suffix } = getRoundedNumberMetricObject({
    locale: userLanguageCode,
    number: question.data.searchVolume,
  });
  const searchVolume = question.data.searchVolume > 0 ? value + suffix : '0-10';

  function toggleQuestion() {
    if (!question.disabled) {
      dispatch(disableRecommendationOptimization(id, mainId, focusTopKeywordId, html, title));
    } else {
      dispatch(enableRecommendationOptimization(id, mainId, focusTopKeywordId, html, title));
    }

    onToggleQuestion(id, !question.disabled);
  }

  function handleAddQuestion() {
    onAddQuestion(question.data.title);
  }

  return (
    <Instruction
      completed={1 === score}
      defaultLabelFontWeight="normal"
      disabled={question.disabled}
      isReadOnly={isReadOnly}
      label={question.data.label}
      scorer={
        <StyledTooltipComponent
          title={t(
            question.data.isPAA
              ? 'content:side-panel-components.optimization.section.questions.instruction-tooltip-paa'
              : 'content:side-panel-components.optimization.section.questions.instruction-tooltip-search-volume'
          )}
        >
          {question.data.isPAA ? (
            <SourceWrapper>
              {t(
                'content:side-panel-components.optimization.section.questions.instruction-label-paa'
              )}
            </SourceWrapper>
          ) : (
            <ScorerWrapper>
              <SearchIcon opacity={0.5} />
              <IndicatorTextDark>{searchVolume}</IndicatorTextDark>
            </ScorerWrapper>
          )}
        </StyledTooltipComponent>
      }
      title={question.data.title}
      onAdd={handleAddQuestion}
      onToggle={toggleQuestion}
    />
  );
}

export default Question;
