import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import SortDirection from 'react-virtualized/dist/es/Table/SortDirection';
import { Checkbox } from 'semji-core/components/Input/Checkbox';
import { getDecimalPlaces } from 'semji-core/utils/numbers';
import styled from 'styled-components/macro';

import { pageRefreshDataAsync } from '@/apis/semji/api';
import {
  FlexCenteredContainer,
  FlexGrowMaxWidthContainer,
  TableFlexStyledCard,
} from '@/components/_common';
import { DarkText, VerticalSeparator } from '@/components/ActionsBar/BulkActionsBar';
import AddToPlanningDialog, { StyledBlockText } from '@/components/Dialog/AddToPlanningDialog';
import BulkAnalysisDialog from '@/components/Dialog/BulkAnalysisDialog';
import CreditLimitReachedDialog from '@/components/Dialog/CreditLimitReachedDialog';
import ExportDialog from '@/components/Dialog/ExportDialog';
import NoPages from '@/components/EmptyState/NoPages';
import NoServiceIntegration, {
  NO_ANALYTICS,
  NO_GOALS,
  NO_GOOGLE_ANALYTICS,
  NO_SEARCH_CONSOLE,
  PRIORITIZE_TEXT_VERSION,
} from '@/components/EmptyState/NoServiceIntegration';
import { PAGES_FK } from '@/components/FocusKeyword/constants';
import FocusTopKeywordAssociation from '@/components/FocusTopKeyword/FocusTopKeywordAssociation';
import Analyze2Icon from '@/components/icons/Analyze2Icon';
import DeleteIcon from '@/components/icons/DeleteIcon';
import FileExportOutline from '@/components/icons/FileExportOutline';
import PlusIcon from '@/components/icons/PlusIcon';
import ReloadIcon from '@/components/icons/ReloadIcon';
import WarningTriangleIcon from '@/components/icons/WarningTriangleIcon';
import FlatLoader, { CircularLoaderWrapper, LoaderWrapper } from '@/components/Loader/FlatLoader';
import { TertiaryLink } from '@/components/Navigation/Link';
import AddToPlanningCell from '@/components/Planning/AddToPlanningCell';
import PlannedCell from '@/components/Planning/PlannedCell';
import DotsMenu from '@/components/Table/Cell/ActionsCell';
import ContentScoreCell from '@/components/Table/Cell/ContentScoreCell';
import FocusKeywordSelectorCell from '@/components/Table/Cell/FocusKeywordSelectorCell';
import NotAcknowledgedCell from '@/components/Table/Cell/NotAcknowledgedCell';
import NumberCell from '@/components/Table/Cell/NumberCell';
import PictureCell from '@/components/Table/Cell/PictureCell';
import PotentialScoreCell from '@/components/Table/Cell/PotentialScoreCell';
import StartAnalysisCell from '@/components/Table/Cell/StartAnalysisCell';
import TitleCell from '@/components/Table/Cell/TitleCell';
import Table from '@/components/Table/Table';
import BulkActionButton from '@/components/TableBulkActions/BulkActionButton';
import BulkActionIcon from '@/components/TableBulkActions/BulkActionIcon';
import TableActionBar from '@/components/TableBulkActions/TableActionBar';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import { SmallFlexTooltipComponent } from '@/components/Tooltip/Tooltip';
import { FILTERS_DEPENDENCY } from '@/containers/Listing/PagesList';
import { useMetricsConfig } from '@/hooks/useMetricsConfig';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import { useViewsGoalsConfig } from '@/hooks/useViewsGoalsConfig';
import { showSuccessSnackbar } from '@/store/actions/ui';
import { selectHasAnalytics } from '@/store/selectors/selectHasAnalytics';
import { selectHasSearchConsole } from '@/store/selectors/selectHasSearchConsole';
import selectPageExportSyncLimitation from '@/store/selectors/selectPageExportSyncLimitation';
import { selectRemainingAnalysisUnit } from '@/store/selectors/selectRemainingAnalysisUnit';
import { STATUS_PENDING, STATUS_QUEUED } from '@/utils/analysis';
import {
  ANALYSIS_CREDITS_TYPE,
  ESTIMATED_AMOUNT_OF_ANALYSIS_UNIT_TO_START_ANALYSIS,
  FROM_ORIGIN,
} from '@/utils/constants';
import { DEFAULT_PRIORITY_SCORE } from '@/utils/filter/constants';
import getCreditsCost from '@/utils/getCreditsCost';
import { ANALYTICS_DEPENDENCY, SEARCH_CONSOLE_DEPENDENCY } from '@/utils/metrics/constants';
import { METRIC_TYPE_NUMBER, METRIC_TYPE_PERCENT } from '@/utils/metrics/constants';
import {
  getExtraKeyFromAlgorithm,
  getExtraRateKeyFromAlgorithm,
  getLabelFromAlgorithm,
} from '@/utils/page';

const LOADING_ROWS_PLACEHOLDER = Array(5).fill({
  extra: {},
  focusKeyword: {},
  loading: true,
  optimizedImages: {},
  workspace: {},
});
const StyledLoaderWrapper = styled(LoaderWrapper)`
  padding-right: 1rem;
  width: 40px;
  height: 40px;
`;
const NoSearchConsoleApiAvailable = styled(TooltipComponent)`
  font-size: 1rem;
`;

function List({
  pages,
  contentsIds,
  pagesFiltered,
  selectedListIds,
  openBulkDeleteDialog,
  deleteRow,
  workspaceCountryName,
  setContentsIds,
  triggerUpdateAnalysis,
  removeFromPlanning,
  addToPlanning,
  triggerBulkUpdateAnalysis,
  selectedListWithFocusKeyword,
  loading,
  selectedListNotPlanned,
  triggerBulkAddToPlanning,
  triggerBulkExportPages,
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const hasSearchConsole = useSelector(selectHasSearchConsole);
  const hasAnalytics = useSelector(selectHasAnalytics);
  const metrics = useMetricsConfig();
  const remainingAnalysisUnit = useSelector(selectRemainingAnalysisUnit);
  const { organizationId, workspaceId } = useParams();
  const [insufficientCreditsModal, setInsufficientCreditsModal] = useState({
    show: false,
  });
  const [bulkAnalysisModal, setBulkAnalysisModal] = useState({ loading: false, show: false });
  const [bulkAddToPlanningModal, setBulkAddToPlanningModal] = useState({
    error: false,
    loading: false,
    show: false,
  });
  const [bulkExportPagesModal, setBulkExportPagesModal] = useState({
    error: false,
    loading: false,
    show: false,
  });

  const pageExportSyncLimitation = useSelector(selectPageExportSyncLimitation);

  const hasSelectedListSomeKeyword = selectedListWithFocusKeyword.length > 0;
  const isPageExportAsync = selectedListIds.length >= pageExportSyncLimitation;

  const { isFeatureEnabled: hasUnlimitedAnalysisCredits } = useOrganizationFeatureSet(
    'credits:analysis:has-unlimited-amount'
  );
  const viewsGoals = useViewsGoalsConfig();
  const getPriorityScoreAlgorithmFromPath = () => {
    const routerSearchParams = new URLSearchParams(location.search);
    const priorityScore = routerSearchParams.getAll('priorityScore');

    return priorityScore[0];
  };
  const priorityScore = getPriorityScoreAlgorithmFromPath();
  const priorityScoreToDisplay = metrics[priorityScore]?.name?.toLowerCase() || '';
  const currentOpportunitiesFilter = FILTERS_DEPENDENCY.find(
    (filter) => filter?.attribute === priorityScore
  );
  const currentViewsGoal = viewsGoals.find((goal) => goal.attribute === priorityScore);
  const isAddToPlanningButtonDisabled =
    !selectedListIds.some((id) => pages.find((page) => page.id === id && !page?.draftId)) &&
    !!selectedListIds.length;

  const getNoServiceIntegrationVersion = () => {
    if (currentOpportunitiesFilter?.dependency === SEARCH_CONSOLE_DEPENDENCY && !hasSearchConsole) {
      return NO_SEARCH_CONSOLE;
    }
    if (
      currentOpportunitiesFilter?.dependency === ANALYTICS_DEPENDENCY &&
      !hasAnalytics &&
      currentViewsGoal
    ) {
      return NO_GOOGLE_ANALYTICS;
    }
    if (currentViewsGoal && !currentViewsGoal?.isConfigured && hasAnalytics) {
      return NO_GOALS;
    }
    if (currentOpportunitiesFilter?.dependency === ANALYTICS_DEPENDENCY && !hasAnalytics) {
      return NO_ANALYTICS;
    }
    return null;
  };

  const noServiceIntegrationVersion = getNoServiceIntegrationVersion();

  const handleSelectChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setContentsIds([...(value ? [...contentsIds, name] : contentsIds.filter((id) => id !== name))]);
  };

  const handleSelectAllChange = (event, pagesList) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    setContentsIds([
      ...(value
        ? [
            ...contentsIds,
            ...pagesList
              .map((content) => content.id)
              .filter((content) => !contentsIds.find((id) => content === id)), // this filter delete double contentsIds
          ]
        : contentsIds.filter((id) => !pagesList.find((page) => page.id === id))),
    ]);
  };

  const onFKUpdate = useCallback((focusKeyword) => {
    if (
      hasUnlimitedAnalysisCredits ||
      remainingAnalysisUnit >= ESTIMATED_AMOUNT_OF_ANALYSIS_UNIT_TO_START_ANALYSIS
    ) {
      triggerUpdateAnalysis(focusKeyword);
    } else {
      displayInsufficientCreditsModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(() => {
    const algorithm = priorityScore;
    const extraColumns = [];
    const { monthlyLabel, potentialLabel } = getLabelFromAlgorithm(
      algorithm || DEFAULT_PRIORITY_SCORE,
      metrics
    );

    const extraKey = getExtraKeyFromAlgorithm(algorithm || DEFAULT_PRIORITY_SCORE);
    const potentialColumn = algorithm
      ? [
          {
            align: 'center',
            cellDataGetter: ({ rowData }) => ({
              loading:
                (rowData.focusTopKeyword &&
                  [STATUS_PENDING, STATUS_QUEUED].includes(
                    rowData.focusTopKeyword.analysisStatus
                  ) &&
                  rowData.potentialScore === null) ||
                rowData.loading,
              potentialScore: rowData.potentialScore,
            }),
            cellRenderer: ({ cellData }) => {
              if (cellData.loading) {
                return (
                  <LoaderWrapper height={15} width={80}>
                    <FlatLoader />
                  </LoaderWrapper>
                );
              }
              return <PotentialScoreCell potentialScore={cellData.potentialScore} />;
            },
            dataKey: 'potentialScore',
            label: (
              <TooltipComponent
                description={t('listing:pages-list.list.tooltip.potential-label', {
                  metricName: potentialLabel,
                })}
                placement="top"
                title={t('listing:pages-list.list.potential-label', { metricName: potentialLabel })}
              >
                {t('listing:pages-list.list.potential-label', { metricName: potentialLabel })}
              </TooltipComponent>
            ),
            sortFunction: (value1, value2, sortDirection) =>
              Table.naturalSort(value1.potentialScore, value2.potentialScore, sortDirection),
            sortable: true,
            width: 120,
          },
        ]
      : [];

    if (
      [
        'conversions',
        'goal1Completions',
        'goal2Completions',
        'goal3Completions',
        'transactions',
      ].includes(algorithm)
    ) {
      const extraRateKey = getExtraRateKeyFromAlgorithm(algorithm);

      extraColumns.push({
        align: 'center',
        cellDataGetter: ({ rowData }) => ({
          loading:
            (!rowData.extra.aggregatedSearchConsoleMetricsRetrievedAt &&
              rowData.extra[extraRateKey] === null) ||
            rowData.loading,
          monthlyAlgorithmDataRate: rowData.extra[extraRateKey],
        }),
        cellRenderer: ({ cellData }) => {
          if (!hasAnalytics) {
            return (
              <NoSearchConsoleApiAvailable title={t('listing:pages-list.connect-web-analytics')}>
                <TertiaryLink to={`/o/${organizationId}/w/${workspaceId}/settings/integrations`}>
                  <WarningTriangleIcon />
                </TertiaryLink>
              </NoSearchConsoleApiAvailable>
            );
          }
          if (cellData.loading) {
            return (
              <LoaderWrapper height={15} width={70}>
                <FlatLoader />
              </LoaderWrapper>
            );
          }
          if (cellData.monthlyAlgorithmDataRate) {
            const decimalPlaces = getDecimalPlaces(
              cellData.monthlyAlgorithmDataRate * 100,
              METRIC_TYPE_PERCENT
            );

            return (
              <NumberCell
                decimalPlaces={decimalPlaces}
                number={
                  cellData.monthlyAlgorithmDataRate ? cellData.monthlyAlgorithmDataRate * 100 : null
                }
                suffix="%"
              />
            );
          }

          return <NotAcknowledgedCell />;
        },
        dataKey: 'monthlyAlgorithmDataRate',
        label: (
          <TooltipComponent
            description={t(
              `listing:pages-list.list.tooltip.rate.${algorithm || DEFAULT_PRIORITY_SCORE}`,
              {
                metricName: potentialLabel,
              }
            )}
            placement="top"
            title={t('listing:pages-list.list.monthly-rate', { metricName: monthlyLabel })}
          >
            {t('listing:pages-list.list.monthly-rate', { metricName: monthlyLabel })}
          </TooltipComponent>
        ),
        sortFunction: (value1, value2, sortDirection) =>
          Table.naturalSort(
            value1.monthlyAlgorithmDataRate,
            value2.monthlyAlgorithmDataRate,
            sortDirection
          ),
        sortable: true,
        width: 100,
      });
    }

    return [
      {
        align: 'center',
        cellDataGetter: ({ rowData }) => ({
          id: rowData.id,
          loading: rowData.loading,
        }),
        cellRenderer: ({ cellData }) =>
          cellData.loading ? (
            <LoaderWrapper height={20} width={20}>
              <FlatLoader />
            </LoaderWrapper>
          ) : (
            <Checkbox
              checked={contentsIds.includes(cellData.id)}
              name={cellData.id}
              onChange={handleSelectChange}
            />
          ),
        dataKey: 'select',
        headerCellRenderer: () => (
          <FlexCenteredContainer>
            <Checkbox
              checked={!loading && pagesFiltered.every((page) => contentsIds.includes(page.id))}
              onChange={(event) => handleSelectAllChange(event, pagesFiltered)}
            />
          </FlexCenteredContainer>
        ),
        label: 'Select',
        sortable: false,
        width: 50,
      },
      {
        cellDataGetter: ({ rowData }) => ({
          isRedirectionDetected: !!rowData.redirectionUrl,
          lastStatusCode: rowData.lastStatusCode,
          loading: rowData.loading,
          loadingTitle: !rowData.titleRetrievedAt && !rowData.title,
          pageId: rowData.id,
          pictureUrl: rowData.optimizedImages?.small,
          title: rowData.title,
          updateInfos: {
            lastPublishedAt: rowData.extra?.lastPublishedAt,
            publicationsCount: rowData.extra?.publicationsCount,
          },
          url: rowData.url,
          workspaceId: workspaceId,
        }),
        cellRenderer: ({ cellData }) => {
          return (
            <FlexGrowMaxWidthContainer maxWidth="100%" style={{ alignItems: 'center' }}>
              {cellData.loading ? (
                <StyledLoaderWrapper>
                  <FlatLoader />
                </StyledLoaderWrapper>
              ) : (
                <PictureCell pictureUrl={cellData.pictureUrl} />
              )}
              <TitleCell
                isRedirectionDetected={cellData.isRedirectionDetected}
                lastStatusCode={cellData.lastStatusCode}
                loading={cellData.loadingTitle || cellData.loading}
                organizationId={organizationId}
                pageId={cellData.pageId}
                title={cellData.title}
                type={FROM_ORIGIN.ONLINE}
                updateInfos={cellData.updateInfos}
                url={cellData.url}
                workspaceId={cellData.workspaceId}
              />
            </FlexGrowMaxWidthContainer>
          );
        },
        customizableHeader: true,
        dataKey: 'title',
        flexGrow: 1,
        label: (
          <TooltipComponent
            description={t('listing:pages-list.list.tooltip.page')}
            placement="top"
            title={t('listing:pages-list.list.page')}
          >
            {t('listing:pages-list.list.page')}
          </TooltipComponent>
        ),
        sortFunction: (value1, value2, sortDirection) =>
          Table.stringSort(value1.title, value2.title, sortDirection),
        sortable: true,
        width: 150,
      },
      {
        align: 'center',
        cellDataGetter: ({ rowData }) => ({
          loading:
            (!rowData.extra.aggregatedSearchConsoleMetricsRetrievedAt &&
              rowData.extra[extraKey] === null) ||
            rowData.loading,
          monthlyAlgorithmData: rowData.extra[extraKey],
        }),
        cellRenderer: ({ cellData }) => {
          if (!hasSearchConsole && extraKey === 'monthlyClicks') {
            return (
              <NoSearchConsoleApiAvailable
                title={t('listing:pages-list.connect-search-console-data')}
              >
                <TertiaryLink to={`/o/${organizationId}/w/${workspaceId}/settings/integrations`}>
                  <WarningTriangleIcon />
                </TertiaryLink>
              </NoSearchConsoleApiAvailable>
            );
          }
          if (cellData.loading) {
            return (
              <LoaderWrapper height={15} width={70}>
                <FlatLoader />
              </LoaderWrapper>
            );
          }
          if (cellData.monthlyAlgorithmData) {
            const decimalPlaces = getDecimalPlaces(
              cellData.monthlyAlgorithmData,
              METRIC_TYPE_NUMBER
            );
            return (
              <NumberCell
                decimalPlaces={decimalPlaces}
                number={cellData.monthlyAlgorithmData ? cellData.monthlyAlgorithmData : null}
                suffix={'monthlyRevenue' === extraKey ? '€' : ''}
              />
            );
          }

          return <NotAcknowledgedCell />;
        },
        dataKey: 'monthlyAlgorithmData',
        label: (
          <TooltipComponent
            description={t(
              `listing:pages-list.list.tooltip.${algorithm || DEFAULT_PRIORITY_SCORE}`,
              {
                metricName: monthlyLabel,
              }
            )}
            placement="top"
            title={t(
              `listing:pages-list.list.monthly-label${
                ['clicks', 'revenue', undefined].includes(priorityScore) ? '' : '_f'
              }`,
              {
                count: 1,
                metricName: monthlyLabel,
              }
            )}
          >
            {t(
              `listing:pages-list.list.monthly-label${
                ['clicks', 'revenue', undefined].includes(priorityScore) ? '' : '_f'
              }`,
              {
                count: 1,
                metricName: monthlyLabel,
              }
            )}
          </TooltipComponent>
        ),
        sortFunction: (value1, value2, sortDirection) =>
          Table.naturalSort(
            value1.monthlyAlgorithmData,
            value2.monthlyAlgorithmData,
            sortDirection
          ),
        sortable: true,
        width: 100,
      },
      ...extraColumns,
      {
        cellDataGetter: ({ rowData }) => ({
          focusTopKeyword: rowData.focusTopKeyword || null,
          loading: (!rowData.topKeywordsRetrievedAt && !rowData.focusTopKeyword) || rowData.loading,
        }),
        cellRenderer: ({ cellData }) =>
          !cellData.loading &&
          cellData.focusTopKeyword && (
            <FocusTopKeywordAssociation focusTopKeyword={cellData.focusTopKeyword} />
          ),
        dataKey: 'focusKeywordAssociation',
        width: 25,
      },
      {
        cellDataGetter: ({ rowData }) => ({
          focusTopKeyword: rowData.focusTopKeyword || null,
          loading: (!rowData.topKeywordsRetrievedAt && !rowData.focusTopKeyword) || rowData.loading,
          pageId: rowData.id,
        }),
        cellRenderer: ({ cellData }) => {
          if (cellData.loading) {
            return (
              <LoaderWrapper key={cellData.focusTopKeyword?.id} height={15} width={150}>
                <FlatLoader />
              </LoaderWrapper>
            );
          }

          return (
            <FocusKeywordSelectorCell
              key={cellData.focusTopKeyword?.id}
              focusTopKeyword={cellData.focusTopKeyword}
              hasSearchConsole={hasSearchConsole}
              pageId={cellData.pageId}
              variant={PAGES_FK}
              workspaceCountryName={workspaceCountryName}
              onUpdate={onFKUpdate}
            />
          );
        },
        dataKey: 'focusKeyword',
        label: (
          <TooltipComponent
            description={t('listing:pages-list.list.tooltip.focus-keyword')}
            placement="top"
            title={t('listing:pages-list.list.focus-keyword')}
          >
            {t('listing:pages-list.list.focus-keyword')}
          </TooltipComponent>
        ),
        sortFunction: (value1, value2, sortDirection) =>
          Table.stringSort(
            value1.focusTopKeyword?.keyword,
            value2.focusTopKeyword?.keyword,
            sortDirection
          ),
        sortable: true,
        width: 180,
      },
      {
        align: 'center',
        cellDataGetter: ({ rowData }) => ({
          loading:
            (rowData.focusTopKeyword &&
              null === rowData.focusTopKeyword.position &&
              null === rowData.focusTopKeyword.lastSearchConsoleUpdatedAt) ||
            rowData.loading,
          position: rowData.focusTopKeyword?.position || null,
        }),
        cellRenderer: ({ cellData }) => {
          if (!hasSearchConsole) {
            return (
              <NoSearchConsoleApiAvailable
                title={t('listing:pages-list.connect-search-console-data')}
              >
                <TertiaryLink to={`/o/${organizationId}/w/${workspaceId}/settings/integrations`}>
                  <WarningTriangleIcon />
                </TertiaryLink>
              </NoSearchConsoleApiAvailable>
            );
          }
          if (cellData.loading) {
            return (
              <LoaderWrapper height={15} width={40}>
                <FlatLoader />
              </LoaderWrapper>
            );
          }
          if (cellData.position !== null) {
            return <NumberCell max={100} number={cellData.position} />;
          }
          return <NotAcknowledgedCell />;
        },
        dataKey: 'focusKeywordPosition',
        label: (
          <TooltipComponent
            description={t('listing:pages-list.list.tooltip.average-position', {
              country: workspaceCountryName,
            })}
            placement="top"
            title={t('listing:pages-list.list.average-position')}
          >
            {t('listing:pages-list.list.average-position')}
          </TooltipComponent>
        ),
        sortFunction: (value1, value2, sortDirection) =>
          Table.naturalSort(value1.position, value2.position, sortDirection),
        sortable: true,
        width: 60,
      },
      {
        align: 'center',
        cellDataGetter: ({ rowData }) => ({
          loading:
            (rowData.focusTopKeyword &&
              !rowData.topKeywordsRetrievedAt &&
              !rowData.focusTopKeyword.keywordDataUpdatedAt &&
              rowData.focusTopKeyword.searchVolume === null) ||
            rowData.loading,
          searchVolume: rowData.focusTopKeyword?.searchVolume || null,
        }),
        cellRenderer: ({ cellData }) => {
          if (cellData.loading) {
            return (
              <LoaderWrapper height={15} width={40}>
                <FlatLoader />
              </LoaderWrapper>
            );
          }
          if (cellData.searchVolume !== null) {
            const decimalPlaces = getDecimalPlaces(cellData.searchVolume, METRIC_TYPE_NUMBER);
            return (
              <NumberCell
                decimalPlaces={decimalPlaces}
                number={cellData.searchVolume ? cellData.searchVolume : null}
              />
            );
          }
          return <NotAcknowledgedCell />;
        },
        dataKey: 'focusKeywordVolume',
        label: (
          <TooltipComponent
            description={t('listing:pages-list.list.tooltip.volume')}
            placement="top"
            title={t('listing:pages-list.list.volume')}
          >
            {t('listing:pages-list.list.volume')}
          </TooltipComponent>
        ),
        sortFunction: (value1, value2, sortDirection) =>
          Table.naturalSort(value1.searchVolume, value2.searchVolume, sortDirection),
        sortable: true,
        width: 60,
      },
      ...potentialColumn,
      {
        align: 'center',
        cellDataGetter: ({ rowData }) => ({
          canAnalyze: !!rowData.focusTopKeyword?.keyword && !rowData.focusTopKeyword.analyzed,
          focusTopKeyword: rowData.focusTopKeyword,
          loading:
            (rowData.focusTopKeyword &&
              [STATUS_PENDING, STATUS_QUEUED].includes(rowData.focusTopKeyword.analysisStatus)) ||
            rowData.loading,
          score: rowData.contentScore,
        }),
        cellRenderer: ({ cellData }) => {
          if (cellData.loading) {
            return (
              <CircularLoaderWrapper height={40} width={40}>
                <FlatLoader />
              </CircularLoaderWrapper>
            );
          }
          if (cellData.canAnalyze) {
            return (
              <StartAnalysisCell
                focusTopKeyword={cellData.focusTopKeyword}
                organizationId={organizationId}
                showInsufficientCreditsModalShow={displayInsufficientCreditsModal}
                triggerUpdateAnalysis={triggerUpdateAnalysis}
              />
            );
          }
          if (cellData.score === null) {
            return <NotAcknowledgedCell align="center" />;
          }
          return <ContentScoreCell contentScore={cellData.score} />;
        },
        dataKey: 'contentScore',
        label: (
          <TooltipComponent
            description={t('listing:pages-list.list.tooltip.content-score')}
            placement="top"
            title={t('listing:pages-list.list.content-score')}
          >
            {t('listing:pages-list.list.content-score')}
          </TooltipComponent>
        ),
        sortFunction: (value1, value2, sortDirection) =>
          Table.naturalSort(value1.score, value2.score, sortDirection),
        sortable: true,
        width: 70,
      },
      {
        align: 'center',
        cellDataGetter: ({ rowData }) => ({
          draftId: rowData.draftId,
          loading: rowData.loading,
          pageId: rowData.id,
          title: rowData.title,
          url: rowData.url,
          workspaceId: workspaceId,
        }),
        cellRenderer: ({ cellData }) => {
          if (cellData.loading) {
            return (
              <CircularLoaderWrapper height={26} width={26}>
                <FlatLoader />
              </CircularLoaderWrapper>
            );
          }

          const draftId = cellData.draftId || null;
          const isPlanned = !!draftId;

          return isPlanned ? (
            <PlannedCell onDelete={() => removeFromPlanning(draftId)} />
          ) : (
            <AddToPlanningCell
              onAdd={() =>
                addToPlanning({
                  pageId: cellData.pageId,
                  title: cellData.title,
                  url: cellData.url,
                  workspaceId: cellData.workspaceId,
                })
              }
            />
          );
        },
        dataKey: 'planning',
        label: (
          <TooltipComponent
            description={t('listing:pages-list.list.tooltip.planning')}
            placement="top"
            title={t('listing:pages-list.list.planning')}
          >
            {t('listing:pages-list.list.planning')}
          </TooltipComponent>
        ),
        sortable: false,
        width: 70,
      },
      {
        cellDataGetter: ({ rowData }) => rowData,
        cellRenderer: ({ cellData }) => {
          function handleRemovePage() {
            deleteRow(cellData.id);
          }

          async function handleUpdateFromWebsite() {
            await pageRefreshDataAsync(cellData.id);
            dispatch(
              showSuccessSnackbar(
                t('listing:pages-list.notification.bulk-refresh-pages_interval', {
                  count: 1,
                  postProcess: 'interval',
                })
              )
            );
          }

          if (!cellData.loading) {
            return (
              <DotsMenu
                handleRemovePage={handleRemovePage}
                handleUpdateFromWebsite={handleUpdateFromWebsite}
              />
            );
          }
        },
        dataKey: 'actions',
        label: ' ',
        width: 50,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, contentsIds, pagesFiltered]);

  function displayInsufficientCreditsModal() {
    setInsufficientCreditsModal({ show: true });
  }

  function onCloseBulkAnalysis() {
    setBulkAnalysisModal({ loading: false, show: false });
  }

  async function onConfirmBulkAnalysis() {
    if (
      !hasUnlimitedAnalysisCredits &&
      remainingAnalysisUnit - getCreditsCost(selectedListWithFocusKeyword.length) < 0
    ) {
      onCloseBulkAnalysis();
      displayInsufficientCreditsModal();

      return;
    }

    setBulkAnalysisModal({ ...bulkAnalysisModal, loading: true });

    try {
      await triggerBulkUpdateAnalysis(selectedListWithFocusKeyword);
    } catch (e) {
      // nothing to catch if an analysis fail
    } finally {
      onCloseBulkAnalysis();
    }
  }

  function checkIfOpenBulkAnalysisDialog() {
    if (bulkAnalysisModal.show && 0 === selectedListWithFocusKeyword.length) {
      setBulkAnalysisModal({ ...bulkAnalysisModal, show: false });
      return false;
    }
    return bulkAnalysisModal.show;
  }

  function handleOpenBulkAddToPlanning() {
    if (bulkAddToPlanningModal.show) {
      if (1 < selectedListNotPlanned.length) {
        return true;
      }
      if (1 === selectedListNotPlanned.length) {
        addToPlanning({
          pageId: selectedListNotPlanned[0].id,
          title: selectedListNotPlanned[0].title,
          url: selectedListNotPlanned[0].url,
          workspaceId: workspaceId,
        });
      }
      setBulkAddToPlanningModal({ ...bulkAddToPlanningModal, error: false, show: false });
    }
    return false;
  }

  async function onConfirmBulkAddToPlanning({ assignedTo, dueDate, contentStatus, folderId }) {
    try {
      setBulkAddToPlanningModal({ ...bulkAddToPlanningModal, error: false, loading: true });
      await triggerBulkAddToPlanning({
        assignedTo,
        contentStatus,
        dueDate,
        folderId,
        pagesList: selectedListNotPlanned,
      });
      setBulkAddToPlanningModal({ loading: false, show: false });
    } catch (e) {
      setBulkAddToPlanningModal({ ...bulkAddToPlanningModal, error: true, loading: false });
    }
  }

  function handleOpenBulkExportPages() {
    if (bulkExportPagesModal.show && 0 === selectedListIds.length) {
      setBulkExportPagesModal({ ...bulkExportPagesModal, show: false });
      return false;
    }

    return bulkExportPagesModal.show;
  }

  async function onConfirmBulkExportPages(fileType) {
    try {
      setBulkExportPagesModal({ ...bulkExportPagesModal, error: false, loading: true });
      await triggerBulkExportPages(selectedListIds, fileType);
      setBulkExportPagesModal({ loading: false, show: false });
    } catch (e) {
      setBulkExportPagesModal({ ...bulkExportPagesModal, error: true, loading: false });
    }
  }

  async function onBulkRefreshPages() {
    const message = t('listing:pages-list.notification.bulk-refresh-pages_interval', {
      count: selectedListIds.length,
      postProcess: 'interval',
    });
    await Promise.all(selectedListIds.map((id) => pageRefreshDataAsync(id)));
    dispatch(showSuccessSnackbar(message));
  }

  function onCloseInsufficientCreditModal() {
    setInsufficientCreditsModal({ show: false });
  }

  function openBulkAddToPlanning() {
    setBulkAddToPlanningModal({ ...bulkAddToPlanningModal, show: true });
  }

  function onCloseBulkAddToPlanning() {
    setBulkAddToPlanningModal({ ...bulkAddToPlanningModal, show: false });
  }

  function onCancelBulkExportPages() {
    setBulkExportPagesModal({ ...bulkExportPagesModal, show: false });
  }

  function openBulkAnalysisDialog() {
    setBulkAnalysisModal({ ...bulkAnalysisModal, show: true });
  }

  function openExportDialog() {
    setBulkExportPagesModal({ ...bulkExportPagesModal, show: true });
  }

  if (noServiceIntegrationVersion) {
    return (
      <NoServiceIntegration
        priorityScore={
          noServiceIntegrationVersion !== NO_GOOGLE_ANALYTICS
            ? priorityScoreToDisplay[priorityScoreToDisplay.length - 1] === 's'
              ? priorityScoreToDisplay.slice(0, -1)
              : priorityScoreToDisplay
            : null
        }
        textVersion={PRIORITIZE_TEXT_VERSION}
        version={noServiceIntegrationVersion}
      />
    );
  }

  if (pagesFiltered.length || loading) {
    return (
      <>
        <Table
          columns={columns}
          customHeader={
            <TableActionBar>
              <DarkText>
                {t('listing:bulk-actions.pages-selected_interval', {
                  count: selectedListIds.length,
                  postProcess: 'interval',
                })}
              </DarkText>

              <BulkActionButton
                disabled={!hasSelectedListSomeKeyword}
                onClick={openBulkAnalysisDialog}
              >
                <SmallFlexTooltipComponent
                  title={t(
                    hasSelectedListSomeKeyword
                      ? 'listing:planning-list.bulk-actions.analyze'
                      : 'listing:planning-list.bulk-actions.no-fk-defined'
                  )}
                >
                  <Analyze2Icon /> {t('listing:planning-list.bulk-actions.analyze')}
                </SmallFlexTooltipComponent>
              </BulkActionButton>

              <BulkActionButton
                disabled={isAddToPlanningButtonDisabled}
                onClick={openBulkAddToPlanning}
              >
                <SmallFlexTooltipComponent
                  title={t(
                    isAddToPlanningButtonDisabled
                      ? t('listing:pages-list.bulk-actions.already-in-planning')
                      : t('listing:pages-list.bulk-actions.add-to-planning')
                  )}
                >
                  <PlusIcon /> {t('listing:pages-list.bulk-actions.add-to-planning')}
                </SmallFlexTooltipComponent>
              </BulkActionButton>

              <BulkActionButton onClick={openExportDialog}>
                <SmallFlexTooltipComponent title={t('listing:planning-list.bulk-actions.export')}>
                  <FileExportOutline /> {t('listing:planning-list.bulk-actions.export')}
                </SmallFlexTooltipComponent>
              </BulkActionButton>

              <VerticalSeparator />

              <BulkActionIcon
                key={'bulk-actions-update'}
                title={t('listing:pages-list.bulk-actions.update-from-website')}
              >
                <ReloadIcon size="small" onClick={onBulkRefreshPages} />
              </BulkActionIcon>

              <BulkActionIcon
                key={'bulk-actions-delete'}
                title={t('listing:pages-list.bulk-actions.delete')}
              >
                <DeleteIcon size="small" onClick={openBulkDeleteDialog} />
              </BulkActionIcon>
            </TableActionBar>
          }
          defaultSort={priorityScore ? 'potentialScore' : 'monthlyAlgorithmData'}
          defaultSortDirection={SortDirection.DESC}
          disablePadding
          enableCustomHeader={selectedListIds.length > 0}
          mode="transparent"
          rows={loading ? LOADING_ROWS_PLACEHOLDER : pagesFiltered}
        />
        {insufficientCreditsModal.show && !loading && (
          <CreditLimitReachedDialog
            creditType={ANALYSIS_CREDITS_TYPE}
            isOpen
            onClose={onCloseInsufficientCreditModal}
          />
        )}
        {checkIfOpenBulkAnalysisDialog(selectedListWithFocusKeyword) && !loading && (
          <BulkAnalysisDialog
            creditsCost={getCreditsCost(selectedListWithFocusKeyword.length)}
            isLoading={bulkAnalysisModal.loading}
            open
            pagesWithFkCount={selectedListWithFocusKeyword.length}
            selectedPageCount={selectedListIds.length}
            onClose={onCloseBulkAnalysis}
            onConfirm={onConfirmBulkAnalysis}
          />
        )}
        {handleOpenBulkAddToPlanning() && !loading && (
          <AddToPlanningDialog
            error={bulkAddToPlanningModal.error}
            header={
              <StyledBlockText color="dark80" size="default" weight={400}>
                {t('listing:pages-list.modal.bulk-add-to-planning-title', {
                  count: selectedListNotPlanned.length,
                })}
              </StyledBlockText>
            }
            loading={bulkAddToPlanningModal.loading}
            open
            onClose={onCloseBulkAddToPlanning}
            onConfirm={onConfirmBulkAddToPlanning}
          />
        )}
        {handleOpenBulkExportPages() && !loading && (
          <ExportDialog
            error={bulkExportPagesModal.error}
            handleCancel={onCancelBulkExportPages}
            handleConfirm={onConfirmBulkExportPages}
            isAsync={isPageExportAsync}
            loading={bulkExportPagesModal.loading}
            open
            title={t('listing:pages-list.export.modal-title_interval', {
              count: selectedListIds.length,
              postProcess: 'interval',
            })}
          />
        )}
      </>
    );
  }

  return (
    <TableFlexStyledCard mode="transparent">
      <NoPages />
    </TableFlexStyledCard>
  );
}

export default List;
