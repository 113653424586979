export const SCOPE_CONTENT_IDEAS = {
  CONTENT_IDEAS: 'getContentIdeas',
  CONTENT_IDEAS_SEARCH: 'getContentIdeaSearch',
  CONTENT_IDEAS_SEARCHES: 'getContentIdeaSearches',
};
export const SCOPE_PAGES = {
  GET_PAGE_BY_ID: 'getPageById',
  WITH_SPECIFIC_PROPERTIES: 'getPagesWithSpecificProperties',
};
export const SCOPE_CONFIGURATION = {
  GET_CONFIGURATIONS: 'getConfigurations',
  GET_INFO: 'getInfo',
};
export const SCOPE_CONTENTS = {
  CONTENT: 'getContentById',
  GET_CONTENTS_BY_PAGE: 'getContentsByPage',
  GET_CONTENT_VERSIONS: 'getContentVersions',
  GET_CONTENT_VERSION_BY_ID: 'getContentVersionById',
  STATUSES: 'getContentsStatuses',
  WITH_SPECIFIC_PROPERTIES: 'getContentsWithSpecificProperties',
};
export const SCOPE_FOLDERS = {
  GET_ALL_BY_WORKSPACE_ID: 'getAllFoldersByWorkspaceId',
};
export const SCOPE_WORKSPACE = {
  GET_CURRENT_WORKSPACE: 'getCurrentWorkspace',
  GET_WORKSPACE_ACCESSES: 'getWorkspaceAccesses',
};
export const SCOPE_SERVICE_INTEGRATIONS = {
  GET_CUSTOM_GOALS: 'getCustomGoals',
  GET_SERVICE_INTEGRATIONS: 'getServiceIntegrations',
};
export const SCOPE_FOCUS_TOP_KEYWORDS = {
  WITH_SPECIFIC_PROPERTIES: 'getFocusTopKeywordsWithSpecificProperties',
};
export const SCOPE_USERS = {
  GET_ALL_BY_WORKSPACE_ID: 'getAllUsersByWorkspaceId',
};
export const SCOPE_USER = {
  UNIVERSAL_SEARCH_API_KEY: 'getUserUniversalSearchApiKey',
};
export const SCOPE_ORGANIZATION = {
  GET_CURRENT_ORGANIZATION: 'getCurrentOrganization',
  GET_ORGANIZATION_ACCESSES: 'getOrganizationAccesses',
};
export const SCOPE_COMPETITORS = {
  GET_COMPETITOR_CLUSTERS: 'getCompetitorsClusters',
  GET_COMPETITOR_CONFIGURATIONS: 'getCompetitorConfigurations',
  GET_COMPETITOR_CONFIGURATION_WEBSITES: 'getCompetitorsConfigurationWebsites',
  GET_COMPETITOR_FUNNELS: 'getCompetitorsFunnels',
  GET_COMPETITOR_PERSONAS: 'getCompetitorsPersonas',
  GET_COMPETITOR_REPORTS: 'getCompetitorsReports',
  GET_COMPETITOR_REPORTS_TOP_PAGES: 'getCompetitorsReportsTopPages',
  GET_COMPETITOR_REPORTS_WEBSITES: 'getCompetitorsReportsWebsites',
  GET_COMPETITOR_REPORT_3YEAR_METRICS: 'getCompetitorsReport3YearMetrics',
  GET_COMPETITOR_REPORT_BY_ID: 'getCompetitorsReportByID',
  GET_COMPETITOR_REPORT_METRICS: 'getCompetitorsReportMetrics',
  GET_COMPETITOR_SUGGESTIONS: 'getCompetitorsSuggestions',
  GET_COMPETITOR_WEBSITE_BY_ID: 'getCompetitorsWebsiteById',
  GET_COMPETITOR_WEBSITE_METRICS: 'getCompetitorsWebsiteMetrics',
};
export const SCOPE_REPORTS = {
  GET_PAGE_METRICS: 'getPageMetrics',
};
export const SCOPE_COMMENTS = {
  GET_THREAD: 'getThread',
  GET_THREAD_BY_CONTENT_ID: 'getThreadByContentId',
};
export const SCOPE_BRAND_VOICES = {
  GET_ALL_BY_WORKSPACE_ID: 'getAllBrandVoicesByWorkspaceId',
  GET_BRAND_VOICE_BY_ID: 'getBrandVoiceById',
};
export const SCOPE_FACTS = {
  GET_FACTS_BY_CONTENT_ID: 'getFactsByContentId',
};
export const SCOPE_UNIT_LIMITATIONS = {
  GET_WORKSPACE_UNIT_LIMITATIONS_BY_ORGANIZATION_ID: 'getWorkspaceUnitLimitationsByOrganizationId',
  GET_WORKSPACE_UNIT_LIMITATIONS_BY_WORKSPACE_ID: 'getWorkspaceUnitLimitationsByWorkspaceId',
};

export const LOCATION = {
  COMPETITORS: 'competitors',
  CONTENT_IDEAS: 'contentIdeas',
  CREATE_DRAFTS: 'createDrafts',
  DASHBOARD: 'dashboard',
  DASHBOARD_CONTENTS_TO_OPTIMIZE: 'contentToOptimize',
  EDITOR: 'editor',
  IDENTIFICATION: 'identification',
  IMPORT_PAGES: 'importPages',
  ORGANIZATION: 'organization',
  PAGES: 'pages',
  PAGES_ALL_PAGES: 'pagesAllPages',
  PAGES_PAGES: 'pagesPages',
  PLANNING: 'planning',
  QUICK_START_GUIDE: 'quickStartGuide',
  REPORTS: 'reports',
  ROOT: 'root',
  SETTINGS: 'settings',
  TOP_PAGES: 'topPages',
  WORKSPACE: 'workspace',
};

export const USER_EVENTS = {
  VIEW_CONTENT_COMMENTS: 'VIEW_CONTENT_COMMENTS',
};

// Maintenance errors: unavaibility / deployment
// https://gitlab.rvip.fr/semji/semji/-/issues/4587#note_141217
// https://gitlab.rvip.fr/semji/semji/-/issues/4648
// https://gitlab.rvip.fr/semji/semji/-/issues/4656
// https://gitlab.rvip.fr/semji/semji/-/issues/6980
export const SEMJI_MAINTENANCE_ERRORS = [502];

export const REQUEST_ERROR_TIMEOUT = 15000; // 15s
export const REQUEST_ERROR_RETRIES = 5;

export const STALE_TIME_ONE_HOUR = 1000 * 60 * 60; // One hour
