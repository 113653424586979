interface CompetitorFilter {
  id: string;
  name: string;
}

export interface CompetitorCluster extends CompetitorFilter {
  classificationName: string;
}
export interface CompetitorCategory extends CompetitorFilter {}
export interface CompetitorPersona extends CompetitorFilter {
  classificationName: string;
}
export interface CompetitorFunnel extends CompetitorFilter {}

export enum PageType {
  Article = 'article',
  CatArticle = 'article_category',
  CatEcommerce = 'ecommerce_category',
  HomePage = 'homepage',
  LandingPage = 'landing_page',
  Misc = 'misc',
  News = 'news',
  ProductSheet = 'ecommerce_product_sheet',
  Shopping = 'shopping',
  Tool = 'tool',
  Forum = 'forum',
}

export interface CompetitorFilters {
  category?: PageType[];
  cluster?: string[];
  persona?: string[];
  funnel?: string[];
  competitorWebsiteId?: string[];
}
