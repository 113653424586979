import styled from 'styled-components/macro';

import { InfoButton } from '../Button/Button';
import TooltipComponent from './Tooltip';

const Tooltip = styled(TooltipComponent)`
  && {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: ${({ theme }) => theme.textCss.sizes.default};
  }
`;

const Link = styled.a`
  flex: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

function InfoLinkTooltip({ className, href, title }) {
  return (
    <Tooltip className={className} placement="top" title={title}>
      <Link href={href} rel="noopener noreferrer" target="_blank">
        <InfoButton $isMargin bordered />
      </Link>
    </Tooltip>
  );
}

export default InfoLinkTooltip;
