import { nanoid } from 'nanoid';
import React, { useMemo } from 'react';

import SvgWrapper from './SvgWrapper';

function FullscreenIcon({ ...props }) {
  const maskId = useMemo(() => nanoid(), []);

  return (
    <SvgWrapper {...props} fill="none" height="13" viewBox="0 0 13 13" width="13">
      <mask
        height="13"
        id={`mask${maskId}`}
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="13"
        x="0"
        y="0"
      >
        <path
          clipRule="evenodd"
          d="M0.698104 0.347038H0.248104V0.797038V5.11404H1.1481V1.88347L4.69691 5.43226L5.33331 4.79586L1.78446 1.24704H5.01511V0.347038H0.698104ZM11.2156 1.24704L7.66671 4.79586L8.3031 5.43226L11.8519 1.88348V5.11404H12.7519V0.797038V0.347038H12.3019H7.9849V1.24704H11.2156ZM1.7824 11.441L5.33124 7.89216L4.69485 7.25576L1.14604 10.8045V7.57397H0.246044V11.891V12.341H0.696044H5.01305V11.441H1.7824ZM11.2176 11.441L7.66877 7.89216L8.30516 7.25576L11.854 10.8045V7.57397H12.754V11.891V12.341H12.304H7.98697V11.441H11.2176Z"
          fill="black"
          fillRule="evenodd"
        />
      </mask>
      <g mask={`url(#mask${maskId})`}>
        <rect fill="currentColor" height="15.2941" width="15.2941" x="-1.52941" y="-0.764648" />
      </g>
    </SvgWrapper>
  );
}

export default FullscreenIcon;
