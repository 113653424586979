import qs from 'qs';

import { QUERY_PARAM_FILTER } from '@/hooks/useFilters/const';
import {
  BOOLEAN_OPERATOR,
  EXISTS_OPERATOR,
  IFilter,
  IFilterBoolean,
  IFilterClassic,
  IFilterDispatched,
  IFilterExists,
  IFilterMulti,
  IFilterOperators,
  MULTI_SEARCH_OPERATOR,
  NUMERIC_OPERATOR,
} from '@/hooks/useFilters/useFilters.types';

export class UseFiltersUtils {
  /* Get url params filter.
  Also reconstruct query string to IFilter<any>

  from:
  propertyA[]=abc & propertyA[]=def & propertyB[exact]=toto & propertyB[partial]=tata & propertyC=false & exists[propertyD]=true

  to:
  {
    propertyA: {
      multi_search: [ abc, def ]
    }
    propertyB: {
      exact: "toto",
      partial: "tata"
    }
    propertyC: {
      boolean: false
    }
    propertyD: {
      exists: true
    }
  }
  */
  static getParsedFilters(query: URLSearchParams): IFilter<any> {
    const hashed: string | null = query.get(QUERY_PARAM_FILTER);
    if (!hashed) return {};
    try {
      const unHashed = qs.parse(UseFiltersUtils.unHashQueryParams(hashed));
      const builtFilters: IFilter<any> = {};
      Object.entries(unHashed).forEach(([propertyKey, value]) => {
        builtFilters[propertyKey] = {};
        // Boolean parsing
        // If value is true | false and operator is a number
        if (['true', 'false'].includes(value as string)) {
          // @ts-ignore
          builtFilters[propertyKey][BOOLEAN_OPERATOR.boolean] = unHashed[propertyKey] === 'true';
          return;
        }

        Object.entries(unHashed[propertyKey] as {}).forEach(([operator, value]) => {
          // Multi search parsing
          // If operator is a number between 0 and 99
          if (Array.from(Array(100).keys()).includes(parseInt(operator))) {
            // @ts-ignore
            builtFilters[propertyKey][MULTI_SEARCH_OPERATOR.multi_search] = [
              // @ts-ignore
              ...(builtFilters?.[propertyKey]?.[MULTI_SEARCH_OPERATOR.multi_search] ?? []),
              value,
            ];
          }
          // Operator parsing
          else {
            if (Object.keys(NUMERIC_OPERATOR).includes(operator)) {
              // @ts-ignore
              builtFilters[propertyKey][operator] = parseInt(value);
            } else if (Object.keys(EXISTS_OPERATOR).includes(propertyKey)) {
              builtFilters[operator] =
                Object.keys(builtFilters[propertyKey] ?? {}).length > 0
                  ? { ...builtFilters[propertyKey] }
                  : {};
              // @ts-ignore
              builtFilters[operator][propertyKey] = value === 'true';
            } else {
              // @ts-ignore
              builtFilters[propertyKey][operator] = value;
            }
          }
        });
      });
      return builtFilters;
    } catch (error) {
      return {};
    }
  }

  static getEncodedFilters(filters: IFilter<any>): string {
    return UseFiltersUtils.hashStringifiedFilters(UseFiltersUtils.getStringifiedFilters(filters));
  }

  // Will return a stringified version of the filters usable in query params
  static getStringifiedFilters(filters: IFilter<any>): string {
    const { booleanFilters, multiFilters, existsFilters, classicFilters } =
      UseFiltersUtils.dispatchFilterOperators(filters);

    return UseFiltersUtils.chainParamsProperties([
      UseFiltersUtils.stringifyMultiFilter(multiFilters),
      UseFiltersUtils.stringifyBooleanFilter(booleanFilters),
      UseFiltersUtils.stringifyClassicFilter(classicFilters),
      UseFiltersUtils.stringifyClassicFilter(existsFilters),
    ]);
  }

  // Will return a transformed version of the filters usable for exports
  static getTransformedFilters(filters: IFilter<any>): any {
    const { booleanFilters, multiFilters, existsFilters, classicFilters } =
      UseFiltersUtils.dispatchFilterOperators(filters);

    return {
      ...booleanFilters,
      ...classicFilters,
      ...multiFilters,
      ...existsFilters,
    };
  }

  static chainParamsProperties(queryParams: (string | undefined | null)[]): string {
    return queryParams.filter((value) => value && value !== '' && value !== 'null').join('&');
  }

  // Will remove the brackets from query params
  static unHashQueryParams(hashed: string | null): string {
    return decodeURIComponent(hashed ?? '').slice(1, -1) ?? '';
  }

  // Will add brackets to be able to target filters value
  static hashStringifiedFilters(stringifiedFilters: string): string {
    return encodeURIComponent(`[${stringifiedFilters}]`);
  }

  // From: { property: [value1, value2] }
  //
  // To : property[0]=value1&property[1]=value2
  private static stringifyMultiFilter(filter: IFilterMulti<any>): string {
    return qs.stringify(filter, { arrayFormat: 'indices' });
  }

  // From: { property: { operator : value } }
  //
  // To : property=value
  private static stringifyBooleanFilter(filter: IFilterBoolean<any>): string {
    return qs.stringify(filter);
  }

  // From: { property: { operator : value } }
  //
  // To : property[operator]=value
  private static stringifyClassicFilter(filter: IFilterClassic<any> | IFilterExists<any>): string {
    return qs.stringify(filter, { indices: false });
  }

  private static dispatchFilterOperators(filters: IFilter<any>): IFilterDispatched<any> {
    const onlyClassicFilters: IFilterClassic<any> = {};
    const onlyMultiFilters: IFilterMulti<any> = {};
    const onlyBooleanFilters: IFilterBoolean<any> = {};
    const onlyExistsFilter: IFilterExists<any> = {};
    Object.keys(filters).forEach((propertyKey, index) => {
      const filterOperators: IFilterOperators = filters[propertyKey] as IFilterOperators;
      Object.keys(filterOperators).forEach((operator) => {
        switch (operator) {
          case EXISTS_OPERATOR.exists:
            // @ts-ignore
            onlyExistsFilter[operator] = { [propertyKey]: filters[propertyKey][operator] };
            break;
          case MULTI_SEARCH_OPERATOR.multi_search:
            // @ts-ignore
            onlyMultiFilters[propertyKey] = filters[propertyKey][operator];
            break;
          case BOOLEAN_OPERATOR.boolean:
            // @ts-ignore
            onlyBooleanFilters[propertyKey] = filters[propertyKey][operator];
            break;
          default:
            // @ts-ignore
            onlyClassicFilters[propertyKey] = filters[propertyKey];
        }
      });
    });

    return {
      existsFilters: onlyExistsFilter,
      booleanFilters: onlyBooleanFilters,
      classicFilters: onlyClassicFilters,
      multiFilters: onlyMultiFilters,
    };
  }
}
