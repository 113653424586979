import cloneDeep from 'lodash/cloneDeep';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useKeyPress } from 'semji-core/hooks/useKeyPress';
import { Placements } from 'semji-core/hooks/usePopover';

import {
  ERROR_MESSAGE_CREDIT_LIMIT,
  ERROR_MESSAGE_RATE_LIMIT,
} from '@/apis/semji/aiWriting/useStreamModel/const';
import {
  computeTypingSpeed,
  GENERATION_INTRODUCTION_TYPE,
  GENERATION_METADESCRIPTION_TYPE,
  GENERATION_OUTLINE_TYPE,
  GENERATION_PARAGRAPH_TYPE,
  GENERATION_TITLE_TYPE,
  sleep,
} from '@/containers/Content/EditorComponents/Ai/constants';
import useAiWrapper, {
  ID_WRITING_ACTION,
  ID_WRITING_LEFT_ARROW,
  ID_WRITING_RIGHT_ARROW,
} from '@/containers/Content/TinyMceComponents/Editor/hooks/useAiWriting/useAIWrappper/useAiWrapper';
import { GeneratedContentType } from '@/containers/Content/TinyMceComponents/Editor/hooks/useAiWriting/useAiWriting.types';
import { isTitleStreamMode } from '@/containers/Content/TinyMceComponents/Editor/hooks/useAiWriting/useStreamContent/helper.utils';
import {
  AbortFactCheckRef,
  AbortRef,
  GeneratedContent,
  UseStreamContentParameters,
} from '@/containers/Content/TinyMceComponents/Editor/hooks/useAiWriting/useStreamContent/useStreamContent.types';
import { useStreamQueue } from '@/containers/Content/TinyMceComponents/Editor/hooks/useAiWriting/useStreamQueue';
import useAiWritingCredit from '@/containers/Content/TinyMceComponents/Editor/hooks/useAiWritingCredit';
import useApiConfigurations from '@/hooks/useApiConfigurations';
import { useMixpanelTrackEvent } from '@/hooks/useMixpanelTrackEvent';
import useOrganizationFeatureSet, {
  AI_WRITING__FACT_CHECKING__IS_ENABLED,
} from '@/hooks/useOrganizationFeatureSet';
import { showErrorSnackbar } from '@/store/actions/ui';
import { selectUserConfiguration } from '@/store/selectors/selectUserConfiguration';
import { Fact } from '@/types/fact/fact.types';
import {
  AI_PLUS_CONTENT_GENERATE,
  AI_PLUS_CONTENT_INSERT,
  AI_PLUS_CONTENT_REGENERATE,
  AI_PLUS_CONTENT_STOP,
} from '@/utils/3rdParty/Mixpanel/constants';
import {
  AI_WRITING_FACT_CHECKING_ENABLED,
  COMPLETION_TITLE_PROMPT_CODE,
} from '@/utils/configurations/constants';

export const BLOCK_TYPES_TO_LOOK_FOR = 'h1, h2, h3, h4, h5, h6';

export const ID_POPPER_WRAPPER = 'ai-writing-popper';

export function getHasContent(selection: any) {
  return selection?.getContent()?.length > 0;
}

export function isNodeInsideTable(currentNode: HTMLElement | any) {
  if (!currentNode) return null;
  if (currentNode.tagName === 'TABLE') return currentNode;
  if (!currentNode.parentNode) return null;
  return isNodeInsideTable(currentNode.parentNode);
}

function useStreamContent({
  editorRef,
  setRefetchAiUnit,
  setShowLimitAiWriting,
  setShowWorkspaceUnitLimitationModal,
  inputTitleRef,
  aiWritingCreditsEnabled,
  textareaMetaDescriptionRef,
  hasUnlimitedAIWriting,
  lastGeneration,
  focusTopKeywordRef,
  currentBrandVoice,
  factCheckHook,
  disableAutoScrollRef,
  setEditorAiWritingPopperOpen,
  triggerSaveRef,
  remainingAiWritingUnit,
  flagContentHook,
}: UseStreamContentParameters) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { factCheckingEnabled } = useSelector(selectUserConfiguration);
  const trackMixpanelEvent = useMixpanelTrackEvent();
  const { isFeatureEnabled: factCheckingFeatureSetEnabled } = useOrganizationFeatureSet(
    AI_WRITING__FACT_CHECKING__IS_ENABLED
  );
  const { getAiWritingCredit } = useAiWritingCredit();
  const aiWritingFactCheckingEnabled = useApiConfigurations(AI_WRITING_FACT_CHECKING_ENABLED);
  const titlePromptCode = useApiConfigurations(COMPLETION_TITLE_PROMPT_CODE);
  const isTitleInStreamMode = isTitleStreamMode(titlePromptCode);

  const [popperTooltip, setPopperTooltip] = useState<{
    content: React.ReactNode;
    position: any;
    placement?: string;
  }>({
    content: null,
    placement: Placements,
    position: null,
  });
  const [generatedContents, setGeneratedContents] = useState<GeneratedContent[]>([]);
  const generatedContentRef = useRef<GeneratedContent[]>([]);
  generatedContentRef.current = generatedContents;
  const isAiFactCheckedCondition =
    factCheckingEnabled && factCheckingFeatureSetEnabled && aiWritingFactCheckingEnabled;
  const initAbortRef = {
    abortMutation: undefined,
    aborted: false,
    isTyping: false,
    lastMetaDescription: '',
    lastTitle: '',
  };

  const initAbortFactCheckRefRef = {
    abortMutation: undefined,
    aborted: false,
  };

  const regenerateFn = useRef<() => void>();
  const regenerateCreditsCountsRef = useRef<number | undefined>();

  const abortRef = useRef<AbortRef>(initAbortRef);
  const abortFactCheckRef = useRef<AbortFactCheckRef>(initAbortFactCheckRefRef);

  const {
    createAiWritingWrapper,
    createLoader,
    createAiWritingError,
    closeAiWriting,
    createFactCheckLoader,
    activeFooterButtons,
    blockFooterButtons,
    deleteFactCheckLoader,
    blockFeedback,
    allowFeedback,
    updateArrows,
    aiWritingWrapperRef,
    updateCounter,
    aiWrapperShown,
    aiWritingContent,
    aiWritingButtonAddContentRef,
  } = useAiWrapper({
    abortRef,
    aiWritingCreditsEnabled,
    editorRef,
    factCheckingEnabled,
    flagContentHook,
    generatedContentsHook: [generatedContents, setGeneratedContents],
    handleCancelAiWriting,
    handleRegenerate,
    handleStreamEnd,
    hasUnlimitedAIWriting,
    inputTitleRef,
    lastGenerationRef: lastGeneration,
    regenerateCreditsCountsRef,
    remainingAiWritingUnit,
    setEditorAiWritingPopperOpen,
    setPopperTooltip,
    textareaMetaDescriptionRef,
  });

  const {
    launchIntroduction,
    launchOutline,
    launchParagraph,
    launchTitle,
    launchMetaDescription,
    stopQueue,
    loading,
  } = useStreamQueue({
    abortFactCheckRef,
    abortRef,
    afterEachFactCheck: async ({ streamId, facts }) => {
      updateGeneratedContentFactCheck({ facts, streamId });
    },
    afterFactCheck: async ({ facts }) => {
      deleteFactCheckLoader();
      factCheckHook.addNewFactsOptimistically(facts);

      prepareAddContentButton();
    },
    afterQueue: async () => {},
    beforeFactCheck: async ({ streamId }) => {
      setGeneratedContentFactCheckLoading({ streamId });
      blockFooterButtons(handleCancelFacts, 'content:ai-writing:stop-fact-checking');
    },
    beforeQueue: async () => {
      openAiWrapper();
      blockFooterButtons(handleCancel);
      deleteFactCheckLoader();
    },
    brandVoice: currentBrandVoice,
    forEachFactCheck: ({ fact, id }) =>
      factCheckHook.handleFactCheck({
        fact,
        targetNode: document.querySelector('div.ai-loader-wrapper') as HTMLElement,
      }),
    handleError: handleError,
    handleSuccess: handleSuccess,
    hasAccessToMetaDescription: true,
    isAiFactChecked: isAiFactCheckedCondition,
    isAtomicContent: false,
    lastGenerationRef: lastGeneration,
    mutations: {
      handleMutateContent: handleMutate,
      handleMutateMetaDescription: handleMutate,
      handleMutateTitle: handleMutate,
    },
    payload: {
      editorRef,
      focusTopKeywordRef,
      inputTitleRef,
    },
    setRefetchAiUnit,
    textareaMetaDescriptionRef,
    write: {
      handleWriteContent: handleStreamWrite,
      handleWriteMetaDescription: handleStreamWriteMetaDescription,
      handleWriteTitle: isTitleInStreamMode ? handleStreamWriteTitle : handleWriteTitleInline,
    },
  });

  useKeyPress('Escape', handleCancel);
  useKeyPress('Enter', handlePressEnter);

  function handlePressEnter(e: React.KeyboardEvent<HTMLElement>) {
    const enterButtonElement = document.getElementById(ID_WRITING_ACTION);
    const footerButtons = document.querySelectorAll('div.ai-editor-wrapper button');
    const activeElement = document.activeElement;
    if (
      enterButtonElement &&
      !e.defaultPrevented &&
      Array.from(footerButtons).every((elem) => elem !== activeElement)
    ) {
      enterButtonElement.click();
      e?.preventDefault();
    }
  }

  function handleCancelFacts() {
    deleteFactCheckLoader();
    handleCancelFactChecking();
    prepareAddContentButton();
  }

  function handleCancel() {
    trackMixpanelEvent(AI_PLUS_CONTENT_STOP, {
      is_fact_checking: isAiFactCheckedCondition,
      type: lastGeneration.current?.type,
    });

    stopQueue();
    if (abortRef.current.isTyping) {
      if (abortRef.current.abortMutation) {
        const loaderElement: Element | null = document.querySelector('div.ai-loader-wrapper');
        abortRef.current.isTyping = false;
        setGeneratedContents((prevState) => {
          const selectedIndex = prevState.findIndex((content) => !!content.selected);
          prevState[selectedIndex].cancelled = true;
          return [...prevState];
        });
        if (loaderElement) {
          handleStreamEnd(loaderElement?.innerHTML, lastGeneration?.current?.type);
        }
        handleCancelAiWriting();
        prepareAddContentButton();
      } else {
        if (lastGeneration?.current?.type === GENERATION_TITLE_TYPE) {
          inputTitleRef.current.value = '';
          setEditorAiWritingPopperOpen(false);
        } else if (lastGeneration?.current?.type === GENERATION_METADESCRIPTION_TYPE) {
          textareaMetaDescriptionRef.current.value = '';
          setEditorAiWritingPopperOpen(false);
        }
        handleCloseAiWritingWrapper();
      }
    } else {
      if (lastGeneration?.current?.type === GENERATION_TITLE_TYPE) {
        inputTitleRef.current.value = '';
        setEditorAiWritingPopperOpen(false);
      } else if (lastGeneration?.current?.type === GENERATION_METADESCRIPTION_TYPE) {
        textareaMetaDescriptionRef.current.value = '';
        setEditorAiWritingPopperOpen(false);
      }
      handleCloseAiWritingWrapper();
    }
  }

  function handleCancelAiWriting() {
    abortRef.current.aborted = true;
    if (!abortRef.current.isTyping && abortRef.current.abortMutation) {
      abortRef.current.abortMutation();
    }
  }

  function handleCancelFactChecking() {
    abortFactCheckRef.current.aborted = true;
    abortFactCheckRef.current.abortMutation?.();
  }

  function handleResetAbortRef() {
    abortRef.current = initAbortRef;
  }

  function handleResetAbortFactCheckRef() {
    abortFactCheckRef.current = initAbortFactCheckRefRef;
  }

  function handleResetTitle() {
    inputTitleRef.current.setAttribute('style', '');
    inputTitleRef.current.disabled = false;
    if (textareaMetaDescriptionRef.current) textareaMetaDescriptionRef.current.disabled = false;
  }

  function handleResetMetaDescription() {
    textareaMetaDescriptionRef.current.setAttribute('style', '');
    textareaMetaDescriptionRef.current.disabled = false;
    if (textareaMetaDescriptionRef.current) textareaMetaDescriptionRef.current.disabled = false;
  }

  function handleError(error: any) {
    setRefetchAiUnit((prevState) => prevState + 1);

    const { response } = error;

    if (error.message === ERROR_MESSAGE_CREDIT_LIMIT) {
      setShowLimitAiWriting(true);
    }
    if (!abortRef.current.aborted && !abortFactCheckRef.current.aborted) {
      deleteFactCheckLoader();
      if (response?.status === 429 || error?.message === ERROR_MESSAGE_RATE_LIMIT) {
        setShowWorkspaceUnitLimitationModal(true);
        handleCloseAiWritingWrapper();
      } else if (error?.message === ERROR_MESSAGE_RATE_LIMIT) {
        createAiWritingError();
        dispatch(showErrorSnackbar(t(`common:error.rate-limit`)));
      } else {
        createAiWritingError();
        dispatch(showErrorSnackbar(t('common:error.default')));
      }
      handleResetTitle();
      handleResetMetaDescription();
    }

    handleResetAbortRef();
    handleResetAbortFactCheckRef();
  }

  async function handleWriteTitleInline(data: { title: string; id: string }) {
    abortRef.current.isTyping = true;
    if (inputTitleRef.current) {
      const generatedTitle = data.title.trim();
      const time = computeTypingSpeed(generatedTitle.length);

      inputTitleRef.current.setAttribute('style', '');

      let buffer = '';

      for (let i = 0; generatedTitle[i]; i++) {
        const loader = document.querySelector('div.ai-loader-wrapper');
        if (abortRef.current.aborted) break;
        buffer = buffer + generatedTitle[i];
        editorRef.current.focus();
        editorRef.current.dom.setHTML(loader, buffer);
        editorRef.current.selection.select(aiWritingContent.current, true);
        editorRef.current.selection.collapse(false);
        await sleep(time);
      }

      handleStreamEnd(buffer, GENERATION_TITLE_TYPE, data.id);

      // Trigger save event on input title.
      inputTitleRef.current.dispatchEvent(new Event('input', { bubbles: true }));
    }
  }

  async function handleSuccess(data: any) {
    if (lastGeneration.current) {
      lastGeneration.current.id = data.streamId;
    }
    if (lastGeneration.current.type === GENERATION_TITLE_TYPE && !isTitleInStreamMode) {
      await handleWriteTitleInline(data.data);
    }

    // after success if no fact check will be triggered
    if (
      !aiWritingFactCheckingEnabled ||
      !factCheckingEnabled ||
      !factCheckingFeatureSetEnabled ||
      ![GENERATION_INTRODUCTION_TYPE, GENERATION_PARAGRAPH_TYPE].includes(
        lastGeneration.current.type as string
      )
    ) {
      prepareAddContentButton();
    }

    handleResetAbortRef();
  }

  function openAiWrapper() {
    if (aiWrapperShown) {
      createLoader();
    } else {
      if (lastGeneration.current?.type === GENERATION_TITLE_TYPE) {
        inputTitleRef.current.value = ' ';
        const aiWritingPopper = document.getElementById(ID_POPPER_WRAPPER);
        if (aiWritingPopper) {
          createAiWritingWrapper(aiWritingPopper);
        }
      } else if (lastGeneration.current?.type === GENERATION_METADESCRIPTION_TYPE) {
        textareaMetaDescriptionRef.current.value = ' ';
        const aiWritingPopper = document.getElementById(ID_POPPER_WRAPPER);
        if (aiWritingPopper) {
          createAiWritingWrapper(aiWritingPopper);
        }
      } else {
        // Init generated content or create a new one
        let currentNode = lastGeneration.current?.isRegenerate
          ? editorRef.current.dom.getPrev(lastGeneration.current?.nodes?.[0], '*') ||
            editorRef.current.selection.getEnd()
          : isNodeInsideTable(editorRef.current.selection.getEnd()) ||
              getHasContent(editorRef.current.selection)
            ? editorRef.current.selection.getEnd()
            : editorRef.current.selection.getNode();

        // No clue why, but it happens that selection is on the editor node
        if (editorRef.current.getBody().id === currentNode.id) {
          currentNode = editorRef.current.dom.select('p.ai-placeholder');
          editorRef.current.selection.select(currentNode, true);
        }

        createAiWritingWrapper(currentNode);

        if (
          currentNode?.tagName === 'P' &&
          (Array.from(currentNode.childNodes).length === 1 ||
            currentNode?.innerHTML === '/<br>' ||
            currentNode?.innerHTML === '/<br data-mce-bogus="1">')
        ) {
          currentNode.remove();
        }
      }
    }
  }

  function handleMutate() {
    disableAutoScrollRef.current = false;
    setGeneratedContents((prevState) => [
      { cancelled: false, html: undefined, reported: false, selected: true, streamId: undefined },
      ...prevState.map((content) => ({ ...content, selected: false })),
    ]);
  }
  async function handleStreamWriteTitle({ contents, streamingEnd, streamId }: any) {
    abortRef.current.isTyping = true;
    if (streamingEnd) {
      if (lastGeneration.current) {
        lastGeneration.current = {
          ...lastGeneration.current,
          isRegenerate: false,
          type: GENERATION_TITLE_TYPE,
        };
      }
      setGeneratedContents((prevState) => {
        prevState[0].html = contents.html;
        prevState[0].streamId = streamId;
        return [...prevState];
      });
    } else {
      const loader = document.querySelector('div.ai-loader-wrapper');
      if (loader) {
        loader.innerHTML = contents.html;
      }
    }

    return Promise.resolve();
  }

  async function handleStreamWriteMetaDescription({ contents, streamingEnd, streamId }: any) {
    abortRef.current.isTyping = true;
    if (streamingEnd) {
      lastGeneration.current = {
        ...lastGeneration.current,
        isRegenerate: false,
        type: GENERATION_METADESCRIPTION_TYPE,
      };
      setGeneratedContents((prevState) => {
        prevState[0].html = contents.html;
        prevState[0].streamId = streamId;
        return [...prevState];
      });
    } else {
      const loader = document.querySelector('div.ai-loader-wrapper');
      if (loader) {
        loader.innerHTML = contents.html;
      }
    }

    return Promise.resolve();
  }

  function handleStreamEnd(html: string, type?: GeneratedContentType, streamId?: string) {
    setGeneratedContents((prevState) => {
      if (prevState.length > 0) {
        prevState[0].html = html;
        if (streamId) prevState[0].streamId = streamId;
        return [...prevState];
      } else {
        return [];
      }
    });
  }

  function updateFactEnabledAt() {
    const selectedContent = generatedContentRef.current?.find(
      (content) => !!content.selected && !!content.html
    );
    const currentDate = new Date().toString();

    cloneDeep(selectedContent?.facts)?.forEach((fact: Fact) => {
      factCheckHook.updateKeyFactsOptimistically(fact, 'enabledAt', currentDate);
    });

    setGeneratedContents([]);
  }
  async function handleStreamWrite({ type, contents, streamingEnd = false, streamId }: any) {
    abortRef.current.isTyping = true;
    if (streamingEnd) {
      return handleStreamEnd(contents.html, type, streamId);
    } else {
      if (!disableAutoScrollRef.current) {
        aiWritingButtonAddContentRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
      // Init or create new generated content
      editorRef.current.focus();
      editorRef.current.dom.setHTML(
        editorRef.current.dom.select('div.ai-loader-wrapper'),
        contents.html
      );
      editorRef.current.selection.select(aiWritingContent.current, true);
      editorRef.current.selection.collapse(false);
    }

    return Promise.resolve();
  }

  function prepareAddContentButton() {
    abortRef.current.isTyping = false;

    activeFooterButtons();

    if (
      [GENERATION_TITLE_TYPE, GENERATION_METADESCRIPTION_TYPE].includes(
        lastGeneration.current.type as string
      )
    ) {
      if (aiWritingButtonAddContentRef.current) {
        aiWritingButtonAddContentRef.current.onclick = function () {
          const selectedContent = generatedContentRef.current?.find(
            (content) => !!content.selected && !!content.html
          );
          const selectedContentHtml = selectedContent?.html;

          trackMixpanelEvent(AI_PLUS_CONTENT_INSERT, {
            is_fact_checking: isAiFactCheckedCondition,
            type: lastGeneration.current.type,
          });

          editorRef.current.mode.set('design');
          inputTitleRef.current.disabled = false;
          if (textareaMetaDescriptionRef.current)
            textareaMetaDescriptionRef.current.disabled = false;

          if (lastGeneration.current.type === GENERATION_TITLE_TYPE && selectedContentHtml) {
            inputTitleRef.current.value = selectedContentHtml as string;
            inputTitleRef.current.focus();
            inputTitleRef.current.dispatchEvent(new Event('input', { bubbles: true }));
          }

          if (
            lastGeneration.current.type === GENERATION_METADESCRIPTION_TYPE &&
            selectedContentHtml
          ) {
            textareaMetaDescriptionRef.current.value = selectedContentHtml;
            textareaMetaDescriptionRef.current.focus();
            textareaMetaDescriptionRef.current.dispatchEvent(new Event('input', { bubbles: true }));
          }
          editorRef.current.dom.remove(aiWritingWrapperRef.current);
          aiWritingWrapperRef.current = null;
          setEditorAiWritingPopperOpen(false);
          handleResetAbortRef();
          handleResetAbortFactCheckRef();
          setGeneratedContents([]);
        };
      }
    } else {
      if (aiWritingButtonAddContentRef.current) {
        aiWritingButtonAddContentRef.current.onclick = function () {
          trackMixpanelEvent(AI_PLUS_CONTENT_INSERT, {
            is_fact_checking: isAiFactCheckedCondition,
            type: lastGeneration.current.type,
          });

          const leftArrow = document.getElementById(ID_WRITING_LEFT_ARROW);
          const rightArrow = document.getElementById(ID_WRITING_RIGHT_ARROW);
          if (leftArrow && rightArrow) {
            leftArrow.style.cursor = 'not-allowed';
            rightArrow.style.cursor = 'not-allowed';
          }
          editorRef.current.mode.set('design');
          inputTitleRef.current.disabled = false;
          if (textareaMetaDescriptionRef.current)
            textareaMetaDescriptionRef.current.disabled = false;

          let lastChildNode = null;
          if (aiWritingContent.current) {
            let previousNode: any | undefined = undefined;
            const arrayChildNodes = Array.from(aiWritingContent.current.childNodes);
            arrayChildNodes.forEach((childNode, index) => {
              if (childNode.nodeName !== '#text') {
                previousNode = editorRef.current.dom.insertAfter(
                  childNode,
                  previousNode ?? aiWritingWrapperRef.current
                );
                if (index === 1) lastChildNode = childNode;
              }
            });
          }
          triggerSaveRef.current = true;
          aiWritingWrapperRef.current?.scrollIntoView({
            behavior: 'instant',
            block: 'center',
          });

          updateFactEnabledAt();

          editorRef.current.dom.remove(aiWritingWrapperRef.current);
          aiWritingWrapperRef.current = null;
          editorRef.current.setDirty(true);
          editorRef.current.focus();
          editorRef.current.selection.select(lastChildNode, true);
          editorRef.current.selection.collapse(false);
        };
      }
    }
  }

  // Set content to add in editor
  useEffect(() => {
    if (generatedContents.length > 0) {
      const selectedContentIndex = generatedContents?.findIndex(
        (content) => !!content.selected && !!content.html
      );
      const selectedContent = generatedContents[selectedContentIndex];
      if (selectedContent) {
        const loaderElement = document.querySelector('div.ai-loader-wrapper');
        if (
          loaderElement &&
          (lastGeneration.current.type === GENERATION_TITLE_TYPE ||
            lastGeneration.current.type === GENERATION_METADESCRIPTION_TYPE)
        ) {
          loaderElement.innerHTML = selectedContent.html || '';
        } else {
          editorRef.current.dom.setHTML(loaderElement, selectedContent.html);
          // Init fact-check loader on selected generation change
          if (!!selectedContent?.factCheckLoading) {
            createFactCheckLoader();
          } else {
            deleteFactCheckLoader();
          }
          // Init highlight on selected generation change
          selectedContent.facts?.forEach((fact) => {
            factCheckHook.handleFactCheck({
              fact,
              targetNode: document.querySelector('div.ai-loader-wrapper') as HTMLElement,
            });
          });
        }

        lastGeneration.current = {
          ...lastGeneration.current,
          id: undefined,
          isRegenerate: false,
          nodes: [],
        };
        lastGeneration.current.id = generatedContents[selectedContentIndex].streamId;
        if (loaderElement) {
          const arrayChildNodes = Array.from(loaderElement?.childNodes);
          arrayChildNodes.forEach((childNode) => {
            if (childNode.nodeName !== '#text') {
              lastGeneration.current.nodes?.push(childNode);
            }
          });
        }
        updateArrows(selectedContentIndex);
        updateCounter();
        setRefetchAiUnit((prevState) => prevState + 1);
      }

      if (!!generatedContents?.[0]?.html) {
        if (
          generatedContents[selectedContentIndex].cancelled ||
          generatedContents[selectedContentIndex].reported
        ) {
          blockFeedback();
        } else {
          allowFeedback();
        }
      } else {
        blockFeedback();
        updateCounter();
        blockFooterButtons(handleCancel);
      }
    }
  }, [generatedContents]);

  function setGeneratedContentFactCheckLoading({ streamId }: { streamId: string }) {
    if (aiWritingContent?.current) {
      setGeneratedContents((prevState) => {
        if (prevState && prevState.length > 0) {
          const targetIndex = prevState.findIndex(
            (generatedContent) => generatedContent.streamId === streamId
          );
          if (targetIndex === -1) return prevState;
          prevState[targetIndex].factCheckLoading = true;
          return prevState;
        } else {
          return prevState;
        }
      });
    }
  }

  function updateGeneratedContentFactCheck({
    streamId,
    facts,
  }: {
    streamId: string;
    facts: Fact[];
  }) {
    if (aiWritingContent?.current) {
      setGeneratedContents((prevState) => {
        if (prevState && prevState.length > 0) {
          const targetIndex = prevState.findIndex(
            (generatedContent) => generatedContent.streamId === streamId
          );
          if (targetIndex === -1) return prevState;
          prevState[targetIndex].factCheckLoading = false;
          prevState[targetIndex].facts = facts;
          return prevState;
        } else {
          return prevState;
        }
      });
    }
  }

  function handleCloseAiWritingWrapper() {
    editorRef.current.mode.set('design');
    inputTitleRef.current.disabled = false;
    if (textareaMetaDescriptionRef.current) textareaMetaDescriptionRef.current.disabled = false;
    setGeneratedContents([]);
    setPopperTooltip({
      content: null,
      position: null,
    });
    handleCancelAiWriting();
    handleCancelFactChecking();
    closeAiWriting();
  }

  function handleRegenerate() {
    setRefetchAiUnit((prevState) => prevState + 1);
    if (lastGeneration.current.type) {
      lastGeneration.current = {
        ...lastGeneration.current,
        isRegenerate: true,
        nodes: [],
      };
      regenerateFn.current?.();
    }
  }

  function setCorrectRegenerateFunction() {
    switch (lastGeneration.current.type) {
      case GENERATION_TITLE_TYPE:
        regenerateFn.current = () => handleGenerateTitle(true);
        break;
      case GENERATION_METADESCRIPTION_TYPE:
        regenerateFn.current = () => handleGenerateMetaDescription(true);
        break;
      case GENERATION_INTRODUCTION_TYPE:
        regenerateFn.current = () => handleGenerateIntroduction(true);
        break;
      case GENERATION_PARAGRAPH_TYPE:
        regenerateFn.current = () => handleGenerateParagraph(true);
        break;
      case GENERATION_OUTLINE_TYPE:
        regenerateFn.current = () => handleGenerateOutlines(true);
        break;
      default:
        break;
    }
  }

  function handleGenerateTitle(isRegenerating = false) {
    lastGeneration.current.type = GENERATION_TITLE_TYPE;
    trackMixpanelEvent(isRegenerating ? AI_PLUS_CONTENT_REGENERATE : AI_PLUS_CONTENT_GENERATE, {
      is_fact_checking: isAiFactCheckedCondition,
      type: GENERATION_TITLE_TYPE,
    });
    setCorrectRegenerateFunction();
    launchTitle();
  }
  function handleGenerateMetaDescription(isRegenerating = false) {
    lastGeneration.current.type = GENERATION_METADESCRIPTION_TYPE;
    trackMixpanelEvent(isRegenerating ? AI_PLUS_CONTENT_REGENERATE : AI_PLUS_CONTENT_GENERATE, {
      is_fact_checking: isAiFactCheckedCondition,
      type: GENERATION_METADESCRIPTION_TYPE,
    });
    setCorrectRegenerateFunction();
    launchMetaDescription();
  }

  function handleGenerateIntroduction(isRegenerating = false) {
    lastGeneration.current.type = GENERATION_INTRODUCTION_TYPE;
    trackMixpanelEvent(isRegenerating ? AI_PLUS_CONTENT_REGENERATE : AI_PLUS_CONTENT_GENERATE, {
      is_fact_checking: isAiFactCheckedCondition,
      type: GENERATION_INTRODUCTION_TYPE,
    });
    setCorrectRegenerateFunction();
    launchIntroduction();
  }
  function handleGenerateParagraph(isRegenerating = false) {
    lastGeneration.current.type = GENERATION_PARAGRAPH_TYPE;
    trackMixpanelEvent(isRegenerating ? AI_PLUS_CONTENT_REGENERATE : AI_PLUS_CONTENT_GENERATE, {
      is_fact_checking: isAiFactCheckedCondition,
      type: GENERATION_PARAGRAPH_TYPE,
    });
    setCorrectRegenerateFunction();
    launchParagraph();
  }
  function handleGenerateOutlines(isRegenerating = false) {
    lastGeneration.current.type = GENERATION_OUTLINE_TYPE;
    trackMixpanelEvent(isRegenerating ? AI_PLUS_CONTENT_REGENERATE : AI_PLUS_CONTENT_GENERATE, {
      is_fact_checking: isAiFactCheckedCondition,
      type: GENERATION_OUTLINE_TYPE,
    });
    setCorrectRegenerateFunction();
    launchOutline();
  }

  useEffect(() => {
    setCorrectRegenerateFunction();
    regenerateCreditsCountsRef.current = getAiWritingCredit(lastGeneration?.current?.type);
  }, [factCheckingEnabled]);

  return {
    actions: {
      handleGenerateIntroduction,
      handleGenerateMetaDescription,
      handleGenerateOutlines,
      handleGenerateParagraph,
      handleGenerateTitle,
    },
    isStreamContentGenerating: loading,
    popperTooltip,
  };
}

export default useStreamContent;
