import { useQuery } from '@tanstack/react-query';

import apiClient from '@/apis/semji/apiClient';
import { SCOPE_CONTENTS } from '@/apis/semji/constants';

import { UseGetContentVersionsProps } from './useGetContentVersions.types';

export default function useGetContentVersions({ contentId, ...props }: UseGetContentVersionsProps) {
  const { get } = apiClient;
  return useQuery({
    enabled: !!contentId,
    queryFn: async ({ signal }) => {
      const { data } = await get(`/contents/${contentId}/versions`, {
        signal,
      });

      return data['hydra:member'] || [];
    },
    queryKey: [SCOPE_CONTENTS.GET_CONTENT_VERSIONS, contentId],
    ...props,
  });
}
