import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';

import BlurredHeatMapDetail from '@/containers/Content/SidePanel/Components/Optimization/Section/Credits/BlurredHeatMapDetail';
import BlurredIncomingLink from '@/containers/Content/SidePanel/Components/Optimization/Section/Credits/BlurredIncomingLink';
import BlurredSearchIntent from '@/containers/Content/SidePanel/Components/Optimization/Section/Credits/BlurredSearchIntent';
import {
  HEAT_MAP_DETAIL,
  INCOMING_LINK,
  SEARCH_INTENT,
} from '@/containers/Content/SidePanel/Components/Optimization/Section/Credits/constants';
import UnlockRecommendationsOverlay from '@/containers/Content/SidePanel/Components/Optimization/Section/Credits/UnlockRecommendationsOverlay';
import {
  getApiCreditType,
  getMessage,
  getTooltipMessage,
} from '@/containers/Content/SidePanel/Components/Optimization/Section/Credits/utils';
import { openCreditLimitDialog } from '@/store/actions/report';
import { unlockRecommendations } from '@/store/actionsCreator/report';
import useParamSelector from '@/store/hooks/useParamSelector';
import { selectCredits } from '@/store/selectors/selectCredits';

const Wrapper = styled.div`
  position: relative;
  margin-right: 50px;
  overflow: hidden;
`;

const getRowsToDisplay = (type, amountToUnlock, isFirstLoad) => {
  let result;

  switch (type) {
    case HEAT_MAP_DETAIL:
      result = isFirstLoad ? 10 : 5;
      break;
    case SEARCH_INTENT:
    case INCOMING_LINK:
    default:
      result = 5;
  }

  return result > amountToUnlock ? amountToUnlock : result;
};

const getBlurredComponent = (type) => {
  switch (type) {
    case SEARCH_INTENT:
      return BlurredSearchIntent;
    case HEAT_MAP_DETAIL:
      return BlurredHeatMapDetail;
    case INCOMING_LINK:
      return BlurredIncomingLink;
    default:
      return null;
  }
};

function BlurredRecommendations({ amountToUnlock, columns = 1, keywordId, type }) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const apiCreditType = getApiCreditType(type);
  const remainingCredits = useParamSelector(selectCredits, apiCreditType, 'remaining');
  const totalCredits = useParamSelector(selectCredits, apiCreditType, 'total');

  const maxAmountToUnlock = amountToUnlock > 10 ? 10 : amountToUnlock;
  const rows = getRowsToDisplay(type, maxAmountToUnlock, isFirstLoad);
  const message = getMessage(type, maxAmountToUnlock, remainingCredits, isFirstLoad);
  const tooltipMessage = getTooltipMessage(type, remainingCredits, totalCredits);
  const BlurredRecommendation = getBlurredComponent(type);

  const blurredItems = useMemo(
    () =>
      Array(rows)
        .fill(null)
        .map(() => {
          const uuid = nanoid();
          return <BlurredRecommendation key={uuid} columns={columns} />;
        }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [keywordId, rows, type]
  );

  async function handleClick() {
    if (remainingCredits > 0) {
      const numberOfItemsToUnlock =
        maxAmountToUnlock > remainingCredits ? remainingCredits : maxAmountToUnlock;

      setIsLoading(true);
      await dispatch(unlockRecommendations(keywordId, apiCreditType, numberOfItemsToUnlock));
      setIsFirstLoad(false);
      setIsLoading(false);
    } else {
      dispatch(openCreditLimitDialog(apiCreditType));
    }
  }

  return maxAmountToUnlock > 0 ? (
    <Wrapper>
      {blurredItems}
      <UnlockRecommendationsOverlay
        isLoading={isLoading}
        message={message}
        tooltip={tooltipMessage}
        onClick={handleClick}
      />
    </Wrapper>
  ) : null;
}

BlurredRecommendations.propTypes = {
  amountToUnlock: PropTypes.number,
  columns: PropTypes.number,
  keywordId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default BlurredRecommendations;
