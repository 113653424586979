import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import PhoneNumberInput from '@/components/PhoneNumber';
import UserService from '@/services/User';
import { showErrorSnackbar, showSuccessSnackbar } from '@/store/actions/ui';
import { setUserPhoneNumber } from '@/store/actions/user';

function Phone({ user }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const _userService = new UserService();
  const [phone, setPhone] = useState({
    format: '',
    formattedValue: '',
    value: user.phoneNumber,
  });

  const onPhoneNumberChange = (value, country, e, formattedValue) => {
    setPhone({
      format: country.format,
      formattedValue,
      value,
    });
  };

  const isPhoneNumberValid = () => {
    // the first mount we do not have the formatted value with the country code
    // we assure that the number is a valid phone number in mount
    if (0 === phone.formattedValue.length && 0 === phone.format.length) {
      return true;
    }
    return (
      !!phone.formattedValue &&
      !!phone.format &&
      phone.formattedValue.length === phone.format.length
    );
  };

  const saveChanges = async () => {
    if (user.phoneNumber !== phone.value) {
      try {
        await _userService.setUserInfo(user.id, { phoneNumber: phone.value });
        dispatch(
          showSuccessSnackbar(
            t('settings:workspace.personal-info-phone.snackbar-phone-change-success')
          )
        );
        dispatch(setUserPhoneNumber(phone.value));
      } catch (e) {
        dispatch(
          showErrorSnackbar(t('settings:workspace.personal-info-phone.snackbar-phone-change-error'))
        );
      }
    }
  };

  return (
    <PhoneNumberInput
      isValid={isPhoneNumberValid()}
      phoneObject={phone}
      placeholder={t('settings:workspace.personal-info-phone.input-phone-placeholder')}
      onBlur={saveChanges}
      onChange={onPhoneNumberChange}
    />
  );
}

export default Phone;
