import { nanoid } from 'nanoid';
import React, { useMemo } from 'react';

import SvgWrapper from './SvgWrapper';

const SidePanelResizeIcon = (props) => {
  const maskId = useMemo(() => nanoid(), []);
  return (
    <SvgWrapper {...props} fill="none" height="20" viewBox="0 0 18 20" width="18">
      <mask
        height="9"
        id={`mask${maskId}`}
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="18"
        x="0"
        y="5"
      >
        <path
          d="M17.6994 9.49998L13.5128 13.5849L12.7737 12.8268L15.64 10.0294H2.36019L5.22749 12.8268L4.48843 13.5849L0.300781 9.49998L4.48737 5.41504L5.22643 6.17316L2.36019 8.97057H15.641L12.7737 6.17316L13.5128 5.41504L17.6994 9.49998Z"
          fill="black"
        />
      </mask>
      <g mask={`url(#mask${maskId})`}>
        <rect fill="currentColor" height="21.1765" width="21.1765" x="-2.11719" y="-0.558838" />
      </g>
    </SvgWrapper>
  );
};

export default SidePanelResizeIcon;
