import React from 'react';

import SvgWrapper from './SvgWrapper';

const WarningIcon = (props) => (
  <SvgWrapper height="19" viewBox="0 0 19 19" width="19" {...props}>
    <circle cx="9.5" cy="9.5" fill="#FF8F54" r="9.5" />
    <mask
      height="12"
      id="mask__0"
      maskUnits="userSpaceOnUse"
      style={{ maskType: 'alpha' }}
      width="13"
      x="3"
      y="2"
    >
      <path
        d="M9.42169 2L3 14H16L9.42169 2ZM9.42169 3.65517L14.5904 13.1724H4.33133L9.42169 3.65517ZM9.89157 9.77931H9.10843V6.55172H9.89157V9.77931ZM10.2831 11.5172C10.2831 12.0138 9.96988 12.3448 9.5 12.3448C9.03012 12.3448 8.71687 12.0138 8.71687 11.5172C8.71687 11.0207 9.03012 10.6897 9.5 10.6897C9.96988 10.6897 10.2831 11.1034 10.2831 11.5172Z"
        fill="black"
      />
    </mask>
    <g mask="url(#mask__0)">
      <rect fill="white" height="16.5517" width="15.6627" x="1.43359" y="1.17236" />
    </g>
  </SvgWrapper>
);

export default WarningIcon;
