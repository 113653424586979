export const ADD_KEYWORD_OPTION_ID = 'action_add_new_focus_keyword';
export const MAX_KEYWORD_LENGTH = 255;
export const EDITOR_FK = 'editor-fk';
export const TOP_PAGE_FK = 'top-page-fk';
export const PAGES_FK = 'pages-fk';
export const STYLE = {
  [EDITOR_FK]: {
    input: {
      color: 'inherit',
      padding: '0 !important',
    },
    inputRoot: {
      '&:hover': {
        backgroundColor: 'transparent',
        borderColor: '#7C7D86',
      },
      background: '#F4F4F5',
      border: '1px solid #E9E9EB',
      borderRadius: '8px',
      color: '#252736',
      fontSize: '14px',
      height: '34px',
      padding: '3px 10px !important',
    },
    option: {
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      '&[data-focus="true"]': {
        backgroundColor: 'rgba(255,77,100,0.05)',
      },
      overflow: 'hidden',
      padding: 0,
    },
    paper: {
      width: 'calc(100% + 50px)',
    },
    popupIndicator: {
      border: 0,
      display: 'none',
    },
    root: {
      width: '100%',
    },
  },
  [PAGES_FK]: {
    endAdornment: {
      '& > *': {
        fontSize: '1em',
      },
      paddingLeft: '5px',
      position: 'relative',
      right: 'auto',
      top: 'auto',
    },
    input: {
      color: 'inherit',
    },
    inputRoot: {
      '&:hover': {
        borderColor: 'transparent',
      },
      alignItems: 'center',
      backgroundColor: 'transparent',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      display: 'flex',
      fontSize: '0.95em',
      height: '44px',
      padding: '0 !important',
    },
    option: {
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      '&[data-focus="true"]': {
        backgroundColor: 'rgba(255,77,100,0.05)',
      },
      padding: 0,
    },
    paper: {
      margin: '10px 0px',
    },
    popper: {
      width: 'fit-content',
    },
    root: {
      width: '100%',
    },
  },
  [TOP_PAGE_FK]: {
    input: {
      color: '#f04f65',
      padding: '0 !important',
    },
    inputRoot: {
      '&:hover': {
        borderColor: 'rgba(0, 0, 0, 0.3)',
      },
      background: 'white',
      border: '1px solid #E9E9EB',
      borderRadius: '5px',
      fontSize: '0.95em',
      height: '40px',
      padding: '3px 10px !important',
    },
    option: {
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      '&[data-focus="true"]': {
        backgroundColor: 'rgba(255,77,100,0.05)',
      },
      padding: 0,
    },
    paper: {
      margin: '10px 0px',
    },
    popupIndicator: {
      border: 0,
      display: 'none',
    },
    root: {
      minWidth: '380px',
      width: '100%',
    },
  },
};
