import { nanoid } from 'nanoid';
import React, { useMemo } from 'react';

import SvgWrapper from './SvgWrapper';

const SidePanelResizeIcon = (props) => {
  const maskId = useMemo(() => nanoid(), []);
  return (
    <SvgWrapper {...props} fill="none" height="19" viewBox="0 0 18 19" width="18">
      <g clip-path="url(#clip0_3109_17457)">
        <mask
          height="9"
          id={`mask${maskId}`}
          maskUnits="userSpaceOnUse"
          style={{ maskType: 'alpha' }}
          width="19"
          x="0"
          y="5"
        >
          <path
            d="M0.0012707 8.97073L7.17057 8.97073L4.30328 6.17332L5.04233 5.4152L9.22998 9.50014L5.04339 13.5851L4.30433 12.827L7.17057 10.0296L0.000211073 10.0296L0.0012707 8.97073Z"
            fill="black"
          />
          <path
            d="M18.4572 10.0295H11.2879L14.1552 12.8269L13.4162 13.585L9.22852 9.5001L13.4151 5.41516L14.1542 6.17328L11.2879 8.97069L18.4583 8.97069L18.4572 10.0295Z"
            fill="black"
          />
        </mask>
        <g mask={`url(#mask${maskId})`}>
          <rect fill="currentColor" height="21.1765" width="21.1765" x="-2.11768" y="-0.558838" />
        </g>
      </g>
    </SvgWrapper>
  );
};

export default SidePanelResizeIcon;
