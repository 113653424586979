import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Toolbar from '@material-ui/core/Toolbar';
import styled from 'styled-components/macro';

import {
  ONLY_LOGO_WHITE_BACKGROUND,
  ONLY_LOGO_WHITE_BACKGROUND_AND_ONBOARDING,
  TRANSPARENT_LOGO_AND_PROFILE_MENU,
} from './Layout';

const Bar = styled(({ variant, ...props }) => <Toolbar {...props} />)`
  && {
    min-height: 56px;
    display: flex;
    align-items: stretch;
    padding: ${(props) =>
      [ONLY_LOGO_WHITE_BACKGROUND, ONLY_LOGO_WHITE_BACKGROUND_AND_ONBOARDING].includes(
        props.variant
      )
        ? '1rem 3.5rem'
        : '0 2rem'};
  }
  && {
    ${(props) => props.theme.mediaQueries.tablet} {
      padding-left: 32px;
      padding-right: 32px;
    }
  }
`;

export const SettingsSidePanelTitle = styled.div`
  font-weight: ${(props) => props.theme.textCss.weights.strong};
  height: 35px;
  font-size: ${(props) => props.theme.textCss.sizes.default};
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: ${(props) => props.theme.cssColors.dark060};
`;

export const SettingsSidePanelCategoryTitle = styled(SettingsSidePanelTitle)`
  && {
    font-weight: ${(props) => props.theme.textCss.weights.strong};
    font-size: ${(props) => props.theme.textCss.sizes.sm};
    text-transform: uppercase;
    padding-bottom: 0;
    &:first-child {
      padding-top: 0;
    }
  }
`;

export const ListingHeaderContainer = styled.div`
  display: flex;
  min-height: 35px;
  justify-content: space-between;
  align-items: center;
  margin: ${({ margin }) => margin};
`;

export const ListingStyledDivider = styled(Divider)`
  && {
    margin-top: 10px;
    margin-bottom: 20px;
    width: 100%;
  }
`;

const CustomAppBar = styled(({ children, userImpersonate, variant, ...otherProps }) => (
  <AppBar color="primary" {...otherProps}>
    <Bar variant={variant}>{children}</Bar>
  </AppBar>
))`
  && {
    position: relative;
    background: ${(props) => props.theme.cssColors.dark100};
    background: ${(props) =>
      [
        TRANSPARENT_LOGO_AND_PROFILE_MENU,
        ONLY_LOGO_WHITE_BACKGROUND,
        ONLY_LOGO_WHITE_BACKGROUND_AND_ONBOARDING,
      ].includes(props.variant) && 'transparent'};
    background: ${(props) => props.userImpersonate && props.theme.cssColors.primary080};
    border-bottom: ${(props) =>
      [ONLY_LOGO_WHITE_BACKGROUND, ONLY_LOGO_WHITE_BACKGROUND_AND_ONBOARDING].includes(
        props.variant
      ) && `1px solid ${props.theme.cssColors.dark010}`};
    box-shadow: none;
  }
`;

export default CustomAppBar;
