import { useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';

import {
  DEFAULT_BACKGROUND,
  NO_MENU_LAYOUT,
  TRANSPARENT_LOGO_AND_PROFILE_MENU,
} from '@/components/Layout/Layout';
import { LayoutLoader } from '@/components/Loader/Loader';
import LayoutWrapper from '@/containers/Layout/Layout';
import PermissionBox from '@/containers/Workspace/PermissionBox';
import Can from '@/hoc/Can';
import useParamSelector from '@/store/hooks/useParamSelector';
import { selectCurrentHub } from '@/store/selectors/selectCurrentHub';
import { selectWorkspaceIsOnboarding } from '@/store/selectors/selectWorkspaceIsOnboarding';
import { SemjiHub } from '@/types/common.types';
import { isDashboardRouteStillAccessible, isRouteAccessGranted } from '@/utils/billing';
import { SUPER_ADMIN_PERFORM_ACTION } from '@/utils/can/constants';
import { getDefaultWorkspaceId } from '@/utils/helper';

function DefaultWorkspace() {
  const { organizationId } = useParams();
  const currentOrganization = useSelector((state) => state.organizations.byId[organizationId]);
  const defaultWorkspaceId = useSelector((state) => state.defaultWorkspaceId) ?? null;
  const workspaceAccesses = useSelector((state) => state.user?.workspaceAccesses) ?? [];
  const currentHub = useSelector(selectCurrentHub);
  const defaultId = getDefaultWorkspaceId(
    defaultWorkspaceId,
    workspaceAccesses,
    currentOrganization,
    organizationId
  );
  const defaultWorkspaceIsInQueue = useSelector(
    (state) =>
      state.organizations.byId[organizationId]?.workspaces?.find(
        (workspace) => workspace.id === defaultId
      )?.isInQueue
  );
  const currentWorkspaceIsOnboarding = useParamSelector(
    selectWorkspaceIsOnboarding,
    organizationId,
    defaultId
  );

  if (!currentOrganization || !currentOrganization.id) {
    return (
      <LayoutWrapper menu={NO_MENU_LAYOUT} variant={DEFAULT_BACKGROUND}>
        <LayoutLoader />
      </LayoutWrapper>
    );
  }
  if (!isDashboardRouteStillAccessible(currentOrganization)) {
    return <Navigate to={`/o/${currentOrganization.id}/workspace-access-blocked`} />;
  }

  if (null === defaultId && !isRouteAccessGranted(currentOrganization)) {
    return <Navigate to={`/o/${currentOrganization.id}/workspace-access-blocked`} />;
  }

  if (defaultId === null) {
    return (
      <Can
        no={() => (
          <LayoutWrapper menu={TRANSPARENT_LOGO_AND_PROFILE_MENU} variant={DEFAULT_BACKGROUND}>
            <PermissionBox
              organizationId={organizationId}
              organizationName={currentOrganization?.name ?? ''}
            />
          </LayoutWrapper>
        )}
        perform="organization-admin-settings:visit"
        yes={() => <Navigate to={`/o/${currentOrganization.id}/create-workspace`} />}
      />
    );
  }

  if (defaultWorkspaceIsInQueue) {
    return (
      <Can
        no={() => <Navigate to={`/o/${currentOrganization.id}/w/${defaultId}/in_queue`} />}
        perform={SUPER_ADMIN_PERFORM_ACTION}
        yes={() => <Navigate to={`/o/${currentOrganization.id}/w/${defaultId}/dashboard`} />}
      />
    );
  }
  if (currentHub === SemjiHub.EXECUTIVE) {
    return <Navigate to={`/o/${currentOrganization.id}/w/${defaultId}/search-intelligence`} />;
  }

  return (
    <Navigate
      to={`/o/${currentOrganization.id}/w/${defaultId}/${
        currentWorkspaceIsOnboarding ? 'onboarding' : 'dashboard'
      }`}
    />
  );
}

export default DefaultWorkspace;
