import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import CheckAltIcon from '@/components/icons/CheckAltIcon';
import DeleteAltIcon from '@/components/icons/DeleteAltIcon';
import TooltipComponent from '@/components/Tooltip/Tooltip';

const StyledCheckAltIcon = styled(CheckAltIcon)`
  && {
    font-size: 1.5rem;
  }
`;

const StyledDeleteAltIcon = styled(DeleteAltIcon)`
  && {
    font-size: 1.5rem;
  }
  &:hover {
    background-color: ${({ theme }) => theme.cssColors.dark010};
    border-radius: 50%;
  }
`;

function PlannedCell({ onDelete }) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  function onOpen() {
    setIsOpen(true);
  }

  function onClose() {
    setIsOpen(false);
  }

  function onClick(e) {
    e.stopPropagation();
    onDelete();
  }

  return (
    <TooltipComponent
      cursor="pointer"
      delay={50}
      placement="top"
      title={t(`components:add-to-planning-cell.remove`)}
      onClick={onClick}
      onClose={onClose}
      onOpen={onOpen}
    >
      {isOpen ? <StyledDeleteAltIcon /> : <StyledCheckAltIcon />}
    </TooltipComponent>
  );
}

PlannedCell.propTypes = {
  onDelete: PropTypes.func,
};

export default PlannedCell;
