import './LaunchTour.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { ENUM_ICON_BUTTON_ROUNDING, IconButton } from 'semji-core/components/Button/IconButton';
import { Tooltip } from 'semji-core/components/Tooltip';
import { ProductTourIcon } from 'semji-core/icons/ProductTourIcon';

import { LaunchTourProps } from './LaunchTour.types';

function LaunchTour({
  withoutTooltip = false,
  noMargin = false,
  backgroundColor,
  children,
  ...props
}: LaunchTourProps) {
  const { t } = useTranslation();

  const id = props['data-intercom-target'];
  return (
    <IconButton
      className={`launch-tour-button launch-tour-button--${noMargin && 'no-margin'} launch-tour-button--${withoutTooltip && 'no-tooltip'}`}
      id={id}
      roundedStyle={ENUM_ICON_BUTTON_ROUNDING.Rounded}
      style={{
        backgroundColor: backgroundColor ?? 'none',
        display: window[`is_${id}_enabled`] ? 'flex' : 'none',
      }}
      {...props}
    >
      {withoutTooltip ? (
        <>
          <ProductTourIcon />
          <p className="launch-tour-button__label">{t('components:badge.launch-tour.text')}</p>
        </>
      ) : (
        <Tooltip
          className="launch-tour-button__icon"
          title={t('components:badge.launch-tour.title')}
        >
          <ProductTourIcon className="launch-tour-button__icon--huge" />
        </Tooltip>
      )}
    </IconButton>
  );
}

export default LaunchTour;
