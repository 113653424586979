import React from 'react';
import { NavLink } from 'react-router-dom';

import { PlanRestrictionBadge } from '@/components/Badge/PlanRestrictionBadge';
import { OrganizationFeatureSet } from '@/hoc/OrganizationFeatureSet';

import { MenuItemType } from './Menu.types';

export function MenuItem({
  label,
  type,
  intercomTarget,
  subLabel,
  feature,
  disabled,
  handleClick,
  to,
  ...props
}: MenuItemType) {
  if (type === 'link') {
    return (
      <NavLink
        className={`layout-menu__item ${disabled && 'layout-menu__item--disabled'}`}
        data-intercom-target={intercomTarget}
        to={disabled ? null : to}
        onClick={handleClick}
        {...props}
      >
        <span className="layout-menu__item__label">{label}</span>
        {feature && (
          <OrganizationFeatureSet
            feature={feature}
            no={() => (
              <PlanRestrictionBadge className="layout-menu__item__restriction-badge" isCondensed />
            )}
          />
        )}
      </NavLink>
    );
  } else {
    return label;
  }
}

export function SubMenuItem({ handleClick, icon, label, to, type, subLabel }: MenuItemType) {
  if (type === 'link') {
    return (
      <NavLink className="layout-menu__sub-menu__item" to={to} onClick={handleClick}>
        {icon}
        {label}
      </NavLink>
    );
  } else {
    return subLabel ?? label;
  }
}
