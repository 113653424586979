import './Profile.scss';

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { DialogModal } from 'semji-core/components/DialogModal';

import ProfileButton from '@/components/Button/ProfileButton';
import WorkspaceLimitReached from '@/components/EmptyState/WorkspaceLimitReached';
import {
  Divider,
  ProfileBoxHeader,
  SettingsItem,
  SignOutItem,
  StopImpersonatingItem,
} from '@/components/Profile/ProfileBox';
import Selector, { SelectorBox } from '@/components/Selector';
import OrganizationSelector from '@/containers/Layout/Profile/OrganizationSelector';
import WorkspaceSelector from '@/containers/Layout/Profile/WorkspaceSelector';
import { useMixpanelTrackEvent } from '@/hooks/useMixpanelTrackEvent';
import { fetchAuthenticatedUser, logoutUser } from '@/store/actions/user';
import { getOrganizationById, getWorkspaceById } from '@/store/reducers';
import { SELECT_ORGANIZATION_TOP_NAV_CLICK } from '@/utils/3rdParty/Mixpanel/constants';
import { COOKIE_IMPERSONATE_KEY_NAME, getCookie, removeCookieByKey } from '@/utils/cookies/cookies';

function Profile(props) {
  const navigate = useNavigate();
  const { organizationId, workspaceId } = useParams();
  const dispatch = useDispatch();
  const [maxWorkspacesAchievedShow, setMaxWorkspacesAchievedShow] = useState(false);
  const currentOrganization = useSelector((state) => getOrganizationById(state, organizationId));
  const currentWorkspace = useSelector((state) => getWorkspaceById(state, workspaceId));
  const user = useSelector((state) => state.user);
  const uiMode = useSelector((state) => state.uiMode);
  const trackMixpanelEvent = useMixpanelTrackEvent();

  const userImpersonate = getCookie(COOKIE_IMPERSONATE_KEY_NAME);

  const unlimitedWorkspaces =
    currentOrganization?.featureSet['organization:workspaces:has-unlimited-amount'];

  async function stopImpersonating() {
    removeCookieByKey(COOKIE_IMPERSONATE_KEY_NAME);
    await dispatch(fetchAuthenticatedUser());
    navigate('/', { replace: true });
  }

  function logout() {
    dispatch(logoutUser());
  }

  function ButtonRender({ openMenu }) {
    return (
      <ProfileButton
        currentWorkspace={currentWorkspace}
        isAdminMode={uiMode === 'advanced'}
        user={user}
        userImpersonate={userImpersonate}
        onClick={openMenu}
        {...props}
      />
    );
  }

  return (
    <>
      <Selector button={ButtonRender}>
        {(selectorProps) => (
          <SelectorBox {...selectorProps}>
            <ProfileBoxHeader user={user} />
            <Divider />
            <OrganizationSelector
              handleClick={() => {
                trackMixpanelEvent(SELECT_ORGANIZATION_TOP_NAV_CLICK);
              }}
              organizationAccesses={user?.organizationAccesses ?? []}
              organizationId={organizationId}
              organizationName={currentOrganization?.name}
              onClose={selectorProps.onClose}
            />
            <WorkspaceSelector
              maxAllowedWorkspacesReached={
                !unlimitedWorkspaces &&
                (currentOrganization?.workspacesCount ?? 0) >=
                  (currentOrganization?.featureSet['organization:workspaces:allowed-amount'] ?? 0)
              }
              organizationId={organizationId}
              organizationWorkspaces={currentOrganization?.workspaces ?? []}
              setMaxWorkspacesAchievedShow={setMaxWorkspacesAchievedShow}
              workspaceAccesses={user?.workspaceAccesses ?? []}
              workspaceId={workspaceId}
              onClose={selectorProps.onClose}
            />
            <Divider />
            <SettingsItem organizationId={organizationId} workspaceId={workspaceId} />
            {userImpersonate && <StopImpersonatingItem onClick={stopImpersonating} />}
            <SignOutItem onClick={logout} />
          </SelectorBox>
        )}
      </Selector>
      <DialogModal
        className="profile-dialog-modal"
        closable
        isOpen={maxWorkspacesAchievedShow}
        onClose={() => setMaxWorkspacesAchievedShow(false)}
      >
        <WorkspaceLimitReached />
      </DialogModal>
    </>
  );
}

export default Profile;
