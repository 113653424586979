import { GridApi } from 'ag-grid-community';
import React, { useRef, useState } from 'react';

import { UseGridSelectionResults } from '@/components/AGGrid/hooks/useGridSelection/useGridSelection.types';

export function useGridSelection<Entity>(): UseGridSelectionResults<Entity> {
  const [selection, setSelection] = useState<Entity[]>([]);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  const gridApiRef: React.MutableRefObject<GridApi> = useRef<any | GridApi>(null);

  function initGridSelection(gridApi: GridApi) {
    gridApiRef.current = gridApi;
  }

  function onSelect(select: Entity[]) {
    if (isAllSelected && selection.length > select.length) {
      setIsAllSelected(false);
    }
    setSelection(select);
  }

  function selectAll() {
    setIsAllSelected(true);
    gridApiRef.current.selectAllOnCurrentPage();
  }

  function deselectAll() {
    setSelection([]);
    setIsAllSelected(false);
    gridApiRef.current.deselectAllOnCurrentPage();
  }

  return {
    deselectAll,
    initGridSelection,
    isAllSelected,
    onSelect,
    selectAll,
    selection,
  };
}
