import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DialogModal } from 'semji-core/components/DialogModal';
import { DROPDOWN_SIZES } from 'semji-core/components/Dropdown';
import { FolderSelect } from 'semji-core/components/FolderSelect';
import { StatusSelect } from 'semji-core/components/StatusSelect';
import styled from 'styled-components/macro';

import useGetCurrentWorkspaceAllFolders from '@/apis/semji/folders/useGetCurrentWorkspaceAllFolders';
import usePostFolder from '@/apis/semji/folders/usePostFolder';
import DatePicker from '@/components/DatePicker';
import { getDefaultFolderGeneral } from '@/components/Select/FolderSelect/constants';
import UserSelect from '@/components/Select/UserSelect';
import { Error } from '@/components/Text/Error';
import { BlockText } from '@/components/Text/Inline';
import useNullUser from '@/hooks/useNullUser';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import useWorkspaceStatuses from '@/hooks/useWorkspaceStatuses';
import useWorkspaceUsers from '@/hooks/useWorkspaceUsers';
import { getOrganizationById } from '@/store/reducers';

const Wrapper = styled.div`
  max-width: 400px;
`;
export const StyledBlockText = styled(BlockText)`
  margin-bottom: 20px;
  line-height: 30px;
`;
const ErrorWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;
const Content = styled.div`
  && {
    display: flex;
    flex-direction: column;

    & > div {
      width: 100%;
      margin-bottom: 25px;
    }

    & > div:last-child {
      margin-bottom: 0;
    }

    & > div:hover {
      background-color: transparent;
    }
  }
`;

function AddToPlanningDialog({ open, onClose, loading, error, onConfirm, header }) {
  const { t } = useTranslation();
  const DEFAULT_FOLDER_GENERAL = getDefaultFolderGeneral();
  const nullUser = useNullUser();

  const [assignedToId, setAssignedToId] = useState(null);
  const [dueDate, setDueDate] = useState(null);
  const [contentStatusId, setContentStatusId] = useState(null);
  const [folderId, setFolderId] = useState(DEFAULT_FOLDER_GENERAL.id);
  const [isFolderUpdated, setIsFolderUpdate] = useState(false);

  const users = useWorkspaceUsers();
  const statuses = useWorkspaceStatuses();
  const {
    data: folders,
    remove: removeFolderCache,
    refetch: refecthFolders,
  } = useGetCurrentWorkspaceAllFolders();

  const { organizationId } = useParams();
  const currentOrganization = useSelector((state) => getOrganizationById(state, organizationId));
  const { isFeatureEnabled: unlimitedFoldersFeatureSet } = useOrganizationFeatureSet(
    'organization:folders:has-unlimited-amount'
  );
  const { data: foldersLimit = 0 } = useOrganizationFeatureSet('organization:folders:amount');
  const isOrganizationFoldersLimit =
    currentOrganization.foldersCount >= foldersLimit && !unlimitedFoldersFeatureSet;
  const createNewFolder = usePostFolder({
    onSuccess: async () => {
      refecthFolders();
    },
  });

  function handleNewFolderCreation(newFolder) {
    createNewFolder.mutate(newFolder);
  }

  // reset to default values after each close
  useEffect(() => {
    if (!open) {
      setAssignedToId(null);
      setDueDate(null);
      setContentStatusId(null);
      setFolderId(DEFAULT_FOLDER_GENERAL.id);
      setIsFolderUpdate(false);
    }

    // Clean up react-query folders cache.
    return () => {
      removeFolderCache();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const contentStatuses = statuses.filter((status) => !status.publish);

  function handleConfirm() {
    let assignedTo = users.find((user) => user.id === assignedToId);

    if (assignedTo?.id === nullUser.id) {
      assignedTo = null;
    }

    const dueDateValue = DateTime.fromISO(dueDate).isValid ? dueDate : null;

    const folderIdValue = isFolderUpdated ? folderId : null;

    const status = contentStatusId
      ? statuses.find((status) => status.id === contentStatusId)
      : contentStatuses[0];

    onConfirm?.({
      assignedTo,
      contentStatus: status,
      dueDate: dueDateValue,
      folderId: folderIdValue,
    });
  }

  function handleChangeAssignedTo(id) {
    setAssignedToId(id);
  }

  function handleChangeDueDate(date) {
    setDueDate(date);
  }

  function handleChangeContentStatus({ id }) {
    setContentStatusId(id);
  }

  function handleChangeFolderId(id) {
    setFolderId(id);
    setIsFolderUpdate(true);
  }

  return (
    <DialogModal
      cancelAction={onClose}
      cancelLabel={t('components:dialog.add-to-planning.cancel')}
      canClickOutsideToClose
      closable
      confirmAction={handleConfirm}
      confirmLabel={t(
        loading
          ? 'components:dialog.add-to-planning.confirm-loading'
          : 'components:dialog.add-to-planning.confirm'
      )}
      disableEnforceFocus
      error={error}
      id="add-to-planning-dialog__content"
      isConfirmDisabled={loading}
      isOpen={open}
      title={
        <>
          <StyledBlockText color="dark100" size="xl" weight={500}>
            {t('components:dialog.add-to-planning.title')}
          </StyledBlockText>
          {header}
        </>
      }
      onClose={onClose}
    >
      <Wrapper>
        <Content>
          <UserSelect
            key={assignedToId || nullUser.id}
            dropDownPlacement="bottom"
            dropDownSize={DROPDOWN_SIZES.FULL_WIDTH}
            handleChange={handleChangeAssignedTo}
            hovered
            longVersion
            options={users}
            paddingValue={100}
            querySelectorListener="#add-to-planning-dialog__content"
            small
            value={assignedToId || nullUser.id}
            variant="column"
            width={400}
          />
          <DatePicker
            key={dueDate}
            dueDate={dueDate}
            handleChange={handleChangeDueDate}
            isInputFullWidth
          />
          <StatusSelect
            key={contentStatusId || contentStatuses[0]?.id}
            handleChange={handleChangeContentStatus}
            options={contentStatuses}
            value={contentStatusId || contentStatuses[0]?.id}
          />
          <FolderSelect
            key={folderId}
            createNewFolder={handleNewFolderCreation}
            dropDownPlacement="bottom"
            dropDownSize={DROPDOWN_SIZES.FULL_WIDTH}
            folders={folders}
            isCreationDisabled={isOrganizationFoldersLimit}
            isFrame={false}
            querySelectorListener="#add-to-planning-dialog__content"
            setValue={handleChangeFolderId}
            translations={{
              button: t('components:select.folder-select.create-folder.label-button'),
              cancel: t('components:select.folder-select.create-folder.cancel'),
              create_folder: t('components:select.folder-select.folder-select.label-create-folder'),
              folder_general: t('components:select.folder-select.label-folder-general'),
              label_no_parent: t('components:filters.folder-filters-panel.label-no-parent'),
              loading: t('components:select.folder-select.create-folder.label-loading'),
              placeHolder: t('components:select.folder-select.create-folder.input-placeholder'),
              search_placeholder: t(
                'components:select.folder-select.folder-select.search-placeholder'
              ),
              title: t('components:select.folder-select.create-folder.title'),
              tooltip_disabled: t('components:select.folder-select.create-folder.tooltip-disabled'),
            }}
            value={folderId}
            withFolderCreation
          />
        </Content>
        <ErrorWrapper>
          {error && <Error>{t('components:dialog.add-to-planning.error')}</Error>}
        </ErrorWrapper>
      </Wrapper>
    </DialogModal>
  );
}

AddToPlanningDialog.propTypes = {
  header: PropTypes.node,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  open: PropTypes.bool,
};

export default AddToPlanningDialog;
