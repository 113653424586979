import TextField from '@material-ui/core/TextField';
import styled from 'styled-components/macro';

const Input = styled(TextField)`
  && {
    width: ${({ width }) => width};
    height: 50px;

    & > label {
      color: ${({ theme, error }) => (error ? theme.cssColors.red : theme.cssColors.dark100)};
      font-size: ${({ theme }) => theme.textCss.sizes.md};
    }

    & > div > input {
      font-size: ${({ theme }) => theme.textCss.sizes.default};
      color: ${({ theme, value }) => (value ? theme.cssColors.dark100 : theme.cssColors.dark040)};
      padding: 15px 0 7px;
      border-bottom: 1px solid ${({ theme }) => theme.cssColors.dark020};
    }

    & > div::after,
    & > div::before {
      border-bottom: 1px solid ${({ theme }) => theme.cssColors.dark020};
    }

    & > div:hover::after,
    & > div:hover::before {
      border-bottom: 2px solid ${({ theme }) => theme.cssColors.secondary};
    }

    & textarea {
      white-space: nowrap;
      font-size: ${({ theme }) => theme.textCss.sizes.default};
      color: ${({ theme, value }) => (value ? theme.cssColors.dark100 : theme.cssColors.dark040)};
      padding: 15px 0 0;
    }

    & > div > input:focus-visible {
      border-bottom: 2px solid ${({ theme }) => theme.cssColors.secondary};
    }
  }
`;

export default Input;
