import { useTranslation } from 'react-i18next';

import FavIcon from '@/components/FavIcon/FavIcon';
import PrimaryColumn from '@/containers/Content/BriefComponents/Table/Columns/PrimaryColumn';
import SecondaryColumn from '@/containers/Content/BriefComponents/Table/Columns/SecondaryColumn';
import EmptyRow from '@/containers/Content/BriefComponents/Table/Rows/EmptyRow';
import Header from '@/containers/Content/BriefComponents/Table/Rows/Header';
import LoadingRow from '@/containers/Content/BriefComponents/Table/Rows/LoadingRow';
import Row from '@/containers/Content/BriefComponents/Table/Rows/Row';
import Table from '@/containers/Content/BriefComponents/Table/Table';

function CompetitorsTitles({ isLoading, titles }) {
  const { t } = useTranslation();

  return (
    <Table>
      <Header>
        <PrimaryColumn>
          {t('content:brief.section-title-table-competitors-header-primary')}
        </PrimaryColumn>
        <SecondaryColumn>
          {t('content:brief.section-title-table-competitors-header-secondary')}
        </SecondaryColumn>
      </Header>

      {isLoading ? (
        <LoadingRow />
      ) : titles?.length > 0 ? (
        titles.map(({ title, url }) => (
          <Row key={`${title}_${encodeURI(url)}`}>
            <PrimaryColumn>
              <span>{title}</span>
            </PrimaryColumn>
            <SecondaryColumn>
              <FavIcon displayLink={true} url={url} />
            </SecondaryColumn>
          </Row>
        ))
      ) : (
        <EmptyRow />
      )}
    </Table>
  );
}

export default CompetitorsTitles;
