import i18next from 'i18next';

import {
  HEAT_MAP_DETAIL,
  INCOMING_LINK,
  SEARCH_INTENT,
} from '@/containers/Content/SidePanel/Components/Optimization/Section/Credits/constants';
import {
  ANALYSIS_CREDITS_TYPE,
  INCOMING_LINKS_CREDITS_TYPE,
  SEARCH_INTENTS_CREDITS_TYPE,
} from '@/utils/constants';

export const getApiCreditType = (type) => {
  switch (type) {
    case SEARCH_INTENT:
    case HEAT_MAP_DETAIL:
      return SEARCH_INTENTS_CREDITS_TYPE;
    case INCOMING_LINK:
      return INCOMING_LINKS_CREDITS_TYPE;
    default:
      return null;
  }
};

export const getLabel = (type, amount) => {
  const i18nNameSpace = 'content:side-panel-components.optimization.section.credits';

  switch (type) {
    case SEARCH_INTENT:
    case HEAT_MAP_DETAIL:
      return i18next.t(`${i18nNameSpace}.search-intent_interval`, {
        count: amount,
        postProcess: 'interval',
      });
    case INCOMING_LINK:
      return i18next.t(`${i18nNameSpace}.incoming-link_interval`, {
        count: amount,
        postProcess: 'interval',
      });
    default:
      return null;
  }
};

export const getMessage = (type, amountToUnlock, remainingCredits, isFirstLoad) => {
  const i18nNameSpace = 'content:side-panel-components.optimization.section.credits';

  if (0 < remainingCredits && remainingCredits < amountToUnlock) {
    return i18next.t(`${i18nNameSpace}.unlock-message-remaining_interval`, {
      amountToUnlock,
      count: remainingCredits,
      label: getLabel(type, remainingCredits),
      postProcess: 'interval',
    });
  } else {
    if (isFirstLoad) {
      return i18next.t(`${i18nNameSpace}.unlock-message-first-load_interval`, {
        count: amountToUnlock,
        label: getLabel(type, amountToUnlock),
        postProcess: 'interval',
      });
    } else {
      return i18next.t(`${i18nNameSpace}.unlock-message_interval`, {
        count: amountToUnlock,
        label: getLabel(type, amountToUnlock),
        postProcess: 'interval',
      });
    }
  }
};

export const getTooltipMessage = (type, remainingCredits, totalCredits) => {
  const i18nNameSpace = 'content:side-panel-components.optimization.section.credits';
  const label = getLabel(type);

  return i18next.t(`${i18nNameSpace}.tooltip-message_interval`, {
    count: remainingCredits,
    label,
    postProcess: 'interval',
    totalCredits,
  });
};

export const getCreditPropertyName = (creditType, property) => {
  if (!['remaining', 'total'].includes(property)) {
    return null;
  }

  switch (creditType) {
    case ANALYSIS_CREDITS_TYPE:
      return `${property}AnalysisUnit`;
    case SEARCH_INTENTS_CREDITS_TYPE:
      return `${property}SearchIntentUnit`;
    case INCOMING_LINKS_CREDITS_TYPE:
      return `${property}IncomingLinkUnit`;
    default:
      return null;
  }
};
