import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import apiClient from '@/apis/semji/apiClient';
import { SCOPE_WORKSPACE } from '@/apis/semji/constants';
import { sortUsers, sortUsersFn } from '@/utils/user';

export default function useGetWorkspaceAccessesById() {
  const { get } = apiClient;
  const { workspaceId } = useParams();

  return useQuery({
    placeholderData: [],
    queryFn: async ({ signal }) => {
      const { data } = await get(`/workspaces/${workspaceId}/workspace_accesses`, {
        signal,
      });

      return sortUsers(data?.['hydra:member'] || []);
    },
    queryKey: [SCOPE_WORKSPACE.GET_WORKSPACE_ACCESSES, workspaceId],
  });
}
