import React, { useId } from 'react';

function AICreditIcon(props) {
  const id = useId();

  return (
    <svg fill="none" height="16" viewBox="0 0 15 16" width="15" {...props}>
      <path
        d="M15 8C15 12.1421 11.6421 15.5 7.5 15.5C3.35786 15.5 0 12.1421 0 8C0 3.85786 3.35786 0.5 7.5 0.5C11.6421 0.5 15 3.85786 15 8Z"
        fill={`url(#linear0_${id})`}
      />
      <circle cx="7.5" cy="8" r="7.19444" stroke={`url(#linear1_${id})`} strokeWidth="0.611111" />
      <path
        d="M7.13545 4.0904C7.20893 3.79799 7.62444 3.79799 7.69792 4.0904L8.34494 6.66532C8.37098 6.76893 8.45189 6.84984 8.55551 6.87588L11.1304 7.5229C11.4228 7.59638 11.4228 8.01189 11.1304 8.08537L8.55551 8.7324C8.45189 8.75843 8.37098 8.83934 8.34494 8.94296L7.69792 11.5179C7.62444 11.8103 7.20893 11.8103 7.13545 11.5179L6.48843 8.94296C6.46239 8.83934 6.38148 8.75843 6.27786 8.7324L3.70295 8.08537C3.41054 8.01189 3.41054 7.59638 3.70295 7.5229L6.27786 6.87588C6.38148 6.84984 6.46239 6.76893 6.48843 6.66531L7.13545 4.0904Z"
        fill="white"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id={`linear0_${id}`}
          x1="3.02908e-05"
          x2="15.0027"
          y1="8.00423"
          y2="8.00423"
        >
          <stop stopColor="#FF4580" />
          <stop offset="1" stopColor="#FF4D64" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id={`linear1_${id}`}
          x1="13.4425"
          x2="1.98413"
          y1="3.07962"
          y2="13.0102"
        >
          <stop stopColor="#CA3B4E" />
          <stop offset="1" stopColor="#CA3B4E" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default AICreditIcon;
