import styled from 'styled-components/macro';

const NavBarGroup = styled.div`
  display: flex;
  flex: ${({ isMain }) => isMain && '1'};
  align-items: center;
  justify-content: ${({ align }) =>
    align === 'end' ? 'flex-end' : align === 'center' ? 'center' : 'flex-start'};
`;

export default NavBarGroup;
