import * as semji from '@/apis/semji/api';
import { SIGNED_IN } from '@/utils/3rdParty/Mixpanel/constants';
import { trackEvent } from '@/utils/3rdParty/Mixpanel/MixPanel';
import { COOKIE_REFRESH_TOKEN_KEY_NAME, getCookie } from '@/utils/cookies/cookies';

import {
  FETCH_REGISTRANT_SUCCESS,
  FETCH_USER_SUCCESS,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  SET_USER_COMMENT_EMAIL_NOTIFICATIONS,
  SET_USER_CONTENT_EMAIL_NOTIFICATIONS,
  SET_USER_EMAIL,
  SET_USER_FACIAL_ID,
  SET_USER_FIRST_NAME,
  SET_USER_IS_EXTERNAL_LOGIN,
  SET_USER_LANGUAGE_CODE,
  SET_USER_LAST_NAME,
  SET_USER_PHONE_NUMBER,
  SET_USER_PROFILE_IMAGE_HASH,
  SET_USER_UPLOADED_AVATAR_URL,
  UPDATE_USER_WORKSPACE_ACCESSES,
} from './actionTypes';

const fetchAccesses = (user) => async (dispatch) => {
  try {
    const [organizationAccesses, workspaceAccesses] = await Promise.all([
      semji.getUserOrganizationAccesses(user.id),
      semji.getUserWorkspaceAccesses(user.id),
    ]);

    await dispatch({
      type: FETCH_USER_SUCCESS,
      user: {
        ...user,
        organizationAccesses: organizationAccesses['hydra:member'],
        userLoggedIn: true,
        workspaceAccesses: workspaceAccesses['hydra:member'],
      },
    });

    return Promise.resolve(user);
  } catch (e) {
    return Promise.reject(e);
  }
};

// Chaining those calls are not that pretty, to refactor later
export const login = (credentials) => async (dispatch) => {
  try {
    const { data } = await semji.login(credentials);
    await dispatch({
      type: LOGIN_SUCCESS,
      user: {
        ...data,
      },
    });

    trackEvent(SIGNED_IN, {
      distinct_id: data.id,
      email: data.email,
      first_name: data.firstName,
      last_name: data.lastName,
      type: 'email+password',
    });

    await dispatch(fetchAccesses(data));
    return Promise.resolve(data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const googleOAuthLogin =
  ({ googleToken, autoCreate, updateProfile }) =>
  async (dispatch) => {
    try {
      const { data } = await semji.googleOAuthLogin({
        autoCreate,
        googleToken,
        updateProfile,
      });

      trackEvent(SIGNED_IN, {
        distinct_id: data.id,
        email: data.email,
        first_name: data.email,
        last_name: data.email,
        type: 'ssoGoogle',
      });

      await dispatch({
        type: LOGIN_SUCCESS,
        user: {
          ...data,
        },
      });

      await dispatch(fetchAccesses(data));
      return Promise.resolve(data);
    } catch (e) {
      return Promise.reject(e);
    }
  };

export const faceIoLogin =
  ({ username, facialId }) =>
  async (dispatch) => {
    try {
      const { data } = await semji.faceIoLogin({
        facialId,
        username,
      });
      await dispatch({
        type: LOGIN_SUCCESS,
        user: {
          ...data,
          facialId,
        },
      });

      await dispatch(fetchAccesses(data));
      return Promise.resolve(data);
    } catch (e) {
      return Promise.reject(e);
    }
  };

// Chaining those calls are not that pretty, to refactor later
export const fetchAuthenticatedUser = (impersonating) => async (dispatch) => {
  try {
    const user = await semji.fetchAuthenticatedUser();
    await dispatch(fetchAccesses(user));
    return Promise.resolve(user);
  } catch (e) {
    if (impersonating) {
      return Promise.reject(e);
    }

    try {
      const userRefreshToken = getCookie(COOKIE_REFRESH_TOKEN_KEY_NAME);

      if (!userRefreshToken) {
        dispatch(logoutUser());
        throw new Error('Missing refresh_token');
      }

      const refreshedUser = await semji.refreshToken(userRefreshToken);
      return dispatch(fetchAccesses(refreshedUser));
    } catch (e) {
      return Promise.reject(e);
    }
  }
};

// TODO: investigate why it looks like it's not used anymore but webpack still see it in use somewhere.
export const registerUser =
  ({ registrantToken, ...restValues }) =>
  async (dispatch) => {
    try {
      await semji.registerUser(registrantToken, restValues);
      const user = await dispatch(fetchAuthenticatedUser());
      return Promise.resolve(user);
    } catch (e) {
      return Promise.reject(e);
    }
  };

export const resetUserPassword = (values) => async (dispatch) => {
  try {
    await semji.resetUserPassword(values.token, values);
    await dispatch(fetchAuthenticatedUser());
    return Promise.resolve();
  } catch (e) {
    return Promise.reject(e);
  }
};

export const setUserEmail = (email) => ({
  email,
  type: SET_USER_EMAIL,
});

export const setUserFirstName = (firstName) => ({
  firstName,
  type: SET_USER_FIRST_NAME,
});

export const setUserLastName = (lastName) => ({
  lastName,
  type: SET_USER_LAST_NAME,
});

export const setUploadedAvatarUrl = (uploadedAvatarUrl) => ({
  type: SET_USER_UPLOADED_AVATAR_URL,
  uploadedAvatarUrl,
});

export const setProfileImageHash = (profileImageHash) => ({
  profileImageHash,
  type: SET_USER_PROFILE_IMAGE_HASH,
});

export const setUserPhoneNumber = (phoneNumber) => ({
  phoneNumber,
  type: SET_USER_PHONE_NUMBER,
});

export const setUserLanguageCode = (languageCode) => ({
  languageCode,
  type: SET_USER_LANGUAGE_CODE,
});

export const setUserFacialId = (facialId) => ({
  facialId,
  type: SET_USER_FACIAL_ID,
});

export const setUserIsExternalLogin = (value) => ({
  payload: {
    value,
  },
  type: SET_USER_IS_EXTERNAL_LOGIN,
});

export const updateUserWorkspaceAccesses = (workspaceAccesses) => ({
  type: UPDATE_USER_WORKSPACE_ACCESSES,
  workspaceAccesses,
});

export const setUserCommentEmailNotifications = (commentEmailNotificationsEnabled) => ({
  commentEmailNotificationsEnabled,
  type: SET_USER_COMMENT_EMAIL_NOTIFICATIONS,
});

export const setUserContentEmailNotifications = (contentEmailNotificationsEnabled) => ({
  contentEmailNotificationsEnabled,
  type: SET_USER_CONTENT_EMAIL_NOTIFICATIONS,
});

export const logoutUser = () => (dispatch) => {
  // Moved here because we can't use the store like we are in the global reducer.
  return dispatch({
    type: LOGOUT_USER,
  });
};

export const fetchRegistrant = (registrantToken) => async (dispatch) => {
  try {
    const response = await semji.fetchRegistrant(registrantToken);

    await dispatch({
      type: FETCH_REGISTRANT_SUCCESS,
      user: response,
    });

    return Promise.resolve(response);
  } catch (e) {
    return Promise.reject(e);
  }
};
