import React from 'react';

import FlatLoader from '@/components/Loader/FlatLoader';
import { FlatLoaderWrapper, Title } from '@/containers/ContentIdeas/Listing/Header/Header.styled';

export default function TitleRender({
  contentIdeasLoading,
  contentIdeasKeyword,
}: {
  contentIdeasLoading: boolean;
  contentIdeasKeyword: string;
}) {
  if (contentIdeasLoading) {
    return (
      <FlatLoaderWrapper>
        <FlatLoader />
      </FlatLoaderWrapper>
    );
  }
  return <Title>{contentIdeasKeyword}</Title>;
}
