import { useTranslation } from 'react-i18next';

import { Loader, LoaderText } from '@/design-system/components/Loader';

export default function ContentLoader() {
  const { t } = useTranslation();

  return (
    <Loader>
      <LoaderText>{t('content:empty-editor-container.retrieving-content')}</LoaderText>
    </Loader>
  );
}
