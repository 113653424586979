import './ChartTooltip.scss';

import { useTranslation } from 'react-i18next';
import { ColoredDot } from 'semji-core/components/ColoredDot';
import { getRoundedNumberMetricWithSuffix } from 'semji-core/utils/numbers';

export function ChartTooltip({ graphPoints, selectedMetrics, label, isInHighChartsLine = false }) {
  const { t } = useTranslation();

  function isWinner(point) {
    return graphPoints.length > 1 && point.y === Math.max(...graphPoints.map((point) => point.y));
  }

  return (
    <div className="chart-tooltip">
      <div className="chart-tooltip__label">{label}</div>
      <div className={`chart-tooltip__points${isInHighChartsLine ? '--column' : '--row'}`}>
        {graphPoints.map((point, index) => (
          <div className="chart-tooltip__points__graphpoints">
            <div
              className={`chart-tooltip__point ${isWinner(point) && 'chart-tooltip__point--winner'}`}
            >
              <div className="chart-tooltip__point__left">&nbsp;</div>
              <div className="chart-tooltip__point__left">
                <ColoredDot color={point.color} size="xxs" />
                <div className="chart-tooltip__point__value">{point.series.name}</div>
              </div>
            </div>
            <div className="chart-tooltip__points__graphpoints__metrics">
              {selectedMetrics.map((metric, key) => (
                <div className="chart-tooltip__points__graphpoints__metrics__metric">
                  {index === 0 && (
                    <div key={key} className="chart-tooltip__label-right">
                      {t(`competitors:duel.report.${metric}`)}
                    </div>
                  )}
                  <div
                    key={metric}
                    className={`chart-tooltip__point ${isWinner(point) && 'chart-tooltip__point--winner'}`}
                  >
                    {getRoundedNumberMetricWithSuffix({
                      number:
                        (isInHighChartsLine
                          ? point.point.custom?.[metric]
                          : point?.custom?.[metric]) || point.y,
                    })}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
