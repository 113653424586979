export const COMPETITOR_REPORT_STATUS_PENDING = 'PENDING';
export const COMPETITOR_REPORT_STATUS_CURRENT_ACTIVE = 'CURRENT_ACTIVE';
export const COMPETITOR_REPORT_STATUS_DEPRECATED = 'DEPRECATED';

export const ENUM_COMPETITOR_REPORT_STATUS = {
  CURRENT_ACTIVE: COMPETITOR_REPORT_STATUS_CURRENT_ACTIVE,
  DEPRECATED: COMPETITOR_REPORT_STATUS_DEPRECATED,
  PENDING: COMPETITOR_REPORT_STATUS_PENDING,
};

export type CompetitorReportStatus =
  | typeof COMPETITOR_REPORT_STATUS_CURRENT_ACTIVE
  | typeof COMPETITOR_REPORT_STATUS_PENDING;

export interface CompetitorReport {
  id: string;
  createdAt: string;
  status: CompetitorReportStatus;
  reportDate: string;
  reportDateStart: string;
  comparativeDates: string[];
  retrievalDates: string[];
  trafficNormalizationStatus: string;
}

export interface CompetitorReportMetric {
  id: string;
  competitorWebsiteId: string;
  nbPages: number;
  traffic: number;
  longTailTraffic: number;
  unbrandedTraffic: number;
}
