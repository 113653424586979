import './Title.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { ENUM_FAVICON_VARIANT, FavIcon } from 'semji-core/components/FavIcon';
import {
  ENUM_NFO_LABEL_ROUNDED,
  ENUM_NFO_LABEL_SIZES,
  INFO_LABEL_VARIANT_CUSTOM,
  InfoLabel,
} from 'semji-core/components/InfoLabel';
import { ENUM_LINK_SIZE_VARIANT, ENUM_LINK_VARIANT, Link } from 'semji-core/components/Link';

import { PageCategory } from '@/containers/Competitors/components/PageCategory';
import { COMPETITOR_COLORS } from '@/containers/Competitors/utils/constants';
import { getHostnamefromUrl } from '@/containers/Competitors/utils/utils';
import { DEFAULT_FAVICON } from '@/utils/constants';
import { getFaviconFromUrl } from '@/utils/favicon';
import { generateColorBasedOnString } from '@/utils/helper';

export default function Title({
  title,
  url,
  theme,
  category,
}: {
  title: string;
  url: string;
  theme: string;
  category: string;
}): JSX.Element {
  const { t } = useTranslation();

  const apiRootUrl = import.meta.env.VITE_REACT_APP_SEMJI_API_ROOT_URL;
  const { color, backgroundColor } = generateColorBasedOnString(COMPETITOR_COLORS, theme);

  return (
    <div className="competitor-data-explorer-title-cell">
      <Link
        displayIcon
        href={url}
        iconStart
        label={title}
        labelLineClamp={2}
        size={ENUM_LINK_SIZE_VARIANT.Normal}
        variant={ENUM_LINK_VARIANT.Dark}
      />
      <div className="competitor-data-explorer-title-cell__info">
        <FavIcon
          defaultFavicon={DEFAULT_FAVICON}
          disableClick
          displayExternalIcon={false}
          displayLink
          label={getHostnamefromUrl(url)}
          linkSize={ENUM_LINK_SIZE_VARIANT.XSmall}
          src={getFaviconFromUrl(url, apiRootUrl)}
          variant={ENUM_FAVICON_VARIANT.Small}
        />
        <PageCategory category={category} />
        <InfoLabel
          backgroundColor={backgroundColor}
          className="competitor-data-explorer-title-cell__info__theme"
          color={color}
          rounded={ENUM_NFO_LABEL_ROUNDED.full}
          size={ENUM_NFO_LABEL_SIZES.ExtraSmall}
          variant={INFO_LABEL_VARIANT_CUSTOM}
        >
          {theme}
        </InfoLabel>
      </div>
    </div>
  );
}
