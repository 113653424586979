import './LayoutHeader.scss';

import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useMatch, useParams } from 'react-router-dom';
import { NavigationButton } from 'semji-core/components/Button';
import { ENUM_NAVIGATION_BUTTON_VARIANTS } from 'semji-core/components/Button/NavigationButton';
import { HelpIcon } from 'semji-core/icons/HelpIcon';
import { SettingsIcon } from 'semji-core/icons/SettingsIcon';

import LaunchTour from '@/components/Badge/LaunchTour';
import { ReportDate } from '@/containers/Competitors/components/ReportDate';
import ScreenOptionButton from '@/containers/Competitors/components/ScreenOptionButton/ScreenOptionButton';
import { selectHasAccessToCompetitorsAdmin } from '@/store/selectors/selectHasAccessToCompetitorsAdmin';
import { getHelpCenterUrl } from '@/utils/helper';

export default function LayoutHeader({
  competitorReportId,
  reportDate,
}: {
  competitorReportId: string;
  reportDate: string;
}) {
  const { organizationId, workspaceId } = useParams();
  const hasAccessToCompetitorsAdmin = useSelector(selectHasAccessToCompetitorsAdmin);

  const match = useMatch('/o/:organizationId/w/:workspaceId/search-intelligence/:reportId/:page/*');
  const { page } = match?.params || {};
  return (
    <div className="competitors-layout-header">
      <div className="competitors-layout-header__date">
        <ReportDate competitorReportId={competitorReportId} reportDate={reportDate} />
      </div>
      <div className="competitors-layout-header__actions">
        <LaunchTour data-intercom-target={`search-intelligence-${page}-tour`} noMargin />
        <NavigationButton
          className="competitors-layout-header__actions__button-help"
          href={getHelpCenterUrl()}
          rel="noreferrer"
          target="_blank"
          variant={ENUM_NAVIGATION_BUTTON_VARIANTS.Text}
        >
          <HelpIcon />
        </NavigationButton>
        <ScreenOptionButton />
        {hasAccessToCompetitorsAdmin && (
          <NavLink
            className="competitors-layout-header__actions__button-config"
            to={`/o/${organizationId}/w/${workspaceId}/search-intelligence/configuration`}
          >
            <SettingsIcon />
          </NavLink>
        )}
      </div>
    </div>
  );
}
