import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import apiClient from '@/apis/semji/apiClient';

export default function useGetCurrentOrganization(props) {
  const { get } = apiClient;
  const { organizationId } = useParams();

  return useQuery({
    queryFn: async ({ signal }) => {
      const result = await get(`/organizations/${organizationId}`, {
        signal,
      });

      return result.data || {};
    },

    ...props,
  });
}
