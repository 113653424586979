function PostMessage() {
  const data = { sender: 'semji' };

  for (let pair of new URL(window.location).searchParams.entries()) {
    data[pair[0]] = pair[1];
  }

  if (window.opener) {
    window.opener.postMessage(data, window.location);
  }

  return null;
}

export default PostMessage;
