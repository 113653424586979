import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { updateWorkspaceUsers } from '@/store/actions/workspace';
import { selectCurrentWorkspaceUsers } from '@/store/selectors/selectCurrentWorkspaceUsers';

/**
 * Custom hook to fetch users from current workspace.
 *
 * @returns [] Array containing users
 */
function useWorkspaceUsers() {
  const dispatch = useDispatch();
  const { workspaceId } = useParams();
  const users = useSelector(selectCurrentWorkspaceUsers);

  /** Effects */
  useEffect(() => {
    dispatch(updateWorkspaceUsers(workspaceId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId]);

  return users;
}

export default useWorkspaceUsers;
