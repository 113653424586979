import styled from 'styled-components/macro';

import InfoBadge from '@/components/Badge/InfoBadge';

export const BetaBadge = styled(InfoBadge)`
  && {
    margin: ${({ margin }) => margin || '10px 15px'};
    font-size: ${({ fontSize }) => fontSize || '13px'};
    font-weight: ${({ fontWeight }) => fontWeight || 400};
    line-height: ${({ lineHeight }) => lineHeight || 1};
    color: ${({ theme }) => theme.cssColors.purple};
    background-color: ${({ theme }) => theme.cssColors.lightPurple};
    text-transform: ${(props) => (props.noCapitalization ? 'none' : 'capitalize')};
    padding: ${({ padding }) => padding || '5px 13px'};
    border-radius: 10px;
    width: ${({ width }) => width || 'initial'};
    height: initial;
  }
`;
