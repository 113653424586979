import { useTranslation } from 'react-i18next';

import ImgErrorAnalysis from '@/assets/images/img-error-analysis.svg';
import { ActionButton } from '@/components/Button/Button';
import { ParagraphText } from '@/components/Text/Paragraph';
import { TitleThree } from '@/components/Text/Title';
import { EditorEmptyBodyWrapper } from '@/containers/Content/Wrappers';

export const PageNotPublishedStatsPanelBody = ({ showMarkAsPublishedDialog }) => {
  const { t } = useTranslation();

  return (
    <EditorEmptyBodyWrapper>
      <img
        alt={t(
          'content:side-panel-components.performance.page-not-published-modal.alt-image-failed-analyse'
        )}
        src={ImgErrorAnalysis}
        width="100"
      />
      <TitleThree weight="strong">
        {t('content:side-panel-components.performance.page-not-published-modal.title')}
      </TitleThree>
      <ParagraphText align="center" color="dark060" weight="medium">
        {t('content:side-panel-components.performance.page-not-published-modal.body')}
      </ParagraphText>
      <ActionButton onClick={showMarkAsPublishedDialog}>
        {t('content:side-panel-components.performance.page-not-published-modal.button')}
      </ActionButton>
    </EditorEmptyBodyWrapper>
  );
};
