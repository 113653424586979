import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';

import {
  FlexCenteredAlignJustifyEndContainer,
  FlexColumnContainer,
  StyledCard,
} from '@/components/_common';
import { ContainedButton, OutlinedButton } from '@/components/Button/Button';
import ProgressBar from '@/components/Rating/LinearProgressBar';
import { InlineText } from '@/components/Text/Inline';
import Medium from '@/components/Text/Medium';
import { TitleFour, TitleThree } from '@/components/Text/Title';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import ReactivatePlan from '@/containers/Billing/ReactivatePlan';
import { showCancelPlanModal, showUpgradePlanModal } from '@/store/actions/billing';
import { breakpoints } from '@/themes/responsive';
import {
  ACTIVE__SUBSCRIPTION,
  CANCELLED_SUBSCRIPTION__LESS_60_SUBSCRIPTION,
  CANCELLED_SUBSCRIPTION__MORE_60_SUBSCRIPTION,
  CANCELLED_TRIAL__LESS_60_DAYS,
  CANCELLED_TRIAL__MORE_60_DAYS,
  IN_TRIAL__EXPIRES_LESS_5_DAYS,
  IN_TRIAL__EXPIRES_MORE_5_DAYS,
  NON_RENEWING__SUBSCRIPTION,
  PAYMENT_ERROR_AFTER_DUNNING__SUBSCRIPTION,
  PAYMENT_ERROR_DURING_DUNNING__SUBSCRIPTION,
  planStatus,
  SUBSCRIPTION_STATUS,
} from '@/utils/billing';

import { Section } from '.';
import Credits from './Credits';

const CustomStyledCard = styled(StyledCard)`
  padding: 16px 24px;
  align-items: center;
  min-width: 225px;

  @media (max-width: ${breakpoints.xl}) {
    flex-direction: column;
    align-items: baseline;
  }
`;
const StyledOutlinedButton = styled(OutlinedButton)`
  @media (max-width: ${breakpoints.xl}) {
    margin-right: 20px;
  }
`;

const FeatureProgressBarWrapper = styled.div`
  width: 90px;
`;
const SpaceAroundFlexCenteredAlignJustifyEndContainer = styled(
  FlexCenteredAlignJustifyEndContainer
)`
  justify-content: space-around;
`;
const InlineVersionWrapper = styled.div`
  margin-right: 50px;
`;

export function UnlimitedFeature({ name, description }) {
  const { t } = useTranslation();

  return (
    <InlineVersionWrapper>
      <TooltipComponent placement="top" title={description}>
        <InlineText>{name}</InlineText>
        <FeatureProgressBarWrapper>
          <ProgressBar backgroundColor="primary010" errorMonoColor value={Infinity} />
        </FeatureProgressBarWrapper>
        <InlineText color="dark060" size="sm">
          {t('layout:credits.unlimited')}
        </InlineText>
      </TooltipComponent>
    </InlineVersionWrapper>
  );
}

export function Feature({ name, value, target, description }) {
  const { t } = useTranslation();

  return (
    <InlineVersionWrapper>
      <TooltipComponent placement="top" title={description}>
        <InlineText>{name}</InlineText>
        <FeatureProgressBarWrapper>
          <ProgressBar backgroundColor="primary010" errorMonoColor value={value / target} />
        </FeatureProgressBarWrapper>
        <InlineText color="dark060" size="sm">
          {t('layout:credits.balance', { target, value })}
        </InlineText>
      </TooltipComponent>
    </InlineVersionWrapper>
  );
}

function PlanStatusText(props) {
  const { t } = useTranslation();

  const {
    subscriptionStatus,
    subscriptionNextBillingAt,
    subscriptionTrialEndAt,
    subscriptionCurrentTermEndAt,
    paymentProblemOccurred,
    paymentDunningEndAt,
  } = props.currentOrganization;
  const {
    status,
    subscriptionTrialEndAtFormattedDate,
    subscriptionCurrentTermEndAtFormattedDate,
    subscriptionPaymentDunningEndAtFormattedDateTime,
  } = planStatus({
    paymentDunningEndAt,
    paymentProblemOccurred,
    subscriptionCurrentTermEndAt,
    subscriptionNextBillingAt,
    subscriptionStatus,
    subscriptionTrialEndAt,
  });

  switch (status) {
    case PAYMENT_ERROR_AFTER_DUNNING__SUBSCRIPTION:
      return (
        <InlineText color="red" size="sm">
          {t('settings:workspace.billing-plan.payment-error-after-dunning-subscription-label', {
            subscriptionPaymentDunningEndAtFormattedDateTime,
          })}
        </InlineText>
      );
    case PAYMENT_ERROR_DURING_DUNNING__SUBSCRIPTION:
    case ACTIVE__SUBSCRIPTION:
      return (
        <InlineText color="dark060" size="sm">
          {t('settings:workspace.billing-plan.active-subscription-label', {
            subscriptionCurrentTermEndAtFormattedDate,
          })}
        </InlineText>
      );
    case NON_RENEWING__SUBSCRIPTION:
      return (
        <InlineText color="red" size="sm">
          {t('settings:workspace.billing-plan.non-renewing-subscription-label', {
            subscriptionCurrentTermEndAtFormattedDate,
          })}
        </InlineText>
      );
    case IN_TRIAL__EXPIRES_MORE_5_DAYS:
      return (
        <InlineText color="dark060" size="sm">
          {t('settings:workspace.billing-plan.in-trial-label', {
            subscriptionTrialEndAtFormattedDate,
          })}
        </InlineText>
      );
    case CANCELLED_TRIAL__LESS_60_DAYS:
    case CANCELLED_TRIAL__MORE_60_DAYS:
      return (
        <InlineText color="red" size="sm">
          {t('settings:workspace.billing-plan.cancelled-trial-label', {
            subscriptionTrialEndAtFormattedDate,
          })}
        </InlineText>
      );
    case IN_TRIAL__EXPIRES_LESS_5_DAYS:
      return (
        <InlineText color="red" size="sm">
          {t('settings:workspace.billing-plan.in-trial-label', {
            subscriptionTrialEndAtFormattedDate,
          })}
        </InlineText>
      );
    case CANCELLED_SUBSCRIPTION__MORE_60_SUBSCRIPTION:
    case CANCELLED_SUBSCRIPTION__LESS_60_SUBSCRIPTION:
      return (
        <InlineText color="red" size="sm">
          {t('settings:workspace.billing-plan.cancelled-subscription-label', {
            subscriptionTrialEndAtFormattedDate,
          })}
        </InlineText>
      );
    default:
      return '';
  }
}

function Plan(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  function openUpgradeModal() {
    dispatch(showUpgradePlanModal());
  }

  function openCancelModal() {
    dispatch(showCancelPlanModal());
  }

  const subscriptionStatus = props.currentOrganization.subscriptionStatus;

  return (
    <Section>
      <TitleFour noMargin weight="strong">
        {t('settings:workspace.billing-plan.title')}
      </TitleFour>
      <br />
      <CustomStyledCard>
        <FlexColumnContainer>
          <TitleThree noMargin>
            {[SUBSCRIPTION_STATUS.in_trial, SUBSCRIPTION_STATUS.cancelled_trial].includes(
              subscriptionStatus
            ) ? (
              <Medium>{t('billing:free-trial')}</Medium>
            ) : (
              <Trans
                components={{
                  medium: <Medium />,
                }}
                i18nKey={'settings:workspace.billing-plan.subtitle'}
                values={{
                  planName: props.currentOrganization.plan?.name ?? '-',
                }}
              />
            )}
          </TitleThree>
          <PlanStatusText currentOrganization={props.currentOrganization} />
        </FlexColumnContainer>
        <Credits organization={props.currentOrganization} />
        {props.disableCta ? null : (
          <SpaceAroundFlexCenteredAlignJustifyEndContainer>
            {props.currentOrganization.subscriptionStatus === SUBSCRIPTION_STATUS.active && (
              <StyledOutlinedButton minWidth="80px" weight="normal" onClick={openCancelModal}>
                {t('settings:workspace.billing-plan.button-cancel')}
              </StyledOutlinedButton>
            )}
            &nbsp;
            {props.currentOrganization.subscriptionStatus === SUBSCRIPTION_STATUS.non_renewing ? (
              <>
                <ReactivatePlan />
                &nbsp;
                <ContainedButton disabled minWidth="120px" OutlinedButton weight="medium">
                  {t('settings:workspace.billing-plan.button-change-plan')}
                </ContainedButton>
              </>
            ) : (
              <ContainedButton
                minWidth="120px"
                OutlinedButton
                weight="medium"
                onClick={openUpgradeModal}
              >
                {t('settings:workspace.billing-plan.button-upgrade')}
              </ContainedButton>
            )}
          </SpaceAroundFlexCenteredAlignJustifyEndContainer>
        )}
      </CustomStyledCard>
    </Section>
  );
}

export default Plan;
