import { DateTime } from 'luxon';

import {
  deleteWorkspace,
  disableWorkspace,
  fetchPagesForWorkspace,
  fetchWorkspace,
  fetchWorkspaceContents,
  putWorkspace,
} from '@/apis/semji/api';

export default class Workspace {
  constructor(id) {
    this._id = id;
    this._workspace = null;
    this._contents = null;
    this._pages = null;
  }

  get workspace() {
    return this._fetch();
  }

  get contents() {
    return this._fetchContents();
  }

  get drafts() {
    return this._fetchContents({
      inStock: false,
      'type[equals]': 'DRAFT',
    });
  }

  delete() {
    return deleteWorkspace(this._id);
  }

  disable() {
    return disableWorkspace(this._id);
  }

  update(values) {
    return putWorkspace(this._id, values);
  }

  async pages(filters, additionalProperties, mainProperties = null) {
    this._pages = await fetchPagesForWorkspace(
      this._id,
      filters,
      additionalProperties,
      mainProperties
    );

    return this._pages['hydra:member'];
  }

  async _fetchContents(filters) {
    if (this._contents === null) {
      try {
        this._contents = await fetchWorkspaceContents({ filters, workspaceId: this._id });
      } catch (e) {
        throw e;
      }
    }

    return this._contents['hydra:member'].sort((a, b) => {
      const date1 = a.dueDate ? DateTime.fromISO(a.dueDate) : null;
      const date2 = b.dueDate ? DateTime.fromISO(b.dueDate) : null;

      if (+date1 === +date2) {
        return 0;
      }

      return (date1 && date1 < date2) || (date1 && !date2) ? -1 : 1;
    });
  }

  async _fetch() {
    if (this._workspace === null) {
      try {
        this._workspace = await fetchWorkspace(this._id);
      } catch (e) {
        throw e;
      }
    }

    return this._workspace;
  }
}
