import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import ClusterBadge from '@/components/Badge/ClusterBadge';
import FlatLoader, { DEFAULT_LOADER_HEIGHT, LoaderWrapper } from '@/components/Loader/FlatLoader';
import { Cell, EllipsisCell } from '@/components/Pages/ContentIdeas/common';
import UrlClassification from '@/components/Pages/ContentIdeas/Listing/CellRender/Title/UrlClassification';
import UrlWordsCount from '@/components/Pages/ContentIdeas/Listing/CellRender/Title/UrlWordsCount';
import { FALSE_VALUE } from '@/components/Pages/ContentIdeas/Listing/constant';
import Flex from '@/design-system/components/Flex';
import useCan from '@/hooks/useCan';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import { formatUrlForDisplay } from '@/utils/url';

import {
  ClustersBadgeWrapper,
  ContentIdeaTitleWrapper,
  StyledFavIcon,
  StyledIconExternalLink,
  StyledTooltipComponent,
  TextWrapper,
  Wrapper,
} from './Title.styled';

function TitleRender({
  url,
  title,
  titleLabel,
  titleRetrievedAt,
  urlCategory,
  urlClassifiedAt,
  urlWordsCountRetrievedAt,
  urlWordsCount,
  contentIdeasClusters,
  contentIdeaClusterIds,
}) {
  const { t } = useTranslation();
  const [query] = useSearchParams();

  const isClusteringDisplayedFeatureFlag = useCan({
    perform: 'content_ideas.clustering.displayed',
  });
  const { isFeatureEnabled: isClusteringFeayureSet } = useOrganizationFeatureSet(
    'content-ideas:has-access-to-clusters'
  );
  const displayClusters = !!contentIdeasClusters && isClusteringDisplayedFeatureFlag;
  const formatedUrl = formatUrlForDisplay(url).split('/')[0];

  function onClick(e) {
    e.stopPropagation();
  }

  function handleClickCluster(clusterId) {
    query.set('archivedType', FALSE_VALUE);
    query.set('clusterId', clusterId);
  }

  return (
    <Wrapper>
      <TextWrapper>
        {titleRetrievedAt ? (
          <ContentIdeaTitleWrapper
            href={url}
            rel="noopener noreferrer"
            target="_blank"
            onClick={onClick}
          >
            <EllipsisCell
              align="justify"
              color="currentColor"
              dangerouslySetInnerHTML={{
                __html: titleLabel ?? t('common:labels.unknown-title'),
              }}
              lineHeight={`19px`}
              noFlex
              noPadding
              title={title}
              weight="medium"
              onClick={onClick}
            />
            <StyledIconExternalLink
              color="currentColor"
              href={url}
              marginTop="2px"
              size="14px"
              title={t('content-ideas:listing.table.open-in-new-tab')}
              onClick={onClick}
            />
          </ContentIdeaTitleWrapper>
        ) : (
          <LoaderWrapper height={DEFAULT_LOADER_HEIGHT} width={400}>
            <FlatLoader />
          </LoaderWrapper>
        )}
        <Flex gap="6px" onClick={onClick}>
          <Flex alignItems="center" gap="5px">
            {url ? (
              <Flex alignItems="center">
                <StyledFavIcon url={url} />
                <Cell
                  alignSelf={`center`}
                  color="dark060"
                  lineHeight={`15px`}
                  maxWidth="100px"
                  noPadding
                  size="xs"
                  title={url}
                  weight="medium"
                >
                  {formatedUrl}
                </Cell>
              </Flex>
            ) : (
              <LoaderWrapper height={DEFAULT_LOADER_HEIGHT} isFlex width={85}>
                <FlatLoader />
              </LoaderWrapper>
            )}
            <UrlClassification urlCategory={urlCategory} urlClassifiedAt={urlClassifiedAt} />
            <UrlWordsCount
              urlWordsCount={urlWordsCount}
              urlWordsCountRetrievedAt={urlWordsCountRetrievedAt}
            />
          </Flex>
          {displayClusters && (
            <ClustersBadgeWrapper alignItems="center" justify-contents="center">
              {contentIdeaClusterIds?.length > 0 &&
                contentIdeaClusterIds.map((id) => {
                  const cluster = contentIdeasClusters?.[id];
                  return (
                    !!cluster &&
                    isClusteringFeayureSet && (
                      <StyledTooltipComponent key={id} title={cluster.name}>
                        <ClusterBadge
                          backgroundColor={cluster.backgroundColor}
                          color={cluster.color}
                          margin="0 0 0 2px"
                          onClick={() => handleClickCluster(id)}
                        >
                          {cluster.name}
                        </ClusterBadge>
                      </StyledTooltipComponent>
                    )
                  );
                })}
            </ClustersBadgeWrapper>
          )}
        </Flex>
      </TextWrapper>
    </Wrapper>
  );
}

export default TitleRender;
