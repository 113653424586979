import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import StartOptimizingIcon from '@/components/icons/StartOptimizingIcon';
import TooltipComponent from '@/components/Tooltip/Tooltip';

const StyledStartOptimizingIcon = styled(StartOptimizingIcon)`
  && {
    font-size: 1rem;
    padding: 0.4rem;
  }
  &:hover {
    background-color: ${({ theme }) => theme.cssColors.primary010};
    border-radius: 50%;
  }
`;

function StartOptimizingCell({ className, onAdd }) {
  const { t } = useTranslation();

  function onClick(e) {
    e.stopPropagation();
    onAdd();
  }

  return (
    <TooltipComponent
      className={className}
      cursor="pointer"
      delay={50}
      placement="top"
      title={t(`components:add-to-planning-cell.start-optimizing`)}
      onClick={onClick}
    >
      <StyledStartOptimizingIcon />
    </TooltipComponent>
  );
}

StartOptimizingCell.propTypes = {
  className: PropTypes.string,
  onAdd: PropTypes.func,
};

export default StartOptimizingCell;
