import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import FlagTriangleIcon from '@/components/icons/FlagTriangleIcon';
import TooltipComponent from '@/components/Tooltip/Tooltip';

const Tooltip = styled(TooltipComponent)`
  && {
    display: flex;
    align-items: center;
    color: ${({ color, theme }) => (color ? theme.cssColors[color] : theme.cssColors.dark040)};
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    opacity: ${({ disabled }) => disabled && 0.3};
    font-size: ${({ fontSize, theme }) =>
      fontSize ? theme.textCss.sizes[fontSize] : theme.textCss.sizes.default};
  }
`;
const StyledFlagTriangleIcon = styled(FlagTriangleIcon)``;

function FlagContentButton({ color, isDisabled, fontSize, onClick }) {
  const { t } = useTranslation();

  return (
    <Tooltip
      color={color}
      disabled={isDisabled}
      fontSize={fontSize}
      title={t('content:brief.flag-content-button-tooltip')}
    >
      <StyledFlagTriangleIcon onClick={isDisabled ? undefined : onClick} />
    </Tooltip>
  );
}

export default FlagContentButton;
