import SvgWrapper from './SvgWrapper';

function CheckAltIcon(props) {
  return (
    <SvgWrapper viewBox="0 0 27 27" {...props}>
      <circle cx="13.5" cy="13.5" fill="#E9E9EB" r="13.5" />
      <path
        d="M20.4542 9.64055L11.4847 18.61L7 14.1253L7.64055 13.4847L11.4847 17.3289L19.8127 9L20.4533 9.64145L20.4542 9.64055Z"
        fill="#51525E"
      />
    </SvgWrapper>
  );
}

export default CheckAltIcon;
