import {
  METRICS_CONFIG_PERIOD_LAST_3_MONTHS,
  METRICS_CONFIG_PERIOD_LAST_6_MONTHS,
  METRICS_CONFIG_PERIOD_LAST_12_MONTHS,
  METRICS_CONFIG_PERIOD_LAST_24_MONTHS,
  METRICS_CONFIG_PERIOD_LAST_30_DAYS,
  METRICS_CONFIG_PERIOD_LAST_36_MONTHS,
  METRICS_CONFIG_PERIOD_LAST_90_DAYS,
  METRICS_CONFIG_PERIOD_LAST_MONTH,
  METRICS_CONFIG_PERIODICITY_DAILY,
  METRICS_CONFIG_PERIODICITY_MONTHLY,
  METRICS_CONFIG_PERIODICITY_QUARTERLY,
  METRICS_CONFIG_PERIODICITY_WEEKLY,
} from '@/utils/metrics/constants';
import { getCurrentDateRange } from '@/utils/metrics/getCurrentDateRange';

export function getChartZonesByMetric({ periodicity, period, today }) {
  // if daily or we are in a complete period
  // no zone to display
  if (
    METRICS_CONFIG_PERIODICITY_DAILY === periodicity ||
    [
      METRICS_CONFIG_PERIOD_LAST_MONTH,
      METRICS_CONFIG_PERIOD_LAST_3_MONTHS,
      METRICS_CONFIG_PERIOD_LAST_6_MONTHS,
      METRICS_CONFIG_PERIOD_LAST_12_MONTHS,
      METRICS_CONFIG_PERIOD_LAST_24_MONTHS,
      METRICS_CONFIG_PERIOD_LAST_36_MONTHS,
    ].includes(period)
  ) {
    return {
      after: undefined,
      before: undefined,
    };
  }

  let { from, to } = getCurrentDateRange({ period, today });

  // all the interval is dashed
  if (
    METRICS_CONFIG_PERIODICITY_MONTHLY === periodicity &&
    period === METRICS_CONFIG_PERIOD_LAST_30_DAYS
  ) {
    return {
      after: to.startOf('month').toMillis(),
      before: from.endOf('month').toMillis(),
    };
  }

  if (METRICS_CONFIG_PERIODICITY_MONTHLY === periodicity) {
    return {
      after: to.plus({ days: 1 }).minus({ months: 1 }).startOf('month').toMillis(),
      before: from.plus({ days: 1 }).plus({ months: 1 }).startOf('month').toMillis(),
    };
  }

  if (METRICS_CONFIG_PERIODICITY_WEEKLY === periodicity) {
    return {
      after: to.plus({ days: 1 }).minus({ weeks: 1 }).startOf('week').toMillis(),
      before: from.plus({ weeks: 1 }).startOf('week').toMillis(),
    };
  }

  // all the interval is dashed
  if (
    METRICS_CONFIG_PERIODICITY_QUARTERLY === periodicity &&
    period === METRICS_CONFIG_PERIOD_LAST_90_DAYS
  ) {
    return {
      after: to.startOf('quarter').toMillis(),
      before: from.endOf('quarter').toMillis(),
    };
  }

  if (METRICS_CONFIG_PERIODICITY_QUARTERLY === periodicity) {
    return {
      after: to.plus({ days: 1 }).minus({ quarters: 1 }).startOf('quarter').toMillis(),
      before: from.plus({ days: 1 }).plus({ quarters: 1 }).startOf('quarter').toMillis(),
    };
  }
}

// Dashed zones for undefined values
export function getChartZones({ aggregateMetrics, dashStyle }) {
  return aggregateMetrics.reduce((acc, aggregateMetric, index, array) => {
    const before = aggregateMetric.x;
    const filled = typeof aggregateMetric.y !== 'undefined';
    const newDashStyle = !filled && dashStyle;
    if (index === 0) {
      acc.push({
        afterStyle: newDashStyle,
        dashStyle: newDashStyle,
        value: before,
      });
      return acc;
    }
    const previousValue = acc[acc.length - 1];
    const previousValueFilled = !previousValue.afterStyle;

    const isLastIndex = index + 1 === array.length;
    if (previousValueFilled !== filled || isLastIndex) {
      acc.push({
        afterStyle: newDashStyle,
        dashStyle: previousValue.afterStyle,
        value: before,
      });
      return acc;
    }

    return acc;
  }, []);
}
