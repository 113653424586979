import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { DialogModal, ENUM_DIALOG_MODAL_SIZE } from 'semji-core/components/DialogModal';

function RemoveDraftFromPlanningDialog({ isLoading, isOpen, onCancel, onConfirm }) {
  const { t } = useTranslation();

  return (
    <DialogModal
      cancelAction={onCancel}
      cancelLabel={t('components:planning.remove-draft-from-planning-dialog.cancel-label')}
      canClickOutsideToClose
      closable
      confirmAction={onConfirm}
      confirmLabel={t(
        isLoading
          ? 'components:planning.remove-draft-from-planning-dialog.confirm-loading-label'
          : 'components:planning.remove-draft-from-planning-dialog.confirm-label'
      )}
      isLoading={isLoading}
      isOpen={isOpen}
      size={ENUM_DIALOG_MODAL_SIZE.Large}
      title={t('components:planning.remove-draft-from-planning-dialog.title')}
      onClose={onCancel}
    >
      <Trans
        components={{
          br: <br />,
        }}
        i18nKey={'components:planning.remove-draft-from-planning-dialog.content'}
      />
    </DialogModal>
  );
}

RemoveDraftFromPlanningDialog.propTpyes = {
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default RemoveDraftFromPlanningDialog;
