import i18next from 'i18next';
import React from 'react';
import { TrafficIcon } from 'semji-core/icons/TrafficIcon';

import {
  ENUM_FILTER_TYPE,
  MultiFilterProps,
  MultipleFilterType,
} from '@/components/DynamicFilters';
import { getBrandTrafficOptions } from '@/containers/Competitors/components/HeaderFilters/Filters/BrandTrafficFilter/const';
import { BRAND_TRAFFIC_TYPE_FILTER_QUERY_PARAM } from '@/containers/Competitors/utils/constants';

export function getBrandTrafficFilter({
  updateFilter,
  filter,
}: MultiFilterProps): MultipleFilterType {
  return {
    icon: <TrafficIcon />,
    key: BRAND_TRAFFIC_TYPE_FILTER_QUERY_PARAM,
    name: i18next.t('competitors:filters.tail-traffic-type'),
    onValidate: (value) => updateFilter(BRAND_TRAFFIC_TYPE_FILTER_QUERY_PARAM, value),
    options: getBrandTrafficOptions(filter),
    type: ENUM_FILTER_TYPE.MULTI_SELECT,
  };
}
