import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import useApiConfigurations from '@/hooks/useApiConfigurations';
import { selectCurrentWorkspaceIsContentBriefEnabled } from '@/store/selectors/selectCurrentWorkspaceIsContentBriefEnabled';
import { SemjiHub } from '@/types/common.types';
import { OrganizationAccess, OrganizationAccessRoles } from '@/types/organization';
import { WorkspaceAccess, WorkspaceAccessRoles } from '@/types/workspace';
import { organizationRules, workspaceRules } from '@/utils/can/constants';
import {
  ADOBE_ENABLED,
  AI_WRITING_NEW_UI_ENABLED,
  ANALYSIS_INCOMING_LINKS_CREDITS_ENABLED,
  COMPETITORS_ENABLED,
  CONTENT_AI_WRITING_ENABLED,
  CONTENT_AI_WRITING_KEY_POINTS_ENABLED,
  CONTENT_IDEAS_CLUSTERING_DISPLAYED,
  CONTENT_IDEAS_ENABLED,
  CONTENT_VERSION_HISTORY_ENABLED,
  EDITOR_COMMENTS_ENABLED,
  MATOMO_ENABLED,
  PAGE_SNAPSHOT_ENABLED,
  PDF_BUILD_ENABLED,
  PIWIK_PRO_ENABLED,
  PLANNING_SYNC_CALENDAR_ENABLED,
  RANK_TRACKING_ENABLED,
  SELF_SERVE_ENABLED,
  UNIVERSAL_SEARCH_GLOBAL_ENABLED,
  USER_REGISTER_ENABLED,
  USER_REGISTER_GOOGLE_ENABLED,
  USER_SIGNIN_FACEIO_ENABLED,
} from '@/utils/configurations/constants';

function useCan({ perform, data, hubs }: { perform: string; data?: any; hubs?: SemjiHub[] }) {
  const user = useSelector((state: any) => state.user ?? null);
  const uiMode = useSelector((state: any) => state.uiMode);
  const currentOrganizationId = useSelector((state: any) => state.defaultOrganizationId ?? null);
  const currentWorkspaceId = useSelector((state: any) => state.defaultWorkspaceId ?? null);

  const userRegisterEnabled = useApiConfigurations(USER_REGISTER_ENABLED);
  const userRegisterGoogleEnabled = useApiConfigurations(USER_REGISTER_GOOGLE_ENABLED);
  const analysisIncomingLinksCreditsEnabled = useApiConfigurations(
    ANALYSIS_INCOMING_LINKS_CREDITS_ENABLED
  );
  const pageSnapshotEnabled = useApiConfigurations(PAGE_SNAPSHOT_ENABLED);
  const contentBriefEnabled = useSelector(selectCurrentWorkspaceIsContentBriefEnabled);
  const pdfBuildEnabled = useApiConfigurations(PDF_BUILD_ENABLED);
  const contentAiWritingEnabled = useApiConfigurations(CONTENT_AI_WRITING_ENABLED);
  const contentIdeasEnabled = useApiConfigurations(CONTENT_IDEAS_ENABLED);
  const competitorsEnabled = useApiConfigurations(COMPETITORS_ENABLED);
  const contentVersionHistoryEnabled = useApiConfigurations(CONTENT_VERSION_HISTORY_ENABLED);

  const contentAiWritingKeyPointsEnabled = useApiConfigurations(
    CONTENT_AI_WRITING_KEY_POINTS_ENABLED
  );
  const contentIdeasClusteringDisplayed = useApiConfigurations(CONTENT_IDEAS_CLUSTERING_DISPLAYED);
  const isSelfServeEnabled = useApiConfigurations(SELF_SERVE_ENABLED);
  const universalSearchGlobalEnabled = useApiConfigurations(UNIVERSAL_SEARCH_GLOBAL_ENABLED);
  const planningSyncCalendarEnabled = useApiConfigurations(PLANNING_SYNC_CALENDAR_ENABLED);
  const isSigninWithFIOEnabled = useApiConfigurations(USER_SIGNIN_FACEIO_ENABLED);
  const isMatomoEnabled = useApiConfigurations(MATOMO_ENABLED);
  const isAdobeEnabled = useApiConfigurations(ADOBE_ENABLED);
  const isPiwikProEnabled = useApiConfigurations(PIWIK_PRO_ENABLED);
  const isEditorCommentsEnabled = useApiConfigurations(EDITOR_COMMENTS_ENABLED);
  const isRankTrackingEnabled = useApiConfigurations(RANK_TRACKING_ENABLED);
  const isAIWritingNewUiEnabled = useApiConfigurations(AI_WRITING_NEW_UI_ENABLED); // TO REMOVE

  const isAuthorized = useMemo(() => {
    let userPermissions: {
      dynamic: Record<string, any>;
      static: string[];
    } = {
      dynamic: {},
      static: [],
    };
    // Those are global feature flags cross Organizations/Workspaces
    let apiFeatureFlagPermissions = {
      dynamic: {
        'adobe:enabled': () => isAdobeEnabled,
        'ai-writing:new-ui:enabled': () => isAIWritingNewUiEnabled,
        'content-brief:visit': () => contentBriefEnabled,
        'content:ai-writing': () => contentAiWritingEnabled,
        'content:ai-writing-key-points': () => contentAiWritingKeyPointsEnabled,
        'content_ideas.clustering.displayed': () => contentIdeasClusteringDisplayed,
        'create-organization:self-serve': () => userRegisterEnabled,
        'editor.comments.enabled': () => isEditorCommentsEnabled,
        'incoming-links:credits': () => analysisIncomingLinksCreditsEnabled,
        'matomo:enabled': () => isMatomoEnabled,
        'page:snapshot': () => pageSnapshotEnabled,
        'pdf:build': () => pdfBuildEnabled,
        'piwik-pro:enabled': () => isPiwikProEnabled,
        'planning:sync_calendar': () => planningSyncCalendarEnabled,
        'rank-tracking:enabled': () => isRankTrackingEnabled,
        'self-serve:enabled': () => isSelfServeEnabled,
        'sign-in:self-serve': () => userRegisterEnabled,
        'sign-in:with-faceio': () => isSigninWithFIOEnabled,
        'sign-in:with-google': () => userRegisterGoogleEnabled,
        'universal-search:global': () => universalSearchGlobalEnabled,
      },
      static: [],
    };

    if (user.superAdmin && uiMode === 'advanced') {
      return true;
    }

    const currentOrganizationRoles =
      (user?.organizationAccesses || []).find(
        (organizationAccess: OrganizationAccess) =>
          organizationAccess.organization.id === currentOrganizationId
      )?.roles || [];
    const currentWorkspaceRoles =
      (user?.workspaceAccesses || []).find(
        (workspaceAccess: WorkspaceAccess) => workspaceAccess.workspace.id === currentWorkspaceId
      )?.roles || [];

    currentOrganizationRoles.forEach((role: OrganizationAccessRoles) => {
      const organizationRoleRules = organizationRules[role];

      if (organizationRoleRules) {
        userPermissions = {
          dynamic: { ...userPermissions.dynamic, ...organizationRoleRules.dynamic },
          static: [...userPermissions.static, ...organizationRoleRules.static],
        };
      }
    });

    currentWorkspaceRoles.forEach((role: WorkspaceAccessRoles) => {
      const workspaceRoleRules = workspaceRules[role];

      // This is to set a global api feature flags that give access only for some roles
      if (role === WorkspaceAccessRoles.MEMBER || role === WorkspaceAccessRoles.OWNER) {
        apiFeatureFlagPermissions = {
          dynamic: {
            ...apiFeatureFlagPermissions.dynamic,
            'competitors:visit': ({ accessGranted }: { accessGranted: boolean }) =>
              accessGranted && competitorsEnabled,
            'content-ideas:visit': ({ accessGranted }: { accessGranted: boolean }) =>
              accessGranted && contentIdeasEnabled,
            'content:version-history:visit': ({ accessGranted }: { accessGranted: boolean }) =>
              accessGranted && contentVersionHistoryEnabled,
          },
          static: [...apiFeatureFlagPermissions.static],
        };
      }

      if (workspaceRoleRules) {
        userPermissions = {
          dynamic: { ...userPermissions.dynamic, ...workspaceRoleRules.dynamic },
          static: [...userPermissions.static, ...workspaceRoleRules.static],
        };
      }
    });

    userPermissions = {
      dynamic: {
        ...userPermissions.dynamic,
        ...apiFeatureFlagPermissions.dynamic,
      },
      static: [...userPermissions.static, ...apiFeatureFlagPermissions.static],
    };

    if (userPermissions.static.includes(perform)) {
      return true;
    }

    const dynamicPermissionCondition = userPermissions.dynamic[perform];

    if (dynamicPermissionCondition) {
      return dynamicPermissionCondition(data);
    }

    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentOrganizationId,
    currentWorkspaceId,
    data,
    isSigninWithFIOEnabled,
    perform,
    uiMode,
    user?.organizationAccesses,
    user.superAdmin,
    user?.workspaceAccesses,
    userRegisterEnabled,
    userRegisterGoogleEnabled,
    analysisIncomingLinksCreditsEnabled,
    contentBriefEnabled,
    pageSnapshotEnabled,
    universalSearchGlobalEnabled,
  ]);

  return isAuthorized;
}

export default useCan;
