import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import apiClient from '@/apis/semji/apiClient';
import { LOCATION, SCOPE_COMPETITORS } from '@/apis/semji/constants';
import { QueryOptions } from '@/apis/types/query.types';
import { showErrorSnackbar } from '@/store/actions/ui';
import { CompetitorWebsite } from '@/types/competitors';

interface UseGetCompetitorConfigurationWebsitesProps extends QueryOptions<{}, CompetitorWebsite[]> {
  competitorConfigurationId: string;
}

export default function useGetCompetitorConfigurationWebsites({
  competitorConfigurationId,
  ...props
}: UseGetCompetitorConfigurationWebsitesProps): UseQueryResult<CompetitorWebsite[]> {
  const { get } = apiClient;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const query = useQuery({
    onError: () => {
      dispatch(showErrorSnackbar(t('common:error.default')));
    },
    placeholderData: [],
    queryFn: async ({ signal }) => {
      const result = await get(
        `/competitor_configurations/${competitorConfigurationId}/competitor_websites`,
        {
          signal,
        }
      );

      return result.data['hydra:member'] || [];
    },
    queryKey: [
      SCOPE_COMPETITORS.GET_COMPETITOR_CONFIGURATION_WEBSITES,
      competitorConfigurationId,
      LOCATION.COMPETITORS,
    ],
    ...props,
  });

  return query;
}
