import SvgWrapper from './SvgWrapper';

function CheckboxOnIcon(props) {
  return (
    <SvgWrapper viewBox="0 0 24 25" {...props}>
      <path
        clipRule="evenodd"
        d="M5 3.5H19C20.11 3.5 21 4.4 21 5.5V19.5C21 20.6 20.11 21.5 19 21.5H5C3.89 21.5 3 20.6 3 19.5V5.5C3 4.4 3.89 3.5 5 3.5ZM5 12.5L10 17.5L19 8.5L17.59 7.08L10 14.67L6.41 11.09L5 12.5Z"
        fill="#FF4D64"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}

export default CheckboxOnIcon;
