import { CompetitorWebsite } from '@/types/competitors';

export const CURRENT_NB_PAGES = 'currentNbPages';
export const PREVIOUS_NB_PAGES = 'previousNbPages';

export interface Competitor extends CompetitorWebsite {
  error?: string;
  color?: string;
  nbPages?: number | null;
  [CURRENT_NB_PAGES]?: number;
  [PREVIOUS_NB_PAGES]?: number;
}
