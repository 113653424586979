import './EmptyState.scss';

import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { NavigationButton } from 'semji-core/components/Button';

import SearchIntelligence from '@/assets/images/search-intelligence-icon.svg';
import { getEmptyStateImage } from '@/containers/Competitors/utils/utils';
import { SUPPORT_ADDRESS_MAIL } from '@/utils/constants';

export default function CompetitorEmptyState() {
  const { t, i18n } = useTranslation();
  return (
    <div className="competitors-empty-state">
      <div className="competitors-empty-state__text">
        <img alt="" className="competitors-empty-state__icon" src={SearchIntelligence} />
        <h2 className="competitors-empty-state__text__title">
          {t('competitors:empty-state.title')}
        </h2>
        <p className="competitors-empty-state__text__description">
          <Trans components={{ br: <br /> }} i18nKey="competitors:empty-state.description" />
        </p>
        <NavigationButton
          className="competitors-empty-state__text__button"
          href={`mailto:${SUPPORT_ADDRESS_MAIL}`}
          rel="noreferrer"
          target="_blank"
        >
          {t('competitors:empty-state.contact-us')}
        </NavigationButton>
      </div>
      <img
        alt=""
        className="competitors-empty-state__image"
        src={getEmptyStateImage(i18n.language)}
      />
    </div>
  );
}
