import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import Loader from '@/components/Loader/Loader';
import { TabBodyContent } from '@/components/Navigation/Tabs';
import UsersSettings from '@/components/Settings/UsersSettings';
import OrganizationService from '@/services/Organization';
import OrganizationAccessesService, {
  TYPE_INVITE_ACTION,
  TYPE_RESEND_ACTION,
} from '@/services/OrganizationAccesses';
import UserService from '@/services/User';
import { fetchCurrentOrganizationEditableData } from '@/store/actionsCreator/organization';
import { getOrganizationById } from '@/store/reducers';
import { organizationUserRoles } from '@/utils/can/constants';
import { getEmailDomainFromUrl } from '@/utils/email';

const StyledTabBodyContent = styled(TabBodyContent)`
  display: flex;
  height: 100%;
  box-sizing: border-box;
`;

function UsersSettingsContainer(props) {
  const dispatch = useDispatch();
  const { organizationId } = useParams();
  const [organizationAccesses, setOrganizationAccesses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [organizationDomain, setOrganizationDomain] = useState(null);
  const _OrganizationService = new OrganizationService(organizationId);
  const currentUser = useSelector((state) => state.user);
  const availableWorkspaces = useSelector(
    (state) => getOrganizationById(state, organizationId)?.workspaces
  );

  useEffect(() => {
    fetchAccesses();
    fetchDomains();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  const fetchAccesses = async () => {
    const _OrganizationAccessesService = new OrganizationAccessesService(null, organizationId);

    const organizationAccesses = await _OrganizationAccessesService.organizationAccesses;

    setOrganizationAccesses(organizationAccesses);
    setLoading(false);
  };

  const fetchDomains = async () => {
    const samlDomains = await _OrganizationService.samlDomains;

    setOrganizationDomain(samlDomains[0]?.domainName);
  };

  const setUserRole = async (organizationAccessesId, role) => {
    const _OrganizationAccessesService = new OrganizationAccessesService(
      organizationAccessesId,
      organizationId
    );

    await _OrganizationAccessesService.setOrganizationAccesses(role);
    fetchAccesses();
  };

  const setUserWebextensionAccess = async (organizationAccessesId, accessToWebExtension) => {
    const _OrganizationAccessesService = new OrganizationAccessesService(
      organizationAccessesId,
      organizationId
    );

    await _OrganizationAccessesService.setOrganizationAccessesWebextension(accessToWebExtension);
    fetchAccesses();
  };

  const deleteUserAccess = async (organizationAccessesId) => {
    const _OrganizationAccessesService = new OrganizationAccessesService(
      organizationAccessesId,
      organizationId
    );
    await _OrganizationAccessesService.deleteOrganizationAccesses();

    setOrganizationAccesses(organizationAccesses.filter((oa) => oa.id !== organizationAccessesId));
    dispatch(fetchCurrentOrganizationEditableData(organizationId));
  };

  const sendInvites = async (emails, workspaces) => {
    const _OrganizationAccessesService = new OrganizationAccessesService(null, organizationId);
    const uniqEmails = [...new Set(emails)];
    const listUsers = uniqEmails.map((email) => ({ email }));
    if (workspaces) {
      const listWorkspaces = workspaces.map((w) => ({ id: w.id }));
      await _OrganizationAccessesService.inviteUsers(listUsers, listWorkspaces, TYPE_INVITE_ACTION);
    } else {
      await _OrganizationAccessesService.inviteUsers(listUsers, null, TYPE_RESEND_ACTION);
    }

    await fetchAccesses();
    dispatch(fetchCurrentOrganizationEditableData(organizationId));
  };

  const handleResetPassword = async (email) => {
    const _userService = new UserService();
    await _userService.resetPasswordForEmail(email);
  };

  if (loading) {
    return (
      <StyledTabBodyContent>
        <Loader />
      </StyledTabBodyContent>
    );
  }

  const filteredWorkspaces = availableWorkspaces.filter((w) => !w?.disabledAt);

  return (
    <StyledTabBodyContent>
      <UsersSettings
        accesses={organizationAccesses}
        accessName={organizationAccesses[0]?.organization?.name}
        alreadyInvitedUsers={[]}
        availableUsers={organizationAccesses.map((oa) => oa.user.email)}
        availableWorkspaces={filteredWorkspaces}
        currentUser={currentUser}
        deleteUserAccess={deleteUserAccess}
        emailDomain={organizationDomain ?? getEmailDomainFromUrl(filteredWorkspaces[0]?.websiteUrl)}
        handleResetPassword={handleResetPassword}
        keyName="organization"
        organizationId={organizationId}
        roles={organizationUserRoles}
        sendInvites={sendInvites}
        setUserRole={setUserRole}
        setUserWebextensionAccess={setUserWebextensionAccess}
        title={props.title}
        withWebextensionSettings
      />
    </StyledTabBodyContent>
  );
}

export default UsersSettingsContainer;
