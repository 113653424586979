import { useDispatch } from 'react-redux';
import { matchRoutes, useLocation } from 'react-router-dom';

import { updateWorkspaceRoute } from '@/store/actions/workspaceRouteHistory';

const CATEGORY_MATCHES = [
  'dashboard',
  'contents',
  'content-ideas',
  'onboarding',
  'planning',
  'search-intelligence',
  'reports',
  'settings',
];
const SUBCATEGORY_EXCLUSIONS = ['create-drafts', 'import-pages'];

function useUpdateWorkspaceRouteHistory() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname, search } = location;
  const matches = matchRoutes(
    [
      { path: '/o/:organizationId/w/:workspaceId/:category/:subCategory/:section' },
      { path: '/o/:organizationId/w/:workspaceId/:category/:subCategory' },
      {
        path: '/o/:organizationId/w/:workspaceId/:category',
      },
    ],
    location
  );

  const { params } = matches?.[0] ?? {};

  if (
    CATEGORY_MATCHES.includes(params?.category) &&
    !SUBCATEGORY_EXCLUSIONS.includes(params?.subCategory)
  ) {
    dispatch(updateWorkspaceRoute(`${pathname}${search}`));
  }
}

export default useUpdateWorkspaceRouteHistory;
