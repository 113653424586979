import { useMemo } from 'react';

import { LOCATION } from '@/apis/semji/constants';
import useGetContents from '@/apis/semji/contents/useGetContents';
import useGetCurrentWorkspacePagesWithSpecificProperties from '@/apis/semji/pages/useGetCurrentWorkspacePagesWithSpecificProperties';
import useGetReportPageMetrics from '@/apis/semji/reports/useGetReportPageMetrics';
import { DEFAULT_FOLDER_GENERAL } from '@/components/Select/FolderSelect/constants';
import { PAGES_TABLE_PROPERTIES } from '@/containers/Report/utils/constants';
import { PageWithMetrics, ReportView } from '@/containers/Report/utils/types';
import { NULL_USER } from '@/hooks/useNullUser';
import { Content } from '@/types/contents';
import { Page } from '@/types/pages';
import { ReportPeriod, ReportPeriodicity } from '@/types/reports';
import { getViewFilter } from '@/utils/helper';
import listByPropertyReducer, { listOfListByPropertyReducer } from '@/utils/listByPropertyReducer';
import { getComparableDayWithToday } from '@/utils/metrics/getComparableDayWithToday';
import { getComparisonDates } from '@/utils/metrics/getComparisonDates';

export default function usePagesWithMetrics({
  comparisonPeriod,
  period,
}: {
  period: ReportPeriod;
  comparisonPeriod: string;
}) {
  const {
    data: contents = [],
    isLoading: isContentsLoading,
    isPlaceholderData: isContentPlaceholderData,
  } = useGetContents({
    properties: ['id', 'pageId', 'folderId', 'assignedToId'],
  });

  const positionComparisonDates = useMemo(
    () => getComparableDayWithToday({ comparisonPeriod, period }),
    [period, comparisonPeriod]
  );
  const comparisonDates = useMemo(
    () => getComparisonDates({ comparisonPeriod, period }),
    [period, comparisonPeriod]
  );

  const {
    data: currentPageMetricsData = [],
    isLoading: isCurrentPageMetricsDataLoading,
    isPlaceholderData: isCurrentMetricsPlaceholderData,
  } = useGetReportPageMetrics({
    filters: {
      ...getViewFilter(ReportView.All),
      date: {
        after: comparisonDates.currentRange.after,
        before: comparisonDates.currentRange.before,
      },
      groupByPage: true,
      periodicity: ReportPeriodicity.Monthly,
    },
  });

  const {
    data: previousPageMetricsData = [],
    isLoading: isPreviousPageMetricsDataLoading,
    isPlaceholderData: isPreviousMetricsPlaceholderData,
  } = useGetReportPageMetrics({
    filters: {
      ...getViewFilter(ReportView.All),
      date: {
        after: comparisonDates.previousRange.after,
        before: comparisonDates.previousRange.before,
      },
      groupByPage: true,
      periodicity: ReportPeriodicity.Monthly,
    },
  });

  const {
    data: last2daysMetricsData = [],
    isLoading: isLast2daysMetricsDataLoading,
    isPlaceholderData: islast2daysMetricsPlaceholderData,
  } = useGetReportPageMetrics({
    filters: {
      ...getViewFilter(ReportView.All),
      date: {
        after: comparisonDates.yesterday,
        before: comparisonDates.today,
      },
      groupByPage: true,
      periodicity: ReportPeriodicity.Daily,
    },
  });

  const {
    data: previousPageDayMetricsData = [],
    isLoading: isPreviousPageDayMetricsDataLoading,
    isPlaceholderData: isPreviousPageDayMetricsPLaceholderData,
  } = useGetReportPageMetrics({
    filters: {
      ...getViewFilter(ReportView.All),
      date: {
        after: positionComparisonDates.after,

        before: positionComparisonDates.after,
      },
      groupByPage: true,
      periodicity: ReportPeriodicity.Daily,
    },
  });

  const {
    data: pagesData = [],
    isLoading: isPagesLoading,
    isPlaceholderData: isPagesPlaceholderData,
  } = useGetCurrentWorkspacePagesWithSpecificProperties({
    filters: {
      ...getViewFilter(ReportView.All),
      'exists[url]': true,
      inStock: false,
    },
    location: LOCATION.REPORTS,
    properties: PAGES_TABLE_PROPERTIES,
  });

  const isLoading =
    isContentsLoading ||
    isContentPlaceholderData ||
    isCurrentPageMetricsDataLoading ||
    isCurrentMetricsPlaceholderData ||
    isPreviousPageMetricsDataLoading ||
    isPreviousMetricsPlaceholderData ||
    isLast2daysMetricsDataLoading ||
    islast2daysMetricsPlaceholderData ||
    isPreviousPageDayMetricsDataLoading ||
    isPreviousPageDayMetricsPLaceholderData ||
    isPagesLoading ||
    isPagesPlaceholderData;

  function extendPagesWithMonthlyPageMetrics(pages: Page[]) {
    if (isLoading) return [];
    const contentsByPageId = contents.reduce(listOfListByPropertyReducer('pageId'), {});
    const currentPageMetricsDataByPageId = currentPageMetricsData.reduce(
      listByPropertyReducer('pageId'),
      {}
    );
    const previousPageMetricsDataByPageId = previousPageMetricsData.reduce(
      listByPropertyReducer('pageId'),
      {}
    );
    const last2daysPageMetricsDataByPageId = last2daysMetricsData.reduce(
      listOfListByPropertyReducer('pageId'),
      {}
    );
    const previousPageDayMetricsDataByPageId = previousPageDayMetricsData.reduce(
      listByPropertyReducer('pageId'),
      {}
    );

    return pages.map((page) => {
      // this is used for positions
      const dailyPageMetrics = {
        last2days: last2daysPageMetricsDataByPageId[page.id],
        [positionComparisonDates.after]: previousPageDayMetricsDataByPageId[page.id],
      };

      // all this is used to filter on the position and evolution of the position
      // if position is not known, we consider it to be 100
      const previousPosition =
        dailyPageMetrics?.[positionComparisonDates.after]?.focusTopKeywordPosition ?? 100;
      const isPositionBeyondTop100Today =
        dailyPageMetrics?.last2days?.[1]?.focusTopKeywordsPositionBeyondTop100;
      const todayPosition = dailyPageMetrics?.last2days?.[1]?.focusTopKeywordPosition;
      const yesterdayPosition = dailyPageMetrics?.last2days?.[0]?.focusTopKeywordPosition;
      const currentPosition =
        (!isPositionBeyondTop100Today ? todayPosition : yesterdayPosition) ?? 100;

      return {
        ...page,
        // assigned to IDs for filtering
        assignedToIds: contentsByPageId[page.id]?.map(
          (c: Content) => c.assignedToId ?? NULL_USER.id
        ),
        // current position for filtering
        currentPosition,
        dailyPageMetrics: dailyPageMetrics,
        // evolution of the position for filtering
        evolution: previousPosition - currentPosition,
        // folders IDs for filtering
        folderIds: contentsByPageId[page.id]?.map(
          (c: Content) => c.folderId ?? DEFAULT_FOLDER_GENERAL.id
        ),
        monthlyPageMetrics: {
          [comparisonDates.currentRange.after]: currentPageMetricsDataByPageId[page.id],
          [comparisonDates.previousRange.after]: previousPageMetricsDataByPageId[page.id],
        },
      };
    });
  }

  const pages: PageWithMetrics[] = extendPagesWithMonthlyPageMetrics(pagesData);

  return {
    isLoading,
    pages,
  };
}
