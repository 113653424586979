import React from 'react';

import SvgWrapper from './SvgWrapper';

const SmallCapIcon = (props) => (
  <SvgWrapper {...props}>
    <path d="M6 5.925h1v2h-1v-1h-2v8h1v1h-3v-1h1v-8h-2v1h-1v-2h6zM16 2h-10v2.577h1v-1.577h4v12h-1v1h3v-1h-1v-12h4v1.577h1v-2.577h-1z" />
  </SvgWrapper>
);

export default SmallCapIcon;
