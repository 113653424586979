import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';

import UnlockImage from '@/assets/images/unlock-image.svg';
import DialogInformationTemplate from '@/components/Dialog/DialogInformationTemplate';
import PrimaryButton from '@/design-system/components/Button/PrimaryButton';
import { showUpgradePlanModal } from '@/store/actions/billing';
import {
  ANALYSIS_CREDITS_TYPE,
  INCOMING_LINKS_CREDITS_TYPE,
  SEARCH_INTENTS_CREDITS_TYPE,
} from '@/utils/constants';

const PrimaryBtn = styled(PrimaryButton)`
  min-width: 270px;
`;

function CreditLimitReachedDialog({ onClose, isOpen, creditType }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const TEXTS = {
    [ANALYSIS_CREDITS_TYPE]: {
      content: (
        <Trans
          components={{
            br: <br />,
          }}
          i18nKey={'components:dialog.credit-limit-reached.analysis-content'}
        />
      ),
      title: t('components:dialog.credit-limit-reached.analysis-title'),
    },
    [INCOMING_LINKS_CREDITS_TYPE]: {
      content: (
        <Trans
          components={{
            br: <br />,
          }}
          i18nKey={'components:dialog.credit-limit-reached.incoming-links-content'}
        />
      ),
      title: t('components:dialog.credit-limit-reached.incoming-links-title'),
    },
    [SEARCH_INTENTS_CREDITS_TYPE]: {
      content: (
        <Trans
          components={{
            br: <br />,
          }}
          i18nKey={'components:dialog.credit-limit-reached.search-intents-content'}
        />
      ),
      title: t('components:dialog.credit-limit-reached.search-intents-title'),
    },
  };

  const { title = '', content = '' } = TEXTS[creditType] || {};

  function openUpgradeModal() {
    onClose();
    dispatch(showUpgradePlanModal());
  }

  return (
    isOpen && (
      <DialogInformationTemplate
        customButton={
          <PrimaryBtn onClick={openUpgradeModal}>
            {t('components:dialog.credit-limit-reached.upgrade-plan')}
          </PrimaryBtn>
        }
        imageAlt={t('components:dialog.credit-limit-reached.image-alt')}
        imagePath={UnlockImage}
        show={isOpen}
        title={title}
        onClose={onClose}
      >
        {content}
      </DialogInformationTemplate>
    )
  );
}

CreditLimitReachedDialog.propTypes = {
  creditType: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default CreditLimitReachedDialog;
