import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import apiClient from '@/apis/semji/apiClient';
import { showErrorSnackbar } from '@/store/actions/ui';

export default function usePostATInternetSpaces(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { post } = apiClient;

  return useMutation({
    mutationFn: async ({ apiKey }) =>
      await post(`/services_integrations/at_internet_spaces`, { apiKey }),

    onError: () => {
      dispatch(showErrorSnackbar(t('common:error.default')));
    },
    ...props,
  });
}
