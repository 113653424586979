import {
  CHANGE_UI_MODE,
  LOGIN_SUCCESS,
  SNACKBAR_CLEAR,
  SNACKBAR_DEFAULT,
  SNACKBAR_ERROR,
  SNACKBAR_HINT,
  SNACKBAR_INFO,
  SNACKBAR_SUCCESS,
  SNACKBAR_WARNING,
} from '@/store/actions/actionTypes';

const initialState = {
  snackbarActions: [],
  snackbarMessage: null,
  snackbarOpen: null,
};

export const ui = (state = initialState, action) => {
  switch (action.type) {
    case SNACKBAR_SUCCESS:
      return {
        ...state,
        snackbarActions: action.actions,
        snackbarMessage: action.message,
        snackbarOpen: 'success',
      };
    case SNACKBAR_HINT:
      return {
        ...state,
        snackbarActions: action.actions,
        snackbarMessage: action.message,
        snackbarOpen: 'hint',
      };
    case SNACKBAR_WARNING:
      return {
        ...state,
        snackbarActions: action.actions,
        snackbarMessage: action.message,
        snackbarOpen: 'warning',
      };
    case SNACKBAR_INFO:
      return {
        ...state,
        snackbarActions: action.actions,
        snackbarMessage: action.message,
        snackbarOpen: 'info',
      };
    case SNACKBAR_ERROR:
      return {
        ...state,
        snackbarActions: action.actions,
        snackbarMessage: action.message,
        snackbarOpen: 'error',
      };
    case SNACKBAR_CLEAR:
      return {
        ...state,
        snackbarActions: [],
        snackbarMessage: null,
        snackbarOpen: null,
      };
    case SNACKBAR_DEFAULT:
      return {
        ...state,
        snackbarActions: action.actions,
        snackbarMessage: action.message,
        snackbarOpen: 'default',
      };
    default:
      return state;
  }
};

export const uiMode = (state = 'standard', action) => {
  switch (action.type) {
    case CHANGE_UI_MODE:
      if (action.isUserSuperAdmin) {
        return state === 'standard' ? 'advanced' : 'standard';
      }
      return state;
    case LOGIN_SUCCESS:
      return 'standard';
    default:
      return state;
  }
};
