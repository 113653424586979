import React from 'react';

import SvgWrapper from './SvgWrapper';

const OutlinedLockIcon = (props) => (
  <SvgWrapper {...props} viewBox="0 0 14 17">
    <path d="M8.49995 10.9722C8.49995 11.3382 8.29295 11.6442 7.99995 11.8182V13.9722H6.99995V11.8192C6.70595 11.6452 6.49995 11.3392 6.49995 10.9722C6.49995 10.4202 6.94695 9.97217 7.49995 9.97217C8.05095 9.97217 8.49995 10.4192 8.49995 10.9722ZM13.965 8.46017V15.5402C13.965 16.3672 13.292 17.0402 12.465 17.0402H2.47595C1.64895 17.0402 0.975952 16.3672 0.975952 15.5402V8.46017C0.975952 7.63317 1.64895 6.96017 2.47595 6.96017H2.49995V4.97217C2.49995 2.21517 4.74295 -0.027832 7.49995 -0.027832C10.257 -0.027832 12.5 2.21517 12.5 4.97217V6.96417C13.311 6.98217 13.965 7.64517 13.965 8.46017ZM3.49995 6.96017H11.5V4.97217C11.5 2.76617 9.70595 0.972168 7.49995 0.972168C5.29395 0.972168 3.49995 2.76617 3.49995 4.97217V6.96017ZM12.965 8.46017C12.965 8.19617 12.758 7.98617 12.5 7.96717V7.97117H2.49995V7.96017H2.47595C2.20095 7.96017 1.97595 8.18417 1.97595 8.46017V15.5402C1.97595 15.8162 2.20095 16.0402 2.47595 16.0402H12.465C12.74 16.0402 12.965 15.8162 12.965 15.5402V8.46017Z" />
  </SvgWrapper>
);

export default OutlinedLockIcon;
