import Slide from '@material-ui/core/Slide';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import WarningMessage from '@/components/FlashMessage/WarningMessage';
import CircleLoader from '@/components/Loader/CircleLoader';
import useRecursiveTimeout from '@/hooks/useRecursiveTimeout';

export const Title = styled.span`
  font-size: 1.5rem;
  text-align: center;
  color: ${(props) => props.theme.cssColors.dark100};
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const Subtitle = styled.span`
  font-size: 0.9rem;
  color: ${(props) => props.theme.cssColors.dark060};
  margin: 10px 10%;
  text-align: center;
  line-height: 20px;
`;

const SentencesWrapper = styled((props) => <div {...props} />)`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const SentenceItem = styled(({ children, ...props }) => (
  <div {...props}>
    <span className="label">{children}</span>
  </div>
))`
  width: 100%;
  height: 0;
  font-size: 1rem;
  color: ${(props) => props.theme.cssColors.dark060};

  .label {
    display: flex;
    justify-content: center;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  justify-self: stretch;
  flex-grow: 1;
`;

const PendingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  justify-self: stretch;
  flex-grow: 1;
  overflow: hidden;
`;

const WAITING_SENTENCES = [
  'components:optimization.focus-keyword-analysis-pending.waiting-identifying-competitors-label',
  'components:optimization.focus-keyword-analysis-pending.waiting-extracting-keywords-label',
  'components:optimization.focus-keyword-analysis-pending.waiting-aggregating-metrics-label',
  'components:optimization.focus-keyword-analysis-pending.waiting-analyzing-topics-label',
  'components:optimization.focus-keyword-analysis-pending.waiting-identifying-questions-label',
  'components:optimization.focus-keyword-analysis-pending.waiting-doing-science-label',
  'components:optimization.focus-keyword-analysis-pending.waiting-estimating-content-score-label',
  'components:optimization.focus-keyword-analysis-pending.waiting-computing-recommendations-label',
  'components:optimization.focus-keyword-analysis-pending.waiting-solving-complex-equations-label',
];

/**
 * MATERIAL UI v4
 * CANNOT work with a functionnal component
 * https://material-ui.com/guides/composition/#caveat-with-refs
 * ERROR: Function components cannot be given refs. Attempts to access this ref will fail. Did you mean to use React.forwardRef()?
 */
class Sentence extends React.PureComponent {
  render() {
    return <SentenceItem {...this.props} />;
  }
}

function FocusKeywordAnalysisPending({ analysisIsAutoUpdating, areManyAnalysisInProgress }) {
  const { t } = useTranslation();
  const [displayedIndex, setDisplayedIndex] = useState(0);

  useRecursiveTimeout(
    () => {
      let newDisplayedIndex = displayedIndex + 1;

      if (newDisplayedIndex === WAITING_SENTENCES.length) {
        newDisplayedIndex = 0;
      }

      setDisplayedIndex(newDisplayedIndex);
    },
    5000,
    true
  );

  let warningMessages = '';

  if (analysisIsAutoUpdating) {
    warningMessages = t(
      'components:optimization.focus-keyword-analysis-pending.analysis-is-auto-updating-warning-message'
    );
  } else if (areManyAnalysisInProgress) {
    warningMessages = t(
      'components:optimization.focus-keyword-analysis-pending.are-many-analysis-in-progress-warning-message'
    );
  }

  return (
    <Wrapper>
      {warningMessages.length > 0 && <WarningMessage extraPadded message={warningMessages} />}
      <PendingWrapper>
        <CircleLoader />
        <Title>{t('components:optimization.focus-keyword-analysis-pending.title-label')}</Title>
        <Subtitle>
          {t('components:optimization.focus-keyword-analysis-pending.subtitle-label')}
        </Subtitle>
        <SentencesWrapper>
          {WAITING_SENTENCES.map((sentence, index) => (
            <Slide
              key={index}
              direction="left"
              in={displayedIndex === index}
              mountOnEnter
              unmountOnExit
            >
              <Sentence>{t(sentence)}</Sentence>
            </Slide>
          ))}
        </SentencesWrapper>
      </PendingWrapper>
    </Wrapper>
  );
}

export default FocusKeywordAnalysisPending;
