import './EmptyEditorContainer.scss';

import React from 'react';

import ContentLoader from '@/containers/Content/ContentLoader';
import { EditorContentWrapper } from '@/containers/Content/Wrappers';

import EmptyEditorContent from './EmptyEditorContent';

function EmptyEditorContainer({
  createNewDraft,
  isCreatingNewDraft,
  isUpdatingPlanning,
}: {
  createNewDraft: (publicId: string | null) => void;
  isCreatingNewDraft: boolean;
  isUpdatingPlanning: boolean;
}) {
  return (
    <div className="empty-editor-container">
      <EditorContentWrapper noShadow>
        <div className="empty-editor-container__body-wrapper">
          {isCreatingNewDraft ? (
            <ContentLoader />
          ) : (
            <EmptyEditorContent
              createNewDraft={createNewDraft}
              isUpdatingPlanning={isUpdatingPlanning}
              showVersionHistoryButton
            />
          )}
        </div>
      </EditorContentWrapper>
    </div>
  );
}

export default EmptyEditorContainer;
