import React from 'react';
import styled from 'styled-components/macro';

import InfoIcon from '@/components/icons/InfoIcon';
import { DarkerText } from '@/components/Text/Dark';

const Wrapper = styled.div`
  display: flex;
  align-items: ${(props) => props.align};
  justify-content: flex-start;
  align-self: stretch;
  background: ${(props) => props.theme.colors.paleOrange};
  padding: ${(props) => (props.extraPadded ? '1em 2em' : '.7rem')};
`;
const StyledInfoIcon = styled(InfoIcon)`
  && {
    font-size: 0.6em;
    margin-right: 1em;
    color: #ffac36;
  }
`;

export default function WarningMessage({ align = 'flex-start', message, extraPadded }) {
  return (
    <Wrapper align={align} extraPadded={extraPadded}>
      <StyledInfoIcon bordered />
      <DarkerText dangerouslySetInnerHTML={{ __html: message }} size="small" />
    </Wrapper>
  );
}
