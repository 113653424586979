import { combineReducers } from 'redux';

import {
  FETCH_ORGANIZATION_SUCCESS,
  FETCH_ORGANIZATION_WORKSPACES_SUCCESS,
  FETCH_USER_ORGANIZATION_EDITABLE_DATA,
  FETCH_USER_ORGANIZATIONS_SUCCESS,
} from '../actions/actionTypes';

const organizationEditableData = [
  'downgradeAuthorized',
  'foldersCount',
  'remainingAiWritingUnit',
  'remainingAnalysisUnit',
  'paymentDunningEndAt',
  'remainingContentIdeasSearchesUnit',
  'remainingContentIdeasSearchesUnit',
  'totalAiWritingUnit',
  'totalAnalysisUnit',
  'totalContentIdeaSearchUnit',
  'paymentProblemOccurred',
  'remainingAnalysisUnit',
  'subscriptionCurrentTermEndAt',
  'subscriptionNextBillingAt',
  'subscriptionResourceVersion',
  'subscriptionStatus',
  'subscriptionTrialEndAt',
  'totalAnalysisUnit',
  'usersCount',
  'workspacesCount',
];

const byId = (state = {}, action) => {
  switch (action.type) {
    // the logic behind this action is to reset only data
    // that can be changed during time
    // so the interface update according to those changes
    case FETCH_USER_ORGANIZATION_EDITABLE_DATA:
      if (state[action.organizationId]) {
        // data that are editable inside the featureSet is not taken into account
        organizationEditableData.forEach((editableDatum) => {
          if (action.organizationData?.[editableDatum]) {
            state[action.organizationId][editableDatum] = action.organizationData[editableDatum];
          }
        });
      }

      // THIS has been added because in many places we connect/useSelector with the organization
      // and we do not have the last value of the editable data because of that (the reference is not updated)
      // this is a quick fix, but the real fix should be to connect every component using editable data
      // with the right one and not with the organization
      return { ...state, [action.organizationId]: state[action.organizationId] };
    case FETCH_USER_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        ...action.organizations.reduce((accumulator, organization) => {
          let indexedOrganization = {};
          indexedOrganization[organization.id] = organization;
          return { ...accumulator, ...indexedOrganization };
        }, {}),
      };
    case FETCH_ORGANIZATION_WORKSPACES_SUCCESS:
      return {
        ...state,
        [action.organizationId]: { ...state[action.organizationId], workspaces: action.workspaces },
      };
    case FETCH_ORGANIZATION_SUCCESS:
      return {
        ...state,
        [action.organization.id]: { ...state[action.organization.id], ...action.organization },
      };
    default:
      return state;
  }
};

const allIds = (state = [], action) => {
  switch (action.type) {
    case FETCH_USER_ORGANIZATIONS_SUCCESS:
      return action.organizations.map((organization) => organization.id);
    case FETCH_ORGANIZATION_SUCCESS:
      return state.indexOf(action.organization.id) === -1
        ? [...state, action.organization.id]
        : state;
    default:
      return state;
  }
};

export default combineReducers({
  allIds,
  byId,
});

export const getById = (state, id) => state.byId[id] || null;
export const getAllIds = (state) => state.allIds;
export const getDictionary = (state) => state.byId;
export const isLoading = (state) => state.loading;
