import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { OptionButton } from 'semji-core/components/OptionButton';
import { MoreIcon } from 'semji-core/icons/MoreIcon';

import {
  SCREEN_OPTION_KEY_SHOW_LABELS,
  ScreenOptionKeyType,
} from '@/containers/Competitors/components/ScreenOptionButton/ScreenOptionButton.types';
import {} from '@/containers/Competitors/utils/constants';
import { setCompetitorDataLabelsEnabled } from '@/store/actions/userConfiguration';
import { selectUserConfiguration } from '@/store/selectors/selectUserConfiguration';

export default function ScreenOptionButton() {
  const { t } = useTranslation();

  const { showCompetitorDataLabels } = useSelector(selectUserConfiguration);
  const dispatch = useDispatch();

  function handleScreenOptionChange(key: ScreenOptionKeyType) {
    return (value: any) => {
      switch (key) {
        case SCREEN_OPTION_KEY_SHOW_LABELS:
          dispatch(setCompetitorDataLabelsEnabled(value));
          break;
        default:
          break;
      }
    };
  }
  return (
    <OptionButton
      dropdownWidth="210px"
      enabled={true}
      icon={<MoreIcon />}
      optionGroups={[
        {
          items: [
            {
              checkable: true,
              checked: showCompetitorDataLabels,
              onClick: handleScreenOptionChange('showLabels'),
              switchPosition: 'start',
              title: t('competitors:screen-options.show-labels'),
            },
          ],
          title: t('competitors:screen-options.title'),
        },
      ]}
    />
  );
}
