import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import LoaderSvg from '@/assets/images/loader.svg';

const Loader = styled.div`
  width: 16rem;
  height: 16rem;
`;

function CircleLoader() {
  const { t } = useTranslation();

  return (
    <Loader>
      <img alt={t('components:loader.circle-loader.alt')} className="img-fluid" src={LoaderSvg} />
    </Loader>
  );
}

export default CircleLoader;
