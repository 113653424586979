import React, { MutableRefObject, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  ERROR_MESSAGE_CREDIT_LIMIT,
  ERROR_MESSAGE_RATE_LIMIT,
} from '@/apis/semji/aiWriting/useStreamModel/const';
import {
  computeTypingSpeed,
  GENERATION_PARAGRAPH_TYPE,
  GENERATION_TITLE_TYPE,
  sleep,
} from '@/containers/Content/EditorComponents/Ai/constants';
import {
  ID_CONTENT,
  ID_METADESCRIPTION_CONTAINER,
  METADESCRIPTION_SELECTOR,
  TITLE_SELECTOR,
} from '@/containers/Content/TinyMceComponents/Editor/constants';
import {
  GeneratedContentType,
  LastGenerationRef,
} from '@/containers/Content/TinyMceComponents/Editor/hooks/useAiWriting/useAiWriting.types';
import { FACT_CHECK_GENERATED_CONTENT_SELECTOR } from '@/containers/Content/TinyMceComponents/Editor/hooks/useAiWriting/useFactCheck/const';
import { FactCheckHookResults } from '@/containers/Content/TinyMceComponents/Editor/hooks/useAiWriting/useFactCheck/useFactCheck.types';
import { isTitleStreamMode } from '@/containers/Content/TinyMceComponents/Editor/hooks/useAiWriting/useStreamContent/helper.utils';
import {
  AbortFactCheckRef,
  AbortRef,
} from '@/containers/Content/TinyMceComponents/Editor/hooks/useAiWriting/useStreamContent/useStreamContent.types';
import { useStreamQueue } from '@/containers/Content/TinyMceComponents/Editor/hooks/useAiWriting/useStreamQueue';
import { ContentUtils } from '@/containers/Content/TinyMceComponents/Editor/hooks/useContent/content.utils';
import useApiConfigurations from '@/hooks/useApiConfigurations';
import { useMixpanelTrackEvent } from '@/hooks/useMixpanelTrackEvent';
import useOrganizationFeatureSet, {
  AI_WRITING__FACT_CHECKING__IS_ENABLED,
} from '@/hooks/useOrganizationFeatureSet';
import { showWarningSnackbar } from '@/store/actions/ui';
import { selectUserConfiguration } from '@/store/selectors/selectUserConfiguration';
import { BrandVoiceModel } from '@/types/brandVoice/brandVoice.types';
import { ATOMIC_CONTENT_STOP } from '@/utils/3rdParty/Mixpanel/constants';
import { STATUS_PENDING, STATUS_QUEUED, STATUS_SUCCESS } from '@/utils/analysis';
import {
  AI_WRITING_FACT_CHECKING_ENABLED,
  COMPLETION_TITLE_PROMPT_CODE,
  CONTENT_AI_WRITING_CREDITS_ENABLED,
} from '@/utils/configurations/constants';

export const BLOCK_TYPES_TO_LOOK_FOR = 'h1, h2, h3, h4, h5, h6';

export function getHasContent(selection: any) {
  return selection?.getContent()?.length > 0;
}

export function isNodeInsideTable(currentNode: any) {
  if (!currentNode) return null;
  if (currentNode.tagName === 'TABLE') return currentNode;
  if (!currentNode.parentNode) return null;
  return isNodeInsideTable(currentNode.parentNode);
}

function useAtomicContent({
  editorRef,
  inputTitleRef,
  currentBrandVoice,
  focusTopKeywordRef,
  factCheckHook,
  triggerSaveRef,
  lastGeneration,
  setRefetchAiUnit,
  askFeedBack,
  textareaMetaDescriptionRef,
  setShowStreamErrorModal,
  setShowLimitAiWriting,
  setShowWorkspaceUnitLimitationModal,
}: {
  editorRef: any;
  inputTitleRef: MutableRefObject<HTMLInputElement>;
  currentBrandVoice: BrandVoiceModel | null;
  focusTopKeywordRef: any;
  triggerSaveRef: any;
  factCheckHook: FactCheckHookResults;
  lastGeneration: MutableRefObject<LastGenerationRef>;
  setRefetchAiUnit: React.Dispatch<React.SetStateAction<number>>;
  askFeedBack: any;
  setShowStreamErrorModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowLimitAiWriting: React.Dispatch<React.SetStateAction<boolean>>;
  setShowWorkspaceUnitLimitationModal: React.Dispatch<React.SetStateAction<boolean>>;
  textareaMetaDescriptionRef: MutableRefObject<HTMLTextAreaElement>;
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { factCheckingEnabled } = useSelector(selectUserConfiguration);
  const aiWritingFactCheckingEnabled = useApiConfigurations(AI_WRITING_FACT_CHECKING_ENABLED);

  const trackMixpanelEvent = useMixpanelTrackEvent();
  const { isFeatureEnabled: hasAccessToMetaDescription } = useOrganizationFeatureSet(
    'contents:meta-description'
  );
  const { isFeatureEnabled: factCheckingFeatureSetEnabled } = useOrganizationFeatureSet(
    AI_WRITING__FACT_CHECKING__IS_ENABLED
  );
  const aiWritingCreditsEnabled = useApiConfigurations(CONTENT_AI_WRITING_CREDITS_ENABLED);
  const titlePromptCode = useApiConfigurations(COMPLETION_TITLE_PROMPT_CODE);
  const isTitleInStreamMode = isTitleStreamMode(titlePromptCode);

  const initAbortRef = {
    abortMutation: undefined,
    aborted: false,
    isTyping: false,
  };
  const initAbortFactCheckRefRef = {
    abortMutation: undefined,
    aborted: false,
  };
  const isAtomicContentGeneratingRef = useRef({ token: null, value: false });
  const typeRef = useRef();
  const loaderRef = useRef<HTMLElement>();
  const abortRef = useRef<AbortRef>(initAbortRef);
  const abortFactCheckRef = useRef<AbortFactCheckRef>(initAbortFactCheckRefRef);

  const { launchAtomicContent, stopQueue, loading, factCheckLoading } = useStreamQueue({
    abortFactCheckRef,
    abortRef,
    afterEachFactCheck: async ({ streamId }) => {
      const generatedContentDiv = document.querySelector(
        `div.${FACT_CHECK_GENERATED_CONTENT_SELECTOR}-${streamId}`
      ) as HTMLElement;
      let previousNode: any = undefined;
      Array.from(generatedContentDiv.childNodes).forEach((childNode) => {
        previousNode = editorRef.current.dom.insertAfter(
          childNode,
          previousNode ?? generatedContentDiv
        );
      });
      generatedContentDiv.remove();
    },
    afterFactCheck: async ({ facts }) => factCheckHook.addNewFactsOptimistically(facts),
    afterQueue: finishAtomicContent,
    brandVoice: currentBrandVoice,
    forEachFactCheck: ({ fact, id }) =>
      factCheckHook.handleFactCheck({
        fact,
        targetNode: document.querySelector(
          `div.${FACT_CHECK_GENERATED_CONTENT_SELECTOR}-${id}`
        ) as HTMLElement,
      }),
    handleError: handleError,
    handleSuccess: handleSuccessInline,
    hasAccessToMetaDescription,
    isAiFactChecked:
      factCheckingEnabled && factCheckingFeatureSetEnabled && aiWritingFactCheckingEnabled,
    isAtomicContent: true,
    lastGenerationRef: lastGeneration,
    mutations: {
      handleMutateContent,
      handleMutateMetaDescription,
      handleMutateTitle,
    },
    payload: {
      editorRef,
      focusTopKeywordRef,
      inputTitleRef,
    },
    setRefetchAiUnit,
    textareaMetaDescriptionRef,
    write: {
      handleWriteContent: handleStreamWriteInline,
      handleWriteTitle: handleWriteTitleInline,
    },
  });

  function handleCancelAtomicContent() {
    trackMixpanelEvent(ATOMIC_CONTENT_STOP, {
      is_fact_checking:
        factCheckingEnabled && factCheckingFeatureSetEnabled && aiWritingFactCheckingEnabled,
    });
    isAtomicContentGeneratingRef.current.value = false;
    isAtomicContentGeneratingRef.current.token = null;
    editorRef.current.mode.set('design');
    inputTitleRef.current.disabled = false;
    textareaMetaDescriptionRef.current.disabled = false;

    inputTitleRef.current.setAttribute('style', '');
    textareaMetaDescriptionRef.current.setAttribute('style', '');

    if (inputTitleRef.current.value === ' ') {
      inputTitleRef.current.value = '';
    }

    if (textareaMetaDescriptionRef.current.value === ' ') {
      textareaMetaDescriptionRef.current.value = '';
    }
    document.getElementById(ID_METADESCRIPTION_CONTAINER)?.click();
    editorRef.current.setDirty(true);
    loaderRef?.current?.remove();
    abortRef.current.aborted = true;
    stopQueue();
    if (!abortRef.current.isTyping && abortRef.current.abortMutation) {
      abortRef.current.abortMutation();
    }
    const editorElement = document.getElementById(ID_CONTENT);
    const childNodes = editorElement?.childNodes ? Array.from(editorElement.childNodes) : [];
    if (childNodes.length > 0) {
      editorRef.current.selection.select(childNodes[0], true);
      editorRef.current.selection.collapse(false);
    } else {
      editorRef.current.setContent(ContentUtils.createEmptyHTML());
    }
    triggerSave();
  }

  function triggerSave() {
    triggerSaveRef.current = true;
  }

  function handleResetAbortRef() {
    abortRef.current = initAbortRef;
  }

  function handleResetTitle() {
    editorRef.current.mode.set('design');
    inputTitleRef.current.setAttribute('style', '');
    inputTitleRef.current.disabled = false;
  }

  function handleResetMetaDescription() {
    editorRef.current.mode.set('design');
    textareaMetaDescriptionRef.current.setAttribute('style', '');
    textareaMetaDescriptionRef.current.disabled = false;
    document.getElementById(ID_METADESCRIPTION_CONTAINER)?.click();
  }

  function handleError(error: any) {
    isAtomicContentGeneratingRef.current.value = false;
    isAtomicContentGeneratingRef.current.token = null;
    const { response } = error;

    if (!abortRef.current.aborted) {
      if (response?.status === 429 || error?.message === ERROR_MESSAGE_RATE_LIMIT) {
        setShowWorkspaceUnitLimitationModal(true);
        stopQueue();
        loaderRef.current?.remove?.();
        loaderRef.current = undefined;
      } else if (error.message === ERROR_MESSAGE_CREDIT_LIMIT) {
        setShowLimitAiWriting(true);
      } else {
        setShowStreamErrorModal(true);
        stopQueue();
        loaderRef.current?.remove?.();
        loaderRef.current = undefined;
      }
      handleResetTitle();
      handleResetMetaDescription();
    } else {
      editorRef.current.focus();
      handleResetTitle();
      handleResetMetaDescription();
      loaderRef.current?.remove?.();
      loaderRef.current = undefined;
    }
    handleResetAbortRef();
  }

  function handleMutateTitle() {
    document
      .querySelector(TITLE_SELECTOR)
      ?.scrollIntoView?.({ behavior: 'smooth', block: 'center' });

    editorRef.current.mode.set('readonly');
    inputTitleRef.current.disabled = true;
    inputTitleRef.current.value = ' ';
    inputTitleRef.current.setAttribute(
      'style',
      'animation-duration: 1s;animation-fill-mode: forwards;animation-iteration-count: infinite;animation-name: placeHolderShimmer;animation-timing-function: linear;background: #f6f7f9;background-image: linear-gradient(to right, #f6f7f9 0%, #e9ebee 20%, #f6f7f9 40%, #f6f7f9 100%);background-repeat: no-repeat;background-size: 800px 104px;height: 24px;position: relative;border-radius: 25px;'
    );
  }

  function handleMutateMetaDescription() {
    document
      .querySelector(METADESCRIPTION_SELECTOR)
      ?.scrollIntoView?.({ behavior: 'smooth', block: 'center' });

    editorRef.current.mode.set('readonly');
    textareaMetaDescriptionRef.current.setAttribute(
      'style',
      'animation-duration: 1s;animation-fill-mode: forwards;animation-iteration-count: infinite;animation-name: placeHolderShimmer;animation-timing-function: linear;background: #f6f7f9;background-image: linear-gradient(to right, #f6f7f9 0%, #e9ebee 20%, #f6f7f9 40%, #f6f7f9 100%);background-repeat: no-repeat;background-size: 800px 104px;height: 22px;position: relative;border-radius: 25px;'
    );
  }

  function handleMutateContent() {
    editorRef.current.focus();
    loaderRef.current = editorRef.current.dom.create(
      'div',
      { class: `ai-loader-wrapper ai-loader-wrapper--${typeRef.current}` },
      ''
    );
    Array(5)
      .fill(() =>
        loaderRef.current?.append(editorRef.current.dom.create('div', { class: `ai-loader` }, ''))
      )
      .forEach((elem) => elem());

    function getEditorContent(element: Element) {
      if (!element?.parentElement) return element;
      if (element?.parentElement?.id !== ID_CONTENT) return getEditorContent(element.parentElement);
      return element;
    }

    const currentNode = getEditorContent(editorRef.current.selection.getNode());

    editorRef.current.dom.insertAfter(loaderRef.current, currentNode);

    if (lastGeneration.current.isRegenerate) {
      lastGeneration.current.nodes?.forEach((node) => node.remove());
    }
    lastGeneration.current.nodes = [];
    loaderRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });

    if (
      currentNode?.tagName === 'P' &&
      currentNode?.childNodes?.length === 1 &&
      currentNode?.childNodes[0]?.tagName === 'BR'
    ) {
      currentNode.remove();
    }
  }

  async function handleSuccessInline(data: any) {
    lastGeneration.current.id = data.streamId;

    if (lastGeneration.current.type === GENERATION_TITLE_TYPE && !isTitleInStreamMode) {
      await handleWriteTitleInline(data.data);
      handleResetAbortRef();
    }
    handleResetAbortRef();
  }

  async function handleWriteTitleInline(data: any) {
    abortRef.current.isTyping = true;
    if (inputTitleRef.current) {
      const generatedTitle = data.title.trim();
      const time = computeTypingSpeed(generatedTitle.length);

      inputTitleRef.current.value = '';
      inputTitleRef.current.setAttribute('style', '');

      for (let i = 0; generatedTitle[i]; i++) {
        if (abortRef.current.aborted) break;
        inputTitleRef.current.value = inputTitleRef.current.value + generatedTitle[i];
        await sleep(time);
      }

      // Trigger save event on input title.
      inputTitleRef.current.dispatchEvent(new Event('input', { bubbles: true }));

      lastGeneration.current = {
        id: data.id,
        isRegenerate: false,
        nodes: [generatedTitle],
        type: GENERATION_TITLE_TYPE,
      };
      triggerSave();
    }
  }

  function handleStreamWriteInline({
    type,
    contents,
    node,
    streamingEnd = false,
  }: {
    type: GeneratedContentType;
    contents: {
      html: string;
    };
    node: HTMLElement;
    streamingEnd: boolean;
  }) {
    if (node) {
      editorRef.current.focus();
      loaderRef.current?.remove?.();
      loaderRef.current = undefined;
      editorRef.current.dom.setHTML(node, contents.html);
      editorRef.current.selection.select(node, true);
      editorRef.current.selection.collapse(false);
    }

    if (streamingEnd) {
      lastGeneration.current.nodes?.[lastGeneration.current.nodes.length - 1].scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      lastGeneration.current = {
        ...lastGeneration.current,
        isRegenerate: false,
        type,
      };
      triggerSave();
    }

    return Promise.resolve();
  }

  async function finishAtomicContent() {
    editorRef.current.mode.set('design');
    inputTitleRef.current.disabled = false;
    textareaMetaDescriptionRef.current.disabled = false;
    editorRef.current.setDirty(true);

    askFeedBack({
      id: lastGeneration.current.id,
      text:
        lastGeneration.current?.nodes?.length === 1
          ? lastGeneration.current?.type === GENERATION_TITLE_TYPE
            ? lastGeneration.current?.nodes[0]?.trim()
            : lastGeneration.current?.nodes[0]?.innerHTML?.trim()
          : lastGeneration.current?.nodes?.map((node) => node.outerHTML.trim())?.join(''),
      type: GENERATION_PARAGRAPH_TYPE,
    });
    isAtomicContentGeneratingRef.current.value = false;
    isAtomicContentGeneratingRef.current.token = null;
  }

  function isGenerationEnabled() {
    if (
      focusTopKeywordRef.current.id &&
      [STATUS_PENDING, STATUS_QUEUED].includes(focusTopKeywordRef.current.analysisStatus)
    ) {
      dispatch(showWarningSnackbar(t('content:ai-writing.error-wait-analysis')));
      return false;
    }
    if (
      !focusTopKeywordRef.current.id ||
      focusTopKeywordRef.current.analysisStatus !== STATUS_SUCCESS
    ) {
      dispatch(showWarningSnackbar(t('content:ai-writing.error-not-analyzed')));
      return false;
    }
    return true;
  }

  async function handleAtomicContent() {
    if (isGenerationEnabled() && aiWritingCreditsEnabled) {
      launchAtomicContent();
    }
  }

  return {
    handleAtomicContent,
    handleCancelAtomicContent,
    isAtomicContentFactCheckGenerating: factCheckLoading,
    isAtomicContentGenerating: loading,
  };
}

export default useAtomicContent;
