import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import IllustrationCongratulations from '@/assets/images/illustration_congratulations.svg';
import { FlexColumnCenteredAlignContainer } from '@/components/_common';
import { ContainedButton } from '@/components/Button/Button';
import { ParagraphText } from '@/components/Text/Paragraph';
import { TitleThree } from '@/components/Text/Title';

const MarginTopBottomTitle = styled(TitleThree)`
  && {
    margin-top: 60px;
    margin-bottom: 15px;
    text-align: center;
  }
`;
const CenterParagraphText = styled(ParagraphText)`
  && {
    text-align: center;
    margin-bottom: 15px;
  }
`;

const MarginFlexColumnCenteredAlignContainer = styled(FlexColumnCenteredAlignContainer)`
  && {
    margin: 70px;
    min-height: 500px;
    justify-content: center;
  }
`;

function ConfirmChangePlanDialog(props) {
  const { t } = useTranslation();

  if (props.show) {
    return (
      <Dialog disableBackdropClick fullWidth maxWidth="sm" open>
        <MarginFlexColumnCenteredAlignContainer>
          <img
            alt={t('components:dialog.confirm-change-plan.image-alt')}
            src={IllustrationCongratulations}
          />
          <MarginTopBottomTitle weight="strong">{props.title}</MarginTopBottomTitle>
          <CenterParagraphText color="dark080" weight="medium">
            {props.children}
          </CenterParagraphText>
          <ContainedButton onClick={props.onClose}>
            {t('components:dialog.confirm-change-plan.button-text')}
          </ContainedButton>
        </MarginFlexColumnCenteredAlignContainer>
      </Dialog>
    );
  }

  return null;
}

ConfirmChangePlanDialog.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default ConfirmChangePlanDialog;
