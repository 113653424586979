import styled from 'styled-components/macro';

export const Number = styled.span`
  font-size: ${(props) =>
    props.size === 'micro'
      ? props.theme.text.sizes.micro
      : props.size === 'small'
        ? props.theme.text.sizes.small
        : props.size === 'large'
          ? props.theme.text.sizes.huge
          : props.theme.text.sizes.default};
  margin-bottom: ${(props) => (props.size === 'small' ? '5px' : 0)};
  white-space: nowrap;
  font-weight: 300;
`;
