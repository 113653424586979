import { nanoid } from 'nanoid';
import { useMemo } from 'react';

function EmptyPublishedIcon() {
  const svgId = useMemo(() => nanoid(), []);

  return (
    <svg fill="none" height="150" viewBox="0 0 145 118" width="145">
      <path
        d="M141 109.978C141 114.424 110.309 118 72.5 118C34.6905 118 4 114.424 4 109.978C4 105.532 34.6905 106.015 72.5 106.015C110.309 106.015 141 105.532 141 109.978Z"
        fill="#E9E9EB"
      />
      <rect fill="#E9E9EB" height="7" rx="3.5" width="15" x="16" y="58" />
      <rect fill="#E9E9EB" height="7" rx="3.5" width="15" x="18" y="30" />
      <rect fill="#E9E9EB" height="7" rx="3.5" width="8" y="43" />
      <rect fill="#E9E9EB" height="7" rx="3.5" width="20" x="12" y="43" />
      <rect
        fill="#E9E9EB"
        height="7"
        rx="3.5"
        transform="rotate(-180 127 93)"
        width="13"
        x="127"
        y="93"
      />
      <rect
        fill="#E9E9EB"
        height="7"
        rx="3.5"
        transform="rotate(-180 127 66)"
        width="15"
        x="127"
        y="66"
      />
      <rect
        fill="#E9E9EB"
        height="7"
        rx="3.5"
        transform="rotate(-180 145 81)"
        width="8"
        x="145"
        y="81"
      />
      <rect
        fill="#E9E9EB"
        height="7"
        rx="3.5"
        transform="rotate(-180 133 81)"
        width="20"
        x="133"
        y="81"
      />
      <path
        d="M32.3 108.7V1.3H73.7883L112.785 37.4295V108.7H32.3Z"
        stroke="#A8A9AF"
        strokeWidth="2.6"
      />
      <path d="M112.914 37.4469H74.2969V1.75537" stroke="#A8A9AF" strokeWidth="2.6" />
      <rect fill="#E9E9EB" height="37.4468" rx="5.85107" width="37.4468" x="53.2344" y="47.9785" />
      <g clipPath={`url(#clip0_${svgId})`}>
        <mask
          height="21"
          id={`mask0_${svgId}`}
          maskUnits="userSpaceOnUse"
          style={{ maskType: 'alpha' }}
          width="22"
          x="60"
          y="55"
        >
          <path
            d="M69.2701 71.1484C67.9829 69.9782 67.2808 68.2229 67.2808 66.4675C67.2808 62.9569 70.2063 60.0314 73.7169 60.0314H77.1105L74.068 56.9888L74.8871 56.1697L79.451 60.7335L74.8871 65.2973L74.068 64.4782L77.1105 61.4356H73.7169C70.7914 61.4356 68.451 63.7761 68.451 66.7016C68.451 68.2229 69.0361 69.5101 70.0893 70.5633L69.2701 71.1484ZM80.1531 65.9995V73.6059C80.1531 73.9569 79.9191 74.191 79.568 74.191H63.185C62.8339 74.191 62.5999 73.9569 62.5999 73.6059V65.9995H61.4297V73.6059C61.4297 74.542 62.2488 75.3612 63.185 75.3612H79.568C80.5042 75.3612 81.3233 74.542 81.3233 73.6059V65.9995H80.1531Z"
            fill="#252736"
            stroke="#252736"
            strokeLineJoin="round"
          />
        </mask>
        <g mask={`url(#mask0_${svgId})`}>
          <rect fill="#A8A9AF" height="23.4043" width="23.4043" x="59.0938" y="54.9995" />
        </g>
      </g>
      <defs>
        <clipPath id={`clip0_${svgId}`}>
          <rect fill="white" height="21.0638" transform="translate(60.2578 55)" width="22.2341" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default EmptyPublishedIcon;
