import { AI_WRAPPER_SELECTOR } from '@/containers/Content/TinyMceComponents/Editor/hooks/useAiWriting/useAIWrappper/useAiWrapper';
import {
  DATA_MCE_ANNOTATION,
  DATA_MCE_ANNOTATION_UID,
  ENUM_SEMJI_THREAD_ATTRIBUTES,
} from '@/containers/Content/TinyMceComponents/Editor/hooks/useComment/useComments.types';
import { ContentUtils } from '@/containers/Content/TinyMceComponents/Editor/hooks/useContent/content.utils';

export function getAiWrapper({ editorRef }) {
  return editorRef.current.dom.select('div.' + AI_WRAPPER_SELECTOR)[0];
}

export function getContentUpCursor({ editorRef }) {
  // Insert a unique marker so I can easily find the current position in the editor
  const UNIQUE_MARKER = 'SEMJI_SPLIT_MARKER';

  const node = editorRef.current.dom.create('p', {}, UNIQUE_MARKER);
  editorRef.current.dom.insertAfter(node, editorRef.current.selection.getNode());

  const markerIndex = editorRef.current.getContent().indexOf(UNIQUE_MARKER);
  const beforeCursorContent = editorRef.current.getContent().slice(0, markerIndex - 3);

  node.remove();
  return beforeCursorContent;
}

// this is to migrate old html clients with span having attributes 'data-mce-annotation-uid="id"' and 'data-mce-annotation="semji-comments"'
// to span with SEMJI_THREAD_DATA_ATTRIBUTE="id"
export function migrateOldHtmlToNewCommentAttributes(html) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const spans = doc.querySelectorAll(
    `span[${DATA_MCE_ANNOTATION_UID}][${DATA_MCE_ANNOTATION}="semji-comments"]`
  );

  spans.forEach((span) => {
    span.setAttribute(
      ENUM_SEMJI_THREAD_ATTRIBUTES.SEMJI_THREAD_DATA_ATTRIBUTE,
      span.getAttribute(DATA_MCE_ANNOTATION_UID)
    );

    /**
     * WE DO NOT WANT FOR NOW TO REMOVE THE OLD ATTRIBUTES
     * to be able to handle rollback if needed
     */
    // span.removeAttribute(DATA_MCE_ANNOTATION_UID);
    // span.removeAttribute(DATA_MCE_ANNOTATION);
  });

  return doc.body.innerHTML;
}

export function getContentUpAiWrapper({ editorRef }) {
  // Insert a unique marker so I can easily find the current position in the editor
  const UNIQUE_MARKER = 'SEMJI_SPLIT_MARKER';

  const aiWrapper = getAiWrapper({ editorRef });

  if (aiWrapper.previousSibling) {
    const node = editorRef.current.dom.create('p', {}, UNIQUE_MARKER);
    editorRef.current.dom.insertAfter(
      node,
      editorRef.current.dom.select('div.' + AI_WRAPPER_SELECTOR)[0].previousSibling
    );

    const markerIndex = editorRef.current.getContent().indexOf(UNIQUE_MARKER);
    const beforeCursorContent = editorRef.current.getContent().slice(0, markerIndex - 3);

    node.remove();
    return beforeCursorContent;
  } else {
    return ContentUtils.createEmptyHTML();
  }
}

export function getHtmlForAiWrapper({ editorRef }) {
  const aiWrapper = getAiWrapper({ editorRef });

  if (aiWrapper.previousSibling) {
    return editorRef.current.getContent();
  } else {
    return ContentUtils.createEmptyHTML();
  }
}
