import { useDispatch, useSelector } from 'react-redux';

import { SerpInstruction } from '@/components/Optimization/Instruction';
import {
  disableRecommendationOptimization,
  enableRecommendationOptimization,
} from '@/store/actionsCreator/report';

function Link({ focusTopKeywordId, id, mainId, onToggleLink }) {
  const dispatch = useDispatch();
  const html = useSelector((state) => state.content.html);
  const link = useSelector((state) => state.report.recommendations[id]);
  const score = useSelector((state) => state.report.recommendations[id].score);
  const title = useSelector((state) => state.content.title);

  function toggleLink() {
    if (!link.disabled) {
      dispatch(disableRecommendationOptimization(id, mainId, focusTopKeywordId, html, title));
    } else {
      dispatch(enableRecommendationOptimization(id, mainId, focusTopKeywordId, html, title));
    }
    onToggleLink(id, !link.disabled);
  }

  return (
    <SerpInstruction
      completed={1 === score}
      disabled={link.disabled}
      rawHtmlDescription={link.data.description}
      title={link.data.title}
      url={link.data.url}
      onToggle={toggleLink}
    />
  );
}

export default Link;
