import { authorize, fetchAnalyticsAccounts, fetchSearchConsoleSitesList } from '@/apis/googleApi';
import fetchSearchConsoleAnalytics from '@/apis/googleApi/fetchSearchConsoleAnalytics';
import { createServiceIntegration, fetchGoogleCredentials } from '@/apis/semji/api';
import isSearchConsoleUrlValidWorkspaceUrl from '@/utils/url/isSearchConsoleUrlValidWorkspaceUrl';

export default class ServiceIntegration {
  async fetchSearchConsoleData(redirectUri, workspaceWebsiteUrl) {
    try {
      const authCode = await authorize(
        'https://www.googleapis.com/auth/webmasters.readonly https://www.googleapis.com/auth/userinfo.email',
        redirectUri
      );
      const googleCredentialsValue = await fetchGoogleCredentials(authCode);
      const searchConsoleProperties = await fetchSearchConsoleSitesList(
        googleCredentialsValue.accessToken
      );

      // Only fetch SC analytics for properties matching wokrspace website Url
      let valid = [];
      let invalid = [];

      searchConsoleProperties.forEach((prop) =>
        isSearchConsoleUrlValidWorkspaceUrl(prop.siteUrl, workspaceWebsiteUrl)
          ? valid.push(prop)
          : invalid.push(prop)
      );

      valid = (
        await Promise.all(
          valid.map(({ siteUrl }) =>
            fetchSearchConsoleAnalytics(
              googleCredentialsValue.accessToken,
              siteUrl,
              workspaceWebsiteUrl
            )
          )
        )
      ).sort((a, b) => (b.rows || []).length - (a.rows || []).length);

      return Promise.resolve({
        googleCredentialsValue,
        searchConsoleProperties: { invalid, valid },
      });
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async createSearchConsoleIntegration({ googleCredentials, workspaceId, property }) {
    try {
      await createServiceIntegration({
        data: {
          access_token: googleCredentials.accessToken,
          code: googleCredentials.code,
          email: googleCredentials.email,
          refresh_token: googleCredentials.refreshToken,
          site_url: property.siteUrl,
        },
        name: 'SEARCH_CONSOLE',
        type: 'SEARCH_CONSOLE',
        workspaceId,
      });

      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async fetchGoogleAnalyticsData(redirectUri) {
    try {
      const authCode = await authorize(
        'https://www.googleapis.com/auth/analytics.readonly https://www.googleapis.com/auth/userinfo.email',
        redirectUri
      );
      const googleCredentialsValue = await fetchGoogleCredentials(authCode);
      const accounts = await fetchAnalyticsAccounts(googleCredentialsValue.accessToken);

      return Promise.resolve({
        accounts,
        googleCredentialsValue,
      });
    } catch (e) {
      return Promise.reject(e);
    }
  }
}
