import './SidePanelComments.scss';

import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Dropdown } from 'semji-core/components/Dropdown';
import { SkeletonLoader } from 'semji-core/components/SkeletonLoader';
import { CheckIcon } from 'semji-core/icons/CheckIcon';
import { DownIcon } from 'semji-core/icons/DownIcon';

import EmptyStateComment from '@/assets/images/empty-state-comment.svg';
import ScrollBar from '@/components/Scrollbar';
import { useContentContainerContext } from '@/containers/Content/ContentContainerContext';
import { PlanRestriction } from '@/containers/Content/SidePanel/PlanRestriction';
import { SidePanelHeader } from '@/containers/Content/SidePanel/SidePanelHeader';
import Thread from '@/containers/Content/TinyMceComponents/Comments/SidePanel/Thread';
import { ENUM_COMMENT_SIDE_PANEL_OPEN_STATUS } from '@/containers/Content/TinyMceComponents/Editor/hooks/useComment/useComments.types';
import Flex from '@/design-system/components/Flex';
import defaultTheme from '@/themes/defaultTheme';

import {
  EmptyStateExplanation,
  EmptyStateTitle,
  HeaderWrapper,
  SelectorDropdownItem,
  SelectorDropdownText,
  SelectorDropdownWrapper,
  SelectorText,
  Title,
} from './SidePanelComments.styled';

function Loader() {
  const { t } = useTranslation();
  const loadingRows = Array(6).fill({});

  return (
    <>
      <SidePanelHeader>{t('comments:comments')}</SidePanelHeader>
      <Flex flexDirection="column" gap="32px" height="100%" padding="20px">
        {loadingRows.map((_, index) => (
          <Flex key={index} gap="12px">
            <Flex>
              <SkeletonLoader borderRadius="50%" height="22px" width="22px" />
            </Flex>
            <Flex flexDirection="column" gap="12px" width="100%">
              <SkeletonLoader borderRadius="3px" height="8px" width="100%" />
              <SkeletonLoader borderRadius="3px" height="8px" width="80%" />
              <SkeletonLoader borderRadius="3px" height="8px" width="90%" />
              <SkeletonLoader borderRadius="3px" height="8px" width="60%" />
            </Flex>
          </Flex>
        ))}
      </Flex>
    </>
  );
}

function Selector({ isCommentsEnabled }) {
  const { t } = useTranslation();
  const dropDrownActionsRef = useRef({
    handleClose: () => null,
    handleOpen: () => null,
  });

  const {
    comments: { openStatus, openComments },
  } = useContentContainerContext();

  const options = [
    {
      label: t('comments:filters.all'),
      value: ENUM_COMMENT_SIDE_PANEL_OPEN_STATUS.COMMENTS_ALL_VALUE,
    },
    {
      label: t('comments:filters.open'),
      value: ENUM_COMMENT_SIDE_PANEL_OPEN_STATUS.COMMENTS_OPEN_VALUE,
    },
    {
      label: t('comments:filters.resolved'),
      value: ENUM_COMMENT_SIDE_PANEL_OPEN_STATUS.COMMENTS_RESOLVED_VALUE,
    },
  ];

  function handleClick(value) {
    return () => {
      openComments(value);
      dropDrownActionsRef.current.handleClose();
    };
  }

  return (
    <Dropdown
      actionsRef={dropDrownActionsRef}
      anchorElement={
        <Flex alignItems="center" cursor={isCommentsEnabled ? 'pointer' : 'not-allowed'} gap="4px">
          <SelectorText>
            {options.find((option) => option.value === openStatus)?.label}
          </SelectorText>
          <DownIcon />
        </Flex>
      }
      disabled={!isCommentsEnabled}
      isFrame={false}
      offsetValue={5}
      paddingValue={15}
      querySelectorListener="#root"
      width="150px"
    >
      <SelectorDropdownWrapper
        backgroundColor="white"
        flexDirection="column"
        padding="4px 0"
        width="150px"
      >
        {options.map((option) => (
          <SelectorDropdownItem
            key={option.value}
            alignItems="center"
            cursor="pointer"
            gap="10px"
            justifyContent="space-between"
            padding="8px 20px"
            onClick={handleClick(option.value)}
          >
            <SelectorDropdownText>{option.label}</SelectorDropdownText>
            {option.value === openStatus && <CheckIcon color={defaultTheme.cssColors.dark080} />}
          </SelectorDropdownItem>
        ))}
      </SelectorDropdownWrapper>
    </Dropdown>
  );
}

function SidePanelCommentsHeader({ isCommentsEnabled }) {
  const { t } = useTranslation();

  return (
    <HeaderWrapper alignItems="center" justifyContent="space-between" padding="15px">
      <Flex alignItems="center" gap="10px">
        <Title>{t('comments:comments')}</Title>
      </Flex>
      <Selector isCommentsEnabled={isCommentsEnabled} />
    </HeaderWrapper>
  );
}

function SidePanelComments() {
  const { t } = useTranslation();
  const { contentId } = useParams();
  const {
    comments: {
      allThreads,
      openStatus,
      closeComments,
      newThread,
      isLoadingThreads,
      isCommentsEnabled,
      isPendingThread,
    },
    getWorkspaceUsers,
  } = useContentContainerContext();

  const filteredThreads = [
    newThread,
    ...(openStatus === ENUM_COMMENT_SIDE_PANEL_OPEN_STATUS.COMMENTS_ALL_VALUE
      ? allThreads
      : allThreads.filter(({ thread }) =>
          openStatus === ENUM_COMMENT_SIDE_PANEL_OPEN_STATUS.COMMENTS_OPEN_VALUE
            ? !thread.resolvedAt
            : thread.resolvedAt
        )),
  ].filter((thread) => thread);

  const sortedThread = filteredThreads.sort(
    (a, b) =>
      isPendingThread(b.thread.id) - isPendingThread(a.thread.id) ||
      !b.thread.resolvedAt - !a.thread.resolvedAt ||
      Math.max(...b.comments.map((comment) => new Date(comment.createdAt).getTime())) -
        Math.max(...a.comments.map((comment) => new Date(comment.createdAt).getTime()))
  );

  if (!contentId) {
    closeComments();
  }

  if (!isCommentsEnabled) {
    return (
      <Flex flexDirection="column" height="100%">
        <SidePanelCommentsHeader isCommentsEnabled={isCommentsEnabled} />
        <PlanRestriction
          description={t('comments:upper-plan.description')}
          title={t('comments:upper-plan.title')}
        />
      </Flex>
    );
  }

  if (isLoadingThreads || !getWorkspaceUsers.data) {
    return <Loader />;
  }

  return (
    <Flex flexDirection="column" height="100%">
      <SidePanelHeader>
        <div>{t('comments:comments')}</div>
        <Selector isCommentsEnabled={isCommentsEnabled} />
      </SidePanelHeader>
      <Flex flexDirection="column" height="100%">
        {sortedThread.length ? (
          <ScrollBar>
            {sortedThread.map(({ thread, comments }, index) => (
              <Thread
                key={thread.id + index}
                comments={comments}
                thread={thread}
                users={getWorkspaceUsers.data}
              />
            ))}
          </ScrollBar>
        ) : (
          <Flex
            alignItems="center"
            flexDirection="column"
            gap="20px"
            height="100%"
            justifyContent="center"
          >
            <img alt="comment empty state" src={EmptyStateComment} />
            <Flex alignItems="center" flexDirection="column" gap="8px">
              <EmptyStateTitle>
                {openStatus === ENUM_COMMENT_SIDE_PANEL_OPEN_STATUS.COMMENTS_ALL_VALUE
                  ? t('comments:empty-state.no-comment')
                  : openStatus === ENUM_COMMENT_SIDE_PANEL_OPEN_STATUS.COMMENTS_OPEN_VALUE
                    ? t('comments:empty-state.no-comment-open')
                    : t('comments:empty-state.no-comment-resolved')}
              </EmptyStateTitle>
              <EmptyStateExplanation>
                {openStatus === ENUM_COMMENT_SIDE_PANEL_OPEN_STATUS.COMMENTS_ALL_VALUE
                  ? t('comments:empty-state.no-comment-explanation')
                  : openStatus === ENUM_COMMENT_SIDE_PANEL_OPEN_STATUS.COMMENTS_OPEN_VALUE
                    ? t('comments:empty-state.no-comment-open-explanation')
                    : t('comments:empty-state.no-comment-resolved-explanation')}
              </EmptyStateExplanation>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}

export default SidePanelComments;
