function NewContentIcon() {
  return (
    <svg fill="none" height="45" viewBox="0 0 62 45" width="62" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M60.5446 40.9475C60.5446 42.8828 47.1856 44.4393 30.7278 44.4393C14.2699 44.4393 0.910889 42.8828 0.910889 40.9475C0.910889 39.0123 14.2699 39.2226 30.7278 39.2226C47.1856 39.2226 60.5446 39.0123 60.5446 40.9475Z"
        fill="#FFEBED"
      />
      <rect fill="#FFEBED" height="3.04698" rx="1.52349" width="6.52924" x="8.31238" y="18.3225" />
      <rect fill="#FFEBED" height="3.04698" rx="1.52349" width="6.52924" x="9.18225" y="6.13452" />
      <rect fill="#FFEBED" height="3.04698" rx="1.52349" width="3.48226" x="1.34741" y="11.7932" />
      <rect fill="#FFEBED" height="3.04698" rx="1.52349" width="8.70566" x="6.57141" y="11.7932" />
      <rect
        fill="#FFEBED"
        height="3.04698"
        rx="1.52349"
        transform="rotate(-180 51.8407 33.5575)"
        width="5.65868"
        x="51.8407"
        y="33.5575"
      />
      <rect
        fill="#FFEBED"
        height="3.04698"
        rx="1.52349"
        transform="rotate(-180 51.8407 21.8048)"
        width="6.52924"
        x="51.8407"
        y="21.8048"
      />
      <rect
        fill="#FFEBED"
        height="3.04698"
        rx="1.52349"
        transform="rotate(-180 59.6754 28.3342)"
        width="3.48226"
        x="59.6754"
        y="28.3342"
      />
      <rect
        fill="#FFEBED"
        height="3.04698"
        rx="1.52349"
        transform="rotate(-180 54.4514 28.3342)"
        width="8.70566"
        x="54.4514"
        y="28.3342"
      />
      <path
        d="M15.7108 40.5219V0.475932H31.2103L45.7453 13.9422V40.5219H15.7108Z"
        stroke="#51525E"
        strokeWidth="0.870566"
      />
      <line
        stroke="#51525E"
        strokeWidth="0.870566"
        x1="31.3806"
        x2="31.3806"
        y1="14.4052"
        y2="0.911384"
      />
      <path d="M31.3798 13.9695L46.1794 13.9695" stroke="#51525E" strokeWidth="0.870566" />
      <rect fill="#FFCCD3" height="12.1879" rx="2.17641" width="12.1879" x="24.4163" y="18.7576" />
      <line
        stroke="#51525E"
        strokeWidth="0.870566"
        x1="30.5094"
        x2="30.5094"
        y1="21.3693"
        y2="27.8985"
      />
      <line
        stroke="#51525E"
        strokeWidth="0.870566"
        x1="27.0271"
        x2="33.9916"
        y1="24.4164"
        y2="24.4164"
      />
    </svg>
  );
}

export default NewContentIcon;
