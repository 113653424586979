import { useMutation } from '@tanstack/react-query';

import apiClient from '@/apis/semji/apiClient';

export default function useDeleteComment({ ...props }) {
  const { delete: deleteRequest } = apiClient;

  return useMutation({
    mutationFn: ({ commentId }) => deleteRequest(`comments/${commentId}`),
    ...props,
  });
}
