import i18next from 'i18next';

export const DEFAULT_OPTION_PARENT_FOLDERS = {
  name: '',
  value: null,
};

export function getDefaultOptionParentFolders() {
  return {
    ...DEFAULT_OPTION_PARENT_FOLDERS,
    name: i18next.t('components:filters.folder-filters-panel.label-no-parent'),
  };
}
