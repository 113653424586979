import { COOKIE_IMPERSONATE_KEY_NAME, getCookie } from '@/utils/cookies/cookies';

function init(user, company, roles = {}) {
  const userImpersonate = getCookie(COOKIE_IMPERSONATE_KEY_NAME);
  let intercom_data_roles = {};

  if (roles.organizationRoles) {
    intercom_data_roles.user_last_used_organization_roles = roles.organizationRoles
      .map((role) => `role_${role.toLowerCase()}`)
      .join(',');
  }

  if (roles.workspaceRoles) {
    intercom_data_roles.user_last_used_workspace_roles = roles.workspaceRoles
      .map((role) => `role_${role.toLowerCase()}`)
      .join(',');
  }

  if (window.Intercom && !userImpersonate) {
    window.Intercom('update', {
      email: user.email,
      name: user.firstName + ' ' + user.lastName,
      user_id: user.id,
      user_is_admin: user.superAdmin,
      ...intercom_data_roles,
      company: user.superAdmin ? {} : { ...company },
      hide_default_launcher: true,
      user_created_at: user.createdAt,
      user_last_login_at: user.lastLoginAt,
    });
  }
}

// After each URL change without a full reload of the page
// https://www.intercom.com/help/en/articles/170-integrate-intercom-in-a-single-page-app
function notifyUrlUpdate() {
  if (window.Intercom) {
    window.Intercom('update', { last_request_at: parseInt(new Date().getTime() / 1000) });
  }
}

// An event is information on what your customers do, and when they do it, e.g. when was the first time they created a project, and when did they most recently create a project.
// https://www.intercom.com/help/en/articles/2433372-custom-attributes-vs-events-what-s-the-difference
function sendCustomEvent(eventId, data) {
  if (window.Intercom) {
    window.Intercom('trackEvent', eventId, { ...data });
  }
}

// A custom attribute is data you track about your users or leads, e.g., how many projects they’ve created in your product.
// https://www.intercom.com/help/en/articles/2433372-custom-attributes-vs-events-what-s-the-difference
function updateWithCustomAttribute(data) {
  if (window.Intercom) {
    window.Intercom('update', { ...data });
  }
}

export { init, notifyUrlUpdate, sendCustomEvent, updateWithCustomAttribute };
