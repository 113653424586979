import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';

import { FlexCenteredAlignJustifyEndContainer } from '@/components/_common';
import { ContainedButton } from '@/components/Button/Button';
import CloseIcon from '@/components/icons/CloseIcon';
import { TitleTwo } from '@/components/Text/Title';

const Close = styled(CloseIcon)`
  && {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 30px;
    font-size: 1.2rem;
    color: ${(props) => props.theme.cssColors.default};
  }
`;
const Header = styled.div`
  padding: 1.5rem;
`;
const Body = styled.div`
  margin: 2rem;
`;
const Footer = styled(FlexCenteredAlignJustifyEndContainer)`
  margin: 0 2rem 2rem 2rem;
`;
const CancelButton = styled(ContainedButton)`
  && {
    margin: 0 1rem 0 0;
  }
`;

function DialogTemplate(props) {
  const confirmActionCall = (e) => {
    // on Enter click we trigger the submit
    // this is used likewise and not using a form
    // because forms, input focus inside material ui modals/dialog
    // triggers a change in the dom and closes the dialog modal
    if (13 === e.keyCode && !props.confirmDisabled) {
      props.confirmAction();
    }
  };

  return (
    <Dialog
      fullWidth={props.fullWidth ?? true}
      maxWidth={props.maxWidth ?? 'sm'}
      open={props.open}
      onClose={props.onClose}
      onKeyDown={confirmActionCall}
    >
      <Header>
        <TitleTwo weight="strong">{props.title}</TitleTwo>
        <Close onClick={props.onClose} />
      </Header>
      <Body>{props.children}</Body>
      <Footer>
        <CancelButton
          bgColor="dark010"
          color="dark040"
          fullWidth
          onClick={props.cancelAction ?? props.onClose}
        >
          {props.cancelLabel ?? 'Cancel'}
        </CancelButton>
        <ContainedButton disabled={props.confirmDisabled} fullWidth onClick={props.confirmAction}>
          {props.confirmLabel ?? 'Confirm'}
        </ContainedButton>
      </Footer>
    </Dialog>
  );
}

DialogTemplate.propTypes = {
  cancelAction: PropTypes.func,
  cancelLabel: PropTypes.string,
  confirmAction: PropTypes.func,
  confirmDisabled: PropTypes.bool,
  confirmLabel: PropTypes.string,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

export default DialogTemplate;
