import './SkeletonForm.scss';

import FlatLoader, { LoaderWrapper } from '@/components/Loader/FlatLoader';

const DEFAULT_LOADER_WIDTH = 720;

function SkeletonForm() {
  return (
    <div className="brand-voice-skeleton">
      <div className="brand-voice-skeleton__container">
        <div className="brand-voice-skeleton__group">
          <LoaderWrapper height={20} width={180}>
            <FlatLoader />
          </LoaderWrapper>
          <LoaderWrapper height={40} width={DEFAULT_LOADER_WIDTH}>
            <FlatLoader />
          </LoaderWrapper>
        </div>
        <div className="brand-voice-skeleton__group">
          <LoaderWrapper height={20} width={220}>
            <FlatLoader />
          </LoaderWrapper>
          <LoaderWrapper height={220} width={DEFAULT_LOADER_WIDTH}>
            <FlatLoader />
          </LoaderWrapper>
        </div>
        <div className="brand-voice-skeleton__group">
          <LoaderWrapper height={20} width={220}>
            <FlatLoader />
          </LoaderWrapper>
          <LoaderWrapper height={220} width={DEFAULT_LOADER_WIDTH}>
            <FlatLoader />
          </LoaderWrapper>
        </div>
      </div>
    </div>
  );
}

export default SkeletonForm;
