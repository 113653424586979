import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { TextButton } from '@/components/Button/Button';
import InputSmartList from '@/components/Input/InputSmartList';
import { Info } from '@/components/Text/Info';
import { TitleTwo } from '@/components/Text/Title';
import { URL_LIMIT_IMPORT } from '@/utils/constants';
import { isUrlAlreadyInList, isUrlValidWithWebSiteUrl, isValidPageUrl } from '@/utils/url';

const Wrapper = styled.div`
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  background: white;
  padding: 3em;
  margin: 1em 0;
  max-height: 400px;
  overflow: auto;
  width: 100%;
  max-width: 700px;
`;
const InfoWrapper = styled.div`
  padding: 5px;
`;

export default function UrlsList({ setUrls, urls, websiteUrl, pages }) {
  const { t } = useTranslation();
  const filteredUrls = urls.filter((url) => !!url);

  const isError = (input) => {
    return (
      (!isValidPageUrl(input).value ||
        !isUrlValidWithWebSiteUrl(input, websiteUrl) ||
        isUrlAlreadyInList(input, urls) ||
        pages.find((page) => page.url === input.toLowerCase())) &&
      !!input
    );
  };

  const helperText = (input) => {
    if (!input) {
      return '';
    }
    const { value, error } = isValidPageUrl(input);
    if (!value) {
      return error;
    }
    if (!isUrlValidWithWebSiteUrl(input, websiteUrl)) {
      return t('pages:urls-list.helper-text.url-not-belong-to', {
        websiteUrl: websiteUrl,
      });
    }
    if (isUrlAlreadyInList(input, urls)) {
      return t('pages:urls-list.helper-text.already-in-list');
    }
    if (pages.find((page) => page.url === input.toLowerCase())) {
      return t('pages:urls-list.helper-text.url-already-imported');
    }
    return '';
  };

  return (
    <>
      <Wrapper>
        <TitleTwo weight="medium">{t('pages:urls-list.urls-to-import')}</TitleTwo>
        <InputSmartList
          AddInput={(extraProps) => (
            <TextButton color="primary" padding="0 0.25rem" weight="medium" {...extraProps}>
              {t('pages:urls-list.add-a-url')}
            </TextButton>
          )}
          getList={setUrls}
          helperText={(input) => helperText(input)}
          inputs={urls}
          isError={(input) => isError(input)}
          isLimitAchieved={filteredUrls.length > URL_LIMIT_IMPORT}
          placeholder={t('pages:urls-list.placeholder')}
        />
      </Wrapper>
      <InfoWrapper>
        {filteredUrls.length > URL_LIMIT_IMPORT && (
          <Info>{t('pages:urls-list.import-limitation', { count: URL_LIMIT_IMPORT })}</Info>
        )}
      </InfoWrapper>
    </>
  );
}
