import { useMutation } from '@tanstack/react-query';

import apiClient from '@/apis/semji/apiClient';

export default function usePutContents(props) {
  const { put } = apiClient;

  return useMutation({
    mutationFn: ({ contentIds = [], content = {} }) =>
      Promise.all(
        contentIds.map(async (contentId) => put(`/contents/${contentId}`, { ...content }))
      ),

    ...props,
  });
}
