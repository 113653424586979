import { useTranslation } from 'react-i18next';

import { LOCATION } from '@/apis/semji/constants';
import useGetCurrentWorkspacePagesWithSpecificProperties from '@/apis/semji/pages/useGetCurrentWorkspacePagesWithSpecificProperties';
import { FlexColumnContainer, FlexContainer, StyledCard } from '@/components/_common';
import LaunchTour from '@/components/Badge/LaunchTour';
import { CardSeparator } from '@/components/Card/Card';
import { ListingHeaderContainer, ListingStyledDivider } from '@/components/Layout/Header';
import { LayoutContent } from '@/components/Layout/Layout';
import { TitleTwo } from '@/components/Text/Title';
import ContentsToOptimize from '@/containers/Dashboard/ContentsToOptimize/ContentsToOptimize';
import DashboardGraph from '@/containers/Dashboard/DashboardGraphs';
import DashboardPages from '@/containers/Dashboard/DashboardPages';
import DashboardPlanning from '@/containers/Dashboard/Planning/DashboardPlanning';
import ProductionCharts from '@/containers/Dashboard/ProductionCharts';
import { TitleWrapper } from '@/containers/Planning/Planning.styled';
import { PAGES_TABLE_PROPERTIES } from '@/containers/Report/utils/constants';
import {
  METRICS_CONFIG_COMPARISON_PERIOD_PREVIOUS_YEAR,
  METRICS_CONFIG_PERIOD_LAST_365_DAYS,
  METRICS_CONFIG_PERIODICITY_MONTHLY,
} from '@/utils/metrics/constants';

function Dashboard() {
  const { t } = useTranslation();

  const {
    isPlaceholderData,
    isFetched,
    data = [],
  } = useGetCurrentWorkspacePagesWithSpecificProperties({
    filters: { 'exists[lastPublishedAt]': true, 'exists[url]': true, inStock: false },
    location: LOCATION.DASHBOARD,
    properties: PAGES_TABLE_PROPERTIES,
  });

  const isPageLoading = isPlaceholderData && !isFetched;
  const newPages = data.filter((page) => page.new && page.extra.lastPublishedAt) || [];
  const updatedPages = data.filter((page) => !page.new && page.extra.lastPublishedAt) || [];

  return (
    <LayoutContent isPadded>
      <ListingHeaderContainer>
        <TitleWrapper>
          <TitleTwo noMargin weight="medium">
            {t('dashboard:dashboard')}
          </TitleTwo>
          <LaunchTour data-intercom-target="product_tour_dashboard" />
        </TitleWrapper>
      </ListingHeaderContainer>
      <ListingStyledDivider />
      <FlexContainer>
        <StyledCard>
          <FlexColumnContainer>
            <ProductionCharts
              comparisonPeriod={METRICS_CONFIG_COMPARISON_PERIOD_PREVIOUS_YEAR}
              loading={isPageLoading}
              newPagesCount={newPages.length}
              period={METRICS_CONFIG_PERIOD_LAST_365_DAYS}
              periodicity={METRICS_CONFIG_PERIODICITY_MONTHLY}
              updatedPagesCount={updatedPages.length}
            />
          </FlexColumnContainer>
        </StyledCard>
      </FlexContainer>
      <CardSeparator />
      <FlexContainer>
        <StyledCard style={{ marginRight: '15px' }}>
          <DashboardGraph
            comparisonPeriod={METRICS_CONFIG_COMPARISON_PERIOD_PREVIOUS_YEAR}
            count={newPages.length}
            loading={isPageLoading}
            period={METRICS_CONFIG_PERIOD_LAST_365_DAYS}
            periodicity={METRICS_CONFIG_PERIODICITY_MONTHLY}
            reportView={'new'}
          />
        </StyledCard>
        <StyledCard style={{ marginLeft: '15px' }}>
          <DashboardGraph
            comparisonPeriod={METRICS_CONFIG_COMPARISON_PERIOD_PREVIOUS_YEAR}
            count={updatedPages.length}
            loading={isPageLoading}
            period={METRICS_CONFIG_PERIOD_LAST_365_DAYS}
            periodicity={METRICS_CONFIG_PERIODICITY_MONTHLY}
            reportView={'updated'}
          />
        </StyledCard>
      </FlexContainer>
      <CardSeparator />
      <FlexContainer>
        <StyledCard style={{ marginRight: '15px' }}>
          <DashboardPlanning />
        </StyledCard>
        <StyledCard style={{ marginLeft: '15px' }}>
          <ContentsToOptimize />
        </StyledCard>
      </FlexContainer>
      <CardSeparator />
      <FlexContainer>
        <StyledCard style={{ marginRight: '15px' }}>
          <DashboardPages
            comparisonPeriod={METRICS_CONFIG_COMPARISON_PERIOD_PREVIOUS_YEAR}
            loading={isPageLoading}
            pages={newPages}
            period={METRICS_CONFIG_PERIOD_LAST_365_DAYS}
            periodicity={METRICS_CONFIG_PERIODICITY_MONTHLY}
            reportView={'new'}
          />
        </StyledCard>
        <StyledCard style={{ marginLeft: '15px' }}>
          <DashboardPages
            comparisonPeriod={METRICS_CONFIG_COMPARISON_PERIOD_PREVIOUS_YEAR}
            loading={isPageLoading}
            pages={updatedPages}
            period={METRICS_CONFIG_PERIOD_LAST_365_DAYS}
            periodicity={METRICS_CONFIG_PERIODICITY_MONTHLY}
            reportView={'updated'}
          />
        </StyledCard>
      </FlexContainer>
      <CardSeparator />
    </LayoutContent>
  );
}

export default Dashboard;
