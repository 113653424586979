import './EmptyBox.scss';

import { Button } from 'semji-core/components/Button';

function EmptyBox({ title, description, buttonTitle, image, onClick }) {
  return (
    <div className="brand-voice-empty-box">
      <img alt={title} src={image} />
      <h2 className="brand-voice-empty-box__title">{title}</h2>
      <p className="brand-voice-empty-box__desc">{description}</p>
      <Button onClick={onClick}>{buttonTitle}</Button>
    </div>
  );
}

export default EmptyBox;
