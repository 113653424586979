import './SidePanelTabs.scss';

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { matchRoutes, useLocation, useMatch } from 'react-router-dom';

import SidePanelToggleIcon from '@/components/icons/SidePanelToggleIcon';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import { useContentContainerContext } from '@/containers/Content/ContentContainerContext';
import { ENUM_SIDE_PANEL_STATUS } from '@/containers/Content/SidePanel/SidePanelContainer/SidePanelContainer.types';
import { ENUM_COMMENT_SIDE_PANEL_OPEN_STATUS } from '@/containers/Content/TinyMceComponents/Editor/hooks/useComment/useComments.types';
import useApiConfigurations from '@/hooks/useApiConfigurations';
import useCan from '@/hooks/useCan';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import { EDITOR_COMMENTS_ENABLED } from '@/utils/configurations/constants';
import { AI_WRITING_FACT_CHECKING_ENABLED } from '@/utils/configurations/constants';
import { FEATURE_SET_COMMENT_IS_ENABLED } from '@/utils/organizationFeatureSet/constants';

import { ISidePanelTab, SidePanelTab } from '../SidePanelTab';
import { ENUM_TAB_LABEL, getSidePanelBottomTabs, getSidePanelTopTabs } from './const';
import { SidePanelTabsProps } from './SidePanelTabs.types';

function SidePanelTabs({
  selectedTabHook,
  sidePanelStatusHook,
  expandDisabled,
  toggleDisabled,
}: SidePanelTabsProps): React.JSX.Element {
  const { t } = useTranslation();
  const location = useLocation();
  const {
    // @ts-ignore
    comments: { openComments, openStatus, closeComments, nbUnreadComments },
  } = useContentContainerContext();
  const aiWritingFactCheckingEnabled = useApiConfigurations(AI_WRITING_FACT_CHECKING_ENABLED);

  // @ts-ignore
  const isEditorCommentEnabled = useCan({ perform: EDITOR_COMMENTS_ENABLED });
  const { isFeatureEnabled: isOrganizationCommentsEnabled } = useOrganizationFeatureSet(
    FEATURE_SET_COMMENT_IS_ENABLED
  );
  const matchesEditor = matchRoutes(
    [
      { path: '/o/:organizationId/w/:workspaceId/p/:pageId/content/:contentId/*' },
      { path: '/o/:organizationId/w/:workspaceId/p/:pageId/versions/:contentId/*' },
    ],
    location
  );

  const matchesOnline = matchRoutes(
    [
      { path: '/o/:organizationId/w/:workspaceId/p/:pageId/online/*' },
      { path: '/o/:organizationId/w/:workspaceId/p/:pageId/text/*' },
    ],
    location
  );

  const isOnlinePage = Boolean(useMatch(`/o/:organizationId/w/:workspaceId/p/:pageId/online`));

  function handleClickTab(tabLabel: ENUM_TAB_LABEL) {
    return function () {
      if (
        ![ENUM_TAB_LABEL.Expand, ENUM_TAB_LABEL.Reduce, ENUM_TAB_LABEL.Comments].includes(tabLabel)
      ) {
        closeComments();
      }

      if (sidePanelStatusHook[0] === ENUM_SIDE_PANEL_STATUS.Close) {
        sidePanelStatusHook[1](ENUM_SIDE_PANEL_STATUS.Normal);
      }
      if (tabLabel === ENUM_TAB_LABEL.Comments) {
        openComments(ENUM_COMMENT_SIDE_PANEL_OPEN_STATUS.COMMENTS_OPEN_VALUE);
      }

      // For product tour, go to SEO page.
      if (tabLabel === ENUM_TAB_LABEL.Tour) {
        selectedTabHook[1](ENUM_TAB_LABEL.Seo);
        return;
      }
      if ([ENUM_TAB_LABEL.Expand, ENUM_TAB_LABEL.Reduce].includes(tabLabel)) {
        sidePanelStatusHook[1]((prevState) => {
          if (prevState === ENUM_SIDE_PANEL_STATUS.Normal) {
            return ENUM_SIDE_PANEL_STATUS.Huge;
          } else {
            return ENUM_SIDE_PANEL_STATUS.Normal;
          }
        });
        return;
      }
      selectedTabHook[1](tabLabel);
    };
  }

  const topTabs: ISidePanelTab[] = getSidePanelTopTabs({
    aiWritingFactCheckingEnabled,
    matchesEditor: !!matchesEditor,
  });
  const bottomTabs: ISidePanelTab[] = getSidePanelBottomTabs(
    !!nbUnreadComments,
    !!matchesEditor,
    sidePanelStatusHook[0] === ENUM_SIDE_PANEL_STATUS.Huge,
    // @ts-ignore
    !!window[`is_${`product_tour_${isOnlinePage ? 'online' : 'content'}_sidepanel`}_enabled`],
    isEditorCommentEnabled,
    isOrganizationCommentsEnabled,
    expandDisabled
  );

  function handleCloseSidePanel() {
    if (sidePanelStatusHook[0] === ENUM_SIDE_PANEL_STATUS.Close) {
      sidePanelStatusHook[1](ENUM_SIDE_PANEL_STATUS.Normal);
      if (selectedTabHook[0] === ENUM_TAB_LABEL.Comments) {
        openComments(ENUM_COMMENT_SIDE_PANEL_OPEN_STATUS.COMMENTS_OPEN_VALUE);
      }
    } else {
      closeComments();
      sidePanelStatusHook[1](ENUM_SIDE_PANEL_STATUS.Close);
    }
  }

  // Open side panel comment when adding comment in editor
  useEffect(() => {
    if ([...Object.values(ENUM_COMMENT_SIDE_PANEL_OPEN_STATUS)].includes(openStatus)) {
      selectedTabHook[1](ENUM_TAB_LABEL.Comments);

      if (sidePanelStatusHook[0] === ENUM_SIDE_PANEL_STATUS.Close) {
        sidePanelStatusHook[1](ENUM_SIDE_PANEL_STATUS.Normal);
      }
    }
  }, [openStatus]);

  useEffect(() => {
    // Open side panel when page change to editor or page
    if (
      (!!matchesEditor || !!matchesOnline) &&
      sidePanelStatusHook[0] === ENUM_SIDE_PANEL_STATUS.Close
    ) {
      sidePanelStatusHook[1](ENUM_SIDE_PANEL_STATUS.Normal);
    }
  }, [location.pathname]);

  useEffect(() => {
    // Close side panel and switch from comment to seo if not in editor
    if (!matchesEditor && !matchesOnline) {
      sidePanelStatusHook[1](ENUM_SIDE_PANEL_STATUS.Close);
      if (selectedTabHook[0] === ENUM_TAB_LABEL.Comments) {
        selectedTabHook[1](ENUM_TAB_LABEL.Seo);
        closeComments();
      }
    }
  }, [matchesEditor, matchesOnline]);

  return (
    <div className="side-panel-tabs">
      <div>
        <TooltipComponent
          title={
            sidePanelStatusHook[0] === ENUM_SIDE_PANEL_STATUS.Close
              ? t('content:side-panel-components.side-panel-header.open-sidebar')
              : t('content:side-panel-components.side-panel-header.close-sidebar')
          }
        >
          <div
            className={`side-panel-tabs__toggle side-panel-tabs__toggle${
              sidePanelStatusHook[0] === ENUM_SIDE_PANEL_STATUS.Close ? '__close' : '__open'
            } ${toggleDisabled ? 'side-panel-tabs__toggle__disabled' : ''}`}
            onClick={toggleDisabled ? undefined : handleCloseSidePanel}
          >
            <SidePanelToggleIcon fontSize="large" />
          </div>
        </TooltipComponent>
        {topTabs.map(({ icon, label, dataIntercomTarget }, index) => (
          <SidePanelTab
            key={`side-panel-top-tab-${index}`}
            data-intercom-target={dataIntercomTarget}
            icon={icon}
            label={label}
            selected={label === selectedTabHook[0]}
            onClick={handleClickTab(label)}
          />
        ))}
      </div>
      <div className="side-panel-tabs__bottom">
        {bottomTabs.map(({ icon, label, sticker, disabled }, index) => {
          if (label === ENUM_TAB_LABEL.Tour)
            return (
              <SidePanelTab
                key={`side-panel-bottom-tab-${index}`}
                data-intercom-target={
                  label === ENUM_TAB_LABEL.Tour
                    ? `product_tour_${isOnlinePage ? 'online' : 'content'}_sidepanel`
                    : ''
                }
                icon={icon}
                label={label}
                selected={label === selectedTabHook[0]}
                onClick={handleClickTab(label)}
              />
            );
          return (
            <SidePanelTab
              key={`side-panel-bottom-tab-${index}`}
              disabled={disabled}
              icon={icon}
              label={label}
              selected={label === selectedTabHook[0]}
              sticker={sticker}
              onClick={disabled ? undefined : handleClickTab(label)}
            />
          );
        })}
      </div>
    </div>
  );
}

export default SidePanelTabs;
