import './PageDataSideBar.scss';

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { IconSquare } from 'semji-core/components/IconSquare';
import { ProductTourIcon } from 'semji-core/icons/ProductTourIcon';

import { BetaBadge } from '@/components/Badge/BetaBadge';
import AngleDoubleLeftIcon from '@/components/icons/AngleDoubleLeftIcon';
import { PageDataLayoutContext } from '@/components/PageDataLayout/PageDataLayout.context';
import { PageDataSideBarTypes } from '@/components/PageDataLayout/PageDataSideBar/PageDataSideBar.types';

function PageDataSideBar({
  titleContent,
  footerContent,
  content,
  dataIntercomTarget,
  isBeta,
}: PageDataSideBarTypes) {
  const { t } = useTranslation();
  const { toggleSideBar } = useContext(PageDataLayoutContext);

  return (
    <div className="page-data-side-bar">
      <div className="page-data-side-bar__content">
        <div className="page-data-side-bar__content__header">
          <div className="page-data-side-bar__content__header__top">
            <h2 className="page-data-side-bar__content__header__top__title">{titleContent}</h2>
            <IconSquare size="normal" variant="tertiary" onClick={toggleSideBar}>
              <AngleDoubleLeftIcon />
            </IconSquare>
          </div>
          {isBeta && (
            <BetaBadge margin="0" width="35px">
              {t(`common:labels.beta`)}
            </BetaBadge>
          )}
        </div>
        <div className="page-data-side-bar__content__body">{content}</div>
        <div className="page-data-side-bar__content__footer">
          {footerContent}
          {dataIntercomTarget && (
            <div
              className={`page-data-side-bar__content__footer__item--primary ${window['is_' + dataIntercomTarget + '_enabled'] ? 'flex' : 'hidden'}`}
              data-intercom-target={dataIntercomTarget}
            >
              <ProductTourIcon />
              {t('components:badge.launch-tour.title')}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PageDataSideBar;
