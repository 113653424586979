import styled from 'styled-components/macro';

import ThreeDotsIcon from '../icons/ThreeDotsIcon';

const AbsoluteLoaderWrapper = styled(({ opaque, ...props }) => <div {...props} />)`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: ${(props) => (props.opaque ? '1' : '0.25')};
  color: currentColor;

  && {
    span {
      color: ${({ theme }) => theme.cssColors.dark060};
      font-size: ${({ theme }) => theme.textCss.sizes.md};
      font-weight: normal;
    }
  }
`;

const RelativeLoaderWrapper = styled(({ opaque, ...props }) => <div {...props} />)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: ${({ flex }) => flex && flex};
  opacity: ${(props) => (props.opaque ? '0.8' : '0.25')};
  color: currentColor;

  && {
    span {
      color: ${({ theme }) => theme.cssColors.dark060};
      font-size: ${({ theme }) => theme.textCss.sizes.md};
      font-weight: normal;
    }
  }
`;

const Loader = ({ fontSize = '3em', children = null, ...props }) => {
  return (
    <AbsoluteLoaderWrapper style={{ fontSize }} {...props}>
      <ThreeDotsIcon />
      {children}
    </AbsoluteLoaderWrapper>
  );
};

export const RelativeLoader = ({ fontSize = '2rem', children, ...props }) => {
  return (
    <RelativeLoaderWrapper style={{ fontSize }} {...props}>
      <ThreeDotsIcon />
      {children}
    </RelativeLoaderWrapper>
  );
};

export const LayoutLoader = styled(Loader)`
  color: white;
  background: none;
`;

export default Loader;
