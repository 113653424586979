import './Optimization.scss';

import Collapse from '@material-ui/core/Collapse';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatLoader } from 'semji-core/components/FlatLoader';
import { FloatLoader } from 'semji-core/components/FloatLoader';
import styled from 'styled-components/macro';

import {
  FlexCenteredAlignJustifyEndContainer,
  FlexColumnContainer,
  FlexJustifiedBetweenContainer,
} from '@/components/_common';
import CaretIcon from '@/components/icons/CaretIcon';
import Close from '@/components/icons/CloseIcon';
import CupAltIcon from '@/components/icons/CupAltIcon';
import InfoIcon from '@/components/icons/InfoIcon';
import ReloadIcon from '@/components/icons/ReloadIcon';
import { IconExternalLink } from '@/components/Navigation/Link';
import {
  AddOptimizationButton,
  DisableOptimizationButton,
  EnableOptimizationButton,
} from '@/components/Optimization/Instruction';
import ProgressBar from '@/components/Rating/LinearProgressBar';
import { DarkText } from '@/components/Text/Dark';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import InfoMessage from '@/containers/Content/SidePanel/Components/Optimization/Details/InfoMessage';
import Rotate from '@/design-system/components/Button/Rotate';
import Can from '@/hoc/Can';
import { SUPER_ADMIN_PERFORM_ACTION } from '@/utils/can/constants';

const ReloadButton = styled(ReloadIcon)`
  && {
    cursor: pointer;
    font-size: 0.8em;
    transition: color 400ms;
    color: ${(props) => props.theme.colors.defaultGrey};
  }
  &:hover {
    color: #51525e;
    background: #f4f4f5;
    padding: 0.3em;
    border-radius: 4px;
  }
`;

const InfoButton = styled(InfoIcon)`
  && {
    cursor: pointer;
    font-size: 0.7em;
    opacity: 0;
    transition:
      opacity 400ms,
      color 400ms;
    color: ${(props) => props.theme.colors.defaultGrey};
  }
  &:hover {
    color: ${(props) => props.theme.colors.darkGreyOpaque};
  }
`;

const CupButton = styled(CupAltIcon)`
  && {
    cursor: pointer;
    font-size: ${({ theme }) => theme.textCss.sizes.sm};
    color: ${({ theme }) => theme.cssColors.primary};
    padding: 4px 3.5px 2.5px 3.5px;
    border-radius: 3px;

    &:hover {
      color: #ca3b4e;
      background: #fff4f6;
    }
  }
`;

const Gain = styled.span`
  color: ${(props) => props.theme.text.colors.bright};
  padding: 0em 0.5em 0 0.5em;
  font-size: 0.8em;
  opacity: 0;
  font-weight: 400;
`;

const StyledTooltipComponent = styled(({ paddedLeft, ...props }) => (
  <TooltipComponent {...props} />
))`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: ${(props) => props.paddedLeft && '0.5em'};
`;

const RotateAction = styled(Rotate)`
  width: 10px;
  justify-content: center;
  font-size: 0.7em;
`;

const ActionToggleIconWrapper = styled.div`
  height: 18px;
  width: 18px;
  border-radius: 4px;
  cursor: pointer;
  padding: 0.1em;
  opacity: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7c7d86;
  &:hover {
    background: #f4f4f5;
    color: #51525e;
  }
`;

const ActionIconWrapper = styled.div`
  width: 15px;
  margin: 0 0;
`;

const Panel = styled(({ expanded, ...props }) => <div {...props} />)`
  margin-bottom: 0;
  background-color: transparent;
  box-shadow: none;
  transition: all 200ms;

  &:hover ${ActionToggleIconWrapper} {
    opacity: 1;
  }

  &:hover ${AddOptimizationButton} {
    opacity: 1;
  }

  &:hover ${InfoButton} {
    opacity: 1;
  }

  &:hover ${Gain} {
    opacity: 1;
  }
`;

const PanelHeader = styled(({ disabled, expanded, ...props }) => <div {...props} />)`
  height: 20px;
  padding: 0;
  display: flex;
  align-items: center;
  position: relative;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  cursor: pointer;
  &:hover {
    opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  }
`;

const PanelBody = styled(Collapse)`
  && {
    padding: 0.5em 0 0 0;
    margin-top: 0;
    opacity: ${(props) => (props.in ? 1 : 0)};
    transition: all 200ms;
  }
`;

const Title = styled.span`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.cssColors.dark100};
  font-weight: 500;
  font-size: 0.85em;
  padding-bottom: 0.3rem;
  padding-top: 0.3rem;
`;

const Scorer = styled(DarkText)`
  color: #7c7d86;
  font-size: 12px;
  font-weight: 400;
`;

const PanelContainer = styled(Collapse)``;

const Description = styled.div`
  display: flex;
  padding: 0.8em 0em;
  font-family: ${({ theme }) => theme.textCss.fonts.main};
  font-weight: ${({ theme }) => theme.textCss.weights.medium};
  font-size: ${() => '13px'};
  color: ${({ theme }) => theme.cssColors.dark060};
  line-height: 135%;

  & > span {
    flex: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    /* The block above is used to clamp line at a specified number: https://css-tricks.com/line-clampin/ */
  }
`;

const CloseIcon = styled(Close)`
  &&& {
    width: 1em;
    height: 1em;
    padding: 0.3em;
    font-size: 0.9em;
    cursor: pointer;
    &:hover {
      border-radius: 4px;
      background: #f4f4f5;
      color: #51525e;
    }
  }
`;

function Optimization({
  alertMessage,
  description,
  disabled,
  disableRecommendation,
  enableRecommendation,
  expanded,
  helpCenterLink,
  id,
  infoText,
  instructions,
  onClick,
  points,
  refreshAction,
  score,
  scorer,
  scorerTooltipText,
  showDetailsAction,
  showExternalLinkToBackOffice,
  title,
  isEmpty,
  isReady,
  infoMessage,
  loaderRows = 1,
  ...props
}) {
  const { t } = useTranslation();

  const [isDescriptionOpen, setIsDescriptionOpen] = useState(true);

  function closeDescription() {
    setIsDescriptionOpen(false);
  }

  function stopBubbling(e) {
    e.stopPropagation();
  }

  return (
    <Panel key={id} data-intercom-target={props['data-intercom-target']} expanded={expanded}>
      <PanelContainer in>
        <PanelHeader
          disabled={disabled || isEmpty}
          expanded={expanded && instructions}
          onClick={() => onClick?.(id)}
        >
          <FlexColumnContainer>
            <FlexJustifiedBetweenContainer>
              <Title>
                {title}

                {!!infoText && (
                  <StyledTooltipComponent paddedLeft title={infoText}>
                    <a
                      href={helpCenterLink}
                      rel="noopener noreferrer"
                      target="_blank"
                      onClick={stopBubbling}
                    >
                      <InfoButton bordered />
                    </a>
                  </StyledTooltipComponent>
                )}
                {!isEmpty && isReady && (
                  <Gain>
                    {t('components:optimization.optimization.gain_interval', {
                      count: disabled ? 0 : Math.round(points),
                      postProcess: 'interval',
                    })}
                  </Gain>
                )}
              </Title>
              <FlexCenteredAlignJustifyEndContainer gap="8px">
                {!!showExternalLinkToBackOffice && (
                  <Can
                    perform={SUPER_ADMIN_PERFORM_ACTION}
                    yes={() => (
                      <IconExternalLink
                        href={showExternalLinkToBackOffice.url}
                        title={showExternalLinkToBackOffice.title}
                      />
                    )}
                  />
                )}
                {!isEmpty && isReady && !!infoText && (
                  <div>
                    <ActionToggleIconWrapper
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!disabled) {
                          disableRecommendation(id);
                        } else {
                          enableRecommendation(id);
                        }
                      }}
                    >
                      <TooltipComponent
                        title={t(
                          disabled
                            ? 'components:optimization.optimization.enable'
                            : 'components:optimization.optimization.disable'
                        )}
                      >
                        {!disabled ? <DisableOptimizationButton /> : <EnableOptimizationButton />}
                      </TooltipComponent>
                    </ActionToggleIconWrapper>
                  </div>
                )}
                {!!showDetailsAction && isReady && !isEmpty && (
                  <ActionIconWrapper>
                    <StyledTooltipComponent
                      title={t('components:optimization.optimization.competitor-analysis')}
                    >
                      <CupButton
                        onClick={(e) => {
                          e.stopPropagation();
                          showDetailsAction();
                        }}
                      />
                    </StyledTooltipComponent>
                  </ActionIconWrapper>
                )}
                {!!refreshAction && isReady && !isEmpty && (
                  <ActionIconWrapper>
                    <StyledTooltipComponent
                      title={t('components:optimization.optimization.refresh')}
                    >
                      <ReloadButton
                        onClick={(e) => {
                          e.stopPropagation();
                          refreshAction();
                        }}
                      />
                    </StyledTooltipComponent>
                  </ActionIconWrapper>
                )}
                {!!scorer && !isEmpty && (
                  <Scorer>
                    {isReady ? (
                      <TooltipComponent fontSize="13px" title={scorerTooltipText}>
                        {scorer}
                      </TooltipComponent>
                    ) : (
                      <FloatLoader width="w-[24px]" />
                    )}
                  </Scorer>
                )}
                <RotateAction degree="-180" hasRotated={expanded}>
                  <CaretIcon />
                </RotateAction>
              </FlexCenteredAlignJustifyEndContainer>
            </FlexJustifiedBetweenContainer>
            {isReady ? (
              <ProgressBar
                backgroundColor={isReady ? 'dark005' : null}
                value={isReady && !isEmpty ? score : 0}
              />
            ) : (
              <div className="optimization__body__loader_wrapper__progressbar">
                <FloatLoader />
              </div>
            )}
          </FlexColumnContainer>
        </PanelHeader>
        <PanelBody in={expanded}>
          {alertMessage}
          {description && isDescriptionOpen && (
            <Description>
              <span>{description}</span>
              <div>
                <TooltipComponent
                  fontSize="13px"
                  title={t('components:optimization.optimization.close-description')}
                >
                  <CloseIcon onClick={closeDescription} />
                </TooltipComponent>
              </div>
            </Description>
          )}
          {instructions && !isEmpty && isReady && (
            <FlexColumnContainer>{instructions}</FlexColumnContainer>
          )}
          {isEmpty && (
            <FlexColumnContainer>
              <InfoMessage message={infoMessage} />
            </FlexColumnContainer>
          )}
          {!isReady && (
            <div className="optimization__body__loader_wrapper">
              <div className="optimization__body__loader_wrapper__loader">
                <FlatLoader lines={loaderRows} />
              </div>
              <div className="optimization__body__loader_wrapper__loader">
                <FlatLoader lines={loaderRows} />
              </div>
              <div className="optimization__body__loader_wrapper__loader">
                <FlatLoader lines={loaderRows} />
              </div>
            </div>
          )}
        </PanelBody>
      </PanelContainer>
    </Panel>
  );
}

export default Optimization;
