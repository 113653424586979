import './ProductionCharts.scss';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'semji-core/components/Tooltip';
import { InfoIcon } from 'semji-core/icons/InfoIcon';

import usePostReportPageMetrics from '@/apis/semji/reports/usePostReportPageMetrics';
import { BadgeWrapper } from '@/components/Chart/ChartWrappers';
import NoContentsVerticalBars, {
  NO_CONTENTS,
} from '@/components/EmptyState/NoContentsVerticalBars';
import Loader from '@/components/Loader/Loader';
import MetricsChart from '@/containers/Chart/MetricsChart';
import { ReportTabs } from '@/containers/Report/components/ReportTabs';
import { ENUM_REPORT_TITLE_VARIANT, ReportTitle } from '@/containers/Report/components/ReportTitle';
import { METRICS_CONFIG } from '@/containers/Report/utils/constants';
import { combineNewAndUpdatedContentsMetric } from '@/containers/Report/utils/helpers';
import { ReportMetricCategory, ReportMetricKey, ReportView } from '@/containers/Report/utils/types';
import { useMetricsConfig } from '@/hooks/useMetricsConfig';
import defaultTheme from '@/themes/defaultTheme';
import { ReportPeriod, ReportPeriodicity } from '@/types/reports';
import { getViewFilter } from '@/utils/helper';
import { aggregateMetrics } from '@/utils/metrics/aggregateMetrics';
import { comparisonPeriods } from '@/utils/metrics/constants';
import { getCurrentDateRangeSQLFormat } from '@/utils/metrics/getCurrentDateRangeSQLFormat';
import { getPreviousDateRangeSQLFormat } from '@/utils/metrics/getPreviousDateRangeSQLFormat';

export default function ProductionCharts({
  comparisonPeriod,
  loading,
  newPagesCount,
  period,
  periodicity,
  updatedPagesCount,
}: {
  comparisonPeriod: string;
  loading: boolean;
  newPagesCount: number;
  period: ReportPeriod;
  periodicity: ReportPeriodicity;
  updatedPagesCount: number;
}) {
  const metrics = useMetricsConfig({ isNew: true });
  const [metricKey, setMetricKey] = useState<ReportMetricKey>(ReportMetricKey.publicationsCount);

  const { t } = useTranslation();

  const currentRangeDate = getCurrentDateRangeSQLFormat({ period });
  const previousRangeDate = getPreviousDateRangeSQLFormat({ comparisonPeriod, period });
  const payload = {
    pageIds: [],
  };
  const commonFilters = {
    'exists[url]': true,
    'order[date]': 'asc',
    periodicity,
  };
  const {
    data: currentMetricsNew = [],
    isLoading: isCurrentMetricsNewLoading,
    isPlaceholderData: isCurrentMetricsNewPlaceholderData,
  } = usePostReportPageMetrics({
    filters: {
      ...getViewFilter(ReportView.New),
      date: {
        after: currentRangeDate.from,
        before: currentRangeDate.to,
      },
      ...commonFilters,
    },
    payload,
  });

  const {
    data: currentMetricsExisting = [],
    isLoading: isCurrentMetricsExistingLoading,
    isPlaceholderData: isCurrentMetricsExistingPlaceholderData,
  } = usePostReportPageMetrics({
    filters: {
      ...getViewFilter(ReportView.Existing),
      date: {
        after: currentRangeDate.from,
        before: currentRangeDate.to,
      },
      ...commonFilters,
    },
    payload,
  });

  const {
    data: currentMetricsAll = [],
    isLoading: isCurrentMetricsAllLoading,
    isPlaceholderData: isCurrentMetricsAllPlaceholderData,
  } = usePostReportPageMetrics({
    filters: {
      ...getViewFilter(ReportView.All),
      date: {
        after: currentRangeDate.from,
        before: currentRangeDate.to,
      },
      ...commonFilters,
    },
    payload,
  });

  const {
    data: previousMetricsNew = [],
    isLoading: isPreviousMetricsNewLoading,
    isPlaceholderData: isPreviousMetricsNewPlaceholderData,
  } = usePostReportPageMetrics({
    filters: {
      ...getViewFilter(ReportView.New),
      date: {
        after: previousRangeDate.from,
        before: previousRangeDate.to,
      },
      ...commonFilters,
    },
    payload,
  });

  const {
    data: previousMetricsExisting = [],
    isLoading: isPreviousMetricsExistingLoading,
    isPlaceholderData: isPreviousMetricsExistingPlaceholderData,
  } = usePostReportPageMetrics({
    filters: {
      ...getViewFilter(ReportView.Existing),
      date: {
        after: previousRangeDate.from,
        before: previousRangeDate.to,
      },
      ...commonFilters,
    },
    payload,
  });

  const {
    data: previousMetricsAll = [],
    isLoading: isPreviousMetricsAllLoading,
    isPlaceholderData: isPreviousMetricsAllPlaceholderData,
  } = usePostReportPageMetrics({
    filters: {
      ...getViewFilter(ReportView.All),
      date: {
        after: previousRangeDate.from,
        before: previousRangeDate.to,
      },
      ...commonFilters,
    },
    payload,
  });

  function getLoadingState(chartKey: ReportMetricKey) {
    if (chartKey === ReportMetricKey.publicationsCount) {
      return (
        isCurrentMetricsNewLoading ||
        isCurrentMetricsNewPlaceholderData ||
        isPreviousMetricsNewLoading ||
        isPreviousMetricsNewPlaceholderData ||
        isCurrentMetricsExistingLoading ||
        isCurrentMetricsExistingPlaceholderData ||
        isPreviousMetricsExistingLoading ||
        isPreviousMetricsExistingPlaceholderData ||
        loading
      );
    }
    return (
      isCurrentMetricsAllLoading ||
      isCurrentMetricsAllPlaceholderData ||
      isPreviousMetricsAllLoading ||
      isPreviousMetricsAllPlaceholderData ||
      loading
    );
  }

  function getCurrentMetrics(chartKey: ReportMetricKey) {
    if (chartKey === ReportMetricKey.publicationsCount) {
      return combineNewAndUpdatedContentsMetric(
        currentMetricsNew,
        currentMetricsExisting,
        ReportMetricKey.publicationsCount
      );
    }

    return currentMetricsAll;
  }

  function getPreviousMetrics(chartKey: ReportMetricKey) {
    if (chartKey === ReportMetricKey.publicationsCount) {
      return combineNewAndUpdatedContentsMetric(
        previousMetricsNew,
        previousMetricsExisting,
        ReportMetricKey.publicationsCount
      );
    }

    return previousMetricsAll;
  }

  const tabs = [
    {
      ...METRICS_CONFIG.publicationsCount,
      category: ReportMetricCategory.Performance,
    },
    {
      ...METRICS_CONFIG.publicationsAverageContentScore,
      category: ReportMetricCategory.Performance,
    },
  ];

  const displayedMetrics =
    metricKey === ReportMetricKey.AverageContentScore
      ? {
          [ReportMetricKey.AverageContentScore]: {
            ...METRICS_CONFIG[ReportMetricKey.AverageContentScore],
            previousVisible: true,
          },
        }
      : {
          publicationsCountNew: {
            ...METRICS_CONFIG[ReportMetricKey.publicationsCount],
            title: t('dashboard:contents-production.new-content_interval', {
              count: 0,
              postProcess: 'interval',
            }),
            tooptipTitleKey: 'dashboard:contents-production.new-content_interval',
          },
          publicationsCountUpdated: {
            ...METRICS_CONFIG[ReportMetricKey.publicationsCount],
            title: t('dashboard:contents-production.updated-content_interval', {
              count: 0,
              postProcess: 'interval',
            }),
            tooptipTitleKey: 'dashboard:contents-production.updated-content_interval',
          },
        };

  return (
    <div className="page-dashboard__production-charts">
      <div className="page-dashboard__production-charts__header">
        <div className="page-dashboard__production-charts__header__title">
          <span>{t('dashboard:contents-production.contents-production')}</span>
          <Tooltip
            className="page-dashboard__production-charts__header__info"
            title={t('dashboard:contents-production.contents-production-tooltip')}
            tooltipClassName="page-dashboard__production-charts__header__tooltip"
          >
            <InfoIcon />
          </Tooltip>
        </div>
        <ReportTabs
          currentMetricKey={metricKey}
          maxInlineMetrics={2}
          metricsList={tabs}
          selectMetricClick={(key) => setMetricKey(key)}
          showMoreButton={false}
        />
      </div>
      {getLoadingState(metricKey) ? (
        <div className="page-dashboard__production-charts__content">
          <Loader />
        </div>
      ) : newPagesCount + updatedPagesCount === 0 ? (
        <div className="page-dashboard__production-charts__content">
          <NoContentsVerticalBars version={NO_CONTENTS} />
        </div>
      ) : (
        <div
          className={`page-dashboard__production-charts__content page-dashboard__production-charts__content--${metricKey === ReportMetricKey.publicationsCount ? 'row' : 'col'}`}
        >
          {metricKey !== ReportMetricKey.publicationsCount && (
            <ReportTitle
              activeSubTab={metricKey}
              aggregatedMetrics={aggregateMetrics(currentMetricsAll, metrics)}
              aggregatedPreviousMetrics={aggregateMetrics(previousMetricsAll, metrics)}
              comparisonPeriodLabel={t(comparisonPeriods[comparisonPeriod].label)}
              currentCount={newPagesCount + updatedPagesCount}
              currentMetricKey={metricKey}
              currentMetricsState={currentMetricsAll}
              variant={ENUM_REPORT_TITLE_VARIANT.Medium}
            />
          )}
          {metricKey === ReportMetricKey.publicationsCount && (
            <div className="page-dashboard__production-charts__content__sidebar">
              <BadgeWrapper
                color={defaultTheme.metricsColor.publicationsCountNew}
                count={newPagesCount}
                title={t('dashboard:contents-production.new-content_interval', {
                  count: newPagesCount,
                  postProcess: 'interval',
                })}
              />
              <BadgeWrapper
                color={defaultTheme.metricsColor.publicationsCountUpdated}
                count={updatedPagesCount}
                title={t('dashboard:contents-production.updated-content_interval', {
                  count: updatedPagesCount,
                  postProcess: 'interval',
                })}
              />
            </div>
          )}
          <div className="page-dashboard__production-charts__content__chart-wrapper">
            <MetricsChart
              commonAxis={metricKey === ReportMetricKey.publicationsCount ? true : false}
              comparisonPeriod={comparisonPeriod}
              currentMetricKey={metricKey}
              disableLegend={metricKey === ReportMetricKey.publicationsCount}
              displayedMetrics={displayedMetrics}
              identifier="production_chart"
              metrics={getCurrentMetrics(metricKey)}
              period={period}
              periodicity={periodicity}
              previousMetrics={getPreviousMetrics(metricKey)}
              stacked={metricKey === ReportMetricKey.publicationsCount ? 'normal' : undefined}
            />
          </div>
        </div>
      )}
    </div>
  );
}
