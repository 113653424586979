import { useMutation } from '@tanstack/react-query';

import apiClient from '@/apis/semji/apiClient';
import { SOURCE_UNKNOWN } from '@/utils/analysis';

export default function usePostTopKeywordAnalysis(props) {
  const { post } = apiClient;

  return useMutation({
    mutationFn: ({ topKeywordIdList = [], data = {} }) =>
      Promise.all(
        topKeywordIdList.map((topKeywordId) =>
          post(`/top_keywords/${topKeywordId}/analysis`, {
            ...{ source: SOURCE_UNKNOWN },
            ...data,
          })
        )
      ),

    ...props,
  });
}
