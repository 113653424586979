import './ReportPagesContainer.scss';

import React from 'react';
import { memo, useMemo } from 'react';
import deepEqual from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import SortDirection from 'react-virtualized/dist/es/Table/SortDirection';

import Table from '@/components/Table/Table';
import reportPagesColumnsBuilder from '@/containers/Report/components/PagesColumns';
import { PageWithMetrics } from '@/containers/Report/utils/types';
import { useMetricsConfig } from '@/hooks/useMetricsConfig';
import { selectCurrentWorkspaceCountry } from '@/store/selectors/selectCurrentWorkspaceCountry';
import { selectHasAnalytics } from '@/store/selectors/selectHasAnalytics';
import { selectHasSearchConsole } from '@/store/selectors/selectHasSearchConsole';
import { DEFAULT_REPORT_METRIC_KEY } from '@/utils/constants';
import { getComparableDayWithToday } from '@/utils/metrics/getComparableDayWithToday';
import { getComparisonDates } from '@/utils/metrics/getComparisonDates';

const getDefaultSortColumn = (metricKey: string, reportView: string) => {
  if (metricKey === DEFAULT_REPORT_METRIC_KEY) {
    return 'traffic';
  }

  if (reportView === 'new') {
    return `current_metric_key_${metricKey}`;
  }

  return 'change';
};

function ReportPagesContainer({
  reportView,
  period,
  periodicity,
  comparisonPeriod,
  metricKey,
  filteredPages,
  isLoading,
}: {
  reportView: string;
  period: string;
  periodicity: string;
  comparisonPeriod: string;
  metricKey: string;
  filteredPages: PageWithMetrics[];
  isLoading: boolean;
}) {
  const { workspaceId, organizationId } = useParams();
  const metrics = useMetricsConfig({ isNew: true });
  const hasSearchConsole = useSelector(selectHasSearchConsole);
  const hasAnalytics = useSelector(selectHasAnalytics);

  const userLanguageCode = useSelector((state: any) => state.user?.languageCode);
  const { countryName } = useSelector(selectCurrentWorkspaceCountry);

  const positionComparisonDates = useMemo(
    () => getComparableDayWithToday({ comparisonPeriod, period }),
    [period, comparisonPeriod]
  );
  const comparisonDates = useMemo(
    () => getComparisonDates({ comparisonPeriod, period }),
    [period, comparisonPeriod]
  );

  const buildColumns = useMemo(
    () =>
      reportPagesColumnsBuilder({
        comparisonDates,
        countryName,
        hasAnalytics,
        hasSearchConsole,
        metricKey,
        metrics,
        monthlyPageMetricsLoading: isLoading,
        organizationId,
        period,
        periodicity,
        positionComparisonDates,
        userLanguageCode,
        workspaceId,
      }),
    [
      metrics,
      metricKey,
      positionComparisonDates,
      periodicity,
      organizationId,
      period,
      isLoading,
      hasSearchConsole,
      hasAnalytics,
      userLanguageCode,
      comparisonDates,
    ]
  );

  if (filteredPages.length) {
    return (
      <div className="report-metric-page-container">
        <Table
          columns={buildColumns}
          defaultSort={getDefaultSortColumn(metricKey, reportView)}
          defaultSortDirection={SortDirection.DESC}
          mode="transparent"
          overscanRowCount={5}
          rows={filteredPages}
          width="inherit"
        />
      </div>
    );
  }

  return null;
}

export default memo(ReportPagesContainer, deepEqual);
