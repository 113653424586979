import { nanoid } from 'nanoid';
import React, { useMemo } from 'react';

import SvgWrapper from './SvgWrapper';

function ReloadIcon(props) {
  const maskId = useMemo(() => nanoid(), []);

  return (
    <SvgWrapper {...props} fill="none" height="17" viewBox="0 0 17 17" width="17">
      <mask
        height="17"
        id={`mask${maskId}`}
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="17"
        x="0"
        y="0"
      >
        <path
          d="M6 8H0V2H1V6.109C2.013 2.916 5.036 0.625 8.5 0.625C12.006 0.625 15.121 2.985 16.074 6.364L15.111 6.635C14.279 3.685 11.56 1.624 8.5 1.624C5.274 1.625 2.484 3.9 1.792 7H6V8ZM11 9V10H15.208C14.515 13.101 11.729 15.375 8.5 15.375C5.438 15.375 2.72 13.314 1.889 10.364L0.926 10.635C1.878 14.014 4.993 16.374 8.5 16.374C11.959 16.374 14.975 14.094 16 10.892V15H17V9H11Z"
          fill="currentColor"
        />
      </mask>
      <g mask={`url(#mask${maskId})`}>
        <rect fill="currentColor" height="20" width="20" x="-2" y="-1" />
      </g>
    </SvgWrapper>
  );
}

export default ReloadIcon;
