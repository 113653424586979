import { useEffect, useRef } from 'react';

import useDebug from '@/hooks/useDebug';
import usePageVisibility from '@/hooks/usePageVisibility';
import { Log } from '@/utils/log/Log';

const HTTP_STATUS_DENIED = 403;

// This hook will replace all the recursive callback with set timeout
// for now, we are using recursive callback in class components
// a first implementation has been done on Profile component
const useRecursiveTimeout = (callback, delay = 1000, isRanOnLoad = false) => {
  const isRecursiveCallDisabled = useDebug('isRecursiveCallDisabled');

  const timerRef = useRef();
  const recursiveTimerRef = useRef();
  const callbackRef = useRef();
  const isCallbackAccessDeniedRef = useRef(false);
  const isCallbackRunningRef = useRef(false);

  async function executeCallback() {
    if (!isCallbackRunningRef.current && !isCallbackAccessDeniedRef.current) {
      try {
        isCallbackRunningRef.current = true;
        await callbackRef.current?.();
      } catch (e) {
        Log.error('useRecursiveTimeout', callbackRef.current?.name, { e });

        if (e?.status === HTTP_STATUS_DENIED) {
          isCallbackAccessDeniedRef.current = true;
        }
      } finally {
        isCallbackRunningRef.current = false;
      }
    }
  }

  async function tick() {
    await executeCallback();
    recursiveTimerRef.current = setTimeout(tick, delay);
  }

  async function launchTimer() {
    cleanUp();

    if (isRecursiveCallDisabled) {
      await executeCallback();
      return undefined;
    }

    if (isRanOnLoad) {
      await executeCallback();
    }

    timerRef.current = setTimeout(tick, delay);
  }

  function cleanUp() {
    clearTimeout(timerRef.current);
    clearTimeout(recursiveTimerRef.current);
  }

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    launchTimer();

    return cleanUp;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  usePageVisibility({
    onHide: cleanUp,
    onShow: launchTimer,
  });

  return cleanUp;
};

export default useRecursiveTimeout;
