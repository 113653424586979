import React, { useId } from 'react';

import SvgWrapper from './SvgWrapper';

function ValidatedGradientIcon({ ...props }) {
  const id = useId();
  return (
    <SvgWrapper fill="none" height="20" viewBox="0 0 20 20" width="20" {...props}>
      <circle cx="9.99946" cy="10" fill={`url(#paint0_linear_${id})`} r="9.77778" />

      <mask
        height="9"
        id={`mask0_${id}`}
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="9"
        x="6"
        y="5"
      >
        <path
          d="M14.6284 5.49033L9.16622 13.8293L6.125 11.117L6.53685 10.6557L9.04193 12.8906L14.1115 5.15146L14.6284 5.49033V5.49033Z"
          fill="black"
        />
      </mask>
      <g mask={`url(#mask0_${id})`}>
        <rect fill="white" height="12.3677" width="12.3677" x="3.85742" y="3.7749" />
      </g>
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id={`paint0_linear_${id}`}
          x1="4.44265e-05"
          x2="22.004"
          y1="11.5988"
          y2="11.5988"
        >
          <stop stopColor="#FF4580" />
          <stop offset="1" stopColor="#FF4D64" />
        </linearGradient>
      </defs>
    </SvgWrapper>
  );
}

export default ValidatedGradientIcon;
