import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import apiClient from '@/apis/semji/apiClient';
import { LOCATION, SCOPE_CONTENT_IDEAS } from '@/apis/semji/constants';
import { PENDING_STATUS } from '@/components/Pages/ContentIdeas/constant';
import { showErrorSnackbar } from '@/store/actions/ui';

export default function useGetContentIdeaSearch({
  contentIdeaSearchId,
  selectedContentIdeaSearch,
  ...props
}) {
  const { t } = useTranslation();
  const { get } = apiClient;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const workspaceId = useSelector((state) => state.defaultWorkspaceId || '');
  const organizationId = useSelector((state) => state.defaultOrganizationId || '');

  return useQuery({
    onError: () => {
      dispatch(showErrorSnackbar(t(`content-ideas:listing.error.default`)));
      navigate(`/o/${organizationId}/w/${workspaceId}/content-ideas/`, { replace: true });
    },
    placeholderData: {
      contentIdeaSearch: null,
    },

    queryFn: async ({ signal }) => {
      if (selectedContentIdeaSearch) return selectedContentIdeaSearch;

      const contentIdeaSearch = await get(`/content_idea_searches/${contentIdeaSearchId}`, {
        signal,
      });
      return contentIdeaSearch.data || null;
    },
    queryKey: [
      SCOPE_CONTENT_IDEAS.CONTENT_IDEAS_SEARCH,
      contentIdeaSearchId,
      LOCATION.CONTENT_IDEAS,
    ],
    refetchInterval: (contentIdeaSearch) => {
      if (
        contentIdeaSearch?.status === PENDING_STATUS ||
        contentIdeaSearch?.clusterizationStatus === PENDING_STATUS
      ) {
        return 2000;
      } else {
        return false;
      }
    },
    refetchOnWindowFocus: false,
    ...props,
  });
}
