import MuiFormControl from '@material-ui/core/FormControl';
import styled from 'styled-components/macro';

const FormControl = styled(MuiFormControl)`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;

    &,
    & > * {
      cursor: pointer;
    }
  }
`;

export default FormControl;
