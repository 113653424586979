import InputAdornment from '@material-ui/core/InputAdornment';
import Radio from '@material-ui/core/Radio';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import EmptyStateSearch from '@/assets/images/empty-state-search-no-result.svg';
import SearchIcon from '@/components/icons/SearchIcon';
import Input from '@/components/Input/Input';
import FlatLoader, { CircularLoaderWrapper, LoaderWrapper } from '@/components/Loader/FlatLoader';
import WarningMessage from '@/components/Message/WarningMessage';
import Flex from '@/design-system/components/Flex';

const Wrapper = styled(Flex)`
  width: 100%;
  max-width: 700px;
  padding: 32px 40px 48px 40px;
  box-shadow: ${({ theme }) => theme.boxShadow.modal};
`;
const Title = styled.span`
  color: ${({ theme }) => theme.cssColors.dark060};
  line-height: ${({ theme }) => theme.textCss.sizes.md};
  margin: 20px 0 17px 0;
`;
const StyledSearchIcon = styled(SearchIcon)`
  && {
    color: ${({ theme }) => theme.cssColors.dark080};
    font-size: ${({ theme }) => theme.textCss.sizes.md};
  }
`;
const StyledInput = styled(Input)`
  && {
    padding-bottom: 16px;
    & > div > input {
      padding: 15px 0 15px;
    }
  }
`;
const StyledRadio = styled(Radio)`
  && {
    & > span > div {
      color: ${({ theme, checked }) => (checked ? theme.colors.pink : theme.cssColors.dark040)};
    }
    & > span > div > svg {
      font-size: ${({ theme }) => theme.textCss.sizes.lg};
    }
  }
`;
const Name = styled.span`
  font-size: ${({ theme }) => theme.textCss.sizes.sm};
  line-height: ${({ theme }) => theme.textCss.sizes.md};
  color: ${({ theme }) => theme.cssColors.dark080};
`;
const Value = styled.span`
  font-size: ${({ theme }) => theme.textCss.sizes.sm};
  line-height: ${({ theme }) => theme.textCss.sizes.md};
  color: ${({ theme }) => theme.cssColors.dark040};
`;
const RowWrapper = styled(Flex)`
  margin-left: -10px;
  overflow-y: auto;
`;
const Message = styled.span`
  color: ${({ theme }) => theme.cssColors.dark080};
  font-size: ${({ theme }) => theme.textCss.sizes.sm};
  line-height: ${({ theme }) => theme.textCss.sizes.lg};
`;
const EmptyStateTitle = styled.span`
  line-height: ${({ theme }) => theme.textCss.sizes.xl};
  font-weight: ${({ theme }) => theme.textCss.weights.strong};
  text-align: center;
`;
const EmptyStateSubTitle = styled.span`
  line-height: ${({ theme }) => theme.textCss.sizes.lg};
  font-size: ${({ theme }) => theme.textCss.sizes.sm};
  color: ${({ theme }) => theme.cssColors.dark060};
  text-align: center;
`;

const ServiceContainer = styled(Flex)`
  cursor: pointer;
`;

function SelectService({
  logo,
  title,
  handleGetFormatedData,
  currentValue,
  setCurrentValue,
  errorMessage = null,
}) {
  const { t } = useTranslation();
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState('');

  const filteredList = list.filter(
    (elem) =>
      elem.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
      elem?.url?.toLocaleLowerCase()?.includes(search.toLocaleLowerCase()) ||
      elem?.value?.toLocaleLowerCase()?.includes(search.toLocaleLowerCase())
  );

  async function handleGetList() {
    const data = await handleGetFormatedData();

    setList(
      data.sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()))
    );
    setCurrentValue(data[0]);
    setIsLoading(false);
  }

  function handleSelect(value) {
    return () => {
      setCurrentValue(list.find((elem) => elem.value === value));
    };
  }

  function handleChangeSearch(e) {
    setSearch(e.target.value);
  }

  useEffect(() => {
    handleGetList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper flexDirection="column">
      {logo}
      <Title>{title}</Title>
      <StyledInput
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <StyledSearchIcon />
            </InputAdornment>
          ),
        }}
        placeholder={t('service-integration:search-label.search')}
        value={search}
        onChange={handleChangeSearch}
      />
      <RowWrapper flexDirection="column" gap="12px" height="248px">
        {!isLoading && !filteredList.length ? (
          !list.length && errorMessage ? (
            <WarningMessage>
              <Message>{errorMessage}</Message>
            </WarningMessage>
          ) : (
            <Flex
              alignItems="center"
              flex="1"
              flexDirection="column"
              gap="5px"
              justifyContent="center"
            >
              <img
                alt={t('service-integration:search-label.search-alt')}
                src={EmptyStateSearch}
                style={{ height: '83px', width: '102px' }}
              />
              <EmptyStateTitle>
                {t('service-integration:search-label.title', { search })}
              </EmptyStateTitle>
              <EmptyStateSubTitle>
                {t('service-integration:search-label.sub-title')}
              </EmptyStateSubTitle>
            </Flex>
          )
        ) : (
          (isLoading ? new Array(5).fill({ loading: true }) : filteredList).map(
            ({ name, url, value, loading }, index) => (
              <Flex
                key={index}
                alignItems="center"
                gap={loading ? '15px' : '5px'}
                height={loading && '40px'}
                onClick={handleSelect(value)}
              >
                {loading ? (
                  <CircularLoaderWrapper height={25} width={25}>
                    <FlatLoader />
                  </CircularLoaderWrapper>
                ) : (
                  <StyledRadio checked={value === currentValue?.value} value={value} />
                )}
                {loading ? (
                  <LoaderWrapper height={25} width={300}>
                    <FlatLoader />
                  </LoaderWrapper>
                ) : (
                  <ServiceContainer flexDirection="column">
                    <Name>{name}</Name>
                    <Flex alignItems="center" gap="5px">
                      <Value>
                        {value} {url && `- ${url}`}
                      </Value>
                    </Flex>
                  </ServiceContainer>
                )}
              </Flex>
            )
          )
        )}
      </RowWrapper>
    </Wrapper>
  );
}

export default SelectService;
