import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { LOCATION, SCOPE_SERVICE_INTEGRATIONS } from '@/apis/semji/constants';
import usePostAdobeCompanies from '@/apis/semji/serviceIntegrations/Adobe/usePostAdobeCompanies';
import usePostAdobeDimensions from '@/apis/semji/serviceIntegrations/Adobe/usePostAdobeDimensions';
import usePostAdobeProjects from '@/apis/semji/serviceIntegrations/Adobe/usePostAdobeProjects';
import useDeleteServiceIntegration from '@/apis/semji/serviceIntegrations/useDeleteServiceIntegration';
import usePostServiceIntegration from '@/apis/semji/serviceIntegrations/usePostServiceIntegration';
import Stepper from '@/components/Stepper/Stepper';
import ApiInformations from '@/containers/ServiceIntegration/common/ApiInformations';
import SelectService from '@/containers/ServiceIntegration/common/SelectService';
import { ADOBE_VALUE, SERVICES_LIST, TYPE_ANALYTICS } from '@/utils/constants';

function AdobeServiceIntegration() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { organizationId, workspaceId } = useParams();
  const queryClient = useQueryClient();

  const [step, setStep] = useState(0);
  const [validating, setValidating] = useState(false);
  const [clientId, setClientId] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [company, setCompany] = useState(null);
  const [project, setProject] = useState(null);
  const [dimension, setDimension] = useState(null);

  const currentAnalyticsServiceIntegration = queryClient
    .getQueryData([SCOPE_SERVICE_INTEGRATIONS.GET_SERVICE_INTEGRATIONS, workspaceId, LOCATION.ROOT])
    ?.find((service) => service.type === TYPE_ANALYTICS);

  const currentService = Object.values(SERVICES_LIST).find((service) =>
    service.values.includes(currentAnalyticsServiceIntegration?.name)
  );

  const deleteServiceIntegration = useDeleteServiceIntegration();
  const postServiceIntegration = usePostServiceIntegration({
    onError: () => {
      setValidating(false);
    },
    onMutate: async () => {
      setValidating(true);

      if (currentAnalyticsServiceIntegration) {
        await deleteServiceIntegration.mutateAsync(currentAnalyticsServiceIntegration.id);
      }
    },
    onSuccess: async () => {
      await queryClient.refetchQueries({
        active: true,
        exact: true,
        fetching: false,
        queryKey: [SCOPE_SERVICE_INTEGRATIONS.GET_SERVICE_INTEGRATIONS, workspaceId, LOCATION.ROOT],
      });
      setValidating(false);
      handleGoToServiceIntegration();
    },
  });

  const postAdobeCompanies = usePostAdobeCompanies();
  const postAdobeProjects = usePostAdobeProjects();
  const postAdobeDimensions = usePostAdobeDimensions();

  function handleGoToServiceIntegration() {
    navigate(`/o/${organizationId}/w/${workspaceId}/settings/integrations`);
  }

  function handlePrevious() {
    setStep(step - 1);
  }

  async function handleNext() {
    setStep(step + 1);
  }

  function isNextDisabled() {
    if (step === 0) {
      return !clientId || !clientSecret;
    }

    if (step === 1) {
      return !company;
    }

    if (step === 2) {
      return !project;
    }

    if (step === 3) {
      return !dimension || validating;
    }

    return true;
  }

  async function handleGetCompagniesFormatedData() {
    try {
      const { data } = await postAdobeCompanies.mutateAsync({ clientId, clientSecret });

      return data.companies.imsOrgs['0'].companies.map((company) => ({
        name: company.companyName,
        value: company.globalCompanyId,
      }));
    } catch {
      return [];
    }
  }

  async function handleGetProjectsFormatedData() {
    try {
      const { data } = await postAdobeProjects.mutateAsync({
        clientId,
        clientSecret,
        companyId: company.value,
      });

      return data.projects.map((project) => ({
        name: project.siteTitle,
        value: project.rsid,
      }));
    } catch {
      return [];
    }
  }

  async function handleGetDimensionsFormatedData() {
    try {
      const { data } = await postAdobeDimensions.mutateAsync({
        clientId,
        clientSecret,
        companyId: company.value,
        projectId: project.value,
      });

      return data.dimensions.map((dimension) => ({
        name: dimension.name,
        value: dimension.id,
      }));
    } catch {
      return [];
    }
  }

  function getStep(index) {
    const steps = [
      {
        step: t('service-integration:adobe.steps.step1.step'),
        subTitle: t('service-integration:adobe.steps.step1.subTitle'),
        title: t('service-integration:adobe.steps.step1.title'),
      },
      {
        step: t('service-integration:adobe.steps.step2.step'),
        subTitle: t('service-integration:adobe.steps.step2.subTitle'),
        title: t('service-integration:adobe.steps.step2.title'),
      },
      {
        step: t('service-integration:adobe.steps.step3.step'),
        subTitle: t('service-integration:adobe.steps.step3.subTitle'),
        title: t('service-integration:adobe.steps.step3.title'),
      },
      {
        step: t('service-integration:adobe.steps.step4.step'),
        subTitle: t('service-integration:adobe.steps.step4.subTitle'),
        title: t('service-integration:adobe.steps.step4.title'),
      },
    ];

    return steps[index] || {};
  }

  async function handleValidate() {
    await postServiceIntegration.mutateAsync({
      data: {
        client_id: clientId,
        client_secret: clientSecret,
        company_id: company.value,
        dimension_id: dimension.value,
        project_id: project.value,
      },
      name: ADOBE_VALUE,
      type: SERVICES_LIST.ADOBE.type,
    });
  }

  function renderStep() {
    if (step === 0) {
      return (
        <ApiInformations
          access={clientId}
          accessPlaceholder={t('service-integration:adobe.api-informations.placeholder-client-id')}
          accessTitle={t('service-integration:adobe.api-informations.sub-title-client-id')}
          handleConfirm={handleNext}
          keyPlaceholder={t('service-integration:adobe.api-informations.placeholder-client-secret')}
          keyTitle={t('service-integration:adobe.api-informations.sub-title-client-secret')}
          keyValue={clientSecret}
          linkHref={t('common:links.help-connect-adobe')}
          linkText={t('service-integration:adobe.api-informations.help-center-text')}
          logo={
            <img
              alt={t('service-integration:adobe.alt-logo')}
              src={SERVICES_LIST.ADOBE.logo}
              style={SERVICES_LIST.ADOBE.logoStyle}
            />
          }
          setAccess={setClientId}
          setKey={setClientSecret}
          title={t('service-integration:adobe.api-informations.title')}
          warningMessage={
            currentAnalyticsServiceIntegration &&
            t('service-integration:adobe.api-informations.warning', {
              analytic: currentService?.title,
            })
          }
        />
      );
    }
    if (step === 1) {
      return (
        <SelectService
          key="select-company"
          currentValue={company}
          errorMessage={
            <Trans i18nKey={'service-integration:adobe.company-informations.error-message'} />
          }
          handleGetFormatedData={handleGetCompagniesFormatedData}
          logo={
            <img
              alt={t('service-integration:adobe.alt-logo')}
              src={SERVICES_LIST.ADOBE.logo}
              style={SERVICES_LIST.ADOBE.logoStyle}
            />
          }
          setCurrentValue={setCompany}
          title={t('service-integration:adobe.company-informations.title')}
        />
      );
    }
    if (step === 2) {
      return (
        <SelectService
          key="select-project"
          currentValue={project}
          errorMessage={
            <Trans i18nKey={'service-integration:adobe.project-informations.error-message'} />
          }
          handleGetFormatedData={handleGetProjectsFormatedData}
          logo={
            <img
              alt={t('service-integration:adobe.alt-logo')}
              src={SERVICES_LIST.ADOBE.logo}
              style={SERVICES_LIST.ADOBE.logoStyle}
            />
          }
          setCurrentValue={setProject}
          title={t('service-integration:adobe.project-informations.title')}
        />
      );
    }
    if (step === 3) {
      return (
        <SelectService
          key="select-dimension"
          currentValue={dimension}
          errorMessage={
            <Trans i18nKey={'service-integration:adobe.dimension-informations.error-message'} />
          }
          handleGetFormatedData={handleGetDimensionsFormatedData}
          logo={
            <img
              alt={t('service-integration:adobe.alt-logo')}
              src={SERVICES_LIST.ADOBE.logo}
              style={SERVICES_LIST.ADOBE.logoStyle}
            />
          }
          setCurrentValue={setDimension}
          title={t('service-integration:adobe.dimension-informations.title')}
        />
      );
    }
    return null;
  }

  return (
    <Stepper
      handleCancel={handleGoToServiceIntegration}
      handleNext={step !== 3 ? handleNext : handleValidate}
      handlePrevious={handlePrevious}
      header={getStep(step)}
      isNextDisabled={isNextDisabled()}
      nextLabel={
        step !== 3
          ? t('service-integration:button-label.next')
          : t('service-integration:button-label.activate')
      }
      previousDisabled={step === 0}
    >
      {renderStep()}
    </Stepper>
  );
}

export default AdobeServiceIntegration;
