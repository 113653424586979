import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import { fetchOrganizationWorkspaces } from '@/apis/semji/api';
import LabelledFavIcon from '@/components/FavIcon/LabelledFavIcon';
import CountryLanguage from '@/components/Flag/CountryLanguage';
import CaretIcon from '@/components/icons/CaretIcon';
import { Divider, MyNavLink, SignOutItem } from '@/components/Profile/ProfileBox';
import { WorkspaceMenuItem } from '@/components/Profile/WorkspacesList';
import Selector, { SelectorBox } from '@/components/Selector';
import { TitleFive } from '@/components/Text/Title';
import { logoutUser } from '@/store/actions/user';

const CaretIconWrapper = styled.div`
  margin-left: 10px;
  font-size: 12px;
  color: ${({ theme }) => theme.textCss.colors.anotherGrey};
`;
const OrganizationWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 20px;
  height: 50px;
  width: 250px;
`;
const SwitchWorkspaceWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 40px;
  cursor: pointer;
`;
const StyledSignOutItem = styled(SignOutItem)`
  && {
    padding: 1.5rem 1.2rem;
    margin: 0;
  }
`;
const StyledSelectorBox = styled(SelectorBox)`
  && {
    max-height: 500px;
  }
`;

const WorkspaceItem = ({ selected, url, workspace }) => (
  <WorkspaceMenuItem selected={selected}>
    <MyNavLink to={url}>
      <CountryLanguage
        countryCode={workspace.countryCode}
        isObvious={workspace.officialLanguages.length === 1 ? true : false}
        languageCode={workspace.languageCode}
      />
      <LabelledFavIcon
        disableClick
        label={workspace.name}
        url={workspace.websiteUrl}
        withoutFavIcon
      />
    </MyNavLink>
  </WorkspaceMenuItem>
);

const SwitchWorkspace = ({ openMenu, hasMultipleWorkspace }) => {
  const { t } = useTranslation();

  return (
    <SwitchWorkspaceWrapper onClick={openMenu}>
      {hasMultipleWorkspace && (
        <TitleFive noMargin weight="400">
          {t('select:select-workspace.switch-workspace')}
        </TitleFive>
      )}
      <CaretIconWrapper>
        <CaretIcon />
      </CaretIconWrapper>
    </SwitchWorkspaceWrapper>
  );
};

const SwitchWorkspaceRender = ({ organizations, currentWorkspaceId, hasMultipleWorkspace }) => {
  const dispatch = useDispatch();

  function logout() {
    dispatch(logoutUser());
  }

  return (
    <>
      {hasMultipleWorkspace &&
        organizations.map((organization) => (
          <>
            <OrganizationWrapper>
              <TitleFive color="dark080" noMargin weight="400">
                {organization.name}
              </TitleFive>
            </OrganizationWrapper>
            <Divider />
            {organization.workspaces.map((workspace) => (
              <>
                <WorkspaceItem
                  selected={currentWorkspaceId === workspace?.id}
                  url={`/o/${organization.id}/w/${workspace?.id}`}
                  workspace={workspace}
                />
              </>
            ))}
            <Divider />
          </>
        ))}
      <StyledSignOutItem onClick={logout} />
    </>
  );
};

async function fetchOrganizationsWorkspacesData(organizationsAccesses, workspaceAccesses) {
  return await Promise.all(
    organizationsAccesses.map(async (organizationsAccesses) => {
      let workspaces = (await fetchOrganizationWorkspaces(organizationsAccesses.organization.id))?.[
        'hydra:member'
      ]?.filter((workspace) =>
        workspaceAccesses.some((workspaceAccesse) => workspaceAccesse.workspace.id === workspace.id)
      );
      if (!workspaces || workspaces.length === 0) {
        return;
      }
      return {
        ...organizationsAccesses.organization,
        workspaces,
      };
    })
  );
}

function SelectWorkspace() {
  const { pathname } = useLocation();
  const { workspaceId } = useParams();
  const [organizations, setOrganizations] = useState([]);
  const organizationAccesses = useSelector((state) => state.user.organizationAccesses);
  const workspaceAccesses = useSelector((state) => state.user.workspaceAccesses);
  const hasMultipleWorkspace =
    workspaceAccesses?.length > 1 ||
    (workspaceAccesses?.length === 1 && pathname === '/o/:organizationId/create-workspace');

  useEffect(() => {
    (async () => {
      setOrganizations(
        (await fetchOrganizationsWorkspacesData(organizationAccesses, workspaceAccesses)).filter(
          (organization) => organization !== undefined
        )
      );
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Selector
      button={({ openMenu }) => (
        <SwitchWorkspace hasMultipleWorkspace={hasMultipleWorkspace} openMenu={openMenu} />
      )}
    >
      {(selectorProps) => (
        <StyledSelectorBox {...selectorProps}>
          <SwitchWorkspaceRender
            currentWorkspaceId={workspaceId}
            hasMultipleWorkspace={hasMultipleWorkspace}
            organizations={organizations}
          />
        </StyledSelectorBox>
      )}
    </Selector>
  );
}

export default SelectWorkspace;
