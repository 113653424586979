import { CHANGE_DEFAULT_WORKSPACE_ID, REMOVE_DEFAULT_WORKSPACE_ID } from '../actions/actionTypes';

export default function defaultWorkspaceId(state = null, action) {
  switch (action.type) {
    case CHANGE_DEFAULT_WORKSPACE_ID:
      return action.workspaceId || null;
    case REMOVE_DEFAULT_WORKSPACE_ID:
      return null;
    default:
      return state;
  }
}
