import './DiffContent.scss';
import './DiffTable.scss';

import { createPatch, diffLines } from 'diff';
import * as Diff2Html from 'diff2html';
import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ColoredDot, ColoredDotSize } from 'semji-core/components/ColoredDot';
import { DialogModal, ENUM_DIALOG_MODAL_SIZE } from 'semji-core/components/DialogModal';
import {
  ENUM_TOGGLE_BUTTON_TYPE,
  ENUM_TOGGLE_BUTTON_VARIANT,
  ToggleButton,
} from 'semji-core/components/ToggleButton';
import { Tooltip } from 'semji-core/components/Tooltip';
import { DownIcon } from 'semji-core/icons/DownIcon';
import { InfoIcon } from 'semji-core/icons/InfoIcon';
import { countCharacters, countWords } from 'semji-core/utils/string';

import { LOCATION } from '@/apis/semji/constants';
import useGetContentByVersion from '@/apis/semji/contents/useGetContentByVersion';
import useGetContentsByPage from '@/apis/semji/contents/useGetContentsByPage';
import useGetContentVersions from '@/apis/semji/contents/useGetContentVersions';
import useGetCurrentWorkspaceContentsStatuses from '@/apis/semji/contents/useGetCurrentWorkspaceContentsStatuses';
import useGetWorkspaceUsers from '@/apis/semji/users/useGetWorkspaceUsers';
import {
  DiffEmptyState,
  DiffEmptyStateFooter,
} from '@/components/Editor/DiffContent/DiffEmptyState/DiffEmptyState';
import {
  beautifyHtml,
  formatTags,
  getInnerText,
  handleDisplayMode,
} from '@/components/Editor/DiffContent/DiffEmptyState/Utils';
import { HistoryVersionModal } from '@/components/modal/HistoryVersionModal/HistoryVersionModal';
import ContentScoreCss from '@/components/Table/Cell/ContentScoreCss';
import { Bold } from '@/containers/Content/EditorComponents/Nodes';
import { buildVersions } from '@/containers/ContentVersion/utils/helpers';
import useUrlQuery from '@/hooks/useUrlQuery';
import { ENUM_CONTENT_TYPE } from '@/types/contents';

const diffConfiguration = {
  drawFileList: false,
  fileContentToggle: false,
  fileListStartVisible: false,
  fileListToggle: false,
  outputFormat: 'side-by-side',
  renderNothingWhenEmpty: false,
  stickyFileHeaders: false,
  synchronisedScroll: true,
} as const;

const HTML_VALUE = 'Html';
const TEXT_VALUE = 'Text';
const TO_VERSION_TYPE = 'to';
const FROM_VERSION_TYPE = 'from';
const LEFT_VERSION = 'V1';
const RIGHT_VERSION = 'V2';
const defaultColor = '#d84854';

export function DiffContent() {
  const [diff, setDiff] = useState('');
  const [displayMode, setDisplayMode] = useState(HTML_VALUE);
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const [versionType, setVersionType] = useState(TO_VERSION_TYPE);
  const [diffLinesCount, setDiffLinesCount] = useState(0);

  const [selectedItem, setSelectedItem] = useState(null);
  const [status, setStatus] = useState(ENUM_CONTENT_TYPE.DRAFT);

  const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const query = useUrlQuery();
  const from = query?.get('from');
  const to = query?.get('to');

  const { t } = useTranslation();
  const { pageId, contentId, organizationId, workspaceId } = useParams();

  const { data: contents = [], isLoading: isContentsLoading } = useGetContentsByPage({
    pageId: String(pageId),
  });
  const { data: users = [], isLoading: isUsersLoading } = useGetWorkspaceUsers({});
  const { data: contentsStatus = [], isLoading: isContentsStatusLoading } =
    useGetCurrentWorkspaceContentsStatuses({
      location: LOCATION.EDITOR,
      refetchOnWindowFocus: 'always',
    });

  const currentDraft = contents.find((content) => content.type === ENUM_CONTENT_TYPE.DRAFT);
  const currentDraftStatusId = currentDraft?.contentStatusId || contentsStatus[0]?.id;

  const { data: contentVersions = [], isLoading: isContentVersionLoading } = useGetContentVersions({
    contentId: String(currentDraft?.id),
    enabled: !!currentDraft && !selectedItem,
  });

  const isLoading =
    isContentsLoading ||
    isUsersLoading ||
    isContentsStatusLoading ||
    (isContentVersionLoading && !!currentDraft);

  const versions = buildVersions(
    contents,
    contentVersions,
    users,
    currentDraftStatusId,
    contentsStatus
  );

  const { html, title, metaDescription } = useSelector((state: any) => state.content);

  const currentDraftVersion = versions.find((version) => version?.isCurrentDraft);

  const defaultMainVersion = versions.find((version) => version?.id === from);

  const otherVersion = versions.find((version) => version?.id === to);

  const mainVersion = useMemo(() => {
    return defaultMainVersion || currentDraftVersion;
  }, [defaultMainVersion, currentDraftVersion]);

  const {
    data: mainContentVersion,
    isLoading: isMainContentVersionLoading,
    isPlaceholderData: isMainContentVersionPlaceholder,
  } = useGetContentByVersion({
    contentId: String(contentId),
    isCurrentDraft: mainVersion?.isCurrentDraft,
    type: mainVersion?.type,
    versionId: mainVersion?.id,
  });

  const mainText = {
    content: mainContentVersion?.content ?? mainContentVersion?.html ?? html,
    metaDescription: mainContentVersion?.metaDescription ?? metaDescription,
    score: mainContentVersion?.contentScore ?? mainVersion?.score ?? currentDraftVersion?.score,
    title: mainContentVersion?.title ?? title,
    type: LEFT_VERSION,
  };

  const {
    data: otherContentVersion,
    isLoading: isOtherContentVersionLoading,
    isPlaceholderData: isOtherContentVersionPlaceholder,
  } = useGetContentByVersion({
    contentId: String(contentId),
    isCurrentDraft: otherVersion?.isCurrentDraft,
    type: otherVersion?.type,
    versionId: otherVersion?.id,
  });
  const otherText = {
    content: otherContentVersion?.content ?? otherContentVersion?.html ?? null,
    metaDescription: otherContentVersion?.metaDescription ?? null,
    score: otherContentVersion?.contentScore ?? null,
    title: otherContentVersion?.title ?? null,
    type: RIGHT_VERSION,
  };

  const loading =
    isMainContentVersionLoading ||
    isMainContentVersionPlaceholder ||
    isOtherContentVersionLoading ||
    isOtherContentVersionPlaceholder;

  function getDiffContent() {
    return createPatch('', beautifyHtml(getMainText()), beautifyHtml(getOtherText()), '', '', {
      context: 1000,
    });
  }

  const handleSelectMode = (mode: string) => () => {
    setDisplayMode(mode);
  };

  const list = [
    {
      active: displayMode === TEXT_VALUE,
      onClick: handleSelectMode(TEXT_VALUE),
      title: TEXT_VALUE,
      tooltip: t('content:editor-container.diff-content.text-mode'),
    },
    {
      active: displayMode === HTML_VALUE,
      onClick: handleSelectMode(HTML_VALUE),
      title: HTML_VALUE,
      tooltip: t('content:editor-container.diff-content.html-mode'),
    },
  ];

  function handleClose() {
    setIsHistoryModalOpen(false);
    if (!otherVersion) {
      goBack();
    }
  }

  function goBack() {
    setOpen(false);
    navigate(`/o/${organizationId}/w/${workspaceId}/p/${pageId}/content/${contentId}`);
  }

  function reset() {
    setDiff('');
    setDisplayMode(TEXT_VALUE);
    setIsHistoryModalOpen(false);
    setVersionType(TO_VERSION_TYPE);
    setSelectedItem(null);
    setStatus(ENUM_CONTENT_TYPE.DRAFT);
    goBack();
  }

  function getMainText() {
    return handleDisplayMode(
      mainText.title,
      mainText.metaDescription,
      mainText.content,
      mainText.type,
      displayMode
    );
  }

  function getOtherText() {
    return handleDisplayMode(
      otherText.title,
      otherText.metaDescription,
      otherText.content,
      otherText.type,
      displayMode
    );
  }

  function getDiffLinesCount() {
    const diffLinesArray = diffLines(getMainText(), getOtherText());
    return diffLinesArray.filter((line: any) => line.added).length;
  }

  const WarningMessage = () => (
    <Trans
      components={{
        bold: <Bold />,
      }}
      i18nKey={`content:editor-container.diff-content.warning-message`}
    />
  );

  useEffect(() => {
    if (mainText.content && otherText.content) {
      const diffContent = getDiffContent();
      const diffHtml = Diff2Html.html(diffContent, diffConfiguration);
      setDiff(diffHtml);

      const count = getDiffLinesCount();
      setDiffLinesCount(count);
    }

    if (!to || to === '') {
      setIsHistoryModalOpen(true);
    }
  }, [displayMode, open, mainText, otherText, diffLinesCount, to]);

  return (
    <>
      <DialogModal
        canClickOutsideToClose
        className="diff-content-container"
        closable
        description={''}
        id={'diff-content-modal'}
        isCancelDisabled={false}
        isOpen={open}
        size={ENUM_DIALOG_MODAL_SIZE.FullScreen}
        title={
          <div className="diff-content-container__title">
            <span className="diff-content-container__title__label">
              {t('content:editor-container.diff-content.title')}
              <Tooltip
                description={t('content:editor-container.diff-content.tooltip')}
                isFrame={false}
              >
                <InfoIcon className="diff-content-container__title__label__icon" theme="dark-60" />
              </Tooltip>
            </span>
            <ToggleButton
              actions={list}
              type={ENUM_TOGGLE_BUTTON_TYPE.Icon}
              variant={ENUM_TOGGLE_BUTTON_VARIANT.Primary}
            />
          </div>
        }
        onClose={reset}
      >
        <div className="diff-content-container__header">
          <div className="diff-content-container__header__bloc">
            <div
              className="diff-content-container__header__bloc__label"
              onClick={() => {
                setVersionType(FROM_VERSION_TYPE);
                setStatus(
                  mainVersion?.type === ENUM_CONTENT_TYPE.DRAFT || !mainVersion
                    ? ENUM_CONTENT_TYPE.DRAFT
                    : ENUM_CONTENT_TYPE.PUBLISHED
                );
                setSelectedItem(mainVersion);
                setIsHistoryModalOpen(true);
              }}
            >
              <ColoredDot
                className="diff-content-container__header__bloc__label__dot"
                color={mainVersion?.color ?? defaultColor}
                size={ColoredDotSize.XXS}
              />
              <span className="diff-content-container__header__bloc__label__text">
                {!mainVersion || mainVersion?.isCurrentDraft
                  ? t('content:version-history-panel.current-draft')
                  : mainVersion?.name || mainVersion?.displayDate}
              </span>
              <DownIcon className="diff-content-container__header__bloc__label__icon" />
            </div>

            {mainText?.score && (
              <ContentScoreCss
                className="version-history-panel__content__listing__group__item__score"
                extraLargeMode={false}
                extraSmallMode={true}
                score={Math.floor(mainText.score * 100)}
                smallMode={false}
              />
            )}
          </div>
          <div className="diff-content-container__header__bloc">
            <div
              className="diff-content-container__header__bloc__label"
              onClick={() => {
                setVersionType(TO_VERSION_TYPE);
                setStatus(
                  otherVersion?.type === ENUM_CONTENT_TYPE.DRAFT || !otherVersion
                    ? ENUM_CONTENT_TYPE.DRAFT
                    : ENUM_CONTENT_TYPE.PUBLISHED
                );
                setSelectedItem(otherVersion);
                setIsHistoryModalOpen(true);
              }}
            >
              <ColoredDot
                className="diff-content-container__header__bloc__label__dot"
                color={otherVersion?.color ?? defaultColor}
                size={ColoredDotSize.XXS}
              />
              <span className="diff-content-container__header__bloc__label__text">
                {otherVersion?.isCurrentDraft
                  ? t('content:version-history-panel.current-draft')
                  : otherContentVersion?.name || otherVersion?.displayDate}
              </span>
              <DownIcon className="diff-content-container__header__bloc__label__icon" />
            </div>
            {otherText?.score && (
              <ContentScoreCss
                extraLargeMode={false}
                extraSmallMode={true}
                score={Math.floor(otherText.score * 100)}
                smallMode={false}
              />
            )}
          </div>
        </div>
        {diffLinesCount === 1 && !loading && (
          <div className="diff-content-container__warning">
            <InfoIcon />
            <span className="diff-content-container__warning__label">
              <WarningMessage />
            </span>
          </div>
        )}
        {loading ? (
          <DiffEmptyState />
        ) : (
          <div
            className={`diff-content-container__content diff-content-container__${displayMode === TEXT_VALUE ? TEXT_VALUE.toLocaleLowerCase() : HTML_VALUE.toLocaleLowerCase()}`}
            dangerouslySetInnerHTML={{
              __html: displayMode === TEXT_VALUE ? formatTags(diff) : diff,
            }}
            id="code-diff"
          />
        )}

        <div className="diff-content-container__footer">
          <div className="diff-content-container__footer__left">
            {loading ? (
              <DiffEmptyStateFooter />
            ) : (
              <>
                <div className="diff-content-container__footer__left__words">
                  <span className="diff-content-container__footer__count">
                    {countWords(
                      displayMode === TEXT_VALUE ? getInnerText(getMainText()) : getMainText()
                    )}
                  </span>
                  {t('content:editor-container.diff-content.words')}
                </div>
                <div className="diff-content-container__footer__left__chars">
                  <span className="diff-content-container__footer__count">
                    {countCharacters(
                      displayMode === TEXT_VALUE ? getInnerText(getMainText()) : getMainText()
                    )}
                  </span>
                  {t('content:editor-container.diff-content.characters')}
                </div>
              </>
            )}
          </div>
          <div className="diff-content-container__footer__right">
            {loading ? (
              <DiffEmptyStateFooter />
            ) : (
              <>
                <div className="diff-content-container__footer__left__words">
                  <span className="diff-content-container__footer__count">
                    {countWords(
                      displayMode === TEXT_VALUE ? getInnerText(getOtherText()) : getOtherText()
                    )}
                  </span>
                  {t('content:editor-container.diff-content.words')}
                </div>
                <div className="diff-content-container__footer__left__chars">
                  <span className="diff-content-container__footer__count">
                    {countCharacters(
                      displayMode === TEXT_VALUE ? getInnerText(getOtherText()) : getOtherText()
                    )}
                  </span>
                  {t('content:editor-container.diff-content.characters')}
                </div>
              </>
            )}
          </div>
        </div>
      </DialogModal>
      <HistoryVersionModal
        handleClose={handleClose}
        isLoading={isLoading}
        isOpen={isHistoryModalOpen}
        selectedItem={selectedItem}
        setIsOpen={setIsHistoryModalOpen}
        setSelectedItem={setSelectedItem}
        setStatus={setStatus}
        status={status}
        versions={versions}
        versionType={versionType}
      />
    </>
  );
}
