import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { selectCurrentOrganizationName } from '@/store/selectors/selectCurrentOrganizationName';
import { selectCurrentWorkspaceName } from '@/store/selectors/selectCurrentWorkspaceName';
import selectOrganizationPlan from '@/store/selectors/selectOrganizationPlan';
import { trackEvent } from '@/utils/3rdParty/Mixpanel/MixPanel';

type TrackEvent = (event: string, properties?: Object) => void;

export function useMixpanelTrackEvent(): TrackEvent {
  const user = useSelector((state: any) => state.user);
  const { organizationId, workspaceId } = useParams();
  const currentOrganizationName = useSelector(selectCurrentOrganizationName);
  const currentworkspaceName = useSelector(selectCurrentWorkspaceName);
  const currentOrganizationPlan = useSelector(selectOrganizationPlan);

  function track(event: string, properties?: Object) {
    trackEvent(event, {
      distinct_id: user.publicId,
      email: user.email,
      first_name: user.firstName,
      last_name: user.lastName,
      organization_id: organizationId,
      organization_name: currentOrganizationName,
      organization_plan: currentOrganizationPlan,
      workspace_id: workspaceId,
      workspace_name: currentworkspaceName,
      ...properties,
    });
  }

  return track;
}
