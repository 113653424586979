import ThreeDotsIcon from '@/components/icons/ThreeDotsIcon';
import PrimaryColumn from '@/containers/Content/BriefComponents/Table/Columns/PrimaryColumn';
import Row from '@/containers/Content/BriefComponents/Table/Rows/Row';

function LoadingRow() {
  return (
    <Row>
      <PrimaryColumn halign="middle">
        <ThreeDotsIcon />
      </PrimaryColumn>
    </Row>
  );
}

export default LoadingRow;
