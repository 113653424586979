import handleHighlightByType from './helpers/handleHighlightByType';

function highlight({
  datum,
  dataAttribute,
  highlightClass,
  overrideClassNames = false,
  overrideData = false,
  removeInternalSelection = false,
}: {
  datum: string;
  dataAttribute: string;
  highlightClass: string;
  overrideClassNames?: boolean;
  overrideData?: boolean;
  removeInternalSelection?: boolean;
}): void {
  const selection: Selection | null = window.getSelection();
  const range: Range | null = selection && selection?.getRangeAt(0);

  if (!selection?.rangeCount || !range || !datum || !highlightClass) {
    return;
  }

  handleHighlightByType({
    dataAttribute,
    datum,
    element: range.commonAncestorContainer as HTMLElement,
    highlightClass,
    overrideClassNames,
    overrideData,
    range,
  });

  if (true === removeInternalSelection) {
    selection.removeAllRanges();
  }
}

export default highlight;
