import styled from 'styled-components/macro';

import Button from './Button';

const PrimaryButton = styled(Button)`
  color: ${({ theme }) => theme.cssColors.white};
  background: ${({ theme }) => theme.cssColors.primaryGradient};
  margin: ${({ margin }) => margin};
  opacity: ${({ disabled }) => (disabled ? '25%' : 'unset')};

  &:hover,
  &:active,
  &.active {
    background: ${({ disabled, theme }) => !disabled && theme.cssColors.primaryHoverGradient};
  }
`;

export default PrimaryButton;
