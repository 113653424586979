import {
  RESET_DEBUG_STATE,
  SET_COMPETITOR_MOCK,
  SET_DEBUG_I18N,
  SET_DEBUG_LANGUAGE,
  SET_MOCK,
  SET_MOCK_PROXY_ENV,
  SET_RECURSIVE_CALLS,
} from '@/store/actions/actionTypes';
import { checkCanDebug } from '@/utils/log/checkCanDebug';
import { checkIsDevEnv } from '@/utils/log/checkIsDevEnv';

const INITIAL_STATE = {};

const debug = (state = INITIAL_STATE, action) => {
  if (checkCanDebug()) {
    const { type, payload } = action;

    switch (type) {
      case SET_DEBUG_LANGUAGE: {
        const { value } = payload;

        return {
          ...state,
          lng: value,
        };
      }
      case SET_DEBUG_I18N: {
        const { value } = payload;

        return {
          ...state,
          i18n: value,
        };
      }
      case SET_RECURSIVE_CALLS: {
        const { value } = payload;

        return {
          ...state,
          isRecursiveCallDisabled: value,
        };
      }
      case SET_MOCK: {
        const { value } = payload;

        return {
          ...state,
          isMockEnabled: value,
        };
      }
      case SET_COMPETITOR_MOCK: {
        const { value } = payload;

        return {
          ...state,
          isCompetitorsfakeData: value,
        };
      }
      case SET_MOCK_PROXY_ENV: {
        if (checkIsDevEnv()) {
          const { value } = payload;

          return {
            ...state,
            mockProxyEnv: value,
          };
        } else {
          return state;
        }
      }
      case RESET_DEBUG_STATE:
        return INITIAL_STATE;
      default:
        return state;
    }
  } else {
    return null;
  }
};

export default debug;
