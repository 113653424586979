import RadioGroup from '@material-ui/core/RadioGroup';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import ScrollBar from '@/components/Scrollbar';
import InfoLinkTooltip from '@/components/Tooltip/InfoLinkTooltip';
import Container from '@/containers/ServiceIntegration/SearchConsole/Steps/Dialog/Container';
import DisplayUrl from '@/containers/ServiceIntegration/SearchConsole/Steps/Dialog/DisplayUrl';
import Img from '@/containers/ServiceIntegration/SearchConsole/Steps/Dialog/Img';
import LinkLikeButton from '@/containers/ServiceIntegration/SearchConsole/Steps/Dialog/LinkLikeButton';
import Row from '@/containers/ServiceIntegration/SearchConsole/Steps/Dialog/Row';
import SiteList from '@/containers/ServiceIntegration/SearchConsole/Steps/Dialog/SiteList';
import SubTitle from '@/containers/ServiceIntegration/SearchConsole/Steps/Dialog/SubTitle';
import Text from '@/containers/ServiceIntegration/SearchConsole/Steps/Dialog/Text';
import Title from '@/containers/ServiceIntegration/SearchConsole/Steps/Dialog/Title';
import TitleRow from '@/containers/ServiceIntegration/SearchConsole/Steps/Dialog/TitleRow';
import UppercaseText from '@/containers/ServiceIntegration/SearchConsole/Steps/Dialog/UppercaseText';
import { SERVICES_LIST } from '@/utils/constants';

const Wrapper = styled.div`
  display: flex;
`;

function NoMatchingProperties({
  handleChange,
  invalidProperties = [],
  property,
  workspaceWebsiteUrl,
}) {
  const { t } = useTranslation();

  const [isAllPropsDisplayed, setIsAllPropsDisplayed] = useState(false);

  function handleTogglePropertiesDisplay() {
    setIsAllPropsDisplayed(!isAllPropsDisplayed);
  }

  function onChange(e) {
    handleChange(e.target.value);
  }

  return (
    <Container>
      <div>
        <Img alt={SERVICES_LIST.SEARCH_CONSOLE.title} src={SERVICES_LIST.SEARCH_CONSOLE.logo} />
        <Title noMargin weight="medium">
          {t('service-integration:search-console.no-matching-properties-modal.title')}
          <InfoLinkTooltip
            href={t('common:links.help-connect-google-search-console')}
            title={t('service-integration:search-console.no-matching-properties-modal.tooltip')}
          />
        </Title>
        <SubTitle color="dark060" size="sm">
          {t('service-integration:search-console.no-matching-properties-modal.subtitle')}
        </SubTitle>
        <DisplayUrl noMargin weight="medium">
          {workspaceWebsiteUrl}
        </DisplayUrl>
      </div>

      {isAllPropsDisplayed && (
        <SiteList>
          <ScrollBar>
            <RadioGroup value={property?.siteUrl || ''} onChange={onChange}>
              <TitleRow>
                <UppercaseText color="dark040" weight="strong">
                  {t(
                    'service-integration:search-console.no-matching-properties-modal.invalid-properties-title'
                  )}
                </UppercaseText>
              </TitleRow>

              {invalidProperties.map(({ siteUrl }, index) => (
                <Row key={`${siteUrl}_${index}`}>
                  <Text>{siteUrl}</Text>
                </Row>
              ))}

              <Wrapper>
                <LinkLikeButton onClick={handleTogglePropertiesDisplay}>
                  {t(
                    'service-integration:search-console.no-matching-properties-modal.hide-properties'
                  )}
                </LinkLikeButton>
              </Wrapper>
            </RadioGroup>
          </ScrollBar>
        </SiteList>
      )}

      {!isAllPropsDisplayed && (
        <Wrapper>
          <LinkLikeButton onClick={handleTogglePropertiesDisplay}>
            {t('service-integration:search-console.no-matching-properties-modal.show-properties')}
          </LinkLikeButton>
        </Wrapper>
      )}

      <SubTitle color="dark060" size="sm">
        {t('service-integration:search-console.no-matching-properties-modal.footer')}
      </SubTitle>
    </Container>
  );
}

export default NoMatchingProperties;
