import algoliasearch from 'algoliasearch/lite';
import { useEffect } from 'react';

import useGetUserUniversalSearchApiKey from '@/apis/semji/users/useGetUserUniversalSearchApiKey';

export default function useSearchClient() {
  const { data, isSuccess, remove, refetch } = useGetUserUniversalSearchApiKey();
  const appId = import.meta.env.VITE_REACT_APP_ALGOLIA_APPLICATION_ID;

  useEffect(() => {
    return () => {
      remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isSuccess || !data.token) {
    return {};
  }

  return { refetch, searchClient: algoliasearch(appId, data.token) };
}
