import { nanoid } from 'nanoid';
import { useMemo } from 'react';

import SvgWrapper from './SvgWrapper';

function FolderIcon(props) {
  const svgId = useMemo(() => nanoid(), []);

  return (
    <SvgWrapper {...props} height="16" viewBox="0 0 17 16" width="17">
      <mask
        height="16"
        id={svgId}
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="17"
        x="0"
        y="0"
      >
        <path d="M8 2.5L6.042 0.5H0V15.5H17V2.5H8ZM5.622 1.5L7.58 3.5H16V5.527L1 5.501V1.5H5.622ZM1 14.5V6.501L16 6.527V14.5H1Z" />
      </mask>
      <g mask={`url(#${svgId})`}>
        <rect height="20" width="20" x="-2" y="-2.5" />
      </g>
    </SvgWrapper>
  );
}

export default FolderIcon;
