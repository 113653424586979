import React from 'react';

import { PlanRestrictionBadge } from '@/components/Badge/PlanRestrictionBadge';
import { OrganizationFeatureSet } from '@/hoc/OrganizationFeatureSet';

import { ReportTabProps } from './ReportTabs.types';

export default function ReportTab({
  onClick,
  startAdornmentIcon,
  endAdornmentIcon,
  feature,
  ...props
}: ReportTabProps) {
  return (
    <div {...props}>
      {startAdornmentIcon}
      <span className="page-report-tabs__item__label">{props.label}</span>
      {endAdornmentIcon}
      <OrganizationFeatureSet feature={feature} no={() => <PlanRestrictionBadge isCondensed />} />
    </div>
  );
}
