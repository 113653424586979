import { useTranslation } from 'react-i18next';

import { PrimaryLink } from '@/components/Navigation/Link';
import { TitleFour } from '@/components/Text/Title';
import { Section } from '@/containers/Settings/Organization/Billing/index';

function Documents() {
  const { t } = useTranslation();
  return (
    <Section>
      <TitleFour noMargin weight="strong">
        {t('settings:workspace.billing-documents.title')}
      </TitleFour>
      <br />
      <PrimaryLink isExternal noDecoration to={t('common:links.terms-and-conditions')}>
        {t('settings:workspace.billing-documents.terms-and-conditions-label')}
      </PrimaryLink>
    </Section>
  );
}

export default Documents;
