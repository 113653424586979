import { isKeyHotkey } from 'is-hotkey';

export const isEnterHotkey = isKeyHotkey('enter');
export const isBoldHotkey = isKeyHotkey('mod+b');
export const isItalicHotkey = isKeyHotkey('mod+i');
export const isUnderlinedHotkey = isKeyHotkey('mod+u');
export const isCodeHotkey = isKeyHotkey('mod+`');
export const isSaveHotkey = isKeyHotkey('mod+s');
export const isShiftEnterHotkey = isKeyHotkey('shift+enter');
export const isDownArrowHotkey = isKeyHotkey('down');
export const isUpArrowHotkey = isKeyHotkey('up');
export const isSpaceHotkey = isKeyHotkey('space');
export const isBackspaceHotkey = isKeyHotkey('backspace');
export const isTabHotkey = isKeyHotkey('tab');
