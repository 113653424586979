import './DashboardReportCard.scss';

import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import UpgradePlan, { ENUM_UPGRADE_PLAN_VARIANT } from '@/components/EmptyState/UpgradePlan';
import Loader from '@/components/Loader/Loader';
import { buildMetricFeatureSetFlag } from '@/components/Metrics/helpers';
import { TertiaryLink } from '@/components/Navigation/Link';
import { ENUM_REPORT_TAB_VARIANT, ReportTabs } from '@/containers/Report/components/ReportTabs';
import { ReportMetric, ReportMetricKey, ReportView } from '@/containers/Report/utils/types';
import { OrganizationFeatureSet } from '@/hoc/OrganizationFeatureSet';
import { useMetricsConfig } from '@/hooks/useMetricsConfig';

function DashboardReportCard({
  reportView,
  isLoading,
  children,
  showFooter = false,
  title,
  currentMetricKey,
  setCurrentMetricKey,
}: {
  reportView: ReportView;
  isLoading?: boolean;
  showFooter?: boolean;
  children: ReactNode;
  title: ReactNode;
  currentMetricKey: ReportMetricKey;
  setCurrentMetricKey: (metricKey: ReportMetricKey) => void;
}) {
  const { t } = useTranslation();
  const { organizationId, workspaceId } = useParams();
  const metrics = useMetricsConfig({ isNew: true });

  const metricsList = Object.values(metrics).filter(
    (metric) =>
      ![
        ReportMetricKey.overview,
        ReportMetricKey.publicationsCount,
        ReportMetricKey.AverageContentScore,
      ].includes(metric.key as ReportMetricKey)
  );
  metricsList.push(metrics[ReportMetricKey.AverageContentScore]);

  function selectMetricClick(currentMetricKey: ReportMetricKey) {
    setCurrentMetricKey(currentMetricKey);
  }

  return (
    <div className="page-dashboard-report-card">
      <div className="page-dashboard-report-card__header">{title}</div>
      <ReportTabs
        currentMetricKey={currentMetricKey}
        maxInlineMetrics={3}
        metricsList={metricsList as ReportMetric[]}
        selectMetricClick={selectMetricClick}
        variant={ENUM_REPORT_TAB_VARIANT.Dashboard}
      />
      {isLoading ? (
        <div className="page-dashboard-report-card__loader">
          <Loader />
        </div>
      ) : (
        <OrganizationFeatureSet
          feature={buildMetricFeatureSetFlag({
            category: metrics[currentMetricKey].category,
            metricKey: currentMetricKey,
          })}
          no={() => <UpgradePlan variant={ENUM_UPGRADE_PLAN_VARIANT.SMALL} />}
          yes={() => <>{children}</>}
        />
      )}

      <div className="page-dashboard-report-card__footer">
        {showFooter && (
          <TertiaryLink
            to={`/o/${organizationId}/w/${workspaceId}/reports/${reportView}/${currentMetricKey}`}
          >
            {t('dashboard:dashboard-pages.view-report')}
          </TertiaryLink>
        )}
      </div>
    </div>
  );
}

export default DashboardReportCard;
