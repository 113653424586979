import { CLOSE_DIALOG, OPEN_DIALOG, SET_DIALOG_STATE } from '@/store/actions/actionTypes';

export const setDialogState = (dialogName, data) => ({
  data,
  dialogName,
  type: SET_DIALOG_STATE,
});

export const openDialog = (dialogName) => ({
  dialogName,
  type: OPEN_DIALOG,
});

export const closeDialog = (dialogName) => ({
  dialogName,
  type: CLOSE_DIALOG,
});
