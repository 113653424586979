import { getFibonacciNthNumber } from './helper';

async function recursiveDelayedCallback(callback, doneCallback, nth = 1) {
  const delay = getFibonacciNthNumber(nth++) * 1000;
  let recursiveTimer;

  const stopTimer = await callback();

  if (stopTimer) {
    doneCallback();
    clearTimeout(recursiveTimer);
  } else {
    recursiveTimer = setTimeout(() => recursiveDelayedCallback(callback, doneCallback, nth), delay);
  }
}

export default recursiveDelayedCallback;
