import './OrganizationMenu.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import CaretIcon from '@/components/icons/CaretIcon';

interface OrganizationMenuProps extends React.HTMLProps<HTMLDivElement> {
  label: string;
  onClose: () => void;
  noDropDown?: boolean;
}
export default function OrganizationMenu({
  id,
  label,
  onClose,
  className,
  noDropDown,
  ...props
}: OrganizationMenuProps) {
  const { t } = useTranslation();

  return (
    <div
      className={`organization-menu ${noDropDown && 'organization-menu--no-dropdown'} ${className}`}
      {...props}
    >
      <span className="organization-menu__title">{t('layout:profile.organization')}</span>
      <div className="organization-menu__label">
        <span className="organization-menu__label__name">{label}</span>
        {!noDropDown && <CaretIcon className="organization-menu__label__icon" />}
      </div>
    </div>
  );
}
