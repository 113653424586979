import './AiWritingPopper.scss';

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Popper } from 'semji-core/components/Popper';

import { ID_POPPER_WRAPPER } from '@/containers/Content/TinyMceComponents/Editor/hooks/useAiWriting/useStreamContent/useStreamContent';
import Flex from '@/design-system/components/Flex/Flex';
import useParamSelector from '@/store/hooks/useParamSelector';
import { selectCurrentWorkspaceAccessByKey } from '@/store/selectors/selectWorkspaceAccessByKey';

function AIWritingPopper({ position, aiWriting, isMetaDescription, isTitle }) {
  const { workspaceId } = useParams();
  const accessToAiFeatures = useParamSelector(selectCurrentWorkspaceAccessByKey, {
    key: 'accessToAiFeatures',
    workspaceId: workspaceId,
  });

  useEffect(() => {
    if (!accessToAiFeatures) {
      aiWriting.aiContentPermission.setShowPermissionModal(true);
      return;
    }
    if (isTitle) aiWriting.actions.handleGenerateTitle();
    if (isMetaDescription) aiWriting.actions.handleGenerateMetaDescription();
  }, []);

  return (
    <>
      <Popper anchorEl={position} open>
        <div id="ai-writing-popper-wrapper">
          <Flex backgroundColor="white" width="700px">
            <div id={ID_POPPER_WRAPPER} />
          </Flex>
        </div>
      </Popper>
    </>
  );
}

export default AIWritingPopper;
