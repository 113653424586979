import { memo } from 'react';

import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import { noop } from '@/utils/noop';

export const OrganizationFeatureSet = memo(
  ({
    yes = noop,
    no = noop,
    feature,
  }: {
    yes: (props: { data: any; upperPlan: string }) => JSX.Element | null;
    no: (props: { data: any; upperPlan: string }) => JSX.Element | null;
    feature: string;
  }) => {
    const { data, isFeatureEnabled, upperPlan } = useOrganizationFeatureSet(feature);

    return (isFeatureEnabled ? yes : no)({
      data,
      upperPlan,
    });
  }
);
