import {
  getRoundedEvolutionMetricObject,
  getRoundedNumberMetricObject,
} from 'semji-core/utils/numbers';

export function gainDataGetter({ rowData, date1, date2, metricKey, locale }) {
  let metric1 = null;
  let metric2 = null;

  if (rowData?.monthlyPageMetrics?.[date1] !== undefined) {
    metric1 = rowData.monthlyPageMetrics[date1][metricKey] ?? null;
  }

  if (rowData?.monthlyPageMetrics?.[date2] !== undefined) {
    metric2 = rowData.monthlyPageMetrics[date2][metricKey] ?? null;
  }

  if (null === metric1 || null === metric2 || metric1 + metric2 === 0) {
    return null;
  }

  if (metric2 === 0 && metric1 !== 0) {
    return getRoundedNumberMetricObject({ number: Infinity });
  }

  const value = ((metric1 - metric2) * 100) / metric2;
  return getRoundedEvolutionMetricObject({
    locale,
    number: value,
  });
}
