import React from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import WarningMessage from '@/components/Message/Message';
import { DefaultLink } from '@/components/Navigation/Link';
import { InlineText } from '@/components/Text/Inline';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import { selectOrganizationMaxAllowedUsers } from '@/store/selectors/selectOrganizationMaxAllowedUsers';
import { selectOrganizationUsersCount } from '@/store/selectors/selectOrganizationUsersCount';

const AVAILABLE_SEATS_WARNING_THRESHOLD = 1;
const StyledWarningMessage = styled(WarningMessage)`
  && {
    margin-bottom: 1rem;
    padding: 0.25rem 2rem;
  }
`;

function WarningAvailableSeatsLeft({ organizationId }) {
  const maxAllowedUsers = useSelector(selectOrganizationMaxAllowedUsers);
  const usersCount = useSelector(selectOrganizationUsersCount);
  const { isFeatureEnabled } = useOrganizationFeatureSet('users:invitations:has-unlimited-amount');

  if (!isFeatureEnabled && maxAllowedUsers - usersCount <= AVAILABLE_SEATS_WARNING_THRESHOLD) {
    return (
      <Trans
        components={{
          defaultLink: <DefaultLink to={`/o/${organizationId}/settings/billing`} weight="medium" />,
          textMedium: <InlineText color="dark080" weight="medium" />,
          textStrong: <InlineText color="dark080" weight="strong" />,
          warning: <StyledWarningMessage type="warning" />,
        }}
        i18nKey={
          maxAllowedUsers - usersCount === AVAILABLE_SEATS_WARNING_THRESHOLD
            ? 'components:flash-message.warning-one-available-seats-left.content'
            : 'components:flash-message.warning-no-available-seats-left.content'
        }
        values={{ leftSeats: maxAllowedUsers - usersCount, maxAllowedUsers, usersCount }}
      />
    );
  }

  return null;
}

export default WarningAvailableSeatsLeft;
