import './CreditsSettings.scss';

import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';
import SortDirection from 'react-virtualized/dist/es/Table/SortDirection';
import { Button, ENUM_BUTTON_VARIANTS } from 'semji-core/components/Button';
import styled from 'styled-components/macro';

import { FlexGrowContainer } from '@/components/_common';
import SearchIcon from '@/components/icons/SearchIcon';
import InputRevealer from '@/components/Input/InputRevealer';
import Tabs, { Label, Tab, TabBodyTitle } from '@/components/Navigation/Tabs';
import ExportModal from '@/components/Settings/Credits/ExportModal';
import Table from '@/components/Table/Table';
import {
  AI_WRITING_CREDITS_TYPE,
  ANALYSIS_CREDITS_TYPE,
  CONTENT_IDEAS_SEARCH_CREDITS_TYPE,
  INCOMING_LINKS_CREDITS_TYPE,
  SEARCH_INTENTS_CREDITS_TYPE,
} from '@/utils/constants';

const REMAINING_CREDITS = {
  [AI_WRITING_CREDITS_TYPE]: 'remainingAiWritingUnits',
  [ANALYSIS_CREDITS_TYPE]: 'remainingAnalysisUnits',
  [CONTENT_IDEAS_SEARCH_CREDITS_TYPE]: 'remainingContentIdeaSearchUnits',
  [INCOMING_LINKS_CREDITS_TYPE]: 'remainingIncomingLinkUnits',
  [SEARCH_INTENTS_CREDITS_TYPE]: 'remainingSearchIntentUnits',
};

const StyledWrapper = styled(FlexGrowContainer)`
  font-size: 0.9em;
`;

function CreditSettings({
  credits,
  defaultSort,
  types,
  modeUnlimitedAnalysis,
  modeUnlimitedContentIdeas,
  modeUnlimitedAIWriting,
  title,
}) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { creditType } = useParams();
  const [searchTerm, setSearchTerm] = useState(null);
  const [openExportDialog, setOpenExportDialog] = useState(false);

  const COLUMNS = [
    {
      cellDataGetter: ({ rowData }) => ({ createdAt: rowData.createdAt, id: rowData.id }),
      cellRenderer: ({ cellData }) =>
        DateTime.fromISO(cellData.createdAt).toFormat('yyyy-MM-dd HH:mm:ss'),
      dataKey: 'createdAt',
      label: t('settings:tabs.billing'),
      sortFunction: (value1, value2, sortDirection) => {
        const a = value1 ? DateTime.fromISO(value1.createdAt) : null;
        const b = value2 ? DateTime.fromISO(value2.createdAt) : null;
        let sortResult = 0;

        if (a === b) {
          sortResult = value1.id > value2.id ? 1 : -1;
        } else {
          sortResult = (a && a < b) || (a && !b) ? -1 : 1;
        }

        return sortDirection === SortDirection.ASC ? sortResult : -1 * sortResult;
      },
      sortable: true,
      width: 175,
    },
    {
      cellDataGetter: ({ rowData }) => rowData?.data?.workspace?.name,
      dataKey: 'workspace',
      label: t('settings:table-columns.workspace'),
      width: 150,
    },
    {
      cellDataGetter: ({ rowData }) => rowData?.data?.keyword?.keyword,
      dataKey: 'keyword',
      flexGrow: 2,
      label: t('settings:table-columns.focus-keyword'),
      width: 150,
    },
    {
      cellDataGetter: ({ rowData }) =>
        rowData?.data?.consumer?.firstName || rowData?.data?.consumer?.lastName
          ? `${rowData?.data?.consumer?.firstName} ${rowData?.data?.consumer?.lastName}`
          : '-',
      dataKey: 'user',
      flexGrow: 1,
      label: t('settings:table-columns.user'),
      width: 120,
    },
  ];

  if (
    (!modeUnlimitedAnalysis && creditType === types.analysis?.slug) ||
    (!modeUnlimitedContentIdeas && creditType === types['content-ideas']?.slug) ||
    (!modeUnlimitedAIWriting && creditType === types['ai-writing']?.slug)
  ) {
    COLUMNS.push({
      cellDataGetter: ({ rowData }) => rowData.amount,
      dataKey: 'amount',
      flexGrow: 1,
      label: t('settings:table-columns.quantity'),
      width: 120,
    });
    COLUMNS.push({
      align: 'center',
      cellDataGetter: ({ rowData }) => ({
        createdAt: DateTime.fromISO(rowData.createdAt).toFormat('yyyy-MM-dd HH:mm:ss'),
        remainingAiWritingUnits: rowData.remainingAiWritingUnits,
        remainingAnalysisUnits: rowData.remainingAnalysisUnits,
        remainingContentIdeaSearchUnits: rowData.remainingContentIdeaSearchUnits,
        remainingIncomingLinkUnits: rowData.remainingIncomingLinkUnits,
        remainingSearchIntentUnits: rowData.remainingSearchIntentUnits,
        type: rowData.type,
      }),
      cellRenderer: ({ cellData }) => {
        const remainingCredits = cellData[REMAINING_CREDITS[cellData.type]] || 0;

        return (
          <span
            style={{ fontWeight: 500 }}
            title={`${remainingCredits} remaining credits on ${cellData.createdAt}`}
          >
            {remainingCredits}
          </span>
        );
      },
      dataKey: 'credits',
      label: t('settings:table-columns.credits-lefts'),
      width: 200,
    });
  }

  const parentUrl = pathname.replace(creditType, '');

  const filteredCredits = useMemo(() => {
    if (searchTerm) {
      return credits.filter((credit) => {
        const clearedSearchTerm = searchTerm.toLowerCase();
        const clearedWorkspace = ('' + credit?.data?.workspace?.name).toLowerCase();
        const clearedFocusKeyword = ('' + credit?.data?.keyword?.keyword).toLowerCase();
        const clearedUser =
          `${credit?.data?.consumer?.firstName} ${credit?.data?.consumer?.lastName}`.toLowerCase();
        const clearedUrl =
          `/o/${credit?.organization?.id}/w/${credit?.data?.workspace?.id}/p/${credit?.data?.page?.id}/online`.toLowerCase();

        return (
          clearedWorkspace.includes(clearedSearchTerm) ||
          clearedFocusKeyword.includes(clearedSearchTerm) ||
          clearedUrl.includes(clearedSearchTerm) ||
          clearedUser.includes(clearedSearchTerm)
        );
      });
    }

    return credits;
  }, [credits, searchTerm]);

  function handleSearchChange(e) {
    setSearchTerm(e.currentTarget.value.toLowerCase());
  }

  function handleExport() {
    setOpenExportDialog(true);
  }

  function handleCloseExportDialog() {
    setOpenExportDialog(false);
  }

  return (
    <div className="credit-settings-bar">
      <div className="credit-settings-bar__header">
        <TabBodyTitle noMargin>{title}</TabBodyTitle>
        <div className="credit-settings-bar__header__search">
          <InputRevealer context="Credits settings" handleChange={handleSearchChange}>
            <SearchIcon />
          </InputRevealer>
          <Button variant={ENUM_BUTTON_VARIANTS.Secondary} onClick={handleExport}>
            {t('settings:credits.export.export-label')}
          </Button>
        </div>
      </div>
      <Tabs value={creditType}>
        {Object.values(types).map((type) => {
          return (
            <Tab
              key={type.slug}
              component={Link}
              label={<Label label={type.label} />}
              smallHeight
              smallWidth
              to={`${parentUrl}${type.slug}`}
              value={type.slug}
            />
          );
        })}
      </Tabs>
      <StyledWrapper>
        <Table
          columns={COLUMNS}
          defaultSort={defaultSort}
          defaultSortDirection={SortDirection.DESC}
          disablePadding
          mode="transparent"
          rows={filteredCredits}
        />
      </StyledWrapper>
      {openExportDialog && <ExportModal handleCloseExportDialog={handleCloseExportDialog} />}
    </div>
  );
}

export default CreditSettings;
