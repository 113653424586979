import {
  CHANGE_DEFAULT_REPORT_ID,
  CHANGE_DEFAULT_WEBSITE_ID,
  REMOVE_DEFAULT_REPORT_ID,
  REMOVE_DEFAULT_WEBSITE_ID,
} from '../actions/actionTypes';

export type SearchIntelligenceState = {
  defaultWebsiteId?: string;
  defaultReportId?: string;
};

export type SearchIntelligenceAction = {
  type: string;
  payload: Partial<SearchIntelligenceState>;
};

export default function searchIntelligence(
  state: SearchIntelligenceState = {},
  action: SearchIntelligenceAction
) {
  switch (action.type) {
    case CHANGE_DEFAULT_REPORT_ID:
      return { ...state, defaultReportId: action.payload.defaultReportId };
    case REMOVE_DEFAULT_REPORT_ID:
      return { ...state, defaultReportId: null };
    case CHANGE_DEFAULT_WEBSITE_ID:
      return { ...state, defaultWebsiteId: action.payload.defaultWebsiteId };
    case REMOVE_DEFAULT_WEBSITE_ID:
      return { ...state, defaultWebsiteId: null };
    default:
      return state;
  }
}
