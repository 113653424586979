import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import { LinkLikeButton } from '@/components/Button/Button';
import { DeleteDialog } from '@/components/Dialog/DeleteDialog';
import { TabBodyContent, TabBodyTitle } from '@/components/Navigation/Tabs';
import { InlineText } from '@/components/Text/Inline';
import { ParagraphText } from '@/components/Text/Paragraph';
import { TitleFour } from '@/components/Text/Title';
import ContentStatusContainer from '@/containers/Settings/Workspace/ContentStatusSettings';
import WorkspaceNameInput from '@/containers/Settings/Workspace/WorkspaceNameInput';
import WorkspaceService from '@/services/Workspace';
import { showSuccessSnackbar } from '@/store/actions/ui';
import { fetchAuthenticatedUser } from '@/store/actions/user';

const Title = styled(TitleFour)`
  margin-top: 3rem;
  margin-bottom: 0.5rem;
`;

function GeneralSettingsContainer({ title }) {
  const { t } = useTranslation();
  const { workspaceId } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const workspaceName = useSelector((state) => state.workspaces.byId[workspaceId]?.name);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [errorDeleting, setErrorDeleting] = useState(null);

  const confirmDelete = async () => {
    setDeleting(true);
    setErrorDeleting(false);

    try {
      const _WorkspaceService = new WorkspaceService(workspaceId);
      await _WorkspaceService.disable();
      dispatch(showSuccessSnackbar('Your Workspace has been deleted successfully'));
      // after delete we fetch the authenticated user to fetch his accesses
      await dispatch(fetchAuthenticatedUser());

      setDeleting(false);
      navigate(`/`, { replace: true });
    } catch (e) {
      setDeleting(false);
      setErrorDeleting(true);
    }
  };

  return (
    <TabBodyContent>
      <TabBodyTitle noMargin>{title}</TabBodyTitle>
      <Title noMargin weight="strong">
        {t('settings:workspace.general-settings.section-workspace.title')}
      </Title>
      <WorkspaceNameInput workspaceId={workspaceId} workspaceName={workspaceName} />
      <div data-intercom-target="workspace_settings_general_tab_content_status_section">
        <Title noMargin weight="strong">
          {t('settings:workspace.general-settings.section-content-status.title')}
        </Title>
        <ParagraphText color="dark080" noPadding>
          {t('settings:workspace.general-settings.section-content-status.text')}
        </ParagraphText>
        <ContentStatusContainer />
      </div>
      <Title noMargin weight="strong">
        {t('settings:workspace.general-settings.section-delete-workspace.title')}
      </Title>
      <ParagraphText color="dark080" noPadding>
        {t('settings:workspace.general-settings.section-delete-workspace.text')}
      </ParagraphText>
      <LinkLikeButton noPadding onClick={() => setShowDeleteModal(true)}>
        {t('settings:workspace.general-settings.section-delete-workspace.button-delete')}
      </LinkLikeButton>
      <DeleteDialog
        confirmLabel={t('settings:workspace.general-settings.delete-dialog.confirm-label')}
        error={errorDeleting}
        loading={deleting}
        matchConfirmMessage={workspaceName}
        show={showDeleteModal}
        text={
          <Trans
            components={{
              inline: <InlineText color="dark080" weight="strong" />,
              paragraph: <ParagraphText color="dark080" noPadding weight="medium" />,
            }}
            i18nKey={'settings:workspace.general-settings.delete-dialog.content'}
            values={{
              workspaceName,
            }}
          />
        }
        title={t('settings:workspace.general-settings.delete-dialog.title')}
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={confirmDelete}
      />
    </TabBodyContent>
  );
}

export default GeneralSettingsContainer;
