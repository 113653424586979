import i18next from 'i18next';
import { DateTime } from 'luxon';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Tooltip } from 'semji-core/components/Tooltip';
import { Placements } from 'semji-core/hooks/usePopover';
import { getRoundedNumberMetricWithSuffix } from 'semji-core/utils/numbers';

import { ENUM_COL_ALIGN, IColDef } from '@/components/AGGrid/GridBody/GridBody.types';
import HighChartsTrend from '@/components/Chart/HighChartsTrend';
import TrendEmptyState from '@/components/EmptyState/TrendEmptyState';
import WarningTriangleIcon from '@/components/icons/WarningTriangleIcon';
import KeywordDifficultyRender from '@/components/Pages/ContentIdeas/Listing/CellRender/KeywordDifficulty';
import AddToPlanningCell from '@/components/Planning/AddToPlanningCell';
import OptimizedCell from '@/components/Planning/OptimizedCell';
import PlannedCell from '@/components/Planning/PlannedCell';
import StartOptimizingCell from '@/components/Planning/StartOptimizingCell';
import { ContentToAddToPlanning } from '@/containers/AddToPlanning/AddToPlanning.types';
import PositionCell from '@/containers/Competitors/DataExplorer/Grid/Cells/Position/Position';
import TitleCell from '@/containers/Competitors/DataExplorer/Grid/Cells/Title';
import { COMPETITOR_NORMALIZATION_SUCCESS_STATUS } from '@/containers/Competitors/utils/constants';
import {
  CompetitorCluster,
  CompetitorPersona,
  CompetitorTopPage,
  KeywordTrend,
} from '@/types/competitors';
import { formatUrlForDisplay } from '@/utils/url';

export function getColsDefDataExplorer({
  organizationId,
  workspaceId,
  clusters,
  personas,
  trafficNormalizationStatus,
  openAddToPlanningModal,
  openStartOptimizingModal,
  openRemoveFromPlanningDialog,
  openEditDraftModal,
}: {
  organizationId: string;
  workspaceId: string;
  clusters: CompetitorCluster[];
  personas: CompetitorPersona[];
  trafficNormalizationStatus: string;
  openAddToPlanningModal: (ids: ContentToAddToPlanning[]) => void;
  openStartOptimizingModal: (ids: ContentToAddToPlanning[]) => void;
  openRemoveFromPlanningDialog: (ids: string[]) => void;
  openEditDraftModal: (ids: string[]) => null | any[];
}): IColDef<CompetitorTopPage>[] {
  return [
    {
      align: ENUM_COL_ALIGN.left,
      cellClass: 'whitespace-normal',
      cellRenderer: (params) => {
        if (!params.data) return '-';
        return (
          <TitleCell
            category={params.data.urlCategory}
            theme={
              clusters.find((cluster: any) => cluster.id === params.data?.clusterId)?.name ?? '-'
            }
            title={params.data.title}
            url={params.data.url}
          />
        );
      },
      field: 'title',
      flex: 1,
      headerCheckboxSelection: true,
      headerName: i18next.t('competitors:data-explorer.metrics.top-pages'),
      minWidth: 330,
      width: 330,
    },
    {
      align: ENUM_COL_ALIGN.center,
      cellClass: 'center',
      cellRenderer: (params) => {
        if (trafficNormalizationStatus !== COMPETITOR_NORMALIZATION_SUCCESS_STATUS) {
          return (
            <Tooltip
              popoverOptions={{ placement: Placements.Bottom }}
              title={i18next.t('listing:pages-list.connect-search-console-data')}
              tooltipClassName="competitor-data-explorer__tooltip"
            >
              <NavLink
                className="text-dark-060"
                to={`/o/${organizationId}/w/${workspaceId}/settings/integrations`}
              >
                <WarningTriangleIcon />
              </NavLink>
            </Tooltip>
          );
        }
        if (!params.data?.urlEstimatedTraffic) return '-';
        return getRoundedNumberMetricWithSuffix({
          number: params.data.urlEstimatedTraffic,
        });
      },
      field: 'urlEstimatedTraffic',
      headerName: i18next.t('competitors:data-explorer.metrics.estimated-traffic'),
      sortable: true,
      width: 130,
    },
    {
      align: ENUM_COL_ALIGN.left,
      cellRenderer: (params) => {
        if (!params.data?.keyword) return '-';
        return params.data.keyword;
      },
      field: 'keyword',
      headerName: i18next.t('competitors:data-explorer.metrics.keyword'),
      width: 180,
    },
    {
      align: ENUM_COL_ALIGN.center,
      cellRenderer: (params) => {
        if (!params.data?.keywordSearchVolume) return '-';
        return getRoundedNumberMetricWithSuffix({
          number: params.data.keywordSearchVolume,
        });
      },
      field: 'keywordSearchVolume',
      headerName: i18next.t('competitors:data-explorer.metrics.volume'),
      sortable: true,
      width: 80,
    },
    {
      align: ENUM_COL_ALIGN.left,
      cellRenderer: (params) => {
        if (!params.data?.keywordTrends) return '-';
        const keywordTrends =
          params.data.keywordTrends?.map((trend: KeywordTrend) => trend.search_volume) ?? [];
        return keywordTrends.length > 0 ? (
          <HighChartsTrend series={keywordTrends} />
        ) : (
          <TrendEmptyState />
        );
      },
      field: 'keywordTrends',
      headerName: i18next.t('competitors:data-explorer.metrics.trends'),
      width: 100,
    },
    {
      align: ENUM_COL_ALIGN.right,
      cellRenderer: (params) => {
        if (!params.data?.urlPosition) return '-';
        return <PositionCell position={params.data.urlPosition} />;
      },
      field: 'urlPosition',
      headerName: i18next.t('competitors:data-explorer.metrics.position'),
      sortable: true,
      width: 80,
    },
    {
      align: ENUM_COL_ALIGN.right,
      cellRenderer: (params) => {
        if (!params.data?.keyword) return '-';
        return (
          <Tooltip
            description={formatUrlForDisplay(params.data.rankedWorkspaceUrl)}
            hide={params.data.rankedWorkspaceUrlPosition == null}
            popoverOptions={{ placement: Placements.Bottom }}
            title={params.data.title || i18next.t('common:labels.unknown-title')}
            tooltipClassName="competitor-data-explorer__tooltip"
          >
            <PositionCell position={params.data.rankedWorkspaceUrlPosition} />
          </Tooltip>
        );
      },
      field: 'rankedWorkspaceUrlPosition',
      headerName: i18next.t('competitors:data-explorer.metrics.my-position'),
      sortable: true,
      width: 100,
    },
    {
      align: ENUM_COL_ALIGN.left,
      cellRenderer: (params) => {
        return <KeywordDifficultyRender keywordDifficulty={params.data.keywordDifficulty} />;
      },
      field: 'keywordDifficulty',
      headerName: i18next.t('competitors:data-explorer.metrics.keyword-difficulty'),
      sortable: true,
      width: 155,
    },
    {
      align: ENUM_COL_ALIGN.left,
      cellRenderer: (params) => {
        return params.data?.funnel ?? '-';
      },
      field: 'funnel',
      headerName: i18next.t('competitors:data-explorer.metrics.funnel-stage'),
      width: 130,
    },
    {
      align: ENUM_COL_ALIGN.left,
      cellRenderer: (params) => {
        return params.data?.personaId
          ? personas.find((persona) => persona.id === params.data?.personaId)?.name
          : '-';
      },
      field: 'personaId',
      headerName: 'Personas',
      width: 110,
    },
    {
      align: ENUM_COL_ALIGN.left,
      cellRenderer: (params) => {
        return params?.data?.firstSeenAt
          ? DateTime.fromISO(params?.data?.firstSeenAt).toFormat('MMM yy')
          : '-';
      },
      field: 'firstSeenAt',
      headerName: i18next.t('competitors:data-explorer.metrics.first-seen'),
      sortable: true,
      width: 150,
    },
    {
      align: ENUM_COL_ALIGN.center,
      cellRenderer: (params) => {
        if (!params.data?.plannedContentIds) return '-';

        return params.data.plannedContentIds.length > 0 ? (
          <div className="flex items-center justify-center h-full gap-2">
            <PlannedCell
              onDelete={() => openRemoveFromPlanningDialog(params.data.plannedContentIds)}
            />
            <OptimizedCell
              handleEditDraftClick={() => openEditDraftModal(params.data.plannedContentIds)}
            />
          </div>
        ) : (
          <div className="flex items-center justify-center h-full gap-2">
            <AddToPlanningCell
              onAdd={() =>
                params?.data?.id &&
                openAddToPlanningModal([
                  {
                    draftTitle: '',
                    draftUrl: '',
                    id: params.data.id,
                    keyword: params.data.keyword,
                    recommendedUrl: '',
                  },
                ])
              }
            />
            <StartOptimizingCell
              onAdd={() =>
                params?.data?.id &&
                openStartOptimizingModal([
                  {
                    draftTitle: '',
                    draftUrl: '',
                    id: params.data.id,
                    keyword: params.data.keyword,
                    recommendedUrl: '',
                  },
                ])
              }
            />
          </div>
        );
      },
      headerName: i18next.t('competitors:data-explorer.metrics.planning'),
      minWidth: 100,
      width: 100,
    },
  ];
}
