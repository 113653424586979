import { nanoid } from 'nanoid';
import React, { useMemo } from 'react';

import SvgWrapper from '@/components/icons/SvgWrapper';

const LayoutList = (props) => {
  const svgId = useMemo(() => nanoid(), []);

  return (
    <SvgWrapper height="16" viewBox="0 0 15 16" width="15" {...props}>
      <mask
        height="12"
        id={`mask0_${svgId}`}
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="15"
        x="0"
        y="1"
      >
        <path
          d="M13.2353 3.72857H5.29412V2.84622H13.2353V3.72857ZM5.29412 4.61093V5.49328H15V4.61093H5.29412ZM0 1.96387H4.41176V6.37563H0V1.96387ZM0.882353 5.49328H3.52941V2.84622H0.882353V5.49328ZM13.2353 9.02269H5.29412V9.90504H13.2353V9.02269ZM5.29412 11.6698H15V10.7874H5.29412V11.6698ZM0 8.14034H4.41176V12.5521H0V8.14034ZM0.882353 11.6698H3.52941V9.02269H0.882353V11.6698Z"
          fill="black"
        />
      </mask>
      <g mask={`url(#mask0_${svgId})`}>
        <rect fill="#7C7D86" height="17.6471" width="17.6471" x="-1.76471" y="-0.683594" />
      </g>
    </SvgWrapper>
  );
};

export default LayoutList;
