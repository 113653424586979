/* eslint-disable */

import { MutableRefObject, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import usePostFacts from '@/apis/semji/aiWriting/usePostFacts/usePostFacts';
import usePostGenerateTitle from '@/apis/semji/aiWriting/usePostGenerateTitle';
import useStreamModel from '@/apis/semji/aiWriting/useStreamModel/useStreamModel';
import {
  GENERATION_INTRODUCTION_TYPE,
  GENERATION_METADESCRIPTION_TYPE,
  GENERATION_OUTLINE_TYPE,
  GENERATION_PARAGRAPH_TYPE,
  GENERATION_REFINED_BRAND_VOICE_TYPE,
  GENERATION_TITLE_TYPE,
  sleep,
} from '@/containers/Content/EditorComponents/Ai/constants';
import { ID_METADESCRIPTION_CONTAINER } from '@/containers/Content/TinyMceComponents/Editor/constants';
import {
  ENUM_COMPLETION_TOKEN_TYPE,
  useGenerateCompletionToken,
} from '@/containers/Content/TinyMceComponents/Editor/hooks/useAiWriting/useGenerateCompletionToken';
import useQueue from '@/containers/Content/TinyMceComponents/Editor/hooks/useAiWriting/useQueue/useQueue';
import { isTitleStreamMode } from '@/containers/Content/TinyMceComponents/Editor/hooks/useAiWriting/useStreamContent/helper.utils';
import { BLOCK_TYPES_TO_LOOK_FOR } from '@/containers/Content/TinyMceComponents/Editor/hooks/useAiWriting/useStreamContent/useStreamContent';
import { ContentUtils } from '@/containers/Content/TinyMceComponents/Editor/hooks/useContent/content.utils';
import {
  getAiWrapper,
  getContentUpAiWrapper,
  getContentUpCursor,
  getHtmlForAiWrapper,
} from '@/containers/Content/TinyMceComponents/Utils';
import useApiConfigurations from '@/hooks/useApiConfigurations';
import { useMixpanelTrackEvent } from '@/hooks/useMixpanelTrackEvent';
import { LogContext } from '@/providers/LogProvider';
import { showErrorSnackbar } from '@/store/actions/ui';
import { Fact } from '@/types/fact/fact.types';
import {
  ATOMIC_CONTENT_GENERATE,
  ATOMIC_CONTENT_INSERT,
} from '@/utils/3rdParty/Mixpanel/constants';
import { COMPLETION_TITLE_PROMPT_CODE } from '@/utils/configurations/constants';
import { SECTIONS } from '@/utils/log/constants';
import { ERROR_NETWORK } from '@/utils/log/errors';
import { NetworkUtils } from '@/utils/network/Network.utils';

import {
  useStreamQueueParameters,
  useStreamQueueResults,
} from '../useStreamQueue/useStreamQueue.types';

// Function that will manage queues
function useStreamQueue({
  isAtomicContent,
  isAiFactChecked,
  hasAccessToMetaDescription,
  brandVoice,
  beforeFactCheck,
  afterFactCheck,
  afterEachFactCheck,
  textareaMetaDescriptionRef,
  forEachFactCheck,
  abortRef,
  abortFactCheckRef,
  payload: { focusTopKeywordRef, editorRef, inputTitleRef },
  handleError,
  handleSuccess,
  mutations: { handleMutateTitle, handleMutateMetaDescription, handleMutateContent },
  write: { handleWriteTitle, handleWriteMetaDescription, handleWriteContent },
  beforeQueue,
  afterQueue,
  setRefetchAiUnit,
  lastGenerationRef,
}: useStreamQueueParameters): useStreamQueueResults {
  const { report, trace } = useContext(LogContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const trackMixpanelEvent = useMixpanelTrackEvent();
  const factCheckingLoadingRef = useRef<number>(0);
  const { addItemToQueue, startQueue, stopQueue, loading, resetQueue, queue } = useQueue({
    afterQueue: afterQueue,
    beforeQueue: beforeQueue,
    cleanQueue: true,
    debug: false,
  });
  // Used to prevent duplication of launch
  const launchDisabledRef: MutableRefObject<boolean> = useRef<boolean>(false);
  const titlePromptCode = useApiConfigurations(COMPLETION_TITLE_PROMPT_CODE);
  const isTitleInStreamMode = isTitleStreamMode(titlePromptCode);

  const postFactCheck = usePostFacts({
    onError: handleFactCheckError,
  });

  function handleFactCheckError(event: Error) {
    dispatch(showErrorSnackbar(t('common:error.default')));
    factCheckingLoadingRef.current = factCheckingLoadingRef.current - 1;
    report(event, {
      errorName: 'AI_OTHER_ERROR',
      queue: JSON.stringify(queue),
      section: SECTIONS.AI.label,
      token: tokenRef.current,
      type: typeRef.current,
    });
  }

  async function handleFactCheck() {
    factCheckingLoadingRef.current = factCheckingLoadingRef.current + 1;
    const streamId = lastGenerationRef.current.id as string;
    if (beforeFactCheck) await beforeFactCheck({ streamId });
    const accumulatedNodes = lastGenerationRef.current?.nodes?.reduce((acc, node) => {
      return acc + ' ' + node.innerText;
    }, '');

    abortFactCheckRef.current.abortMutation = postFactCheck.abort;

    postFactCheck
      .mutateAsync({
        assessment: JSON.stringify(accumulatedNodes.trim()),
        generatedContentId: lastGenerationRef.current.id as string,
        generatedContentToken: tokenRef.current as string,
        topKeywordId: focusTopKeywordRef.current.id,
      })
      .then(async ({ data }) => {
        const facts: Fact[] = data?.['hydra:member'];
        if (afterFactCheck) await afterFactCheck({ facts });
        for (const fact of facts) {
          if (forEachFactCheck) {
            forEachFactCheck({ fact, id: streamId });
          }
          // TO DO: is sleep mandatory ?
          // between two fact, the internal selection sometimes with the highlight is not good
          await sleep(100);
        }
        if (afterEachFactCheck) await afterEachFactCheck({ facts, streamId: streamId });
      })
      .finally(() => (factCheckingLoadingRef.current = factCheckingLoadingRef.current - 1));
  }

  function getClosestHeading(currentNode = editorRef.current.selection.getEnd()) {
    if (!currentNode) return null;
    if (BLOCK_TYPES_TO_LOOK_FOR.includes(currentNode.tagName?.toLowerCase()))
      return currentNode?.innerHTML;

    const heading = editorRef.current.dom.getPrev(currentNode, BLOCK_TYPES_TO_LOOK_FOR)?.innerHTML;

    if (heading) return heading;
    if (!currentNode.parentNode) return null;
    return getClosestHeading(currentNode.parentNode);
  }

  const tokenRef = useRef<string>();
  const typeRef = useRef<ENUM_COMPLETION_TOKEN_TYPE>();

  const generateTitle = usePostGenerateTitle({
    onError: handleStreamError,
    onMutate: handleMutateTitle,
    onSuccess: handleSuccess,
  });

  const streamInlineMetaDescription = useStreamModel({
    onError: handleStreamError,
    onMutate: handleMutateMetaDescription,
    onSuccess: handleSuccess,
  });

  const streamInlineTitle = useStreamModel({
    onError: handleStreamError,
    onMutate: handleMutateTitle,
    onSuccess: handleSuccess,
  });

  const streamInline = useStreamModel({
    onError: handleStreamError,
    // not clean, only for hot fix
    onMutate: () => {
      if (!brandVoice?.isRefined) {
        handleMutateContent?.();
      }
    },
    onSuccess: handleSuccess,
  });

  const preStream = useStreamModel({
    onError: handleStreamError,
    // not clean, only for hot fix
    onMutate: () => {
      if (brandVoice?.isRefined) {
        handleMutateContent?.();
      }
    },
    onSuccess: () => {},
  });

  const generateCompletionToken = useGenerateCompletionToken({
    onError: handleStreamError,
    onSuccess: ({ data }: any) => {
      tokenRef.current = data.token;
    },
  });

  function disableLaunch() {
    launchDisabledRef.current = true;
  }

  function enableLaunch() {
    launchDisabledRef.current = false;
  }

  async function handleStreamError(event: Error) {
    if (event?.message === ERROR_NETWORK) {
      NetworkUtils.executeWhenOnline(() => {
        report(event, {
          errorName: 'AI_NETWORK_ERROR',
          queue: JSON.stringify(queue),
          section: SECTIONS.AI.label,
          token: tokenRef.current,
          type: typeRef.current,
        });
      });
    } else {
      NetworkUtils.executeWhenOnline(() => {
        report(event, {
          errorName: 'AI_OTHER_ERROR',
          queue: JSON.stringify(queue),
          section: SECTIONS.AI.label,
          token: tokenRef.current,
          type: typeRef.current,
        });
      });
    }
    handleError(event);
    enableLaunch();
    resetQueue();
  }

  function isLaunchDisabled() {
    if (launchDisabledRef.current)
      trace({
        name: 'launch disabled',
        type: 'useStreamQueue',
      });
    return launchDisabledRef.current;
  }

  async function generationTokenQueueItem() {
    trace({
      name: 'generate token queue item',
      type: 'useStreamQueue',
    });

    await generateCompletionToken.mutateAsync({
      aiBrandVoice: !!brandVoice?.id,
      aiFact: isAiFactChecked,
      topKeywordId: focusTopKeywordRef.current.id,
      type: typeRef.current as ENUM_COMPLETION_TOKEN_TYPE,
    });
  }

  async function classicTitleQueueItem() {
    if (!tokenRef.current) {
      return;
    }
    trace({
      name: 'classic title queue item',
      type: 'useStreamQueue',
    });

    await generateTitle.mutateAsync({
      focusTopKeywordId: focusTopKeywordRef.current.id,
      generatedContentToken: tokenRef.current,
    });
  }

  async function TitleQueueItem() {
    if (!tokenRef.current) {
      return;
    }
    trace({
      name: 'title queue item',
      type: 'useStreamQueue',
    });

    await streamInlineTitle.mutateAsync({
      editorRef,
      focusTopKeywordId: focusTopKeywordRef.current.id,
      generatedContentToken: tokenRef.current,
      handleStreamWriteTitle: handleWriteTitle,
      inputTitleRef,
      isFullDraft: isAtomicContent,
      lastGenerationRef: lastGenerationRef,
      textareaMetaDescriptionRef,
    });
  }

  async function metaDescriptionQueueItem() {
    trace({
      name: 'meta desc queue item',
      type: 'useStreamQueue',
    });

    await streamInlineMetaDescription.mutateAsync({
      editorRef,
      focusTopKeywordId: focusTopKeywordRef.current.id,
      generatedContentToken: tokenRef.current,
      handleStreamWriteMetaDescription: handleWriteMetaDescription,
      inputTitleRef,
      isFullDraft: isAtomicContent,
      lastGenerationRef: lastGenerationRef,
      textareaMetaDescriptionRef,
    });
  }

  async function introductionQueueItem() {
    if (!tokenRef.current) {
      return;
    }

    trace({
      name: 'introduction queue item',
      type: 'useStreamQueue',
    });

    if (brandVoice?.isRefined) {
      trace({
        name: 'introduction queue item - refined brand voice',
        type: 'useStreamQueue',
      });

      const responseFirstPass = await preStream.mutateAsync({
        brandVoiceId: brandVoice?.id,
        editorRef,
        focusTopKeywordId: focusTopKeywordRef.current.id,
        generatedContentToken: tokenRef.current,
        handleStreamWrite: undefined,
        heading: null,
        isFullDraft: isAtomicContent,
        lastGenerationRef: lastGenerationRef,
        title: inputTitleRef.current.value,
      });

      lastGenerationRef.current.type = GENERATION_REFINED_BRAND_VOICE_TYPE;

      await streamInline.mutateAsync({
        brandVoiceId: brandVoice?.id,
        editorRef,
        focusTopKeywordId: focusTopKeywordRef.current.id,
        generatedContentId: responseFirstPass.streamId,
        generatedContentToken: tokenRef.current,
        handleStreamWrite: handleWriteContent,
        html: responseFirstPass.generatedContent,
        isFullDraft: isAtomicContent,
        lastGenerationRef: lastGenerationRef,
        title: inputTitleRef.current.value,
      });
    } else {
      trace({
        name: 'introduction queue item - not refined brand voice',
        type: 'useStreamQueue',
      });

      await streamInline.mutateAsync({
        brandVoiceId: brandVoice?.id,
        editorRef,
        focusTopKeywordId: focusTopKeywordRef.current.id,
        generatedContentToken: tokenRef.current,
        handleStreamWrite: handleWriteContent,
        heading: null,
        isFullDraft: isAtomicContent,
        lastGenerationRef: lastGenerationRef,
        title: inputTitleRef.current.value,
      });
    }
  }

  async function outlineQueueItem() {
    if (!tokenRef.current) {
      return;
    }

    trace({
      name: 'outline queue item',
      type: 'useStreamQueue',
    });

    if (brandVoice?.isRefined) {
      trace({
        name: 'outline queue item - refined brand voice',
        type: 'useStreamQueue',
      });

      const responseFirstPass = await preStream.mutateAsync({
        brandVoiceId: brandVoice?.id,
        editorRef,
        focusTopKeywordId: focusTopKeywordRef.current.id,
        generatedContentToken: tokenRef.current,
        handleStreamWrite: undefined,
        html: getAiWrapper({ editorRef })
          ? getHtmlForAiWrapper({ editorRef })
          : editorRef.current.getContent(),
        isFullDraft: isAtomicContent,
        lastGenerationRef: lastGenerationRef,
        title: inputTitleRef.current.value,
      });

      lastGenerationRef.current.type = GENERATION_REFINED_BRAND_VOICE_TYPE;

      await streamInline.mutateAsync({
        brandVoiceId: brandVoice?.id,
        editorRef,
        focusTopKeywordId: focusTopKeywordRef.current.id,
        generatedContentId: responseFirstPass.streamId,
        generatedContentToken: tokenRef.current,
        handleStreamWrite: handleWriteContent,
        html: responseFirstPass.generatedContent,
        isFullDraft: isAtomicContent,
        lastGenerationRef: lastGenerationRef,
        title: inputTitleRef.current.value,
      });
    } else {
      trace({
        name: 'outline queue item - not refined brand voice',
        type: 'useStreamQueue',
      });

      await streamInline.mutateAsync({
        brandVoiceId: brandVoice?.id,
        editorRef,
        focusTopKeywordId: focusTopKeywordRef.current.id,
        generatedContentToken: tokenRef.current,
        handleStreamWrite: handleWriteContent,
        html: getAiWrapper({ editorRef })
          ? getHtmlForAiWrapper({ editorRef })
          : editorRef.current.getContent(),
        isFullDraft: isAtomicContent,
        lastGenerationRef: lastGenerationRef,
        title: inputTitleRef.current.value,
      });
    }
  }

  async function paragraphQueueItem() {
    if (!tokenRef.current) {
      return;
    }

    trace({
      name: 'paragraph queue item',
      type: 'useStreamQueue',
    });

    if (brandVoice?.isRefined) {
      trace({
        name: 'paragraph queue item - refined brand voice',
        type: 'useStreamQueue',
      });

      const responseFirstPass = await preStream.mutateAsync({
        brandVoiceId: brandVoice?.id,
        editorRef,
        focusTopKeywordId: focusTopKeywordRef.current.id,
        generatedContentToken: tokenRef.current,
        handleStreamWrite: undefined,
        heading: getClosestHeading(),
        html: getAiWrapper({ editorRef })
          ? getHtmlForAiWrapper({ editorRef })
          : editorRef.current.getContent(),
        htmlAboveCursor: getAiWrapper({ editorRef })
          ? getContentUpAiWrapper({ editorRef })
          : getContentUpCursor({ editorRef }),
        isFullDraft: isAtomicContent,
        lastGenerationRef: lastGenerationRef,
        title: inputTitleRef.current.value,
      });

      lastGenerationRef.current.type = GENERATION_REFINED_BRAND_VOICE_TYPE;

      await streamInline.mutateAsync({
        brandVoiceId: brandVoice.id,
        editorRef,
        focusTopKeywordId: focusTopKeywordRef.current.id,
        generatedContentId: responseFirstPass.streamId,
        generatedContentToken: tokenRef.current,
        handleStreamWrite: handleWriteContent,
        html: responseFirstPass.generatedContent,
        isFullDraft: isAtomicContent,
        lastGenerationRef: lastGenerationRef,
        title: inputTitleRef.current.value,
      });
    } else {
      trace({
        name: 'paragraph queue item - not refined brand voice',
        type: 'useStreamQueue',
      });

      await streamInline.mutateAsync({
        brandVoiceId: brandVoice?.id,
        editorRef,
        focusTopKeywordId: focusTopKeywordRef.current.id,
        generatedContentToken: tokenRef.current,
        handleStreamWrite: handleWriteContent,
        heading: getClosestHeading(),
        html: getAiWrapper({ editorRef })
          ? getHtmlForAiWrapper({ editorRef })
          : editorRef.current.getContent(),
        htmlAboveCursor: getAiWrapper({ editorRef })
          ? getContentUpAiWrapper({ editorRef })
          : getContentUpCursor({ editorRef }),
        isFullDraft: isAtomicContent,
        lastGenerationRef: lastGenerationRef,
        title: inputTitleRef.current.value,
      });
    }
  }

  function addTokenToQueue() {
    resetQueue();
    trace({
      name: 'add token to queue',
      type: 'useStreamQueue',
    });

    addItemToQueue({
      afterFn: async () => {
        if (tokenRef.current === null) {
          dispatch(showErrorSnackbar(t('common:error.default')));
        } else {
          setRefetchAiUnit((prevState) => prevState + 1);
        }
        enableLaunch();
      },
      beforeFn: async () => {
        tokenRef.current = undefined;
      },
      fn: generationTokenQueueItem,
      isAwaited: true,
    });
  }

  function addTitleToQueue() {
    if (inputTitleRef.current.value === '/' || inputTitleRef.current.value === ' ') {
      trace({
        name: 'add title to queue',
        type: 'useStreamQueue',
      });

      addItemToQueue({
        afterFn: async () => {},
        beforeFn: async () => {
          if (isTitleInStreamMode) {
            abortRef.current.abortMutation = streamInlineTitle.abort;
          }

          lastGenerationRef.current.type = GENERATION_TITLE_TYPE;
        },
        fn: isTitleInStreamMode ? TitleQueueItem : classicTitleQueueItem,
        isAwaited: true,
      });
    }
  }

  function addMetaDescriptionToQueue() {
    if (
      (textareaMetaDescriptionRef.current.value === '/' ||
        textareaMetaDescriptionRef.current.value === ' ') &&
      hasAccessToMetaDescription
    ) {
      trace({
        name: 'add meta desc to queue',
        type: 'useStreamQueue',
      });

      addItemToQueue({
        afterFn: async () => {},
        beforeFn: async () => {
          abortRef.current.abortMutation = streamInlineMetaDescription.abort;
          lastGenerationRef.current.type = GENERATION_METADESCRIPTION_TYPE;
        },
        fn: metaDescriptionQueueItem,
        isAwaited: true,
      });
    }
  }

  function addIntroductionToQueue() {
    trace({
      name: 'add introduction to queue',
      type: 'useStreamQueue',
    });

    editorRef.current.mode.set('readonly');
    addItemToQueue({
      afterFn: async () => {
        if (isAiFactChecked) {
          await handleFactCheck();
        }
      },
      beforeFn: async () => {
        abortRef.current.abortMutation = streamInline.abort;
        lastGenerationRef.current.type = GENERATION_INTRODUCTION_TYPE;
      },
      fn: introductionQueueItem,
      isAwaited: true,
    });
  }

  function addOutlineToQueue() {
    trace({
      name: 'add outline to queue',
      type: 'useStreamQueue',
    });

    editorRef.current.mode.set('readonly');
    addItemToQueue({
      afterFn: async () => {},
      beforeFn: async () => {
        abortRef.current.abortMutation = streamInline.abort;
        lastGenerationRef.current.type = GENERATION_OUTLINE_TYPE;
      },
      fn: outlineQueueItem,
      isAwaited: true,
    });
  }

  function addParagraphToQueue(target?: Element) {
    trace({
      name: 'add paragraph to queue',
      type: 'useStreamQueue',
    });

    editorRef.current.mode.set('readonly');
    addItemToQueue({
      afterFn: async () => {
        if (isAiFactChecked) {
          await handleFactCheck();
        }
      },
      beforeFn: async () => {
        // Used to position paragraph during atomic content
        if (target) {
          editorRef.current.selection.select(target, true);
          editorRef.current.selection.collapse(false);
        }
        abortRef.current.abortMutation = streamInline.abort;
        lastGenerationRef.current.type = GENERATION_PARAGRAPH_TYPE;
      },
      fn: paragraphQueueItem,
      isAwaited: true,
    });
  }

  function addAllParagraphsToQueue() {
    trace({
      name: 'add all paragraphs to queue',
      type: 'useStreamQueue',
    });

    addItemToQueue({
      fn: async () => {
        // we dont keep the h2 that are followed by a h3
        const outlineNodes = lastGenerationRef.current.nodes?.filter(
          (n, i) =>
            !(n.nodeName === 'H2' && lastGenerationRef.current.nodes?.[i + 1]?.nodeName === 'H3')
        );

        for (let i = 0; outlineNodes?.[i]; i++) {
          addParagraphToQueue(outlineNodes[i]);
        }
      },
    });
  }

  function addInitAtomicContent() {
    trace({
      name: 'add init atomic content',
      type: 'useStreamQueue',
    });
    editorRef.current.setContent(ContentUtils.createEmptyHTML());
    editorRef.current.focus();
    if (inputTitleRef.current.value === '') inputTitleRef.current.value = ' ';
    if (textareaMetaDescriptionRef.current.value === '') {
      textareaMetaDescriptionRef.current.value = ' ';
    }
    document.getElementById(ID_METADESCRIPTION_CONTAINER)?.focus();
    inputTitleRef.current.disabled = true;
    textareaMetaDescriptionRef.current.disabled = true;

    trackMixpanelEvent(ATOMIC_CONTENT_INSERT, { is_fact_checking: isAiFactChecked });
  }

  function launchTitle() {
    if (isLaunchDisabled()) return;
    disableLaunch();
    trace({
      name: 'launch title',
      type: 'useStreamQueue',
    });

    typeRef.current = ENUM_COMPLETION_TOKEN_TYPE.TYPE_TITLE;
    addTokenToQueue();
    addTitleToQueue();
    startQueue();
  }

  function launchMetaDescription() {
    if (isLaunchDisabled()) return;
    disableLaunch();
    trace({
      name: 'launch metadesc',
      type: 'useStreamQueue',
    });

    typeRef.current = ENUM_COMPLETION_TOKEN_TYPE.TYPE_META_DESCRIPTION;
    addTokenToQueue();
    addMetaDescriptionToQueue();
    startQueue();
  }

  function launchIntroduction() {
    if (isLaunchDisabled()) return;
    disableLaunch();
    trace({
      name: 'launch introduction',
      type: 'useStreamQueue',
    });

    typeRef.current = ENUM_COMPLETION_TOKEN_TYPE.TYPE_INTRODUCTION;
    addTokenToQueue();
    addIntroductionToQueue();
    startQueue();
  }

  function launchOutline() {
    if (isLaunchDisabled()) return;
    disableLaunch();
    trace({
      name: 'launch outlines',
      type: 'useStreamQueue',
    });

    typeRef.current = ENUM_COMPLETION_TOKEN_TYPE.TYPE_OUTLINES;
    addTokenToQueue();
    addOutlineToQueue();
    startQueue();
  }

  function launchParagraph() {
    if (isLaunchDisabled()) return;
    disableLaunch();
    trace({
      name: 'launch paragraph',
      type: 'useStreamQueue',
    });

    typeRef.current = ENUM_COMPLETION_TOKEN_TYPE.TYPE_PARAGRAPH;
    addTokenToQueue();
    addParagraphToQueue();
    startQueue();
  }

  function launchAtomicContent() {
    if (isLaunchDisabled()) return;
    disableLaunch();
    trace({
      name: 'launch atomic content',
      type: 'useStreamQueue',
    });
    trackMixpanelEvent(ATOMIC_CONTENT_GENERATE, { is_fact_checking: isAiFactChecked });

    typeRef.current = ENUM_COMPLETION_TOKEN_TYPE.TYPE_ATOMIC_CONTENT;
    addTokenToQueue();
    addInitAtomicContent();
    addTitleToQueue();
    addMetaDescriptionToQueue();
    addIntroductionToQueue();
    addOutlineToQueue();
    addAllParagraphsToQueue();
    startQueue();
  }

  return {
    factCheckLoading: factCheckingLoadingRef.current > 0,
    launchAtomicContent,
    launchIntroduction,
    launchMetaDescription,
    launchOutline,
    launchParagraph,
    launchTitle,
    loading,
    stopQueue,
  };
}

export default useStreamQueue;
