import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { copyToClipboard } from 'semji-core/utils/clipboard';
import styled from 'styled-components/macro';

import { FlexContainer } from '@/components/_common';
import FlashMessage from '@/components/FlashMessage/FlashMessage';
import FilesIcon from '@/components/icons/FilesIcon';
import { showSuccessSnackbar } from '@/store/actions/ui';
import { COOKIE_IMPERSONATE_KEY_NAME, getCookie } from '@/utils/cookies/cookies';

const CopyWrapper = styled.span`
  display: flex;
  margin-left: 10px;
  color: ${({ theme }) => theme.cssColors.primary};
  cursor: pointer;

  svg {
    margin-right: 5px;
  }
`;

export default function ImpersonateFlashMessage() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const userImpersonate = getCookie(COOKIE_IMPERSONATE_KEY_NAME);

  function handleCopyToClipboard() {
    copyToClipboard(
      `${import.meta.env.VITE_REACT_APP_SEMJI_APP_FRONTEND_URL}/impersonate/${userImpersonate}${
        location.pathname
      }${location.search}`
    );
    dispatch(showSuccessSnackbar(t('layout:flash-message.copy-impersonate-link-message')));
  }

  if (!userImpersonate) {
    return null;
  }

  return (
    <FlashMessage>
      <FlexContainer>
        {t('layout:flash-message.you-are-impersonating', { userImpersonate })}
        <CopyWrapper onClick={handleCopyToClipboard}>
          <FilesIcon /> {t('layout:flash-message.copy-impersonate-link')}
        </CopyWrapper>
      </FlexContainer>
    </FlashMessage>
  );
}
