import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import PlusAltIcon from '@/components/icons/PlusAltIcon';
import TooltipComponent from '@/components/Tooltip/Tooltip';

const StyledPlusAltIcon = styled(PlusAltIcon)`
  && {
    font-size: 1.5rem;
  }
`;

function AddToPlanningCell({ className, onAdd }) {
  const { t } = useTranslation();

  function onClick(e) {
    e.stopPropagation();
    onAdd();
  }

  return (
    <TooltipComponent
      className={className}
      cursor="pointer"
      delay={50}
      placement="top"
      title={t(`components:add-to-planning-cell.add`)}
      onClick={onClick}
    >
      <StyledPlusAltIcon />
    </TooltipComponent>
  );
}

AddToPlanningCell.propTypes = {
  className: PropTypes.string,
  onAdd: PropTypes.func,
};

export default AddToPlanningCell;
