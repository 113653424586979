import { useQuery } from '@tanstack/react-query';

import apiClient from '@/apis/semji/apiClient';
import { SCOPE_CONTENTS } from '@/apis/semji/constants';

import { UseGetContentsByPageProps } from './useGetContentsByPage.types';

export default function useGetContentsByPage({ pageId, ...props }: UseGetContentsByPageProps) {
  const { get } = apiClient;
  return useQuery({
    queryFn: async ({ signal }) => {
      const { data } = await get(`/pages/${pageId}/contents`, {
        signal,
      });

      return data['hydra:member'] || [];
    },
    queryKey: [SCOPE_CONTENTS.GET_CONTENTS_BY_PAGE, pageId],
    ...props,
  });
}
