import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVert from '@material-ui/icons/MoreVert';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DefaultMenu } from '@/components/_common';

const ITEM_HEIGHT = 48;

function DotsMenu({
  disabledAnalysisAction,
  disabledPublishAction,
  disabledUpdateFromWebsiteAction,
  disabledRemoveAction,
  handleAnalysis,
  handlePublishAction,
  handleRemovePage,
  handleUpdateFromWebsite,
}) {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleMenuClick(action) {
    return () => {
      action?.();
      handleClose();
    };
  }

  return (
    <div>
      <IconButton
        aria-haspopup="true"
        aria-label={t('components:table.actions-cell.icon-aria-label')}
        aria-owns={!!anchorEl ? 'long-menu' : undefined}
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <DefaultMenu
        anchorEl={anchorEl}
        id="long-menu"
        open={!!anchorEl}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            maxWidth: 400,
            minWidth: 200,
          },
        }}
        onClose={handleClose}
      >
        {handleAnalysis && (
          <MenuItem disabled={disabledAnalysisAction} onClick={handleMenuClick(handleAnalysis)}>
            <ListItemText primary={t('components:table.start-analysis-cell.tooltip-title')} />
          </MenuItem>
        )}
        {handlePublishAction && (
          <MenuItem disabled={disabledPublishAction} onClick={handleMenuClick(handlePublishAction)}>
            <ListItemText primary={t('listing:planning-list.bulk-actions.mark-as-published')} />
          </MenuItem>
        )}
        {handleUpdateFromWebsite && (
          <MenuItem
            disabled={disabledUpdateFromWebsiteAction}
            onClick={handleMenuClick(handleUpdateFromWebsite)}
          >
            <ListItemText primary={t('listing:pages-list.bulk-actions.update-from-website')} />
          </MenuItem>
        )}
        {handleRemovePage && (
          <MenuItem disabled={disabledRemoveAction} onClick={handleMenuClick(handleRemovePage)}>
            <ListItemText primary={t('components:table.actions-cell.text')} />
          </MenuItem>
        )}
      </DefaultMenu>
    </div>
  );
}

export default DotsMenu;
