import React, { MutableRefObject, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import AICreditIcon from '@/components/icons/AICreditIcon';
import FactCheckingCreditIcon from '@/components/icons/FactCheckingCreditIcon';
import IntroCreditIcon from '@/components/icons/IntroCreditIcon';
import MetaDescCreditIcon from '@/components/icons/MetaDescCreditIcon';
import OutlineCreditIcon from '@/components/icons/OutlineCreditIcon';
import ParagraphCreditIcon from '@/components/icons/ParagraphCreditIcon';
import TitleCreditIcon from '@/components/icons/TitleCreditIcon';
import {
  GENERATION_METADESCRIPTION_TYPE,
  GENERATION_TITLE_TYPE,
} from '@/containers/Content/EditorComponents/Ai/constants';
import { ID_METADESCRIPTION_CONTAINER } from '@/containers/Content/TinyMceComponents/Editor/constants';
import { LastGenerationRef } from '@/containers/Content/TinyMceComponents/Editor/hooks/useAiWriting/useAiWriting.types';
import { FlagContentHookResults } from '@/containers/Content/TinyMceComponents/Editor/hooks/useAiWriting/useFlagContent/useFlagContent.types';
import { GeneratedContent } from '@/containers/Content/TinyMceComponents/Editor/hooks/useAiWriting/useStreamContent/useStreamContent.types';
import useAiWritingCredit from '@/containers/Content/TinyMceComponents/Editor/hooks/useAiWritingCredit';

export const ID_WRITING_ACTION = 'ai-writing-action';

export const ID_WRITING_FOOTER = 'ai-writing-footer';
export const ID_WRITING_LIKE = 'ai-writing-like';
export const ID_WRITING_DISLIKE = 'ai-writing-dislike';

export const SVG_WRAPPER = 'svg-wrapper';

export const SVG_FACT_CHECK_ANIMATION = 'loader-animation';

export const SELECTOR_AI_WRAPPER_HEADER = 'ai-editor-header';

export const SELECTOR_AI_WRAPPER_FACT_CHECK_LOADER = 'ai-editor-fact-check-loader';

export const ID_WRITING_LEFT_ARROW = 'ai-writing-left-arrow';
export const ID_WRITING_RIGHT_ARROW = 'ai-writing-right-arrow';

export const ID_WRITING_ERROR_ACTION = 'ai-error-regenerate';

export const AI_WRAPPER_SELECTOR = 'ai-editor-wrapper';

const SKELETON_LOADER_CLASSIC = 1;
const SKELETON_LOADER_CONTENT = 3;

const DISABLED_COLOR = '#D3D4D7';
const ENABLED_COLOR = '#7C7D86';

interface useAiWrapperParameters {
  editorRef: any;
  lastGenerationRef: MutableRefObject<LastGenerationRef>;
  inputTitleRef: MutableRefObject<HTMLInputElement>;
  textareaMetaDescriptionRef: MutableRefObject<HTMLTextAreaElement>;
  hasUnlimitedAIWriting: boolean;
  aiWritingCreditsEnabled: boolean;
  setPopperTooltip: ({ content, position }: { content: React.ReactNode; position: any }) => void;
  remainingAiWritingUnit: number;
  abortRef: any;
  setEditorAiWritingPopperOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleStreamEnd: any;
  handleCancelAiWriting: () => void;
  handleRegenerate: () => void;
  generatedContentsHook: [
    GeneratedContent[],
    React.Dispatch<React.SetStateAction<GeneratedContent[]>>,
  ];
  flagContentHook: FlagContentHookResults;
  factCheckingEnabled: boolean;
  regenerateCreditsCountsRef: MutableRefObject<number | undefined>;
}

function getGenerationType(type?: string) {
  if (!type) return '';
  return {
    GENERATION_INTRODUCTION_TYPE: 'introduction',
    GENERATION_METADESCRIPTION_TYPE: 'metadescription',
    GENERATION_OUTLINE_TYPE: 'outline',
    GENERATION_PARAGRAPH_TYPE: 'paragraph',
    GENERATION_TITLE_TYPE: 'title',
  }[type];
}

function getGenerationIcon(type?: string) {
  if (!type) return <AICreditIcon />;
  return {
    GENERATION_INTRODUCTION_TYPE: <IntroCreditIcon />,
    GENERATION_METADESCRIPTION_TYPE: <MetaDescCreditIcon />,
    GENERATION_OUTLINE_TYPE: <OutlineCreditIcon />,
    GENERATION_PARAGRAPH_TYPE: <ParagraphCreditIcon />,
    GENERATION_TITLE_TYPE: <TitleCreditIcon />,
  }[type];
}

function useAiWrapper({
  editorRef,
  inputTitleRef,
  textareaMetaDescriptionRef,
  lastGenerationRef,
  hasUnlimitedAIWriting,
  aiWritingCreditsEnabled,
  setPopperTooltip,
  remainingAiWritingUnit,
  abortRef,
  handleStreamEnd,
  setEditorAiWritingPopperOpen,
  handleCancelAiWriting,
  generatedContentsHook,
  handleRegenerate,
  flagContentHook,
  factCheckingEnabled,
  regenerateCreditsCountsRef,
}: useAiWrapperParameters) {
  const { t } = useTranslation();
  const aiWritingWrapperRef = useRef<Element | null>();
  const aiWritingContentRef = useRef<Element>();
  const aiWritingButtonAddContentRef = useRef<HTMLButtonElement>();
  const aiWritingButtonRegenerateRef = useRef<HTMLButtonElement>();
  const aiWritingCounterRef = useRef<HTMLElement>();
  const { getAiWritingCredit } = useAiWritingCredit();
  const [generatedContents, setGeneratedContents] = generatedContentsHook;

  function handleSetContentReported() {
    setGeneratedContents((prevState) => {
      const selectedIndex = prevState.findIndex((content) => !!content.selected);
      prevState[selectedIndex].reported = true;
      return [...prevState];
    });
  }

  function createAiWritingWrapper(targetNode: Element) {
    aiWritingWrapperRef.current = editorRef.current.dom.create(
      'div',
      { class: AI_WRAPPER_SELECTOR, tabindex: -1 },
      ''
    );

    createAiWritingHeader();
    createAiWritingContent();
    createAiWritingFooter();

    editorRef.current.mode.set('readonly');
    inputTitleRef.current.disabled = true;
    if (textareaMetaDescriptionRef?.current) textareaMetaDescriptionRef.current.disabled = true;
    editorRef.current.dom.insertAfter(aiWritingWrapperRef.current, targetNode);
    (document.querySelector('div.' + AI_WRAPPER_SELECTOR) as HTMLElement)?.focus();

    if (lastGenerationRef.current?.isRegenerate) {
      lastGenerationRef.current.nodes?.forEach((node: Element) => node.remove());
    }
    if (lastGenerationRef.current?.nodes) lastGenerationRef.current.nodes = [];
  }

  function createRegenerateTooltip() {
    const domRect = aiWritingButtonRegenerateRef?.current?.getBoundingClientRect();
    const aiWritingButtonPosition = {
      getBoundingClientRect: () => ({
        bottom: domRect?.bottom,
        height: domRect?.height,
        left: domRect?.left,
        right: domRect?.right,
        top: domRect?.top,
        width: domRect?.width,
      }),
    };
    if (!hasUnlimitedAIWriting && aiWritingCreditsEnabled) {
      setPopperTooltip({
        content: (
          <div className="flex flex-col items-center space-x-1 leading-5">
            <div className="flex items-center">
              {getGenerationIcon(lastGenerationRef.current?.type)}
              {factCheckingEnabled && <FactCheckingCreditIcon className="ml-1" />}
              <span className="font-medium ml-0.5">
                {t('content:ai-writing.ai-writing-credits-needed', {
                  count: isNaN(regenerateCreditsCountsRef.current)
                    ? getAiWritingCredit(lastGenerationRef?.current?.type)
                    : regenerateCreditsCountsRef.current,
                  postProcess: 'interval',
                })}
              </span>
            </div>
            <div>
              <span className="italic text-center">
                (
                {t('content:ai-writing.ai-writing-credits-remaining.key_interval', {
                  count:
                    remainingAiWritingUnit - getAiWritingCredit(lastGenerationRef?.current?.type),
                  postProcess: 'interval',
                })}
                )
              </span>
            </div>
          </div>
        ),
        position: aiWritingButtonPosition,
      });
    }
  }

  function handleCloseAiWritingWrapper() {
    editorRef.current.mode.set('design');
    inputTitleRef.current.disabled = false;
    if (textareaMetaDescriptionRef.current) textareaMetaDescriptionRef.current.disabled = false;
    setGeneratedContents([]);
    setPopperTooltip({
      content: null,
      position: null,
    });
    handleCancelAiWriting();
    // Insert line when wrapper close
    if (lastGenerationRef.current.type === GENERATION_TITLE_TYPE) {
      inputTitleRef.current.focus();
      aiWritingWrapperRef.current = null;
      return;
    }

    if (lastGenerationRef.current.type === GENERATION_METADESCRIPTION_TYPE) {
      (document.getElementById(ID_METADESCRIPTION_CONTAINER) as HTMLElement).focus();
      aiWritingWrapperRef.current = null;
      return;
    }

    const newParagraph = editorRef.current.dom.create('p', {}, '');
    const breakLine = editorRef.current.dom.create('br', {}, '');
    newParagraph.append(breakLine);
    editorRef.current.dom.insertAfter(newParagraph, aiWritingWrapperRef.current);
    editorRef.current.dom.remove(aiWritingWrapperRef.current);
    editorRef.current.focus();
    aiWritingWrapperRef.current = null;
  }

  function createAiWritingHeader() {
    const aiWritingHeader = editorRef.current.dom.create(
      'div',
      { class: SELECTOR_AI_WRAPPER_HEADER },
      ''
    );
    const title: string | undefined = {
      GENERATION_INTRODUCTION_TYPE: t('content:ai-writing:generate-introduction'),
      GENERATION_METADESCRIPTION_TYPE: t('content:ai-writing:generate-meta-description'),
      GENERATION_OUTLINE_TYPE: t('content:ai-writing:generate-plan'),
      GENERATION_PARAGRAPH_TYPE: t('content:ai-writing:generate-paragraph'),
      GENERATION_REFINED_BRAND_VOICE_TYPE: t('content:ai-writing:refining-with-brand-voice'),
      GENERATION_TITLE_TYPE: t('content:ai-writing:generate-title'),
    }[lastGenerationRef.current.type as string];

    const aiWritingTitleWrapper = editorRef.current.dom.create(
      'div',
      { class: 'ai-title-wrapper' },
      ''
    );
    const aiWritingTitle = editorRef.current.dom.create('span', {}, title);
    const aiWritingTitleSVG = editorRef.current.dom.create('div', { class: SVG_WRAPPER }, '');
    aiWritingTitleSVG.innerHTML =
      '<svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="mask0_2115_13317" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="1" width="17" height="16"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.96478 1.86244C6.84335 1.37919 6.15665 1.37919 6.03522 1.86244L4.9659 6.11791C4.92287 6.28916 4.78916 6.42287 4.61791 6.4659L0.362443 7.53522C-0.120814 7.65665 -0.120815 8.34335 0.362442 8.46478L4.61791 9.5341C4.78916 9.57713 4.92287 9.71084 4.9659 9.88209L6.03522 14.1376C6.15665 14.6208 6.84335 14.6208 6.96478 14.1376L8.0341 9.88209C8.07713 9.71084 8.21084 9.57713 8.38209 9.5341L12.6376 8.46478C13.1208 8.34335 13.1208 7.65665 12.6376 7.53522L8.38209 6.4659C8.21084 6.42287 8.07713 6.28916 8.0341 6.11791L6.96478 1.86244ZM14.2145 10.6673C14.1585 10.4442 13.8415 10.4442 13.7855 10.6673L13.292 12.6313C13.2721 12.7104 13.2104 12.7721 13.1313 12.792L11.1673 13.2855C10.9442 13.3415 10.9442 13.6585 11.1673 13.7145L13.1313 14.208C13.2104 14.2279 13.2721 14.2896 13.292 14.3687L13.7855 16.3327C13.8415 16.5558 14.1585 16.5558 14.2145 16.3327L14.708 14.3687C14.7279 14.2896 14.7896 14.2279 14.8687 14.208L16.8327 13.7145C17.0558 13.6585 17.0558 13.3415 16.8327 13.2855L14.8687 12.792C14.7896 12.7721 14.7279 12.7104 14.708 12.6313L14.2145 10.6673Z" fill="#252736"/></mask><g mask="url(#mask0_2115_13317)"><rect x="-2" y="-0.5" width="20" height="20" fill="#FFB3BD"/></g></svg>';

    aiWritingTitleWrapper.append(aiWritingTitleSVG);
    aiWritingTitleWrapper.append(aiWritingTitle);
    const aiWritingHeaderClose = editorRef.current.dom.create(
      'div',
      { class: 'ai-editor-close' },
      ''
    );
    aiWritingHeaderClose.innerHTML =
      '<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="mask0_2115_11581" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="14" height="14"><path d="M7.58183 6.99999L13.055 12.4732L12.4728 13.0554L6.99959 7.58223L1.52642 13.0554L0.944183 12.4732L6.41736 6.99999L0.943359 1.52682L1.52559 0.94458L6.99959 6.41776L12.4728 0.94458L13.055 1.52682L7.58183 6.99999Z" fill="black"/></mask><g mask="url(#mask0_2115_11581)"><rect x="-1.64648" y="-0.823486" width="16.4706" height="16.4706" fill="#252736"/></g></svg>';

    aiWritingHeaderClose.onclick = function () {
      const currentHtml = editorRef.current.dom.select('div.ai-loader-wrapper');
      abortRef.current.isTyping = false;
      setEditorAiWritingPopperOpen(false);
      handleStreamEnd(currentHtml?.[0]?.innerHTML, lastGenerationRef.current?.type);
      handleCloseAiWritingWrapper();
    };
    aiWritingHeader.append(aiWritingTitleWrapper);
    aiWritingHeader.append(aiWritingHeaderClose);

    aiWritingWrapperRef.current?.append(aiWritingHeader);
  }

  function createAiWritingContent() {
    aiWritingContentRef.current = editorRef.current.dom.create(
      'div',
      {
        class: `ai-loader-wrapper ai-loader-wrapper--${getGenerationType(
          lastGenerationRef.current?.type
        )}`,
      },
      ''
    );
    Array(
      [GENERATION_TITLE_TYPE, GENERATION_METADESCRIPTION_TYPE].includes(
        lastGenerationRef.current?.type as string
      )
        ? SKELETON_LOADER_CLASSIC
        : SKELETON_LOADER_CONTENT
    )
      .fill(() =>
        aiWritingContentRef.current?.append(
          editorRef.current.dom.create('div', { class: `ai-loader` }, '')
        )
      )
      .forEach((elem) => elem());

    if (aiWritingContentRef.current) {
      aiWritingWrapperRef.current?.append(aiWritingContentRef.current);
    }
  }

  function createAiWritingFooter() {
    const aiWritingFooter = editorRef.current.dom.create(
      'div',
      { class: 'ai-editor-footer', id: ID_WRITING_FOOTER },
      ''
    );

    const aiWritingFooterLeft = editorRef.current.dom.create(
      'div',
      { class: 'ai-editor-footer-left' },
      ''
    );
    const aiWritingFooterRight = editorRef.current.dom.create(
      'div',
      { class: 'ai-editor-footer-right' },
      ''
    );

    const aiWritingCounterWrapper = editorRef.current.dom.create(
      'div',
      { class: 'ai-editor-counter' },
      ''
    );
    aiWritingCounterRef.current = editorRef.current.dom.create(
      'div',
      { class: 'ai-editor-counter-text' },
      '1 / 1'
    );
    const aiWritingLeftButton = editorRef.current.dom.create(
      'button',
      {
        class: 'semji-core-button p-0 mr-1.5',
        disabled: true,
        id: ID_WRITING_LEFT_ARROW,
      },
      ''
    );
    aiWritingLeftButton.onclick = function () {
      setGeneratedContents((prevState) => {
        const selectedIndex = prevState.findIndex((content) => !!content.selected);
        if (selectedIndex !== 0 && prevState?.[0]?.html) {
          prevState[selectedIndex].selected = false;
          prevState[selectedIndex - 1].selected = true;
          return [...prevState];
        }
        return prevState;
      });
    };
    aiWritingLeftButton.style.cursor = 'not-allowed';

    const aiWritingRightButton = editorRef.current.dom.create(
      'button',
      {
        class: 'semji-core-button p-0 ml-1.5',
        disabled: true,
        id: ID_WRITING_RIGHT_ARROW,
      },
      ''
    );
    aiWritingRightButton.onclick = function () {
      setGeneratedContents((prevState) => {
        const selectedIndex = prevState.findIndex((content) => !!content.selected);
        if (selectedIndex !== prevState.length - 1 && prevState?.[0]?.html) {
          prevState[selectedIndex].selected = false;
          prevState[selectedIndex + 1].selected = true;
          return [...prevState];
        }
        return prevState;
      });
    };
    aiWritingRightButton.style.cursor = 'not-allowed';

    aiWritingButtonRegenerateRef.current = editorRef.current.dom.create(
      'button',
      { class: 'semji-core-button ai-button-regenerate h-[34px] p-0 ai-editor-button' },
      ''
    );
    const regenerateSpan = editorRef.current.dom.create(
      'div',
      {},
      t('content:ai-writing:regenerate')
    );

    if (aiWritingButtonRegenerateRef.current) {
      aiWritingButtonRegenerateRef.current.onclick = function () {
        handleRegenerate();
      };

      aiWritingButtonRegenerateRef.current.onmouseenter = function () {
        createRegenerateTooltip();
      };

      aiWritingButtonRegenerateRef.current.onmouseleave = function () {
        setPopperTooltip({ content: null, position: null });
      };
    }

    const regenerateSVG = editorRef.current.dom.create('div', {}, SVG_WRAPPER);

    aiWritingButtonRegenerateRef.current?.append(regenerateSVG);
    aiWritingButtonRegenerateRef.current?.append(regenerateSpan);

    const aiWritingFeedback = editorRef.current.dom.create(
      'div',
      { class: 'ai-editor-feedback' },
      ''
    );

    const aiWritingLikeButton = editorRef.current.dom.create(
      'button',
      {
        class: 'semji-core-button px-1.5 py-1.5',
        disabled: false,
        id: ID_WRITING_LIKE,
      },
      ''
    );

    const aiWritingDislikeButton = editorRef.current.dom.create(
      'button',
      {
        class: 'semji-core-button px-1.5 py-1.5',
        disabled: true,
        id: ID_WRITING_DISLIKE,
      },
      ''
    );

    aiWritingLikeButton.onclick = function () {
      flagContentHook.goodFeedback(handleSetContentReported);
    };

    aiWritingLikeButton.onmouseenter = function () {
      if (!aiWritingLikeButton.disabled) aiWritingLikeButton.style.backgroundColor = '#F4F4F5';
      const domRect = aiWritingLikeButton?.getBoundingClientRect();
      const aiWritingLikeButtonPosition = {
        getBoundingClientRect: () => ({
          bottom: domRect?.bottom,
          height: domRect?.height,
          left: domRect?.left,
          right: domRect?.right,
          top: domRect?.top,
          width: domRect?.width,
        }),
      };
      setPopperTooltip({
        content: <span>{t('content:ai-writing:report.approve')}</span>,
        position: aiWritingLikeButtonPosition,
      });
    };

    aiWritingLikeButton.onmouseleave = function () {
      aiWritingLikeButton.style.backgroundColor = 'white';
      setPopperTooltip({ content: null, position: null });
    };

    aiWritingDislikeButton.onclick = function () {
      flagContentHook.badFeedback();
    };

    aiWritingDislikeButton.onmouseenter = function () {
      if (!aiWritingDislikeButton.disabled)
        aiWritingDislikeButton.style.backgroundColor = '#F4F4F5';
      const domRect = aiWritingDislikeButton?.getBoundingClientRect();
      const aiWritingLikeButtonPosition = {
        getBoundingClientRect: () => ({
          bottom: domRect?.bottom,
          height: domRect?.height,
          left: domRect?.left,
          right: domRect?.right,
          top: domRect?.top,
          width: domRect?.width,
        }),
      };
      setPopperTooltip({
        content: <span>{t('content:ai-writing:report.disapprove')}</span>,
        position: aiWritingLikeButtonPosition,
      });
    };

    aiWritingDislikeButton.onmouseleave = function () {
      aiWritingDislikeButton.style.backgroundColor = 'white';
      setPopperTooltip({ content: null, position: null });
    };

    const likeSVG = editorRef.current.dom.create('div', { class: SVG_WRAPPER }, '');
    const dislikeSVG = editorRef.current.dom.create('div', { class: SVG_WRAPPER }, '');

    likeSVG.innerHTML =
      '<svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
      '<g clip-path="url(#clip0_2335_16531)">\n' +
      '<mask id="mask0_2335_16531" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="12" height="12">\n' +
      '<path fill-rule="evenodd" clip-rule="evenodd" d="M8.79593 11.6504C9.41991 11.6504 9.97872 11.2641 10.1992 10.6804L11.6337 6.88203C11.6977 6.71266 11.7305 6.5331 11.7305 6.35205L11.7305 5.80368C11.7305 4.97525 11.0589 4.30368 10.2305 4.30368L7.27701 4.30368L7.80242 2.73862C8.1154 1.80635 7.42178 0.841834 6.43838 0.841834C6.01312 0.841834 5.60963 1.02995 5.33625 1.3557L2.86206 4.30368L1.77081 4.30368C0.942387 4.30368 0.270815 4.97525 0.270814 5.80368L0.270814 10.1504C0.270814 10.9788 0.942388 11.6504 1.77081 11.6504L8.79593 11.6504ZM9.26368 10.327C9.19019 10.5216 9.00392 10.6504 8.79593 10.6504L3.05846 10.6504L3.05846 5.30368L3.09518 5.30368C3.24296 5.30368 3.38317 5.23831 3.47817 5.12511L6.10222 1.99856C6.18561 1.89921 6.30867 1.84183 6.43838 1.84183C6.73832 1.84183 6.94988 2.13602 6.85442 2.42036L6.10773 4.64455C6.05652 4.79709 6.08185 4.96496 6.1758 5.0956C6.26975 5.22624 6.42082 5.30368 6.58173 5.30368L10.2305 5.30368C10.5066 5.30368 10.7305 5.52753 10.7305 5.80368L10.7305 6.35205C10.7305 6.4124 10.7195 6.47225 10.6982 6.52871L9.26368 10.327ZM2.05846 5.30368L1.77081 5.30368C1.49467 5.30368 1.27081 5.52753 1.27081 5.80368L1.27081 10.1504C1.27081 10.4265 1.49467 10.6504 1.77081 10.6504L2.05846 10.6504L2.05846 5.30368Z" fill="#252736"/>\n' +
      '</mask>\n' +
      '<g mask="url(#mask0_2335_16531)">\n' +
      '<rect x="-0.863281" y="0.290894" width="13.0719" height="13.0719" fill="currentColor"/>\n' +
      '</g>\n' +
      '</g>\n' +
      '<defs>\n' +
      '<clipPath id="clip0_2335_16531">\n' +
      '<rect width="11.1111" height="11.1111" fill="white" transform="translate(0.445312 0.944458)"/>\n' +
      '</clipPath>\n' +
      '</defs>\n' +
      '</svg>\n';

    dislikeSVG.innerHTML =
      '<svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
      '<g clip-path="url(#clip0_2335_16540)">\n' +
      '<mask id="mask0_2335_16540" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="1" width="12" height="12">\n' +
      '<path fill-rule="evenodd" clip-rule="evenodd" d="M3.20798 1.4248C2.584 1.4248 2.02519 1.81109 1.80473 2.39483L0.370182 6.19317C0.306214 6.36254 0.273438 6.54209 0.273438 6.72314L0.273438 7.27152C0.273438 8.09994 0.945009 8.77152 1.77344 8.77152H4.72689L4.20148 10.3366C3.88851 11.2688 4.58213 12.2334 5.56553 12.2334C5.99079 12.2334 6.39427 12.0452 6.66766 11.7195L9.14185 8.77152H10.2331C11.0615 8.77152 11.7331 8.09994 11.7331 7.27152V2.9248C11.7331 2.09638 11.0615 1.4248 10.2331 1.4248L3.20798 1.4248ZM2.74023 2.74815C2.81372 2.55357 2.99999 2.4248 3.20798 2.4248L8.94545 2.4248V7.77152H8.90873C8.76095 7.77152 8.62074 7.83689 8.52574 7.95008L5.90168 11.0766C5.8183 11.176 5.69524 11.2334 5.56553 11.2334C5.26559 11.2334 5.05403 10.9392 5.14949 10.6548L5.89618 8.43065C5.94739 8.2781 5.92205 8.11023 5.82811 7.9796C5.73416 7.84896 5.58308 7.77152 5.42217 7.77152L1.77344 7.77152C1.49729 7.77152 1.27344 7.54766 1.27344 7.27152V6.72314C1.27344 6.66279 1.28436 6.60294 1.30569 6.54648L2.74023 2.74815ZM9.94545 7.77152H10.2331C10.5092 7.77152 10.7331 7.54766 10.7331 7.27152V2.9248C10.7331 2.64866 10.5092 2.4248 10.2331 2.4248H9.94545V7.77152Z" fill="#252736"/>\n' +
      '</mask>\n' +
      '<g mask="url(#mask0_2335_16540)">\n' +
      '<rect x="-0.863281" y="0.290894" width="13.0719" height="13.0719" fill="currentColor"/>\n' +
      '</g>\n' +
      '</g>\n' +
      '<defs>\n' +
      '<clipPath id="clip0_2335_16540">\n' +
      '<rect width="11.1111" height="11.1111" fill="white" transform="translate(0.445312 0.944458)"/>\n' +
      '</clipPath>\n' +
      '</defs>\n' +
      '</svg>\n';

    aiWritingLikeButton.append(likeSVG);
    // aiWritingLikeButton.append(tooltip);
    aiWritingDislikeButton.append(dislikeSVG);

    aiWritingFeedback.append(aiWritingLikeButton);
    aiWritingFeedback.append(aiWritingDislikeButton);

    aiWritingButtonAddContentRef.current = editorRef.current.dom.create(
      'button',
      {
        class: 'semji-core-button semji-core-button-styled semji-core-button--gradient-primary',
        id: ID_WRITING_ACTION,
      },
      ''
    );

    const leftArrowSVG = editorRef.current.dom.create('div', { class: SVG_WRAPPER }, '');
    const rightArrowSVG = editorRef.current.dom.create('div', { class: SVG_WRAPPER }, '');

    regenerateSVG.innerHTML =
      '<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="mask0_2115_13348" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="12" height="12"><path d="M4.23529 5.64704H0V1.41175H0.705882V4.31222C1.42094 2.05834 3.55482 0.441162 6 0.441162C8.47482 0.441162 10.6736 2.10704 11.3464 4.49222L10.6666 4.68352C10.0793 2.60116 8.16 1.14634 6 1.14634C3.72282 1.14704 1.75341 2.75293 1.26494 4.94116H4.23529V5.64704ZM7.76471 6.35293V7.05881H10.7351C10.2459 9.24775 8.27929 10.8529 6 10.8529C3.83859 10.8529 1.92 9.3981 1.33341 7.31575L0.653647 7.50704C1.32565 9.89222 3.52447 11.5581 6 11.5581C8.44165 11.5581 10.5706 9.94869 11.2941 7.68846V10.5882H12V6.35293H7.76471Z" fill="black"/></mask><g mask="url(#mask0_2115_13348)"><rect x="-1.41211" y="-0.705933" width="14.1176" height="14.1176" fill="currentColor"/></g></svg>';

    rightArrowSVG.innerHTML =
      '<svg fill="none" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><mask id="mask0_2115_13379" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="7" y="5" width="7" height="11"><path d="M13.0778 10.4817L7.94451 15.6151L7.48242 15.153L12.153 10.4824L7.48242 5.81048L7.94451 5.34839L13.0778 10.4817Z" fill="black"/></mask><g mask="url(#mask0_2115_13379)"><rect x="3.13867" y="4.29089" width="13.0719" height="13.0719" fill="currentColor"/></g></svg>';

    leftArrowSVG.innerHTML = rightArrowSVG.innerHTML;
    leftArrowSVG.style = 'transform: rotate(180deg)';
    aiWritingLeftButton.append(leftArrowSVG);
    aiWritingRightButton.append(rightArrowSVG);

    aiWritingCounterWrapper.append(aiWritingLeftButton);
    aiWritingCounterWrapper.append(aiWritingCounterRef.current);
    aiWritingCounterWrapper.append(aiWritingRightButton);

    aiWritingFooterLeft.append(aiWritingButtonRegenerateRef.current);
    aiWritingFooterLeft.append(aiWritingCounterWrapper);

    aiWritingFooterRight.append(aiWritingFeedback);
    aiWritingFooterRight.append(aiWritingButtonAddContentRef.current);

    aiWritingFooter.append(aiWritingFooterLeft);
    aiWritingFooter.append(aiWritingFooterRight);

    aiWritingWrapperRef.current?.append(aiWritingFooter);
  }

  function createAiWritingError() {
    const footerElement = document.querySelector('div.ai-editor-footer');
    footerElement?.remove();
    const loaderElement = document.querySelector('div.ai-loader-wrapper');
    if (loaderElement) {
      loaderElement.innerHTML =
        '<div class="ai-editor-error"><span class="title"></span><span class="text"> <a HREF="mailto:support@semji.com"></a></span><div><button id="ai-error-regenerate" class="semji-core-button semji-core-button-styled semji-core-button--gradient-primary"></button></div></div>';
    }
    const errorElement = document.querySelector('div.ai-editor-error');
    if (errorElement) {
      errorElement.childNodes[0].textContent = t('content:ai-writing:error-title');
      errorElement.childNodes[1].childNodes[0].textContent = `${t(
        'content:ai-writing:error-text'
      )} `;
      errorElement.childNodes[1].childNodes[1].textContent = t('content:ai-writing:error-link');
      errorElement.childNodes[2].childNodes[0].textContent = t('content:ai-writing:error-retry');
    }
    const errorButton = document.getElementById(ID_WRITING_ERROR_ACTION);
    if (errorButton) {
      errorButton.onclick = function () {
        handleRegenerate();
      };
    }
    setGeneratedContents([]);
  }

  function activeFooterButtons() {
    if (aiWritingButtonRegenerateRef.current) {
      aiWritingButtonRegenerateRef.current.disabled = false;
      aiWritingButtonRegenerateRef.current.style.color = ENABLED_COLOR;
      aiWritingButtonRegenerateRef.current.style.cursor = 'pointer';
    }

    if (aiWritingButtonAddContentRef.current) {
      aiWritingButtonAddContentRef.current.classList.remove('semji-core-button--tertiary');
      aiWritingButtonAddContentRef.current.classList.add('semji-core-button--gradient-primary');
      aiWritingButtonAddContentRef.current.textContent = t('content:ai-writing:add-content');
    }
  }

  function blockFooterButtons(
    handleCancel: () => void,
    ctaTextKey: string = 'content:ai-writing:stop-generating'
  ) {
    if (aiWritingButtonRegenerateRef.current) {
      aiWritingButtonRegenerateRef.current.disabled = true;
      aiWritingButtonRegenerateRef.current.style.cursor = 'not-allowed';
      aiWritingButtonRegenerateRef.current.style.color = '#BEBFC3';
    }
    if (aiWritingButtonAddContentRef.current) {
      aiWritingButtonAddContentRef.current.textContent = '';
      aiWritingButtonAddContentRef?.current?.classList.remove(
        'semji-core-button--gradient-primary'
      );
      aiWritingButtonAddContentRef?.current?.classList.add('semji-core-button--tertiary');
      const aiWritingButtonAddContentWrapper = editorRef.current.dom.create(
        'div',
        { class: 'ai-editor-button' },
        ''
      );
      const aiWritingButtonAddContentSVG = editorRef.current.dom.create(
        'div',
        { class: SVG_WRAPPER },
        ''
      );
      const aiWritingButtonAddContentText = editorRef.current.dom.create('div', {}, t(ctaTextKey));
      aiWritingButtonAddContentSVG.innerHTML =
        '<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="mask0_2136_15782" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="3" y="3" width="11" height="11"><path d="M3 3V14H14V3H3ZM13 13H4V4H13V13Z" fill="black"/></mask><g mask="url(#mask0_2136_15782)"><rect x="-2" y="-1" width="20" height="20" fill="#7C7D86"/></g></svg>';

      aiWritingButtonAddContentWrapper.append(aiWritingButtonAddContentSVG);
      aiWritingButtonAddContentWrapper.append(aiWritingButtonAddContentText);
      aiWritingButtonAddContentRef.current.append(aiWritingButtonAddContentWrapper);

      aiWritingButtonAddContentRef.current.onclick = function () {
        handleCancel();
      };
    }
  }

  function blockFeedback() {
    const like: HTMLButtonElement | null = document.getElementById(
      ID_WRITING_LIKE
    ) as HTMLButtonElement;
    const dislike: HTMLButtonElement | null = document.getElementById(
      ID_WRITING_DISLIKE
    ) as HTMLButtonElement;
    if (like && dislike) {
      like.disabled = true;
      like.style.color = DISABLED_COLOR;
      like.style.cursor = 'not-allowed';
      dislike.disabled = true;
      dislike.style.color = DISABLED_COLOR;
      dislike.style.cursor = 'not-allowed';
    }
  }

  function allowFeedback() {
    const like: HTMLButtonElement | null = document.getElementById(
      ID_WRITING_LIKE
    ) as HTMLButtonElement;
    const dislike: HTMLButtonElement | null = document.getElementById(
      ID_WRITING_DISLIKE
    ) as HTMLButtonElement;
    if (like && dislike) {
      like.disabled = false;
      like.style.color = ENABLED_COLOR;
      like.style.cursor = 'pointer';
      dislike.disabled = false;
      dislike.style.color = ENABLED_COLOR;
      dislike.style.cursor = 'pointer';
    }
  }

  function updateArrows(selectedContentIndex: number) {
    const leftArrow = document.getElementById(ID_WRITING_LEFT_ARROW) as HTMLButtonElement;
    const rightArrow = document.getElementById(ID_WRITING_RIGHT_ARROW) as HTMLButtonElement;

    if (leftArrow && rightArrow) {
      leftArrow.style.cursor = 'pointer';
      rightArrow.style.cursor = 'pointer';

      if (selectedContentIndex === 0 && generatedContents.length > 1) {
        rightArrow.disabled = false;
        rightArrow.style.color = ENABLED_COLOR;
        leftArrow.disabled = true;
        leftArrow.style.color = DISABLED_COLOR;
      } else if (
        selectedContentIndex === generatedContents.length - 1 &&
        generatedContents.length > 1
      ) {
        rightArrow.disabled = true;
        rightArrow.style.color = DISABLED_COLOR;
        leftArrow.disabled = false;
        leftArrow.style.color = ENABLED_COLOR;
      } else if (generatedContents.length > 1) {
        rightArrow.disabled = false;
        rightArrow.style.color = ENABLED_COLOR;
        leftArrow.disabled = false;
        leftArrow.style.color = ENABLED_COLOR;
      }
    }
  }

  function updateCounter() {
    if (aiWritingCounterRef.current) {
      aiWritingCounterRef.current.textContent = `${
        generatedContents.findIndex((content) => !!content.selected) + 1
      } / ${generatedContents.length}`;
    }
  }

  function closeAiWriting() {
    if (lastGenerationRef.current.type === GENERATION_TITLE_TYPE) {
      inputTitleRef.current.focus();
      aiWritingWrapperRef.current = null;
      return;
    }

    if (lastGenerationRef.current.type === GENERATION_METADESCRIPTION_TYPE) {
      (document.getElementById(ID_METADESCRIPTION_CONTAINER) as HTMLElement).focus();
      aiWritingWrapperRef.current = null;
      return;
    }

    const newParagraph = editorRef.current.dom.create('p', {}, '');
    const breakLine = editorRef.current.dom.create('br', {}, '');
    newParagraph.append(breakLine);
    editorRef.current.dom.insertAfter(newParagraph, aiWritingWrapperRef.current);
    editorRef.current.dom.remove(aiWritingWrapperRef.current);
    editorRef.current.focus();
    aiWritingWrapperRef.current = null;
  }

  function createFactCheckLoader() {
    const existingFactCheckLoader = document.querySelector(
      `div.${SELECTOR_AI_WRAPPER_FACT_CHECK_LOADER}`
    );

    if (!existingFactCheckLoader) {
      const factCheckLoader = editorRef.current.dom.create(
        'div',
        { class: SELECTOR_AI_WRAPPER_FACT_CHECK_LOADER },
        ''
      );

      const factCheckLoaderSVG = editorRef.current.dom.create(
        'div',
        { class: `${SVG_WRAPPER} ${SVG_FACT_CHECK_ANIMATION}` },
        ''
      );
      factCheckLoaderSVG.innerHTML =
        '<svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg"> <mask id="mask0_4034_12832" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="14" height="15"> <path d="M13.1758 7.50069C13.1758 10.912 10.4106 13.6772 6.99931 13.6772C3.58805 13.6772 0.82284 10.912 0.82284 7.50069C0.82284 4.08942 3.58805 1.32422 6.99931 1.32422" stroke="black" stroke-width="0.823529" stroke-linecap="round" stroke-linejoin="round"/> </mask> <g mask="url(#mask0_4034_12832)"> <rect x="-1.64648" y="-0.324219" width="16.4706" height="16.4706" fill="#7C7D86"/> </g> </svg>';

      const factCheckLoaderText = editorRef.current.dom.create(
        'span',
        {},
        t('content:ai-writing:fact-check-loading')
      );

      factCheckLoader.append(factCheckLoaderSVG);
      factCheckLoader.append(factCheckLoaderText);

      const headerElement = document.querySelector(`div.${SELECTOR_AI_WRAPPER_HEADER}`);

      editorRef.current.dom.insertAfter(factCheckLoader, headerElement);
    }
  }

  function deleteFactCheckLoader() {
    const factCheckLoader = document.querySelector(`div.${SELECTOR_AI_WRAPPER_FACT_CHECK_LOADER}`);
    if (factCheckLoader) {
      factCheckLoader.remove();
    }
  }

  function createLoader() {
    const loaderElement = document.querySelector('div.ai-loader-wrapper');
    editorRef.current.dom.setHTML(loaderElement, null);
    Array(
      [GENERATION_TITLE_TYPE, GENERATION_METADESCRIPTION_TYPE].includes(
        lastGenerationRef.current?.type || ''
      )
        ? SKELETON_LOADER_CLASSIC
        : SKELETON_LOADER_CONTENT
    )
      .fill(() =>
        aiWritingContentRef.current?.append(
          editorRef.current.dom.create('div', { class: `ai-loader` }, '')
        )
      )
      .forEach((elem) => elem());
    const footerElement = document.querySelector('div.ai-editor-footer');
    if (!footerElement) {
      createAiWritingFooter();
    }
  }

  return {
    activeFooterButtons,
    aiWrapperShown: aiWritingWrapperRef.current,
    aiWritingButtonAddContentRef,
    aiWritingContent: aiWritingContentRef,
    aiWritingWrapperRef,
    allowFeedback,
    blockFeedback,
    blockFooterButtons,
    closeAiWriting,
    createAiWritingError,
    createAiWritingFooter,
    createAiWritingWrapper,
    createFactCheckLoader,
    createLoader,
    deleteFactCheckLoader,
    updateArrows,
    updateCounter,
  };
}

export default useAiWrapper;
