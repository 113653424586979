import { useTranslation } from 'react-i18next';

import { PlanRestrictionBadge } from '@/components/Badge/PlanRestrictionBadge';
import { Label } from '@/components/Navigation/Tabs';
import { OrganizationFeatureSet } from '@/hoc/OrganizationFeatureSet';

function TabSettings({ tab, settingScope }) {
  const { t } = useTranslation();
  const label = t(`settings:tabs.${tab.label || tab.value}`);

  return (
    <OrganizationFeatureSet
      feature={`${settingScope}:has-access-to-${tab.featureFlagKey}`}
      no={() => (
        <Label
          align="flex-start"
          endAdornmentIcon={<PlanRestrictionBadge isCondensed />}
          label={label}
          startAdornmentIcon={tab.icon}
        />
      )}
      yes={() => <Label align="flex-start" label={label} startAdornmentIcon={tab.icon} />}
    />
  );
}

export default TabSettings;
