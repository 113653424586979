import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Zoom from '@material-ui/core/Zoom';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { fetchSamlUrl } from '@/apis/semji/api';
import { authorize } from '@/apis/SsoApi';
import { Box, BoxHeader, Form, Input } from '@/components/Box/Box';
import { ContainedButton } from '@/components/Button/Button';
import WhiteLogo from '@/components/icons/LogoWhite';
import {
  DEFAULT_BACKGROUND,
  LayoutCenteredContent,
  NO_MENU_LAYOUT,
} from '@/components/Layout/Layout';
import { RelativeLoader } from '@/components/Loader/Loader';
import { DefaultLink } from '@/components/Navigation/Link';
import { TitleTwo } from '@/components/Text/Title';
import LayoutWrapper from '@/containers/Layout/Layout';
import { fetchAuthenticatedUser } from '@/store/actions/user';
import { pushAuthenticatedUserToGoogleAnalytics } from '@/utils/googleTagManager';
import { SECTIONS } from '@/utils/log/constants';
import { Log } from '@/utils/log/Log';

function LoginSso(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [state, setState] = useState({ email: '' });
  const [loginState, setLoginState] = useState({
    isOnError: null,
    isPending: false,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoginState({
      isOnError: null,
      isPending: true,
    });

    try {
      const { samlLoginUrl } = await fetchSamlUrl(
        state.email,
        window.location.origin + '/notify-sso-login'
      );
      await authorize(samlLoginUrl);
      const user = await dispatch(fetchAuthenticatedUser());
      pushAuthenticatedUserToGoogleAnalytics(user);
      setLoginState({
        isOnError: null,
        isPending: false,
      });
      navigate('/', { replace: true });
    } catch (error) {
      setLoginState({
        isOnError: true,
        isPending: false,
      });

      Log.report({
        context: 'handleSubmit',
        error,
        extra: 'Submit forget password',
        section: SECTIONS.login.key,
      });
    }
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  return (
    <LayoutWrapper menu={NO_MENU_LAYOUT} variant={DEFAULT_BACKGROUND}>
      <LayoutCenteredContent>
        <Helmet>
          <title>{t('login:login-sso.title')}</title>
        </Helmet>
        <WhiteLogo height="75" width="150" />
        <Zoom in transition={600}>
          <Box>
            <BoxHeader>
              <TitleTwo weight="medium">{t('login:login-sso.sign-in-saml-sso')}</TitleTwo>
            </BoxHeader>
            <Form onChange={handleChange} onSubmit={handleSubmit}>
              <FormControl
                aria-describedby="credentials-error"
                error={loginState.isOnError}
                fullWidth
              >
                <Input
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus
                  error={loginState.isOnError}
                  fullWidth
                  label={t('login:login-sso.email')}
                  name="email"
                  required
                  type="email"
                />
                {loginState.isOnError && (
                  <FormHelperText>
                    {t('login:login-sso.account-not-configured')} <br />
                    {t('login:login-sso.please-sign-in-with-email-password')}
                  </FormHelperText>
                )}
              </FormControl>
              <ContainedButton
                disabled={loginState.isPending}
                fullWidth
                height="big"
                type="submit"
                withMargin
              >
                {loginState.isPending ? <RelativeLoader /> : t('login:login-sso.sign-in')}
              </ContainedButton>
            </Form>
            <DefaultLink color="dark080" decoration to="/login" weight="medium">
              {t('login:login-sso.sign-in-with-email-password')}
            </DefaultLink>
          </Box>
        </Zoom>
      </LayoutCenteredContent>
    </LayoutWrapper>
  );
}

export default LoginSso;
