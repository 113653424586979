import styled from 'styled-components/macro';

import FavIcon from '@/components/FavIcon/FavIcon';
import BackRightIcon from '@/components/icons/BackRightIcon';
import BoxIcon from '@/components/icons/BoxIcon';
import CursorIcon from '@/components/icons/CursorIcon';
import DeleteAltIcon from '@/components/icons/DeleteAltIcon';
import ExternalIcon from '@/components/icons/ExternalIcon';
import HashIcon from '@/components/icons/HashIcon';
import PencilIcon from '@/components/icons/PencilIcon';
import PlusIcon from '@/components/icons/PlusIcon';
import SearchIcon from '@/components/icons/SearchIcon';
import ValidatedIcon from '@/components/icons/ValidatedIcon';
import Flex from '@/design-system/components/Flex/Flex';

export const KeywordDetailed = styled.span`
  line-height: normal;
  color: ${({ color }) => color};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  strong {
    font-weight: ${({ theme }) => theme.textCss.weights.strong};
  }
`;
export const VolumeDetailed = styled(Flex)`
  line-height: normal;
  font-size: ${({ theme }) => theme.textCss.sizes.xs};
`;
export const TextDetailed = styled.span`
  line-height: normal;
  color: ${({ theme }) => theme.cssColors.dark060};
  font-size: ${({ theme }) => theme.textCss.sizes.xs};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: ${({ maxWidth }) => maxWidth};
`;
export const TitleDetailed = styled.span`
  line-height: normal;
  font-size: 13px;
  font-weight: ${({ fontWeight }) => fontWeight};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  strong {
    font-weight: ${({ theme }) => theme.textCss.weights.strong};
  }
`;
export const StyledExternalIcon = styled(ExternalIcon)`
  && {
    display: none;
    font-size: ${({ theme }) => theme.textCss.sizes.xs};
    margin-bottom: 1px;
  }
`;
export const TitleDetailedWrapper = styled(Flex)`
  &:hover {
    color: ${({ theme }) => theme.cssColors.secondaryBlue};
    cursor: pointer;

    ${StyledExternalIcon} {
      display: inline;
    }
  }
`;
export const FavIconDetailed = styled(FavIcon)`
  && {
    img {
      padding: 0;
    }
  }
`;
export const CursorIconDetailed = styled(CursorIcon)`
  && {
    color: ${({ theme }) => theme.cssColors.dark060};
  }
`;
export const SearchIconDetailed = styled(SearchIcon)`
  && {
    color: ${({ theme }) => theme.cssColors.dark060};
  }
`;
export const Keyword = styled.span`
  line-height: 135%;
  margin-left: 24px;

  strong {
    font-weight: ${({ theme }) => theme.textCss.weights.strong};
  }
`;
export const HashIconDetailed = styled(HashIcon)`
  && {
    font-size: ${({ theme }) => theme.textCss.sizes.sm};
    color: ${({ theme }) => theme.cssColors.dark060};
  }
`;
export const StyledPlusIcon = styled(PlusIcon)`
  && {
    background-color: ${({ backgroundColor }) => backgroundColor};
    font-size: 10px;
    color: ${({ theme }) => theme.cssColors.white};
    padding: 4px;
    cursor: pointer;
    border-radius: 100%;
    height: 12px;
    width: 12px;
  }
`;
export const StyledValidatedIcon = styled(ValidatedIcon)`
  && {
    cursor: pointer;
    height: 22px;
    width: 22px;
  }
`;
export const StyledDeleteIcon = styled(DeleteAltIcon)`
  && {
    height: 22px;
    width: 22px;
    display: none;
    cursor: pointer;
  }

  &:hover {
    background-color: ${({ theme }) => theme.cssColors.dark010};
    border-radius: 50%;
  }
`;
export const StyledEditIcon = styled(PencilIcon)`
  && {
    height: 18px;
    width: 18px;
    display: none;
    cursor: pointer;
    padding: 2px;
  }
  &:hover {
    background-color: ${({ theme }) => theme.cssColors.dark010};
    border-radius: 50%;
  }
`;
export const StyledBoxIcon = styled(BoxIcon)`
  && {
    height: 18px;
    width: 18px;
    display: none;
    cursor: pointer;
    color: ${({ theme }) => theme.cssColors.dark060};
    padding: 1px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.cssColors.dark010};
    border-radius: 10%;
  }
`;
export const StyledBackRightIcon = styled(BackRightIcon)`
  && {
    display: none;
    cursor: pointer;
    color: ${({ theme }) => theme.cssColors.dark060};
  }
`;
export const FloatingWrapper = styled(Flex)`
  cursor: default;

  &:hover {
    background-color: white;
    width: ${({ isPlanned }) => isPlanned && '80px'};
    left: ${({ isPlanned }) => isPlanned && '-70px'};
    box-shadow: 0px 3px 5px 0px #2527360d;

    ${StyledBoxIcon}, ${StyledBackRightIcon}, ${StyledDeleteIcon}, ${StyledEditIcon} {
      display: inline;
    }

    ${StyledValidatedIcon} {
      display: none;
    }
  }
`;
