import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';

import { PlanRestrictionBadge } from '@/components/Badge/PlanRestrictionBadge';
import CollapsibleList from '@/design-system/components/List/CollapsibleList';
import ListBigItem from '@/design-system/components/List/ListBigItem';
import ListItem from '@/design-system/components/List/ListItem';
import { OrganizationFeatureSet } from '@/hoc/OrganizationFeatureSet';
import { showUpgradePlanModal } from '@/store/actions/billing';

const SideBar = styled.nav`
  width: ${({ width }) => width ?? '100%'};

  &&& {
    & svg {
      font-size: 14px;
    }
  }
`;

function TableFilters({ className, tableFilters, selectedFilter, width, onClick, ...props }) {
  const dispatch = useDispatch();

  const handleFilterClick = (href) => {
    onClick(href);
  };

  const openUpgradeModal = () => {
    dispatch(showUpgradePlanModal());
  };

  return (
    <SideBar className={className} width={width} {...props}>
      {tableFilters.map((tableFilter) => {
        if (tableFilter.collapsible) {
          return (
            <CollapsibleList
              key={tableFilter.attribute}
              isDefaultOpen={true}
              label={tableFilter.label}
            >
              {tableFilter.filters?.map((filter) =>
                !!filter.featureFlag ? (
                  <OrganizationFeatureSet
                    key={filter.attribute}
                    feature={filter.featureFlag}
                    no={() => (
                      <ListItem
                        color={filter.color}
                        count={filter.count}
                        icon={<PlanRestrictionBadge isCondensed />}
                        label={filter.label}
                        weight={filter.weight}
                        withLeftPadding={filter.withLeftPadding}
                        onClick={openUpgradeModal}
                      />
                    )}
                    yes={() => (
                      <ListItem
                        color={filter.color}
                        count={filter.count}
                        endAdornment={filter.endAdornment}
                        icon={filter.icon}
                        label={filter.label}
                        selected={filter.attribute === selectedFilter}
                        weight={filter.weight}
                        withLeftPadding={filter.withLeftPadding}
                        onClick={() => handleFilterClick(filter.href)}
                      />
                    )}
                  />
                ) : (
                  <ListItem
                    key={filter.attribute}
                    color={filter.color}
                    count={filter.count}
                    endAdornment={filter.endAdornment}
                    icon={filter.icon}
                    label={filter.label}
                    selected={filter.attribute === selectedFilter}
                    weight={filter.weight}
                    withLeftPadding={filter.withLeftPadding}
                    onClick={() => handleFilterClick(filter.href)}
                  />
                )
              )}
            </CollapsibleList>
          );
        }

        return (
          <Fragment key={tableFilter.attribute}>
            {tableFilter.label && (
              <ListBigItem
                selected={tableFilter.attribute === selectedFilter}
                onClick={() => handleFilterClick(tableFilter.href)}
              >
                <span>{tableFilter.label}</span> {0 === tableFilter.count ? '-' : tableFilter.count}
              </ListBigItem>
            )}
            {tableFilter.filters?.map((filter) =>
              !!filter.featureFlag ? (
                <OrganizationFeatureSet
                  key={filter.attribute}
                  feature={filter.featureFlag}
                  no={() => (
                    <ListItem
                      color={filter.color}
                      count={filter.count}
                      icon={<PlanRestrictionBadge isCondensed />}
                      label={filter.label}
                      weight={filter.weight}
                      onClick={openUpgradeModal}
                    />
                  )}
                  yes={() => (
                    <ListItem
                      color={filter.color}
                      count={filter.count}
                      endAdornment={filter.endAdornment}
                      icon={filter.icon}
                      label={filter.label}
                      selected={filter.attribute === selectedFilter}
                      weight={filter.weight}
                      onClick={() => handleFilterClick(filter.href)}
                    />
                  )}
                />
              ) : (
                <ListItem
                  key={filter.attribute}
                  color={filter.color}
                  count={filter.count}
                  endAdornment={filter.endAdornment}
                  icon={filter.icon}
                  label={filter.label}
                  selected={filter.attribute === selectedFilter}
                  weight={filter.weight}
                  onClick={() => handleFilterClick(filter.href)}
                />
              )
            )}
          </Fragment>
        );
      })}
    </SideBar>
  );
}

TableFilters.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  selectedFilter: PropTypes.string,
  tableFilters: PropTypes.array,
  width: PropTypes.string,
};

export default TableFilters;
