import { useQuery } from '@tanstack/react-query';

import apiClient from '@/apis/semji/apiClient';
import { LOCATION, SCOPE_USER } from '@/apis/semji/constants';

export default function useGetUserUniversalSearchApiKey() {
  const { post } = apiClient;

  return useQuery({
    queryFn: async () => {
      const result = await post(`/me/algolia_token`, {});

      return result.data ?? {};
    },
    queryKey: [SCOPE_USER.UNIVERSAL_SEARCH_API_KEY, LOCATION.ROOT],
  });
}
