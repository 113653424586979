import styled from 'styled-components/macro';

import DatePicker from '@/components/DatePicker';
import { Cell, Divider } from '@/components/Pages/ContentIdeas/common';
import CheckboxRender, {
  SELECT_ONCE,
} from '@/components/Pages/ContentIdeas/Listing/CellRender/Checkbox';
import UserSelect from '@/components/Select/UserSelect';
import ContentScoreCell from '@/components/Table/Cell/ContentScoreCell';
import TitleStatefull from '@/components/TitleStatefull';
import useNullUser from '@/hooks/useNullUser';

const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  height: ${({ rowSize }) => `${rowSize}px`};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.cssColors.dark005};
  }
`;

function RowRender({
  id,
  length,
  handleSelect,
  selectedContents,
  title,
  contentScore,
  assignedTo,
  dueDate,
  type,
  contentStatus,
  onClick,
  rowSize,
}) {
  const nullUser = useNullUser();

  return (
    <>
      <RowWrapper rowSize={rowSize} onClick={onClick}>
        {length > 1 && (
          <div>
            <CheckboxRender
              handleSelect={handleSelect}
              id={id}
              selectedIds={selectedContents}
              type={SELECT_ONCE}
            />
          </div>
        )}
        <TitleStatefull contentStatus={contentStatus} title={title} type={type} />
        <Cell align="center" noPadding size="sm" weight="medium" width="90px">
          <ContentScoreCell contentScore={contentScore} />
        </Cell>
        <Cell align="center" noPadding size="sm" weight="medium" width="90px">
          <UserSelect
            disabled
            options={[assignedTo || nullUser]}
            shortVersion
            value={assignedTo?.id || nullUser.id}
            variant="column"
            width={300}
          />
        </Cell>
        <Cell align="center" color="dark060" noPadding size="sm" weight="medium" width="90px">
          <DatePicker disabled dueDate={dueDate} shortVersion size="35px" small />
        </Cell>
      </RowWrapper>
      <Divider />
    </>
  );
}

export default RowRender;
