import { useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import apiClient from '@/apis/semji/apiClient';

export default function usePostOrganizationUnitUsages(props) {
  const { post } = apiClient;
  const { organizationId } = useParams();

  return useMutation({
    mutationFn: ({ fileType }) =>
      post(`/organizations/${organizationId}/organization_unit_usages_export`, { fileType }),
    ...props,
  });
}
