import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import usePostNewContent from '@/apis/semji/contents/usePostNewContent';
import usePostRefreshContentData from '@/apis/semji/contents/usePostRefreshContentData';
import {
  CREATE_NEW_DRAFT_FAIL,
  CREATE_NEW_DRAFT_LOADING,
  CREATE_NEW_DRAFT_SUCCESS,
} from '@/store/actions/actionTypes';
import { showErrorSnackbar } from '@/store/actions/ui';
import { SECTIONS } from '@/utils/log/constants';
import { Log } from '@/utils/log/Log';

export default function useCreateNewDraft() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { organizationId, workspaceId, pageId } = useParams();
  const refreshContentData = usePostRefreshContentData({
    onSuccess: ({ data }) => {
      navigate(`/o/${organizationId}/w/${workspaceId}/p/${pageId}/content/${data.id}`);
    },
  });

  const newContent = usePostNewContent({
    onError: (error: any) => {
      dispatch({
        type: CREATE_NEW_DRAFT_FAIL,
      });
      Log.report({
        context: 'createNewDraft',
        error,
        extra: 'Create new draft from version history',
        section: SECTIONS.content.key,
      });
      dispatch(showErrorSnackbar(t('common:error.default')));
    },
    onMutate: () => dispatch({ type: CREATE_NEW_DRAFT_LOADING }),
    onSuccess: (result) => {
      dispatch({
        payload: {
          draft: {
            id: result.data.id,
          },
        },
        type: CREATE_NEW_DRAFT_SUCCESS,
      });

      refreshContentData.mutate(result.data.id);
    },
  });
  function createNewDraft() {
    newContent.mutate({
      data: {
        page: `/pages/${pageId}`,
      },
      keyword: null,
      startAnalysis: true,
    });
  }

  return {
    createNewDraft,
    isCreating: newContent.isLoading || refreshContentData.isLoading,
  };
}
