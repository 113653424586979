import './AddFilter.scss';

import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'semji-core/components/Dropdown';

import { AddFilterProps } from '@/components/DynamicFilters/AddFilter/AddFilter.types';
import ScrollBar from '@/components/Scrollbar';

function AddFilter({ secondaryFilters, showFilter }: AddFilterProps) {
  const { t } = useTranslation();
  const dropDrownActionsRef = useRef({
    handleClose: () => null,
    handleOpen: () => null,
  });

  function handleDropDrownInteract() {}

  function handleSelectKey(key: string) {
    dropDrownActionsRef.current.handleClose();
    showFilter(key);
  }

  function getContentHeight() {
    return Math.min(secondaryFilters.length * 43 + 32, 280);
  }

  if (secondaryFilters.length === 0) return <></>;

  return (
    <Dropdown
      actionsRef={dropDrownActionsRef}
      anchorElement={
        <div className="add-filter">{t('components:filters.filter-groups.button-add-filter')}</div>
      }
      containerClassName=""
      isFrame={false}
      offsetValue={10}
      placement={'bottom-start'}
      querySelectorListener="#root"
      width="240px"
      onClose={handleDropDrownInteract}
      onOpen={handleDropDrownInteract}
    >
      <div className="add-filter-dropdown__content" style={{ height: getContentHeight() }}>
        <ScrollBar autoHide={false} weight="4px">
          <div className="add-filter-dropdown__content__list">
            {secondaryFilters.map((filter) => (
              <div
                key={`add-filter-dropdown-${filter.name}`}
                className="add-filter-dropdown__content__list__item"
                onClick={() => handleSelectKey(filter.key)}
              >
                {filter.icon} {filter.name}
              </div>
            ))}
          </div>
        </ScrollBar>
      </div>
    </Dropdown>
  );
}

export default AddFilter;
