import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { PureAvatar } from 'semji-core/components/Avatar';
import styled from 'styled-components/macro';

import useGetOrganizationAccessesById from '@/apis/semji/organizationAccesses/useGetOrganizationAccessesById';
import { ParagraphText } from '@/components/Text/Paragraph';
import { TitleTwo } from '@/components/Text/Title';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import { API_ORGANIZATION_ROLE_ADMIN } from '@/utils/can/constants';

const WrapperBox = styled.div`
  height: 100%;
  display: flex;
`;

const Box = styled.div`
  background: white;
  min-width: 350px;
  max-width: 800px;
  margin: auto;
  padding: 2rem;
  border-radius: 5px;
`;

const BoxBody = styled.div`
  padding-top: 1.5rem;
`;

const BoxList = styled.div`
  padding-top: 1rem;
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 1.5rem;
`;

const Item = styled.a`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${(props) => props.theme.cssColors.dark100};
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: ${(props) => props.theme.cssColors.secondary};
  }
`;

export default function PermissionBox({ organizationName, organizationId }) {
  const { t } = useTranslation();
  const { data } = useGetOrganizationAccessesById(organizationId);
  const currentUser = useSelector((state) => state.user);

  const apiRootUrl = import.meta.env.VITE_REACT_APP_SEMJI_API_ROOT_URL;
  const title = t('workspace:default-workspace.no-permissions-access-workspaces.title', {
    organizationName,
  });
  const message = t('workspace:default-workspace.no-permissions-access-workspaces.message');

  return (
    <WrapperBox>
      <Box>
        <TitleTwo noMargin weight="strong">
          {title}
        </TitleTwo>
        <BoxBody>
          <ParagraphText color="dark080" noPadding weight="medium">
            {message}
          </ParagraphText>
          <BoxList>
            {data?.map(({ user, roles }) => {
              const adminName =
                user.firstName && user.lastName && user.firstName + ' ' + user.lastName;
              const userName = currentUser.firstName + ' ' + currentUser.lastName;
              const emailSubject = t(
                'workspace:default-workspace.no-permissions-access-workspaces.email.subject',
                { user: userName }
              );
              const emailBody = t(
                'workspace:default-workspace.no-permissions-access-workspaces.email.body',
                { admin: adminName || '', organizationName, user: userName }
              );

              if (!roles.includes(API_ORGANIZATION_ROLE_ADMIN)) {
                return null;
              }
              return (
                user.lastLoginAt && (
                  <TooltipComponent key={user.id} title={user.email}>
                    <Item
                      href={`mailto:${user.email}?subject=${emailSubject}&body=${encodeURI(
                        emailBody
                      )}`}
                    >
                      <PureAvatar
                        apiRootUrl={apiRootUrl}
                        email={user.email ?? ''}
                        firstName={user.firstName || ''}
                        id={user.id}
                        lastName={user.lastName || ''}
                        profileImageHash={user.profileImageHash || null}
                        translations={{ unassigned: t('common:user-picker.unassigned') }}
                      />
                      {adminName || user.email}
                    </Item>
                  </TooltipComponent>
                )
              );
            })}
          </BoxList>
        </BoxBody>
      </Box>
    </WrapperBox>
  );
}
