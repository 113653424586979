import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogModal, ENUM_DIALOG_MODAL_SIZE } from 'semji-core/components/DialogModal';

import { Text } from '@/components/Dialog/ExportCalendar.styles';

function StartAtomicContentDialog({ onClose, onStart, show }) {
  const { t } = useTranslation();
  const loadingRef = useRef(false);

  function handleClick() {
    if (!loadingRef.current) {
      loadingRef.current = true;
      onStart();
    }
  }

  useEffect(() => {
    if (!show && loadingRef.current) {
      loadingRef.current = false;
    }
  }, [show]);

  return (
    <DialogModal
      cancelAction={onClose}
      cancelLabel={t('components:dialog.atomic-content.cancel')}
      canClickOutsideToClose
      closable
      confirmAction={handleClick}
      confirmLabel={t('components:dialog.atomic-content.confirm')}
      isOpen={show}
      size={ENUM_DIALOG_MODAL_SIZE.Large}
      title={t('components:dialog.atomic-content.title')}
      onClose={onClose}
    >
      <Text>{t('components:dialog.atomic-content.text')}</Text>
    </DialogModal>
  );
}

export default StartAtomicContentDialog;
