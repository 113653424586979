import i18next from 'i18next';
import { DateTime } from 'luxon';

import { Content, ContentStatus, ENUM_CONTENT_TYPE } from '@/types/contents';
import { ContentVersion } from '@/types/contents/ContentVersion.types';
import { UserModel as User } from '@/types/user/user.types';

export function formatDate(date?: string, withoutTime = false) {
  const currentDate = DateTime.now();
  if (!date) {
    return {
      day: currentDate.day,
      displayDate: i18next.t('content:version-history-panel.current-draft'),
      hour: currentDate.hour,
      longDisplayDate: i18next.t('content:version-history-panel.current-draft'),
      month: currentDate.month,
      year: currentDate.year,
    };
  }

  const parsedDate = DateTime.fromISO(date);
  let display: string;
  let longDisplay: string;

  if (parsedDate.hasSame(currentDate, 'day')) {
    display = i18next.t(`content:version-history-panel.${withoutTime ? 'today' : 'today-at'}`, {
      time: parsedDate.toFormat('HH:mm'),
    });
    longDisplay = display;
  } else if (parsedDate.hasSame(currentDate.minus({ days: 1 }), 'day')) {
    display = i18next.t(
      `content:version-history-panel.${withoutTime ? 'yesterday' : 'yesterday-at'}`,
      {
        time: parsedDate.toFormat('HH:mm'),
      }
    );
    longDisplay = display;
  } else {
    display = withoutTime
      ? parsedDate.toFormat('dd MMMM')
      : i18next.t(`content:version-history-panel.date-formatted`, {
          date: parsedDate.toFormat('dd MMMM'),
          time: parsedDate.toFormat('HH:mm'),
        });
    longDisplay = withoutTime
      ? parsedDate.toFormat('dd MMMM yyyy')
      : i18next.t(`content:version-history-panel.date-formatted`, {
          date: parsedDate.toFormat('dd MMMM yyyy'),
          time: parsedDate.toFormat('HH:mm'),
        });
  }

  return {
    day: parsedDate.day,
    displayDate: display,
    hour: parsedDate.hour,
    longDisplayDate: longDisplay,
    month: parsedDate.month,
    year: parsedDate.year,
  };
}

export function buildVersions(
  contents: Content[],
  drafts: ContentVersion[],
  users: User[],
  currentDraftStatusId: string,
  contentsStatus: ContentStatus[]
) {
  const lastUpdatedById = drafts[0]?.authorId;

  return [
    ...contents.map((content) => ({
      author: users.find(
        (user) =>
          user.id ===
          (content.type === ENUM_CONTENT_TYPE.DRAFT ? lastUpdatedById : content.publishedById)
      ),
      color: contentsStatus.find((status) => status.id === content.contentStatusId)?.color,
      date: content.publishedAt ?? content.createdAt,
      id: content.id,
      isCurrentDraft: content.type === ENUM_CONTENT_TYPE.DRAFT,
      name: undefined,
      score: content.contentScore,
      type: content.type,
      ...formatDate(content.publishedAt ?? content.createdAt, !!content.publishedAt),
    })),
    // We avoid to show 2 times the current draft in the version history
    ...drafts.slice(1).map((draft) => ({
      author: users.find((user) => user.id === draft.authorId),
      color: contentsStatus.find((status) => status.id === currentDraftStatusId)?.color,
      date: draft.createdAt,
      id: draft.id,
      name: draft.name,
      score: draft.contentScore,
      type: ENUM_CONTENT_TYPE.DRAFT,
      isCurrentDraft: false,
      ...formatDate(draft.createdAt),
    })),
  ];
}
