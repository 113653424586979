import styled from 'styled-components/macro';

import FileIcon from '@/components/icons/FileIcon';
import LoopIcon from '@/components/icons/LoopIcon';
import { NEW_CONTENT, UPDATED_CONTENT } from '@/components/Pages/ContentIdeas/constant';

const StyledFileIcon = styled(FileIcon)`
  cursor: help;
  margin: 8px;
  color: rgba(37, 39, 54, 0.4);
`;
const StyledLoopIcon = styled(LoopIcon)`
  cursor: help;
  margin: 8px;
  color: rgba(37, 39, 54, 0.4);
`;

function ContentType({ type }) {
  if (type === NEW_CONTENT) {
    return <StyledFileIcon />;
  }

  if (type === UPDATED_CONTENT) {
    return <StyledLoopIcon />;
  }

  return null;
}

export default ContentType;
