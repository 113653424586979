import styled from 'styled-components/macro';

import { FlexCenteredAlignContainer } from '@/components/_common';
import AngleDoubleLeftIcon from '@/components/icons/AngleDoubleLeftIcon';

export const TitleWrapper = styled(FlexCenteredAlignContainer)`
  justify-content: space-between;
  margin-bottom: 26px;
  padding: 24px 17px 0 0;
  min-height: 35px;
  flex: 0;
`;

export const Chevron = styled(AngleDoubleLeftIcon)`
  padding: 5px;
  color: ${({ theme }) => theme.cssColors.dark060};
  transform: ${({ $isOpen }) => !$isOpen && 'rotate(180deg)'};
  margin: ${({ margin }) => margin ?? '5px 0 0 0'};
  &:hover {
    border-radius: 5px;
    color: ${({ theme }) => theme.cssColors.dark080};
    background-color: ${({ theme }) => theme.cssColors.dark010};
  }
`;
