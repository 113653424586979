import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import CreditLimitReachedDialog from '@/components/Dialog/CreditLimitReachedDialog';
import {
  EmptyStatePattern,
  SimpleBodyEmptyState,
  StyledOptimizationIcon,
  StyledTrafficIcon,
} from '@/components/EmptyState/commons';
import { EditorEmptyBodyWrapper } from '@/containers/Content/Wrappers';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import useParamSelector from '@/store/hooks/useParamSelector';
import { selectCredits } from '@/store/selectors/selectCredits';
import { ANALYSIS_CREDITS_TYPE } from '@/utils/constants';

export const NO_PAGES_DASHBOARD = 'noPagesDashboard';

const NoPages = ({ version }) => {
  const { t } = useTranslation();
  const { organizationId, workspaceId } = useParams();
  const isRemainingAnalysesCredits =
    useParamSelector(selectCredits, ANALYSIS_CREDITS_TYPE, 'remaining') > 0;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { isFeatureEnabled: hasUnlimitedAnalysisCredits } = useOrganizationFeatureSet(
    'credits:analysis:has-unlimited-amount'
  );

  const NO_PAGES_DASHBOARD_VALUES = {
    [NO_PAGES_DASHBOARD]: {
      buttonLabel: t('empty-state:no-pages.no-pages-dashboard.button'),
      href: `/o/${organizationId}/w/${workspaceId}/contents/import-pages`,
      title: t('empty-state:no-pages.no-pages-dashboard.title'),
    },
  };

  function handleOpenModal() {
    setIsModalOpen(true);
  }

  function handleCloseModal() {
    setIsModalOpen(false);
  }

  if (version) {
    return (
      <EditorEmptyBodyWrapper>
        <StyledOptimizationIcon />
        <SimpleBodyEmptyState
          buttonLabel={NO_PAGES_DASHBOARD_VALUES[version].buttonLabel}
          title={NO_PAGES_DASHBOARD_VALUES[version].title}
          url={NO_PAGES_DASHBOARD_VALUES[version].href}
        />
      </EditorEmptyBodyWrapper>
    );
  }
  return (
    <>
      <EmptyStatePattern
        buttonLabel={t('empty-state:no-pages.default.button')}
        icon={<StyledTrafficIcon />}
        openModal={
          !hasUnlimitedAnalysisCredits && !isRemainingAnalysesCredits ? handleOpenModal : null
        }
        text={<Trans i18nKey={'empty-state:no-pages.default.body'} />}
        title={t('empty-state:no-pages.default.title')}
        url={`/o/${organizationId}/w/${workspaceId}/contents/import-pages`}
      />
      {isModalOpen && (
        <CreditLimitReachedDialog
          creditType={ANALYSIS_CREDITS_TYPE}
          isOpen
          onClose={handleCloseModal}
        />
      )}
    </>
  );
};

export default NoPages;
