import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import apiClient from '@/apis/semji/apiClient';
import { SCOPE_CONTENTS } from '@/apis/semji/constants';
import { showErrorSnackbar } from '@/store/actions/ui';

export default function useGetCurrentWorkspaceContentsWithSpecificProperties({
  filters = {},
  properties = [],
  location,
  ...props
}) {
  const { t } = useTranslation();
  const { get } = apiClient;
  const dispatch = useDispatch();
  const workspaceId = useSelector((state) => state.defaultWorkspaceId || '');

  return useQuery({
    onError: () => {
      dispatch(showErrorSnackbar(t('common:error.default')));
    },
    placeholderData: [],

    queryFn: async ({ signal }) => {
      const result = await get(`/workspaces/${workspaceId}/contents`, {
        params: {
          ...filters,
          properties,
        },
        signal,
      });

      return result.data['hydra:member'] || [];
    },
    queryKey: [SCOPE_CONTENTS.WITH_SPECIFIC_PROPERTIES, workspaceId, location],
    ...props,
  });
}
