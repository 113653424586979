import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';

import InfoIcon from '../icons/InfoIcon';
import { ParagraphText } from '../Text/Paragraph';

const Wrapper = styled.div`
  background: ${(props) => props.theme.cssColors.primary010};
  border-radius: 5px;
`;
const ErrorWrapper = styled(Wrapper)`
  margin: 8px auto;
  padding: 0.2rem;
  display: flex;
`;
const StyledInfoIcon = styled(InfoIcon)`
  && {
    font-size: 0.7rem;
    margin: 8px;
    color: ${(props) => props.theme.cssColors.red};
  }
`;

function WarningMessage({ type, children, ...props }) {
  switch (type) {
    case 'error':
      return (
        <ErrorWrapper {...props}>
          <StyledInfoIcon bordered />
          <ParagraphText color="red" size="sm">
            {children}
          </ParagraphText>
        </ErrorWrapper>
      );
    case 'warning':
    default:
      return (
        <Wrapper {...props}>
          <ParagraphText color="dark080">{children}</ParagraphText>
        </Wrapper>
      );
  }
}

WarningMessage.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
  type: PropTypes.string.isRequired,
};

export default WarningMessage;
