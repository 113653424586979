import styled from 'styled-components/macro';

const NavBar = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 60px;
  background: ${({ userImpersonate, variant, theme }) =>
    userImpersonate
      ? theme.colors.darkGreyOpaque
      : variant === 'dark'
        ? theme.cssColors.dark100
        : theme.colors.primaryGradient};

  ${({ theme }) => theme.mediaQueries.phone} {
    padding: 0 ${({ theme }) => theme.textCss.sizes.xs};
  }

  ${({ theme }) => theme.mediaQueries.tablet} {
    padding: 0 ${({ theme }) => theme.textCss.sizes.xs};
  }

  ${({ theme }) => theme.mediaQueries.desktop} {
    padding: 0 ${({ theme }) => theme.textCss.sizes.default};
  }

  ${({ theme }) => theme.mediaQueries.largeDesktop} {
    padding: 0 ${({ theme }) => theme.textCss.sizes.default};
  }
`;

export default NavBar;
