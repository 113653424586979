import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import { RelativeLoader } from '@/components/Loader/Loader';
import { DiffPercentage } from '@/components/Metrics/DiffPercentage';
import NotAcknowledgedCell from '@/components/Table/Cell/NotAcknowledgedCell';
import { BrightText } from '@/components/Text/Light';
import { formatDateByPeriodicity } from '@/utils/metrics/formatDateByPeriodicity';

export function ChangeLabel({ date }) {
  const { t } = useTranslation();

  return (
    <div>
      <div>{t('report:change-label.title')}</div>
      <BrightText capitalize size="small">{`(${formatDateByPeriodicity({
        date,
        locale: i18next.language,
      })})`}</BrightText>
    </div>
  );
}

export function ChangeCell({ metricValue, evolutionRating, isLoading, suffix }) {
  if (isLoading) {
    return <RelativeLoader fontSize="2rem" opaque />;
  }

  if (metricValue !== null && metricValue.value !== null) {
    return (
      <span>
        <DiffPercentage
          evolutionRating={evolutionRating}
          initialValue={metricValue.initialValue}
          suffix={`${metricValue.suffix}${suffix}`}
          value={metricValue.value}
        />
      </span>
    );
  }

  return <NotAcknowledgedCell align="center" />;
}
