import './Table.scss';

import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import SortDirection from 'react-virtualized/dist/es/Table/SortDirection';

import { Cell } from '@/components/FocusKeyword/Cell';
import CloseIcon from '@/components/icons/CloseIcon';
import FlatLoader, { DEFAULT_LOADER_HEIGHT, LoaderWrapper } from '@/components/Loader/FlatLoader';
import Link from '@/components/Navigation/Link';
import { LoadingCellRender } from '@/components/Pages/ContentIdeas/Menu/constant';
import Table from '@/components/Table/Table';
import TooltipComponent from '@/components/Tooltip/Tooltip';

function TableBrandVoices({ rows, isLoading, handleDelete }) {
  const { t } = useTranslation();
  const location = useLocation();

  const LOADING_ROWS_PLACEHOLDER = Array(5).fill({
    createdAt: '',
    createdById: '',
    name: '',
    updatedAt: '',
    updatedById: '',
  });

  function handleDeleteBrandVoice(id) {
    return () => handleDelete(id);
  }

  const columns = [
    {
      cellDataGetter: ({ rowData }) => ({
        isLoading: isLoading,
        name: rowData.name,
        url: location.pathname + '/' + rowData.id,
      }),
      cellRenderer: ({ cellData }) => (
        <Cell color="dark100" noPadding weight="strong" width="100%">
          <LoadingCellRender
            fullWidth
            status={cellData.isLoading ? 'pending' : 'success'}
            value={
              <span className="table-brand-voice__title-cell">
                <Link title={cellData.name} to={cellData.url}>
                  {cellData.name}
                </Link>
              </span>
            }
          />
        </Cell>
      ),
      dataKey: 'name',
      flexGrow: 1,
      label: (
        <Cell
          color="dark060"
          noPadding
          size="sm"
          title={t('brand-voice:table.header.brand-voice')}
          weight="strong"
        >
          {t('brand-voice:table.header.brand-voice')}
        </Cell>
      ),
      sortFunction: (value1, value2, sortDirection) =>
        Table.stringSort(value1.name, value2.name, sortDirection),
      sortable: true,
      width: 150,
    },
    {
      cellDataGetter: ({ rowData }) => ({
        createdByName: rowData.createdByName,
        isLoading,
      }),
      cellRenderer: ({ cellData }) => {
        if (cellData.isLoading) {
          return (
            <LoaderWrapper height={DEFAULT_LOADER_HEIGHT} width={180}>
              <FlatLoader />
            </LoaderWrapper>
          );
        }
        return <span className="table-brand-voice__cell">{cellData.createdByName}</span>;
      },
      dataKey: 'createdByName',
      label: (
        <Cell
          color="dark060"
          noPadding
          size="sm"
          title={t('brand-voice:table.header.created-by')}
          weight="strong"
        >
          {t('brand-voice:table.header.created-by')}
        </Cell>
      ),
      sortFunction: (value1, value2, sortDirection) =>
        Table.stringSort(value1.createdByName, value2.createdByName, sortDirection),
      sortable: true,
      width: 220,
    },
    {
      cellDataGetter: ({ rowData }) => ({
        createdAt: rowData.createdAt,
        isLoading,
      }),
      cellRenderer: ({ cellData }) => {
        if (cellData.isLoading) {
          return (
            <LoaderWrapper height={DEFAULT_LOADER_HEIGHT} width={120}>
              <FlatLoader />
            </LoaderWrapper>
          );
        }
        return (
          <span className="table-brand-voice__cell">
            {DateTime.fromISO(cellData.createdAt).toFormat('dd LLL yy').toLocaleLowerCase()}
          </span>
        );
      },
      dataKey: 'createdAt',
      label: (
        <Cell
          color="dark060"
          noPadding
          size="sm"
          title={t('brand-voice:table.header.creation-date')}
          weight="strong"
        >
          {t('brand-voice:table.header.creation-date')}
        </Cell>
      ),
      sortFunction: (value1, value2, sortDirection) =>
        Table.naturalSort(value1.createdAt, value2.createdAt, sortDirection),
      sortable: true,
      width: 180,
    },
    {
      cellDataGetter: ({ rowData }) => ({
        isLoading,
        updatedAt: rowData.updatedAt,
      }),
      cellRenderer: ({ cellData }) => {
        if (cellData.isLoading) {
          return (
            <LoaderWrapper height={DEFAULT_LOADER_HEIGHT} width={120}>
              <FlatLoader />
            </LoaderWrapper>
          );
        }
        return (
          <span className="table-brand-voice__cell">
            {DateTime.fromISO(cellData.updatedAt).toFormat('dd LLL yy').toLocaleLowerCase()}
          </span>
        );
      },
      dataKey: 'updatedAt',
      label: (
        <Cell
          color="dark060"
          noPadding
          size="sm"
          title={t('brand-voice:table.header.edition-date')}
          weight="strong"
        >
          {t('brand-voice:table.header.edition-date')}
        </Cell>
      ),
      sortFunction: (value1, value2, sortDirection) =>
        Table.naturalSort(value1.updatedAt, value2.updatedAt, sortDirection),
      sortable: true,
      width: 180,
    },
    {
      cellDataGetter: ({ rowData }) => ({
        isLoading,
        updatedByName: rowData.updatedByName,
      }),
      cellRenderer: ({ cellData }) => {
        if (isLoading) {
          return (
            <LoaderWrapper height={DEFAULT_LOADER_HEIGHT} width={110}>
              <FlatLoader />
            </LoaderWrapper>
          );
        }
        return <span className="table-brand-voice__cell">{cellData.updatedByName}</span>;
      },
      dataKey: 'updatedByName',
      label: (
        <Cell
          color="dark060"
          noPadding
          size="sm"
          title={t('brand-voice:table.header.last-editor')}
          weight="strong"
        >
          {t('brand-voice:table.header.last-editor')}
        </Cell>
      ),
      sortFunction: (value1, value2, sortDirection) =>
        Table.stringSort(value1.updatedByName, value2.updatedByName, sortDirection),
      sortable: true,
      width: 150,
    },
    {
      align: 'center',
      cellDataGetter: ({ rowData }) => ({
        id: rowData.id,
        isSkeletonData: isLoading,
      }),
      cellRenderer: ({ cellData }) =>
        !cellData.isSkeletonData && (
          <Cell
            className="table-brand-voice__close-icon"
            cursor={`pointer`}
            lineHeight="0"
            noPadding
            size="sm"
          >
            <TooltipComponent title={t(`brand-voice:table.delete`)}>
              <CloseIcon onClick={handleDeleteBrandVoice(cellData.id)} />
            </TooltipComponent>
          </Cell>
        ),
      dataKey: 'delete',
      label: '',
      sortable: false,
      width: 40,
    },
  ];

  return (
    <div className="table-brand-voice__wrapper">
      <Table
        columns={columns}
        defaultSort="createdAt"
        defaultSortDirection={SortDirection.DESC}
        disablePadding
        headerHeight={50}
        mode="transparent"
        rowHeight={50}
        rows={isLoading ? LOADING_ROWS_PLACEHOLDER : rows}
      />
    </div>
  );
}

export default TableBrandVoices;
