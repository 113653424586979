import './Report.scss';

import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { Tooltip } from 'semji-core/components/Tooltip';
import { InfoIcon } from 'semji-core/icons/InfoIcon';

import Card from '@/components/Card/Card';
import {
  NO_COMPETITOR_EVOLUTION_3YEARS_DATA,
  NO_COMPETITOR_EVOLUTION_3YEARS_MID_DATA,
} from '@/components/EmptyState/NoContentsVerticalBars';
import ChartWrapper, {
  CHART_WRAPPER_VARIANT_SMALL,
} from '@/containers/Competitors/components/ChartWrapper';
import EmptyStateChart from '@/containers/Competitors/components/EmptyStateChart';
import { ExportOptionButton } from '@/containers/Competitors/components/ExportOptionButton/ExportOptionButton';
import { HighChartsGroupedBar } from '@/containers/Competitors/Duel/Report/Chart/HighChartsGroupedBar';
import { SMALL_CARD_CONTENT_MIN_HEIGHT } from '@/containers/Competitors/Duel/Report/constant';
import { useCompetitor } from '@/containers/Competitors/hooks/useCompetitor';
import { EVOLUTION_REPORT_COLORS } from '@/containers/Competitors/utils/colors';
import {
  COMPETITORS_METRIC_TYPES,
  RETRIEVAL_DATE_FILTER_QUERY_PARAM,
} from '@/containers/Competitors/utils/constants';
import { filterCompetitorWebsites, getHostnamefromUrl } from '@/containers/Competitors/utils/utils';

export function EvolutionReport({
  title,
  tooltip,
  type,
  isMinimizable,
  metrics,
  competitorsFilter,
  isLoading,
  handleOpenDataExplorer,
}) {
  const { competitorWebsites, websiteReference, competitorComparativeDates } = useCompetitor();
  const [exportImage, setExportImage] = useState(false);

  const reference = getHostnamefromUrl(websiteReference?.url);
  const { series, categories } = buildSeries();
  const sortKeyNames = competitorComparativeDates.map((date) => new Date(date).getFullYear());

  function getCompetitorMetricByType(competitorId, dateReport) {
    let valueMetricByCompetitorId =
      metrics?.[dateReport]?.find((metric) => metric?.competitorWebsiteId === competitorId)?.[
        type
      ] ?? 0;
    let nbPages = metrics?.[dateReport]?.filter(
      (metric) => metric.competitorWebsiteId === competitorId
    )[0]?.nbPages;
    return {
      custom: {
        competitorId,
        nbPages: nbPages,
        type: DateTime.fromISO(dateReport).toFormat('yyyy-MM-dd'),
        value: valueMetricByCompetitorId,
      },
      y: valueMetricByCompetitorId,
    };
  }
  function buildSeries() {
    const dates = [...competitorComparativeDates].sort((a, b) => new Date(a) - new Date(b));
    const websites = filterCompetitorWebsites(competitorWebsites, competitorsFilter);

    const series = dates.map((dateReport, index) => {
      return {
        color: EVOLUTION_REPORT_COLORS[type][index],
        data: websites.map(({ id }) => getCompetitorMetricByType(id, dateReport)),
        name: new Date(dateReport).getFullYear(),
      };
    });

    const categories = websites.map((website) => {
      return { name: website.name, url: website.url, urlType: website.urlType };
    });
    return { categories, series };
  }

  const isNoData =
    series?.length === 0 ||
    categories?.length === 0 ||
    series.reduce((acc, serie) => acc + serie.data.reduce((acc, data) => acc + data.y, 0), 0) === 0;

  const chartRef = React.useRef(null);

  return (
    <ChartWrapper isLoading={isLoading} variant={CHART_WRAPPER_VARIANT_SMALL}>
      <Card
        className="competitors-duel-report__card"
        contentMinHeight={SMALL_CARD_CONTENT_MIN_HEIGHT}
        title={
          <div className="competitors-duel-report__card__header">
            <div className="competitors-duel-report__card__header__title">
              <span>{title}</span>
              <Tooltip
                description={tooltip.description}
                isFrame={false}
                title={tooltip.title}
                tooltipClassName="competitors-duel-report__card__header__title-tooltip competitors-duel-report__card__header__title-tooltip--extra-large"
              >
                <InfoIcon />
              </Tooltip>
            </div>
            <ExportOptionButton
              chartRef={chartRef}
              exportImage={exportImage}
              setExportImage={setExportImage}
            />
          </div>
        }
      >
        {isNoData ? (
          <EmptyStateChart
            version={
              isMinimizable
                ? NO_COMPETITOR_EVOLUTION_3YEARS_MID_DATA
                : NO_COMPETITOR_EVOLUTION_3YEARS_DATA
            }
          />
        ) : (
          <HighChartsGroupedBar
            categories={categories}
            chartRef={chartRef}
            exportImage={exportImage}
            metricsSeries={series}
            reference={reference}
            selectedMetrics={[...new Set([type, COMPETITORS_METRIC_TYPES.page])]}
            serieKey={RETRIEVAL_DATE_FILTER_QUERY_PARAM}
            showLegendWinner={false}
            sortKeyNames={sortKeyNames}
            title={title}
            withPositioner={true}
            onPointClick={handleOpenDataExplorer}
          />
        )}
      </Card>
    </ChartWrapper>
  );
}
