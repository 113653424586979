import './HeaderTitle.scss';

import React from 'react';

import { usePageDataLayout } from '@/components/PageDataLayout/PageDataLayout.context';

export default function HeaderTitle({
  title,
  description,
  className,
  actionsContent,
}: {
  title: string | React.ReactNode;
  description?: string;
  className?: string;
  actionsContent?: React.ReactNode;
}) {
  const { isSidebarOpen, ToggleComponent } = usePageDataLayout();
  return (
    <div className={`competitors-header-title ${className}`}>
      <div className="competitors-header-title__text">
        <div className="competitors-header-title__h1">
          {!isSidebarOpen && ToggleComponent}
          {title}
        </div>
        {!!description && (
          <div className="competitors-header-title__description">{description}</div>
        )}
      </div>
      <div className="competitors-header-title__actions">{actionsContent}</div>
    </div>
  );
}
