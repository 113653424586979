import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import styled from 'styled-components/macro';

import { PRIMARY_COUNTRIES } from '@/utils/helper';

const MAX_NUMBER_LENGTH = 15;

const InputWrapper = styled.div`
  display: flex;
  flex: 1;
  input[type='search']::-webkit-search-cancel-button {
    cursor: pointer;
  }
`;

function PhoneNumberInput(props) {
  const { t } = useTranslation();
  const [isValid, setIsValid] = useState(props.isValid);

  function onChange(value, country, e, formattedValue) {
    props.onChange(`+${value}`, country, e, formattedValue);
  }

  function onFocus() {
    setIsValid(true);
  }

  function onBlur() {
    setIsValid(props.isValid);
    props.onBlur();
  }

  function checkValid() {
    return isValid;
  }

  return (
    <InputWrapper>
      <PhoneInput
        autocompleteSearch
        autoFormat={false}
        country={props.defaultCountry.toLowerCase()}
        countryCodeEditable={false}
        disableSearchIcon
        enableSearch
        inputProps={{
          autoFocus: props.autoFocus,
          maxLength: MAX_NUMBER_LENGTH,
          name: 'phone',
          required: true,
        }}
        isValid={checkValid}
        placeholder={props.placeholder}
        preferredCountries={PRIMARY_COUNTRIES.map((country) => country.toLowerCase())}
        searchPlaceholder={t('components:phone-number.search-input-placeholder')}
        searchStyle={{ margin: 0, width: '95%' }}
        value={props.phoneObject.value}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
      />
    </InputWrapper>
  );
}

PhoneNumberInput.propTypes = {
  autoFocus: PropTypes.bool,
  defaultCountry: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  phoneObject: PropTypes.shape({
    format: PropTypes.string,
    formattedValue: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  placeholder: PropTypes.string,
};

PhoneNumberInput.defaultProps = {
  autoFocus: false,
  defaultCountry: 'fr',
  onBlur: () => {},
  phoneObject: {
    value: '',
  },
  placeholder: null,
};

export default PhoneNumberInput;
