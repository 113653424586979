import { nanoid } from 'nanoid';
import React, { useMemo } from 'react';

import SvgWrapper from './SvgWrapper';

const SidePanelTourIcon = (props) => {
  const maskId = useMemo(() => nanoid(), []);
  return (
    <SvgWrapper {...props} fill="none" height="20" viewBox="0 0 18 20" width="18">
      <mask
        height="19"
        id={`mask${maskId}`}
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="16"
        x="1"
        y="0"
      >
        <path
          d="M9.52927 6.85294H14.5004L16.5524 4.13918L14.492 1.55882H9.52927V0.5H8.47044V1.55882H2.1175V6.85294H8.47044V8.97059H3.50774L1.44727 11.5509L3.49927 14.2647H8.47044V18.5H9.52927V14.2647H15.8822V8.97059H9.52927V6.85294ZM3.17632 2.61765H13.9827L15.212 4.15718L13.9742 5.79412H3.17632V2.61765ZM14.8234 13.2059H4.0255L2.78774 11.5689L4.01703 10.0294H14.8234V13.2059Z"
          fill="black"
        />
      </mask>
      <g mask={`url(#mask${maskId})`}>
        <rect fill="currentColor" height="21.1765" width="21.1765" x="-2.11719" y="-0.558838" />
      </g>
    </SvgWrapper>
  );
};

export default SidePanelTourIcon;
