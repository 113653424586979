import React, { ComponentType, ReactNode } from 'react';

import { ErrorBoundary } from '@/components/ErrorBoundary';

interface WithErrorBoundaryProps {
  fallback?: ReactNode;
}

export default function withErrorBoundary<
  T extends WithErrorBoundaryProps = WithErrorBoundaryProps,
>(WrappedComponent: ComponentType<T>) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
  const ComponentWithErrorBoundary = (props: T) => {
    const { fallback } = props;
    return (
      <ErrorBoundary fallback={fallback}>
        <WrappedComponent {...props} />
      </ErrorBoundary>
    );
  };

  ComponentWithErrorBoundary.displayName = displayName;

  return ComponentWithErrorBoundary;
}
