import React, { useId } from 'react';

function ParagraphCreditIcon(props) {
  const id = useId();
  return (
    <svg fill="none" height="15" viewBox="0 0 15 15" width="15" {...props}>
      <path
        d="M15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0C11.6421 0 15 3.35786 15 7.5Z"
        fill={`url(#linear0_${id})`}
      />
      <circle cx="7.5" cy="7.5" r="7.19444" stroke={`url(#linear1_${id})`} strokeWidth="0.611111" />
      <g clipPath={`url(#clip_${id})`}>
        <path
          d="M11.4395 4.46185V4.92456H3.57346V4.46185H11.4395ZM3.57346 6.77541H11.4395V6.3127H3.57346V6.77541ZM3.57346 8.62625H11.4395V8.16354H3.57346V8.62625ZM3.57346 10.4771H11.4395V10.0144H3.57346V10.4771Z"
          fill="white"
          stroke="white"
          strokeWidth="0.561863"
        />
      </g>
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id={`linear0_${id}`}
          x1="3.02908e-05"
          x2="15.0027"
          y1="7.50423"
          y2="7.50423"
        >
          <stop stopColor="#FF4580" />
          <stop offset="1" stopColor="#FF4D64" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id={`linear1_${id}`}
          x1="13.4425"
          x2="1.98413"
          y1="2.57962"
          y2="12.5102"
        >
          <stop stopColor="#CA3B4E" />
          <stop offset="1" stopColor="#CA3B4E" stopOpacity="0" />
        </linearGradient>
        <clipPath id={`clip_${id}`}>
          <rect
            fill="white"
            height="7.86609"
            transform="translate(3.57346 3.53643)"
            width="7.86609"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ParagraphCreditIcon;
