import { MatchResult } from './domMatcher.types';
import { DOMMatcherUtils } from './helpers/domMatcher.utils';

function findTextAcrossHtmlNodes({
  domWrapper,
  textToMatch,
  caseSenstivity = false,
  includePunctuation = false,
}: {
  domWrapper: HTMLElement;
  textToMatch: string;
  caseSenstivity?: boolean;
  includePunctuation?: boolean;
}): MatchResult | null {
  if (!(domWrapper instanceof HTMLElement) || textToMatch.length === 0) {
    return null;
  }

  const parentNodesOfTextNodes: Set<Node> = DOMMatcherUtils.getParentNodesOfTextNodes(domWrapper);

  return DOMMatcherUtils.matchTextAcrossNodes({
    blockNodes: parentNodesOfTextNodes,
    caseSenstivity,
    includePunctuation,
    textToMatch,
  });
}

export default findTextAcrossHtmlNodes;
