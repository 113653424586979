import PropTypes from 'prop-types';
import AutoSizer from 'react-virtualized/dist/es/AutoSizer';
import styled from 'styled-components/macro';

import HighChartsChart from '@/components/Chart/HighChartsChart';
import { ReportMetricKey } from '@/containers/Report/utils/types';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import { forgeMetricsSeries } from '@/utils/charts/forgeMetricsSeries';
import { buildLockMarker } from '@/utils/metrics/buildLockMarker';
import {
  METRIC_POSITION_KEY,
  METRICS_CONFIG_COMPARISON_PERIOD_PREVIOUS_RANGE,
  METRICS_CONFIG_COMPARISON_PERIOD_PREVIOUS_YEAR,
  METRICS_CONFIG_PERIOD_LAST_3_MONTHS,
  METRICS_CONFIG_PERIOD_LAST_6_MONTHS,
  METRICS_CONFIG_PERIOD_LAST_12_MONTHS,
  METRICS_CONFIG_PERIOD_LAST_24_MONTHS,
  METRICS_CONFIG_PERIOD_LAST_30_DAYS,
  METRICS_CONFIG_PERIOD_LAST_36_MONTHS,
  METRICS_CONFIG_PERIOD_LAST_90_DAYS,
  METRICS_CONFIG_PERIOD_LAST_180_DAYS,
  METRICS_CONFIG_PERIOD_LAST_365_DAYS,
  METRICS_CONFIG_PERIOD_LAST_MONTH,
  METRICS_CONFIG_PERIODICITY_DAILY,
  METRICS_CONFIG_PERIODICITY_MONTHLY,
  METRICS_CONFIG_PERIODICITY_QUARTERLY,
  METRICS_CONFIG_PERIODICITY_WEEKLY,
} from '@/utils/metrics/constants';
import { translateDate } from '@/utils/metrics/translateDate';

const MetricsChartWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  min-height: 300px;
`;

function MetricsChart({
  children,
  currentMetricKey,
  metrics = [],
  previousMetrics = [],
  markers = [],
  period,
  legendLeft,
  periodicity,
  comparisonPeriod,
  displayedMetrics = {},
  disableLegend = false,
  commonAxis,
  stacked,
  identifier,
  forceSelectedPositionKey,
  multipleMetricsKey,
  yAxisReversed,
  noMargin,
}) {
  const featureFlagData = useOrganizationFeatureSet('url-metrics:max-data-to-display-in-months');
  const gapDuration = translateDate({ comparisonPeriod, period, periodicity });

  const metricsSeries = forgeMetricsSeries({
    displayedMetrics,
    forceSelectedPositionKey:
      currentMetricKey === METRIC_POSITION_KEY ? forceSelectedPositionKey : null,
    gapDuration,
    // TODO: Remove this condition when decide which graph to use for average score
    keepNullValues: currentMetricKey === ReportMetricKey.AverageContentScore,
    metrics,
    periodicity,
    previousMetrics,
  });

  return (
    <MetricsChartWrapper>
      {children}
      <AutoSizer>
        {({ width, height }) => (
          <HighChartsChart
            // We are using keys here because of the autoSizer can change the calculated width and height
            // live size changing cause some issues to HighCharts and loose some values with stacked bar chart
            // we are controlling the chart size by destroying the component and forcing the size of the chart
            key={`chart_width_${width}_chart_height_${height}_${identifier}`}
            commonAxis={commonAxis}
            comparisonPeriod={comparisonPeriod}
            currentMetricKey={currentMetricKey}
            disableLegend={disableLegend}
            gapDuration={gapDuration}
            height={height}
            legendLeft={legendLeft}
            markers={[
              ...markers,
              ...buildLockMarker({
                comparisonPeriod,
                featureFlagData,
                period,
                periodicity,
              }),
            ]}
            metricsSeries={metricsSeries}
            multipleMetricsKey={multipleMetricsKey}
            noMargin={noMargin}
            period={period}
            periodicity={periodicity}
            stacked={stacked}
            width={width}
            yAxisReversed={yAxisReversed}
          />
        )}
      </AutoSizer>
    </MetricsChartWrapper>
  );
}

MetricsChart.propTypes = {
  comparisonPeriod: PropTypes.oneOf([
    METRICS_CONFIG_COMPARISON_PERIOD_PREVIOUS_RANGE,
    METRICS_CONFIG_COMPARISON_PERIOD_PREVIOUS_YEAR,
  ]).isRequired,
  identifier: PropTypes.string,
  metrics: PropTypes.arrayOf(PropTypes.object),
  period: PropTypes.PropTypes.oneOf([
    METRICS_CONFIG_PERIOD_LAST_30_DAYS,
    METRICS_CONFIG_PERIOD_LAST_90_DAYS,
    METRICS_CONFIG_PERIOD_LAST_180_DAYS,
    METRICS_CONFIG_PERIOD_LAST_365_DAYS,
    METRICS_CONFIG_PERIOD_LAST_MONTH,
    METRICS_CONFIG_PERIOD_LAST_3_MONTHS,
    METRICS_CONFIG_PERIOD_LAST_6_MONTHS,
    METRICS_CONFIG_PERIOD_LAST_12_MONTHS,
    METRICS_CONFIG_PERIOD_LAST_24_MONTHS,
    METRICS_CONFIG_PERIOD_LAST_36_MONTHS,
  ]).isRequired,
  periodicity: PropTypes.oneOf([
    METRICS_CONFIG_PERIODICITY_DAILY,
    METRICS_CONFIG_PERIODICITY_WEEKLY,
    METRICS_CONFIG_PERIODICITY_MONTHLY,
    METRICS_CONFIG_PERIODICITY_QUARTERLY,
  ]).isRequired,
  previousMetrics: PropTypes.arrayOf(PropTypes.object),
};

MetricsChart.defaultProps = {
  identifier: 'default_identifier',
};

export default MetricsChart;
