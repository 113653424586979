import styled from 'styled-components/macro';

import InfoIcon from '@/components/icons/InfoIcon';

const RedInfoIcon = styled(InfoIcon)`
  && {
    font-size: 0.7rem;
    margin-right: 1em;
    color: ${({ theme }) => theme.cssColors.red};
  }
`;

export default RedInfoIcon;
