import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import ReportIcon from '@/components/icons/ReportIcon';
import { DefaultLink } from '@/components/Navigation/Link';
import { ParagraphText } from '@/components/Text/Paragraph';
import { TitleTwo } from '@/components/Text/Title';
import { EditorEmptyBodyWrapper } from '@/containers/Content/Wrappers';
import PrimaryButton from '@/design-system/components/Button/PrimaryButton';
import Can from '@/hoc/Can';

const StyledParagraphText = styled(ParagraphText)`
  max-width: 380px;
`;

export const NO_ANALYTICS = 'noAnalytics';
export const NO_GOOGLE_ANALYTICS = 'noGoogleAnalytics';
export const NO_GOALS = 'noGoals';
export const NO_SEARCH_CONSOLE = 'noSearchConsole';
export const KPI_TEXT_VERSION = 'kpiTextVersion';
export const PRIORITIZE_TEXT_VERSION = 'prioritizeTextVersion';
export const IMPORT_PAGES_TEXT_VERSION = 'importPagesTextVersion';
export const CREATE_DRAFT_VERSION = 'createDraftTextVersion';

const NoServiceIntegration = ({ version = NO_ANALYTICS, textVersion, priorityScore }) => {
  const { t } = useTranslation();
  const { organizationId, workspaceId } = useParams();

  const ANALYTICS_EMPTYSTATE_VALUES = {
    [NO_ANALYTICS]: {
      buttonLabel: t('empty-state:no-service-integration.no-analytics.button'),
      getText: (textVersion, priorityScore) => {
        switch (textVersion) {
          case KPI_TEXT_VERSION:
            return t('empty-state:no-service-integration.no-analytics.body.kpi-version');
          case PRIORITIZE_TEXT_VERSION:
            return t('empty-state:no-service-integration.no-analytics.body.prioritize-version', {
              priorityScore: t(`${priorityScore}s`).toLowerCase(),
            });
          default:
            return t('empty-state:no-service-integration.no-analytics.body.default');
        }
      },
      href: `/o/${organizationId}/w/${workspaceId}/settings/integrations`,
      noText: t('empty-state:no-service-integration.no-analytics.no-text'),
      title: t('empty-state:no-service-integration.no-analytics.title'),
    },
    [NO_GOALS]: {
      buttonLabel: t('empty-state:no-service-integration.no-goals.button'),
      getText: (textVersion) => {
        switch (textVersion) {
          case PRIORITIZE_TEXT_VERSION:
            return t('empty-state:no-service-integration.no-goals.body.prioritize-version');
          default:
            return t('empty-state:no-service-integration.no-goals.body.defauult');
        }
      },
      href: `/o/${organizationId}/w/${workspaceId}/settings/integrations`,
      noText: t('empty-state:no-service-integration.no-goals.no-text'),
      title: t('empty-state:no-service-integration.no-goals.title'),
    },
    [NO_GOOGLE_ANALYTICS]: {
      buttonLabel: t('empty-state:no-service-integration.no-google-analytics.button'),
      getText: (textVersion) => {
        switch (textVersion) {
          case PRIORITIZE_TEXT_VERSION:
            return t(
              'empty-state:no-service-integration.no-google-analytics.body.prioritize-version'
            );
          default:
            return t('empty-state:no-service-integration.no-google-analytics.body.default');
        }
      },
      href: `/o/${organizationId}/w/${workspaceId}/settings/integrations/google_analytics`,
      noText: t('empty-state:no-service-integration.no-google-analytics.no-text'),
      title: t('empty-state:no-service-integration.no-google-analytics.title'),
    },
    [NO_SEARCH_CONSOLE]: {
      buttonLabel: t('empty-state:no-service-integration.no-search-console.button'),
      getText: (textVersion, priorityScore) => {
        switch (textVersion) {
          case KPI_TEXT_VERSION:
            return t('empty-state:no-service-integration.no-search-console.body.kpi-version');
          case PRIORITIZE_TEXT_VERSION:
            return t(
              'empty-state:no-service-integration.no-search-console.body.prioritize-version',
              { priorityScore: t(priorityScore) }
            );
          case IMPORT_PAGES_TEXT_VERSION:
            return t(
              'empty-state:no-service-integration.no-search-console.body.import-pages-version'
            );
          case CREATE_DRAFT_VERSION:
            return t(
              'empty-state:no-service-integration.no-search-console.body.create-draft-version'
            );
          default:
            return t('empty-state:no-service-integration.no-search-console.body.default');
        }
      },
      href: `/o/${organizationId}/w/${workspaceId}/settings/integrations/search_console`,
      noText: t('empty-state:no-service-integration.no-search-console.no-text'),
      title: t('empty-state:no-service-integration.no-search-console.title'),
    },
  };

  return (
    <EditorEmptyBodyWrapper>
      <ReportIcon />
      <TitleTwo weight="strong">{ANALYTICS_EMPTYSTATE_VALUES[version].title}</TitleTwo>
      <StyledParagraphText align="center" color="dark060" weight="medium">
        {ANALYTICS_EMPTYSTATE_VALUES[version].getText(textVersion, priorityScore)}
      </StyledParagraphText>
      <Can
        data={{
          accessGranted: true,
        }}
        no={() => (
          <ParagraphText color="primary" weight="strong">
            {ANALYTICS_EMPTYSTATE_VALUES[version].noText}
          </ParagraphText>
        )}
        perform="workspace-owner-settings:visit"
        yes={() => (
          <DefaultLink to={ANALYTICS_EMPTYSTATE_VALUES[version].href} weight="strong">
            <PrimaryButton weight="strong">
              {ANALYTICS_EMPTYSTATE_VALUES[version].buttonLabel}
            </PrimaryButton>
          </DefaultLink>
        )}
      />
    </EditorEmptyBodyWrapper>
  );
};

export default NoServiceIntegration;
