import { lazy, Suspense } from 'react';

import ContentLoader from '@/containers/Content/ContentLoader';

const TinyMceEditor = lazy(
  () =>
    import(
      /* webpackChunkName: "Editor" */ '@/containers/Content/TinyMceComponents/Editor/Editor.jsx'
    )
);

function Editor() {
  return (
    <Suspense fallback={<ContentLoader />}>
      <TinyMceEditor />
    </Suspense>
  );
}

export default Editor;
