import { nanoid } from 'nanoid';
import { useMemo } from 'react';

import SvgWrapper from './SvgWrapper';

function PlusAltIcon(props) {
  const svgId = useMemo(() => nanoid(), []);

  return (
    <SvgWrapper {...props} viewBox="0 0 27 27">
      <circle cx="13.5" cy="13.5" fill={`url(#${svgId})`} r="13.5" />
      <path
        d="M8 13.9231V13.0769H13.0769V8H13.9231V13.0769H19V13.9231H13.9231V19H13.0769V13.9231H8Z"
        fill="white"
        stroke="white"
        strokeWidth="0.5"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id={svgId}
          x1="5.45234e-05"
          x2="27.0049"
          y1="13.5076"
          y2="13.5076"
        >
          <stop stopColor="#FF4580" />
          <stop offset="1" stopColor="#FF4D64" />
        </linearGradient>
      </defs>
    </SvgWrapper>
  );
}

export default PlusAltIcon;
