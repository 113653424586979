import { nanoid } from 'nanoid';
import { useMemo } from 'react';

const ZoomIn = (props) => {
  const maskId = useMemo(() => nanoid(), []);
  return (
    <svg
      fill="none"
      height="17"
      viewBox="0 0 17 17"
      width="17"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        height="17"
        id={`mask${maskId}`}
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="17"
        x="0"
        y="0"
      >
        <path
          d="M16.604 15.896L11.431 10.723C12.406 9.586 13 8.113 13 6.5C13 2.916 10.084 0 6.5 0C4.763 0 3.131 0.676 1.903 1.904C0.675 3.131 0 4.764 0 6.5C0 10.084 2.916 13 6.5 13C8.112 13 9.586 12.406 10.724 11.431L15.897 16.604L16.604 15.896ZM6.5 12C3.467 12 1 9.533 1 6.5C1 5.03 1.571 3.649 2.61 2.61C3.649 1.572 5.03 1 6.5 1C9.533 1 12 3.467 12 6.5C12 9.533 9.533 12 6.5 12ZM7 6H9V7H7V9H6V7H4V6H6V4H7V6Z"
          fill="black"
        />
      </mask>
      <g mask={`url(#mask${maskId})`}>
        <rect fill="#252736" height="20" width="20" x="-2" y="-1" />
      </g>
    </svg>
  );
};

export default ZoomIn;
