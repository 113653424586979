import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ID_CONTENT,
  ID_METADESCRIPTION,
  ID_METADESCRIPTION_CONTAINER,
  ID_TITLE,
  TITLE_CONTENT_CONTAINER_SELECTOR,
} from '@/containers/Content/TinyMceComponents/Editor/constants';
import AIWritingPopper from '@/containers/Content/TinyMceComponents/Editor/hooks/useEditorPopper/AIWritingPopper';
import EditorPopper from '@/containers/Content/TinyMceComponents/Editor/hooks/useEditorPopper/EditorPopper/EditorPopper';
import { FLOATING_AI_TYPE_ENUM } from '@/containers/Content/TinyMceComponents/Editor/hooks/useEditorPopper/FloatingAI/FloatingAI.types';

const CONTENT_PLACEHOLDER_BLURRED = 'data-placeholder-blurred';
const CONTENT_PLACEHOLDER_FOCUSED = 'data-placeholder-focused';

enum EDITOR_EVENTS_ENUM {
  FOCUSIN = 'focusin',
  FOCUSOUT = 'focusout',
  NODECHANGE = 'NodeChange',
  INIT = 'init',
  KEYUP = 'keyup',
  KEYDOWN = 'keydown',
}

export function removeEditorPlaceholders(childNodes: any) {
  if (childNodes.length === 0) return;
  childNodes.forEach((childNode: any) => {
    if (!childNode?.className) return;
    if (typeof childNode.className !== 'string') return;
    if (childNode?.className?.includes('ai-placeholder')) {
      childNode.className = '';
      childNode.removeAttribute(CONTENT_PLACEHOLDER_BLURRED);
      childNode.removeAttribute(CONTENT_PLACEHOLDER_FOCUSED);
    }
    if (Array.from(childNode.childNodes).length > 0) removeEditorPlaceholders(childNode.childNodes);
  });
}

export function canOpenEditorPopper(currentLineNode: HTMLElement) {
  return !nodeIsBlockQuoted(currentLineNode) && placeholderNodeIsSelected(currentLineNode);
}

function nodeIsBlockQuoted(currentNode: HTMLElement) {
  return currentNode?.parentElement?.tagName === 'BLOCKQUOTE';
}

function placeholderNodeIsSelected(currentNode: HTMLElement) {
  return (
    currentNode?.tagName === 'P' &&
    currentNode?.childNodes?.length === 1 &&
    (currentNode?.childNodes[0] as HTMLElement)?.tagName === 'BR' &&
    currentNode?.className?.includes('ai-placeholder')
  );
}

function useEditorPopper({
  editorRef,
  aiWriting,
  editorAiWritingPopperOpen,
  setEditorAiWritingPopperOpen,
  setLockEditorScroll,
  textareaMetaDescriptionRef,
  focusTopKeywordRef,
  inputTitleRef,
}: any) {
  const [floatingAIType, setFloatingAIType] = useState<FLOATING_AI_TYPE_ENUM | false>(false);
  const [editorPopperOpen, setEditorPopperOpen] = useState(false);
  const [isTitle, setIsTitle] = useState(false);
  const [isMetaDescription, setIsMetaDescription] = useState(false);
  const { t } = useTranslation();
  const previousActiveElementRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (!editorAiWritingPopperOpen) {
      setIsTitle(false);
      setIsMetaDescription(false);
    }
  }, [editorAiWritingPopperOpen]);

  const [popperPosition, setPopperPosition] = useState<any>();

  function handleOpenEditorPopper() {
    previousActiveElementRef.current = document.activeElement as HTMLElement;
    setLockEditorScroll(true);
    const currentFocusedNode = editorRef.current.selection.getNode();
    const domRect = currentFocusedNode.getBoundingClientRect();
    const titleContentContainer = document
      ?.querySelector(TITLE_CONTENT_CONTAINER_SELECTOR)
      ?.getBoundingClientRect();

    setEditorPopperOpen(true);
    setPopperPosition({
      getBoundingClientRect: () => ({
        bottom: domRect.bottom,
        height: domRect.height,
        left: titleContentContainer?.left,
        right: titleContentContainer?.right,
        top: domRect.top,
        width: titleContentContainer?.width,
      }),
    });
  }

  function handleCloseEditorPopper() {
    editorRef.current.mode.set('design');
    inputTitleRef.current.disabled = false;
    if (textareaMetaDescriptionRef?.current) textareaMetaDescriptionRef.current.disabled = false;
    setLockEditorScroll(false);
    setEditorPopperOpen(false);
    getCorrectFocusTarget();
  }

  function getCorrectFocusTarget() {
    editorRef.current.mode.set('design');
    inputTitleRef.current.disabled = false;
    if (textareaMetaDescriptionRef?.current) textareaMetaDescriptionRef.current.disabled = false;

    // Handle focus textarea
    if (previousActiveElementRef?.current?.id === ID_METADESCRIPTION) {
      document.getElementById(ID_METADESCRIPTION_CONTAINER)?.focus();
    } else {
      previousActiveElementRef?.current?.focus();
    }
  }

  function handleEscapeCloseEditorPopper() {
    editorRef.current.mode.set('design');
    inputTitleRef.current.disabled = true;
    if (textareaMetaDescriptionRef?.current) textareaMetaDescriptionRef.current.disabled = true;
    getCorrectFocusTarget();
    setLockEditorScroll(false);
    setEditorPopperOpen(false);
  }

  function handleBackspaceCloseEditorPopper() {
    const currentLineNode = editorRef.current.selection.getNode();

    editorRef.current.mode.set('design');
    inputTitleRef.current.disabled = false;
    if (textareaMetaDescriptionRef?.current) textareaMetaDescriptionRef.current.disabled = false;

    if (previousActiveElementRef.current?.id === ID_TITLE) {
      inputTitleRef.current.value = '';
    }

    if (previousActiveElementRef.current?.id === ID_METADESCRIPTION) {
      textareaMetaDescriptionRef.current.value = '';
    }

    setLockEditorScroll(false);
    setEditorPopperOpen(false);

    getCorrectFocusTarget();
    currentLineNode?.current?.focus();
  }

  function nodeIsParagraph(currentNode: HTMLElement) {
    return currentNode.tagName === 'P';
  }

  function nodeIsTitle(currentNode: HTMLElement) {
    return ['H1', 'H2', 'H3', 'H4', 'H5', 'H6'].includes(currentNode.tagName);
  }

  function isEditorEmpty() {
    return !Boolean(editorRef.current.getContent({ format: 'text' })?.trim().replace('/', ''));
  }

  function isThereATitleInEditor() {
    const content = editorRef.current.getContent();
    return /<h[1-5]\b[^>]*>.*?<\/h[1-5]>/gi.test(content);
  }

  function handleOpenInputPopper(id: string) {
    previousActiveElementRef.current = document.activeElement as HTMLElement;
    setIsMetaDescription(id === ID_METADESCRIPTION);
    setIsTitle(id === ID_TITLE);

    const inputElement = document.getElementById(id);
    const domRect = inputElement?.getBoundingClientRect();
    const contentContainer = document
      .querySelector(TITLE_CONTENT_CONTAINER_SELECTOR)
      ?.getBoundingClientRect();
    setEditorPopperOpen(true);
    setPopperPosition({
      getBoundingClientRect: () => ({
        bottom: domRect?.bottom,
        height: domRect?.height,
        left: contentContainer?.left,
        right: contentContainer?.right,
        top: domRect?.top,
        width: contentContainer?.width,
      }),
    });
  }

  function setParagraphPlaceholder(element: HTMLElement) {
    element.className = 'ai-placeholder';
    element.setAttribute(
      CONTENT_PLACEHOLDER_BLURRED,
      t('content:editor-components.editor.add-page-content')
    );
    element.setAttribute(
      CONTENT_PLACEHOLDER_FOCUSED,
      t('content:editor-components.editor.add-page-content-ai')
    );
  }

  function setHeadingPlaceholder(element: HTMLElement) {
    element.className = 'ai-placeholder';
    element.setAttribute(
      CONTENT_PLACEHOLDER_BLURRED,
      t('content:editor-components.editor.add-page-content')
    );
    element.setAttribute(
      CONTENT_PLACEHOLDER_FOCUSED,
      t('content:editor-components.editor.heading-' + element.tagName.slice(-1))
    );
  }

  function openEditorPopper(e: any) {
    setIsTitle(false);
    setIsMetaDescription(false);
    e?.stopPropagation();
    e?.preventDefault();
    inputTitleRef.current.disabled = true;
    textareaMetaDescriptionRef.current.disabled = true;
    editorRef.current.insertContent(`/`, { format: 'text' });
    editorRef.current.mode.set('readonly');
    setLockEditorScroll(true);
    handleOpenEditorPopper();
  }

  async function handleFloatingAIContent(type: EDITOR_EVENTS_ENUM) {
    const node = editorRef.current.selection.getNode();

    if (type !== EDITOR_EVENTS_ENUM.FOCUSOUT && canOpenEditorPopper(node)) {
      setFloatingAIType(FLOATING_AI_TYPE_ENUM.CONTENT);
    } else {
      setFloatingAIType(false);
    }
  }

  function setupEditorPopper(editor: any) {
    if (!editorRef.current?.mode?.isReadOnly()) {
      editor.on(EDITOR_EVENTS_ENUM.FOCUSIN, (e: any) => {
        const currentLineNode = editorRef.current.selection.getNode();
        if (nodeIsParagraph(currentLineNode)) {
          setParagraphPlaceholder(currentLineNode);
        } else if (nodeIsTitle(currentLineNode)) {
          setHeadingPlaceholder(currentLineNode);
        }
        handleFloatingAIContent(EDITOR_EVENTS_ENUM.FOCUSIN);
      });
      editor.on(EDITOR_EVENTS_ENUM.FOCUSOUT, (e: any) => {
        const editor = document.getElementById(ID_CONTENT);
        if (!editor) return;
        if (Array.from(editor.childNodes).length > 1) {
          removeEditorPlaceholders(Array.from(editor.childNodes));
          if (
            Array.from(editor.childNodes).every(
              (childNode) =>
                Array.from(childNode.childNodes).length === 1 &&
                (childNode.childNodes[0] as HTMLElement).tagName === 'BR'
            )
          ) {
            Array.from(editor.childNodes).forEach((childNode, index) => {
              index === 0 ? setParagraphPlaceholder(childNode as HTMLElement) : childNode.remove();
            });
          }
        }
        handleFloatingAIContent(EDITOR_EVENTS_ENUM.FOCUSOUT);
      });

      editor.on(EDITOR_EVENTS_ENUM.NODECHANGE, (e: any) => {
        const editor = document.getElementById(ID_CONTENT);
        if (!editor) return;
        removeEditorPlaceholders(Array.from(editor.childNodes));
        if (
          nodeIsParagraph(e.element) &&
          Array.from(e.element.childNodes).length === 1 &&
          document.activeElement?.id === ID_CONTENT
        ) {
          setParagraphPlaceholder(e.element);
        } else if (
          nodeIsTitle(e.element) &&
          Array.from(e.element.childNodes).length === 1 &&
          document.activeElement?.id === ID_CONTENT
        ) {
          setHeadingPlaceholder(e.element);
        }

        if (Array.from(editor.childNodes as NodeListOf<HTMLElement>).length === 1) {
          const targetP = Array.from(editor.childNodes).find((childNode) =>
            nodeIsParagraph(childNode as HTMLElement)
          );
          const targetH = Array.from(editor.childNodes).find((childNode) =>
            nodeIsTitle(childNode as HTMLElement)
          );
          if (targetP && targetP?.textContent?.length === 0)
            setParagraphPlaceholder(targetP as HTMLElement);
          if (targetH && targetH?.textContent?.length === 0)
            setHeadingPlaceholder(targetH as HTMLElement);
        }
        handleFloatingAIContent(EDITOR_EVENTS_ENUM.NODECHANGE);
      });

      editor.on(EDITOR_EVENTS_ENUM.INIT, (e: any) => {
        e.target.contentAreaContainer.focus();
        const lastElement = Array.from(e.target.contentAreaContainer.childNodes)[0] as HTMLElement;
        if (lastElement) lastElement?.focus();
        setParagraphPlaceholder(
          Array.from(e.target.contentAreaContainer.childNodes)[0] as HTMLElement
        );
        handleFloatingAIContent(EDITOR_EVENTS_ENUM.INIT);
      });

      editor.on(EDITOR_EVENTS_ENUM.KEYDOWN, (e: any) => {
        const currentLineNode = editorRef.current.selection.getNode();
        // launch manual AI Generation
        if (e.key === '/' && canOpenEditorPopper(currentLineNode)) {
          openEditorPopper(e);
        }

        // Close popper fact check card on delete
        if (e.key === 'Backspace') {
          aiWriting.factCheckHook.handleForceClosePopperFactCheckCard();
        }
      });

      editor.on(EDITOR_EVENTS_ENUM.KEYUP, (e: any) => {
        handleFloatingAIContent(EDITOR_EVENTS_ENUM.KEYUP);
      });
    }
  }

  function renderEditorPopper() {
    return (
      <>
        {editorPopperOpen && popperPosition && (
          <EditorPopper
            aiWriting={aiWriting}
            editorRef={editorRef}
            focusTopKeywordRef={focusTopKeywordRef}
            handleBackspaceCloseEditorPopper={handleBackspaceCloseEditorPopper}
            handleCloseEditorPopper={handleCloseEditorPopper}
            handleEscapeCloseEditorPopper={handleEscapeCloseEditorPopper}
            hasTitle={isThereATitleInEditor()}
            isEmpty={isEditorEmpty()}
            isMetaDescription={isMetaDescription}
            isTitle={isTitle}
            position={popperPosition}
            setOpenAIWritingPopper={setEditorAiWritingPopperOpen}
          />
        )}
        {editorAiWritingPopperOpen && popperPosition && (
          <AIWritingPopper
            aiWriting={aiWriting}
            isMetaDescription={isMetaDescription}
            isTitle={isTitle}
            position={popperPosition}
          />
        )}
      </>
    );
  }

  return {
    floatingAIType,
    handleOpenInputPopper,
    openEditorPopper,
    renderEditorPopper,
    setEditorPopperOpen,
    setFloatingAIType,
    setupEditorPopper,
  };
}

export default useEditorPopper;
