import { useOutletContext } from 'react-router-dom';

import {
  CompetitorCluster,
  CompetitorPersona,
  CompetitorReport,
  CompetitorWebsite,
} from '@/types/competitors';

export type CompetitorContext = {
  competitorReportId: string;
  competitorReportDate: string;
  competitorReportDateStart: string;
  competitorComparativeDates: string[];
  competitorWebsites: CompetitorWebsite[];
  websiteReference: CompetitorWebsite;
  personas: CompetitorPersona[];
  clusters: CompetitorCluster[];
  retrievalDates: string[];
  trafficNormalizationStatus: string;
};

export type CompetitorReportsContext = {
  competitorReports: CompetitorReport[];
};

export function useCompetitor() {
  return useOutletContext<CompetitorContext>();
}

export function useCompetitorReports() {
  return useOutletContext<CompetitorReportsContext>();
}
