import styled from 'styled-components/macro';

const Text = styled.span`
  font-family: ${(props) => props.theme.textCss.fonts.main};
  color: ${(props) => props.theme.cssColors[props.color || 'dark100']};
  font-size: ${(props) => props.theme.textCss.sizes[props.size || 'sm']};
  font-weight: ${(props) => props.theme.textCss.weights[props.weight || 'normal']};
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${(props) => props.theme.mediaQueries.largeDesktop} {
    font-size: ${(props) => props.theme.textCss.sizes[props.size || 'default']};
  }
`;
export const TextAuto = styled.div`
  font-family: ${(props) => props.theme.textCss.fonts.main};
  color: ${(props) => props.theme.cssColors[props.color || 'dark100']};
  font-size: ${(props) => props.theme.textCss.sizes[props.size || 'sm']};
  font-weight: ${(props) => props.theme.textCss.weights[props.weight || 'normal']};
  line-height: 24px;
  overflow: auto;
  word-wrap: break-word;
  ${(props) => props.theme.mediaQueries.largeDesktop} {
    font-size: ${(props) => props.theme.textCss.sizes[props.size || 'default']};
  }
`;

export const InlineText = styled(Text)`
  display: inline;
`;

export const BlockText = styled(Text)`
  display: block;
`;
