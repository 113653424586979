import TextField from '@material-ui/core/TextField';
import trimEnd from 'lodash/trimEnd';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DialogModal } from 'semji-core/components/DialogModal';

import DatePicker from '@/components/DatePicker';
import { Text as TextClassic } from '@/components/Message/common';
import HelperMessage from '@/components/Message/HelperMessage';
import WarningMessage from '@/components/Message/WarningMessage';
import { DefaultLink, SecondaryLink } from '@/components/Navigation/Link';
import { Info } from '@/components/Text/Info';
import { Warning } from '@/components/Text/Warning';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import { Content, Title } from '@/design-system/components/Dialog';
import Text from '@/design-system/components/Text';
import useCan from '@/hooks/useCan';
import { setMarkAsPublishedHelperMessageEnabled } from '@/store/actions/userConfiguration';
import { selectUserConfiguration } from '@/store/selectors/selectUserConfiguration';
import defaultTheme from '@/themes/defaultTheme';
import { SUPPORT_ADDRESS_MAIL } from '@/utils/constants';
import { isUrlValidWithWebSiteUrl } from '@/utils/url';
const DEFAULT_TRACKED_KEYWORDS_LIMIT = 500;
const MIN_DRAFT_SCORE = 75;

export default function MarkAsPublishedDialog({
  confirmPublishContent,
  createdAt: createdAtProps,
  currentWorkspaceWebsiteUrl,
  error,
  handleCancel,
  loading,
  score,
  show,
  title,
  url: urlProps,
  trackedKeywordsLimitExceeded = false,
  trackedKeywordsLimit = DEFAULT_TRACKED_KEYWORDS_LIMIT,
}) {
  const today = DateTime.fromJSDate(new Date()).toISODate();
  const { t } = useTranslation();
  const [url, setUrl] = useState(urlProps || '');
  const [publishDate, setPublishDate] = useState(today);
  const isRankTrackingEnabled = useCan({ perform: 'rank-tracking:enabled' });
  const dispatch = useDispatch();
  const { markAsPublishedHelperMessageEnabled } = useSelector(selectUserConfiguration);

  function getErrorText(error) {
    if (error && error.status === 409) {
      return t('components:dialog.mark-as-published-dialog.error.already-have-page');
    }
    if (error || (url && !isUrlValidWithWebSiteUrl(url, currentWorkspaceWebsiteUrl))) {
      return t('components:dialog.mark-as-published-dialog.error.invalid-url');
    }
    return '';
  }

  function getDateError() {
    const createdAt = createdAtProps
      ? DateTime.fromISO(createdAtProps).startOf('day').toMillis()
      : DateTime.fromISO(today).startOf('day').toMillis();

    if (
      DateTime.fromISO(publishDate).startOf('day').toMillis() >
        DateTime.fromISO(today).startOf('day').toMillis() ||
      DateTime.fromISO(publishDate).startOf('day').toMillis() < createdAt
    ) {
      return t('components:dialog.mark-as-published-dialog.error.selected-date');
    }
    return '';
  }

  function onCancel() {
    setUrl(urlProps);

    return handleCancel();
  }

  function onUrlChange(event) {
    setUrl(event.target.value);
  }

  function onClick() {
    confirmPublishContent(url, DateTime.fromISO(publishDate).startOf('day').toISODate());
  }
  const handleClickCloseHelperMessage = () => {
    dispatch(setMarkAsPublishedHelperMessageEnabled(!markAsPublishedHelperMessageEnabled));
  };

  return (
    <DialogModal
      cancelAction={onCancel}
      cancelLabel={t('components:dialog.mark-as-published-dialog.cancel')}
      canClickOutsideToClose
      closable
      confirmAction={onClick}
      confirmLabel={
        loading
          ? t('components:dialog.mark-as-published-dialog.publishing')
          : t('components:dialog.mark-as-published-dialog.mark-as-published')
      }
      error={
        error && (
          <Info>{t('components:dialog.mark-as-published-dialog.error.an-error-occurred')}</Info>
        )
      }
      fullWidth
      id="form-dialog-title"
      isCancelDisabled={loading}
      isConfirmDisabled={loading || !isUrlValidWithWebSiteUrl(url, currentWorkspaceWebsiteUrl)}
      isOpen={Boolean(show)}
      title={
        <Title id="form-dialog-title">
          {t('components:dialog.mark-as-published-dialog.title')}
        </Title>
      }
      onClose={onCancel}
    >
      {' '}
      <Content id="dialog-description">
        {markAsPublishedHelperMessageEnabled && (
          <HelperMessage
            handleClick={handleClickCloseHelperMessage}
            margin="0 0 16px 0"
            withCloseBtn
          >
            <Trans
              components={{
                bold: <strong />,
                primaryLink: (
                  <DefaultLink
                    color="dark080"
                    decoration
                    isExternal
                    size="sm"
                    to={t('components:dialog.mark-as-published-dialog.help-center-url')}
                    weight="medium"
                  />
                ),
              }}
              i18nKey={'components:dialog.mark-as-published-dialog.helper-message'}
            />
          </HelperMessage>
        )}
        {Math.round(score * 100) < MIN_DRAFT_SCORE && (
          <WarningMessage
            margin={`0 0 ${
              isRankTrackingEnabled && trackedKeywordsLimitExceeded ? '16px' : '32px'
            } 0`}
          >
            <Trans
              components={{
                primaryLink: (
                  <DefaultLink
                    color="dark080"
                    decoration
                    isExternal
                    size="sm"
                    to={t('components:dialog.mark-as-published-dialog.recommendation-score-url')}
                    weight="medium"
                  />
                ),
              }}
              i18nKey={'components:dialog.mark-as-published-dialog.recommendation-score'}
              values={{ minDraftScore: MIN_DRAFT_SCORE }}
            />
          </WarningMessage>
        )}
        {isRankTrackingEnabled && trackedKeywordsLimitExceeded && (
          <WarningMessage color={defaultTheme.cssColors.primary005} margin="0 0 32px 0">
            <TextClassic size="sm" weight="medium">
              <Trans
                components={{ strong: <strong /> }}
                i18nKey={'components:dialog.mark-as-published-dialog.tracked-keyword-limit'}
                values={{ limit: trackedKeywordsLimit }}
              />
            </TextClassic>
            <TooltipComponent
              title={t('components:dialog.mark-as-published-dialog.untracked-keyword')}
            ></TooltipComponent>
            <DefaultLink
              color="primary"
              decoration
              isExternal
              size="sm"
              to={`mailto:${SUPPORT_ADDRESS_MAIL}`}
              weight="medium"
            >
              {t('components:dialog.mark-as-published-dialog.contact-us')}
            </DefaultLink>
          </WarningMessage>
        )}
        {!urlProps ? (
          <>
            <Text>{t('components:dialog.mark-as-published-dialog.new-content')}</Text>
            <Text fontWeight="medium">
              {t('components:dialog.mark-as-published-dialog.explanation-text')}
            </Text>

            <Text
              color="dark100"
              margin="15px 0 0 0"
              title={title || t('common:labels.unknown-title')}
            >
              {title || t('common:labels.unknown-title')}
            </Text>
            <TextField
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              error={Boolean(
                error || (url && !isUrlValidWithWebSiteUrl(url, currentWorkspaceWebsiteUrl))
              )}
              fullWidth
              helperText={getErrorText(error)}
              id="url"
              placeholder={`${trimEnd(currentWorkspaceWebsiteUrl, '/')}/page`}
              required
              type="url"
              value={url}
              onChange={onUrlChange}
            />
          </>
        ) : (
          <>
            <Text>{t('components:dialog.mark-as-published-dialog.existing-content')}</Text>
            <Text
              color="dark100"
              margin="15px 0 0 0"
              title={title || t('common:labels.unknown-title')}
            >
              {title || t('common:labels.unknown-title')}
            </Text>
            <SecondaryLink isExternal to={urlProps}>
              {urlProps}
            </SecondaryLink>
          </>
        )}
        <div>
          <Text margin="30px 0 0 0">
            {t('components:dialog.mark-as-published-dialog.publication-date')}
          </Text>
          <DatePicker dueDate={publishDate} handleChange={setPublishDate} inputMode margin="none" />
        </div>
        {getDateError() && <Warning width="200px">{getDateError()}</Warning>}
      </Content>
    </DialogModal>
  );
}
