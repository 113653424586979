import './DiffEmptyState.scss';

import React from 'react';

import FlatLoader, { LoaderWrapper } from '@/components/Loader/FlatLoader';

export function DiffEmptyState() {
  return (
    <div className="diff-empty-state">
      <div className="diff-empty-state__left-content">
        <div className="diff-empty-state__loader">
          <DiffEmptyStateLoader />
        </div>
      </div>
      <div className="diff-empty-state__right-content">
        <div className="diff-empty-state__loader">
          <DiffEmptyStateLoader />
        </div>
      </div>
    </div>
  );
}

function DiffEmptyStateLoader() {
  return (
    <>
      <div className="diff-empty-state__section-1">
        <LoaderWrapper height={15} width="65%">
          <FlatLoader />
        </LoaderWrapper>
      </div>

      <div className="diff-empty-state__section-2">
        <LoaderWrapper height={15} width="85%">
          <FlatLoader />
        </LoaderWrapper>
        <LoaderWrapper height={15} width="100%">
          <FlatLoader />
        </LoaderWrapper>
        <LoaderWrapper height={15} width="65%">
          <FlatLoader />
        </LoaderWrapper>
        <LoaderWrapper height={15} width="80%">
          <FlatLoader />
        </LoaderWrapper>
        <LoaderWrapper height={15} width="35%">
          <FlatLoader />
        </LoaderWrapper>
      </div>

      <div className="diff-empty-state__section-3">
        <LoaderWrapper height={15} width="85%">
          <FlatLoader />
        </LoaderWrapper>
        <LoaderWrapper height={15} width="100%">
          <FlatLoader />
        </LoaderWrapper>
        <LoaderWrapper height={15} width="65%">
          <FlatLoader />
        </LoaderWrapper>
        <LoaderWrapper height={15} width="80%">
          <FlatLoader />
        </LoaderWrapper>
        <LoaderWrapper height={15} width="35%">
          <FlatLoader />
        </LoaderWrapper>
      </div>
    </>
  );
}

export function DiffEmptyStateFooter() {
  return (
    <>
      <LoaderWrapper height={15} width={68}>
        <FlatLoader />
      </LoaderWrapper>
      <LoaderWrapper height={15} width={68}>
        <FlatLoader />
      </LoaderWrapper>
    </>
  );
}
