import { nanoid } from 'nanoid';
import React from 'react';
import { useMemo } from 'react';

import SvgWrapper from './SvgWrapper';

function PenIcon(props: any) {
  const maskId = useMemo(() => nanoid(), []);
  return (
    <SvgWrapper
      {...props}
      fill="none"
      height="17"
      style={{ fontSize: 18 }}
      viewBox="0 0 18 17"
      width="18"
    >
      <mask
        height="17"
        id={`mask${maskId}`}
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="12"
        x="3"
        y="0"
      >
        <path
          d="M3 0.5V6.999L8.466 16.498L14 7V0.5H3V0.5ZM8.471 14.5H8.473L8.472 14.502L8.471 14.5ZM8.5 6.5C7.948 6.5 7.5 6.051 7.5 5.5C7.5 4.949 7.948 4.5 8.5 4.5C9.052 4.5 9.5 4.949 9.5 5.5C9.5 6.051 9.052 6.5 8.5 6.5ZM13 6.73L9 13.596V7.429C9.86 7.206 10.5 6.429 10.5 5.5C10.5 4.397 9.603 3.5 8.5 3.5C7.397 3.5 6.5 4.397 6.5 5.5C6.5 6.429 7.14 7.206 8 7.429V13.683L4 6.731V1.5H13V6.73Z"
          fill="currentColor"
        />
      </mask>
      <g mask={`url(#mask${maskId})`}>
        <rect fill="currentColor" height="20" width="20" x="-2" y="-0.5" />
      </g>
    </SvgWrapper>
  );
}

export default PenIcon;
