import { useNavigate, useParams } from 'react-router-dom';

export function useDiffContent() {
  const navigate = useNavigate();
  const { workspaceId, organizationId, pageId, contentId } = useParams();

  function handleCompareClick({ from, to }: { from: string | undefined; to: string | undefined }) {
    navigate(
      `/o/${organizationId}/w/${workspaceId}/p/${pageId}/content/${contentId}/compare?from=${from || contentId}&to=${to ?? ''}`
    );
  }

  return {
    handleCompareClick,
  };
}
