import styled from 'styled-components/macro';

import Column from '@/containers/Content/BriefComponents/Table/Columns/Column';

const SecondaryColumn = styled(Column)`
  width: calc(100% / 3);

  & > img {
    margin-right: 5px;
  }
`;

export default SecondaryColumn;
