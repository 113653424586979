import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import ImgErrorAnalysis from '@/assets/images/img-error-analysis.svg';
import { FlexColumnContainer } from '@/components/_common/index';
import { ActionButton } from '@/components/Button/Button';
import NoServiceIntegration, {
  KPI_TEXT_VERSION,
  NO_ANALYTICS,
  NO_SEARCH_CONSOLE,
} from '@/components/EmptyState/NoServiceIntegration';
import UpgradePlan, { ENUM_UPGRADE_PLAN_VARIANT } from '@/components/EmptyState/UpgradePlan';
import ColoredReportIcon from '@/components/icons/ColoredReportIcon';
import RetrieveLoader from '@/components/Loader/RetrieveLoader';
import { buildMetricFeatureSetFlag } from '@/components/Metrics/helpers';
import { ContainedLinkButton } from '@/components/Navigation/Link';
import { ParagraphText } from '@/components/Text/Paragraph';
import { PrimaryTitle } from '@/components/Text/Title';
import { TitleTwo } from '@/components/Text/Title';
import MetricsChart from '@/containers/Chart/MetricsChart';
import MetricsDateConfigSelector from '@/containers/Chart/MetricsDateConfigSelector';
import MetricPositionTabs from '@/containers/Content/SidePanel/Components/Performance/Section/MetricPositionTabs';
import MetricSelector from '@/containers/Content/SidePanel/Components/Performance/Section/MetricSelector';
import { PageNotPublishedStatsPanelBody } from '@/containers/Content/SidePanel/Components/Performance/Section/PageNotPublishedStatsPanelBody';
import { EditorEmptyBodyWrapper } from '@/containers/Content/Wrappers';
import { ReportMetricKey } from '@/containers/Report/utils/types';
import Flex from '@/design-system/components/Flex';
import Select from '@/design-system/components/Select';
import { OrganizationFeatureSet } from '@/hoc/OrganizationFeatureSet';
import { useMetricGoalsConfig } from '@/hooks/useMetricGoalsConfig';
import { useMetricsConfig } from '@/hooks/useMetricsConfig';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import PageMetricsService from '@/services/PageMetrics';
import { selectHasAnalytics } from '@/store/selectors/selectHasAnalytics';
import { selectHasSearchConsole } from '@/store/selectors/selectHasSearchConsole';
import { selectUserConfiguration } from '@/store/selectors/selectUserConfiguration';
import { selectWorkspaceConfig } from '@/store/selectors/selectWorkspaceConfig';
import { SUPPORT_ADDRESS_MAIL } from '@/utils/constants';
import { Log } from '@/utils/log/Log';
import {
  ANALYTICS_DEPENDENCY,
  comparisonPeriods,
  FOCUS_TOP_KEYWORD_POSITION,
  METRIC_POSITION_KEY,
  METRIC_STACKING_TYPE_NORMAL,
  METRICS_CONFIG_PERIODICITY_DAILY,
  METRICS_CONFIG_PERIODICITY_MONTHLY,
  METRICS_CONFIG_PERIODICITY_QUARTERLY,
  METRICS_CONFIG_PERIODICITY_WEEKLY,
  PLOT_LINE_PUBLICATION_TYPE,
  RANKING_KEYWORDS_METRICS,
  SEARCH_CONSOLE_DEPENDENCY,
  TOOLTIP_METRIC_POSITION_CENTER,
  TOOLTIP_METRIC_POSITION_LEFT,
  TOOLTIP_METRIC_POSITION_RIGHT,
  TOP_100_TAB,
} from '@/utils/metrics/constants';
import { getCurrentDateRange } from '@/utils/metrics/getCurrentDateRange';
import { getCurrentDateRangeSQLFormat } from '@/utils/metrics/getCurrentDateRangeSQLFormat';
import { getPreviousDateRangeSQLFormat } from '@/utils/metrics/getPreviousDateRangeSQLFormat';

const StyledEditorEmptyBodyWrapper = styled(EditorEmptyBodyWrapper)`
  gap: 32px;
`;

const EmptyRankTrackingWrapper = styled(Flex)`
  text-align: center;
`;

const SelectWrapper = styled.div`
  margin-top: 10px;
`;

const ChartWrapper = styled.div`
  display: flex;
  height: 400px;
`;

const Wrapper = styled.div`
  padding-bottom: 1rem;
`;

function MainMetricsEvolution({ page, pageId, showMarkAsPublishedDialog }) {
  const { t } = useTranslation();
  const { period, periodicity, comparisonPeriod } = useSelector(selectWorkspaceConfig);
  const { pageContents } = useSelector((state) => state.editor);
  const { currentMetricKey } = useSelector(selectUserConfiguration);

  const [goalKey, setGoalKey] = useState(null);
  const hasSearchConsole = useSelector(selectHasSearchConsole);
  const hasAnalytics = useSelector(selectHasAnalytics);
  const { organizationId } = useParams();
  const currentOrganizationTrackedPages = useSelector(
    (state) => state.organizations.byId[organizationId]?.trackedPages
  );
  const metrics = useMetricsConfig({ isNew: true });
  const goals = useMetricGoalsConfig(currentMetricKey);
  const [pageMetrics, setPageMetrics] = useState({
    current: [],
    previous: [],
  });
  const [loading, setLoading] = useState(true);
  const [activeSubTab, setActiveSubTab] = useState(FOCUS_TOP_KEYWORD_POSITION);
  const { isFeatureEnabled: hasUnlimitedRankTrackingUrls } = useOrganizationFeatureSet(
    'rank-tracking:urls-tracked-number:has-unlimited-amount'
  );
  const { data: rankTrackingLimit } = useOrganizationFeatureSet(
    'rank-tracking:urls-tracked-number'
  );

  const hasRemainingTrackedPages =
    currentOrganizationTrackedPages < rankTrackingLimit || hasUnlimitedRankTrackingUrls;

  const fetchPageMetrics = async () => {
    const currentDateRange = getCurrentDateRangeSQLFormat({ period });
    const previousDateRange = getPreviousDateRangeSQLFormat({
      comparisonPeriod:
        currentMetricKey === METRIC_POSITION_KEY
          ? comparisonPeriods.METRICS_CONFIG_COMPARISON_PERIOD_PREVIOUS_RANGE.key
          : comparisonPeriod,
      period,
    });
    const _CurrentPageMetricsService = new PageMetricsService(
      pageId,
      currentDateRange,
      periodicity
    );
    const _PreviousPageMetricsService = new PageMetricsService(
      pageId,
      previousDateRange,
      periodicity
    );

    try {
      const pageMetrics = await Promise.all([
        _CurrentPageMetricsService.pageMetrics,
        _PreviousPageMetricsService.pageMetrics,
      ]);

      setPageMetrics({
        current: pageMetrics[0],
        previous: pageMetrics[1],
      });
      setLoading(false);
    } catch (e) {
      Log.error({ e });
    }
  };

  useEffect(() => {
    fetchPageMetrics();
    return () => {
      // when we destroy this component we need to reset the cache of pageMetrics
      // pageMetrics data are used for the trends cell
      new PageMetricsService().clearLocalCache();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period, comparisonPeriod, periodicity]);

  useEffect(() => {
    if (currentMetricKey !== METRIC_POSITION_KEY) {
      setActiveSubTab(null);
    } else if (!activeSubTab) {
      setActiveSubTab(FOCUS_TOP_KEYWORD_POSITION);
    }
  }, [currentMetricKey, activeSubTab]);

  function getMarkerPosition(markerValue, start, end) {
    if (markerValue < start + (end - start) / 3) return TOOLTIP_METRIC_POSITION_LEFT;
    if (markerValue > start + (2 * (end - start)) / 3) return TOOLTIP_METRIC_POSITION_RIGHT;
    return TOOLTIP_METRIC_POSITION_CENTER;
  }

  function groupMarkersByPeriodicity(periodicity, markers) {
    const dateRange = getCurrentDateRange({ period });
    const groupedMarkers = {};
    switch (periodicity) {
      case METRICS_CONFIG_PERIODICITY_DAILY:
        markers.forEach((marker) => {
          if (!groupedMarkers[`${marker.date.year}-${marker.date.month}-${marker.date.day}`]) {
            groupedMarkers[`${marker.date.year}-${marker.date.month}-${marker.date.day}`] = [];
          }
          groupedMarkers[`${marker.date.year}-${marker.date.month}-${marker.date.day}`].push(
            marker
          );
        });
        break;
      case METRICS_CONFIG_PERIODICITY_WEEKLY:
        markers.forEach((marker) => {
          if (!groupedMarkers[`${marker.date.year}-${marker.date.weekNumber}`]) {
            groupedMarkers[`${marker.date.year}-${marker.date.weekNumber}`] = [];
          }
          groupedMarkers[`${marker.date.year}-${marker.date.weekNumber}`].push(marker);
        });
        break;
      case METRICS_CONFIG_PERIODICITY_MONTHLY:
        markers.forEach((marker) => {
          if (!groupedMarkers[`${marker.date.year}-${marker.date.month}`]) {
            groupedMarkers[`${marker.date.year}-${marker.date.month}`] = [];
          }
          groupedMarkers[`${marker.date.year}-${marker.date.month}`].push(marker);
        });
        break;
      case METRICS_CONFIG_PERIODICITY_QUARTERLY:
        markers.forEach((marker) => {
          if (!groupedMarkers[`${marker.date.year}-${marker.date.quarter}`]) {
            groupedMarkers[`${marker.date.year}-${marker.date.quarter}`] = [];
          }
          groupedMarkers[`${marker.date.year}-${marker.date.quarter}`].push(marker);
        });
        break;
      default:
    }
    return Object.entries(groupedMarkers).map(([key, periodMarkers]) => {
      const markerToShow = periodMarkers.sort((a, b) => b.date.day - a.date.day)[0];
      return {
        count: periodMarkers.length,
        groupedBy: periodMarkers.length > 1 && periodicity,
        label:
          periodMarkers.length > 1 ? 'chart:plotline.label-publication-count' : markerToShow.label,
        tooltipPosition: getMarkerPosition(markerToShow.x, dateRange.from, dateRange.to),
        type: PLOT_LINE_PUBLICATION_TYPE,
        x: markerToShow.x,
      };
    });
  }

  const buildMarkers = (pageContents, period, periodicity) => {
    const markers = [];
    pageContents
      .filter((pageContent) => pageContent.type === 'PUBLISHED')
      .forEach((pageContent) => {
        const publicationDate = DateTime.fromISO(pageContent.publishedAt);
        const isLastPublication = page.extra.lastPublishedAt === pageContent.publishedAt;
        const dateRange = getCurrentDateRange({ period });

        if (dateRange.from <= publicationDate && dateRange.to >= publicationDate) {
          markers.push({
            date: publicationDate,
            label: isLastPublication
              ? 'chart:plotline.label-last-publication'
              : 'chart:plotline.label-publication-date',
            type: PLOT_LINE_PUBLICATION_TYPE,
            x: publicationDate.toMillis(),
          });
        }
      });
    return groupMarkersByPeriodicity(periodicity, markers);
  };

  const isPositionMetric = currentMetricKey === METRIC_POSITION_KEY;
  const isAnalyticsDependent = metrics[currentMetricKey]?.dependency === ANALYTICS_DEPENDENCY;
  const isSearchConsoleDependent =
    metrics[currentMetricKey]?.dependency === SEARCH_CONSOLE_DEPENDENCY;
  const isAnalyticsEmptyState = !hasAnalytics && isAnalyticsDependent;
  const isSearchConsoleEmptyState = !hasSearchConsole && isSearchConsoleDependent;
  const isRetrievingSearchConsoleData =
    hasSearchConsole && isSearchConsoleDependent && !page.extra.searchConsoleMetricsRetrievedAt;
  const isRetrievingAnalyticsData =
    hasAnalytics && isAnalyticsDependent && !page.extra.analyticMetricsRetrievedAt;

  return (
    <Wrapper>
      <Flex marginTop="0.5em">
        <PrimaryTitle noMargin size="normal">
          {t('content:side-panel-components.performance.section.main-metrics-evolution.title')}
        </PrimaryTitle>
      </Flex>
      <Flex
        alignItems="center"
        flex={1}
        justifyContent="space-between"
        marginBottom="1em"
        marginTop="1em"
      >
        <MetricsDateConfigSelector
          hideComparisonPeriods={currentMetricKey === METRIC_POSITION_KEY}
        />
      </Flex>
      <MetricSelector
        forceSelectedPositionKey={
          activeSubTab === TOP_100_TAB ? RANKING_KEYWORDS_METRICS : METRIC_POSITION_KEY
        }
        goalKey={goalKey}
        isPublished={page.extra.lastPublishedAt}
        isRetrievingAnalyticsData={isRetrievingAnalyticsData}
        isRetrievingSearchConsoleData={isRetrievingSearchConsoleData}
        metrics={pageMetrics.current}
        previousMetrics={pageMetrics.previous}
        setGoalKey={setGoalKey}
      />
      <ChartWrapper>
        <OrganizationFeatureSet
          feature={buildMetricFeatureSetFlag({
            category: metrics[currentMetricKey].category,
            goalKey,
            metricKey: currentMetricKey,
          })}
          no={({ upperPlan }) => (
            <UpgradePlan plan={upperPlan} variant={ENUM_UPGRADE_PLAN_VARIANT.SMALL} />
          )}
          yes={() => {
            const key = goalKey ?? currentMetricKey;
            if (isAnalyticsEmptyState) {
              return <NoServiceIntegration textVersion={KPI_TEXT_VERSION} version={NO_ANALYTICS} />;
            }
            if (isSearchConsoleEmptyState) {
              return (
                <NoServiceIntegration textVersion={KPI_TEXT_VERSION} version={NO_SEARCH_CONSOLE} />
              );
            }
            if (isRetrievingSearchConsoleData) {
              return (
                <RetrieveLoader>
                  {t(
                    'content:side-panel-components.performance.section.main-metrics-evolution.retrieve-loader-search-console'
                  )}
                </RetrieveLoader>
              );
            }
            if (isRetrievingAnalyticsData) {
              return (
                <RetrieveLoader>
                  {t(
                    'content:side-panel-components.performance.section.main-metrics-evolution.retrieve-loader-analytics'
                  )}
                </RetrieveLoader>
              );
            }
            if (
              key === METRIC_POSITION_KEY &&
              !page.extra.lastPublishedAt &&
              hasRemainingTrackedPages
            ) {
              return (
                <PageNotPublishedStatsPanelBody
                  showMarkAsPublishedDialog={showMarkAsPublishedDialog}
                />
              );
            }

            if (key === METRIC_POSITION_KEY) {
              if (!page?.extra?.lastPublishedAt) {
                return (
                  <EmptyRankTrackingWrapper
                    alignItems="center"
                    flex={1}
                    flexDirection="column"
                    justifyContent="center"
                    textAlign="center"
                  >
                    <img
                      alt={t(
                        'content:side-panel-components.performance.page-not-published-modal.alt-draft'
                      )}
                      src={ImgErrorAnalysis}
                      width="100"
                    />
                    <div>
                      <TitleTwo weight="strong">
                        {t(
                          'content:side-panel-components.performance.page-not-published-modal.title'
                        )}
                      </TitleTwo>
                      <ParagraphText align="center" color="dark060" weight="medium">
                        {t(
                          'content:side-panel-components.performance.page-not-published-modal.body-rank-tracking'
                        )}
                      </ParagraphText>
                    </div>
                    <ActionButton onClick={showMarkAsPublishedDialog}>
                      {t(
                        'content:side-panel-components.performance.page-not-published-modal.button'
                      )}
                    </ActionButton>
                  </EmptyRankTrackingWrapper>
                );
              }
              if (page.extra.lastPublishedAt && !page.isTracked) {
                return (
                  <StyledEditorEmptyBodyWrapper>
                    <ColoredReportIcon />
                    <div>
                      <TitleTwo weight="strong">
                        {t(
                          'content:side-panel-components.performance.tracked-keyword-limit-title',
                          {
                            limit: rankTrackingLimit,
                          }
                        )}
                      </TitleTwo>
                      <ParagraphText align="center" color="dark060" weight="medium">
                        {t('content:side-panel-components.performance.tracked-keyword-limit-text')}
                      </ParagraphText>
                    </div>
                    <ContainedLinkButton to={`mailto:${SUPPORT_ADDRESS_MAIL}`}>
                      {t('content:side-panel-components.performance.contact-us')}
                    </ContainedLinkButton>
                  </StyledEditorEmptyBodyWrapper>
                );
              }
            }

            return (
              <FlexColumnContainer>
                {!isAnalyticsEmptyState && Object.keys(goals).length > 1 && (
                  <OrganizationFeatureSet
                    feature="pages:priority-score:has-access-to-conversions"
                    yes={() => (
                      <SelectWrapper>
                        <Select
                          key={currentMetricKey}
                          options={Object.keys(goals).map((key) => ({
                            name: t(goals[key].name),
                            value: key,
                          }))}
                          useDefaultValue
                          onChange={(goalKey) => {
                            setGoalKey(goalKey ? goalKey : null);
                          }}
                        />
                      </SelectWrapper>
                    )}
                  />
                )}
                {!loading && (
                  <MetricsChart
                    comparisonPeriod={comparisonPeriod}
                    currentMetricKey={key}
                    displayedMetrics={{
                      [key]: {
                        ...metrics[key],
                        previousVisible: true,
                        ...(key === ReportMetricKey.AverageContentScore
                          ? { name: 'report:chart.metric-tabs.publicationsContentScore' }
                          : {}),
                      },
                    }}
                    forceSelectedPositionKey={
                      activeSubTab === TOP_100_TAB
                        ? RANKING_KEYWORDS_METRICS
                        : FOCUS_TOP_KEYWORD_POSITION
                    }
                    identifier="editor_side_panel"
                    legendLeft={true}
                    markers={buildMarkers(pageContents, period, periodicity)}
                    metrics={pageMetrics.current}
                    multipleMetricsKey={
                      isPositionMetric && activeSubTab === TOP_100_TAB
                        ? RANKING_KEYWORDS_METRICS
                        : null
                    }
                    period={period}
                    periodicity={periodicity}
                    previousMetrics={key === METRIC_POSITION_KEY ? [] : pageMetrics.previous}
                    stacked={
                      isPositionMetric && activeSubTab === TOP_100_TAB
                        ? METRIC_STACKING_TYPE_NORMAL
                        : null
                    }
                    yAxisReversed={isPositionMetric && activeSubTab === FOCUS_TOP_KEYWORD_POSITION}
                  >
                    {isPositionMetric && (
                      <MetricPositionTabs
                        activeTab={activeSubTab}
                        setActiveSubTab={setActiveSubTab}
                      />
                    )}
                  </MetricsChart>
                )}
              </FlexColumnContainer>
            );
          }}
        />
      </ChartWrapper>
    </Wrapper>
  );
}

MainMetricsEvolution.propTypes = { page: PropTypes.object, pageId: PropTypes.string };

export default MainMetricsEvolution;
