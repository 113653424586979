import Radio from '@material-ui/core/Radio';
import styled from 'styled-components/macro';

import { InlineText } from '@/components/Text/Inline';

const RadioButton = styled(Radio)`
  && {
    box-sizing: border-box;
    padding: 0;
    width: 20px;
    height: 20px;
  }
`;

const TextCheckButton = styled.div`
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  background: white;
  min-width: 100px;
  max-width: 200px;
  padding: 1.5rem 2rem;
  margin: 0.5rem 1rem 0.5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-top: 4px solid transparent;
  border-bottom: ${({ checked, theme }) =>
    checked ? `4px solid ${theme.cssColors.primary}` : '4px solid transparent'};
  cursor: pointer;

  &:hover {
    box-shadow: ${({ theme }) => `0 0 15px ${theme.cssColors.primary040}`};
  }
`;

const Card = styled.div`
  width: 340px;
  height: 360px;
  padding: 16px 32px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: ${({ checked, theme }) =>
    checked ? theme.cssColors.primary002 : theme.cssColors.white};
  border-radius: 3px;
  border: 0.5px solid
    ${({ checked, theme }) => (checked ? theme.cssColors.primary : theme.cssColors.dark020)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    box-shadow: 0px 3px 15px ${({ theme }) => theme.cssColors.primary020};
    border: 0.5px solid ${({ theme }) => theme.cssColors.primary};
  }
`;

const Header = styled.div`
  box-sizing: border-box;
  height: 20px;
  align-items: center;
  display: flex;
  justify-content: flex-end;
`;

const IconContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  & > svg {
    height: 50%;
    width: 50%;
  }
`;

const Content = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 45%;
`;

const Title = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.cssColors.dark100};
  font-size: ${({ theme }) => theme.textCss.sizes.lg};
  font-weight: ${({ theme }) => theme.textCss.weights.medium};
`;

const Description = styled.div`
  color: ${({ theme }) => theme.cssColors.dark060};
  font-size: ${({ theme }) => theme.textCss.sizes.default};
  line-height: ${({ theme }) => theme.textCss.sizes.lg};
  text-align: center;
`;

export default function StepCheckbox({
  title,
  description,
  checked,
  children,
  disabled,
  disabledWarningMessage,
  ...props
}) {
  return (
    <Card {...props} checked={checked} disabled={disabled}>
      <Header>{checked && <RadioButton checked />}</Header>
      <IconContainer>{children}</IconContainer>
      <Content>
        <Title checked={checked}>{title}</Title>
        <Description>{description}</Description>
      </Content>
      {disabled && disabledWarningMessage}
    </Card>
  );
}

export function TextCheckbox({ text, checked, ...props }) {
  return (
    <TextCheckButton {...props} checked={checked}>
      <InlineText color="dark080" weight="medium">
        {text}
      </InlineText>
    </TextCheckButton>
  );
}
