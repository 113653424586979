import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import SemjiCredit from '@/components/icons/SemjiCreditIcon';
import ThreeDotsIcon from '@/components/icons/ThreeDotsIcon';
import TooltipComponent from '@/components/Tooltip/Tooltip';

const Wrapper = styled.div`
  && {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    overflow: hidden;
    cursor: pointer;
    background: linear-gradient(rgba(255, 255, 255, 0.333) 10%, rgb(255, 255, 255, 0.8) 100%);
  }
`;

export const Loader = styled(ThreeDotsIcon)`
  && {
    font-size: ${({ theme }) => theme.textCss.sizes.xl};
    color: ${({ theme }) => theme.cssColors.dark040};
  }
`;

const Tooltip = styled(TooltipComponent)`
  && {
    display: flex;
    max-width: 70%;
    color: ${({ theme }) => theme.cssColors.primary};
    font-size: ${({ theme }) => theme.textCss.sizes.sm};
  }
`;

const Text = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const SemjiCreditIcon = styled(SemjiCredit)`
  && {
    margin-right: 5px;
    font-size: ${({ theme }) => theme.textCss.sizes.default};
  }
`;

function UnlockRecommendationsOverlay({ isLoading, message, onClick, tooltip }) {
  return (
    <Wrapper onClick={onClick}>
      {isLoading ? (
        <Loader />
      ) : (
        <Tooltip icon={<SemjiCreditIcon />} placement="top" title={tooltip}>
          <SemjiCreditIcon /> <Text>{message}</Text>
        </Tooltip>
      )}
    </Wrapper>
  );
}

UnlockRecommendationsOverlay.propTypes = {
  isLoading: PropTypes.bool,
  message: PropTypes.string,
  onClick: PropTypes.func,
};

export default UnlockRecommendationsOverlay;
