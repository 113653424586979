import SvgWrapper from './SvgWrapper';

const SyncCalendarIcon = (props) => {
  return (
    <SvgWrapper fill="none" height="16" viewBox="0 0 17 16" width="17" {...props}>
      <g clipPath="url(#clip0_14119_21107)">
        <mask
          height="16"
          id="mask0_14119_21107"
          maskUnits="userSpaceOnUse"
          style={{ maskType: 'alpha' }}
          width="17"
          x="0"
          y="0"
        >
          <path
            clipRule="evenodd"
            d="M12.9245 0.180054V1.10325H15.5483V8.26896C15.2816 8.02942 14.9883 7.82194 14.6737 7.65218V5.79215H1.55461V14.028H8.55622C8.71166 14.3593 8.90242 14.6688 9.12332 14.9512H0.68V1.10325H3.30382V0.180054H5.92763V1.10325H10.3007V0.180054H12.9245ZM12.0499 1.10325H11.1753V2.94964H12.0499V1.10325ZM5.05303 1.10325H4.17842V2.94964H5.05303V1.10325ZM1.55461 2.02644V4.86896H14.6737V2.02644H12.9245V3.87283H10.3007V2.02644H5.92763V3.87283H3.30382V2.02644H1.55461Z"
            fill="#252736"
            fillRule="evenodd"
          />
          <g clipPath="url(#clip1_14119_21107)">
            <mask
              fill="black"
              height="9"
              id="path-2-outside-1_14119_21107"
              maskUnits="userSpaceOnUse"
              width="9"
              x="7.84298"
              y="7.22046"
            >
              <rect fill="white" height="9" width="9" x="7.84298" y="7.22046" />
              <path d="M11.5061 11.6758H8.84298V8.86467H9.28684V10.7898C9.73647 9.29384 11.0783 8.22046 12.6158 8.22046C14.172 8.22046 15.5546 9.32616 15.9776 10.9093L15.5501 11.0363C15.1809 9.65413 13.974 8.68851 12.6158 8.68851C11.1839 8.68898 9.94553 9.75486 9.63838 11.2073H11.5061V11.6758ZM13.7254 12.1443V12.6128H15.5932C15.2856 14.0657 14.049 15.1311 12.6158 15.1311C11.2567 15.1311 10.0503 14.1655 9.68143 12.7834L9.254 12.9103C9.67655 14.4935 11.0592 15.5992 12.6158 15.5992C14.1511 15.5992 15.4898 14.5309 15.9447 13.0307V14.9554H16.3886V12.1443H13.7254Z" />
            </mask>
            <path
              d="M11.5061 11.6758H8.84298V8.86467H9.28684V10.7898C9.73647 9.29384 11.0783 8.22046 12.6158 8.22046C14.172 8.22046 15.5546 9.32616 15.9776 10.9093L15.5501 11.0363C15.1809 9.65413 13.974 8.68851 12.6158 8.68851C11.1839 8.68898 9.94553 9.75486 9.63838 11.2073H11.5061V11.6758ZM13.7254 12.1443V12.6128H15.5932C15.2856 14.0657 14.049 15.1311 12.6158 15.1311C11.2567 15.1311 10.0503 14.1655 9.68143 12.7834L9.254 12.9103C9.67655 14.4935 11.0592 15.5992 12.6158 15.5992C14.1511 15.5992 15.4898 14.5309 15.9447 13.0307V14.9554H16.3886V12.1443H13.7254Z"
              fill="black"
            />
            <path
              d="M11.5061 11.6758H8.84298V8.86467H9.28684V10.7898C9.73647 9.29384 11.0783 8.22046 12.6158 8.22046C14.172 8.22046 15.5546 9.32616 15.9776 10.9093L15.5501 11.0363C15.1809 9.65413 13.974 8.68851 12.6158 8.68851C11.1839 8.68898 9.94553 9.75486 9.63838 11.2073H11.5061V11.6758ZM13.7254 12.1443V12.6128H15.5932C15.2856 14.0657 14.049 15.1311 12.6158 15.1311C11.2567 15.1311 10.0503 14.1655 9.68143 12.7834L9.254 12.9103C9.67655 14.4935 11.0592 15.5992 12.6158 15.5992C14.1511 15.5992 15.4898 14.5309 15.9447 13.0307V14.9554H16.3886V12.1443H13.7254Z"
              mask="url(#path-2-outside-1_14119_21107)"
              stroke="black"
              strokeWidth="0.421667"
            />
          </g>
        </mask>
        <g mask="url(#mask0_14119_21107)">
          <rect fill="#7C7D86" height="17.3778" width="18.1095" x="-0.966316" y="-0.688965" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_14119_21107">
          <rect fill="white" height="15.64" transform="translate(0.68 0.180054)" width="15.64" />
        </clipPath>
        <clipPath id="clip1_14119_21107">
          <rect
            fill="white"
            height="7.96482"
            transform="translate(8.84299 7.92761)"
            width="7.54562"
          />
        </clipPath>
      </defs>
    </SvgWrapper>
  );
};

export default SyncCalendarIcon;
