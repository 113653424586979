import './VersionHistoryPanel.scss';

import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'semji-core/components/Button';
import { ENUM_TABS_VARIANTS, Tabs } from 'semji-core/components/Tabs';

import { SCOPE_CONTENTS } from '@/apis/semji/constants';
import usePutContentVersion from '@/apis/semji/contents/usePutContentVersion';
import { PlanRestrictionBadge } from '@/components/Badge/PlanRestrictionBadge';
import RestrictionModal from '@/containers/ContentVersion/components/VersionHistoryPanel/RestrictionModal';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import { showErrorSnackbar, showSuccessSnackbar } from '@/store/actions/ui';
import { ContentVersion, ENUM_CONTENT_TYPE } from '@/types/contents';

import EmptyState from './LoadingState';
import NoVersion from './NoVersion';
import VersionGroup from './VersionGroup';
import { KEYBOARD_NAVIGATE_DIRECTION } from './VersionGroup/VersionGroup.types';
import { VersionHistoryPanelProps } from './VersionHistoryPanel.types';
import VersionHistoryPanelUtils from './VersionHistoryPanel.utils';

function VersionHistoryPanel({
  onRestoreClick,
  onCreateNewDraft,
  isLoading,
  isCreating,
  versions,
}: VersionHistoryPanelProps) {
  const [status, setStatus] = useState(ENUM_CONTENT_TYPE.DRAFT);

  const { contentId, organizationId, pageId, workspaceId, versionId } = useParams();
  const dispatch = useDispatch();
  const { isFeatureEnabled: hasAccessToContentVersionHistory } = useOrganizationFeatureSet(
    'contents:draft-versioning:is-enabled'
  );
  const selectedVersionId = versionId || contentId;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const getVersionQueryKey = [SCOPE_CONTENTS.GET_CONTENT_VERSIONS, contentId];

  const putContentVersion = usePutContentVersion({
    contentId,
    onError: (_, __, context) => {
      dispatch(showErrorSnackbar(t('common:error.default')));
      queryClient.setQueryData<ContentVersion[]>(getVersionQueryKey, context?.previousVersions);
    },
    onMutate: async ({ version }) => {
      await queryClient.cancelQueries({
        queryKey: [SCOPE_CONTENTS.GET_CONTENT_VERSIONS, contentId],
      });

      const previousVersions = queryClient.getQueryData<ContentVersion[]>(getVersionQueryKey);

      // Optimistically update to the new value
      queryClient.setQueryData<ContentVersion[]>(getVersionQueryKey, (old) =>
        old?.map((item) => {
          if (item.id === version.id) {
            return { ...item, name: version.name || '' };
          }
          return item;
        })
      );

      return { previousVersions: previousVersions || [] };
    },

    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [SCOPE_CONTENTS.GET_CONTENT_VERSIONS, contentId] });
    },
    onSuccess: async ({ data }) => {
      dispatch(
        showSuccessSnackbar(
          t(
            `content:version-history-panel.version-name-${data.name ? 'edited' : 'removed'}-successfully`
          )
        )
      );
    },
  });

  const activeVersion = versions.find((o) => o.id === selectedVersionId);

  useEffect(() => {
    // If the active content is published, set the status tab to published
    if (!isLoading) {
      if ([ENUM_CONTENT_TYPE.PUBLISHED, ENUM_CONTENT_TYPE.ORIGINAL].includes(activeVersion?.type)) {
        setStatus(ENUM_CONTENT_TYPE.PUBLISHED);
      }
    }
  }, [isLoading]);

  function handleSelectTab(tab: ENUM_CONTENT_TYPE) {
    return function () {
      setStatus(tab);
    };
  }

  const hideRestoreButton =
    !versions.find((version) => version.isCurrentDraft) && status === ENUM_CONTENT_TYPE.DRAFT;

  const hideVersionHistory =
    !hasAccessToContentVersionHistory && status === ENUM_CONTENT_TYPE.DRAFT;
  const isRestoreDisabled = !activeVersion || activeVersion?.isCurrentDraft || hideVersionHistory;

  function handleSelectVersion(id: string) {
    return function (_: React.MouseEvent<HTMLDivElement, MouseEvent>) {
      const selectedVersion = versions.find((version) => version.id === id);
      // If the user doesn't have access to the content version history, they can only view the current draft
      if (hideVersionHistory && !selectedVersion?.isCurrentDraft) {
        return;
      }

      if (selectedVersion?.type !== ENUM_CONTENT_TYPE.DRAFT || selectedVersion?.isCurrentDraft) {
        navigate(`/o/${organizationId}/w/${workspaceId}/p/${pageId}/versions/${id}`, {
          replace: true,
        });
      } else {
        const currentDraft = versions.find((version) => version.isCurrentDraft);
        navigate(
          `/o/${organizationId}/w/${workspaceId}/p/${pageId}/versions/${currentDraft?.id}/content-version/${selectedVersion?.id}`,
          {
            replace: true,
          }
        );
      }
    };
  }

  function handleClickRestore() {
    if (!isRestoreDisabled) {
      onRestoreClick();
    }
  }

  function handleRenameVersion(id: string, name: string) {
    putContentVersion.mutate({
      version: {
        id,
        name,
      },
    });
  }

  const filteredVersions = VersionHistoryPanelUtils.filterVersionsByStatus(versions, status);

  const groupedVersions = VersionHistoryPanelUtils.groupVersionsByMonthYear(filteredVersions);
  const versionGroupsRecords = Object.entries(groupedVersions);

  function handleTabulate(
    direction: KEYBOARD_NAVIGATE_DIRECTION = KEYBOARD_NAVIGATE_DIRECTION.DOWN
  ) {
    let versionIndex = filteredVersions.findIndex((version) => version.id === versionId);
    if (!versionId) {
      versionIndex = filteredVersions.findIndex((version) => version.id === contentId);
    }
    const nextVersionId = filteredVersions[versionIndex + 1]?.id || filteredVersions[0].id;
    const previousVersionId =
      filteredVersions[versionIndex - 1]?.id || filteredVersions[filteredVersions.length - 1].id;

    handleSelectVersion(
      direction === KEYBOARD_NAVIGATE_DIRECTION.DOWN ? nextVersionId : previousVersionId
    )({} as any);
  }

  const noData = filteredVersions.length === 0;
  const isAccordion = filteredVersions.some((version, index) => {
    return filteredVersions.some((v, i) => {
      let hasSameDate =
        index !== i &&
        v.year === version.year &&
        v.month === version.month &&
        v.day === version.day;
      if (status === ENUM_CONTENT_TYPE.DRAFT) {
        hasSameDate = hasSameDate && v.hour === version.hour;
      }
      return hasSameDate;
    });
  });

  return (
    <div className="version-history-panel version-history-panel">
      <div className="version-history-panel__header">
        <div className="version-history-panel__header__title">
          {t('content:version-history-panel.version-history')}
        </div>
      </div>
      <div className="version-history-panel__content">
        <Tabs
          overrideClassName="version-history-panel__content__tabs"
          tabs={[
            {
              content: <div>{t('content:version-history-panel.tabs.current-draft')}</div>,
              onClick: handleSelectTab(ENUM_CONTENT_TYPE.DRAFT),
              selected: status === ENUM_CONTENT_TYPE.DRAFT,
            },
            {
              content: <div>{t('content:version-history-panel.tabs.published-versions')}</div>,
              onClick: handleSelectTab(ENUM_CONTENT_TYPE.PUBLISHED),
              selected: status === ENUM_CONTENT_TYPE.PUBLISHED,
            },
          ]}
          variant={ENUM_TABS_VARIANTS.Small}
        />
        {isLoading && <EmptyState />}
        {!isLoading && !noData && (
          <div
            className={`version-history-panel__content__listing ${hideVersionHistory && 'version-history-panel__content__listing--hide_overflow'}`}
          >
            {versionGroupsRecords.map(
              ([date, versions], index) =>
                versions.length > 0 && (
                  <VersionGroup
                    key={date}
                    date={date}
                    enableOptionsMenu={true}
                    handleRenameVersion={handleRenameVersion}
                    handleSelectVersion={handleSelectVersion}
                    isPublished={status === ENUM_CONTENT_TYPE.PUBLISHED}
                    selectedVersionId={String(selectedVersionId)}
                    versions={versions}
                    withPadding={isAccordion}
                    onTabulate={handleTabulate}
                  />
                )
            )}
            {hideVersionHistory && <RestrictionModal />}
          </div>
        )}
        {!isLoading && noData && (
          <NoVersion
            canCreateDraft={!!contentId}
            isCreationDisabled={isCreating}
            status={status}
            onCreateNewDraft={onCreateNewDraft}
          />
        )}
      </div>

      <div className="version-history-panel__footer">
        {!hideRestoreButton && (
          <Button
            className="version-history-panel__footer__button"
            disabled={isRestoreDisabled}
            stretch
            onClick={handleClickRestore}
          >
            {hideVersionHistory && (
              <PlanRestrictionBadge className="layout-menu__item__restriction-badge" isCondensed />
            )}
            {t('content:version-history-panel.restore-this-version')}
          </Button>
        )}
      </div>
    </div>
  );
}

export default VersionHistoryPanel;
