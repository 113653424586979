export const VIEW_REPORTS = 'View Reports';
export const VIEW_BRIEF = 'View Brief';
export const SIGNED_IN = 'Signed In';

export const ATOMIC_CONTENT_GENERATE = 'Atomic Content Generate';
export const ATOMIC_CONTENT_INSERT = 'Atomic Content Insert';
export const ATOMIC_CONTENT_STOP = 'Atomic Content Stop';

export const AI_PLUS_CONTENT_GENERATE = 'AI+ Content Generate';
export const AI_PLUS_CONTENT_STOP = 'AI+ Content Stop';
export const AI_PLUS_CONTENT_INSERT = 'AI+ Content Insert';
export const AI_PLUS_CONTENT_REGENERATE = 'AI+ Content Regenerate';

export const CONTENT_PUBLISHED = 'Content Published';
export const SESSION_START = 'Session Start';
export const DASHBOARD_TOP_NAV_CLICK = 'Dashboard - Top nav click';
export const CONTENT_IDEAS_TOP_NAV_CLICK = 'Content Ideas - Top nav click';
export const PAGES_TOP_NAV_CLICK = 'Pages - Top nav click';
export const PLANNING_TOP_NAV_CLICK = 'Planning - Top nav click';
export const REPORTS_TOP_NAV_CLICK = 'Reports - Top nav click';
export const CREATE_DRAFT_TOP_NAV_CLICK = 'Create Draft - Top nav click';
export const QUICKSTART_GUIDE_TOP_NAV_CLICK = 'Quickstart Guide - Top nav click';
export const HELP_CENTER_TOP_NAV_CLICK = 'Help Center - Top nav click';
export const CONTACT_US_TOP_NAV_CLICK = 'Contact us - Top nav click';
export const SELECT_WORKSPACE_TOP_NAV_CLICK = 'Select Workspace - Top nav click';
export const SELECT_ORGANIZATION_TOP_NAV_CLICK = 'Select Organization - Top nav click';
export const ADD_WORKSPACE_TOP_NAV_CLICK = 'Add Workspace - Top nav click';
export const SETTINGS_TOP_NAV_CLICK = 'Settings - Top nav click';
export const CONTENT_IDEAS_SEARCH_TOP_NAV_CLICK = 'Content Ideas Search - Click';
export const CONTENT_IDEAS_MINDMAP_CLICK = 'Content Ideas Mindmap - Click';
export const PAGES_IMPORT_CLICK = 'Pages Import - Click';
export const PAGES_ADD_FILTER_CLICK = 'Pages Add filter - Click';
export const PLANNING_NEW_DRAFT_CLICK = 'Planning New Draft - Click';
export const PLANNING_SYNCHRONIZE_AGENDA_CLICK = 'Planning Synchronize agenda - Click';
export const REPORTS_ADD_FILTER_CLICK = 'Reports Add filter - Click';
export const REPORTS_EXPORT_CLICK = 'Reports Export - Click';
export const WHATS_NEW_TOP_NAV_CLICK = "What's new - Top nav click";
export const FACT_CHECKING_ACTIVATION_CLICK = 'FactChecking Activation Click';
export const FACT_CHECKING_VALIDATED_CLICK = 'FactChecking Validated Click';
export const FACT_CHECKING_DELETED_CLICK = 'FactChecking Deleted Click';
export const COMPETITORS_TOP_NAV_CLICK = 'Competitors - Top nav click';
export const BRANDVOICE_TOP_NAV_CLICK = 'BrandVoice - Top nav click';
export const QUICK_SEARCH_CLICK = 'Quick Search - Click';
export const QUICK_SEARCH_INPUT = 'Quick Search - Input';
