import { useQueryClient } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import SortDirection from 'react-virtualized/dist/es/Table/SortDirection';
import styled from 'styled-components/macro';

import { LOCATION, SCOPE_SERVICE_INTEGRATIONS } from '@/apis/semji/constants';
import useDeleteServiceIntegration from '@/apis/semji/serviceIntegrations/useDeleteServiceIntegration';
import { FlexCenteredAlignJustifyEndContainer, FlexGrowContainer } from '@/components/_common';
import { PlanRestrictionBadge } from '@/components/Badge/PlanRestrictionBadge';
import { LinkLikeButton } from '@/components/Button/Button';
import { PrimaryButton, SecondaryButton } from '@/components/Button/Button';
import ServiceIntegrationAddOnDialog from '@/components/Dialog/ServiceIntegrationAddOnDialog';
import CheckIcon from '@/components/icons/CheckIcon';
import CloseIcon from '@/components/icons/CloseIcon';
import { StretchTabBodyContent, TabBodyTitle } from '@/components/Navigation/Tabs';
import Table from '@/components/Table/Table';
import { DarkText } from '@/components/Text/Dark';
import { ExtraSmallLightText } from '@/components/Text/Light';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import CustomGoalsDialog from '@/containers/ServiceIntegration/common/CustomGoalsDialog';
import DisconnectServiceIntegrationDialog from '@/containers/ServiceIntegration/common/DisconnectServiceIntegrationDialog';
import Flex from '@/design-system/components/Flex';
import useCan from '@/hooks/useCan';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import { showUpgradePlanModal } from '@/store/actions/billing';
import {
  AT_INTERNET_VALUE,
  GOOGLE_ANALYTICS_4_VALUE,
  GOOGLE_ANALYTICS_VALUE,
  MATOMO_VALUE,
  SEARCH_CONSOLE_VALUE,
  SERVICES_LIST,
} from '@/utils/constants';

const StyledSecondaryButton = styled(SecondaryButton)`
  && {
    color: ${(props) => props.theme.cssColors.dark080};
    font-size: ${(props) => props.theme.textCss.sizes.sm};
    padding: 8px 20px;
    width: 140px;

    div {
      gap: 9px;
      align-items: center;
      justify-content: center;
    }

    .text--hovered {
      display: none;
    }

    .text {
      display: flex;
      align-items: center;
    }

    &:hover {
      .text {
        display: none;
      }

      .text--hovered {
        display: flex;
        align-items: center;
      }
    }
  }
`;

const EllipsisSpan = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  && {
    font-size: ${(props) => props.theme.textCss.sizes.sm};
    padding: 8px 20px;
    width: 140px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;
const StyledFlexGrowContainer = styled(FlexGrowContainer)`
  width: 100%;
`;
const ServiceInfo = styled.span`
  font-size: ${(props) => props.theme.textCss.sizes.sm};
  color: ${(props) => props.theme.cssColors.dark040};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: ${(props) => props.theme.textCss.lineHeight};
`;
const StyledTitle = styled(DarkText)`
  color: ${(props) => props.theme.cssColors.dark100};
  font-size: ${(props) => props.theme.textCss.sizes.sm};
  font-weight: ${(props) => props.theme.textCss.weights.medium};
  padding: 0.5em 0;
`;

function CellConnectedButton({
  featureFlagKey,
  path,
  id,
  title,
  openCustomGoalsDialog,
  setServiceToDelete,
  isGoalsEnabled,
  isConnected,
  isDisabledAt,
  handleOpenServiceIntegrationAddOnDialog,
  dataIntercomTarget,
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { workspaceId, organizationId } = useParams();
  const dispatch = useDispatch();

  const { isFeatureEnabled: hasAccessToServiceIntegration, addOn } = useOrganizationFeatureSet(
    'service-integration:has-access-to-' + featureFlagKey
  );
  const { isFeatureEnabled: hasAccessToGoals } = useOrganizationFeatureSet(
    'pages:priority-score:has-access-to-conversions'
  );

  function handleClickConnect() {
    if (!addOn.isAddOn && !hasAccessToServiceIntegration) {
      dispatch(showUpgradePlanModal());
    } else if (addOn.isAddOn && !hasAccessToServiceIntegration) {
      handleOpenServiceIntegrationAddOnDialog(title);
    } else {
      navigate(`/o/${organizationId}/w/${workspaceId}/settings/integrations/${path}`);
    }
  }

  function handleDeleteService() {
    setServiceToDelete({
      id: id,
      title: title,
    });
  }

  function handleOpenGoalsDialog() {
    if (hasAccessToGoals) {
      openCustomGoalsDialog();
    } else {
      dispatch(showUpgradePlanModal());
    }
  }

  return (
    <FlexCenteredAlignJustifyEndContainer gap="30px">
      {isGoalsEnabled && (
        <Flex>
          <LinkLikeButton
            cursor="pointer"
            isDisabled={!hasAccessToGoals}
            onClick={handleOpenGoalsDialog}
          >
            {t('settings:workspace.integrations-settings.button-manage-goals')}
          </LinkLikeButton>
          {!hasAccessToServiceIntegration && <PlanRestrictionBadge isCondensed />}
        </Flex>
      )}
      {isConnected ? (
        <StyledSecondaryButton inverted onClick={handleDeleteService}>
          <TooltipComponent
            className="text--hovered"
            placement="top"
            title={t('settings:workspace.integrations-settings.button-disconnect')}
          >
            <CloseIcon />
            <EllipsisSpan>
              {t('settings:workspace.integrations-settings.button-connected')}
            </EllipsisSpan>
          </TooltipComponent>
          <TooltipComponent
            className="centered text"
            placement="top"
            title={t('settings:workspace.integrations-settings.button-connected')}
          >
            <CheckIcon />
            <EllipsisSpan>
              {t('settings:workspace.integrations-settings.button-connected')}
            </EllipsisSpan>
          </TooltipComponent>
        </StyledSecondaryButton>
      ) : (
        <>
          {!hasAccessToServiceIntegration && (
            <PlanRestrictionBadge
              isCondensed
              tooltip={
                addOn.isAddOn
                  ? t('components:badge.plan.available-on-demand')
                  : t('components:badge.plan.available-on-higher-plan')
              }
            />
          )}
          <TooltipComponent
            placement="top"
            title={
              isDisabledAt
                ? t('settings:workspace.integrations-settings.button-reconnect')
                : t('settings:workspace.integrations-settings.button-connect')
            }
          >
            <StyledPrimaryButton
              data-intercom-target={`service-integration-${dataIntercomTarget}`}
              onClick={handleClickConnect}
            >
              {isDisabledAt
                ? t('settings:workspace.integrations-settings.button-reconnect')
                : t('settings:workspace.integrations-settings.button-connect')}
            </StyledPrimaryButton>
          </TooltipComponent>
        </>
      )}
    </FlexCenteredAlignJustifyEndContainer>
  );
}

function IntegrationsSettings({ title }) {
  const { workspaceId } = useParams();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [isCustomGoalsModalOpen, setIsCustomGoalsModalOpen] = useState(false);
  const [serviceToDelete, setServiceToDelete] = useState(null);
  const [addOnConnectorName, setAddOnConnectorName] = useState(null);

  const isMatomoEnabled = useCan({ perform: 'matomo:enabled' });
  const isAdobeEnabled = useCan({ perform: 'adobe:enabled' });
  const isPiwikProEnabled = useCan({ perform: 'piwik-pro:enabled' });

  const serviceIntegrations =
    queryClient.getQueryData([
      SCOPE_SERVICE_INTEGRATIONS.GET_SERVICE_INTEGRATIONS,
      workspaceId,
      LOCATION.ROOT,
    ]) || [];

  const deleteServiceIntegration = useDeleteServiceIntegration({
    onSuccess: () => {
      queryClient.refetchQueries({
        active: true,
        exact: true,
        fetching: false,
        queryKey: [SCOPE_SERVICE_INTEGRATIONS.GET_SERVICE_INTEGRATIONS, workspaceId, LOCATION.ROOT],
      });
      setServiceToDelete(null);
    },
  });

  function openCustomGoalsDialog() {
    setIsCustomGoalsModalOpen(true);
  }

  function closeCustomGoalsDialog() {
    setIsCustomGoalsModalOpen(false);
  }

  function closeDisconnectServiceIntegrationDialog() {
    setServiceToDelete(null);
  }

  function handleOpenServiceIntegrationAddOnDialog(title) {
    setAddOnConnectorName(title);
  }

  function handleCloseServiceIntegrationAddOnDialog() {
    setAddOnConnectorName(null);
  }

  const columns = [
    {
      align: 'center',
      cellDataGetter: ({ rowData }) => ({
        alt: rowData.alt,
        icon: rowData.icon,
        style: rowData.iconStyle,
      }),
      cellRenderer: ({ cellData }) => (
        <img alt={cellData.alt} src={cellData.icon} style={cellData.style} />
      ),
      dataKey: 'url',
      label: '',
      width: 50,
    },
    {
      cellDataGetter: ({ rowData }) => ({
        isConnected: !!rowData.createdAt,
        name: rowData.name,
        siteUrl: rowData.site_url,
        title: rowData.title,
        webPropertyName: rowData.web_property_name,
      }),
      cellRenderer: ({ cellData }) => (
        <Flex flexDirection="column" minWidth="0">
          <StyledTitle>{cellData.title}</StyledTitle>
          {cellData.isConnected && cellData.name === SEARCH_CONSOLE_VALUE && (
            <ServiceInfo size="micro">{cellData.siteUrl}</ServiceInfo>
          )}
          {cellData.isConnected && cellData.name === GOOGLE_ANALYTICS_VALUE && (
            <ServiceInfo size="micro">{cellData.webPropertyName} (UA)</ServiceInfo>
          )}
          {cellData.isConnected && cellData.name === GOOGLE_ANALYTICS_4_VALUE && (
            <ServiceInfo size="micro">{cellData.webPropertyName} (GA4)</ServiceInfo>
          )}
          {cellData.isConnected && cellData.name === AT_INTERNET_VALUE && (
            <ServiceInfo size="micro">{cellData.webPropertyName}</ServiceInfo>
          )}
          {cellData.isConnected && cellData.name === MATOMO_VALUE && (
            <ServiceInfo size="micro">{cellData.webPropertyName}</ServiceInfo>
          )}
        </Flex>
      ),
      dataKey: 'apps',
      flexGrow: 1,
      label: t('settings:workspace.integrations-settings.column-label.apps'),
      width: 175,
    },
    {
      align: 'center',
      cellDataGetter: ({ rowData }) => rowData.createdAt,
      cellRenderer: ({ cellData }) => (
        <ExtraSmallLightText>
          {cellData ? DateTime.fromISO(cellData).toFormat('dd MMM yy') : '-'}
        </ExtraSmallLightText>
      ),
      dataKey: 'createdAt',
      label: t('settings:workspace.integrations-settings.column-label.createdAt'),
      sortFunction: (value1, value2, sortDirection) => {
        const a = value1 ? DateTime.fromISO(value1) : null;
        const b = value2 ? DateTime.fromISO(value2) : null;

        if (+a === +b) {
          return 0;
        }

        if (sortDirection === SortDirection.ASC) {
          return (a && a < b) || (a && !b) ? -1 : 1;
        }

        return a < b ? 1 : -1;
      },
      width: 125,
    },
    {
      align: 'center',
      cellDataGetter: ({ rowData }) => rowData.lastUsedAt,

      cellRenderer: ({ cellData }) => (
        <ExtraSmallLightText>
          {cellData ? DateTime.fromISO(cellData).toFormat('dd MMM yy') : '-'}
        </ExtraSmallLightText>
      ),
      dataKey: 'lastSynced',
      label: t('settings:workspace.integrations-settings.column-label.lastSynced'),
      width: 125,
    },
    {
      cellDataGetter: ({ rowData }) => ({
        currentGoals: rowData.custom_goals || {},
        dataIntercomTarget: rowData.dataIntercomTarget,
        featureFlagKey: rowData.featureFlagKey,
        id: rowData.id,
        isConnected: !!rowData.createdAt && !rowData.disabledAt,
        isDisabledAt: !!rowData.disabledAt,
        isGoalsEnabled:
          [
            ...SERVICES_LIST.GOOGLE_ANALYTICS.values,
            ...SERVICES_LIST.MATOMO.values,
            ...SERVICES_LIST.ADOBE.values,
            ...SERVICES_LIST.PIWIK_PRO.values,
          ].includes(rowData.name) &&
          !!rowData.createdAt &&
          !rowData.disabledAt,
        path: rowData.path,
        title: rowData.title,
        type: rowData.type,
      }),
      cellRenderer: ({ cellData }) => (
        <CellConnectedButton
          dataIntercomTarget={cellData.dataIntercomTarget}
          featureFlagKey={cellData.featureFlagKey}
          handleOpenServiceIntegrationAddOnDialog={handleOpenServiceIntegrationAddOnDialog}
          id={cellData.id}
          isConnected={cellData.isConnected}
          isDisabledAt={cellData.isDisabledAt}
          isGoalsEnabled={cellData.isGoalsEnabled}
          openCustomGoalsDialog={openCustomGoalsDialog}
          path={cellData.path}
          setServiceToDelete={setServiceToDelete}
          title={cellData.title}
        />
      ),
      dataKey: 'isConnected',
      label: '',
      width: 400,
    },
  ];

  function formatServices() {
    return Object.values(SERVICES_LIST)
      .filter((service) => {
        if (service.feature === 'matomo:enabled') {
          return isMatomoEnabled;
        }
        if (service.feature === 'adobe:enabled') {
          return isAdobeEnabled;
        }
        if (service.feature === 'piwik-pro:enabled') {
          return isPiwikProEnabled;
        }
        return true;
      })
      .map((service) => {
        const { workspace, data, ...rest } =
          serviceIntegrations.find((serviceProvider) =>
            service.values.includes(serviceProvider.name)
          ) || {};

        return {
          ...service,
          ...data,
          ...rest,
        };
      });
  }

  function disconnectService() {
    deleteServiceIntegration.mutate(serviceToDelete.id);
  }

  return (
    <>
      <StretchTabBodyContent>
        <TabBodyTitle noMargin>{title}</TabBodyTitle>
        <StyledFlexGrowContainer>
          <Table
            columns={columns}
            defaultSort="connectedAt"
            defaultSortDirection={SortDirection.DESC}
            headerHeight={50}
            mode="transparent"
            rows={formatServices()}
          />
        </StyledFlexGrowContainer>
        {serviceToDelete && (
          <DisconnectServiceIntegrationDialog
            isLoading={deleteServiceIntegration.isLoading}
            serviceName={serviceToDelete.title}
            onClose={closeDisconnectServiceIntegrationDialog}
            onConfirm={disconnectService}
          />
        )}
        {isCustomGoalsModalOpen && <CustomGoalsDialog onClose={closeCustomGoalsDialog} />}
        {!!addOnConnectorName && (
          <ServiceIntegrationAddOnDialog
            connector={addOnConnectorName}
            onClose={handleCloseServiceIntegrationAddOnDialog}
          />
        )}
      </StretchTabBodyContent>
    </>
  );
}

export default IntegrationsSettings;
