import './CustomInput.scss';

import React, { useRef } from 'react';
import { ColoredDot } from 'semji-core/components/ColoredDot';

import ContainedFavIcon, {
  FAVICON_SIZE_SM,
} from '@/containers/Competitors/components/ContainedFavIcon';
import { formatUrl, hasGoodUrlOrDomainPattern } from '@/utils/url';

import { CustomInputProps } from './CustomInput.types';

export default function CustomInput({
  color = '#2758BC',
  value,
  className,
  onKeyDown: handleKeyDown,
  startAdornment,
  endAdornment,
  withoutFavIcon,
  error,
  ...props
}: CustomInputProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const hasFocus = inputRef.current?.contains(document.activeElement);

  function handleInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    handleKeyDown && handleKeyDown(e);
    if (e.key === 'Enter') {
      inputRef.current?.blur();
    }
  }

  return (
    <div
      className={`competitors-configuration-listing-custom-input ${!!error && 'competitors-configuration-listing-custom-input--error'}`}
    >
      <div className="competitors-configuration-listing-custom-input__start">
        <ColoredDot
          className="competitors-configuration-listing-custom-input__start__dot"
          color={!hasFocus && hasGoodUrlOrDomainPattern(String(value)) ? color : '#D9D9D9'}
          label={''}
        />
        {!withoutFavIcon && (
          <ContainedFavIcon
            className="competitors-configuration-listing-custom-input__favico"
            size={FAVICON_SIZE_SM}
            url={formatUrl(value)}
          />
        )}
        {startAdornment}
      </div>

      <div className="competitors-configuration-listing-custom-input__input-wrapper">
        <input
          {...props}
          ref={inputRef}
          className="competitors-configuration-listing-custom-input__input"
          value={value}
          onKeyDown={handleInputKeyDown}
          {...props}
        />
        <span className="competitors-configuration-listing-custom-input__error">{error}</span>
      </div>
      {endAdornment && (
        <div className="competitors-configuration-listing-custom-input__end">{endAdornment}</div>
      )}
    </div>
  );
}
