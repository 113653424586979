import styled from 'styled-components/macro';

export const VolumeCell = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 100px;
  max-width: 100px;
  font-size: 0.9em;
`;
