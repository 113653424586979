import { useMutation } from '@tanstack/react-query';

import apiClient from '@/apis/semji/apiClient';

import { UsePutContentProps } from './usePutContent.types';

export default function usePutContent({ contentId, ...props }: UsePutContentProps) {
  const { put } = apiClient;

  return useMutation({
    mutationFn: async ({ content = {} }) => {
      const result = await put(`contents/${contentId}`, { ...content });

      return result;
    },
    ...props,
  });
}
