import React from 'react';
import { useTranslation } from 'react-i18next';
import { OptionButton } from 'semji-core/components/OptionButton';
import { MoreIcon } from 'semji-core/icons/MoreIcon';

import LockRoundIcon from '@/components/icons/LockRoundIcon';
import { useDiffContent } from '@/containers/Content/TinyMceComponents/Editor/hooks/useEditorPopper/useDiffContent';
import useApiConfigurations from '@/hooks/useApiConfigurations';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import { DIFF_CONTENT_ENABLED } from '@/utils/configurations/constants';

const COMPARE_KEY = 'compare';
const RENAME_KEY = 'rename';
const REMOVE_NAME_KEY = 'remove-name';

export default function VersionOptionsMenu({
  version,
  onRenameClick,
  onRemoveNameClick,
  isRenameDisabled,
  hasAlreadyBeenNamed,
}: {
  handleClick?: () => void;
  version: any;
  onRenameClick: () => void;
  onRemoveNameClick: () => void;
  isRenameDisabled: boolean;
  hasAlreadyBeenNamed?: boolean;
}) {
  const { t } = useTranslation();
  const diffContentEnabled = useApiConfigurations(DIFF_CONTENT_ENABLED);

  const { isFeatureEnabled: hasAccessToContentVersionHistory } = useOrganizationFeatureSet(
    'contents:draft-versioning:is-enabled'
  );

  const isDiffContentEnabled = hasAccessToContentVersionHistory && diffContentEnabled;

  const { handleCompareClick } = useDiffContent();

  function handleOptionChange(key: string) {
    return (value: any) => {
      switch (key) {
        case COMPARE_KEY:
          handleCompareClick({ from: version.id, to: undefined });
          break;
        case RENAME_KEY:
          onRenameClick();
          break;
        case REMOVE_NAME_KEY:
          onRemoveNameClick();
          break;
        default:
          break;
      }
    };
  }
  return (
    <OptionButton
      dropdownWidth="210px"
      enabled={true}
      icon={<MoreIcon />}
      optionGroups={[
        {
          items: [
            {
              checkable: false,
              checked: true,
              closeOnClick: true,
              disabled: !isDiffContentEnabled,
              onClick: handleOptionChange(COMPARE_KEY),
              'data-intercom-target': 'editor_compare_button',
              badgeIcon: !isDiffContentEnabled && <LockRoundIcon className="icon" />,
              tooltip: !isDiffContentEnabled
                ? t('components:badge.plan.available-on-higher-plan')
                : undefined,
              title: t('content:editor-container.diff-content.history-modal.compare'),
            },
            {
              checkable: false,
              checked: true,
              closeOnClick: true,
              disabled: isRenameDisabled,
              onClick: handleOptionChange(RENAME_KEY),
              title: t(
                `content:editor-container.diff-content.history-modal.${hasAlreadyBeenNamed ? 'rename' : 'name'}`
              ),
            },
            ...(hasAlreadyBeenNamed
              ? [
                  {
                    checkable: false,
                    checked: true,
                    closeOnClick: true,
                    disabled: isRenameDisabled,
                    onClick: handleOptionChange(REMOVE_NAME_KEY),
                    title: t(`content:editor-container.diff-content.history-modal.remove-name`),
                  },
                ]
              : []),
          ].filter(Boolean),
        },
      ]}
    />
  );
}
