import React from 'react';
import { useTranslation } from 'react-i18next';
import { PureAvatar } from 'semji-core/components/Avatar';

import { ColoredDot } from '@/components/_common';
import { getDefaultFolderGeneral } from '@/components/Select/FolderSelect/constants';
import useNullUser from '@/hooks/useNullUser';

export function ContentItem({ users, statuses, folders, hit, components }) {
  const { t } = useTranslation();
  const nullUser = useNullUser();
  const DEFAULT_FOLDER_GENERAL = getDefaultFolderGeneral();
  const apiRootUrl = import.meta.env.VITE_REACT_APP_SEMJI_API_ROOT_URL;

  const status = statuses.find((s) => s.id === hit.content_status_public_id) ?? statuses?.[0];
  const folder = folders.find((f) => f.id === hit.folder_public_id) ?? DEFAULT_FOLDER_GENERAL;
  const user = users.find((u) => u.id === hit.user_assigned_to_public_id) ?? nullUser;

  return (
    <div className="aa-ItemContent">
      <ColoredDot color={status.color} />
      <div className="aa-ItemContentBody">
        <div className="aa-ItemContentTitle">
          <components.Highlight attribute="title" hit={hit} />
          <span className="aa-dotSeparator">•</span>
          <components.Highlight attribute="page_focus_keyword" hit={hit} />
        </div>
        <div className="aa-ItemContentDescription">{folder.name}</div>
      </div>
      <div className="aa-UserAvatar">
        <PureAvatar
          apiRootUrl={apiRootUrl}
          email={user.email ?? ''}
          firstName={user.firstName || ''}
          id={user.id}
          lastName={user.lastName || ''}
          profileImageHash={user.profileImageHash || null}
          translations={{ unassigned: t('common:user-picker.unassigned') }}
        />
        {user.firstName + ' ' + user.lastName}
      </div>
    </div>
  );
}
