import { DEFAULT_FAVICON } from '@/utils/constants';
import { formatUrl } from '@/utils/url';

export const getFaviconFromUrl = (url: string, apiRootUrl: string) => {
  try {
    const { host } = new URL(formatUrl(url));
    return `${apiRootUrl}/domains/${host}/favicon`;
  } catch (e) {
    return DEFAULT_FAVICON;
  }
};
