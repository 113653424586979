import React from 'react';

import SvgWrapper from './SvgWrapper';

const CaretIcon = (props) => (
  <SvgWrapper {...props}>
    <path
      color="currentColor"
      d="M16.354 5.075l-7.855 7.854-7.853-7.854 0.707-0.707 7.145 7.146 7.148-7.147 0.708 0.708z"
    />
  </SvgWrapper>
);

export default CaretIcon;
