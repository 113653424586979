import styled, { css } from 'styled-components/macro';

export const NO_MENU_LAYOUT = 'NO_MENU_LAYOUT';
export const TRANSPARENT_LOGO_AND_PROFILE_MENU = 'TRANSPARENT_LOGO_AND_PROFILE_MENU';
export const GRADIENT_LOGO_AND_PROFILE_MENU = 'GRADIENT_LOGO_AND_PROFILE_MENU';
export const ONLY_LOGO_WHITE_BACKGROUND = 'ONLY_LOGO_WHITE_BACKGROUND';
export const ONLY_LOGO_WHITE_BACKGROUND_AND_ONBOARDING =
  'ONLY_LOGO_WHITE_BACKGROUND_AND_ONBOARDING';

export const DEFAULT_BACKGROUND = 'DEFAULT_BACKGROUND';

export const LayoutBody = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100%;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: auto;
`;

export const LayoutContent = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'column'};
  min-height: 100%;
  max-height: ${({ maxHeight }) => maxHeight};
  margin: ${({ margin }) => margin};
  flex: ${({ flex }) => flex};

  padding: ${({ isPadded }) => (isPadded ? '32px' : 0)};
  ${({ theme }) => theme.mediaQueries.phone} {
    && {
      padding: 0;
    }
  }
  ${({ theme }) => theme.mediaQueries.tablet} {
    && {
      padding: ${({ isPadded }) => (isPadded ? '32px' : 0)};
    }
  }
`;

export const LayoutCenteredContent = styled(LayoutContent)`
  align-items: center;
  justify-content: center;
  flex: 1;
  z-index: 1;
  ${({ theme }) => theme.mediaQueries.phone} {
    height: 100%;
  }
  ${({ theme }) => theme.mediaQueries.tablet} {
    height: 100%;
  }
`;

export default styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.cssColors.white};
  flex: ${({ flex }) => flex};
  ${({ variant }) =>
    variant === DEFAULT_BACKGROUND &&
    css`
      &:before {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        content: '';
        background: #252736;
      }
    `};
  min-height: 100%;
  max-height: 100%;
`;

export const OnboardingWrapper = styled.div`
  padding: ${({ $isMobileVersion }) => ($isMobileVersion ? '15px 30px' : '3.5rem')};
  max-width: ${({ maxWidth }) => maxWidth};

  ${({ theme }) => theme.mediaQueries.desktop} {
    max-width: ${({ maxWidth }) => maxWidth ?? '630px'};
    padding: 3.5rem 0rem 3.5rem 3.5rem;
  }
  ${({ theme }) => theme.mediaQueries.largeDesktop} {
    max-width: ${({ maxWidth }) => maxWidth ?? '820px'};
    padding: 10rem 0rem 0rem 13rem;
  }
`;
export const OnboardingTitleWrapper = styled.div``;
export const OnboardingBodyWrapper = styled.div`
  padding: 2rem 0;
`;
export const OnboardingFooterWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  width: ${({ width }) => width ?? '100%'};
  background-color: white;
  padding: 5px 0;
  gap: 15px;
  flex-wrap: wrap;

  ${({ theme }) => `@media ${theme.responsive.max.xxs}`} {
    gap: 10px;
    flex-direction: column-reverse;
    align-items: stretch;

    &&& > div > button {
      width: 100%;
    }
  }
`;
