import { nanoid } from 'nanoid';
import { useMemo } from 'react';

export default function Analyze2Icon() {
  const maskId = useMemo(() => nanoid(), []);
  const clipId = useMemo(() => nanoid(), []);

  return (
    <svg
      fill="currentColor"
      height="17"
      viewBox="0 0 17 17"
      width="17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#${clipId})`}>
        <mask
          height="17"
          id={maskId}
          maskUnits="userSpaceOnUse"
          style={{ maskType: 'alpha' }}
          width="17"
          x="0"
          y="0"
        >
          <path
            clipRule="evenodd"
            d="M15.8667 8.5C15.8667 8.42187 15.8654 8.34402 15.863 8.26646L16.9955 8.22086C16.9985 8.31354 17 8.4066 17 8.5C17 13.1864 13.1864 17 8.5 17C7.56881 17 6.67208 16.8494 5.83288 16.5714L6.21026 15.5021C6.93134 15.7387 7.70107 15.8667 8.5 15.8667C12.5605 15.8667 15.8667 12.5605 15.8667 8.5ZM1.29956 10.0593L0.169256 10.1923C0.0582699 9.64525 0 9.07929 0 8.5C0 3.81357 3.81357 2.04847e-07 8.5 0C9.91483 -6.18456e-08 11.2501 0.347582 12.4249 0.961829L11.9655 2.00122C10.9319 1.44755 9.75179 1.13333 8.5 1.13333C4.4395 1.13333 1.13333 4.4395 1.13333 8.5C1.13333 9.03481 1.19069 9.55653 1.29956 10.0593ZM5.02281 8.57273C5.02281 6.69681 6.55128 5.16834 8.4272 5.16834C10.3031 5.16834 11.8316 6.69681 11.8316 8.57273C11.8316 10.4487 10.3031 11.9771 8.4272 11.9771C6.55128 11.9771 5.02281 10.4487 5.02281 8.57273ZM8.4272 4.03501C5.92535 4.03501 3.88948 6.07089 3.88948 8.57273C3.88948 11.0746 5.92535 13.1105 8.4272 13.1105C10.929 13.1105 12.9649 11.0746 12.9649 8.57273C12.9649 6.07089 10.929 4.03501 8.4272 4.03501Z"
            fillRule="evenodd"
          />
        </mask>
        <g mask={`url(#${maskId})`}>
          <rect height="20" width="20" x="-2" y="-1" />
        </g>
      </g>
      <defs>
        <clipPath id={clipId}>
          <rect height="17" width="17" />
        </clipPath>
      </defs>
    </svg>
  );
}
