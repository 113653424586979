import { refreshToken } from '@/apis/semji/api';
import {
  REQUEST_ERROR_RETRIES,
  REQUEST_ERROR_TIMEOUT,
  SEMJI_MAINTENANCE_ERRORS,
} from '@/apis/semji/constants';
import { logoutUser } from '@/store/actions/user';
import Store from '@/store/configureStore';
import {
  COOKIE_IMPERSONATE_KEY_NAME,
  COOKIE_REFRESH_TOKEN_KEY_NAME,
  COOKIE_TOKEN_KEY_NAME,
  getCookie,
} from '@/utils/cookies/cookies';
import { Log } from '@/utils/log/Log';

const clientFetch = (url, options, clientOptions = {}) => {
  const controller = new AbortController();
  const signal = controller.signal;

  const promise = new Promise(async (resolve, reject) => {
    try {
      const dispatch = Store.get().dispatch;
      const userImpersonate = getCookie(COOKIE_IMPERSONATE_KEY_NAME);
      const token = getCookie(COOKIE_TOKEN_KEY_NAME);

      url = new URL(url);
      let { disableImpersonation = false, requestRetries = 0 } = clientOptions;
      let authorizationHeader = {};
      if (token) {
        authorizationHeader = { Authorization: 'Bearer ' + token };

        const userRefreshToken = getCookie(COOKIE_REFRESH_TOKEN_KEY_NAME);

        if (!userRefreshToken) {
          dispatch(logoutUser());
          reject('Missing refresh_token');
        }
      }

      let impersonateHeader = {};
      if (!disableImpersonation && userImpersonate) {
        impersonateHeader = {
          'X-Switch-User': userImpersonate,
        };
      }

      const { pathname = '', search = '', hash = '' } = window.location;
      const route = `${pathname}${search}${hash}`;

      let response = await fetch(url, {
        headers: new Headers({
          Accept: 'application/ld+json,application/json',
          'Content-Type': 'application/json',
          'x-semji-client-route': route,
          'x-semji-client-type': import.meta.env.VITE_REACT_APP_CLIENT_TYPE,
          'x-semji-client-version': import.meta.env.VITE_REACT_APP_VERSION,
          ...authorizationHeader,
          ...impersonateHeader,
        }),
        ...options,
        signal,
      });

      // // Handle deployment / maintenance errors of the api.
      if (SEMJI_MAINTENANCE_ERRORS.includes(response.status)) {
        if (requestRetries < REQUEST_ERROR_RETRIES) {
          ++requestRetries;
          response = new Promise((resolve, reject) => {
            setTimeout(() => {
              (async function () {
                try {
                  const res = await clientFetch(url, options, { ...clientOptions, requestRetries });
                  resolve(res);
                } catch (e) {
                  reject(e);
                }
              })();
            }, REQUEST_ERROR_TIMEOUT);
          });

          const res = await response;
          resolve(res);
        } else {
          reject(response);
        }
      }

      // we check the specific message to not handle this process on invalid credential
      if (
        [401].includes(response.status) &&
        !response.url.includes('login_check') &&
        !response.url.includes('token_refresh')
      ) {
        try {
          const userRefreshToken = getCookie(COOKIE_REFRESH_TOKEN_KEY_NAME);

          if (!userRefreshToken) {
            dispatch(logoutUser());
            throw new Error('Missing refresh_token');
          }

          const { token } = await refreshToken(userRefreshToken);

          response = await fetch(url, {
            headers: new Headers({
              Accept: 'application/ld+json,application/json',
              'Content-Type': 'application/json',
              ...{ ...authorizationHeader, Authorization: 'Bearer ' + token },
              ...impersonateHeader,
            }),
            ...options,
            signal,
          });
        } catch (err) {
          dispatch(logoutUser());
          reject(err);
        }
      }
      if ([204, 202].includes(response.status)) {
        resolve();
      } else if (response.ok) {
        try {
          if (response.headers.get('Content-Type').includes('json')) {
            const json = await response.json();

            resolve(json);
          }

          resolve(response);
        } catch (error) {
          reject(error);
        }
      } else if (response?.headers?.get?.('Content-Type').includes('json')) {
        const json = await response.json();
        reject({ ...json, status: response.status });
      } else {
        reject(response);
      }
    } catch (err) {
      if (err && err.name === 'AbortError') {
        Log.log(`${url.pathname} request cancelled`);
      } else {
        reject(err);
      }
    }
  });

  promise.cancel = () => controller.abort();
  return promise;
};

export const client = {
  delete(url, data = null) {
    let options = {
      method: 'DELETE',
    };
    if (data) {
      options.body = JSON.stringify(data);
    }
    return clientFetch(url, options);
  },
  get(url, data = {}) {
    return clientFetch(client.getFullUrl(url, data), {
      method: 'GET',
    });
  },
  getFullUrl(url, data) {
    let urlClass = new URL(url);
    Object.keys(data)
      .filter((key) => data[key] !== undefined)
      .forEach((key) => {
        if (Array.isArray(data[key])) {
          data[key].forEach((value) => {
            urlClass.searchParams.append(key + '[]', value);
          });
        } else if ('object' === typeof data[key]) {
          for (const [subKey, value] of Object.entries(data[key])) {
            urlClass.searchParams.append(`${key}[${subKey}]`, value);
          }
        } else {
          urlClass.searchParams.append(key, data[key]);
        }
      });

    return urlClass.href;
  },
  post(url, data = {}, filters = {}) {
    return clientFetch(client.getFullUrl(url, filters), {
      body: JSON.stringify(data),
      method: 'POST',
    });
  },
  put(url, data = {}) {
    return clientFetch(url, {
      body: JSON.stringify(data),
      method: 'PUT',
    });
  },
};
