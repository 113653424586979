import './CreditLimitationSettingsModal.scss';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DialogModal, ENUM_DIALOG_MODAL_SIZE } from 'semji-core/components/DialogModal';
import { Input, InputIcon } from 'semji-core/components/Input';
import { ENUM_INPUT_VARIANT } from 'semji-core/components/Search';
import { Select } from 'semji-core/components/Select';
import { SelectDropdownItem } from 'semji-core/components/Select';
import { Separator } from 'semji-core/components/Separator';
import { Tooltip } from 'semji-core/components/Tooltip';

import useGetWorkspaceUnitLimitationsByOrganizationId from '@/apis/semji/workspaceUnitLimitations/useGetWorkspaceUnitLimitationsByOrganizationId';
import usePostWorkspaceUnitLimitation from '@/apis/semji/workspaceUnitLimitations/usePostWorkspaceUnitLimitation';
import usePutWorkspaceUnitLimitation from '@/apis/semji/workspaceUnitLimitations/usePutWorkspaceUnitLimitation';
import NoSearchFound from '@/components/AGGrid/NoSearchFound/NoSearchFound';
import CountryLanguageFlag from '@/components/Flag/CountryLanguage';
import SearchIcon from '@/components/icons/SearchIcon';
import Loader from '@/components/Loader/Loader';
import { showSuccessSnackbar } from '@/store/actions/ui';
import { getOrganizationById } from '@/store/reducers';
import { AI_WRITING_CREDITS_TYPE } from '@/utils/constants';

export function CreditLimitationSettingsModal({
  showUnitLimitationModal,
  setShowUnitLimitationModal,
}) {
  const { t } = useTranslation();
  const { organizationId } = useParams();
  const [searchValue, setSearchValue] = useState('');
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [state, setState] = useState({});

  const LIMIT_DURATIONS = [
    { title: t('billing:credit-limitation.duration.365'), value: 365 },
    { title: t('billing:credit-limitation.duration.90'), value: 90 },
    { title: t('billing:credit-limitation.duration.30'), value: 30 },
  ];

  const dispatch = useDispatch();

  const {
    data,
    refetch: refetchLimitations,
    isFetching,
    isPlaceholderData,
  } = useGetWorkspaceUnitLimitationsByOrganizationId();

  const postWorkspaceUnitLimitation = usePostWorkspaceUnitLimitation({
    onSuccess: async () => {
      refetchLimitations();
    },
  });

  const putWorkspaceUnitLimitation = usePutWorkspaceUnitLimitation({
    onSuccess: async () => {
      refetchLimitations();
    },
  });

  const availableWorkspaces = useSelector(
    (state) => getOrganizationById(state, organizationId)?.workspaces
  );

  const loading = isFetching || isPlaceholderData;

  const filterdWorkspaces = availableWorkspaces?.filter(
    (w) => !w?.disabledAt && w.name?.toLowerCase()?.includes(searchValue?.toLowerCase())
  );

  function handleSearchChange(e) {
    setSearchValue(e.target.value);
  }

  function onClose() {
    setSearchValue('');
    setShowUnitLimitationModal(false);
    setIsSearchOpen(false);
  }

  function onConfirm() {
    if (Object.keys(state).length === 0) {
      return;
    }

    for (const [key, value] of Object.entries(state)) {
      if (value.id) {
        putWorkspaceUnitLimitation.mutate({
          workspaceId: key,
          ...value,
        });
      } else {
        postWorkspaceUnitLimitation.mutate({
          workspaceId: key,
          ...value,
        });
      }
      onClose();
    }
    setState({});
    setIsSearchOpen(false);
    refetchLimitations();
    dispatch(
      showSuccessSnackbar(
        t('settings:workspace.content-status-settings.snackbar-update-workspace-limitation-success')
      )
    );
  }

  function getWorkspaceUnitLimitation(workspaceId) {
    return (
      data?.find((d) => d.workspaceId === workspaceId && d.type === AI_WRITING_CREDITS_TYPE) ?? {}
    );
  }

  const DropdownItem = ({ optionValue, ...props }) => (
    <SelectDropdownItem {...props}>
      <span>{optionValue.title}</span>
    </SelectDropdownItem>
  );

  function onSearchClick() {
    setIsSearchOpen(true);
  }

  function onSelectChange(item, workspaceId, workspaceUnitLimitationId) {
    setState({
      ...state,
      [workspaceId]: {
        ...state[workspaceId],
        id: workspaceUnitLimitationId,
        limitationDuration: item?.value,
      },
    });
  }

  function onInputChange(e, workspaceId, workspaceUnitLimitationId) {
    setState({
      ...state,
      [workspaceId]: {
        ...state[workspaceId],
        amount: e.target.value ? Number(e.target.value) : null,
        id: workspaceUnitLimitationId,
      },
    });
  }

  function handleKeyDown(event) {
    if (event.key === 'Enter') {
      onConfirm();
    }
    const pattern = /-|\+|\./g;
    if (pattern.test(event.key)) {
      event.preventDefault();
      return;
    }
  }

  function onReset() {
    setSearchValue('');
    setIsSearchOpen(false);
  }

  return (
    <DialogModal
      ariaDescribedby="alert-dialog-description"
      ariaLabelledby="alert-dialog-title"
      cancelAction={onClose}
      cancelLabel={t('common:labels.cancel')}
      canClickOutsideToClose
      closable
      confirmAction={onConfirm}
      confirmLabel={t('common:labels.update')}
      description={t('billing:credit-limitation.settings.description')}
      isConfirmDisabled={loading}
      isOpen={showUnitLimitationModal}
      size={ENUM_DIALOG_MODAL_SIZE.Large}
      title={t('billing:credit-limitation.settings.title')}
      onClose={onClose}
    >
      {loading ? (
        <Loader opaque style={{ fontSize: '1.5rem' }} />
      ) : (
        <div className="credit-limitation-modal">
          <div className="credit-limitation-modal__header">
            <div className="credit-limitation-modal__header__workspace">
              <Tooltip
                isFrame={false}
                noDivider={true}
                popoverOptions={{ placement: 'top' }}
                title={t(`layout:profile.search`)}
              >
                <SearchIcon
                  className="credit-limitation-modal__header__workspace__icon"
                  onClick={onSearchClick}
                />
              </Tooltip>

              {isSearchOpen ? (
                <InputIcon
                  autoFocus
                  placeholder={t('layout:profile.search-workspace')}
                  titleTooltipCloseIcon={t(`layout:profile.clear-and-close`)}
                  variant={ENUM_INPUT_VARIANT.Borderless}
                  onChange={handleSearchChange}
                  onReset={onReset}
                />
              ) : (
                <span className="credit-limitation-modal__header__workspace">
                  {t('billing:credit-limitation.workspace')}
                </span>
              )}
            </div>

            <span className="credit-limitation-modal__header__limit">
              {t('billing:credit-limitation.credit-limit')}
            </span>
            <span className="credit-limitation-modal__header__period">
              {t('billing:credit-limitation.maximum-period')}
            </span>
          </div>

          <div className="credit-limitation-modal__content">
            <Separator />
            {filterdWorkspaces?.map((workspace) => {
              const workspaceUnitLimitation = getWorkspaceUnitLimitation(workspace.id);
              const amount = workspaceUnitLimitation?.amount ?? 0;
              const remainingAmount = workspaceUnitLimitation?.remainingAmount ?? amount;
              let durationIndex = LIMIT_DURATIONS.findIndex(
                (element) => element.value === workspaceUnitLimitation?.limitationDuration
              );
              durationIndex = durationIndex === -1 ? 0 : durationIndex;
              return (
                <div key={workspace.id} className="credit-limitation-modal__content__row">
                  <div className="credit-limitation-modal__content__row__name">
                    <CountryLanguageFlag
                      countryCode={workspace.countryCode}
                      isObvious={workspace.officialLanguages.length === 1 ? true : false}
                      languageCode={workspace.languageCode}
                    />
                    <span className="credit-limitation-modal__content__row__name__label">
                      {workspace.name}
                    </span>
                  </div>
                  <div className="credit-limitation-modal__content__row__input-wrapper">
                    <Input
                      autoComplete="off"
                      defaultValue={workspaceUnitLimitation?.amount}
                      min="0"
                      name={`credit-limitation-${workspace.id}`}
                      placeholder={t('billing:credit-limitation.no-limit')}
                      type="number"
                      withoutLabel={true}
                      onChange={(e) => onInputChange(e, workspace.id, workspaceUnitLimitation?.id)}
                      onKeyDown={handleKeyDown}
                    />
                    <Tooltip
                      isFrame={false}
                      noDivider={true}
                      popoverOptions={{ placement: 'top' }}
                      title={t('billing:credit-limitation.consumed-credits', {
                        amount,
                        consumed: amount - remainingAmount,
                      })}
                    >
                      {workspaceUnitLimitation?.amount && (
                        <span className="credit-limitation-modal__content__row__input-wrapper__unit">
                          {amount - remainingAmount} / {amount}
                        </span>
                      )}
                    </Tooltip>
                  </div>
                  <Select
                    className="credit-limitation-modal__content__row__select"
                    defaultValueIndex={durationIndex}
                    DropdownItem={DropdownItem}
                    dropdownPlacement="bottom-start"
                    getValue={(item) => item?.title}
                    isFrame={false}
                    offsetValue={0}
                    options={LIMIT_DURATIONS}
                    variant="domain"
                    width="232px"
                    onChange={(value) =>
                      onSelectChange(value, workspace.id, workspaceUnitLimitation?.id)
                    }
                  />
                </div>
              );
            })}
            {filterdWorkspaces?.length === 0 && (
              <div className="credit-limitation-modal__content__not-found">
                <NoSearchFound showResetButton={false} />{' '}
              </div>
            )}
          </div>
        </div>
      )}
    </DialogModal>
  );
}
