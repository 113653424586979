import { nanoid } from 'nanoid';
import React, { useMemo } from 'react';

import SvgWrapper from './SvgWrapper';

const SidePanelSEOIcon = (props) => {
  const maskId = useMemo(() => nanoid(), []);
  return (
    <SvgWrapper {...props} height="20" viewBox="0 0 18 20" width="18">
      <mask
        height="12"
        id={`mask${maskId}`}
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="18"
        x="0"
        y="3"
      >
        <path
          d="M18 12.6765V13.2059H11.4692V12.1471H16.9242C16.8056 10.3556 16.0888 8.72604 14.9739 7.45228L14.1205 8.30569L13.3719 7.5571L14.2211 6.70793C12.9484 5.59298 11.3199 4.8804 9.52941 4.76075V7.91181H8.47059V4.76075C6.68012 4.8804 5.05165 5.59298 3.78 6.70793L4.62282 7.55075L3.87424 8.29934L3.02718 7.45228C1.91224 8.72604 1.19435 10.3556 1.07682 12.1471H6.552V13.2059H0V12.6765C0 7.71381 4.03729 3.67651 9 3.67651C13.9627 3.67651 18 7.71381 18 12.6765ZM11.1176 12.6765C11.1176 13.8444 10.1679 14.7942 9 14.7942C7.83212 14.7942 6.88235 13.8444 6.88235 12.6765C6.88235 11.9946 7.21165 11.3953 7.71247 11.0067L5.508 7.16322L6.426 6.63698L8.69294 10.5896C8.79353 10.5747 8.89412 10.5589 9 10.5589C10.1679 10.5589 11.1176 11.5086 11.1176 12.6765ZM10.0588 12.6765C10.0588 12.0931 9.58341 11.6177 9 11.6177C8.41659 11.6177 7.94118 12.0931 7.94118 12.6765C7.94118 13.2599 8.41659 13.7353 9 13.7353C9.58341 13.7353 10.0588 13.2599 10.0588 12.6765Z"
          fill="black"
        />
      </mask>
      <g mask={`url(#mask${maskId})`}>
        <rect fill="currentColor" height="21.1765" width="21.1765" x="-2.11719" y="-0.558838" />
      </g>
    </SvgWrapper>
  );
};

export default SidePanelSEOIcon;
