import './Listing.scss';

import React from 'react';
export default function Listing() {
  return (
    <div className="brand-voice-listing">
      <h1>BrandVoice</h1>
    </div>
  );
}
