import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import AlertAltIcon from '@/components/icons/AlertAltIcon';
import BoxIcon from '@/components/icons/BoxIcon';
import { FAILED_STATUS, PENDING_STATUS } from '@/components/Pages/ContentIdeas/constant';
import { FALSE_VALUE, TRUE_VALUE } from '@/components/Pages/ContentIdeas/Listing/constant';
import { FlexTooltipComponent } from '@/components/Tooltip/Tooltip';
import Clusters from '@/containers/ContentIdeas/Listing/Panel/Clusters';
import PanelElementRender from '@/containers/ContentIdeas/Listing/Panel/PanelElementRender';
import Flex from '@/design-system/components/Flex/Flex';
import defaultTheme from '@/themes/defaultTheme';

const InfoIcon = styled(AlertAltIcon)`
  && {
    width: 16px;
    height: 24px;
  }
`;

function getContentIdeasClustersFiltered(contentIdeasListFiltered, contentIdeasClusters) {
  if (contentIdeasClusters) {
    Object.values(contentIdeasClusters).forEach((cluster) => (cluster.occurrences = 0));
    contentIdeasListFiltered.forEach(({ isArchived, contentIdeaClusterIds }) => {
      if (!isArchived) {
        if (contentIdeaClusterIds.length) {
          contentIdeaClusterIds.forEach((id) => contentIdeasClusters[id].occurrences++);
        }
      }
    });
  }
  return contentIdeasClusters;
}

function Panel({ contentIdeasList, contentIdeasClusters, contentIdeasLoading, status }) {
  const { t } = useTranslation();
  const [query, setQuery] = useSearchParams();

  const archivedType = query.get('archivedType');
  const clusterId = query.get('clusterId');

  const contentIdeasClustersFiltered = getContentIdeasClustersFiltered(
    contentIdeasList,
    contentIdeasClusters
  );

  const archivedContentIdeaLength = contentIdeasList.filter(
    (contentIdea) => contentIdea.isArchived
  ).length;

  function handleClickContentIdeas() {
    query.set('archivedType', FALSE_VALUE);
    query.set('clusterId', FALSE_VALUE);
    setQuery(query);
  }

  function handleClickArchive() {
    query.set('archivedType', TRUE_VALUE);
    query.set('clusterId', FALSE_VALUE);
    setQuery(query);
  }

  function handleClickCluster(clusterId) {
    query.set('archivedType', FALSE_VALUE);
    query.set('clusterId', clusterId);
    setQuery(query);
  }

  const isLoading = contentIdeasLoading || status === PENDING_STATUS;
  const isClusterizationFailed = status === FAILED_STATUS;
  return (
    <Flex flexDirection="column" width="100%">
      <Flex data-intercom-target="content_ideas_panel_clusters" flexDirection="column" gap="12px">
        <Flex>
          <PanelElementRender
            active={archivedType === FALSE_VALUE && clusterId === FALSE_VALUE}
            endAdornment={
              isClusterizationFailed ? (
                <FlexTooltipComponent
                  title={t('content-ideas:listing.panel.cluster-tooltip-message')}
                >
                  <InfoIcon />
                </FlexTooltipComponent>
              ) : (
                contentIdeasList.length - archivedContentIdeaLength
              )
            }
            isClusterizationFailed={isClusterizationFailed}
            isLoading={isLoading}
            isMainElement
            label={t('content-ideas:listing.panel.all-clusters')}
            onClick={handleClickContentIdeas}
          />
        </Flex>
        <Clusters
          contentIdeasClusters={contentIdeasClustersFiltered}
          isLoading={isLoading}
          onClick={handleClickCluster}
        />
        <Flex backgroundColor="dark010" height="1px" width="100%" />
        <Flex>
          <PanelElementRender
            active={archivedType === TRUE_VALUE}
            endAdornment={archivedContentIdeaLength}
            isLoading={isLoading}
            label={t('content-ideas:listing.panel.archived')}
            startAdornment={
              <BoxIcon
                fill={archivedType === TRUE_VALUE && defaultTheme.cssColors.primary}
                style={{ fontSize: '1rem', marginRight: '5px' }}
              />
            }
            onClick={handleClickArchive}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Panel;
