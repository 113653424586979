/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import './FloatingAI.scss';

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'semji-core/components/Tooltip';
import { PlusIcon } from 'semji-core/icons/PlusIcon';

import { useContentContainerContext } from '@/containers/Content/ContentContainerContext';
import {
  CONTENT_SELECTOR,
  ID_CONTENT,
  ID_METADESCRIPTION,
  ID_TITLE,
  METADESCRIPTION_WRAPPER_SELECTOR,
  TITLE_CONTENT_CONTAINER_SELECTOR,
} from '@/containers/Content/TinyMceComponents/Editor/constants';
import {
  FLOATING_AI_TYPE_ENUM,
  FloatingAIType,
} from '@/containers/Content/TinyMceComponents/Editor/hooks/useEditorPopper/FloatingAI/FloatingAI.types';

const CIRCLE_SIZE = 28;
const TITLE_CONTENT_CONTAINER_GAP = 10;

function FloatingAI({ floatingAIType, inputTitleRef, textareaMetaDescriptionRef }: FloatingAIType) {
  const { t } = useTranslation();
  const {
    editorPopper: { handleOpenInputPopper, openEditorPopper },
  } = useContentContainerContext();

  function handleClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    // If left mouse button
    if (e.button === 0) {
      action();
    }
  }

  const [positions, action] = useMemo((): [
    { left: number | string; top: number | string } | false,
    () => void,
  ] => {
    const aiPlaceholderElement = document.querySelector(`${CONTENT_SELECTOR} > p.ai-placeholder`);
    const contentEditorElement = document.querySelector(`#${ID_CONTENT}`);
    const metaDescriptionWrapperElement = document.querySelector(METADESCRIPTION_WRAPPER_SELECTOR);
    const titleWrapperElement = document.querySelector(
      `${TITLE_CONTENT_CONTAINER_SELECTOR} > .semji-core-input-wrapper`
    );

    if (floatingAIType === FLOATING_AI_TYPE_ENUM.TITLE && inputTitleRef.current) {
      const { height } = inputTitleRef.current.getBoundingClientRect();

      return [
        {
          left: -46,
          top: height / 2 - CIRCLE_SIZE / 2,
        },
        () => {
          if (inputTitleRef.current) {
            inputTitleRef.current.value = '/';
          }
          handleOpenInputPopper(ID_TITLE);
        },
      ];
    } else if (
      floatingAIType === FLOATING_AI_TYPE_ENUM.META_DESCRIPTION &&
      textareaMetaDescriptionRef.current &&
      titleWrapperElement
    ) {
      const titleWrapperPosition = titleWrapperElement.getBoundingClientRect();

      return [
        {
          left: -46,
          top: titleWrapperPosition.height + TITLE_CONTENT_CONTAINER_GAP * 2 + 6,
        },
        () => {
          if (textareaMetaDescriptionRef.current) {
            textareaMetaDescriptionRef.current.value = '/';
          }
          handleOpenInputPopper(ID_METADESCRIPTION);
        },
      ];
    } else if (
      floatingAIType === FLOATING_AI_TYPE_ENUM.CONTENT &&
      aiPlaceholderElement &&
      contentEditorElement &&
      metaDescriptionWrapperElement &&
      titleWrapperElement
    ) {
      const aiPlaceholderPosition = aiPlaceholderElement.getBoundingClientRect();
      const contentEditorPosition = contentEditorElement.getBoundingClientRect();
      const metaDescriptionWrapperPosition = metaDescriptionWrapperElement.getBoundingClientRect();
      const titleWrapperPosition = titleWrapperElement.getBoundingClientRect();

      return [
        {
          left: -46,
          top:
            ((aiPlaceholderPosition.top - contentEditorPosition.top) /
              contentEditorPosition.height) *
              contentEditorPosition.height +
            metaDescriptionWrapperPosition.height +
            titleWrapperPosition.height / 2 +
            TITLE_CONTENT_CONTAINER_GAP * 8 +
            2,
        },
        () => {
          openEditorPopper();
        },
      ];
    } else {
      return [false, () => {}];
    }
  }, [
    floatingAIType,
    handleOpenInputPopper,
    inputTitleRef,
    openEditorPopper,
    textareaMetaDescriptionRef,
  ]);

  if (!positions || !action) {
    return null;
  }

  return (
    <div
      className="floating-ai-wrapper"
      style={{
        left: positions.left,
        top: positions.top,
      }}
      onMouseDown={handleClick}
    >
      <Tooltip
        className="floating-ai-circle"
        placement="top"
        title={
          <div className="flex flex-col items-center gap-1">
            {t('content:editor-components.editor.floating-ai-tooltip')}
            <div className="bg-dark-080 rounded-sm px-1">/</div>
          </div>
        }
      >
        <PlusIcon />
      </Tooltip>
    </div>
  );
}

export default FloatingAI;
