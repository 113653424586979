import { refreshToken } from '@/apis/semji/api';
import instance from '@/apis/semji/apiClient';
import { logoutUser } from '@/store/actions/user';
import Store from '@/store/configureStore';
import {
  COOKIE_IMPERSONATE_KEY_NAME,
  COOKIE_REFRESH_TOKEN_KEY_NAME,
  COOKIE_TOKEN_KEY_NAME,
  getCookie,
} from '@/utils/cookies/cookies';

function setupApiClient() {
  // Request interceptor for API calls
  instance.interceptors.request.use(
    async (config) => {
      if (config._retry) {
        return config;
      }
      const userImpersonate = getCookie(COOKIE_IMPERSONATE_KEY_NAME);
      const token = getCookie(COOKIE_TOKEN_KEY_NAME);

      const { pathname = '', search = '', hash = '' } = window.location;
      const route = `${pathname}${search}${hash}`;

      return {
        ...config,
        headers: {
          ...config.headers,
          ...(!!token ? { Authorization: `Bearer ${token}` } : {}),
          ...(!!userImpersonate
            ? {
                'X-Switch-User': userImpersonate,
              }
            : {}),
          'x-semji-client-route': route,
        },
      };
    },
    (error) => {
      Promise.reject(error);
    }
  );

  // Response interceptor for API calls
  instance.interceptors.response.use(
    (response) => response,
    async function (error) {
      const originalRequest = error?.config;
      const requestUrl = error?.response?.config?.url || '';

      if (
        [401].includes(error?.response?.status) &&
        !requestUrl.includes('login_check') &&
        !requestUrl.includes('token_refresh') &&
        !originalRequest._retry
      ) {
        try {
          originalRequest._retry = true;

          const userRefreshToken = getCookie(COOKIE_REFRESH_TOKEN_KEY_NAME);

          if (!userRefreshToken) {
            throw new Error('Missing refresh_token');
          }

          const { token } = await refreshToken(userRefreshToken);

          return instance({
            ...originalRequest,
            headers: {
              ...originalRequest.headers,
              Authorization: `Bearer ${token}`,
            },
          });
        } catch (e) {
          const dispatch = Store.get().dispatch;

          dispatch(logoutUser());
          return Promise.reject(error);
        }
      }
      return Promise.reject(error);
    }
  );
}

export default setupApiClient;
