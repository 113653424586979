import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

const ImageWrapper = styled(function ({ mode, width, height, ...props }) {
  const { t } = useTranslation();

  return <img {...props} alt={t('components:flag.label-flag')} />;
})`
  border-radius: ${(props) => (props.mode === 'circ' ? '50%' : '3px')};
  width: ${(props) => props.width + 'px'};
  height: ${(props) => props.height + 'px'};
  object-fit: cover;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
`;
const DefaultImageWrapper = styled(({ mode, width, height, ...props }) => <div {...props} />)`
  background: ${(props) => props.theme.colors.lightGreyBlue};
  color: ${(props) => props.theme.text.colors.black};
  border-radius: ${(props) => (props.mode === 'circ' ? '50%' : '3px')};
  width: ${(props) => props.width + 'px'};
  height: ${(props) => props.height + 'px'};
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
`;

// the country code is an Alpha2 Code
function Country({ countryCode, mode, width, height }) {
  const { t } = useTranslation();

  const alpha2CountryCode = countryCode.split('-')[0];
  const [error, setError] = useState(null);

  useEffect(() => {
    setError(null);
  }, [alpha2CountryCode]);

  function onError() {
    setError(true);
  }

  if (error) {
    return (
      <DefaultImageWrapper height={height} mode={mode} width={width}>
        {countryCode}
      </DefaultImageWrapper>
    );
  }

  return (
    <ImageWrapper
      alt={t('components:flag.label-flag')}
      height={height}
      mode={mode}
      src={`/images/flags/${alpha2CountryCode.toLowerCase()}.svg`}
      width={width}
      onError={onError}
    />
  );
}

Country.propTypes = {
  countryCode: PropTypes.string.isRequired,
  height: PropTypes.number,
  mode: PropTypes.oneOf(['circ', 'rect']),
  width: PropTypes.number,
};

Country.defaultProps = {
  height: 50,
  mode: 'circ',
  width: 50,
};

export default Country;
