import React from 'react';

import SvgWrapper from './SvgWrapper';

function PlanIcon(props) {
  return (
    <SvgWrapper {...props} fill="none" height="11" viewBox="0 0 13 11" width="14">
      <svg
        fill="none"
        height="11"
        viewBox="0 0 13 11"
        width="13"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip-plan-icon-0)">
          <path
            d="M10.5268 0.681416H12.5711V11H0.889648V0.681416H2.9339V0H4.97814V0.681416H8.38522V0H10.4295V0.681416H10.5268ZM1.57106 3.50442H11.8896V1.36283H10.5268V2.72566H8.48257V1.36283H4.97814V2.72566H2.9339V1.36283H1.57106V3.50442ZM11.8896 10.3186V4.18584H1.57106V10.3186H11.8896ZM3.61531 0.681416V2.04425H4.29673V0.681416H3.61531ZM9.16398 0.681416V2.04425H9.8454V0.681416H9.16398Z"
            fill="currentColor"
          />
          <path
            d="M8.22363 6.13331L6.22363 8L5.22363 7.06665L5.36646 6.93335L6.22363 7.73338L8.0806 6L8.22363 6.13331Z"
            fill="currentColor"
            stroke="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip-plan-icon-0">
            <rect fill="white" height="11" transform="translate(0.889648)" width="11.6814" />
          </clipPath>
        </defs>
      </svg>
    </SvgWrapper>
  );
}

export default PlanIcon;
