import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import apiClient from '@/apis/semji/apiClient';
import { SCOPE_REPORTS } from '@/apis/semji/constants';
import { QueryOptions } from '@/apis/types/query.types';
import { showErrorSnackbar } from '@/store/actions/ui';
import { ReportFilter, ReportPageMetric } from '@/types/reports';

import { createQueryKeyFromFiltersAndProperties } from '../helper';

interface UsePostReportPageMetricsProps extends QueryOptions<{}, ReportPageMetric[]> {
  payload: {
    pageIds?: string[];
  };
  filters: ReportFilter;
}

export default function usePostReportPageMetrics({
  payload,
  filters,
  ...props
}: UsePostReportPageMetricsProps) {
  const { post } = apiClient;
  const { workspaceId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return useQuery({
    onError: () => {
      dispatch(showErrorSnackbar(t('common:error.default')));
    },
    queryFn: async ({ signal }) => {
      const result = await post(`/workspaces/${workspaceId}/page_metrics`, payload, {
        params: {
          ...filters,
        },
        signal,
      });

      return result.data['hydra:member'] ?? [];
    },
    queryKey: [
      SCOPE_REPORTS.GET_PAGE_METRICS,
      workspaceId,
      ...createQueryKeyFromFiltersAndProperties({ filters: { ...filters, ...payload } }),
    ],
    ...props,
  });
}
