import intersection from 'lodash/intersection';

import {
  FETCH_SERVICE_INTEGRATIONS,
  UPDATE_SERVICE_INTEGRATIONS,
} from '@/store/actions/actionTypes';

export function serviceIntegrations(
  state = { disabledApis: [], hasAnalytics: true, hasSearchConsole: true, services: [] },
  action
) {
  switch (action.type) {
    case FETCH_SERVICE_INTEGRATIONS:
      const disabledApis =
        intersection(state.disabledApis, action.disabledApis).length ===
          action.disabledApis.length && state.disabledApis.length === action.disabledApis.length
          ? state.disabledApis
          : action.disabledApis;

      return {
        ...state,
        disabledApis,
        hasAnalytics: action.hasAnalytics,
        hasSearchConsole: action.hasSearchConsole,
        services: action.serviceIntegrations,
      };

    case UPDATE_SERVICE_INTEGRATIONS:
      const { service } = action;
      const { services } = state;
      const updatedServices = services.map((oldService) =>
        oldService.id === service.id ? service : oldService
      );

      return {
        ...state,
        services: [...updatedServices],
      };
    default:
      return state;
  }
}
