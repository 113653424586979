import { useTranslation } from 'react-i18next';

import Dialog from '@/design-system/components/Dialog';

function DeleteFolderDialog({ isLoading, onConfirm, onCancel, folderName, folderDraftsCount }) {
  const { t } = useTranslation();

  const draftsInterval = t(
    'components:filters.folder-filters-panel.delete-dialog.drafts_interval',
    {
      count: folderDraftsCount,
      postProcess: 'interval',
    }
  );

  return (
    <Dialog
      confirmLabel={t('components:filters.folder-filters-panel.delete-dialog.label-confirm')}
      isLoading={isLoading}
      isOpen={true}
      title={t('components:filters.folder-filters-panel.delete-dialog.title', {
        folderName,
      })}
      onCancel={onCancel}
      onConfirm={onConfirm}
    >
      {t('components:filters.folder-filters-panel.delete-dialog.content_interval', {
        count: folderDraftsCount,
        draftsInterval,
        postProcess: 'interval',
      })}
    </Dialog>
  );
}

export default DeleteFolderDialog;
