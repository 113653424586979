import './index.css';
import 'semji-core/styles';

import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';
import React from 'react';
import ReactDOM from 'react-dom/client';

import Root from './containers/Root';
import Store from './store/configureStore';

const store = Store.get();

const options = {
  info: {
    applicationID: import.meta.env.VITE_REACT_APP_NEW_RELIC_APPLICATION_ID,
    beacon: 'bam.eu01.nr-data.net',
    errorBeacon: 'bam.eu01.nr-data.net',
    licenseKey: import.meta.env.VITE_REACT_APP_NEW_RELIC_LICENSE_KEY,
    sa: 1,
  },
  init: {
    ajax: { deny_list: ['bam.eu01.nr-data.net'] },
    distributed_tracing: { enabled: true },
    privacy: { cookies_enabled: true },
    spa: { autoStart: false },
  },
  loader_config: {
    accountID: import.meta.env.VITE_REACT_APP_NEW_RELIC_ACCOUNT_ID,
    agentID: import.meta.env.VITE_REACT_APP_NEW_RELIC_APPLICATION_ID,
    applicationID: import.meta.env.VITE_REACT_APP_NEW_RELIC_APPLICATION_ID,
    licenseKey: import.meta.env.VITE_REACT_APP_NEW_RELIC_LICENSE_KEY,
    trustKey: import.meta.env.VITE_REACT_APP_NEW_RELIC_ACCOUNT_ID,
  },
};

const browserAgent = new BrowserAgent(options);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Root browserAgent={browserAgent} store={store} />);
