import styled from 'styled-components/macro';

const ListBigItem = styled.div`
  display: flex;
  font-size: ${({ theme }) => theme.textCss.sizes.sm};
  font-weight: ${({ theme }) => theme.textCss.weights.strong};
  line-height: ${({ theme }) => theme.textCss.sizes.xl};
  color: ${({ theme, selected }) => (selected ? theme.cssColors.primary : theme.cssColors.dark080)};
  padding: 4px 22px 4px 22px;
  margin-top: ${({ marginTop }) => marginTop ?? '20px'};
  margin-bottom: 2px;
  cursor: pointer;
  border-radius: 0.5rem;
  background-color: ${({ theme, selected }) => selected && theme.colors.primaryPink5};

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryPink5};
  }

  & > :first-child {
    flex: 1;
    user-select: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export default ListBigItem;
