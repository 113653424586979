import { nanoid } from 'nanoid';
import { useMemo } from 'react';

const ZoomOut = (props) => {
  const maskId = useMemo(() => nanoid(), []);
  return (
    <svg
      fill="none"
      height="17"
      viewBox="0 0 17 17"
      width="17"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_2143_42)">
        <mask
          height="18"
          id={`mask${maskId}`}
          maskUnits="userSpaceOnUse"
          style={{ maskType: 'alpha' }}
          width="17"
          x="0"
          y="-1"
        >
          <path
            d="M16.604 15.8679L11.431 10.6949C12.406 9.55792 13 8.08392 13 6.47192C13 2.88792 10.084 -0.0280762 6.5 -0.0280762C4.764 -0.0280762 3.131 0.647924 1.902 1.87492C0.675001 3.10292 -0.000998892 4.73592 1.10788e-06 6.47192C1.10788e-06 10.0559 2.916 12.9719 6.5 12.9719C8.112 12.9719 9.587 12.3779 10.724 11.4029L15.897 16.5759L16.604 15.8679ZM6.5 11.9719C3.468 11.9719 1 9.50492 1 6.47192C0.999001 5.00192 1.571 3.62092 2.61 2.58292C3.648 1.54392 5.03 0.971924 6.5 0.971924C9.532 0.971924 12 3.43892 12 6.47192C12 9.50392 9.532 11.9719 6.5 11.9719ZM4 5.97192H9V6.97192H4V5.97192Z"
            fill="black"
          />
        </mask>
        <g mask={`url(#mask${maskId})`}>
          <rect fill="#252736" height="20" width="20" x="-2" y="-1" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2143_42">
          <rect fill="white" height="17" width="17" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default ZoomOut;
