import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import CreateNewDraft from '@/components/CreateNewDraft';
import { getDefaultFolderGeneral } from '@/components/Select/FolderSelect/constants';
import { Info } from '@/components/Text/Info';
import { NewDraftContext } from '@/containers/Drafts/CreateDrafts';
import useIsUserLimitedGuestWriter from '@/hooks/useIsUserLimitedGuestWriter';
import useNullUser from '@/hooks/useNullUser';
import useWorkspaceStatuses from '@/hooks/useWorkspaceStatuses';
import useWorkspaceUsers from '@/hooks/useWorkspaceUsers';
import { NEW_DRAFTS_LIMIT_CREATION } from '@/utils/constants';

const DEFAULT_TAB = 'open';

const Wrapper = styled.div`
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  background: white;
  padding: 2em;
  margin: 1em 0;
  max-height: 400px;
  overflow: auto;
`;
const InfoWrapper = styled.div`
  padding: 5px;
`;
const NEW_DRAFTS_LIST_LOADING_KEY = 'NEW_DRAFTS_LIST_LOADING_KEY';
const NEW_DRAFTS_LIST_LOADED_KEY = 'NEW_DRAFTS_LIST_LOADED_KEY';

export default function NewContentsCreation({
  isLimitAchieved,
  automaticallyAddRow,
  loading,
  setDefaultStatusId,
  setNewDrafts,
  workspaceCountryName,
  workspaceId,
  inputValidation,
  setInputValidation,
}) {
  const { t } = useTranslation();
  const DEFAULT_FOLDER_GENERAL = getDefaultFolderGeneral();
  const nullUser = useNullUser();
  const [query] = useSearchParams();

  const defaultUserId = useSelector((state) => state.user.id);
  const users = useWorkspaceUsers();
  const statuses = useWorkspaceStatuses();
  const isUserLimitedGuestWriter = useIsUserLimitedGuestWriter();

  const tab = query.get('tab') || DEFAULT_TAB;
  const isInFolderTab = tab.startsWith('folder-');
  const folderIdFromQuery = isInFolderTab && tab.split('-')[1];

  useEffect(() => {
    if (statuses.length > 0) {
      setNewDrafts([
        {
          dueDate: null,
          focusKeyword: '',
          folderId: folderIdFromQuery || DEFAULT_FOLDER_GENERAL.id,
          statusId: statuses[0]?.id,
          title: '',
          // Auto assign current user to drafts if the user is a LIMITED_GUEST_WRITER
          userId: isUserLimitedGuestWriter ? defaultUserId : nullUser.id,
        },
      ]);
      setDefaultStatusId(statuses[0]?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statuses, tab]);

  return (
    <>
      <Wrapper id="creation-wrapper">
        <NewDraftContext.Consumer>
          {(newDrafts) => (
            <CreateNewDraft
              key={loading ? NEW_DRAFTS_LIST_LOADING_KEY : NEW_DRAFTS_LIST_LOADED_KEY}
              assignationReadOnly={isUserLimitedGuestWriter}
              automaticallyAddRow={automaticallyAddRow}
              defaultUserId={defaultUserId}
              folderIdFromQuery={folderIdFromQuery}
              getList={setNewDrafts}
              inputValidation={inputValidation}
              isLimitAchieved={isLimitAchieved}
              loading={loading}
              rows={newDrafts}
              setInputValidation={setInputValidation}
              statuses={statuses.filter((status) => !status.publish)}
              users={users}
              workspaceCountryName={workspaceCountryName}
              workspaceId={workspaceId}
            />
          )}
        </NewDraftContext.Consumer>
      </Wrapper>
      <InfoWrapper>
        {isLimitAchieved && (
          <Info>
            {t('drafts:new-contents.creation.limit-reached', {
              limit: NEW_DRAFTS_LIMIT_CREATION,
            })}
          </Info>
        )}
      </InfoWrapper>
    </>
  );
}
