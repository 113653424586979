import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';

import InfoIcon from '@/components/icons/InfoIcon';

const Button = styled.button`
  display: ${(props) => props.defaultDisplay ?? 'block'};
  font-family: ${(props) => props.theme.textCss.fonts.main};
  text-decoration: none;
  padding: ${(props) => props.padding ?? '0 1rem'};
  margin: ${(props) => (props.withMargin ? '1rem 0' : '0')};
  font-size: ${(props) => props.theme.textCss.sizes[props.size || 'sm']};
  font-weight: ${(props) => props.theme.textCss.weights[props.weight || 'medium']};
  min-height: ${(props) => (props.height === 'big' ? '40px' : '35px')};
  max-height: ${(props) => (props.height === 'big' ? '40px' : '35px')};
  width: ${(props) => props.fullWidth && '100%'};
  white-space: nowrap;
  min-width: ${(props) => props.minWidth ?? '150px'};
  letter-spacing: 0.75px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  border-radius: 3px;
  &:first-letter {
    text-transform: uppercase;
  }
`;

export const ContainedButton = styled(Button)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) =>
      props.disabled
        ? props.theme.cssColors.dark060
        : props.theme.cssColors[props.color ?? 'white']};
    background-color: ${(props) =>
      props.disabled
        ? props.theme.cssColors.dark020
        : props.theme.cssColors[props.bgColor ?? 'primary']};
    border: 0;
    ${({ width }) => width && `width: ${width}`};
  }
`;

export const OutlinedButton = styled(Button)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) =>
      props.disabled
        ? props.theme.cssColors.dark020
        : props.theme.cssColors[props.color ?? 'primary']};
    background-color: ${({ $bgColor, theme }) =>
      $bgColor ? theme.cssColors[$bgColor] : 'transparent'};
    border: 1px solid
      ${(props) =>
        props.disabled
          ? props.theme.cssColors.dark020
          : props.theme.cssColors[props.color ?? 'primary']};
    ${({ $hasNoBorder }) => $hasNoBorder && 'border: none;'}
    ${({ width }) => width && `width: ${width}`};
  }
`;

export const TextButton = styled(Button)`
  && {
    font-family: ${(props) => props.theme.textCss.fonts.main};
    text-decoration: none;
    color: ${(props) =>
      props.disabled
        ? props.theme.cssColors.dark020
        : props.theme.cssColors[props.color ?? 'dark060']};
    background-color: transparent;
    border: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`;
export const TextButtonLink = styled(Button)`
  && {
    font-family: ${(props) => props.theme.textCss.fonts.main};
    text-decoration: none;
    color: ${(props) => props.theme.cssColors.primary};
    background-color: transparent;
    border: 0;
    padding: 0;
    min-width: 0;
  }
`;

export const PrimaryButton = styled.button`
  && {
    font-family: ${(props) => props.theme.text.font};
    text-decoration: none;
    color: ${(props) =>
      props.disabled ? props.theme.text.colors.bright : props.theme.text.colors.white};
    background-color: ${(props) =>
      props.disabled ? props.theme.colors.quarterGrey : props.theme.colors.secondary};
    padding: 0px 20px;
    font-size: ${(props) => props.theme.text.sizes.small};
    font-weight: 500;
    border: 0;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    border-radius: 3px;
    min-height: 35px;
    max-height: 35px;
    margin: ${(props) => props.withMargin && '0 .5rem'};
    white-space: nowrap;
  }
`;

export const SecondaryButton = styled.button`
  && {
    font-family: ${(props) => props.theme.text.font};
    text-decoration: none;
    color: ${(props) => props.theme.textCss.colors.brightGrey};
    background-color: ${(props) =>
      props.inverted ? props.theme.colors.lightGreyBlue : 'transparent'};
    padding: 0px 20px;
    font-size: ${(props) => props.theme.text.sizes.small};
    font-weight: 500;
    border: ${(props) =>
      props.inverted ? 0 : '1px solid ' + props.theme.textCss.colors.brightGrey};
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    border-radius: 3px;
    min-height: 35px;
    opacity: ${(props) => (props.disabled ? 0.7 : 1)};
  }
`;

export const TertiaryButton = styled.button`
  && {
    font-family: ${(props) => props.theme.text.font};
    text-decoration: none;
    color: ${(props) =>
      props.inverted ? props.theme.colors.secondary : props.theme.textCss.colors.brightGrey};
    background-color: transparent;
    padding: ${(props) => (props.inverted ? '5px' : '0px 20px')};
    margin-left: ${(props) => (props.inverted ? '2px' : '5px')};
    font-size: ${(props) =>
      props.size === 'small' ? props.theme.text.sizes.small : props.theme.text.sizes.default};
    font-weight: 400;
    border: 0;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    min-height: 35px;
    opacity: ${(props) => (props.disabled ? 0.7 : 1)};
  }
`;

export const ActionButton = styled.div`
  color: ${(props) => props.theme.colors.secondary};
  font-family: ${(props) => props.theme.text.font};
  font-size: 0.9em;
  margin: 0 auto;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  text-decoration: underline;
`;

export const NavButton = styled(NavLink)`
  && {
    display: flex;
    color: ${(props) => props.theme.text.colors.white};
    background: ${(props) => props.theme.colors.secondary};
    font-family: ${(props) => props.theme.text.font};
    font-size: ${(props) => props.theme.text.sizes.small};
    font-weight: 400;
    border-radius: 3px;
    padding: 0px 20px;
    min-height: 35px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
  }
`;

export const FloatingButton = styled(PrimaryButton)`
  width: 200px;
  position: absolute;
  bottom: 1em;
  left: calc(50% - 100px);
`;

export const IconButton = styled.button`
  && {
    visibility: ${({ visibility }) => visibility};
    border: none;
    box-shadow: none;
    text-transform: initial;
    color: ${({ mode, theme }) =>
      mode === 'inverted' ? theme.cssColors.dark080 : theme.cssColors.white};
    display: flex;
    background-color: transparent;
    align-items: center;
    padding: 0 5px;
    margin: 2px;
    justify-content: center;
    font-weight: 500;
    font-size: 1.1em;
    transition: opacity 400ms;
    white-space: nowrap;
    border-radius: 3px;

    &:hover {
      opacity: 0.7;
      box-shadow: none;
      cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
    }
  }
`;

export const DiscreteButton = styled.button`
  && {
    font-family: ${(props) => props.theme.text.font};
    border: none;
    box-shadow: none;
    text-transform: initial;
    color: ${(props) =>
      props.active ? props.theme.cssColors.primary : props.theme.cssColors.dark060};
    display: flex;
    background-color: transparent;
    align-items: center;
    padding: 7px;
    justify-content: center;
    font-weight: 400;
    font-size: 0.9em;
    white-space: nowrap;
    border-radius: 3px;
    max-height: 35px;
    max-width: 310px;
    &:hover {
      background-color: none;
      box-shadow: none;
      cursor: pointer;
      color: ${(props) =>
        props.active ? props.theme.cssColors.secondaryDark : props.theme.cssColors.dark080};
    }
  }
`;

export const LinkLikeButton = styled.button`
  && {
    display: flex;
    padding: ${({ noPadding }) => noPadding && '0'};
    font-family: ${({ theme }) => theme.text.font};
    border: none;
    box-shadow: none;
    text-transform: initial;
    color: ${({ color, theme }) => (color ? theme.cssColors[color] : theme.cssColors.primary)};
    background-color: transparent;
    font-size: ${({ theme }) => theme.textCss.sizes.sm};
    white-space: nowrap;
    opacity: ${({ isDisabled }) => (isDisabled ? 0.4 : 1)};

    &:hover {
      background-color: none;
      box-shadow: none;
      cursor: ${({ cursor, isDisabled }) => cursor || (isDisabled ? 'not-allowed' : 'pointer')};
    }
  }
`;

export const InfoButton = styled(InfoIcon)`
  && {
    cursor: pointer;
    font-size: 0.7em;
    transition:
      opacity 400ms,
      color 400ms;
    color: ${(props) => props.theme.colors.defaultGrey};
    margin: ${({ $isMargin }) => ($isMargin ? '0 5px' : '0')};
  }
  &:hover {
    color: ${(props) => props.theme.colors.darkGreyOpaque};
  }
`;
