import AdobeIcon from '@/assets/images/adobe-icon.png';
import AdobeLogo from '@/assets/images/adobe-logo.png';
import GAIcon from '@/assets/images/ga_icon.png';
import GALogo from '@/assets/images/ga_logo.png';
import MatomoIcon from '@/assets/images/matomo-icon.png';
import MatomoLogo from '@/assets/images/matomo-logo.png';
import ATInternetIcon from '@/assets/images/piano_icon.svg';
import ATInternetLogo from '@/assets/images/piano_logo.svg';
import PiwikProIcon from '@/assets/images/piwik-pro-icon.png';
import PiwikProLogo from '@/assets/images/piwik-pro-logo.png';
import SearchConsoleIcon from '@/assets/images/search_console_icon.png';
import SearchConsoleLogo from '@/assets/images/search_console_logo.png';

export const DISPLAY_LIMIT = 10;
export const DISPLAY_LIMIT_OUTGOINGLINKS = 15;
export const MAX_DISPLAY_LIMIT = 50;
export const ORDER_ASCENDING = 'ORDER_ASCENDING';
export const ORDER_DESCENDING = 'ORDER_DESCENDING';
export const LOADING = 'LOADING';
export const PENDING = 'PENDING';
export const READY = 'READY';
export const DEFAULT_VERSION = {
  id: '55bfe990-00f2-4772-a81a-6057c3e5b7ea',
  type: 'DEFAULT',
};
export const FROM_ORIGIN = {
  DASHBOARD: 'DASHBOARD',
  DEFAULT: 'DASHBOARD',
  ONLINE: 'ONLINE',
  PLANNING: 'PLANNING',
  REPORT: 'REPORT',
};
export const PLANNING_TAB = {
  ALL: 'all',
  FOLDER: 'folder',
  MY_DRAFTS: 'my-drafts',
  OPEN: 'open',
  PUBLISHED: 'published',
};
export const DEFAULT_REPORT_METRIC_KEY = 'publicationsCount';
export const STYLED_CARD_PADDING = 15;
export const REPORT_FILTERS = [
  // Todo: remove updated when the feature is ready
  {
    filter: {
      'exists[lastPublishedAt]': true,
      inStock: false,
      new: false,
    },
    id: 'updated',
    label: 'report:chart.title.updated-content_interval',
  },
  {
    filter: {
      'exists[lastPublishedAt]': true,
      inStock: false,
      new: false,
    },
    id: 'existing',
    label: 'report:chart.title.updated-content_interval',
  },
  {
    filter: {
      'exists[lastPublishedAt]': true,
      inStock: false,
      new: true,
    },
    id: 'new',
    label: 'report:chart.title.new-content_interval',
  },
  {
    filter: {
      'exists[lastPublishedAt]': true,
      inStock: false,
    },
    id: 'all',
    label: 'report:chart.title.all-content_interval',
  },
];

export const CONTENT_STATUS_TO_ADD = {
  color: '#F05065',
  id: 'CONTENT_STATUS_TO_ADD_ID',
  label: '',
  placeholder: 'settings:workspace.content-status-settings.status-create',
};

export const TYPE_ANALYTICS = 'ANALYTICS';
export const TYPE_SEARCH_CONSOLE = 'SEARCH_CONSOLE';

export const SEARCH_CONSOLE_VALUE = 'SEARCH_CONSOLE';
export const GOOGLE_ANALYTICS_VALUE = 'GOOGLE_ANALYTICS';
export const GOOGLE_ANALYTICS_4_VALUE = 'GOOGLE_ANALYTICS_4';
export const AT_INTERNET_VALUE = 'AT_INTERNET';
export const MATOMO_VALUE = 'MATOMO';
export const PIWIK_PRO_VALUE = 'PIWIK_PRO';
export const ADOBE_VALUE = 'ADOBE';

export const SERVICES_LIST = {
  // eslint-disable-next-line sort-keys-fix/sort-keys-fix
  SEARCH_CONSOLE: {
    dataIntercomTarget: 'search-console',
    feature: '',
    featureFlagKey: 'google-search-console',
    icon: SearchConsoleIcon,
    iconAlt: 'SearchConsole Icon',
    iconStyle: { objectFit: 'contain', userSelect: 'none', width: '75%' },
    logo: SearchConsoleLogo,
    path: 'search_console',
    title: 'Google Search Console',
    type: TYPE_SEARCH_CONSOLE,
    values: [SEARCH_CONSOLE_VALUE],
  },
  // eslint-disable-next-line sort-keys-fix/sort-keys-fix
  GOOGLE_ANALYTICS: {
    dataIntercomTarget: 'google-analytics',
    feature: '',
    featureFlagKey: 'google-analytics',
    icon: GAIcon,
    iconAlt: 'GoogleAnalytics Icon',
    iconStyle: { objectFit: 'contain', userSelect: 'none', width: '75%' },
    logo: GALogo,
    logoStyle: { height: '40px', userSelect: 'none', width: '252px' },
    path: 'google_analytics',
    title: 'Google Analytics',
    type: TYPE_ANALYTICS,
    values: [GOOGLE_ANALYTICS_VALUE, GOOGLE_ANALYTICS_4_VALUE],
  },
  // eslint-disable-next-line sort-keys-fix/sort-keys-fix
  AT_INTERNET: {
    dataIntercomTarget: 'at-internet',
    feature: '',
    featureFlagKey: 'at-internet',
    icon: ATInternetIcon,
    iconAlt: 'ATInternet Icon',
    iconStyle: { objectFit: 'contain', userSelect: 'none', width: '75%' },
    logo: ATInternetLogo,
    logoStyle: { height: '32px', userSelect: 'none', width: '201px' },
    path: 'at_internet',
    title: 'Piano Analytics (AT Internet)',
    type: TYPE_ANALYTICS,
    values: [AT_INTERNET_VALUE],
  },
  // eslint-disable-next-line sort-keys-fix/sort-keys-fix
  MATOMO: {
    dataIntercomTarget: 'matomo',
    feature: 'matomo:enabled',
    featureFlagKey: 'matomo',
    icon: MatomoIcon,
    iconAlt: 'Matomo Icon',
    iconStyle: { objectFit: 'contain', userSelect: 'none', width: '75%' },
    logo: MatomoLogo,
    logoStyle: { height: '28px', userSelect: 'none', width: '164px' },
    path: 'matomo',
    title: 'Matomo',
    type: TYPE_ANALYTICS,
    values: [MATOMO_VALUE],
  },
  // eslint-disable-next-line sort-keys-fix/sort-keys-fix
  ADOBE: {
    dataIntercomTarget: 'adobe',
    feature: 'adobe:enabled',
    featureFlagKey: 'adobe',
    icon: AdobeIcon,
    iconAlt: 'Adobe Icon',
    iconStyle: { objectFit: 'contain', userSelect: 'none', width: '75%' },
    logo: AdobeLogo,
    logoStyle: { height: '38px', userSelect: 'none', width: '165px' },
    path: 'adobe',
    title: 'Adobe Analytics',
    type: TYPE_ANALYTICS,
    values: [ADOBE_VALUE],
  },
  // eslint-disable-next-line sort-keys-fix/sort-keys-fix
  PIWIK_PRO: {
    dataIntercomTarget: 'piwikPro',
    feature: 'piwik-pro:enabled',
    featureFlagKey: 'piwik-pro',
    icon: PiwikProIcon,
    iconAlt: 'Piwik Pro Icon',
    iconStyle: { objectFit: 'contain', userSelect: 'none', width: '75%' },
    logo: PiwikProLogo,
    logoStyle: { height: '28px', userSelect: 'none', width: '164px' },
    path: 'piwik-pro',
    title: 'Piwik Pro',
    type: TYPE_ANALYTICS,
    values: [PIWIK_PRO_VALUE],
  },
};
export const DEFAULT_PASSWORD_MIN_STRENGTH = 3;
export const DEFAULT_PASSWORD_THRESHOLD_LENGTH = 8;
export const SUPPORT_ADDRESS_MAIL = 'support@semji.com';
export const HELP_CENTER_URL = 'https://help.semji.com';
export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const SERVICE_INTEGRATION = 'SERVICE_INTEGRATION';
export const ABOUT_WORK_STEP = 'ABOUT_WORK_STEP';
export const CREATE_WORKSPACE_STEP = 'CREATE_WORKSPACE_STEP';
export const ONBOARDING_STEPS = [
  {
    id: CREATE_ACCOUNT,
    isCurrent: false,
    isValidated: false,
    title: 'ACCOUNT',
  },
  {
    id: ABOUT_WORK_STEP,
    isCurrent: false,
    isValidated: false,
    title: 'ORGANIZATION',
  },
  {
    id: CREATE_WORKSPACE_STEP,
    isCurrent: false,
    isValidated: false,
    title: 'WORKSPACE',
  },
  {
    id: SERVICE_INTEGRATION,
    isCurrent: false,
    isValidated: false,
    title: 'SEARCH CONSOLE',
  },
];
export const ONBOARDING_CONTENT_PRODUCTION_INTERVALS_PER_MONTH = [
  'None',
  '1-5',
  '6-10',
  '11-20',
  '20+',
];
export const ONBOARDING_USERS_JOB_TITLES = [
  'Marketing',
  'Digital',
  'SEO',
  'Content',
  'CEO',
  'Other',
];
export const ESTIMATED_AMOUNT_OF_ANALYSIS_UNIT_TO_START_ANALYSIS = 1;

export const ANALYSIS_CREDITS_TYPE = 'ANALYSIS';
export const SEARCH_INTENTS_CREDITS_TYPE = 'SEARCH_INTENT';
export const INCOMING_LINKS_CREDITS_TYPE = 'INCOMING_LINK';
export const CONTENT_IDEAS_SEARCH_CREDITS_TYPE = 'CONTENT_IDEA_SEARCH';
export const AI_WRITING_CREDITS_TYPE = 'AI_WRITING';

export const NEW_DRAFTS_LIMIT_CREATION = 100;

export const URL_LIMIT_CREATION = 100;
export const URL_LIMIT_IMPORT = 100;

export const ACCESS_DENIED_ERROR = 'access_denied';

export const DEFAULT_FAVICON = '/images/favicon/default-favicon.svg';

export const SEMJI_PAGE_BODY_ID = 'semji-page-body';
