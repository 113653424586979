import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import TooltipComponent from '@/components/Tooltip/Tooltip';

const RedirectionDisplayer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: ${({ theme, size }) =>
    size === 'micro' ? theme.text.sizes.micro : theme.text.sizes.small};
  color: ${(props) => props.theme.text.colors.white};
  background-color: ${({ theme }) => theme.cssColors.orange};
  margin-bottom: ${(props) => (props.size === 'micro' ? 0 : '1px')};
  border-radius: 5px;
  padding: 2px 4px;
`;

export function RedirectionBadge({ statusCode = 301, ...props }) {
  const { t } = useTranslation();

  return (
    <TooltipComponent title={t('components:badge.redirection-badge.title', { statusCode })}>
      <RedirectionDisplayer {...props}>
        {t('components:badge.redirection-badge.text', { statusCode })}
      </RedirectionDisplayer>
    </TooltipComponent>
  );
}
