import React from 'react';

import SvgWrapper from './SvgWrapper';

const ScreenIcon = (props) => (
  <SvgWrapper viewBox="0 0 13 13" {...props}>
    <path d="M1.5 8.66681H11.4V2.56683H1.5V8.66681ZM2.30005 3.26685H10.7001V7.86682H2.30005V3.26685ZM11.8 0.966797H1.09998C0.499976 0.966797 0 1.46683 0 2.06683V9.86682C0 10.4668 0.499976 10.9668 1.09998 10.9668H11.8C12.4 10.9668 12.9 10.4668 12.9 9.86682V2.06683C13 1.46683 12.5 0.966797 11.8 0.966797ZM12.2001 9.86682C12.2001 10.0668 12 10.1668 11.8 10.1668H1.09998C0.899976 10.1668 0.700073 10.0668 0.700073 9.86682V2.06683C0.700073 1.86683 0.899976 1.76685 1.09998 1.76685H11.8C12 1.76685 12.2001 1.86683 12.2001 2.06683V9.86682ZM3.80005 11.7668H9.20007V12.5668H3.80005V11.7668Z" />
  </SvgWrapper>
);

export default ScreenIcon;
