import { nanoid } from 'nanoid';
import { useMemo } from 'react';

import SvgWrapper from './SvgWrapper';

function WarningTriangleIcon(props) {
  const maskId = useMemo(() => nanoid(), []);

  return (
    <SvgWrapper height="13" viewBox="0 0 14 13" width="14" {...props}>
      <mask
        height="13"
        id={maskId}
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="14"
        x="0"
        y="0"
      >
        <path
          d="M6.91566 0L0 12.2289H14L6.91566 0ZM6.91566 1.68675L12.4819 11.3855H1.43373L6.91566 1.68675ZM7.42169 7.92771H6.57831V4.63855H7.42169V7.92771ZM7.84337 9.6988C7.84337 10.2048 7.50602 10.5422 7 10.5422C6.49398 10.5422 6.15663 10.2048 6.15663 9.6988C6.15663 9.19277 6.49398 8.85542 7 8.85542C7.50602 8.85542 7.84337 9.27711 7.84337 9.6988Z"
          fill="black"
        />
      </mask>
      <g mask={`url(#${maskId})`}>
        <rect height="16.8675" width="16.8675" x="-1.68677" y="-0.843262" />
      </g>
    </SvgWrapper>
  );
}

export default WarningTriangleIcon;
