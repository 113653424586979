import styled from 'styled-components/macro';

const Rotate = styled.div`
  display: flex;
  align-items: center;
  transition: transform 0.25s linear;
  transform: ${({ degree = '180', hasRotated }) => hasRotated && `rotate(${degree}deg)`};
`;

export default Rotate;
