import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';

import { ERROR_FRONT_TOKEN_NOT_FOUND } from '@/components/GoogleLogin/constants';
import DotsLoaderIcon from '@/components/icons/DotsLoaderIcon';
import FaceRecognitionIcon from '@/components/icons/FaceRecognitionIcon';
import { faceIoLogin } from '@/store/actions/user';
import { pushAuthenticatedUserToGoogleAnalytics } from '@/utils/googleTagManager';

const Text = styled.span`
  flex: 1;
`;

const Spacer = styled.span`
  width: 30px;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;

const Button = styled.button`
  border: none;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  background-color: white;
  box-sizing: border-box;
  width: 100%;
  height: 60px;
  padding: 15px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: ${(props) => props.theme.textCss.sizes.default};
  font-weight: ${(props) => props.theme.textCss.weights.medium};
  font-family: ${(props) => props.theme.textCss.fonts.main};
  color: ${({ theme }) => theme.textCss.colors.default};

  && svg {
    font-size: ${(props) => props.theme.textCss.sizes.xl};
  }

  &:disabled {
    opacity: 0.4;
  }
`;

export default function FaceIoLoginButton({ onError, redirectTo, onClick }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLogging, setIsLogging] = useState(false);

  const onSuccess = async (response = {}) => {
    try {
      setIsLogging(true);
      const { facialId, payload = {} } = response;
      const { email } = payload;

      if (!!facialId && email) {
        const user = await dispatch(faceIoLogin({ facialId, username: email }));
        pushAuthenticatedUserToGoogleAnalytics(user);
        navigate(redirectTo || '/', { replace: true });
      } else {
        throw new Error(ERROR_FRONT_TOKEN_NOT_FOUND);
      }
    } catch (e) {
      onError?.(e);
    } finally {
      setIsLogging(false);
    }
  };

  function handleClick() {
    onClick?.({ onSuccess });
  }

  const isLoaded = true;

  return (
    <>
      <Button disabled={!isLoaded || isLogging} onClick={handleClick}>
        <Flex alignItems="center" height="30px" justifyContent="center" width="30px">
          <FaceRecognitionIcon />
        </Flex>
        <Text>{isLogging ? <DotsLoaderIcon /> : t('login:faceio-login-button')}</Text>
        <Spacer />
      </Button>
    </>
  );
}
