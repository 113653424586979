import { bulkDeleteContent, exportContents, fetchWorkspaceContents } from '@/apis/semji/api';

export default class ContentCollection {
  constructor(workspaceId, ids) {
    this._workspaceId = workspaceId;
    this._ids = ids;
    this._contents = null;
  }

  get contents() {
    return this._fetchContents();
  }

  export(fileType) {
    return exportContents(this._ids, fileType);
  }

  bulkDelete() {
    return bulkDeleteContent(this._workspaceId, this._ids);
  }

  async _fetchContents() {
    if (this._contents === null) {
      try {
        this._contents = await fetchWorkspaceContents({
          filters: {
            inStock: false,
            type: { not_equals: 'ORIGINAL' },
          },
          properties: [
            'id',
            'createdAt',
            'updatedAt',
            'title',
            'type',
            'publishedAt',
            'pageId',
            'assignedToId',
            'dueDate',
            'contentStatusId',
            'contentScore',
            'redirectionUrl',
            'workspaceId',
            'pageFocusTopKeywordId',
            'folderId',
          ],
          workspaceId: this._workspaceId,
        });
      } catch (e) {
        throw e;
      }
    }

    return this._contents['hydra:member'];
  }
}
