import SvgWrapper from './SvgWrapper';

function CheckboxOffIcon(props) {
  return (
    <SvgWrapper viewBox="0 0 24 25" {...props}>
      <path
        clipRule="evenodd"
        d="M5 3.5H19C20.1 3.5 21 4.4 21 5.5V19.5C21 20.6 20.1 21.5 19 21.5H5C3.9 21.5 3 20.6 3 19.5V5.5C3 4.4 3.9 3.5 5 3.5ZM19 19.5V5.5H5V19.5H19Z"
        fill="#A8A9AF"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}

export default CheckboxOffIcon;
