import removeElementAttribute from '@/utils/highlight/helpers/removeElementAttribute';

function removeElementAttributes({
  element,
  keepAttributes = [],
}: {
  element: HTMLElement;
  keepAttributes?: string[];
}): HTMLElement {
  Array.from(element.attributes).forEach((attr) => {
    if (keepAttributes.includes(attr.name)) return;

    removeElementAttribute({ attribute: attr.name, element });
  });

  return element;
}

export default removeElementAttributes;
