import styled from 'styled-components/macro';

import Row from '@/containers/Content/BriefComponents/Table/Rows/Row';

const MultiLineRow = styled(Row)`
  &&& {
    flex-direction: column;
    align-items: stretch;
  }
`;

export default MultiLineRow;
