export const GROUP_BY_TYPE = 'type';
export const GROUP_BY_CATEGORY = 'category';
export const GROUP_BY_FUNNEL = 'funnel';
export const GROUP_BY_CLUSTER = 'cluster';
export const GROUP_BY_GROUP_POSITION = 'group_position';
export const GROUP_BY_PERSONA = 'persona';
export const GROUP_BY_MONTH = 'month';
export const GROUP_BY_MONTHLY_PRODUCTION = 'monthly_production';

export type MetricsGroupByType =
  | typeof GROUP_BY_TYPE
  | typeof GROUP_BY_CATEGORY
  | typeof GROUP_BY_FUNNEL
  | typeof GROUP_BY_CLUSTER
  | typeof GROUP_BY_GROUP_POSITION
  | typeof GROUP_BY_MONTH
  | typeof GROUP_BY_MONTHLY_PRODUCTION
  | typeof GROUP_BY_PERSONA;
