import qs from 'qs';

import { QUERY_PARAM_SORT } from '@/components/AGGrid/hooks/useGridSorts/const';
import { ISort } from '@/components/AGGrid/hooks/useGridSorts/useGridSorts.types';

export class UseGridSortsUtils {
  // Get url params sort.
  //
  // order[property]=operator
  //
  // ==> order: { property: operator }
  static getUnhashedSorts(query: URLSearchParams): ISort | undefined {
    const hashed: string | null = query.get(QUERY_PARAM_SORT);
    if (!hashed) return undefined;
    try {
      const unHashed = qs.parse(decodeURIComponent(hashed)) as unknown as ISort;
      return unHashed ?? undefined;
    } catch (error) {
      return undefined;
    }
  }

  static getStringifiedSort(sort: ISort): string {
    return this.stringifySort(sort);
  }

  // From: { order: { property : operator } }
  //
  // To : order[property]=operator
  private static stringifySort(sort: ISort) {
    return qs.stringify(sort, { indices: false });
  }
}
