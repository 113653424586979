import './WorkspaceItem.scss';

import React from 'react';
import { NavLink } from 'react-router-dom';
import { ENUM_FAVICON_VARIANT, FavIcon } from 'semji-core/components/FavIcon';

import CountryLanguageFlag from '@/components/Flag/CountryLanguage';
import { Workspace } from '@/types/workspace';
import { DEFAULT_FAVICON } from '@/utils/constants';
import { getFaviconFromUrl } from '@/utils/favicon';

interface WorkspaceItemProps extends React.HTMLProps<HTMLDivElement> {
  selected?: boolean;
  workspace: Workspace;
  url: string;
  settingsUrl: string;
}
export default function WorkspaceItem({
  id,
  selected,
  workspace,
  url,
  settingsUrl,
  className,
  ...props
}: WorkspaceItemProps) {
  const apiRootUrl = import.meta.env.VITE_REACT_APP_SEMJI_API_ROOT_URL;

  return (
    <div
      className={`workspace-item ${selected && 'workspace-item--selected'} ${className}`}
      {...props}
    >
      <NavLink className="workspace-item__link" to={url}>
        <CountryLanguageFlag
          countryCode={workspace.countryCode}
          isObvious={workspace.officialLanguages.length === 1 ? true : false}
          languageCode={workspace.languageCode}
        />
        <FavIcon
          className="workspace-item__link__label"
          defaultFavicon={DEFAULT_FAVICON}
          disableClick
          displayLink
          label={workspace.name}
          src={getFaviconFromUrl(workspace.websiteUrl, apiRootUrl)}
          variant={ENUM_FAVICON_VARIANT.Normal}
        />
      </NavLink>
    </div>
  );
}
