import i18next from 'i18next';

import { FilterOption } from '@/components/DynamicFilters';

export const LONG_TAIL_TRAFFIC = 'long-tail-traffic';
export const SHORT_TAIL_TRAFFIC = 'short-tail-traffic';

export const TAIL_TRAFFIC_TYPES = [LONG_TAIL_TRAFFIC, SHORT_TAIL_TRAFFIC];

export const getTailTrafficOptions = (filter?: string[]): FilterOption[] =>
  TAIL_TRAFFIC_TYPES.map((option) => ({
    id: option,
    label: i18next.t(`competitors:reports.labels.${option}`),
    selected: filter?.includes(option),
    value: option,
  }));
