import './LoadingState.scss';

import React from 'react';
import { FloatLoader } from 'semji-core/components/FloatLoader';

import VersionLoader from './VersionLoader';

export default function LoadingState() {
  return (
    <div className="version-history-panel-loading-state">
      <div className="version-history-panel-loading-state__group">
        <div className="version-history-panel-loading-state__group__title">
          <FloatLoader />
        </div>
        {[...Array(2)].map((_, i) => (
          <VersionLoader key={i} />
        ))}
      </div>
      <div className="version-history-panel-loading-state__group">
        <div className="version-history-panel-loading-state__group__title">
          <FloatLoader />
        </div>
        {[...Array(8)].map((_, i) => (
          <VersionLoader key={i} />
        ))}
      </div>
    </div>
  );
}
