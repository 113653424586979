import React from 'react';

import SvgWrapper from './SvgWrapper';

const NotValidatedIcon = (props) => (
  <SvgWrapper height="28" viewBox="0 0 28 28" width="28" {...props}>
    <circle cx="14" cy="14" fill="white" r="14" />
    <path
      d="M14 25C20.0751 25 25 20.0751 25 14C25 7.92487 20.0751 3 14 3C7.92487 3 3 7.92487 3 14C3 20.0751 7.92487 25 14 25Z"
      fill="white"
      stroke="#454754"
      strokeMiterlimit="10"
      strokeOpacity="0.85"
      strokeWidth="0.8"
    />
    <path
      d="M12.8984 18.6749L9.46094 15.6499L9.87342 15.1L12.761 17.5749L18.536 8.7749L19.0859 9.18747"
      fill="#454754"
      fillOpacity="0.85"
    />
  </SvgWrapper>
);

export default NotValidatedIcon;
