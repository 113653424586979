export const DEFAULT_CONTENT_PROPERTIES = [
  'id',
  'assignedTo',
  'assignedToId',
  'dueDate',
  'contentStatus',
  'contentStatusId',
  'version',
  'createdAt',
  'html',
  'text',
  'title',
  'metaDescription',
  'page',
  'pageId',
  'contentScore',
  'wordsCount',
  'publishedAt',
  'type',
  'workspace',
  'workspaceId',
  'pageFocusTopKeyword',
  'pageFocusTopKeywordId',
  'isStarted',
  'folderId',
  'nbUnreadComments',
];
