import React from 'react';
import { SelectDropdownItem, SelectDropdownItemProps } from 'semji-core/components/Select';

import { SelectOptionItemType } from './Select.types';

export default function SelectItem({
  optionValue,
  ...props
}: SelectDropdownItemProps<SelectOptionItemType>) {
  return (
    <SelectDropdownItem {...props}>
      <span>{optionValue?.name ?? optionValue?.label}</span>
    </SelectDropdownItem>
  );
}
