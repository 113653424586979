import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { Cell } from '@/components/FocusKeyword/Cell';
import CursorIcon from '@/components/icons/CursorIcon';
import HashIcon from '@/components/icons/HashIcon';
import SearchIcon from '@/components/icons/SearchIcon';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import Can from '@/hoc/Can';

const Tooltip = styled(TooltipComponent)`
  cursor: help;
`;

export const FocusKeywordOptionsHeader = styled(function ({
  title,
  workspaceCountryName,
  hasSearchConsole,
  ...props
}) {
  const { t } = useTranslation();

  return (
    <div {...props}>
      <Cell maxWidth="190px" minWidth="190px" style={{ fontSize: '12px' }}>
        {title}
      </Cell>
      <Can
        data={{
          hasSearchConsole,
        }}
        perform="editor-keyword-traffic:display"
        yes={() => (
          <Cell alignCenter minWidth="50px">
            <Tooltip
              description={t(
                'components:focus-keyword.focus-keyword-options-header.traffic-description',
                { workspaceCountryName }
              )}
              title={t('components:focus-keyword.focus-keyword-options-header.traffic-title')}
            >
              <CursorIcon />
            </Tooltip>
          </Cell>
        )}
      />
      <Can
        data={{
          hasSearchConsole,
        }}
        perform="editor-keyword-position:display"
        yes={() => (
          <Cell alignCenter minWidth="50px">
            <Tooltip
              description={t(
                'components:focus-keyword.focus-keyword-options-header.position-description',
                { workspaceCountryName }
              )}
              title={t('components:focus-keyword.focus-keyword-options-header.position-title')}
            >
              <HashIcon />
            </Tooltip>
          </Cell>
        )}
      />
      <Cell alignCenter minWidth="50px">
        <Tooltip
          description={t(
            'components:focus-keyword.focus-keyword-options-header.volume-description',
            { workspaceCountryName }
          )}
          title={t('components:focus-keyword.focus-keyword-options-header.volume-title')}
        >
          <SearchIcon />
        </Tooltip>
      </Cell>
    </div>
  );
})`
  && {
    padding: 0.9em;
    font-size: 0.9em;
    display: flex;
    justify-content: space-between;
    border-left: 3px solid;
    border-color: transparent;
    color: ${(props) => props.theme.text.colors.light};
  }
`;
