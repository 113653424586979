import styled from 'styled-components/macro';

import SearchIcon from '@/components/icons/SearchIcon';
import { UnChecked } from '@/components/Optimization/Instruction';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  padding: 0.8em 0.5em;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
  user-select: none;
  filter: blur(3px);
`;

const Text = styled.span`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.3em 0;
  font-weight: 300;
  font-size: ${({ theme }) => theme.textCss.sizes.sm};
  color: ${({ theme }) => theme.text.colors.black};
  flex: 1;
`;

const Infos = styled.div`
  display: flex;
  color: ${({ theme }) => theme.text.colors.default};
  font-size: ${({ theme }) => theme.textCss.sizes.sm};
  font-weight: 300;
`;

const SmallText = styled.span`
  width: 25px;
  padding-left: 0.2em;
  font-size: 1em;
  white-space: nowrap;
`;

const BoldText = styled.strong`
  font-weight: 500;
  color: ${({ theme }) => theme.text.colors.black};
`;

const SENTENCES = [
  <>
    Lorem <BoldText>ipsum</BoldText> dolor sit amet
  </>,
  <>
    Ut velit <BoldText>mauris</BoldText> egestas sed
  </>,
  <>
    Aliquam convallis <BoldText>sollicitudin</BoldText> purus
  </>,
  <>
    <BoldText>Praesent</BoldText> aliquam enim at fermentum mollis
  </>,
  <>
    Aenean ut orci vel <BoldText>massa</BoldText>
  </>,
  <>
    Morbi vel erat non <BoldText>mauris</BoldText> convallis
  </>,
  <>
    <BoldText>Curabitur</BoldText> aliquet pellentesque diam
  </>,
  <>
    Nulla et <BoldText>sapien</BoldText>
  </>,
];

function BlurredSearchIntent() {
  const volume = Math.floor(Math.random() * 1000);
  const sentence = SENTENCES[(Math.random() * SENTENCES.length) | 0];

  return (
    <Wrapper>
      <UnChecked bordered />
      <Text>{sentence}</Text>
      <Infos>
        <SearchIcon opacity={0.5} />
        <SmallText>{volume}</SmallText>
      </Infos>
    </Wrapper>
  );
}

export default BlurredSearchIntent;
