import { useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';

import WorkspaceCreator from '@/containers/CreateWorkspace/WorkspaceCreator';
import Can from '@/hoc/Can';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';

export const CREATE_WORKSPACE_STEPS = {
  dataSources: {
    position: 1,
    step: 'create-workspace:steps.data-sources.step',
    stepKey: 'dataSources',
    subTitle: 'create-workspace:steps.data-sources.subtitle',
    title: 'create-workspace:steps.data-sources.title',
    title_cancelable_step: 'create-workspace:steps.data-sources.title-cancelable-step',
    urlFragment: '#search-console',
  },
  general: {
    position: 0,
    step: 'create-workspace:steps.general.step',
    stepKey: 'general',
    subTitle: 'create-workspace:steps.general.subtitle',
    title: 'create-workspace:steps.general.title',
    title_cancelable_step: 'create-workspace:steps.general.title-cancelable-step',
    urlFragment: '',
  },
  users: {
    position: 2,
    step: 'create-workspace:steps.users.step',
    stepKey: 'users',
    subTitle: 'create-workspace:steps.users.subtitle',
    title: 'create-workspace:steps.users.title',
    title_cancelable_step: 'create-workspace:steps.users.title-cancelable-step',
    urlFragment: '#users',
  },
};

function CreateWorkspace() {
  const { organizationId } = useParams();
  const { isFeatureEnabled: hasUnlimitedWorkspaces } = useOrganizationFeatureSet(
    'organization:workspaces:has-unlimited-amount'
  );
  const maxAllowedWorkspaces = useSelector(
    (state) =>
      state.organizations.byId[organizationId]?.featureSet[
        'organization:workspaces:allowed-amount'
      ] || 0
  );
  const workspacesCount = useSelector(
    (state) => state.organizations.byId[organizationId]?.workspacesCount || 0
  );

  function handleCantCreateWorkspace() {
    return <Navigate to={`/o/${organizationId}/add-workspace-not-authorized`} />;
  }

  function handleCreateWorkspace() {
    return <WorkspaceCreator isFirstWorkspace={workspacesCount === 0} />;
  }

  return (
    <Can
      data={{
        limitReached: !hasUnlimitedWorkspaces && workspacesCount >= maxAllowedWorkspaces,
      }}
      no={handleCantCreateWorkspace}
      perform="create-workspace:exec"
      yes={handleCreateWorkspace}
    />
  );
}

export default CreateWorkspace;
