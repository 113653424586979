import { MutableRefObject, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { DEFAULT_PAGE, QUERY_PARAM_PAGE } from '@/components/AGGrid/hooks/useGridPagination/const';
import { QUERY_PARAM_FILTER } from '@/hooks/useFilters/const';
import {
  AddFilterOptions,
  BuildStringifiedFiltersOptions,
  FindFilterByKeyOptions,
  IFilter,
  IFilterTransformed,
  RemoveFilterByKeyOptions,
  UseFiltersResults,
} from '@/hooks/useFilters/useFilters.types';
import { UseFiltersUtils } from '@/hooks/useFilters/useFilters.utils';
import { ObjectUtils } from '@/utils/object/Object.utils';

// Documentation: https://www.notion.so/semji/Tableau-et-filtrage-5d442697113c4ce194d9aa79e6e8354d
function useFilters<EntityModel>(): UseFiltersResults<EntityModel> {
  const [query, setQuery] = useSearchParams();
  const filtersRef: MutableRefObject<IFilter<EntityModel> | {}> = useRef<IFilter<EntityModel> | {}>(
    UseFiltersUtils.getParsedFilters(query)
  );

  // Mutate filters ref and query params
  function setFilters(payload?: IFilter<EntityModel>, replace: boolean = false) {
    // Do not mutate filters if payload is equal to current filters
    if (ObjectUtils.fastEqual(filtersRef.current, payload ?? {})) return;
    filtersRef.current = payload ?? {};
    if (Object.keys(filtersRef.current).length > 0) {
      query.set(QUERY_PARAM_FILTER, UseFiltersUtils.getEncodedFilters(filtersRef.current));
      // If page query exists and on filter change, set page 1
      if (query.get(QUERY_PARAM_PAGE)) {
        query.set(QUERY_PARAM_PAGE, DEFAULT_PAGE.toString());
      }
      setQuery(query, { replace });
    } else {
      // Remove query param filter if no filters
      query.delete(QUERY_PARAM_FILTER);
      setQuery(query, { replace });
    }
  }

  function addFilters(filters: IFilter<EntityModel>, options?: AddFilterOptions): void {
    const queryFilters: IFilter<any> = UseFiltersUtils.getParsedFilters(query);
    setFilters({ ...queryFilters, ...filters }, options?.replace);
  }

  function removeFilterByKey(key: keyof EntityModel, options?: RemoveFilterByKeyOptions): void {
    const newFilters = JSON.parse(JSON.stringify(filtersRef.current));

    options?.targetOperator
      ? delete newFilters[key as keyof IFilter<EntityModel>][options.targetOperator]
      : delete newFilters[key as keyof IFilter<EntityModel>];

    setFilters(newFilters, options?.replace);
  }

  function removeAllFilters(): void {
    setFilters({});
  }

  function findFilterByKey(
    key: keyof EntityModel,
    options?: FindFilterByKeyOptions
  ): IFilter<EntityModel> | any {
    const currentFilters = UseFiltersUtils.getParsedFilters(query);
    const filter = Object.keys(currentFilters).includes(key as string)
      ? (currentFilters as IFilter<EntityModel>)[key]
      : undefined;
    if (!filter) return undefined;
    return options?.targetOperator ? filter[options.targetOperator] : filter;
  }

  function buildStringifiedFilters<TargetEntityModel>(
    payload: IFilter<TargetEntityModel>,
    options?: BuildStringifiedFiltersOptions
  ): string {
    if (Object.keys(payload).length === 0) return '';
    return options?.encoded
      ? `${QUERY_PARAM_FILTER}=${UseFiltersUtils.getEncodedFilters(payload)}`
      : UseFiltersUtils.getStringifiedFilters(payload);
  }

  function buildTransformedFilters<TargetEntityModel>(
    payload: IFilter<TargetEntityModel>
  ): IFilterTransformed<TargetEntityModel> {
    return UseFiltersUtils.getTransformedFilters(payload);
  }

  return {
    addFilters,
    buildStringifiedFilters,
    buildTransformedFilters,
    filters: UseFiltersUtils.getParsedFilters(query),
    findFilterByKey,
    removeAllFilters,
    removeFilterByKey,
    searchParamsHook: [query, setQuery],
  };
}

export default useFilters;
