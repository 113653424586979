import './InfoMessage.scss';

import React from 'react';

import InfoIcon from '@/components/icons/InfoIcon';

function InfoMessage({ message }: { message: string }): JSX.Element {
  return (
    <div className="info-message-no-recommendations">
      <InfoIcon />
      <span>{message}</span>
    </div>
  );
}

export default InfoMessage;
