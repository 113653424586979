import React from 'react';

import SvgWrapper from './SvgWrapper';

const AngleRight = (props) => (
  <SvgWrapper {...props} viewBox="0 0 17 17">
    <path d="M13.207 8.472l-7.854 7.854-0.707-0.707 7.146-7.146-7.146-7.148 0.707-0.707 7.854 7.854z" />
  </SvgWrapper>
);

export default AngleRight;
