import { mediaQueries } from '@/themes/responsive';

const defaultTheme = {
  boxShadow: {
    modal: '0px 3px 10px 0px #25273633',
  },
  colors: {
    salmon: '#E68174',
    red: '#f76767',
    pink: '#FF4D64',
    primaryPink5: '#FFF4F6',
    lighterPink: '#FFEDEF',
    lightPink: '#fed9db',
    lightPurple: '#c79dc7',
    eggplant: '#CB4897',
    secondaryBlue: '#2758BC',
    yellowGreen: '#a8da82',
    green: '#61CE9E',
    secondaryGreen: '#96D66F',
    cyan: '#00A8B2',
    blue: '#4387F4',
    purple: '#8174E6',
    darkPurple: '#483D99',
    paleOrange: '#F9EDDB',
    yellow: '#ffce5f',
    gold: '#FFA840',
    orange: '#f7934d',
    brown: '#b78b56',
    sand: '#cabba5',
    greyBlue: '#7EA5B9',
    lightlyGreyBlue: 'rgba(137, 213, 218, 0.3)',
    lightGreyBlue: '#EFF3F6',
    lighterGreyBlue: 'rgba(137, 213, 218, 0.15)',
    darkGreyOpaque: '#3F3F3F',
    defaultGrey: 'rgba(0, 0, 0, 0.6)',
    darkGrey: 'rgba(0, 0, 0, 0.5)',
    grey: 'rgba(0, 0, 0, 0.3)',
    greyOpaque: '#777777',
    halfGrey: 'rgba(0, 0, 0, 0.2)',
    quarterGrey: 'rgba(0, 0, 0, 0.1)',
    lightGrey: 'rgba(0, 0, 0, 0.08)',
    lightGreyOpaque: '#EBEBEB',
    lightOrange: '#FFCB80',
    brightGrey: 'rgba(0, 0, 0, 0.04)',
    brightGreyOpaque: '#F7F7F7',
    brightestGrey: 'rgba(0, 0, 0, 0.02)',
    brightestGreyOpaque: '#FAFAFA',
    potentialBackground: '#284a71',
    secondaryScore25Minus: '#FF5451',
    secondaryScore75Plus: '#6FD398',
    /** START REFACTORING CSS VARIABLES */

    // The aim of this block is to refactor
    // css variables and being pixel perfect with what is suggested in Figma
    // this variables and temporary keys will replace the old styles
    // once it's done and all the texts/buttons/links/titles...has been updated
    // the temporary keys will replace the old keys
    // those colors need to be refactored and use less colors for texts

    white: '#FFFFFF',
  },
  icon: {
    colors: {
      default: 'rgba(69, 71, 84, 0.85)',
    },
    sizes: {
      xl: '1.2rem',
    },
  },
  // temporary key
  cssColors: {
    // primary colors
    primary: 'rgba(255, 77, 100, 1)',
    primary080: 'rgba(255, 77, 100, .8)',
    primary060: 'rgba(255, 77, 100, .6)',
    primary040: 'rgba(255, 77, 100, .4)',
    primary020: 'rgba(255, 77, 100, .2)',
    primary010: 'rgba(255, 77, 100, .1)',
    primary005: 'rgba(255, 77, 100, .05)',
    primary002: 'rgba(255, 77, 100, .02)',

    secondary: 'rgba(61, 156, 255, 1)',
    secondary080: 'rgba(61, 156, 255, .8)',
    secondary060: 'rgba(61, 156, 255, .6)',
    secondary040: 'rgba(61, 156, 255, .4)',
    secondary020: 'rgba(61, 156, 255, .2)',
    secondary010: 'rgba(61, 156, 255, .1)',
    secondary005: 'rgba(61, 156, 255, .05)',

    secondaryBlue: 'rgba(39, 88, 188, 1)',
    secondaryBlue080: 'rgba(39, 88, 188, 0.8)',
    secondaryBlue060: 'rgba(39, 88, 188, 0.6)',
    secondaryBlue040: 'rgba(39, 88, 188, 0.4)',
    secondaryDark: 'rgba(202, 59, 78, 1)',

    primaryGradientStart: '#FF4580',
    primaryGradientEnd: '#FF4D64',
    primaryGradient: 'linear-gradient(90deg, #FF4580 0%, #FF4D64 100%)',
    primaryHoverGradientStart: '#FF1F66',
    primaryHoverGradientEnd: '#EA203A',
    primaryHoverGradient: 'linear-gradient(90deg, #E42F52 0%, #FB4058 100%)',

    darkBlueGradient: 'linear-gradient(90deg, #5F66DF 0%, #5F83DF 100%)',
    darkBlueHoverGradient: 'linear-gradient(90deg,#595FD2 0%,#5A7DD6 100%)',

    lightBlueGradient: 'linear-gradient(90deg, #3DC8F4 0%, #3DD3F4 100%)',
    lightBlueHoverGradient: 'linear-gradient(90deg,#38B6dE 0%,#3DCDEC 100%)',

    // secondary colors
    red: 'rgba(255, 84, 81, 1)',
    red020: 'rgba(255, 84, 81, 0.2)',

    salmon: 'rgba(244, 156, 110, 1)',
    salmon020: 'rgba(244, 156, 110, 0.2)',

    salmonOrange: 'rgba(255, 143, 84, 1)',
    salmonOrange020: 'rgba(255, 143, 84, 0.2)',

    green: 'rgba(84, 202, 149, 1)',
    green020: 'rgba(84, 202, 149, 0.2)',

    lightGreen: 'rgba(111, 211, 152, 1)',
    lightGreen020: 'rgba(111, 211, 152, 0.2)',

    yellowGreen: 'rgba(184, 223, 99, 1)',
    yellowGreen020: 'rgba(184, 223, 99, 0.2)',

    yellow: 'rgba(240, 198, 89, 1)',
    yellow020: 'rgba(240, 198, 89, 0.2)',

    // additional colors
    lightPink: 'rgba(255, 166, 177, 1)',
    candyPink: 'rgba(255, 113, 131, 1)',
    salmonPink: 'rgba(250, 112, 99, 1)',
    orange: 'rgba(255, 172, 52, 1)',
    lightBlue: 'rgba(61, 200, 244, 1)',
    purple: 'rgba(82, 87, 216, 1)',
    lightPurple: 'rgba(215, 217, 247, 1)',
    khakiGreen: 'rgba(82, 87, 216, 1)',
    lightBluePurple: 'rgba(95, 102, 223, 1)',
    eggplant: 'rgba(203, 72, 151, 1)',

    // text colors
    dark002: 'rgba(251, 251, 251, 1)',
    dark005: 'rgba(244, 244, 245, 1)',
    dark010: 'rgba(233, 233, 235, 1)',
    dark020: 'rgba(211, 212, 215, 1)',
    dark030: '#BEBFC3',
    dark040: 'rgba(168, 169, 175, 1)',
    dark050: 'rgba(134, 135, 143, 1)',
    dark060: 'rgba(124, 125, 134, 1)',
    dark080: 'rgba(81, 82, 94, 1)',
    dark100: 'rgba(37, 39, 54, 1)',

    white: '#FFFFFF',
  },
  textCss: {
    colors: {
      white: '#FFFFFF',
      default: 'rgba(69, 71, 84, 1)',
      grey: 'rgba(114, 115, 124, 1)',
      lightGrey: 'rgba(69, 71, 84, 0.5)',
      blackGrey: 'rgba(69, 71, 84, 0.85)',
      darkGrey: 'rgba(123, 131, 138, 1)',
      brightGrey: 'rgba(179, 185, 188, 1)',
      shinyGrey: 'rgba(152, 153, 159, 1)',
      anotherGrey: 'rgba(88, 89, 101, 1)',
      anotherShinyGrey: 'rgba(152, 158, 164, 1)',
    },
    // base 16px
    sizes: {
      xxs: '.625rem', // 10px
      xs: '.75rem', // 12px
      xsm: '.813rem', // 13px
      sm: '.875rem', // 14px
      default: '1rem', // 16px
      md: '1.125rem', // 18px
      lg: '1.375rem', // 22px
      xl: '1.625rem', // 26px
      xxl: '2rem', // 32px
      xxxl: '2.5rem', // 40px
      xxxxl: '3.12rem', // 50px
    },
    weights: {
      normal: 300,
      medium: 400,
      strong: 500,
      bold: 700,
    },
    fonts: {
      main: 'Rubik, sans-serif',
      secondary: 'Montserrat, sans-serif',
    },
    lineHeight: '135%',
  },
  /** END REFACTORING */
  text: {
    font: 'Rubik, sans-serif',
    titleFont: 'Montserrat, sans-serif',
    colors: {
      bright: 'rgba(0, 0, 0, 0.3)',
      light: 'rgba(0, 0, 0, 0.5)',
      default: 'rgba(0, 0, 0, 0.6)',
      darker: 'rgba(0, 0, 0, 0.7)',
      black: 'rgba(0, 0, 0, 0.8)',
      white: '#FAFAFA',
      blue: '#4387F4',
      info: '#F05065',
      warning: '#FFA840',
      error: '#f76767',
      green: '#61CE9E',
      brightWhite: 'rgba(255, 255, 255, 0.6)',
    },
    sizes: {
      micro: '0.52em',
      small: '0.8em',
      default: '1em',
      big: '1.1em',
      huge: '1.5em',
    },
  },
  image: {
    sizes: {
      small: '0.8em',
      default: '1em',
      big: '1.5em',
      huge: '2em',
      immense: '2.5em',
      humongous: '3em',
    },
  },
  mediaQueries: {
    phone: '@media (max-width: 700px)',
    tablet: '@media (min-width: 701px) and (max-width: 1200px)',
    desktop: '@media (min-width: 1201px) and (max-width: 1800px)',
    largeDesktop: '@media (min-width: 1801px)',
  },
  responsive: {
    ...mediaQueries,
  },
};

defaultTheme.colors.primary = defaultTheme.colors.blue;
defaultTheme.colors.secondary = defaultTheme.colors.pink;
defaultTheme.colors.primaryGradient = `linear-gradient(
  34deg,
  rgb(0 190 241 / 0.9),
  rgb(20 169 244 / 0.9) 12%,
  rgb(53 135 249 / 0.9) 36%,
  rgb(76 111 252 / 0.9) 59%,
  rgb(90 96 254 / 0.9) 80%,
  rgb(95 91 255 / 0.9) 98%
)`;
defaultTheme.metricsColor = {
  publicationsCount: defaultTheme.colors.pink,
  publicationsCountUpdated: '#dc001c', // darken(0.22, defaultTheme.colors.pink),
  publicationsCountNew: defaultTheme.colors.pink,
  publicationsAverageContentScore: defaultTheme.colors.pink,
  sessions: defaultTheme.colors.blue,
  conversions: defaultTheme.colors.secondaryBlue,
  revenue: defaultTheme.cssColors.orange,
  averagePageLoadTime: defaultTheme.colors.red,
  averageSessionDuration: defaultTheme.colors.eggplant,
  bounceRate: defaultTheme.colors.eggplant,
  clicks: defaultTheme.colors.blue,
  conversionRate: defaultTheme.colors.secondaryBlue,
  impressions: defaultTheme.colors.blue,
  newUsers: defaultTheme.colors.cyan,
  pageViews: defaultTheme.colors.purple,
  pagesPerSession: defaultTheme.colors.eggplant,
  returningUsers: defaultTheme.colors.green,
  sessionsPerUser: defaultTheme.colors.sand,
  users: defaultTheme.colors.orange,
  position: defaultTheme.cssColors.secondaryBlue,
  ctr: defaultTheme.colors.blue,
  transactions: defaultTheme.colors.orange,
  transactionRate: defaultTheme.colors.orange,
  focusTopKeywordPosition: defaultTheme.cssColors.secondaryBlue,
  focuskeywords: defaultTheme.cssColors.secondaryBlue,
  rankingKeywordsMetrics: defaultTheme.cssColors.secondaryBlue,
  rankingKeywordsTop3: defaultTheme.colors.lightOrange,
  rankingKeywordsTop10: defaultTheme.cssColors.secondaryBlue,
  // Unlike what highchart.js doc says, rgba did a weird visual bug while hexa worked
  rankingKeywordsTop100: defaultTheme.cssColors.secondaryBlue040,
  top100: defaultTheme.cssColors.secondaryBlue040,
  focusTopKeywordsPositionTop3: defaultTheme.colors.lightOrange,
  focusTopKeywordsPositionTop10: defaultTheme.cssColors.secondaryBlue,
  focusTopKeywordsPositionTop30: defaultTheme.cssColors.secondaryBlue060,
  focusTopKeywordsPositionTop31Plus: defaultTheme.cssColors.secondaryBlue040,
};

export default defaultTheme;
