import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';

import { LOCATION } from '@/apis/semji/constants';
import useGetCurrentWorkspacePagesWithSpecificProperties from '@/apis/semji/pages/useGetCurrentWorkspacePagesWithSpecificProperties';
import { TableFlexStyledCard } from '@/components/_common';
import EmptyStateTopPagesImport from '@/components/EmptyState/TopPagesImport';
import FilterGroups from '@/components/Filters/FilterGroups';
import BarChartIcon from '@/components/icons/BarChartIcon';
import EyeIcon from '@/components/icons/EyeIcon';
import InfoAltIcon from '@/components/icons/InfoAltIcon';
import LinkIcon from '@/components/icons/LinkIcon';
import SearchIcon from '@/components/icons/SearchIcon';
import SmallCapIcon from '@/components/icons/SmallCapIcon';
import TextIcon from '@/components/icons/TextIcon';
import TopPages from '@/components/Pages/TopPages';
import { Info } from '@/components/Text/Info';
import { SOURCE_TOP_PAGES } from '@/services/Page';
import { showErrorSnackbar } from '@/store/actions/ui';
import { URL_LIMIT_IMPORT } from '@/utils/constants';
import { NUMBER_TYPE_FILTER, STRING_TYPE_FILTER } from '@/utils/filter/constants';
import filterPages from '@/utils/filter/filterPages';

const FILTERS_LIST = [
  {
    attribute: 'title',
    icon: <SmallCapIcon opacity={0.6} />,
    labelKey: 'pages:top-pages.filters.title',
    type: STRING_TYPE_FILTER,
  },
  {
    attribute: 'url',
    icon: <LinkIcon opacity={0.6} />,
    labelKey: 'pages:top-pages.filters.url',
    type: STRING_TYPE_FILTER,
  },
  {
    attribute: 'countryMonthlyClicks',
    icon: <EyeIcon opacity={0.6} />,
    labelKey: 'pages:top-pages.filters.traffic',
    type: NUMBER_TYPE_FILTER,
  },
  {
    attribute: 'keyword',
    icon: <TextIcon opacity={0.6} />,
    labelKey: 'pages:top-pages.filters.keyword',
    type: STRING_TYPE_FILTER,
  },
  {
    attribute: 'lastStatusCode',
    icon: <InfoAltIcon opacity={0.6} />,
    labelKey: 'listing:pages-list.filters-panel.http-code',
    type: NUMBER_TYPE_FILTER,
  },
  {
    attribute: 'position',
    icon: <BarChartIcon opacity={0.6} />,
    labelKey: 'pages:top-pages.filters.position',
    type: NUMBER_TYPE_FILTER,
  },
  {
    attribute: 'searchVolume',
    icon: <SearchIcon opacity={0.6} />,
    labelKey: 'pages:top-pages.filters.search-volume',
    type: NUMBER_TYPE_FILTER,
  },
];

const Wrapper = styled.div`
  flex: 90%;
  width: 70%;
  display: flex;
  flex-direction: column;
`;

const InfoWrapper = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  height: 50px;
  box-sizing: border-box;
`;

const Card = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1;
  flex-direction: column;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.08);
`;

const LOADING_ROWS_PLACEHOLDER = Array(5).fill({
  loading: true,
});

const FilterWrapper = styled.div`
  margin: 10px 0px;
`;

function TopPagesList({
  appliedFilters,
  selectedPages,
  setAppliedFilters,
  setSelectedPages,
  setTotalPagesNumber,
  workspaceCountryCode,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [filteredPages, setFilteredPages] = useState([]);

  const pagesWithSpecificProperties = useGetCurrentWorkspacePagesWithSpecificProperties({
    filters: {
      'exists[url]': true,
      inStock: true,
      'source[equals]': SOURCE_TOP_PAGES,
    },
    location: LOCATION.TOP_PAGES,
    onError: () => dispatch(showErrorSnackbar(t('common:error.default'))),
    onSuccess: (pages) => {
      setFilteredPages(filterPages(pages, appliedFilters));
      setTotalPagesNumber(pages.length);
    },
    properties: [
      'id',
      'url',
      'title',
      'contentScore',
      'countryMonthlyClicks',
      'draft',
      'extra',
      'optimizedImages',
      'imageUrl',
      'source',
      'new',
      'redirectionUrl',
      'focusTopKeyword',
      'titleRetrievedAt',
      'topKeywordsRetrievedAt',
      'lastStatusCode',
    ],
    refetchInterval: 5000,
  });

  function applyFilters(filters) {
    setFilteredPages(filterPages(pagesWithSpecificProperties.data, filters));
    setAppliedFilters(filters);
  }

  function handleSetSelectedPages(pages) {
    setSelectedPages(pages);
  }

  useEffect(() => {
    return () => {
      pagesWithSpecificProperties.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Wrapper>
        <FilterWrapper>
          <FilterGroups
            applyFilters={applyFilters}
            defaultFilters={appliedFilters}
            filtersList={[...FILTERS_LIST]}
          />
        </FilterWrapper>
        <Card>
          {filteredPages.length === 0 &&
          pagesWithSpecificProperties.isFetched &&
          !pagesWithSpecificProperties.isPlaceholderData ? (
            <TableFlexStyledCard>
              <EmptyStateTopPagesImport />
            </TableFlexStyledCard>
          ) : (
            <TopPages
              rows={
                pagesWithSpecificProperties.isFetched &&
                !pagesWithSpecificProperties.isPlaceholderData
                  ? filteredPages
                  : LOADING_ROWS_PLACEHOLDER
              }
              selectedPages={selectedPages}
              setSelectedPages={handleSetSelectedPages}
              workspaceCountryCode={workspaceCountryCode}
            />
          )}
        </Card>
      </Wrapper>
      <InfoWrapper>
        {selectedPages.length > URL_LIMIT_IMPORT && (
          <Info>
            {t('pages:top-pages.import-limitation', {
              count: URL_LIMIT_IMPORT,
            })}
          </Info>
        )}
      </InfoWrapper>
    </>
  );
}

export default TopPagesList;
