import React from 'react';
import { useTranslation } from 'react-i18next';
import { Placements } from 'semji-core/hooks/usePopover';
import styled from 'styled-components/macro';

import { LOCATION } from '@/apis/semji/constants';
import useGetCurrentWorkspaceFocusTopKeywordsWithSpecificProperties from '@/apis/semji/topKeywords/useGetCurrentWorkspaceFocusTopKeywordsWithSpecificProperties';
import { Cell } from '@/components/FocusKeyword/Cell';
import { EDITOR_FK, PAGES_FK } from '@/components/FocusKeyword/constants';
import { StyledLoaderWrapper } from '@/components/FocusKeyword/StyledLoaderWrapper';
import FocusTopKeywordAssociation from '@/components/FocusTopKeyword/FocusTopKeywordAssociation';
import SemjiCreditIcon from '@/components/icons/SemjiCreditIcon';
import FlatLoader from '@/components/Loader/FlatLoader';
import NotAcknowledgedCell from '@/components/Table/Cell/NotAcknowledgedCell';
import NumberCell from '@/components/Table/Cell/NumberCell';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import Can from '@/hoc/Can';
import useApiConfigurations from '@/hooks/useApiConfigurations';
import { LISTING_REFETCH_FREQUENCY_MS } from '@/utils/configurations/constants';

const SemjiIcon = styled(SemjiCreditIcon)`
  margin-left: 5px;
`;

const Tooltip = styled(TooltipComponent)`
  display: flex;
  align-items: center;

  & > ${SemjiIcon} {
    display: none;
  }
`;

const FkCell = styled(Cell)`
  &:hover {
    & ${SemjiIcon} {
      display: block;
    }
  }
`;

export const FocusKeywordOption = styled(function ({
  analyzed,
  keyword,
  trafficValue,
  positionValue,
  searchVolumeValue,
  loadingKeywordVolume,
  loadingSearchConsoleData,
  hasSearchConsole,
  variant,
  ...props
}) {
  const { t } = useTranslation();
  const REFETCH_INTERVAL_IN_MS = parseInt(useApiConfigurations(LISTING_REFETCH_FREQUENCY_MS), 10);
  const { data: focusTopKeywordsWithSpecificProperties } =
    useGetCurrentWorkspaceFocusTopKeywordsWithSpecificProperties({
      filters: { inStock: false },
      location: LOCATION.PLANNING,
      properties: [
        'id',
        'keyword',
        'searchVolume',
        'associatedToPagesCount',
        'associatedToDraftsCount',
      ],
      refetchInterval: REFETCH_INTERVAL_IN_MS,
      refetchOnWindowFocus: 'always',
    });
  const existingFocusKeyword = focusTopKeywordsWithSpecificProperties?.find(
    (focusKeyword) => focusKeyword?.keyword === keyword
  );

  return (
    <div {...props}>
      <FkCell as="div" maxWidth="190px" minWidth="190px">
        {!analyzed && [EDITOR_FK, PAGES_FK].includes(variant) ? (
          <Tooltip
            icon={<SemjiCreditIcon />}
            placement="top"
            title={t('components:focus-keyword.focus-keyword-option.launch-analysis')}
          >
            {keyword}
            <SemjiIcon />
          </Tooltip>
        ) : (
          <> {keyword}</>
        )}
      </FkCell>
      <Cell alignCenter minWidth="0px">
        {existingFocusKeyword && (
          <FocusTopKeywordAssociation
            focusTopKeyword={existingFocusKeyword}
            placement={Placements.Bottom}
          />
        )}
      </Cell>
      <Can
        data={{
          hasSearchConsole,
        }}
        perform="editor-keyword-traffic:display"
        yes={() => (
          <Cell alignCenter minWidth="50px">
            {loadingSearchConsoleData ? (
              <StyledLoaderWrapper>
                <FlatLoader />
              </StyledLoaderWrapper>
            ) : trafficValue ? (
              <NumberCell number={trafficValue} />
            ) : (
              <NotAcknowledgedCell />
            )}
          </Cell>
        )}
      />
      <Can
        data={{
          hasSearchConsole,
        }}
        perform="editor-keyword-position:display"
        yes={() => (
          <Cell alignCenter minWidth="50px">
            {loadingSearchConsoleData ? (
              <StyledLoaderWrapper>
                <FlatLoader />
              </StyledLoaderWrapper>
            ) : positionValue ? (
              positionValue
            ) : (
              <NotAcknowledgedCell />
            )}
          </Cell>
        )}
      />
      <Cell alignCenter minWidth="50px">
        {loadingKeywordVolume ? (
          <StyledLoaderWrapper>
            <FlatLoader />
          </StyledLoaderWrapper>
        ) : searchVolumeValue ? (
          <NumberCell number={searchVolumeValue} />
        ) : (
          <NotAcknowledgedCell />
        )}
      </Cell>
    </div>
  );
})`
  && {
    padding: 0.9em;
    display: flex;
    justify-content: space-between;
    border-left: 3px solid;
    border-left-color: ${(props) =>
      props.selected ? `${props.theme.colors.secondary}` : 'transparent'};
    cursor: pointer;
    font-size: ${(props) => props.theme.text.sizes.small};
    flex: 1;
  }
`;
