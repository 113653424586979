import './Report.scss';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'semji-core/components/Tooltip';
import { InfoIcon } from 'semji-core/icons/InfoIcon';

import { useGetCompetitorWebsiteMetrics } from '@/apis/semji/competitors';
import { GROUP_BY_CLUSTER } from '@/apis/semji/competitors/constants';
import Card from '@/components/Card/Card';
import { NO_COMPETITOR_THEME_DATA } from '@/components/EmptyState/NoContentsVerticalBars';
import Loader from '@/components/Loader/Loader';
import ChartWrapper, {
  CHART_WRAPPER_VARIANT_DEFAULT,
  CHART_WRAPPER_VARIANT_SMALL,
} from '@/containers/Competitors/components/ChartWrapper';
import EmptyStateChart from '@/containers/Competitors/components/EmptyStateChart';
import { HighChartsBar } from '@/containers/Competitors/Duel/Report/Chart/HighChartsBar';
import {
  DEFAULT_CARD_CONTENT_MIN_HEIGHT,
  SMALL_CARD_CONTENT_MIN_HEIGHT,
} from '@/containers/Competitors/Duel/Report/constant';
import { ReportTabs } from '@/containers/Competitors/Duel/Report/ReportTabs';
import {
  CLUSTER_FILTER_QUERY_PARAM,
  COMPETITORS_METRIC_TYPES,
  DEFAULT_COLOR,
} from '@/containers/Competitors/utils/constants';
import { getCompetitorColor, getHostnamefromUrl } from '@/containers/Competitors/utils/utils';
import { IFilterTransformed } from '@/hooks/useFilters/useFilters.types';
import {
  CompetitorCluster,
  CompetitorTopPagesFilters,
  CompetitorWebsite,
  CompetitorWebsiteMetric,
} from '@/types/competitors';
import {
  COMPETITORS_METRIC_TYPE_CONTENT,
  COMPETITORS_METRICS_CONFIG,
} from '@/utils/metrics/constants';

interface ThemeReportProps {
  competitorReportId: string;
  clusters: CompetitorCluster[];
  filters: IFilterTransformed<CompetitorTopPagesFilters>;
  competitor: CompetitorWebsite;
  reference: CompetitorWebsite;
  defaultMetricKey?: COMPETITORS_METRIC_TYPES;
  canToggle?: boolean;
  isLoading?: boolean;
  handleOpenDataExplorer?: (e: any, serieKey: string) => void;
}

export function ThemeReport({
  handleOpenDataExplorer,
  competitorReportId,
  competitor,
  reference,
  filters,
  clusters,
  canToggle,
  defaultMetricKey = COMPETITORS_METRIC_TYPES.traffic,
  isLoading,
}: ThemeReportProps) {
  const { t } = useTranslation();

  const [metricKey, setMetricKey] = useState<COMPETITORS_METRIC_TYPES>(defaultMetricKey);

  const {
    data,
    isLoading: isCompetitorMetricsLoading,
    isPlaceholderData,
  } = useGetCompetitorWebsiteMetrics({
    filters: {
      ...filters,
      groupBy: GROUP_BY_CLUSTER,
    },
    referenceId: reference?.id,
    reportId: competitorReportId,
    staleTime: 0,
    websiteId: competitor.id,
  });

  const referenceMetrics = data?.referenceMetrics ?? [];
  const competitorMetrics = data?.competitorMetrics ?? [];

  function selectMetricClick(metricKey: COMPETITORS_METRIC_TYPES) {
    setMetricKey(metricKey);
  }

  const nameReference = getHostnamefromUrl(reference.url);
  const metrics = COMPETITORS_METRICS_CONFIG[COMPETITORS_METRIC_TYPE_CONTENT];
  const metricsList = Object.values(metrics);
  const series = buildSeries(referenceMetrics, competitorMetrics);
  const categories = buildCategories(clusters);

  function buildCategories(clusters: CompetitorCluster[]) {
    return clusters?.map((cluster) => cluster.name) ?? [];
  }

  function getDataByCluster(data: CompetitorWebsiteMetric[], cluster: string) {
    return (
      data?.find((d) => d.id === cluster) ?? {
        [COMPETITORS_METRIC_TYPES.page]: 0,
        [metricKey]: 0,
        unbrandedTraffic: 0,
      }
    );
  }

  function buildSeries(
    referenceMetrics: CompetitorWebsiteMetric[] = [],
    competitorMetrics: CompetitorWebsiteMetric[] = []
  ) {
    if (competitorMetrics?.length === 0 || referenceMetrics?.length === 0) return [];

    return [
      {
        color: DEFAULT_COLOR,
        data: clusters.map((cluster) => ({
          custom: {
            [COMPETITORS_METRIC_TYPES.page]:
              getDataByCluster(referenceMetrics, cluster.id)?.[COMPETITORS_METRIC_TYPES.page] || 0,
            competitorId: reference.id,
            type: cluster.id,
            value: getDataByCluster(referenceMetrics, cluster.id)?.[metricKey] || 0,
          },
          y: getDataByCluster(referenceMetrics, cluster.id)?.[metricKey] || 0,
        })),
        name: nameReference,
      },
      {
        color: getCompetitorColor(competitor.url)?.color,
        data: clusters.map((cluster) => ({
          custom: {
            [COMPETITORS_METRIC_TYPES.page]:
              getDataByCluster(competitorMetrics, cluster.id)?.[COMPETITORS_METRIC_TYPES.page] || 0,
            competitorId: competitor.id,
            type: cluster.id,
            value: getDataByCluster(competitorMetrics, cluster.id)?.[metricKey] || 0,
          },
          y: getDataByCluster(competitorMetrics, cluster.id)?.[metricKey] || 0,
        })),
        name: competitor ? getHostnamefromUrl(competitor.url) : '',
      },
    ];
  }

  const isNoData = series?.length === 0 || categories?.length === 0;

  return (
    <ChartWrapper
      isLoading={isLoading}
      variant={canToggle ? CHART_WRAPPER_VARIANT_DEFAULT : CHART_WRAPPER_VARIANT_SMALL}
    >
      <Card
        className="competitors-duel-report__card"
        contentMinHeight={
          canToggle ? DEFAULT_CARD_CONTENT_MIN_HEIGHT : SMALL_CARD_CONTENT_MIN_HEIGHT
        }
        title={
          <div className="competitors-duel-report__card__header">
            <div className="competitors-duel-report__card__header__title">
              <span>
                {t(`competitors:duel.report.title-theme.${canToggle ? 'default' : metricKey}`)}
              </span>
              <Tooltip
                description={t('competitors:source-normalized-traffic')}
                isFrame={false}
                title={t(
                  `competitors:duel.report.title-theme-tooltip.${canToggle ? 'default' : metricKey}`
                )}
                tooltipClassName="competitors-duel-report__card__header__title-tooltip competitors-duel-report__card__header__title-tooltip--large"
              >
                <InfoIcon />
              </Tooltip>
            </div>
          </div>
        }
      >
        {canToggle && (
          <ReportTabs
            className="competitors-duel-report__card__tabs"
            currentMetricKey={metricKey}
            metricsList={metricsList}
            selectMetricClick={selectMetricClick}
          />
        )}

        {isCompetitorMetricsLoading || isPlaceholderData ? (
          <Loader children={''} fontSize="50px" />
        ) : isNoData ? (
          <EmptyStateChart version={NO_COMPETITOR_THEME_DATA} />
        ) : (
          <HighChartsBar
            categories={categories}
            metricsSeries={series}
            options={{}}
            selectedMetrics={[...new Set([metricKey, COMPETITORS_METRIC_TYPES.page])]}
            serieKey={CLUSTER_FILTER_QUERY_PARAM}
            sortKey={nameReference}
            onPointClick={handleOpenDataExplorer}
          />
        )}
      </Card>
    </ChartWrapper>
  );
}
