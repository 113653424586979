import OrganizationService from '@/services/Organization';
import { setCurrentOrganizationEditableData, setOrganization } from '@/store/actions/organization';

export const fetchOrganization = (organizationId) => async (dispatch) => {
  if (!organizationId) {
    return Promise.reject('[fetchOrganization]: Missing organizationId argument');
  }

  try {
    const _OrganizationService = new OrganizationService(organizationId);

    const response = await _OrganizationService.organization;

    dispatch(setOrganization(response));
    return Promise.resolve({ ...response });
  } catch (e) {
    return Promise.reject(e);
  }
};

export const fetchCurrentOrganizationEditableData = (organizationId) => async (dispatch) => {
  if (!organizationId) {
    return Promise.reject(
      '[fetchCurrentOrganizationEditableData]: Missing organizationId argument'
    );
  }

  try {
    const _OrganizationService = new OrganizationService(organizationId);
    const response = await _OrganizationService.organization;

    dispatch(
      setCurrentOrganizationEditableData({
        organizationId,
        ...response,
      })
    );
    return Promise.resolve(response);
  } catch (e) {
    return Promise.reject(e);
  }
};
