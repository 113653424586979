import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Zoom from '@material-ui/core/Zoom';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { Box, BoxHeader, Form, Info, Input, Link } from '@/components/Box/Box';
import { ContainedButton } from '@/components/Button/Button';
import WhiteLogo from '@/components/icons/LogoWhite';
import {
  DEFAULT_BACKGROUND,
  LayoutCenteredContent,
  NO_MENU_LAYOUT,
} from '@/components/Layout/Layout';
import { RelativeLoader } from '@/components/Loader/Loader';
import { DefaultLink } from '@/components/Navigation/Link';
import { TitleTwo } from '@/components/Text/Title';
import LayoutWrapper from '@/containers/Layout/Layout';
import UserService from '@/services/User';
import { SECTIONS } from '@/utils/log/constants';
import { Log } from '@/utils/log/Log';

const StyledInfo = styled(Info)`
  && {
    text-align: center;
    line-height: 1.25em;
    font-size: 1em;
    margin-bottom: 1em;
  }
`;

function ForgotPassword() {
  const { t } = useTranslation();
  const [state, setState] = useState({ email: '' });
  const [resetState, setResetState] = useState({
    isOnError: null,
    isPending: false,
    resetDone: false,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setResetState({
      isOnError: null,
      isPending: true,
      resetDone: false,
    });

    try {
      const _userService = new UserService();
      await _userService.resetPasswordForEmail(state.email);
      setResetState({
        ...resetState,
        isPending: false,
        resetDone: true,
      });
    } catch (error) {
      setResetState({
        isOnError: true,
        isPending: false,
        resetDone: false,
      });
      Log.report({
        context: 'handleSubmit',
        error,
        extra: 'Submit forget password',
        section: SECTIONS.login.key,
      });
    }
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  return (
    <LayoutWrapper menu={NO_MENU_LAYOUT} variant={DEFAULT_BACKGROUND}>
      <LayoutCenteredContent>
        <Helmet>
          <title>{t('login:forgot-password.title')}</title>
        </Helmet>
        <WhiteLogo height="75" width="150" />
        <Zoom in transition={600}>
          {resetState.resetDone ? (
            <Box small>
              <BoxHeader>
                <TitleTwo weight="medium">{t('login:forgot-password.instructions-sent')}</TitleTwo>
              </BoxHeader>
              <StyledInfo>
                <Trans
                  components={{ strong: <strong /> }}
                  i18nKey={'login:forgot-password.instructions-sent-to'}
                  values={{ email: state.email }}
                />
              </StyledInfo>
              <StyledInfo>
                {t('login:forgot-password.you-will-receive-email')}
                <br />
                {t('login:forgot-password.check-spam')}
              </StyledInfo>
              <Link to="/login">{t('login:forgot-password.return-to-sign-in')}</Link>
            </Box>
          ) : (
            <Box>
              <BoxHeader>
                <TitleTwo weight="medium">
                  {t('login:forgot-password.forgot-your-password')}
                </TitleTwo>
              </BoxHeader>
              <Form onChange={handleChange} onSubmit={handleSubmit}>
                <FormControl
                  aria-describedby="credentials-error"
                  error={resetState.isOnError}
                  fullWidth
                >
                  <Input
                    autoComplete="username"
                    autoFocus
                    error={resetState.isOnError}
                    fullWidth
                    label={t('login:forgot-password.email')}
                    name="email"
                    required
                    type="email"
                  />
                  {resetState.isOnError && (
                    <FormHelperText>
                      {t('login:forgot-password.given-email-password-invalid')}
                    </FormHelperText>
                  )}
                </FormControl>
                <ContainedButton
                  disabled={resetState.isPending}
                  fullWidth
                  height="big"
                  type="submit"
                >
                  {resetState.isPending ? (
                    <RelativeLoader />
                  ) : (
                    t('login:forgot-password.send-me-instructions')
                  )}
                </ContainedButton>
              </Form>
              <br />
              <DefaultLink color="dark060" decoration to="/login" weight="medium">
                {t('login:forgot-password.return-to-sign-in')}
              </DefaultLink>
            </Box>
          )}
        </Zoom>
      </LayoutCenteredContent>
    </LayoutWrapper>
  );
}

export default ForgotPassword;
