import MuiDialogActions from '@material-ui/core/DialogActions';
import styled from 'styled-components/macro';

const DialogActions = styled(MuiDialogActions)`
  &&& {
    justify-content: space-between;
    padding-right: 2rem;
    padding-left: 2rem;
    padding-bottom: 2rem;
    flex-wrap: wrap;
    gap: 10px;

    & > button {
      margin: 0;
      width: 200px;
    }

    ${({ theme }) => `@media ${theme.responsive.max.xxs}`} {
      flex-direction: column-reverse;

      && > button {
        margin: 0;
        width: 100%;
      }
    }
  }
`;

export default DialogActions;
