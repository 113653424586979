import Highcharts from 'highcharts';
import HighchartsExporting from 'highcharts/modules/exporting';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import HighchartsReact from 'highcharts-react-official';
import { useId, useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getRoundedNumberMetricWithSuffix } from 'semji-core/utils/numbers';

import { AxisChartLabel } from '@/containers/Competitors/Duel/Report/AxisChartLabel/AxisChartLabel';
import { ChartLegend } from '@/containers/Competitors/Duel/Report/ChartLegend';
import { ChartStackedBarTooltip } from '@/containers/Competitors/Duel/Report/ChartStackedBarTooltip';
import { ENUM_SERIES_CURSOR_TYPE } from '@/containers/Competitors/Duel/Report/constant';
import { sortBarChart } from '@/containers/Competitors/utils/utils';
import { selectUserConfiguration } from '@/store/selectors/selectUserConfiguration';
import defaultTheme from '@/themes/defaultTheme';

import ChartUtils from './Chart.utils';
import { DEFAULT_CHART_OPTIONS, DEFAULT_SERIE_STACKED_OPTIONS, getContainerId } from './constants';

NoDataToDisplay(Highcharts);
HighchartsExporting(Highcharts);

export function HighChartsGroupedBar({
  metricsSeries,
  selectedMetrics,
  categories,
  options = {},
  showLegendWinner = true,
  sortKeyNames,
  chartRef,
  exportImage,
  title,
  reference,
  serieKey,
  onPointClick = () => null,
}) {
  const id = useId();
  const chartContainerId = getContainerId(id);
  const { t } = useTranslation();
  const apiRootUrl = import.meta.env.VITE_REACT_APP_SEMJI_API_ROOT_URL;

  const { showCompetitorDataLabels } = useSelector(selectUserConfiguration);

  // Handle the visible series
  const visibleSeries = useRef(sortKeyNames);
  const [sortkey, setSortkey] = useState(visibleSeries.current[0]);
  const categoriesNames = categories.map((category) => category.name);

  const series = formatSeries(metricsSeries);
  const { seriesData, categoriesData } = sortBarChart(series, categoriesNames, sortkey);
  const OPTIONS = {
    ...DEFAULT_CHART_OPTIONS,
    chart: {
      ...DEFAULT_CHART_OPTIONS.chart,
      events: {
        render: function () {
          const series = this.series;
          series.forEach((serie) => {
            serie.data.forEach((point) => {
              !showCompetitorDataLabels && point.dataLabel.hide();
            });
          });
        },
      },
      type: 'column',
    },
    exporting: ChartUtils.getExportingOptions({
      overrideOptions: {
        title: {
          align: 'left',
          text: title,
        },
      },
    }),

    lang: {
      noData: t('competitors:duel.report.no-data'),
    },
    legend: ChartUtils.getLegendOptions({
      overrideOptions: {
        labelFormatter: function () {
          return ReactDOMServer.renderToStaticMarkup(
            getChartLegend(this.name, this.color, this.visible)
          );
        },
      },
    }),
    plotOptions: {
      series: {
        cursor: onPointClick ? ENUM_SERIES_CURSOR_TYPE.POINTER : ENUM_SERIES_CURSOR_TYPE.UNDEFINED,
        dataLabels: {
          color: defaultTheme.cssColors.dark100,
          enabled: true,
          formatter: function () {
            //format the number with suffix like 'K' or 'M'
            return getRoundedNumberMetricWithSuffix({ number: this.y });
          },
          shadow: false,
          style: {
            fontSize: defaultTheme.textCss.sizes.xs,
            fontWeight: defaultTheme.textCss.weights.bold,
            textOutline: false,
          },
        },
        events: {
          click: (e) => onPointClick?.(e, serieKey),
          legendItemClick: function () {
            const { name, visible } = this;
            handleLegendItemClick({ name, visible: !visible });
          },
          mouseOut: function () {
            this.data.forEach((point) => {
              !showCompetitorDataLabels && point.dataLabel.hide();
            });
          },
          mouseOver: function () {
            this.data.forEach((point) => {
              point.dataLabel.show();
            });
          },
        },
        maxPointWidth: 50,
      },
    },
    tooltip: {
      backgroundColor: 'transparent',
      borderWidth: '0px',
      className: 'competitors-duel-report__chart__tooltip',
      distance: 20,
      enabled: true,
      formatter: function () {
        return ReactDOMServer.renderToStaticMarkup(getChartTooltip(this));
      },
      hideDelay: 0,
      outside: true,
      shadow: false,
      style: {
        color: defaultTheme.cssColors.dark080,
      },
      useHTML: true,
    },
    xAxis: ChartUtils.getXAxisOptions({
      overrideLabelOptions: {
        autoRotation: [0], // Waiting for the design to be validated
        formatter: function () {
          const imgId = `chart-${id}-xaxis-${this.value}`;
          ChartUtils.addChartFaviconEventListeners(imgId);
          const retrievedValues = categories?.find((category) => category.name === this.value);

          return ReactDOMServer.renderToStaticMarkup(
            <AxisChartLabel
              apiRootUrl={apiRootUrl}
              domain={this.value}
              exportImage={exportImage}
              id={imgId}
              isReference={this.value === reference}
              url={retrievedValues?.url}
              urlType={retrievedValues?.urlType}
            />
          );
        },
      },
      overrideOptions: {
        categories: categoriesData,

        ...options.xAxis,
      },
    }),
    yAxis: ChartUtils.getYAxisOptions(),
  };

  // Used with ReactDOMServer to custom label
  function getChartLegend(name, color, isVisible) {
    const winner = showLegendWinner ? ChartUtils.computeWinner(series).name : null;

    return (
      <ChartLegend color={color} isVisible={isVisible} isWinner={winner === name} name={name} />
    );
  }

  // Used with ReactDOMServer to custom chart tooltip
  function getChartTooltip({ series, color, x, y, point }) {
    const imgId = `chart-${id}-tooltip-${series.name}`;
    ChartUtils.addChartFaviconEventListeners(imgId);
    return (
      <ChartStackedBarTooltip
        color={color}
        id={imgId}
        metrics={selectedMetrics}
        name={series.name}
        point={point}
        title={x}
      />
    );
  }

  function formatSeries(series) {
    return series?.map((serie) => {
      return {
        ...DEFAULT_SERIE_STACKED_OPTIONS,
        ...serie,
      };
    });
  }

  function onClick(e) {
    e.stopPropagation();
  }

  function handleLegendItemClick({ name, visible }) {
    visibleSeries.current = !visible
      ? visibleSeries.current.filter((year) => year !== name)
      : [...visibleSeries.current, name];

    setSortkey(visibleSeries.current.sort((a, b) => b - a)[0]);
  }

  return (
    <div className="competitors-duel-report__chart">
      <div aria-hidden="true" id={chartContainerId} onClick={onClick}>
        <HighchartsReact
          ref={chartRef}
          highcharts={Highcharts}
          options={{
            ...OPTIONS,
            series: seriesData,
          }}
        />
      </div>
    </div>
  );
}
