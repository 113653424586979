import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import TagIcon from '@/components/icons/TagIcon';
import { getDateRange } from '@/utils/date';
import {
  METRICS_CONFIG_PERIODICITY_MONTHLY,
  METRICS_CONFIG_PERIODICITY_QUARTERLY,
  METRICS_CONFIG_PERIODICITY_WEEKLY,
  METRICS_CONFIG_QUARTER,
  METRICS_CONFIG_WEEK,
  TOOLTIP_METRIC_POSITION_LEFT,
  TOOLTIP_METRIC_POSITION_RIGHT,
} from '@/utils/metrics/constants';

const PlotLineLabel = styled.div`
  position: absolute;
  right: ${({ position }) => position === TOOLTIP_METRIC_POSITION_RIGHT && '-6px'};
  left: ${({ position }) => position === TOOLTIP_METRIC_POSITION_LEFT && '-30px'};
  top: -3em;
  background: white;
  font-size: 0.8em;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

function PlotLineTag({ marker, theme, ...props }) {
  const { t } = useTranslation();

  function getDate(x, periodicity) {
    switch (periodicity) {
      case METRICS_CONFIG_PERIODICITY_WEEKLY:
        return getDateRange(DateTime.fromMillis(x), METRICS_CONFIG_WEEK, 'MMM d', 'd');
      case METRICS_CONFIG_PERIODICITY_MONTHLY:
        return DateTime.fromMillis(x).toFormat('yyyy MMM');
      case METRICS_CONFIG_PERIODICITY_QUARTERLY:
        return getDateRange(DateTime.fromMillis(x), METRICS_CONFIG_QUARTER, 'yyyy MMM', 'MMM');
      default:
        return DateTime.fromMillis(x).toFormat('MMM d');
    }
  }

  return (
    <div {...props}>
      <PlotLineLabel className="label" position={marker.tooltipPosition}>
        <span>{t(marker.label, { count: marker.count })}</span>
        <span>{getDate(marker.x, marker.groupedBy)}</span>
      </PlotLineLabel>
      <TagIcon className="icon" />
    </div>
  );
}

export default styled(PlotLineTag)`
  display: inline-flex;
  justify-content: center;
  right: 4px;
  top: 0;
  position: relative;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 1.2rem;

  .label {
    display: none;
    font-size: 0.8rem;
  }

  &:hover {
    .label {
      display: flex;
    }
  }
`;
