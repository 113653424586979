import { FilterRadioRangeOption } from '@/components/DynamicFilters';
import { ArrayUtils } from '@/utils/array/Array.utils';

export const POSITION_TOP_1_3 = 'Top 1-3';
export const POSITION_TOP_4_10 = 'Top 4-10';
export const POSITION_TOP_11_100 = 'Top 11-100';

export const POSITION_OPTIONS = [POSITION_TOP_1_3, POSITION_TOP_4_10, POSITION_TOP_11_100];

export const getPositionOptions = (filter?: number[]): FilterRadioRangeOption[] => {
  return POSITION_OPTIONS.map((option) => {
    const value = option.replace('Top ', '').split('-').map(Number);
    return {
      id: option,
      label: option,
      selected: !!(filter && ArrayUtils.fastEqual(filter, value)),
      value,
    };
  });
};
