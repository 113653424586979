import './FactCheckLoader.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import SpinnerIconTwo from '@/components/icons/SpinnerIconTwo';

function FactCheckLoader(): React.JSX.Element {
  const { t } = useTranslation();
  return (
    <div className="fact-check-loader">
      <div className="fact-check-loader__icon">
        <SpinnerIconTwo />
      </div>
      <span className="fact-check-loader__text">{t('content:ai-writing:fact-check-loading')}</span>
    </div>
  );
}

export default FactCheckLoader;
