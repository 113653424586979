import styled from 'styled-components/macro';

const ClusterBadge = styled.span`
  font-weight: ${(props) => props.theme.textCss.weights.medium};
  font-size: ${(props) => props.theme.textCss.sizes.xs};
  line-height: ${({ theme }) => theme.textCss.sizes.default};
  border-radius: 25px;
  padding: 2px 6px;
  text-overflow: ellipsis;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  min-height: 16px;

  margin: ${(props) => props.margin};
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};
`;

export default ClusterBadge;
