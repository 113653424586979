import './WorkspaceDomain.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { FloatLoader } from 'semji-core/components/FloatLoader';
import { Tooltip } from 'semji-core/components/Tooltip';
import { InfoIcon } from 'semji-core/icons/InfoIcon';

import CustomInput from '@/containers/Competitors/Configuration/Listing/CustomInput';
import { getCompetitorColor } from '@/containers/Competitors/utils/utils';
import useCan from '@/hooks/useCan';

export default function WorkspaceDomain({
  currentNbPages,
  previousNbPages,
  value,
  onChange,
  prefixUrl,
  isCalculatingPagesNumber,
}: {
  value: string;
  onChange: (value: string) => void;
  prefixUrl: string;
  currentNbPages?: number | null;
  previousNbPages?: number | null;
  isCalculatingPagesNumber: boolean;
}) {
  const { t } = useTranslation();
  const canDisplayCompetitorWebsiteNumberOfPages = useCan({
    perform: 'can-display-competitor-website-number-of-pages',
  });

  return (
    <div className="competitors-configuration__workspace-domain">
      <div className="competitors-configuration__workspace-domain__header">
        <span className="competitors-configuration__workspace-domain-title">
          {t('competitors:configuration.your-domain.title')}
        </span>
        <Tooltip
          title={t('competitors:configuration.your-domain.tooltip')}
          tooltipClassName="competitors-configuration-screen__tooltip"
        >
          <InfoIcon />
        </Tooltip>
      </div>
      <div className="competitors-configuration__workspace-domain__content">
        <CustomInput
          color={getCompetitorColor(prefixUrl)?.color}
          placeholder={t('competitors:configuration.your-domain.placeholder')}
          startAdornment={
            <span className="competitors-configuration__workspace-domain__content__input-start">
              {prefixUrl}/
            </span>
          }
          value={value}
          withoutFavIcon
          onChange={(e) => onChange(e.target.value)}
        />
        {canDisplayCompetitorWebsiteNumberOfPages && (
          <div className="competitors-configuration__workspace-domain__content__pages">
            {isCalculatingPagesNumber ? (
              <FloatLoader />
            ) : (
              <span>
                {`${currentNbPages ?? '-'} ${t('competitors:configuration.pages')} / ${previousNbPages ?? '-'} ${t('competitors:configuration.pages')}`}
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
