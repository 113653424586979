import './Radio.scss';

import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ENUM_BUTTON_VARIANTS } from 'semji-core/components/Button';
import { Dropdown } from 'semji-core/components/Dropdown';

import { ENUM_FILTER_TYPE, FilterAnchor } from '@/components/DynamicFilters';
import RadioItem from '@/components/DynamicFilters/Radio/RadioItem';
import ScrollBar from '@/components/Scrollbar';

import { RadioProps } from './Radio.types';

function Radio({ icon, options, onValidate, name, id, disabled = false }: RadioProps) {
  const { t } = useTranslation();
  const selectedValue = options?.find((option) => option.selected)?.value;

  const [selected, setSelected] = useState<string | undefined>(selectedValue || undefined);

  const dropDrownActionsRef = useRef({
    handleClose: () => null,
    handleOpen: () => null,
  });

  function handleItemChange(value: string): void {
    if (disabled) return;

    setSelected(value);
  }

  function handleValidate(): void {
    if (disabled) return;

    onValidate(selected);
    dropDrownActionsRef.current.handleClose();
  }

  return (
    <Dropdown
      actionsRef={dropDrownActionsRef}
      anchorElement={
        <FilterAnchor
          disabled={disabled}
          icon={icon}
          id={id}
          name={name}
          options={options}
          type={ENUM_FILTER_TYPE.RADIO}
          onValidate={onValidate}
        />
      }
      containerClassName="competitors-radio"
      disabled={disabled}
      isFrame={false}
      offsetValue={10}
      placement={'bottom-start'}
      querySelectorListener="#root"
      width="240px"
    >
      <div className="competitors-radio__content">
        <div
          className="competitors-radio__content__list"
          style={{ height: Math.min(options.length * 40, 280) }}
        >
          <ScrollBar autoHide={false} weight="4px">
            {options.map((option, index) => (
              <RadioItem
                key={`radio-${option.value}-options-${index}`}
                checked={!!selected && selected === option.value}
                label={option.label}
                onClick={() => handleItemChange(option.value)}
              />
            ))}
          </ScrollBar>
        </div>
        <div className="competitors-radio__content__footer">
          <Button
            className="competitors-radio__content__footer__validate"
            variant={ENUM_BUTTON_VARIANTS.Text}
            onClick={handleValidate}
          >
            {t('competitors:filters.validate')}
          </Button>
        </div>
      </div>
    </Dropdown>
  );
}

export default Radio;
