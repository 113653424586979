import apiClient from '@/apis/semji/apiClient';

const { get } = apiClient;

export class NetworkUtils {
  // When no network is available retry the function every 5 seconds
  static executeWhenOnline(fn: () => void): void {
    if (!navigator.onLine) return;
    get('/info.json')
      .then(() => {
        fn();
        return;
      })
      .catch(() => {
        setTimeout(() => {
          NetworkUtils.executeWhenOnline(fn);
        }, 5000);
      });
  }
}
