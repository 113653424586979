import React from 'react';

import FlashMessage from '@/components/FlashMessage/FlashMessage';
import useDebug from '@/hooks/useDebug';
import { PROXY_HOSTS } from '@/utils/log/constants';

export default function MockProxyMessage() {
  const mockProxyEnv = useDebug('mockProxyEnv');

  return (
    <>
      {Boolean(mockProxyEnv) && (
        <FlashMessage bgColor="primary" color="dark100">
          <span>
            Your are using{' '}
            <strong>
              {Object.entries(PROXY_HOSTS).find(([label, host]) => host === mockProxyEnv)?.[0]} API
            </strong>
            . Please, be very careful! 🙏
          </span>
        </FlashMessage>
      )}
    </>
  );
}
