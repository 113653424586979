import { nanoid } from 'nanoid';
import React, { useMemo } from 'react';

import SvgWrapper from './SvgWrapper';

function MessageIcon({ ...props }) {
  const maskId = useMemo(() => nanoid(), []);

  return (
    <SvgWrapper {...props} fill="none" height="17" viewBox="0 0 17 17" width="17">
      <g clipPath={`url(#clip${maskId})`}>
        <mask
          height="18"
          id={`mask${maskId}`}
          maskUnits="userSpaceOnUse"
          style={{ maskType: 'alpha' }}
          width="17"
          x="0"
          y="0"
        >
          <path
            d="M15.5 0H1.5C0.673 0 0 0.673 0 1.5V11.5C0 12.327 0.673 13 1.5 13H2V17.102L6.688 13H15.5C16.327 13 17 12.327 17 11.5V1.5C17 0.673 16.327 0 15.5 0ZM16 11.5C16 11.775 15.776 12 15.5 12H6.312L3 14.898V12H1.5C1.224 12 1 11.775 1 11.5V1.5C1 1.225 1.224 1 1.5 1H15.5C15.776 1 16 1.225 16 1.5V11.5ZM3 3H14V4H3V3ZM3 5H14V6H3V5ZM3 7H9V8H3V7Z"
            fill="black"
          />
        </mask>
        <g mask={`url(#mask${maskId})`}>
          <rect fill="currentColor" height="20" width="20" x="-2" y="-1" />
        </g>
      </g>
      <defs>
        <clipPath id={`clip${maskId}`}>
          <rect fill="white" height="17" width="17" />
        </clipPath>
      </defs>
    </SvgWrapper>
  );
}

export default MessageIcon;
