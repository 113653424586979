import { useTranslation } from 'react-i18next';

export const NULL_USER = {
  email: '',
  firstName: '',
  id: 'e666e204-e661-4dd1-a7a1-f6a791ee0bcf',
  lastName: '',
  profileImageHash: null,
};

function useNullUser() {
  const { t } = useTranslation();

  NULL_USER.lastName = t('common:user-picker.unassigned');

  return NULL_USER;
}

export default useNullUser;
