import React from 'react';

import ImpersonateFlashMessage from '@/containers/Layout/FlashMessages/ImpersonateFlashMessage';
import MissingServiceIntegrationFlashMessage from '@/containers/Layout/FlashMessages/MissingServiceIntegrationFlashMessage';
import MockProxyMessage from '@/containers/Layout/FlashMessages/MockProxyMessage';
import SubscriptionStatusFlashMessage from '@/containers/Layout/FlashMessages/SubscriptionStatusFlashMessage';

function LayoutFlashMessages({ organizationId, workspaceId }) {
  return (
    <>
      <MockProxyMessage />
      <ImpersonateFlashMessage />
      {Boolean(workspaceId) && Boolean(organizationId) && (
        <MissingServiceIntegrationFlashMessage
          organizationId={organizationId}
          workspaceId={workspaceId}
        />
      )}
      {Boolean(organizationId) && (
        <SubscriptionStatusFlashMessage organizationId={organizationId} />
      )}
    </>
  );
}

export default LayoutFlashMessages;
