import React from 'react';

import SvgWrapper from './SvgWrapper';

const PlainBulletListIcon = (props) => (
  <SvgWrapper {...props} height="33" viewBox="0 0 33 33" width="33">
    <mask
      height="23"
      id="mask0"
      maskUnits="userSpaceOnUse"
      style={{ maskType: 'alpha' }}
      width="33"
      x="0"
      y="5"
    >
      <path
        d="M33 7.76466V9.70584H9.70588V7.76466H33ZM5.82353 8.73525C5.82353 10.3406 4.51712 11.647 2.91176 11.647C1.30641 11.647 0 10.3406 0 8.73525C0 7.1299 1.30641 5.82349 2.91176 5.82349C4.51712 5.82349 5.82353 7.1299 5.82353 8.73525ZM3.88235 8.73525C3.88235 8.20143 3.44753 7.76466 2.91176 7.76466C2.376 7.76466 1.94118 8.20143 1.94118 8.73525C1.94118 9.26907 2.376 9.70584 2.91176 9.70584C3.44753 9.70584 3.88235 9.26907 3.88235 8.73525ZM9.70588 17.4705H33V15.5294H9.70588V17.4705ZM5.82353 16.5C5.82353 18.1053 4.51712 19.4117 2.91176 19.4117C1.30641 19.4117 0 18.1053 0 16.5C0 14.8946 1.30641 13.5882 2.91176 13.5882C4.51712 13.5882 5.82353 14.8946 5.82353 16.5ZM3.88235 16.5C3.88235 15.9661 3.44753 15.5294 2.91176 15.5294C2.376 15.5294 1.94118 15.9661 1.94118 16.5C1.94118 17.0338 2.376 17.4705 2.91176 17.4705C3.44753 17.4705 3.88235 17.0338 3.88235 16.5ZM9.70588 25.2353H33V23.2941H9.70588V25.2353ZM5.82353 24.2647C5.82353 25.87 4.51712 27.1764 2.91176 27.1764C1.30641 27.1764 0 25.87 0 24.2647C0 22.6593 1.30641 21.3529 2.91176 21.3529C4.51712 21.3529 5.82353 22.6593 5.82353 24.2647ZM3.88235 24.2647C3.88235 23.7308 3.44753 23.2941 2.91176 23.2941C2.376 23.2941 1.94118 23.7308 1.94118 24.2647C1.94118 24.7985 2.376 25.2353 2.91176 25.2353C3.44753 25.2353 3.88235 24.7985 3.88235 24.2647Z"
        fill="black"
      />
    </mask>
    <g mask="url(#mask0)">
      <rect fill="#A8A9AF" height="38.8235" width="38.8235" x="-3.88281" y="-1.94116" />
    </g>
  </SvgWrapper>
);

export default PlainBulletListIcon;
