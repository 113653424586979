import { SetURLSearchParams } from 'react-router-dom';

export interface UseFiltersResults<EntityModel> {
  // Add filters and update query params
  addFilters: TypeAddFilters<EntityModel>;
  // Current filters, sync with query params
  filters: IFilter<EntityModel>;
  // Will find a filter with his key and return value after operator
  findFilterByKey: TypeFindFilterByKey<EntityModel>;
  // Remove filters by keys[] and update query params
  removeFilterByKey: TypeRemoveFiltersByKeys<EntityModel>;
  // Remove all filters and update query params
  removeAllFilters: () => void;
  // Will stringify filters passed as arguments
  buildStringifiedFilters: <TargetEntityModel>(
    payload: IFilter<TargetEntityModel>,
    options?: BuildStringifiedFiltersOptions
  ) => string;
  // Will transform filters passed as arguments
  buildTransformedFilters: <TargetEntityModel>(
    payload: IFilter<TargetEntityModel>
  ) => IFilterTransformed<TargetEntityModel>;
  searchParamsHook: [URLSearchParams, SetURLSearchParams];
}

export type IFilter<EntityModel> = {
  [key in keyof Partial<EntityModel>]?: IFilterOperators;
};

export type IFilterOperators = {
  [operator in FILTER_OPERATOR]?: any;
};

export type IFilterBoolean<EntityModel> = {
  [key in keyof Partial<EntityModel>]: boolean;
};

export type IFilterClassic<EntityModel> = {
  [key in keyof Partial<EntityModel>]: {
    [operator in FILTER_OPERATOR]: any;
  };
};

export type IFilterExists<EntityModel> = {
  [key in keyof Partial<EntityModel>]: {
    [operator in EXISTS_OPERATOR]: any;
  };
};

export type IFilterMulti<EntityModel> = {
  [key in keyof Partial<EntityModel>]: any[];
};

export type TypeAddFilters<EntityModel> = (
  filters: IFilter<EntityModel>,
  options?: AddFilterOptions
) => void;

export type TypeRemoveFiltersByKeys<EntityModel> = (
  key: keyof EntityModel,
  options?: RemoveFilterByKeyOptions
) => void;

export type TypeFindFilterByKey<EntityModel> = (
  key: keyof EntityModel,
  options?: FindFilterByKeyOptions
) => IFilter<EntityModel> | any;

export interface AddFilterOptions {
  // If true, will not create a new navigation.
  // Default: false
  replace?: boolean;
}

export interface RemoveFilterByKeyOptions extends FindFilterByKeyOptions {
  // If true, will not create a new navigation.
  // Default: false
  replace?: boolean;
}

export interface FindFilterByKeyOptions {
  // Will return the value of the targeted operator
  targetOperator?: FILTER_OPERATOR;
}

export interface BuildStringifiedFiltersOptions {
  // Will return the stringified params with the `filters=` suffix and will be encoded for params injection
  encoded?: boolean;
}

export type FILTER_OPERATOR =
  | SEARCH_OPERATOR
  | MULTI_SEARCH_OPERATOR
  | DATE_OPERATOR
  | NUMERIC_OPERATOR
  | BOOLEAN_OPERATOR
  | STRING_OPERATOR
  | EXISTS_OPERATOR;

export enum MULTI_SEARCH_OPERATOR {
  multi_search = 'multi_search',
}

export enum BOOLEAN_OPERATOR {
  boolean = 'boolean',
}

export enum STRING_OPERATOR {
  equals = 'equals',
  not_equals = 'not_equals',
  contains = 'contains',
  not_contains = 'not_contains',
}

export enum NUMERIC_OPERATOR {
  eq = 'eq',
  neq = 'neq',
  gte = 'gte',
  gt = 'gt',
  lte = 'lte',
  lt = 'lt',
}

export enum DATE_OPERATOR {
  equals = 'equals',
  not_equals = 'not_equals',
  after = 'after',
  strictly_after = 'strictly_after',
  before = 'before',
  strictly_before = 'strictly_before',
}

export enum SEARCH_OPERATOR {
  exact = 'exact',
  partial = 'partial',
  start = 'start',
  end = 'end',
  word_start = 'word_start',
}

export enum EXISTS_OPERATOR {
  exists = 'exists',
}

export interface IFilterDispatched<EntityModel> {
  booleanFilters: IFilterBoolean<EntityModel>;
  classicFilters: IFilterClassic<EntityModel>;
  multiFilters: IFilterMulti<EntityModel>;
  existsFilters: IFilterExists<EntityModel>;
}

export type IFilterTransformed<EntityModel> =
  | Partial<IFilterBoolean<EntityModel>>
  | Partial<IFilterMulti<EntityModel>>
  | Partial<IFilterClassic<EntityModel>>
  | Partial<IFilterExists<EntityModel>>;
