import styled from 'styled-components/macro';

const Img = styled.img`
  max-width: 250px;
  width: 100%;
  object-fit: contain;
  margin-bottom: 2rem;
`;

export default Img;
