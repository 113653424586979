export function removeScript(node, elementId) {
  const element = node.getElementById(elementId);
  if (element) {
    element.parentNode.removeChild(element);
  }
}

export function loadScript(src, id) {
  return new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) return resolve();
    const script = document.createElement('script');
    script.src = src;
    script.setAttribute('id', id);
    script.onload = () => resolve();
    script.onerror = (err) => reject(err);
    document.body.appendChild(script);
  });
}
