import styled from 'styled-components/macro';

export const CompetitorCell = styled.div`
  width: 40px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
