export enum FLOATING_AI_TYPE_ENUM {
  TITLE = 'title',
  META_DESCRIPTION = 'meta-description',
  CONTENT = 'content',
}

export type FloatingAIType = {
  floatingAIType: FLOATING_AI_TYPE_ENUM | false;
  inputTitleRef: React.RefObject<HTMLInputElement>;
  textareaMetaDescriptionRef: React.RefObject<HTMLTextAreaElement>;
};
