import i18next from 'i18next';
import { capitalize } from 'semji-core/utils/string';

export function getExtraKeyFromAlgorithm(algorithm) {
  switch (algorithm) {
    case 'clicks':
      return 'monthlyClicks';
    case 'conversions':
      return 'monthlyConversions';
    case 'goal1Completions':
      return 'monthlyGoal1Completions';
    case 'goal2Completions':
      return 'monthlyGoal2Completions';
    case 'goal3Completions':
      return 'monthlyGoal3Completions';
    case 'transactions':
      return 'monthlyTransactions';
    case 'revenue':
      return 'monthlyRevenue';
    default:
      return `monthly${capitalize(algorithm)}`;
  }
}

export function getExtraRateKeyFromAlgorithm(algorithm) {
  switch (algorithm) {
    case 'conversions':
      return 'monthlyConversionRate';
    case 'goal1Completions':
      return 'monthlyGoal1CompletionRate';
    case 'goal2Completions':
      return 'monthlyGoal2CompletionRate';
    case 'goal3Completions':
      return 'monthlyGoal3CompletionRate';
    case 'transactions':
      return 'monthlyTransactionRate';
    default:
      return `monthly${capitalize(algorithm)}Rate`;
  }
}

export function getLabelFromAlgorithm(algorithm, metrics) {
  switch (algorithm) {
    case 'goal1Completions':
      return {
        monthlyLabel: metrics?.goal1?.name,
        potentialLabel: metrics?.goal1?.name,
      };

    case 'goal2Completions':
      return {
        monthlyLabel: metrics?.goal2?.name,
        potentialLabel: metrics?.goal2?.name,
      };

    case 'goal3Completions':
      return {
        monthlyLabel: metrics?.goal3?.name,
        potentialLabel: metrics?.goal3?.name,
      };

    default:
      return {
        monthlyLabel: i18next.t(`listing:pages-list.list.metric-name-${algorithm}`),
        potentialLabel: i18next.t(`listing:pages-list.list.metric-name-${algorithm}`),
      };
  }
}
