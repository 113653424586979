import { CLOSE_DIALOG, OPEN_DIALOG, SET_DIALOG_STATE } from '@/store/actions/actionTypes';

export const SUCCESS_MARK_AS_PUBLISHED_DIALOG = 'SuccessMarkAsPublishedDialog';

const initialState = {
  [SUCCESS_MARK_AS_PUBLISHED_DIALOG]: {
    dratfCount: 0,
    isOpen: false,
  },
};

const dialogs = (state = initialState, action) => {
  const { dialogName, type, data } = action;
  const currentDialogState = state?.[dialogName];
  if (!currentDialogState) {
    return state;
  }

  switch (type) {
    case SET_DIALOG_STATE: {
      return {
        ...state,
        [dialogName]: {
          ...data,
        },
      };
    }
    case OPEN_DIALOG: {
      return {
        ...state,
        [dialogName]: {
          ...currentDialogState,
          isOpen: true,
        },
      };
    }
    case CLOSE_DIALOG: {
      return {
        ...state,
        [dialogName]: {
          isOpen: false,
        },
      };
    }
    default:
      return state;
  }
};

export default dialogs;
