import styled from 'styled-components/macro';

import { FlexRowCenteredContainer } from '@/components/_common';
import { NoPaddingParagraphText, Wrapper } from '@/containers/Onboarding/common';

const BorderedNumber = styled(FlexRowCenteredContainer)`
  && {
    flex: none;
  }
  background-color: ${({ theme }) => theme.cssColors.dark005};
  border-radius: 100%;
  height: 22px;
  width: 22px;
  margin-right: 5px;
`;
const StepWrapper = styled.div`
  display: flex;
  align-items: baseline;
  margin-top: ${({ marginTop }) => (marginTop ? '15px' : '0')};
`;
const StepsWrapper = styled.div`
  margin: 20px 0;
`;
const GoodToKnowWrapper = styled.span`
  display: block;
  padding: 15px;
  margin-bottom: 20px;
  background-color: ${({ theme }) => theme.cssColors.dark005};
`;

function DescribeStepsComponents({
  explanatoryText,
  tip,
  helper,
  helperRender,
  visualRendering,
  steps,
  button,
  render,
}) {
  function StepRender({ step, index }) {
    return (
      <StepWrapper marginTop={index !== 0}>
        <BorderedNumber>{index + 1}</BorderedNumber>
        <NoPaddingParagraphText size="sm" weight="medium">
          {step}
        </NoPaddingParagraphText>
      </StepWrapper>
    );
  }

  return (
    <Wrapper>
      <div>
        <NoPaddingParagraphText size="sm" weight="medium">
          {explanatoryText}
        </NoPaddingParagraphText>
        {render
          ? render
          : steps &&
            steps.length > 0 && (
              <StepsWrapper>
                {steps.map((step, index) => (
                  <StepRender key={index} index={index} step={step} />
                ))}
              </StepsWrapper>
            )}
        {tip && (
          <NoPaddingParagraphText size="sm" weight="medium">
            <GoodToKnowWrapper>{tip}</GoodToKnowWrapper>
          </NoPaddingParagraphText>
        )}
        {button}
        {helperRender
          ? helperRender
          : helper && (
              <NoPaddingParagraphText size="xs" weight="medium">
                {helper}
              </NoPaddingParagraphText>
            )}
      </div>
      {visualRendering}
    </Wrapper>
  );
}

export default DescribeStepsComponents;
