import { ColDef, ICellRendererParams } from 'ag-grid-community';
import React from 'react';

import { UseGridPaginationResults } from '@/components/AGGrid/hooks/useGridPagination/useGridPagination.types';
import { UseGridSelectionResults } from '@/components/AGGrid/hooks/useGridSelection/useGridSelection.types';
import { UseGridSortsResults } from '@/components/AGGrid/hooks/useGridSorts/useGridSorts.types';
import { UseGridStateResults } from '@/components/AGGrid/hooks/useGridState/useGridState.types';
import { UseFiltersResults } from '@/hooks/useFilters/useFilters.types';

export interface GridBodyProps<Entity> {
  colDefs: IColDef<any>[];
  gridStateHook: UseGridStateResults;
  sortingHook: UseGridSortsResults;
  paginationHook: UseGridPaginationResults;
  gridSelectionHook?: UseGridSelectionResults<Entity>;
  filterHook: UseFiltersResults<any>;
  gridSelection?: boolean;
}

export interface IColDef<Model> extends ColDef<Model> {
  // Force usage of cellRenderer to be able to have a generic loading
  cellRenderer: (params: ICellRendererParams<Model>) => React.ReactNode;
  align?: ENUM_COL_ALIGN;
  width: number;
}

export enum ENUM_COL_ALIGN {
  left = 'left',
  right = 'right',
  center = 'center',
}

export interface IGridColumnPersister {
  columnId: string;
  width: number;
  pinned?: ENUM_GRID_PIN;
}

export enum ENUM_GRID_DISPLAY {
  normal = 'normal',
  pinned = 'pinned',
}

export enum ENUM_GRID_PIN {
  left = 'left',
  right = 'right',
}
