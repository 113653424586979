import MuiDialogContent from '@material-ui/core/DialogContent';
import styled from 'styled-components/macro';

const DialogContent = styled(MuiDialogContent)`
  &&& {
    padding: 0;
  }
`;

export default DialogContent;
