import { ACCESS_DENIED_ERROR } from '@/utils/constants';
import { setRefreshTokenCookie, setTokenCookie } from '@/utils/cookies/cookies';

import { openCenteredWindow } from './googleApi';

export const authorize = (samlLoginUrl) => {
  return new Promise((resolve, reject) => {
    const url = new URL(samlLoginUrl);
    const consentWindow = openCenteredWindow(url.toString(), 'User Consent', 600, 600);

    const interval = setInterval(() => {
      if (consentWindow.closed) {
        clearInterval(interval);
        reject(ACCESS_DENIED_ERROR);
      }
    }, 400);

    window.onmessage = function (e) {
      if (e.data?.sender === 'semji') {
        clearInterval(interval);
        consentWindow.close();
        if (e.data.error) {
          reject(e.data.error);
        } else {
          const { jwtToken: token, refreshToken, refreshTokenExpiration } = e?.data || {};
          setTokenCookie(token);
          setRefreshTokenCookie({
            refreshToken,
            refreshTokenExpiration,
          });
          resolve(token);
        }
      }
    };
  });
};
