import './EmptyState.scss';

import React from 'react';

function FactEmptyState({
  title,
  description,
  illustration,
  callToActionText,
  callToActionFn,
}: {
  title: string;
  description: string;
  illustration: string;
  callToActionText?: string;
  callToActionFn?: Function;
}): JSX.Element {
  return (
    <div className="fact_empty_state">
      <img alt="Fact Shield" src={illustration} />
      <div className="fact_empty_state__body">
        <div className="fact_empty_state__body__title">{title}</div>
        <div className="fact_empty_state__body__description">{description}</div>
        {!!callToActionText && (
          <div className="fact_empty_state__body__call_to_action" onClick={callToActionFn}>
            {callToActionText}
          </div>
        )}
      </div>
    </div>
  );
}

export default FactEmptyState;
