import i18next from 'i18next';
import React from 'react';

import {
  ENUM_FILTER_TYPE,
  MultiFilterProps,
  MultipleFilterType,
} from '@/components/DynamicFilters';
import BookMarkIcon from '@/components/icons/BookMarkIcon';
import { getFunnelOptions } from '@/containers/Competitors/components/HeaderFilters/Filters/FunnelFilter/const';
import { FUNNEL_STAGE_FILTER_QUERY_PARAM } from '@/containers/Competitors/utils/constants';

export function getFunnelFilter({ updateFilter, filter }: MultiFilterProps): MultipleFilterType {
  return {
    icon: <BookMarkIcon />,
    key: FUNNEL_STAGE_FILTER_QUERY_PARAM,
    name: i18next.t('competitors:filters.funnel-stage'),
    onValidate: (value) => updateFilter(FUNNEL_STAGE_FILTER_QUERY_PARAM, value),
    options: getFunnelOptions(filter),
    type: ENUM_FILTER_TYPE.MULTI_SELECT,
  };
}
