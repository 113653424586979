import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import Placeholder from '@/assets/images/image-placeholder.svg';

const FALLBACK_URL = Placeholder;

const Cell = styled.div`
  width: ${({ width }) => width ?? '40px'};
  min-width: ${({ width }) => width ?? '40px'};
  height: 40px;
  margin-right: 10px;
  box-sizing: border-box;
  background: ${({ pictureUrl }) =>
    !!pictureUrl
      ? 'transparent'
      : `url("${FALLBACK_URL}") left center/contain no-repeat transparent`};
`;

const Image = styled.img`
  width: 40px;
  height: 40px;
  object-fit: cover;
`;

function addDefaultSrc(e) {
  e.target.src = FALLBACK_URL;
}

function PictureCell({ pictureUrl, width }) {
  const { t } = useTranslation();

  return (
    <Cell pictureUrl={pictureUrl} width={width}>
      {!!pictureUrl && (
        <Image
          alt={t('components:table.picture-cell.alt')}
          loading="lazy"
          src={pictureUrl}
          onError={addDefaultSrc}
        />
      )}
    </Cell>
  );
}

export default PictureCell;
