import {
  FETCH_REGISTRANT_SUCCESS,
  FETCH_USER_SUCCESS,
  LOGIN_SUCCESS,
  REGISTER_USER_SUCCESS,
  SET_USER_COMMENT_EMAIL_NOTIFICATIONS,
  SET_USER_CONTENT_EMAIL_NOTIFICATIONS,
  SET_USER_EMAIL,
  SET_USER_FACIAL_ID,
  SET_USER_FIRST_NAME,
  SET_USER_IS_EXTERNAL_LOGIN,
  SET_USER_LANGUAGE_CODE,
  SET_USER_LAST_NAME,
  SET_USER_PHONE_NUMBER,
  SET_USER_PROFILE_IMAGE_HASH,
  SET_USER_UPLOADED_AVATAR_URL,
  UPDATE_USER_WORKSPACE_ACCESSES,
} from '@/store/actions/actionTypes';

const user = (state = {}, action) => {
  switch (action.type) {
    case FETCH_USER_SUCCESS:
    case FETCH_REGISTRANT_SUCCESS:
    case LOGIN_SUCCESS:
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        ...action.user,
      };
    case SET_USER_EMAIL:
      return {
        ...state,
        email: action.email,
      };
    case SET_USER_FIRST_NAME:
      return {
        ...state,
        firstName: action.firstName,
      };
    case SET_USER_LAST_NAME:
      return {
        ...state,
        lastName: action.lastName,
      };
    case SET_USER_PHONE_NUMBER:
      return {
        ...state,
        phoneNumber: action.phoneNumber,
      };
    case UPDATE_USER_WORKSPACE_ACCESSES:
      return {
        ...state,
        workspaceAccesses: [
          ...state.workspaceAccesses.filter((wa) => wa.id !== action.workspaceAccesses.id),
          action.workspaceAccesses,
        ],
      };
    case SET_USER_UPLOADED_AVATAR_URL:
      return {
        ...state,
        uploadedAvatarUrl: action.uploadedAvatarUrl,
      };
    case SET_USER_PROFILE_IMAGE_HASH:
      return {
        ...state,
        profileImageHash: action.profileImageHash,
      };
    case SET_USER_LANGUAGE_CODE:
      return {
        ...state,
        languageCode: action.languageCode,
      };
    case SET_USER_IS_EXTERNAL_LOGIN:
      return {
        ...state,
        isExternalLogin: action.payload.value,
      };
    case SET_USER_FACIAL_ID:
      return {
        ...state,
        facialId: action.facialId,
      };
    case SET_USER_COMMENT_EMAIL_NOTIFICATIONS:
      return {
        ...state,
        commentEmailNotificationsEnabled: action.commentEmailNotificationsEnabled,
      };
    case SET_USER_CONTENT_EMAIL_NOTIFICATIONS:
      return {
        ...state,
        contentEmailNotificationsEnabled: action.contentEmailNotificationsEnabled,
      };
    default:
      return state;
  }
};

export default user;
