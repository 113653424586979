import './Report.scss';

import debounce from 'lodash/debounce';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Alert } from 'semji-core/components/Alert';

import usePostTopKeywordAnalysis from '@/apis/semji/topKeywords/usePostTopKeywordAnalysis';
import { FlexGrowColumnCenteredContainer } from '@/components/_common/index';
import FocusKeywordSelector from '@/components/FocusKeyword';
import { EDITOR_FK } from '@/components/FocusKeyword/constants';
import SpinnerIconTwo from '@/components/icons/SpinnerIconTwo';
import CircleLoader from '@/components/Loader/CircleLoader';
import ContentScoreCss from '@/components/Table/Cell/ContentScoreCss';
import Body from '@/containers/Content/SidePanel/Components/Body';
import RelaunchAnalysis from '@/containers/Content/SidePanel/Components/RelaunchAnalysis';
import ReportTabs from '@/containers/Content/SidePanel/Components/Report/Tabs/ReportTabs';
import SidePanelNoKeyword from '@/containers/Content/SidePanel/Components/SidePanelNoKeyword';
import useApiConfigurations from '@/hooks/useApiConfigurations';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import KeywordService from '@/services/Keyword';
import PageService from '@/services/Page';
import ReportService from '@/services/Report';
import {
  refreshCurrentReport,
  setFocusTopKeyword,
  updateFocusTopKeyword,
} from '@/store/actions/report';
import { showErrorSnackbar } from '@/store/actions/ui';
import selectCurrentWorkspaceCountryName from '@/store/selectors/selectCurrentWorkspaceCountryName';
import { selectRemainingAnalysisUnit } from '@/store/selectors/selectRemainingAnalysisUnit';
import { SOURCE_EDITOR, STATUS_PENDING, STATUS_QUEUED, STATUS_SUCCESS } from '@/utils/analysis';
import { makeCancelable } from '@/utils/cancelabePromise';
import { EDITOR_REPORT_FREQUENCY_MS } from '@/utils/configurations/constants';
import { ESTIMATED_AMOUNT_OF_ANALYSIS_UNIT_TO_START_ANALYSIS } from '@/utils/constants';
import { SECTIONS } from '@/utils/log/constants';
import { Log } from '@/utils/log/Log';

const MAX_RECURSIVE_CALL = 5;

function Report({ loading, isReadOnly, showMarkAsPublishedDialog, url, setTopKeywordUpdating }) {
  const { t } = useTranslation();
  const { pageId } = useParams();
  const dispatch = useDispatch();
  const REPORT_DELAY_MS = parseInt(useApiConfigurations(EDITOR_REPORT_FREQUENCY_MS), 10);

  const html = useSelector((state) => state.content?.html);
  const title = useSelector((state) => state.content.title);
  const score = useSelector((state) => state.report.score);
  const workspaceCountryName = useSelector(selectCurrentWorkspaceCountryName);
  const remainingAnalysisUnit = useSelector(selectRemainingAnalysisUnit);
  const hasSearchConsole = useSelector((state) => state.serviceIntegrations.hasSearchConsole);
  const focusTopKeyword = useSelector((state) => state.report?.focusTopKeyword || {});
  const { isRefreshLoading } = useSelector((state) => state.editor);
  const recommendations = useSelector((state) => state.report.recommendations);

  const pageService = new PageService(pageId);

  const [loadingTopKeywords, setLoadingTopKeywords] = useState(true);
  const [loadingTopKeyword, setLoadingTopKeyword] = useState(false);
  const [loadingReport, setLoadingReport] = useState(true);
  const [areManyAnalysisInProgress, setAreManyAnalysisInProgress] = useState(false);
  const [recursiveReportCallEnabled, setRecursiveReportCallEnabled] = useState(false); // this flag is temporary to fix #3318
  const [isExtraSmallMode, setIsExtraSmallMode] = useState(false);

  const topKeywordAnalysis = usePostTopKeywordAnalysis({
    onError: () => dispatch(showErrorSnackbar(t('common:error.default'))),
  });

  const { isFeatureEnabled: hasUnlimitedAnalysisCredits } = useOrganizationFeatureSet(
    'credits:analysis:has-unlimited-amount'
  );
  const debounceRef = useRef(null);
  const cancelFetchReportRef = useRef(null);
  const recursiveCallTimeoutIdRef = useRef(null);

  // Used Ref here to prevent closure effect.
  const topKeywordsRef = useRef([]);
  const fkRef = useRef(null);
  const htmlRef = useRef(null);
  const titleRef = useRef(null);
  fkRef.current = focusTopKeyword;
  htmlRef.current = html;
  titleRef.current = title;

  const mandatoryRecommendationsReady =
    recommendations?.articleWordsCount?.ready === true &&
    recommendations?.focusKeywordInTitle?.ready === true;

  const areSomeTopKeywordsAnalyzed =
    focusTopKeyword?.analyzed ||
    topKeywordsRef.current.some((keyword) => keyword.analyzed) ||
    (loadingTopKeyword && debounceRef.current);

  useEffect(() => {
    //  to trigger the get on keywords if the get on the mount cycle hasn't been triggered
    if (!loading && !!loadingTopKeywords) {
      getKeywordsByPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, loadingTopKeywords]);

  useEffect(() => {
    // to start the report after each change
    // automatically if there is at least one analyzed keyword
    // and the loading should be already finished
    // trigger the get report after loading the list of top keywords and having at least one keyword analyzed
    if (!loadingTopKeywords && areSomeTopKeywordsAnalyzed) {
      getReport(html, title, focusTopKeyword?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [html, title, focusTopKeyword?.id, loadingTopKeywords, areSomeTopKeywordsAnalyzed]);

  useEffect(() => {
    // to trigger the get on keywords after loading all the assets from the parent container
    if (!loading) {
      getKeywordsByPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    // start the recursive calls system
    if (recursiveReportCallEnabled) {
      recursiveCall();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recursiveReportCallEnabled]);

  useEffect(() => {
    return () => {
      debounceRef.current?.cancel?.();
      cancelFetchReportRef.current?.();

      if (recursiveCallTimeoutIdRef.current) {
        clearTimeout(recursiveCallTimeoutIdRef.current);
      }
    };
  }, []);

  // Call the report recursively is to fix temporarily #3318 & #5012
  const recursiveCall = (recursiveCallCounter = 0) => {
    // trigger the report only after finishing the analysis
    if (STATUS_SUCCESS === fkRef?.current?.analysisStatus) {
      if (recursiveCallCounter >= MAX_RECURSIVE_CALL) {
        clearTimeout(recursiveCallTimeoutIdRef.current);

        return;
      }

      ++recursiveCallCounter;

      getReport(htmlRef.current, titleRef.current, fkRef?.current?.id, 0);
    }

    recursiveCallTimeoutIdRef.current = setTimeout(() => {
      recursiveCall(recursiveCallCounter);
    }, 5000);
  };

  const updateTopKeyword = () => {
    const index = topKeywordsRef.current.findIndex((elem) => elem.id === focusTopKeyword?.id);

    if (index !== -1) {
      dispatch(updateFocusTopKeyword());
    }
  };

  async function getReport(html, title, focusTopKeywordId, delay = 300) {
    debounceRef.current?.cancel?.();
    cancelFetchReportRef.current?.();

    if (focusTopKeywordId && focusTopKeywordId.startsWith('pending_top_keyword_id')) {
      return;
    }

    debounceRef.current = debounce(async () => {
      try {
        if (focusTopKeywordId) {
          /**** getting the report of the draft and the given focus keyword (selected top keyword) ******/
          // would be better to trigger this service without title, html
          const reportService = new ReportService(html, title, focusTopKeywordId);
          const { promise, cancel } = makeCancelable(reportService.report);
          cancelFetchReportRef.current = cancel;
          const report = await promise;

          setAreManyAnalysisInProgress(report.manyAnalysisInProgress);
          dispatch(setFocusTopKeyword(report.topKeyword));

          topKeywordsRef.current = [
            ...topKeywordsRef.current.map((tk) =>
              tk.id === report.topKeyword?.id ? report.topKeyword : tk
            ),
          ];

          if (STATUS_QUEUED === report.topKeyword.analysisStatus) {
            setLoadingReport(false);
            setLoadingTopKeyword(false);

            getReport(html, title, focusTopKeywordId, REPORT_DELAY_MS);
          } else if (STATUS_PENDING === report.topKeyword.analysisStatus) {
            setLoadingReport(false);
            setLoadingTopKeyword(false);

            // Enable the recursive call only when it is a new analysis this logic should be dropped once we fix the api #3318
            setRecursiveReportCallEnabled(true);

            /** save the report in the store to use the same component for the recommendations (temporary)**/
            dispatch(refreshCurrentReport(report, true));

            getReport(html, title, focusTopKeywordId, REPORT_DELAY_MS);
          } else if (
            !report.topKeyword.analyzed &&
            (hasUnlimitedAnalysisCredits ||
              remainingAnalysisUnit >= ESTIMATED_AMOUNT_OF_ANALYSIS_UNIT_TO_START_ANALYSIS)
          ) {
            await topKeywordAnalysis.mutateAsync({
              data: {
                source: SOURCE_EDITOR,
              },
              topKeywordIdList: [focusTopKeywordId],
            });
            getReport(html, title, focusTopKeywordId);
          } else if (
            !report.topKeyword.analyzed &&
            !hasUnlimitedAnalysisCredits &&
            remainingAnalysisUnit < ESTIMATED_AMOUNT_OF_ANALYSIS_UNIT_TO_START_ANALYSIS
          ) {
            setLoadingReport(false);
            setLoadingTopKeyword(false);
          } else if (report.topKeyword.analyzed && report.topKeyword.lastAnalysisExpired) {
            await topKeywordAnalysis.mutateAsync({
              data: {
                source: SOURCE_EDITOR,
              },
              topKeywordIdList: [focusTopKeywordId],
            });
            getReport(html, title, focusTopKeywordId);
          } else {
            setTopKeywordUpdating?.({ keyword: null, loading: false });
            setLoadingReport(false);
            setLoadingTopKeyword(false);
            try {
              /** save the report in the store to use the same component for the recommendations (temporary)**/
              dispatch(refreshCurrentReport(report, true));
            } catch (error) {
              Log.report({
                context: 'refreshCurrentReport',
                error,
                extra: 'Save the report in the store',
                section: SECTIONS.content.key,
              });
            }
          }
        } else {
          setLoadingReport(false);
          setLoadingTopKeyword(false);
        }
      } catch (error) {
        if (error.isCanceled) {
          return;
        }

        Log.report({
          context: 'getReport',
          error,
          extra: 'get the report in the side panel',
          section: SECTIONS.content.key,
        });
      }
    }, delay);

    debounceRef.current?.();
  }

  async function onKeywordAdd(option, startReport) {
    debounceRef.current?.cancel?.();

    topKeywordsRef.current.push(option);

    dispatch(setFocusTopKeyword(option));
    setLoadingTopKeyword(true);

    const newTopKeyword = await pageService.addTopKeyword(option.keyword);
    // removing the added temp keyword from the list
    topKeywordsRef.current = topKeywordsRef.current.filter((o) => o.id !== option?.id);

    //setting the focus top keyword with the right metrics
    topKeywordsRef.current.push(newTopKeyword);
    dispatch(setFocusTopKeyword(newTopKeyword));

    await pageService.setFocusTopKeyword(newTopKeyword['@id']);
    if (false !== startReport) {
      getReport(html, title, newTopKeyword.id);
      updateTopKeyword();
    } else {
      setLoadingTopKeyword(false);
    }
  }

  async function onKeywordChange(option, startReport) {
    debounceRef.current?.cancel?.();

    dispatch(setFocusTopKeyword(option));
    setLoadingTopKeyword(true);

    setTopKeywordUpdating?.({ keyword: option.keyword, loading: true });
    await pageService.setFocusTopKeyword(option.topKeywordData['@id']);

    if (false !== startReport) {
      getReport(html, title, option.id);
      updateTopKeyword();
    } else {
      setLoadingTopKeyword(false);
    }
  }

  async function getKeywordsByPage() {
    // we do not need to call the get each tile we click
    if (topKeywordsRef.current.length === 0) {
      try {
        const keywordService = new KeywordService(null, pageId);
        topKeywordsRef.current = await keywordService.keywords;
      } catch (error) {
        Log.report({
          context: 'getKeywordsByPage',
          error,
          extra: 'get the top keywords by page',
          section: SECTIONS.content.key,
        });

        topKeywordsRef.current = [];
      } finally {
        setLoadingTopKeywords(false);
      }
    }
  }

  function startAnalysis() {
    getReport(html, title, focusTopKeyword?.id);
    updateTopKeyword();
  }

  function keywordAdd(option) {
    onKeywordAdd(option, false);
  }

  function keywordChange(option) {
    onKeywordChange(option, false);
  }

  function refreshReport() {
    getReport(html, title, focusTopKeyword?.id);
  }

  function handleInputGetFocus() {
    setIsExtraSmallMode(true);
  }

  function handleInputLooseFocus() {
    setIsExtraSmallMode(false);
  }

  const isLoading = loadingReport || loadingTopKeyword || isRefreshLoading;

  if (loadingTopKeywords) {
    return (
      <FlexGrowColumnCenteredContainer>
        <CircleLoader />
      </FlexGrowColumnCenteredContainer>
    );
  }
  const isAnalyzing = [STATUS_PENDING, STATUS_QUEUED].includes(focusTopKeyword.analysisStatus);

  if (areSomeTopKeywordsAnalyzed) {
    return (
      <>
        <div className="editor-sidepanel-report__header">
          <div className="editor-sidepanel-report__header__keyword">
            <FocusKeywordSelector
              focusTopKeywordId={focusTopKeyword.id}
              getKeywordsByPage={getKeywordsByPage}
              hasSearchConsole={Boolean(hasSearchConsole)}
              topKeywords={topKeywordsRef.current}
              variant={EDITOR_FK}
              workspaceCountryName={workspaceCountryName}
              onBlur={handleInputLooseFocus}
              onFocus={handleInputGetFocus}
              onKeywordAdd={onKeywordAdd}
              onKeywordChange={onKeywordChange}
            />
            <ContentScoreCss
              dataIntercomTarget="editor-sidepanel-report__content-score"
              extraLargeMode={!isExtraSmallMode}
              extraSmallMode={isExtraSmallMode}
              loading={isLoading || isAnalyzing}
              score={Math.floor(score * 100)}
            />
          </div>
          {!!focusTopKeyword && !!focusTopKeyword?.analyzedAt && (
            <RelaunchAnalysis focusTopKeyword={focusTopKeyword} refreshReport={refreshReport} />
          )}
          <Alert
            className={`editor-sidepanel-report__header__alert ${!isAnalyzing && 'editor-sidepanel-report__header__alert--hidden'}`}
            Icon={() => <SpinnerIconTwo className="animate-spin" />}
            variant="secondary"
          >
            {t('content:side-panel-components.report.focus-keyword-analyzing')}
          </Alert>
          <ReportTabs
            disabled={isLoading}
            focusTopKeyword={focusTopKeyword}
            mandatoryRecommendationsReady={mandatoryRecommendationsReady}
          />
        </div>
        <Body
          areManyAnalysisInProgress={areManyAnalysisInProgress}
          focusTopKeyword={focusTopKeyword}
          isReadOnly={isReadOnly}
          loading={loadingReport || loadingTopKeyword}
          mandatoryRecommendationsReady={mandatoryRecommendationsReady}
          refreshReport={refreshReport}
          showMarkAsPublishedDialog={showMarkAsPublishedDialog}
          url={url}
        />
      </>
    );
  }

  return (
    <SidePanelNoKeyword
      focusTopKeyword={focusTopKeyword ?? {}}
      getKeywordsByPage={getKeywordsByPage}
      hasSearchConsole={hasSearchConsole}
      loading={loadingTopKeyword}
      remainingAnalysisUnit={remainingAnalysisUnit}
      startAnalysis={startAnalysis}
      topKeywords={topKeywordsRef.current}
      workspaceCountryName={workspaceCountryName}
      onKeywordAdd={keywordAdd}
      onKeywordChange={keywordChange}
    />
  );
}

export default Report;
