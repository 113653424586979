import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { LinkLikeButton } from '@/components/Button/Button';
import FavIcon from '@/components/FavIcon/FavIcon';
import TargetIcon from '@/components/icons/TargetIcon';
import Column from '@/containers/Content/BriefComponents/Table/Columns/Column';
import MetadataColumn from '@/containers/Content/BriefComponents/Table/Columns/MetadataColumn';
import OneLineColumn from '@/containers/Content/BriefComponents/Table/Columns/OneLineColumn';
import PrimaryColumn from '@/containers/Content/BriefComponents/Table/Columns/PrimaryColumn';
import EmptyRow from '@/containers/Content/BriefComponents/Table/Rows/EmptyRow';
import Header from '@/containers/Content/BriefComponents/Table/Rows/Header';
import LoadingRow from '@/containers/Content/BriefComponents/Table/Rows/LoadingRow';
import MultiLineRow from '@/containers/Content/BriefComponents/Table/Rows/MultiLineRow';
import Row from '@/containers/Content/BriefComponents/Table/Rows/Row';
import Table from '@/containers/Content/BriefComponents/Table/Table';
import DarkerText from '@/containers/Content/BriefComponents/Text/DarkerText';
import { getFragmentDirective } from '@/utils/url';

const SmallFavIcon = styled(FavIcon)`
  &&& {
    img {
      box-sizing: border-box;
      padding: 0;
    }
  }
`;

function RelatedTerms({ isLoading, relatedTerms }) {
  const { t } = useTranslation();
  const [isShowingAll, setIsShowingAll] = useState(false);

  const hasALotOfRelatedTerms = relatedTerms.length > 10;

  const relatedTermsToShow = useMemo(() => {
    if (hasALotOfRelatedTerms && !isShowingAll) {
      return relatedTerms.slice(0, 10);
    }

    return relatedTerms;
  }, [hasALotOfRelatedTerms, isShowingAll, relatedTerms]);

  return (
    <Table>
      <Header>
        <PrimaryColumn>
          {t('content:brief.section-article-table-suggested-related-terms-header')}
        </PrimaryColumn>
      </Header>

      {isLoading ? (
        <LoadingRow />
      ) : relatedTerms?.length > 0 ? (
        <>
          {relatedTermsToShow.map(({ term, label, samples = [], relevanceInterval }) => {
            return (
              <MultiLineRow key={label} gap="8px">
                <Column gap="8px">
                  <PrimaryColumn>
                    <DarkerText as="strong" title={term}>
                      {label}
                    </DarkerText>
                  </PrimaryColumn>
                  <MetadataColumn>
                    <TargetIcon />
                    &nbsp;{relevanceInterval ?? '-'}
                  </MetadataColumn>
                </Column>
                {samples?.[0] && (
                  <OneLineColumn color="light" gap="15px">
                    <PrimaryColumn color="light">
                      <div
                        dangerouslySetInnerHTML={{ __html: samples?.[0]?.html }}
                        title={samples?.[0]?.text}
                      />
                    </PrimaryColumn>
                    <SmallFavIcon
                      url={`${samples?.[0]?.competitorUrl}#${getFragmentDirective(
                        samples?.[0]?.text
                      )}`}
                    />
                  </OneLineColumn>
                )}
              </MultiLineRow>
            );
          })}
          {hasALotOfRelatedTerms && (
            <Row>
              <PrimaryColumn halign="middle">
                <LinkLikeButton onClick={() => setIsShowingAll(!isShowingAll)}>
                  {/* Traduction keys are not built dynamically here to facilitate search of keys in the code base */}
                  {t(isShowingAll ? 'content:brief.show-less' : 'content:brief.show-more')}
                </LinkLikeButton>
              </PrimaryColumn>
            </Row>
          )}
        </>
      ) : (
        <EmptyRow />
      )}
    </Table>
  );
}

export default RelatedTerms;
