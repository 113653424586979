import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import FlashMessage from '@/components/FlashMessage/FlashMessage';
import { DefaultLink } from '@/components/Navigation/Link';
import {
  CANCELLED_SUBSCRIPTION__LESS_60_SUBSCRIPTION,
  CANCELLED_SUBSCRIPTION__MORE_60_SUBSCRIPTION,
  CANCELLED_TRIAL__LESS_60_DAYS,
  CANCELLED_TRIAL__MORE_60_DAYS,
  IN_TRIAL__EXPIRES_LESS_5_DAYS,
  IN_TRIAL__EXPIRES_MORE_5_DAYS,
  isSelfServeOrganization,
  NON_RENEWING__SUBSCRIPTION,
  PAYMENT_ERROR_AFTER_DUNNING__SUBSCRIPTION,
  PAYMENT_ERROR_DURING_DUNNING__SUBSCRIPTION,
  planStatus,
} from '@/utils/billing';

export default function SubscriptionStatusFlashMessage({ organizationId }) {
  const { t } = useTranslation();
  const currentOrganization = useSelector((state) => state.organizations.byId[organizationId]);
  const {
    subscriptionNextBillingAt,
    subscriptionTrialEndAt,
    subscriptionStatus,
    subscriptionCurrentTermEndAt,
    paymentProblemOccurred,
    paymentDunningEndAt,
  } = currentOrganization;
  const {
    status,
    subscriptionTrialEndAtDiffDaysFromNow,
    subscriptionCurrentTermEndAtDiffDaysFromNow,
    subscriptionPaymentDunningEndAtDiffDaysFromNow,
  } = planStatus({
    paymentDunningEndAt,
    paymentProblemOccurred,
    subscriptionCurrentTermEndAt,
    subscriptionNextBillingAt,
    subscriptionStatus,
    subscriptionTrialEndAt,
  });

  // We skip subscription status message for managed plan type
  if (!isSelfServeOrganization(currentOrganization)) {
    return null;
  }

  switch (status) {
    case PAYMENT_ERROR_DURING_DUNNING__SUBSCRIPTION:
      return (
        <FlashMessage bgColor="primary" color="white">
          <span>
            {t('layout:flash-message.payment-error-during-dunning', {
              dayCount: Math.abs(subscriptionPaymentDunningEndAtDiffDaysFromNow),
              dayFormat: t('layout:flash-message.day_interval', {
                count: Math.abs(subscriptionPaymentDunningEndAtDiffDaysFromNow),
                postProcess: 'interval',
              }),
            })}
            &nbsp;
          </span>
          <DefaultLink
            color="white"
            size="sm"
            to={`/o/${organizationId}/settings/billing`}
            weight="strong"
          >
            {t('layout:flash-message.update-payment-method')}
          </DefaultLink>
        </FlashMessage>
      );
    case PAYMENT_ERROR_AFTER_DUNNING__SUBSCRIPTION:
      return (
        <FlashMessage bgColor="primary" color="white">
          <span>
            {t('layout:flash-message.payment-error-after-dunning')}
            &nbsp;
          </span>
          <DefaultLink
            color="white"
            size="sm"
            to={`/o/${organizationId}/settings/billing`}
            weight="strong"
          >
            {t('layout:flash-message.update-payment-method')}
          </DefaultLink>
        </FlashMessage>
      );
    case NON_RENEWING__SUBSCRIPTION:
      return (
        <FlashMessage>
          <span>
            {t('layout:flash-message.your-subscription-expires-in', {
              dayCount: Math.abs(subscriptionCurrentTermEndAtDiffDaysFromNow),
              dayFormat: t('layout:flash-message.day_interval', {
                count: Math.abs(subscriptionCurrentTermEndAtDiffDaysFromNow),
                postProcess: 'interval',
              }),
            })}
            &nbsp;
          </span>
          <DefaultLink size="sm" to={`/o/${organizationId}/settings/billing`} weight="medium">
            {t('layout:flash-message.renew-plan')}
          </DefaultLink>
        </FlashMessage>
      );
    case IN_TRIAL__EXPIRES_MORE_5_DAYS:
      return (
        <FlashMessage>
          <span>
            {t('layout:flash-message.your-trial-expires-in', {
              dayCount: Math.abs(subscriptionTrialEndAtDiffDaysFromNow),
              dayFormat: t('layout:flash-message.day_interval', {
                count: Math.abs(subscriptionTrialEndAtDiffDaysFromNow),
                postProcess: 'interval',
              }),
            })}
            &nbsp;
          </span>
          <DefaultLink size="sm" to={`/o/${organizationId}/settings/billing`} weight="strong">
            {t('layout:flash-message.upgrade-plan')}
          </DefaultLink>
        </FlashMessage>
      );
    case IN_TRIAL__EXPIRES_LESS_5_DAYS:
      return (
        <FlashMessage hideIcon>
          <span>
            {t('layout:flash-message.your-trial-expires-in', {
              dayCount: Math.abs(subscriptionTrialEndAtDiffDaysFromNow),
              dayFormat: t('layout:flash-message.day_interval', {
                count: Math.abs(subscriptionTrialEndAtDiffDaysFromNow),
                postProcess: 'interval',
              }),
            })}
            &nbsp;
          </span>
          <DefaultLink size="sm" to={`/o/${organizationId}/settings/billing`} weight="medium">
            {t('layout:flash-message.upgrade-plan')}
          </DefaultLink>
        </FlashMessage>
      );
    case CANCELLED_TRIAL__LESS_60_DAYS:
      return (
        <FlashMessage bgColor="primary" color="white" hideIcon>
          <span>
            {t('layout:flash-message.your-trial-has-expires-ago-less-60-days', {
              dayCount: Math.abs(subscriptionTrialEndAtDiffDaysFromNow),
              dayFormat: t('layout:flash-message.day_interval', {
                count: Math.abs(subscriptionTrialEndAtDiffDaysFromNow),
                postProcess: 'interval',
              }),
            })}
            &nbsp;
          </span>
          <DefaultLink
            color="white"
            size="sm"
            to={`/o/${organizationId}/settings/billing`}
            weight="strong"
          >
            {t('layout:flash-message.upgrade-plan')}
          </DefaultLink>
        </FlashMessage>
      );
    case CANCELLED_TRIAL__MORE_60_DAYS:
      return (
        <FlashMessage bgColor="primary" color="white" hideIcon>
          <span>
            {t('layout:flash-message.your-trial-has-expires-ago-more-60-days', {
              dayCount: Math.abs(subscriptionTrialEndAtDiffDaysFromNow),
              dayFormat: t('layout:flash-message.day_interval', {
                count: Math.abs(subscriptionTrialEndAtDiffDaysFromNow),
                postProcess: 'interval',
              }),
            })}
            &nbsp;
          </span>
          <DefaultLink
            color="white"
            size="sm"
            to={`/o/${organizationId}/settings/billing`}
            weight="strong"
          >
            {t('layout:flash-message.upgrade-plan')}
          </DefaultLink>
        </FlashMessage>
      );
    case CANCELLED_SUBSCRIPTION__MORE_60_SUBSCRIPTION:
      return (
        <FlashMessage bgColor="primary" color="white" hideIcon>
          <span>
            {t('layout:flash-message.your-subscription-has-expires-ago-more-60-days', {
              dayCount: Math.abs(subscriptionCurrentTermEndAtDiffDaysFromNow),
              dayFormat: t('layout:flash-message.day_interval', {
                count: Math.abs(subscriptionCurrentTermEndAtDiffDaysFromNow),
                postProcess: 'interval',
              }),
            })}
            &nbsp;
          </span>
          <DefaultLink
            color="white"
            size="sm"
            to={`/o/${organizationId}/settings/billing`}
            weight="strong"
          >
            {t('layout:flash-message.upgrade-plan')}
          </DefaultLink>
        </FlashMessage>
      );
    case CANCELLED_SUBSCRIPTION__LESS_60_SUBSCRIPTION:
      return (
        <FlashMessage bgColor="primary" color="white" hideIcon>
          <span>
            {t('layout:flash-message.your-subscription-has-expires-ago-less-60-days', {
              dayCount: Math.abs(subscriptionCurrentTermEndAtDiffDaysFromNow),
              dayFormat: t('layout:flash-message.day_interval', {
                count: Math.abs(subscriptionCurrentTermEndAtDiffDaysFromNow),
                postProcess: 'interval',
              }),
            })}
            &nbsp;
          </span>
          <DefaultLink
            color="white"
            size="sm"
            to={`/o/${organizationId}/settings/billing`}
            weight="strong"
          >
            {t('layout:flash-message.upgrade-plan')}
          </DefaultLink>
        </FlashMessage>
      );
    default:
      return null;
  }
}
