import './HistoryVersionModal.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { DialogModal, ENUM_DIALOG_MODAL_SIZE } from 'semji-core/components/DialogModal';
import { ENUM_TABS_VARIANTS, Tabs } from 'semji-core/components/Tabs';

import EmptyState from '@/containers/ContentVersion/components/VersionHistoryPanel/LoadingState';
import VersionGroup from '@/containers/ContentVersion/components/VersionHistoryPanel/VersionGroup';
import VersionHistoryPanelUtils from '@/containers/ContentVersion/components/VersionHistoryPanel/VersionHistoryPanel.utils';
import { ENUM_CONTENT_TYPE } from '@/types/contents';

import NoVersion from './NoVersion';

export function HistoryVersionModal({
  isOpen,
  versions,
  isLoading,
  setIsOpen,
  handleClose,
  versionType,
  selectedItem,
  setSelectedItem,
  status,
  setStatus,
}: {
  isOpen: boolean;
  versions: any[];
  isLoading: boolean;
  versionType: string;
  selectedItem: any;
  status: ENUM_CONTENT_TYPE;
  setStatus: (status: ENUM_CONTENT_TYPE) => void;
  setSelectedItem: (selectedItem: any) => void;
  handleClose: () => void;
  setIsOpen: (isOpen: boolean) => void;
}) {
  const { t } = useTranslation();

  const { contentId } = useParams();
  const [query, setQuery] = useSearchParams();

  const selectedVersionId = selectedItem?.id || contentId;

  function handleConfirm() {
    query.set(versionType, selectedVersionId);
    setQuery(query);
    setIsOpen(false);
  }

  function onClose() {
    setStatus(ENUM_CONTENT_TYPE.DRAFT);
    handleSelectTab(ENUM_CONTENT_TYPE.DRAFT);
    setSelectedItem(null);
    handleClose();
  }

  function handleSelectTab(tab: ENUM_CONTENT_TYPE) {
    return function () {
      setStatus(tab);
    };
  }

  function handleSelectVersion(id: string) {
    return function (e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
      const selectedVersion = versions.find((version) => version.id === id);
      setSelectedItem(selectedVersion);
    };
  }

  const filteredVersions = VersionHistoryPanelUtils.filterVersionsByStatus(versions, status);

  const isAccordion = filteredVersions.some((version, index) => {
    return filteredVersions.some((v, i) => {
      let hasSameDate =
        index !== i &&
        v.year === version.year &&
        v.month === version.month &&
        v.day === version.day;
      if (status === ENUM_CONTENT_TYPE.DRAFT) {
        hasSameDate = hasSameDate && v.hour === version.hour;
      }
      return hasSameDate;
    });
  });
  const groupedVersions = VersionHistoryPanelUtils.groupVersionsByMonthYear(filteredVersions);

  const noData = filteredVersions.length === 0;
  return (
    <DialogModal
      cancelAction={onClose}
      cancelLabel={t('content:editor-container.diff-content.history-modal.cancel')}
      canClickOutsideToClose
      className="history-version-modal"
      closable
      confirmAction={handleConfirm}
      confirmLabel={t('content:editor-container.diff-content.history-modal.compare')}
      description={''}
      isOpen={isOpen}
      size={ENUM_DIALOG_MODAL_SIZE.Small}
      title={
        <span className="history-version-modal__title">
          {t('content:editor-container.diff-content.history-modal.title')}
        </span>
      }
      onClose={onClose}
    >
      <Tabs
        overrideClassName="version-history-panel__content__tabs"
        tabs={[
          {
            content: <div>{t('content:version-history-panel.tabs.current-draft')}</div>,
            onClick: handleSelectTab(ENUM_CONTENT_TYPE.DRAFT),
            selected: status === ENUM_CONTENT_TYPE.DRAFT,
          },
          {
            content: <div>{t('content:version-history-panel.tabs.published-versions')}</div>,
            onClick: handleSelectTab(ENUM_CONTENT_TYPE.PUBLISHED),
            selected: status === ENUM_CONTENT_TYPE.PUBLISHED,
          },
        ]}
        variant={ENUM_TABS_VARIANTS.Small}
      />
      {isLoading && <EmptyState />}
      {!isLoading && !noData && (
        <div className="history-version-modal__listing">
          {Object.entries(groupedVersions).map(
            ([date, versions]) =>
              versions.length > 0 && (
                <VersionGroup
                  key={date}
                  date={date}
                  handleSelectVersion={handleSelectVersion}
                  isPublished={status === ENUM_CONTENT_TYPE.PUBLISHED}
                  selectedVersionId={String(selectedVersionId)}
                  versions={versions}
                  withPadding={isAccordion}
                />
              )
          )}
        </div>
      )}
      {!isLoading && noData && <NoVersion canCreateDraft={false} status={status} />}
    </DialogModal>
  );
}
