import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { StyledCard } from '@/components/_common';
import { ContainedButton } from '@/components/Button/Button';
import { RelativeLoader } from '@/components/Loader/Loader';
import Img from '@/containers/ServiceIntegration/SearchConsole/Steps/Dialog/Img';
import ServiceIntegrationService from '@/services/ServiceIntegration';
import { ACCESS_DENIED_ERROR, SERVICES_LIST } from '@/utils/constants';
import { SECTIONS } from '@/utils/log/constants';
import { Log } from '@/utils/log/Log';

const CustomCard = styled(StyledCard)`
  flex-direction: column;
  padding: 2em;
`;

function ConnectStep({ autoTrigger, consent, setError, workspaceWebsiteUrl }) {
  const { t } = useTranslation();

  const [consenting, setConsenting] = useState(false);

  useEffect(() => {
    if (autoTrigger) {
      giveConsent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoTrigger]);

  async function giveConsent() {
    setConsenting(true);
    setError(null);

    try {
      const _ServiceIntegration = new ServiceIntegrationService();
      const { searchConsoleProperties, googleCredentialsValue } =
        await _ServiceIntegration.fetchSearchConsoleData(undefined, workspaceWebsiteUrl);
      setConsenting(false);

      return consent({
        googleCredentialsValue,
        searchConsoleProperties,
      });
    } catch (e) {
      setConsenting(false);
      setError(
        e === ACCESS_DENIED_ERROR
          ? ACCESS_DENIED_ERROR
          : t('service-integration:search-console.connect-step.error')
      );

      Log.report({
        context: 'fetchGoogleData',
        error: e,
        extra: 'search console',
        section: SECTIONS.serviceIntegrations.key,
      });

      consent(null);
    }
  }

  return (
    <div>
      <CustomCard>
        <Img
          alt={t('service-integration:search-console.connect-step.alt-search-console-logo')}
          src={SERVICES_LIST.SEARCH_CONSOLE.logo}
        />
        <ContainedButton disabled={consenting} fullWidth onClick={giveConsent}>
          {consenting ? (
            <RelativeLoader />
          ) : (
            t('service-integration:search-console.connect-step.button-connect')
          )}
        </ContainedButton>
      </CustomCard>
    </div>
  );
}

export default ConnectStep;
