import './VersionGroup.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import DayRender from './DayRender';
import { GroupedVersionProps, TODAY } from './VersionGroup.types';
import { VersionGroupUtils } from './VersionGroup.utils';

export default function VersionGroup({
  date,
  handleSelectVersion,
  handleRenameVersion,
  selectedVersionId,
  versions,
  withPadding,
  isPublished,
  onTabulate: handleTabulate,
  enableOptionsMenu,
}: GroupedVersionProps) {
  const { t } = useTranslation();

  const month = date?.split('-')[0];
  const year = date?.split('-')[1];

  const groupedVersions = isPublished
    ? VersionGroupUtils.groupVersionsByDay(versions)
    : VersionGroupUtils.groupVersionsByHour(versions);

  return (
    <div className="version-history-panel__content__listing__group">
      <div className="version-history-panel__content__listing__group__title">
        {date === TODAY
          ? t('content:version-history-panel.today')
          : `${t('content:version-history-panel.months.' + month)} ${year}`}
      </div>
      {Object.entries(groupedVersions).map(([date, versions]) => (
        <DayRender
          key={date}
          enableOptionsMenu={enableOptionsMenu}
          handleRenameVersion={handleRenameVersion}
          handleSelectVersion={handleSelectVersion}
          isPublished={isPublished}
          selectedVersionId={selectedVersionId}
          versions={versions}
          withPadding={withPadding}
          onTabulate={handleTabulate}
        />
      ))}
    </div>
  );
}
