import { useTranslation } from 'react-i18next';

import useDetectOperatingSystem from '@/hooks/useDetectOperatingSystem';

const BOLD_BUTTON = 'customBold';
const ITALIC_BUTTON = 'customItalic';
const UNDERLINE_BUTTON = 'customUnderline';
const BLOCKQUOTE_BUTTON = 'customBlockquote';

export default function useCustomButtons() {
  const { t } = useTranslation();

  const operatingSystem = useDetectOperatingSystem();
  const controlKey =
    operatingSystem === 'Mac'
      ? t('content:editor-components.toolbar.mac-control-touch')
      : t('content:editor-components.toolbar.other-control-touch');

  const formatButtons = `${BOLD_BUTTON} ${ITALIC_BUTTON} ${UNDERLINE_BUTTON} ${BLOCKQUOTE_BUTTON}`;

  // https://www.tiny.cloud/docs/tinymce/6/content-formatting/#formats
  // https://www.tiny.cloud/docs/tinymce/6/editor-icon-identifiers/
  // https://www.tiny.cloud/docs/tinymce/6/keyboard-shortcuts/
  function generateFormatButton(editor, buttonName, action, icon) {
    editor.ui.registry.addToggleButton(buttonName, {
      icon: icon || action,
      onAction: (_) => editor.execCommand('mceToggleFormat', false, action),
      onSetup: (api) => {
        api.setActive(editor.formatter.match(action));
        const changed = editor.formatter.formatChanged(action, (state) => api.setActive(state));
        return () => changed.unbind();
      },
      tooltip: t(`content:editor-components.toolbar.control-key-label.${action}`, { controlKey }),
    });
  }

  function setupToolbarButtons(editor) {
    generateFormatButton(editor, BOLD_BUTTON, 'bold');
    generateFormatButton(editor, ITALIC_BUTTON, 'italic');
    generateFormatButton(editor, UNDERLINE_BUTTON, 'underline');
    generateFormatButton(editor, BLOCKQUOTE_BUTTON, 'blockquote', 'quote');
  }

  return {
    buttons: {
      formatButtons,
    },
    setupToolbarButtons,
  };
}
