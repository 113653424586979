import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTheme } from 'styled-components/macro';

import { MetricsCartridges } from '@/components/Metrics/MetricsCartridges';
import { useMetricsConfig } from '@/hooks/useMetricsConfig';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import { changeCurrentMetric, changeSelectedMetric } from '@/store/actions/userConfiguration';
import { selectUserConfiguration } from '@/store/selectors/selectUserConfiguration';
import { DEFAULT_REPORT_METRIC_KEY } from '@/utils/constants';
import { aggregateMetrics } from '@/utils/metrics/aggregateMetrics';
import {
  ANALYTICS_DEPENDENCY,
  METRIC_POSITION_KEY,
  METRICS_CONFIG,
  RANKING_KEYWORDS_METRICS,
  SEARCH_CONSOLE_DEPENDENCY,
} from '@/utils/metrics/constants';

const POSITION_TABS = [METRIC_POSITION_KEY, RANKING_KEYWORDS_METRICS];

const MetricSelector = ({
  metrics = [],
  previousMetrics = [],
  theme,
  isPublished,
  direction = 'row',
  size = 'small',
  setGoalKey,
  isRetrievingSearchConsoleData,
  isRetrievingAnalyticsData,
  goalKey,
  forceSelectedPositionKey,
}) => {
  const { selectedMetrics, currentMetricKey } = useSelector(selectUserConfiguration);
  const { isFeatureEnabled: organizationRankTrackingEnabled } = useOrganizationFeatureSet(
    'rank-tracking:is-enabled'
  );
  const dispatch = useDispatch();

  const metricsConfig = useMetricsConfig({ isNew: true });
  const options = Object.keys(metricsConfig).flatMap((key) =>
    key !== DEFAULT_REPORT_METRIC_KEY
      ? [
          {
            key,
            name: metricsConfig[key].name,
          },
        ]
      : []
  );

  useEffect(() => {
    const isPositionMetric = currentMetricKey === METRIC_POSITION_KEY;

    // the current selected metrics should be an element of metrics config
    if (
      !metricsConfig[currentMetricKey] ||
      (isPositionMetric && !organizationRankTrackingEnabled)
    ) {
      // if the currentSelectedMetric is not an element we fall back to a default value
      dispatch(changeCurrentMetric(METRICS_CONFIG.clicks.key));
    }

    // all selected metrics should be an element of metrics config
    selectedMetrics.forEach((selectedMetric, index) => {
      // if a selected metric is not an element of the main list, we fallback to a default value
      // AND if orga hasn't rank tracking rights, we fallback to a default value
      if (
        !metricsConfig[selectedMetric] ||
        (isPositionMetric && !organizationRankTrackingEnabled)
      ) {
        dispatch(changeSelectedMetric(index, METRICS_CONFIG.clicks.key));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationRankTrackingEnabled]);

  const changeMetric = (index, newMetricKey, previousMetricKey) => {
    if (previousMetricKey === currentMetricKey) {
      dispatch(changeCurrentMetric(newMetricKey));
    }

    setGoalKey(null);
    dispatch(changeSelectedMetric(index, newMetricKey));
  };

  const onClick = (metricKey) => {
    setGoalKey(null);
    dispatch(changeCurrentMetric(metricKey));
  };

  const aggregatedMetrics = aggregateMetrics(metrics, metricsConfig);
  const aggregatedPreviousMetrics = aggregateMetrics(previousMetrics, metricsConfig);

  function getPositionValue(currentPositionKey, metric) {
    if (currentPositionKey === METRIC_POSITION_KEY) {
      return metric?.focusTopKeywordPosition ?? 100;
    }
    if (currentPositionKey === RANKING_KEYWORDS_METRICS) {
      return (
        (metric?.rankingKeywordsTop3 ?? 0) +
        (metric?.rankingKeywordsTop10 ?? 0) +
        (metric?.rankingKeywordsTop100 ?? 0)
      );
    }
  }

  return (
    <MetricsCartridges
      cartridgeSize={size}
      direction={direction}
      isPublished={isPublished}
      metrics={selectedMetrics.map((metricKey, index) => {
        let metricKeyToUseForValuesDisplay =
          metricKey === currentMetricKey ? goalKey ?? metricKey : metricKey;
        if (metricKeyToUseForValuesDisplay === METRIC_POSITION_KEY) {
          metricKeyToUseForValuesDisplay = forceSelectedPositionKey;
        }

        return {
          active: metricKey === currentMetricKey,
          color: metricsConfig[metricKey]?.isSubCategory
            ? theme.metricsColor[metricsConfig[metricKey]?.parentCategoryKey]
            : theme.metricsColor[metricKey],
          'data-intercom-target': `editor-performances-tab-${index + 1}`,
          evolutionRating: metricsConfig[metricKey]?.evolutionRating,
          handleMenuItemClick: ({ key }) => changeMetric(index, key, metricKey),
          icon: metricsConfig[metricKey]?.isSubCategory
            ? metricsConfig[metricsConfig[metricKey]?.parentCategoryKey]?.icon
            : metricsConfig[metricKey]?.icon,
          isRetrieving:
            (metricsConfig[metricKey]?.dependency === SEARCH_CONSOLE_DEPENDENCY &&
              isRetrievingSearchConsoleData) ||
            (metricsConfig[metricKey]?.dependency === ANALYTICS_DEPENDENCY &&
              isRetrievingAnalyticsData),
          key: index,
          metricKey,
          name: metricsConfig[metricKey]?.name,
          onClick: () => onClick(metricKey),
          options,
          previousValue: POSITION_TABS.includes(metricKeyToUseForValuesDisplay)
            ? getPositionValue(metricKeyToUseForValuesDisplay, metrics[0])
            : aggregatedPreviousMetrics[metricKeyToUseForValuesDisplay] || 0,
          selectedPositionKey: forceSelectedPositionKey,
          suffix: metricsConfig[metricKey]?.suffix,
          type: metricsConfig[metricKey]?.type,
          value: POSITION_TABS.includes(metricKeyToUseForValuesDisplay)
            ? getPositionValue(metricKeyToUseForValuesDisplay, metrics[metrics.length - 1])
            : aggregatedMetrics[metricKeyToUseForValuesDisplay] || 0,
        };
      })}
      size="100%"
    />
  );
};

export default withTheme(MetricSelector);
