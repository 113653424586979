import { DateTime } from 'luxon';

export default function mapQueryParameterToFilters({ filter, operator, filterList }) {
  const filterValues = filter ? JSON.parse(filter) : [];

  return {
    filterGroups: filterValues.map(({ uid, comparison = '', value = '' }) => {
      const template = filterList.find((el) => el.uid === uid);

      const camparisonObject = template?.type?.comparisonList.find(
        (el) => el.comparison === comparison
      );

      let valueFromUrl = value;

      if (template.type?.type === 'date') {
        valueFromUrl = valueFromUrl ? DateTime.fromFormat(valueFromUrl, 'yyyy-MM-dd') : null;
      }

      return {
        filters: [
          {
            ...template,
            comparison: camparisonObject,
            values: [
              ...(template.mappingValues
                ? [template.mappingValues(valueFromUrl)]
                : [{ labelKey: valueFromUrl, value: valueFromUrl }]),
            ],
          },
        ],
        operator: 'AND',
      };
    }),
    operator,
  };
}
