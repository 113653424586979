import styled from 'styled-components/macro';

import Button from './Button';

const TextButton = styled(Button)`
  color: ${({ disabled, theme }) =>
    disabled ? theme.cssColors.primary020 : theme.cssColors.primary};
  background: transparent;
  border: none;
`;

export default TextButton;
