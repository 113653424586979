import React from 'react';

import SvgWrapper from './SvgWrapper';

const ShoppingCartFiledIcon = (props) => (
  <SvgWrapper viewBox="0 0 16 14" {...props}>
    <path d="M2.7002 11.0004C1.9002 11.0004 1.2002 11.7004 1.2002 12.5004C1.2002 13.3004 1.9002 14.0004 2.7002 14.0004C3.5002 14.0004 4.2002 13.3004 4.2002 12.5004C4.2002 11.7004 3.5002 11.0004 2.7002 11.0004ZM2.7002 13.2004C2.3002 13.2004 2.00012 12.9004 2.00012 12.5004C2.00012 12.1004 2.3002 11.8004 2.7002 11.8004C3.1002 11.8004 3.40015 12.1004 3.40015 12.5004C3.30015 12.9004 3.0002 13.2004 2.7002 13.2004ZM10.1001 11.0004C9.3001 11.0004 8.6001 11.7004 8.6001 12.5004C8.6001 13.3004 9.3001 14.0004 10.1001 14.0004C10.9001 14.0004 11.6001 13.3004 11.6001 12.5004C11.6001 11.7004 11.0001 11.0004 10.1001 11.0004ZM10.1001 13.2004C9.7001 13.2004 9.40015 12.9004 9.40015 12.5004C9.40015 12.1004 9.7001 11.8004 10.1001 11.8004C10.5001 11.8004 10.8002 12.1004 10.8002 12.5004C10.8002 12.9004 10.5001 13.2004 10.1001 13.2004ZM12.0001 1.80038L11.7002 3.6004H0.200195L1.2002 10.7004H11.7002L12.6001 4.1004L12.8002 2.80038H15.2002V1.90039H12.0001V1.80038ZM10.9001 9.70038H2.00012L1.30017 4.40039H11.7002L10.9001 9.70038ZM9.90015 8.80038H2.90015V5.30038H3.80017V7.90039H9.1001V5.30038H10.0001V8.80038H9.90015ZM3.80017 2.6004H2.90015V0.900391H9.90015V2.6004H9.00012V1.80038H3.7002V2.6004H3.80017Z" />
  </SvgWrapper>
);

export default ShoppingCartFiledIcon;
