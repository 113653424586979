import Dialog from '@material-ui/core/Dialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Input from '@material-ui/core/Input';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { FlexColumnContainer } from '@/components/_common';
import { ContainedButton, TextButton } from '@/components/Button/Button';
import { Close } from '@/components/Dialog/AlertDialog';
import { ParagraphText } from '@/components/Text/Paragraph';
import { TitleThree } from '@/components/Text/Title';
import { FlexWrapper } from '@/containers/Content/Wrappers';

const MarginBottomTitle = styled(TitleThree)`
  && {
    margin-bottom: 15px;
  }
`;
const NoPaddingParagraphText = styled(ParagraphText)`
  && {
    padding: 0px;
    margin-bottom: 40px;
  }
`;

const RightClose = styled(Close)`
  && {
    right: 40px;
  }
`;

const MarginFlexColumnContainer = styled(FlexColumnContainer)`
  && {
    margin: 25px 40px 40px 40px;
  }
`;

const MarginRightRadio = styled(Radio)`
  && {
    padding: 0px;
    margin-right: 10px;
  }
`;

const MarginRadioDiv = styled.div`
  && {
    margin: 0px 0px 10px 10px;
  }
`;

const JustifyEndContainer = styled.div`
  && {
    margin-top: 60px;
    display: flex;
    justify-content: flex-end;
  }
`;

const MarginFlexWrapper = styled(FlexWrapper)`
  && {
    margin: 5px 0px 15px 23px;
  }
`;

function CancelSubscription(props) {
  const { t } = useTranslation();

  const REASONS_LIST = [
    {
      id: '1',
      input: true,
      name: t('components:dialog.cancel-subscription.reason-1-name'),
      textPlaceholder: t('components:dialog.cancel-subscription.reason-1-placeholder'),
    },
    {
      id: '2',
      input: false,
      name: t('components:dialog.cancel-subscription.reason-2-name'),
    },
    {
      id: '3',
      input: true,
      name: t('components:dialog.cancel-subscription.reason-3-name'),
      textPlaceholder: t('components:dialog.cancel-subscription.reason-3-placeholder'),
    },
    {
      id: '4',
      input: false,
      name: t('components:dialog.cancel-subscription.reason-4-name'),
    },
    {
      id: '5',
      input: true,
      name: t('components:dialog.cancel-subscription.reason-5-name'),
      textPlaceholder: t('components:dialog.cancel-subscription.reason-5-placeholder'),
    },
  ];

  const { show, confirmCancelSubscription, closeCancelSubscription } = props;
  const [reasonId, setReasonId] = useState('1');
  const [reasonDescription, setReasonDescription] = useState('');

  function isButtonDisabled() {
    return REASONS_LIST.find((elem) => elem.id === reasonId).input ? !reasonDescription : !reasonId;
  }

  function onClose() {
    setReasonId('1');
    setReasonDescription('');
    closeCancelSubscription();
  }

  function onConfirm() {
    const customerReason = REASONS_LIST.find((reason) => reason.id === reasonId);
    const aboutReason = {
      detailedReason: customerReason?.input ? reasonDescription : null,
      mainReason: customerReason?.name,
    };
    confirmCancelSubscription(aboutReason);
  }

  function onReasonChange(event) {
    setReasonId(event.target.value);
  }

  function onReasonDescriptionChange(event) {
    setReasonDescription(event.target.value);
  }

  if (!show) {
    return null;
  }

  return (
    <Dialog fullWidth maxWidth="sm" open onClose={onClose}>
      <MarginFlexColumnContainer>
        <RightClose onClick={onClose} />
        <MarginBottomTitle noMargin weight="strong">
          {t('components:dialog.cancel-subscription.title')}
        </MarginBottomTitle>
        <NoPaddingParagraphText color="dark080" weight="medium">
          {t('components:dialog.cancel-subscription.description')}
        </NoPaddingParagraphText>
        <RadioGroup value={reasonId} onChange={onReasonChange}>
          {REASONS_LIST.map((reason) => {
            return (
              <MarginRadioDiv key={reason.id}>
                <FormControlLabel
                  control={<MarginRightRadio />}
                  label={reason.name}
                  value={reason.id}
                />
                {reasonId === reason.id && reason.input && (
                  <MarginFlexWrapper>
                    <Input
                      fullWidth
                      name="reason"
                      placeholder={reason.textPlaceholder}
                      required
                      onChange={onReasonDescriptionChange}
                    />
                  </MarginFlexWrapper>
                )}
              </MarginRadioDiv>
            );
          })}
        </RadioGroup>
        <JustifyEndContainer>
          <TextButton onClick={onClose}>
            {t('components:dialog.cancel-subscription.go-back')}
          </TextButton>
          <ContainedButton disabled={isButtonDisabled()} onClick={onConfirm}>
            {t('components:dialog.cancel-subscription.cancel-subscription')}
          </ContainedButton>
        </JustifyEndContainer>
      </MarginFlexColumnContainer>
    </Dialog>
  );
}

CancelSubscription.propTypes = {
  closeCancelSubscription: PropTypes.func.isRequired,
  confirmCancelSubscription: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default CancelSubscription;
