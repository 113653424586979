import React from 'react';

import SvgWrapper from './SvgWrapper';

function ExchangeVerticalIcon(props) {
  return (
    <SvgWrapper
      fill="none"
      height="17"
      viewBox="0 0 17 17"
      width="17"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        height="17"
        id="mask0_1_494"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="17"
        x="0"
        y="0"
      >
        <path
          d="M6 15.043V8H5V15.043L1.354 11.397L0.647003 12.104L5.5 16.957L10.354 12.103L9.647 11.396L6 15.043ZM5.488 15.531H5.511L5.5 15.543L5.488 15.531ZM15.646 5.604L12 1.957V9.053H11V1.957L7.354 5.604L6.646 4.896L11.5 0.0429993L16.354 4.897L15.646 5.604Z"
          fill="black"
        />
      </mask>
      <g mask="url(#mask0_1_494)">
        <rect fill="#252736" height="20" width="20" x="-2" y="-1" />
      </g>
    </SvgWrapper>
  );
}

export default ExchangeVerticalIcon;
