import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import InfoBadge from '@/components/Badge/InfoBadge';
import Ellipsis from '@/components/Text/Ellipsis';

const StyledAutoComplete = withStyles({
  option: {
    padding: 0,
  },
  root: {
    height: '56px',
  },
})(Autocomplete);

const CustomTextField = styled(({ pxSize, ...props }) => <TextField {...props} />)`
  && {
    width: ${(props) => props.pxSize}px;
    max-width: 700px;
    padding: 8px 18px 0 0px;
    & textarea {
      white-space: nowrap;
    }
  }
`;

const Label = styled.div`
  min-width: 0;
  padding: 0.8em 0 0.8em 0.9em;
  display: flex;
  justify-content: space-between;
  border-left: 3px solid;
  border-left-color: ${(props) =>
    props.selected ? `${props.theme.colors.secondary}` : 'transparent'};
  cursor: pointer;
  font-size: ${(props) => props.theme.text.sizes.small};
  color: ${(props) => props.theme.text.colors.black};
  flex: 1;
  align-items: center;
`;

export default function UrlSuggest(props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const loading = open && !props.options && !props.noRender;

  function getOptionLabel(option) {
    return option?.url;
  }

  function getOptionDisabled(option) {
    return !option?.inStock && !!option?.draft;
  }

  function onFocus(e) {
    // so the cursor is set at the end of the text
    const val = e.target.value;
    e.target.value = '';
    e.target.value = val;
  }

  function renderInput(params) {
    return (
      <CustomTextField
        {...params}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={props.autoFocus}
        error={props.isError}
        helperText={props.helperText}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <>
              {loading ? <CircularProgress color="inherit" size={20} /> : null}
              {params.InputProps.endAdornment}
            </>
          ),
          type: 'search',
        }}
        label={props.label}
        margin="dense"
        multiline
        placeholder={props.placeholder}
        pxSize={props.pxSize || 700}
        rowsMax={props.rowsMax || '1'}
        onFocus={onFocus}
        onKeyDown={!open ? props.onKeyDown : undefined}
      />
    );
  }

  function renderOption({ url, inStock, draft }, { selected, inputValue }) {
    return (
      <Label selected={selected}>
        <Ellipsis
          dangerouslySetInnerHTML={{
            __html: url.replace(inputValue, `<strong>${inputValue}</strong>`),
          }}
          title={url}
        />
        {!inStock && !!draft && (
          <InfoBadge>{t('components:autocomplete.url-suggest.already-in-planning')}</InfoBadge>
        )}
      </Label>
    );
  }

  function onChange(event, newValue, reason) {
    if (reason === 'select-option') {
      props.onChange?.(newValue.url);
    }
  }

  function onClose() {
    setOpen(false);
  }

  function onInputChange(event, newInputValue, reason) {
    if (reason === 'input' || reason === 'clear') {
      props.onChange?.(newInputValue);
    }
  }

  function onOpen() {
    setOpen(true);
  }

  return (
    <StyledAutoComplete
      {...props}
      disableClearable
      freeSolo
      getOptionDisabled={getOptionDisabled}
      getOptionLabel={getOptionLabel}
      inputValue={props.inputValue}
      loading={loading}
      noOptionsText={t('components:autocomplete.url-suggest.no-options-text')}
      open={open}
      options={props.options || []}
      renderInput={renderInput}
      renderOption={renderOption}
      value={props.value}
      onChange={onChange}
      onClose={onClose}
      onInputChange={onInputChange}
      onOpen={onOpen}
    />
  );
}
