import styled from 'styled-components/macro';

export const Container = styled.div`
  font-size: 13px;
  line-height: ${({ theme }) => theme.textCss.lineHeight};
  color: ${({ theme }) => theme.cssColors.dark100};
  opacity: ${({ isThreadResolved }) => (isThreadResolved ? 0.5 : 1)};
  white-space: pre-wrap;

  span[data-mentions='active'] {
    color: ${({ theme }) => theme.cssColors.secondaryBlue};
  }

  span[data-mentions='deleted'] {
    color: ${({ theme }) => theme.cssColors.dark040};
  }
`;
