import { useState } from 'react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { DialogModal } from 'semji-core/components/DialogModal';

import useDeleteContentById from '@/apis/semji/contents/useDeleteContentById';
import {
  SELECT_ALL,
  SELECT_ONCE,
} from '@/components/Pages/ContentIdeas/Listing/CellRender/Checkbox';
import { showSuccessSnackbar } from '@/store/actions/ui';

import { Listing } from './Listing';

function RemoveFromPlanningDialog({
  onClose,
  contentsToRemoveFromPlanning,
  handleRefetch,
  handleRefetchContent,
  handleRefetchPages,
}: {
  onClose: () => void;
  contentsToRemoveFromPlanning: any;
  handleRefetch: () => void;
  handleRefetchContent: () => void;
  handleRefetchPages: () => void;
}): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedContents, setSelectedContents] = useState([]);

  const { mutate, isLoading } = useDeleteContentById({
    onSuccess,
  });

  function onSuccess() {
    handleRefetchContent();
    handleRefetch();
    handleRefetchPages();
    dispatch(showSuccessSnackbar(t('remove-from-planning:removed-successfully')));
    onClose();
  }

  const isButtonDisabled =
    contentsToRemoveFromPlanning.length > 1 ? !selectedContents.length : false;

  function handleSelect(e) {
    const name = e.target.name;
    const isChecked = e.target.checked;
    const selectedId = e.target.value;

    if (name === SELECT_ALL) {
      setSelectedContents(
        isChecked ? contentsToRemoveFromPlanning.map((contentToRemove) => contentToRemove.id) : []
      );
    }
    if (name === SELECT_ONCE) {
      setSelectedContents(
        isChecked
          ? [...selectedContents, selectedId]
          : selectedContents.filter((id) => id !== selectedId)
      );
    }
  }

  function handleSelectInline(id) {
    const isChecked = !!selectedContents.find((contentId) => contentId === id);

    if (contentsToRemoveFromPlanning.length > 1) {
      setSelectedContents(
        isChecked
          ? selectedContents.filter((contentId) => contentId !== id)
          : [...selectedContents, id]
      );
    }
  }

  function handleConfirm() {
    if (contentsToRemoveFromPlanning.length > 1) {
      mutate(selectedContents);
    } else {
      mutate([contentsToRemoveFromPlanning[0].id]);
    }
  }

  return (
    <DialogModal
      cancelAction={onClose}
      cancelLabel={t('remove-from-planning:cancel')}
      canClickOutsideToClose
      closable
      confirmAction={handleConfirm}
      confirmLabel={
        isLoading
          ? t('remove-from-planning:removing')
          : t('remove-from-planning:remove-from-planning')
      }
      isCancelDisabled={isLoading}
      isConfirmDisabled={isButtonDisabled || isLoading}
      isOpen={true}
      title={
        <>
          <div className="remove-from-planning-dialog__title__cell">
            {t('remove-from-planning:title')}
          </div>
          <div className="remove-from-planning-dialog__title__cell_right">
            <Trans
              i18nKey={'remove-from-planning:explanation-text'}
              values={{
                count: contentsToRemoveFromPlanning.length,
                postProcess: 'interval',
              }}
            />
          </div>
        </>
      }
      onClose={onClose}
    >
      <Listing
        contentsToRemoveFromPlanning={contentsToRemoveFromPlanning}
        handleSelect={handleSelect}
        handleSelectInline={handleSelectInline}
        selectedContents={selectedContents}
      />
    </DialogModal>
  );
}

export default RemoveFromPlanningDialog;
