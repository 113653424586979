import './RestrictionModal.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavigationButton } from 'semji-core/components/Button';

import UnlockImage from '@/assets/images/unlock-image.svg';
import { SUPPORT_ADDRESS_MAIL } from '@/utils/constants';

export default function RestrictionModal() {
  const { t } = useTranslation();
  return (
    <div className="version-history-panel-restriction-modal">
      <img alt="" className="version-history-panel-restriction-modal__image" src={UnlockImage} />
      <div className="version-history-panel-restriction-modal__text">
        <div className="version-history-panel-restriction-modal__title">
          {t('content:version-history-panel.restriction-modal.title')}
        </div>
        <div className="version-history-panel-restriction-modal__description">
          {t('content:version-history-panel.restriction-modal.description')}
        </div>
      </div>
      <NavigationButton
        className="version-history-panel-restriction-modal__button"
        href={`mailto:${SUPPORT_ADDRESS_MAIL}`}
        rel="noreferrer"
        target="_blank"
      >
        {t('content:version-history-panel.restriction-modal.contact-us')}
      </NavigationButton>
    </div>
  );
}
