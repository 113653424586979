import i18next from 'i18next';
import React from 'react';
import styled from 'styled-components/macro';

import LockIcon from '@/components/icons/LockIcon';
import TooltipComponent from '@/components/Tooltip/Tooltip';

const BadgeWrapper = styled.div`
  background-color: ${(props) => props.theme.cssColors.salmonOrange020};
  color: ${(props) => props.theme.cssColors.salmonOrange};
  height: 18px;
  width: ${(props) => (props.$isCondensed ? '18px' : '80px')};
  min-width: ${(props) => (props.$isCondensed ? '18px' : '80px')};
  border: ${(props) => props.border};
  border-radius: ${(props) => (props.$isCondensed ? '50px' : '25px')};
  font-size: ${(props) => props.theme.textCss.sizes.xs};
  font-weight: ${({ theme }) => theme.textCss.weights.strong};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: help;
  padding: 3px 10px;
  box-sizing: border-box;
  gap: 5px;
`;

export const PlanRestrictionBadge = ({
  isCondensed,
  hasTooltip = true,
  tooltip = '',
  isAddOn = false,
  ...props
}) => {
  if (!hasTooltip) {
    return (
      <BadgeWrapper $isCondensed={isCondensed} {...props}>
        <LockIcon />
      </BadgeWrapper>
    );
  }

  const tooltipLabel =
    tooltip ||
    (isAddOn
      ? i18next.t('components:badge.plan.available-on-demand')
      : i18next.t('components:badge.plan.available-on-higher-plan'));

  return (
    <TooltipComponent title={tooltipLabel}>
      <BadgeWrapper $isCondensed={isCondensed} {...props}>
        <LockIcon />
      </BadgeWrapper>
    </TooltipComponent>
  );
};
