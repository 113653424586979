import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import apiClient from '@/apis/semji/apiClient';
import { showErrorSnackbar } from '@/store/actions/ui';

export default function useDeleteServiceIntegration(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { delete: deleteRequest } = apiClient;

  return useMutation({
    mutationFn: async (id) => {
      await deleteRequest(`/service_integrations/${id}`);
    },

    onError: () => {
      dispatch(showErrorSnackbar(t('common:error.default')));
    },
    ...props,
  });
}
