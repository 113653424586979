import styled from 'styled-components/macro';

import DoubleImportantFileIcon from '@/components/icons/DoubleImportantFileIcon';
import ImportantFileIcon from '@/components/icons/ImportantFileIcon';

const StyledImportantFileIcon = styled(ImportantFileIcon)`
  && {
    font-size: 17px;
    margin-left: 5px;
    cursor: pointer;
  }
`;
const StyledDoubleImportantFileIcon = styled(DoubleImportantFileIcon)`
  && {
    font-size: 17px;
    margin-left: 5px;
    cursor: pointer;
  }
`;

function NumberFile({ number }) {
  if (0 === number || isNaN(number)) {
    return <StyledImportantFileIcon />;
  }

  if (number === 1) {
    return <StyledImportantFileIcon number={number} />;
  }

  return <StyledDoubleImportantFileIcon number={number} />;
}

export default NumberFile;
