import { useQuery } from '@tanstack/react-query';

import apiClient from '@/apis/semji/apiClient';
import { SCOPE_CONFIGURATION } from '@/apis/semji/constants';

export default function useGetInfo(props: any) {
  const { get } = apiClient;

  return useQuery({
    queryFn: async ({ signal }: { signal: any }) =>
      get(`/info.json`, {
        signal,
      }),
    queryKey: [SCOPE_CONFIGURATION.GET_INFO],
    ...props,
  });
}
