import { createGlobalStyle } from 'styled-components/macro';

const GlobalStyle = createGlobalStyle`
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  /* font-size: 100%; */
  font-weight: inherit;
  vertical-align: baseline;
}
strong {
  font-weight: bold;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

:focus {
    outline: none;
}

html,
body,
#root {
  font-family: Rubik, sans-serif;
  height: 100%;
  overflow: hidden;
}

/* Scrollbars */
.scrollbarTrackVertical {
  right: 2px;
  bottom: 2px;
  top: 2px;
  border-radius: 6px;
  z-index: 1000;
}

.scrollbarTrackHorizontal {
  right: 2px;
  bottom: 2px;
  left: 2px;
  border-radius: 6px;
  z-index: 1000;
}

/* Customize website's scrollbar like Mac OS on chrome and chromium */

/* total width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background: transparent;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 5px;
}

@-moz-document url-prefix() {
  * {
    scrollbar-color: #cccccc transparent;
  }
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}
`;

export default GlobalStyle;
