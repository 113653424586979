import { useEffect, useState } from 'react';

import apiSettings from '@/apis/semji/settings';

// List of filters: https://easylist.to/easylist/easyprivacy.txt
function useCheckAdBlocker(filter = '/analytics/v3/management/accounts', onError) {
  const [isBlocked, setIsBlocked] = useState(false);

  useEffect(() => {
    fetch(`${apiSettings.rootUrl}/info.json?test=${filter}`).catch((e) => {
      onError?.();
      setIsBlocked(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return isBlocked;
}

export default useCheckAdBlocker;
