import { setupWorker } from 'msw';
import { useEffect } from 'react';

import useDebug from '@/hooks/useDebug';
import getProxyHandlers from '@/mocks/getProxyHandlers';
import handlers from '@/mocks/handlers';

let worker = null;

function useMock() {
  const isMockEnabled = useDebug('isMockEnabled');
  const mockProxyEnv = useDebug('mockProxyEnv');

  useEffect(() => {
    const isMocked = isMockEnabled || mockProxyEnv;
    const allHandlers = [
      ...(isMockEnabled ? handlers : []),
      ...(mockProxyEnv ? getProxyHandlers(mockProxyEnv) : []),
    ];

    if (isMocked && !worker) {
      worker = setupWorker();
      worker?.start?.({
        onUnhandledRequest: 'bypass',
      });
      worker?.use?.(...allHandlers);
      worker?.printHandlers?.();
    } else if (isMocked) {
      worker?.resetHandlers?.(...allHandlers);
      worker?.printHandlers?.();
    } else {
      worker?.stop?.();
      worker?.resetHandlers?.();
      worker = null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMockEnabled, mockProxyEnv]);
}

export default useMock;
