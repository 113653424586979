import styled, { keyframes } from 'styled-components/macro';

export const EditorPageContainerWrapper = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colors.lightGrey};

  /* Force dimensions to ensure it takes the whole viewport and doesn't overflow */
  height: 100%;
  overflow: hidden;
  flex: 1;
`;
export const FlexWrapper = styled.div`
  display: flex;
  flex: 1;
  position: relative;

  /* Force dimensions to ensure it takes the whole viewport and doesn't overflow */
  height: 100%;
  width: 100%;
`;
export const EditorWrapper = styled.div`
  flex: 1;
  background: white;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0;
`;
export const EditorBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 3.5em;
`;

export const OnlineContextWrapper = styled.div`
  padding: 2em 8%;
  max-width: 900px;
`;
export const EditorEmptyBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 0 50px 0 50px;
  text-align: center;
`;

const translationAnimationOpen = keyframes`
  0% { transform : translateX(100%) }
  100% { transform : translateX(0%) }
`;

export const SidePanelWrapper = styled.div`
  ${(props) => props.theme.mediaQueries.phone} {
    width: 100%;
  }
  ${(props) => props.theme.mediaQueries.tablet} {
    width: 100%;
  }
  ${(props) => props.theme.mediaQueries.desktop} {
    min-width: 525px;
    max-width: 525px;
    height: 100%;
  }
  ${(props) => props.theme.mediaQueries.largeDesktop} {
    height: 100%;
    min-width: 525px;
    max-width: 525px;
  }
  position: relative;
  z-index: 100;
  flex: 1;
  flex-direction: column;
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  animation-name: ${translationAnimationOpen};
  animation-duration: '400ms';
  box-shadow: 0px 3px 10px rgba(37, 39, 54, 0.1);
  background: white;
`;
export const SidePanelBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
export const TaskBarWrapper = styled.div`
  height: 60px;
  width: 100%;
  background: white;
  display: flex;
  align-items: center;
  border-bottom: 0.5px solid ${({ theme }) => theme.cssColors.dark020};
`;

export const TaskBarContentWrapper = styled.div`
  padding: 0.5rem 46px;
  padding-right: ${({ isSidePanelOpen }) => isSidePanelOpen && '15px'};
  width: 100%;
  display: flex;
  align-items: center;
`;

export const SelectorWrapper = styled.div`
  padding: 0 0 0 10px;
  width: ${({ width }) => width ?? '120px'};
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const VersionSelectorWrapper = styled.div`
  width: ${({ isSmall }) => (isSmall ? '20px' : '125px')};
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BackWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 0;
  align-items: center;
  && > a {
    color: ${(props) => props.theme.text.colors.white};
    font-size: 2em;
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
`;
export const BackTitleWrapper = styled.div`
  font-size: 18px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) => props.theme.text.colors.white};
  padding-bottom: 5px;
`;
export const BackPathWrapper = styled.a`
  font-size: 14px;
  color: ${(props) => props.theme.text.colors.white};
  opacity: 0.8;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  text-decoration: none;
`;
export const TitleWrapper = styled.div`
  text-align: center;
  font-size: 1.5em;
  font-weight: 500;
  color: ${(props) => props.theme.text.colors.default};
`;

export const FooterWrapper = styled.div`
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  ${({ theme }) => theme.mediaQueries.phone} {
    margin: 0px 40px 0px 10px;
    max-width: 100%;
    width: calc(100% - 50px);
  }
  ${({ theme }) => theme.mediaQueries.tablet} {
    margin: 0px 40px 0px 10px;
    width: calc(100% - 50px);
    max-width: 100%;
  }
  ${({ theme }) => theme.mediaQueries.desktop} {
    margin: 0px 30px;
    width: calc(100% - 60px);
    max-width: 1000px;
  }
  ${({ theme }) => theme.mediaQueries.largeDesktop} {
    margin: 0px 30px;
    width: calc(100% - 60px);
    max-width: 1200px;
  }
`;

export const EditorContentWrapper = styled.div`
  flex: 1;
  display: flex;
  position: relative;
  flex-direction: column;
  color: ${({ noShadow, theme }) => (noShadow ? theme.cssColors.dark100 : 'inherit')};
  background: #fff;
  box-shadow: ${({ noShadow }) => (noShadow ? 'none' : `0px 3px 10px rgba(37, 39, 54, 0.1)`)};
  padding-bottom: 30px;

  ${({ theme }) => theme.mediaQueries.phone} {
    margin: ${({ noShadow }) => (noShadow ? '0' : '10px 40px 0px 10px')};
    max-width: 100%;
    width: ${({ noShadow }) => (noShadow ? '100%' : `calc( 100% - 50px)`)};
  }
  ${({ theme }) => theme.mediaQueries.tablet} {
    margin: ${({ noShadow }) => (noShadow ? '0' : '10px 40px 0px 10px')};
    width: ${({ noShadow }) => (noShadow ? '100%' : `calc( 100% - 50px)`)};
    max-width: 100%;
  }
  ${({ theme }) => theme.mediaQueries.desktop} {
    margin: ${({ noShadow }) => (noShadow ? '0' : '30px 30px 0px 30px')};
    width: ${({ noShadow }) => (noShadow ? '100%' : `calc( 100% - 60px)`)};
    max-width: ${({ noShadow }) => (noShadow ? '100%' : '1000px')};
  }
  ${({ theme }) => theme.mediaQueries.largeDesktop} {
    margin: ${({ noShadow }) => (noShadow ? '0' : '30px 30px 0px 30px')};
    width: ${({ noShadow }) => (noShadow ? '100%' : `calc( 100% - 60px)`)};
    max-width: ${({ noShadow }) => (noShadow ? '100%' : '1200px')};
  }
`;
