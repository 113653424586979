import { nanoid } from 'nanoid';
import { useMemo } from 'react';

const DoubleImportantFileIcon = ({ number, ...props }) => {
  const svgId = useMemo(() => nanoid(), []);

  return (
    <svg fill="none" height="18" viewBox="0 0 14 18" width="14" {...props}>
      <path
        d="M8.9502 5.81714H11.1877V17.4524H0.44751V3.13208H8.50269V5.36963V5.81714H8.9502Z"
        stroke="#A8A9AF"
        strokeWidth="0.89502"
      />
      <line
        stroke="#A8A9AF"
        strokeWidth="0.89502"
        x1="11.3183"
        x2="8.63327"
        y1="5.68558"
        y2="3.00052"
      />
      <mask
        height="17"
        id={`mask0_${svgId}`}
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="12"
        x="2"
        y="0"
      >
        <path
          d="M13.1877 4.1901V15.7676H2.44751V0.947518L9.8196 0.947648L13.1877 4.1901Z"
          stroke="#A8A9AF"
          strokeWidth="0.89502"
        />
      </mask>
      <g mask={`url(#mask0_${svgId})`}>
        <path
          clipRule="evenodd"
          d="M15.4263 0.104492H0.210938V16.2148H0.316406V2H9.54718V4.82353H12.3164V16.2148H15.4263V0.104492Z"
          fill="#A8A9AF"
          fillRule="evenodd"
        />
      </g>
      <g>
        <text
          dominantBaseline="middle"
          fill="#A8A9AF"
          fontSize="0.6em"
          textAnchor="middle"
          x="40%"
          y="60%"
        >
          {number ?? '!'}
        </text>
      </g>
    </svg>
  );
};

export default DoubleImportantFileIcon;
