import React from 'react';

import SvgWrapper from './SvgWrapper';

const CardIcon = (props) => (
  <SvgWrapper viewBox="0 0 15 12" {...props}>
    <path d="M13.6 0H1.80005C1.10005 0 0.5 0.599988 0.5 1.29999V9.79999C0.5 10.5 1.10005 11.1 1.80005 11.1H13.6C14.3 11.1 14.9 10.5 14.9 9.79999V1.29999C14.9 0.499988 14.3 0 13.6 0ZM14 9.69998C14 9.89998 13.8 10.1 13.6 10.1H1.80005C1.60005 10.1 1.40002 9.89998 1.40002 9.69998V5H14.1V9.69998H14ZM1.30005 2.5V1.19998C1.30005 0.999982 1.50007 0.799988 1.70007 0.799988H13.5C13.7 0.799988 13.9 0.999982 13.9 1.19998V2.5H1.30005Z" />
  </SvgWrapper>
);

export default CardIcon;
