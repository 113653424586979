import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import CaretIcon from '@/components//icons/CaretIcon';
import { PlanRestrictionBadge } from '@/components/Badge/PlanRestrictionBadge';
import { MenuButton } from '@/components/Menu/MenuButton';
import MenuItem from '@/components/Menu/MenuItem';
import Selector, { SelectorBox } from '@/components/Selector';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import { OrganizationFeatureSet } from '@/hoc/OrganizationFeatureSet';

const StyledTooltipComponent = styled(TooltipComponent)`
  display: flex;
  align-items: center;
  min-width: 115px;
  justify-content: ${({ withNoPadding }) => (withNoPadding ? 'left' : 'flex-end')};
`;

export default function AccessSelect({
  currentAccess,
  disabled,
  roles,
  setUserRole,
  withNoPadding,
}) {
  const [currentRole, setCurrentRole] = useState(
    roles.find((role) => role.id === currentAccess.roles[0]) || {}
  );
  const { t } = useTranslation();

  const accessType = !!currentAccess.organization
    ? 'organization'
    : !!currentAccess.workspace
      ? 'workspace'
      : '';

  return (
    <Selector
      button={({ openMenu }) => (
        <StyledTooltipComponent
          title={
            disabled
              ? t('components:settings.users-settings.cannot-change-own-rights')
              : t(currentRole.description)
          }
          withNoPadding={withNoPadding}
        >
          <MenuButton
            darkColor
            disabled={disabled}
            padding={withNoPadding && '0px'}
            onClick={openMenu}
          >
            {t(currentRole.label)}&nbsp;
            <CaretIcon />
          </MenuButton>
        </StyledTooltipComponent>
      )}
    >
      {(localProps) => (
        <SelectorBox {...localProps}>
          {roles.map((role) => (
            <OrganizationFeatureSet
              key={role.id}
              feature={`user-role:${accessType}:has-access-to-${role.key}`}
              no={() => (
                <MenuItem
                  badge={<PlanRestrictionBadge isCondensed />}
                  description={t(role.description)}
                  isDisabled
                  value={t(role.label)}
                />
              )}
              yes={() => (
                <MenuItem
                  description={t(role.description)}
                  selected={currentRole.id === role.id}
                  value={t(role.label)}
                  onClick={() => {
                    if (!disabled && currentRole.id !== role.id) {
                      setUserRole(currentAccess.id, role.id);
                      setCurrentRole(role);
                      localProps.onClose();
                    }
                  }}
                />
              )}
            />
          ))}
        </SelectorBox>
      )}
    </Selector>
  );
}
