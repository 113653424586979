import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { FlexVerticalAlignedContainer } from '@/components/_common';
import { PlanRestrictionBadge } from '@/components/Badge/PlanRestrictionBadge';
import { TextButton } from '@/components/Button/Button';
import UserIcon from '@/components/icons/UserIcon';
import InputSmartList from '@/components/Input/InputSmartList';
import ErrorAvailableSeatsLeftMessage from '@/components/Message/ErrorAvailableSeatsLeftMessage';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import { selectOrganizationMaxAllowedUsers } from '@/store/selectors/selectOrganizationMaxAllowedUsers';
import { isValidEmail } from '@/utils/email';

const InviteIllustration = React.lazy(
  () =>
    import(/* webpackChunkName: "InviteIllustration" */ '@/components/DynamicIllustration/Invite')
); // Lazy-loaded

const UnfilledUserIcon = styled(UserIcon)`
  && {
    fill: none;
    stroke: ${(props) => props.theme.colors.secondary};
    margin-right: 1rem;
    margin-top: 0.7rem;
  }
`;

// Lazy load this component because it's a heavy svg with too many  base-64 images
const StyledInviteIllustration = styled((props) => (
  <Suspense fallback={<></>}>
    <InviteIllustration {...props} />
  </Suspense>
))`
  position: fixed;
  bottom: 0;
  right: 0;
  max-width: 50%;
  // resize on medium screen
  ${(props) => props.theme.mediaQueries.desktop} {
    max-width: 100%;
    transform: scale(0.9);
    transform-origin: right bottom;
  }
  // hide illustration for small screens
  ${(props) => props.theme.mediaQueries.phone} {
    display: none;
  }
  ${(props) => props.theme.mediaQueries.tablet} {
    display: none;
  }
`;

function fillArray(list) {
  const defaultArraySize = 7;
  const arrayToFill = new Array(defaultArraySize).fill('');

  return arrayToFill.map((elem, index) => list[index] ?? '');
}

function InviteWorkspace(props) {
  const { t } = useTranslation();
  const maxAllowedUsers = useSelector(selectOrganizationMaxAllowedUsers);
  const { isFeatureEnabled } = useOrganizationFeatureSet('users:invitations:has-unlimited-amount');
  const isLimitAchieved = !isFeatureEnabled && props.users.length >= maxAllowedUsers;

  function helperText(email) {
    if (isValidEmail(email) || (email || '').length === 0) {
      return;
    }
    return 'Email is not valid';
  }

  function isOnError(email) {
    return !!email?.length && !isValidEmail(email);
  }

  function addInput(extraProps) {
    return (
      <FlexVerticalAlignedContainer>
        <TextButton
          color="primary"
          disabled={isLimitAchieved}
          padding="0 0.25rem"
          weight="medium"
          {...extraProps}
        >
          {t('create-workspace:invite-users.add-user')}
        </TextButton>
        {isLimitAchieved && <PlanRestrictionBadge />}
      </FlexVerticalAlignedContainer>
    );
  }

  return (
    <>
      <InputSmartList
        AddInput={addInput}
        getList={props.onChangeUsers}
        helperText={helperText}
        InputProps={{
          startAdornment: <UnfilledUserIcon />,
        }}
        inputs={props.users}
        isError={isOnError}
        isLimitAchieved={isLimitAchieved}
        limitAchievedMessage={
          <ErrorAvailableSeatsLeftMessage
            organizationId={props.organizationId}
            seatsWillBeConsumed={props.seatsWillBeConsumed}
          />
        }
        placeholder={t('create-workspace:invite-users.input-placeholder')}
      />
      <StyledInviteIllustration users={fillArray(props.users)} />
    </>
  );
}

export default InviteWorkspace;
