import './index.scss';

import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { FlexGrowContainer } from '@/components/_common';
import OutlinedLockIcon from '@/components/icons/OutlinedLockIcon';
import OutlinedUserIcon from '@/components/icons/OutlinedUserIcon';
import { SettingsSidePanelCategoryTitle } from '@/components/Layout/Header';
import { GRADIENT_LOGO_AND_PROFILE_MENU } from '@/components/Layout/Layout';
import { Label, Tab, VerticalTabs } from '@/components/Navigation/Tabs';
import { PageDataLayout } from '@/components/PageDataLayout';
import { SidebarToggle } from '@/components/Sidebar';
import LayoutWrapper from '@/containers/Layout/Layout';

import PersonalInfo from './PersonalInfo';
import SecurityContainer from './Security';

const TABS_LIST = {
  'user-profile': {
    featureFlagKey: 'profile',
    icon: <OutlinedUserIcon />,
    value: 'user-profile',
  },
  'user-security': {
    featureFlagKey: 'profile-security',
    icon: <OutlinedLockIcon />,
    value: 'user-security',
  },
};

function ProfileSettings() {
  const { t } = useTranslation();
  const { organizationId, tab } = useParams();

  const renderTab = () => {
    const title = t(`settings:tabs.${TABS_LIST[tab]?.value}`);

    switch (tab) {
      case 'user-profile':
        return <PersonalInfo title={title} />;
      case 'user-security':
        return <SecurityContainer title={title} />;
      default:
        return null;
    }
  };

  return (
    <LayoutWrapper flex={1} menu={GRADIENT_LOGO_AND_PROFILE_MENU}>
      <PageDataLayout
        sideBar={{
          content: (
            <div className="profile-settings-index__tabs">
              <SettingsSidePanelCategoryTitle>
                {t('settings:category.profile')}
              </SettingsSidePanelCategoryTitle>
              <VerticalTabs value={tab}>
                {Object.values(TABS_LIST).map((tabElement) => (
                  <Tab
                    key={tabElement.value}
                    component={Link}
                    data-intercom-target={`profile_settings-${tabElement.value}`}
                    id={tabElement.value}
                    label={
                      <Label
                        align="flex-start"
                        label={t(`settings:tabs.${tabElement.value}`)}
                        startAdornmentIcon={tabElement.icon}
                      />
                    }
                    labelLeft
                    orientation="vertical"
                    to={
                      organizationId
                        ? `/o/${organizationId}/settings/${tabElement.value}`
                        : `/settings/${tabElement.value}`
                    }
                    value={tabElement.value}
                  />
                ))}
              </VerticalTabs>
            </div>
          ),
          titleContent: <span>{t(`settings:category.settings`)}</span>,
        }}
      >
        <FlexGrowContainer>
          <SidebarToggle className="pl-8 pt-8" />
          {renderTab()}
        </FlexGrowContainer>
      </PageDataLayout>
    </LayoutWrapper>
  );
}

export default ProfileSettings;
