export const CONTENT_STATUS = {
  NEW_CONTENT: 'new-content',
  UPDATED_CONTENT: 'updated-content',
};

export const ADD_CONTENT_VERSION = {
  ADD_TO_PLANNING_VERSION: 'ADD_TO_PLANNING_VERSION',
  START_OPTIMIZING_VERSION: 'START_OPTIMIZING_VERSION',
};

export const ADD_TO_PLANNING_MODAL_ID = 'add-to-planning-modal';

export interface ContentToAddToPlanning {
  draftTitle: string;
  draftUrl: string;
  id: string;
  keyword: string;
  recommendedUrl: string | null;
}
