import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { IconButton } from '@/components/Button/Button';
import AnimatedReloadIcon from '@/components/icons/AnimatedReloadIcon';
import TooltipComponent from '@/components/Tooltip/Tooltip';

const Tooltip = styled(TooltipComponent)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.cssColors.dark080};
  font-size: ${({ theme }) => theme.text.sizes.default};
`;

export default function UpdateButton({ isLoading, onClick }) {
  const { t } = useTranslation();

  return (
    <Tooltip title={t('components:content.update-button.tooltip-text')}>
      <IconButton disabled={isLoading} mode="inverted" onClick={onClick}>
        <AnimatedReloadIcon $isLoading={isLoading} viewBox="0 0 17 17" />
        &nbsp;{t('components:content.update-button.text')}
      </IconButton>
    </Tooltip>
  );
}
