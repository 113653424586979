import capitalize from 'lodash/capitalize';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Avatar } from 'semji-core/components/Avatar';
import styled from 'styled-components/macro';

import { ColoredDot } from '@/components/_common';
import Card from '@/components/Card/Card';
import DatePicker from '@/components/DatePicker';
import NoDraft, { NO_DRAFT_DASHBOARD } from '@/components/EmptyState/NoDraft';
import AvatarIcon from '@/components/icons/Avatar';
import Loader from '@/components/Loader/Loader';
import ContentScoreCell from '@/components/Table/Cell/ContentScoreCell';
import NotAcknowledgedCell from '@/components/Table/Cell/NotAcknowledgedCell';
import TitleWithKeywordCell from '@/components/Table/Cell/TitleWithKeywordCell';
import Table from '@/components/Table/Table';
import Tooltip from '@/components/Tooltip/Tooltip';
import useNullUser from '@/hooks/useNullUser';
import Workspace from '@/services/Workspace';
import { showErrorSnackbar } from '@/store/actions/ui';
import { SECTIONS } from '@/utils/log/constants';
import { Log } from '@/utils/log/Log';

const TooltipComponent = styled(Tooltip)`
  &&& ${AvatarIcon} {
    border-style: solid;
  }
`;

function DashboardPlanningContainer() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const nullUser = useNullUser();
  const { organizationId, workspaceId } = useParams();

  const [drafts, setDrafts] = useState([]);
  const [loading, setLoading] = useState(true);

  const apiRootUrl = import.meta.env.VITE_REACT_APP_SEMJI_API_ROOT_URL;

  const columns = [
    {
      cellDataGetter: ({ rowData }) => rowData.contentStatus,
      cellRenderer: ({ cellData }) => (
        <TooltipComponent title={`Status: ${capitalize(cellData.label)}`}>
          <ColoredDot color={cellData.color} />
        </TooltipComponent>
      ),
      dataKey: 'status',
      label: ' ',
      sortable: false,
      width: 30,
    },
    {
      cellDataGetter: ({ rowData }) => ({
        contentId: rowData.id,
        focusKeyword: rowData.pageFocusTopKeyword?.keyword || null,
        new: rowData.page.new,
        pageId: rowData.page.id,
        searchVolume: rowData.pageFocusTopKeyword?.searchVolume || null,
        title: rowData.title,
        url: rowData.page.url,
        workspaceId: rowData.workspace.id,
      }),
      cellRenderer: ({ cellData }) => (
        <TitleWithKeywordCell
          contentId={cellData.contentId}
          focusKeyword={cellData.focusKeyword}
          isNewContent={cellData.new}
          isUpdatedContent={!cellData.new}
          organizationId={organizationId}
          pageId={cellData.pageId}
          searchVolume={cellData.searchVolume}
          title={cellData.title}
          url={cellData.url}
          workspaceId={cellData.workspaceId}
        />
      ),
      dataKey: 'title',
      flexGrow: 1,
      label: t('dashboard:dashboard-planning.contents'),
      sortable: false,
      width: 100,
    },
    {
      align: 'center',
      cellDataGetter: ({ rowData }) => rowData.contentScore,
      cellRenderer: ({ cellData }) =>
        cellData !== null ? (
          <ContentScoreCell contentScore={cellData} />
        ) : (
          <NotAcknowledgedCell align="center" />
        ),
      dataKey: 'contentScore',
      label: t('dashboard:dashboard-planning.content-score'),
      sortable: false,
      width: 100,
    },
    {
      align: 'center',
      cellDataGetter: ({ rowData }) => ({
        contentId: rowData.id,
        dueDate: rowData.dueDate,
        pageId: rowData.page.id,
        readOnly: rowData.type !== 'DRAFT',
      }),
      cellRenderer: ({ cellData }) => (
        <DatePicker
          key={`${cellData.pageId}_due_date`}
          disabled
          dueDate={cellData.dueDate}
          handleChange={() => {}}
          shortVersion
        />
      ),
      dataKey: 'dueDate',
      label: t('dashboard:dashboard-planning.due-date'),
      sortable: false,
      width: 70,
    },
    {
      align: 'center',
      cellDataGetter: ({ rowData }) => rowData.assignedTo || nullUser,
      cellRenderer: ({ cellData }) => (
        <TooltipComponent title={`${cellData.firstName} ${cellData.lastName}`}>
          <Avatar
            apiRootUrl={apiRootUrl}
            email={cellData.email}
            firstName={cellData.firstName}
            id={cellData.id}
            lastName={cellData.lastName}
            profileImageHash={cellData.profileImageHash}
            size="small"
            translations={{ unassigned: t('common:user-picker.unassigned') }}
            uploadedAvatarUrl={cellData.uploadedAvatarUrl}
          />
        </TooltipComponent>
      ),
      dataKey: 'assignedTo',
      label: t('dashboard:dashboard-planning.assignation'),
      sortable: false,
      width: 80,
    },
  ];

  async function getData() {
    const _WorkspaceService = new Workspace(workspaceId);
    try {
      const drafts = (await _WorkspaceService.drafts) || [];

      setDrafts(drafts.slice(0, 5));
      setLoading(false);
    } catch (error) {
      Log.report({
        context: 'getData',
        error,
        extra: 'get Data from dashboard',
        section: SECTIONS.dashboard.key,
      });
      dispatch(showErrorSnackbar(t('common:error.default')));
    }
  }

  useEffect(() => {
    getData();
  }, [workspaceId]);

  return (
    <Card
      contentMinHeight="380px"
      contentSize="big"
      displayFooter={drafts.length > 0 && !loading}
      footerLabel={t('dashboard:dashboard-planning.view-planning')}
      footerLink={`/o/${organizationId}/w/${workspaceId}/planning`}
      height="470px"
      title={t('dashboard:dashboard-planning.planning')}
    >
      {drafts.length === 0 && !loading ? (
        <NoDraft version={NO_DRAFT_DASHBOARD} />
      ) : loading ? (
        <Loader />
      ) : (
        <Table columns={columns} headerHeight={55} mode="transparent" rows={drafts} width="100%" />
      )}
    </Card>
  );
}

export default DashboardPlanningContainer;
