import {
  RESET_CONTENT,
  SET_CONTENT,
  SET_CONTENT_HTML,
  SET_CONTENT_METADESCRIPTION,
  SET_CONTENT_TITLE,
} from './actionTypes';

export const setContent =
  ({ html, title, metaDescription }) =>
  (dispatch) => {
    dispatch({
      html,
      metaDescription,
      title,
      type: SET_CONTENT,
    });
  };

export const setContentHtml = (html) => (dispatch) => {
  dispatch({
    html,
    type: SET_CONTENT_HTML,
  });
};

export const setContentTitle = (title) => (dispatch) => {
  dispatch({
    title,
    type: SET_CONTENT_TITLE,
  });
};

export const setContentMetaDescription = (metaDescription) => (dispatch) => {
  dispatch({
    metaDescription,
    type: SET_CONTENT_METADESCRIPTION,
  });
};

export const resetContent = () => (dispatch) => {
  dispatch({
    type: RESET_CONTENT,
  });
};
