/* eslint-disable react/jsx-no-bind */
import { useQueryClient } from '@tanstack/react-query';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useMatch,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { LOCATION, SCOPE_SERVICE_INTEGRATIONS } from '@/apis/semji/constants';
import useGetServiceIntegrations from '@/apis/semji/serviceIntegrations/useGetServiceIntegrations';
import { DiffContent } from '@/components/Editor/DiffContent/DiffContent';
import { DEFAULT_BACKGROUND, NO_MENU_LAYOUT } from '@/components/Layout/Layout';
import { LayoutLoader } from '@/components/Loader/Loader';
import { BrandVoiceListing } from '@/containers/BrandVoice';
import {
  DeprecatedBrandVoiceCreate,
  DeprecatedBrandVoiceEdit,
  DeprecatedBrandVoiceLayout,
  DeprecatedBrandVoiceListing,
} from '@/containers/BrandVoiceDeprecated';
import {
  CompetitorsConfiguration,
  CompetitorsDataExplorer,
  CompetitorsDuel,
  CompetitorsReport,
} from '@/containers/Competitors';
import CompetitorLayout from '@/containers/Competitors/layouts/CompetitorsLayout/CompetitorsLayout';
import SearchIntelligenceReportLayout from '@/containers/Competitors/layouts/SearchIntelligenceReportLayout/SearchIntelligenceReportLayout';
import { CompetitorsLoader } from '@/containers/Competitors/Loader';
import ContentContainer from '@/containers/Content/ContentContainer';
import ContentContainerProvider from '@/containers/Content/ContentContainerContext';
import DefaultContentContainer from '@/containers/Content/DefaultContentContainer';
import ContentIdeasListingContainer from '@/containers/ContentIdeas/Listing';
import ContentIdeasMenu from '@/containers/ContentIdeas/Menu/index';
import { ContentVersionEmpty } from '@/containers/ContentVersion/EmptyVersion';
import ContentVersionLayout from '@/containers/ContentVersion/layouts/ContentVersionLayout';
import { ContentVersionEditor } from '@/containers/ContentVersion/VersionEditor';
import CreateDrafts from '@/containers/Drafts/CreateDrafts';
import { KnowledgeBaseListing } from '@/containers/KnowledgeBase';
import LayoutWrapper from '@/containers/Layout/Layout';
import PagesList from '@/containers/Listing/PagesList';
import QuickStartGuide from '@/containers/Onboarding/QuickStartGuide';
import PagesImport from '@/containers/Pages';
import Planning from '@/containers/Planning';
import { ReportDashboard, ReportLayout, ReportMetric } from '@/containers/Report';
import ServiceIntegration from '@/containers/ServiceIntegration';
import Settings from '@/containers/Settings';
import { hubRoutes } from '@/containers/Workspace/constants';
import Can from '@/hoc/Can';
import useOrganizationFeatureSet, {
  AI_WRITING__BRAND_VOICE__IS_ENABLED,
  AI_WRITING__DEPRECATED_BRAND_VOICE_CONFIGURATOR__IS_ENABLED,
  AI_WRITING__KNOWLEDGE_BASE__IS_ENABLED,
} from '@/hooks/useOrganizationFeatureSet';
import useUpdateWorkspaceRouteHistory from '@/hooks/useUpdateWorkspaceRouteHistory';
import { init as intercomInit } from '@/services/Intercom';
import { showUpgradePlanModal } from '@/store/actions/billing';
import { changeDefaultHub } from '@/store/actions/hub';
import {
  changeDefaultWorkspaceId,
  fetchWorkspace,
  removeDefaultWorkspaceId,
} from '@/store/actions/workspace';
import useParamSelector from '@/store/hooks/useParamSelector';
import { selectCurrentHub } from '@/store/selectors/selectCurrentHub';
import { selectCurrentOrganization } from '@/store/selectors/selectCurrentOrganization';
import { selectCurrentWorkspace } from '@/store/selectors/selectCurrentWorkspace';
import { selectHasAccessToCompetitorsAdmin } from '@/store/selectors/selectHasAccessToCompetitorsAdmin';
import { selectWorkspaceIsOnboarding } from '@/store/selectors/selectWorkspaceIsOnboarding';
import { SemjiHub } from '@/types/common.types';
import { UserModel as User } from '@/types/user/user.types';
import { isDashboardRouteStillAccessible, isRouteAccessGranted } from '@/utils/billing';
import { SECTIONS } from '@/utils/log/constants';
import { Log } from '@/utils/log/Log';

const InQueue = lazy(() => import(/* webpackChunkName: "InQueue" */ '@/components/Pages/InQueue'));

const Dashboard = lazy(
  () => import(/* webpackChunkName: "Dashboard" */ '@/containers/Dashboard/Dashboard')
);

function Workspace() {
  const { t } = useTranslation();
  const { organizationId, workspaceId } = useParams();
  const dispatch = useDispatch();
  const user: User = useSelector((state: any) => state.user);
  const currentWorkspace = useSelector(selectCurrentWorkspace);
  const currentOrganization = useSelector(selectCurrentOrganization);
  const advancedMode = useSelector((state: any) => state.uiMode) === 'advanced' && user.superAdmin;
  const currentHub = useSelector(selectCurrentHub);
  const currentWorkspaceIsOnboarding = useParamSelector(
    selectWorkspaceIsOnboarding,
    organizationId,
    workspaceId
  );
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isInRouteInQueue = Boolean(useMatch(`${pathname}/in_queue`)?.isExact);
  const [isReady, setIsReady] = useState(false);
  const queryClient = useQueryClient();

  const getServiceIntegrations = useGetServiceIntegrations();

  const isCompetitorAdmin = useSelector(selectHasAccessToCompetitorsAdmin);

  const { isFeatureEnabled: hasAccessToDeprecatedBrandVoice } = useOrganizationFeatureSet(
    AI_WRITING__DEPRECATED_BRAND_VOICE_CONFIGURATOR__IS_ENABLED
  );
  const { isFeatureEnabled: hasAccessToBrandVoice } = useOrganizationFeatureSet(
    AI_WRITING__BRAND_VOICE__IS_ENABLED
  );
  const { isFeatureEnabled: hasAccessToKnowledgeBase } = useOrganizationFeatureSet(
    AI_WRITING__KNOWLEDGE_BASE__IS_ENABLED
  );

  useUpdateWorkspaceRouteHistory();

  const getWorkspaceRoles = () => {
    return user?.workspaceAccesses?.filter(
      (workspaceAccess) => workspaceAccess.workspace.id === workspaceId
    )[0]?.roles;
  };

  const retrieveWorkspace = async () => {
    try {
      await dispatch(fetchWorkspace(workspaceId));
      await queryClient.refetchQueries({
        active: true,
        exact: true,
        fetching: false,
        queryKey: [SCOPE_SERVICE_INTEGRATIONS.GET_SERVICE_INTEGRATIONS, workspaceId, LOCATION.ROOT],
      });

      setIsReady(true);

      dispatch(changeDefaultWorkspaceId(workspaceId));
      intercomInit(
        user,
        {},
        {
          workspaceRoles: getWorkspaceRoles(),
        }
      );
    } catch (error: any) {
      if (error.status >= 400 && error.status < 500) {
        dispatch(removeDefaultWorkspaceId());
        // TODO
        navigate('/');
      }

      Log.report({
        context: 'componentDidMount',
        error,
        extra: 'Fetch workspace data',
        section: SECTIONS.workspace.key,
      });
    }
  };

  useEffect(() => {
    retrieveWorkspace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId]);

  const getLayoutMenu = (path = '') => {
    if (
      path.includes('/p/') ||
      path.includes('/import-pages') ||
      path.includes('/create-drafts') ||
      path.includes('/in_queue') ||
      path.includes('/settings')
    ) {
      return NO_MENU_LAYOUT;
    }

    return null;
  };

  const getPageTitle = () => {
    if (currentWorkspace?.name) {
      return t('workspace:workspace.title-workspace', {
        currentWorkspaceName: currentWorkspace?.name,
      });
    }

    return t('workspace:workspace.title-semji');
  };

  const deployUpgradeModalOnNotAllowedRedirect = () => {
    // each time we are redirected here (after  generally a link click)
    // we will display the upgrade modal
    // this is the best way to handle it globally and not on each link which is just too much
    // this is displayed only if the Route access is not granted
    // which means only for users with a trial expired or a subscription expired
    if (!isRouteAccessGranted(currentOrganization)) {
      dispatch(showUpgradePlanModal());
    }
  };

  useEffect(() => {
    //check if the page asked is available in the hub
    const route = hubRoutes.find((route) => pathname.match(route.pathRex));
    if (!route?.hubs.includes(currentHub)) {
      //if not, we change the hub to the first available
      dispatch(changeDefaultHub(route?.hubs[0] || SemjiHub.CONTENT));
    }
    return () => {
      getServiceIntegrations.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isReady) {
    return (
      <LayoutWrapper menu={NO_MENU_LAYOUT} variant={DEFAULT_BACKGROUND}>
        <LayoutLoader />
      </LayoutWrapper>
    );
  }

  if (currentWorkspace?.isInQueue && !isInRouteInQueue && !advancedMode) {
    return navigate(`${pathname}/in_queue`, { replace: true });
  }

  if (currentWorkspace?.isInQueue && isInRouteInQueue && advancedMode) {
    return navigate(`${pathname}/dashboard`, { replace: true });
  }

  const defaultFallbackUrl = `/o/${organizationId}/w/${workspaceId}/${currentHub === SemjiHub.EXECUTIVE ? 'search-intelligence' : 'dashboard'}`;

  function ContentHub() {
    return (
      <>
        <Route
          element={
            <Can
              no={() => {
                if (isRouteAccessGranted(currentOrganization)) {
                  return <Navigate to={`/o/${organizationId}/w/${workspaceId}/planning`} />;
                }
                return <Navigate to={`/o/${organizationId}/workspace-access-blocked`} />;
              }}
              perform="dashboard-page:visit"
              yes={() => {
                if (isDashboardRouteStillAccessible(currentOrganization)) {
                  return (
                    <Suspense fallback={<></>}>
                      <Dashboard />
                    </Suspense>
                  );
                }
                return <Navigate to={`/o/${organizationId}/workspace-access-blocked`} />;
              }}
            />
          }
          path={`/dashboard`}
        />
        <Route
          element={
            <Can
              data={{
                accessGranted: isRouteAccessGranted(currentOrganization),
              }}
              no={() => {
                deployUpgradeModalOnNotAllowedRedirect();
                return <Navigate to={`/o/${organizationId}/w/${workspaceId}/dashboard`} />;
              }}
              perform="content-ideas:visit"
              yes={() => <ContentIdeasListingContainer />}
            />
          }
          path={`/content-ideas/:contentIdeaSearchId`}
        />
        <Route
          element={
            <Can
              data={{
                accessGranted: isRouteAccessGranted(currentOrganization),
              }}
              no={() => {
                deployUpgradeModalOnNotAllowedRedirect();
                return <Navigate to={`/o/${organizationId}/w/${workspaceId}/dashboard`} />;
              }}
              perform="content-ideas:visit"
              yes={() => <ContentIdeasMenu />}
            />
          }
          path={`/content-ideas`}
        />
        <Route
          element={
            <Can
              data={{
                accessGranted: isRouteAccessGranted(currentOrganization),
              }}
              no={() => {
                deployUpgradeModalOnNotAllowedRedirect();
                return <Navigate to={`/o/${organizationId}/w/${workspaceId}/dashboard`} />;
              }}
              perform="onboarding-workspace:visit"
              yes={() => <QuickStartGuide />}
            />
          }
          path={`/onboarding`}
        />
        <Route
          element={
            <Can
              data={{
                accessGranted: isRouteAccessGranted(currentOrganization),
              }}
              no={() => {
                deployUpgradeModalOnNotAllowedRedirect();
                return <Navigate to={`/o/${organizationId}/w/${workspaceId}/dashboard`} />;
              }}
              perform="online-contents-page:visit"
              yes={() => <PagesList />}
            />
          }
          path={`/contents`}
        />
        <Route
          element={
            <Can
              data={{
                accessGranted: isRouteAccessGranted(currentOrganization),
              }}
              no={() => {
                deployUpgradeModalOnNotAllowedRedirect();
                return <Navigate to={`/o/${organizationId}/w/${workspaceId}/dashboard`} />;
              }}
              perform="online-contents-page:visit"
              yes={() => <PagesImport />}
            />
          }
          path={`/contents/import-pages/*`}
        />
        <Route
          element={
            <Can
              data={{
                accessGranted: isRouteAccessGranted(currentOrganization),
              }}
              no={() => {
                deployUpgradeModalOnNotAllowedRedirect();
                return <Navigate to={`/o/${organizationId}/w/${workspaceId}/dashboard`} />;
              }}
              perform="planning-page:visit"
              yes={() => <Planning />}
            />
          }
          path={`/planning`}
        />
        <Route
          element={
            <Can
              data={{
                accessGranted: isRouteAccessGranted(currentOrganization),
              }}
              no={() => {
                deployUpgradeModalOnNotAllowedRedirect();
                return <Navigate to={`/o/${organizationId}/w/${workspaceId}/dashboard`} />;
              }}
              perform="planning-page:visit"
              yes={() => <CreateDrafts />}
            />
          }
          path={`/planning/create-drafts/*`}
        />
        <Route
          element={
            <Can
              data={{
                accessGranted: isRouteAccessGranted(currentOrganization),
              }}
              no={() => {
                deployUpgradeModalOnNotAllowedRedirect();
                return <Navigate to={`/o/${organizationId}/w/${workspaceId}/dashboard`} />;
              }}
              perform="editor-page:visit"
              yes={() => <DefaultContentContainer />}
            />
          }
          path={`/p/:pageId`}
        />
        <Route
          element={
            <Can
              data={{
                accessGranted: isRouteAccessGranted(currentOrganization),
              }}
              no={() => {
                deployUpgradeModalOnNotAllowedRedirect();
                return <Navigate to={`/o/${organizationId}/w/${workspaceId}/dashboard`} />;
              }}
              perform="editor-page:visit"
              yes={() => (
                <ContentContainerProvider>
                  <ContentContainer isNew />
                </ContentContainerProvider>
              )}
            />
          }
          path={`/p/:pageId/create`}
        />
        <Route
          element={
            <Can
              data={{
                accessGranted: isRouteAccessGranted(currentOrganization),
              }}
              no={() => {
                deployUpgradeModalOnNotAllowedRedirect();
                return <Navigate to={`/o/${organizationId}/w/${workspaceId}/dashboard`} />;
              }}
              perform="editor-page:visit"
              yes={() => (
                <ContentContainerProvider>
                  <ContentContainer isContent />
                </ContentContainerProvider>
              )}
            />
          }
          path={`/p/:pageId/content/:contentId/*`}
        />
        <Route element={<ContentVersionLayout />} path={`/p/:pageId/versions/`}>
          <Route element={<ContentVersionEmpty />} path="" />
          <Route element={<ContentVersionEditor />} path=":contentId/*" />
          <Route
            element={<ContentVersionEditor />}
            path=":contentId/content-version/:versionId/*"
          />
        </Route>
        <Route element={<DiffContent />} path={`/p/:pageId/content/:contentId/compare`} />
        <Route
          element={
            <Can
              data={{
                accessGranted: isRouteAccessGranted(currentOrganization),
              }}
              no={() => (
                <ContentContainerProvider>
                  <ContentContainer isBrief isContentBriefEnabled={false} />
                </ContentContainerProvider>
              )}
              perform="content-brief:visit"
              yes={() => (
                <ContentContainerProvider>
                  <ContentContainer isBrief isContentBriefEnabled />
                </ContentContainerProvider>
              )}
            />
          }
          path={`/p/:pageId/brief/*`}
        />
        <Route
          element={
            <Can
              data={{
                accessGranted: isRouteAccessGranted(currentOrganization),
              }}
              no={() => {
                deployUpgradeModalOnNotAllowedRedirect();
                return <Navigate to={`/o/${organizationId}/w/${workspaceId}/dashboard`} />;
              }}
              perform="editor-page:visit"
              yes={() => (
                <ContentContainerProvider>
                  <ContentContainer />
                </ContentContainerProvider>
              )}
            />
          }
          path={`/p/:pageId/online/*`}
        />
        <Route
          element={
            <Can
              data={{
                accessGranted: isRouteAccessGranted(currentOrganization),
              }}
              no={() => {
                deployUpgradeModalOnNotAllowedRedirect();
                return <Navigate to={`/o/${organizationId}/w/${workspaceId}/dashboard`} />;
              }}
              perform="editor-page:visit"
              yes={() => (
                <ContentContainerProvider>
                  <ContentContainer />
                </ContentContainerProvider>
              )}
            />
          }
          path={`/p/:pageId/text/*`}
        />

        <Route
          element={
            <Can
              data={{
                accessGranted: isRouteAccessGranted(currentOrganization),
              }}
              no={() => {
                deployUpgradeModalOnNotAllowedRedirect();
                return <Navigate to={`/o/${organizationId}/w/${workspaceId}/dashboard`} />;
              }}
              perform="reports-page:visit"
              yes={() => <ReportLayout />}
            />
          }
          path="/reports"
        >
          <Route element={<ReportDashboard />} index path="all/overview" />
          <Route element={<ReportDashboard />} path=":reportView/overview" />
          <Route element={<ReportMetric />} path=":reportView/:metricKey" />
          <Route element={<Navigate to="all/overview" />} path="" />
        </Route>

        <Route
          element={
            <Can
              data={{
                accessGranted: hasAccessToDeprecatedBrandVoice,
              }}
              no={() => {
                return <Navigate to={`/o/${organizationId}/w/${workspaceId}/dashboard`} />;
              }}
              perform="brand-voice-page:visit"
              yes={() => <DeprecatedBrandVoiceLayout />}
            />
          }
          path="/brand-voice"
        >
          <Route element={<DeprecatedBrandVoiceListing />} path="" />
          <Route element={<DeprecatedBrandVoiceCreate />} path="create" />
          <Route element={<DeprecatedBrandVoiceEdit />} path=":brandVoiceId" />
        </Route>
        <Route
          element={
            <Can
              data={{
                accessGranted: hasAccessToBrandVoice,
              }}
              no={() => {
                return <Navigate to={`/o/${organizationId}/w/${workspaceId}/dashboard`} />;
              }}
              perform="brand-voice-page:visit"
              yes={() => <BrandVoiceListing />}
            />
          }
          path="ai-content/brand-voice"
        />
        <Route
          element={
            <Can
              data={{
                accessGranted: hasAccessToKnowledgeBase,
              }}
              no={() => {
                return <Navigate to={`/o/${organizationId}/w/${workspaceId}/dashboard`} />;
              }}
              perform="knowledge-base-page:visit"
              yes={() => <KnowledgeBaseListing />}
            />
          }
          path="ai-content/knowledge-base"
        />
        <Route
          element={
            <Navigate
              to={`/o/${organizationId}/w/${workspaceId}/${
                currentWorkspaceIsOnboarding ? 'onboarding' : 'dashboard'
              }`}
            />
          }
          path="*"
        />
      </>
    );
  }
  function ExecutiveHub() {
    return (
      <>
        <Route
          element={
            isCompetitorAdmin ? (
              <CompetitorsConfiguration />
            ) : (
              <Navigate to={`/o/${organizationId}/w/${workspaceId}/search-intelligence`} />
            )
          }
          path={`/search-intelligence/configuration`}
        />
        <Route element={<SearchIntelligenceReportLayout />} path="/search-intelligence">
          <Route element={<CompetitorsLoader />} path=":reportId/analyzing" />
          <Route element={<CompetitorLayout />} path=":reportId">
            <Route element={<CompetitorsReport />} path="reports/" />
            <Route element={<CompetitorsDuel />} path="contents-duel/" />
            <Route element={<CompetitorsDuel />} path="contents-duel/:websiteId/" />
            <Route element={<CompetitorsDataExplorer />} path="data-explorer" />
            <Route element={<Navigate to="reports" />} path="" />
          </Route>
        </Route>
        <Route
          element={<Navigate to={`/o/${organizationId}/w/${workspaceId}/search-intelligence`} />}
          path="*"
        />
      </>
    );
  }

  return (
    <Can
      no={() => {
        dispatch(removeDefaultWorkspaceId());
        return <Navigate to="/" />;
      }}
      perform="current-workspace:visit"
      yes={() => (
        <LayoutWrapper menu={getLayoutMenu(pathname)}>
          <Helmet>
            <title>{getPageTitle()}</title>
          </Helmet>
          <Routes>
            {/* TO FIX */}
            <Route
              element={() => {
                if (currentWorkspace?.isInQueue || (!currentWorkspace?.isInQueue && advancedMode)) {
                  return (
                    <Suspense fallback={<></>}>
                      <InQueue />
                    </Suspense>
                  );
                }
                return <Navigate to={defaultFallbackUrl} />;
              }}
              hubs={[SemjiHub.CONTENT]}
              path={`/in_queue`}
            />

            <Route
              element={
                <Can
                  data={{
                    accessGranted: isRouteAccessGranted(currentOrganization),
                  }}
                  no={() => {
                    deployUpgradeModalOnNotAllowedRedirect();
                    return <Navigate to={defaultFallbackUrl} />;
                  }}
                  perform="workspace-member-settings:visit"
                  yes={() => <ServiceIntegration />}
                />
              }
              path={`/settings/integrations/:serviceIntegrationType`}
            />
            <Route element={<Settings />} path={`/settings/:tab/*`} />
            <Route
              element={
                <Navigate to={`/o/${organizationId}/w/${workspaceId}/settings/user-profile`} />
              }
              path={`/settings`}
            />
            {currentHub === SemjiHub.CONTENT ? ContentHub() : ExecutiveHub()}
          </Routes>
        </LayoutWrapper>
      )}
    />
  );
}

export default Workspace;
