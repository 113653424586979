import styled from 'styled-components/macro';

import { BlockText } from '../../../../../components/Text/Inline';

const SubTitle = styled(BlockText)`
  padding: ${({ padding }) => padding || '0 0 0.5rem 0'};
  white-space: normal;
`;

export default SubTitle;
