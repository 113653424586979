import styled from 'styled-components/macro';

import Column from '@/containers/Content/BriefComponents/Table/Columns/Column';

const MetadataColumn = styled(Column)`
  &&&  {
    display: flex;
    justify-content: space-around;
    width: 50px;
    font-size: ${({ theme }) => theme.textCss.sizes.sm};
    color: ${({ theme }) => theme.cssColors.dark040};
  }
`;

export default MetadataColumn;
