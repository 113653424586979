import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Box from '@/design-system/components/Box';

const Flex = styled(Box)`
  align-items: ${({ alignItems }) => alignItems};
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  gap: ${({ gap }) => gap};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-self: ${({ alignSelf }) => alignSelf};
`;

Flex.propTypes = {
  alignItems: PropTypes.string,
  flexDirection: PropTypes.string,
  flexWrap: PropTypes.string,
  gap: PropTypes.string,
  justifyContent: PropTypes.string,
};

export default Flex;
