import React, { MutableRefObject } from 'react';

import { LastGenerationRef } from '@/containers/Content/TinyMceComponents/Editor/hooks/useAiWriting/useAiWriting.types';
import { FactCheckHookResults } from '@/containers/Content/TinyMceComponents/Editor/hooks/useAiWriting/useFactCheck/useFactCheck.types';
import { FlagContentHookResults } from '@/containers/Content/TinyMceComponents/Editor/hooks/useAiWriting/useFlagContent/useFlagContent.types';
import { BrandVoiceModel } from '@/types/brandVoice/brandVoice.types';
import { Fact } from '@/types/fact/fact.types';

export interface UseStreamContentParameters {
  editorRef: any;
  setRefetchAiUnit: React.Dispatch<React.SetStateAction<number>>;
  setShowLimitAiWriting: any;
  inputTitleRef: MutableRefObject<HTMLInputElement>;
  aiWritingCreditsEnabled: boolean;
  textareaMetaDescriptionRef: MutableRefObject<HTMLTextAreaElement>;
  hasUnlimitedAIWriting: boolean;
  lastGeneration: MutableRefObject<LastGenerationRef>;
  focusTopKeywordRef: MutableRefObject<any>;
  currentBrandVoice: BrandVoiceModel | null;
  disableAutoScrollRef: any;
  factCheckHook: FactCheckHookResults;
  setEditorAiWritingPopperOpen: any;
  setShowWorkspaceUnitLimitationModal: React.Dispatch<React.SetStateAction<boolean>>;
  triggerSaveRef: any;
  remainingAiWritingUnit: number;
  flagContentHook: FlagContentHookResults;
}

export interface GeneratedContent {
  cancelled: boolean;
  selected?: boolean;
  html?: string;
  factCheckLoading?: boolean;
  facts?: Fact[];
  reported: boolean;
  streamId?: string;
}

export interface AbortRef {
  abortMutation?: () => void;
  aborted: boolean;
  isTyping: boolean;
}

export interface AbortFactCheckRef {
  abortMutation?: () => void;
  aborted: boolean;
}

export const STREAM_TYPE_INLINE = 'inline';
export const STREAM_TYPE_STREAM = 'stream';

export enum TitleModelStreamType {
  D2 = STREAM_TYPE_INLINE,
  D3 = STREAM_TYPE_STREAM,
}
