import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { ColoredDot } from '@/components/_common';
import ContentType from '@/components/_common/ContentType';
import { Cell } from '@/components/Pages/ContentIdeas/common';
import { NEW_CONTENT } from '@/components/Pages/ContentIdeas/constant';
import TooltipComponent from '@/components/Tooltip/Tooltip';

const TitleWrapper = styled.div`
  display: flex;
  flex: 1;
  margin-left: 15px;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
`;

function TitleStatefull({ contentStatus, type, title }) {
  const { t } = useTranslation();

  return (
    <TitleWrapper>
      <TooltipComponent title={contentStatus.label}>
        <ColoredDot color={contentStatus.color} style={{ cursor: 'help' }} />
      </TooltipComponent>
      <TooltipComponent
        title={
          type === NEW_CONTENT
            ? t('content-ideas:listing.new-content')
            : t('content-ideas:listing.updated-content')
        }
      >
        <ContentType type={type} />
      </TooltipComponent>
      <Cell noPadding size="sm" title={title || t('common:labels.unknown-title')} weight="medium">
        {title || t('common:labels.unknown-title')}
      </Cell>
    </TitleWrapper>
  );
}

export default TitleStatefull;
