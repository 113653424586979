import React from 'react';

import SvgWrapper from '@/components/icons/SvgWrapper';

const TriangleArrow = (props) => {
  return (
    <SvgWrapper
      {...props}
      fill="none"
      height="11"
      viewBox="0 0 9 11"
      width="9"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.95068 10.071L4.55078 0.270996L0.0507812 10.071H8.95068Z" fill="currentColor" />
    </SvgWrapper>
  );
};

export default TriangleArrow;
