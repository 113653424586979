import {
  HIDE_MODAL_CANCEL_PLAN,
  HIDE_MODAL_UPGRADE_PLAN,
  SHOW_MODAL_CANCEL_PLAN,
  SHOW_MODAL_UPGRADE_PLAN,
} from './actionTypes';

export const showUpgradePlanModal = () => ({
  type: SHOW_MODAL_UPGRADE_PLAN,
});

export const hideUpgradePlanModal = () => ({
  type: HIDE_MODAL_UPGRADE_PLAN,
});

export const showCancelPlanModal = () => ({
  type: SHOW_MODAL_CANCEL_PLAN,
});

export const hideCancelPlanModal = () => ({
  type: HIDE_MODAL_CANCEL_PLAN,
});
