import { Competitor } from '@/containers/Competitors/Configuration';
import { CompetitorSuggestion, UrlType } from '@/types/competitors';

export type OptionType = {
  title: string;
  subTitle: string;
  value: UrlType;
};

export enum ENUM_COMPETITOR_CHANGE_KEY {
  Url = 'url',
  UrlType = 'urlType',
}

export interface ListingProps {
  competitors: Competitor[];
  competitorSuggestions: CompetitorSuggestion[];
  handleRemoveElement: (index: number) => void;
  handleChangeElement: (
    key: ENUM_COMPETITOR_CHANGE_KEY,
    validate?: boolean
  ) => (value: string, index: number) => void;
  handleBlurElement?: (index: number) => void;
  shouldFocusIndexZero?: boolean;
  isCalculatingPagesNumber?: boolean;
}
