import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import NumberFile from '@/components/NumberFile';
import { EllipsisCell } from '@/components/Pages/ContentIdeas/common';
import TooltipComponent from '@/components/Tooltip/Tooltip';

function Keyword({ keywordLabel, keyword, associatedKeyword }) {
  const { t } = useTranslation();
  const { workspaceId, organizationId } = useParams();

  function getTitleAndHref() {
    const number = associatedKeyword.length;
    const appBaseUrl = import.meta.env.VITE_REACT_APP_SEMJI_APP_FRONTEND_URL;
    if (0 === number) {
      return { href: null, title: null };
    }

    if (number > 1) {
      return {
        href: `${appBaseUrl}/o/${organizationId}/w/${workspaceId}/contents?tab=all&operator=AND&filter=%5B%7B%22uid%22%3A%22keyword%22%2C%22comparison%22%3A%22eq%22%2C%22value%22%3A%22${keyword}%22%7D%5D`,
        title: t('content-ideas:listing.table.multiple-associated-keyword', { number }),
      };
    }

    return {
      href: `${appBaseUrl}/o/${organizationId}/w/${workspaceId}/p/${associatedKeyword[0].id}/online`,
      title: t('content-ideas:listing.table.simple-associated-keyword', {
        title: associatedKeyword[0].title || t('common:labels.unknown-title'),
      }),
    };
  }

  function onClick(e) {
    e.stopPropagation();
  }

  const { title, href } = getTitleAndHref();

  return (
    <>
      <EllipsisCell
        color="dark060"
        dangerouslySetInnerHTML={{ __html: keywordLabel }}
        noPadding
        size="sm"
        title={keyword}
        weight="medium"
        onClick={onClick}
      />
      {associatedKeyword.length > 0 && (
        <a href={href} rel="noopener noreferrer" target="_blank" onClick={onClick}>
          <TooltipComponent title={title}>
            <NumberFile number={associatedKeyword.length} />
          </TooltipComponent>
        </a>
      )}
    </>
  );
}

export default Keyword;
