import './PortalFactCheckCard.scss';

import React from 'react';
import { Popper } from 'semji-core/components/Popper';
import { Placements } from 'semji-core/hooks/usePopover';

import { ID_CONTENT_CONTAINER } from '@/containers/Content/TinyMceComponents/Editor/constants';

import { PortalFactCheckCardProps } from './PortalFactCheckCard.types';

function PortalFactCheckCard({
  factCheckPopperCardHook,
}: PortalFactCheckCardProps): React.JSX.Element {
  const [factCheckPopperCard, setFactCheckPopperCardHook] = factCheckPopperCardHook;

  function handleOnMouseEnter(event: React.MouseEvent<HTMLElement>) {
    setFactCheckPopperCardHook((prevState) => ({
      ...prevState,
      cardHovered: true,
    }));
  }

  function handleOnMouseLeave(event: React.MouseEvent<HTMLElement>) {
    setFactCheckPopperCardHook((prevState) => ({
      ...prevState,
      cardHovered: false,
    }));
  }

  return (
    <Popper
      anchorEl={factCheckPopperCard.popperContext?.nearestElement}
      container={document.getElementById(ID_CONTENT_CONTAINER)}
      delay={250}
      modifiers={[
        {
          name: 'flip',
          options: {
            fallbackPlacements: [Placements.TopEnd],
          },
        },
      ]}
      open={true}
      placement={factCheckPopperCard.placement}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      <div className="portal-fact-check-card-content">{factCheckPopperCard.content}</div>
    </Popper>
  );
}

export default PortalFactCheckCard;
