import i18next from 'i18next';
import React from 'react';

import {
  ENUM_FILTER_TYPE,
  MultiFilterProps,
  MultipleFilterType,
} from '@/components/DynamicFilters';
import CupAltIcon from '@/components/icons/CupAltIcon';
import { CompetitorLabel } from '@/containers/Competitors/components/CompetitorLabel';
import { COMPETITOR_FILTER_QUERY_PARAM } from '@/containers/Competitors/utils/constants';
import { getHostnamefromUrl } from '@/containers/Competitors/utils/utils';
import { CompetitorWebsite } from '@/types/competitors';

export function getCompetitorFilter({
  updateFilter,
  filter,
  competitorWebsites,
}: MultiFilterProps & { competitorWebsites: CompetitorWebsite[] }): MultipleFilterType {
  return {
    icon: <CupAltIcon />,
    key: COMPETITOR_FILTER_QUERY_PARAM,
    name: i18next.t('competitors:filters.competitor'),
    onValidate: (value) => updateFilter(COMPETITOR_FILTER_QUERY_PARAM, value),
    options: competitorWebsites.map((competitorWebsite) => ({
      label: (
        <CompetitorLabel
          competitorId={competitorWebsite.id}
          competitorWebsites={competitorWebsites}
        />
      ),
      name: getHostnamefromUrl(competitorWebsite.url),
      selected: filter.includes(competitorWebsite.id),
      value: competitorWebsite.id,
    })),
    type: ENUM_FILTER_TYPE.MULTI_SELECT,
  };
}
