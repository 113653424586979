import './FolderFiltersPanel.scss';

import { Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { LimitModal } from 'semji-core/components/LimitModal';

import useDeleteFolder from '@/apis/semji/folders/useDeleteFolder';
import useGetCurrentWorkspaceAllFolders from '@/apis/semji/folders/useGetCurrentWorkspaceAllFolders';
import usePostFolder from '@/apis/semji/folders/usePostFolder';
import usePutFolder from '@/apis/semji/folders/usePutFolder';
import UnlockImage from '@/assets/images/unlock-image.svg';
import useOpenedFoldersPersistedState from '@/components/Filters/FolderFiltersPanel/useOpenedFoldersPersistedState';
import CaretIcon from '@/components/icons/CaretIcon';
import FolderIcon from '@/components/icons/FolderIcon';
import PlusIcon from '@/components/icons/PlusIcon';
import { getDefaultFolderGeneral } from '@/components/Select/FolderSelect/constants';
import Rotate from '@/design-system/components/Button/Rotate';
import useCan from '@/hooks/useCan';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import { fetchOrganization } from '@/store/actionsCreator/organization';
import { getOrganizationById } from '@/store/reducers';

import CreateFolderDialog from './dialogs/CreateFolderDialog';
import DeleteFolderDialog from './dialogs/DeleteFolderDialog';
import EditFolderDialog from './dialogs/EditFolderDialog';
import {
  Header,
  Item,
  ItemAction,
  ItemContent,
  ItemNumber,
  Items,
  StyledDeleteIcon,
  StyledPencilIcon,
  Title,
  Tooltip,
} from './FolderFiltersPanel.styled';

const DIALOG_STATE = {
  CREATE: 'CREATE',
  DELETE: 'DELETE',
  EDIT: 'EDIT',
};

function FolderItem({
  count,
  id,
  isActive,
  isEditable,
  isParent,
  name,
  onParentOpen,
  onDelete,
  onEdit,
  onSelect,
  isChildren,
  isParentOpen,
  canCRUDFolder,
}) {
  const { t } = useTranslation();
  const indentation = isChildren ? 2 : isParent ? 0 : 1;
  return (
    <Item
      key={`${id}-${name}`}
      indentation={indentation}
      isActive={isActive}
      isEditable={isEditable}
      onClick={onSelect}
    >
      {isParent && (
        <Rotate degree="-90" hasRotated={!isParentOpen} onClick={onParentOpen}>
          <CaretIcon />
        </Rotate>
      )}
      <FolderIcon opacity={0.6} />
      <ItemContent title={name}>
        <span>{name}</span>
      </ItemContent>
      <ItemNumber>{count}</ItemNumber>
      <ItemAction>
        <Tooltip
          title={
            canCRUDFolder
              ? t('components:filters.folder-filters-panel.label-edit')
              : t('components:filters.folder-filters-panel.label-can-not-edit')
          }
        >
          <StyledPencilIcon disabled={!canCRUDFolder} onClick={canCRUDFolder && onEdit} />
        </Tooltip>
        <Tooltip
          title={
            canCRUDFolder
              ? t('components:filters.folder-filters-panel.label-delete')
              : t('components:filters.folder-filters-panel.label-can-not-delete')
          }
        >
          <StyledDeleteIcon disabled={!canCRUDFolder} onClick={canCRUDFolder && onDelete} />
        </Tooltip>
      </ItemAction>
    </Item>
  );
}

function FolderFiltersPanel({ count, selectedFilter, onClick }) {
  const { t } = useTranslation();
  const { organizationId } = useParams();
  const dispatch = useDispatch();
  const DEFAULT_FOLDER_GENERAL = getDefaultFolderGeneral();
  const { data: folders = [] } = useGetCurrentWorkspaceAllFolders();
  const [openedFoldersState, setOpenedFoldersState] = useOpenedFoldersPersistedState();

  const currentOrganization = useSelector((state) => getOrganizationById(state, organizationId));
  const canCRUDFolder = useCan({ perform: 'create-or-update-folder:exec' });

  const [dialogState, setDialogState] = useState(null);
  const [folderUsedInDialog, setFolderUsedInDialog] = useState(null);
  // TODO: need refactoring
  // Currently used to hold UI loading state while organization data is fetcher after creation or deletion of folders
  const [isFetchingOrga, setIsFetchingOrga] = useState(false);

  const { isFeatureEnabled: unlimitedFoldersFeatureSet } = useOrganizationFeatureSet(
    'organization:folders:has-unlimited-amount'
  );
  const { data: foldersMaxAmount = 0 } = useOrganizationFeatureSet('organization:folders:amount');
  const hasReachedFoldersLimit =
    currentOrganization.foldersCount >= foldersMaxAmount && !unlimitedFoldersFeatureSet;

  const { childrenFoldersByParentId, parentFolders } = useMemo(() => {
    const { childrenFoldersByParentId, parentFolders } = Object.values(folders).reduce(
      (acc, folder) => {
        if (!!folder.parentFolderId) {
          acc.childrenFoldersByParentId[folder.parentFolderId] = [
            ...(acc.childrenFoldersByParentId[folder.parentFolderId] || []),
            folder,
          ];
        } else {
          acc.parentFolders.push(folder);
        }

        return acc;
      },
      { childrenFoldersByParentId: {}, parentFolders: [] }
    );

    return { childrenFoldersByParentId, parentFolders };
  }, [folders]);

  const createNewFolder = usePostFolder({
    onSuccess: async () => {
      setIsFetchingOrga(true);
      await dispatch(fetchOrganization(organizationId));
      setIsFetchingOrga(false);
      handleCloseDialog();
    },
  });

  const updateFolder = usePutFolder({
    onSuccess: handleCloseDialog,
  });

  const deleteFolder = useDeleteFolder({
    onSuccess: async () => {
      setIsFetchingOrga(true);
      await dispatch(fetchOrganization(organizationId));
      setIsFetchingOrga(false);
      handleCloseDialog();
    },
  });

  function isDefaultFolder(id) {
    return id === DEFAULT_FOLDER_GENERAL.id;
  }

  function isActive(id) {
    return selectedFilter === `folder-${id}`;
  }

  function onItemClick(id) {
    return (event) => {
      onClick?.({ tab: `folder-${id}` });
      event.stopPropagation();
    };
  }

  function onActionClick(folder, action) {
    return (event) => {
      event.stopPropagation();

      setFolderUsedInDialog(folder);
      setDialogState(action);
    };
  }

  function handleCloseDialog() {
    setDialogState(null);
    setFolderUsedInDialog(null);
  }

  function handleCreateFolder({ name, parentFolderId }) {
    createNewFolder.mutate({ name, parentFolderId });
  }

  function handleEditFolder({ id, name, parentFolderId }) {
    updateFolder.mutate({ id, name, parentFolderId });
  }

  function handleDeleteFolder() {
    deleteFolder.mutate({ id: folderUsedInDialog?.id });
  }

  function onParentOpen(id) {
    return (ev) => {
      setOpenedFoldersState((prevState) => {
        if (prevState.includes(id)) {
          return [...prevState.filter((i) => i !== id)];
        } else {
          return [...new Set([...prevState, id])];
        }
      });
      ev.stopPropagation();
    };
  }

  const selectedParentFolderId = useMemo(() => {
    const selectedFolderId = selectedFilter.split('-')[1];
    let parentFolder = parentFolders.find((item) => item.id === selectedFolderId);
    if (!parentFolder) {
      const childrenFolders = Object.values(childrenFoldersByParentId).reduce(
        (acc, folder) => acc.concat(folder),
        []
      );
      const selectedFolder = childrenFolders.find((item) => item.id === selectedFolderId);
      parentFolder = parentFolders.find((item) => item.id === selectedFolder?.parentFolderId);
    }
    return parentFolder?.id;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilter, childrenFoldersByParentId, parentFolders]);

  return (
    <>
      <div className="folder-filter-panel">
        <Header>
          <Title>{t('components:filters.folder-filters-panel.title')}</Title>
          <Tooltip
            placement="top"
            title={
              canCRUDFolder
                ? t('components:filters.folder-filters-panel.tooltip-create-folder')
                : t('components:filters.folder-filters-panel.tooltip-can-not-create-folder')
            }
          >
            <PlusIcon
              borderRadius="4px"
              cursor="pointer"
              disabled={!canCRUDFolder}
              hoverBackgroundColor="dark005"
              hoverColor="dark080"
              padding="4px"
              onClick={canCRUDFolder && onActionClick(null, DIALOG_STATE.CREATE)}
            />
          </Tooltip>
        </Header>
        <div className="folder-filter-panel__content">
          {[DEFAULT_FOLDER_GENERAL, ...parentFolders].map(({ name = '', id, parentFolderId }) => {
            const hasChildren = childrenFoldersByParentId[id];
            const isFolderOpened = openedFoldersState.includes(id) && hasChildren;
            return (
              <Fragment key={`${id}-${name}`}>
                <FolderItem
                  key={`${id}-${name}`}
                  canCRUDFolder={canCRUDFolder}
                  count={count[id] || ''}
                  id={id}
                  isActive={isActive(id)}
                  isEditable={!isDefaultFolder(id)}
                  isParent={hasChildren}
                  isParentOpen={isFolderOpened}
                  name={name}
                  parentFolderId={parentFolderId}
                  onDelete={onActionClick({ id, name, parentFolderId }, DIALOG_STATE.DELETE)}
                  onEdit={onActionClick({ id, name, parentFolderId }, DIALOG_STATE.EDIT)}
                  onParentOpen={onParentOpen(id)}
                  onSelect={onItemClick(id)}
                />
                {isFolderOpened && (
                  <Items isOpen={isFolderOpened}>
                    {childrenFoldersByParentId[id].map(({ name = '', id, parentFolderId }) => {
                      return (
                        <FolderItem
                          key={`${id}-${name}`}
                          canCRUDFolder={canCRUDFolder}
                          count={count[id] || ''}
                          id={id}
                          isActive={isActive(id)}
                          isChildren
                          isEditable={!isDefaultFolder(id)}
                          name={name}
                          onDelete={onActionClick(
                            { id, name, parentFolderId },
                            DIALOG_STATE.DELETE
                          )}
                          onEdit={onActionClick({ id, name, parentFolderId }, DIALOG_STATE.EDIT)}
                          onSelect={onItemClick(id)}
                        />
                      );
                    })}
                  </Items>
                )}
              </Fragment>
            );
          })}
        </div>
      </div>

      {dialogState === DIALOG_STATE.CREATE &&
        canCRUDFolder &&
        (!hasReachedFoldersLimit ? (
          <CreateFolderDialog
            defaultSelectedParentFolderId={selectedParentFolderId}
            isLoading={createNewFolder.isLoading || isFetchingOrga}
            parentFoldersOptions={parentFolders}
            onCancel={handleCloseDialog}
            onConfirm={handleCreateFolder}
          />
        ) : (
          <LimitModal
            buttonLabel={t('components:dialog.dialog-information-template.button-text')}
            description={t('components:filters.folder-filters-panel.limitation-dialog.description')}
            illustration={<img alt="" src={UnlockImage} />}
            isOpen
            title={t('components:filters.folder-filters-panel.limitation-dialog.title', {
              count: foldersMaxAmount,
            })}
            onClose={handleCloseDialog}
          />
        ))}

      {dialogState === DIALOG_STATE.EDIT && canCRUDFolder && (
        <EditFolderDialog
          currentId={folderUsedInDialog?.id}
          currentName={folderUsedInDialog?.name}
          currentParentFolderId={folderUsedInDialog?.parentFolderId}
          folders={folders}
          isLoading={updateFolder.isLoading}
          onCancel={handleCloseDialog}
          onConfirm={handleEditFolder}
        />
      )}

      {dialogState === DIALOG_STATE.DELETE && canCRUDFolder && (
        <DeleteFolderDialog
          folderDraftsCount={count[folderUsedInDialog?.id] || 0}
          folderName={folderUsedInDialog?.name}
          isLoading={deleteFolder.isLoading || isFetchingOrga}
          onCancel={handleCloseDialog}
          onConfirm={handleDeleteFolder}
        />
      )}
    </>
  );
}

export default FolderFiltersPanel;
