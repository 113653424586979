import PropTypes from 'prop-types';
import { memo, useMemo } from 'react';
import { withTheme } from 'styled-components/macro';

import { ReportMetricKey } from '@/containers/Report/utils/types';

import HighChartsSparkline from '../../components/Chart/HighChartsSparkline';
import { forgeSparklinesMetricsSeries } from '../../utils/charts/forgeMetricsSeries';
import {
  METRICS_CONFIG_PERIODICITY_DAILY,
  METRICS_CONFIG_PERIODICITY_MONTHLY,
  METRICS_CONFIG_PERIODICITY_QUARTERLY,
  METRICS_CONFIG_PERIODICITY_WEEKLY,
} from '../../utils/metrics/constants';

function MetricsSparkline({
  metrics = [],
  periodicity = METRICS_CONFIG_PERIODICITY_DAILY,
  selectedMetric,
  movingAverageWindow,
}) {
  const metricsSeries = useMemo(
    () =>
      forgeSparklinesMetricsSeries({
        keepNullValues: selectedMetric.key === ReportMetricKey.AverageContentScore,
        metrics,
        selectedMetric,
      }),
    [metrics, selectedMetric]
  );

  return (
    <HighChartsSparkline
      metricKey={selectedMetric.key}
      metricsSeries={metricsSeries}
      movingAverageWindow={movingAverageWindow}
      periodicity={periodicity}
    />
  );
}

MetricsSparkline.propTypes = {
  metrics: PropTypes.arrayOf(PropTypes.object),
  movingAverageWindow: PropTypes.number.isRequired,
  periodicity: PropTypes.oneOf([
    METRICS_CONFIG_PERIODICITY_DAILY,
    METRICS_CONFIG_PERIODICITY_WEEKLY,
    METRICS_CONFIG_PERIODICITY_MONTHLY,
    METRICS_CONFIG_PERIODICITY_QUARTERLY,
  ]).isRequired,
  selectedMetric: PropTypes.object.isRequired,
};

export default withTheme(memo(MetricsSparkline));
