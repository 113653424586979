import styled from 'styled-components/macro';

export const BrightText = styled.span`
  font-size: ${(props) =>
    props.size === 'small'
      ? props.theme.text.sizes.small
      : props.size === 'micro'
        ? '0.7em'
        : 'inherit'};
  font-weight: ${(props) => (props.bold ? 700 : props.initial ? 300 : 'inherit')};
  color: ${(props) => props.theme.text.colors.bright};
  text-transform: ${(props) => (props.capitalize ? 'capitalize' : 'none')};
`;
export const LightText = styled.span`
  font-size: ${(props) =>
    props.size === 'small'
      ? props.theme.text.sizes.small
      : props.size === 'micro'
        ? '0.7em'
        : 'inherit'};
  font-weight: ${(props) => (props.bold ? 700 : props.initial ? 300 : 'inherit')};
  color: ${(props) => props.theme.textCss.colors.grey};
`;
export const ExtraSmallGreyText = styled.span`
  font-size: ${(props) => props.theme.textCss.sizes.sm};
  color: ${(props) => props.theme.textCss.colors.grey};
`;
export const DefaultLightText = styled.span`
  font-size: ${(props) => props.theme.textCss.sizes.default};
  color: ${(props) => props.theme.textCss.colors.shinyGrey};
`;
export const ExtraSmallLightText = styled.span`
  font-size: ${(props) => props.theme.textCss.sizes.sm};
  color: ${(props) => props.theme.textCss.colors.shinyGrey};
`;
export const DefaultBrightText = styled.span`
  font-size: ${(props) => props.theme.textCss.sizes.default};
  color: ${(props) => props.theme.textCss.colors.lightGrey};
`;
export const SmallBrightText = styled.span`
  font-size: ${(props) => props.theme.textCss.sizes.sm};
  color: ${(props) => props.theme.textCss.colors.lightGrey};
`;
export const ExtraSmallBrightText = styled.span`
  font-size: ${(props) => props.theme.textCss.sizes.sm};
  color: ${(props) => props.theme.textCss.colors.lightGrey};
`;
