import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import apiClient from '@/apis/semji/apiClient';
import { LOCATION, SCOPE_ORGANIZATION } from '@/apis/semji/constants';
import { sortUsers } from '@/utils/user';

import { useGetOrganizationAccessesByIdProps } from './useGetOrganizationAccessesById.types';

export default function useGetOrganizationAccessesById(props: useGetOrganizationAccessesByIdProps) {
  const { get } = apiClient;
  const { organizationId } = useParams();

  return useQuery({
    placeholderData: [],
    queryFn: async ({ signal }) => {
      const { data } = await get(`/organizations/${organizationId}/organization_accesses`, {
        signal,
      });

      return sortUsers(data?.['hydra:member'] || []);
    },
    queryKey: [SCOPE_ORGANIZATION.GET_ORGANIZATION_ACCESSES, LOCATION.WORKSPACE, organizationId],
    ...props,
  });
}
