import styled from 'styled-components/macro';

export const DarkText = styled.span`
  font-size: ${(props) =>
    props.size === 'small'
      ? props.theme.text.sizes.small
      : props.size === 'micro'
        ? '0.7em'
        : '1em'};
  color: ${(props) => props.theme.text.colors.default};
  font-weight: ${(props) => (props.bold ? 700 : props.medium ? 500 : 300)};
  line-height: ${({ lineHeight, theme }) => lineHeight && theme.text.sizes[lineHeight]};
`;
export const DarkerText = styled.span`
  font-size: ${(props) =>
    props.size === 'small'
      ? props.theme.text.sizes.small
      : props.size === 'micro'
        ? '0.7em'
        : '1rem'};
  color: ${(props) => props.theme.textCss.colors.default};
  font-weight: ${(props) => (props.bold ? 700 : props.medium ? 500 : 300)};
`;

export const DefaultDarkText = styled.span`
  font-size: ${(props) => props.theme.textCss.sizes.default};
  color: ${(props) => props.theme.textCss.colors.default};
`;
export const ExtraSmallDefaultDarkText = styled.span`
  font-size: ${(props) => props.theme.textCss.sizes.sm};
  color: ${(props) => props.theme.textCss.colors.default};
`;

export const BigDefaultDarkText = styled.span`
  font-size: ${(props) => props.theme.textCss.sizes.md};
  color: ${(props) => props.theme.textCss.colors.default};
`;
