import './EmptyEditorContainer.scss';

import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, ENUM_BUTTON_VARIANTS } from 'semji-core/components/Button';

import UserIcon from '@/assets/images/user.svg';
import CreateDraft from '@/components/icons/CreateDraft';
import useApiConfigurations from '@/hooks/useApiConfigurations';
import useIsUserLimitedGuestWriter from '@/hooks/useIsUserLimitedGuestWriter';
import { CONTENT_VERSION_HISTORY_ENABLED } from '@/utils/configurations/constants';

export default function EmptyEditorContent({
  createNewDraft,
  isUpdatingPlanning,
  isCreating,
  showVersionHistoryButton,
  hideStartedMode,
}: {
  createNewDraft: (publicId: string | null) => void;
  isUpdatingPlanning: boolean;
  isCreating?: boolean;
  showVersionHistoryButton?: boolean;
  hideStartedMode?: boolean;
}) {
  const { organizationId, workspaceId, pageId } = useParams();
  const navigate = useNavigate();
  const { draftIsStarted } = useSelector((state: any) => state.editor);
  const { t } = useTranslation();
  const isUserLimitedGuestWriter = useIsUserLimitedGuestWriter();
  const contentVersionHistoryEnabled = useApiConfigurations(CONTENT_VERSION_HISTORY_ENABLED);
  const publicId = useSelector((state: any) => state.user.id);

  function handleCreateDraft() {
    createNewDraft(isUserLimitedGuestWriter ? `/users/${publicId}` : null);
  }

  function handleGoToPlanning(e?: React.MouseEvent<HTMLButtonElement>) {
    e?.preventDefault();
    navigate(`/o/${organizationId}/w/${workspaceId}/planning`);
  }

  function handleGoToVersionHistory() {
    navigate(`/o/${organizationId}/w/${workspaceId}/p/${pageId}/versions/`);
  }

  return draftIsStarted && !hideStartedMode ? (
    <div className="empty-editor-container__content">
      <img alt="" className="empty-editor-container__content__image" src={UserIcon} />
      <h2 className="empty-editor-container__content__title">
        {t('content:empty-editor-container.draft-assigned-to-another-user')}
      </h2>
      <p className="empty-editor-container__content__description">
        {t('content:empty-editor-container.draft-insufficient-rights')}
      </p>
      <Button disabled={isUpdatingPlanning} onClick={handleGoToPlanning}>
        {t('content:empty-editor-container.go-to-planning')}
      </Button>
    </div>
  ) : (
    <div className="empty-editor-container__content">
      <CreateDraft />
      <h2 className="empty-editor-container__content__title">
        {t('content:empty-editor-container.let-optimize-content')}
      </h2>
      <p className="empty-editor-container__content__description">
        <Trans i18nKey={'content:empty-editor-container.improve-your-content'} />
      </p>
      <Button disabled={isUpdatingPlanning || isCreating} onClick={handleCreateDraft}>
        {t('content:empty-editor-container.start-optimizing')}
      </Button>
      {showVersionHistoryButton && contentVersionHistoryEnabled && (
        <Button variant={ENUM_BUTTON_VARIANTS.TextPrimary} onClick={handleGoToVersionHistory}>
          {t('content:empty-editor-container.see-content-history')}
        </Button>
      )}
    </div>
  );
}
