import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import BoxIcon from '@/components/icons/BoxIcon';
import TooltipComponent from '@/components/Tooltip/Tooltip';

export const ArchiveWrapper = styled.div`
  display: none;
`;

const BoxIconStyled = styled(BoxIcon)`
  && {
    font-size: 1rem;
    padding: 0.3rem;
  }
  &:hover {
    background-color: ${({ theme }) => theme.cssColors.dark010};
    border-radius: 10%;
  }
`;

function ArchiveCell({ className, onClick, defaultDisabled }) {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(defaultDisabled);

  async function handleClick(e) {
    e.stopPropagation();
    if (!disabled) {
      setDisabled(true);
      onClick();
      setTimeout(() => setDisabled(false), 40);
    }
  }

  return (
    <ArchiveWrapper>
      <TooltipComponent
        className={className}
        cursor={disabled ? 'not-allowed' : 'pointer'}
        delay={50}
        placement="top"
        title={t('content-ideas:listing.archive.archive')}
      >
        <BoxIconStyled style={{ fontSize: '1rem' }} onClick={handleClick} />
      </TooltipComponent>
    </ArchiveWrapper>
  );
}

export default ArchiveCell;
