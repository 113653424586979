import React from 'react';

import Layout, { LayoutBody } from '@/components/Layout/Layout';
import LayoutHeader from '@/containers/Layout/LayoutHeader';
import { SEMJI_PAGE_BODY_ID } from '@/utils/constants';

const LayoutWrapper = ({ variant, menu, children, steps = [], ...props }) => (
  <Layout variant={variant} {...props}>
    <LayoutHeader steps={steps} variant={menu} />
    <LayoutBody id={SEMJI_PAGE_BODY_ID}>{children}</LayoutBody>
  </Layout>
);

export default LayoutWrapper;
