export const ANALYSIS_INCOMING_LINKS_CREDITS_ENABLED = 'analysis.incoming_links_credits.enabled';
export const PAGE_SNAPSHOT_ENABLED = 'page.snapshot.enabled';
export const PDF_BUILD_ENABLED = 'pdf.build.enabled';
export const TOP_KEYWORD_SEARCH_CONSOLE_DATA_RETRIEVAL_FOR_ORGANIZATION_IN_TRIAL_ENABLED =
  'top_keyword.search_console_data_retrieval_for_organization_in_trial.enabled';
export const TOP_PAGES_RETRIEVAL_SCHEDULING_FOR_ORGANIZATION_IN_TRIAL_ENABLED =
  'top_pages.retrieval_scheduling_for_organization_in_trial.enabled';
export const USER_REGISTER_ENABLED = 'user.register.enabled';
export const USER_REGISTER_GOOGLE_ENABLED = 'user.register_google.enabled';
export const USER_SIGNIN_FACEIO_ENABLED = 'user.signin_faceio.enabled';
export const WORKSPACE_DELAY_ONBOARDING_ENABLED = 'workspace.delay_onboarding.enabled';
export const CONTENT_AI_WRITING_ENABLED = 'content.ai_writing.enabled';
export const CONTENT_AI_WRITING_KEY_POINTS_ENABLED = ' content.ai_writing.keypoints.enabled';
export const CONTENT_IDEAS_CLUSTERING_DISPLAYED = 'content_ideas.clustering.displayed';
export const CONTENT_IDEAS_ENABLED = 'content_ideas.enabled';
export const CONTENT_IDEAS_CREDITS_ENABLED = 'content_ideas.credits.enabled';
export const COMPETITORS_ENABLED = 'competitors.enabled';
export const CONTENT_VERSION_HISTORY_ENABLED = 'editor.draft_versioning.fetch.enabled';
export const CONTENT_EXPORT_SYNC_LIMITATION = 'content.export.sync_limitation';
export const AI_WRITING_ASK_FOR_RATE_PROBABILITY = 'ai_writing.ask_for_rate.probability';
export const SELF_SERVE_ENABLED = 'self_serve.enabled';
export const EDITOR_CLEAN_HTML_ENABLED = 'editor.clean_html.enabled';
export const PAGE_EXPORT_SYNC_LIMITATION = 'page.export.sync_limitation';
export const UNIVERSAL_SEARCH_GLOBAL_ENABLED = 'universal_search.global.enabled';
export const CONTENT_IDEAS_EXPORT_SYNC_LIMITATION = 'content_idea.export.sync_limitation';
export const PLANNING_SYNC_CALENDAR_ENABLED = 'planning.sync_calendar.enabled';
export const MATOMO_ENABLED = 'matomo.enabled';
export const ADOBE_ENABLED = 'adobe.enabled';
export const PIWIK_PRO_ENABLED = 'piwik-pro.enabled';
export const EDITOR_COMMENTS_ENABLED = 'editor.comments.enabled';
export const EDITOR_WORD_COUNT_PLUGIN_ENABLED = 'editor.word_count_plugin.enabled';
export const RANK_TRACKING_ENABLED = 'rank_tracking.enabled';
export const AI_WRITING_NEW_UI_ENABLED = 'ai-writing.new-ui.enabled';
export const CONTENT_AI_WRITING_CREDITS_ENABLED = 'content.ai_writing.credits.enabled';
export const AI_WRITING_FACT_CHECKING_ENABLED = 'ai-writing.fact-checking.enabled';
export const AI_WRITING_BRAND_VOICE_CREDITS_ENABLED = 'ai_writing.brand_voice.credits.enabled';
export const DIFF_CONTENT_ENABLED = 'diff_content.enabled';

// Frequencies for debounced / recursive calls
export const EDITOR_DEBOUNCE_SAVE_FREQUENCY_MS = 'editor.debounce_save_frequency_ms';
export const EDITOR_REPORT_FREQUENCY_MS = 'editor.debounce_report_frequency_ms';
export const RECURSIVE_CALLS_REFETCH_FREQUENCY_MS = 'recursive-calls.refetch_frequency_ms';
export const LISTING_REFETCH_FREQUENCY_MS = 'listing.refetch_frequency_ms';
export const EDITOR_COMMENT_REFETCH_FREQUENCY_MS = 'editor.comments_refetch_frequency_ms';
export const EDITOR_CONTENT_REFETCH_FREQUENCY_MS = 'editor.content_refetch_frequency_ms';

export const AI_WRITING_CREDITS_INTRODUCTION = 'ai_writing.credits.introduction';
export const AI_WRITING_CREDITS_TITLE = 'ai_writing.credits.title';
export const AI_WRITING_CREDITS_OUTLINE = 'ai_writing.credits.outline';
export const AI_WRITING_CREDITS_PARAGRAPH = 'ai_writing.credits.paragraph';
export const AI_WRITING_CREDITS_META_DESCRIPTION = 'ai_writing.credits.meta_description';
export const AI_WRITING_CREDITS_ATOMIC_CONTENT = 'ai_writing.credits.atomic_content';
export const AI_WRITING_CREDITS_BRIEF_TITLE = 'ai_writing.credits.brief.title';
export const AI_WRITING_CREDITS_BRIEF_SEARCH_INTENTS = 'ai_writing.credits.brief.search_intents';

export const SW_VERSION_RECOMMENDED = 'app.version.recommended';
export const SW_VERSION_MANDATORY = 'app.version.mandatory';

export const COMPLETION_TITLE_PROMPT_CODE = 'completion.title.prompt_code';
