import './RadioRange.scss';

import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ENUM_BUTTON_VARIANTS } from 'semji-core/components/Button';
import { Dropdown } from 'semji-core/components/Dropdown';
import { Input } from 'semji-core/components/Input';

import { ENUM_FILTER_TYPE, FilterAnchor } from '@/components/DynamicFilters';
import RadioItem from '@/components/DynamicFilters/Radio/RadioItem';
import { RadioRangeProps } from '@/components/DynamicFilters/RadioRange/RadioRange.types';
import ScrollBar from '@/components/Scrollbar';
import { ArrayUtils } from '@/utils/array/Array.utils';

function RadioRange({
  icon,
  options,
  onValidate,
  name,
  id,
  filterHook,
  onClose,
  key,
  customRange,
  filterKey,
  disabled,
}: RadioRangeProps) {
  const { t } = useTranslation();
  const selectedValue = options?.find((option) => option.selected)?.value;
  const [fromInit, toInit] = customRange ?? [0, 0];
  const [fromTo, setFromTo] = useState(!!(fromInit && toInit));
  const [fromToValues, setFromToValues] = useState<{ from: number; to: number }>({
    from: fromInit,
    to: toInit,
  });
  const [selected, setSelected] = useState<number[] | undefined>(selectedValue || undefined);
  const nothingSelected = !selected && (!fromToValues.from || !fromToValues.to);
  const [initOpen, setInitOpen] = useState(nothingSelected);

  const dropDrownActionsRef = useRef({
    handleClose: () => null,
    handleOpen: () => null,
  });

  function handleItemChange(value: number[]): void {
    if (disabled) return;

    setFromTo(false);
    setSelected(value);
  }

  function handleActivateFromTo() {
    if (disabled) return;

    setFromTo(true);
    setSelected(undefined);
  }

  function handleSetFrom(e) {
    if (disabled) return;

    setFromToValues({ ...fromToValues, from: e.target.value });
  }

  function handleSetTo(e) {
    if (disabled) return;

    setFromToValues({ ...fromToValues, to: e.target.value });
  }

  function handleCloseSecondaryFilter() {
    if (disabled) return;

    if (!selected && (fromToValues.from === 0 || fromToValues.to === 0)) {
      onClose(filterKey);
    }
  }

  function handleForceCloseSecondaryFilter() {
    if (disabled) return;

    onClose(filterKey);
  }

  function handleValidate(): void {
    if (disabled) return;

    if (fromTo) {
      onValidate([fromToValues.from, fromToValues.to]);
    } else {
      onValidate(selected);
    }
    dropDrownActionsRef.current.handleClose();
  }

  function getContentHeight() {
    let accumulator = 1;
    if (fromTo) accumulator += 1.5;
    return Math.min((options.length + accumulator) * 40, 280);
  }

  return (
    <Dropdown
      actionsRef={dropDrownActionsRef}
      anchorElement={
        <FilterAnchor
          customLabel={
            fromTo && customRange ? `Top ${customRange[0]}-${customRange[1]}` : undefined
          }
          disabled={disabled}
          icon={icon}
          id={id}
          name={name}
          options={options}
          type={ENUM_FILTER_TYPE.RADIO_RANGE}
          onReset={handleForceCloseSecondaryFilter}
          onValidate={onValidate}
        />
      }
      containerClassName="competitors-radio-range"
      disabled={disabled}
      isFrame={false}
      offsetValue={10}
      openValue={initOpen}
      placement={'bottom-start'}
      querySelectorListener="#root"
      width="240px"
      onClose={handleCloseSecondaryFilter}
    >
      <div className="competitors-radio-range__content">
        <div
          className="competitors-radio-range__content__list"
          style={{ height: getContentHeight() }}
        >
          <ScrollBar autoHide={false} weight="4px">
            {options.map((option, index) => (
              <RadioItem
                key={`radio-range-${option.value}-options-${index}`}
                checked={!!selected && ArrayUtils.fastEqual(selected, option.value)}
                label={option.label}
                onClick={() => handleItemChange(option.value)}
              />
            ))}
            <RadioItem
              checked={fromTo}
              label={t('common:filters.custom')}
              onClick={handleActivateFromTo}
            />
            {fromTo && (
              <div className={'competitors-radio-range__content__list__from_to'}>
                <div className={'competitors-radio-range__content__list__from_to__input'}>
                  <Input
                    label={t('common:filters.from')}
                    type="number"
                    value={fromToValues.from}
                    variant={'borderless'}
                    onChange={handleSetFrom}
                  />
                </div>
                <div className={'competitors-radio-range__content__list__from_to__input'}>
                  <Input
                    label={t('common:filters.to')}
                    type="number"
                    value={fromToValues.to}
                    variant={'borderless'}
                    onChange={handleSetTo}
                  />
                </div>
              </div>
            )}
          </ScrollBar>
        </div>
        <div className="competitors-radio-range__content__footer">
          <Button
            className="competitors-radio-range__content__footer__validate"
            variant={ENUM_BUTTON_VARIANTS.Text}
            onClick={handleValidate}
          >
            {t('competitors:filters.validate')}
          </Button>
        </div>
      </div>
    </Dropdown>
  );
}

export default RadioRange;
