import useEventListener from '@/hooks/useEventListener';

const EVENT = 'visibilitychange';

// Inspired by https://github.com/donavon/use-event-listener/blob/develop/src/index.js
const usePageVisibility = ({ onHide, onShow, element = document }) => {
  useEventListener(
    EVENT,
    () => {
      const isHidden = element.visibilityState === 'hidden';
      if (isHidden) {
        onHide?.();
      } else {
        onShow?.();
      }
    },
    element
  );
};

export default usePageVisibility;
