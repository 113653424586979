import React from 'react';

import SvgWrapper from './SvgWrapper';

const StatsUpIcon = (props) => (
  <SvgWrapper {...props}>
    <path d="M17 16v1h-17v-1h17zM5.203 7.976l4.204 3.026 5.593-6.251v2.284h1v-4.035h-4.036v1h2.366l-5.070 5.665-4.129-2.974-4.372 3.956 0.671 0.741 3.773-3.412z" />
  </SvgWrapper>
);

export default StatsUpIcon;
