import './Filters.scss';

import React, { useId } from 'react';

import FilterItem from '@/components/DynamicFilters/FilterItem/FilterItem';

import { FiltersUtils } from './Filters.utils';
import { FilterProps } from './index';

export default function Filters({
  filters = [],
  before = undefined,
  after = undefined,
  AddFilterElement,
}: FilterProps) {
  const itemPrefix = `competitor-filter-${useId()}`;
  return (
    <div className="competitors-filters">
      {FiltersUtils.getSortSelects(filters).map((filter, index) => (
        <FilterItem
          key={`${itemPrefix}-${filter.key}-${index}`}
          filter={filter}
          id={`${itemPrefix}-${filter.key}-${index}`}
          type={filter.type}
        />
      ))}
      {AddFilterElement}
    </div>
  );
}
