import React from 'react';

import SvgWrapper from './SvgWrapper';

const FunnelIcon = (props) => (
  <SvgWrapper viewBox="0 0 12 12" {...props}>
    <path d="M0 0H11.2L6.7 6.5V12H4.6V6.5L0 0ZM6 6.3L9.9 0.7H1.4L5.3 6.3V11.3H6V6.3Z" />
  </SvgWrapper>
);

export default FunnelIcon;
