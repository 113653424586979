import './ReportGraphContainer.scss';

import React, { memo, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useMatch, useParams } from 'react-router-dom';
import { Alert } from 'semji-core/components/Alert';

import usePostReportPageMetrics from '@/apis/semji/reports/usePostReportPageMetrics';
import NoContentsVerticalBars, {
  NO_NEW_POSITION_TRACKED_REPORT,
  NO_POSITION_TRACKED_REPORT,
} from '@/components/EmptyState/NoContentsVerticalBars';
import NoDraft, {
  NO_NEW_DRAFT_DASHBOARD,
  NO_UPDATED_DRAFT_DASHBOARD,
} from '@/components/EmptyState/NoDraft';
import NoServiceIntegrationCurve, {
  NO_ANALYTICS_DASHBOARD,
  NO_ANALYTICS_REPORT,
  NO_SEARCH_CONSOLE_DASHBOARD,
  NO_SEARCH_CONSOLE_REPORT,
} from '@/components/EmptyState/NoServiceIntegrationCurve';
import Loader from '@/components/Loader/Loader';
import { DefaultLink } from '@/components/Navigation/Link';
import MetricsChart from '@/containers/Chart/MetricsChart';
import { ENUM_REPORT_TITLE_VARIANT, ReportTitle } from '@/containers/Report/components/ReportTitle';
import { CHART_FORMAT_VALUES } from '@/containers/Report/utils/constants';
import { getNoDraftVersion } from '@/containers/Report/utils/helpers';
import { AggregatedMetrics, ReportMetricKey, ReportView } from '@/containers/Report/utils/types';
import { useMetricGoalsConfig } from '@/hooks/useMetricGoalsConfig';
import { useMetricsConfig } from '@/hooks/useMetricsConfig';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import { selectHasAnalytics } from '@/store/selectors/selectHasAnalytics';
import { selectHasSearchConsole } from '@/store/selectors/selectHasSearchConsole';
import { selectUserCurrentWorkspaceRoles } from '@/store/selectors/selectUserCurrentWorkspaceRoles';
import { API_WORKSPACE_ROLE_OWNER } from '@/utils/can/constants';
import { SUPPORT_ADDRESS_MAIL } from '@/utils/constants';
import { getViewFilter } from '@/utils/helper';
import { aggregateMetrics } from '@/utils/metrics/aggregateMetrics';
import {
  ANALYTICS_DEPENDENCY,
  comparisonPeriods,
  FOCUS_KEYWORDS,
  FOCUS_TOP_KEYWORDS_METRICS,
  METRIC_STACKING_TYPE_DISABLED,
  RANKING_KEYWORDS_METRICS,
  SEARCH_CONSOLE_DEPENDENCY,
  TOP_100_TAB,
} from '@/utils/metrics/constants';
import { METRIC_POSITION_KEY, METRIC_STACKING_TYPE_NORMAL } from '@/utils/metrics/constants';
import { getCurrentDateRangeSQLFormat } from '@/utils/metrics/getCurrentDateRangeSQLFormat';
import { getPreviousDateRangeSQLFormat } from '@/utils/metrics/getPreviousDateRangeSQLFormat';

function ReportGraphContainer({
  reportView,
  period,
  periodicity,
  comparisonPeriod,
  metricKey,
  currentCount,
  customGoalKey,
  selectMetricClick,
  setShowTable,
  filteredPagesIds = [],
  isLoading: isLoadingProps,
  isFullWidth,
}: {
  reportView: ReportView;
  period: string;
  periodicity: string;
  comparisonPeriod: string;
  metricKey: ReportMetricKey;
  currentCount: number;
  customGoalKey?: string;
  setShowTable?: (showTable: boolean) => void;
  selectMetricClick: (metricKey: ReportMetricKey, goalKey: string) => void;
  filteredPagesIds?: string[];
  isLoading?: boolean;
  isFullWidth?: boolean;
}) {
  const { t } = useTranslation();
  const metrics = useMetricsConfig({ isNew: true });
  const { organizationId } = useParams();
  const hasSearchConsole = useSelector(selectHasSearchConsole);
  const hasAnalytics = useSelector(selectHasAnalytics);
  const goals = useMetricGoalsConfig(metricKey);
  const userRole = useSelector(selectUserCurrentWorkspaceRoles);
  const isOwner = userRole.includes(API_WORKSPACE_ROLE_OWNER);
  const [activeSubTab, setActiveSubTab] = useState(FOCUS_KEYWORDS);
  const [metricStackingType, setMetricStackingType] = useState<string | null>(
    CHART_FORMAT_VALUES[0].value
  );
  const isDashboardPage = Boolean(useMatch(`/o/:organizationId/w/:workspaceId/dashboard`));
  const currentRangeDate = getCurrentDateRangeSQLFormat({ period });
  const previousRangeDate = getPreviousDateRangeSQLFormat({ comparisonPeriod, period });
  const { data: canTrackPosition } = useOrganizationFeatureSet('rank-tracking:is-enabled');
  const { data: hasAccessToConversion } = useOrganizationFeatureSet(
    'pages:priority-score:has-access-to-conversions'
  );
  const commonFilters = {
    'exists[url]': true,
    'order[date]': 'asc',
    periodicity,
  };
  const payload = {
    pageIds: filteredPagesIds,
  };

  const {
    data: currentMetrics = [],
    isLoading: isCurrentMetricsLoading,
    isPlaceholderData: isCurrentMetricsPlaceholderData,
  } = usePostReportPageMetrics({
    filters: {
      ...getViewFilter(reportView),
      date: {
        after: currentRangeDate.from,
        before: currentRangeDate.to,
      },
      ...commonFilters,
    },
    payload,
  });
  const {
    data: previousMetrics = [],
    isLoading: isPreviousMetricsLoading,
    isPlaceholderData: isPreviousMetricsPlaceholderData,
  } = usePostReportPageMetrics({
    filters: {
      ...getViewFilter(reportView),
      date: {
        after: previousRangeDate.from,
        before: previousRangeDate.to,
      },
      ...commonFilters,
    },
    payload,
  });

  const isLoading =
    isLoadingProps ||
    isCurrentMetricsLoading ||
    isCurrentMetricsPlaceholderData ||
    isPreviousMetricsLoading ||
    isPreviousMetricsPlaceholderData;

  const { data: rankTrackingLimit } = useOrganizationFeatureSet(
    'rank-tracking:urls-tracked-number'
  );
  const { isFeatureEnabled: hasUnlimitedRankTrackingUrls } = useOrganizationFeatureSet(
    'rank-tracking:urls-tracked-number:has-unlimited-amount'
  );
  const currentOrganizationTrackedPages = useSelector(
    (state) => state.organizations.byId[organizationId]?.trackedPages
  );
  const hasTrackedPages = currentMetrics.some(
    (pageMetrics) => pageMetrics.focusTopKeywordPositionComputed
  );

  const aggregatedMetrics: AggregatedMetrics = aggregateMetrics(currentMetrics, metrics);
  const aggregatedPreviousMetrics: AggregatedMetrics = aggregateMetrics(previousMetrics, metrics);

  const isMetricData = aggregatedMetrics[metricKey] || aggregatedPreviousMetrics[metricKey];

  useEffect(() => {
    handleStackingChange(
      hasTrackedPages ? METRIC_STACKING_TYPE_NORMAL : METRIC_STACKING_TYPE_DISABLED
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metricKey, hasTrackedPages]);

  useEffect(() => {
    setShowTable && setShowTable(!!isMetricData);
  }, [isMetricData]);

  function handleStackingChange(stackingType: string) {
    if (metricKey === METRIC_POSITION_KEY) {
      setMetricStackingType(stackingType);
    } else {
      setMetricStackingType(null);
    }
  }

  const isAnalyticsEmptyState =
    !hasAnalytics && metrics[metricKey]?.dependency === ANALYTICS_DEPENDENCY;
  const isSearchConsoleEmptyState =
    !hasSearchConsole && metrics[metricKey]?.dependency === SEARCH_CONSOLE_DEPENDENCY;

  if (!metricKey) {
    return null;
  }

  const selectOptions = Object.keys(goals).map((key) => ({
    name: t(goals[key].name),
    title: t(goals[key].name),
    value: key,
  }));

  const key = customGoalKey ?? metricKey;
  const isPositionMetric = metricKey === METRIC_POSITION_KEY;
  const chartType = metrics[metricKey].chartType;

  const hasReachLimit =
    !hasUnlimitedRankTrackingUrls && currentOrganizationTrackedPages >= rankTrackingLimit;

  function handleSelectItem(positionItem: { value: string }) {
    if (
      hasAccessToConversion &&
      [ReportMetricKey.Conversions, ReportMetricKey.ConversionRate].includes(metricKey)
    ) {
      selectMetricClick(metricKey, positionItem.value);
    } else {
      setActiveSubTab(positionItem.value);
    }
  }

  function getActiveSubTab() {
    if (
      hasAccessToConversion &&
      [ReportMetricKey.Conversions, ReportMetricKey.ConversionRate].includes(metricKey)
    ) {
      return key;
    }
    return activeSubTab;
  }

  function getTitleSelectList() {
    if (
      hasAccessToConversion &&
      [ReportMetricKey.Conversions, ReportMetricKey.ConversionRate].includes(metricKey)
    ) {
      return selectOptions;
    } else if (canTrackPosition && isPositionMetric) {
      return [
        {
          description: t(
            'content:side-panel-components.performance.section.main-metrics-evolution.top100-tooltip'
          ),
          title: t('report:chart.metric-subtabs.top100-keywords'),
          value: TOP_100_TAB,
        },
        {
          description: t(
            'content:side-panel-components.performance.section.main-metrics-evolution.focus-keyword-tooltip'
          ),
          title: t('report:chart.metric-subtabs.focus-keywords'),
          value: FOCUS_KEYWORDS,
        },
      ];
    }
    return undefined;
  }

  return (
    <div
      className={`report-graph-container ${isDashboardPage && 'report-graph-container--dashboard'}`}
    >
      {isPositionMetric && hasReachLimit && (
        <Alert className="report-graph-container__alert" variant="primary">
          <Trans
            components={{ strong: <strong /> }}
            i18nKey={'report:alerts.tracked-keyword-limit'}
            values={{ limit: rankTrackingLimit }}
          />
          &nbsp;
          <DefaultLink
            color="primary"
            decoration
            isExternal
            size="sm"
            to={`mailto:${SUPPORT_ADDRESS_MAIL}`}
            weight="medium"
          >
            {t('components:dialog.mark-as-published-dialog.contact-us')}
          </DefaultLink>
          &nbsp;
          <span>
            {t('components:dialog.mark-as-published-dialog.tracked-keyword-limit-increase')}
          </span>
        </Alert>
      )}

      {!isLoading && (
        <ReportTitle
          activeSubTab={getActiveSubTab()}
          aggregatedMetrics={
            isAnalyticsEmptyState || isSearchConsoleEmptyState ? {} : aggregatedMetrics
          }
          aggregatedPreviousMetrics={
            isAnalyticsEmptyState || isSearchConsoleEmptyState ? {} : aggregatedPreviousMetrics
          }
          comparisonPeriodLabel={t(comparisonPeriods[comparisonPeriod].label)}
          currentCount={
            isAnalyticsEmptyState || isSearchConsoleEmptyState
              ? 0
              : hasReachLimit && isPositionMetric
                ? rankTrackingLimit
                : currentCount
          }
          currentMetricKey={customGoalKey ?? metricKey}
          currentMetricsState={currentMetrics}
          handleMetricStackingTypeChange={handleStackingChange}
          handleSelectItem={handleSelectItem}
          isNoData={
            isLoading || !currentCount || isAnalyticsEmptyState || isSearchConsoleEmptyState
          }
          metric={metrics[key]}
          metricStackingType={metricStackingType || undefined}
          SelectList={getTitleSelectList()}
          variant={
            isDashboardPage ? ENUM_REPORT_TITLE_VARIANT.Medium : ENUM_REPORT_TITLE_VARIANT.Default
          }
        />
      )}
      {(() => {
        if (isAnalyticsEmptyState) {
          if (isDashboardPage) {
            return (
              <div className="report-graph-container__chart-wrapper report-graph-container__chart-wrapper--huge">
                <NoServiceIntegrationCurve
                  isFullWidth={isFullWidth}
                  isOwner={isOwner}
                  version={NO_ANALYTICS_DASHBOARD}
                />
              </div>
            );
          }
          return (
            <div className="report-graph-container__chart-wrapper">
              <NoServiceIntegrationCurve
                chartType={chartType}
                isFullWidth={isFullWidth}
                isOwner={isOwner}
                version={NO_ANALYTICS_REPORT}
              />
            </div>
          );
        }
        if (isSearchConsoleEmptyState) {
          if (isDashboardPage) {
            return (
              <div className="report-graph-container__chart-wrapper report-graph-container__chart-wrapper--huge">
                <NoServiceIntegrationCurve
                  isOwner={isOwner}
                  version={NO_SEARCH_CONSOLE_DASHBOARD}
                />
              </div>
            );
          }
          return (
            <div className="report-graph-container__chart-wrapper">
              <NoServiceIntegrationCurve
                chartType={chartType}
                isFullWidth={isFullWidth}
                isOwner={isOwner}
                version={NO_SEARCH_CONSOLE_REPORT}
              />
            </div>
          );
        }
        if (isLoading || currentCount === undefined) {
          return (
            <div className="report-graph-container__chart-wrapper">
              <Loader />
            </div>
          );
        }
        if (key === METRIC_POSITION_KEY) {
          if (!currentCount || !hasTrackedPages) {
            return (
              <div className="report-graph-container__chart-wrapper">
                <NoContentsVerticalBars
                  isFull={isFullWidth}
                  version={
                    reportView === 'new'
                      ? NO_NEW_POSITION_TRACKED_REPORT
                      : NO_POSITION_TRACKED_REPORT
                  }
                />
              </div>
            );
          }
        }
        if (currentCount && isMetricData) {
          const positionMultipleMetricsKey =
            activeSubTab === TOP_100_TAB ? RANKING_KEYWORDS_METRICS : FOCUS_TOP_KEYWORDS_METRICS;
          return (
            <div className="report-graph-container__chart-wrapper">
              <MetricsChart
                comparisonPeriod={comparisonPeriod}
                currentMetricKey={key}
                displayedMetrics={{
                  [key]: { ...metrics[key], previousVisible: true },
                }}
                forceSelectedPositionKey={
                  activeSubTab === TOP_100_TAB
                    ? RANKING_KEYWORDS_METRICS
                    : FOCUS_TOP_KEYWORDS_METRICS
                }
                identifier={`report_chart_${key}`}
                legendLeft
                metrics={currentMetrics}
                multipleMetricsKey={key === METRIC_POSITION_KEY ? positionMultipleMetricsKey : null}
                noMargin
                period={period}
                periodicity={periodicity}
                previousMetrics={previousMetrics}
                stacked={isPositionMetric ? metricStackingType : null}
              />
            </div>
          );
        }
        if (isDashboardPage) {
          return (
            <div className="report-graph-container__chart-wrapper report-graph-container__chart-wrapper--huge">
              <NoDraft
                version={reportView === 'new' ? NO_NEW_DRAFT_DASHBOARD : NO_UPDATED_DRAFT_DASHBOARD}
              />
            </div>
          );
        } else {
          return (
            <div className="report-graph-container__chart-wrapper">
              <NoContentsVerticalBars
                isFull={isFullWidth}
                version={getNoDraftVersion(chartType, reportView)}
              />
            </div>
          );
        }
      })()}
    </div>
  );
}

export default memo(ReportGraphContainer);
