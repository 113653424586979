import FlatLoader from '@/components/Loader/FlatLoader';
import Toolbar from '@/containers/Content/TinyMceComponents/Toolbar';
import Box from '@/design-system/components/Box';
import Flex from '@/design-system/components/Flex';

export default function EditorSkeleton({ hideTopBar }) {
  return (
    <Flex backgroundColor="white" flex={1} flexDirection="column" overflow="hidden">
      {!hideTopBar && <Toolbar></Toolbar>}
      <Flex
        display="flex"
        flexDirection="column"
        gap="10px"
        margin="0 auto"
        paddingTop="40px"
        width="650px"
      >
        {/* Title */}
        <Box height="22px" padding="6px 0" width="60%">
          <FlatLoader />
        </Box>
        {/* Url */}
        <Box height="20px" width="75%">
          <FlatLoader />
        </Box>
        <br />
        {/* Content */}
        <Flex flex={1} flexDirection="column" gap="15px" width="650px">
          <Box height="32px" width="65%">
            <FlatLoader />
          </Box>
          <Box height="16px" width="100%">
            <FlatLoader />
          </Box>
          <Box height="16px" width="95%">
            <FlatLoader />
          </Box>
          <Box height="16px" width="95%">
            <FlatLoader />
          </Box>
          <Box height="16px" width="100%">
            <FlatLoader />
          </Box>
          <Box height="16px" width="90%">
            <FlatLoader />
          </Box>
          <Box height="16px" width="95%">
            <FlatLoader />
          </Box>
          <Box height="16px" width="80%">
            <FlatLoader />
          </Box>
          <br />

          <Box height="28px" width="45%">
            <FlatLoader />
          </Box>
          <Box height="16px" width="100%">
            <FlatLoader />
          </Box>
          <Box height="16px" width="95%">
            <FlatLoader />
          </Box>
          <Box height="16px" width="95%">
            <FlatLoader />
          </Box>
          <Box height="16px" width="100%">
            <FlatLoader />
          </Box>
          <Box height="16px" width="90%">
            <FlatLoader />
          </Box>
          <Box height="16px" width="95%">
            <FlatLoader />
          </Box>
          <Box height="16px" width="80%">
            <FlatLoader />
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
}
