import styled from 'styled-components/macro';

import Row from '@/containers/Content/BriefComponents/Table/Rows/Row';

const Header = styled(Row)`
  &&& {
    text-transform: uppercase;
    color: ${({ theme }) => theme.cssColors.dark040};
    font-size: ${(props) => props.theme.textCss.sizes.xs};
    font-weight: ${({ theme }) => theme.textCss.weights.strong};
  }
`;

export default Header;
