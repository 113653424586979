import { ENV_DEMO, ENV_DEV, ENV_NEXT, ENV_PRODUCTION, ENV_STAGING } from '@/utils/log/constants';

export function envDetector() {
  const url = import.meta.env.VITE_REACT_APP_SEMJI_API_ROOT_URL;

  if (url.includes('api.')) {
    return ENV_PRODUCTION;
  }
  if (url.includes('api-sg.')) {
    return ENV_STAGING;
  }
  if (url.includes('api-demo')) {
    return ENV_DEMO;
  }
  if (url.includes('api-next')) {
    return ENV_NEXT;
  }
  if (url.includes('localhost')) {
    return ENV_DEV;
  }

  return 'n/a';
}
