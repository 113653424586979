import styled from 'styled-components/macro';

import { MetricCartridge } from './MetricCartridge';

const CartridgesContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: ${(props) => props.direction};
  width: 100%;
  height: auto;
`;

export function MetricsCartridges({
  direction,
  size,
  metrics,
  cartridgeSize,
  isPublished,
  isRetrieving,
  ...otherProps
}) {
  return (
    <CartridgesContainer direction={direction} size={size} {...otherProps}>
      {metrics.map((metric) => (
        <MetricCartridge
          direction={direction}
          isPublished={isPublished}
          isRetrieving={isRetrieving}
          size={cartridgeSize}
          {...metric}
        />
      ))}
    </CartridgesContainer>
  );
}
