import './MessageSearchConsole.scss';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import WarningTriangleIcon from '@/components/icons/WarningTriangleIcon';
import { DefaultLink } from '@/components/Navigation/Link';

const StyledWarningTriangleIcon = styled(WarningTriangleIcon)`
  && {
    font-size: 1rem;
    fill: ${(props) => props.theme.cssColors.primary};
  }
`;

function MessageSearchConsole({ message }) {
  const { t } = useTranslation();

  const { organizationId, workspaceId } = useParams();

  return (
    <div className="message-search-console">
      <StyledWarningTriangleIcon />
      <p className="message-search-console__text">
        <DefaultLink
          noDecoration
          size="sm"
          to={`/o/${organizationId}/w/${workspaceId}/settings/integrations`}
          weight="medium"
        >
          {t('components:message.message-search-console.text')}
        </DefaultLink>
        <span> {message}</span>
      </p>
    </div>
  );
}

export default MessageSearchConsole;
