import { useMutation } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import apiClient from '@/apis/semji/apiClient';
import { LOCATION, SCOPE_WORKSPACE } from '@/apis/semji/constants';

export default function usePostWorkspaceAchievement(props) {
  const { post } = apiClient;
  const workspaceId = useSelector((state) => state.defaultWorkspaceId || '');
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (code) => {
      await post(`/workspaces/${workspaceId}/achievement`, {
        code,
      });
      queryClient.refetchQueries({
        active: true,
        exact: true,
        fetching: false,
        queryKey: [SCOPE_WORKSPACE.GET_CURRENT_WORKSPACE, workspaceId, LOCATION.QUICK_START_GUIDE],
      });
    },

    ...props,
  });
}
