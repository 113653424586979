import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import ImgErrorAnalysis from '@/assets/images/img-error-analysis.svg';
import { DefaultLink } from '@/components/Navigation/Link';
import { ParagraphText } from '@/components/Text/Paragraph';
import { TitleTwo } from '@/components/Text/Title';
import { EditorEmptyBodyWrapper } from '@/containers/Content/Wrappers';
import PrimaryButton from '@/design-system/components/Button/PrimaryButton';
import { SUPPORT_ADDRESS_MAIL } from '@/utils/constants';

const StyledParagraphText = styled(ParagraphText)`
  max-width: 380px;
`;

export const AN_ERROR_OCCURRED = 'anErrorOccurred';
export const KEYWORD_NOT_ANALYZED = 'keywordNotAnalyzed';

const NoAnalyze = ({ version = AN_ERROR_OCCURRED, onClick, isInProgress }) => {
  const { t } = useTranslation();
  const NO_ANALYZE_VALUES = {
    [AN_ERROR_OCCURRED]: {
      body: () => (
        <Trans
          components={{
            defaultLink: (
              <DefaultLink isExternal to={`mailto:${SUPPORT_ADDRESS_MAIL}`} weight="strong" />
            ),
          }}
          i18nKey={'empty-state:no-analyze.an-error-occurred.body'}
          values={{ email: SUPPORT_ADDRESS_MAIL }}
        />
      ),
      button: t('empty-state:no-analyze.an-error-occurred.button'),
      title: t('empty-state:no-analyze.an-error-occurred.title'),
    },
    [KEYWORD_NOT_ANALYZED]: {
      body: () => t('empty-state:no-analyze.keyword-not-analyzed.body'),
      button: t('empty-state:no-analyze.keyword-not-analyzed.button'),
      title: t('empty-state:no-analyze.keyword-not-analyzed.title'),
    },
  };
  return (
    <EditorEmptyBodyWrapper>
      <img alt={t('empty-state:no-analyze.failed-alt-image')} src={ImgErrorAnalysis} width="150" />
      <TitleTwo weight="strong">{NO_ANALYZE_VALUES[version].title}</TitleTwo>
      <StyledParagraphText align="center" color="dark060" weight="medium">
        {NO_ANALYZE_VALUES[version].body()}
      </StyledParagraphText>
      <PrimaryButton disabled={isInProgress} weight="strong" onClick={onClick}>
        {NO_ANALYZE_VALUES[version].button}
      </PrimaryButton>
    </EditorEmptyBodyWrapper>
  );
};

export default NoAnalyze;
