import './DetailsBase.scss';

import { DialogModal, ENUM_DIALOG_MODAL_SIZE } from 'semji-core/components/DialogModal';

function DetailsBase({ show, onClose, title, subTitle, description, subDescription, children }) {
  function handleClose() {
    onClose();
  }

  return (
    <DialogModal
      ariaLabelledBy="form-dialog-title"
      canClickOutsideToClose
      className="details-base-dialog"
      closable
      description={
        <span
          className="details-base-dialog__light-text"
          dangerouslySetInnerHTML={{ __html: subTitle }}
        ></span>
      }
      isOpen={show}
      size={ENUM_DIALOG_MODAL_SIZE.ExtraLarge}
      title={title}
      onClose={handleClose}
    >
      <span className="details-base-dialog__header" id="form-dialog-title">
        {!(!description || !!subDescription) && (
          <div className="details-base-dialog__secondary-header">
            {description}
            {subDescription}
          </div>
        )}
      </span>
      <div className="details-base-dialog__content">{children}</div>
    </DialogModal>
  );
}

export default DetailsBase;
