import { FilterRadioRangeOption } from '@/components/DynamicFilters';
import { ArrayUtils } from '@/utils/array/Array.utils';

export const MY_POSITION_TOP_1_3 = 'Top 1-3';
export const MY_POSITION_TOP_4_10 = 'Top 4-10';
export const MY_POSITION_TOP_11_100 = 'Top 11-100';
export const MY_POSITION_UNRANKED = '>100';

export const MY_POSITION_OPTIONS = [
  MY_POSITION_TOP_1_3,
  MY_POSITION_TOP_4_10,
  MY_POSITION_TOP_11_100,
  MY_POSITION_UNRANKED,
];

export const getMyPositionOptions = (filter?: number[]): FilterRadioRangeOption[] => {
  return MY_POSITION_OPTIONS.map((option) => {
    const value =
      option === MY_POSITION_UNRANKED
        ? [-1, -1]
        : option.replace('Top ', '').split('-').map(Number);
    return {
      id: option,
      label: option,
      selected: filter && ArrayUtils.fastEqual(filter, value),
      value,
    };
  });
};
