import removeDiacritics from '@/utils/removeDiacritics';

export default function searchFilter(pages, searchTerm) {
  if (searchTerm) {
    return pages.filter((page) => {
      const clearedSearchTerm = removeDiacritics(searchTerm.toLowerCase());
      const clearedFocusKeyword = removeDiacritics(
        ('' + page.focusTopKeyword?.keyword).toLowerCase()
      );
      const clearedUrl = removeDiacritics(('' + page.url).toLowerCase());
      const clearedTitle = removeDiacritics(('' + page.title).toLowerCase());

      return (
        clearedFocusKeyword.includes(clearedSearchTerm) ||
        clearedUrl.includes(clearedSearchTerm) ||
        clearedTitle.includes(clearedSearchTerm)
      );
    });
  }

  return pages;
}
