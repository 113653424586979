import { CLASS_ATTRIBUTE_NAME, HIGHLIGHTER_TAG_NAME } from '@/utils/highlight/constants';
import getElementDataByAttribute from '@/utils/highlight/helpers/getElementDataByAttribute';
import removeElementAttribute from '@/utils/highlight/helpers/removeElementAttribute';
import removeElementAttributes from '@/utils/highlight/helpers/removeElementAttributes';

function unhighlight({
  datum,
  dataAttribute,
  element,
  highlightClass,
  removeHightlightClass = true,
  keepAttributes = false,
  keepDatum = false,
}: {
  datum: string;
  dataAttribute: string;
  element: HTMLElement;
  highlightClass: string;
  removeHightlightClass?: boolean;
  keepAttributes?: boolean;
  keepDatum?: boolean;
}): void {
  const data: string[] = getElementDataByAttribute({ dataAttribute, element });

  if (0 === data.length) return;

  // remove the datum from the dataset
  if (false === keepDatum) {
    element.dataset[dataAttribute] = data.filter((d: string) => d !== datum).join(';');
  }

  // if there still is data in the dataset, return only if keepDatum is false
  if ('' !== element.dataset[dataAttribute] && false === keepDatum) {
    return;
  }

  // if the dataset is empty, remove the data attribute
  if (false === keepAttributes) {
    removeElementAttributes({ element, keepAttributes: [CLASS_ATTRIBUTE_NAME] });
  }

  if (true === removeHightlightClass) {
    element.classList.remove(highlightClass);
  }

  // if the element has no class, remove the class attribute
  if (element.classList.length === 0) {
    removeElementAttribute({ attribute: CLASS_ATTRIBUTE_NAME, element });
  }

  // if the element is a span and has no attributes make the element a text node
  if (HIGHLIGHTER_TAG_NAME === element.tagName.toLowerCase() && 0 === element.attributes.length) {
    const textNode: Text = document.createTextNode(element.innerText);

    element.replaceWith(textNode);
  }
}

export default unhighlight;
