import React from 'react';

import SvgWrapper from './SvgWrapper';

const SemjiIcon = (props) => (
  <SvgWrapper {...props} viewBox="0 0 400 400">
    <path
      className="big_stripe"
      d="M 199.34152,16.593049 0.23107643,383.40695 H 76.760932 L 199.34152,158.56753 322.71118,383.40695 h 77.05774 z"
    />
    <path className="small_stripe" d="m 199.34152,288.32265 -51.13235,95.0843 h 101.60362 z" />
  </SvgWrapper>
);

export default SemjiIcon;
