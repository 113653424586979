import {
  RESET_CREATE_WORKSPACE,
  SET_LOCATION,
  SET_ORGANIZATION_ID,
  SET_SERVICE_INTEGRATION,
  SET_USERS,
  SET_WEBSITE_URL,
  SET_WORKSPACE_NAME,
} from '../actions/actionTypes';

export const initialState = {
  dataSources: {
    analytics: null,
    searchConsole: null,
  },
  location: {
    country: null,
    countryCode: null,
    isLocationDirty: false,
    isObvious: null,
    languageCode: null,
    primary: null,
  },
  organizationId: null,
  users: ['', '', '', '', ''],
  workspace: {
    isNameDirty: false,
    name: '',
    websiteUrl: '',
  },
};

const createWorkspace = (state = {}, action) => {
  switch (action.type) {
    case SET_WEBSITE_URL:
      return {
        ...state,
        [action.organizationId]: {
          ...state[action.organizationId],
          workspace: {
            ...state[action.organizationId].workspace,
            websiteUrl: action.websiteUrl,
          },
        },
      };
    case SET_LOCATION:
      return {
        ...state,
        [action.organizationId]: {
          ...state[action.organizationId],
          location: {
            ...action.location,
            isLocationDirty: action.isLocationDirty,
          },
        },
      };
    case SET_WORKSPACE_NAME:
      return {
        ...state,
        [action.organizationId]: {
          ...state[action.organizationId],
          workspace: {
            ...state[action.organizationId].workspace,
            isNameDirty: action.isNameDirty,
            name: action.name,
          },
        },
      };
    case SET_SERVICE_INTEGRATION:
      return {
        ...state,
        [action.organizationId]: {
          ...state[action.organizationId],
          dataSources: {
            ...(state[action.organizationId]?.dataSources || {}),
            ...action.dataSources,
          },
        },
      };
    case SET_USERS:
      return {
        ...state,
        [action.organizationId]: {
          ...state[action.organizationId],
          users: action.users,
        },
      };
    case SET_ORGANIZATION_ID:
      return {
        ...state,
        [action.organizationId]: {
          ...initialState,
          ...state[action.organizationId],
          organizationId: action.organizationId,
        },
      };
    case RESET_CREATE_WORKSPACE:
      let tmp = { ...state };
      delete tmp[action.organizationId];
      return tmp;
    default:
      return state;
  }
};

export default createWorkspace;
