import { useParams } from 'react-router-dom';

import usePersistedState from '@/hooks/usePersistedState';

export default function useOpenedFoldersPersistedState() {
  const { organizationId, workspaceId } = useParams();

  return usePersistedState({
    initialData: [],
    key: `open-folders-ids-o${organizationId}-w${workspaceId}`,
  });
}
