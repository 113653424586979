import styled from 'styled-components/macro';

import SpinnerIcon from '@/components/icons/SpinnerIcon';
import StarIcon from '@/components/icons/StarIcon';

export const StyledStarIcon = styled(StarIcon)`
  && {
    font-size: 11px;
    margin-bottom: 1px;
    margin-right: 4px;
  }
  color: ${({ color }) => color};
`;

export const StyledSpinnerIcon = styled(SpinnerIcon)`
  && {
    font-size: 11px;
    margin-bottom: 1px;
    margin-right: 4px;
  }
`;
