import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';

import Input from '@/components/Input/Input';
import WorkspaceService from '@/services/Workspace';
import { showSuccessSnackbar } from '@/store/actions/ui';
import { changeWorkspaceName } from '@/store/actions/workspace';

const StyledTextField = styled(Input)`
  && {
    max-width: 400px;
  }
`;

function WorkspaceNameInput({ workspaceId, workspaceName }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [localWorkspaceName, setLocalWorkspaceName] = useState(workspaceName);
  const [errorSaving, setErrorSaving] = useState(null);

  const handleBlur = async () => {
    setErrorSaving(null);

    if (workspaceName === localWorkspaceName) {
      return;
    }

    if (!localWorkspaceName?.length) {
      setErrorSaving(t('settings:workspace.workspace-name-input.error-length'));
    } else {
      try {
        const _WorkspaceService = new WorkspaceService(workspaceId);
        await _WorkspaceService.update({ name: localWorkspaceName });
        dispatch(
          showSuccessSnackbar(t('settings:workspace.workspace-name-input.snackbar-success'))
        );
        dispatch(changeWorkspaceName(workspaceId, localWorkspaceName));
      } catch (error) {
        setErrorSaving(t('settings:workspace.workspace-name-input.error-unknown'));
      }
    }
  };

  const handleChange = (event) => {
    setLocalWorkspaceName(event.target.value);
  };

  useEffect(() => {
    setLocalWorkspaceName(workspaceName);
  }, [workspaceName]);

  return (
    <StyledTextField
      error={!!errorSaving}
      fullWidth
      helperText={errorSaving}
      placeholder={t('settings:workspace.workspace-name-input.placeholder')}
      value={localWorkspaceName}
      onBlur={handleBlur}
      onChange={handleChange}
    />
  );
}

export default WorkspaceNameInput;
