import './Duel.scss';

import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import DuelCards from '@/containers/Competitors/Duel/DuelCards';
import { ContentReport } from '@/containers/Competitors/Duel/Report/ContentReport';
import { ThemeReport } from '@/containers/Competitors/Duel/Report/ThemeReport';
import { TrafficReport } from '@/containers/Competitors/Duel/Report/TrafficReport';
import { useCompetitor } from '@/containers/Competitors/hooks/useCompetitor';
import { CompetitorDrillUtils } from '@/containers/Competitors/utils/CompetitorDrill.utils';
import { COMPETITORS_METRIC_TYPES } from '@/containers/Competitors/utils/constants';
import useBreakpoints from '@/hooks/useBreakpoints';
import useFilters from '@/hooks/useFilters/useFilters';
import { changeDefaultWebsiteId } from '@/store/actions/searchIntelligence';
import { CompetitorTopPagesFilters } from '@/types/competitors';

import { Header } from './Header';

export default function Duel() {
  const { websiteId, reportId: competitorReportId, organizationId, workspaceId } = useParams();
  const { websiteReference, competitorWebsites, clusters } = useCompetitor();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isMinLargeDesktop } = useBreakpoints();

  const filterHook = useFilters<CompetitorTopPagesFilters>();
  const { search } = useLocation();

  // Use the first competitor website if the websiteId is not valid
  const filteredCompetitorWebsites = competitorWebsites.filter(
    (website) => !website.isSelfReference
  );
  const competitor =
    filteredCompetitorWebsites.find((c) => c.id === websiteId) || filteredCompetitorWebsites[0];

  const baseUrl = `/o/${organizationId}/w/${workspaceId}/search-intelligence/${competitorReportId}`;

  const filters = useMemo(
    () => filterHook.buildTransformedFilters(filterHook.filters),
    [filterHook.filters]
  );

  useEffect(() => {
    // Soft redirect to the first competitor website if the websiteId is not valid
    if (!websiteId || filteredCompetitorWebsites.findIndex((c) => c.id === websiteId) === -1) {
      navigate(`${baseUrl}/contents-duel/${filteredCompetitorWebsites[0]?.id}${search}`, {
        replace: true,
      });
    }
    if (websiteId) {
      dispatch(changeDefaultWebsiteId(websiteId));
    }
  }, [websiteId]);

  const handleOpenDataExplorer = CompetitorDrillUtils.openDrilledDataExplorer({
    baseUrl: `${baseUrl}/data-explorer`,
    filterHook,
  });

  return (
    <div className="competitors-duel">
      <Header competitorWebsites={competitorWebsites} />
      <div className="competitors-duel__content">
        <DuelCards clusters={clusters} filters={filters} />
        <div className="competitor-report__content__group-chart">
          {!isMinLargeDesktop && (
            <>
              <TrafficReport
                canToggle
                clusters={clusters}
                competitor={competitor}
                competitorReportId={String(competitorReportId)}
                filters={filters}
                handleOpenDataExplorer={handleOpenDataExplorer}
                reference={websiteReference}
              />
              <ContentReport
                clusters={clusters}
                competitor={competitor}
                competitorReportId={String(competitorReportId)}
                defaultMetricKey={COMPETITORS_METRIC_TYPES.traffic}
                filters={filters}
                handleOpenDataExplorer={handleOpenDataExplorer}
                reference={websiteReference}
              />
              <ContentReport
                clusters={clusters}
                competitor={competitor}
                competitorReportId={String(competitorReportId)}
                defaultMetricKey={COMPETITORS_METRIC_TYPES.page}
                filters={filters}
                handleOpenDataExplorer={handleOpenDataExplorer}
                reference={websiteReference}
              />
              <ThemeReport
                canToggle
                clusters={clusters}
                competitor={competitor}
                competitorReportId={String(competitorReportId)}
                filters={filters}
                handleOpenDataExplorer={handleOpenDataExplorer}
                isLoading={false}
                reference={websiteReference}
              />
            </>
          )}
        </div>
        {isMinLargeDesktop && (
          <>
            <div className="competitor-report__content__group-chart">
              <div className="competitor-report__content__group-chart__item">
                <TrafficReport
                  canToggle={false}
                  clusters={clusters}
                  competitor={competitor}
                  competitorReportId={String(competitorReportId)}
                  defaultMetricKey={COMPETITORS_METRIC_TYPES.traffic}
                  filters={filters}
                  handleOpenDataExplorer={handleOpenDataExplorer}
                  reference={websiteReference}
                />
              </div>
              <div className="competitor-report__content__group-chart__item">
                <TrafficReport
                  canToggle={false}
                  clusters={clusters}
                  competitor={competitor}
                  competitorReportId={String(competitorReportId)}
                  defaultMetricKey={COMPETITORS_METRIC_TYPES.page}
                  filters={filters}
                  handleOpenDataExplorer={handleOpenDataExplorer}
                  reference={websiteReference}
                />
              </div>
            </div>
            <div className="competitor-report__content__group-chart">
              <div className="competitor-report__content__group-chart__item">
                <ContentReport
                  clusters={clusters}
                  competitor={competitor}
                  competitorReportId={String(competitorReportId)}
                  defaultMetricKey={COMPETITORS_METRIC_TYPES.traffic}
                  filters={filters}
                  handleOpenDataExplorer={handleOpenDataExplorer}
                  reference={websiteReference}
                />
              </div>
              <div className="competitor-report__content__group-chart__item">
                <ContentReport
                  clusters={clusters}
                  competitor={competitor}
                  competitorReportId={String(competitorReportId)}
                  defaultMetricKey={COMPETITORS_METRIC_TYPES.page}
                  filters={filters}
                  handleOpenDataExplorer={handleOpenDataExplorer}
                  reference={websiteReference}
                />
              </div>
            </div>
            <div className="competitor-report__content__group-chart">
              <div className="competitor-report__content__group-chart__item">
                <ThemeReport
                  clusters={clusters}
                  competitor={competitor}
                  competitorReportId={String(competitorReportId)}
                  defaultMetricKey={COMPETITORS_METRIC_TYPES.traffic}
                  filters={filters}
                  handleOpenDataExplorer={handleOpenDataExplorer}
                  isLoading={false}
                  reference={websiteReference}
                />
              </div>
              <div className="competitor-report__content__group-chart__item">
                <ThemeReport
                  canToggle={false}
                  clusters={clusters}
                  competitor={competitor}
                  competitorReportId={String(competitorReportId)}
                  defaultMetricKey={COMPETITORS_METRIC_TYPES.page}
                  filters={filters}
                  handleOpenDataExplorer={handleOpenDataExplorer}
                  isLoading={false}
                  reference={websiteReference}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
