import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { LOCATION } from '@/apis/semji/constants';
import useGetCurrentWorkspacePagesWithSpecificProperties from '@/apis/semji/pages/useGetCurrentWorkspacePagesWithSpecificProperties';
import Card from '@/components/Card/Card';
import NoPages, { NO_PAGES_DASHBOARD } from '@/components/EmptyState/NoPages';
import Loader from '@/components/Loader/Loader';
import ContentScoreCell from '@/components/Table/Cell/ContentScoreCell';
import NotAcknowledgedCell from '@/components/Table/Cell/NotAcknowledgedCell';
import PictureCell from '@/components/Table/Cell/PictureCell';
import PotentialScoreCell from '@/components/Table/Cell/PotentialScoreCell';
import TitleCell from '@/components/Table/Cell/TitleCell';
import Table from '@/components/Table/Table';
import { FROM_ORIGIN } from '@/utils/constants';

function ContentsToOptimize() {
  const { t } = useTranslation();
  const { workspaceId, organizationId } = useParams();
  const pagesWithSpecificProperties = useGetCurrentWorkspacePagesWithSpecificProperties({
    filters: {
      'exists[draft]': false,
      'exists[lastPublishedAt]': false,
      'exists[url]': true,
      inStock: false,
      potentialScore: 'clicks',
    },
    location: LOCATION.DASHBOARD_CONTENTS_TO_OPTIMIZE,
    properties: [
      'id',
      'url',
      'title',
      'contentScore',
      'draft',
      'extra',
      'optimizedImages',
      'potentialScore',
      'imageUrl',
      'source',
      'new',
      'redirectionUrl',
      'focusTopKeyword',
      'titleRetrievedAt',
      'topKeywordsRetrievedAt',
      'lastStatusCode',
    ],
  });

  const columns = [
    {
      cellDataGetter: ({ rowData }) => rowData.optimizedImages.small,
      cellRenderer: ({ cellData }) => <PictureCell pictureUrl={cellData} />,
      dataKey: 'imageUrl',
      label: ' ',
      width: 45,
    },
    {
      cellDataGetter: ({ rowData }) => ({
        isRedirectionDetected: !!rowData.redirectionUrl,
        lastStatusCode: rowData.lastStatusCode,
        pageId: rowData.id,
        title: rowData.title,
        url: rowData.url,
        workspaceId: workspaceId,
      }),
      cellRenderer: ({ cellData }) => (
        <TitleCell
          isRedirectionDetected={cellData.isRedirectionDetected}
          lastStatusCode={cellData.lastStatusCode}
          organizationId={organizationId}
          pageId={cellData.pageId}
          title={cellData.title}
          type={FROM_ORIGIN.ONLINE}
          url={cellData.url}
          workspaceId={workspaceId}
        />
      ),
      dataKey: 'title',
      flexGrow: 1,
      label: t('dashboard:content-to-optimize.contents'),
      sortable: false,
      width: 100,
    },
    {
      align: 'center',
      cellDataGetter: ({ rowData }) => rowData.contentScore,
      cellRenderer: ({ cellData }) =>
        cellData !== null ? (
          <ContentScoreCell contentScore={cellData} />
        ) : (
          <NotAcknowledgedCell align="center" />
        ),
      dataKey: 'contentScore',
      label: t('dashboard:content-to-optimize.content-score'),
      sortable: false,
      width: 100,
    },
    {
      align: 'center',
      cellDataGetter: ({ rowData }) => rowData.potentialScore,
      cellRenderer: ({ cellData }) => <PotentialScoreCell potentialScore={cellData} />,
      dataKey: 'potentialScore',
      label: t('dashboard:content-to-optimize.potential-score'),
      sortable: false,
      width: 100,
    },
  ];

  useEffect(() => {
    return () => {
      pagesWithSpecificProperties.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    pagesWithSpecificProperties.data.length === 0 &&
    !(pagesWithSpecificProperties.isFetching && pagesWithSpecificProperties.isPlaceholderData)
  ) {
    return (
      <Card
        contentMinHeight="380px"
        contentSize="big"
        height="470px"
        title={t('dashboard:content-to-optimize.content-title_interval', {
          count: pagesWithSpecificProperties.data.length,
          postProcess: 'interval',
        })}
      >
        <NoPages version={NO_PAGES_DASHBOARD} />
      </Card>
    );
  }

  if (pagesWithSpecificProperties.isFetching && pagesWithSpecificProperties.isPlaceholderData) {
    return (
      <Card
        contentMinHeight="380px"
        height="470px"
        title={t('dashboard:content-to-optimize.content-title_interval', {
          count: '-',
          postProcess: 'interval',
        })}
      >
        <Loader />
      </Card>
    );
  }

  return (
    <Card
      contentMinHeight="380px"
      contentSize="big"
      displayFooter
      footerLabel={t('dashboard:content-to-optimize.view-opportunities')}
      footerLink={`/o/${organizationId}/w/${workspaceId}/contents?tab=opportunities&priorityScore=clicks`}
      height="470px"
      title={t('dashboard:content-to-optimize.content-title_interval', {
        count: pagesWithSpecificProperties.data.length,
        postProcess: 'interval',
      })}
    >
      <Table
        columns={columns}
        headerHeight={55}
        mode="transparent"
        rows={pagesWithSpecificProperties.data
          .sort((a, b) => (a.potentialScore - b.potentialScore > 0 ? -1 : 1))
          .slice(0, 5)}
        width="100%"
      />
    </Card>
  );
}

export default ContentsToOptimize;
