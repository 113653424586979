import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import apiClient from '@/apis/semji/apiClient';
import { SCOPE_PAGES } from '@/apis/semji/constants';
import { createQueryKeyFromFiltersAndProperties } from '@/apis/semji/helper';
import { QueryOptions, QueryResult } from '@/apis/types/query.types';
import { showErrorSnackbar } from '@/store/actions/ui';
import { Page } from '@/types/pages';

interface UseGetCurrentWorkspacePagesWithSpecificPropertiesProps extends QueryOptions<{}, Page[]> {
  filters: Record<string, any>;
  properties?: string[];
  location: string;
}

export default function useGetCurrentWorkspacePagesWithSpecificProperties({
  filters = {},
  properties = [],
  location,
  ...props
}: UseGetCurrentWorkspacePagesWithSpecificPropertiesProps): QueryResult<Page[]> {
  const { t } = useTranslation();
  const { get } = apiClient;
  const dispatch = useDispatch();
  const { workspaceId } = useParams();

  return useQuery({
    onError: () => {
      dispatch(showErrorSnackbar(t('common:error.default')));
    },
    placeholderData: [],

    queryFn: async ({ signal }) => {
      const result = await get(`/workspaces/${workspaceId}/pages`, {
        params: {
          ...filters,
          properties,
        },
        signal,
      });

      return result.data['hydra:member'] || [];
    },

    queryKey: [
      SCOPE_PAGES.WITH_SPECIFIC_PROPERTIES,
      workspaceId,
      // Location is no longer needed because properties are now passed as an argument
      //   location,
      ...createQueryKeyFromFiltersAndProperties({ filters, properties }),
    ],
    ...props,
  });
}
