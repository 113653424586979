import kebabCase from 'lodash/kebabCase';

import { ReportMetricCategory, ReportMetricKey } from '@/containers/Report/utils/types';

export function buildMetricFeatureSetFlag({
  metricKey,
  category,
  goalKey,
}: {
  metricKey: ReportMetricKey;
  category: ReportMetricCategory;
  goalKey?: string;
}) {
  if (
    metricKey === ReportMetricKey.AverageContentScore ||
    metricKey === ReportMetricKey.publicationsCount ||
    metricKey === ReportMetricKey.overview
  ) {
    // because no feature set is required for AverageContentScore and overview
    return `metric:${kebabCase(ReportMetricCategory.Performance)}:has-access-to-${kebabCase(ReportMetricKey.publicationsCount)}`;
  }
  if (metricKey === ReportMetricKey.Position) {
    return 'rank-tracking:is-enabled';
  }
  return goalKey
    ? 'pages:priority-score:has-access-to-conversions'
    : `metric:${kebabCase(category)}:has-access-to-${kebabCase(metricKey)}`;
}
