import {
  RESET_CREATE_WORKSPACE,
  SET_LOCATION,
  SET_ORGANIZATION_ID,
  SET_SERVICE_INTEGRATION,
  SET_USERS,
  SET_WEBSITE_URL,
  SET_WORKSPACE_NAME,
} from '../actions/actionTypes';

export const setWebsiteUrl = (websiteUrl, organizationId) => ({
  organizationId,
  type: SET_WEBSITE_URL,
  websiteUrl,
});

export const setLocation = (location, organizationId, isLocationDirty) => ({
  isLocationDirty,
  location,
  organizationId,
  type: SET_LOCATION,
});

export const setWorkspaceName = (name, organizationId, isNameDirty) => ({
  isNameDirty,
  name,
  organizationId,
  type: SET_WORKSPACE_NAME,
});

export const setServiceIntegration = (dataSources, organizationId) => ({
  dataSources,
  organizationId,
  type: SET_SERVICE_INTEGRATION,
});

export const setUsers = (users, organizationId) => ({
  organizationId,
  type: SET_USERS,
  users,
});

export const setOrganizationId = (organizationId) => ({
  organizationId,
  type: SET_ORGANIZATION_ID,
});

export const resetWorkspaceData = (organizationId) => ({
  organizationId,
  type: RESET_CREATE_WORKSPACE,
});
