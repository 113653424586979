import { DateTime } from 'luxon';

import { getDiffInDaysFromNow } from './date';

const SUBSCRIPTION_TYPES = {
  MANAGED: 'MANAGED',
  SELF_SERVE: 'SELF_SERVE',
  TRIAL: 'TRIAL',
};

export const SUBSCRIPTION_STATUS = {
  active: 'active',
  cancelled_subscription: 'cancelled_subscription',
  cancelled_trial: 'cancelled_trial',
  in_trial: 'in_trial',
  non_renewing: 'non_renewing',
};
export const ACTIVE__SUBSCRIPTION = 'ACTIVE__SUBSCRIPTION';
export const NON_RENEWING__SUBSCRIPTION = 'NON_RENEWING__SUBSCRIPTION';
export const IN_TRIAL__EXPIRES_MORE_5_DAYS = 'IN_TRIAL__EXPIRES_MORE_5_DAYS';
export const IN_TRIAL__EXPIRES_LESS_5_DAYS = 'IN_TRIAL__EXPIRES_LESS_5_DAYS';
export const CANCELLED_TRIAL__LESS_60_DAYS = 'CANCELLED_TRIAL__LESS_60_DAYS';
export const CANCELLED_TRIAL__MORE_60_DAYS = 'CANCELLED_TRIAL__MORE_60_DAYS';
export const CANCELLED_SUBSCRIPTION__MORE_60_SUBSCRIPTION =
  'CANCELLED_SUBSCRIPTION__MORE_60_SUBSCRIPTION';
export const CANCELLED_SUBSCRIPTION__LESS_60_SUBSCRIPTION =
  'CANCELLED_SUBSCRIPTION__LESS_60_SUBSCRIPTION';
export const PAYMENT_ERROR_AFTER_DUNNING__SUBSCRIPTION =
  'PAYMENT_ERROR_AFTER_DUNNING__SUBSCRIPTION';
export const PAYMENT_ERROR_DURING_DUNNING__SUBSCRIPTION =
  'PAYMENT_ERROR_DURING_DUNNING__SUBSCRIPTION';

export function planStatus({
  subscriptionStatus,
  subscriptionNextBillingAt,
  subscriptionTrialEndAt,
  subscriptionCurrentTermEndAt,
  paymentProblemOccurred,
  paymentDunningEndAt,
}) {
  const nextBillingAtDateTime = DateTime.fromISO(subscriptionNextBillingAt);
  const trialEndDateTime = DateTime.fromISO(subscriptionTrialEndAt);
  const currentTermEndAtDateTime = DateTime.fromISO(subscriptionCurrentTermEndAt);
  const paymentDunningEndAtDateTime = DateTime.fromISO(paymentDunningEndAt);
  const nowDateTime = DateTime.fromJSDate(new Date());
  const result = {
    status: null,
    subscriptionCurrentTermEndAtDiffDaysFromNow: currentTermEndAtDateTime.isValid
      ? getDiffInDaysFromNow(subscriptionCurrentTermEndAt)
      : null,
    subscriptionCurrentTermEndAtFormattedDate: currentTermEndAtDateTime.isValid
      ? currentTermEndAtDateTime.toFormat('dd LLL, yyyy')
      : null,
    subscriptionNextBillingAtDiffDaysFromNow: nextBillingAtDateTime.isValid
      ? getDiffInDaysFromNow(subscriptionNextBillingAt)
      : null,
    subscriptionNextBillingAtFormattedDate: nextBillingAtDateTime.isValid
      ? nextBillingAtDateTime.toFormat('dd LLL, yyyy')
      : null,
    subscriptionPaymentDunningEndAtDiffDaysFromNow: paymentDunningEndAtDateTime.isValid
      ? getDiffInDaysFromNow(paymentDunningEndAt)
      : null,
    subscriptionPaymentDunningEndAtFormattedDateTime: paymentDunningEndAtDateTime.isValid
      ? paymentDunningEndAtDateTime.toFormat('dd LLL, yyyy')
      : null,
    subscriptionTrialEndAtDiffDaysFromNow: trialEndDateTime.isValid
      ? getDiffInDaysFromNow(subscriptionTrialEndAt)
      : null,
    subscriptionTrialEndAtFormattedDate: trialEndDateTime.isValid
      ? trialEndDateTime.toFormat('dd LLL, yyyy')
      : null,
  };

  if (
    subscriptionStatus === SUBSCRIPTION_STATUS.cancelled_trial &&
    trialEndDateTime.isValid &&
    nowDateTime.diff(trialEndDateTime, 'days').toObject().days >= 60
  ) {
    result.status = CANCELLED_TRIAL__MORE_60_DAYS;
    return result;
  }
  if (
    subscriptionStatus === SUBSCRIPTION_STATUS.cancelled_subscription &&
    currentTermEndAtDateTime.isValid &&
    nowDateTime.diff(currentTermEndAtDateTime, 'days').toObject().days >= 60
  ) {
    result.status = CANCELLED_SUBSCRIPTION__MORE_60_SUBSCRIPTION;
    return result;
  }
  if (
    paymentProblemOccurred &&
    paymentDunningEndAtDateTime.isValid &&
    subscriptionStatus === SUBSCRIPTION_STATUS.cancelled_subscription
  ) {
    result.status = PAYMENT_ERROR_AFTER_DUNNING__SUBSCRIPTION;
    return result;
  }
  if (
    paymentProblemOccurred &&
    paymentDunningEndAtDateTime.isValid &&
    subscriptionStatus === SUBSCRIPTION_STATUS.active
  ) {
    result.status = PAYMENT_ERROR_DURING_DUNNING__SUBSCRIPTION;
    return result;
  }
  if (
    subscriptionStatus === SUBSCRIPTION_STATUS.cancelled_trial &&
    trialEndDateTime.isValid &&
    nowDateTime.diff(trialEndDateTime, 'days').toObject().days < 60
  ) {
    result.status = CANCELLED_TRIAL__LESS_60_DAYS;
    return result;
  }
  if (
    subscriptionStatus === SUBSCRIPTION_STATUS.cancelled_subscription &&
    currentTermEndAtDateTime.isValid &&
    nowDateTime.diff(currentTermEndAtDateTime, 'days').toObject().days < 60
  ) {
    result.status = CANCELLED_SUBSCRIPTION__LESS_60_SUBSCRIPTION;
    return result;
  }
  if (
    subscriptionStatus === SUBSCRIPTION_STATUS.active &&
    nextBillingAtDateTime.isValid &&
    nextBillingAtDateTime >= nowDateTime
  ) {
    result.status = ACTIVE__SUBSCRIPTION;
    return result;
  }
  if (
    subscriptionStatus === SUBSCRIPTION_STATUS.non_renewing &&
    currentTermEndAtDateTime.isValid &&
    currentTermEndAtDateTime >= nowDateTime
  ) {
    result.status = NON_RENEWING__SUBSCRIPTION;
    return result;
  }
  if (
    subscriptionStatus === SUBSCRIPTION_STATUS.in_trial &&
    trialEndDateTime.isValid &&
    trialEndDateTime.diff(nowDateTime, 'days').toObject().days >= 5
  ) {
    result.status = IN_TRIAL__EXPIRES_MORE_5_DAYS;
    return result;
  }
  if (
    subscriptionStatus === SUBSCRIPTION_STATUS.in_trial &&
    trialEndDateTime.isValid &&
    trialEndDateTime.diff(nowDateTime, 'days').toObject().days < 5 &&
    trialEndDateTime.diff(nowDateTime, 'days').toObject().days >= 0
  ) {
    result.status = IN_TRIAL__EXPIRES_LESS_5_DAYS;
    return result;
  }

  return result;
}

const getCurrentSubscriptionStatus = (currentOrganization) => {
  const {
    subscriptionStatus,
    subscriptionNextBillingAt,
    subscriptionTrialEndAt,
    subscriptionCurrentTermEndAt,
    paymentProblemOccurred,
    paymentDunningEndAt,
  } = currentOrganization;

  const { status } = planStatus({
    paymentDunningEndAt,
    paymentProblemOccurred,
    subscriptionCurrentTermEndAt,
    subscriptionNextBillingAt,
    subscriptionStatus,
    subscriptionTrialEndAt,
  });

  return status;
};

export const isRouteAccessGranted = (currentOrganization) => {
  const status = getCurrentSubscriptionStatus(currentOrganization);
  return (
    !isSelfServeOrganization(currentOrganization) ||
    ![
      CANCELLED_SUBSCRIPTION__MORE_60_SUBSCRIPTION,
      CANCELLED_SUBSCRIPTION__LESS_60_SUBSCRIPTION,
      CANCELLED_TRIAL__MORE_60_DAYS,
      CANCELLED_TRIAL__LESS_60_DAYS,
      PAYMENT_ERROR_AFTER_DUNNING__SUBSCRIPTION,
    ].includes(status)
  );
};

export const isDashboardRouteStillAccessible = (currentOrganization) => {
  const status = getCurrentSubscriptionStatus(currentOrganization);
  return (
    !isSelfServeOrganization(currentOrganization) ||
    ![CANCELLED_SUBSCRIPTION__MORE_60_SUBSCRIPTION, CANCELLED_TRIAL__MORE_60_DAYS].includes(status)
  );
};

export function isSelfServeOrganization(currentOrganization) {
  return [SUBSCRIPTION_TYPES.SELF_SERVE, SUBSCRIPTION_TYPES.TRIAL].includes(
    currentOrganization.subscriptionType
  );
}
