import { SPLIT_DATA_SYMBOL } from '../constants';

function getElementDataByAttribute({
  element,
  dataAttribute,
}: {
  element: HTMLElement;
  dataAttribute: string;
}): string[] {
  const data: string = element.dataset?.[dataAttribute] || '';

  return data.split(SPLIT_DATA_SYMBOL).filter(Boolean);
}

export default getElementDataByAttribute;
