import React from 'react';
import { useSelector } from 'react-redux';
import { getRoundedNumberObject } from 'semji-core/utils/numbers';

function NumberCell(props) {
  const userLanguageCode = useSelector((state) => state.user?.languageCode);

  let { number, prefix = '', suffix = '', max, min, decimalPlaces, ...otherProps } = props;

  if (max && number > max) {
    number = max;
    prefix = '> ';
  }

  if (min && number < min) {
    number = min;
    prefix = '< ';
  }

  const numberObject = getRoundedNumberObject({ decimalPlaces, locale: userLanguageCode, number });

  if (null !== numberObject.value) {
    return <div {...otherProps}>{prefix + numberObject.value + numberObject.suffix + suffix}</div>;
  }
  return <div {...otherProps}>{prefix + numberObject.initialValue + suffix}</div>;
}

export default NumberCell;
