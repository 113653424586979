export default function TopPagesIcon() {
  return (
    <svg
      fill="none"
      height="102"
      viewBox="0 0 147 102"
      width="147"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M145.515 93.9627C145.515 98.4086 114.825 101.985 77.0154 101.985C39.2059 101.985 8.51541 98.4086 8.51541 93.9627C8.51541 89.5168 39.2059 90 77.0154 90C114.825 90 145.515 89.5168 145.515 93.9627Z"
        fill="#FFEBED"
      />
      <rect fill="#FFEBED" height="7" rx="3.5" width="15" x="19" y="43.0283" />
      <rect fill="#FFEBED" height="7" rx="3.5" width="13" x="17" y="15.0283" />
      <rect fill="#FFEBED" height="7" rx="3.5" width="8" y="28.0283" />
      <rect fill="#FFEBED" height="7" rx="3.5" width="20" x="12" y="28.0283" />
      <rect
        fill="#FFEBED"
        height="7"
        rx="3.5"
        transform="rotate(-180 134 48)"
        width="15"
        x="134"
        y="48"
      />
      <rect
        fill="#FFEBED"
        height="7"
        rx="3.5"
        transform="rotate(-180 118 73)"
        width="13"
        x="118"
        y="73"
      />
      <rect
        fill="#FFEBED"
        height="7"
        rx="3.5"
        transform="rotate(-180 147 61)"
        width="8"
        x="147"
        y="61"
      />
      <rect
        fill="#FFEBED"
        height="7"
        rx="3.5"
        transform="rotate(-180 135 61)"
        width="20"
        x="135"
        y="61"
      />
      <mask fill="white" id="path-10-inside-1_7077_84386">
        <path d="M44.2268 0H109.514C110.81 0 111.944 1.13426 112.268 2.26828V3.72616V5.18403H121.988C123.284 5.18403 124.418 6.31805 124.418 7.61408V9.07234C124.418 15.0665 123.933 21.2225 123.285 27.2166C122.312 35.1548 118.424 61.2374 107.408 66.5835C106.274 67.2315 104.978 67.5553 103.682 67.5553C102.224 67.5553 100.766 67.0696 99.3079 66.2596L98.984 66.0974C94.2859 73.0636 88.1298 76.9515 81.0017 78.0855V86.6718H95.0959C96.3919 86.6718 97.5259 87.8058 97.5259 89.1018V92.018C97.5259 93.314 96.3919 94.448 95.0959 94.448H59.6171C58.3211 94.448 57.1871 93.314 57.1871 92.018V89.1018C57.1871 87.8058 58.3211 86.6718 59.6171 86.6718H73.2254V78.0855C65.9353 77.1135 59.7792 73.0637 55.0811 66.2596L54.9192 66.4214C53.4611 67.2314 52.003 67.7175 50.383 67.7175C49.087 67.7175 47.7909 67.3933 46.6568 66.7453C35.6406 61.3992 31.7526 35.317 30.7806 27.3788C29.9706 21.2227 29.6465 15.2282 29.6465 9.23411V7.77624C29.6465 6.48021 30.7805 5.34619 32.0765 5.34619H41.7967V3.88832V2.43005C41.7967 1.13402 42.9308 0 44.2268 0Z" />
      </mask>
      <path
        d="M44.2268 0H109.514C110.81 0 111.944 1.13426 112.268 2.26828V3.72616V5.18403H121.988C123.284 5.18403 124.418 6.31805 124.418 7.61408V9.07234C124.418 15.0665 123.933 21.2225 123.285 27.2166C122.312 35.1548 118.424 61.2374 107.408 66.5835C106.274 67.2315 104.978 67.5553 103.682 67.5553C102.224 67.5553 100.766 67.0696 99.3079 66.2596L98.984 66.0974C94.2859 73.0636 88.1298 76.9515 81.0017 78.0855V86.6718H95.0959C96.3919 86.6718 97.5259 87.8058 97.5259 89.1018V92.018C97.5259 93.314 96.3919 94.448 95.0959 94.448H59.6171C58.3211 94.448 57.1871 93.314 57.1871 92.018V89.1018C57.1871 87.8058 58.3211 86.6718 59.6171 86.6718H73.2254V78.0855C65.9353 77.1135 59.7792 73.0637 55.0811 66.2596L54.9192 66.4214C53.4611 67.2314 52.003 67.7175 50.383 67.7175C49.087 67.7175 47.7909 67.3933 46.6568 66.7453C35.6406 61.3992 31.7526 35.317 30.7806 27.3788C29.9706 21.2227 29.6465 15.2282 29.6465 9.23411V7.77624C29.6465 6.48021 30.7805 5.34619 32.0765 5.34619H41.7967V3.88832V2.43005C41.7967 1.13402 42.9308 0 44.2268 0Z"
        mask="url(#path-10-inside-1_7077_84386)"
        stroke="#51525E"
        strokeWidth="4"
      />
      <mask fill="white" id="path-11-inside-2_7077_84386">
        <path d="M104.654 61.0752C104.168 61.2372 103.844 61.3995 103.52 61.3995C103.196 61.3995 101.414 60.5894 101.252 60.4274L100.604 59.9413C106.436 48.7631 109.514 33.0488 110.162 11.8264V11.5024H117.938V11.9885C117.938 16.8486 117.614 21.7087 116.966 26.5688C114.698 46.3332 109.028 58.9692 104.654 61.0752Z" />
      </mask>
      <path
        d="M104.654 61.0752C104.168 61.2372 103.844 61.3995 103.52 61.3995C103.196 61.3995 101.414 60.5894 101.252 60.4274L100.604 59.9413C106.436 48.7631 109.514 33.0488 110.162 11.8264V11.5024H117.938V11.9885C117.938 16.8486 117.614 21.7087 116.966 26.5688C114.698 46.3332 109.028 58.9692 104.654 61.0752Z"
        mask="url(#path-11-inside-2_7077_84386)"
        stroke="#51525E"
        strokeWidth="4"
      />
      <mask fill="white" id="path-12-inside-3_7077_84386">
        <path d="M52.6862 59.9533C52.6862 59.9533 50.9043 60.9254 50.5803 60.9254C50.2563 60.9254 49.9322 60.9251 49.2842 60.6011C45.0721 58.4951 39.402 45.8591 36.972 26.0947C36.486 21.2346 36.162 16.3745 36 11.5144V11.0283H43.776V11.3522C44.2621 25.7705 46.2061 45.5349 53.3343 59.4672L52.6862 59.9533Z" />
      </mask>
      <path
        d="M52.6862 59.9533C52.6862 59.9533 50.9043 60.9254 50.5803 60.9254C50.2563 60.9254 49.9322 60.9251 49.2842 60.6011C45.0721 58.4951 39.402 45.8591 36.972 26.0947C36.486 21.2346 36.162 16.3745 36 11.5144V11.0283H43.776V11.3522C44.2621 25.7705 46.2061 45.5349 53.3343 59.4672L52.6862 59.9533Z"
        mask="url(#path-12-inside-3_7077_84386)"
        stroke="#51525E"
        strokeWidth="4"
      />
      <mask fill="white" id="path-13-inside-4_7077_84386">
        <path d="M105.788 6.64234C105.626 31.1048 101.414 72.2537 76.9517 72.2537C52.4892 72.2537 48.277 31.1048 48.115 6.64234V6.15625H105.788V6.64234Z" />
      </mask>
      <path
        d="M105.788 6.64234C105.626 31.1048 101.414 72.2537 76.9517 72.2537C52.4892 72.2537 48.277 31.1048 48.115 6.64234V6.15625H105.788V6.64234Z"
        mask="url(#path-13-inside-4_7077_84386)"
        stroke="#51525E"
        strokeWidth="4"
      />
      <path
        d="M94 20.2795C93.9045 34.3432 91.4214 58 77.0001 58C62.5787 58 60.0955 34.3432 60 20.2795C60 20.1251 60.1251 20 60.2795 20H93.7205C93.8749 20 94 20.1251 94 20.2795Z"
        fill="#FFCCD3"
      />
      <path
        d="M87.2 38.7L78.3 29.8V49.1H76.9V29.8L68 38.7L67 37.7L77.7 27L88.4 37.7L87.2 38.7Z"
        fill="#51525E"
      />
    </svg>
  );
}
