import './DashboardPages.scss';

import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import NoDraft, {
  NO_NEW_DRAFT_DASHBOARD,
  NO_UPDATED_DRAFT_DASHBOARD,
} from '@/components/EmptyState/NoDraft';
import NoServiceIntegrationCurve, {
  NO_ANALYTICS_DASHBOARD,
  NO_SEARCH_CONSOLE_DASHBOARD,
} from '@/components/EmptyState/NoServiceIntegrationCurve';
import ContentScoreCell from '@/components/Table/Cell/ContentScoreCell';
import NotAcknowledgedCell from '@/components/Table/Cell/NotAcknowledgedCell';
import PictureCell from '@/components/Table/Cell/PictureCell';
import TitleCell from '@/components/Table/Cell/TitleCell';
import Table from '@/components/Table/Table';
import DashboardReportCard from '@/containers/Dashboard/DashboardReportCard';
import { MetricCell, metricDataGetter } from '@/containers/Report/components/PagesColumns/Metric';
import { TrendCell } from '@/containers/Report/components/PagesColumns/Trend';
import { ReportQueryFiltersOperator } from '@/containers/Report/hooks';
import usePagesWithMetrics from '@/containers/Report/hooks/usePagesWithMetrics';
import { PageWithMetrics, ReportMetricKey, ReportView } from '@/containers/Report/utils/types';
import { useMetricsConfig } from '@/hooks/useMetricsConfig';
import { selectHasAnalytics } from '@/store/selectors/selectHasAnalytics';
import { selectHasSearchConsole } from '@/store/selectors/selectHasSearchConsole';
import { selectUserCurrentWorkspaceRoles } from '@/store/selectors/selectUserCurrentWorkspaceRoles';
import { Page } from '@/types/pages';
import { ReportPeriod, ReportPeriodicity } from '@/types/reports';
import { API_WORKSPACE_ROLE_OWNER } from '@/utils/can/constants';
import { FROM_ORIGIN } from '@/utils/constants';
import filterPages from '@/utils/filter/filterPages';
import {
  ANALYTICS_DEPENDENCY,
  FOCUS_TOP_KEYWORD_POSITION,
  METRIC_POSITION_KEY,
  SEARCH_CONSOLE_DEPENDENCY,
} from '@/utils/metrics/constants';
import { getComparisonDates } from '@/utils/metrics/getComparisonDates';

const TOP = 5;

const topPagesByMetricKey = (
  pages: PageWithMetrics[],
  metricKey: ReportMetricKey,
  currentMonthDate: string,
  top = TOP
) => {
  const isPositionMetric = metricKey === METRIC_POSITION_KEY;
  const currentMetricKey = isPositionMetric ? FOCUS_TOP_KEYWORD_POSITION : metricKey;
  const filteredPages = pages.filter((page) => {
    if (isPositionMetric) {
      return !!page.monthlyPageMetrics[currentMonthDate]?.[currentMetricKey];
    } else {
      return true;
    }
  });

  const sortedPages = filteredPages.sort((a, b) => {
    const value1 = a.monthlyPageMetrics[currentMonthDate]?.[currentMetricKey] || 0;
    const value2 = b.monthlyPageMetrics[currentMonthDate]?.[currentMetricKey] || 0;

    return isPositionMetric ? value1 - value2 : value2 - value1;
  });

  return sortedPages.slice(0, Math.min(top, sortedPages.length));
};

export default function DashboardPages({
  period,
  comparisonPeriod,
  periodicity,
  reportView,
  loading,
  rankTrackingFeatSetFlag,
}: {
  period: ReportPeriod;
  comparisonPeriod: string;
  pages: Page[];
  periodicity: ReportPeriodicity;
  reportView: ReportView;
  loading: boolean;
  rankTrackingFeatSetFlag: boolean;
}) {
  const { t } = useTranslation();
  const { organizationId, workspaceId } = useParams();
  const [currentMetricKey, setCurrentMetricKey] = useState<ReportMetricKey>(
    rankTrackingFeatSetFlag ? ReportMetricKey.Position : ReportMetricKey.Clicks
  );
  const metrics = useMetricsConfig({ isNew: true });
  const hasAnalytics = useSelector(selectHasAnalytics);
  const hasSearchConsole = useSelector(selectHasSearchConsole);
  const userRole = useSelector(selectUserCurrentWorkspaceRoles);
  const { pages, isLoading: isPagesLoading } = usePagesWithMetrics({
    comparisonPeriod,
    period,
  });

  const AllFilteredPages: PageWithMetrics[] = filterPages(pages, {
    filterGroups: [],
    operator: ReportQueryFiltersOperator.AND,
  });
  function getCurrentFilteredPages() {
    if (reportView === ReportView.New) {
      return AllFilteredPages.filter((page) => page.new && page?.extra?.lastPublishedAt);
    }
    if (reportView === ReportView.Existing) {
      return AllFilteredPages.filter((page) => !page.new && page?.extra?.lastPublishedAt);
    }
    return AllFilteredPages;
  }

  const filteredPages = getCurrentFilteredPages();

  useEffect(() => {
    setCurrentMetricKey(rankTrackingFeatSetFlag ? METRIC_POSITION_KEY : 'clicks');
  }, [rankTrackingFeatSetFlag]);

  const isOwner = userRole.includes(API_WORKSPACE_ROLE_OWNER);
  const isAnalyticsEmptyState =
    !hasAnalytics && metrics[currentMetricKey]?.dependency === ANALYTICS_DEPENDENCY;
  const isSearchConsoleEmptyState =
    !hasSearchConsole && metrics[currentMetricKey]?.dependency === SEARCH_CONSOLE_DEPENDENCY;
  const noServiceIntegrationVersion = isAnalyticsEmptyState
    ? NO_ANALYTICS_DASHBOARD
    : isSearchConsoleEmptyState
      ? NO_SEARCH_CONSOLE_DASHBOARD
      : null;
  const metricsList = Object.values(metrics).filter(
    (metric) =>
      ![
        ReportMetricKey.overview,
        ReportMetricKey.publicationsCount,
        ReportMetricKey.AverageContentScore,
      ].includes(metric.key as ReportMetricKey)
  );
  metricsList.push(metrics[ReportMetricKey.AverageContentScore]);

  const buildColumns = useCallback(
    (currentMonthDate: string) => {
      return [
        {
          cellDataGetter: ({ rowData }) => rowData.optimizedImages.small,
          cellRenderer: ({ cellData }) => <PictureCell pictureUrl={cellData} />,
          dataKey: 'imageUrl',
          label: 'picture',
          width: 40,
        },
        {
          cellDataGetter: ({ rowData }) => ({
            isRedirectionDetected: !!rowData.redirectionUrl,
            lastStatusCode: rowData.lastStatusCode,
            pageId: rowData.id,
            title: rowData.title,
            url: rowData.url,
            workspaceId: workspaceId,
          }),
          cellRenderer: ({ cellData }) => (
            <TitleCell
              isRedirectionDetected={cellData.isRedirectionDetected}
              lastStatusCode={cellData.lastStatusCode}
              organizationId={organizationId}
              pageId={cellData.pageId}
              title={cellData.title}
              type={FROM_ORIGIN.REPORT}
              url={cellData.url}
              workspaceId={cellData.workspaceId}
            />
          ),
          dataKey: 'title',
          flexGrow: 1,
          label: 'title',
          sortable: false,
          width: 100,
        },
        {
          align: 'center',
          cellDataGetter: ({ rowData }) => rowData.contentScore,
          cellRenderer: ({ cellData }) =>
            cellData !== null ? (
              <ContentScoreCell contentScore={cellData} />
            ) : (
              <NotAcknowledgedCell align="center" />
            ),
          dataKey: 'contentScore',
          label: 'contentScore',
          sortable: false,
          width: 80,
        },
        {
          align: 'center',
          cellDataGetter: ({ rowData }) => rowData.id,
          cellRenderer: ({ cellData }) => (
            <TrendCell
              metric={metrics[currentMetricKey]}
              pageId={cellData}
              period={period}
              periodicity={periodicity}
            />
          ),
          dataKey: 'pageMetrics',
          label: 'trend',
          sortable: false,
          width: 150,
        },
        {
          align: 'center',
          cellDataGetter: ({ rowData }) =>
            metricDataGetter({
              date: currentMonthDate,
              metricKey:
                currentMetricKey === METRIC_POSITION_KEY
                  ? FOCUS_TOP_KEYWORD_POSITION
                  : currentMetricKey,
              metricType: metrics[currentMetricKey].type,
              rowData,
            }),
          cellRenderer: ({ cellData }) => {
            const { icon, suffix } = metrics[currentMetricKey];
            return (
              <MetricCell
                icon={icon}
                isLoading={isPagesLoading}
                suffix={suffix}
                type={cellData.metricType}
                value={cellData.value}
              />
            );
          },
          dataKey: currentMonthDate + '_' + currentMetricKey,
          label: 'metric',
          sortable: false,
          width: 80,
        },
      ];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentMetricKey, organizationId, period, periodicity, isPagesLoading]
  );

  const comparisonDates = getComparisonDates({ comparisonPeriod, period });
  const columns = buildColumns(String(comparisonDates.currentRange.after));
  const topPages = topPagesByMetricKey(
    filteredPages,
    currentMetricKey,
    String(comparisonDates.currentRange.after)
  );

  function selectMetricClick(currentMetricKey: ReportMetricKey) {
    setCurrentMetricKey(currentMetricKey);
  }

  const isLoading = loading || isPagesLoading;

  return (
    <DashboardReportCard
      currentMetricKey={currentMetricKey}
      isLoading={isLoading}
      reportView={reportView}
      setCurrentMetricKey={selectMetricClick}
      showFooter={!isLoading && topPages.length > 0 && !noServiceIntegrationVersion}
      title={
        reportView === 'new'
          ? t('dashboard:dashboard-pages.top-new-content', {
              count: TOP,
            })
          : t('dashboard:dashboard-pages.top-updated-content', {
              count: TOP,
            })
      }
    >
      <div className="page-dashboard-pages-card__content">
        {noServiceIntegrationVersion ? (
          <div className="page-dashboard-pages-card__empty-state">
            <NoServiceIntegrationCurve isOwner={isOwner} version={noServiceIntegrationVersion} />
          </div>
        ) : topPages.length === 0 ? (
          <div className="page-dashboard-pages-card__empty-state">
            <NoDraft
              version={reportView === 'new' ? NO_NEW_DRAFT_DASHBOARD : NO_UPDATED_DRAFT_DASHBOARD}
            />
          </div>
        ) : (
          <Table columns={columns} disableHeader mode="transparent" rows={topPages} width="100%" />
        )}
      </div>
    </DashboardReportCard>
  );
}
