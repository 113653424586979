import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const Box = styled.div`
  background: ${({ background }) => background};
  background-color: ${({ theme, backgroundColorWithoutTheme, backgroundColor }) =>
    backgroundColorWithoutTheme || theme.cssColors[backgroundColor]};
  color: ${({ theme, colorWithoutTheme, color }) => colorWithoutTheme || theme.cssColors[color]};
  cursor: ${({ cursor }) => cursor};
  display: ${({ display }) => display};
  gap: ${({ gap }) => gap};
  height: ${({ height }) => height};
  margin: ${({ margin }) => margin};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  margin-left: ${({ marginLeft }) => marginLeft};
  margin-right: ${({ marginRight }) => marginRight};
  margin-top: ${({ marginTop }) => marginTop};
  max-height: ${({ maxHeight }) => maxHeight};
  max-width: ${({ maxWidth }) => maxWidth};
  min-height: ${({ minHeight }) => minHeight};
  min-width: ${({ minWidth }) => minWidth};
  overflow: ${({ overflow }) => overflow};
  padding: ${({ padding }) => padding};
  padding-bottom: ${({ paddingBottom }) => paddingBottom};
  padding-left: ${({ paddingLeft }) => paddingLeft};
  padding-right: ${({ paddingRight }) => paddingRight};
  padding-top: ${({ paddingTop }) => paddingTop};
  position: ${({ position }) => position};
  visibility: ${({ visibility }) => visibility};
  width: ${({ width }) => width};
  opacity: ${({ opacity }) => opacity};
  border-radius: ${({ borderRadius }) => borderRadius};
  pointer-events: ${({ pointerEvents }) => pointerEvents};
  box-shadow: ${({ boxShadow }) => boxShadow};
  white-space: ${({ whiteSpace }) => whiteSpace};
  border: ${({ border }) => border};
  border-top: ${({ borderTop }) => borderTop};
  border-right: ${({ borderRight }) => borderRight};
  border-bottom: ${({ borderBottom }) => borderBottom};
  border-left: ${({ borderLeft }) => borderLeft};

  top: ${({ top }) => top};
  left: ${({ left }) => left};
  right: ${({ right }) => right};
  bottom: ${({ bottom }) => bottom};

  /* Flex Child */
  align-self: ${({ alignSelf }) => alignSelf};
  flex: ${({ flex }) => flex};
  flex-grow: ${({ flexGrow }) => flexGrow};
  flex-shrink: ${({ flexShrink }) => flexShrink};
  flex-basis: ${({ flexBasis }) => flexBasis};
  order: ${({ order }) => order};

  /* Grid Child */
  justify-self: ${({ justifySelf }) => justifySelf};
`;

Box.propTypes = {
  alignSelf: PropTypes.string,
  background: PropTypes.string,
  backgroundColor: PropTypes.string,
  border: PropTypes.string,
  borderBottom: PropTypes.string,
  borderLeft: PropTypes.string,
  borderRadius: PropTypes.string,
  borderRight: PropTypes.string,
  borderTop: PropTypes.string,
  bottom: PropTypes.string,
  boxShadow: PropTypes.string,
  color: PropTypes.string,
  cursor: PropTypes.string,
  display: PropTypes.string,
  flex: PropTypes.string,
  flexBasis: PropTypes.string,
  flexGrow: PropTypes.string,
  flexShrink: PropTypes.string,
  height: PropTypes.string,
  left: PropTypes.string,
  margin: PropTypes.string,
  marginBottom: PropTypes.string,
  marginLeft: PropTypes.string,
  marginRight: PropTypes.string,
  marginTop: PropTypes.string,
  maxHeight: PropTypes.string,
  maxWidth: PropTypes.string,
  minHeight: PropTypes.string,
  minWidth: PropTypes.string,
  opacity: PropTypes.string,
  order: PropTypes.string,
  overflow: PropTypes.string,
  padding: PropTypes.string,
  paddingBottom: PropTypes.string,
  paddingLeft: PropTypes.string,
  paddingRight: PropTypes.string,
  paddingTop: PropTypes.string,
  pointerEvents: PropTypes.string,
  position: PropTypes.string,
  right: PropTypes.string,
  top: PropTypes.string,
  visibility: PropTypes.string,
  whiteSpace: PropTypes.string,
  width: PropTypes.string,
};

export default Box;
