import React from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import FilterGroups from '@/components/Filters/FilterGroups';
import { FILTERS_LIST } from '@/components/Pages/ContentIdeas/Listing/constant';
import useMapUrlQueryToFilter from '@/hooks/useMapUrlQueryToFilter';
import mapFiltersToQueryParameter from '@/utils/filter/mapFiltersToQueryParameter';

export default function Filters() {
  const navigate = useNavigate();
  const [query, setQuery] = useSearchParams();
  const location = useLocation();
  const filters = useMapUrlQueryToFilter({ filterList: FILTERS_LIST });

  function applyFiltersToUrl(filters = {}) {
    mapFiltersToQueryParameter({ filters, location, navigate, query, setQuery });
  }

  return (
    <FilterGroups
      applyFilters={applyFiltersToUrl}
      defaultFilters={filters}
      filtersList={FILTERS_LIST}
    />
  );
}
