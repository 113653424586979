import Dialog from '@material-ui/core/Dialog';
import MenuItem from '@material-ui/core/MenuItem';
import MuiSelect from '@material-ui/core/Select';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import { LOCATION, SCOPE_SERVICE_INTEGRATIONS } from '@/apis/semji/constants';
import useGetCustomGoals from '@/apis/semji/serviceIntegrations/useGetCustomGoals';
import usePutServiceIntegration from '@/apis/semji/serviceIntegrations/usePutServiceIntegration';
import { SecondaryButton } from '@/components/Button/Button';
import CaretIcon from '@/components/icons/CaretIcon';
import CloseIcon from '@/components/icons/CloseIcon';
import FlatLoader, { LoaderWrapper } from '@/components/Loader/FlatLoader';
import PrimaryButton from '@/design-system/components/Button/PrimaryButton';
import Flex from '@/design-system/components/Flex';
import { SERVICES_LIST, TYPE_ANALYTICS } from '@/utils/constants';

const Wrapper = styled(Flex)`
  padding: 32px;
`;
const Title = styled.span`
  font-size: ${({ theme }) => theme.textCss.sizes.lg};
  line-height: ${({ theme }) => theme.textCss.sizes.xxl};
`;
const SubTitle = styled.span`
  color: ${({ theme }) => theme.cssColors.dark060};
  line-height: ${({ theme }) => theme.textCss.sizes.lg};
`;
const Select = styled(MuiSelect)`
  && {
    color: ${({ value, theme }) => !value && theme.cssColors.dark040};

    & > div {
      padding: 10px 0 10px;
    }
  }
`;
const PlaceholderMenuItem = styled(MenuItem)`
  && {
    color: ${({ theme }) => theme.cssColors.dark060};
  }
`;
const StyledPrimaryButton = styled(PrimaryButton)`
  && {
    font-size: ${({ theme }) => theme.textCss.sizes.sm};
    font-weight: ${({ theme }) => theme.textCss.sizes.strong};
  }
`;
const StyledSecondaryButton = styled(SecondaryButton)`
  && {
    font-size: ${({ theme }) => theme.textCss.sizes.sm};
    color: ${({ theme }) => theme.cssColors.dark060};
    font-weight: ${({ theme }) => theme.textCss.weights.strong};
  }
`;
const ButtonWrapper = styled(Flex)`
  margin-top: 10px;
`;
const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`;

function CustomGoalsDialog({ onClose }) {
  const { workspaceId } = useParams();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const currentAnalyticsServiceIntegration = queryClient
    .getQueryData([SCOPE_SERVICE_INTEGRATIONS.GET_SERVICE_INTEGRATIONS, workspaceId, LOCATION.ROOT])
    ?.find((service) => service.type === TYPE_ANALYTICS);

  const currentService = Object.values(SERVICES_LIST).find((service) =>
    service.values.includes(currentAnalyticsServiceIntegration?.name)
  );

  const [customGoals, setCustomGoals] = useState({
    goal1: currentAnalyticsServiceIntegration?.data?.custom_goals?.goal1 || null,
    goal2: currentAnalyticsServiceIntegration?.data?.custom_goals?.goal2 || null,
    goal3: currentAnalyticsServiceIntegration?.data?.custom_goals?.goal3 || null,
  });

  const getCustomGoals = useGetCustomGoals();
  const putServiceIntegration = usePutServiceIntegration({
    onSuccess: () => {
      queryClient.refetchQueries({
        active: true,
        exact: true,
        fetching: false,
        queryKey: [SCOPE_SERVICE_INTEGRATIONS.GET_SERVICE_INTEGRATIONS, workspaceId, LOCATION.ROOT],
      });
      onClose();
    },
  });

  function handleChangeGoal(goalKey) {
    return (e) => {
      setCustomGoals({
        ...customGoals,
        [goalKey]: getCustomGoals.data.find((goal) => goal.id === e.target.value),
      });
    };
  }

  function handleConfirm() {
    putServiceIntegration.mutate({
      data: { ...currentAnalyticsServiceIntegration?.data, custom_goals: customGoals },
      id: currentAnalyticsServiceIntegration?.id,
    });
  }

  useEffect(() => {
    return () => {
      getCustomGoals.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog fullWidth open={true} onClose={onClose}>
      <Wrapper flexDirection="column" gap="20px">
        <Flex alignItems="flex-start" justifyContent="space-between">
          <img
            alt={t('service-integration:custom-goals.alt-logo')}
            src={currentService?.logo}
            style={currentService?.logoStyle}
          />
          <StyledCloseIcon onClick={onClose} />
        </Flex>
        <Flex flexDirection="column" gap="5px">
          <Title>
            {t('service-integration:custom-goals.title', {
              serviceIntegrationName: currentService?.title,
            })}
          </Title>
          <SubTitle>{t('service-integration:custom-goals.sub-title')}</SubTitle>
        </Flex>
        <Flex flexDirection="column" gap="25px">
          {Object.keys(customGoals).map((key) =>
            getCustomGoals.isLoading ? (
              <LoaderWrapper height={39}>
                <FlatLoader />
              </LoaderWrapper>
            ) : (
              <Select
                key={key}
                displayEmpty
                IconComponent={CaretIcon}
                value={customGoals[key]?.id}
                onChange={handleChangeGoal(key)}
              >
                {[
                  <PlaceholderMenuItem key="placeholder" value={undefined}>
                    {t('service-integration:custom-goals.select-placeholder')}
                  </PlaceholderMenuItem>,
                  ...getCustomGoals.data.map(({ id, name }) => (
                    <MenuItem
                      key={id}
                      disabled={Object.values(customGoals).some((value) => value?.id === id)}
                      value={id}
                    >
                      {name}
                    </MenuItem>
                  )),
                ]}
              </Select>
            )
          )}
        </Flex>
        <ButtonWrapper gap="10px" justifyContent="flex-end">
          <StyledSecondaryButton disabled={putServiceIntegration.isLoading} onClick={onClose}>
            {t('service-integration:custom-goals.cancel')}
          </StyledSecondaryButton>
          <StyledPrimaryButton
            disabled={putServiceIntegration.isLoading}
            noLetterSpacing
            onClick={handleConfirm}
          >
            {t('service-integration:custom-goals.save')}
          </StyledPrimaryButton>
        </ButtonWrapper>
      </Wrapper>
    </Dialog>
  );
}

export default CustomGoalsDialog;
