import './WorkspaceUnitLimitationModal.scss';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { WrapperModal } from 'semji-core/components/WrapperModal';

import useGetOrganizationAccessesById from '@/apis/semji/organizationAccesses/useGetOrganizationAccessesById';
import UnlockImage from '@/assets/images/unlock-image.svg';
import ExpandableUserList from '@/components/ExpandableUserList';
import { API_ORGANIZATION_ROLE_ADMIN } from '@/utils/can/constants';

export function WorkspaceUnitLimitationModal({
  setShowWorkspaceUnitLimitationModal,
  showWorkspaceUnitLimitationModal,
}) {
  const { t } = useTranslation();
  const { organizationId } = useParams();

  const title = t('billing:credit-limitation.limit-modal.title');
  const description = t('billing:credit-limitation.limit-modal.content');

  const { data: accesses = [] } = useGetOrganizationAccessesById(organizationId);

  const organizationAdmins =
    accesses
      .filter((item) => item.roles.includes(API_ORGANIZATION_ROLE_ADMIN))
      .map((oa) => oa.user) ?? [];

  function onClose() {
    setShowWorkspaceUnitLimitationModal(false);
  }

  return (
    <div className="workspace-unit-limitation-modal">
      <WrapperModal
        buttonLabel={t('components:dialog.dialog-information-template.button-text')}
        description={description}
        illustration={<img alt="" src={UnlockImage} />}
        isOpen={showWorkspaceUnitLimitationModal}
        title={title}
        onClose={onClose}
      >
        <ExpandableUserList
          emailBodyKey="workspace:default-workspace.credit-limit-reached.email.body"
          emailSubjectKey="workspace:default-workspace.credit-limit-reached.email.subject"
          users={organizationAdmins}
        />
      </WrapperModal>
    </div>
  );
}
