import './ContentVersionLayout.scss';

import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { DialogModal, ENUM_DIALOG_MODAL_SIZE } from 'semji-core/components/DialogModal';

import { LOCATION } from '@/apis/semji/constants';
import useGetContentsByPage from '@/apis/semji/contents/useGetContentsByPage';
import useGetContentVersions from '@/apis/semji/contents/useGetContentVersions';
import useGetCurrentWorkspaceContentsStatuses from '@/apis/semji/contents/useGetCurrentWorkspaceContentsStatuses';
import usePostNewContent from '@/apis/semji/contents/usePostNewContent';
import usePutContent from '@/apis/semji/contents/usePutContent';
import useGetWorkspaceUsers from '@/apis/semji/users/useGetWorkspaceUsers';
import { Header } from '@/containers/ContentVersion/components/Header';
import VersionHistoryPanel from '@/containers/ContentVersion/components/VersionHistoryPanel';
import useCreateNewDraft from '@/containers/ContentVersion/hooks/useCreateNewDraft';
import useGetVersionData from '@/containers/ContentVersion/hooks/useGetVersionData';
import { buildVersions } from '@/containers/ContentVersion/utils/helpers';
import { FETCH_PAGE_CONTENTS_FAIL, FETCH_PAGE_CONTENTS_SUCCESS } from '@/store/actions/actionTypes';
import { showErrorSnackbar, showSuccessSnackbar } from '@/store/actions/ui';
import { Content, ENUM_CONTENT_TYPE } from '@/types/contents';

export default function VersionLayout() {
  const { contentId, versionId, pageId, organizationId, workspaceId } = useParams();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  const selectedVersionId = versionId || contentId;
  // because editor use redux, we need to dispatch the action to update the content
  const dispatch = useDispatch();

  const {
    data: contents = [],
    isLoading: isContentsLoading,
    isFetched: isContentsFetched,
    remove: removeContents,
  } = useGetContentsByPage({
    onError: () => {
      dispatch(showErrorSnackbar(t('common:error.default')));
      dispatch({
        type: FETCH_PAGE_CONTENTS_FAIL,
      });
    },
    onSuccess: (data) => {
      dispatch({
        payload: {
          pageContents: data,
        },
        type: FETCH_PAGE_CONTENTS_SUCCESS,
      });
    },
    pageId: String(pageId),
  });
  const { data: users = [], isLoading: isUsersLoading } = useGetWorkspaceUsers({});
  const { data: contentsStatus = [], isLoading: isContentsStatusLoading } =
    useGetCurrentWorkspaceContentsStatuses({
      location: LOCATION.EDITOR,
      refetchOnWindowFocus: 'always',
    });
  const currentDraft = contents.find((content) => content.type === ENUM_CONTENT_TYPE.DRAFT);
  const currentDraftStatusId = currentDraft?.contentStatusId || contentsStatus[0]?.id;

  const {
    data: contentVersions = [],
    isLoading: isContentVersionLoading,
    isFetched: isContentVersionFetched,
    remove: removeContentVersion,
  } = useGetContentVersions({
    contentId: String(currentDraft?.id),
    enabled: !!currentDraft,
  });
  const { createNewDraft, isCreating } = useCreateNewDraft();

  const currentVersionData = useGetVersionData({
    contentId: contentId as string,
    versionId: versionId as string,
  });

  function handleMutationError() {
    dispatch(showErrorSnackbar(t('common:error.default')));
  }
  function handleMutationFinish() {
    setIsModalOpen(false);
  }
  function handleMutationSuccess(result: { data: Content }) {
    navigate(`/o/${organizationId}/w/${workspaceId}/p/${pageId}/content/${result.data.id}`);
    dispatch(
      showSuccessSnackbar(
        t('content:version-history-panel.restore-success', {
          date: currentVersion?.longDisplayDate,
        })
      )
    );
  }
  const postNewContent = usePostNewContent({
    onError: handleMutationError,
    onSettled: handleMutationFinish,
    onSuccess: handleMutationSuccess,
  });
  const putContent = usePutContent({
    contentId: currentDraft?.id,
    onError: handleMutationError,
    onSettled: handleMutationFinish,
    onSuccess: handleMutationSuccess,
  });

  useEffect(() => {
    // redirect to current draft if no contentId is provided and there is a draft
    if (isContentsFetched && isContentVersionFetched && !contentId && !!currentDraft) {
      navigate(`/o/${organizationId}/w/${workspaceId}/p/${pageId}/versions/${currentDraft.id}`, {
        replace: true,
      });
    }
  }, [isContentVersionFetched, isContentVersionFetched]);

  useEffect(() => {
    return () => {
      removeContents();
      removeContentVersion();
    };
  }, []);

  const versions = buildVersions(
    contents,
    contentVersions,
    users,
    currentDraftStatusId,
    contentsStatus
  );

  const currentVersion = versions.find((version) => version.id === selectedVersionId);

  function handleRestoreClick() {
    setIsModalOpen(true);
  }

  function handleCloseModal() {
    setIsModalOpen(false);
  }

  function handleConfirmRestore() {
    if (!!currentDraft) {
      putContent.mutate({
        content: {
          ...currentVersionData,
        } as Content,
      });
    } else {
      postNewContent.mutate({
        data: {
          ...currentVersionData,
          page: `/pages/${pageId}`,
          type: ENUM_CONTENT_TYPE.DRAFT,
          version: 0,
        },
      });
    }
  }

  function handleGoBack() {
    if (currentDraft) {
      navigate(`/o/${organizationId}/w/${workspaceId}/p/${pageId}/content/${currentDraft.id}`);
    } else {
      navigate(`/o/${organizationId}/w/${workspaceId}/planning?tab=published`);
    }
  }

  const isLoading =
    isContentsLoading ||
    isUsersLoading ||
    isContentsStatusLoading ||
    (isContentVersionLoading && !!currentDraft);

  return (
    <div className="content-version-layout">
      <VersionHistoryPanel
        isCreating={isCreating}
        isLoading={isLoading}
        versions={versions}
        onCreateNewDraft={createNewDraft}
        onRestoreClick={handleRestoreClick}
      />
      <div className="content-version-layout__content">
        <Header
          title={
            selectedVersionId && !isLoading && currentVersion
              ? currentVersion.name || currentVersion?.longDisplayDate
              : ''
          }
          onGoBack={handleGoBack}
        />
        <Outlet context={{ contentVersions, contents, createNewDraft, isLoading }} />
      </div>
      {isModalOpen && (
        <DialogModal
          cancelAction={handleCloseModal}
          cancelLabel={t('content:version-history-panel.modal.cancel')}
          canClickOutsideToClose
          closable
          confirmAction={handleConfirmRestore}
          confirmLabel={t('content:version-history-panel.modal.restore')}
          description={t(
            !!currentDraft
              ? 'content:version-history-panel.modal.restore-the-version_interval'
              : 'content:version-history-panel.modal.create-new-draft_interval',
            {
              count: Math.floor(
                Math.abs(DateTime.fromISO(String(currentVersion?.date)).diffNow().as('days'))
              ),
              date: currentVersion?.longDisplayDate.toLowerCase(),
              postProcess: 'interval',
            }
          )}
          isCancelDisabled={false}
          isConfirmDisabled={postNewContent.isLoading || putContent.isLoading}
          isOpen={isModalOpen}
          size={ENUM_DIALOG_MODAL_SIZE.Medium}
          title={t('content:version-history-panel.modal.restore-this-version')}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
}
