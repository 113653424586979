import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';

import { ContainedButton } from '@/components/Button/Button';
import ExternalIcon from '@/components/icons/ExternalIcon';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import { noop } from '@/utils/noop';

export const DefaultLink = styled(
  ({
    to,
    isExternal,
    children,
    decoration,
    color,
    size,
    weight,
    noDynamicFontSize,
    noPadding,
    padding,
    backgroundColor,
    rounded,
    ...props
  }) => {
    if (isExternal || !to) {
      return (
        <a href={to} {...props} rel="noopener noreferrer" target="_blank">
          {children}
        </a>
      );
    }

    return (
      <NavLink to={to} {...props}>
        {children}
      </NavLink>
    );
  }
)`
  && {
    color: ${(props) => props.theme.cssColors[props.color ?? 'primary']};
    background-color: ${(props) => props.theme.cssColors[props.backgroundColor ?? 'transparent']};
    border-radius: ${(props) => (props.rounded ? props.rounded : '0')};
    font-family: ${(props) => props.theme.textCss.fonts.main};
    font-size: ${(props) => props.theme.textCss.sizes[props.size ?? 'sm']};
    font-weight: ${(props) => props.theme.textCss.weights[props.weight ?? 'normal']};
    cursor: pointer;
    text-decoration: ${(props) => (props.decoration ? 'underline' : 'none')};
    overflow: hidden;
    text-overflow: ellipsis;
    padding: ${({ noPadding, padding }) => (noPadding ? '0' : padding ? padding : '0.1rem 0')};
    ${(props) => props.theme.mediaQueries.largeDesktop} {
      font-size: ${(props) =>
        props.noDynamicFontSize
          ? props.theme.textCss.sizes[props.size ?? 'sm']
          : props.theme.textCss.sizes[props.size || 'default']};
    }
  }
  &&:hover {
    color: ${(props) => props.theme.cssColors[props.color ?? 'primary']};
  }
`;

export const IconExternalLink = styled(({ href, onClick = noop, ...props }) => (
  <TooltipComponent {...props}>
    <a href={href} rel="noopener noreferrer" target="_blank" onClick={onClick}>
      <ExternalIcon />
    </a>
  </TooltipComponent>
))`
  && {
    display: none;
    margin-left: ${({ marginLeft }) => marginLeft ?? '4px'};
    margin-top: ${({ marginTop }) => marginTop};
    & > a {
      color: ${(props) =>
        props.color === 'currentColor'
          ? props.color
          : props.theme.cssColors[props.color ?? 'dark060']};
      cursor: pointer;
      & > svg {
        font-size: ${({ size }) => size ?? '10px'};
      }
    }
  }
`;

export const IconLink = styled(({ href, tooltipTitle, icon, ...props }) => (
  <TooltipComponent title={tooltipTitle} {...props}>
    <a href={href} rel="noopener noreferrer" target="_blank">
      {icon}
    </a>
  </TooltipComponent>
))`
  && {
    & > a,
    & > svg {
      font-size: ${({ theme, size }) => theme.textCss.sizes[size ?? 'default']};
      color: ${({ theme, color }) => theme.cssColors[color ?? 'dark060']};
      cursor: ${({ cursor }) => cursor ?? 'pointer'};
    }
  }
`;

export const ContainedLinkButton = ({ to, ...props }) => (
  <a href={to} rel="noopener noreferrer" style={{ textDecoration: 'none' }} target="_blank">
    <ContainedButton {...props} />
  </a>
);

export const PrimaryLink = styled(({ to, isExternal, children, noDecoration, ...props }) => {
  if (isExternal || !to) {
    return (
      <a href={to} {...props} rel="noopener noreferrer" target="_blank">
        {children}
      </a>
    );
  }

  return (
    <NavLink to={to} {...props}>
      {children}
    </NavLink>
  );
})`
  && {
    color: ${(props) => props.theme.colors.secondary};
    font-family: ${(props) => props.theme.text.font};
    font-size: ${(props) => props.theme.textCss.sizes[props.size || 'default']};
    font-weight: ${(props) => props.theme.textCss.weights[props.weight || 'normal']};
    margin: 0 auto;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    text-decoration: ${(props) => (props.noDecoration ? 'none' : 'underline')};
  }
  &&:hover {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const SecondaryLink = styled(
  ({ to, isExternal, children, noPadding, small, defaultColor, micro, ...props }) => {
    if (isExternal) {
      return (
        <a href={to} {...props} rel="noopener noreferrer" target="_blank">
          {children}
        </a>
      );
    }

    return (
      <a href={to} {...props}>
        {children}
      </a>
    );
  }
)`
  && {
    text-decoration: none;
    color: ${(props) =>
      props.defaultColor
        ? props.theme.text.colors.default
        : props.isExternal
          ? props.theme.text.colors.blue
          : 'currentColor'};
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
    font-size: ${(props) => (props.micro ? '0.7em' : props.small ? '0.8em' : '0.9em')};
    padding-bottom: ${(props) => (props.noPadding ? 0 : props.theme.text.sizes.micro)};
  }
  &:hover {
    color: ${(props) =>
      props.defaultColor
        ? props.theme.text.colors.darker
        : props.isExternal
          ? props.theme.text.colors.blue
          : 'currentColor'};
  }
`;

export const TertiaryLink = styled(({ to, isExternal, children, size, color, ...props }) => {
  if (isExternal) {
    return (
      <a href={to} {...props} rel="noopener noreferrer" target="_blank">
        {children}
      </a>
    );
  }

  return (
    <NavLink to={to} {...props}>
      {children}
    </NavLink>
  );
})`
  && {
    text-decoration: none;
    font-weight: ${({ weight }) => weight};
    font-size: ${({ size, theme }) =>
      size === 'small' ? theme.text.sizes.small : size === 'default' ? '0.9em' : 'inherit'};
    color: ${({ color, theme }) => (color ? theme.cssColors[color] : theme.text.colors.default)};
  }
  &&:hover {
    color: ${({ theme }) => theme.text.colors.darker};
  }
`;

export default styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.colors.default};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
`;

export const ButtonLikeLink = styled.a`
  && {
    align-items: center;
    justify-content: center;
    display: flex;
    font-family: ${(props) => props.theme.text.font};
    text-decoration: none;
    color: ${(props) =>
      props.disabled ? props.theme.text.colors.bright : props.theme.text.colors.white};
    background-color: ${(props) =>
      props.disabled ? props.theme.colors.quarterGrey : props.theme.colors.secondary};
    padding: 0px 20px;
    font-size: ${(props) => props.theme.text.sizes.default};
    font-weight: 400;
    border: 0;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    border-radius: 3px;
    min-height: 35px;
    margin: ${(props) => props.withMargin && '0 .5rem'};
    white-space: nowrap;
  }
`;
