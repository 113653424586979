export const BLOCK_TYPES_TO_LOOK_FOR = [
  'heading-one',
  'heading-two',
  'heading-three',
  'heading-four',
  'heading-five',
  'heading-six',
];
export const GENERATION_TITLE_TYPE = 'GENERATION_TITLE_TYPE';
export const GENERATION_METADESCRIPTION_TYPE = 'GENERATION_METADESCRIPTION_TYPE';
export const GENERATION_PARAGRAPH_TYPE = 'GENERATION_PARAGRAPH_TYPE';
export const GENERATION_OUTLINE_TYPE = 'GENERATION_OUTLINE_TYPE';
export const GENERATION_INTRODUCTION_TYPE = 'GENERATION_INTRODUCTION_TYPE';
export const GENERATION_REFINED_BRAND_VOICE_TYPE = 'GENERATION_REFINED_BRAND_VOICE_TYPE';
export const ATOMIC_CONTENT_TYPE = 'ATOMIC_CONTENT_TYPE';
export const GENERATION_KEY_POINTS_TYPE = 'GENERATION_KEY_POINTS_TYPE';
export const GENERATION_TOPICS_TYPE = 'GENERATION_TOPICS_TYPE';
export const DEFAULT_OUTLINE_TYPE = 'heading-two';
export const DEFAULT_KEY_POINTS_TYPE = 'paragraph';
export const DEFAULT_TOPICS_TYPE = 'paragraph';
export const BOLD_MARK = 'bold';
export const TYPING_TIME = 3000; // In ms.
export const TYPING_TIME_PER_CHARACTER = 25; // In ms.

export const sleep = (t, value) => new Promise((resolve) => setTimeout(resolve, t, value));

export function computeTypingSpeed(textLength, typingTime = TYPING_TIME) {
  const estimatedCaracterSpeed = typingTime / textLength;

  return estimatedCaracterSpeed > TYPING_TIME_PER_CHARACTER
    ? TYPING_TIME_PER_CHARACTER
    : estimatedCaracterSpeed;
}
