import MoreHoriz from '@material-ui/icons/MoreHoriz';
import styled from 'styled-components/macro';

import { FlexJustifiedSpaceBetweenContainer } from '@/components/_common';
import { PrimaryButton } from '@/components/Button/Button';
import MenuItem from '@/components/Menu/MenuItem';

export const StyledPrimaryButton = styled(PrimaryButton)`
  && {
    font-size: ${(props) => props.theme.textCss.sizes.sm};
  }
`;

export const StyledMoreHoriz = styled(MoreHoriz)`
  && {
    fill: ${(props) =>
      props.disabled ? props.theme.text.colors.bright : props.theme.text.colors.light};
    font-size: 16px;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  }
`;

export const StyledFlexJustifiedSpaceBetweenContainer = styled(FlexJustifiedSpaceBetweenContainer)`
  padding-bottom: 15px;
`;

export const StyledMenuItem = styled(MenuItem)`
  && {
    font-size: 0.9em;
  }
`;
export const InfoBadge = styled.div`
  display: inline;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: ${(props) => props.theme.textCss.sizes.xxs};
  line-height: ${({ theme }) => theme.textCss.lineHeight};
  align-items: center;
  background-color: #c4c4c41a;
  border-radius: 25px;
  color: #45475480;
  justify-content: center;
  padding: 4px 8px;
`;

export const CustomTableHeaderSpan = styled.span`
  font-weight: ${({ theme, lightWeight }) =>
    lightWeight ? theme.textCss.weights.normal : theme.textCss.weights.strong};
  font-size: 0.9em;
  color: rgb(138, 138, 138);
`;
