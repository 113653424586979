import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { exportContentIdeas } from '@/apis/semji/api';
import { LOCATION, SCOPE_CONTENT_IDEAS, SCOPE_CONTENTS, SCOPE_PAGES } from '@/apis/semji/constants';
import useGetContentIdeasByContentIdeaSearchIdAndKeyword from '@/apis/semji/contentIdeas/useGetContentIdeasByContentIdeaSearchIdAndKeyword';
import useGetContentIdeaSearch from '@/apis/semji/contentIdeas/useGetContentIdeaSearch';
import useGetCurrentWorkspaceContentsWithSpecificProperties from '@/apis/semji/contents/useGetCurrentWorkspaceContentsWithSpecificProperties';
import useGetCurrentWorkspacePagesWithSpecificProperties from '@/apis/semji/pages/useGetCurrentWorkspacePagesWithSpecificProperties';
import ExportDialog from '@/components/Dialog/ExportDialog';
import { SUCCESS_STATUS } from '@/components/Pages/ContentIdeas/constant';
import {
  SELECT_ALL,
  SELECT_ONCE,
} from '@/components/Pages/ContentIdeas/Listing/CellRender/Checkbox';
import {
  ALL_VALUE,
  BRANDED_VALUE,
  brandedTypeList,
  deprecatedKeywordsTypeList,
  FALSE_VALUE,
  FILTERS_LIST,
  keywordsTypeList,
  RANKED,
  rankedTypeList,
  TRUE_VALUE,
  UNRANKED,
  urlsClassificationTypeList,
  viewTypeList,
} from '@/components/Pages/ContentIdeas/Listing/constant';
import { AddToPlanningDialog } from '@/containers/AddToPlanning';
import { ADD_CONTENT_VERSION } from '@/containers/AddToPlanning/AddToPlanning.types';
import ContentIdeasListing from '@/containers/ContentIdeas/Listing/Listing';
import { RemoveFromPlanningDialog } from '@/containers/RemoveFromPlanning';
import useApiConfigurations from '@/hooks/useApiConfigurations';
import useCan from '@/hooks/useCan';
import useMapUrlQueryToFilter from '@/hooks/useMapUrlQueryToFilter';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import { showErrorSnackbar, showSuccessSnackbar } from '@/store/actions/ui';
import { CONTENT_IDEAS_EXPORT_SYNC_LIMITATION } from '@/utils/configurations/constants';
import applyFilters from '@/utils/filter/applyFilters';
import { SECTIONS } from '@/utils/log/constants';
import { Log } from '@/utils/log/Log';
import { downloadFileFromBlob } from '@/utils/url';

const TOP_FILTER_LIST = 30;
const ALL_KEYWORDS = 'all';

function ContentIdeasListingContainer() {
  const navigate = useNavigate();
  const [query, setQuery] = useSearchParams();
  const queryClient = useQueryClient();
  const filters = useMapUrlQueryToFilter({ filterList: FILTERS_LIST });

  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { contentIdeaSearchId, workspaceId, organizationId } = useParams();

  const [selectedIds, setSelectedIds] = useState([]);

  const [isAddToPlanningDialogOpen, setIsAddToPlanningDialogOpen] = useState(false);
  const [contentsToAddToPlanning, setContentsToAddToPlanning] = useState([]);
  const [addToPlanningDialogVersion, setAddToPlanningDialogVersion] = useState(null);

  const [isRemoveFromPlanningDialogOpen, setIsRemoveFromPlanningDialogOpen] = useState(false);
  const [contentsToRemoveFromPlanning, setContentsToRemoveFromPlanning] = useState([]);

  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isContentIdeasExportLoading, setIsContentIdeasExportLoading] = useState(false);
  const [exportContentIdeasError, setExportContentIdeasError] = useState(false);

  const isClusteringDisplayedFeatureFlag = useCan({
    perform: 'content_ideas.clustering.displayed',
  });
  const { isFeatureEnabled: isClusteringFeayureSet } = useOrganizationFeatureSet(
    'content-ideas:has-access-to-clusters'
  );
  const isClusteringEnabled = isClusteringDisplayedFeatureFlag && isClusteringFeayureSet;
  const contentIdeasExportSyncLimitation = parseInt(
    useApiConfigurations(CONTENT_IDEAS_EXPORT_SYNC_LIMITATION),
    10
  );
  const isContentIdeasExportAsync = selectedIds.length >= contentIdeasExportSyncLimitation;

  const keywordType = query.get('keywordType');
  const urlClassificationType = query.get('urlClassificationType');
  const brandedType = query.get('brandedType');
  const rankedType = query.get('rankedType');
  const archivedType = query.get('archivedType');
  const clusterId = query.get('clusterId');
  const viewType = query.get('viewType');

  let isSearchDeprecated = JSON.parse(localStorage.getItem('deprecatedSearchIdsList'))?.[
    contentIdeaSearchId
  ];
  const contentIdeaSearch = useGetContentIdeaSearch({
    contentIdeaSearchId,
    selectedContentIdeaSearch: location.state,
  });

  if (contentIdeaSearch.data.isDeprecated && !isSearchDeprecated) {
    let deprecatedSearchIdsList = JSON.parse(localStorage.getItem('deprecatedSearchIdsList')) || {};
    deprecatedSearchIdsList[contentIdeaSearchId] = true;
    localStorage.setItem('deprecatedSearchIdsList', JSON.stringify(deprecatedSearchIdsList));
    isSearchDeprecated = contentIdeaSearch.data.isDeprecated;
  }

  const isClusterizationStatusSuccess =
    contentIdeaSearch.data.clusterizationStatus === SUCCESS_STATUS;

  const contentIdeas = useGetContentIdeasByContentIdeaSearchIdAndKeyword({
    contentIdeaSearch,
    contentIdeaSearchId,
    enabled: !contentIdeaSearch.isFetching && !contentIdeaSearch.isPlaceholderData,
    isClusteringEnabled: isClusteringDisplayedFeatureFlag,
    isClusterizationStatusSuccess,
    isSearchDeprecated,
    keywordType,
    onError: () => {
      dispatch(showErrorSnackbar(t(`content-ideas:listing.error.default`)));
      navigate(`/o/${organizationId}/w/${workspaceId}/content-ideas/`, { replace: true });
    },
    onSuccess: (data) => {
      if (data.contentIdeasClusters) {
        const clustersIds = data.contentIdeasClusters;
        if (!clustersIds[clusterId]) {
          query.set('clusterId', FALSE_VALUE);
          setQuery(query, { replace: true });
        }
      }
    },
  });

  const pagesWithSpecificProperties = useGetCurrentWorkspacePagesWithSpecificProperties({
    filters: { 'url[contains]': '' },
    location: LOCATION.CONTENT_IDEAS,
    properties: ['id', 'focusTopKeyword', 'title', 'url', 'draft', 'inStock'],
  });
  const contentsWithSpecificProperties = useGetCurrentWorkspaceContentsWithSpecificProperties({
    filters: { inStock: false },
    location: LOCATION.CONTENT_IDEAS,
    properties: [
      'assignedTo',
      'contentScore',
      'contentStatus',
      'dueDate',
      'id',
      'page',
      'pageFocusTopKeyword',
      'title',
      'type',
    ],
  });

  function getContentIdeasListFiltered() {
    if (contentIdeas.isPlaceholderData) {
      return contentIdeas.data.contentIdeasList;
    }
    let filteredRows = applyFilters(contentIdeas.data.contentIdeasList, filters);

    if (urlClassificationType !== ALL_VALUE) {
      filteredRows = filteredRows.filter(
        (contentIdea) => contentIdea.urlCategory === urlClassificationType
      );
    }

    if (brandedType !== ALL_VALUE) {
      filteredRows = filteredRows.filter((contentIdea) =>
        brandedType === BRANDED_VALUE ? contentIdea.brandedKeyword : !contentIdea.brandedKeyword
      );
    }

    if (rankedType === RANKED) {
      filteredRows = filteredRows.filter((contentIdea) => contentIdea.rankedWorkspaceUrlPosition);
    }

    if (rankedType === UNRANKED) {
      filteredRows = filteredRows.filter((contentIdea) => !contentIdea.rankedWorkspaceUrlPosition);
    }

    if (keywordType !== ALL_VALUE && !isSearchDeprecated) {
      filteredRows = filteredRows.filter((contentIdea) => keywordType === contentIdea.type);
    }

    return filteredRows;
  }

  function handleRemoveFromPlanningDialog(ids) {
    if (!isRemoveFromPlanningDialogOpen) {
      setContentsToRemoveFromPlanning(
        contentsWithSpecificProperties.data.filter((content) => ids.includes(content.id))
      );
    }

    setIsRemoveFromPlanningDialogOpen((prevState) => !prevState);
  }

  function handleOpenEditDraftModal(id) {
    const plannedContents =
      contentIdeas.data.contentIdeasList?.find((ci) => ci.id === id)?.plannedContentIds ?? [];

    if (1 === plannedContents.length) {
      const pageId = contentsWithSpecificProperties.data?.find((c) => c.id === plannedContents[0])
        ?.page?.id;

      if (pageId) {
        navigate(
          `/o/${organizationId}/w/${workspaceId}/p/${pageId}/content/${plannedContents[0]}`,
          { replace: true }
        );
      }

      return null;
    }

    if (plannedContents.length > 1) {
      return contentsWithSpecificProperties.data.filter((content) =>
        plannedContents.includes(content.id)
      );
    }
  }

  function prepareContents(ids) {
    const contentIdeasClassifiedList = getContentIdeasListFiltered();
    const contents = [];

    contentIdeasClassifiedList.forEach((content) => {
      if (ids?.includes(content.id) && !content.plannedContentIds.length) {
        contents.push({
          draftTitle: '',
          draftUrl: content.rankedWorkspaceUrl || '',
          id: content.id,
          keyword: content.keyword,
          recommendedUrl:
            content.rankedWorkspaceUrlPosition <= TOP_FILTER_LIST
              ? content.rankedWorkspaceUrl
              : null,
          urlError: '',
        });
      }
    });

    return contents;
  }

  function handleCloseAddToPlanningModal() {
    setIsAddToPlanningDialogOpen(false);
  }

  function handleOpenAddToPlanningModal(ids) {
    const contents = prepareContents(ids);
    setContentsToAddToPlanning(contents);
    setAddToPlanningDialogVersion(ADD_CONTENT_VERSION.ADD_TO_PLANNING_VERSION);
    setIsAddToPlanningDialogOpen(true);
  }

  function handleOpenStartOptimizingModal(ids) {
    const contents = prepareContents(ids);
    setContentsToAddToPlanning(contents);
    setAddToPlanningDialogVersion(ADD_CONTENT_VERSION.START_OPTIMIZING_VERSION);
    setIsAddToPlanningDialogOpen(true);
  }

  function handleOpenExportModal() {
    setIsExportModalOpen(true);
  }

  function handleCloseExportModal() {
    setIsExportModalOpen(false);
  }

  async function handleExportContentIdeas(fileType) {
    try {
      setExportContentIdeasError(false);
      setIsContentIdeasExportLoading(true);

      const response = await exportContentIdeas({
        contentIdeaSearchId,
        contentIdeasIds: selectedIds,
        fileType,
      });
      if (response.isAsync) {
        dispatch(
          showSuccessSnackbar(t('content-ideas:listing.export.notification.async-export-success'))
        );
      } else {
        await downloadFileFromBlob({ fileBlob: response, filePrefix: 'export', fileType });
      }
      setIsExportModalOpen(false);
    } catch (error) {
      Log.report({
        context: 'exportContentIdeas',
        error,
        extra: 'Bulk export content ideas',
        section: SECTIONS.content.key,
      });
      dispatch(showErrorSnackbar(t('common:error.default')));
      setExportContentIdeasError(error);
    } finally {
      setIsContentIdeasExportLoading(false);
    }
  }

  function handleSelectContentIdeas(e) {
    const name = e.target.name;
    const isChecked = e.target.checked;
    const selectedId = e.target.value;

    if (name === SELECT_ALL) {
      const contentIdeasClassifiedList = getContentIdeasListFiltered();

      // Temporary fix
      // We need to have two filtered list, one for the panel and the other one for the rows
      // cf https://gitlab.rvip.fr/semji/semji/-/blob/master/frontend/src/containers/ContentIdeas/Listing/Listing.js#L72
      const newSelectedIds = isChecked
        ? contentIdeasClassifiedList.reduce((acc, contentIdea) => {
            // Only return archived ideas if we are on the archived tab
            if (archivedType === TRUE_VALUE) {
              if (contentIdea.isArchived) {
                acc.push(contentIdea.id);
              }
              return acc;
            }
            // Only ideas that are not archived and part of the active cluster
            if (!contentIdea.isArchived) {
              if (clusterId !== FALSE_VALUE) {
                if (contentIdea.contentIdeaClusterIds?.find((id) => id === clusterId)) {
                  acc.push(contentIdea.id);
                }
              } else {
                acc.push(contentIdea.id);
              }
            }
            return acc;
          }, [])
        : [];
      setSelectedIds(newSelectedIds);
    }
    if (name === SELECT_ONCE) {
      setSelectedIds(
        isChecked ? [...selectedIds, selectedId] : selectedIds.filter((id) => id !== selectedId)
      );
    }
  }

  function handleSelectContentIdeasInline({ rowData }) {
    if (!!rowData?.computing) {
      return;
    }
    const isChecked = !!selectedIds.find((id) => id === rowData.id);
    const selectedId = rowData.id;

    setSelectedIds(
      isChecked ? selectedIds.filter((id) => id !== selectedId) : [...selectedIds, selectedId]
    );
  }

  function handleRefetchContentIdeas() {
    queryClient.refetchQueries({
      active: true,
      exact: true,
      fetching: false,
      queryKey: [
        SCOPE_CONTENT_IDEAS.CONTENT_IDEAS,
        contentIdeaSearchId,
        LOCATION.CONTENT_IDEAS,
        isSearchDeprecated ? keywordType : ALL_KEYWORDS,
        isClusteringEnabled,
        isClusterizationStatusSuccess,
      ],
    });
  }

  function handleRefetchContent() {
    queryClient.refetchQueries({
      active: true,
      exact: true,
      fetching: false,
      queryKey: [SCOPE_CONTENTS.WITH_SPECIFIC_PROPERTIES, workspaceId, LOCATION.CONTENT_IDEAS],
    });
  }

  function handleRefetchPages() {
    queryClient.refetchQueries({
      active: true,
      exact: true,
      fetching: false,
      queryKey: [SCOPE_PAGES.WITH_SPECIFIC_PROPERTIES, workspaceId, LOCATION.CONTENT_IDEAS],
    });
  }

  useEffect(() => {
    if (isSearchDeprecated) {
      if (!deprecatedKeywordsTypeList.some((filter) => filter.value === keywordType)) {
        query.set('keywordType', deprecatedKeywordsTypeList[0].value);
      }
    } else {
      if (!keywordsTypeList.some((filter) => filter.value === keywordType)) {
        query.set('keywordType', keywordsTypeList[0].value);
      }
    }
    if (!urlsClassificationTypeList.some((filter) => filter.value === urlClassificationType)) {
      query.set('urlClassificationType', urlsClassificationTypeList[0].value);
    }
    if (!brandedTypeList.some((filter) => filter.value === brandedType)) {
      query.set('brandedType', brandedTypeList[0].value);
    }
    if (!rankedTypeList.some((filter) => filter.value === rankedType)) {
      query.set('ranked', rankedTypeList[0].value);
    }
    if (archivedType !== TRUE_VALUE && archivedType !== FALSE_VALUE) {
      query.set('archivedType', FALSE_VALUE);
    }
    if (!clusterId) {
      query.set('clusterId', FALSE_VALUE);
    }
    if (!viewTypeList.some((filter) => filter.value === viewType)) {
      query.set('viewType', viewTypeList[0].value);
    }

    setQuery(query, { replace: true });

    return () => {
      queryClient.removeQueries({
        exact: false,
        queryKey: [
          SCOPE_CONTENT_IDEAS.CONTENT_IDEAS,
          contentIdeaSearchId,
          LOCATION.CONTENT_IDEAS,
          isSearchDeprecated ? keywordType : ALL_KEYWORDS,
          isClusteringEnabled,
          isClusterizationStatusSuccess,
        ],
      });
      contentsWithSpecificProperties.remove();
      pagesWithSpecificProperties.remove();
      contentIdeaSearch.remove();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedIds([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlClassificationType, keywordType, brandedType, archivedType, clusterId, rankedType]);

  // Temporary fix
  // Thw whole tree of components need refactoring
  const contentIdeasListFiltered = getContentIdeasListFiltered();

  return (
    <>
      <ContentIdeasListing
        contentIdeasClusters={contentIdeas.data.contentIdeasClusters}
        contentIdeaSearch={contentIdeas.data.contentIdeaSearch}
        contentIdeasList={contentIdeasListFiltered}
        contentIdeasLoading={
          (contentIdeas.isFetching && contentIdeas.isPlaceholderData) ||
          (contentsWithSpecificProperties.isFetching &&
            contentsWithSpecificProperties.isPlaceholderData) ||
          (pagesWithSpecificProperties.isFetching && pagesWithSpecificProperties.isPlaceholderData)
        }
        contentsToAddToPlanning={contentsToAddToPlanning}
        contentsToRemoveFromPlanning={contentsToRemoveFromPlanning}
        handleOpenAddToPlanningModal={handleOpenAddToPlanningModal}
        handleOpenEditDraftModal={handleOpenEditDraftModal}
        handleOpenExportModal={handleOpenExportModal}
        handleOpenStartOptimizingModal={handleOpenStartOptimizingModal}
        handleRemoveFromPlanningDialog={handleRemoveFromPlanningDialog}
        handleSelectContentIdeas={handleSelectContentIdeas}
        handleSelectContentIdeasInline={handleSelectContentIdeasInline}
        isAddToPlanningDialogOpen={isAddToPlanningDialogOpen}
        isClusterizationStatusSuccess={isClusterizationStatusSuccess}
        isRemoveFromPlanningDialogOpen={isRemoveFromPlanningDialogOpen}
        isSearchDeprecated={isSearchDeprecated}
        nbContentIdeasForCurrentKeywordType={contentIdeas.data.contentIdeasList.length}
        pages={pagesWithSpecificProperties.data}
        selectedIds={selectedIds}
      />
      {isRemoveFromPlanningDialogOpen && (
        <RemoveFromPlanningDialog
          contentsToRemoveFromPlanning={contentsToRemoveFromPlanning}
          handleRefetch={handleRefetchContentIdeas}
          handleRefetchContent={handleRefetchContent}
          handleRefetchPages={handleRefetchPages}
          onClose={handleRemoveFromPlanningDialog}
        />
      )}
      {isAddToPlanningDialogOpen && !!contentsToAddToPlanning.length && (
        <AddToPlanningDialog
          key={contentsToAddToPlanning.length}
          addToPlanningDialogVersion={addToPlanningDialogVersion}
          contentsToAddToPlanning={contentsToAddToPlanning}
          handleRefetch={handleRefetchContentIdeas}
          handleRefetchContent={handleRefetchContent}
          pages={pagesWithSpecificProperties.data}
          onClose={handleCloseAddToPlanningModal}
        />
      )}
      {isExportModalOpen && (
        <ExportDialog
          error={exportContentIdeasError}
          handleCancel={handleCloseExportModal}
          handleConfirm={handleExportContentIdeas}
          isAsync={isContentIdeasExportAsync}
          loading={isContentIdeasExportLoading}
          open={true}
          title={t('content-ideas:listing.export.modal-title_interval', {
            count: selectedIds.length,
            postProcess: 'interval',
          })}
        />
      )}
    </>
  );
}

export default ContentIdeasListingContainer;
