import { useMutation } from '@tanstack/react-query';

import apiClient from '@/apis/semji/apiClient';

import {
  CompletionTokenParams,
  UseGenerateCompletionTokenResults,
} from './useGenerateCompletionToken.types';

export default function useGenerateCompletionToken({
  ...props
}): UseGenerateCompletionTokenResults {
  const { post } = apiClient;

  return useMutation({
    mutationFn: ({
      topKeywordId,
      type,
      aiFact = false,
      aiBrandVoice = false,
    }: CompletionTokenParams) =>
      post(`top_keywords/${topKeywordId}/completions/token`, { aiBrandVoice, aiFact, type }),

    ...props,
  });
}
