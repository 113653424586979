import { nanoid } from 'nanoid';
import React, { useMemo } from 'react';

const PieChart = (props) => {
  const maskId = useMemo(() => nanoid(), []);
  return (
    <svg
      fill="none"
      height="17"
      viewBox="0 0 18 17"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path={`url(#clip${maskId})`}>
        <mask
          height="17"
          id={`mask${maskId}`}
          maskUnits="userSpaceOnUse"
          style={{ maskType: 'alpha' }}
          width="17"
          x="1"
          y="-1"
        >
          <path
            d="M15.318 8.94302L16.316 9.01002C16.053 12.93 12.766 16 8.83301 16C4.69701 16 1.33301 12.636 1.33301 8.50002C1.33301 4.59502 4.38001 1.31002 8.27101 1.02102L8.34501 2.01802C4.97401 2.26802 2.33301 5.11502 2.33301 8.50002C2.33301 12.084 5.24901 15 8.83301 15C12.242 15 15.09 12.34 15.318 8.94302ZM17.333 7.49902V7.99902L16.832 8.01602H9.35001V-0.000976562L9.85101 2.34375e-05C13.977 0.00902344 17.333 3.37402 17.333 7.49902ZM16.319 7.01602C16.096 3.84602 13.543 1.27302 10.35 1.02102V7.01602H16.319Z"
            fill="black"
          />
        </mask>
        <g mask={`url(#mask${maskId})`}>
          <rect fill="#F0C659" height="20" width="20" x="-1.66699" y="-1" />
        </g>
      </g>
      <defs>
        <clipPath id={`clip${maskId}`}>
          <rect fill="white" height="17" transform="translate(0.333008)" width="17" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PieChart;
