// GLOBAL

export const LOGOUT_USER = 'LOGOUT_USER';

// DEBUG

export const SET_DEBUG_LANGUAGE = 'SET_DEBUG_LANGUAGE';
export const SET_DEBUG_I18N = 'SET_DEBUG_I18N';
export const RESET_DEBUG_STATE = 'RESET_DEBUG_STATE';
export const SET_RECURSIVE_CALLS = 'SET_RECURSIVE_CALLS';
export const SET_MOCK = 'SET_MOCK';
export const SET_COMPETITOR_MOCK = 'SET_COMPETITOR_MOCK';
export const SET_MOCK_PROXY_ENV = 'SET_MOCK_PROXY_ENV';

// REPORT

export const CLOSE_CREDIT_LIMIT_DIALOG = 'CLOSE_CREDIT_LIMIT_DIALOG';
export const CLOSE_SIDE_PANEL = 'CLOSE_SIDE_PANEL';
export const OPEN_CREDIT_LIMIT_DIALOG = 'OPEN_CREDIT_LIMIT_DIALOG';
export const OPEN_SIDE_PANEL = 'OPEN_SIDE_PANEL';
export const RESET_REPORT = 'RESET_REPORT';
export const SET_REPORT = 'SET_REPORT';
export const SET_FOCUS_TOP_KEYWORD = 'SET_FOCUS_TOP_KEYWORD';
export const TOGGLE_REPORT_MAIN_RECOMMENDATION = 'TOGGLE_REPORT_MAIN_RECOMMENDATION';
export const TOGGLE_REPORT_RECOMMENDATION = 'TOGGLE_REPORT_RECOMMENDATION';
export const TOGGLE_SIDE_PANEL = 'TOGGLE_SIDE_PANEL';
export const UPDATE_FOCUS_TOP_KEYWORD = 'UPDATE_FOCUS_TOP_KEYWORD';

// CONTENT

export const SET_CONTENT = 'SET_CONTENT';
export const RESET_CONTENT = 'RESET_CONTENT';
export const SET_CONTENT_HTML = 'SET_CONTENT_HTML';
export const SET_CONTENT_TITLE = 'SET_CONTENT_TITLE';
export const SET_CONTENT_METADESCRIPTION = 'SET_CONTENT_METADESCRIPTION';

// EDITOR

export const FETCH_PAGE_LOADING = 'FETCH_PAGE_LOADING';
export const FETCH_PAGE_SUCCESS = 'FETCH_PAGE_SUCCESS';
export const FETCH_PAGE_CONTENTS_LOADING = 'FETCH_PAGE_CONTENTS_LOADING';
export const FETCH_PAGE_CONTENTS_SUCCESS = 'FETCH_PAGE_CONTENTS_SUCCESS';
export const FETCH_PAGE_CONTENTS_FAIL = 'FETCH_PAGE_CONTENTS_FAIL';
export const FETCH_LIVE_PAGE_DATA_LOADING = 'FETCH_LIVE_PAGE_DATA_LOADING';
export const FETCH_LIVE_PAGE_DATA_FINISHED = 'FETCH_LIVE_PAGE_DATA_FINISHED';
export const FETCH_LIVE_CONTENT_DATA_LOADING = 'FETCH_LIVE_CONTENT_DATA_LOADING';
export const FETCH_LIVE_CONTENT_DATA_SUCCESS = 'FETCH_LIVE_CONTENT_DATA_SUCCESS';
export const FETCH_LIVE_CONTENT_DATA_FINISHED = 'FETCH_LIVE_CONTENT_DATA_FINISHED';
export const FETCH_DRAFT_LOADING = 'FETCH_DRAFT_LOADING';
export const FETCH_DRAFT_SUCCESS = 'FETCH_DRAFT_SUCCESS';
export const FETCH_DRAFT_FAIL = 'FETCH_DRAFT_FAIL';
export const FETCH_CONTENT_LOADING = 'FETCH_CONTENT_LOADING';
export const FETCH_CONTENT_SUCCESS = 'FETCH_CONTENT_SUCCESS';
export const FETCH_CONTENT_FAIL = 'FETCH_CONTENT_FAIL';
export const PUBLISH_PENDING = 'PUBLISH_PENDING';
export const PUBLISH_SUCCESS = 'PUBLISH_SUCCESS';
export const PUBLISH_FAIL = 'PUBLISH_FAIL';
export const PUBLISH_CANCEL = 'PUBLISH_CANCEL';
export const CREATE_NEW_DRAFT_LOADING = 'CREATE_NEW_DRAFT_LOADING';
export const CREATE_NEW_DRAFT_SUCCESS = 'CREATE_NEW_DRAFT_SUCCESS';
export const CREATE_NEW_DRAFT_FAIL = 'CREATE_NEW_DRAFT_FAIL';
export const DELETE_DRAFT_LOADING = 'DELETE_DRAFT_LOADING';
export const ADD_DRAFT_TO_PLANNING_LOADING = 'ADD_DRAFT_TO_PLANNING_LOADING';
export const ADD_DRAFT_TO_PLANNING_SUCCESS = 'ADD_DRAFT_TO_PLANNING_SUCCESS';
export const ADD_DRAFT_TO_PLANNING_FAIL = 'ADD_DRAFT_TO_PLANNING_FAIL';
export const DELETE_DRAFT_ID = 'DELETE_DRAFT_ID';
export const UPDATE_DRAFT = 'UPDATE_DRAFT';
export const OPEN_PUBLISH_DIALOG = 'OPEN_PUBLISH_DIALOG';
export const UPDATE_PAGE = 'UPDATE_PAGE';
export const RESET_DRAFT = 'RESET_DRAFT';
export const RESET_EDITOR_STATE = 'RESET_EDITOR_STATE';
export const SET_AI_THINKING_FINISHED = 'SET_AI_THINKING_FINISHED';
export const SET_AI_THINKING_STARTED = 'SET_AI_THINKING_STARTED';
export const SET_AI_WRITING_FINISHED = 'SET_AI_WRITING_FINISHED';
export const SET_AI_WRITING_STARTED = 'SET_AI_WRITING_STARTED';
export const SET_AI_LAST_GENERATION_TYPE = 'SET_AI_LAST_GENERATION_TYPE';
export const SET_SELECTION_TEXT = 'SET_SELECTION_TEXT';

// UI

export const CHANGE_UI_MODE = 'CHANGE_UI_MODE';

// ORGANIZATIONS

export const FETCH_USER_ORGANIZATIONS_SUCCESS = 'FETCH_USER_ORGANIZATIONS_SUCCESS';
export const FETCH_ORGANIZATION_WORKSPACES_SUCCESS = 'FETCH_ORGANIZATION_WORKSPACES_SUCCESS';
export const FETCH_USER_ORGANIZATION_EDITABLE_DATA = 'FETCH_USER_ORGANIZATION_EDITABLE_DATA';
export const CHANGE_DEFAULT_ORGANIZATION_ID = 'CHANGE_DEFAULT_ORGANIZATION_ID';
export const REMOVE_DEFAULT_ORGANIZATION_ID = 'REMOVE_DEFAULT_ORGANIZATION_ID';
export const FETCH_ORGANIZATION_SUCCESS = 'FETCH_ORGANIZATION_SUCCESS';

// WORKSPACE

export const FETCH_WORKSPACE_SUCCESS = 'FETCH_WORKSPACE_SUCCESS';
export const CHANGE_DEFAULT_WORKSPACE_ID = 'CHANGE_DEFAULT_WORKSPACE_ID';
export const REMOVE_DEFAULT_WORKSPACE_ID = 'REMOVE_DEFAULT_WORKSPACE_ID';
export const CHANGE_WORKSPACE_NAME = 'CHANGE_WORKSPACE_NAME';
export const UPDATE_WORKSPACE_ACHIEVEMENTS = 'UPDATE_WORKSPACE_ACHIEVEMENTS';
export const UPDATE_WORKSPACE_USERS = 'UPDATE_WORKSPACE_USERS';

// METRICS CONFIG

export const CHANGE_METRICS_CONFIG_CURRENT_COMPARISON_PERIOD =
  'CHANGE_METRICS_CONFIG_CURRENT_COMPARISON_PERIOD';
export const CHANGE_METRICS_CONFIG_CURRENT_METRIC = 'CHANGE_METRICS_CONFIG_CURRENT_METRIC';
export const CHANGE_METRICS_CONFIG_PERIOD = 'CHANGE_METRICS_CONFIG_PERIOD';
export const CHANGE_METRICS_CONFIG_PERIODICITY = 'CHANGE_METRICS_CONFIG_PERIODICITY';

// USER CONFIGURATION
export const SET_FACT_CHECKING_ENABLED = 'SET_FACT_CHECKING_ENABLED';
export const SET_HIGHLIGHT_COMMENT_ENABLED = 'SET_HIGHLIGHT_COMMENT_ENABLED';
export const SET_HIGHLIGHT_FACT_CHECKING_ENABLED = 'SET_HIGHLIGHT_FACT_CHECKING_ENABLED';
export const SET_MARK_AS_PUBLISHED_HELPER_MESSAGE_ENABLED =
  'SET_MARK_AS_PUBLISHED_HELPER_MESSAGE_ENABLED';
export const SET_COMPETITOR_DATA_LABELS_ENABLED = 'SET_COMPETITOR_DATA_LABELS_ENABLED';
export const SET_REPORT_ACCORDION_STATE = 'SET_REPORT_ACCORDION_STATE';

// HUB
export const CHANGE_DEFAULT_HUB = 'CHANGE_DEFAULT_HUB';

// SELECTED METRICS

export const CHANGE_SELECTED_METRIC = 'CHANGE_SELECTED_METRIC';

// ONLINE CONTENTS

export const FETCH_WORKSPACE_CONTENTS_REQUEST = 'FETCH_WORKSPACE_CONTENTS_REQUEST';
export const FETCH_WORKSPACE_CONTENTS_SUCCESS = 'FETCH_WORKSPACE_CONTENTS_SUCCESS';

// USER
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const SET_USER_EMAIL = 'SET_USER_EMAIL';
export const SET_USER_FIRST_NAME = 'SET_USER_FIRST_NAME';
export const SET_USER_UPLOADED_AVATAR_URL = 'SET_USER_UPLOADED_AVATAR_URL';
export const SET_USER_PROFILE_IMAGE_HASH = 'SET_USER_PROFILE_IMAGE_HASH';
export const SET_USER_LAST_NAME = 'SET_USER_LAST_NAME';
export const SET_USER_PHONE_NUMBER = 'SET_USER_PHONE_NUMBER';
export const UPDATE_USER_WORKSPACE_ACCESSES = 'UPDATE_USER_WORKSPACE_ACCESSES';
export const SET_USER_LANGUAGE_CODE = 'SET_USER_LANGUAGE_CODE';
export const FETCH_REGISTRANT_SUCCESS = 'FETCH_REGISTRANT_SUCCESS';
export const SET_USER_IS_EXTERNAL_LOGIN = 'SET_USER_IS_EXTERNAL_LOGIN';
export const SET_USER_FACIAL_ID = 'SET_USER_FACIAL_ID';
export const SET_USER_COMMENT_EMAIL_NOTIFICATIONS = 'SET_USER_COMMENT_EMAIL_NOTIFICATIONS';
export const SET_USER_CONTENT_EMAIL_NOTIFICATIONS = 'SET_USER_CONTENT_EMAIL_NOTIFICATIONS';

// SERVICE INTEGRATIONS
export const FETCH_SERVICE_INTEGRATIONS = 'FETCH_SERVICE_INTEGRATIONS';
export const UPDATE_SERVICE_INTEGRATIONS = 'UPDATE_SERVICE_INTEGRATIONS';

// UI SNACKBAR
export const SNACKBAR_SUCCESS = 'SNACKBAR_SUCCESS';
export const SNACKBAR_WARNING = 'SNACKBAR_WARNING';
export const SNACKBAR_INFO = 'SNACKBAR_INFO';
export const SNACKBAR_ERROR = 'SNACKBAR_ERROR';
export const SNACKBAR_CLEAR = 'SNACKBAR_CLEAR';
export const SNACKBAR_HINT = 'SNACKBAR_HINT';
export const SNACKBAR_DEFAULT = 'SNACKBAR_DEFAULT';

// BILLING
export const SHOW_MODAL_UPGRADE_PLAN = 'SHOW_MODAL_UPGRADE_PLAN';
export const HIDE_MODAL_UPGRADE_PLAN = 'HIDE_MODAL_UPGRADE_PLAN';
export const SHOW_MODAL_CANCEL_PLAN = 'SHOW_MODAL_CANCEL_PLAN';
export const HIDE_MODAL_CANCEL_PLAN = 'HIDE_MODAL_CANCEL_PLAN';

// SIGNUP
export const SET_SELECTED_PLAN_ID = 'SET_SELECTED_PLAN_ID';

// CONFIGURATIONS
export const SET_CONFIGURATIONS = 'SET_CONFIGURATIONS';

// WORKSPACE ROUTE HISTORY
export const UPDATE_WORKSPACE_ROUTE = 'UPDATE_WORKSPACE_ROUTE';

// CREATE WORKSPACE
export const SET_WEBSITE_URL = 'SET_WEBSITE_URL';
export const SET_LOCATION = 'SET_LOCATION';
export const SET_WORKSPACE_NAME = 'SET_WORKSPACE_NAME';
export const SET_SERVICE_INTEGRATION = 'SET_SERVICE_INTEGRATION';
export const SET_USERS = 'SET_USERS';
export const SET_ORGANIZATION_ID = 'SET_ORGANIZATION_ID';
export const RESET_CREATE_WORKSPACE = 'RESET_CREATE_WORKSPACE';

// DIALOGS
export const SET_DIALOG_STATE = 'SET_DIALOG_STATE';
export const OPEN_DIALOG = 'OPEN_DIALOG';
export const CLOSE_DIALOG = 'CLOSE_DIALOG';

// OUTLINE
export const TOGGLE_OUTLINE = 'TOGGLE_OUTLINE';
export const CLOSE_OUTLINE = 'CLOSE_OUTLINE';

// BRAND VOICE
export const SET_BRAND_VOICE = 'SET_BRAND_VOICE';
export const DELETE_BRAND_VOICE = 'DELETE_BRAND_VOICE';

// SEARCH INTELLIGENCE
export const CHANGE_DEFAULT_REPORT_ID = 'CHANGE_DEFAULT_REPORT_ID';
export const REMOVE_DEFAULT_REPORT_ID = 'REMOVE_DEFAULT_REPORT_ID';
export const CHANGE_DEFAULT_WEBSITE_ID = 'CHANGE_DEFAULT_WEBSITE_ID';
export const REMOVE_DEFAULT_WEBSITE_ID = 'REMOVE_DEFAULT_WEBSITE_ID ';
