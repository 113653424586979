import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { copyToClipboard } from 'semji-core/utils/clipboard';
import styled from 'styled-components/macro';

import CopyIcon from '@/components/icons/CopyIcon';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import { showSuccessSnackbar } from '@/store/actions/ui';

const Tooltip = styled(TooltipComponent)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.cssColors.dark080};
  cursor: pointer;
`;
const StyledCloseIcon = styled(CopyIcon)`
  && {
    font-size: 16px;
    fill: ${({ theme }) => theme.cssColors.dark040};
  }
`;

function CopyContentButton({ contentToCopy }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onClick = () => {
    copyToClipboard(contentToCopy);
    dispatch(showSuccessSnackbar(t('common:notifications.copy-to-clipboard')));
  };

  return (
    <Tooltip title={t('content:brief.copy-content-button-tooltip')}>
      <StyledCloseIcon onClick={onClick} />
    </Tooltip>
  );
}

export default CopyContentButton;
