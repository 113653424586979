import { nanoid } from 'nanoid';
import React from 'react';
import { useMemo } from 'react';

import SvgWrapper from './SvgWrapper';

function TitleIcon(props) {
  const maskId = useMemo(() => nanoid(), []);

  return (
    <SvgWrapper {...props} fill="none" height="17" viewBox="0 0 17 17" width="17">
      <mask
        height="14"
        id={`mask${maskId}`}
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="17"
        x="0"
        y="2"
      >
        <path
          d="M6 5.925H7V7.925H6V6.925H4V14.925H5V15.925H2V14.925H3V6.925H1V7.925H0V5.925H6ZM16 2H6V4.577H7V3H11V15H10V16H13V15H12V3H16V4.577H17V2H16Z"
          fill="black"
        />
      </mask>
      <g mask={`url(#mask${maskId})`}>
        <rect fill="currentColor" height="20" width="20" x="-2" y="-1" />
      </g>
    </SvgWrapper>
  );
}

export default TitleIcon;
