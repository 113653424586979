import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import EmptyStateCompetitor3BarsLg from '@/assets/images/empty_state_competitors_3bars_lg.svg';
import EmptyStateCompetitor3BarsMd from '@/assets/images/empty_state_competitors_3bars_md.svg';
import EmptyStateCompetitor3BarsMidLg from '@/assets/images/empty_state_competitors_3bars_mid_lg.svg';
import EmptyStateCompetitor3BarsSm from '@/assets/images/empty_state_competitors_3bars_sm.svg';
import EmptyStateCompetitorHorizontalBarLg from '@/assets/images/empty_state_competitors_horizontal_bar_lg.svg';
import EmptyStateCompetitorHorizontalBarMd from '@/assets/images/empty_state_competitors_horizontal_bar_md.svg';
import EmptyStateCompetitorHorizontalBarSm from '@/assets/images/empty_state_competitors_horizontal_bar_sm.svg';
import EmptyStateCompetitorHorizontalStackBarFullLg from '@/assets/images/empty_state_competitors_horizontal_stack_bar_full_lg.svg';
import EmptyStateCompetitorHorizontalStackBarFullMd from '@/assets/images/empty_state_competitors_horizontal_stack_bar_full_md.svg';
import EmptyStateCompetitorHorizontalStackBarFullSm from '@/assets/images/empty_state_competitors_horizontal_stack_bar_full_sm.svg';
import EmptyStateCompetitorStackBarFullLg from '@/assets/images/empty_state_competitors_stack_bar_full_lg.svg';
import EmptyStateCompetitorStackBarFullMd from '@/assets/images/empty_state_competitors_stack_bar_full_md.svg';
import EmptyStateCompetitorStackBarFullSm from '@/assets/images/empty_state_competitors_stack_bar_full_sm.svg';
import EmptyStateCompetitorStackBarLg from '@/assets/images/empty_state_competitors_stack_bar_lg.svg';
import EmptyStateCompetitorStackBarMd from '@/assets/images/empty_state_competitors_stack_bar_md.svg';
import EmptyStateCompetitorStackBarSm from '@/assets/images/empty_state_competitors_stack_bar_sm.svg';
import EmptyStateCompetitorContentLg from '@/assets/images/empty_state_content_bar_lg.svg';
import EmptyStateCompetitorContentMd from '@/assets/images/empty_state_content_bar_md.svg';
import EmptyStateCompetitorContentSm from '@/assets/images/empty_state_content_bar_sm.svg';
import EmptyStateReportsAreaLg from '@/assets/images/empty_state_reports_area_chart_lg.svg';
import EmptyStateReportsAreaMd from '@/assets/images/empty_state_reports_area_chart_md.svg';
import EmptyStateReportsAreaSm from '@/assets/images/empty_state_reports_area_chart_sm.svg';
import EmptyStateReportsBarLg from '@/assets/images/empty_state_reports_bar_chart_lg.svg';
import EmptyStateReportsBarMd from '@/assets/images/empty_state_reports_bar_chart_md.svg';
import EmptyStateReportsBarSm from '@/assets/images/empty_state_reports_bar_chart_sm.svg';
import EmptyStateReportsLineLg from '@/assets/images/empty_state_reports_line_chart_lg.svg';
import EmptyStateReportsLineMd from '@/assets/images/empty_state_reports_line_chart_md.svg';
import EmptyStateReportsLineSm from '@/assets/images/empty_state_reports_line_chart_sm.svg';
import EmptyStateReportsLineFullLg from '@/assets/images/empty_state_reports_line_full_chart_lg.svg';
import EmptyStateReportsLineFullMd from '@/assets/images/empty_state_reports_line_full_chart_md.svg';
import EmptyStateReportsStackedBarLg from '@/assets/images/empty_state_reports_stacked_bar_lg.svg';
import EmptyStateReportsStackedBarMd from '@/assets/images/empty_state_reports_stacked_bar_md.svg';
import EmptyStateReportsStackedBarSm from '@/assets/images/empty_state_reports_stacked_bar_sm.svg';
import EmptyStateCompetitorThemeLg from '@/assets/images/empty_state_theme_bar_lg.svg';
import EmptyStateCompetitorThemeMd from '@/assets/images/empty_state_theme_bar_md.svg';
import EmptyStateCompetitorThemeSm from '@/assets/images/empty_state_theme_bar_sm.svg';
import EmptyStateCompetitorTrafficBarLg from '@/assets/images/empty_state_traffic_bar_lg.svg';
import EmptyStateCompetitorTrafficBarMd from '@/assets/images/empty_state_traffic_bar_md.svg';
import EmptyStateCompetitorTrafficBarSm from '@/assets/images/empty_state_traffic_bar_sm.svg';
import EmptyStateCompetitorTrafficLineLg from '@/assets/images/empty_state_traffic_line_lg.svg';
import EmptyStateCompetitorTrafficLineMd from '@/assets/images/empty_state_traffic_line_md.svg';
import EmptyStateCompetitorTrafficLineSm from '@/assets/images/empty_state_traffic_line_sm.svg';
import EmptyStateBarres from '@/assets/images/empty-state-barres.svg';
import EmptyStateBarresNoContentData from '@/assets/images/empty-state-barres-no-content-data.svg';
import { SimpleBodyEmptyState, WrapperWithBackground } from '@/components/EmptyState/commons';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';

export const NO_CONTENTS = 'noContents';
export const NO_NEW_CONTENTS = 'noNewContents';
export const NO_UPDATED_CONTENTS = 'noUpdatedContents';
export const NO_POSITION_TRACKED_REPORT = 'noPositionTracked';
export const NO_NEW_POSITION_TRACKED_REPORT = 'noNewPositionTracked';
export const NEW_POSITION_TRACKED_LIMIT_REPORT = 'newPositionTrackedLimit';
export const POSITION_TRACKED_LIMIT_REPORT = 'positionTrackedLimit';
//COMPETITOR REPORTS
export const NO_COMPETITOR_CONTENT_DATA = 'noCompetitorContentData';
export const NO_COMPETITOR_THEME_DATA = 'noCompetitorThemeData';
export const NO_COMPETITOR_TRAFFIC_BAR_DATA = 'noCompetitorTrafficBarData';
export const NO_COMPETITOR_TRAFFIC_LINE_DATA = 'noCompetitorTrafficLineData';
export const NO_COMPETITOR_EVOLUTION_12MONTHS_DATA = 'noCompetitorEvolution12MonthsData';
export const NO_COMPETITOR_EVOLUTION_3YEARS_DATA = 'noCompetitorEvolution3YearsData';
export const NO_COMPETITOR_EVOLUTION_3YEARS_MID_DATA = 'noCompetitorEvolution3YearsMidData';
export const NO_COMPETITOR_STACK_BAR = 'noCompetitorStackBar';
export const NO_COMPETITOR_STACK_BAR_FULL = 'noCompetitorStackBarFull';
export const NO_COMPETITOR_HORIZONTAL_STACK_BAR_FULL = 'noCompetitorHorizontalStackBarFull';
export const NO_REPORTS_BAR = {
  all: 'noReportsBar',
  existing: 'noReportsBarUpdated',
  new: 'noReportsBarNew',
};
export const NO_REPORTS_LINE = {
  all: 'noReportsLine',
  existing: 'noReportsLineUpdated',
  new: 'noReportsLineNew',
};
export const NO_REPORTS_AREA = {
  all: 'noReportsArea',
  existing: 'noReportsAreaUpdated',
  new: 'noReportsAreaNew',
};
export const NO_REPORTS_STACKED_BAR = {
  all: 'noReportsStackedBar',
  existing: 'noReportsStackedBarUpdated',
  new: 'noReportsStackedBarNew',
};

const NoContentsVerticalBars = ({ version = NO_CONTENTS, isFull = false }) => {
  const { t } = useTranslation();
  const { organizationId, workspaceId } = useParams();
  const { data: rankTrackingLimit } = useOrganizationFeatureSet(
    'rank-tracking:urls-tracked-number'
  );
  const DEFAULT_COMPETITOR_DATA = {
    buttonLabel: null,
    href: ``,
    title: t('empty-state:no-contents-vertical-bars.competitor-no-data.title'),
  };

  const DEFAULT_NO_CONTENT = {
    buttonLabel: t('empty-state:no-contents-vertical-bars.no-contents.button'),
    href: `/o/${organizationId}/w/${workspaceId}/planning/create-drafts`,
    imageUrl: EmptyStateBarres,
    title: t('empty-state:no-contents-vertical-bars.no-contents.title'),
  };

  const DEFAULT_NO_NEW_CONTENT = {
    buttonLabel: t('empty-state:no-contents-vertical-bars.no-new-contents.button'),
    href: `/o/${organizationId}/w/${workspaceId}/planning/create-drafts/new`,
    imageUrl: EmptyStateBarresNoContentData,
    title: t('empty-state:no-contents-vertical-bars.no-new-contents.title'),
  };

  const DEFAULT_NO_UPDATED_CONTENT = {
    buttonLabel: t('empty-state:no-contents-vertical-bars.no-updated-contents.button'),
    href: `/o/${organizationId}/w/${workspaceId}/planning/create-drafts/updated`,
    imageUrl: EmptyStateBarresNoContentData,
    title: t('empty-state:no-contents-vertical-bars.no-updated-contents.title'),
  };

  const NO_REPORT_BAR_IMAGES = {
    imageUrl: isFull ? EmptyStateBarresNoContentData : EmptyStateReportsBarLg,
    imageUrlDesktop: isFull ? EmptyStateReportsBarLg : EmptyStateReportsBarMd,
    imageUrlLaptop: EmptyStateReportsBarSm,
  };

  const NO_REPORT_LINE_IMAGES = {
    imageUrl: EmptyStateReportsLineLg,
    imageUrlDesktop: EmptyStateReportsLineMd,
    imageUrlLaptop: EmptyStateReportsLineSm,
  };

  const NO_REPORT_LINE_FULL_IMAGES = {
    imageUrl: EmptyStateReportsLineFullLg,
    imageUrlDesktop: EmptyStateReportsLineFullMd,
    imageUrlLaptop: EmptyStateReportsLineSm,
  };

  const NO_REPORT_LINE_AREA_IMAGES = {
    imageUrl: EmptyStateReportsAreaLg,
    imageUrlDesktop: EmptyStateReportsAreaMd,
    imageUrlLaptop: EmptyStateReportsAreaSm,
  };

  const NO_REPORT_STACKED_BAR_IMAGES = {
    imageUrl: EmptyStateReportsStackedBarLg,
    imageUrlDesktop: EmptyStateReportsStackedBarMd,
    imageUrlLaptop: EmptyStateReportsStackedBarSm,
  };

  const CONTENTS_EMPTYSTATE_VALUES = {
    [NEW_POSITION_TRACKED_LIMIT_REPORT]: {
      buttonLabel: t('empty-state:no-contents-vertical-bars.new-tracked-positions-limit.button'),
      href: `/o/${organizationId}/w/${workspaceId}/settings/billing`,
      ...NO_REPORT_BAR_IMAGES,
      title: t('empty-state:no-contents-vertical-bars.new-tracked-positions-limit.title', {
        limit: rankTrackingLimit,
      }),
    },
    [NO_COMPETITOR_CONTENT_DATA]: {
      ...DEFAULT_COMPETITOR_DATA,
      imageUrl: EmptyStateCompetitorContentLg,
      imageUrlDesktop: EmptyStateCompetitorContentMd,
      imageUrlLaptop: EmptyStateCompetitorContentSm,
    },
    [NO_COMPETITOR_EVOLUTION_12MONTHS_DATA]: {
      ...DEFAULT_COMPETITOR_DATA,
      imageUrl: EmptyStateCompetitorHorizontalBarLg,
      imageUrlDesktop: EmptyStateCompetitorHorizontalBarMd,
      imageUrlLaptop: EmptyStateCompetitorHorizontalBarSm,
    },
    [NO_COMPETITOR_EVOLUTION_3YEARS_DATA]: {
      ...DEFAULT_COMPETITOR_DATA,
      imageUrl: EmptyStateCompetitor3BarsLg,
      imageUrlDesktop: EmptyStateCompetitor3BarsMd,
      imageUrlLaptop: EmptyStateCompetitor3BarsSm,
    },
    [NO_COMPETITOR_EVOLUTION_3YEARS_MID_DATA]: {
      ...DEFAULT_COMPETITOR_DATA,
      imageUrl: EmptyStateCompetitor3BarsMidLg,
      imageUrlDesktop: EmptyStateCompetitor3BarsMd,
      imageUrlLaptop: EmptyStateCompetitor3BarsSm,
    },
    [NO_COMPETITOR_HORIZONTAL_STACK_BAR_FULL]: {
      ...DEFAULT_COMPETITOR_DATA,
      imageUrl: EmptyStateCompetitorHorizontalStackBarFullLg,
      imageUrlDesktop: EmptyStateCompetitorHorizontalStackBarFullMd,
      imageUrlLaptop: EmptyStateCompetitorHorizontalStackBarFullSm,
    },
    [NO_COMPETITOR_STACK_BAR]: {
      ...DEFAULT_COMPETITOR_DATA,
      imageUrl: EmptyStateCompetitorStackBarLg,
      imageUrlDesktop: EmptyStateCompetitorStackBarMd,
      imageUrlLaptop: EmptyStateCompetitorStackBarSm,
    },
    [NO_COMPETITOR_STACK_BAR_FULL]: {
      ...DEFAULT_COMPETITOR_DATA,
      imageUrl: EmptyStateCompetitorStackBarFullLg,
      imageUrlDesktop: EmptyStateCompetitorStackBarFullMd,
      imageUrlLaptop: EmptyStateCompetitorStackBarFullSm,
    },
    [NO_COMPETITOR_THEME_DATA]: {
      ...DEFAULT_COMPETITOR_DATA,
      imageUrl: EmptyStateCompetitorThemeLg,
      imageUrlDesktop: EmptyStateCompetitorThemeMd,
      imageUrlLaptop: EmptyStateCompetitorThemeSm,
    },
    [NO_COMPETITOR_TRAFFIC_BAR_DATA]: {
      ...DEFAULT_COMPETITOR_DATA,
      imageUrl: EmptyStateCompetitorTrafficBarLg,
      imageUrlDesktop: EmptyStateCompetitorTrafficBarMd,
      imageUrlLaptop: EmptyStateCompetitorTrafficBarSm,
    },
    [NO_COMPETITOR_TRAFFIC_LINE_DATA]: {
      ...DEFAULT_COMPETITOR_DATA,
      imageUrl: EmptyStateCompetitorTrafficLineLg,
      imageUrlDesktop: EmptyStateCompetitorTrafficLineMd,
      imageUrlLaptop: EmptyStateCompetitorTrafficLineSm,
    },
    [NO_CONTENTS]: DEFAULT_NO_CONTENT,
    [NO_NEW_CONTENTS]: DEFAULT_NO_NEW_CONTENT,
    [NO_NEW_POSITION_TRACKED_REPORT]: {
      buttonLabel: t('empty-state:no-contents-vertical-bars.no-new-tracked-positions.button'),
      href: `/o/${organizationId}/w/${workspaceId}/planning`,
      ...NO_REPORT_BAR_IMAGES,
      title: t('empty-state:no-contents-vertical-bars.no-new-tracked-positions.title', {
        limit: rankTrackingLimit,
      }),
    },
    [NO_POSITION_TRACKED_REPORT]: {
      buttonLabel: t('empty-state:no-contents-vertical-bars.no-tracked-positions.button'),
      href: `/o/${organizationId}/w/${workspaceId}/planning`,
      ...NO_REPORT_BAR_IMAGES,
      title: t('empty-state:no-contents-vertical-bars.no-tracked-positions.title', {
        limit: rankTrackingLimit,
      }),
    },
    [NO_REPORTS_BAR.all]: {
      ...DEFAULT_NO_CONTENT,
      ...NO_REPORT_BAR_IMAGES,
    },
    [NO_REPORTS_BAR.new]: {
      ...DEFAULT_NO_NEW_CONTENT,
      ...NO_REPORT_BAR_IMAGES,
    },
    [NO_REPORTS_BAR.existing]: {
      ...DEFAULT_NO_UPDATED_CONTENT,
      ...NO_REPORT_BAR_IMAGES,
    },
    [NO_REPORTS_LINE.all]: {
      ...DEFAULT_NO_CONTENT,
      ...(isFull ? NO_REPORT_LINE_FULL_IMAGES : NO_REPORT_LINE_IMAGES),
    },
    [NO_REPORTS_LINE.new]: {
      ...DEFAULT_NO_NEW_CONTENT,
      ...(isFull ? NO_REPORT_LINE_FULL_IMAGES : NO_REPORT_LINE_IMAGES),
    },
    [NO_REPORTS_LINE.existing]: {
      ...DEFAULT_NO_UPDATED_CONTENT,
      ...(isFull ? NO_REPORT_LINE_FULL_IMAGES : NO_REPORT_LINE_IMAGES),
    },
    [NO_REPORTS_AREA.all]: {
      ...DEFAULT_NO_CONTENT,
      ...NO_REPORT_LINE_AREA_IMAGES,
    },
    [NO_REPORTS_AREA.new]: {
      ...DEFAULT_NO_NEW_CONTENT,
      ...NO_REPORT_LINE_AREA_IMAGES,
    },
    [NO_REPORTS_AREA.existing]: {
      ...DEFAULT_NO_UPDATED_CONTENT,
      ...NO_REPORT_LINE_AREA_IMAGES,
    },
    [NO_REPORTS_STACKED_BAR.all]: {
      ...DEFAULT_NO_CONTENT,
      ...NO_REPORT_STACKED_BAR_IMAGES,
    },
    [NO_REPORTS_STACKED_BAR.new]: {
      ...DEFAULT_NO_NEW_CONTENT,
      ...NO_REPORT_STACKED_BAR_IMAGES,
    },
    [NO_REPORTS_STACKED_BAR.existing]: {
      ...DEFAULT_NO_UPDATED_CONTENT,
      ...NO_REPORT_STACKED_BAR_IMAGES,
    },

    [NO_UPDATED_CONTENTS]: DEFAULT_NO_UPDATED_CONTENT,
    [POSITION_TRACKED_LIMIT_REPORT]: {
      buttonLabel: t('empty-state:no-contents-vertical-bars.tracked-positions-limit.button'),
      href: `/o/${organizationId}/w/${workspaceId}/settings/billing`,
      ...NO_REPORT_BAR_IMAGES,
      title: t('empty-state:no-contents-vertical-bars.tracked-positions-limit.title', {
        limit: rankTrackingLimit,
      }),
    },
  };

  return (
    <WrapperWithBackground
      backgroundSize={
        isFull ||
        [
          NO_COMPETITOR_CONTENT_DATA,
          NO_COMPETITOR_THEME_DATA,
          NO_COMPETITOR_TRAFFIC_LINE_DATA,
          NO_COMPETITOR_TRAFFIC_BAR_DATA,
          NO_COMPETITOR_EVOLUTION_12MONTHS_DATA,
          NO_COMPETITOR_EVOLUTION_3YEARS_DATA,
          NO_COMPETITOR_EVOLUTION_3YEARS_MID_DATA,
          NO_COMPETITOR_STACK_BAR,
          NO_COMPETITOR_STACK_BAR_FULL,
          NO_COMPETITOR_HORIZONTAL_STACK_BAR_FULL,
        ].includes(version)
          ? '100%'
          : 'contain'
      }
      imageUrl={CONTENTS_EMPTYSTATE_VALUES[version].imageUrl}
      imageUrlDesktop={CONTENTS_EMPTYSTATE_VALUES[version].imageUrlDesktop}
      imageUrlLaptop={CONTENTS_EMPTYSTATE_VALUES[version].imageUrlLaptop}
    >
      <SimpleBodyEmptyState
        buttonLabel={CONTENTS_EMPTYSTATE_VALUES[version].buttonLabel}
        title={CONTENTS_EMPTYSTATE_VALUES[version].title}
        url={CONTENTS_EMPTYSTATE_VALUES[version].href}
      />
    </WrapperWithBackground>
  );
};

export default NoContentsVerticalBars;
