import useGetContentById from '@/apis/semji/contents/useGetContentById';
import useGetContentVersionById from '@/apis/semji/contents/useGetVersionById/useGetVersionById';

export default function useGetVersionData({
  contentId,
  versionId,
}: {
  contentId: string;
  versionId: string;
}) {
  const getContentReq = useGetContentById({ contentId, enabled: !!contentId && !versionId });
  const getContentVersionsReq = useGetContentVersionById({
    contentId,
    enabled: !!versionId,
    versionId,
  });

  return {
    html: versionId ? getContentVersionsReq.data?.content : getContentReq.data?.html,
    metaDescription: versionId
      ? getContentVersionsReq.data?.metaDescription
      : getContentReq.data?.metaDescription,
    title: versionId ? getContentVersionsReq.data?.title : getContentReq.data?.title,
  };
}
