import './FactCheckButton.scss';

import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ClickableTooltip } from 'semji-core/components/ClickableTooltip';
import { InfoLabel } from 'semji-core/components/InfoLabel';
import { Tooltip } from 'semji-core/components/Tooltip';

import FactCheckingIcon from '@/components/icons/FactCheckingIcon';

export default function FactCheckButton({
  disabled,
  factCheckingFeatureSetEnabled = false,
  factCheckingEnabled = false,
  toggleFactChecking,
}: {
  disabled?: boolean;
  factCheckingFeatureSetEnabled: boolean;
  factCheckingEnabled: boolean;
  toggleFactChecking: () => void;
}) {
  const { t } = useTranslation();
  const dropDrownActionsRef = useRef({
    handleClose: () => null,
    handleOpen: () => null,
  });

  let factCheckButtonClassName = 'fact-checking-button';
  factCheckButtonClassName += factCheckingFeatureSetEnabled
    ? ''
    : ' fact-checking-button--disabled';
  factCheckButtonClassName += factCheckingEnabled ? ' fact-checking-button--selected' : '';

  if (disabled) {
    return;
  }

  return (
    <div
      className={factCheckButtonClassName}
      data-intercom-target="fact-check-toggle"
      onClick={toggleFactChecking}
    >
      {factCheckingFeatureSetEnabled ? (
        <Tooltip
          className="fact-checking-button__icon"
          description={t(`fact:badge.tooltip.description`)}
          isFrame={false}
          popoverOptions={{ placement: 'bottom' }}
          title={
            <div className="flex gap-2">
              <span>
                {t(
                  `fact:badge.tooltip.${
                    factCheckingEnabled ? 'deactivate-title' : 'activate-title'
                  }`
                )}
              </span>
              <InfoLabel variant="beta">{t(`common:labels.beta`)}</InfoLabel>
            </div>
          }
          tooltipClassName="fact-checking-button__tooltip"
        >
          <FactCheckingIcon />
        </Tooltip>
      ) : (
        <ClickableTooltip
          actionsRef={dropDrownActionsRef}
          anchorElement={<FactCheckingIcon />}
          isFrame={false}
          offsetValue={10}
          querySelectorListener="#root"
          width="310px"
        >
          <div className="fact-checking-button__dropdown">
            <span className="fact-checking-button__dropdown__title">
              {t(`fact:badge.tooltip.ai-fact-checking`)}
            </span>
            <span>{t(`fact:badge.tooltip.upgrade-plan`)}</span>
            <a
              className="fact-checking-button__dropdown__link"
              href={t('fact:badge.tooltip.learn-more-link')}
              rel="noreferrer"
              target="_blank"
            >
              {t('fact:badge.tooltip.learn-more')}
            </a>
          </div>
        </ClickableTooltip>
      )}
    </div>
  );
}
