import { ReportMetric, ReportMetricKey } from '@/containers/Report/utils/types';

export enum ENUM_REPORT_TAB_VARIANT {
  Dashboard = 'dashboard',
  Report = 'report',
}

export interface ReportTabsProps {
  currentMetricKey: ReportMetricKey;
  metricsList: ReportMetric[];
  maxInlineMetrics?: number;
  selectMetricClick: (metricKey: ReportMetricKey) => void;
  variant?: ENUM_REPORT_TAB_VARIANT;
  showMoreButton?: boolean;
}
export interface ReportTabProps extends React.HTMLAttributes<HTMLDivElement> {
  startAdornmentIcon?: React.ReactNode;
  label: string;
  feature: string;
  endAdornmentIcon: React.ReactNode;
}
