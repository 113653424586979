import React from 'react';

import SvgWrapper from './SvgWrapper';

const PowerOffIcon = (props) => (
  <SvgWrapper {...props}>
    <path d="M16 9.5c0 4.136-3.364 7.5-7.5 7.5s-7.5-3.364-7.5-7.5c0-3.498 2.476-6.579 5.888-7.326l0.214 0.977c-2.956 0.647-5.102 3.317-5.102 6.349 0 3.584 2.916 6.5 6.5 6.5s6.5-2.916 6.5-6.5c0-3.028-2.143-5.698-5.096-6.348l0.215-0.977c3.408 0.751 5.881 3.831 5.881 7.325zM9 0h-1v10h1v-10z" />
  </SvgWrapper>
);

export default PowerOffIcon;
