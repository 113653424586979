import './MetadataBar.scss';

import { useQueryClient } from '@tanstack/react-query';
import en from 'date-fns/locale/en-US';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';
import i18next from 'i18next';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Avatar } from 'semji-core/components/Avatar';
import { ColoredDot } from 'semji-core/components/ColoredDot';
import { ContentStatusesSelect } from 'semji-core/components/ContentStatusesSelect';
import { DatePicker } from 'semji-core/components/DatePicker';
import { DROPDOWN_SIZES } from 'semji-core/components/Dropdown';
import { Dropdown } from 'semji-core/components/Dropdown';
import { FolderSelect } from 'semji-core/components/FolderSelect';
import { useHover } from 'semji-core/hooks/useHover';
import { useNullUser } from 'semji-core/hooks/useNullUser';
import { UserIcon } from 'semji-core/icons/UserIcon';
import { getClassNames } from 'semji-core/utils/getClassNames';

import { SCOPE_CONTENTS } from '@/apis/semji/constants';
import useGetContentById from '@/apis/semji/contents/useGetContentById';
import usePutContent from '@/apis/semji/contents/usePutContent';
import useGetCurrentWorkspaceAllFolders from '@/apis/semji/folders/useGetCurrentWorkspaceAllFolders';
import usePostFolder from '@/apis/semji/folders/usePostFolder';
import UserSelect from '@/components/Select/UserSelect/UserSelect';
import { useContentContainerContext } from '@/containers/Content/ContentContainerContext';
import {
  AnchorWrapper,
  ChildrenWrapper,
} from '@/containers/Content/HeaderComponents/MetadataBar/MetadataBar.styled';
import Flex from '@/design-system/components/Flex';
import useIsUserLimitedGuestWriter from '@/hooks/useIsUserLimitedGuestWriter';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import useWorkspaceStatuses from '@/hooks/useWorkspaceStatuses';
import { getOrganizationById } from '@/store/reducers';

function MetadataBar() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { organizationId, workspaceId, contentId } = useParams();
  const queryClient = useQueryClient();
  const [hoverRef, isHovered] = useHover();

  const { getWorkspaceUsers } = useContentContainerContext();

  const nullUser = useNullUser({
    translations: {
      unassigned: t('common:user-picker.unassigned'),
    },
  });

  const apiRootUrl = import.meta.env.VITE_REACT_APP_SEMJI_API_ROOT_URL;

  const getContentById = useGetContentById({
    contentId,
  });

  const putContent = usePutContent({
    contentId,
    onMutate: async (params) => {
      const queryKey = [SCOPE_CONTENTS.CONTENT, contentId];
      await queryClient.cancelQueries({ queryKey });
      const previousData = queryClient.getQueryData(queryKey);
      if (params.content.hasOwnProperty('assignedTo')) {
        queryClient.setQueryData(queryKey, {
          ...previousData,
          assignedTo: getWorkspaceUsers.data.find(
            (user) => user.id === params.content.assignedTo?.split('/')?.[2]
          ),
        });
      }
      if (params.content.hasOwnProperty('dueDate')) {
        queryClient.setQueryData(queryKey, {
          ...previousData,
          dueDate: params.content.dueDate && params.content.dueDate.toISOString(),
        });
      }
      if (params.content.hasOwnProperty('contentStatus')) {
        queryClient.setQueryData(queryKey, {
          ...previousData,
          contentStatus: contentStatuses.find(
            (status) => status.id === params.content.contentStatus?.split('/')?.[2]
          ),
        });
      }
      if (params.content.hasOwnProperty('folderId')) {
        queryClient.setQueryData(queryKey, {
          ...previousData,
          folderId: params.content.folderId,
        });
      }

      return { previousData };
    },
    onSettled: () => {
      getContentById.refetch();
    },
  });

  const statuses = useWorkspaceStatuses(); // todo : refactor with react query
  const getCurrentWorkspaceAllFolders = useGetCurrentWorkspaceAllFolders();
  const currentOrganization = useSelector((state) => getOrganizationById(state, organizationId));
  const { isFeatureEnabled: unlimitedFoldersFeatureSet } = useOrganizationFeatureSet(
    'organization:folders:has-unlimited-amount'
  );
  const { data: foldersLimit = 0 } = useOrganizationFeatureSet('organization:folders:amount');
  const isOrganizationFoldersLimit =
    currentOrganization.foldersCount >= foldersLimit && !unlimitedFoldersFeatureSet;
  const createNewFolder = usePostFolder({
    onSuccess: async () => {
      getCurrentWorkspaceAllFolders.refetch();
    },
  });

  function handleNewFolderCreation(newFolder) {
    createNewFolder.mutate(newFolder);
  }

  const isOriginalContent = getContentById.data?.type === 'ORIGINAL';
  const isPublishedContent = getContentById.data?.type === 'PUBLISHED';
  const contentStatuses = statuses.filter((status) =>
    isPublishedContent ? status.publish : !status.publish
  );

  const isUserLimitedGuestWriter = useIsUserLimitedGuestWriter();

  const locales = [en, fr, es, it];
  const locale = locales.find((locale) => locale.code === i18next.language) || en;

  async function handleChangeAssignation(id) {
    const assignation = getWorkspaceUsers.data.find((user) => user?.id === id) || nullUser;
    await putContent.mutateAsync({
      content: { assignedTo: assignation?.id === nullUser.id ? null : assignation?.['@id'] },
    });

    if (isUserLimitedGuestWriter) {
      // If user is API_WORKSPACE_ROLE_LIMITED_GUEST_WRITER and he changes the assignation
      // redirect to planning because he no longer has access to the content
      navigate(`/o/${organizationId}/w/${workspaceId}/planning`, { replace: true });
    }
  }

  function handleChangeDate(date) {
    putContent.mutate({ content: { dueDate: date } });
  }

  function handleChangeStatus(status) {
    putContent.mutate({ content: { contentStatus: status['@id'] } });
  }

  function handleChangeFolder(value) {
    putContent.mutate({ content: { folderId: value } });
  }

  useEffect(() => {
    return () => {
      getContentById.remove();
      getWorkspaceUsers.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!contentId || isOriginalContent || getContentById.isLoading) {
    return null;
  }

  const activeWorkspaceFolder = getCurrentWorkspaceAllFolders.data?.find(
    (folder) => folder?.id === getContentById.data.folderId
  )?.id;

  return (
    <Dropdown
      anchorElement={
        <Flex ref={hoverRef} justifyContent="center" width="200px">
          <AnchorWrapper
            alignItems="center"
            backgroundColor={isHovered && 'dark080'}
            cursor="pointer"
            gap="8px"
            padding="4px 8px 4px 4px"
          >
            {!getContentById.data.assignedTo?.id ? (
              <UserIcon
                className={getClassNames(
                  'metadatabar__user-icon',
                  isHovered && 'metadatabar__user-icon--white'
                )}
                rounded
              />
            ) : (
              <Avatar
                apiRootUrl={apiRootUrl}
                email={getContentById.data.assignedTo?.email}
                firstName={getContentById.data.assignedTo?.firstName}
                lastName={getContentById.data.assignedTo?.lastName}
                profileImageHash={getContentById.data.assignedTo?.profileImageHash}
                size="30px"
                translations={{ unassigned: t('common:user-picker.unassigned') }}
                uploadedAvatarUrl={getContentById.data.assignedTo?.uploadedAvatarUrl}
              />
            )}
            <ColoredDot color={getContentById.data.contentStatus.color} />
            <span
              className={getClassNames(
                'metadatabar__due-date',
                (isHovered || getContentById.data.dueDate) && 'metadatabar__due-date--white'
              )}
            >
              {getContentById.data.dueDate
                ? DateTime.fromISO(getContentById.data.dueDate)
                    .setLocale(locale.code)
                    .toFormat('d LLL yy')
                    .toLocaleLowerCase()
                : t('content:metadatabar.no-due-date')}
            </span>
          </AnchorWrapper>
        </Flex>
      }
      id="content-metadatatabar-dropdown"
      isFrame={false}
      offsetValue={12}
      querySelectorListener="#root"
    >
      <ChildrenWrapper backgroundColor="white" flexDirection="column" gap="16px" padding="32px">
        <span className="metadatabar__title">{t('content:metadatabar.planification')}</span>
        <Flex flexDirection="column" gap="20px">
          <UserSelect
            key={getContentById.data.assignedTo?.id || nullUser?.id}
            addDefaultUser={true}
            dropDownPlacement="bottom"
            dropDownSize={DROPDOWN_SIZES.FULL_WIDTH}
            handleChange={handleChangeAssignation}
            longVersion
            options={getWorkspaceUsers.data}
            querySelectorListener="#content-metadatatabar-dropdown"
            small
            value={getContentById.data.assignedTo?.id || nullUser?.id}
            variant="column"
            width={260}
          />
          <DatePicker
            dropDownOffsetValue={{ crossAxis: 42 }}
            footer
            isFrame={false}
            locale={locale}
            querySelectorListener="#content-metadatatabar-dropdown"
            setValue={handleChangeDate}
            translations={{
              dueDate: t('content:metadatabar.due-date'),
              reset: t('content:metadatabar.reset'),
              today: t('content:metadatabar.today'),
            }}
            value={getContentById.data.dueDate && new Date(getContentById.data.dueDate)}
          />
          <ContentStatusesSelect
            contentStatuses={contentStatuses}
            disabled={isPublishedContent}
            isFrame={false}
            querySelectorListener="#content-metadatatabar-dropdown"
            renderClassName="metadatabar__content-status-select"
            setValue={handleChangeStatus}
            value={getContentById.data.contentStatus}
          />
          <FolderSelect
            createNewFolder={handleNewFolderCreation}
            folders={getCurrentWorkspaceAllFolders.data}
            isCreationDisabled={isOrganizationFoldersLimit}
            isFrame={false}
            querySelectorListener="#content-metadatatabar-dropdown"
            setValue={handleChangeFolder}
            translations={{
              button: t('components:select.folder-select.create-folder.label-button'),
              cancel: t('components:select.folder-select.create-folder.cancel'),
              create_folder: t('components:select.folder-select.folder-select.label-create-folder'),
              folder_general: t('components:select.folder-select.label-folder-general'),
              label_no_parent: t('components:filters.folder-filters-panel.label-no-parent'),
              loading: t('components:select.folder-select.create-folder.label-loading'),
              placeHolder: t('components:select.folder-select.create-folder.input-placeholder'),
              search_placeholder: t(
                'components:select.folder-select.folder-select.search-placeholder'
              ),
              title: t('components:select.folder-select.create-folder.title'),
              tooltip_disabled: t('components:select.folder-select.create-folder.tooltip-disabled'),
            }}
            value={activeWorkspaceFolder}
            width={260}
            withFolderCreation
            withSearchInput
          />
        </Flex>
      </ChildrenWrapper>
    </Dropdown>
  );
}

export default MetadataBar;
