import { DELETE_BRAND_VOICE, SET_BRAND_VOICE } from '@/store/actions/actionTypes';

const INITIAL_STATE = {};

const brandVoiceByWorkspaceId = (
  state: { [name: string]: string } = INITIAL_STATE,
  action: {
    type: string;
    value?: string;
    workspaceId: string | undefined;
  }
) => {
  const { type, value, workspaceId } = action;

  if (!workspaceId) return state;

  switch (type) {
    case SET_BRAND_VOICE: {
      return {
        ...state,
        [workspaceId]: value,
      };
    }

    case DELETE_BRAND_VOICE: {
      delete state[workspaceId];
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};

export default brandVoiceByWorkspaceId;
