import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Mention, MentionsInput } from 'react-mentions';
import { useSearchParams } from 'react-router-dom';
import { Avatar } from 'semji-core/components/Avatar';
import { ClickAwayListener } from 'semji-core/components/ClickAwayListener';

import { InfoBadge } from '@/components/Settings/UsersSettings/UserSettings.styled';
import { FlexTooltipComponent } from '@/components/Tooltip/Tooltip';
import { useContentContainerContext } from '@/containers/Content/ContentContainerContext';
import { FOCUSED_THREAD_ID_QUERY_PARAM } from '@/containers/Content/TinyMceComponents/Editor/hooks/useComment/constants';
import Flex from '@/design-system/components/Flex';
import { NULL_USER } from '@/hooks/useNullUser';

import {
  FakeTextArea,
  FakeTextAreaText,
  Separator,
  StyledAtIcon,
  StyledCancelIcon,
  StyledSubmitIcon,
  StyledValidatedIcon,
  stylesMentions,
  stylesMentionsInput,
  TextAreaFooter,
  TextAreaWrapper,
  UserEmail,
  UserInfo,
  UserItem,
  UserName,
} from './TextArea.styled';

function TextArea({ onSubmit, defaultValue = '', threadId, onClose, ...props }) {
  const { t } = useTranslation();
  const {
    comments: { isPendingThread, focusOnThread, isFocusOnThread },
    getWorkspaceUsers,
  } = useContentContainerContext();

  const [query, setQuery] = useSearchParams();
  const inputRef = useRef(null);
  const [value, setValue] = useState(defaultValue);
  const [showPlaceholder, setShowPlaceholder] = useState(true);

  const isNewThread = isPendingThread(threadId);

  function handleChange(_, newValue) {
    setValue(() => newValue);
  }

  function handleKeyDown(e) {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  }

  function handleSubmit(e) {
    e?.stopPropagation();
    if (value && !!value.trim()) {
      onSubmit(value);
      setValue('');
    }
  }

  function handleCloseEdit() {
    // close action is when we are in comment editing mode
    if (onClose) {
      onClose();
      return;
    }

    // a new comment but not a new thread
    if (!isNewThread) {
      setShowPlaceholder(true);
      return;
    }

    // we are in a new thread, we need to remove the query params which will trigger the remove of the new thread and query param and the highlight
    if (isNewThread) {
      query.delete(FOCUSED_THREAD_ID_QUERY_PARAM);
      setQuery(query, { replace: true });
    }
  }

  function handleShowPlaceholder(e) {
    e?.stopPropagation();
    if (!value?.length) {
      setShowPlaceholder(true);
    }
  }

  function handleClick(e) {
    e.stopPropagation();

    if (!isFocusOnThread(threadId)) {
      handleRemovePlaceholder();
    }
  }

  function handleRemovePlaceholder(e) {
    e?.stopPropagation();
    focusOnThread(threadId);
    setShowPlaceholder(false);
    setTimeout(() => inputRef.current?.focus());
    // we have to had a timeout here because the selection is not yet updated
  }

  function addAtCharacter() {
    setValue(`${value} @`);
    inputRef.current.focus();
  }

  function renderSuggestion(suggestion) {
    const apiRootUrl = import.meta.env.VITE_REACT_APP_SEMJI_API_ROOT_URL;
    const { workspaceUser: user } = suggestion;
    const hasPendingInvite = user && !user.lastLoginAt;

    return (
      <UserItem alignItems="center" cursor="pointer" gap="10px" id={user.id}>
        <Avatar
          apiRootUrl={apiRootUrl}
          email={user.email}
          firstName={user.firstName}
          lastName={user.lastName}
          profileImageHash={user.profileImageHash}
          size="small"
          translations={{ unassigned: t('common:user-picker.unassigned') }}
          uploadedAvatarUrl={user.uploadedAvatarUrl}
        />
        <Flex gap="4px" justifyContent="space-between" overflow="hidden" width="100%">
          <UserInfo flexDirection="column">
            <UserName>
              {user.firstName} {user.lastName}
            </UserName>
            <UserEmail>{user.email}</UserEmail>
          </UserInfo>

          {hasPendingInvite && (
            <Flex alignItems="center">
              <InfoBadge>{t('components:select.user-select.label-pending-invite')}</InfoBadge>
            </Flex>
          )}
        </Flex>
      </UserItem>
    );
  }

  const data = getWorkspaceUsers.data
    .filter((workspaceUser) => workspaceUser.id !== NULL_USER.id)
    .map((workspaceUser) => ({
      display: `${workspaceUser.firstName} ${workspaceUser.lastName} ${workspaceUser.email} ${workspaceUser.firstName}${workspaceUser.lastName} ${workspaceUser.lastName}${workspaceUser.firstName}`,
      id: workspaceUser.id,
      workspaceUser,
    }));

  function getDisplay(userId, display) {
    const user = getWorkspaceUsers.data.find((workspaceUser) => workspaceUser.id === userId);
    if (user) {
      if (user.firstName) {
        return `${user.firstName} ${user.lastName || ''}`;
      } else {
        return user.email;
      }
    } else {
      return display;
    }
  }

  useEffect(() => {
    if (inputRef.current && value?.length) {
      inputRef.current.setSelectionRange(value.length, value.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef.current]);

  if (!isNewThread && !defaultValue && showPlaceholder) {
    return (
      <FakeTextArea cursor="pointer" height="36px" width="100%" onClick={handleRemovePlaceholder}>
        <FakeTextAreaText justifyContent="space-between" padding="8px" width="100%">
          {t('comments:text-area.reply')}
          <StyledSubmitIcon disabled={!value?.trim()} displayOnHover onClick={handleSubmit} />
        </FakeTextAreaText>
      </FakeTextArea>
    );
  }

  return (
    <ClickAwayListener
      fullWidth
      outsideAction={handleShowPlaceholder}
      querySelectorListener="#root"
      {...props}
    >
      <TextAreaWrapper
        backgroundColor="white"
        flexDirection="column"
        width="95%"
        onClick={handleClick}
      >
        <MentionsInput
          allowSpaceInQuery
          allowSuggestionsAboveCursor
          inputRef={inputRef}
          placeholder={t('comments:text-area.add-comment')}
          style={stylesMentionsInput}
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
        >
          <Mention
            appendSpaceOnAdd
            data={data}
            displayTransform={getDisplay}
            markup="@[user_public_id:__id__]"
            renderSuggestion={renderSuggestion}
            style={stylesMentions}
            trigger="@"
          />
        </MentionsInput>
        <TextAreaFooter alignItems="center" gap="12px" justifyContent="flex-end" padding="8px 16px">
          <Flex>
            <FlexTooltipComponent cursor="pointer" title={t('comments:text-area.add-mention')}>
              <StyledAtIcon onClick={addAtCharacter} />
            </FlexTooltipComponent>
          </Flex>
          <Separator />
          <Flex alignItems="center" gap="10px">
            <FlexTooltipComponent cursor="pointer" title={t('comments:text-area.cancel')}>
              <StyledCancelIcon onClick={handleCloseEdit} />
            </FlexTooltipComponent>
            <FlexTooltipComponent cursor="pointer" title={t('comments:text-area.submit')}>
              {defaultValue ? (
                <StyledValidatedIcon
                  disabled={!value?.trim() || defaultValue === value}
                  onClick={handleSubmit}
                />
              ) : (
                <StyledSubmitIcon disabled={!value?.trim()} onClick={handleSubmit} />
              )}
            </FlexTooltipComponent>
          </Flex>
        </TextAreaFooter>
      </TextAreaWrapper>
    </ClickAwayListener>
  );
}

export default TextArea;
