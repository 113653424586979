import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import IllustrationContactSales from '@/assets/images/illustration_contact_sales.svg';
import { LinkLikeButton } from '@/components/Button/Button';
import { DeleteDialog } from '@/components/Dialog/DeleteDialog';
import DialogInformationTemplate from '@/components/Dialog/DialogInformationTemplate';
import { DefaultLink } from '@/components/Navigation/Link';
import { InlineText } from '@/components/Text/Inline';
import { ParagraphText } from '@/components/Text/Paragraph';
import UserService from '@/services/User';
import { logoutUser } from '@/store/actions/user';
import { selectCurrentOrganization } from '@/store/selectors/selectCurrentOrganization';

import { Title } from '.';

const LAST_ORGANIZATION_ADMIN_EXCEPTION = 'last_organization_admin_exception';

function DeleteAccount() {
  const { t } = useTranslation();
  const { organizationId, workspaceId } = useParams();
  const dispatch = useDispatch();
  const _userService = new UserService();
  const currentOrganization = useSelector(selectCurrentOrganization);
  const userId = useSelector((state) => state.user.id);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showLastOrganizationAdminErrorModal, setShowLastOrganizationAdminErrorModal] =
    useState(false);
  const [deleting, setDeleting] = useState(false);
  const [errorDeleting, setErrorDeleting] = useState(null);

  const confirmDelete = async () => {
    setDeleting(true);
    setErrorDeleting(false);

    try {
      await _userService.delete(userId);
      dispatch(logoutUser());
    } catch (error) {
      if (error.status === 400 && error.error_code === LAST_ORGANIZATION_ADMIN_EXCEPTION) {
        setShowDeleteModal(false);
        setShowLastOrganizationAdminErrorModal(true);
      } else {
        setErrorDeleting(true);
      }
    } finally {
      setDeleting(false);
    }
  };

  return (
    <>
      <Title noMargin weight="strong">
        {t('settings:workspace.delete-account.title')}
      </Title>
      <ParagraphText color="dark080" noPadding weight="medium">
        {t('settings:workspace.delete-account.text')}
      </ParagraphText>
      <LinkLikeButton noPadding onClick={() => setShowDeleteModal(true)}>
        {t('settings:workspace.delete-account.button-delete-account')}
      </LinkLikeButton>
      <DeleteDialog
        confirmLabel={t('settings:workspace.delete-account.dialog-delete-confirm-label')}
        error={errorDeleting}
        loading={deleting}
        matchConfirmMessage={t(
          'settings:workspace.delete-account.dialog-delete-match-confirm-message'
        )}
        show={showDeleteModal}
        text={
          <Trans
            components={{
              br: <br />,
              inline: <InlineText color="dark080" weight="strong" />,
              paragraph: <ParagraphText color="dark080" noPadding weight="medium" />,
            }}
            i18nKey={'settings:workspace.delete-account.dialog-delete-content'}
          />
        }
        title={t('settings:workspace.delete-account.dialog-delete-title')}
        onCancel={() => {
          setShowDeleteModal(false);
          setErrorDeleting(false);
        }}
        onConfirm={confirmDelete}
      />
      <DialogInformationTemplate
        imageAlt={t('settings:workspace.delete-account.image-alt-contact-support')}
        imagePath={IllustrationContactSales}
        show={showLastOrganizationAdminErrorModal}
        title={t('settings:workspace.delete-account.dialog-info-title', {
          organizationName: currentOrganization?.name,
        })}
        withoutButton
        onClose={() => setShowLastOrganizationAdminErrorModal(false)}
      >
        <Trans
          components={{
            organizationLink: (
              <DefaultLink
                to={
                  workspaceId
                    ? `/o/${organizationId}/w/${workspaceId}/settings/organization-users`
                    : `/o/${organizationId}/settings/organization-users`
                }
                weight="medium"
              />
            ),
          }}
          i18nKey={'settings:workspace.delete-account.dialog-info-content'}
        />
      </DialogInformationTemplate>
    </>
  );
}

export default DeleteAccount;
