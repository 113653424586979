import styled from 'styled-components/macro';

import Column from '@/containers/Content/BriefComponents/Table/Columns/Column';

const OneLineColumn = styled(Column)`
  &&&  {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
  }
`;

export default OneLineColumn;
