import React from 'react';
import { useTranslation } from 'react-i18next';

import UpdatedContentIcon from '@/components/icons/UpdatedContentIcon';
import { ParagraphText } from '@/components/Text/Paragraph';
import { TitleTwo } from '@/components/Text/Title';
import { EditorEmptyBodyWrapper } from '@/containers/Content/Wrappers';

const ExistingUrlsCreation = () => {
  const { t } = useTranslation();

  return (
    <EditorEmptyBodyWrapper>
      <UpdatedContentIcon />
      <TitleTwo weight="strong">{t('empty-state:existing-url-creation.no-url-found')}</TitleTwo>
      <ParagraphText align="center" color="dark060" weight="medium">
        {t('empty-state:existing-url-creation.all-urls-have-drafts')}
      </ParagraphText>
    </EditorEmptyBodyWrapper>
  );
};

export default ExistingUrlsCreation;
