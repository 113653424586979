import { nanoid } from 'nanoid';
import React from 'react';
import { useMemo } from 'react';

import SvgWrapper from './SvgWrapper';

function LineDoubleIcon(props) {
  const maskId = useMemo(() => nanoid(), []);

  return (
    <SvgWrapper {...props} fill="none" height="17" viewBox="0 0 17 17" width="17">
      <mask
        height="4"
        id={`mask${maskId}`}
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="17"
        x="0"
        y="6"
      >
        <path d="M17 6V7H0V6H17ZM0 10H17V9H0V10Z" fill="currentColor" />
      </mask>
      <g mask={`url(#mask${maskId})`}>
        <rect fill="currentColor" height="20" width="20" x="-2" y="-1" />
      </g>
    </SvgWrapper>
  );
}

export default LineDoubleIcon;
