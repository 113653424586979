import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import apiClient from '@/apis/semji/apiClient';
import { showErrorSnackbar } from '@/store/actions/ui';

export default function usePostBrandVoice(props) {
  const { post } = apiClient;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: async (payload) => {
      const createdBrandVoice = await post(
        `/workspaces/${props.workspaceId}/deprecated_brand_voices`,
        payload
      );

      return createdBrandVoice;
    },

    onError: () => {
      dispatch(showErrorSnackbar(t('common:error.default')));
    },

    ...props,
  });
}
