import { nanoid } from 'nanoid';
import React, { useMemo } from 'react';

import SvgWrapper from './SvgWrapper';

function SubmitIcon({ ...props }) {
  const id = useMemo(() => nanoid(), []);

  return (
    <SvgWrapper fill="none" height="23" viewBox="0 0 22 23" width="22" {...props}>
      <rect fill={`url(#paint0_linear_${id})`} height="22" rx="11" width="22" y="0.592621" />
      <mask
        height="15"
        id={`mask0_${id}`}
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="14"
        x="4"
        y="4"
      >
        <path
          d="M16.8493 11.9041L11.4408 6.49478V18.1926H10.5608V6.49478L5.15234 11.9041L4.5293 11.2811L11.0008 4.81046L17.4723 11.282L16.8493 11.9041Z"
          fill="black"
        />
      </mask>
      <g mask={`url(#mask0_${id})`}>
        <rect fill="white" height="17.6" width="17.6" x="1.75977" y="3.2326" />
      </g>
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id={`paint0_linear_${id}`}
          x1="4.44265e-05"
          x2="22.004"
          y1="11.5988"
          y2="11.5988"
        >
          <stop stopColor="#FF4580" />
          <stop offset="1" stopColor="#FF4D64" />
        </linearGradient>
      </defs>
    </SvgWrapper>
  );
}

export default SubmitIcon;
