import './Position.scss';

import React from 'react';

const OUT_OF_RANK_POSITION_PLACEHOLDER = '>100';

export default function Position({ position }: { position?: number }): JSX.Element {
  return (
    <div
      className={`competitor-positioon-cell ${
        position ? '' : 'competitor-positioon-cell__out_of_rank'
      }`}
    >
      {position ?? OUT_OF_RANK_POSITION_PLACEHOLDER}
    </div>
  );
}
