import { getSimpleMovingAverageWindow } from '../simpleMovingAverage';
import { getCurrentDateRange } from './getCurrentDateRange';

// today date is passed throw the different methods for test purpose
export const getTrendCurrentDateRangeSQLFormat = ({ period, periodicity, today }) => {
  const dateRange = getCurrentDateRange({ period, today });
  const simpleMovingAverageWindow = getSimpleMovingAverageWindow(periodicity);

  return {
    from: dateRange.from.minus({ days: simpleMovingAverageWindow }).toSQLDate(),
    to: dateRange.to.toSQLDate(),
  };
};
