import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player/youtube';
import { useDispatch, useSelector } from 'react-redux';

import usePostWorkspaceAchievement from '@/apis/semji/workspace/usePostWorkspaceAchievement';
import { PrimaryLink } from '@/components/Navigation/Link';
import { VideoWrapper } from '@/containers/Onboarding/common';
import { DISCOVER_SEMJI_VIDEO, VIDEO_VALIDATION_TIME } from '@/containers/Onboarding/constants';
import DescribeStepsComponents from '@/containers/Onboarding/DescribeSteps';
import PrimaryButton from '@/design-system/components/Button/PrimaryButton';
import { showErrorSnackbar, showSuccessSnackbar } from '@/store/actions/ui';
import { selectCurrentWorkspaceAchievements } from '@/store/selectors/selectCurrentWorkspaceAchievements';

function DiscoverSemjiVideo() {
  const dispatch = useDispatch();
  const workspaceAchievements = useSelector(selectCurrentWorkspaceAchievements);
  const { t } = useTranslation();
  const [isPlaying, setIsPlaying] = useState(false);
  const isAchievementAlreadyValidated = workspaceAchievements.find(
    (achievement) => achievement.code === DISCOVER_SEMJI_VIDEO
  );
  const i18nNameSpace = 'quick-start-guide:body.accordion.discover-semji';

  const postWorkspaceAchievement = usePostWorkspaceAchievement({
    onError: (error) => {
      if (error.response?.status === 409) {
        dispatch(showErrorSnackbar(t(`${i18nNameSpace}.error-achievement`)));
      }
    },
    onSuccess: () => dispatch(showSuccessSnackbar(t(`${i18nNameSpace}.success-achievement`))),
  });

  function onProgressVideo(videoData) {
    if (videoData.played > VIDEO_VALIDATION_TIME && !isAchievementAlreadyValidated) {
      postWorkspaceAchievement.mutate(DISCOVER_SEMJI_VIDEO);
    }
  }

  function handleIsPlaying() {
    setIsPlaying(!isPlaying);
  }

  return (
    <DescribeStepsComponents
      button={
        <PrimaryButton margin="20px 0" noFlex weight="strong" onClick={handleIsPlaying}>
          {t(`${i18nNameSpace}.button-label`)}
        </PrimaryButton>
      }
      explanatoryText={<Trans i18nKey={`${i18nNameSpace}.explanatory-text`} />}
      helper={
        <Trans
          components={{
            primaryLink: (
              <PrimaryLink
                href={t(`${i18nNameSpace}.helper-link`)}
                isExternal
                noDecoration
                size="xs"
                weight="medium"
              />
            ),
          }}
          i18nKey={`${i18nNameSpace}.helper`}
        />
      }
      visualRendering={
        <VideoWrapper>
          <ReactPlayer
            controls
            height="300px"
            playing={isPlaying}
            url={t(`${i18nNameSpace}.video-link`)}
            width="468px"
            onProgress={onProgressVideo}
          />
        </VideoWrapper>
      }
    />
  );
}

export default DiscoverSemjiVideo;
