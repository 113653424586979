import {
  HIDE_MODAL_CANCEL_PLAN,
  HIDE_MODAL_UPGRADE_PLAN,
  SHOW_MODAL_CANCEL_PLAN,
  SHOW_MODAL_UPGRADE_PLAN,
} from '../actions/actionTypes';

const initialState = {
  showCancelModal: false,
  showUpgradeModal: false,
};

const billing = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MODAL_UPGRADE_PLAN:
      return {
        ...state,
        showUpgradeModal: true,
      };
    case HIDE_MODAL_UPGRADE_PLAN:
      return {
        ...state,
        showUpgradeModal: false,
      };
    case SHOW_MODAL_CANCEL_PLAN:
      return {
        ...state,
        showCancelModal: true,
      };
    case HIDE_MODAL_CANCEL_PLAN:
      return {
        ...state,
        showCancelModal: false,
      };
    default:
      return state;
  }
};

export default billing;
