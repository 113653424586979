import MuiMenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import styled from 'styled-components/macro';

import Can from '@/hoc/Can';

export const SelectorItem = styled(MuiMenuItem)`
  && {
    border-bottom: 1px solid ${(props) => props.theme.colors.brightGrey};
    width: 100%;
    height: 48px;
    background-color: ${(props) =>
      props.active ? props.theme.cssColors.primary005 : 'transparent'};
    &:hover {
      background-color: ${(props) => props.theme.cssColors.primary005};
    }
  }
`;
export const HorizontalSelectorItem = styled(({ extraPadding, ...props }) => (
  <MuiMenuItem {...props} />
))`
  && {
    font-size: 0.8em;
    min-height: ${(props) => props.minHeight ?? '30px'};
    width: 100%;
    padding-top: ${(props) => (props.extraPadding ? '10px' : '5px')};
    padding-bottom: ${(props) => (props.extraPadding ? '10px' : '5px')};
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: ${(props) => props.textTransformation ?? 'none'};
    border-left: ${(props) =>
      `2px solid ${props.active ? props.theme.cssColors.primary : 'transparent'}`};
    background-color: ${(props) =>
      props.active ? props.theme.cssColors.primary005 : 'transparent'};
    &:hover {
      background-color: ${(props) => props.theme.cssColors.primary005};
    }
    & span {
      display: flex;
      align-items: center;
      margin-right: ${({ theme }) => theme.textCss.sizes.xs};
    }
  }
`;

const Wrapper = styled.div`
  min-height: ${(props) => `${Math.min(210, Math.min(4, props.size) * 50)}px`};
  max-height: 210px;
  display: grid;
`;

export const ScrollWorkspacesWrapper = (props) => (
  <Can
    no={() => <Wrapper {...props} size={props.size} />}
    // additional item for Add workspace CTA
    perform="organization-admin-settings:visit"
    yes={() => <Wrapper {...props} size={props.size + 1} />}
  />
);

export const SelectorBox = withStyles({
  paper: {
    borderRadius: '0',
    boxShadow: '0 0px 5px -3px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06)',
    boxSizing: 'border-box',
    marginTop: '2px',
    maxWidth: '300px',
  },
})(
  ({
    anchorOrigin = { horizontal: 'right', vertical: 'bottom' },
    transformOrigin = { horizontal: 'right', vertical: 'top' },
    ...props
  }) => <Popover anchorOrigin={anchorOrigin} transformOrigin={transformOrigin} {...props} />
);

export const PortalInputBox = withStyles({
  paper: {
    borderRadius: '5px',
    boxShadow: '0 0px 5px -3px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06)',
    marginTop: '5px',
    maxHeight: '300px',
    padding: '25px',
  },
})((props) => (
  <Popover
    anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
    transformOrigin={{ horizontal: 'left', vertical: 'top' }}
    {...props}
  />
));

export const CustomSelectorBox = withStyles({
  paper: {
    borderRadius: '0',
    boxShadow: '0 0px 5px -3px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06)',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: (props) => props.direction,
    marginTop: '0px',
    maxHeight: '380px',
    padding: '12px 0',
    width: '260px',
  },
})(Popover);

function Selector({ button, children }) {
  const [anchorEl, setAnchorEl] = useState(null);

  function handleOpen(e) {
    setAnchorEl(e.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <>
      {button({ open: Boolean(anchorEl), openMenu: handleOpen })}
      {children({
        anchorEl: anchorEl,
        onClose: handleClose,
        open: Boolean(anchorEl),
      })}
    </>
  );
}

export default Selector;
