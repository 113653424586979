import { useState } from 'react';
import styled from 'styled-components/macro';

import CaretIcon from '@/components/icons/CaretIcon';
import Rotate from '@/design-system/components/Button/Rotate';
import ListBigItem from '@/design-system/components/List/ListBigItem';

const List = styled.ul`
  max-height: ${({ isOpen }) => (isOpen ? '500px' : '0')};
  overflow: hidden;
  transition: max-height 0.25s linear;
`;

function CollapsibleList({ children, className, label, isDefaultOpen = false }) {
  const [isOpen, setIsOpen] = useState(isDefaultOpen);

  function toggleOpen() {
    setIsOpen(!isOpen);
  }

  return (
    <>
      <ListBigItem margin="20px 0 0 0" marginTop="0" onClick={toggleOpen}>
        <span>{label}</span>
        <Rotate hasRotated={isOpen}>
          <CaretIcon />
        </Rotate>
      </ListBigItem>
      <List className={className} isOpen={isOpen}>
        {children}
      </List>
    </>
  );
}

export default CollapsibleList;
