import { DateTime } from 'luxon';

import { getPreviousDateRange } from './getPreviousDateRange';

// today date is passed throw the different methods for test purpose
export const getPreviousDateRangeSQLFormat = ({
  period,
  comparisonPeriod,
  today,
}: {
  period: string;
  comparisonPeriod: string;
  today?: DateTime;
}) => {
  const dateRange = getPreviousDateRange({ comparisonPeriod, period, today });

  return {
    from: dateRange.from.toSQLDate(),
    to: dateRange.to.toSQLDate(),
  };
};
