import { DateTime } from 'luxon';

import { ORDER_ASCENDING, ORDER_DESCENDING } from '../utils/constants';

export const sortAlphabetically = (a, b, order = ORDER_ASCENDING) => {
  if (a === null) return 1;
  if (b === null) return -1;
  if (a === b) return 0;

  if (order === ORDER_DESCENDING) {
    return a > b ? -1 : 1;
  }

  return a < b ? -1 : 1;
};

export const sortByDate = (a, b, order = ORDER_ASCENDING) => {
  return sortAlphabetically(
    a === null ? null : DateTime.fromFormat(a, 'LLL d, yyyy'),
    b === null ? null : DateTime.fromFormat(b, 'LLL d, yyyy'),
    order
  );
};

export const sortPagesByURLOrderSelection = (urls, pages) => {
  const sortedPages = [];

  urls.forEach((url) => {
    const currentPage = pages.find((page) => page.url === url);
    if (currentPage) {
      sortedPages.push(currentPage);
    }
  });

  return sortedPages;
};
