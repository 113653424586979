import React from 'react';

import SvgWrapper from './SvgWrapper';

const DotsLoaderIcon = (props) => (
  <SvgWrapper {...props} viewBox={'0 0 62 62'}>
    <g>
      <circle cx="16" cy="32" r="5.17801" strokeWidth="0">
        <animate
          attributeName="fill-opacity"
          dur="750ms"
          repeatCount="indefinite"
          values=".5;.6;.8;1;.8;.6;.5;.5"
        />
        <animate attributeName="r" dur="750ms" repeatCount="indefinite" values="3;3;4;5;6;5;4;3" />
      </circle>
      <circle cx="32" cy="32" r="5.82199" strokeWidth="0">
        <animate
          attributeName="fill-opacity"
          dur="750ms"
          repeatCount="indefinite"
          values=".5;.5;.6;.8;1;.8;.6;.5"
        />
        <animate attributeName="r" dur="750ms" repeatCount="indefinite" values="4;3;3;4;5;6;5;4" />
      </circle>
      <circle cx="48" cy="32" r="4.82199" strokeWidth="0">
        <animate
          attributeName="fill-opacity"
          dur="750ms"
          repeatCount="indefinite"
          values=".6;.5;.5;.6;.8;1;.8;.6"
        />
        <animate attributeName="r" dur="750ms" repeatCount="indefinite" values="5;4;3;3;4;5;6;5" />
      </circle>
    </g>
  </SvgWrapper>
);

export default DotsLoaderIcon;
