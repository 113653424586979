import { useRef } from 'react';

import { generateColorBasedOnString } from '@/utils/helper';
import { USER_COLORS } from '@/utils/user';

function useUniqueColor(optionalColors) {
  const colors = optionalColors ?? USER_COLORS;
  const availableColorsRef = useRef(Array.from(colors));

  function setAvailableColors() {
    availableColorsRef.current = Array.from(colors);
  }

  function getColor({ str, isLastColor }) {
    const { backgroundColor, color, fontColor } = generateColorBasedOnString(
      availableColorsRef.current,
      str,
      colors.length
    );
    const colorIndex = availableColorsRef.current.findIndex((avColor) => avColor.color === color);
    availableColorsRef.current.splice(colorIndex, 1);
    // Fallback if not enough colors OR clean up
    if (availableColorsRef.current.length === 0 || isLastColor) {
      setAvailableColors();
    }
    return { backgroundColor, color, fontColor };
  }

  return {
    availableColorsRef,
    getColor,
  };
}

export default useUniqueColor;
