import React from 'react';

import SvgWrapper from './SvgWrapper';

const UserIcon = (props) => (
  <SvgWrapper {...props}>
    <path d="m 16.491606,16.028929 c -0.05931,-2.531152 -2.614327,-4.709055 -6.136799,-5.283676 V 9.4509478 c 0.463011,-0.445566 0.792391,-1.1369939 1.007897,-1.7266865 0.312442,-0.1563718 0.587238,-0.5049118 0.747221,-0.9730855 0.223978,-0.6480958 0.137398,-1.2462657 -0.193863,-1.5345176 0.02636,-0.2241959 0.04328,-0.4531018 0.01412,-0.6810653 -0.07435,-0.6245458 0.06116,-0.9777956 0.182565,-1.2886557 0.09975,-0.2609337 0.215508,-0.5567217 0.09975,-0.8901895 C 11.796545,1.1575821 10.587253,0.49724049 8.8076665,0.49724049 l -0.2484457,0.00471 C 7.3235794,0.54622476 6.952793,1.0671506 6.7043469,1.5522804 6.6732918,1.6106844 6.6318837,1.6898122 6.6318837,1.6945223 5.0207503,1.8386482 5.0348666,3.1725197 5.0471007,4.2445154 l 0.00284,0.3475977 c 0,0.1931096 0.00846,0.3946976 0.024466,0.6019375 C 4.674436,5.4766502 4.6000903,6.1407599 4.9059422,6.8303037 5.0800425,7.2212336 5.3313116,7.5170213 5.6108131,7.6705675 5.8394961,8.3054761 6.1989898,9.0553077 6.7203495,9.5215978 v 1.2085832 c -3.57423,0.554839 -6.16785346,2.738395 -6.22714167,5.298748 l -0.0112932,0.482304 H 16.501958 Z" />
  </SvgWrapper>
);
export default UserIcon;
