export const breakpoints = {
  mobile: '500px',
  xs: '700px',
  sm: '900px',
  md: '1200px',
  lg: '1400px',
  xl: '1600px',
  xxl: '1800px',
  laptop: 1166,
  desktop: 1366,
  largeDesktop: 1920,
};

export const maxBreakpoints = {
  mobile: '499.98px',
  xxs: '699.98px',
  xs: '899.98px',
  sm: '1199.98px',
  md: '1399.98px',
  lg: '1599.98px',
  xl: '1799.98px',
  laptop: '1365px',
  desktop: '1919px',
};

export const mediaQueries = {
  range: {
    mobile: `(max-width: ${maxBreakpoints.mobile})`,
    xxs: `(min-width: ${breakpoints.xxs}) and (max-width: ${maxBreakpoints.xs})`,
    xs: `(min-width: ${breakpoints.xs}) and (max-width: ${maxBreakpoints.xs})`,
    sm: `(min-width: ${breakpoints.sm}) and (max-width: ${maxBreakpoints.sm})`,
    md: `(min-width: ${breakpoints.md}) and (max-width: ${maxBreakpoints.md})`,
    lg: `(min-width: ${breakpoints.lg}) and (max-width: ${maxBreakpoints.lg})`,
    xl: `(min-width: ${breakpoints.xl}) and (max-width: ${maxBreakpoints.xl})`,
    xxl: `(min-width: ${breakpoints.xxl})`,
    laptop: `(max-width: ${maxBreakpoints.laptop})`,
    desktop: `(min-width: ${breakpoints.desktop}) and (max-width: ${maxBreakpoints.desktop})`,
    largeDesktop: `(min-width: ${breakpoints.largeDesktop})`,
  },
  min: {
    mobile: `(min-width: ${breakpoints.mobile})`,
    xs: `(min-width: ${breakpoints.xs})`,
    sm: `(min-width: ${breakpoints.sm})`,
    md: `(min-width: ${breakpoints.md})`,
    lg: `(min-width: ${breakpoints.lg})`,
    xl: `(min-width: ${breakpoints.xl})`,
    xxl: `(min-width: ${breakpoints.xxl})`,
    laptop: `(min-width: ${breakpoints.laptop}px)`,
    desktop: `(min-width: ${breakpoints.desktop}px)`,
    largeDesktop: `(min-width: ${breakpoints.largeDesktop}px)`,
  },
  max: {
    mobile: `(max-width: ${maxBreakpoints.mobile})`,
    xxs: `(max-width: ${maxBreakpoints.xxs})`,
    xs: `(max-width: ${maxBreakpoints.xs})`,
    sm: `(max-width: ${maxBreakpoints.sm})`,
    md: `(max-width: ${maxBreakpoints.md})`,
    lg: `(max-width: ${maxBreakpoints.lg})`,
    xl: `(max-width: ${maxBreakpoints.xl})`,
    laptop: `(max-width: ${maxBreakpoints.laptop})`,
    desktop: `(max-width: ${maxBreakpoints.desktop})`,
  },
};
