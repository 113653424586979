import i18next from 'i18next';

const REGEX_MENTION = /@\[user_public_id:(.*?)\]/g;

export function formatMentions({ content, users, workspaceUsers }) {
  if (!users?.length) {
    return { mentionnedUserHasAccess: false, newContentText: content };
  }

  let mentionnedUserHasAccess = true;
  const newContentText = content?.replaceAll(REGEX_MENTION, (_, p1) => {
    const user = users.find((user) => user.id === p1);
    const hasAccess =
      !!user && workspaceUsers.some((workspaceUser) => workspaceUser.id === user.id);

    if (!hasAccess) {
      mentionnedUserHasAccess = false;
    }

    let text;
    if (user?.firstName) {
      text = user.firstName;
      if (user.lastName) {
        text = `${text} ${user.lastName}`;
      }
    } else {
      text = user?.email || i18next.t('comments:mentions.user-deleted');
    }

    return `<span data-mentions="${hasAccess ? 'active' : 'deleted'}">${text}</span>`;
  });

  return { mentionnedUserHasAccess, newContentText };
}
