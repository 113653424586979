import { useQuery } from '@tanstack/react-query';

import apiClient from '@/apis/semji/apiClient';
import { LOCATION, SCOPE_CONTENT_IDEAS } from '@/apis/semji/constants';
import { PENDING_STATUS } from '@/components/Pages/ContentIdeas/constant';
import { findKeywordDifficultyToolkit } from '@/components/Pages/ContentIdeas/Listing/CellRender/KeywordDifficulty';
import { MISC_VALUE } from '@/components/Pages/ContentIdeas/Listing/constant';
import useUniqueColor from '@/hooks/useUniqueColor';
import { CLUSTERS_COLORS } from '@/utils/cluster';

const ALL_KEYWORDS = 'all';

export default function useGetContentIdeasByContentIdeaSearchIdAndKeyword({
  contentIdeaSearchId,
  keywordType,
  isSearchDeprecated,
  contentIdeaSearch,
  isClusteringEnabled,
  isClusterizationStatusSuccess,
  ...props
}) {
  const { get } = apiClient;
  const { getColor } = useUniqueColor(CLUSTERS_COLORS);

  return useQuery({
    placeholderData: {
      contentIdeaSearch: null,
      contentIdeasList: Array(5).fill({ isSkeletonData: true }),
    },
    queryFn: async ({ signal }) => {
      const useAllKeyword = !isSearchDeprecated || ALL_KEYWORDS === keywordType;
      const formattedKeywordType = useAllKeyword ? `` : `/${keywordType}`;

      const contentIdeas = await get(
        `/content_idea_searches/${contentIdeaSearchId}/content_ideas${formattedKeywordType}`
      );

      let contentIdeasClusters = null;

      if (isClusteringEnabled && isClusterizationStatusSuccess) {
        contentIdeasClusters = await get(
          `/content_idea_searches/${contentIdeaSearchId}/content_idea_clusters`
        );
      }

      const clustersByIds = contentIdeasClusters?.data['hydra:member'].reduce(
        (acc, current, index, array) => {
          const isLastItem = index + 1 === array.length;
          const { color, backgroundColor, fontColor } = getColor({
            isLastColor: isLastItem,
            str: current.name,
          });

          const cluster = {
            ...current,
            backgroundColor,
            color,
            fontColor,
            occurrences: 0,
          };
          acc[current.id] = cluster;
          return acc;
        },
        {}
      );

      const contentIdeasList =
        contentIdeas.data['hydra:member'].map((contentIdea) => {
          return {
            ...contentIdea,
            // this value is used for the filters
            // we add a referenceValue for keyword difficulty so we can filter using equal and not equal reference value
            keywordDifficultyReferenceValue: findKeywordDifficultyToolkit(
              contentIdea.keywordDifficulty
            )?.maxValue,
            // we stock similarityScore in percent value
            similarityScore: Math.round((contentIdea.similarityScore ?? 0) * 100),
            urlCategory:
              null === contentIdea.urlCategory && contentIdea.urlClassifiedAt
                ? MISC_VALUE
                : contentIdea.urlCategory,
          };
        }) || [];

      return {
        contentIdeaSearch: contentIdeaSearch.data || null,
        contentIdeasClusters: clustersByIds,
        contentIdeasList,
      };
    },

    queryKey: [
      SCOPE_CONTENT_IDEAS.CONTENT_IDEAS,
      contentIdeaSearchId,
      LOCATION.CONTENT_IDEAS,
      isSearchDeprecated ? keywordType : ALL_KEYWORDS,
      isClusteringEnabled,
      isClusterizationStatusSuccess,
    ],
    refetchInterval: (contentIdea) => {
      if (
        contentIdea?.contentIdeaSearch?.status === PENDING_STATUS ||
        contentIdea?.contentIdeaSearch?.clusterizationStatus === PENDING_STATUS
      ) {
        return 2000;
      } else if (
        contentIdea?.contentIdeasList?.some(
          (contentIdea) =>
            !contentIdea.urlEstimatedTrafficUpdatedAt ||
            !contentIdea.keywordDifficultyUpdatedAt ||
            !contentIdea.urlClassifiedAt
        )
      ) {
        return 5000;
      } else {
        return false;
      }
    },
    refetchOnWindowFocus: false,
    ...props,
  });
}
