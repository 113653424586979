import { CompetitorWebsite } from './competitorWebsite.types';

export interface CreateCompetitorConfigurationDto {
  competitorWebsites: Partial<CompetitorWebsite>[];
  funnelPrompt: string | null;
  clusterPrompt: string | null;
  personaPrompt: string | null;
  periodicity: CompetitorPeriodicity;
  isAutoUpdate: boolean;
}

export enum CompetitorPeriodicity {
  Monthly = 'monthly',
}
export interface CompetitorConfiguration {
  id: string;
  createdAt: string;
  funnelPrompt: string;
  clusterPrompt: string;
  personaPrompt: string;
  periodicity: string;
  isAutoUpdate: boolean;
}
