import React from 'react';
import styled from 'styled-components/macro';

export default styled((props) => <div {...props} />)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.1em 0;
`;

export const LineClampEllipsis = styled(({ lineClamp, ...props }) => <span {...props} />)`
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: ${({ lineClamp = 2 }) => lineClamp};
  -webkit-box-orient: vertical;
  padding: 0.1em 0;
`;
