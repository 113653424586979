import './FactsSidePanel.scss';

import { useQueryClient } from '@tanstack/react-query';
import en from 'date-fns/locale/en-US';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';
import i18next from 'i18next';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { FactCheckCard } from 'semji-core/components/FactCheckCard';
import { strongify } from 'semji-core/utils/strongify';

import { SCOPE_FACTS } from '@/apis/semji/constants';
import usePostStatusFact from '@/apis/semji/facts/usePostStatusFact';
import { useContentContainerContext } from '@/containers/Content/ContentContainerContext';
import {
  getSourceTitle,
  getSourceUrl,
  transformerCriticalityToVariant,
} from '@/containers/Content/SidePanel/Facts/facts.helpers';
import {
  FACT_CHECK_CARD_PREFIX_ID,
  FACT_CHECK_DATA_ATTRIBUTE,
  FACT_CHECK_DATA_ATTRIBUTE_CC,
  FACT_CHECK_HIGHLIGHT_CLASS,
  FACT_CHECK_POPOVER_CARD_PREFIX_ID,
  FACT_CHECK_VALIDATED_DATA_ATTRIBUTE,
} from '@/containers/Content/TinyMceComponents/Editor/hooks/useAiWriting/useFactCheck/const';
import { useMixpanelTrackEvent } from '@/hooks/useMixpanelTrackEvent';
import { selectCurrentWorkspaceCountry } from '@/store/selectors/selectCurrentWorkspaceCountry';
import selectCurrentWorkspaceLanguageCode from '@/store/selectors/selectCurrentWorkspaceLanguageCode';
import {
  FACT_CHECKING_DELETED_CLICK,
  FACT_CHECKING_VALIDATED_CLICK,
} from '@/utils/3rdParty/Mixpanel/constants';
import { DEFAULT_FAVICON } from '@/utils/constants';
import { getFaviconFromUrl } from '@/utils/favicon';
import { unhighlight } from '@/utils/highlight';

import { Fact, FACT_FILTER_ENUM, FACT_QUERY_PARAM, FACT_STATUS_ENUM } from './facts.types';

export default function FactCheckCardWrapper({
  fact,
  contentId,
  prefixId,
}: {
  fact: Fact;
  contentId: string;
  prefixId: string;
}): React.JSX.Element | null {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const locales = [en, fr, es, it];
  const locale = locales.find((locale) => locale.code === i18next.language) || en;

  const {
    aiWriting: {
      factCheckHook: { updateKeyFactsOptimistically },
    },
  } = useContentContainerContext();
  const trackMixpanelEvent = useMixpanelTrackEvent();

  const apiRootUrl = import.meta.env.VITE_REACT_APP_SEMJI_API_ROOT_URL;
  const [query, setQuery] = useSearchParams();
  const languageCode = useSelector(selectCurrentWorkspaceLanguageCode);
  const { countryCode } = useSelector(selectCurrentWorkspaceCountry);

  const queryKey = [SCOPE_FACTS.GET_FACTS_BY_CONTENT_ID, contentId];

  const postStatusFact = usePostStatusFact({
    onError: (err, newFacts, context) => {
      queryClient.setQueryData(queryKey, context.previousFacts);
    },
    onMutate: async ({ id, status }: { id: string; status: string | null }) => {
      const previousFacts = await updateKeyFactsOptimistically(fact, 'status', status);

      return previousFacts;
    },
    onSuccess: ({ data: fact }: { data: Fact }) => {
      const factEls: NodeListOf<HTMLElement> = document.querySelectorAll(
        `[${FACT_CHECK_DATA_ATTRIBUTE}="${fact.id}"]`
      );

      if (!factEls) {
        return;
      }

      factEls.forEach((factEl: HTMLElement) => {
        if (fact.status === FACT_STATUS_ENUM.STATUS_VALIDATED) {
          factEl.setAttribute(FACT_CHECK_VALIDATED_DATA_ATTRIBUTE, 'true');
          return;
        }
        // we want to unhighlight the fact if it is deleted
        if (fact.status === FACT_STATUS_ENUM.STATUS_DELETED) {
          unhighlight({
            dataAttribute: FACT_CHECK_DATA_ATTRIBUTE_CC,
            datum: fact.id,
            element: factEl,
            highlightClass: FACT_CHECK_HIGHLIGHT_CLASS,
          });

          return;
        }

        factEl.setAttribute(FACT_CHECK_VALIDATED_DATA_ATTRIBUTE, 'false');
      });
    },
  });

  function deleteFact(event: Event, id: string) {
    trackMixpanelEvent(FACT_CHECKING_DELETED_CLICK, {
      fact_check_important_to_check: fact.criticality === FACT_FILTER_ENUM.CRITICALITY_HIGH,
      fact_check_source_identified: fact.criticality === FACT_FILTER_ENUM.CRITICALITY_VERIFIED,
      fact_check_to_be_verified: fact.criticality === FACT_FILTER_ENUM.CRITICALITY_MEDIUM,
      in_editor: prefixId === FACT_CHECK_POPOVER_CARD_PREFIX_ID,
      in_sidepanel: prefixId === FACT_CHECK_CARD_PREFIX_ID,
    });

    event.stopPropagation();

    postStatusFact.mutate({ id, status: FACT_STATUS_ENUM.STATUS_DELETED });
  }

  function validateFact(event: Event, id: string) {
    trackMixpanelEvent(FACT_CHECKING_VALIDATED_CLICK, {
      fact_check_important_to_check: fact.criticality === FACT_FILTER_ENUM.CRITICALITY_HIGH,
      fact_check_source_identified: fact.criticality === FACT_FILTER_ENUM.CRITICALITY_VERIFIED,
      fact_check_to_be_verified: fact.criticality === FACT_FILTER_ENUM.CRITICALITY_MEDIUM,
      in_editor: prefixId === FACT_CHECK_POPOVER_CARD_PREFIX_ID,
      in_sidepanel: prefixId === FACT_CHECK_CARD_PREFIX_ID,
    });
    event.stopPropagation();

    postStatusFact.mutate({ id, status: FACT_STATUS_ENUM.STATUS_VALIDATED });
  }

  function unvalidateFact(event: Event, id: string) {
    event.stopPropagation();

    postStatusFact.mutate({ id, status: null });
  }

  function onClickFact(event: Event, id: string) {
    event.stopPropagation();

    query.set(FACT_QUERY_PARAM, id);
    setQuery(query);
  }

  const sourceUrl = getSourceUrl(fact, languageCode, countryCode);

  return (
    <FactCheckCard
      date={
        fact.isFactChecked && fact.searchEngineResultsSourcedAt
          ? DateTime.fromISO(fact.searchEngineResultsSourcedAt)
              .setLocale(locale.code || 'en')
              .toFormat('d LLL yy')
              .toLocaleLowerCase()
          : null
      }
      defaultFavicon={DEFAULT_FAVICON}
      fact={fact}
      id={`${prefixId}${fact.id}`}
      isChecked={fact.status === FACT_STATUS_ENUM.STATUS_VALIDATED}
      isFocused={query.get(FACT_QUERY_PARAM) === fact.id}
      label={t(
        'fact:label-criticality.' + (fact.criticality ?? FACT_FILTER_ENUM.CRITICALITY_VERIFIED)
      )}
      source={{
        favicon: getFaviconFromUrl(sourceUrl || '', apiRootUrl),
        title: getSourceTitle(fact),
        url: sourceUrl,
      }}
      translations={{
        checkTooltip: {
          title: t('fact:card-actions.check'),
        },
        deleteTooltip: {
          title: t('fact:card-actions.delete'),
        },
        showLess: {
          title: t('fact:card-actions.show-less'),
        },
        showMore: {
          title: t('fact:card-actions.show-more'),
        },
        uncheckTooltip: {
          title: t('fact:card-actions.uncheck'),
        },
      }}
      variant={transformerCriticalityToVariant(fact.criticality)}
      onCheck={(event: Event) => validateFact(event, fact.id)}
      onClick={(event: Event) => onClickFact(event, fact.id)}
      onDelete={(event: Event) => deleteFact(event, fact.id)}
      onUncheck={(event: Event) => unvalidateFact(event, fact.id)}
    >
      {!!fact.searchEngineResultsContent ? (
        <span
          dangerouslySetInnerHTML={{
            __html: strongify(fact.searchEngineResultsContent, fact.searchEngineResultsQuote ?? ''),
          }}
        />
      ) : (
        t('fact:card-content.empty')
      )}
    </FactCheckCard>
  );
}
