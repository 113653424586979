import './Editor.scss';

import { Editor as EditorTinyMCE } from '@tinymce/tinymce-react';
import throttle from 'lodash/throttle';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button, ENUM_BUTTON_VARIANTS } from 'semji-core/components/Button';
import { Input } from 'semji-core/components/Input';
import { Separator } from 'semji-core/components/Separator';
import {
  ENUM_EXPANDING_TEXTAREA_VARIANTS,
  ExpandingTextarea,
} from 'semji-core/components/Textarea';
import { copyHtmlToClipboard } from 'semji-core/utils/clipboard';
import { getClassNames } from 'semji-core/utils/getClassNames';

import serializeDom from '@/apis/semji/utils/serializeDom';
import AtomicContentButton from '@/components/Editor/AtomicContentButton/AtomicContentButton';
import { PortalFactCheckCard } from '@/components/Editor/PortalFactCheckCard';
import PortalTooltip from '@/components/Editor/PortalTooltip';
import LockRoundIcon from '@/components/icons/LockRoundIcon';
import SquareIcon from '@/components/icons/SquareIcon';
import { AiContentPermissionModal } from '@/components/modal/AiContentPermissionModal';
import { AiContentStreamErrorModal } from '@/components/modal/AiContentStreamErrorModal/AiContentStreamErrorModal';
import { WorkspaceUnitLimitationModal } from '@/components/modal/WorkspaceUnitLimitationModal/WorkspaceUnitLimitationModal';
import ScrollBar from '@/components/Scrollbar';
import VersionSelect from '@/components/Select/VersionSelect';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import { useContentContainerContext } from '@/containers/Content/ContentContainerContext';
import CopyContent from '@/containers/Content/EditorComponents/CopyContent';
import SaveStatus from '@/containers/Content/EditorComponents/StatusBar';
import { FACT_QUERY_PARAM } from '@/containers/Content/SidePanel/Facts/facts.types';
import CharacterCounter from '@/containers/Content/TinyMceComponents/CharacterCounter';
import BrandVoiceButton from '@/containers/Content/TinyMceComponents/Editor/BrandVoiceButton/BrandVoiceButton';
import EditorAlert from '@/containers/Content/TinyMceComponents/Editor/EditorAlert';
import EditorSkeleton from '@/containers/Content/TinyMceComponents/Editor/EditorSkeleton';
import FactCheckButton from '@/containers/Content/TinyMceComponents/Editor/FactCheckButton';
import {
  FACT_CHECK_CARD_PREFIX_ID,
  FACT_CHECK_DATA_ATTRIBUTE,
} from '@/containers/Content/TinyMceComponents/Editor/hooks/useAiWriting/useFactCheck/const';
import { CommentDOMUtils } from '@/containers/Content/TinyMceComponents/Editor/hooks/useComment/commentDom.utils';
import {
  FOCUSED_THREAD_ID_QUERY_PARAM,
  PENDING_THREAD_ID,
  SEMJI_THREAD_HIGHLIGHT_CLASS_NAME,
  THREAD_CARD_PREFIX_ID,
} from '@/containers/Content/TinyMceComponents/Editor/hooks/useComment/constants';
import {
  DATA_MCE_ANNOTATION,
  DATA_MCE_ANNOTATION_UID,
  ENUM_SEMJI_THREAD_ATTRIBUTES,
} from '@/containers/Content/TinyMceComponents/Editor/hooks/useComment/useComments.types';
import useContent from '@/containers/Content/TinyMceComponents/Editor/hooks/useContent/useContent';
import useCustomButtons from '@/containers/Content/TinyMceComponents/Editor/hooks/useCustomButtons';
import { FloatingAI } from '@/containers/Content/TinyMceComponents/Editor/hooks/useEditorPopper/FloatingAI';
import { FLOATING_AI_TYPE_ENUM } from '@/containers/Content/TinyMceComponents/Editor/hooks/useEditorPopper/FloatingAI/FloatingAI.types';
import useGetWordCount from '@/containers/Content/TinyMceComponents/Editor/hooks/useGetWordCount';
import ScreenOptionButton from '@/containers/Content/TinyMceComponents/Editor/ScreenOptionButton';
import FactCheckLoader from '@/containers/Content/TinyMceComponents/FactCheckLoader/FactCheckLoader';
import { ReadTime } from '@/containers/Content/TinyMceComponents/ReadTime';
import StatusBar from '@/containers/Content/TinyMceComponents/StatusBar/StatusBar';
import Toolbar from '@/containers/Content/TinyMceComponents/Toolbar';
import WordCounter from '@/containers/Content/TinyMceComponents/WordCounter';
import Box from '@/design-system/components/Box';
import PrimaryButton from '@/design-system/components/Button/PrimaryButton';
import Flex from '@/design-system/components/Flex';
import useApiConfigurations from '@/hooks/useApiConfigurations';
import { useMixpanelTrackEvent } from '@/hooks/useMixpanelTrackEvent';
import useOrganizationFeatureSet, {
  AI_WRITING__DEPRECATED_BRAND_VOICE__IS_ENABLED,
  AI_WRITING__FACT_CHECKING__IS_ENABLED,
} from '@/hooks/useOrganizationFeatureSet';
import { OPEN_PUBLISH_DIALOG } from '@/store/actions/actionTypes';
import { showErrorSnackbar, showSuccessSnackbar } from '@/store/actions/ui';
import {
  setFactCheckingEnabled,
  setHighlightCommentEnabled,
  setHighlightFactCheckingEnabled,
} from '@/store/actions/userConfiguration';
import useParamSelector from '@/store/hooks/useParamSelector';
import { selectBrandVoiceByWorkspaceId } from '@/store/selectors/selectBrandVoiceByWorkspaceId';
import { selectIsAdvancedUIMode } from '@/store/selectors/selectIsAdvancedUIMode';
import { selectUserConfiguration } from '@/store/selectors/selectUserConfiguration';
import { FACT_CHECKING_ACTIVATION_CLICK } from '@/utils/3rdParty/Mixpanel/constants';
import {
  AI_WRITING_FACT_CHECKING_ENABLED,
  CONTENT_VERSION_HISTORY_ENABLED,
  EDITOR_WORD_COUNT_PLUGIN_ENABLED,
} from '@/utils/configurations/constants';
import detectBrowser from '@/utils/editor/detectBrowser';
import { unhighlight } from '@/utils/highlight';
import { Log } from '@/utils/log/Log';

import {
  ALLOWED_ATTRIBUTES_FOR_SAVE,
  CONTENT_SELECTOR,
  ID_CONTENT,
  ID_CONTENT_CONTAINER,
  ID_METADESCRIPTION,
  ID_METADESCRIPTION_CONTAINER,
  ID_METADESCRIPTION_WRAPPER,
  ID_TITLE,
  ID_TITLE_CONTENT_CONTAINER,
  ID_TOOLBAR,
  TOOLBAR_SELECTOR,
} from './constants';

// Map semji language code to tinymce.
// https://www.tiny.cloud/docs/tinymce/6/ui-localization/#supported-languages
const TINYMCE_LANGS = {
  en: 'en',
  es: 'es',
  fr: 'fr_FR',
  it: 'it',
};

function Editor() {
  const {
    editorRef,
    forceRender,
    inputTitleRef,
    textareaMetaDescriptionRef,
    lockEditorScroll,
    comments: {
      buttons: { COMMENTS_BUTTON },
      setupToolbarButtons: setupToolbarButtonsForComments,
      setCommentsUpdate,
      isCommentEnabled,
      openStatus,
    },
    aiWriting: {
      isAtomicContentFactCheckGenerating,
      isAtomicContentGenerating,
      isStreamContentGenerating,
      factCheckPopperCardHook,
      factCheckHook,
      disableAutoScrollRef,
      actions: { handleAtomicContent, handleCancelAtomicContent },
      buttons: { AI_WRITING_BUTTON, AI_WRITING_FLAG_CONTENT_BUTTON },
      setupToolbarButtons: setupToolbarButtonsForAiWriting,
      renderFlagContentDialogs,
      popperTooltip,
      remainingAiWritingUnit,
      hasUnlimitedAIWriting,
      limitAiWriting: { setShowLimitAiWriting },
      aiContentPermission: { showPermissionModal, setShowPermissionModal },
      aiContentStreamError: { showStreamErrorModal, setShowStreamErrorModal },
      aiContentWorkspaceUnitLimitation: {
        showWorkspaceUnitLimitationModal,
        setShowWorkspaceUnitLimitationModal,
      },
    },
    editorPopper: {
      floatingAIType,
      setupEditorPopper,
      renderEditorPopper,
      handleOpenInputPopper,
      setEditorPopperOpen,
      setFloatingAIType,
    },
    triggerSaveRef,
  } = useContentContainerContext();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { contentId, workspaceId, organizationId, pageId } = useParams();
  const navigate = useNavigate();

  const userLanguageCode = useSelector((state) => state.user.languageCode);
  const { isPublishPending } = useSelector((state) => state.editor);
  const isAdvancedUIMode = useSelector(selectIsAdvancedUIMode);
  const { factCheckingEnabled, highlightCommentEnabled, highlightFactCheckingEnabled } =
    useSelector(selectUserConfiguration);
  const { isFeatureEnabled: hasAccessToMetaDescription } = useOrganizationFeatureSet(
    'contents:meta-description'
  );
  const brandVoiceSelected = useParamSelector(selectBrandVoiceByWorkspaceId, workspaceId);
  const { isFeatureEnabled: factCheckingFeatureSetEnabled } = useOrganizationFeatureSet(
    AI_WRITING__FACT_CHECKING__IS_ENABLED
  );
  const { isFeatureEnabled: brandVoiceEnabled } = useOrganizationFeatureSet(
    AI_WRITING__DEPRECATED_BRAND_VOICE__IS_ENABLED
  );
  const contentVersionHistoryEnabled = useApiConfigurations(CONTENT_VERSION_HISTORY_ENABLED);

  const isWordCountPluginEnabled = useApiConfigurations(EDITOR_WORD_COUNT_PLUGIN_ENABLED);
  const aiWritingFactCheckingEnabled = useApiConfigurations(AI_WRITING_FACT_CHECKING_ENABLED);
  const WORD_COUNT_PLUGIN = isWordCountPluginEnabled ? 'wordcount' : '';

  const isFirefox = useMemo(() => detectBrowser() === 'firefox', []);
  const trackMixpanelEvent = useMixpanelTrackEvent();

  const {
    buttons: { formatButtons },
    setupToolbarButtons: setupCustomToolbarButtons,
  } = useCustomButtons();

  const {
    content: { page, title, metaDescription },
    debouncedSave,
    initialHtml,
    isLoading,
    isResolvingConflict,
    isReadOnly,
    isSaveIdle,
    isSaving,
    openRefreshDialog,
    openSourceCodeDialog,
    renderDialogs: renderContentDialogs,
    save,
  } = useContent({
    cleanHtml,
    handleCancelAtomicContent,
    handleSetMetaDescription,
    handleSetTitle,
    hasAccessToMetaDescription,
    isAtomicContentGenerating,
  });

  const { characterCount, selectionCharacterCount, wordCount, selectionWordCount, updateCount } =
    useGetWordCount(editorRef);

  const [query, setQuery] = useSearchParams();
  const matchVersionRoutes = useMatch(
    '/o/:organizationId/w/:workspaceId/p/:pageId/versions/:contentId/*'
  );

  const queryRef = useRef(query);
  const setQueryRef = useRef(setQuery);
  queryRef.current = query;
  setQueryRef.current = setQuery;
  const [showFactCheckCard, setShowFactCheckCard] = useState(false);
  const timeoutRef = useRef(null);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (factCheckPopperCardHook?.[0]?.textHovered || factCheckPopperCardHook?.[0]?.cardHovered) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      setShowFactCheckCard(true);
    } else {
      timeoutRef.current = setTimeout(() => {
        setShowFactCheckCard(false);
      }, 1000);
    }
  }, [factCheckPopperCardHook]);

  function copyToClipboard() {
    const content = editorRef.current?.getContent();
    try {
      if (editorRef.current) {
        const cleanedHtml = cleanHtml(content, ALLOWED_ATTRIBUTES_FOR_SAVE);
        copyHtmlToClipboard(cleanedHtml);
        dispatch(
          showSuccessSnackbar(
            t('content:editor-container.saving-error-dialog.copy-success-message')
          )
        );
      }
    } catch (e) {
      dispatch(
        showErrorSnackbar(t('content:editor-container.saving-error-dialog.copy-error-message'))
      );
    }
  }

  function toggleFactChecking() {
    if (
      factCheckingFeatureSetEnabled &&
      !isStreamContentGenerating &&
      !isAtomicContentGenerating &&
      !isAtomicContentFactCheckGenerating
    ) {
      trackMixpanelEvent(FACT_CHECKING_ACTIVATION_CLICK, {
        brand_voice_id: brandVoiceSelected?.id ?? '',
        brand_voice_name: brandVoiceSelected?.name ?? '',
        is_brand_voice: brandVoiceSelected != null,
        is_fact_checking: !factCheckingEnabled,
      });
      dispatch(setFactCheckingEnabled(!factCheckingEnabled));
      if (!highlightFactCheckingEnabled) {
        toggleHighlightFactChecking();
      }
    }
  }

  function toggleHighlightComment() {
    dispatch(setHighlightCommentEnabled(!highlightCommentEnabled));
  }

  function toggleHighlightFactChecking() {
    dispatch(setHighlightFactCheckingEnabled(!highlightFactCheckingEnabled));
  }

  function handleScroll(e) {
    if (isStreamContentGenerating && !disableAutoScrollRef.current) {
      disableAutoScrollRef.current = true;
    }
    if (lockEditorScroll) setEditorPopperOpen(false);
  }

  function handleChangeTitle(e) {
    if (
      e.key === '/' &&
      (inputTitleRef.current?.value === '' || inputTitleRef.current.selectionStart === 0)
    ) {
      inputTitleRef.current.value = '/';
      e.preventDefault();
      e.stopPropagation();
      handleOpenInputPopper(ID_TITLE);
    }
  }

  function handleChangeMetaDescription(e) {
    if (
      e.key === '/' &&
      (textareaMetaDescriptionRef.current?.value === '' ||
        textareaMetaDescriptionRef.current.selectionStart === 0) &&
      hasAccessToMetaDescription
    ) {
      textareaMetaDescriptionRef.current.value = '/';
      e.preventDefault();
      e.stopPropagation();
      handleOpenInputPopper(ID_METADESCRIPTION);
    }
  }

  function handleGoToVersionHistory() {
    navigate(`/o/${organizationId}/w/${workspaceId}/p/${pageId}/versions/${contentId}`);
  }

  function handleSave() {
    if (
      isReadOnly ||
      editorRef.current?.mode?.isReadOnly() ||
      !editorRef?.current?.initialized ||
      lockEditorScroll
    ) {
      return;
    }
    const html = document.querySelector(CONTENT_SELECTOR).innerHTML;
    debouncedSave({
      content: {
        html: cleanHtml(html),
        metaDescription: hasAccessToMetaDescription
          ? textareaMetaDescriptionRef.current?.value
          : metaDescription,
        title: inputTitleRef.current?.value,
      },
    });
  }

  function handleSetTitle(title) {
    if (inputTitleRef.current) {
      inputTitleRef.current.value = title;
    }
  }

  function handleTitleOnChange(e) {
    if (e.target.value) {
      setFloatingAIType(false);
    } else {
      setFloatingAIType(FLOATING_AI_TYPE_ENUM.TITLE);
    }
  }

  async function handleTitleOnFocus() {
    inputTitleRef.current.placeholder = t('content:editor-components.editor.add-title-ai');
    if (!inputTitleRef.current.value) {
      setFloatingAIType(FLOATING_AI_TYPE_ENUM.TITLE);
    }
  }

  async function handleTitleOnBlur() {
    inputTitleRef.current.placeholder = t('content:editor-components.editor.add-title');
    setFloatingAIType(false);
  }

  function handleTextAreaOnChange(e) {
    if (e?.target?.value) {
      setFloatingAIType(false);
    } else {
      setFloatingAIType(FLOATING_AI_TYPE_ENUM.META_DESCRIPTION);
    }
  }

  async function handleTextAreaOnFocus() {
    textareaMetaDescriptionRef.current.placeholder = t(
      'content:editor-components.editor.add-meta-description-ai'
    );
    if (!textareaMetaDescriptionRef.current.value) {
      setFloatingAIType(FLOATING_AI_TYPE_ENUM.META_DESCRIPTION);
    }
  }

  async function handleTextAreaOnBlur() {
    textareaMetaDescriptionRef.current.placeholder = t(
      'content:editor-components.editor.add-meta-description-ai-empty'
    );
    setFloatingAIType(false);
  }

  function handleTextAreaOnInput(e) {
    if (e) {
      handleTextAreaOnChange(e);
    }
    handleSave();
  }

  function handleSetMetaDescription(metaDescription) {
    if (textareaMetaDescriptionRef.current && hasAccessToMetaDescription) {
      textareaMetaDescriptionRef.current.value = metaDescription;
    }
  }

  function onInit(evt, editor) {
    if (isReadOnly) {
      document.querySelector(CONTENT_SELECTOR).contentEditable = false;
    }

    editorRef.current = editor;
    editorRef.current.isSemjiContentReadOnly = isReadOnly;
    forceRender();
    handleSetTitle(title);
    handleSetMetaDescription(metaDescription);
    // when moving useContent to ContentContainerContext remove isSemjiContentReadOnly
    // because we will be able to get readonly value from the content

    setTimeout(() => {
      updateCount();
    });

    setTimeout(() => {
      document
        .querySelector(
          `${TOOLBAR_SELECTOR} button[title^="table" i], ${TOOLBAR_SELECTOR} button[title="Plus..." i], ${TOOLBAR_SELECTOR} button[title="More..." i]`
        )
        ?.setAttribute('data-intercom-target', 'editor-toolbar-table-button');
    });

    // we need to wait for the editor to be initialized to set the init ref of comments & facts
    setCommentsUpdate(true);
    factCheckHook.handleInitFactCheckHighlight(true);

    return editor;
  }

  function onDirty(props) {
    handleSave();
    props.target.setDirty(false);
  }

  const throttledCaret = throttle((e) => {
    if (e.key === 'Enter') {
      const maxheight = window.innerHeight || document.documentElement.clientHeight;
      const nodeTop = editorRef.current?.selection?.getNode().getBoundingClientRect().top;
      const navBarheight =
        document.getElementById('semji-navbar')?.getBoundingClientRect().height || 0;
      const toolbarHeight =
        document.getElementById('semji-editor-toolbar')?.getBoundingClientRect().height || 0;

      if (maxheight <= nodeTop + navBarheight + toolbarHeight) {
        editorRef.current?.selection?.getNode().scrollIntoView();
      }
    }
  }, 50);

  function onKeyUp(e) {
    handleSave();
    updateCount();
  }

  function onKeyDown(e) {
    if (e.key === 'Backspace' && e.metaKey) {
      setTimeout(() => {
        editorRef.current?.undoManager?.add?.();
      });
    }
    throttledCaret(e);
  }

  function onSelectionChange() {
    setTimeout(() => {
      updateCount();
    });
  }

  function cleanHtml(html, defaultAttributesToKeep) {
    const newDocument = new DOMParser().parseFromString(html, 'text/html');

    // remove styles from HTML
    const loaderElement = newDocument.querySelector('div.ai-loader-wrapper');
    if (loaderElement) {
      loaderElement.remove();
    }

    const placeholderElement = newDocument.querySelector('input.ai-placeholder');

    if (placeholderElement) {
      const breakLineElement = newDocument.createElement('br');
      placeholderElement.parentElement.append(breakLineElement);
      placeholderElement.remove();
    }
    // remove unnecessary attributes from HTML

    const attributesToKeep = defaultAttributesToKeep ?? [
      ENUM_SEMJI_THREAD_ATTRIBUTES.SEMJI_THREAD_DATA_ATTRIBUTE,
      DATA_MCE_ANNOTATION_UID,
      DATA_MCE_ANNOTATION,
      FACT_CHECK_DATA_ATTRIBUTE,
      ...ALLOWED_ATTRIBUTES_FOR_SAVE,
      'data-mce-href', // tinymce add it to all links so we have to keep it to avoid pop in editor not saved #5771
    ];

    newDocument.querySelectorAll('*').forEach((el) => {
      const attrs = el.attributes;

      // remove pending annotation from the dom
      unhighlight({
        dataAttribute: ENUM_SEMJI_THREAD_ATTRIBUTES.SEMJI_THREAD_ATTRIBUTE,
        datum: PENDING_THREAD_ID,
        element: el,
        highlightClass: SEMJI_THREAD_HIGHLIGHT_CLASS_NAME,
      });

      for (let i = attrs.length - 1; i >= 0; i--) {
        const attr = attrs[i];
        if (!attributesToKeep.includes(attr.name)) {
          el.removeAttribute(attr.name);
        }
      }

      // replace span without attributes by their content
      if (el.tagName === 'SPAN' && el.attributes.length === 0) {
        const textNode = newDocument.createTextNode(el.textContent);
        el.replaceWith(textNode);
      }
    });

    return newDocument.body.innerHTML || serializeDom(newDocument.body);
  }

  function onPastePreprocess(editor, args) {
    try {
      args.content = cleanHtml(args.content);

      setCommentsUpdate(Date.now());
      factCheckHook.handleInitFactCheckHighlight(Date.now());
    } catch (e) {
      Log.error('paste_preprocess', e);
    }
  }

  function showMarkAsPublishedDialog() {
    if (isSaving) {
      return;
    }
    dispatch({
      type: OPEN_PUBLISH_DIALOG,
    });
  }

  useEffect(() => {
    if (triggerSaveRef.current) {
      triggerSaveRef.current = false;
      const html = document.querySelector(CONTENT_SELECTOR)?.innerHTML;
      if (html && inputTitleRef.current?.value && textareaMetaDescriptionRef.current?.value) {
        save({
          content: {
            html: cleanHtml(html),
            metaDescription: textareaMetaDescriptionRef.current?.value,
            title: inputTitleRef.current?.value,
          },
        });
      }
    }
  }, [triggerSaveRef.current]);

  // On contentId change, clean the editor ref.
  useEffect(() => {
    editorRef.current = null;
    inputTitleRef.current = null;
    textareaMetaDescriptionRef.current = null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentId]);

  // Cleanup editorRef on unmount
  useEffect(() => {
    return () => {
      editorRef.current = null;
      inputTitleRef.current = null;
      textareaMetaDescriptionRef.current = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // update the init ref after a new content is loaded
  // this helps to trigger the calculation of highlight of comments & facts
  useEffect(() => {
    setCommentsUpdate(Date.now());
    factCheckHook.handleInitFactCheckHighlight(Date.now());
  }, [initialHtml.value]);

  function setQueryParam(queryParam, value) {
    queryRef.current?.set(queryParam, value);
    setQueryRef.current?.(queryRef.current, { replace: true });
  }

  function unsetQueryParam(queryParam) {
    queryRef.current?.delete(queryParam);
    setQueryRef.current?.(queryRef.current, { replace: true });
  }

  function breakAnnotation(element, attribute) {
    if (!element.getAttribute(attribute) || element.parentElement.textContent.length !== 0) {
      return;
    }

    const breakLine = editorRef.current.dom.create('br', {}, '');
    editorRef.current.dom.insertAfter(breakLine, element);
    element.remove();
  }

  function scrollTo(selector) {
    document.querySelector(selector)?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }

  function handleCommentsOnNodeChange(element) {
    if (!isCommentEnabled || isReadOnly) {
      return;
    }

    // On selection change, check if new focused element contains annotation attribute
    breakAnnotation(element, ENUM_SEMJI_THREAD_ATTRIBUTES.SEMJI_THREAD_DATA_ATTRIBUTE);

    // if the pending thread has a non empty comment, we need to keep the focus on the pending thread
    // and not manipualet the query params
    if (CommentDOMUtils.isPendingThreadHaveAComment()) {
      return;
    }

    // If selection changes (means a new node is selected) and the node has the data attribute
    // then we set the query param to the threadId
    // the use effect will handle the rest
    const threadId =
      element?.attributes[ENUM_SEMJI_THREAD_ATTRIBUTES.SEMJI_THREAD_DATA_ATTRIBUTE]?.value?.split(
        ';'
      )[0];

    if (threadId) {
      setQueryParam(FOCUSED_THREAD_ID_QUERY_PARAM, threadId);
      scrollTo(`#${THREAD_CARD_PREFIX_ID}${threadId}`);
    } else {
      unsetQueryParam(FOCUSED_THREAD_ID_QUERY_PARAM);
    }
  }

  function handleFactsOnNodeChange(element) {
    if (!factCheckingFeatureSetEnabled || !aiWritingFactCheckingEnabled || isReadOnly) {
      return;
    }

    // On selection change, check if new focused element contains annotation attribute
    breakAnnotation(element, FACT_CHECK_DATA_ATTRIBUTE);

    const factId = element?.attributes[FACT_CHECK_DATA_ATTRIBUTE]?.value?.split(';')[0];

    if (factId) {
      setQueryParam(FACT_QUERY_PARAM, factId);
      scrollTo(`#${FACT_CHECK_CARD_PREFIX_ID}${factId}`);
    } else {
      unsetQueryParam(FACT_QUERY_PARAM);
    }
  }

  function setupEditorNodeChangeListener(editor) {
    editor.on('NodeChange', (data) => {
      if (true !== data.selectionChange) {
        return;
      }

      handleCommentsOnNodeChange(data.element);
      handleFactsOnNodeChange(data.element);
    });
  }

  if (isLoading || (isResolvingConflict && !isAtomicContentGenerating)) {
    return <EditorSkeleton hideTopBar={matchVersionRoutes} />;
  }

  return (
    <>
      <Flex backgroundColor="white" flex={1} flexDirection="column">
        {!matchVersionRoutes && (
          <Toolbar id="semji-editor-toolbar">
            <div className="semji-editor-toolbar--left">
              {!contentVersionHistoryEnabled && <VersionSelect />}
              {!isReadOnly && (
                <AtomicContentButton
                  {...{
                    editorRef,
                    factCheckingEnabled,
                    handleAtomicContent,
                    hasUnlimitedAIWriting,
                    isAtomicContentGenerating,
                    remainingAiWritingUnit,
                    setShowLimitAiWriting,
                    setShowPermissionModal,
                  }}
                />
              )}

              <FactCheckButton
                disabled={isReadOnly || !aiWritingFactCheckingEnabled}
                factCheckingEnabled={factCheckingEnabled}
                factCheckingFeatureSetEnabled={factCheckingFeatureSetEnabled}
                toggleFactChecking={toggleFactChecking}
              />
              {/* handle disabled later, display available on higher plan */}

              {!isReadOnly && !!brandVoiceEnabled && <BrandVoiceButton />}
              {!isReadOnly && <div className="semji-editor-toolbar--separator" />}
            </div>

            <Box id={ID_TOOLBAR}></Box>
            <Flex gap="8px">
              <ScreenOptionButton
                canAccessToFactChecking={aiWritingFactCheckingEnabled}
                canShowVersionHistory={contentVersionHistoryEnabled || isAdvancedUIMode}
                canSyncToWebsite={!isReadOnly && Boolean(page?.url)}
                handleChangeIsCommentEnabled={toggleHighlightComment}
                handleChangeIsFactCheckingEnabled={toggleHighlightFactChecking}
                handleClickShowSourceCode={openSourceCodeDialog}
                handleClickShowVersionHistory={handleGoToVersionHistory}
                handleClickSyncWithWebsite={openRefreshDialog}
                isCommentEnabled={highlightCommentEnabled}
                isFactCheckingEnabled={highlightFactCheckingEnabled}
              />
              {!isReadOnly && (
                <>
                  <PrimaryButton
                    data-intercom-target="editor_mark_as_published"
                    disabled={isPublishPending}
                    minWidth="165px"
                    onClick={showMarkAsPublishedDialog}
                  >
                    {t('content:editor-container.mark-as-published')}
                  </PrimaryButton>
                </>
              )}
            </Flex>
          </Toolbar>
        )}

        <ScrollBar
          customViewStyle={{
            alignItems: 'center',
            display: 'flex',
            marginBottom: 0,
            overflowX: 'hidden',
            overflowY: 'scroll',
            paddingTop: '40px',
          }}
          id={ID_CONTENT_CONTAINER}
          onScroll={handleScroll}
        >
          <div
            className={getClassNames(
              'editor-container',
              highlightCommentEnabled && 'highlight-comment',
              highlightFactCheckingEnabled && 'highlight-fact-checking',
              openStatus && 'open-comment-panel'
            )}
          >
            <div
              className={getClassNames(
                'editor-container__content',
                matchVersionRoutes && 'editor-container__content--version'
              )}
              id={ID_TITLE_CONTENT_CONTAINER}
            >
              <FloatingAI
                floatingAIType={floatingAIType}
                inputTitleRef={inputTitleRef}
                textareaMetaDescriptionRef={textareaMetaDescriptionRef}
              />
              <Input
                ref={inputTitleRef}
                autoComplete="off"
                defaultValue={title}
                disabled={isReadOnly}
                fontSize="editor-title"
                id={ID_TITLE}
                noBorder={true}
                padding={'p-0'}
                placeholder={
                  isReadOnly ? '<title>' : t('content:editor-components.editor.add-title')
                }
                onBlur={handleTitleOnBlur}
                onChange={handleTitleOnChange}
                onFocus={handleTitleOnFocus}
                onKeyDown={handleChangeTitle}
                type="text"
                // use onInput and not onChange to trigger the save event only when the user has changed the text
                onInput={handleSave}
              />
              <Separator />
              <Flex
                data-intercom-target="editor_meta_description"
                gap="30px"
                id={ID_METADESCRIPTION_WRAPPER}
              >
                <div className="text-dark-040 text-[14px] font-medium whitespace-nowrap my-2">
                  <div className="flex items-center gap-2">
                    <div>
                      <TooltipComponent
                        title={t(
                          'content:editor-components.editor.add-meta-description-label-tooltip'
                        )}
                      >
                        <div className="h-[24px] flex items-center">
                          <span>
                            {t('content:editor-components.editor.meta-description-label')}
                          </span>
                        </div>
                      </TooltipComponent>
                    </div>

                    {!hasAccessToMetaDescription && (
                      <TooltipComponent
                        hide={hasAccessToMetaDescription}
                        title={t('content:editor-components.editor.add-meta-description-tooltip')}
                      >
                        <LockRoundIcon style={{ fontSize: 16, marginTop: 4 }} />
                      </TooltipComponent>
                    )}
                  </div>
                </div>
                <div className={'w-[70%] pb-1 mt-2'}>
                  <ExpandingTextarea
                    ref={textareaMetaDescriptionRef}
                    containerId={ID_METADESCRIPTION_CONTAINER}
                    containerplaceholder={t(
                      'content:editor-components.editor.add-meta-description-ai-empty'
                    )}
                    defaultValue={metaDescription}
                    disabled={isReadOnly || !hasAccessToMetaDescription}
                    placeholder={
                      isReadOnly
                        ? '<meta>'
                        : t('content:editor-components.editor.add-meta-description-ai')
                    }
                    textareaId={ID_METADESCRIPTION}
                    variant={ENUM_EXPANDING_TEXTAREA_VARIANTS.standard}
                    onBlur={handleTextAreaOnBlur}
                    onFocus={handleTextAreaOnFocus}
                    onInput={handleTextAreaOnInput}
                    onKeyDown={handleChangeMetaDescription}
                  />
                </div>
              </Flex>
              <Separator />
              <EditorTinyMCE
                id={ID_CONTENT}
                init={{
                  advlist_bullet_styles: 'default',
                  advlist_number_styles: 'default',
                  autosave_ask_before_unload: true,
                  autosave_interval: '10s',
                  autosave_retention: '30m',
                  block_formats:
                    'Paragraph=p; Heading 1=h1; Heading 2=h2; Heading 3=h3; Heading 4=h4',
                  branding: false,
                  // Issues on some firefox: https://gitlab.rvip.fr/semji/semji/-/issues/5130#note_153373
                  browser_spellcheck: !isFirefox,
                  contextmenu: false,
                  convert_urls: false,
                  elementpath: true,
                  entity_encoding: 'raw',
                  external_plugins: {
                    // PASTE_PLUGIN, // Premium plugin, we are copying the plugin from tinymce 5
                    // [PASTE_PLUGIN]: PASTE_PLUGIN ? '/editor/plugins/paste/plugin.min.js' : undefined,
                    // we are not using CODE_PLUGIN='code|semjicode' anymore, we are using our own modal that only displays HTML
                    // CODE_PLUGIN, // Free https://www.tiny.cloud/docs/tinymce/6/code/
                  },
                  fixed_toolbar_container: TOOLBAR_SELECTOR,
                  formats: {
                    underline: { inline: 'u' },
                  },
                  help_tabs: ['shortcuts', 'keyboardnav'],
                  inline: true,
                  language: TINYMCE_LANGS[userLanguageCode || 'en'],
                  // we do not have a the en.js file in the langs folder
                  // we fallback to the translation of tinymce for english
                  language_url: `/editor/langs/${TINYMCE_LANGS[userLanguageCode || 'en']}.js`,

                  link_context_toolbar: true,

                  menubar: false,

                  object_resizing: false,

                  paste_preprocess: onPastePreprocess,

                  plugins: [
                    'advlist', // Free https://www.tiny.cloud/docs/tinymce/6/advlist/
                    'anchor', // Free https://www.tiny.cloud/docs/tinymce/6/anchor/
                    'autolink', // Free https://www.tiny.cloud/docs/tinymce/6/autolink/
                    'autosave', // Free https://www.tiny.cloud/docs/tinymce/6/autosave/
                    'image', // Free https://www.tiny.cloud/docs/tinymce/6/image/
                    'link', // Free https://www.tiny.cloud/docs/tinymce/6/link/
                    'lists', // Free https://www.tiny.cloud/docs/tinymce/6/lists/
                    'media', // Free https://www.tiny.cloud/docs/tinymce/6/media/
                    'save', // Free https://www.tiny.cloud/docs/tinymce/6/save/
                    'table', // Free https://www.tiny.cloud/docs/tinymce/6/table/
                    WORD_COUNT_PLUGIN, // Free https://www.tiny.cloud/docs/tinymce/6/wordcount/,
                    'quickbars', // Free https://www.tiny.cloud/docs/tinymce/6/quickbars/
                  ],

                  quickbars_insert_toolbar: false,

                  quickbars_selection_toolbar: `${COMMENTS_BUTTON}`,

                  relative_urls: true,
                  setup: (editor) => {
                    if (!isReadOnly) setupEditorPopper(editor);
                    setupCustomToolbarButtons(editor);
                    setupToolbarButtonsForAiWriting(editor);
                    setupToolbarButtonsForComments(editor);
                    setupEditorNodeChangeListener(editor);
                  },

                  skin_url: '/editor/skins/ui/semji',
                  table_advtab: false,
                  table_appearance_options: false,
                  table_cell_advtab: false,
                  table_default_attributes: {},
                  table_default_styles: {},
                  table_row_advtab: false,
                  table_sizing_mode: 'responsive',
                  table_style_by_css: true,
                  toolbar: isReadOnly
                    ? ` `
                    : `
                ${AI_WRITING_BUTTON} ${AI_WRITING_FLAG_CONTENT_BUTTON} |
                undo redo |
                blocks |
                ${formatButtons} |
                link unlink |
                bullist numlist|
                table |
                `,
                  toolbar_mode: 'floating',
                  toolbar_persist: true,
                  // we need to add class to the list of valid attributes in span else it will be removed
                  // and we make old annotations and new annotations required in a span else we tell tinymce to remove the wrapping span
                  valid_elements: `sup,span[!data-mce-annotation|!${ENUM_SEMJI_THREAD_ATTRIBUTES.SEMJI_THREAD_DATA_ATTRIBUTE}|!${FACT_CHECK_DATA_ATTRIBUTE}],blockquote,br,p,h1,h2,h3,h4,p/h5,p/h6,code,strong/b,em/i,u,ul,li,ol,dd,dt,table,th,#td,tr,thead,tbody,col,colgroup,a[href|src|target|title]`,
                  valid_styles: {
                    '*': '',
                  },
                }}
                initialValue={initialHtml.value}
                scriptLoading={{ async: true }}
                tinymceScriptSrc="/tinymce/tinymce.min.js"
                onDirty={onDirty}
                onInit={onInit}
                onKeyDown={onKeyDown}
                onKeyUp={onKeyUp}
                onMouseUp={onSelectionChange}
                onSelectionChange={onSelectionChange}
              />
            </div>
          </div>
        </ScrollBar>
        {isAtomicContentFactCheckGenerating && (
          <EditorAlert variant={'secondary'}>
            <FactCheckLoader />
          </EditorAlert>
        )}

        {!isAtomicContentGenerating ? (
          <StatusBar>
            <div className="flex justify-between items-center mt-1">
              <div className="flex flex-row justify-start items-center">
                <WordCounter count={wordCount} selectionCount={selectionWordCount} />
                <CharacterCounter count={characterCount} selectionCount={selectionCharacterCount} />
                <ReadTime count={wordCount} selectionCount={selectionWordCount} />
              </div>
              <div>
                <div className="flex flex-row justify-end items-center">
                  {!isSaveIdle && !isReadOnly && <SaveStatus isSaving={isSaving} />}
                  <CopyContent copyToClipboard={copyToClipboard} />
                </div>
              </div>
            </div>
          </StatusBar>
        ) : (
          <StatusBar>
            <div className="flex items-center">
              <div className="flex-1"></div>
              <Button
                size={'sm'}
                variant={ENUM_BUTTON_VARIANTS.Tertiary}
                onClick={handleCancelAtomicContent}
              >
                <div className="flex items-center justify-between space-x-1">
                  <SquareIcon />
                  <span>{t('content:editor-components.editor.stop-generating')}</span>
                </div>
              </Button>
              <div className="flex-1"></div>
            </div>
          </StatusBar>
        )}
      </Flex>
      {showFactCheckCard && <PortalFactCheckCard {...{ factCheckPopperCardHook }} />}
      {popperTooltip?.position && (
        <PortalTooltip
          content={popperTooltip?.content}
          placement={popperTooltip?.placement || 'bottom'}
          position={popperTooltip?.position}
        />
      )}
      <AiContentPermissionModal
        setShowPermissionModal={setShowPermissionModal}
        showPermissionModal={showPermissionModal}
      />
      <WorkspaceUnitLimitationModal
        setShowWorkspaceUnitLimitationModal={setShowWorkspaceUnitLimitationModal}
        showWorkspaceUnitLimitationModal={showWorkspaceUnitLimitationModal}
      />
      <AiContentStreamErrorModal
        handleAtomicContent={handleAtomicContent}
        setShowStreamErrorModal={setShowStreamErrorModal}
        showStreamErrorModal={showStreamErrorModal}
      />
      {renderEditorPopper()}
      {renderContentDialogs()}
      {renderFlagContentDialogs()}
    </>
  );
}

export default Editor;
