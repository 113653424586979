import TextField from '@material-ui/core/TextField';
import React, { lazy, Suspense, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { FlexVerticalAlignedContainer } from '@/components/_common';
import { IconButton } from '@/components/Button/Button';
import DotColorPicker from '@/components/ColorPicker/ColorPicker';
import CloseIcon from '@/components/icons/CloseIcon';
import LayoutGrid2AltIcon from '@/components/icons/LayoutGrid2AltIcon';
import { Error } from '@/components/Text/Error';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import { CONTENT_STATUS_TO_ADD } from '@/utils/constants';

const DraggableComponent = lazy(
  () => import(/* webpackChunkName: "DraggableComponent" */ '@/components/Draggable/Draggable')
);

const InputText = styled(({ loading, ...props }) => <TextField {...props} />)`
  && {
    margin: 0 1em;
    font-size: 0.85em;
    font-weight: 500;
    width: 300px;
    min-width: 300px;

    & > div > input {
      opacity: ${(props) => (props.disabled ? 0.7 : 1)};
      cursor: ${(props) => props.disabled && 'not-allowed'};
      color: ${(props) =>
        props.error ? props.theme.text.colors.error : props.theme.text.colors.default};
    }

    & > div {
      &:before,
      &:after {
        border-color: ${(props) => (props.error ? props.theme.text.colors.error : 'transparent')};
      }
    }

    & > div:hover {
      &:before,
      &:after {
        border-color: ${(props) => (props.disabled ? 'transparent' : props.theme.colors.halfGrey)};
      }
    }
  }
`;

const StyledIconButton = styled(IconButton)`
  && {
    font-size: 0.8em;
    color: ${(props) => props.theme.colors.halfGrey};
  }
`;

const Wrapper = styled(({ hoverable, ...props }) => <FlexVerticalAlignedContainer {...props} />)`
  && {
    position: relative;
    padding: 5px;
    cursor: ${(props) => (props.readOnly ? 'not-allowed' : 'initial')};
    background: ${(props) => (props.readOnly ? props.theme.colors.brightGrey : 'transparent')};
    border: 1px solid ${(props) => props.theme.colors.brightGrey};
    border-radius: 5px;
    margin: 6px 6px 6px 0;
    width: 415px;
  }

  ${StyledIconButton} {
    opacity: 0;
  }

  &:hover ${StyledIconButton} {
    opacity: ${(props) => (props.hoverable ? 1 : 0)};

    &:hover {
      cursor: ${(props) => (props.hoverable ? 'pointer' : 'not-allowed')};
    }
  }
`;

const ListIconWrapper = styled(({ hoverable, ...props }) => <LayoutGrid2AltIcon {...props} />)`
  && {
    font-size: 0.8em;
    cursor: ${(props) => (props.hoverable ? 'grab' : 'not-allowed')};
  }
`;

const Container = styled.div``;
const StyledTooltipComponent = styled(TooltipComponent)`
  display: flex;
  align-items: center;
  flex: 1;
`;
const StyledError = styled(Error)`
  position: absolute;
  left: 450px;
  white-space: nowrap;
`;

function StatusInput({ verifyStatus, status, updateLabel, deleteStatus }) {
  const [value, setValue] = useState(status.label);
  const [error, setError] = useState(null);
  const textInputRef = useRef(null);
  const { t } = useTranslation();

  function onChange(e) {
    setValue(e.target.value);
  }

  function onBlur() {
    if (!value) {
      deleteStatus(status);
      return;
    }

    const error = verifyStatus({ label: value, status });
    setError(error);

    if (!error && value !== status.label) {
      updateLabel({ label: value, status });
    }
  }

  function onFocus(e) {
    // so the cursor is set at the end of the text
    const val = e.target.value;
    e.target.value = '';
    e.target.value = val;
  }

  function onClick(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  function onKeyDown(e) {
    if (e.key === 'Enter') {
      textInputRef.current.blur();
    }
  }

  return (
    <>
      <InputText
        key={`${status.id}_${status.position}_input`}
        disabled={status.readOnly}
        error={!!error}
        inputRef={textInputRef}
        placeholder={t(status.placeholder)}
        rowsMax="1"
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        onClick={onClick}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
      />
      <StyledError>{error}</StyledError>
    </>
  );
}

export default function ContentStatusSettings(props) {
  const { t } = useTranslation();
  const generateContentItem = (status) => {
    return (
      <StyledTooltipComponent
        hide={!status.readOnly}
        placement="bottom-start"
        title={t('components:content-status-settings.tooltip-no-edit')}
      >
        <Wrapper hoverable={!status.readOnly} readOnly={status.readOnly}>
          <StyledIconButton
            aria-label={t('components:content-status-settings.aria-label-position')}
            mode="inverted"
          >
            <ListIconWrapper hoverable={!status.readOnly} viewBox="0 0 17 17" />
          </StyledIconButton>
          <DotColorPicker
            disabled={status.readOnly}
            value={status.color}
            onChange={(color) => {
              props.updateColor({ color, status });
            }}
          />
          <StatusInput
            deleteStatus={props.deleteStatus}
            status={status}
            updateLabel={props.updateLabel}
            verifyStatus={props.verifyStatus}
          />
          {CONTENT_STATUS_TO_ADD.id !== status.id && (
            <StyledIconButton
              aria-label={t('components:content-status-settings.aria-label-delete')}
              mode="inverted"
              onClick={() => {
                props.deleteStatus(status);
              }}
            >
              <TooltipComponent
                title={t('components:content-status-settings.tooltip-remove-status')}
              >
                <CloseIcon />
              </TooltipComponent>
            </StyledIconButton>
          )}
        </Wrapper>
      </StyledTooltipComponent>
    );
  };

  const generateItems = (items) => {
    return items.map((status, index) => ({
      ...status,
      Content: () => generateContentItem({ ...status, index }),
    }));
  };

  const onChange = (items) => {
    props.contentStatusesChange(items);
    props.updatePositions(items);
  };

  return (
    <Container>
      {generateContentItem(props.firstStatus)}
      <Suspense fallback={<></>}>
        <DraggableComponent items={generateItems(props.contentStatuses)} onChange={onChange} />
      </Suspense>
      {generateContentItem(props.lastStatus)}
    </Container>
  );
}
