import styled from 'styled-components/macro';

const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  width: 100%;
`;

export default Section;
