import styled from 'styled-components/macro';

const Toolbar = styled.div`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.cssColors.dark005};
  box-sizing: border-box;
  display: grid;
  grid-template-columns: auto 1fr auto;
  height: 55px;
  max-height: 55px;
  min-height: 55px;
  padding: 0 20px;
`;

export default Toolbar;
