import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { LOCATION, SCOPE_SERVICE_INTEGRATIONS } from '@/apis/semji/constants';
import useGetGA4Properties from '@/apis/semji/serviceIntegrations/GoogleAnalytics/useGetGA4Properties';
import useGetUniversalAnalyticsProfiles from '@/apis/semji/serviceIntegrations/GoogleAnalytics/useGetUniversalAnalyticsProfiles';
import useGetUniversalAnalyticsProperties from '@/apis/semji/serviceIntegrations/GoogleAnalytics/useGetUniversalAnalyticsProperties';
import useDeleteServiceIntegration from '@/apis/semji/serviceIntegrations/useDeleteServiceIntegration';
import usePostServiceIntegration from '@/apis/semji/serviceIntegrations/usePostServiceIntegration';
import Stepper from '@/components/Stepper/Stepper';
import SelectService from '@/containers/ServiceIntegration/common/SelectService';
import ConnectStep from '@/containers/ServiceIntegration/GoogleAnalytics/Steps/Connect';
import {
  GOOGLE_ANALYTICS_4_VALUE,
  GOOGLE_ANALYTICS_VALUE,
  SERVICES_LIST,
  TYPE_ANALYTICS,
} from '@/utils/constants';

function GoogleAnalyticsServiceIntegration() {
  const { t } = useTranslation();
  const { workspaceId, organizationId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [step, setStep] = useState(0);
  const [validating, setValidating] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [account, setAccount] = useState(null);
  const [profile, setProfile] = useState(null);
  const [googleCredentials, setGoogleCredentials] = useState(null);
  const [error, setError] = useState(null);

  const currentAnalyticsServiceIntegration = queryClient
    .getQueryData([SCOPE_SERVICE_INTEGRATIONS.GET_SERVICE_INTEGRATIONS, workspaceId, LOCATION.ROOT])
    ?.find((service) => service.type === TYPE_ANALYTICS);

  const currentService = Object.values(SERVICES_LIST).find((service) =>
    service.values.includes(currentAnalyticsServiceIntegration?.name)
  );

  const deleteServiceIntegration = useDeleteServiceIntegration();
  const postServiceIntegration = usePostServiceIntegration({
    onError: (e) => {
      setValidating(false);
      switch (e.error.code) {
        case 400:
        case 422:
          setError(t('service-integration:google-analytics.validation.cant-retrieve-url-error'));
          break;
        default:
          setError(t('service-integration:google-analytics.validation.error'));
      }
    },
    onMutate: async () => {
      setValidating(true);
      setError(null);

      if (currentAnalyticsServiceIntegration) {
        await deleteServiceIntegration.mutateAsync(currentAnalyticsServiceIntegration.id);
      }
    },
    onSuccess: async () => {
      await queryClient.refetchQueries({
        active: true,
        exact: true,
        fetching: false,
        queryKey: [SCOPE_SERVICE_INTEGRATIONS.GET_SERVICE_INTEGRATIONS, workspaceId, LOCATION.ROOT],
      });
      setValidating(false);
      handleGoToServiceIntegration();
    },
  });
  const getAnalyticsProfiles = useGetUniversalAnalyticsProfiles();
  const getAnalyticsProperties = useGetUniversalAnalyticsProperties();
  const getGA4Properties = useGetGA4Properties();

  function handleGoToServiceIntegration() {
    navigate(`/o/${organizationId}/w/${workspaceId}/settings/integrations`);
  }

  function getStep(index) {
    const steps = [
      {
        step: t('service-integration:google-analytics.stepper.first-step'),
        subTitle: t('service-integration:google-analytics.stepper.first-step-subtitle'),
        title: t('service-integration:google-analytics.stepper.first-step-title'),
      },
      {
        step: t('service-integration:google-analytics.stepper.second-step'),
        subTitle: t('service-integration:google-analytics.stepper.second-step-subtitle'),
        title: t('service-integration:google-analytics.stepper.second-step-title'),
      },
      {
        step: t('service-integration:google-analytics.stepper.third-step'),
        subTitle: t('service-integration:google-analytics.stepper.third-step-subtitle'),
        title: t('service-integration:google-analytics.stepper.third-step-title'),
      },
    ];

    return steps[index] || {};
  }

  function handlePrevious() {
    setStep(step - 1);
  }

  function consent(consentData) {
    if (consentData) {
      setAccounts(consentData.accounts);
      setGoogleCredentials(consentData.googleCredentialsValue);
      setStep(step + 1);
    }
  }

  async function handleGetFormatedDataStep1() {
    return accounts
      .filter((account) => account.name && account.id)
      .map((account) => ({ name: account.name, value: account.id }));
  }

  async function handleGetFormatedDataStep2() {
    // keep this order it's important for the usage below
    const data = await Promise.all([
      getAnalyticsProfiles.mutateAsync({
        accessToken: googleCredentials.accessToken,
        accountId: account.value,
      }),
      getAnalyticsProperties.mutateAsync({
        accessToken: googleCredentials.accessToken,
        accountId: account.value,
      }),
      getGA4Properties.mutateAsync({
        accessToken: googleCredentials.accessToken,
        accountId: account.value,
      }),
    ]);

    return [
      ...(data[0]?.items || []).reduce((acc, curr) => {
        if (curr.name && curr.webPropertyId) {
          const webPropertyName = (data[1]?.items || []).find(
            (property) => property.id === curr.webPropertyId
          )?.name;

          return [
            ...acc,
            {
              name: `${webPropertyName} > ${curr.name}`,
              type: GOOGLE_ANALYTICS_VALUE,
              value: `${curr.webPropertyId} / ${curr.id}`,
            },
          ];
        }
        return acc;
      }, []),
      ...(data[2]?.properties || []).reduce(
        (acc, curr) => [
          ...acc,
          {
            name: curr.displayName,
            type: GOOGLE_ANALYTICS_4_VALUE,
            value: `GA4 / ${curr.name.split('/')[1]}`,
          },
        ],
        []
      ),
    ];
  }

  function handleNext() {
    setStep(step + 1);
  }

  function isNextDisabled() {
    if (step === 1) {
      return !account?.value || error;
    }

    if (step === 2) {
      return !profile?.value || validating || error;
    }

    return true;
  }

  async function handleValidate() {
    function getGoogleAnalytics4Data() {
      return {
        property_id: profile.value.split('/')?.[1]?.trim?.(),
        web_property_name: profile.name,
      };
    }

    function getUniversalAnalyticsData() {
      const profileValue = profile.value.split('/');
      const profileName = profile.name.split('/');

      return {
        profile_id: profileValue?.[1]?.trim?.(),
        profile_name: profileName?.[1]?.trim?.(),
        web_property_id: profileValue?.[0]?.trim?.(),
        web_property_name: profileName?.[0]?.trim?.(),
      };
    }

    await postServiceIntegration.mutateAsync({
      data: {
        access_token: googleCredentials.accessToken,
        account_id: account.value,
        code: googleCredentials.code,
        email: googleCredentials.email,
        refresh_token: googleCredentials.refreshToken,
        ...(profile.type === GOOGLE_ANALYTICS_4_VALUE
          ? getGoogleAnalytics4Data()
          : getUniversalAnalyticsData()),
      },
      name: profile.type,
      type: TYPE_ANALYTICS,
    });
  }

  function renderStep() {
    if (step === 0) {
      return <ConnectStep analytic={currentService?.title} consent={consent} setError={setError} />;
    }
    if (step === 1) {
      return (
        <SelectService
          key="step1"
          currentValue={account}
          handleGetFormatedData={handleGetFormatedDataStep1}
          logo={
            <img
              alt={t('service-integration:google-analytics.alt-logo')}
              src={SERVICES_LIST.GOOGLE_ANALYTICS.logo}
              style={SERVICES_LIST.GOOGLE_ANALYTICS.logoStyle}
            />
          }
          setCurrentValue={setAccount}
          title={t('service-integration:google-analytics.select-service-step1.title')}
        />
      );
    }
    if (step === 2) {
      return (
        <SelectService
          key="step2"
          currentValue={profile}
          handleGetFormatedData={handleGetFormatedDataStep2}
          logo={
            <img
              alt={t('service-integration:google-analytics.alt-logo')}
              src={SERVICES_LIST.GOOGLE_ANALYTICS.logo}
              style={SERVICES_LIST.GOOGLE_ANALYTICS.logoStyle}
            />
          }
          setCurrentValue={setProfile}
          title={t('service-integration:google-analytics.select-service-step2.title')}
        />
      );
    }
    return null;
  }

  return (
    <Stepper
      handleCancel={handleGoToServiceIntegration}
      handleNext={step === 2 ? handleValidate : handleNext}
      handlePrevious={handlePrevious}
      header={getStep(step)}
      info={error}
      infoOnError={!!error}
      isNextDisabled={isNextDisabled()}
      nextLabel={
        step === 2
          ? t('service-integration:button-label.activate')
          : t('service-integration:button-label.next')
      }
      previousDisabled={step === 0}
    >
      {renderStep()}
    </Stepper>
  );
}

export default GoogleAnalyticsServiceIntegration;
