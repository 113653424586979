import React from 'react';
import { Select as SemjiSelect, SELECT_VARIANT_FILTER } from 'semji-core/components/Select';

import { SelectOptionItemType, SelectProps } from './Select.types';
import SelectItem from './SelectItem';

export default function Select({
  options,
  onValidate,
  icon,
  name,
  id,
  disabled = false,
  filterHook,
}: SelectProps) {
  function handleChange(option: SelectOptionItemType) {
    onValidate(option.value);
  }
  return (
    <SemjiSelect
      anchorId={id}
      defaultValueIndex={Math.max(
        options.findIndex((option) => option.selected),
        0
      )}
      disabled={disabled}
      DropdownItem={SelectItem}
      dropdownPlacement="bottom-start"
      getValue={(option) => option?.label}
      icon={icon}
      options={options}
      scrollMaxHeight={220}
      title={name}
      variant={SELECT_VARIANT_FILTER}
      width="240px"
      onChange={handleChange}
      onCloseDropdown={() => null}
    />
  );
}
