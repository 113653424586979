import { useState } from 'react';
import { useEffect } from 'react';

function useDetectOperatingSystem() {
  const [operatingSystem, setOperatingSystem] = useState(null);

  // Credits: https://dev.to/vaibhavkhulbe/get-os-details-from-the-webpage-in-javascript-b07
  const getOS = () => {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    let os = null;

    if (macosPlatforms.includes(platform)) {
      os = 'Mac';
    } else if (iosPlatforms.includes(platform)) {
      os = 'iOS';
    } else if (windowsPlatforms.includes(platform)) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }

    return os;
  };

  useEffect(() => {
    const os = getOS();
    setOperatingSystem(os);
  }, []);

  return operatingSystem;
}

export default useDetectOperatingSystem;
