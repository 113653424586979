import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import usePostTopKeywordAnalysis from '@/apis/semji/topKeywords/usePostTopKeywordAnalysis';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import { openCreditLimitDialog } from '@/store/actions/report';
import { showErrorSnackbar } from '@/store/actions/ui';
import { selectRemainingAnalysisUnit } from '@/store/selectors/selectRemainingAnalysisUnit';
import { SOURCE_EDITOR } from '@/utils/analysis';
import { ANALYSIS_CREDITS_TYPE } from '@/utils/constants';

const UpdateAnalysis = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.cssColors.dark060};
  justify-content: flex-start;
  display: flex;
  gap: 5px;
  padding: 0 2px;
`;

const UpdateAnalysisWrapper = styled.div`
  position: sticky;
  z-index: 1;
  justify-content: center;
`;

const CustomCTA = styled.span`
  cursor: pointer;
  color: ${(props) => props.theme.cssColors.primary};
`;

function RelaunchAnalysis({ focusTopKeyword, refreshReport }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const remainingAnalysisUnit = useSelector(selectRemainingAnalysisUnit);

  const [diffInDays, setDiffInDays] = useState(null);
  const [analysisDate, setAnalysisDate] = useState(null);

  const { isFeatureEnabled: hasUnlimitedAnalysisCredits } = useOrganizationFeatureSet(
    'credits:analysis:has-unlimited-amount'
  );

  const topKeywordAnalysis = usePostTopKeywordAnalysis({
    onError: (error) => {
      if (error.response.status === 403) {
        openCreditLimitReached();
      } else {
        dispatch(showErrorSnackbar(t('common:error.default')));
      }
    },
    onSuccess: () => refreshReport(),
  });

  const openCreditLimitReached = () => {
    dispatch(openCreditLimitDialog(ANALYSIS_CREDITS_TYPE));
  };

  useEffect(() => {
    const focusTopKeywordLastAnalysisDate = DateTime.fromISO(focusTopKeyword.analyzedAt);
    const today = DateTime.fromJSDate(new Date());
    const diff = today.diff(focusTopKeywordLastAnalysisDate, ['days']).toObject();
    setDiffInDays(Math.round(diff.days));
    setAnalysisDate(focusTopKeywordLastAnalysisDate);
  }, [focusTopKeyword.analyzedAt]);

  async function triggerUpdateAnalysis() {
    if (!hasUnlimitedAnalysisCredits && remainingAnalysisUnit < 1) {
      return openCreditLimitReached();
    }

    setDiffInDays(null);

    await topKeywordAnalysis.mutateAsync({
      data: {
        source: SOURCE_EDITOR,
      },
      topKeywordIdList: [focusTopKeyword.id],
    });
  }

  if (!diffInDays) {
    return null;
  }

  const text = diffInDays >= 7 ? analysisDate?.toFormat('MMM dd') : analysisDate?.toRelative();

  return (
    <UpdateAnalysisWrapper>
      <UpdateAnalysis>
        <span>
          {t('content:side-panel-components.relaunch-analysis.text')}{' '}
          <span title={analysisDate.toFormat('LLL dd, yyyy HH:mm:ss')}>{text}.</span>
        </span>
        <TooltipComponent
          description={t(
            `content:side-panel-components.relaunch-analysis.tooltip-description${
              hasUnlimitedAnalysisCredits ? '-unlimited' : ''
            }`
          )}
          title={t('content:side-panel-components.relaunch-analysis.tooltip-title')}
        >
          <CustomCTA
            data-intercom-target="editor-sidepanel-report__relaunch-analysis"
            onClick={triggerUpdateAnalysis}
          >
            {t('content:side-panel-components.relaunch-analysis.cta-text')}
          </CustomCTA>
        </TooltipComponent>
      </UpdateAnalysis>
    </UpdateAnalysisWrapper>
  );
}

export default RelaunchAnalysis;
