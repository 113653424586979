import React from 'react';

function Alert1Icon(props) {
  return (
    <svg fill="none" height="10" viewBox="0 0 10 10" width="10" {...props}>
      <rect fill="#FF5451" height="10" rx="5.00002" width="10" />
      <g clipPath="url(#clip0_472_223949)">
        <path d="M5.44657 5.37558H4.55371V1.875H5.44657V5.37558Z" fill="white" />
        <path
          d="M4.96035 7.84717C5.37694 7.84717 5.71367 7.51117 5.71367 7.09717C5.71367 6.68317 5.37694 6.34717 4.96035 6.34717C4.54377 6.34717 4.20703 6.68317 4.20703 7.09717C4.20703 7.51117 4.54377 7.84717 4.96035 7.84717Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_472_223949">
          <rect
            fill="white"
            height="6.25002"
            transform="translate(4.10742 1.875)"
            width="1.78572"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Alert1Icon;
