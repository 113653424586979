function UpdatedContentIcon() {
  return (
    <svg fill="none" height="45" viewBox="0 0 80 45" width="80" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M70.0388 40.8079C70.0388 42.7431 56.6797 44.2997 40.2219 44.2997C23.7641 44.2997 10.405 42.7431 10.405 40.8079C10.405 38.8727 23.7641 39.083 40.2219 39.083C56.6797 39.083 70.0388 38.8727 70.0388 40.8079Z"
        fill="#FFEBED"
      />
      <path
        d="M52.1294 9.25418C56.9967 9.25418 62.4363 14.23 62.4363 20.8052C62.4363 25.9889 58.1713 32.0007 52.1294 32.0007L38.4819 32.0007L43.27 27.1167L42.7108 26.4918L36.491 32.7115L42.7108 38.8694L43.4876 38.2165L38.2642 32.9931L52.1294 32.9931C58.1092 32.9931 63.5106 27.1097 63.5106 20.8052C63.5106 14.5006 58.349 8.54335 52.1294 8.54335L52.1294 9.25418Z"
        fill="#FFCCD3"
      />
      <path
        d="M28.3169 32.3562C23.4496 32.3562 18.01 27.3804 18.01 20.8052C18.01 15.6215 22.275 9.60965 28.3169 9.60965L41.9644 9.60965L37.1763 14.4936L37.7355 15.1186L43.9553 8.89882L37.7355 2.74097L36.9587 3.39389L42.1821 8.61729L28.3169 8.61729C22.3371 8.61729 16.9357 14.5007 16.9357 20.8052C16.9357 27.1097 22.0973 33.067 28.3169 33.067V32.3562Z"
        fill="#FFCCD3"
      />
      <path
        d="M29.3436 31.4949V31.0596H28.9083C23.555 31.0596 19.2345 26.455 19.2345 20.8855C19.2345 15.1597 23.5508 10.7115 28.9083 10.7115H39.091L35.5 14.3089L35.193 14.6164L35.5 14.9239L37.4256 16.8529L37.7337 17.1615L38.0417 16.8529L45.5833 9.29773L45.8902 8.99021L45.5833 8.6827L38.0417 1.12754L37.7337 0.81893L37.4256 1.12753L35.5 3.05652L35.193 3.36404L35.5 3.67157L39.091 7.26897H28.9083C21.7535 7.26897 15.7967 13.4264 15.7967 20.8855C15.7967 28.341 21.5895 34.5021 28.9083 34.5021H29.3436V34.0668V31.4949ZM51.5332 7.10821H51.0979V7.5435V10.1155V10.5507H51.5332C56.8866 10.5507 61.2068 15.1553 61.2068 20.7248C61.2068 26.2943 56.8866 30.8988 51.5332 30.8988H41.3505L44.9414 27.3015L45.2484 26.994L44.9414 26.6865L43.016 24.7575L42.7079 24.4488L42.3998 24.7575L34.8581 32.3126L34.5511 32.6201L34.8581 32.9276L42.3998 40.4827L42.7079 40.7914L43.016 40.4827L44.9414 38.5538L45.2484 38.2463L44.9414 37.9388L41.3505 34.3414H51.5332C58.8504 34.3414 64.6424 28.1829 64.6449 20.7296C64.808 13.2598 58.8418 7.10821 51.5332 7.10821Z"
        stroke="#51525E"
        strokeWidth="0.870566"
      />
      <rect fill="#FFEBED" height="3.04698" rx="1.52349" width="6.52924" x="6.92322" y="17.9756" />
      <rect fill="#FFEBED" height="3.04698" rx="1.52349" width="5.65868" x="13.017" y="5.99426" />
      <rect fill="#FFEBED" height="3.04698" rx="1.52349" width="3.48226" x="0.829224" y="11.4463" />
      <rect fill="#FFEBED" height="3.04698" rx="1.52349" width="8.70566" x="6.05334" y="11.4463" />
      <rect
        fill="#FFEBED"
        height="3.04698"
        rx="1.52349"
        transform="rotate(-180 73.087 24.0698)"
        width="6.52924"
        x="73.087"
        y="24.0698"
      />
      <rect
        fill="#FFEBED"
        height="3.04698"
        rx="1.52349"
        transform="rotate(-180 66.9932 34.5164)"
        width="5.65868"
        x="66.9932"
        y="34.5164"
      />
      <rect
        fill="#FFEBED"
        height="3.04698"
        rx="1.52349"
        transform="rotate(-180 79.6158 29.7281)"
        width="3.48226"
        x="79.6158"
        y="29.7281"
      />
      <rect
        fill="#FFEBED"
        height="3.04698"
        rx="1.52349"
        transform="rotate(-180 74.3917 29.7281)"
        width="8.70566"
        x="74.3917"
        y="29.7281"
      />
    </svg>
  );
}

export default UpdatedContentIcon;
