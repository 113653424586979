import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import apiClient from '@/apis/semji/apiClient';
import { showErrorSnackbar } from '@/store/actions/ui';

export default function usePutServiceIntegration(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { put } = apiClient;

  return useMutation({
    mutationFn: async ({ id, data }) => {
      await put(`/service_integrations/${id}`, { data });
    },

    onError: () => {
      dispatch(showErrorSnackbar(t('common:error.default')));
    },
    ...props,
  });
}
