import './Listing.scss';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'semji-core/components/Button';

import useDeleteBrandVoiceById from '@/apis/semji/brandVoices/useDeleteBrandVoiceById';
import useGetAllBrandVoicesByWorkspaceId from '@/apis/semji/brandVoices/useGetAllBrandVoicesByWorkspaceId';
import EmptyImage from '@/assets/images/brand-voice-empty-state.svg';
import DialogTemplate from '@/components/Dialog/DialogTemplate';
import useWorkspaceUsers from '@/hooks/useWorkspaceUsers';

import EmptyBox from './EmptyBox';
import TableBrandVoices from './Table';

function BrandVoiceListing() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [brandVoiceToDeleteId, setBrandVoiceToDeleteId] = useState(undefined);
  const { workspaceId, organizationId } = useParams();
  const {
    data: brandVoices = [],
    isFetching,
    isPlaceholderData,
    refetch,
  } = useGetAllBrandVoicesByWorkspaceId(workspaceId);

  const brandVoiceToDelete = brandVoices.find(
    (brandVoice) => brandVoice.id === brandVoiceToDeleteId
  );

  function handleDeleteSuccess() {
    handleCloseDeleteModal();
    refetch();
  }

  const deleteMutation = useDeleteBrandVoiceById({ onSuccess: handleDeleteSuccess });

  const isLoading = isFetching && isPlaceholderData;
  const users = useWorkspaceUsers();

  function navigateToCreateForm() {
    navigate(`/o/${organizationId}/w/${workspaceId}/brand-voice/create`);
  }

  function handleDelete(brandVoiceId) {
    setBrandVoiceToDeleteId(brandVoiceId);
  }

  function handleConfirmDelete() {
    deleteMutation.mutate(brandVoiceToDeleteId);
  }

  function handleCloseDeleteModal() {
    setBrandVoiceToDeleteId();
  }

  function mapUserToBrandVoice(brandVoices) {
    return brandVoices.map((brandVoice) => {
      const creatorUser = users.find((user) => user.id === brandVoice.createdById);
      const editorUser = users.find((user) => user.id === brandVoice.updatedById);
      return {
        ...brandVoice,
        createdByName: creatorUser ? `${creatorUser.firstName} ${creatorUser.lastName}` : '-',
        updatedByName: editorUser ? `${editorUser.firstName} ${editorUser.lastName}` : '-',
      };
    });
  }

  return (
    <>
      <div className="brand-voice-page-listing">
        <div className="brand-voice-page-listing__header">
          <h1 className="brand-voice-page-listing__title">
            {t('brand-voice:listing.brand-voice')}
          </h1>
          <div className="brand-voice-page-listing__actions">
            <Button varian="primary-gradient" onClick={navigateToCreateForm}>
              {t('brand-voice:listing.create-new')}
            </Button>
          </div>
        </div>
        <div className="brand-voice-page-listing__content">
          {brandVoices.length || isLoading ? (
            <TableBrandVoices
              handleDelete={handleDelete}
              isLoading={isLoading}
              rows={mapUserToBrandVoice(brandVoices)}
            />
          ) : (
            <EmptyBox
              buttonTitle={t('brand-voice:listing.create-new')}
              description={t('brand-voice:listing.no-brand-voice-desc')}
              image={EmptyImage}
              title={t('brand-voice:listing.no-brand-voice')}
              onClick={navigateToCreateForm}
            />
          )}
        </div>
        <DialogTemplate
          cancelLabel={t('brand-voice:listing.modal.dialog-cancel-label')}
          confirmAction={handleConfirmDelete}
          confirmLabel={t('brand-voice:listing.modal.dialog-confirm-label')}
          fullWidth
          maxWidth="sm"
          open={!!brandVoiceToDeleteId}
          title={t('brand-voice:listing.modal.dialog-title')}
          onClose={handleCloseDeleteModal}
        >
          <div className="brand-voice-page-listing__dialog-body">
            <p>
              {t('brand-voice:listing.modal.brand-voice')} :{' '}
              <span className="brand-voice-page-listing__dialog-body__highligth">
                {brandVoiceToDelete?.name}
              </span>
            </p>
            <p>{t('brand-voice:listing.modal.dialog-desc')}</p>
          </div>
        </DialogTemplate>
      </div>
    </>
  );
}

export default BrandVoiceListing;
