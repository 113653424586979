import i18next from 'i18next';

import { FilterOption } from '@/components/DynamicFilters';

export const CATEGORY_ARTICLE = 'article';
export const CATEGORY_ARTICLE_CATEGORY = 'article_category';
export const CATEGORY_ECOMMERCE = 'ecommerce_category';
export const CATEGORY_HOMEPAGE = 'homepage';
export const CATEGORY_LANDING_PAGE = 'landing_page';
export const CATEGORY_MISC = 'misc';
export const CATEGORY_NEWS = 'news';
export const CATEGORY_PRODUCT_SHEET = 'ecommerce_product_sheet';
export const CATEGORY_SHOPPING = 'shopping';
export const CATEGORY_TOOL = 'tool';
export const CATEGORY_FORUM = 'forum';

export const CATEGORY_TYPES = [
  CATEGORY_ARTICLE,
  CATEGORY_ARTICLE_CATEGORY,
  CATEGORY_ECOMMERCE,
  CATEGORY_HOMEPAGE,
  CATEGORY_LANDING_PAGE,
  CATEGORY_MISC,
  CATEGORY_NEWS,
  CATEGORY_PRODUCT_SHEET,
  CATEGORY_SHOPPING,
  CATEGORY_TOOL,
  CATEGORY_FORUM,
];

// Options available for urlCategory
export const getCategoriesOptions = (filter?: string[]): FilterOption[] =>
  CATEGORY_TYPES.map((option) => ({
    id: option,
    label: i18next.t(`competitors:filters.page-type-list.${option}`),
    selected: filter?.includes(option),
    value: option,
  }));
