import './Header.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ENUM_ICON_BUTTON_BORDER,
  ENUM_ICON_BUTTON_ROUNDING,
  IconButton,
} from 'semji-core/components/Button/IconButton';
import { DownIcon, ENUM_DOWN_ICON_DIRECTION } from 'semji-core/icons/DownIcon';

export default function Header({ title, onGoBack }: { title: string; onGoBack: () => void }) {
  const { t } = useTranslation();

  return (
    <div className="content-version-header" id="semji-navbar">
      <IconButton
        borderStyle={ENUM_ICON_BUTTON_BORDER.None}
        className="content-version-header__back-button"
        data-intercom-target="content-version-go-back"
        roundedStyle={ENUM_ICON_BUTTON_ROUNDING.Rounded}
        onClick={onGoBack}
      >
        <DownIcon
          className="content-version-header__back-button__icon"
          direction={ENUM_DOWN_ICON_DIRECTION.LEFT}
          theme="light"
        />
        <span>{t('content:content-version.go-back')}</span>
      </IconButton>
      <h1 className="content-version-header__title">{title}</h1>
      <div className="content-version-header__spacer" />
    </div>
  );
}
