import { Placement, VirtualElement } from '@popperjs/core';
import React from 'react';
import { Popper } from 'semji-core/components/Popper';

import TooltipComponent from '@/components/Tooltip/Tooltip';

function PortalTooltip({
  position,
  placement,
  content,
}: {
  position:
    | HTMLElement
    | VirtualElement
    | (() => HTMLElement)
    | (() => VirtualElement)
    | null
    | undefined;
  placement: Placement;
  content: React.ReactNode;
}): React.JSX.Element {
  return (
    <Popper anchorEl={position} open placement={placement}>
      <TooltipComponent content={content} open={true} />
    </Popper>
  );
}

export default PortalTooltip;
