import styled from 'styled-components/macro';

const GroupButtons = styled.div`
  &&& {
    display: flex;
    margin: ${({ margin }) => margin || '0 20px 0 0'};

    button {
      border-radius: 0;
    }

    button:first-child {
      border-radius: 5px 0% 0% 5px;
      border-right: none;
    }

    button:last-child {
      border-radius: 0% 5px 5px 0%;
    }
  }
`;
export default GroupButtons;
