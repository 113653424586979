import { SPLIT_CLASS_NAME_SYMBOL, SPLIT_DATA_SYMBOL, TextRangeType } from '../constants';
import getElementClassNames from './getElementClassNames';
import getElementDataByAttribute from './getElementDataByAttribute';
import getTexts from './getTexts';
import handleCreateElement from './handleCreateElement';
import isElementAlreadyHighlightedByDatum from './isElementAlreadyHighlightedByDatum';
import isElementHighlighted from './isElementHighlighted';

function highlightElement({
  element,
  range,
  highlightClass,
  datum,
  dataAttribute,
  overrideClassNames,
  overrideData,
}: {
  element: HTMLElement;
  range: Range;
  highlightClass: string;
  datum: string;
  dataAttribute: string;
  overrideClassNames: boolean;
  overrideData: boolean;
}): void {
  const texts: TextRangeType = getTexts({ element, range });

  if (!texts || !element.parentElement) {
    return;
  }

  const parent: HTMLElement = element.parentElement;
  const haveToHighlightParent: boolean =
    isElementHighlighted({ element: parent, highlightClass }) &&
    !isElementAlreadyHighlightedByDatum({
      dataAttribute,
      datum: datum.toString(),
      element: parent,
    });

  if (haveToHighlightParent && !parent.parentElement) {
    return;
  }

  const dataCollection: string[] = getElementDataByAttribute({ dataAttribute, element: parent });
  const previousData: string = dataCollection.join(SPLIT_DATA_SYMBOL);
  let data: string = previousData;

  if (haveToHighlightParent && !overrideData && !!previousData) {
    if (!dataCollection.includes(datum)) {
      data = `${previousData}${SPLIT_DATA_SYMBOL}${datum}`;
    }
  } else {
    data = datum;
  }

  const classesCollection: string[] = getElementClassNames({ element: parent });
  const previousClasses: string = classesCollection.join(SPLIT_CLASS_NAME_SYMBOL);
  let classes: string = previousClasses;

  if (haveToHighlightParent && !overrideClassNames && !!previousClasses) {
    if (!classesCollection.includes(highlightClass)) {
      classes = `${previousClasses}${SPLIT_CLASS_NAME_SYMBOL}${highlightClass}`;
    }
  } else {
    classes = highlightClass;
  }

  const highlight: Element | Text = handleCreateElement({
    dataAttribute,
    datum: data,
    highlightClass: classes,
    isHighlight: true,
    textContent: texts.text,
  });
  const newParent: HTMLElement = haveToHighlightParent
    ? (parent.parentElement as HTMLElement)
    : parent;
  const elementToReplace = haveToHighlightParent ? parent : element;

  newParent.replaceChild(highlight, elementToReplace);

  if (texts.prevText) {
    highlight.before(
      handleCreateElement({
        dataAttribute,
        datum: previousData,
        highlightClass: previousClasses,
        isHighlight: haveToHighlightParent,
        textContent: texts.prevText,
      })
    );
  }

  if (texts.nextText) {
    highlight.after(
      handleCreateElement({
        dataAttribute,
        datum: previousData,
        highlightClass: previousClasses,
        isHighlight: haveToHighlightParent,
        textContent: texts.nextText,
      })
    );
  }
}

export default highlightElement;
