import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';

import { FlexCenteredAlignContainer } from '../_common';
import TooltipComponent from '../Tooltip/Tooltip';
import Country from './Country';
import Language from './Language';

const StyledLanguage = styled(Language)`
  && {
    position: absolute;
    top: -8px;
    right: -8px;
  }
`;
const Wrapper = styled.div`
  position: relative;
  width: ${(props) => props.width + 'px'};
  height: ${(props) => props.height + 'px'};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
`;

function CountryLanguage({
  languageCode,
  countryCode,
  countryName,
  mode,
  isObvious,
  height,
  width,
  disabled,
  ...props
}) {
  const coefficientLanguageCountrySize = 1.66;

  return (
    <Wrapper height={height} width={width} {...props}>
      <TooltipComponent title={countryName ?? `${countryCode}/${languageCode}`}>
        <FlexCenteredAlignContainer>
          <Country countryCode={countryCode} height={height} mode={mode} width={width} />
          {!isObvious && (
            <StyledLanguage
              height={height / coefficientLanguageCountrySize}
              languageCode={languageCode}
              mode={mode}
              width={width / coefficientLanguageCountrySize}
            />
          )}
        </FlexCenteredAlignContainer>
      </TooltipComponent>
    </Wrapper>
  );
}

CountryLanguage.propTypes = {
  countryCode: PropTypes.string.isRequired,
  height: PropTypes.number,
  languageCode: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(['circ', 'rect']),
  width: PropTypes.number,
};

CountryLanguage.defaultProps = {
  height: 25,
  mode: 'circ',
  width: 25,
};

export default CountryLanguage;
