import { useMemo } from 'react';

import { METRICS_CONFIG as NEW_METRICS_CONFIG } from '@/containers/Report/utils/constants';
import useCan from '@/hooks/useCan';
import useDeepEqualSelector from '@/store/hooks/useDeepEqualSelector';
import { selectSavedAnalyticsGoals } from '@/store/selectors/selectSavedAnalyticsGoals';
import {
  GOAL_METRIC_TEMPLATE,
  GOAL_RATE_METRIC_TEMPLATE,
  METRIC_POSITION_KEY,
  METRIC_REVENUE_KEY,
  METRICS_CONFIG as DEFAULT_METRICS_CONFIG,
} from '@/utils/metrics/constants';

export const useMetricsConfig = ({ isNew = false } = {}) => {
  const savedGoals = useDeepEqualSelector(selectSavedAnalyticsGoals);
  const rankTrackingFeatFlag = useCan({ perform: 'rank-tracking:enabled' });

  const METRICS_CONFIG = isNew ? NEW_METRICS_CONFIG : DEFAULT_METRICS_CONFIG;

  if (METRIC_POSITION_KEY in METRICS_CONFIG) {
    METRICS_CONFIG[METRIC_POSITION_KEY].default = rankTrackingFeatFlag;
  }
  if (METRIC_REVENUE_KEY in METRICS_CONFIG) {
    METRICS_CONFIG[METRIC_REVENUE_KEY].default = !rankTrackingFeatFlag;
  }

  return useMemo(
    () => ({
      ...METRICS_CONFIG,
      // Spread goals from local store
      ...Object.keys(savedGoals).reduce((acc, key) => {
        /* Key corresponds to key metric: goalX
          id is assigned from API */
        if (!savedGoals[key]) {
          return { ...acc };
        }
        const { id, name } = savedGoals[key];

        return {
          ...acc,
          [key]: {
            // Template used for those specific metrics
            ...GOAL_METRIC_TEMPLATE,
            id,
            key,
            name: isNew ? GOAL_METRIC_TEMPLATE.parentName : name,
          },
          [`${key}Rate`]: {
            // Template used for those specific metrics
            ...GOAL_RATE_METRIC_TEMPLATE,
            id: `${id}Rate`,
            key: `${key}Rate`,
            name: isNew ? GOAL_RATE_METRIC_TEMPLATE.parentName : `${name} Rate`,
          },
        };
      }, {}),
    }),
    [savedGoals]
  );
};
