import React, { MutableRefObject, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import usePutGeneratedContentReport from '@/apis/semji/generated/usePutGeneratedContentReport';
import ThumbDownIcon from '@/components/icons/ThumbDownIcon';
import ThumbUpIcon from '@/components/icons/ThumbUpIcon';
import FlagContentDialog from '@/containers/Content/BriefComponents/FlagContentDialog';
import { GENERATION_TITLE_TYPE } from '@/containers/Content/EditorComponents/Ai/constants';
import { LastGenerationRef } from '@/containers/Content/TinyMceComponents/Editor/hooks/useAiWriting/useAiWriting.types';
import Flex from '@/design-system/components/Flex';
import useApiConfigurations from '@/hooks/useApiConfigurations';
import {
  clearSnackbar,
  showErrorSnackbar,
  showSnackbar,
  showSuccessSnackbar,
} from '@/store/actions/ui';
import { AI_WRITING_ASK_FOR_RATE_PROBABILITY } from '@/utils/configurations/constants';
import { Log } from '@/utils/log/Log';

import { FlagContentHookResults, ReportGeneratedContentState } from './useFlagContent.types';

export const AI_WRITING_REPORT_POSITIVE = 'positive';
export const AI_WRITING_REPORT_NEGATIVE = 'negative';

function useFlagContent({
  lastGeneration,
}: {
  lastGeneration: MutableRefObject<LastGenerationRef>;
}): FlagContentHookResults {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [reportGeneratedContentState, setReportGeneratedContentState] =
    useState<ReportGeneratedContentState>({
      id: undefined,
      isOpen: false,
      text: '',
    });
  const ASK_FEEDBACK_RATE = useApiConfigurations(AI_WRITING_ASK_FOR_RATE_PROBABILITY);

  const putGeneratedContentReport = usePutGeneratedContentReport();

  async function goodFeedback(setContentReported: () => void) {
    try {
      await (putGeneratedContentReport as any).mutateAsync({
        id: lastGeneration.current.id,
        rating: AI_WRITING_REPORT_POSITIVE,
      });
      dispatch(showSuccessSnackbar(t('content:brief.flag-content-success-snackbar')));
      setContentReported();
    } catch (error) {
      Log.report({
        context: 'Error while sending good feedback',
        error: error,
        extra: 'errors on atomic content',
        section: '',
      });
      dispatch(showErrorSnackbar(t('content:brief.flag-content-error-snackbar')));
    }
  }

  function badFeedback() {
    handleFlagContent();
  }

  function askFeedback({
    id,
    text,
    rate = ASK_FEEDBACK_RATE,
  }: {
    id: string;
    text: string;
    rate: number;
  }) {
    function handleFeedback({
      rating,
    }: {
      rating: typeof AI_WRITING_REPORT_NEGATIVE | typeof AI_WRITING_REPORT_POSITIVE;
    }) {
      return () => {
        putGeneratedContentReport.mutate({ id, rating });
        dispatch(clearSnackbar());
        if (rating === AI_WRITING_REPORT_NEGATIVE) {
          setReportGeneratedContentState({ id, isOpen: true, text });
        }
      };
    }

    if (Math.random() < rate) {
      dispatch(
        showSnackbar(t('content:ai-writing.feedback'), [
          <Flex key="actions">
            <ThumbUpIcon
              style={{ cursor: 'pointer', fontSize: '20px', marginRight: '15px' }}
              onClick={handleFeedback({ rating: AI_WRITING_REPORT_POSITIVE })}
            />
            <ThumbDownIcon
              style={{ cursor: 'pointer', fontSize: '20px', marginRight: '5px' }}
              onClick={handleFeedback({ rating: AI_WRITING_REPORT_NEGATIVE })}
            />
          </Flex>,
        ])
      );
    }
  }

  function renderFlagContentDialogs() {
    function handleCancel() {
      setReportGeneratedContentState({
        id: undefined,
        isOpen: false,
        text: '',
      });
    }

    async function handleConfirm({
      id,
      report,
      setContentReported,
    }: {
      id: string;
      report: any;
      setContentReported: () => void;
    }) {
      try {
        await putGeneratedContentReport.mutateAsync({
          id,
          rating: AI_WRITING_REPORT_NEGATIVE,
          report,
        });
        dispatch(showSuccessSnackbar(t('content:brief.flag-content-success-snackbar')));
        setContentReported();
      } catch {
        dispatch(showErrorSnackbar(t('content:brief.flag-content-error-snackbar')));
      } finally {
        handleCancel();
      }
    }

    return (
      <FlagContentDialog
        flaggedContent={reportGeneratedContentState}
        isLoading={putGeneratedContentReport.isLoading}
        isOpen={reportGeneratedContentState.isOpen}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
    );
  }

  function handleFlagContent() {
    setReportGeneratedContentState({
      id: lastGeneration?.current?.id,
      isOpen: true,
      text:
        lastGeneration.current?.nodes?.length === 1
          ? lastGeneration.current?.type === GENERATION_TITLE_TYPE
            ? lastGeneration.current?.nodes[0]?.trim()
            : lastGeneration.current?.nodes[0]?.innerHTML?.trim()
          : lastGeneration.current?.nodes?.map((node: any) => node.outerHTML.trim())?.join(''),
    });
  }

  return {
    askFeedback,
    badFeedback,
    goodFeedback,
    handleFlagContent,
    renderFlagContentDialogs,
  };
}

export default useFlagContent;
