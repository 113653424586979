import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import {
  REQUEST_ERROR_RETRIES,
  REQUEST_ERROR_TIMEOUT,
  SEMJI_MAINTENANCE_ERRORS,
} from '@/apis/semji/constants';
import setupApiClient from '@/apis/semji/setupApiClient';
import ForceDesktopLayout from '@/components/ForceDesktopLayout';
import Mock from '@/components/Mock';
import RecursiveCalls from '@/components/RecursiveCalls';
import NotificationSnackbar from '@/components/Snackbar/Snackbar';
import Authentication from '@/containers/Authentication';
import HubspotContainer from '@/containers/Hubspot/HubspotContainer';
import ThemeLoader from '@/hoc/ThemeLoader';
import I18n from '@/i18n/components/I18n';
import { LogProvider } from '@/providers/LogProvider';
import defaultTheme from '@/themes/defaultTheme';
import { generateHubspotScript, HUBSPOT_ID } from '@/utils/3rdParty/Hubspot';
import { generateGoogleTagManagerScript } from '@/utils/googleTagManager';
import { generateIntercomScript } from '@/utils/intercomScript';

function shoudlRetryRequest(failureCount, error) {
  return (
    SEMJI_MAINTENANCE_ERRORS.includes(error?.response?.status) &&
    failureCount < REQUEST_ERROR_RETRIES
  );
}

const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      retry: shoudlRetryRequest,
      retryDelay: REQUEST_ERROR_TIMEOUT,
    },
    queries: {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      retry: shoudlRetryRequest,
      retryDelay: REQUEST_ERROR_TIMEOUT,
      staleTime: 0,
    },
  },
});

function Root({ store, browserAgent, children }) {
  setupApiClient();

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistStore(store)}>
        <Mock />
        <LogProvider browserAgent={browserAgent}>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <ThemeLoader theme={defaultTheme}>
              <BrowserRouter>
                <Helmet>
                  {HUBSPOT_ID && generateHubspotScript()}
                  {!!generateGoogleTagManagerScript() && (
                    <script>{generateGoogleTagManagerScript()}</script>
                  )}
                  {!!generateIntercomScript() && <script>{generateIntercomScript()}</script>}
                </Helmet>
                {/* Suspense is mandatory for the asynchronous load of traductions */}
                <Suspense fallback={<></>}>
                  {/* Added here because the Root component doesn't have access to Redux Provider yet and Application is not loaded on login screen */}
                  <ForceDesktopLayout />
                  <I18n />
                  <RecursiveCalls />
                  <NotificationSnackbar />
                  {HUBSPOT_ID && <HubspotContainer />}
                  <Authentication />
                </Suspense>
              </BrowserRouter>
            </ThemeLoader>
          </QueryClientProvider>
        </LogProvider>
      </PersistGate>
    </Provider>
  );
}

export default Root;
