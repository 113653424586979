import styled from 'styled-components/macro';

import { FlexColumnContainer } from '@/components/_common';
import FavIcon from '@/components/FavIcon/FavIcon';
import { IconExternalLink } from '@/components/Navigation/Link';
import { FlexTooltipComponent } from '@/components/Tooltip/Tooltip';
import Flex from '@/design-system/components/Flex';

export const Wrapper = styled(Flex)`
  min-width: 0;
  min-height: 40px;
`;

export const TextWrapper = styled(FlexColumnContainer)`
  min-width: 0;
  justify-content: space-between;
  gap: 5px;
`;

export const ContentIdeaTitleWrapper = styled.a`
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  text-decoration: none;
  color: ${({ theme }) => theme.cssColors.dark100};
`;

export const StyledFavIcon = styled(FavIcon)`
  && {
    img {
      padding: 0;
      height: 12px;
      width: 12px;
      border-radius: 3px;
      margin-right: 5px;
    }
  }
`;

export const ClustersBadgeWrapper = styled(Flex)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: white;
  max-width: 100%;
`;

export const StyledTooltipComponent = styled(FlexTooltipComponent)`
  min-width: 0;
  margin-left: 2px;

  &:not(: first-child) {
    margin-left: 6px;
  }
`;

export const StyledIconExternalLink = styled(IconExternalLink)`
  && {
    display: inline;
    opacity: 0;
  }
`;
