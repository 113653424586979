import IconButton from '@material-ui/core/IconButton';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DROPDOWN_SIZES } from 'semji-core/components/Dropdown';
import { FolderSelect } from 'semji-core/components/FolderSelect';
import { Checkbox } from 'semji-core/components/Input/Checkbox';
import { Separator } from 'semji-core/components/Separator';
import { StatusSelect } from 'semji-core/components/StatusSelect';
import { DeleteIcon } from 'semji-core/icons/DeleteIcon';
import styled from 'styled-components/macro';

import useGetCurrentWorkspaceAllFolders from '@/apis/semji/folders/useGetCurrentWorkspaceAllFolders';
import usePostFolder from '@/apis/semji/folders/usePostFolder';
import { VerticalSeparator } from '@/components/ActionsBar/BulkActionsBar';
import UrlSuggest from '@/components/Autocomplete/UrlSuggest';
import { TextButton } from '@/components/Button/Button';
import DatePicker from '@/components/DatePicker';
import User2Icon from '@/components/icons/User2Icon';
import FlatLoader, { CircularLoaderWrapper, LoaderWrapper } from '@/components/Loader/FlatLoader';
import {
  DEFAULT_FOLDER_GENERAL,
  getDefaultFolderGeneral,
} from '@/components/Select/FolderSelect/constants';
import UserSelect from '@/components/Select/UserSelect';
import BulkActionButton from '@/components/TableBulkActions/BulkActionButton';
import BulkActionIcon from '@/components/TableBulkActions/BulkActionIcon';
import DatePickerBulk from '@/components/TableBulkActions/DatePicker';
import { DarkerText, DarkText } from '@/components/Text/Dark';
import { StatusBulk, StyledStatusSelect } from '@/containers/Planning/Planning.styled';
import useNullUser from '@/hooks/useNullUser';
import { selectHasSearchConsole } from '@/store/selectors/selectHasSearchConsole';

const FlexContainer = styled.div`
  display: flex;
  gap: 5px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`;
const StyledDarkerText = styled(({ pxSize, ...props }) => <DarkerText {...props} />)`
  && {
    width: ${(props) => props.pxSize}px;
    max-width: 700px;
    margin-right: 18px;
  }
`;
const DeleteButton = styled(({ display, ...props }) => (
  <IconButton aria-label="Delete" mode="inverted" {...props}>
    <DeleteIcon viewBox="0 0 17 17" />
  </IconButton>
))`
  && {
    opacity: ${(props) => (props.display ? 1 : 0)};
    padding: 0.5rem;
    font-size: 1rem;
  }
`;
const Row = styled.div`
  margin: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: ${(props) => props.marginLeft || 0};
`;
const PaddedWrapper = styled(LoaderWrapper)`
  margin-right: 1.5rem;
`;
const PaddedCircularWrapper = styled(CircularLoaderWrapper)`
  margin-right: 1.5rem;
`;

export default function DraftsBuilder(props) {
  const { t } = useTranslation();
  const DEFAULT_FOLDER_GENERAL = getDefaultFolderGeneral();
  const nullUser = useNullUser();
  const [filteredDrafts, setFilteredDrafts] = useState([]);

  const hasSearchConsole = useSelector(selectHasSearchConsole);
  const [focusIndex, setFocusIndex] = React.useState(0);
  const DEFAULT_ROW = {
    dueDate: null,
    folderId: props.folderIdFromQuery || DEFAULT_FOLDER_GENERAL.id,
    statusId: props.statuses?.[0]?.id,
    url: '',
    userId: props.assignationReadOnly ? props.defaultUserId : nullUser.id,
  };

  const { data: folders, refetch: refecthFolders } = useGetCurrentWorkspaceAllFolders();

  const createNewFolder = usePostFolder({
    onSuccess: async () => {
      refecthFolders();
    },
  });

  const rows = [...props.rows];

  const handleChange =
    (index, changingKey) =>
    (newValue = '') => {
      let focusIndex = index;

      if (newValue.indexOf('\n') > 0) {
        let rowsList = newValue.split('\n');
        const newRowsList = rowsList
          .filter((term) => term !== '')
          .map((newVal, localIndex) => ({
            [changingKey]: newVal,
            dueDate: rows[index + localIndex]?.dueDate || DEFAULT_ROW.dueDate,
            folderId: rows[index + localIndex]?.folderId || DEFAULT_ROW.folderId,
            statusId: rows[index + localIndex]?.statusId || DEFAULT_ROW.statusId,
            userId: rows[index + localIndex]?.userId || DEFAULT_ROW.userId,
          }));
        rows.splice(index, rowsList.length, ...newRowsList);
        focusIndex = index + rowsList.length;
      } else {
        rows[index] = {
          [changingKey]: newValue,
          dueDate: rows[index]?.dueDate || DEFAULT_ROW.dueDate,
          folderId: rows[index]?.folderId || DEFAULT_ROW.folderId,
          statusId: rows[index]?.statusId || DEFAULT_ROW.statusId,
          userId: rows[index]?.userId || DEFAULT_ROW.userId,
        };
      }

      if (
        props.automaticallyAddRow &&
        rows[rows.length - 1][changingKey] !== '' &&
        !props.isLimitAchieved
      ) {
        rows.push(DEFAULT_ROW);
      }

      setFocusIndex(focusIndex);
      props.getList([...rows]);
    };

  const handleKeyDown = (index, changingKey) => (event) => {
    if (event.key === 'Backspace' && rows[index][changingKey] === '') {
      rows.splice(index, 1);
      if (props.rows.length === 0 || rows[rows.length - 1][changingKey] !== '') {
        rows.push(DEFAULT_ROW);
      }
      event.stopPropagation();
      event.preventDefault();
      setFocusIndex(Math.max(0, index - 1));
      props.getList([...rows]);
    }
    if (event.key === 'Enter') {
      event.stopPropagation();
      event.preventDefault();
      if (
        props.automaticallyAddRow &&
        rows[index][changingKey] !== '' &&
        rows[index + 1][changingKey] !== '' &&
        !props.isLimitAchieved
      ) {
        rows.splice(index + 1, 0, DEFAULT_ROW);
      }
      setFocusIndex(Math.min(rows.length - 1, index + 1));
      props.getList([...rows]);
    }
    if (event.key === 'ArrowUp') {
      event.stopPropagation();
      event.preventDefault();
      setFocusIndex(Math.max(0, index - 1));
    }
    if (event.key === 'ArrowDown') {
      event.stopPropagation();
      event.preventDefault();
      setFocusIndex(Math.min(rows.length - 1, index + 1));
    }
  };

  const handleUserChange = (index, id) => {
    rows[index].userId = id;

    props.getList([...rows]);
  };

  const handleStatusChange = (index, id) => {
    rows[index].statusId = id;

    props.getList([...rows]);
  };

  const handleDueDateChange = (index, chosenDate) => {
    rows[index].dueDate = chosenDate;

    props.getList([...rows]);
  };

  const handleFolderChange = (index, id) => {
    rows[index].folderId = id;

    props.getList([...rows]);
  };

  function handleNewFolderCreation(newFolder) {
    createNewFolder.mutate(newFolder);
  }

  const deleteRow = (rows, index) => {
    if (rows.length > 1) {
      const newRows = [...rows];
      newRows.splice(index, 1);
      setFocusIndex(Math.max(0, index - 1));
      props.getList(newRows);
    } else {
      props.getList([
        {
          dueDate: null,
          focusKeyword: '',
          folderId: DEFAULT_FOLDER_GENERAL.id,
          statusId: props.statuses[0]?.id,
          url: '',
          userId: null,
        },
      ]);
    }
  };

  function isNewDraftChecked(index) {
    return filteredDrafts.filter((draft) => draft.id === index).length > 0;
  }

  function handleCheckboxChange(row, index) {
    if (isNewDraftChecked(index)) {
      setFilteredDrafts(filteredDrafts.filter((draft) => draft.id !== index));
    } else {
      setFilteredDrafts([...filteredDrafts, { ...row, id: index }]);
    }
  }

  function handleAllDraftFiltered() {
    if (filteredDrafts.length === rows.length) {
      setFilteredDrafts([]);
    } else {
      setFilteredDrafts(rows.map((row, index) => ({ id: index, ...row })));
    }
  }

  return (
    <>
      <Wrapper key={`smart_row_text_list_${focusIndex}`}>
        {filteredDrafts.length > 0 && (
          <>
            <Row height="39px" marginLeft="10px">
              <HeaderBulkActions
                filteredDrafts={filteredDrafts}
                folders={folders}
                getList={props.getList}
                handleNewFolderCreation={handleNewFolderCreation}
                isFolderCreationDisabled={props.isFolderCreationDisabled}
                rows={rows}
                setFilteredDrafts={setFilteredDrafts}
                statuses={props.statuses}
                users={props.users}
              />
            </Row>
            <Separator />
          </>
        )}
        <Row>
          <Checkbox
            checked={filteredDrafts.length === rows.length}
            indeterminate={filteredDrafts.length > 0 && filteredDrafts.length < rows.length}
            name="all"
            onChange={handleAllDraftFiltered}
          />
          <StyledDarkerText medium pxSize={props.pxSize || 700} size="small">
            {t('components:input.drafts-builder.url')}
          </StyledDarkerText>
          <DarkerText medium size="small">
            {t('components:input.drafts-builder.assignation')}
          </DarkerText>
        </Row>
        <Separator />
        {rows.map((row, index) => (
          <Row key={`smart_row_text_list_${index}`}>
            {row.loading ? (
              <PaddedWrapper height={30} width={30}>
                <FlatLoader />
              </PaddedWrapper>
            ) : (
              <Checkbox
                checked={isNewDraftChecked(index)}
                name={index}
                onChange={() => handleCheckboxChange(row, index)}
              />
            )}

            {row.loading ? (
              <PaddedWrapper height={30} width={props.pxSize || 700}>
                <FlatLoader />
              </PaddedWrapper>
            ) : (
              <UrlSuggest
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={focusIndex === index}
                data-intercom-target={index === 0 ? 'existing_draft_url_suggest_input' : undefined}
                helperText={props.helperText(row.url)}
                inputValue={row.url}
                isError={props.isError(row.url)}
                noRender={!hasSearchConsole}
                options={props.pages}
                placeholder={props.placeholder}
                pxSize={props.pxSize}
                rowsMax="1"
                value={props.pages?.find((p) => p.url === row.url) || null}
                onChange={handleChange(index, 'url')}
                onKeyDown={handleKeyDown(index, 'url')}
              />
            )}
            <FlexContainer
              data-intercom-target={index === 0 ? 'existing_draft_assignation_actions' : undefined}
            >
              {row.loading || props.users.length === 0 ? (
                <PaddedCircularWrapper height={32} width={32}>
                  <FlatLoader />
                </PaddedCircularWrapper>
              ) : (
                <UserSelect
                  key={`user_select_${row.userId}`}
                  disabled={props.assignationReadOnly}
                  handleChange={(id) => handleUserChange(index, id)}
                  options={props.users}
                  shortVersion
                  small
                  value={row.userId}
                  variant="column"
                  width={300}
                />
              )}
              {row.loading ? (
                <PaddedCircularWrapper height={32} width={32}>
                  <FlatLoader />
                </PaddedCircularWrapper>
              ) : (
                <DatePicker
                  key={`date_picker_${row.dueDate}`}
                  dueDate={row.dueDate}
                  handleChange={(choseDate) => handleDueDateChange(index, choseDate)}
                  shortVersion
                  small
                />
              )}
              {row.loading || props.statuses.length === 0 ? (
                <PaddedWrapper height={30} width={110}>
                  <FlatLoader />
                </PaddedWrapper>
              ) : (
                <StatusSelect
                  key={`status_select_${row.statusId}`}
                  handleChange={({ id }) => handleStatusChange(index, id)}
                  options={props.statuses}
                  value={row.statusId}
                />
              )}
              {row.loading ? (
                <PaddedCircularWrapper height={32} width={32}>
                  <FlatLoader />
                </PaddedCircularWrapper>
              ) : (
                <FolderSelect
                  createNewFolder={handleNewFolderCreation}
                  folders={folders}
                  isFrame={false}
                  setValue={(id) => handleFolderChange(index, id)}
                  translations={{
                    button: t('components:select.folder-select.create-folder.label-button'),
                    cancel: t('components:select.folder-select.create-folder.cancel'),
                    create_folder: t(
                      'components:select.folder-select.folder-select.label-create-folder'
                    ),
                    folder_general: t('components:select.folder-select.label-folder-general'),
                    label_no_parent: t('components:filters.folder-filters-panel.label-no-parent'),
                    loading: t('components:select.folder-select.create-folder.label-loading'),
                    placeHolder: t(
                      'components:select.folder-select.create-folder.input-placeholder'
                    ),
                    search_placeholder: t(
                      'components:select.folder-select.folder-select.search-placeholder'
                    ),
                    title: t('components:select.folder-select.create-folder.title'),
                    tooltip_disabled: t(
                      'components:select.folder-select.create-folder.tooltip-disabled'
                    ),
                  }}
                  value={row.folderId}
                  width="280px"
                  withFolderCreation
                  withSearchInput
                />
              )}
            </FlexContainer>
            {row.loading ? (
              <PaddedWrapper height={30} width={30}>
                <FlatLoader />
              </PaddedWrapper>
            ) : (
              <DeleteButton
                display={true}
                onClick={() => {
                  deleteRow(rows, index);
                }}
              />
            )}
          </Row>
        ))}
      </Wrapper>
      <TextButton
        color="primary"
        padding="0 0.25rem"
        weight="medium"
        onClick={() => {
          if (!rows[0].loading) {
            props.getList([...rows, DEFAULT_ROW]);
          }
        }}
      >
        {t('components:input.drafts-builder.add-url')}
      </TextButton>
    </>
  );
}

export function HeaderBulkActions({
  rows,
  filteredDrafts,
  statuses,
  users,
  folders,
  getList,
  setFilteredDrafts,
  handleNewFolderCreation,
  isFolderCreationDisabled,
}) {
  const { t } = useTranslation();

  function handleDeleteRows() {
    const ids = filteredDrafts.map((draft) => draft.id);
    if (ids.length === rows.length) {
      // always keep one row
      getList([
        {
          dueDate: null,
          focusKeyword: '',
          folderId: DEFAULT_FOLDER_GENERAL.id,
          statusId: statuses[0]?.id,
          url: '',
          userId: null,
        },
      ]);
      setFilteredDrafts([]);
      return;
    }
    setFilteredDrafts(filteredDrafts.filter((draft) => !ids.includes(draft.id)));
    getList(rows.filter((row, index) => !ids.includes(index)));
  }

  function handleChangeFolderIdBulk(folderId) {
    const newRows = [...rows];
    filteredDrafts.forEach((draft) => {
      newRows[draft.id] = {
        ...newRows[draft.id],
        folderId: folderId,
      };
    });
    getList(newRows);
  }

  function handleStatusBulk(statusId) {
    const newRows = [...rows];
    filteredDrafts.forEach((draft) => {
      newRows[draft.id] = {
        ...newRows[draft.id],
        statusId: statusId,
      };
    });
    getList(newRows);
  }

  function handleChangeDueDateBulk(dueDate) {
    const newRows = [...rows];
    filteredDrafts.forEach((draft) => {
      newRows[draft.id] = {
        ...newRows[draft.id],
        dueDate: dueDate,
      };
    });
    getList(newRows);
  }

  function handleAssignedToBulk(userId) {
    const newRows = [...rows];
    filteredDrafts.forEach((draft) => {
      newRows[draft.id] = {
        ...newRows[draft.id],
        userId: userId,
      };
    });
    getList(newRows);
  }

  return (
    <>
      <DarkText>
        {t('listing:bulk-actions.pages-selected_interval', {
          count: filteredDrafts.length,
          postProcess: 'interval',
        })}
      </DarkText>
      <BulkActionButton />
      <VerticalSeparator />
      <BulkActionIcon key={'bulk-actions-assignation'} placement="top" title={'Assignation'}>
        <UserSelect
          centered
          displayEmpty
          handleChange={handleAssignedToBulk}
          options={users}
          renderValue={
            <BulkActionIcon title={t('listing:planning-list.bulk-actions.assignation')}>
              <User2Icon />
            </BulkActionIcon>
          }
          shortVersion
          small
          variant="column"
          width={300}
        />
      </BulkActionIcon>

      <BulkActionIcon placement="top" title={t('listing:planning-list.bulk-actions.due-date')}>
        <DatePickerBulk key="bulk-action-due-date" onChange={handleChangeDueDateBulk} />
      </BulkActionIcon>
      <StyledStatusSelect
        disabled={rows.length === 0}
        displayEmpty
        handleChange={handleStatusBulk}
        options={statuses}
        renderValue={
          <BulkActionIcon
            placement="top"
            title={
              rows.length === 0
                ? t('listing:planning-list.bulk-actions.status-disabled')
                : t('listing:planning-list.bulk-actions.status')
            }
          >
            <StatusBulk />
          </BulkActionIcon>
        }
        withLoading
      />

      <BulkActionIcon placement="top" title={t('listing:planning-list.bulk-actions.add-to-folder')}>
        <FolderSelect
          createNewFolder={handleNewFolderCreation}
          dropdownIconOnly
          dropDownSize={DROPDOWN_SIZES.FULL_WIDTH}
          folders={folders}
          isCreationDisabled={isFolderCreationDisabled}
          isFrame={false}
          setValue={handleChangeFolderIdBulk}
          translations={{
            button: t('components:select.folder-select.create-folder.label-button'),
            cancel: t('components:select.folder-select.create-folder.cancel'),
            create_folder: t('components:select.folder-select.folder-select.label-create-folder'),
            folder_general: t('components:select.folder-select.label-folder-general'),
            label_no_parent: t('components:filters.folder-filters-panel.label-no-parent'),
            loading: t('components:select.folder-select.create-folder.label-loading'),
            placeHolder: t('components:select.folder-select.create-folder.input-placeholder'),
            search_placeholder: t(
              'components:select.folder-select.folder-select.search-placeholder'
            ),
            title: t('components:select.folder-select.create-folder.title'),
            tooltip_disabled: t('components:select.folder-select.create-folder.tooltip-disabled'),
          }}
          value={DEFAULT_FOLDER_GENERAL.id}
          width="280px"
          withFolderCreation
          withSearchInput
        />
      </BulkActionIcon>

      <VerticalSeparator />

      <BulkActionIcon
        key={'bulk-actions-delete'}
        placement="top"
        title={t('listing:planning-list:bulk-actions.delete')}
      >
        <DeleteIcon size="small" onClick={handleDeleteRows} />
      </BulkActionIcon>
    </>
  );
}
