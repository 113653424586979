import { useMutation } from '@tanstack/react-query';

import apiClient from '@/apis/semji/apiClient';

export default function usePutGeneratedSearchIntentsReport(props) {
  const { put } = apiClient;

  return useMutation({
    mutationFn: ({ ids = [], report = {} }) =>
      Promise.all(
        ids.map(async (id) => put(`/generated_search_intents/${id}/report`, { ...report }))
      ),

    ...props,
  });
}
