import serializeDom from '@/apis/semji/utils/serializeDom';
import zeroWidthTrim from '@/apis/semji/utils/zeroWidthTrim';
import Store from '@/store/configureStore';
import { EDITOR_CLEAN_HTML_ENABLED } from '@/utils/configurations/constants';
import { Log } from '@/utils/log/Log';

function replaceRecursively(str, match, replacement) {
  if (str.indexOf(match) !== -1) {
    return replaceRecursively(str.replace(match, replacement), match, replacement);
  }

  return str;
}

function stripTagsAndAttributes(str) {
  if (str === undefined || str === null) {
    return str;
  }

  try {
    const newDocument = new DOMParser().parseFromString(str, 'text/html');

    // First pass to strip unwanted tags (depending on the order it get removed, we could miss some empty text tag afterward).
    newDocument
      .querySelectorAll('head, script, style, iframe, video, figure, img, picture')
      .forEach((el) => {
        el.remove();
      });

    newDocument.querySelectorAll('*').forEach((el) => {
      const tagName = el.tagName.toLowerCase();

      // Strip it if it's an empty text tag. (Except <p> because of break line in slate: https://gitlab.rvip.fr/semji/semji/-/issues/4773)
      if (
        ['span', 'strong', 'b', 'em', 's'].includes(tagName) &&
        zeroWidthTrim(el.textContent.trim()) === ''
      ) {
        el.remove();
        return;
      }

      // Remove attributes
      // TODO: specify an array of attributes to keep.
      const attrs = el.attributes;
      for (let i = attrs.length - 1; i >= 0; i--) {
        const attr = attrs[i];

        if (!['href', '_target'].includes(attr.name)) {
          el.removeAttribute(attr.name);
        }
      }
    });

    let newStr = (newDocument.body.innerHTML || serializeDom(newDocument.body))
      // One liner to remove comments, new lines, and some duplicated tags (TODO: to improve, add recursivity)
      .replace(/\<\!--\s*?[^\s?\[][\s\S]*?--\>/g, '')
      .replace(/\>\s*\</g, '><');

    newStr = replaceRecursively(newStr, '<div><div>', '<div>');
    newStr = replaceRecursively(newStr, '</div></div>', '</div>');
    newStr = replaceRecursively(newStr, '<div></div>', '');
    newStr = replaceRecursively(newStr, '<p><p>', '<p>');
    newStr = replaceRecursively(newStr, '</p></p>', '</p>');
    newStr = replaceRecursively(newStr, '  ', ' ');

    return newStr;
  } catch (e) {
    Log.error('stripTagsAndAttributes', e);
    return str;
  }
}

export default function stripTagsAndAttributesIfFeatureFlagEnabled(str) {
  const isFeatureEnabled = Boolean(
    Store.get?.().getState?.().configurations?.flags?.[EDITOR_CLEAN_HTML_ENABLED]
  );

  if (isFeatureEnabled) {
    return stripTagsAndAttributes(str);
  }

  return str;
}
