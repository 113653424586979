import { useQuery } from '@tanstack/react-query';

import apiClient from '@/apis/semji/apiClient';
import { SCOPE_COMPETITORS } from '@/apis/semji/constants';
import { QueryOptions } from '@/apis/types/query.types';
import { IFilterTransformed } from '@/hooks/useFilters/useFilters.types';
import { CompetitorReportMetric, CompetitorTopPagesFilters } from '@/types/competitors';

interface useGetCompetitorReport3YearMetricsProps
  // The hook will return an array of CompetitorReportMetrics for each year
  // current year is the first array, previous year is the second array and so on
  extends QueryOptions<{}, { [date: string]: CompetitorReportMetric[] }> {
  competitorReportId: string;
  compareDates: string[];
  filters: IFilterTransformed<CompetitorTopPagesFilters>;
}

export default function useGetCompetitorReport3YearMetrics({
  competitorReportId,
  compareDates,
  filters = {},
  ...props
}: useGetCompetitorReport3YearMetricsProps) {
  const { get } = apiClient;

  return useQuery({
    placeholderData: {},
    queryFn: async ({ signal }) => {
      const [date0Metrics, date1Metrics, date2Metrics] = await Promise.all([
        get(`/competitor_reports/${competitorReportId}/metrics`, {
          params: {
            ...filters,
            date: {
              equals: compareDates[0],
            },
          },
          signal,
        }),
        get(`/competitor_reports/${competitorReportId}/metrics`, {
          params: {
            ...filters,
            date: {
              equals: compareDates[1],
            },
          },
          signal,
        }),
        get(`/competitor_reports/${competitorReportId}/metrics`, {
          params: {
            ...filters,
            date: {
              equals: compareDates[2],
            },
          },
          signal,
        }),
      ]);

      return {
        [compareDates[0]]: date0Metrics.data['hydra:member'] || [],
        [compareDates[1]]: date1Metrics.data['hydra:member'] || [],
        [compareDates[2]]: date2Metrics.data['hydra:member'] || [],
      };
    },

    queryKey: [
      competitorReportId,
      SCOPE_COMPETITORS.GET_COMPETITOR_REPORT_3YEAR_METRICS,
      compareDates[0],
      compareDates[1],
      compareDates[2],
      filters,
    ],
    ...props,
  });
}
