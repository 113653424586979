import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import apiClient from '@/apis/semji/apiClient';
import { LOCATION, SCOPE_CONTENT_IDEAS } from '@/apis/semji/constants';
import { PENDING_STATUS } from '@/components/Pages/ContentIdeas/constant';
import { showErrorSnackbar } from '@/store/actions/ui';

export default function useGetCurrentWorkspaceContentIdeaSearches() {
  const { t } = useTranslation();
  const { get } = apiClient;
  const dispatch = useDispatch();
  const workspaceId = useSelector((state) => state.defaultWorkspaceId || '');

  return useQuery({
    onError: () => {
      dispatch(showErrorSnackbar(t(`content-ideas:menu.error.default`)));
    },
    placeholderData: Array(5).fill({ isSkeletonData: true }),

    queryFn: async ({ signal }) => {
      const result = await get(`/workspaces/${workspaceId}/content_idea_searches`, {
        signal,
      });

      return result.data['hydra:member'] || [];
    },
    queryKey: [SCOPE_CONTENT_IDEAS.CONTENT_IDEAS_SEARCHES, workspaceId, LOCATION.CONTENT_IDEAS],
    refetchInterval: (contentIdeaSearches) =>
      contentIdeaSearches &&
      contentIdeaSearches.some((contentIdeaSearch) => contentIdeaSearch?.status === PENDING_STATUS)
        ? 5000
        : false,
    refetchOnWindowFocus: 'always',
  });
}
