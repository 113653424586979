/* eslint-disable sort-keys-fix/sort-keys-fix */
import { useTranslation } from 'react-i18next';

import { BetaBadge } from '@/components/Badge/BetaBadge';
import BarChartIcon from '@/components/icons/BarChartIcon';
import CardIcon from '@/components/icons/CardIcon';
import CursorIcon from '@/components/icons/CursorIcon';
import DiagramIcon from '@/components/icons/DiagramIcon';
import EyeIcon from '@/components/icons/EyeIcon';
import FileIcon from '@/components/icons/FileIcon';
import FunnelIcon from '@/components/icons/FunnelIcon';
import MoneyIcon from '@/components/icons/MoneyIcon';
import PositionIcon from '@/components/icons/PositionIcon';
import ScreenIcon from '@/components/icons/ScreenIcon';
import ShoppingCartFiledIcon from '@/components/icons/ShoppingCartFiledIcon';
import StatsIcon from '@/components/icons/StatsIcon';
import TargetIcon from '@/components/icons/TargetIcon';
import TimeIcon from '@/components/icons/TimeIcon';
import { COMPETITORS_METRIC_TYPES } from '@/containers/Competitors/utils/constants';

export const METRIC_STACKING_TYPE_NORMAL = 'normal';
export const METRIC_STACKING_TYPE_PERCENT = 'percent';
export const METRIC_STACKING_TYPE_DISABLED = 'disabled';

export const METRIC_TYPE_EVOLUTION = 'evolution';
export const METRIC_TYPE_PERCENT = 'percent';
export const METRIC_TYPE_NUMBER = 'number';

export const METRIC_SPARKLINE_TYPE_SUM = 'sum';
export const METRIC_SPARKLINE_TYPE_AVG = 'avg';

export const METRIC_CHART_LINE = 'line';
export const METRIC_CHART_BAR = 'bar';
export const METRIC_STACKED_CHART_BAR = 'stacked-bar';

export const METRIC_POSITION_KEY = 'position';
export const METRIC_REVENUE_KEY = 'revenue';

export const PERFORMANCE_CATEGORY = 'performance';

export const ACQUISITION_CATEGORY = 'acquisition';
export const CONVERSION_CATEGORY = 'conversion';
export const E_COMMERCE_CATEGORY = 'e-commerce';
export const USER_BEHAVIOUR_CATEGORY = 'user-behaviour';

export const SEARCH_CONSOLE_DEPENDENCY = 'search-console';
export const ANALYTICS_DEPENDENCY = 'analytics';

export const TOP_100_TAB = 'top100';
export const FOCUS_KEYWORDS = 'focuskeywords';
export const FOCUS_TOP_KEYWORDS_METRICS = 'focusTopKeywordsMetrics';
export const RANKING_KEYWORDS_METRICS = 'rankingKeywordsMetrics';
export const FOCUS_TOP_KEYWORD_POSITION = 'focusTopKeywordPosition';
export const FOCUS_TOP_KEYWORDS_31_PLUS = 'focusTopKeywordsPositionTop31Plus';
export const NO_DEPENDENCY = null;

const BetaBadgeIcon = ({ label }) => {
  const { t } = useTranslation();

  return (
    <BetaBadge fontSize="10px" margin="0 8px 0 0" padding="2px 6px">
      {t(label)}
    </BetaBadge>
  );
};

export const reducers = {
  avg: (accumulator = 0, currentValue = 0, total, weight) =>
    accumulator + (currentValue * weight) / total,
  sum: (accumulator, currentValue = 0) => accumulator + currentValue,
};

export const METRICS_CONFIG = {
  publicationsCount: {
    key: 'publicationsCount',
    name: 'report:chart.metric-tabs.overview',
    reducer: reducers.sum,
    chartType: METRIC_CHART_BAR,
    type: METRIC_TYPE_NUMBER,
    evolutionRating: 'positive',
    category: PERFORMANCE_CATEGORY,
    icon: <BarChartIcon />,
    default: true,
    suffix: '',
    dependency: NO_DEPENDENCY,
    sparklineMovingType: METRIC_SPARKLINE_TYPE_SUM,
    allowDecimals: false,
  },
  clicks: {
    category: ACQUISITION_CATEGORY,
    chartType: METRIC_CHART_LINE,
    default: true,
    dependency: SEARCH_CONSOLE_DEPENDENCY,
    evolutionRating: 'positive',
    icon: <CursorIcon />,
    key: 'clicks',
    name: 'report:chart.metric-tabs.clicks',
    reducer: reducers.sum,
    suffix: '',
    type: METRIC_TYPE_NUMBER,
    sparklineMovingType: METRIC_SPARKLINE_TYPE_SUM,
    allowDecimals: false,
  },
  [METRIC_POSITION_KEY]: {
    key: METRIC_POSITION_KEY,
    name: 'report:chart.metric-tabs.positions',
    rankingKeywordsMetrics: [
      { key: 'rankingKeywordsTop3', legendLabel: 'chart:legend.label.top3' },
      { key: 'rankingKeywordsTop10', legendLabel: 'chart:legend.label.top10' },
      { key: 'rankingKeywordsTop100', legendLabel: 'chart:legend.label.top100' },
    ],
    [FOCUS_TOP_KEYWORDS_METRICS]: [
      { key: 'focusTopKeywordsPositionTop3', legendLabel: 'chart:legend.label.top3' },
      { key: 'focusTopKeywordsPositionTop10', legendLabel: 'chart:legend.label.top10' },
      { key: 'focusTopKeywordsPositionTop30', legendLabel: 'chart:legend.label.top30' },
      { key: 'focusTopKeywordsPositionTop31Plus', legendLabel: 'chart:legend.label.top31Plus' },
    ],
    reducer: reducers.avg,
    chartType: METRIC_CHART_LINE,
    type: METRIC_TYPE_NUMBER,
    evolutionRating: 'positive',
    category: ACQUISITION_CATEGORY,
    icon: <PositionIcon />,
    default: false,
    suffix: '',
    dependency: NO_DEPENDENCY,
    sparklineMovingType: METRIC_SPARKLINE_TYPE_AVG,
    badge: <BetaBadgeIcon label={'report:beta'} />,
    allowDecimals: false,
  },
  conversions: {
    category: CONVERSION_CATEGORY,
    chartType: METRIC_CHART_LINE,
    default: true,
    dependency: ANALYTICS_DEPENDENCY,
    evolutionRating: 'positive',
    icon: <FunnelIcon />,
    key: 'conversions',
    name: 'report:chart.metric-tabs.conversions',
    reducer: reducers.sum,
    suffix: '',
    type: METRIC_TYPE_NUMBER,
    sparklineMovingType: METRIC_SPARKLINE_TYPE_SUM,
    tooltip: {
      count: 2,
    },
    allowDecimals: false,
  },
  averageSessionDuration: {
    category: USER_BEHAVIOUR_CATEGORY,
    chartType: METRIC_CHART_LINE,
    default: false,
    dependency: ANALYTICS_DEPENDENCY,
    evolutionRating: 'positive',
    icon: <TimeIcon />,
    key: 'averageSessionDuration',
    name: 'report:chart.metric-tabs.averageSessionDuration',
    reducer: reducers.avg,
    suffix: 's',
    type: METRIC_TYPE_NUMBER,
    sparklineMovingType: METRIC_SPARKLINE_TYPE_AVG,
    tooltip: { context: 'female', count: 2 },
    allowDecimals: false,
  },
  sessions: {
    category: ACQUISITION_CATEGORY,
    chartType: METRIC_CHART_LINE,
    default: false,
    dependency: ANALYTICS_DEPENDENCY,
    evolutionRating: 'positive',
    icon: <EyeIcon />,
    key: 'sessions',
    name: 'report:chart.metric-tabs.sessions',
    reducer: reducers.sum,
    suffix: '',
    type: METRIC_TYPE_NUMBER,
    sparklineMovingType: METRIC_SPARKLINE_TYPE_SUM,
    tooltip: { context: 'female', count: 2 },
    allowDecimals: false,
  },
  conversionRate: {
    category: CONVERSION_CATEGORY,
    chartType: METRIC_CHART_LINE,
    default: false,
    dependency: ANALYTICS_DEPENDENCY,
    evolutionRating: 'positive',
    icon: <StatsIcon />,
    key: 'conversionRate',
    name: 'report:chart.metric-tabs.conversionRate',
    reducer: reducers.avg,
    suffix: '%',
    type: METRIC_TYPE_PERCENT,
    sparklineMovingType: METRIC_SPARKLINE_TYPE_AVG,
    allowDecimals: true,
  },
  bounceRate: {
    category: USER_BEHAVIOUR_CATEGORY,
    chartType: METRIC_CHART_LINE,
    default: false,
    dependency: ANALYTICS_DEPENDENCY,
    evolutionRating: 'negative',
    icon: <DiagramIcon />,
    key: 'bounceRate',
    name: 'report:chart.metric-tabs.bounceRate',
    reducer: reducers.avg,
    suffix: '%',
    type: METRIC_TYPE_PERCENT,
    sparklineMovingType: METRIC_SPARKLINE_TYPE_AVG,
    allowDecimals: true,
  },
  ctr: {
    category: ACQUISITION_CATEGORY,
    chartType: METRIC_CHART_LINE,
    default: false,
    dependency: SEARCH_CONSOLE_DEPENDENCY,
    evolutionRating: 'positive',
    icon: <TargetIcon />,
    key: 'ctr',
    name: 'report:chart.metric-tabs.ctr',
    reducer: reducers.avg,
    suffix: '%',
    type: METRIC_TYPE_PERCENT,
    sparklineMovingType: METRIC_SPARKLINE_TYPE_AVG,
    allowDecimals: true,
  },
  impressions: {
    category: ACQUISITION_CATEGORY,
    chartType: METRIC_CHART_LINE,
    default: false,
    dependency: SEARCH_CONSOLE_DEPENDENCY,
    evolutionRating: 'positive',
    icon: <ScreenIcon />,
    key: 'impressions',
    name: 'report:chart.metric-tabs.impressions',
    reducer: reducers.sum,
    suffix: '',
    type: METRIC_TYPE_NUMBER,
    sparklineMovingType: METRIC_SPARKLINE_TYPE_SUM,
    tooltip: { context: 'female', count: 2 },
    allowDecimals: false,
  },
  pagesPerSession: {
    category: USER_BEHAVIOUR_CATEGORY,
    chartType: METRIC_CHART_LINE,
    default: false,
    dependency: ANALYTICS_DEPENDENCY,
    evolutionRating: 'positive',
    icon: <FileIcon />,
    key: 'pagesPerSession',
    name: 'report:chart.metric-tabs.pagesPerSession',
    reducer: reducers.avg,
    suffix: '',
    type: METRIC_TYPE_NUMBER,
    sparklineMovingType: METRIC_SPARKLINE_TYPE_AVG,
    allowDecimals: true,
  },
  transactions: {
    category: E_COMMERCE_CATEGORY,
    chartType: METRIC_CHART_LINE,
    default: false,
    dependency: ANALYTICS_DEPENDENCY,
    evolutionRating: 'positive',
    icon: <CardIcon />,
    key: 'transactions',
    name: 'report:chart.metric-tabs.transactions',
    reducer: reducers.sum,
    suffix: '',
    type: METRIC_TYPE_NUMBER,
    sparklineMovingType: METRIC_SPARKLINE_TYPE_SUM,
    tooltip: { context: 'female', count: 2 },
    allowDecimals: false,
  },
  transactionRate: {
    category: E_COMMERCE_CATEGORY,
    chartType: METRIC_CHART_LINE,
    default: false,
    dependency: ANALYTICS_DEPENDENCY,
    evolutionRating: 'positive',
    icon: <ShoppingCartFiledIcon />,
    key: 'transactionRate',
    name: 'report:chart.metric-tabs.transactionRate',
    reducer: reducers.avg,
    suffix: '%',
    type: METRIC_TYPE_PERCENT,
    sparklineMovingType: METRIC_SPARKLINE_TYPE_AVG,
    allowDecimals: true,
  },
  [METRIC_REVENUE_KEY]: {
    category: E_COMMERCE_CATEGORY,
    chartType: METRIC_CHART_LINE,
    default: true,
    dependency: ANALYTICS_DEPENDENCY,
    evolutionRating: 'positive',
    icon: <MoneyIcon />,
    key: METRIC_REVENUE_KEY,
    name: 'report:chart.metric-tabs.revenue',
    reducer: reducers.sum,
    suffix: '€',
    type: METRIC_TYPE_NUMBER,
    sparklineMovingType: METRIC_SPARKLINE_TYPE_SUM,
    allowDecimals: false,
  },
};

export const GOAL_METRIC_TEMPLATE = {
  category: PERFORMANCE_CATEGORY,
  chartType: METRIC_CHART_LINE,
  evolutionRating: 'positive',
  isSubCategory: true,
  parentCategoryKey: METRICS_CONFIG.conversions.key,
  parentName: METRICS_CONFIG.conversions.name,
  reducer: reducers.sum,
  suffix: '',
  type: METRIC_TYPE_NUMBER, // All the goals are conversions goals
  sparklineMovingType: METRIC_SPARKLINE_TYPE_SUM,
  allowDecimals: false,
};
export const GOAL_RATE_METRIC_TEMPLATE = {
  category: PERFORMANCE_CATEGORY,
  chartType: METRIC_CHART_LINE,
  evolutionRating: 'positive',
  isSubCategory: true,
  parentCategoryKey: METRICS_CONFIG.conversionRate.key,
  parentName: METRICS_CONFIG.conversionRate.name,
  reducer: reducers.avg,
  suffix: '%',
  type: METRIC_TYPE_PERCENT, // All the goalsRate are conversionsRate goals
  sparklineMovingType: METRIC_SPARKLINE_TYPE_AVG,
  allowDecimals: true,
};

export const METRICS_CONFIG_PERIODICITY_DAILY = 'daily';
export const METRICS_CONFIG_PERIODICITY_WEEKLY = 'weekly';
export const METRICS_CONFIG_PERIODICITY_MONTHLY = 'monthly';
export const METRICS_CONFIG_PERIODICITY_QUARTERLY = 'quarterly';

export const METRICS_CONFIG_DAY = 'day';
export const METRICS_CONFIG_WEEK = 'week';
export const METRICS_CONFIG_MONTH = 'month';
export const METRICS_CONFIG_QUARTER = 'quarter';

export const METRICS_CONFIG_PERIOD_LAST_30_DAYS = 'last 30 days';
export const METRICS_CONFIG_PERIOD_LAST_90_DAYS = 'last 90 days';
export const METRICS_CONFIG_PERIOD_LAST_180_DAYS = 'last 180 days';
export const METRICS_CONFIG_PERIOD_LAST_365_DAYS = 'last 365 days';
export const METRICS_CONFIG_PERIOD_LAST_MONTH = 'last month';
export const METRICS_CONFIG_PERIOD_LAST_3_MONTHS = 'last 3 months';
export const METRICS_CONFIG_PERIOD_LAST_6_MONTHS = 'last 6 months';
export const METRICS_CONFIG_PERIOD_LAST_12_MONTHS = 'last 12 months';
export const METRICS_CONFIG_PERIOD_LAST_24_MONTHS = 'last 24 months';
export const METRICS_CONFIG_PERIOD_LAST_36_MONTHS = 'last 36 months';

export const PLOT_LINE_LOCK_TYPE = 'PLOT_LINE_LOCK_TYPE';
export const PLOT_LINE_PUBLICATION_TYPE = 'PLOT_LINE_PUBLICATION_TYPE';

export const periodicities = {
  [METRICS_CONFIG_PERIODICITY_DAILY]: {
    format: 'y-LL-dd',
    humanFormat: 'dd LLL yy',
    label: 'report:chart.periodicity.daily',
    luxonReference: METRICS_CONFIG_DAY, // 19 Feb, 21
    pitch: {
      days: 1,
    },
  },
  [METRICS_CONFIG_PERIODICITY_WEEKLY]: {
    format: 'kkkk-WW',
    humanFormat: "'Week' W, y",
    label: 'report:chart.periodicity.weekly',
    luxonReference: METRICS_CONFIG_WEEK, // Week 5, 2021
    pitch: {
      weeks: 1,
    },
  },
  [METRICS_CONFIG_PERIODICITY_MONTHLY]: {
    format: 'y-LL',
    humanFormat: 'LLL y',
    label: 'report:chart.periodicity.monthly',
    luxonReference: METRICS_CONFIG_MONTH, // Feb, 2021
    pitch: {
      months: 1,
    },
  },
  [METRICS_CONFIG_PERIODICITY_QUARTERLY]: {
    format: 'y-Qq',
    humanFormat: "'Q'q, y",
    label: 'report:chart.periodicity.quarterly',
    luxonReference: METRICS_CONFIG_QUARTER, // 2021, Q2
    pitch: {
      months: 3,
    },
  },
};

export const METRICS_CONFIG_COMPARISON_PERIOD_PREVIOUS_RANGE =
  'METRICS_CONFIG_COMPARISON_PERIOD_PREVIOUS_RANGE';
export const METRICS_CONFIG_COMPARISON_PERIOD_PREVIOUS_YEAR =
  'METRICS_CONFIG_COMPARISON_PERIOD_PREVIOUS_YEAR';

export const comparisonPeriods = {
  [METRICS_CONFIG_COMPARISON_PERIOD_PREVIOUS_RANGE]: {
    key: METRICS_CONFIG_COMPARISON_PERIOD_PREVIOUS_RANGE,
    label: 'report:chart.periodicity.previous-period',
  },
  [METRICS_CONFIG_COMPARISON_PERIOD_PREVIOUS_YEAR]: {
    key: METRICS_CONFIG_COMPARISON_PERIOD_PREVIOUS_YEAR,
    label: 'report:chart.periodicity.previous-year',
  },
};

export const COMPETITORS_METRIC_TYPE_CONTENT = 'content';
export const COMPETITORS_METRIC_TYPE_THEME = 'theme';
export const COMPETITORS_METRIC_TYPE_TRAFFIC = 'traffic';

export const COMPETITORS_METRICS_CONFIG = {
  [COMPETITORS_METRIC_TYPE_CONTENT]: {
    traffic: {
      category: ACQUISITION_CATEGORY,
      chartType: METRIC_CHART_LINE,
      default: true,
      dependency: SEARCH_CONSOLE_DEPENDENCY,
      evolutionRating: 'positive',
      icon: <CursorIcon />,
      key: COMPETITORS_METRIC_TYPES.traffic,
      name: 'competitors:duel.report.tabs.traffic',
      reducer: reducers.sum,
      suffix: '',
      type: METRIC_TYPE_NUMBER,
      sparklineMovingType: METRIC_SPARKLINE_TYPE_SUM,
    },

    organic: {
      category: ACQUISITION_CATEGORY,
      chartType: METRIC_CHART_LINE,
      default: true,
      dependency: ANALYTICS_DEPENDENCY,
      evolutionRating: 'positive',
      icon: <FileIcon />,
      key: COMPETITORS_METRIC_TYPES.page,
      name: 'competitors:duel.report.tabs.organic-page',
      reducer: reducers.sum,
      suffix: '',
      type: METRIC_TYPE_NUMBER,
      sparklineMovingType: METRIC_SPARKLINE_TYPE_SUM,
      tooltip: { context: 'female', count: 2 },
    },
  },
};

export const TOOLTIP_METRIC_POSITION_RIGHT = 'right';
export const TOOLTIP_METRIC_POSITION_LEFT = 'left';
export const TOOLTIP_METRIC_POSITION_CENTER = 'center';
