export const generateGoogleTagManagerScript = () => {
  const gtmId = import.meta.env.VITE_REACT_APP_GOOGLE_TAG_MANAGER_ID;

  if (!gtmId) {
    return null;
  }

  return `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${gtmId}');
`.trim();
};

export const pushAuthenticatedUserToGoogleAnalytics = (user) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'semji_login_success',
      semji_user_email: user.email,
      semji_user_first_name: user.firstName,
      semji_user_id: user.id,
      semji_user_is_admin: user.superAdmin,
      semji_user_last_name: user.lastName,
    });
  }
};
