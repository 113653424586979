import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getRoundedNumberMetricObject } from 'semji-core/utils/numbers';
import styled from 'styled-components/macro';

import SearchIcon from '@/components/icons/SearchIcon';
import { Instruction } from '@/components/Optimization/Instruction';
import { DarkText } from '@/components/Text/Dark';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import {
  disableRecommendationOptimization,
  enableRecommendationOptimization,
} from '@/store/actionsCreator/report';
import htmlDecode from '@/utils/htmlDecode';

export const IndicatorTextDark = styled(DarkText)`
  && {
    width: 30px;
    padding-left: 0.2em;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1em;
    white-space: nowrap;
  }
`;
const StyledTooltipComponent = styled(TooltipComponent)`
  font-size: 0.8em;
  display: flex;
`;

function SearchIntent({
  focusTopKeywordId,
  id,
  isReadOnly,
  mainId,
  onToggleSearchIntent = () => {},
  onAddSearchIntent = () => {},
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const html = useSelector((state) => state.content.html);
  const score = useSelector((state) => state.report.recommendations[id].score);
  const searchIntent = useSelector((state) => state.report.recommendations[id]);
  const title = useSelector((state) => state.content.title);
  const userLanguageCode = useSelector((state) => state.user?.languageCode);

  const { value, suffix } = getRoundedNumberMetricObject({
    locale: userLanguageCode,
    number: searchIntent.data.searchVolume,
  });
  const searchVolume = searchIntent.data.searchVolume > 0 ? value + suffix : '0-10';

  function toggleSearchIntent() {
    if (!searchIntent.disabled) {
      dispatch(disableRecommendationOptimization(id, mainId, focusTopKeywordId, html, title));
    } else {
      dispatch(enableRecommendationOptimization(id, mainId, focusTopKeywordId, html, title));
    }
    onToggleSearchIntent(id, !(searchIntent.disabled || isReadOnly));
  }

  function addSearchIntent() {
    onAddSearchIntent(htmlDecode(searchIntent.data.termToAdd));
  }

  return (
    <>
      <Instruction
        completed={1 === score}
        defaultLabelFontWeight="normal"
        disabled={searchIntent.disabled}
        isReadOnly={isReadOnly}
        label={searchIntent.data.label}
        scorer={
          <StyledTooltipComponent
            title={t(
              'content:side-panel-components.optimization.section.search-intents.instruction-tooltip-text'
            )}
          >
            <SearchIcon opacity={0.5} />
            <IndicatorTextDark>{searchVolume}</IndicatorTextDark>
          </StyledTooltipComponent>
        }
        title={searchIntent.data.term}
        onAdd={addSearchIntent}
        onToggle={toggleSearchIntent}
      />
    </>
  );
}

export default SearchIntent;
