import Divider from '@material-ui/core/Divider';
import Radio from '@material-ui/core/Radio';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import {
  DefaultPopover,
  FlexAlignedStartContainer,
  FlexCenteredAlignContainer,
  FlexCenteredContainer,
  FlexContainer,
} from '@/components/_common';
import { DiscreteButton, TertiaryButton } from '@/components/Button/Button';
import FilterInputRevealer from '@/components/Filters/FilterInputRevealer';
import CaretIcon from '@/components/icons/CaretIcon';
import CloseIcon from '@/components/icons/CloseIcon';
import { BOOL_BUTTON, KNOWN_BUTTON, UNKNOWN_BUTTON } from '@/utils/filter/constants';

const DeleteWrapper = styled.div`
  cursor: pointer;
  padding: 7px;
  padding-right: 10px;
  display: flex;
  color: ${(props) =>
    props.active ? props.theme.cssColors.primary : props.theme.text.colors.default};
  font-size: 0.8em;
`;

const DoneWrapper = styled(FlexCenteredContainer)`
  margin: 5px;
`;

const IconWrapper = styled.span`
  padding: 4px 6px;
  margin-top: 3px;
`;

const Label = styled.span`
  padding: 4px 0;
`;

const Value = styled.span`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const VerticalDivider = styled.div`
  background: ${(props) => props.theme.cssColors.primary020};
  height: 17px;
  max-height: 80%;
  width: 1px;
`;

const PaddedFlexContainer = styled(FlexContainer)`
  margin: 5px 15px 5px 5px;
`;

const RadioLabelWrapper = styled.div`
  padding-top: 15px;
`;

const FlexCenteredAlignContainerEllipsis = styled(FlexCenteredAlignContainer)`
  max-width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

// Comparison Selector Menu
function ComparisonSelectorMenu(props) {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  // needed to open menu automatically
  const MenuRef = useCallback((node) => {
    if ((node !== null && props.error) || props.open) {
      setAnchorEl(node);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    if (!props.buttonValue) {
      props.deleteGroup();
    }
    setAnchorEl(null);
  }

  function handleSelect(item) {
    props.onChangeComparison(item);
  }

  function handleDone() {
    props.onApply();
    handleClose();
  }

  return (
    <div>
      <FlexCenteredAlignContainerEllipsis>
        <DiscreteButton
          ref={MenuRef}
          active={props.active}
          aria-controls="comparison-selector-menu"
          aria-haspopup="true"
          error={props.error}
          onClick={handleClick}
        >
          <IconWrapper active={props.buttonLabel}>{props.icon}</IconWrapper>
          <Label>
            {props.buttonLabel} {props.buttonValue && ' :'}
          </Label>
          &nbsp;
          <Value>{props.buttonValue}</Value>
        </DiscreteButton>
        {props.active ? <VerticalDivider /> : null}
        <DeleteWrapper
          active={props.active}
          onClick={props.buttonValue ? props.deleteGroup : handleClick}
        >
          {props.active ? <CloseIcon /> : <CaretIcon />}
        </DeleteWrapper>
      </FlexCenteredAlignContainerEllipsis>
      <DefaultPopover
        anchorEl={anchorEl}
        id="comparison-selector-menu"
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {props.menuItems.map((item) => (
          <PaddedFlexContainer key={item.label}>
            <FlexAlignedStartContainer>
              <Radio
                checked={
                  props.checkedValue.comparison === item.comparison &&
                  t(props.checkedValue.labelKey) === item.label
                }
                onClick={() => {
                  // for know values we compare to true and all not falsy values returns true
                  if (item.type === KNOWN_BUTTON) {
                    props.onChangeValue(true);
                    // for unknown values we compare to null
                  } else if (item.type === UNKNOWN_BUTTON) {
                    props.onChangeValue(null);
                    // when the previous value is known, the value is true, this condition reset the value to empty string so the input won't have "true" as a value
                  } else if (props.value.value === true) {
                    props.onChangeValue('');
                  }
                  handleSelect(item);
                }}
              />
            </FlexAlignedStartContainer>
            <RadioLabelWrapper>
              {/* for now the placeholder is only used for BOOL filters */}
              <span>
                {item.type === BOOL_BUTTON ? `${item.label} ${props.placeholder}` : item.label}
              </span>
              {props.checkedValue.comparison === item.comparison &&
                t(props.checkedValue.labelKey) === item.label && (
                  <FilterInputRevealer
                    checkedValue={props.checkedValue}
                    createNewFolders={props.createNewFolders}
                    folders={props.folders}
                    handleDone={handleDone}
                    isCreationDisabled={props.isCreationDisabled}
                    menuItems={props.menuItems}
                    selectList={props.selectList?.map((el) => ({
                      ...el,
                      label: t(el.labelKey),
                    }))}
                    value={props.value}
                    onChangeValue={props.onChangeValue}
                  />
                )}
            </RadioLabelWrapper>
          </PaddedFlexContainer>
        ))}
        <Divider />
        <DoneWrapper>
          <TertiaryButton
            disabled={props.error}
            inverted
            onClick={() => !props.error && handleDone()}
          >
            {t('components:filters.comparison-selector-menu.done')}
          </TertiaryButton>
        </DoneWrapper>
      </DefaultPopover>
    </div>
  );
}

export default ComparisonSelectorMenu;
