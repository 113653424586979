import { nanoid } from 'nanoid';
import { useMemo } from 'react';

export default function Link2Icon({ className }) {
  const maskId = useMemo(() => nanoid(), []);
  const clipId = useMemo(() => nanoid(), []);

  return (
    <svg
      className={className}
      fill="currentColor"
      height="17"
      viewBox="0 0 17 17"
      width="17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#${clipId})`}>
        <mask
          height="18"
          id={maskId}
          maskUnits="userSpaceOnUse"
          style={{ maskType: 'alpha' }}
          width="14"
          x="1"
          y="0"
        >
          <path
            d="M12.983 6.94001L12.045 7.87801L11.338 7.17101L12.276 6.23301C13.251 5.25801 13.251 3.67201 12.276 2.69801C11.301 1.72401 9.715 1.72301 8.741 2.69801L5.754 5.68601C4.779 6.66101 4.779 8.24701 5.754 9.22101C6.729 10.195 8.315 10.196 9.289 9.22101L9.996 9.92801C9.313 10.611 8.418 10.951 7.521 10.951C6.624 10.951 5.729 10.61 5.047 9.92801C3.683 8.56401 3.683 6.34301 5.047 4.97901L8.034 1.99201C9.399 0.627012 11.618 0.627012 12.983 1.99201C14.348 3.35501 14.348 5.57601 12.983 6.94001ZM6.042 8.03401L5.912 8.16301L6.617 8.87201L6.748 8.74201C7.723 7.76701 9.309 7.76701 10.283 8.74201C11.257 9.71701 11.258 11.303 10.283 12.277L7.26 15.302C6.285 16.277 4.699 16.277 3.725 15.302C2.751 14.327 2.75 12.741 3.725 11.767L4.783 10.708L4.076 10.001L3.018 11.06C1.654 12.424 1.654 14.645 3.018 16.009C3.701 16.692 4.596 17.032 5.493 17.032C6.39 17.032 7.285 16.691 7.968 16.009L10.991 12.985C12.355 11.621 12.355 9.40001 10.991 8.03601C9.625 6.66901 7.405 6.67201 6.042 8.03401Z"
            fill="black"
          />
        </mask>
        <g mask={`url(#${maskId})`}>
          <rect height="20" width="20" x="-2" y="-1" />
        </g>
      </g>
      <defs>
        <clipPath id={clipId}>
          <rect fill="white" height="17" width="17" />
        </clipPath>
      </defs>
    </svg>
  );
}
