import './AddWorkspace.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { PlusIcon } from 'semji-core/icons/PlusIcon';

import Can from '@/hoc/Can';

interface AddWorkspaceProps extends React.HTMLProps<HTMLDivElement> {
  url: string;
}

export default function AddWorkspace({ url, className, ...props }: AddWorkspaceProps) {
  const { t } = useTranslation();

  return (
    <Can
      perform="organization-admin-settings:visit"
      yes={() => (
        <div className={`add-workspace-button ${className}`} {...props}>
          <NavLink className="add-workspace-button__link" to={url}>
            <PlusIcon className="add-workspace-button__link__icon" />
            <span>{t('layout:profile.add-workspace')}</span>
          </NavLink>
        </div>
      )}
    />
  );
}
