import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getRoundedNumberMetricWithSuffix } from 'semji-core/utils/numbers';

import UrlClassification from '@/components/Pages/ContentIdeas/Listing/CellRender/Title/UrlClassification';
import UrlWordsCount from '@/components/Pages/ContentIdeas/Listing/CellRender/Title/UrlWordsCount';
import { FlexTooltipComponent } from '@/components/Tooltip/Tooltip';
import {
  CursorIconDetailed,
  FavIconDetailed,
  HashIconDetailed,
  TextDetailed,
} from '@/containers/ContentIdeas/Listing/MindMap/Nodes/Keyword/KeywordNode.styled';
import Flex from '@/design-system/components/Flex';
import { formatUrlForDisplay } from '@/utils/url';

function KeywordFooter({ data }) {
  const { t } = useTranslation();
  const userLanguageCode = useSelector((state) => state.user.languageCode);

  return (
    <Flex alignItems="center" gap="12px">
      <Flex gap="5px">
        <FavIconDetailed url={data.url} />
        <TextDetailed maxWidth="150px">{formatUrlForDisplay(data.url).split('/')[0]}</TextDetailed>
      </Flex>
      <UrlClassification urlCategory={data.urlCategory} urlClassifiedAt={data.urlClassifiedAt} />
      <UrlWordsCount
        tooltipOptions={{ placement: 'bottom' }}
        urlWordsCount={data.urlWordsCount}
        urlWordsCountRetrievedAt={data.urlWordsCountRetrievedAt}
      />
      <FlexTooltipComponent cursor="help" title={t('content-ideas:mind-map.estimated-traffic')}>
        <Flex>
          <CursorIconDetailed />
          <TextDetailed>
            {getRoundedNumberMetricWithSuffix({
              locale: userLanguageCode,
              number: data.urlEstimatedTraffic,
            })}
          </TextDetailed>
        </Flex>
      </FlexTooltipComponent>
      <FlexTooltipComponent cursor="help" title={t('content-ideas:mind-map.my-position')}>
        <Flex>
          <HashIconDetailed />
          <TextDetailed>{data.rankedWorkspaceUrlPosition || '-'}</TextDetailed>
        </Flex>
      </FlexTooltipComponent>
    </Flex>
  );
}

export default KeywordFooter;
