import './AxisChartLabel.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { DEFAULT_FAVICON } from '@/utils/constants';
import { getFaviconFromUrl } from '@/utils/favicon';

export enum ENUM_AXIS_CHART_LABEL_ORIENTATION {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

type AxisChartLabelProps = {
  domain: string;
  orientation?: ENUM_AXIS_CHART_LABEL_ORIENTATION;
  isReference?: boolean;
  exportImage: boolean;
  id: string;
  url: string;
  urlType: string;
};

export function AxisChartLabel({
  exportImage,
  domain,
  orientation = ENUM_AXIS_CHART_LABEL_ORIENTATION.VERTICAL,
  isReference = false,
  id,
  url,
  urlType,
}: AxisChartLabelProps) {
  const apiRootUrl = import.meta.env.VITE_REACT_APP_SEMJI_API_ROOT_URL;
  const { t } = useTranslation();

  return (
    <div
      className={`axis-chart-label axis-chart-label__${orientation} ${isReference && 'axis-chart-label--reference'}`}
    >
      <div className="axis-chart-label__anchor">
        <div className={`axis-chart-label__icon`}>
          <img
            alt=""
            id={id}
            src={getFaviconFromUrl(domain, apiRootUrl)}
            style={{
              backgroundImage: `url(${DEFAULT_FAVICON})`,
            }}
          />
        </div>
        <span className="axis-chart-label__label">{domain}</span>
      </div>
      {exportImage === false && (
        <div className="axis-chart-label__tooltip">
          <div className="axis-chart-label__tooltip--title">{url}</div>
          <div className="axis-chart-label__tooltip--description">
            {t(`competitors:duel.report.axis-chart-label.${urlType?.toLowerCase()}`)}
          </div>
        </div>
      )}
    </div>
  );
}
