import { nanoid } from 'nanoid';
import { useMemo } from 'react';

import SvgWrapper from './SvgWrapper';

const DoubleFolder = (props) => {
  const maskId = useMemo(() => nanoid(), []);

  return (
    <SvgWrapper fill="none" height="17" viewBox="0 0 17 17" width="17" {...props}>
      <mask
        height="15"
        id={maskId}
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="17"
        x="0"
        y="1"
      >
        <path
          d="M13 1H0V12H13V1ZM12 11H1V2H12V11ZM17 5V16H4V12.969H5V15H16V6H13.984V5H17Z"
          fill="black"
        />
      </mask>
      <g mask={`url(#${maskId})`}>
        <rect height="20" width="20" x="-2" y="-1" />
      </g>
    </SvgWrapper>
  );
};
export default DoubleFolder;
