import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';

import { FlexGrowColumnCenteredContainer } from '@/components/_common';
import { BodyMessage } from '@/components/Dialog/DialogInformationTemplate';
import PrimaryButton from '@/design-system/components/Button/PrimaryButton';
import { showUpgradePlanModal } from '@/store/actions/billing';

const PrimaryBtn = styled(PrimaryButton)`
  && {
    min-width: 200px;
  }
`;

function SidePanelErrorMessage({ title, imagePath, imageAlt, informationText, upgradePlanButton }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  function showModal() {
    dispatch(showUpgradePlanModal());
  }

  return (
    <FlexGrowColumnCenteredContainer>
      <BodyMessage imageAlt={imageAlt} imagePath={imagePath} title={title}>
        {informationText}
      </BodyMessage>
      {upgradePlanButton && (
        <PrimaryBtn onClick={showModal}>
          {t('content:side-panel-components.error-message-text')}
        </PrimaryBtn>
      )}
    </FlexGrowColumnCenteredContainer>
  );
}

SidePanelErrorMessage.propTypes = {
  imageAlt: PropTypes.string.isRequired,
  imagePath: PropTypes.string.isRequired,
  informationText: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  upgradePlanButton: PropTypes.bool,
};

export default SidePanelErrorMessage;
