import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import { LOCATION } from '@/apis/semji/constants';
import useGetCurrentWorkspaceAllFolders from '@/apis/semji/folders/useGetCurrentWorkspaceAllFolders';
import useGetCurrentWorkspacePagesWithSpecificProperties from '@/apis/semji/pages/useGetCurrentWorkspacePagesWithSpecificProperties';
import DraftsBuilder from '@/components/Input/DraftsBuilder';
import { getDefaultFolderGeneral } from '@/components/Select/FolderSelect/constants';
import { Info } from '@/components/Text/Info';
import useIsUserLimitedGuestWriter from '@/hooks/useIsUserLimitedGuestWriter';
import useNullUser from '@/hooks/useNullUser';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import useWorkspaceStatuses from '@/hooks/useWorkspaceStatuses';
import useWorkspaceUsers from '@/hooks/useWorkspaceUsers';
import { getOrganizationById } from '@/store/reducers';
import { URL_LIMIT_CREATION } from '@/utils/constants';
import { isUrlAlreadyInDraftList, isUrlValidWithWebSiteUrl, isValidPageUrl } from '@/utils/url';

const DEFAULT_TAB = 'open';

const Wrapper = styled.div`
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  background: white;
  padding: 3em;
  margin: 1em 0;
  max-height: 400px;
  overflow: auto;
`;
const InfoWrapper = styled.div`
  padding: 5px;
`;
const URLS_DRAFTS_LIST_LOADING_KEY = 'URLS_DRAFTS_LIST_LOADING_KEY';
const URLS_DRAFTS_LIST_LOADED_KEY = 'URLS_DRAFTS_LIST_LOADED_KEY';

export default function UpdatedContentsIdentification({
  currentWorkspaceWebsiteUrl,
  existingDrafts,
  isLimitAchieved,
  automaticallyAddRow,
  setDefaultStatusId,
  setExistingDrafts,
}) {
  const { t } = useTranslation();
  const DEFAULT_FOLDER_GENERAL = getDefaultFolderGeneral();
  const nullUser = useNullUser();
  const [query] = useSearchParams();

  const defaultUserId = useSelector((state) => state.user.id);
  const users = useWorkspaceUsers();
  const statuses = useWorkspaceStatuses();
  const { data: folders, remove: removeFolderCache } = useGetCurrentWorkspaceAllFolders();
  const isUserLimitedGuestWriter = useIsUserLimitedGuestWriter();

  const { organizationId } = useParams();

  const currentOrganization = useSelector((state) => getOrganizationById(state, organizationId));
  const { isFeatureEnabled: unlimitedFoldersFeatureSet } = useOrganizationFeatureSet(
    'organization:folders:has-unlimited-amount'
  );
  const { data: foldersLimit = 0 } = useOrganizationFeatureSet('organization:folders:amount');
  const isOrganizationFoldersLimit =
    currentOrganization.foldersCount >= foldersLimit && !unlimitedFoldersFeatureSet;

  const pagesWithSpecificProperties = useGetCurrentWorkspacePagesWithSpecificProperties({
    filters: { 'url[contains]': '' },
    location: LOCATION.IDENTIFICATION,
    properties: ['id', 'url', 'draft', 'extra', 'new', 'inStock'],
  });
  const pages = pagesWithSpecificProperties.data.filter((p) =>
    isUrlValidWithWebSiteUrl(p.url, currentWorkspaceWebsiteUrl)
  );

  const tab = query.get('tab') || DEFAULT_TAB;
  const isInFolderTab = tab.startsWith('folder-');
  const folderIdFromQuery = isInFolderTab && tab.split('-')[1];

  // Clean up react-query cache.
  useEffect(() => {
    return () => {
      pagesWithSpecificProperties.remove();
      removeFolderCache();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (statuses.length > 0) {
      const defaultDraft = {
        dueDate: null,
        folderId: folderIdFromQuery || DEFAULT_FOLDER_GENERAL.id,
        statusId: statuses[0]?.id,
        url: '',
        // Auto assign current user to drafts if the user is a LIMITED_GUEST_WRITER
        userId: isUserLimitedGuestWriter ? defaultUserId : nullUser.id,
      };

      if (existingDrafts[0].loading) {
        setExistingDrafts([defaultDraft]);
      } else {
        setExistingDrafts([...existingDrafts.fill(defaultDraft, -1)]);
      }

      setDefaultStatusId(statuses[0]?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statuses, folderIdFromQuery]);

  function isError(input) {
    return (
      (!isValidPageUrl(input).value ||
        !isUrlValidWithWebSiteUrl(input, currentWorkspaceWebsiteUrl) ||
        pages?.find((p) => p.url === input)?.draft ||
        isUrlAlreadyInDraftList(input, existingDrafts)) &&
      !!input
    );
  }

  function helperText(input) {
    if (!input) {
      return '';
    }
    if (pages?.find((p) => p.url === input)?.draft) {
      return t('drafts:updated-contents.identification.error-already-in-planning');
    }
    const { value, error } = isValidPageUrl(input);
    if (!value) {
      return error;
    }
    if (!isUrlValidWithWebSiteUrl(input, currentWorkspaceWebsiteUrl)) {
      return t('drafts:updated-contents.identification.error-url-not-from-workspace', {
        currentWorkspaceWebsiteUrl,
      });
    }
    if (isUrlAlreadyInDraftList(input, existingDrafts)) {
      return t('drafts:updated-contents.identification.error-url-already-in-list');
    }
    return '';
  }

  function sortFunctionPages(a, b) {
    if (!a.inStock && !!a.draft && (b.inStock || !b.draft)) return 1;
    return -1;
  }

  return (
    <>
      <Wrapper id="identification-wrapper">
        <DraftsBuilder
          key={
            existingDrafts[0]?.loading ? URLS_DRAFTS_LIST_LOADING_KEY : URLS_DRAFTS_LIST_LOADED_KEY
          }
          assignationReadOnly={isUserLimitedGuestWriter}
          automaticallyAddRow={automaticallyAddRow}
          defaultUserId={defaultUserId}
          folderIdFromQuery={folderIdFromQuery}
          folders={folders}
          getList={setExistingDrafts}
          helperText={helperText}
          isError={isError}
          isFolderCreationDisabled={isOrganizationFoldersLimit}
          isLimitAchieved={isLimitAchieved}
          pages={pages?.sort(sortFunctionPages)}
          placeholder={t('drafts:updated-contents.identification.add-url')}
          pxSize={600}
          rows={existingDrafts}
          statuses={statuses.filter((status) => !status.publish)}
          users={users}
        />
      </Wrapper>
      <InfoWrapper>
        {isLimitAchieved && (
          <Info>
            {t('drafts:updated-contents.identification.limit-reached', {
              limit: URL_LIMIT_CREATION,
            })}
          </Info>
        )}
      </InfoWrapper>
    </>
  );
}
