import { aggregateMetrics } from '@/utils/metrics/aggregateMetrics';
import {
  METRIC_SPARKLINE_TYPE_AVG,
  METRICS_CONFIG_PERIODICITY_MONTHLY,
  METRICS_CONFIG_PERIODICITY_QUARTERLY,
  METRICS_CONFIG_PERIODICITY_WEEKLY,
} from '@/utils/metrics/constants';

export const WINDOW_OVER_LAST_7_DAYS = 7;
export const WINDOW_OVER_LAST_30_DAYS = 30;
export const WINDOW_OVER_LAST_90_DAYS = 90;
const MAX_POINTS_REFERENCE = 12;

export function simpleMovingAverage({
  data,
  movingAverageWindow,
  movingAverageStep,
  metricsConfig,
  periodicity,
  excludedMetricsConfig,
}) {
  const result = [];
  let currentIndex = data.length - 1;

  while (currentIndex + 1 - movingAverageWindow >= 0) {
    const windowData = data.slice(currentIndex + 1 - movingAverageWindow, currentIndex + 1);
    // we sum the excluded keys (like publications) and we do not need duplicated data that's why
    // we are applying the sum only on the stepData and not on all the window data
    const stepData = data.slice(currentIndex, currentIndex + movingAverageStep);
    const aggregatedMetrics = aggregateMetrics(windowData, metricsConfig);
    const aggregatedExcludedMetrics = aggregateMetrics(stepData, excludedMetricsConfig);

    Object.keys(aggregatedMetrics).forEach((key) => {
      aggregatedMetrics[key] = getAverageByPeriodicity({
        dailyTotal: aggregatedMetrics[key],
        daysCount: windowData.length,
        periodicity,
        type: metricsConfig[key]?.sparklineMovingType,
      });
    });

    result.unshift({
      ...aggregatedMetrics,
      ...aggregatedExcludedMetrics,
    });

    currentIndex = currentIndex - movingAverageStep;
  }

  return result;
}

export function getSimpleMovingAverageWindow(periodicity) {
  if (METRICS_CONFIG_PERIODICITY_QUARTERLY === periodicity) {
    return WINDOW_OVER_LAST_90_DAYS;
  }

  if (METRICS_CONFIG_PERIODICITY_MONTHLY === periodicity) {
    return WINDOW_OVER_LAST_30_DAYS;
  }

  return WINDOW_OVER_LAST_7_DAYS;
}

export function getSimpleMovingAverageStep(sampleRangeSize) {
  return Math.max(Math.floor(sampleRangeSize / MAX_POINTS_REFERENCE), 2);
}

function getAverageByPeriodicity({ dailyTotal, daysCount, periodicity, type }) {
  if (typeof dailyTotal !== 'number') {
    return dailyTotal;
  }

  if (0 === daysCount || typeof daysCount !== 'number') {
    return 0;
  }

  if (type === METRIC_SPARKLINE_TYPE_AVG) {
    return dailyTotal;
  }

  if (periodicity === METRICS_CONFIG_PERIODICITY_WEEKLY) {
    return (dailyTotal / daysCount) * 7;
  }

  if (periodicity === METRICS_CONFIG_PERIODICITY_MONTHLY) {
    return (dailyTotal / daysCount) * 30;
  }

  if (periodicity === METRICS_CONFIG_PERIODICITY_QUARTERLY) {
    return (dailyTotal / daysCount) * 90;
  }

  return dailyTotal / daysCount;
}
