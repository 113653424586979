import { useTranslation } from 'react-i18next';
import { DialogModal } from 'semji-core/components/DialogModal';
import { ENUM_LINK_SIZE_VARIANT, ENUM_LINK_VARIANT, Link } from 'semji-core/components/Link';

export default function EditorSynchronizeDialog({ isLoading, url, onConfirm, onCancel, isOpen }) {
  const { t } = useTranslation();

  return (
    <DialogModal
      cancelAction={onCancel}
      cancelLabel={t('common:labels.cancel')}
      canClickOutsideToClose
      closable
      confirmAction={onConfirm}
      confirmLabel={
        isLoading
          ? t('content:editor-components.task-bar.synchronize-dialog.loading-label')
          : t('content:editor-components.task-bar.synchronize-dialog.confirm-label')
      }
      isCancelDisabled={false}
      isConfirmDisabled={isLoading}
      isOpen={isOpen}
      title={t('content:editor-components.task-bar.synchronize-dialog.title')}
      onClose={onCancel}
    >
      <p>{t('content:editor-components.task-bar.synchronize-dialog.body')}</p>
      <br />
      <Link
        displayIcon
        href={url}
        size={ENUM_LINK_SIZE_VARIANT.Small}
        variant={ENUM_LINK_VARIANT.Default}
      />
    </DialogModal>
  );
}
