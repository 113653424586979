import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import WorkspaceLimitReached from '@/components/EmptyState/WorkspaceLimitReached';
import { DEFAULT_BACKGROUND, TRANSPARENT_LOGO_AND_PROFILE_MENU } from '@/components/Layout/Layout';
import LayoutWrapper from '@/containers/Layout/Layout';

function MaxAllowedWorkspacesReached() {
  const { t } = useTranslation();

  return (
    <LayoutWrapper menu={TRANSPARENT_LOGO_AND_PROFILE_MENU} variant={DEFAULT_BACKGROUND}>
      <Helmet>
        <title>{t('errors:max-allowed-workspaces-reached.title')}</title>
      </Helmet>
      <div className="flex flex-1 justify-center items-center z-[1]">
        <WorkspaceLimitReached />
      </div>
    </LayoutWrapper>
  );
}

export default MaxAllowedWorkspacesReached;
