import React from 'react';
import { Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import { PrimaryLink } from '@/components/Navigation/Link';
import { ParagraphText } from '@/components/Text/Paragraph';
import { TitleOne } from '@/components/Text/Title';
import UpgradePlan from '@/containers/Billing/UpgradePlan';
import LayoutWrapper from '@/containers/Layout/Layout';
import { SUPPORT_ADDRESS_MAIL } from '@/utils/constants';

const Wrapper = styled.div`
  padding: 4rem 0;
`;
const StyledParagraphText = styled(ParagraphText)`
  padding: 2rem 0;
`;

function WorkspaceAccessBlocked() {
  const { organizationId } = useParams();

  return (
    <LayoutWrapper>
      <Wrapper>
        <Trans
          components={{
            br: <br />,
            pLink: (
              <PrimaryLink
                isExternal
                noDecoration
                target="_blank"
                to={`mailto:${SUPPORT_ADDRESS_MAIL}`}
              />
            ),
            paragraph: <StyledParagraphText align="center" size="md" />,
            title: <TitleOne align="center" weight="strong" />,
          }}
          i18nKey={'errors:workspace-access-blocked.content'}
        />
        <UpgradePlan organizationId={organizationId} />
      </Wrapper>
    </LayoutWrapper>
  );
}

export default WorkspaceAccessBlocked;
