import { nanoid } from 'nanoid';
import React, { useMemo } from 'react';

import SvgWrapper from './SvgWrapper';

const SidePanelToggleIcon = (props) => {
  const maskId = useMemo(() => nanoid(), []);
  return (
    <SvgWrapper
      fill="none"
      height="13"
      viewBox="0 0 14 13"
      width="14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        height="11"
        id={`mask${maskId}`}
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="11"
        x="1"
        y="1"
      >
        <path
          d="M7.94204 6.49998L2.29757 11.3381L1.71533 10.839L6.77674 6.49998L1.71533 2.16163L2.29757 1.66187L7.94204 6.49998ZM6.28674 1.66187L5.70451 2.16163L10.7659 6.49998L5.70451 10.8383L6.28674 11.3374L11.9304 6.49998L6.28674 1.66187Z"
          fill="black"
        />
      </mask>
      <g mask={`url(#mask${maskId})`}>
        <rect fill="#A8A9AF" height="14.1176" width="16.4706" x="-1.64697" y="-0.205933" />
      </g>
    </SvgWrapper>
  );
};

export default SidePanelToggleIcon;
