import styled from 'styled-components/macro';

const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  box-sizing: border-box;
  max-width: 900px;
  width: 90%;
  height: 100%;
  margin: 0 auto;
  padding: 0 20px;
`;

export default ContentSection;
