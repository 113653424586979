import { useMutation } from '@tanstack/react-query';

import apiClient from '@/apis/semji/apiClient';

export default function usePutWorkspaceBlacklistedTerms({ workspaceId, ...rest }) {
  const { put } = apiClient;

  return useMutation({
    mutationFn: async (blacklistedTerms) => {
      await put(`/workspaces/${workspaceId}`, { blacklistedTerms });
    },

    ...rest,
  });
}
