import { UPDATE_WORKSPACE_ROUTE } from '../actions/actionTypes';

export default function workspaceRouteHistory(state = null, action) {
  switch (action.type) {
    case UPDATE_WORKSPACE_ROUTE:
      return action.route || null;
    default:
      return state;
  }
}
