export function getNumberOfFiltersDisplayable({
  screenWidth,
  elementWidths,
  options: { marginLeft = 0, marginRight = 0, gap = 16, addMoreButtonWidth = 40 },
}: {
  screenWidth: number;
  elementWidths: number[];
  options: {
    marginLeft?: number;
    marginRight?: number;
    gap?: number;
    addMoreButtonWidth?: number;
  };
}): number {
  let remainingWidth =
    screenWidth - (marginLeft > 0 ? marginLeft : 0) - (marginRight > 0 ? marginRight : 0);
  if (remainingWidth <= 0 || elementWidths.length === 0) {
    return 0;
  }

  // Don't loop if screen size is large enough to display all filters
  if (
    remainingWidth >=
    elementWidths.reduce((acc, width, index) => acc + (index > 0 ? gap : 0) + width, 0)
  ) {
    return elementWidths.length;
  }

  let currentIndexOfFilter = 0;
  let displayableFilterCount = 0;

  while (currentIndexOfFilter < elementWidths.length || remainingWidth > 0) {
    const neededWidth = gap + elementWidths[currentIndexOfFilter] + gap + addMoreButtonWidth;

    if (neededWidth > remainingWidth) {
      break;
    } else {
      remainingWidth -=
        elementWidths[currentIndexOfFilter] + (currentIndexOfFilter === 0 ? 0 : gap);
      displayableFilterCount++;
    }
    currentIndexOfFilter++;
  }

  return displayableFilterCount;
}
