import TextField from '@material-ui/core/TextField';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { LimitModal } from 'semji-core/components/LimitModal';
import styled from 'styled-components/macro';

import usePostCurrentWorkspaceContentIdeaSearch from '@/apis/semji/contentIdeas/usePostCurrentWorkspaceContentIdeaSearch';
import UnlockImage from '@/assets/images/unlock-image.svg';
import { BetaBadge } from '@/components/Badge/BetaBadge';
import { RelativeLoader } from '@/components/Loader/Loader';
import { DefaultLink } from '@/components/Navigation/Link';
import { ParagraphText } from '@/components/Text/Paragraph';
import { TitleOne } from '@/components/Text/Title';
import { Alert, AlertDescription } from '@/containers/ContentIdeas/Listing/Header/Header.styled';
import PrimaryButton from '@/design-system/components/Button/PrimaryButton';
import useApiConfigurations from '@/hooks/useApiConfigurations';
import { useMixpanelTrackEvent } from '@/hooks/useMixpanelTrackEvent';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import { selectCurrentOrganization } from '@/store/selectors/selectCurrentOrganization';
import { selectRemainingContentIdeasSearchesUnit } from '@/store/selectors/selectRemainingContentIdeasSearchesUnit';
import defaultTheme from '@/themes/defaultTheme';
import { CONTENT_IDEAS_MINDMAP_CLICK } from '@/utils/3rdParty/Mixpanel/constants';
import { planStatus } from '@/utils/billing';
import { CONTENT_IDEAS_CREDITS_ENABLED } from '@/utils/configurations/constants';
import { SUPPORT_ADDRESS_MAIL } from '@/utils/constants';

const SUBSCRIPTION_TYPES = {
  MANAGED: 'MANAGED',
  SELF_SERVE: 'SELF_SERVE',
  TRIAL: 'TRIAL',
};

const Title = styled(TitleOne)`
  && {
    font-size: 32px;
  }
`;
const EnterKeywordWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 30px 0 40px 0;
  background-color: white;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  min-height: 120px;
`;
const InputWrapper = styled.div`
  display: flex;
  flex: 1;
  margin: 0 40px;
`;
const Button = styled(PrimaryButton)`
  && {
    margin-right: 40px;
  }
`;
const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

const AlertWrapper = styled(Alert)`
  && {
    width: 700px;
  }
`;

function Header() {
  const { t } = useTranslation();
  const [keyword, setKeyword] = useState('');
  const contentIdeaSearch = usePostCurrentWorkspaceContentIdeaSearch();
  const [openedLimitModal, setOpenedLimitModal] = useState(false);
  const remainingContentIdeasSearches = useSelector(selectRemainingContentIdeasSearchesUnit);
  const currentOrganization = useSelector(selectCurrentOrganization);
  const trackMixpanelEvent = useMixpanelTrackEvent();

  const contentIdeasCreditsEnabled = useApiConfigurations(CONTENT_IDEAS_CREDITS_ENABLED);
  const { isFeatureEnabled: hasUnlimitedContentIdeas } = useOrganizationFeatureSet(
    'credits:content-ideas-searches:has-unlimited-amount'
  );

  const isThresholdCreditReached =
    remainingContentIdeasSearches > 0 && remainingContentIdeasSearches <= 5;

  const isContentIdeasCreditOver = remainingContentIdeasSearches === 0;

  const alertMessageType = isThresholdCreditReached ? 'managed-1' : 'managed-2';

  function handleChange(e) {
    setKeyword(e.target.value);
  }

  function handleConfirmContentIdeaSearch(e) {
    trackMixpanelEvent(CONTENT_IDEAS_MINDMAP_CLICK);

    if ((e.keyCode === 13 || e.type === 'click') && keyword.trim().length) {
      if (
        contentIdeasCreditsEnabled &&
        !hasUnlimitedContentIdeas &&
        remainingContentIdeasSearches <= 0
      ) {
        setOpenedLimitModal(true);
        return;
      }

      contentIdeaSearch.mutate(keyword);
    }
  }

  function handleCloseLimitModal() {
    setOpenedLimitModal(false);
  }

  const {
    subscriptionStatus,
    subscriptionNextBillingAt,
    subscriptionTrialEndAt,
    subscriptionCurrentTermEndAt,
    paymentProblemOccurred,
    paymentDunningEndAt,
  } = currentOrganization;
  const { subscriptionCurrentTermEndAtFormattedDate } = planStatus({
    paymentDunningEndAt,
    paymentProblemOccurred,
    subscriptionCurrentTermEndAt,
    subscriptionNextBillingAt,
    subscriptionStatus,
    subscriptionTrialEndAt,
  });

  return (
    <>
      <TitleWrapper>
        <Title font={`secondary`} noMargin weight="medium">
          {t(`content-ideas:menu.title`)}
        </Title>
        <BetaBadge>{t(`common:labels.beta`)}</BetaBadge>
      </TitleWrapper>
      <ParagraphText align="center" color="dark060" height="28px" size="md" weight="medium">
        {t(`content-ideas:menu.explanation-text`)}
      </ParagraphText>
      {[SUBSCRIPTION_TYPES.SELF_SERVE, SUBSCRIPTION_TYPES.TRIAL].includes(
        currentOrganization.subscriptionType
      ) &&
        contentIdeasCreditsEnabled &&
        !hasUnlimitedContentIdeas &&
        isContentIdeasCreditOver && (
          <AlertWrapper gap="16px">
            <div>
              <AlertDescription fontWeight="strong">
                <Trans
                  components={{
                    extLink: (
                      <DefaultLink
                        color="red"
                        decoration
                        isExternal
                        noDynamicFontSize
                        noPadding
                        to={`mailto:${SUPPORT_ADDRESS_MAIL}`}
                        weight="strong"
                      />
                    ),
                  }}
                  i18nKey="content-ideas:listing.header.alert.limit-credit.self-serve"
                  values={{
                    maxAmount: currentOrganization.totalContentIdeaSearchUnit ?? 0,
                    subscriptionCurrentTermEndAtFormattedDate,
                  }}
                />
              </AlertDescription>
            </div>
          </AlertWrapper>
        )}
      {SUBSCRIPTION_TYPES.MANAGED === currentOrganization.subscriptionType &&
        !hasUnlimitedContentIdeas &&
        contentIdeasCreditsEnabled &&
        (isContentIdeasCreditOver || isThresholdCreditReached) && (
          <AlertWrapper gap="16px">
            <div>
              <AlertDescription fontWeight="strong">
                <Trans
                  components={{
                    extLink: (
                      <DefaultLink
                        color="red"
                        decoration
                        isExternal
                        noDynamicFontSize
                        noPadding
                        to={`mailto:${SUPPORT_ADDRESS_MAIL}`}
                        weight="strong"
                      />
                    ),
                  }}
                  i18nKey={`content-ideas:listing.header.alert.limit-credit.${alertMessageType}`}
                  values={{
                    countAmount:
                      currentOrganization.totalContentIdeaSearchUnit -
                      (remainingContentIdeasSearches ?? 0),
                    maxAmount: currentOrganization.totalContentIdeaSearchUnit ?? 0,
                  }}
                />
              </AlertDescription>
            </div>
          </AlertWrapper>
        )}
      <EnterKeywordWrapper>
        <InputWrapper>
          <TextField
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            disabled={contentIdeaSearch.isLoading}
            fullWidth
            placeholder={t(`content-ideas:menu.enter-keyword`)}
            value={keyword}
            onChange={handleChange}
            onKeyDown={handleConfirmContentIdeaSearch}
          />
        </InputWrapper>
        <Button
          disabled={!keyword.trim().length || contentIdeaSearch.isLoading}
          minWidth="150px"
          noLetterSpacing
          weight="strong"
          onClick={handleConfirmContentIdeaSearch}
        >
          {contentIdeaSearch.isLoading ? (
            <RelativeLoader
              opaque
              style={{ color: defaultTheme.cssColors.dark060, fontSize: '1.75em' }}
            />
          ) : (
            t(`content-ideas:menu.search`)
          )}
        </Button>
      </EnterKeywordWrapper>
      <LimitModal
        buttonLabel={t('components:dialog.dialog-information-template.button-text')}
        description={t('content-ideas:limit.description')}
        illustration={<img alt="" src={UnlockImage} />}
        isOpen={openedLimitModal}
        title={t('content-ideas:limit.title', {
          maxAmount: currentOrganization.totalContentIdeaSearchUnit ?? 0,
        })}
        onClose={handleCloseLimitModal}
      />
    </>
  );
}

export default Header;
