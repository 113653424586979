import React from 'react';
import { ToggleButton } from 'semji-core/components/ToggleButton';

export function ToggleSelector({
  list,
}: {
  list: { title: React.ReactNode; onClick: () => void; active?: boolean }[];
}) {
  return <ToggleButton actions={list} type="icon" />;
}
