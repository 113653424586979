import i18next from 'i18next';

export const DEFAULT_FOLDER_GENERAL = {
  id: '000000000000',
  name: '',
  parentFolderId: null,
};

export function getDefaultFolderGeneral() {
  return {
    ...DEFAULT_FOLDER_GENERAL,
    name: i18next.t('components:select.folder-select.label-folder-general'),
  };
}
