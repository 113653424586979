import styled from 'styled-components/macro';

import { UnChecked } from '@/components/Optimization/Instruction';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 0.8em 0.5em;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
  user-select: none;
  filter: blur(3px);
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Text = styled.span`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.3em 0 0.52em 0;
  font-size: ${({ theme }) => theme.text.sizes.small};
  color: ${({ theme }) => theme.text.colors.blue};
`;

const Description = styled.span`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2em;
  font-size: ${({ theme }) => theme.text.sizes.small};
  color: ${({ theme }) => theme.textCss.colors.grey};
`;

const Em = styled.em`
  font-style: initial;
  font-weight: 700;
  color: ${({ theme }) => theme.textCss.colors.grey};
`;

// below is the blurred text, do not translate
const SENTENCES = [
  "Qu'est-ce que l'identité numérique ? - Semji",
  "Les blogueurs au service de l'e-réputation des marques - Semji",
  "Les 6 acteurs de l'e-réputation d'une entreprise - Semji",
  'Modifier une e reputation : les alternatives gratuites - Semji',
  'Pourquoi et comment réaliser un Ebook ? - Semji',
];

function BlurredIncomingLink() {
  const sentence = SENTENCES[(Math.random() * SENTENCES.length) | 0];

  // below is the blurred text, do not translate
  return (
    <Wrapper>
      <UnChecked bordered />
      <Content>
        <Text>{sentence}</Text>
        <Description>
          <Em>Comment</Em> construire une image de marque, forte et positive ? ... vos concurrents,
          même si cela nécessite de <Em>créer</Em> une nouvelle identité visuelle. ... Soyez-leur
          utiles en élaborant des contenus pertinents (<Em>livre blanc</Em>, vidéos, articles de
          fond …)&nbsp;...
        </Description>
      </Content>
    </Wrapper>
  );
}

export default BlurredIncomingLink;
