import { Trans, useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player/youtube';

import { PrimaryLink } from '@/components/Navigation/Link';
import { VideoWrapper } from '@/containers/Onboarding/common';
import DescribeStepsComponents from '@/containers/Onboarding/DescribeSteps';

function ContentScore() {
  const i18nNameSpace = 'quick-start-guide:body.accordion.create-new-content.content-score';
  const { t } = useTranslation();

  return (
    <DescribeStepsComponents
      explanatoryText={<Trans i18nKey={`${i18nNameSpace}.explanatory-text`} />}
      steps={[
        <Trans
          key={`${i18nNameSpace}.steps.follow-recommendations`}
          i18nKey={`${i18nNameSpace}.steps.follow-recommendations`}
        />,
        <Trans
          key={`${i18nNameSpace}.steps.boost-writing`}
          components={{
            primaryLink: (
              <PrimaryLink
                href={t(`${i18nNameSpace}.ai-writing-link`)}
                isExternal
                noDecoration
                size="sm"
                weight="medium"
              />
            ),
          }}
          i18nKey={`${i18nNameSpace}.steps.boost-writing`}
        />,
      ]}
      visualRendering={
        <VideoWrapper>
          <ReactPlayer
            controls
            height="300px"
            url={t(`${i18nNameSpace}.video-link`)}
            width="468px"
          />
        </VideoWrapper>
      }
    />
  );
}

export default ContentScore;
