import styled from 'styled-components/macro';

export default styled.span`
  box-sizing: border-box;
  height: 15px;
  width: 15px;
  border: 0.08em solid currentColor;
  box-shadow: 0px 0px 0px 0.13em white inset;
  background-color: currentColor;
  border-radius: 50%;
  display: inline-block;
`;
