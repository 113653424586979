import { useMutation } from '@tanstack/react-query';

import apiClient from '@/apis/semji/apiClient';

export default function usePostEmailChecker(props) {
  const { post } = apiClient;

  return useMutation({
    mutationFn: async ({ email = '' }) =>
      await post(`/email_checker`, { email: email.toLowerCase().trim() }),

    ...props,
  });
}
