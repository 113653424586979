import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';

import { DefaultLink } from '@/components/Navigation/Link';
import Optimization from '@/components/Optimization/Optimization';
import DetailsBase from '@/containers/Content/SidePanel/Components/Optimization/Details/DetailsBase';
import LengthDetails from '@/containers/Content/SidePanel/Components/Optimization/Details/LengthDetails';
import Article from '@/containers/Content/SidePanel/Components/Optimization/Section/Length/Article';
import { disableMainOptimization, enableMainOptimization } from '@/store/actionsCreator/report';

const Wrapper = styled.div`
  padding: 1em 0;
`;

class ArticlesLength extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabledArticles: [],
      enabledArticles: [],
      showDetails: false,
    };

    this.openDetails = this.openDetails.bind(this);
    this.closeDetails = this.closeDetails.bind(this);
  }

  componentDidMount() {
    this.setStateRecommendations();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.recommendationsIds !== this.props.recommendationsIds) {
      this.setStateRecommendations();
    }
  }

  setStateRecommendations() {
    const { allRecommendations, recommendationsIds } = this.props;
    let enabledArticles = [];
    let disabledArticles = [];

    recommendationsIds.forEach((recommendationsId) => {
      if (allRecommendations[recommendationsId].disabled) {
        disabledArticles.push(recommendationsId);
      } else {
        enabledArticles.push(recommendationsId);
      }
    });

    this.setState({ disabledArticles, enabledArticles });
  }

  toggleArticle = (key, disabled) => {
    let { enabledArticles, disabledArticles } = this.state;

    if (disabled) {
      disabledArticles = [...disabledArticles, key];
      enabledArticles = enabledArticles.filter((enabledLink) => enabledLink !== key);
    } else {
      enabledArticles = [...enabledArticles, key];
      disabledArticles = disabledArticles.filter((enabledLink) => enabledLink !== key);
    }

    this.setState({ disabledArticles, enabledArticles });
  };

  disableRecommendation = (key) =>
    this.props.disableMainOptimization(
      key,
      this.props.focusTopKeywordId,
      this.props.html,
      this.props.contentTitle
    );

  enableRecommendation = (key) =>
    this.props.enableMainOptimization(
      key,
      this.props.focusTopKeywordId,
      this.props.html,
      this.props.contentTitle
    );

  renderArticles(articles) {
    return articles.map((articleId, index) => (
      <Article
        key={`${articleId}_${index}`}
        articleWordsCount={this.props.articleWordsCount}
        focusTopKeywordId={this.props.focusTopKeywordId}
        id={articleId}
        mainId={this.props.id}
        onToggleArticle={this.toggleArticle}
      />
    ));
  }

  openDetails() {
    this.setState({ showDetails: true });
  }

  closeDetails() {
    this.setState({ showDetails: false });
  }

  render() {
    const {
      articleWordsCount,
      targetWordsCount,
      score,
      weight,
      keyword,
      disabled,
      t,
      html,
      contentTitle,
      focusTopKeywordId,
      recommendationsIds,
      allRecommendations,
      ready,
      ...props
    } = this.props;
    const articleWordsCountLabel =
      null !== articleWordsCount
        ? articleWordsCount
        : t('content:side-panel-components.optimization.section.length.non-available');
    const targetWordsCountLabel =
      null !== targetWordsCount
        ? Math.ceil(targetWordsCount / 100) * 100
        : t('content:side-panel-components.optimization.section.length.non-available');
    const articles = [...this.state.enabledArticles, ...this.state.disabledArticles];

    return (
      <>
        <Optimization
          description={
            <Trans
              components={{
                extLink: (
                  <DefaultLink
                    color="dark040"
                    decoration
                    isExternal
                    noDynamicFontSize
                    noPadding
                    size="xsm"
                    to={t('common:links.help-length')}
                    weight="medium"
                  />
                ),
              }}
              i18nKey="content:side-panel-components.optimization.section.length.description"
            />
          }
          disabled={disabled}
          disableRecommendation={this.disableRecommendation}
          enableRecommendation={this.enableRecommendation}
          helpCenterLink={t('common:links.help-length')}
          infoText={t('content:side-panel-components.optimization.section.length.info-text')}
          instructions={this.renderArticles(articles)}
          isEmpty={false}
          isReady={ready}
          loaderRows={1}
          points={Math.round((weight - weight * score) * 100)}
          score={score}
          scorer={t('content:side-panel-components.optimization.section.length.scorer-text', {
            articleWordsCountLabel,
            targetWordsCountLabel,
          })}
          scorerTooltipText={t(
            'content:side-panel-components.optimization.section.length.scorer-tooltip-text',
            { targetWordsCountLabel }
          )}
          showDetailsAction={this.openDetails}
          title={t('content:side-panel-components.optimization.section.length.title')}
          {...props}
        />
        <DetailsBase
          description={t(
            'content:side-panel-components.optimization.section.length.details-description',
            { keyword }
          )}
          show={this.state.showDetails}
          subTitle={t(
            'content:side-panel-components.optimization.section.length.details-subtitle',
            { targetWordsCount }
          )}
          title={t('content:side-panel-components.optimization.section.length.title')}
          onClose={this.closeDetails}
        >
          <Wrapper>
            {articles.map((articleId) => (
              <LengthDetails
                key={articleId}
                id={articleId}
                targetWordsCount={targetWordsCountLabel}
              />
            ))}
          </Wrapper>
        </DetailsBase>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    allRecommendations: state.report.recommendations,
    articleWordsCount: state.report.recommendations[props.id]?.data.articleWordsCount,
    contentTitle: state.content.title,
    disabled: state.report.recommendations[props.id]?.disabled,
    html: state.content.html,
    ready: state.report.recommendations[props.id]?.ready,
    recommendationsIds: state.report.recommendations[props.id]?.recommendationsIds,
    score: state.report.recommendations[props.id]?.score,
    targetWordsCount: state.report.recommendations[props.id]?.data.targetWordsCount,
    weight: state.report.recommendations[props.id]?.weight,
  };
};

export default withTranslation()(
  connect(mapStateToProps, {
    disableMainOptimization,
    enableMainOptimization,
  })(ArticlesLength)
);
