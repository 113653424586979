import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import BackRightIcon from '@/components/icons/BackRightIcon';
import TooltipComponent from '@/components/Tooltip/Tooltip';

export const ArchivedWrapper = styled.div`
  display: none;
`;

function ArchivedCell({ className, onClick, disabled }) {
  const { t } = useTranslation();

  async function handleClick(e) {
    e.stopPropagation();
    if (!disabled) {
      onClick();
    }
  }

  return (
    <ArchivedWrapper>
      <TooltipComponent
        className={className}
        cursor={disabled ? 'not-allowed' : 'pointer'}
        delay={50}
        placement="top"
        title={t('content-ideas:listing.archive.restore')}
      >
        <BackRightIcon style={{ fontSize: '1rem' }} onClick={disabled ? null : handleClick} />
      </TooltipComponent>
    </ArchivedWrapper>
  );
}

export default ArchivedCell;
