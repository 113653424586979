import useForceRender from '@/hooks/useForceRender';

const INITIAL_COUNT_STATE = {
  characterCount: 0,
  characterCountWithoutSpaces: 0,
  selectionCharacterCount: 0,
  selectionCharacterCountWithoutSpaces: 0,
  selectionWordCount: 0,
  wordCount: 0,
};

export default function useGetWordCount(editorRef) {
  const { forceRender } = useForceRender();
  const wordcount = editorRef?.current?.plugins?.wordcount || {};

  const countValues = () => {
    try {
      return {
        characterCount: wordcount.body?.getCharacterCount?.() || 0,
        characterCountWithoutSpaces: wordcount.body?.getCharacterCountWithoutSpaces?.() || 0,
        selectionCharacterCount: wordcount.selection?.getCharacterCount?.() || 0,
        selectionCharacterCountWithoutSpaces:
          wordcount.selection?.getCharacterCountWithoutSpaces?.() || 0,
        selectionWordCount: wordcount.selection?.getWordCount?.() || 0,
        wordCount: wordcount.body?.getWordCount?.() || 0,
      };
    } catch {
      return INITIAL_COUNT_STATE;
    }
  };

  return {
    ...countValues(),
    updateCount: forceRender,
  };
}
