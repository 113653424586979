import './PageDataNavigation.scss';

import React from 'react';
import { Link } from 'react-router-dom';
import { getClassNames } from 'semji-core/utils/getClassNames';

import { PageDataNavigationProps } from '@/components/PageDataLayout/PageDataNavigation/PageDataNavigation.types';

function PageDataNavigation({ title, menuItems }: PageDataNavigationProps) {
  return (
    <div className="page-data-navigation">
      {title && <div className="page-data-navigation__title">{title}</div>}
      <div className="page-data-navigation__list">
        {menuItems.map((item, index) => (
          <Link
            key={index}
            className={getClassNames(
              'page-data-navigation__list__item',
              item.active && 'page-data-navigation__list__item--active'
            )}
            data-intercom-target={item.intercomTarget}
            to={item.to}
          >
            {item.icon}
            <span className="page-data-navigation__list__item__label">{item.title}</span>
            {item.end}
          </Link>
        ))}
      </div>
    </div>
  );
}

export default PageDataNavigation;
