import { useQuery } from '@tanstack/react-query';

import apiClient from '@/apis/semji/apiClient';
import extractReactQueryData from '@/utils/extractReactQueryData';

export default function useGetRegistrant(registrantToken) {
  const { get } = apiClient;

  return useQuery({
    queryFn: async ({ signal }) => {
      const result = await get(`/registrants/${registrantToken}`, {
        signal,
      });

      return extractReactQueryData(result);
    },
    queryKey: ['registrant'],
  });
}
