import handleHighlightByType from './handleHighlightByType';
import isElementAlreadyHighlightedByDatum from './isElementAlreadyHighlightedByDatum';
import isElementHighlighted from './isElementHighlighted';

function highlightChildren({
  element,
  datum,
  dataAttribute,
  highlightClass,
  range,
  overrideClassNames,
  overrideData,
}: {
  element: Element;
  datum: string;
  dataAttribute: string;
  highlightClass: string;
  overrideClassNames: boolean;
  overrideData: boolean;
  range: Range;
}): void {
  for (let i: number = 0, count: number = element.childNodes.length; i < count; i++) {
    const childrenElement: HTMLElement = element.childNodes[i] as HTMLElement;

    const childrenElementisHighlight: boolean = isElementHighlighted({
      element: childrenElement,
      highlightClass,
    });

    const childrenElementIsAlreadHighlighted: boolean = isElementAlreadyHighlightedByDatum({
      dataAttribute,
      datum: datum.toString(),
      element: childrenElement,
    });

    if (
      !childrenElement.tagName ||
      !childrenElementisHighlight ||
      (childrenElementisHighlight && !childrenElementIsAlreadHighlighted)
    ) {
      handleHighlightByType({
        dataAttribute,
        datum,
        element: childrenElement,
        highlightClass,
        overrideClassNames,
        overrideData,
        range,
      });
      count = element.childNodes.length;
    }
  }
}

export default highlightChildren;
