import { useQuery } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import apiClient from '@/apis/semji/apiClient';
import { LOCATION, SCOPE_SERVICE_INTEGRATIONS } from '@/apis/semji/constants';
import { showErrorSnackbar } from '@/store/actions/ui';
import { TYPE_ANALYTICS } from '@/utils/constants';

export default function useGetCustomGoals(props) {
  const { workspaceId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { get } = apiClient;
  const queryClient = useQueryClient();
  const currentAnalyticsServiceIntegration = queryClient
    .getQueryData([SCOPE_SERVICE_INTEGRATIONS.GET_SERVICE_INTEGRATIONS, workspaceId, LOCATION.ROOT])
    ?.find((service) => service.type === TYPE_ANALYTICS);

  return useQuery({
    onError: () => {
      dispatch(showErrorSnackbar(t('common:error.default')));
    },
    queryFn: async ({ signal }) => {
      const result = await get(
        `/service_integrations/${currentAnalyticsServiceIntegration?.id}/custom_goals`,
        {
          signal,
        }
      );

      return result.data['hydra:member'] || [];
    },

    queryKey: [SCOPE_SERVICE_INTEGRATIONS.GET_CUSTOM_GOALS, workspaceId, LOCATION.SETTINGS],
    ...props,
  });
}
