import Highcharts from 'highcharts';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import HighchartsReact from 'highcharts-react-official';
import { useId, useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getRoundedNumberMetricWithSuffix } from 'semji-core/utils/numbers';

import { ChartLegend } from '@/containers/Competitors/Duel/Report/ChartLegend';
import { ENUM_SERIES_CURSOR_TYPE } from '@/containers/Competitors/Duel/Report/constant';
import { sortBarChart } from '@/containers/Competitors/utils/utils';
import { selectUserConfiguration } from '@/store/selectors/selectUserConfiguration';
import defaultTheme from '@/themes/defaultTheme';

import { ChartTooltip } from '../ChartTooltip/ChartTooltip';
import ChartUtils from './Chart.utils';
import { DEFAULT_CHART_OPTIONS, DEFAULT_SERIE_OPTIONS, getContainerId } from './constants';

NoDataToDisplay(Highcharts);

export function HighChartsBar({
  metricsSeries,
  selectedMetrics,
  categories,
  options = {},
  withPositioner = false,
  space = 0,
  showLegendWinner = true,
  sortData = true,
  sortKey = null,
  serieKey,
  onPointClick,
  getCustomFilter,
}) {
  const id = useId();
  const chartContainerId = getContainerId(id);
  const { t } = useTranslation();
  const { showCompetitorDataLabels } = useSelector(selectUserConfiguration);

  const series = buildSeries(metricsSeries);

  const { cleanedCategories, cleanedSeries } = removeEmptyData(series, categories);

  // Handle the visible series
  const hiddenSeriesRef = useRef([]);
  const [hiddenSeries, setHiddenSeries] = useState(hiddenSeriesRef.current);

  // Sort series with categories in descending order
  const { categoriesData, seriesData } = getSortedData();
  const positioner = withPositioner
    ? function (x, y, point) {
        const offsets = document.getElementById(chartContainerId).getBoundingClientRect();
        return {
          x: offsets.left + point.plotX + space / 2 - 220,
          y: offsets.top + point.plotY,
        };
      }
    : undefined;
  const OPTIONS = {
    ...DEFAULT_CHART_OPTIONS,
    chart: {
      ...DEFAULT_CHART_OPTIONS.chart,
      events: {
        render: function () {
          const series = this.series;
          series.forEach((serie) => {
            serie.data.forEach((point) => {
              !showCompetitorDataLabels && point.dataLabel.hide();
            });
          });
        },
      },
      type: 'column',
    },
    exporting: {
      enabled: false,
    },
    lang: {
      noData: t('competitors:duel.report.no-data'),
    },
    legend: ChartUtils.getLegendOptions({
      overrideOptions: {
        labelFormatter: function () {
          return ReactDOMServer.renderToStaticMarkup(
            getChartLegend(this.name, this.color, this.visible)
          );
        },
      },
    }),
    plotOptions: {
      series: {
        cursor: onPointClick ? ENUM_SERIES_CURSOR_TYPE.POINTER : ENUM_SERIES_CURSOR_TYPE.UNDEFINED,
        dataLabels: {
          color: defaultTheme.cssColors.dark100,
          enabled: true,
          formatter: function () {
            //format the number with suffix like 'K' or 'M'
            return getRoundedNumberMetricWithSuffix({ number: this.y });
          },
          shadow: false,
          style: {
            fontSize: defaultTheme.textCss.sizes.xs,
            fontWeight: defaultTheme.textCss.weights.medium,
            textOutline: false,
          },
        },
        events: {
          click: (e) => onPointClick?.(e, serieKey, getCustomFilter?.(e.point)),
          legendItemClick: function () {
            const { name, visible } = this;
            handleLegendItemClick({ name, visible: !visible });
          },
          mouseOut: function () {
            this.data.forEach((point) => {
              !showCompetitorDataLabels && point.dataLabel.hide();
            });
          },
          mouseOver: function () {
            this.data.forEach((point) => {
              point.dataLabel.show();
            });
          },
        },
        maxPointWidth: 40,
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            opacity: 0.3,
          },
        },
        ...options.plotOptions?.series,
      },
    },
    tooltip: {
      backgroundColor: 'transparent',
      borderWidth: '0px',
      className: 'competitors-duel-report__chart__tooltip',
      distance: 20,
      enabled: true,
      formatter: function () {
        return ReactDOMServer.renderToStaticMarkup(
          getChartTooltip({ custom: this, graphPoint: this.point, label: this.x, selectedMetrics })
        );
      },
      hideDelay: 0,
      outside: true,
      positioner,
      shadow: false,
      style: {
        color: defaultTheme.cssColors.dark080,
      },
      useHTML: true,
    },
    xAxis: ChartUtils.getXAxisOptions({
      overrideLabelOptions: {
        style: options.xAxisStyle,
      },
      overrideOptions: { categories: categoriesData, ...options.xAxis },
    }),
    yAxis: ChartUtils.getYAxisOptions(),
  };

  // Used with ReactDOMServer to custom label
  function getChartLegend(name, color, isVisible) {
    const winner = ChartUtils.computeWinner(series).name;

    return (
      <ChartLegend
        color={color}
        isVisible={isVisible && showLegendWinner}
        isWinner={winner === name}
        name={name}
      />
    );
  }

  function removeEmptyData(series, categories) {
    const reference = series[0];
    const competitor = series[1];

    const cleanedCategories = [];
    const referenceSerie = { ...reference, data: [] };
    const competitorSerie = { ...competitor, data: [] };

    categories.forEach((category, index) => {
      const dataReference = reference.data[index] || 0;
      const dataCompetitor = competitor.data[index] || 0;

      if (dataReference?.y + dataCompetitor?.y > 0) {
        cleanedCategories.push(category);
        referenceSerie.data.push(dataReference);
        competitorSerie.data.push(dataCompetitor);
      }
    });

    return {
      cleanedCategories: cleanedCategories,
      cleanedSeries: [referenceSerie, competitorSerie],
    };
  }

  // Used with ReactDOMServer to custom chart tooltip
  function getChartTooltip({ graphPoint, selectedMetrics, label }) {
    return (
      <ChartTooltip graphPoints={[graphPoint]} label={label} selectedMetrics={selectedMetrics} />
    );
  }

  function buildSeries(series) {
    return series.map((serie) => {
      return {
        ...DEFAULT_SERIE_OPTIONS,
        ...serie,
      };
    });
  }

  function onClick(e) {
    e.stopPropagation();
  }

  function handleLegendItemClick({ name, visible }) {
    hiddenSeriesRef.current = visible
      ? hiddenSeriesRef.current.filter((item) => item !== name)
      : [...hiddenSeriesRef.current, name];

    setHiddenSeries(hiddenSeriesRef.current);
  }

  function getSortedData() {
    if (sortData) {
      return sortBarChart(cleanedSeries, cleanedCategories, sortKey, hiddenSeries);
    } else
      return {
        categoriesData: cleanedCategories,
        seriesData: cleanedSeries,
      };
  }

  return (
    <div className="competitors-duel-report__chart">
      <div aria-hidden="true" id={chartContainerId} onClick={onClick}>
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            ...OPTIONS,
            series: seriesData,
          }}
        />
      </div>
    </div>
  );
}
