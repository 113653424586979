import { Log } from '@/utils/log/Log';

export const initChargebee = () => {
  const chargebeeSite = import.meta.env.VITE_REACT_APP_CHARGEBEE_SITE;

  if (Boolean(chargebeeSite)) {
    try {
      /* Initialize a Chargebee instance after mounting the root component. */
      window.Chargebee.init({ site: chargebeeSite });
    } catch (e) {
      Log.error('initChargebee', { e });
    }
  }
};
