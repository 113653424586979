import { nanoid } from 'nanoid';
import { useMemo } from 'react';

import SvgWrapper from './SvgWrapper';

function FlagTriangleIcon(props) {
  const svgId = useMemo(() => nanoid(), []);

  return (
    <SvgWrapper {...props}>
      <mask
        height="17"
        id={svgId}
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="14"
        x="1"
        y="0"
      >
        <path d="M13.0001 10.008V0.77L1.12109 5.389L13.0001 10.008ZM12.0001 8.547L3.87909 5.389L12.0001 2.23V8.547ZM15.0001 0V17H14.0001V0H15.0001Z" />
      </mask>
      <g mask={`url(#${svgId})`}>
        <rect height="20" width="20" x="-2" y="-1" />
      </g>
    </SvgWrapper>
  );
}

export default FlagTriangleIcon;
