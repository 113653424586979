import React from 'react';
import styled from 'styled-components/macro';

const ContentProductionWrapper = styled.div`
  width: 150px;
`;

const ReportIcon = () => (
  <ContentProductionWrapper
    dangerouslySetInnerHTML={{
      __html: `
      <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
   <style type="text/css">
     .report_icon_style_st0{fill:#E1E4E6;}
     .report_icon_style_st1{fill:#E8EAEB;}
     .report_icon_style_st2{fill:none;}
     .report_icon_style_st3{clip-path:url(#SVGID_4_);fill:none;stroke:#989EA4;stroke-width:6.861;stroke-miterlimit:10;}
     .report_icon_style_st4{fill:#D9DCDD;}
     .report_icon_style_st5{fill:none;stroke:#989EA4;stroke-width:5.2777;stroke-miterlimit:10;}
   </style>
   <g>
     <path class="report_icon_style_st0" d="M413.8,295.7H393c-4.6,0-8.3-3.7-8.3-8.3l0,0c0-4.6,3.7-8.3,8.3-8.3h20.8c4.6,0,8.3,3.7,8.3,8.3l0,0
       C422.1,292,418.4,295.7,413.8,295.7z"/>
     <g>
       <path class="report_icon_style_st1" d="M80.7,250.2H50.9c-4.6,0-8.3-3.7-8.3-8.3v0c0-4.6,3.7-8.3,8.3-8.3h29.7c4.6,0,8.3,3.7,8.3,8.3v0
         C88.9,246.4,85.2,250.2,80.7,250.2z"/>
       <path class="report_icon_style_st1" d="M121.6,154.7H91.9c-4.6,0-8.3-3.7-8.3-8.3v0c0-4.6,3.7-8.3,8.3-8.3h29.7c4.6,0,8.3,3.7,8.3,8.3v0
         C129.9,151,126.2,154.7,121.6,154.7z"/>
       <path class="report_icon_style_st1" d="M101.8,197.7H57.9c-4.6,0-8.3-3.7-8.3-8.3v0c0-4.6,3.7-8.3,8.3-8.3h43.9c4.6,0,8.3,3.7,8.3,8.3v0
         C110.1,194,106.4,197.7,101.8,197.7z"/>
       <path class="report_icon_style_st1" d="M23.1,197.4h-7.8c-4.6,0-8.3-3.7-8.3-8.3v0c0-4.6,3.7-8.3,8.3-8.3h7.8c4.6,0,8.3,3.7,8.3,8.3v0
         C31.4,193.6,27.7,197.4,23.1,197.4z"/>
     </g>
     <path class="report_icon_style_st1" d="M454.1,295.4h-64.9c-4.6,0-8.3-3.7-8.3-8.3v0c0-4.6,3.7-8.3,8.3-8.3h64.9c4.6,0,8.3,3.7,8.3,8.3v0
       C462.4,291.7,458.6,295.4,454.1,295.4z"/>
     <path class="report_icon_style_st1" d="M498.7,253.4h-29.6c-4.6,0-8.3-3.7-8.3-8.3v0c0-4.6,3.7-8.3,8.3-8.3h29.6c4.6,0,8.3,3.7,8.3,8.3v0
       C507,249.7,503.3,253.4,498.7,253.4z"/>
     <g>
       <path class="report_icon_style_st1" d="M451.5,214.3h-23.4c-4.6,0-8.3-3.7-8.3-8.3v0c0-4.6,3.7-8.3,8.3-8.3h23.4c4.6,0,8.3,3.7,8.3,8.3v0
         C459.8,210.6,456.1,214.3,451.5,214.3z"/>
     </g>
     <g>
       <path class="report_icon_style_st1" d="M440.3,253.6h-8.6c-4.6,0-8.3-3.7-8.3-8.3v0c0-4.6,3.7-8.3,8.3-8.3h8.6c4.6,0,8.3,3.7,8.3,8.3v0
         C448.6,249.8,444.9,253.6,440.3,253.6z"/>
     </g>
     <path class="report_icon_style_st1" d="M457.9,426.8c0,10.7-91.3,19.3-203.9,19.3S50,437.5,50,426.8c0-10.7,91.3-9.4,203.9-9.4
       S457.9,416.2,457.9,426.8z"/>
     <g>
       <path class="report_icon_style_st2" d="M369.3,324.4c23.6-26.2,38.4-60.6,39.4-98.6c2.2-84.5-64.4-155-148.4-157.2c-40.7-1.1-79.4,13.9-108.9,42.1
         C121.9,138.9,105,177,104,217.9c-2.2,84.5,64.4,155,148.4,157.2c37.8,1,72.7-12.1,100-34.4L369.3,324.4z"/>
     </g>
     <circle class="report_icon_style_st1" cx="256.3" cy="221.2" r="108.3"/>
     <g>
       <defs>
         <circle id="SVGID_1_" cx="256.3" cy="221.2" r="108.3"/>
       </defs>
       <clipPath id="SVGID_2_">
         <use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
       </clipPath>
     </g>
     <g>
       <defs>
         <circle id="SVGID_3_" cx="256.3" cy="221.2" r="108.3"/>
       </defs>
       <clipPath id="SVGID_4_">
         <use xlink:href="#SVGID_3_"  style="overflow:visible;"/>
       </clipPath>
       <polyline class="report_icon_style_st3" points="141.7,249.6 179.3,249.6 221.5,215.8 271.6,249.6 366,168.3 		"/>
     </g>
     <path class="report_icon_style_st4" d="M451.2,403.4L371,317.6c23.2-25.9,37.9-59.8,38.8-97.3C412,137,346.4,67.5,263.5,65.3
       c-40.1-1-78.3,13.7-107.5,41.5c-29.1,27.8-45.7,65.4-46.8,105.8c-2.2,83.3,63.5,152.9,146.3,155.1c37.3,1,71.7-12,98.6-33.9
       l80.1,85.6c4.4,4.7,11.8,4.9,16.5,0.4C450.8,419.7,459.6,412.6,451.2,403.4z M256.2,344.4c-70.1-1.8-125.7-60.7-123.8-131.2
       c0.9-34.2,14.9-66,39.6-89.5c24.6-23.5,56.9-36,90.9-35.1c70.1,1.8,125.7,60.7,123.8,131.2C384.9,290.3,326.3,346.2,256.2,344.4z"
       />
     <path class="report_icon_style_st5" d="M451,411.8l-81.5-87.1c23.6-26.3,38.5-60.8,39.5-98.9c2.2-84.7-64.5-155.5-148.8-157.6
       C219.4,67,180.6,82,151,110.3c-29.6,28.3-46.5,66.5-47.5,107.5c-2.2,84.7,64.5,155.5,148.8,157.6c37.9,1,72.9-12.2,100.2-34.5
       l81.4,87c4.5,4.8,12,5,16.7,0.4C450.6,428.4,459.6,421.1,451,411.8z"/>
     <g>
       <g>
         <path class="report_icon_style_st5" d="M252.8,351.8c-71.3-1.9-127.7-61.7-125.9-133.4c0.9-34.8,15.2-67,40.2-91c25-23.9,57.9-36.6,92.4-35.7
           c71.3,1.9,127.7,61.7,125.9,133.4C383.6,296.8,324.1,353.7,252.8,351.8z"/>
       </g>
     </g>
   </g>
   </svg>

`,
    }}
  />
);

export default ReportIcon;
