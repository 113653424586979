import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import Loader from '@/components/Loader/Loader';
import { TabBodyContent } from '@/components/Navigation/Tabs';
import UsersSettings from '@/components/Settings/UsersSettings';
import OrganizationAccessesService from '@/services/OrganizationAccesses';
import UserService from '@/services/User';
import WorkspaceAccessesService from '@/services/WorkspaceAccesses';
import { updateUserWorkspaceAccesses } from '@/store/actions/user';
import { fetchCurrentOrganizationEditableData } from '@/store/actionsCreator/organization';
import { userRoles } from '@/utils/can/constants';
import { getEmailDomainFromUrl } from '@/utils/email';

const StyledTabBodyContent = styled(TabBodyContent)`
  display: flex;
  height: 100%;
  box-sizing: border-box;
`;

function UsersSettingsContainer(props) {
  const dispatch = useDispatch();
  const { organizationId, workspaceId } = useParams();
  const [state, setState] = useState({
    OrganizationAccesses: [],
    loading: true,
    workspaceAccesses: [],
  });
  // TO DO: REFACTOR SELECTORS
  // put all selectors under store so they can be reused
  const currentUser = useSelector((state) => state.user);

  const fetchAccesses = async () => {
    const _workspaceAccessesService = new WorkspaceAccessesService(null, workspaceId);
    const _organizationAccessesService = new OrganizationAccessesService(null, organizationId);
    const [workspaceAccesses, organizationAccesses] = await Promise.all([
      _workspaceAccessesService.workspaceAccesses,
      _organizationAccessesService.organizationAccesses,
    ]);

    setState({
      loading: false,
      organizationAccesses,
      workspaceAccesses,
    });

    return { organizationAccesses, workspaceAccesses };
  };

  useEffect(() => {
    fetchAccesses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, workspaceId]);

  async function setUserRole(workspaceAccessesId, role) {
    const _workspaceAccessesService = new WorkspaceAccessesService(
      workspaceAccessesId,
      workspaceId
    );
    await _workspaceAccessesService.setWorkspaceAccesses(role);
  }
  async function deleteUserAccess(workspaceAccessesId) {
    const _workspaceAccessesService = new WorkspaceAccessesService(
      workspaceAccessesId,
      workspaceId
    );
    await _workspaceAccessesService.deleteWorkspaceAccesses();
    setState({
      ...state,
      workspaceAccesses: state.workspaceAccesses.filter((wa) => wa.id !== workspaceAccessesId),
    });
    dispatch(fetchCurrentOrganizationEditableData(organizationId));
  }

  async function deleteOrganizationUserAccess(organizationAccessId, workspaceAccessId) {
    const _OrganizationAccessesService = new OrganizationAccessesService(
      organizationAccessId,
      organizationId
    );

    await _OrganizationAccessesService.deleteOrganizationAccesses();
    setState({
      ...state,
      organizationAccesses: state.organizationAccesses.filter(
        (org) => org.id !== organizationAccessId
      ),
      workspaceAccesses: state.workspaceAccesses.filter((wa) => wa.id !== workspaceAccessId),
    });
    dispatch(fetchCurrentOrganizationEditableData(organizationId));
  }

  async function sendInvites(emails) {
    const _workspaceAccessesService = new WorkspaceAccessesService(null, workspaceId);
    const uniqEmails = [...new Set(emails)];
    const listUsers = uniqEmails.map((email) => ({ email }));
    await _workspaceAccessesService.inviteUsers(listUsers);

    await fetchAccesses();
    dispatch(fetchCurrentOrganizationEditableData(organizationId));
  }

  async function handleResetPassword(email) {
    const _userService = new UserService();
    await _userService.resetPasswordForEmail(email);
  }

  const setUserAiSuiteAccess = async (workspaceAccessesId, accessToAiFeatures) => {
    const _WorkspaceAccessesService = new WorkspaceAccessesService(
      workspaceAccessesId,
      workspaceId
    );

    await _WorkspaceAccessesService.setWorkspaceAccessesAiSuite(accessToAiFeatures);
    const { workspaceAccesses } = await fetchAccesses();

    const currentWorkspaceAccesses = workspaceAccesses.find((wa) => wa.id === workspaceAccessesId);
    dispatch(updateUserWorkspaceAccesses(currentWorkspaceAccesses));
  };

  const setUserSearchIntelligenceAccess = async (
    workspaceAccessesId,
    accessToSearchIntelligence
  ) => {
    const _WorkspaceAccessesService = new WorkspaceAccessesService(
      workspaceAccessesId,
      workspaceId
    );
    await _WorkspaceAccessesService.setWorkspaceAccessesSearchIntelligence(
      accessToSearchIntelligence
    );
    const { workspaceAccesses } = await fetchAccesses();

    const currentWorkspaceAccesses = workspaceAccesses.find((wa) => wa.id === workspaceAccessesId);
    dispatch(updateUserWorkspaceAccesses(currentWorkspaceAccesses));
  };

  if (state.loading) {
    return (
      <TabBodyContent>
        <Loader />
      </TabBodyContent>
    );
  }

  return (
    <StyledTabBodyContent>
      <UsersSettings
        accesses={state.workspaceAccesses}
        accessName={state.workspaceAccesses[0]?.workspace?.name}
        alreadyInvitedUsers={state.workspaceAccesses.map((wa) => wa.user.email)}
        availableUsers={state.organizationAccesses
          .map((wa) => wa.user.email)
          .filter((email) => !state.workspaceAccesses.find((wa) => wa.user.email === email))}
        currentUser={currentUser}
        deleteOrganizationUserAccess={deleteOrganizationUserAccess}
        deleteUserAccess={deleteUserAccess}
        emailDomain={getEmailDomainFromUrl(state.workspaceAccesses[0]?.workspace?.websiteUrl)}
        handleResetPassword={handleResetPassword}
        keyName="workspace"
        organizationAccesses={state.organizationAccesses}
        organizationId={organizationId}
        organizationName={
          state.organizationAccesses.find((org) => org.organization.id === organizationId)
            ?.organization?.name
        }
        roles={userRoles}
        sendInvites={sendInvites}
        setUserAiSuiteAccess={setUserAiSuiteAccess}
        setUserRole={setUserRole}
        setUserSearchIntelligenceAccess={setUserSearchIntelligenceAccess}
        title={props.title}
        withAiSuiteSettings={true}
        withSearchIntelligenceSettings={true}
      />
    </StyledTabBodyContent>
  );
}

export default UsersSettingsContainer;
