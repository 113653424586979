export const SEMJI_THREAD_HIGHLIGHT_CLASS_NAME: string =
  'semji_annotation_comment_threads_highlight';

export const PENDING_THREAD_ID: string = 'pending-thread-id';
export const PENDING_COMMENT_ID: string = 'pending-comment-id';

export const COMMENTS_ICON: string = 'semji-comments-icon';

export const FOCUSED_THREAD_ID_QUERY_PARAM: string = 'threadId';
export const COMMENT_QUERY_PARAM: string = 'comments';

export const THREAD_CARD_PREFIX_ID: string = 'thread-';
