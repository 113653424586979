import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';

import { FlexJustifiedBetweenAndAlignCenterContainer } from '@/components/_common';
import { DefaultLink } from '@/components/Navigation/Link';
import { DisableableWrapper, ShowMoreInstructions } from '@/components/Optimization/Instruction';
import Optimization from '@/components/Optimization/Optimization';
import { TertiaryTitle } from '@/components/Text/Title';
import DetailsBase from '@/containers/Content/SidePanel/Components/Optimization/Details/DetailsBase';
import QuestionDetails from '@/containers/Content/SidePanel/Components/Optimization/Details/QuestionDetails';
import Question from '@/containers/Content/SidePanel/Components/Optimization/Section/Questions/Question';
import { isNodeInsideTable } from '@/containers/Content/TinyMceComponents/Editor/hooks/useAiWriting/useAiWriting';
import { disableMainOptimization, enableMainOptimization } from '@/store/actionsCreator/report';
import { DISPLAY_LIMIT, MAX_DISPLAY_LIMIT } from '@/utils/constants';

const Wrapper = styled(FlexJustifiedBetweenAndAlignCenterContainer)`
  padding: 1em 0 0 0;
`;
const Title = styled(TertiaryTitle)`
  display: flex;
  align-items: center;
  font-size: 0.9em;
  font-weight: 500;
`;

class Questions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabledQuestions: [],
      enabledQuestions: [],
      isListLimited: true,
      showDetails: false,
    };

    this.openDetails = this.openDetails.bind(this);
    this.closeDetails = this.closeDetails.bind(this);
  }

  toggleLimit = () => {
    this.setState({ isListLimited: !this.state.isListLimited });
  };

  toggleQuestion = (key, disabled) => {
    let { enabledQuestions, disabledQuestions } = this.state;

    if (disabled) {
      disabledQuestions = [...disabledQuestions, key];
      enabledQuestions = enabledQuestions.filter((enabledLink) => enabledLink !== key);
    } else {
      enabledQuestions = [...enabledQuestions, key];
      disabledQuestions = disabledQuestions.filter((enabledLink) => enabledLink !== key);
    }

    this.setState({ disabledQuestions, enabledQuestions });
  };

  componentDidMount() {
    this.setStateRecommendations();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.recommendationsIds !== this.props.recommendationsIds) {
      this.setStateRecommendations();
    }
  }

  setStateRecommendations() {
    const { allRecommendations, recommendationsIds } = this.props;
    let enabledQuestions = [];
    let disabledQuestions = [];

    recommendationsIds.forEach((recommendationsId) => {
      if (allRecommendations[recommendationsId].disabled) {
        disabledQuestions.push(recommendationsId);
      } else {
        enabledQuestions.push(recommendationsId);
      }
    });

    this.setState({ disabledQuestions, enabledQuestions });
  }

  disableRecommendation = (key) =>
    this.props.disableMainOptimization(
      key,
      this.props.focusTopKeywordId,
      this.props.html,
      this.props.contentTitle
    );

  enableRecommendation = (key) =>
    this.props.enableMainOptimization(
      key,
      this.props.focusTopKeywordId,
      this.props.html,
      this.props.contentTitle
    );

  onAddOptimization = (optimization) => {
    const { editorRef } = this.props;
    if (editorRef.current.isSemjiContentReadOnly) {
      return;
    }

    const node = editorRef.current.dom.create('h2', {}, optimization);
    editorRef.current.focus();
    const insideTable = isNodeInsideTable(editorRef.current.selection.getEnd());
    const currentNode = insideTable || editorRef.current.selection.getNode();
    editorRef.current.dom.insertAfter(node, currentNode);

    // When selection is on a <br> with a parent, we insert after the parent
    const isInsideEmptyParagraph = currentNode?.tagName === 'BR' && currentNode?.parentNode;
    if (isInsideEmptyParagraph) {
      editorRef.current.dom.insertAfter(node, currentNode.parentNode);
      editorRef.current.dom.remove(currentNode.parentNode);
    } else {
      editorRef.current.dom.insertAfter(node, currentNode);
    }

    // When selection is on a <p><br></p>, we remove this empty paragraph
    const isEmptyParagraph =
      currentNode?.tagName === 'P' &&
      currentNode?.childNodes.length === 1 &&
      currentNode?.childNodes[0]?.tagName === 'BR';
    if (isEmptyParagraph) {
      editorRef.current.dom.remove(currentNode);
    }

    node.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    editorRef.current.selection.select(node, true);
    editorRef.current.selection.collapse(false);
    editorRef.current.setDirty(true);

    setTimeout(() => {
      this.props.editorRef.current?.undoManager?.add?.();
    });
  };

  renderDisplayedQuestions(questions, limit) {
    return questions
      .map((questionId, index) => (
        <Question
          key={`${questionId}_${index}`}
          focusTopKeywordId={this.props.focusTopKeywordId}
          id={questionId}
          isReadOnly={this.props.isReadOnly}
          mainId={this.props.id}
          onAddQuestion={this.onAddOptimization}
          onToggleQuestion={this.toggleQuestion}
        />
      ))
      .slice(0, limit);
  }

  openDetails() {
    this.setState({ showDetails: true });
  }

  closeDetails() {
    this.setState({ showDetails: false });
  }

  render() {
    const {
      score,
      weight,
      keyword,
      recommendationsIds,
      disabled,
      data,
      pageId,
      focusTopKeywordId,
      t,
      html,
      ready,
      enableMainOptimization,
      disableMainOptimization,
      contentTitle,
      allRecommendations,
      ...props
    } = this.props;
    const limitedQuestions = recommendationsIds.slice(0, DISPLAY_LIMIT);
    const displayableQuestions = recommendationsIds.slice(0, MAX_DISPLAY_LIMIT);
    const limit = this.state.isListLimited ? DISPLAY_LIMIT : MAX_DISPLAY_LIMIT;
    const leftQuestionsCount = displayableQuestions.length - limitedQuestions.length;
    const questions = [...this.state.enabledQuestions, ...this.state.disabledQuestions];
    const clusteringDetailLink = `${
      import.meta.env.VITE_REACT_APP_SEMJI_BACKOFFICE_URL
    }/clustering-details/p/${pageId}/tk/${focusTopKeywordId}/questions`;
    const isEmpty = questions.length === 0 && ready;

    return (
      <>
        <Optimization
          description={
            <span>
              {t('content:side-panel-components.optimization.section.questions.description')}
              <Trans
                components={{
                  extLink: (
                    <DefaultLink
                      color="dark060"
                      decoration
                      isExternal
                      noDynamicFontSize
                      noPadding
                      size={'xsm'}
                      to={t('common:links.help-questions')}
                      weight="medium"
                    />
                  ),
                }}
                i18nKey="content:side-panel-components.optimization.section.questions.description-learn-more"
              />
            </span>
          }
          disabled={disabled}
          disableRecommendation={this.disableRecommendation}
          enableRecommendation={this.enableRecommendation}
          helpCenterLink={t('common:links.help-questions')}
          infoMessage={t('components:optimization.optimization.no-questions-message', {
            keyword,
          })}
          infoText={t('content:side-panel-components.optimization.section.questions.info-text')}
          isEmpty={isEmpty}
          isReady={ready}
          loaderRows={1}
          instructions={[
            <DisableableWrapper key="questions" disabled={disabled}>
              {this.renderDisplayedQuestions(questions, limit)}
            </DisableableWrapper>,
            <ShowMoreInstructions
              key="moreQuestions"
              hide={recommendationsIds.length <= DISPLAY_LIMIT}
              toggle={this.toggleLimit}
            >
              {this.state.isListLimited
                ? t(
                    'content:side-panel-components.optimization.section.questions.toggle-more-text_interval',
                    {
                      count: leftQuestionsCount,
                      postProcess: 'interval',
                    }
                  )
                : t(
                    'content:side-panel-components.optimization.section.questions.toggle-less-text'
                  )}
            </ShowMoreInstructions>,
          ]}
          // set the state of showDetails to display the modal of details
          // temporary disabled
          points={Math.round((weight - weight * score) * 100)}
          score={score}
          scorer={`${data.foundQuestionCount}/${data.questionGoalCount}`}
          scorerTooltipText={t(
            'content:side-panel-components.optimization.section.questions.scorer-tooltip-text_interval',
            {
              count: data.questionGoalCount,
              postProcess: 'interval',
            }
          )}
          // Disabled, see this comment: https://gitlab.rvip.fr/semji/semji/-/issues/2158#note_84560
          showDetailsAction={undefined}
          showExternalLinkToBackOffice={{
            title: t(
              'content:side-panel-components.optimization.section.questions.external-link-title'
            ),
            url: clusteringDetailLink,
          }}
          title={t('content:side-panel-components.optimization.section.questions.title')}
          {...props}
        />
        <DetailsBase
          description={t(
            'content:side-panel-components.optimization.section.questions.details-description',
            { keyword }
          )}
          show={this.state.showDetails}
          subTitle={t(
            'content:side-panel-components.optimization.section.questions.details-subtitle',
            { keyword }
          )}
          title={t('content:side-panel-components.optimization.section.questions.title')}
          onClose={this.closeDetails}
        >
          <Wrapper>
            <Title>
              {t('content:side-panel-components.optimization.section.questions.label-question')}
            </Title>
            <Title>
              {t('content:side-panel-components.optimization.section.questions.label-volume')}
            </Title>
          </Wrapper>
          {questions.map((questionId) => (
            <QuestionDetails key={questionId} id={questionId} />
          ))}
        </DetailsBase>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    allRecommendations: state.report.recommendations,
    contentTitle: state.content.title,
    data: state.report.recommendations[props.id]?.data,
    disabled: state.report.recommendations[props.id]?.disabled,
    html: state.content.html,
    ready: state.report.recommendations[props.id]?.ready,
    recommendationsIds: state.report.recommendations[props.id]?.recommendationsIds,
    score: state.report.recommendations[props.id]?.score,
    weight: state.report.recommendations[props.id]?.weight,
  };
};

export default withTranslation()(
  connect(mapStateToProps, {
    disableMainOptimization,
    enableMainOptimization,
  })(Questions)
);
