import './NoVersion.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'semji-core/components/Button';

import { ENUM_CONTENT_TYPE } from '@/types/contents';

export default function NoVersion({
  status,
  onCreateNewDraft,
  isCreationDisabled,
  canCreateDraft,
}: {
  status: ENUM_CONTENT_TYPE;
  onCreateNewDraft: () => void;
  isCreationDisabled?: boolean;
  canCreateDraft?: boolean;
}) {
  const { t } = useTranslation();
  function getTranslationPrefix() {
    if (status === ENUM_CONTENT_TYPE.DRAFT) {
      if (canCreateDraft) {
        return 'create-draft';
      }
      return 'no-draft';
    }
    return 'no-published-version';
  }
  return (
    <div className="version-history-panel-no_version">
      <div className="version-history-panel-no_version__title">
        {t(`content:version-history-panel.${getTranslationPrefix()}`)}
      </div>
      <div className="version-history-panel-no_version__description">
        {t(`content:version-history-panel.${getTranslationPrefix()}-desc`)}
      </div>
      {status === ENUM_CONTENT_TYPE.DRAFT && canCreateDraft && (
        <Button disabled={isCreationDisabled} onClick={onCreateNewDraft}>
          {t('content:version-history-panel.start-optimizing')}
        </Button>
      )}
    </div>
  );
}
