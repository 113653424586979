import React from 'react';

import SvgWrapper from './SvgWrapper';

const CheckboxIcon = (props) => (
  <SvgWrapper {...props}>
    <path d="M14 5.761h1v10.239h-14v-14h8.393v1h-7.393v12h12v-9.239zM4.854 8.146l-0.708 0.708 3.434 3.434 7.587-11.512-0.835-0.551-6.912 10.488-2.566-2.567z" />
  </SvgWrapper>
);

export default CheckboxIcon;
