import './RestrictionEmptyState.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import useGetWorkspaceAccessesById from '@/apis/semji/workspaceAccesses/useGetWorkspaceAccessesById';
import SearchIntelligence from '@/assets/images/search-intelligence-icon.svg';
import ExpandableUserList from '@/components/ExpandableUserList';
import { WorkspaceAccess, WorkspaceAccessRoles } from '@/types/workspace';

export default function CompetitorRestrictionEmptyState() {
  const { t } = useTranslation();

  const { data: accesses } = useGetWorkspaceAccessesById();
  const ownerList = (accesses as WorkspaceAccess[])
    .filter((wa) => wa.roles.includes(WorkspaceAccessRoles.OWNER))
    .map((item) => item.user);

  return (
    <div className="competitors-restriction-empty-state">
      <div className="competitors-restriction-empty-state__text">
        <img alt="" className="competitors-empty-state__icon" src={SearchIntelligence} />
        <h2 className="competitors-restriction-empty-state__text__title">
          {t('competitors:restriction-empty-state.title')}
        </h2>
        <p className="competitors-restriction-empty-state__text__description">
          {t('competitors:restriction-empty-state.description')}
        </p>
      </div>
      <ExpandableUserList
        emailBodyKey="competitors:restriction-empty-state.email.body"
        emailSubjectKey="competitors:restriction-empty-state.email.subject"
        users={ownerList}
      />
    </div>
  );
}
