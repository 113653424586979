import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useMatch, useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';

import { OutlinedButton } from '@/components/Button/Button';
import CrawlError from '@/components/Content/CrawlError';
import RedirectionHeader from '@/components/Content/RedirectionHeader';
import UpdateButton from '@/components/Content/UpdateButton';
import Url from '@/components/Content/Url';
import AnimatedReloadIcon from '@/components/icons/AnimatedReloadIcon';
import ZoomIn from '@/components/icons/ZoomIn';
import ZoomOut from '@/components/icons/ZoomOut';
import DefaultIframe from '@/components/Iframe/DefaultIframe';
import Iframe from '@/components/Iframe/Iframe';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import {
  CONTENT_HTML_CRAWL_ERROR,
  DEFAULT_ZOOM_LEVEL,
  MAX_ZOOM_LEVEL,
  MIN_ZOOM_LEVEL,
  ONLINE_PAGE_TAB,
  STATIC_HTML_CRAWL_ERROR,
  STEP_ZOOM_LEVEL,
  TEXT_PAGE_TAB,
} from '@/containers/Content/constants';
import BottomBar from '@/containers/Content/EditorComponents/BottomBar';
import { OnlineContextWrapper } from '@/containers/Content/Wrappers';
import GroupButtons from '@/design-system/components/Button/GroupButtons';
import TertiaryButton from '@/design-system/components/Button/TertiaryButton';
import { Loader, LoaderText } from '@/design-system/components/Loader';
import NavBarGroup from '@/design-system/components/NavBar/NavBarGroup';
import useCan from '@/hooks/useCan';
import useIsUserLimitedGuestWriter from '@/hooks/useIsUserLimitedGuestWriter';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import { setContent } from '@/store/actions/content';
import { selectCurrentWorkspaceIsStaticContentEnabled } from '@/store/selectors/selectCurrentWorkspaceIsStaticContentEnabled';

const Tootlip = styled(TooltipComponent)`
  display: flex;
  align-items: center;
`;

const Div = styled.div`
  font-size: 22px;
  font-weight: 500;
  width: 100%;
  padding: 6px 0 7px;
  color: ${({ $loading, theme }) => ($loading ? 'transparent' : theme.text.colors.light)};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const Container = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.cssColors.white};
`;

const SwitchButton = styled(TertiaryButton)`
  letter-spacing: 0;
  font-weight: 400;
`;

function PageContainer({
  createNewDraft,
  isCreatingNewDraft,
  isDraftStarted,
  refreshLivePageData,
  toEditor,
  toPageHtml,
  toPageText,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isUserLimitedGuestWriter = useIsUserLimitedGuestWriter();
  const isStaticContentEnabled = useSelector(selectCurrentWorkspaceIsStaticContentEnabled);
  const { params } = useMatch('/o/:organizationId/w/:workspaceId/p/:pageId/:tab/*');

  const { organizationId, pageId, workspaceId, tab, sidePanelTab = 'optimizations' } = params || {};
  const { isPageLoading, isRefreshLoading, page, url } = useSelector((state) => state.editor);
  const publicId = useSelector((state) => state.user['@id']);
  const {
    contentRetrievedAt,
    lastStatusCode,
    html,
    staticHtml,
    staticHtmlRetrievedAt,
    redirectionUrl,
    title,
    metaDescription,
  } = page || {};

  const [snapshotZoomLevel, setSnapshotZoomLevel] = useState(DEFAULT_ZOOM_LEVEL);

  const pageSnapshotEnabled = useCan({ perform: 'page:snapshot' });
  const { isFeatureEnabled: hasAccessToMetaDescription } = useOrganizationFeatureSet(
    'contents:meta-description'
  );

  const isSnapshotPage = isStaticContentEnabled && tab === ONLINE_PAGE_TAB;
  const isTextPage = isStaticContentEnabled
    ? tab === TEXT_PAGE_TAB
    : tab === ONLINE_PAGE_TAB || tab === TEXT_PAGE_TAB;
  const isContentNotRetrieved = contentRetrievedAt === null;
  const isStaticHtmlNotRetrieved = staticHtmlRetrievedAt === null;
  const isCrawlContentError = contentRetrievedAt !== null && !html;
  const isCrawlStaticHtmlError = staticHtmlRetrievedAt !== null && !staticHtml;
  const isCrawlError =
    (isCrawlContentError && isTextPage) || (isCrawlStaticHtmlError && isSnapshotPage);
  const isRefreshingLivePageData =
    (isContentNotRetrieved && isTextPage) ||
    (isStaticHtmlNotRetrieved && isSnapshotPage) ||
    isRefreshLoading;

  const crawlErrorType =
    (isCrawlContentError && CONTENT_HTML_CRAWL_ERROR) ||
    (isCrawlStaticHtmlError && STATIC_HTML_CRAWL_ERROR);

  useEffect(() => {
    if (!pageSnapshotEnabled && isSnapshotPage) {
      navigate(`/o/${organizationId}/w/${workspaceId}/p/${pageId}/text`, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSnapshotPage, pageSnapshotEnabled]);

  useEffect(() => {
    if (
      !isRefreshLoading &&
      ((isContentNotRetrieved && isTextPage) || (isStaticHtmlNotRetrieved && isSnapshotPage))
    ) {
      refreshLivePageData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isSnapshotPage,
    isTextPage,
    isContentNotRetrieved,
    isStaticHtmlNotRetrieved,
    isRefreshLoading,
  ]);

  useEffect(() => {
    if (!isPageLoading || !isRefreshingLivePageData) {
      dispatch(
        setContent({ html: page?.html, metaDescription: page?.metaDescription, title: page?.title })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPageLoading, isRefreshingLivePageData, page?.html, page?.title, page?.metaDescription]);

  const handleClickStartOptimizing = () => {
    navigate(`/o/${organizationId}/w/${workspaceId}/p/${pageId}/create`);
    createNewDraft(isUserLimitedGuestWriter ? publicId : null);
  };

  return (
    <Container>
      <RedirectionHeader lastStatusCode={lastStatusCode} redirectionUrl={redirectionUrl} />
      {isRefreshingLivePageData && (
        <Loader>
          <LoaderText>{t('content:page.loader-content-update')}</LoaderText>
        </Loader>
      )}
      {isCrawlError && !isRefreshLoading && (
        <CrawlError secondaryAction={refreshLivePageData} type={crawlErrorType} />
      )}
      {!isCrawlError && !isRefreshLoading && (
        <>
          {isTextPage && (
            <OnlineContextWrapper>
              <Div loading={isPageLoading} title={title}>
                {title}
              </Div>
              {hasAccessToMetaDescription && (
                <Div loading={isPageLoading} title={metaDescription}>
                  {metaDescription}
                </Div>
              )}
              <Url href={url} loading={isPageLoading}>
                {url}
              </Url>
            </OnlineContextWrapper>
          )}
          {!isPageLoading && isTextPage && <Iframe html={html} id="pageIframe" url={url} />}
          {!isPageLoading && isSnapshotPage && (
            <DefaultIframe html={staticHtml} id="staticHtmlPageIframe" zoom={snapshotZoomLevel} />
          )}
        </>
      )}
      {Boolean(url) && (
        <BottomBar>
          {isStaticContentEnabled && (
            <GroupButtons>
              <SwitchButton
                className={isSnapshotPage && 'active'}
                disabled={!pageSnapshotEnabled}
                onClick={() => !isSnapshotPage && navigate(`${toPageHtml}/${sidePanelTab}`)}
              >
                {isStaticHtmlNotRetrieved && (
                  <>
                    <AnimatedReloadIcon $isLoading viewBox="0 0 17 17" />
                    &nbsp;
                  </>
                )}
                {t('content:page.button-snapshot')}
              </SwitchButton>
              <SwitchButton
                className={isTextPage && 'active'}
                onClick={() => !isTextPage && navigate(`${toPageText}/${sidePanelTab}`)}
              >
                {isContentNotRetrieved && (
                  <>
                    <AnimatedReloadIcon $isLoading viewBox="0 0 17 17" />
                    &nbsp;
                  </>
                )}
                {t('content:page.button-text')}
              </SwitchButton>
            </GroupButtons>
          )}
          <UpdateButton isLoading={isRefreshLoading} onClick={refreshLivePageData} />
          <NavBarGroup align="center" isMain>
            {isDraftStarted ? (
              <OutlinedButton as={Link} color="primary" to={toEditor} width="200px">
                {t('content:page.button-edit-draft')}
              </OutlinedButton>
            ) : (
              <OutlinedButton
                color="primary"
                disabled={isCreatingNewDraft}
                width="200px"
                onClick={handleClickStartOptimizing}
              >
                {t('content:page.button-start-optimizing')}
              </OutlinedButton>
            )}
          </NavBarGroup>

          {isStaticContentEnabled && isSnapshotPage && (
            <NavBarGroup align="end">
              <GroupButtons margin="0">
                <TertiaryButton
                  disabled={snapshotZoomLevel >= MAX_ZOOM_LEVEL}
                  size="xl"
                  onClick={() => setSnapshotZoomLevel(snapshotZoomLevel + STEP_ZOOM_LEVEL)}
                >
                  <Tootlip title={t('content:page.button-zoom-out')}>
                    <ZoomOut />
                  </Tootlip>
                </TertiaryButton>
                <TertiaryButton
                  disabled={snapshotZoomLevel <= MIN_ZOOM_LEVEL}
                  size="xl"
                  onClick={() => setSnapshotZoomLevel(snapshotZoomLevel - STEP_ZOOM_LEVEL)}
                >
                  <Tootlip title={t('content:page.button-zoom-in')}>
                    <ZoomIn />
                  </Tootlip>
                </TertiaryButton>
              </GroupButtons>
            </NavBarGroup>
          )}
        </BottomBar>
      )}
    </Container>
  );
}

PageContainer.propTypes = {
  refreshLivePageData: PropTypes.func,
};

export default PageContainer;
