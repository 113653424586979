import PropTypes from 'prop-types';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import CreditsSettings from './CreditsSettings';

function CreditsRoutes({ title }) {
  const { pathname } = useLocation();

  return (
    <Routes>
      <Route element={<CreditsSettings title={title} />} path={`/:creditType`} />
      <Route element={<Navigate to={`${pathname}/analysis`} />} path="/" />
    </Routes>
  );
}

CreditsRoutes.propTypes = {
  title: PropTypes.string,
};

export default CreditsRoutes;
