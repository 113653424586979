import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { LOCATION, SCOPE_SERVICE_INTEGRATIONS, SCOPE_WORKSPACE } from '@/apis/semji/constants';
import usePostServiceIntegration from '@/apis/semji/serviceIntegrations/usePostServiceIntegration';
import TopePagesIcon from '@/assets/images/top-pages.svg';
import CheckIcon from '@/components/icons/CheckIcon';
import { PrimaryLink } from '@/components/Navigation/Link';
import SearchConsoleServiceIntegration from '@/containers/CreateWorkspace/Connect/SearchConsole';
import { NoPaddingParagraphText, StyledImg } from '@/containers/Onboarding/common';
import DescribeStepsComponents from '@/containers/Onboarding/DescribeSteps';
import PrimaryButton from '@/design-system/components/Button/PrimaryButton';
import { showErrorSnackbar } from '@/store/actions/ui';
import { selectCurrentWorkspaceWebsiteUrl } from '@/store/selectors/selectCurrentWorkspaceWebsiteUrl';
import { selectHasSearchConsole } from '@/store/selectors/selectHasSearchConsole';

function SearchConsole() {
  const dispatch = useDispatch();
  const i18nNameSpace = 'quick-start-guide:body.accordion.optimise-existing-content.search-console';
  const { t } = useTranslation();
  const { organizationId, workspaceId } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isSearchConsoleConnected = useSelector(selectHasSearchConsole);
  const queryClient = useQueryClient();

  const postServiceIntegration = usePostServiceIntegration({
    onError: () => dispatch(showErrorSnackbar(t('common:error.default'))),
    onSuccess: async () => {
      await queryClient.refetchQueries({
        active: true,
        exact: true,
        fetching: false,
        queryKey: [SCOPE_SERVICE_INTEGRATIONS.GET_SERVICE_INTEGRATIONS, workspaceId, LOCATION.ROOT],
      });
      await queryClient.refetchQueries({
        active: true,
        exact: true,
        fetching: false,
        queryKey: [SCOPE_WORKSPACE.GET_CURRENT_WORKSPACE, workspaceId, LOCATION.QUICK_START_GUIDE],
      });
    },
  });

  const isWorkspaceOwner = useSelector((state) =>
    state.user.workspaceAccesses
      .find((workspaceAccess) => workspaceAccess.workspace.id === state.defaultWorkspaceId)
      .roles.includes('OWNER')
  );
  const currentWorkspaceWebsiteUrl = useSelector(selectCurrentWorkspaceWebsiteUrl);

  function handleOpenModal() {
    if (!isSearchConsoleConnected) {
      setIsModalOpen(true);
    }
  }

  function handleCloseModal() {
    setIsModalOpen(false);
  }

  async function handleConfirmSearchConsole(searchConsoleData) {
    postServiceIntegration.mutate({
      data: {
        access_token: searchConsoleData.googleCredentials.accessToken,
        code: searchConsoleData.googleCredentials.code,
        email: searchConsoleData.googleCredentials.email,
        refresh_token: searchConsoleData.googleCredentials.refreshToken,
        site_url: searchConsoleData.property.siteUrl,
      },
      name: searchConsoleData.name,
      type: searchConsoleData.name,
    });
  }

  return (
    <>
      <DescribeStepsComponents
        explanatoryText={<Trans i18nKey={`${i18nNameSpace}.explanatory-text`} />}
        helper={
          <Trans
            components={{
              primaryLink: (
                <PrimaryLink
                  href={t(`${i18nNameSpace}.helper-link`)}
                  noDecoration
                  size="xs"
                  weight="medium"
                />
              ),
            }}
            i18nKey={`${i18nNameSpace}.helper-text`}
          />
        }
        render={
          <>
            <PrimaryButton
              disabled={
                !isWorkspaceOwner || isSearchConsoleConnected || postServiceIntegration.isLoading
              }
              margin="25px 0"
              weight="medium"
              onClick={handleOpenModal}
            >
              {isSearchConsoleConnected && <CheckIcon style={{ marginRight: '5px' }} />}
              {isSearchConsoleConnected
                ? t(`${i18nNameSpace}.button-label-connected`)
                : t(`${i18nNameSpace}.button-label`)}
            </PrimaryButton>
            <NoPaddingParagraphText margin="0 0 30px 0" size="sm" weight="medium">
              <Trans
                components={{
                  primaryLink: (
                    <PrimaryLink
                      href={`/o/${organizationId}/w/${workspaceId}/contents/import-pages/urls`}
                      noDecoration
                      size="sm"
                      weight="medium"
                    />
                  ),
                }}
                i18nKey={`${i18nNameSpace}.or-text`}
              />
            </NoPaddingParagraphText>
          </>
        }
        visualRendering={<StyledImg alt={t(`${i18nNameSpace}.alt-image`)} src={TopePagesIcon} />}
      />
      {isModalOpen && isWorkspaceOwner && (
        <SearchConsoleServiceIntegration
          handleClose={handleCloseModal}
          handlePrevious={null}
          workspaceWebsiteUrl={currentWorkspaceWebsiteUrl}
          onChangeSearchConsoleDataSource={handleConfirmSearchConsole}
        />
      )}
    </>
  );
}

export default SearchConsole;
