import { fetchTopKeywordReport } from '@/apis/semji/api';
import { SECTIONS } from '@/utils/log/constants';
import { Log } from '@/utils/log/Log';

export default class Report {
  constructor(html, title, keywordId) {
    this.html = html;
    this.title = title;
    this.keywordId = keywordId;
    this._report = null;
  }

  get report() {
    return this._fetchReport();
  }

  async _fetchReport() {
    if (this._report === null) {
      try {
        this._report = await fetchTopKeywordReport(this.keywordId, {
          html: this.html,
          title: this.title,
        });
      } catch (error) {
        Log.report({
          context: '_fetchReport',
          error,
          extra: 'fetch page report',
          section: SECTIONS.organization.key,
        });
      }
    }

    return this._report;
  }
}
