import {
  fetchOrganization,
  fetchOrganizationCreditsUsage,
  getSamlConfiguration,
  getSamlDomains,
  postOrganization,
  postSamlConfiguration,
  postWorkspace,
  putSamlConfiguration,
} from '@/apis/semji/api';

export default class Organization {
  constructor(id) {
    this._id = id;
    this._organization = null;
  }

  get organization() {
    return this._fetchOrganization();
  }

  get creditsUsage() {
    return this._fetchCreditsUsage();
  }

  get samlConfiguration() {
    return this._fetchSamlConfiguration(this._id);
  }

  get samlDomains() {
    return this._fetchSamlDomains(this._id);
  }

  async postSamlConfiguration(data) {
    return await postSamlConfiguration({
      organizationId: this._id,
      ...data,
    });
  }

  async putSamlConfiguration(data) {
    return await putSamlConfiguration({
      ...data,
    });
  }

  createWorkspace(workspace) {
    return postWorkspace(this._id, workspace);
  }

  createOrganization(userId, payload) {
    return postOrganization(userId, payload);
  }

  async _fetchSamlDomains() {
    try {
      const domains = await getSamlDomains(this._id);
      return Promise.resolve(domains['hydra:member']);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async _fetchSamlConfiguration() {
    try {
      const configuration = await getSamlConfiguration(this._id);
      return Promise.resolve(configuration['hydra:member']);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async _fetchCreditsUsage() {
    try {
      const credits = await fetchOrganizationCreditsUsage(this._id);
      return credits;
    } catch (e) {
      throw e;
    }
  }

  async _fetchOrganization() {
    if (this._organization === null) {
      try {
        this._organization = await fetchOrganization(this._id);
      } catch (e) {
        throw e;
      }
    }

    return this._organization;
  }
}
