import './HubMenuSelect.scss';

import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { PlacementEnum } from 'semji-core/components/Dropdown';
import {
  ENUM_MENU_SELECT_POSITION,
  ENUM_MENU_SELECT_TYPE,
  MenuSelectDropdown,
} from 'semji-core/components/MenuSelect';
import { DownIcon, ENUM_DOWN_ICON_DIRECTION } from 'semji-core/icons/DownIcon';
import { LightIcon } from 'semji-core/icons/LightIcon';
import { PencilAltIcon } from 'semji-core/icons/PencilAltIcon';

import { changeDefaultHub } from '@/store/actions/hub';
import { selectCurrentHub } from '@/store/selectors/selectCurrentHub';
import { SemjiHub } from '@/types/common.types';

export default function HubMenuSelect() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const dropDrownActionsRef = useRef({
    handleClose: () => setOpen(false),
    handleOpen: () => setOpen(true),
  });
  const dispatch = useDispatch();
  const currentHub = useSelector(selectCurrentHub);

  function handleSelect(hub: SemjiHub) {
    dispatch(changeDefaultHub(hub));
    dropDrownActionsRef.current.handleClose();
  }

  const items = [
    {
      label: t(`components:hub-menu-select.hubs`),
      position: ENUM_MENU_SELECT_POSITION.TOP,
      type: ENUM_MENU_SELECT_TYPE.TITLE,
    },
    {
      icon: (
        <LightIcon className={`hub-menu-select__item__icon hub-menu-select__item__icon--purple`} />
      ),
      id: SemjiHub.EXECUTIVE,
      label: t(`components:hub-menu-select.${SemjiHub.EXECUTIVE}`),
      onSelect: () => handleSelect(SemjiHub.EXECUTIVE),
      position: ENUM_MENU_SELECT_POSITION.DEFAULT,
      type: ENUM_MENU_SELECT_TYPE.DEFAULT,
      value: SemjiHub.EXECUTIVE,
    },
    {
      icon: (
        <PencilAltIcon
          className={`hub-menu-select__item__icon hub-menu-select__item__icon--pink`}
        />
      ),
      id: SemjiHub.CONTENT,
      label: t(`components:hub-menu-select.${SemjiHub.CONTENT}`),
      onSelect: () => handleSelect(SemjiHub.CONTENT),
      position: ENUM_MENU_SELECT_POSITION.DEFAULT,
      type: ENUM_MENU_SELECT_TYPE.DEFAULT,
      value: SemjiHub.CONTENT,
    },
  ];

  const selectedItem = items.find((item) => item.id === currentHub);

  return (
    <div className="hub-menu-select">
      <MenuSelectDropdown
        actionsRef={dropDrownActionsRef}
        anchorElement={
          <div className={`hub-menu-select__item ${open && 'hub-menu-select__item--active'}`}>
            {selectedItem?.icon}
            <span>{selectedItem?.label}</span>
            <DownIcon
              className="hub-menu-select__arrow-icon"
              direction={ENUM_DOWN_ICON_DIRECTION.DOWN}
              theme="light"
            />
          </div>
        }
        dropdownPlacement={PlacementEnum.DROPDOWN_PLACEMENT_BOTTOM_START}
        isFrame={false}
        items={items}
        itemSelected={currentHub}
        searchPlaceholder={t('components:input-search.placeholder')}
        titleTooltipCloseIcon={t('components:input-search.tooltip.title')}
        width="180px"
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      />
    </div>
  );
}
