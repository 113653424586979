import { nanoid } from 'nanoid';
import { useMemo } from 'react';

import SvgWrapper from './SvgWrapper';

function BookMarkIcon(props) {
  const svgId = useMemo(() => nanoid(), []);

  return (
    <SvgWrapper {...props} fill="none" height="17" viewBox="0 0 17 17" width="17">
      <mask
        height="16"
        id={`mask${svgId}`}
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="10"
        x="3"
        y="1"
      >
        <path
          d="M3.562 1V16.459L8.248 13.189L13 16.449V1H3.562ZM12 14.551L8.244 11.973L4.563 14.541V2H12V14.551Z"
          fill="black"
        />
      </mask>
      <g mask={`url(#mask${svgId})`}>
        <rect fill="currentColor" height="20" width="20" x="-2" y="-1" />
      </g>
    </SvgWrapper>
  );
}

export default BookMarkIcon;
