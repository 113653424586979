import styled from 'styled-components/macro';

import { InlineText } from '../../../../../components/Text/Inline';

export const Text = styled(InlineText)`
  display: flex;
  flex-direction: column;

  /* 20px on the right due to Scrollbar overlapping text */
  padding: 0.3rem 20px 0.3rem 0;
`;

export default Text;
