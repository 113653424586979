import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';

import { FlexColumnCenteredAlignContainer } from '@/components/_common';
import { RelativeLoader } from '@/components/Loader/Loader';
import { TitleThree } from '@/components/Text/Title';

const MarginTopBottomTitle = styled(TitleThree)`
  && {
    margin-top: 60px;
    margin-bottom: 15px;
  }
`;

const MarginFlexColumnCenteredAlignContainer = styled(FlexColumnCenteredAlignContainer)`
  && {
    margin: 70px;
    min-height: 500px;
    justify-content: center;
  }
`;

const RedLoader = styled(RelativeLoader)`
  && {
    color: ${({ theme }) => theme.cssColors.primary};
  }
`;

function WaitChangePlanDialog({ show, title }) {
  if (show) {
    return (
      <Dialog disableBackdropClick fullWidth maxWidth="sm" open>
        <MarginFlexColumnCenteredAlignContainer>
          <RedLoader fontSize="3rem" />
          <MarginTopBottomTitle weight="strong">{title}</MarginTopBottomTitle>
        </MarginFlexColumnCenteredAlignContainer>
      </Dialog>
    );
  }

  return null;
}

WaitChangePlanDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default WaitChangePlanDialog;
