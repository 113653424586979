import i18next from 'i18next';
import { Tooltip } from 'semji-core/components/Tooltip';
import { formatNumber } from 'semji-core/utils/numbers';
import { getRoundedNumberMetricWithSuffix } from 'semji-core/utils/numbers';
import styled from 'styled-components/macro';

import HighChartsTrend from '@/components/Chart/HighChartsTrend';
import BarChartIcon from '@/components/icons/BarChartIcon';
import BranchIcon from '@/components/icons/BranchIcon';
import BulletListIcon from '@/components/icons/BulletListIcon';
import CursorIcon from '@/components/icons/CursorIcon';
import EmptyShoppingCart from '@/components/icons/EmptyShoppingCart';
import EyeIcon from '@/components/icons/EyeIcon';
import LayoutList from '@/components/icons/LayoutList';
import LayoutMedia from '@/components/icons/LayoutMedia';
import LinkIcon from '@/components/icons/LinkIcon';
import PercentIcon from '@/components/icons/PercentIcon';
import SearchIcon from '@/components/icons/SearchIcon';
import SmallCapIcon from '@/components/icons/SmallCapIcon';
import TextIcon from '@/components/icons/TextIcon';
import FlatLoader, {
  CircularLoaderWrapper,
  DEFAULT_LOADER_HEIGHT,
  LoaderWrapper,
} from '@/components/Loader/FlatLoader';
import { Cell } from '@/components/Pages/ContentIdeas/common';
import { PENDING_STATUS } from '@/components/Pages/ContentIdeas/constant';
import CheckboxRender, {
  SELECT_ALL,
  SELECT_ONCE,
} from '@/components/Pages/ContentIdeas/Listing/CellRender/Checkbox';
import Keyword from '@/components/Pages/ContentIdeas/Listing/CellRender/Keyword';
import KeywordDifficultyRender, {
  findKeywordDifficultyToolkit,
  getKeywordDifficultyToolkits,
} from '@/components/Pages/ContentIdeas/Listing/CellRender/KeywordDifficulty';
import TitleRender from '@/components/Pages/ContentIdeas/Listing/CellRender/Title';
import AddToPlanningCell from '@/components/Planning/AddToPlanningCell';
import ArchiveCell from '@/components/Planning/ArchiveCell';
import ArchivedCell from '@/components/Planning/ArchivedCell';
import OptimizedCell from '@/components/Planning/OptimizedCell';
import PlannedCell from '@/components/Planning/PlannedCell';
import StartOptimizingCell from '@/components/Planning/StartOptimizingCell';
import Table from '@/components/Table/Table';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import Flex from '@/design-system/components/Flex';
import {
  NUMBER_TYPE_FILTER,
  SELECT_TYPE_FILTER,
  STRING_TYPE_FILTER,
} from '@/utils/filter/constants';
import { formatUrlForDisplay } from '@/utils/url';

const StyledLoaderWrapper = styled(LoaderWrapper)`
  padding: ${({ padding }) => padding};
`;
const StyledToolTip = styled(TooltipComponent)`
  && {
    min-width: 0;
  }
`;

export const BROADMATCH_VALUE = 'broadmatch';
export const RELATED_VALUE = 'related';
export const ALL_VALUE = 'all';
export const SHOPPING_VALUE = 'shopping';
export const ARTICLE_VALUE = 'article';
export const MISC_VALUE = 'misc';
export const QUESTION_VALUE = 'question';
export const BRANDED_VALUE = 'branded';
export const NOT_BRANDED_VALUE = 'not branded';
export const RANKED = 'ranked';
export const UNRANKED = 'unranked';

export const FALSE_VALUE = 'false';
export const TRUE_VALUE = 'true';

export const LISTING_VALUE = 'listing';
export const MIND_MAP_VALUE = 'mind-map';
export const SORT_TYPE = 'sortType';
export const SORT_DIRECTION = 'sortDirection';
export const SORT_DIRECTION_TYPE = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const FILTERS_LIST = [
  {
    attribute: 'title',
    icon: <SmallCapIcon opacity={0.6} />,
    labelKey: 'content-ideas:listing.filters-panel.title',
    type: STRING_TYPE_FILTER,
    uid: 'title',
  },
  {
    attribute: 'url',
    icon: <LinkIcon opacity={0.6} />,
    labelKey: 'content-ideas:listing.filters-panel.url',
    type: STRING_TYPE_FILTER,
    uid: 'url',
  },
  {
    attribute: 'urlEstimatedTraffic',
    icon: <CursorIcon opacity={0.6} />,
    labelKey: 'content-ideas:listing.filters-panel.url-estimated-traffic',
    mappingValues: (value) => ({
      labelKey: value === true ? '' : value,
      value,
    }),
    type: NUMBER_TYPE_FILTER,
    uid: 'urlEstimatedTraffic',
  },
  {
    attribute: 'keyword',
    icon: <TextIcon opacity={0.6} />,
    labelKey: 'content-ideas:listing.filters-panel.keyword',
    type: STRING_TYPE_FILTER,
    uid: 'keyword',
  },
  {
    attribute: 'searchVolume',
    icon: <SearchIcon opacity={0.6} />,
    labelKey: 'content-ideas:listing.filters-panel.search-volume',
    mappingValues: (value) => ({
      labelKey: value === true ? '' : value,
      value,
    }),
    type: NUMBER_TYPE_FILTER,
    uid: 'searchVolume',
  },
  {
    attribute: 'similarityScore',
    icon: <PercentIcon opacity={0.6} />,
    labelKey: 'content-ideas:listing.filters-panel.similarity-score',
    mappingValues: (value) => ({
      labelKey: value === true ? '' : `${value}%`,
      value,
    }),
    type: NUMBER_TYPE_FILTER,
    uid: 'similarityScore',
  },
  {
    attribute: 'rankedWorkspaceUrlPosition',
    icon: <BarChartIcon opacity={0.6} />,
    labelKey: 'content-ideas:listing.filters-panel.my-position',
    mappingValues: (value) => ({
      labelKey: value === true ? '' : value,
      value,
    }),
    type: NUMBER_TYPE_FILTER,
    uid: 'rankedWorkspaceUrlPosition',
  },
  {
    attribute: 'keywordDifficultyReferenceValue',
    icon: <EyeIcon opacity={0.6} />,
    labelKey: 'content-ideas:listing.filters-panel.keyword-difficulty',
    mappingValues: (value) => ({
      labelKey: findKeywordDifficultyToolkit(value)?.label,
      value,
    }),
    selectList: getKeywordDifficultyToolkits().map((focusKeywordValue) => ({
      labelKey: focusKeywordValue.label,
      value: focusKeywordValue.maxValue,
    })),
    type: SELECT_TYPE_FILTER,
    uid: 'keywordDifficultyReferenceValue',
  },
];

export const urlsClassificationTypeList = [
  {
    icon: null,
    isSelected: false,
    label: 'content-ideas:listing.header.filters.classification.all.label',
    tooltip: 'content-ideas:listing.header.filters.classification.all.tooltip',
    tooltipProps: {
      className: `content-ideas-listing-header__first-child`,
      label: 'content-ideas:listing.header.filters.classification.all.label',
      tooltipTitle: 'content-ideas:listing.header.filters.classification.all.tooltip',
    },
    value: ALL_VALUE,
  },
  {
    icon: <LayoutMedia size="small" />,
    isSelected: false,
    label: 'content-ideas:listing.header.filters.classification.article.label',
    tooltip: 'content-ideas:listing.header.filters.classification.article.tooltip',
    tooltipProps: {
      className: 'content-ideas-listing-header__child',
      label: 'content-ideas:listing.header.filters.classification.article.label',
      tooltipTitle: 'content-ideas:listing.header.filters.classification.article.tooltip',
    },
    value: ARTICLE_VALUE,
  },
  {
    icon: <EmptyShoppingCart size="small" />,
    isSelected: false,
    label: 'content-ideas:listing.header.filters.classification.shopping.label',
    tooltip: 'content-ideas:listing.header.filters.classification.shopping.tooltip',
    tooltipProps: {
      className: 'content-ideas-listing-header__child',
      label: 'content-ideas:listing.header.filters.classification.shopping.label',
      tooltipTitle: 'content-ideas:listing.header.filters.classification.shopping.tooltip',
    },
    value: SHOPPING_VALUE,
  },
  {
    icon: <LayoutList size="small" />,
    isSelected: false,
    label: 'content-ideas:listing.header.filters.classification.misc.label',
    tooltip: 'content-ideas:listing.header.filters.classification.misc.tooltip',
    tooltipProps: {
      className: `content-ideas-listing-header__last-child`,
      label: 'content-ideas:listing.header.filters.classification.misc.label',
      tooltipTitle: 'content-ideas:listing.header.filters.classification.misc.tooltip',
    },
    value: MISC_VALUE,
  },
];
export const brandedTypeList = [
  {
    isSelected: false,
    label: 'content-ideas:listing.header.filters.branded.all.label',
    tooltip: 'content-ideas:listing.header.filters.branded.all.tooltip',
    tooltipProps: {
      className: `content-ideas-listing-header__first-child`,
      label: 'content-ideas:listing.header.filters.branded.all.label',
      tooltipTitle: 'content-ideas:listing.header.filters.branded.all.tooltip',
    },
    value: ALL_VALUE,
  },
  {
    isSelected: false,
    label: 'content-ideas:listing.header.filters.branded.branded.label',
    tooltip: 'content-ideas:listing.header.filters.branded.branded.tooltip',
    tooltipProps: {
      className: 'content-ideas-listing-header__child',
      label: 'content-ideas:listing.header.filters.branded.branded.label',
      tooltipTitle: 'content-ideas:listing.header.filters.branded.branded.tooltip',
    },
    value: BRANDED_VALUE,
  },
  {
    isSelected: false,
    label: 'content-ideas:listing.header.filters.branded.not-branded.label',
    tooltip: 'content-ideas:listing.header.filters.branded.not-branded.tooltip',
    tooltipProps: {
      className: `content-ideas-listing-header__last-child`,
      label: 'content-ideas:listing.header.filters.branded.not-branded.label',
      tooltipTitle: 'content-ideas:listing.header.filters.branded.not-branded.tooltip',
    },
    value: NOT_BRANDED_VALUE,
  },
];
export const rankedTypeList = [
  {
    isSelected: false,
    label: 'content-ideas:listing.header.filters.ranked.all.label',
    tooltip: 'content-ideas:listing.header.filters.ranked.all.tooltip',
    tooltipProps: {
      className: `content-ideas-listing-header__first-child`,
      label: 'content-ideas:listing.header.filters.ranked.all.label',
      tooltipTitle: 'content-ideas:listing.header.filters.ranked.all.tooltip',
    },
    value: ALL_VALUE,
  },
  {
    isSelected: false,
    label: 'content-ideas:listing.header.filters.ranked.ranked.label',
    tooltip: 'content-ideas:listing.header.filters.ranked.ranked.tooltip',
    tooltipProps: {
      className: 'content-ideas-listing-header__child',
      label: 'content-ideas:listing.header.filters.ranked.ranked.label',
      tooltipTitle: 'content-ideas:listing.header.filters.ranked.ranked.tooltip',
    },

    value: RANKED,
  },
  {
    isSelected: false,
    label: 'content-ideas:listing.header.filters.ranked.not-ranked.label',
    tooltip: 'content-ideas:listing.header.filters.ranked.not-ranked.tooltip',
    tooltipProps: {
      className: `content-ideas-listing-header__last-child`,
      label: 'content-ideas:listing.header.filters.ranked.not-ranked.label',
      tooltipTitle: 'content-ideas:listing.header.filters.ranked.not-ranked.tooltip',
    },
    value: UNRANKED,
  },
];
export const keywordsTypeList = [
  {
    isSelected: false,
    label: 'content-ideas:listing.header.filters.keyword-type.all.label',
    tooltip: 'content-ideas:listing.header.filters.keyword-type.all.tooltip',
    tooltipProps: {
      className: `content-ideas-listing-header__first-child`,
      label: 'content-ideas:listing.header.filters.keyword-type.all.label',
      tooltipTitle: 'content-ideas:listing.header.filters.keyword-type.all.tooltip',
    },
    value: ALL_VALUE,
  },
  {
    isSelected: false,
    label: 'content-ideas:listing.header.filters.keyword-type.broad-match.label',
    tooltip: 'content-ideas:listing.header.filters.keyword-type.broad-match.tooltip',
    tooltipProps: {
      className: 'content-ideas-listing-header__child',
      label: 'content-ideas:listing.header.filters.keyword-type.broad-match.label',
      tooltipTitle: 'content-ideas:listing.header.filters.keyword-type.broad-match.tooltip',
    },
    value: BROADMATCH_VALUE,
  },
  {
    isSelected: false,
    label: 'content-ideas:listing.header.filters.keyword-type.related.label',
    tooltip: 'content-ideas:listing.header.filters.keyword-type.related.tooltip',
    tooltipProps: {
      className: `content-ideas-listing-header__last-child`,
      label: 'content-ideas:listing.header.filters.keyword-type.related.label',
      tooltipTitle: 'content-ideas:listing.header.filters.keyword-type.related.tooltip',
    },
    value: RELATED_VALUE,
  },
];

export const deprecatedKeywordsTypeList = [
  {
    isSelected: false,
    label: 'content-ideas:listing.header.filters.keyword-type.broad-match.label',
    tooltip: 'content-ideas:listing.header.filters.keyword-type.broad-match.tooltip',
    tooltipProps: {
      className: `content-ideas-listing-header__first-child`,
      label: 'content-ideas:listing.header.filters.keyword-type.broad-match.label',
      tooltipTitle: 'content-ideas:listing.header.filters.keyword-type.broad-match.tooltip',
    },
    value: BROADMATCH_VALUE,
  },
  {
    isSelected: false,
    label: 'content-ideas:listing.header.filters.keyword-type.related.label',
    tooltip: 'content-ideas:listing.header.filters.keyword-type.related.tooltip',
    tooltipProps: {
      className: `content-ideas-listing-header__last-child`,
      label: 'content-ideas:listing.header.filters.keyword-type.related.label',
      tooltipTitle: 'content-ideas:listing.header.filters.keyword-type.related.tooltip',
    },
    value: RELATED_VALUE,
  },
];

export const viewTypeList = [
  {
    'data-intercom-target': 'content_ideas_list_view_toggle_button',
    isSelected: false,
    tooltip: 'Listing',
    tooltipProps: {
      className: 'content-ideas-listing-header__first-child__anchor',
      label: 'content-ideas:listing.header.selectors.list.label',
      tooltipIcon: <BulletListIcon />,
      tooltipTitle: 'content-ideas:listing.header.selectors.list.tooltip',
    },

    value: LISTING_VALUE,
  },
  {
    'data-intercom-target': 'content_ideas_mindmap_view_toggle_button',
    isSelected: false,
    tooltip: 'Mindmap',
    tooltipProps: {
      className: 'content-ideas-listing-header__last-child__anchor',
      label: 'content-ideas:listing.header.selectors.mindmap.label',
      tooltipIcon: <BranchIcon />,
      tooltipTitle: 'content-ideas:listing.header.selectors.mindmap.tooltip',
    },
    value: MIND_MAP_VALUE,
  },
];

export function TableHeader({ title, description, ...props }) {
  return (
    <StyledToolTip description={description} title={title}>
      <Cell {...props} />
    </StyledToolTip>
  );
}

export function getColumns(
  handleSelectContentIdeas,
  contentIdeasClusters,
  contentIdeasList,
  selectedIds,
  handleOpenAddToPlanningModal,
  handleOpenStartOptimizingModal,
  handleRemoveFromPlanningDialog,
  handleOpenEditDraftModal,
  keywordType,
  pages,
  archiveContentIdeas,
  userLanguageCode,
  isSearchDeprecated,
  status,
  contentIdeasKeyword,
  setQueryParams
) {
  const i18nNameSpace = 'content-ideas:listing.table.header';
  const isBroadMatch = keywordType === BROADMATCH_VALUE;
  const isByRelevance =
    isBroadMatch || !isSearchDeprecated ? null : i18next.t(`${i18nNameSpace}.by-relevance`);

  const contentIdeasTitle = i18next.t(
    isSearchDeprecated
      ? `${i18nNameSpace}.content-ideas-deprecated.label`
      : `${i18nNameSpace}.content-ideas.label`,
    {
      isByRelevance,
    }
  );

  function openAddToPlanningModal(ids) {
    return () => {
      handleOpenAddToPlanningModal(ids);
    };
  }

  function openStartOptimizingModal(ids) {
    return () => {
      handleOpenStartOptimizingModal(ids);
    };
  }

  function openEditDraftModal(id) {
    return () => {
      return handleOpenEditDraftModal(id);
    };
  }

  function openRemoveFromPlanningModal(ids) {
    return () => {
      handleRemoveFromPlanningDialog(ids);
    };
  }

  function handleArchive({ contentIdeasIds, isArchived }) {
    return () => {
      archiveContentIdeas.mutate({ contentIdeasIds, isArchived });
    };
  }

  function onClick(e) {
    e.stopPropagation();
  }

  return [
    {
      align: 'center',
      cellRenderer: ({ rowData }) =>
        rowData.isSkeletonData || rowData.computing ? (
          <StyledLoaderWrapper height={20} width={20}>
            <FlatLoader />
          </StyledLoaderWrapper>
        ) : (
          <CheckboxRender
            handleSelect={handleSelectContentIdeas}
            id={rowData.id}
            selectedIds={selectedIds}
            type={SELECT_ONCE}
          />
        ),
      dataKey: 'select',
      headerCellRenderer: () =>
        !status || status === PENDING_STATUS ? (
          <Flex justifyContent="center">
            <StyledLoaderWrapper height={20} width={20}>
              <FlatLoader />
            </StyledLoaderWrapper>
          </Flex>
        ) : (
          <CheckboxRender
            handleSelect={handleSelectContentIdeas}
            list={contentIdeasList}
            selectedIds={selectedIds}
            type={SELECT_ALL}
          />
        ),
      label: '',
      sortable: false,
      width: 60,
    },
    {
      cellDataGetter: ({ rowData }) => ({
        contentIdeaClusterIds: rowData.contentIdeaClusterIds,
        contentIdeasClusters: contentIdeasClusters,
        loading: rowData.isSkeletonData,
        relevance: rowData.relevance,
        title: rowData.title,
        titleLabel: rowData.titleLabel,
        titleRetrievedAt: rowData.titleRetrievedAt,
        url: rowData.url,
        urlCategory: rowData.urlCategory,
        urlClassifiedAt: rowData.urlClassifiedAt,
        urlWordsCount: rowData.urlWordsCount,
        urlWordsCountRetrievedAt: rowData.urlWordsCountRetrievedAt,
      }),
      cellRenderer: ({ cellData }) =>
        cellData.loading ? (
          <Flex flexDirection="column" gap="6px" height="35px">
            <StyledLoaderWrapper height={DEFAULT_LOADER_HEIGHT} isFlex width={400}>
              <FlatLoader />
            </StyledLoaderWrapper>
            <StyledLoaderWrapper height={DEFAULT_LOADER_HEIGHT} isFlex width={200}>
              <FlatLoader />
            </StyledLoaderWrapper>
          </Flex>
        ) : (
          <TitleRender
            contentIdeaClusterIds={cellData.contentIdeaClusterIds}
            contentIdeasClusters={cellData.contentIdeasClusters}
            title={cellData.title}
            titleLabel={cellData.titleLabel}
            titleRetrievedAt={cellData.titleRetrievedAt}
            url={cellData.url}
            urlCategory={cellData.urlCategory}
            urlClassifiedAt={cellData.urlClassifiedAt}
            urlWordsCount={cellData.urlWordsCount}
            urlWordsCountRetrievedAt={cellData.urlWordsCountRetrievedAt}
          />
        ),
      customizableHeader: true,
      dataKey: 'title',
      flexGrow: 10,
      label: (
        <TableHeader
          color="dark060"
          description={i18next.t(
            isSearchDeprecated
              ? `${i18nNameSpace}.content-ideas-deprecated.description`
              : `${i18nNameSpace}.content-ideas.description`
          )}
          noPadding
          size="sm"
          title={i18next.t(`${i18nNameSpace}.content-ideas.title`)}
          weight="strong"
        >
          {contentIdeasTitle}
        </TableHeader>
      ),
      sortFunction: (value1, value2, sortDirection) => {
        setQueryParams({ [SORT_DIRECTION]: sortDirection, [SORT_TYPE]: 'title' });
        return Table.stringSort(value1.title, value2.title, sortDirection);
      },
      sortable: true,
      width: 500,
    },
    {
      align: 'center',
      cellDataGetter: ({ rowData }) => ({
        // Fix similarity rate to 100% when the searchKeyword equals to focus keyword
        similarityScore: rowData.keyword === contentIdeasKeyword ? 100 : rowData.similarityScore,
        similarityScoreComputedAt: rowData.similarityScoreComputedAt,
      }),
      cellRenderer: ({ cellData }) =>
        cellData.loading || !cellData.similarityScoreComputedAt ? (
          <StyledLoaderWrapper height={DEFAULT_LOADER_HEIGHT} isFlex>
            <FlatLoader />
          </StyledLoaderWrapper>
        ) : (
          <Cell color="dark060" noPadding size="sm" weight="medium" onClick={onClick}>
            {cellData.similarityScore !== null ? `${cellData.similarityScore}%` : '-'}
          </Cell>
        ),
      dataKey: 'similarityScore',
      hide: isSearchDeprecated,
      label: (
        <TableHeader
          color="dark060"
          data-intercom-target="similarity-score-content-idea-th"
          description={i18next.t(`${i18nNameSpace}.similarity-score.description`)}
          noPadding
          size="sm"
          title={i18next.t(`${i18nNameSpace}.similarity-score.title`)}
          weight="strong"
        >
          {i18next.t(`${i18nNameSpace}.similarity-score.label`)}
        </TableHeader>
      ),
      sortFunction: (value1, value2, sortDirection) => {
        setQueryParams({ [SORT_DIRECTION]: sortDirection, [SORT_TYPE]: 'similarityScore' });
        return Table.naturalSort(value1.similarityScore, value2.similarityScore, sortDirection);
      },
      sortable: true,
      width: 190,
    },
    {
      align: 'center',
      cellDataGetter: ({ rowData }) => ({
        computing: rowData.computing,
        loading: rowData.isSkeletonData,
        urlEstimatedTraffic: rowData.urlEstimatedTraffic,
      }),
      cellRenderer: ({ cellData }) =>
        (cellData.loading || cellData.computing) && !cellData.urlEstimatedTraffic ? (
          <StyledLoaderWrapper height={DEFAULT_LOADER_HEIGHT} isFlex padding="15px">
            <FlatLoader />
          </StyledLoaderWrapper>
        ) : (
          <TableHeader
            color="dark060"
            description={formatNumber({ number: cellData.urlEstimatedTraffic, returnValue: 'n/a' })}
            noPadding
            size="sm"
            title={i18next.t(`${i18nNameSpace}.traffic.label`)}
            weight="medium"
            onClick={onClick}
          >
            {cellData.urlEstimatedTraffic !== null
              ? getRoundedNumberMetricWithSuffix({
                  locale: userLanguageCode,
                  number: cellData.urlEstimatedTraffic,
                })
              : '-'}
          </TableHeader>
        ),
      dataKey: 'urlEstimatedTraffic',
      label: (
        <TableHeader
          color="dark060"
          description={i18next.t(`${i18nNameSpace}.traffic.description`)}
          noPadding
          size="sm"
          title={i18next.t(`${i18nNameSpace}.traffic.title`)}
          weight="strong"
        >
          {i18next.t(`${i18nNameSpace}.traffic.label`)}
        </TableHeader>
      ),
      sortFunction: (value1, value2, sortDirection) => {
        setQueryParams({ [SORT_DIRECTION]: sortDirection, [SORT_TYPE]: 'urlEstimatedTraffic' });
        return Table.naturalSort(
          value1.urlEstimatedTraffic,
          value2.urlEstimatedTraffic,
          sortDirection
        );
      },
      sortable: true,
      width: 100,
    },
    {
      cellDataGetter: ({ rowData }) => ({
        associatedKeyword: pages.filter(
          (page) => page?.focusTopKeyword?.keyword === (rowData.keyword && !page.inStock)
        ),
        hasValues: rowData.keyword && rowData.keywordLabel,
        keyword: rowData.keyword,
        keywordLabel: rowData.keywordLabel,
        loading: rowData.isSkeletonData,
      }),
      cellRenderer: ({ cellData }) =>
        cellData.hasValues ? (
          <Keyword
            associatedKeyword={cellData.associatedKeyword}
            keyword={cellData.keyword}
            keywordLabel={cellData.keywordLabel}
          />
        ) : (
          <StyledLoaderWrapper height={DEFAULT_LOADER_HEIGHT} isFlex>
            <FlatLoader />
          </StyledLoaderWrapper>
        ),
      dataKey: 'keyword',
      flexGrow: 1,
      label: (
        <TableHeader
          color="dark060"
          description={i18next.t(
            isSearchDeprecated
              ? `${i18nNameSpace}.focus-keyword-deprecated.description`
              : `${i18nNameSpace}.focus-keyword.description`
          )}
          noPadding
          size="sm"
          title={i18next.t(
            isSearchDeprecated
              ? `${i18nNameSpace}.focus-keyword-deprecated.title`
              : `${i18nNameSpace}.focus-keyword.title`
          )}
          weight="strong"
        >
          {i18next.t(
            isSearchDeprecated
              ? `${i18nNameSpace}.focus-keyword-deprecated.label`
              : `${i18nNameSpace}.focus-keyword.label`
          )}
        </TableHeader>
      ),
      sortFunction: (value1, value2, sortDirection) => {
        setQueryParams({ [SORT_DIRECTION]: sortDirection, [SORT_TYPE]: 'keyword' });
        return Table.stringSort(value1.keyword, value2.keyword, sortDirection);
      },
      sortable: true,
      width: 250,
    },
    {
      align: 'center',
      cellDataGetter: ({ rowData }) => ({
        computed: !!rowData.keywordDataUpdatedAt,
        loading: rowData.isSkeletonData,
        searchVolume: rowData.searchVolume,
      }),
      cellRenderer: ({ cellData }) =>
        cellData.searchVolume || cellData.computed ? (
          <TableHeader
            color="dark060"
            description={formatNumber({ number: cellData.searchVolume, returnValue: 'n/a' })}
            noPadding
            size="sm"
            title={i18next.t(`${i18nNameSpace}.volume.label`)}
            weight="medium"
            onClick={onClick}
          >
            {cellData.searchVolume !== null
              ? getRoundedNumberMetricWithSuffix({
                  locale: userLanguageCode,
                  number: cellData.searchVolume,
                })
              : '-'}
          </TableHeader>
        ) : (
          <StyledLoaderWrapper height={DEFAULT_LOADER_HEIGHT} isFlex padding="15px">
            <FlatLoader />
          </StyledLoaderWrapper>
        ),
      dataKey: 'searchVolume',
      label: (
        <TableHeader
          color="dark060"
          description={i18next.t(`${i18nNameSpace}.volume.description`)}
          noPadding
          size="sm"
          title={i18next.t(`${i18nNameSpace}.volume.title`)}
          weight="strong"
        >
          {i18next.t(`${i18nNameSpace}.volume.label`)}
        </TableHeader>
      ),
      sortFunction: (value1, value2, sortDirection) => {
        setQueryParams({ [SORT_DIRECTION]: sortDirection, [SORT_TYPE]: 'searchVolume' });
        return Table.naturalSort(value1.searchVolume, value2.searchVolume, sortDirection);
      },
      sortable: true,
      width: 80,
    },
    {
      align: 'center',
      cellDataGetter: ({ rowData }) => ({
        computing: rowData.computing,
        loading: rowData.isSkeletonData,
        monthlySearchesTrend: rowData.monthlySearchesTrend?.map((trend) => trend['search_volume']),
      }),
      cellRenderer: ({ cellData }) =>
        (cellData.loading || cellData.computing) && cellData.monthlySearchesTrend === undefined ? (
          <StyledLoaderWrapper height={DEFAULT_LOADER_HEIGHT} isFlex padding="15px">
            <FlatLoader />
          </StyledLoaderWrapper>
        ) : cellData.monthlySearchesTrend.length === 0 ? (
          <StyledLoaderWrapper height={DEFAULT_LOADER_HEIGHT} isFlex padding="15px">
            <FlatLoader />
          </StyledLoaderWrapper>
        ) : 0 === cellData.monthlySearchesTrend?.length ? (
          <Cell color="dark060" noPadding size="sm" weight="medium" onClick={onClick}>
            -
          </Cell>
        ) : (
          <HighChartsTrend series={cellData.monthlySearchesTrend} />
        ),
      dataKey: 'trends',
      label: (
        <TableHeader
          color="dark060"
          description={i18next.t(`${i18nNameSpace}.trend.description`)}
          noPadding
          size="sm"
          title={i18next.t(`${i18nNameSpace}.trend.title`)}
          weight="strong"
        >
          {i18next.t(`${i18nNameSpace}.trend.label`)}
        </TableHeader>
      ),
      sortable: false,
      width: 100,
    },
    {
      align: 'center',
      cellDataGetter: ({ rowData }) => ({
        hasValues: rowData.rankedWorkspaceUrlTitle || rowData.rankedWorkspaceUrl,
        monthlySearchesTrend: rowData.monthlySearchesTrend?.map((trend) => trend['search_volume']),
        rankedWorkspaceUrl: rowData.rankedWorkspaceUrl,
        rankedWorkspaceUrlPosition: rowData.rankedWorkspaceUrlPosition,
        rankedWorkspaceUrlTitle: rowData.rankedWorkspaceUrlTitle,
      }),
      cellRenderer: ({ cellData }) =>
        status === PENDING_STATUS ? (
          <StyledLoaderWrapper height={DEFAULT_LOADER_HEIGHT} isFlex padding="15px">
            <FlatLoader />
          </StyledLoaderWrapper>
        ) : cellData.rankedWorkspaceUrlTitle || cellData.rankedWorkspaceUrl ? (
          <TableHeader
            color="dark060"
            description={formatUrlForDisplay(cellData.rankedWorkspaceUrl)}
            noPadding
            size="sm"
            title={cellData.rankedWorkspaceUrlTitle || i18next.t('common:labels.unknown-title')}
            weight="medium"
            onClick={onClick}
          >
            {cellData.rankedWorkspaceUrlPosition > 0 ? cellData.rankedWorkspaceUrlPosition : '-'}
          </TableHeader>
        ) : (
          <Cell color="dark060" noPadding size="sm" weight="medium" onClick={onClick}>
            {cellData.rankedWorkspaceUrlPosition > 0 ? cellData.rankedWorkspaceUrlPosition : '-'}
          </Cell>
        ),
      dataKey: 'rankedWorkspaceUrlPosition',
      label: (
        <TableHeader
          color="dark060"
          description={i18next.t(`${i18nNameSpace}.position.description`)}
          noPadding
          size="sm"
          title={i18next.t(`${i18nNameSpace}.position.title`)}
          weight="strong"
        >
          {i18next.t(`${i18nNameSpace}.position.label`)}
        </TableHeader>
      ),
      sortFunction: (value1, value2, sortDirection) =>
        Table.naturalSort(
          value1.rankedWorkspaceUrlPosition,
          value2.rankedWorkspaceUrlPosition,
          sortDirection
        ),
      sortable: true,
      width: 80,
    },
    {
      cellDataGetter: ({ rowData }) => ({
        computed: !!rowData.keywordDifficultyUpdatedAt,
        keywordDifficulty: rowData.keywordDifficultyUpdatedAt
          ? rowData.keywordDifficultyReferenceValue
          : null,
        loading: rowData.isSkeletonData,
      }),
      cellRenderer: ({ cellData }) =>
        cellData.loading || !cellData.computed ? (
          <StyledLoaderWrapper height={DEFAULT_LOADER_HEIGHT} isFlex>
            <FlatLoader />
          </StyledLoaderWrapper>
        ) : (
          <KeywordDifficultyRender keywordDifficulty={cellData.keywordDifficulty} />
        ),
      dataKey: 'keywordDifficultyReferenceValue',
      label: (
        <TableHeader
          color="dark060"
          description={i18next.t(`${i18nNameSpace}.keyword-difficulty.description`)}
          noPadding
          size="sm"
          title={i18next.t(`${i18nNameSpace}.keyword-difficulty.title`)}
          weight="strong"
        >
          {i18next.t(`${i18nNameSpace}.keyword-difficulty.label`)}
        </TableHeader>
      ),
      sortFunction: (value1, value2, sortDirection) => {
        setQueryParams({
          [SORT_DIRECTION]: sortDirection,
          [SORT_TYPE]: 'keywordDifficultyReferenceValue',
        });
        return Table.naturalSort(value1.keywordDifficulty, value2.keywordDifficulty, sortDirection);
      },
      sortable: true,
      width: 150,
    },
    {
      align: 'center',
      cellDataGetter: ({ rowData }) => ({
        computing: rowData.computing,
        id: rowData.id,
        loading: rowData.isSkeletonData,
        plannedContentIds: rowData.plannedContentIds,
      }),
      cellRenderer: ({ cellData }) =>
        cellData.loading || cellData.computing ? (
          <CircularLoaderWrapper height={28} width={28}>
            <FlatLoader />
          </CircularLoaderWrapper>
        ) : cellData.plannedContentIds.length > 0 ? (
          <PlannedCell onDelete={openRemoveFromPlanningModal(cellData.plannedContentIds)} />
        ) : (
          <AddToPlanningCell onAdd={openAddToPlanningModal([cellData.id])} />
        ),
      dataKey: 'planning',
      label: (
        <TableHeader
          color="dark060"
          description={i18next.t(`${i18nNameSpace}.planning.description`)}
          noPadding
          size="sm"
          title={i18next.t(`${i18nNameSpace}.planning.title`)}
          weight="strong"
        >
          {i18next.t(`${i18nNameSpace}.planning.label`)}
        </TableHeader>
      ),
      width: 60,
    },
    {
      align: 'center',
      cellRenderer: ({ rowData }) =>
        rowData.isSkeletonData || rowData.computing ? (
          <CircularLoaderWrapper height={28} width={28}>
            <FlatLoader />
          </CircularLoaderWrapper>
        ) : rowData.plannedContentIds.length > 0 ? (
          <OptimizedCell handleEditDraftClick={openEditDraftModal(rowData.id)} />
        ) : (
          <StartOptimizingCell onAdd={openStartOptimizingModal([rowData.id])} />
        ),
      dataKey: 'startOptimizing',
      label: '',
      width: 60,
    },
    {
      align: 'center',
      cellRenderer: ({ rowData }) =>
        rowData.isSkeletonData || rowData.computing ? (
          <CircularLoaderWrapper height={28} width={28}>
            <FlatLoader />
          </CircularLoaderWrapper>
        ) : rowData.isArchived ? (
          <ArchivedCell
            onClick={handleArchive({ contentIdeasIds: [rowData.id], isArchived: false })}
          />
        ) : (
          <ArchiveCell
            onClick={handleArchive({ contentIdeasIds: [rowData.id], isArchived: true })}
          />
        ),
      dataKey: 'archive',
      label: '',
      width: 60,
    },
  ];
}
