import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

import EmptyStateCourbe from '@/assets/images/empty-state-courbe.svg';
import EmptyStateCourbeLongue from '@/assets/images/empty-state-courbe-longue.svg';
import {
  EmptyStatePattern,
  SimpleBodyEmptyState,
  StyledCalendarIcon,
  WrapperWithBackground,
} from '@/components/EmptyState/commons';
import EmptyPublishedIcon from '@/components/icons/EmptyPublishedIcon';
import { EditorEmptyBodyWrapper } from '@/containers/Content/Wrappers';

export const NO_DRAFT_DASHBOARD = 'noDraftDashboard';
export const NO_NEW_DRAFT_DASHBOARD = 'noNewDraftDashboard';
export const NO_UPDATED_DRAFT_DASHBOARD = 'noUpdatedDraftDashboard';
export const NO_NEW_DRAFT_REPORT = 'noNewDraftReport';
export const NO_UPDATED_DRAFT_REPORT = 'noUpdatedDraftReport';
export const NO_DRAFT_ASSIGNED = 'noDraftAssigned';
export const NO_DRAFT_FOLDER = 'noDraftFolder';
export const NO_DRAFT_PUBLISHED = 'noDraftPublished';

const NoDraft = ({ version, folderName = undefined }) => {
  const { t } = useTranslation();
  const { organizationId, workspaceId } = useParams();
  const [query] = useSearchParams();

  const NO_DRAFT_DASHBOARD_VALUES = {
    [NO_DRAFT_ASSIGNED]: {
      buttonLabel: t('empty-state:no-draft.no-draft-assigned.button'),
      href: `/o/${organizationId}/w/${workspaceId}/planning/create-drafts`,
      image: <StyledCalendarIcon />,
      text: t('empty-state:no-draft.no-draft-assigned.body'),
      title: t('empty-state:no-draft.no-draft-assigned.title'),
    },
    [NO_DRAFT_DASHBOARD]: {
      buttonLabel: t('empty-state:no-draft.no-draft-dashboard.button'),
      href: `/o/${organizationId}/w/${workspaceId}/planning/create-drafts`,
      image: <StyledCalendarIcon />,
      title: t('empty-state:no-draft.no-draft-dashboard.title'),
    },
    [NO_DRAFT_FOLDER]: {
      buttonLabel: t('empty-state:no-draft.no-draft-folder.button'),
      href: `/o/${organizationId}/w/${workspaceId}/planning/create-drafts?${query.toString()}`,
      image: <StyledCalendarIcon />,
      text: t('empty-state:no-draft.no-draft-folder.body'),
      title: folderName
        ? t('empty-state:no-draft.no-draft-folder.title-name', { folderName })
        : t('empty-state:no-draft.no-draft-folder.title'),
    },
    [NO_DRAFT_PUBLISHED]: {
      image: <EmptyPublishedIcon />,
      text: t('empty-state:no-draft.no-draft-published.body'),
      title: t('empty-state:no-draft.no-draft-published.title'),
    },
    [NO_NEW_DRAFT_DASHBOARD]: {
      buttonLabel: t('empty-state:no-draft.no-new-draft-dashboard.button'),
      href: `/o/${organizationId}/w/${workspaceId}/planning/create-drafts/new`,
      imageUrl: EmptyStateCourbe,
      title: t('empty-state:no-draft.no-new-draft-dashboard.title'),
    },
    [NO_NEW_DRAFT_REPORT]: {
      buttonLabel: t('empty-state:no-draft.no-new-draft-report.button'),
      href: `/o/${organizationId}/w/${workspaceId}/planning/create-drafts/new`,
      imageUrl: EmptyStateCourbeLongue,
      title: t('empty-state:no-draft.no-new-draft-report.title'),
    },
    [NO_UPDATED_DRAFT_DASHBOARD]: {
      buttonLabel: t('empty-state:no-draft.no-updated-draft-dashboard.button'),
      href: `/o/${organizationId}/w/${workspaceId}/planning/create-drafts/updated`,
      imageUrl: EmptyStateCourbe,
      title: t('empty-state:no-draft.no-updated-draft-dashboard.title'),
    },
    [NO_UPDATED_DRAFT_REPORT]: {
      buttonLabel: t('empty-state:no-draft.no-new-updated-report.button'),
      href: `/o/${organizationId}/w/${workspaceId}/planning/create-drafts/updated`,
      imageUrl: EmptyStateCourbeLongue,
      title: t('empty-state:no-draft.no-new-updated-report.title'),
    },
  };

  if (version === NO_DRAFT_DASHBOARD) {
    return (
      <EditorEmptyBodyWrapper>
        {NO_DRAFT_DASHBOARD_VALUES[version]?.image}
        <SimpleBodyEmptyState
          buttonLabel={NO_DRAFT_DASHBOARD_VALUES[version]?.buttonLabel}
          title={NO_DRAFT_DASHBOARD_VALUES[version]?.title}
          url={NO_DRAFT_DASHBOARD_VALUES[version]?.href}
        />
      </EditorEmptyBodyWrapper>
    );
  }
  if (
    version === NO_NEW_DRAFT_DASHBOARD ||
    version === NO_UPDATED_DRAFT_DASHBOARD ||
    version === NO_NEW_DRAFT_REPORT ||
    version === NO_UPDATED_DRAFT_REPORT
  ) {
    return (
      <WrapperWithBackground imageUrl={NO_DRAFT_DASHBOARD_VALUES[version]?.imageUrl}>
        <SimpleBodyEmptyState
          buttonLabel={NO_DRAFT_DASHBOARD_VALUES[version]?.buttonLabel}
          title={NO_DRAFT_DASHBOARD_VALUES[version]?.title}
          url={NO_DRAFT_DASHBOARD_VALUES[version]?.href}
        />
      </WrapperWithBackground>
    );
  }
  if (
    version === NO_DRAFT_ASSIGNED ||
    version === NO_DRAFT_FOLDER ||
    version === NO_DRAFT_PUBLISHED
  ) {
    return (
      <EmptyStatePattern
        buttonLabel={NO_DRAFT_DASHBOARD_VALUES[version]?.buttonLabel}
        icon={NO_DRAFT_DASHBOARD_VALUES[version]?.image}
        text={NO_DRAFT_DASHBOARD_VALUES[version]?.text}
        title={NO_DRAFT_DASHBOARD_VALUES[version]?.title}
        url={NO_DRAFT_DASHBOARD_VALUES[version]?.href}
      />
    );
  }
  return (
    <EmptyStatePattern
      buttonLabel={t('empty-state:no-draft.default.button')}
      icon={<StyledCalendarIcon />}
      text={<Trans i18nKey={'empty-state:no-draft.default.body'} />}
      title={t('empty-state:no-draft.default.title')}
      url={`/o/${organizationId}/w/${workspaceId}/planning/create-drafts`}
    />
  );
};

export default NoDraft;
