import upperFirst from 'lodash/upperFirst';

import { COOKIE_IMPERSONATE_KEY_NAME, getCookie } from '@/utils/cookies/cookies';

function canPushData(user) {
  const userImpersonate = getCookie(COOKIE_IMPERSONATE_KEY_NAME);

  return user.email && !userImpersonate && !user.superAdmin;
}

export const HUBSPOT_ID = import.meta.env.VITE_REACT_APP_HUBSPOT_ID;

export function generateHubspotScript() {
  return (
    <script
      async
      defer
      id="hs-script-loader"
      src={'//js-eu1.hs-scripts.com/' + HUBSPOT_ID + '.js'}
      type="text/javascript"
    ></script>
  );
}

export function identifyUser(user, organization = {}) {
  if (!canPushData(user)) {
    return;
  }

  let identifyData = {
    app_first_name: upperFirst(user.firstName),
    app_last_login_date: new Date(user.lastLoginAt).getTime(),
    app_last_name: upperFirst(user.lastName),
    app_phone_number: user.phoneNumber,
    app_user_created_at: new Date(user.createdAt).getTime(),
    app_user_language: user.languageCode,
    email: user.email,
  };

  if (organization?.id) {
    identifyData = {
      ...identifyData,
      app_current_organization_name: organization.name,
      app_current_organization_plan: organization.planName,
      app_current_organization_public_id: organization.id,
      app_current_organization_role: user.organizationAccesses?.find(
        (oa) => oa.organization.id === organization.id
      )?.roles?.[0],
      app_current_organization_type:
        organization.subscriptionStatus ?? organization.subscriptionType,
    };
  }

  let _hsq = (window._hsq = window._hsq || []);
  _hsq.push(['identify', identifyData]);
  _hsq.push(['trackPageView']);
}

export function loadChatWidget(email, token) {
  window.hsConversationsSettings = {
    identificationEmail: email,
    identificationToken: token,
  };

  if (!window.HubSpotConversations) {
    return;
  }

  const status = window.HubSpotConversations.widget.status();

  if (status.loaded) {
    window.HubSpotConversations.widget.refresh();
  } else {
    window.HubSpotConversations.widget.load();
  }
  window.HubSpotConversations.clear({ resetWidget: true });
}

export function removeChatWidget() {
  window.hsConversationsSettings = {};
  if (!window.HubSpotConversations) {
    return;
  }

  window.HubSpotConversations.clear();
  window.HubSpotConversations.widget.remove({ resetWidget: true });
}

export function notifyUrlUpdate(user, url) {
  if (!canPushData(user)) {
    return;
  }

  let _hsq = (window._hsq = window._hsq || []);
  _hsq.push(['setPath', url]);
  _hsq.push(['trackPageView']);
}
