import { nanoid } from 'nanoid';
import React, { useMemo } from 'react';

import SvgWrapper from './SvgWrapper';

const SidePanelOutlineIcon = (props) => {
  const maskId = useMemo(() => nanoid(), []);
  return (
    <SvgWrapper {...props} fill="none" height="20" viewBox="0 0 18 20" width="18">
      <mask
        height="14"
        id={`mask${maskId}`}
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="18"
        x="0"
        y="2"
      >
        <path
          d="M15.8824 4.73532H6.35294V3.6765H15.8824V4.73532ZM6.35294 5.79415V6.85297H18V5.79415H6.35294ZM0 2.61768H5.29412V7.91179H0V2.61768ZM1.05882 6.85297H4.23529V3.6765H1.05882V6.85297ZM15.8824 11.0883H6.35294V12.1471H15.8824V11.0883ZM6.35294 14.2647H18V13.2059H6.35294V14.2647ZM0 10.0294H5.29412V15.3236H0V10.0294ZM1.05882 14.2647H4.23529V11.0883H1.05882V14.2647Z"
          fill="black"
        />
      </mask>
      <g mask={`url(#mask${maskId})`}>
        <rect fill="currentColor" height="21.1765" width="21.1765" x="-2.11719" y="-0.558838" />
      </g>
    </SvgWrapper>
  );
};

export default SidePanelOutlineIcon;
