import MenuItem from '@material-ui/core/MenuItem';
import groupBy from 'lodash/groupBy';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { DefaultMenu } from '@/components/_common';
import { TextButtonLink } from '@/components/Button/Button';

const IconFilterWrapper = styled.div`
  margin: 5px 10px 0 0;
`;

const IconLabelWrapper = styled.div``;
const Group = styled.div`
  border-top: 1px solid ${({ theme }) => theme.cssColors.dark020};
  padding: 16px 16px 10px 16px;
  color: ${({ theme }) => theme.cssColors.dark040};
  font-size: ${({ theme }) => theme.textCss.sizes.sm};
  text-transform: uppercase;
`;

// Filter MENU
function FilterSelectorMenu({ buttonLabel, menuItems, onClick, size }) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleSelect(item) {
    return () => {
      onClick(item);
      handleClose();
    };
  }

  // undefined group first always
  // the undefined group is a string
  const groupedItems = groupBy(menuItems, (item) => item.group);

  return (
    <div>
      <TextButtonLink
        aria-controls="filter-selector-menu"
        aria-haspopup="true"
        data-intercom-target="filter_selector_menu"
        size={size}
        weight="medium"
        onClick={handleClick}
      >
        {buttonLabel}
      </TextButtonLink>
      <DefaultMenu
        anchorEl={anchorEl}
        id="filter-selector-menu"
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* TODO: refacto this part because we are cmparing traduction keys or
         depending on the case.
        We have to wait all parts to be migrated.  */}
        {Object.keys(groupedItems).map((group) => (
          <div key={group}>
            {/* due to usage of groupBy from lodash, the elements with an undefined group are grouped under the key "undefined" => that's why we are comparing group to undefined as a string here */}
            {group !== 'undefined' && <Group>{t(group)}</Group>}
            {groupedItems[group]
              .sort((a, b) => t(a.labelKey).localeCompare(t(b.labelKey)))
              .map((item) => (
                <MenuItem key={item.labelKey} onClick={handleSelect(item)}>
                  <IconFilterWrapper>{item.icon}</IconFilterWrapper>
                  <IconLabelWrapper>{t(item.labelKey)}</IconLabelWrapper>
                </MenuItem>
              ))}
          </div>
        ))}
      </DefaultMenu>
    </div>
  );
}

export default FilterSelectorMenu;
