import styled from 'styled-components/macro';

import WarningAltIcon from '@/components/icons/WarningAltIcon';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import Flex from '@/design-system/components/Flex/Flex';

const WarningIcon = styled(WarningAltIcon)`
  && {
    color: ${({ theme }) => theme.cssColors.dark060};
    font-size: ${({ theme }) => theme.textCss.sizes.md};
  }
`;

function NotTrackedCell({ label }) {
  return (
    <Flex justifyContent="center" position="relative">
      <TooltipComponent title={label}>
        <WarningIcon />
      </TooltipComponent>
    </Flex>
  );
}

export default NotTrackedCell;
