import { stratify, tree } from 'd3-hierarchy';
import { useMemo } from 'react';

import {
  ORIENTATION_VERTICAL,
  SHOW_MORE_NODE_TYPE,
} from '@/containers/ContentIdeas/Listing/MindMap/constant';

function getChildrens({ descendant }) {
  if (descendant.data.data.expanded) {
    if (descendant.children && typeof descendant.data.data.expanded === 'number') {
      return [
        ...descendant.children
          ?.filter((child) => child.data.type !== SHOW_MORE_NODE_TYPE)
          .splice(0, descendant.data.data.expanded),
        descendant.children?.find((child) => child.data.type === SHOW_MORE_NODE_TYPE),
      ].filter((child) => child);
    }
    return descendant.children;
  }
  return undefined;
}

function useExpandCollapse({
  nodes = [],
  edges = [],
  layoutNodes = true,
  treeWidth = 220,
  treeHeight = 100,
  orientation = ORIENTATION_VERTICAL,
}) {
  return useMemo(() => {
    if (!nodes.length) {
      return { edges: [], nodes: [] };
    }

    const hierarchy = stratify()
      .id((d) => d.id)
      .parentId((d) => edges.find((e) => e.target === d.id)?.source)(nodes);

    hierarchy.descendants().forEach((descendant) => {
      descendant.data.data.expandable = !!descendant.children?.length;
      descendant.children = getChildrens({ descendant });
    });

    const layout = tree()
      .nodeSize(
        orientation === ORIENTATION_VERTICAL ? [treeWidth, treeHeight] : [treeHeight, treeWidth]
      )
      .separation(() => 1);

    const root = layoutNodes ? layout(hierarchy) : hierarchy;

    const finalNodes = root.descendants().map((d) => ({
      ...d.data,
      data: { ...d.data.data },
      position:
        typeof d.x === 'number' && typeof d.y === 'number'
          ? orientation === ORIENTATION_VERTICAL
            ? { x: d.x, y: d.y }
            : { x: d.y, y: d.x }
          : d.data.position,
    }));

    return {
      edges: edges.filter(
        (edge) => root.find((h) => h.id === edge.source) && root.find((h) => h.id === edge.target)
      ),
      nodes: orientation === ORIENTATION_VERTICAL ? finalNodes : finalNodes.reverse(),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodes, edges, layoutNodes, treeWidth, treeHeight]);
}

export default useExpandCollapse;
