import { ENUM_TEXT_RANGE_TYPE, TextRangeType } from '../constants';
import getTextRangeType from './getTextRangeType';

function getTexts({ element, range }: { element: HTMLElement; range: Range }): TextRangeType {
  const textRangeType: ENUM_TEXT_RANGE_TYPE = getTextRangeType({ element, range });
  const textContent: string = element.textContent || '';

  switch (textRangeType) {
    case ENUM_TEXT_RANGE_TYPE.BEFORE_PARTIALLY_RANGE:
      return {
        nextText: textContent.substring(range.endOffset),
        text: textContent.substring(0, range.endOffset),
      };
    case ENUM_TEXT_RANGE_TYPE.AFTER_PARTIALLY_RANGE:
      return {
        prevText: textContent.substring(0, range.startOffset),
        text: textContent.substring(range.startOffset),
      };
    case ENUM_TEXT_RANGE_TYPE.IN_RANGE:
      return { text: textContent };
    case ENUM_TEXT_RANGE_TYPE.PARTIALLY_RANGE:
      return {
        nextText: textContent.substring(range.endOffset),
        prevText: textContent.substring(0, range.startOffset),
        text: textContent.substring(range.startOffset, range.endOffset),
      };
    case ENUM_TEXT_RANGE_TYPE.NOT_IN_RANGE:
    default:
      return null;
  }
}

export default getTexts;
