import { useMutation } from '@tanstack/react-query';
import { MutableRefObject, useCallback, useRef } from 'react';

import apiClient from '@/apis/semji/apiClient';

import { FactCheckParams, UsePostFactsResults } from './usePostFacts.types';

export default function usePostFacts(props: any): UsePostFactsResults {
  const { post } = apiClient;
  const abortControllerRef: MutableRefObject<AbortController | undefined> = useRef<
    AbortController | undefined
  >();

  const mutation = useMutation({
    mutationFn: async ({
      topKeywordId,
      assessment,
      generatedContentId,
      generatedContentToken,
    }: FactCheckParams) => {
      abortControllerRef.current = new AbortController();

      return await post(
        `top_keywords/${topKeywordId}/facts`,
        {
          assessment,
          generatedContentId,
          generatedContentToken,
          topKeywordId,
        },
        {
          signal: abortControllerRef.current.signal,
        }
      );
    },
    ...props,
  });

  const abort = useCallback(() => {
    abortControllerRef.current?.abort();
  }, [mutation.abort]);

  return {
    ...mutation,
    abort,
  };
}
