import { useQuery } from '@tanstack/react-query';

import apiClient from '@/apis/semji/apiClient';
import { SCOPE_WORKSPACE } from '@/apis/semji/constants';

export default function useGetWorkspaceById({ workspaceId, location, ...props }) {
  const { get } = apiClient;

  return useQuery({
    queryFn: async ({ signal }) => {
      const result = await get(`/workspaces/${workspaceId}`, {
        signal,
      });

      return result.data || {};
    },
    queryKey: [SCOPE_WORKSPACE.GET_CURRENT_WORKSPACE, workspaceId, location],
    ...props,
  });
}
