const ImportantFileIcon = ({ number, ...props }) => {
  return (
    <svg fill="none" height="18" viewBox="0 0 14 18" width="14" {...props}>
      <path
        d="M8.9502 5.81714H11.1877V17.4524H0.44751V3.13208H8.50269V5.36963V5.81714H8.9502Z"
        stroke="#A8A9AF"
        strokeWidth="0.89502"
      />
      <line
        stroke="#A8A9AF"
        strokeWidth="0.89502"
        x1="11.3183"
        x2="8.63327"
        y1="5.68558"
        y2="3.00052"
      />
      <g>
        <text
          dominantBaseline="middle"
          fill="#A8A9AF"
          fontSize="0.6em"
          textAnchor="middle"
          x="40%"
          y="60%"
        >
          {number ?? '!'}
        </text>
      </g>
    </svg>
  );
};

export default ImportantFileIcon;
