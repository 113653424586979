import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { FlexContainer } from '@/components/_common';
import { Position } from '@/components/CompetitorPage/CompetitorPage';
import FavIcon from '@/components/FavIcon/FavIcon';
import { SecondaryLink } from '@/components/Navigation/Link';
import TooltipComponent from '@/components/Tooltip/Tooltip';

const Wrapper = styled.div`
  padding: 0.5em 0;
`;
const StyledFavIcon = styled(FavIcon)`
  font-size: 0.8em;
`;
const Competitor = styled(FlexContainer)`
  padding: 0.6em 0;
`;
const StyledPosition = styled(Position)`
  font-size: 0.7em;
`;

function TitleDetails({ data = [] }) {
  const { t } = useTranslation();
  const i18nNameSpace = 'content:side-panel-components.optimization.details.title-details';

  return (
    <Wrapper>
      {data.map((competitor) => (
        <Competitor key={competitor.url}>
          <TooltipComponent title={t(`${i18nNameSpace}.rank`)}>
            <StyledPosition isCurrentPage={competitor.isConsideredSameAsPage}>
              {competitor.position}
            </StyledPosition>
          </TooltipComponent>
          <div>
            <SecondaryLink
              isExternal
              title={competitor.title || t('common:labels.unknown-title')}
              to={competitor.url}
            >
              {competitor.title || t('common:labels.unknown-title')}
            </SecondaryLink>
            <StyledFavIcon defaultColor displayLink url={competitor.url} />
          </div>
        </Competitor>
      ))}
    </Wrapper>
  );
}

export default TitleDetails;
