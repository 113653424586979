import './EmptyVersion.scss';

import React from 'react';

import EmptyEditorContent from '@/containers/Content/EmptyEditorContainer/EmptyEditorContent';
import { useContentVersions } from '@/containers/ContentVersion/hooks/useContentVersions';

export default function EmptyVersion() {
  const { createNewDraft, isCreating } = useContentVersions();
  return (
    <div className="content-version-empty">
      <EmptyEditorContent
        createNewDraft={createNewDraft}
        hideStartedMode
        isCreating={isCreating}
        isUpdatingPlanning={false}
      />
    </div>
  );
}
