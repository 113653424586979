import 'reactflow/dist/style.css';
import './index.scss';

import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import ReactFlow, { ControlButton, Controls, ReactFlowProvider, useReactFlow } from 'reactflow';

import ExitFullscreenIcon from '@/components/icons/ExitFullscreenIcon';
import FullscreenIcon from '@/components/icons/FullscreenIcon';
import { RelativeLoader } from '@/components/Loader/Loader';
import { PENDING_STATUS, SUCCESS_STATUS } from '@/components/Pages/ContentIdeas/constant';
import {
  FALSE_VALUE,
  SORT_DIRECTION,
  SORT_DIRECTION_TYPE,
  SORT_TYPE,
  TRUE_VALUE,
} from '@/components/Pages/ContentIdeas/Listing/constant';
import {
  ANIMATION_DURATION,
  CLUSTER_NODE_TYPE,
  DETAILED_TREE_NODE_HEIGHT,
  DETAILED_TREE_NODE_WIDTH,
  KEYWORD_NODE_TYPE,
  MAIN_KEYWORD_NODE_TYPE,
  MAX_EXPANDED_NODES,
  MIN_ZOOM_REACT_FLOW,
  MIND_MAP_SIMPLIFIED_VIEW,
  ORIENTATION_HORIZONTAL,
  ORIENTATION_VERTICAL,
  SHOW_MORE_NODE_TYPE,
  SIMPLIFIED_TREE_NODE_HEIGHT,
  SIMPLIFIED_TREE_NODE_WIDTH,
} from '@/containers/ContentIdeas/Listing/MindMap/constant';
import MindMapViewSelector from '@/containers/ContentIdeas/Listing/MindMap/MindMapViewSelector';
import ClusterNode from '@/containers/ContentIdeas/Listing/MindMap/Nodes/Cluster';
import FakeNode from '@/containers/ContentIdeas/Listing/MindMap/Nodes/FakeNode';
import KeywordNode from '@/containers/ContentIdeas/Listing/MindMap/Nodes/Keyword';
import MainKeywordNode from '@/containers/ContentIdeas/Listing/MindMap/Nodes/MainKeyword';
import ShowMoreNode from '@/containers/ContentIdeas/Listing/MindMap/Nodes/ShowMore';
import useAnimatedNodes from '@/containers/ContentIdeas/Listing/MindMap/useAnimatedNodes';
import useExpandCollapse from '@/containers/ContentIdeas/Listing/MindMap/useExpandCollapse';
import Box from '@/design-system/components/Box/Box';
import Flex from '@/design-system/components/Flex/Flex';
import defaultTheme from '@/themes/defaultTheme';
import { noop } from '@/utils/noop';

const nodeTypes = {
  [CLUSTER_NODE_TYPE]: ClusterNode,
  [KEYWORD_NODE_TYPE]: KeywordNode,
  [MAIN_KEYWORD_NODE_TYPE]: MainKeywordNode,
  [SHOW_MORE_NODE_TYPE]: ShowMoreNode,
  fakeNode: FakeNode,
};

function ReactFlowRender({
  orientation = ORIENTATION_VERTICAL,
  nodes = [],
  edges = [],
  handleFullScreen = noop,
  isFullScreen = false,
}) {
  const nodesRef = useRef(null);
  nodesRef.current = nodes;
  const { t } = useTranslation();
  const [query] = useSearchParams();

  const clusterId = query.get('clusterId');
  const mindMapView = query.get('mindMapView');
  const archivedType = query.get('archivedType');

  const { nodes: visibleNodes, edges: visibleEdges } = useExpandCollapse({
    edges,
    layoutNodes: true,
    nodes,
    orientation,
    treeHeight:
      mindMapView === MIND_MAP_SIMPLIFIED_VIEW
        ? SIMPLIFIED_TREE_NODE_HEIGHT
        : DETAILED_TREE_NODE_HEIGHT,
    treeWidth:
      mindMapView === MIND_MAP_SIMPLIFIED_VIEW
        ? SIMPLIFIED_TREE_NODE_WIDTH
        : DETAILED_TREE_NODE_WIDTH,
  });

  const { fitView, zoomIn, zoomOut } = useReactFlow();
  const { nodes: animatedNodes } = useAnimatedNodes({
    animationDuration: ANIMATION_DURATION,
    nodes: visibleNodes,
  });

  function handleGetFocusedNodeIds() {
    return clusterId !== FALSE_VALUE
      ? nodesRef.current // we use a ref to have the latest nodes value and not the one from the closure
          .filter((node) => node.data.cluster?.id === clusterId || node.id === clusterId)
          .map((showMoreNode) => ({ id: showMoreNode.id }))
      : nodesRef.current.map((node) => ({ id: node.id }));
  }

  function handleFocusedNodeIdsChange() {
    const nodesToFocus = handleGetFocusedNodeIds();

    if (nodesToFocus.length) {
      fitView({ duration: ANIMATION_DURATION, includeHiddenNodes: true, nodes: nodesToFocus });
    }
  }

  useEffect(() => {
    if (clusterId || mindMapView || archivedType) {
      setTimeout(() => handleFocusedNodeIdsChange(), 3 * ANIMATION_DURATION);
      // we need to wait nodes animation to be finished before focusing on the nodes
      // 3 times the animation duration should be enough
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clusterId, mindMapView, archivedType, isFullScreen]);

  function onZoomIn() {
    zoomIn({ duration: ANIMATION_DURATION });
  }

  function onZoomOut() {
    zoomOut({ duration: ANIMATION_DURATION });
  }

  const title = isFullScreen
    ? t('content-ideas:mind-map.controls.exit-full-screen')
    : t('content-ideas:mind-map.controls.full-screen');

  return (
    <ReactFlow
      edges={visibleEdges}
      elementsSelectable={false}
      minZoom={MIN_ZOOM_REACT_FLOW}
      nodes={animatedNodes}
      nodesConnectable={false}
      nodesDraggable={false}
      nodeTypes={nodeTypes}
      proOptions={{ hideAttribution: true }}
      zoomOnDoubleClick={false}
      onInit={handleFocusedNodeIdsChange}
    >
      <Controls
        className="semji__mindmap__controls"
        position="bottom-right"
        showFitView={false}
        showInteractive={false}
        onZoomIn={onZoomIn}
        onZoomOut={onZoomOut}
      >
        <ControlButton title={title} onClick={handleFullScreen}>
          {isFullScreen ? <ExitFullscreenIcon /> : <FullscreenIcon />}
        </ControlButton>
      </Controls>
    </ReactFlow>
  );
}

function handleComputeNodesEdges({
  clusterId = null,
  contentIdeasClusters = {},
  contentIdeaSearch = {},
  contentIdeasList = [],
  orientation = ORIENTATION_HORIZONTAL,
  mindMapView = MIND_MAP_SIMPLIFIED_VIEW,
  previousNodes = [],
}) {
  const mainKeywordNode = {
    data: { ...contentIdeaSearch, expanded: true, mindMapView, orientation },
    id: contentIdeaSearch.id,
    position: { x: 0, y: 0 },
    style: {
      cursor: 'pointer',
      pointerEvents: 'all',
    },
    type: MAIN_KEYWORD_NODE_TYPE,
  };
  const clusterNodes = Object.values(contentIdeasClusters).map((cluster) => {
    const previousExpanded = previousNodes.find((node) => node.id === cluster.id)?.data?.expanded;

    const isActiveCluster = cluster.id === clusterId;
    let expanded = previousExpanded;
    if (previousExpanded === undefined || (isActiveCluster && previousExpanded === 0)) {
      expanded = MAX_EXPANDED_NODES;
    }

    return {
      data: {
        ...cluster,
        blurred: !(clusterId === FALSE_VALUE || isActiveCluster),
        expanded,
        mindMapView,
        orientation,
      },
      id: cluster.id,
      position: { x: 0, y: 0 },
      style: {
        cursor: 'pointer',
        pointerEvents: 'all',
      },
      type: CLUSTER_NODE_TYPE,
    };
  });

  const keywordNodes = contentIdeasList
    .map((contentIdea) => {
      return contentIdea.contentIdeaClusterIds.map((id) => ({
        // here we have a content idea with a unique cluster for the moment but in the future we can have to map it to multiple nodes if it has multiple clusters
        data: {
          ...contentIdea,
          blurred: !(clusterId === FALSE_VALUE || id === clusterId),
          cluster: contentIdeasClusters[id],
          expanded: true,
          mindMapView,
          orientation,
        },
        id: `${id}-${contentIdea.id}`,
        position: { x: 0, y: 0 },
        style: {
          pointerEvents: 'all',
        },
        type: KEYWORD_NODE_TYPE,
      }));
    })
    .flat(); // flat the array of array

  const showMoreNodes = clusterNodes
    .map(
      (cluster) =>
        cluster.data.occurrences > MAX_EXPANDED_NODES && {
          data: {
            blurred: !(clusterId === FALSE_VALUE || cluster.data.id === clusterId),
            cluster,
            expanded: true,
            mindMapView,
            orientation,
          },
          id: `${cluster.id}-show-more`,
          position: { x: 0, y: 0 },
          style: {
            cursor: 'pointer',
            marginLeft: 4,
            pointerEvents: 'all',
          },
          type: SHOW_MORE_NODE_TYPE,
        }
    )
    .filter((showMoreNode) => showMoreNode);

  // We have hidden nodes (that should never be displayed) to force fitView behaviour
  const fakeNodes =
    MIND_MAP_SIMPLIFIED_VIEW === mindMapView
      ? keywordNodes.map((keywordNode) => ({
          // here we have a content idea with a unique cluster for the moment but in the future we can have to map it to multiple nodes if it has multiple clusters
          data: {
            ...keywordNode.data,
            id: keywordNode.id,
          },
          id: `${keywordNode.id}-fake-node`,
          position: { x: 0, y: 0 },
          type: 'fakeNode',
        }))
      : [];

  const newNodes = [
    mainKeywordNode,
    ...clusterNodes,
    ...keywordNodes,
    ...showMoreNodes,
    ...fakeNodes,
  ];

  const newEdges = [
    ...clusterNodes.map((clusterNode) => ({
      id: `${mainKeywordNode.id}->${clusterNode.id}`,
      source: mainKeywordNode.id,
      style: {
        opacity: clusterId === FALSE_VALUE || clusterNode.id === clusterId ? '1' : '0.3',
        stroke: defaultTheme.cssColors.dark100,
      },
      target: clusterNode.id,
    })),
    ...keywordNodes.map((keywordNode) => ({
      id: `${keywordNode.data.cluster.id}->${keywordNode.id}`,
      source: keywordNode.data.cluster.id,
      style: {
        opacity:
          clusterId === FALSE_VALUE || keywordNode.data.cluster.id === clusterId ? '1' : '0.3',
        stroke: keywordNode.data.cluster.color,
      },
      target: keywordNode.id,
    })),
    ...showMoreNodes.map((showMoreNode) => ({
      id: `${showMoreNode.data.cluster.id}->${showMoreNode.id}`,
      source: showMoreNode.data.cluster.id,
      style: {
        visibility: 'hidden',
      },
      target: showMoreNode.id,
    })),
    ...fakeNodes.map((fakeNode) => ({
      id: `${fakeNode.data.id}->${fakeNode.id}`,
      source: fakeNode.data.id,
      style: {
        visibility: 'hidden',
      },
      target: fakeNode.id,
    })),
  ];

  return {
    newEdges,
    newNodes,
  };
}

function MindMap({
  contentIdeasClusters = {},
  contentIdeaSearch = {},
  contentIdeasList = [],
  contentIdeasLoading = true,
  clusterizationStatus = PENDING_STATUS,
  setIsSidebarOpen = noop,
  setIsHeaderOpen = noop,
  handleOpenAddToPlanningModal = noop,
  handleOpenEditDraftModal = noop,
  handleRemoveFromPlanningDialog = noop,
  archiveContentIdeas = noop,
  isAddToPlanningDialogOpen,
  isRemoveFromPlanningDialogOpen,
  contentsToAddToPlanning,
  contentsToRemoveFromPlanning,
}) {
  const { t } = useTranslation();
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const [query] = useSearchParams();

  const clusterId = query.get('clusterId');
  const mindMapView = query.get('mindMapView');
  const archivedType = query.get('archivedType');
  const sortType = query.get(SORT_TYPE);
  const sortDirection = query.get(SORT_DIRECTION);

  const isArchivedView = archivedType === TRUE_VALUE;

  const contentIdeasListFiltered = contentIdeasList.filter((contentIdea) =>
    isArchivedView ? contentIdea.isArchived : !contentIdea.isArchived
  );

  const contentIdeasClustersFiltered = Object.values(contentIdeasClusters)
    .sort((a, b) => (a.occurrences > b.occurrences ? -1 : 1))
    .reduce((acc, cluster) => {
      const occurrences = contentIdeasListFiltered.filter((contentIdea) =>
        contentIdea.contentIdeaClusterIds.includes(cluster.id)
      ).length;

      if (!occurrences) {
        return acc;
      }
      return {
        ...acc,
        [cluster.id]: {
          ...cluster,
          occurrences,
        },
      };
    }, {});

  const orientation = ORIENTATION_HORIZONTAL;

  function handleFullScreen() {
    setIsFullScreen(!isFullScreen);
  }

  function sortContentIdeasByKey(contentIdeas) {
    if (!sortType || !sortDirection) return contentIdeas;
    const isNumberType = Number.isInteger(contentIdeas[0]?.[sortType]);

    if (isNumberType) {
      return contentIdeas.sort((a, b) => {
        if (sortDirection === SORT_DIRECTION_TYPE.ASC) {
          return a[sortType] - b[sortType] || b.similarityScore - a.similarityScore;
        }
        return b[sortType] - a[sortType] || b.similarityScore - a.similarityScore;
      });
    }
    return contentIdeas.sort((a, b) => {
      if (sortDirection === SORT_DIRECTION_TYPE.ASC) {
        return (a[sortType] ?? '').localeCompare(b[sortType]);
      }
      return (b[sortType] ?? '').localeCompare(a[sortType]);
    });
  }

  useEffect(() => {
    setIsSidebarOpen(!isFullScreen);
    setIsHeaderOpen(!isFullScreen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFullScreen]);

  useEffect(() => {
    if (contentIdeasLoading || clusterizationStatus !== SUCCESS_STATUS) {
      return;
    }

    const { newEdges, newNodes } = handleComputeNodesEdges({
      clusterId,
      contentIdeaSearch,
      contentIdeasClusters: contentIdeasClustersFiltered,
      contentIdeasList: sortContentIdeasByKey(contentIdeasListFiltered),
      mindMapView,
      orientation,
      previousNodes: nodes,
    });

    setNodes(newNodes);
    setEdges(newEdges);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    contentIdeasLoading,
    contentIdeasListFiltered?.length,
    contentIdeaSearch,
    contentIdeasClustersFiltered?.length,
    clusterId,
    mindMapView,
    clusterizationStatus,
  ]);

  if (contentIdeasLoading || clusterizationStatus !== SUCCESS_STATUS || !nodes.length) {
    return (
      <Flex
        alignItems="center"
        flexDirection="column"
        gap="12px"
        justifyContent="center"
        width="100%"
      >
        <RelativeLoader fontSize="64px" />
        <Box color="dark060">{t('content-ideas:mind-map.loading')}</Box>
      </Flex>
    );
  }

  return (
    <Flex backgroundColor="dark002" position="relative" width="100%">
      <ReactFlowProvider>
        <ReactFlowRender
          edges={edges.map((edge) => ({
            ...edge,
            data: {
              ...edge.data,
              archiveContentIdeas,
              edges,
              handleOpenAddToPlanningModal,
              handleOpenEditDraftModal,
              handleRemoveFromPlanningDialog,
              nodes,
              setEdges,
              setNodes,
            },
          }))}
          handleFullScreen={handleFullScreen}
          isFullScreen={isFullScreen}
          nodes={nodes.map((node) => ({
            ...node,
            data: {
              ...node.data,
              archiveContentIdeas,
              contentsToAddToPlanning,
              contentsToRemoveFromPlanning,
              edges,
              handleOpenAddToPlanningModal,
              handleOpenEditDraftModal,
              handleRemoveFromPlanningDialog,
              isAddToPlanningDialogOpen,
              isRemoveFromPlanningDialogOpen,
              nodes,
              setEdges,
              setNodes,
            },
          }))}
          orientation={orientation}
        />
      </ReactFlowProvider>
      <MindMapViewSelector />
    </Flex>
  );
}

export default MindMap;
