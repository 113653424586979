import React from 'react';

import SvgWrapper from './SvgWrapper';

const CloseIcon = (props) => (
  <SvgWrapper {...props}>
    <path d="M9.207 8.5l6.646 6.646-0.707 0.707-6.646-6.646-6.646 6.646-0.707-0.707 6.646-6.646-6.647-6.646 0.707-0.707 6.647 6.646 6.646-6.646 0.707 0.707-6.646 6.646z" />
  </SvgWrapper>
);

export default CloseIcon;
