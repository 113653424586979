import { nanoid } from 'nanoid';
import React, { useMemo } from 'react';

import SvgWrapper from './SvgWrapper';

function ExitFullscreenIcon({ ...props }) {
  const maskId = useMemo(() => nanoid(), []);

  return (
    <SvgWrapper {...props} fill="none" height="13" viewBox="0 0 13 13" width="13">
      <mask
        height="13"
        id={`mask${maskId}`}
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="13"
        x="0"
        y="0"
      >
        <path
          clipRule="evenodd"
          d="M3.76149 4.29505L0.212641 0.746232L0.849034 0.109834L4.39784 3.65862V0.428047H5.29784V4.74505V5.19505H4.84784H0.530841V4.29505H3.76149ZM9.23851 4.29528L12.7874 0.74646L12.151 0.110061L8.60216 3.65884V0.428275H7.70216V4.74528V5.19528H8.15216H12.4692V4.29528H9.23851ZM3.7594 8.39339L0.210558 11.9422L0.846951 12.5786L4.39575 9.02983L4.39575 12.2604H5.29575L5.29575 7.94339V7.49339H4.84575L0.528749 7.49339L0.52875 8.39339L3.7594 8.39339ZM9.24058 8.39339L12.7894 11.9422L12.153 12.5786L8.60422 9.02982L8.60423 12.2604H7.70423L7.70422 7.94339V7.49339H8.15422L12.4712 7.49339V8.39339L9.24058 8.39339Z"
          fill="currentColor"
          fillRule="evenodd"
        />
      </mask>
      <g mask={`url(#mask${maskId})`}>
        <rect fill="currentColor" height="15.2941" width="15.2941" x="-1.52941" y="-0.764648" />
      </g>
    </SvgWrapper>
  );
}

export default ExitFullscreenIcon;
