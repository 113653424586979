import { useTranslation } from 'react-i18next';

import Tooltip from '@/components/Tooltip/Tooltip';
import { useContentContainerContext } from '@/containers/Content/ContentContainerContext';

import { Container } from './CommentText.styled';
import { formatMentions } from './helper';

function CommentText({ comment, isThreadResolved, users }) {
  const { content } = comment;
  const { t } = useTranslation();
  const { getWorkspaceUsers } = useContentContainerContext();
  const { mentionnedUserHasAccess, newContentText } = formatMentions({
    content,
    users,
    workspaceUsers: getWorkspaceUsers.data,
  });

  return (
    <Container isThreadResolved={isThreadResolved}>
      {mentionnedUserHasAccess ? (
        <p dangerouslySetInnerHTML={{ __html: newContentText }} />
      ) : (
        <Tooltip placement="top" title={t('comments:tooltip.greyed-mentions')}>
          <p dangerouslySetInnerHTML={{ __html: newContentText }} />
        </Tooltip>
      )}
    </Container>
  );
}

export default CommentText;
