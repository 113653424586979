import { nanoid } from 'nanoid';
import React, { useMemo } from 'react';

import SvgWrapper from './SvgWrapper';

function ThumbDownIcon(props) {
  const maskId = useMemo(() => nanoid(), []);

  return (
    <SvgWrapper {...props} fill="none" height="18" viewBox="0 0 17 18" width="17">
      <g clipPath={`url(#clip${maskId})`}>
        <mask
          height="17"
          id={`mask${maskId}`}
          maskUnits="userSpaceOnUse"
          style={{ maskType: 'alpha' }}
          width="18"
          x="0"
          y="1"
        >
          <path
            clipRule="evenodd"
            d="M3.85848 1.5C3.2345 1.5 2.67569 1.88629 2.45522 2.47002L0.0967449 8.71473C0.0327768 8.8841 0 9.06365 0 9.2447L0 10.7105C0 11.5389 0.671573 12.2105 1.5 12.2105H6.91728L5.80298 15.5297C5.4767 16.5016 6.19979 17.5071 7.22498 17.5071H8.03539C8.47872 17.5071 8.89935 17.311 9.18435 16.9714L13.1801 12.2105H15.5033C16.3317 12.2105 17.0033 11.5389 17.0033 10.7105V3C17.0033 2.17157 16.3317 1.5 15.5033 1.5L3.85848 1.5ZM3.39073 2.82334C3.46421 2.62876 3.65048 2.5 3.85848 2.5L13.0032 2.5L13.0032 11.2105H12.947C12.7992 11.2105 12.659 11.2758 12.564 11.389L8.41838 16.3285C8.32338 16.4417 8.18317 16.5071 8.03539 16.5071H7.22498C6.88325 16.5071 6.64222 16.1719 6.75098 15.848L8.08657 11.8696C8.13778 11.7171 8.11244 11.5492 8.0185 11.4185C7.92455 11.2879 7.77347 11.2105 7.61256 11.2105L1.5 11.2105C1.22386 11.2105 1 10.9866 1 10.7105L1 9.2447C1 9.18435 1.01093 9.1245 1.03225 9.06805L3.39073 2.82334ZM14.0032 11.2105H15.5033C15.7794 11.2105 16.0033 10.9866 16.0033 10.7105V3C16.0033 2.72386 15.7794 2.5 15.5033 2.5H14.0032L14.0032 11.2105Z"
            fill="#252736"
            fillRule="evenodd"
          />
        </mask>
        <g mask={`url(#mask${maskId})`}>
          <rect fill="currentColor" height="20" width="20" x="-2" y="-0.5" />
        </g>
      </g>
      <defs>
        <clipPath id={`clip${maskId}`}>
          <rect fill="currentColor" height="17" transform="translate(0 0.5)" width="17" />
        </clipPath>
      </defs>
    </SvgWrapper>
  );
}

export default ThumbDownIcon;
