import React from 'react';
import { Navigate, useParams } from 'react-router-dom';

import AdobeServiceIntegration from '@/containers/ServiceIntegration/Adobe';
import ATInternetServiceIntegration from '@/containers/ServiceIntegration/ATInternet';
import GoogleAnalyticsServiceIntegration from '@/containers/ServiceIntegration/GoogleAnalytics';
import MatomoServiceIntegration from '@/containers/ServiceIntegration/Matomo';
import PiwikProServiceIntegration from '@/containers/ServiceIntegration/PiwikPro';
import SearchConsoleServiceIntegration from '@/containers/ServiceIntegration/SearchConsole';
import useCan from '@/hooks/useCan';
import { SERVICES_LIST } from '@/utils/constants';

export default function ServiceIntegrations() {
  const { serviceIntegrationType } = useParams();

  const canUserVisit = useCan({
    data: { accessGranted: true },
    perform: 'workspace-owner-settings:visit',
  });
  const isMatomoEnabled = useCan({ perform: 'matomo:enabled' });
  const isAdobeEnabled = useCan({ perform: 'adobe:enabled' });
  const isPiwikProEnabled = useCan({ perform: 'piwik-pro:enabled' });

  function handleVisitRender() {
    switch (serviceIntegrationType) {
      case SERVICES_LIST.AT_INTERNET.path:
        return <ATInternetServiceIntegration />;
      case SERVICES_LIST.GOOGLE_ANALYTICS.path:
        return <GoogleAnalyticsServiceIntegration />;
      case SERVICES_LIST.SEARCH_CONSOLE.path:
        return <SearchConsoleServiceIntegration />;
      case SERVICES_LIST.MATOMO.path:
        return isMatomoEnabled ? <MatomoServiceIntegration /> : handleNoVisitRender();
      case SERVICES_LIST.ADOBE.path:
        return isAdobeEnabled ? <AdobeServiceIntegration /> : handleNoVisitRender();
      case SERVICES_LIST.PIWIK_PRO.path:
        return isPiwikProEnabled ? <PiwikProServiceIntegration /> : handleNoVisitRender();
      default:
        return handleNoVisitRender();
    }
  }

  function handleNoVisitRender() {
    return <Navigate to="/" />;
  }

  return canUserVisit ? handleVisitRender() : handleNoVisitRender();
}
