import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import WarningMessage from '@/components/Message/WarningMessage';
import { PrimaryLink } from '@/components/Navigation/Link';
import { DefaultLink } from '@/components/Navigation/Link';
import { DisableableWrapper } from '@/components/Optimization/Instruction';
import Optimization from '@/components/Optimization/Optimization';
import DetailsBase from '@/containers/Content/SidePanel/Components/Optimization/Details/DetailsBase';
import HeatMapDetails from '@/containers/Content/SidePanel/Components/Optimization/Details/HeatMapDetails';
import BlurredRecommendations from '@/containers/Content/SidePanel/Components/Optimization/Section/Credits/BlurredRecommendations';
import { SEARCH_INTENT } from '@/containers/Content/SidePanel/Components/Optimization/Section/Credits/constants';
import CreditWarningMessage from '@/containers/Content/SidePanel/Components/Optimization/Section/Credits/CreditWarningMessage';
import SearchIntent from '@/containers/Content/SidePanel/Components/Optimization/Section/SearchIntents/SearchIntent';
import { isNodeInsideTable } from '@/containers/Content/TinyMceComponents/Editor/hooks/useAiWriting/useAiWriting';
import { disableMainOptimization, enableMainOptimization } from '@/store/actionsCreator/report';

function SearchIntents({
  isReadOnly,
  id,
  editorRef,
  focusTopKeywordId,
  keyword,
  pageId,
  ...props
}) {
  const { t } = useTranslation();
  const [enabledSearchIntents, setEnabledSearchIntents] = useState([]);
  const [disabledSearchIntents, setDisabledSearchIntents] = useState([]);
  const [showDetails, setShowDetails] = useState(false);

  const dispatch = useDispatch();
  const allRecommendations = useSelector((state) => state.report.recommendations);
  const recommendationsIds = useSelector(
    (state) => state.report.recommendations[id]?.recommendationsIds
  );
  const score = useSelector((state) => state.report.recommendations[id]?.score);
  const weight = useSelector((state) => state.report.recommendations[id]?.weight);
  const disabled = useSelector((state) => state.report.recommendations[id]?.disabled);
  const ready = useSelector((state) => state.report.recommendations[id]?.ready);

  const lockedSearchIntentCount = useSelector(
    (state) => state.report.recommendations[id]?.data?.lockedSearchIntentCount
  );
  const foundSearchIntentsCount = useSelector(
    (state) => state.report.recommendations[id]?.data?.foundSearchIntentsCount
  );
  const searchIntentGoalCount = useSelector(
    (state) => state.report.recommendations[id]?.data?.searchIntentGoalCount
  );
  const competitors = useSelector((state) => state.report.recommendations[id]?.data?.competitors);

  const html = useSelector((state) => state.content?.html);
  const contentTitle = useSelector((state) => state.content?.title);
  const isCreditLimitDialogOpen = useSelector((state) => state.report?.isCreditLimitDialogOpen);
  const isUpgradePlanModalOpen = useSelector((state) => state.billing.showUpgradeModal);
  const remainingCredits = useSelector(
    (state) =>
      state.organizations?.byId[state.defaultOrganizationId]?.remainingSearchIntentUnit || 0
  );
  const maxAmountToUnlock = Math.min(lockedSearchIntentCount, 10);
  const displayWarningCreditMessage = maxAmountToUnlock > remainingCredits && maxAmountToUnlock > 0;
  const displayWarningInsufficientSearchIntents =
    !displayWarningCreditMessage &&
    disabledSearchIntents.length + enabledSearchIntents.length + lockedSearchIntentCount < 10;

  function closeDetails() {
    setShowDetails(false);
  }

  function toggleSearchIntent(key, disabled) {
    if (disabled) {
      setDisabledSearchIntents([...disabledSearchIntents, key]);
      setEnabledSearchIntents(enabledSearchIntents.filter((enabledLink) => enabledLink !== key));
    } else {
      setEnabledSearchIntents([...enabledSearchIntents, key]);
      setDisabledSearchIntents(disabledSearchIntents.filter((enabledLink) => enabledLink !== key));
    }
  }

  const setStateRecommendations = () => {
    let enabledSI = [];
    let disabledSI = [];

    recommendationsIds.forEach((recommendationsId) => {
      if (allRecommendations[recommendationsId].disabled) {
        disabledSI.push(recommendationsId);
      } else {
        enabledSI.push(recommendationsId);
      }
    });

    setEnabledSearchIntents([...enabledSI]);
    setDisabledSearchIntents([...disabledSI]);
  };

  function disableRecommendation(key) {
    dispatch(disableMainOptimization(key, focusTopKeywordId, html, contentTitle));
  }

  function enableRecommendation(key) {
    dispatch(enableMainOptimization(key, focusTopKeywordId, html, contentTitle));
  }

  function onAddOptimization(optimization) {
    if (editorRef.current.isSemjiContentReadOnly) {
      return;
    }

    const node = editorRef.current.dom.create('h2', {}, optimization);
    editorRef.current.focus();
    const insideTable = isNodeInsideTable(editorRef.current.selection.getEnd());
    const currentNode = insideTable || editorRef.current.selection.getNode();
    editorRef.current.dom.insertAfter(node, currentNode);

    // When selection is on a <br> with a parent, we insert after the parent
    const isInsideEmptyParagraph = currentNode?.tagName === 'BR' && currentNode?.parentNode;
    if (isInsideEmptyParagraph) {
      editorRef.current.dom.insertAfter(node, currentNode.parentNode);
      editorRef.current.dom.remove(currentNode.parentNode);
    } else {
      editorRef.current.dom.insertAfter(node, currentNode);
    }

    // When selection is on a <p><br></p>, we remove this empty paragraph
    const isEmptyParagraph =
      currentNode?.tagName === 'P' &&
      currentNode?.childNodes.length === 1 &&
      currentNode?.childNodes[0]?.tagName === 'BR';
    if (isEmptyParagraph) {
      editorRef.current.dom.remove(currentNode);
    }

    node.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    editorRef.current.selection.select(node, true);
    editorRef.current.selection.collapse(false);
    editorRef.current.setDirty(true);

    setTimeout(() => {
      editorRef.current?.undoManager?.add?.();
    });
  }

  function openDetails() {
    setShowDetails(true);
  }

  useEffect(() => {
    setStateRecommendations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setStateRecommendations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recommendationsIds]);

  useEffect(() => {
    if (isCreditLimitDialogOpen || isUpgradePlanModalOpen) {
      closeDetails();
    }
  }, [isCreditLimitDialogOpen, isUpgradePlanModalOpen]);

  const searchIntents = [...enabledSearchIntents, ...disabledSearchIntents];
  const isEmpty = searchIntents.length === 0 && ready;

  return (
    <>
      <Optimization
        description={
          <span>
            {t('content:side-panel-components.optimization.section.search-intents.description')}
            <Trans
              components={{
                extLink: (
                  <DefaultLink
                    color="dark040"
                    decoration
                    isExternal
                    noDynamicFontSize
                    noPadding
                    size="xsm"
                    to={t('common:links.help-search-intents')}
                    weight="medium"
                  />
                ),
              }}
              i18nKey="content:side-panel-components.optimization.section.search-intents.description-learn-more"
            />
          </span>
        }
        disabled={disabled}
        disableRecommendation={disableRecommendation}
        enableRecommendation={enableRecommendation}
        helpCenterLink={t('common:links.help-search-intents')}
        id={id}
        infoMessage={t('components:optimization.optimization.no-search-intents-message', {
          keyword,
        })}
        infoText={t('content:side-panel-components.optimization.section.search-intents.info-text')}
        instructions={[
          <DisableableWrapper key="searchIntents" disabled={disabled}>
            <CreditWarningMessage amountToUnlock={lockedSearchIntentCount} type={SEARCH_INTENT} />
            {displayWarningInsufficientSearchIntents && (
              <Trans
                components={{
                  pLink: (
                    <PrimaryLink
                      isExternal
                      noDecoration
                      size="sm"
                      to={t('common:links.help-no-recommendations')}
                      weight="strong"
                    />
                  ),
                  warning: <WarningMessage />,
                }}
                i18nKey="content:side-panel-components.optimization.section.search-intents.instruction-warning"
              />
            )}
            {searchIntents.map((searchIntentId, index) => (
              <SearchIntent
                key={`${searchIntentId}_${index}`}
                competitors={competitors}
                focusTopKeywordId={focusTopKeywordId}
                id={searchIntentId}
                isReadOnly={isReadOnly}
                mainId={id}
                onAddSearchIntent={onAddOptimization}
                onToggleSearchIntent={toggleSearchIntent}
              />
            ))}
            <BlurredRecommendations
              amountToUnlock={lockedSearchIntentCount}
              keywordId={focusTopKeywordId}
              type={SEARCH_INTENT}
            />
          </DisableableWrapper>,
        ]}
        isEmpty={isEmpty}
        isReady={ready}
        loaderRows={1}
        points={Math.round((weight - weight * score) * 100)}
        score={score}
        scorer={`${foundSearchIntentsCount}/${searchIntentGoalCount}`}
        scorerTooltipText={t(
          'content:side-panel-components.optimization.section.search-intents.scorer-tooltip-text',
          { searchIntentGoalCount }
        )}
        showDetailsAction={openDetails}
        title={t('content:side-panel-components.optimization.section.search-intents.title')}
        {...props}
      />
      <DetailsBase
        description={t(
          'content:side-panel-components.optimization.section.search-intents.details-description',
          { keyword }
        )}
        show={showDetails}
        subTitle={t(
          'content:side-panel-components.optimization.section.search-intents.details-subtitle',
          { searchIntentGoalCount }
        )}
        title={t('content:side-panel-components.optimization.section.search-intents.title')}
        onClose={closeDetails}
      >
        <HeatMapDetails
          competitors={competitors}
          keyword={keyword}
          keywordId={focusTopKeywordId}
          lockedRecommendationsLength={lockedSearchIntentCount}
          recommendations={(recommendationsIds || [])
            .filter((instructionId) => !allRecommendations[instructionId].disabled)
            .map((instructionId) => allRecommendations[instructionId])}
          termKey="searchIntent"
          title={t('content:side-panel-components.optimization.section.search-intents.topics')}
        />
      </DetailsBase>
    </>
  );
}

export default SearchIntents;
