import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Handle, Position } from 'reactflow';

import { FALSE_VALUE } from '@/components/Pages/ContentIdeas/Listing/constant';
import {
  CLUSTER_NODE_TYPE,
  MAX_EXPANDED_NODES,
  ORIENTATION_VERTICAL,
} from '@/containers/ContentIdeas/Listing/MindMap/constant';
import { MainKeyword } from '@/containers/ContentIdeas/Listing/MindMap/Nodes/MainKeyword/MainKeywordNode.styled';
import Box from '@/design-system/components/Box/Box';

function MainKeywordNode({ data }) {
  const [query, setQuery] = useSearchParams();
  const [expanded, setExpanded] = useState(true);
  const { nodes, setNodes } = data;

  function handleShow() {
    setNodes(
      nodes.reduce((acc, node) => {
        if (node.type === CLUSTER_NODE_TYPE) {
          acc.push({
            ...node,
            data: { ...node.data, expanded: expanded ? 0 : MAX_EXPANDED_NODES },
          });
        } else {
          acc.push(node);
        }
        return acc;
      }, [])
    );
    setExpanded(!expanded);
    query.set('clusterId', FALSE_VALUE);
    setQuery(query);
  }

  return (
    <Box backgroundColor="dark100" borderRadius="24px" padding="12px 24px" onClick={handleShow}>
      <Handle
        position={data.orientation === ORIENTATION_VERTICAL ? Position.Bottom : Position.Right}
        style={{ visibility: 'hidden' }}
        type="source"
      />
      <MainKeyword>{data.keyword}</MainKeyword>
    </Box>
  );
}

export default MainKeywordNode;
