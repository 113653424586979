import * as semji from '@/apis/semji/api';
import { NULL_USER } from '@/hooks/useNullUser';
import WorkspaceService from '@/services/Workspace';
import {
  CHANGE_DEFAULT_WORKSPACE_ID,
  CHANGE_WORKSPACE_NAME,
  FETCH_ORGANIZATION_WORKSPACES_SUCCESS,
  FETCH_WORKSPACE_SUCCESS,
  REMOVE_DEFAULT_WORKSPACE_ID,
  UPDATE_WORKSPACE_ACHIEVEMENTS,
  UPDATE_WORKSPACE_USERS,
} from '@/store/actions/actionTypes';

export const changeWorkspaceName = (id, name) => ({
  id,
  name,
  type: CHANGE_WORKSPACE_NAME,
});

export const fetchWorkspace = (workspaceId) => async (dispatch) => {
  try {
    const _WorkspaceService = new WorkspaceService(workspaceId);
    const response = await _WorkspaceService.workspace;
    await dispatch({
      type: FETCH_WORKSPACE_SUCCESS,
      workspace: response,
    });
    return Promise.resolve();
  } catch (e) {
    return Promise.reject(e);
  }
};

export const fetchWorkspaces = (organizationId) => async (dispatch) => {
  try {
    const response = await semji.fetchOrganizationWorkspaces(organizationId);
    await dispatch({
      organizationId,
      type: FETCH_ORGANIZATION_WORKSPACES_SUCCESS,
      workspaces: response['hydra:member'],
    });
    return Promise.resolve();
  } catch (e) {
    return Promise.reject(e);
  }
};

export const changeDefaultWorkspaceId = (workspaceId) => ({
  type: CHANGE_DEFAULT_WORKSPACE_ID,
  workspaceId,
});

export const removeDefaultWorkspaceId = () => ({
  type: REMOVE_DEFAULT_WORKSPACE_ID,
});

export const updateWorkspaceAchievements = (workspaceId, achievements) => ({
  achievements,
  type: UPDATE_WORKSPACE_ACHIEVEMENTS,
  workspaceId,
});

export const updateWorkspaceUsers = (workspaceId) => async (dispatch) => {
  try {
    const response = await semji.fetchWorkspaceUsers(workspaceId);
    const users = [
      NULL_USER,
      ...response['hydra:member'].sort((a, b) =>
        `${a.firstName || ''}${a.lastName || ''}${a.email}`
          .trim()
          .localeCompare(`${b.firstName || ''}${b.lastName || ''}${b.email}`.trim())
      ),
    ];

    await dispatch({
      type: UPDATE_WORKSPACE_USERS,
      users,
      workspaceId,
    });
    return Promise.resolve(users);
  } catch (e) {
    return Promise.reject(e);
  }
};
