import PropTypes from 'prop-types';

import LaunchTour from '@/components/Badge/LaunchTour';
import { usePageDataLayout } from '@/components/PageDataLayout/PageDataLayout.context';
import { TitleTwo } from '@/components/Text/Title';
import Tooltip from '@/components/Tooltip/Tooltip';
import Flex from '@/design-system/components/Flex/Flex';
import defaultTheme from '@/themes/defaultTheme';

import { Chevron, TitleWrapper } from './Sidebar.styles';

export const Sidebar = ({ children, isOpen }) => {
  if (!isOpen) return null;

  return (
    <Flex borderRight={`1px solid ${defaultTheme.cssColors.dark010}`} flexDirection="column">
      {children}
    </Flex>
  );
};

export const SidebarTitle = ({ i18n, onClick, isOpen, toolTipProps, launchTour }) => {
  return (
    <TitleWrapper>
      <TitleTwo noMargin weight="medium">
        {i18n.title}
      </TitleTwo>
      {launchTour && <LaunchTour data-intercom-target={launchTour} />}
      <Tooltip $isOpen={isOpen} cursor="pointer" {...toolTipProps}>
        <Chevron $isOpen={isOpen} onClick={onClick} />
      </Tooltip>
    </TitleWrapper>
  );
};

export const SidebarButton = ({ isOpen, onClick, toolTipProps, chevronProps }) => (
  <Tooltip $isOpen={isOpen} cursor="pointer" {...toolTipProps}>
    <Chevron $isOpen={isOpen} onClick={onClick} {...chevronProps} />
  </Tooltip>
);

export const SidebarToggle = ({ className }) => {
  const { isSidebarOpen, ToggleComponent } = usePageDataLayout();
  return isSidebarOpen ? null : <div className={className}>{ToggleComponent}</div>;
};

SidebarTitle.propTypes = {
  i18n: PropTypes.object,
  isOpen: PropTypes.bool,
  launchTour: PropTypes.string,
  onClick: PropTypes.func,
  toolTipProps: PropTypes.object,
};
