import FormControlMui from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Zoom from '@material-ui/core/Zoom';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';

import { Box, BoxHeader, Form } from '@/components/Box/Box';
import { ContainedButton } from '@/components/Button/Button';
import FaceIoLoginButton from '@/components/FaceIo/FaceIoLoginButton';
import Block from '@/components/GoogleLogin/Block';
import { SIGNIN } from '@/components/GoogleLogin/constants';
import GoogleLoginButton from '@/components/GoogleLogin/GoogleLoginButton';
import Separator from '@/components/GoogleLogin/Separator';
import WhiteLogo from '@/components/icons/LogoWhite';
import {
  DEFAULT_BACKGROUND,
  LayoutCenteredContent,
  NO_MENU_LAYOUT,
} from '@/components/Layout/Layout';
import { RelativeLoader } from '@/components/Loader/Loader';
import { DefaultLink } from '@/components/Navigation/Link';
import { BlockText } from '@/components/Text/Inline';
import { TitleTwo } from '@/components/Text/Title';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import LayoutWrapper from '@/containers/Layout/Layout';
import Can from '@/hoc/Can';
import useCan from '@/hooks/useCan';
import useFaceIo from '@/hooks/useFaceIo';
import { login } from '@/store/actions/user';
import { pushAuthenticatedUserToGoogleAnalytics } from '@/utils/googleTagManager';
import { SECTIONS } from '@/utils/log/constants';
import { Log } from '@/utils/log/Log';

const FormControl = styled(FormControlMui)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Tooltip = styled(TooltipComponent)`
  display: flex;
`;

function Login({ redirectTo }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isSigninWithGoogleEnabled = useCan({ perform: 'sign-in:with-google' });
  const { isEnabled: isFaceIoEnabled, authenticateUser } = useFaceIo();

  const [state, setState] = useState({ email: '', password: '' });
  const [showPassword, setShowPassword] = useState(false);
  const [loginState, setLoginState] = useState({
    isOnError: false,
    isPending: false,
  });

  async function handleSubmit(event) {
    event.preventDefault();
    setLoginState({
      isOnError: false,
      isPending: true,
    });

    try {
      const user = await dispatch(login({ password: state.password, username: state.email }));

      pushAuthenticatedUserToGoogleAnalytics(user);
      setLoginState({
        isOnError: false,
        isPending: false,
      });

      navigate(redirectTo || '/', { replace: true });
    } catch (error) {
      Log.report({
        context: 'Submit form login',
        error,
        extra: 'login with username and password',
        section: SECTIONS.login.key,
      });

      setLoginState({
        isOnError: true,
        isPending: false,
      });
    }
  }

  function handleChange(event) {
    setState({ ...state, [event.target.name]: event.target.value });
  }

  const labelVisibilityPassword = t(
    showPassword
      ? 'login:password-field.label-hide-password'
      : 'login:password-field.label-show-password'
  );

  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }

  function handleMouseDownPassword(event) {
    event.preventDefault();
  }

  return (
    <LayoutWrapper menu={NO_MENU_LAYOUT} variant={DEFAULT_BACKGROUND}>
      <LayoutCenteredContent>
        <Helmet>
          <title>{t('login:login.title')}</title>
        </Helmet>
        <WhiteLogo height="75" width="150" />
        <Zoom in transition={600}>
          <Box>
            <BoxHeader>
              <TitleTwo weight="medium">{t('login:login.sign-in')}</TitleTwo>
            </BoxHeader>
            {isSigninWithGoogleEnabled && (
              <Block>
                <GoogleLoginButton context={SIGNIN} redirectTo={redirectTo} />
                <Separator>{t('login:label-or')}</Separator>
              </Block>
            )}
            {isFaceIoEnabled && (
              <Block>
                <FaceIoLoginButton onClick={authenticateUser} />
                <Separator>{t('login:label-or')}</Separator>
              </Block>
            )}

            <Form onChange={handleChange} onSubmit={handleSubmit}>
              <FormControl
                aria-describedby="credentials-error"
                error={loginState.isOnError}
                fullWidth
              >
                <TextField
                  autoComplete="username"
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus
                  error={loginState.isOnError}
                  fullWidth
                  label={t('login:login.email')}
                  name="email"
                  required
                  type="email"
                />
                <TextField
                  autoComplete="current-password"
                  error={loginState.isOnError}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label={labelVisibilityPassword}
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          <Tooltip title={labelVisibilityPassword}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </Tooltip>
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  label={t('login:login.password')}
                  name="password"
                  required
                  type={showPassword ? 'text' : 'password'}
                />
                {loginState.isOnError && (
                  <FormHelperText>{t('login:login.given-email-password-invalid')}</FormHelperText>
                )}
              </FormControl>
              <ContainedButton disabled={loginState.isPending} fullWidth height="big" type="submit">
                {loginState.isPending ? <RelativeLoader /> : t('login:login.sign-in').toUpperCase()}
              </ContainedButton>
            </Form>
            <DefaultLink color="dark060" size="sm" to="/forgot-password" weight="medium">
              {t('login:login.forgot-your-password')}
            </DefaultLink>
            <DefaultLink color="dark080" decoration to="/sso" weight="medium">
              {t('login:login.sign-in-saml-sso')}
            </DefaultLink>
            <Can
              perform={'sign-in:self-serve'}
              yes={() => (
                <BlockText color="dark080" size="default" weight="medium">
                  {t('login:login.do-not-have-account')}
                  <DefaultLink to="/register" weight="medium">
                    {t('login:login.sign-up')}
                  </DefaultLink>
                </BlockText>
              )}
            />
          </Box>
        </Zoom>
      </LayoutCenteredContent>
    </LayoutWrapper>
  );
}

export default Login;
