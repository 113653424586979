import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';

import useDeleteComment from '@/apis/semji/comments/useDeleteComment';
import useDeleteThread from '@/apis/semji/comments/useDeleteThread';
import useGetThreads from '@/apis/semji/comments/useGetThreads';
import usePostComment from '@/apis/semji/comments/usePostComment';
import usePostResolveThread from '@/apis/semji/comments/usePostResolveThread';
import usePostThread from '@/apis/semji/comments/usePostThread';
import usePostUnResolveThread from '@/apis/semji/comments/usePostUnResolveThread';
import usePutComment from '@/apis/semji/comments/usePutComment';
import { LOCATION, SCOPE_COMMENTS, USER_EVENTS } from '@/apis/semji/constants';
import useGetContentById from '@/apis/semji/contents/useGetContentById';
import usePostUserEvent from '@/apis/semji/users/usePostUserEvent';
import {
  CommentModel,
  ENUM_COMMENT_SIDE_PANEL_OPEN_STATUS,
  ENUM_SEMJI_THREAD_ATTRIBUTES,
  NewThreadModel,
  UseCommentsReturn,
} from '@/containers/Content/TinyMceComponents/Editor/hooks/useComment/useComments.types';
import useApiConfigurations from '@/hooks/useApiConfigurations';
import useCan from '@/hooks/useCan';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import { showErrorSnackbar } from '@/store/actions/ui';
import {
  EDITOR_COMMENT_REFETCH_FREQUENCY_MS,
  EDITOR_COMMENTS_ENABLED,
} from '@/utils/configurations/constants';
import { highlight, unhighlight } from '@/utils/highlight';
import { FEATURE_SET_COMMENT_IS_ENABLED } from '@/utils/organizationFeatureSet/constants';

import { CommentDOMUtils } from './commentDom.utils';
import {
  COMMENT_QUERY_PARAM,
  COMMENTS_ICON,
  FOCUSED_THREAD_ID_QUERY_PARAM,
  PENDING_COMMENT_ID,
  PENDING_THREAD_ID,
  SEMJI_THREAD_HIGHLIGHT_CLASS_NAME,
} from './constants';

export default function useComments({
  editorRef,
}: {
  editorRef: React.MutableRefObject<any>;
}): UseCommentsReturn {
  const { t } = useTranslation();
  const { contentId = '' } = useParams();

  const dispatch = useDispatch();
  const [query, setQuery] = useSearchParams();
  const queryClient = useQueryClient();

  // TO DO: move the store to typescript
  const user = useSelector((state) => state.user);

  const [newThread, setNewThread] = useState<NewThreadModel | null>(null);
  // updateComments = true when the editor is initialized and the content is inserted
  // updateComments = timestamp when the editor content is updated
  const [updateComments, setCommentsUpdate] = useState<number | boolean>(false);

  // TO DO: move the useCan HOC to typescript
  const isGlobalCommentsEnabled = useCan({ perform: EDITOR_COMMENTS_ENABLED });
  const { isFeatureEnabled: isOrganizationCommentsEnabled } = useOrganizationFeatureSet(
    FEATURE_SET_COMMENT_IS_ENABLED
  );
  const isCommentsEnabled: boolean = isOrganizationCommentsEnabled && isGlobalCommentsEnabled;
  const COMMENT_REFETCH_FREQUENCY: number | boolean =
    parseInt(useApiConfigurations(EDITOR_COMMENT_REFETCH_FREQUENCY_MS), 10) || false;

  // an empty string makes the annotate button disappear
  const COMMENTS_BUTTON: string = isCommentsEnabled ? 'annotate-alpha' : '';

  const openStatus: string | null = query.get(COMMENT_QUERY_PARAM);
  const focusedThreadId: string | null = query.get(FOCUSED_THREAD_ID_QUERY_PARAM);

  const queryRef = useRef<any | null | undefined>(null);
  const setQueryRef = useRef<any | null | undefined>(null);

  queryRef.current = query;
  setQueryRef.current = setQuery;

  const buttons = {
    COMMENTS_BUTTON,
  };

  const postUserEvent = usePostUserEvent({ userId: user.id });

  function postUserEventViewContentComments() {
    postUserEvent.mutate({ name: USER_EVENTS.VIEW_CONTENT_COMMENTS, resourceId: `${contentId}` });
  }

  const {
    data: getThreads,
    isFetching: isLoadingThreads,
    isPlaceholderData: isThreadsPlaceholderData,
  } = useGetThreads({
    enabled: !!contentId && isCommentsEnabled,
    onError: () => {
      dispatch(showErrorSnackbar(t('common:error.default')));
    },
    onSuccess: () => {
      if (!!openStatus) {
        postUserEventViewContentComments();
      }
    },
    refetchInterval: COMMENT_REFETCH_FREQUENCY,
    refetchOnWindowFocus: 'always',
  });
  const allThreads = getThreads ?? [];

  const getContentById = useGetContentById({
    contentId,
  });

  // TO DO: move all hooks to typescipt
  const deleteComment = useDeleteComment({
    onError: (error: any, comment: any, { previousThreads }: { previousThreads: any[] }) => {
      dispatch(showErrorSnackbar(t('common:error.default')));

      // restore the previousThreads list
      queryClient.setQueryData(
        [SCOPE_COMMENTS.GET_THREAD_BY_CONTENT_ID, contentId, LOCATION.EDITOR],
        [...previousThreads]
      );
    },
    onMutate: ({ commentId, threadId }: { commentId: string; threadId: string }) => {
      // replace the content of a comment in the allThreads
      const threadIndex = getThreadIndexFromThreads(threadId);
      if (threadIndex === -1) return;

      let newThreads = [...allThreads];
      newThreads[threadIndex] = {
        ...newThreads[threadIndex],
        comments: newThreads[threadIndex].comments.filter(
          (comment: CommentModel) => comment.id !== commentId
        ),
      };

      // handle with the optimistic update by adding it to the allThreads
      handleOptimisticUpdate({
        data: [...newThreads],
        key: [SCOPE_COMMENTS.GET_THREAD_BY_CONTENT_ID, contentId, LOCATION.EDITOR],
      });

      // store the previousThreads list in context to restore it in case of error
      return { previousThreads: [...allThreads] };
    },
  });

  const deleteThread = useDeleteThread({
    onError: (error: any, comment: any, { previousThreads }: { previousThreads: any[] }) => {
      dispatch(showErrorSnackbar(t('common:error.default')));

      // restore the previousThreads list
      queryClient.setQueryData(
        [SCOPE_COMMENTS.GET_THREAD_BY_CONTENT_ID, contentId, LOCATION.EDITOR],
        [...previousThreads]
      );
    },
    onMutate: ({ threadId }: { threadId: string }) => {
      // replace the content of a comment in the allThreads
      const newThreads = allThreads.filter((thread) => thread.thread.id !== threadId);

      unsetQueryParamThreadId();

      // handle with the optimistic update by adding it to the allThreads
      handleOptimisticUpdate({
        data: [...newThreads],
        key: [SCOPE_COMMENTS.GET_THREAD_BY_CONTENT_ID, contentId, LOCATION.EDITOR],
      });

      handleRemoveThread(threadId);

      // store the previousThreads list in context to restore it in case of error
      return { previousThreads: [...allThreads] };
    },
  });

  const postUnResolveThread = usePostUnResolveThread({
    onError: (
      err: any,
      { threadId }: { threadId: string },
      { previousThreads }: { previousThreads: any[] }
    ) => {
      dispatch(showErrorSnackbar(t('common:error.default')));

      // restore the previousThreads list
      queryClient.setQueryData(
        [SCOPE_COMMENTS.GET_THREAD_BY_CONTENT_ID, contentId, LOCATION.EDITOR],
        [...previousThreads]
      );
    },
    onMutate: ({ threadId }: { threadId: string }) => {
      // replace the content of a comment in the allThreads
      const threadIndex = getThreadIndexFromThreads(threadId);
      if (threadIndex === -1) return;

      let newThreads = [...allThreads];
      newThreads[threadIndex] = {
        ...newThreads[threadIndex],
        thread: { ...newThreads[threadIndex].thread, resolvedAt: null },
      };

      unsetQueryParamThreadId();

      // handle with the optimistic update by adding it to the allThreads
      handleOptimisticUpdate({
        data: [...newThreads],
        key: [SCOPE_COMMENTS.GET_THREAD_BY_CONTENT_ID, contentId, LOCATION.EDITOR],
      });

      // store the previousThreads list in context to restore it in case of error
      return { previousThreads: [...allThreads] };
    },
  });

  const postResolveThread = usePostResolveThread({
    onError: (
      err: any,
      { threadId }: { threadId: string },
      { previousThreads }: { previousThreads: any[] }
    ) => {
      // return it to the list maybe ?
      dispatch(showErrorSnackbar(t('common:error.default')));

      // restore the previousThreads list
      queryClient.setQueryData(
        [SCOPE_COMMENTS.GET_THREAD_BY_CONTENT_ID, contentId, LOCATION.EDITOR],
        [...previousThreads]
      );
    },
    onMutate: ({ threadId }: { threadId: string }) => {
      // replace the content of a comment in the allThreads
      const threadIndex = getThreadIndexFromThreads(threadId);
      if (threadIndex === -1) return;

      let newThreads = [...allThreads];
      newThreads[threadIndex] = {
        ...newThreads[threadIndex],
        thread: { ...newThreads[threadIndex].thread, resolvedAt: new Date().toISOString() },
      };

      unsetQueryParamThreadId();

      // handle with the optimistic update by adding it to the allThreads
      handleOptimisticUpdate({
        data: [...newThreads],
        key: [SCOPE_COMMENTS.GET_THREAD_BY_CONTENT_ID, contentId, LOCATION.EDITOR],
      });

      // store the previousThreads list in context to restore it in case of error
      return { previousThreads: [...allThreads] };
    },
  });

  const putComment = usePutComment({
    onError: (err: any, newContent: any, { previousThreads }: { previousThreads: any[] }) => {
      dispatch(showErrorSnackbar(t('common:error.default')));

      // restore the previousThreads list
      queryClient.setQueryData(
        [SCOPE_COMMENTS.GET_THREAD_BY_CONTENT_ID, contentId, LOCATION.EDITOR],
        [...previousThreads]
      );
    },
    onMutate: ({
      commentId,
      content,
      threadId,
    }: {
      commentId: string;
      content: string;
      threadId: string;
    }) => {
      // replace the content of a comment in the allThreads
      const threadIndex = getThreadIndexFromThreads(threadId);
      if (-1 === threadIndex) return;

      const commentIndex = getCommentIndexFromThreads(threadIndex, commentId);
      if (-1 === commentIndex) return;

      let newThreads = [...allThreads];
      newThreads[threadIndex] = {
        ...newThreads[threadIndex],
        comments: newThreads[threadIndex].comments.map((comment: CommentModel, index: number) =>
          index === commentIndex
            ? { ...comment, content, updatedAt: new Date().toISOString() }
            : comment
        ),
      };

      // handle with the optimistic update by adding it to the allThreads
      handleOptimisticUpdate({
        data: [...newThreads],
        key: [SCOPE_COMMENTS.GET_THREAD_BY_CONTENT_ID, contentId, LOCATION.EDITOR],
      });

      // store the previousThreads list in context to restore it in case of error
      return { previousThreads: [...allThreads] };
    },
  });

  const postComment = usePostComment({
    onError: (
      err: any,
      newComment: CommentModel,
      { previousThreads }: { previousThreads: any[] }
    ) => {
      dispatch(showErrorSnackbar(t('common:error.default')));

      // restore the previousThreads list
      queryClient.setQueryData(
        [SCOPE_COMMENTS.GET_THREAD_BY_CONTENT_ID, contentId, LOCATION.EDITOR],
        [...previousThreads]
      );
    },
    onMutate: ({ content, threadId }: { content: string; threadId: string }) => {
      // replace the content of a comment in the allThreads
      const threadIndex = getThreadIndexFromThreads(threadId);
      if (-1 === threadIndex) return;

      let newThreads = [...allThreads];
      newThreads[threadIndex] = {
        ...newThreads[threadIndex],
        comments: [...newThreads[threadIndex].comments, ...pendingComment(content, threadId)],
      };

      // handle with the optimistic update by adding it to the allThreads
      handleOptimisticUpdate({
        data: [...newThreads],
        key: [SCOPE_COMMENTS.GET_THREAD_BY_CONTENT_ID, contentId, LOCATION.EDITOR],
      });

      // store the previousThreads list in context to restore it in case of error
      return { previousThreads: [...allThreads] };
    },
    onSuccess: (
      { data }: { data: CommentModel },
      { content, threadId }: { content: string; threadId: string }
    ) => {
      // replace the content of a comment in the allThreads
      const threadIndex = getThreadIndexFromThreads(threadId);
      if (-1 === threadIndex) return;

      const commentIndex = getCommentIndexFromThreads(threadIndex, PENDING_COMMENT_ID);
      if (-1 === commentIndex) return;

      let newThreads = [...allThreads];
      newThreads[threadIndex] = {
        ...newThreads[threadIndex],
        comments: newThreads[threadIndex].comments.map((comment: CommentModel, index: number) =>
          index === commentIndex ? { ...data } : comment
        ),
      };

      // handle with the optimistic update by adding it to the allThreads
      handleOptimisticUpdate({
        data: [...newThreads],
        key: [SCOPE_COMMENTS.GET_THREAD_BY_CONTENT_ID, contentId, LOCATION.EDITOR],
      });
    },
  });

  const postThread = usePostThread({
    onError: () => {
      // TO DO:  of edge cases and handle them in a better way
      dispatch(showErrorSnackbar(t('common:error.default')));

      // reset the new thread to null
      setNewThread(null);
      editorRef.current.setDirty(true);
    },
    onMutate: ({ content }: { content: string }) => {
      setNewThread({
        comments: pendingComment(content, PENDING_THREAD_ID),
        thread: { id: PENDING_THREAD_ID },
      });
    },
    onSuccess: ({ data }: { data: any }, { content }: { content: string }) => {
      // After posting the thread, we need to post the comment
      postComment.mutate({ content, threadId: data.id });
      CommentDOMUtils.replacePendingCommentThread(data.id);

      // Set focus to the newId and not the pendingId
      setQueryParamThreadId(data.id);

      // handle with the optimistic update by adding it to the allThreads
      handleOptimisticUpdate({
        data: [
          {
            comments: pendingComment(content, data.id),
            thread: data,
          },
          ...allThreads,
        ],
        key: [SCOPE_COMMENTS.GET_THREAD_BY_CONTENT_ID, contentId, LOCATION.EDITOR],
      });
      editorRef.current.setDirty(true);

      // reset the new thread to null
      setNewThread(null);
    },
  });

  function handleRemoveThread(threadId: string): void {
    const elements = CommentDOMUtils.getElementsByDataAttribute(threadId);

    elements.forEach((element) => {
      unhighlight({
        dataAttribute: ENUM_SEMJI_THREAD_ATTRIBUTES.SEMJI_THREAD_ATTRIBUTE,
        datum: threadId,
        element,
        highlightClass: SEMJI_THREAD_HIGHLIGHT_CLASS_NAME,
      });
    });

    editorRef?.current?.setDirty(true);
  }

  function getThreadIndexFromThreads(threadId: string): number {
    const threadIndex = allThreads.findIndex((thread) => thread.thread.id === threadId);
    if (undefined === threadIndex) {
      return -1;
    }

    return threadIndex;
  }

  function getCommentIndexFromThreads(threadIndex: number, commentId: string): number {
    const commentIndex = allThreads[threadIndex]?.comments?.findIndex(
      (comment: CommentModel) => comment.id === commentId
    );
    if (undefined === commentIndex) {
      return -1;
    }

    return commentIndex;
  }

  function handleOptimisticUpdate({ key, data }: { key: string[]; data: any[] }): void {
    queryClient.cancelQueries({ queryKey: key });
    queryClient.setQueryData(key, data);
  }

  function pendingComment(content: string, threadId: string): CommentModel[] {
    return [
      {
        content,
        createdAt: new Date().toISOString(),
        createdById: user.id,
        id: PENDING_COMMENT_ID,
        threadId,
        updatedAt: null,
      },
    ];
  }

  function setQueryParamThreadId(threadId: string): void {
    if (!threadId) {
      return;
    }

    query.set(FOCUSED_THREAD_ID_QUERY_PARAM, threadId);
    setQuery(query, { replace: true });
  }

  function unsetQueryParamThreadId() {
    query.delete(FOCUSED_THREAD_ID_QUERY_PARAM);
    setQuery(query, { replace: true });
  }

  function focusOnThread(threadId: string): void {
    if (!openStatus) {
      return;
    }

    /**
     * Set query param to the threadId
     */
    setQueryParamThreadId(threadId);

    const elements = CommentDOMUtils.getElementsByDataAttribute(threadId);

    if (elements.length === 0) {
      return;
    }

    /**
     * scroll to the thread
     */
    elements[0]?.scrollIntoView({ block: 'nearest' });
  }

  function isFocusOnThread(threadId: string): boolean {
    return threadId === focusedThreadId;
  }

  function isPendingThread(threadId: string): boolean {
    return threadId === PENDING_THREAD_ID;
  }

  function openComments(type: string) {
    query.set(COMMENT_QUERY_PARAM, type);
    setQuery(query, { replace: true });

    // when open comments, mark all content comments as read
    postUserEventViewContentComments();
  }

  function closeComments() {
    query.delete(COMMENT_QUERY_PARAM);
    query.delete(FOCUSED_THREAD_ID_QUERY_PARAM);
    setQuery(query, { replace: true });
  }

  function onActionEditor() {
    // if a pending comment exists, that means we need to keep it
    if (queryRef.current.get(FOCUSED_THREAD_ID_QUERY_PARAM) === PENDING_THREAD_ID) {
      return;
    }

    highlight({
      dataAttribute: ENUM_SEMJI_THREAD_ATTRIBUTES.SEMJI_THREAD_ATTRIBUTE,
      datum: PENDING_THREAD_ID,
      highlightClass: SEMJI_THREAD_HIGHLIGHT_CLASS_NAME,
      removeInternalSelection: true,
    });

    queryRef.current?.set(FOCUSED_THREAD_ID_QUERY_PARAM, PENDING_THREAD_ID);
    queryRef.current?.set(
      COMMENT_QUERY_PARAM,
      ENUM_COMMENT_SIDE_PANEL_OPEN_STATUS.COMMENTS_OPEN_VALUE
    );
    setQueryRef.current?.(queryRef.current, { replace: true });

    setTimeout(() => {
      setNewThread({
        comments: [],
        thread: {
          id: PENDING_THREAD_ID,
        },
      });
    }, 100);
  }

  function onSetupEditor() {
    const aiPlaceholder = editorRef.current.dom.select('input.ai-placeholder');
    if (aiPlaceholder.length > 0) {
      editorRef.current.dom.setHTML(aiPlaceholder[0].parentElement, '<br data-mce-bogus="1">');
    }
  }

  function setupToolbarButtons(editor: any) {
    if (!isCommentsEnabled) {
      return;
    }

    // Add the comments icon to the editor on selection
    editor.ui.registry.addIcon(
      COMMENTS_ICON,
      `<svg width="135" height="27" viewBox="0 0 115 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.0887 17.8167L13.2133 16.3457H18.1225C18.5099 16.3457 18.8259 16.0297 18.8259 15.6423V8.60849C18.8259 8.22113 18.5099 7.90511 18.1225 7.90511H8.27518C7.88782 7.90511 7.5718 8.22113 7.5718 8.60849V15.6423C7.5718 16.0297 7.88782 16.3457 8.27518 16.3457H11.0887V17.8167ZM9.68194 20.5017V17.7524H8.27518C7.11088 17.7524 6.16504 16.8066 6.16504 15.6423V8.60849C6.16504 7.4442 7.11088 6.49835 8.27518 6.49835H18.1225C19.2868 6.49835 20.2326 7.4442 20.2326 8.60849V15.6423C20.2326 16.8066 19.2868 17.7524 18.1225 17.7524H13.6528L9.68194 20.5017Z" fill="#FF4D64"/>
            <path d="M13.9026 11.4211H15.3094C15.6979 11.4211 16.0128 11.736 16.0128 12.1245C16.0128 12.513 15.6979 12.8279 15.3094 12.8279H13.9026V14.2346C13.9026 14.6231 13.5877 14.938 13.1993 14.938C12.8108 14.938 12.4959 14.6231 12.4959 14.2346V12.8279H11.0891C10.7007 12.8279 10.3857 12.513 10.3857 12.1245C10.3857 11.736 10.7007 11.4211 11.0891 11.4211H12.4959V10.0144C12.4959 9.62589 12.8108 9.31097 13.1993 9.31097C13.5877 9.31097 13.9026 9.62589 13.9026 10.0144V11.4211Z" fill="#FF4D64"/>
            <text x="30" y="17" fill="#FF4D64">${t('comments:add-comment')}</text>
        </svg>`
    );

    // register the button and the associated actions
    editor.ui.registry.addButton(COMMENTS_BUTTON, {
      icon: COMMENTS_ICON,
      onAction: onActionEditor,
      onSetup: onSetupEditor,
    });
  }

  /**
   * When the thread is resolved, we set the attribute SEMJI_THREAD_RESOLVED_ATTRIBUTE to true/false
   * When the comments are disabled, we set the attribute SEMJI_THREAD_DISABLED_ATTRIBUTE to true/false
   */
  useEffect(() => {
    if (!isThreadsPlaceholderData && !isLoadingThreads && false !== updateComments) {
      // Handle setting focus/resolved/disabled attributes
      let threads = allThreads;

      // add the new thread to the list of threads
      // it helps to handle the focus on the new thread
      if (newThread) {
        threads = [newThread, ...allThreads];
      }

      CommentDOMUtils.handleThreadElementAttributes({
        focusedThreadId,
        isCommentsEnabled,
        threads: threads.map(({ thread }) => ({
          id: thread.id,
          resolved: !!thread.resolvedAt,
        })),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isThreadsPlaceholderData, isLoadingThreads, allThreads, newThread, updateComments]);

  useEffect(() => {
    // only when we refresh and we already been focused on a thread, to set the cursor location on the first node of the thread
    if (true === updateComments && focusedThreadId && focusedThreadId !== PENDING_THREAD_ID) {
      const targetNode = CommentDOMUtils.getElementsByDataAttribute(focusedThreadId)[0];

      // if focus thread does not exist anymore, we do nothing
      if (!targetNode) return;

      editorRef.current.selection.setCursorLocation(targetNode, 0);
    }

    if (true === updateComments && focusedThreadId && focusedThreadId === PENDING_THREAD_ID) {
      unsetQueryParamThreadId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateComments]);

  /**
   * When the thread is focused, we set the attribute SEMJI_THREAD_FOCUSED_ATTRIBUTE to true/false
   */
  useEffect(() => {
    // When focusedThreadId is null, it means that the selection has changed to something else
    // we need to remove the previous pending thread selection
    if (focusedThreadId !== PENDING_THREAD_ID) {
      handleRemoveThread(PENDING_THREAD_ID);
      setNewThread(null);
    }

    CommentDOMUtils.handleFocusByThreadId(focusedThreadId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusedThreadId]);

  return {
    allThreads,
    buttons,
    closeComments,
    deleteComment,
    deleteThread,
    focusOnThread,
    isCommentsEnabled,
    isFocusOnThread,
    isLoadingThreads: isThreadsPlaceholderData && isLoadingThreads,
    isPendingThread,
    nbUnreadComments: getContentById.data?.nbUnreadComments || 0,
    newThread,
    openComments,
    openStatus,
    postComment,
    postResolveThread,
    postThread,
    postUnResolveThread,
    putComment,
    setCommentsUpdate,
    setupToolbarButtons,
    isCommentEnabled: isCommentsEnabled,
  };
}
