import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  QUERY_PARAM_PAGE,
  QUERY_PARAM_PER_PAGE,
} from '@/components/AGGrid/hooks/useGridPagination/const';
import { DynamicFilters, ENUM_FILTER_TYPE, FilterType } from '@/components/DynamicFilters';
import { AddFilter } from '@/components/DynamicFilters/AddFilter';
import CalendarIcon from '@/components/icons/CalendarIcon';
import { getBrandTrafficFilter } from '@/containers/Competitors/components/HeaderFilters/Filters/BrandTrafficFilter/config';
import {
  BRAND_TRAFFIC_TYPES,
  BRANDED_TRAFFIC,
  getBrandTrafficOptions,
  UNBRANDED_TRAFFIC,
} from '@/containers/Competitors/components/HeaderFilters/Filters/BrandTrafficFilter/const';
import { getCategoryFilter } from '@/containers/Competitors/components/HeaderFilters/Filters/CategoryFilter/config';
import { getCategoriesOptions } from '@/containers/Competitors/components/HeaderFilters/Filters/CategoryFilter/const';
import { getClusterFilter } from '@/containers/Competitors/components/HeaderFilters/Filters/ClusterFilter/config';
import { getCompetitorFilter } from '@/containers/Competitors/components/HeaderFilters/Filters/CompetitorFilter/config';
import { getFunnelFilter } from '@/containers/Competitors/components/HeaderFilters/Filters/FunnelFilter/config';
import { getFunnelOptions } from '@/containers/Competitors/components/HeaderFilters/Filters/FunnelFilter/const';
import { getMyPositionFilter } from '@/containers/Competitors/components/HeaderFilters/Filters/MyPositionFilter/config';
import { getMyPositionOptions } from '@/containers/Competitors/components/HeaderFilters/Filters/MyPositionFilter/const';
import { getPersonaFilter } from '@/containers/Competitors/components/HeaderFilters/Filters/PersonaFilter/config';
import { getPositionFilter } from '@/containers/Competitors/components/HeaderFilters/Filters/PositionFilter/config';
import { getPositionOptions } from '@/containers/Competitors/components/HeaderFilters/Filters/PositionFilter/const';
import { getTailTrafficFilter } from '@/containers/Competitors/components/HeaderFilters/Filters/TailTrafficFilter/config';
import {
  getTailTrafficOptions,
  LONG_TAIL_TRAFFIC,
  SHORT_TAIL_TRAFFIC,
  TAIL_TRAFFIC_TYPES,
} from '@/containers/Competitors/components/HeaderFilters/Filters/TailTrafficFilter/const';
import { HeaderFiltersProps } from '@/containers/Competitors/components/HeaderFilters/HeaderFilters.types';
import {
  BRAND_TRAFFIC_TYPE_FILTER_QUERY_PARAM,
  CATEGORY_FILTER_QUERY_PARAM,
  CLUSTER_FILTER_QUERY_PARAM,
  COMPETITOR_FILTER_QUERY_PARAM,
  FIRST_MONTH_OF_YEAR,
  FUNNEL_STAGE_FILTER_QUERY_PARAM,
  PERSONA_FILTER_QUERY_PARAM,
  RANKED_WORKSPACE_URL_POSITION_QUERY_PARAM,
  RETRIEVAL_DATE_FILTER_QUERY_PARAM,
  TAIL_TRAFFIC_TYPE_FILTER_QUERY_PARAM,
  URL_POSITION_FILTER_QUERY_PARAM,
} from '@/containers/Competitors/utils/constants';
import {
  BOOLEAN_OPERATOR,
  MULTI_SEARCH_OPERATOR,
  STRING_OPERATOR,
} from '@/hooks/useFilters/useFilters.types';
import { CompetitorTopPagesFilters } from '@/types/competitors';
import { ArrayUtils } from '@/utils/array/Array.utils';

export default function HeaderFilters({
  clusters,
  competitorWebsites = [],
  personas,
  filterHook,
  retrievalDates = [],
  reportDate,
  disabledFilters = {},
  disabledPagination,
}: HeaderFiltersProps) {
  const [query, setQuery] = filterHook.searchParamsHook;
  const { websiteId } = useParams();

  const { t } = useTranslation();
  const funnelTypes = getFunnelOptions();
  const tailTrafficTypes = getTailTrafficOptions();
  const categoriesList = getCategoriesOptions();
  const brandTrafficTypes = getBrandTrafficOptions();
  const urlPositionOptions = getPositionOptions();
  const myPositionOptions = getMyPositionOptions();

  const competitorsParams = filterHook.findFilterByKey(COMPETITOR_FILTER_QUERY_PARAM, {
    targetOperator: MULTI_SEARCH_OPERATOR.multi_search,
  });
  const clustersParams = filterHook.findFilterByKey(CLUSTER_FILTER_QUERY_PARAM, {
    targetOperator: MULTI_SEARCH_OPERATOR.multi_search,
  });
  const urlCategoriesParams = filterHook.findFilterByKey(CATEGORY_FILTER_QUERY_PARAM, {
    targetOperator: MULTI_SEARCH_OPERATOR.multi_search,
  });
  const funnelStagesParams = filterHook.findFilterByKey(FUNNEL_STAGE_FILTER_QUERY_PARAM, {
    targetOperator: MULTI_SEARCH_OPERATOR.multi_search,
  });
  const personasParams = filterHook.findFilterByKey(PERSONA_FILTER_QUERY_PARAM, {
    targetOperator: MULTI_SEARCH_OPERATOR.multi_search,
  });
  const brandTrafficTypeParams = filterHook.findFilterByKey(BRAND_TRAFFIC_TYPE_FILTER_QUERY_PARAM, {
    targetOperator: BOOLEAN_OPERATOR.boolean,
  });
  const tailTrafficTypeParams = filterHook.findFilterByKey(TAIL_TRAFFIC_TYPE_FILTER_QUERY_PARAM, {
    targetOperator: BOOLEAN_OPERATOR.boolean,
  });
  const retrievalDateParams = filterHook.findFilterByKey(RETRIEVAL_DATE_FILTER_QUERY_PARAM, {
    targetOperator: STRING_OPERATOR.equals,
  });
  const urlPositionParams = filterHook.findFilterByKey(URL_POSITION_FILTER_QUERY_PARAM);
  const myPositionParams = filterHook.findFilterByKey(RANKED_WORKSPACE_URL_POSITION_QUERY_PARAM);

  const [showedFilters, setShowedFilters] = useState<string[]>(() => {
    let initialFilters = [];
    if (urlPositionParams) initialFilters.push(URL_POSITION_FILTER_QUERY_PARAM);
    if (myPositionParams) initialFilters.push(RANKED_WORKSPACE_URL_POSITION_QUERY_PARAM);
    return initialFilters;
  });

  const secondaryFilters: FilterType[] = [
    getPositionFilter({
      updateFilter,
    }),
    getMyPositionFilter({
      updateFilter,
    }),
  ];

  const competitorsFilter = competitorsParams ?? competitorWebsites.map(({ id }) => id);
  const clusterFilter = clustersParams ?? clusters.map(({ id }) => id);
  const urlCategoryFilter = urlCategoriesParams ?? categoriesList.map(({ id }) => id);
  const funnelStageFilter = funnelStagesParams ?? funnelTypes.map(({ id }) => id);
  const personaFilter = personasParams ?? personas.map(({ id }) => id);
  const brandTrafficTypeFilter =
    typeof brandTrafficTypeParams === 'boolean'
      ? brandTrafficTypeParams
        ? [BRANDED_TRAFFIC]
        : [UNBRANDED_TRAFFIC]
      : BRAND_TRAFFIC_TYPES;
  const tailTrafficTypeFilter =
    typeof tailTrafficTypeParams === 'boolean'
      ? filterHook.findFilterByKey(TAIL_TRAFFIC_TYPE_FILTER_QUERY_PARAM, {
          targetOperator: BOOLEAN_OPERATOR.boolean,
        }) === false
        ? [SHORT_TAIL_TRAFFIC]
        : [LONG_TAIL_TRAFFIC]
      : TAIL_TRAFFIC_TYPES;
  const reportDateTime = DateTime.fromISO(reportDate);
  const urlPositionFilter = urlPositionOptions?.find(({ value }) => {
    return value[0] === urlPositionParams?.gte && value[1] === urlPositionParams?.lte;
  })?.value;
  const customUrlPositionFilter: number[] | undefined =
    urlPositionParams && !urlPositionFilter
      ? [urlPositionParams?.gte, urlPositionParams?.lte]
      : undefined;
  const myPositionFilter = myPositionOptions?.find(({ value }) => {
    if (myPositionParams?.exists === false && ArrayUtils.fastEqual(value, [-1, -1])) return true;
    return value[0] === myPositionParams?.gte && value[1] === myPositionParams?.lte;
  })?.value;
  const customMyPositionFilter: number[] | undefined =
    myPositionParams && !myPositionFilter
      ? [myPositionParams?.gte, myPositionParams?.lte]
      : undefined;

  // Remove pagination params
  useEffect(() => {
    if (disabledPagination) {
      query.delete(QUERY_PARAM_PAGE);
      query.delete(QUERY_PARAM_PER_PAGE);
      setQuery(query, { replace: true });
    }
    /* note: websiteId is required to clean the pagination params after
    /* Duel component change the websiteId by keeping all search params */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabledPagination, websiteId]);

  useEffect(() => {
    if (!disabledFilters.date && retrievalDates.length > 0 && !retrievalDateParams) {
      const defaultDate = DateTime.fromISO(retrievalDates[0]).toFormat('yyyy-MM-dd');
      filterHook.addFilters(
        {
          date: {
            equals: defaultDate,
          },
        },
        { replace: true }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retrievalDates, disabledFilters.date]);

  // Remove filters that are disabled
  useEffect(() => {
    Object.entries(disabledFilters).forEach(([key, value]) => {
      if (value) {
        filterHook.removeFilterByKey(key as keyof CompetitorTopPagesFilters, { replace: true });
      }
    });
    /* note: websiteId is required to clean the filters after
    /* Duel component change the websiteId by keeping all search params */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabledFilters, websiteId]);

  function updateFilter(targetKey: string, value: any) {
    switch (targetKey) {
      case COMPETITOR_FILTER_QUERY_PARAM:
        if (value.length === competitorWebsites.length) {
          filterHook.removeFilterByKey(COMPETITOR_FILTER_QUERY_PARAM);
        } else {
          filterHook.addFilters({
            competitorWebsiteId: {
              multi_search: value,
            },
          });
        }
        break;
      case CLUSTER_FILTER_QUERY_PARAM:
        if (value.length === clusters.length) {
          filterHook.removeFilterByKey(CLUSTER_FILTER_QUERY_PARAM);
        } else {
          filterHook.addFilters({
            cluster: {
              multi_search: value,
            },
          });
        }
        break;
      case CATEGORY_FILTER_QUERY_PARAM:
        if (value.length === categoriesList.length) {
          filterHook.removeFilterByKey(CATEGORY_FILTER_QUERY_PARAM);
        } else {
          filterHook.addFilters({
            category: {
              multi_search: value,
            },
          });
        }
        break;
      case BRAND_TRAFFIC_TYPE_FILTER_QUERY_PARAM:
        if (value.length === brandTrafficTypes.length) {
          filterHook.removeFilterByKey(BRAND_TRAFFIC_TYPE_FILTER_QUERY_PARAM);
        } else {
          filterHook.addFilters({
            isBranded: {
              boolean: !(value.length === 1 && value.includes(UNBRANDED_TRAFFIC)),
            },
          });
        }
        break;
      case FUNNEL_STAGE_FILTER_QUERY_PARAM:
        if (value.length === funnelTypes.length) {
          filterHook.removeFilterByKey(FUNNEL_STAGE_FILTER_QUERY_PARAM);
        } else {
          filterHook.addFilters({
            funnel: {
              multi_search: value,
            },
          });
        }
        break;
      case PERSONA_FILTER_QUERY_PARAM:
        if (value.length === personas.length) {
          filterHook.removeFilterByKey(PERSONA_FILTER_QUERY_PARAM);
        } else {
          filterHook.addFilters({
            persona: {
              multi_search: value,
            },
          });
        }
        break;
      case TAIL_TRAFFIC_TYPE_FILTER_QUERY_PARAM:
        if (value.length === tailTrafficTypes.length) {
          filterHook.removeFilterByKey(TAIL_TRAFFIC_TYPE_FILTER_QUERY_PARAM);
        } else {
          filterHook.addFilters({
            isLongTail: {
              boolean: !(value.length === 1 && value.includes(SHORT_TAIL_TRAFFIC)),
            },
          });
        }
        break;
      case RETRIEVAL_DATE_FILTER_QUERY_PARAM:
        filterHook.addFilters({
          date: {
            equals: value,
          },
        });
        break;
      case URL_POSITION_FILTER_QUERY_PARAM:
        if (!value) {
          filterHook.removeFilterByKey(URL_POSITION_FILTER_QUERY_PARAM);
        } else {
          filterHook.addFilters({
            urlPosition: {
              gte: value[0],
              lte: value[1],
            },
          });
        }
        break;
      case RANKED_WORKSPACE_URL_POSITION_QUERY_PARAM:
        if (!value) {
          filterHook.removeFilterByKey(RANKED_WORKSPACE_URL_POSITION_QUERY_PARAM);
        } else {
          if (ArrayUtils.fastEqual([-1, -1], value)) {
            filterHook.addFilters({
              rankedWorkspaceUrlPosition: {
                exists: false,
              },
            });
            return;
          } else {
            filterHook.addFilters({
              rankedWorkspaceUrlPosition: {
                gte: value[0],
                lte: value[1],
              },
            });
          }
        }
        break;
      default:
        return;
    }
  }

  function showFilter(name: string) {
    setShowedFilters((prevState) => [...prevState, name]);
  }

  function onCloseSecondaryFilter(key: string) {
    setShowedFilters((prevState) => prevState.filter((savedKed) => savedKed !== key));
  }

  return (
    <DynamicFilters
      AddFilterElement={
        <AddFilter
          secondaryFilters={secondaryFilters.filter(({ key }) => !showedFilters.includes(key))}
          showFilter={showFilter}
        />
      }
      filterHook={filterHook}
      filters={(
        [
          {
            ...getCompetitorFilter({
              competitorWebsites,
              filter: competitorsFilter,
              updateFilter,
            }),
            disabled: !!disabledFilters.competitorWebsiteId,
          },
          getClusterFilter({ clusters, filter: clusterFilter, updateFilter }),
          getCategoryFilter({ filter: urlCategoryFilter, updateFilter }),
          getBrandTrafficFilter({ filter: brandTrafficTypeFilter, updateFilter }),
          getFunnelFilter({ filter: funnelStageFilter, updateFilter }),
          getPersonaFilter({ filter: personaFilter, personas, updateFilter }),
          getTailTrafficFilter({ filter: tailTrafficTypeFilter, updateFilter }),
          showedFilters.includes(URL_POSITION_FILTER_QUERY_PARAM) &&
            getPositionFilter({
              customRange: customUrlPositionFilter,
              filter: urlPositionFilter,
              onClose: onCloseSecondaryFilter,
              updateFilter,
            }),
          showedFilters.includes(RANKED_WORKSPACE_URL_POSITION_QUERY_PARAM) &&
            getMyPositionFilter({
              customRange: customMyPositionFilter,
              filter: myPositionFilter,
              onClose: onCloseSecondaryFilter,
              updateFilter,
            }),
          // will be placed in the grid header
          !disabledFilters.date && {
            icon: <CalendarIcon />,
            key: 'retrievalDate',
            name: t('competitors:filters.analyzed-data'),
            onValidate: (value) => updateFilter(RETRIEVAL_DATE_FILTER_QUERY_PARAM, value),
            options: retrievalDates.map((retrievalDate) => ({
              fixed:
                DateTime.fromISO(retrievalDate).month === FIRST_MONTH_OF_YEAR &&
                DateTime.fromISO(retrievalDate).year !== reportDateTime.year
                  ? 'bottom'
                  : undefined,
              label: DateTime.fromISO(retrievalDate).toFormat('LLLL yyyy'),
              selected:
                retrievalDateParams === DateTime.fromISO(retrievalDate).toFormat('yyyy-MM-dd'),
              value: DateTime.fromISO(retrievalDate).toFormat('yyyy-MM-dd'),
            })),
            type: ENUM_FILTER_TYPE.SELECT,
          },
        ] as FilterType[]
      ).filter(Boolean)}
    />
  );
}
