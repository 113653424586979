import './KeywordAssociation.scss';

import TextField from '@material-ui/core/TextField';
import { useEffect } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import UrlSuggest from '@/components/Autocomplete/UrlSuggest';
import CloseIcon from '@/components/icons/CloseIcon';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import { CONTENT_STATUS } from '@/containers/AddToPlanning/AddToPlanning.types';
import { isDownArrowHotkey, isEnterHotkey, isUpArrowHotkey } from '@/utils/keyboard';

const StyledUrlSuggest = styled(UrlSuggest)`
  && {
    & > div {
      margin: 0;
      align-self: flex-end;
      width: 100%;
      box-sizing: border-box;
    }
  }
`;

function KeywordAssociation({
  type,
  handleTitleChange,
  handleRemoveFromContentToAdd,
  nextContentId,
  previousContentId,
  index,
  handleAddToPlanning,
  isButtonDisabled,
  contentsLength,
  draftTitle,
  draftUrl,
  keyword,
  id,
  urlError,
  pages,
  handleUrlChange,
  handleFocusKeywordChange,
  focusKeywordError,
}: {
  type: string;
  handleTitleChange: (id: string, title: string) => void;
  handleRemoveFromContentToAdd: (id: string) => void;
  nextContentId: string;
  previousContentId: string;
  index: number;
  handleAddToPlanning: () => void;
  isButtonDisabled: boolean;
  contentsLength: number;
  draftTitle: string;
  draftUrl: string;
  keyword: string;
  id: string;
  urlError: string;
  pages: any;
  handleUrlChange: (id: string, url: string) => void;
  handleFocusKeywordChange: (id: string, keyword: string) => void;
  focusKeywordError: string;
}): JSX.Element {
  const { t } = useTranslation();
  const textValue = type === CONTENT_STATUS.NEW_CONTENT ? draftTitle : draftUrl;

  function handleRemove() {
    handleRemoveFromContentToAdd(id);
  }

  useEffect(() => {
    if (index === 0) {
      document.getElementById(id)?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  function handleKeyDown(e) {
    // if the touch is Enter and we are on the last element and the button is valid
    if (isEnterHotkey(e) && index === contentsLength - 1 && !isButtonDisabled) {
      handleAddToPlanning();
    }
    // if the touch is arrow up and this its not the first element or if the touch is enter or down arrow and its not last element
    if (
      (isUpArrowHotkey(e) && index !== 0) ||
      ((isEnterHotkey(e) || isDownArrowHotkey(e)) && index !== contentsLength - 1)
    ) {
      e.preventDefault();
      const input = document.getElementById(isUpArrowHotkey(e) ? previousContentId : nextContentId);
      input?.focus();
      input?.setSelectionRange(input.value.length, input.value.length);
    }
  }

  function sortFunctionPages(a, b) {
    if (!a.inStock && !!a.draft && (b.inStock || !b.draft)) return 1;
    return -1;
  }

  function onUrlChange(newUrl) {
    handleUrlChange(id, newUrl);
  }

  function onFocusKeywordChange(e) {
    handleFocusKeywordChange(id, e.target.value);
  }

  function onTitleChange(e) {
    handleTitleChange(id, e.target.value);
  }

  return (
    <div className="add-to-planning-dialog-keyword-association">
      <div className="add-to-planning-dialog-keyword-association__input">
        <TextField
          error={!!focusKeywordError}
          helperText={focusKeywordError || ' '}
          placeholder={t('add-to-planning:associate.focus-keyword-placeholder')}
          value={keyword}
          onChange={onFocusKeywordChange}
          onKeyDown={handleKeyDown}
        />
        {type === CONTENT_STATUS.NEW_CONTENT ? (
          <TextField
            helperText={' '}
            id={id}
            placeholder={t('add-to-planning:associate.title-placeholder')}
            value={textValue}
            onChange={onTitleChange}
            onKeyDown={handleKeyDown}
          />
        ) : (
          <StyledUrlSuggest
            helperText={urlError || ' '}
            inputValue={textValue}
            isError={!!urlError}
            options={pages?.sort(sortFunctionPages)}
            placeholder={t('add-to-planning:associate.url-placeholder')}
            rowsMax="1"
            value={pages?.find((p) => p?.url === textValue) || null}
            onChange={onUrlChange}
            onKeyDown={handleKeyDown}
          />
        )}
      </div>
      {contentsLength > 1 && (
        <div className="add-to-planning-dialog-keyword-association__input__close">
          <TooltipComponent title={t('add-to-planning:associate.remove')}>
            <CloseIcon
              className="add-to-planning-dialog-keyword-association__input__close__icon"
              onClick={handleRemove}
            />
          </TooltipComponent>
        </div>
      )}
    </div>
  );
}

export default KeywordAssociation;
