import { useMutation } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import apiClient from '@/apis/semji/apiClient';

export default function usePostInviteUsersOnWorkspace(props) {
  const { post } = apiClient;
  const workspaceId = useSelector((state) => state.defaultWorkspaceId || '');

  return useMutation({
    mutationFn: async (users) => {
      await post(`/workspaces/${workspaceId}/invite_users`, {
        users,
      });
    },

    ...props,
  });
}
