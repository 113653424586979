import React from 'react';

import SvgWrapper from './SvgWrapper';

const OutlinedDoubleUserIcon = (props) => (
  <SvgWrapper {...props} viewBox="0 0 23 17">
    <path d="M23.012 16.488C22.949 13.801 20.234 11.489 16.491 10.879V9.505C16.983 9.032 17.333 8.298 17.562 7.672C17.894 7.506 18.186 7.136 18.356 6.639C18.594 5.951 18.502 5.316 18.15 5.01C18.178 4.772 18.196 4.529 18.165 4.287C18.086 3.624 18.23 3.249 18.359 2.919C18.465 2.642 18.588 2.328 18.465 1.974C18.023 0.701 16.738 0 14.847 0L14.583 0.005C13.27 0.052 12.876 0.605 12.612 1.12C12.579 1.182 12.535 1.266 12.535 1.271C10.823 1.424 10.838 2.84 10.851 3.978L10.854 4.347C10.854 4.552 10.863 4.766 10.88 4.986C10.455 5.286 10.376 5.991 10.701 6.723C10.886 7.138 11.153 7.452 11.45 7.615C11.693 8.289 12.075 9.085 12.629 9.58V10.863C8.831 11.452 6.075 13.77 6.012 16.488L6 17H23.023L23.012 16.488ZM7.066 16C7.458 13.906 9.925 12.179 13.188 11.796L13.629 11.744V9.078L13.413 8.928C13.02 8.656 12.622 7.981 12.323 7.077L12.24 6.796L11.946 6.745C11.893 6.726 11.738 6.592 11.616 6.317C11.541 6.149 11.512 6.005 11.504 5.902L12.014 6.045L11.918 5.296C11.876 4.966 11.854 4.645 11.854 4.346L11.851 3.966C11.836 2.625 11.902 2.332 12.624 2.267C13.169 2.219 13.376 1.818 13.5 1.578C13.65 1.286 13.78 1.035 14.62 1.004L14.847 1C15.676 1 17.126 1.169 17.516 2.282C17.516 2.325 17.464 2.459 17.426 2.557C17.281 2.931 17.062 3.496 17.172 4.41C17.196 4.598 17.165 4.834 17.132 5.085L17.043 5.89L17.484 5.842C17.492 5.946 17.48 6.111 17.409 6.314C17.312 6.603 17.167 6.752 17.172 6.768H16.812L16.698 7.11C16.415 7.963 16.048 8.607 15.689 8.878L15.491 9.028V11.754L15.929 11.809C19.14 12.21 21.57 13.932 21.959 16.001L7.066 16Z" />
    <mask id="maskDoubleUser0" maskUnits="userSpaceOnUse" style={{ maskType: 'alpha' }} x="2" y="0">
      <path
        d="M19.012 16.488C18.949 13.801 16.234 11.489 12.491 10.879V9.505C12.983 9.032 13.333 8.298 13.562 7.672C13.894 7.506 14.186 7.136 14.356 6.639C14.594 5.951 14.502 5.316 14.15 5.01C14.178 4.772 14.196 4.529 14.165 4.287C14.086 3.624 14.23 3.249 14.359 2.919C14.465 2.642 14.588 2.328 14.465 1.974C14.023 0.701 12.738 0 10.847 0L10.583 0.005C9.27 0.052 8.876 0.605 8.612 1.12C8.579 1.182 8.535 1.266 8.535 1.271C6.823 1.424 6.838 2.84 6.851 3.978L6.854 4.347C6.854 4.552 6.863 4.766 6.88 4.986C6.455 5.286 6.376 5.991 6.701 6.723C6.886 7.138 7.153 7.452 7.45 7.615C7.693 8.289 8.075 9.085 8.629 9.58V10.863C4.831 11.452 2.075 13.77 2.012 16.488L2 17H19.023L19.012 16.488ZM3.066 16C3.458 13.906 5.925 12.179 9.188 11.796L9.629 11.744V9.078L9.413 8.928C9.02 8.656 8.622 7.981 8.323 7.077L8.24 6.796L7.946 6.745C7.893 6.726 7.738 6.592 7.616 6.317C7.541 6.149 7.512 6.005 7.504 5.902L8.014 6.045L7.918 5.296C7.876 4.966 7.854 4.645 7.854 4.346L7.851 3.966C7.836 2.625 7.902 2.332 8.624 2.267C9.169 2.219 9.376 1.818 9.5 1.578C9.65 1.286 9.78 1.035 10.62 1.004L10.847 1C11.676 1 13.126 1.169 13.516 2.282C13.516 2.325 13.464 2.459 13.426 2.557C13.281 2.931 13.062 3.496 13.172 4.41C13.196 4.598 13.165 4.834 13.132 5.085L13.043 5.89L13.484 5.842C13.492 5.946 13.48 6.111 13.409 6.314C13.312 6.603 13.167 6.752 13.172 6.768H12.812L12.698 7.11C12.415 7.963 12.048 8.607 11.689 8.878L11.491 9.028V11.754L11.929 11.809C15.14 12.21 17.57 13.932 17.959 16.001H3.066V16Z"
        fill="black"
      />
    </mask>
    <g mask="url(#maskDoubleUser0)">
      <path
        clipRule="evenodd"
        d="M13.8469 0H0.0119629V17H4.99994L5.01194 16.488C5.07494 13.77 8.49994 10.5 10.9999 10.5V9.5C10.6541 9.19102 10.6204 8.79593 10.5848 8.37869C10.5634 8.1275 10.5413 7.86828 10.4499 7.615C10.1529 7.452 9.88594 7.138 9.70094 6.723C9.37594 5.991 9.45494 5.286 9.87994 4.986C9.86294 4.766 9.85394 4.552 9.85394 4.347L9.85094 3.978C9.83794 2.84 10.012 1 12.012 0.5C12.2452 0.0449409 12.2677 0.0442879 13.1756 0.0178754C13.2952 0.0143964 13.4301 0.0104703 13.5829 0.005L13.8469 0ZM22.012 16.4892L22.0119 16.488C21.9489 13.801 19.2339 11.489 15.4909 10.879V9.505C15.9829 9.032 16.3329 8.298 16.5619 7.672C16.8939 7.506 17.1859 7.136 17.3559 6.639C17.5939 5.951 17.5019 5.316 17.1499 5.01C17.1779 4.772 17.1959 4.529 17.1649 4.287C17.0859 3.624 17.2299 3.249 17.3589 2.919L17.3624 2.91006C17.4676 2.63525 17.5866 2.3242 17.4649 1.974C17.0229 0.701 15.7379 0 13.8469 0H22.012V16.4892Z"
        fillRule="evenodd"
      />
    </g>
  </SvgWrapper>
);
export default OutlinedDoubleUserIcon;
