import styled from 'styled-components/macro';

import { NoEllipsisCell } from '@/components/Pages/ContentIdeas/common';
import PrimaryButton from '@/design-system/components/Button/PrimaryButton';

const EmptyStateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  && {
    font-weight: 500;
  }
`;

function EmptyState({ altImage, image, title, text, buttonText, onClick }) {
  return (
    <EmptyStateWrapper>
      <img alt={altImage} src={image} />
      <NoEllipsisCell align="center" margin="40px 0 0 0" noPadding size="lg" weight="strong">
        {title}
      </NoEllipsisCell>
      <NoEllipsisCell
        align="center"
        color="dark080"
        margin="20px 0 20px 0"
        noPadding
        weight="medium"
      >
        {text}
      </NoEllipsisCell>
      {buttonText && onClick && (
        <StyledPrimaryButton onClick={onClick}>{buttonText}</StyledPrimaryButton>
      )}
    </EmptyStateWrapper>
  );
}

export default EmptyState;
