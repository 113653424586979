import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchRoutes, useLocation } from 'react-router-dom';

import CreditLimitReachedDialog from '@/components/Dialog/CreditLimitReachedDialog';
import { ONLINE_PAGE_TAB } from '@/containers/Content/constants';
import SidePanel from '@/containers/Content/SidePanel/SidePanel/SidePanel';
import { ENUM_TAB_LABEL } from '@/containers/Content/SidePanel/SidePanelTabs';
import {
  EDITOR_ONLY_TABS,
  ENUM_SIDE_PANEL_VARIANTS,
} from '@/containers/Content/SidePanel/SidePanelTabs/const';
import SidePanelTabs from '@/containers/Content/SidePanel/SidePanelTabs/SidePanelTabs';
import { closeCreditLimitDialog } from '@/store/actions/report';
import { selectCurrentWorkspaceIsStaticContentEnabled } from '@/store/selectors/selectCurrentWorkspaceIsStaticContentEnabled';

import { ENUM_SIDE_PANEL_STATUS } from './SidePanelContainer.types';

interface SidePanelContainerProps {
  loading: boolean;
  tab: string;
  isReadOnly: boolean;
  url: string;
  showMarkAsPublishedDialog: boolean;
  setTopKeywordUpdating?: any;
  setSidePanelStatus: any;
  sidePanelStatus: ENUM_SIDE_PANEL_STATUS;
  expandDisabled?: boolean;
  toggleDisabled?: boolean;
}
function SidePanelContainer({
  setSidePanelStatus,
  sidePanelStatus,
  loading,
  isReadOnly,
  showMarkAsPublishedDialog,
  url,
  setTopKeywordUpdating,
  tab,
  expandDisabled,
  toggleDisabled,
}: SidePanelContainerProps) {
  const isDraft = ['content', 'draft'].includes(tab);
  const dispatch = useDispatch();

  const [selectedTab, setSelectedTab] = useState<ENUM_TAB_LABEL>(ENUM_TAB_LABEL.Seo);
  const isSidePanelOpen = useSelector((state: any) => state.report.isSidePanelOpen);
  const isStaticContentEnabled = useSelector(selectCurrentWorkspaceIsStaticContentEnabled);
  const creditLimitDialogType = useSelector((state: any) => state.report.creditLimitDialogType);
  const isCreditLimitDialogOpen = useSelector((state: any) => state.report.isCreditLimitDialogOpen);
  const html = useSelector((state: any) => (isDraft ? state.content.html : state.editor.page.html));
  const location = useLocation();
  const isEditorPage = matchRoutes(
    [
      { path: '/o/:organizationId/w/:workspaceId/p/:pageId/content/:contentId/*' },
      { path: '/o/:organizationId/w/:workspaceId/p/:pageId/versions/:contentId/*' },
    ],
    location
  );

  const isVersionEditorPage = matchRoutes(
    [{ path: '/o/:organizationId/w/:workspaceId/p/:pageId/versions/:contentId/*' }],
    location
  );

  const isSnapshotPage = isStaticContentEnabled && tab === ONLINE_PAGE_TAB;
  useEffect(() => {
    // Used to trigger resize event to automatically resize TinyMce's toolbar.
    window.dispatchEvent(new Event('resize'));
  }, [isSidePanelOpen]);
  useEffect(() => {
    if (!isEditorPage && EDITOR_ONLY_TABS.includes(selectedTab)) {
      setSelectedTab(ENUM_TAB_LABEL.Seo);
    }
  }, [isEditorPage]);
  function close() {
    dispatch(closeCreditLimitDialog());
  }
  return (
    <>
      <div className="min-h-full max-h-full flex flex-row">
        {sidePanelStatus !== ENUM_SIDE_PANEL_STATUS.Close && (
          <SidePanel
            {...{
              html,
              isReadOnly,
              isSnapshotPage,
              loading,
              selectedTabHook: [selectedTab, setSelectedTab],
              setTopKeywordUpdating,
              showMarkAsPublishedDialog,
              sidePanelStatus,
              url,
              variant: !!isVersionEditorPage
                ? ENUM_SIDE_PANEL_VARIANTS.VERSION_EDITOR
                : ENUM_SIDE_PANEL_VARIANTS.CONTENT_EDITOR,
            }}
          />
        )}
        <SidePanelTabs
          {...{
            expandDisabled,
            selectedTabHook: [selectedTab, setSelectedTab],
            sidePanelStatusHook: [sidePanelStatus, setSidePanelStatus],
            toggleDisabled,
          }}
        />
      </div>
      {isCreditLimitDialogOpen && (
        <CreditLimitReachedDialog
          creditType={creditLimitDialogType}
          isOpen={isCreditLimitDialogOpen}
          onClose={close}
        />
      )}
    </>
  );
}
export default SidePanelContainer;
