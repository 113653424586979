import React from 'react';

import SvgWrapper from './SvgWrapper';

const ExternalIcon = (props) => (
  <SvgWrapper {...props} height="13" viewBox="0 0 13 13" width="13">
    <path d="M9.56604 12.1415V6.37736H10.4245V13H0V2.57547H6.5V3.43396H0.858491V12.1415H9.56604ZM13 0H6.5V0.858491H11.6509L3.43396 9.07547L4.04717 9.68868L12.1415 1.59434V6.5H13V0Z" />
  </SvgWrapper>
);

export default ExternalIcon;
