import React, { createContext } from 'react';

import { LogProviderTraceAttributes } from '@/providers/LogProvider/LogProvider.types';

export const LogContext: React.Context<{
  report: (error: Error | string, customAttributes?: object | undefined) => void;
  trace: (customAttributes: LogProviderTraceAttributes) => void;
}> = createContext<{
  report: (error: Error | string, customAttributes?: object | undefined) => void;
  trace: (customAttributes: LogProviderTraceAttributes) => void;
}>({ report: () => null, trace: () => null });
