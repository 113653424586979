import styled from 'styled-components/macro';

import CircleEmptyIcon from '@/components/icons/CircleEmptyIcon';
import CircleHalfIcon from '@/components/icons/CircleHalfIcon';
import CirclePlainIcon from '@/components/icons/CirclePlainIcon';

const circlesCount = 5;

function PotentialRating(props) {
  const { value, ...otherProps } = props;
  let circles = [];
  const nearestHalf = Math.round(circlesCount * value * 2) / 2;

  for (let i = 0; i < Math.floor(nearestHalf); i++) {
    circles.push(<CirclePlainIcon key={`plain_${circles.length}`} />);
  }

  if (
    nearestHalf !== Math.ceil(nearestHalf) &&
    Math.round(nearestHalf) === Math.ceil(nearestHalf)
  ) {
    circles.push(<CircleHalfIcon key={`half_${circles.length}`} />);
  }

  while (circles.length < circlesCount) {
    circles.push(<CircleEmptyIcon key={`empty_${circles.length}`} />);
  }

  return <div {...otherProps}>{circles}</div>;
}

export default styled(PotentialRating)`
  box-sizing: border-box;
  display: flex;
  color: ${(props) =>
    props.value === null ? props.theme.colors.halfGrey : props.theme.colors.secondary};
`;
