import Paper from '@material-ui/core/Paper';
import React from 'react';
import styled from 'styled-components/macro';

import { FlexColumnContainer } from '../_common';
import Select from '../Select/Select';

const PanelTitle = styled.h2`
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 2rem;
`;

export const PanelContent = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  padding: 10px 16px;
  height: 100%;
  justify-content: flex-start;

  ${PanelTitle} {
    padding: 0;
  }

  ${(props) => props.theme.mediaQueries.phone} {
    padding: 2em 1em;

    ${PanelTitle} {
      padding: 0 1em;
    }
  }
`;

export const PanelHeader = styled.div`
  display: flex;
  justify-content: start;
  box-sizing: border-box;
  height: ${(props) => (props.height ? props.height : props.smallHeight ? '48px' : '60px')};
  padding: ${(props) => (props.withPadding ? '20px' : 0)};
  align-items: end;
`;

const PanelWrapper = styled(({ disabled, ...otherProps }) => (
  <Paper elevation={2} {...otherProps} />
))`
  min-width: 520px;
  z-index: 1;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'initial')};
  border-radius: 0;
  position: relative;

  ${(props) => props.theme.mediaQueries.phone} {
    width: 100%;
    min-width: 0;
    box-shadow: none;
  }
  ${(props) => props.theme.mediaQueries.tablet} {
    width: 100%;
    min-width: 0;
    box-shadow: none;
  }
  ${(props) => props.theme.mediaQueries.desktop} {
    border-radius: 0;
    border-left: 1px solid ${(props) => props.theme.colors.lightGrey};
    box-shadow: 0 0 16px ${(props) => props.theme.colors.halfGrey};
  }

  ${Select} {
    font-size: 0.7em;
    padding: 0.3em 0.7em;
    background: ${(props) => props.theme.colors.brightGrey};
    border-radius: 3px;
    border: 1px solid ${(props) => props.theme.colors.lightGrey};
  }
`;

const Panel = ({ children, ...otherProps }) => (
  <PanelWrapper {...otherProps}>
    <FlexColumnContainer style={{ height: '100%' }}>{children}</FlexColumnContainer>
  </PanelWrapper>
);

export default Panel;
