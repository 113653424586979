import { DEFAULT_FAVICON } from '@/utils/constants';
import { getFaviconFromUrl } from '@/utils/favicon';

import {
  DEFAULT_LEGEND_OPTIONS,
  DEFAULT_SERIE_OPTIONS,
  DEFAULT_XAXIS_LABEL_OPTIONS,
  DEFAULT_XAXIS_OPTIONS,
  DEFAULT_YAXIS_LABEL_OPTIONS,
  DEFAULT_YAXIS_OPTIONS,
  EXPORTING_CHART_OPTIONS,
} from './constants';

type SerieType = {
  data: {
    custom?: any;
    id: number;
    value: number;
  }[];
  color: string;
};

type SerieBuiltType = {
  data: SerieDataType[];
  name?: string;
};

type SerieDataType = {
  custom?: any;
  id: number;
  value: number;
};

type HighchartOption = Partial<{ [key: string]: string | number | boolean | undefined }>;

export default class ChartUtils {
  static getMaxOfAllSeries(series: SerieType[]) {
    return series.reduce((max, serie) => {
      const serieMax = Math.max(...serie.data.map(({ value }) => value));
      return serieMax > max ? serieMax : max;
    }, 0);
  }

  static getMinOfAllSeries(series: SerieType[]) {
    return series.reduce((min, serie) => {
      const serieMin = Math.min(...serie.data.map(({ value }) => value));
      return serieMin < min ? serieMin : min;
    }, 0);
  }

  static buildSeries(series: SerieType[], defaultOptions = {}): SerieBuiltType[] {
    return series.map((serie: any) => ({
      ...DEFAULT_SERIE_OPTIONS,
      ...serie,
      data: serie.data.map((item: SerieDataType, index: number) => ({
        custom: item.custom,
        x: index,
        y: item.value,
      })),
    }));
  }
  static round(value: number, precision: number) {
    let multiplier = Math.pow(10, precision || 0);

    while (Math.abs(value) < multiplier && precision >= 0 && value !== 0) {
      precision--;
      multiplier = Math.pow(10, precision || 0);
    }
    const result = Math.ceil(Math.abs(value) / multiplier) * multiplier;
    if (value < 0) {
      return -result;
    }
    return result;
  }

  static getChartMaxValue(series: SerieType[], { roundPrecision = 2 }: { roundPrecision: number }) {
    return ChartUtils.round(ChartUtils.getMaxOfAllSeries(series), roundPrecision);
  }

  static getChartMinValue(series: SerieType[], { roundPrecision = 2 }: { roundPrecision: number }) {
    return ChartUtils.round(ChartUtils.getMinOfAllSeries(series), roundPrecision);
  }

  static getSerieWinner(series: SerieBuiltType, categories: string[]) {
    const max = Math.max(...series.data);
    const maxIndex = series.data.indexOf(max);
    return categories[maxIndex];
  }

  static computeWinner(chartSeries: SerieBuiltType[]) {
    let winner = chartSeries[0];
    let winnerSum = winner?.data?.reduce((partialSum, n) => partialSum + n, 0);

    for (let index = 1; index < chartSeries.length; index++) {
      const serie = chartSeries[index];
      const sum = serie?.data?.reduce((partialSum, n) => partialSum + n, 0);
      if (sum > winnerSum) {
        winner = serie;
      }
    }

    return winner;
  }

  static getYAxisOptions({
    overrideOptions = {},
    overrideLabelOptions = {},
  }: {
    overrideOptions?: HighchartOption;
    overrideLabelOptions?: { formatter?: () => string | number };
  } = {}) {
    return {
      ...DEFAULT_YAXIS_OPTIONS,
      labels: {
        ...DEFAULT_YAXIS_LABEL_OPTIONS,
        ...overrideLabelOptions,
      },
      ...overrideOptions,
    };
  }

  static getXAxisOptions({
    overrideOptions = {},
    overrideLabelOptions = {},
  }: {
    overrideOptions?: HighchartOption;
    overrideLabelOptions?: HighchartOption & {
      formatter?: () => string | number;
      style?: { [key: string]: any };
    };
  } = {}) {
    return {
      ...DEFAULT_XAXIS_OPTIONS,
      labels: {
        ...DEFAULT_XAXIS_LABEL_OPTIONS,
        ...overrideLabelOptions,
        style: {
          ...DEFAULT_XAXIS_LABEL_OPTIONS.style,
          ...(overrideLabelOptions.style || {}),
        },
      },
      ...overrideOptions,
    };
  }

  static getLegendOptions({ overrideOptions = {} }: { overrideOptions?: HighchartOption }) {
    return {
      ...DEFAULT_LEGEND_OPTIONS,
      ...overrideOptions,
    };
  }

  static getExportingOptions({ overrideOptions = {} }: { overrideOptions?: any }) {
    return {
      chartOptions: {
        ...EXPORTING_CHART_OPTIONS,
        ...overrideOptions,
      },
      enabled: false,
      sourceHeight: 400,
      sourceWidth: 900,
    };
  }

  static addChartFaviconEventListeners(id: string, delay: number = 0): void {
    setTimeout(() => {
      const element = document.getElementById(id) as HTMLImageElement | null;
      if (!element || !(element instanceof HTMLImageElement)) return;
      element.onload = () => {
        element.style.background = 'none';
      };
      element.onerror = () => {
        element.src = DEFAULT_FAVICON;
      };
    }, delay);
  }
}
