import LuxonUtils from '@date-io/luxon';
import { ThemeProvider } from '@material-ui/core/styles';
import { DatePicker as MuiDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import styled from 'styled-components/macro';

import AgendaIcon from '@/components/icons/Agenda';

const MyAgendaIcon = styled(AgendaIcon)`
  && {
    fill: ${(props) => props.theme.colors.darkGrey};
    font-size: 1.3em;
  }
`;

export default function DatePicker({ disabled, onChange }) {
  function handleChangeDate(date) {
    if (!disabled) {
      onChange?.(date);
    }
  }

  return (
    <MuiPickersUtilsProvider utils={LuxonUtils}>
      <ThemeProvider theme={{}}>
        <MuiDatePicker
          disabled={disabled}
          format="dd LLLL yyyy"
          // eslint-disable-next-line react/jsx-no-bind
          TextFieldComponent={(props) => <MyAgendaIcon {...props} />}
          value={null}
          onChange={handleChangeDate}
        />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
}
