import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import AngleRight from '@/components/icons/AngleRight';
import FlatLoader, { LoaderWrapper } from '@/components/Loader/FlatLoader';
import { InlineText } from '@/components/Text/Inline';
import { TitleFour } from '@/components/Text/Title';

const FloatingText = styled(InlineText)`
  && {
    display: flex;
    align-items: center;
    position: absolute;
    top: calc(50% - 16px / 2);
    right: 45px;
    opacity: 0;
  }
`;

const Wrapper = styled.div`
  && {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 300px;
    position: relative;
    font-family: ${(props) => props.theme.textCss.fonts.main};
    background: ${(props) => props.theme.cssColors.white};
    border: 1px solid ${(props) => props.theme.cssColors.dark020};
    border-radius: 5px;
    padding: 37px 50px;
    margin: ${({ noMarginLeft }) => (noMarginLeft ? '0.5rem 15px 0.5rem 0' : '0.5rem 15px')};

    &:hover {
      border: 1px solid ${(props) => props.theme.cssColors.primary040};
      background: ${(props) => props.theme.cssColors.primary010};
      cursor: pointer;

      ${FloatingText} {
        opacity: 1;
      }
    }
  }
`;

function PortalButton(props) {
  const { t } = useTranslation();

  const DEFAULT_ROWS_IN_LOADING = 3;
  const rows = props.loading ? Array(DEFAULT_ROWS_IN_LOADING).fill({ loading: true }) : props.data;

  return (
    <Wrapper noMarginLeft={props.noMarginLeft} onClick={props.onClick}>
      <TitleFour color="dark060" noMargin weight="strong">
        {props.title}
      </TitleFour>
      <br />
      {rows.map((row, index) =>
        row?.loading ? (
          <div key={`laoding_comp_portal_button_${index}`}>
            <LoaderWrapper height={16} width={150}>
              <FlatLoader />
            </LoaderWrapper>
            <br />
          </div>
        ) : (
          <InlineText key={row} color="dark080" weight="medium">
            {row}
            <br />
          </InlineText>
        )
      )}
      <FloatingText color="primary" weight="strong">
        {t('components:button.portal-button.text')}
        <AngleRight />
      </FloatingText>
    </Wrapper>
  );
}

export default PortalButton;
