import './ReportDashboard.scss';

import { Duration } from 'luxon';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Accordion, ENUM_ACCORDION_VARIANTS } from 'semji-core/components/Accordion';

import usePostReportPageMetrics from '@/apis/semji/reports/usePostReportPageMetrics';
import { DashboardChartMetric } from '@/containers/Report/components/DashboardChartMetric';
import { useReport } from '@/containers/Report/hooks';
import { DASHBOARD_METRICS_CONFIG } from '@/containers/Report/utils/constants';
import {
  combineNewAndUpdatedContentsMetric,
  getMetricsFromCategory,
} from '@/containers/Report/utils/helpers';
import {
  ReportMetricCategory,
  ReportMetricCategoryWithPosition,
  ReportMetricChartType,
  ReportMetricKey,
  ReportView,
} from '@/containers/Report/utils/types';
import { useMetricsConfig } from '@/hooks/useMetricsConfig';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import { setReportAccordionState } from '@/store/actions/userConfiguration';
import useDeepEqualSelector from '@/store/hooks/useDeepEqualSelector';
import { selectHasAnalytics } from '@/store/selectors/selectHasAnalytics';
import { selectHasSearchConsole } from '@/store/selectors/selectHasSearchConsole';
import { selectSavedAnalyticsGoals } from '@/store/selectors/selectSavedAnalyticsGoals';
import { selectUserConfiguration } from '@/store/selectors/selectUserConfiguration';
import { selectUserCurrentWorkspaceRoles } from '@/store/selectors/selectUserCurrentWorkspaceRoles';
import { selectWorkspaceConfig } from '@/store/selectors/selectWorkspaceConfig';
import { API_WORKSPACE_ROLE_OWNER } from '@/utils/can/constants';
import { getViewFilter } from '@/utils/helper';
import {
  ANALYTICS_DEPENDENCY,
  FOCUS_TOP_KEYWORDS_METRICS,
  RANKING_KEYWORDS_METRICS,
  SEARCH_CONSOLE_DEPENDENCY,
  TOP_100_TAB,
} from '@/utils/metrics/constants';
import { getCurrentDateRangeSQLFormat } from '@/utils/metrics/getCurrentDateRangeSQLFormat';
import { getPeriods } from '@/utils/metrics/getPeriods';
import { getPreviousDateRangeSQLFormat } from '@/utils/metrics/getPreviousDateRangeSQLFormat';

function ReportDashboard() {
  const { organizationId, workspaceId, reportView = ReportView.All } = useParams();
  const { currentCount, isLoading: isLayoutLoading, filters, filteredPagesIds } = useReport();
  const dispatch = useDispatch();
  const { isReportAccordionOpen } = useSelector(selectUserConfiguration);

  const { t } = useTranslation();
  const urlPrefix = `/o/${organizationId}/w/${workspaceId}/reports`;

  const hasSearchConsole = useSelector(selectHasSearchConsole);
  const hasAnalytics = useSelector(selectHasAnalytics);
  const userRole = useSelector(selectUserCurrentWorkspaceRoles);
  const isOwner = userRole.includes(API_WORKSPACE_ROLE_OWNER);
  const { data: canTrackPosition } = useOrganizationFeatureSet('rank-tracking:is-enabled');

  const savedGoals = useDeepEqualSelector(selectSavedAnalyticsGoals);
  const conversionOptionList = Object.keys(savedGoals).map((key) => ({
    title: savedGoals[key].name,
    value: key,
  }));

  const { period, periodicity, comparisonPeriod } = useSelector(selectWorkspaceConfig);

  const metrics = useMetricsConfig({ isNew: true });
  const metricsList = Object.values(DASHBOARD_METRICS_CONFIG);

  const { data: maxDataToDisplay } = useOrganizationFeatureSet(
    'url-metrics:max-data-to-display-in-months'
  );
  const { data: hasAccessToConversion } = useOrganizationFeatureSet(
    'pages:priority-score:has-access-to-conversions'
  );

  const currentRangeDate = getCurrentDateRangeSQLFormat({ period });
  const previousRangeDate = getPreviousDateRangeSQLFormat({ comparisonPeriod, period });
  const commonFilters = {
    'exists[url]': true,
    'order[date]': 'asc',
    periodicity,
  };

  const isActiveFilter = filters?.filterGroups?.length > 0;
  const payload = {
    pageIds: isActiveFilter ? filteredPagesIds : [],
  };

  const isActivePeriodLimited = useMemo(() => {
    const periodObj = getPeriods()[period];
    if (!maxDataToDisplay || !periodObj.duration?.months) {
      return false;
    } else {
      const lockDate = Duration.fromISO(maxDataToDisplay).toObject();
      return lockDate.months > periodObj.months;
    }
  }, [maxDataToDisplay, period]);

  const {
    data: currentMetricsNew = [],
    isLoading: isCurrentMetricsNewLoading,
    isPlaceholderData: isCurrentMetricsNewPlaceholderData,
  } = usePostReportPageMetrics({
    filters: {
      ...getViewFilter(ReportView.New),
      date: {
        after: currentRangeDate.from,
        before: currentRangeDate.to,
      },
      ...commonFilters,
    },
    payload,
  });

  const {
    data: currentMetricsExisting = [],
    isLoading: isCurrentMetricsExistingLoading,
    isPlaceholderData: isCurrentMetricsExistingPlaceholderData,
  } = usePostReportPageMetrics({
    filters: {
      ...getViewFilter(ReportView.Existing),
      date: {
        after: currentRangeDate.from,
        before: currentRangeDate.to,
      },
      ...commonFilters,
    },
    payload,
  });

  const {
    data: currentMetricsAll = [],
    isLoading: isCurrentMetricsAllLoading,
    isPlaceholderData: isCurrentMetricsAllPlaceholderData,
  } = usePostReportPageMetrics({
    filters: {
      ...getViewFilter(ReportView.All),
      date: {
        after: currentRangeDate.from,
        before: currentRangeDate.to,
      },
      ...commonFilters,
    },
    payload,
  });

  const {
    data: previousMetricsNew = [],
    isLoading: isPreviousMetricsNewLoading,
    isPlaceholderData: isPreviousMetricsNewPlaceholderData,
  } = usePostReportPageMetrics({
    filters: {
      ...getViewFilter(ReportView.New),
      date: {
        after: previousRangeDate.from,
        before: previousRangeDate.to,
      },
      ...commonFilters,
    },
    payload,
  });

  const {
    data: previousMetricsExisting = [],
    isLoading: isPreviousMetricsExistingLoading,
    isPlaceholderData: isPreviousMetricsExistingPlaceholderData,
  } = usePostReportPageMetrics({
    filters: {
      ...getViewFilter(ReportView.Existing),
      date: {
        after: previousRangeDate.from,
        before: previousRangeDate.to,
      },
      ...commonFilters,
    },
    payload,
  });

  const {
    data: previousMetricsAll = [],
    isLoading: isPreviousMetricsAllLoading,
    isPlaceholderData: isPreviousMetricsAllPlaceholderData,
  } = usePostReportPageMetrics({
    filters: {
      ...getViewFilter(ReportView.All),
      date: {
        after: previousRangeDate.from,
        before: previousRangeDate.to,
      },
      ...commonFilters,
    },
    payload,
  });

  function getLoadingState(chartKey: ReportMetricKey) {
    if (chartKey === ReportMetricKey.publicationsCount && ReportView.All) {
      return (
        isCurrentMetricsNewLoading ||
        isCurrentMetricsNewPlaceholderData ||
        isPreviousMetricsNewLoading ||
        isPreviousMetricsNewPlaceholderData ||
        isCurrentMetricsExistingLoading ||
        isCurrentMetricsExistingPlaceholderData ||
        isPreviousMetricsExistingLoading ||
        isPreviousMetricsExistingPlaceholderData ||
        isLayoutLoading
      );
    }
    if (reportView === ReportView.All) {
      return (
        isCurrentMetricsAllLoading ||
        isCurrentMetricsAllPlaceholderData ||
        isPreviousMetricsAllLoading ||
        isPreviousMetricsAllPlaceholderData ||
        isLayoutLoading
      );
    }
    if (reportView === ReportView.New) {
      return (
        isCurrentMetricsNewLoading ||
        isCurrentMetricsNewPlaceholderData ||
        isPreviousMetricsNewLoading ||
        isPreviousMetricsNewPlaceholderData ||
        isLayoutLoading
      );
    }
    if (reportView === ReportView.Existing) {
      return (
        isCurrentMetricsExistingLoading ||
        isCurrentMetricsExistingPlaceholderData ||
        isPreviousMetricsExistingLoading ||
        isPreviousMetricsExistingPlaceholderData ||
        isLayoutLoading
      );
    }
  }

  const dashboardData = Object.values(ReportMetricCategoryWithPosition).map((category) => ({
    category,
    charts: getMetricsFromCategory(metricsList, category as any).map((item) => ({
      ...item,
      name: `report:chart.metric-title-chart.${item.key}`,
    })),
    title: t(`report:metric-categories.${category}`),
  }));

  const fullWidthCharts = [ReportMetricKey.AverageSessionDuration, ReportMetricKey.Revenue];

  function getChartOverrideProps({
    chartKey,
    chartCategory,
  }: {
    chartKey: ReportMetricKey;
    chartCategory: ReportMetricCategory;
  }) {
    if (isPositionChart(chartCategory)) {
      return {
        currentMetricKey: chartCategory,
        displayedMetrics: {
          [chartCategory]: {
            ...metrics[ReportMetricCategoryWithPosition.Position],
            previousVisible: true,
          },
        },
        forceSelectedPositionKey:
          String(chartKey) === TOP_100_TAB ? RANKING_KEYWORDS_METRICS : FOCUS_TOP_KEYWORDS_METRICS,

        multipleMetricsKey:
          String(chartKey) === TOP_100_TAB ? RANKING_KEYWORDS_METRICS : FOCUS_TOP_KEYWORDS_METRICS,
      };
    }
    if (chartKey === ReportMetricKey.publicationsCount && reportView === ReportView.All) {
      return {
        commonAxis: true,
        displayedMetrics: {
          publicationsCountNew: {
            ...metrics.publicationsCount,
            title: t('report:titles.new-contents'),
            tooptipTitleKey: 'dashboard:contents-production.new-content_interval',
          },
          publicationsCountUpdated: {
            ...metrics.publicationsCount,
            title: t('report:titles.existing-contents'),
            tooptipTitleKey: 'dashboard:contents-production.updated-content_interval',
          },
        },
        metrics: combineNewAndUpdatedContentsMetric(
          currentMetricsNew,
          currentMetricsExisting,
          ReportMetricKey.publicationsCount
        ),
        previousMetrics: combineNewAndUpdatedContentsMetric(
          previousMetricsNew,
          previousMetricsExisting,
          ReportMetricKey.publicationsCount
        ),
        stacked: 'normal',
      };
    }
    return {};
  }

  function getCurrentCount() {
    switch (reportView) {
      case ReportView.New:
        return currentCount.new;
      case ReportView.Existing:
        return currentCount.existing;
      default:
        return currentCount.new + currentCount.existing;
    }
  }

  function isPositionChart(chartCategory: ReportMetricCategory) {
    return String(chartCategory) === String(ReportMetricCategoryWithPosition.Position);
  }

  function getCurrentMetrics() {
    switch (reportView) {
      case ReportView.New:
        return currentMetricsNew;
      case ReportView.Existing:
        return currentMetricsExisting;
      default:
        return currentMetricsAll;
    }
  }

  function getPreviousMetrics() {
    switch (reportView) {
      case ReportView.New:
        return previousMetricsNew;
      case ReportView.Existing:
        return previousMetricsExisting;
      default:
        return previousMetricsAll;
    }
  }

  function getOptionList(chartKey: ReportMetricKey) {
    if (
      chartKey === ReportMetricKey.Conversions &&
      conversionOptionList.length > 0 &&
      hasAccessToConversion
    ) {
      return [
        {
          title: t(`listing:pages-list.view-panel.all-conversions`),
          value: chartKey,
        },
        ...conversionOptionList,
      ];
    }
    if (
      chartKey === ReportMetricKey.ConversionRate &&
      conversionOptionList.length > 0 &&
      hasAccessToConversion
    ) {
      return [
        {
          title: t(`listing:pages-list.view-panel.all-conversions`),
          value: chartKey,
        },
        ...conversionOptionList.map((item) => ({
          title: item.title,
          value: `${item.value}Rate`,
        })),
      ];
    }
    return undefined;
  }

  function getCurrentMetricKey(chartKey: ReportMetricKey, chartCategory: ReportMetricCategory) {
    if (isPositionChart(chartCategory)) {
      return chartCategory;
    }
    if (
      chartCategory === ReportMetricCategory.Conversion &&
      conversionOptionList.length > 0 &&
      hasAccessToConversion
    ) {
      // if is conversion and the goal select is defined, the currentMetricKey should be the goal
      return undefined;
    }
    return chartKey;
  }

  function getPositionChartType(chartKey: ReportMetricKey) {
    if (chartKey === TOP_100_TAB) {
      return ReportMetricChartType.Area;
    }
    return ReportMetricChartType.Bar;
  }

  function handleAccordionChange(category: ReportMetricCategoryWithPosition) {
    return function (open: boolean) {
      dispatch(setReportAccordionState({ category, isOpen: open }));
    };
  }

  return (
    <div className="report-page-dashboard">
      {dashboardData.map((group) => (
        <Accordion
          key={group.title}
          className="report-page-dashboard__group__accordion"
          open={isReportAccordionOpen?.[group?.category]}
          titleContent={<span className="report-page-dashboard__group__title">{group.title}</span>}
          variant={ENUM_ACCORDION_VARIANTS.Transparent}
          onChange={handleAccordionChange(group.category)}
        >
          <div className="report-page-dashboard__group">
            {group.charts
              .sort((a, b) => {
                if (fullWidthCharts.includes(a.key)) {
                  return -1;
                }
                if (fullWidthCharts.includes(b.key)) {
                  return 1;
                }
                return 0;
              })
              .map((chart) => (
                <DashboardChartMetric
                  key={chart.key}
                  chartType={
                    isPositionChart(chart.category)
                      ? getPositionChartType(chart.key)
                      : chart.chartType
                  }
                  className={
                    fullWidthCharts.includes(chart.key)
                      ? 'report-page-dashboard__chart--full-width'
                      : ''
                  }
                  comparisonPeriod={comparisonPeriod}
                  currentCount={getCurrentCount()}
                  currentMetrics={getCurrentMetrics()}
                  defaultActiveSubTab={chart.key}
                  defaultMetricStackingType={isPositionChart(chart.category) ? 'normal' : undefined}
                  isAnalyticsEmptyState={
                    !hasAnalytics && metrics[chart.key]?.dependency === ANALYTICS_DEPENDENCY
                  }
                  isFullWidth={fullWidthCharts.includes(chart.key)}
                  isLoading={getLoadingState(chart.key)}
                  isOwner={isOwner}
                  isRestricted={
                    isActivePeriodLimited || (isPositionChart(chart.category) && !canTrackPosition)
                  }
                  isSearchConsoleEmptyState={
                    !hasSearchConsole &&
                    metrics[chart.key]?.dependency === SEARCH_CONSOLE_DEPENDENCY
                  }
                  metricKey={getCurrentMetricKey(chart.key, chart.category)}
                  metrics={metrics as any}
                  optionList={getOptionList(chart.key)}
                  overrideChartProps={getChartOverrideProps({
                    chartCategory: chart.category,
                    chartKey: chart.key,
                  })}
                  period={period}
                  periodicity={periodicity}
                  previousMetrics={getPreviousMetrics()}
                  reportView={reportView as ReportView}
                  seeDetailsLink={`${urlPrefix}/${reportView}/${isPositionChart(chart.category) ? chart.category : chart.key}`}
                />
              ))}
          </div>
        </Accordion>
      ))}
    </div>
  );
}

export default ReportDashboard;
