import { getAlgoliaResults } from '@algolia/autocomplete-js';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useGetCurrentWorkspaceAllFolders from '@/apis/semji/folders/useGetCurrentWorkspaceAllFolders';
import { Autocomplete } from '@/containers/UniversalSearch/Autocomplete';
import { predefinedItemsPlugin } from '@/containers/UniversalSearch/plugins/predefinedItemsPlugin';
import { recentSearchesPlugin } from '@/containers/UniversalSearch/plugins/recentSearchesPlugin';
import { CategoryHeader } from '@/containers/UniversalSearch/Ui/CategoryHeader';
import { ContentItem } from '@/containers/UniversalSearch/Ui/ContentItem';
import { PageItem } from '@/containers/UniversalSearch/Ui/PageItem';
import useCan from '@/hooks/useCan';
import { useMixpanelTrackEvent } from '@/hooks/useMixpanelTrackEvent';
import useSearchClient from '@/hooks/useSearchClient';
import useWorkspaceStatuses from '@/hooks/useWorkspaceStatuses';
import useWorkspaceUsers from '@/hooks/useWorkspaceUsers';
import selectCurrentWorkspaceLanguageCode from '@/store/selectors/selectCurrentWorkspaceLanguageCode';
import { CONTENT_IDEAS_SEARCH_TOP_NAV_CLICK } from '@/utils/3rdParty/Mixpanel/constants';

function UniversalSearch(props) {
  const { t } = useTranslation();
  const isUniversalSearchGlobalEnabled = useCan({ perform: 'universal-search:global' });
  const workspaceId = useSelector((state) => state.defaultWorkspaceId || '');
  const organizationId = useSelector((state) => state.defaultOrganizationId || '');
  const { searchClient } = useSearchClient();
  // const { searchClient, refetch: refetchSearchClient } = useSearchClient();
  const users = useWorkspaceUsers();
  const statuses = useWorkspaceStatuses();
  const { data: folders } = useGetCurrentWorkspaceAllFolders();
  const navigate = useNavigate();
  const appBaseUrl = import.meta.env.VITE_REACT_APP_SEMJI_APP_FRONTEND_URL;
  const workspaceLanguageCode = useSelector(selectCurrentWorkspaceLanguageCode);
  const trackMixpanelEvent = useMixpanelTrackEvent();

  function getResults(indexName, query) {
    return getAlgoliaResults({
      queries: [
        {
          indexName,
          params: {
            filters: `(workspace_public_id:${workspaceId})`,
            hitsPerPage: 3,
          },
          query,
        },
      ],
      searchClient,
    });
  }

  function getSources({ query }) {
    return [
      {
        getItemUrl({ item }) {
          return `/o/${item.organization_public_id}/w/${item.workspace_public_id}/p/${item.page_public_id}/online`;
        },
        getItems() {
          return getResults(`pages_${workspaceLanguageCode}`, query);
        },
        limit: 3,
        onSelect({ item, event }) {
          event.stopPropagation();
          event.preventDefault();
          navigate(
            `/o/${item.organization_public_id}/w/${item.workspace_public_id}/p/${item.page_public_id}/online`
          );
        },
        sourceId: `pages_${workspaceLanguageCode}`,
        templates: {
          header({ items }) {
            return <CategoryHeader items={items} label="Pages" />;
          },
          item({ item, components }) {
            return <PageItem components={components} hit={item} />;
          },
        },
      },
      {
        getItemUrl({ item }) {
          return `/o/${item.organization_public_id}/w/${item.workspace_public_id}/p/${item.page_public_id}/content/${item.content_public_id}`;
        },
        getItems() {
          return getResults(`contents_${workspaceLanguageCode}`, query);
        },
        limit: 3,
        onSelect({ item, event }) {
          event.stopPropagation();
          event.preventDefault();

          navigate(
            `/o/${item.organization_public_id}/w/${item.workspace_public_id}/p/${item.page_public_id}/content/${item.content_public_id}`
          );
        },
        sourceId: `contents_${workspaceLanguageCode}`,
        templates: {
          header({ items }) {
            return <CategoryHeader items={items} label="Contents" />;
          },
          item({ item, components }) {
            return (
              <ContentItem
                components={components}
                folders={folders}
                hit={item}
                statuses={statuses}
                users={users}
              />
            );
          },
        },
      },
    ];
  }

  if (!isUniversalSearchGlobalEnabled || !searchClient) {
    return null;
  }

  return (
    <div
      {...props}
      type="button"
      onClick={() => {
        trackMixpanelEvent(CONTENT_IDEAS_SEARCH_TOP_NAV_CLICK);
      }}
    >
      <Autocomplete
        debug
        detachedMediaQuery=""
        getSources={getSources}
        navigator={{
          navigate({ itemUrl, item }) {
            trackMixpanelEvent(CONTENT_IDEAS_SEARCH_TOP_NAV_CLICK);
            if (item.isExternal) {
              window.open(itemUrl, '_blank', 'noopener');
            } else {
              navigate(itemUrl);
            }
          },
          navigateNewTab({ itemUrl }) {
            const windowReference = window.open(appBaseUrl + itemUrl, '_blank', 'noopener');

            if (windowReference) {
              windowReference.focus();
            }
          },
          navigateNewWindow({ itemUrl }) {
            window.open(appBaseUrl + itemUrl, '_blank', 'noopener');
          },
        }}
        openOnFocus
        placeholder={t('components:select.folder-select.folder-select.search-placeholder')}
        plugins={[
          recentSearchesPlugin,
          predefinedItemsPlugin(organizationId, workspaceId, navigate),
        ]}
      />
    </div>
  );
}

export default UniversalSearch;
