import './Hubspot.scss';

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { postUserHubspotVisitorToken } from '@/apis/semji/api';
import {
  identifyUser,
  loadChatWidget,
  notifyUrlUpdate,
  removeChatWidget,
} from '@/utils/3rdParty/Hubspot';

export default function HubspotContainer() {
  // we connect implicitly on email|firstName|lastName|languageCode because these keys can be updated from the settings page
  // the update modify only the value and keep the same user reference in the store
  const email = useSelector((state) => state.user?.email);
  const firstName = useSelector((state) => state.user?.firstName);
  const lastName = useSelector((state) => state.user?.lastName);
  const languageCode = useSelector((state) => state.user?.languageCode);
  const userData = useSelector((state) => state.user);
  const user = {
    ...userData,
    email,
    firstName,
    languageCode,
    lastName,
  };
  const organizationId = useSelector((state) => state.defaultOrganizationId || '');
  const currentOrganization = useSelector(
    (state) => state.organizations?.byId?.[organizationId] || {}
  );
  const { pathname } = useLocation();

  async function updateChatWidget() {
    if (!email) {
      removeChatWidget();

      return;
    }

    const hubspotToken = await postUserHubspotVisitorToken();
    loadChatWidget(email, hubspotToken.token);
  }

  useEffect(() => {
    updateChatWidget();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  useEffect(() => {
    identifyUser(user, currentOrganization);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    email,
    firstName,
    lastName,
    currentOrganization.id,
    currentOrganization.status,
    currentOrganization.name,
    currentOrganization.planName,
    currentOrganization.subscriptionStatus,
  ]);

  useEffect(() => {
    notifyUrlUpdate(user, pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return null;
}
