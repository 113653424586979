import { DateTime } from 'luxon';

import { METRICS_CONFIG_PERIODICITY_MONTHLY, periodicities } from './constants';

export const formatDateByPeriodicity = ({ date, periodicity, locale = 'en' }) => {
  return DateTime.fromSQL(date).toFormat(
    periodicities[periodicity ?? METRICS_CONFIG_PERIODICITY_MONTHLY].humanFormat,
    { locale }
  );
};
