import './OutgoingLinks.scss';

import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { DefaultLink } from '@/components/Navigation/Link';
import { ShowMoreInstructions } from '@/components/Optimization/Instruction';
import Optimization from '@/components/Optimization/Optimization';
import DetailsBase from '@/containers/Content/SidePanel/Components/Optimization/Details/DetailsBase';
import { RecommendationProps } from '@/containers/Content/SidePanel/Components/Optimization/Section/OutgoingLinks/OutgoingLinks.types';
import { disableMainOptimization, enableMainOptimization } from '@/store/actionsCreator/report';
import { selectReportRecommendationData } from '@/store/selectors/selectReportRecommendationData';
import { DISPLAY_LIMIT_OUTGOINGLINKS } from '@/utils/constants';

import OutgoingLink from './OutgoingLink';

function OutgoingLinks({
  id,
  focusTopKeywordId,
  keyword,
  pageId,
  editorRef,
  ...props
}: RecommendationProps) {
  const { t } = useTranslation();
  const {
    actualLinksCount,
    contentTitle,
    disabled,
    expectedLinksCount,
    html,
    recommendationsIds,
    recommendations,
    score,
    weight,
    ready,
    maximumLinks,
  } = useSelector(selectReportRecommendationData(id));
  const dispatch = useDispatch();
  const [showDetails, setShowDetails] = useState(false);
  const [isListLimited, setIsListLimited] = useState(false);
  const [disabledOutgoingLinks, setDisabledOutgoingLinks] = useState<string[]>([]);
  const [enabledOutgoingLinks, setEnabledOutgoingLinks] = useState<string[]>([]);
  const outgoingLinks = [...enabledOutgoingLinks, ...disabledOutgoingLinks];
  const limit = isListLimited ? maximumLinks : DISPLAY_LIMIT_OUTGOINGLINKS;
  const linkWithUrlCount = outgoingLinks.length || 0;

  useEffect(() => {
    let enabledRecommendationsIds: string[] = [];
    let disabledRecommendationsIds: string[] = [];

    recommendationsIds.forEach((recommendationsId: string) => {
      if (recommendations[recommendationsId]?.disabled) {
        disabledRecommendationsIds.push(recommendationsId);
      } else {
        enabledRecommendationsIds.push(recommendationsId);
      }
    });

    setEnabledOutgoingLinks(enabledRecommendationsIds);
    setDisabledOutgoingLinks(disabledRecommendationsIds);
  }, [recommendationsIds]);

  function disableRecommendation(key: string) {
    dispatch(disableMainOptimization(key, focusTopKeywordId, html, contentTitle));
  }
  function enableRecommendation(key: string) {
    dispatch(enableMainOptimization(key, focusTopKeywordId, html, contentTitle));
  }
  function closeDetails() {
    setShowDetails(false);
  }
  function toggleLimit() {
    setIsListLimited(!isListLimited);
  }
  function onToggleOutgoingLink(recommendationId: string, disabled: boolean) {
    if (!disabled) {
      setEnabledOutgoingLinks((enabledOutgoingLinkTable) => {
        const newEnabledOutgoingLinkTable = enabledOutgoingLinkTable.filter(
          (id) => id !== recommendationId
        );
        return newEnabledOutgoingLinkTable;
      });

      setDisabledOutgoingLinks((disabledOutgoingLinkTable) => {
        const newDisabledOutgoingLinkTable = [...disabledOutgoingLinkTable, recommendationId];
        return newDisabledOutgoingLinkTable;
      });
    } else {
      setDisabledOutgoingLinks((disabledOutgoingLinkTable) => {
        const newDisabledOutgoingLinkTable = disabledOutgoingLinkTable.filter(
          (id) => id !== recommendationId
        );
        return newDisabledOutgoingLinkTable;
      });

      setEnabledOutgoingLinks((enabledOutgoingLinkTable) => {
        const newEnabledOutgoingLinkTable = [...enabledOutgoingLinkTable, recommendationId];
        return newEnabledOutgoingLinkTable;
      });
    }
  }

  const isEmpty = ready && outgoingLinks.length === 0;

  return (
    <>
      <Optimization
        description={
          <Trans
            components={{
              extLink: (
                <DefaultLink
                  color="dark040"
                  decoration
                  isExternal
                  noDynamicFontSize
                  noPadding
                  size={'xsm'}
                  to={t('common:links.help-outgoing-links')}
                  weight="medium"
                />
              ),
            }}
            i18nKey="content:side-panel-components.optimization.section.outgoing-links.description"
          />
        }
        disabled={disabled}
        disableRecommendation={disableRecommendation}
        enableRecommendation={enableRecommendation}
        helpCenterLink={t('common:links.help-outgoing-links')}
        id={id}
        infoMessage={t('components:optimization.optimization.no-outgoing-links-message', {
          keyword,
        })}
        infoText={t('content:side-panel-components.optimization.section.outgoing-links.info-text')}
        instructions={[
          outgoingLinks
            .map((outgoingLinkId: string, index) => (
              <OutgoingLink
                key={index}
                disabled={disabled}
                editorRef={editorRef}
                focusTopKeywordId={focusTopKeywordId}
                mainId={id}
                recommendationId={outgoingLinkId}
                onToggle={onToggleOutgoingLink}
              />
            ))
            .slice(0, limit),
          <ShowMoreInstructions
            key="moreOutgoingLinks"
            hide={outgoingLinks?.length <= DISPLAY_LIMIT_OUTGOINGLINKS}
            isGrayColor={true}
            toggle={toggleLimit}
          >
            {isListLimited
              ? t(
                  'content:side-panel-components.optimization.section.outgoing-links.toggle-more-text_interval'
                )
              : t(
                  'content:side-panel-components.optimization.section.outgoing-links.toggle-less-text'
                )}
          </ShowMoreInstructions>,
        ]}
        isEmpty={isEmpty}
        isReady={ready}
        loaderRows={3}
        points={Math.round((weight - weight * score) * 100)}
        scorerTooltipText={t(
          'content:side-panel-components.optimization.section.outgoing-links.scorer-tooltip-text',
          { expectedLinksCount }
        )}
        // Disabled, see this comment: https://gitlab.rvip.fr/semji/semji/-/issues/2158#note_84560
        score={score}
        scorer={`${actualLinksCount}/${expectedLinksCount}`}
        showDetailsAction={undefined}
        title={t('content:side-panel-components.optimization.section.outgoing-links.title')}
        {...props}
      />
      <DetailsBase
        description={t(
          'content:side-panel-components.optimization.section.outgoing-links.details-description_interval',
          {
            count: linkWithUrlCount,
            postProcess: 'interval',
          }
        )}
        show={showDetails}
        subTitle={t(
          'content:side-panel-components.optimization.section.outgoing-links.details-subtitle_interval',
          {
            count: expectedLinksCount,
            postProcess: 'interval',
          }
        )}
        title={t('content:side-panel-components.optimization.section.outgoing-links.title')}
        onClose={closeDetails}
      />
    </>
  );
}

export default OutgoingLinks;
