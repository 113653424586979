import styled from 'styled-components/macro';

import { SmallFlexTooltipComponent } from '@/components/Tooltip/Tooltip';

const BulkActionButton = styled.button`
  font-family: ${({ theme }) => theme.textCss.fonts.main};
  font-size: 13px;
  line-height: 17px;
  font-weight: 500;
  color: ${({ theme }) => theme.cssColors.primary};
  background: none;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => disabled && '0.5'};
  border: none;
  white-space: nowrap;

  ${SmallFlexTooltipComponent} {
    gap: 5px;
    align-items: center;

    svg {
      width: 14px;
      height: 14px;
    }
  }
`;

export default BulkActionButton;
