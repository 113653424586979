import { useCallback, useContext, useEffect } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

// sources :
// https://denislistiadi.medium.com/react-router-v6-preventing-transitions-2389806e8556
// https://github.com/remix-run/react-router/blob/fe661c5c0405c2212a5299b75af362df9f031b11/packages/react-router/lib/hooks.tsx#L40
// https://gist.github.com/MarksCode/64e438c82b0b2a1161e01c88ca0d0355

function useConfirmExit({ confirmExit, when = true, bypassNavigationRegex = null }) {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) {
      return;
    }

    const push = navigator.push;

    navigator.push = (...args) => {
      if (args[0].pathname.match(bypassNavigationRegex) || confirmExit()) {
        push(...args);
      }
    };

    return () => {
      navigator.push = push;
    };
  }, [navigator, confirmExit, when, bypassNavigationRegex]);
}

function usePrompt({ message = '', when = true, bypassNavigationRegex = null }) {
  const confirmExit = useCallback(() => window.confirm(message), [message]);

  useConfirmExit({ bypassNavigationRegex, confirmExit, when });

  useEffect(() => {
    if (when) {
      window.onbeforeunload = () => message;
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [message, when]);
}

export default usePrompt;
