import './HeatMapDetails.scss';

import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import FavIcon from '@/components/FavIcon/FavIcon';
import NumberCell from '@/components/Table/Cell/NumberCell';
import Ellipsis from '@/components/Text/Ellipsis';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import { Heat } from '@/containers/Content/SidePanel/Components/Optimization/Details/Heat';
import BlurredRecommendations from '@/containers/Content/SidePanel/Components/Optimization/Section/Credits/BlurredRecommendations';
import { HEAT_MAP_DETAIL } from '@/containers/Content/SidePanel/Components/Optimization/Section/Credits/constants';
import CreditWarningMessage from '@/containers/Content/SidePanel/Components/Optimization/Section/Credits/CreditWarningMessage';
import { getRelevanceOfRecommendation } from '@/utils/recommendationOccurrenceInterval';

const RELATED_TERMS_KEY = 'relatedTerms';
const SEARCH_INTENT_KEY = 'searchIntent';

const CreditWarning = styled(CreditWarningMessage)`
  margin: 5px 0 0 0;
`;

const StyledEllipsis = styled(Ellipsis)`
  && strong {
    font-weight: 500;
  }
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 300px;
`;
function HeatMapDetails({
  competitors = {},
  keywordId,
  keyword,
  lockedRecommendationsLength,
  recommendations = [],
  termKey,
  title,
}) {
  const { t } = useTranslation();
  const i18nNameSpace = 'content:side-panel-components.optimization.details.heatmap-details';
  const sortedCompetitor = Object.values(competitors).sort(
    (competitor1, competitor2) => competitor1.position - competitor2.position
  );

  return (
    <div className="heatMap-details__map-container">
      <div className="heatMap-details__map-container__header">
        <div className="heatMap-details__map-container__header__row">
          <div className="heatMap-details__map-container__header__row__headers">
            <div className="heatMap-details__map-container__header__row__headers__recommendation">
              <h3 className="heatMap-details__map-container__header__row__headers__recommendation__primary-title">
                {title}
              </h3>
            </div>
          </div>
          {termKey === RELATED_TERMS_KEY && (
            <div className="heatMap-details__map-container__header__row__headers__target-header">
              <h3 className="heatMap-details__map-container__header__row__headers__target-header__primary-title">
                {t(`${i18nNameSpace}.target`)}
              </h3>
            </div>
          )}

          {termKey === SEARCH_INTENT_KEY && (
            <div className="heatMap-details__map-container__header__row__headers">
              <div className="heatMap-details__map-container__header__row__headers__volume-header">
                <TooltipComponent
                  title={t(`${i18nNameSpace}.tooltip-search-intent-cluster-volume`, {
                    keyword,
                  })}
                >
                  <h3 className="heatMap-details__map-container__header__row__headers__volume-header__primary-title">
                    {t(`${i18nNameSpace}.cluster-volume`)}
                  </h3>
                </TooltipComponent>
              </div>
            </div>
          )}

          <div className="heatMap-details__map-container__header__row__headers__heat-container">
            {sortedCompetitor.map((competitor) => (
              <div
                key={competitor.position}
                className="heatMap-details__map-container__header__row__headers__heat-container__competitor-cell"
              >
                <TooltipComponent title={t(`${i18nNameSpace}.rank`)}>
                  <div className="heatMap-details__map-container__header__row__headers__competitor-cell__position">
                    {competitor.position}
                  </div>
                </TooltipComponent>
                <FavIcon url={competitor.url} />
              </div>
            ))}
          </div>
        </div>
        {termKey === SEARCH_INTENT_KEY && (
          <CreditWarning amountToUnlock={lockedRecommendationsLength} type={HEAT_MAP_DETAIL} />
        )}
      </div>
      <div className="heatMap-details__map-container__scroll-container">
        {recommendations.map((recommendation) => {
          const { min, interval, max } = getRelevanceOfRecommendation(
            recommendation.data.medianOccurrences
          );

          return (
            <div
              key={recommendation.data?.term}
              className="heatMap-details__map-container__scroll-container__row"
            >
              <div className="heatMap-details__map-container__scroll-container__row__ellipsis">
                <StyledEllipsis
                  dangerouslySetInnerHTML={{
                    __html: recommendation.data?.label,
                  }}
                  title={recommendation.data?.term}
                />
              </div>
              {termKey === RELATED_TERMS_KEY && (
                <div className="heatMap-details__map-container__scroll-container__row__target-cell">
                  <TooltipComponent
                    title={t(`${i18nNameSpace}.tooltip-recommendation-relevance_interval`, {
                      count: min,
                      max,
                      min,
                      postProcess: 'interval',
                    })}
                  >
                    {interval}
                  </TooltipComponent>
                </div>
              )}
              {termKey === SEARCH_INTENT_KEY && (
                <div className="heatMap-details__map-container__scroll-container__row__volume-cell">
                  <NumberCell
                    decimalPlaces={0}
                    number={
                      recommendation.data.searchVolume > 0 ? recommendation.data.searchVolume : '-'
                    }
                  />
                </div>
              )}
              <div className="heatMap-details__map-container__scroll-container__row__heat-container">
                {sortedCompetitor.map((competitor) => {
                  const uuid = nanoid();
                  let count =
                    recommendation.data.competitorOccurrences.find(
                      (competitorOccurrence) =>
                        competitorOccurrence.competitorPosition === competitor.position
                    )?.occurrencesCount || 0;
                  if (count !== 0) {
                    return (
                      <TooltipComponent
                        key={uuid}
                        title={t(`${i18nNameSpace}.found-tooltip_interval`, {
                          count,
                          postProcess: 'interval',
                        })}
                      >
                        <div className="heatMap-details__map-container__scroll-container__row__heat-container__competitor-cell">
                          <Heat count={count} />
                        </div>
                      </TooltipComponent>
                    );
                  }

                  return (
                    <div
                      key={uuid}
                      className="heatMap-details__map-container__scroll-container__row__heat-container__competitor-cell"
                    >
                      <Heat count={count} />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
        {termKey === SEARCH_INTENT_KEY && (
          <BlurredRecommendations
            amountToUnlock={lockedRecommendationsLength}
            columns={sortedCompetitor.length}
            keywordId={keywordId}
            type={HEAT_MAP_DETAIL}
          />
        )}
      </div>
    </div>
  );
}

HeatMapDetails.propTypes = {
  competitors: PropTypes.object,
  keywordId: PropTypes.string,
  lockedRecommendationsLength: PropTypes.number,
  recommendations: PropTypes.array,
  termKey: PropTypes.string,
  title: PropTypes.string,
  unlockRecommendations: PropTypes.func,
};

export default HeatMapDetails;
