import React from 'react';
import { ENUM_MENU_ENTITY_TYPE } from 'semji-core/components/Menu';
import { BrandVoiceCreditIcon } from 'semji-core/icons/BrandVoiceCreditIcon';

import AICreditIcon from '@/components/icons/AICreditIcon';
import FactCheckingCreditIcon from '@/components/icons/FactCheckingCreditIcon';
import MetaDescCreditIcon from '@/components/icons/MetaDescCreditIcon';
import MetaDescIcon from '@/components/icons/MetaDescIcon';
import PenIcon from '@/components/icons/PenIcon';
import TitleCreditIcon from '@/components/icons/TitleCreditIcon';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import {
  GENERATION_INTRODUCTION_TYPE,
  GENERATION_METADESCRIPTION_TYPE,
  GENERATION_PARAGRAPH_TYPE,
  GENERATION_TITLE_TYPE,
} from '@/containers/Content/EditorComponents/Ai/constants';
import {
  items,
  StyledTitleIcon,
} from '@/containers/Content/TinyMceComponents/Editor/hooks/useEditorPopper/constant';
import { BrandVoiceModel } from '@/types/brandVoice/brandVoice.types';

export function getTitleItem(
  getAiWritingCredit: (type: string) => number,
  remainingAiWritingUnit: number,
  handleGenerateAi: (type: string) => void,
  hasUnlimitedAIWriting: boolean,
  t: (key: string, options?: any) => string,
  factCheckingEnabled: boolean
) {
  return {
    content: (
      <div className="flex items-center gap-2">
        <StyledTitleIcon />
        <span className="leading-4">{t('content:ai-writing.dropdown-menu.ai-model-title')}</span>
      </div>
    ),
    contentActive: hasUnlimitedAIWriting ? null : (
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <StyledTitleIcon />
          <span className="leading-4">{t('content:ai-writing.dropdown-menu.ai-model-title')}</span>
        </div>
        <TooltipComponent
          content={
            <div className="flex flex-col">
              <span className="flex items-center justify-center">
                <TitleCreditIcon />
                <span className="font-medium">
                  {t('content:ai-writing.ai-writing-credits-needed', {
                    count: getAiWritingCredit(GENERATION_TITLE_TYPE),
                    postProcess: 'interval',
                  })}
                </span>
              </span>
              <span className="text-center italic">
                (
                {t('content:ai-writing.ai-writing-credits-remaining.key_interval', {
                  count: remainingAiWritingUnit,
                  postProcess: 'interval',
                })}
                )
              </span>
            </div>
          }
        >
          <div className="flex items-center">
            <AICreditIcon />
            <span className="text-primary ml-0.5 text-xs">
              {getAiWritingCredit(GENERATION_TITLE_TYPE)}
            </span>
          </div>
        </TooltipComponent>
      </div>
    ),
    onClick: () => handleGenerateAi(GENERATION_TITLE_TYPE),
    type: ENUM_MENU_ENTITY_TYPE.item,
  };
}

export function getMetaDescriptionItem(
  getAiWritingCredit: (type: string) => number,
  remainingAiWritingUnit: number,
  handleGenerateAi: (type: string) => void,
  hasUnlimitedAIWriting: boolean,
  t: (key: string, options?: any) => string,
  factCheckingEnabled: boolean
) {
  return {
    content: (
      <div className="flex items-center gap-2">
        <MetaDescIcon />
        <span className="leading-4">
          {t('content:ai-writing.dropdown-menu.ai-model-meta-description')}
        </span>
      </div>
    ),
    contentActive: hasUnlimitedAIWriting ? null : (
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <MetaDescIcon />
          <span className="leading-4">
            {t('content:ai-writing.dropdown-menu.ai-model-meta-description')}
          </span>
        </div>
        <TooltipComponent
          content={
            <div className="flex flex-col">
              <span className="flex items-center justify-center gap-1">
                <MetaDescCreditIcon />
                <span className="font-medium">
                  {t('content:ai-writing.ai-writing-credits-needed', {
                    count: getAiWritingCredit(GENERATION_METADESCRIPTION_TYPE),
                    postProcess: 'interval',
                  })}
                </span>
              </span>
              <span className="text-center italic">
                (
                {t('content:ai-writing.ai-writing-credits-remaining.key_interval', {
                  count: remainingAiWritingUnit,
                  postProcess: 'interval',
                })}
                )
              </span>
            </div>
          }
        >
          <div className="flex items-center">
            <AICreditIcon />
            <span className="text-primary ml-0.5 text-xs">
              {getAiWritingCredit(GENERATION_METADESCRIPTION_TYPE)}
            </span>
          </div>
        </TooltipComponent>
      </div>
    ),
    onClick: () => handleGenerateAi(GENERATION_METADESCRIPTION_TYPE),
    type: ENUM_MENU_ENTITY_TYPE.item,
  };
}

export function getEditorAiWritingItems(
  getAiWritingCredit: (type: string) => number,
  remainingAiWritingUnit: number,
  handleGenerateAi: (type: string) => void,
  hasUnlimitedAIWriting: boolean,
  searchValue: string,
  t: (key: string, options?: any) => string,
  factCheckingEnabled: boolean,
  aiWritingBrandVoiceEnabled: boolean
) {
  const mappingList =
    searchValue === '' ? items : items.filter(({ label }) => label.includes(searchValue));
  return mappingList.map((item) => ({
    aiType: item.type,
    content: (
      <div className="flex items-center gap-2">
        {item.icon}
        <span className="leading-4">
          {t(`content:ai-writing.dropdown-menu.ai-model-${item.label}`)}
        </span>
      </div>
    ),
    contentActive: hasUnlimitedAIWriting ? null : (
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          {item.icon}
          <span className="leading-4">
            {t(`content:ai-writing.dropdown-menu.ai-model-${item.label}`)}
          </span>
        </div>
        <TooltipComponent
          content={
            <div className="flex flex-col">
              <span className="flex items-center justify-center gap-1">
                {item.creditIcon}
                {[GENERATION_PARAGRAPH_TYPE, GENERATION_INTRODUCTION_TYPE].includes(item.type) &&
                  factCheckingEnabled && <FactCheckingCreditIcon />}
                {aiWritingBrandVoiceEnabled && <BrandVoiceCreditIcon />}
                <span className="font-medium">
                  {t('content:ai-writing.ai-writing-credits-needed', {
                    count: getAiWritingCredit(item.type),
                    postProcess: 'interval',
                  })}
                </span>
              </span>
              <span className="text-center italic">
                (
                {t('content:ai-writing.ai-writing-credits-remaining.key_interval', {
                  count: remainingAiWritingUnit,
                  postProcess: 'interval',
                })}
                )
              </span>
            </div>
          }
        >
          <div className="flex items-center">
            <AICreditIcon />
            <span className="text-primary ml-0.5 text-xs">{getAiWritingCredit(item.type)}</span>
          </div>
        </TooltipComponent>
      </div>
    ),
    onClick: () => handleGenerateAi(item.type),
    type: ENUM_MENU_ENTITY_TYPE.item,
  }));
}

export function getBrandVoiceItems({
  brandVoices,
  saveBrandVoice,
  removeBrandVoice,
  currentBrandVoiceId,
  t,
}: {
  brandVoices: BrandVoiceModel[];
  saveBrandVoice: (value: BrandVoiceModel) => void;
  removeBrandVoice: () => void;
  currentBrandVoiceId: string | null;
  t: (key: string, options?: any) => string;
}) {
  const currentBrandBrandVoice = brandVoices.find(({ id }) => currentBrandVoiceId === id)?.name;

  const brandVoicesList = [
    {
      content: (
        <div className="editor-popper-item">
          <span>{t('content:ai-writing.dropdown-menu.ai-brand-voice-generic')}</span>
        </div>
      ),
      onClick: () => {
        removeBrandVoice();
      },
      type: ENUM_MENU_ENTITY_TYPE.item,
    },
  ];

  brandVoices.forEach((brandVoice) => {
    brandVoicesList.push({
      content: (
        <div className="editor-popper-item">
          <span>{brandVoice.name}</span>
        </div>
      ),
      onClick: () => {
        saveBrandVoice(brandVoice);
      },
      type: ENUM_MENU_ENTITY_TYPE.item,
    });
  });

  return [
    {
      content: (
        <div className="flex items-center gap-2">
          <span>{t('content:ai-writing.dropdown-menu.ai-brand-voice')}</span>
        </div>
      ),
      type: ENUM_MENU_ENTITY_TYPE.section,
    },
    {
      content: (
        <div className="flex items-center editor-popper-item gap-2">
          <PenIcon className="text-primary" />
          <span>
            {currentBrandBrandVoice
              ? currentBrandBrandVoice
              : t('content:ai-writing.dropdown-menu.ai-brand-voice-generic')}
          </span>
        </div>
      ),
      subEntities: [...brandVoicesList],
      type: ENUM_MENU_ENTITY_TYPE.subFolder,
    },
  ];
}
