import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Handle, Position } from 'reactflow';
import { ClickableTooltip } from 'semji-core/components/ClickableTooltip';
import { Placements } from 'semji-core/hooks/usePopover';
import { getRoundedNumberMetricWithSuffix } from 'semji-core/utils/numbers';

import { FlexTooltipComponent } from '@/components/Tooltip/Tooltip';
import {
  MIND_MAP_DETAILED_VIEW,
  ORIENTATION_VERTICAL,
} from '@/containers/ContentIdeas/Listing/MindMap/constant';
import Box from '@/design-system/components/Box/Box';
import Flex from '@/design-system/components/Flex/Flex';
import defaultTheme from '@/themes/defaultTheme';

import HoverCard from './components/HoverCard';
import KeywordFooter from './components/KeywordFooter';
import KeywordTitle from './components/KeywordTitle';
import {
  FloatingWrapper,
  Keyword,
  SearchIconDetailed,
  StyledBackRightIcon,
  StyledBoxIcon,
  StyledDeleteIcon,
  StyledEditIcon,
  StyledExternalIcon,
  StyledPlusIcon,
  StyledValidatedIcon,
  TitleDetailed,
  TitleDetailedWrapper,
  VolumeDetailed,
} from './KeywordNode.styled';

const DELETE_ACTION = 'delete';
const ADD_ACTION = 'add';

function DetailedRender({ data, withConnector = true }) {
  const [query, setQuery] = useSearchParams();
  const { t } = useTranslation();
  const userLanguageCode = useSelector((state) => state.user?.languageCode);

  const [action, setAction] = useState(null);
  const [isPlanned, setIsPlanned] = useState(data.plannedContentIds.length > 0);

  function handleNavigate() {
    window.open(data.url, '_blank');
  }

  function handleArchive() {
    data.archiveContentIdeas.mutate({
      contentIdeasIds: [data.id],
      isArchived: !data.isArchived,
    });
  }

  function handleAddToPlanning() {
    setAction(ADD_ACTION);
    data.handleOpenEditDraftModal(data.id);
  }

  function handleFocusCluster() {
    query.set('clusterId', data.cluster.id);
    setQuery(query);
  }

  useEffect(() => {
    if (action === DELETE_ACTION) {
      setIsPlanned(
        !(
          data.contentsToRemoveFromPlanning?.[0]?.id === data.plannedContentIds[0] &&
          data.isRemoveFromPlanningDialogOpen !== false
        )
      );
    }

    if (action === ADD_ACTION) {
      setIsPlanned(
        data.contentsToAddToPlanning?.[0]?.id === data.id &&
          data.isAddToPlanningDialogOpen !== false
      );
    }
  }, [data, action]);

  return (
    <Flex
      backgroundColor="white"
      borderRadius="8px"
      boxShadow={defaultTheme.boxShadow.modal}
      cursor="pointer"
      flexDirection="column"
      gap="12px"
      opacity={data.blurred ? 0.3 : 1}
      padding="12px 20px"
      width="450px"
      onClick={handleFocusCluster}
    >
      {withConnector && (
        <Handle
          position={data.orientation === ORIENTATION_VERTICAL ? Position.Top : Position.Left}
          type="target"
        >
          <FloatingWrapper
            alignItems="center"
            backgroundColor="transparent"
            border="none"
            borderRadius="100px"
            gap="12px"
            isPlanned={isPlanned}
            justifyContent="end"
            left="-44px"
            padding="2px 4px"
            position="absolute"
            top="-8px"
            width="52px"
          >
            {data.blurred ? null : (
              <>
                {data.isArchived ? (
                  <FlexTooltipComponent title={t('content-ideas:mind-map.restore')}>
                    <StyledBackRightIcon onClick={handleArchive} />
                  </FlexTooltipComponent>
                ) : (
                  <FlexTooltipComponent title={t('content-ideas:mind-map.archive')}>
                    <StyledBoxIcon onClick={handleArchive} />
                  </FlexTooltipComponent>
                )}
                {isPlanned && <PlannedContentMenuOptions data={data} setAction={setAction} />}
              </>
            )}
            {isPlanned ? (
              <StyledValidatedIcon color="#E9E9EB" fill="black" />
            ) : (
              <FlexTooltipComponent title={t('content-ideas:mind-map.add-to-planning')}>
                <StyledPlusIcon
                  backgroundColor={data.cluster.color}
                  onClick={handleAddToPlanning}
                />
              </FlexTooltipComponent>
            )}
          </FloatingWrapper>
        </Handle>
      )}
      <KeywordTitle data={data} userLanguageCode={userLanguageCode} />
      <TitleDetailedWrapper alignItems="center" gap="8px" onClick={handleNavigate}>
        <TitleDetailed
          dangerouslySetInnerHTML={{ __html: data.titleLabel }}
          fontWeight={defaultTheme.textCss.weights.medium}
        />
        <StyledExternalIcon />
      </TitleDetailedWrapper>
      <KeywordFooter data={data} />
    </Flex>
  );
}

function SimplifiedRender({ data }) {
  const { t } = useTranslation();
  const userLanguageCode = useSelector((state) => state.user?.languageCode);

  const [action, setAction] = useState(null);
  const [isPlanned, setIsPlanned] = useState(data.plannedContentIds.length > 0);

  const [query, setQuery] = useSearchParams();

  function handleAddToPlanning() {
    setAction(ADD_ACTION);
    data.handleOpenAddToPlanningModal([data.id]);
  }

  useEffect(() => {
    if (action === DELETE_ACTION) {
      setIsPlanned(
        !(
          data.contentsToRemoveFromPlanning?.[0]?.id === data.plannedContentIds[0] &&
          data.isRemoveFromPlanningDialogOpen !== false
        )
      );
    }

    if (action === ADD_ACTION) {
      setIsPlanned(
        data.contentsToAddToPlanning?.[0]?.id === data.id &&
          data.isAddToPlanningDialogOpen !== false
      );
    }
  }, [data, action]);

  function handleArchive() {
    data.archiveContentIdeas.mutate({
      contentIdeasIds: [data.id],
      isArchived: !data.isArchived,
    });
  }

  function handleFocusCluster() {
    query.set('clusterId', data.cluster.id);
    setQuery(query);
  }

  return (
    <Flex alignItems="center" gap="10px">
      <ClickableTooltip
        anchorElement={
          <Flex
            alignItems="center"
            cursor="pointer"
            gap="12px"
            opacity={data.blurred ? 0.3 : 1}
            pointerEvents="all"
            onClick={handleFocusCluster}
          >
            <Handle
              position={data.orientation === ORIENTATION_VERTICAL ? Position.Top : Position.Left}
              type="target"
            >
              <FloatingWrapper
                alignItems="center"
                backgroundColor="transparent"
                border="none"
                borderRadius="100px"
                gap="12px"
                isPlanned={isPlanned}
                justifyContent="end"
                left="-40px"
                padding="2px 4px"
                position="absolute"
                top="-8px"
                width="52px"
              >
                {data.blurred ? null : (
                  <Box display="flex" gap="10px">
                    {data.isArchived ? (
                      <FlexTooltipComponent title={t('content-ideas:mind-map.restore')}>
                        <StyledBackRightIcon onClick={handleArchive} />
                      </FlexTooltipComponent>
                    ) : (
                      <FlexTooltipComponent title={t('content-ideas:mind-map.archive')}>
                        <StyledBoxIcon onClick={handleArchive} />
                      </FlexTooltipComponent>
                    )}
                    {isPlanned && <PlannedContentMenuOptions data={data} setAction={setAction} />}
                  </Box>
                )}

                {isPlanned ? (
                  <StyledValidatedIcon color="#E9E9EB" fill="black" />
                ) : (
                  <FlexTooltipComponent title={t('content-ideas:mind-map.add-to-planning')}>
                    <StyledPlusIcon
                      backgroundColor={data.cluster.color}
                      onClick={handleAddToPlanning}
                    />
                  </FlexTooltipComponent>
                )}
              </FloatingWrapper>
            </Handle>
            <Keyword dangerouslySetInnerHTML={{ __html: data.keywordLabel }} />
          </Flex>
        }
        disabled={data.blurred}
        isFrame={false}
        popoverOptions={{ offset: 30, placement: Placements.Right }}
      >
        <HoverCard data={data} />
      </ClickableTooltip>
      <FlexTooltipComponent
        cursor="help"
        description={data.searchVolume}
        title={t('content-ideas:mind-map.volume')}
      >
        <VolumeDetailed
          alignItems="center"
          color="dark060"
          gap="2px"
          opacity={data.blurred ? 0.3 : 1}
        >
          <SearchIconDetailed />
          {getRoundedNumberMetricWithSuffix({
            number: data.searchVolume,
            userLanguageCode,
          })}
        </VolumeDetailed>
      </FlexTooltipComponent>
    </Flex>
  );
}

function PlannedContentMenuOptions({ data, setAction }) {
  const { t } = useTranslation();

  function handleDeleteFromPlanning() {
    setAction(DELETE_ACTION);
    data.handleRemoveFromPlanningDialog(data.plannedContentIds);
  }

  function handleEditPlannedContent(e) {
    e.stopPropagation();
    data.handleOpenEditDraftModal(data.id);
  }

  return (
    <>
      <FlexTooltipComponent title={t('content-ideas:mind-map.edit')}>
        <StyledEditIcon onClick={handleEditPlannedContent} />
      </FlexTooltipComponent>
      <FlexTooltipComponent title={t('content-ideas:mind-map.remove-from-planning')}>
        <StyledDeleteIcon onClick={handleDeleteFromPlanning} />
      </FlexTooltipComponent>
    </>
  );
}

function KeywordNode({ data }) {
  return data.mindMapView === MIND_MAP_DETAILED_VIEW ? (
    <DetailedRender data={data} />
  ) : (
    <SimplifiedRender data={data} />
  );
}

export default KeywordNode;
