import './Report.scss';

import { capitalize } from 'lodash';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'semji-core/components/Tooltip';
import { InfoIcon } from 'semji-core/icons/InfoIcon';
import {
  getRoundedNumberMetricWithSuffix,
  getRoundedPercentMetricObject,
} from 'semji-core/utils/numbers';

import Card from '@/components/Card/Card';
import { NO_COMPETITOR_EVOLUTION_12MONTHS_DATA } from '@/components/EmptyState/NoContentsVerticalBars';
import ChartWrapper, {
  CHART_WRAPPER_VARIANT_SMALL,
} from '@/containers/Competitors/components/ChartWrapper';
import EmptyStateChart from '@/containers/Competitors/components/EmptyStateChart';
import { ExportOptionButton } from '@/containers/Competitors/components/ExportOptionButton/ExportOptionButton';
import { HighChartsColumn } from '@/containers/Competitors/Duel/Report/Chart/HighChartsColumn';
import {
  ChartDataType,
  ENUM_CHART_DATA_TYPE,
  HORIZONTAL_MULTIPLIER_PIXEL,
} from '@/containers/Competitors/Duel/Report/constant';
import { ToggleSelector } from '@/containers/Competitors/Duel/Selector/Selector';
import { useCompetitor } from '@/containers/Competitors/hooks/useCompetitor';
import {
  CompetitorReportMetricFiltered,
  COMPETITORS_METRIC_TYPES,
  getReportFormatValues,
  RETRIEVAL_DATE_FILTER_QUERY_PARAM,
} from '@/containers/Competitors/utils/constants';
import { getHostnamefromUrl } from '@/containers/Competitors/utils/utils';
import { CompetitorWebsite } from '@/types/competitors';

interface HEvolutionReportProps {
  color: string;
  title: string;
  tooltip: { title: string; description?: string };
  type: COMPETITORS_METRIC_TYPES;
  isLoading?: boolean;
  competitorsFilter: string[];
  handleOpenDataExplorer: (e: any, serieKey: string) => void;
  metrics: {
    currentMetrics: CompetitorReportMetricFiltered[];
    previousMetrics: CompetitorReportMetricFiltered[];
  };
}

export function HEvolutionReport({
  color,
  title,
  tooltip,
  type,
  isLoading,
  competitorsFilter,
  handleOpenDataExplorer,
  metrics: { currentMetrics = [], previousMetrics = [] },
}: HEvolutionReportProps) {
  const { t } = useTranslation();

  const [renderType, setRenderType] = useState(ENUM_CHART_DATA_TYPE.VALUE);
  const [exportImage, setExportImage] = useState(false);
  const { competitorWebsites, websiteReference, competitorReportDate, competitorComparativeDates } =
    useCompetitor();

  const chartRef = React.createRef();

  const reference = getHostnamefromUrl(websiteReference?.url);

  const websites = [...competitorWebsites, websiteReference]
    .filter((competitor) => competitorsFilter.includes(competitor?.id))
    .map((competitor) => ({
      id: competitor?.id,
      name: getHostnamefromUrl(competitor?.url),
      url: competitor?.url,
      urlType: competitor?.urlType,
    }));

  function handleSelectThemeType(value: string) {
    return () => {
      setRenderType(value);
    };
  }

  function getMetricValue(metric: CompetitorReportMetricFiltered) {
    if (type === COMPETITORS_METRIC_TYPES.page) {
      return metric?.nbPages ?? 0;
    }
    return metric?.traffic;
  }

  function getValueBetweenTwoPeriods(
    currentData: CompetitorReportMetricFiltered,
    previousData: CompetitorReportMetricFiltered
  ) {
    return getMetricValue(currentData) - getMetricValue(previousData);
  }
  function getNbPages(currentDataNbPages?: number, previousDataNbPages?: number) {
    return getRoundedNumberMetricWithSuffix({
      number: (currentDataNbPages ?? 0) - (previousDataNbPages ?? 0),
    });
  }

  function getDataToRender(
    currentData: CompetitorReportMetricFiltered,
    previousData: CompetitorReportMetricFiltered,
    renderType: string
  ) {
    if (renderType === ENUM_CHART_DATA_TYPE.PERCENTAGE) {
      return (
        (getValueBetweenTwoPeriods(currentData, previousData) / getMetricValue(previousData)) * 100
      );
    }
    return getValueBetweenTwoPeriods(currentData, previousData);
  }

  function buildSeries({
    currenYear,
    previousYear,
  }: {
    currenYear: CompetitorReportMetricFiltered[];
    previousYear: CompetitorReportMetricFiltered[];
  }) {
    const data = currenYear
      .filter((element) => competitorsFilter.includes(element.competitorWebsiteId))
      .map((metric, index) => ({
        custom: {
          competitorId: metric.competitorWebsiteId,
          type: DateTime.fromISO(competitorReportDate).toFormat('yyyy-MM-dd'),
        },
        id: metric.competitorWebsiteId,
        value: getDataToRender(metric, previousYear[index], renderType),
      }));

    data.sort((a, b) => b.value - a.value);

    const categories = data.map(
      (item) => websites.find((website) => website?.id === item.id) ?? ({} as CompetitorWebsite)
    );
    return {
      categories,
      series: [
        {
          color,
          data,
        },
      ],
    };
  }

  const heightChart = `${HORIZONTAL_MULTIPLIER_PIXEL * (websites.length || 10)}px`;

  const { series, categories } = buildSeries({
    currenYear: currentMetrics,
    previousYear: previousMetrics,
  });

  function getTooltipDataByPoint({ x, y }: { x: string; y: number }) {
    const currentDate = DateTime.fromISO(competitorComparativeDates?.[0]);
    const previousDate = DateTime.fromISO(competitorComparativeDates?.[0]).minus({ years: 1 });
    const competitorWebsite = websites.find((website) => website.name === x);

    const currentData = currentMetrics.find(
      (item) => item.competitorWebsiteId === competitorWebsite?.id
    );
    const previousData = previousMetrics.find(
      (item) => item.competitorWebsiteId === competitorWebsite?.id
    );

    const value =
      currentData && previousData
        ? getDataToRender(currentData, previousData, ENUM_CHART_DATA_TYPE.VALUE)
        : 0;

    const percentValue =
      currentData && previousData
        ? getDataToRender(currentData, previousData, ENUM_CHART_DATA_TYPE.PERCENTAGE)
        : 0;

    const { value: percentageValue, suffix } = getRoundedPercentMetricObject({
      number: percentValue,
    });

    return {
      custom: {
        nbPages: getNbPages(currentData?.nbPages, previousData?.nbPages),
      },
      metrics: [...new Set([type, COMPETITORS_METRIC_TYPES.page])],
      name: x,
      percentValue: `${percentageValue}${suffix}%`,
      title: `${capitalize(currentDate.toFormat('LLL yyyy'))} vs ${capitalize(
        previousDate.toFormat('LLL yyyy')
      )}`,
      value: getRoundedNumberMetricWithSuffix({ number: value }),
    };
  }

  const formatValues = getReportFormatValues();

  const isNoData =
    series?.length === 0 ||
    categories?.length === 0 ||
    series.reduce((acc, item) => acc + item.data.length, 0) === 0;
  const isExtraExtraLargeTooltip =
    title === t('competitors:reports.report.page-number-evolution-12.title');

  return (
    <ChartWrapper isLoading={isLoading} variant={CHART_WRAPPER_VARIANT_SMALL}>
      <Card
        className="competitors-duel-report__card"
        contentMinHeight={heightChart}
        title={
          <div className="competitors-duel-report__card__header">
            <div className="competitors-duel-report__card__header__title">
              <span>{title}</span>
              <Tooltip
                description={tooltip.description}
                isFrame={false}
                title={tooltip.title}
                tooltipClassName={` competitors-duel-report__card__header__title-tooltip competitors-duel-report__card__header__title-tooltip--${!isExtraExtraLargeTooltip ? 'extra-extra-large' : 'extra-large2'}`}
              >
                <InfoIcon />
              </Tooltip>
            </div>
            <div className="competitors-duel-report__card__header__actions">
              <div className="competitors-duel-report__card__header__filter">
                <ToggleSelector
                  list={formatValues.map(({ title, tooltip, value }) => ({
                    active: value === renderType,
                    onClick: handleSelectThemeType(value),
                    title: title,
                    tooltip: tooltip,
                    value: value,
                  }))}
                />
              </div>
              <ExportOptionButton
                chartRef={chartRef}
                exportImage={exportImage}
                setExportImage={setExportImage}
              />
            </div>
          </div>
        }
      >
        {isNoData ? (
          <EmptyStateChart version={NO_COMPETITOR_EVOLUTION_12MONTHS_DATA} />
        ) : (
          <HighChartsColumn
            categories={categories}
            chartRef={chartRef}
            exportImage={exportImage}
            getTooltipDataByPoint={getTooltipDataByPoint}
            metricsSeries={series}
            options={{
              height: heightChart,
              legend: { enabled: false },
              xAxis: { width: '100%' },
            }}
            reference={reference}
            renderType={renderType as ChartDataType}
            serieKey={RETRIEVAL_DATE_FILTER_QUERY_PARAM}
            title={title}
            onPointClick={handleOpenDataExplorer}
          />
        )}
      </Card>
    </ChartWrapper>
  );
}
