import { stringify } from 'qs';

function alphabeticalSort(a: string, b: string): number {
  return a.localeCompare(b);
}

export function createQueryKeyFromFiltersAndProperties({
  filters,
  properties,
}: {
  filters?: {
    [key: string]: any;
  };
  properties?: string[];
}): string[] {
  const filterString = stringify(filters, {
    allowDots: true,
    arrayFormat: 'comma',
    delimiter: ';',
    sort: alphabeticalSort,
  });
  const propertyString = properties?.sort().join(',') || '';
  return [filterString, propertyString];
}
