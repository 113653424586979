import './DefaultErrorFallback.scss';

import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, ENUM_BUTTON_SIZES } from 'semji-core/components/Button';

import BrokenLaptop from '@/assets/images/broken-laptop.svg';

export default function DefaultErrorFallback() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <div className="default-error-fallback">
      <div className="default-error-fallback__image">
        <img alt="Error" src={BrokenLaptop} />
      </div>
      <div className="default-error-fallback__text">
        <h4>{t('common:error:default')}</h4>
        <span>{t('common:error:default-desc')}</span>
      </div>
      <div className="default-error-fallback__actions">
        <Button size={ENUM_BUTTON_SIZES.Large} stretch onClick={() => navigate(-1)}>
          {t('common:labels:go-back')}
        </Button>

        <span className="default-error-fallback__actions__link">
          <Trans
            components={{
              a: <a href={pathname} />,
            }}
            i18nKey={'common:labels:try-refresh'}
          />
        </span>
      </div>
    </div>
  );
}
