import styled from 'styled-components/macro';

import ActionsColumn from '@/containers/Content/BriefComponents/Table/Columns/ActionsColumn';

const Row = styled.div`
  && {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: ${({ gap }) => gap || '15px'};
    padding: 10px 20px;
    min-width: 0;
    min-height: 50px;
    border-bottom: ${({ theme }) => theme.cssColors.dark010} solid 0.5px;
    color: ${({ theme }) => theme.cssColors.dark100};
    font-size: ${({ theme }) => theme.textCss.sizes.sm};
    font-weight: ${({ theme }) => theme.textCss.weights.normal};
    overflow: hidden;

    &:hover ${ActionsColumn} {
      visibility: visible;
    }

    & strong {
      font-weight: 500;
    }
  }
`;

export default Row;
