import React from 'react';

const ColoredReportIcon = (props) => (
  <svg
    {...props}
    fill="none"
    height="114"
    viewBox="0 0 150 114"
    width="150"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.0106 55.0765H13.2064C11.7915 55.0765 10.6909 53.9753 10.6909 52.5595C10.6909 51.1437 11.7915 50.0425 13.2064 50.0425H22.0106C23.4256 50.0425 24.5261 51.1437 24.5261 52.5595C24.5261 53.9753 23.4256 55.0765 22.0106 55.0765Z"
      fill="#FF4D64"
      fillOpacity="0.2"
    />
    <path
      d="M34.2738 26.6022H25.4696C24.0546 26.6022 22.9541 25.5011 22.9541 24.0852C22.9541 22.6694 24.0546 21.5681 25.4696 21.5681H34.2738C35.6887 21.5681 36.7893 22.6694 36.7893 24.0852C36.7893 25.5011 35.6887 26.6022 34.2738 26.6022Z"
      fill="#FF4D64"
      fillOpacity="0.2"
    />
    <path
      d="M28.2994 39.5022H15.2504C13.8354 39.5022 12.7349 38.4009 12.7349 36.985C12.7349 35.5692 13.8354 34.468 15.2504 34.468H28.2994C29.7144 34.468 30.8149 35.5692 30.8149 36.985C30.8149 38.4009 29.7144 39.5022 28.2994 39.5022Z"
      fill="#FF4D64"
      fillOpacity="0.2"
    />
    <path
      d="M4.87376 39.3448H2.5155C1.10053 39.3448 0 38.2437 0 36.8278C0 35.412 1.10053 34.3108 2.5155 34.3108H4.87376C6.28872 34.3108 7.38926 35.412 7.38926 36.8278C7.38926 38.2437 6.28872 39.3448 4.87376 39.3448Z"
      fill="#FF4D64"
      fillOpacity="0.2"
    />
    <path
      d="M133.321 68.6056H113.983C112.568 68.6056 111.467 67.5044 111.467 66.0886C111.467 64.6727 112.568 63.5715 113.983 63.5715H133.321C134.736 63.5715 135.836 64.6727 135.836 66.0886C135.836 67.5044 134.578 68.6056 133.321 68.6056Z"
      fill="#FF4D64"
      fillOpacity="0.2"
    />
    <path
      d="M146.527 56.0201H137.723C136.308 56.0201 135.208 54.9189 135.208 53.5031C135.208 52.0873 136.308 50.9861 137.723 50.9861H146.527C147.942 50.9861 149.043 52.0873 149.043 53.5031C149.043 54.9189 147.942 56.0201 146.527 56.0201Z"
      fill="#FF4D64"
      fillOpacity="0.2"
    />
    <path
      d="M132.535 44.3789H125.617C124.202 44.3789 123.102 43.2777 123.102 41.8618C123.102 40.446 124.202 39.3447 125.617 39.3447H132.535C133.95 39.3447 135.05 40.446 135.05 41.8618C135.05 43.2777 133.95 44.3789 132.535 44.3789Z"
      fill="#FF4D64"
      fillOpacity="0.2"
    />
    <path
      d="M129.233 56.0201H126.718C125.303 56.0201 124.202 54.9189 124.202 53.5031C124.202 52.0873 125.303 50.9861 126.718 50.9861H129.233C130.648 50.9861 131.749 52.0873 131.749 53.5031C131.591 54.9189 130.491 56.0201 129.233 56.0201Z"
      fill="#FF4D64"
      fillOpacity="0.2"
    />
    <path
      d="M134.421 107.777C134.421 110.923 107.223 113.598 73.7354 113.598C40.248 113.598 13.0493 111.081 13.0493 107.777C13.0493 104.631 40.248 104.946 73.7354 104.946C107.223 104.946 134.421 104.631 134.421 107.777Z"
      fill="#FF4D64"
      fillOpacity="0.2"
    />
    <path
      d="M74.3639 78.6739C92.1639 78.6739 106.594 64.2352 106.594 46.4241C106.594 28.613 92.1639 14.1743 74.3639 14.1743C56.564 14.1743 42.1343 28.613 42.1343 46.4241C42.1343 64.2352 56.564 78.6739 74.3639 78.6739Z"
      fill="#FF4D64"
      fillOpacity="0.2"
    />
    <path
      d="M40.248 54.9191H51.4105L63.9879 44.8508L78.9236 54.9191L107.066 30.6924"
      stroke="#FF4D64"
      strokeMiterlimit="10"
      strokeWidth="1.8"
    />
    <path
      d="M132.377 100.698L108.48 75.2129C115.398 67.5044 119.8 57.4362 120.114 46.2667C120.743 21.4108 101.248 0.645025 76.565 0.0157607C64.6164 -0.298871 53.2967 4.10602 44.4925 12.4438C35.8455 20.7815 30.8146 31.951 30.5001 43.907C29.8713 68.7629 49.3663 89.5287 74.0495 90.158C85.212 90.4726 95.4311 86.5396 103.449 80.0897L127.346 105.575C128.604 106.991 130.805 106.991 132.22 105.732C132.377 105.575 134.893 103.53 132.377 100.698ZM74.3639 83.236C53.4539 82.7641 36.9461 65.1446 37.4177 44.0643C37.7322 33.8387 41.8198 24.3998 49.2091 17.3205C56.5983 10.2413 66.1886 6.62311 76.2505 6.78043C97.1605 7.25238 113.668 24.8717 113.197 45.9521C112.725 67.0324 95.2739 83.708 74.3639 83.236Z"
      fill="#EEEEEE"
    />
    <path
      d="M132.378 103.215L108.166 77.258C115.241 69.3922 119.643 59.1667 119.957 47.8399C120.586 22.512 100.777 1.43164 75.6219 0.802376C63.5161 0.487744 51.8819 4.89259 43.0777 13.3877C34.2735 21.8827 29.2426 33.2095 28.9281 45.4802C28.2993 70.8081 48.1087 91.8885 73.2636 92.5177C84.5833 92.8324 94.9596 88.8995 103.135 82.2922L127.347 108.249C128.761 109.665 130.963 109.665 132.378 108.407C132.22 108.249 134.893 106.047 132.378 103.215Z"
      stroke="#252736"
      strokeMiterlimit="10"
      strokeOpacity="0.8"
      strokeWidth="1.2"
    />
    <path
      d="M73.2634 85.4383C52.039 84.8091 35.2167 67.0323 35.6884 45.6373C36.0028 35.2544 40.2477 25.6582 47.6369 18.579C55.0261 11.4998 64.9309 7.72412 75.15 7.88143C96.3744 8.5107 113.197 26.2875 112.725 47.6825C112.253 68.9202 94.6451 85.9103 73.2634 85.4383Z"
      stroke="#252736"
      strokeMiterlimit="10"
      strokeOpacity="0.8"
      strokeWidth="1.2"
    />
  </svg>
);

export default ColoredReportIcon;
