import React from 'react';

import { RelativeLoader } from '@/components/Loader/Loader';
import { DiffPercentage } from '@/components/Metrics/DiffPercentage';
import NotAcknowledgedCell from '@/components/Table/Cell/NotAcknowledgedCell';

export const GainCell = ({ gainValue, evolutionRating, isLoading }) => {
  if (isLoading) {
    return <RelativeLoader fontSize="2rem" opaque />;
  }

  if (gainValue !== null) {
    return (
      <span>
        <DiffPercentage
          evolutionRating={evolutionRating}
          initialValue={gainValue.initialValue}
          suffix={`${gainValue.suffix}%`}
          value={gainValue.value}
        />
      </span>
    );
  }

  return <NotAcknowledgedCell align="center" />;
};
