import React, { lazy, Suspense } from 'react';
import { Navigate, Route, Routes, useLocation, useParams } from 'react-router-dom';

import Competitors from '@/containers/Content/SidePanel/Components/Competitors/Competitors';
import Performance from '@/containers/Content/SidePanel/Components/Performance/Performance';
import { SidePanelBodyWrapper } from '@/containers/Content/Wrappers';
import useCan from '@/hooks/useCan';

const OptimizationContainer = lazy(
  () =>
    import(
      /* webpackChunkName: "OptimizationContainer" */ `@/containers/Content/SidePanel/Components/Optimization/OptimizationContainer`
    )
);

function Body({
  focusTopKeyword,
  refreshReport,
  loading,
  refreshing,
  url,
  isReadOnly,
  areManyAnalysisInProgress,
  showMarkAsPublishedDialog,
  mandatoryRecommendationsReady,
}) {
  const { pathname } = useLocation();
  const { pageId } = useParams();
  const canVisitPerformancePanel = useCan({
    data: { accessGranted: true },
    perform: 'editor-performances-panel:visit',
  });

  return (
    <SidePanelBodyWrapper data-intercom-target="side_panel_body">
      <Routes>
        {canVisitPerformancePanel && (
          <Route
            element={
              <Performance
                loading={loading}
                refreshing={refreshing}
                showMarkAsPublishedDialog={showMarkAsPublishedDialog}
                url={url}
              />
            }
            path={`/performances`}
          />
        )}
        <Route
          element={
            <Competitors
              focusTopKeyword={focusTopKeyword}
              loading={loading}
              mandatoryRecommendationsReady={mandatoryRecommendationsReady}
              pageId={pageId}
              refreshing={refreshing}
            />
          }
          path={`/competitors`}
        />
        <Route
          element={
            <Suspense fallback={<></>}>
              <OptimizationContainer
                areManyAnalysisInProgress={areManyAnalysisInProgress}
                focusTopKeyword={focusTopKeyword}
                isReadOnly={isReadOnly}
                loading={loading}
                mandatoryRecommendationsReady={mandatoryRecommendationsReady}
                refreshing={refreshing}
                refreshReport={refreshReport}
              />
            </Suspense>
          }
          path={`/optimizations`}
        />
        <Route element={<Navigate replace to={`${pathname}/optimizations`} />} path="/" />
      </Routes>
    </SidePanelBodyWrapper>
  );
}

export default Body;
