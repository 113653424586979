import './Report.scss';

import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Select,
  SELECT_VARIANT_BORDERLESS,
  SelectDropdownItem,
} from 'semji-core/components/Select';
import { Tooltip } from 'semji-core/components/Tooltip';
import { CheckIcon } from 'semji-core/icons/CheckIcon';
import { InfoIcon } from 'semji-core/icons/InfoIcon';

import { useGetCompetitorWebsiteMetrics } from '@/apis/semji/competitors';
import {
  GROUP_BY_MONTH,
  GROUP_BY_MONTHLY_PRODUCTION,
  MetricsGroupByType,
} from '@/apis/semji/competitors/constants';
import Card from '@/components/Card/Card';
import {
  NO_COMPETITOR_TRAFFIC_BAR_DATA,
  NO_COMPETITOR_TRAFFIC_LINE_DATA,
} from '@/components/EmptyState/NoContentsVerticalBars';
import Loader from '@/components/Loader/Loader';
import MenuItem from '@/components/Menu/MenuItem';
import ChartWrapper, {
  CHART_WRAPPER_VARIANT_DEFAULT,
  CHART_WRAPPER_VARIANT_SMALL,
} from '@/containers/Competitors/components/ChartWrapper';
import EmptyStateChart from '@/containers/Competitors/components/EmptyStateChart';
import { HighChartsBar } from '@/containers/Competitors/Duel/Report/Chart/HighChartsBar';
import { HighChartsLine } from '@/containers/Competitors/Duel/Report/Chart/HighChartsLine';
import {
  AVAILABLE_PERIODS,
  DEFAULT_CARD_CONTENT_MIN_HEIGHT,
  ReportPeriodType,
  SMALL_CARD_CONTENT_MIN_HEIGHT,
} from '@/containers/Competitors/Duel/Report/constant';
import { ReportTabs } from '@/containers/Competitors/Duel/Report/ReportTabs';
import {
  COMPETITORS_METRIC_TYPES,
  DEFAULT_COLOR,
  FIRST_SEEN_DATE_FILTER_QUERY_PARAM,
} from '@/containers/Competitors/utils/constants';
import { getCompetitorColor, getHostnamefromUrl } from '@/containers/Competitors/utils/utils';
import { IFilterTransformed } from '@/hooks/useFilters/useFilters.types';
import defaultTheme from '@/themes/defaultTheme';
import {
  CompetitorCluster,
  CompetitorTopPagesFilters,
  CompetitorWebsite,
  CompetitorWebsiteMetric,
} from '@/types/competitors';
import {
  COMPETITORS_METRIC_TYPE_CONTENT,
  COMPETITORS_METRICS_CONFIG,
  METRICS_CONFIG_PERIOD_LAST_12_MONTHS,
} from '@/utils/metrics/constants';

interface TrafficReportProps {
  competitorReportId: string;
  competitor: CompetitorWebsite;
  reference: CompetitorWebsite;
  defaultMetricKey?: COMPETITORS_METRIC_TYPES;
  clusters: CompetitorCluster[];
  filters: IFilterTransformed<CompetitorTopPagesFilters>;
  canToggle?: boolean;
  isLoading?: boolean;
  handleOpenDataExplorer?: (e: any, serieKey: string) => void;
}

export function TrafficReport({
  competitorReportId,
  competitor,
  filters,
  reference,
  clusters,
  defaultMetricKey = COMPETITORS_METRIC_TYPES.traffic,
  canToggle,
  isLoading = false,
  handleOpenDataExplorer,
}: TrafficReportProps) {
  const { t } = useTranslation();
  const [metricKey, setMetricKey] = useState<COMPETITORS_METRIC_TYPES>(defaultMetricKey);
  const [period, setPeriod] = useState<ReportPeriodType>(METRICS_CONFIG_PERIOD_LAST_12_MONTHS);
  const [groupBy, setGroupBy] = useState<MetricsGroupByType>(
    defaultMetricKey === COMPETITORS_METRIC_TYPES.traffic
      ? GROUP_BY_MONTH
      : GROUP_BY_MONTHLY_PRODUCTION
  );

  const {
    data,
    isLoading: isCompetitorMetricsLoading,
    isPlaceholderData,
  } = useGetCompetitorWebsiteMetrics({
    filters: { ...filters, groupBy },
    referenceId: reference?.id,
    reportId: competitorReportId,
    staleTime: 0,
    websiteId: competitor.id,
  });

  const referenceMetrics = data?.referenceMetrics ?? [];
  const competitorMetrics = data?.competitorMetrics ?? [];

  const selectMetricClick = (metricKey: COMPETITORS_METRIC_TYPES) => {
    setMetricKey(metricKey);
    setGroupBy(
      metricKey === COMPETITORS_METRIC_TYPES.traffic ? GROUP_BY_MONTH : GROUP_BY_MONTHLY_PRODUCTION
    );
  };

  const selectPeriod = (period: ReportPeriodType) => {
    setPeriod(period);
  };

  function handleSelectPeriod(period: { key: ReportPeriodType }) {
    setPeriod(period.key);
  }

  const metrics = COMPETITORS_METRICS_CONFIG[COMPETITORS_METRIC_TYPE_CONTENT];
  const metricsList = Object.values(metrics);

  const series = buildSeries(referenceMetrics, competitorMetrics);
  const categories = buildCategories();

  function buildCategories() {
    const items =
      referenceMetrics.map((metric) => {
        const date = new Date(metric.id);
        const month = date.toLocaleString('en', { month: 'short' }).toLowerCase();
        return `${t(`chart:lang.shortMonths.${month}`)} '${(date.getFullYear() + '').slice(-2)}`;
      }) ?? [];

    const periodDuration = AVAILABLE_PERIODS[period].duration.months;
    return period === METRICS_CONFIG_PERIOD_LAST_12_MONTHS ? items : items.slice(-periodDuration);
  }

  function buildSeries(
    referenceMetrics: CompetitorWebsiteMetric[] = [],
    competitorMetrics: CompetitorWebsiteMetric[] = []
  ) {
    if (referenceMetrics.length === 0 && competitorMetrics?.length === 0) return [];

    const periodDuration = AVAILABLE_PERIODS[period].duration.months;
    const referenceData = referenceMetrics.map((metric) => ({
      custom: {
        [COMPETITORS_METRIC_TYPES.page]: metric[COMPETITORS_METRIC_TYPES.page] || 0,
        competitorId: reference.id,
        type: metric.id,
        value: metric[metricKey] || 0,
      },
      y: metric[metricKey] || 0,
    }));

    const competitorData = competitorMetrics.map((metric, index) => ({
      custom: {
        [COMPETITORS_METRIC_TYPES.page]: metric[COMPETITORS_METRIC_TYPES.page] || 0,
        competitorId: competitor.id,
        type: metric.id,
        value: metric[metricKey] || 0,
      },
      y: metric[metricKey] || 0,
    }));

    const items = [
      {
        color: DEFAULT_COLOR,
        data:
          period === METRICS_CONFIG_PERIOD_LAST_12_MONTHS
            ? referenceData
            : referenceData.slice(-periodDuration),
        name: getHostnamefromUrl(reference.url),
      },
      {
        color: getCompetitorColor(competitor.url)?.color,
        data:
          period === METRICS_CONFIG_PERIOD_LAST_12_MONTHS
            ? competitorData
            : competitorData.slice(-periodDuration),
        name: competitor ? getHostnamefromUrl(competitor.url) : '',
      },
    ];

    return items;
  }

  const getListPeriods = useMemo(
    () =>
      Object.entries(AVAILABLE_PERIODS).map(([periodKey, periodObj]) => {
        return (
          <MenuItem
            key={periodKey}
            badge={<CheckIcon />}
            description={t(periodObj.name)}
            isDisabled={periodKey === period}
            value={periodKey}
            onClick={() => selectPeriod(periodKey as any)}
          >
            {t(periodObj.name)}
          </MenuItem>
        );
      }),
    [period, t]
  );

  const isNoData =
    series?.length === 0 ||
    categories?.length === 0 ||
    series.some((serie) => serie.data.reduce((acc, item) => acc + item.y, 0) === 0);

  function SelectItem({ optionValue, ...props }: any) {
    return (
      <SelectDropdownItem {...props}>
        <span>{t(optionValue.value.name)}</span>
        <CheckIcon />
      </SelectDropdownItem>
    );
  }

  const periods = Object.entries(AVAILABLE_PERIODS).map(([periodKey, periodObj]) => ({
    key: periodKey,
    value: periodObj,
  }));

  periods.sort((a, b) => {
    if (a.value.size.days > b.value.size.days) return 1;
    if (a.value.size.days < b.value.size.days) return -1;
    return 0;
  });

  function getSelectedValue(period: any) {
    return t(period.value.name);
  }

  function getCustomFilter(point: any) {
    const customFilter = {
      date: {
        equals: point.custom.type,
      },
    };
    return customFilter;
  }

  const xAxisStyle = {
    color: defaultTheme.cssColors.dark060,
    fontFamily: defaultTheme.textCss.fonts.main,
    fontSize: defaultTheme.textCss.sizes.xsm,
    fontWeight: defaultTheme.textCss.weights.normal,
  };
  return (
    <ChartWrapper
      isLoading={isLoading}
      variant={canToggle ? CHART_WRAPPER_VARIANT_DEFAULT : CHART_WRAPPER_VARIANT_SMALL}
    >
      <Card
        className="competitors-duel-report__card"
        contentMinHeight={
          canToggle ? DEFAULT_CARD_CONTENT_MIN_HEIGHT : SMALL_CARD_CONTENT_MIN_HEIGHT
        }
        title={
          <div className="competitors-duel-report__card__header">
            <div className="competitors-duel-report__card__header__title">
              <span>
                {t(`competitors:duel.report.title-traffic.${canToggle ? 'default' : metricKey}`)}
              </span>
              <Tooltip
                description={t('competitors:source-normalized-traffic')}
                isFrame={false}
                title={t(
                  `competitors:duel.report.title-traffic-tooltip.${
                    canToggle ? 'default' : metricKey
                  }`
                )}
                tooltipClassName="competitors-duel-report__card__header__title-tooltip"
              >
                <InfoIcon className="competitors-duel-report__card__header__title-icon" />
              </Tooltip>
            </div>
            <div className="competitors-duel-report__card__header__filter">
              <Select
                defaultValueIndex={periods.findIndex((p) => p.key === period)}
                DropdownItem={SelectItem}
                dropdownPlacement="bottom-end"
                getValue={getSelectedValue}
                isFrame={false}
                offsetValue={0}
                options={periods}
                variant={SELECT_VARIANT_BORDERLESS}
                width="180px"
                onChange={handleSelectPeriod}
              >
                {getListPeriods}
              </Select>
            </div>
          </div>
        }
      >
        {canToggle && (
          <ReportTabs
            className="competitors-duel-report__card__tabs"
            currentMetricKey={metricKey}
            metricsList={metricsList}
            selectMetricClick={selectMetricClick}
          />
        )}

        {isCompetitorMetricsLoading || isPlaceholderData ? (
          <Loader children={''} fontSize="50px" />
        ) : isNoData ? (
          <EmptyStateChart
            version={
              metricKey === COMPETITORS_METRIC_TYPES.traffic
                ? NO_COMPETITOR_TRAFFIC_LINE_DATA
                : NO_COMPETITOR_TRAFFIC_BAR_DATA
            }
          />
        ) : metricKey === COMPETITORS_METRIC_TYPES.traffic ? (
          <HighChartsLine
            categories={categories}
            getCustomFilter={getCustomFilter}
            metricsSeries={series}
            options={{
              xAxisStyle,
            }}
            selectedMetrics={[metricKey, COMPETITORS_METRIC_TYPES.page]}
            onPointClick={handleOpenDataExplorer}
          />
        ) : (
          <HighChartsBar
            categories={categories}
            getCustomFilter={getCustomFilter}
            metricsSeries={series}
            options={{
              xAxisStyle,
            }}
            selectedMetrics={[...new Set([metricKey, COMPETITORS_METRIC_TYPES.page])]}
            serieKey={FIRST_SEEN_DATE_FILTER_QUERY_PARAM}
            sortData={false}
            onPointClick={handleOpenDataExplorer}
          />
        )}
      </Card>
    </ChartWrapper>
  );
}
