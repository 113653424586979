import { useSelector } from 'react-redux';

import { selectUserCurrentWorkspaceRoles } from '@/store/selectors/selectUserCurrentWorkspaceRoles';
import { API_WORKSPACE_ROLE_LIMITED_GUEST_WRITER } from '@/utils/can/constants';

function useIsUserLimitedGuestWriter() {
  const currentWorkspaceRoles = useSelector(selectUserCurrentWorkspaceRoles);

  return currentWorkspaceRoles.includes(API_WORKSPACE_ROLE_LIMITED_GUEST_WRITER);
}

export default useIsUserLimitedGuestWriter;
