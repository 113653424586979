import { TYPE_ANALYTICS } from '@/utils/constants';

export const selectConversionRateGoalsMetrics = (state) => {
  const savedGoals =
    state.serviceIntegrations?.services?.find((service) => service.type === TYPE_ANALYTICS)?.data
      ?.custom_goals || {};
  const goalsRate = {};

  Object.keys(savedGoals).forEach((savedGoalKey) => {
    if (savedGoals[savedGoalKey]) {
      goalsRate[`${savedGoalKey}Rate`] = {
        ...savedGoals[savedGoalKey],
        id: `${savedGoals[savedGoalKey].id}Rate`,
        name: `${savedGoals[savedGoalKey].name} Rate`,
      };
    }
  });

  return {
    conversionRate: {
      id: 0,
      name: 'content:side-panel-components.performance.section.main-metrics-evolution.all-conversions',
    },
    ...goalsRate,
  };
};
