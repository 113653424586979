import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import LaunchTour from '@/components/Badge/LaunchTour';
import { NavButton } from '@/components/Button/Button';
import AddToPlanningDialog from '@/components/Dialog/AddToPlanningDialog';
import CreditLimitReachedDialog from '@/components/Dialog/CreditLimitReachedDialog';
import DeleteConfirmDialog from '@/components/Dialog/DeleteConfirmDialog';
import FilterGroups from '@/components/Filters/FilterGroups';
import SearchIcon from '@/components/icons/SearchIcon';
import InputRevealer from '@/components/Input/InputRevealer';
import { ListingHeaderContainer } from '@/components/Layout/Header';
import { LayoutContent } from '@/components/Layout/Layout';
import { PageDataLayout } from '@/components/PageDataLayout';
import { usePageDataLayout } from '@/components/PageDataLayout/PageDataLayout.context';
import AddToPlanningHeader from '@/components/Pages/Pages/AddToPlanningHeader';
import { FILTERS_LIST } from '@/components/Pages/Pages/constants';
import List from '@/components/Pages/Pages/List';
import ViewPanel from '@/components/Pages/Pages/ViewPanel';
import RemoveDraftFromPlanningDialog from '@/components/Planning/RemoveDraftFromPlanningDialog';
import { SidebarToggle } from '@/components/Sidebar';
import { Bold } from '@/containers/Content/EditorComponents/Nodes';
import Flex from '@/design-system/components/Flex';
import useMapUrlQueryToFilter from '@/hooks/useMapUrlQueryToFilter';
import { useMixpanelTrackEvent } from '@/hooks/useMixpanelTrackEvent';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import ContentService from '@/services/Content';
import PageService from '@/services/Page';
import { selectRemainingAnalysisUnit } from '@/store/selectors/selectRemainingAnalysisUnit';
import { PAGES_ADD_FILTER_CLICK, PAGES_IMPORT_CLICK } from '@/utils/3rdParty/Mixpanel/constants';
import { ANALYSIS_CREDITS_TYPE } from '@/utils/constants';
import applyFilters from '@/utils/filter/applyFilters';
import { DEFAULT_PRIORITY_SCORE, OPPORTUNITIES_TAB } from '@/utils/filter/constants';
import filterPagesByView from '@/utils/filter/filterPagesByView';
import mapFiltersToQueryParameter from '@/utils/filter/mapFiltersToQueryParameter';
import searchFilter from '@/utils/filter/searchFilter';
import { SECTIONS } from '@/utils/log/constants';
import { Log } from '@/utils/log/Log';

const MainContent = styled(LayoutContent)`
  padding: ${({ isSidebarOpen }) => (isSidebarOpen ? '0 32px 24px 32px' : '24px 32px')};

  ${({ theme }) => theme.mediaQueries.tablet} {
    && {
      padding: ${({ isSidebarOpen }) => (isSidebarOpen ? '0 32px 24px 32px' : '24px 32px')};
    }
  }
`;

const Wrapper = styled(LayoutContent)`
  margin: ${({ isSidebarOpen }) => isSidebarOpen && '24px 0 0 24px'};

  ${({ theme }) => theme.mediaQueries.phone} {
    && {
      margin-top: 0;
    }
  }
`;

const Container = styled(Flex)`
  margin-left: ${({ isSidebarOpen }) => !isSidebarOpen && '32px'};
`;

const StyledSearchIcon = styled(SearchIcon)`
  color: ${({ theme }) => theme.cssColors.dark060};
`;

const FlexWithMargin = styled(Flex)`
  margin-left: 25px;
  ${({ theme }) => theme.mediaQueries.phone} {
    && {
      margin-left: 10px;
    }
  }
`;

function Pages({
  allPages,
  contents,
  loading,
  pages,
  refreshData,
  triggerBulkAddToPlanning,
  triggerBulkUpdateAnalysis,
  triggerUpdateAnalysis,
  triggerDeletePages,
  triggerBulkExportPages,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { organizationId, workspaceId } = useParams();
  const i18nNameSpaceModal = 'components:dialog.delete-page-dialog';

  const remainingAnalysisUnit = useSelector(selectRemainingAnalysisUnit);
  const workspaceCountryName = useSelector(
    (state) => state.workspaces.byId[workspaceId]?.countryName
  );

  const [query, setQuery] = useSearchParams();
  const filters = useMapUrlQueryToFilter({
    filterList: FILTERS_LIST,
    onError,
  });

  const [contentsIds, setContentsIds] = useState([]);
  const [draftIdToDeleteFromPlanning, setDraftIdToDeleteFromPlanning] = useState(null);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isAddDraftOnError, setIsAddDraftOnError] = useState(false);
  const [isAddingDraft, setIsAddingDraft] = useState(false);
  const [isDeleteDraftDialogOpen, setIsDeleteDraftDialogOpen] = useState(false);
  const [isDeletingDraft, setIsDeletingDraft] = useState(false);
  const [isInsufficientCreditsModalOpen, setIsInsufficientCreditsModalOpen] = useState(false);
  const [metadataForAddToPlanningDialog, setMetadataForAddToPlanningDialog] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);
  const [pagesToDelete, setPagesToDelete] = useState([]);
  const { isSidebarOpen } = usePageDataLayout();

  const trackMixpanelEvent = useMixpanelTrackEvent();

  const { isFeatureEnabled: hasUnlimitedAnalysisCredits } = useOrganizationFeatureSet(
    'credits:analysis:has-unlimited-amount'
  );

  const associatedDrafts = pagesToDelete?.reduce(
    (acc, pageId) => acc + contents?.filter((content) => content?.pageId === pageId)?.length,
    0
  );

  function onError() {
    navigate(
      `/o/${organizationId}/w/${workspaceId}/contents?tab=${OPPORTUNITIES_TAB}&priorityScore=${DEFAULT_PRIORITY_SCORE}`,
      { replace: true }
    );
  }

  function handleDeleteConfirm() {
    triggerDeletePages(pagesToDelete);
  }

  function handleDelete(id) {
    setPagesToDelete([id]);
  }

  function handleBulkDelete() {
    setPagesToDelete(selectedListIds);
  }

  function handleSearchChange(e) {
    const searchTerm = e.currentTarget.value.toLowerCase();

    setSearchTerm(searchTerm);
  }

  function onCloseDeletePagesDialog() {
    setPagesToDelete([]);
  }

  function handleCLoseInsufficientCreditModal() {
    setIsInsufficientCreditsModalOpen(false);
  }

  function handleSetContentsIds(contentsIds) {
    setContentsIds([...contentsIds]);
  }

  function handleResetContentsIds() {
    setContentsIds([]);
  }

  function handleCloseAddToPlanningDialog() {
    setIsAddDialogOpen(false);
    setMetadataForAddToPlanningDialog(null);
  }

  function handleRemoveFromPlanning(draftId) {
    setDraftIdToDeleteFromPlanning(draftId);
    setIsDeleteDraftDialogOpen(true);
  }

  function handleCloseRemoveDraftFromPlanningDialog() {
    setIsDeleteDraftDialogOpen(false);
    setDraftIdToDeleteFromPlanning(null);
  }

  function handleAddToPlanning(metadataForAddToPlanningDialog) {
    setIsAddDialogOpen(true);
    setMetadataForAddToPlanningDialog(metadataForAddToPlanningDialog);
  }

  function getSelectedListIds(visibleRows) {
    return contentsIds.filter((id) => visibleRows.findIndex((row) => row.id === id) !== -1);
  }

  function getSelectedListWithFocusKeyword(visibleRows) {
    return visibleRows.filter((elem) => !!elem.focusTopKeyword && contentsIds.includes(elem.id));
  }

  function getSelectedListNotPlanned(visibleRows) {
    return visibleRows.filter((elem) => elem.draftId === null && contentsIds.includes(elem.id));
  }

  async function onConfirmAddDraftToPlanning({ assignedTo, dueDate, contentStatus, folderId }) {
    const { pageId, title } = metadataForAddToPlanningDialog || {};
    const wId = metadataForAddToPlanningDialog?.workspaceId || workspaceId;

    if (pageId) {
      const pageService = new PageService(pageId, wId);

      try {
        setIsAddingDraft(true);
        setIsAddDraftOnError(false);
        await pageService.saveNewDraft({
          assignedTo,
          contentStatus,
          dueDate,
          folderId,
          html: null,
          title,
        });
        refreshData();
      } catch (error) {
        Log.report({
          context: 'onConfirmAddDraftToPlanning',
          error,
          extra: 'Draft create action',
          section: SECTIONS.pages.key,
        });
        setIsAddDraftOnError(true);
      } finally {
        setIsAddingDraft(false);
        setIsAddDialogOpen(false);
        setMetadataForAddToPlanningDialog(null);
      }
    }
  }

  async function onConfirmDeleteDraftFromPlanning() {
    const draftId = draftIdToDeleteFromPlanning;

    if (draftId) {
      const contentService = new ContentService(draftId);

      try {
        setIsDeletingDraft(true);
        await contentService.delete();
        refreshData();
      } catch (error) {
        Log.report({
          context: 'onConfirmDeleteDraftFromPlanning',
          error,
          extra: 'Draft delete action',
          section: SECTIONS.pages.key,
        });
      } finally {
        setDraftIdToDeleteFromPlanning(null);
        setIsDeleteDraftDialogOpen(false);
        setIsDeletingDraft(false);
      }
    }
  }

  function onNavigationToImport(event) {
    trackMixpanelEvent(PAGES_IMPORT_CLICK);

    if (!hasUnlimitedAnalysisCredits && remainingAnalysisUnit < 1) {
      event.preventDefault();
      setIsInsufficientCreditsModalOpen(true);
    }
  }

  function applyFiltersToUrl(filters = {}) {
    mapFiltersToQueryParameter({ filters, location, navigate, query, setQuery });
  }

  function applyAllFilters() {
    try {
      const tab = query.getAll('tab')[0] ?? OPPORTUNITIES_TAB;
      const algorithm = query.getAll('priorityScore')[0] ?? DEFAULT_PRIORITY_SCORE;

      const pagesSearchFiltered = searchFilter(pages, searchTerm);
      const pagesFilterFiltered = applyFilters(pagesSearchFiltered, filters);
      const pagesFiltered = filterPagesByView(pagesFilterFiltered, tab, algorithm);

      return pagesFiltered;
    } catch {
      navigate(
        `/o/${organizationId}/w/${workspaceId}/contents?tab=${OPPORTUNITIES_TAB}&priorityScore=${DEFAULT_PRIORITY_SCORE}`,
        { replace: true }
      );
      return pages;
    }
  }

  const pagesFiltered = applyAllFilters();
  const selectedListWithFocusKeyword = getSelectedListWithFocusKeyword(pagesFiltered);
  const selectedListIds = getSelectedListIds(pagesFiltered);
  const selectedListNotPlanned = getSelectedListNotPlanned(pagesFiltered);

  return (
    <>
      <PageDataLayout
        sideBar={{
          content: <ViewPanel pages={allPages} resetContentsIds={handleResetContentsIds} />,
          dataIntercomTarget: 'product_tour_pages',
          titleContent: <span>{t('listing:pages-list.pages')}</span>,
        }}
      >
        <MainContent flexDirection="row" isSidebarOpen={isSidebarOpen}>
          <Wrapper flex={3} flexDirection="column" isSidebarOpen={isSidebarOpen}>
            <ListingHeaderContainer margin="0 0 50px 0">
              <SidebarToggle />
              <FlexWithMargin
                flex={1}
                flexDirection="row"
                height="100%"
                onClick={() => trackMixpanelEvent(PAGES_ADD_FILTER_CLICK)}
              >
                {!loading && (
                  <FilterGroups
                    applyFilters={applyFiltersToUrl}
                    defaultFilters={filters}
                    filtersList={FILTERS_LIST}
                  />
                )}
              </FlexWithMargin>
              <Flex alignItems="center" gap="15px" height="35px">
                <InputRevealer context="Pages" handleChange={handleSearchChange}>
                  <StyledSearchIcon />
                </InputRevealer>
                <NavButton
                  data-intercom-target="pages_import_new_contents_button"
                  to="import-pages"
                  onClick={onNavigationToImport}
                >
                  {t('listing:pages-list.import')}
                </NavButton>
              </Flex>
            </ListingHeaderContainer>
            <Container flex={1} isSidebarOpen={isSidebarOpen}>
              <List
                addToPlanning={handleAddToPlanning}
                contentsIds={contentsIds}
                deleteRow={handleDelete}
                loading={loading}
                openBulkDeleteDialog={handleBulkDelete}
                pages={pages}
                pagesFiltered={pagesFiltered}
                removeFromPlanning={handleRemoveFromPlanning}
                selectedListIds={selectedListIds}
                selectedListNotPlanned={selectedListNotPlanned}
                selectedListWithFocusKeyword={selectedListWithFocusKeyword}
                setContentsIds={handleSetContentsIds}
                triggerBulkAddToPlanning={triggerBulkAddToPlanning}
                triggerBulkExportPages={triggerBulkExportPages}
                triggerBulkUpdateAnalysis={triggerBulkUpdateAnalysis}
                triggerUpdateAnalysis={triggerUpdateAnalysis}
                workspaceCountryName={workspaceCountryName}
              />
            </Container>
          </Wrapper>
        </MainContent>
        {isAddDialogOpen && (
          <AddToPlanningDialog
            error={isAddDraftOnError}
            header={<AddToPlanningHeader data={metadataForAddToPlanningDialog} />}
            loading={isAddingDraft}
            open
            onClose={handleCloseAddToPlanningDialog}
            onConfirm={onConfirmAddDraftToPlanning}
          />
        )}
        {isDeleteDraftDialogOpen && (
          <RemoveDraftFromPlanningDialog
            isLoading={isDeletingDraft}
            isOpen
            onCancel={handleCloseRemoveDraftFromPlanningDialog}
            onConfirm={onConfirmDeleteDraftFromPlanning}
          />
        )}
        {isInsufficientCreditsModalOpen && (
          <CreditLimitReachedDialog
            creditType={ANALYSIS_CREDITS_TYPE}
            isOpen
            onClose={handleCLoseInsufficientCreditModal}
          />
        )}
        {!!pagesToDelete.length && (
          <DeleteConfirmDialog
            cancelLabel={t(`${i18nNameSpaceModal}.cancel`)}
            confirmationText={
              <Trans
                components={{
                  bold: <Bold />,
                }}
                i18nKey={`${i18nNameSpaceModal}.confirm-message`}
                values={{ delete: t(`${i18nNameSpaceModal}.delete-placeholder`) }}
              />
            }
            deleteLabel={t(`${i18nNameSpaceModal}.delete-button`)}
            deletePlaceholder={t(`${i18nNameSpaceModal}.delete-placeholder`)}
            explanationText={
              <Trans
                components={{
                  bold: <Bold />,
                }}
                i18nKey={`${i18nNameSpaceModal}.explanation-text`}
                values={{
                  selectedPages: t(`${i18nNameSpaceModal}.selected-pages`, {
                    count: pagesToDelete.length,
                    postProcess: 'interval',
                  }),
                }}
              />
            }
            open
            title={t(`${i18nNameSpaceModal}.title`)}
            warningCondition={!!associatedDrafts}
            warningMessage={
              <Trans
                components={{
                  bold: <Bold />,
                }}
                i18nKey={`${i18nNameSpaceModal}.warning-text_interval`}
                values={{
                  associatedDrafts: t(`${i18nNameSpaceModal}.associated-drafts`, {
                    count: associatedDrafts,
                    postProcess: 'interval',
                  }),
                  count: associatedDrafts,
                  postProcess: 'interval',
                }}
              />
            }
            onClose={onCloseDeletePagesDialog}
            onConfirm={handleDeleteConfirm}
          />
        )}
      </PageDataLayout>
    </>
  );
}

export default Pages;
