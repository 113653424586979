import { lighten } from 'polished';
import styled from 'styled-components/macro';

const HeatComputer = styled.div`
  background-color: ${(props) => lighten(props.lighten, props.theme.colors.secondary)};
  border: 1px solid white;
  width: 100%;
  height: 100%;
`;

export const Heat = ({ count = 0 }) => {
  if (count > 10) {
    return <HeatComputer lighten={0} />;
  }
  if (count >= 3) {
    return <HeatComputer lighten={0.17} />;
  }
  if (count >= 1) {
    return <HeatComputer lighten={0.28} />;
  }
  return <HeatComputer lighten={1} />;
};
