import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { FlexCenteredAlignContainer } from '@/components/_common';
import { Feature, UnlimitedFeature } from '@/containers/Settings/Organization/Billing/Plan';
import { OrganizationFeatureSet } from '@/hoc/OrganizationFeatureSet';
import useApiConfigurations from '@/hooks/useApiConfigurations';
import useCan from '@/hooks/useCan';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import { breakpoints } from '@/themes/responsive';
import {
  CONTENT_AI_WRITING_CREDITS_ENABLED,
  CONTENT_IDEAS_CREDITS_ENABLED,
} from '@/utils/configurations/constants';

const FlexCenteredAlignContainerMarged = styled(FlexCenteredAlignContainer)`
  margin: 10px 0px 25px 5px;
  @media (max-width: ${breakpoints.xl}) {
    flex-direction: column;
    align-items: baseline;
  }
`;
const FlexAlignCenter = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: ${({ margin }) => margin};
  min-height: ${({ minHeight }) => minHeight};
  min-width: ${({ minWidth }) => minWidth};
  justify-content: ${({ justifyContent }) => justifyContent};
`;

function Credits({ organization }) {
  const { t } = useTranslation();

  const { totalAiWritingUnit = 0, totalContentIdeaSearchUnit = 0 } = organization;
  const contentIdeasCreditsEnabled = useApiConfigurations(CONTENT_IDEAS_CREDITS_ENABLED);
  const aiWritingCreditsEnabled = useApiConfigurations(CONTENT_AI_WRITING_CREDITS_ENABLED);
  const maxAllowedUsers = organization.featureSet['users:invitations:allowed-amount'] ?? 0;
  const usersCount = organization.usersCount ?? 0;
  const maxAllowedWorkspaces =
    organization.featureSet['organization:workspaces:allowed-amount'] ?? 0;
  const workspacesCount = organization.workspacesCount ?? 0;
  const totalAnalysisUnit = organization.totalAnalysisUnit ?? 0;
  const remainingAnalysisUnit = organization.remainingAnalysisUnit ?? 0;
  const totalTrackedPages = organization.trackedPages ?? 0;
  const remainingAiWritingUnit = organization.remainingAiWritingUnit ?? 0;
  const foldersCount = organization.foldersCount ?? 0;
  const remainingContentIdeasSearchesUnit = organization.remainingContentIdeasSearchesUnit ?? 0;
  const { data: foldersTotal = 0 } = useOrganizationFeatureSet('organization:folders:amount');
  const { data: trackedPagesLimit = 0 } = useOrganizationFeatureSet(
    'rank-tracking:urls-tracked-number'
  );
  const { isFeatureEnabled: rankTrackingFeatSetFlag } = useOrganizationFeatureSet(
    'rank-tracking:is-enabled'
  );
  const { isFeatureEnabled: usersCreditsDisplayEnabled } = useOrganizationFeatureSet(
    'credits:users:enable-display'
  );
  const { isFeatureEnabled: analysisCreditsDisplayEnabled } = useOrganizationFeatureSet(
    'credits:analysis:enable-display'
  );
  const rankTrackingFeatFlag = useCan({ perform: 'rank-tracking:enabled' });

  return (
    <FlexCenteredAlignContainerMarged>
      <FlexAlignCenter>
        {usersCreditsDisplayEnabled && (
          <OrganizationFeatureSet
            feature="users:invitations:has-unlimited-amount"
            no={() => (
              <Feature
                description={t('settings:workspace.billing-plan.users-tooltip_interval', {
                  count: usersCount,
                  postProcess: 'interval',
                  total: maxAllowedUsers,
                })}
                name={t('settings:workspace.billing-plan.users-label')}
                target={maxAllowedUsers}
                value={usersCount}
              />
            )}
            yes={() => (
              <UnlimitedFeature
                description={t('settings:workspace.billing-plan.users-tooltip-unlimited')}
                name={t('settings:workspace.billing-plan.users-label')}
              />
            )}
          />
        )}
        <OrganizationFeatureSet
          feature="organization:workspaces:has-unlimited-amount"
          no={() => (
            <Feature
              description={t('settings:workspace.billing-plan.workspace-tooltip_interval', {
                count: workspacesCount,
                postProcess: 'interval',
                total: maxAllowedWorkspaces,
              })}
              name={t('settings:workspace.billing-plan.workspace-label')}
              target={maxAllowedWorkspaces}
              value={workspacesCount}
            />
          )}
          yes={() => (
            <UnlimitedFeature
              description={t('settings:workspace.billing-plan.users-tooltip-unlimited')}
              name={t('settings:workspace.billing-plan.workspace-label')}
            />
          )}
        />
        {analysisCreditsDisplayEnabled && (
          <OrganizationFeatureSet
            feature="credits:analysis:has-unlimited-amount"
            no={() => (
              <Feature
                description={t('settings:workspace.billing-plan.analysis-tooltip_interval', {
                  count: totalAnalysisUnit - remainingAnalysisUnit,
                  postProcess: 'interval',
                  total: totalAnalysisUnit,
                })}
                name={t('settings:workspace.billing-plan.analysis-label')}
                target={totalAnalysisUnit}
                value={totalAnalysisUnit - remainingAnalysisUnit}
              />
            )}
            yes={() => (
              <UnlimitedFeature
                description={t('settings:workspace.billing-plan.analysis-tooltip-unlimited')}
                name={t('settings:workspace.billing-plan.analysis-label')}
              />
            )}
          />
        )}
        {rankTrackingFeatSetFlag && rankTrackingFeatFlag && (
          <OrganizationFeatureSet
            feature="rank-tracking:urls-tracked-number:has-unlimited-amount"
            no={() => (
              <Feature
                description={t('settings:workspace.billing-plan.rank-tracking-tooltip_interval', {
                  count: totalTrackedPages,
                  postProcess: 'interval',
                  total: trackedPagesLimit,
                })}
                name={t('settings:workspace.billing-plan.rank-tracking-label')}
                target={trackedPagesLimit}
                value={totalTrackedPages}
              />
            )}
            yes={() => (
              <UnlimitedFeature
                description={t('settings:workspace.billing-plan.rank-tracking-tooltip-unlimited')}
                name={t('settings:workspace.billing-plan.rank-tracking-label')}
              />
            )}
          />
        )}
        {aiWritingCreditsEnabled && (
          <OrganizationFeatureSet
            feature="credits:ai-writing:has-unlimited-amount"
            no={() => (
              <Feature
                description={t('settings:workspace.billing-plan.ai-writing-tooltip_interval', {
                  count: totalAiWritingUnit - remainingAiWritingUnit,
                  postProcess: 'interval',
                  total: totalAiWritingUnit,
                })}
                name={t('settings:workspace.billing-plan.ai-writing-label')}
                target={totalAiWritingUnit}
                value={totalAiWritingUnit - remainingAiWritingUnit}
              />
            )}
            yes={() => (
              <UnlimitedFeature
                description={t('settings:workspace.billing-plan.ai-writing-tooltip-unlimited')}
                name={t('settings:workspace.billing-plan.ai-writing-label')}
              />
            )}
          />
        )}
        {contentIdeasCreditsEnabled && (
          <OrganizationFeatureSet
            feature="credits:content-ideas-searches:has-unlimited-amount"
            no={() => (
              <Feature
                description={t('settings:workspace.billing-plan.content-ideas-tooltip_interval', {
                  count: totalContentIdeaSearchUnit - remainingContentIdeasSearchesUnit,
                  postProcess: 'interval',
                  total: totalContentIdeaSearchUnit,
                })}
                name={t('settings:workspace.billing-plan.content-ideas-label')}
                target={totalContentIdeaSearchUnit}
                value={totalContentIdeaSearchUnit - remainingContentIdeasSearchesUnit}
              />
            )}
            yes={() => (
              <UnlimitedFeature
                description={t('settings:workspace.billing-plan.content-ideas-tooltip-unlimited')}
                name={t('settings:workspace.billing-plan.content-ideas-label')}
              />
            )}
          />
        )}
        <OrganizationFeatureSet
          feature="organization:folders:has-unlimited-amount"
          no={() => (
            <Feature
              description={t('settings:workspace.billing-plan.folders-tooltip_interval', {
                count: foldersCount,
                postProcess: 'interval',
                total: foldersTotal,
              })}
              name={t('settings:workspace.billing-plan.folders-label')}
              target={foldersTotal}
              value={foldersCount}
            />
          )}
          yes={() => (
            <UnlimitedFeature
              description={t('settings:workspace.billing-plan.folders-tooltip-unlimited')}
              name={t('settings:workspace.billing-plan.folders-label')}
            />
          )}
        />
      </FlexAlignCenter>
    </FlexCenteredAlignContainerMarged>
  );
}

export default Credits;
