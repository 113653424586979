import React from 'react';

import { MultiSelect } from '@/components/DynamicFilters/MultiSelect';
import { Radio } from '@/components/DynamicFilters/Radio';
import { RadioRange } from '@/components/DynamicFilters/RadioRange';
import { Select } from '@/components/DynamicFilters/Select';
import { UseFiltersResults } from '@/hooks/useFilters/useFilters.types';

import { ENUM_FILTER_TYPE, FilterType } from '../index';

export default function FilterItem({
  type,
  filter,
  id,
  filterHook,
}: {
  type: ENUM_FILTER_TYPE;
  id: string;
  filter: FilterType;
  filterHook: UseFiltersResults<any>;
}) {
  if (type === ENUM_FILTER_TYPE.SELECT) {
    // @ts-ignore
    return <Select {...filter} filterHook={filterHook} id={id} />;
  }

  if (type === ENUM_FILTER_TYPE.MULTI_SELECT) {
    // @ts-ignore
    return <MultiSelect {...filter} filterHook={filterHook} id={id} />;
  }

  if (type === ENUM_FILTER_TYPE.TOGGLE) {
    return 'TO DO';
  }

  if (type === ENUM_FILTER_TYPE.RADIO) {
    // @ts-ignore
    return <Radio {...filter} filterHook={filterHook} id={id} />;
  }

  if (type === ENUM_FILTER_TYPE.RADIO_RANGE) {
    // @ts-ignore
    return <RadioRange filterKey={filter.key} {...filter} filterHook={filterHook} id={id} />;
  }

  return null;
}
