import React from 'react';
import { useTranslation } from 'react-i18next';

import OptimizationIcon from '@/components/icons/OptimizationIcon';
import { ParagraphText } from '@/components/Text/Paragraph';
import { TitleTwo } from '@/components/Text/Title';
import { EditorEmptyBodyWrapper } from '@/containers/Content/Wrappers';

const PagesImport = () => {
  const { t } = useTranslation();

  return (
    <EditorEmptyBodyWrapper>
      <OptimizationIcon />
      <TitleTwo weight="strong">{t('empty-state:pages-import.title')}</TitleTwo>
      <ParagraphText align="center" color="dark060" weight="medium">
        {t('empty-state:pages-import.all-pages-have-been-imported')}
      </ParagraphText>
    </EditorEmptyBodyWrapper>
  );
};

export default PagesImport;
