import './FilterAnchor.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { ENUM_FILTER_TYPE } from '@/components/DynamicFilters';
import CaretIcon from '@/components/icons/CaretIcon';
import CloseIcon from '@/components/icons/CloseIcon';

function FilterAnchor({
  icon,
  id,
  onValidate,
  name,
  options,
  type,
  customLabel,
  onReset,
  disabled,
}: {
  icon: any;
  id: string;
  onValidate: Function;
  options: any[];
  name: React.ReactNode;
  type: ENUM_FILTER_TYPE;
  customLabel?: string;
  onReset?: () => void;
  disabled?: boolean;
}) {
  const { i18n } = useTranslation();

  const selectedCount = options.filter((option) => option.selected).length;
  const isInactive =
    !customLabel && (type === ENUM_FILTER_TYPE.RADIO || type === ENUM_FILTER_TYPE.RADIO_RANGE)
      ? isRadioInactive()
      : isMultiSelectInactive();

  function isRadioInactive() {
    return !options.find(({ selected }) => selected === true);
  }

  function isMultiSelectInactive() {
    return options.every((option) => option.selected);
  }

  function getCount() {
    if (isInactive || selectedCount === 0 || selectedCount === 1) {
      return;
    }
    return `(+${selectedCount - 1})`;
  }

  function getAnchorLabel(): React.ReactNode | string {
    if (customLabel) return customLabel;
    if (isInactive || selectedCount === 0) {
      return name;
    }
    if (selectedCount === 1) {
      const selectedOption = options.find((option) => option.selected);
      return `${name} ${i18n.language === 'fr' ? ' ' : ''}: ${selectedOption?.name || selectedOption?.label}`;
    }
    const firstSelected = options.find((option) => option.selected);
    return `${name} ${i18n.language === 'fr' ? ' ' : ''}: ${firstSelected?.name || firstSelected?.label}`;
  }

  function resetAndValidate(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (disabled || isInactive) return;

    type === ENUM_FILTER_TYPE.RADIO || type === ENUM_FILTER_TYPE.RADIO_RANGE
      ? resetRadio()
      : resetMultiSelect();
    onReset?.();
    e.stopPropagation();
  }

  function resetRadio() {
    onValidate(undefined);
  }

  function resetMultiSelect() {
    onValidate(options.map((option) => option.value));
  }

  return (
    <div
      className={`competitors-filter-anchor competitors-filter-anchor--${isInactive ? 'inactive' : 'active'} competitors-filter-anchor--${disabled ? 'disabled' : 'enabled'}`}
      id={id}
    >
      <div className="competitors-filter-anchor__content">
        {icon}
        <span className="competitors-filter-anchor__content__label">{getAnchorLabel()}</span>
        <span className="competitors-filter-anchor__content__count">{getCount()}</span>
      </div>
      {!isInactive && <div className="competitors-filter-anchor__divider" />}
      <div
        className={`competitors-filter-anchor__action competitors-filter-anchor__action--${disabled ? 'disabled' : 'enabled'}`}
        onClick={resetAndValidate}
      >
        {isInactive ? <CaretIcon /> : <CloseIcon />}
      </div>
    </div>
  );
}

export default FilterAnchor;
