import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import LaunchAnalysisIcon from '@/assets/images/launch-analysis.svg';
import { PrimaryLink } from '@/components/Navigation/Link';
import { StyledImg } from '@/containers/Onboarding/common';
import DescribeStepsComponents from '@/containers/Onboarding/DescribeSteps';

function LaunchAnalysis() {
  const i18nNameSpace =
    'quick-start-guide:body.accordion.optimise-existing-content.launch-analysis';
  const { t } = useTranslation();
  const { organizationId, workspaceId } = useParams();

  return (
    <DescribeStepsComponents
      explanatoryText={<Trans i18nKey={`${i18nNameSpace}.explanatory-text`} />}
      steps={[
        <Trans
          key={`${i18nNameSpace}.steps.discover-pages`}
          components={{
            primaryLink: (
              <PrimaryLink
                href={`/o/${organizationId}/w/${workspaceId}/contents`}
                isExternal
                noDecoration
                size="sm"
                weight="medium"
              />
            ),
          }}
          i18nKey={`${i18nNameSpace}.steps.discover-pages`}
        />,
        <Trans
          key={`${i18nNameSpace}.steps.choose-page`}
          i18nKey={`${i18nNameSpace}.steps.choose-page`}
        />,
        <Trans key={`${i18nNameSpace}.steps.start`} i18nKey={`${i18nNameSpace}.steps.start`} />,
      ]}
      visualRendering={<StyledImg alt={t(`${i18nNameSpace}.alt-image`)} src={LaunchAnalysisIcon} />}
    />
  );
}

export default LaunchAnalysis;
