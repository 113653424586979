import highlightChildren from './highlightChildren';
import highlightElement from './highlightElement';

function handleHighlightByType({
  element,
  datum,
  dataAttribute,
  range,
  highlightClass,
  overrideClassNames,
  overrideData,
}: {
  element: HTMLElement;
  datum: string;
  dataAttribute: string;
  range: Range;
  highlightClass: string;
  overrideClassNames: boolean;
  overrideData: boolean;
}): void {
  if (element.nodeType === Node.TEXT_NODE) {
    highlightElement({
      dataAttribute,
      datum,
      element,
      highlightClass,
      overrideClassNames,
      overrideData,
      range,
    });

    return;
  }

  highlightChildren({
    dataAttribute,
    datum,
    element,
    highlightClass,
    overrideClassNames,
    overrideData,
    range,
  });
}

export default handleHighlightByType;
