import { nanoid } from 'nanoid';
import { useMemo } from 'react';

import SvgWrapper from './SvgWrapper';

function AngleLeft(props) {
  const svgId = useMemo(() => nanoid(), []);

  return (
    <SvgWrapper {...props} viewBox="0 0 15 16">
      <mask
        height="15"
        id={svgId}
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="8"
        x="3"
        y="1"
      >
        <path
          d="M4.59433 8.07365L10.8996 14.3798L10.2758 15.0037L3.34668 8.07365L10.2767 1.14453L10.9005 1.76835L4.59433 8.07365Z"
          fill="black"
        />
      </mask>
      <g mask={`url(#${svgId})`}>
        <rect height="17.6471" width="17.6471" x="-1.76465" y="-0.283203" />
      </g>
    </SvgWrapper>
  );
}

export default AngleLeft;
