import { DateTime } from 'luxon';

import { ENUM_CONTENT_TYPE } from '@/types/contents';

import { TODAY, VersionType } from './VersionGroup/VersionGroup.types';
import { GroupedVersion } from './VersionHistoryPanel.types';

export default class VersionHistoryPanelUtils {
  static groupVersionsByMonthYear(versions: VersionType[]): GroupedVersion {
    const groupedVersions: GroupedVersion = {};
    const currentDate = DateTime.now();

    // Add today's at the top of the list
    groupedVersions[TODAY] = [];
    const sortedVersions = versions.sort(VersionHistoryPanelUtils.sortVersionType);

    sortedVersions.forEach((version) => {
      const isToday =
        version.day === currentDate.day &&
        version.month === currentDate.month &&
        version.year === currentDate.year;
      const key =
        isToday || version.isCurrentDraft
          ? TODAY
          : `${version.month.toString().padStart(2, '0')}-${version.year}`;

      if (!groupedVersions[key]) {
        groupedVersions[key] = [];
      }

      groupedVersions[key].push(version);
    });

    return groupedVersions;
  }

  static filterVersionsByStatus(versions: VersionType[], status: string) {
    return versions.filter((version) => {
      switch (status) {
        case ENUM_CONTENT_TYPE.DRAFT:
          return version.type === ENUM_CONTENT_TYPE.DRAFT;
        case ENUM_CONTENT_TYPE.PUBLISHED:
          return version.type !== ENUM_CONTENT_TYPE.DRAFT;
        default:
          return false;
      }
    });
  }

  static sortVersionType(a: VersionType, b: VersionType) {
    if (b.year - a.year !== 0) {
      return b.year - a.year;
    }
    if (b.month - a.month !== 0) {
      return b.month - a.month;
    }
    if (b.day - a.day !== 0) {
      return b.day - a.day;
    }
    if (b.hour - a.hour !== 0) {
      return b.hour - a.hour;
    }
    return 0;
  }
}
