import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { PlanRestrictionBadge } from '@/components/Badge/PlanRestrictionBadge';
import { buildMetricFeatureSetFlag } from '@/components/Metrics/helpers';
import { CustomSelectorBox, HorizontalSelectorItem } from '@/components/Selector';
import { OrganizationFeatureSet } from '@/hoc/OrganizationFeatureSet';
import useCan from '@/hooks/useCan';
import { METRIC_POSITION_KEY } from '@/utils/metrics/constants';

const SelectorItem = styled.div`
  display: flex;
  flex-direction: row;
`;

const SelectorListContainer = styled.div`
  max-width: 362px;
  overflow-y: scroll;
`;

function MetricsSelectorBox({
  open,
  anchorEl,
  onClose,
  anchorOrigin,
  transformOrigin,
  direction,
  elements,
  activeItemKey = '',
}) {
  const { t } = useTranslation();

  const rankTrackingFeatFlag = useCan({ perform: 'rank-tracking:enabled' });

  return (
    <CustomSelectorBox
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      direction={direction}
      open={open}
      transformOrigin={transformOrigin}
      onClose={onClose}
    >
      <SelectorListContainer>
        {elements.map(({ title, metricsList, selectMetric }) => (
          <div key={title}>
            <HorizontalSelectorItem disabled extraPadding textTransformation="uppercase">
              {title}
            </HorizontalSelectorItem>
            {metricsList.map(
              (metric) =>
                (metric.key !== METRIC_POSITION_KEY || rankTrackingFeatFlag) && (
                  <HorizontalSelectorItem
                    key={metric.key}
                    active={activeItemKey === metric.key}
                    data-intercom-target={`metrics_drop_down_${metric.key}_metric`}
                    minHeight="40px"
                    onClick={(e) => {
                      selectMetric(metric);
                      onClose(e);
                    }}
                  >
                    <SelectorItem>
                      <span>
                        {metric.icon}
                        &nbsp;{t(`${metric.name}`)}
                      </span>
                      {metric?.badge && <span>{metric.badge}</span>}
                    </SelectorItem>
                    <OrganizationFeatureSet
                      feature={buildMetricFeatureSetFlag({
                        category: metric.category,
                        metricKey: metric.key,
                      })}
                      no={() => <PlanRestrictionBadge isCondensed />}
                    />
                  </HorizontalSelectorItem>
                )
            )}
          </div>
        ))}
      </SelectorListContainer>
    </CustomSelectorBox>
  );
}

MetricsSelectorBox.propTypes = {
  anchorEl: PropTypes.object,
  anchorOrigin: PropTypes.object.isRequired,
  direction: PropTypes.string.isRequired,
  elements: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  transformOrigin: PropTypes.object.isRequired,
};

export default MetricsSelectorBox;
