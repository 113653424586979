import { fetchPageCompetitorPages } from '../apis/semji/api';

export default class Competitor {
  constructor(pageId) {
    this._pageId = pageId;
    this._competitors = null;
  }

  get competitors() {
    return this._fetchCompetitors();
  }

  async _fetchCompetitors() {
    if (this._competitors === null) {
      try {
        this._competitors = await fetchPageCompetitorPages(this._pageId);
      } catch (e) {
        throw new Error(e);
      }
    }

    return this._competitors['hydra:member'];
  }
}
