import { ReportMetricKey } from '@/containers/Report/utils/types';
import { FOCUS_TOP_KEYWORD_POSITION, METRIC_POSITION_KEY } from '@/utils/metrics/constants';

export const aggregateMetrics = (metrics, metricsMapToAggregate) => {
  let initialValue = {};
  if (metrics.length === 0) {
    Object.keys(metricsMapToAggregate).forEach((key) => {
      initialValue[key] = null;
    });
    return initialValue;
  }

  // Specific total for ranking, which ignores null values when counting total
  const positionTotal = metrics.reduce((accumulator, currentValue) => {
    return accumulator + (currentValue.focusTopKeywordPosition ? 1 : 0);
  }, 0);
  // Specific total for content score, which ignores null values when counting total
  const contentScoreTotal = metrics.reduce((accumulator, currentValue) => {
    return accumulator + (currentValue.publicationsAverageContentScore !== null ? 1 : 0);
  }, 0);
  const total = metrics.reduce((accumulator, currentValue) => {
    return accumulator + (currentValue.weight || 1);
  }, 0);

  Object.keys(metricsMapToAggregate).forEach((key) => {
    initialValue[key] = 0;
  });

  return metrics.reduce((accumulator, currentValue) => {
    Object.keys(metricsMapToAggregate).forEach((key) => {
      const reducer = metricsMapToAggregate[key].reducer;

      if (key === ReportMetricKey.Position) {
        const value = currentValue[FOCUS_TOP_KEYWORD_POSITION];
        // Keep undefined values for Position metric
        accumulator[key] = !!value
          ? reducer(accumulator[key], value, positionTotal, currentValue.weight || 1)
          : undefined;
      } else if (key === ReportMetricKey.AverageContentScore) {
        // only count non-null values for content score
        accumulator[key] = reducer(
          accumulator[key],
          currentValue[key],
          contentScoreTotal,
          currentValue.weight || 1
        );
      } else {
        accumulator[key] = reducer(
          accumulator[key],
          currentValue[key],
          total,
          currentValue.weight || 1
        );
      }

      if (key === METRIC_POSITION_KEY) {
        const rankingKeywordsMetricsValue = metricsMapToAggregate[
          key
        ].rankingKeywordsMetrics?.reduce((acc, metric) => acc + (currentValue[metric.key] ?? 0), 0);
        accumulator.rankingKeywordsMetrics = rankingKeywordsMetricsValue;
      }
    });

    return {
      date: currentValue.date, // keep the last date for each aggregated metric
      ...accumulator,
    };
  }, initialValue);
};
