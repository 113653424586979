import { useTranslation } from 'react-i18next';

import { TertiaryLink } from '@/components/Navigation/Link';
import Column from '@/containers/Content/BriefComponents/Table/Columns/Column';
import PrimaryColumn from '@/containers/Content/BriefComponents/Table/Columns/PrimaryColumn';
import EmptyRow from '@/containers/Content/BriefComponents/Table/Rows/EmptyRow';
import Header from '@/containers/Content/BriefComponents/Table/Rows/Header';
import LoadingRow from '@/containers/Content/BriefComponents/Table/Rows/LoadingRow';
import MultiLineRow from '@/containers/Content/BriefComponents/Table/Rows/MultiLineRow';
import Table from '@/containers/Content/BriefComponents/Table/Table';

function IncomingLinks({ isLoading, incomingLinks }) {
  const { t } = useTranslation();

  return (
    <Table>
      <Header>
        <PrimaryColumn>
          {t('content:brief.section-article-table-suggested-incoming-links-header')}
        </PrimaryColumn>
      </Header>

      {isLoading ? (
        <LoadingRow />
      ) : incomingLinks?.length > 0 ? (
        incomingLinks.map(({ title, description, url, position }) => (
          <MultiLineRow key={`${title}_${description}`} gap="8px">
            <PrimaryColumn>
              <TertiaryLink color="secondary" isExternal title={title} to={url} weight="400">
                {title}
              </TertiaryLink>
            </PrimaryColumn>
            <Column color="light">
              <div dangerouslySetInnerHTML={{ __html: description }} />
            </Column>
          </MultiLineRow>
        ))
      ) : (
        <EmptyRow />
      )}
    </Table>
  );
}

export default IncomingLinks;
