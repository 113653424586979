import { initialState } from '../../store/reducers/createWorkspace';

function getDefaultCountry(countries) {
  return countries.find((c) => c.isObvious) || countries[0] || initialState.location;
}

function getRegionAndLanguage(languageCode) {
  try {
    const { region, language } = new Intl.Locale(languageCode);
    return { language, region };
  } catch (err) {
    return { language: languageCode, region: null };
  }
}

export function getDefaultLocation(countries) {
  const navigatorLanguage = navigator.language;

  if (!countries) {
    return initialState.location;
  }

  if (!navigatorLanguage) {
    return getDefaultCountry(countries);
  }

  const { region, language } = getRegionAndLanguage(navigatorLanguage);

  if (!region && language) {
    const countriesWithSameLanguageCode = countries.filter((c) => c.languageCode === language);

    if (0 === countriesWithSameLanguageCode.length) {
      return getDefaultCountry(countries);
    }

    return getDefaultCountry(countriesWithSameLanguageCode);
  }

  const countriesWithSameCountryCode = countries.filter((c) => c.countryCode === region);

  if (0 === countriesWithSameCountryCode.length) {
    return getDefaultCountry(countries);
  }

  return (
    countriesWithSameCountryCode.find((c) => c.languageCode === language) ||
    getDefaultCountry(countriesWithSameCountryCode)
  );
}
