import { nanoid } from 'nanoid';
import React, { useMemo } from 'react';

const SpinnerIconTwo = ({ className }: { className?: string }) => {
  const maskId = useMemo(() => nanoid(), []);
  return (
    <svg
      className={className}
      fill="none"
      height="15"
      viewBox="0 0 14 15"
      width="14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask height="15" id={`mask${maskId}`} style={{ maskType: 'alpha' }} width="14" x="0" y="0">
        <path
          d="M13.1758 7.50069C13.1758 10.912 10.4106 13.6772 6.99931 13.6772C3.58805 13.6772 0.82284 10.912 0.82284 7.50069C0.82284 4.08942 3.58805 1.32422 6.99931 1.32422"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.823529"
        />
      </mask>
      <g mask={`url(#mask${maskId})`}>
        <rect fill="#7C7D86" height="16.4706" width="16.4706" x="-1.64648" y="-0.324219" />
      </g>
    </svg>
  );
};

export default SpinnerIconTwo;
