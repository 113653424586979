import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import apiClient from '@/apis/semji/apiClient';
import { LOCATION, SCOPE_SERVICE_INTEGRATIONS } from '@/apis/semji/constants';
import useApiConfigurations from '@/hooks/useApiConfigurations';
import { FETCH_SERVICE_INTEGRATIONS } from '@/store/actions/actionTypes';
import { showErrorSnackbar } from '@/store/actions/ui';
import { RECURSIVE_CALLS_REFETCH_FREQUENCY_MS } from '@/utils/configurations/constants';
import { SERVICES_LIST, TYPE_ANALYTICS, TYPE_SEARCH_CONSOLE } from '@/utils/constants';

function getAvailableIntegrations(serviceIntegrations) {
  return serviceIntegrations.reduce(
    (acc, serviceIntegration) => ({
      ...acc,
      disabledApis: serviceIntegration.disabledAt
        ? [...acc.disabledApis, serviceIntegration.name]
        : [...acc.disabledApis],
      hasAnalytics:
        acc.hasAnalytics ||
        Object.values(SERVICES_LIST).find(
          (service) =>
            service.values.includes(serviceIntegration.name) && service.type === TYPE_ANALYTICS
        ),
      hasSearchConsole:
        acc.hasSearchConsole ||
        Object.values(SERVICES_LIST).find(
          (service) =>
            service.values.includes(serviceIntegration.name) && service.type === TYPE_SEARCH_CONSOLE
        ),
    }),
    { disabledApis: [], hasAnalytics: false, hasSearchConsole: false }
  );
}

export default function useGetServiceIntegrations() {
  const DELAY = parseInt(useApiConfigurations(RECURSIVE_CALLS_REFETCH_FREQUENCY_MS), 10);
  const { t } = useTranslation();
  const { get } = apiClient;
  const dispatch = useDispatch();
  const { workspaceId } = useParams();
  const userId = useSelector((state) => state.user?.id || null);

  return useQuery({
    onError: () => {
      dispatch(showErrorSnackbar(t('common:error.default')));
    },
    onSuccess: (serviceIntegrations) => {
      dispatch({
        serviceIntegrations,
        type: FETCH_SERVICE_INTEGRATIONS,
        ...getAvailableIntegrations(serviceIntegrations),
      });
    },

    placeholderData: [],
    queryFn: async ({ signal }) => {
      if (userId && workspaceId) {
        const result =
          (
            await get(`/workspaces/${workspaceId}/service_integrations`, {
              signal,
            })
          ).data['hydra:member'] || [];

        return result.reduce(
          (acc, curr) => [
            ...acc,
            curr.data.custom_goals
              ? {
                  ...curr,
                  data: {
                    ...curr.data,
                    custom_goals: Object.fromEntries(
                      Object.entries(curr.data.custom_goals).filter((v) => v[1])
                    ), // remove empty value from object
                  },
                }
              : curr,
          ],
          []
        );
      }
      return [];
    },
    queryKey: [SCOPE_SERVICE_INTEGRATIONS.GET_SERVICE_INTEGRATIONS, workspaceId, LOCATION.ROOT],
    refetchInterval: DELAY,
    refetchOnWindowFocus: 'always',
  });
}
