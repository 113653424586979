import React from 'react';

import SvgWrapper from './SvgWrapper';

const TagIcon = (props) => (
  <SvgWrapper {...props}>
    <path d="m 14.001071,0.66792945 c 0,0 -5.5169779,-1.46975714 -11.0021863,0 C 2.7254715,0.83486741 2.5614224,1.1130991 2.5614224,1.411203 v 14.678668 c 0,0.717437 0.8836746,1.142732 1.5376815,0.739298 l 3.8300062,-2.418621 c 0.1727775,-0.105331 0.3718446,-0.157002 0.5708911,-0.155015 0.199044,-0.004 0.3980895,0.04767 0.5708885,0.155015 l 3.8300073,2.418621 c 0.653986,0.403434 1.537681,-0.02188 1.537681,-0.739298 V 1.411203 C 14.438534,1.1130991 14.274487,0.83288127 14.001071,0.66792945 Z" />
  </SvgWrapper>
);
export default TagIcon;
