import './AtomicContentButton.scss';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ClickableTooltip } from 'semji-core/components/ClickableTooltip';
import { InfoLabel } from 'semji-core/components/InfoLabel';
import { Tooltip } from 'semji-core/components/Tooltip';
import { Placements } from 'semji-core/hooks/usePopover';
import { BrandVoiceCreditIcon } from 'semji-core/icons/BrandVoiceCreditIcon';
import styled from 'styled-components/macro';

import StartAtomicContentDialog from '@/components/Dialog/StartAtomicContentDialog';
import { AtomicContentButtonProps } from '@/components/Editor/AtomicContentButton/AtomicContentButton.types';
import AtomicContentCreditIcon from '@/components/icons/AtomicContentCreditIcon';
import FactCheckingCreditIcon from '@/components/icons/FactCheckingCreditIcon';
import { ATOMIC_CONTENT_TYPE } from '@/containers/Content/EditorComponents/Ai/constants';
import { ID_CONTENT } from '@/containers/Content/TinyMceComponents/Editor/constants';
import {
  StyledSpinnerIcon,
  StyledStarIcon,
} from '@/containers/Content/TinyMceComponents/Editor/Editor.styled';
import useAiWritingCredit from '@/containers/Content/TinyMceComponents/Editor/hooks/useAiWritingCredit';
import useApiConfigurations from '@/hooks/useApiConfigurations';
import useOrganizationFeatureSet from '@/hooks/useOrganizationFeatureSet';
import useParamSelector from '@/store/hooks/useParamSelector';
import { selectBrandVoiceByWorkspaceId } from '@/store/selectors/selectBrandVoiceByWorkspaceId';
import { selectCurrentWorkspaceAccessByKey } from '@/store/selectors/selectWorkspaceAccessByKey';
import defaultTheme from '@/themes/defaultTheme';
import {
  AI_WRITING_BRAND_VOICE_CREDITS_ENABLED,
  CONTENT_AI_WRITING_CREDITS_ENABLED,
} from '@/utils/configurations/constants';

const DropdownWrapper = styled.div`
  font-size: 13px;
  line-height: 17px;
  padding: 12px 16px;
  width: 225px;
  background-color: ${({ theme }: any) => theme.cssColors.white};
  color: ${({ theme }: any) => theme.cssColors.dark100};
  box-shadow: ${({ theme }: any) => theme.boxShadow.modal};
  border-radius: 3px;

  span {
    display: inline;
    color: ${({ theme }: any) => theme.cssColors.primary};
    cursor: pointer;
  }
`;

function AtomicContentButton({
  isAtomicContentGenerating,
  factCheckingEnabled,
  handleAtomicContent,
  editorRef,
  inputTitleRef,
  textareaMetaDescriptionRef,
  hasUnlimitedAIWriting,
  remainingAiWritingUnit,
  setShowLimitAiWriting,
  setShowPermissionModal,
}: AtomicContentButtonProps): React.JSX.Element {
  const { t } = useTranslation();
  const [showAtomicContentDialog, setShowAtomicContentDialog] = useState<boolean>(false);
  const { workspaceId } = useParams();
  const { isFeatureEnabled: isFullDraftEnabled } = useOrganizationFeatureSet(
    'ai-writing:full-draft-is-enabled'
  );
  const aiWritingCreditsEnabled = useApiConfigurations(CONTENT_AI_WRITING_CREDITS_ENABLED);
  const aiWritingBrandVoiceCreditsEnabled = useApiConfigurations(
    AI_WRITING_BRAND_VOICE_CREDITS_ENABLED
  );

  const brandVoiceSelected = useParamSelector(selectBrandVoiceByWorkspaceId, workspaceId);

  const { getAiWritingCredit } = useAiWritingCredit();
  const atomicContentCredits = getAiWritingCredit(ATOMIC_CONTENT_TYPE);
  const accessToAiFeatures = useParamSelector(selectCurrentWorkspaceAccessByKey, {
    key: 'accessToAiFeatures',
    workspaceId: workspaceId,
  });

  function handleNavigate() {
    window.open(t('content:editor-components.toolbar.atomic-content.tooltip-locked-url'), '_blank');
  }

  function handleOpenAtomicContentDialog(): void {
    if (!accessToAiFeatures) {
      setShowPermissionModal(true);
      return;
    }
    const editorElement = document.getElementById(ID_CONTENT);
    const childNodes = editorElement?.childNodes ? Array.from(editorElement.childNodes) : [];
    if (
      aiWritingCreditsEnabled &&
      !hasUnlimitedAIWriting &&
      remainingAiWritingUnit < atomicContentCredits
    ) {
      setShowLimitAiWriting(true);
      if (inputTitleRef?.current) inputTitleRef.current.disabled = false;
      if (textareaMetaDescriptionRef?.current) textareaMetaDescriptionRef.current.disabled = false;
    } else {
      if (
        !isAtomicContentGenerating &&
        isFullDraftEnabled &&
        !editorRef.current?.mode?.isReadOnly() &&
        (childNodes?.length > 1 || childNodes[0].firstChild?.tagName !== 'BR')
      ) {
        setShowAtomicContentDialog(true);
      } else if (
        !isAtomicContentGenerating &&
        isFullDraftEnabled &&
        !editorRef.current?.mode?.isReadOnly()
      ) {
        handleAtomicContent();
      }
    }
  }

  function handleCloseAtomicContentDialog(): void {
    setShowAtomicContentDialog(false);
  }

  function handleStartAtomicContent() {
    setShowAtomicContentDialog(false);
    handleAtomicContent();
  }

  return (
    <>
      <div className="atomic-content-button">
        <Tooltip
          description={
            <div className="atomic-content-button__tooltip__desc">
              <div>{t('content:editor-components.toolbar.atomic-content.tooltip')}</div>
              {!hasUnlimitedAIWriting && aiWritingCreditsEnabled && (
                <>
                  <div className="atomic-content-button__tooltip__desc__credits">
                    <AtomicContentCreditIcon />
                    {factCheckingEnabled && <FactCheckingCreditIcon />}
                    {brandVoiceSelected && aiWritingBrandVoiceCreditsEnabled && (
                      <BrandVoiceCreditIcon />
                    )}
                    <div>
                      <span className="atomic-content-button__tooltip__desc__credits__title">
                        {t('content:ai-writing.ai-writing-credits-needed', {
                          count: atomicContentCredits,
                          postProcess: 'interval',
                        })}
                      </span>{' '}
                      <span className="atomic-content-button__tooltip__desc__credits__count">
                        (
                        {t('content:ai-writing.ai-writing-credits-remaining.key_interval', {
                          count: remainingAiWritingUnit,
                          postProcess: 'interval',
                        })}
                        )
                      </span>
                    </div>
                  </div>
                </>
              )}
            </div>
          }
          hide={!isFullDraftEnabled}
          isFrame={false}
          popoverOptions={{ placement: 'bottom' }}
          title={
            <div className="atomic-content-button__tooltip__title">
              <span>{t('components:dialog.atomic-content.atomic-content')}</span>
              <InfoLabel variant="beta">{t(`common:labels.beta`)}</InfoLabel>
            </div>
          }
          tooltipClassName="atomic-content-button__tooltip"
        >
          <div
            className={`flex items-center rounded my-1 p-[0.4rem] ${
              isFullDraftEnabled && !isAtomicContentGenerating
                ? 'hover:bg-primary-010 cursor-pointer'
                : 'cursor-not-allowed'
            }`}
            data-intercom-target="atomic-content"
            onClick={handleOpenAtomicContentDialog}
          >
            {isAtomicContentGenerating ? (
              <StyledSpinnerIcon
                className={'animate-spin'}
                fill="none"
                fontSize="11px"
                height="11"
                viewBox="0 0 11 11"
                width="11"
              />
            ) : (
              <StyledStarIcon
                color={
                  isFullDraftEnabled && !isAtomicContentGenerating
                    ? defaultTheme.cssColors.primary
                    : defaultTheme.cssColors.dark040
                }
                fill="none"
                height="11"
                viewBox="0 0 11 11"
                width="11"
              />
            )}
            <ClickableTooltip
              anchorElement={
                <span
                  className={`text-[14px] font-medium leading-none whitespace-nowrap ${
                    isFullDraftEnabled && !isAtomicContentGenerating
                      ? 'text-primary'
                      : 'text-dark-030'
                  }`}
                >
                  {t('components:dialog.atomic-content.atomic-content')}
                </span>
              }
              disabled={isFullDraftEnabled}
              isFrame={false}
              popoverOptions={{ offset: 12, placement: Placements.Bottom }}
            >
              <DropdownWrapper>
                <div className="font-medium">
                  {t('components:dialog.atomic-content.atomic-content')}
                </div>
                <div>{t('content:editor-components.toolbar.atomic-content.tooltip-locked')} </div>
                <div onClick={handleNavigate}>
                  <span>
                    {t('content:editor-components.toolbar.atomic-content.tooltip-learn-more')}
                  </span>
                </div>
              </DropdownWrapper>
            </ClickableTooltip>
          </div>
        </Tooltip>
      </div>
      <StartAtomicContentDialog
        show={showAtomicContentDialog}
        onClose={handleCloseAtomicContentDialog}
        onStart={handleStartAtomicContent}
      />
    </>
  );
}

export default AtomicContentButton;
