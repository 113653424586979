import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Handle, Position } from 'reactflow';
import { useHover } from 'semji-core/hooks/useHover';

import { FlexTooltipComponent } from '@/components/Tooltip/Tooltip';
import {
  MAX_EXPANDED_NODES,
  ORIENTATION_VERTICAL,
} from '@/containers/ContentIdeas/Listing/MindMap/constant';
import {
  Cluster,
  ClusterWrapper,
  StyledHandle,
  StyledMinusIcon,
  StyledPlusIcon,
} from '@/containers/ContentIdeas/Listing/MindMap/Nodes/Cluster/ClusterNode.styled';

function ClusterNode({ data }) {
  const { t } = useTranslation();
  const [query, setQuery] = useSearchParams();
  const { nodes, setNodes } = data;
  const [hoverRef, isHovered, setIsHovered] = useHover();

  const clusterId = query.get('clusterId');

  function handleShow() {
    setIsHovered(false);

    const parentIndex = nodes.findIndex((node) => node.id === data.id);

    if (!nodes[parentIndex].data.expanded) {
      nodes[parentIndex].data.expanded = MAX_EXPANDED_NODES;
    } else {
      nodes[parentIndex].data.expanded = 0;
    }

    setNodes([...nodes]);
  }

  function handleClick(e) {
    e.stopPropagation();

    if (!data.expanded) {
      query.set('clusterId', data.id);
    } else if (data.id === clusterId) {
      query.set('clusterId', null);
    }
    setQuery(query);

    handleShow();
  }

  return (
    <ClusterWrapper
      ref={hoverRef}
      backgroundColorWithoutTheme={data.color}
      borderRadius="24px"
      opacity={data.blurred ? 0.3 : 1}
      padding="12px 24px"
      onClick={handleClick}
    >
      <Handle
        position={data.orientation === ORIENTATION_VERTICAL ? Position.Top : Position.Left}
        style={{ visibility: 'hidden' }}
        type="target"
      />
      <Cluster>{data.name}</Cluster>
      <StyledHandle
        color={data.color}
        position={data.orientation === ORIENTATION_VERTICAL ? Position.Bottom : Position.Right}
        type="source"
      >
        {data.expanded ? (
          <FlexTooltipComponent
            hide={!isHovered}
            placement="top"
            title={t('content-ideas:mind-map.hide-content-ideas')}
            onClick={handleClick}
          >
            <StyledMinusIcon color={data.color} />
          </FlexTooltipComponent>
        ) : (
          <FlexTooltipComponent
            hide={!isHovered}
            placement="top"
            title={t('content-ideas:mind-map.show-content-ideas')}
            onClick={handleClick}
          >
            <StyledPlusIcon color={data.color} />
          </FlexTooltipComponent>
        )}
      </StyledHandle>
    </ClusterWrapper>
  );
}

export default ClusterNode;
