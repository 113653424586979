export const CLIENT_ID = import.meta.env.VITE_REACT_APP_GOOGLE_APP_CLIENT_ID;

export const GOOGLE_ANALYTICS_API_ROOT_URL =
  import.meta.env.VITE_REACT_APP_GOOGLE_ANALYTICS_API_ROOT_URL.replace(/\/$/, '');

export const GOOGLE_API_ROOT_URL = import.meta.env.VITE_REACT_APP_GOOGLE_API_ROOT_URL.replace(
  /\/$/,
  ''
);

export const GOOGLE_AUTH_ROOT_URL = import.meta.env.VITE_REACT_APP_GOOGLE_AUTH_ROOT_URL.replace(
  /\/$/,
  ''
);

export const LOGIN_BUTTON_CLIENT_ID = import.meta.env.VITE_REACT_APP_GOOGLE_LOGIN_BUTTON_CLIENT_ID;
