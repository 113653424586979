import styled from 'styled-components/macro';

import Row from '@/containers/Content/BriefComponents/Table/Rows/Row';

const WarningRow = styled(Row)`
  && {
    background: ${({ theme }) => theme.cssColors.primary010};
  }
`;

export default WarningRow;
