export const CLUSTERS_COLORS = [
  { backgroundColor: '#EB6B3E1A', color: '#E85521', fontColor: '' },
  { backgroundColor: '#EF90391A', color: '#B36B0E', fontColor: '' },
  { backgroundColor: '#EF90391A', color: '#B36B0E', fontColor: '' },
  { backgroundColor: '#EF90391A', color: '#B36B0E', fontColor: '' },
  { backgroundColor: '#80CF7A1A', color: '#35832F', fontColor: '' },
  { backgroundColor: '#9CCD4D1A', color: '#6E9333', fontColor: '' },
  { backgroundColor: '#51a8E41A', color: '#1D79B9', fontColor: '' },
  { backgroundColor: '#A065DC1A', color: '#8C44D5', fontColor: '' },
  { backgroundColor: '#D36ADD1A', color: '#C844D4', fontColor: '' },
  { backgroundColor: '#EE96AD26', color: '#E03E68', fontColor: '' },
  { backgroundColor: '#91A2A526', color: '#5E7073', fontColor: '' },
  { backgroundColor: '#48E6FF1A', color: '#0698AF', fontColor: '' },
  { backgroundColor: '#E7C5421F', color: '#957A13', fontColor: '' },
  { backgroundColor: '#477BE01F', color: '#30549A', fontColor: '' },
  { backgroundColor: '#FCB7051A', color: '#E98F09', fontColor: '' },
  { backgroundColor: '#9E97FA1A', color: '#5246E1', fontColor: '' },
  { backgroundColor: '#3FE1461A', color: '#27A02C', fontColor: '' },
];
