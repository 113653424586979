import { getRoundedNumberMetricWithSuffix } from 'semji-core/utils/numbers';

import defaultTheme from '@/themes/defaultTheme';

export const DEFAULT_CHART_OPTIONS = {
  chart: {
    // use to degug
    // borderWidth: 1,
    // plotBorderWidth: 1,
    spacing: [0, 10, 0, 2],
  },
  credits: {
    enabled: false,
  },
  noData: {
    style: {
      color: defaultTheme.cssColors.dark080,
      fontFamily: defaultTheme.textCss.fonts.main,
      fontSize: '16px',
      fontWeight: '300',
    },
  },
  style: {
    fontFamily: defaultTheme.textCss.fonts.main,
    overflow: 'visible',
  },
  subtitle: {
    align: 'left',
    text: undefined,
  },
  title: {
    align: 'left',
    text: undefined,
  },
};

export const EXPORTING_CHART_OPTIONS = {
  allowHTML: true,
  legend: {
    align: 'left',
    itemDistance: 20,
    itemMarginBottom: 0,
    itemMarginTop: 0,
    labelFormatter: function () {
      return this.visible
        ? `<span style="color: ${this.color}; font-size:25px;"> - </span>${this.name}`
        : null;
    },
    margin: 32,
    useHTML: true,
    verticalAlign: 'top',
  },
  plotOptions: {
    series: {
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '6px',
          fontWeight: defaultTheme.textCss.weights.bold,
          textOutline: false,
        },
      },
    },
  },
  subtitle: null,
};

export const DEFAULT_SERIE_OPTIONS = {
  point: {
    events: {
      mouseOut() {
        let series = this.series;

        series.points.forEach((p) => {
          p.graphic?.css({
            opacity: 1,
          });
        });
      },
      mouseOver() {
        let series = this.series;

        series.points.forEach((p) => {
          p.graphic.css({
            opacity: 0.3,
          });
        });

        this.graphic?.css({
          opacity: 1,
        });
      },
    },
  },
};

export const DEFAULT_YAXIS_OPTIONS = {
  gridLineDashStyle: 'dash',
  softMin: 0,
  title: {
    text: '',
  },
};

export const DEFAULT_YAXIS_LABEL_OPTIONS = {
  formatter: function () {
    return getRoundedNumberMetricWithSuffix({ number: this.value });
  },
  style: {
    color: defaultTheme.cssColors.dark060,
    fontFamily: 'Rubik',
    fontSize: '13px',
    fontWeight: '300',
  },
};

export const DEFAULT_XAXIS_LABEL_OPTIONS = {
  align: 'center',
  style: {
    color: defaultTheme.cssColors.dark080,
    fontFamily: defaultTheme.textCss.fonts.main,
    fontSize: defaultTheme.textCss.sizes.xsm,
    fontWeight: defaultTheme.textCss.weights.medium,
    textAlign: 'center',
  },
  useHTML: true,
};

export const DEFAULT_XAXIS_OPTIONS = {
  accessibility: {
    description: '',
  },
  lineWidth: 0,
};

export const DEFAULT_LEGEND_OPTIONS = {
  align: 'left',
  itemDistance: 20,
  itemHiddenStyle: {
    textDecoration: 'none',
  },
  itemMarginBottom: 0,
  itemMarginTop: 0,
  margin: 32,
  padding: 0,
  squareSymbol: false,
  symbolRadius: 0,
  symbolWidth: 0,
  useHTML: true,
  verticalAlign: 'top',
  x: -8,
};

export const DEFAULT_SERIE_STACKED_OPTIONS = {
  point: {
    events: {
      mouseOut() {
        let series = this.series;

        series.points.forEach((p) => {
          p.graphic.css({
            opacity: 1,
          });
        });
      },
      mouseOver() {
        let series = this.series;

        series.points.forEach((p) => {
          p.graphic.css({
            opacity: 1,
          });
        });

        this.graphic.css({
          opacity: 1,
        });
      },
    },
  },
};

const CONTAINER_CHART_ID_PREFIX = 'chart-container';
export const getContainerId = (id: string) => `${CONTAINER_CHART_ID_PREFIX}-${id}`;

export const CHART_TYPE_BAR = 'bar';
export const CHART_TYPE_COLUMN = 'column';
export const CHART_TYPE_LINE = 'line';

export type ChartType = typeof CHART_TYPE_BAR | typeof CHART_TYPE_COLUMN | typeof CHART_TYPE_LINE;
