import {
  CHANGE_METRICS_CONFIG_CURRENT_COMPARISON_PERIOD,
  CHANGE_METRICS_CONFIG_PERIOD,
  CHANGE_METRICS_CONFIG_PERIODICITY,
} from './actionTypes';

export const changePeriodicity = (periodicity) => ({
  periodicity,
  type: CHANGE_METRICS_CONFIG_PERIODICITY,
});

export const changePeriod = (period) => ({
  period,
  type: CHANGE_METRICS_CONFIG_PERIOD,
});

export const changeComparisonPeriod = (comparisonPeriod) => ({
  comparisonPeriod,
  type: CHANGE_METRICS_CONFIG_CURRENT_COMPARISON_PERIOD,
});
