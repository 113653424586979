import React, { useId } from 'react';

function OutlineCreditIcon(props) {
  const id = useId();
  return (
    <svg fill="none" height="15" viewBox="0 0 15 15" width="15" {...props}>
      <path
        d="M15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0C11.6421 0 15 3.35786 15 7.5Z"
        fill={`url(#linear0_${id})`}
      />
      <circle cx="7.5" cy="7.5" r="7.19444" stroke={`url(#linear1_${id})`} strokeWidth="0.611111" />
      <g clipPath={`url(#clip_${id})`}>
        <path
          d="M4.96159 5.84999C4.96159 6.23265 4.65018 6.54405 4.26752 6.54405C3.88486 6.54405 3.57346 6.23265 3.57346 5.84999C3.57346 5.46732 3.88486 5.15592 4.26752 5.15592C4.65018 5.15592 4.96159 5.46732 4.96159 5.84999ZM4.96159 7.70083C4.96159 8.08349 4.65018 8.3949 4.26752 8.3949C3.88486 8.3949 3.57346 8.08349 3.57346 7.70083C3.57346 7.31817 3.88486 7.00676 4.26752 7.00676C4.65018 7.00676 4.96159 7.31817 4.96159 7.70083ZM4.96159 9.55167C4.96159 9.93434 4.65018 10.2457 4.26752 10.2457C3.88486 10.2457 3.57346 9.93434 3.57346 9.55167C3.57346 9.16901 3.88486 8.85761 4.26752 8.85761C4.65018 8.85761 4.96159 9.16901 4.96159 9.55167Z"
          fill="white"
        />
        <path d="M11.4395 6.08134V5.61863H5.88701V6.08134H11.4395Z" fill="white" />
        <path d="M11.4395 7.93219H5.88701V7.46947H11.4395V7.93219Z" fill="white" />
        <path d="M11.4395 9.78303H5.88701V9.32032H11.4395V9.78303Z" fill="white" />
        <path
          d="M11.4395 6.08134V5.61863H5.88701V6.08134H11.4395Z"
          stroke="white"
          strokeWidth="0.561863"
        />
        <path
          d="M11.4395 7.93219H5.88701V7.46947H11.4395V7.93219Z"
          stroke="white"
          strokeWidth="0.561863"
        />
        <path
          d="M11.4395 9.78303H5.88701V9.32032H11.4395V9.78303Z"
          stroke="white"
          strokeWidth="0.561863"
        />
      </g>
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id={`linear0_${id}`}
          x1="3.02908e-05"
          x2="15.0027"
          y1="7.50423"
          y2="7.50423"
        >
          <stop stopColor="#FF4580" />
          <stop offset="1" stopColor="#FF4D64" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id={`linear1_${id}`}
          x1="13.4425"
          x2="1.98413"
          y1="2.57962"
          y2="12.5102"
        >
          <stop stopColor="#CA3B4E" />
          <stop offset="1" stopColor="#CA3B4E" stopOpacity="0" />
        </linearGradient>
        <clipPath id={`clip_${id}`}>
          <rect
            fill="white"
            height="7.86609"
            transform="translate(3.57346 3.76779)"
            width="7.86609"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default OutlineCreditIcon;
