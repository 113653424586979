import styled from 'styled-components/macro';

import Flex from '@/design-system/components/Flex';

export const ThreadWrapper = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.cssColors.dark005};

  :hover {
    background-color: ${({ theme }) => theme.cssColors.dark002};
  }

  && {
    background-color: ${({ theme, isFocus }) => isFocus && theme.cssColors.primary005};
  }
`;

export const ThreadQuote = styled.span`
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  padding: 0.1em 0;
  max-height: 50px;
  overflow: hidden;
  opacity: ${({ isThreadResolved }) => (isThreadResolved ? 0.5 : 1)};
  border-left: 2px solid #fee97a;
  font-style: italic;
  font-size: 13px;
  line-height: ${({ theme }) => theme.textCss.lineHeight};
  color: ${({ theme }) => theme.cssColors.dark040};
  padding-left: 12px;
`;
