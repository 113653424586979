import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import TextField from '@material-ui/core/TextField';
import uniqBy from 'lodash/uniqBy';
import { lighten } from 'polished';
import { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { getDecimalPlaces } from 'semji-core/utils/numbers';
import styled from 'styled-components/macro';

import { LOCATION } from '@/apis/semji/constants';
import useGetCurrentWorkspaceFocusTopKeywordsWithSpecificProperties from '@/apis/semji/topKeywords/useGetCurrentWorkspaceFocusTopKeywordsWithSpecificProperties';
import { PrimaryButton } from '@/components/Button/Button';
import FocusTopKeywordAssociation from '@/components/FocusTopKeyword/FocusTopKeywordAssociation';
import AngleLeft from '@/components/icons/AngleLeft';
import SearchIcon from '@/components/icons/SearchIcon';
import FlatLoader, { LoaderWrapper } from '@/components/Loader/FlatLoader';
import Tabs, { Tab } from '@/components/Navigation/Tabs';
import NumberCell from '@/components/Table/Cell/NumberCell';
import { DarkText } from '@/components/Text/Dark';
import Ellipsis from '@/components/Text/Ellipsis';
import { Info } from '@/components/Text/Info';
import { BrightText, LightText } from '@/components/Text/Light';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import Flex from '@/design-system/components/Flex';
import useApiConfigurations from '@/hooks/useApiConfigurations';
import { LISTING_REFETCH_FREQUENCY_MS } from '@/utils/configurations/constants';
import { METRIC_TYPE_NUMBER } from '@/utils/metrics/constants';

const DEFAULT_ROWS_IN_LOADING = 3;
const DEFAULT_SCROLLBAR_SIZE = 15;
const InputField = styled(({ pxSize, ...props }) => <TextField {...props} />)`
  && {
    width: ${(props) => props.pxSize}px;
    max-width: 700px;
    margin-bottom: ${({ error }) => (error ? '-24px' : '0px')};
    & textarea {
      white-space: nowrap;
    }
  }
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-width: 0;
  flex: 1;
  width: ${(props) => props.pxSize}px;
  padding: 15px 0;
  margin-top: 8px;
  box-shadow:
    0 0px 5px -3px rgba(0, 0, 0, 0.1),
    0px 3px 14px 2px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  background: white;
  max-height: 300px;
`;
const BodyWrapper = styled.div`
  width: 100%;
  flex: 1;
  overflow-y: scroll;
  border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
`;
const TitleWrapper = styled.div`
  width: 100%;
`;
const Title = styled(BrightText)`
  display: flex;
  align-items: center;
  font-size: 0.85em;
  font-weight: 500;
  padding: 0em 1.5em 1em 1.5em;
  display: block;
`;
const ClickableTitle = styled(BrightText)`
  display: flex;
  align-items: center;
  font-size: 0.85em;
  font-weight: 500;
  padding: ${(props) => `0em ${props.paddingRight + 10}px 1em 0.8em`};
  cursor: pointer;
  justify-content: space-between;
`;
const Suggest = styled.div`
  padding: 0.6em 0.5em 0.6em 1.5em;
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.space ? 'space-between' : props.disabled ? 'center' : 'flex-start'};
  cursor: pointer;
  font-size: ${(props) => (props.disabled ? '1.1em' : 'inherit')};
  border-left: 3px solid;
  border-left-color: ${(props) =>
    props.selected ? `${props.theme.colors.secondary}` : 'transparent'};
  background-color: ${(props) => props.selected && lighten(0.35, props.theme.colors.secondary)};
  &:hover {
    background-color: ${(props) => !props.disabled && lighten(0.35, props.theme.colors.secondary)};
  }
`;
const StyledSearchIcon = styled(SearchIcon)`
  color: ${(props) => props.theme.colors.grey};
  padding-right: 0.5em;
`;
const StyledAngleLeft = styled(AngleLeft)`
  color: ${(props) => props.theme.colors.grey};
`;
const StyledDarkText = styled(DarkText)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.85em;
  padding-bottom: 0.1em;
`;
const StyledLightText = styled(LightText)`
  font-size: 0.8em;
  white-space: nowrap;
`;
const TitleFlexWrapper = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
  border-bottom: ${(props) => props.withBorders && `1px solid ${props.theme.colors.lightGrey}`};
`;
const StyledButton = styled(PrimaryButton)`
  margin-top: 1em;
  margin-bottom: 0.2em;
  width: 75%;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export default function KeywordSuggest({
  suggestions,
  questions,
  getSuggestionForKeyword,
  workspaceCountryName,
  lastKnownSearch,
  setLastKnownSearch,
  rowLastKnownSearch,
  openedTab,
  setOpenedTab,
  value,
  ...props
}) {
  const { t } = useTranslation();

  const [currentKeyword, setCurrentKeyword] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [error, setError] = useState(null);
  const [displayHistory, setDisplayHistory] = useState(false);
  const scrollableBodyRef = useRef(null);
  const REFETCH_INTERVAL_IN_MS = parseInt(useApiConfigurations(LISTING_REFETCH_FREQUENCY_MS), 10);

  const { data: focusTopKeywordsWithSpecificProperties } =
    useGetCurrentWorkspaceFocusTopKeywordsWithSpecificProperties({
      filters: { inStock: false },
      location: LOCATION.PLANNING,
      properties: ['id', 'keyword', 'associatedToPagesCount', 'associatedToDraftsCount'],
      refetchInterval: REFETCH_INTERVAL_IN_MS,
      refetchOnWindowFocus: 'always',
    });

  const existingFocusKeyword = focusTopKeywordsWithSpecificProperties?.find(
    (keyword) => keyword?.keyword === value.toLowerCase()
  );

  const getListKeywords = () => {
    return uniqBy([...suggestions, ...questions], 'keyword');
  };

  const getLastKnownSearch = () => {
    return rowLastKnownSearch && !!value ? rowLastKnownSearch : lastKnownSearch;
  };

  const getListSuggestionsByKeyword = (keyword) => {
    const suggestedKeywords =
      suggestions.find((suggestion) => suggestion.keyword === keyword) || {};
    const suggestedQuestions = questions.find((question) => question.keyword === keyword) || {};

    if (keyword && openedTab === 0) {
      return {
        list: [
          ...(suggestedKeywords.relatedKeywordsSuggested || []),
          ...(suggestedQuestions.relatedKeywordsSuggested || []),
        ],
        loading: suggestedKeywords.loading && suggestedQuestions.loading,
      };
    }

    if (keyword && openedTab === 1) {
      return {
        list: [...(suggestedQuestions.relatedKeywordsSuggested || [])],
        loading: suggestedQuestions.loading,
      };
    }

    return {
      list: [],
      loading: false,
    };
  };

  function isDisabled() {
    const { loading, list } = getListSuggestionsByKeyword(value);
    return loading || !value || list.length > 0;
  }

  function getButtonLabel() {
    const { loading } = getListSuggestionsByKeyword(value);
    if (loading) {
      return t('components:autocomplete.keyword-suggest.button-label-loading');
    }
    if (isDisabled()) {
      return t('components:autocomplete.keyword-suggest.button-label-disabled');
    }

    return t('components:autocomplete.keyword-suggest.button-label', { value: value });
  }

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  async function search() {
    setError(null);
    try {
      setCurrentKeyword(value);
      setLastKnownSearch(value);
      await getSuggestionForKeyword(value);
    } catch (e) {
      if (e.status === 403) {
        setError(t('components:autocomplete.keyword-suggest.error-403'));
      } else {
        setError(t('components:autocomplete.keyword-suggest.error'));
      }
    }
  }

  const renderTitle = () => {
    if (!displayHistory && (currentKeyword || getLastKnownSearch())) {
      return (
        <ClickableTitle
          paddingRight={
            scrollableBodyRef.current
              ? scrollableBodyRef.current.offsetWidth - scrollableBodyRef.current.clientWidth
              : DEFAULT_SCROLLBAR_SIZE
          }
          onClick={() => {
            setCurrentKeyword('');
            setDisplayHistory(true);
          }}
        >
          <TitleFlexWrapper>
            <StyledAngleLeft />
            <Ellipsis>
              <Trans
                components={{ strong: <strong /> }}
                i18nKey={'components:autocomplete.keyword-suggest.title-suggestions'}
                values={{ value: currentKeyword || getLastKnownSearch() }}
              />
            </Ellipsis>
          </TitleFlexWrapper>
        </ClickableTitle>
      );
    }

    if (getListKeywords().length === 0) {
      return <Title>{t('components:autocomplete.keyword-suggest.title')}</Title>;
    }

    return <Title>{t('components:autocomplete.keyword-suggest.title-recent-search')}</Title>;
  };

  const renderBody = () => {
    const currentKeywordValue = currentKeyword || getLastKnownSearch();

    if (!displayHistory && currentKeywordValue) {
      const { loading, list } = getListSuggestionsByKeyword(currentKeywordValue);
      const isCurrentTabLoading = loading && list.length === 0;
      const isCurrentTabEmpty = !loading && list.length === 0;
      const loadingRows = Array(DEFAULT_ROWS_IN_LOADING).fill('loader');

      return (
        <>
          <TitleFlexWrapper withBorders>
            <Tabs value={openedTab} onChange={(event, newValue) => setOpenedTab(newValue)}>
              {[
                t('components:autocomplete.keyword-suggest.tab-all'),
                t('components:autocomplete.keyword-suggest.tab-questions'),
              ].map((label, index) => (
                <Tab
                  key={label + index}
                  index={index}
                  label={label}
                  minWidth
                  noGrow
                  smallHeight
                  smallWidth
                />
              ))}
            </Tabs>
            <TooltipComponent
              description={t('components:autocomplete.keyword-suggest.tooltip-description', {
                workspaceCountryName,
              })}
              title={t('components:autocomplete.keyword-suggest.tooltip-title')}
            >
              <StyledSearchIcon />
            </TooltipComponent>
          </TitleFlexWrapper>
          {isCurrentTabLoading ? (
            loadingRows.map((loader, index) => (
              <Suggest key={loader + index} disabled>
                <LoaderWrapper height={15} width={280}>
                  <FlatLoader />
                </LoaderWrapper>
              </Suggest>
            ))
          ) : isCurrentTabEmpty ? (
            <Suggest disabled>
              <StyledDarkText>
                {t('components:autocomplete.keyword-suggest.message-no-keyword')}
              </StyledDarkText>
            </Suggest>
          ) : (
            list
              .sort((a, b) => b.volume - a.volume)
              .map((relatedKeyword) => {
                const decimalPlaces = getDecimalPlaces(relatedKeyword?.volume, METRIC_TYPE_NUMBER);
                return (
                  <Suggest
                    key={relatedKeyword['@id']}
                    selected={value === relatedKeyword.keyword}
                    space
                    onClick={() => {
                      props.onChange({ target: { value: relatedKeyword.keyword } }, true);
                      handleClose();
                    }}
                  >
                    <StyledDarkText
                      dangerouslySetInnerHTML={{ __html: relatedKeyword.formattedKeyword }}
                      title={relatedKeyword.keyword}
                    />
                    <StyledLightText>
                      <NumberCell
                        decimalPlaces={decimalPlaces}
                        min={10}
                        number={relatedKeyword.volume ? relatedKeyword.volume : 0}
                      />
                    </StyledLightText>
                  </Suggest>
                );
              })
          )}
        </>
      );
    }

    return getListKeywords().map((suggestion) => (
      <Suggest
        key={suggestion['@id']}
        onClick={() => {
          setLastKnownSearch(suggestion.keyword);
          setCurrentKeyword(suggestion.keyword);
          setDisplayHistory(false);
        }}
      >
        <StyledSearchIcon />
        <StyledDarkText title={suggestion.keyword}>{suggestion.keyword}</StyledDarkText>
      </Suggest>
    ));
  };

  const open = Boolean(anchorEl);
  const id = open ? 'keyword-suggest-popper' : undefined;

  const buttonLabel = getButtonLabel();

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <Flex alignItems="center" gap="12px" marginRight="20px" width="420px">
          <InputField
            {...props}
            aria-describedby={id}
            value={value}
            onFocus={(e) => {
              props.onFocus(e);
              handleClick(e);
              setDisplayHistory(false);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Tab') {
                handleClose();
              } else {
                props.onKeyDown(e);
              }
            }}
          />
          {existingFocusKeyword && (
            <FocusTopKeywordAssociation focusTopKeyword={existingFocusKeyword} isNew />
          )}
        </Flex>
        <Popper anchorEl={anchorEl} id={id} open={open} placement={'bottom'}>
          <Wrapper pxSize={props.pxSize}>
            <TitleWrapper>{renderTitle()}</TitleWrapper>
            <BodyWrapper ref={scrollableBodyRef}>{renderBody()}</BodyWrapper>
            <StyledButton disabled={isDisabled()} title={buttonLabel} onClick={search}>
              {buttonLabel}
            </StyledButton>
            {!!error && <Info>{error}</Info>}
          </Wrapper>
        </Popper>
      </div>
    </ClickAwayListener>
  );
}
