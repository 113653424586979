import { DateTime } from 'luxon';

export function getDiffInDaysFromNow(isoDate) {
  const date = DateTime.fromISO(isoDate);
  const today = DateTime.fromJSDate(new Date());
  const diff = date.diff(today, ['days']).toObject();
  return Math.round(diff.days);
}

export function getDiffInDaysFromDate(isoDateA, luxonDateB) {
  const dateA = DateTime.fromISO(isoDateA);
  const diff = dateA.diff(luxonDateB, ['days']).toObject();
  return Math.round(diff.days);
}

export function getDateRange(date, period, formatStart = 'dd MMM YYY', formatEnd = 'dd MMM YYY') {
  const { start, end } = getDateRangeObject(date, period);

  const formattedStartDate = start.toFormat(formatStart);
  const formattedEndDate = end.toFormat(formatEnd);

  return `${formattedStartDate}-${formattedEndDate}`;
}

export function getDateRangeObject(date, period) {
  const firstDayOfPeriod = date.startOf(period);
  const lastDayOfPeriod = date.endOf(period);

  return {
    end: lastDayOfPeriod,
    start: firstDayOfPeriod,
  };
}
