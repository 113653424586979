import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import reducers from '@/store/reducers';
import { checkCanDebug } from '@/utils/log/checkCanDebug';

let store = null;
const preloadedState = {};

// https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/API/Arguments.md
const reduxDevToolsOptions = checkCanDebug() ? { trace: true, traceLimit: 10 } : {};
const composeEnhancers = composeWithDevTools(reduxDevToolsOptions);

let middlewares = [thunk];

export default class Store {
  static get() {
    if (store === null) {
      Store.load();
    }

    return store;
  }

  static load() {
    store = createStore(
      reducers,
      preloadedState,
      composeEnhancers(applyMiddleware(...middlewares))
    );
  }
}
