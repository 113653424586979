import './Instruction.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { FlexColumnContainer, FlexGrowContainer } from '@/components/_common';
import { TertiaryButton } from '@/components/Button/Button';
import FavIcon from '@/components/FavIcon/FavIcon';
import BackRightIcon from '@/components/icons/BackRightIcon';
import CheckIcon from '@/components/icons/CheckIcon';
import CloseIcon from '@/components/icons/CloseIcon';
import CopyIcon from '@/components/icons/CopyIcon';
import PlusIcon from '@/components/icons/PlusIcon';
import { IconExternalLink, SecondaryLink } from '@/components/Navigation/Link';
import ProgressBar from '@/components/Rating/LinearProgressBar';
import { DarkerText } from '@/components/Text/Dark';
import { LightText } from '@/components/Text/Light';
import TooltipComponent from '@/components/Tooltip/Tooltip';

const Position = styled.span`
  box-sizing: border-box;
  display: flex;
  background: ${(props) =>
    props.isCurrentPage ? props.theme.colors.secondary : props.theme.colors.lightGreyOpaque};
  border-radius: 50%;
  color: ${(props) =>
    props.isCurrentPage ? props.theme.text.colors.white : props.theme.text.colors.default};
  font-size: ${(props) => props.theme.textCss.sizes.xxs};
  font-weight: 400;
  width: 18px;
  height: 18px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;

export const DisableableWrapper = styled.div`
  && {
    opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  }
`;

export const AddOptimizationButton = styled(PlusIcon)`
  padding: 1px 2px 2px 2px;
  margin-top: 1px;
  && {
    cursor: pointer;
    opacity: 1;
    font-size: 15px;
  }
`;

export const DisableOptimizationButton = styled(CloseIcon)`
  && {
    cursor: pointer;
    opacity: inherit;
    font-size: 0.7em;
  }
`;

export const EnableOptimizationButton = styled(BackRightIcon)`
  && {
    cursor: pointer;
    opacity: inherit;
    font-size: 0.7em;
    transition: opacity 400ms;
  }
`;

const Checked = styled(CheckIcon)`
  && {
    color: ${(props) => props.theme.colors.brightestGreyOpaque};
    background-color: ${(props) => props.theme.colors.green};
    font-size: 0.5em;
    display: flex;
    padding: 3px;
    border: 1px solid ${(props) => props.theme.colors.green};
  }
`;
export const UnChecked = styled(CheckIcon)`
  && {
    color: ${(props) => props.theme.colors.greyOpaque};
    background-color: ${(props) => props.theme.colors.brightestGreyOpaque};
    font-size: 0.5em;
    display: flex;
    padding: 3px;
    border: 1px solid ${(props) => props.theme.colors.greyOpaque};
  }
`;
const DisabledIcon = styled(CloseIcon)`
  && {
    color: ${(props) => props.theme.colors.brightestGreyOpaque};
    background-color: ${(props) => props.theme.colors.greyOpaque};
    font-size: 0.5em;
    display: flex;
    padding: 3px;
    border: 1px solid ${(props) => props.theme.colors.greyOpaque};
  }
`;
const ActionToggleIconWrapper = styled.div`
  font-size: 16px;
  border-radius: 4px;
  padding: 4px 4px;
  cursor: ${(props) => !props.empty && 'pointer'};
  opacity: 0;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #7c7d86;
  &:hover {
    background: ${(props) => !props.empty && '#F4F4F5'};
    color: #51525e;
  }
`;
const StyledText = styled(LightText)`
  line-height: 1.2em;
  && em {
    font-style: initial;
    font-weight: 500;
  }
`;
const ActionWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  opacity: 1;
  color: #7c7d86;
  &:hover {
    background: ${(props) => !props.empty && '#F4F4F5'};
    color: #51525e;
  }
`;

const InstructionWrapper = styled.div`
  padding: 6px 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  }
  &:hover {
    opacity: 1;
  }
  &:hover ${ActionToggleIconWrapper} {
    opacity: 1;
  }
  &:hover ${IconExternalLink} {
    display: inline;
  }
`;

const Wrapper = styled(FlexGrowContainer)`
  min-width: 0;
  padding: 4px 0;
  gap: ${({ gap }) => gap};

  &:hover ${ActionWrapper} {
    opacity: 1;
  }
`;
const EllipsisColumnWrapper = styled(FlexColumnContainer)`
  min-width: 0;
  flex: 2;
  cursor: ${(props) => (props.clickable ? 'pointer' : 'initial')};
  gap: ${({ gap }) => gap};
  line-height: 19px;
`;

const RecommendationProgressBarWrapper = styled.span`
  padding: 0 1em;
  width: 60px;
  margin: auto 0;
`;

const CompletionIcon = (props) => {
  if (props.disabled) {
    return <DisabledIcon bordered />;
  }
  if (props.completed) {
    return <Checked bordered />;
  }
  return <UnChecked bordered />;
};

const StyledSecondaryLink = styled(SecondaryLink)`
  && {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    padding-bottom: 0;
    font-weight: 500;
    line-height: ${({ theme }) => theme.textCss.sizes.default};
    color: ${({ theme }) => theme.colors.secondaryBlue};
  }
`;

const TextOnTwoLines = styled(DarkerText)`
  padding: 0 0;
  && {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    font-weight: ${(props) => (props.defaultLabelFontWeight === 'medium' ? 500 : 400)};
    font-size: 13px;

    & strong {
      font-weight: 500;
      color: ${(props) => props.theme.text.colors.black};
    }
  }
`;

const EllipsisLightText = styled(LightText)`
  && {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 0.75em;
    line-height: 1.2em;
  }
`;

const StyledTooltipComponent = styled(TooltipComponent)`
  min-width: 0;
`;

export const InstructionProgressBar = ({ score }) => (
  <RecommendationProgressBarWrapper>
    <ProgressBar small successMonoColor value={score} />
  </RecommendationProgressBarWrapper>
);

export function SerpInstruction({
  disabled,
  completed,
  url,
  title,
  rawHtmlDescription,
  onToggle,
  onCopy,
  description = null,
}) {
  const { t } = useTranslation();
  return (
    <InstructionWrapper disabled={disabled || completed}>
      <Wrapper gap="10px">
        <CompletionIcon completed={completed} disabled={disabled} />
        <EllipsisColumnWrapper gap="2px">
          <StyledSecondaryLink isExternal small title={title} to={url}>
            {title}
          </StyledSecondaryLink>
          {!!rawHtmlDescription && (
            <StyledText dangerouslySetInnerHTML={{ __html: rawHtmlDescription }} size="small" />
          )}
          {description}
        </EllipsisColumnWrapper>
      </Wrapper>
      {!!onCopy && (
        <ActionToggleIconWrapper onClick={onCopy}>
          <CopyIcon onClick={onCopy} />
        </ActionToggleIconWrapper>
      )}
      <ActionToggleIconWrapper onClick={onToggle}>
        <TooltipComponent
          title={t(
            disabled
              ? 'components:optimization.instruction.enable'
              : 'components:optimization.instruction.disable'
          )}
        >
          {!disabled ? <DisableOptimizationButton /> : <EnableOptimizationButton />}
        </TooltipComponent>
      </ActionToggleIconWrapper>
    </InstructionWrapper>
  );
}

export function Instruction({
  allowActions = true,
  disabled,
  isReadOnly = true,
  url,
  title,
  label,
  position,
  scorer,
  isConsideredSameAsPage,
  onAdd,
  onToggle,
  onShowDetails,
  completed,
  description,
  defaultLabelFontWeight = 'medium',
}) {
  const { t } = useTranslation();

  function showDetails() {
    onShowDetails?.();
  }

  return (
    <div
      className={`optimization-instruction ${(disabled || completed) && 'optimization-instruction--disabled'}`}
    >
      <div className="optimization-instruction__wrapper">
        <CompletionIcon completed={completed} disabled={disabled} />
        {position && (
          <TooltipComponent title={t('components:optimization.instruction.rank')}>
            <Position isCurrentPage={isConsideredSameAsPage}>{position}</Position>
          </TooltipComponent>
        )}
        {!!url ? (
          <FavIcon
            className="optimization-instruction__external-link"
            defaultColor
            disableClick
            displayLink
            isEditor={true}
            small={true}
            url={url}
          />
        ) : (
          <StyledTooltipComponent
            hide={!onShowDetails}
            title={t('components:optimization.instruction.view-examples')}
          >
            <EllipsisColumnWrapper clickable={!!onShowDetails} gap="2px" onClick={showDetails}>
              <TextOnTwoLines
                dangerouslySetInnerHTML={{
                  __html: label,
                }}
                defaultLabelFontWeight={defaultLabelFontWeight}
                title={title}
              />
              {description && <EllipsisLightText>{description}</EllipsisLightText>}
            </EllipsisColumnWrapper>
          </StyledTooltipComponent>
        )}
        <div className="optimization-instruction__end">
          <div className="optimization-instruction__scorer">{!!scorer && scorer}</div>
          {allowActions && (
            <div className="optimization-instruction__actions">
              {!isReadOnly && (
                <TooltipComponent title={t('components:optimization.instruction.add-to-content')}>
                  <div className="optimization-instruction__actions__add-optimization">
                    <AddOptimizationButton onClick={onAdd} />
                  </div>
                </TooltipComponent>
              )}
              <TooltipComponent
                title={t(
                  disabled
                    ? 'components:optimization.instruction.enable'
                    : 'components:optimization.instruction.disable'
                )}
              >
                <div className="optimization-instruction__actions__toggle" onClick={onToggle}>
                  {!disabled ? <DisableOptimizationButton /> : <EnableOptimizationButton />}
                </div>
              </TooltipComponent>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export const ShowMoreInstructions = (props) =>
  !props.hide && (
    <div className="optimization-show-more-instruction">
      <TertiaryButton inverted size="small" onClick={props.toggle}>
        {props.children}
      </TertiaryButton>
    </div>
  );
