import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InfoIcon } from 'semji-core/icons/InfoIcon';
import styled from 'styled-components/macro';

import FavIcon from '@/components/FavIcon/FavIcon';
import CaretIcon from '@/components/icons/CaretIcon';
import Crown from '@/components/icons/Crown';
import { IconExternalLink } from '@/components/Navigation/Link';
import TooltipComponent from '@/components/Tooltip/Tooltip';
import Rotate from '@/design-system/components/Button/Rotate';

export const Position = styled.span`
  box-sizing: border-box;
  display: flex;
  background: ${(props) =>
    props.isCurrentPage
      ? props.theme.colors.secondary
      : props.isCompleted
        ? props.theme.colors.green
        : props.theme.colors.lightGreyOpaque};
  border-radius: 50%;
  color: ${(props) =>
    props.isCurrentPage ? props.theme.text.colors.white : props.theme.text.colors.default};
  margin-top: 4px;
  padding: 5px;
  font-size: 12px;
  font-weight: 400;
  width: 18px;
  height: 18px;
  line-height: 22px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;

const Wrapper = styled(({ isExpand, ...props }) => <div {...props} />)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  gap: 8px;
  &:hover ${IconExternalLink} {
    display: inline;
  }
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  flex-grow: 1;
  max-width: 100%;
  overflow: hidden;
`;

const Title = styled.span`
  color: ${({ theme }) => '#2758BC'};
  font-size: 14px;
  font-weight: 400;
  line-height: 135%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  width: 100%;
`;

const DocumentOutline = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow: hidden;
`;

const Heading2 = styled.h2`
  display: block;
  overflow: hidden;
  width: 100%;
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
  padding: 4px 0 4px 0;
  margin-top: 5px;
  color: #252736;
  counter-increment: document-outline-headers 1;

  &&:before {
    content: counter(document-outline-headers) '. ';
    font-weight: 200;
    margin-right: 5px;
  }
`;

const Heading3 = styled.h3`
  display: block;
  overflow: hidden;
  width: 100%;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  color: #252736;
  padding: 4px 0 4px 0;

  &&:before {
    content: '> ';
    margin-right: 2px;
  }
`;

const UnknownPageSummary = styled(function (props) {
  const { t } = useTranslation();

  return <span {...props}>{t('components:competitor-page.unknown-page-summary')}</span>;
})`
  display: flex;
  justify-content: center;
  flex-grow: 1;
  padding: 10px;
  color: ${({ theme }) => theme.text.colors.default};
  font-size: 0.9em;
  line-height: normal;
  font-style: italic;
`;

const ExpansionPanel = styled((props) => (
  <Accordion
    {...props}
    classes={{
      expanded: 'expanded',
    }}
  />
))`
  && {
    border-bottom: 1px solid ${(props) => props.theme.colors.brightGrey};
    box-shadow: none;
    padding: 10px 0;

    &.expanded {
      margin: 0;
    }

    &::before {
      background-color: transparent;
    }
  }
`;

const ExpansionPanelSummary = styled((props) => (
  <AccordionSummary
    {...props}
    classes={{
      content: 'content',
      expanded: 'expanded',
    }}
  />
))`
  && {
    padding: 0;

    .content {
      margin: 0;
      width: 100%;
    }
  }
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  && {
    padding: 0 28px 20px;
  }
`;

export const Headers = styled(function ({ date, ...props }) {
  const { t } = useTranslation();
  return (
    <div {...props}>
      <span>
        <strong>{t('components:competitor-page.headers-google-result')}</strong>&nbsp;(
        {DateTime.fromISO(date).toFormat('LLL dd')}.)
      </span>
      <TooltipComponent
        isFrame={false}
        placement="top"
        title={t('components:competitor-page.google-serp-date')}
      >
        <InfoIcon />
      </TooltipComponent>
    </div>
  );
})`
  padding: 18px 30px 0 0;
  color: ${({ theme }) => theme.cssColors.dark060};
  font-size: 13px;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 10px;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    strong {
      font-weight: 500;
    }
  }
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 12px;
      height: 12px;
    }
  }
`;

const StyledTooltipComponent = styled(TooltipComponent)`
  && {
    color: ${({ theme }) => theme.text.colors.default};
  }
`;

const StyledFavIcon = styled(FavIcon)`
  && {
    span:last-child {
      font-size: 13px;
    }
  }
`;

const RotateAction = styled(Rotate)`
  width: 10px;
  justify-content: center;
  font-size: 0.7em;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
  justify-content: space-between;
`;

export default function CompetitorPage({
  title,
  url,
  position,
  isCurrentPage,
  featuredSnippet,
  documentOutline,
  expanded,
  onChange = () => {},
}) {
  const { t } = useTranslation();

  function handleChange() {
    onChange(position);
  }

  function formatHeadingTitle(title) {
    return title === '' ? '' : title[0].toUpperCase() + title.substring(1);
  }

  return (
    <ExpansionPanel expanded={expanded} square onChange={handleChange}>
      <ExpansionPanelSummary>
        <Wrapper>
          <Position isCurrentPage={isCurrentPage}>{position}</Position>
          <PageWrapper>
            <TitleContainer>
              <StyledFavIcon
                defaultColor
                disableClick
                displayLink
                isEditor={true}
                noPadding
                url={url}
              />
              <RotateAction degree="-180" hasRotated={expanded}>
                <CaretIcon />
              </RotateAction>
            </TitleContainer>
            <Title title={title || url}>{title || url}</Title>
          </PageWrapper>
          {featuredSnippet && (
            <StyledTooltipComponent title={t('components:competitor-page.tooltip-text')}>
              <Crown />
            </StyledTooltipComponent>
          )}
        </Wrapper>
      </ExpansionPanelSummary>
      <StyledAccordionDetails>
        {(documentOutline || []).length ? (
          <DocumentOutline>
            {documentOutline.map((heading, index) => {
              switch (heading.tagName) {
                case 'h3':
                  return (
                    <Heading3 key={`${heading.tagName}_${index}`} title={heading.title}>
                      {formatHeadingTitle(heading.title)}
                    </Heading3>
                  );
                case 'h2':
                default:
                  return (
                    <Heading2 key={`${heading.tagName}_${index}`} title={heading.title}>
                      {formatHeadingTitle(heading.title)}
                    </Heading2>
                  );
              }
            })}
          </DocumentOutline>
        ) : (
          <UnknownPageSummary />
        )}
      </StyledAccordionDetails>
    </ExpansionPanel>
  );
}
