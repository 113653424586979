import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { SERVICES_LIST } from '@/utils/constants';

import Img from './Dialog/Img';
import LinkLikeButton from './Dialog/LinkLikeButton';
import SubTitle from './Dialog/SubTitle';
import Title from './Dialog/Title';

const Container = styled.div`
  padding: 2rem;
`;

function NoProperties() {
  const { t } = useTranslation();

  return (
    <Container>
      <Img alt={SERVICES_LIST.SEARCH_CONSOLE.title} src={SERVICES_LIST.SEARCH_CONSOLE.logo} />
      <Title noMargin weight="medium">
        {t('service-integration:search-console.no-properties-modal.title')}
      </Title>
      <SubTitle color="dark060" size="sm">
        <Trans i18nKey="service-integration:search-console.no-properties-modal.subtitle" t={t}>
          Semji could’nt find any Search Console properties.
          <br />
          Please, select another Google account with Search Console properties.
        </Trans>
        <br />
        <LinkLikeButton
          as="a"
          href={t('common:links.help-connect-google-search-console')}
          margin="25px 0 0 0"
          rel="noopener noreferrer"
          target="_blank"
        >
          {t('service-integration:search-console.help.connect-search-console')}
        </LinkLikeButton>
      </SubTitle>
    </Container>
  );
}

export default NoProperties;
