import './ChartLegend.scss';

import React from 'react';
import { CupIcon } from 'semji-core/icons/CupIcon';

import CheckboxColoredIcon from '@/components/icons/CheckboxColoredIcon';
import theme from '@/themes/defaultTheme';

export interface ChartLegendProps {
  isVisible: boolean;
  color: string;
  name: string;
  isWinner?: string;
}

export default function ChartLegend({ isVisible, color, name, isWinner }: ChartLegendProps) {
  return (
    <div className="competitors-chart-legend">
      <CheckboxColoredIcon
        className="competitors-chart-legend__checkbox"
        color={isVisible ? color : theme.cssColors.dark020}
      />
      <span className="competitors-chart-legend__label">{name}</span>
      {isVisible && isWinner && <CupIcon />}
    </div>
  );
}
