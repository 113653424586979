import './NoSearchFound.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ENUM_BUTTON_VARIANTS } from 'semji-core/components/Button';

import EmptyStateSearch from '@/assets/images/empty-state-search-no-result.svg';

export default function NoSearchFound({
  onClearFilters,
  showResetButton = true,
}: {
  onClearFilters: () => void;
  showResetButton?: boolean;
}) {
  const { t } = useTranslation();
  return (
    <div className="ag-grid__no-search-found">
      <img
        alt={t('ag-grid:no-search-found.search-alt')}
        className="ag-grid__no-search-found__image"
        src={EmptyStateSearch}
      />
      <div className="ag-grid__no-search-found__text">
        <p className="ag-grid__no-search-found__title">{t('ag-grid:no-search-found.no-results')}</p>
        <p className="ag-grid__no-search-found__description">
          {t('ag-grid:no-search-found.no-results-description')}
        </p>
      </div>
      {showResetButton && (
        <Button
          className="ag-grid__no-search-found__button"
          variant={ENUM_BUTTON_VARIANTS.Text}
          onClick={onClearFilters}
        >
          {t('ag-grid:no-search-found.clear-filters')}
        </Button>
      )}
    </div>
  );
}
