import styled from 'styled-components/macro';

const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-width: 700px;
  height: 500px;
  min-height: 350px;
  overflow: hidden;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  background: white;
  width: 100%;
`;

export default Wrapper;
