import './PageCategory.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { AnnouncementIcon } from 'semji-core/icons/AnnouncementIcon';
import { CartIcon } from 'semji-core/icons/CartIcon';
import { ClipBoardIcon } from 'semji-core/icons/ClipBoardIcon';
import { HomeIcon } from 'semji-core/icons/HomeIcon';
import { HummerIcon } from 'semji-core/icons/HummerIcon';
import { MediaIcon } from 'semji-core/icons/MediaIcon';
import { MiscIcon } from 'semji-core/icons/MiscIcon';
import { PinIcon } from 'semji-core/icons/PinIcon';
import { TagIcon } from 'semji-core/icons/TagIcon';

import { PageType } from '@/types/competitors';

const pageTypes = [
  {
    icon: <MediaIcon />,
    label: 'common:page-category.article',
    value: PageType.Article,
  },
  {
    icon: <TagIcon />,
    label: 'common:page-category.cat-article',
    value: PageType.CatArticle,
  },
  {
    icon: <CartIcon />,
    label: 'common:page-category.cat-ecommerce',
    value: PageType.CatEcommerce,
  },
  {
    icon: <MiscIcon />,
    label: 'common:page-category.misc',
    value: PageType.Misc,
  },
  {
    icon: <ClipBoardIcon />,
    label: 'common:page-category.product-sheet',
    value: PageType.ProductSheet,
  },
  {
    icon: <AnnouncementIcon />,
    label: 'common:page-category.news',
    value: PageType.News,
  },
  {
    icon: <PinIcon />,
    label: 'common:page-category.landing-page',
    value: PageType.LandingPage,
  },
  {
    icon: <HomeIcon />,
    label: 'common:page-category.homepage',
    value: PageType.HomePage,
  },
  {
    icon: <HummerIcon />,
    label: 'common:page-category.tool',
    value: PageType.Tool,
  },
  {
    icon: <CartIcon />,
    label: 'common:page-category.shopping',
    value: PageType.Shopping,
  },
];

export default function PageCategory({ category }: { category: string }): JSX.Element {
  const { t } = useTranslation();
  const pageTypesInfo = pageTypes.find((pt) => pt.value === category);

  return (
    <span className="competitor-page-category">
      {pageTypesInfo && pageTypesInfo.icon}
      {pageTypesInfo && t(pageTypesInfo.label)}
    </span>
  );
}
