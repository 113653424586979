import RadioGroup from '@material-ui/core/RadioGroup';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getRoundedNumberWithSuffix } from 'semji-core/utils/numbers';
import styled from 'styled-components/macro';

import { LinkLikeButton } from '@/components/Button/Button';
import ScrollBar from '@/components/Scrollbar';
import InfoLinkTooltip from '@/components/Tooltip/InfoLinkTooltip';
import Container from '@/containers/ServiceIntegration/SearchConsole/Steps/Dialog/Container';
import DisplayUrl from '@/containers/ServiceIntegration/SearchConsole/Steps/Dialog/DisplayUrl';
import StyledFormControl from '@/containers/ServiceIntegration/SearchConsole/Steps/Dialog/FormControl';
import FormLabel from '@/containers/ServiceIntegration/SearchConsole/Steps/Dialog/FormLabel';
import Img from '@/containers/ServiceIntegration/SearchConsole/Steps/Dialog/Img';
import StyledRadio from '@/containers/ServiceIntegration/SearchConsole/Steps/Dialog/Radio';
import Row from '@/containers/ServiceIntegration/SearchConsole/Steps/Dialog/Row';
import SiteList from '@/containers/ServiceIntegration/SearchConsole/Steps/Dialog/SiteList';
import SubTitle from '@/containers/ServiceIntegration/SearchConsole/Steps/Dialog/SubTitle';
import Text from '@/containers/ServiceIntegration/SearchConsole/Steps/Dialog/Text';
import Title from '@/containers/ServiceIntegration/SearchConsole/Steps/Dialog/Title';
import TitleRow from '@/containers/ServiceIntegration/SearchConsole/Steps/Dialog/TitleRow';
import UppercaseText from '@/containers/ServiceIntegration/SearchConsole/Steps/Dialog/UppercaseText';
import { SERVICES_LIST } from '@/utils/constants';

const Wrapper = styled.div`
  display: flex;
  padding: 15px 0;
`;

function Properties({
  handleChange,
  invalidProperties = [],
  validProperties = [],
  property,
  workspaceWebsiteUrl,
}) {
  const { t } = useTranslation();
  const userLanguageCode = useSelector((state) => state.user?.languageCode);

  const [isAllPropsDisplayed, setIsAllPropsDisplayed] = useState(false);

  function handleTogglePropertiesDisplay() {
    setIsAllPropsDisplayed(!isAllPropsDisplayed);
  }

  function onChange(e) {
    handleChange(e.target.value);
  }

  const formatNumberOfPages = (num) =>
    num >= 25000
      ? '25K+'
      : getRoundedNumberWithSuffix({
          locale: userLanguageCode,
          number: num,
        }) || 0;

  return (
    <Container>
      <div>
        <Img alt={SERVICES_LIST.SEARCH_CONSOLE.title} src={SERVICES_LIST.SEARCH_CONSOLE.logo} />
        <Title noMargin weight="medium">
          {t('service-integration:search-console.properties-modal.title')}
          <InfoLinkTooltip
            href={t('common:links.help-connect-google-search-console')}
            title={t('service-integration:search-console.properties-modal.tooltip')}
          />
        </Title>
        <SubTitle color="dark060" size="sm">
          {t('service-integration:search-console.properties-modal.subtitle')}
        </SubTitle>
        <DisplayUrl noMargin weight="medium">
          {workspaceWebsiteUrl}
        </DisplayUrl>
      </div>
      <br />
      <SiteList>
        <ScrollBar>
          <RadioGroup value={property?.siteUrl || ''} onChange={onChange}>
            <TitleRow>
              <UppercaseText color="dark040" weight="strong">
                {t('service-integration:search-console.properties-modal.valid-properties-title')}
              </UppercaseText>
              <UppercaseText color="dark040" weight="strong">
                {t('service-integration:search-console.properties-modal.pages')}
              </UppercaseText>
            </TitleRow>
            {validProperties.length > 0 &&
              validProperties.map(({ siteUrl, rows }, index) => (
                <Row key={`${siteUrl}_${index}`}>
                  <StyledFormControl>
                    <StyledRadio id={siteUrl} value={siteUrl} />
                    <FormLabel htmlFor={siteUrl}>
                      <Text>{siteUrl}</Text>
                      <Text>{formatNumberOfPages(rows?.length)}</Text>
                    </FormLabel>
                  </StyledFormControl>
                </Row>
              ))}
            {isAllPropsDisplayed && (
              <>
                <TitleRow>
                  <UppercaseText color="dark040" weight="strong">
                    {t(
                      'service-integration:search-console.properties-modal.invalid-properties-title'
                    )}
                  </UppercaseText>
                </TitleRow>

                {invalidProperties.map(({ siteUrl, rows }, index) => (
                  <Row key={`${siteUrl}_${index}`}>
                    <Text>{siteUrl}</Text>
                  </Row>
                ))}
              </>
            )}

            {invalidProperties.length > 0 && (
              <Wrapper>
                <LinkLikeButton noPadding onClick={handleTogglePropertiesDisplay}>
                  {t(
                    isAllPropsDisplayed
                      ? 'service-integration:search-console.properties-modal.hide-properties'
                      : 'service-integration:search-console.properties-modal.show-all-properties'
                  )}
                </LinkLikeButton>
              </Wrapper>
            )}
          </RadioGroup>
        </ScrollBar>
      </SiteList>
    </Container>
  );
}

export default Properties;
