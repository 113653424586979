import { BrandVoiceModel } from '@/types/brandVoice/brandVoice.types';

import { DELETE_BRAND_VOICE, SET_BRAND_VOICE } from './actionTypes';

export const setBrandVoice = ({
  workspaceId,
  value,
}: {
  workspaceId: string | undefined;
  value: BrandVoiceModel | null;
}) => ({
  type: SET_BRAND_VOICE,
  value,
  workspaceId,
});

export const deleteBrandVoice = ({ workspaceId }: { workspaceId: string | undefined }) => ({
  type: DELETE_BRAND_VOICE,
  workspaceId,
});
