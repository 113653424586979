import React from 'react';

import SvgWrapper from './SvgWrapper';

const OutlinedCardIcon = (props) => (
  <SvgWrapper {...props} viewBox="0 0 17 17">
    <mask
      height="13"
      id="mask0"
      maskUnits="userSpaceOnUse"
      style={{ maskType: 'alpha' }}
      width="17"
      x="0"
      y="2"
    >
      <path
        d="M15.5 2H1.5C0.673 2 0 2.673 0 3.5V13.5C0 14.327 0.673 15 1.5 15H15.5C16.327 15 17 14.327 17 13.5V3.5C17 2.673 16.327 2 15.5 2ZM16 13.5C16 13.775 15.776 14 15.5 14H1.5C1.224 14 1 13.775 1 13.5V8H16V13.5ZM1 5V3.5C1 3.225 1.224 3 1.5 3H15.5C15.776 3 16 3.225 16 3.5V5H1Z"
        fill="black"
      />
    </mask>
    <g mask="url(#mask0)">
      <rect height="20" width="20" x="-2" y="-1" />
    </g>
  </SvgWrapper>
);
export default OutlinedCardIcon;
