import { useMutation } from '@tanstack/react-query';

import apiClient from '@/apis/semji/apiClient';

export default function usePutArchiveContentIdeas(props) {
  const { put } = apiClient;

  return useMutation({
    mutationFn: ({ contentIdeasIds = [], isArchived = false }) =>
      Promise.all(
        contentIdeasIds.map(async (contentIdeaId) =>
          put(`/content_ideas/${contentIdeaId}`, { isArchived })
        )
      ),

    ...props,
  });
}
