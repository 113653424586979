import { useMutation } from '@tanstack/react-query';

import apiClient from '@/apis/semji/apiClient';

export default function usePostComment({ ...props }) {
  const { post } = apiClient;

  return useMutation({
    mutationFn: ({ threadId, content }) =>
      post(`threads/${threadId}/comments`, {
        content,
      }),

    ...props,
  });
}
