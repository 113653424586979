import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { putUserData } from '../../apis/semji/api';
import Country from '../../components/Flag/Country';
import { showSuccessSnackbar } from '../../store/actions/ui';
import { setUserLanguageCode } from '../../store/actions/user';
import { SUPPORTED_LANGUAGES } from '../constants';

function LanguageSelector() {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user?.id);
  const userLanguageCode = useSelector((state) => state.user?.languageCode);

  const { t } = useTranslation();

  const [value, setValue] = useState({
    code: userLanguageCode,
    label: t(`common:locale.${userLanguageCode}`),
  });
  const options = SUPPORTED_LANGUAGES.map((code) => ({ code, label: t(`common:locale.${code}`) }));

  const handleChange = async (event, newValue) => {
    const { code } = newValue || {};
    setValue(newValue);
    await putUserData(userId, { languageCode: code });
    dispatch(setUserLanguageCode(code));
    dispatch(showSuccessSnackbar(t(`settings:profile.language-success-snackbar`)));
  };

  return (
    <Autocomplete
      autoHighlight
      disableClearable={true}
      getOptionLabel={({ code, label }) => label || t(`common:locale.${code}`)}
      getOptionSelected={(option, value) => option.code === value.code}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
      renderOption={({ code, label }) => (
        <>
          <Country key={code} countryCode={code} height={20} mode="circ" width={20} />
          &nbsp;&nbsp;{label}
        </>
      )}
      style={{ width: '100%' }}
      value={value}
      onChange={handleChange}
    />
  );
}

export default LanguageSelector;
