import './CodeModal.scss';

import htmlToMarkdown from '@wcj/html-to-markdown';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  ENUM_TOGGLE_BUTTON_TYPE,
  ENUM_TOGGLE_BUTTON_VARIANT,
  ToggleButton,
} from 'semji-core/components/ToggleButton';
import { copyToClipboard } from 'semji-core/utils/clipboard';

import { beautifyHtml } from '@/components/Editor/DiffContent/DiffEmptyState/Utils';
import {
  CodeModalProps,
  ENUM_CODE_TYPE,
} from '@/containers/Content/TinyMceComponents/Code/CodeModal.types';
import { ALLOWED_ATTRIBUTES_FOR_SAVE } from '@/containers/Content/TinyMceComponents/Editor/constants';
import Dialog from '@/design-system/components/Dialog';
import { showSuccessSnackbar } from '@/store/actions/ui';

function CodeModal({ close, cleanHtml, html }: CodeModalProps): React.JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [displayMode, setDisplayMode] = React.useState(ENUM_CODE_TYPE.HTML);
  const [markdown, setMarkdown] = React.useState('');

  const cleanedHtml = cleanHtml(html, ALLOWED_ATTRIBUTES_FOR_SAVE);
  const beautifulHtml = beautifyHtml(cleanedHtml);

  function onConfirm() {
    if (displayMode === ENUM_CODE_TYPE.MARKDOWN) {
      copyToClipboard(markdown);
    } else {
      copyToClipboard(beautifulHtml);
    }

    dispatch(showSuccessSnackbar(t('common:notifications.copy-to-clipboard')));
  }

  useEffect(() => {
    htmlToMarkdown({ html: cleanedHtml }).then((output) => {
      setMarkdown(output);
    });
  }, [html]);

  return (
    <Dialog
      cancelLabel={t('content:editor-container.source-code.close')}
      confirmLabel={t('content:editor-container.source-code.copy')}
      isOpen
      maxWidth={'lg'}
      title={t('content:editor-container.source-code.title')}
      onCancel={close}
      onConfirm={onConfirm}
    >
      <ToggleButton
        actions={[
          {
            active: displayMode === ENUM_CODE_TYPE.HTML,
            onClick: () => setDisplayMode(ENUM_CODE_TYPE.HTML),
            title: ENUM_CODE_TYPE.HTML,
          },
          {
            active: displayMode === ENUM_CODE_TYPE.MARKDOWN,
            dataIntercomTarget: 'code_modal_markdown_tab',
            onClick: () => setDisplayMode(ENUM_CODE_TYPE.MARKDOWN),
            title: ENUM_CODE_TYPE.MARKDOWN,
          },
        ]}
        type={ENUM_TOGGLE_BUTTON_TYPE.Icon}
        variant={ENUM_TOGGLE_BUTTON_VARIANT.Primary}
      />
      <p className="code_modal__body">
        <textarea value={displayMode === ENUM_CODE_TYPE.HTML ? beautifulHtml : markdown} />
      </p>
    </Dialog>
  );
}

export default CodeModal;
