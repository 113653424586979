import './NoVersion.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import NoDraftIllustration from '@/assets/images/ill-empty-state-draft.svg';
import { ENUM_CONTENT_TYPE } from '@/types/contents';

export default function NoVersion({ status }: { status: ENUM_CONTENT_TYPE }) {
  const { t } = useTranslation();
  function getTranslationPrefix() {
    if (status === ENUM_CONTENT_TYPE.DRAFT) {
      return 'no-draft';
    }
    return 'no-published-version';
  }
  return (
    <div className="diff-version-modal__no-version">
      <img alt="No draft" src={NoDraftIllustration} />
      <div className="diff-version-modal__no-version__description">
        {t(`content:version-history-panel.${getTranslationPrefix()}`)}
      </div>
    </div>
  );
}
