import {
  METRICS_CONFIG_COMPARISON_PERIOD_PREVIOUS_RANGE,
  METRICS_CONFIG_COMPARISON_PERIOD_PREVIOUS_YEAR,
} from '@/utils/metrics/constants';

export enum ReportPeriodicity {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
  Quarterly = 'quarterly',
  Yearly = 'yearly',
}

export enum ReportPeriod {
  PreviousRange = METRICS_CONFIG_COMPARISON_PERIOD_PREVIOUS_RANGE,
  PreviousYear = METRICS_CONFIG_COMPARISON_PERIOD_PREVIOUS_YEAR,
}

export enum ReportOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export interface ReportFilter {
  inStock: boolean;
  new: boolean;
  date: {
    after: string;
    before: string;
  };
  exist: {
    lastPublishedAt: boolean;
    url: boolean;
  };
  order: {
    date: ReportOrder;
  };
  groupByPage?: boolean;
  periodicity: ReportPeriodicity;
}
