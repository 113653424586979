import { DateTime } from 'luxon';

import { getCurrentDateRange } from './getCurrentDateRange';

// today date is passed throw the different methods for test purpose
export const getCurrentDateRangeSQLFormat = ({
  period,
  today,
}: {
  period: string;
  today?: DateTime;
}) => {
  const dateRange = getCurrentDateRange({ period, today });

  return {
    from: dateRange.from.toSQLDate(),
    to: dateRange.to.toSQLDate(),
  };
};
