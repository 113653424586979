import { getUpdatedAccordionList } from '@/containers/Onboarding/constants';

export const selectWorkspaceIsOnboarding = (state, organizationId, defaultId) => {
  const workspaceAchievements = state.organizations.byId[organizationId]?.workspaces?.find(
    (workspace) => workspace.id === defaultId
  )?.workspaceAchievements;

  if (!workspaceAchievements) {
    return false;
  }

  const stepsList = getUpdatedAccordionList(workspaceAchievements);
  const someTasksAreNotValidated = stepsList.some((step) => {
    if (step.children.length) {
      return step.children.some((child) => !child.validated);
    }

    return !step.validated;
  });

  return someTasksAreNotValidated;
};
