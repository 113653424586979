import './Report.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'semji-core/components/Tooltip';
import { InfoIcon } from 'semji-core/icons/InfoIcon';

import { useGetCompetitorWebsiteMetrics } from '@/apis/semji/competitors';
import { GROUP_BY_CATEGORY } from '@/apis/semji/competitors/constants';
import Card from '@/components/Card/Card';
import { NO_COMPETITOR_CONTENT_DATA } from '@/components/EmptyState/NoContentsVerticalBars';
import Loader from '@/components/Loader/Loader';
import ChartWrapper, {
  CHART_WRAPPER_VARIANT_SMALL,
} from '@/containers/Competitors/components/ChartWrapper';
import EmptyStateChart from '@/containers/Competitors/components/EmptyStateChart';
import { getCategoriesOptions } from '@/containers/Competitors/components/HeaderFilters/Filters/CategoryFilter/const';
import { HighChartsBar } from '@/containers/Competitors/Duel/Report/Chart/HighChartsBar';
import { SMALL_CARD_CONTENT_MIN_HEIGHT } from '@/containers/Competitors/Duel/Report/constant';
import {
  CATEGORY_FILTER_QUERY_PARAM,
  COMPETITORS_METRIC_TYPES,
  DEFAULT_COLOR,
} from '@/containers/Competitors/utils/constants';
import { getCompetitorColor, getHostnamefromUrl } from '@/containers/Competitors/utils/utils';
import { IFilterTransformed } from '@/hooks/useFilters/useFilters.types';
import {
  CompetitorCluster,
  CompetitorTopPagesFilters,
  CompetitorWebsite,
  CompetitorWebsiteMetric,
} from '@/types/competitors';

interface ContentReportProps {
  competitorReportId: string;
  competitor: CompetitorWebsite;
  reference: CompetitorWebsite;
  defaultMetricKey: COMPETITORS_METRIC_TYPES;
  clusters: CompetitorCluster[];
  filters: IFilterTransformed<CompetitorTopPagesFilters>;
  isLoading?: boolean;
  handleOpenDataExplorer?: (e: any, serieKey: string) => void;
}
export function ContentReport({
  handleOpenDataExplorer,
  competitorReportId,
  competitor,
  reference,
  clusters,
  filters,
  defaultMetricKey = COMPETITORS_METRIC_TYPES.traffic,
  isLoading,
}: ContentReportProps) {
  const { t } = useTranslation();

  const {
    data,
    isLoading: isCompetitorMetricsLoading,
    isPlaceholderData,
  } = useGetCompetitorWebsiteMetrics({
    filters: { ...filters, groupBy: GROUP_BY_CATEGORY },
    referenceId: reference?.id,
    reportId: competitorReportId,
    staleTime: 0,
    websiteId: competitor.id,
  });

  const referenceMetrics = data?.referenceMetrics ?? [];
  const competitorMetrics = data?.competitorMetrics ?? [];
  const nameReference = getHostnamefromUrl(reference.url);
  const pageTypes = getCategoriesOptions();
  const key = defaultMetricKey;
  function getDataByCategory(data: any[], category: string) {
    return data?.find((d) => d.id === category) ?? {};
  }

  function buildSeries(
    referenceMetrics: CompetitorWebsiteMetric[] = [],
    competitorMetrics: CompetitorWebsiteMetric[] = []
  ) {
    if (referenceMetrics?.length === 0 && competitorMetrics?.length === 0) return [];

    return [
      {
        color: DEFAULT_COLOR,
        data: pageTypes.map((pageType) => ({
          custom: {
            [COMPETITORS_METRIC_TYPES.page]:
              getDataByCategory(referenceMetrics, pageType.value)?.[
                COMPETITORS_METRIC_TYPES.page
              ] || 0,
            competitorId: reference.id,
            type: pageType.value,
            value: getDataByCategory(referenceMetrics, pageType.value)?.[key] || 0,
          },
          y: getDataByCategory(referenceMetrics, pageType.value)?.[key] || 0,
        })),
        name: nameReference,
      },
      {
        color: getCompetitorColor(competitor.url)?.color,
        data: pageTypes.map((pageType) => ({
          custom: {
            [COMPETITORS_METRIC_TYPES.page]:
              getDataByCategory(competitorMetrics, pageType.value)?.[
                COMPETITORS_METRIC_TYPES.page
              ] || 0,
            competitorId: competitor.id,
            type: pageType.value,
            value: getDataByCategory(competitorMetrics, pageType.value)?.[key] || 0,
          },
          y: getDataByCategory(competitorMetrics, pageType.value)?.[key] || 0,
        })),
        name: competitor ? getHostnamefromUrl(competitor.url) : '',
      },
    ];
  }

  const series = buildSeries(referenceMetrics, competitorMetrics);
  const categories = pageTypes.map((category) => category.label);
  const isNoData = series?.length === 0 || categories?.length === 0;

  return (
    <ChartWrapper isLoading={isLoading} variant={CHART_WRAPPER_VARIANT_SMALL}>
      <Card
        className="competitors-duel-report__card"
        contentMinHeight={SMALL_CARD_CONTENT_MIN_HEIGHT}
        title={
          <div className="competitors-duel-report__card__header">
            <div className="competitors-duel-report__card__header__title">
              <span>{t(`competitors:duel.report.title-content.${defaultMetricKey}`)}</span>
              <Tooltip
                description={t('competitors:source-normalized-traffic')}
                isFrame={false}
                title={t(`competitors:duel.report.title-content-tooltip.${defaultMetricKey}`)}
                tooltipClassName="competitors-duel-report__card__header__title-tooltip competitors-duel-report__card__header__title-tooltip--extra-large"
              >
                <InfoIcon />
              </Tooltip>
            </div>
          </div>
        }
      >
        {isCompetitorMetricsLoading || isPlaceholderData ? (
          <Loader children={''} fontSize="50px" />
        ) : isNoData ? (
          <EmptyStateChart version={NO_COMPETITOR_CONTENT_DATA} />
        ) : (
          <HighChartsBar
            categories={categories}
            metricsSeries={series}
            options={{}}
            selectedMetrics={[...new Set([defaultMetricKey, COMPETITORS_METRIC_TYPES.page])]}
            serieKey={CATEGORY_FILTER_QUERY_PARAM}
            sortKey={nameReference}
            onPointClick={handleOpenDataExplorer}
          />
        )}
      </Card>
    </ChartWrapper>
  );
}
