import { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import CloseIcon from '@/components/icons/CloseIcon';

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 30px;
  padding: 5px 0;
  color: ${({ theme }) => theme.cssColors.dark080};
  font-style: normal;
  font-weight: normal;
  font-size: ${({ fontSize, theme }) => theme.textCss.sizes[fontSize || 'default']};
  background-color: transparent;
  position: relative;

  &:after {
    position: absolute;
    bottom: 0;
    width: 100%;
    content: '';

    /* Border style */
    border-width: 0 0
      ${({ isInputFocused, isHovered }) => (isInputFocused || isHovered ? '2px' : '1px')} 0;
    border-style: solid;
    border-color: ${({ isHovered, isInputFocused, theme }) =>
      isInputFocused
        ? theme.cssColors.secondaryBlue
        : isHovered
          ? theme.cssColors.dark080
          : theme.cssColors.dark020};

    /* Border transition */
    transition: border-color 200ms ease-in;
  }

  & > svg {
    cursor: pointer;
  }
`;

const TextInput = styled.input.attrs((props) => ({
  type: 'text',
  ...props,
}))`
  flex: 1;
  border: none;
  background-color: transparent;
  font-size: ${({ fontSize, theme }) => theme.textCss.sizes[fontSize || 'default']};
  ::placeholder {
    color: ${({ theme }) => theme.cssColors.dark040};
  }
`;

export default forwardRef(function TextField(
  {
    className,
    fontSize,
    isClearable,
    leftIcon,
    placeholder,
    rightIcon,
    value,
    onChange,
    onFocus,
    onBlur,
    onKeyDown,
    ...props
  },
  ref
) {
  const { t } = useTranslation();
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  function onInputChange(event) {
    const value = event.target.value || '';
    onChange?.(value);
    event.stopPropagation();
  }

  function onInputKeyDown(event) {
    onKeyDown?.(event);
    event.stopPropagation();
  }

  function onInputClear() {
    onChange?.('');
  }

  function onInputFocus(event) {
    setIsInputFocused(true);
    onFocus?.(event);
  }

  function onInputBlur(event) {
    setIsInputFocused(false);
    onBlur?.(event);
  }

  function onMouseEnter() {
    setIsHovered(true);
  }

  function onMouseLeave() {
    setIsHovered(false);
  }

  return (
    <Container
      className={className}
      fontSize={fontSize}
      isHovered={isHovered}
      isInputFocused={isInputFocused}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {leftIcon && <>{leftIcon}</>}
      <TextInput
        ref={ref}
        fontSize={fontSize}
        placeholder={placeholder}
        value={value}
        onBlur={onInputBlur}
        onChange={onInputChange}
        onFocus={onInputFocus}
        onKeyDown={onInputKeyDown}
        {...props}
      />
      {isClearable && !!value && (
        <CloseIcon
          alt={t('components:select.folder-select.text-field.button-clear-alt')}
          onClick={onInputClear}
        />
      )}
      {rightIcon && <>{rightIcon}</>}
    </Container>
  );
});
