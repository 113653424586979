export interface QueueItem {
  // Define if function should be awaited
  isAwaited?: boolean;
  // Executed before main function
  beforeFn?: () => Promise<any>;
  // Main function
  fn: () => Promise<void>;
  // Executed after main function
  afterFn?: () => Promise<any>;
  // (debug) Intern queue id
  readonly id?: string;
  // (debug) Intern status of the item
  status?: ENUM_QUEUE_ITEM_STATUS;
}

export interface UseQueueParams {
  queue?: QueueItem[];
  // Show updates in queue
  debug?: boolean;
  // Empty the queue when stop is called or all queueItems are resolved
  cleanQueue?: boolean;
  // Function that will be executed before startQueue
  beforeQueue?: () => Promise<any> | (() => void);
  // Function that will be executed when all QueueItem finished main function
  // Will not be executed after stopQueue
  afterQueue?: () => Promise<any> | (() => void);
}

export enum ENUM_QUEUE_ITEM_STATUS {
  Pending = 'pending',
  Resolving = 'resolving',
  Success = 'success',
  Failed = 'failed',
  Cancelled = 'cancelled',
}
