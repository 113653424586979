import styled from 'styled-components/macro';

import PlusIcon from '@/components/icons/PlusIcon';

export const Text = styled.span`
  line-height: 135%;
  font-size: 13px;
  color: ${({ theme }) => theme.cssColors.dark060};
`;

export const StyledPlusIcon = styled(PlusIcon)`
  && {
    color: ${({ theme }) => theme.cssColors.dark060};
    font-size: 10px;
  }
`;
