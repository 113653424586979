import './DataExplorer.scss';

import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetCompetitorReportMetrics } from '@/apis/semji/competitors';
import { LOCATION, SCOPE_COMPETITORS } from '@/apis/semji/constants';
import useGetCurrentWorkspaceContentsWithSpecificProperties from '@/apis/semji/contents/useGetCurrentWorkspaceContentsWithSpecificProperties';
import useGetCurrentWorkspacePagesWithSpecificProperties from '@/apis/semji/pages/useGetCurrentWorkspacePagesWithSpecificProperties';
import Grid from '@/components/AGGrid/Grid';
import { useGridSelection } from '@/components/AGGrid/hooks/useGridSelection/useGridSelection';
import { AddToPlanningDialog } from '@/containers/AddToPlanning';
import {
  ADD_CONTENT_VERSION,
  ContentToAddToPlanning,
} from '@/containers/AddToPlanning/AddToPlanning.types';
import Export from '@/containers/Competitors/DataExplorer/BulkActions/Export';
import Modify from '@/containers/Competitors/DataExplorer/BulkActions/Modify';
import GridHeader from '@/containers/Competitors/DataExplorer/GridHeader/GridHeader';
import { getColsDefDataExplorer } from '@/containers/Competitors/DataExplorer/Table/config';
import { useCompetitor } from '@/containers/Competitors/hooks/useCompetitor';
import useCompetitorsFilters from '@/containers/Competitors/hooks/useCompetitorsFilters';
import { RemoveFromPlanningDialog } from '@/containers/RemoveFromPlanning';
import useFilters from '@/hooks/useFilters/useFilters';
import { CompetitorTopPage, CompetitorTopPagesFilters } from '@/types/competitors';

import { Header } from './Header';

export default function DataExplorer() {
  const { workspaceId, organizationId, reportId: competitorReportId } = useParams();
  const { competitorWebsites, personas, clusters, trafficNormalizationStatus } = useCompetitor();
  const navigate = useNavigate();

  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [isModifyOpen, setIsModifyOpen] = useState<boolean>(false);
  const [isRemoveFromPlanningDialogOpen, setIsRemoveFromPlanningDialogOpen] =
    useState<boolean>(false);
  const [isAddToPlanningDialogOpen, setIsAddToPlanningDialogOpen] = useState<boolean>(false);
  const [addToPlanningDialogVersion, setAddToPlanningDialogVersion] = useState<string | null>(null);
  const [contentsToAddToPlanning, setContentsToAddToPlanning] = useState<ContentToAddToPlanning[]>(
    []
  );
  const [contentsToRemoveFromPlanning, setContentsToRemoveFromPlanning] = useState<
    { id: string }[]
  >([]);
  const [forceRefetchDate, setForceRefetchDate] = useState<string>(new Date().toISOString());

  const filterHook = useFilters<CompetitorTopPagesFilters>();
  const gridSelectionHook = useGridSelection<CompetitorTopPage>();

  const { filters } = useCompetitorsFilters({
    clusters,
    competitors: competitorWebsites,
    personas,
  });

  // TO DO: use a context to share this between content ideas and competitors
  // include states of open/close modals
  const { data: pages = [], refetch: refetchPages } =
    useGetCurrentWorkspacePagesWithSpecificProperties({
      filters: { 'url[contains]': '' },
      location: LOCATION.COMPETITORS,
      properties: ['id', 'focusTopKeyword', 'title', 'url', 'draft', 'inStock'],
    });
  const { data: contents = [], refetch: refetchContents } =
    useGetCurrentWorkspaceContentsWithSpecificProperties({
      filters: { inStock: false },
      location: LOCATION.COMPETITORS,
      properties: [
        'assignedTo',
        'contentScore',
        'contentStatus',
        'dueDate',
        'id',
        'page',
        'pageFocusTopKeyword',
        'title',
        'type',
      ],
    });
  const {
    data: competitorsMetrics = [],
    isLoading: isCompetitorsMetricsLoading,
    isPlaceholderData: isCompetitorsMetricsPlaceholder,
  } = useGetCompetitorReportMetrics({
    competitorReportId: competitorReportId,
    filters: filterHook.buildTransformedFilters(filterHook.filters),
  });

  function getTotalMetric(metricKey: string): string {
    // Todo: remove filter on front side when api filters are fixed
    const competitorWebsiteIdFilter =
      filters.competitorWebsiteId ?? competitorWebsites.map((website) => website.id);
    return (
      competitorsMetrics
        .filter((competitorsMetric) =>
          competitorWebsiteIdFilter.includes(competitorsMetric.competitorWebsiteId)
        )
        .reduce((acc, competitorsMetric) => acc + competitorsMetric[metricKey], 0) ?? 0
    );
  }

  function buildBulkActionsPayload(isBulk: boolean) {
    return {
      ...filterHook.buildTransformedFilters(filterHook.filters),
      ...(isBulk ? { competitorTopPageId: gridSelectionHook.selection.map((ctp) => ctp.id) } : {}),
    };
  }

  function openAddToPlanningModal(contents: ContentToAddToPlanning[]) {
    setContentsToAddToPlanning(contents);
    setAddToPlanningDialogVersion(ADD_CONTENT_VERSION.ADD_TO_PLANNING_VERSION);
    setIsAddToPlanningDialogOpen(true);
  }

  function openStartOptimizingModal(contents: ContentToAddToPlanning[]) {
    setContentsToAddToPlanning(contents);
    setAddToPlanningDialogVersion(ADD_CONTENT_VERSION.START_OPTIMIZING_VERSION);
    setIsAddToPlanningDialogOpen(true);
  }

  function handleCancelExport() {
    setIsExporting(false);
  }

  function openRemoveFromPlanningDialog(ids: string[]) {
    if (!isRemoveFromPlanningDialogOpen) {
      setContentsToRemoveFromPlanning(contents.filter((content: any) => ids.includes(content.id)));
    }

    setIsRemoveFromPlanningDialogOpen((prevState) => !prevState);
  }

  function closeRemoveFromPlanningDialog() {
    setIsRemoveFromPlanningDialogOpen(false);
  }

  function closeAddToPlanningDialog() {
    setIsAddToPlanningDialogOpen(false);
  }

  function openEditDraftModal(plannedContentIds: string[]): null | any[] {
    if (1 === plannedContentIds.length) {
      const pageId = contents.find((content: any) => content.id === plannedContentIds[0])?.page?.id;

      if (pageId) {
        navigate(
          `/o/${organizationId}/w/${workspaceId}/p/${pageId}/content/${plannedContentIds[0]}`
        );
      }

      return null;
    }

    return contents.filter((content: any) => plannedContentIds.includes(content.id));
  }

  function refetchTopPages() {
    setForceRefetchDate(new Date().toISOString());
  }

  function onExportClick() {
    setIsExporting(true);
  }

  function onAddToPlanningClick() {
    const contents: ContentToAddToPlanning[] = gridSelectionHook.selection.map((content) => ({
      draftTitle: content.title,
      draftUrl: '',
      id: content.id,
      keyword: content.keyword,
      recommendedUrl: content.rankedWorkspaceUrl,
    }));

    openAddToPlanningModal(contents);
  }

  function onModifyClick() {
    setIsModifyOpen(true);
  }

  function onModifyClose() {
    setIsModifyOpen(false);
  }

  return (
    <div className="competitors-data-explorer">
      <Header onExportClick={onExportClick} />
      <div className="competitors-data-explorer__grid">
        <GridHeader
          gridSelectionHook={gridSelectionHook}
          isLoading={isCompetitorsMetricsLoading || isCompetitorsMetricsPlaceholder}
          nbPages={parseInt(getTotalMetric('nbPages'))}
          traffic={parseInt(getTotalMetric('traffic'))}
          onAddToPlanningClick={onAddToPlanningClick}
          onExportClick={onExportClick}
          onModifyClick={onModifyClick}
        />
        <div className="competitors-data-explorer__grid__content">
          <Grid
            cacheStalingTime={0}
            colDefs={getColsDefDataExplorer({
              clusters,
              openAddToPlanningModal,
              openEditDraftModal,
              openRemoveFromPlanningDialog,
              openStartOptimizingModal,
              organizationId,
              personas,
              trafficNormalizationStatus,
              workspaceId,
            })}
            filterHook={filterHook}
            gridSelection
            gridSelectionHook={gridSelectionHook}
            masterCacheKey={[
              SCOPE_COMPETITORS.GET_COMPETITOR_REPORTS_TOP_PAGES,
              LOCATION.COMPETITORS,
              forceRefetchDate, // this increment is to force refetch of the current page
            ]}
            url={`/competitor_reports/${competitorReportId}/top_pages`}
          />
        </div>
      </div>
      {isModifyOpen && (
        <Modify
          cancel={onModifyClose}
          clusters={clusters}
          competitorReportId={competitorReportId}
          filters={buildBulkActionsPayload(true)}
          gridSelectionHook={gridSelectionHook}
          isOpen
          nbPages={parseInt(getTotalMetric('nbPages'))}
          personas={personas}
          refetchTopPages={refetchTopPages}
        />
      )}
      <Export
        cancel={handleCancelExport}
        competitorReportId={competitorReportId}
        filters={buildBulkActionsPayload(true)}
        isOpen={isExporting}
      />
      {isRemoveFromPlanningDialogOpen && (
        <RemoveFromPlanningDialog
          contentsToRemoveFromPlanning={contentsToRemoveFromPlanning}
          handleRefetch={refetchTopPages}
          handleRefetchContent={refetchContents}
          handleRefetchPages={refetchPages}
          onClose={closeRemoveFromPlanningDialog}
        />
      )}
      {isAddToPlanningDialogOpen && addToPlanningDialogVersion && (
        <AddToPlanningDialog
          addToPlanningDialogVersion={addToPlanningDialogVersion}
          contentsToAddToPlanning={contentsToAddToPlanning}
          handleRefetch={refetchTopPages}
          handleRefetchContent={refetchContents}
          pages={pages}
          onClose={closeAddToPlanningDialog}
        />
      )}
    </div>
  );
}
