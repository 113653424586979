import './ReportDate.scss';

import { DateTime } from 'luxon';
import React, { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import { PlacementEnum } from 'semji-core/components/Dropdown';
import {
  ENUM_MENU_SELECT_POSITION,
  ENUM_MENU_SELECT_TYPE,
  MenuSelectDropdown,
} from 'semji-core/components/MenuSelect';
import { Tooltip } from 'semji-core/components/Tooltip';
import { InfoIcon } from 'semji-core/icons/InfoIcon';
import { capitalize } from 'semji-core/utils/string';

import CountryLanguageFlag from '@/components/Flag/CountryLanguage';
import CaretIcon from '@/components/icons/CaretIcon';
import { useCompetitorReports } from '@/containers/Competitors/hooks/useCompetitor';
import { SEARCH_INTELLIGENCE_PAGES } from '@/containers/Competitors/utils/constants';
import { selectCurrentWorkspaceCountry } from '@/store/selectors/selectCurrentWorkspaceCountry';
import selectCurrentWorkspaceLanguageCode from '@/store/selectors/selectCurrentWorkspaceLanguageCode';

export default function ReportDate({
  reportDate,
  competitorReportId,
}: {
  reportDate: string;
  competitorReportId: string;
}) {
  const { t } = useTranslation();
  const dropDrownActionsRef = useRef({
    handleClose: () => null,
    handleOpen: () => null,
  });
  const languageCode = useSelector(selectCurrentWorkspaceLanguageCode);
  const { organizationId, workspaceId, websiteId } = useParams();
  const { countryCode, countryName } = useSelector(selectCurrentWorkspaceCountry);

  const [searchValue, setSearchValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const { competitorReports } = useCompetitorReports();
  const match = useMatch('/o/:organizationId/w/:workspaceId/search-intelligence/:reportId/:page/*');
  const { page } = match?.params || {};

  const urlEnd =
    page === SEARCH_INTELLIGENCE_PAGES.competition && websiteId ? `${page}/${websiteId}` : page;

  const navigate = useNavigate();
  const handleSelectDate = (item: any) => {
    dropDrownActionsRef.current.handleClose();
    return navigate(
      `/o/${organizationId}/w/${workspaceId}/search-intelligence/${item.id}/${urlEnd}`
    );
  };

  function handleReset() {
    setSearchValue('');
  }

  function handleSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchValue(e.target.value);
  }

  const menuSelectItems = competitorReports.map((item) => {
    return {
      id: item.id,
      label: capitalize(DateTime.fromISO(item.reportDate).toFormat('LLLL yyyy')),
      onSelect: () => handleSelectDate(item),
      position: ENUM_MENU_SELECT_POSITION.DEFAULT,
      type: ENUM_MENU_SELECT_TYPE.DEFAULT,
      value: item.reportDate,
    };
  });

  const filteredAnalysisMonths = menuSelectItems.filter(
    (item) =>
      item.label.toLowerCase().includes(searchValue.toLowerCase().trim()) ||
      item.value.includes(searchValue.trim())
  );
  const itemsToSelect = [
    {
      id: 'search Value',
      label: '',
      onSelect: (e: any) => handleSelectDate(e),
      position: ENUM_MENU_SELECT_POSITION.TOP,
      type: ENUM_MENU_SELECT_TYPE.SEARCH,
      value: searchValue,
    },
    ...(searchValue === '' ? menuSelectItems : filteredAnalysisMonths),
  ];

  return (
    <div className="competitors-report-date">
      <div className="competitors-report-date__country">
        <CountryLanguageFlag
          countryCode={countryCode}
          countryName={countryName}
          height={19}
          isObvious={true}
          languageCode={languageCode}
          width={19}
        />
      </div>

      <div className={`competitors-report-date`}>
        {t('competitors:reports.header.date-report-tooltip.report-of')}
        <MenuSelectDropdown
          actionsRef={dropDrownActionsRef}
          anchorElement={
            <div
              className={`competitors-report-date__period ${isOpen && 'competitors-report-date__period--open'}`}
              onClick={handleReset}
            >
              <span>{capitalize(DateTime.fromISO(reportDate).toFormat('LLLL yyyy'))}</span>
              <CaretIcon />
            </div>
          }
          dropdownPlacement={PlacementEnum.DROPDOWN_PLACEMENT_BOTTOM_START}
          handleReset={handleReset}
          handleSearchChange={handleSearchChange}
          isFrame={false}
          items={itemsToSelect}
          itemSelected={competitorReportId}
          searchPlaceholder={t('components:input-search.placeholder')}
          titleTooltipCloseIcon={t('components:input-search.tooltip.title')}
          width="200px"
          onClose={() => setIsOpen(false)}
          onOpen={() => setIsOpen(true)}
        />
        <Tooltip
          description={
            <span>
              <Trans
                components={{
                  em: <em></em>,
                  p: <p></p>,
                }}
                i18nKey={'competitors:reports.header.date-report-tooltip.description'}
              />
            </span>
          }
          isFrame={false}
          popoverOptions={{ placement: 'top-left' }}
          title={t('competitors:reports.header.date-report-tooltip.title', {
            date: capitalize(DateTime.fromISO(reportDate).toFormat('LLLL yyyy')),
          })}
          tooltipClassName="competitors-report-date__tooltip"
        >
          <InfoIcon className="competitors-report-date__period__icon" opacity={0.6} />
        </Tooltip>
      </div>
    </div>
  );
}
